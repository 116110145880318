import React from 'react';
import pt from 'prop-types';
import { connect } from 'react-redux';
import { first, last, isEmpty } from 'lodash';
import moment from 'moment-timezone';

import LogPage from 'components/console/logs';
import { getDateRangeOptions } from 'constants/date-ranges';
import api from 'services/api';

import { LOG_LEVEL_FILTERS, LOG_MESSAGE_FILTERS } from 'constants/logs';

/**
 * @param {Record<string, unknown>} query
 * @param {AbortSignal} signal
 */
async function fetchLogs(query, signal) {
  try {
    const lines = await api.post(
      '/data/$get/:logs',
      {
        ...query,
        where: {
          ...(query.where || undefined),
        },
      },
      null,
      { signal },
    );

    lines.results.reverse();

    return lines;
  } catch (err) {
    console.error('Error fetching logs', err);
    return { skip: true };
  }
}

export const mapStateToProps = (state) => ({
  collection: state.data.collection,
  loading: state.loading,
});

export class ConsoleLogs extends React.Component {
  static contextTypes = {
    client: pt.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      query: {},
      queryValues: {},
      lines: [],
      newLines: [],
      prevLines: [],
      loaded: false,
    };

    this.methods = {
      onSubmitQuery: this.onSubmitQuery,
      onLoadMore: this.onLoadMore,
      onLoadPrev: this.onLoadPrev,
    };

    this.submittingCount = 0;
    this.abortController = new AbortController();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  getQuery(values = {}) {
    // Ignore certain location values
    const query = {
      ...values,
      date: undefined,
      cols: undefined,
    };

    let where = Object.keys(query).reduce((acc, key) => {
      const filter = this.getFilterWhere(query, key);

      if (filter?.$and) {
        const $and = acc.$and || [];

        Object.assign(acc, filter);

        acc.$and = [...$and, ...filter.$and];

        return acc;
      }

      return Object.assign(acc, filter);
    }, {});

    // Defaults
    if (!where.environment_id) {
      const { TEST_ENV } = require('utils');
      if (TEST_ENV) {
        where.environment_id = TEST_ENV;
      }
    }

    if (isEmpty(where)) {
      where = undefined;
    }

    return {
      // Note: depending on performance, we may need to use Mongo full-text search instead
      search: query.search,
      limit: query.limit || 30,
      where,
    };
  }

  getFilterWhere(values, key) {
    if (values[key] !== undefined) {
      if (LOG_LEVEL_FILTERS[key]) {
        return !isEmpty(values[key]) ? LOG_LEVEL_FILTERS[key](values) : [];
      }
      const filterFormat = LOG_MESSAGE_FILTERS[key]?.formatValue;
      const filterPath = LOG_MESSAGE_FILTERS[key]?.path;
      return values?.[key]?.map
        ? {
            [filterPath || `message.${key}`]: {
              $in: values[key].map((value) =>
                filterFormat ? filterFormat(value) : value,
              ),
            },
          }
        : {};
    }
    return [];
  }

  onSubmitQuery = async (values) => {
    this.abortController.abort();
    this.abortController = new AbortController();

    this.setState({ loaded: false, loading: true });

    const query = this.getQuery(values);

    this.submittingCount += 1;

    const { results: newLines, skip } = await fetchLogs(
      query,
      this.abortController.signal,
    );

    this.submittingCount -= 1;

    if (skip) {
      if (this.submittingCount <= 0) {
        this.setState({ loaded: true, loading: false });
      }

      return;
    }

    this.setState({
      query,
      queryValues: values,
      lines: this.assignSubLines(newLines),
      loaded: true,
      loading: false,
    });
  };

  getUpdatedQueryValues() {
    const { queryValues } = this.state;

    // Get updated values from range options
    const dateRangeOptions = getDateRangeOptions(
      this.context.client.timezone,
      'time',
    );

    const value = queryValues.date || 'alltime';
    const newDate = dateRangeOptions.find((option) => option.value === value);

    return {
      ...queryValues,
      dates: { ...(newDate?.range || queryValues.dates) },
    };
  }

  getExtraQuery(orientation) {
    const { lines } = this.state;

    // 1) Get updated date query
    const newQueryValues = this.getUpdatedQueryValues();

    // 3) Take the last or first line remaining and use that as the boundary
    if (orientation === 'more') {
      const lastDate = last(lines)?.date;
      if (lastDate) {
        newQueryValues.dates.startDate = moment(lastDate).add(
          1,
          'milliseconds',
        );
      }
    } else {
      // prev
      const firstDate = first(lines)?.date;
      if (firstDate) {
        newQueryValues.dates.endDate = moment(firstDate).subtract(
          1,
          'milliseconds',
        );
      }
    }

    const newQuery = this.getQuery(newQueryValues);
    return { lines, query: newQuery, queryValues: newQueryValues };
  }

  onLoadMore = async () => {
    const { lines, query } = this.getExtraQuery('more');

    const { results: moreNewLines, skip } = await fetchLogs(
      query,
      this.abortController.signal,
    );

    if (skip || moreNewLines.length <= 0) {
      return 0;
    }

    this.setState({
      lines: this.assignSubLines([...lines, ...moreNewLines]),
      query,
    });

    return moreNewLines.length;
  };

  onLoadPrev = async () => {
    const { lines, query } = this.getExtraQuery('prev');

    const { results: morePrevLines, skip } = await fetchLogs(
      query,
      this.abortController.signal,
    );

    if (skip || morePrevLines.length <= 0) {
      return 0;
    }

    this.setState({
      lines: this.assignSubLines([...morePrevLines, ...lines]),
      query,
    });

    return morePrevLines.length;
  };

  /** @param {object[]} lines */
  assignSubLines(lines) {
    /** @type {Map<string, object[]>} */
    const linesByRequest = new Map();

    for (const line of lines) {
      if (line.req_id) {
        let list = linesByRequest.get(line.req_id);

        if (list === undefined) {
          list = [];
          linesByRequest.set(line.req_id, list);
        }

        list.push(line);
      }
    }

    // Assign sub lines to request if function or webhook
    for (const reqLines of linesByRequest.values()) {
      if (reqLines.length > 1) {
        const firstLine = first(reqLines);
        const subLines = reqLines.slice(1);

        firstLine.subLines = (firstLine.subLines || []).filter(
          (line) => !subLines.some((subLine) => subLine.id === line.id),
        );

        firstLine.subLines.push(...subLines);

        // Remove sublines from lines array
        for (const subLine of subLines) {
          let pos = lines.indexOf(subLine);

          if (pos !== -1) {
            lines.splice(pos, 1);
          }
        }
      }
    }

    return lines;
  }

  render() {
    return <LogPage {...this.props} {...this.state} {...this.methods} />;
  }
}

export default connect(mapStateToProps, null)(ConsoleLogs);
