import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'components/form';
import View from 'components/view';
import ContentFields from 'components/content/fields';
import CustomerLocaleCurrencyFields from 'components/customer/locale-currency-fields';
import { FadeIn } from 'components/transitions';
import { hasLocalizedContent } from 'utils/content';
import './customer.scss';

export default class CustomerNew extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object,
  };

  render() {
    const { settings, content, edited, values, onSubmitRecord, onChangeForm } =
      this.props;

    const hasGroups =
      settings.accounts.groups && settings.accounts.groups.length > 0;
    const features = settings.accounts.features || {};

    return (
      <Form
        onSubmit={onSubmitRecord}
        autoFocus={true}
        onChange={onChangeForm}
        values={values}
      >
        <View
          detail={true}
          uri="/customers"
          sectionTitle="Customers"
          headerTitle="New customer"
          headerActions={[
            {
              label: 'Save customer',
              type: edited ? 'default' : 'secondary',
              submit: true,
            },
          ]}
          localized={hasLocalizedContent(content.models, 'accounts')}
          devtools={{
            model: 'accounts',
            console: false,
          }}
        >
          <div className="customer-new">
            <fieldset>
              {features.business && (
                <div className="row">
                  <Field
                    type="radio"
                    name="type"
                    label="Customer type"
                    buttons={true}
                    options={[
                      { value: 'individual', label: 'Individual' },
                      { value: 'business', label: 'Business' },
                    ]}
                  />
                </div>
              )}
              {values.type !== 'business' ? (
                <div className="row">
                  <Field
                    type="text"
                    label="First name"
                    name="first_name"
                    required={true}
                    className="span2"
                  />
                  <Field
                    type="text"
                    label="Last name"
                    name="last_name"
                    className="span2"
                  />
                </div>
              ) : (
                <FadeIn>
                  <div className="row">
                    <Field
                      type="text"
                      label="Name"
                      name="name"
                      required={true}
                      className="span4"
                    />
                  </div>
                </FadeIn>
              )}
              <div className="row">
                <Field
                  type="text"
                  label="Email address"
                  name="email"
                  required={true}
                  autoCompleteOff
                  className="span4"
                />
              </div>
              <div className="row">
                <Field
                  type="checkbox"
                  label="Customer accepts email marketing"
                  name="email_optin"
                  className="span4"
                />
              </div>
              <div className="row">
                <Field
                  type="text"
                  label="Phone number"
                  name="phone"
                  className="span4"
                />
              </div>
              <div className="row">
                {hasGroups && (
                  <Field
                    type="select"
                    label="Group"
                    name="group"
                    placeholder="None"
                    options={settings.accounts.groups}
                    clearIcon
                    className="span2"
                  />
                )}
                <Field
                  type="password"
                  label="Password"
                  name="password"
                  placeholder="Optional"
                  className="span2"
                  autoComplete="new-password"
                />
              </div>
              <CustomerLocaleCurrencyFields
                localeLabel="Locale preference"
                currencyLabel="Currency preference"
              />
              {values.type === 'business' && features.vat && (
                <FadeIn>
                  <div className="row">
                    <Field
                      type="text"
                      name="vat_number"
                      label="VAT number"
                      className="span2"
                      placeholder="Optional"
                    />
                    <div />
                  </div>
                </FadeIn>
              )}
              <ContentFields
                {...this.props}
                zone="contact"
                collection="accounts"
                models={content.models}
                values={values}
                currency={this.context.client.currency}
              />
            </fieldset>
          </div>
        </View>
      </Form>
    );
  }
}
