import React from 'react';
import Icon from 'components/icon';
import User from '../utils/user';
import Date from '../utils/date';
import Discount from '../utils/discount';

export default function CouponAdded({ event, record, coupon }) {
  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/discount-updated" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          Coupon added
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
        <span className="activity-event-subtitle">
          <Discount data={{ ...coupon, currency: record.currency }} />
        </span>
      </div>
    </div>
  );
}
