import { reduce } from 'lodash';
import { arrayToObject } from 'utils';

import api from '../services/api';

export const SETTINGS_FETCH = 'settings/fetch';
export const SETTINGS_FETCH_CONFIG = 'settings/fetchConfig';
export const SETTINGS_UPDATE = 'settings/update';
export const SETTINGS_LOAD_APPS = 'settings/loadApps';
export const SETTINGS_LOADING = 'settings/loading';

export default {
  fetch(id) {
    return {
      type: SETTINGS_FETCH,
      payload: api.getLocalized(`/data/settings/${mapNewToOldId(id)}`),
      meta: { id },
    };
  },

  fetchConfig(id) {
    return {
      type: SETTINGS_FETCH_CONFIG,
      payload: api.getLocalized(`/data/:settings/${id}`),
      meta: { id },
    };
  },

  load(id) {
    return (dispatch, getState) => {
      const { settings } = getState();

      if (settings[id]) {
        return Promise.resolve(settings[id]);
      }

      return dispatch(this.fetch(id));
    };
  },

  update(id, data) {
    return {
      type: SETTINGS_UPDATE,
      payload: api.put(`/data/settings/${mapNewToOldId(id)}`, data),
      meta: { id },
    };
  },

  loading(payload) {
    return { type: SETTINGS_LOADING, payload };
  },
};

export const initialState = {
  loading: false,
  configs: {},
  appSettings: [],
};

function applyAppSettings(appSettings) {
  return {
    appSettings,
    ...appSettings.reduce((acc, settings) => {
      acc[settings.id] = settings;
      return acc;
    }, {}),
  };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET':
      // App settings are not reset normally due to the timing between switching environments
      return {
        ...applyAppSettings(state.appSettings),
        ...initialState,
      };

    case SETTINGS_FETCH:
    case SETTINGS_UPDATE: {
      let payload = action.payload || {};

      if (payload.error || payload.errors) {
        return state;
      }

      switch (action.meta.id) {
        case 'products':
          payload = {
            ...payload,
            typeIndex: arrayToObject(payload.types),
          };
          break;

        default:
          break;
      }

      return {
        ...state,
        [action.meta.id]: payload,
        ...(mapNewToOldId(action.meta.id) !== action.meta.id
          ? { [mapNewToOldId(action.meta.id)]: payload }
          : undefined),
      };
    }

    case SETTINGS_FETCH_CONFIG:
      return {
        ...state,
        configs: {
          ...state.configs,
          [action.meta.id]: action.payload,
        },
      };

    case SETTINGS_LOAD_APPS:
      return {
        ...state,
        // Remove existing settings in case they were left over after reset
        ...reduce(
          state.appSettings,
          (acc, settings) => {
            acc[settings.id] = undefined;
            return acc;
          },
          {},
        ),
        ...applyAppSettings(action.payload.results),
      };

    case SETTINGS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}

/**
 * @param {import('redux').Store} store
 * @param {string} id
 * @returns {Promise<object>}
 * @deprecated use `actions.settings.load` action instead
 */
export async function getSettings(store, id) {
  const { session, settings } = store.getState();
  if (session.user_id) {
    if (!settings[id]) {
      settings[id] = await store.dispatch({
        type: SETTINGS_FETCH,
        payload: api.getLocalized(`/data/settings/${id}`),
        meta: { id },
      });
    }
    return settings[id];
  }
}

export async function getAdminSettings(store, id) {
  const { session, settings } = store.getState();
  if (session.user_id) {
    if (!settings[id]) {
      settings[id] = await store.dispatch({
        type: SETTINGS_FETCH,
        payload: api.get(`/data/schema.com.settings/${id}`),
        meta: { id },
      });
    }
    return settings[id];
  }
}

function mapNewToOldId(id) {
  if (id === 'shipping') {
    return 'shipments';
  }
  return id;
}
