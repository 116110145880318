// @flow
import PropTypes from 'prop-types';

import React from 'react';
import { find } from 'lodash';
import { connect } from 'react-redux';
import InsertImageModalPage from 'components/pages/modals/insert-image';
import actions from 'actions';

const mapStateToProps = (state) => ({
  files: state.files,
});

const mapDispatchToProps = (dispatch) => ({
  fetchImageCollection: async (query) => {
    return dispatch(
      actions.files.fetchCollection({
        content_type: {
          $regex: '^image/',
        },
        limit: 5,
        ...query,
      }),
    );
  },

  loadMoreImages: async (query) => {
    return dispatch(
      actions.files.loadMore({
        content_type: {
          $regex: '^image/',
        },
        limit: 5,
        ...query,
      }),
    );
  },

  uploadImage: async (image) => {
    return dispatch(actions.files.uploadImage(image));
  },

  notifyError: (record) => {
    dispatch(actions.flash.error('Error uploading image'));
  },
});

export class InsertImageModal extends React.Component {
  static propTypes = {
    onInsert: PropTypes.func.isRequired,
  };

  static contextTypes = {
    closeModal: PropTypes.func.isRequired,
    uploadImages: PropTypes.func.isRequired,
  };

  state = {};

  constructor(props: Object) {
    super(props);
    this.state = {
      images: [],
      page: 1,
      selectedId: null,
      onClickImage: this.onClickImage.bind(this),
      onClickInsert: this.onClickInsert.bind(this),
      onClickNextPage: this.onClickNextPage.bind(this),
      onClickPrevPage: this.onClickPrevPage.bind(this),
      onUploadImage: this.onUploadImage.bind(this),
    };
  }

  componentWillMount() {
    const { fetchImageCollection } = this.props;
    fetchImageCollection();
  }

  componentWillReceiveProps(nextProps: Object) {
    if (nextProps.files.collection) {
      this.setState({ images: nextProps.files.collection.results });
    }
  }

  onClickImage(event: Object) {
    event.preventDefault();
    this.setState({ selectedId: event.target.dataset.id });
  }

  onClickInsert(event: Object) {
    event.preventDefault();
    const image = find(this.state.images, { id: this.state.selectedId });
    this.props.onInsert(image.url);
    this.context.closeModal();
  }

  onClickNextPage(event: Object) {
    event.preventDefault();
    const page = this.state.page + 1;
    this.setState({ page });
    if (page > this.props.files.collection.page) {
      this.props.loadMoreImages({ page });
    }
  }

  onClickPrevPage(event: Object) {
    event.preventDefault();
    const page = this.state.page - 1;
    this.setState({ page });
  }

  onUploadImage(files: Object) {
    const { fetchImageCollection } = this.props;

    this.context.uploadImages(files).then((results) => {
      const last = results[results.length - 1] || {};
      this.setState({ page: 1, selectedId: last.id });
      fetchImageCollection();
    });
  }

  render() {
    return <InsertImageModalPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InsertImageModal);
