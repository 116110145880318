import React from 'react';
import './error.scss';
import LogoTopLeft from 'components/logo';

export default function Error403({ location, withLogo = true }) {
  return (
    <div className="error-container">
      {withLogo && <LogoTopLeft />}
      <div className="error-wrapper">
        <div className="error-404">
          <h1>403 access denied</h1>
        </div>
      </div>
    </div>
  );
}
