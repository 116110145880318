import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NewModalPage from 'components/pages/modals/new-category';

import {
  NewCategory,
  mapStateToProps,
  mapDispatchToProps,
} from '../NewCategory';

export class NewCategoryModal extends NewCategory {
  static contextTypes = {
    notifyCreated: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  onSubmitRecord(values) {
    return super.onSubmitRecord(values).then((success) => {
      if (success) {
        this.context.closeModal();
        return this.props.fetchCategories();
      }
    });
  }

  render() {
    return <NewModalPage {...this.props} {...this.state} {...this.methods} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCategoryModal);
