import URL from 'url';
import Cookies from 'universal-cookie';
import { COOKIE_MAX_AGE } from './cookie';

const cookies = new Cookies();

let loggedIn = undefined;
let sessionId = undefined;
let sessionClient = undefined;

const Auth = {
  isLoggedIn(account) {
    return loggedIn;
  },

  buildSessionHeaders() {
    return {
      'X-Session': `${this.getSession()}`,
    };
  },

  setSession(session) {
    if (session && session.id && session.client_id) {
      loggedIn = !!session.user_id;
      sessionId = session.id;
      sessionClient = session.client_id;
      this.setSessionCookie();
    } else {
      this.removeSessionCookie();
      loggedIn = false;
      sessionId = null;
      sessionClient = null;
    }
  },

  setLoggedInSessionId(id, clientId) {
    loggedIn = true;
    sessionId = id;
    sessionClient = clientId;
    this.setSessionCookie();
  },

  getSession() {
    if (sessionId) {
      return sessionId;
    }
    return (sessionId = cookies.get('_session_id'));
  },

  setSessionCookie() {
    if (!sessionId) {
      return;
    }
    cookies.set('_session_id', sessionId, this.getCookieOptions());
  },

  removeSessionCookie() {
    cookies.remove('_session_id', this.getCookieOptions());
  },

  getCookieDomain() {
    const { CLIENT_URL, ADMIN_SUB } = process.env;
    if (sessionClient) {
      const domain = URL.parse(
        CLIENT_URL.replace(
          'CLIENT_ID',
          sessionClient === 'admin' ? ADMIN_SUB : sessionClient,
        ),
      ).host.replace(/:.*/, '');
      return domain;
    }
    if (!window.location) {
      return null;
    }
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return `.${window.location.hostname.split('.').slice(-2).join('.')}`;
  },

  getCookieOptions() {
    const { NODE_ENV } = process.env;

    return {
      path: '/',
      maxAge: COOKIE_MAX_AGE,
      domain: this.getCookieDomain(),
      // client cookies cannot be httponly
      httpOnly: false,
      sameSite: 'lax',
      secure: NODE_ENV !== 'development',
    };
  },

  redirectClientSessionTo(
    url = '',
    router = null,
    options = { forceRedirect: false },
  ) {
    const { CLIENT_URL, BASE_URI, ADMIN_SUB } = process.env;
    if (!CLIENT_URL) {
      throw new Error('Missing CLIENT_URL');
    }

    const { forceRedirect } = options;
    const clientUrl = CLIENT_URL.replace(
      'CLIENT_ID',
      sessionClient === 'admin' ? ADMIN_SUB : sessionClient,
    );

    if (router && (forceRedirect || clientUrl === window.location.origin)) {
      router.replace(decodeURIComponent(url || ''));
    } else {
      window.location.href = `${clientUrl}${BASE_URI}/login/${sessionId}?to=${
        url || ''
      }`;
    }
  },
};

export default Auth;
