import { find, findIndex } from 'lodash';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
  HIDE_EDITOR,
  SHOW_EDITOR,
  UPDATE_EDITOR_LOADING,
  UPDATE_EDITOR_SECTION,
} from './types';

let MESSAGE_ID = 0;
let MESSAGE_LISTENER_REGISTERED = false;
const MESSAGE_HANDLERS = {};

export const hideEditor = () => ({
  type: HIDE_EDITOR,
});

export const showEditor = () => ({
  type: SHOW_EDITOR,
});

export const updateEditorLoading = (loading) => (dispatch) => {
  if (loading) {
    dispatch(showLoading());
  } else {
    dispatch(hideLoading());
  }
  return dispatch({
    type: UPDATE_EDITOR_LOADING,
    payload: loading,
  });
};

export const updateEditorSection = (section, scrollTop) => ({
  type: UPDATE_EDITOR_SECTION,
  payload: { section, scrollTop },
});

export const sendEditorMessage = (type, details) => {
  try {
    const editorFrame = document.getElementById('editor-viewport');
    if (editorFrame && editorFrame.contentWindow) {
      const id = ++MESSAGE_ID;
      if (details.value && details.value.$locale) {
        details.value = { ...details.value };
        delete details.value.$locale;
      }
      console.log('send message', { id, type, details });
      editorFrame.contentWindow.postMessage({ id, type, details }, '*');
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleEditorMessage = (type, handler, register = true) => {
  if (!register) {
    if (find(MESSAGE_HANDLERS[type], (h) => h === handler)) {
      const index = findIndex(MESSAGE_HANDLERS[type], (h) => h === handler);
      MESSAGE_HANDLERS[type].splice(index, 1);
    }
    return;
  }
  MESSAGE_HANDLERS[type] = MESSAGE_HANDLERS[type] || [];
  MESSAGE_HANDLERS[type].push(handler);
  if (!MESSAGE_LISTENER_REGISTERED) {
    MESSAGE_LISTENER_REGISTERED = true;
    window.addEventListener(
      'message',
      ({ data: msg }) => {
        const { type } = msg;
        if (type && MESSAGE_HANDLERS[type]) {
          for (let handler of MESSAGE_HANDLERS[type]) {
            handler(msg);
          }
        }
      },
      false,
    );
  }
};
