import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import View from 'components/view';
import Icon from 'components/icon';
import Link from 'components/link';
import Image from 'components/loading/image';
import CollectionEmpty from 'components/collection/empty';
import ExternalLink from 'components/link/external';

import themeCustomImg from './theme-custom.png';
import './storefront.scss';
import { getStorefrontURL, renderStorefrontThemeDesc } from 'utils/storefront';
import Status from 'components/status/status';
import ThemeSupportAlert from './theme-support-alert';
import DropdownButton from 'components/button/dropdown';

export default class StorefrontsList extends React.PureComponent {
  static contextTypes = {
    user: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    notifyDeleted: PropTypes.func.isRequired,
  };

  renderThumbnail(storefront) {
    return (
      <div
        className={classNames('storefront-list-thumbnail', {
          activating: !storefront.thumbnails?.desktop,
        })}
      >
        <Link to={`/storefronts/${storefront.id}`}>
          {storefront.thumbnails?.desktop ? (
            <Image
              className="storefront-list-thumbnail-image"
              src={storefront.thumbnails?.desktop || themeCustomImg}
              alt={storefront.name}
              unloader={
                <div className="storefront-list-thumbnail-missing">
                  <Icon fa="image-slash" />
                </div>
              }
            />
          ) : (
            <span className="storefront-list-thumbnail-icon">
              <Icon type="nav/storefront" />
            </span>
          )}
        </Link>
      </div>
    );
  }

  renderStorefront = (storefront) => {
    const storefrontURL = getStorefrontURL(storefront);

    return (
      <div key={storefront.id}>
        {storefront.hosted && (
          <ThemeSupportAlert appearance="opaque" className="connected bottom" />
        )}
        <div
          key={storefront.id}
          className={classNames('storefront-list-item', {
            'storefront-primary': storefront.primary,
            hosted: storefront.hosted,
          })}
        >
          <div className="storefront-details-container">
            {this.renderThumbnail(storefront)}

            <div className="storefront-list-details">
              <div className="box-title">
                <Link to={`/storefronts/${storefront.id}`}>
                  {storefront.name}
                </Link>
              </div>

              {storefrontURL ? (
                <div className="box-subtitle">
                  <ExternalLink to={storefrontURL} icon={false}>
                    {storefrontURL}
                  </ExternalLink>
                </div>
              ) : (
                <div className="storefront-list-alert">
                  <Icon fa="exclamation-circle" />
                  Set up your storefront URL.{' '}
                  <a
                    href="https://developers.swell.is/storefronts/storefront-hosting/storefront-hosting"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                </div>
              )}

              {storefront.primary && (
                <div className="storefront-list-status note">
                  <Status dot={false} type="positive">
                    <span>Primary</span>
                  </Status>
                </div>
              )}

              {storefront.source_model === 'themes' && (
                <div className="storefront-list-theme">
                  <span className="note">
                    {renderStorefrontThemeDesc(storefront)}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div>
            <DropdownButton
              anchor="right"
              anchorPosition={49}
              alignOffset={9}
              type="secondary"
              items={[
                storefront.editor && (
                  <Link to={`/storefronts/${storefront.id}/editor`} key="1">
                    Edit theme
                  </Link>
                ),
                <Link to={`/storefronts/${storefront.id}`} key="2">
                  Edit settings
                </Link>,
                !storefront.primary && (
                  <button
                    key="3"
                    onClick={this.props.onClickSetPrimary}
                    data-id={storefront.id}
                  >
                    Make primary
                  </button>
                ),
                <hr key="4" />,
                <button
                  key="5"
                  onClick={this.props.onClickDelete}
                  className="danger"
                  data-id={storefront.id}
                >
                  Delete storefront
                </button>,
              ]}
            >
              Actions
            </DropdownButton>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { storefronts } = this.props;

    const hasStorefronts = Boolean(storefronts?.results?.length);

    // sort by primary, then create date
    const storefrontList = [...(storefronts.results || [])].sort((a, b) => {
      if (a.primary) return -1;
      if (b.primary) return 1;

      return a.date_created - b.date_created;
    });

    return (
      <View
        key="storefront"
        headerTitle="Storefronts"
        headerSubtitle="Manage storefronts connected to your frontends"
        detail={true}
        headerActions={[
          hasStorefronts && {
            label: 'New storefront',
            link: `/storefronts/new`,
          },
        ]}
        overrideHelpLinkKey="storefronts"
      >
        {hasStorefronts ? (
          storefrontList.map((storefront) => this.renderStorefront(storefront))
        ) : (
          <CollectionEmpty
            emptyTitle="Set up a storefront"
            emptyDescription="Create a storefront to connect your frontend"
            emptyAction="New storefront"
            uri="/storefronts"
          />
        )}
      </View>
    );
  }
}
