import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils';

class SummaryMetricBox extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    field: PropTypes.shape({
      checked: PropTypes.bool,
      id: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
    values: PropTypes.array.isRequired,
    currency: PropTypes.string,
  };

  renderBoxValue(value) {
    const {
      field: { type },
      currency,
    } = this.props;

    switch (type) {
      case 'currency':
        return (
          <div className="field-value">
            <div className="currency">{formatCurrency(value, currency)}</div>
          </div>
        );
      default:
        return (
          <div className="field-value">
            <div className="number">{value}</div>
          </div>
        );
    }
  }

  render() {
    const { label, values } = this.props;

    const value = values.reduce((i, j) => i + j, 0);

    return (
      <div className="summary-field-container">
        <div className="field-title">{label}</div>
        {this.renderBoxValue(value)}
      </div>
    );
  }
}

export default SummaryMetricBox;
