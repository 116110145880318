import React from 'react';
import pt from 'prop-types';

import Alert from 'components/alert';
import { Form, Field, Button } from 'components/form';

export default class OrderNotes extends React.PureComponent {
  static propTypes = {
    rows: pt.number,
    type: pt.string,
    label: pt.string,
    deprecated: pt.bool,
    placeholder: pt.string,
    buttonLabel: pt.string,
    clearOnSubmit: pt.bool,

    onSubmit: pt.func.isRequired,
    // for unit testing
    onChange: pt.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: this.getDefaultValue(props),
      edited: false,
    };
  }

  getDefaultValue(props = this.props) {
    const { record, defaultValue } = props;
    return defaultValue !== null ? record.notes : '';
  }

  onChangeForm = (values, edited) => {
    this.setState({ edited, value: values.notes });

    const { onChange } = this.props;

    if (onChange) {
      onChange(values);
    }
  };

  onSubmitForm = async (values) => {
    const { clearOnSubmit } = this.props;
    await this.props.onSubmit(values);
    this.setState({ edited: false, ...(clearOnSubmit && { value: '' }) });
  };

  render() {
    const { label, placeholder, deprecated, type, buttonLabel } = this.props;
    const { edited, value } = this.state;

    return (
      <Form
        className="order-notes"
        onSubmit={this.onSubmitForm}
        onChange={this.onChangeForm}
      >
        {deprecated && (
          <Alert type="warning" className="tight">
            {`${label} are deprecated. We suggest using Activity notes instead.`}
          </Alert>
        )}

        <Field
          type={type || 'textarea'}
          name="notes"
          label={label}
          placeholder={placeholder || 'Write something'}
          value={value}
          defaultValue={this.getDefaultValue()}
          autoSize={true}
          rows={this.props.rows || 3}
          help="Notes are not visible to the customer"
        />

        <Button
          type="submit"
          size="sm"
          disabled={!edited}
          styleType={edited ? 'default' : 'secondary'}
        >
          {buttonLabel || 'Save notes'}
        </Button>
      </Form>
    );
  }
}
