import { get, reduce, isEmpty } from 'lodash';
import * as CSV from 'csv-string';

import {
  formatDate,
  locationWithQuery,
  snakeCase,
  getClientLocaleAndCurrencyParams,
} from 'utils';
import {
  populateContentLookupValues,
  assignContentValuesForUpdate,
  contentValuesWithLocationData,
} from 'utils/content';

import api, { getLocalizedParams } from '../services/api';

import flash from './flash';
import content from './content';

const DEFAULT_LIMIT = 50;
const BULK_PAGE_LIMIT = 100;

export const DATA_FETCH_COLLECTION = 'data/fetchCollection';
export const DATA_FETCH_COLLECTION_META = 'data/fetchCollectionMeta';
export const DATA_FETCH_COLLECTION_COUNT = 'data/fetchCollectionCount';
export const DATA_FETCH_RECORD = 'data/fetchRecord';
export const DATA_FETCH_RELATED = 'data/fetchRelated';
export const DATA_PREFETCH_RELATED = 'data/prefetchRelated';
export const DATA_CLEAR_RECORD = 'data/clearRecord';
export const DATA_CLEAR_ERRORS = 'data/clearErrors';
export const DATA_CLEAR_QUERY = 'data/clearQuery';
export const DATA_UPDATE_RECORD = 'data/updateRecord';
export const DATA_UPDATE_FETCH_RECORD = 'data/updateFetchRecord';
export const DATA_CREATE_RECORD = 'data/createRecord';
export const DATA_DELETE_RECORD = 'data/deleteRecord';
export const DATA_SELECT_ALL = 'data/selectAll';
export const DATA_SELECT_ONE = 'data/selectOne';
export const DATA_SELECT_CLEAR = 'data/selectClear';
export const DATA_BULK_START = 'data/bulkStart';
export const DATA_BULK_PROGRESS = 'data/bulkProgress';
export const DATA_BULK_CANCEL = 'data/bulkCancel';
export const DATA_BULK_COMPLETE = 'data/bulkComplete';
export const DATA_INTEGRATION_SYNC_WATCH = 'data/integrationSyncWatch';
export const DATA_INTEGRATION_SYNC_PROCESSING =
  'data/integrationSyncProcessing';
export const DATA_INTEGRATION_SYNC_FAILED = 'data/integrationSyncFailed';
export const DATA_INTEGRATION_SYNC_COMPLETE = 'data/integrationSyncComplete';
export const DATA_LOADING = 'data/loading';
export const DATA_LOADING_RELATED = 'data/loadingRelated';
export const DATA_PATCH_RECORD = 'data/patchRecord';

function fillBatchSubRequest(batch, method, data) {
  batch = { ...batch };

  if (!batch.method) {
    batch.method = method;
  }

  if (data) {
    if (!batch.data) {
      batch.data = {};
    }

    Object.assign(batch.data, data);
  }

  return batch;
}

function fillBatchData(batch, method, data) {
  if (Array.isArray(batch)) {
    return batch.map((query) => fillBatchSubRequest(query, method, data));
  }

  batch = { ...batch };

  for (const [key, query] of Object.entries(batch)) {
    batch[key] = fillBatchSubRequest(query, method);
  }

  Object.assign(batch, data);

  return batch;
}

/**
 * this function replaces the content model name if this is a draft order:
 * we show the "orders" content for draft orders when model is "carts".
 * therefore, we should update the "orders" content.
 * @param {string} model - model name
 * @param {object} record - current record
 * @returns {string} - content model name to update
 */
function getRecordContentModel(model, record) {
  if (model === 'carts' && record?.draft) {
    return 'orders';
  }

  return model;
}

const actions = {
  fetchCollection(
    model,
    query = {},
    locationQuery = {},
    filterQuery = {},
    endpoint = undefined,
  ) {
    return (dispatch) => {
      query.limit =
        query.limit ||
        window.localStorage.getItem(`${model}_per_page`) ||
        DEFAULT_LIMIT;

      const meta = { model, query: { ...query }, locationQuery, filterQuery };

      dispatch({
        type: DATA_FETCH_COLLECTION_META,
        payload: meta,
      });

      return dispatch({
        type: DATA_FETCH_COLLECTION,
        payload: endpoint
          ? api.get(endpoint, query)
          : api.post(`/data/$get/${model}`, query),
        meta: meta,
        showLoading: [':logs'].includes(model) ? false : true,
      });
    };
  },

  getCollectionSeriesQuery(queryModel) {
    return (_dispatch, getState) => {
      const {
        data: { model, filterQuery },
      } = getState();
      if (model !== queryModel) {
        return {};
      }
      return filterQuery;
    };
  },

  fetchCollectionCount(model, endpoint = undefined, baseQuery = {}) {
    return {
      type: DATA_FETCH_COLLECTION_COUNT,
      payload: api.get(endpoint || `/data/${model}/:count`, baseQuery),
    };
  },

  fetchRecord(model, id, query = {}) {
    return async (dispatch) => {
      return dispatch({
        type: DATA_FETCH_RECORD,
        payload: api
          .getLocalized(`/data/${model}/${id}`, {
            ...query,
          })
          .then(async (record) => {
            if (!record && global.router?.didChangeEnvironment) {
              this.redirectToCollectionList(global.router);
            }
            await dispatch(this.fetchRecordContent(model, record));
            return record;
          }),
        meta: { model, id, query },
      });
    };
  },

  redirectToCollectionList(router) {
    const { id } = router.params;
    const nextUrl = router.location.pathname.replace(
      new RegExp(`/${id}.*`),
      '',
    );
    router.replace(nextUrl);
  },

  fetchRelated(relatedId, relatedQuery) {
    if (isEmpty(relatedQuery)) {
      return Promise.resolve(Array.isArray(relatedQuery) ? [] : {});
    }

    return (dispatch) => {
      dispatch(this.loadingRelated(true));
      dispatch({
        type: DATA_PREFETCH_RELATED,
        meta: { relatedId },
      });
      return dispatch({
        type: DATA_FETCH_RELATED,
        payload: api.post(
          '/data/:batch',
          fillBatchData(relatedQuery, 'get', getLocalizedParams(relatedQuery)),
        ),
        meta: { relatedId, showLoading: false },
      }).then((result) => {
        dispatch(this.loadingRelated(false));
        return result;
      });
    };
  },

  clearRecord() {
    return {
      type: DATA_CLEAR_RECORD,
    };
  },

  clearErrors() {
    return {
      type: DATA_CLEAR_ERRORS,
    };
  },

  clearQuery() {
    return {
      type: DATA_CLEAR_QUERY,
    };
  },

  loading(payload) {
    return { type: DATA_LOADING, payload };
  },

  loadingRelated(payload) {
    return { type: DATA_LOADING_RELATED, payload };
  },

  updateRecord(model, id, data, updateContent = true) {
    return async (dispatch) => {
      const update = updateContent
        ? await dispatch(this.updateRecordContent(model, id, data))
        : data;
      return dispatch({
        type: DATA_UPDATE_RECORD,
        payload: api.put(`/data/${model}/${id}`, update),
        meta: { model, id },
      });
    };
  },

  updateFetchRecord(model, id, data) {
    return async (dispatch, getState) => {
      const { query } = getState().data;
      const update = await dispatch(this.updateRecordContent(model, id, data));
      return dispatch({
        type: DATA_UPDATE_FETCH_RECORD,
        payload: api
          .put(`/data/${model}/${id}`, update)
          .then((result) => {
            if (result && result.errors) {
              return result;
            }
            return api.getLocalized(`/data/${model}/${id}`, query);
          })
          .then(async (record) => {
            await dispatch(this.fetchRecordContent(model, record));
            return record;
          }),
        meta: { model, id },
      });
    };
  },

  patchRecord(payload) {
    return async (dispatch) => {
      return dispatch({
        type: DATA_PATCH_RECORD,
        payload,
      });
    };
  },

  fetchRecordContent(model, record) {
    return async (dispatch, getState) => {
      const { superAdmin } = getState().user;
      if (!record || superAdmin) return record;
      const contentModels = await dispatch(
        content.getModelsByCollection(model),
      );
      if (contentModels.length > 0) {
        await populateContentLookupValues(record, contentModels);
      }
    };
  },

  fetchIncludedContent(models, result) {
    return async (dispatch, getState) => {
      const { superAdmin } = getState().user;
      if (superAdmin) return;
      const promises = [];
      for (const model of models) {
        await dispatch(content.getModelsByCollection(model));
        if (result[model] && result[model].results) {
          promises.push(
            Promise.all(
              result[model].results.map((record) =>
                dispatch(this.fetchRecordContent(model, record)),
              ),
            ),
          );
        }
      }
      await Promise.all(promises);
    };
  },

  updateRecordContent(model, id, data) {
    return async (dispatch, getState) => {
      const { superAdmin } = getState().user;

      if (!data || superAdmin) return data;

      const { record } = getState().data;
      const contentModel = getRecordContentModel(model, record);

      const contentModels = await dispatch(
        content.getModelsByCollection(contentModel),
      ).then((models) => models.filter((model) => !model.standard));

      if (contentModels.length > 0) {
        const { model: exModel } = getState().data;
        const updateRecord =
          contentModel === exModel
            ? record
            : get(record && record[contentModel], 'results', []).find(
                (rec) => rec.id === id,
              );
        const contentData = assignContentValuesForUpdate(
          data,
          updateRecord,
          contentModels,
        );
        return contentData;
      }
      return data;
    };
  },

  createRecordContent(model, data) {
    return async (dispatch, getState) => {
      const { superAdmin } = getState().user;
      if (!data || superAdmin) return data;

      const modelsByCollection = await dispatch(
        content.getModelsByCollection(model),
      );
      const contentModels = modelsByCollection.filter(
        (model) => !model.standard,
      );

      if (contentModels.length > 0) {
        const newData = assignContentValuesForUpdate(
          data,
          undefined,
          contentModels,
        );
        return {
          ...newData,
          ...newData.$set,
          $set: undefined,
        };
      }
      return data;
    };
  },

  getValuesWithData(model, data) {
    return async (dispatch) => {
      const values = { ...(data || undefined) };
      if (data) {
        const contentModels = await dispatch(
          content.getModelsByCollection(model),
        );
        if (contentModels.length > 0) {
          await populateContentLookupValues(values, contentModels);
        }
      }
      return values;
    };
  },

  getValuesWithLocationData(model, location) {
    return async (dispatch) => {
      const values = contentValuesWithLocationData(location);
      if (location?.query?.record) {
        const contentModels = await dispatch(
          content.getModelsByCollection(model),
        );
        if (contentModels.length > 0) {
          await populateContentLookupValues(values, contentModels);
        }
      }
      return values;
    };
  },

  createRecord(model, data) {
    return async (dispatch) => {
      const newData = await dispatch(this.createRecordContent(model, data));
      return dispatch({
        type: DATA_CREATE_RECORD,
        payload: api.post(`/data/${model}`, newData),
        meta: { model },
      });
    };
  },

  deleteRecord(model, id) {
    return {
      type: DATA_DELETE_RECORD,
      payload: api.delete(`/data/${model}/${id}`, { $force_delete: true }),
      meta: { model },
    };
  },

  selectAll() {
    return {
      type: DATA_SELECT_ALL,
    };
  },

  selectOne(id, isRange) {
    return {
      type: DATA_SELECT_ONE,
      payload: id,
      meta: { isRange },
    };
  },

  selectClear() {
    return {
      type: DATA_SELECT_CLEAR,
    };
  },

  integrationSyncFailed(params) {
    return {
      type: DATA_INTEGRATION_SYNC_FAILED,
      payload: params,
    };
  },

  integrationSyncWatch(integration, watch = true) {
    return {
      type: DATA_INTEGRATION_SYNC_WATCH,
      payload: integration,
      meta: {
        watch,
      },
    };
  },

  integrationSyncProcessing(integration, message) {
    return {
      type: DATA_INTEGRATION_SYNC_PROCESSING,
      payload: integration,
      meta: { message },
    };
  },

  integrationSyncComplete(integration, message) {
    return {
      type: DATA_INTEGRATION_SYNC_COMPLETE,
      payload: integration,
      meta: {
        message,
      },
    };
  },

  bulkStart(params) {
    return {
      type: DATA_BULK_START,
      payload: params,
    };
  },

  bulkProgress(percent, message) {
    return {
      type: DATA_BULK_PROGRESS,
      payload: percent,
      meta: { message },
    };
  },

  bulkCancel() {
    return {
      type: DATA_BULK_CANCEL,
      payload: null,
    };
  },

  bulkComplete(result) {
    return {
      type: DATA_BULK_COMPLETE,
      payload: result,
    };
  },

  bulkExport(model, params) {
    return (dispatch, getState) => {
      if (getState().data.bulk.running) {
        return;
      }

      dispatch(this.bulkStart({ model, ...params }));

      return runExport({
        getState,
        model,
        params,
        onProgress: (percent, message) => {
          dispatch(this.bulkProgress(percent, message));
        },
        onCancel: () => {
          dispatch(this.bulkCancel());
        },
        onComplete: (result) => {
          dispatch(this.bulkComplete(result));
        },
        onError: (message) => {
          dispatch(this.bulkCancel());
          dispatch(flash.error(message));
        },
      });
    };
  },

  bulkImport(model, params) {
    return (dispatch, getState) => {
      if (getState().data.bulk.running) {
        return;
      }

      dispatch(this.bulkStart({ model, ...params }));

      return runImport({
        getState,
        model,
        params,
        onProgress: (percent, message) => {
          dispatch(this.bulkProgress(percent, message));
        },
        onCancel: () => {
          dispatch(this.bulkCancel());
        },
        onComplete: (result) => {
          dispatch(this.bulkComplete(result));
        },
        onError: (message) => {
          dispatch(this.bulkCancel());
          dispatch(flash.error(message));
        },
      });
    };
  },

  bulkUpdateSelected(model, handler) {
    return (dispatch, getState) => {
      if (getState().data.bulk.running) {
        return;
      }

      dispatch(this.bulkStart({ model, handler }));

      return runUpdateSelected({
        getState,
        model,
        handler,
        onProgress: (percent, message) => {
          dispatch(this.bulkProgress(percent, message));
        },
        onCancel: () => {
          dispatch(this.bulkCancel());
        },
        onComplete: (result) => {
          dispatch(this.bulkComplete(result));
        },
        onError: (message) => {
          dispatch(this.bulkCancel());
          dispatch(flash.error(message));
        },
      });
    };
  },

  bulkDeleteSelected(model, query = undefined, bulkParams = undefined) {
    return (dispatch, getState) => {
      if (getState().data.bulk.running) {
        return;
      }

      dispatch(this.bulkStart({ model }));

      return runDeleteSelected({
        getState,
        model,
        query,
        bulkParams,
        onProgress: (percent, message) => {
          dispatch(this.bulkProgress(percent, message));
        },
        onCancel: () => {
          dispatch(this.bulkCancel());
        },
        onComplete: (result) => {
          dispatch(this.selectClear());
          dispatch(this.bulkComplete(result));
        },
        onError: (message) => {
          dispatch(this.bulkCancel());
          dispatch(flash.error(message));
        },
      });
    };
  },

  bulkGenerateCouponCodes(couponId, count) {
    return async (dispatch, getState) => {
      if (getState().data.bulk.running) {
        return;
      }

      dispatch(this.bulkStart({ couponId, count }));

      const gen = await api.post(`/data/coupons/${couponId}/generations`, {
        count,
      });

      return runCouponCodeGenerationProgress(gen, {
        onProgress: (percent, message) => {
          dispatch(this.bulkProgress(percent, message));
        },
        onComplete: (result) => {
          dispatch(this.bulkComplete(result));
        },
        onError: (message) => {
          dispatch(this.bulkCancel());
          dispatch(flash.error(message));
        },
      });
    };
  },

  startIntegrationSync(integrationId) {
    if (!integrationId) {
      return flash.error('Provide integration ID');
    }

    return async (dispatch, getState) => {
      const event = await api.post('/data/events', {
        data: {
          id: integrationId,
        },
        model: ':webhooks',
        type: 'integration.sync_processing',
      });

      if (!event) {
        dispatch(flash.error('Unable to start sync process'));
        return;
      } else if (event.errors) {
        dispatch(flash.error(Object.keys(event.errors)[0].message));
        return;
      } else if (event.error) {
        dispatch(flash.error(event.error));
        return;
      }

      dispatch(this.integrationSyncProcessing(integrationId));
      dispatch(this.integrationSyncWatch(integrationId, true));

      return runIntegrationProgressCheck(integrationId, {
        getState,
        onProcessing: () => {
          dispatch(this.integrationSyncProcessing(integrationId));
        },
        onComplete: () => {
          dispatch(this.integrationSyncComplete(integrationId));
        },
        onError: (message) => {
          dispatch(this.integrationSyncFailed(integrationId));
          dispatch(flash.error(message));
        },
      });
    };
  },

  watchIntegrationSync(integrationId, watch = true) {
    return async (dispatch, getState) => {
      const { integrationSync } = getState().data;
      dispatch(this.integrationSyncWatch(integrationId, watch));
      if (watch && !integrationSync[integrationId].watch) {
        return runIntegrationProgressCheck(integrationId, {
          getState,
          onProcessing: () => {
            dispatch(this.integrationSyncProcessing(integrationId));
          },
          onComplete: () => {
            dispatch(this.integrationSyncComplete(integrationId));
          },
          onError: (message) => {
            dispatch(this.integrationSyncFailed(integrationId));
            dispatch(flash.error(message));
          },
        });
      }
    };
  },

  bulkGenerateGiftCards(productId, counts, description) {
    return async (dispatch, getState) => {
      if (getState().data.bulk.running) {
        return;
      }

      const actualCounts = counts
        .map((count) => count && count.count > 0 && count.amount > 0 && count)
        .filter((count) => count);

      dispatch(this.bulkStart({ productId, counts }));

      try {
        await Promise.all(
          actualCounts.map((count) =>
            api.post(`/data/giftcards`, {
              $bulk_count: count.count,
              amount: count.amount,
              $currency: count.$currency,
              product_id: productId,
              bulk_description: description,
            }),
          ),
        );
        dispatch(this.bulkComplete({}));
      } catch (err) {
        dispatch(this.bulkCancel());
        dispatch(flash.error(err.message));
      }

      dispatch(this.bulkComplete({}));
    };
  },
};

appendChildActions();

export default actions;

export const initialState = {
  model: null,
  collection: null,
  collectionCount: null,
  query: {},
  locationQuery: null,
  filterQuery: null,
  related: {},
  relatedId: null,
  record: null,
  recordErrors: {},
  selection: {
    all: false,
    records: {},
    except: {},
    count: 0,
    lastId: null,
  },
  bulk: {
    params: {},
    running: false,
    percent: 0,
    complete: false,
    result: {},
    message: undefined,
  },
  integrationSync: {
    // [integration]: { status, watch }
  },
  loading: false,
  loadingRelated: false,
  loadingCollection: false,

  // Child collections
  children: {},
  initialState() {
    return { ...initialState };
  },
};

export function reducer(state = initialState, action) {
  const childId = action.meta?.childId;
  if (childId) {
    const nextState = {
      ...state,
      children: {
        ...state.children,
        [childId]: reduceState(state.children[childId], action),
      },
    };
    const loadingCollection = reduce(
      nextState.children,
      (acc, childState) => acc || childState.loadingCollection,
      false,
    );
    return {
      ...nextState,
      loadingCollection,
    };
  }
  return reduceState(state, action);
}

function reduceState(state = initialState, action) {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case DATA_FETCH_COLLECTION:
      const result = action.payload;
      if (action.meta === undefined) {
        return state;
      }
      if (state.model !== action.meta.model) {
        console.warn(
          `DATA_FETCH_COLLECTION expected '${state.model}' but received '${action.meta.model}'`,
        );
        return state;
      }
      return {
        ...state,
        ...action.meta,
        loadingCollection: false,
        collection: {
          ...result,
          totalPages: Object.keys(result?.pages || {}).length,
          limit: action.meta.query.limit,
        },
        query: {
          ...(action.meta.query || {}),
        },
        selection: {
          ...state.selection,
          count: getSelectionCount(state.selection, result),
        },
      };

    case DATA_FETCH_COLLECTION_META:
      return {
        ...state,
        ...action.payload,
        ...(state.model !== action.payload.model
          ? {
              query: {},
              selection: {
                ...initialState.selection,
              },
            }
          : {}),
        loadingCollection: true,
      };

    case DATA_FETCH_COLLECTION_COUNT:
      return {
        ...state,
        collectionCount: action.payload,
      };

    case DATA_FETCH_RECORD:
      return {
        ...state,
        ...action.meta,
        record: action.payload,
        recordErrors: {},
      };

    case DATA_PREFETCH_RELATED: {
      const relatedId = action.meta?.relatedId ?? null;

      return {
        ...state,
        related:
          !relatedId || relatedId !== state.relatedId ? {} : state.related,
        relatedId,
      };
    }

    case DATA_FETCH_RELATED:
      if (!state.record || !action.payload) {
        return state;
      }
      if (action.meta.relatedId !== state.relatedId) {
        return state;
      }
      return {
        ...state,
        related: {
          ...state.related,
          ...action.payload,
        },
      };

    case DATA_CLEAR_RECORD:
      return {
        ...state,
        record: null,
        recordErrors: {},
        related: {},
        relatedId: null,
      };

    case DATA_CLEAR_ERRORS:
      return {
        ...state,
        recordErrors: {},
      };

    case DATA_CLEAR_QUERY:
      return {
        ...state,
        query: {},
      };

    case DATA_CREATE_RECORD:
    case DATA_UPDATE_RECORD:
      if (action.payload && action.payload.errors) {
        return {
          ...state,
          recordErrors: action.payload.errors,
        };
      }
      return state;

    case DATA_UPDATE_FETCH_RECORD:
      if (action.payload && action.payload.errors) {
        return {
          ...state,
          recordErrors: action.payload.errors,
        };
      }
      return {
        ...state,
        record: action.payload,
      };

    case DATA_DELETE_RECORD:
      if (action.payload && action.payload.errors) {
        return {
          ...state,
          recordErrors: action.payload.errors,
          selection: {
            ...initialState.selection,
          },
        };
      }
      return state;

    case DATA_SELECT_ALL:
      return {
        ...state,
        selection: {
          all: true,
          records: {},
          except: {},
          count: state.collection.count,
          lastId: null,
        },
      };

    case DATA_SELECT_ONE:
      if (!action.payload) {
        return state;
      }
      const id = action.payload;
      const sel = state.selection;
      const { all } = sel;
      let records = { ...sel.records };
      let except = { ...sel.except };
      let range;
      if (action.meta.isRange && sel.lastId) {
        range = getSelectionRange(sel.lastId, id, state.collection);
      }
      if (all) {
        if (except[id]) {
          delete except[id];
          if (range) {
            for (const key in range) {
              delete except[key];
            }
          }
        } else {
          except[id] = true;
          if (range) {
            except = { ...except, ...range };
          }
        }
      } else {
        if (records[id]) {
          delete records[id];
          if (range) {
            for (const key in range) {
              delete records[key];
            }
          }
        } else {
          records[id] = true;
          if (range) {
            records = { ...records, ...range };
          }
        }
      }
      return {
        ...state,
        selection: {
          all,
          records,
          except,
          count: getSelectionCount({ all, records, except }, state.collection),
          lastId: id,
        },
      };

    case DATA_SELECT_CLEAR:
      return {
        ...state,
        selection: {
          all: false,
          records: {},
          except: {},
          count: 0,
          lastId: null,
        },
      };

    case DATA_BULK_START:
      return {
        ...state,
        bulk: {
          ...initialState.bulk,
          running: true,
          params: action.payload,
        },
      };

    case DATA_INTEGRATION_SYNC_WATCH: {
      const status = get(state.integrationSync[action.payload], 'status');
      return {
        ...state,
        integrationSync: {
          ...state.integrationSync,
          [action.payload]: {
            ...state.integrationSync[action.payload],
            status: action.meta.watch ? status : null,
            watch: action.meta.watch,
          },
        },
      };
    }

    case DATA_INTEGRATION_SYNC_PROCESSING: {
      return {
        ...state,
        integrationSync: {
          ...state.integrationSync,
          [action.payload]: {
            ...state.integrationSync[action.payload],
            status: 'processing',
          },
        },
      };
    }

    case DATA_INTEGRATION_SYNC_COMPLETE: {
      return {
        ...state,
        integrationSync: {
          ...state.integrationSync,
          [action.payload]: {
            ...state.integrationSync[action.payload],
            status: 'complete',
            watch: false,
          },
        },
      };
    }

    case DATA_INTEGRATION_SYNC_FAILED: {
      return {
        ...state,
        integrationSync: {
          ...state.integrationSync,
          [action.payload]: {
            ...state.integrationSync[action.payload],
            status: 'failed',
          },
        },
      };
    }

    case DATA_BULK_PROGRESS:
      return {
        ...state,
        bulk: {
          ...state.bulk,
          percent: get(action, 'payload', state.bulk.percent),
          message: get(action.meta, 'message', state.bulk.message),
        },
      };

    case DATA_BULK_CANCEL:
      return {
        ...state,
        bulk: {
          ...initialState.bulk,
        },
      };

    case DATA_BULK_COMPLETE:
      return {
        ...state,
        bulk: {
          ...state.bulk,
          running: false,
          complete: true,
          percent: 100,
          result: action.payload,
        },
      };

    case DATA_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case DATA_LOADING_RELATED:
      return {
        ...state,
        loadingRelated: action.payload,
      };

    case DATA_PATCH_RECORD:
      return {
        ...state,
        record: { ...state.record, ...action.payload },
      };

    default:
      return state;
  }
}

function dispatchChildAction(childId, method, args) {
  return (dispatch, getState) => {
    const childDispatch = (action) => {
      return dispatch({
        ...action,
        meta: { ...(action.meta || {}), childId },
      });
    };
    const childGetState = () => {
      const state = getState();
      return { ...state, data: state.children[childId] };
    };
    const action = method.call(actions, ...args);
    if (typeof action === 'function') {
      return action(childDispatch, childGetState);
    }
    return childDispatch(action);
  };
}

// Appends actions to handle child scoped dispatching
function appendChildActions() {
  const actionNames = Object.keys(actions);
  for (const name of actionNames) {
    const childName = `child${name[0].toUpperCase()}${name.slice(1)}`;
    actions[childName] = function (childId, ...args) {
      return dispatchChildAction(childId, actions[name], args);
    };
    Object.defineProperty(actions[childName], 'name', {
      value: childName,
    });
  }
}

function getSelectionCount(sel, coll) {
  if (sel.all) {
    return coll.count - Object.keys(sel.except).length;
  } else {
    return Object.keys(sel.records).length;
  }
}

function getSelectionRange(fromId, toId, coll) {
  const range = {};
  let inRange = false;
  for (const record of coll.results) {
    if (record.id === fromId || record.id === toId) {
      if (inRange) {
        range[record.id] = true;
        break;
      }
      inRange = true;
    }
    if (inRange) {
      range[record.id] = true;
    }
  }
  return range;
}

export function replaceLocationQuery(store, nextState, replace) {
  const { data } = store.getState();
  const { BASE_URI } = process.env;
  if (data.locationQuery) {
    if (!nextState.location.query) {
      let nextUri = locationWithQuery(nextState.location, data.locationQuery);
      if (nextUri.indexOf(BASE_URI) === 0) {
        nextUri = nextUri.replace(BASE_URI, '');
      }
      replace(nextUri);
      return true;
    } else {
      // TODO: we used to compare data.locationQuery with nextState.location.query, failed in some cases
    }
  }
  return false;
}

// Bulk data export
function runExport(args, data = []) {
  const {
    getState,
    model,
    params,
    onCancel,
    onError,
    lastId = null,
    page = 1,
    totalCount = 0,
    retries = 0,
  } = args;

  const query = getExportQuery(params, getState, lastId, page);

  if (!query) {
    onError('There is nothing to export');
    return;
  }

  return api[params.method || 'get'](`/data/${model}`, { ...query })
    .then((result) => {
      // May be no results
      if (!result.count && !data.length) {
        onError('There is nothing to export');
        return;
      }
      // May be canceled while running
      if (getState().data.bulk.running !== true) {
        onCancel();
        return;
      }
      // Update data from result
      handleExportData(args, result, data);
      // Update progress state
      const resultCount = totalCount || result.count;
      handleExportProgress(args, resultCount, data);
      // Complete when pages run out
      if (result.results.length === 0) {
        handleExportComplete(args, data);
        return;
      }
      // Next page
      const lastId = result.results[result.results.length - 1].id;
      return runExport(
        {
          ...args,
          totalCount: resultCount,
          page: page + 1,
          lastId,
          retries: 0,
        },
        data,
      );
    })
    .catch((err) => {
      console.error(err);
      if (retries > 10) {
        onError(err.message);
        return;
      }
      return runExport({ ...args, retries: retries + 1 }, data);
    });
}

function getExportQuery(params, getState, lastId = null, page = 1) {
  const { format, query: paramsQuery = {} } = params;

  if (format !== 'csv') {
    paramsQuery.expand = [];
  }

  const { client, data } = getState();

  let query = {
    ...getClientLocaleAndCurrencyParams(
      client,
      params.allLocales,
      params.allCurrencies,
    ),
    limit: BULK_PAGE_LIMIT,
    ...paramsQuery,
    sort: {
      id: -1,
      ...paramsQuery.sort,
    },
    ...(lastId
      ? paramsQuery.conditions
        ? {
            conditions: {
              $and: [
                ...(paramsQuery.conditions.$and || []),
                { id: { $lt: lastId } },
              ],
            },
          }
        : { $and: [...(paramsQuery.$and || []), { id: { $lt: lastId } }] }
      : params.page
      ? {
          page: page,
        }
      : undefined),
  };

  switch (params.type) {
    case 'page': {
      query.$and = [
        ...(query.$and || []),
        { id: { $in: data.collection.results.map((result) => result.id) } },
      ];
      break;
    }

    case 'search': {
      query = {
        ...data.filterQuery,
        ...query,
        search: data.query.search,
        where: {
          ...(query.where || {}),
          ...(data.query.where || {}),
        },
      };
      break;
    }

    case 'date': {
      query.$and = [
        ...(query.$and || []),
        { date_created: { $gte: params.date_start } },
        { date_created: { $lte: params.date_end } },
      ];
      break;
    }

    case 'selected': {
      // Includes search
      query.search = data.query.search;
      query.where = {
        ...(query.where || {}),
        ...(data.query.where || {}),
      };
      if (data.selection.all) {
        const exceptIds = Object.keys(data.selection.except);
        if (exceptIds.length) {
          query.$and = [...(query.$and || []), { id: { $nin: exceptIds } }];
        }
      } else {
        const recordIds = Object.keys(data.selection.records);
        if (recordIds.length) {
          query.$and = [...(query.$and || []), { id: { $in: recordIds } }];
        } else {
          return;
        }
      }
      break;
    }

    case 'all':
    default:
      break;
  }

  return query;
}

function handleExportProgress({ onProgress, params }, resultCount, data) {
  const dataCount = params.onCountRowsExported
    ? params.onCountRowsExported(data)
    : data.length;
  let percent = Math.ceil((dataCount / resultCount) * 100).toFixed(0);
  if (percent > 100) {
    percent = 100;
  }
  onProgress(percent);
}

function handleExportData({ params }, result, data) {
  result.results.forEach((result) => {
    switch (params.format) {
      case 'json': {
        data.push(generateJSONRows(result, params));
        break;
      }

      case 'csv':
      default: {
        // CSV header first
        if (data.length === 0) {
          data.push(params.csvFields.map((field) => field.label));
        }

        data.push(...generateCSVRows(result, params));
        break;
      }
    }
  });
}

function handleExportComplete({ getState, params, onComplete }, data = []) {
  let finalData;
  let filename;
  let mimetype;
  let extype;

  const { client } = getState();
  const now = Date.now();

  switch (params.type) {
    case 'page':
      extype = `current-page-${formatDate(now, 'isoDate')}`;
      break;
    case 'search':
      extype = `current-search-${formatDate(now, 'isoDate')}`;
      break;
    case 'date':
      extype = `by-date-${formatDate(
        params.date_start,
        'isoDate',
      )}-to-${formatDate(params.date_end, 'isoDate')}`;
      break;
    case 'selected':
      extype = `selected-${formatDate(now, 'isoDate')}`;
      break;
    default:
    case 'all':
      extype = `all-${formatDate(now, 'isoDate')}`;
      break;
  }

  const filenameId = params.filename || snakeCase(params.model);

  switch (params.format) {
    case 'json':
      filename = `${client.id}-${filenameId}-${extype}.json`;
      mimetype = 'application/json';
      finalData = `${JSON.stringify(data)}`;
      break;
    case 'csv':
    default:
      filename = `${client.id}-${filenameId}-${extype}.csv`;
      mimetype = 'text/csv';
      finalData = CSV.stringify(data);
      break;
  }

  onComplete({
    data: finalData,
    filename,
    mimetype,
  });
}

function generateJSONRows(result, params) {
  // Generate values for each JSON field
  params.csvFields.forEach((field) => {
    try {
      if (result[field.key] && typeof field.export === 'function') {
        result[field.key] = field.export(result, params.format);
      }
    } catch (err) {
      // Oops
      console.warn(err);
    }
  });

  return result;
}

function generateCSVRows(result, params) {
  // Generate values for each CSV field
  const values = params.csvFields.map((field) => {
    let value;

    try {
      if (typeof field.export === 'function') {
        value = field.export(result, params.format);
      } else if (field.value !== undefined) {
        value = field.value;
      } else {
        value = result[field.key];
      }
    } catch (err) {
      // Oops
      console.warn(err);
    }

    return value;
  });

  // Generate row matrix from values
  const rows = [[]];

  values.forEach((value, topIndex) => {
    if (Array.isArray(value)) {
      value.forEach((val, valIndex) => {
        if (rows[valIndex] === undefined) {
          rows[valIndex] = Array.from({ length: values.length }).fill('');
        }

        rows[valIndex][topIndex] = val !== undefined ? val : '';
      });
    } else {
      rows[0].push(value !== undefined ? value : '');
    }
  });

  return rows;
}

// Bulk query from selection
function getBulkQuery(getState, sortKey, lastId = null) {
  const {
    data: { selection, query },
  } = getState();

  const bulkQuery = {
    ...query,
    sort: `${sortKey} desc`,
    limit: null,
    page: 1,
  };

  if (lastId) {
    bulkQuery[sortKey] = { $lt: lastId };
  }

  if (selection.all) {
    const exceptIds = Object.keys(selection.except);

    if (exceptIds.length > 0) {
      bulkQuery.$and = [{ id: { $nin: exceptIds } }];
    }
  } else {
    const recordIds = Object.keys(selection.records);

    if (recordIds.length > 0) {
      bulkQuery.$and = [{ id: { $in: recordIds } }];
    } else {
      // Nothing selected
      return;
    }
  }

  return bulkQuery;
}

/**
 * Bulk query handler
 *
 * @param {object} args
 * @param {(pageResult: object) => void} onData
 * @param {(resultCount: number) => any} onFinished
 * @returns {Promise<void>}
 */
function runBulkQuery(args, onData, onFinished) {
  const {
    model,
    bulkParams,
    getState,
    onCancel,
    onComplete,
    onError,
    lastId = null,
    fields = null,
    lastCount = 0,
    totalCount = 0,
    retries = 0,
  } = args;

  const sortKey = (bulkParams ? bulkParams.sortKey : false) || 'id';

  const query = getBulkQuery(getState, sortKey, lastId);

  if (!query) {
    onError('There is nothing selected');
    return;
  }

  const queryArg = args.query || {};

  return api
    .post('/data/:batch', {
      data: {
        method: 'get',
        url: model,
        data: {
          ...query,
          ...queryArg,
          fields,
        },
      },
    })
    .then(({ data: pageResult }) => {
      // May be no results
      if (!lastId && !pageResult.count) {
        onError('There is nothing selected');
        return;
      }

      // May be canceled while running
      if (getState().data.bulk.running !== true) {
        onCancel();
        return;
      }

      // Update progress state
      const resultCount = totalCount || pageResult.count;
      const progressCount = lastCount + pageResult.results.length;

      runBulkUpdateProgress(args, resultCount, progressCount);

      // Update data from result
      return Promise.resolve(pageResult)
        .then(onData)
        .then(() => {
          // Complete when pages run out
          if (pageResult.results.length === 0) {
            if (typeof onFinished === 'function') {
              const result = onFinished(resultCount);
              onComplete(result);
              return result;
            }

            onComplete();
            return;
          }

          // Next page
          return runBulkQuery(
            {
              ...args,
              totalCount: resultCount,
              lastId:
                pageResult.results[pageResult.results.length - 1][sortKey],
              lastCount: progressCount,
              retries: 0,
            },
            onData,
            onFinished,
          );
        });
    })
    .catch((err) => {
      console.error(err);

      if (retries > 10) {
        onError(err.message);
        return;
      }

      return runBulkQuery(
        { ...args, retries: retries + 1 },
        onData,
        onFinished,
      );
    });
}

// Bulk progress handler
function runBulkUpdateProgress({ onProgress }, resultCount, progressCount) {
  let percent = Math.ceil((progressCount / resultCount) * 100);

  if (percent > 100) {
    percent = 100;
  }

  onProgress(percent);
}

// Bulk data import
async function runImport(args) {
  const {
    getState,
    model,
    params,
    onProgress,
    onComplete,
    onCancel,
    onError,
    index = 0,
    errors = [],
    counts = {},
    retries = 0,
  } = args;

  const {
    data,
    csvTransform,
    idTransform,
    updateTransform,
    recordTransform,
    onMessage,
  } = params;

  // Init counts
  counts.succeeded = counts.succeeded || 0;
  counts.updated = counts.updated || 0;
  counts.ignored = counts.ignored || 0;

  // May be canceled while running
  if (getState().data.bulk.running !== true) {
    onCancel();
    return;
  }

  // Perform insert or update
  try {
    const trans = await csvTransform(
      data[index],
      data[index - 1],
      data[index + 1],
    );

    if (csvTransformHasData(trans)) {
      const recordId = await idTransform(
        data[index],
        data[index - 1],
        data[index + 1],
      );

      const importModel = trans.model || model;

      // Ignore when not overwriting
      const existing = !recordId
        ? false
        : await api.get(`/data/${importModel}/{id}`, {
            id: recordId,
          });

      try {
        if (!existing || params.overwrite) {
          trans.data = updateTransform
            ? await updateTransform(existing, trans.data, trans.orig)
            : trans.data;

          const importMethod = existing ? 'put' : 'post';

          const importUrl = existing
            ? `/data/${importModel}/{id}`
            : `/data/${importModel}`;

          if (onMessage) {
            onProgress(undefined, onMessage(trans.data));
          }

          const result = await api[importMethod](importUrl, {
            ...trans.data,
            id: recordId,
            // Hack: $set doesn't work for post
            ...(existing ? {} : trans.data.$set || {}),
          });

          // Track errors
          if (result.errors) {
            errors.push({
              data: trans.data,
              errors: result.errors,
            });
          } else {
            // Assign id to data
            data[index].id = result.id;
            data[index].record = result;

            // Secondary transform
            if (recordTransform) {
              const recordErrors = await recordTransform(
                `/data/${importModel}/{id}`,
                data[index],
                result,
                data[index + 1],
                data[index - 1],
              );

              if (recordErrors) {
                errors.push(...recordErrors);
              }
            }

            counts.succeeded += 1;

            if (existing) {
              counts.updated += 1;
            }
          }
        } else {
          if (existing) {
            data[index].id = existing.id;
            data[index].record = existing;
          }

          counts.ignored += 1;
        }
      } catch (err) {
        console.error(err);

        if (retries > 10) {
          errors.push({
            data: {},
            errors: {
              'Request error': {
                message: err.message || err,
              },
            },
          });
        } else {
          return runImport({ ...args, retries: retries + 1 });
        }
      }
    }

    // Update progress percent
    let percent = Math.ceil(((index + 1) / data.length) * 100).toFixed(0);

    if (percent > 100) {
      percent = 100;
    }

    onProgress(percent);

    // Continue
    if (data.length > index + 1) {
      await new Promise((resolve) => setTimeout(resolve, 1));

      return runImport({
        ...args,
        index: index + 1,
        errors,
        counts,
        retries: 0,
      });
    }

    // Complete
    onComplete({
      errors,
      counts,
    });
  } catch (err) {
    console.error(err);

    if (retries > 10) {
      onError(err.message);
      return;
    }

    return runImport({ ...args, retries: retries + 1 });
  }
}

function csvTransformHasData(trans) {
  if (!trans || !trans.data) {
    return false;
  }
  for (const key in trans.data) {
    if (trans.data[key] && typeof trans.data[key] !== 'object') {
      return true;
    }
  }
  return false;
}

// Bulk update
function runUpdateSelected(args) {
  const { model, handler } = args;
  return runBulkQuery(
    {
      ...args,
      query: {
        limit: 100,
      },
    },
    async (pageResult) => {
      const batchData = [];
      pageResult.results.forEach((record) => {
        const update = handler(record);
        if (update) {
          if (update instanceof Array) {
            update.forEach((up) =>
              batchData.push({
                url: `${model}/${record.id}`,
                ...up,
              }),
            );
          } else {
            batchData.push({
              url: `${model}/${record.id}`,
              ...update,
            });
          }
        }
      });
      if (batchData.length > 0) {
        await api.put('/data/:batch', batchData);
      }
    },
    (resultCount) => resultCount,
  );
}

// Bulk delete
function runDeleteSelected(args) {
  const { model } = args;

  return runBulkQuery(
    {
      ...args,
      query: {
        limit: 100,
        fields: 'id',
      },
    },
    (pageResult) => {
      if (pageResult.results.length > 0) {
        const batchDeletes = pageResult.results.map((record) => ({
          method: 'delete',
          url: `${model}/${record.id}`,
          query: args.query,
        }));

        return api.put('/data/:batch', batchDeletes);
      }
    },
    (resultCount) => {
      return resultCount;
    },
  );
}

// Bulk coupon code generation progress
function runCouponCodeGenerationProgress(gen, args) {
  const { onError, onProgress, onComplete } = args;

  if (!gen) {
    onError('Unable to generate coupon codes');
    return;
  }
  if (gen.errors) {
    onError(Object.keys(gen.errors)[0].message);
    return;
  }
  if (gen.error) {
    onError(gen.error);
    return;
  }

  return new Promise((resolve) => {
    setTimeout(async () => {
      const genUpdated = await api.get('/data/coupons:generations/{id}', {
        id: gen.id,
        expand: 'codes:1',
      });

      const progressPercent = (
        (genUpdated.codes.count / genUpdated.count) *
        100
      ).toFixed(0);
      onProgress(progressPercent);

      if (progressPercent >= 100) {
        onComplete();
        resolve();
        return;
      }

      await runCouponCodeGenerationProgress(genUpdated, args);
      resolve();
    }, 1000);
  });
}

async function runIntegrationProgressCheck(integrationId, args) {
  // May be unwatched while running
  const integrationState = args.getState().data.integrationSync[integrationId];

  if (!integrationState?.watch) {
    return;
  }

  const integrationSetting = await api.get(
    `/data/settings/integrations/services/${integrationId}`,
  );

  switch (integrationSetting.sync_status) {
    case 'complete':
      args.onComplete();
      return;

    case 'failed':
      args.onError(integrationSetting.sync_error);
      return;

    case 'processing':
      args.onProcessing();
      break;

    default:
      break;
  }

  await new Promise((resolve) => {
    setTimeout(resolve, 3000);
  });

  return runIntegrationProgressCheck(integrationId, args);
}
