import React from 'react';
import PropTypes from 'prop-types';
import View from 'components/view';
import { noop } from 'lodash';
import MenuForm from './menu-form';
import { isEmpty } from 'utils';

export default class Menu extends React.Component {
  static propTypes = {
    record: PropTypes.object,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
  };

  static contextTypes = {
    openModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onDelete: noop,
    onEdit: noop,
  };

  state = {
    edited: false,
  };

  formRef = null;

  onEdited = (edited) => {
    this.setState({ edited });
    this.props.onEdited(edited);
  };

  onClickSave = (event) => {
    event.preventDefault();
    if (this.formRef) {
      this.formRef.submit();
    }
  };

  onClickDelete = (event) => {
    event.preventDefault();
    this.context.openModal('ConfirmDelete', {
      title: 'this menu',
      onConfirm: async () => {
        await this.props.onDelete(this.props.record.id);
        this.onEdited(false);
        this.props.onClose();
      },
    });
  };

  render() {
    const { storefront, record = {}, configs = {} } = this.props;

    const { edited } = this.state;

    return (
      <View
        detail={true}
        uri={`/storefronts/${storefront.id}?tab=navigation`}
        sectionTitle="Navigation"
        headerTitle={record.name || 'New menu'}
        headerActions={[
          {
            label: 'Save menu',
            type: edited ? 'default' : 'secondary disabled',
            onClick: this.onClickSave,
          },
        ]}
        extraActions={[
          {
            label: 'Delete menu',
            onClick: this.onClickDelete,
            hidden: isEmpty(record),
            className: 'danger',
            delete: true,
          },
        ]}
      >
        <MenuForm
          {...this.props}
          menuConfigs={configs.editor?.values?.menus}
          onEdited={this.onEdited}
          onRef={(ref) => (this.formRef = ref)}
          edited={edited}
        />
      </View>
    );
  }
}
