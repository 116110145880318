// Settings
export const LOAD_SETTINGS_SUCCESS = 'editor/loadSettingsSuccess';
export const CHANGE_SETTING_LOCAL = 'editor/changeSettingLocal';
export const CHANGE_CHECKOUT_SETTING_LOCAL =
  'editor/changeCheckoutSettingLocal';
// Settings config
export const LOAD_THEME_CONFIG_SUCCESS = 'editor/loadThemeConfigSuccess';
export const SET_THEME_HOME_PAGE = 'editor/setThemeHomePage';

// Templates
export const STATUS_ITEM_CREATE = 'editor/statusItemCreate';
export const STATUS_ITEM_UPDATE = 'editor/statusItemUpdate';
export const STATUS_ITEM_DELETE = 'editor/statusItemDelete';
export const FETCH_PUBLISH_STATUS = 'editor/fetchPublishStatus';
export const UPDATE_PUBLISH_STATUS = 'editor/updatePublishStatus';
export const LOAD_HASHES = 'editor/loadHashes';
export const CHANGE_MULTIPLE_USERS_MODE = 'editor/changeMultipleUsersMode';
// Editor
export const HIDE_EDITOR = 'editor/hideEditor';
export const SHOW_EDITOR = 'editor/showEditor';
export const UPDATE_EDITOR_LOADING = 'editor/updateLoading';
export const UPDATE_EDITOR_SECTION = 'editor/updateSection';
