import { compose, createStore, combineReducers, applyMiddleware } from 'redux';
import {
  loadingBarReducer,
  showLoading,
  hideLoading,
} from 'react-redux-loading-bar';
import promiseMiddleware from 'redux-promise';
import thunkMiddleware from 'redux-thunk';

import { reducers, actions } from 'actions';

const BASE_URI = process.env.BASE_URI;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// When true, don't show error messages
let isExiled = false;

// Action loading middleware helpers
const loadingByType = new Set();
const loadingType = (action) => action.type.replace(/\/.*/, '/loading');

function actionLoading(dispatch, action) {
  loadingByType.add(action.type);

  if (action.showLoading !== false) {
    dispatch({
      type: loadingType(action),
      payload: true,
    });

    dispatch(showLoading());
  }
}

function actionUnloading(dispatch, action) {
  loadingByType.delete(action.type);

  setTimeout(() => {
    dispatch({
      type: loadingType(action),
      payload: false,
    });

    dispatch(hideLoading());
  }, 25);
}

export function actionLoadingMiddleware({ dispatch }) {
  return (next) => (action) => {
    if (!action) {
      return;
    }
    // If promise
    if (action.payload && action.payload.then) {
      actionLoading(dispatch, action);
      // Bypass promiseMiddleware
      // This is mainly for API request handling
      // Unlike redux-promise, this will not dispatch a resolved action on error
      // May need to differentiate in case it becomes a problem
      return action.payload
        .then((result) => {
          actionUnloading(dispatch, action);
          dispatch({ ...action, payload: result });
          return result;
        })
        .catch((err) => {
          actionUnloading(dispatch, action);
          let message = typeof err === 'string' ? err : String(err.message);
          // Hack
          if (!isExiled) {
            isExiled =
              message.indexOf('trial expired') > 0 ||
              message.indexOf('plan was canceled') > 0;
          }
          if (isExiled) {
            if (window.location.pathname.indexOf(`${BASE_URI}/plans`) !== 0) {
              window.location.href = `${BASE_URI}/plans`;
            }
            return;
          }
          const isLoggedOut =
            message.indexOf(
              'User must be logged in to access this resource',
            ) === 0;
          if (isLoggedOut) {
            window.location.href = `${BASE_URI}/login`;
            return;
          }
          const isServerError = /50\d/.test(message);
          if (isServerError) {
            message =
              'We were unable to complete this request. Please try again momentarily.';
          }
          actionUnloading(dispatch, action);
          dispatch(actions.flash.error(message));
          console.error(err);
        });
    }

    // Continue
    return next(action);
  };
}

export function actionLoadingReducer(state = false, action) {
  if (action.type.indexOf('/loading') > 0) {
    if (action.payload) {
      return true;
    } else if (loadingByType.size <= 0) {
      return false;
    }
  }
  return state;
}

export function configureStore(initialState) {
  return createStore(
    combineReducers({
      ...reducers,
      loadingBar: loadingBarReducer,
      loading: actionLoadingReducer,
    }),
    initialState,
    composeEnhancers(
      applyMiddleware(
        actionLoadingMiddleware,
        thunkMiddleware,
        promiseMiddleware,
      ),
    ),
  );
}
