import PropTypes from 'prop-types';
import React from 'react';
import '../tabs/tabs.scss';

export default class Tabs extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
  };

  static contextTypes = {
    registerField: PropTypes.func,
    unregisterField: PropTypes.func,
    onChangeField: PropTypes.func,
  };

  state = {
    value: undefined,
  };

  constructor(props, context) {
    super(props, context);
    this.readonly = true;
    this.state = {
      value: this.getInitialValue(props),
    };
    this.onClickTab = this.onClickTab.bind(this);
  }

  componentDidMount() {
    if (this.context.registerField) {
      this.context.registerField(this);
    }

    this.onChangeField();
  }

  componentWillUnmount() {
    if (this.context.unregisterField) {
      this.context.unregisterField(this);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.value !== prevProps.value ||
      this.props.defaultValue !== prevProps.defaultValue ||
      this.props.items !== prevProps.items
    ) {
      this.setState({ value: this.getInitialValue(this.props) });
    }
  }

  getInitialValue(props) {
    return (
      props.value ||
      props.defaultValue ||
      this.state.value ||
      props.items[0].value ||
      props.items[0]
    );
  }

  onChangeField() {
    if (this.context.onChangeField) {
      this.context.onChangeField(this);
    }
  }

  onClickTab(event) {
    event.preventDefault();
    const value = event.currentTarget.dataset.value;
    this.setState({ value }, () =>
      this.props.onChange ? this.props.onChange(value) : this.onChangeField(),
    );
  }

  render() {
    const { items, className } = this.props;

    const { value } = this.state;

    return (
      <div className={`form-tabs ${className || ''}`}>
        <ul className="tabs">
          {items.map(
            (tab, i) =>
              tab && (
                <li
                  key={tab.value || i}
                  className={value === (tab.value || i) ? 'active' : ''}
                >
                  <a
                    href=""
                    onClick={this.onClickTab}
                    data-value={tab.value || i}
                  >
                    {tab.label}
                  </a>
                </li>
              ),
          )}
        </ul>
      </div>
    );
  }
}
