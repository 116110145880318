import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';
import DropdownButton from 'components/button/dropdown';
import StorefrontDomains from 'containers/StorefrontDomains';
import StorefrontSettings from 'containers/StorefrontSettings';
import StorefrontNavigation from 'containers/StorefrontNavigation';
import StorefrontDeveloperTools from 'containers/StorefrontDeveloperTools';

import { confirmTabLeave, confirmRouteLeave } from 'utils/container';
import { getStorefrontURL, renderStorefrontThemeDesc } from 'utils/storefront';
import Tooltip from 'components/tooltip/tooltip';

import './storefront.scss';

export default class StorefrontView extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
  };

  state = {
    mode: null,
    edited: false,
    tab: undefined,
  };

  componentDidMount() {
    this.setState({ tab: this.props.location?.query?.tab || 'settings' });
    confirmRouteLeave(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!confirmTabLeave(this, nextProps)) {
      this.setState({ tab: nextProps.location.query.tab });
    }
  }

  onEdited = (edited) => {
    this.setState({ edited });
  };

  render() {
    const { record: storefront } = this.props;
    const storefrontURL = getStorefrontURL(storefront);
    const { tab, edited } = this.state;

    const viewProps = {
      uri: '/storefronts',
      sectionTitle: 'Storefronts',
      headerTitle: storefront.name || 'Storefront',
      detail: true,
      tags: [
        storefront.source_model === 'themes' &&
          renderStorefrontThemeDesc(storefront),
        storefront.primary && 'Primary',
      ],
      headerActions: [
        {
          component: (
            <DropdownButton
              key="actions"
              type="secondary"
              anchor="right"
              anchorPosition={49}
              alignOffset={9}
              items={[
                storefront.editor && (
                  <Link to={`/storefronts/${storefront.id}/editor`} key="1">
                    Edit theme
                  </Link>
                ),

                <Link
                  to={storefrontURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  disabled={!storefrontURL}
                  className={`${!storefrontURL && 'muted'}`}
                  key="2"
                >
                  <Tooltip
                    dir="up"
                    message={
                      storefrontURL
                        ? 'This will open in a new tab'
                        : 'Missing storefront URL'
                    }
                  >
                    <span>View storefront</span>
                  </Tooltip>
                </Link>,
                !storefront.primary && (
                  <button
                    key="3"
                    onClick={this.props.onClickSetPrimary}
                    data-id={storefront.id}
                  >
                    Make primary
                  </button>
                ),
                <hr key="4" />,
                <button
                  key="5"
                  onClick={this.props.onClickDelete}
                  className="danger"
                  data-id={storefront.id}
                >
                  Delete storefront
                </button>,
              ]}
            >
              Actions
            </DropdownButton>
          ),
        },
      ],
      tabs: {
        default: {
          label: 'Settings',
        },
        navigation: {
          label: 'Navigation',
        },
        ...(storefront.hosted && storefront.primary
          ? {
              domains: {
                label: 'Domains',
              },
            }
          : {}),
        devtools: {
          label: 'Developer',
        },
      },
    };

    switch (tab) {
      case 'domains':
        return (
          <div className="storefront-view">
            <StorefrontDomains {...this.props} viewProps={viewProps} />
          </div>
        );
      case 'navigation':
        return (
          <div className="storefront-view">
            <StorefrontNavigation
              {...this.props}
              viewProps={viewProps}
              edited={edited}
              onEdited={this.onEdited}
            />
          </div>
        );
      case 'devtools':
        return (
          <div className="storefront-view">
            <StorefrontDeveloperTools
              {...this.props}
              viewProps={viewProps}
              edited={edited}
              onEdited={this.onEdited}
            />
          </div>
        );
      case 'settings':
      default:
        return (
          <div className="storefront-view">
            <StorefrontSettings
              {...this.props}
              viewProps={viewProps}
              edited={edited}
              onEdited={this.onEdited}
            />
          </div>
        );
    }
  }
}
