import React from 'react';
import Icon from 'components/icon';
import User from '../utils/user';
import Date from '../utils/date';
import Items from '../utils/items';

export default function ItemsCanceled({ event, items }) {
  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/items-canceled" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          {items.length > 1 ? 'Items' : 'Item'} canceled
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
        {event.notes && (
          <span className="activity-event-subtitle">Reason: {event.notes}</span>
        )}
        <div className="activity-event-details">
          <Items items={items} />
        </div>
      </div>
    </div>
  );
}
