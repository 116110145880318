import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';
import Link from 'components/link';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import OrderItemDetail from './item-detail';
import Help from 'components/tooltip/help';
import ContentFields from 'components/content/fields';
import { FadeIn } from 'components/transitions';
import {
  formatCurrencyRounded,
  formatCurrency,
  formatDate,
  parseCurrency,
  currencyValue,
} from 'utils';
import { productThumbUrl, productName } from 'utils/product';
import { getReturnableItems, getReturnedItems } from 'utils/order';
import { hasLocalizedContent } from 'utils/content';

export default class OrderReturnAdd extends React.PureComponent {
  static propTypes = {
    record: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    content: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    returnRecord: PropTypes.object,
    onClickReturnAdd: PropTypes.func.isRequired,
    onClickReturnDelete: PropTypes.func,
    onSubmitReturnAdd: PropTypes.func.isRequired,
    onSubmitReturnDelete: PropTypes.func,
  };

  static contextTypes = {
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { record, returnRecord = {} } = props;
    const returnableItems = getReturnableItems(
      record.items,
      returnRecord.items,
    );
    this.state = {
      values: {
        ...returnRecord,
        items: this.getInitialItemValues(returnableItems),
        restock: returnRecord.items
          ? returnRecord.items.filter((item) => item.quantity_restocked > 0)
              .length > 0
          : false,
        shipment_total: returnRecord.shipment_total,
        restock_fee: returnRecord.restock_fee,
      },
      returnableItems,
    };
    this.onChangeForm = this.onChangeForm.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  getInitialItemValues(returnableItems) {
    const { returnRecord = {} } = this.props;
    return returnableItems.map((item, index) => ({
      quantity: returnRecord.items
        ? get(
            find(returnRecord.items, {
              order_item_id: item.id,
              ...(item.bundle_item_id
                ? { bundle_item_id: item.bundle_item_id }
                : {}),
            }),
            'quantity',
            0,
          )
        : 0,
    }));
  }

  onChangeForm(values) {
    this.setState({
      values: {
        ...this.state.values,
        ...values,
        items: [...values.items],
      },
    });
  }

  onSubmitForm(values) {
    const { returnableItems } = this.state;

    this.props.onSubmitReturnAdd({
      ...values,
      restock: undefined,
      items: getReturnedItems(values.items, values.restock, returnableItems),
    });
  }

  hasSelectedItems(items = []) {
    for (let item of items) {
      if (item.quantity > 0) {
        return true;
      }
    }
    return false;
  }

  taxIncluded() {
    const {
      record: { tax_included, item_tax_included },
    } = this.props;

    return tax_included || item_tax_included;
  }

  itemCreditAmounts(item) {
    const discount = item.discount_each || 0;
    const tax = this.taxIncluded() ? 0 : item.tax_each || 0;
    return {
      price: item.price,
      discount,
      tax,
      total: item.price - discount + tax,
    };
  }

  itemCreditTotal() {
    const { values, returnableItems } = this.state;
    return returnableItems.reduce((acc, item, index) => {
      const quantity = values.items[index].quantity || 0;
      const { total } = this.itemCreditAmounts(item);
      return acc + Number(total * quantity);
    }, 0);
  }

  renderItemPriceWithDiscountsAndTaxes(item) {
    const {
      record: { currency },
    } = this.props;
    const { price, discount, tax, total } = this.itemCreditAmounts(item);
    return (
      <Fragment>
        {formatCurrency(total, currency)}
        {(discount > 0 || tax > 0) && (
          <Fragment>
            &nbsp;
            <Help
              message={`${formatCurrency(price, currency)}${
                item.tax_each > 0
                  ? ` + ${formatCurrency(tax, currency)} (tax)`
                  : ''
              }${
                discount > 0
                  ? ` - ${formatCurrency(discount, currency)} (discount)`
                  : ''
              }`}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }

  shipmentTaxPortion() {
    const { record } = this.props;
    const {
      values: { shipment_total },
    } = this.state;
    if (
      shipment_total <= 0 ||
      record.shipment_tax <= 0 ||
      record.shipment_tax_included ||
      record.shipment_total <= 0
    ) {
      return 0;
    }
    return (
      (record.shipment_tax || 0) * (shipment_total / record.shipment_total)
    );
  }

  render() {
    const {
      record,
      loading,
      content,
      returnRecord = {},
      onClickReturnAdd,
      onClickReturnDelete,
    } = this.props;

    const { values, returnableItems } = this.state;

    const hasItems = this.hasSelectedItems(values.items);

    const itemCreditTotal = this.itemCreditTotal();
    const { currency } = record;
    const shippingCreditTotal =
      parseCurrency(currencyValue(values.shipment_total, currency), currency) ||
      0 + this.shipmentTaxPortion();
    const returnCreditTotal =
      itemCreditTotal +
        shippingCreditTotal -
        parseCurrency(currencyValue(values.restock_fee, currency), currency) ||
      0;
    const maxRestockFee = hasItems
      ? itemCreditTotal
      : record.grand_total - record.shipment_total;

    return (
      <Form
        onSubmit={this.onSubmitForm}
        onChange={this.onChangeForm}
        autoFocus={!returnRecord.id}
        values={values}
      >
        <Modal
          title={
            returnRecord.id
              ? `Edit return ${returnRecord.number}`
              : 'Return items'
          }
          actions={[
            {
              label: `Save`,
              type: hasItems ? 'submit' : 'secondary',
              disabled: hasItems ? false : true,
            },
            returnRecord.id && {
              label: 'Delete',
              type: 'secondary',
              className: 'left button-cancel',
              onClick: onClickReturnDelete,
            },
            { label: 'Cancel', type: 'cancel', onClick: onClickReturnAdd },
          ]}
          width={700}
          cancel={false}
          loading={loading}
          onClose={onClickReturnAdd}
          devtools={{
            model: 'returns',
            uri: returnRecord.id,
            console: !!returnRecord.id,
            zone: 'order',
          }}
          localized={hasLocalizedContent(content.models, 'returns', 'order')}
        >
          <fieldset>
            {!!returnRecord.date_created && (
              <p>
                Created {formatDate(returnRecord.date_created, 'shortExact')}
              </p>
            )}
            <div className="collection-table-container">
              <table className="collection-table">
                <thead>
                  <tr>
                    <th colSpan="2">Returnable items</th>
                    <th>Value</th>
                    <th>Quantity</th>
                    <th>{values.restock && 'Restock'}</th>
                  </tr>
                </thead>
                <tbody>
                  {returnableItems.map((item, index) => {
                    const { quantity } = values.items[index];
                    const zero = !(quantity > 0);
                    const itemRecord =
                      find(returnRecord.items, {
                        order_item_id: get(returnableItems[index], 'id'),
                      }) || {};
                    const isRemoving = zero && itemRecord.id;
                    return (
                      <tr
                        key={index}
                        style={isRemoving ? { opacity: 0.5 } : undefined}
                      >
                        <td className="image">
                          <span className="collection-table-images">
                            <span className="image">
                              <Link to={`/products/${item.product_id}`}>
                                <img
                                  src={productThumbUrl(
                                    item.product,
                                    item.variant,
                                  )}
                                  alt={productName(item.product, item.variant)}
                                />
                              </Link>
                            </span>
                          </span>
                        </td>
                        <td>
                          <OrderItemDetail
                            item={item}
                            style={
                              isRemoving
                                ? { textDecoration: 'line-through' }
                                : undefined
                            }
                          />
                        </td>
                        <td>
                          {this.renderItemPriceWithDiscountsAndTaxes(item)}
                        </td>
                        <td className="nowrap">
                          <Field
                            type="number"
                            name={`items.${index}.quantity`}
                            defaultValue={!zero ? quantity : ''}
                            maxValue={item.quantity_returnable}
                            placeholder="0"
                            className="order-line-items-qty-field"
                            selectFocus={true}
                          />
                          <div className="order-line-items-qty-desc">
                            of {item.quantity_returnable}
                          </div>
                        </td>
                        <td className="nowrap">
                          {values.restock && (
                            <Field
                              type="number"
                              name={`items.${index}.quantity_restocked`}
                              defaultValue={
                                itemRecord.id
                                  ? itemRecord.quantity_restocked
                                  : ''
                              }
                              maxValue={quantity}
                              placeholder={quantity || '0'}
                              className="order-line-items-qty-field"
                              selectFocus={true}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Field
              type="checkbox"
              label="Return items back to stock"
              name="restock"
              defaultChecked={values.restock}
            />
            <Field
              type="textarea"
              name="notes"
              label="Reason"
              defaultValue={returnRecord.notes}
              placeholder="Optional"
              autoSize={true}
              rows={2}
              help="Return notes are not visible to the customer"
            />
            <div className="row">
              <Field
                type="currency"
                name="shipment_total"
                label="Shipping credit"
                currency={currency}
                defaultValue={returnRecord.shipment_total}
                help={
                  record.shipment_total === 0
                    ? 'Shipping on this order was free'
                    : `Amount to credit for shipping, up to ${formatCurrency(
                        record.shipment_total,
                        currency,
                      )}`
                }
                maxValue={record.shipment_total}
                disabled={record.shipment_total === 0}
                className="span2"
              />
              <Field
                type="currency"
                name="restock_fee"
                label="Restocking fee"
                defaultValue={returnRecord.restock_fee}
                currency={currency}
                help={`Amount to charge as a restocking fee, up to ${formatCurrency(
                  maxRestockFee,
                  currency,
                )}`}
                maxValue={maxRestockFee}
                className="span2"
              />
            </div>
            <div className="order-totals">
              {parseCurrency(itemCreditTotal, currency) > 0 && (
                <FadeIn>
                  <Fragment>
                    <span className="order-totals-label">Return value</span>
                    <span className="order-totals-value">
                      {formatCurrencyRounded(itemCreditTotal, currency)}
                    </span>
                  </Fragment>
                  {shippingCreditTotal > 0 && (
                    <FadeIn>
                      <span className="order-totals-label">
                        Shipping credit{' '}
                        {record.shipment_tax
                          ? record.shipment_tax_included
                            ? '(tax included)'
                            : '(+ tax)'
                          : ''}
                      </span>
                      <span className="order-totals-value">
                        {formatCurrency(shippingCreditTotal, currency)}
                      </span>
                    </FadeIn>
                  )}
                  {parseCurrency(values.restock_fee, currency) > 0 && (
                    <FadeIn>
                      <span className="order-totals-label">Restocking fee</span>
                      <span className="order-totals-value">
                        {formatCurrency(values.restock_fee, currency)}
                      </span>
                    </FadeIn>
                  )}
                  {parseCurrency(shippingCreditTotal, currency) +
                    parseCurrency(values.restock_fee, currency) >
                    0 && (
                    <Fragment>
                      <span className="order-totals-label">
                        <strong>Total credit</strong>
                      </span>
                      <span className="order-totals-value">
                        <strong>
                          {formatCurrencyRounded(returnCreditTotal, currency)}
                        </strong>
                      </span>
                    </Fragment>
                  )}
                </FadeIn>
              )}
            </div>
          </fieldset>
          <ContentFields
            {...this.props}
            zone="order"
            collection="returns"
            models={content.models}
            record={returnRecord}
            values={values}
            currency={record.currency}
          />
        </Modal>
      </Form>
    );
  }
}
