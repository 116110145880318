import React from 'react';
import PropTypes from 'prop-types';
import { FadeInUp } from 'components/transitions';
import StoreIcon from 'components/store/icon';
import UserExitNote from 'components/user/exit-note';
import { formatDate } from 'utils';
import './login.scss';

export default class SwitchPage extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onClickSwitchAccount: PropTypes.func.isRequired,
  };

  render() {
    const { user, client, onClickSwitchAccount } = this.context;

    return (
      <div className="login-form login-switch">
        <FadeInUp>
          <div>
            <div className="login-heading">
              <h2>Choose an account</h2>
            </div>
            <div className="box">
              {user.authorizations && user.authorizations.length > 1 ? (
                user.authorizations.map((auth) => (
                  <a
                    key={auth.client_id}
                    className="box-section login-switch-section"
                    href=""
                    onClick={onClickSwitchAccount}
                    data-id={auth.client_id}
                  >
                    <span className="login-switch-icon">
                      <StoreIcon auth={auth} size={48} />
                    </span>
                    <span className="login-switch-body">
                      <b>{auth.client_name}</b>
                      <div className="muted">
                        {auth.date_last_login
                          ? `Last accessed ${formatDate(
                              auth.date_last_login,
                              'age',
                            )}`
                          : `Never accessed`}
                      </div>
                    </span>
                  </a>
                ))
              ) : (
                <a
                  key={user.client_id}
                  className="box-section login-switch-section"
                  href=""
                  onClick={onClickSwitchAccount}
                  data-id={user.client_id}
                >
                  <span className="login-switch-icon">
                    <img
                      src={logomark}
                      width="40"
                      height="40"
                      alt={user.client_id}
                    />
                  </span>
                  <span className="login-switch-body">
                    <b>{client.name}</b>
                    <div className="muted">
                      {`Last accessed ${formatDate(
                        user.date_last_login || user.date_created,
                        'age',
                      )}`}
                    </div>
                  </span>
                </a>
              )}
            </div>
            <UserExitNote hideSwitch={true} />
          </div>
        </FadeInUp>
      </div>
    );
  }
}
