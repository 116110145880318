import React, { Fragment } from 'react';
import pt from 'prop-types';

import { formatCurrency } from 'utils';
import { renderMultiCurrencyValuesTooltip } from 'utils/money';

function renderValue(value, amount, currency) {
  return (
    <Fragment>
      {formatCurrency(value, currency)}{' '}
      <span className="muted muted-value">
        / {formatCurrency(amount, currency)}
      </span>
    </Fragment>
  );
}

function GiftcardValue({ giftcard }) {
  const { amount, amount_spent, balance, redeemed, currency } = giftcard;

  if (redeemed) {
    return renderValue(balance, amount + (amount_spent ?? 0), currency);
  }

  if (amount_spent > 0) {
    return renderValue(amount - amount_spent, amount, currency);
  }

  return (
    <Fragment>
      {renderValue(balance, amount, currency)}

      {renderMultiCurrencyValuesTooltip(
        giftcard.$currency,
        'amount',
        currency,
        'left',
      )}
    </Fragment>
  );
}

GiftcardValue.propTypes = {
  giftcard: pt.shape({
    amount: pt.number.isRequired,
    amount_spent: pt.number,
    balance: pt.number.isRequired,
    currency: pt.string.isRequired,
    redeemed: pt.bool,
    $currency: pt.object,
  }).isRequired,
};

export default React.memo(GiftcardValue);
