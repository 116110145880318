import React from 'react';
import Modal from 'components/modal';
import ContentFields from 'components/content/fields';
import { Form } from 'components/form';
import OrderAddressForm from 'components/pages/order/address-form';
import { isEmpty } from 'utils';
import { hasLocalizedContent } from 'utils/content';

export default class CustomerShippingEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: props.record,
    };
    this.onChangeForm = this.onChangeForm.bind(this);
  }

  onChangeForm(values) {
    this.setState({
      values: {
        shipping: {
          ...this.state.values.shipping,
          ...values.shipping,
        },
      },
    });
  }

  render() {
    const {
      record,
      content,
      onClickShippingEdit,
      onSubmitShippingEdit,
      suggestedAddresses,
    } = this.props;
    const { values } = this.state;

    return (
      <Form
        onSubmit={onSubmitShippingEdit}
        onChange={this.onChangeForm}
        autoFocus={true}
      >
        <Modal
          title="Edit shipping address"
          className="customer-view-modal-edit-shipping"
          actions={[
            { label: 'Save', type: 'submit' },
            { label: 'Cancel', type: 'cancel', onClick: onClickShippingEdit },
          ]}
          width={600}
          cancel={false}
          onClose={onClickShippingEdit}
          localized={hasLocalizedContent(content.models, 'accounts')}
          devtools={{
            model: 'accounts',
            uri: `${record.id}/shipping`,
            zone: 'shipping',
            console: !isEmpty(values.shipping),
          }}
        >
          <fieldset>
            <OrderAddressForm
              suggestedAddresses={suggestedAddresses}
              name="shipping"
              record={values.record}
              values={values.shipping}
              onChangeForm={this.onChangeForm}
            />
            <ContentFields
              {...this.props}
              zone="shipping"
              collection="accounts"
              models={content.models}
              record={record}
              values={values}
              currency={record.currency}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
