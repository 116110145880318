import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import PaymentCardsEditRadio from 'components/payment/cards-edit-radio';
import PaymentMethodsEditRadio from 'components/payment/methods-edit-radio';

import { getAddressFromRecord, EMPTY_ADDRESS } from 'utils/order';

import './payment.scss';

const NEW_ADDRESS_ID = 'new';
const NONE_ADDRESS_ID = 'none';
const EMPTY_ARRAY = Object.freeze([]);

const mapStateToProps = (state) => ({
  addresses: state.data.related?.account_addresses?.results || EMPTY_ARRAY,
});

class PaymentMethodsEdit extends React.PureComponent {
  static contextTypes = {
    notifyError: pt.func.isRequired,
  };

  static propTypes = {
    addresses: pt.array.isRequired,
    fetchRecord: pt.func.isRequired,
    requireAddress: pt.bool,
    onClickEditCard: pt.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedCard: null,
      selectedMethod: null,
      selectedAddress: null,
      editingCard: false,
      addingBilling: false,
    };
  }

  getAddress(id) {
    const { addresses } = this.props;

    if (id === NEW_ADDRESS_ID) {
      return null;
    } else if (id === NONE_ADDRESS_ID) {
      return EMPTY_ADDRESS;
    }

    const address = addresses.find((address) => address.id === id);

    return address ? getAddressFromRecord(address) : null;
  }

  value = () => {
    const { notifyError } = this.context;
    const { requireAddress = true } = this.props;
    const { selectedCard, selectedMethod, selectedAddress } = this.state;

    if (!selectedCard && !selectedMethod) {
      notifyError('Payment method not selected');

      return false;
    } else if (selectedCard) {
      return {
        method: 'card',
        account_card_id: selectedCard,
      };
    } else {
      const address = this.getAddress(selectedAddress);

      if (requireAddress && !address) {
        notifyError('Billing address not selected');

        return false;
      }

      return {
        method: selectedMethod,
        address,
      };
    }
  };

  onClickEditCard = () => {
    this.setState({ editingCard: true });
    this.props.onClickEditCard?.(true);
  };

  onClickEditCardCancel = () => {
    this.setState({ editingCard: false });
    this.props.onClickEditCard?.(false);
  };

  onSelectCard = (value) => {
    this.setState({
      selectedMethod: null,
      selectedCard: value,
    });
  };

  onSelectMethod = (value) => {
    this.setState({
      selectedCard: null,
      selectedMethod: value,
    });
  };

  onSelectAddress = (value) => {
    this.setState({ selectedAddress: value });
  };

  render() {
    const { fetchRecord, requireAddress = true } = this.props;
    const { selectedCard, selectedMethod, editingCard } = this.state;

    return (
      <div className="payment-methods-edit">
        <PaymentCardsEditRadio
          fetchRecord={fetchRecord}
          value={selectedCard}
          onSelect={this.onSelectCard}
          onClickAddCard={this.onClickEditCard}
          onClickEditCard={this.onClickEditCard}
          onClickCancel={this.onClickEditCardCancel}
        />
        <PaymentMethodsEditRadio
          fetchRecord={fetchRecord}
          value={selectedMethod}
          onSelect={this.onSelectMethod}
          onSelectAddress={this.onSelectAddress}
          requireAddress={requireAddress}
          disabled={editingCard}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, null, null, { withRef: true })(
  PaymentMethodsEdit,
);
