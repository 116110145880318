import React from 'react';
import Help from 'components/tooltip/help';
import Icon from 'components/icon';
import AccountUsageOverview from 'components/reports/overview/account-usage';
import { toFixed, formatDate, formatNumberAbbreviated } from 'utils';

import './account.scss';

export default class AccountBilling extends React.PureComponent {
  graphHasData(type) {
    const {
      usageGraph: { current, previous },
    } = this.props;
    if (current.summary[type] > 0 || previous.summary[type] > 0) {
      return true;
    }
    return false;
  }

  render() {
    const {
      account,
      client: { usage: usage },
      usageGraph,
    } = this.props;

    const dataDiff = toFixed(
      usage.last_month_data > 0 &&
        (usage.current_month_data / usage.last_month_data) * 100,
      0,
    );

    return (
      <fieldset className="full last-child account-usage">
        <div className="box with-header-sections">
          <div className="box-section box-header">
            <h4 className="box-title">Usage</h4>
            <div className="box-subtitle">
              {formatDate(account.plan.date_period_start, 'short')} -{' '}
              {formatDate(account.plan.date_period_end, 'short')}
            </div>
          </div>
          <div className="box-section account-usage-chart">
            <div className="row">
              <div className="span2">
                <div className="tinyheading">API</div>
                <AccountUsageOverview
                  type="api_calls"
                  typeName="calls"
                  limits={account.features}
                  hint={`Number of API calls made this monthly to Swell from all
                        applications and environments. ${
                          account.features.api_calls > 0
                            ? `Plan limit: ${formatNumberAbbreviated(
                                account.features.api_calls,
                              )}`
                            : ''
                        }`}
                  points={[usageGraph.current, usageGraph.previous]}
                />
              </div>
              <div className="span2">
                <div className="tinyheading">Functions</div>
                <AccountUsageOverview
                  type="function_calls"
                  typeName="calls"
                  limits={account.features}
                  hint={`Number of function calls made this month by Swell Apps
                        you have installed in all environments. ${
                          account.features.function_calls > 0
                            ? `Plan limit: ${formatNumberAbbreviated(
                                account.features.function_calls,
                              )}`
                            : ''
                        }`}
                  points={[usageGraph.current, usageGraph.previous]}
                />
              </div>
            </div>
          </div>
          <div className="box-section account-usage-data">
            <div className="tinyheading">Data</div>
            <div className="reports-overview-values">
              <div className="reports-overview-value">
                {usage.total_gb < 1 ? (
                  <>{usage.total_mb}MB</>
                ) : (
                  <>{usage.total_gb}GB</>
                )}
                <Help
                  message={`Total data storage and number of records used by your
                        account including products, orders, assets, etc. ${
                          account.features.data > 0
                            ? `Plan limit: ${account.features.data}GB`
                            : ''
                        }`}
                />
              </div>
              {usage.current_month_data && usage.last_month_data && (
                <div className="reports-overview-diff">
                  {dataDiff > 0 && (
                    <span className="positive">
                      <Icon fa="arrow-alt-up" faType="solid" /> {dataDiff}%
                    </span>
                  )}
                  {dataDiff < 0 && (
                    <span className="muted">
                      <Icon fa="arrow-alt-down" faType="solid" /> {-dataDiff}%
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="account-usage-data-bar">
              <div
                className="account-usage-data-bar-progress"
                style={{
                  width: `${
                    account.features.data > 0
                      ? (usage.total_gb / account.features.data) * 100
                      : 100
                  }%`,
                }}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
