import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import pt from 'prop-types';

import ContentFields from 'components/content/fields';
import { FadeIn } from 'components/transitions';
import { Form, Button } from 'components/form';

export default class OrderContent extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    values: pt.object,
    content: pt.object.isRequired,
    currency: pt.string,
    collection: pt.string,
    zone: pt.string,

    onSubmit: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      edited: false,
      pending: false,
      values: props.values || cloneDeep(props.record),
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.state.pending) {
      if (prevProps.values !== this.props.values) {
        this.setState({ values: this.props.values });
      } else if (prevProps.record !== this.props.record) {
        this.setState({ values: cloneDeep(this.props.record) });
      }
    }
  }

  onChangeForm = (values, edited) => {
    this.setState((state) => ({
      values: { ...state.values, ...values },
      edited,
      pending: edited,
    }));
  };

  onSubmitForm = async (values) => {
    this.setState({ pending: false });
    await this.props.onSubmit(values);
    this.setState({ edited: false });
  };

  render() {
    const { content, zone, collection, record, currency } = this.props;

    const { values, edited } = this.state;

    return (
      <ContentFields
        {...this.props}
        zone={zone}
        collection={collection || 'orders'}
        models={content.models}
        record={record}
        values={values}
        currency={currency || record.currency}
        render={(children) => (
          <div className="order-content content-fields-box">
            <Form
              onSubmit={this.onSubmitForm}
              onChange={this.onChangeForm}
              values={values}
            >
              {children}
              <FadeIn active={edited}>
                <Button type="submit" size="sm">
                  Save changes
                </Button>
              </FadeIn>
            </Form>
          </div>
        )}
      />
    );
  }
}
