import PropTypes from 'prop-types';
import React from 'react';
import FadeIn from '../transitions/fade-in-down-bounce';

let idCounter = 0;

export default class Fieldset extends React.Component {
  static contextTypes = {
    subscribeChange: PropTypes.func,
    unsubscribeChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      id: props.id || `fieldset-${idCounter++}`,
      disabled: typeof props.disabled === 'function' ? false : props.disabled,
    };

    this.onChangeForm = this.onChangeForm.bind(this);
  }

  componentWillMount() {
    if (this.context.subscribeChange) {
      this.context.subscribeChange(this.state.id, this.onChangeForm);
    }
  }

  componentWillUnmount() {
    if (this.context.unsubscribeChange) {
      this.context.unsubscribeChange(this.state.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.disabled !== undefined &&
      typeof nextProps.disabled !== 'function'
    ) {
      this.setState({
        disabled: nextProps.disabled,
      });
    }
  }

  onChangeForm(values) {
    if (typeof this.props.disabled === 'function') {
      this.setState({ disabled: this.props.disabled(values) });
    }
  }

  render() {
    const { children, ...props } = this.props;

    const className = `${props.className || ''} ${
      this.state.disabled ? 'disabled' : ''
    }`;

    return (
      <fieldset {...props} className={className} disabled={this.state.disabled}>
        <FadeIn transitionAppear={false} active={!this.state.disabled}>
          {children}
        </FadeIn>
      </fieldset>
    );
  }
}
