import React from 'react';
import { imageUrl } from 'utils';
import { getNameFields } from 'actions/user';
import './user.scss';

export default class UserPhoto extends React.PureComponent {
  render() {
    const { user, width = 45, height = 45 } = this.props;

    let { nameInitials } = user;
    if (nameInitials === undefined) {
      nameInitials = getNameFields(user).nameInitials;
    }

    return (
      <div
        className={`user-photo ${
          user.photo && user.photo.url ? 'has-image' : ''
        }`}
        style={{ width, height }}
      >
        {user.photo && user.photo.url ? (
          <img
            src={imageUrl(user.photo, { width: width * 2, height: height * 2 })}
            alt={user.name}
            style={{ width, height }}
          />
        ) : (
          <span className="user-photo-avatar">
            <span className="user-photo-icon" style={{ width, height, lineHeight: `${height}px` }}>
              {nameInitials}
            </span>
          </span>
        )}
      </div>
    );
  }
}
