import React from 'react';
import pt from 'prop-types';
import { isEmpty } from 'lodash';

import { isSameAddress, shouldUseAccountShippingAddress } from 'utils/order';

import Modal from 'components/modal';
import { Form, Field } from 'components/form';

import OrderAddressForm from './address-form';

export default class OrderAddressEdit extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    shipping: pt.object,
    loading: pt.bool,
    label: pt.string,
    hideShipping: pt.bool,
    showLookupCurrency: pt.bool,
    showAutoUpdateAddress: pt.bool,
    suggestedAddresses: pt.array,
    showUseDefaultAddressCheckbox: pt.bool,
    manageAddresses: pt.bool,

    onClickEditAddress: pt.func,
    onSubmitEditAddress: pt.func,
  };

  constructor(props) {
    super(props);

    const { shipping } = props;
    const record = props.record || {};
    const account = record.account || {};

    this.state = {
      values: {
        ...record,
        shipping: shipping || {},
      },
      accountDefaultShipping: isSameAddress(shipping, account.shipping),
    };
  }

  onChangeForm = (values) => {
    const { account, shipping: recordShipping } = this.props.record || {};
    const { accountDefaultShipping } = this.state;

    let shippingUpdates = values.shipping;

    if (values.shipping) {
      if (values.shipping.use_account && account) {
        shippingUpdates = { ...account.shipping, use_account: true };
      } else if (!values.shipping.use_account && accountDefaultShipping) {
        // then go back to record.shipping
        shippingUpdates = {
          ...(recordShipping || undefined),
          use_account: false,
        };
      }
    }

    this.setState((state) => ({
      values: {
        ...state.values,
        ...values,
        shipping: {
          ...(state.values.shipping || undefined),
          ...shippingUpdates,
        },
      },
      accountDefaultShipping: values.shipping
        ? !!values.shipping.use_account
        : state.accountDefaultShipping,
    }));
  };

  render() {
    const {
      record,
      loading,
      onClickEditAddress,
      onSubmitEditAddress,
      hideShipping,
      showUseDefaultAddressCheckbox,
      suggestedAddresses,
    } = this.props;

    const { values } = this.state;
    const { shipping } = record;
    const account = record.account || {};

    const isNew = !account.id;

    return (
      <Form
        onChange={this.onChangeForm}
        onSubmit={onSubmitEditAddress}
        autoFocus={true}
      >
        <Modal
          title={`${
            shipping?.address1 || record.account.shipping?.address1
              ? 'Edit'
              : 'Add'
          } shipping address`}
          className="order-view-modal-edit-address"
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'secondary',
              className: 'button-cancel',
              onClick: onClickEditAddress,
            },
          ]}
          width={600}
          cancel={false}
          loading={loading}
          onClose={onClickEditAddress}
        >
          {(!hideShipping || isNew) && (
            <fieldset>
              <Field
                type="text"
                label="Phone number"
                name="account.phone"
                defaultValue={account.phone}
                className="span4 hidden"
              />
              <OrderAddressForm
                suggestedAddresses={suggestedAddresses}
                onChangeForm={this.onChangeForm}
                name="shipping"
                values={values.shipping}
                disabled={!!(values.shipping && values.shipping.use_account)}
              />

              {showUseDefaultAddressCheckbox && !isNew && (
                <div className="row">
                  <Field
                    type="checkbox"
                    label="Use account default shipping address"
                    name="shipping.use_account"
                    defaultChecked={shouldUseAccountShippingAddress(record.shipping)}
                    className="span4"
                  />
                </div>
              )}
            </fieldset>
          )}
        </Modal>
      </Form>
    );
  }
}
