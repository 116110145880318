import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ViewLoading from 'components/view/loading';
import DomainsPage from 'components/storefront/domains';
import actions from 'actions';

const mapStateToProps = (state) => ({
  record: state.storefronts.storefront,
  loading: state.storefronts.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStorefront: (id) => {
    return dispatch(actions.storefronts.fetch(id));
  },

  updateStorefront: (id, data) => {
    return dispatch(actions.storefronts.update(id, data));
  },

  createDomain: (storefrontId, data) => {
    return dispatch(actions.storefronts.createDomain(storefrontId, data));
  },

  updateDomain: (storefrontId, domainId, data) => {
    return dispatch(
      actions.storefronts.updateDomain(storefrontId, domainId, data),
    );
  },

  verifyDomain: (storefrontId, domainId) => {
    return dispatch(actions.storefronts.verifyDomain(storefrontId, domainId));
  },

  enableSSLDomain: (storefrontId, domainId) => {
    return dispatch(
      actions.storefronts.enableSSLDomain(storefrontId, domainId),
    );
  },

  disableSSLDomain: (storefrontId, domainId) => {
    return dispatch(
      actions.storefronts.disableSSLDomain(storefrontId, domainId),
    );
  },

  deleteDomain: (storefrontId, domainId) => {
    return dispatch(actions.storefronts.deleteDomain(storefrontId, domainId));
  },
});

export class StorefrontDomains extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded: false,
      verifying: false,
      onCreateDomain: this.onCreateDomain.bind(this),
      onVerifyDomain: this.onVerifyDomain.bind(this),
      onUpdateDomain: this.onUpdateDomain.bind(this),
      onDeleteDomain: this.onDeleteDomain.bind(this),
      onToggleSSLDomain: this.onToggleSSLDomain.bind(this),
    };
  }

  async componentWillMount() {
    const { fetchStorefront, record, params } = this.props;

    if (!record) {
      await fetchStorefront(params.name);
    }

    this.setState({
      loaded: true,
    });
  }

  async onCreateDomain(values) {
    const { createDomain, fetchStorefront } = this.props;
    const { client, notifyError } = this.context;

    const result = await createDomain(client.id, values);

    if (!result) {
      return false;
    } else if (result.errors) {
      notifyError(result.errors);
      return false;
    }

    await fetchStorefront(client.id);

    return result;
  }

  async onDeleteDomain(id) {
    const { deleteDomain, fetchStorefront } = this.props;
    const { client, notifyError } = this.context;

    const result = await deleteDomain(client.id, id);

    if (!result) {
      return false;
    } else if (result.errors) {
      notifyError(result.errors);
      return false;
    }

    await fetchStorefront(client.id);

    return result;
  }

  async onVerifyDomain(id) {
    const { verifyDomain, fetchStorefront } = this.props;
    const { client, notifyError } = this.context;

    const result = await verifyDomain(client.id, id);

    if (!result) {
      return false;
    } else if (result.errors) {
      notifyError(result.errors);
      return false;
    }

    await fetchStorefront(client.id);

    return result;
  }

  async onUpdateDomain(id, values) {
    const { updateDomain, fetchStorefront } = this.props;
    const { client, notifyError } = this.context;

    const result = await updateDomain(client.id, id, values);

    if (!result) {
      return false;
    } else if (result.errors) {
      notifyError(result.errors);
      return false;
    }

    await fetchStorefront(client.id);

    return result;
  }

  async onToggleSSLDomain(id, status) {
    const { enableSSLDomain, disableSSLDomain, fetchStorefront } = this.props;
    const { client, notifyError } = this.context;

    const result = await (status
      ? enableSSLDomain(client.id, id)
      : disableSSLDomain(client.id, id));

    if (!result) {
      return false;
    } else if (result.errors) {
      notifyError(result.errors);
      return false;
    }

    await fetchStorefront(client.id);

    return result;
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return <DomainsPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorefrontDomains);
