import {
  get,
  map,
  find,
  merge,
  remove,
  isEmpty,
  capitalize,
  sortedUniq,
  cloneDeep,
} from 'lodash';

import {
  contentRecordName,
  contentFieldLabel,
  contentFieldProps,
  contentFieldPath,
  contentModelRoot,
  findContentTitleFields,
} from './index';

import { objectToArray } from 'utils';
import { wordify, contentLookupKey } from 'shared/utils';

import {
  STANDARD_CONTENT_VIEWS,
  COLLECTION_MODELS,
  CONTENT_MODELS,
  CONTENT_MODEL_VIEWS,
} from 'constants/content';

/*
  Structure of content models is as follows
  --

  Data types:

  ContentModel {
    id: string,
    gid: string,
    collection: string,
    collectionModel: CollectionModel,
    childCollection: string,
    childCollectionModel: CollectionModel,
    root: boolean|string,
    fields: [ContentField],
    views: [ContentView],
  }

  ContentView {
    id: string,
    gid: string,
    type: string,
    label: string,
    fields: [ContentField],
    tabs: [ContentTab],
    filters: [ContentFilter],
    actions: [ContentAction],
    extra_actions: [ContentAction],
  }

  ContentTab {
    id: string,
    label: string,
    default: boolean,
    filter: string,
  }

  ContentFilter {
    id: string,
    label: string,
    default: boolean,
    filter: string,
  }

  ContentAction {
    id: string,
    label: string,
    default: boolean,
    filter: string,
  }

  ContentField {
    id: string,
    type: string,
    label: string,
    required: boolean,
    localized: boolean,
    default: any,
    options: [string],
    fields: [ContentField],
    func: (record) => any,
  }

  CollectionModel {
    collection: string,
    singular: string,
    plural: string,
    name_field: string,
    fields: [CollectionField],
  }
*/

/**
 * Normalize a content model including all of its fields, views, etc
 * @param {object} contentModel
 * @param {Record<string, any>} allCollectionModels
 * @param {Record<string, any>} appsEnabledById
 */
export function normalizeContentModel(
  contentModel,
  allCollectionModels,
  appsEnabledById = {},
) {
  const appId =
    contentModel.source_type === 'app' ? contentModel.source_id : undefined;
  const installedApp = appId ? appsEnabledById[appId] : undefined;

  const collection = collectionName({ name: contentModel.collection });

  let childCollection;
  let childCollectionModel;
  if (contentModel.root) {
    const childCollectionName = collectionName({
      name: `${collection}:${contentModel.root}`,
    });

    if (allCollectionModels[childCollectionName]) {
      childCollection = childCollectionName;
      childCollectionModel = allCollectionModels[childCollectionName];
    }
  }

  // Assign collection fields before normalizing fields and views
  const collectionModel = allCollectionModels[contentModel.collection];

  const modelProps = {
    ...contentModel,
    collection,
    collectionModel,
    ...(childCollection
      ? {
          childCollection,
          childCollectionModel,
        }
      : undefined),
    ...(installedApp
      ? {
          app: installedApp.app,
          app_id: installedApp.app_id,
        }
      : undefined),
  };

  const root = contentModelRoot(modelProps, collectionModel);

  const fields = (contentModel.fields || []).map((field) =>
    normalizeCollectionField(field, modelProps),
  );

  const views = (contentModel.views || []).map((view) =>
    normalizeContentView(modelProps, view, allCollectionModels),
  );

  return {
    ...modelProps,
    root,
    fields,
    views,
  };
}

/**
 * Normalize a content view including all of its fields, actions, etc
 *
 * @param {object} contentModel
 * @param {object} contentView
 * @param {Record<string, any>} [allCollectionModels={}]
 */
function normalizeContentView(
  contentModel,
  contentView,
  allCollectionModels = COLLECTION_MODELS,
) {
  const gid = contentView.gid || `${contentModel.id}.${contentView.id}`;
  const type =
    contentView.type || (contentView.id === 'list' ? 'list' : 'record');

  const collection = contentView.collection || contentModel.collection;
  const collectionModel =
    contentView.collectionModel || allCollectionModels[collection];
  const childCollection =
    contentView.childCollection || contentModel.childCollection;
  const childCollectionModel = allCollectionModels[childCollection];

  const label =
    contentView.label ||
    capitalize(`${contentView.id} ${collectionModel.singular}`);

  const viewProps = {
    ...contentView,
    gid,
    type,
    label,
    collection,
    collectionModel,
    contentModel,
    ...(childCollection
      ? {
          childCollection,
          childCollectionModel,
        }
      : undefined),
    ...(contentModel.app_id
      ? {
          app: contentModel.app,
          app_id: contentModel.app_id,
        }
      : undefined),
  };

  delete viewProps.extra_actions;

  const contentFields = contentView.fields || contentModel.fields;

  if (
    childCollection &&
    contentView?.parentContentModel?.collection !== collection
  ) {
    type === 'list'
      ? ensureParentListField(collectionModel, contentFields)
      : ensureParentRecordField(collectionModel, contentFields);
  }

  const fields = map(contentFields, (field) =>
    type === 'list'
      ? normalizeCollectionListField(field, contentModel)
      : normalizeCollectionField(field, contentModel),
  ).filter(Boolean);

  const defaultConfigs =
    type === 'list'
      ? defaultCollectionListView(collectionModel, fields)
      : defaultCollectionRecordView(collectionModel, fields);

  const tabs = isEmpty(contentView.tabs)
    ? defaultConfigs.tabs || []
    : contentView.tabs.map((tab) => ({
        ...normalizeCollectionTab(tab, type, contentModel, fields),
        ...(tab.filter
          ? find(contentView.filters, { id: tab.filter }) || undefined
          : undefined),
      }));

  // Prepend default tab if not already present
  const hasDefault =
    find(tabs, { id: 'default' }) || find(tabs, { default: true });
  if (!hasDefault) {
    if (type === 'list') {
      tabs.unshift({
        id: 'default',
        label: `All ${collectionModel.pluralLower}`,
        default: true,
      });
    } else if (tabs.length > 0) {
      tabs.unshift({
        id: 'default',
        label: `Details`,
      });
    }
  }

  const filters = isEmpty(contentView.filters)
    ? defaultConfigs.filters || []
    : contentView.filters.map((filter) => ({
        ...normalizeCollectionFilter(filter),
      }));

  const actions = map(
    isEmpty(contentView.actions) ? defaultConfigs.actions : contentView.actions,
    (action) => normalizeCollectionAction(action, collectionModel),
  );

  const extraActions = map(
    isEmpty(contentView.extra_actions)
      ? defaultConfigs.extra_actions
      : contentView.extra_actions,
    (action) => normalizeCollectionAction(action, collectionModel),
  );

  const query = collectionListViewQuery(
    isEmpty(contentView.query) ? defaultConfigs.query : undefined,
    fields,
  );

  return {
    ...viewProps,
    fields,
    tabs,
    filters,
    actions,
    extraActions,
    query,
  };
}

function defaultContentModel(
  collectionModel,
  allCollectionModels,
  appsEnabledById = {},
) {
  const appId = collectionModel.app_id;
  const { collection } = collectionModel;
  const standardViews = standardContentViews(collection, allCollectionModels);

  const id = `default.${appId ? `${appId}.` : ''}${collection}`;
  const source_type = appId ? 'app' : standardViews ? 'standard' : 'custom';
  const source_id = appId ? appId : 'admin';

  const contentModel = {
    id,
    source_type,
    source_id,
    collection,
    root: appId || standardViews ? true : 'content',
    views: standardViews || [],
  };

  return normalizeContentModel(
    {
      ...contentModel,
      fields: defaultCollectionFields(collectionModel, contentModel),
    },
    allCollectionModels,
    appsEnabledById,
  );
}

/**
 * Generate default content models for all collections where one is not defined
 *
 * @param {Array<object>} contentModels
 * @param {Record<string, any>} allCollectionModels
 * @param {Record<string, any>} [appsEnabledById={}]
 * @returns {Array<object>}
 */
export function defaultContentModels(
  contentModels,
  allCollectionModels,
  appsEnabledById = {},
) {
  const defaultModels = [];

  for (const [collection, collectionModel] of Object.entries(
    allCollectionModels,
  )) {
    const contentModel = find(contentModels, { collection });

    const standardModel = standardContentModel(
      collectionModel,
      allCollectionModels,
    );

    if (standardModel) {
      defaultModels.push(standardModel);
    }

    if (!standardModel && !contentModel) {
      defaultModels.push(
        defaultContentModel(
          collectionModel,
          allCollectionModels,
          appsEnabledById,
        ),
      );
    }
  }

  return defaultModels;
}

export function assignModelsToChildCollections(allContentModels) {
  for (const contentModel of allContentModels) {
    for (const field of contentModel.fields) {
      assignModelsToChildCollectionFields(field, contentModel);
    }

    for (const contentView of contentModel.views) {
      for (const field of contentView.fields) {
        assignModelsToChildCollectionFields(field, contentModel);
      }

      for (const contentTab of contentView.tabs) {
        for (const field of contentTab.fields || []) {
          assignModelsToChildCollectionFields(field, contentModel);
        }
      }
    }
  }
}

export function isFieldGroup(field) {
  switch (field.type) {
    case 'field_row':
    case 'field_group':
      return true;
    default:
      return false;
  }
}

function assignModelsToChildCollectionFields(field, contentModel) {
  if (isFieldGroup(field)) {
    for (const childField of field.fields || []) {
      assignModelsToChildCollectionFields(childField, contentModel);
    }

    return;
  }

  if (!field.child || !field.collectionModel) {
    return;
  }

  switch (field.type) {
    case 'collection': {
      field.contentModel = defaultChildCollectionModel(
        field.collectionModel,
        field.childCollectionModel,
        field.id,
      );

      // Assign default fields
      if (!field.fields?.length) {
        field.fields =
          field.contentModel?.fields ||
          defaultCollectionFields(
            field.childCollectionModel || field.collectionModel,
            field.contentModel,
          );
      }

      break;
    }

    case 'lookup': {
      // Assign properties from link collection
      if (field.childCollectionModel) {
        field.contentModel = defaultChildCollectionModel(
          field.collectionModel,
          field.childCollectionModel,
          field.id,
        );
      } else {
        field.contentModel = defaultChildCollectionModel(field.collectionModel);
      }

      if (!field.fields?.length) {
        field.fields =
          field.contentModel?.fields ||
          defaultCollectionFields(field.collectionModel, field.contentModel);
      }

      break;
    }

    default:
      return;
  }

  if (!field.contentModel) {
    return;
  }

  // Assign a list field definition for each field in a child collection
  if (field.collection) {
    for (const childField of field.fields || []) {
      if (childField.childCollectionField) continue;
      childField.childCollectionField = normalizeCollectionListField(
        childField,
        field.contentModel,
      );
    }
  }

  // Remove field references to the parent content model
  remove(
    field.fields,
    (field) =>
      field.childCollectionField?.collection === contentModel.collection,
  );

  assignViewToCollectionField(field);
}

function assignViewToCollectionField(field) {
  // Assign a view for use in child collection
  // 1) Map child collection fields
  // 2) Flatten field rows and groups
  // 3) Remove undefined, conditional, and parent reference fields
  const childFields = flattenContentModelFields(field.fields).filter(
    (f) =>
      !f.conditions &&
      f.id !== 'parent' &&
      field.parentContentModel?.collection !== f.collection,
  );

  // Get default tabs/filters from related content view
  const contentView = find(
    CONTENT_MODEL_VIEWS[field.childCollection || field.collection],
    (view) =>
      view.id === 'list' &&
      view.type === 'list' &&
      (!field.app_id || view.app_id === field.app_id),
  );

  const gid = `${
    contentView?.gid ||
    `${
      field.contentModel?.id || field.childCollection || field.collection
    }.list`
  }:${field.id}`;

  const viewProps = {
    id: 'list',
    type: 'list',
    gid,
    collection: field.collection,
    collectionModel: field.collectionModel,
    childCollection: field.childCollection,
    childCollectionModel: field.childCollectionModel,
    contentModel: field.contentModel,
    parentContentModel: field.parentContentModel,
    fields: childFields,
    tabs: contentView?.tabs,
    filters: contentView?.filters,
  };

  field.contentView = normalizeContentView(field.contentModel, viewProps);
}

function defaultChildCollectionModel(
  collectionModel,
  childCollectionModel,
  childFieldId,
) {
  let childContentModel;
  if (childCollectionModel) {
    const contentModel = defaultChildCollectionModel(childCollectionModel);
    if (contentModel?.fields) {
      childContentModel = contentModel;
    }
  }

  if (
    !CONTENT_MODEL_VIEWS[collectionModel.collection] &&
    !CONTENT_MODELS[collectionModel.collection]
  ) {
    return null;
  }

  // Try to find a list view, app and then standard
  const contentView =
    find(
      CONTENT_MODEL_VIEWS[collectionModel.collection],
      (view) => view.app_id === collectionModel.app_id && view.id === 'edit',
    ) ||
    find(
      CONTENT_MODEL_VIEWS[collectionModel.collection],
      (view) => view.standard && view.id === 'edit',
    );

  if (contentView?.fields?.length) {
    // Fall back to field of parent model
    if (childCollectionModel && childFieldId) {
      const parentModelFields = find(contentView.fields, {
        id: childFieldId,
      })?.fields;

      if (parentModelFields) {
        return {
          ...contentView.contentModel,
          childCollectionModel,
          childCollection: childCollectionModel.collection,
          fields: parentModelFields,
          views: [],
          actions: [],
          defaults: {},
        };
      }
    } else {
      return {
        ...contentView.contentModel,
        fields: contentView.fields,
      };
    }
  }

  // Then try to find a content model, app and then standard
  const contentModel =
    find(
      CONTENT_MODELS[collectionModel.collection],
      (model) => model.app_id === collectionModel.app_id,
    ) ||
    find(CONTENT_MODELS[collectionModel.collection], (model) => model.standard);

  if (contentModel?.fields?.length) {
    // Fall back to field of parent model
    if (childCollectionModel && childFieldId) {
      const parentModelFields = find(contentModel.fields, {
        id: childFieldId,
      })?.fields;

      return {
        ...contentModel,
        childCollectionModel,
        childCollection: childCollectionModel.collection,
        fields: parentModelFields,
        views: [],
        actions: [],
        defaults: {},
      };
    } else {
      return contentModel;
    }
  }

  if (childContentModel) {
    return childContentModel;
  }

  return null;
}

/**
 * @param {object} [collectionModel]
 * @param {object} [contentModel]
 * @returns {object[]}
 */
function defaultCollectionFields(collectionModel, contentModel) {
  return map(collectionModel?.fields, (field, key) => {
    const collectionField = normalizeCollectionField(
      { id: field.id || key },
      { ...(contentModel || undefined), collectionModel },
    );
    return collectionField;
  }).filter(
    (df) => df && df.id !== 'id' && df.id !== 'parent' && df.id !== 'parent_id',
  );
}

export function standardContentModel(collectionModel, allCollectionModels) {
  const { collection } = collectionModel;
  const standardViews = standardContentViews(collection, allCollectionModels);

  if (!standardViews) {
    return null;
  }

  const contentModel = {
    id: `standard.${collection}.admin`,
    source_type: 'standard',
    source_id: 'admin',
    standard: true,
    collection,
    root: true,
    views: standardViews,
  };

  return normalizeContentModel(
    {
      ...contentModel,
      fields: defaultCollectionFields(collectionModel, contentModel),
    },
    allCollectionModels,
  );
}

function standardContentViews(collection) {
  if (!STANDARD_CONTENT_VIEWS[collection]) {
    return null;
  }

  const contentViews = Array.isArray(STANDARD_CONTENT_VIEWS[collection])
    ? STANDARD_CONTENT_VIEWS[collection]
    : [
        // Default views are hard-coded and have list/edit/new pages
        { id: 'list' },
        { id: 'edit' },
        { id: 'new' },
      ];

  for (let i = 0; i < contentViews.length; i++) {
    contentViews[i] = {
      ...contentViews[i],
      collection,
      gid: `standard.${collection}.${contentViews[i].id}`,
      label: 'Standard view',
      standard: true,
    };
  }

  return contentViews;
}

/**
 * @param {object} collectionModel
 * @param {Array<object>} contentModels
 * @param {string} viewType
 * @param {Record<string, any>} [allCollectionModels={}]
 * @returns {object}
 */
export function defaultContentView(
  collectionModel,
  contentModels,
  viewType,
  allCollectionModels = {},
) {
  if (!collectionModel || !contentModels) {
    return null;
  }

  // Get unique field definitions from all content models
  const fields = Array.from(
    contentModels
      .reduce((map, model) => {
        for (const field of model.fields || []) {
          if (!map.has(field.id)) {
            map.set(field.id, normalizeCollectionField(field, model));
          }
        }

        return map;
      }, new Map())
      .values(),
  );

  const contentModel = {
    ...defaultContentModel(collectionModel, allCollectionModels),
    fields,
  };

  const view = {
    id: viewType,
    type: viewType,
    default: true,
    contentModel,
    ...(collectionModel.parent
      ? {
          collection: collectionModel.parent,
          collectionModel: allCollectionModels[collectionModel.parent],
          childCollection: collectionModel.collection,
          childCollectionModel: allCollectionModels[collectionModel.collection],
        }
      : {
          collection: collectionModel.collection,
          collectionModel: allCollectionModels[collectionModel.collection],
        }),
    // List view should only show "title fields"
    fields: viewType === 'list' ? findContentTitleFields(fields) : fields,
    ...(viewType === 'list'
      ? defaultCollectionListView(collectionModel, fields)
      : defaultCollectionRecordView(collectionModel, fields)),
  };

  if (collectionModel.parent) {
    viewType === 'list'
      ? ensureParentListField(collectionModel, view.fields)
      : ensureParentRecordField(collectionModel, view.fields);
  }

  // Add a name field if not contained in content fields
  const nameField = get(collectionModel.fields, collectionModel.name_field);
  if (
    nameField &&
    nameField.required &&
    !findContentField(
      view.fields,
      (f) => f.id === collectionModel.name_field && !f.root,
    )
  ) {
    view.fields.unshift({
      id: collectionModel.name_field,
      type: 'short_text',
      label: wordify(collectionModel.name_field),
      localized: true,
      required: nameField.required,
      func: (record) =>
        contentRecordName(
          collectionModel.collection,
          record[collectionModel.name_field],
        ),
    });
  }

  return normalizeContentView(contentModel, view, allCollectionModels);
}

function defaultCollectionListView(collectionModel, fields) {
  const listView = {
    label: 'Default view',
    plural: collectionModel.plural,
    singular: collectionModel.singular,
    actions: ['new'],
  };

  return listView;
}

function defaultCollectionRecordView(collectionModel, fields) {
  const recordView = {
    label: 'Default view',
    plural: collectionModel.plural,
    singular: collectionModel.singular,
    actions: ['save'],
    extra_actions: ['delete'],
  };

  return recordView;
}

export function collectionName({ namespace, name, app_id }) {
  const appApp = app_id ? `apps/${app_id}/` : '';
  const appNamespace = namespace ? `${namespace}/${name}` : name;

  return `${appApp}${appNamespace}`;
}

function collectionListViewQuery(viewQuery, fields) {
  const query = { ...(viewQuery || undefined) };

  const linkFields = fields.filter((f) => f.type === 'link');
  for (const field of linkFields) {
    query.include = query.include || {};
    if (!query.include[field.id]) {
      query.include[field.id] = {
        url: `/${field.model || field.collection}/{id}`,
        params: {
          id: contentLookupKey(field),
        },
      };
    }
  }

  const expandFields = fields.filter((f) => f.child);
  for (const field of expandFields) {
    query.expand = query.expand || [];
    if (typeof query.expand === 'string') {
      query.expand = query.expand.split(',');
    }
    query.expand.push(`${field.id}:1`);
  }

  return query;
}

/**
 * @param {object} [contentModel]
 * @returns {string[]}
 */
export function childCollectionExpandQuery(contentModel) {
  const expand = [];

  for (const field of contentModel?.fields || []) {
    const fieldProps = contentFieldProps(field);

    switch (fieldProps.type) {
      case 'lookup':
      case 'link': {
        expand.push(field.key_field ? field.id : `${field.id}:1`);
        break;
      }

      default: {
        if (fieldProps.child) {
          expand.push(`${field.id}:1`);
        }

        break;
      }
    }
  }

  return expand;
}

function findCollectionModelField(field, contentModel) {
  if (!contentModel) {
    return null;
  }

  const { collectionModel } = contentModel;
  if (!collectionModel) {
    return null;
  }

  let fieldPath = contentFieldPath(field, contentModel);

  if (!fieldPath) {
    return null;
  }

  // Remove $app prefix if exists
  if (fieldPath.startsWith('$app.')) {
    fieldPath = fieldPath.split('.').slice(2).join('.');
  }

  const modelFieldPath = fieldPath
    .split('.')
    .reduce(
      (acc, id) => (acc.length > 0 ? `${acc}.fields.${id}` : `fields.${id}`),
      '',
    );
  let modelField = get(collectionModel, modelFieldPath);

  // If the field is in an app, prepend the app id to the field path
  if (contentModel.app_id && collectionModel?.$app?.[contentModel.app_id]) {
    const appFieldPath = `$app.${contentModel.app_id}.${modelFieldPath}`;
    const appModelField = get(collectionModel, appFieldPath);
    if (appModelField && modelField && modelField.type === appModelField.type) {
      modelField = merge(cloneDeep(modelField), appModelField);
    } else {
      modelField = appModelField;
    }
  }

  if (!modelField) {
    return null;
  }

  return {
    ...modelField,
    name: modelField.name || modelFieldPath.split('.').pop(),
  };
}

/**
 * Returns the field that match id and root within fields list
 *
 * @param {string} fieldId
 * @param {string} fieldRoot
 * @param {Array<object>} fields
 * @returns {object}
 */
export function findContentModelField(fieldId, fieldRoot, fields) {
  if (!fieldId) {
    return;
  }

  for (const field of fields || []) {
    if (field.id === fieldId && field.root === fieldRoot) {
      return field;
    }

    if (field.fields) {
      const contentModelField = findContentModelField(
        fieldId,
        fieldRoot,
        field.fields,
      );

      if (contentModelField) {
        return contentModelField;
      }
    }
  }
}

function flattenContentModelFields(fields) {
  const flatFields = [];

  for (const field of fields || []) {
    if (field) {
      if (!isFieldGroup(field)) {
        flatFields.push(field);
      }

      if (field.fields) {
        flatFields.push(...flattenContentModelFields(field.fields));
      }
    }
  }

  return flatFields;
}

function collectionModelFieldDefaultProps(field, contentModel) {
  const dataField = findCollectionModelField(field, contentModel);

  if (!dataField) {
    return {};
  }

  const { collectionModel } = contentModel;

  const defaultProps =
    collectionModelFieldToContentField(field, contentModel, collectionModel) ||
    {};

  if (dataField.label) {
    defaultProps.label = dataField.label;
  }

  if (dataField.default !== undefined && !dataField.default?.$formula) {
    // Default, does not support formulas
    defaultProps.default = dataField.default;
  }

  // Enums
  if (dataField.enum) {
    defaultProps.options = dataField.enum;
  }

  switch (dataField.type) {
    case 'array': {
      defaultProps.type = 'collection';

      if (dataField.label) {
        defaultProps.label = dataField.plural;
      }

      break;
    }

    case 'collection': {
      defaultProps.child = true;
      defaultProps.type = 'collection';
      defaultProps.parentContentModel = contentModel;
      defaultProps.collection = collectionModel.collection;
      defaultProps.collectionModel = collectionModel;

      if (dataField.label) {
        defaultProps.label = dataField.plural;
      }

      const childCollectionModel =
        dataField.parent &&
        COLLECTION_MODELS[
          collectionModel.collection.replace(dataField.parent, dataField.name)
        ];

      if (childCollectionModel) {
        defaultProps.childCollection = childCollectionModel.collection;
        defaultProps.childCollectionModel = childCollectionModel;
      }

      break;
    }

    case 'link': {
      defaultProps.type = 'lookup';

      if (dataField.label) {
        defaultProps.label = dataField.plural;
      }

      // Assign properties from link collection
      if (field.type === 'collection') {
        defaultProps.child = true;
        defaultProps.parentContentModel = contentModel;

        // Linked collections are readonly by default
        defaultProps.readonly = field.readonly ?? true;

        field.collection = dataField.model || field.collection;

        let childLinkCollectionModel;
        let linkCollectionModel = COLLECTION_MODELS[field.collection];

        if (linkCollectionModel) {
          // Reassign if targeting child collection
          if (linkCollectionModel.parent) {
            childLinkCollectionModel = linkCollectionModel;
            linkCollectionModel = COLLECTION_MODELS[linkCollectionModel.parent];
          }

          defaultProps.collection = linkCollectionModel.collection;
          defaultProps.collectionModel = linkCollectionModel;

          if (childLinkCollectionModel) {
            defaultProps.childCollection = childLinkCollectionModel.collection;
            defaultProps.childCollectionModel = childLinkCollectionModel;
          }
        }
      } else if (field.type === 'lookup') {
        defaultProps.collection = field.collection || dataField.model;
      }

      // Set parent field label if undefined
      if (
        defaultProps.id === 'parent' &&
        !field.label &&
        collectionModel.parent
      ) {
        const parentModelCollection =
          COLLECTION_MODELS[collectionModel.fields?.parent?.model];
        defaultProps.label = parentModelCollection?.singular;
      }

      break;
    }

    default:
      break;
  }

  return defaultProps;
}

export function normalizeCollectionField(field, contentModel) {
  if (!field) {
    return {};
  }

  const { id: fieldId, root: fieldRoot } = field;
  const { fields, source_type, collection } = contentModel || {};
  const contentModelField = findContentModelField(fieldId, fieldRoot, fields);

  if (!contentModelField && fieldId && source_type === 'app') {
    console.warn(
      `The "${fieldId}" field will not be rendered because it is not declared in the "${collection}" model.`,
    );
  }

  const fieldProps = {
    ...field,
    ...contentFieldProps(field),
    ...(contentModelField ? contentFieldProps(contentModelField) : undefined),
  };

  const defaultProps = collectionModelFieldDefaultProps(
    fieldProps,
    contentModel,
  );

  const fieldResult = {
    label: contentFieldLabel(field),
    ...defaultProps,
    ...fieldProps,
    id: fieldProps.id,
  };

  if (!fieldResult.type) {
    return;
  }

  if (isFieldGroup(fieldResult)) {
    fieldResult.fields = fieldResult.fields.map((f) =>
      normalizeCollectionField(f, contentModel),
    );
  }

  return fieldResult;
}

/**
 * Used for top-level content models depopulating parent lookup fields
 *
 * @param {object} contentModel
 */
export function getParentModelField(contentModel) {
  return {
    id: 'parent',
    type: 'lookup',
    collection: contentModel.collection,
  };
}

function ensureParentListField(collectionModel, fields) {
  if (!findContentField(fields, (f) => f.id === 'parent' && !f.root)) {
    // At the end
    fields.push({
      id: 'parent',
      label: collectionModel.singular,
      type: 'lookup',
      collection: collectionModel.collection,
    });
  }
}

function ensureParentRecordField(collectionModel, fields) {
  if (!findContentField(fields, (f) => f.id === 'parent' && !f.root)) {
    // At the beginning
    fields.unshift({
      id: 'parent',
      label: collectionModel.singular,
      type: 'lookup',
      collection: collectionModel.collection,
      required: true,
    });
  }
}

function normalizeCollectionListField(field, contentModel) {
  const normalField = normalizeCollectionField(field, contentModel);

  // Use app path if app defined defined on a standard model
  if (
    normalField &&
    contentModel.app_id &&
    contentModel.app_id !== contentModel?.collectionModel?.app_id
  ) {
    normalField.path = `$app.${
      contentModel.app?.slug_id || contentModel.app_id
    }.${normalField.id}`;
  }

  switch (normalField?.type) {
    case 'short_text':
      normalField.type = 'string';
      break;
    case 'long_text':
      normalField.type = 'string';
      break;
    case 'number':
      if (normalField.ui === 'currency') {
        normalField.type = 'currency';
      } else {
        normalField.type = 'string';
      }
      break;
    case 'select':
      normalField.type = 'string';
      break;
    case 'boolean':
      normalField.type = 'string';
      normalField.func =
        normalField.func ||
        ((record) => (get(record, normalField.id) ? 'Yes' : 'No'));
      break;
    case 'date':
      normalField.type = 'date';
      break;
    case 'asset':
      if (normalField.ui === 'image') {
        normalField.type = 'image';
      }
      break;
    case 'tags':
      normalField.type = 'string';
      break;
    case 'color':
      normalField.type = 'string';
      break;
    case 'icon':
      normalField.type = 'image';
      break;
    case 'lookup':
      normalField.type = 'link';
      normalField.key = contentLookupKey(normalField);

      normalField.url =
        normalField.link ||
        `${collectionLinkTarget(normalField)}/{${normalField.key}}`;

      normalField.func =
        normalField.func ||
        ((record) => {
          const value = get(record, normalField.id, {});
          return (
            value &&
            contentRecordName(
              normalField.model || normalField.collection,
              value,
            )
          );
        });
      break;
    case 'collection':
      normalField.type = 'string';
      normalField.func =
        normalField.func ||
        ((record) => {
          return get(
            record,
            normalField.child
              ? `${normalField.id}.count`
              : `${normalField.id}.length`,
            0,
          );
        });
      break;
    default:
      break;
  }
  return normalField;
}

function normalizeCollectionViewField(field, contentView) {
  if (!field.id || isFieldGroup(field)) {
    return field;
  }

  const { contentModel, collectionModel } = contentView;

  const contentField = findContentField(contentModel?.fields, field.id);
  if (contentField) {
    return { ...contentField, ...field };
  }

  const modelContentField = collectionModelFieldToContentField(
    field,
    contentModel,
    collectionModel,
  );
  if (modelContentField) {
    return modelContentField;
  }

  return field;
}

function collectionModelFieldToContentField(
  field,
  contentModel,
  collectionModel,
) {
  const modelField = findCollectionModelField(field, contentModel);

  if (modelField) {
    const { name } = modelField;

    const label =
      field.label || collectionModel?.parent
        ? `${collectionModel.singular} ${wordify(name).toLowerCase()}`
        : wordify(name);

    const fieldProps = { ...field, label };

    switch (modelField.type) {
      case 'objectid':
        return null;

      case 'string': {
        if (modelField.multiline) {
          return { ...fieldProps, type: 'long_text' };
        }

        return { ...fieldProps, type: 'short_text' };
      }

      case 'bool':
        return { ...fieldProps, type: 'boolean', ui: 'toggle' };

      case 'int': {
        return { ...fieldProps, type: 'number' };
      }

      case 'float':
        return { ...fieldProps, type: 'number', ui: 'float' };

      case 'currency':
        return { ...fieldProps, type: 'number', ui: 'currency' };

      case 'date':
        return { ...fieldProps, type: 'date' };

      case 'link': {
        if (modelField.model) {
          return {
            ...fieldProps,
            type: 'lookup',
            model: modelField.model,
            key_field: modelField.key,
          };
        }

        break;
      }

      case 'array': {
        if (modelField.value_type !== 'object') {
          return null;
        }

        return {
          ...fieldProps,
          type: 'collection',
          fields: objectToArray(modelField.fields)
            .map((objectField) => {
              return collectionModelFieldToContentField(
                { ...field, id: `${field.id}.${objectField.id}` },
                contentModel,
                collectionModel,
              );
            })
            .filter(Boolean),
        };
      }

      case 'collection':
        return null;

      case 'object':
        return null;

      default:
        return null;
    }
  }
}

export function collectionLinkTarget(field) {
  let defaultCollection = field.model || field.collection || field.id;
  switch (defaultCollection) {
    case 'accounts':
      return '/customers';
    default:
      if (!STANDARD_CONTENT_VIEWS[defaultCollection]) {
        return `/collections/${defaultCollection?.replace(/\//g, '_')}`;
      }
      break;
  }
  return `/${defaultCollection}`;
}

function normalizeCollectionFilter(filter) {
  const fieldProps = contentFieldProps(filter);
  const normalFilter = {
    ...filter,
    id: fieldProps.id,
    label: contentFieldLabel(filter),
  };
  switch (fieldProps.type) {
    case 'short_text':
    case 'long_text':
      normalFilter.type = 'text';
      break;
    case 'number':
      normalFilter.type = 'number';
      break;
    case 'select':
      normalFilter.type = 'select';
      break;
    case 'boolean':
      normalFilter.type = 'toggle';
      break;
    case 'date':
      normalFilter.type = 'date';
      break;
    case 'asset':
      break;
    case 'tags':
      normalFilter.type = 'tags';
      break;
    case 'color':
      normalFilter.type = 'color';
      break;
    case 'product_lookup':
      normalFilter.type = 'LookupProduct';
      // TODO more props
      break;
    case 'category_lookup':
      normalFilter.type = 'LookupCategory';
      // TODO more props
      break;
    case 'customer_lookup':
      normalFilter.type = 'LookupCustomer';
      // TODO more props
      break;
    // TODO add support for more lookup types including generic 'lookup'
    // lookup (or generic_lookup?), variant_lookup
    default:
      return null;
  }
  return normalFilter;
}

function normalizeCollectionAction(action, collectionModel) {
  const { singularLower } = collectionModel;
  const uri = collectionLinkTarget({
    collection: collectionModel.collection,
  });

  const normalAction = {
    ...(typeof action === 'string'
      ? { id: action }
      : { ...action, id: action?.id }),
  };
  switch (normalAction.id) {
    case 'new':
      normalAction.label = normalAction.label || `New ${singularLower}`;
      normalAction.link = normalAction.link || `${uri}/new`;
      break;
    case 'save':
      normalAction.label = normalAction.label || `Save ${singularLower}`;
      normalAction.submit = true;
      break;
    case 'delete':
      normalAction.label = normalAction.label || `Delete ${singularLower}`;
      normalAction.className = 'danger';
      normalAction.delete = true;
      break;
    default:
      normalAction.label = normalAction.label || wordify(normalAction.id);
      break;
  }

  return normalAction;
}

/**
 * @param {object} tab
 * @param {string} type
 * @param {object} contentModel
 * @param {object[]} contentViewFields
 */
export function normalizeCollectionTab(
  tab,
  type,
  contentModel,
  contentViewFields,
) {
  const tabFields =
    tab.fields?.length > 0
      ? (tab.fields || [])
          // Normalize the new fields
          .map(
            (field) =>
              find(contentViewFields, { id: field.id }) ||
              (type === 'list'
                ? normalizeCollectionListField(field, contentModel)
                : normalizeCollectionField(field, contentModel)),
          )
          .filter(Boolean)
      : undefined;

  const normalTab = {
    ...tab,
    label: tab.label || wordify(tab.id),
    fields: tabFields,
  };

  // Modify tab query if applied to standard model from app model
  if (
    normalTab.query &&
    contentModel.app_id &&
    contentModel.app_id !== contentModel.collectionModel?.app_id
  ) {
    normalTab.query = { ...normalTab.query };

    for (const key of Object.keys(normalTab.query)) {
      if (!key.startsWith('$')) {
        normalTab.query[
          `$app.${contentModel.app?.slug_id || contentModel.app_id}.${key}`
        ] = normalTab.query[key];

        delete normalTab.query[key];
      }
    }
  }

  return normalTab;
}

/**
 * @template T
 * @param {T[]} fields
 * @param {string | ((field: T) => boolean)} idOrFilter
 * @returns {T | undefined}
 */
export function findContentField(fields, idOrFilter) {
  let foundField;

  find(fields, (field) => {
    switch (typeof idOrFilter) {
      case 'string': {
        if (field.id === idOrFilter) {
          foundField = field;
          return true;
        }

        break;
      }

      case 'function': {
        if (idOrFilter(field)) {
          foundField = field;
          return true;
        }

        break;
      }

      default:
        break;
    }

    if (Array.isArray(field.fields)) {
      foundField = findContentField(field.fields, idOrFilter);

      if (foundField !== undefined) {
        return true;
      }
    }

    return false;
  });

  return foundField;
}
