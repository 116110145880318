import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import actions from 'actions';

import NewPage from 'components/customer/new';
import LoadingView from 'components/view/loading';

export const mapStateToProps = (state) => ({
  data: state.data,
  content: state.content,
  lookup: state.lookup,
  loading: state.data.loading,
  errors: state.data.recordErrors,
  settings: state.settings,
  categories: state.categories,
});

export const mapDispatchToProps = (dispatch) => ({
  createRecord: (data) => {
    return dispatch(actions.data.createRecord('accounts', data));
  },

  loadContentModels: () => {
    return dispatch(actions.content.loadModels('accounts'));
  },

  loadSettings() {
    return dispatch(actions.settings.fetch('accounts'));
  },
});

export class NewCustomer extends React.Component {
  static contextTypes = {
    notifyCreated: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      values: {},
      edited: false,
      onChangeForm: this.onChangeForm.bind(this),
      onSubmitRecord: this.onSubmitRecord.bind(this),
    };
  }

  componentDidMount() {
    const { loadSettings, loadContentModels } = this.props;

    Promise.all([loadSettings(), loadContentModels()]).then(() => {
      this.setState({ loaded: true });
    });
  }

  onChangeForm(values) {
    this.setState((state) => ({
      values: { ...state.values, ...values },
      edited: true,
    }));
  }

  onSubmitRecord(values) {
    const { router, createRecord } = this.props;

    return createRecord(values).then((result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        this.context.notifyCreated(result);
        router.replace(`/customers/${result.id}`);
      }
    });
  }

  render() {
    if (!this.state.loaded) {
      return <LoadingView />;
    }

    return <NewPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomer);
