import React from 'react';

export default function CollectionSort({ field, label, sorted, dir, onClick }) {
  return (
    <span
      className="collection-sort"
      onClick={onClick}
      data-field={field}
      data-dir={dir}
      data-sorted={sorted ? 1 : 0}
    >
      {label}
      {sorted && (
        <span className="collection-sort-icon">
          {dir === 'asc' ? (
            <svg viewBox="0 0 20 20">
              <path d="M15 12l-5-5-5 5z" />
            </svg>
          ) : (
            <svg viewBox="0 0 20 20">
              <path d="M5 8l5 5 5-5z" fillRule="evenodd" />
            </svg>
          )}
        </span>
      )}
    </span>
  );
}

// {field.path ? (
//   <span
//     className="collection-sort"
//     onClick={this.onClickSort.bind(this, field)}
//   >
//     {field.label}
//     {sort &&
//       sortField === String(field.path) && (
//         <span className="collection-sort-icon" onClick={onClick}>
//           {sortDirection === 'asc' ? (
//             <svg viewBox="0 0 20 20">
//               <path d="M15 12l-5-5-5 5z" />
//             </svg>
//           ) : (
//             <svg viewBox="0 0 20 20">
//               <path d="M5 8l5 5 5-5z" fillRule="evenodd" />
//             </svg>
//           )}
//         </span>
//       )}
//   </span>
// ) : (
//   field.label
// )}
