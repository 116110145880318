import React, { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import pt from 'prop-types';

import {
  countryName,
  countryOptions,
  stateOptions,
  countryStateLabel,
} from 'utils/geo';

import { Form, Field } from 'components/form';
import Modal from 'components/modal';
import View from 'components/view';
import ButtonLink from 'components/button/link';
import SectionHeader from 'components/section-header';

import ShippingLocations from './shipping-locations';
import ShippingServices from './shipping-services';
import ShippingZones from './shipping-zones';

import './settings.scss';

export default class ShippingSettings extends React.PureComponent {
  static propTypes = {
    values: pt.object,
    edited: pt.bool,
    settings: pt.object,
    suggestedAddresses: pt.array,

    onChangeForm: pt.func,
    onSubmitForm: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      editValues: null,
      showingEditLocation: false,
    };
  }

  onClickChangeMultiLocation = (event) => {
    event.preventDefault();
    const { values } = this.props;
    this.context.openModal('Confirm', {
      title: values.features.multi_location
        ? 'Disable multi-locations'
        : 'Enable multi-locations',
      message: (
        <p>
          This feature allows you to assign inventory to multiple locations.
          <br />
          <br />
          <b>
            Do you want to{' '}
            {values.features.multi_location ? 'disable' : 'enable'} it?
          </b>
        </p>
      ),
      action: values.features.multi_location ? 'Disable' : 'Enable',
      actionType: values.features.multi_location ? 'danger' : 'default',
      width: 400,
      onConfirm: () => {
        this.props.onSubmitForm({
          ...values,
          features: {
            ...values.features,
            multi_location: !values.features.multi_location,
          },
        });
      },
    });
  };

  onSubmitLocations = (values) => {
    return this.props.onSubmitForm({ locations: values });
  };

  onSubmitZones = (values) => {
    return this.props.onSubmitForm({ zones: values });
  };

  onSubmitServices = (values) => {
    return this.props.onSubmitForm({ services: values });
  };

  onClickEditLocation = (event) => {
    event.preventDefault();
    this.setState({
      editValues: cloneDeep(this.props.values),
      showingEditLocation: !this.state.showingEditLocation,
    });
  };

  onChangeEditLocation = (values) => {
    this.setState({
      editValues: { ...values },
    });
  };

  onSubmitEditLocation = async (values) => {
    const result = await this.props.onSubmitForm(values);
    if (result) {
      this.setState({ showingEditLocation: false });
    }
  };

  onAddress = (option) => {
    const { values } = this.props;
    const { editValues } = this.state;

    if (option) {
      this.setState({
        editValues: { ...values },
      });
      this.onChangeEditLocation({
        locations: editValues.locations.map((location, index) => {
          if (index === 0) {
            return {
              address1: option.suggestedAddress.street_line,
              address2:
                option.suggestedAddress.entries < 2
                  ? option.suggestedAddress.secondary
                  : '',
              city: option.suggestedAddress.city,
              country: 'US',
              state: option.suggestedAddress.state,
              zip: option.suggestedAddress.zipcode,
            };
          }

          return location;
        }),
      });
    }
  };

  renderEditLocationModal() {
    const values = this.state.editValues;
    const { suggestedAddresses } = this.props;
    const defaultLocation = (values.locations ? values.locations[0] : {}) || {};

    return (
      <Form
        onSubmit={this.onSubmitEditLocation}
        onChange={this.onChangeEditLocation}
      >
        <Modal
          title="Edit store location"
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'cancel',
              onClick: this.onClickEditLocation,
            },
          ]}
          width={600}
          cancel={false}
          onClose={this.onClickEditLocation}
        >
          <fieldset>
            <Field
              type="hidden"
              name="locations[0].id"
              defaultValue={defaultLocation.id || 'default'}
            />
            <Field
              type="hidden"
              name="locations[0].name"
              defaultValue={defaultLocation.name || 'Default'}
            />
            <Field
              type="address"
              onAddress={this.onAddress}
              options={suggestedAddresses}
              name="locations[0].address1"
              label="Street address"
              defaultValue={defaultLocation.address1}
              required
              values={values}
              debounce={300}
            />
            <Field
              type="text"
              name="locations[0].address2"
              label="Apt, suite, etc"
              defaultValue={defaultLocation.address2}
            />
            <div className="row">
              <Field
                type="text"
                name="locations[0].city"
                label="City"
                defaultValue={defaultLocation.city}
                required={true}
                className="span2"
              />
              <Field
                type="text"
                name="locations[0].zip"
                label="Zip/postal code"
                defaultValue={defaultLocation.zip}
                className="span2"
              />
            </div>
            <div className="row">
              <Field
                type="select"
                name="locations[0].country"
                label="Country"
                defaultValue={defaultLocation.country}
                options={countryOptions}
                required={true}
                className="span2"
              />
              <Field
                type={stateOptions[defaultLocation.country] ? 'select' : 'text'}
                name="locations[0].state"
                label={countryStateLabel(defaultLocation.country)}
                defaultValue={defaultLocation.state}
                options={stateOptions[defaultLocation.country]}
                className="span2"
              />
            </div>
          </fieldset>
        </Modal>
      </Form>
    );
  }

  render() {
    const {
      values,
      edited,
      settings,
      onSubmitForm,
      onChangeForm,
      suggestedAddresses,
    } = this.props;

    const defaultLocation = (values.locations ? values.locations[0] : {}) || {};

    return (
      <div className="settings settings-shipping">
        <Form ref="form" onSubmit={onSubmitForm} onChange={onChangeForm}>
          <View
            detail={true}
            uri="/settings"
            sectionTitle="Settings"
            headerTitle="Shipping"
            headerActions={[
              {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
          >
            <fieldset className="full last-child">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title={
                    'Store location' +
                    (values.features.multi_location ? 's' : '')
                  }
                  subtitle="This information is used to calculate shipping rates"
                />
              </div>
              {values.features.multi_location ? (
                <Fragment>
                  <ShippingLocations
                    onChangeForm={onChangeForm}
                    suggestedAddresses={suggestedAddresses}
                    values={values}
                    onSubmit={this.onSubmitLocations}
                  />
                  <br />
                </Fragment>
              ) : defaultLocation.id ? (
                <div className="box box-grid">
                  <div className="box-column">
                    {defaultLocation.name &&
                      defaultLocation.name !== 'Default' && (
                        <div className="box-title">{defaultLocation.name}</div>
                      )}
                    {defaultLocation.address1 && (
                      <div>{defaultLocation.address1}</div>
                    )}
                    {defaultLocation.address2 && (
                      <div>{defaultLocation.address2}</div>
                    )}
                    <div>
                      {defaultLocation.city && `${defaultLocation.city}, `}
                      {defaultLocation.state && `${defaultLocation.state} `}
                    </div>
                    {defaultLocation.zip && <div>{defaultLocation.zip}</div>}
                    {defaultLocation.country && (
                      <div>{countryName(defaultLocation.country)}</div>
                    )}
                  </div>
                  <div className="box-column">
                    <button
                      className="as-link"
                      onClick={this.onClickEditLocation}
                      type="button"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <ButtonLink size="sm" onClick={this.onClickEditLocation}>
                    Configure
                  </ButtonLink>
                </div>
              )}
              <p className="note">
                {values.features.multi_location ? (
                  <span>
                    You have enabled <u>multi-location</u>.{' '}
                    <button
                      className="as-link"
                      onClick={this.onClickChangeMultiLocation}
                      type="button"
                    >
                      Disable
                    </button>
                  </span>
                ) : (
                  <span>
                    Use <u>multi-location</u> to associate products with
                    different locations.{' '}
                    <button
                      className="as-link"
                      onClick={this.onClickChangeMultiLocation}
                      type="button"
                    >
                      Enable
                    </button>
                  </span>
                )}
              </p>
            </fieldset>
            <fieldset className="full">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title="Shipping zones"
                  subtitle="Create shipping zones to specify what parts of the world you ship to"
                />
              </div>
              <ShippingZones
                zones={values.zones}
                onSubmit={this.onSubmitZones}
              />
            </fieldset>
            <fieldset className="full">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title="Shipping services"
                  subtitle="Create shipping services for customers to choose from at checkout"
                />
              </div>
              <ShippingServices
                services={values.services}
                zones={values.zones}
                settings={settings}
                onSubmit={this.onSubmitServices}
              />
            </fieldset>
          </View>
        </Form>
        {this.state.showingEditLocation && this.renderEditLocationModal()}
      </div>
    );
  }
}
