import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Modal from 'components/modal';
import Alert from 'components/alert';
import { Form, Field, FieldLocalized, TabView, Label } from 'components/form';
import ButtonLink from 'components/button/link';
import LocaleFieldSelect from 'components/locale/field-select';
import TemplateEngine from 'swell-template';
import TemplatePreview from 'components/template-preview';
import NotificationFields from 'components/settings/notifications-fields';
import { isValueEqual, localeFallbackValue } from 'utils';
import api from 'services/api';
import $ from 'jquery';

export default class EditNotificationModalPage extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
  };

  getTemplateEngine = () => {
    const { client } = this.context;

    if (this.templateEngine) {
      return this.templateEngine;
    }
    return (this.templateEngine = new TemplateEngine({
      get: (url, data) => {
        return api.getLocalized(`/data/${url}`, data);
      },
      details: {
        ...client,
      },
    }));
  };

  render = () => {
    const {
      client,
      notifications,
      loading,
      noteValues,
      noteEdited,
      noteLoading,
      onChange,
      onSubmit,
      onClickRevertNotification,
      onClickSendNotification,
      onSubmitNotificationFieldsForm,
      onTemplateContentKeyDown,
      setPreviewReadyState,
      setFormRef,
    } = this.props;
    const { record, defaultRecord, defaultContent } = notifications;

    if (!record) {
      return null;
    }

    const isV2 = client.v2 || client.v2_notifications;

    const isCustom = record.client_id && !record.extends;

    const isCustomized =
      defaultRecord &&
      (noteValues.content !== defaultContent ||
        noteValues.subject !== defaultRecord.subject ||
        noteValues.contact !== defaultRecord.contact ||
        !isValueEqual(noteValues.query, defaultRecord.query) ||
        !isValueEqual(noteValues.conditions, defaultRecord.conditions) ||
        !isValueEqual(noteValues.fields, defaultRecord.fields));

    const hasLocales = client.locales.length > 0;
    let subjectValue =
      noteValues.subject || record.subject || defaultRecord.sub || '';

    if (noteValues.preview_locale) {
      const context =
        noteValues.$locale || noteValues.subject ? noteValues : record;
      subjectValue = localeFallbackValue({
        context,
        name: 'subject',
        locale: noteValues.preview_locale,
        localeConfigs: client.locales,
      });
    }

    // Hack to prevent autosize template from janking scroll position
    if (this.templateModalScrollTop) {
      const $modal = $('.modal');
      if ($modal[0]) {
        $modal[0].scrollTop = this.templateModalScrollTop;
        this.templateModalScrollTop = null;
      }
    }

    return (
      <Form ref={setFormRef} onSubmit={onSubmit} onChange={onChange}>
        <Modal
          title={record.label}
          ref="showModal"
          width={960}
          actions={[
            {
              label: 'Save',
              type: 'submit',
              styleType: noteEdited ? 'default' : 'secondary',
            },
            isCustomized && {
              label: 'Revert to default',
              type: 'secondary',
              onClick: onClickRevertNotification,
            },
            noteValues.tab === 'fields' && {
              component: (
                <Field
                  type="toggle"
                  name="editing_fields"
                  className="left"
                  label="Edit fields"
                  defaultChecked={false}
                />
              ),
            },
          ]}
          loading={noteLoading || loading}
          localized={true}
          tabs={[
            { value: 'preview', label: 'Preview' },
            noteValues.fields && { value: 'fields', label: 'Fields' },
            { value: 'template', label: 'Template' },
            { value: 'settings', label: 'Settings' },
            { value: 'test', label: 'Test email' },
          ]}
        >
          <fieldset>
            {!isV2 && (
              <Alert type="info">
                <b>Note:</b> Your account is currently setup to send v1
                notifications by default.
                <br />
                Please contact us when you're ready to make the switch.
              </Alert>
            )}
            <TabView value={noteValues.tab} active="preview" default>
              <div className="row">
                <div className={`form-field ${hasLocales ? 'span2' : 'span4'}`}>
                  <Label>Subject</Label>
                  <TemplatePreview
                    client={client}
                    className="preview-subject"
                    defaults={notifications}
                    values={{ ...noteValues, content: subjectValue }}
                    onReadyStateChange={setPreviewReadyState}
                    getTemplateEngine={this.getTemplateEngine}
                    visible={!noteValues.tab || noteValues.tab === 'preview'}
                  />
                </div>
                {hasLocales && (
                  <LocaleFieldSelect
                    name="preview_locale"
                    label="Preview locale"
                    className="span2"
                    defaultValue={noteValues.preview_locale || client.locale}
                  />
                )}
              </div>
              <div>
                <div className="form-field">
                  <Label>Email body</Label>
                  <TemplatePreview
                    className="preview-content"
                    client={client}
                    defaults={notifications}
                    values={noteValues}
                    onReadyStateChange={setPreviewReadyState}
                    getTemplateEngine={this.getTemplateEngine}
                    visible={!noteValues.tab || noteValues.tab === 'preview'}
                  />
                </div>
              </div>
            </TabView>
            {noteValues.fields && (
              <TabView value={noteValues.tab} active="fields">
                <NotificationFields
                  values={noteValues}
                  record={record}
                  onSubmit={onSubmitNotificationFieldsForm}
                  editing={noteValues.editing_fields}
                />
              </TabView>
            )}
            <TabView value={noteValues.tab} active="template">
              <FieldLocalized
                type="text"
                name="subject"
                label="Email subject"
                defaultValue={noteValues.subject}
                localeValue={noteValues.$locale}
              />
              {noteValues.tab === 'template' ? (
                <Field
                  type="textarea"
                  name="content"
                  label="Email body"
                  defaultValue={noteValues.content}
                  autoSize={true}
                  onKeyDown={onTemplateContentKeyDown}
                  className="code"
                />
              ) : (
                <Field
                  type="hidden"
                  name="content"
                  value={noteValues.content}
                />
              )}
            </TabView>
            <TabView value={noteValues.tab} active="settings">
              {isCustom && (
                <Fragment>
                  <div className="row">
                    <Field
                      type="text"
                      name="label"
                      label="Label"
                      help="For internal use, not visible to customers"
                      defaultValue={record.label}
                      placeholder={record.label || record.name}
                      required={!!record.label}
                      className="span2"
                    />
                    <Field
                      type="text"
                      name="name"
                      label="Internal ID"
                      help="For developer use, not visible to customers"
                      defaultValue={record.name.replace('v2', '')}
                      placeholder={record.name}
                      readonly={true}
                      disabled={true}
                      className="span2"
                    />
                  </div>
                  <Field
                    type="textarea"
                    name="description"
                    label="Description"
                    help="For internal use, not visible to customers"
                    defaultValue={record.description}
                    placeholder={record.description}
                    required={!!record.description}
                  />
                </Fragment>
              )}
              <Field
                type="text"
                name="from"
                label={
                  <span>
                    From email <span className="muted">(optional)</span>
                  </span>
                }
                defaultValue={noteValues.from || noteValues.replyto}
                placeholder={this.context.client.support_email}
                rules="email"
              />
              <Field
                type="text"
                name="bcc"
                label={
                  <span>
                    BCC emails <span className="muted">(optional)</span>
                  </span>
                }
                defaultValue={noteValues.bcc}
                placeholder="Comma separated list of emails"
              />
            </TabView>
            <TabView value={noteValues.tab} active="test">
              <div className="row">
                <Field
                  type="text"
                  name="send_to"
                  label="Send to email"
                  defaultValue={this.context.user.email}
                  placeholder={this.context.user.email}
                  selectFocus={true}
                  className={hasLocales ? 'span2' : 'span4'}
                />
                {hasLocales && (
                  <LocaleFieldSelect
                    name="preview_locale"
                    label="Test locale"
                    className="span2"
                    defaultValue={noteValues.preview_locale || client.locale}
                  />
                )}
              </div>
              <ButtonLink size="sm" onClick={onClickSendNotification}>
                Send
              </ButtonLink>
            </TabView>
          </fieldset>
        </Modal>
      </Form>
    );
  };
}
