// @flow
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import ManageAttributesModalPage from 'components/pages/modals/manage-attributes';
import actions from 'actions';

const mapStateToProps = (state) => ({
  lookup: state.lookup,
  attributes: state.attributes,
  loading: state.attributes.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadAttributes: async () => {
    return dispatch(actions.attributes.load());
  },

  loadProductSettings: async () => {
    return dispatch(actions.settings.load('products'));
  },
});

export class ManageAttributesModal extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
  };

  state = {};

  constructor(props: Object) {
    super(props);
    this.state = {
      loaded: false,
      typeName: '',
      onSubmitForm: this.onSubmitForm.bind(this),
    };
  }

  componentWillMount() {
    const { loadAttributes, loadProductSettings } = this.props;

    loadAttributes().then(() => {
      loadProductSettings().then((productSettings) => {
        const type = productSettings.typeIndex[this.props.type] || {};
        this.setState({
          loaded: true,
          typeName: type.name,
        });
      });
    });
  }

  onSubmitForm(values: Object) {}

  render() {
    return <ManageAttributesModalPage {...this.props} {...this.state} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageAttributesModal);
