import PropTypes from 'prop-types';
import React from 'react';
import { set, map, each, cloneDeep } from 'lodash';
import { CSSTransitionGroup } from 'react-css-transition';
import { FadeIn } from 'components/transitions';
import './form.scss';
import Icon from 'components/icon';

const OBJ = {};

export default class Fieldlist extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    label: PropTypes.string,
    fieldLabels: PropTypes.bool,
    addRemove: PropTypes.bool,
  };

  static contextTypes = {
    registerField: PropTypes.func,
    unregisterField: PropTypes.func,
    onChangeField: PropTypes.func,
    subscribeChange: PropTypes.func,
    unsubscribeChange: PropTypes.func,
  };

  static childContextTypes = {
    formValues: PropTypes.object,
    registerField: PropTypes.func,
    unregisterField: PropTypes.func,
    onChangeField: PropTypes.func,
  };

  getChildContext() {
    return {
      formValues: {},
      registerField: this.registerField,
      unregisterField: this.unregisterField,
      onChangeField: null,
    };
  }

  fieldCanFocus = false;

  constructor(props) {
    super(props);
    this.state = {
      value: this.getInitialValue(props),
      defaultValue: this.getInitialDefaultValue(props),
      disabled: typeof props.disabled === 'function' ? false : props.disabled,
    };
    this.fields = {};
    this.registerField = this.registerField.bind(this);
    this.unregisterField = this.unregisterField.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeForm = this.onChangeForm.bind(this);
    this.onClickAddRow = this.onClickAddRow.bind(this);
    this.onClickRemoveRow = this.onClickRemoveRow.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: this.getInitialValue(nextProps),
      });
    }
    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        value: this.getInitialValue(nextProps),
        defaultValue: this.getInitialDefaultValue(nextProps),
      });
    }
  }

  componentWillMount() {
    if (this.context.registerField) {
      this.context.registerField(this);
    }
    if (this.context.subscribeChange) {
      this.context.subscribeChange(this.state.id, this.onChangeForm);
    }
  }

  componentWillUnmount() {
    if (this.context.unregisterField) {
      this.context.unregisterField(this);
    }
    if (this.context.unsubscribeChange) {
      this.context.unsubscribeChange(this.state.id);
    }
  }

  componentDidMount() {
    this.onChangeField();
    setTimeout(() => (this.fieldCanFocus = true), 500);
  }

  registerField(component) {
    this.fields[component.props.name] = component;
    if (this.fieldCanFocus && component.props.autoFocus && component.focus) {
      setTimeout(() => component.focus(), 200);
    }
  }

  unregisterField(component) {
    delete this.fields[component.props.name];
  }

  onChangeForm(values) {
    if (typeof this.props.disabled === 'function') {
      this.setState({ disabled: this.props.disabled(values) });
    }
  }

  getInitialValue(props) {
    if (props.value) {
      return [...cloneDeep(props.value)];
    }
    return this.getInitialDefaultValue(props);
  }

  getInitialDefaultValue(props) {
    if (props.defaultValue) {
      return [...cloneDeep(props.defaultValue)];
    }
    return [];
  }

  onChangeField() {
    if (this.context.onChangeField) {
      this.context.onChangeField(this);
    }
  }

  onClickAddRow(event) {
    event.preventDefault();
    const value = [...this.state.value];
    value.push({});
    this.setState({ value });
  }

  onClickRemoveRow(event) {
    event.preventDefault();
    const index = event.target.dataset.index;
    const value = [...this.state.value];
    const defaultValue = [...this.state.defaultValue];
    value.splice(index, 1);
    defaultValue.splice(index, 1);
    this.setState({ value, defaultValue }, () => this.onChangeField());
  }

  onChangeInput(event, val) {
    if (!event.target) {
      return;
    }
    const value = [...this.state.value];
    const index = event.target.dataset.index;
    const path = event.target.name ? `[${index}].${event.target.name}` : index;
    set(value, path, val || event.target.value);

    this.setState({ value }, () => this.onChangeField());

    if (this.props.onChange) {
      const result = this.props.onChange(event, value);
      if (result === false) {
        this.setState({ value: this.state.value }, () => this.onChangeField());
        return false;
      }
    }
  }

  validate() {
    let isValid = true;
    each(this.fields, (component, name) => {
      isValid = component.validate() && isValid;
    });
    return isValid;
  }

  disabled(isDisabled) {
    this.setState({ disabled: isDisabled });
  }

  value() {
    return map(this.state.value, (value) => value);
  }

  render() {
    const { label, render, fieldLabels, addRemove } = this.props;

    const { value, disabled, defaultValue } = this.state;

    return (
      <div
        className={`form-fieldlist ${
          fieldLabels === false
            ? 'without-field-labels'
            : fieldLabels === true
            ? 'with-field-labels'
            : ''
        }`}
      >
        <CSSTransitionGroup className="form-fieldlist-list">
          {map(value, (val, index) => (
            <FadeIn key={index} className="row" initDisplayStyle="table-row">
              {render({
                value: val,
                defaultValue: (defaultValue && defaultValue[index]) || OBJ,
                disabled,
                index,
                onChange: this.onChangeInput,
              })}
              {addRemove !== false && (
                <span className="col" style={{ width: 46 }}>
                  <a
                    href=""
                    onClick={this.onClickRemoveRow}
                    data-index={index}
                    className="form-fieldlist-remove"
                  >
                    <Icon fa="xmark" faType="solid" />
                  </a>
                </span>
              )}
            </FadeIn>
          ))}
        </CSSTransitionGroup>
        {addRemove !== false && (
          <div className="form-field">
            <a
              href=""
              onClick={this.onClickAddRow}
              className="button button-secondary button-sm form-fieldlist-add form-link"
            >
              Add {label.toLowerCase()}
            </a>
          </div>
        )}
      </div>
    );
  }
}
