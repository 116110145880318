'use strict';

require('core-js/actual');
require('regenerator-runtime/runtime');

global.Promise = require('bluebird');

// Disable those pesky warnings
global.Promise.config({
  warnings: {
    wForgottenReturn: false,
  },
});

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');
