import moment from 'moment-timezone';

// TODO: move all date stuff here

export function timezoneName(timezone) {
  return moment().tz(timezone).format('z');
}

export function getNextBillingDate(interval, date, intervalCount, origDate) {
  if (!interval || !date) return;

  // Interval represents number of days (daily = 1, weekly = 7, monthly = 30)
  // Special case for Monthly which should always land on the same day of the month
  if (interval === 'monthly' || interval === 30) {
    return dateNextMonth(date, intervalCount, origDate);
  }
  let intervalDays = 0;
  if (typeof interval === 'string') {
    if (interval === 'daily') {
      intervalDays = 1;
    } else if (interval === 'weekly') {
      intervalDays = 7;
    } else if (interval === 'yearly') {
      intervalDays = 365;
    }
  }
  if (intervalDays <= 0) {
    throw new Error(
      "Invalid 'interval'; Expected number or string (daily, weekly, monthly, yearly)",
    );
  }
  return time(date) + intervalDays * intervalCount * 86400 * 1000;
}

function getDate(date) {
  if (date === undefined) {
    return Date.now();
  }
  if (typeof date === 'number' || typeof date === 'string') {
    return new Date(date).getTime();
  }
  if (date instanceof Date) {
    return date.getTime();
  }
}

function dateNextMonth(startDate, intervalCount, origDate) {
  const date = new Date(getDate(startDate));
  const nextDate = new Date(date);
  nextDate.setMonth(date.getMonth() + (intervalCount || 1));
  // Correct for shorter month days and auto rollover
  if (nextDate.getDate() !== date.getDate()) {
    nextDate.setDate(nextDate.getDate() - nextDate.getDate());
  }
  if (origDate) {
    origDate = new Date(getDate(origDate));
    nextDate.setDate(origDate.getDate());
    // Correct for shorter month days and auto rollover
    if (nextDate.getDate() !== origDate.getDate()) {
      nextDate.setDate(nextDate.getDate() - nextDate.getDate());
    }
  }
  return nextDate.getTime();
}

function time(date) {
  if (date === undefined) {
    return Date.now();
  }
  if (typeof date === 'number' || typeof date === 'string') {
    return new Date(date).getTime();
  }
  if (date instanceof Date) {
    return date.getTime();
  }
}
