import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

const easeOutQuart = 'cubic-bezier(0.165, 0.84, 0.44, 1)';

export default function SlideDown({ duration = 5000, children, ...props }) {
  const transitionStyles = {
    defaultStyle: {
      height: '0%',
      overflow: 'hidden',
    },
    enterStyle: {
      height: transit('100%', duration, easeOutQuart),
    },
    leaveStyle: {
      height: transit('0%', duration, easeOutQuart),
    },
    activeStyle: {
      height: '100%',
    },
    transitionAppear: true,
  };
  return (
    <CSSTransition {...transitionStyles} {...props}>
      {children}
    </CSSTransition>
  );
}
