import React, { Fragment } from 'react';
import LocaleIcon from 'components/locale/icon';
import { LOCALE_CODES } from 'utils';
import './collection.scss';

export default function CollectionLocale({ children, locale }) {
  return locale && LOCALE_CODES.length > 0 ? (
    <Fragment>
      {children}
      <span className="collection-locale">
        <LocaleIcon locale={locale} />
      </span>
    </Fragment>
  ) : (
    children || null
  );
}
