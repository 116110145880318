import React from 'react';
import classNames from 'classnames';
import pt from 'prop-types';

import Button from 'components/button/button';

export default class FileReader extends React.PureComponent {
  static propTypes = {
    id: pt.string,
    label: pt.string,
    accept: pt.string,
    children: pt.node,
    multiple: pt.bool,
    disabled: pt.bool,
    className: pt.string,

    onFiles: pt.func.isRequired,
  };

  clicked = false;
  inputRef = React.createRef();

  onClickTarget = (event) => {
    event.preventDefault();

    // Detect if the click occurred on a button
    const buttonClicked = event.target.closest('button');

    // Detect if this button is configured to prevent opening the file manager
    const preventClick = event.target.closest(
      'button[data-prevent-click="true"]',
    );

    // If a button was clicked that shouldn't open the file manager, then don't
    // open the file manager
    if (buttonClicked && preventClick) {
      return;
    }

    this.clicked = true;
    this.inputRef.current.click();
  };

  onChangeInput = (event) => {
    this.props.onFiles(event.target.files);
    event.target.value = '';
  };

  onClickInput = (event) => {
    if (this.clicked) {
      this.clicked = false;
    } else {
      event.preventDefault();
    }
  };

  onClickButton = (event) => {
    this.clicked = true;
  };

  render() {
    const {
      id,
      label = 'Upload',
      accept = 'image/*',
      children,
      multiple = true,
      disabled = false,
      className,
    } = this.props;

    return (
      <div>
        <input
          id={id}
          type="file"
          ref={this.inputRef}
          multiple={multiple}
          accept={accept}
          style={{ display: 'none' }}
          disabled={disabled}
          onChange={this.onChangeInput}
          onClick={this.onClickInput}
        />

        <div
          className={classNames('form-file-reader-container', className, {
            disabled,
          })}
          onClick={this.onClickTarget}
        >
          <div className="asset-upload-action-container">
            <div className="asset-action-group">
              {children || (
                <Button type="secondary" size="sm" onClick={this.onClickButton}>
                  {label}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
