// @flow
import { each, find, findIndex } from 'lodash';
import { isCircular, escapeRegExp } from 'utils';
import api from 'services/api';

import lookup from './lookup';

export const CATEGORIES_FETCH = 'categories/fetch';
export const CATEGORIES_RELOAD = 'categories/reload';
export const CATEGORIES_LOOKUP = 'categories/lookup';
export const CATEGORIES_SEARCH = 'categories/search';
export const CATEGORIES_CLEAR_SEARCH = 'categories/clearSearch';
export const CATEGORIES_UPDATE_BATCH = 'categories/updateBatch';
export const CATEGORIES_CREATE = 'categories/create';
export const CATEGORIES_UPDATE = 'categories/update';
export const CATEGORIES_DELETE = 'categories/delete';
export const CATEGORIES_LOADING = 'categories/loading';

const actions = {
  fetch() {
    return {
      type: CATEGORIES_FETCH,
      payload: fetchCategories(),
    };
  },

  add(category) {
    return (dispatch: Function, getState: Function) => {
      const { categories } = getState();
      const updatedCategories = assembleCategories(
        { results: [...categories.results, category] },
        categories.productCounts,
      );
      dispatch({
        type: CATEGORIES_FETCH,
        payload: updatedCategories,
      });
      return updatedCategories;
    };
  },

  load() {
    return (dispatch: Function, getState: Function) => {
      const { categories } = getState();
      if (categories.fetched) {
        return Promise.resolve(categories);
      }
      return dispatch(this.fetch());
    };
  },

  reload() {
    return {
      type: CATEGORIES_RELOAD,
    };
  },

  lookup(query: any, limit: any = null) {
    return async (dispatch: Function, getState: Function) => {
      let lookupResults = [];
      if (typeof query === 'string') {
        const { categories } = getState();
        if (!categories.fetched) {
          return dispatch(this.fetch()).then(() => {
            return dispatch(this.lookup(query, limit));
          });
        }
        const exp = new RegExp(escapeRegExp(query), 'i');
        lookupResults = categories.results.reduce((results, category) => {
          if (exp.test(category.name)) {
            results.push(category);
          }
          return results;
        }, []);
      } else {
        lookupResults = (await fetchCategories(query)).results;
      }
      return Promise.all([
        dispatch({
          type: CATEGORIES_LOOKUP,
          payload: foundCategories(
            { results: lookupResults, count: lookupResults.length },
            query,
          ),
        }),
        dispatch(lookup.results(lookupResults)),
      ]);
    };
  },

  search(query: string, limit: any = null) {
    return (dispatch: Function, getState: Function) => {
      const { categories } = getState();
      if (!categories.fetched) {
        return dispatch(this.fetch()).then(() => {
          return dispatch(this.search(query, limit));
        });
      }
      const exp = new RegExp(escapeRegExp(query), 'i');
      const searchResults = categories.results.reduce((results, category) => {
        if (exp.test(category.name)) {
          results.push(category);
        }
        return results;
      }, []);
      return dispatch({
        type: CATEGORIES_SEARCH,
        payload: foundCategories(
          { results: searchResults, count: searchResults.length },
          query,
        ),
      });
    };
  },

  clearSearch() {
    return {
      type: CATEGORIES_CLEAR_SEARCH,
    };
  },

  updateBatch(updates: Array<Object>) {
    return async function thunk(dispatch) {
      const limit = 1000;
      const payload = [];

      for (let i = 0; i < updates.length; i += limit) {
        const result = await api.put(
          '/data/:batch',
          updates
            .slice(i, i + limit)
            .map(({ id, sort, parent_id, active }) => ({
              url: `/categories/${id}`,
              data: { sort, parent_id, active },
            })),
        );

        payload.push(...result);
      }

      dispatch({
        type: CATEGORIES_UPDATE_BATCH,
        payload,
      });
    };
  },

  create(data: Object) {
    return {
      type: CATEGORIES_CREATE,
      payload: api.post('/data/categories', data),
    };
  },

  update(id: string, data: Object) {
    return {
      type: CATEGORIES_UPDATE,
      payload: api.put(`/data/categories/${id}`, data),
    };
  },

  delete(id: string) {
    return {
      type: CATEGORIES_DELETE,
      payload: api.delete(`/data/categories/${id}`),
    };
  },
};

export default actions;

export const initialState = {
  fetched: false,
  results: [],
  index: new Map(),
  list: [],
  count: 0,
  totalCount: 0,
  productCounts: [],
  found: {
    index: new Map(),
    count: 0,
    query: null,
  },
  lookup: {
    index: new Map(),
    count: 0,
    query: null,
  },
  loading: false,
};

export function reducer(state: Object = initialState, action: Object) {
  switch (action.type) {
    case 'RESET':
      return initialState;

    case CATEGORIES_FETCH: {
      if (action.payload.error) {
        return state;
      }

      return {
        ...state,
        ...action.payload,
        fetched: true,
      };
    }

    case CATEGORIES_RELOAD:
      return {
        ...state,
        fetched: false,
      };

    case CATEGORIES_LOOKUP: {
      if (action.payload.error) {
        return state;
      }

      return {
        ...state,
        lookup: action.payload,
      };
    }

    case CATEGORIES_SEARCH: {
      if (action.payload.error) {
        return state;
      }

      return {
        ...state,
        found: action.payload,
      };
    }

    case CATEGORIES_CLEAR_SEARCH:
      return {
        ...state,
        found: {
          index: new Map(),
          count: 0,
          query: null,
        },
      };

    case CATEGORIES_UPDATE_BATCH: {
      if (action.payload.error) {
        return state;
      }

      const exResults = cloneCategoryResults(state.results);

      /** @type {Map<string, number>} */
      const indexMap = exResults.reduce(
        (map, item, index) => map.set(item.id, index),
        new Map(),
      );

      each(action.payload, ({ id, name, active, sort, parent_id }) => {
        const idx = indexMap.get(id);

        if (idx !== undefined) {
          exResults[idx] = {
            id,
            name,
            active,
            sort,
            parent_id,
          };
        }
      });

      return {
        ...state,
        ...assembleCategories(
          {
            results: exResults,
            count: state.count,
          },
          state.productCounts,
          state.totalCount,
        ),
      };
    }

    case CATEGORIES_CREATE: {
      if (action.payload.error) {
        return state;
      }

      const newResults = [
        ...cloneCategoryResults(state.results),
        action.payload,
      ];

      const newIndex = buildCategoryIndex(newResults);
      const newList = buildCategoryList(newIndex);

      return {
        ...state,
        result: newResults,
        index: newIndex,
        list: newList,
        count: state.count + 1,
      };
    }

    case CATEGORIES_UPDATE: {
      if (action.payload.error) {
        return state;
      }

      const idx = findIndex(state.results, { id: action.payload.id });
      const updateResults = cloneCategoryResults(state.results);

      updateResults[idx] = action.payload;

      const updateIndex = buildCategoryIndex(updateResults);
      const updateList = buildCategoryList(updateIndex);

      return {
        ...state,
        result: updateResults,
        index: updateIndex,
        list: updateList,
      };
    }

    case CATEGORIES_DELETE: {
      if (action.payload.error) {
        return state;
      }

      const delIdx = findIndex(state.results, { id: action.payload.id });
      const delResults = cloneCategoryResults(state.results);

      delete delResults[delIdx];

      const delIndex = buildCategoryIndex(delResults);
      const delList = buildCategoryList(delIndex);

      return {
        ...state,
        result: delResults,
        index: delIndex,
        list: delList,
        count: state.count + 1,
      };
    }

    case CATEGORIES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}

export function buildCategoryIndex(
  results: Array<Object>,
): Map<String, Object> {
  const index = results.reduce((index, category) => {
    return index.set(category.id, category);
  }, new Map());

  results.forEach((category) => {
    const indexParent = index.get(category.parent_id);

    if (category.parent_id && indexParent) {
      const indexCategory = index.get(category.id);

      indexCategory.parent = indexParent;

      if (isCircular(indexCategory.parent, 'parent')) {
        console.error(
          'Circular reference detected in parent category',
          indexCategory.parent,
        );

        delete indexCategory.parent.parent;

        if (indexCategory.parent) {
          delete indexCategory.parent.parent_id;
        }

        delete indexCategory.parent;
        delete indexCategory.parent_id;
      } else {
        indexParent.children = indexParent.children || [];
        indexParent.children.push(indexCategory);
      }
    }
  });

  for (const category of index.values()) {
    fixBadCategoryRelations(category);

    if (category.children) {
      category.children.sort(
        (a, b) =>
          (a.sort ?? Number.POSITIVE_INFINITY) -
          (b.sort ?? Number.POSITIVE_INFINITY),
      );

      category.children.forEach((child, sort) => {
        child.sort = sort;
      });
    }
  }

  return index;
}

function fixBadCategoryRelations(category) {
  if (category.id === category.parent_id) {
    delete category.parent_id;
  }
}

export function buildCategoryList(index: Map<String, Object>) {
  const list = [];

  for (const category of index.values()) {
    if (!category.parent_id) {
      list.push(category);
    }
  }

  list.sort(
    (a, b) =>
      (a.sort ?? Number.POSITIVE_INFINITY) -
      (b.sort ?? Number.POSITIVE_INFINITY),
  );

  list.forEach((category, sort) => {
    category.sort = sort;
  });

  return list;
}

export function cloneCategoryResults(results: Array<Object>) {
  return results.map((category) => ({
    id: category.id,
    name: category.name,
    active: category.active,
    sort: category.sort,
    parent_id: category.parent_id,
    slug: category.slug,
    $locale: category.$locale,
    $currency: category.$currency,
  }));
}

export function getSortedCategoryUpdates(
  sortedResults: Array<Object>,
  origResults: Array<Object>,
) {
  return sortedResults.reduce((updates, category) => {
    if (origResults) {
      const ex = find(origResults, { id: category.id });
      if (!ex) {
        return updates;
      }
      if (
        ex.sort !== category.sort ||
        ex.parent_id !== category.parent_id ||
        ex.active !== category.active
      ) {
        updates.push({
          id: category.id,
          sort: category.sort,
          parent_id: category.parent_id,
          active: category.active,
        });
      }
    } else {
      updates.push({
        id: category.id,
        sort: category.sort,
        parent_id: category.parent_id,
        active: category.active,
      });
    }
    return updates;
  }, []);
}

export function assignProductCounts(
  index: Map<String, Object>,
  productCounts: Array<Object>,
) {
  for (const category of index.values()) {
    category.product_count = 0;
  }

  each(productCounts, (result) => {
    let category = index.get(result.parent_id);

    if (category) {
      category.product_count = (category.product_count || 0) + result.count;
      let { parent } = category;

      while (parent) {
        parent.product_count = parent.product_count || 0;
        parent.product_count += result.count;
        parent = parent.parent;
      }
    }
  });
}

async function fetchCategories(query = {}) {
  const [categories, productCounts, totalCount] = await Promise.all([
    api.getAll('/data/categories', {
      fields: ['id', 'name', 'active', 'sort', 'parent_id', 'slug'],
      ...query,
    }),
    api.get('/data/categories:products/:group', {
      parent_id: 1,
      count: { $sum: 1 },
    }),
    api.get('/data/categories/:count'),
  ]);

  return assembleCategories(categories, productCounts, totalCount);
}

function assembleCategories({ results, count }, productCounts, totalCount) {
  const clonedResults = cloneCategoryResults(results);
  const index = buildCategoryIndex(clonedResults);
  const list = buildCategoryList(index);
  assignProductCounts(index, productCounts);
  return {
    results,
    totalCount,
    index,
    list,
    count,
    productCounts,
  };
}

function foundCategories({ results, count }, query) {
  return {
    index: buildCategoryIndex(results),
    count,
    query,
  };
}
