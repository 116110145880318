import React from 'react';
import classNames from 'classnames';
import pt from 'prop-types';

import Link from 'components/link';

import './button.scss';

function ButtonLink({
  size = 'md',
  type = 'default',
  className,
  disabled,
  children,
  ...props
}) {
  const classes = classNames(
    `button button-${size} button-${type}`,
    className,
    {
      'button-disabled': Boolean(disabled),
    },
  );

  if (props.to) {
    return (
      <Link className={classes} {...props}>
        {children}
      </Link>
    );
  }

  if (props.href) {
    return (
      <Link className={classes} target="blank" {...props} to={props.href}>
        {children}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      className={classes}
      {...props}
      onClick={props.onClick}
      type="button"
    >
      {children}
    </button>
  );
}

ButtonLink.propTypes = {
  to: pt.string,
  size: pt.string,
  type: pt.string,
  className: pt.string,
  disabled: pt.bool,
  children: pt.node,
  onClick: pt.func,
};

export default React.memo(ButtonLink);
