import React from 'react';
import { Form, Field } from 'components/form';
import Modal from 'components/modal';
import { FadeIn } from 'components/transitions';

const REASON_OPTIONS = [
  {
    value: 'received',
    label: 'Received stock',
  },
  {
    value: 'returned',
    label: 'Returned by customer',
  },
  {
    value: 'canceled',
    label: 'Canceled by customer',
  },
  {
    value: 'sold',
    label: 'Sold to customer',
  },
  {
    value: 'missing',
    label: 'Missing from inventory',
  },
  {
    value: 'damaged',
    label: 'Damaged goods',
  },
];

export default class AdjustInventoryModalPage extends React.PureComponent {
  state = {
    values: {
      adjust: 'add',
    },
  };

  onChangeForm = (values) => {
    this.setState({
      values: {
        ...this.state.values,
        ...values,
      },
    });
  };

  render() {
    const { params, onSubmitForm, loading } = this.props;
    const { values } = this.state;
    const { variant } = params;

    return (
      <Form
        ref="form"
        onSubmit={onSubmitForm}
        onChange={this.onChangeForm}
        autoFocus={true}
      >
        <Modal
          title="Adjust inventory"
          actions={[{ label: 'Save', type: 'submit' }]}
          width={500}
          loading={loading}
        >
          <fieldset>
            {!!variant && <p>Variant: {variant.name}</p>}
            <Field
              type="radio"
              name="adjust"
              options={[
                { label: 'Add', value: 'add' },
                { label: 'Remove', value: 'remove' },
              ]}
              buttons={true}
              defaultValue="add"
              required={true}
            />
            <div className="row">
              <Field
                type="number"
                name="quantity"
                label="Quantity"
                placeholder="0"
                maxValue={1000000}
                required={true}
                className="span1"
              />
              <Field
                type="select"
                name="reason"
                label="Reason"
                options={REASON_OPTIONS}
                placeholder="Choose a reason"
                required={true}
                className="span3"
              />
            </div>
            <FadeIn active={!!values.reason}>
              <Field
                type="textarea"
                name="reason_message"
                placeholder="Additional notes"
                autoSize={true}
                rows={2}
                maxRows={6}
              />
            </FadeIn>
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
