import PropTypes from 'prop-types';
import React from 'react';
import Field from './field';
import CustomerAvatar from 'components/customer/avatar';
import { CURRENCY_CODES } from 'utils';
import { accountName } from 'utils/account';

export default class LookupCustomer extends React.PureComponent {
  static propTypes = {
    lookup: PropTypes.object.isRequired,
    multiple: PropTypes.bool,
    query: PropTypes.object,
    fields: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      query: this.getQuery(props),
    };
    this.renderCustomerValue = this.renderCustomerValue.bind(this);
    this.renderCustomerLookupItems = this.renderCustomerLookupItems.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.query !== this.props.query) {
      this.setState({ query: this.getQuery(this.props) });
    }
  }

  getQuery(props) {
    return {
      ...(props.query || {}),
      fields: props.fields || 'name, email, currency',
    };
  }

  renderCustomerValue(record) {
    return (
      <span>
        <span className="image round">
          <CustomerAvatar account={record} size={20} />
        </span>
        {record.name || record.email}
      </span>
    );
  }

  renderCustomerLookupItems({ lookup, ...props }) {
    return lookup.results.map((record) => (
      <li
        key={record.id}
        role="option"
        data-id={record.id}
        aria-selected={record.id === props.selected?.id}
        className={`item ${
          record.id === props.selected?.id ? 'selected' : null
        }`}
        onMouseOver={props.onMouseOver}
        onClick={props.onClick}
      >
        <span className="col">
          <span className="image round">
            <CustomerAvatar account={record} size={20} />
          </span>
          {accountName(record)}
        </span>
        <span className="col muted">{record.email}</span>
        {this.props.showCurrency && CURRENCY_CODES.length > 0 && (
          <span className="col muted">{record.currency}</span>
        )}
      </li>
    ));
  }

  onValueLink(value) {
    return `/customers/${value.id}`;
  }

  render() {
    const { showCurrency, ...props } = this.props;
    return (
      <div className={`form-lookup-customer ${this.props.className || ''}`}>
        <Field
          ref="field"
          type="lookup"
          name={this.props.multiple ? 'accounts' : 'account'}
          placeholder={
            this.props.multiple
              ? 'Find customers by name'
              : 'Find customer by name'
          }
          model="accounts"
          renderValue={this.renderCustomerValue}
          renderLookupItems={this.renderCustomerLookupItems}
          query={this.state.query}
          lookup={this.props.lookup}
          multiple={this.props.multiple}
          {...props}
          className={undefined}
          onValueLink={this.onValueLink}
        />
      </div>
    );
  }
}
