import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import ContentFields from 'components/content/fields';
import CustomerLocaleCurrencyFields from 'components/customer/locale-currency-fields';
import { Form, Field } from 'components/form';
import { hasLocalizedContent } from 'utils/content';

export default class CustomerContactEdit extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      values: props.record,
    };
    this.onChangeForm = this.onChangeForm.bind(this);
  }

  onChangeForm(values) {
    this.setState({
      values: {
        ...this.state.values,
        ...values,
      },
    });
  }

  onClickConvertToCustomer = () => {
    const {
      state: { values },
      props: { onEditValues },
    } = this;

    this.context.openModal('Confirm', {
      title: 'Convert to business customer',
      message: (
        <p>
          Are you sure you want to change the account type to a business
          customer?
          <br />
          This action can't be undone.
        </p>
      ),
      actionType: 'danger',
      onConfirm: async () => {
        const businessCustomerInfo = {
          type: 'business',
          name: `${values.first_name} ${values.last_name}`,
        };

        this.setState((prevState) => {
          return {
            values: {
              ...prevState.values,
              ...businessCustomerInfo,
            },
          };
        });

        await onEditValues(businessCustomerInfo);
      },
    });
  };

  render() {
    const {
      record,
      settings,
      content,
      loading,
      onClickContactEdit,
      onSubmitContactEdit,
    } = this.props;
    const { values } = this.state;

    const features = settings.accounts.features || {};

    return (
      <Form
        onSubmit={onSubmitContactEdit}
        onChange={this.onChangeForm}
        autoFocus={true}
      >
        <Modal
          title={record.type === 'business' ? 'Edit business' : 'Edit contact'}
          className="customer-view-modal-edit-contact"
          actions={[
            { label: 'Save', type: 'submit' },
            { label: 'Cancel', type: 'cancel', onClick: onClickContactEdit },
            features.business &&
              record.type === 'individual' && {
                label: 'Convert to business customer',
                type: 'secondary',
                className: 'left button-cancel',
                onClick: this.onClickConvertToCustomer,
              },
          ]}
          width={600}
          cancel={false}
          loading={loading}
          onClose={onClickContactEdit}
          localized={hasLocalizedContent(content.models, 'accounts')}
          devtools={{
            model: 'accounts',
            console: false,
            zone: 'contact',
          }}
        >
          <fieldset>
            {values.type !== 'business' ? (
              <div className="row" key="individual">
                <Field
                  type="text"
                  label="First name"
                  name="first_name"
                  defaultValue={record.first_name}
                  className="span2"
                  required={true}
                />
                <Field
                  type="text"
                  label="Last name"
                  name="last_name"
                  defaultValue={record.last_name}
                  className="span2"
                />
              </div>
            ) : (
              <div className="row" key="business">
                <Field
                  type="text"
                  label="Name"
                  name="name"
                  defaultValue={record.name}
                  required={true}
                  className="span4"
                />
              </div>
            )}
            <div className="row">
              <Field
                type="text"
                label="Email address"
                name="email"
                defaultValue={record.email}
                rules={'required, email'}
                className="span4"
              />
            </div>
            <div className="row">
              <Field
                type="checkbox"
                label="Customer accepts email marketing"
                name="email_optin"
                defaultChecked={record.email_optin !== false}
                className="span4"
              />
            </div>
            <div className="row">
              <Field
                type="text"
                label="Phone number"
                name="phone"
                defaultValue={record.phone}
                className="span4"
              />
            </div>
            {settings.accounts.groups.length > 0 && (
              <div className="row">
                <Field
                  type="select"
                  label="Customer group"
                  name="group"
                  placeholder="None"
                  clearIcon
                  options={[
                    ...settings.accounts.groups.map((group) => ({
                      value: group.id,
                      label: group.name,
                    })),
                  ]}
                  defaultValue={record.group}
                  className="span4"
                />
              </div>
            )}
            <CustomerLocaleCurrencyFields
              localeLabel="Locale preference"
              currencyLabel="Currency preference"
              record={record}
            />
            {((values.type === 'business' && features.vat) ||
              record.vat_number) && (
              <div className="row">
                <Field
                  type="text"
                  name="vat_number"
                  label="VAT number"
                  className="span2"
                  placeholder="Optional"
                  defaultValue={record.vat_number}
                />
                <div />
              </div>
            )}
            <ContentFields
              {...this.props}
              zone="contact"
              collection="accounts"
              models={content.models}
              record={record}
              values={values}
              currency={record.currency}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
