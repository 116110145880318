import React from 'react';
import { List } from 'react-movable';
import classNames from 'classnames';
import pt from 'prop-types';

import './collection.scss';

/** @typedef {import('react-movable').RenderListParams} RenderListParams */
/** @typedef {import('react-movable').RenderItemParams} RenderItemParams */
/** @typedef {import('react-movable').BeforeDragParams} BeforeDragParams */
/** @typedef {import('react-movable').OnChangeMeta} OnChangeMeta */

const cellStyles = {
  borderRadius: '0px',
  borderTopWidth: '1px',
  borderBottomWidth: '1px',
};

export default class SortableCollection extends React.PureComponent {
  static propTypes = {
    rows: pt.array.isRequired,
    values: pt.array.isRequired,
    columns: pt.array,
    className: pt.string,
    movableContainer: pt.object,

    onSort: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      widths: [],
    };
  }

  /** @param {OnChangeMeta} meta */
  onMoveChange = ({ oldIndex, newIndex }) => {
    const valueSource = this.props.values[oldIndex];
    const valueTarget = this.props.values[newIndex];

    this.props.onSort(valueSource, valueTarget);
  };

  /** @param {BeforeDragParams} params */
  onBeforeDrag = ({ elements, index }) => {
    const cells = Array.from(elements[index].children);

    const widths = cells.map((cell) => window.getComputedStyle(cell).width);

    this.setState({ widths });
  };

  /** @param {RenderListParams} params */
  renderList = ({ children, props, isDragged }) => {
    const { columns, className } = this.props;

    const tableClassName = classNames(
      'collection-table',
      className,
      { headless: !columns },
      'outer',
      'sortable',
    );

    return (
      <div className="collection-table-container">
        <table className={tableClassName}>
          {columns && (
            <thead>
              <tr>
                {columns.map((col, index) =>
                  col?.content !== undefined ? (
                    <th key={index} {...col.props}>
                      {col.content}
                    </th>
                  ) : (
                    col
                  ),
                )}
              </tr>
            </thead>
          )}

          <tbody {...props}>{children}</tbody>
        </table>
      </div>
    );
  };

  /** @param {RenderItemParams} params */
  renderItem = ({ value, props, isDragged }) => {
    const { columns, className } = this.props;

    const tableClassName = classNames(
      'collection-table',
      className,
      { headless: !columns },
      'outer',
      'sortable',
    );

    const widths = isDragged ? this.state.widths : [];

    if (widths.length > 0) {
      const cells = [];

      const origCells = value.reduce((acc, val, index) => {
        if (!val) {
          return acc;
        }

        acc.push(
          val?.content !== undefined ? (
            <td key={index} {...val.props}>
              {val.content}
            </td>
          ) : (
            val
          ),
        );

        return acc;
      }, []);

      for (let i = 0; i < widths.length; ++i) {
        const val = origCells[i];

        cells.push({
          ...val,
          props: {
            ...val.props,
            style: {
              ...(val.props || {}).style,
              ...cellStyles,
              width: widths[i],
            },
          },
        });
      }

      return (
        <table
          className={classNames(tableClassName, { dragging: isDragged })}
          {...props}
        >
          <tbody>
            <tr>{cells}</tr>
          </tbody>
        </table>
      );
    }

    return (
      <tr {...props}>
        {value.map((val, index) => {
          return val?.content !== undefined ? (
            <td key={index} {...val.props}>
              {val.content}
            </td>
          ) : (
            val
          );
        })}
      </tr>
    );
  };

  render() {
    const { rows, movableContainer } = this.props;

    return (
      <List
        values={rows}
        lockVertically={true}
        transitionDuration={200}
        container={movableContainer}
        renderList={this.renderList}
        renderItem={this.renderItem}
        beforeDrag={this.onBeforeDrag}
        onChange={this.onMoveChange}
      />
    );
  }
}
