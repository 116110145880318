import React, { PureComponent } from 'react';
import pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import HelpIcon from '../../icons/Help';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import TooltipViewport from '../../TooltipViewport';
import s from './Label.sss';
import { snakeCase } from 'lodash';

withStyles(s);
class Label extends PureComponent {
  static propTypes = {
    tip: pt.string,
    children: pt.node,
    hasLeftMargin: pt.bool,
    hasRightMargin: pt.bool,
  };

  static defaultProps = {
    tip: '',
    children: '',
    hasLeftMargin: false,
    hasRightMargin: false,
  };

  render() {
    const { tip } = this.props;

    return (
      <label
        className={cx(s.root, {
          [s.leftMargin]: this.props.hasLeftMargin,
          [s.rightMargin]: this.props.hasRightMargin,
        })}
      >
        {this.props.children}
        {tip && [
          <span data-tip={tip} data-for={snakeCase(tip)} className={s.icon}>
            <HelpIcon />
          </span>,
          <TooltipViewport>
            <ReactTooltip id={snakeCase(tip)} />
          </TooltipViewport>,
        ]}
      </label>
    );
  }
}

export default Label;
