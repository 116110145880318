import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import { find, remove, cloneDeep } from 'lodash';

import actions from 'actions';

import { confirmRouteLeave, confirmPageLeave } from 'utils/container';

import LoadingView from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';
import RecordPage from 'components/collection/record';

export const mapStateToProps = (state) => ({
  data: state.data,
  loading: state.data.loading,
  record: state.data.record,
  related: state.data.related,
  errors: state.data.recordErrors,
  categories: state.categories,
  content: state.content,
  view: state.content.view,
  lookup: state.lookup,
  settings: state.settings,
  bulk: state.data.bulk,
  stores: state.stores,
});

function collectionParam(collection) {
  return collection.replace(/_/g, '/');
}

export const mapDispatchToProps = (dispatch) => ({
  fetchRecordView: (collection, isNew) => {
    return dispatch(
      actions.content.fetchView(collectionParam(collection), 'record', isNew),
    );
  },

  fetchRecord(collection, id) {
    return dispatch(
      actions.content.fetchRecord(collectionParam(collection), id),
    );
  },

  createRecord(collection, data) {
    return dispatch(
      actions.content.createRecord(collectionParam(collection), data),
    );
  },

  updateRecord(collection, id, data) {
    return dispatch(
      actions.content.updateRecord(collectionParam(collection), id, data),
    );
  },

  deleteRecord(collection, id) {
    return dispatch(
      actions.content.deleteRecord(collectionParam(collection), id),
    );
  },

  getValuesWithLocationData(collection, location) {
    return dispatch(
      actions.data.getValuesWithLocationData(
        collectionParam(collection),
        location,
      ),
    );
  },
});

function getTabState(props, state) {
  return {
    tab: props.location.query.tab || 'details',
    tabs: {
      ...state.tabs,
      [props.location.query.tab || 'details']: true,
    },
  };
}

export class CollectionRecord extends React.PureComponent {
  static propTypes = {
    data: pt.object,
    record: pt.object,
    params: pt.object,
    router: pt.object,
    location: pt.object,
    settings: pt.object,
    content: pt.object,
    categories: pt.object,
    attributes: pt.object,

    fetchRecord: pt.func,
    createRecord: pt.func,
    updateRecord: pt.func,
    deleteRecord: pt.func,
  };

  static contextTypes = {
    account: pt.object.isRequired,
    client: pt.object.isRequired,
    notifyError: pt.func.isRequired,
    notifySuccess: pt.func.isRequired,
    notifyCreated: pt.func.isRequired,
    notifyDeleted: pt.func.isRequired,
    uploadImages: pt.func.isRequired,
    openModal: pt.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loaded: false,
      values: {},
      edited: false,
      tab: null,
      tabs: {},
      model: null,
      view: null,
      new: props.params.id === 'new',
      recordProps: null,
      cloneLoading: false,
      cloneProgressMessage: null,
      cloneProgressStep: null,
      cloneProgressStepTotal: null,
      onSubmitRecord: this.onSubmitRecord.bind(this),
      onSubmitClone: this.onSubmitClone.bind(this),
      onClickDelete: this.onClickDelete.bind(this),
      onChangeForm: this.onChangeForm.bind(this),
    };

    this.tabChangeListeners = [];
  }

  componentDidMount() {
    const { params, location, fetchRecordView, getValuesWithLocationData } =
      this.props;

    confirmRouteLeave(this);

    const isNew = params.id === 'new';

    Promise.all([
      fetchRecordView(params.collection, isNew),
      getValuesWithLocationData(params.collection, location),
    ]).then(([{ currentCollection, currentView }, values]) => {
      this.setRecordProps(currentCollection, currentView);
      this.fetchRecord().then((record) => {
        this.setState((state, props) => ({
          loaded: true,
          new: isNew,
          values,
          edited: false,
          ...getTabState(props, state),
          ...this.mapStateFromRecord(record),
        }));
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    const { fetchRecordView } = this.props;
    const { params } = nextProps;
    const { loaded } = this.state;

    const currentView = this.props.content.currentView;
    const nextView = nextProps.content.currentView;

    if (loaded && params.collection !== this.props.params.collection) {
      const isNew = params.id === 'new';
      this.setState({ loaded: false });
      fetchRecordView(params.collection, isNew).then(
        ({ currentCollection, currentView }) => {
          this.setRecordProps(currentCollection, currentView);
          this.fetchRecord().then((record) => {
            this.setState((state, props) => ({
              loaded: true,
              values: {},
              edited: false,
              ...getTabState(props, state),
              ...this.mapStateFromRecord(record),
            }));
          });
        },
      );
    } else if (loaded && currentView && currentView !== nextView) {
      const { currentCollection, currentView } = nextProps.content;
      if (currentView.gid === nextView?.gid) {
        this.setRecordProps(currentCollection, currentView);
      } else {
        this.setState({ loaded: false }, () => {
          this.setRecordProps(currentCollection, currentView);
          this.setState({ loaded: true });
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);

    const { params } = this.props;

    if (params.id !== prevProps.params.id) {
      this.setState((state, props) => ({
        loaded: false,
        values: {},
        ...getTabState(props, state),
      }));

      this.fetchRecord().then((record) => {
        this.setState({
          loaded: true,
          values: {},
          edited: false,
          ...this.mapStateFromRecord(record),
        });
      });
    } else if (this.state.edited !== prevState.edited) {
      this.updateRecordProps();
    }
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  setRecordProps(model, view) {
    const { params } = this.props;

    if (!model || !view) {
      this.setState({ recordProps: null });
      return;
    }

    const normalView = this.normalizeCollectionView(view);

    this.setState({
      model,
      view,
      recordProps: {
        title: normalView.label,
        uri: `/collections/${params.collection}`,
        model: model.collection,
        actions: normalView.actions,
        extraActions: normalView.extraActions,
        fields: normalView.fields,
      },
    });
  }

  updateRecordProps() {
    const { view, recordProps } = this.state;

    const normalView = this.normalizeCollectionView(view);

    this.setState({
      recordProps: {
        ...recordProps,
        actions: normalView.actions,
        extraActions: normalView.extraActions,
      },
    });
  }

  normalizeCollectionView(view) {
    const normalView = cloneDeep(view);

    if (!this.state.new) {
      // Set button type based on edited state
      const saveAction = find(normalView.actions, { submit: true });
      if (saveAction) {
        saveAction.type = this.state.edited ? 'default' : 'secondary';
      }
      // Assign click handler to delete actions
      const deleteAction = find(normalView.extraActions, { delete: true });
      if (deleteAction) {
        deleteAction.onClick = this.state.onClickDelete;
      }
    } else {
      // Remove delete action if new record
      remove(normalView.extraActions, { delete: true });
    }

    return normalView;
  }

  isEdited() {
    return this.state.edited;
  }

  fetchRecord = async () => {
    const { params, fetchRecord } = this.props;
    if (params.id === 'new') {
      return;
    }
    return fetchRecord(params.collection, params.id);
  };

  mapStateFromRecord(record) {
    if (!record) {
      return {};
    }
    return {
      values: {
        ...this.state.values,
        ...cloneDeep(record),
      },
    };
  }

  onChangeForm(values, edited) {
    this.setState((state) => ({
      values: {
        ...state.values,
        ...values,
      },
      edited,
    }));
  }

  onClickDelete(event) {
    event.preventDefault();
    this.context.openModal('ConfirmDelete', {
      title: `this ${this.state.model.singular.toLowerCase()}`,
      onConfirm: () => {
        const { params, router, deleteRecord } = this.props;
        const { model } = this.state;
        deleteRecord(params.collection, params.id).then((result) => {
          if (result && !result.errors) {
            this.setState({ edited: false }, () => {
              router.replace(`/collections/${params.collection}`);
              this.context.notifyDeleted(model.singular);
            });
          }
        });
      },
    });
  }

  async onSubmitRecord(values) {
    const { params, router, createRecord, updateRecord } = this.props;
    const { new: isNew } = this.state;

    const result = await (isNew
      ? createRecord(params.collection, values)
      : updateRecord(params.collection, params.id, values));

    if (result) {
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        const record = await this.fetchRecord();
        this.setState(
          {
            edited: false,
            ...this.mapStateFromRecord(record),
          },
          () => {
            if (isNew) {
              this.setState({ new: false });
              router.replace(`/collections/${params.collection}/${result.id}`);
            }
          },
        );
      }
    }
  }

  getCloneProgressStepTotal() {
    const { record } = this.props;
    let cloneProgressStepTotal = 2;

    if (record.images && record.images.length) {
      cloneProgressStepTotal += 1;
    }

    if (record.variants.results && record.variants.results.length) {
      cloneProgressStepTotal += 1;
    }

    return cloneProgressStepTotal;
  }

  onSubmitClone(values) {
    const { params, router, record, createRecord, view } = this.props;

    const cloneProgressStepTotal = this.getCloneProgressStepTotal();

    this.setState({
      cloneLoading: true,
      cloneProgressMessage: `Cloning ${view.recordLabel}`,
      cloneProgressStep: 1,
      cloneProgressStepTotal,
    });

    return createRecord({
      ...values,
      content: record.content,
      $locale: record.$locale,
      $currency: record.$currency,
    }).then((result) => {
      if (result.errors) {
        setTimeout(() => {
          this.setState({ cloneLoading: false }, () => {
            this.context.notifyError(result.errors);
          });
        }, 300);
      } else {
        return this.updateClonedRecord(result).then(() => {
          setTimeout(() => {
            this.setState(
              { cloneLoading: false, cloneProgressMessage: null },
              () => {
                router.push(`/collections/${params.collection}/${result.id}`);
                this.context.notifyCreated(result);
              },
            );
          }, 300);
        });
      }
    });
  }

  async updateClonedRecord(result) {
    // TODO: clone nested images and collections etc
    // See product view for example
  }

  render() {
    const { record } = this.props;
    const { loaded, new: isNew, recordProps } = this.state;

    if (!loaded) {
      return <LoadingView />;
    }

    if ((!record && !isNew) || !recordProps) {
      return <NotFoundPage />;
    }

    return <RecordPage {...this.props} {...recordProps} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionRecord);
