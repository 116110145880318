import get from 'lodash/get';
import set from 'lodash/set';

const getIsValueEmpty = (value) =>
  value === undefined || value === null || value === '';

const getBulkEditDataForUpdate = (record, key, defaultStoreCurrency) => {
  let updateKey = undefined;
  let isValueEmpty = undefined;

  switch (key) {
    case 'price':
    case 'sale_price':
    case 'sale':
      if (
        defaultStoreCurrency !== 'USD' &&
        record.currency !== defaultStoreCurrency
      ) {
        updateKey = `purchase_options.standard.$currency.${defaultStoreCurrency}.${key}`;
        break;
      }

      const standardPurchaseOption = get(record, 'purchase_options.standard');
      const path = `purchase_options.standard.${key}`;

      updateKey = standardPurchaseOption ? path : key;
      isValueEmpty = getIsValueEmpty(
        standardPurchaseOption ? get(record, path) : record[key],
      );
      break;

    default:
      updateKey = key;
      isValueEmpty = getIsValueEmpty(record[key]);
      break;
  }

  return { updateKey, isValueEmpty };
};

// Generic bulk updates
export function bulkEditUpdates(
  record,
  values,
  defaultStoreCurrency,
  overwrite = true,
) {
  if (!record || typeof record !== 'object') {
    return;
  }
  if (!values || typeof values !== 'object') {
    return;
  }
  const updates = {
    data: {},
  };
  Object.keys(values).forEach((key) => {
    const { updateKey, isValueEmpty } = getBulkEditDataForUpdate(
      record,
      key,
      defaultStoreCurrency,
    );

    if (overwrite || isValueEmpty) {
      set(updates.data, updateKey, values[key]);
    }
  });
  return updates;
}

// Bulk add categories to products
export function bulkCategoriesAdd(record, categories) {
  if (!record || typeof record !== 'object') {
    return;
  }
  if (!(categories instanceof Array)) {
    return;
  }
  const updates = [];
  categories.forEach((category) => {
    updates.push({
      method: 'post',
      url: '/categories:products',
      data: {
        parent_id: category.id,
        product_id: record.id,
        sort: 0,
      },
    });
  });
  return updates;
}

// Bulk remove categories from products
export function bulkCategoriesRemove(record, categories) {
  if (!record || typeof record !== 'object') {
    return;
  }
  if (!(categories instanceof Array)) {
    return;
  }
  const updates = [];
  categories.forEach((category) => {
    updates.push({
      method: 'delete',
      url: '/categories:products/:first',
      data: {
        parent_id: category.id,
        product_id: record.id,
      },
    });
  });
  return updates;
}
