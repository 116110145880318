import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import size from 'lodash/size';
import _values from 'lodash/values';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';

import { isValueEqual, isEmpty, classNames } from 'utils';
import { confirmPageLeave } from 'utils/container';

import Icon from 'components/icon';
import Modal from 'components/modal';
import SortableCollection from 'components/collection/sortable';
import { FadeIn } from 'components/transitions';

import Field from './field';
import Form, { ensureValueType, getComponentValue } from './form';

import './form.scss';

function getInitialDefaultValue(props) {
  if (props.defaultValue) {
    return props.single
      ? { ...cloneDeep(props.defaultValue) }
      : [..._values(cloneDeep(props.defaultValue))];
  }

  return props.single ? {} : [];
}

function getInitialValue(props) {
  if (props.value) {
    return props.single
      ? { ...cloneDeep(props.value) }
      : [..._values(cloneDeep(props.value))];
  }

  return getInitialDefaultValue(props);
}

let INDEX = 0;

export default class Fieldtable extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    saveLabel: PropTypes.string,
    addTitle: PropTypes.string,
    editTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    editSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    editedFieldId: PropTypes.string,
    addSubtitle: PropTypes.bool,
    saveAndContinue: PropTypes.bool,
    saveBackLabel: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    heading: PropTypes.array,
    value: PropTypes.array,
    defaultValue: PropTypes.any,
    renderHeading: PropTypes.func,
    renderValue: PropTypes.func,
    renderForm: PropTypes.func,
    renderTable: PropTypes.func,
    renderModalActions: PropTypes.func,
    className: PropTypes.string,
    modelClassName: PropTypes.string,
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    formWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    overrideHelpLinkKey: PropTypes.string,
    helpLink: PropTypes.bool,
    chromeless: PropTypes.bool,
    sortable: PropTypes.bool,
    sortSubmit: PropTypes.bool,
    autoFocus: PropTypes.bool,
    addable: PropTypes.bool,
    editable: PropTypes.bool,
    editableCheck: PropTypes.func,
    saveable: PropTypes.bool,
    removable: PropTypes.bool,
    inline: PropTypes.bool,
    single: PropTypes.bool,
    valign: PropTypes.bool,
    transitionAppear: PropTypes.bool,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitModal: PropTypes.func,
    submitPreventValue: PropTypes.bool,
    onAddRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onCloseModal: PropTypes.func,
    mountAddRow: PropTypes.bool,
    confirmEdited: PropTypes.bool,
    unregisterField: PropTypes.bool,
    uniqueFields: PropTypes.array,
    onEdited: PropTypes.bool,
    localized: PropTypes.bool,
    lookup: PropTypes.object,
    devtools: PropTypes.object,
    editLocalized: PropTypes.func,
    editDevtools: PropTypes.func,
    editLoadingTitle: PropTypes.string,
    onChangeModalForm: PropTypes.func,
    getRef: PropTypes.func,
  };

  static contextTypes = {
    registerField: PropTypes.func,
    unregisterField: PropTypes.func,
    onChangeField: PropTypes.func,
    subscribeChange: PropTypes.func,
    unsubscribeChange: PropTypes.func,
    setFormValue: PropTypes.func,
    openModal: PropTypes.func,
    refreshModal: PropTypes.func,
    closeModal: PropTypes.func,
    getModalSavingActive: PropTypes.func,
    setModalSavingActive: PropTypes.func,
  };

  static childContextTypes = {
    formValues: PropTypes.object,
    registerField: PropTypes.func,
    unregisterField: PropTypes.func,
    onChangeField: PropTypes.func,
  };

  getChildContext() {
    return {
      formValues: {},
      registerField: this.registerField,
      unregisterField: this.unregisterField,
      onChangeField: this.onChangeInlineField,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      id: props.name,
      value: getInitialValue(props),
      defaultValue: getInitialDefaultValue(props),
      disabled: typeof props.disabled === 'function' ? false : props.disabled,
      showForm: false,
      editValue: null,
      editLoading: false,
      editIndex: null,
      edited: false,
      sorting: false,
    };

    this.isNew = false;
    this.fields = {};

    this.fieldCanFocus = false;
    this.submittingModal = false;
    this.inlineValues = null;
    this.inlinePromise = null;
    this.initialTimer = 0;
    this.autoFocusTimer = 0;
    this.refreshModalTimer = 0;

    this.movableContainerRef = React.createRef();
  }

  getEditValuesUpdated(value, props) {
    const { editValue, editIndex } = this.state;
    const { uniqueFields } = this.props;

    if (props.single) {
      return { editValue: editValue ? value : null };
    }

    if (
      Array.isArray(uniqueFields) &&
      Array.isArray(value) &&
      value.every((valueItem) => typeof valueItem === 'object')
    ) {
      for (const uniqueField of uniqueFields) {
        value.forEach((el) => {
          el.error = null;
        });

        for (let i = 0; i < value.length; ++i) {
          for (let j = i + 1; j < value.length; ++j) {
            if (value[i][uniqueField] === value[j][uniqueField]) {
              value[i].error = 'Value should be unique';
              value[j].error = 'Value should be unique';
            }
          }
        }
      }
    }

    return { editValue: editValue ? value[editIndex] : null };
  }

  componentDidMount() {
    if (this.context.registerField) {
      this.context.registerField(this);
    }

    if (this.context.subscribeChange) {
      this.context.subscribeChange(this.state.id, this.onChangeForm);
    }

    this.index = ++INDEX;

    this.onChangeField();

    if (this.props.mountAddRow && isEmpty(this.state.value)) {
      this.onMountAddRow();
    }

    if (this.props.getRef) {
      this.props.getRef(this);
    }

    // find corresponding field and start its editing
    if (this.props.editedFieldId) {
      const fieldIndex = findIndex(
        this.state.value,
        (field) => field.id === this.props.editedFieldId,
      );
      if (fieldIndex >= 0) {
        this.onEditFieldByIndex(fieldIndex);
      }
    }

    this.initialTimer = setTimeout(() => {
      this.initialTimer = 0;
      this.fieldCanFocus = true;
    }, 500);
  }

  componentWillUnmount() {
    const {
      unregisterField,
      confirmEdited,
      saveable = true,
      editable = true,
    } = this.props;

    INDEX -= 1;

    if (this.initialTimer) {
      clearTimeout(this.initialTimer);
    }

    if (this.autoFocusTimer) {
      clearTimeout(this.autoFocusTimer);
    }

    if (this.refreshModalTimer) {
      clearTimeout(this.refreshModalTimer);
    }

    if (this.context.unregisterField && unregisterField !== false) {
      this.context.unregisterField(this);
    }

    if (this.context.unsubscribeChange) {
      this.context.unsubscribeChange(this.state.id);
    }

    if (confirmEdited && saveable && editable) {
      confirmPageLeave(this);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      name,
      confirmEdited,
      saveable = true,
      editable = true,
    } = this.props;

    if (!prevState.showForm && this.state.showForm) {
      this.context.openModal(
        `fieldtable_${name}_${this.index}`,
        this.renderForm,
      );
    } else if (prevState.showForm && !this.state.showForm) {
      this.context.closeModal();
    } else if (
      this.state.showForm &&
      (this.state.editValue !== prevState.editValue ||
        prevProps.lookup !== this.props.lookup)
    ) {
      // Only refresh modal when edit value or lookup changes
      // Using a time to prevent too frequent refreshes
      if (this.refreshModalTimer) {
        clearTimeout(this.refreshModalTimer);
      }

      this.refreshModalTimer = setTimeout(() => {
        this.refreshModalTimer = 0;
        this.context.refreshModal();
      }, 10);
    }

    if (confirmEdited && saveable && editable) {
      confirmPageLeave(this, prevState);
    }

    if (
      this.props.value !== prevProps.value ||
      !isValueEqual(this.props.defaultValue, prevProps.defaultValue)
    ) {
      const value = getInitialValue(this.props);

      this.setState(
        {
          value,
          defaultValue: getInitialDefaultValue(this.props),
          ...this.getEditValuesUpdated(value, this.props),
        },
        () => {
          if (this.props.onSubmit) {
            this.context.setFormValue(
              this.props.name,
              this.state.value,
              undefined,
              true,
            );
          } else {
            this.onChangeField();
          }
        },
      );
    }
  }

  registerField = (component) => {
    this.fields[component.props.name] = component;

    if (this.fieldCanFocus && component.props.autoFocus && component.focus) {
      if (this.autoFocusTimer) {
        clearTimeout(this.autoFocusTimer);
      }

      this.autoFocusTimer = setTimeout(() => {
        this.autoFocusTimer = 0;
        component.focus();
      }, 200);
    }
  };

  unregisterField = (component) => {
    delete this.fields[component.props.name];
  };

  onChangeForm = (values) => {
    if (typeof this.props.disabled === 'function') {
      this.setState({ disabled: this.props.disabled(values) });
    }
  };

  onChangeField = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.value);
    }
    if (this.context.onChangeField) {
      this.context.onChangeField(this);
    }
  };

  onChangeInlineField = (component) => {
    if (!this.props.inline && !component.props.fieldtableInline) {
      return;
    }
    if (this.state.sorting) {
      return;
    }

    const { name } = component.props;
    let fieldPath;
    if (this.props.single) {
      fieldPath = name;
    } else if (component.props.fieldtableInline) {
      fieldPath = component.props.name;
    } else {
      const index = component.props['data-index'];
      fieldPath = name ? `[${index}].${name}` : `[${index}]`;
    }

    const fieldValue = ensureValueType(component, getComponentValue(component));

    const exValue = ensureValueType(
      component,
      get(this.state.value, fieldPath),
    );

    if (fieldValue === exValue) {
      return;
    }

    // Accumulate changes for multiple fields
    this.inlineValues = this.inlineValues || cloneDeep(this.state.value);
    set(this.inlineValues, fieldPath, fieldValue);

    // If the promise already exists, then just accumulate the changes
    if (this.inlinePromise !== null) {
      return;
    }

    // Non-blocking update to overwrite value after adding newline
    this.inlinePromise = Promise.resolve().then(() => {
      this.setState({ value: this.inlineValues }, () => {
        // After applying the changes, reset the promise to be able to create a new one
        this.inlinePromise = null;
        // Reset accumulated field changes
        this.inlineValues = null;
        // Apply accumulated changes
        this.onChangeField();
      });
    });
  };

  onChangeFieldSubmit = async (value) => {
    if (this.props.onSubmit) {
      this.setState({ editLoading: true });

      const success = await this.props.onSubmit(value || this.state.value);

      this.setState({ editLoading: false });

      if (success === false) {
        return false;
      }

      if (this.props.submitPreventValue) {
        return;
      }

      if (value) {
        this.setState({ value }, () => this.setFormValueChange(value));
      } else {
        this.onChangeField();
      }
    } else if (value) {
      this.setState({ value }, () => this.onChangeField());
    } else {
      this.onChangeField();
    }
  };

  setFormValueChange(value) {
    this.onChangeField();
    this.context.setFormValue(
      this.props.name,
      value || this.state.value,
      undefined,
      true,
    );
  }

  updateSubmittingModal = (value) => {
    // update class variable and context variable
    this.submittingModal = value;
    this.context.setModalSavingActive(value);
  }

  isUpdateLocked = () => {
    return this.submittingModal || this.context.getModalSavingActive();
  }

  onClickAddRow = (event) => {
    event.preventDefault();
    if (this.isUpdateLocked()) {
      return;
    }
    this.isNew = true;
    const exValue = _values(this.state.value);
    let newValue;
    if (this.props.onAddRow) {
      newValue = this.props.onAddRow();
    }
    if (this.props.inline && !this.props.single) {
      const firstKey = Object.keys(this.fields)[0];
      newValue = newValue || (firstKey || firstKey === undefined ? {} : '');
      const value = [...exValue, newValue];
      this.setState(
        { value, editValue: newValue, editIndex: value.length - 1 },
        () => this.onChangeField(),
      );
    } else {
      this.setState({
        showForm: true,
        editValue: newValue || {},
        editIndex: exValue.length,
        saveAndContinue: this.props.saveAndContinue,
      });
    }
  };

  onMountAddRow() {
    this.isNew = true;
    this.setState({ showForm: true, editValue: null, editIndex: null });
  }

  onClickEditRow = (event) => {
    event.preventDefault();
    if (this.isUpdateLocked()) {
      return;
    }
    const { index } = event.target.dataset;
    this.onEditFieldByIndex(index);
  };

  onEditFieldByIndex = (index) => {
    this.isNew = false;
    let editValue;
    let editIndex = null;
    if (this.props.single) {
      editValue = this.state.value;
    } else {
      editValue = this.state.value[index] || {};
      editIndex = index;
      if (this.props.onEditRow) {
        const returnEditValue = this.props.onEditRow(editValue, editIndex);
        if (returnEditValue !== undefined) {
          editValue = returnEditValue;
        }
      }
    }
    this.setState({
      showForm: true,
      editValue,
      editIndex,
      saveAndContinue: false,
    });
  };

  isValueEmpty(index) {
    const { value } = this.state;

    if (isEmpty(value[index])) {
      return true;
    }

    if (value[index] && typeof value[index] === 'object') {
      const definedKeys = Object.entries(value[index]).reduce(
        (acc, [key, val]) => {
          if (val) {
            acc.push(key);
          }

          return acc;
        },
        [],
      );

      if (definedKeys.length <= 0) {
        return true;
      }
    }

    return false;
  }

  removeValue(index) {
    const { single, inline } = this.props;
    let value;
    let defaultValue;
    if (single) {
      value = {};
      defaultValue = {};
    } else {
      value = [..._values(this.state.value)];
      defaultValue = [..._values(this.state.defaultValue)];
      value.splice(index, 1);
      defaultValue.splice(index, 1);
    }
    this.setState({ value, defaultValue }, () =>
      inline ? this.onChangeField() : this.onChangeFieldSubmit(),
    );
  }

  onClickRemoveRow = (event) => {
    const { label, single } = this.props;
    event.preventDefault();
    if (this.isUpdateLocked()) {
      return;
    }
    const { index } = event.target.dataset;
    const lableLower = label.toLowerCase();
    if (!single && this.isValueEmpty(index)) {
      this.removeValue(index);
      return;
    }
    this.context.openModal('Confirm', {
      title: `Remove ${lableLower}`,
      message: (
        <p>
          Are you sure you want to remove this <b>{lableLower}</b>?
        </p>
      ),
      actionType: 'danger',
      onConfirm: () => {
        this.removeValue(index);
      },
    });
  };

  onSubmitModalForm = async () => {
    const { editValue } = this.state;
    let value;
    let success;
    this.updateSubmittingModal(true);
    if (this.props.saveAndContinue && this.isNew) {
      this.isNew = false;
      this.setState({ editValue: { ...editValue } });
      this.updateSubmittingModal(false);
      return;
    }
    if (this.props.single) {
      value = editValue;
    } else {
      value = [..._values(this.state.value)];
      if (this.props.onSubmitModal) {
        this.setState({ editLoading: true });
        success = await this.props.onSubmitModal(editValue);
        this.setState({ editLoading: false });
        if (success === false) {
          this.updateSubmittingModal(false);
          return;
        }
      }
      if (this.state.editIndex !== null) {
        value[this.state.editIndex] = editValue;
      } else {
        value.push(editValue);
      }
    }
    success = await this.onChangeFieldSubmit(value);
    if (success === false) {
      this.updateSubmittingModal(false);
      return;
    }
    this.onCloseModalForm();
    this.updateSubmittingModal(false);
  };

  onChangeModalForm = (values, edited) => {
    if (!this.submittingModal) {
      let value = values;
      if (this.props.onChangeModalForm) {
        const returnValue = this.props.onChangeModalForm(
          this.state.value,
          values,
          this.state.editIndex,
          this.state.editValue,
        );
        if (returnValue !== undefined) {
          value = returnValue;
        }
      }
      this.setState({ editValue: { ...value }, edited });
    }
  };

  onCloseModalForm = (event) => {
    event && event.preventDefault();
    this.setState({ showForm: false, editValue: null, editIndex: null });
    if (this.props.onCloseModal) {
      this.props.onCloseModal();
    }
  };

  onClickBackFromContinue = (event) => {
    event.preventDefault();
    this.isNew = true;

    this.setState((state) => ({
      editValue: { ...state.editValue },
    }));
  };

  onClickCloseModalForm = (event) => {
    event.preventDefault();
    const { confirmEdited, saveable = true, editable = true } = this.props;
    if (confirmEdited && saveable && editable && this.state.edited) {
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.onCloseModalForm();
        },
      });
    } else {
      this.onCloseModalForm();
    }
  };

  onSortValues = (source, target) => {
    const sorted = [..._values(this.state.value)];
    const sourceIndex = sorted.indexOf(source);
    const targetIndex = sorted.indexOf(target);
    sorted.splice(targetIndex, 0, sorted.splice(sourceIndex, 1)[0]);
    this.setState({ value: sorted, sorting: true }, () => {
      if (this.props.sortSubmit) {
        this.onChangeFieldSubmit();
      } else {
        this.onChangeField();
      }
      this.setState({ sorting: false });
    });
  };

  validate() {
    let isValid = true;

    for (const component of Object.values(this.fields)) {
      isValid = component.validate() && isValid;
    }

    return isValid;
  }

  disabled(isDisabled) {
    this.setState({ disabled: isDisabled });
  }

  value() {
    if (this.props.single) {
      return this.state.value;
    }
    return map(this.state.value, (value) => value);
  }

  renderForm = ({ modalIndex }) => {
    const {
      label,
      saveLabel,
      saveBackLabel,
      title,
      editTitle,
      editSubtitle,
      editable = true,
      saveable = true,
      addTitle,
      addSubtitle,
      subtitle,
      formWidth,
      renderForm,
      renderModalActions,
      localized,
      devtools,
      editLocalized,
      editDevtools,
      editLoadingTitle,
      modelClassName,
      autoFocus = true,
      helpLink = true,
      overrideHelpLinkKey = null,
    } = this.props;

    const { editIndex, editValue, editLoading, saveAndContinue } = this.state;

    const thisValue = editValue || {};
    const labelLower = label.toLowerCase();

    const actions = [
      saveable &&
        editable && {
          label:
            saveAndContinue && this.isNew ? 'Continue' : saveLabel || 'Save',
          type: 'submit',
        },
      saveAndContinue &&
        !this.isNew && {
          label: saveBackLabel || 'Back',
          type: 'secondary',
          onClick: this.onClickBackFromContinue,
        },
      {
        label: saveable && editable ? 'Cancel' : 'Close',
        type: saveable && editable ? 'cancel' : 'secondary',
        onClick: this.onClickCloseModalForm,
      },
    ];

    const modalTitle =
      title || this.isNew
        ? addTitle || `Add ${labelLower}`
        : typeof editTitle === 'function'
        ? editTitle(thisValue)
        : `Edit ${labelLower}`;

    const modalSubtitle =
      subtitle || this.isNew
        ? addSubtitle
        : typeof editSubtitle === 'function'
        ? editSubtitle(thisValue)
        : undefined;

    const modalLocalized =
      typeof editLocalized === 'function'
        ? editLocalized(thisValue)
        : localized;

    const modalDevtools =
      typeof editDevtools === 'function' ? editDevtools(thisValue) : devtools;

    return (
      <Form
        onSubmit={this.onSubmitModalForm}
        onChange={this.onChangeModalForm}
        autoFocus={autoFocus && this.isNew ? true : undefined}
        values={thisValue}
      >
        <Modal
          title={modalTitle}
          subtitle={
            modalSubtitle && modalSubtitle !== modalTitle
              ? modalSubtitle
              : undefined
          }
          width={
            (typeof formWidth === 'function'
              ? formWidth(thisValue, this.isNew)
              : formWidth) || 500
          }
          className={classNames('form-fieldtable-modal', modelClassName)}
          actions={
            renderModalActions
              ? renderModalActions(thisValue, editIndex, actions)
              : actions
          }
          cancel={false}
          onClose={this.onClickCloseModalForm}
          loading={editLoading}
          loadingTitle={editLoadingTitle}
          localized={modalLocalized}
          devtools={modalDevtools}
          modalIndex={modalIndex}
          helpLink={helpLink}
          overrideHelpLinkKey={overrideHelpLinkKey}
        >
          {renderForm(
            thisValue,
            editIndex,
            this.value(),
            this.isNew,
            this.onChangeModalForm,
          )}
        </Modal>
      </Form>
    );
  };

  renderValueFields(value, index) {
    if (typeof value !== 'object' || value === null) {
      return null;
    }

    const { name } = this.props;

    return Object.entries(value).map(([key, val]) => {
      if (key.startsWith('$')) {
        return (
          <Fragment key={key}>
            {Object.entries(val).map(([localeKey, localeValues]) => (
              <Fragment key={localeKey}>
                {Object.entries(localeValues).map(([fieldName, localeVal]) => (
                  <Field
                    key={fieldName}
                    type="hidden"
                    name={`${name}[${index}].${key}.${localeKey}.${fieldName}`}
                    value={localeVal}
                  />
                ))}
              </Fragment>
            ))}
          </Fragment>
        );
      }

      return (
        <Field
          key={key}
          type="hidden"
          name={`${name}[${index}].${key}`}
          value={val}
        />
      );
    });
  }

  render() {
    const {
      label,
      heading,
      renderHeading,
      renderValue,
      renderTable,
      className,
      removable = true,
      sortable = false,
      addable = true,
      editable = true,
      editableCheck = () => true,
      inline = false,
      single = false,
      valign = false,
      transitionAppear = undefined,
    } = this.props;

    const { value } = this.state;

    const headingColumns = heading
      ? heading
      : renderHeading && renderHeading(value);
    const hasActions = !inline || !!removable;

    return (
      <div ref={this.movableContainerRef} className="form-fieldtable">
        {size(value) > 0 &&
          (renderTable ? (
            renderTable(value, this)
          ) : sortable && size(value) > 1 ? (
            <FadeIn transitionAppear={transitionAppear}>
              <SortableCollection
                onSort={this.onSortValues}
                values={value}
                columns={
                  headingColumns && [
                    ...headingColumns,
                    ...(hasActions && [<th key="s">&nbsp;</th>]),
                    <th key="s2">&nbsp;</th>,
                  ]
                }
                className={classNames(className, {
                  'fieldtable-inline aligned': inline,
                })}
                movableContainer={this.movableContainerRef.current}
                enabled={true}
                rows={map(value, (val, index) => {
                  return [
                    ...renderValue(
                      {
                        value: val,
                        index,
                      },
                      value,
                    ),
                    ...(hasActions && [
                      <td
                        className="action compact"
                        valign={valign ? 'top' : undefined}
                        key="s"
                      >
                        {!inline && this.renderValueFields(val, index)}
                        {!inline && (
                          <button
                            data-index={index}
                            className="as-link"
                            onClick={this.onClickEditRow}
                            type="button"
                          >
                            {editable && editableCheck(val, index)
                              ? 'Edit'
                              : 'View'}
                          </button>
                        )}
                        {!!removable && editableCheck(val, index) && (
                          <button
                            data-index={index}
                            className="as-link"
                            onClick={this.onClickRemoveRow}
                            type="button"
                          >
                            Remove
                          </button>
                        )}
                      </td>,
                    ]),
                    <td
                      className="action handle movable"
                      valign={valign ? 'top' : undefined}
                      key="s2"
                      data-movable-handle="true"
                    >
                      <span className="collection-table-handle">
                        <Icon type="drag-vertical" />
                      </span>
                    </td>,
                  ];
                })}
              />
            </FadeIn>
          ) : (
            <FadeIn transitionAppear={transitionAppear}>
              <div className="collection-table-container">
                <table
                  className={classNames('collection-table outer', className, {
                    headless: !headingColumns,
                    'fieldtable-inline aligned': inline,
                  })}
                >
                  {headingColumns && (
                    <thead>
                      <tr>
                        {headingColumns}
                        {(!inline || removable) && <th>&nbsp;</th>}
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {map(value, (val, index) => (
                      <tr key={index}>
                        {renderValue(
                          {
                            value: val,
                            index,
                            error: value.error,
                          },
                          value,
                        )}
                        {(!inline || removable) && (
                          <td
                            className="action compact"
                            valign={valign ? 'top' : undefined}
                          >
                            {!inline && this.renderValueFields(val, index)}
                            {!inline && (
                              <button
                                data-index={index}
                                className="as-link"
                                onClick={this.onClickEditRow}
                                type="button"
                              >
                                {editable && editableCheck(val, index)
                                  ? 'Edit'
                                  : 'View'}
                              </button>
                            )}
                            {!!removable && editableCheck(val, index) && (
                              <button
                                data-index={index}
                                className="as-link"
                                onClick={this.onClickRemoveRow}
                                type="button"
                              >
                                Remove
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </FadeIn>
          ))}
        {addable !== false && (!single || isEmpty(value)) && (
          <div className="form-field">
            <div className="collection-action-group">
              <button
                className="button button-secondary button-sm form-fieldlist-add form-link"
                onClick={this.onClickAddRow}
                type="button"
              >
                {`Add ${label.toLowerCase()}`}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
