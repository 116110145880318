import React from 'react';
import Status from 'components/status';

export default ({ cart: { active, order_id, recovered } }) => {
  return (
    <Status
      type={
        recovered
          ? 'positive'
          : order_id
          ? 'positive'
          : active
          ? 'positive'
          : 'muted'
      }
    >
      {recovered
        ? 'Recovered'
        : order_id
        ? 'Converted'
        : active
        ? 'Active'
        : 'Abandoned'}
    </Status>
  );
};
