import React from 'react';
import pt from 'prop-types';
import { get } from 'lodash';
import Loading from 'components/loading';
import { FadeIn } from 'components/transitions';

import 'components/modal/modal.scss';
import 'components/full-page-modal/full-page-modal.scss';
import Modal from 'components/modal/modal';

let MODAL_COUNT = 0;

export default class FullPageModal extends Modal {
  static propTypes = {
    className: pt.string,
    closable: pt.bool,
    loading: pt.bool,
    backButton: pt.element,
    transition: pt.bool,
    onClose: pt.func,
  };

  static contextTypes = {
    closeModal: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: !props.loading,
      wasLoading: Boolean(props.loading),
    };

    this.unmounted = false;
  }

  componentDidMount() {
    MODAL_COUNT += 1;
  }

  componentDidUpdate(prevProps) {
    if (!this.state.loaded && prevProps.loading && !this.props.loading) {
      setTimeout(() => {
        if (!this.unmounted) {
          this.setState({ loaded: true });
        }
      }, 50);
    }
  }

  componentWillUnmount() {
    MODAL_COUNT -= 1;

    this.unmounted = true;
  }

  onClickClose = (event, canceled = undefined) => {
    if (this.props.onClose) {
      this.props.onClose(event, canceled);
      if (event.defaultPrevented) {
        return;
      }
    }
    event.preventDefault();
    this.context.closeModal();
  };

  onClickCancel = (event) => {
    this.onClickClose(event, true);
  };

  onClick = (event) => {
    const { tapout = true, closable = true } = this.props;

    if (get(event, 'target.id') === 'modal' && closable && tapout) {
      this.onClickClose(event);
    }
  };

  renderLoading = () => {
    return <Loading className="modal-loading" />;
  };

  render() {
    const {
      closable = true,
      loading = false,
      className,
      children,
      backButton,
      transition,
    } = this.props;

    const { loaded, wasLoading } = this.state;

    const isLoading = !loaded && loading;

    return (
      <div
        id="full-page-modal"
        data-testid="full-page-modal"
        className="full-page-modal"
      >
        {isLoading && (
          <div className="modal-container-loading">{this.renderLoading()}</div>
        )}

        {loaded && !isLoading && (
          <FadeIn
            duration={transition ? 350 : 0}
            transitionDelay={wasLoading || MODAL_COUNT >= 3 ? 0 : 200}
          >
            {backButton}
            {closable && (
              <button
                className="full-page-modal-close-button"
                onClick={(e) => this.onClickClose(e, true)}
                type="button"
              >
                Cancel
              </button>
            )}

            <div className={className}>{children}</div>
          </FadeIn>
        )}
      </div>
    );
  }
}
