import React from 'react';
import JSONPretty from 'react-json-pretty';
import { get, isEmpty, isObject, cloneDeep } from 'lodash';
import pt from 'prop-types';

import Loading from 'components/loading';
import { FadeIn } from 'components/transitions';

let ROUTER;

window.__console_click_link = function (element) {
  if (element.dataset.link) {
    ROUTER.push(element.dataset.link);
  }
  return false;
};

class JSONPrettyExt extends JSONPretty {
  render() {
    const div = super.render();

    let { __html } = div.props.dangerouslySetInnerHTML;

    __html = __html.replace(
      /\$href:([^$]+)\$link:([^$]+)\$value:([^"]+)/g,
      `<a href="$1" data-link="$1" data-balloon="$2" data-balloon-pos="down" class="tooltip-delayed" onclick="javascript:return window.__console_click_link(this)">$3</a>`,
    );

    return (
      <div
        className={this.props.className}
        dangerouslySetInnerHTML={{ __html }}
      />
    );
  }
}

function setLinkValue(url, object, valueKey) {
  if (object[valueKey]) {
    if (Array.isArray(object[valueKey])) {
      for (let i = 0; i < object[valueKey].length; ++i) {
        const val = object[valueKey][i];

        if (typeof val === 'string' && !val.includes('$href')) {
          const link = url.replace(`{${valueKey}}`, val);

          object[valueKey][
            i
          ] = `$href:${window.location.pathname}?get${link}$link:${link}$value:${val}`;
        }
      }
    } else if (
      typeof object[valueKey] === 'string' &&
      !object[valueKey].includes('$href')
    ) {
      const link = url.replace(`{${valueKey}}`, object[valueKey]);

      object[
        valueKey
      ] = `$href:${window.location.pathname}?get${link}$link:${link}$value:${object[valueKey]}`;
    }
  }
}

function applyLinks(
  $links,
  response,
  responseWithLinks,
  parentUrl = undefined,
) {
  for (const [key, { url, links }] of Object.entries($links)) {
    if (links && response[key]) {
      applyLinks(
        links['*'] || links,
        response[key],
        responseWithLinks[key],
        url || null,
      );

      continue;
    }

    if (!url) {
      continue;
    }

    const firstUrlPart = url.split('?')[0];
    const segment = firstUrlPart.match(/\{([^}]+)\}/);

    if (!segment || !segment[1]) continue;

    const valueKey = segment[1];
    const responseArray = response.results || response;

    const responseWithLinksArray =
      responseWithLinks.results || responseWithLinks;

    if (Array.isArray(responseArray)) {
      for (let i = 0; i < responseArray.length; ++i) {
        if (isEmpty(responseArray[i])) continue;

        setLinkValue(url, responseWithLinksArray[i], valueKey);

        // Also set id field as a record link when inside an array
        if (parentUrl && responseWithLinksArray[i].id) {
          const idUrl = parentUrl.split('?')[0];

          setLinkValue(
            `${idUrl}/${responseWithLinksArray[i].id}`,
            responseWithLinksArray[i],
            'id',
          );
        }
      }
    } else {
      setLinkValue(url, responseWithLinks, valueKey);
    }
  }
}

function getResponseWithLinks({
  router,
  response,
  headers,
  query: { endpoint },
}) {
  ROUTER = router;

  const $links = get(headers, '$links');

  if (isEmpty($links) || isEmpty(response) || !isObject(response)) {
    return response;
  }

  const responseWithLinks = cloneDeep(response);

  applyLinks($links, response, responseWithLinks, `/${endpoint}`);

  return responseWithLinks;
}

export default class ConsoleResponse extends React.PureComponent {
  static propTypes = {
    query: pt.object,
    loading: pt.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      responseWithLinks: getResponseWithLinks(props),
    };
  }

  static getDerivedStateFromProps(props) {
    return { responseWithLinks: getResponseWithLinks(props) };
  }

  renderJSON(response) {
    return (
      <JSONPrettyExt
        className="console-response"
        data={response}
        onJSONPrettyError={(e) =>
          response && typeof response === 'object' && console.log(e)
        }
      />
    );
  }

  render() {
    const { query, loading } = this.props;
    const { responseWithLinks } = this.state;

    return (
      <div className="console-view">
        {responseWithLinks ? (
          <FadeIn key={query.id}>{this.renderJSON(responseWithLinks)}</FadeIn>
        ) : (
          <div className="muted note">{loading ? <Loading /> : 'None'}</div>
        )}
      </div>
    );
  }
}
