import React from 'react';
import PropTypes from 'prop-types';
import { findIndex, findLastIndex, map } from 'lodash';
import { List, arrayMove } from 'react-movable';
import {
  Root,
  Item,
  Trigger,
  Content,
  Label,
  Portal,
  Sub,
  SubTrigger,
  SubContent,
  Separator,
} from '@radix-ui/react-dropdown-menu';
import Icon from 'components/icon';
import AppIcon from 'components/apps/icon';
import Tooltip from 'components/tooltip';
import { isValueEqual, classNames } from 'utils';
import './preferences-menu.scss';

/** @typedef {import('react-movable').OnChangeMeta} OnChangeMeta */

export default class ViewPreferencesMenu extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    isOwner: PropTypes.bool,
    updateWithViewPreferences: PropTypes.func.isRequired,
    resetWithViewPreferences: PropTypes.func.isRequired,
  };

  static propTypes = {
    buttonChildren: PropTypes.any.isRequired,
    buttonClassName: PropTypes.string,
    buttonAriaLabel: PropTypes.string,
    items: PropTypes.array,
    clientItems: PropTypes.array,
    swellItems: PropTypes.array,
    preferenceKey: PropTypes.string,
    listOnly: PropTypes.bool,
    withPreferences: PropTypes.bool,
    side: PropTypes.string,
    sideOffset: PropTypes.number,
    align: PropTypes.string,
    cleanSaveItem: PropTypes.func,
    filter: PropTypes.func,
    avoidCollisions: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.getItemState(props),
      open: false,
    };

    this.state.options = this.getOptions(props, context);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.items) return false;

    if (prevProps.items !== this.props.items) {
      this.setState(
        {
          ...this.getItemState(this.props),
        },
        () => {
          this.setState({ options: this.getOptions(this.props) });
        },
      );
    }
  }

  getItemState(props) {
    if (!props.items) return false;

    const origItems = this.getOriginalItems(props);
    const isClientDefault = this.getIsClientDefault(props, origItems);
    const isSwellDefault = this.getIsSwellDefault(props, origItems);
    const isClientUsingSwellDefault = this.getIsClientUsingSwellDefault(props);

    const visibleItems = props.items.filter((item) => !item.hidden);
    const hiddenItems = props.items.filter((item) => item.hidden);

    return {
      visibleItems: props.filter
        ? visibleItems.filter(props.filter)
        : visibleItems,
      hiddenItems: props.filter
        ? hiddenItems.filter(props.filter)
        : hiddenItems,
      allItems: origItems,
      origItems: this.state?.origItems || origItems,
      isClientDefault,
      isSwellDefault,
      isClientUsingSwellDefault,
    };
  }

  getOriginalItems(props) {
    if (!props.items) return false;

    return props.cleanSaveItem
      ? props.items.map(props.cleanSaveItem)
      : props.items;
  }

  getAllItemsSorted() {
    const { allItems } = this.state;
    const allItemsSorted = [...allItems];

    // Sort items with hidden last
    allItemsSorted.sort((a, b) => {
      if (!a.hidden && b.hidden) {
        return -1;
      } else if (a.hidden && !b.hidden) {
        return 1;
      } else {
        return 0;
      }
    });
    return allItemsSorted;
  }

  getIsClientDefault(props, origItems) {
    if (props.listOnly) return false;

    const cleanDefaults = props.cleanSaveItem
      ? props.clientItems.map(props.cleanSaveItem)
      : props.clientItems;

    return isValueEqual(cleanDefaults, origItems);
  }

  getIsSwellDefault(props, origItems) {
    if (props.listOnly) return false;

    const cleanDefaults = props.cleanSaveItem
      ? props.swellItems.map(props.cleanSaveItem)
      : props.swellItems;

    return isValueEqual(cleanDefaults, origItems);
  }

  getIsClientUsingSwellDefault(props) {
    if (props.listOnly) return false;

    const cleanClientDefaults = props.cleanSaveItem
      ? props.clientItems.map(props.cleanSaveItem)
      : props.clientItems;
    const cleanSwellDefaults = props.cleanSaveItem
      ? props.swellItems.map(props.cleanSaveItem)
      : props.swellItems;

    return isValueEqual(cleanClientDefaults, cleanSwellDefaults);
  }

  getOptions(props, context = undefined) {
    if (props.listOnly) return [];

    const { options: optionProp } = props;
    const { isOwner } = context || this.context;
    const { isClientDefault, isSwellDefault, isClientUsingSwellDefault } =
      this.state;
    const canResetUser = !isClientDefault;
    const canSaveClient = isOwner && !isClientDefault;
    const canResetClient =
      isOwner && !isSwellDefault && !isClientUsingSwellDefault;

    const userOptions = [
      canResetUser && {
        label: 'Reset to store default',
        onClick: this.onClickResetToStoreDefaults,
      },
    ].filter((o) => o);

    const ownerOptions = [
      canSaveClient && {
        label: 'Save as store default',
        onClick: this.onClickSaveDefaults,
      },
      canResetClient && {
        label: 'Reset store to Swell default',
        onClick: this.onClickResetToSwellDefaults,
      },
    ].filter((o) => o);

    const allOptions = [
      optionProp && (
        <Separator key="1" className="preferences-menu-separator" />
      ),
      ...userOptions,
      userOptions.length && ownerOptions.length && (
        <Separator key="2" className="preferences-menu-separator" />
      ),
      ...ownerOptions,
    ].filter((o) => o);

    return allOptions;
  }

  onClickResetToStoreDefaults = (event) => {
    event.preventDefault();
    this.resetPreferences();
    this.setState({ open: false });
  };

  onClickResetToSwellDefaults = (event) => {
    event.preventDefault();
    this.resetPreferences(true);
    this.setState({ open: false });
  };

  onClickSaveDefaults = (event) => {
    event.preventDefault();
    this.updatePreferences(true);
    this.setState({ open: false });
  };

  updatePreferences(clientDefaults = false) {
    const { preferenceKey } = this.props;
    const { updateWithViewPreferences } = this.context;
    return updateWithViewPreferences(
      { [preferenceKey]: this.state.allItems },
      clientDefaults,
    );
  }

  resetPreferences(clientDefaults = false) {
    const { preferenceKey } = this.props;
    const { resetWithViewPreferences } = this.context;
    return resetWithViewPreferences({ [preferenceKey]: null }, clientDefaults);
  }

  onClickSaveDefault = (event) => {
    event.preventDefault();
  };

  onClickItem = (item, event) => {
    event.preventDefault();
    // This random variable seems to determine whether it was dragged or not
    if (this.refs.items.afterIndex === -2) {
      item && this.props.onClickItem?.(item);
      if (this.props.listOnly) {
        this.setState({ open: false });
      }
    }
  };

  /**
   * Event handler for hiding a config.
   * @param {Event} event - The event object.
   */
  onClickHideItem = (event, index) => {
    event.preventDefault();
    const { visibleItems, hiddenItems } = this.state;

    const newItems = this.getAllItemsSorted();
    const oldIndex = findIndex(newItems, { id: visibleItems[index].id });
    const firstIndex = findIndex(newItems, (item) => item.hidden);
    newItems[oldIndex].hidden = true;

    this.setState(
      {
        visibleItems: visibleItems.filter((_item, i) => i !== index),
        hiddenItems: [visibleItems[index], ...hiddenItems],
        allItems: arrayMove(
          newItems,
          oldIndex,
          firstIndex >= 0 ? firstIndex - 1 : 0,
        ),
      },
      () => {
        this.updatePreferences();
      },
    );
  };

  /**
   * Event handler for showing a config.
   * @param {Event} event - The event object.
   */
  onClickShowItem = (event, index) => {
    event.preventDefault();
    const { visibleItems, hiddenItems } = this.state;

    if (hiddenItems[index].hiddenByConditions) {
      return;
    }

    const newItems = this.getAllItemsSorted();
    const oldIndex = findIndex(newItems, { id: hiddenItems[index].id });
    const lastIndex = findLastIndex(newItems, (item) => !item.hidden);
    delete newItems[oldIndex].hidden;

    this.setState(
      {
        visibleItems: [...visibleItems, hiddenItems[index]],
        hiddenItems: hiddenItems.filter((_item, i) => i !== index),
        allItems: arrayMove(
          newItems,
          oldIndex,
          lastIndex >= 0 ? lastIndex + 1 : 0,
        ),
      },
      () => {
        this.updatePreferences();
      },
    );
  };

  /** @param {OnChangeMeta} meta */
  onMoveChange = ({ oldIndex, newIndex }) => {
    const { visibleItems, allItems } = this.state;

    const oldIndexInitial = findIndex(allItems, {
      id: visibleItems[oldIndex]?.id,
    });
    const newIndexInitial = findIndex(allItems, {
      id: visibleItems[newIndex]?.id,
    });
    const newItems = arrayMove(allItems, oldIndexInitial, newIndexInitial);

    this.setState(
      {
        visibleItems: arrayMove(visibleItems, oldIndex, newIndex),
        allItems: newItems,
      },
      () => {
        this.updatePreferences();
      },
    );
  };

  /**
   * Event handler for toggling a state.
   */
  onClickToggleState = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
      // Set orig items to current items when opening
      origItems: prevState.open
        ? this.state.origItems
        : this.getOriginalItems(this.props),
    }));
  };

  renderVisibleItems() {
    const { listOnly } = this.props;
    const { visibleItems } = this.state;

    return (
      <>
        <List
          ref="items"
          lockVertically={true}
          transitionDuration={200}
          values={visibleItems.map((item) => ({
            ...item,
            disabled: listOnly,
          }))}
          onChange={this.onMoveChange}
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ value, props }) => (
            <div className="preferences-menu-movable" {...props}>
              {this.renderVisibleItem(value, props.key)}
            </div>
          )}
        />
      </>
    );
  }

  renderVisibleItem(item, index) {
    const { listOnly } = this.props;

    return (
      <div key={index} className="preferences-menu-item-container">
        <Item
          className="preferences-menu-item"
          onClick={this.onClickItem.bind(this, item)}
        >
          {!listOnly && (
            <div
              aria-label="Click and drag to reorder"
              className="preferences-menu-movable-trigger"
            >
              <Icon svgType="dots-grip" width={16} height={16} />
            </div>
          )}
          {item.label} {this.renderAppIcon(item)}
        </Item>
        {!item.default && (
          <button
            type="button"
            className="preferences-menu-hide-trigger"
            onClick={(event) => this.onClickHideItem(event, index)}
          >
            <Icon svgType="eye" width={16} height={16} />
          </button>
        )}
        {/* TODO: Start of visible options submenu for advanced features like `rename` shelved until next iteration */}
        {/* <Sub>
          <SubTrigger className="preferences-menu-submenu-trigger">
            <Icon svgType="dots-horizontal" />
          </SubTrigger>
          <Portal>
            <SubContent
              sideOffset={2}
              alignOffset={-6}
              className="preferences-menu-submenu-content"
            >
              {map(item.options, (option, index) =>
                this.renderOptionItems(option, index),
              )}
            </SubContent>
          </Portal>
        </Sub> */}
      </div>
    );
  }

  renderHiddenItems() {
    const { preferenceKey } = this.props;
    const { hiddenItems, visibleItems } = this.state;
    return (
      <>
        {visibleItems.length > 0 && (
          <Separator className="preferences-menu-separator" />
        )}
        <Label className="preferences-menu-label">Hidden {preferenceKey}</Label>
        {map(hiddenItems, (item, index) => this.renderHiddenItem(item, index))}
      </>
    );
  }

  renderHiddenItem(item, index) {
    return (
      <div
        key={index}
        className={classNames('preferences-menu-item-container', {
          default: item.hiddenByConditions,
        })}
      >
        <Item
          className="preferences-menu-item muted"
          onClick={this.onClickItem.bind(this, item)}
        >
          {item.label} {this.renderAppIcon(item)}
        </Item>
        <button
          type="button"
          className="preferences-menu-hide-trigger"
          onClick={(event) => this.onClickShowItem(event, index)}
        >
          {item.hiddenByConditions ? (
            <Tooltip message="Disabled" dir="left">
              <Icon svgType="eye-off" width={16} height={16} />
            </Tooltip>
          ) : (
            <Icon svgType="eye-off" width={16} height={16} />
          )}
        </button>
        {item.items?.length > 0 && (
          <Sub>
            <SubTrigger className="preferences-menu-submenu-trigger">
              <Icon svgType="dots-horizontal" />
            </SubTrigger>
            <Portal>
              <SubContent
                sideOffset={2}
                alignOffset={-6}
                className="preferences-menu-submenu-content"
              >
                {map(item.options, (option, index) =>
                  this.renderOptionItems(option, index),
                )}
              </SubContent>
            </Portal>
          </Sub>
        )}
      </div>
    );
  }

  renderAppIcon(item) {
    const {
      client: { appsById },
    } = this.context;

    const app = appsById[item.app_id];
    if (!app) {
      return null;
    }

    return <AppIcon image={app.logo_icon} name={app.name} size={16} />;
  }

  renderOptions() {
    const { options } = this.state;
    return (
      <Sub>
        <Separator className="preferences-menu-separator" />
        <SubTrigger className="preferences-menu-submenu-options-trigger">
          Options
          <Icon svgType="chevron-right" />
        </SubTrigger>
        <Portal>
          <SubContent
            className="preferences-menu-submenu-content"
            align="middle"
            sideOffset={2}
            alignOffset={-5}
          >
            {map(options, (option, index) =>
              this.renderOptionItems(option, index),
            )}
          </SubContent>
        </Portal>
      </Sub>
    );
  }

  renderOptionItems(option, index) {
    return React.isValidElement(option) ? (
      option
    ) : !option ? null : (
      <Item
        key={index}
        onClick={option.onClick}
        className="preferences-menu-submenu-item"
      >
        {option.icon && <Icon svgType={option.icon} />}
        <button type="button" {...option.dataAttribute}>
          {option.label}
        </button>
      </Item>
    );
  }

  render() {
    const {
      buttonChildren,
      buttonClassName = '',
      buttonAriaLabel = '',
      listOnly,
      preferenceKey,
      items,
      side = 'bottom',
      sideOffset = 0,
      align = 'end',
      avoidCollisions = true,
    } = this.props;

    const { visibleItems, hiddenItems, options, open } = this.state;

    if (!items) {
      return null;
    }

    return (
      <Root modal={false} open={open} onOpenChange={this.onClickToggleState}>
        <Trigger asChild>
          <button
            type="button"
            aria-label={
              buttonAriaLabel.length > 0
                ? buttonAriaLabel
                : `Modify ${preferenceKey}`
            }
            className={
              buttonClassName.length > 0
                ? buttonClassName
                : 'preferences-menu-trigger'
            }
          >
            {buttonChildren}
          </button>
        </Trigger>
        <Portal>
          <Content
            className="preferences-menu-content"
            avoidCollisions={avoidCollisions}
            side={side}
            sideOffset={sideOffset}
            align={align}
          >
            <>
              {!listOnly && (
                <Label className="preferences-menu-label">
                  Edit {preferenceKey}
                </Label>
              )}
              {visibleItems.length > 0 && this.renderVisibleItems()}
              {hiddenItems.length > 0 && this.renderHiddenItems()}
              {options.length > 0 && this.renderOptions()}
            </>
          </Content>
        </Portal>
      </Root>
    );
  }
}
