import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsPage from 'components/storefront/settings';
import actions from 'actions';
import { writeToClipboard } from 'utils/clipboard';
import store from '../store/instance';

const mapStateToProps = (state) => ({
  loadingAny: state.loading,
  loading: state.storefronts.loading,
});

const mapDispatchToProps = (dispatch) => ({
  createAPIKey: (sourceId, data) => {
    return dispatch(actions.storefronts.createAPIKey(sourceId, data));
  },

  updateAPIKey: (sourceId, keyId, data) => {
    return dispatch(actions.storefronts.updateAPIKey(sourceId, keyId, data));
  },

  deleteAPIKey: (sourceId, keyId) => {
    return dispatch(actions.storefronts.deleteAPIKey(sourceId, keyId));
  },

  fetchStorefront: (name) => {
    return dispatch(actions.storefronts.fetch(name));
  },

  updateStorefront: (name, data) => {
    return dispatch(actions.storefronts.update(name, data));
  },
});

export class StorefrontSettings extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      values: { ...props.record },
      copiedKey: false,
      onChangeForm: this.onChangeForm.bind(this),
      onSubmitForm: this.onSubmitForm.bind(this),
      onClickDisablePassword: this.onClickDisablePassword.bind(this),
      onCopyKey: this.onCopyKey.bind(this),
      onSaveAPIKey: this.onSaveAPIKey.bind(this),
      onRemoveAPIKey: this.onRemoveAPIKey.bind(this),
    };
  }

  async onChangeForm(values, edited) {
    this.setState({
      values: {
        ...this.state.values,
        ...values,
      },
    });
    this.props.onEdited && this.props.onEdited(edited);
  }

  async onSubmitForm({
    name,
    password,
    password_message,
    password_enabled,
    custom_url,
  }) {
    const { params, record, fetchStorefront, updateStorefront, onEdited } =
      this.props;

    let result = await updateStorefront(record.id, {
      name,
      hosting: {
        password,
        password_message,
        password_enabled,
      },
      external: {
        custom: !!custom_url,
        custom_url,
      },
    });

    if (!result) {
      return false;
    } else if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    result = await fetchStorefront(params.id);

    this.setState({
      values: { ...result },
    });

    onEdited && onEdited(false);

    return result;
  }

  async onClickDisablePassword(event) {
    event.preventDefault();
    const { params, fetchStorefront, updateStorefront } = this.props;
    const { notifyError } = this.context;

    const result = await updateStorefront(params.id, {
      hosting: {
        password_enabled: false,
      },
    });

    if (result && result.errors) {
      notifyError(result.errors);
      return;
    }

    await fetchStorefront(params.id);
  }

  onCopyKey(event, key) {
    event.stopPropagation();

    writeToClipboard(key)
      .then(() => {
        this.setState({
          copiedKey: key,
        });

        setTimeout(() => {
          this.setState({
            copiedKey: false,
          });
        }, 5000);
        return store.dispatch(
          actions.flash.success('API key copied to clipboard'),
        );
      })
      .catch(() => {
        return store.dispatch(
          actions.flash.error(
            'Error copying API key to clipboard. Please select it and copy it manually',
          ),
        );
      });
    return true;
  }

  async onSaveAPIKey(keyId, values) {
    const { params, updateAPIKey, createAPIKey, fetchStorefront } = this.props;

    const result = await (keyId
      ? updateAPIKey(params.id, keyId, values)
      : createAPIKey(params.id, values));

    if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    await fetchStorefront(params.id);

    return true;
  }

  async onRemoveAPIKey(keyId) {
    const { params, deleteAPIKey, fetchStorefront } = this.props;

    const result = await deleteAPIKey(params.id, keyId);

    if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    await fetchStorefront(params.id);

    return true;
  }

  render() {
    return <SettingsPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorefrontSettings);
