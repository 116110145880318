import { find, findIndex, set } from 'lodash';
import fingerprint from 'ssh-fingerprint';

import api from 'services/api';
import auth from 'services/auth';
import {
  CURRENCY_CODE,
  LOCALE_CODE,
  TIMEZONE,
  isValueEqual,
  setCurrency,
  setCurrencyCodes,
  setLocale,
  setLocaleCodes,
  setTimezone,
} from 'utils';

import {
  executeClientUpdateRecaptcha,
  executeClientVerifyRecaptcha,
  executeLeadCreateRecaptcha,
  executeSignupRecaptcha,
} from 'utils/recaptcha';

import account from './account';
import flash from './flash';
import session from './session';
import user from './user';

import { SETTINGS_LOAD_APPS } from './settings';

export const CLIENT_FETCH = 'client/fetch';
export const CLIENT_UPDATE = 'client/update';
export const CLIENT_CREATE = 'client/create';
export const CLIENT_VALIDATE = 'client/validate';
export const CLIENT_LEAD_FETCH = 'client/fetchLead';
export const CLIENT_LEAD_CREATE = 'client/createLead';
export const CLIENT_LEAD_UPDATE = 'client/updateLead';
export const CLIENT_FETCH_SSHKEYS = 'client/fetchSSHKeys';
export const CLIENT_CREATE_SSHKEY = 'client/createSSHKey';
export const CLIENT_DELETE_SSHKEY = 'client/deleteSSHKey';
export const CLIENT_FETCH_ENVIRONMENTS = 'client/fetchEnvironments';
export const CLIENT_ENABLE_LIVE_ENVIRONMENT = 'client/enableLiveEnvironment';
export const CLIENT_UNSUBSCRIBE = 'client/unsubscribe';
export const CLIENT_FETCH_USAGE = 'client/fetchUsage';
export const CLIENT_FETCH_ROLES = 'client/fetchRoles';
export const CLIENT_CREATE_ROLE = 'client/createRole';
export const CLIENT_UPDATE_ROLE = 'client/updateRole';
export const CLIENT_DELETE_ROLE = 'client/deleteRole';
export const CLIENT_REASSIGN_ROLES = 'client/reassignRoles';
export const CLIENT_FETCH_INSTALLED_APPS = 'client/fetchInstalledApps';
export const CLIENT_INSTALL_APP = 'client/installApp';
export const CLIENT_UPDATE_APP = 'client/updateApp';
export const CLIENT_UNINSTALL_APP = 'client/uninstallApp';
export const CLIENT_UPDATE_PREFERENCES = 'client/updatePreferences';

export default {
  fetch(query) {
    return (dispatch) => {
      return dispatch({
        type: CLIENT_FETCH,
        payload: api.get('/client', query).then((client) => {
          if (client) {
            setTimezone(client.timezone);
            this.setLocale(client);
            this.setCurrency(client);

            dispatch(this.loadAppSettings(client.app_settings));

            // Convert client.features to a set.
            transformClientFeatures(client);
          }
          return client;
        }),
      });
    };
  },

  setLocale(client) {
    setLocale(client.locale);
    setLocaleCodes(client.locales || [], localeCodes(client));
  },

  setCurrency(client) {
    setCurrency(client.currency);
    const multiCurrency = find(client.currencies, { code: client.currency });
    if (multiCurrency) {
      setCurrency(client.currency, multiCurrency.decimals);
    }
    setCurrencyCodes(client.currencies || [], currencyCodes(client));
  },

  loadAppSettings(appSettings) {
    return (dispatch) => {
      if (appSettings?.results?.length > 0) {
        dispatch({
          type: SETTINGS_LOAD_APPS,
          payload: appSettings,
        });
      }
    };
  },

  update(data) {
    return async (dispatch, getState) => {
      const { client } = getState();
      const recaptcha = await executeClientUpdateRecaptcha(client);

      return dispatch({
        type: CLIENT_UPDATE,
        payload: api
          .put('/client', {
            ...data,
            recaptcha,
          })
          .then((client) => {
            if (client) {
              setTimezone(client.timezone);
              this.setLocale(client);
              this.setCurrency(client);
            }
            return client;
          })
          .catch((error) => {
            if (typeof error === 'string') {
              error = new Error(error);
            }

            return { error };
          }),
      });
    };
  },

  create(data) {
    return async (dispatch, getState) => {
      const { client } = getState();
      const recaptcha = await executeSignupRecaptcha(client);

      return dispatch({
        type: CLIENT_CREATE,
        payload: api.post('/client', {
          ...data,
          recaptcha,
        }),
      }).then((result) => {
        if (result && !result.error & !result.errors) {
          if (result.session_id) {
            auth.setLoggedInSessionId(result.session_id, result.id);
            return result;
          }
          return Promise.all([
            dispatch(user.fetch()),
            dispatch(session.fetch()),
            dispatch(account.fetchPlan()),
          ]).then(() => {
            return result;
          });
        }
        return result;
      });
    };
  },

  validate(data) {
    return {
      type: CLIENT_VALIDATE,
      payload: api.post('/client/validate', data),
      showLoading: false,
    };
  },

  fetchLead(id) {
    return {
      type: CLIENT_LEAD_FETCH,
      payload: api.get(`/leads/${id}`),
    };
  },

  createLead(data) {
    return async (dispatch, getState) => {
      const { client } = getState();
      const recaptcha = await executeLeadCreateRecaptcha(client);

      return dispatch({
        type: CLIENT_LEAD_CREATE,
        payload: api.post('/leads', { ...data, recaptcha }).catch((err) => {
          dispatch(flash.error(err.message));
          return { error: { message: err.message } };
        }),
      });
    };
  },

  resendLead(id) {
    return {
      type: CLIENT_LEAD_UPDATE,
      payload: api.put(`/leads/${id}`, { $notify: 'verify-new' }),
    };
  },

  verifyLead(id, data) {
    return async (dispatch, getState) => {
      const { client } = getState();
      const recaptcha = await executeClientVerifyRecaptcha(client);
      return dispatch({
        type: CLIENT_LEAD_UPDATE,
        payload: api.post(`/leads/${id}/verify`, { ...data, recaptcha }),
      });
    };
  },

  updateSetup(id, value = true) {
    return async (dispatch, getState) => {
      const { client } = getState();
      if (value !== client.setup[id]) {
        await dispatch({
          type: CLIENT_UPDATE,
          payload: api.put('/client', {
            setup: {
              [id]: value,
            },
          }),
        });
      }
    };
  },

  updateDashboard(values = {}) {
    return async (dispatch, getState) => {
      const { client } = getState();
      if (!isValueEqual(values, client.dashboard)) {
        await dispatch({
          type: CLIENT_UPDATE,
          payload: api.put('/client', {
            dashboard: values,
          }),
        });
      }
    };
  },

  fetchSSHKeys() {
    return {
      type: CLIENT_FETCH_SSHKEYS,
      payload: api.get('/client/sshkeys'),
    };
  },

  createSSHKey(data) {
    return (dispatch, getState) => {
      const user_id = getState().user.id;
      return dispatch({
        type: CLIENT_CREATE_SSHKEY,
        payload: api.post('/client/sshkeys', { ...data, user_id }),
      });
    };
  },

  deleteSSHKey(id) {
    return {
      type: CLIENT_DELETE_SSHKEY,
      payload: api.delete(`/client/sshkeys/${id}`),
    };
  },

  fetchEnvironments() {
    return {
      type: CLIENT_FETCH_ENVIRONMENTS,
      payload: api.get('/client/environments'),
    };
  },

  enableLiveEnvironment() {
    return async (dispatch) => {
      const result = await dispatch({
        type: CLIENT_ENABLE_LIVE_ENVIRONMENT,
        payload: api.put('/client/live'),
      });
      if (result?.live) {
        await dispatch(account.fetchPlan());
      }
      return result;
    };
  },

  unsubscribeFromEmails(query) {
    return {
      type: CLIENT_UNSUBSCRIBE,
      payload: api.put(`/client/unsubscribe`, query),
    };
  },

  fetchUsage() {
    return {
      type: CLIENT_FETCH_USAGE,
      payload: api.get('/client/usage'),
    };
  },

  fetchRoles() {
    return {
      type: CLIENT_FETCH_ROLES,
      payload: api.get('/client/roles'),
    };
  },

  createRole(data) {
    return {
      type: CLIENT_CREATE_ROLE,
      payload: api.post('/client/roles', data),
    };
  },

  updateRole(data) {
    return {
      type: CLIENT_UPDATE_ROLE,
      payload: api.put('/client/roles', data),
    };
  },

  deleteRole(id) {
    return {
      type: CLIENT_DELETE_ROLE,
      payload: api.delete(`/client/roles/${id}`),
    };
  },

  reassignRoles(data) {
    return {
      type: CLIENT_REASSIGN_ROLES,
      payload: api.delete('/client/roles', data),
    };
  },

  fetchInstalledApps() {
    return {
      type: CLIENT_FETCH_INSTALLED_APPS,
      payload: api.get('/client/apps'),
    };
  },

  installApp(id, data) {
    return {
      type: CLIENT_INSTALL_APP,
      payload: api.post(`/client/apps/${id}`, data),
    };
  },

  updateApp(id, data) {
    return {
      type: CLIENT_UPDATE_APP,
      payload: api.put(`/client/apps/${id}`, data),
      meta: { id, data },
    };
  },

  uninstallApp(id) {
    return {
      type: CLIENT_UNINSTALL_APP,
      payload: api.put(`/client/apps/${id}`, { uninstalled: true }),
      meta: { id },
    };
  },

  updatePreferences(data) {
    return {
      type: CLIENT_UPDATE_PREFERENCES,
      payload: api.put('/client/preferences', data),
    };
  },
};

export const initialState = {
  id: null,
  environments: undefined,
  setup: {},
  trialExpired: false,
  sshKeys: [],
  exists: null,
  lead: null,
  timezone: null,
  locale: null,
  locales: [],
  localeCodes: [],
  localeNames: {},
  currency: null,
  currencies: [],
  currencyCodes: [],
  currencyNames: {},
  pricedCurrencies: [],
  preferences: {},
  features: new Set(),
  super_features: {},
  apps: {},
  appsById: {},
  appsBySlug: {},
  appsEnabledById: {},
  appsEnabledBySlug: {},
  usage: null,
  dashboard: {
    orders: {
      default: true,
    },
    products: {},
    sales: {
      sales: true,
      sales_by_product: true,
    },
    customers: {
      customers: true,
    },
  },
};

export function reducer(state = initialState, action) {
  if (action.payload && action.payload.error) {
    return state;
  }
  switch (action.type) {
    case CLIENT_FETCH:
    case CLIENT_UPDATE:
    case CLIENT_CREATE:
    case CLIENT_ENABLE_LIVE_ENVIRONMENT:
      const locale = cleanLocale(action.payload.locale) || LOCALE_CODE;
      const currency = action.payload.currency || CURRENCY_CODE;
      return {
        ...state,
        ...action.payload,
        timezone: action.payload.timezone || TIMEZONE,
        locale,
        locales: sortCurrencyLocaleConfigs(
          locale,
          action.payload.locales || [],
        ),
        localeCodes: localeCodes(action.payload),
        localeNames: localeNames(action.payload),
        currency,
        currencies: sortCurrencyLocaleConfigs(
          currency,
          action.payload.currencies || [],
        ),
        currencyCodes: currencyCodes(action.payload),
        currencyNames: currencyNames(action.payload),
        pricedCurrencies: (action.payload.currencies || []).filter(
          (curr) =>
            curr.active !== false &&
            (curr.priced || curr.code === action.payload.currency),
        ),
        setup: action.payload.setup || initialState.setup,
        dashboard: action.payload.dashboard || initialState.dashboard,
        trialExpired: trialExpired(action.payload),
        usage: null, // In some cases this object exists on the record but should be re-fetched
        ...appsById(action.payload.apps),
      };

    case CLIENT_LEAD_FETCH:
    case CLIENT_LEAD_CREATE:
      return {
        ...state,
        lead: action.payload,
      };

    case CLIENT_FETCH_SSHKEYS:
      return {
        ...state,
        sshKeys: appendSSHKeyFingerprints(action.payload),
      };

    case CLIENT_FETCH_USAGE:
      return {
        ...state,
        usage: action.payload,
      };

    case CLIENT_FETCH_ENVIRONMENTS:
      return {
        ...state,
        environments: action.payload,
      };

    case CLIENT_INSTALL_APP:
      const results = [...state.apps.results, action.payload];
      return {
        ...state,
        apps: {
          ...state.apps,
          results,
          count: state.apps.count + 1,
        },
        ...appsById({ results }),
      };

    case CLIENT_UPDATE_APP:
      const updateResults = [...state.apps.results];
      const updateIndex = findIndex(updateResults, { app_id: action.meta.id });
      if (updateIndex >= 0) {
        set(updateResults, `[${updateIndex}]`, {
          ...updateResults[updateIndex],
          ...action.meta.data,
        });
        return {
          ...state,
          apps: {
            ...state.apps,
            results: updateResults,
          },
          ...appsById({ results: updateResults }),
        };
      }
      return state;

    case CLIENT_UNINSTALL_APP:
      const removeResults = [...state.apps.results];
      remove(removeResults, { app_id: action.meta.id });
      return {
        ...state,
        apps: {
          ...state.apps,
          results: removeResults,
          count: state.apps.count - 1,
        },
        ...appsById({ results: removeResults }),
      };

    case CLIENT_UPDATE_PREFERENCES:
      return {
        ...state,
        preferences: action.payload,
      };

    default:
      return state;
  }
}

function trialExpired(client) {
  if (client.date_trial_end && new Date(client.date_trial_end) < Date.now()) {
    return true;
  }
  return false;
}

function appendSSHKeyFingerprints(keys) {
  for (let key of keys) {
    key.fingerprint = fingerprint(key.public_key);
  }
  return keys;
}

function cleanLocale(code) {
  if (code) {
    return String(code).replace('_', '-');
  }
  return code;
}

function localeCodes(client) {
  if (client.locales && client.locales.length) {
    return client.locales.map(({ code }) => code);
  }
  return [];
}

function localeNames(client) {
  if (client.locales && client.locales.length) {
    const names = {};
    for (let locale of client.locales) {
      names[locale.code] = locale.name;
    }
    return names;
  }
  return {};
}

function currencyCodes(client) {
  if (client.currencies && client.currencies.length) {
    // Only for priced currencies
    return client.currencies
      .filter((curr) => curr.priced || curr.code === client.currency)
      .map(({ code }) => code);
  }
  return [];
}

function currencyNames(client) {
  if (client.currencies && client.currencies.length) {
    const names = {};
    for (let currency of client.currencies) {
      names[currency.code] = currency.name;
    }
    return names;
  }
  return {};
}

function sortCurrencyLocaleConfigs(code, configs) {
  return configs.sort((a, b) => (a.code === code ? -1 : 1));
}

function appsById(installedApps) {
  const apps = {
    apps: {},
    appsById: {},
    appsBySlug: {},
    appsEnabledById: {},
    appsEnabledBySlug: {},
  };
  const validInstalledApps = (installedApps?.results || []).filter(
    (installed) => installed.app,
  );

  apps.apps = {
    ...installedApps,
    results: validInstalledApps,
  };

  for (const installed of validInstalledApps) {
    // Define a slug ID for easy display purposes
    installed.app.slug_id =
      installed.app.public_id || installed.app.private_id?.replace(/^_/, '');
    installed.app_slug_id = installed.app_private_id?.replace(/^_/, '');
    
    apps.appsById[installed.app_id] = installed.app;
    apps.appsBySlug[installed.app.slug_id] = installed.app;

    if (installed.active !== false) {
      apps.appsEnabledById[installed.app_id] = installed;
      apps.appsEnabledBySlug[installed.app.slug_id] = installed;
    }
  }

  return apps;
}

/**
 * Transforms client.features into a set of enabled client.features.
 *
 * Before:
 * {
 *   features: {
 *     count: 2,
 *     results: [
 *       {id: "a", feature_id: "featureX"},
 *       {id: "b", feature_id: "featureY"}
 *     ]
 *   }
 * }
 *
 * After:
 * {
 *   features: Set{"featureX", "featureY"}
 * }
 *
 * Also transforms client.super_features into an object of enabled client.super_features.
 *
 * Before:
 * {
 *   super_features: {
 *     count: 2,
 *     results: [
 *       {id: "a", feature_id: "featureX", some_field: {a: 4}},
 *       {id: "b", feature_id: "featureY"}
 *     ]
 *   }
 * }
 *
 * After:
 * {
 *   super_features: {
 *     featureX: {
 *       id: "a",
 *       feature_id: "featureX",
 *       some_field: {
 *         a: 4
 *       }
 *     },
 *     featureY: {
 *       id: "b",
 *       feature_id: "featureY",
 *     },
 *     get(id), function to get feature values by id
 *     has(id), function to check if a feature is enabled
 *   }
 * }
 *
 * @param client The client result
 */
function transformClientFeatures(client) {
  // Check for an included client.features result set
  if (client) {
    if (client.features && Array.isArray(client.features.results)) {
      const features = client.features.results.map(
        (feature) => feature.feature_id,
      );
      client.features = new Set(features);
    }
    if (client.super_features && Array.isArray(client.super_features.results)) {
      client.super_features = client.super_features.results.reduce(
        (acc, feature) => {
          acc[feature.feature_id] = feature;
          return acc;
        },
        {},
      );
      client.super_features.get = (id) => client.super_features[id];
      client.super_features.has = (id) =>
        Boolean(client.super_features.get(id));
    }
  }
}
