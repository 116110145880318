import React from 'react';
import { connect } from 'react-redux';
import capitalize from 'lodash/capitalize';

import { arrayToObject, template } from 'utils';

import settings from 'actions/settings';
import { replaceLocationQuery } from 'actions/data';

import ViewLoading from 'components/view/loading';
import Tooltip from 'components/tooltip';
import Status from 'components/status';

import Collection from './Collection';

const TRUNC_ID_LENGTH = 30;

const query = {
  sort: 'name asc',
  include: {
    product_count: {
      url: '/products/:count?attributes.{id}[$nin]&attributes.{id}[$nin]=&attributes.{id}[$exists]=true',
    },
  },
};

const filters = {
  date_created: {
    label: 'Created',
    operators: ['after', 'before'],
    type: 'date',
  },
  type: {
    label: 'Type',
    options: [
      { value: 'variant', label: 'Variant' },
      { value: 'text', label: 'Text' },
      { value: 'select', label: 'Select' },
      { value: 'checkbox', label: 'Checkbox' },
      { value: 'radio', label: 'Radio' },
      { value: 'image', label: 'Image' },
    ],
    func(query) {
      switch (query) {
        case 'select':
          return { type: { $in: ['select', 'dropdown'] } };
        case 'variant':
          return { variant: true };
        case 'text':
          return { type: 'text' };
        case 'checkbox':
          return { type: 'checkbox' };
        case 'radio':
          return { type: 'radio' };
        case 'image':
          return { type: 'image' };
        default:
          break;
      }
    },
  },
  visible: {
    label: 'Visible',
    options: [
      { value: 'true', label: 'True' },
      { value: 'false', label: 'False' },
    ],
    func(query) {
      switch (query) {
        case 'true':
          return { visible: true };

        case 'false':
          return { visible: false };

        default:
          break;
      }
    },
    desc(query) {
      switch (query) {
        case 'true':
          return 'Visible';

        case 'false':
          return 'Not visible';

        default:
          break;
      }
    },
  },
  filterable: {
    label: 'Filterable',
    options: [
      { value: 'true', label: 'True' },
      { value: 'false', label: 'False' },
    ],
    func(query) {
      switch (query) {
        case 'true':
          return { filterable: true };

        case 'false':
          return { filterable: false };

        default:
          break;
      }
    },
    desc(query) {
      switch (query) {
        case 'true':
          return 'Filterable';

        case 'false':
          return 'Not filterable';

        default:
          break;
      }
    },
  },
  required: {
    label: 'Required',
    options: [
      { value: 'true', label: 'True' },
      { value: 'false', label: 'False' },
    ],
    func(query) {
      switch (query) {
        case 'true':
          return { required: true };

        case 'false':
          return { required: false };

        default:
          break;
      }
    },
    desc(query) {
      switch (query) {
        case 'true':
          return 'Required';

        case 'false':
          return 'Not required';

        default:
          break;
      }
    },
  },
};

function attributeUrlFunction(record) {
  // use special path for "new"
  if (record?.id && String(record.id).toLowerCase() === 'new') {
    return '/attributes/edit/new';
  }

  return template('/attributes/{id}', record);
}

const fields = {
  name: {
    label: 'Attribute',
    type: 'link',
    url: attributeUrlFunction,
    default: true,
  },
  id: {
    label: 'ID',
    default: true,
    func(attr) {
      if (attr.id.length > TRUNC_ID_LENGTH) {
        return (
          <Tooltip message={attr.id}>
            <code>{attr.id.slice(0, TRUNC_ID_LENGTH)}...</code>
          </Tooltip>
        );
      }
      return <code>{attr.id}</code>;
    },
  },
  product_count: {
    label: 'Products',
    sort: false,
  },
  type: {
    label: 'Type',
    func: (attr) => (attr.variant ? 'Variant' : capitalize(attr.type)),
  },
  visible: {
    label: 'Visibility',
    sort: false,
    func(attr) {
      return (
        <Status type={attr.visible ? 'positive' : 'muted'}>
          {attr.visible ? 'Visible' : 'Hidden'}
        </Status>
      );
    },
  },
  filterable: {
    label: 'Filtering',
    sort: false,
    func(attr) {
      return (
        <Status type={attr.filterable ? 'positive' : 'muted'}>
          {attr.filterable ? 'Filterable' : null}
        </Status>
      );
    },
  },
  required: {
    label: 'Required',
    sort: false,
    func(attr) {
      return (
        <Status type={attr.required ? 'positive' : 'muted'}>
          {attr.variant ? null : attr.required ? 'Required' : 'Optional'}
        </Status>
      );
    },
  },
};

const headerActions = [{ label: 'New attribute', link: '/attributes/new' }];

class Attributes extends React.Component {
  static async onEnter(store, nextState, replace) {
    if (replaceLocationQuery(store, nextState, replace)) {
      return;
    }
  }

  state = {
    tabs: {},
    attributeSets: {},
    loaded: false,
  };

  componentDidMount() {
    this.props.fetchSettings('products').then((productSettings) => {
      if (!productSettings) {
        this.setState({ loaded: true });
        return;
      }

      const attributeSets = arrayToObject(productSettings.attributeSets || []);

      const tabs = {
        default: {
          label: 'All attributes',
        },
        visible: {
          label: 'Visible',
          query: {
            visible: true,
          },
        },
        hidden: {
          label: 'Hidden',
          query: {
            visible: { $ne: true },
          },
        },
      };

      for (const set of Object.values(attributeSets)) {
        tabs[set.id] = {
          label: set.name,
        };
      }

      this.setState({ tabs, loaded: true });
    });
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return (
      <Collection
        {...this.props}
        title="Attributes"
        uri="/attributes"
        model="attributes"
        emptyDescription="Attributes help describe describe products with properties in a uniform way, and enhance search in your storefront."
        tabs={this.state.tabs}
        filters={filters}
        fields={fields}
        headerActions={headerActions}
        query={query}
      />
    );
  }
}

const mapDispatchToProps = {
  fetchSettings: settings.fetch,
};

export default connect(null, mapDispatchToProps)(Attributes);
