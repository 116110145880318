import PropTypes from 'prop-types';
import React from 'react';
import Label from './label';
import Field from './field';
import FieldLocalized from './field-localized';
import { currencySymbol } from 'utils';

export default class FieldAmount extends React.Component {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    typeName: PropTypes.string.isRequired,
    fixedName: PropTypes.string.isRequired,
    fixedValue: PropTypes.string.isRequired,
    percentName: PropTypes.string.isRequired,
    percentValue: PropTypes.string.isRequired,
    valueType: PropTypes.any,
    valueFixed: PropTypes.any,
    valuePercent: PropTypes.any,
    defaultValueType: PropTypes.any,
    defaultValueFixed: PropTypes.any,
    defaultValuePercent: PropTypes.any,
    localized: PropTypes.bool,
    localeValue: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      type: this.getAmountType(props),
      value: this.getAmountValue(props),
      valueFixed: this.getAmountValueFixed(props),
      valuePercent: this.getAmountValuePercent(props),
    };
    this.onChange = this.onChange.bind(this);
    this.onChangeLocalized = this.onChangeLocalized.bind(this);
    this.onClickAmountSymbol = this.onClickAmountSymbol.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const isValueChanged =
      nextProps.defaultValueType !== this.props.defaultValueType ||
      nextProps.defaultValueFixed !== this.props.defaultValueFixed ||
      nextProps.defaultValuePercent !== this.props.defaultValuePercent;
    if (isValueChanged) {
      this.setState({ value: this.getAmountValue(nextProps) });
    }
  }

  getAmountType(props) {
    return props.valueType || props.defaultValueType || props.fixedValue;
  }

  getAmountValue(props) {
    const { fixedValue, percentValue } = props;

    const type = this.getAmountType(props);

    if (type === fixedValue) {
      return this.getAmountValueFixed(props);
    } else if (type === percentValue) {
      return this.getAmountValuePercent(props);
    }

    return '';
  }

  getAmountValueFixed(props) {
    const { valueFixed, defaultValueFixed } = props;
    if (valueFixed !== undefined) {
      return valueFixed;
    }
    if (defaultValueFixed !== undefined) {
      return defaultValueFixed;
    }
    return '';
  }

  getAmountValuePercent(props) {
    const { valuePercent, defaultValuePercent } = props;
    if (valuePercent !== undefined) {
      return valuePercent;
    }
    if (defaultValuePercent !== undefined) {
      return defaultValuePercent;
    }
    return '';
  }

  onChange(event, value) {
    this.setState({ value });
    if (this.state.type === this.props.fixedValue) {
      this.setState({ valueFixed: value });
    } else if (this.state.type === this.props.percentValue) {
      this.setState({ valuePercent: value });
    }
  }

  onChangeLocalized(event, value, component) {
    if (!component.props['data-locale']) {
      this.setState({ valueFixed: value });
    }
  }

  onClickAmountSymbol(event) {
    event.preventDefault();
    const type = event.currentTarget.dataset.type;
    this.setState({ type });
    if (type === this.props.fixedValue) {
      this.setState({ value: this.state.valueFixed });
    } else if (type === this.props.percentValue) {
      this.setState({ value: this.state.valuePercent });
    }
    setTimeout(() => {
      const input =
        this.refs.input && this.refs.input.getWrappedInstance
          ? this.refs.input.getWrappedInstance()
          : this.refs.input;
      if (input && input.focus) {
        input.focus();
      }
    }, 50);
  }

  render() {
    const {
      label,
      help,
      currency,
      required,
      typeName,
      fixedName,
      percentName,
      fixedValue,
      percentValue,
      defaultValueType,
      defaultValueFixed,
      defaultValuePercent,
      className,
      localized = false,
      localeValue,
      localeFieldPrefix,
      localeFieldName,
      ...props
    } = this.props;

    const { type } = this.state;
    const isFixed = type === fixedValue;
    const isPercent = type === percentValue;
    const currencySymbolString = currencySymbol(currency);

    return (
      <span
        className={`form-amount ${className ? className : ''} ${
          localized ? 'localized' : ''
        }`}
      >
        <Label label={label} help={help} htmlFor={props.id} />
        <span className="form-field-input">
          <span className="form-amount-toggle">
            <span className="form-amount-symbol fixed">
              <a
                href=""
                className={isFixed ? 'selected' : ''}
                onClick={this.onClickAmountSymbol}
                data-type="fixed"
              >
                {currencySymbolString}
              </a>
            </span>
            <span className="form-amount-symbol percent">
              <a
                href=""
                className={isPercent ? 'selected' : ''}
                onClick={this.onClickAmountSymbol}
                data-type="percent"
              >
                %
              </a>
            </span>
          </span>
          <Field
            type="hidden"
            name={typeName}
            defaultValue={defaultValueType}
            value={type}
          />
          {isFixed && (
            <FieldLocalized
              type="currency"
              ref="input"
              name={fixedName}
              value={this.state.value}
              onChange={localized ? this.onChangeLocalized : this.onChange}
              selectFocus={true}
              autoFocus={props.autoFocus}
              className={`${props.disabled ? 'disabled' : ''} ${
                props.className || ''
              }`}
              currencySymbolPadding={localized ? 74 : 87}
              required={required}
              currency={currency}
              localized={localized}
              localeValue={localeValue}
              localeFieldPrefix={localeFieldPrefix}
              localeFieldName={localeFieldName}
            />
          )}
          {isPercent && (
            <span>
              <span className="form-percent-symbol">%</span>
              <Field
                type="number"
                {...props}
                ref="input"
                name={percentName}
                value={this.state.value}
                onChange={this.onChange}
                selectFocus={true}
                autoFocus={props.autoFocus}
                className={`${props.disabled ? 'disabled' : ''} ${
                  props.className || ''
                }`}
                placeholder="0"
                required={required}
                scale={isPercent ? undefined : 2}
              />
            </span>
          )}
        </span>
      </span>
    );
  }
}
