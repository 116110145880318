import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'components/modal';
import Form from 'components/form';
import CategoryForm from 'components/pages/category/form';

export default class NewCategoryModalPage extends React.Component {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  onClickSubmit(event) {
    event.preventDefault();
    this.refs.form.submit();
  }

  onCloseModal(event) {
    if (this.props.edited) {
      event.preventDefault();
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.context.closeModal();
        },
      });
    }
  }

  render() {
    const { onSubmitRecord, onChangeForm } = this.props;

    return (
      <Modal
        title="New category"
        width={800}
        onClose={this.onCloseModal}
        actions={[{ label: 'Save category', onClick: this.onClickSubmit }]}
        localized={true}
        devtools={{ model: 'categories', console: false }}
        helpLink={false}
      >
        <Form
          ref="form"
          onSubmit={onSubmitRecord}
          onChange={onChangeForm}
          autoFocus={true}
        >
          <CategoryForm {...this.props} modal={true} />
        </Form>
      </Modal>
    );
  }
}
