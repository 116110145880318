import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ViewLoading from 'components/view/loading';
import AccountIndexPage from 'components/settings/account';
import NotFoundPage from 'components/pages/error/404';
import actions from 'actions';
import api from 'services/api';
import { tokenizeCard } from 'services/api';
import segment from 'services/segment';
import moment from 'moment';

import AccountPlans from 'components/settings/account-plans';
import AccountInvoices from 'components/settings/account-invoices';

const BASE_URI = process.env.BASE_URI;

const mapStateToProps = (state) => ({
  account: state.account,
  client: state.client,
  user: state.user,
  flash: state.flash,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccount: () => {
    return dispatch(actions.account.fetch());
  },

  updateAccount: (data) => {
    return dispatch(actions.account.update(data));
  },

  fetchClient: () => {
    return dispatch(actions.client.fetch());
  },

  fetchPlan: () => {
    return dispatch(actions.account.fetchPlan());
  },

  createPlan: (data) => {
    return dispatch(actions.account.createPlan(data));
  },

  updatePlan: (data) => {
    return dispatch(actions.account.updatePlan(data));
  },

  cancelPlan: (data) => {
    return dispatch(actions.account.cancelPlan(data));
  },

  applyPromo: (code) => {
    return dispatch(actions.account.applyPromo(code));
  },

  fetchInvoice: (id) => {
    return dispatch(actions.account.fetchInvoice(id));
  },

  fetchInfo: () => {
    return dispatch(actions.account.fetchInfo());
  },

  fetchUsage: () => {
    return dispatch(actions.client.fetchUsage());
  },

  fetchUsageGraph: () => {
    return dispatch(actions.reports.fetchAccountUsageGraph());
  },

  fetchUserCount: () => {
    return api.get('/data/:users/:count');
  },

  userLogout: () => {
    dispatch(actions.user.performLogout());
  },
});

export class AccountSettings extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loaded: false,
      invoice: null,
      userCount: null,
      usageGraph: null,
      onSelectPlan: this.onSelectPlan.bind(this),
      onUpdateBilling: this.onUpdateBilling.bind(this),
      onCancelPlan: this.onCancelPlan.bind(this),
      onClickLogout: this.onClickLogout.bind(this),
    };
  }

  async componentWillMount() {
    const {
      fetchAccount,
      fetchInvoice,
      fetchInfo,
      route,
      params,
    } = this.props;

    await fetchAccount();

    if (route.section === 'account-invoices') {
      const invoice = await fetchInvoice(params.id);
      this.setState({ invoice });
    } else {
      await this.fetchUsageData();
    }

    const leadInfo = await fetchInfo();
    this.setState({ leadInfo });

    this.setState({ loaded: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.client?.usage && !nextProps.client?.usage) {
      this.fetchUsageData();
    }
  }

  async fetchUsageData() {
    const {
      fetchUsage,
      fetchUsageGraph,
      fetchUserCount,
    } = this.props;
    const [userCount, usageGraph] = await Promise.all([
      fetchUserCount(),
      fetchUsageGraph(),
      fetchUsage(),
    ]);
    this.setState({ userCount, usageGraph });
  }

  async onSelectPlan(values) {
    const {
      router,
      client,
      account,
      user,
      fetchAccount,
      fetchPlan,
      fetchClient,
      createPlan,
      updatePlan,
      redirectUrl,
    } = this.props;
    const { leadInfo } = this.state;

    const companyType =
      leadInfo && leadInfo.signup_state
        ? leadInfo.signup_state.company_type
        : '';
    const parts = user.name.split(' ');
    const firstName = parts.shift();
    const lastName = parts.join(' ');

    if (values.billing) {
      const result = await this.onUpdateBilling({
        billing: values.billing,
      });
      if (!result) {
        return false;
      }
    }

    const planData = {
      plan: values.plan,
      interval: values.interval,
      interval_count: values.interval_count,
    };

    const planResult = await (account.plan && !account.plan.canceled
      ? updatePlan(planData)
      : createPlan(planData));

    if (!planResult) {
      return false;
    } else if (planResult.errors) {
      this.context.notifyError(planResult.errors);
      return false;
    }

    const wasExiled =
      (client.trialExpired && !account.plan) ||
      (account.planCanceled && !account.planCurrent);

    if (wasExiled) {
      const newPlan = await fetchPlan();
      await segment.track(
        'User subscribed plan',
        {
          id: user.id,
          username: user.username,
          email: user.username,
          client_id: user.client_id,
          client_name: client.name,
          first_name: firstName,
          last_name: lastName,
          plan_name: newPlan.name,
          stage_name: 'Closed Won',
          close_date: moment().add(2, 'months').format(),
          company_type: companyType,
        },
        {
          Salesforce: true,
        },
      );
      window.location.href = BASE_URI || '/';
    } else {
      // Fetch client before account in order to get new env flags
      await fetchClient();

      const [newPlan] = await Promise.all([fetchPlan(), fetchAccount()]);

      const userDowngradedPlan =
        newPlan.metadata.price < account.plan.metadata.price;
      const today = moment().format();

      if (account.plan) {
        this.context.notifySuccess('Your subscription has been updated');
        if (!account.plan.canceled) {
          const params = {
            id: user.id,
            username: user.username,
            client_id: user.client_id,
            client_name: client.name,
            orig_plan_name: account.plan.name,
            plan_name: newPlan.name,
          };

          if (!userDowngradedPlan) {
            params.upgrade_date = today;
            if (account.plan.metadata.name === 'Trial') {
              params.stage_name = 'Closed Won';
            }
          } else {
            params.downgrade_date = today;
          }

          await segment.track(
            userDowngradedPlan ? 'User downgraded plan' : 'User upgraded plan',
            params,
            {
              Salesforce: true,
            },
          );
        } else {
          await segment.track(
            'User subscribed plan',
            {
              id: user.id,
              username: user.username,
              email: user.username,
              client_id: user.client_id,
              client_name: client.name,
              first_name: firstName,
              last_name: lastName,
              plan_name: newPlan.name,
              stage_name: 'Closed Won',
              close_date: moment().add(2, 'months').format(),
              company_type: companyType,
            },
            {
              Salesforce: true,
            },
          );
        }
      } else {
        await segment.track(
          'User subscribed plan',
          {
            id: user.id,
            username: user.username,
            email: user.username,
            client_id: user.client_id,
            client_name: client.name,
            first_name: firstName,
            last_name: lastName,
            plan_name: newPlan.name,
            stage_name: 'Closed Won',
            close_date: moment().add(2, 'months').format(),
            company_type: companyType,
          },
          {
            Salesforce: true,
          },
        );
      }

      router.push(redirectUrl || `/settings/account`);

      await router.push(window.location.pathname);

      await flashSuccess(`Subscription plan has been successfully updated.`);
    }
  }

  async onUpdateBilling(values) {
    const { account, updateAccount, fetchAccount } = this.props;

    const { BILLING_PUBLIC_KEY } = process.env;

    const card = values.billing.card || {};

    this.setState({ loading: true });

    if (card.number) {
      try {
        const billing = { ...values.billing, card: undefined };
        const cardResult = await tokenizeCard(BILLING_PUBLIC_KEY, {
          card,
          billing,
        });
        if (cardResult.errors) {
          let knownError =
            cardResult.errors.gateway || cardResult.errors.number;
          if (knownError) {
            switch (knownError.code) {
              case 'CARD_DECLINED':
                knownError = {
                  message: 'Your credit card was declined, please try again',
                };
                break;
              case 'INCORRECT_CVC':
                knownError = {
                  message: 'Your card CVC code is incorrect, please try again',
                };
                break;
              case 'EXPIRED_CARD':
                knownError = {
                  message: 'Your credit card is expired, please try again',
                };
                break;
              default:
                break;
            }
          }
          throw new Error(
            `${
              knownError
                ? knownError.message
                : JSON.stringify(cardResult.errors)
            }`,
          );
        }
        values.billing.method = 'card';
        values.billing.card = cardResult;
        values.$subscription_payment = true;
      } catch (err) {
        this.context.notifyError(err.message);
        this.setState({ loading: undefined });
        return false;
      }
    }

    try {
      const result = await updateAccount({
        billing: {
          ...values.billing,
        },
      });

      this.setState({ loading: undefined });

      if (!result) {
        return false;
      } else if (result.errors) {
        if (result.errors['cards.0.gateway']) {
          this.context.notifyError(result.errors['cards.0.gateway'].message);
        } else {
          this.context.notifyError(result.errors);
        }
        return false;
      }
    } catch (err) {
      this.context.notifyError(err.message);
      this.setState({ loading: undefined });
      return false;
    }

    await fetchAccount();

    if (account.plan?.metadata?.id !== 'trial') {
      setTimeout(() => {
        this.context.notifySuccess(
          `Your billing ${
            card.number ? 'and payment' : ''
          } information has been updated`,
        );
      }, 250);
    }

    return true;
  }

  async onCancelPlan(values) {
    const { router, fetchPlan, cancelPlan, client, user, account } = this.props;
    const today = moment().format();

    const result = await cancelPlan(values);

    if (!result) {
      return false;
    } else if (result && result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    await segment.track(
      'User canceled plan',
      {
        id: user.id,
        username: user.username,
        client_id: user.client_id,
        client_name: client.name,
        plan_name: account.plan.name,
        canceled_plan: true,
        cancel_date: today,
      },
      {
        Salesforce: true,
      },
    );

    router.push(`/settings/account`);
    this.context.notifyError('Your account has been canceled 🙁');

    await fetchPlan();
  }

  onClickLogout(event) {
    event.preventDefault();
    const { userLogout, router } = this.props;
    userLogout();
    router.push('/login');
  }

  renderPage() {
    const { section } = this.props.route;
    switch (section) {
      case 'account-plans':
        return <AccountPlans {...this.props} {...this.state} />;
      case 'account-invoices':
        return <AccountInvoices {...this.props} {...this.state} />;
      default:
        return <AccountIndexPage {...this.props} {...this.state} />;
    }
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    if (this.props.section && !this.props.settings[this.props.section]) {
      return <NotFoundPage />;
    }

    return this.renderPage();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
