import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

const easeOutQuart = 'cubic-bezier(0.165, 0.84, 0.44, 1)';

export default class FadeInUp extends React.Component {
  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount();
    }
  }

  render() {
    const {
      distance = '30px',
      duration = 750,
      children,
      onMount,
      ...props
    } = this.props;

    const transitionStyles = {
      defaultStyle: {
        opacity: 0,
        transform: `translate(0, ${distance})`,
      },
      enterStyle: {
        opacity: transit(1, duration, easeOutQuart),
        transform: transit('translate(0, 0)', duration, easeOutQuart),
      },
      leaveStyle: {
        opacity: transit(0, duration, easeOutQuart),
        transform: transit(`translate(0, ${distance})`, duration, easeOutQuart),
      },
      activeStyle: {},
      active: true,
      transitionAppear: true,
    };
    return (
      <CSSTransition {...transitionStyles} {...props}>
        {children}
      </CSSTransition>
    );
  }
}
