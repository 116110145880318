import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, findIndex, pick } from 'lodash';
import { objectToArray } from 'utils';
import { viewConfigsWithPreferences } from 'utils/preferences';
import { getVisibleConfigsByLocation } from 'utils/collection';

const mapStateToProps = (state) => ({
  client: state.client,
  user: state.user,
  content: state.content,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  //
});

export class WithViewPreferences extends React.Component {
  static contextTypes = {
    updateViewPreferences: PropTypes.func.isRequired,
    resetViewPreferences: PropTypes.func.isRequired,
  };

  static childContextTypes = {
    getCurrentView: PropTypes.func,
    withPreferences: PropTypes.bool,
    updateWithViewPreferences: PropTypes.func,
    resetWithViewPreferences: PropTypes.func,
    cleanSaveTab: PropTypes.func,
    cleanSaveField: PropTypes.func,
    cleanSaveFilter: PropTypes.func,
    tabs: PropTypes.array,
    clientTabs: PropTypes.array,
    swellTabs: PropTypes.array,
    fields: PropTypes.array,
    clientFields: PropTypes.array,
    swellFields: PropTypes.array,
    filters: PropTypes.array,
    clientFilters: PropTypes.array,
    swellFilters: PropTypes.array,
  };

  static propTypes = {
    model: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    view: PropTypes.object,
    location: PropTypes.object,
    locationQuery: PropTypes.object,
    tabs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    fields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    filters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      ...this.getStateWithPreferences(),
    };
  }

  componentDidUpdate(prevProps) {
    const { user, client, content, settings, tabs, fields, filters, location } =
      this.props;

    if (
      prevProps.content !== content ||
      prevProps.tabs !== tabs ||
      prevProps.fields !== fields ||
      prevProps.filters !== filters ||
      prevProps.location !== location ||
      prevProps.user !== user ||
      prevProps.client !== client ||
      prevProps.settings !== settings
    ) {
      this.setState({
        ...this.getStateWithPreferences(),
      });
    }
  }

  getChildContext() {
    return {
      getCurrentView: this.getCurrentView.bind(this),
      withPreferences: true,
      updateWithViewPreferences: this.updateWithViewPreferences.bind(this),
      resetWithViewPreferences: this.resetWithViewPreferences.bind(this),
      cleanSaveTab: this.cleanSaveTab.bind(this),
      cleanSaveField: this.cleanSaveField.bind(this),
      cleanSaveFilter: this.cleanSaveFilter.bind(this),
      tabs: this.state.tabs,
      clientTabs: this.state.clientTabs,
      swellTabs: this.state.swellTabs,
      fields: this.state.fields,
      clientFields: this.state.clientFields,
      swellFields: this.state.swellFields,
      filters: this.state.filters,
      clientFilters: this.state.clientFilters,
      swellFilters: this.state.swellFilters,
    };
  }

  getCurrentView() {
    const { content, model, view, id, type, childId } = this.props;
    const currentView =
      view ||
      find(content?.viewsByCollection[model], { id, type, standard: true });

    if (childId && currentView?.standard) {
      return { ...currentView, gid: `${currentView.gid}:${childId}` };
    }

    return currentView;
  }

  updateWithViewPreferences(values, clientDefaults) {
    const currentView = this.getCurrentView();

    this.updateTabPreferenceFields(values);

    if (currentView) {
      this.context.updateViewPreferences(currentView, values, clientDefaults);
    }
  }

  resetWithViewPreferences(values, clientDefaults) {
    const currentView = this.getCurrentView();

    this.updateTabPreferenceFields(values);

    if (currentView) {
      this.context.resetViewPreferences(currentView, values, clientDefaults);
    }
  }

  updateTabPreferenceFields(values) {
    // Saving fields while on a tab
    const { location, locationQuery } = this.props;

    const locationTab = find(this.state.tabs, {
      id: (locationQuery || location.query).tab,
    });

    const tabIndex = findIndex(this.state.tabs, { id: locationTab?.id });

    if (values.fields === undefined || !locationTab || tabIndex === -1) {
      return;
    }

    const exTabs = [...this.state.tabs.map(this.cleanSaveTab)];
    exTabs[tabIndex] = {
      ...exTabs[tabIndex],
      fields: values.fields?.map?.(this.cleanSaveField),
    };
    values.tabs = [...exTabs];

    delete values.fields;
  }

  cleanSaveTab(tab) {
    return {
      ...pick(tab, ['id', 'label', 'app_id', 'source_id', 'custom', 'hidden']),
    };
  }

  cleanSaveField(field) {
    return {
      ...pick(field, [
        'id',
        'label',
        'app_id',
        'source_id',
        'custom',
        'hidden',
      ]),
    };
  }

  cleanSaveFilter(filter) {
    return {
      ...pick(filter, [
        'id',
        'label',
        'app_id',
        'source_id',
        'custom',
        'hidden',
        'query',
      ]),
    };
  }

  getViewConfigsWithPreferences(type, defaults, props) {
    const { user, client, settings, record, values, child } = this.props;

    if (!defaults) {
      return {};
    }

    var defaults = getVisibleConfigsByLocation(defaults, this.props.location);

    const { currentView } = props;
    const $settings = currentView?.app_id && settings?.[currentView.app_id];
    const conditionalValues = {
      ...(!child ? values || record || {} : undefined),
      ...($settings ? { $settings } : undefined),
    };

    const userConfigs = viewConfigsWithPreferences(type, {
      ...props,
      defaults,
      conditionalValues,
      overrides: this.getViewConfigOverrides(
        type,
        currentView,
        user.preferences,
      ),
    });
    const clientConfigs = viewConfigsWithPreferences(type, {
      ...props,
      defaults,
      conditionalValues,
      user: undefined,
      overrides: this.getViewConfigOverrides(
        type,
        currentView,
        client.preferences,
      ),
    });
    const swellConfigs = viewConfigsWithPreferences(type, {
      ...props,
      defaults,
      conditionalValues,
      user: undefined,
      client: { ...props.client, preferences: undefined },
    });

    return { userConfigs, clientConfigs, swellConfigs };
  }

  getViewConfigOverrides(type, currentView, preferences) {
    const { content, location, locationQuery } = this.props;

    if (!currentView) {
      return;
    }

    let overrides;
    if (type === 'fields') {
      const tabId = (locationQuery || location.query).tab;
      const viewPrefs =
        preferences &&
        find(preferences.views, {
          id: currentView.gid,
        });
      const tabFields = find(viewPrefs?.tabs, { id: tabId })?.fields;

      if (tabFields?.length > 0) {
        overrides = { fields: tabFields };
      } else {
        // Find the tab by ID in the other content views
        // If one exists with fields, turn an empty array as overrides
        const otherContentViews = content.viewsByCollection[
          currentView.collection
        ]?.filter(
          (view) =>
            view.active !== false &&
            view.standard !== true &&
            view.type === currentView.type,
        );
        for (const otherView of otherContentViews) {
          for (const otherTab of otherView.tabs || []) {
            const otherTabId = otherView.app_id
              ? `${otherView.app_id}_${otherTab.id}`
              : otherTab.id;

            if (tabId === otherTabId && otherTab.fields?.length > 0) {
              overrides = { fields: [] };
              break;
            }
          }
        }
      }
    }

    return overrides;
  }

  getDefaultFieldsByTab(swellTabs) {
    const { fields, location, locationQuery } = this.props;

    const tabId = (locationQuery || location.query).tab;
    const locationTab = find(swellTabs, { id: tabId });

    if (!tabId || !locationTab?.fields) {
      return fields;
    }

    const fieldsArray = objectToArray(fields);

    const defaultFields = [
      ...fieldsArray
        .map((field) => {
          const tabField = find(locationTab?.fields, { id: field.id });
          if (locationTab?.fields?.length > 0) {
            if (!tabField) {
              return {
                ...field,
                hidden: true,
              };
            }
            return;
          }
          return field;
        })
        .filter((f) => f),
      ...(locationTab?.fields || []).map((field) => {
        const defaultField = find(fieldsArray, { id: field.id });
        if (defaultField) {
          return {
            ...defaultField,
            ...(field.hidden || defaultField.hidden
              ? { hidden: true }
              : undefined),
          };
        }
        return field;
      }),
    ];

    return defaultFields;
  }

  getStateWithPreferences() {
    const { user, client, content, tabs, fields, filters } = this.props;

    const currentView = this.getCurrentView();

    const configProps = {
      user,
      client,
      content,
      currentView,
    };

    const {
      userConfigs: userTabs,
      clientConfigs: clientTabs,
      swellConfigs: swellTabs,
    } = this.getViewConfigsWithPreferences('tabs', tabs, configProps);

    const {
      userConfigs: userFields,
      clientConfigs: clientFields,
      swellConfigs: swellFields,
    } = this.getViewConfigsWithPreferences(
      'fields',
      this.getDefaultFieldsByTab(swellTabs),
      configProps,
    );

    const {
      userConfigs: userFilters,
      clientConfigs: clientFilters,
      swellConfigs: swellFilters,
    } = this.getViewConfigsWithPreferences('filters', filters, configProps);

    return {
      ...(tabs
        ? {
            tabs: userTabs,
            clientTabs,
            swellTabs,
          }
        : {}),
      ...(fields
        ? {
            fields: userFields,
            clientFields,
            swellFields,
          }
        : {}),
      ...(filters
        ? {
            filters: userFilters,
            clientFilters,
            swellFilters,
          }
        : {}),
    };
  }

  render() {
    return React.Children.map(this.props.children, (child) =>
      child
        ? React.cloneElement(child, {
            ...this.props,
            ...this.state,
            children: child.props.children,
          })
        : child,
    );
  }
}

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithViewPreferences);

export function withViewPreferences(viewProps, WrappedComponent) {
  return function (props) {
    return (
      <ConnectedComponent {...props} {...viewProps}>
        <WrappedComponent {...props} />
      </ConnectedComponent>
    );
  };
}

export default ConnectedComponent;
