import React, { Fragment } from 'react';
import { formatCurrency } from 'utils';
import Items from './items';

export default function Return({ event, items }) {
  function renderReturnValue(label, value) {
    return !value ? null : (
      <Fragment>
        {label}: {value}&emsp;
      </Fragment>
    );
  }

  const currency = event.data.currency || 'USD';
  const notes = event.data.notes;

  return (
    <Fragment>
      <div className="activity-event-subtitle">
        {event.data.shipment_total &&
          renderReturnValue(
            'Credit',
            formatCurrency(event.data.shipment_total, currency),
          )}
        {event.data.shipment_total &&
          renderReturnValue(
            'Fee',
            formatCurrency(event.data.restock_fee, currency),
          )}
        {renderReturnValue('Reason', event.data.notes)}
      </div>
      {items && (
        <div className="activity-event-details">
          <Items items={items} showQuantity />
        </div>
      )}
      {notes && (
        <div className="activity-event-subtitle">
          <div className="activity-event-details-note">
            <div className="note body">{notes}</div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
