import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, map, find, filter, upperFirst, reduce } from 'lodash';
import { Form, Field } from 'components/form';
import View from 'components/view';
import UserPhoto from 'components/user/photo';
import Tooltip from 'components/tooltip';
import AccessDeniedPage from 'components/pages/error/403';
import { canUserManagePermissions, inflect } from 'utils';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';
import { DEFAULT_PERMISSIONS } from 'shared/roles';
import './settings.scss';

const VALUES_BY_PERMISSION = {
  restrict: 0,
  view: 34,
  manage: 67,
  full: 100,
};

const PERMISSIONS_BY_VALUE = reduce(
  VALUES_BY_PERMISSION,
  (acc, value, key) => {
    acc[value] = key;
    return acc;
  },
  {},
);

const PERMISSION_MARKS = reduce(
  VALUES_BY_PERMISSION,
  (acc, value) => {
    acc[value] = { label: null };
    return acc;
  },
  {},
);

export default class UserRole extends PureComponent {
  static contextTypes = {
    notifySuccess: PropTypes.func.isRequired,
    notifyWarning: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    isOwner: PropTypes.bool.isRequired,
    isAdvancedUserPermissions: PropTypes.bool.isRequired,
  };

  reassigmentRef = null;
  usersDetailsRefs = {};

  constructor(props) {
    super(props);
    const role = this.getRole(props);
    this.state = {
      edited: false,
      isNew: this.isNew(props),
      role,
      values: { ...role },
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    confirmRouteLeave(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.roles !== this.props.roles ||
      nextProps.params.id !== this.props.params.id
    ) {
      const role = this.getRole(nextProps);
      this.setState({
        isNew: this.isNew(nextProps),
        role,
        values: { ...role },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  getRole(props = this.props) {
    const {
      roles,
      params: { id },
    } = props;
    const isNew = this.isNew(props);

    return isNew
      ? { permissions: DEFAULT_PERMISSIONS }
      : find(roles.results, { id });
  }

  isNew(props = this.props) {
    return get(props, 'params.id') === 'new';
  }

  async onSubmit(values) {
    const { notifySuccess } = this.context;
    const { onCreateRole, onUpdateRole, router } = this.props;
    const isNew = this.isNew();
    values.permissions = reduce(
      values.permissions,
      (acc, value, key) => {
        acc[key] = PERMISSIONS_BY_VALUE[value];
        return acc;
      },
      {},
    );

    const result = isNew
      ? await onCreateRole(values)
      : await onUpdateRole(values);
    if (result) {
      if (isNew) {
        this.setState({ edited: false }, () =>
          router.replace('/settings/users/roles'),
        );
        return notifySuccess(`${values.name} role has been created`);
      }
      notifySuccess(`${values.name} role updated`);
    }
  }

  renderUserDetails = (user) => (
    <Fragment>
      <UserPhoto user={user} width={45} height={45} />
      <div
        className="note"
        ref={(ref) => (this.usersDetailsRefs[user.id] = ref)}
      >
        {user.name || user.email}
        {user.name && (
          <Fragment>
            <br />
            {user.email}
          </Fragment>
        )}
      </div>
    </Fragment>
  );

  renderUser = (user) => {
    const userDetailsRef = this.usersDetailsRefs[user.id];
    let shouldShowTooltip = false;
    if (userDetailsRef) {
      shouldShowTooltip =
        userDetailsRef.offsetWidth < userDetailsRef.scrollWidth;
    }

    return shouldShowTooltip ? (
      <Tooltip
        className="assigned-user"
        message={`${user.name || user.email}${
          user.name ? `\n${user.email}` : ''
        }`}
      >
        {this.renderUserDetails(user)}
      </Tooltip>
    ) : (
      <div className="assigned-user">{this.renderUserDetails(user)}</div>
    );
  };

  renderDeleteMessage = (role, availableRoles) => {
    const users = role.users;

    if (!users.count) {
      return null;
    }

    return (
      <Form
        ref={(ref) => (this.reassigmentRef = ref)}
        className="delete-role-message"
      >
        <p>
          You have <b>{inflect(users.count, 'users')}</b> currently assigned as{' '}
          <b>{role.name}</b>. All users assigned this role
          <br />
          should be assigned the existing one.
        </p>
        <div className="delete-role-message-users">
          {map(users.results, ({ user }, key) => (
            <div key={key} className="delete-role-message-user">
              {this.renderUser(user)}
              {!!availableRoles.length && (
                <Field
                  type="select"
                  name={user.id}
                  options={map(availableRoles, (role) => ({
                    value: role.id,
                    label: role.name,
                  }))}
                  defaultValue={availableRoles[0].id}
                />
              )}
            </div>
          ))}
        </div>
      </Form>
    );
  };

  onDelete = (event) => {
    event.preventDefault();
    const { roles, onDeleteRole, onReassignRoles, router } = this.props;
    const { role } = this.state;
    const availableRoles = filter(roles.results, ({ id }) => id !== role.id);
    const hasAffectedUsers = !!role.users.count;

    this.context.openModal('ConfirmDelete', {
      title: `${role.name} role`,
      message: this.renderDeleteMessage(role, availableRoles),
      action: hasAffectedUsers ? 'Reassign users and delete role' : 'Delete',
      onConfirm: async () => {
        const result = hasAffectedUsers
          ? await onReassignRoles(role.id, this.reassigmentRef.values)
          : await onDeleteRole(role.id);
        if (result) {
          this.setState({ edited: false }, () =>
            router.replace('/settings/users/roles'),
          );
          this.context.notifyWarning('Role deleted');
        }
      },
    });
  };

  onChange = (values, edited) => {
    this.setState({ values: { ...values }, edited });
  };

  permissionName(permission) {
    if (permission.includes('/')) {
      const [section, id] = permission.split('/');
      return (
        <Fragment>
          <span className="bold">{upperFirst(section)}</span> / {upperFirst(id)}
        </Fragment>
      );
    }
    return <span className="bold">{upperFirst(permission)}</span>;
  }

  render() {
    const { isNew, role, values, edited } = this.state;
    const canManageRole = canUserManagePermissions(this.context);
    const isAdmin = role.type === 'admin';
    const isReadOnly = role.type === 'read-only';
    const canDelete = canManageRole && !isNew && role.type === 'custom';
    const canChangePermissions = canManageRole && !isAdmin && !isReadOnly;
    const users = role.users;

    if (!canManageRole) {
      return <AccessDeniedPage withLogo={false} />;
    }

    return (
      <div className="settings settings-user-role">
        <Form ref="form" onChange={this.onChange} onSubmit={this.onSubmit}>
          <View
            detail={true}
            uri="/settings/users/roles"
            sectionTitle="User roles"
            headerTitle={
              get(values, 'name') ||
              (isNew ? 'Create new user role' : role.name)
            }
            headerSubtitle="Manage permissions for this role"
            headerActions={[
              canManageRole && {
                label: 'Save role',
                submit: true,
                type: edited ? 'primary' : 'secondary',
              },
            ]}
            extraActions={[
              {
                label: 'Delete this role',
                onClick: this.onDelete,
                hidden: !canDelete,
                className: 'danger',
                delete: true,
              },
            ]}
          >
            <Field type="hidden" name="id" value={role.id} />
            <Field type="hidden" name="type" value={role.type || 'custom'} />
            <Field
              type="text"
              label="Role name"
              name="name"
              required={true}
              defaultValue={role.name}
              placeholder="Role name"
            />
            <div className="role-permissions">
              <table className="collection-table">
                <thead>
                  <tr>
                    <th>Permissions</th>
                    <th>
                      <span>Restrict</span>
                      <span>View</span>
                      <span>Manage</span>
                      <span>Full</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {map(role.permissions, (value, key) => (
                    <tr key={key}>
                      <td>{this.permissionName(key)}</td>
                      <td>
                        <Field
                          id={key}
                          type="slider"
                          name={`permissions.${key}`}
                          marks={PERMISSION_MARKS}
                          step={null}
                          defaultValue={VALUES_BY_PERMISSION[value]}
                          disabled={!canChangePermissions}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {users && !!users.count && (
                <div className="box assigned-users-container">
                  <span className="bold assigned-users-header">
                    {users.count} assigned{' '}
                    {inflect(users.count, 'users', { showCount: false })}
                  </span>
                  <div className="assigned-users">
                    {map(users.results, ({ user }) => this.renderUser(user))}
                  </div>
                </div>
              )}
            </div>
          </View>
        </Form>
      </div>
    );
  }
}
