// @flow
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { formatCurrency } from 'utils';

import { getAccountAddressesQuery, getAccountCardsQuery } from 'utils/account';

import actions from 'actions';

import { tokenizeCard } from 'services/api';

import ViewPage from 'components/customer/view';
import LoadingView from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';

export const mapStateToProps = (state) => ({
  record: state.data.record,
  related: state.data.related,
  prev: state.data.record && (state.data.record.prev || undefined),
  next: state.data.record && (state.data.record.next || undefined),
  loading: state.data.loading,
  loadingRelated: state.data.loadingRelated,
  errors: state.data.recordErrors,
  lookup: state.lookup,
  categories: state.categories,
  content: state.content,
  settings: state.settings,
  suggestedAddresses: state.lookup.suggestedAddresses,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchRecord(id) {
    const seriesQuery = dispatch(
      actions.data.getCollectionSeriesQuery('accounts'),
    );

    return dispatch(
      actions.data.fetchRecord('accounts', id, {
        expand: [],
        include: {
          prev: {
            url: `/accounts/:first`,
            data: seriesQuery,
            params: {
              id: { $gt: 'id' },
            },
          },
          next: {
            url: `/accounts/:last`,
            data: seriesQuery,
            params: {
              id: { $lt: 'id' },
            },
          },
          order_totals: {
            url: `/orders/:group`,
            data: {
              count: { $sum: 1 },
              total: { $sum: 'grand_total' },
              avg: { $avg: 'grand_total' },
              currency: '$currency',
              where: {
                draft: { $ne: true },
              },
            },
            params: {
              where: {
                account_id: 'id',
              },
            },
          },
          invoice_totals: {
            url: `/invoices/:group`,
            data: {
              count: { $sum: 1 },
              total: { $sum: 'grand_total' },
              avg: { $avg: 'grand_total' },
              currency: '$currency',
              where: {
                draft: { $ne: true },
              },
            },
            params: {
              where: {
                account_id: 'id',
              },
            },
          },
          orders: {
            url: `/orders`,
            params: {
              account_id: 'id',
            },
            data: {
              limit: 10,
              fields: [
                'number',
                'date_created',
                'grand_total',
                'billing',
                'payment_total',
                'payment_balance',
                'refund_total',
                'delivered',
                'paid',
                'canceled',
                'item_quantity_delivered',
                'item_quantity_deliverable',
                'item_quantity',
                'item_quantity_returned',
                'currency',
              ],
            },
          },
          subscriptions: {
            url: `/subscriptions`,
            params: {
              account_id: 'id',
            },
            data: {
              limit: 10,
              fields: [
                'date_created',
                'date_period_end',
                'date_pause_end',
                'paused',
                'canceled',
                'recurring_total',
                'grand_total',
                'interval',
                'interval_count',
                'product_name',
                'status',
                'product_id',
                'variant_id',
                'currency',
              ],
              expand: ['product', 'variant'],
            },
          },
          draft_orders: {
            url: `/carts`,
            params: {
              account_id: 'id',
            },
            data: {
              draft: true,
              order_id: null,
              limit: 10,
              fields: [
                'number',
                'date_created',
                'date_updated',
                'discount_total',
                'grand_total',
                'currency',
              ],
            },
          },
          abandoned_carts: {
            url: `/carts`,
            params: {
              account_id: 'id',
            },
            data: {
              abandoned: true,
              order_id: null,
              limit: 10,
              fields: [
                'number',
                'date_created',
                'date_updated',
                'discount_total',
                'grand_total',
                'currency',
              ],
            },
          },
        },
      }),
    ).then(async (result) => {
      if (result?.id) {
        await dispatch(
          actions.data.fetchRelated(id, {
            account_cards: getAccountCardsQuery(id),
            account_addresses: getAccountAddressesQuery(id),
          }),
        );
      }

      return result;
    });
  },

  fetchAccountAddresses: (accountId) => {
    return dispatch(
      actions.data.fetchRelated(accountId, {
        account_addresses: getAccountAddressesQuery(accountId),
      }),
    );
  },

  fetchAccountCards: (accountId) => {
    return dispatch(
      actions.data.fetchRelated(accountId, {
        account_cards: getAccountCardsQuery(accountId),
        account_addresses: getAccountAddressesQuery(accountId),
      }),
    );
  },

  fetchAccountCredits: (accountId, query) => {
    return dispatch(
      actions.data.fetchRelated(accountId, {
        credits: {
          url: '/accounts:credits',
          data: {
            parent_id: accountId,
            ...(query || {}),
            include: {
              payment: {
                url: '/payments/{payment_id}',
                data: {
                  fields: 'order_id',
                  include: {
                    order: {
                      url: '/orders/{order_id}',
                      data: {
                        fields: 'number',
                      },
                    },
                  },
                },
              },
              refund: {
                url: '/payments:refunds/{refund_id}',
                data: {
                  fields: 'order_id',
                  include: {
                    order: {
                      url: '/orders/{order_id}',
                      data: {
                        fields: 'number',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      }),
    );
  },

  updateRecord: (id, data) => {
    return dispatch(actions.data.updateRecord('accounts', id, data));
  },

  deleteRecord: (id) => {
    return dispatch(actions.data.deleteRecord('accounts', id));
  },

  loadSettings() {
    return Promise.all([
      dispatch(actions.settings.load('payments')),
      dispatch(actions.settings.fetch('accounts')),
    ]);
  },
});

export class ViewCustomer extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    notifyDeleted: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      onEditValues: this.onEditValues.bind(this),
      onUpdateBilling: this.onUpdateBilling.bind(this),
      onAdjustCredit: this.onAdjustCredit.bind(this),
      onDelete: this.onDelete.bind(this),
      onLoadBilling: this.onLoadBilling.bind(this),
      onLoadCredits: this.onLoadCredits.bind(this),
      onLoadAddresses: this.onLoadAddresses.bind(this),
    };
  }

  componentDidMount() {
    const { params, fetchRecord, loadSettings } = this.props;

    Promise.all([fetchRecord(params.id), loadSettings()]).then(() => {
      this.setState({ loaded: true });
    });
  }

  async componentWillReceiveProps(nextProps) {
    const { params, fetchRecord } = this.props;

    if (params.id !== nextProps.params.id) {
      this.setState({ loaded: false });
      await fetchRecord(nextProps.params.id);
      this.setState({ loaded: true });
    }
  }

  async onEditValues(values) {
    const { params, record, updateRecord, fetchRecord } = this.props;

    this.setState({ loading: true });

    const accountCardId = get(values, 'billing.account_card_id');
    if (
      accountCardId &&
      accountCardId !== get(record, 'billing.account_card_id')
    ) {
      await updateRecord(params.id, {
        billing: { account_card_id: accountCardId },
      });
      values.billing.account_card_id = undefined;
    }

    const result = await updateRecord(params.id, values);
    if (result.errors) {
      this.context.notifyError(result.errors);
      this.setState({ loading: undefined });
      return false;
    }

    await fetchRecord(params.id);
    this.setState({ loading: undefined });

    return true;
  }

  async onUpdateBilling(values) {
    const { params, fetchAccountCards } = this.props;

    if (values.card && values.card.number) {
      this.setState({ loading: true });
      try {
        const cardResult = await tokenizeCard(this.context.client.public_key, {
          card: values.card,
          billing: values.billing,
          account_id: params.id,
        });
        if (cardResult.errors) {
          throw new Error(
            `${
              cardResult.errors.gateway
                ? cardResult.errors.gateway.message
                : JSON.stringify(cardResult.errors)
            }`,
          );
        }
        values = {
          ...values.billing,
          card: cardResult,
        };
      } catch (err) {
        this.context.notifyError(err.message);
        this.setState({ loading: undefined });
        return false;
      }
    } else {
      values = {
        ...values.billing,
        account_card_id: values.account_card_id,
      };
    }

    const success = await this.onEditValues({ billing: values });
    if (success) {
      if (values.card) {
        await fetchAccountCards(params.id);
        this.context.notifySuccess(
          `Credit card updated (${values.card.brand} ${values.card.last4})`,
        );
      } else {
        this.context.notifySuccess(`Customer billing information updated`);
      }
    }

    return success;
  }

  async onAdjustCredit(values) {
    const { currency } = values;
    const success = await this.onEditValues({ credits: [values] });
    if (success) {
      this.context.notifySuccess(
        `${
          values.amount > 0
            ? `Added ${formatCurrency(values.amount, currency)} to`
            : `Subtracted ${formatCurrency(-values.amount, currency)} from`
        } customer account balance`,
      );
    }
    return success;
  }

  async onDelete() {
    const { params, router, deleteRecord } = this.props;

    const result = await deleteRecord(params.id);

    if (result) {
      this.context.notifyDeleted('Customer');
      router.replace('/customers');
    }
  }

  async onLoadBilling() {
    const { params, fetchAccountCards } = this.props;

    await fetchAccountCards(params.id);
  }

  async onLoadCredits(query) {
    const { params, fetchAccountCredits } = this.props;

    await fetchAccountCredits(params.id, query);
  }

  async onLoadAddresses() {
    const { params, fetchAccountAddresses } = this.props;

    await fetchAccountAddresses(params.id);
  }

  render() {
    if (!this.state.loaded) {
      return <LoadingView />;
    }

    if (!this.props.record) {
      return <NotFoundPage />;
    }

    return <ViewPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomer);
