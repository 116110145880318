import React from 'react';
import PropTypes from 'prop-types';
import { get, map } from 'lodash';
import { Form, Field } from 'components/form';
import View from 'components/view';
import Modal from 'components/modal';
import UserPhoto from 'components/user/photo';
import UserPermissions from 'components/user/permissions';
import { canUserManagePermissions, formatDate, inflect } from 'utils';
import './settings.scss';

export default class UserSettings extends React.PureComponent {
  static contextTypes = {
    account: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    isOwner: PropTypes.bool.isRequired,
    isAdvancedUserPermissions: PropTypes.bool.isRequired,
  };

  state = {
    showingInviteModal: null,
    values: null,
    edited: null,
  };

  onClickInviteUser = (event) => {
    event.preventDefault();
    this.setState({
      showingInviteModal: true,
      values: {},
      edited: false,
    });
  };

  onCloseInviteUser = (event) => {
    event.preventDefault();
    this.setState({ showingInviteModal: false });
  };

  onChangeInviteForm = (values, edited) => {
    this.setState({
      values: {
        ...this.state.values,
        ...values,
      },
      edited,
    });
  };

  onSubmitInviteForm = (values) => {
    this.props.onInviteUser(null, values).then((success) => {
      if (success) {
        this.setState({ showingInviteModal: false });
        this.context.notifySuccess(`Invite sent to ${values.email}`);
      }
    });
  };

  onClickUser = (event) => {
    event.preventDefault();
    const userId = get(event, 'currentTarget.dataset.id');
    if (userId) {
      this.props.router.push(`/settings/users/${userId}`);
    }
  };

  renderInviteModal() {
    const { loading, roles } = this.props;
    const { isAdvancedUserPermissions } = this.context;
    const hasRoles =
      isAdvancedUserPermissions && !!get(roles, 'results.length');

    return (
      <Form
        onSubmit={this.onSubmitInviteForm}
        onChange={this.onChangeInviteForm}
        autoFocus={true}
      >
        <Modal
          title="Invite user"
          width={550}
          actions={[{ label: 'Save', type: 'submit' }]}
          loading={loading}
          onClose={this.onCloseInviteUser}
        >
          <fieldset>
            <Field
              type="text"
              name="email"
              label="Email address"
              rules="required, email"
              autoComplete="off"
            />
            {hasRoles && (
              <Field
                type="select"
                label="Role"
                name="role_id"
                options={map(roles.results, (role) => ({
                  value: role.id,
                  label: role.name,
                }))}
                defaultValue={roles.results[0].id}
              />
            )}
            <Field
              type="checkbox"
              name="notify"
              label="Notify when an order is placed"
              defaultChecked={true}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }

  render() {
    const { users } = this.props;
    const { account, isAdvancedUserPermissions } = this.context;
    const canManageRoles = canUserManagePermissions(this.context);

    return (
      <div className="settings settings-webhooks">
        <Form ref="form">
          <View
            detail={true}
            uri="/settings"
            sectionTitle="Settings"
            headerTitle="Users"
            headerSubtitle="Manage users with access to this store"
            headerActions={[
              canManageRoles && {
                label: 'Manage roles',
                type: 'sub',
                link: '/settings/users/roles',
              },
              { label: 'Invite user', onClick: this.onClickInviteUser },
            ]}
          >
            <table className="collection-table collection-table-hoverable outer">
              <thead>
                <tr>
                  <th className="image">User</th>
                  <th />
                  <th>Email</th>
                  <th>{isAdvancedUserPermissions ? 'Role' : 'Permissions'}</th>
                  <th>Last login</th>
                </tr>
              </thead>
              <tbody>
                {users.results.map((user) => (
                  <tr
                    key={user.id}
                    onClick={this.onClickUser}
                    data-id={user.id}
                  >
                    <td className="image">
                      <span className="collection-table-images">
                        <UserPhoto user={user} width={45} height={45} />
                      </span>
                    </td>
                    <td>
                      {user.name ? user.name : <span className="muted">&mdash;</span>}
                    </td>
                    <td>
                      {user.email}
                    </td>
                    <td>
                      <UserPermissions user={user} />
                    </td>
                    <td>
                      {user.date_last_login ? (
                        formatDate(user.date_last_login, 'age')
                      ) : (
                        <span className="muted">Email invite sent</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="collection-pages">
              You have {inflect(users.count, 'users')}
              {account.features.users > 0 && (
                <span> of {account.features.users} allowed</span>
              )}
            </div>
          </View>
        </Form>
        {this.state.showingInviteModal && this.renderInviteModal()}
      </div>
    );
  }
}
