import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'components/form';

export default class SSLToggle extends React.Component {
  static propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };

  render() {
    const { checked, disabled, onClick } = this.props;

    return (
      <div className="storefront-ssl-toggle" onClick={onClick}>
        <Field type="toggle" name="ssl" checked={checked} disabled={disabled} />
      </div>
    );
  }
}
