import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit, isEqual } from 'lodash';
import JSONEditor from 'jsoneditor';
import { isEmpty } from 'utils';
import 'jsoneditor/dist/jsoneditor.css';
import './editor.scss';

const modes = {
  tree: 'tree',
  view: 'view',
  form: 'form',
  code: 'code',
  text: 'text',
};

const CODE_EDITOR_MAX_LINES = 100;

const values = Object.values(modes);

modes.allValues = values;

/**
 * @type {object}
 * @property {object} [value]
 * @property {string} [mode='tree'] - Set the editor mode.
 * @property {string} [name=undefined] - Initial field name for the root node
 * @property {object} [schema] - Validate the JSON object against a JSON schema.
 * @property {object} [schemaRefs] - Schemas that are referenced using
 * the $ref property
 * @property {Function} [onChange] - Set a callback function
 * triggered when the contents of the JSONEditor change.
 * Called without parameters. Will only be triggered on changes made by the user.
 * Return new json.
 * @property {Function} [onError] - Set a callback function triggered when an error occurs.
 * Invoked with the error as first argument.
 * The callback is only invoked for errors triggered by a users action,
 * like switching from code mode to tree mode or clicking
 * the Format button whilst the editor doesn't contain valid JSON.
 * @property {Function} [onModeChange] - Set a callback function
 * triggered right after the mode is changed by the user.
 * @property {object} [ace] - Provide a version of the Ace editor.
 * Only applicable when mode is code
 * @property {object} [ajv] - Provide a instance of ajv,
 * the library used for JSON schema validation.
 * @property {string} [theme] - Set the Ace editor theme,
 * uses included 'ace/theme/jsoneditor' by default.
 * @property {boolean} [history=false] - Enables history,
 * adds a button Undo and Redo to the menu of the JSONEditor. Only applicable when
 * mode is 'tree' or 'form'
 * @property {boolean} [navigationBar=true] - Adds navigation bar to the menu
 * the navigation bar visualize the current position on the
 * tree structure as well as allows breadcrumbs navigation.
 * @property {boolean} [statusBar=true] - Adds status bar to the button of the editor
 * the status bar shows the cursor position and a count of the selected characters.
 * Only applicable when mode is 'code' or 'text'.
 * @property {boolean} [search=true] - Enables a search box in
 * the upper right corner of the JSONEditor.
 * @property {Array<string>} [allowedModes] - Create a box in the editor menu where
 * the user can switch between the specified modes.
 * @property {string} [tag='div'] - Html element to render
 * @property {object} [htmlElementProps] - html element custom props
 * @property {Function} [innerRef] - callback to get html element reference
 * @property {boolean} [readOnly=false] - activate read only mode
 * @property {string} [id] - uniq identifier
 */
export default class JsonEditor extends Component {
  static propTypes = {
    //  jsoneditor props
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.string,
    ]),
    mode: PropTypes.oneOf(values),
    name: PropTypes.string,
    schema: PropTypes.object,
    schemaRefs: PropTypes.object,

    onChange: PropTypes.func,
    onError: PropTypes.func,
    onModeChange: PropTypes.func,

    ace: PropTypes.object,
    ajv: PropTypes.object,
    theme: PropTypes.string,
    history: PropTypes.bool,
    navigationBar: PropTypes.bool,
    statusBar: PropTypes.bool,
    search: PropTypes.bool,
    allowedModes: PropTypes.arrayOf(PropTypes.oneOf(values)),
    readOnly: PropTypes.bool,
    id: PropTypes.string,
    autoFocus: PropTypes.bool,

    //  custom props
    tag: PropTypes.string,
    htmlElementProps: PropTypes.object,
    innerRef: PropTypes.func,
  };

  static defaultProps = {
    tag: 'div',
    mode: modes.code,
    history: false,
    search: false,
    navigationBar: false,
    statusBar: false,
    readOnly: false,
    autoFocus: false,
  };

  static modes = modes;

  constructor(props) {
    super(props);

    this.htmlElementRef = null;
    this.jsonEditor = null;

    this.handleChange = this.handleChange.bind(this);
    this.setRef = this.setRef.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.focus = this.focus.bind(this);
  }

  componentDidMount() {
    const { allowedModes, innerRef, htmlElementProps, tag, onChange, ...rest } =
      this.props;

    this.createEditor({
      ...omit(rest, ['id', 'autoFocus']),
      modes: allowedModes,
    });
  }

  componentWillReceiveProps({
    allowedModes,
    schema,
    name,
    theme,
    schemaRefs,
    innerRef,
    htmlElementProps,
    tag,
    onChange,
    value,
    ...rest
  }) {
    if (this.jsonEditor) {
      if (theme !== this.props.theme) {
        this.createEditor({
          ...omit(rest, ['id', 'autoFocus']),
          value,
          theme,
          modes: allowedModes,
        });
      } else {
        if (
          schema !== this.props.schema ||
          schemaRefs !== this.props.schemaRefs
        ) {
          this.jsonEditor.setSchema(schema, schemaRefs);
        }

        if (name !== this.jsonEditor.getName()) {
          this.jsonEditor.setName(name);
        }
      }

      try {
        if (
          value &&
          value !== this.props.value &&
          JSON.stringify(this.jsonEditor.get()) !== JSON.stringify(value)
        ) {
          try {
            if (value && typeof value === 'object') {
              this.jsonEditor.set(value);
            } else {
              this.jsonEditor.set(JSON.parse(value));
            }
            this.handlePosition(value);
          } catch (err) {
            console.warn(err);
            this.jsonEditor.set('');
          }
        }
      } catch (err) {
        console.warn(err);
      }
    }
  }

  shouldComponentUpdate({ htmlElementProps }) {
    return htmlElementProps !== this.props.htmlElementProps;
  }

  componentWillUnmount() {
    if (this.jsonEditor) {
      this.jsonEditor.destroy();
      this.jsonEditor = null;
    }
  }

  setRef(element) {
    this.htmlElementRef = element;
    if (this.props.innerRef) {
      this.props.innerRef(element);
    }
  }

  initCodeEditor(value) {
    const { aceEditor } = this.jsonEditor;
    aceEditor.setOptions({ maxLines: CODE_EDITOR_MAX_LINES });
    this.handlePosition(value);
  }

  handlePosition(value) {
    const { aceEditor } = this.jsonEditor;
    if (this.props.autoFocus) {
      this.jsonEditor.focus();
      if (isEmpty(value)) {
        aceEditor.navigateTo(0, 1);
        aceEditor.insert('\n');
      } else {
        aceEditor.navigateDown();
        aceEditor.navigateLineEnd();
      }
    }
  }

  onEditable(node) {
    if (!node.path) {
      // In modes code and text, node is empty: no path, field, or value
      // returning false makes the text area read-only
      return false;
    }
  }

  createEditor({ value = {}, readOnly, mode, ...rest }) {
    if (this.jsonEditor) {
      this.jsonEditor.destroy();
    }

    this.jsonEditor = new JSONEditor(this.htmlElementRef, {
      onChange: this.handleChange,
      mainMenuBar: false,
      mode,
      ...(readOnly ? { onEditable: this.onEditable } : {}),
      ...rest,
    });
    this.jsonEditor.set(value);

    isEqual(mode, modes.code) && this.initCodeEditor(value);
  }

  handleChange() {
    if (this.props.onChange) {
      let text;
      try {
        text = this.jsonEditor.getText();
        if (text === '') {
          this.props.onChange(null);
        }

        const currentJson = this.jsonEditor.get();
        if (JSON.stringify(this.props.value) !== JSON.stringify(currentJson)) {
          this.props.onChange(currentJson);
        }

        this.handleValidate(true);
      } catch (err) {
        if (text.trim().length > 0) {
          this.handleValidate(false, err);
        } else {
          this.handleValidate(true);
        }
      }
    }
  }

  handleValidate(valid, err) {
    if (valid) {
      this.err = null;
      if (this.props.onValidate) {
        this.props.onValidate(true);
      }
    } else {
      this.err = err;
      if (this.props.onValidate) {
        this.props.onValidate(false, err.message);
      }
    }
  }

  collapseAll() {
    if (this.jsonEditor) {
      this.jsonEditor.collapseAll();
    }
  }

  expandAll() {
    if (this.jsonEditor) {
      this.jsonEditor.expandAll();
    }
  }

  focus() {
    if (this.jsonEditor) {
      this.jsonEditor.focus();
    }
  }

  render() {
    const { htmlElementProps, tag, readOnly } = this.props;

    return (
      <div className={`json-editor-container ${readOnly ? 'readonly' : ''}`}>
        {React.createElement(tag, {
          ...htmlElementProps,
          ref: this.setRef,
        })}
      </div>
    );
  }
}
