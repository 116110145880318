import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { map, reverse, reduce, first, get, find } from 'lodash';
import * as d3 from 'd3';

class ToolTipViewer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.el = document.getElementById('chart-tooltip-' + this.props.chartId);
  }

  componentWillUnmount() {
    this.el.setAttribute('style', 'visibility: hidden');
  }

  render() {
    const { style } = this.props;
    style && this.el.setAttribute('style', style);
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

class LineChartTooltip extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  state = {
    x: 0,
    y: 0,
    bestItems: null,
    visible: false,
  };

  componentDidMount() {
    const node = d3.select(this.container);
    const self = this;
    node.on('mousemove', function(event) {
      self.handleMouseMove(event);
    });
  }

  componentWillUnmount() {
    const node = d3.select(this.container);
    node.on('mousemove', null);
  }

  handleMouseOver = () => {
    this.setState({ visible: true });
  };

  handleMouseOut = () => {
    this.setState({ visible: false, bestItems: null });
  };

  handleMouseMove(event) {
    const { xScales, data, margin, xValue, offsetWidth } = this.props;
    const [x0, y0] = d3.pointer(event);
    const defaultId = first(data).id;

    const xObj = reduce(
      xScales,
      (memo, xScale, key) => ({
        ...memo,
        [key]: xScale.invert(x0 - margin.left - offsetWidth),
      }),
      {},
    );

    let bestDist = 99999999999;
    let bestItems = {};

    data.forEach((item) => {
      let tempItem = null;

      item.points.forEach((point) => {
        const dist = Math.abs(point[xValue] - xObj[item.id]);

        if (dist <= bestDist) {
          bestDist = dist;
          tempItem = point;
        }
      });

      bestItems = {
        ...bestItems,
        [item.id]: tempItem,
      };

      bestDist = 99999999999;
    });

    this.setState({
      bestItems,
      x: xScales[defaultId](get(bestItems[defaultId], xValue)),
      y0,
      x0,
    });
  }

  handleRef = (ref) => {
    this.container = ref;
  };

  handleTooltipRef = (ref) => {
    this.tooltipContainer = ref;
  };

  renderTooltip() {
    const {
      margin,
      height,
      yScale,
      pointColors,
      yValue,
      xFormat,
      yFormat,
      xValue,
      width,
      chartId,
      offsetWidth,
    } = this.props;

    const { currency } = this.context.client;

    const { bestItems, x, y0, x0 } = this.state;
    if (!bestItems) {
      return null;
    }

    const tooltipWidth = get(this.tooltipContainer, 'el.offsetWidth', 0);
    const tooltipHeight = get(this.tooltipContainer, 'el.offsetHeight', 0);
    const top = Math.min(y0 + 15, height - tooltipHeight);
    const left = Math.min(
      x0 + 15,
      width - margin.right - tooltipWidth - offsetWidth,
    );
    const style = `top: ${top}px; left: ${left}px; visibility: ${
      this.state.visible ? 'visible' : 'hidden'
    }`;

    return [
      ...reverse(
        map(
          pointColors,
          (color, id) =>
            bestItems[id] && (
              <circle
                cx={x + margin.left + offsetWidth}
                cy={yScale(bestItems[id][yValue]) + margin.top}
                r="6"
                key={id}
                fill={color}
                stroke="#fff"
                pointerEvents="none"
              />
            ),
        ),
      ),
      <ToolTipViewer
        key="tt"
        style={style}
        ref={this.handleTooltipRef}
        chartId={chartId}
      >
        {map(bestItems, (item, id) => (
          <div className="chart-tooltip-item" key={id}>
            <span className="nowrap">
              {xFormat ? xFormat(item[xValue]) : item[xValue]}
            </span>
            <span className="nowrap">
              <div
                style={{ backgroundColor: pointColors[id] }}
                className="chart-tooltip-box-color"
              />
              {yFormat
                ? yFormat(item[yValue], { currency, index: id })
                : item[yValue]}
            </span>
          </div>
        ))}
      </ToolTipViewer>,
    ];
  }

  render() {
    const { width, height, margin, offsetWidth, offsetHeight } = this.props;
    const chartWidth = width - margin.left - margin.right - offsetWidth;
    const chartHeight = height - margin.top - margin.bottom - offsetHeight;

    return (
      <g>
        {this.renderTooltip()}
        <rect
          ref={this.handleRef}
          x={margin.left + offsetWidth}
          y={margin.top}
          width={chartWidth > 0 ? chartWidth : 0}
          height={chartHeight > 0 ? chartHeight : 0}
          opacity="0"
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        />
      </g>
    );
  }
}

class BarChartTooltip extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  state = {
    activePoint: null,
    tooltipHeight: 0,
    tooltipWidth: 0,
  };

  handleMouseOver = (point) => {
    this.setState({
      activePoint: point,
    });
  };

  handleMouseOut = (param) => {
    this.setState({
      activePoint: null,
    });
  };

  handleTooltipRef = (ref) => {
    this.tooltipContainer = ref;
  };

  componentDidUpdate() {
    const tooltipWidth = get(this.tooltipContainer, 'el.offsetWidth', 0);
    const tooltipHeight = get(this.tooltipContainer, 'el.offsetHeight', 0);

    if (
      tooltipWidth !== this.state.tooltipWidth ||
      tooltipHeight !== this.state.tooltipHeight
    ) {
      this.setState({
        tooltipWidth,
        tooltipHeight,
      });
    }
  }

  renderTooltip() {
    const { chartId } = this.props;
    const { activePoint, tooltipWidth, tooltipHeight } = this.state;
    const { currency } = this.context.client;

    if (!activePoint) {
      return <ToolTipViewer chartId={chartId} />;
    }

    const offsetY = 5;
    const {
      xValue,
      yValue,
      xScales,
      yScale,
      margin,
      /*height,*/ xFormat,
      yFormat,
      dataId,
      width,
    } = this.props;
    const barWidth = xScales[dataId].bandwidth();
    const y0 = yScale(activePoint[yValue]);
    const x0 = xScales[dataId](activePoint[xValue]);
    const tempTooltipTop = y0 + margin.top - offsetY - tooltipHeight;
    const tempTooltipLeft = x0 + (barWidth - tooltipWidth) / 2 + margin.left;
    const tooltipTop = Math.max(tempTooltipTop, margin.top);
    const tooltipLeft = Math.max(
      margin.left,
      Math.min(tempTooltipLeft, width - margin.right - tooltipWidth),
    );
    const style = `top: ${tooltipTop}px; left: ${tooltipLeft}px; transition: 0.2s ease-in-out;`;

    return (
      <ToolTipViewer
        style={style}
        ref={this.handleTooltipRef}
        chartId={chartId}
      >
        <div className="chart-tooltip-item">
          <span>
            {xFormat ? xFormat(activePoint[xValue]) : activePoint[xValue]}
          </span>
          <span>
            {yFormat
              ? yFormat(activePoint[yValue], { currency, index: dataId })
              : activePoint[yValue]}
          </span>
        </div>
      </ToolTipViewer>
    );
  }

  render() {
    const { data, xValue, yValue, xScales, yScale, margin, dataId, height } =
      this.props;
    const translate = `translate(${margin.left}, ${margin.top})`;
    const calculateHeight = (point) =>
      height - yScale(point[yValue] || 2) - margin.top - margin.bottom;
    const bars = find(data, { id: dataId }).points.map((point, idx) => (
      <rect
        key={idx}
        x={xScales[dataId](point[xValue])}
        y={yScale(point[yValue] || 2)}
        width={xScales[dataId].bandwidth()}
        opacity="0"
        onMouseOver={this.handleMouseOver.bind(this, point)}
        height={calculateHeight(point) > 0 ? calculateHeight(point) : 0}
      />
    ));

    return [
      <g key="1" transform={translate} onMouseOut={this.handleMouseOut}>
        {bars}
      </g>,
      this.renderTooltip(),
    ];
  }
}

export default class Tooltip extends React.PureComponent {
  render() {
    const { ordinal, ...props } = this.props;

    return ordinal ? (
      <BarChartTooltip {...props} />
    ) : (
      <LineChartTooltip {...props} />
    );
  }
}
