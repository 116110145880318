import React from 'react';
import { connect } from 'react-redux';
import Collection from './Collection';
import BulkExport from 'components/bulk/export';
import CartStatus from 'components/pages/cart/status';
import CollectionLocale from 'components/collection/locale';
import { accountName } from 'utils/account';
import actions from 'actions';
import { currencyValue } from 'utils';

export const query = {
  expand: ['account'],
  draft: {
    $ne: true,
  },
  item_quantity: {
    $gt: 0,
  },
  $or: [
    {
      abandoned: true,
    },
    {
      recovered: true,
    },
    {
      active: true,
    },
    {
      order_id: {
        $ne: null,
      },
    },
  ],
};

export const tabs = {
  default: {
    label: 'All carts',
  },
  active: {
    label: 'Active',
    query: {
      active: true,
    },
  },
  converted: {
    label: 'Converted',
    query: {
      status: 'converted',
    },
  },
  abandoned: {
    label: 'Abandoned',
    query: {
      status: 'abandoned',
      active: { $ne: true },
    },
  },
  recovered: {
    label: 'Recovered',
    query: {
      recovered: true,
    },
  },
};

export const filters = {
  grand_total: {
    label: 'Total',
    operators: ['gt', 'lt', 'eq'],
    type: 'currency',
  },
  date_created: {
    label: 'Date',
    operators: ['after', 'before'],
    type: 'date',
  },
  status: {
    label: 'Status',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'abandoned', label: 'Abandoned' },
      { value: 'recovered', label: 'Recovered' },
    ],
    func: (query) => {
      if (query === 'active') {
        return { active: true };
      }
      if (query === 'abandoned') {
        return {
          abandoned: true,
          active: { $ne: true },
          status: 'abandoned',
        };
      }
      if (query === 'recovered') {
        return { recovered: true };
      }
    },
  },
  customer: {
    label: 'Customer',
    type: 'LookupCustomer',
    func: (account_id) => {
      return { account_id };
    },
  },
};

export const fields = {
  number: {
    label: 'Cart',
    type: 'link',
    url: '/carts/{id}',
    default: true,
    func: (cart) => (
      <CollectionLocale locale={cart.display_locale}>
        {cart.number}
      </CollectionLocale>
    ),
  },
  date_updated: {
    label: 'Last Active',
    type: 'date',
    func: (cart) => cart.date_updated || cart.date_created,
  },
  account: {
    label: 'Customer',
    func: (cart) =>
      cart.account && accountName(cart.account, cart.account.email),
  },
  status: {
    label: 'Status',
    func: (cart) => {
      return <CartStatus cart={cart} />;
    },
  },
  grand_total: {
    label: 'Total',
    type: 'currency',
    rounded: true,
  },
};

const exportCSVFields = [
  { label: 'Cart', export: (cart) => cart.number },
  { label: 'Email', export: (cart) => cart.account.email },
  { label: 'Phone', export: (cart) => cart.account.phone },
  { label: 'Currency', export: (cart) => cart.currency },
  {
    label: 'Subtotal',
    export: (cart) => currencyValue(cart.sub_total, cart.currency),
  },
  {
    label: 'Shipping',
    export: (cart) => currencyValue(cart.shipment_total, cart.currency),
  },
  {
    label: 'Taxes',
    export: (cart) => currencyValue(cart.tax_total, cart.currency),
  },
  { label: 'Coupon code', export: (cart) => cart.coupon_code },
  {
    label: 'Promotions',
    export: (cart) =>
      cart.promotions.results.map((promo) => promo.name).join('\n'),
  },
  {
    label: 'Discount total',
    export: (cart) => currencyValue(cart.discount_total, cart.currency),
  },
  {
    label: 'Shipping method',
    export: (cart) => cart.shipping.service_name || cart.shipping.service,
  },
  { label: 'Date created', export: (cart) => cart.date_created },
  {
    label: 'Item quantity',
    export: (cart) =>
      cart.items &&
      cart.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map(
                (bundleItem) => bundleItem.quantity * item.quantity,
              )
            : [item.quantity],
        );
      }, []),
  },
  {
    label: 'Item name',
    export: (cart) =>
      cart.items &&
      cart.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map(
                (bundleItem) =>
                  `(${item.product.name}) ${bundleItem.product.name}${
                    bundleItem.variant ? ` - ${bundleItem.variant.name}` : ''
                  }`,
              )
            : [
                `${item.product.name}${
                  item.variant ? ` - ${item.variant.name}` : ''
                }`,
              ],
        );
      }, []),
  },
  {
    label: 'Item price',
    export: (cart) =>
      cart.items &&
      cart.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map((bundleItem, index) =>
                index === 0 ? item.price : null,
              )
            : [currencyValue(item.price, cart.currency)],
        );
      }, []),
  },
  {
    label: 'Item tax total',
    export: (cart) =>
      cart.items &&
      cart.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map((bundleItem, index) =>
                index === 0 ? item.tax_total : null,
              )
            : [currencyValue(item.tax_total, cart.currency)],
        );
      }, []),
  },
  {
    label: 'Item discount total',
    export: (cart) =>
      cart.items &&
      cart.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map((bundleItem, index) =>
                index === 0 ? item.discount_total : null,
              )
            : [currencyValue(item.discount_total, cart.currency)],
        );
      }, []),
  },
  {
    label: 'Item SKU',
    export: (cart) =>
      cart.items &&
      cart.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map(
                (bundleItem) =>
                  (bundleItem.variant && bundleItem.variant.sku) ||
                  bundleItem.product.sku ||
                  item.product.sku,
              )
            : [(item.variant && item.variant.sku) || item.product.sku],
        );
      }, []),
  },
  {
    label: 'Item fulfillment method',
    export: (cart) =>
      cart.items &&
      cart.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map((bundleItem) => bundleItem.delivery)
            : [item.delivery],
        );
      }, []),
  },
  {
    label: 'Item quantity fulfilled',
    export: (cart) =>
      cart.items &&
      cart.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map(
                (bundleItem) => bundleItem.quantity_delivered || '0',
              )
            : [item.quantity_delivered || '0'],
        );
      }, []),
  },
  {
    label: 'Item quantity canceled',
    export: (cart) =>
      cart.items &&
      cart.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map(
                (bundleItem) => bundleItem.quantity_canceled || '0',
              )
            : [item.quantity_canceled || '0'],
        );
      }, []),
  },
  {
    label: 'Item quantity returned',
    export: (cart) =>
      cart.items &&
      cart.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map(
                (bundleItem) => bundleItem.quantity_returned || '0',
              )
            : [item.quantity_returned || '0'],
        );
      }, []),
  },
  { label: 'Billing name', export: (cart) => cart.billing.name },
  { label: 'Billing address line 1', export: (cart) => cart.billing.address1 },
  { label: 'Billing address line 2', export: (cart) => cart.billing.address2 },
  { label: 'Billing city', export: (cart) => cart.billing.city },
  { label: 'Billing state', export: (cart) => cart.billing.state },
  { label: 'Billing zip', export: (cart) => cart.billing.zip },
  { label: 'Billing country', export: (cart) => cart.billing.country },
  { label: 'Billing phone', export: (cart) => cart.billing.phone },
  { label: 'Shipping name', export: (cart) => cart.shipping.name },
  {
    label: 'Shipping address line 1',
    export: (cart) => cart.shipping.address1,
  },
  {
    label: 'Shipping address line 2',
    export: (cart) => cart.shipping.address2,
  },
  { label: 'Shipping city', export: (cart) => cart.shipping.city },
  { label: 'Shipping state', export: (cart) => cart.shipping.state },
  { label: 'Shipping zip', export: (cart) => cart.shipping.zip },
  { label: 'Shipping country', export: (cart) => cart.shipping.country },
  { label: 'Shipping phone', export: (cart) => cart.shipping.phone },
  { label: 'Comments', export: (cart) => cart.comments },
  { label: 'Notes', export: (cart) => cart.notes },
  { label: 'Canceled', export: (cart) => (cart.canceled ? 'yes' : '') },
  { label: 'Payment method', export: (cart) => cart.billing.method },
  { label: 'ID', export: (cart) => cart.id },
];

const mapStateToProps = (state) => ({
  data: state.data,
  bulk: state.data.bulk,
});

const mapDispatchToProps = (dispatch) => ({
  bulkExport: (model: string, params: Object) => {
    return dispatch(actions.data.bulkExport(model, params));
  },

  bulkCancel: () => {
    return dispatch(actions.data.bulkCancel());
  },
});

export class CartsContainer extends React.Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      showingExport: false,
      onClickExport: this.onClickExport.bind(this),
      onClickExportCancel: this.onClickExportCancel.bind(this),
      onClickExportReset: this.onClickExportReset.bind(this),
      onSubmitExport: this.onSubmitExport.bind(this),
    };
  }

  onClickExport(event) {
    event.preventDefault();
    this.setState({ showingExport: !this.state.showingExport });
  }

  onClickExportCancel(event) {
    event.preventDefault();
    if (this.props.bulk.running) {
      this.props.bulkCancel();
    }
    this.setState({ showingExport: false });
  }

  onClickExportReset(event) {
    event.preventDefault();
    this.props.bulkCancel();
  }

  onSubmitExport(values) {
    this.props.bulkExport(`carts`, {
      ...values,
      filename: `carts`,
      csvFields: exportCSVFields,
      query: {
        ...query,
        expand: [
          'account',
          'promotions',
          'items.product',
          'items.variant',
          'items.bundle_items.product',
          'items.bundle_items.variant',
        ],
      },
    });
  }

  headerActions = [
    {
      label: 'Export',
      fa: 'arrow-to-bottom',
      type: 'sub',
      onClick: this.onClickExport.bind(this),
    },
  ];

  render() {
    return (
      <div>
        <Collection
          {...this.props}
          title="Carts"
          uri="/carts"
          model="carts"
          emptyTitle="No carts created yet"
          emptyDescription="Once your storefront is up and running, you'll be able to review shopping carts created by customers in this section."
          emptyAction={false}
          tabs={tabs}
          filters={filters}
          fields={fields}
          query={query}
          headerActions={this.headerActions}
        />
        {this.state.showingExport && (
          <BulkExport label="Carts" {...this.props} {...this.state} />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartsContainer);
