const DEFAULT_PERMISSIONS = {
  products: 'view',
  orders: 'view',
  subscriptions: 'view',
  customers: 'view',
  coupons: 'view',
  content: 'view',
  promotions: 'view',
  integrations: 'view',
  storefront: 'view',
  developer: 'view',
  'settings/general': 'view',
  'settings/account': 'view',
  'settings/users': 'view',
  'settings/products': 'view',
  'settings/customers': 'view',
  'settings/orders': 'view',
  'settings/payments': 'view',
  'settings/subscriptions': 'view',
  'settings/shipping': 'view',
  'settings/taxes': 'view',
  'settings/checkout': 'view',
  'settings/giftcards': 'view',
  'settings/notifications': 'view',
};

const DEFAULT_ROLES = {
  admin: {
    name: 'Admin',
    permissions: {
      products: 'full',
      orders: 'full',
      subscriptions: 'full',
      customers: 'full',
      coupons: 'full',
      content: 'full',
      promotions: 'full',
      integrations: 'full',
      storefront: 'full',
      developer: 'full',
      'settings/general': 'full',
      'settings/account': 'full',
      'settings/users': 'full',
      'settings/products': 'full',
      'settings/customers': 'full',
      'settings/orders': 'full',
      'settings/payments': 'full',
      'settings/subscriptions': 'full',
      'settings/shipping': 'full',
      'settings/taxes': 'full',
      'settings/checkout': 'full',
      'settings/giftcards': 'full',
      'settings/notifications': 'full',
    },
  },
  operations: {
    name: 'Operations',
    permissions: {
      ...DEFAULT_PERMISSIONS,
      orders: 'full',
      subscriptions: 'full',
      storefront: 'restrict',
      developer: 'restrict',
    },
  },
  developer: {
    name: 'Developer',
    permissions: {
      ...DEFAULT_PERMISSIONS,
      integrations: 'full',
      storefront: 'full',
      developer: 'full',
    },
  },
  'read-only': {
    name: 'Read-only',
    permissions: {
      ...DEFAULT_PERMISSIONS,
      integrations: 'restrict',
      storefront: 'restrict',
      developer: 'restrict',
    },
  },
};

module.exports = {
  DEFAULT_ROLES,
  DEFAULT_PERMISSIONS,
};
