import React, { Fragment } from 'react';
import { get } from 'lodash';
import Link from 'components/link';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import { Label } from 'components/form';
import { productName, productThumbUrl } from 'utils/product';
import {
  subscriptionPlanDesc,
  subscriptionOptionValueNameDeprecated,
} from 'utils/subscription';

function getVariantNameFromBundleOptions(bundleItem, item) {
  const bundleOptions = item.bundle_options;
  const itemBundleOptions =
    bundleOptions && bundleOptions[bundleItem.product_id];

  if (!itemBundleOptions) {
    return '';
  }

  return itemBundleOptions
    .reduce((acc, itemBundleOption) => {
      const option = bundleItem.product.options.find(
        (opt) => opt.id === itemBundleOption.id,
      );
      const value = option.values.find(
        (value) => value.id === itemBundleOption.value,
      );

      if (value) {
        acc.push(value.name);
      }

      return acc;
    }, [])
    .join(', ');
}

function getVariantNameFromVariantOptions(bundleItem) {
  if (!Array.isArray(bundleItem.options) || bundleItem.options.length === 0) {
    return '';
  }

  return bundleItem.options
    .reduce((acc, option) => {
      if (option.value) {
        acc.push(option.value);
      }

      return acc;
    }, [])
    .join(', ');
}

export default class OrderItemDetail extends React.PureComponent {
  renderCustomOptions(item) {
    const customOptions = item.options
      ? item.options.filter((option) => !option.variant)
      : [];
    return (
      customOptions.length > 0 && (
        <div className="order-view-item-options">
          <h4>Options</h4>
          <ul className="text">
            {customOptions.map((option, index) => (
              <li key={index}>
                <b>{option.name}</b> {option.value}
              </li>
            ))}
          </ul>
        </div>
      )
    );
  }

  render() {
    const {
      item,
      index,
      style,
      showQuantity = false,
      className,
      onClickEditSingleItem,
      onClickEditPlan,
    } = this.props;

    const variantOptions = item.options
      ? item.options.filter((option) => option.variant)
      : [];
    let variantName = item.variant && item.variant.name;

    const isVariant = variantOptions.length > 0 || item.variant_id;
    const realVariantName = variantOptions
      .map((option) => option.value)
      .join(', ');
    if (!variantName && variantOptions.length > 0) {
      variantName = realVariantName;
    }
    let itemSku = item.product ? item.product.sku : '';
    if (item.variant) {
      itemSku = item.variant.sku || itemSku;
    }

    const isDeprecatedSubscriptionDelivery = item.delivery === 'subscription';
    const subscriptionPlanName =
      (get(item.purchase_option, 'type') === 'subscription' &&
        subscriptionPlanDesc(item.purchase_option)) ||
      (isDeprecatedSubscriptionDelivery &&
        subscriptionOptionValueNameDeprecated(item));
    const isRecurring = item.recurring || subscriptionPlanName;

    return (
      <Fragment>
        {isRecurring && (
          <Fragment>
            <span className="order-item-recurring-icon">
              <Tooltip
                message={subscriptionPlanName || 'This is a recurring item'}
              >
                <Icon fa="sync-alt" faType="regular" />
              </Tooltip>
            </span>
          </Fragment>
        )}
        <span
          style={style}
          className={`
          ${
            !item.variant && !item.bundle_items && !item.options && !itemSku
              ? 'collection-field-padded'
              : ''
          }
          ${className || ''}
          order-item-name
        `}
        >
          {showQuantity && (
            <span className="muted">{item.quantity} &times; </span>
          )}
          {item.product_id ? (
            <Link to={`/products/${item.product_id}`} className="sneaky-link">
              {productName(item.product, null, item.product_name)}
            </Link>
          ) : (
            <span>{item.description || '(Unknown item)'}</span>
          )}
          {variantName && (isVariant || itemSku) && (
            <div className="order-view-item-details">
              {isVariant && (
                <Label
                  label={variantName}
                  help={
                    realVariantName && variantName !== realVariantName
                      ? realVariantName
                      : undefined
                  }
                  className="item-variant-name"
                />
              )}
              {itemSku && <Label label={`(${itemSku})`} className="item-sku" />}
            </div>
          )}

          {this.renderCustomOptions(item)}

          {item.bundle_items && item.bundle_items.length > 0 && (
            <div className="order-view-item-bundle">
              <ul>
                {item.bundle_items.map((bundleItem) => {
                  let bundleItemVariant = null;

                  if (bundleItem.variant) {
                    bundleItemVariant = bundleItem.variant;
                  } else if (item.bundle_options) {
                    const fallbackVariantName = getVariantNameFromBundleOptions(
                      bundleItem,
                      item,
                    );

                    if (fallbackVariantName) {
                      bundleItemVariant = { name: fallbackVariantName };
                    }
                  } else if (bundleItem.options) {
                    const fallbackVariantName =
                      getVariantNameFromVariantOptions(bundleItem);

                    if (fallbackVariantName) {
                      bundleItemVariant = { name: fallbackVariantName };
                    }
                  }

                  return (
                    <li key={bundleItem.id}>
                      <Link to={`/products/${bundleItem.product_id}`}>
                        <span className="image">
                          <img
                            src={productThumbUrl(
                              bundleItem.product,
                              bundleItemVariant,
                              50,
                            )}
                            alt={productName(
                              bundleItem.product,
                              bundleItemVariant,
                              bundleItem.product_name,
                            )}
                          />
                        </span>
                        {productName(
                          bundleItem.product,
                          bundleItemVariant,
                          bundleItem.product_name,
                        )}
                      </Link>
                      <span className="muted">
                        &nbsp;&times; {bundleItem.quantity}
                      </span>
                      {this.renderCustomOptions(bundleItem)}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </span>
        {onClickEditSingleItem && (
          <div className="order-item-edit-icon">
            <button
              data-id={item.id}
              data-index={index}
              onClick={onClickEditPlan || onClickEditSingleItem}
              type="button"
            >
              <Icon fa="ellipsis-h" />
            </button>
          </div>
        )}
      </Fragment>
    );
  }
}
