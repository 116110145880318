const moment = require('moment');
const { get, find } = require('lodash');
const plans = require('./plans');

const { ANNUAL_DISCOUNT } = plans;

function canGoLive(plan) {
  // Sandbox plans cannot go live
  return !isSandbox(plan);
}

function isSandbox(plan) {
  const planId = typeof plan === 'string' ? plan : get(plan, 'metadata.id');
  return planId === 'partner-sandbox' || isOldSandbox(plan);
}

function isOldSandbox(plan) {
  const planId = typeof plan === 'string' ? plan : get(plan, 'metadata.id');
  if (
    planId === 'sandbox' ||
    planId === 'sandbox-plus' ||
    planId === 'sandbox2'
  ) {
    return true;
  }
  return false;
}

function billingTrialDaysLeft(plan = null) {
  if (plan && plan.trial) {
    const remaining = moment(plan.date_trial_end).diff(
      Date.now(),
      'days',
      true,
    );
    if (remaining > 1) {
      return Math.ceil(remaining);
    }
    return 0;
  }
  return null;
}

function billingTrialDaysLeftMessage(plan = null) {
  if (plan && plan.trial) {
    const remaining = moment(plan.date_trial_end).diff(
      Date.now(),
      'days',
      true,
    );
    if (remaining > 1) {
      return `${Math.ceil(remaining)} days left`;
    } else if (remaining > 0.5) {
      return '1 day left';
    } else if (remaining > 0) {
      return 'a few hours left';
    }
  }
  return null;
}

function billingDiscountByInterval(
  product,
  interval = 'monthly',
  intervalCount = 1,
) {
  const productMetaId = product.metadata?.id || product.id;
  if (productMetaId === 'enterprise') {
    return 0;
  }
  if (interval.indexOf('yearly') === 0) {
    return Math.round(
      ANNUAL_DISCOUNT * intervalCount - (intervalCount - 1) * 5,
    );
  }
  return 0;
}

function billingPriceByInterval(
  product,
  interval = 'monthly',
  intervalCount = 1,
  account = null,
) {
  if (!product) {
    return 0;
  }
  const price = billingPriceWithPromo(product, account);
  if (interval.indexOf('yearly') === 0) {
    const intervalMonths = intervalCount * 12;
    const discountPercent = billingDiscountByInterval(
      product,
      interval,
      intervalCount,
    );
    return Math.round(
      (price - price * (discountPercent / 100)) * intervalMonths,
    );
  }
  return Math.round(price);
}

function billingPriceByMonth(
  product,
  interval = 'monthly',
  intervalCount = 1,
  account = null,
) {
  const price = billingPriceWithPromo(product, account);
  if (interval.indexOf('yearly') === 0) {
    const discountPercent = billingDiscountByInterval(
      product,
      interval,
      intervalCount,
    );
    return Math.round(price - price * (discountPercent / 100));
  }
  return Math.round(price);
}

function billingPriceByYear(
  product,
  interval = 'monthly',
  intervalCount = 1,
  account = null,
) {
  const price = billingPriceWithPromo(product, account);
  if (interval.indexOf('monthly') === 0) {
    return Math.round(price * 12);
  }
  return Math.round(price);
}

function billingPriceWithPromo(product, account = null) {
  const productMetaId = product.metadata?.id || product.id;
  let price = product.price || 0;
  if (account && account.pricing) {
    const accountPricingPlan = find(account.pricing, { id: productMetaId });
    if (accountPricingPlan && accountPricingPlan.price !== null) {
      price = accountPricingPlan.price || 0;
    }
  }
  let actualPrice = price;
  if (account && account.promo) {
    if (
      account.promo.pricing &&
      account.promo.pricing[productMetaId] !== undefined
    ) {
      actualPrice = account.promo.pricing[productMetaId];
    } else if (account.promo.discount) {
      actualPrice = price - price * (account.promo.discount / 100);
      actualPrice = actualPrice > 0 ? actualPrice : 0;
    }
  }
  return Math.round(actualPrice);
}

module.exports = {
  ...plans,
  billingTrialDaysLeft,
  billingTrialDaysLeftMessage,
  billingDiscountByInterval,
  billingPriceByInterval,
  billingPriceByMonth,
  billingPriceByYear,
  canGoLive,
  isSandbox,
  isOldSandbox,
};
