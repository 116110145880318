// @flow
import React from 'react';
import { connect } from 'react-redux';
import { find, reduce, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import { slugify } from 'utils';
import { contentUpdatesDeprecated } from 'utils/content';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';
import { cleanPurchaseOptions, transformCombinedOptions } from 'utils/product';

import actions from 'actions';

import LoadingView from 'components/view/loading';
import NewPage from 'components/pages/product/new';

export const mapStateToProps = (state: Object) => ({
  data: state.data,
  loading: state.data.loading,
  errors: state.data.recordErrors,
  categories: state.categories,
  content: state.content,
  lookup: state.lookup,
  attributes: state.attributes,
  settings: state.settings,
  bulk: state.data.bulk,
});

export const mapDispatchToProps = (dispatch: Function) => ({
  searchCategories: (search: string) => {
    return dispatch(actions.categories.search(search, 10));
  },

  createRecord: (data: Object) => {
    return dispatch(actions.data.createRecord('products', data));
  },

  updateRecord: (id: string, data: Object) => {
    return dispatch(actions.data.updateRecord('products', id, data));
  },

  addAttributeToStore(attr) {
    return dispatch(actions.attributes.addToStore(attr));
  },

  updateAttributeInStore(attr) {
    return dispatch(actions.attributes.updateInStore(attr));
  },

  loadContentModels: () => {
    return dispatch(actions.content.loadModels('products'));
  },

  loadContentFieldsDeprecated: () => {
    return dispatch(actions.content.loadFieldsDeprecated('products'));
  },

  fetchCategories() {
    return dispatch(actions.categories.fetch());
  },

  bulkGenerateGiftCards: (id: string, counts: Array, description: string) => {
    return dispatch(
      actions.data.bulkGenerateGiftCards(id, counts, description),
    );
  },

  bulkCancel: () => {
    return dispatch(actions.data.bulkCancel());
  },

  loadSettings() {
    return Promise.all([
      dispatch(actions.settings.fetch('products')),
      dispatch(actions.settings.fetch('accounts')),
      dispatch(actions.settings.fetch('shipments')),
      dispatch(actions.settings.fetch('integrations')),
    ]);
  },
});

export class NewProduct extends React.Component {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    // notifyCreated: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loaded: false,
      values: {},
      record: {},
      edited: false,
      attributeSet: null,
      attributeSetName: null,
      attributeSetAttributes: [],
      attributeValues: {},
      attributeSorted: false,
      giftcardGenerating: false,
      giftcardGeneratingCount: 0,
      onChangeForm: this.onChangeForm.bind(this),
      onSubmitRecord: this.onSubmitRecord.bind(this),
      onQueryCategories: this.onQueryCategories.bind(this),
      onAddAttribute: this.onAddAttribute.bind(this),
      onRemoveAttribute: this.onRemoveAttribute.bind(this),
      onChangeAttributeValue: this.onChangeAttributeValue.bind(this),
      onSortAttributes: this.onSortAttributes.bind(this),
      setTypeFields: this.setTypeFields.bind(this),
    };
  }

  componentDidMount() {
    confirmRouteLeave(this);

    const { loadSettings, loadContentModels, loadContentFieldsDeprecated } =
      this.props;

    loadSettings()
      .then(() =>
        Promise.all([loadContentModels(), loadContentFieldsDeprecated()]),
      )
      .then(() => {
        this.setState({ loaded: true });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  setTypeFields({ type, delivery, bundle_items }) {
    // TODO: refactor once we implement custom product types
    switch (type) {
      case 'physical':
        return {
          // No type by default
          // However, note the API can set `default_type` to `standard` for backward compat
          // See schema-api-server: products.features.apply_prototype for details
          type: undefined,
          delivery: 'shipment',
          bundle: null,
        };

      case 'digital':
        return {
          delivery: null,
          virtual: true,
          bundle: null,
        };

      case 'giftcard':
        return {
          delivery: delivery || 'giftcard',
          virtual: true,
          bundle: null,
        };

      case 'bundle': {
        const hasStandardItem =
          Array.isArray(bundle_items) &&
          bundle_items.some(
            ({ product }) =>
              product &&
              (product.type === 'standard' || product.delivery === 'shipment'),
          );

        return {
          bundle: true,
          delivery: hasStandardItem ? 'shipment' : null,
          virtual: true,
        };
      }

      default:
        return {};
    }
  }

  onQueryCategories(value: string) {
    this.props.searchCategories(value);
  }

  onChangeForm(values: Object, edited: boolean) {
    this.setState((state) => ({
      values: {
        ...state.values,
        ...values,
        ...this.setTypeFields(values),
      },
      edited,
    }));
  }

  // TODO: move these functions somewhere in common with EditProduct
  onChangeAttributeSet(event: Object) {
    const { value } = event.currentTarget;

    this.setState((_, props) => {
      const { record, settings } = props;

      return {
        attributeSet: value,
        attributeSetName:
          value && find(settings.products.attribute_sets, { id: value }).name,
        attributeSetAttributes: this.getAttributesFromSet(
          record.attribute_set,
          record.attributes,
          props.attributes.index,
        ),
      };
    });
  }

  onAddAttribute(attr: Object) {
    if (!this.props.attributes.index.has(attr.id)) {
      this.props.addAttributeToStore(attr);
    }

    this.setState((state) => ({
      attributeSetAttributes: [...state.attributeSetAttributes, attr.id],
      attributeValues: {
        ...state.attributeValues,
        [attr.id]: attr.default || null,
      },
    }));
  }

  onUpdateAttribute(attr) {
    this.props.updateAttributeInStore(attr);
  }

  onRemoveAttribute(attrId: string) {
    this.setState((state) => {
      const attributeValues = { ...state.attributeValues };
      delete attributeValues[attrId];

      return {
        attributeSetAttributes: state.attributeSetAttributes.filter(
          (attr) => attr !== attrId,
        ),
        attributeValues,
      };
    });
  }

  onSortAttributes(sourceId: string, targetId: string) {
    const sorted = [...this.state.attributeSetAttributes];
    const sourceIndex = sorted.indexOf(sourceId);
    const targetIndex = sorted.indexOf(targetId);
    sorted.splice(targetIndex, 0, sorted.splice(sourceIndex, 1)[0]);
    const attributeValues = sorted.reduce((attrs, attrId) => {
      if (this.state.attributeValues[attrId] !== undefined) {
        attrs[attrId] = this.state.attributeValues[attrId];
      }
      return attrs;
    }, {});
    const origSorted = this.getAttributesFromSet(
      '',
      {},
      this.props.attributes.index,
    );
    const attributeSorted = !isEqual(sorted, origSorted);
    this.setState({
      attributeSetAttributes: sorted,
      attributeSorted,
      attributeValues,
    });
  }

  getAttributesFromSet(
    attributeSetId: string,
    recordAttributes: Object = {},
    attributeIndex: Map<String, Object>,
  ) {
    const { settings } = this.props;
    const attributeSet = find(settings.products.attribute_sets, {
      id: attributeSetId,
    });
    if (attributeSet) {
      return (attributeSet.attributes || []).filter((key) =>
        attributeIndex.has(key),
      );
    }
    if (recordAttributes) {
      return Object.keys(recordAttributes).filter((key) =>
        attributeIndex.has(key),
      );
    }
    return [];
  }

  onChangeAttributeValue(event: Object, value: any) {
    const attrId = event.target.dataset.id;

    this.setState((state) => ({
      attributeValues: {
        ...state.attributeValues,
        [attrId]: value,
      },
    }));
  }

  async onSubmitRecord(values: Object) {
    const {
      router,
      createRecord,
      updateRecord,
      bulkGenerateGiftCards,
      bulkCancel,
      location,
      content,
      categories,
      fetchCategories,
    } = this.props;

    if (values.giftcard_generate) {
      for (const count of values.giftcard_generate.counts) {
        if (count.count > 1000) {
          this.context.notifyError(
            'You can only generate up to 1,000 codes at once per denomination',
          );
          return false;
        }
      }
    }
    const type = values.type || this.props.location.query.type || 'standard';

    const result = await createRecord({
      ...values,
      type,
      ...this.setTypeFields({
        bundle_items: values.bundle_items,
        delivery: values.delivery,
        type,
      }),
      slug: values.slug || slugify(values.name),
      price: values.price || 0,
      stock_tracking: values.stock_tracking || false,
      ...location.query,
      categories: undefined,
      categoryValues: undefined,
      enable_up_sells: undefined,
      enable_cross_sells: undefined,
      custom_options: undefined,
      variant_options: undefined,
      combined_options: undefined,
      giftcard_generate: undefined,
      purchase_options: reduce(
        cleanPurchaseOptions(values.purchase_options),
        (acc, purchaseOption, type) => {
          if (purchaseOption.active) {
            acc[type] = purchaseOption;
          }
          return acc;
        },
        {},
      ),
      bundle_items: values.bundle_items
        ? values.bundle_items.map((item) => ({
            id: item.id,
            product_id: item.product && item.product.id,
            variant_id: item.variant && item.variant.id,
            options: (item.variable !== 'choose' && item.options) || [],
            quantity: item.quantity || 1,
            variable: item.variable,
          }))
        : undefined,
      options: (values.options || [])
        .concat(values.variant_options || [])
        .concat(values.custom_options || [])
        .concat(transformCombinedOptions(values.combined_options)),
      up_sells:
        values.up_sells &&
        values.up_sells.map((item) => ({
          id: item.id,
          product_id: item.product && item.product.id,
        })),
      cross_sells:
        values.cross_sells &&
        values.cross_sells.map((item) => ({
          id: item.id,
          product_id: item.product && item.product.id,
          discount_type: item.discount_type,
          discount_amount: item.discount_amount,
          discount_percent: item.discount_percent,
        })),
      content: contentUpdatesDeprecated(
        content.fieldsDeprecated.products,
        values.content,
      ),
    });

    if (result.errors) {
      this.context.notifyError(result.errors);
    } else {
      if (values.giftcard_generate) {
        this.setState({
          giftcardGenerating: true,
          giftcardGeneratingCount: values.giftcard_generate.counts,
        });
        await bulkGenerateGiftCards(result.id, values.giftcard_generate.counts);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.setState({ giftcardGenerating: false });
        bulkCancel();
      }
      if (values.categoryValues && values.categoryValues.length > 0) {
        await updateRecord(result.id, {
          categories: values.categoryValues.map((category) => ({
            parent_id: category.id,
          })),
        });

        if (
          // if new categories were created
          values.categoryValues.some(
            (category) => !categories.index.has(category.id),
          )
        ) {
         await fetchCategories();
        }
      }
      this.setState({ edited: false }, () => {
        router.replace(`/products/${result.id}?success=true`);
      });
    }
  }

  render() {
    if (!this.state.loaded) {
      return <LoadingView />;
    }

    return <NewPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewProduct);
