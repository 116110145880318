import React from 'react';
import pt from 'prop-types';

const EnvironmentBadge = ({ isLive }) => {
  return (
    <div
      className={`nav-env-badge ${isLive ? 'nav-env-live' : 'nav-env-test'}`}
    >
      <div>{isLive ? 'LIVE' : 'TEST'}</div>
    </div>
  );
};

EnvironmentBadge.propTypes = {
  isLive: pt.bool.isRequired,
};

export default EnvironmentBadge;
