import React from 'react';
import { connect } from 'react-redux';

import { formatDate, inflect } from 'utils';
import { couponCodeSingle } from 'utils/discount';

import api from 'services/api';

import actions from 'actions';
import { replaceLocationQuery } from 'actions/data';

import Status from 'components/status';
import DiscountRuleDescriptions from 'components/discount/rule-descriptions';
import ViewLoading from 'components/view/loading';

import Collection from './Collection';

let SETTINGS;
let CATEGORIES;

const query = {
  expand: ['codes:1', 'discounts.product', 'discounts.category'],
};

const tabs = {
  default: {
    label: 'All coupons',
  },
  active: {
    label: 'Active',
    query: {
      active: true,
      $or: [
        { date_expired: null },
        { date_expired: { $exists: false } },
        { date_expired: { $gt: Date.now() } },
      ],
    },
  },
  expired: {
    label: 'Expired',
    query: {
      $and: [
        { date_expired: { $exists: true } },
        { date_expired: { $lte: Date.now() } },
      ],
    },
  },
  inactive: {
    label: 'Inactive',
    query: {
      $or: [{ active: false }],
    },
  },
};

const filters = {
  use_count: {
    label: 'Use count',
    operators: ['gt', 'lt', 'eq'],
    type: 'number',
  },
  date_created: {
    label: 'Created date',
    operators: ['after', 'before'],
    type: 'date',
  },
  status: {
    label: 'Status',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
      { value: 'expired', label: 'Expired' },
    ],
    func: (query) => {
      if (query === 'active') {
        return {
          active: true,
          $or: [
            { date_expired: null },
            { date_expired: { $exists: false } },
            { date_expired: { $gt: Date.now() } },
          ],
        };
      }
      if (query === 'inactive') {
        return {
          $or: [{ active: false }, { date_valid: { $gt: Date.now() } }],
        };
      }
      if (query === 'expired') {
        return {
          active: true,
          $and: [
            { date_expired: { $exists: true } },
            { date_expired: { $lte: Date.now() } },
          ],
        };
      }
    },
  },
};

const fields = {
  name: {
    label: 'Name',
    type: 'link',
    url: '/coupons/{id}',
    default: true,
    func: (coupon) => {
      const name = couponCodeSingle(coupon) || coupon.name;
      if (coupon.multi_codes) {
        return (
          <span>
            {name}
            <br />
            <span className="note muted">
              {inflect(coupon.codes.count, 'codes')}
            </span>
          </span>
        );
      } else {
        return name;
      }
    },
  },
  discounts: {
    label: 'Discount',
    sort: false,
    func: (coupon) => (
      <DiscountRuleDescriptions
        record={coupon}
        settings={SETTINGS}
        categories={CATEGORIES}
      />
    ),
  },
  use_count: {
    label: 'Used',
    func: (coupon) => {
      return coupon.use_count || 0;
    },
  },
  starts: {
    label: 'Starts',
    sort: false,
    func: (coupon) => {
      if (coupon.date_valid) {
        return (
          <span className="nowrap">
            {formatDate(coupon.date_valid, 'short')}
          </span>
        );
      }
      return <span className="muted">&mdash;</span>;
    },
  },
  expires: {
    label: 'Expires',
    sort: false,
    func: (coupon) => {
      if (coupon.date_expired) {
        return (
          <span className="nowrap">
            {formatDate(coupon.date_expired, 'short')}
          </span>
        );
      }
      return <span className="muted">&mdash;</span>;
    },
  },
  status: {
    label: 'Status',
    sort: false,
    func: (coupon) => {
      const isValid =
        coupon.date_valid && new Date(coupon.date_valid) <= Date.now();
      const isExpired =
        coupon.date_expired && new Date(coupon.date_expired) <= Date.now();
      return (
        <Status
          type={
            isValid === false
              ? 'muted'
              : isExpired
              ? 'negative'
              : coupon.active
              ? 'positive'
              : 'muted'
          }
        >
          {isValid === false
            ? 'Pending'
            : isExpired
            ? 'Expired'
            : coupon.active
            ? 'Active'
            : 'Inactive'}
        </Status>
      );
    },
  },
};

const headerActions = [{ label: 'New coupon', link: '/coupons/new' }];

async function searchHandler(search) {
  if (!search) {
    return {};
  }

  const foundCodes = await api.get('/data/coupons:codes', {
    search,
    limit: null,
    fields: 'parent_id',
  })
    .then(({ results }) =>
      Array.from(new Set(results.map((code) => code.parent_id)))
    );

  return {
    $or: [
      { id: { $in: foundCodes } },
      { name: { $regex: search } },
    ],
  };
}

class Coupons extends React.Component {
  static onEnter(store, nextState, replace) {
    if (replaceLocationQuery(store, nextState, replace)) {
      return;
    }
  }

  state = { loaded: false };

  componentDidMount() {
    const { loadCategories, loadSettings } = this.props;

    Promise.all([loadCategories(), loadSettings()])
      .then(() => {
        this.setState({ loaded: true });
      });
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    CATEGORIES = this.props.categories;
    SETTINGS = this.props.settings;

    return (
      <Collection
        {...this.props}
        title="Coupons"
        uri="/coupons"
        model="coupons"
        emptyDescription="Coupons are used to offer discounts to your customers at checkout. You may create one or many codes for each coupon."
        tabs={tabs}
        filters={filters}
        fields={fields}
        headerActions={headerActions}
        query={query}
        queryCurrency={true}
        searchHandler={searchHandler}
      />
    );
  }
}

function mapStateToProps({ categories, settings }) {
  return {
    categories,
    settings,
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadCategories() {
    return dispatch(actions.categories.load());
  },

  loadSettings() {
    return dispatch(actions.settings.fetch('shipments'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);
