import times from 'lodash/times';
import moment from 'moment-timezone';

import numeralEndings from 'constants/numeralEndings';

/**
 * @param {number} count
 * @param {moment.Moment} now
 */
function getLastQuarters(count, now) {
  return times(count, (num) => {
    const time = now.clone().add(-num - 1, 'quarter');
    const quarter = time.quarter();

    return {
      value: `${quarter}_${
        numeralEndings[quarter]
      }_${time.year()}`.toLowerCase(),
      label: `${quarter}${numeralEndings[quarter]} (${time.year()})`,
      time,
      range: {
        startDate: time.clone().startOf('quarter'),
        endDate: time.clone().endOf('quarter'),
      },
      compareTo: {
        startDate: time.clone().add(-1, 'quarter').startOf('quarter'),
        endDate: time.clone().add(-1, 'quarter').endOf('quarter'),
      },
    };
  });
}

/**
 * @param {string} timezone
 * @param {'day' | 'time'} [type='day']
 * @returns {Array<object>}
 */
export function getDateRangeOptions(timezone, type = 'day') {
  switch (type) {
    case 'day':
      return getDateRangeOptionsForDay(timezone);

    case 'time':
      return getDateRangeOptionsForTime(timezone);

    default:
      return [];
  }
}

/**
 * @param {string} timezone
 */
function getDateRangeOptionsForDay(timezone) {
  const now = moment().tz(timezone);

  return [
    {
      value: 'today',
      label: 'Today',
      range: {
        startDate: now.clone().startOf('day'),
        endDate: now.clone().endOf('day'),
      },
    },
    {
      value: 'yesterday',
      label: 'Yesterday',
      range: {
        startDate: now.clone().add(-1, 'days').startOf('day'),
        endDate: now.clone().add(-1, 'days').endOf('day'),
      },
    },
    {
      value: 'last_7_days',
      label: 'Last 7 days',
      range: {
        startDate: now.clone().add(-6, 'days').startOf('day'),
        endDate: now.clone().endOf('day'),
      },
    },
    {
      value: 'last_30_days',
      label: 'Last 30 days',
      range: {
        startDate: now.clone().add(-30, 'days').startOf('day'),
        endDate: now.clone().endOf('day'),
      },
    },
    {
      value: 'last_90_days',
      label: 'Last 90 days',
      range: {
        startDate: now.clone().add(-90, 'days').startOf('day'),
        endDate: now.clone().endOf('day'),
      },
    },
    {
      value: 'custom',
      label: 'Custom',
    },
    {
      value: 'last_month',
      label: 'Last month',
      range: {
        startDate: now.clone().add(-1, 'months').startOf('month'),
        endDate: now.clone().add(-1, 'months').endOf('month'),
      },
      compareTo: {
        startDate: now.clone().add(-2, 'months').startOf('month'),
        endDate: now.clone().add(-2, 'months').endOf('month'),
      },
    },
    {
      value: 'last_12_months',
      label: 'Last 12 months',
      range: {
        startDate: moment().add(-11, 'months').startOf('month'),
        endDate: now.clone().endOf('day'),
      },
    },
    {
      value: 'last_year',
      label: 'Last year',
      range: {
        startDate: now.clone().add(-1, 'years').startOf('year'),
        endDate: now.clone().add(-1, 'years').endOf('year'),
      },
      compareTo: {
        startDate: now.clone().add(-2, 'years').startOf('year'),
        endDate: now.clone().add(-2, 'years').endOf('year'),
      },
    },
    {
      value: 'week_to_date',
      label: 'Week to date',
      range: {
        startDate: now.clone().startOf('isoWeek'),
        endDate: now.clone().endOf('day'),
      },
      compareTo: {
        startDate: now.clone().add(-1, 'weeks').startOf('isoWeek'),
        endDate: now
          .clone()
          .add(-1, 'weeks')
          .startOf('isoWeek')
          .add(now.diff(now.clone().startOf('isoWeek'), 'days'), 'days'),
      },
    },
    {
      value: 'month_to_date',
      label: 'Month to date',
      range: {
        startDate: now.clone().startOf('month'),
        endDate: now.clone().endOf('day'),
      },
      compareTo: {
        startDate: now.clone().add(-1, 'months').startOf('month'),
        endDate: now
          .clone()
          .add(-1, 'months')
          .startOf('month')
          .add(
            Math.min(
              now.diff(now.clone().startOf('month'), 'days'),
              now.clone().add(-1, 'months').daysInMonth() - 1,
            ),
            'days',
          ),
      },
    },
    {
      value: 'quarter_to_date',
      label: 'Quarter to date',
      range: {
        startDate: now.clone().startOf('quarter'),
        endDate: now.clone().endOf('day'),
      },
      compareTo: {
        startDate: now.clone().add(-1, 'quarters').startOf('quarter'),
        endDate: now
          .clone()
          .add(-1, 'quarters')
          .startOf('quarter')
          .add(
            Math.min(
              now.diff(now.clone().startOf('quarter'), 'days'),
              now
                .clone()
                .add(-1, 'quarters')
                .endOf('quarter')
                .diff(
                  now.clone().add(-1, 'quarters').startOf('quarter'),
                  'days',
                ),
            ),
            'days',
          ),
      },
    },
    ...getLastQuarters(4, now),
  ];
}

function getDateRangeOptionsForTime(timezone) {
  const now = moment().tz(timezone);

  return [
    {
      value: 'alltime',
      label: 'All time',
      range: {
        startDate: now.clone().add(-100, 'years'),
        endDate: now.clone(),
      },
    },
    {
      value: '5min',
      label: 'Last 5 minutes',
      range: {
        startDate: now.clone().add(-5, 'minutes'),
        endDate: now.clone(),
      },
    },
    {
      value: '15min',
      label: 'Last 15 minutes',
      range: {
        startDate: now.clone().add(-15, 'minutes'),
        endDate: now.clone(),
      },
    },
    {
      value: '30min',
      label: 'Last 30 minutes',
      range: {
        startDate: now.clone().add(-30, 'minutes'),
        endDate: now.clone(),
      },
    },
    {
      value: '1hour',
      label: 'Last 1 hour',
      range: {
        startDate: now.clone().add(-1, 'hours'),
        endDate: now.clone(),
      },
    },
    {
      value: '4hour',
      label: 'Last 4 hours',
      range: {
        startDate: now.clone().add(-4, 'hours'),
        endDate: now.clone(),
      },
    },
    {
      value: '1day',
      label: 'Last 1 day',
      range: {
        startDate: now.clone().add(-1, 'days'),
        endDate: now.clone(),
      },
    },
    {
      value: '2day',
      label: 'Last 2 days',
      range: {
        startDate: now.clone().add(-2, 'days'),
        endDate: now.clone(),
      },
    },
    {
      value: '1week',
      label: 'Last 1 week',
      range: {
        startDate: now.clone().add(-1, 'weeks'),
        endDate: now.clone(),
      },
    },
    {
      value: '1month',
      label: 'Last 1 month',
      range: {
        startDate: now.clone().add(-1, 'months'),
        endDate: now.clone(),
      },
    },
    {
      value: 'custom',
      label: 'Custom',
    },
  ];
}
