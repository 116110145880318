import React from 'react';
import classNames from 'classnames';
import Icon from 'components/icon';

export default class ExternalLink extends React.PureComponent {
  getDisabledProps() {
    const { icon = true, disabled, className } = this.props;

    return {
      className: classNames(className, { 'with-icon': icon, disabled }),
      ...(disabled
        ? {
            onClick: (event) => {
              event.preventDefault();
              event.stopPropagation();
            },
          }
        : {}),
    };
  }

  render() {
    const { to = '', icon = true, children } = this.props;

    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...this.getDisabledProps()}
      >
        <span>{children}</span>{' '}
        {icon && <Icon fa="external-link" faType="light" />}
      </a>
    );
  }
}
