import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { FadeIn } from 'components/transitions';
import Label from 'components/form/label';
import { rgbToHex } from 'utils/form';
import $ from 'jquery';

const { textContrast } = require('text-contrast');

export default class InputColor extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.any,
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
  };

  state = {
    showingPicker: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.showingPicker && this.state.showingPicker) {
      document.addEventListener('mousedown', this.onClickWhenPickerVisible);
    } else if (prevState.showingPicker && !this.state.showingPicker) {
      document.removeEventListener('mousedown', this.onClickWhenPickerVisible);
    }
  }

  onClickWhenPickerVisible = (event) => {
    if (!$(event.target).closest(this.refs.colorPicker).length) {
      this.setState({ showingPicker: false });
    }
  };

  onClickShowPicker = (event) => {
    event.preventDefault();
    this.setState({ showingPicker: true });
  };

  onChangeComplete = (value) => {
    const event = new Event('change', { bubbles: true });
    const { r, g, b, a } = value.rgb;
    const nextValue = a < 1 ? `rgba(${r},${g},${b},${a})` : rgbToHex(r, g, b);
    this.props.onChange && this.props.onChange(event, nextValue);
  };

  onClickClose = (event) => {
    event.preventDefault();
    this.setState({ showingPicker: false });
  };

  isLightOrDark(value) {
    try {
      return value ? textContrast.isLightOrDark(value) : '';
    } catch (err) {
      return '';
    }
  }

  render() {
    const { id, value, label, className = '', help } = this.props;
    const { showingPicker } = this.state;

    const lightOrDark = this.isLightOrDark(value);

    return (
      <div className={`form-color ${className}`}>
        <input
          id={id}
          ref="input"
          className={`form-color-input ${label ? 'with-label' : ''}`}
          value={value || undefined}
          onClick={this.onClickShowPicker}
          onFocus={this.onClickShowPicker}
          readOnly
        />
        <div>
          <Label help={help} htmlFor={id}>
            {label}
          </Label>
          <div
            className={`form-color-preview ${
              value ? 'with-value' : ''
            } ${lightOrDark}`}
            style={{ backgroundColor: value }}
          >
            {value && <span className={lightOrDark}>{value}</span>}
          </div>
        </div>
        {showingPicker && (
          <FadeIn className="form-color-picker-container">
            <a
              href=""
              onClick={this.onClickClose}
              className="form-color-picker-close"
            >
              &times;
            </a>
            <div ref="colorPicker">
              <SketchPicker
                className="form-color-picker-color"
                color={value || undefined}
                onChangeComplete={this.onChangeComplete}
              />
            </div>
          </FadeIn>
        )}
      </div>
    );
  }
}
