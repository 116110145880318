import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'components/form';
import View from 'components/view';
import Icon from 'components/icon';
import FileReader from 'components/form/file-reader';
import { FadeIn } from 'components/transitions';
import { imageUrl } from 'utils';
import './profile.scss';

export default class ProfilePage extends React.PureComponent {
  static contextTypes = {
    onClickLogout: PropTypes.func.isRequired,
  };

  render() {
    const {
      user,
      values,
      edited,
      onUploadPhoto,
      onChangeForm,
      onSubmitProfile,
    } = this.props;

    const { onClickLogout } = this.context;

    return (
      <div className="profile">
        <Form ref="form" onSubmit={onSubmitProfile} onChange={onChangeForm}>
          <View
            detail={true}
            uri={`/settings/account`}
            sectionTitle="Account"
            headerTitle={user.name}
            headerComponent={
              <div className="with-image">
                <div className="view-header-image">
                  {user.photo && (
                    <img
                      src={imageUrl(user.photo, { width: 120, height: 120 })}
                      alt="Upload"
                    />
                  )}
                  <div
                    className={`profile-photo-upload ${
                      user.photo ? '' : 'empty'
                    }`}
                  >
                    <FileReader accept="image/*" onFiles={onUploadPhoto} multiple={false}>
                      <span className="profile-photo-upload-label">
                        {user.photo ? <Icon fa="edit" /> : <Icon fa="image" />}
                      </span>
                    </FileReader>
                  </div>
                </div>
                <h3 className="view-header-name">{user.name}</h3>
                <div className="view-header-note">{user.username}</div>
              </div>
            }
            headerActions={[
              {
                label: 'Save profile',
                type: edited ? 'default' : 'secondary',
                submit: true,
              },
            ]}
            footerActions={[
              {
                label: `Log out ${user.name}`,
                type: 'cancel-danger inverse',
                onClick: onClickLogout,
              },
            ]}
          >
            <fieldset>
              <div className="row">
                <Field
                  type="text"
                  name="name"
                  label="Your name"
                  required={true}
                  defaultValue={user.name}
                  className="span2"
                />
                <Field
                  type="text"
                  name="username"
                  label="Email address"
                  required={true}
                  defaultValue={user.username}
                  className="span2"
                />
              </div>
              <br />
              <Field
                type="checkbox"
                name="change_password"
                label="Change password"
                readonly={true}
              />
              <FadeIn active={!!values.change_password} className="row">
                <input
                  type="text"
                  name="password"
                  className="hidden"
                  value=""
                />
                <Field
                  type="password"
                  name="password"
                  label="New password"
                  placeholder="**********"
                  className="span2"
                  autoComplete="off"
                  rules="password_admin"
                />
              </FadeIn>
              <div className="row">
                <Field
                  type="checkbox"
                  name="notify"
                  label="Notify me when an order is placed"
                  defaultChecked={user.notify}
                />
              </div>
              <div className="row">
                <Field
                  type="checkbox"
                  name="devtools"
                  label="Enable developer tools"
                  help="Turn on a few useful shortcuts for developers"
                  defaultChecked={user.devtools}
                />
              </div>
            </fieldset>
          </View>
        </Form>
      </div>
    );
  }
}
