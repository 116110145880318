import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import OrderAddressForm from 'components/pages/order/address-form';
import ContentFields from 'components/content/fields';

import { hasLocalizedContent } from 'utils/content';
import { isEmpty } from 'utils';

const mapStateToProps = (state) => ({
  model: state.data.model,
  record: state.data.record,
  suggestedAddresses: state.lookup.suggestedAddresses,
  content: state.content,
});

class OrderBillingEdit extends React.PureComponent {
  static propTypes = {
    model: pt.string.isRequired,
    record: pt.object.isRequired,
    suggestedAddresses: pt.array,
    content: pt.object.isRequired,

    onSubmitEditBilling: pt.func,
    onClickEditBilling: pt.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      values: {},
    };
  }

  onChangeForm = (values) => {
    this.setState({
      values: {
        billing: {
          ...this.state.values.billing,
          ...values.billing,
        },
      },
    });
  };

  render() {
    const {
      model,
      record,
      content,
      suggestedAddresses,
      onSubmitEditBilling,
      onClickEditBilling,
    } = this.props;
    const { values } = this.state;
    const allowContent = model === 'accounts';
    const contentProps = {};

    if (allowContent) {
      contentProps.localized = hasLocalizedContent(content.models, model);
      contentProps.devtools = {
        model,
        uri: `${record.id}/billing`,
        zone: 'billing',
        console: !isEmpty(values.billing),
      };
    }

    return (
      <Form
        onSubmit={onSubmitEditBilling}
        onChange={this.onChangeForm}
        autoFocus={true}
      >
        <Modal
          title="New address"
          className="order-view-modal-edit-billing"
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'secondary',
              className: 'button-cancel',
              onClick: onClickEditBilling,
            },
          ]}
          width={700}
          cancel={false}
          onClose={onClickEditBilling}
          {...contentProps}
        >
          <fieldset>
            <OrderAddressForm
              name="billing"
              required={true}
              record={record.billing}
              values={values.billing}
              suggestedAddresses={suggestedAddresses}
              onChangeForm={this.onChangeForm}
            />
            <div className="row">
              <Field
                type="checkbox"
                label="Set as default address"
                name="is_default"
                className="span4"
                defaultChecked={true}
                nonValue={false}
              />
            </div>
            {allowContent && (
              <ContentFields
                {...this.props}
                zone="billing"
                collection={model}
                models={content.models}
                record={record}
                values={values}
                currency={record.currency}
              />
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }
}

export default connect(mapStateToProps)(OrderBillingEdit);
