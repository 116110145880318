import React, { isValidElement, Fragment } from 'react';
import pt from 'prop-types';

import Button from 'components/button/button';
import KebabButton from 'components/button/kebab';
import ModelLink from 'components/model/link';
import ConsoleLink from 'components/console/link';

const isActionLeft = (act) =>
  (act?.className?.indexOf('left') >= 0 ||
    act?.component?.props?.className?.indexOf('left') >= 0) &&
  (!act?.component ||
    act?.component?.props?.className?.indexOf('outside') === -1);

function ModalActions({
  actions,
  devtools,
  loading,
  cancel,
  cancelText,
  cancelSize,
  onClickCancel,
}) {
  const list = [];
  const leftList = [];

  if (Array.isArray(actions)) {
    list.push(...actions.filter((act) => !isActionLeft(act)));
    leftList.push(...actions.filter(isActionLeft));
  }

  if (devtools) {
    const devtoolActions = [];
    if ((devtools.uri || devtools.record) && devtools.console !== false) {
      devtoolActions.push(
        <ConsoleLink
          label="View in console"
          model={devtools.model}
          uri={devtools.uri || (devtools.record && devtools.record.id)}
          buttonType={null}
          icon={false}
        />,
      );
    }

    if (devtools.zone) {
      devtoolActions.push(
        <ModelLink
          model={devtools.model}
          zone={devtools.zone}
          buttonType={null}
          icon={false}
        />,
      );
    }

    if (leftList.length > 0) {
      leftList.push(<hr />);
    }
    leftList.push(...devtoolActions);
  }

  if (cancel) {
    list.push(
      <Button
        type="secondary"
        size={cancelSize || 'sm'}
        onClick={onClickCancel}
        className="button-cancel"
        disabled={loading}
      >
        {cancelText || 'Cancel'}
      </Button>,
    );
  }

  if (leftList.length > 0) {
    list.push(
      <KebabButton
        anchor="left"
        anchorDown={true}
        items={leftList.map(renderAction)}
      />,
    );
  }

  function renderAction(action, index) {
    if (!action) {
      return null;
    }

    if (isValidElement(action)) {
      return <Fragment key={index}>{action}</Fragment>;
    }

    if (isValidElement(action.component)) {
      return <Fragment key={index}>{action.component}</Fragment>;
    }

    const { label, ...props } = action;

    if (label === 'Remove item') {
      return (
        <button type="button" key={index} {...props}>
          {label}
        </button>
      );
    }

    return (
      <Button key={index} size="sm" disabled={loading} {...props}>
        {label}
      </Button>
    );
  }

  return (
    <Fragment>
      <div className="modal-actions-shadow-wrapper">
        <div className="modal-actions-shadow" />
      </div>

      <div className="modal-actions">{list.map(renderAction)}</div>
    </Fragment>
  );
}

ModalActions.propTypes = {
  actions: pt.array,

  devtools: pt.shape({
    uri: pt.string,
    zone: pt.string,
    model: pt.string.isRequired,

    record: pt.shape({
      id: pt.string,
    }),

    console: pt.bool,
  }),

  loading: pt.bool,
  cancel: pt.bool,
  cancelText: pt.string,
};

export default React.memo(ModalActions);
