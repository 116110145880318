import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LocaleFieldSelect from 'components/locale/field-select';
import CurrencyFieldSelect from 'components/locale/currency-field-select';
import { localeOptions } from 'utils/geo';

export default class CustomerLocaleCurrencyFields extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object,
  };

  render() {
    const {
      record = {},
      localeName = undefined,
      localeLabel = undefined,
      currencyName = undefined,
      currencyLabel = undefined,
    } = this.props;
    const { client } = this.context;

    const hasLocale = client.locales.length > 0 || record.locale;
    const hasCurrency =
      client.pricedCurrencies.length > 0 || record.currency !== client.currency;

    return (
      <Fragment>
        {(hasLocale || hasCurrency) && (
          <div className="row">
            {hasCurrency && (
              <CurrencyFieldSelect
                name={currencyName || 'currency'}
                label={currencyLabel || 'Currency'}
                defaultValue={record.currency}
                record={record}
                className="span2"
              />
            )}
            {hasLocale && (
              <LocaleFieldSelect
                name={localeName || 'locale'}
                label={localeLabel || 'Locale'}
                placeholder="None"
                options={localeOptions}
                defaultValue={record.locale}
                className="span2"
              />
            )}
            {(!hasLocale || !hasCurrency) && <div />}
          </div>
        )}
      </Fragment>
    );
  }
}
