const actions = {
  importAttribute: (attr, data) => {
    switch (attr.type) {
      // Array types
      case 'multiselect':
      case 'checkbox':
        if (data.length > 0) {
          return data;
        }
        break;
      case 'image':
      case 'file':
        if (data.length > 0) {
          if (attr.multi) {
            return data;
          }
          return { file: { url: data } };
        }
        break;
      default:
        break;
    }

    return data;
  },
  importCategories: (data) => data,
  importBundleItems: (data) => data,
  importCategoriesPartsFromPath: (data) => data,
  importCategoriesPartName: (data) => data,
  importImages: (data) => data,
  importNewCategories: (data) => (data.results ? data.results : data),
  importTags: (data) => data,
  importTransformFilesUrls: (data) => data,
  importTransformVariantValueIds: (data) => data,
  importVariantsOptionValueIds: (data) => data,
  importProductOptions: (data) => data,
  importPurchaseOptions: (data) => data,
  importLocaleOptions: (data) => data,
};

export default actions;
