import { get, startCase, filter, capitalize } from 'lodash';
import { formatCurrency, formatCurrencyRounded } from 'utils';
import ObjectID from 'bson-objectid';
import currency from 'currency.js';

export const SUBSCRIPTION_INTERVAL_OPTIONS = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'yearly', label: 'Yearly' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'daily', label: 'Daily' },
];

export const DEFAULT_SUBSCRIPTION_PLAN = {
  name: SUBSCRIPTION_INTERVAL_OPTIONS[0].label,
  description: '',
  price: '',
  billing_schedule: {
    interval: SUBSCRIPTION_INTERVAL_OPTIONS[0].value,
    interval_count: 1,
    limit: '',
    trial_days: 0,
  },
  has_order_schedule: false,
  order_schedule: {
    interval: SUBSCRIPTION_INTERVAL_OPTIONS[0].value,
    interval_count: 1,
    limit: '',
  },
};

export function subscriptionIntervalOptions(intervalCount) {
  return intervalCount > 1
    ? [
        { value: 'monthly', label: 'Months' },
        { value: 'yearly', label: 'Years' },
        { value: 'weekly', label: 'Weeks' },
        { value: 'daily', label: 'Days' },
      ]
    : [
        { value: 'monthly', label: 'Month' },
        { value: 'yearly', label: 'Year' },
        { value: 'weekly', label: 'Week' },
        { value: 'daily', label: 'Day' },
      ];
}

// Get subscription status name
export function subscriptionStatus(sub) {
  return startCase(sub.status) || 'Unknown';
}

export function subscriptionTitle(sub) {
  return sub && sub.product
    ? sub.product.name
    : sub && sub.product_name
    ? sub.product_name
    : '(Unknown subscription)';
}

// Get a nice description of recurring subscription interval
export function subscriptionIntervalDesc(sub, params = {}) {
  const { interval, interval_count, trial_days, limit, limit_current } = get(
    sub,
    'billing_schedule',
    sub,
  );
  const plan_description = get(sub, 'plan_description');
  let desc = interval || 'monthly';

  if (plan_description) {
    desc = plan_description;
  } else if (interval_count > 1) {
    switch (interval) {
      case 'daily':
        desc = `Every ${interval_count} days`;
        break;
      case 'weekly':
        desc = `Every ${interval_count} weeks`;
        break;
      case 'monthly':
        desc = `Every ${interval_count} months`;
        break;
      case 'yearly':
        desc = `Every ${interval_count} years`;
        break;
      default:
        return '';
    }
  } else {
    if (params.every) {
      switch (interval) {
        case 'daily':
          desc = 'day';
          break;
        case 'weekly':
          desc = 'week';
          break;
        case 'monthly':
          desc = 'month';
          break;
        case 'yearly':
          desc = 'year';
          break;
        default:
          return '';
      }
      desc = `Every ${desc}`;
    }
  }

  if (limit > 0) {
    desc = `${desc} (${
      limit_current && limit_current < limit
        ? `${limit_current} of ${limit}`
        : `limit ${limit}`
    })`;
  }

  const orderSchedule = get(sub, 'order_schedule', {});
  if (sub.order_schedule && orderSchedule.interval) {
    if (orderSchedule.interval_count > 1) {
      switch (orderSchedule.interval) {
        case 'daily':
          desc += `, orders every ${orderSchedule.interval_count} days`;
          break;
        case 'weekly':
          desc += `, orders every ${orderSchedule.interval_count} weeks`;
          break;
        case 'monthly':
          desc += `, orders every ${orderSchedule.interval_count} months`;
          break;
        case 'yearly':
          desc += `, orders every ${orderSchedule.interval_count} years`;
          break;
        default:
          return '';
      }
    } else {
      switch (orderSchedule.interval) {
        case 'daily':
          desc += ', orders daily';
          break;
        case 'weekly':
          desc += ', orders weekly';
          break;
        case 'monthly':
          desc += ', orders monthly';
          break;
        case 'yearly':
          desc += ', orders yearly';
          break;
        default:
          return '';
      }
    }

    if (orderSchedule.limit > 0) {
      desc = `${desc} (${
        orderSchedule.limit_current &&
        orderSchedule.limit_current < orderSchedule.limit
          ? `${orderSchedule.limit_current} of ${orderSchedule.limit}`
          : `limit ${orderSchedule.limit}`
      })`;
    }
  }

  if (params.currency && sub.price > 0) {
    desc = `${desc}, ${formatCurrencyRounded(sub.price, params.currency)}`;
  }

  const hasTrial =
    (sub.trial === true || sub.trial === undefined) && trial_days > 0;
  if (hasTrial) {
    desc = `${desc}, ${trial_days}-day trial`;
  }

  return capitalize(desc);
}

/*
  Similar to subscriptionIntervalDesc but description value will be used
  for displaying in report table.
*/
export function subscriptionReportPlanDescription(
  subscriptionReport,
  currency,
) {
  const {
    billing_schedule: { interval_count, interval },
    price,
  } = subscriptionReport;

  let intervalStr = null;
  const recordCurrency = subscriptionReport.currency || currency;
  if (interval_count === 1) {
    intervalStr = interval[0].toUpperCase() + interval.slice(1);
    return `${intervalStr}, ${formatCurrency(price, recordCurrency)}`;
  } else {
    switch (interval) {
      case 'daily':
        intervalStr = 'days';
        break;
      case 'weekly':
        intervalStr = 'weeks';
        break;
      case 'monthly':
        intervalStr = 'months';
        break;
      case 'yearly':
        intervalStr = 'years';
        break;
      default:
        break;
    }

    return `Every ${interval_count} ${intervalStr}, ${formatCurrency(
      price,
      recordCurrency,
    )}`;
  }
}

export function subscriptionOptionValueNameDeprecated(optionValue, params) {
  return subscriptionIntervalDesc(
    {
      interval: optionValue.subscription_interval,
      interval_count: optionValue.subscription_interval_count,
      trial_days: optionValue.subscription_trial_days,
      price: optionValue.price,
    },
    params,
  );
}

export function subscriptionPlanDesc(plan, params) {
  return subscriptionIntervalDesc(plan, params);
}

// Get a nice description of recurring subscription total
export function subscriptionPriceDesc(sub) {
  let extra = '';
  const recurring_total = currency(sub.recurring_total, { precision: 2 }).value;
  if (sub.grand_total > recurring_total) {
    extra = `+ ${formatCurrencyRounded(
      sub.grand_total - recurring_total,
      sub.currency,
    )}`;
  }

  return `${formatCurrencyRounded(
    recurring_total,
    sub.currency,
  )} / ${subscriptionIntervalDesc(sub)} ${extra}`;
}

function subscriptionItemHasOrderDelivery(item) {
  return ['shipment', 'giftcard'].indexOf(item.delivery) >= 0;
}

function subscriptionProductHasOrderItems(product) {
  // TODO: remove deprecated subscription delivery
  if (
    product &&
    product.delivery === 'subscription' &&
    product.bundle_items &&
    product.bundle_items.length > 0
  ) {
    return true;
  } else if (product.bundle_items && product.bundle_items.length > 0) {
    return (
      product.bundle_items.filter(subscriptionItemHasOrderDelivery).length > 0
    );
  } else if (subscriptionItemHasOrderDelivery(product)) {
    return true;
  }
  return false;
}

export function subscriptionHasOrderItems(record, product) {
  return (
    subscriptionProductHasOrderItems(product) ||
    filter(record.items, subscriptionItemHasOrderDelivery).length > 0
  );
}

function getCurrencies(context) {
  const { client, account } = context;
  let { pricedCurrencies } = client;
  if (!account.hasPricedCurrencies) {
    pricedCurrencies = [];
  }

  const subscriptionDefaultCurrencies = pricedCurrencies.reduce(
    (prev, { active, code }) => {
      if (active) {
        prev.$currency[code] = { price: '' };
      }

      return prev;
    },
    { $currency: {} },
  );

  return pricedCurrencies.length > 0 ? subscriptionDefaultCurrencies : {};
}

function getLocales(context) {
  const { client } = context;
  const { locales } = client;

  const subscriptionDefaultLocales = locales.reduce(
    (prev, { active, code }) => {
      if (active) {
        prev.$locale[code] = { description: '' };
      }

      return prev;
    },
    { $locale: {} },
  );

  return locales.length > 0 ? subscriptionDefaultLocales : {};
}

export function getDefaultSubscriptionPlan(context, withDefaultPlan = true) {
  const defaultSubscriptionPlan = {
    ...getCurrencies(context),
    ...getLocales(context),
    ...(withDefaultPlan ? {
      ...DEFAULT_SUBSCRIPTION_PLAN,
      id: ObjectID().toHexString(),
      active: true,
    }: {}),
  };

  return defaultSubscriptionPlan;
}
