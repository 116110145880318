import React from 'react';
import { startCase } from 'lodash';
import Status from 'components/status';

export default function InvoiceStatus({ invoice }) {
  const { status } = invoice;
  return (
    <Status
      type={
        status === 'paid'
          ? 'positive'
          : status === 'void' || status === 'unpaid'
          ? 'negative'
          : 'muted'
      }
    >
      {startCase(status)}
    </Status>
  );
}
