import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  first,
  last,
  some,
  get,
  minBy,
  size,
  takeRight,
  forEach,
} from 'lodash';
import objectid from 'objectid';
import * as d3 from 'd3';
import './chart.scss';
import { toFixed } from 'client/src/utils';

const OFFSET_WIDTH = 20;
const OFFSET_HEIGHT = 10;

class Chart extends PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.chartId = objectid();
  }

  state = {
    width: null,
    height: null,
    tooltip: {
      showTooltip: false,
    },
  };

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
    this.onWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  handleRef = (ref) => {
    this.container = ref;
  };

  onWindowResize = () => {
    if (this.container) {
      const { width, height } = this.container.getBoundingClientRect();
      if (width !== this.state.width || height !== this.state.height) {
        this.setState({ width, height });
      }
    }
  };

  getYMinMaxValues = () => {
    const { data, yValue } = this.props;

    const dataForProcessing = data.reduce((memo, { points }) => {
      return [...memo, ...points.map((value) => value[yValue])];
    }, []);

    const minValue = d3.min(dataForProcessing);
    const maxValue = d3.max(dataForProcessing);

    return { maxValue: maxValue > 0 ? maxValue : 1, minValue };
  };

  getXScale = (points, width) => {
    const { xValue, ordinal } = this.props;

    if (ordinal) {
      return d3
        .scaleBand()
        .domain(points.map((point) => point[xValue]))
        .range([0, width])
        .padding(0.2);
    }

    return d3
      .scaleLinear()
      .domain([first(points)[xValue], last(points)[xValue]])
      .range([0, width]);
  };

  getPoints = (allPoints, minLength) => {
    const { withNegativeValues, yValue } = this.props;

    const rightPoints = takeRight(allPoints, minLength);

    return withNegativeValues
      ? rightPoints
      : forEach(
          rightPoints,
          (point) => (point[yValue] = point[yValue] < 0 ? 0 : point[yValue]),
        );
  };

  renderAlert() {
    return (
      <div className="chart-alert-root">
        <span className="chart-alert-info-icon">i</span>
        <span className="chart-alert-text">
          This report shows up to 1,000 results.{' '}
          <span className="chart-no-display">
            To see all results, you can{' '}
            <span className="chart-alert-link">export the report</span>.
          </span>
        </span>
      </div>
    );
  }

  render() {
    if (this.props.data[0].points.length > 1000) {
      return this.renderAlert();
    }

    const {
      style,
      children,
      margin,
      data,
      xValue,
      yValue,
      pointColors = ['#785ce0', '#D4D4D4'],
      title,
      ordinal,
      className,
    } = this.props;

    const { currency, timezone } = this.context.client;

    // align point arrays
    const minLength = size(minBy(data, (obj) => size(obj.points)).points);
    const newData = data.reduce(
      (memo, value) =>
        memo.concat([
          {
            ...value,
            points: this.getPoints(value.points, minLength),
          },
        ]),
      [],
    );

    const { chartId } = this;
    const { /*minValue: minY,*/ maxValue: maxY } = this.getYMinMaxValues();
    const { width, height } = this.state;

    const reStyle = {
      ...style,
    };

    const chartWidth = width - margin.left - margin.right - OFFSET_WIDTH;
    const chartHeight = height - margin.top - margin.bottom - OFFSET_HEIGHT;

    const xScales = newData.reduce(
      (acc, value) => ({
        ...acc,
        [value.id]: this.getXScale(value.points, chartWidth),
      }),
      {},
    );

    const yScale = d3.scaleLinear().domain([0, maxY]).range([chartHeight, 0]);

    const isExistYAxis = some(children, (x) => get(x, 'type.name') === 'YAxis');

    let addOffsetWidth = 0;

    if (!currency) {
      const maxYStr = Number(toFixed(maxY, 2)).toString();

      addOffsetWidth = Math.max(maxYStr.length - 8, 0) * 5;
    }

    return (
      <div className={`chart-container ${className || ''}`}>
        <div className="chart-title">{title}</div>
        <div
          id={'chart-tooltip-' + chartId}
          className="chart-tooltip-root"
          style={{ visibility: 'hidden' }}
        />
        <svg style={reStyle} width={width} height={height} ref={this.handleRef}>
          <linearGradient
            id="chartLineAreaGradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop
              offset="0%"
              style={{ stopColor: '#EBE9F6', stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: '#F9F1FB', stopOpacity: 1 }}
            />
          </linearGradient>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              width,
              height,
              xScales,
              yScale,
              margin,
              data: newData,
              xValue,
              yValue,
              pointColors,
              chartId,
              ordinal,
              offsetWidth:
                isExistYAxis && maxY ? OFFSET_WIDTH + addOffsetWidth : 0,
              offsetHeight: OFFSET_HEIGHT,
              currency: currency || this.props.currency,
              timezone: timezone || this.props.timezone,
              ...child.props,
            }),
          )}
        </svg>
        <div id={`chart-legend-viewport-${chartId}`} />
      </div>
    );
  }
}

export default Chart;
