import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';
import md5 from 'md5';
import ViewLoading from 'components/view/loading';
import UsersIndexPage from 'components/settings/users';
import NotFoundPage from 'components/pages/error/404';
import actions from 'actions';
import segment from 'services/segment';

import Users from 'components/settings/users';
import User from 'components/settings/user';
import UserRoles from 'components/settings/user-roles';
import UserRole from 'components/settings/user-role';

const { BASE_URI } = process.env;

const mapStateToProps = (state) => ({
  client: state.client,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (id, values) => {
    return dispatch(actions.data.updateRecord(':users', id, values));
  },

  updateUserSelf: (values) => {
    return dispatch(actions.user.update(values));
  },

  createUser: (values) => {
    return dispatch(actions.data.createRecord(':users', values));
  },

  removeUser: (id) => {
    return dispatch(actions.data.deleteRecord(':users', id));
  },

  uploadPhoto: (image) => {
    return dispatch(actions.user.uploadPhoto(image));
  },

  updateClient: (data) => {
    return dispatch(actions.client.update(data));
  },

  fetchUsers: () => {
    return dispatch(actions.data.fetchCollection(`:users`, { limit: null }));
  },

  fetchRoles: () => {
    return dispatch(actions.client.fetchRoles());
  },

  createRole: (data) => {
    return dispatch(actions.client.createRole(data));
  },

  updateRole: (data) => {
    return dispatch(actions.client.updateRole(data));
  },

  deleteRole: (id) => {
    return dispatch(actions.client.deleteRole(id));
  },

  reassignRoles: (data) => {
    return dispatch(actions.client.reassignRoles(data));
  },
});

export class UserSettings extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    uploadImages: PropTypes.func.isRequired,
    isAdvancedUserPermissions: PropTypes.bool.isRequired,
  };

  state = {};

  constructor(props, context) {
    super(props, context);
    this.state = {
      edited: false,
      loaded: false,
      loading: false,
      users: null,
      roles: null,
      values: {},
      onSubmitUser: this.onSubmitUser.bind(this),
      onInviteUser: this.onInviteUser.bind(this),
      onRemoveUser: this.onRemoveUser.bind(this),
      onUploadPhoto: this.onUploadPhoto.bind(this),
      onCreateRole: this.onCreateRole.bind(this),
      onUpdateRole: this.onUpdateRole.bind(this),
      onDeleteRole: this.onDeleteRole.bind(this),
      onReassignRoles: this.onReassignRoles.bind(this),
    };
  }

  async componentWillMount() {
    return this.fetchSettings().then((settings) =>
      this.setState({
        loaded: true,
        ...settings,
        values: { ...settings },
      }),
    );
  }

  componentWillReceiveProps(nextProps) {
    const { section } = this.props.route;

    if (nextProps.route.section && nextProps.route.section !== section) {
      this.setState({ loaded: false });
      this.fetchSettings().then((settings) =>
        this.setState({
          loaded: true,
          ...settings,
          values: { ...settings },
        }),
      );
    }
  }

  async fetchSettings() {
    const [users, roles] = await Promise.all([
      this.fetchUsers(),
      this.fetchRoles(),
    ]);
    return {
      users,
      roles,
    };
  }

  async fetchUsers() {
    return this.props.fetchUsers();
  }

  async fetchRoles() {
    const { fetchRoles } = this.props;
    const { isAdvancedUserPermissions } = this.context;
    return (isAdvancedUserPermissions && fetchRoles()) || {};
  }

  async onSaveUser(userId, values) {
    const {
      client,
      updateUser,
      updateUserSelf,
      createUser,
      removeUser,
      updateClient,
    } = this.props;
    const { notifySuccess, notifyError, user } = this.context;
    const { users } = this.state;

    const userValues = {
      ...values,
      owner: undefined,
    };

    this.setState({ loading: true });

    let result;
    if (userId) {
      if (user.id === userId) {
        result = await updateUserSelf({
          ...userValues,
          all_permissions: undefined,
          permissions: undefined,
        });
      } else {
        const exUser = find(users.results, { id: userId });
        if (exUser.email !== userValues.email) {
          await removeUser(userId);
          result = await createUser({ ...userValues, id: undefined });
        } else {
          result = await updateUser(userId, {
            role_id: values.owner ? null : userValues.role_id,
            all_permissions: userValues.all_permissions,
            permissions: userValues.permissions,
            ...(userValues.$notify && {
              invite_key: userValues.invite_key,
              notify: userValues.notify,
              $notify: userValues.$notify,
            }),
          });
        }
      }
    } else {
      result = await createUser(userValues);
    }

    if (!result) {
      this.setState({ loading: false });
      return false;
    }

    if (result.error || result.errors) {
      notifyError(result.error || result.errors);
      this.setState({ loading: false });
      return false;
    }

    if (!userId) {
      segment.track('User invited user', {
        client_id: client.id,
        client_name: client.name,
        username: user.username,
        invited_email: userValues.email,
      });
    }

    // Change account owner
    if (values.owner || client.owner_id === result.id) {
      // Note: this also triggers user.updateAccountInfo via API
      const clientResult = await updateClient({ owner_id: result.id });
      const error = clientResult.error || clientResult.errors;

      if (error) {
        notifyError(error);
        this.setState({ loading: false });
        return false;
      }

      segment.track(
        'User switched owners',
        {
          client_id: client.id,
          client_name: client.name,
          username: user.username,
          new_owner_email: result.email,
        },
        {
          Salesforce: true,
        },
      );
    }

    await this.fetchUsers().then((result) =>
      this.setState({ users: { ...result }, loading: false }),
    );

    notifySuccess('User profile updated');

    return true;
  }

  async onRemoveUser(userId) {
    const { client, removeUser, router } = this.props;
    const result = await removeUser(userId);
    if (result.errors) {
      this.context.notifyError(result.errors);
    } else {
      segment.track('User removed user', {
        client_id: client.id,
        client_name: client.name,
        username: this.context.user.username,
        removed_email: result.email,
      });
      router.replace('/settings/users');
    }
  }

  onSubmitUser(values) {
    return this.onSaveUser(this.props.params.id, values);
  }

  async onInviteUser(userId, values) {
    const { user, client } = this.context;
    const { email } = values;
    const inviteKey = md5(email + Date.now());

    const result = await this.onSaveUser(userId, {
      ...values,
      invite_key: inviteKey,
      $notify: {
        id: 'invite',
        subject: `You're invited to join ${client.name}`,
        data: {
          store: {
            name: client.name,
          },
          invite_key: inviteKey,
          invite_url: `${window.location.origin}${BASE_URI}/join/${inviteKey}`,
          from_name: user.name,
          from_email: user.email,
        },
      },
    });

    if (result) {
      this.context.notifySuccess(`Invite sent to ${email}`);
      return true;
    }
  }

  onUploadPhoto(file) {
    const {
      params: { id },
    } = this.props;

    return this.context
      .uploadImages([file])
      .then((results) => results && this.onSaveUser(id, { photo: results[0] }));
  }

  async onCreateRole(role) {
    const result = await this.props.createRole(role);
    if (result.error || result.errors) {
      this.context.notifyError(result.error || result.errors);
      return false;
    }

    const roles = await this.fetchRoles();
    this.setState({ roles: { ...roles } });
    return true;
  }

  async onUpdateRole(role) {
    const result = await this.props.updateRole(role);
    if (result.error || result.errors) {
      this.context.notifyError(result.error || result.errors);
      return false;
    }
    return true;
  }

  async onDeleteRole(id) {
    const result = await this.props.deleteRole(id);
    if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }
    return true;
  }

  async onReassignRoles(id, reassignment) {
    await this.props.reassignRoles({ id, reassignment });
    return true;
  }

  renderPage() {
    const { section } = this.props.route;
    switch (section) {
      case 'users':
        return <Users {...this.props} {...this.state} />;
      case 'user':
        return <User {...this.props} {...this.state} />;
      case 'user-roles':
        return <UserRoles {...this.props} {...this.state} />;
      case 'user-role':
        return <UserRole {...this.props} {...this.state} />;
      default:
        return <UsersIndexPage {...this.props} {...this.state} />;
    }
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    if (this.props.section && !this.props.settings[this.props.section]) {
      return <NotFoundPage />;
    }

    return this.renderPage();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
