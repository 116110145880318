import React, { Fragment } from 'react';
import { get, orderBy, first } from 'lodash';
import { Form, Label } from 'components/form';
import Link from 'components/link';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import ButtonLink from 'components/button/link';
import DropdownButton from 'components/button/dropdown';
import ConsoleForm from './form';
import ConsoleResponse from './response';
import { singularize, isEmpty, classNames } from 'utils';
import { expandString } from 'utils/string';
import { VIEW_COLLECTIONS } from 'utils/content';
import './console.scss';

export default class ConsoleAPI extends React.PureComponent {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      editing: props.query && props.query.method ? false : true,
    };
  }

  onClickEditQuery = (event) => {
    event.preventDefault();
    this.setState({ editing: true });
    const uriRef = get(this.refs.consoleForm, 'refs.uri.refs.input');
    if (uriRef) {
      uriRef.focus();
    }
  };

  onClickRunQuery = (event) => {
    event.preventDefault();
    if (this.state.editing) {
      this.refs.form.submit();
    } else {
      this.props.onSubmitQuery(this.props.query);
    }
  };

  onKeyDownForm = (event) => {
    const { keyCode, ctrlKey, metaKey } = event;

    if ((ctrlKey || metaKey) && keyCode === 13) {
      event.preventDefault();
      this.refs.form.submit();
    }
  };

  getRequestId() {
    const { history } = this.props;
    const orderedHistory = orderBy(history, 'meta.created', 'desc');
    const historyItem = first(orderedHistory);
    return historyItem ? historyItem.id : '';
  }

  getResponseLinks($links, response, foundLinks = {}, path = '') {
    for (const key of Object.keys($links)) {
      const { url, links } = $links[key];
      const thisPath = path ? `${path}.${key}` : key;
      if (links && response[key]) {
        this.getResponseLinks(
          links['*'] || links,
          response[key],
          foundLinks,
          thisPath,
        );
        continue;
      }
      if (!url) {
        continue;
      }
      const thisUrl = expandString(url, response);
      if (thisUrl) {
        foundLinks[thisPath] = thisUrl;
      }
    }

    return foundLinks;
  }

  renderLinks() {
    const { headers, response } = this.props;

    if (!headers) {
      return null;
    }

    const recordId = this.getRecordId();
    if (!recordId) {
      return null;
    }

    const { $links } = headers;

    const allLinks = [
      { endpoint: 'events', uri: `?data.id=${recordId}`, path: 'events' },
    ];

    if ($links) {
      const refLinks = this.getResponseLinks($links, response);
      for (const path of Object.keys(refLinks)) {
        let [endpoint, ...uriParts] = refLinks[path]
          .replace(/^\//, '')
          .split('/');
        if (endpoint.indexOf('?') > 0) {
          const parts = endpoint.split('?');
          endpoint = parts[0];
          uriParts.push(`?${parts[1]}`);
        }
        allLinks.push({
          endpoint: encodeURIComponent(endpoint),
          uri: uriParts.join('/'),
          path,
        });
      }
    }

    return (
      <span className="note muted">
        &nbsp;&nbsp;&nbsp;&nbsp;
        <DropdownButton
          anchor="left"
          anchorPosition={42}
          type="sub"
          items={allLinks.map((link) => (
            <Link
              to={`${window.location.pathname}?get/${encodeURIComponent(
                link.endpoint,
              )}${link.uri ? `/${encodeURIComponent(link.uri)}` : ''}`}
            >
              <code>{link.path}</code>
            </Link>
          ))}
          button={false}
          arrow={false}
          className="console-link-dropdown"
        >
          Links <Icon fa="sort-down" faType="solid" />
        </DropdownButton>
      </span>
    );
  }

  renderViewLink() {
    const {
      query: { endpoint },
      response,
    } = this.props;

    const model = VIEW_COLLECTIONS[endpoint];
    const recordId = this.getRecordId();
    if (!recordId || !model) {
      return null;
    }

    let { url } = model;
    if (typeof url === 'function') {
      url = url(response);
    }

    url = expandString(url, response);

    let { name } = model;
    if (typeof name === 'function') {
      name = name(response);
    }

    return (
      url && (
        <span className="note">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Link to={url} className="muted">
            Go to {name || singularize(endpoint)} <Icon fa="external-link" />
          </Link>
        </span>
      )
    );
  }

  getRecordId() {
    const {
      query: { uri },
      response,
    } = this.props;

    const uriParts = uri.replace(/[?].*$/, '').split('/');

    if (uriParts.length !== 1 || !response || typeof response !== 'object') {
      return null;
    }

    const recordId =
      uriParts[0][0] && uriParts[0][0] !== ':' ? uriParts[0] : response.id;

    return recordId;
  }

  render() {
    const {
      edited,
      onSubmitQuery,
      onChangeForm,
      query,
      queryRan,
      queryTime,
      loading,
      ...props
    } = this.props;

    const { editing } = this.state;

    let isTruncated, uriTruncated, uriParts;
    if (query && query.uri) {
      uriTruncated = query.uri.substr(0, 60);
      isTruncated = uriTruncated.length < query.uri;
      uriParts = uriTruncated.split('/').filter((u) => u);
    }

    return (
      <div
        className={classNames('console-api', { 'console-loading': loading })}
      >
        <Form
          onSubmit={onSubmitQuery}
          onChange={onChangeForm}
          onKeyDown={this.onKeyDownForm}
          ref="form"
        >
          <fieldset className="full">
            {editing ? (
              <div className="box console-api-query-box">
                <div className="box-action">
                  <div className="console-action">
                    <ButtonLink
                      onClick={this.onClickRunQuery}
                      size="sm"
                      type="default"
                    >
                      Run query
                    </ButtonLink>
                  </div>
                </div>
                <ConsoleForm
                  {...props}
                  ref="consoleForm"
                  onChangeForm={onChangeForm}
                />
                {queryTime > 0 && (
                  <div className="console-view-wrapper">
                    <div className="note">
                      {`Executed in ${Number(queryTime / 1000).toFixed(
                        2,
                      )} seconds`}
                      {this.renderLinks()}
                      {this.renderViewLink()}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="box">
                <h4 className="box-title">
                  <code className="console-view-url">
                    {query.method.toUpperCase()} /
                    {uriParts && uriParts.length > 0 ? (
                      <Fragment>
                        <Link
                          to={`${
                            window.location.pathname
                          }?get/${encodeURIComponent(query.endpoint)}`}
                        >
                          {query.endpoint}
                        </Link>
                        <Tooltip message={isTruncated ? query.uri : undefined}>
                          {uriParts.map((part, i) => (
                            <Fragment key={part}>
                              {part[0] === '?' ? '' : '/'}
                              {i < uriParts.length - 1 ? (
                                <Link
                                  to={`${
                                    window.location.pathname
                                  }?get/${encodeURIComponent(
                                    query.endpoint,
                                  )}/${encodeURIComponent(
                                    i > 0
                                      ? `${uriParts
                                          .slice(0, i)
                                          .join('/')}/${part}`
                                      : part,
                                  )}`}
                                >
                                  {part}
                                </Link>
                              ) : (
                                <span>{part}</span>
                              )}
                            </Fragment>
                          ))}
                        </Tooltip>
                      </Fragment>
                    ) : (
                      <span>{query.endpoint}</span>
                    )}
                  </code>
                </h4>
                {queryTime && (
                  <div className="console-view-wrapper">
                    <div className="box-subtitle">
                      Executed in {Number(queryTime / 1000).toFixed(2)} seconds
                      {this.renderLinks()}
                      {this.renderViewLink()}
                    </div>
                  </div>
                )}
                <div className="box-action">
                  <Link onClick={this.onClickEditQuery}>Edit</Link>
                </div>
                {!isEmpty(query.body) && (
                  <pre className="console-body">
                    {JSON.stringify(query.body, null, 2)}
                  </pre>
                )}
                {!queryTime && (
                  <div className="console-action bottom">
                    <ButtonLink
                      onClick={this.onClickRunQuery}
                      size="sm"
                      type="secondary"
                    >
                      Run query
                    </ButtonLink>
                  </div>
                )}
              </div>
            )}
            {queryTime > 0 && (
              <>
                <Label label="Response" className="console-view-label" />
                <div className="console-box">
                  <ConsoleResponse {...this.props} />
                </div>
              </>
            )}
          </fieldset>
        </Form>
      </div>
    );
  }
}
