import React from 'react';
import { formatCurrencyRounded } from 'utils';
import Tooltip from 'components/tooltip';

/**
 * Prepares a tooltip with 3 provided lines
 * @param {string} itemTaxesText - first line text
 * @param {number} itemTaxes - first line value
 * @param {string} shippingTaxesText - second line text
 * @param {number} shippingTaxes - second line value
 * @param {string} totalTaxesText - third line text
 * @param {number} totalTaxes - third line value
 * @param {string} currency - currency
 * @return {JSX.Element} - prepared tooltip
 */
const getTaxesTooltip = (
  itemTaxesText,
  itemTaxes,
  shippingTaxesText,
  shippingTaxes,
  totalTaxesText,
  totalTaxes,
  currency,
) => {
  const message = (
    <div className="taxes-tooltip">
      <div className="taxes-tooltip-column-labels">
        <div>{itemTaxesText}</div>
        <div>{shippingTaxesText}</div>
        <div className="taxes-tooltip-bold">{totalTaxesText}</div>
      </div>
      <div className="taxes-tooltip-column-values">
        <div>{formatCurrencyRounded(itemTaxes || 0, currency)}</div>
        <div>{formatCurrencyRounded(shippingTaxes || 0, currency)}</div>
        <div className="taxes-tooltip-bold">
          {formatCurrencyRounded(totalTaxes || 0, currency)}
        </div>
      </div>
    </div>
  );

  return (
    <Tooltip message={message}>
      <span className="help icon icon-info">&nbsp;</span>
      &nbsp;&nbsp;
    </Tooltip>
  );
};

/**
 * Checks if tooltip should be rendered (do not render if order\subscription has no shipments)
 * @param {object} record - record with data
 * @param {boolean} isSubscription - record is a subscription
 * @param {boolean} subscriptionHasShipmentItems - indicates that subscription has shipment items. Ignored if isSubscription is false
 * @return {boolean} - true if should be rendered
 */
const shouldRenderTaxesTooltip = (
  record,
  isSubscription,
  subscriptionHasShipmentItems,
) => {
  if (isSubscription) {
    return subscriptionHasShipmentItems;
  }

  if (!record.shipment_delivery && (record.shipment_total || 0) <= 0) {
    return false;
  }

  return true;
};

/**
 * Prepares a tooltip with not included taxes
 * returns null if there is no shipment delivery
 * @param {object} record - record with data
 * @param {boolean} isSubscription - record is a subscription
 * @param {boolean} subscriptionHasShipmentItems - indicates that subscription has shipment items. Ignored if isSubscription is false
 * @param {string} currency - currency
 * @return {JSX.Element} - prepared tooltip
 */
export const getNotIncludedTaxesTooltip = (
  record,
  isSubscription,
  subscriptionHasShipmentItems,
  currency,
) => {
  // do not show tooltip if we do not have shipment delivery
  if (
    !shouldRenderTaxesTooltip(
      record,
      isSubscription,
      subscriptionHasShipmentItems,
    )
  ) {
    return null;
  }

  // different formulas for order and subscription
  let itemTaxIncludedTotal = 0;
  if (isSubscription && !record.tax_included) {
    itemTaxIncludedTotal =
      (record.item_tax || 0) + (record.product_tax_total || 0);
  }
  if (!isSubscription && !record.item_tax_included) {
    itemTaxIncludedTotal = record.item_tax;
  }

  return getTaxesTooltip(
    'Item tax',
    itemTaxIncludedTotal,
    'Shipping tax',
    !record.shipment_tax_included ? record.shipment_tax : 0,
    'Total tax',
    record.tax_included_total,
    currency,
  );
};

/**
 * Prepares a tooltip with included taxes
 * returns null if there is no shipment delivery
 * @param {object} record - record with data
 * @param {boolean} isSubscription - record is a subscription
 * @param {boolean} subscriptionHasShipmentItems - indicates that subscription has shipment items. Ignored if isSubscription is false
 * @param {number} includedInPriceItemTaxes - included in price item taxes
 * @param {number} includedInPriceShipmentTaxes - included in price shipment taxes
 * @param {number} includedInPriceTaxes - included in price total taxes
 * @param {string} currency - currency
 * @return {JSX.Element} - prepared tooltip
 */
const getIncludedTaxesTooltip = (
  record,
  isSubscription,
  subscriptionHasShipmentItems,
  includedInPriceItemTaxes,
  includedInPriceShipmentTaxes,
  includedInPriceTaxes,
  currency,
) => {
  // do not show tooltip if we do not have shipment delivery
  if (
    !shouldRenderTaxesTooltip(
      record,
      isSubscription,
      subscriptionHasShipmentItems,
    )
  ) {
    return null;
  }

  return getTaxesTooltip(
    'Tax included in item price',
    includedInPriceItemTaxes,
    'Tax included in shipping',
    includedInPriceShipmentTaxes,
    'Total tax included',
    includedInPriceTaxes,
    currency,
  );
};

/**
 * Prepares a footnote with included in price taxes
 * returns null if there is no in price included taxes
 * @param {object} record - record with data
 * @param {boolean} isSubscription - record is a subscription
 * @param {boolean} subscriptionHasShipmentItems - indicates that subscription has shipment items. Ignored if isSubscription is false
 * @param {string} currency - currency
 * @return {JSX.Element} - prepared tooltip
 */
export const getIncludedTaxesFootnote = (
  record,
  isSubscription,
  subscriptionHasShipmentItems,
  currency,
) => {
  // different formulas for order and subscription
  let includedInPriceItemTaxes = 0;
  if (isSubscription && record.tax_included) {
    includedInPriceItemTaxes =
      (record.item_tax || 0) + (record.product_tax_total || 0);
  }
  if (!isSubscription && record.item_tax_included) {
    includedInPriceItemTaxes = record.item_tax;
  }
  includedInPriceItemTaxes = includedInPriceItemTaxes || 0;

  const includedInPriceShipmentTaxes = record.shipment_tax_included
    ? record.shipment_tax || 0
    : 0;
  const includedInPriceTaxes =
    includedInPriceItemTaxes + includedInPriceShipmentTaxes;

  if (includedInPriceTaxes <= 0) {
    return null;
  }

  return (
    <span className="order-totals-value" role="note">
      <span>
        {getIncludedTaxesTooltip(
          record,
          isSubscription,
          subscriptionHasShipmentItems,
          includedInPriceItemTaxes,
          includedInPriceShipmentTaxes,
          includedInPriceTaxes,
          currency,
        )}
        Total also includes{' '}
        {formatCurrencyRounded(includedInPriceTaxes, currency)} in taxes
      </span>
    </span>
  );
};
