import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'components/form';
import Modal from 'components/modal';
import { FadeIn } from 'components/transitions';
import { formatDate } from 'utils';
import { getNextBillingDate } from 'utils/date';
import { combineDateTime } from 'utils';
import moment from 'moment';

export default class PauseSubscriptionModal extends React.PureComponent {
  static contextTypes = {
    closeModal: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const resumeDate = new Date(
      this.props.subscription.date_pause_end || undefined,
    );

    this.state = {
      loading: false,
      resumeDate,
      resumeTime: resumeDate,
      pauseMethod: 'pause_indefinitely',
      resumeWhen: 'immediately',
    };
    this.clickConfirm = this.clickConfirm.bind(this);
    this.getResumeDate = this.getResumeDate.bind(this);
  }

  /**
   * If pausing indefinitely, paused = true, date_pause_end = null
   * If pausing for a time period, paused = true, date_pause_end = date
   * if resuming immediately = paused = false, date_pause_end = null
   * If resuming date, paused = true, date_pause_end = date
   */
  clickConfirm(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const { subscription } = this.props;

    let paused = true;
    let date_pause_end;
    if (subscription.paused && this.state.resumeWhen === 'immediately') {
      paused = false;
      date_pause_end = null;
    }

    if (!subscription.paused) {
      date_pause_end = moment(
        getNextBillingDate(
          subscription.interval,
          subscription.date_period_start,
          subscription.interval_count + 1,
        ),
      ).utc();
      if (this.state.pauseMethod === 'pause_indefinitely') {
        date_pause_end = null;
      }
    } else {
      if (this.state.resumeWhen === 'date') {
        date_pause_end = this.getResumeDate();
        if (new Date(date_pause_end) < new Date()) {
          this.context.notifyError('Next invoice date must be in the future');
          this.setState({ loading: false });
          return;
        }
      }
    }
    Promise.resolve(
      this.props.onPauseSubscription(paused, date_pause_end),
    ).then((result) => {
      if (result !== false) {
        this.context.closeModal();
      } else {
        this.setState({ loading: false });
      }
    });
  }

  getResumeDate() {
    return combineDateTime(this.state.resumeDate, this.state.resumeTime);
  }

  render() {
    const { subscription } = this.props;
    const nextBillingDate = getNextBillingDate(
      subscription.interval,
      subscription.date_period_start,
      subscription.interval_count + 1,
    );

    return (
      <Modal
        title={`${this.props.pauseOrResume} subscription`}
        actions={[
          {
            label: `${this.props.pauseOrResume}`,
            type: 'danger',
            onClick: this.clickConfirm,
          },
        ]}
        loading={this.state.loading}
      >
        {!this.props.subscription.paused ? (
          <React.Fragment>
            <Field
              type="radio"
              options={[
                { value: 'pause_indefinitely', label: 'Pause indefinitely' },
                { value: 'skip_next', label: 'Skip next cycle' },
              ]}
              name="pauseMethod"
              onChange={(_e, pauseMethod) => {
                this.setState({ pauseMethod });
              }}
              defaultValue={this.state.pauseMethod}
            />
            {this.state.pauseMethod === 'skip_next' ? (
              <p>
                This will skip the next cycle of the subscription. The next
                billing date for this subscription is: <br />
                <strong>{formatDate(nextBillingDate, 'shortExact')}</strong>
              </p>
            ) : (
              <p>
                This subscription will be paused, and will not generate any
                orders or invoices while paused.
              </p>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Field
              type="radio"
              options={[
                { value: 'immediately', label: 'Resume immediately' },
                { value: 'date', label: 'Resume on date' },
              ]}
              name="resumeWhen"
              onChange={(_e, resumeWhen) => {
                this.setState({ resumeWhen });
              }}
              defaultValue={this.state.resumeWhen}
            />
            {this.state.resumeWhen === 'immediately' ? (
              <p>
                This subscription will be resumed, and the customer will be
                billed immediately.
              </p>
            ) : (
              <p>
                This subscription will be resumed on{' '}
                <strong>
                  {formatDate(this.getResumeDate(), 'shortExact')}
                </strong>
                . The customer will be billed on that date.
              </p>
            )}
            <FadeIn active={this.state.resumeWhen === 'date'}>
              <div className="row">
                <Field
                  type="date"
                  name="date_pause_end"
                  defaultValue={
                    this.props.subscription.date_pause_end ||
                    this.props.subscription.date_period_end
                  }
                  required={true}
                  className="span2"
                  onChange={(_e, resumeDate) => this.setState({ resumeDate })}
                />
                <Field
                  type="time"
                  name="date_pause_end_time"
                  defaultValue={
                    this.props.subscription.date_pause_end ||
                    this.props.subscription.date_period_end
                  }
                  required={true}
                  className="span2"
                  onChange={(_e, resumeTime) => this.setState({ resumeTime })}
                />
              </div>
            </FadeIn>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}
