import React from 'react';
import PropTypes from 'prop-types';

export default class PaymentMethodLogo extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  render() {
    const { id } = this.props;

    let icon;
    try {
      icon = require(`../icon/integration/${id}.svg`);
    } catch (err) {
      icon = require('../logo/swell-purple-logomark.svg');
    }

    return (
      <img src={icon} alt={id} className={`settings-integrations-icon ${id}`} />
    );
  }
}
