import React from 'react';
import { merge } from 'lodash';
import RecordForm from './record-form';
import Button from 'components/button';
import { Form, TabView } from 'components/form';
import AppIndicator from 'components/view/app-indicator';
import { normalizeCollectionTab } from 'utils/content';
import { STANDARD_CONTENT_VIEWS } from 'constants/content';

export default class ContentRecordTab extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getTabState(props),
      edited: {},
    };

    this.tabActive = {};
  }

  componentDidUpdate(prevProps) {
    const { record } = this.props;

    if (prevProps.record !== record) {
      this.setState({
        ...this.getTabState(this.props),
      });
    }
  }

  getTabState(props) {
    const { record, content, collection, view, contentTab } = props;
    if (contentTab) {
      return {};
    }

    const contentTabs = getContentTabs(content, collection, view);

    const allValues = {
      ...contentTabs.reduce(
        (acc, contentTab) => ({
          ...acc,
          [contentTab.id]: { ...(record || {}) },
        }),
        {},
      ),
    };

    return {
      values: allValues,
      contentTabs,
    };
  }

  onChangeTab = (contentId, isActive) => {
    const { onChangeTab } = this.props;

    if (!onChangeTab) {
      return;
    }

    const nextState = {
      ...this.tabActive,
      [contentId]: isActive,
    };

    onChangeTab(Object.values(nextState).some((isActive) => isActive));

    // Don't use state because these calls happen synchronously
    this.tabActive = nextState;
  };

  onChangeForm = (contentId, values, edited) => {
    const { onEditedForm } = this.props;

    if (onEditedForm) {
      onEditedForm(contentId, values, edited);
    }

    this.setState({
      values: { ...this.state.values, [contentId]: { ...values } },
      edited: {
        ...this.state.edited,
        [contentId]: edited,
      },
    });
  };

  onSubmitForm = async (values) => {
    const result = await this.props.onSubmitForm(values);
    if (result) {
      this.setState({ edited: false, values: { ...this.props.record } });
    }
  };

  render() {
    const { contentTab, location, form, onEditedForm } = this.props;
    const { contentTabs, values, edited } = this.state;

    if (contentTab) {
      return (
        <>
          <RecordForm
            {...this.props}
            view={contentTab.view}
            model={contentTab.model}
          />
          {contentTab.model?.app && <AppIndicator app={contentTab.model.app} />}
        </>
      );
    } else if (!contentTabs?.length) {
      return null;
    }

    return contentTabs.map((contentTab) => (
      <TabView
        value={location.query.tab}
        active={contentTab.id}
        key={contentTab.id}
        onChange={this.onChangeTab.bind(this, contentTab.id)}
      >
        <fieldset className="last-child content-record-tab">
          {form ? (
            <Form
              onChange={this.onChangeForm.bind(this, contentTab.id)}
              onSubmit={this.onSubmitForm.bind(this, values)}
            >
              <RecordForm
                {...this.props}
                values={values[contentTab.id]}
                view={contentTab.view}
                model={contentTab.model}
              />
              {!onEditedForm && (
                <Button
                  type="submit"
                  styleType={edited ? 'default' : 'secondary'}
                  disabled={!edited}
                >
                  Save changes
                </Button>
              )}
            </Form>
          ) : (
            <RecordForm
              {...this.props}
              values={values}
              view={contentTab.view}
              model={contentTab.model}
            />
          )}
        </fieldset>
        {contentTab.model?.app && <AppIndicator app={contentTab.model.app} />}
      </TabView>
    ));
  }
}

export function contentRecordSubmitAction(state, label = undefined) {
  const { edited, onSubmitForm /*, isActive */ } = state;

  const isEdited = Object.values(edited || {}).some((edited) => edited);

  if (!isEdited) {
    return null;
  }

  return {
    type: isEdited ? 'default' : 'secondary',
    label: label || 'Save changes',
    disabled: !isEdited,
    onClick: () => onSubmitForm(),
  };
}

export function contentRecordSubmitChangeHandlers(component, submitHandler) {
  const onSubmitForm = () => {
    const { contentRecordTab } = component.state;
    const allValues = merge(
      {},
      ...Object.values(contentRecordTab?.values || {}),
    );
    return submitHandler(allValues).then((success) => {
      if (success) {
        component.setState({
          contentRecordTab: { ...contentRecordTab, edited: {} },
        });
      }
    });
  };

  return {
    onSubmitForm,
    onChangeTab: (isActive) => {
      const { contentRecordTab } = component.state;
      component.setState({
        contentRecordTab: {
          ...contentRecordTab,
          isActive,
        },
      });
    },
    onEditedForm: (contentId, values, edited) => {
      const { contentRecordTab } = component.state;
      component.setState({
        contentRecordTab: {
          ...contentRecordTab,
          onSubmitForm,
          values: {
            ...contentRecordTab?.values,
            [contentId]: values,
          },
          edited: {
            ...contentRecordTab?.edited,
            [contentId]: edited,
          },
        },
      });
    },
  };
}

// Helper to get a content tab for a record view
export function getContentTabs(content, collection, view = 'edit') {
  const tabs = [];

  const contentModels = (content.models || []).filter(
    (contentModel) => contentModel.collection === collection,
  );

  const isStandard = Boolean(STANDARD_CONTENT_VIEWS[collection]);

  for (const contentModel of contentModels) {
    const contentViews = (contentModel.views || []).filter(
      (contentView) => !view || contentView.id === view,
    );
    for (const contentView of contentViews) {
      for (const contentTab of contentView.tabs || []) {
        if (!contentTab.id) {
          continue;
        }
        if (isStandard && contentTab.default) {
          continue;
        }
        if (!contentTab.fields) {
          continue;
        }
        const normalTab = normalizeCollectionTab(
          contentTab,
          contentView.type,
          contentModel,
          contentView.fields,
        );
        tabs.push({
          ...normalTab,
          id: `${contentModel.app_id || 'admin'}_${contentTab.id}`,
          // Attach the content view and model
          view: {
            ...contentView,
            app_id: contentModel.app_id,
            fields: normalTab.fields || [],
            contentModel,
          },
          model: contentModel,
        });
      }
    }
  }

  return tabs;
}
