import React from 'react';

import api from 'services/api';

import NotFoundPage from 'components/pages/error/404';
import ViewLoading from 'components/view/loading';

import Giftcards from './Giftcards';

export default class GiftcardProductCodes extends React.Component {
  state = { loaded: false, product: null };

  componentDidMount() {
    api
      .get('/data/products/{id}', {
        id: this.props.params.id,
      })
      .then((product) => {
        this.setState({ loaded: true, product });
      });
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    const { product } = this.state;

    if (!product) {
      return <NotFoundPage />;
    }

    return (
      <Giftcards
        {...this.props}
        ref="giftcards"
        sectionTitle={product.name}
        title="Gift card codes"
        detail={true}
        nested={true}
        uri="/giftcards"
        recordUri="/giftcards/{id}"
        query={{
          product_id: product.id,
        }}
        emptyAction={false}
      />
    );
  }
}
