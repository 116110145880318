import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import CollectionEmpty from 'components/collection/empty';
import { Form, Field, Button } from 'components/form';
import Modal from 'components/modal';
import SectionHeader from 'components/section-header';
import SSHKeyForm from './ssh-key-form';
import { formatDate } from 'utils';

export default class SSHKeys extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  state = {
    showAddModal: false,
    values: null,
  };

  componentWillReceiveProps(props) {
    if (props.location.hash && props.location.hash === '#new-ssh-key') {
      this.setState({
        showAddModal: true,
        values: {},
      });
    }
  }

  onClickAddKey = (event) => {
    event.preventDefault();
    this.setState({
      showAddModal: true,
      values: {},
    });
  };

  onCloseAddModal = (event) => {
    this.setState({ showAddModal: false });
  };

  onChangeForm = (values) => {
    const { public_key, name } = this.state.values;
    const shouldCheckKeyName =
      !name &&
      !values.name &&
      values.public_key &&
      !public_key &&
      public_key !== values.public_key;
    if (shouldCheckKeyName) {
      const parts = values.public_key.split(' ');
      if (parts[0] === 'ssh-rsa' && parts[1] && parts[2]) {
        values.name = parts.slice(2).join(' ').trim();
      }
    }
    this.setState({
      values: {
        ...this.state.values,
        ...values,
      },
    });
  };

  onSubmitForm = (values) => {
    this.props.onCreateSSHKey(values).then((success) => {
      if (success) {
        this.onCloseAddModal();
      }
    });
  };

  onClickRemoveKey = (event) => {
    event.preventDefault();
    const { id } = event.currentTarget.dataset;
    const key = find(this.props.sshKeys, { id });
    this.context.openModal('ConfirmDelete', {
      title: key.name || key.fingerprint,
      loadingTitle: 'Removing SSH key',
      onConfirm: async () => {
        const success = await this.props.onDeleteSSHKey(id);
        if (success) {
          this.setState({ showAddModal: false });
        }
      },
    });
  };

  renderAddModal() {
    const { loading } = this.props;
    const { values } = this.state;

    return (
      <Form onSubmit={this.onSubmitForm} onChange={this.onChangeForm}>
        <Modal
          title="Add SSH key"
          width={500}
          actions={[{ label: 'Add', type: 'submit' }]}
          className="modal-confirm"
          loading={loading}
          onClose={this.onCloseAddModal}
        >
          <fieldset>
            <p>
              For more information see{' '}
              <a
                href="https://help.github.com/articles/generating-a-new-ssh-key-and-adding-it-to-the-ssh-agent/"
                target="blank"
              >
                generating a new SSH key
              </a>
              .
            </p>
            <Field
              type="textarea"
              name="public_key"
              label="Public key"
              required={true}
              autoSize={true}
              rows={3}
              placeholder="ssh-rsa..."
            />
            <Field
              type="text"
              name="name"
              label="Key name"
              required={true}
              placeholder="user@host"
              defaultValue={values.name}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }

  render() {
    const { sshKeys } = this.props;

    return (
      <div className="view-body-subsection">
        <div className="view-body-subheader">
          <SectionHeader
            className="view-body-subtitle"
            title="SSH keys"
            subtitle="Manage SSH keys with access to a theme code repository"
          />
          {sshKeys.length > 0 && (
            <div className="view-body-actions">
              <Button onClick={this.onClickAddKey}>Add SSH key</Button>
            </div>
          )}
        </div>
        {sshKeys.length > 0 ? (
          <table className="collection-table outer">
            <thead>
              <tr>
                <th>Name</th>
                <th>Fingerprint</th>
                <th>User</th>
                <th>Added</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {sshKeys.map((key) => (
                <tr key={key.id}>
                  <td>{key.name || <span className="muted">&mdash;</span>}</td>
                  <td>{key.fingerprint}</td>
                  <td>
                    {key.user_name ? (
                      key.user_name
                    ) : (
                      <span className="muted">&mdash;</span>
                    )}
                  </td>
                  <td>{formatDate(key.date_created, 'age')}</td>
                  <td className="action">
                    <a href="" onClick={this.onClickRemoveKey} data-id={key.id}>
                      Remove
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="collection-table outer">
            <CollectionEmpty
              emptyTitle="SSH Keys"
              emptyDescription="Manage developer keys with access to customize themes."
              emptyAction="Add SSH Key"
              onClickAction={this.onClickAddKey}
            />
          </div>
        )}

        {this.state.showAddModal && (
          <SSHKeyForm
            {...this.props}
            onSubmitForm={this.onSubmitForm}
            onCloseModal={this.onCloseAddModal}
          />
        )}
      </div>
    );
  }
}
