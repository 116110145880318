import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, find, merge, isObject } from 'lodash';

import Modal from 'components/modal';
import Collection from 'containers/Collection';
import CollectionEmpty from 'components/collection/empty';
import { Form } from 'components/form';
import ContentFieldGroups from 'components/content/field-groups';

import { stringifyQuery } from 'utils';
import { confirmPageLeave } from 'utils/container';
import {
  contentFieldLabel,
  contentFieldPath,
  hasLocalizedFields,
  childCollectionExpandQuery,
  collectionLinkTarget,
  mergeLinkParams,
} from 'utils/content';

import actions from 'actions';

export const mapStateToProps = (state) => ({
  data: state.data,
  loading: state.data.loading,
  content: state.content,
  view: state.content.view,
  lookup: state.lookup,
  settings: state.settings,
  bulk: state.data.bulk,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchRecord(collection, id) {
    return dispatch(actions.content.fetchRecord(collection, id));
  },

  createRecord(collection, data) {
    return dispatch(actions.content.createRecord(collection, data));
  },

  updateRecord(collection, id, data) {
    return dispatch(actions.content.updateRecord(collection, id, data));
  },

  deleteRecord(collection, id) {
    return dispatch(actions.content.deleteRecord(collection, id));
  },

  getValuesWithData(collection, data) {
    return dispatch(actions.data.getValuesWithData(collection, data));
  },
});

export class ChildCollection extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    refreshModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    notifyCreated: PropTypes.func.isRequired,
    notifyDeleted: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  static propTypes = {
    parentRecord: PropTypes.object,
    contentView: PropTypes.object.isRequired,
    childCollection: PropTypes.string.isRequired,
    childCollectionModel: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      localized: false,
      initialData: this.getInitialData(props),
      initialValues: null,
      initialQuery: {},
      locationQuery: {},
      showForm: false,
      formLoading: false,
      record: null,
      values: null,
      edited: false,
      loaded: false,
    };
  }

  componentWillMount() {
    this.getInitialValues().then((initialValues) => {
      const { contentView } = this.props;

      const initialFields = contentView.fields.filter(
        (field) => !get(initialValues, field.id),
      );

      const initialQuery = this.getInitialQuery(this.props, initialFields);

      this.setState({
        initialQuery,
        initialValues,
        loaded: true,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleFormState(prevProps, prevState);
  }

  handleFormState(_prevProps, prevState) {
    const { id } = this.props;

    if (!prevState.showForm && this.state.showForm) {
      this.context.openModal(`content_collection_${id}`, this.renderForm);
    } else if (prevState.showForm && !this.state.showForm) {
      this.context.closeModal();
    } else if (this.state.showForm && this.state.values !== prevState.values) {
      // Only refresh modal when edit value changes
      // Using a time to prevent too frequent refreshes
      if (this.refreshModalTimer) {
        clearTimeout(this.refreshModalTimer);
      }
      this.refreshModalTimer = setTimeout(() => {
        this.context.refreshModal();
      }, 10);
    }

    confirmPageLeave(this, prevState);
  }

  getInitialData(props) {
    const data = {
      ...(props.parentRecord?.id
        ? {
            parent_id: props.parentRecord.id,
          }
        : {}),
      ...merge(
        isObject(props.link?.params)
          ? mergeLinkParams(props.link.params, props.record)
          : {},
        isObject(props.link?.data) ? props.link.data : {},
      ),
    };

    return data;
  }

  getInitialQuery(props, fields) {
    const expand = childCollectionExpandQuery({
      ...props.model,
      fields,
    });

    const query = {
      expand,
      ...(props.parentRecord?.id
        ? {
            parent_id: props.parentRecord.id,
          }
        : {}),
      ...merge(
        isObject(props.link?.params)
          ? mergeLinkParams(props.link.params, props.record)
          : {},
        isObject(props.link?.data) ? props.link.data : {},
      ),
    };

    return query;
  }

  async getInitialValues() {
    const { childCollection, getValuesWithData } = this.props;
    const { initialData } = this.state;

    const initialValues = await getValuesWithData(childCollection, initialData);

    return initialValues;
  }

  getFormFieldsByType(valueType = undefined, record = this.state.record) {
    const { item_types, fields, contentModel } = this.props;
    const { initialValues } = this.state;

    let fieldsByType = fields.filter((field) => !get(initialValues, field.id));

    // Append fields from item type
    if (item_types && valueType !== undefined) {
      const itemType = find(
        item_types,
        (type) => type === valueType || type.id === valueType,
      );
      if (itemType && itemType.fields) {
        fieldsByType = [...fieldsByType, ...itemType.fields].filter((x) => x);
      }
    }

    // Filter out readonly fields from new record
    if (!record?.id) {
      fieldsByType = fieldsByType
        .map((field) => ({
          ...field,
          fields: field.fields?.filter((f) => !f.readonly),
        }))
        .filter((field) => !field.readonly);
    }

    return {
      fields: fieldsByType.map((field) => ({
        field,
        model: contentModel,
        path: contentFieldPath(field, contentModel),
      })),
      localized: hasLocalizedFields(fieldsByType),
    };
  }

  renderFormFields = () => {
    const { path, readonly } = this.props;
    const { fields, record, values } = this.state;

    return (
      <>
        {fields.length > 0 ? (
          <ContentFieldGroups
            {...this.props}
            root={path}
            fields={fields}
            record={record}
            values={values}
            readonly={readonly}
          />
        ) : (
          <table className="collection-table outer headless">
            <tbody>
              <tr>
                <td align="center" className="muted">
                  No fields added yet
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </>
    );
  };

  renderForm = () => {
    const { record, values, localized, formLoading } = this.state;
    const {
      childCollection,
      childCollectionModel: { singularLower },
      readonly,
    } = this.props;

    const modalTitle = `${
      readonly ? 'View' : values?.id ? 'Edit' : 'New'
    } ${singularLower}`;

    return (
      <Form
        onSubmit={this.onSubmitForm}
        onChange={this.onChangeForm}
        values={values}
      >
        <Modal
          title={modalTitle}
          width={650}
          actions={
            readonly
              ? [
                  {
                    label: 'Close',
                    type: 'cancel',
                    onClick: this.onClickCloseForm,
                  },
                ]
              : [
                  {
                    label: `Save ${singularLower}`,
                    type: 'submit',
                  },
                  {
                    label: 'Cancel',
                    type: 'cancel',
                    onClick: this.onClickCloseForm,
                  },
                  values?.id && {
                    label: `Delete ${singularLower}`,
                    type: 'cancel-danger',
                    className: 'left',
                    onClick: this.onClickDelete,
                  },
                ]
          }
          cancel={false}
          loading={formLoading}
          onClose={this.onClickCloseForm}
          localized={localized}
          devtools={{
            model: childCollection,
            record,
          }}
        >
          {!formLoading && this.renderFormFields()}
        </Modal>
      </Form>
    );
  };

  onSubmitForm = async (values) => {
    const {
      createRecord,
      updateRecord,
      childCollection,
      childCollectionModel: { singular },
    } = this.props;
    const { notifyCreated, notifyError } = this.context;
    const { initialData } = this.state;

    let result;
    if (this.state.values?.id) {
      result = await updateRecord(
        childCollection,
        this.state.values?.id,
        values,
      );
    } else {
      result = await createRecord(childCollection, {
        ...values,
        ...initialData,
      });
    }

    if (result?.errors) {
      notifyError(result.errors);
    } else {
      notifyCreated(singular);
    }

    // Close and trigger collection reload
    this.setState({
      showForm: false,
      edited: false,
      locationQuery: { ...this.state.locationQuery },
    });
  };

  onChangeForm = (values, edited) => {
    this.setState({
      edited,
      values: { ...values },
      ...this.getFormFieldsByType(values.type),
    });
  };

  onClickCloseForm = () => {
    this.setState({ showForm: false, edited: false });
  };

  onClickAdd = (event) => {
    event.preventDefault();
    const { childCollection, childCollectionModel } = this.props;
    const { initialValues, initialData } = this.state;
    const { router } = this.context;

    if (childCollectionModel?.parent) {
      this.setState({
        showForm: true,
        edited: false,
        record: null,
        values: initialValues,
        ...this.getFormFieldsByType(undefined, null),
      });
      return false;
    }

    const nextUri = `/collections/${childCollection.replace(
      /\//g,
      '_',
    )}/new${stringifyQuery({ record: initialData })}`;

    router.push(nextUri);
  };

  onClickRow = (record) => {
    const { childCollectionModel } = this.props;
    const { initialValues } = this.state;

    if (childCollectionModel?.parent) {
      this.setState({
        showForm: true,
        edited: false,
        record,
        values: { ...record, ...initialValues },
        ...this.getFormFieldsByType(undefined, record),
      });
      return false;
    }
  };

  onQuery = (locationQuery) => {
    this.setState({ locationQuery });
  };

  onClickDelete = (event) => {
    event.preventDefault();
    const {
      childCollectionModel: { singularLower },
      childCollection,
      deleteRecord,
    } = this.props;
    const { values } = this.state;
    const { openModal, notifyDeleted } = this.context;

    openModal('ConfirmDelete', {
      title: `this ${singularLower}`,
      onConfirm: async () => {
        const result = await deleteRecord(childCollection, values.id);
        if (!result?.errors) {
          this.setState({
            showForm: false,
            locationQuery: { ...this.state.locationQuery },
          });
          notifyDeleted(singularLower);
        }
      },
    });
  };

  render() {
    const {
      label,
      rawLabel,
      contentView,
      childCollection,
      childCollectionModel: { plural, pluralLower, singularLower },
      readonly,
    } = this.props;
    const { location, router } = this.context;
    const { initialQuery, initialValues, locationQuery, loaded } = this.state;

    if (!loaded) {
      return null;
    }

    const fieldLabel = contentFieldLabel({
      ...this.props,
      label: rawLabel || label,
    });

    const listFields = contentView.fields.filter(
      (field) => !get(initialValues, field.id),
    );

    return (
      <div className="collection-child">
        <Collection
          {...this.props}
          title={plural}
          headerTitle={fieldLabel}
          headerActions={[
            !readonly && {
              label: `New ${singularLower}`,
              onClick: this.onClickAdd,
            },
          ]}
          uri={collectionLinkTarget({ collection: childCollection })}
          view={contentView}
          fields={listFields}
          model={childCollection}
          childId={childCollection}
          location={location}
          router={router}
          query={initialQuery}
          locationQuery={locationQuery}
          onQuery={this.onQuery}
          onClickRow={this.onClickRow}
          showApp={false}
          renderEmpty={({ collectionEmpty }) => {
            return (
              <CollectionEmpty
                title={plural}
                className="with-query"
                emptyTitle={false}
                emptyDescription={
                  <span>
                    No {pluralLower} {locationQuery.search ? 'found' : 'yet'}
                  </span>
                }
                emptyAction={readonly || !collectionEmpty ? false : undefined}
                onClickAction={this.onClickAdd}
                emptyActionSize="sm"
              />
            );
          }}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildCollection);
