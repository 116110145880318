import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

const duration = 300;
const easeOutQuart = 'cubic-bezier(0.165, 0.84, 0.44, 1)';

export default function MoveUp({ children, distance = 70, ...props }) {
  const durationExt = props.duration || duration;

  const transitionStyles = {
    defaultStyle: { transform: `translate(0, ${distance}px)` },
    enterStyle: {
      transform: transit('translate(0, 0)', durationExt, easeOutQuart),
    },
    leaveStyle: {
      transform: transit(
        `translate(0, ${distance}px)`,
        durationExt,
        easeOutQuart,
      ),
    },
    activeStyle: { transform: 'translate(0, 0)' },
    transitionAppear: true,
    active: true,
  };

  return (
    <CSSTransition {...transitionStyles} {...props}>
      {children}
    </CSSTransition>
  );
}
