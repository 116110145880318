import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrencyRounded } from 'utils';
import { writeToClipboard } from 'utils/clipboard';
import {
  makePurchaseLinkUrl,
  getDomainFromSettings,
} from 'utils/purchase-link';

import SectionHeader from 'components/section-header';
import Icon from 'components/icon';
import Link from 'components/link';
import Button from 'components/button';
import Status from 'components/status';

export default class ProductPurchaseLinks extends React.PureComponent {
  static contextTypes = {
    notifySuccess: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  static propTypes = {
    currency: PropTypes.string,

    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        grand_total: PropTypes.number.isRequired,
        items: PropTypes.array.isRequired,
        active: PropTypes.bool.isRequired,
      }),
    ).isRequired,

    settings: PropTypes.object.isRequired,

    onClickCreatePurchaseLink: PropTypes.func.isRequired,
    showSectionHeader: PropTypes.bool,
  };

  onClick = (event) => {
    const { url } = event.currentTarget.dataset;

    writeToClipboard(url)
      .then(() => {
        this.context.notifySuccess('Purchase link copied to clipboard');
      })
      .catch(() => {
        this.context.notifyError(
          'Error adding link to clipboard. Please select the link text and copy it manually',
        );
      });
  };

  render() {
    const {
      items,
      currency,
      onClickCreatePurchaseLink,
      showSectionHeader = true,
    } = this.props;

    if (items.length <= 0) {
      return null;
    }

    const domain = getDomainFromSettings(this.props.settings.purchaselinks);

    return (
      <>
        <div className="view-body-section">
          {showSectionHeader && (
            <div className="view-body-subheader">
              <SectionHeader
                className="view-body-subtitle"
                title="Purchase links"
                subtitle="Create links to send customers directly to checkout with pre-configured items"
              />
              <div className="view-section-action-group">
                <button
                  className="button button-secondary button-sm form-fieldlist-add form-link"
                  type="button"
                  onClick={onClickCreatePurchaseLink}
                >
                  New purchase link
                </button>
              </div>
            </div>
          )}

          <section data-testid="ProductPurchaseLinks">
            <div className="view-section-content collection-full">
              <div className="collection-table-container">
                <table className="collection-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Link</th>
                      <th>Status</th>
                      <th>Items</th>
                      <th className="currency">Item total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {items.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <Link to={`/purchase-links/${item.id}`}>
                              {item.name}
                            </Link>
                          </td>

                          <td>
                            <Button
                              data-url={makePurchaseLinkUrl(item.id, domain)}
                              styleType="secondary"
                              className="product-purchase-link-button"
                              title={makePurchaseLinkUrl(item.id, domain)}
                              onClick={this.onClick}
                              type="button"
                              size="xs"
                            >
                              <Icon fa="copy" /> Copy link
                            </Button>
                          </td>

                          <td>
                            {item.active ? (
                              <Status type="positive">Active</Status>
                            ) : (
                              <Status type="muted">Inactive</Status>
                            )}
                          </td>

                          <td>{item.items.length}</td>

                          <td className="currency">
                            {formatCurrencyRounded(item.grand_total, currency)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
