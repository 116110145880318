import React from 'react';
import { imageUrl } from 'utils';
import { getNameFields } from 'actions/user';
import Avatar from 'boring-avatars';
import './store.scss';

export default class StoreIcon extends React.PureComponent {
  renderWithText(name, text, width, height) {
    return (
      <span className="store-icon-initials">
        <span className="store-icon-initials-bg">
          <Avatar
            size={width}
            square={true}
            name={name}
            variant="marble"
          />
        </span>
        {this.props.text !== false && (
          <span
            className="store-icon-initials-inner"
            style={{ width, height, lineHeight: `${height}px` }}
          >
            {text}
          </span>
        )}
      </span>
    );
  }

  render() {
    const { text, client, store, auth, size = 48 } = this.props;
    const { width = size, height = size } = this.props;

    const storeObject =
      store ||
      client ||
      (auth && {
        name: auth.client_name,
        id: auth.client_id,
        icon: auth.client_icon,
      });

    const name = text || storeObject?.name;
    const nameInitials = !text && getNameFields(storeObject).nameInitials;

    return (
      <div
        className={`store-icon ${storeObject?.icon?.url ? 'has-image' : ''}`}
        style={{ width, height }}
      >
        {storeObject?.icon?.url ? (
          <img
            src={imageUrl(storeObject.icon, {
              width: width * 2,
              height: height * 2,
            })}
            alt={storeObject.name}
            style={{ width, height }}
          />
        ) : (
          this.renderWithText(name, text || nameInitials, width, height)
        )}
      </div>
    );
  }
}
