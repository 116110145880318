import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { map } from 'lodash';
import {
  Field,
  FieldAmount,
  FieldLocalized,
  LookupProduct,
  LookupCategory,
  LookupCategories,
} from 'components/form';
import Icon from 'components/icon';
import { FadeIn } from 'components/transitions';
import BuyGetRuleForm from './buy-get-rule-form';

const DISCOUNT_TYPES_BUTTONS = {
  total: {
    faIcon: 'shopping-cart',
    label: 'Order subtotal',
    desc: 'Apply discount to the entire order subtotal',
  },
  shipment: {
    faIcon: 'truck',
    label: 'Shipping',
    desc: 'Apply discount to the price of shipping',
  },
  product_category: {
    faIcon: 'cube',
    label: 'Product or category',
    desc: 'Discount a specific product or category',
  },
  buy_get: {
    faIcon: 'exchange',
    label: 'Buy X get Y',
    desc: 'Discount specific items when others are purchased',
  },
};

const DISCOUNT_TYPES_MODAL = [
  { value: 'total', label: 'Order total' },
  { value: 'shipment', label: 'Shipping' },
  { value: 'product', label: 'Product' },
  { value: 'category', label: 'Category' },
  { value: 'buy_get', label: 'Buy X get Y' },
];

// Not pure
export default class DiscountRuleForm extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  state = {
    mounted: false,
  };

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    const {
      record = {},
      values = {},
      path = '',
      categories,
      lookup,
      settings,
      modal = false,
    } = this.props;
    const { mounted } = this.state;

    const { client } = this.context;
    const currency = record.currency || client.currency;

    let inputType = values.type_input || values.type || 'total';
    let valueType = values.type || inputType;
    if (
      !values.type_input &&
      (values.type === 'product' || values.type === 'category')
    ) {
      inputType = 'product_category';
    } else if (inputType && inputType !== 'product_category') {
      valueType = inputType;
    }

    const discountTypeField = (
      <Field
        name={modal ? `${path}type` : `${path}type_input`}
        label="Discount type"
        defaultValue={modal ? valueType : inputType}
        readonly={true}
        {...(modal
          ? {
              type: 'select',
              className: 'span2',
              options: DISCOUNT_TYPES_MODAL,
            }
          : {
              type: 'radio',
              buttonsWithIcons: true,
              className: 'span4 discount-field-type',
              options: map(DISCOUNT_TYPES_BUTTONS, (type, value) => ({
                value,
                label: (
                  <div className="form-radio-button-wrapper">
                    <div className="label">
                      <Icon
                        fa={type.faIcon || 'cube'}
                        faType={type.faType || 'light'}
                      />
                      {type.label}
                    </div>
                    <span className="description muted">{type.desc}</span>
                  </div>
                ),
              })),
            })}
      />
    );

    const amountField = valueType !== 'buy_get' && (
      <FieldAmount
        label="Amount"
        typeName={`${path}value_type`}
        fixedName={`${path}value_fixed`}
        fixedValue={`fixed`}
        percentName={`${path}value_percent`}
        percentValue={`percent`}
        defaultValueType={values.value_type}
        defaultValueFixed={values.value_fixed || values.value}
        defaultValuePercent={values.value_percent}
        currency={currency}
        localized={true}
        localeValue={values.$currency}
        localeFieldPrefix={path.replace('.', '')}
        localeFieldName="value_fixed"
        className="span2"
        selectFocus={true}
        required={true}
      />
    );

    return (
      <Fragment>
        <Field type="hidden" name={`${path}type`} value={valueType} />
        {modal ? (
          <div className="row">
            {discountTypeField}
            {amountField}
          </div>
        ) : (
          <Fragment>
            <div className="row">{discountTypeField}</div>
            <div className="row">{amountField}</div>
          </Fragment>
        )}
        {modal ? (
          valueType === 'product' ? (
            <FadeIn>
              <LookupProduct
                name={`${path}product`}
                label="Product"
                defaultValue={values.product}
                lookup={lookup}
                required={true}
              />
            </FadeIn>
          ) : (
            valueType === 'category' && (
              <FadeIn>
                <LookupCategory
                  name={`${path}category`}
                  label="Category"
                  defaultValue={values.category}
                  categories={categories}
                  required={true}
                />
              </FadeIn>
            )
          )
        ) : (
          inputType === 'product_category' && (
            <FadeIn>
              <div className="lookup-radio-wrapper">
                <Field
                  type="radio"
                  name={`${path}type`}
                  defaultValue={valueType}
                  options={[
                    { value: 'product', label: 'Product' },
                    { value: 'category', label: 'Category' },
                  ]}
                  className="snug"
                />
                {valueType === 'category' ? (
                  <LookupCategory
                    name={`${path}category`}
                    defaultValue={values.category}
                    categories={categories}
                    required={true}
                  />
                ) : (
                  <LookupProduct
                    name={`${path}product`}
                    defaultValue={values.product}
                    lookup={lookup}
                    required={true}
                  />
                )}
              </div>
            </FadeIn>
          )
        )}
        {(valueType === 'category' || valueType === 'total') && (
          <Fragment>
            <Field
              type="checkbox"
              name={`${path}check_exclude_categories`}
              label="Exclude categories"
              defaultChecked={values.check_exclude_categories}
              className="snug"
            />
            {values.check_exclude_categories && (
              <FadeIn transitionAppear={mounted}>
                <div className="row indented">
                  <LookupCategories
                    name={`${path}exclude_categories`}
                    defaultValue={values.exclude_categories}
                    categories={categories}
                    autoFocus={true}
                    required={true}
                    className="span4"
                  />
                </div>
              </FadeIn>
            )}
          </Fragment>
        )}
        {valueType !== 'buy_get' && (
          <Field
            type="checkbox"
            name={`${path}check_total_min`}
            label="Require minimum order subtotal"
            defaultChecked={values.check_total_min}
            className="snug"
          />
        )}
        {valueType !== 'buy_get' && values.check_total_min && (
          <FadeIn transitionAppear={mounted}>
            <div className="row indented">
              <FieldLocalized
                type="currency"
                name={`${path}total_min`}
                defaultValue={values.total_min}
                currency={currency}
                localeValue={values.$currency}
                localeFieldPrefix={path.replace('.', '')}
                localeFieldName="total_min"
                required={true}
                selectFocus={true}
                autoFocus={true}
                className="span1 snug2"
              />
            </div>
          </FadeIn>
        )}
        {values.value_type === 'percent' && (
          <Field
            type="checkbox"
            name={`${path}check_discount_max`}
            label="Up to maximum discount amount per product"
            defaultChecked={values.check_discount_max}
            className="snug"
          />
        )}
        {values.check_discount_max && values.value_type === 'percent' && (
          <FadeIn transitionAppear={mounted}>
            <div className="row indented">
              <FieldLocalized
                type="currency"
                name={`${path}discount_max`}
                defaultValue={values.discount_max}
                currency={currency}
                localeValue={values.$currency}
                localeFieldPrefix={path.replace('.', '')}
                localeFieldName="discount_max"
                required
                selectFocus
                autoFocus
                className="span1 snug2"
              />
            </div>
          </FadeIn>
        )}
        {values.type === 'shipment' && (
          <Fragment>
            <Field
              type="checkbox"
              name={`${path}check_shipment_service`}
              label="Apply to specific shipping method"
              defaultChecked={values.check_shipment_service}
              className="snug"
            />
            {values.check_shipment_service && (
              <FadeIn transitionAppear={mounted}>
                <div className="row indented">
                  <Field
                    type="select"
                    name={`${path}shipment_service`}
                    defaultValue={values.shipment_service || ''}
                    options={settings.shipments.services.filter(
                      (service) => !!service.enabled,
                    )}
                    placeholder="Choose one"
                    required={true}
                    className="span2 snug2"
                  />
                </div>
              </FadeIn>
            )}
          </Fragment>
        )}
        {valueType === 'category' && (
          <Fragment>
            <Field
              type="checkbox"
              name={`${path}check_price_min`}
              label="Require minimum product price"
              defaultChecked={values.check_price_min}
              className="snug"
            />
            {values.check_price_min && (
              <FadeIn transitionAppear={mounted}>
                <div className="row indented">
                  <FieldLocalized
                    type="currency"
                    name={`${path}price_min`}
                    defaultValue={values.price_min}
                    currency={currency}
                    localeValue={values.$currency}
                    selectFocus={true}
                    autoFocus={true}
                    required={true}
                    className="span1 snug2"
                  />
                </div>
              </FadeIn>
            )}
          </Fragment>
        )}
        {(valueType === 'product' || valueType === 'category') && (
          <div>
            <Field
              type="checkbox"
              name={`${path}check_quantity_min`}
              label="Require minimum product quantity"
              defaultChecked={values.check_quantity_min}
              className="snug"
            />
            {values.check_quantity_min && (
              <FadeIn transitionAppear={mounted}>
                <div className="row indented">
                  <Field
                    type="number"
                    name={`${path}quantity_min`}
                    defaultValue={values.quantity_min}
                    error={
                      values.quantity_min > values.quantity_max &&
                      'Please enter a value lower than the maximum value'
                    }
                    selectFocus={true}
                    autoFocus={true}
                    required={true}
                    placeholder="0"
                    className="span1 snug2"
                  />
                </div>
              </FadeIn>
            )}
          </div>
        )}
        <div>
          <Field
            type="checkbox"
            name={`${path}check_quantity_max`}
            label="Up to maximum product quantity"
            defaultChecked={values.check_quantity_max}
            className="snug"
          />
          {values.check_quantity_max && (
            <FadeIn transitionAppear={mounted}>
              <div className="row indented">
                <Field
                  type="number"
                  name={`${path}quantity_max`}
                  defaultValue={values.quantity_max}
                  error={
                    values.quantity_min > values.quantity_max &&
                    'Please enter a value higher than the minimum value'
                  }
                  selectFocus={true}
                  autoFocus={true}
                  required={true}
                  placeholder="0"
                  className="span1 snug2"
                />
              </div>
            </FadeIn>
          )}
        </div>
        {valueType === 'product' && (
          <Fragment>
            <Field
              type="checkbox"
              name={`${path}check_quantity_add`}
              label="Add product to cart when applied"
              defaultChecked={values.check_quantity_add}
              className="snug"
            />
            {values.check_quantity_add && (
              <Field
                type="hidden"
                name={`${path}quantity_add`}
                value={
                  values.quantity_add !== undefined ? values.quantity_add : 1
                }
              />
            )}
          </Fragment>
        )}
        {valueType === 'buy_get' && <BuyGetRuleForm {...this.props} />}
      </Fragment>
    );
  }
}
