import React from 'react';
import PropTypes from 'prop-types';

import { Form, Field } from 'components/form';
import Modal from 'components/modal';

import PurchaseOptions from 'components/purchase-options';
import ProductOptionInputs from 'components/pages/product/option-inputs';

import './product.scss';

export default class ModalPurchaseLink extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  state = {
    values: {},
    edited: false,
  };

  onCloseModal = (event) => {
    if (this.state.edited) {
      event.preventDefault();

      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.props.onClose();
        },
      });
    } else {
      this.props.onClose();
    }
  };

  onChangeForm = (values, edited) => {
    this.setState((state) => ({ values: { ...state.values, ...values }, edited }));
  };

  onChangePurchaseOptionType = (type, _event, value) => {
    if (value && type) {
      this.setState((state) => ({
        values: {
          ...state.values,
          purchase_option: {
            ...state.values.purchase_option,
            type,
          },
        },
      }));
    }
  };

  render() {
    const { record, loading, onSubmit } = this.props;

    const { client } = this.context;

    const currency = record.currency || client.currency;

    return (
      <Form onSubmit={onSubmit} onChange={this.onChangeForm} autoFocus={true}>
        <Modal
          title="New purchase link"
          className="product-modal-variant-edit"
          actions={[
            { label: 'Create', type: 'submit' },
            {
              label: 'Cancel',
              type: 'secondary',
              className: 'button-cancel',
              onClick: this.onCloseModal,
            },
          ]}
          cancel={false}
          onClose={this.onCloseModal}
          width={800}
          loading={loading}
          localized={true}
        >
          <div className="row">
            <Field
              name="name"
              type="text"
              hint="Unique name of the purchase link"
              label="Title"
              required
              className="span4"
            />
          </div>

          <ProductOptionInputs key={record.id} product={record} values={this.state.values} />

          <PurchaseOptions
            product={record}
            pricedItem={record}
            currency={currency}
            values={this.state.values}
            onChangePurchaseOptionType={this.onChangePurchaseOptionType}
          />
        </Modal>
      </Form>
    );
  }
}
