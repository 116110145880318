import React from 'react';
import Icon from 'components/icon';
import { get, reduce, find, uniq } from 'lodash';
import User from '../utils/user';
import Date from '../utils/date';
import Shipment from '../utils/shipment';

export default function ShipmentUpdated({ event, record }) {
  const shipmentItems = get(event, 'data.items');
  const items = uniq(
    reduce(
      shipmentItems,
      (acc, shipmentItem) => {
        if (shipmentItem) {
          let item = find(record.items, { id: shipmentItem.order_item_id });
          if (item && shipmentItem.bundle_item_id) {
            item = find(item.bundle_items, { id: shipmentItem.bundle_item_id });
          }
          if (item) {
            acc.push({ ...item, quantity: shipmentItem.quantity });
          }
        }
        return acc;
      },
      [],
    ),
    'id',
  );

  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/shipment-updated" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          Shipment updated
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
        <Shipment event={event} items={items} />
      </div>
    </div>
  );
}
