import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import Loading from 'components/loading';
import ButtonLink from 'components/button/link';
import fileDownload from 'react-file-download';
import { FadeIn } from 'components/transitions';
import { formatDate } from 'utils';
import { isSearching } from 'utils/collection';

export default class OrderExport extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  state = {};
  exportLabels = {};
  exportOptions = [];
  formatOptions = [
    { value: 'csv', label: 'CSV' },
    { value: 'json', label: 'JSON' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      completedTime: null,
      defaultType: this.getDefaultType(props),
    };
    this.onClose = this.onClose.bind(this);
    this.onClickDownload = this.onClickDownload.bind(this);

    this.exportLabels = props.exportLabels || {
      page: 'Current page',
      search: 'Current search',
      all: `All orders`,
      selected: `Selected orders`,
    };

    if (props.exportOptions) {
      this.exportOptions = props.exportOptions;
    } else {
      this.exportOptions = [{ value: 'all', label: this.exportLabels.all }];
      if (props.data && isSearching(props.router, props.data)) {
        this.exportOptions.push({
          value: 'search',
          label: this.exportLabels.search,
        });
      }
      this.exportOptions.push({ value: 'page', label: this.exportLabels.page });
    }

    if (props.data && props.data.selection.count > 0) {
      this.exportOptions.push({
        value: 'selected',
        label: this.exportLabels.selected,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      export: { complete, data, filename, mimetype },
    } = nextProps;
    if (complete && !this.props.export.complete) {
      this.setState({ completedTime: Date.now() });
      fileDownload(data, filename, mimetype);
    }
  }

  getDefaultType(props) {
    if (props.data.selection.count > 0) {
      return 'selected';
    }
    if (isSearching(props.router, props.data)) {
      return 'search';
    }
    return 'all';
  }

  onClickDownload(event) {
    const {
      export: { data, filename, mimetype },
    } = this.props;
    event.preventDefault();
    fileDownload(data, filename, mimetype);
  }

  onClose(event) {
    const {
      export: { running },
    } = this.props;
    if (running) {
      this.context.openModal('Confirm', {
        title: 'Cancel export',
        message: 'Are you sure you want to cancel this export?',
        action: 'Confirm',
        actionType: 'danger',
        onConfirm: () => {
          this.props.onClickExportCancel(event);
          this.context.closeModal();
        },
      });
    } else {
      this.props.onClickExportReset(event);
      this.props.onClickExport(event);
    }
  }

  render() {
    const {
      export: { running, complete, percent, params },
      onSubmitExport,
      onClickExportReset,
    } = this.props;

    return (
      <Form onSubmit={onSubmitExport} autoFocus={true}>
        <Modal
          title="Export orders"
          className="order-modal-export"
          width={475}
          actions={[
            !running && !complete ? { label: 'Export', type: 'submit' } : null,
            !complete
              ? { label: 'Cancel', type: 'cancel', onClick: this.onClose }
              : null,
            complete
              ? { label: 'Close', type: 'default', onClick: this.onClose }
              : null,
            complete
              ? { label: 'Back', type: 'cancel', onClick: onClickExportReset }
              : null,
          ]}
          cancel={false}
          onClose={this.onClose}
        >
          <fieldset>
            {running ? (
              <FadeIn
                key="progress"
                active={true}
                transitionAppear={true}
                className="order-export-loading"
              >
                <Loading />
                <div className="note">{percent}%</div>
              </FadeIn>
            ) : (
              complete && (
                <FadeIn
                  key="complete"
                  active={true}
                  transitionAppear={true}
                  className="order-export-complete"
                >
                  <div className="note">
                    Downloaded <strong>{this.exportLabels[params.type]}</strong>{' '}
                    in <strong>{params.format.toUpperCase()}</strong> at{' '}
                    {formatDate(this.state.completedTime, 'time')}
                  </div>
                  <ButtonLink
                    onClick={this.onClickDownload}
                    type="secondary"
                    size="sm"
                  >
                    Download Again
                  </ButtonLink>
                </FadeIn>
              )
            )}
            <div
              style={running || complete ? { display: 'none' } : {}}
              className="row"
            >
              <Field
                type="radio"
                label="Export"
                name="type"
                defaultValue={this.state.defaultType}
                options={this.exportOptions}
                stacked={true}
                required={true}
                className="span2"
              />
              <Field
                type="radio"
                label="Format"
                name="format"
                options={this.formatOptions}
                stacked={true}
                required={true}
                className="span2"
              />
            </div>
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
