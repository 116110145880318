import React from 'react';
import pt from 'prop-types';

import { wordify } from 'shared/utils';
import { modelEndpoint, countModelFields } from 'utils/model';
import { contentCollectionLabel, countContentFields } from 'utils/content';

import Table from 'components/collection/table';
import Tooltip from 'components/tooltip';

import AppIcon from './icon';

export default class AppConfigs extends React.PureComponent {
  static propTypes = {
    app: pt.object.isRequired,
    content: pt.object.isRequired,
    appConfigs: pt.object.isRequired,
    installedApp: pt.object.isRequired,
  };

  getConfigsByType() {
    const { app, content, appConfigs } = this.props;
    const configs = appConfigs.results.reduce((acc, config) => {
      if (!acc[config.type]) {
        acc[config.type] = [];
      }
      acc[config.type].push(config);
      return acc;
    }, {});

    // Get more details from content models
    if (content.modelsByApp[app.id]) {
      configs.content = content.modelsByApp[app.id];
    }

    return configs;
  }

  renderCollectionLabel = (label, endpoint, isAppCollection) => {
    const {
      app,
      installedApp: {
        app: { slug_id },
      },
    } = this.props;

    return (
      <>
        {isAppCollection ? (
          <>
            <div className="apps-configs-collection-label">
              <b>{label}</b>
            </div>

            <code className="muted apps-configs-collection-endpoint">
              <AppIcon image={app.logo_icon} name={app.name} size={16} />
              <Tooltip message={`/${endpoint.replace(app.id, slug_id)}`}>
                {endpoint.replace(`apps/${app.id}/`, '/')}
              </Tooltip>
            </code>
          </>
        ) : (
          <>
            <div className="apps-configs-collection-label">
              <b>{label}</b>
            </div>
            <code className="muted apps-configs-collection-endpoint">
              {endpoint}
            </code>
          </>
        )}
      </>
    );
  };

  renderDataModelName = ({ values }) => {
    const {
      content: { collections },
      app,
    } = this.props;

    const appCollection = `apps/${app.id}/${values.name}`;
    const isAppCollection = Boolean(collections[appCollection]);

    const label = contentCollectionLabel(values);
    const endpoint = isAppCollection ? appCollection : modelEndpoint(values);
    return this.renderCollectionLabel(label, endpoint, isAppCollection);
  };

  renderContentModelName = (model) => {
    // The structure of the "model" parameter depends on "this.props.content.modelsByApp"
    // If we have data in "modelsByApp", then they will be used
    // Otherwise, data from "appConfigs" will be used, which differ significantly in structure
    // We must take this into account so that the UI does not crash during rendering
    const collection = model.values?.collection || model.collection;

    const isAppCollection = collection.startsWith('apps/');
    const endpoint = isAppCollection ? collection : `/${collection}`;
    const label = wordify(model.slug);

    return this.renderCollectionLabel(label, endpoint, isAppCollection);
  };

  renderNotificationName = ({ name, values }) => {
    const isAppCollection = values.model.startsWith('apps/');

    const label = wordify(name);
    const endpoint = isAppCollection ? values.model : `/${values.model}`;
    return this.renderCollectionLabel(label, endpoint, isAppCollection);
  };

  render() {
    const configs = this.getConfigsByType();

    const allCount = Object.keys(configs).reduce((acc, type) => {
      return acc + configs[type].length;
    }, 0);
    if (!allCount) {
      return <>No configurations installed yet</>;
    }

    return (
      <div className="view-body-section-group">
        {configs.model?.length > 0 && (
          <Table
            title="Data models"
            titleCount={true}
            subtitle="Models that define new data structures and API endpoints"
            fields={[
              {
                label: 'Name',
                className: 'nowrap',
                func: this.renderDataModelName,
              },
              {
                label: 'Fields',
                func: ({ values }) => countModelFields(values) || 0,
              },
              {
                label: 'Events',
                func: ({ values }) => values.events?.types?.length || 0,
              },
              { label: 'Description', path: 'values.description' },
            ]}
            rows={configs.model}
          />
        )}
        {configs.content?.length > 0 && (
          <Table
            title="Content models"
            titleCount={true}
            subtitle="Admin interfaces used to manage app content"
            fields={[
              {
                label: 'Name',
                className: 'nowrap',
                func: this.renderContentModelName,
              },
              {
                label: 'Fields',
                func: (model) => countContentFields(model) || 0,
              },
              {
                label: 'Views',
                func: (model) => model.views?.length || 0,
              },
              { label: 'Description', path: 'values.description' },
            ]}
            rows={configs.content}
          />
        )}
        {configs.notification?.length > 0 && (
          <Table
            title="Notifications"
            titleCount={true}
            subtitle="Transactional templates to used to notify users of events"
            fields={[
              {
                label: 'Name',
                className: 'nowrap',
                func: this.renderNotificationName,
              },
              { label: 'Description', path: 'values.description' },
            ]}
            rows={configs.notification}
          />
        )}
        {configs.function?.length > 0 && (
          <Table
            title="Functions"
            titleCount={true}
            subtitle="Internal functions triggered by events or API requests"
            fields={[
              {
                label: 'Name',
                className: 'nowrap',
                func: ({ name }) => (
                  <div>
                    <b>{wordify(name)}</b>
                  </div>
                ),
              },
              {
                label: 'Trigger',
                func: ({ values }) =>
                  values.route
                    ? 'HTTP'
                    : values.model
                    ? 'Model events'
                    : values.webhook && 'Webhook events',
              },
              { label: 'Description', path: 'values.description' },
            ]}
            rows={configs.function}
          />
        )}
        {configs.webhook?.length > 0 && (
          <Table
            title="Webhooks"
            titleCount={true}
            subtitle="External webhooks triggered by events or API requests"
            fields={[
              {
                label: 'Name',
                className: 'nowrap',
                func: ({ name }) => (
                  <div>
                    <b>{wordify(name)}</b>
                  </div>
                ),
              },
              {
                label: 'URL',
                path: 'values.url',
              },
              { label: 'Description', path: 'values.description' },
            ]}
            rows={configs.webhook}
          />
        )}
        {configs.asset?.length > 0 && (
          <Table
            title="Assets"
            titleCount={true}
            subtitle="Images and other file-based assets used by the app"
            fields={[
              {
                label: 'Name',
                className: 'nowrap',
                func: ({ name }) => (
                  <div>
                    <b>{wordify(name)}</b>
                  </div>
                ),
              },
              {
                label: 'Type',
                func: ({ file }) => file.content_type,
              },
              {
                label: 'Size',
                func: ({ file }) => file.size,
              },
              { label: 'Description', path: 'values.description' },
            ]}
            rows={configs.asset}
          />
        )}
        {configs.setting?.length > 0 && (
          <Table
            title="Settings"
            titleCount={true}
            subtitle="App and user-level settings used by the app"
            fields={[
              {
                label: 'Name',
                className: 'nowrap',
                func: ({ name }) => (
                  <div>
                    <b>{wordify(name)}</b>
                  </div>
                ),
              },
              {
                label: 'Fields',
                func: ({ values }) => countContentFields(values),
              },
              { label: 'Description', path: 'values.description' },
            ]}
            rows={configs.setting}
          />
        )}
      </div>
    );
  }
}
