import React, { Fragment } from 'react';
import pt from 'prop-types';

import Modal from 'components/modal';
import { FadeIn } from 'components/transitions';
import { Form, Field, LookupCustomer } from 'components/form';
import CustomerLocaleCurrencyFields from 'components/customer/locale-currency-fields';

export default class OrderCustomerEdit extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    billing: pt.object,
    loading: pt.bool,
    lookup: pt.object,
    settings: pt.object,
    label: pt.string,
    showLookupCurrency: pt.bool,
    showAutoUpdateAddress: pt.bool,

    onClickEditCustomer: pt.func,
    onSubmitEditCustomer: pt.func,
  };

  constructor(props) {
    super(props);

    const { billing } = props;
    const record = props.record || {};

    this.state = {
      values: {
        ...record,
        billing: billing || {},
      },
    };
  }

  onChangeForm = (values) => {
    this.setState((state) => ({
      values: {
        ...state.values,
        ...values,
        billing: {
          ...(state.values.billing || undefined),
          ...values.billing,
        },
      },
    }));
  };

  render() {
    const {
      record,
      loading,
      lookup,
      settings = {},
      label,
      onClickEditCustomer,
      onSubmitEditCustomer,
      showLookupCurrency,
    } = this.props;

    const { values } = this.state;
    const account = record.account || {};

    const isNew = !account.id;

    const showLookup =
      isNew && (values.add === undefined || values.add === 'ex');

    const accountType = account.type || (values.account && values.account.type);
    const features = settings.accounts ? settings.accounts.features : {};

    return (
      <Form
        onChange={this.onChangeForm}
        onSubmit={onSubmitEditCustomer}
        autoFocus={true}
      >
        <Modal
          title={label || (account.id ? 'Edit customer' : 'Add customer')}
          className="order-view-modal-edit-customer"
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'secondary',
              className: 'button-cancel',
              onClick: onClickEditCustomer,
            },
          ]}
          width={600}
          cancel={false}
          loading={loading}
          onClose={onClickEditCustomer}
        >
          <fieldset>
            {isNew && (
              <Field
                type="radio"
                name="add"
                readonly={true}
                buttons={true}
                defaultValue={
                  account.id === undefined || account.id ? 'ex' : 'new'
                }
                options={[
                  { value: 'ex', label: 'Existing' },
                  { value: 'new', label: 'New' },
                ]}
              />
            )}

            {showLookup ? (
              <LookupCustomer
                name="account"
                placeholder="Find customer by name or email"
                lookup={lookup}
                required={true}
                fields="name,email,billing,shipping,currency"
                autoFocus={true}
                showCurrency={showLookupCurrency}
              />
            ) : (
              <Fragment>
                <fieldset>
                  {isNew && features.business && (
                    <div className="row">
                      <Field
                        type="radio"
                        name="account.type"
                        label="Customer type"
                        buttons={true}
                        options={[
                          { value: 'individual', label: 'Individual' },
                          { value: 'business', label: 'Business' },
                        ]}
                      />
                    </div>
                  )}

                  {accountType !== 'business' ? (
                    <div className="row">
                      <Field
                        type="text"
                        label="First name"
                        name="account.first_name"
                        defaultValue={account.first_name}
                        className="span2"
                        required={true}
                        autoFocus={true}
                      />

                      <Field
                        type="text"
                        label="Last name"
                        name="account.last_name"
                        defaultValue={account.last_name}
                        className="span2"
                      />
                    </div>
                  ) : (
                    <FadeIn>
                      <div className="row" key="business">
                        <Field
                          type="text"
                          label="Business name"
                          name="account.name"
                          defaultValue={account.name}
                          required={true}
                          className="span4"
                        />
                      </div>
                    </FadeIn>
                  )}

                  <div className="row">
                    <Field
                      type="text"
                      label="Email address"
                      name="account.email"
                      defaultValue={account.email}
                      rules="required, email"
                      className="span4"
                    />
                  </div>

                  <div className="row">
                    <Field
                      type="checkbox"
                      label="Customer accepts email marketing"
                      name="account.email_optin"
                      defaultChecked={account.email_optin !== false}
                      className="span4"
                    />
                  </div>

                  <div className="row">
                    <Field
                      type="text"
                      label="Phone number"
                      name="account.phone"
                      defaultValue={account.phone}
                      className="span4"
                    />
                  </div>

                  {settings.accounts.groups &&
                    settings.accounts.groups.length > 0 && (
                      <div className="row">
                        <Field
                          type="select"
                          label="Customer group"
                          name="account.group"
                          placeholder="None"
                          clearIcon
                          options={[
                            ...settings.accounts.groups.map((group) => ({
                              value: group.id,
                              label: group.name,
                            })),
                          ]}
                          defaultValue={account.group}
                          className={isNew ? 'span2' : 'span4'}
                        />

                        {isNew && (
                          <Field
                            type="password"
                            label="Password"
                            name="password"
                            placeholder="Optional"
                            className="span2"
                            rules="password"
                            autoComplete="new-password"
                          />
                        )}
                      </div>
                    )}

                  <CustomerLocaleCurrencyFields
                    localeName="account.locale"
                    localeLabel="Locale preference"
                    currencyName="account.currency"
                    currencyLabel="Currency preference"
                    record={account}
                  />

                  {((accountType === 'business' && features.vat) ||
                    account.vat_number) && (
                    <FadeIn>
                      <div className="row">
                        <Field
                          type="text"
                          name="account.vat_number"
                          label="VAT number"
                          className="span2"
                          placeholder="Optional"
                          defaultValue={account.vat_number}
                        />
                        <div />
                      </div>
                    </FadeIn>
                  )}
                </fieldset>
              </Fragment>
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
