import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

const easeOutQuart = 'cubic-bezier(0.165, 0.84, 0.44, 1)';

export default function FadeInUpMask({ distance = '30px', duration = 750, children, ...props }) {
  const transitionStyles = {
    defaultStyle: {
      transform: `translate(0, ${distance})`,
    },
    enterStyle: {
      transform: transit('translate(0, 0)', duration, easeOutQuart),
    },
    leaveStyle: {
      transform: transit(`translate(0, ${distance})`, duration, easeOutQuart),
    },
    activeStyle: {
      // transform: 'translate(0, 0)',
    },
    active: true,
    transitionAppear: true,
  };
  const maskTransitionStyles = {
    defaultStyle: {
      opacity: 1,
    },
    enterStyle: {
      opacity: transit(0, duration, easeOutQuart),
    },
    leaveStyle: {
      opacity: transit(1, duration, easeOutQuart),
    },
    activeStyle: {
      opacity: 0,
      display: 'none',
    },
    active: true,
    transitionAppear: true,
  };
  const maskStyles = {
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  return (
    <CSSTransition
      {...transitionStyles}
      {...props}
      style={{ position: 'relative' }}
    >
      {children}
      <CSSTransition {...maskTransitionStyles}>
        <div style={maskStyles} />
      </CSSTransition>
    </CSSTransition>
  );
}
