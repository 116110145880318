import React from 'react';
import pt from 'prop-types';

import { formatCurrency } from 'utils';
import { renderMultiCurrencyValuesTooltip } from 'utils/money';

function PaymentTotal({ totalPaid, currency, totals }) {
  return (
    <>
      {formatCurrency(totalPaid, currency)}{' '}
      {renderMultiCurrencyValuesTooltip(totals, 'payment_total', currency)}
    </>
  );
}

PaymentTotal.propTypes = {
  totalPaid: pt.number.isRequired,
  currency: pt.string.isRequired,
  totals: pt.array.isRequired,
};

export default React.memo(PaymentTotal);
