export const NAV_TOGGLE_GROUP = 'nav/toggleGroup';
export const NAV_TOGGLE_EDITING = 'nav/toggleEditing';

export default {
  toggleGroup(id) {
    return {
      type: NAV_TOGGLE_GROUP,
      payload: id,
    };
  },

  toggleEditing() {
    return {
      type: NAV_TOGGLE_EDITING,
    };
  },
};

export const initialState = {
  activeGroups: {},
  editing: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case NAV_TOGGLE_GROUP:
      return {
        ...state,
        activeGroups: {
          ...state.activeGroups,
          [action.payload]: !state.activeGroups[action.payload],
        },
      };
    case NAV_TOGGLE_EDITING:
      return {
        ...state,
        editing: !state.editing,
      };
    default:
      return state;
  }
}
