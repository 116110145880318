import React from 'react';
import { Form, Button } from 'components/form';
import { Field } from 'components/form';
import Panel from './panel';
import Logo from 'components/logo/logo-inline';
import './signup.scss';

export default class VerifySignupPage extends React.PureComponent {
  sent = false;

  state = {
    codeEntered: false,
  };

  onChangeCode = (event, value) => {
    this.setState({ codeEntered: value.length > 0 });
  };

  validateCode = {
    rule: (value) => value && value.length === 4,
    hint: () => 'Code should be 4 digits long',
  };

  onClickResendLead = (event) => {
    event.preventDefault();
    if (!this.sent) {
      this.sent = true;
      setTimeout(() => (this.sent = false), 5000);
      this.props.onResendEmail();
    }
  };

  onClickContinue = (event) => {
    // Hack to workaround a weird issue where
    // submit does not work the 2nd time this form loads
    event.preventDefault();
    this.refs.form.submit();
  };

  onKeyDown = (event) => {
    switch (event.keyCode) {
      case 13: // enter
        event.preventDefault();
        this.refs.form.submit();
        break;
      default:
    }
  };

  render() {
    const { lead, onSubmitCode, loading } = this.props;

    return (
      <div className="single-signup single-signup-verify">
        <Panel />
        <div className="single-signup_form-container">
          <div className="form-container_inner">
            <Logo />
            <div>
              <div className="single-signup_verify-text">
                <h1 className="single-signup_title">
                  We need to verify your email
                </h1>
                <p>
                  Copy the verification code or follow the email link to verify
                  your email
                </p>
              </div>
              <Form
                onSubmit={onSubmitCode}
                autoFocus={true}
                ref="form"
                loading={loading}
              >
                <Field
                  type="text"
                  name="code"
                  label="Verification code"
                  rules="required"
                  large={true}
                  validateIcon={true}
                  validateBlur={true}
                  showError={false}
                  onKeyDown={this.onKeyDown}
                  onChange={this.onChangeCode}
                />
                <Button type="submit" styleType="default-purple" size="large">
                  Verify my email
                </Button>
                <div className="login-note">
                  <a href="" onClick={this.onClickResendLead}>
                    Resend verification code
                  </a>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
