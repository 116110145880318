import PropTypes from 'prop-types';
import React from 'react';
import { Form, Field } from 'components/form';
import Modal from 'components/modal';

export default class SendNotificationModalPage extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    template: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    fromEmails: PropTypes.array.isRequired,
    recordData: PropTypes.object.isRequired,
    subject: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  onClickSubmit(event) {
    event.preventDefault();
    this.refs.form.submit();
  }

  render() {
    const { title, subject, email, fromEmails, loaded, onSubmitForm } =
      this.props;

    return (
      <Form ref="form" onSubmit={onSubmitForm}>
        <Modal
          title={`Send ${title}`}
          className="modal-send-notification"
          actions={[{ label: 'Send', type: 'submit' }]}
          loading={!loaded}
        >
          <fieldset>
            <div className="row">
              <Field
                type="text"
                label="To email"
                name="to_email"
                placeholder={email}
                className="span2"
              />
              <Field
                type="select"
                label="From email"
                name="from_email"
                options={fromEmails}
                className="span2"
              />
            </div>
            <Field
              type="text"
              label="Subject"
              name="subject"
              defaultValue={subject}
            />
            <Field
              type="textarea"
              label="Optional message to customer"
              name="message"
              rows={5}
            />
            <Field
              type="radio"
              label="Send BCC to"
              name="message"
              options={fromEmails}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
