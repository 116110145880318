import React from 'react';
import Link from 'components/link';
import Tooltip from 'components/tooltip';
import AppIcon from 'components/apps/icon';

export default function AppIndicator({ app, button, icon = true }) {
  return (
    <div className="view-app-indicator">
      {icon === false ? (
        <Link to={`/apps/${app.id}`} className="collection-table-link">
          <Tooltip message={app.name}>
            <AppIcon image={app.logo_icon} name={app.name} size={20} />
          </Tooltip>
        </Link>
      ) : (
        <>
          <AppIcon image={app.logo_icon} name={app.name} size={20} />
          <Link to={`/apps/${app.id}`} className="collection-table-link">
            {app.name || 'Unknown App'}
          </Link>
          {button || null}
        </>
      )}
    </div>
  );
}
