import React, { Fragment } from 'react';
import Label from './label';
import { loadStripe } from 'utils/stripe';
import variables from 'styles/variables.scss';

const INPUT_STYLE = {
  style: {
    base: {
      color: variables.colorInput,
      fontWeight: variables.fontWeightInput,
      fontFamily: variables.fontForm,
      fontSize: variables.fontSizeInput,
    },
  },
};

export default class InputStripeCard extends React.PureComponent {
  state = {
    stripe: null,
    mounted: false,
  };

  async componentDidMount() {
    this.setState({
      stripe: await loadStripe(this.props.settings),
    });
  }

  componentDidUpdate() {
    const { stripe, mounted } = this.state;
    const { onInit, onChange } = this.props;

    if (!mounted && stripe) {
      const elementProps = {
        ...INPUT_STYLE,
        showIcon: true,
        classes: {
          base: 'input',
          focus: 'focus',
        },
      };
      const elements = stripe.elements();
      const card = elements.create('cardNumber', elementProps);
      elements.create('cardExpiry', elementProps).mount('#stripe-card-exp');
      elements.create('cardCvc', elementProps).mount('#stripe-card-cvc');
      card.mount('#stripe-card-number');
      onInit && onInit(stripe, card);
      onChange && card.on('change', (event) => onChange(event, {}));
      this.setState({ mounted: true });
    }
  }

  render() {
    return (
      <Fragment>
        <div className="form-field form-field-card-number span2">
          <span className="form-card-number">
            <Label label="Credit card number" />
            <span id="stripe-card-number" className="form-field-input" />
          </span>
        </div>
        <div className="form-field form-field-card-exp span1">
          <span className="form-card-exp">
            <Label label="Expiration" />
            <span id="stripe-card-exp" className="form-field-input" />
          </span>
        </div>
        <div className="form-field form-field-card-text span1">
          <span className="form-card-text">
            <Label label="CVC" />
            <span id="stripe-card-cvc" className="form-field-input" />
          </span>
        </div>
      </Fragment>
    );
  }
}
