// Sort category products by `sorting` field
export function sortProducts(products, sorting) {
  const nextProducts = [...products];

  switch (sorting) {
    case 'date_asc':
      nextProducts.sort(({ product: a }, { product: b }) =>
        String(a.date_created).localeCompare(b.date_created),
      );
      break;

    case 'date_desc':
      nextProducts.sort(({ product: a }, { product: b }) =>
        String(b.date_created).localeCompare(a.date_created),
      );
      break;

    case 'price_asc':
      nextProducts.sort(({ product: a }, { product: b }) => a.price - b.price);
      break;

    case 'price_desc':
      nextProducts.sort(({ product: a }, { product: b }) => b.price - a.price);
      break;

    case 'popularity':
      nextProducts.sort(
        ({ product: a }, { product: b }) => a.popularity - b.popularity,
      );
      break;

    default: {
      // Manual sort
      nextProducts.forEach((product, sort) => {
        product.sort = sort;
      });
      break;
    }
  }

  return nextProducts;
}

// Add a product to category considering sort
export function addProduct(products, newProduct, sorting = null) {
  products.results.push({
    id: newProduct.id,
    product_id: newProduct.id,
    product: newProduct,
    sort: products.results.length,
  });

  products.count += 1;

  if (sorting) {
    products.results = sortProducts(products.results, sorting);
  }

  return { ...products };
}

// Remove a product from category
export function removeProduct(products, id) {
  const pos = products.results.findIndex((product) => product.id === id);

  if (pos !== -1) {
    products.results.splice(pos, 1);
    products.count -= 1;
  }

  return { ...products };
}

// Move a product sort index manually
export function moveProduct(products, sourceIndex, targetIndex) {
  const nextProducts = [...products.results];

  nextProducts.splice(targetIndex, 0, nextProducts.splice(sourceIndex, 1)[0]);

  products.results = nextProducts;

  return { ...products };
}

// Determine if categories have found children
export function categoriesHaveFoundChildren(categories, found) {
  for (const category of categories) {
    if (category) {
      if (found.index.has(category.id)) {
        return true;
      }

      if (categoriesHaveFoundChildren(category.children || [], found)) {
        return true;
      }
    }
  }

  return false;
}
