import React, { Fragment } from 'react';

import { formatCurrency } from 'utils';

function renderOrderValue(label, value, currency, rounded = false) {
  if (!value) {
    return null;
  }

  const options = rounded ? { precision: undefined } : undefined;

  return (
    <Fragment>
      &emsp;{label}:&nbsp;
      <strike>{formatCurrency(value.old, currency, options)}</strike>
      &nbsp;
      {formatCurrency(value.new, currency, options)}
    </Fragment>
  );
}

function Discount({ data }) {
  const currency = data.currency || 'USD';

  return (
    <Fragment>
      <span className="bold">{data.name}</span>
      {renderOrderValue('Subtotal', data.sub_total, currency)}
      {renderOrderValue('Shipping', data.shipment_total, currency)}
      {renderOrderValue('Taxes', data.tax_included_total, currency)}
      {renderOrderValue('Total', data.grand_total, currency, true)}
    </Fragment>
  );
}

export default React.memo(Discount);
