import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { getTabFromLocation } from 'utils';
import { accountName } from 'utils/account';

import {
  PaymentStatus,
  FulfillmentStatus,
} from 'components/pages/order/status';
import PaymentIcon from 'components/icon/payment';
// TODO: replace with bulk/export component/actions
import OrderExport from 'components/pages/order/export';
import CollectionLocale from 'components/collection/locale';
import ViewLoading from 'components/view/loading';

import Collection from './Collection';

import { replaceLocationQuery } from 'actions/data';
import { getSettings } from 'actions/settings';
import actions from 'actions';

let PAYMENT_SETTINGS = {};

export const query = {
  expand: ['account'],
};

export const tabs = {
  default: {
    label: 'All orders',
  },
  unpaid: {
    label: 'Awaiting payment',
    query: {
      canceled: { $ne: true },
      payment_balance: { $lt: 0 },
      refunded: false,
    },
  },
  ready: {
    label: 'Ready to ship',
    query: {
      paid: true,
      item_quantity_deliverable: { $gt: 0 },
      canceled: { $ne: true },
      delivered: { $ne: true },
      hold: { $ne: true },
    },
  },
  hold: {
    label: 'On hold',
    query: {
      hold: true,
    },
  },
  refunded: {
    label: 'Refunded',
    query: {
      refund_total: { $gt: 0 },
    },
  },
};

const filters = {
  grand_total: {
    label: 'Total',
    operators: ['gt', 'lt', 'eq'],
    type: 'currency',
  },
  refund_total: {
    label: 'Refunded amount',
    operators: ['gt', 'lt', 'eq'],
    type: 'currency',
    show(location) {
      switch (getTabFromLocation(location)) {
        case 'refunded':
          return true;
        default:
          return false;
      }
    },
  },
  date_created: {
    label: 'Date',
    operators: ['after', 'before'],
    type: 'date',
  },
  status: {
    label: 'Status',
    options: [
      { value: 'canceled', label: 'Canceled' },
      { value: 'hold', label: 'On hold' },
      { value: 'paid', label: 'Paid' },
      { value: 'unpaid', label: 'Payment pending' },
      { value: 'fulfilled', label: 'Fulfilled' },
      { value: 'unfulfilled', label: 'Fulfillment pending' },
      { value: 'refunded', label: 'Refunded' },
    ],
    func(query) {
      switch (query) {
        case 'unpaid':
          return { payment_balance: { $lt: 0 } };

        case 'unfulfilled':
          return {
            delivered: { $ne: true },
            item_quantity_deliverable: { $gt: 0 },
          };

        case 'canceled':
          return { canceled: true };

        case 'hold':
          return { hold: true };

        case 'refunded':
          return { refund_total: { $gt: 0 } };

        case 'paid':
          return { paid: true };

        case 'fulfilled':
          return { delivered: true };

        default:
          break;
      }
    },
  },
  customer: {
    label: 'Customer',
    type: 'LookupCustomer',
    func(account_id) {
      return { account_id };
    },
  },
  product: {
    label: 'Product',
    type: 'LookupProduct',
    func(product_id) {
      return { 'items.product_id': product_id };
    },
  },
  payment_method: {
    label: 'Payment method',
    options: [],
    func(method) {
      return { 'billing.method': method };
    },
  },
};

export const fields = {
  number: {
    label: 'Order',
    type: 'link',
    url: '/orders/{id}',
    default: true,
    func: (order) => (
      <CollectionLocale locale={order.display_locale}>
        {order.number}
      </CollectionLocale>
    ),
  },
  date_created: {
    label: 'Date',
    type: 'date',
  },
  account: {
    label: 'Customer',
    sort: false,
    func: (order) =>
      accountName(order.account, order.shipping && order.shipping.name),
  },
  paid: {
    label: 'Payment',
    sort: false,
    func: (order) => {
      const billing = { ...(order.billing || {}) };
      return (
        <span>
          {billing.method ? (
            <Fragment>
              <PaymentIcon
                {...billing}
                settings={PAYMENT_SETTINGS}
                tooltip
                blank
              />
              &ensp;
            </Fragment>
          ) : null}
          <PaymentStatus order={order} />
        </span>
      );
    },
  },
  delivered: {
    label: 'Fulfillment',
    sort: false,
    func: (order) => {
      return <FulfillmentStatus order={order} />;
    },
  },
  // status: {
  //   label: 'State',
  //   func: (order) => {
  //     switch (order.status) {
  //       case 'payment_pending':
  //       case 'delivery_pending':
  //         return 'Ready';
  //       default:
  //         if (order.hold) {
  //           return (
  //             <span className="negative">On Hold</span>
  //           );
  //         }
  //         return startCase(order.status);
  //     }
  //   },
  // },
  refund_total: {
    label: 'Refunded amount',
    type: 'currency',
    rounded: true,
    show: (location) =>
      getTabFromLocation(location) === 'refunded' ||
      location.query.refund_total,
  },
  grand_total: {
    label: 'Total',
    type: 'currency',
    rounded: true,
  },
};

const bulkActions = [
  // {
  //   id: 'edit',
  //   label: 'Edit Orders',
  // },
  // {
  //   id: 'order_hold',
  //   label: 'Put on Hold',
  // },
  // {
  //   id: 'order_cancel',
  //   label: 'Cancel Order',
  // },
  {
    modal: 'BulkDelete',
    label: 'Delete',
    type: 'danger inverse',
  },
];

const mapStateToProps = (state) => ({
  account: state.account,
  data: state.data,
  export: state.orders.export,
});

const mapDispatchToProps = (dispatch) => ({
  startExport: (params) => {
    return dispatch(actions.orders.export(params));
  },

  exportCancel: () => {
    return dispatch(actions.orders.exportCancel());
  },

  fetchSettings(id) {
    return dispatch(actions.settings.fetch(id));
  },
});

export class Orders extends React.Component {
  static async onEnter(store, nextState, replace) {
    if (replaceLocationQuery(store, nextState, replace)) {
      return;
    }

    PAYMENT_SETTINGS = await getSettings(store, 'payments');
    if (PAYMENT_SETTINGS && PAYMENT_SETTINGS.methods) {
      filters.payment_method.options = PAYMENT_SETTINGS.methods.map((type) => ({
        value: type.id,
        label: type.name,
      }));
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      showingExport: false,
      onClickExport: this.onClickExport.bind(this),
      onClickExportCancel: this.onClickExportCancel.bind(this),
      onClickExportReset: this.onClickExportReset.bind(this),
      onSubmitExport: this.onSubmitExport.bind(this),
    };
  }

  componentDidMount() {
    this.props
      .fetchSettings('payments')
      .then((paymentSettings) => {
        if (!paymentSettings) {
          return;
        }

        filters.payment_method.options = paymentSettings.methods.map(
          (type) => ({
            value: type.id,
            label: type.name,
          }),
        );
      })
      .then(() => {
        this.setState({ loaded: true });
      });
  }

  onClickExport(event) {
    event.preventDefault();
    this.setState({ showingExport: !this.state.showingExport });
  }

  onClickExportCancel(event) {
    event.preventDefault();
    if (this.props.export.running) {
      this.props.exportCancel();
    }
    this.setState({ showingExport: false });
  }

  onClickExportReset(event) {
    event.preventDefault();
    this.props.exportCancel();
  }

  onSubmitExport(values) {
    this.props.startExport(values);
  }

  headerActions = [
    {
      label: 'Export',
      fa: 'arrow-to-bottom',
      type: 'sub',
      onClick: this.onClickExport.bind(this),
    },
    { label: 'New order', link: '/orders/drafts/new' },
  ];

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return (
      <div className="order-collection">
        <Collection
          ref="collection"
          title="Orders"
          uri="/orders"
          model="orders"
          emptyTitle="Create and manage orders"
          emptyDescription="You'll use this section to manage orders submitted by your storefront, or create orders manually with a link to checkout."
          emptyAction="Create a draft order"
          emptyActionLink="/orders/drafts/new"
          tabs={tabs}
          filters={filters}
          fields={fields}
          selectable={true}
          bulkActions={bulkActions}
          headerActions={this.headerActions}
          {...this.props}
          query={{
            ...query,
            ...(this.props.query || undefined),
          }}
        />

        {this.state.showingExport && (
          <OrderExport {...this.props} {...this.state} />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
