/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PaymentMethodLogo from 'components/settings/payment-method-logo';
import { Field } from 'components/form';
import Status from 'components/status';
import { isLiveMode } from 'utils/payment';

export default class PayPalExpress extends React.PureComponent {
  static propTypes = {
    paypalMethod: PropTypes.object,
    onClickEdit: PropTypes.func,
  };

  render() {
    const { paypalMethod, onClickEdit } = this.props;
    const { express_enabled, mode } = paypalMethod;

    return (
      <Fragment>
        <div
          data-testid="rtl-paypalBox"
          className="settings-integrations-box box"
        >
          <div className="box-grid">
            <div className="box-column">
              <div className="box-title payment-method">
                <PaymentMethodLogo id="paypal" />
                PayPal Express
              </div>
            </div>
            <div className="box-column">
              <Field
                type="toggle"
                name="methods.paypal.express_enabled"
                defaultChecked={express_enabled}
              />
            </div>
          </div>
          <div className="box-subtitle">
            Enable customers to pay with PayPal Express at checkout
            {express_enabled && !isLiveMode(mode) && (
              <Fragment>
                <br />
                <Status type="positive">Test mode</Status>
              </Fragment>
            )}
          </div>
          <div className="box-actions">
            <a
              href=""
              onClick={onClickEdit}
              className="button button-sm button-secondary"
            >
              Settings
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}
