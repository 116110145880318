import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { connect } from 'react-redux';
import ViewLoading from 'components/view/loading';
import NavigationPage from 'components/storefront/navigation';
import actions from 'actions';
import { snakeCase } from 'utils';

const mapStateToProps = (state) => ({
  record: state.storefronts.storefront,
  configs: state.storefronts.configs,
  lookup: state.lookup,
  content: state.content,
  categories: state.categories,
  loading: state.settings.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStorefrontMenus: (id) => {
    return dispatch(actions.storefronts.fetchMenus(id));
  },

  updateStorefrontMenu: (id, values) => {
    return dispatch(actions.storefronts.updateMenu(id, values));
  },

  deleteStorefrontMenu: (id, menuId) => {
    return dispatch(actions.storefronts.deleteMenu(id, menuId));
  },

  fetchStorefrontEditor: (id) => {
    return dispatch(actions.storefronts.fetchConfig(id, 'editor'));
  },

  loadCategories: () => {
    return dispatch(actions.categories.load());
  },

  loadContentCollections: () => {
    return dispatch(actions.content.loadCollections());
  },
});

export class StorefrontNavigation extends React.PureComponent {
  static contextTypes = {
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded: false,
      menus: null,
      onSaveMenu: this.onSaveMenu.bind(this),
      onDeleteMenu: this.onDeleteMenu.bind(this),
    };
  }

  async componentWillMount() {
    const {
      record,
      fetchStorefrontMenus,
      fetchStorefrontEditor,
      loadContentCollections,
      loadCategories,
    } = this.props;

    const [menus] = await Promise.all([
      fetchStorefrontMenus(record.id),
      fetchStorefrontEditor(record.id),
      loadCategories(),
      loadContentCollections(),
    ]);

    this.setState({
      loaded: true,
      menus,
    });
  }

  async onSaveMenu(values) {
    const { record, fetchStorefrontMenus, updateStorefrontMenu } = this.props;
    const { menus } = this.state;

    if (!values.id) {
      values.id = snakeCase(values.name);
      if (find(menus, (menu) => menu.id === values.id)) {
        this.context.notifyError(
          `A menu with ID "${values.id}" already exists`,
        );
        return false;
      }
    }

    let result = await updateStorefrontMenu(record.id, values);

    if (!result) {
      return false;
    } else if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    result = await fetchStorefrontMenus(record.id);

    this.setState({
      loaded: true,
      menus: result,
    });

    return result;
  }

  async onDeleteMenu(menuId) {
    const { fetchStorefrontMenus, deleteStorefrontMenu, record } = this.props;

    let result = await deleteStorefrontMenu(record.id, menuId);

    if (!result) {
      return false;
    } else if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    result = await fetchStorefrontMenus(record.id);

    this.setState({
      loaded: true,
      menus: result,
    });

    return result;
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return <NavigationPage {...this.props} {...this.state} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StorefrontNavigation);
