import React from 'react';
import { List } from 'react-movable';
import classNames from 'classnames';
import pt from 'prop-types';

import Icon from 'components/icon';
import Link from 'components/link';
import Showing from 'components/collection/showing';
import Pagination from 'components/collection/pagination';
import { FadeIn } from 'components/transitions';

import CategoryProduct from './product';
import CategoryProductPlaceholder from './product-placeholder';

/** @typedef {import('react-movable').RenderListParams} RenderListParams */
/** @typedef {import('react-movable').RenderItemParams} RenderItemParams */
/** @typedef {import('react-movable').BeforeDragParams} BeforeDragParams */
/** @typedef {import('react-movable').OnChangeMeta} OnChangeMeta */

export const MANUAL_SORTING_LIMIT = 200;

export default class CategoryProducts extends React.PureComponent {
  static propTypes = {
    modal: pt.bool,
    sorting: pt.string,
    loading: pt.bool,
    products: pt.object.isRequired,
    categoryId: pt.string,

    onChangePage: pt.func,
    onChangeLimit: pt.func,
    onMoveProductManually: pt.func,
    onClickRemoveProduct: pt.func,
  };

  state = {
    widths: [],
    sorting: false,
  };

  movableContainerRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    const sorting =
      !props.sorting && props.products.count <= MANUAL_SORTING_LIMIT;

    if (sorting !== state.sorting) {
      return { sorting };
    }

    return null;
  }

  /** @param {BeforeDragParams} params */
  onBeforeDrag = ({ elements, index }) => {
    const widths = Array.from(elements[index].children).map(
      (cell) => window.getComputedStyle(cell).width,
    );

    this.setState({ widths });
  };

  /** @param {OnChangeMeta} meta */
  onSort = ({ oldIndex: sourceIndex, newIndex: targetIndex }) => {
    const dragSourceId = this.props.products.results[sourceIndex].id;
    const targetSort = this.props.products.results[targetIndex].sort;

    this.props.onMoveProductManually(
      dragSourceId,
      sourceIndex,
      targetIndex,
      targetSort,
    );
  };

  /** @param {RenderListParams} params */
  renderList = ({ children, props }) => {
    return (
      <div className="collection-table-container">
        <table className={classNames('collection-table sortable')}>
          <thead>
            <tr>
              <th colSpan="2">Product</th>
              <th>Price</th>
              <th />
            </tr>
          </thead>

          <tbody {...props}>{children}</tbody>
        </table>
      </div>
    );
  };

  /** @param {RenderItemParams} params */
  renderItem = ({ value, props, isDragged }) => {
    const { loading, onClickRemoveProduct } = this.props;
    const { sorting, widths } = this.state;

    const element = loading ? (
      <CategoryProductPlaceholder key={value.id} />
    ) : (
      <CategoryProduct
        key={value.id}
        itemId={value.id}
        product={value.product}
        sorting={sorting}
        widths={isDragged ? widths : undefined}
        movableProps={isDragged ? undefined : props}
        onRemove={onClickRemoveProduct}
      />
    );

    return isDragged ? (
      <table className="collection-table outer sortable dragging" {...props}>
        <tbody>{element}</tbody>
      </table>
    ) : (
      element
    );
  };

  render() {
    const {
      modal,
      loading,
      products,
      categoryId,
      onChangePage,
      onChangeLimit,
    } = this.props;

    if (products.results.length <= 0) {
      return (
        <div>
          <span className="span4">
            <div className="collection-none-found">
              There are no products in this category
            </div>
          </span>
        </div>
      );
    }

    return (
      <div ref={this.movableContainerRef} className="category-products">
        {loading && products.count > MANUAL_SORTING_LIMIT && (
          <FadeIn active>
            <div className="category-sorting-message">
              {`Sorting ${products.count} products. This may take a few minutes…`}
            </div>
          </FadeIn>
        )}

        {this.state.sorting ? (
          <List
            values={products.results}
            lockVertically={true}
            transitionDuration={200}
            container={this.movableContainerRef.current}
            renderList={this.renderList}
            renderItem={this.renderItem}
            beforeDrag={this.onBeforeDrag}
            onChange={this.onSort}
          />
        ) : (
          this.renderList({
            children: products.results.map((product) =>
              this.renderItem({ value: product }),
            ),
          })
        )}

        {modal ? (
          <div className="collection-pages">
            <Showing collection={products} />

            {categoryId && (
              <div>
                <Link to={`/categories/${categoryId}`}>
                  Go to full product listing <Icon fa="external-link" />
                </Link>
              </div>
            )}
          </div>
        ) : (
          <Pagination
            title="products"
            collection={products}
            onClickPage={onChangePage}
            onChangeLimit={onChangeLimit}
          />
        )}
      </div>
    );
  }
}
