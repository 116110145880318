import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import Icon from 'components/icon';
import View from 'components/view';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import Loading from 'components/loading';
import CollectionEmpty from 'components/collection/empty';
import { FadeIn } from 'components/transitions';
import { formatDate } from 'utils';
import Helptip from 'components/tooltip/help';
import Tooltip from 'components/tooltip/tooltip';
import Status from 'components/status';
import SectionHeader from 'components/section-header';
import SSLToggle from './ssl-toggle';
import Alert from 'components/alert/alert';

export default class StorefrontDomains extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showingConnect: false,
      showingVerify: false,
      domainId: null,
      verified: false,
      verifying: false,
    };
    this.onSubmitConnect = this.onSubmitConnect.bind(this);
    this.onClickAutoSSL = this.onClickAutoSSL.bind(this);
  }

  onClickConnect = (event) => {
    event.preventDefault();
    this.setState({ showingConnect: true, domainId: null });
  };

  onCloseConnect = () => {
    this.setState({ showingConnect: false });
  };

  onClickEdit = (event) => {
    event.preventDefault();
    const domainId = event.currentTarget.dataset.id || this.state.domainId;
    this.setState({
      showingVerify: false,
      showingConnect: true,
      verified: false,
      domainId,
    });
  };

  onClickVerify = (event) => {
    event.preventDefault();
    const domainId = event.currentTarget.dataset.id;
    this.setState({
      showingVerify: true,
      verified: false,
      verifying: false,
      domainId,
    });
  };

  onCloseVerify = () => {
    this.setState({ showingVerify: false });
  };

  onClickVerifyAction = (event) => {
    event.preventDefault();
    const { domainId } = this.state;
    this.setState({ verifying: true });
    this.props.onVerifyDomain(domainId).then(() =>
      setTimeout(() => {
        this.setState({ verifying: false });
        if (this.state.domainId === domainId) {
          this.setState({ verified: true });
        }
      }, 1000),
    );
  };

  onClickMakePrimary = (event) => {
    event.preventDefault();
    const { record } = this.props;
    const domainId = event.currentTarget.dataset.id;
    const domain = find(record.domains.results, { id: domainId });
    this.context.openModal('Confirm', {
      title: `Make domain primary`,
      message: (
        <p>
          Auto-redirect storefront traffic to <b>{domain.name}</b>?
        </p>
      ),
      action: 'Make it so',
      actionType: 'default',
      onConfirm: async () => {
        await this.props.onUpdateDomain(domainId, { primary: true });
      },
    });
  };

  onClickRemove = (event) => {
    event.preventDefault();
    const { record } = this.props;
    const domainId = event.currentTarget.dataset.id;
    const domain = find(record.domains.results, { id: domainId });
    this.context.openModal('Confirm', {
      title: `Remove domain`,
      message: (
        <p>
          Are you sure you want to remove <b>{domain.name}</b>?<br />
          <br />
          You'll be able to connect it again later.
        </p>
      ),
      action: 'Remove',
      actionType: 'danger',
      onConfirm: async () => {
        await this.props.onDeleteDomain(domainId);
      },
    });
  };

  onClickAutoSSL(domainId, status) {
    const { onToggleSSLDomain } = this.props;

    if (status) {
      this.context.openModal('Confirm', {
        title: `Enable Auto SSL`,
        message: (
          <>
            <p>
              Enabling Auto SSL for a custom domain can take a few minutes to
              hours depending on your region.
            </p>
            <p>
              After successfully{' '}
              <span className="warning">issuing your certificate</span>, your
              custom domain will be{' '}
              <span className="positive">verified and secure</span>.
            </p>
          </>
        ),
        action: 'Enable',
        actionType: 'default',
        onConfirm() {
          onToggleSSLDomain(domainId, status);
        },
      });

      return;
    }

    onToggleSSLDomain(domainId, status);
  }

  onClickSubmit = (event) => {
    event?.preventDefault();
    this.refs.domainForm.submit();
  };

  async onSubmitConnect(values) {
    const { onCreateDomain, onUpdateDomain } = this.props;
    const { domainId } = this.state;
    if (domainId) {
      if (await onUpdateDomain(domainId, values)) {
        this.setState({
          showingConnect: false,
          showingVerify: true,
          verified: false,
        });
      }
    } else {
      const result = await onCreateDomain(values);
      if (result) {
        this.setState({
          showingConnect: false,
          verified: false,
          domainId: result.id,
        });
      }
    }
  }

  renderConnectModal() {
    const { record, loading } = this.props;
    const { domainId } = this.state;
    const domain = domainId && find(record.domains.results, { id: domainId });
    return (
      <Form onSubmit={this.onSubmitConnect} ref="domainForm" autoFocus={true}>
        <Modal
          title="Connect domain"
          width={600}
          actions={[
            { label: 'Next', type: 'submit', onClick: this.onClickSubmit },
          ]}
          onClose={this.onCloseConnect}
          loading={loading}
        >
          <fieldset>
            <div className="row">
              <Field
                type="text"
                name="name"
                label="Domain name"
                placeholder="i.e. example.com"
                defaultValue={domain ? domain.name : undefined}
                rules="domain_name"
                required={true}
                className="span4"
              />
            </div>
          </fieldset>
        </Modal>
      </Form>
    );
  }

  renderVerifyModal() {
    const { record } = this.props;
    const { domainId, verified, verifying } = this.state;
    const domain = find(record.domains.results, { id: domainId });
    return (
      <Modal
        title="Verify domain"
        className="storefront-domains-verify-modal"
        width={600}
        actions={[
          verified &&
            !verifying &&
            !domain.verified && {
              label: 'Retry',
              type: 'default',
              onClick: this.onClickVerifyAction,
            },
          !verified &&
            !verifying && {
              label: 'Verify connection',
              type: 'default',
              onClick: this.onClickVerifyAction,
            },
          verified &&
            domain.verified && {
              label: 'Ok',
              type: 'default',
              onClick: this.onCloseVerify,
            },
        ]}
        closable={!verifying}
        cancel={!verifying && !(verified && domain.verified)}
        onClose={this.onCloseVerify}
      >
        {verifying ? (
          <FadeIn key="verifying" className="storefront-domains-verifying">
            <Loading />
            <div className="note">Checking your domain connection...</div>
          </FadeIn>
        ) : verified ? (
          <FadeIn key="success">
            {domain.verified ? (
              <FadeIn key="success" className="storefront-domains-verifying">
                <div className="storefront-domains-status positive">
                  <Icon fa="check-circle" faType="light" />
                </div>
                <div className="note">
                  Success! <b>{domain.name}</b> has been verified.
                </div>
              </FadeIn>
            ) : (
              <div>
                <p>
                  <b className="negative">
                    <code>{domain.name}</code>
                  </b>{' '}
                  &nbsp;{' '}
                  <a href="" onClick={this.onClickEdit}>
                    edit
                  </a>
                </p>
                <p>
                  There was a problem verifying your domain. It may take up to
                  48 hours for your DNS changes to take effect.
                </p>
                <p>
                  <b>CNAME: {this.context.client.id}.apps.swellstores.com</b>
                </p>
              </div>
            )}
          </FadeIn>
        ) : (
          <div>
            <p>
              <b className="positive">
                <code>{domain.name}</code>
              </b>{' '}
              &nbsp;{' '}
              <a href="" onClick={this.onClickEdit}>
                edit
              </a>
            </p>
            <p>
              To complete the connection, log into your domain provider and
              create a CNAME record for your custom domain with the hostname
              below.
            </p>
            <p>
              <b>CNAME: {this.context.client.id}.apps.swellstores.com</b>
            </p>
          </div>
        )}
      </Modal>
    );
  }

  renderDomainStatus(domain) {
    if (!domain.verified) {
      return (
        <>
          <Status type="muted">Unverified</Status>
          &nbsp; &nbsp;
          <a href="" onClick={this.onClickVerify} data-id={domain.id}>
            Verify
          </a>
        </>
      );
    }

    if (!domain.ssl) {
      return <Status type="positive">Verified</Status>;
    }

    if (domain.ssl_error) {
      return (
        <Tooltip message={domain.ssl_error}>
          <Status type="negative">Error issuing certificate</Status>
        </Tooltip>
      );
    }

    switch (domain.ssl_status) {
      case 'destroying':
        return <Status type="warning">Disabling Auto SSL</Status>;

      case 'success':
        return (
          <Tooltip message="Your domain is secured by automatic SSL">
            <Status type="positive">
              Verified <Icon fa="lock" />
            </Status>
          </Tooltip>
        );

      default:
        return <Status className="warning">Issuing SSL certificate</Status>;
    }
  }

  render() {
    const { record, viewProps = {} } = this.props;
    const { showingConnect, showingVerify } = this.state;

    const domains = record?.domains?.results || [];

    return (
      <>
        <View
          key="domains"
          {...viewProps}
          detail={true}
          headerActions={[
            ...viewProps.headerActions,
            { label: 'Connect domain', onClick: this.onClickConnect },
          ]}
        >
          <Alert type="warning">
            Storefront domains will no longer be officially supported while we
            work to implement new hosting integrations. &nbsp;
            <a
              href="https://developers.swell.is/storefronts/storefront-migration-guide"
              target="_blank"
            >
              Learn how to migrate
            </a>
          </Alert>
          <div className="view-body-subheader">
            <SectionHeader
              className="view-body-subtitle"
              title="Domains"
              subtitle="Manage your custom domains"
            />
          </div>
          {domains.length > 0 ? (
            <table className="collection-table outer">
              <thead>
                <tr>
                  <th>Domain</th>
                  <th>Created</th>
                  <th>Status</th>
                  <th>
                    Auto SSL{' '}
                    <Helptip message="Enable automatic SSL/TLS certificates with Let’s Encrypt for your verified custom domains." />
                  </th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {domains.map((domain) => (
                  <tr key={domain.id}>
                    <td>
                      <code>{domain.name}</code>
                      {domain.primary && domain.verified && (
                        <span className="badge">Primary</span>
                      )}
                    </td>
                    <td>{formatDate(domain.date_created, 'age')}</td>
                    <td>{this.renderDomainStatus(domain)}</td>
                    <td>
                      <SSLToggle
                        checked={domain.ssl}
                        disabled={!domain.verified}
                        onClick={(event) => {
                          this.onClickAutoSSL(domain.id, !domain.ssl);
                        }}
                      />
                    </td>
                    <td className="action">
                      {domain.verified && !domain.primary && (
                        <a
                          href=""
                          onClick={this.onClickMakePrimary}
                          data-id={domain.id}
                        >
                          Make primary
                        </a>
                      )}
                      <a
                        href=""
                        onClick={this.onClickRemove}
                        data-id={domain.id}
                        className="muted"
                      >
                        Remove
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="box">
              <CollectionEmpty
                emptyTitle="Connect a custom domain"
                emptyDescription="Use a custom domain for your hosted storefront"
                emptyAction={false}
              />
            </div>
          )}
        </View>
        {showingConnect && this.renderConnectModal()}
        {showingVerify && this.renderVerifyModal()}
      </>
    );
  }
}
