import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import TemplateEngine from 'swell-template';
import { get, find, debounce, capitalize } from 'lodash';
import PropTypes from 'prop-types';

import api from 'services/api';
import actions from 'actions';

import { getTrialTotal } from 'utils/activity';

import Icon from 'components/icon';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import ButtonLink from 'components/button/link';
import Activity from 'components/activity';
import OrderNotes from 'components/pages/order/notes';
import CollectionLocale from 'components/collection/locale';
import TemplatePreview from 'components/template-preview';

export const mapStateToProps = (state) => ({
  record: state.data.record,
  loading: state.data.loading,
  settings: state.settings,
  notifications: state.notifications,
  activity: state.activity,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchNotification: async (id) => {
    return dispatch(actions.notifications.fetch(id));
  },

  fetchActivity: debounce((model, record) => {
    dispatch(actions.activity.fetch(model, record));
  }, 500),

  clearActivity: () => {
    return dispatch(actions.activity.clear());
  },

  sendNotification: (data) => {
    dispatch(actions.activity.loading(true));
    return dispatch(actions.data.createRecord('notifications', data));
  },

  createNote: async (data) => {
    const note = await dispatch(actions.data.createRecord(':notes', data));
    dispatch(actions.activity.addNote(note));
    return note;
  },

  updateNote: (id, note) => {
    dispatch(actions.activity.loading(true));
    return dispatch(actions.data.updateRecord(':notes', id, { body: note }));
  },

  deleteNote: (id) => {
    dispatch(actions.activity.loading(true));
    return dispatch(actions.data.deleteRecord(':notes', id));
  },
});

export class ActivityFeed extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  state = {};

  templateEngine = null;

  constructor(props) {
    super(props);
    this.state = {
      notificationValues: {},
      notification: null,
      notificationViewRecord: null,
      notificationLoading: false,
      showNotification: false,
    };
    this.onCreateNote = this.onCreateNote.bind(this);
    this.onSaveNote = this.onSaveNote.bind(this);
    this.onDeleteNote = this.onDeleteNote.bind(this);
    this.onSubmitNote = this.onSubmitNote.bind(this);
    this.getTemplateEngine = this.getTemplateEngine.bind(this);
    this.onClickShowNotification = this.onClickShowNotification.bind(this);
    this.onCloseShowNotification = this.onCloseShowNotification.bind(this);
    this.onResendNotification = this.onResendNotification.bind(this);
    this.onChangeResendSendNotificationForm =
      this.onChangeResendSendNotificationForm.bind(this);
  }

  async componentWillMount() {
    const { clearActivity, fetchActivity, model, record } = this.props;
    clearActivity();
    if (model && record) {
      await fetchActivity(model, record);
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { fetchActivity } = this.props;
    if (nextProps.record && this.props.record !== nextProps.record) {
      fetchActivity(nextProps.model, nextProps.record);
    }
  }

  async onCreateNote(note) {
    const { record, createNote } = this.props;
    const { user } = this.context;
    await createNote({ record_id: record.id, body: note, user_id: user.id });
  }

  async onSaveNote(id, note) {
    const { model, record, updateNote, fetchActivity } = this.props;
    await updateNote(id, note);
    fetchActivity(model, record);
  }

  async onDeleteNote(id) {
    const { model, record, deleteNote, fetchActivity } = this.props;
    await deleteNote(id);
    fetchActivity(model, record);
  }

  async onSubmitNote(values) {
    await this.onCreateNote(values.notes.trim());
  }

  async loadNotification(template) {
    const { fetchNotification } = this.props;
    const { notificationValues: { record_id: recordId, locale, data } = {} } =
      this.state;
    const notification = await fetchNotification(template);
    if (!notification || !notification.record) {
      this.onCloseShowNotification();
      return;
    }
    const notificationViewRecord = await api.get(
      `/data/${notification.record.model}/${recordId}`,
      {
        $locale: locale,
        ...(notification.record.query || {}),
      },
    );
    this.setState({
      notification,
      notificationViewRecord: {
        ...notificationViewRecord,
        ...data,
        // TODO: move this logic into order feature, doesn't belong here
        ...getTrialTotal(notificationViewRecord, notification.record.model),
      },
      notificationLoading: false,
    });
  }

  async onClickShowNotification(event) {
    event.preventDefault();
    const { activity: { results: events } = {} } = this.props;
    const template = get(event, 'currentTarget.dataset.template');
    const eventId = get(event, 'currentTarget.dataset.id');
    const notificationValues = find(events, { id: eventId }) || {};
    this.setState(
      { notificationValues, notificationLoading: true, showNotification: true },
      () => {
        setTimeout(() => this.loadNotification(template), 10);
      },
    );
  }

  onCloseShowNotification() {
    this.setState({ showNotification: false, notificationLoading: false });
  }

  onChangeResendSendNotificationForm(values) {
    this.setState({
      notificationValues: { ...this.state.notificationValues, ...values },
    });
  }

  async onResendNotification() {
    const {
      notificationValues: {
        to,
        from,
        template,
        record_id,
        label: title = 'Message',
        data,
      },
    } = this.state;
    const { sendNotification, fetchActivity, model, record } = this.props;
    const { notifySuccess, notifyError, user } = this.context;

    try {
      const result = await sendNotification({
        template,
        to,
        from,
        record_id,
        data,
        user_id: user.id,
      });
      if (!result || result.errors) {
        throw new Error(result ? result.errors : 'Unable to send email');
      }
      fetchActivity(model, record);
      notifySuccess(`${title} sent to ${to || record.account.email}`);
    } catch (err) {
      notifyError(err);
    }

    this.onCloseShowNotification();
  }

  getTemplateEngine = () => {
    const { client } = this.context;
    const { notificationValues } = this.state;

    if (this.templateEngine) {
      return this.templateEngine;
    }
    return (this.templateEngine = new TemplateEngine({
      get: (url, data) => {
        return api.get(`/data/${url}`, {
          $locale: notificationValues.locale,
          ...(data || {}),
        });
      },
      details: {
        ...client,
        currency: get(this.props.record || client, 'currency'),
      },
    }));
  };

  showNotification() {
    const {
      notification,
      notificationLoading,
      notificationValues,
      notificationViewRecord,
    } = this.state;
    const { client } = this.context;

    const notificationFound =
      notification && notification.record && notificationViewRecord;
    const notificationId = notificationFound && notificationValues.id;

    return (
      <Form
        onSubmit={this.onResendNotification}
        onChange={this.onChangeResendSendNotificationForm}
      >
        <Modal
          title={
            <>
              {capitalize(
                get(notificationValues, 'label', 'Email notification'),
              )}
              <CollectionLocale locale={notificationValues.locale}></CollectionLocale>
            </>
          }
          width={960}
          actions={[
            notificationFound && {
              label: 'Resend email',
              type: 'submit',
              styleType: 'default',
            },
            notificationFound && {
              component: (
                <ButtonLink
                  size="sm"
                  type="secondary"
                  className="left secondary button-cancel"
                  to={`/settings/notifications?template=${notification.name}`}
                >
                  View template{' '}
                  <span className="muted">
                    <Icon fa="external-link" />
                  </span>
                </ButtonLink>
              ),
            },
          ]}
          cancelText="Close"
          loading={notificationLoading}
          onClose={this.onCloseShowNotification}
          devtools={
            notificationId && {
              model: 'notifications',
              uri: notificationId,
            }
          }
        >
          {notificationFound ? (
            <Fragment>
              <div className="row">
                <Field
                  type="email"
                  label="From email"
                  className="span2"
                  name="from"
                  defaultValue={client.support_email || notificationValues.from}
                  required={true}
                />
                <Field
                  type="email"
                  label="To email"
                  className="span2"
                  name="to"
                  defaultValue={notificationValues.to}
                  required={true}
                />
              </div>
              <TemplatePreview
                client={this.context.client}
                defaults={notification}
                record={notificationViewRecord}
                locale={notificationValues.locale}
                getTemplateEngine={this.getTemplateEngine}
              />
            </Fragment>
          ) : (
            <p>Notification template not found</p>
          )}
        </Modal>
      </Form>
    );
  }

  render() {
    const { showNotification } = this.state;
    const { record } = this.props;

    return (
      <Fragment>
        <Activity
          label="Activity"
          onClickShowNotification={this.onClickShowNotification}
          onSaveNote={this.onSaveNote}
          onDeleteNote={this.onDeleteNote}
          notes={
            <OrderNotes
              type="textarea"
              buttonLabel="Add note"
              record={record}
              defaultValue={null}
              onSubmit={this.onSubmitNote}
              placeholder="Leave a note..."
              clearOnSubmit={true}
              rows={1}
            />
          }
          {...this.props}
        />
        {showNotification && this.showNotification()}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityFeed);
