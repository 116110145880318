import React from 'react';
import get from 'lodash/get';

import { formatCurrencyRounded, formatNumber } from 'utils';
import SectionHeader from 'components/section-header/section-header';

import Link from 'components/link';
import PaymentIcon from 'components/icon/payment';
import DateTime from 'components/date-time';
import {
  PaymentStatus,
  FulfillmentStatus,
} from 'components/pages/order/status';

export default class CustomerOrders extends React.PureComponent {
  render() {
    const {
      record: { id, orders },
    } = this.props;

    const orderLength = get(orders, 'results.length', 0);
    const orderCount = get(orders, 'count', 0);

    return (
      <div className="customer-orders">
        <div className="view-body-section">
          <div className="view-body-subheader">
            <SectionHeader
              className="view-body-subtitle"
              title="Orders"
              count={formatNumber(orderCount)}
            />
          </div>

          <div className="view-section-content collection-full">
            {orderLength > 0 ? (
              <div className="collection-table-container">
                <table className="collection-table">
                  <thead>
                    <tr>
                      <th>Number</th>
                      <th>Date</th>
                      <th>Payment</th>
                      <th>Fulfillment</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.results.map((order) => (
                      <tr key={order.id}>
                        <td>
                          <Link to={`/orders/${order.number}`}>
                            {order.number}
                          </Link>
                        </td>
                        <td>
                          <DateTime value={order.date_created} format="age" />
                        </td>
                        <td>
                          {order.billing?.method && (
                            <PaymentIcon {...(order.billing || {})} />
                          )}
                          <PaymentStatus order={order} />
                        </td>
                        <td>
                          <FulfillmentStatus order={order} />
                        </td>
                        <td>
                          {formatCurrencyRounded(
                            order.grand_total,
                            order.currency,
                          )}
                        </td>
                      </tr>
                    ))}
                    {orderCount > orderLength && (
                      <tr className="footnote">
                        <td colSpan={5}>
                          <Link to={`/orders?customer=${id}`}>
                            View all &rarr;
                          </Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="collection-none-found">No orders yet</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
