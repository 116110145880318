import React from 'react';
import ReactDOM from 'react-dom';

const DEFAULT_CSS = `
  body {
    font-family: 'Open sans', sans-serif;
    font-size: 15px;
    margin: 0;
    padding: 0;
    color: #3d4246;
  }
  a {
    color: #00a3ce;
  }
  .__frameError {
    color: #e00640;
  }
`;

export default class Frame extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultCSS !== this.props.defaultCSS) {
      this.updateDefaultCSS(nextProps.defaultCSS);
    }

    this.renderFrame(nextProps.children);
  }

  componentDidMount() {
    setTimeout(() => {
      this.initFrame();
      this.renderFrame(this.props.children);
    }, 0);
  }

  componentWillUnmount() {
    const node = ReactDOM.findDOMNode(this);
    const root =
      node.contentDocument && node.contentDocument.getElementById('root');
    if (root) {
      ReactDOM.unmountComponentAtNode(root);
    }
  }

  updateDefaultCSS(css = DEFAULT_CSS) {
    if (!this.styleEl) {
      const el = document.createElement('style');
      el.type = 'text/css';
      this.head.appendChild(el);
      this.styleEl = el;
    }

    const el = this.styleEl;

    if (el.styleSheet) {
      el.styleSheet.cssText = css;
    } else {
      const cssNode = document.createTextNode(css);
      if (this.cssNode) el.removeChild(this.cssNode);
      el.appendChild(cssNode);
      this.cssNode = cssNode;
    }

    if (!this.head.contains(el)) {
      this.head.appendChild(el);
    }
  }

  initFrame() {
    const { defaultCSS } = this.props;
    const frame = ReactDOM.findDOMNode(this);
    const root = document.createElement('div');

    root.setAttribute('id', 'root');

    this.head = frame.contentDocument.head;
    this.body = frame.contentDocument.body;
    this.body.appendChild(root);

    setTimeout(() => {
      this.updateDefaultCSS(defaultCSS);
    }, 0);
  }

  renderFrame(children) {
    const frame = ReactDOM.findDOMNode(this);

    if (!frame) return;

    let root = frame.contentDocument.getElementById('root');

    if (!root) {
      this.initFrame();
      root = frame.contentDocument.getElementById('root');
    }

    if (root && children && this.children !== children) {
      this.children = children;
      ReactDOM.render(children, frame.contentDocument.getElementById('root'));
      setTimeout(() => {
        frame.height = root.scrollHeight + 20;
      }, 0);
    }
  }

  render() {
    const { children, defaultCSS, ...props } = this.props;

    return <iframe {...props} onLoad={this.renderFrame} />;
  }
}
