/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';
import ccutils from 'creditcardutils';

import actions from 'actions';
import { tokenizeCard } from 'services/api';

import { Form, Field } from 'components/form';
import Link from 'components/link/link';
import { FadeInDownBounce } from 'components/transitions';
import PaymentCard from 'components/payment/card';
import PaymentMethodsRadio from 'components/payment/methods-radio';

import {
  makeAddressString,
  isSameAddress,
  getAddressFromRecord,
  EMPTY_ADDRESS,
} from 'utils/order';
import { getDefaultCardId, getDefaultAddressId } from 'utils/account';

import './payment.scss';

const NEW_ADDRESS_ID = 'new';
const NONE_ADDRESS_ID = 'none';
const EMPTY_ARRAY = Object.freeze([]);

const mapStateToProps = (state) => ({
  model: state.data.model,
  record: state.data.record,
  cards: state.data.related?.account_cards?.results || EMPTY_ARRAY,
  addresses: state.data.related?.account_addresses?.results || EMPTY_ARRAY,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccount(id, data) {
    return dispatch(actions.data.updateRecord('accounts', id, data, false));
  },

  createAccountCard(id, data) {
    return dispatch(actions.data.createRecord(`accounts/${id}/cards`, data));
  },

  updateAccountCard(id, data) {
    return dispatch(
      actions.data.updateRecord(`accounts/${id}/cards`, data.id, data),
    );
  },

  deleteAccountCard: (id, cardId) => {
    return dispatch(actions.data.deleteRecord(`accounts/${id}/cards`, cardId));
  },

  createAccountAddress(id, data) {
    return dispatch(
      actions.data.createRecord(`accounts/${id}/addresses`, data),
    );
  },

  updateRecord(model, id, data) {
    return dispatch(actions.data.updateRecord(model, id, data, false));
  },
});

class PaymentCardsEditRadio extends React.PureComponent {
  static contextTypes = {
    client: pt.object.isRequired,
    openModal: pt.func.isRequired,
    notifySuccess: pt.func.isRequired,
    notifyError: pt.func.isRequired,
  };

  static propTypes = {
    model: pt.string.isRequired,
    record: pt.object.isRequired,
    cards: pt.array.isRequired,
    addresses: pt.array.isRequired,
    fetchRecord: pt.func.isRequired,
    updateAccount: pt.func.isRequired,
    createAccountCard: pt.func.isRequired,
    updateAccountCard: pt.func.isRequired,
    deleteAccountCard: pt.func.isRequired,
    createAccountAddress: pt.func.isRequired,
    updateRecord: pt.func.isRequired,
    value: pt.string,
    onSelect: pt.func,
    onClickAddCard: pt.func,
    onClickEditCard: pt.func,
    onClickCancel: pt.func,
    deletable: pt.bool,
    selectable: pt.bool,
  };

  constructor(props) {
    super(props);

    const method = props.record.billing?.method;
    const shouldSelectCard = !method || method === 'card';
    const selectedCardId = shouldSelectCard
      ? this.getDefaultSelectedCardId(props)
      : null;

    if (selectedCardId) {
      props.onSelect?.(selectedCardId);
    }

    this.state = {
      adding: false,
      editing: false,
      addingBilling: false,
      selectedCardId,
      selectedAddressId: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value === undefined || props.value === state.selectedCardId) {
      return null;
    }

    return { selectedCardId: props.value };
  }

  componentDidUpdate(_prevProps, prevState) {
    const { selectedCardId, adding, editing } = this.state;

    if ((adding || editing) && !prevState.adding && !prevState.editing) {
      this.setDefaultSelectedAddress();
    } else if (selectedCardId !== prevState.selectedCardId) {
      this.setState({ selectedAddressId: null });
    }
  }

  getDefaultCardId() {
    const { record } = this.props;

    return getDefaultCardId(record.account || record);
  }

  getDefaultSelectedCardId(props = this.props) {
    const { record, cards } = props;
    const billingCardId = getDefaultCardId(record);
    const defaultCardId = getDefaultCardId(record.account);
    let defaultCard;

    for (const card of cards) {
      if (card.id === billingCardId) {
        return card.id;
      }

      if (card.id === defaultCardId) {
        defaultCard = card;
      }
    }

    return defaultCard?.id || cards[0]?.id || null;
  }

  getDefaultAddressId() {
    const { record } = this.props;

    return getDefaultAddressId(record.account || record);
  }

  getSelectedCard() {
    const { cards } = this.props;
    const { selectedCardId } = this.state;

    return selectedCardId
      ? cards.find((card) => card.id === selectedCardId)
      : null;
  }

  setDefaultSelectedAddress() {
    const { addresses } = this.props;
    const selectedCard = this.getSelectedCard();
    const defaultAddressId = this.getDefaultAddressId();
    let selectedAddressId;

    if (!selectedCard?.billing?.address1) {
      selectedAddressId = NONE_ADDRESS_ID;
    }

    for (const address of addresses) {
      if (selectedCard && isSameAddress(selectedCard.billing, address)) {
        selectedAddressId = address.id;
        break;
      }

      if (!selectedAddressId && address.id === defaultAddressId) {
        selectedAddressId = defaultAddressId;
      }
    }

    this.setState({
      selectedAddressId: selectedAddressId || addresses[0]?.id || null,
    });
  }

  getAddress(id) {
    const { addresses } = this.props;

    if (id === NEW_ADDRESS_ID) {
      return null;
    } else if (id === NONE_ADDRESS_ID) {
      return EMPTY_ADDRESS;
    }

    const address = addresses.find((address) => address.id === id);

    return address ? getAddressFromRecord(address) : null;
  }

  selectDefaultCard() {
    const { selectedCardId } = this.state;
    const defaultSelectedCardId =
      selectedCardId || this.getDefaultSelectedCardId();

    this.onSelect(defaultSelectedCardId);
  }

  onSelect = (value) => {
    const { onSelect } = this.props;

    this.setState({ selectedCardId: value });

    onSelect?.(value);
  };

  onClickAddCard = () => {
    const { onClickAddCard } = this.props;

    this.setState({
      adding: true,
    });

    this.onSelect(null);

    onClickAddCard?.();
  };

  onClickEditCard = (event) => {
    event?.preventDefault();

    const { onClickEditCard } = this.props;
    const { editing } = this.state;

    if (editing) {
      this.onClickCancel(event);
    } else {
      this.setState({ editing: true });

      onClickEditCard?.();
    }
  };

  onClickCancel = (event) => {
    event?.preventDefault();

    const { onClickCancel } = this.props;

    this.setState({
      adding: false,
      editing: false,
    });

    onClickCancel?.();

    this.selectDefaultCard();
  };

  onClickAddBilling = () => {
    this.setState({ addingBilling: !this.state.addingBilling });
  };

  onChangeBilling = (event) => {
    event.preventDefault();

    const { index } = event.currentTarget.dataset;
    const isNewBillingIndex = index === '0';

    if (isNewBillingIndex) {
      this.onClickAddBilling();
    }
  };

  onClickDelete = (event) => {
    event?.preventDefault();

    const { openModal } = this.context;
    const { selectedCardId } = this.state;
    const card = this.getSelectedCard();

    if (!card) {
      return;
    }

    openModal('ConfirmDelete', {
      title: 'this card',
      confirmInput: {
        description: 'Enter the last 4 digits of the card to confirm',
        value: card.last4,
      },
      onConfirm: async () => {
        try {
          await this.onRemoveCard(selectedCardId);

          this.setState({ selectedCardId: null }, this.onClickCancel);

          return true;
        } catch {
          return false;
        }
      },
    });
  };

  onAddCard = async (values) => {
    const { client, notifySuccess, notifyError } = this.context;
    const { record, fetchRecord, updateAccount, createAccountCard } =
      this.props;
    const accountId = record.account_id || record.id;
    const { card, is_default: isDefaultCard, address_id: addressId } = values;
    const billing = this.getAddress(addressId);
    let cardResult;

    if (!billing) {
      return notifyError('Billing address not selected');
    }

    const formattedNumber = ccutils.formatCardNumber(card.number);
    const brand = ccutils.parseCardType(formattedNumber);
    const last4 = formattedNumber.slice(-4);

    if (isDefaultCard && !(await this.confirmDefaultCard({ brand, last4 }))) {
      return;
    }

    try {
      cardResult = await tokenizeCard(client.public_key, {
        card,
        billing,
        account_id: accountId,
      });

      if (cardResult.errors) {
        throw new Error(
          `${
            cardResult.errors.gateway
              ? cardResult.errors.gateway.message
              : JSON.stringify(cardResult.errors)
          }`,
        );
      }
    } catch (err) {
      return notifyError(err.message);
    }

    const result = await createAccountCard(accountId, {
      ...cardResult,
      billing,
    });

    if (result.errors) {
      return notifyError(result.errors);
    }

    if (isDefaultCard) {
      await updateAccount(accountId, {
        billing: {
          account_card_id: result.id,
        },
      });
    }

    await fetchRecord(record.id);

    notifySuccess(`Credit card added (${result.brand} ${result.last4})`);

    this.setState({ selectedCardId: result.id }, this.onClickCancel);
  };

  onEditCard = async (values) => {
    const { notifySuccess, notifyError } = this.context;
    const {
      model,
      record,
      fetchRecord,
      updateAccount,
      updateAccountCard,
      updateRecord,
    } = this.props;
    const accountId = record.account_id || record.id;
    const { card, is_default: isDefaultCard, address_id: addressId } = values;
    const selectedCard = this.getSelectedCard();
    const billing = this.getAddress(addressId);

    if (!billing) {
      return notifyError('Billing address not selected');
    }

    if (isDefaultCard && !(await this.confirmDefaultCard(selectedCard))) {
      return;
    }

    const update = {
      id: card.id,
      billing,
    };

    if (card.exp) {
      const exp = global.Schema.cardExpiry(card.exp);

      update.exp_month = exp.month;
      update.exp_year = exp.year;
    }

    const result = await updateAccountCard(accountId, update);

    if (result.errors) {
      return notifyError(result.errors);
    }

    if (isDefaultCard) {
      await updateAccount(accountId, {
        billing: {
          account_card_id: result.id,
        },
      });
    }

    if (selectedCard.id === getDefaultCardId(record)) {
      await updateRecord(model, record.id, {
        billing: {
          account_card_id: result.id,
        },
      });
    }

    await fetchRecord(record.id);

    notifySuccess(`Credit card updated (${result.brand} ${result.last4})`);

    this.onClickCancel();
  };

  onRemoveCard = async (cardId) => {
    const { notifySuccess } = this.context;
    const { record, fetchRecord, updateAccount, deleteAccountCard } =
      this.props;
    const card = await deleteAccountCard(record.id, cardId);

    if (!card) {
      return;
    }

    if (card.id === record.billing?.account_card_id) {
      await updateAccount(record.id, {
        billing: { card: null, account_card_id: null },
      });
    }

    await fetchRecord(record.id);

    notifySuccess(
      `Credit card removed from customer record (${card.brand} ${card.last4})`,
    );
  };

  onAddAddress = async (values) => {
    const { notifySuccess, notifyError } = this.context;
    const { record, fetchRecord, createAccountAddress, updateAccount } =
      this.props;
    const accountId = record.account_id || record.id;
    const { is_default: isDefaultAddress, billing } = values;

    const result = await createAccountAddress(accountId, billing);

    if (result.errors) {
      return notifyError(result.errors);
    }

    if (isDefaultAddress) {
      await updateAccount(accountId, {
        shipping: { account_address_id: result.id },
        billing,
      });
    }

    await fetchRecord(record.id);

    notifySuccess('Billing address added');

    this.setState({ addingBilling: false, selectedAddressId: result.id });
  };

  confirmDefaultCard(card) {
    const { openModal } = this.context;

    return new Promise((resolve) => {
      openModal('Confirm', {
        title: 'Confirm payment method',
        message: (
          <p>
            Customer account will be updated to use{' '}
            <b>
              {card.brand} ending in {card.last4}
            </b>
            .
            <br />
            Any subscription set to the default credit card will now use this
            card.
          </p>
        ),
        action: 'Save',
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  }

  renderCardOption(card) {
    const defaultCardId = this.getDefaultCardId();
    const isDefault = defaultCardId === card.id;

    return (
      <div className="payment-method-option card-option">
        <div className="payment-method-option-container">
          <PaymentCard
            className="payment-method-option-details"
            card={card}
            isDefault={isDefault}
          />
          <Link className="link-button" onClick={this.onClickEditCard}>
            Edit
          </Link>
        </div>
      </div>
    );
  }

  renderOptions = () => {
    const { cards } = this.props;
    const { editing, selectedCardId } = this.state;

    return cards.map((card) => ({
      label: this.renderCardOption(card),
      value: card.id,
      disabled: editing && selectedCardId !== card.id,
    }));
  };

  getValidationRules(cardFieldRule) {
    const { editing } = this.state;
    const rules = [cardFieldRule];

    if (!editing) {
      rules.push('required');
    }

    return rules;
  }

  getAddressOptions() {
    const { addresses } = this.props;

    return [
      { value: NEW_ADDRESS_ID, label: 'New address' },
      { value: NONE_ADDRESS_ID, label: 'None' },
      ...addresses.map((address) => ({
        value: address.id,
        label: makeAddressString(address),
      })),
    ];
  }

  renderCardForm() {
    const { deletable } = this.props;
    const { editing, selectedCardId, selectedAddressId } = this.state;
    const defaultCardId = this.getDefaultCardId();
    const isDefault = defaultCardId === selectedCardId;

    return (
      <FadeInDownBounce>
        <Form onSubmit={editing ? this.onEditCard : this.onAddCard}>
          <div className="payment-method-action payment-card-edit">
            <div className="row">
              {editing ? (
                <Field type="hidden" name="card.id" value={selectedCardId} />
              ) : (
                <Field
                  type="card-number"
                  label="Credit card number"
                  name="card.number"
                  className="span2"
                  rules={this.getValidationRules('credit_card_number')}
                />
              )}
              <Field
                type="card-exp"
                label="Expiration"
                name="card.exp"
                className="span1"
                rules={this.getValidationRules('credit_card_exp')}
              />
              {!editing && (
                <Field
                  type="text"
                  label="CVC"
                  name="card.cvc"
                  className="span1"
                  placeholder="***"
                  rules={this.getValidationRules('credit_card_cvc')}
                />
              )}
            </div>
            <div className="row">
              <Field
                type="select"
                label="Billing address"
                name="address_id"
                className="span4"
                placeholder="Select billing address"
                options={this.getAddressOptions()}
                onClickSelectValue={this.onChangeBilling}
                defaultValue={selectedAddressId}
                autoComplete="off"
              />
            </div>
            <div className="row">
              {!isDefault && (
                <Field
                  type="checkbox"
                  label="Set as default payment method"
                  name="is_default"
                  className="span4"
                  defaultChecked={true}
                  nonValue={false}
                />
              )}
            </div>
          </div>
          <div className="payment-method-action payment-card-submit">
            {editing && deletable && (
              <Link className="link-button danger" onClick={this.onClickDelete}>
                Delete card
              </Link>
            )}
            <Link className="link-button" onClick={this.onClickCancel}>
              Cancel
            </Link>
            <button type="submit" className="button button-sm button-secondary">
              Save card
            </button>
          </div>
        </Form>
      </FadeInDownBounce>
    );
  }

  renderActions() {
    const { adding, editing } = this.state;

    if (adding || editing) {
      return this.renderCardForm();
    }

    return (
      <div className="payment-method-action">
        <button
          type="button"
          className="button button-sm button-secondary"
          onClick={this.onClickAddCard}
        >
          Add card
        </button>
      </div>
    );
  }

  renderEmptyState() {
    return (
      <div className="payment-cards-radio-empty">
        <span>There are no credit cards on file for this customer</span>
        <span className="muted">
          Add a credit card to use for the current and future orders
        </span>
      </div>
    );
  }

  render() {
    const { selectable = true } = this.props;
    const { selectedCardId, adding, addingBilling } = this.state;

    return (
      <PaymentMethodsRadio
        className="payment-cards-radio"
        title="Customer credit cards"
        options={this.renderOptions()}
        actions={this.renderActions()}
        empty={this.renderEmptyState()}
        value={selectedCardId}
        disabled={adding}
        showBilling={addingBilling}
        selectable={selectable}
        onSelect={this.onSelect}
        onClickAddBilling={this.onClickAddBilling}
        onSubmitBilling={this.onAddAddress}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentCardsEditRadio);
