import React from 'react';
import { string, func } from 'prop-types';
import FroalaEditor from 'react-froala-wysiwyg';
import NativeFroalaEditor from 'froala-editor';
import Label from './label';
import './input-editor.scss';

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Froala plugins for toolbar buttons
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/css/plugins/colors.min.css';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/css/plugins/code_view.min.css';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/css/plugins/draggable.min.css';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/css/plugins/emoticons.min.css';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/css/plugins/fullscreen.min.css';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/css/plugins/image.min.css';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/css/plugins/line_breaker.min.css';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/css/plugins/table.min.css';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/css/plugins/video.min.css';
import 'froala-editor/js/plugins/word_paste.min.js';

export default class InputEditor extends React.Component {
  static contextTypes = {
    openModal: func,
  };

  static propTypes = {
    toolbar: string,
    placeholder: string,
    onChange: func,
    id: string,
    label: string,
    help: string,
  };

  constructor(props) {
    super(props);
    this.state = {
      model: this.getInitialModel(props),
      value: '',
      config: {
        ...this.froalaConfig,
        placeholderText: props.placeholder || this.froalaConfig.placeholderText,
        // Large devices (≥ 1200px)
        toolbarButtons: this.toolbars[props.toolbar] || this.toolbars.rich,
        // Medium devices (≥ 992px)
        toolbarButtonsMD:
          this.responsiveToolbarFormatter(4)[props.toolbar] ||
          this.responsiveToolbarFormatter(4).rich,
        // Small devices (≥ 768px)
        toolbarButtonsSM:
          this.responsiveToolbarFormatter(3)[props.toolbar] ||
          this.responsiveToolbarFormatter(3).rich,
        // Extra small devices (< 768px)
        toolbarButtonsXS:
          this.responsiveToolbarFormatter(2)[props.toolbar] ||
          this.responsiveToolbarFormatter(2).rich,
      },
    };

    this.changeTimeout = 0;
  }

  componentWillUnmount() {
    if (this.changeTimeout) {
      clearTimeout(this.changeTimeout);
    }
  }

  // checks if placeholder text changed
  shouldComponentUpdate(nextProps) {
    const oldPlaceholder = this.props.placeholder;
    const { placeholder } = nextProps;

    return oldPlaceholder !== placeholder;
  }

  // update placeholder
  componentDidUpdate(prevProps) {
    if (prevProps.placeholder !== this.props.placeholder) {
      const { editor } = this.refs;
      if (!editor) return;

      const { element } = editor;
      if (!element) return;

      const froalaEl = element['data-froala.editor'];

      if (!froalaEl) return;

      if (froalaEl.opts) {
        froalaEl.opts.placeholderText = this.props.placeholder;
      }
      if (froalaEl.placeholder) {
        froalaEl.placeholder.refresh();
      }
    }
  }

  getInitialModel(props) {
    return (props.value || props.defaultValue || '').toString();
  }

  onModelChange = (model) => {
    // Debounce because there is a lot happening here
    this.setState({ model });

    if (this.changeTimeout) {
      clearTimeout(this.changeTimeout);
    }

    this.changeTimeout = setTimeout(() => {
      this.changeTimeout = 0;
      this.setState({ value: model });
      const event = new Event('change', { bubbles: true });
      this.refs.input.value = model;
      this.refs.input.dispatchEvent(event);
      this.props.onChange(event);
    }, 100);
  };

  onInsertImage = (url) => {
    this.refs.editor.editor.image.insert(url, true);
  };

  openImageModal = () => {
    this.context.openModal('InsertImage', {
      onInsert: this.onInsertImage,
    });
  };

  registerFroalaCommands = () => {
    const component = this;

    NativeFroalaEditor.DefineIcon('insertImage', {
      NAME: 'insertImage',
      SVG_KEY: 'insertImage',
    });

    NativeFroalaEditor.RegisterCommand('insertImage', {
      title: 'Insert Image',
      undo: false,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: true,
      callback: () => component.openImageModal(),
    });
  };

  // Large devices (≥ 1200px)
  toolbars = {
    rich: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'textColor',
          'backgroundColor',
          'clearFormatting',
          'paragraphFormat',
          'formatUL',
          'formatOL',
          'outdent',
          'indent',
          'align',
          'insertLink',
          'insertTable',
          'insertImage',
          'insertVideo',
          'fullscreen',
          'html',
        ],
        buttonsVisible: 20,
      },
    },
    basic: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'textColor',
          'backgroundColor',
          'formatUL',
          'formatOL',
          'align',
          'insertLink',
        ],
        buttonsVisible: 20,
      },
    },
  };

  responsiveToolbarFormatter(buttonsVisible) {
    return {
      rich: {
        moreText: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'textColor',
            'backgroundColor',
            'clearFormatting',
          ],
          buttonsVisible,
          align: 'left',
        },
        moreParagraph: {
          buttons: [
            'paragraphFormat',
            'formatUL',
            'formatOL',
            'outdent',
            'indent',
            'align',
          ],
          buttonsVisible,
          align: 'center',
        },
        moreRich: {
          buttons: ['insertLink', 'insertTable', 'insertImage', 'insertVideo'],
          buttonsVisible,
          align: 'center',
        },
        moreMisc: {
          buttons: ['fullscreen', 'html'],
          buttonsVisible,
          align: 'center',
        },
      },
      basic: {
        moreText: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'textColor',
            'backgroundColor',
          ],
          buttonsVisible,
          align: 'center',
        },
        moreParagraph: {
          buttons: ['formatUL', 'formatOL', 'align'],
          buttonsVisible,
          align: 'center',
        },
        moreRich: {
          buttons: ['insertLink'],
          buttonsVisible,
          align: 'center',
        },
      },
    };
  }

  froalaConfig = {
    // v3 & v4
    key: process.env.REACT_APP_FROALA_KEY,
    toolbarSticky: false,
    charCounterCount: false,
    placeholderText: 'Write something',
    pluginsEnabled: [
      'align',
      'codeBeautifier',
      'codeView',
      'colors',
      'draggable',
      'emoticons',
      'entities',
      'fullscreen',
      'image',
      'lineBreaker',
      'link',
      'lists',
      'paragraphFormat',
      'quote',
      'table',
      'url',
      'video',
      'wordPaste',
    ],
    imageEditButtons: [
      'imageAlign',
      'imageRemove',
      'imageLink',
      'linkOpen',
      'linkEdit',
      'linkRemove',
      '-',
      'imageDisplay',
      'imageAlt',
      'imageSize',
    ],
    videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
    linkInsertButtons: ['linkBack'],
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
    // Setting iframe: true causes TypeError: Cannot read property 'disableBlur' of undefined
    // setting it to false fixes the error, still an open issue
    // https://github.com/froala/react-froala-wysiwyg/issues/274 | https://github.com/froala/wysiwyg-editor/issues/4342
    iframe: false,
    heightMax: 500,
    heightMin: 186,
    quickInsertButtons: false,
    enter: NativeFroalaEditor.ENTER_BR,
    attribution: false,
  };

  render() {
    const { id, label, help } = this.props;

    return (
      <div className="form-editor">
        <Label
          className="form-field-label"
          label={label}
          help={help}
          htmlFor={id}
        />

        <input type="hidden" ref="input" />

        <div
          className="form-field-input"
          onMouseEnter={this.registerFroalaCommands}
        >
          <FroalaEditor
            id={id}
            config={this.state.config}
            model={this.state.model}
            onModelChange={this.onModelChange}
            ref="editor"
            tag="textarea"
          />
        </div>
      </div>
    );
  }
}
