import React, { Fragment } from 'react';
import { find, map, each } from 'lodash';
import moment from 'moment-timezone';
import { css } from '@emotion/css';
import pt from 'prop-types';

import {
  classNames,
  locationWithQuery,
  isEmpty,
  isValueEqual,
  getCookie,
  setCookie,
} from 'utils';

import {
  LOG_COLUMNS,
  LOG_COLUMN_DEFAULTS,
  logMessageFilters,
} from 'constants/logs';

import Loading from 'components/loading';
import { Form } from 'components/form';
import Icon from 'components/icon';
import DropdownButton from 'components/button/dropdown';
import { FadeIn } from 'components/transitions';

import LogsFilters from './logs-filters';
import LogsDetails from './logs-details';
import LogsLine from './logs-line';

import './console.scss';

const LOAD_INTERVAL = 2000;
const LOAD_MULT_TIMES = 3;

const SCROLLER_CLASS = css({
  height: 'calc(100% - 115px)',
  overflowY: 'auto',
});

function getInitialQuery(locationQuery) {
  const query = { ...locationQuery };

  if (query.date === 'current') {
    query.dates = {
      startDate: moment(query.start),
      endDate: moment(query.end),
    };
  } else {
    delete query.start;
    delete query.end;
  }

  return query;
}

function getInitialFilters(locationQuery, allFilters) {
  const filters = {};
  each(locationQuery, (values, id) => {
    if (allFilters[id]) {
      filters[id] = values;
    }
  });
  return filters;
}

function getInitialColumns(locationQuery) {
  let columns = locationQuery?.cols?.split(',') || LOG_COLUMN_DEFAULTS;
  if (columns.length < 1) {
    columns = [LOG_COLUMN_DEFAULTS[0]];
    return;
  }
  return columns;
}

export default class ConsoleLogs extends React.PureComponent {
  static propTypes = {
    location: pt.object,
    loading: pt.bool,
    loaded: pt.bool,
    router: pt.object,
    lines: pt.array,

    onLoadMore: pt.func,
    onLoadPrev: pt.func,
    onSubmitQuery: pt.func,
  };

  static contextTypes = {
    client: pt.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      columns: LOG_COLUMN_DEFAULTS,
      showLineDetail: null,
      showLineDetailParent: null,
      timesLoadedZero: 0,
      moreCount: 0,
      loadingPrev: false,
      loadingNext: false,
      noPrevFound: false,
      noNextFound: false,
      notFoundLabel: '',
      query: {},
      filters: {},
      allFilters: {},
      initialSubmitted: false,
      atBottom: true,
      ...this.getInitialQueryState(props, context),
    };

    this.mounted = false;
    this.loadInterval = 0;
    /** @type {React.MutableRefObject<HTMLDivElement>} */
    this.divScrollRef = React.createRef();
    /** @type {React.RefObject<HTMLDivElement>} */
    this.detailPanelRef = React.createRef();
    /** @type {React.RefObject<import('components/date-range-picker').default>} */
    this.dateRangeRef = React.createRef();
    /** @type {React.RefObject<HTMLTableElement>} */
    this.logTableRef = React.createRef();
    /** @type {React.RefObject<React.Component>} */
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    this.setLoadInterval();

    if (!isEmpty(this.state.query)) {
      const query =
        this.state.query.date === 'current'
          ? this.state.query
          : this.getQueryByLocation(this.props.location);

      this.submitQuery(query);
    }
  }

  componentWillUnmount() {
    this.mounted = false;

    if (this.loadInterval) {
      clearInterval(this.loadInterval);
      this.loadInterval = 0;
    }

    document.removeEventListener('click', this.onClickOffLineDetail);

    this.removeConsoleScrollHandlers();
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading, loaded, lines, location } = this.props;

    // Update not-found label to match date range
    if (prevState.query?.dates !== this.state.dates) {
      const notFoundLabel =
        loaded && !loading && lines.length === 0 && this.getNotFoundLabel();

      this.setState({ notFoundLabel: notFoundLabel || '' });
    }

    // Location query changed
    if (!isValueEqual(prevProps.location.query, location.query)) {
      setCookie('_console_logs_query', location.query);

      // Only submit if something changes besides columns
      const prevQueryWithoutCols = {
        ...prevProps.location.query,
        cols: undefined,
      };
      const queryWithoutCols = { ...location.query, cols: undefined };
      if (!isValueEqual(prevQueryWithoutCols, queryWithoutCols)) {
        const newQuery = this.getQueryByLocation(location);
        this.submitQuery(newQuery);
      }

      // Columns
      if (!isValueEqual(prevProps.location.query?.cols, location.query.cols)) {
        this.setState({ columns: getInitialColumns(location.query) });
      }

      // Filters
      this.setState((state, props) => ({
        filters: getInitialFilters(props.location.query, state.allFilters),
      }));
    }
  }

  /** @param {React.MouseEvent} event */
  onClickOffLineDetail = (event) => {
    if (
      !this.detailPanelRef.current?.contains(event.target) &&
      !this.logTableRef.current?.contains(event.target)
    ) {
      this.setState({
        showLineDetail: null,
        showLineDetailParent: null,
      });

      document.removeEventListener('click', this.onClickOffLineDetail);
    }
  };

  submitQuery(query) {
    if (this.loadInterval) {
      clearInterval(this.loadInterval);
      this.loadInterval = 0;
    }

    this.removeConsoleScrollHandlers();

    this.setState(
      { initialSubmitted: true, noPrevFound: false, noNextFound: false },
      () => {
        this.props.onSubmitQuery(query).then(() => {
          const $console = this.getConsoleContainer();

          if ($console) {
            $console.scrollTop = $console.scrollHeight;
          }

          this.addConsoleScrollHandlers();
          this.setLoadInterval();
        });
      },
    );
  }

  getInitialQueryState(props, context) {
    let locationQuery = !isEmpty(props.location.query)
      ? props.location.query
      : null;

    // Restore query from cookie if current location query is empty
    if (!locationQuery) {
      const locationQueryCookie = getCookie('_console_logs_query');

      if (!isEmpty(locationQueryCookie)) {
        locationQuery = locationQueryCookie;

        this.props.router.replace(
          locationWithQuery(props.location, locationQueryCookie),
        );
      }
    }

    locationQuery = locationQuery || {};

    const allFilters = logMessageFilters(context.client.apps.results);

    return {
      initialDateOption: locationQuery.date || 'alltime',
      query: getInitialQuery(locationQuery),
      filters: getInitialFilters(locationQuery, allFilters),
      columns: getInitialColumns(locationQuery),
      allFilters,
    };
  }

  getQueryByLocation(location) {
    const dateOption = this.getDateOptionByValue(location.query.date);
    return {
      ...this.state.query,
      ...location.query,
      dates: dateOption?.range?.startDate
        ? {
            startDate: dateOption.range.startDate.toISOString(),
            endDate: dateOption.range.endDate.toISOString(),
          }
        : undefined,
      date: dateOption?.value,
      // Unset if exists in location query
      cols: undefined,
      start: undefined,
      end: undefined,
    };
  }

  getDateOptionByValue(optionValue = 'alltime') {
    const rangeOptions = this.dateRangeRef.current.dateRangeOptions;
    const option = rangeOptions.find((option) => option.value === optionValue);

    if (option?.value === 'current') {
      return { ...option, range: { ...this.state.query.dates } };
    }

    return option;
  }

  setLoadInterval(interval = LOAD_INTERVAL) {
    if (this.loadInterval) {
      clearInterval(this.loadInterval);
      this.loadInterval = 0;
    }

    if (!this.mounted) {
      return;
    }

    this.loadInterval = setInterval(() => {
      this.loadNextLines();
    }, interval);
  }

  async loadNextLines() {
    const { loaded, onLoadMore } = this.props;
    const { noNextFound, loadingNext, atBottom, query } = this.state;

    if (!loaded || noNextFound || loadingNext) return;

    this.setState({ loadingNext: true });

    const moreCount = await onLoadMore();

    this.setState({ moreCount, loadingNext: false }, () => {
      if (atBottom) {
        const $console = this.getConsoleContainer();
        $console.scrollTop = $console.scrollHeight;
      }

      // Checks to see if scroll is at the top to load prev lines next
      this.onConsoleScroll();
    });

    // Do not request new logs when we have reached the end of the selected period
    if (moreCount === 0 && query.date === 'current') {
      if (this.loadInterval) {
        clearInterval(this.loadInterval);
        this.loadInterval = 0;
      }

      this.setState({ noNextFound: true });
    }
    // This means that there may be another page of logs
    else if (moreCount === this.state.moreCount) {
      this.setState((state) => {
        if (state.timesLoadedZero > 1) {
          this.setLoadInterval(
            LOAD_INTERVAL *
              (state.timesLoadedZero > LOAD_MULT_TIMES
                ? LOAD_MULT_TIMES
                : state.timesLoadedZero),
          );
        } else {
          this.setLoadInterval();
        }

        return { timesLoadedZero: state.timesLoadedZero + 1 };
      });
    }
  }

  async loadPrevLines() {
    const { loaded, onLoadPrev } = this.props;
    const { noPrevFound, loadingPrev } = this.state;

    if (!loaded || noPrevFound || loadingPrev) return;

    this.setState({ loadingPrev: true });
    const prevCount = await onLoadPrev();
    this.setState({ loadingPrev: false });

    if (prevCount === 0) {
      this.setState({ noPrevFound: true });
    } else if (prevCount > 0) {
      // Scroll down by 1px to prevent scroll event from firing again automatically
      const $console = this.getConsoleContainer();

      if ($console.scrollTop < 1) {
        $console.scrollTop = 45;
      }
    }
  }

  getConsoleContainer() {
    return this.divScrollRef.current;
  }

  addConsoleScrollHandlers() {
    this.getConsoleContainer().addEventListener('scroll', this.onConsoleScroll);
  }

  removeConsoleScrollHandlers() {
    this.getConsoleContainer().removeEventListener(
      'scroll',
      this.onConsoleScroll,
    );
  }

  onConsoleScroll = () => {
    const $console = this.getConsoleContainer();

    if ($console.scrollTop < 10) {
      this.loadPrevLines();
      return;
    }

    const scrollHeight = $console.scrollHeight - $console.offsetHeight;

    if (scrollHeight - $console.scrollTop > 20) {
      if (this.state.atBottom) {
        this.setState({ atBottom: false });
      }
    } else if (scrollHeight - 20 < $console.scrollTop) {
      if (!this.state.atBottom) {
        this.setState({ atBottom: true });
      }
    }
  };

  onClickBackToBottom = () => {
    const $console = this.getConsoleContainer();

    if ($console) {
      $console.scrollTo({ top: $console.scrollHeight, behavior: 'smooth' });
    }
  };

  onClickToggleColumn = (event) => {
    const { id } = event.currentTarget.dataset;
    const { location } = this.props;
    const { columns } = this.state;

    let nextColumns = [...columns];

    // Remove or add
    if (nextColumns.includes(id)) {
      nextColumns = nextColumns.filter((col) => col !== id);
      // Must have at least one additional column visible
      if (nextColumns.length === 1) {
        return;
      }
    } else {
      // Make sure columns are added in the expected order
      nextColumns = map(LOG_COLUMNS, (_col, key) => key).filter(
        (key) => columns.includes(key) || key === id,
      );
    }

    if (isValueEqual(LOG_COLUMN_DEFAULTS, nextColumns)) {
      nextColumns = undefined;
    }

    const queryColumns = nextColumns?.toString();
    const shouldSetColumnLocation = location.query?.cols !== queryColumns;
    if (shouldSetColumnLocation) {
      this.updateLocationQuery({ cols: queryColumns });
    }
  };

  onClickLineDetails = (event) => {
    const { id, parent } = event.currentTarget.dataset;
    const { showLineDetail } = this.state;

    if (id && showLineDetail?.id !== id) {
      this.setState(
        (_, props) => {
          const parentLine = parent
            ? props.lines.find((line) => line.id === parent)
            : null;

          const line = (parentLine?.subLines || props.lines).find(
            (line) => line.id === id,
          );

          return {
            showLineDetail: line || null,
            showLineDetailParent: parentLine || null,
          };
        },
        () => document.addEventListener('click', this.onClickOffLineDetail),
      );
    } else {
      this.setState({
        showLineDetail: null,
        showLineDetailParent: null,
      });
    }
  };

  updateLocationQuery(query) {
    const next = locationWithQuery(this.props.location, {
      ...query,
      dates: undefined,
      // Pass custom dates to the query string so that query updating works
      start:
        query.date === 'current'
          ? query.dates.startDate.toISOString()
          : undefined,
      end:
        query.date === 'current'
          ? query.dates.endDate.toISOString()
          : undefined,
    });

    this.props.router.push(next);
  }

  onSubmitForm = (values) => {
    const query = { ...this.state.query };
    if (String(values.search).length > 0) {
      query.search = values.search;
    } else {
      // Remove search key when cleared
      query.search = undefined;
    }
    this.setState({ query }, () => this.updateLocationQuery(query));
  };

  /** @param {React.KeyboardEvent} event */
  onKeyDownSearchEscape = (event) => {
    if (event.key === 'Escape') {
      this.searchRef.current.setState({ value: '' });

      // Remove search key when cleared
      const query = { ...this.state.query, search: undefined };

      this.setState({ query }, () => this.updateLocationQuery(query));
    }
  };

  onChangeDates = ({ option, startDate, endDate, selected }) => {
    const { location } = this.props;

    const query = {
      ...this.state.query,
      dates: { startDate, endDate },
      date: option.value,
    };

    this.setState({ query }, () => {
      const isDefault = option.value === 'alltime';
      const isQueryDefault =
        !location.query.date || location.query.date === 'alltime';
      const nextDate = isDefault ? undefined : option.value;
      const queryDate = isQueryDefault ? undefined : location.query.date;
      const shouldSetDateLocation = selected || queryDate !== nextDate;

      // Update location if changing to or from non-default
      if (shouldSetDateLocation) {
        this.updateLocationQuery({
          ...query,
          date: nextDate,
        });
      } else if (!this.state.initialSubmitted) {
        // Submit directly on initial load
        this.submitQuery({
          ...query,
          ...location.query,
        });
      }
    });
  };

  /** @param {React.MouseEvent} event */
  onClickToggleFilter = (event) => {
    const { id, value } = event.currentTarget.dataset;

    let newValues = [...(this.state.filters[id] || [])];

    if (newValues.includes(value)) {
      newValues = newValues.filter((val) => val !== value);
    } else {
      newValues.push(value);
    }

    if (newValues.length === 0) {
      newValues = undefined;
    }

    const query = { ...this.state.query, [id]: newValues };

    this.setState(
      (state) => ({ filters: { ...state.filters, [id]: newValues }, query }),
      () => this.updateLocationQuery(query),
    );
  };

  onResetFilters = () => {
    const query = Object.keys(this.props.location.query).reduce((acc, key) => {
      acc[key] = undefined;
      return acc;
    }, {});

    this.setState(
      { columns: LOG_COLUMN_DEFAULTS, filters: {}, query: {} },
      () => this.updateLocationQuery(query),
    );
  };

  renderColumns() {
    const { columns } = this.state;

    const cols = columns.reduce((acc, id) => {
      const col = LOG_COLUMNS[id];

      if (col) {
        acc.push(
          <th className={`console-col-${id}`} key={id}>
            {col.label}
          </th>,
        );
      }

      return acc;
    }, []);

    return [
      ...cols,
      <th width="10" key="column-dropdown">
        <DropdownButton
          key="2"
          anchor="right"
          alignOffset={15}
          arrow={false}
          button={false}
          keepOpen={true}
          items={map(
            LOG_COLUMNS,
            (col, id) =>
              col.toggle !== false && (
                <button
                  key={id}
                  data-id={id}
                  onClick={this.onClickToggleColumn}
                  type="button"
                >
                  {col.label}

                  <span className={columns.includes(id) ? '' : 'invisible'}>
                    <Icon fa="check" />
                  </span>
                </button>
              ),
          )}
        >
          <span className="console-logs-cols-button">
            <Icon fa="gear" />
          </span>
        </DropdownButton>
      </th>,
    ];
  }

  renderAllLines(subLines = undefined, parentLine = undefined) {
    const { location } = this.props;
    const { showLineDetail, columns } = this.state;

    const selectedLineId = showLineDetail?.id;

    return (subLines || this.props.lines).map((line) => (
      <Fragment key={line.id}>
        <LogsLine
          line={line}
          parentLine={parentLine}
          location={location}
          selected={selectedLineId === line.id}
          columns={columns}
          onClick={this.onClickLineDetails}
        />

        {line.subLines?.length > 0 && this.renderAllLines(line.subLines, line)}
      </Fragment>
    ));
  }

  renderFilterDescription() {
    const { filters, allFilters } = this.state;

    const filterDescriptions = map(filters, (values, id) => {
      const filter = allFilters[id];
      if (!filter || !values?.length) {
        return;
      }
      return (
        <div className="console-logs-filter-description-kind" key={id}>
          <strong>{filter.label}:</strong>
          <span>
            {values
              .map((value) => find(filter.options, { value })?.label)
              .join(', ')}
          </span>
        </div>
      );
    });

    if (!filterDescriptions.length) {
      return null;
    }

    return (
      <div className="console-logs-filter-description">
        {filterDescriptions}
      </div>
    );
  }

  getNotFoundLabel() {
    const { query } = this.state;

    if (query?.dates?.startDate) {
      const rangeOptions = this.dateRangeRef.current.dateRangeOptions;
      const option = find(
        rangeOptions,
        (option) =>
          option.range?.startDate.toISOString() ===
          query.dates.startDate.toISOString(),
      );
      if (option) {
        return option.label;
      }
    }

    return '';
  }

  getRef = (elem) => {
    if (this.divScrollRef.current) {
      this.removeConsoleScrollHandlers();
    }

    this.divScrollRef.current = elem;

    if (elem) {
      this.addConsoleScrollHandlers();
    }
  };

  render() {
    const { loading, loaded, lines, location } = this.props;

    const {
      columns,
      showLineDetail,
      notFoundLabel,
      initialDateOption,
      noPrevFound,
      noNextFound,
      atBottom,
    } = this.state;

    return (
      <div
        className={classNames('console-logs', {
          'console-loading': !loaded && loading,
        })}
      >
        <div
          className={classNames('console-logs-bottom-link note', {
            hidden: atBottom,
          })}
        >
          <button
            className="as-link"
            onClick={this.onClickBackToBottom}
            type="button"
          >
            &darr; Back to bottom
          </button>
        </div>

        <Form onSubmit={this.onSubmitForm} className="console-logs-container">
          <fieldset className="full">
            <div className="console-box-table">
              <LogsFilters
                location={location}
                initialDateOption={initialDateOption}
                filters={this.state.filters}
                allFilters={this.state.allFilters}
                searchRef={this.searchRef}
                dateRangeRef={this.dateRangeRef}
                onChangeDates={this.onChangeDates}
                onResetFilters={this.onResetFilters}
                onClickToggleFilter={this.onClickToggleFilter}
                onKeyDownSearchEscape={this.onKeyDownSearchEscape}
              />

              <div ref={this.getRef} className={SCROLLER_CLASS}>
                {showLineDetail !== null && (
                  <FadeIn duration={75} id="console-logs-details">
                    <LogsDetails
                      line={showLineDetail}
                      parent={this.state.showLineDetailParent}
                      location={location}
                      detailPanelRef={this.detailPanelRef}
                      onClickLineDetails={this.onClickLineDetails}
                    />
                  </FadeIn>
                )}

                <div className="console-box-table-container">
                  <table ref={this.logTableRef} className="collection-table">
                    {lines.length > 0 ? (
                      <Fragment>
                        <thead id="console-logs-table-head">
                          <tr>{this.renderColumns()}</tr>
                        </thead>

                        <tbody>
                          {noPrevFound ? (
                            <tr className="console-logs-count-row">
                              <td colSpan={columns.length + 1}>End of logs</td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={columns.length + 1}>
                                <div className="console-logs-loading">
                                  <Loading width={20} height={20} />
                                </div>
                              </td>
                            </tr>
                          )}

                          {this.renderAllLines()}

                          {noNextFound ? (
                            <tr className="console-logs-count-row">
                              <td colSpan={columns.length + 1}>End of logs</td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={columns.length + 1}>
                                <div className="console-logs-loading">
                                  <Loading width={20} height={20} />

                                  {this.renderFilterDescription()}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Fragment>
                    ) : (
                      <tbody>
                        <tr className="console-logs-empty">
                          <td>
                            {loaded
                              ? `No logs found ${
                                  notFoundLabel
                                    ? `(${notFoundLabel.toLowerCase()})`
                                    : ''
                                }`
                              : loading
                              ? 'Loading...'
                              : ''}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </fieldset>

          {!loaded && loading && (
            <div className="console-logs-submit-loading">
              <Loading width={40} height={40} />
            </div>
          )}
        </Form>
      </div>
    );
  }
}
