import { find, findIndex, assign, isArray, isEmpty, remove } from 'lodash';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { LOAD_THEME_CONFIG_SUCCESS, SET_THEME_HOME_PAGE } from './types';
import actions from 'actions';
import settingActions from 'actions/settings';
import api from 'services/api';

export const loadThemeConfigSuccess = (themeConfig) => ({
  type: LOAD_THEME_CONFIG_SUCCESS,
  payload: themeConfig,
});

export const loadThemeConfig = () => async (dispatch, getState) => {
  const {
    storefronts: { storefront },
  } = getState();

  dispatch(showLoading());

  const isTheme = Boolean(storefront.editor);

  let config = {};
  if (isTheme) {
    config = await getConfigFromSettings(storefront, dispatch);
  }

  if (!isArray(config.settings)) {
    config.settings = [];
  }
  if (!isArray(config.pages)) {
    config.pages = [];
  }

  if (isTheme) {
    await populatePages(config);
  }

  // Only populate checkout settings if not using custom checkout
  const checkoutSettings = await dispatch(settingActions.load('checkout'));
  if (!checkoutSettings?.custom_checkout) {
    await populateCheckoutSettings(config, checkoutSettings);
  }

  ensureConfigDefaults(config);

  dispatch(loadThemeConfigSuccess(config));
  dispatch(hideLoading());
};

export const updateThemeConfigPage =
  (page, prev = {}) =>
  (dispatch, getState) => {
    const { themeConfig: config } = getState();
    dispatch(showLoading());
    const exIndex = findIndex(config.pages, {
      id: prev.slug || prev.id || page.slug || page.id,
    });
    let exPage = config.pages[exIndex];
    if (!exPage) {
      return;
    }
    const pageContent = `/content/pages/${page.slug || page.id}`;
    exPage = config.pages[exIndex] = {
      ...exPage,
      id: page.slug || page.id,
      label: page.name,
      route: config.page_route.replace(':slug', page.slug || page.id),
      content: pageContent,
      published: page.published,
    };
    dispatch(loadThemeConfigSuccess(config));
    dispatch(hideLoading());
    return exPage;
  };

export const setThemeHomePage = (value) => async (dispatch, getState) => {
  try {
    const {
      storefronts: { storefront },
    } = getState();

    await api.put('/data/:storefronts/{id}/configs/settings', {
      id: storefront.id,
      values: { home_page: value },
    });
    return dispatch({
      type: SET_THEME_HOME_PAGE,
      payload: value,
    });
  } catch (err) {
    console.error(`Unable to set home page: ${err.message}`);
    dispatch(actions.flash.error(`An error occurred setting home page`));
  }
};

async function getConfigFromSettings(storefront) {
  try {
    const editor = await api.get('/data/:storefronts/{id}/configs/editor', {
      id: storefront.id,
    });
    return editor?.values || {};
  } catch (err) {
    console.error(`Unable to retrieve editor settings: ${err.message}`);
    return {};
  }
}

function ensureConfigDefaults(config) {
  for (let setting of config.settings) {
    setting.id = setting.id || String(setting.name).toLowerCase().trim();
  }
  for (let page of config.pages) {
    page.id =
      page.id ||
      String(page.label || page.name)
        .toLowerCase()
        .trim();
  }
}

async function populatePages(config) {
  config.page_route = config.pageRoute || config.page_route || '/:slug';

  const homePageSlug = config.home_page || 'home';

  const otherPages = await api.get('/data/content/pages', {
    fields: 'slug, name, published',
    limit: 100,
  });

  if (otherPages && otherPages.results.length > 0) {
    for (let page of otherPages.results) {
      const pageContent = `/content/pages/${page.slug || page.id}`;
      const pageEx = find(config.pages, { content: pageContent });
      if (pageEx) {
        pageEx.id = page.slug || page.id;
      } else {
        config.pages.push({
          id: page.slug || page.id,
          label: page.name,
          route: config.page_route.replace(':slug', page.slug || page.id),
          content: pageContent,
          icon: 'file',
          page: true,
          published: page.published,
        });
      }
    }
  }

  const homePageContent = `/content/pages/${homePageSlug}`;
  const homePage = find(config.pages, { content: homePageContent });

  if (homePage) {
    remove(config.pages, homePage);
    config.pages.unshift(homePage);
    homePage.home = true;
    homePage.icon = homePage.icon || 'home';
  } else {
    config.pages.unshift({
      label: 'Home',
      route: '/',
      content: homePageContent,
      home: true,
      icon: 'home',
      page: true,
    });
  }
}

async function populateCheckoutSettings(config, checkoutSettings = undefined) {
  config.checkout =
    checkoutSettings?.editor ||
    (await api.getLocalized('/data/settings/checkout/editor'));
}
