import React from 'react';
import Icon from 'components/icon';
import Link from 'components/link';
import User from '../utils/user';
import Date from '../utils/date';
import { formatDate } from 'utils';

export default function SubscriptionInvoiceCreated({ event }) {
  const { id, number, date_period_start, date_period_end } = event.data;
  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/payment" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          Subscription <Link to={`/invoices/${id}`}>invoice {number}</Link>{' '}
          created for {formatDate(date_period_start, 'short')} &mdash;{' '}
          {formatDate(date_period_end, 'short')}
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
      </div>
    </div>
  );
}
