import React, { Fragment } from 'react';
import pt from 'prop-types';
import {
  get,
  minBy,
  maxBy,
  reduce,
  isEmpty,
  find,
  map,
  intersection,
} from 'lodash';

import { formatCurrency } from 'utils';
import { renderMultiCurrencyValuesTooltip } from 'utils/money';
import {
  productPrice,
  productSalePrice,
  productMinMaxPrice,
} from 'utils/product';

import SubscriptionPrice from 'components/pages/subscription/price';

function ProductPrice({ product, variant, currency, quantity = 1 }) {
  if (!product) {
    return null;
  }

  if (variant) {
    // Has variant or subscription

    if (shouldShowSubscriptionPrice(product, variant)) {
      return (
        <SubscriptionPrice
          product={product}
          variant={variant}
          currency={currency}
          quantity={quantity}
        />
      );
    }

    return getVariantPrice(product, variant, quantity, currency);
  }

  // No variants or subscriptions.

  if (typeof product.price !== 'number') {
    return <span className="muted">&mdash;</span>;
  }

  if (product.type === 'giftcard') {
    const values = get(product, 'options[0].values');
    const lowValue = minBy(values, 'price');
    const highValue = maxBy(values, 'price');

    if (lowValue && highValue && lowValue !== highValue) {
      return (
        <span>
          {formatCurrency(lowValue.price, product.currency)} &mdash;{' '}
          {formatCurrency(highValue.price, product.currency)}
        </span>
      );
    }

    if (lowValue || highValue) {
      return (
        <span>
          {formatCurrency(
            lowValue ? lowValue.price : highValue.price,
            product.currency,
          )}
        </span>
      );
    }

    return <span className="muted">&mdash;</span>;
  }

  return getProductPrice(product, quantity, currency);
}

ProductPrice.propTypes = {
  product: pt.object,
  variant: pt.object,
  currency: pt.string,
  quantity: pt.number,
};

export default React.memo(ProductPrice);

function getVariantPrice(product, variant, quantity, currency) {
  const price = productPrice(product, variant);
  const salePrice = productSalePrice(product, variant);

  if (Number.isNaN(price) && Number.isNaN(salePrice)) {
    return null;
  }

  const sale = (product && product.sale) || (variant && variant.sale);
  const isSale = !Number.isNaN(salePrice) && sale;
  const actualCurrency = currency || (product && product.currency);

  return (
    <span className="nowrap">
      {isSale ? (
        <Fragment>
          <strike>{formatCurrency(price * quantity, actualCurrency)}</strike>
          &nbsp;
          <span className="positive">
            {formatCurrency(salePrice * quantity, actualCurrency)}
            {renderMultiCurrencyPriceTooltip(
              product,
              variant,
              quantity,
              actualCurrency,
            )}
          </span>
        </Fragment>
      ) : (
        <Fragment>
          {formatCurrency(price * quantity, actualCurrency)}
          {renderMultiCurrencyPriceTooltip(
            product,
            variant,
            quantity,
            actualCurrency,
          )}
        </Fragment>
      )}
    </span>
  );
}

function renderMultiCurrencyPriceTooltip(product, variant, quantity, currency) {
  const $currency = reduce(
    product.$currency,
    (acc, values, code) => {
      const currencyProduct = {
        ...product,
        price: undefined,
        sale_price: undefined,
        code,
        ...(values || undefined),
      };

      const currencyVariant = variant && {
        ...variant,
        price: undefined,
        sale_price: undefined,
        ...get(variant.$currency, code, undefined),
      };

      const price = productPrice(currencyProduct, currencyVariant);
      const salePrice = productSalePrice(currencyProduct, currencyVariant);

      const hasPrice = !Number.isNaN(price);
      const hasSalePrice = !Number.isNaN(salePrice);

      if (!hasPrice && !hasSalePrice) {
        return acc;
      }

      const isSale =
        (product.sale || (variant && variant.sale)) && hasSalePrice;

      if (!hasPrice && !isSale) {
        return acc;
      }

      acc[code] = { price: quantity * (isSale ? salePrice : price) };

      return acc;
    },
    {},
  );

  if (isEmpty($currency)) {
    return null;
  }

  return renderMultiCurrencyValuesTooltip($currency, 'price', currency, 'left');
}

function getProductPrice(product, quantity, currency) {
  const { minPrice, maxPrice, minSalePrice, maxSalePrice } =
    productMinMaxPrice(product);

  const actualCurrency = currency || (product && product.currency);

  const priceMinMax = getFormattedPriceRange(
    minPrice,
    maxPrice,
    quantity,
    actualCurrency,
  );

  const salePriceMinMax = getFormattedPriceRange(
    minSalePrice,
    maxSalePrice,
    quantity,
    actualCurrency,
  );

  return (
    <span className="nowrap">
      {!Number.isNaN(minSalePrice) ? (
        <Fragment>
          <strike>{priceMinMax}</strike>
          &nbsp;
          <span className="positive">
            {salePriceMinMax}
            {renderMultiCurrencyPriceTooltip(
              product,
              undefined,
              quantity,
              actualCurrency,
            )}
          </span>
        </Fragment>
      ) : (
        <Fragment>
          {priceMinMax}
          {renderMultiCurrencyPriceTooltip(
            product,
            undefined,
            quantity,
            actualCurrency,
          )}
        </Fragment>
      )}
    </span>
  );
}

/**
 * @param {number} minPrice
 * @param {number} maxPrice
 * @param {number} quantity
 * @param {string?} currency
 * @returns {JSX.Element}
 */
function getFormattedPriceRange(minPrice, maxPrice, quantity, currency) {
  return (
    <Fragment>
      {formatCurrency((minPrice || 0) * quantity, currency)}

      {maxPrice > minPrice ? (
        <Fragment>
          {' '}
          &mdash; {formatCurrency(maxPrice * quantity, currency)}
        </Fragment>
      ) : null}
    </Fragment>
  );
}

function shouldShowSubscriptionPrice(product, variant) {
  if (!product || !variant) {
    return false;
  }

  const option = find(product.options, { subscription: true });

  if (!option) {
    return false;
  }

  return (
    intersection(map(option.values, 'id'), variant.option_value_ids).length > 0
  );
}
