import React from 'react';
import { classNames } from 'utils';
import PropTypes from 'prop-types';

import Button from '../button/button';
import Loading from '../loading/loading';

export default class FormButton extends React.PureComponent {
  static contextTypes = {
    registerButton: PropTypes.func,
    unregisterButton: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: props.loading || false,
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.loading !== undefined) {
      return { loading: props.loading };
    }

    return null;
  }

  componentDidMount() {
    if (this.context.registerButton) {
      this.context.registerButton(this);
    }
  }

  componentWillUnmount() {
    if (this.context.unregisterButton) {
      this.context.unregisterButton(this);
    }
  }

  /**
   * This function is called from outside, by reference to the component.
   *
   * @param {boolean} loading
   * @returns {void}
   */
  loading(loading) {
    this.setState({ loading });
  }

  render() {
    const { type, children, className, loading, ...props } = this.props;

    const isLoading = Boolean(loading || this.state.loading);

    return (
      <Button
        {...props}
        type={type}
        className={classNames(className, { 'button-loading': isLoading })}
      >
        {children}

        {(type === 'submit' || loading !== undefined) && (
          <Loading when={isLoading} />
        )}
      </Button>
    );
  }
}
