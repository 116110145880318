import PropTypes from 'prop-types';
import React from 'react';
import Loading from 'components/loading';
import { FadeIn } from 'components/transitions';
import './bulk.scss';

export default class BulkProgress extends React.PureComponent {
  static propTypes = {
    percent: PropTypes.any,
    message: PropTypes.any,
  };

  render() {
    const { percent, message } = this.props;

    return (
      <FadeIn key="progress" className="bulk-loading">
        <Loading />
        {percent !== undefined && <div className="note">{percent}%</div>}
        {message !== undefined && <div className="message">{message}</div>}
      </FadeIn>
    );
  }
}
