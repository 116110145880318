import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import actions from 'actions';

import { confirmRouteLeave, confirmPageLeave } from 'utils/container';

import LoadingView from 'components/view/loading';
import EditPage from 'components/model/edit';
import NotFoundPage from 'components/pages/error/404';

export const mapStateToProps = (state) => ({
  client: state.client,
  content: state.content,
  collection: state.data.collection,
  selection: state.data.selection,
  loading: state.content.loading,
  lookup: state.lookup,
  categories: state.categories,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchRecord: (id) => {
    return dispatch(actions.content.fetchCollectionModel(id));
  },

  updateRecord: (id, data) => {
    return dispatch(actions.content.updateCollectionModel(id, data));
  },

  updateRecordFields: (id, data) => {
    return dispatch(actions.content.updateCollectionModelFields(id, data));
  },

  deleteRecord: (id) => {
    return dispatch(actions.content.deleteCollectionModel(id));
  },

  loadCategories: () => {
    return dispatch(actions.categories.load());
  },
});

export class EditModel extends React.PureComponent {
  static contextTypes = {
    notifyError: PropTypes.func.isRequired,
    notifyDeleted: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      edited: false,
      editedConfirm: false,
      record: {},
      onSubmitRecord: this.onSubmitRecord.bind(this),
      onClickDelete: this.onClickDelete.bind(this),
      onChangeForm: this.onChangeForm.bind(this),
      setEditedConfirm: this.setEditedConfirm.bind(this),
    };
  }

  componentDidMount() {
    const { params, fetchRecord, loadCategories } = this.props;

    confirmRouteLeave(this);

    Promise.all([fetchRecord(params.id), loadCategories()]).then(([record]) => {
      this.setState({
        loaded: true,
        record,
        values: { ...this.getValuesFromRecord(record) },
      });
    });
  }

  async componentWillReceiveProps(nextProps) {
    const { params, fetchRecord } = this.props;

    if (params.id !== nextProps.params.id) {
      this.setState({ loaded: false });
      const record = await fetchRecord(nextProps.params.id);
      this.setState({
        loaded: true,
        record,
        values: { ...this.getValuesFromRecord(record) },
      });
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  onChangeForm(values, edited) {
    this.setState({
      edited,
      values: { ...this.getValuesFromRecord(), ...values, json: undefined },
    });
  }

  setEditedConfirm(confirm) {
    this.setState({
      editedConfirm: confirm,
    });
  }

  getValuesFromRecord(record = this.state.record) {
    if (!record) {
      return {};
    }
    return {
      ...record,
    };
  }

  onClickDelete(event) {
    event.preventDefault();
    const { record } = this.state;
    this.context.openModal('ConfirmDelete', {
      titleMessage: record.standard
        ? `Reset ${record.name}`
        : `Delete ${record.name}`,
      action: record.standard ? 'Reset' : 'Delete',
      message: (
        <p>
          Are you sure you want to{' '}
          {record.standard
            ? `reset ${record.name} to the standard configuration`
            : 'delete this model'}
          ?
          <br />
          This action{' '}
          {record.standard ? 'will remove all custom fields and ' : ''}can't be
          undone.
        </p>
      ),
      actionType: 'danger',
      confirmInput: {
        description: `Enter the collection name to confirm`,
        value: record.name,
      },
      loadingTitle: this.state.loading
        ? record.standard
          ? 'Resetting model'
          : 'Deleting model'
        : undefined,
      onConfirm: () => {
        const { params, router, deleteRecord, fetchRecord } = this.props;
        this.setState({ loading: true });
        return deleteRecord(params.id).then(async (result) => {
          if (result && !result.errors) {
            if (record.standard) {
              const record2 = await fetchRecord(params.id);
              this.setState({
                edited: false,
                record: record2,
                values: { ...this.getValuesFromRecord(record2) },
              });
            } else {
              this.setState({ edited: false }, () => {
                router.replace(`/settings/models`);
                this.context.notifyDeleted('Content model');
              });
            }
          }
          this.setState({ loading: undefined });
        });
      },
    });
  }

  async onSubmitRecord(values, fieldsOnly = false) {
    const { params, updateRecord, updateRecordFields, fetchRecord } =
      this.props;

    this.setState({ loading: false });

    const result = fieldsOnly
      ? await updateRecordFields(params.id, {
          ...this.state.record,
          ...values,
        })
      : await updateRecord(params.id, {
          ...this.state.record,
          ...values,
        });

    if (result) {
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        const record = await fetchRecord(params.id);
        this.setState({
          edited: false,
          record,
          values: { ...this.getValuesFromRecord(record) },
        });
        this.setState({ loading: undefined });
        return true;
      }
    }

    this.setState({ loading: undefined });
    return false;
  }

  render() {
    if (!this.state.loaded) {
      return <LoadingView />;
    }

    if (!this.state.record) {
      return <NotFoundPage />;
    }

    return <EditPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditModel);
