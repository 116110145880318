import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import View from 'components/view';
import ThemeSupportAlert from './theme-support-alert';
import { Form, Field, FieldLocalized, Fieldgroup } from 'components/form';
import SectionHeader from 'components/section-header';
import StorefrontDocs from './docs';

export default class StorefrontSettings extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  render() {
    const {
      record,
      values,
      edited,
      onSubmitForm,
      onChangeForm,
      viewProps = {},
    } = this.props;

    const { client } = this.context;

    const urlPlaceholder = `https://www.${client.id || 'example'}.com`;

    return (
      <View
        {...viewProps}
        headerActions={[
          ...viewProps.headerActions,
          {
            label: 'Save changes',
            type: edited ? 'default' : 'secondary disabled',
            submit: true,
          },
        ]}
        form={this.refs.form}
        localized={true}
      >
        <Form ref="form" onSubmit={onSubmitForm} onChange={onChangeForm}>
          {record.hosted && <ThemeSupportAlert />}
          <div>
            <fieldset>
              <div className="view-body-subsection">
                <div className="row">
                  <Field
                    type="text"
                    name="name"
                    label="Storefront name"
                    defaultValue={record.name}
                    placeholder="My storefront"
                    required={true}
                    className="span4"
                    help="Name used to identify this storefront"
                  />
                </div>
                {!record.hosted ? (
                  <div className="row">
                    <Field
                      type="text"
                      name="custom_url"
                      label="Storefront URL"
                      defaultValue={values.external?.custom_url}
                      placeholder={urlPlaceholder}
                      rules="secure_url"
                      className="span4"
                      help="URL should point to a storefront application"
                    />
                  </div>
                ) : (
                  <Fragment />
                )}
              </div>

              {record.hosted ? (
                <Fragment>
                  <a name="password" ref="passwordAnchor" />

                  <div className="view-body-subsection">
                    <div className="view-body-subheader">
                      <SectionHeader
                        className="view-body-subtitle"
                        title="Password protection"
                        subtitle="Enable a password to restrict access to your storefront"
                      />
                    </div>

                    <div className="row">
                      <Field
                        type="toggle"
                        name="password_enabled"
                        label="Enabled"
                        defaultChecked={record.hosting?.password_enabled}
                      />
                    </div>

                    {values.password_enabled && (
                      <Field
                        type="text"
                        name="password"
                        label="Password"
                        defaultValue={record.hosting?.password}
                        disabled={!values.password_enabled}
                        hint="Visitors will be asked to enter this password if enabled"
                      />
                    )}

                    {values.password_enabled && (
                      <FieldLocalized
                        type="textarea"
                        name="password_message"
                        label="Message to visitors"
                        defaultValue={record.hosting?.password_message}
                        localeValue={record.$locale}
                        disabled={!values.password_enabled}
                        rows={3}
                        maxRows={5}
                        autoSize={true}
                        hint="This will be displayed to visitors when they're asked to log in"
                      />
                    )}
                  </div>
                </Fragment>
              ) : (
                <Fragment></Fragment>
              )}
              {!record.hosted &&
                (record.external?.custom_url ? (
                  <Fieldgroup
                    label="Framework & hosting docs"
                    defaultActive={false}
                  >
                    <StorefrontDocs />
                  </Fieldgroup>
                ) : (
                  <StorefrontDocs />
                ))}
            </fieldset>
          </div>
        </Form>
      </View>
    );
  }
}
