import React from 'react';
import Link from 'components/link';
import { Form, Field, Button } from 'components/form';
import { FadeInUp } from 'components/transitions';
import './login.scss';

export default class RecoverPage extends React.PureComponent {
  render() {
    const { params, onSubmitRecover, defaultValues } = this.props;

    if (params.resetKey) {
      return (
        <div className="login-form" autoFocus={true}>
          <FadeInUp>
            <div>
              <div className="login-heading">
                <h2>Reset password</h2>
                <p>Enter a new password to access your account</p>
              </div>
              <Form onSubmit={onSubmitRecover}>
                <fieldset>
                  <Field
                    type="password"
                    name="password"
                    label="New password"
                    placeholder="********"
                    rules="required, password_admin"
                    large={true}
                    validateIcon={true}
                  />
                </fieldset>
                <div className="login-actions">
                  <Button type="submit" styleType="primary" size="large">
                    Save password
                  </Button>
                  <div className="login-note">
                    Already have it? <Link to="/login">Log in</Link>
                  </div>
                </div>
              </Form>
            </div>
          </FadeInUp>
        </div>
      );
    }

    return (
      <div className="login-form" autoFocus={true}>
        <FadeInUp>
          <div>
            <div className="login-heading">
              <h2>Recover password</h2>
              <p>
                Enter your email address and we'll send <br />
                instructions to reset your password
              </p>
            </div>
            <Form onSubmit={onSubmitRecover}>
              <fieldset>
                <Field
                  type="text"
                  name="email"
                  label="Email"
                  rules="required, email"
                  placeholder="Email address"
                  defaultValue={defaultValues.username}
                  large={true}
                  validateIcon={true}
                />
              </fieldset>
              <div className="login-actions">
                <Button type="submit" styleType="primary" size="large">
                  Recover password
                </Button>
                <div className="login-note">
                  Already have it? <Link to="/login">Log in</Link>
                </div>
              </div>
            </Form>
          </div>
        </FadeInUp>
      </div>
    );
  }
}
