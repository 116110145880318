// @flow
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import AdjustInventoryModalPage from '../../components/pages/modals/adjust-inventory';
import actions from 'actions';

const mapStateToProps = (state) => ({
  loading: state.data.loading,
});

const mapDispatchToProps = (dispatch) => ({
  postInventory: async (data: Object) => {
    return dispatch(actions.data.createRecord('products:stock', data));
  },
});

export class AdjustInventoryModal extends React.PureComponent {
  static contextTypes = {
    notifyError: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  static propTypes = {
    params: PropTypes.object.isRequired,
  };

  state = {};

  constructor(props: Object) {
    super(props);
    this.state = {
      onSubmitForm: this.onSubmitForm.bind(this),
    };
  }

  async onSubmitForm(values: Object) {
    const { params, postInventory } = this.props;

    const { product, variant, onAdjust } = params;

    const result = await postInventory({
      parent_id: product.id,
      variant_id: variant && variant.id ? variant.id : null,
      quantity: values.adjust === 'add' ? ~~values.quantity : -values.quantity,
      reason: values.reason,
      reason_message: values.reason_message,
    });

    if (result && result.errors) {
      this.context.notifyError(result.errors);
    } else if (onAdjust) {
      onAdjust(result);
    }

    this.context.closeModal();
  }

  render() {
    return <AdjustInventoryModalPage {...this.props} {...this.state} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdjustInventoryModal);
