import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import pt from 'prop-types';

import { LOG_COLUMNS } from 'constants/logs';

export function renderLineColumn(line, col, id, parentLine, location) {
  const val = get(line, id);

  const value =
    typeof col.render === 'function'
      ? col.render(val, line, location, parentLine)
      : val;

  return col.renderEmpty === false ? value ?? null : value;
}

function LogsLine({ line, parentLine, columns, location, selected, onClick }) {
  return (
    <tr
      data-id={line.id}
      data-parent={parentLine?.id}
      className={classNames('console-logs-line', {
        'console-logs-selected': selected,
        'console-logs-childline': Boolean(parentLine),
      })}
      onClick={onClick}
    >
      {columns.reduce((acc, id) => {
        const col = LOG_COLUMNS[id];

        if (col) {
          acc.push(
            <td
              key={id}
              className={`console-logs-col console-col-${id.replace('.', '-')}`}
            >
              {renderLineColumn(line, col, id, parentLine, location)}
            </td>,
          );
        }

        return acc;
      }, [])}

      <td key="last"></td>
    </tr>
  );
}

LogsLine.propTypes = {
  line: pt.object.isRequired,
  parentLine: pt.object,
  location: pt.object,
  selected: pt.bool,
  columns: pt.array,

  onClick: pt.func,
};

export default React.memo(LogsLine);
