import segment from 'services/segment';

import App from 'containers/App';
import Apps from 'containers/Apps';
import ViewApp from 'containers/ViewApp';
import Login from 'containers/Login';
import Signup from 'containers/Signup';
import SignupVerify from 'containers/SignupVerify';
import Main from 'containers/Main';
import Dashboard from 'containers/Dashboard';
import Profile from 'containers/Profile';
import Charts from 'containers/Charts';
import Orders from 'containers/Orders';
import React from 'react';
import DraftOrders from 'containers/DraftOrders';
import DraftOrder from 'containers/DraftOrder';
import ViewOrder from 'containers/ViewOrder';
import Carts from 'containers/Carts';
import ViewCart from 'containers/ViewCart';
import Customers from 'containers/Customers';
import NewCustomer from 'containers/NewCustomer';
import ViewCustomer from 'containers/ViewCustomer';
import Products from 'containers/Products';
import NewProduct from 'containers/NewProduct';
import EditProduct from 'containers/EditProduct';
import Categories from 'containers/Categories';
import NewCategory from 'containers/NewCategory';
import EditCategory from 'containers/EditCategory';
import Attributes from 'containers/Attributes';
import NewAttribute from 'containers/NewAttribute';
import EditAttribute from 'containers/EditAttribute';
import Giftcards from 'containers/Giftcards';
import GiftcardProducts from 'containers/GiftcardProducts';
import GiftcardProductCodes from 'containers/GiftcardProductCodes';
import NewGiftcard from 'containers/NewGiftcard';
import ViewGiftcard from 'containers/ViewGiftcard';
import PurchaseLinks from 'containers/PurchaseLinks';
import NewPurchaseLink from 'containers/NewPurchaseLink';
import ViewPurchaseLink from 'containers/ViewPurchaseLink';
import Coupons from 'containers/Coupons';
import CouponCodes from 'containers/CouponCodes';
import NewCoupon from 'containers/NewCoupon';
import EditCoupon from 'containers/EditCoupon';
import Promotions from 'containers/Promotions';
import NewPromotion from 'containers/NewPromotion';
import EditPromotion from 'containers/EditPromotion';
import CollectionList from 'containers/CollectionList';
import CollectionRecord from 'containers/CollectionRecord';
import Reports from 'containers/Reports';
import ViewReport from 'containers/ViewReport';
import Storefronts from 'containers/Storefronts';
import StorefrontEditor from 'containers/StorefrontEditor';
import StorefrontPassword from 'containers/StorefrontPassword';
import NewStorefront from 'containers/NewStorefront';
import ViewStorefront from 'containers/ViewStorefront';
import Subscriptions from 'containers/Subscriptions';
import DraftSubscription from 'containers/DraftSubscription';
import ViewSubscription from 'containers/ViewSubscription';
import SubscriptionInvoices from 'containers/SubscriptionInvoices';
import SubscriptionOrders from 'containers/SubscriptionOrders';
import Invoices from 'containers/Invoices';
import ViewInvoice from 'containers/ViewInvoice';
import Settings from 'containers/Settings';
import Models from 'containers/Models';
import NewModel from 'containers/NewModel';
import EditModel from 'containers/EditModel';
import AccountSettings from 'containers/AccountSettings';
import UserSettings from 'containers/UserSettings';
import ModelExplorer from 'containers/ModelExplorer';
import Console from 'containers/Console';
import UnsubscribeFromEmails from 'containers/UnsubscribeFromEmails';
import OAuth from 'containers/OAuth';

import AccessDeniedPage from 'components/pages/error/403';
import NotFoundPage from 'components/pages/error/404';

const { BASE_URI } = process.env;

const PAGE_VIEWS = new Set();

function trackRoutes(routes, params) {
  if (!Array.isArray(routes)) {
    return;
  }

  for (const route of routes) {
    if (!route.track) {
      continue;
    }

    let name;

    if (typeof route.track === 'function') {
      name = route.track(params);
    } else if (typeof route.track === 'string') {
      name = route.track;
    } else {
      console.warn(`Route tracking requires a page name: ${route.path}`);
      return;
    }

    if (PAGE_VIEWS.has(name)) {
      return;
    }

    PAGE_VIEWS.add(name);

    segment.trackPage(name, params);
  }
}

function path(uri) {
  return `${BASE_URI}${uri}`;
}

export function configureRoutes(store) {
  // build specific routes based on partners subdomain, ie partners.swell.test
  const isPartnerPortal = window.location.host.split('.')[0] === 'partners';

  const routeHook = (handler, params) => {
    if (typeof handler !== 'function') {
      return undefined;
    }

    return async function (...args) {
      // router hook can have 3 or 4 args:
      // also, args need to be defined for callback to have an effect on order
      // router.onChange(prevState, nextState, replace, callback?)
      // router.onEnter(nextState, replace, callback?)
      // router.onLeave(nextState, replace, callback?)
      const nextState = args[args.length - 3];
      const replace = args[args.length - 2];
      const callback = args[args.length - 1];

      const replaceWithBase = function (uri, ...args) {
        return replace(
          BASE_URI ? `${BASE_URI}${uri}`.replace(/\/$/, '') : uri,
          ...args,
        );
      };

      if (params) {
        await handler(store, params, nextState, replaceWithBase);
      } else {
        await handler(store, nextState, replaceWithBase);
      }

      callback();

      trackRoutes(nextState.routes, nextState.params);
    };
  };

  const BASE_URI_REGEXP = new RegExp(`^${BASE_URI}`);

  function appendTestEnvRoutes(routes) {
    const testRoutes = [];

    for (const route of routes.childRoutes) {
      if (route?.testEnv === false) {
        continue;
      }

      if (BASE_URI_REGEXP.test(route?.path)) {
        testRoutes.push({
          ...route,
          path: path(`${route.path.replace(BASE_URI, '/test')}`),
        });

        testRoutes.push({
          ...route,
          path: path(`${route.path.replace(BASE_URI, '/test-:env')}`),
        });

        if (route.childRoutes) {
          appendTestEnvRoutes(route);
        }
      }
    }

    routes.childRoutes.push(...testRoutes);
  }

  const container = (ComponentKlass, params) => ({
    component: (props) => (
      <ComponentKlass {...props} isPartnerPortal={isPartnerPortal} />
    ),
    onEnter: routeHook(ComponentKlass.onEnter, params),
    onChange: routeHook(ComponentKlass.onChange, params),
    onLeave: routeHook(ComponentKlass.onLeave, params),
  });

  const buildClientRoutes = () => ({
    ...container(App),
    testEnv: false,
    childRoutes: [
      {
        path: '/password',
        ...container(StorefrontPassword),
      },
      {
        path: '/signup',
        ...container(Signup),
      },
      {
        path: '/signup/simplified',
        onEnter: () => {
          window.location.replace('/signup');
        },
      },
      {
        path: '/signup/verify-email/:id',
        ...container(SignupVerify),
      },
      {
        path: '/signup/verify-email/:id/:key',
        ...container(SignupVerify),
      },
      {
        path: '/login',
        ...container(Login),
        page: 'login',
      },
      {
        path: '/recover',
        ...container(Login),
        page: 'recover',
      },
      {
        path: '/switch',
        ...container(Login),
        page: 'switch',
      },
      {
        path: '/cli-switch',
        ...container(Login),
        page: 'cli-switch',
      },
      {
        path: '/cli-success',
        ...container(Login),
        page: 'cli-success',
      },
      {
        path: path('/login'),
        ...container(Login),
        page: 'login',
        testEnv: false,
      },
      {
        path: path('/cli-switch'),
        ...container(Login),
        page: 'cli-switch',
      },
      {
        path: path('/cli-success'),
        ...container(Login),
        page: 'cli-success',
      },
      {
        path: path('/docs-login'),
        ...container(Login),
        page: 'docs-login',
      },
      {
        path: path('/recover'),
        ...container(Login),
        page: 'recover',
        testEnv: false,
      },
      {
        path: path('/recover/:resetKey'),
        ...container(Login),
        page: 'recover',
        testEnv: false,
      },
      {
        path: path('/join/:inviteKey'),
        ...container(Login),
        page: 'join',
        testEnv: false,
      },
      {
        path: path('/unsubscribe'),
        ...container(UnsubscribeFromEmails),
        page: 'unsubscribe',
        testEnv: false,
      },
      {
        path: path('/oauth/authorize'),
        ...container(OAuth),
        page: 'oauth',
      },
      {
        path: '/checkout*',
        onEnter: () => {
          // For dev/local only
          const { CHECKOUT_URL } = process.env;
          if (CHECKOUT_URL) {
            const clientDomain = window.location.host.replace(/:.+/, '');
            const clientCheckoutUrl = CHECKOUT_URL.replace(
              'localhost',
              clientDomain,
            );
            window.location.replace(
              `${clientCheckoutUrl}${window.location.pathname}`,
            );
          }
        },
      },
      {
        path: path('/model-explorer/client/'),
        onEnter: () => {
          // For dev/local only
          const { MODEL_EXPLORER_URL } = process.env;
          if (MODEL_EXPLORER_URL) {
            const clientDomain = window.location.host.replace(/:.+/, '');
            const clientModelExplorerUrl = MODEL_EXPLORER_URL.replace(
              'localhost',
              clientDomain,
            );
            window.location.replace(
              `${clientModelExplorerUrl}${window.location.pathname}`,
            );
          }
        },
      },
      {
        path: path(''),
        ...container(Main),
        indexRoute: { ...container(Dashboard) },
        childRoutes: [
          {
            path: path('/plans'),
            section: 'account-plans',
            ...container(AccountSettings),
            layout: false,
            track: 'plans',
          },
          {
            path: path('/account'),
            ...container(AccountSettings),
            layout: 'minimal',
            track: 'account details',
          },
          {
            path: path('/profile'),
            ...container(Profile),
            track: 'profile',
          },
          {
            path: path('/reports'),
            ...container(Reports),
            track: 'reports',
            permission: 'reports',
          },
          {
            path: path('/reports/charts'),
            ...container(Charts),
            permission: 'reports',
          },
          {
            path: path('/reports/:type'),
            ...container(ViewReport),
            track: (params) => `reports/${params.type}`,
            permission: 'reports',
          },
          {
            path: path('/orders'),
            ...container(Orders),
            permission: 'orders',
            track: 'orders',
          },
          {
            path: path('/orders/drafts'),
            ...container(DraftOrders),
            permission: 'orders',
          },
          {
            path: path('/orders/drafts/new'),
            ...container(DraftOrder),
            permission: 'orders',
          },
          {
            path: path('/orders/drafts/:id'),
            ...container(DraftOrder),
            permission: 'orders',
          },
          {
            path: path('/orders/:id'),
            ...container(ViewOrder),
            permission: 'orders',
          },
          {
            path: path('/carts'),
            ...container(Carts),
            permission: 'carts',
            track: 'carts',
          },
          {
            path: path('/carts/:id'),
            ...container(ViewCart),
            permission: 'carts',
          },
          {
            path: path('/customers'),
            ...container(Customers),
            permission: 'customers',
            track: 'customers',
          },
          {
            path: path('/customers/new'),
            ...container(NewCustomer),
            permission: 'customers',
          },
          {
            path: path('/customers/:id'),
            ...container(ViewCustomer),
            permission: 'customers',
          },
          {
            path: path('/products'),
            ...container(Products),
            permission: 'products',
            track: 'products',
          },
          {
            path: path('/products/new'),
            ...container(NewProduct),
            permission: 'products',
          },
          {
            path: path('/products/:id'),
            ...container(EditProduct),
            permission: 'products',
          },
          {
            path: path('/categories'),
            ...container(Categories),
            permission: 'categories',
            track: 'categories',
          },
          {
            path: path('/categories/new'),
            ...container(NewCategory),
            permission: 'categories',
          },
          {
            path: path('/categories/:id'),
            ...container(EditCategory),
            permission: 'categories',
          },
          {
            path: path('/attributes'),
            ...container(Attributes),
            permission: 'attributes',
            track: 'attributes',
          },
          {
            path: path('/attributes/new'),
            ...container(NewAttribute),
            permission: 'attributes',
          },
          {
            path: path('/attributes/edit/:id'),
            ...container(EditAttribute),
            permission: 'attributes',
          },
          {
            path: path('/attributes/:id'),
            ...container(EditAttribute),
            permission: 'attributes',
          },
          {
            path: path('/giftcards'),
            ...container(Giftcards),
            permission: 'giftcards',
            track: 'gift cards',
          },
          {
            path: path('/giftcards/products'),
            ...container(GiftcardProducts),
            permission: 'giftcards',
            track: 'gift card products',
          },
          {
            path: path('/giftcards/products/:id/giftcards'),
            ...container(GiftcardProductCodes),
            permission: 'giftcards',
          },
          {
            path: path('/giftcards/new'),
            ...container(NewGiftcard),
            permission: 'giftcards',
          },
          {
            path: path('/giftcards/:id'),
            ...container(ViewGiftcard),
            permission: 'giftcards',
          },
          {
            path: path('/purchase-links'),
            ...container(PurchaseLinks),
            permission: 'products',
          },
          {
            path: path('/purchase-links/new'),
            ...container(NewPurchaseLink),
            permission: 'products',
          },
          {
            path: path('/purchase-links/:id'),
            ...container(ViewPurchaseLink),
            permission: 'products',
          },
          {
            path: path('/subscriptions'),
            ...container(Subscriptions),
            permission: 'subscriptions',
            track: 'subscriptions',
          },
          {
            path: path('/subscriptions/drafts/new'),
            ...container(DraftSubscription),
            permission: 'subscriptions',
          },
          {
            path: path('/subscriptions/drafts/:id'),
            ...container(DraftSubscription),
            permission: 'subscriptions',
          },
          {
            path: path('/subscriptions/:id'),
            ...container(ViewSubscription),
            permission: 'subscriptions',
          },
          {
            path: path('/subscriptions/:id/invoices'),
            ...container(SubscriptionInvoices),
            permission: 'subscriptions',
          },
          {
            path: path('/subscriptions/:id/orders'),
            ...container(SubscriptionOrders),
            permission: 'subscriptions',
          },
          {
            path: path('/invoices'),
            ...container(Invoices),
            permission: 'orders',
            track: 'invoices',
          },
          {
            path: path('/invoices/:id'),
            ...container(ViewInvoice),
            permission: 'orders',
          },
          {
            path: path('/coupons'),
            ...container(Coupons),
            permission: 'coupons',
            track: 'coupons',
          },
          {
            path: path('/coupons/new'),
            ...container(NewCoupon),
            permission: 'coupons',
          },
          {
            path: path('/coupons/:id'),
            ...container(EditCoupon),
            permission: 'coupons',
          },
          {
            path: path('/coupons/:id/codes'),
            ...container(CouponCodes),
            permission: 'coupons',
          },
          {
            path: path('/promotions'),
            ...container(Promotions),
            permission: 'promotions',
            track: 'promotions',
          },
          {
            path: path('/promotions/new'),
            ...container(NewPromotion),
            permission: 'promotions',
          },
          {
            path: path('/promotions/:id'),
            ...container(EditPromotion),
            permission: 'promotions',
          },
          {
            path: path('/collections/:collection'),
            ...container(CollectionList),
            permission: 'content',
          },
          {
            path: path('/collections/:collection/:id'),
            ...container(CollectionRecord),
            permission: 'content',
          },
          {
            path: path('/storefronts'),
            ...container(Storefronts),
            permission: 'storefront',
            track: 'storefront',
          },
          {
            path: path('/storefronts/new'),
            ...container(NewStorefront),
            permission: 'storefront',
            track: 'storefront new',
          },
          {
            path: path('/storefronts/:id/editor'),
            ...container(StorefrontEditor),
            layout: false,
            loader: false,
            intercom: false,
            trial: false,
            permission: 'storefront',
            track: 'storefront editor',
          },
          {
            path: path('/storefronts/:id'),
            ...container(ViewStorefront),
            permission: 'storefront',
            track: 'storefront view',
          },
          {
            path: path('/apps'),
            ...container(Apps),
            track: 'apps',
          },
          {
            path: path('/apps/:id'),
            ...container(ViewApp),
          },
          {
            path: path('/settings'),
            ...container(Settings),
            permission: 'settings',
          },
          {
            path: path('/settings/models'),
            ...container(Models),
            permission: 'settings',
            track: 'models',
          },
          {
            path: path('/settings/models/new'),
            ...container(NewModel),
            permission: 'settings',
          },
          {
            path: path('/settings/models/:id'),
            ...container(EditModel),
            permission: 'settings',
          },
          {
            path: path('/settings/account'),
            ...container(AccountSettings),
            permission: 'settings',
            track: 'account details',
          },
          {
            path: path('/settings/account/plans'),
            section: 'account-plans',
            ...container(AccountSettings),
            permission: 'settings',
            track: 'account plans',
          },
          {
            path: path('/settings/account/invoices/:id'),
            section: 'account-invoices',
            ...container(AccountSettings),
            layout: false,
            intercom: true,
            permission: 'settings',
            track: 'account invoices',
          },
          {
            path: path('/settings/users'),
            section: 'users',
            ...container(UserSettings),
            permission: 'settings',
          },
          {
            path: path('/settings/users/roles'),
            section: 'user-roles',
            ...container(UserSettings),
            permission: 'settings',
          },
          {
            path: path('/settings/users/roles/:id'),
            section: 'user-role',
            ...container(UserSettings),
            permission: 'settings',
          },
          {
            path: path('/settings/users/:id'),
            section: 'user',
            ...container(UserSettings),
            permission: 'settings',
          },
          {
            path: path('/settings/:section'),
            ...container(Settings),
            permission: 'settings',
            track: (params) => `settings/${params.section}`,
          },
          {
            path: path('/settings/:section/:command'),
            ...container(Settings),
            permission: 'settings',
          },
          {
            path: path('/model-explorer'),
            ...container(ModelExplorer),
            permission: 'settings',
          },
          {
            path: path('/console'),
            ...container(Console),
            permission: 'settings',
          },
          {
            path: path('/console/logs'),
            section: 'logs',
            ...container(Console),
            permission: 'settings',
          },
          {
            path: path('/console/:request_id'),
            ...container(Console),
            permission: 'settings',
          },
        ],
      },
      {
        path: path('/access-denied'),
        component: AccessDeniedPage,
      },
    ],
  });

  const allRoutes = buildClientRoutes();
  appendTestEnvRoutes(allRoutes);

  // Append catch-all route after test routes
  allRoutes.childRoutes.push({
    path: '*',
    component: NotFoundPage,
  });

  return allRoutes;
}
