import React from 'react';
import { Form, Field } from 'components/form';
import Modal from 'components/modal';
import './product.scss';

export default class ProductVariantImagesModal extends React.PureComponent {
  render() {
    const { variantRecord, onClickVariantImages, onSubmitVariantImages } =
      this.props;

    return (
      <Form onSubmit={onSubmitVariantImages}>
        <Modal
          title={variantRecord.name}
          className="product-modal-variant-images"
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'secondary',
              className: 'button-cancel',
              onClick: onClickVariantImages,
            },
          ]}
          cancel={false}
          onClose={onClickVariantImages}
        >
          <Field
            type="images"
            label="Variant images"
            name="images"
            fullSize={false}
            defaultValue={variantRecord.images}
          />
        </Modal>
      </Form>
    );
  }
}
