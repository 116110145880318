import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, map, reduce } from 'lodash';

import {
  shippingService,
  getItemsPriceTotal,
  getItemGroups,
} from 'utils/order';

import {
  formatCurrencyRounded,
  formatCurrency,
  parseCurrency,
  currencyValue,
  isPrice,
  inflect,
} from 'utils';

import {
  productThumbUrl,
  productName,
  productPrice,
  isSubscriptionItem,
  hasDeprecatedSubscriptionPlan,
  isTrialItem,
} from 'utils/product';

import Link from 'components/link';
import { Field, Fieldgroup } from 'components/form';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import Help from 'components/tooltip/help';
import ButtonLink from 'components/button/link';
import { FadeIn } from 'components/transitions';
import SubscriptionPrice from 'components/pages/subscription/price';
import {
  getNotIncludedTaxesTooltip,
  getIncludedTaxesFootnote,
} from './taxes-tooltip';

import { PriceDisplay } from './price-display';
import OrderItemDetail from './item-detail';

export default class OrderItems extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
  };

  isOrderAndItemFullyCanceled(item) {
    const { record } = this.props;
    return record.canceled && item.quantity === item.quantity_canceled;
  }

  renderItemPrice(item, showTotal = false) {
    const { record } = this.props;
    const { client } = this.context;
    const currency = record.currency || client.currency;
    const { product, variant, quantity = 1 } = item;
    let { price, price_total } = item;

    if (isTrialItem(item)) {
      if (showTotal) {
        const trialDays = get(item, 'purchase_option.trial_days');
        return (
          <Fragment>
            {formatCurrency(price_total, currency)}
            <div className="positive">{trialDays}-day trial period</div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            {formatCurrency(item.price, currency)}
            <Help
              message={`Auth amount ${formatCurrency(
                item.purchase_option.auth_amount,
                currency,
              )}`}
            />
          </Fragment>
        );
      }
    }

    if (typeof price !== 'number' && product) {
      price = product.sale ? product.sale_price : product.price;

      price_total = price * quantity;
    }

    if (record.draft && item.price === item.orig_price) {
      const isSale =
        Boolean(product && product.sale) || Boolean(variant && variant.sale);

      const isSubscription =
        get(item, 'purchase_option.type') === 'subscription';

      const regularPrice = productPrice(product, variant);
      const currentPrice = item.price;

      // Purchase option or deprecated subscription option
      if (
        showTotal &&
        (isSubscription ||
          (product && hasDeprecatedSubscriptionPlan(product) && variant))
      ) {
        return (
          <SubscriptionPrice
            product={product}
            variant={variant}
            currency={currency}
            quantity={quantity}
            price={item.price}
            purchaseOption={item.purchase_option}
          />
        );
      }

      if (isSale && regularPrice > currentPrice && !isSubscription) {
        return (
          <div className="order-view-item-sale">
            <strike>
              {formatCurrency(
                showTotal ? regularPrice * quantity : regularPrice,
                currency,
              )}
            </strike>{' '}
            <span className="positive">
              {formatCurrency(showTotal ? price_total : price, currency)}
            </span>
          </div>
        );
      }
    }

    return formatCurrency(showTotal ? price_total : price, currency);
  }

  renderItemImage = (item) => {
    return (
      <td className="image top">
        <FadeIn active={true} transitionAppear={!item.id}>
          <Field
            type="hidden"
            name={`items[${item.index}].id`}
            value={item.id}
          />
          <span className="collection-table-images">
            <span className="image">
              {item.product_id ? (
                <Link to={`/products/${item.product_id}`}>
                  <img
                    src={productThumbUrl(item.product, item.variant)}
                    alt={productName(item.product, item.variant)}
                  />
                </Link>
              ) : (
                <img
                  src={productThumbUrl(item.product, item.variant)}
                  alt={productName(item.product, item.variant)}
                />
              )}
            </span>
          </span>
        </FadeIn>
      </td>
    );
  };

  renderItem = (item) => {
    const {
      record,
      editing,
      onClickEditSingleItem,
      onClickRemoveEditItem,
      onChangeEditItemInput,
    } = this.props;
    const { client } = this.context;
    const currency = record.currency || client.currency;
    const { index } = item;

    const hasPrice =
      (isPrice(item.price) && item.price !== item.orig_price) ||
      isPrice(item.set_price);

    const actualPrice = isPrice(item.set_price)
      ? item.set_price
      : isPrice(item.calc_price)
      ? item.calc_price
      : item.price;

    const formattedItemPrice = currencyValue(actualPrice, currency);
    const floatItemPrice = parseCurrency(formattedItemPrice, currency);
    const formattedTotalValue = currencyValue(
      floatItemPrice * (item.quantity || 1),
      currency,
      { isSymbol: true },
    );

    const isSubscription = isSubscriptionItem(item);
    const subscriptionTrialDays =
      isSubscription &&
      get(
        item,
        'purchase_option.billing_schedule.trial_days',
        item.subscription_trial_days,
      );

    return (
      <tr
        key={index}
        className={
          (item.quantity_canceled >= item.quantity &&
            !this.isOrderAndItemFullyCanceled(item)) ||
          item.removed
            ? `disabled`
            : undefined
        }
      >
        {this.renderItemImage(item)}
        <td className="pad-right">
          <FadeIn active={true} transitionAppear={!item.id}>
            <OrderItemDetail
              item={item}
              index={index}
              style={
                item.removed ? { textDecoration: 'line-through' } : undefined
              }
              onClickEditSingleItem={onClickEditSingleItem}
            />
          </FadeIn>
        </td>
        <td
          className={`nowrap ${editing ? 'tight-right' : ''}`}
          style={editing ? { width: 100 } : undefined}
        >
          {editing ? (
            <FadeIn active={true} transitionAppear={!item.id}>
              <Field
                type="number"
                placeholder="1"
                name={`${index}.quantity`}
                defaultValue={item.quantity}
                scale={0}
                minValue={1}
                maxValue={9999999}
                onChange={onChangeEditItemInput}
                disabled={item.removed}
                selectFocus={true}
                tabIndex={index}
              />
            </FadeIn>
          ) : (
            <span className="collection-field-padded item-quantity-cell">
              {((item.quantity_canceled > 0 &&
                !this.isOrderAndItemFullyCanceled(item)) ||
                item.quantity_delivered > 0 ||
                item.quantity_returned > 0) &&
              (item.quantity_delivered !== item.quantity ||
                (item.quantity_canceled && !record.canceled) ||
                item.quantity_returned) ? (
                <Fragment>
                  {item.quantity_canceled >= item.quantity ? (
                    <div>{item.quantity} canceled</div>
                  ) : item.quantity_returned === item.quantity_delivered ? (
                    <div>{item.quantity_returned} returned</div>
                  ) : (
                    <Fragment>
                      <div>{item.quantity} ordered</div>
                      {item.quantity_canceled > 0 && (
                        <div> {item.quantity_canceled} canceled</div>
                      )}
                      {item.quantity_delivered > 0 && (
                        <div>{item.quantity_delivered} fulfilled</div>
                      )}
                      {item.quantity_returned > 0 && (
                        <div>{item.quantity_returned} returned</div>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                item.quantity
              )}
            </span>
          )}
        </td>
        <td
          className={editing ? undefined : 'currency'}
          style={editing ? { width: 150 } : undefined}
        >
          {editing ? (
            <FadeIn active={true} transitionAppear={!item.id}>
              <Field
                type="currency"
                name={`${index}.set_price`}
                placeholder={
                  isPrice(item.calc_price)
                    ? currencyValue(item.calc_price)
                    : currencyValue(item.price)
                }
                defaultValue={hasPrice ? actualPrice : undefined}
                maxValue={9999999}
                currency={currency}
                onChange={onChangeEditItemInput}
                disabled={item.removed}
                selectFocus={true}
                tabIndex={index}
              />
            </FadeIn>
          ) : (
            <span className="collection-field-padded">
              <PriceDisplay order={record}>
                {this.renderItemPrice(item)}
              </PriceDisplay>
            </span>
          )}
        </td>
        <td className="currency" style={editing ? { width: 75 } : undefined}>
          {subscriptionTrialDays > 0 ? (
            editing ? (
              <FadeIn active={true} transitionAppear={!item.id}>
                <strike>{formattedTotalValue}</strike>
                <div className="positive">
                  {subscriptionTrialDays}-day trial
                </div>
              </FadeIn>
            ) : (
              <span>
                <strike>{formattedTotalValue}</strike>
                <div className="positive">
                  {subscriptionTrialDays}-day trial
                </div>
              </span>
            )
          ) : (
            <span
              className="collection-field-padded"
              style={
                item.removed ? { textDecoration: 'line-through' } : undefined
              }
            >
              {editing ? (
                <FadeIn active={true} transitionAppear={!item.id}>
                  {formattedTotalValue}
                </FadeIn>
              ) : (
                <PriceDisplay order={record}>
                  {this.renderItemPrice(item, true)}
                </PriceDisplay>
              )}
            </span>
          )}
        </td>
        {editing && (
          <td className="action">
            <FadeIn active={true} transitionAppear={!item.id}>
              <span className="collection-field-padded">
                <button
                  data-index={index}
                  className="as-link"
                  onClick={onClickRemoveEditItem}
                  type="button"
                >
                  {item.removed ? 'Undo' : 'Remove'}
                </button>
              </span>
            </FadeIn>
          </td>
        )}
      </tr>
    );
  };

  renderGroupTotalsItemPrice(
    item,
    currency,
    fields = { price: 'price', tax: 'tax_each', discount: 'discount_each' },
  ) {
    const price = item[fields.price];
    const tax = item[fields.tax];
    const discount = item[fields.discount];

    return (
      <Fragment>
        <div className="order-trial-totals-group-item-price">
          <span className="muted">Price</span>
          <span>{formatCurrency(price, currency)}</span>
        </div>
        {Boolean(tax) && (
          <div className="order-trial-totals-group-item-price">
            <span className="muted">Taxes</span>
            <span>{formatCurrency(tax, currency)}</span>
          </div>
        )}
        {Boolean(discount) && (
          <div className="order-trial-totals-group-item-price">
            <span className="muted">Discount</span>
            <span>({formatCurrency(discount, currency)})</span>
          </div>
        )}
      </Fragment>
    );
  }

  renderGroupTotals = (label, items) => {
    const { record } = this.props;
    const { client } = this.context;
    const { shipping = {}, shipment_total } = record;
    const currency = record.currency || client.currency;
    const shouldDisplayShipping =
      label === 'At checkout' && Boolean(shipment_total);

    return (
      <div className="order-trial-totals-group">
        <Fieldgroup label={label} defaultActive>
          <table className="collection-table headless">
            <tbody>
              {map(items, (item) => (
                <tr>
                  {this.renderItemImage(item)}
                  <td className="pad-right">
                    <FadeIn active={true} transitionAppear={!item.id}>
                      <OrderItemDetail
                        item={item}
                        index={item.index}
                        showQuantity={true}
                      />
                    </FadeIn>
                  </td>
                  <td>{this.renderGroupTotalsItemPrice(item, currency)}</td>
                </tr>
              ))}
              {shouldDisplayShipping && (
                <tr>
                  <td colSpan={2}>
                    <span className="bold">Shipping</span>
                    <div className="muted">
                      {shipping.service_name || 'Unknown shipping service'}
                    </div>
                  </td>
                  <td>
                    {this.renderGroupTotalsItemPrice(record, currency, {
                      price: 'shipment_price',
                      tax: 'shipment_tax',
                      discount: 'shipment_discount',
                    })}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Fieldgroup>
        <span className="order-totals-value">
          {formatCurrency(
            getItemsPriceTotal(items) +
              (shouldDisplayShipping ? shipment_total : 0),
            currency,
          )}
        </span>
      </div>
    );
  };

  renderShipmentTotals() {
    const { record, onClickShippingEdit, settings } = this.props;

    if (!record.shipment_delivery && record.shipment_total <= 0) {
      return null;
    }

    const { client } = this.context;
    const currency = record.currency || client.currency;

    const shippingServiceNode = (
      <Fragment>
        Shipping{' '}
        {settings && record.shipping?.service && (
          <Fragment>
            (
            {get(
              shippingService(settings, record.shipping),
              'name',
              record.shipping.service,
            )}
            )
          </Fragment>
        )}
      </Fragment>
    );

    const shipmentTotalNode = (
      <span>
        {record.shipment_discount > 0 && (
          <Tooltip
            message={`Discounted by ${formatCurrency(
              record.shipment_discount,
              currency,
            )} from ${formatCurrency(
              record.shipment_total + record.shipment_discount,
              currency,
            )}`}
          >
            <span className="help icon icon-info icon-info-shipping">&nbsp;</span>
            &nbsp;&nbsp;
          </Tooltip>
        )}

        {formatCurrency(record.shipment_total, currency)}
      </span>
    );

    return (
      <div>
        <span className="order-totals-label">
          {onClickShippingEdit ? (
            <button
              className="as-link sneaky-link"
              onClick={onClickShippingEdit}
              type="button"
            >
              {shippingServiceNode}
            </button>
          ) : (
            shippingServiceNode
          )}
        </span>

        <span className="order-totals-value">
          {onClickShippingEdit ? (
            <button
              className="as-link sneaky-link"
              onClick={onClickShippingEdit}
              type="button"
            >
              {shipmentTotalNode}
            </button>
          ) : (
            shipmentTotalNode
          )}
        </span>
      </div>
    );
  }

  renderOrderTotals(itemGroups) {
    if (this.props.editing) {
      return null;
    }

    const { record, onClickDiscountsEdit } = this.props;
    const { client } = this.context;
    const currency = record.currency || client.currency;
    const hasPromotions = record.promotions;
    const couponCode = record.coupon_code || undefined;
    const promotionIds = record.promotion_ids || [];
    let itemCouponDiscountTotal = 0;
    let itemPromoDiscountTotals = {};
    const orderDiscounts = record.discounts || [];

    orderDiscounts.forEach((discount) => {
      if (discount.id.startsWith('coupon')) {
        itemCouponDiscountTotal += discount.amount;
      } else if (discount.id.startsWith('promo')) {
        const promoId = discount.id.split('-')[1];
        itemPromoDiscountTotals[promoId] =
          itemPromoDiscountTotals[promoId] || 0;
        itemPromoDiscountTotals[promoId] += discount.amount;
      }
    });

    if (record.promotions) {
      record.promotions.results.sort((a, b) => {
        return itemPromoDiscountTotals[b.id] - itemPromoDiscountTotals[a.id];
      });
    }

    const hasTrialItems = !!itemGroups.trial;
    const trialGroups = reduce(
      itemGroups.trial,
      (acc, item) => {
        const trialDays = item.purchase_option.trial_days;
        if (acc[trialDays]) {
          acc[trialDays].push(item);
        } else {
          acc[trialDays] = [item];
        }
        return acc;
      },
      {},
    );

    return (
      <tr>
        <td colSpan="5">
          <div className="order-totals">
            <span className="order-totals-label">Subtotal</span>

            <span className="order-totals-value">
              <PriceDisplay order={record}>
                {formatCurrency(record.sub_total, currency)}
              </PriceDisplay>
            </span>

            {this.renderShipmentTotals()}

            {couponCode || promotionIds.length > 0 ? (
              <Fragment>
                <span className="order-totals-label">
                  <button
                    className="as-link sneaky-link"
                    onClick={onClickDiscountsEdit}
                    type="button"
                  >
                    Discounts
                  </button>

                  <ul>
                    {couponCode && (
                      <li>
                        Coupon:&nbsp;
                        {record.coupon_id ? (
                          <Link to={`/coupons/${record.coupon_id}`}>
                            {couponCode}
                          </Link>
                        ) : (
                          <span>{couponCode}</span>
                        )}
                        {promotionIds.length > 0 && (
                          <span className="muted">
                            &nbsp; (
                            {formatCurrency(itemCouponDiscountTotal, currency)})
                          </span>
                        )}
                      </li>
                    )}
                    {hasPromotions &&
                      promotionIds.length > 0 &&
                      record.promotions.results.map((promo) => (
                        <li key={promo.id}>
                          Promo:&nbsp;
                          <Link to={`/promotions/${promo.id}`}>
                            {promo.name}
                          </Link>
                          {itemPromoDiscountTotals[promo.id] > 0 &&
                            (couponCode || promotionIds.length > 1) && (
                              <span className="muted">
                                &nbsp; (
                                {formatCurrency(
                                  itemPromoDiscountTotals[promo.id],
                                  currency,
                                )}
                                )
                              </span>
                            )}
                        </li>
                      ))}
                  </ul>
                </span>
                <span className="order-totals-value">
                  <button
                    className="as-link sneaky-link"
                    onClick={onClickDiscountsEdit}
                    type="button"
                  >
                    {record.discount_total > 0 ? (
                      <span>
                        ({formatCurrency(record.discount_total, currency)})
                      </span>
                    ) : (
                      <span>&mdash;</span>
                    )}
                  </button>
                </span>
              </Fragment>
            ) : (
              record.discount_total > 0 && (
                <Fragment>
                  <span className="order-totals-label">Discount</span>

                  <span className="order-totals-value">
                    {record.discount_total > 0 ? (
                      <span>
                        ({formatCurrency(record.discount_total, currency)})
                      </span>
                    ) : (
                      <span>&mdash;</span>
                    )}
                  </span>
                </Fragment>
              )
            )}

            <span className="order-totals-label">Taxes</span>
            <span className="order-totals-value">
              {record.tax_included_total > 0 ? (
                <span>
                  {getNotIncludedTaxesTooltip(record, false, false, currency)}
                  {formatCurrency(record.tax_included_total, currency)}
                </span>
              ) : (
                <span>&mdash;</span>
              )}
            </span>
            <span className="order-totals-label grand">Total</span>
            <span className="order-totals-value grand">
              <PriceDisplay order={record}>
                {formatCurrencyRounded(record.grand_total, currency)}
              </PriceDisplay>
            </span>
            {getIncludedTaxesFootnote(record, false, false, currency)}
            {record.return_total > 0 && (
              <Fragment>
                <span className="order-totals-label">Returns</span>
                <span className="order-totals-value">
                  ({formatCurrencyRounded(record.return_total, currency)})
                </span>
                <span className="order-totals-label">
                  <strong>Final total</strong>
                </span>
                <span className="order-totals-value">
                  <strong>
                    {formatCurrencyRounded(
                      record.grand_total - record.return_total,
                      currency,
                    )}
                  </strong>
                </span>
              </Fragment>
            )}
            {hasTrialItems && (
              <div className="order-trial-totals">
                {(Boolean(itemGroups.standard) ||
                  Boolean(record.shipment_total)) &&
                  this.renderGroupTotals('At checkout', itemGroups.standard)}
                {map(trialGroups, (items, trialDays) =>
                  this.renderGroupTotals(
                    `${inflect(trialDays, 'days')} after fulfillment`,
                    items,
                  ),
                )}
              </div>
            )}
          </div>
        </td>
      </tr>
    );
  }

  render() {
    const {
      record,
      editing,
      editable,
      hasEditableItems,
      hasCancelableItems,
      editItems,
      onClickAddItem,
      onClickEditItems,
      onClickCancelItems,
      onClickShippingEdit,
      onClickDiscountsEdit,
    } = this.props;

    const orderItems = editing ? editItems : record.items || [];
    const itemGroups = getItemGroups(orderItems);
    const hasTrialItems = !!itemGroups.trial;

    return (
      <div>
        {orderItems.length > 0 ? (
          <div className="collection-table-container">
            <table className="collection-table white-space-wrap outer">
              <thead>
                <tr>
                  <th colSpan="2">{hasTrialItems ? 'Try period' : 'Items'}</th>
                  <th className="tight-right">Qty</th>
                  <th className={editing ? undefined : 'currency'}>Price</th>
                  <th className="currency">Total</th>
                  {editing && <th>&nbsp;</th>}
                </tr>
              </thead>

              <tbody>
                {itemGroups.trial && map(itemGroups.trial, this.renderItem)}

                {itemGroups.standard && (
                  <Fragment>
                    {hasTrialItems && (
                      <tr className="order-row-item-group">
                        <td colSpan="100">Pay up front</td>
                      </tr>
                    )}
                    {map(itemGroups.standard, this.renderItem)}
                  </Fragment>
                )}

                {(editing || editable) && (
                  <tr className="order-row-add empty">
                    <td>
                      <button
                        className="collection-table-images"
                        onClick={onClickAddItem}
                        type="button"
                      >
                        <div className="image">&nbsp;</div>
                      </button>
                    </td>

                    <td colSpan="4">
                      <button
                        className="as-link"
                        onClick={onClickAddItem}
                        type="button"
                      >
                        <span className="collection-field-padded">
                          <Icon fa="plus" />
                          &nbsp; Add item
                        </span>
                      </button>
                    </td>

                    {editing && <td />}
                  </tr>
                )}
                {this.renderOrderTotals(itemGroups)}
                {!editing && editable && (
                  <tr className="tfoot">
                    <td colSpan="100" className="action">
                      <ButtonLink
                        type="secondary"
                        size="sm"
                        onClick={onClickDiscountsEdit}
                      >
                        {record.discounts
                          ? 'Edit discounts'
                          : 'Apply discounts'}
                      </ButtonLink>

                      {record.shipment_delivery && onClickShippingEdit && (
                        <ButtonLink
                          type="secondary"
                          size="sm"
                          onClick={onClickShippingEdit}
                        >
                          Edit shipping
                        </ButtonLink>
                      )}

                      {hasEditableItems !== false ? (
                        <ButtonLink
                          type="secondary"
                          size="sm"
                          onClick={onClickEditItems}
                        >
                          Edit items
                        </ButtonLink>
                      ) : (
                        hasCancelableItems && (
                          <ButtonLink
                            type="secondary"
                            size="sm"
                            onClick={onClickCancelItems}
                          >
                            Cancel items
                          </ButtonLink>
                        )
                      )}
                    </td>
                  </tr>
                )}

                {record.comments && (
                  <tr>
                    <td colSpan="100">
                      <table className="collection-table inner">
                        <thead>
                          <tr>
                            <th>Comments</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td className="order-comments muted">
                              {record.comments}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="collection-table-container">
            <table className="collection-table empty outer">
              <thead>
                <tr>
                  <th>Items</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    {editable ? (
                      <button
                        className="as-link order-empty-items"
                        onClick={onClickAddItem}
                        type="button"
                      >
                        Add items
                      </button>
                    ) : (
                      <span className="order-empty-items" />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}
