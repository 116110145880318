import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'components/form';
import View from 'components/view';
import { FadeIn } from 'components/transitions';
import './giftcard.scss';

export default class New extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  render() {
    const { errors, edited, values, onSubmitForm, onChangeForm } = this.props;

    const { currency, pricedCurrencies } = this.context.client;
    const currencyCode = values.currency || currency;

    return (
      <Form onSubmit={onSubmitForm} onChange={onChangeForm} autoFocus={true}>
        <View
          detail={true}
          uri={`/giftcards`}
          sectionTitle="Gift cards"
          headerTitle="New gift card"
          headerActions={[
            {
              label: 'Save gift card',
              type: edited ? 'default' : 'secondary',
              submit: true,
            },
          ]}
        >
          <div className="giftcard-new">
            <fieldset>
              <div className="row">
                <Field
                  type="text"
                  label="Gift card code"
                  name="code"
                  error={errors.code}
                  placeholder="Optional"
                  hint="Leave blank to generate a unique gift card code"
                  className="span3"
                />
                <Field
                  type="currency"
                  currency={currencyCode}
                  label="Value"
                  name="amount"
                  error={errors.amount}
                  required={true}
                  className="span1"
                />
                {pricedCurrencies.length > 0 && (
                  <Field
                    type="select"
                    name="currency"
                    label="Currency"
                    options={pricedCurrencies.map((curr) => curr.code)}
                    defaultValue={currency}
                    required={true}
                    className="span1"
                  />
                )}
              </div>
              <div className="row">
                <Field
                  type="toggle"
                  name="send"
                  label="Send via email"
                  readonly={true}
                />
              </div>
              <FadeIn active={!!values.send}>
                <div className="row">
                  <Field
                    type="text"
                    name="send_email"
                    label="Send to email address"
                    rules="email"
                    required={!!values.send}
                    className="span3"
                  />
                </div>
                <div className="row">
                  <Field
                    type="textarea"
                    name="send_note"
                    label="Note to recipient"
                    placeholder="Optional"
                    className="span3"
                    autoSize={true}
                    rows={this.props.rows || 3}
                  />
                </div>
              </FadeIn>
            </fieldset>
          </div>
        </View>
      </Form>
    );
  }
}
