import React from 'react';
import PropTypes from 'prop-types';
import EditorLayout from 'editor/src/components/EditorLayout';

export class StorefrontEditor extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  render() {
    return <EditorLayout {...this.props} />;
  }
}

export default StorefrontEditor;
