import React, { PureComponent } from 'react';
import * as d3 from 'd3';
import { toFixed } from 'utils';
import { formatCurrencyAbbreviated } from 'utils/money';

class YAxis extends PureComponent {
  static defaultProps = {
    isCurrency: true,
    skipFractional: true,
  };

  componentDidMount() {
    this.renderD3();
  }

  componentDidUpdate() {
    this.renderD3();
  }

  renderD3() {
    const {
      currency,
      isCurrency,
      margin,
      offsetWidth,
      skipFractional,
      ticks = 3,
      width,
      yScale,
    } = this.props;

    const axis = d3
      .axisLeft()
      .scale(yScale)
      .ticks(ticks)
      .tickSizeInner(-(width - margin.left - margin.right - offsetWidth))
      .tickSizeOuter(0)
      .tickFormat((item) =>
        isCurrency && currency
          ? formatCurrencyAbbreviated(item, currency)
          : skipFractional && item % 1 !== 0
          ? ''
          : Number(toFixed(item, 2)).toLocaleString(),
      );

    d3.select(this.refs.axisAnchor)
      .attr('class', 'y axis')
      .attr('dx', '-2em')
      .call(axis)
      .selectAll('text')
      .attr('dx', '-1em');
  }

  render() {
    const { margin, offsetWidth } = this.props;
    const translate = `translate(${margin.left + offsetWidth}, ${margin.top})`;

    return <g ref="axisAnchor" transform={translate} />;
  }
}

export default YAxis;
