import React from 'react';
import pt from 'prop-types';

import { formatNumber, inflect } from 'utils';

import { Field } from 'components/form';
import PrevButton from 'components/button/prev';
import NextButton from 'components/button/next';

import Showing from './showing';

export default class CollectionPagination extends React.PureComponent {
  static propTypes = {
    collection: pt.object.isRequired,
    title: pt.string.isRequired,
    help: pt.oneOfType([pt.string, pt.node]),

    onClickPage: pt.func,
    onChangeLimit: pt.func,
  };

  static contextTypes = {
    client: pt.object.isRequired,
  };

  render() {
    const {
      client: { locale },
    } = this.context;

    const { collection, title, onClickPage, onChangeLimit, help } = this.props;

    return (
      <div className="collection-pages">
        {collection.totalPages > 1 ? (
          <div className="collection-pages-flex">
            {onClickPage && (
              <div className="collection-pages-links button-group">
                <PrevButton
                  onClick={onClickPage}
                  data-page={collection.page - 1}
                  aria-label="Previous page"
                  disabled={
                    !collection.pages || !collection.pages[collection.page - 1]
                  }
                  type="secondary"
                  size="sm"
                />

                <NextButton
                  onClick={onClickPage}
                  data-page={collection.page + 1}
                  aria-label="Next page"
                  disabled={
                    !collection.pages || !collection.pages[collection.page + 1]
                  }
                  type="secondary"
                  size="sm"
                />
              </div>
            )}

            <Showing collection={collection} />
          </div>
        ) : (
          collection.count > 0 && (
            <div className="collection-showing">
              Showing{' '}
              {inflect(
                formatNumber(collection.count, locale),
                title.toLowerCase(),
              )}
            </div>
          )
        )}

        {help}

        {!!onChangeLimit && collection.count > 15 && (
          <div className="collection-limit">
            <Field
              type="select"
              options={[
                { value: '15', label: 'Show 15 per page' },
                { value: '25', label: 'Show 25 per page' },
                { value: '50', label: 'Show 50 per page' },
                { value: '100', label: 'Show 100 per page' },
                { value: '200', label: 'Show 200 per page' },
                ...(collection.limit > 200
                  ? [
                      {
                        value: String(collection.limit),
                        label: `Show ${collection.limit} per page`,
                      },
                    ]
                  : []),
              ]}
              aria-label="Items per page"
              defaultValue={String(collection.limit)}
              onChange={onChangeLimit}
            />
          </div>
        )}
      </div>
    );
  }
}
