import find from 'lodash/find';
import some from 'lodash/some';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isNumber from 'lodash/isNumber';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import reduce from 'lodash/reduce';

import { isEmpty } from 'utils';
import { integrationName } from 'utils/integration';
import { productPrice, defaultVariantPrice } from 'shared/utils';

import { imageUrl, isObject, parseNumber } from './index';

export { productPrice, defaultVariantPrice };

export const FEATURES_BY_TYPE = {
  standard: {
    name: 'product',
    pricing: true,
    options: true,
    variants: true,
    inventory: true,
  },
  giftcard: {
    name: 'gift card product',
    giftcard: true,
    inventory: true,
  },
};

export const VARIANT_TYPES = ['select', 'toggle'];

/**
 * @param {string?} type
 * @returns
 */
export function productFeaturesByType(type) {
  return FEATURES_BY_TYPE[type] || FEATURES_BY_TYPE.standard;
}

export function productThumbUrl(
  product,
  variant,
  size = 50,
  params = undefined,
) {
  if (variant && variant.images && variant.images.length) {
    if (get(variant, 'images.0.file')) {
      return imageUrl(variant, {
        width: size,
        height: size,
        padded: false,
        ...params,
      });
    }
  }

  return imageUrl(product, {
    width: size,
    height: size,
    padded: false,
    ...params,
  });
}

export function productName(product, variant = null, product_name) {
  if (product) {
    return `${product.name || '(Unnamed product)'}${
      variant ? ` - ${variant.name || '(Unnamed variant)'}` : ''
    }`;
  } else if (product_name) {
    return `${product_name}${
      variant ? ` - ${variant.name || '(Unnamed variant)'}` : ''
    }`;
  }
  return '(Unknown product)';
}

export function mapCategoryValuesFromIndex(recordCategories, categoryIndex) {
  return recordCategories.results.map((result) => categoryIndex.get(result.id));
}

export function isProductVariable(product) {
  return (
    get(product, 'options', []).filter((option) => option.variant).length > 0
  );
}

export function isBundleItemVariable(bundleItem) {
  return (
    isProductVariable(bundleItem.product) &&
    (isEmpty(bundleItem.options) || bundleItem.variable === 'choose')
  );
}

/**
 * @param {object?} product
 * @param {object?} variant
 * @returns {number}
 */
export function productSalePrice(product, variant) {
  if (variant) {
    const value = parseFloat(variant.sale_price);

    if (variant.sale) {
      if (Number.isFinite(value)) {
        return value;
      }
    } else if (Number.isFinite(parseFloat(variant.price))) {
      return Number.NaN;
    }

    if (product) {
      const productValue = parseFloat(product.sale_price);

      if ((product.sale || variant.sale) && Number.isFinite(productValue)) {
        return defaultVariantPrice(product, variant, productValue);
      }
    }
  }

  if (product) {
    const productValue = parseFloat(product.sale_price);

    if (product.sale && Number.isFinite(productValue)) {
      return productValue;
    }
  }

  return Number.NaN;
}

/**
 * @typedef {object} ProductMinMaxPrice
 * @property {number} minPrice
 * @property {number} maxPrice
 * @property {number} minSalePrice
 * @property {number} maxSalePrice
 */

/**
 * @param {object?} product
 * @returns {ProductMinMaxPrice}
 */
export function productMinMaxPrice(product) {
  /** @type {unknown} */
  const variants = get(product, 'variants.results');

  if (Array.isArray(variants) && variants.length > 0) {
    /** @type {ProductMinMaxPrice} */
    const prices = variants.reduce(
      /**
       * @param {ProductMinMaxPrice} prices
       * @param {object} variant
       */
      (prices, variant) => {
        const price = productPrice(product, variant);

        if (prices.minPrice > price) {
          prices.minPrice = price;
        }

        if (prices.maxPrice < price) {
          prices.maxPrice = price;
        }

        const salePrice = productSalePrice(product, variant);

        if (salePrice) {
          if (prices.minSalePrice > salePrice) {
            prices.minSalePrice = salePrice;
          }

          if (prices.maxSalePrice < salePrice) {
            prices.maxSalePrice = salePrice;
          }
        }

        return prices;
      },
      {
        minPrice: Number.POSITIVE_INFINITY,
        maxPrice: Number.NEGATIVE_INFINITY,
        minSalePrice: Number.POSITIVE_INFINITY,
        maxSalePrice: Number.NEGATIVE_INFINITY,
      },
    );

    for (const [key, value] of Object.entries(prices)) {
      if (!Number.isFinite(value)) {
        prices[key] = Number.NaN;
      }
    }

    return prices;
  }

  const defaultPrice = productPrice(product);
  const defaultSalePrice = productSalePrice(product);

  const { options } = product;

  if (Array.isArray(options) && options.length > 0) {
    if (!hasDeprecatedSubscriptionPlan(product)) {
      const minPrice = defaultPrice || 0;
      const minSalePrice = defaultSalePrice;

      return options.reduce(
        /**
         * @param {ProductMinMaxPrice} prices
         * @param {object} variant
         */
        (prices, option) => {
          if (option.required) {
            const minOption = some(option.values, (item) => !item.price)
              ? undefined
              : minBy(option.values, 'price');

            if (minOption?.price) {
              prices.minPrice += Number(minOption.price);
              prices.minSalePrice += Number(minOption.price);
            }
          }

          const maxOption = maxBy(option.values, 'price');

          if (maxOption?.price) {
            prices.maxPrice += Number(maxOption.price);
            prices.maxSalePrice += Number(maxOption.price);
          }

          return prices;
        },
        {
          minPrice: minPrice,
          maxPrice: minPrice,
          minSalePrice: minSalePrice,
          maxSalePrice: minSalePrice,
        },
      );
    }
  }

  return {
    minPrice: defaultPrice,
    maxPrice: Number.NaN,
    minSalePrice: defaultSalePrice,
    maxSalePrice: Number.NaN,
  };
}

export function getVariantByOptions(product, options) {
  const { variants: { results: variants = [] } = {} } = product;
  const optionValueIds = reduce(
    options,
    (acc, option) => {
      if (option.variant) {
        acc.push(option.value_id);
      }
      return acc;
    },
    [],
  );
  return find(
    variants,
    (variant) =>
      intersection(variant.option_value_ids, optionValueIds).length ===
      optionValueIds.length,
  );
}

export function isSubscriptionItem(item) {
  if (get(item, 'purchase_option.type') === 'subscription') {
    return true;
  }
  // Deprecated plan option
  if (item && item.product && hasDeprecatedSubscriptionPlan(item.product)) {
    return true;
  }
  return false;
}

export function hasDeprecatedSubscriptionPlan(product) {
  return some(product.options, { subscription: true });
}

export function isTrialItem(item) {
  return get(item, 'purchase_option.type') === 'trial';
}

export function defaultTaxCodeAndHintText(taxServiceId, { type, delivery }) {
  let text;
  let code;
  let referenceUrl;

  const serviceName = integrationName(taxServiceId);

  if (taxServiceId === 'avatax') {
    referenceUrl = 'https://taxcode.avatax.avalara.com/';
    if (type === 'giftcard') {
      if (delivery === 'shipment') {
        text = 'physical gift cards';
        code = 'PG050000';
      } else if (delivery === 'giftcard') {
        text = 'electronic gift cards';
        code = 'DG020000';
      }
    }
    if (!code) {
      text = 'physical goods';
      code = 'P000000';
    }
  } else if (taxServiceId === 'taxjar') {
    referenceUrl = 'https://developers.taxjar.com/api/reference/#categories';
    if (type === 'giftcard') {
      if (delivery === 'shipment') {
        text = 'physical gift cards';
        code = '14111803A0001';
      } else if (delivery === 'giftcard') {
        text = 'digital goods';
        code = '31000';
      }
    }
    if (!code) {
      text = 'fully taxable product';
      code = '';
    }
  }
  return { text, code, serviceName, referenceUrl };
}

// compare 3 visible fields for billing and order schedules
function areBillingAndOrderIntervalsEqual(plan) {
  const { order_schedule, billing_schedule } = plan;

  if (!order_schedule && !billing_schedule) {
    return true;
  }

  if (!order_schedule || !billing_schedule) {
    return false;
  }

  // all empty values are handled equally (we can have '' or undefined on frontend)
  const orderLimit = order_schedule.limit || '';
  const billingLimit = billing_schedule.limit || '';

  if (
    order_schedule.interval === billing_schedule.interval &&
    order_schedule.interval_count === billing_schedule.interval_count &&
    orderLimit === billingLimit
  ) {
    return true;
  }

  return false;
}

export function cleanPurchaseOptions(purchaseOptions) {
  if (purchaseOptions) {
    if (purchaseOptions.subscription) {
      for (const plan of purchaseOptions.subscription.plans || []) {
        // hide order schedule if it is the same as billing schedule
        const areIntervalsEqual = areBillingAndOrderIntervalsEqual(plan);
        if (!plan.has_order_schedule || areIntervalsEqual) {
          delete plan.order_schedule;
          // Remove flag used to toggle order schedule
          delete plan.has_order_schedule;
        }
      }
    }
  }
  return purchaseOptions;
}

export function productPurchaseOptions(product) {
  const types = Object.keys(get(product, 'purchase_options', {}));
  return types.filter((type) =>
    get(product.purchase_options[type], 'active', true),
  );
}

export function subOptionValueIntervalDesc(value) {
  if (
    !value ||
    !value.subscription_interval ||
    !value.subscription_interval_count
  ) {
    return;
  }

  let interval;

  switch (value.subscription_interval) {
    case 'daily':
      interval = 'days';
      break;
    case 'weekly':
      interval = 'weeks';
      break;
    case 'monthly':
      interval = 'months';
      break;
    case 'yearly':
      interval = 'years';
      break;
    default:
      return value.subscription_interval;
  }

  return interval ? `${value.subscription_interval_count} ${interval}` : '';
}

// replace names with ids in parent_value_ids
export function transformCombinedOptions(options = []) {
  return options.map((item) => {
    const { parent_id, parent_value_ids = [] } = item;

    if (parent_id && parent_value_ids.length) {
      const { values = [] } = options.find((opt) => opt.id === parent_id) || {};

      for (let i = 0; i < parent_value_ids.length; i++) {
        const { id } =
          values.find((val) => val.name === parent_value_ids[i]) || {};

        if (id) {
          parent_value_ids[i] = id;
        }
      }
    }

    return item;
  });
}

export function isQuantityValid(value) {
  return value !== undefined && (isNumber(parseNumber(value)) || value === '');
}

export function hasQuantityMaxInIndexKey(value) {
  return (
    isObject(value) &&
    Object.prototype.hasOwnProperty.call(value, 'quantity_max')
  );
}

export function getQuantityMaxValue(currentValue, recordValue) {
  return isQuantityValid(currentValue)
    ? currentValue
    : isQuantityValid(recordValue)
    ? recordValue
    : null;
}

export function getCleanOptions(options) {
  if (!options || options.length === 0) {
    return [];
  }

  const cleanOptions = options
    .map((op) => ({
      ...op,
      // New active flag sep 2019
      active: op.active !== undefined ? op.active : true,
    }))
    // Subscription option deprecated Oct 2021
    .filter((op) => !op.subscription);

  return cleanOptions;
}
