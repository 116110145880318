import React from 'react';
import pt from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import each from 'lodash/each';
import get from 'lodash/get';

import View from 'components/view/view';
import CollectionPage from 'components/reports/collection';
import CollectionLoading from 'components/collection/loading';
import DateRangePicker from 'components/date-range-picker';
import BulkExport from 'components/bulk/export';

import { isEmpty, locationWithQuery } from 'utils';
import { getChartPeriod, formatReportDate } from 'utils/chart';

export default class ReportsCollection extends React.Component {
  static propTypes = {
    title: pt.string,
    headerTitle: pt.string,
    startDate: pt.object,
    endDate: pt.object,
    model: pt.string.isRequired,
    bulk: pt.object,
    query: pt.object,
    router: pt.object,
    fields: pt.array,
    reportType: pt.string,
    searchQuery: pt.string,
    routeParams: pt.object,
    location: pt.object,
    charts: pt.array,
    component: pt.node,

    page: pt.func,
    onReportUpdate: pt.func,
    onChangeQuery: pt.func,
    onChangeDates: pt.func,
    onResetReport: pt.func,

    // connected actions
    fetchCollection: pt.func,
    fetchGraph: pt.func,
    createCustom: pt.func,
    updateCustom: pt.func,
    deleteCustom: pt.func,
    bulkExport: pt.func,
    bulkCancel: pt.func,
  };

  static contextTypes = {
    setLastUrl: pt.func.isRequired,
    client: pt.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      showingExport: false,
      collection: null,
      activeFilters: [],
      filterKeys: null,
      filterOp: null,
      filterValue: null,
      filterValues: null,
      filterListVisible: false,
      onClickSort: this.onClickSort.bind(this),
      onChangeDates: this.onChangeDates.bind(this),
      onClickPage: this.onClickPage.bind(this),
      onChangeFields: this.onChangeFields.bind(this),
      onSubmitSearch: this.onSubmitSearch.bind(this),
      onChangeSearch: this.onChangeSearch.bind(this),
      onChangeActiveFilters: this.onChangeActiveFilters.bind(this),
      onClickRemoveFilter: this.onClickRemoveFilter.bind(this),
      onClickApplyFilter: this.onClickApplyFilter.bind(this),
      onClickShowFilterList: this.onClickShowFilterList.bind(this),
      onSaveAs: this.onSaveAs.bind(this),
      onUpdateCustom: this.onUpdateCustom.bind(this),
      onDeleteCustom: this.onDeleteCustom.bind(this),
      onClickExport: this.onClickExport,
      onClickExportCancel: this.onClickExportCancel,
      onClickExportReset: this.onClickExportReset,
      onSubmitExport: this.onSubmitExport,
    };
  }

  componentDidMount() {
    const {
      location,
      fetchCollection,
      charts,
      routeParams: { type: reportId },
    } = this.props;

    fetchCollection(location, reportId).then(async (collection) => {
      if (charts) {
        await this.getCharts();
      }

      this.context.setLastUrl(null);

      this.setState({ collection, loaded: true });
    });
  }

  componentDidUpdate(prevProps) {
    const { query, location, fetchCollection, charts } = this.props;

    const isCollectionChanged =
      location.pathname !== prevProps.location.pathname;

    const isQueryChanged = !isEqual(query, prevProps.query);

    const {
      routeParams: { type: reportId },
    } = this.props;

    if (isCollectionChanged) {
      this.setState({ loaded: false });

      fetchCollection(location, reportId, this.props).then((collection) => {
        this.setState({ collection, loaded: true });
      });
    } else if (isQueryChanged) {
      fetchCollection(location, reportId, this.props).then((collection) => {
        this.setState({ collection, loaded: true });

        if (charts) {
          this.getCharts(this.props);
        }
      });
    }
  }

  async getCharts(props = this.props) {
    const { timezone } = this.context.client;
    const { charts, startDate, endDate, fetchGraph } = props;

    const finalCharts = [];
    for (const chartConfig of charts) {
      const period = chartConfig.period || getChartPeriod(startDate, endDate);
      const { type: chartType } = chartConfig;

      await fetchGraph(chartType, startDate, endDate, period)
        .then((response) => response?.results || [])
        .then((points) => {
          const chart = cloneDeep(chartConfig);

          chart.data = chart.data.map((line) => ({
            ...line,
            points: points.map((point) => [point.x, point[line.field] || 0]),
          }));

          chart.xFormat = formatReportDate(period, timezone);

          finalCharts.push(chart);
        });
    }
    this.setState({ charts: finalCharts });
  }

  onSaveAs({ name, checkedFields, min, max }) {
    const columns = {},
      params = {
        name,
        min,
        max,
        type: this.props.reportType,
      };

    for (let groupKey in checkedFields) {
      const group = checkedFields[groupKey];

      for (let fieldKey in group.fields) {
        if (columns[groupKey]) {
          columns[groupKey].push(fieldKey);
        } else {
          columns[groupKey] = [fieldKey];
        }
      }
    }

    if (!isEmpty(columns)) {
      params.columns = columns;
    }

    this.props
      .createCustom(params)
      .then((custom) => this.props.router.push(`/reports/${custom.id}`));
  }

  onUpdateCustom(params) {
    this.props.onReportUpdate(params);

    this.props.updateCustom(params);
  }

  onDeleteCustom({ id }) {
    this.props
      .deleteCustom({ id })
      .then((custom) => this.props.router.push('/reports'));
  }

  onClickSort(sort, originalPath) {
    const [fields, direction] = sort.split(' '),
      sortQuery = fields.split(',').reduce((memo, field) => {
        memo[field] = direction === 'asc' ? 1 : -1;

        return memo;
      }, {});

    this.props.onChangeQuery({ sort: sortQuery });
  }

  onChangeDates({ startDate, endDate }) {
    this.props.onChangeDates(startDate, endDate);
  }

  onChangeFields(fields) {
    this.props.onChangeQuery({ fields });
  }

  onSubmitSearch = (values) => {
    const { search } = values;
    this.props.onChangeQuery({ search });
  };

  onChangeSearch({ filterKeys, filterOp, filterValue, filterValues }) {
    this.setState({
      filterKeys: { ...filterKeys },
      filterOp,
      filterValue,
      filterValues,
    });
  }

  onChangeActiveFilters = (activeFilters) => {
    this.setState({ activeFilters });
  };

  onClickApplyFilter(event) {
    event.preventDefault();
    const { filterKeys, filterValue, filterValues } = this.state;

    let filter = {};
    each(filterKeys, (active, key) => {
      if (active) {
        if (filterValues && !isEmpty(filterValues[key])) {
          filter[key] = filterValues[key];
        } else if (filterValue && filterValue[key]) {
          filter[key] = filterValue[key].id
            ? filterValue[key].id
            : filterValue[key];
        }
      }
    });

    this.setState({
      filterListVisible: false,
    });
    this.props.onChangeQuery({ filter });
  }

  onClickRemoveFilter(event) {
    event.preventDefault();
    const { location } = this.props;
    const filterKey = event.currentTarget.dataset.key;
    const next = locationWithQuery(location, { [filterKey]: undefined });
    this.props.router.push(next);
  }

  onClickShowFilterList(event) {
    event.preventDefault();
    this.setState({
      filterListVisible: !this.state.filterListVisible,
    });
  }

  onClickPage(event) {
    event.preventDefault();
    const { page } = event.currentTarget.dataset;
    this.props.onChangeQuery({ page });
  }

  onClickReset = (event) => {
    event.preventDefault();
    this.props.onResetReport();
  };

  onClickExport = (event) => {
    event.preventDefault();
    this.setState({ showingExport: !this.state.showingExport });
  };

  onClickExportCancel = (event) => {
    event.preventDefault();
    if (this.props.bulk.running) {
      this.props.bulkCancel();
    }
    this.setState({ showingExport: false });
  };

  onClickExportReset = (event) => {
    event.preventDefault();
    this.props.bulkCancel();
  };

  onSubmitExport = (values) => {
    const exportCSVFields = this.props.fields.map((field) => ({
      key: field.path,
      label: field.label,
      export: field.func || ((result) => get(result, field.path)),
    }));

    this.props.bulkExport({
      ...values,
      method: 'post',
      filename: `${this.props.reportType}-report`,
      csvFields: exportCSVFields,
      page: true,
      query: {
        ...this.props.query,
        ...this.props.location.query,
        fields: undefined,
      },
    });
  };

  render() {
    if (!this.state.loaded) {
      return <CollectionLoading />;
    }

    const { component, title, headerTitle } = this.props;

    if (component) {
      return <component {...this.props} {...this.state} />;
    }
    
    return (
      <div className="reports-collection">
        <View
          {...this.props}
          {...this.state}
          headerActions={[
            !isEmpty(this.props.location.query) && {
              label: 'Reset',
              type: 'sub',
              onClick: this.onClickReset,
            },
            {
              label: 'Export',
              fa: 'arrow-to-bottom',
              type: 'sub',
              onClick: this.onClickExport,
            },
            {
              key: 'report-date-range-picker',
              component: (
                <DateRangePicker
                  name="report-date-range-picker"
                  aria-label="Select date range"
                  startDate={this.props.startDate}
                  endDate={this.props.endDate}
                  onChangeDates={this.state.onChangeDates}
                />
              ),
            },
          ]}
          headerTitle={headerTitle !== undefined ? headerTitle : title}
        >
          <CollectionPage {...this.props} {...this.state} />

          {this.state.showingExport && (
            <BulkExport
              label="Report"
              {...this.props}
              {...this.state}
              exportOptions={[]}
            />
          )}
        </View>
      </div>
    );
  }
}
