import React, { Fragment } from 'react';
import TemplateEngine from 'swell-template';
import { find, map, reduce, isObject } from 'lodash';

import { formatCurrencyMaybeRound, isEmpty } from 'utils';
import { getStorefrontURL } from 'utils/storefront';

import Icon from 'components/icon';

import api from 'services/api';
import Popover from 'components/popover';

export const filterOperators = {
  in: '$in',
  nin: '$nin',
  ne: '$ne',
  eq: '$eq',
  lt: '$lt',
  gt: '$gt',
  before: '$lte',
  after: '$gte',
};

export const filterOperatorsToDesc = {
  in: 'contains',
  nin: 'does not contain',
  eq: '',
  ne: 'not',
  lt: <Icon fa="less-than" />,
  gt: <Icon fa="greater-than" />,
  before: 'before',
  after: 'after',
};

export const filterOperatorsToLabel = {
  in: 'One of',
  nin: 'Not one of',
  eq: 'Equals',
  ne: 'Not equals',
  lt: 'Less than',
  gt: 'Greater than',
  before: 'Before',
  after: 'After',
};

// Convert URL query filters to array of where clauses
export function filterQueryToWhere(filters, query) {
  // Example: ?key[operator]=value&key=value
  const activeFilters = { filterQuery: [], filterWhere: [] };
  map(query, (q, key) => {
    const filter = find(filters, { id: key });
    if (!filter) {
      return;
    }
    let result;
    if (isObject(q) && !(q instanceof Array)) {
      const field = filter.field || key;
      const where = reduce(
        q,
        (where, value, op) => {
          if (!isEmpty(value)) {
            const operator = filterOperators[op];
            if (operator) {
              where[field] = where[field] || {};
              where[field][operator] = value;
            } else {
              where[field] = value;
            }
          }
          return where;
        },
        {},
      );
      if (typeof filter.func === 'function') {
        result = filter.func(q, where);
      } else {
        result = where;
      }
    } else {
      if (typeof filter.func === 'function') {
        result = filter.func(q);
      } else {
        result = { [key]: q };
      }
    }
    if (result) {
      if (filter.query) {
        activeFilters.filterQuery.push(result);
      } else {
        activeFilters.filterWhere.push(result);
      }
    }
  });
  return activeFilters;
}

// Get description of a single filter and query value
export function filterQueryToDescription(filter, value) {
  let valueDesc = filter.valueLabel || value;

  if (Array.isArray(value)) {
    valueDesc = value.join(', ');
  } else if (isObject(value)) {
    const desc = reduce(
      value,
      (desc, val, op) => {
        if (String(val).length === 0) {
          return desc;
        }

        const operator = filterOperatorsToDesc[op] || 'is';

        const valueLabel =
          filter.valueLabels && filter.valueLabels[op]
            ? filter.valueLabels[op]
            : filter.valueLabel ||
              (filter.type === 'currency'
                ? formatCurrencyMaybeRound(val)
                : val);

        if (operator === 'after' && value.before) {
          return <Fragment>between {valueLabel}</Fragment>;
        } else if (operator === 'gt' && value.lt) {
          return <Fragment>between {valueLabel}</Fragment>;
        } else if (operator === 'before' && desc) {
          return (
            <Fragment>
              {desc} and {valueLabel}
            </Fragment>
          );
        } else if (operator === 'lt' && desc) {
          return (
            <Fragment>
              {desc} and {valueLabel}
            </Fragment>
          );
        } else if (desc) {
          return (
            <Fragment>
              {desc} and {operator} {valueLabel}
            </Fragment>
          );
        }
        return (
          <Fragment>
            {operator} {valueLabel}
          </Fragment>
        );
      },
      '',
    );

    return (
      <Fragment>
        <span className="collection-filter-value-label">{filter.label}</span>{' '}
        {desc}
      </Fragment>
    );
  }

  if (filter.options) {
    const opt = find(filter.options, { value });

    if (opt) {
      valueDesc = opt.label || opt.value;
    }
  }

  if (filter.desc) {
    if (typeof filter.desc === 'function') {
      return `${filter.desc(value)}`;
    }

    return `${filter.desc} ${valueDesc}`;
  } else if (filter.desc !== undefined) {
    return valueDesc;
  }

  return (
    <Fragment>
      <span className="collection-filter-value-label">{filter.label}</span>{' '}
      {valueDesc}
    </Fragment>
  );
}

export function isSearching(router, data) {
  const { location } = router;
  const {
    query: { search },
    filterQuery,
  } = data;
  if (location.query && location.query.search) {
    return true;
  }
  if (search) {
    return true;
  }
  if (!isEmpty(filterQuery)) {
    return true;
  }
  return false;
}

export function truncatedText(text, maxlength = 100) {
  if (text && maxlength > 0 && text.length > maxlength) {
    return (
      <Popover
        openOnHover
        align="middle"
        message={text}
        triggerOnClick={() => {}} // Prevent default popover `onClick` behavior
        triggerClassName="truncated-popover-text"
        triggerChildren={text.substr(0, maxlength) + '...'}
        contentRest={{ style: { maxWidth: 320 } }}
      />
    );
  }
  return text;
}

function getTemplateDefaults(client, primaryStorefront = undefined) {
  const { CLIENT_URL } = process.env;
  return {
    currency: client.currency,
    store: {
      ...client,
      admin_url: CLIENT_URL.replace('CLIENT_ID', client.id),
      url: primaryStorefront
        ? getStorefrontURL(primaryStorefront)
        : CLIENT_URL.replace('CLIENT_ID', client.id).replace(/admin$/, ''),
    },
  };
}

export function getTemplateEngine(client, primaryStorefront = undefined) {
  return new TemplateEngine({
    ...getTemplateDefaults(client, primaryStorefront),
    get: (url, data) => {
      return api.getLocalized(`/data/${url}`, data);
    },
    details: {
      ...client,
    },
  });
}

// Set template engine globally
export let TEMPLATE_ENGINE = null;
export function setTemplateEngine(engine) {
  TEMPLATE_ENGINE = engine || null;
}

// Easiest way to render a template string from a sub component
// Template engine contains defaults including client and storefront
export async function renderTemplate(template, data) {
  if (TEMPLATE_ENGINE) {
    let result;
    await TEMPLATE_ENGINE.render(template, data)
      .then((r) => (result = r))
      .catch((err) => {
        console.warn(`Template ${err.toString()}`);
      });
    return result !== undefined ? result : template;
  }
  return template;
}

export function getVisibleConfigsByLocation(configs, location) {
  // Always convert objects to arrays
  let arrayConfigs = configs;
  if (!isEmpty(configs) && !Array.isArray(configs)) {
    arrayConfigs = [...map(configs, (val, id) => ({ id, ...val }))];
  }

  const shouldShow = (value) =>
    typeof value.show === 'function'
      ? value.show(location)
      : value.show !== false && value.hidden !== false;

  return reduce(
    arrayConfigs,
    (acc, config) => {
      if (shouldShow(config)) {
        acc.push(config);
      }
      return acc;
    },
    [],
  );
}
