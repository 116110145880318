import React from 'react';
import { find } from 'lodash';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import { LOCALES } from 'utils';
import './locale.scss';

export default class LocaleIcon extends React.PureComponent {
  render() {
    const { locale } = this.props;
    const localeConfig = find(LOCALES, { code: locale }) || {};
    return (
      <Tooltip message={localeConfig.name || locale}>
        <Icon locale={locale} />
      </Tooltip>
    );
  }
}
