import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'components/form';
import { filter, find, map, reduce } from 'lodash';
import './lookup-generic.scss';
import { productThumbUrl } from 'utils/product';
import { FadeIn } from 'components/transitions';
import Icon from 'components/icon';
import { singularize } from 'utils/';

const LOOKUP_QUERIES = {
  products: {
    fields: 'name, images',
  },
  categories: {
    fields: 'name, images',
  },
};

export default class LookupGeneric extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    field: PropTypes.object,
    onChange: PropTypes.func,
    lookup: PropTypes.object,
    value: PropTypes.array,
  };

  getLookupQueries(models) {
    reduce(models, (acc, cur) => (acc[cur] = LOOKUP_QUERIES[cur]), {});
  }

  renderFinderItems({ lookup, ...props }) {
    const valueIndex = props.valueIndex || {};
    return lookup.results.map((record) => (
      <li
        key={record.id}
        role="option"
        data-id={record.id}
        aria-selected={record.id === props.selected?.id}
        className={`item form-lookup-generic-item ${
          record.id === props.selected?.id ? 'selected' : null
        }`}
        onMouseOver={props.onMouseOver}
        onFocus={props.onMouseOver}
        onClick={props.onClick}
      >
        <span className="col">
          <FadeIn
            className="check"
            transitionAppear={false}
            active={valueIndex[record.id]}
          >
            <Icon fa="check" />
          </FadeIn>
          <span className="image">
            <img alt="" src={productThumbUrl(record)} />
          </span>
          {record.name}
        </span>
        <span className="col muted text-right text-capitalize">
          {singularize(record._model)}
        </span>
      </li>
    ));
  }

  onSelectValue(value, selected, onChange) {
    if (!value) {
      onChange([selected]);
    }

    if (value && !find(value, (valueItem) => valueItem.id === selected.id)) {
      onChange([...value, selected]);
    } else if (
      value &&
      find(value, (valueItem) => valueItem.id === selected.id)
    ) {
      onChange([...filter(value, (valueItem) => valueItem.id !== selected.id)]);
    }
  }

  render() {
    const { label, field, onChange, lookup, value } = this.props;
    return (
      <Fragment>
        <Field
          type="finder"
          name="find-items"
          placeholder={field.placeholder || 'Pick items'}
          models={field.models}
          queries={this.getLookupQueries(field.models)}
          renderLookupItems={this.renderFinderItems}
          lookup={lookup}
          defaultValue={value}
          label={label}
          required={field.required ?? false}
          onSelectValue={(selected) =>
            this.onSelectValue(value, selected, onChange)
          }
        />
        <ul className="LookupGeneric-list">
          {map(value, (item, index) => (
            <li
              key={item.id}
              className={[
                index === 0 ? 'first' : '',
                index === value.length - 1 ? 'last' : '',
              ].join(' ')}
            >
              <span className="LookupGeneric-list-title">{item.name}</span>
              <span className="LookupGeneric-list-title-label text-capitalize">
                {singularize(item._model)}
              </span>
              <button
                className="LookupGeneric-close"
                aria-label="Close"
                onClick={() => this.onSelectValue(value, item, onChange)}
              />
            </li>
          ))}
        </ul>
      </Fragment>
    );
  }
}
