import React from 'react';

import { couponCodeSingle } from 'utils/discount';

import View from 'components/view';
import { Form, TabView } from 'components/form';
import WithViewPreferences from 'containers/WithViewPreferences';
import ContentRecordTab from 'components/content/record-tab';

import CouponForm from './form';

import './coupon.scss';

export default class CouponEdit extends React.PureComponent {
  render() {
    const {
      record,
      edited,
      location,
      onSubmitRecord,
      onChangeForm,
      onClickDelete,
      ...props
    } = this.props;

    const name = record.name || couponCodeSingle(record);

    return (
      <div>
        <Form ref="form" onSubmit={onSubmitRecord} onChange={onChangeForm}>
          <WithViewPreferences
            {...this.props}
            tabs={[]}
            model="coupons"
            id="edit"
            type="record"
          >
            <View
              detail={true}
              uri="/coupons"
              devtools={{
                model: 'coupons',
                record,
              }}
              withPreferences={true}
              sectionTitle="Coupons"
              headerTitle={name}
              headerActions={[
                {
                  label: 'Save coupon',
                  submit: true,
                  type: edited ? 'default' : 'secondary disabled',
                },
              ]}
              extraActions={[
                {
                  label: 'Delete coupon',
                  onClick: onClickDelete,
                  className: 'danger',
                  delete: true,
                },
              ]}
              localized={true}
            >
              <TabView value={location.query.tab} isDefault>
                <CouponForm
                  {...props}
                  onChangeForm={onChangeForm}
                  record={record}
                />
              </TabView>
              <ContentRecordTab
                {...this.props}
                collection="coupons"
                view="edit"
              />
            </View>
          </WithViewPreferences>
        </Form>
      </div>
    );
  }
}
