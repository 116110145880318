import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, map, last } from 'lodash';
import { Field } from 'components/form';
import Link from 'components/link';
import Icon from 'components/icon';
import DateRangePicker from 'components/date-range-picker';
import CollectionTable from 'components/collection/collection';
import { FadeIn } from 'components/transitions';
import SalesOverview from 'components/reports/overview/sales';
import CustomersOverview from 'components/reports/overview/customers';
import ReportsCollection from 'components/reports/collection/collection';
import SectionHeader from '../section-header/section-header';
import { timeGreeting, formatNumber, toFixed } from 'utils';
import { formatCurrencyRound } from 'utils/money';

import './dashboard.scss';

export default class DashboardStats extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  rendered = false;

  componentDidMount() {
    setTimeout(() => (this.rendered = true), 1000);
  }

  onChangeDates = ({ startDate, endDate, compareTo }) => {
    this.props.onChangeDates({
      startDate,
      endDate,
      compareTo,
    });
  };

  render() {
    const { client, user } = this.context;
    const {
      stats,
      period,
      startDate,
      endDate,
      nextSteps,
      reportOptions,
      firstModel,
      firstReport,
      location,
      router,
    } = this.props;

    const { currency } = client;

    if (!stats) {
      return null;
    }

    return (
      <FadeIn transitionAppear={!nextSteps.length}>
        {nextSteps.length > 0 && <div className="dashboard-stats-divider" />}
        <div className="dashboard-stats">
          {!nextSteps.length && (
            <div className="dashboard-stats-welcone">
              <h2 className="dashboard-welcome-title">
                Good {timeGreeting()}, {user.firstName}
              </h2>
              <p className="dashboard-welcome-subtitle">
                Here's the latest activity in your store.
              </p>
            </div>
          )}
          {firstModel ? (
            <div className="dashboard-stats-head">
              <SectionHeader
                className="dashboard-stats-heading"
                title={reportOptions[firstModel.group].label}
              />
              <div className="dashboard-stats-actions">
                <Field
                  type="multiselect"
                  name="fields"
                  placeholder="Edit views"
                  options={reportOptions}
                  onChange={this.props.onChangeDashboardOptions}
                />
              </div>
            </div>
          ) : (
            !firstReport && (
              <Fragment>
                <div className="dashboard-stats-head">
                  <SectionHeader
                    className="dashboard-stats-heading"
                    title="Dashboard"
                  />
                  <div className="dashboard-stats-actions">
                    <Field
                      type="multiselect"
                      name="fields"
                      placeholder="Edit views"
                      options={reportOptions}
                      onChange={this.props.onChangeDashboardOptions}
                    />
                  </div>
                </div>
                <FadeIn className="dashboard-stats-box">
                  <div className="collection-none-found">
                    No dashboard views selected
                  </div>
                </FadeIn>
              </Fragment>
            )
          )}
          {map(reportOptions, (statGroup, group) => {
            if (!statGroup.model) {
              return null;
            }
            return map(statGroup.fields, (stat, key) => {
              if (!get(stats.models, `${group}.${key}`)) {
                return null;
              }
              const { model, modelFields } = statGroup;
              const { label, result, id } = stats.models[group][key];
              return (
                <Fragment key={`${group}.${id}`}>
                  {group !== firstModel.group && (
                    <div className="dashboard-stats-head">
                      <SectionHeader
                        className="dashboard-stats-heading"
                        title={statGroup.label}
                      />
                    </div>
                  )}
                  <FadeIn
                    className="dashboard-stats-box"
                    transitionAppear={this.rendered}
                  >
                    <div className="box-title">{label}</div>
                    <CollectionTable
                      title={label}
                      emptyDescription={`No ${statGroup.label.toLowerCase()} ${label.toLowerCase()}`}
                      fields={modelFields}
                      collection={result}
                      location={location}
                      router={router}
                      searchable={false}
                      linkUri={`/${model}`}
                    />
                    {result.count > result.results.length && (
                      <div className="box-action">
                        <Link to={`/${model}?tab=${id}`}>
                          View all {formatNumber(result.count, client.locale)}{' '}
                          &rarr;
                        </Link>
                      </div>
                    )}
                  </FadeIn>
                </Fragment>
              );
            });
          })}
          {firstReport && (
            <div
              className={`dashboard-stats-head ${
                firstModel ? 'with-more-margin' : ''
              }`}
            >
              <SectionHeader
                className="dashboard-stats-heading"
                title="Reports"
              />
              <div className="dashboard-stats-actions">
                {!firstModel && (
                  <Field
                    type="multiselect"
                    name="fields"
                    placeholder="Edit views"
                    options={reportOptions}
                    onChange={this.props.onChangeDashboardOptions}
                  />
                )}
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onChangeDates={this.onChangeDates}
                  compareTo
                />
              </div>
            </div>
          )}
          {map(reportOptions, (statGroup, group) => {
            if (!statGroup.report) {
              return null;
            }
            return map(statGroup.fields, (stat, key) => {
              if (!get(stats.reports, `${group}.${key}`)) {
                return null;
              }
              const {
                label,
                id,
                url,
                current,
                previous,
                query,
                result,
                fields,
              } = stats.reports[group][key];
              let component;
              if (id === 'sales') {
                const ordersDiff = toFixed(
                  previous.summary.orders &&
                    (current.summary.orders / previous.summary.orders - 1) *
                      100,
                  0,
                );
                const discountDiff = toFixed(
                  previous.summary.discount_total &&
                    (current.summary.discount_total /
                      previous.summary.discount_total -
                      1) *
                      100,
                  0,
                );
                const totalDiff = toFixed(
                  previous.summary.grand_total &&
                    (current.summary.grand_total /
                      previous.summary.grand_total -
                      1) *
                      100,
                  0,
                );
                const iconUp = (
                  <Fragment>
                    <Icon fa="arrow-alt-up" faType="solid" />{' '}
                  </Fragment>
                );
                const iconDown = (
                  <Fragment>
                    <Icon fa="arrow-alt-down" faType="solid" />{' '}
                  </Fragment>
                );
                component = (
                  <Fragment>
                    <SalesOverview
                      points={[current, previous]}
                      period={period}
                    />
                    <div className="dashboard-stats-kpis">
                      <div className="dashboard-stats-kpi">
                        <h5 className="dashboard-stats-kpi-heading">
                          Net sales
                        </h5>
                        <div className="dashboard-stats-kpi-value">
                          {formatCurrencyRound(
                            current.summary.grand_total,
                            currency,
                          )}
                          <span className="dashboard-stats-kpi-diff">
                            {totalDiff > 0 && (
                              <span className="positive">
                                {iconUp}
                                {totalDiff}%
                              </span>
                            )}
                            {totalDiff < 0 && (
                              <span className="muted">
                                {iconDown}
                                {-totalDiff}%
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="dashboard-stats-kpi">
                        <h5 className="dashboard-stats-kpi-heading">Orders</h5>
                        <div className="dashboard-stats-kpi-value">
                          {current.summary.orders || 0}
                          <span className="dashboard-stats-kpi-diff">
                            {ordersDiff > 0 && (
                              <span className="positive">
                                {iconUp}
                                {ordersDiff}%
                              </span>
                            )}
                            {ordersDiff < 0 && (
                              <span className="muted">
                                {iconDown}
                                {-ordersDiff}%
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="dashboard-stats-kpi">
                        <h5 className="dashboard-stats-kpi-heading">
                          Discounts
                        </h5>
                        <div className="dashboard-stats-kpi-value">
                          {formatCurrencyRound(
                            current.summary.discount_total,
                            currency,
                          )}
                          <span className="dashboard-stats-kpi-diff">
                            {discountDiff > 0 && (
                              <span className="positive">
                                {iconUp}
                                {discountDiff}%
                              </span>
                            )}
                            {discountDiff < 0 && (
                              <span className="muted">
                                {iconDown}
                                {-discountDiff}%
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              } else if (id === 'customers') {
                component = (
                  <CustomersOverview
                    points={[current, previous]}
                    period={period}
                  />
                );
              } else {
                component = (
                  <ReportsCollection
                    emptyDescription="No data to report"
                    fields={fields}
                    query={query}
                    collection={result}
                  />
                );
              }
              if (!component) {
                return null;
              }
              return (
                <Fragment key={`${group}.${id}`}>
                  <FadeIn
                    className="dashboard-stats-box"
                    transitionAppear={this.rendered}
                  >
                    <div className="box-title">{label}</div>
                    {component}
                    <div className="box-action">
                      <Link to={`/reports/${url}`}>View report &rarr;</Link>
                    </div>
                  </FadeIn>
                </Fragment>
              );
            });
          })}
        </div>
      </FadeIn>
    );
  }
}
