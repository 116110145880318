import React from 'react';
import { find } from 'lodash';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import { STOREFRONT_HOSTING_PROVIDERS } from 'constants/storefront';

function hostingName(id) {
  return find(STOREFRONT_HOSTING_PROVIDERS, { id })?.name;
}

export default function HostingIcons({ hostingProviders = [] }) {
  return (
    <div className="storefront-hosting-icons">
      {hostingProviders?.map((hosting) => (
        <Tooltip key={hosting} message={hostingName(hosting)}>
          <Icon
            svgType={`hosting/${hosting}`}
            fallback="info"
            width={30}
            height={30}
          />
        </Tooltip>
      ))}
    </div>
  );
}
