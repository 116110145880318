import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ViewLoading from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';
import actions from 'actions';

import {
  Storefront,
  mapStateToProps as sfMapStateToProps,
  mapDispatchToProps as sfMapDispatchToProps,
} from 'containers/Storefronts';
import StorefrontView from 'components/storefront/view';

export const mapStateToProps = (state) => ({
  ...sfMapStateToProps(state),
  record: state.storefronts.storefront,
  loadingAny: state.loading,
  loading: state.storefronts.loading,
});

export const mapDispatchToProps = (dispatch) => ({
  ...sfMapDispatchToProps(dispatch),

  fetchStorefront: (id) => {
    return dispatch(actions.storefronts.fetch(id));
  },

  fetchThumbnails: (storefront) => {
    return dispatch(actions.storefronts.fetchThumbnails(storefront));
  },

  updateStorefront: (id, data) => {
    return dispatch(actions.storefronts.update(id, data));
  },

  deleteStorefront: (name, data) => {
    return dispatch(actions.storefronts.delete(name, data));
  },
});

export class ViewStorefront extends Storefront {
  static contextTypes = {
    ...Storefront.contextTypes,
    client: PropTypes.object.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifyDeleted: PropTypes.func.isRequired,
  };

  mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loaded: false,
      creating: false,
      creatingError: false,
      creatingQueue: null,
      onClickDelete: this.onClickDelete.bind(this),
    };
  }

  componentDidMount() {
    const { params } = this.props;

    this.mounted = true;

    this.setupStorefront(params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.id !== nextProps.params.id) {
      this.setState({ loaded: false });
      this.setupStorefront(nextProps.params.id);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onClickDelete = (event) => {
    event?.preventDefault();
    const { record: storefront, deleteStorefront, router } = this.props;

    if (!storefront) {
      this.context.notifyError('Storefront not found');
      return;
    }

    this.context.openModal('ConfirmDelete', {
      title: 'storefront',
      action: 'Confirm',
      confirmInput: {
        description: `Enter the storefront name to confirm`,
        value: storefront.name,
      },
      message: `Are you sure you want to delete this storefront permanently?`,
      onConfirm: () => {
        deleteStorefront(storefront.id).then((deleted) => {
          router.push('/storefronts');
        });
      },
    });
  };

  async setupStorefront(storefrontId) {
    const { fetchStorefront, fetchThumbnails, router } = this.props;
    const storefront = await fetchStorefront(storefrontId);

    if (storefront) {
      this.setState({ loaded: true });

      if (
        !storefront.thumbnails &&
        (storefront.hosted || storefront.external?.custom_url)
      ) {
        fetchThumbnails(storefront).then(() => fetchStorefront(storefrontId));
      }
    } else {
      router.push('/storefronts');
    }

    return storefront;
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    if (!this.props.record) {
      return <NotFoundPage />;
    }

    return (
      <Fragment>
        <StorefrontView {...this.props} {...this.state} />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewStorefront);
