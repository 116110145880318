/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PaymentMethodLogo from 'components/settings/payment-method-logo';
import { Field } from 'components/form';
import Status from 'components/status';
import { isLiveMode } from 'utils/payment';

export default class PayPalCommerce extends React.PureComponent {
  static propTypes = {
    paypalMethod: PropTypes.object,
    onEnable: PropTypes.func,
    onClickEdit: PropTypes.func,
  };

  render() {
    const { paypalMethod, onClickEdit, onEnable } = this.props;
    const { ppcp_activated, ppcp_enabled, mode } = paypalMethod;
    const isLive = isLiveMode(mode);

    return (
      <div
        data-testid="rtl-paypalBox"
        className="settings-integrations-box box"
      >
        <div className="box-grid">
          <div className="box-column">
            <div className="box-title payment-method">
              <PaymentMethodLogo id="paypal" />
              PayPal Commerce
            </div>
          </div>
          <div className="box-column">
            <Field
              type="toggle"
              name="methods.paypal.ppcp_enabled"
              defaultChecked={ppcp_enabled}
              onPreChange={onEnable}
            />
          </div>
        </div>
        <div className="box-subtitle">
          Enable customers to pay with PayPal Commerce Platform at checkout
        </div>
        <div className="box-actions">
          <Fragment>
            <a
              href=""
              onClick={onClickEdit}
              className="button button-sm button-secondary"
            >
              Settings
            </a>
            {ppcp_activated && ppcp_enabled && !isLive && (
              <Fragment>
                <Status type="positive">Test mode</Status>
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    );
  }
}
