import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { getCleanBundleItems } from 'utils/order';

import { getAccountAddressesQuery, getAccountCardsQuery } from 'utils/account';

import api, { tokenizeCard } from 'services/api';

import actions from 'actions';

import ViewPage from 'components/pages/subscription/view';
import ViewLoading from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';

export const mapStateToProps = (state) => ({
  record: state.data.record,
  related: state.data.related,
  prev: state.data.record && (state.data.record.prev || undefined),
  next: state.data.record && (state.data.record.next || undefined),
  loading: state.data.loading,
  errors: state.data.recordErrors,
  lookup: state.lookup,
  categories: state.categories,
  content: state.content,
  settings: state.settings,
  discounts: state.orders.discounts,
  suggestedAddresses: state.lookup.suggestedAddresses,
});

export const getMapDispatchToProps =
  (mapMore = null) =>
  (dispatch) => ({
    fetchRecord(id) {
      const seriesQuery = dispatch(
        actions.data.getCollectionSeriesQuery('subscriptions'),
      );

      return dispatch(
        actions.data.fetchRecord('subscriptions', id, {
          expand: [
            'account',
            'product',
            'product.bundle_items.product',
            'product.bundle_items.variant',
            'variant',
            'items.product',
            'items.variant',
            'items.bundle_items.product',
            'items.bundle_items.variant',
            'coupon',
          ],
          include: {
            prev: {
              url: '/subscriptions/:first',
              data: {
                ...seriesQuery,
                draft: { $ne: true },
              },
              params: {
                id: { $gt: 'id' },
              },
            },
            next: {
              url: '/subscriptions/:last',
              data: {
                ...seriesQuery,
                draft: { $ne: true },
              },
              params: {
                id: { $lt: 'id' },
              },
            },
            order: {
              url: '/orders/{order_id}',
              data: {
                fields: [
                  'billing',
                  'number',
                  'items',
                  'date_created',
                  'canceled',
                  'paid',
                  'payment_balance',
                  'payment_total',
                  'refund_total',
                  'grand_total',
                  'currency',
                ],
              },
            },
            invoices: {
              url: '/invoices',
              params: {
                subscription_id: 'id',
              },
              data: {
                limit: 5,
                fields: [
                  'number',
                  'date_created',
                  'date_period_start',
                  'date_period_end',
                  'void',
                  'paid',
                  'unpaid',
                  'payment_error',
                  'date_payment_retry',
                  'grand_total',
                  'currency',
                ],
              },
            },
            orders: {
              url: '/orders',
              params: {
                subscription_id: 'id',
              },
              data: {
                limit: 5,
                fields: [
                  'number',
                  'date_created',
                  'date_period_start',
                  'date_period_end',
                  'void',
                  'paid',
                  'unpaid',
                  'payment_error',
                  'date_payment_retry',
                  'grand_total',
                  'currency',
                ],
                expand: 'payments:0',
              },
            },
            invoice_totals: {
              url: '/invoices/:group',
              params: {
                where: {
                  subscription_id: 'id',
                },
              },
              data: {
                payment_total: {
                  $sum: 'payment_total',
                },
                currency: '$currency',
              },
            },
            order_totals: {
              url: '/orders/:group',
              params: {
                where: {
                  subscription_id: 'id',
                },
              },
              data: {
                payment_total: {
                  $sum: 'payment_total',
                },
                currency: '$currency',
              },
            },
          },
        }),
      ).then((result) => {
        if (!result) return result;

        dispatch(
          actions.data.fetchRelated(id, {
            account_cards: getAccountCardsQuery(result.account_id),
            account_addresses: getAccountAddressesQuery(result.account_id),
          }),
        );

        return result;
      });
    },

    fetchAccountAddresses: (relatedId, accountId) => {
      return dispatch(
        actions.data.fetchRelated(relatedId, {
          account_addresses: getAccountAddressesQuery(accountId),
        }),
      );
    },

    fetchAccountCards: (relatedId, accountId) => {
      return dispatch(
        actions.data.fetchRelated(relatedId, {
          account_cards: getAccountCardsQuery(accountId),
          account_addresses: getAccountAddressesQuery(accountId),
        }),
      );
    },

    updateRecord: async (id: string, data: Object) => {
      return dispatch(actions.data.updateRecord('subscriptions', id, data));
    },

    updateFetchRecord: async (id: string, data: Object) => {
      return dispatch(
        actions.data.updateFetchRecord('subscriptions', id, data),
      );
    },

    getItemPrice: (id, item) => {
      return api.get(`/data/subscriptions/${id || 'new'}/items`, {
        $price: item,
      });
    },

    addItem: async (id: string, item: Object) => {
      return dispatch(
        actions.data.createRecord(`subscriptions/${id}/items`, item),
      );
    },

    editItems: async (id: string, items: Array<Object>) => {
      return Promise.all(
        items
          .filter((item) => !!item.removed)
          .map((item) => {
            return dispatch(
              actions.data.deleteRecord(`subscriptions/${id}/items`, item.id),
            );
          }),
      ).then(() => {
        return dispatch(
          actions.data.updateRecord(
            'subscriptions',
            `${id}/items`,
            items
              .filter((item) => !item.removed)
              .map((item) => ({ ...item, removed: undefined })),
          ),
        );
      });
    },

    deleteRecord: async (id: string) => {
      return dispatch(actions.data.deleteRecord('subscriptions', id));
    },

    retryPayment: async (data: Object) => {
      return dispatch(actions.data.createRecord(`payments`, data));
    },

    fetchDiscounts: () => {
      return dispatch(actions.orders.fetchDiscounts());
    },

    loadContentModels() {
      return Promise.all([
        dispatch(actions.content.loadModels('subscriptions')),
        dispatch(actions.content.loadModels('accounts')),
      ]);
    },

    loadSettings() {
      return Promise.all([
        dispatch(actions.settings.fetch('payments')),
        dispatch(actions.settings.fetch('accounts')),
        dispatch(actions.settings.fetch('shipments')),
      ]);
    },

    ...(mapMore ? mapMore(dispatch) : {}),
  });

export class ViewSubscription extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifyDeleted: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      edited: false,
      getItemPrice: this.getItemPrice.bind(this),
      onAddItem: this.onAddItem.bind(this),
      onEditItems: this.onEditItems.bind(this),
      onEditValues: this.onEditValues.bind(this),
      onPaymentEdit: this.onPaymentEdit.bind(this),
      onPaymentRetry: this.onPaymentRetry.bind(this),
      onChangeCurrency: this.onChangeCurrency.bind(this),
      onEdited: this.onEdited.bind(this),
      onDelete: this.onDelete.bind(this),
      onShippingGetRates: this.onShippingGetRates.bind(this),
      onLoadAddresses: this.onLoadAddresses.bind(this),
      onLoadBilling: this.onLoadBilling.bind(this),
    };
  }

  componentDidMount() {
    const {
      params,
      fetchRecord,
      fetchDiscounts,
      loadContentModels,
      loadSettings,
    } = this.props;

    Promise.all([
      fetchDiscounts(),
      fetchRecord(params.id),
      loadContentModels(),
      loadSettings(),
    ]).then(() => {
      this.setState({ loaded: true });
    });
  }

  async componentWillReceiveProps(nextProps) {
    const { params, fetchRecord } = this.props;

    if (params.id !== nextProps.params.id) {
      this.setState({ loaded: false });
      await fetchRecord(nextProps.params.id);
      this.setState({ loaded: true });
    }
  }

  onEdited(edited) {
    this.setState({ edited });
  }

  getItemPrice(item) {
    const { params, getItemPrice } = this.props;
    return getItemPrice(params.id, item);
  }

  onAddItem(values) {
    const { params, addItem, editItems, fetchRecord } = this.props;

    const data = {
      description: values.description,
      recurring: values.recurring,
      product_id: values.product_id,
      variant_id: values.variant_id,
      options: values.options,
      quantity: values.quantity,
      price: values.set_price,
      bundle_items: getCleanBundleItems(values.bundle_items),
    };

    let promise;
    if (values.id) {
      promise = editItems(params.id, [{ id: values.id, ...data }]);
    } else {
      promise = addItem(params.id, data);
    }

    return promise.then((result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        return fetchRecord(params.id);
      }
    });
  }

  onEditItems(items) {
    const { params, editItems, fetchRecord } = this.props;

    return editItems(
      params.id,
      items.map((item) => ({
        id: item.id,
        product: undefined,
        product_id: item.product && item.product.id,
        variant: undefined,
        variant_id: item.variant && item.variant.id,
        price: item.set_price,
        quantity: item.quantity,
        options: item.options,
        removed: item.removed,
        description: item.description,
        recurring: item.recurring,
        bundle_items: getCleanBundleItems(item.bundle_items),
      })),
    ).then(() => {
      return fetchRecord(params.id);
    });
  }

  onEditValues(values, draftId) {
    const { params, updateRecord, fetchRecord } = this.props;

    const subscriptionId = draftId || params.id;

    return updateRecord(subscriptionId, values).then((result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
        return false;
      } else {
        return fetchRecord(subscriptionId);
      }
    });
  }

  async onPaymentEdit(values, draftId) {
    const { params, record, updateRecord, fetchRecord } = this.props;

    const recordId = draftId || params.id;

    const billing = values.billing || {};
    const card = billing.card || {};

    this.setState({ loading: true });

    if (card.number) {
      delete billing.card;

      const cardResult = await tokenizeCard(this.context.client.public_key, {
        card,
        billing,
        account_id: record && record.account_id,
      });

      if (cardResult.errors) {
        const errorMessage = get(
          cardResult,
          'errors.gateway.message',
          JSON.stringify(cardResult.errors),
        );
        this.context.notifyError(`Error: ${errorMessage}`);
        this.setState({ loading: undefined });
        return false;
      }

      billing.card = {
        token: cardResult.token,
      };
    }

    if (
      billing.method &&
      billing.method !== 'card' &&
      get(record, 'billing.card')
    ) {
      billing.card = null;
      billing.account_card_id = null;
    } else if (billing.account_card_id) {
      const accountCardId = billing.account_card_id;

      if (
        accountCardId &&
        accountCardId !== get(record, 'billing.account_card_id')
      ) {
        billing.account_card_id = accountCardId;
      }
    }

    const result = await updateRecord(recordId, values);
    if (result.errors) {
      this.context.notifyError(result.errors);
      this.setState({ loading: undefined });
      return false;
    }

    await fetchRecord(recordId);
    this.setState({ loading: undefined });
    return true;
  }

  async onPaymentRetry(recordId, model, amount) {
    const { params, retryPayment, fetchRecord } = this.props;
    const { notifyError } = this.context;
    const paymentData = {
      method: 'card',
      subscription_id: params.id,
      amount,
    };

    if (model === 'orders') {
      paymentData.order_id = recordId;
    } else {
      paymentData.invoice_id = recordId;
    }

    this.setState({ loading: true });

    const result = await retryPayment(paymentData);

    if (result.errors) {
      notifyError(result.errors);
      this.setState({ loading: undefined });
      return false;
    }

    await fetchRecord(params.id);
    this.setState({ loading: undefined });
    return true;
  }

  async onChangeCurrency(currencyCode, draftId) {
    const { params, fetchRecord, updateRecord } = this.props;
    const { notifyError } = this.context;

    this.setState({ loading: true });

    const result = await updateRecord(draftId || params.id, {
      currency: currencyCode,
    });

    if (result.errors) {
      notifyError(result.errors);
      this.setState({ loading: undefined });
      return false;
    }

    await fetchRecord(draftId || params.id);
    this.setState({ loading: undefined });
    return true;
  }

  onDelete() {
    const { params, router, deleteRecord } = this.props;

    return deleteRecord(params.id).then(() => {
      this.context.notifyDeleted('Subscription');
      router.replace('/subscriptions');
    });
  }

  onShippingGetRates(draftId) {
    const { params, updateFetchRecord } = this.props;

    const recordId = draftId || params.id;

    return updateFetchRecord(recordId, {
      shipment_rating: null,
    });
  }

  async onLoadAddresses() {
    const {
      record: { id, account_id },
      fetchAccountAddresses,
    } = this.props;

    await fetchAccountAddresses(id, account_id);
  }

  async onLoadBilling() {
    const {
      record: { id, account_id },
      fetchAccountCards,
    } = this.props;

    await fetchAccountCards(id, account_id);
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    if (!this.props.record) {
      return <NotFoundPage />;
    }

    return this.props.page ? (
      <this.props.page {...this.props} {...this.state} />
    ) : (
      <ViewPage {...this.props} {...this.state} />
    );
  }
}

export default connect(
  mapStateToProps,
  getMapDispatchToProps(),
)(ViewSubscription);
