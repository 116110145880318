import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  isValueEqual,
  parseNumber,
  numberLocaleInfo,
  formatTableValue,
} from 'utils';

import Label from './label';

function valueToLocaleString(value, decimalSeparator) {
  if (typeof value !== 'number' && !value) {
    return '';
  }

  return parseNumber(value).toString().replace('.', decimalSeparator);
}

export default class InputNumber extends React.PureComponent {
  static propTypes = {
    help: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelRenderer: PropTypes.func,
    rawLabel: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    scale: PropTypes.number,
    maxScale: PropTypes.number,
    selectFocus: PropTypes.bool,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { decimal, group } = numberLocaleInfo();

    this.state = {
      focused: false,
      value: valueToLocaleString(props.value ?? props.defaultValue, decimal),
      propsValue: props.value,
      propsDefaultValue: valueToLocaleString(props.defaultValue, decimal),
      decimalSeparator: decimal,
      thousandsSeparator: group,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.focused) {
      const nextDefaultValue = valueToLocaleString(
        props.value ?? props.defaultValue,
        state.decimalSeparator,
      );

      if (props.value !== state.propsValue) {
        return { value: nextDefaultValue, propsValue: props.value };
      }

      if (
        nextDefaultValue !== state.value &&
        !isValueEqual(nextDefaultValue, state.propsDefaultValue)
      ) {
        return { value: nextDefaultValue, propsDefaultValue: nextDefaultValue };
      }
    }

    return null;
  }

  onFocus = (_event) => {
    if (this.props.selectFocus) {
      this.refs.input.select();
    }

    this.setState({ focused: true });
  };

  onBlur = (_event) => {
    this.setState({ focused: false });
  };

  /** @param {string} value */
  formatChangeScale(value) {
    const { scale, maxScale } = this.props;

    const scaleMax = maxScale !== undefined ? maxScale : scale;

    if (scaleMax === undefined) {
      return value;
    }

    const { decimalSeparator } = this.state;

    const decimalIndex = value.indexOf(decimalSeparator);

    if (decimalIndex >= 0) {
      if (scaleMax === 0) {
        return parseNumber(value).toFixed(0);
      }

      const decimalParts = value.trim().split(decimalSeparator);

      if (decimalParts.length > 2) {
        value = value.substring(0, value.lastIndexOf(decimalSeparator));
      }

      if (decimalParts[1].length > scaleMax) {
        value = parseNumber(value).toFixed(scaleMax + 1);

        value = value
          .substring(0, value.length - 1)
          .replace('.', decimalSeparator);
      }
    }

    return value;
  }

  onChange = (event) => {
    const { minValue, maxValue, onChange } = this.props;
    const { decimalSeparator, thousandsSeparator } = this.state;

    let value = String(event.target.value)
      .replace(new RegExp(`\\${thousandsSeparator}`, 'g'), '')
      .replace(new RegExp(`\\${decimalSeparator}+`, 'g'), decimalSeparator);

    value = this.formatChangeScale(value);

    const testValue = parseNumber(value);

    if (Number.isNaN(testValue)) {
      return;
    }

    if (thousandsSeparator.replace(/\s/, '') !== '') {
      value = value.trim();
    }

    if (maxValue !== undefined && testValue > maxValue) {
      value = String(maxValue);
    } else if (minValue !== undefined && testValue < minValue && value !== '') {
      value = String(minValue);
    }

    this.setState({ value });

    onChange(event, value ? parseNumber(value) : '');
  };

  value() {
    const { value } = this.state;

    return value ? parseNumber(value) : null;
  }

  focus() {
    this.refs.input.focus();
  }

  render() {
    const {
      help,
      label,
      rawLabel,
      labelRenderer,
      selectFocus,
      minValue,
      maxValue,
      scale,
      maxScale,
      readonlyContent,
      ...props
    } = this.props;

    return (
      <span className="form-number">
        {label && <Label label={label} help={help} htmlFor={props.id} />}

        {readonlyContent ? (
          <div className="form-field-readonly">
            {formatTableValue(this.state.value)}
          </div>
        ) : (
          <span className="form-field-input">
            <input
              {...props}
              ref="input"
              type="text"
              value={this.state.value}
              defaultValue={undefined}
              className={classNames(props.className, {
                disabled: Boolean(props.disabled),
              })}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
          </span>
        )}
      </span>
    );
  }
}
