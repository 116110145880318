import axios from 'axios';

import api from 'services/api';
import auth from 'services/auth';

export const SESSION_FETCH = 'session/fetch';
export const SESSION_UPDATE = 'session/update';
export const SESSION_SEND_CLI_AUTH = 'session/sendCliAuth';

export default {
  fetch() {
    return {
      type: SESSION_FETCH,
      payload: api.get('/session').then((session) => {
        auth.setSession(session);
        return session;
      }),
    };
  },

  update(data) {
    return {
      type: SESSION_UPDATE,
      payload: api.put('/session', data).then((session) => {
        auth.setSession(session);
        return session;
      }),
    };
  },

  superAccess(mode = true) {
    return {
      type: SESSION_UPDATE,
      payload: api.put('/session/super-access', { mode }).then((session) => {
        auth.setSession(session);
        return session;
      }),
    };
  },

  sendCliAuth(cliBaseUrl, storeId) {
    return async (dispatch) => {
      await axios(cliBaseUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
          'x-session': auth.getSession(),
          'x-store': storeId,
        },
      }).then((response) => response.data);

      return dispatch({
        type: SESSION_SEND_CLI_AUTH,
        payload: null,
      });
    };
  },
};

export const initialState = {};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SESSION_FETCH:
    case SESSION_UPDATE:
      return action.payload;

    default:
      return state;
  }
}
