import React from 'react';
import PropTypes from 'prop-types';
import fileDownload from 'react-file-download';

import { formatDate, hasMultipleCurrencies, hasMultipleLocales } from 'utils';
import { isSearching } from 'utils/collection';

import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import ButtonLink from 'components/button/link';
import BulkProgress from 'components/bulk/progress';
import { FadeIn } from 'components/transitions';

export default class BulkExport extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  static propTypes = {
    data: PropTypes.object,
    bulk: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    selectable: PropTypes.bool,
    exportLabels: PropTypes.array,
    exportOptions: PropTypes.array,
    onClickExport: PropTypes.func.isRequired,
    onClickExportCancel: PropTypes.func.isRequired,
    onClickExportReset: PropTypes.func.isRequired,
    onSubmitExport: PropTypes.func.isRequired,
    showCurrency: PropTypes.bool,
    showLocale: PropTypes.bool,
  };

  state = {};
  exportLabels = {};
  exportOptions = [];
  formatOptions = [
    { value: 'csv', label: 'CSV' },
    { value: 'json', label: 'JSON' },
  ];

  constructor(props) {
    super(props);

    this.state = {
      completedTime: null,
      defaultType: this.getDefaultType(props),
    };

    this.exportLabels = props.exportLabels || {
      page: 'Current page',
      search: 'Current search',
      all: `All ${props.label.toLowerCase()}`,
      selected: `Selected ${props.label.toLowerCase()}`,
    };

    if (props.exportOptions) {
      this.exportOptions = props.exportOptions;
    } else {
      this.exportOptions = [{ value: 'all', label: this.exportLabels.all }];
      if (props.data && isSearching(props.router, props.data)) {
        this.exportOptions.push({
          value: 'search',
          label: this.exportLabels.search,
        });
      }
      this.exportOptions.push({ value: 'page', label: this.exportLabels.page });
    }

    if (props.data && props.selectable && props.data.selection.count > 0) {
      this.exportOptions.push({
        value: 'selected',
        label: this.exportLabels.selected,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      bulk: {
        complete,
        result: { data, filename, mimetype },
      },
    } = this.props;

    if (complete && !prevProps.bulk.complete) {
      this.setState({ completedTime: Date.now() });
      fileDownload(data, filename, mimetype);
    }
  }

  getDefaultType(props) {
    if (props.data) {
      if (props.data.selection.count > 0) {
        return 'selected';
      }
      if (isSearching(props.router, props.data)) {
        return 'search';
      }
    }
    return 'all';
  }

  onClickDownload = (event) => {
    event.preventDefault();
    const {
      bulk: {
        result: { data, filename, mimetype },
      },
    } = this.props;
    fileDownload(data, filename, mimetype);
  };

  onClose = (event) => {
    const {
      bulk: { running },
    } = this.props;

    if (running) {
      event.preventDefault();

      this.context.openModal('Confirm', {
        title: 'Cancel export',
        message: 'Are you sure you want to cancel this export?',
        action: 'Confirm',
        actionType: 'danger',
        onConfirm: () => {
          this.props.onClickExportCancel(event);
          this.context.closeModal();
        },
      });
    } else {
      this.props.onClickExportReset(event);
      this.props.onClickExport(event);
    }
  };

  render() {
    const {
      label,
      bulk: { running, complete, percent, params },
      onSubmitExport,
      onClickExportReset,
    } = this.props;
    const { client } = this.context;

    const hasExportOptions = !!(
      this.exportOptions && this.exportOptions.length > 0
    );

    const showCurrencyCheckbox =
      this.props.showCurrency && hasMultipleCurrencies(client);
    const showLocaleCheckbox =
      this.props.showLocale && hasMultipleLocales(client);

    return (
      <Form onSubmit={onSubmitExport} autoFocus={true}>
        <Modal
          title={`Export ${label.toLowerCase()}`}
          actions={[
            !running && !complete ? { label: 'Export', type: 'submit' } : null,
            !complete
              ? { label: 'Cancel', type: 'cancel', onClick: this.onClose }
              : null,
            complete
              ? { label: 'Close', type: 'default', onClick: this.onClose }
              : null,
            complete
              ? { label: 'Back', type: 'cancel', onClick: onClickExportReset }
              : null,
          ]}
          width={475}
          cancel={false}
          onClose={this.onClose}
        >
          <fieldset>
            {running ? (
              <BulkProgress percent={percent} />
            ) : (
              complete && (
                <FadeIn
                  key="complete"
                  active={true}
                  transitionAppear={true}
                  className="bulk-complete"
                >
                  <div className="note">
                    Downloaded <strong>{this.exportLabels[params.type]}</strong>{' '}
                    in <strong>{params.format.toUpperCase()}</strong> at{' '}
                    {formatDate(this.state.completedTime, 'time')}
                  </div>

                  <ButtonLink
                    onClick={this.onClickDownload}
                    type="secondary"
                    size="sm"
                  >
                    Download again
                  </ButtonLink>
                </FadeIn>
              )
            )}

            <div
              style={running || complete ? { display: 'none' } : {}}
              className="row"
            >
              {hasExportOptions && (
                <Field
                  type="radio"
                  label="Export"
                  name="type"
                  defaultValue={this.state.defaultType}
                  options={this.exportOptions}
                  stacked={true}
                  required={true}
                  className="span2"
                />
              )}

              <Field
                type="radio"
                label="Format"
                name="format"
                options={this.formatOptions}
                stacked={hasExportOptions}
                required={true}
                className={hasExportOptions ? 'span2' : 'span4'}
              />
            </div>
            <div
              style={running || complete ? { display: 'none' } : {}}
              className="column"
            >
              {showCurrencyCheckbox && (
                <Field
                  type="checkbox"
                  label="Export all currencies"
                  name="allCurrencies"
                  className={showLocaleCheckbox ? 'form-field-no-margin' : ''}
                />
              )}
              {showLocaleCheckbox && (
                <Field
                  type="checkbox"
                  label="Export all locales"
                  name="allLocales"
                />
              )}
            </div>
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
