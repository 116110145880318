import React, { Fragment } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import pt from 'prop-types';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import { isValueEqual, classNames, formatTableValue } from 'utils';

import Icon from 'components/icon';
import Checkbox from 'components/icon/checkbox';
import Tooltip from 'components/tooltip';
import { FadeIn } from 'components/transitions';
import DateTime from 'components/date-time/date-time';

import validation from './validation';
import Label from './label';
import InputTags from './input-tags';
import InputFile from './input-file';
import InputImages from './input-images';
import InputEditor from './input-editor';
import InputJsonEditor from './input-json-editor';
import InputFinder from './input-finder';
import InputLookup from './input-lookup';
import InputSelect from './input-select';
import InputMultiselect from './input-multiselect';
import InputCurrency from './input-currency';
import InputStripeCard from './input-stripe-card';
import InputIDeal from './input-ideal';
import InputCardNumber from './input-card-number';
import InputCardExp from './input-card-exp';
import InputNumber from './input-number';
import InputSlider from './input-slider';
import InputDate from './input-date';
import InputTime from './input-time';
import InputAddress from './input-address';
import InputColor from './input-color';

import './form.scss';

function getChecked(props) {
  const checked =
    props.checked !== undefined ? props.checked : props.defaultChecked;

  switch (props.type) {
    case 'checkbox':
    case 'radio':
    case 'toggle': {
      if (props.options) {
        return undefined;
      }

      return Boolean(checked);
    }

    default:
      return undefined;
  }
}

function getInitialValue(props) {
  const value = props.value !== undefined ? props.value : props.defaultValue;

  if (typeof value === 'function') {
    return '';
  }

  switch (props.type) {
    case 'checkbox':
    case 'radio':
    case 'toggle': {
      if (props.defaultValue !== undefined && !props.options) {
        throw new Error(
          'Error: defaultValue was passed to a checkable component, expected defaultChecked',
        );
      }

      if (props.defaultValue === null && props.nullable) {
        return null;
      }

      if (props.options) {
        let optionValues = Array.isArray(value) ? value : [value];

        optionValues = filter(props.options, (op) =>
          optionValues.includes(
            op.value !== undefined ? op.value : op.id || op,
          ),
        ).map((val) =>
          // prioritize val.value, val.id, then val
          val.value !== undefined
            ? val.value
            : val.id !== undefined
            ? val.id
            : val,
        );

        if (props.type === 'checkbox') {
          return optionValues;
        }

        return optionValues[0];
      }

      if (getChecked(props)) {
        return value || true;
      }

      return props.nonValue !== undefined ? props.nonValue : '';
    }

    case 'tags': {
      if (value !== undefined && value !== null) {
        return value;
      }

      return [];
    }

    default: {
      if (value !== undefined && value !== null) {
        return value;
      }

      return '';
    }
  }
}

function makePrevProps(props) {
  return {
    hint: props.hint,
    valid: props.valid,
    error: props.error,
    warning: props.warning,
    disabled: props.disabled,
    value: props.value,
    defaultValue: props.defaultValue,
    checked: props.checked,
    defaultChecked: props.defaultChecked,
  };
}

export default class Field extends React.PureComponent {
  static propTypes = {
    id: pt.oneOfType([pt.number, pt.string]),
    name: pt.string,
    type: pt.string,
    hint: pt.oneOfType([pt.string, pt.node]),
    hintOnFocus: pt.oneOfType([pt.string, pt.node]),
    label: pt.oneOfType([pt.string, pt.node]),
    context: pt.object,
    path: pt.string,
    required: pt.bool,
    disabled: pt.bool,
    inactive: pt.bool,
    large: pt.bool,
    error: pt.any,
    warning: pt.any,
    value: pt.any,
    defaultValue: pt.any,
    nonValue: pt.any,
    checked: pt.bool,
    defaultChecked: pt.bool,
    className: pt.string,
    options: pt.oneOfType([pt.array, pt.object]),
    rules: pt.oneOfType([pt.array, pt.string]),
    valid: pt.bool,
    validateIcon: pt.bool,
    validateBlur: pt.bool,
    locked: pt.bool,
    selectFocus: pt.bool,
    debounce: pt.oneOfType([pt.bool, pt.number]),
    editableTags: pt.bool,
    clearIcon: pt.bool,

    onChangeLock: pt.func,
    onPreChange: pt.func,
    onChange: pt.func,
    onClick: pt.func,
  };

  static contextTypes = {
    formValues: pt.oneOfType([pt.object, pt.array]),
    registerField: pt.func,
    unregisterField: pt.func,
    onChangeField: pt.func,
    openModal: pt.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      skipDebounce: false,
      value: getInitialValue(props),
      error:
        props.error && props.error.message ? props.error.message : props.error,
      warning:
        props.warning && props.warning.message
          ? props.warning.message
          : props.warning,
      valid: props.valid !== undefined ? props.valid : false,
      hint: props.hint,
      hintOnFocus: props.hintOnFocus,
      checked: getChecked(props),
      disabled: props.disabled,
      locked: props.locked,
      focus: false,
      prev: makePrevProps(props),
    };

    this.debounceTimeout = null;
    this.inputRef = React.createRef();

    this.nextEvent = null;
    this.unmounted = false;
  }

  componentWillUnmount() {
    this.unmounted = true;

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;
    }

    if (this.context.unregisterField) {
      this.context.unregisterField(this);
    }
  }

  componentDidMount() {
    if (this.context.registerField) {
      this.context.registerField(this, this.onChangeForm);
    }

    const input = this.getInputRef();
    // Manually trigger change on input
    if (this.props.onChange && input) {
      if (input.dispatchEvent) {
        const event = new Event('change', { bubbles: true });
        input.dispatchEvent(event);
        this.props.onChange(event, this.state.value, this);
      }
    }

    // Set default option value
    if (this.props.options && this.state.value === undefined) {
      let defaultValue;
      if (this.props.type === 'checkbox') {
        defaultValue = [];
      } else {
        defaultValue = get(this.props, 'options[0].value');
        if (defaultValue === undefined) defaultValue = this.props.options[0];
      }
      this.setState({ value: defaultValue }, () => this.onChangeField());
    } else {
      this.onChangeField();
    }

    // Focus states
    if (this.props.hintOnFocus && this.state.hintOnFocus !== undefined) {
      input.onfocus = () => {
        this.setState({ focus: true });
      };

      input.onblur = () => {
        this.setState({ focus: false });
      };
    }
  }

  static getDerivedStateFromProps(props, state) {
    let obj = null;

    const prevState = state.prev;

    const error = props.error?.message ?? props.error;

    if (error !== prevState.error) {
      obj = Object.assign(obj ?? {}, { error });
    } else {
      const warning = props.warning?.message ?? props.warning;

      if (warning !== prevState.warning) {
        obj = Object.assign(obj ?? {}, { warning });
      }
    }

    if (props.valid !== undefined && props.valid !== prevState.valid) {
      obj = Object.assign(obj ?? {}, { valid: props.valid });
    }

    if (props.hint !== prevState.hint) {
      obj = Object.assign(obj ?? {}, { hint: props.hint });
    }

    if (props.disabled !== prevState.disabled) {
      obj = Object.assign(obj ?? {}, { disabled: props.disabled });
    }

    if (state.checked !== undefined) {
      const thisChecked = Boolean(prevState.checked);
      const nextChecked = Boolean(props.checked);
      const thisDefaultChecked = Boolean(prevState.defaultChecked);
      const nextDefaultChecked = Boolean(props.defaultChecked);

      if (
        nextChecked !== thisChecked ||
        nextDefaultChecked !== thisDefaultChecked
      ) {
        obj = Object.assign(obj ?? {}, {
          skipDebounce: true,
          checked: getChecked(props),
          value: getInitialValue(props),
        });
      }
    } else {
      // Use isValueEqual to avoid change with array/object values
      if (
        !isValueEqual(props.value, prevState.value) ||
        !isValueEqual(props.defaultValue, prevState.defaultValue) ||
        (props.type === 'hidden' && props.value !== prevState.value)
      ) {
        obj = Object.assign(obj ?? {}, {
          skipDebounce: true,
          value: getInitialValue(props),
        });
      }
    }

    if (obj !== null) {
      obj.prev = makePrevProps(props);
    }

    return obj;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.value !== prevState.value ||
      this.state.checked !== prevState.checked
    ) {
      const { debounce } = this.props;

      const event = this.nextEvent;
      this.nextEvent = null;

      if (!this.state.skipDebounce && debounce) {
        if (this.debounceTimeout) {
          clearTimeout(this.debounceTimeout);
        }

        this.debounceTimeout = setTimeout(
          () => {
            this.debounceTimeout = null;
            this.onChangeField();
            this.onChange(event);
          },
          isNumber(debounce) ? debounce : 700,
        );
      } else {
        if (this.state.skipDebounce) {
          this.setState({ skipDebounce: false });
        }

        this.onChangeField();
        this.onChange(event);
      }
    }
  }

  setTextareaAutosizeInputRef = (element) => {
    this.inputRef.current = element?.textarea;
  };

  getInputRef() {
    let input = this.inputRef.current;

    while (input) {
      if (typeof input.inputRef?.current?.focus === 'function') {
        input = input.inputRef.current;
      } else if (typeof input.refs?.input?.focus === 'function') {
        input = input.refs.input;
      } else {
        break;
      }
    }

    return input;
  }

  onFocus = () => {
    if (this.props.selectFocus) {
      const input = this.getInputRef();

      if (input) {
        input.select();
      }
    }
  };

  onChangeField() {
    if (this.context.onChangeField) {
      this.context.onChangeField(this);
    }
  }

  isToggle = (props = this.props) => {
    const { type, options } = props;
    return Boolean(
      !options &&
        (type === 'checkbox' || type === 'radio' || type === 'toggle'),
    );
  };

  onChangeInput = (event, value) => {
    const { type, options } = this.props;

    let nextValue;

    if (this.isToggle()) {
      const nextChecked = !this.state.checked;
      nextValue = nextChecked
        ? this.props.value || value || true
        : this.props.nonValue !== undefined
        ? this.props.nonValue
        : '';

      if (
        this.props.onPreChange &&
        this.props.onPreChange(event, nextValue, this) === false
      ) {
        return;
      }

      this.setState({ checked: nextChecked });
    } else {
      nextValue = value !== undefined ? value : event.target.value || '';
    }

    // Keep synthetic event around if possible
    event.persist && event.persist();

    if (this.state.value !== nextValue) {
      this.nextEvent = event;
    }

    this.setState({ value: nextValue });
  };

  onChange(nextEvent) {
    const { onChange } = this.props;
    if (onChange) {
      let event = nextEvent;
      if (!event) {
        event = new Event('change', { bubbles: true });
        const input = this.getInputRef();
        if (input?.dispatchEvent) {
          input.dispatchEvent(event);
        }
      }
      const { value } = this.state;
      const result = onChange(event, value, this);
      if (result === false) {
        this.setState(
          {
            value,
            ...(this.isToggle() ? { checked: !this.state.checked } : {}),
          },
          () => this.onChangeField(),
        );
        return false;
      }
    }
  }

  value() {
    if (typeof this.inputRef.current?.value === 'function') {
      return this.inputRef.current.value();
    }

    const { value } = this.state;

    // Special cases for fields with no own components
    // @see renderInput method
    switch (this.props.type) {
      case 'checkbox': {
        // If the checkbox has options, then it returns
        // an array of the selected options (instead of a boolean).
        // If value is passed, then it should be returned instead of boolean
        if (
          !this.props.options &&
          typeof this.props.value === 'undefined' &&
          typeof this.props.defaultValue === 'undefined'
        ) {
          return Boolean(value);
        }

        break;
      }

      case 'toggle':
        // If value is passed, then it should be returned instead of boolean
        return typeof this.props.value === 'undefined' &&
          typeof this.props.defaultValue === 'undefined'
          ? Boolean(value)
          : value;

      default:
        break;
    }

    return value;
  }

  focus() {
    if (this.state.autoFocusMounted) {
      this.setState({ autoFocusMounted: false });
    } else {
      const input = this.getInputRef();
      if (typeof input?.focus === 'function') {
        input.focus();
      }
    }
  }

  getOptionValue(index) {
    const op = this.props.options[index];
    return get(op, 'value', get(op, 'id', op));
  }

  getOptionLabel(index) {
    const op = this.props.options[index];
    return get(op, 'label', get(op, 'name', op));
  }

  onClickCheckboxRadio = (event) => {
    event.preventDefault();
    const { locked, options, type, onClick, inactive } = this.props;
    const { locked: lockedState, disabled } = this.state;
    if (disabled || (locked && lockedState) || inactive) {
      return;
    }
    if (onClick) {
      onClick(event);
    }
    const inputEvent = new Event('change', { bubbles: true });
    const input = this.getInputRef();
    if (options) {
      const { index } = event.currentTarget.dataset;
      let value = this.getOptionValue(index);
      if (type === 'checkbox') {
        const checkedValue = value;
        value = [...(this.state.value || [])];
        const index = value.indexOf(checkedValue);
        if (index >= 0) {
          value.splice(index, 1);
        } else {
          value.push(checkedValue);
        }
      }
      this.setState({ value });
      input.dispatchEvent(inputEvent);
      this.onChangeInput(inputEvent, value);
    } else {
      input.dispatchEvent(inputEvent);
      this.onChangeInput(inputEvent);
    }
  };

  onClickLock = (event) => {
    event.preventDefault();
    if (this.state.locked) {
      this.context.openModal('Confirm', {
        title: `Unlock ${this.props.label || 'this field'}`,
        message: (
          <p>
            Warning: editing this field may cause problems for applications
            <br /> referencing it until those applications are updated
          </p>
        ),
        actionType: 'danger',
        onConfirm: () => {
          this.setState({ locked: false });
          const input = this.getInputRef();
          input.select();
          if (this.props.onChangeLock) {
            this.props.onChangeLock(false);
          }
        },
      });
    } else {
      this.setState({ locked: true });
      if (this.props.onChangeLock) {
        this.props.onChangeLock(true);
      }
    }
  };

  onValidateBlur = () => {
    const value = this.value();
    if (value !== null && value !== undefined && value !== '') {
      this.validate();
    } else {
      this.setState({
        valid: false,
        error: null,
        warning: null,
      });
    }
  };

  validate() {
    const {
      type,
      rules,
      required,
      error: errorProp,
      warning: warningProp,
    } = this.props;

    if (this.state.disabled) {
      return true;
    }

    if (errorProp || warningProp) {
      return false;
    }

    let isValid = true;
    const value = this.value();

    const validationRules = Array.isArray(rules) ? rules : [];

    if (typeof rules === 'string') {
      validationRules.push(...rules.split(',').map((rule) => rule.trim()));
    }

    if (required) {
      validationRules.push('required');
    }

    switch (type) {
      case 'date':
        validationRules.push('date');
        break;

      case 'time':
        validationRules.push('time');
        break;

      default:
        break;
    }

    for (const ruleName of validationRules) {
      let validator;

      try {
        switch (typeof ruleName) {
          case 'string':
            validator = validation.rules[ruleName];
            break;

          case 'object':
            validator = ruleName;
            break;

          default:
            throw new Error(`Unexpected rule type "${typeof ruleName}"`);
        }
      } catch (e) {
        throw new Error(`Validation rule '${ruleName}' is not defined`);
      }

      const result = validator.rule(value, this.context.formValues);

      if (!result || typeof result === 'string') {
        isValid = false;

        this.setState({
          error: validator.warn ? undefined : result || validator.hint(),
          warning: validator.warn ? result || validator.hint() : undefined,
          valid: false,
        });
      }
    }

    const input = this.getInputRef();

    if (input && isValid && input.validate) {
      isValid = input.validate();
      if (!isValid) {
        this.setState({
          error: input.state.error || 'Invalid',
          valid: false,
        });
      }
    }

    if ((this.state.error || this.state.warning) && isValid) {
      this.setState({
        error: null,
        warning: null,
        valid: true,
      });
    } else if (isValid) {
      this.setState({ valid: true });
    }

    return isValid;
  }

  disabled(isDisabled) {
    this.setState({ disabled: this.props.disabled ? true : isDisabled });
  }

  normalizeProps() {
    const { name, path, error, warning, hint, ...attrs } = this.props;

    if (!attrs.id && (path || name)) {
      attrs.id = path || `form_field_${name || path}`;

      const dataIndex = attrs['data-index'];

      if (typeof dataIndex === 'number') {
        attrs.id = `${attrs.id}.${dataIndex}`;
      }
    }

    return {
      ...attrs,
      name: name || path,
    };
  }

  renderInput(props) {
    const {
      // avoid passing these on
      inactive,
      debounce,
      onPreChange,
      ...inputProps
    } = props;

    const {
      label,
      labelRenderer,
      rawLabel,
      labelOff,
      labelClass,
      error,
      warning,
      hint,
      help,
      options,
      defaultValue,
      stacked,
      buttons,
      buttonsWithIcons,
      buttonsWithCheck,
      selectFocus,
      autoSize,
      nonValue,
      nullable,
      maxValue,
      valid,
      display,
      svgIcon,
      readonly,
      readonlyContent,
      editableTags,
      clearIcon,
      ...attrs
    } = inputProps;

    switch (attrs.type) {
      case 'finder':
        return <InputFinder {...inputProps} />;
      case 'lookup':
        return <InputLookup {...inputProps} />;
      case 'editor':
        return <InputEditor {...inputProps} />;
      case 'json-editor': {
        return <InputJsonEditor {...inputProps} />;
      }
      case 'tags':
        return <InputTags {...inputProps} editableTags={editableTags} />;
      case 'file':
        return <InputFile {...inputProps} />;
      case 'files':
        return <InputFile multiple={true} {...inputProps} />;
      case 'image':
        return <InputImages single={true} {...inputProps} />;
      case 'images':
        return <InputImages {...inputProps} />;
      case 'select':
        return <InputSelect {...inputProps} />;
      case 'address':
        return <InputAddress {...inputProps} />;
      case 'multiselect':
        return <InputMultiselect {...inputProps} />;
      case 'currency':
        return <InputCurrency {...inputProps} />;
      case 'stripe-card':
        return <InputStripeCard {...inputProps} />;
      case 'stripe-ideal':
        return <InputIDeal {...inputProps} />;
      case 'card-number':
        return <InputCardNumber {...inputProps} />;
      case 'card-exp':
        return <InputCardExp {...inputProps} />;
      case 'number':
        return <InputNumber {...inputProps} />;
      case 'slider':
        return <InputSlider {...inputProps} />;
      case 'date':
        if (readonlyContent) {
          return this.renderReadOnlyContent(props);
        }
        return <InputDate {...inputProps} />;
      case 'datetime':
        return <InputDate {...inputProps} time={true} />;
      case 'time':
        return <InputTime {...inputProps} />;
      case 'color':
        return <InputColor {...inputProps} />;
      case 'checkbox': {
        if (readonlyContent) {
          return this.renderReadOnlyContent(props);
        }
        return (
          <div
            className={classNames('form-checkbox', {
              stacked: Boolean(stacked),
              disabled: Boolean(attrs.disabled),
              'checkbox-display-button': display === 'button',
              'is-checked': Boolean(this.state.checked),
            })}
          >
            {Boolean(options) && <Label label={label} help={help} />}

            {options ? (
              options.map((op, index) => {
                let value = this.state.value || [];

                if (typeof value.includes !== 'function') {
                  value = [];
                }

                const opValue = op
                  ? op.value !== undefined
                    ? op.value
                    : op.id || op
                  : op;

                const opLabel = op
                  ? op.label !== undefined
                    ? op.label
                    : op.name || op
                  : op;

                const checked = value.includes(opValue);

                return (
                  <button
                    key={index}
                    role="checkbox"
                    data-index={index}
                    aria-checked={checked}
                    className="form-field-input"
                    onClick={this.onClickCheckboxRadio}
                    type="button"
                  >
                    <label>
                      <Checkbox
                        checked={checked}
                        className="form-checkbox-button"
                      />

                      <input
                        {...attrs}
                        aria-hidden
                        id={`${attrs.id}_${opValue}`}
                        tabIndex={-1}
                        checked={checked}
                        value={this.state.value}
                        defaultValue={undefined}
                        defaultChecked={undefined}
                        className={classNames(
                          'form-checkbox-input',
                          attrs.className,
                          {
                            disabled: Boolean(attrs.disabled),
                          },
                        )}
                      />

                      {opLabel}
                    </label>
                  </button>
                );
              })
            ) : (
              <button
                role="checkbox"
                aria-checked={Boolean(this.state.checked)}
                onClick={this.onClickCheckboxRadio}
                type="button"
              >
                <span className="form-field-input">
                  <Label help={help}>
                    <Checkbox
                      checked={this.state.checked}
                      className="form-checkbox-button"
                    />

                    {svgIcon ? (
                      <Icon
                        svgType={svgIcon.src}
                        width={svgIcon.width}
                        height={svgIcon.height}
                      />
                    ) : null}

                    <input
                      {...attrs}
                      aria-hidden
                      tabIndex={-1}
                      value={this.state.value}
                      checked={this.state.checked}
                      defaultValue={undefined}
                      defaultChecked={undefined}
                      className={classNames(
                        'form-checkbox-input',
                        attrs.className,
                        {
                          disabled: Boolean(attrs.disabled),
                        },
                      )}
                    />

                    {label}
                  </Label>
                </span>
              </button>
            )}
          </div>
        );
      }

      case 'radio': {
        if (readonlyContent) {
          return this.renderReadOnlyContent(props);
        }
        return (
          <div
            className={classNames('form-radio', {
              stacked: Boolean(stacked),
              buttons: Boolean(buttons || buttonsWithIcons),
              'buttons-with-icons': Boolean(buttonsWithIcons),
              'buttons-with-check': Boolean(buttonsWithCheck),
              disabled: Boolean(attrs.disabled),
              'form-radio-display': Boolean(display),
            })}
          >
            {options && <Label label={label} help={help} />}

            <div
              className={classNames('form-radio-wrapper', {
                'radio-columns': display === 'column',
                'radio-rows': display === 'row',
              })}
            >
              {options ? (
                options.map((op, index) => {
                  const opValue = op
                    ? op.value !== undefined
                      ? op.value
                      : op
                    : op;

                  const opLabel = op
                    ? op.label !== undefined
                      ? op.label
                      : op
                    : op;

                  const opIcon = op
                    ? op.icon !== undefined
                      ? op.icon
                      : op
                    : op;

                  const isChecked = this.state.value === opValue;

                  return (
                    op &&
                    opValue !== 'hidden_option' && (
                      <button
                        key={index}
                        data-index={index}
                        role="radio"
                        aria-checked={isChecked}
                        className={classNames('form-field-input', {
                          checked: isChecked,
                        })}
                        onClick={this.onClickCheckboxRadio}
                        type="button"
                      >
                        <label>
                          <span
                            className={classNames('form-radio-button', {
                              checked: isChecked,
                            })}
                          />

                          <input
                            {...attrs}
                            aria-hidden
                            id={`${attrs.id}_${opValue}`}
                            tabIndex={-1}
                            checked={isChecked}
                            value={
                              this.state.value === null ? '' : this.state.value
                            }
                            defaultValue={undefined}
                            defaultChecked={undefined}
                            className={classNames(
                              'form-radio-input',
                              attrs.className,
                              {
                                disabled: Boolean(attrs.disabled),
                              },
                            )}
                          />

                          <Icon
                            svgType={opIcon.src}
                            width={opIcon.width}
                            height={opIcon.height}
                          />

                          <span className="label">{opLabel}</span>

                          {isChecked && display === 'row' && (
                            <FadeIn
                              key="icon-valid"
                              className="form-validate-icon"
                            >
                              <Icon
                                svgType="field-valid"
                                width="28px"
                                height="28px"
                              />
                            </FadeIn>
                          )}
                        </label>
                      </button>
                    )
                  );
                })
              ) : (
                <button
                  role="radio"
                  aria-checked={Boolean(this.state.checked)}
                  className="form-field-input"
                  onClick={this.onClickCheckboxRadio}
                  type="button"
                >
                  <Label help={help}>
                    <span
                      className={classNames('form-radio-button', {
                        checked: Boolean(this.state.checked),
                      })}
                    />

                    <input
                      {...attrs}
                      aria-hidden
                      tabIndex={-1}
                      value={this.state.value}
                      checked={this.state.checked}
                      defaultValue={undefined}
                      defaultChecked={undefined}
                      className={classNames(
                        'form-radio-input',
                        attrs.className,
                        {
                          disabled: Boolean(attrs.disabled),
                        },
                      )}
                    />

                    {label}
                  </Label>
                </button>
              )}
            </div>
          </div>
        );
      }

      case 'toggle': {
        if (readonlyContent) {
          return this.renderReadOnlyContent(props);
        }

        const labelTrue = label || 'Enabled';

        const labelFalse =
          labelOff !== undefined ? labelOff : label ? label : 'Disabled';

        const disabled = Boolean(attrs.disabled);
        const checked = Boolean(this.state.checked);

        return (
          <div
            className={classNames('form-toggle', {
              disabled,
              checked,
            })}
          >
            <div className="form-field-input">
              <button
                role="checkbox"
                aria-checked={checked}
                aria-labelledby={attrs.id}
                className="form-toggle-button"
                onClick={this.onClickCheckboxRadio}
                type="button"
              >
                <span className="form-toggle-handle" />
              </button>

              <input
                {...attrs}
                aria-hidden
                type="checkbox"
                tabIndex={-1}
                checked={checked}
                value={this.state.value}
                defaultValue={undefined}
                defaultChecked={undefined}
                className={classNames('form-toggle-input', attrs.className, {
                  disabled,
                })}
              />
            </div>

            <Label
              label={
                label === false ? undefined : checked ? labelTrue : labelFalse
              }
              help={help}
              htmlFor={attrs.id}
              onClick={this.onClickCheckboxRadio}
            />
          </div>
        );
      }

      case 'textarea': {
        if (readonlyContent) {
          return this.renderReadOnlyContent(props);
        }

        const { ref, ...attrsWithoutRef } = attrs;

        return (
          <Fragment>
            {label && (
              <Label
                className={classNames({
                  'form-field-label':
                    this.props.type === 'textarea' &&
                    this.props.className === 'span4 ',
                })}
                label={label}
                help={help}
                htmlFor={attrs.id}
              />
            )}

            {autoSize ? (
              <TextareaAutosize
                ref={this.setTextareaAutosizeInputRef}
                {...attrsWithoutRef}
                async
                className={classNames(attrs.className, {
                  disabled: Boolean(attrs.disabled),
                })}
              />
            ) : (
              <textarea
                ref={ref}
                {...attrsWithoutRef}
                className={classNames(attrs.className, {
                  disabled: Boolean(attrs.disabled),
                })}
              />
            )}
          </Fragment>
        );
      }

      default: {
        if (readonlyContent) {
          return this.renderReadOnlyContent(props);
        }

        if (attrs.type === 'code') {
          attrs.type = 'text';
          attrs.className = `form-field-code ${attrs.className || ''}`;
        }

        return (
          <span>
            {label && (
              <Label
                label={label}
                help={help}
                htmlFor={attrs.id}
                className={labelClass}
              />
            )}

            <input
              {...attrs}
              value={attrs.value !== null ? attrs.value : ''}
              className={classNames(attrs.className, {
                disabled: Boolean(attrs.disabled),
              })}
              onFocus={this.onFocus}
            />
          </span>
        );
      }
    }
  }

  renderReadOnlyContent({ label, help, type, options }) {
    let readValue = this.state.value;
    switch (type) {
      case 'checkbox':
        readValue = options
          ? options
              .map((op) => {
                let value = this.state.value || [];

                if (typeof value.includes !== 'function') {
                  value = [];
                }

                const opValue = op
                  ? op.value !== undefined
                    ? op.value
                    : op.id || op
                  : op;

                const opLabel = op
                  ? op.label !== undefined
                    ? op.label
                    : op.name || op
                  : op;

                return value.includes(opValue) ? opLabel : null;
              })
              .filter((op) => op)
              .join(', ')
          : this.state.checked
          ? 'Yes'
          : 'No';
        break;

      case 'toggle':
        readValue = this.state.checked ? 'Yes' : 'No';
        break;

      case 'date':
      case 'datetime':
        readValue = (
          <>
            <DateTime value={readValue} format="shortExact" />
          </>
        );
        break;

      case 'textarea':
        readValue = (
          <>
            <TextareaAutosize disabled={true} value={readValue} />
          </>
        );
        break;

      default:
        break;
    }
    return (
      <>
        <Label label={label} help={help} />
        <div className="form-field-readonly">{formatTableValue(readValue)}</div>
      </>
    );
  }

  render() {
    const {
      type = 'text',
      rules,
      required,
      className,
      showError,
      autoFocus,
      autoCompleteOff,
      fieldtableInline,
      large,
      validateIcon,
      validateBlur,
      onAutocompleteChange,
      locked,
      onChangeLock,
      hintOnFocus,
      readonlyContent,
      ...attrs
    } = this.normalizeProps();

    const {
      error,
      warning,
      hint,
      value,
      disabled,
      valid,
      locked: lockedState,
      focus,
    } = this.state;

    return (
      <div
        data-testid="rtl-formField"
        className={classNames('form-field', `form-field-${type}`, className, {
          'with-buttons': Boolean(attrs.buttons),
          'has-error': Boolean(error),
          'has-warning': Boolean(warning),
          'hiding-message': (error || warning) && showError === false,
          'form-field-large': Boolean(large),
          'with-value': large && typeof value === 'string' && value.length > 0,
          'form-field-locked': Boolean(locked),
        })}
      >
        {this.renderInput({
          ...attrs,
          type,
          disabled,
          ref: this.inputRef,
          value,
          onChange: this.onChangeInput,
          placeholder:
            attrs.placeholder !== undefined
              ? attrs.placeholder
              : large
              ? attrs.label
              : undefined,
          readonlyContent,
          ...(autoCompleteOff ? { autoComplete: 'off' } : undefined),
          ...(locked && lockedState ? { disabled: true } : undefined),
          ...(validateBlur ? { onBlur: this.onValidateBlur } : undefined),
        })}

        {autoCompleteOff && (
          <input
            type={type}
            name={attrs.name}
            tabIndex={-1}
            className="form-field-hidden-input"
          />
        )}

        {validateIcon &&
          (error ? (
            <FadeIn key="icon-invalid" className="form-validate-icon">
              <Icon svgType="field-invalid" />
            </FadeIn>
          ) : warning ? (
            <FadeIn key="icon-warning" className="form-validate-icon">
              <Icon svgType="field-warning" />
            </FadeIn>
          ) : (
            valid && (
              <FadeIn key="icon-valid" className="form-validate-icon">
                <Icon svgType="field-valid" />
              </FadeIn>
            )
          ))}

        {locked && !disabled && (
          <div className="form-locked-icon">
            <button
              aria-label={lockedState ? 'Unlock' : 'Lock'}
              className="as-link"
              onClick={this.onClickLock}
              type="button"
            >
              {lockedState ? (
                <Tooltip message="Unlock this field">
                  <span className="icon-lock" />
                </Tooltip>
              ) : (
                <Tooltip message="Lock this field">
                  <span className="icon-unlock" />
                </Tooltip>
              )}
            </button>
          </div>
        )}

        {showError !== false &&
          (error ? (
            <FadeIn>
              <span className="form-field-error">{error}</span>
            </FadeIn>
          ) : (
            warning && (
              <FadeIn>
                <span className="form-field-warning">{warning}</span>
              </FadeIn>
            )
          ))}

        {hint && <span className="form-field-hint">{hint}</span>}

        {hintOnFocus && (
          <span
            className={`form-field-hint form-field-hint-onfocus ${
              focus ? 'active' : ''
            }`}
          >
            <span>{hintOnFocus}</span>
          </span>
        )}
      </div>
    );
  }
}
