// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  get,
  set,
  each,
  pickBy,
  reduce,
  first,
  cloneDeep,
  merge,
} from 'lodash';
import ViewLoading from 'components/view/loading';
import DashboardPage from 'components/dashboard';
import actions from 'actions';
import moment from 'moment-timezone';
import { getReports } from 'constants/reports';
import { getReportFields } from 'constants/report-fields';
import { locationWithQuery } from 'utils';
import { getChartPeriod } from 'utils/chart';
import { tabs as orderTabs, fields as orderFields } from './Orders';
import { tabs as productTabs, fields as productFields } from './Products';

export const mapStateToProps = (state: Object) => ({
  account: state.account,
  dashboard: state.dashboard,
  settings: state.settings,
  client: state.client,
  loading: state.dashboard.loading,
});

export const mapDispatchToProps = (dispatch: Function) => ({
  fetchDashboard: async (start: Date, end: Date) => {
    return dispatch(actions.dashboard.fetch(start, end));
  },

  fetchReportRecords: (model, query) => {
    return dispatch(
      actions.reports.fetchRecords(model, {
        ...(query || {}),
        limit: 5,
      }),
    );
  },

  fetchReportGraph: (graph, startDate, endDate, period) => {
    return dispatch(
      actions.reports.fetchGraph(graph, { startDate, endDate, period }),
    );
  },

  fetchReportCollection: (type, query = {}) => {
    return dispatch(
      actions.reports.fetchCollection(type, {
        ...(query || {}),
        limit: 5,
      }),
    );
  },

  updateSetup: async (id, value) => {
    return dispatch(actions.client.updateSetup(id, value));
  },

  updateDashboard: async (values) => {
    return dispatch(actions.client.updateDashboard(values));
  },
});

export class Dashboard extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  state = {
    reportOptions: [],
  };

  canUpdateDashboard = false;

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      startDate: null,
      endDate: null,
      compareTo: null,
      period: null,
      stats: null,
      firstModel: null,
      firstReport: null,
      onChangeDates: this.onChangeDates.bind(this),
      onChangeDashboardOptions: this.onChangeDashboardOptions.bind(this),
    };
  }

  async componentWillMount() {
    const { router, location, settings } = this.props;

    const { client, user } = this.context;

    if (
      !user.all_permissions &&
      user.permissions &&
      user.permissions.length > 0
    ) {
      if (user.permissions[0].length > 0) {
        if (user.permissions[0] === 'dashboard') {
          router.replace('/');
        } else {
          router.replace(`/${user.permissions[0]}`);
        }
      }
    }

    const now = moment().tz(client.timezone);
    this.defaultDates = {
      startDate: now.clone().subtract(30, 'days').startOf('day'),
      endDate: now.clone().subtract(1, 'day').endOf('day'),
      compareTo: {
        startDate: now.clone().subtract(60, 'days').startOf('day'),
        endDate: now.clone().subtract(31, 'days').endOf('day'),
      },
    };

    this.reloadReports();
  }

  componentWillUnmount() {
    this.canUpdateDashboard = false;
  }

  async componentWillReceiveProps(nextProps) {
    const { location } = this.props;

    if (location.query !== nextProps.location.query) {
      this.fetchDashboard(nextProps.location.query);
    }
  }

  async reloadReports() {
    const { location, settings } = this.props;
    const { client } = this.context;
    const reportOptions = this.getReportOptions();
    const reportFields = getReportFields(client.timezone, settings);

    this.setState({ reportOptions, reportFields }, async () => {
      await this.fetchDashboard(location.query);
      setTimeout(() => (this.canUpdateDashboard = true), 1500);
      this.setState({ loaded: true });
    });
  }

  async fetchDashboard(locationQuery = {}) {
    const { fetchDashboard } = this.props;
    const { timezone } = this.context.client;

    const {
      start: locationStartDate,
      end: locationEndDate,
      compare_start: compareStart,
      compare_end: compareEnd,
    } = locationQuery;

    const startDate =
      locationStartDate && locationEndDate
        ? moment
            .tz(locationStartDate, 'YYYY-MM-DD', timezone)
            .tz(timezone)
            .startOf('day')
        : this.defaultDates.startDate;
    const endDate =
      locationStartDate && locationEndDate
        ? moment
            .tz(locationEndDate, 'YYYY-MM-DD', timezone)
            .tz(timezone)
            .endOf('day')
        : this.defaultDates.endDate;

    const compareTo =
      compareStart && compareEnd
        ? {
            startDate: moment
              .tz(compareStart, 'YYYY-MM-DD', timezone)
              .tz(timezone)
              .startOf('day'),
            endDate: moment
              .tz(compareEnd, 'YYYY-MM-DD', timezone)
              .tz(timezone)
              .endOf('day'),
          }
        : this.defaultDates.compareTo;

    this.setState({
      startDate,
      endDate,
      compareTo,
    });

    const period = getChartPeriod(startDate, endDate);

    await new Promise((resolve) => {
      this.setState(
        {
          startDate,
          endDate,
          compareTo,
          period,
        },
        async () => {
          await Promise.all([fetchDashboard(), this.fetchStats()]);
          resolve();
        },
      );
    });
  }

  getReportOptions() {
    const { client } = this.context;
    const reports = getReports(client.timezone);
    const productTab = productTabs.out_of_stock;
    return {
      orders: {
        label: 'Orders',
        model: 'orders',
        modelFields: orderFields,
        fields: reduce(
          orderTabs,
          (acc, tab, key) => ({
            ...acc,
            [key]: {
              id: key,
              label: tab.label,
              query: {
                expand: ['account'],
                ...tab.query,
              },
              checked: get(client.dashboard, `orders.${key}`, false),
            },
          }),
          {},
        ),
      },
      products: {
        label: 'Products',
        model: 'products',
        modelFields: productFields,
        fields: {
          out_of_stock: {
            id: 'out_of_stock',
            label: productTab.label,
            query: productTab.query,
            checked: get(client.dashboard, `products.out_of_stock`, false),
          },
        },
      },
      sales: {
        label: 'Sales',
        report: 'sales',
        fields: {
          sales: {
            ...reports.sales.types[0],
            label: 'Sales over time',
            checked: get(client.dashboard, `sales.sales`, false),
            graph: true,
          },
          ...reduce(
            pickBy(
              reports.sales.types,
              (type) =>
                [
                  'sales_by_product',
                  'sales_by_sku',
                  'sales_by_coupon',
                  'sales_by_promotion',
                  'sales_by_shipping_country',
                  'sales_by_customer',
                ].indexOf(type.id) >= 0,
            ),
            (acc, type) => ({
              ...acc,
              [type.id]: {
                ...type,
                label: type.dashboardName || type.name,
                checked: get(client.dashboard, `sales.${type.id}`, false),
              },
            }),
            {},
          ),
        },
      },
      // customers: {
      //   label: 'Customers',
      //   report: 'customers',
      //   fields: {
      //     customers: {
      //       ...reports.customers.types[0],
      //       label: 'Customers over time',
      //       checked: get(client.dashboard, `customers.customers`, false),
      //       graph: true,
      //     },
      //     ...reduce(
      //       pickBy(
      //         reports.customers.types,
      //         (type) => ['customers_first_vs_returning'].indexOf(type.id) >= 0,
      //       ),
      //       (acc, type) => ({
      //         ...acc,
      //         [type.id]: {
      //           ...type,
      //           label: type.dashboardName || type.name,
      //           checked: get(client.dashboard, `customers.${type.id}`, false),
      //         },
      //       }),
      //       {},
      //     ),
      //   },
      // },
    };
  }

  async fetchStats() {
    const { client } = this.context;
    const { fetchReportGraph, fetchReportCollection, fetchReportRecords } =
      this.props;
    const { startDate, endDate, period, compareTo, reportOptions } = this.state;

    const statsReqs = reduce(
      client.dashboard,
      (acc, val, group) => {
        return [
          ...acc,
          ...reduce(
            val,
            (acc, val2, typeId) => {
              return [
                ...acc,
                {
                  ...get(reportOptions, `${group}.fields.${typeId}`),
                  model: get(reportOptions, `${group}.model`),
                  report: get(reportOptions, `${group}.report`),
                  graph: get(reportOptions, `${group}.fields.${typeId}.graph`),
                  group,
                },
              ];
            },
            [],
          ).filter((x) => get(x, 'checked')),
        ];
      },
      [],
    );

    const stats = {
      models: {},
      reports: {},
    };

    const modelReqs = statsReqs.filter((req) => req.model);
    const reportReqs = statsReqs.filter((req) => req.report);

    await Promise.all([
      ...modelReqs.map((req) =>
        fetchReportRecords(req.model, req.query).then((result) => {
          set(stats.models, `${req.model}.${req.id}`, {
            ...req,
            result,
          });
        }),
      ),
      ...reportReqs.map((req) =>
        req.graph
          ? Promise.all([
              fetchReportGraph(req.report, startDate, endDate, period).then(
                (result) => {
                  set(stats.reports, `${req.report}.${req.id}`, {
                    ...req,
                    ...get(stats.reports, `${req.report}.${req.id}`, {}),
                    current: result,
                  });
                },
              ),
              fetchReportGraph(
                req.report,
                compareTo.startDate,
                compareTo.endDate,
                period,
              ).then((result) => {
                set(stats.reports, `${req.report}.${req.id}`, {
                  ...req,
                  ...get(stats.reports, `${req.report}.${req.id}`, {}),
                  previous: result,
                });
              }),
            ])
          : new Promise((resolve) => {
              const { fields, query } = this.getReportCollectionParams(
                req,
                startDate,
                endDate,
              );
              req.query = query;
              return fetchReportCollection(req.report, {
                ...req.query,
              }).then((result) => {
                set(stats.reports, `${req.report}.${req.id}`, {
                  ...req,
                  ...get(stats.reports, `${req.report}.${req.id}`, {}),
                  fields,
                  result,
                });
                resolve();
              });
            }),
      ),
    ]);

    this.setState({
      stats,
      firstModel: first(modelReqs),
      firstReport: first(reportReqs),
    });
  }

  getReportCollectionParams(req, startDate, endDate) {
    const fields = [];
    const query = {
      group: {},
      sort: req.sort,
      conditions: {
        $and: [
          {
            date_created: {
              $gte: { $date: startDate.toISOString() },
              $lte: { $date: endDate.toISOString() },
            },
          },
        ],
      },
    };
    const reportFields = cloneDeep(this.state.reportFields[req.report]);
    each(req.columns, (columns, group) => {
      columns.forEach((column) => {
        const field = reportFields[group].fields[column];
        fields.push(field);
        query.group = merge(query.group, field.group);
      });
    });
    return { fields, query };
  }

  onChangeDashboardOptions = (event, value) => {
    if (!this.canUpdateDashboard || !value) {
      return;
    } else {
      this.canUpdateDashboard = false;
      setTimeout(() => (this.canUpdateDashboard = true), 500);
    }
    const values = reduce(
      this.state.reportOptions,
      (acc, val, group) => ({
        ...acc,
        [group]: reduce(
          val.fields,
          (acc, field, key) => ({
            ...acc,
            [key]: !!get(value.nested, `${group}.fields.${key}`),
          }),
          {},
        ),
      }),
      {},
    );
    this.props.updateDashboard(values).then(() => this.reloadReports());
  };

  onChangeDates = ({ startDate, endDate, compareTo }) => {
    const { router, location } = this.props;
    if (!this.canUpdateDashboard) {
      return;
    }
    const next = locationWithQuery(location, {
      start: startDate.format('YYYY-MM-DD'),
      end: endDate.format('YYYY-MM-DD'),
      compare_start: compareTo.startDate.format('YYYY-MM-DD'),
      compare_end: compareTo.endDate.format('YYYY-MM-DD'),
    });
    router.replace(next);
    this.setState({ startDate, endDate, compareTo });
  };

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return <DashboardPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
