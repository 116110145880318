import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import iconify from 'services/iconify';

import Field from './field';

export default class LookupIcon extends React.PureComponent {
  static propTypes = {
    lookup: PropTypes.object.isRequired,
  };

  static contextTypes = {
    queryLookupResults: PropTypes.func.isRequired,
    queryLookupLoading: PropTypes.func.isRequired,
  };

  onQuery = (value) => {
    const { queryLookupResults, queryLookupLoading } = this.context;
    queryLookupLoading(true);
    iconify.search(value, { limit: 25 }).then((result) => {
      queryLookupLoading(false);
      queryLookupResults(map(result && result.icons, (icon) => ({ id: icon })));
    });
  };

  renderIconValue(icon) {
    return (
      <span>
        <span className="image">
          <span className="iconify" data-icon={icon.id || icon} />
        </span>
        {icon.id || icon}
      </span>
    );
  }

  renderIconLookupItems({ lookup, ...props }) {
    return lookup.results.map((icon) => (
      <li
        key={icon.id}
        role="option"
        data-id={icon.id}
        aria-selected={icon.id === props.selected?.id}
        className={classNames('item', {
          selected: icon.id === props.selected?.id,
        })}
        onMouseOver={props.onMouseOver}
        onClick={props.onClick}
      >
        <span className="col">
          <span className="image">
            <span className="iconify" data-icon={icon.id} />
          </span>
          {icon.id}
        </span>
      </li>
    ));
  }

  render() {
    return (
      <div className={classNames('form-lookup-icon', this.props.className)}>
        <Field
          ref="field"
          type="lookup"
          name="Icon"
          placeholder="Search icons by name"
          renderValue={this.renderIconValue}
          renderLookupItems={this.renderIconLookupItems}
          onQuery={this.onQuery}
          lookup={this.props.lookup}
          {...this.props}
          className={undefined}
          scalarValue={true}
        />
      </div>
    );
  }
}
