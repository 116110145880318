import React, { Fragment } from 'react';

export default function User({ user }) {
  const name = user && (user.name || user.username || user.email);
  return name ? (
    <Fragment>
      &nbsp;by&nbsp;
      <span className="bold">{name}</span>
    </Fragment>
  ) : null;
}
