import moment from 'moment-timezone';

import { formatCurrency } from 'utils';

// graph line colors
export const COLOR_PURPLE = '#785ce0';
export const COLOR_YELLOW = '#F4CA7C';
export const COLOR_RED = '#D54B44';
export const COLOR_BLUE = '#785ce0';

export function getReports(timezone) {
  return {
    sales: {
      name: 'Sales',
      description: 'View detailed reports on your sales',
      types: [
        {
          id: 'sales',
          name: 'Sales by day',
          type: 'sales',
          url: 'sales',
          main: 'time.day',
          sort: {
            year: -1,
            month: -1,
            dayOfMonth: -1,
          },
          includeTimeFields: true,
          columns: {
            time: ['day'],
            order: [
              'count',
              'gross_sales',
              'discount',
              'item_taxes',
              'net_sales',
            ],
          },
          charts: [
            {
              yFormat: (value, { currency }) => formatCurrency(value, currency),
              period: 'dayOfMonth',
              data: [
                {
                  id: 0,
                  field: 'sub_total',
                  description: 'Gross sales',
                },
              ],
            },
          ],
          metric: {
            label: 'orders',
            url: '/orders/:count',
          },
        },
        {
          id: 'sales_by_month',
          name: 'Sales by month',
          type: 'sales',
          url: 'sales-by-month',
          main: 'time.month',
          sort: {
            year: -1,
            month: -1,
          },
          includeTimeFields: true,
          columns: {
            time: ['month'],
            order: [
              'count',
              'gross_sales',
              'discount',
              'item_taxes',
              'net_sales',
            ],
          },
          dateRange: {
            startDate: moment()
              .tz(timezone)
              .add(-11, 'months')
              .startOf('month'),
            endDate: moment().tz(timezone).endOf('day'),
          },
          charts: [
            {
              yFormat: (value, { currency }) => formatCurrency(value, currency),
              period: 'month',
              data: [
                {
                  id: 0,
                  field: 'sub_total',
                  description: 'Gross sales',
                },
              ],
            },
          ],
        },
        {
          id: 'sales_by_product',
          name: 'Sales by product',
          type: 'sales',
          url: 'sales-by-product',
          main: 'product.name',
          sort: {
            sub_total: -1,
          },
          dateRange: {
            startDate: moment()
              .tz(timezone)
              .add(-11, 'months')
              .startOf('month'),
          },
          includeTimeFields: true,
          columns: {
            product: ['name', 'quantity'],
            order: ['gross_sales', 'discount', 'item_taxes', 'net_sales'],
          },
          metric: {
            label: 'products',
            url: '/products/:count',
          },
        },
        {
          id: 'sales_by_sku',
          name: 'Sales by SKU',
          type: 'sales',
          url: 'sales-by-sku',
          main: 'product.sku',
          sort: {
            sub_total: -1,
          },
          includeTimeFields: true,
          columns: {
            product: ['sku', 'name', 'quantity'],
            order: ['gross_sales', 'discount', 'item_taxes', 'net_sales'],
          },
          dateRange: {
            startDate: moment()
              .tz(timezone)
              .add(-11, 'months')
              .startOf('month'),
          },
          metric: {
            label: 'variants',
            url: '/products:variants/:count',
          },
        },
        {
          id: 'sales_by_coupon',
          name: 'Sales by coupon',
          type: 'sales',
          url: 'sales-by-coupon',
          main: 'order.coupon_code',
          sort: {
            coupon_code: 1,
          },
          includeTimeFields: true,
          columns: {
            order: [
              'coupon_code',
              'count',
              'gross_sales',
              'discount',
              'item_taxes',
              'net_sales',
            ],
          },
          metric: {
            label: 'coupons',
            url: '/coupons/:count',
          },
        },
        {
          id: 'sales_by_promotion',
          name: 'Sales by promotion',
          type: 'sales',
          url: 'sales-by-promotion',
          main: 'promotion.name',
          sort: {
            'promotion.name': 1,
          },
          includeTimeFields: true,
          columns: {
            promotion: ['name'],
            order: [
              'count',
              'gross_sales',
              'discount',
              'item_taxes',
              'net_sales',
            ],
            shipping: ['country'],
          },
          metric: {
            label: 'promotions',
            url: '/promotions/:count',
          },
        },
        {
          id: 'sales_by_shipping_country',
          name: 'Sales by shipping country',
          dashboardName: 'Sales by country',
          type: 'sales',
          url: 'sales-by-shipping-country',
          main: 'shipping.country',
          sort: {
            'shipping.country': 1,
          },
          includeTimeFields: true,
          columns: {
            shipping: ['country'],
            order: [
              'count',
              'gross_sales',
              'discount',
              'item_taxes',
              'net_sales',
            ],
          },
          metric: {
            label: 'countries',
            url: '/orders/:count',
          },
        },
        {
          id: 'sales_by_customer',
          name: 'Sales by customer',
          type: 'sales',
          url: 'sales-by-customer',
          main: 'customer.email',
          sort: {
            'account.email': 1,
          },
          includeTimeFields: true,
          columns: {
            customer: ['name', 'email'],
            order: [
              'count',
              'gross_sales',
              'discount',
              'item_taxes',
              'net_sales',
            ],
          },
          metric: {
            label: 'countries',
            url: '/accounts/:count',
          },
        },
        // {
        //   id: 'sales_average',
        //   name: 'Average order value',
        //   type: 'sales',
        //   url: 'sales-average',
        //   main: 'time.month',
        //   //hidden: true,
        //   sort: {
        //     year: -1,
        //     month: -1,
        //   },
        //   columns: {
        //     time: ['month'],
        //     order: ['count', 'discount', 'taxes', 'avg_price'],
        //   },
        //   dateRange: {
        //     startDate: moment().tz(timezone)
        //       .add(-11, 'months')
        //       .startOf('month'),
        //   },
        //   chart: {
        //     yFormat: (value, { currency }) => formatCurrency(value, currency),
        //     data: [
        //       {
        //         id: 0,
        //         field: 'avg_price',
        //         description: 'Average order value',
        //       },
        //     ],
        //   },
        // },
        // {
        //   name: 'Total orders',
        //   type: 'sales',
        //   url: 'sales-total',
        //   main: 'time.month',
        //   hidden: true,
        //   sort: {
        //     year: -1,
        //     month: -1,
        //   },
        //   columns: {
        //     time: ['month'],
        //     order: ['count'],
        //   },
        //   dateRange: {
        //     startDate: moment().tz(timezone)
        //       .add(-11, 'months')
        //       .startOf('month'),
        //   },
        //   chart: {
        //     yFormat: (value) => value,
        //     data: [
        //       {
        //         id: 0,
        //         field: 'orders',
        //         description: 'Orders count',
        //       },
        //     ],
        //   },
        // },
      ],
    },
    subscriptions: {
      hidden: false,
      name: 'Subscriptions',
      description: 'View detailed reports on your subscriptions',
      types: [
        {
          id: 'subscriptions-over-time',
          name: 'Subscriptions over time',
          type: 'subscriptions',
          url: 'subscriptions-over-time',
          main: 'time.month',
          sort: {
            year: -1,
            month: -1,
            dayOfMonth: -1,
          },
          includeTimeFields: true,
          columns: {
            time: ['month'],
            subscription: [
              'new_subscriptions',
              'paused',
              'canceled',
              'gross_sales',
            ],
          },
          removeFields: {
            subscription: ['active'],
          },
          dateRange: {
            startDate: moment()
              .tz(timezone)
              .add(-11, 'months')
              .startOf('month'),
          },
          charts: [
            {
              title: 'Subscription activity',
              pointColors: [COLOR_PURPLE, COLOR_YELLOW, COLOR_RED],
              yFormat: (value, { index }) => {
                switch (index) {
                  case '0':
                    return `${value} new`;
                  case '1':
                    return `${value} paused`;
                  case '2':
                    return `${value} canceled`;
                  default:
                    return value;
                }
              },
              data: [
                {
                  id: 0,
                  field: 'new_subscriptions',
                  description: 'New',
                },
                {
                  id: 1,
                  field: 'paused_subscriptions',
                  description: 'Paused',
                },
                {
                  id: 2,
                  field: 'canceled_subscriptions',
                  description: 'Canceled',
                },
              ],
              isCurrency: false,
            },
            {
              title: 'Gross sales',
              type: 'gross-sales',
              yFormat: (value, { currency }) => formatCurrency(value, currency),
              data: [
                {
                  id: 0,
                  field: 'gross_sales',
                  description: 'Gross Sales',
                },
              ],
              isCurrency: true,
            },
          ],
        },
        {
          id: 'subscriptions-by-plan',
          name: 'Subscriptions by plan',
          type: 'subscriptions',
          url: 'subscriptions-by-plan',
          sort: {
            gross_sales: -1,
          },
          search: {
            placeHolder: 'Search Products',
            searchField: 'product.name',
            filters: {
              type: {
                label: 'Product type',
                options: [
                  { value: 'physical', label: 'Physical' },
                  { value: 'digital', label: 'Digital' },
                  { value: 'bundle', label: 'Bundle' },
                  { value: 'giftcard', label: 'Gift card' },
                  { value: 'standard', label: 'Standard' },
                ],
                func: (query) => {
                  return { type: query };
                },
                searchField: 'product.type',
              },
              price: {
                label: 'Price',
                operators: ['gt', 'lt', 'eq'],
                type: 'currency',
                searchField: 'product.price',
                filterValue: (value) => {
                  if (value && typeof value === 'object') {
                    const filter = {};
                    for (const [key, v] of Object.entries(value)) {
                      if (v && !isNaN(v)) {
                        filter[`$${key}`] = parseFloat(v);
                      }
                    }

                    return filter;
                  }

                  return value;
                },
              },
              category: {
                label: 'Category',
                desc: 'Category',
                type: 'LookupCategory',
                searchField: 'category_ids',
                query: true,
                func: (query) => {
                  if (typeof query === 'string') {
                    return { categories: query };
                  }
                },
              },
              tags: {
                label: 'Tags',
                desc: 'Tagged with',
                type: 'tags',
                searchField: 'product.tags',
                filterValue: (value) => {
                  if (Array.isArray(value)) {
                    return {
                      $all: value,
                    };
                  }
                  return value;
                },
              },
            },
          },
          includeTimeFields: false,
          columns: {
            product: ['name'],
            subscription: ['plan', 'active', 'gross_sales'],
          },
          removeFields: {
            subscription: [
              'new_subscriptions',
              'trials_expired',
              'trials_converted',
              'trial_conversion_rate',
            ],
          },
          totalSummaryMetrics: [
            {
              label: 'Total Gross Value',
              fieldId: 'subscription.gross_sales',
            },
            {
              label: 'Total Active Subscriptions',
              fieldId: 'subscription.active',
            },
          ],
          dateRange: {
            startDate: moment().tz(timezone).add(-1, 'months').startOf('month'),
          },
        },
        {
          id: 'subscriptions-conversions',
          name: 'Trial Conversions',
          type: 'subscriptions',
          url: 'subscriptions-conversions',
          main: 'time.month',
          sort: {
            year: -1,
            month: -1,
            dayOfMonth: -1,
          },
          includeTimeFields: true,
          columns: {
            time: ['month'],
            subscription: [
              'trials_expired',
              'trials_converted',
              'trial_conversion_rate',
            ],
          },
          removeFields: {
            subscription: ['active'],
          },
          dateRange: {
            startDate: moment()
              .tz(timezone)
              .add(-11, 'months')
              .startOf('month'),
          },
          charts: [
            {
              title: 'Trial conversion and expirations',
              type: 'conversions',
              pointColors: [COLOR_BLUE, COLOR_YELLOW],
              yFormat: (value, { index }) => {
                switch (index) {
                  case '0':
                    return `${value} expired`;
                  case '1':
                    return `${value} converted`;
                  default:
                    return value;
                }
              },
              data: [
                {
                  id: 0,
                  field: 'trials_expired',
                  description: 'Trials expired',
                },
                {
                  id: 1,
                  field: 'trials_converted',
                  description: 'Trials converted',
                },
              ],
              isCurrency: false,
            },
          ],
        },
      ],
    },
    customers: {
      name: 'Customers',
      description: 'View detailed reports on your customers',
      types: [
        {
          id: 'customers',
          name: 'Customers over time',
          type: 'customers',
          url: 'customers',
          main: 'time.month',
          sort: {
            year: -1,
            month: -1,
          },
          includeTimeFields: true,
          columns: {
            time: ['month'],
            customer: ['count'],
            attributes: ['first_time', 'returning'],
          },
          dateRange: {
            startDate: moment()
              .tz(timezone)
              .add(-11, 'months')
              .startOf('month'),
          },
          charts: [
            {
              yFormat: (value, { index }) =>
                `${value} ${~~index === 0 ? 'returning' : 'first-time'}`,
              data: [
                {
                  id: 0,
                  field: 'returning',
                  description: 'Returning customers',
                },
                {
                  id: 1,
                  field: 'first_time',
                  description: 'First-time customers',
                },
              ],
              isCurrency: false,
            },
          ],
          metric: {
            label: 'customers',
            url: '/orders/:count',
          },
        },
        {
          id: 'customers_first_vs_returning',
          name: 'First-time vs returning customers',
          dashboardName: 'First-time vs returning',
          type: 'customers',
          url: 'customers-first-vs-returning',
          main: 'time.month',
          sort: {
            year: -1,
            month: -1,
          },
          includeTimeFields: true,
          columns: {
            time: ['month'],
            customer: ['count'],
            attributes: ['first_time', 'returning'],
          },
          charts: [
            {
              yFormat: (value) => value,
              data: [
                {
                  id: 0,
                  description: 'Returning customers',
                  field: 'returning',
                },
                {
                  id: 1,
                  description: 'First-time customers',
                  field: 'first_time',
                },
              ],
              isCurrency: false,
            },
          ],
          metric: {
            label: 'returning orders',
            url: '/orders/:count',
          },
        },
        {
          id: 'customers_by_country',
          name: 'Customers by country',
          type: 'customers',
          url: 'customers-by-country',
          main: 'location.country',
          sort: {
            'location.country': 1,
          },
          includeTimeFields: true,
          columns: {
            customer: ['count'],
            location: ['country'],
          },
          metric: {
            label: 'countries',
            url: '/orders/:count',
          },
        },
        {
          id: 'customers_returning',
          name: 'Returning customers',
          type: 'customers',
          url: 'customers-returning',
          main: 'time.month',
          sort: {
            year: -1,
            month: -1,
          },
          includeTimeFields: true,
          columns: {
            time: ['month'],
            attributes: ['returning'],
          },
          charts: [
            {
              yFormat: (value) => value,
              data: [
                {
                  id: 0,
                  field: 'customers',
                  description: 'Customers count',
                },
              ],
              isCurrency: false,
            },
          ],
          metric: {
            label: 'returning customers',
            url: '/orders/:count',
          },
        },
        {
          id: 'customers_first_time',
          name: 'First-time customers',
          type: 'customers',
          url: 'customers-first-time',
          main: 'time.month',
          sort: {
            year: -1,
            month: -1,
          },
          includeTimeFields: true,
          columns: {
            time: ['month'],
            attributes: ['first_time'],
          },
          charts: [
            {
              yFormat: (value) => value,
              data: [
                {
                  id: 0,
                  field: 'first_time',
                  description: 'First-time customers',
                },
              ],
              isCurrency: false,
            },
          ],
          metric: {
            label: 'First-time customers',
            url: '/orders/:count',
          },
        },
      ],
    },
    finance: {
      name: 'Financial',
      description: 'View detailed reports on your finances',
      types: [
        {
          id: 'payments',
          name: 'Payments by type',
          type: 'payments',
          url: 'payments',
          main: 'payments.method',
          sort: {
            payments: -1,
          },
          includeTimeFields: true,
          columns: {
            payments: [
              'method',
              'card_brand',
              'count',
              'gross_payments',
              'refunds',
              'net_payments',
            ],
          },
          metric: {
            label: 'what is this',
            url: '/payments/:count',
          },
        },
        {
          id: 'taxes_by_country',
          name: 'Taxes by country',
          type: 'taxes',
          url: 'taxes',
          main: 'shipping.country',
          sort: {
            'shipping.country': 1,
          },
          includeTimeFields: true,
          columns: {
            shipping: ['country'],
            info: ['amount'],
          },
          metric: {
            label: 'tax countries',
            url: '/orders/:count',
          },
        },
        {
          id: 'taxes_by_state',
          name: 'Taxes by state',
          type: 'taxes',
          url: 'taxes-by-state',
          main: 'shipping.state',
          sort: {
            'shipping.state': 1,
          },
          includeTimeFields: true,
          columns: {
            shipping: ['state'],
            info: ['amount'],
          },
          metric: {
            label: 'tax states',
            url: '/orders/:count',
          },
        },
        {
          id: 'taxes_by_rate',
          name: 'Taxes by rate',
          type: 'taxes',
          url: 'taxes-by-rate',
          main: 'rate',
          sort: {
            rate: 1,
          },
          includeTimeFields: true,
          columns: {
            info: ['name', 'rate', 'amount'],
          },
          metric: {
            label: 'tax rates',
            url: '/orders/:count',
          },
        },
        // {
        //   id: 'taxes_by_name',
        //   name: 'Taxes by name',
        //   type: 'taxes',
        //   url: 'taxes-by-name',
        //   columns: {
        //     info: ['name', 'amount'],
        //   },
        //   metric: {
        //     label: 'orders',
        //     url: '/orders/:count',
        //   },
        // },
      ],
    },
  };
}
