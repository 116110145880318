import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import { formatCurrency } from 'utils';

import actions from 'actions';

import ViewPage from 'components/giftcard/view';
import LoadingView from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';

export const mapStateToProps = (state) => ({
  record: state.data.record,
  loading: state.data.loading,
  errors: state.data.recordErrors,
  lookup: state.lookup,
  categories: state.categories,
  content: state.content,
  settings: state.settings,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchRecord(id) {
    return dispatch(
      actions.data.fetchRecord('giftcards', id, {
        expand: ['account', 'order'],
        include: {
          product: {
            url: '/products/{product_id}',
            fields: 'name',
          },
        },
      }),
    );
  },

  updateRecord(id, data) {
    return dispatch(actions.data.updateRecord('giftcards', id, data));
  },

  deleteRecord(id) {
    return dispatch(actions.data.deleteRecord('giftcards', id));
  },

  loadSettings() {
    return Promise.all([
      dispatch(actions.settings.load('accounts')),
      dispatch(actions.settings.load('payments')),
    ]);
  },
});

export class ViewGiftcard extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    params: pt.object.isRequired,
    router: pt.object.isRequired,

    fetchRecord: pt.func.isRequired,
    loadSettings: pt.func.isRequired,
    updateRecord: pt.func.isRequired,
    deleteRecord: pt.func.isRequired,
  };

  static contextTypes = {
    notifyError: pt.func.isRequired,
    notifySuccess: pt.func.isRequired,
    notifyDeleted: pt.func.isRequired,
    account: pt.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      onEditValues: this.onEditValues,
      onDelete: this.onDelete,
    };
  }

  componentDidMount() {
    const { params, fetchRecord, loadSettings } = this.props;

    Promise.all([fetchRecord(params.id), loadSettings()]).then(() => {
      this.setState({ loaded: true });
    });
  }

  componentDidUpdate(prevProps) {
    const { params, fetchRecord } = this.props;

    if (params.id !== prevProps.params.id) {
      this.setState({ loaded: false });

      fetchRecord(params.id).then(() => {
        this.setState({ loaded: true });
      });
    }
  }

  onEditValues = async (values) => {
    const { params, record, updateRecord, fetchRecord } = this.props;

    const result = await updateRecord(params.id, values);

    if (result.errors) {
      this.context.notifyError(result.errors);
      return;
    }

    if (!record.account_id && result.account_id) {
      this.context.notifySuccess(
        `${formatCurrency(
          record.amount,
          record.currency,
        )} applied to customer's account balance`,
      );
    }

    if ((!record.send_email || values.$notify) && result.send_email) {
      this.context.notifySuccess(`Gift card send to ${result.send_email}`);
    }

    return fetchRecord(params.id);
  };

  onDelete = async () => {
    const { params, router, deleteRecord } = this.props;

    await deleteRecord(params.id);

    this.context.notifyDeleted('Gift card');
    router.replace('/giftcards');
  };

  render() {
    if (!this.state.loaded) {
      return <LoadingView />;
    }

    if (!this.props.record) {
      return <NotFoundPage />;
    }

    return <ViewPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewGiftcard);
