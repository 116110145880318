/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FadeIn } from 'components/transitions';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';

export default class PayPalExpressEditModal extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    paypalMethod: PropTypes.object,
    editValues: PropTypes.object,
    testEnv: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
  };

  render() {
    const {
      loading,
      paypalMethod,
      editValues,
      testEnv,
      onClose,
      onSubmit,
      onChange,
    } = this.props;

    const paypalValues = editValues?.methods?.paypal || {};
    const useCustomCreds =
      paypalValues.custom_creds !== undefined
        ? paypalValues.custom_creds
        : Boolean(paypalMethod.client_id);
    const mode = testEnv ? 'test' : paypalValues.mode || paypalMethod.mode;

    return (
      <Form onSubmit={onSubmit} onChange={onChange}>
        <Modal
          title="Edit PayPal Express settings"
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'cancel',
              onClick: onClose,
            },
          ]}
          width={500}
          cancel={false}
          loading={loading}
          onClose={onClose}
        >
          <fieldset>
            <Field type="hidden" name="methods.paypal.activated" value={true} />
            <Field type="hidden" name="methods.paypal.enabled" value={true} />
            <Field type="hidden" name="methods.paypal.mode" value={mode} />
            <Field
              type="hidden"
              name="methods.paypal.express_activated"
              value={true}
            />
            <Field
              type="hidden"
              name="methods.paypal.express_enabled"
              value={true}
            />
            {!useCustomCreds && (
              <FadeIn>
                <Field
                  type="text"
                  label="Merchant ID"
                  name="methods.paypal.merchant_id"
                  defaultValue={paypalMethod.merchant_id}
                />
              </FadeIn>
            )}
            <Field
              type="toggle"
              label="Use custom credentials"
              name="methods.paypal.custom_creds"
              defaultChecked={useCustomCreds}
              readonly={true}
            />
            {useCustomCreds ? (
              <FadeIn>
                <p>
                  Your own PayPal credentials may be necessary for capturing
                  charges in some cases including custom store checkouts.
                  Contact <a href="mailto:support@swell.is">support</a> for
                  details.
                </p>
                <div className="row">
                  {testEnv ? (
                    <Field
                      name="methods.paypal.mode"
                      type="hidden"
                      value="test"
                    />
                  ) : (
                    <Field
                      type="radio"
                      name="methods.paypal.mode"
                      buttons={true}
                      options={[
                        {
                          value: 'live',
                          label: 'Live',
                        },
                        {
                          value: 'test',
                          label: 'Sandbox',
                        },
                      ]}
                      defaultValue={mode}
                      required={true}
                    />
                  )}
                </div>
                <Field
                  type="text"
                  label="Merchant ID"
                  name="methods.paypal.merchant_id"
                  defaultValue={paypalMethod.merchant_id}
                  required={true}
                />
                <Field
                  type="text"
                  label="Client ID"
                  name="methods.paypal.client_id"
                  defaultValue={paypalMethod.client_id}
                  required={true}
                />
                <Field
                  type="text"
                  label="Client secret"
                  name="methods.paypal.client_secret"
                  defaultValue={paypalMethod.client_secret}
                  required={true}
                />
              </FadeIn>
            ) : (
              <Fragment>
                <Field
                  type="hidden"
                  name="methods.paypal.merchant_id"
                  value={paypalMethod.merchant_id}
                />
                <Field
                  type="hidden"
                  name="methods.paypal.client_id"
                  value={null}
                />
                <Field
                  type="hidden"
                  name="methods.paypal.client_secret"
                  value={null}
                />
              </Fragment>
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
