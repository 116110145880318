import React from 'react';

import { formatCurrencyRounded } from 'utils';
import Icon from 'components/icon';
import Payment from '../utils/payment';
import Date from '../utils/date';

export default function PaymentSucceeded({ event, settings }) {
  const { payment = {} } = event;

  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/payment" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          {formatCurrencyRounded(
            payment.amount || 0,
            payment.currency || 'USD',
          )}{' '}
          paid with &ensp;
          <Payment data={payment} settings={settings} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
      </div>
    </div>
  );
}
