import React from 'react';
import { Form, Button } from 'components/form';
import Link from 'components/link';
import Fields from './fields';
import Panel from './panel';
import Logo from 'components/logo/logo-inline';
import './signup.scss';

export default class SingleSignupPage extends React.PureComponent {
  render() {
    const { onSubmitForm } = this.props;

    return (
      <div className="single-signup">
        <Panel />
        <div className="single-signup_form-container">
          <div className="form-container_inner">
            <Logo />
            <Form
              onSubmit={onSubmitForm}
              autoFocus={true}
              autoFocusEmpty={true}
            >
              <Fields {...this.props} />
              <div>
                <div className="single-signup_actions">
                  <Button type="submit" styleType="default-purple" size="large">
                    Create your store
                  </Button>
                  <div className="single-signup_note">
                    Already have an account? <Link to="/login">Log in</Link>
                  </div>
                </div>
                <div className="single-signup_policies">
                  <p>
                    By creating an account, you agree with our
                    <br />
                    <a href="https://www.swell.is/legal/privacy-policy">
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a href="https://www.swell.is/legal/terms-of-service">
                      Terms of Service.
                    </a>
                  </p>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
