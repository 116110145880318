import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import api from 'services/api';
import actions from 'actions';

import LoadingView from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';
import EditPage from 'components/pages/category/edit';

import { locationWithQuery } from 'utils';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';
import { moveProduct } from 'utils/category';
import {
  contentExpandsDeprecated,
  contentUpdatesDeprecated,
} from 'utils/content';

const DEFAULT_PAGE_SIZE = 15;

function isProductsPageRequested(query, prevQuery) {
  return query.page !== prevQuery.page || query.limit !== prevQuery.limit;
}

function checkProductsIndex(list) {
  if (list.length <= 0) {
    return true;
  }

  if (typeof list[0].sort !== 'number') {
    return false;
  }

  let index = list[0].sort - 1;

  return list.every((item) => {
    index += 1;

    return item.sort === index;
  });
}

function getProductSortAfterReindex(products, index) {
  return (products.page - 1) * (products.limit || DEFAULT_PAGE_SIZE) + index;
}

export const mapStateToProps = (state) => ({
  data: state.data,
  categories: state.categories,
  content: state.content,
  loading: state.categories.loading || state.data.loading,
  record: state.data.record,
  errors: state.data.recordErrors,
  lookup: state.lookup,
});

export const mapDispatchToProps = (dispatch) => ({
  async fetchRecord(id) {
    const content = await dispatch(
      actions.content.loadFieldsDeprecated('categories'),
    );

    return dispatch(
      actions.data.fetchRecord('categories', id, {
        expand: ['parent', ...contentExpandsDeprecated(content.results)],
      }),
    );
  },

  fetchCategories() {
    return dispatch(actions.categories.fetch());
  },

  loadCategories() {
    return dispatch(actions.categories.load());
  },

  updateRecord(id, data) {
    return dispatch(actions.data.updateRecord('categories', id, data));
  },

  deleteRecord(id) {
    return dispatch(actions.data.deleteRecord('categories', id));
  },

  deleteCategoryProduct(id) {
    return dispatch(actions.data.deleteRecord('categories:products', id));
  },
});

export class EditCategory extends React.Component {
  static propTypes = {
    params: pt.object,
    router: pt.object,
    record: pt.object,
    content: pt.object,
    location: pt.object,

    fetchRecord: pt.func,
    updateRecord: pt.func,
    deleteRecord: pt.func,
    loadCategories: pt.func,
    fetchCategories: pt.func,
    deleteCategoryProduct: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
    closeModal: pt.func.isRequired,
    notifyError: pt.func.isRequired,
    notifyDeleted: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      edited: false,
      values: {},
      products: { results: [], count: 0 },
      sorting: '',
    };

    this.methods = {
      onSubmitRecord: this.onSubmitRecord,
      onClickDelete: this.onClickDelete,
      onChangeForm: this.onChangeForm,
      onChangePage: this.onChangePage,
      onChangeLimit: this.onChangeLimit,
      onChangeSorting: this.onChangeSorting,
      onSelectProduct: this.onSelectProduct,
      onClickRemoveProduct: this.onClickRemoveProduct,
      onMoveProductManually: this.onMoveProductManually,
    };
  }

  componentDidMount() {
    const { params, loadCategories } = this.props;

    confirmRouteLeave(this);

    loadCategories();

    this.fetchCategory(params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);

    if (prevProps.params.id !== this.props.params.id) {
      this.setState({ loaded: false });

      this.fetchCategory(this.props.params.id);
    }

    if (
      isProductsPageRequested(
        this.props.location.query,
        prevProps.location.query,
      )
    ) {
      this.fetchProducts(this.props.record.id).then((products) => {
        this.setState({ products });
      });
    }
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  async fetchCategory(categoryId) {
    const { fetchRecord } = this.props;

    const [record, products] = await Promise.all([
      fetchRecord(categoryId),
      this.fetchProducts(categoryId),
    ]);

    this.setState({
      loaded: true,
      products,
      sorting: record.sorting,
      values: {
        ...record,
      },
    });
  }

  onChangePage = (event) => {
    const page = Number(event.currentTarget.dataset.page);

    const next = locationWithQuery(this.props.location, {
      page: page > 1 ? page : undefined,
    });

    this.props.router.push(next);
  };

  onChangeLimit = (event, limit) => {
    if (this.props.location.query.limit === limit) {
      return;
    }

    const number = Number(limit) || DEFAULT_PAGE_SIZE;

    if (number === DEFAULT_PAGE_SIZE && !this.props.location.query.limit) {
      return;
    }

    const next = locationWithQuery(this.props.location, {
      page: undefined,
      limit: number !== DEFAULT_PAGE_SIZE ? number : undefined,
    });

    this.props.router.push(next);
  };

  fetchProducts(categoryId) {
    const { page, limit = DEFAULT_PAGE_SIZE } = this.props.location.query;

    return api
      .get('/data/categories:products', {
        parent_id: categoryId,
        fields:
          'id, sort, product_id, product.id, product.name, product.price, product.currency, product.date_created, product.images',
        expand: 'product',
        limit,
        page,
      })
      .then((result) => {
        result.limit = limit;
        result.totalPages = result.page_count;
        return result;
      });
  }

  /**
   * @param {object} values
   * @param {boolean} edited
   */
  onChangeForm = (values, edited) => {
    this.setState((state) => ({
      values: {
        ...state.values,
        ...values,
      },
      edited,
    }));
  };

  /** @param {React.MouseEvent} event */
  onClickDelete = (event) => {
    event.preventDefault();

    const { params, router, deleteRecord, fetchCategories } = this.props;

    this.context.openModal('ConfirmDelete', {
      title: `this category`,
      onConfirm: async () => {
        const result = await deleteRecord(params.id);
        if (result && !result.errors) {
          fetchCategories();
          router.replace(`/categories`);
          this.context.notifyDeleted('Category');
          if (this.closeOnDelete) {
            this.context.closeModal();
          }
        }
      },
    });
  };

  /**
   * @param {React.ChangeEvent} event
   * @param {string} sorting
   */
  onChangeSorting = (event, sorting) => {
    if (!this.state.sorting && !sorting) {
      return;
    } else if (sorting !== this.state.sorting) {
      this.setState({ sorting });
    }
  };

  onSelectProduct = async (product) => {
    if (!product.category_index) {
      product.category_index = { id: [] };
    }

    if (!product.category_index.id) {
      product.category_index.id = [];
    }

    const categoryId = this.props.record.id;

    const position = product.category_index.id.indexOf(categoryId);

    let result;

    if (position === -1) {
      product.category_index.id.push(categoryId);

      result = await this.props.updateRecord(categoryId, {
        products: [{ product_id: product.id, sort: this.state.products.count }],
      });
    } else {
      product.category_index.id.splice(position, 1);

      const item = await api.get('/data/categories:products/:last', {
        parent_id: categoryId,
        product_id: product.id,
      });

      if (item) {
        return this.deleteCategoryProduct(item.id);
      }
    }

    if (result?.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    const products = await this.fetchProducts(categoryId);

    this.setState({ products });

    return true;
  };

  /** @param {React.MouseEvent} event */
  onClickRemoveProduct = (event) => {
    event.preventDefault();

    const { id } = event.currentTarget.dataset;

    return this.deleteCategoryProduct(id);
  };

  async deleteCategoryProduct(id) {
    const result = await this.props.deleteCategoryProduct(id);

    if (result?.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    const categoryId = this.props.record.id;

    // If the last item on the page is removed
    if (this.state.products.results.length <= 1) {
      // then go to the previous page
      const prevPage = (Number(this.props.location.query.page) || 1) - 1;

      const next = locationWithQuery(this.props.location, {
        page: prevPage > 1 ? prevPage : undefined,
      });

      this.props.router.replace(next);
    } else {
      const products = await this.fetchProducts(categoryId);

      this.setState({ products });
    }

    return true;
  }

  /**
   * @param {string} itemId
   * @param {number} sourceIndex
   * @param {number} targetIndex
   * @param {number} targetSort
   */
  onMoveProductManually = async (
    itemId,
    sourceIndex,
    targetIndex,
    targetSort,
  ) => {
    // Check if products need to be re-indexed
    const reindex = !checkProductsIndex(this.state.products.results);

    // Move product locally
    this.setState((state) => ({
      products: moveProduct(state.products, sourceIndex, targetIndex),
    }));

    // Move product in database
    const result = await api.put(`/data/categories:products/${itemId}`, {
      sort: reindex
        ? getProductSortAfterReindex(this.state.products, targetIndex)
        : targetSort,
      $insert: true,
      $reindex: reindex,
    });

    if (result?.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    const products = await this.fetchProducts(this.props.params.id);

    this.setState({ products });

    return true;
  };

  onSubmitRecord = async (values) => {
    const { params, content, updateRecord } = this.props;

    const result = await updateRecord(params.id, {
      ...values,
      parent: undefined,
      parent_id: values.parent && values.parent.id,
      slug: values.slug.trim() !== '' ? values.slug : undefined,
      images: undefined,
      content: undefined,
      $set: {
        content: contentUpdatesDeprecated(
          content.fieldsDeprecated.categories,
          values.content,
        ),
        images: values.images,
      },
    });

    if (result?.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    await this.fetchCategory(params.id);

    this.setState({ edited: false });

    return true;
  };

  render() {
    if (!this.state.loaded) {
      return <LoadingView />;
    }

    if (!this.props.record) {
      return <NotFoundPage />;
    }

    return <EditPage {...this.props} {...this.state} {...this.methods} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);
