import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { get, set, find, cloneDeep, omit } from 'lodash';
import pt from 'prop-types';

import {
  setLocaleSelectorState,
  registerLocalizedField,
  unregisterLocalizedField,
} from 'utils/container';
import { localeFallbackValue, isValueEqual } from 'utils';
import { localeName } from 'utils/geo';
import { currencyName } from 'utils/money';

import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import { FadeIn } from 'components/transitions';

import Label from './label';
import Field from './field';

function getInitialValues(props, localizeKey) {
  const values = {
    [localizeKey]: props.localeValue,
  };

  set(
    values,
    props.localeFieldName || props.name,
    props.value !== undefined ? props.value : props.defaultValue,
  );

  return cloneDeep(values);
}

export const mapStateToProps = (state) => ({
  user: state.user,
});

export class FieldLocalized extends React.PureComponent {
  static propTypes = {
    className: pt.string,
    currency: pt.string,
    defaultValue: pt.any,
    hint: pt.string,
    hasLocalizedRules: pt.bool,
    initialTemp: pt.string,
    locale: pt.string,
    localeFieldName: pt.string,
    localeFieldPrefix: pt.string,
    localePlaceholder: pt.object,
    localeTemp: pt.string,
    localeValue: pt.object,
    localized: pt.bool,
    name: pt.string,
    label: pt.node,
    placeholder: pt.string,
    tooltipDir: pt.string,
    type: pt.string,
    value: pt.any,
    user: pt.object,
    help: pt.oneOfType([pt.node, pt.object]),

    dispatch: pt.func,
    onChange: pt.func,
  };

  static defaultProps = {
    tooltipDir: 'right',
  };

  static contextTypes = {
    client: pt.object,
    account: pt.object,
    user: pt.object,
  };

  constructor(props) {
    super(props);

    const localizeKey = props.type === 'currency' ? '$currency' : '$locale';

    this.state = {
      values: getInitialValues(props, localizeKey),
      useDefaultValue: true,
      initialTemp: props.localeTemp,
      initialCurrency: props.currency,
      localizeKey,
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    registerLocalizedField(this);
    this.setLocaleSelectorState(true, this.props);
  }

  componentWillUnmount() {
    unregisterLocalizedField(this);
    this.setLocaleSelectorState(false, this.props);
  }

  componentDidUpdate(prevProps) {
    const isValueChanged =
      !isValueEqual(prevProps.defaultValue, this.props.defaultValue) ||
      !isValueEqual(prevProps.value, this.props.value) ||
      !isValueEqual(prevProps.localeValue, this.props.localeValue);

    if (isValueChanged) {
      this.setState({
        values: getInitialValues(this.props, this.state.localizeKey),
      });
    }

    if (!this.state.initialTemp && this.props.localeTemp) {
      this.setState({ initialTemp: this.props.localeTemp });
    }

    if (prevProps.localized !== this.props.localized) {
      this.setLocaleSelectorState(true, this.props, true);
    }

    if (prevProps.currency !== this.props.currency) {
      this.setState({
        useDefaultValue: this.props.currency === this.state.initialCurrency,
      });
    }
  }

  setLocaleSelectorState(mounted, props, prev = false) {
    if (props.localized !== false) {
      if (props.type === 'currency') {
        setLocaleSelectorState(this, 'currencyFieldCount', mounted ? 1 : -1);
      } else {
        setLocaleSelectorState(this, 'localeFieldCount', mounted ? 1 : -1);
      }
    } else if (mounted && prev) {
      if (props.type === 'currency') {
        setLocaleSelectorState(this, 'currencyFieldCount', -1);
      } else {
        setLocaleSelectorState(this, 'localeFieldCount', -1);
      }
    }
  }

  getLocales() {
    const { locale, localeTemp } = this.props;
    const { initialTemp } = this.state;
    const { client } = this.context;
    const { locales } = client;
    if (locale) {
      return [
        find(locales, { code: locale }) || {
          code: locale,
          name: localeName(locale),
        },
      ];
    }
    let result =
      locales.length > 0
        ? locales
        : [{ code: client.locale, name: localeName(client.locale) }];
    if (initialTemp) {
      result = result.filter((loc) => loc.code !== initialTemp);
    }
    const tempLocale = localeTemp
      ? [{ code: localeTemp, name: localeName(localeTemp) }]
      : [];
    return [...result, ...tempLocale];
  }

  getCurrencies() {
    const { currency } = this.props;
    const { client, account } = this.context;
    let { pricedCurrencies } = client;
    if (!account.hasPricedCurrencies) {
      pricedCurrencies = [];
    }
    const defaultCode = currency || client.currency;
    const defaultConfig = find(pricedCurrencies, { code: defaultCode }) || {
      code: defaultCode,
      name: currencyName(defaultCode),
    };
    return pricedCurrencies.length > 0
      ? [
          ...pricedCurrencies,
          ...(pricedCurrencies.indexOf(defaultConfig) === -1
            ? [defaultConfig]
            : []),
        ]
      : defaultConfig;
  }

  onChange = (event, value, component) => {
    const { localeFieldName, name, onChange } = this.props;
    const { localizeKey } = this.state;
    if (component && component.props.name) {
      let fieldName = localeFieldName || name;
      if (
        localizeKey === '$locale' &&
        component.props.name.includes('$locale')
      ) {
        fieldName = component.props.name.replace(/.*\$locale\./, `$locale.`);
      } else if (
        localizeKey === '$currency' &&
        component.props.name.includes('$currency')
      ) {
        fieldName = component.props.name.replace(
          /.*\$currency\./,
          `$currency.`,
        );
      }
      const values = { ...this.state.values };
      set(values, fieldName, value);
      this.setState({ values });
    }
    if (onChange) {
      onChange(event, value, component);
    }
  };

  getInputRef() {
    return this.inputRef.current;
  }

  focus() {
    const input = this.getInputRef();

    if (input !== null && typeof input.focus === 'function') {
      input.focus();
    }
  }

  /**
   * Prepare localized rules if the hasLocalizedRules is specified:
   *  some localized fields may have dependencies and only the parent field can resolve the rules correctly
   *  with the hasLocalizedRules property you can force the field to indicate the currency or locale for the rule
   * @param {object} props
   * @param {string | undefined} currency
   * @return {array} - new rules
   */
  getLocalizedRules(props, currency) {
    if (!props.hasLocalizedRules || !Array.isArray(props.rules)) {
      return props.rules;
    }

    const newRules = [];
    for (const rule of props.rules) {
      const newRule = {
        ...(rule || {}),
      };

      if (typeof newRule.rule === 'function') {
        const localizedRule = () => {
          // provide current currency for each rule except main (that uses root data)
          return rule.rule(currency);
        };

        newRule.rule = localizedRule;
      }

      newRules.push(newRule);
    }

    return newRules;
  }

  getFieldProps(props, config, defaultCode, isFirst) {
    const isDefault = config.code === defaultCode;
    const fieldProps = {
      ...props,
      id: isDefault ? props.id || props.name : props.id,
      label: undefined,
      help: undefined,
      className: props.className
        ? props.className.replace(/span[1234]/g, '')
        : undefined,
      required: isDefault || config.required ? props.required : false,
      placeholder: isDefault
        ? props.placeholder
        : this.getFallbackPlaceholder(config, config.code),
      onChange: this.onChange,
      'data-locale': config.code,
      ...(isFirst
        ? {
            hint: props.hint,
            autoFocus: props.autoFocus,
            'data-locale': undefined,
          }
        : { hint: undefined, autoFocus: undefined }),
      ...(props.type === 'currency'
        ? {
            currency: config.code,
            currencySymbolPadding: 0,
          }
        : undefined),
    };

    fieldProps.rules = this.getLocalizedRules(
      props,
      isFirst ? undefined : config.code,
    );
    // do not provide it to the fields
    delete fieldProps.hasLocalizedRules;

    return fieldProps;
  }

  getFallbackPlaceholder(config, code) {
    const { type, localeFieldName, name, placeholder, localePlaceholder } =
      this.props;

    if (localePlaceholder) {
      return localePlaceholder[code];
    } else if (type === 'currency') {
      return undefined;
    }

    const fieldName = localeFieldName || name;
    const fallbackValue = localeFallbackValue({
      context: this.state.values,
      name: fieldName,
      locale: config,
      localeConfigs: this.context.client.locales,
    });
    if (fallbackValue && fallbackValue.length > 0) {
      return fallbackValue instanceof Array
        ? fallbackValue.join(', ')
        : fallbackValue;
    }
    return placeholder;
  }

  renderFields({ configs, defaultCode, userCodes, tempCode }) {
    const {
      user,
      label,
      localeValue = {},
      localeFieldPrefix = '',
      localeFieldName,
      localePlaceholder,
      initialTemp,
      localeTemp,
      help,
      value,
      defaultValue,
      tooltipDir,
      dispatch,
      localized,
      ...props
    } = this.props;

    const { localizeKey, useDefaultValue, initialCurrency } = this.state;

    const { currency: clientCurrency } = this.context.client;

    const isCurrency = props.type === 'currency';

    const defaultConfig = find(configs, { code: defaultCode }) || {
      code: defaultCode,
      name: defaultCode,
    };

    const fieldId = props.id || props.name;
    const dataIndex = props['data-index'];

    const namePrefix = localeFieldPrefix ? `${localeFieldPrefix}.` : '';

    const isMultiCurrency =
      localizeKey === '$currency' &&
      this.context.client.pricedCurrencies.length;

    const isMultiLocale =
      localizeKey === '$locale' && this.context.client.locales.length;

    if (localized !== false && (isMultiCurrency || isMultiLocale)) {
      if (configs.length > 1) {
        const otherConfigs = configs.filter((loc) => loc.code !== defaultCode);

        const isActive = (config) =>
          !!(
            userCodes.indexOf(config.code) >= 0 ||
            (tempCode && tempCode === config.code)
          );

        const getName = (config) =>
          `${namePrefix}${localizeKey}.${config.code}.${
            localeFieldName || props.name
          }`;

        const getValue = (config) =>
          get(
            this.state.values,
            `${localizeKey}.${config.code}.${localeFieldName || props.name}`,
          );

        const { values } = this.state;

        const defaultHiddenValue =
          get(values, localeFieldName || props.name) ||
          get(values, getName(defaultConfig));

        const otherActive = [];
        const otherHidden = [];

        for (const config of otherConfigs) {
          if (isActive(config)) {
            otherActive.push(config);
          } else {
            otherHidden.push(config);
          }
        }

        return (
          <div
            data-testid="rtl-formFieldLocalized"
            className={classNames('form-field-localized', props.className)}
          >
            {label && <Label htmlFor={fieldId} label={label} help={help} />}

            <div className="form-field-localized-group">
              <div className="form-field-localized-field">
                <div
                  className={classNames(
                    'form-field-localized-label',
                    props.type,
                  )}
                >
                  {otherActive.length > 0 && (
                    <Tooltip message={defaultConfig.name} dir={tooltipDir}>
                      {isCurrency ? (
                        defaultConfig.code
                      ) : (
                        <Icon locale={defaultConfig.code} />
                      )}
                    </Tooltip>
                  )}
                </div>

                <Field
                  type="hidden"
                  name={getName(defaultConfig)}
                  data-type={props.type}
                  data-index={dataIndex}
                  value={
                    defaultHiddenValue !== undefined
                      ? defaultHiddenValue
                      : defaultValue
                  }
                />

                {otherHidden.map(
                  (config) =>
                    get(
                      localeValue[config.code],
                      localeFieldName || props.name,
                    ) !== undefined && (
                      <Field
                        key={config.code}
                        type="hidden"
                        name={getName(config)}
                        data-type={props.type}
                        data-index={dataIndex}
                        value={
                          defaultValue !== undefined || value !== undefined
                            ? getValue(config)
                            : undefined
                        }
                      />
                    ),
                )}

                <Field
                  {...this.getFieldProps(
                    props,
                    defaultConfig,
                    defaultCode,
                    true,
                  )}
                  defaultValue={
                    useDefaultValue ? defaultValue : getValue(defaultConfig)
                  }
                  value={useDefaultValue ? value : undefined}
                  ref={this.inputRef}
                />
              </div>

              {otherActive.map((config) => (
                <FadeIn
                  transitionAppear={false}
                  className="form-field-localized-field"
                  key={config.code}
                >
                  <div
                    className={classNames(
                      'form-field-localized-label',
                      props.type,
                    )}
                  >
                    <Tooltip message={config.name} dir={tooltipDir}>
                      {isCurrency ? config.code : <Icon locale={config.code} />}
                    </Tooltip>
                  </div>

                  <Field
                    {...this.getFieldProps(props, config, defaultCode)}
                    name={getName(config)}
                    defaultValue={
                      defaultValue !== undefined ? getValue(config) : undefined
                    }
                    value={value !== undefined ? getValue(config) : undefined}
                  />
                </FadeIn>
              ))}
            </div>
          </div>
        );
      }

      const { values } = this.state;
      const defaultHiddenValue = values[localeFieldName || props.name];

      return (
        <div
          className={classNames(
            'form-field-localized',
            'without-group',
            props.className,
          )}
        >
          {label && <Label htmlFor={fieldId} label={label} help={help} />}

          <Field
            type="hidden"
            name={`${namePrefix}${localizeKey}.${defaultCode}.${
              localeFieldName || props.name
            }`}
            data-type={props.type}
            data-index={dataIndex}
            value={
              defaultHiddenValue !== undefined ? defaultHiddenValue : value
            }
          />

          <Field
            {...this.getFieldProps(props, defaultConfig, defaultCode, true)}
            defaultValue={defaultValue}
            value={value}
            ref={this.inputRef}
          />
        </div>
      );
    }

    const exLocaleValue =
      localeValue &&
      localeValue[defaultCode] &&
      get(localeValue[defaultCode], localeFieldName || props.name);

    const isCurrencyChanged = isCurrency && initialCurrency !== clientCurrency;

    return (
      <Fragment>
        {exLocaleValue !== undefined && (
          <Field
            type="hidden"
            name={`${namePrefix}${localizeKey}.${defaultCode}.${
              localeFieldName || props.name
            }`}
            data-type={props.type}
            data-index={dataIndex}
            value={value || defaultValue}
          />
        )}

        {
          // Overrides the record currency if it does not match the client currency.
          isCurrencyChanged && (
            <Field type="hidden" name="currency" value={clientCurrency} />
          )
        }

        <Field
          {...omit(props, 'hasLocalizedRules')}
          label={label}
          help={help}
          value={value}
          {
            // Prevents default value if the record currency is not the same as the client currency.
            ...(!isCurrencyChanged && {
              defaultValue,
            })
          }
          ref={this.inputRef}
          rules={this.getLocalizedRules(props)}
        />
      </Fragment>
    );
  }

  render() {
    const { type, user, localeTemp, currency } = this.props;
    const { initialTemp } = this.state;

    const { locale: clientLocale, currency: clientCurrency } =
      this.context.client;

    if (type === 'currency') {
      return this.renderFields({
        configs: this.getCurrencies(),
        defaultCode: currency || clientCurrency,
        userCodes: user.currencyCodes,
      });
    }

    return this.renderFields({
      configs: this.getLocales(),
      tempCode: initialTemp,
      defaultCode: (initialTemp === clientLocale && localeTemp) || clientLocale,
      userCodes: user.localeCodes,
    });
  }
}

export default connect(mapStateToProps, null, null, { withRef: true })(
  FieldLocalized,
);
