import React from 'react';

import { formatCurrencyRounded } from 'utils';
import Icon from 'components/icon';
import Payment from '../utils/payment';
import Date from '../utils/date';

export default function PaymentFailed({ event, settings }) {
  const { payment = {} } = event;

  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/payment" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          {formatCurrencyRounded(
            payment.amount || 0,
            payment.currency || 'USD',
          )}{' '}
          payment failed &nbsp;
          <Date date={event.date_created} />
        </div>
        <div className="activity-event-subtitle">
          <Payment data={payment} settings={settings} />
          &nbsp;
          {payment.error && payment.error.message && (
            <span className="muted note">&mdash; {payment.error.message}</span>
          )}
        </div>
      </div>
    </div>
  );
}
