import { testUrlSegment } from 'utils';

/**
 * @param {string} path
 * @returns {string}
 */
export function getPurchaseLinkIdFromPath(path) {
  const list = path.match(/^\/buy\/([^\/]+)\/?([^\/]+)?/);

  if (!list) {
    return {};
  }

  // Handle test url i.e. /buy/test-:env/:id
  if (String(list[1]).indexOf('test') === 0) {
    const envParts = list[1].split('-');
    const envId = envParts[1] ? envParts[1] : 'test';
    return { testEnv: envId, id: list[2] };
  }

  return list.length > 1 ? { id: list[1] } : {};
}

/**
 * @param {object?} purchaseLinks
 * @returns {string}
 */
export function getDomainFromSettings(purchaseLinks) {
  /** @type {Array<object>} */
  const list =
    purchaseLinks &&
    purchaseLinks.domains &&
    Array.isArray(purchaseLinks.domains.results)
      ? purchaseLinks.domains.results
      : [];

  const item = list.find((item) => item.primary && item.verified);

  if (item !== undefined) {
    return item.name;
  }

  if (list.length > 0) {
    return list[0].name;
  }

  return '';
}

/**
 * @param {string} id
 * @param {string?} domain
 * @returns {string}
 */
export function makePurchaseLinkUrl(id, domain = undefined) {
  const { TEST_ENV } = require('./');
  const host = domain || window.location.host;

  return `${window.location.protocol}//${host}/buy/${testUrlSegment(
    TEST_ENV,
  )}${id}`;
}
