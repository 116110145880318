import React from 'react';
import findIndex from 'lodash/findIndex';
import classNames from 'classnames';
import pt from 'prop-types';

import { categoriesHaveFoundChildren } from 'utils/category';
import {
  contentLookupRecordName,
  mergeQueryParams,
  getNameFieldExpand,
} from 'utils/content';

import { calculateTagsInputStyle } from 'utils/form';

import Icon from 'components/icon';
import Link from 'components/link';
import Loading from 'components/loading';
import { FadeIn, FadeInPop } from 'components/transitions';
import { COLLECTION_MODELS } from 'constants/content';

import Label from './label';

const ARR = Object.freeze([]);
// special identifier for inline item adding
const ADD_ITEM_ID = 'add_new_item';

/**
 * @param {object} props
 * @returns {string[] | string | null}
 */
function getInitialValue(props) {
  if (props.multiple) {
    if (Array.isArray(props.defaultValue)) {
      return props.defaultValue;
    }

    return ARR;
  }

  return props.defaultValue || null;
}

/**
 * @param {object} props
 * @returns {Map<string, boolean>}
 */
function getInitialValueIndex(props) {
  if (props.multiple && Array.isArray(props.defaultValue)) {
    const idx = props.defaultValue.reduce((index, value) => {
      if (!value) return index;

      index.set(value.id, true);

      let { parent } = value;

      while (parent) {
        index.set(parent.id, true);
        parent = parent.parent;
      }

      return index;
    }, new Map());

    return idx;
  }

  return new Map();
}

function isFound(props, selected) {
  const { lookup } = props;

  if (lookup.list && lookup.found) {
    return categoriesHaveFoundChildren([selected], lookup.found);
  }

  return true;
}

export default class InputLookup extends React.PureComponent {
  static propTypes = {
    model: pt.string,
    record: pt.object,
    lookup: pt.object,
    query: pt.object,
    // contentQuery is a user-defined query (via content models) that can be used
    // in addition to or extending the base query.
    contentQuery: pt.object,
    label: pt.string,
    help: pt.oneOfType([pt.node, pt.object]),
    multiple: pt.bool,
    disabled: pt.bool,
    scalarValue: pt.bool,
    queryFocus: pt.bool,
    defaultValue: pt.any,
    namePattern: pt.string,
    parentId: pt.string,
    newable: pt.bool,
    newValueText: pt.string,
    newValueFormName: pt.string,
    newValueFormParams: pt.object,
    readonlyContent: pt.bool,

    onQuery: pt.func,
    onChange: pt.func,
    renderNewLookupItem: pt.func,
    renderLookupItems: pt.func,
    renderValue: pt.func,
    onValueLink: pt.func,
  };

  static contextTypes = {
    queryLookupLoading: pt.func.isRequired,
    queryLookup: pt.func.isRequired,
    clearLookup: pt.func.isRequired,
    refreshModal: pt.func.isRequired,
    closeModal: pt.func,
    openModal: pt.func,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showing: false,
      showLoading: 0,
      selected: this.findNext(props),
      value: getInitialValue(props),
      valueIndex: getInitialValueIndex(props),
      inputValue: '',
    };

    /** @type {React.RefObject<HTMLDivElement>} */
    this.rootRef = React.createRef();
    /** @type {React.RefObject<HTMLInputElement>} */
    this.inputRef = React.createRef();
    /** @type {React.RefObject<HTMLUlElement>} */
    this.tagListRef = React.createRef();

    this.inputTimer = null;
    this.inputFocused = false;
    this.valueFocused = false;
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutsideComponent);

    this.forceUpdate();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutsideComponent);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lookup !== this.props.lookup) {
      this.setState({ selected: this.findNext(this.props) });
      this.context.refreshModal();
    }

    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState(
        {
          value: getInitialValue(this.props),
          valueIndex: getInitialValueIndex(this.props),
        },
        () => this.forceUpdate(),
      );
    }

    if (prevProps.parentId !== this.props.parentId && this.state.value) {
      this.setValue(null);
    }
  }

  /** @param {React.KeyboardEvent} event */
  onKeyDown = (event) => {
    if (this.props.disabled) {
      event.preventDefault();
      return;
    }

    switch (event.key) {
      case 'Enter': {
        this.selectValue();
        if (this.state.showing) {
          event.preventDefault();
        }
        break;
      }

      case 'Escape': {
        this.inputRef.current.value = '';
        this.hideLookup();
        break;
      }

      case 'ArrowUp':
      case 'ArrowLeft': {
        event.preventDefault();
        this.selectPrev();
        break;
      }

      case 'ArrowDown':
      case 'ArrowRight': {
        event.preventDefault();
        this.selectNext();
        break;
      }

      case 'Backspace': {
        if (this.props.multiple) {
          if (event.target.value.length <= 0) {
            this.removeValue(this.state.value.length - 1);
            event.target.value = '';
          }
        } else if (this.valueFocused) {
          this.setState({ value: null }, () => this.inputRef.current.focus());
        }

        break;
      }

      case ' ':
      case 'Spacebar': {
        if (event.target.value.length <= 0) {
          event.preventDefault();
        }

        break;
      }

      default:
        break;
    }
  };

  /** @param {React.KeyboardEvent} event */
  onKeyUp = (event) => {
    clearTimeout(this.inputTimer);

    if (event.key === 'Escape') {
      return;
    }

    this.inputTimer = setTimeout(() => {
      const inputValue = this.inputRef.current.value;
      const isChanged = inputValue !== this.state.inputValue;
      const showing = !!inputValue || this.state.showing;

      this.setState({ inputValue, showing });

      if (inputValue === '') {
        this.hideLookup();
        return;
      }

      if (isChanged && showing && inputValue !== '') {
        this.doQuery(inputValue);
      }
    }, 250);
  };

  async doQuery(inputValue) {
    this.setState((state) => ({ showLoading: state.showLoading + 1 }));

    this.context.queryLookupLoading(true);
    this.context.refreshModal();

    if (this.props.onQuery) {
      await this.props.onQuery(inputValue);
    } else {
      await this.context.queryLookup(this.props.model, {
        limit: 15,
        ...this.queryWithNameExpand(this.props.query),
        ...this.queryWithParentId(),
        search: inputValue,
      });
    }

    this.context.queryLookupLoading(false);
    this.context.refreshModal();

    this.setState((state) => ({ showLoading: state.showLoading - 1 }));
  }

  queryWithNameExpand(query = {}) {
    const { record, namePattern } = this.props;
    const q = query || {};

    const contentQuery =
      mergeQueryParams(this.props.contentQuery, record) || {};

    if (contentQuery.include) {
      // Don't override include query
      contentQuery.include = this.props.contentQuery.include;
    }

    let expand = [...(q.expand || []), ...(contentQuery.expand || [])];

    const model = COLLECTION_MODELS[this.props.model];

    if (namePattern) {
      expand = [
        ...expand,
        ...getNameFieldExpand({ name_pattern: this.props.namePattern }),
      ];
    } else if (model && model.nameFieldExpand) {
      expand = [...expand, ...model.nameFieldExpand];
    }

    return {
      ...contentQuery,
      ...q,
      expand,
    };
  }

  queryWithParentId() {
    if (this.props.parentId) {
      return { parent_id: this.props.parentId };
    }

    return {};
  }

  hideLookup() {
    const { queryFocus } = this.props;
    const { value, showing } = this.state;
    const shouldHide = !this.inputFocused || !queryFocus || value;

    if (shouldHide) {
      this.setState({ showing: false });

      setTimeout(() => {
        if (!showing) {
          this.context.clearLookup();
        }
      }, 250);
    }
  }

  focus() {
    this.inputRef.current.focus();
  }

  onFocus = () => {
    this.inputFocused = true;

    if (this.inputRef.current.value && this.props.multiple) {
      this.setState({ showing: true });
    }

    if (this.props.queryFocus && !this.state.value) {
      this.setState({ showing: true });
      this.doQuery(this.inputRef.current?.value || '');
    }
  };

  onBlur = () => {
    this.inputFocused = false;
  };

  onFocusValue = () => {
    this.valueFocused = true;
  };

  onBlurValue = () => {
    this.valueFocused = false;
  };

  /** @param {React.MouseEvent} event */
  onClickRemove = (event) => {
    event.preventDefault();

    if (this.props.multiple === true) {
      this.removeValue(event.currentTarget.dataset.index);
      this.inputRef.current.focus();
    } else {
      this.setValue(null);
    }
  };

  setValue(value) {
    const event = new Event('change', { bubbles: true });

    this.setState({ value, inputValue: '' }, () => {
      if (!value) {
        this.inputRef.current.focus();
      }
    });

    this.inputRef.current.dispatchEvent(event);

    if (this.props.scalarValue && value) {
      value = value.id || value;
    }

    this.props.onChange(event, value);
  }

  addValue(value) {
    const event = new Event('change', { bubbles: true });

    const { valueIndex } = this.state;
    valueIndex.set(value.id, true);
    this.addListValue(value, valueIndex);

    let next = this.state.value ? [...this.state.value] : [];
    next.push(value);

    this.setState({ value: next, valueIndex: new Map(valueIndex) }, () =>
      this.forceUpdate(),
    );

    this.inputRef.current.dispatchEvent(event);

    if (this.props.scalarValue && next) {
      next = next.id || next;
    }

    this.props.onChange(event, next);
  }

  /**
   * @param {object} value
   * @param {Map<string, boolean>} valueIndex
   * @returns {void}
   */
  addListValue(value, valueIndex) {
    let { parent } = value;

    while (parent) {
      valueIndex.set(parent.id, true);
      parent = parent.parent;
    }
  }

  removeValue(index) {
    const event = new Event('change', { bubbles: true });

    const { valueIndex } = this.state;

    const value = this.state.value[index];

    if (value) {
      this.removeListValue(value, valueIndex);
    }

    const next = [...this.state.value];
    next.splice(index, 1);

    this.setState(
      { value: next, valueIndex: new Map(valueIndex), inputValue: '' },
      () => this.forceUpdate(),
    );

    this.inputRef.current.dispatchEvent(event);
    this.props.onChange(event, next);
  }

  /**
   * @param {object} value
   * @param {Map<string, boolean>} valueIndex
   * @returns {void}
   */
  removeListValue(value, valueIndex) {
    if (value.children) {
      for (const child of value.children) {
        if (valueIndex.get(child.id)) {
          return;
        }
      }
    }

    valueIndex.delete(value.id);

    let { parent } = value;

    while (parent) {
      if (categoriesHaveFoundChildren(parent.children, { index: valueIndex })) {
        break;
      }

      if (!this.state.value.includes(parent)) {
        valueIndex.delete(parent.id);
      }

      parent = parent.parent;
    }
  }

  selectValue() {
    const { showing, selected } = this.state;

    if (showing && selected === ADD_ITEM_ID) {
      this.onCreateNewValue();
      return;
    }

    if (showing && selected) {
      if (this.props.multiple === true) {
        const exIndex = findIndex(this.state.value, { id: selected.id });

        if (exIndex !== -1) {
          this.removeValue(exIndex);
        } else {
          this.addValue(selected);
        }
      } else {
        this.setValue(selected);
        this.hideLookup();
      }

      this.inputRef.current.value = '';
    }
  }

  findNext(props, selected = null, isLastChild = false) {
    const { lookup } = props;

    const results = lookup.list || lookup.results;

    if (selected && selected !== ADD_ITEM_ID) {
      if (selected.children && !isLastChild) {
        for (const child of selected.children) {
          if (isFound(props, child)) {
            return child;
          }
        }
      } else if (selected.parent) {
        const index = selected.parent.children.indexOf(selected);

        if (index < selected.parent.children.length - 1) {
          for (let i = index + 1; i < selected.parent.children.length; ++i) {
            const child = selected.parent.children[i];

            if (isFound(props, child)) {
              return child;
            }
          }
        } else {
          return this.findNext(props, selected.parent, true);
        }
      } else {
        const index = results.indexOf(selected);

        for (let i = index + 1; i < results.length; ++i) {
          const result = results[i];

          if (isFound(props, result)) {
            return result;
          }
        }
      }
    } else if (results) {
      for (const result of results) {
        if (isFound(props, result)) {
          return result;
        }
      }
    }

    return null;
  }

  findPrev(props, selected = null) {
    const { lookup } = props;

    const results = lookup.list || lookup.results;

    if (selected && selected !== ADD_ITEM_ID) {
      if (selected.parent) {
        const index = selected.parent.children.indexOf(selected);

        if (index > 0) {
          for (let i = index - 1; i >= 0; --i) {
            const child = selected.parent.children[i];

            if (isFound(props, child)) {
              return child;
            }
          }
        } else {
          if (index === 0 && isFound(props, selected.parent)) {
            return selected.parent;
          }

          return this.findPrev(props, selected.parent, true);
        }
      } else {
        const index = results.indexOf(selected);

        for (let i = index - 1; i >= 0; --i) {
          const result = results[i];

          if (isFound(props, result)) {
            let lastChild = result;

            while (lastChild) {
              if (lastChild.children) {
                lastChild = lastChild.children[lastChild.children.length - 1];
              } else {
                return lastChild;
              }
            }
          }
        }
      }
    } else if (results) {
      return this.findNext(props);
    }

    return null;
  }

  selectNext() {
    const { selected } = this.state;

    if (selected) {
      const next = this.findNext(this.props, selected);

      if (next !== null) {
        this.setState({ selected: next });
      }
    }
  }

  selectPrev() {
    const { selected } = this.state;

    if (selected) {
      const prev = this.findPrev(this.props, selected);

      if (prev !== null) {
        this.setState({ selected: prev });
      }
    }
  }

  onClickSelectValue = (event) => {
    event.preventDefault();
    this.selectValue();

    const modals = document.getElementsByClassName('modal');

    const main = modals.length
      ? modals[modals.length - 1]
      : document.getElementById('main');

    const top = main.scrollTop;
    main.scrollTop = top;
  };

  onMouseOverValue = (event) => {
    const { lookup } = this.props;
    const { id } = event.currentTarget.dataset;

    if (!id) return;

    if (id === ADD_ITEM_ID) {
      this.setState({ selected: ADD_ITEM_ID });
      return;
    }

    if (lookup.list) {
      this.setState({ selected: lookup.index.get(id) });
    } else {
      const index = findIndex(lookup.results, { id });

      if (index >= 0) {
        this.setState({ selected: lookup.results[index] });
      }
    }
  };

  /** @param {React.MouseEvent} event */
  onClickOutsideComponent = (event) => {
    if (!this.rootRef.current?.contains(event.target)) {
      if (this.state.showing) {
        this.inputRef.current.value = '';
        this.hideLookup();
      }
    }
  };

  onCreateNewValue = () => {
    const inputValue = this.inputRef.current?.value;

    if (!inputValue) {
      return;
    }

    const { newValueFormName, newValueFormParams } = this.props;

    const formParams = { ...newValueFormParams };
    const { onCreate } = formParams;

    if (onCreate) {
      formParams.onCreate = (result) => {
        this.context.closeModal();
        return onCreate(result);
      };
    }

    this.context.openModal(newValueFormName, {
      record: {
        name: inputValue,
      },
      params: formParams,
      createItemInline: true,
      onItemCreated: (newItem) => {
        this.addValue(newItem);
        this.context.closeModal(newValueFormName);
      },
    });
  };

  renderNewLookupItem(inputValue) {
    const { selected: selectedItem } = this.state;
    const value = this.props.newValueText || `New: ${inputValue}`;

    // use provided render function if possible
    if (this.props.renderNewLookupItem) {
      return this.props.renderNewLookupItem({
        value,
        newItemId: ADD_ITEM_ID,
        selected: selectedItem,
        onClick: this.onCreateNewValue,
        onMouseOver: this.onMouseOverValue,
      });
    }

    const selected = ADD_ITEM_ID === selectedItem;

    return (
      <li
        key={ADD_ITEM_ID}
        role="option"
        data-id={ADD_ITEM_ID}
        aria-selected={selected}
        className={classNames(
          'item',
          selected ? 'selected' : 'new-inline-item',
        )}
        onMouseOver={this.onMouseOverValue}
        onClick={this.onCreateNewValue}
      >
        <span className="col padded_item_col">
          <Icon
            fa="plus-circle"
            faType="solid"
            className="new-inline-item-icon"
          />

          {value}
        </span>
      </li>
    );
  }

  renderLookupItems() {
    const { lookup } = this.props;
    const { selected, value, valueIndex, showLoading } = this.state;

    const items = this.props.renderLookupItems
      ? this.props.renderLookupItems({
          lookup,
          selected,
          value,
          valueIndex,
          onClick: this.onClickSelectValue,
          onMouseOver: this.onMouseOverValue,
        })
      : lookup.results.map((result) => {
          const name = this.getNameValue(result);
          const itemSelected = result === selected;

          return (
            <li
              key={result.id}
              role="option"
              data-id={result.id}
              aria-selected={itemSelected}
              aria-label={name}
              className={classNames('item', { selected: itemSelected })}
              onMouseOver={this.onMouseOverValue}
              onClick={this.onClickSelectValue}
            >
              <span className="col">
                {valueIndex.size > 0 && (
                  <FadeIn
                    className="check"
                    transitionAppear={false}
                    active={valueIndex.has(result.id)}
                  >
                    <Icon fa="check" />
                  </FadeIn>
                )}

                {name}
              </span>
            </li>
          );
        });

    const inputValue = this.inputRef.current?.value || '';
    const findValue = inputValue.trim().toUpperCase();

    if (this.props.newable && findValue) {
      if (
        !lookup.results.some(
          (result) => (result.name || '').trim().toUpperCase() === findValue,
        )
      ) {
        items.push(this.renderNewLookupItem(inputValue));
      }
    }

    if (items.length <= 0 || showLoading > 0) {
      if (lookup.loading) {
        return (
          <li className="item">
            <Loading />
          </li>
        );
      }

      if (this.props.newable && findValue) {
        return this.renderNewLookupItem(inputValue);
      }

      return (
        <li className="item">
          <span className="col muted">None found</span>
        </li>
      );
    }

    return items;
  }

  getNameValue(values) {
    const { model, namePattern } = this.props;
    return contentLookupRecordName(model, values, undefined, namePattern);
  }

  renderValue(value) {
    const { renderValue, onValueLink } = this.props;
    const renderedValue = renderValue
      ? renderValue(value, this.state)
      : this.getNameValue(value);

    const valueLink = onValueLink ? onValueLink(value) : null;

    return valueLink ? (
      <Link to={valueLink}>{renderedValue}</Link>
    ) : (
      renderedValue
    );
  }

  render() {
    const {
      record,
      label,
      help,
      model,
      lookup,
      query,
      queryFocus,
      onQuery,
      contentQuery,
      multiple,
      renderValue,
      renderLookupItems,
      parentId,
      namePattern,
      scalarValue,
      newable,
      newValueText,
      newValueFormName,
      newValueFormParams,
      renderNewLookupItem,
      readonlyContent,
      onValueLink,
      ...props
    } = this.props;

    const { value, showing } = this.state;

    const listboxId = `${props.id || props.name}-listbox`;

    return (
      <div ref={this.rootRef} className="form-lookup form-tags">
        {label && <Label label={label} help={help} htmlFor={props.id} />}

        <div className="form-field-input">
          <input
            {...props}
            type="text"
            role="combobox"
            ref={this.inputRef}
            aria-controls={listboxId}
            aria-expanded={showing}
            value={undefined}
            defaultValue={undefined}
            onChange={undefined}
            onKeyDown={this.onKeyDown}
            onKeyUp={this.onKeyUp}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            autoComplete="off"
            tabIndex={value ? -1 : 0}
            disabled={!multiple && value ? true : props.disabled}
            className={classNames('form-tags-input', props.className, {
              disabled: props.disabled,
            })}
            placeholder={
              !value || !value.length ? props.placeholder : undefined
            }
            style={
              multiple
                ? calculateTagsInputStyle(
                    this.inputRef.current,
                    this.tagListRef.current,
                  )
                : undefined
            }
          />

          {!multiple && <Icon fa="search" />}

          {multiple ? (
            <ul ref={this.tagListRef} className="form-tags-list">
              {(value || []).map((val, index) => {
                const renderedValue = this.renderValue(val);

                return (
                  renderedValue && (
                    <li key={val.id}>
                      {renderedValue}

                      <button
                        data-index={index}
                        className="remove"
                        onClick={this.onClickRemove}
                        type="button"
                      >
                        <Icon fa="times" />
                      </button>
                    </li>
                  )
                );
              })}
            </ul>
          ) : (
            value && (
              <div
                tabIndex={props.disabled ? -1 : 0}
                className="form-lookup-value input"
                onKeyDown={this.onKeyDown}
                onFocus={this.onFocusValue}
                onBlur={this.onBlurValue}
              >
                {this.renderValue(value)}

                {!props.disabled && (
                  <button
                    className="remove"
                    onClick={this.onClickRemove}
                    type="button"
                  >
                    <Icon fa="times" />
                  </button>
                )}
              </div>
            )
          )}

          <div className="form-lookup-list-container">
            <FadeInPop
              active={showing}
              component="ul"
              className={classNames('form-lookup-list', {
                'form-lookup-loading': lookup.loading,
              })}
              id={listboxId}
              origin="top left"
              role="listbox"
            >
              {this.renderLookupItems()}
            </FadeInPop>
          </div>
        </div>
      </div>
    );
  }
}
