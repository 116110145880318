import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Field, FieldLocalized } from 'components/form';
import Icon from 'components/icon';
import Link from 'components/link';
import { FadeIn } from 'components/transitions';

import { formatCurrency, inflect } from 'utils';

import { productThumbUrl } from 'utils/product';

const LOOKUP_MODELS = ['products', 'categories'];
const LOOKUP_QUERIES = {
  products: {
    fields: 'name, price, currency, date_created, images',
  },
  categories: {
    fields: 'name, date_created',
    include: {
      product_count: {
        url: '/categories:products/:count',
        params: {
          parent_id: 'id',
        },
      },
    },
  },
};

// Not pure
export default class BuyGetRuleForm extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.getProductLookupItemsRender = this.productLookupItemsRender.bind(this);
  }

  state = {
    mounted: false,
  };

  componentDidMount() {
    this.setState({ mounted: true });
  }

  productLookupItemsRender({ lookup, ...props }) {
    const valueIndex = props.valueIndex || {};
    return lookup.results.map((record) => (
      <li
        key={record.id}
        role="option"
        data-id={record.id}
        aria-selected={record.id === props.selected?.id}
        onClick={props.onClick}
        onMouseOver={props.onMouseOver}
        className={`item ${
          record.id === props.selected?.id ? 'selected' : null
        }`}
      >
        <span className="col" style={{ paddingLeft: 42 }}>
          <FadeIn
            className="check"
            transitionAppear={false}
            active={valueIndex[record.id]}
          >
            <Icon fa="check" />
          </FadeIn>
          <span className="image">
            <img alt="" src={productThumbUrl(record)} />
          </span>
          {record.name}
        </span>
        <span className="col muted">
          {record._model === 'products' ? 'Product' : 'Category'}
        </span>
        <span className="col muted">
          {record._model === 'products'
            ? formatCurrency(record.price, record.currency)
            : inflect(record.product_count, 'product')}
        </span>
        <span className="col ">{record.sku}</span>
      </li>
    ));
  }

  renderItemsTable = (items, field) => {
    const { onRemoveBuyGetDiscountItem } = this.props;
    if (!items.length) {
      return null;
    }
    return (
      <div className="collection-table-container">
        <table className="collection-table discount-buy-get-rule-table">
          <thead>
            <tr>
              <th className="link">Title</th>
              <th>Value</th>
              <th>Type</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              const isProduct = item._model === 'products';
              return (
                <tr key={item.id} data-id={item.id} data-model={item._model}>
                  <td data-id={item.id}>
                    <Link
                      to={
                        isProduct
                          ? `/products/${item.id}`
                          : `/categories/${item.id}`
                      }
                    >
                      {item.name}
                    </Link>
                  </td>
                  <td data-id={item.id}>
                    <span>
                      {isProduct
                        ? formatCurrency(item.price, item.currency)
                        : `${inflect(item.product_count, 'product')}`}
                    </span>
                  </td>
                  <td data-id={item.id}>
                    <span>{isProduct ? 'Product' : 'Category'}</span>
                  </td>
                  <td data-id={item.index}>
                    <a
                      href=""
                      data-id={item.id}
                      data-name={field}
                      onClick={onRemoveBuyGetDiscountItem}
                    >
                      Remove
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { record = {}, values = {}, path = '', lookup } = this.props;
    const { mounted } = this.state;
    const { client } = this.context;
    const currency = record.currency || client.currency;
    const valueConditionType = values.condition_type || 'total_min';
    const valueDiscountType = values.discount_type || 'items';
    const buyItems = values.buy_items || [];
    const getItems = values.get_items || [];

    return (
      <Fragment>
        <div className="view-body-subsection-group">
          <div className="view-body-subsection">
            <div className="view-body-subheader">
              <div>
                <h3 className="view-body-subtitle">Customer buys</h3>
                <p>Items that activate the discount</p>
              </div>
            </div>
            <div className="subsection-content">
              <fieldset>
                <div className="row">
                  <Field
                    type="select"
                    name={`${path}condition_type`}
                    label="Minimum"
                    defaultValue={valueConditionType}
                    options={[
                      { value: 'total_min', label: 'Purchase amount' },
                      { value: 'quantity_min', label: 'Quantity' },
                    ]}
                    className="span2"
                  />
                  {valueConditionType === 'total_min' ? (
                    <FieldLocalized
                      label="Amount"
                      type="currency"
                      name={`${path}total_min`}
                      defaultValue={values.total_min}
                      currency={currency}
                      localeValue={values.$currency}
                      localeFieldPrefix={path.replace('.', '')}
                      localeFieldName="total_min"
                      required={true}
                      selectFocus={true}
                      className="span2"
                    />
                  ) : (
                    <Field
                      label="Quantity"
                      type="number"
                      name={`${path}quantity_min`}
                      defaultValue={values.quantity_min || 1}
                      required={true}
                      selectFocus={true}
                      className="span2"
                    />
                  )}
                </div>
                <FadeIn transitionAppear={mounted}>
                  <div className="row">
                    <Field
                      type="finder"
                      name={`${path}buy_items`}
                      placeholder="Pick products and categories"
                      className="span4"
                      models={LOOKUP_MODELS}
                      queries={LOOKUP_QUERIES}
                      renderLookupItems={this.productLookupItemsRender}
                      lookup={lookup}
                      defaultValue={buyItems}
                      required={true}
                    />
                  </div>
                </FadeIn>
              </fieldset>
              {this.renderItemsTable(buyItems, 'buy_items')}
            </div>
          </div>
          <div className="view-body-subsection">
            <div className="view-body-subheader">
              <div>
                <h3 className="view-body-subtitle">Customer gets</h3>
                <p>Items available at a discount when activated</p>
              </div>
            </div>
            <div className="subsection-content">
              <fieldset>
                <div className="row">
                  <Field
                    type="select"
                    name={`${path}discount_type`}
                    label="Discount type"
                    defaultValue={valueDiscountType}
                    options={[
                      { value: 'items', label: '% off selected items' },
                      { value: 'total', label: '% off entire cart' },
                    ]}
                    className={
                      valueDiscountType === 'items' ? 'span1-3rd' : 'span2'
                    }
                  />
                  <Field
                    label="Amount (%)"
                    type="number"
                    name={`${path}value_percent`}
                    defaultValue={values.value_percent}
                    required={true}
                    selectFocus={true}
                    className={
                      valueDiscountType === 'items' ? 'span1-3rd' : 'span2'
                    }
                  />
                  {valueDiscountType === 'items' && (
                    <Field
                      label="Up to quantity"
                      type="number"
                      name={`${path}quantity_max`}
                      defaultValue={values.quantity_max || 1}
                      required={true}
                      selectFocus={true}
                      scale={0}
                      className="span1-3rd"
                    />
                  )}
                </div>
                {valueDiscountType === 'items' && (
                  <FadeIn transitionAppear={mounted}>
                    <div className="row">
                      <Field
                        type="finder"
                        name={`${path}get_items`}
                        placeholder="Pick products and categories"
                        className="span4"
                        models={LOOKUP_MODELS}
                        queries={LOOKUP_QUERIES}
                        renderLookupItems={this.productLookupItemsRender}
                        lookup={lookup}
                        defaultValue={getItems}
                      />
                    </div>
                  </FadeIn>
                )}
                {this.renderItemsTable(getItems, 'get_items')}
              </fieldset>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
