import { get, find } from 'lodash';

import { currencyValue } from 'utils';

function exportAttribute(attr, value) {
  if (attr.type === 'image' || attr.type === 'file') {
    if (value instanceof Array) {
      return value.map((val) => get(val, 'file.url', '')).join('\n');
    }
    return get(value, 'file.url', '');
  }
  if (value instanceof Array) {
    return value.join('\n');
  }
  return value;
}

function exportVariantOptions(product, variant) {
  if (!product.options) {
    return;
  }
  const result =
    variant.option_value_ids &&
    variant.option_value_ids
      .reduce((acc, opValId) => {
        for (let option of product.options) {
          const opVal = find(option.values, { id: opValId });
          if (opVal) {
            // TODO: escape commas
            return [...acc, `${option.name}: ${opVal.name}`];
          }
        }
        return acc;
      }, [])
      .join('\n');
  return result;
}

function exportOptions(product) {
  if (!product.options) {
    return;
  }

  const result = product.options;

  return exportJSON(result);
}

function exportJSON(data) {
  if (data instanceof Array) {
    return data.map((obj) => JSON.stringify(obj)).join('\n');
  }
  return data ? JSON.stringify(data) : undefined;
}

const actions = {
  exportActive: (data) =>
    data.variants.results.reduce(
      (rows, variant) => rows.concat(variant.active ? 'true' : 'false'),
      [data.active && data.active !== 'false' ? 'true' : 'false'],
    ),
  exportAttributes: (data, attr) =>
    data.variants.results.reduce(
      (rows, variant) =>
        rows.concat([
          exportAttribute(
            attr,
            variant.attributes && variant.attributes[attr.id],
          ),
        ]),
      [exportAttribute(attr, data.attributes && data.attributes[attr.id])],
    ),
  exportBundleItems: (data) =>
    exportJSON(
      data?.map((item) => {
        const { product, quantity, variable, variant_id, options } = item || {};
        return {
          product_id: product?.id || null,
          quantity: quantity || null,
          variable: variable || null,
          variant_id: variant_id || null,
          options: options || [],
        };
      }) || [],
    ),
  exportCategories: (data, categories) =>
    data &&
    data
      .map((categoryProduct) => {
        let category = categories.index.get(categoryProduct.parent_id);
        if (category) {
          let path = category.name;
          while (category.parent) {
            path = `${category.parent.name} > ${path}`;
            category = category.parent;
          }
          return path;
        }
        return null;
      })
      .filter((path) => path)
      .join('\n'),
  exportCost: (data) =>
    data.variants.results.reduce(
      (rows, variant) =>
        rows.concat(
          variant.cost !== null && variant.cost !== undefined
            ? currencyValue(variant.cost, data.currency)
            : undefined,
        ),
      [currencyValue(data.cost, data.currency)],
    ),
  exportId: (data) =>
    data.variants.results.reduce(
      (rows, variant) => rows.concat(variant.id),
      [data.id],
    ),
  exportImages: (data) =>
    data.variants.results.reduce(
      (rows, variant) =>
        rows.concat(
          variant.images &&
            variant.images.map((img) => img.file.url).join('\n'),
        ),
      [data.images && data.images.map((img) => img.file.url).join('\n')],
    ),
  exportPrice: (data) =>
    data.variants.results.reduce(
      (rows, variant) =>
        rows.concat(
          variant.price !== null && variant.price !== undefined
            ? currencyValue(variant.price, data.currency)
            : undefined,
        ),
      [currencyValue(data.price, data.currency)],
    ),
  exportPrices: (data) =>
    data.variants.results.reduce(
      (rows, variant) => rows.concat(exportJSON(variant.prices)),
      [exportJSON(data.prices)],
    ),
  exportSale: (data) =>
    data.variants.results.reduce(
      (rows, variant) => rows.concat(variant.sale ? 'true' : 'false'),
      [data.sale && data.sale !== 'false' ? 'true' : 'false'],
    ),
  exportSalePrice: (data) =>
    data.variants.results.reduce(
      (rows, variant) =>
        rows.concat(
          variant.sale_price !== null && variant.sale_price !== undefined
            ? currencyValue(variant.sale_price, data.currency)
            : undefined,
        ),
      [currencyValue(data.sale_price, data.currency)],
    ),
  exportShippingPrices: (data) => exportJSON(data),
  exportShippingWeight: (data) =>
    data.variants.results.reduce(
      (rows, variant) => rows.concat(variant.shipment_weight),
      [data.shipment_weight],
    ),
  exportSku: (data) =>
    data.variants.results.reduce(
      (rows, variant) => rows.concat(variant.sku),
      [data.sku],
    ),
  exportStockLevel: (data) =>
    data.variants.results.reduce(
      (rows, variant) => rows.concat(variant.stock_level),
      [data.stock_level],
    ),
  exportTags: (data) => data && data.join(', '),
  exportVariantOptions: (data) =>
    data.variants.results.reduce(
      (rows, variant) => rows.concat(exportVariantOptions(data, variant)),
      [''],
    ),
  exportProductOptions: (data) => exportOptions(data),
  exportPurchaseOptions: (data) =>
    data.variants.results.reduce(
      (rows, variant) => rows.concat(exportJSON(variant.purchase_options)),
      [exportJSON(data.purchase_options)],
    ),
  exportLocaleOptions: (data) =>
    data.variants.results.reduce(
      (rows, variant) => rows.concat(exportJSON(variant.$locale)),
      [exportJSON(data.$locale)],
    ),
};

export default actions;
