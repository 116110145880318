import React from 'react';
import { connect } from 'react-redux';

import EditPage from 'components/pages/modals/edit-model';

import { EditModel, mapStateToProps, mapDispatchToProps } from '../EditModel';

export class EditModelModal extends EditModel {
  static contextTypes = {
    ...EditModel.contextTypes,
  };

  render() {
    return <EditPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditModelModal);
