import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import EditModalPage from 'components/pages/modals/edit-attribute';

import {
  EditAttribute,
  mapStateToProps,
  mapDispatchToProps,
} from '../EditAttribute';

class EditAttributeModal extends EditAttribute {
  static contextTypes = {
    notifyError: pt.func.isRequired,
    notifyDeleted: pt.func.isRequired,
    openModal: pt.func.isRequired,
    closeModal: pt.func.isRequired,
  };

  static modal = true;

  onSubmitModalRecord = (values) => {
    return this.onSubmitRecord(values).then(() => {
      this.context.closeModal();
    });
  };

  onClickDelete(event) {
    event.preventDefault();

    this.context.openModal('ConfirmDelete', {
      title: this.props.record.name,
      onConfirm: async () => {
        const { params, deleteRecord, removeAttributeFromStore } = this.props;
        const result = deleteRecord(params.id);
        if (result && !result.errors) {
          await removeAttributeFromStore(params.id);
          this.context.notifyDeleted('Attribute');
          this.context.closeModal();
        }
      },
    });
  }

  render() {
    return (
      <EditModalPage
        onSubmitRecord={this.onSubmitModalRecord}
        {...this.props}
        {...this.state}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAttributeModal);
