import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, Fieldtable } from 'components/form';
import ContentField from 'components/content/field';
import { snakeCase } from 'utils';

export default class NotificationFields extends React.PureComponent {
  static contextTypes = {
    onChangeField: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.renderFieldValue = this.renderFieldValue.bind(this);
    this.renderFieldForm = this.renderFieldForm.bind(this);
    this.onSubmitModal = this.onSubmitModal.bind(this);
  }

  onSubmitModal(values) {
    if (!values.id) {
      values.id = snakeCase(values.label);
    }
  }

  renderFieldHeading() {
    return [
      <th key="1">Label</th>,
      <th key="2">Content ID</th>,
      <th key="3">Default value</th>,
    ];
  }

  renderFieldValue({ value }) {
    return [
      <td key="1">
        <span className="nowrap">{value.label}</span>
      </td>,
      <td key="2">
        <span>{value.id}</span>
      </td>,
      <td key="3">
        <span>{value.default || <span className="muted">&mdash;</span>}</span>
      </td>,
    ];
  }

  renderFieldForm(values, index, allValues, isNew) {
    return (
      <Fragment>
        <div className="row">
          <Field
            type="text"
            name="label"
            label="Label"
            defaultValue={values.label}
            required={true}
            className="span2"
          />
          <Field
            type="text"
            name="id"
            label="ID"
            defaultValue={values.id}
            placeholder={snakeCase(values.label)}
            help="Used to reference this field in the notification template"
            className="span2"
          />
        </div>
        <div className="row">
          <Field
            type="select"
            name="type"
            label="Type"
            options={[
              { value: 'short_text', label: 'Short text' },
              { value: 'long_text', label: 'Long text' },
              { value: 'basic_html', label: 'Basic HTML' },
              { value: 'rich_html', label: 'Rich HTML' },
            ]}
            defaultValue={values.type || 'short_text'}
            required={true}
            className="span2"
          />
          <div />
        </div>
        <div className="row">
          <ContentField
            type={values.type || 'short_text'}
            name="default"
            label="Default value"
            defaultValue={values.default}
            localeValue={values.$locale}
            placeholder="Optional"
            className="span4"
            localized={true}
          />
        </div>
      </Fragment>
    );
  }

  renderEditing() {
    const { values, onSubmit, mountAddRow } = this.props;

    return (
      <Fieldtable
        className="white-space-wrap"
        label="Field"
        name="fields"
        formWidth={600}
        mountAddRow={mountAddRow}
        onSubmit={onSubmit}
        onSubmitModal={this.onSubmitModal}
        defaultValue={values.fields}
        renderHeading={this.renderFieldHeading}
        renderValue={this.renderFieldValue}
        renderForm={this.renderFieldForm}
        sortable={true}
        getRef={(fieldtable) => (this.fieldtable = fieldtable)}
        renderModalActions={this.renderModalActions}
        localized={true}
      />
    );
  }

  renderInput() {
    const fields = this.props.values.fields || [];
    return (
      <Fragment>
        <Field type="hidden" name="fields" value={this.props.values.fields} />
        {fields.length > 0 ? (
          <div className="collection-table-container">
            <table
              className={`
              collection-table fieldtable-inline aligned
            `}
            >
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {fields.map((field, i) => (
                  <tr key={field.id}>
                    <td>
                      <div className="collection-field-padded">
                        {field.label}
                        <br />
                        <span className="note muted nowrap">{`{{ content.${field.id} }}`}</span>
                      </div>
                    </td>
                    <td width="75%">
                      <div className="collection-field-padded">
                        <ContentField
                          type={field.type}
                          name={`fields.${i}.value`}
                          defaultValue={
                            field.value && field.value.length > 0
                              ? field.value
                              : field.default
                          }
                          localeValue={field.$locale}
                          localeFieldPrefix={`fields.${i}`}
                          localeFieldName="value"
                          placeholder={field.default}
                          localized={true}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="collection-none-found">
            No fields defined for this template
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const { editing } = this.props;

    if (editing) {
      return this.renderEditing();
    }

    return this.renderInput();
  }
}
