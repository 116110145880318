import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import Modal from 'components/modal';
import { Form, Field, Tabs, TabView } from 'components/form';
import Link from 'components/link';
import Loading from 'components/loading';
import PrevButton from 'components/button/prev';
import NextButton from 'components/button/next';
import { FadeIn } from 'components/transitions';
import Tooltip from 'components/tooltip';
import { formatCurrency, formatDate } from 'utils';
import { renderMultiCurrencyValuesTooltip } from 'utils/money';

const REASON_OPTIONS = [
  { label: 'Promotional credit', value: 'promo' },
  { label: 'Customer service incident', value: 'incident' },
  { label: 'Other', value: 'other' },
];

export default class CustomerCredits extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object,
  };

  state = {
    values: {
      adjust: 'add',
    },
  };

  onChangeForm = (values) => {
    this.setState({
      values: {
        ...this.state.values,
        ...values,
      },
      willBeBalanceNegative: this.checkWillBeBalanceNegative(values),
    });
  };

  renderReasonHistory(credit) {
    if (credit.reason) {
      const reason = find(REASON_OPTIONS, { value: credit.reason });
      if (reason) {
        return reason.label;
      }
    }
    if (credit.giftcard_id) {
      return <Link to={`/giftcards/${credit.giftcard_id}`}>Gift card</Link>;
    }
    if (credit.payment && credit.payment.order) {
      return (
        <Link to={`/orders/${credit.payment.order.id}`}>
          Order {credit.payment.order.number}
        </Link>
      );
    }
    if (credit.refund && credit.refund.order) {
      return (
        <Link to={`/orders/${credit.refund.order.id}`}>
          Order {credit.refund.order.number}
        </Link>
      );
    }
  }

  renderReasonMessage(credit) {
    if (!credit.reason_message) {
      return null;
    }
    return (
      <Tooltip message={credit.reason_message}>
        <span className="help icon icon-info">&nbsp;</span>
      </Tooltip>
    );
  }

  getCreditCurrencies() {
    const { record } = this.props;
    const {
      client: { pricedCurrencies },
    } = this.context;
    const creditCurrencies = [...pricedCurrencies.map((curr) => curr.code)];
    if (creditCurrencies.indexOf(record.currency) === -1) {
      creditCurrencies.unshift(record.currency);
    }
    const valueCodes = Object.keys(record.$currency || {});
    for (const code of valueCodes) {
      if (creditCurrencies.indexOf(code) === -1) {
        creditCurrencies.push(code);
      }
    }
    return creditCurrencies;
  }

  checkWillBeBalanceNegative(values) {
    const { record } = this.props;

    return (
      values.amount &&
      ((values.adjust !== 'add' && record.balance < values.amount) ||
        (values.adjust === 'add' && record.balance + values.amount < 0))
    );
  }

  render() {
    const {
      record,
      related: { credits },
      onClickCredits,
      onSubmitCreditAdjustment,
      onClickCreditPagePrev,
      onClickCreditPageNext,
      creditPage,
    } = this.props;
    const {
      values: { adjust, amount, reason, tab, currency },
      willBeBalanceNegative,
    } = this.state;

    const creditCurrencies = this.getCreditCurrencies();

    return (
      <Form
        onSubmit={onSubmitCreditAdjustment}
        onChange={this.onChangeForm}
        autoFocus={true}
      >
        <Modal
          title="Account credit"
          actions={[
            amount > 0 && {
              label:
                adjust === 'add'
                  ? `Add ${formatCurrency(amount, currency || record.currency)}`
                  : `Subtract ${formatCurrency(
                      amount,
                      currency || record.currency,
                    )}`,
              type: 'submit',
            },
          ]}
          width={650}
          cancelText={amount > 0 ? 'Cancel' : 'Close'}
          onClose={onClickCredits}
        >
          <fieldset>
            <Tabs
              name="tab"
              items={[
                { value: 'adjust', label: 'Current' },
                { value: 'history', label: 'History' },
              ]}
            />
            <TabView value={tab} active="adjust" default>
              <p>
                Balance: {formatCurrency(record.balance, record.currency)}
                {renderMultiCurrencyValuesTooltip(
                  record.$currency,
                  'balance',
                  record.currency,
                )}
              </p>
              <div>
                <fieldset>
                  <Field
                    type="radio"
                    name="adjust"
                    buttons={true}
                    options={[
                      { label: 'Add', value: 'add' },
                      { label: 'Subtract', value: 'subtract' },
                    ]}
                    defaultValue="add"
                    required={true}
                  />
                  <div className="row">
                    <Field
                      type="currency"
                      name="amount"
                      required={true}
                      error={
                        willBeBalanceNegative
                          ? 'Account credits cannot be less than zero'
                          : false
                      }
                      currency={currency || record.currency}
                      className="span2"
                    />
                    {creditCurrencies.length > 0 && (
                      <Field
                        type="select"
                        name="currency"
                        options={creditCurrencies}
                        defaultValue={record.currency}
                        placeholder="Currency"
                        required={true}
                        className="span2"
                      />
                    )}
                  </div>
                  <div className="row">
                    <Field
                      type="select"
                      name="reason"
                      options={REASON_OPTIONS}
                      placeholder="Choose a reason"
                      required={true}
                      className="span4"
                    />
                  </div>
                  <FadeIn active={!!reason}>
                    <Field
                      type="textarea"
                      name="reason_message"
                      placeholder="Additional notes"
                      autoSize={true}
                      rows={2}
                      maxRows={6}
                    />
                  </FadeIn>
                </fieldset>
              </div>
            </TabView>
            <TabView value={tab} active="history">
              {!credits ? (
                <Loading />
              ) : (
                <React.Fragment>
                  <div className="collection-table-container">
                    <table className="collection-table headless">
                      <tbody>
                        {credits.results.length > 0 ? (
                          credits.results.map((credit) => (
                            <tr key={credit.id} className="right">
                              <td
                                className={
                                  credit.amount > 0 ? 'positive' : 'negative'
                                }
                              >
                                {credit.amount > 0
                                  ? `+ ${formatCurrency(
                                      credit.amount,
                                      credit.currency,
                                    )}`
                                  : `- ${formatCurrency(
                                      -credit.amount,
                                      credit.currency,
                                    )}`}
                              </td>
                              <td>{formatDate(credit.date_created, 'age')}</td>
                              <td>
                                {this.renderReasonHistory(credit)}{' '}
                                {this.renderReasonMessage(credit)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No credit history found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {credits.pages && (
                    <span className="button-group">
                      <PrevButton
                        onClick={onClickCreditPagePrev}
                        type="secondary"
                        size="sm"
                        disabled={!credits.pages[creditPage - 1]}
                      />
                      <NextButton
                        onClick={onClickCreditPageNext}
                        type="secondary"
                        size="sm"
                        disabled={!credits.pages[creditPage + 1]}
                      />
                    </span>
                  )}
                </React.Fragment>
              )}
            </TabView>
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
