import React from 'react';
import get from 'lodash/get';
import pt from 'prop-types';

import Link from 'components/link';
import { formatDate, formatCurrency, template, imageUrl } from 'utils';

class CollectionRow extends React.PureComponent {
  static propTypes = {
    id: pt.oneOfType([pt.string, pt.number]),
    num: pt.number,
    field: pt.object,
    record: pt.object,
    currency: pt.string,
    summary: pt.bool,
  };

  render() {
    const { id, num, field, record, summary, currency } = this.props;

    let value;
    let { type } = field;

    try {
      if (summary && num === 0) {
        value = 'Summary';
        type = 'string';
      } else if (field.component) {
        value = field.component(record);
      } else if (field.func) {
        if (field.func_requires_currency === true) {
          value = field.func(record, '', currency);
        } else {
          value = field.func(record);
        }
      } else if (field.path) {
        value = get(record, field.path);
      } else {
        value = record[id];
      }
    } catch (e) {
      value = '';
    }

    switch (type) {
      case 'link':
        return (
          <td>
            {value ? (
              <Link to={template(field.url, record)}>{value}</Link>
            ) : (
              <span>&mdash;</span>
            )}
          </td>
        );

      case 'date':
        return (
          <td className="nowrap">
            {(value && formatDate(value, field.format || 'age')) || (
              <span className="muted">&mdash;</span>
            )}
          </td>
        );

      case 'currency':
        return (
          <td className="currency">
            {field.func
              ? value
              : formatCurrency(
                  value,
                  currency,
                  field.rounded ? { precision: undefined } : undefined,
                )}
          </td>
        );

      case 'number':
        return <td className="number">{value}</td>;

      case 'image': {
        const imageValue = value && (value[0] || value);
        const image = imageValue && (
          <span className="image">
            <img
              title={imageValue.caption || ''}
              alt={imageValue.caption || ''}
              src={imageUrl(imageValue, {
                width: 45,
                height: 45,
                padded: true,
              })}
            />
          </span>
        );

        return (
          <td className="image">
            <span className="collection-table-images">
              {image ? (
                field.url ? (
                  <Link to={template(field.url, record)}>{image}</Link>
                ) : (
                  image
                )
              ) : (
                <span className="image">&nbsp;</span>
              )}
            </span>
          </td>
        );
      }

      case 'order-images':
        return (
          <td>
            <span className="collection-table-images">
              {value &&
                value.map(
                  (item, index) =>
                    item.product &&
                    (index < 4 ? (
                      <span key={index} className="image">
                        <img
                          title={item.name || item.product.name}
                          alt={item.name || item.product.name}
                          src={imageUrl(item.product || item, {
                            width: 45,
                            height: 45,
                            padded: true,
                          })}
                        />
                      </span>
                    ) : index === 4 ? (
                      <span className="image-overflow">...</span>
                    ) : null),
                )}
            </span>
          </td>
        );

      default:
        return (
          <td>
            {value || typeof value === 'number' ? value : <span>&mdash;</span>}
          </td>
        );
    }
  }
}

export default CollectionRow;
