import React, { Fragment } from 'react';
import classNames from 'classnames';
import pt from 'prop-types';
import { map, reduce, find } from 'lodash';

import { parseQuery, isObject } from 'utils';
import {
  filterQueryToDescription,
  filterOperatorsToLabel,
} from 'utils/collection';

import Icon from 'components/icon';
import {
  Field,
  LookupCategory,
  LookupCustomer,
  LookupProduct,
} from 'components/form';
import Button from 'components/button/button';
import { FadeIn, FadeInPop } from 'components/transitions';

import './collection.scss';

function getActiveFilters(props) {
  const { location, filters } = props;

  // Make sure query has been parsed first
  if (!location.queryParsed) {
    location.queryParsed = parseQuery(location.search);
    location.query = location.queryParsed;
  }

  if (!location.query || !filters) {
    return [];
  }

  return reduce(
    location.query,
    (acc, value, key) => {
      const filter = find(filters, { id: key });

      if (filter) {
        acc.push({
          key,
          label: filterQueryToDescription(filter, value, key),
        });
      }

      return acc;
    },
    [],
  );
}

function isValidValue(value) {
  return value === 0 || Boolean(value);
}

function isObjectHasValue(obj) {
  for (const val of Object.values(obj)) {
    if (isObject(val) ? isObjectHasValue(val) : isValidValue(val)) {
      return true;
    }
  }

  return false;
}

function isFilterHasValue(value) {
  if (isObject(value)) {
    return isObjectHasValue(value);
  }

  return isValidValue(value);
}

function getEnabledFilters(props, activeFilters = [], isLoading = false) {
  const { location, filters, filterKeys } = props;

  const set = reduce(
    location.query,
    (set, value, key) => {
      const filter = find(filters, { id: key });

      if (
        (isLoading && filter) ||
        (((filterKeys && filterKeys[key]) ||
          activeFilters.some((item) => item.key === key)) &&
          isFilterHasValue(value))
      ) {
        set.add(key);
      }

      return set;
    },
    new Set(),
  );

  reduce(
    filterKeys,
    (set, val, key) => {
      if (val) {
        set.add(key);
      }

      return set;
    },
    set,
  );

  return set;
}

export default class CollectionFilter extends React.PureComponent {
  static propTypes = {
    categories: pt.object,
    filterKeys: pt.object,
    filterListVisible: pt.bool.isRequired,
    filters: pt.array.isRequired,
    router: pt.object.isRequired,
    location: pt.object.isRequired,
    lookup: pt.object.isRequired,

    onChangeActiveFilters: pt.func,
    onClickApplyFilter: pt.func.isRequired,
    onClickRemoveFilter: pt.func.isRequired,
    onClickShowFilterList: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    const activeFilters = getActiveFilters(props);

    this.state = {
      filters: props.filters,
      location: props.location,
      filterKeys: props.filterKeys,
      filterListVisible: props.filterListVisible,
      activeFilters,
      enabledFilters: getEnabledFilters(props, activeFilters),
    };

    if (props.onChangeActiveFilters) {
      props.onChangeActiveFilters(this.state.activeFilters);
    }
  }

  static getDerivedStateFromProps(props, state) {
    let obj = null;

    if (props.location !== state.location) {
      const activeFilters = getActiveFilters(props);

      if (props.onChangeActiveFilters) {
        props.onChangeActiveFilters(activeFilters);
      }

      obj = Object.assign(obj ?? {}, {
        filters: props.filters,
        location: props.location,
        activeFilters,
        enabledFilters: getEnabledFilters(props, activeFilters),
      });
    }

    if (props.filters !== state.filters && !obj?.activeFilters) {
      obj = Object.assign(obj ?? {}, {
        filters: props.filters,
        activeFilters: getActiveFilters(props),
      });
    }

    if (props.filterListVisible && !state.filterListVisible) {
      obj = Object.assign(obj ?? {}, {
        filterListVisible: props.filterListVisible,
        enabledFilters: getEnabledFilters(props, [], true),
      });
    }

    if (
      props.filterListVisible &&
      (state.filterListVisible || obj?.filterListVisible) &&
      props.filterKeys !== state.filterKeys
    ) {
      obj = Object.assign(obj ?? {}, {
        filterKeys: props.filterKeys,
        enabledFilters: getEnabledFilters(props),
      });
    }

    return obj;
  }

  componentDidUpdate(prevProps) {
    if (this.props.filterListVisible && !prevProps.filterListVisible) {
      document.addEventListener('click', this.onClickWhenShowingFilter);
    } else if (!this.props.filterListVisible && prevProps.filterListVisible) {
      document.removeEventListener('click', this.onClickWhenShowingFilter);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickWhenShowingFilter);
  }

  onClickWhenShowingFilter = (event) => {
    if (
      // Check that the target element is inside the document (it hasn't been removed)
      document.body.contains(event.target) &&
      // Check if the target element is not inside the filter layout
      !document
        .querySelector('.collection-search-filters-container')
        .contains(event.target)
    ) {
      if (event.target.type === 'submit') {
        this.props.onClickApplyFilter(event);
      }
      this.props.onClickShowFilterList(event);
    }
  };

  getTypeSpecificProps(filter, key, op = undefined, k = undefined) {
    const { location } = this.props;
    const locationValue = location.query[key];
    const props = {
      ...{
        [['checkbox', 'radio', 'toggle'].includes(filter.type)
          ? 'defaultChecked'
          : 'defaultValue']:
          locationValue && locationValue !== 'false'
            ? op
              ? locationValue[Number.isInteger(k) ? op : k]
              : locationValue
            : undefined,
      },
      ...(filter.type === 'toggle' ? { label: 'True', labelOff: 'False' } : {}),
    };
    return props;
  }

  render() {
    const {
      categories,
      filterListVisible,
      filters,
      lookup,
      onClickApplyFilter,
      onClickRemoveFilter,
      onClickShowFilterList,
    } = this.props;

    const { activeFilters, enabledFilters } = this.state;

    return (
      <div className="collection-search-filters">
        <ul className="collection-search-filters-active">
          {activeFilters.map((filter) => (
            <li key={filter.key}>
              {filter.label}
              <a
                href=""
                onClick={onClickRemoveFilter}
                data-key={filter.key}
                title="Remove"
              >
                &times;
              </a>
            </li>
          ))}
        </ul>

        <div className="collection-search-filters-menu">
          <Button type="secondary" size="md" onClick={onClickShowFilterList}>
            <Icon type="filter" /> Filter
          </Button>

          <FadeInPop
            active={filterListVisible}
            className="collection-search-filters-container"
            origin="top"
          >
            {map(filters, (filter) => (
              <Fragment key={filter.id}>
                <div className="row">
                  <Field
                    type="toggle"
                    name={`filterKeys.${filter.id}`}
                    label={filter.label}
                    className="snug"
                    defaultChecked={enabledFilters.has(filter.id)}
                  />
                </div>

                {enabledFilters.has(filter.id) && (
                  <FadeIn className="collection-search-filter-fields">
                    <div className="row">
                      {filter.operators ? (
                        filter.operators.map((op, k, operators) => (
                          <Field
                            key={`${filter.id}_${k}`}
                            label={filterOperatorsToLabel[op] || op}
                            type={filter.type || 'text'}
                            name={`filterValues.${filter.id}[${
                              Number.isInteger(k) ? op : k
                            }]`}
                            className={classNames('snug', {
                              span1: operators.length > 3,
                              'span1-3rd': operators.length === 3,
                              span2: operators.length === 2,
                              span4: operators.length < 2,
                            })}
                            autoFocus={k === 0}
                            {...this.getTypeSpecificProps(
                              filter,
                              filter.id,
                              op,
                              k,
                            )}
                          />
                        ))
                      ) : filter.options ? (
                        <Field
                          key="filterSelect"
                          type="select"
                          name={`filterValue.${filter.id}`}
                          options={filter.options}
                          placeholder="Select"
                          required={true}
                          className="span4 snug"
                          autoFocus={true}
                          {...(filter.options.length > 1 &&
                            (filter.options.length <= 3
                              ? {
                                  type: 'radio',
                                  buttons: true,
                                }
                              : filter.options.length <= 8
                              ? {
                                  type: 'radio',
                                  stacked: true,
                                }
                              : undefined))}
                          {...this.getTypeSpecificProps(filter, filter.id)}
                        />
                      ) : filter.type === 'LookupCategory' ? (
                        <LookupCategory
                          key="filterValueLookupCategory"
                          name={`filterValue.${filter.id}`}
                          categories={categories}
                          required={true}
                          className="span4 snug anchor-right"
                          autoFocus={true}
                          {...this.getTypeSpecificProps(filter, filter.id)}
                        />
                      ) : filter.type === 'LookupCustomer' ? (
                        <LookupCustomer
                          key="filterValueLookupCustomer"
                          name={`filterValue.${filter.id}`}
                          lookup={lookup}
                          query={filter.query}
                          model={filter.model || 'accounts'}
                          required={true}
                          className="span4 snug anchor-right"
                          autoFocus={true}
                          {...this.getTypeSpecificProps(filter, filter.id)}
                        />
                      ) : filter.type === 'LookupProduct' ? (
                        <LookupProduct
                          key="filterValueLookupProduct"
                          name={`filterValue.${filter.id}`}
                          lookup={lookup}
                          query={filter.query}
                          model={filter.model || 'products'}
                          required={true}
                          className="span4 snug anchor-right"
                          autoFocus={true}
                          {...this.getTypeSpecificProps(filter, filter.id)}
                        />
                      ) : (
                        <Field
                          key="filterValue"
                          type={filter.type || 'text'}
                          name={`filterValue.${filter.id}`}
                          required={true}
                          className="span4 snug"
                          autoFocus={true}
                          {...this.getTypeSpecificProps(filter, filter.id)}
                        />
                      )}
                    </div>
                  </FadeIn>
                )}
              </Fragment>
            ))}

            <FadeIn className="collection-search-filter-apply">
              <Button
                size="sm"
                type={enabledFilters.size > 0 ? 'default' : 'secondary'}
                onClick={onClickApplyFilter}
              >
                {enabledFilters.size > 1
                  ? `Apply ${enabledFilters.size} filters`
                  : 'Apply filter'}
              </Button>
            </FadeIn>
          </FadeInPop>
        </div>
      </div>
    );
  }
}
