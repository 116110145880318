import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

export default function FadeIn({
  duration = 150,
  children,
  initDisplayStyle = 'block',
  ...props
}) {
  const transitionStyles = {
    defaultStyle: {
      opacity: 0,
      display: 'none',
    },
    enterInitStyle: {
      opacity: 0,
      display: initDisplayStyle,
    },
    enterStyle: {
      opacity: transit(1.0, duration, 'ease-out'),
    },
    leaveStyle: {
      opacity: transit(0, 0),
    },
    activeStyle: {},
    transitionAppear: true,
    active: true,
  };
  return (
    <CSSTransition {...transitionStyles} {...props}>
      {children}
    </CSSTransition>
  );
}
