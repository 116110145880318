import api from 'services/api';

export const OAUTH_FETCH_CLIENT_INFO = 'oauth/fetchClientInfo';
export const OAUTH_GET_AUTHORIZATION = 'oauth/getAuthorization';
export const OAUTH_CREATE_AUTHORIZATION = 'oauth/createAuthorization';

export default {
  fetchClientInfo(query) {
    return {
      type: OAUTH_FETCH_CLIENT_INFO,
      payload: api.get('/oauth/client', query).catch((error) => ({ error })),
    };
  },

  getAuthorization(query) {
    return {
      type: OAUTH_GET_AUTHORIZATION,
      payload: api.get('/oauth/authorization', query),
    };
  },

  createAuthorization(data) {
    return {
      type: OAUTH_CREATE_AUTHORIZATION,
      payload: api.post('/oauth/authorizations', data),
    };
  },
};

export const initialState = {
  app: null,
  user: null,
  scope: {},
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case OAUTH_FETCH_CLIENT_INFO:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
