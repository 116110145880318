import React from 'react';
import pt from 'prop-types';

import { Field, FieldLocalized, Fieldtable } from 'components/form';

function getInitialValue(props) {
  return props.values || [];
}

export default class TrialPurchaseOptionGroups extends React.PureComponent {
  static propTypes = {
    name: pt.string,
    values: pt.array,
    disabled: pt.bool,
    settings: pt.object,
    currency: pt.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      prevValues: null,
      value: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.values !== state.prevValues) {
      return { prevValues: props.values, value: getInitialValue(props) };
    }

    return null;
  }

  renderTableHeading = () => {
    return [
      <th key="price">Price rule</th>,
      <th key="auth_amount">Authorization amount</th>,
      <th key="trial_days">Trial days</th>,
      <th key="account_group">Customer group</th>,
    ];
  };

  renderTableValue = ({ value, index, disabled, onChange }) => {
    const { settings, currency } = this.props;

    return [
      <td key="price">
        <FieldLocalized
          type="currency"
          name="price"
          data-index={index}
          defaultValue={value.price}
          currency={currency}
          localeValue={value.$currency}
          required={true}
          disabled={disabled}
          onChange={onChange}
          autoFocus={true}
        />
      </td>,
      <td key="auth_amount">
        <FieldLocalized
          type="currency"
          name="auth_amount"
          data-index={index}
          defaultValue={value.auth_amount}
          currency={currency}
          localeValue={value.$currency}
          disabled={disabled}
          onChange={onChange}
        />
      </td>,
      <td key="trial_days" className="col">
        <Field
          type="number"
          name="trial_days"
          data-index={index}
          defaultValue={value.trial_days}
          placeholder="30"
          disabled={disabled}
          onChange={onChange}
        />
      </td>,
      <td key="account_group">
        <Field
          type="select"
          name="account_group"
          data-index={index}
          options={settings.accounts.groups}
          clearIcon
          defaultValue={value.account_group}
          placeholder="All Customers"
          disabled={disabled}
          required={true}
          onChange={onChange}
        />
      </td>,
    ];
  };

  render() {
    const { disabled, name } = this.props;

    return (
      <div className="product-prices">
        <Fieldtable
          label="Price rule"
          name={name}
          defaultValue={this.state.value}
          renderHeading={this.renderTableHeading}
          renderValue={this.renderTableValue}
          disabled={disabled}
          sortable={true}
          inline={true}
        />
      </div>
    );
  }
}
