import React from 'react';
import { func } from 'prop-types';

export default class GoLiveNotice extends React.PureComponent {
  static contextTypes = {
    openModal: func.isRequired,
    closeModal: func.isRequired,
  };

  static propTypes = {
    onCloseGoLiveNotice: func.isRequired,
    onEnableLiveEnvironment: func.isRequired,
  };

  onClickGoLive = () => {
    this.context.openModal('AccountPlanSelection');
  };

  render() {
    return (
      <div className="nav-cta-textbox-container">
        <div className="nav-cta-textbox">
          You're in test mode. Enable a live environment to setup your
          production store.
          <div className="nav-go-live-cta-container">
            <button className="nav-go-live-cta" onClick={this.onClickGoLive}>
              Enable live mode
            </button>
          </div>
          <a
            href=""
            className="nav-cta-textbox-close"
            onClick={this.props.onCloseGoLiveNotice}
          >
            &times;
          </a>
        </div>
      </div>
    );
  }
}
