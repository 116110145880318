/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import { isLiveMode } from 'utils/payment';
import FadeIn from 'components/transitions/fade-in-down-bounce';

export default class PayPalCommerceEditModal extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    loading: PropTypes.bool,
    cardMethod: PropTypes.object,
    paypalMethod: PropTypes.object,
    editValues: PropTypes.object,
    testEnv: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    onClickActivate: PropTypes.func,
    onClickReset: PropTypes.func,
  };

  render() {
    const {
      loading,
      cardMethod,
      paypalMethod,
      editValues,
      testEnv,
      onClose,
      onSubmit,
      onChange,
      onClickActivate,
      onClickReset,
    } = this.props;
    const { client } = this.context;
    const paypalValues = editValues?.methods?.paypal || {};
    const mode = paypalValues.mode || paypalMethod.mode;
    const merchantIdField = `${mode}_merchant_id`;
    const merchantId = paypalMethod[merchantIdField];
    const activated = Boolean(merchantId);
    const enabled = Boolean(paypalMethod.ppcp_enabled);
    const isLive = testEnv ? false : isLiveMode(mode);
    const canUseBraintreeGateway = cardMethod.gateway === 'braintree';
    const useBraintreeGateway =
      canUseBraintreeGateway && paypalValues.gateway === 'braintree';
    const shouldRenderResetButton = activated && !useBraintreeGateway;

    return (
      <Form onSubmit={onSubmit} onChange={onChange}>
        <Modal
          title="PayPal Commerce Platform"
          actions={[
            {
              label: 'Save',
              type: 'submit',
            },
            {
              label: 'Cancel',
              type: 'cancel',
              onClick: onClose,
            },
            shouldRenderResetButton && {
              component: (
                <a
                  href=""
                  className="button button-sm button-secondary float-begin"
                  onClick={onClickReset}
                >
                  Reset credentials
                </a>
              ),
            },
          ]}
          width={500}
          cancel={false}
          loading={loading}
          onClose={onClose}
        >
          <fieldset>
            <Fragment>
              {testEnv ? (
                <Field name="methods.paypal.mode" type="hidden" value="test" />
              ) : (
                <Field
                  type="radio"
                  buttons={true}
                  name="methods.paypal.mode"
                  options={[
                    { value: 'live', label: 'Live' },
                    { value: 'test', label: 'Sandbox' },
                  ]}
                  defaultValue={isLive ? 'live' : 'test'}
                />
              )}
              {!useBraintreeGateway && (
                <FadeIn>
                  <Field
                    type="hidden"
                    name="methods.paypal.ppcp"
                    value={activated}
                  />
                  <Field
                    type="hidden"
                    name="methods.paypal.activated"
                    value={activated}
                  />
                  <Field
                    type="hidden"
                    name="methods.paypal.ppcp_activated"
                    value={activated}
                  />
                  <Field
                    type="hidden"
                    name="methods.paypal.enabled"
                    value={enabled}
                  />
                  <Field
                    type="hidden"
                    name="methods.paypal.ppcp_enabled"
                    value={enabled}
                  />
                  <Field
                    type="hidden"
                    name="methods.paypal.merchant_id"
                    value={null}
                  />
                  <Field
                    type="hidden"
                    name="methods.paypal.client_id"
                    value={null}
                  />
                  <Field
                    type="hidden"
                    name="methods.paypal.client_secret"
                    value={null}
                  />
                  {activated ? (
                    <Field
                      type="text"
                      label="Merchant ID"
                      name={`methods.paypal.${merchantIdField}`}
                      defaultValue={merchantId}
                      disabled={true}
                    />
                  ) : (
                    <Fragment>
                      <p>
                        You can use Paypal in live or sandbox mode without a
                        PayPal account. Once you receive your first live
                        payment, you will get an email with further
                        instructions.
                      </p>
                      <Field
                        type="email"
                        label="Merchant email (optional)"
                        name="methods.paypal.store_owner_email"
                        defaultValue={paypalMethod.store_owner_email}
                        placeholder={client.owner?.email}
                      />
                      <a
                        href=""
                        onClick={onClickActivate}
                        className="button button-sm button-secondary paypal-commerce-connect"
                      >
                        Connect PayPal account
                      </a>
                    </Fragment>
                  )}
                </FadeIn>
              )}
            </Fragment>
            {canUseBraintreeGateway && (
              <Field
                type="toggle"
                label="Use Braintree to process PayPal payments"
                name="methods.paypal.gateway"
                value="braintree"
                nonValue={null}
                defaultChecked={useBraintreeGateway}
              />
            )}
            {useBraintreeGateway && (
              <FadeIn>
                <Field
                  type="hidden"
                  name="methods.paypal.activated"
                  value={true}
                />
                <Field
                  type="hidden"
                  name="methods.paypal.enabled"
                  value={true}
                />
                <Field
                  type="text"
                  label="Merchant ID"
                  name="methods.paypal.merchant_id"
                  defaultValue={paypalMethod.merchant_id}
                  required={true}
                />
                <Field
                  type="text"
                  label="Client ID"
                  name="methods.paypal.client_id"
                  defaultValue={paypalMethod.client_id}
                  required={true}
                />
                <Field
                  type="text"
                  label="Client secret"
                  name="methods.paypal.client_secret"
                  defaultValue={paypalMethod.client_secret}
                  required={true}
                />
                <p>
                  Your Braintree account will be used to process PayPal payments
                  as long as it&apos;s enabled. Contact{' '}
                  <a href="mailto:support@swell.is">support</a> for details.
                </p>
              </FadeIn>
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
