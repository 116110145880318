import React from 'react';

import Form from 'components/form';
import View from 'components/view';

import AttributeForm from './form';

const headerActions = [{ label: 'Save attribute', submit: true }];

const devtools = {
  model: 'attributes',
};

function NewAttributePage({ onChangeForm, onSubmitRecord, ...props }) {
  return (
    <Form
      onSubmit={onSubmitRecord}
      onChange={onChangeForm}
      autoFocus={true}
      values={props.values}
    >
      <View
        detail={true}
        uri="/attributes"
        sectionTitle="Attributes"
        headerTitle="New attribute"
        headerActions={headerActions}
        localized={true}
        devtools={devtools}
      >
        <AttributeForm {...props} />
      </View>
    </Form>
  );
}

export default React.memo(NewAttributePage);
