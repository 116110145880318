import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';

function MenuSectionTitle({ faIcon, title, contextMenuButton, ...props }) {
  return (
    <section {...props}>
      <div className="SettingsMenu-section-icon">
        <Icon fa={faIcon} faType="solid" />
      </div>
      <div className="SettingsMenu-section-title">{title}</div>
      {contextMenuButton ?? null}
    </section>
  );
}

MenuSectionTitle.propTypes = {
  className: PropTypes.string,
  faIcon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  contextMenuButton: PropTypes.element,
};

export default React.memo(MenuSectionTitle);
