import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import api from 'services/api';

import actions from 'actions';

import { getCleanBundleItems, makeOrderItem } from 'utils/order';

import { getAccountCardsQuery } from 'utils/account';

import ViewPage from 'components/pages/purchase-links/view';
import NotFoundPage from 'components/pages/error/404';
import ViewLoading from 'components/view/loading';

export const mapStateToProps = (state) => ({
  record: state.data.record,
  related: state.data.related,
  loading: state.data.loading,
  errors: state.data.recordErrors,
  lookup: state.lookup,
  categories: state.categories,
  content: state.content,
  settings: state.settings,
  discounts: state.orders.discounts,
});

export const getMapDispatchToProps =
  (mapMore = null) =>
  (dispatch) => ({
    fetchRecord(id) {
      const seriesQuery = dispatch(
        actions.data.getCollectionSeriesQuery('purchaselinks'),
      );

      return dispatch(
        actions.data.fetchRecord('purchaselinks', id, {
          expand: [
            'items.product',
            'items.variant',
            'items.bundle_items.product',
            'items.bundle_items.variant',
            'coupon',
            'promotions:100',
          ],
        }),
      ).then((result) => {
        if (result) {
          dispatch(
            actions.data.fetchRelated(id, {
              account_cards: getAccountCardsQuery(result.account_id),
            }),
          );
        }

        return result;
      });
    },

    createRecord(data) {
      return dispatch(actions.data.createRecord('purchaselinks', data));
    },

    updateRecord(id, data) {
      return dispatch(actions.data.updateRecord('purchaselinks', id, data));
    },

    updateFetchRecord(id, data) {
      return dispatch(
        actions.data.updateFetchRecord('purchaselinks', id, data),
      );
    },

    getItemPrice(id, item) {
      return api.get(`/data/purchaselinks/${id}/items`, { $price: item });
    },

    addItem(id, item) {
      return dispatch(
        actions.data.createRecord(`purchaselinks/${id}/items`, item),
      );
    },

    editItems(id, items) {
      return Promise.all(
        items
          .filter((item) => Boolean(item.removed))
          .map((item) => {
            return dispatch(
              actions.data.deleteRecord(`purchaselinks/${id}/items`, item.id),
            );
          }),
      ).then(() => {
        return dispatch(
          actions.data.updateRecord(
            'purchaselinks',
            `${id}/items`,
            items
              .filter((item) => !item.removed)
              .map((item) => ({ ...item, removed: undefined })),
          ),
        );
      });
    },

    deleteRecord(id) {
      return dispatch(actions.data.deleteRecord('purchaselinks', id));
    },

    clearErrors() {
      return dispatch(actions.data.clearErrors());
    },

    fetchDiscounts() {
      return dispatch(actions.orders.fetchDiscounts());
    },

    loadContentModels() {
      return dispatch(actions.content.loadModels('purchaselinks'));
    },

    loadSettings() {
      return dispatch(actions.settings.fetch('purchaselinks'));
    },

    ...(mapMore ? mapMore(dispatch) : {}),
  });

export class ViewPurchaseLink extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    notifyDeleted: PropTypes.func.isRequired,
    notifyCreated: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      id: props.params.id,
      loaded: false,
      edited: false,
    };
  }

  componentDidMount() {
    const {
      params,
      fetchRecord,
      fetchDiscounts,
      loadContentModels,
      loadSettings,
    } = this.props;

    Promise.all([
      fetchDiscounts(),
      fetchRecord(params.id),
      loadContentModels(),
      loadSettings(),
    ]).then(() => {
      this.setState({ loaded: true });
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.params.id !== state.id) {
      return { id: props.params.id, loaded: false };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.id !== prevState.id) {
      this.props.fetchRecord(this.props.params.id).then(() => {
        this.setState({ loaded: true });
      });
    } else if (
      this.props.record &&
      prevProps.record &&
      this.props.record.id === prevProps.record.id &&
      this.props.record.active !== prevProps.record.active
    ) {
      this.context.notifySuccess(
        this.props.record.active
          ? 'Purchase link has been activated'
          : 'Purchase link has been deactivated',
      );
    }
  }

  onEdited = (edited) => {
    this.setState({ edited });
  };

  getItemPrice = (item) => {
    const { params, getItemPrice } = this.props;
    return getItemPrice(params.id, item);
  };

  onAddItem = (values) => {
    const { params, addItem, editItems, fetchRecord } = this.props;

    const data = makeOrderItem(values);

    const isExists = Boolean(values.id);

    const promise = isExists
      ? editItems(params.id, [{ id: values.id, ...data }])
      : addItem(params.id, data);

    return promise.then((result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        return fetchRecord(params.id);
      }
    });
  };

  onEditItems = (items) => {
    const { params, editItems, fetchRecord } = this.props;

    return editItems(
      params.id,
      items.map((item) => ({
        id: item.id,
        product: undefined,
        product_id: item.product && item.product.id,
        variant: undefined,
        variant_id: item.variant && item.variant.id,
        price: item.set_price,
        quantity: item.quantity,
        options: item.options,
        removed: item.removed,
        description: item.description,
        recurring: item.recurring,
        bundle_items: getCleanBundleItems(item.bundle_items),
      })),
    ).then((result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        return fetchRecord(params.id);
      }
    });
  };

  onEditValues = (values, draftId) => {
    const { params, updateFetchRecord, fetchRecord } = this.props;

    return updateFetchRecord(draftId || params.id, values).then((result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        return fetchRecord(draftId || params.id);
      }
    });
  };

  onChangeCurrency = async (currencyCode, draftId) => {
    const { params, fetchRecord, updateRecord } = this.props;
    const { notifyError, notifySuccess } = this.context;

    this.setState({ loading: true });

    const result = await updateRecord(draftId || params.id, {
      currency: currencyCode,
    });

    if (result.errors) {
      notifyError(result.errors);

      this.setState({ loading: undefined });

      return false;
    }

    notifySuccess(
      `The currency for the purchase link has been successfully changed to ${currencyCode}`,
    );

    await fetchRecord(draftId || params.id);

    this.setState({ loading: undefined });

    return true;
  };

  onSubmitClone = () => {
    const { router, record, createRecord } = this.props;

    return createRecord({
      ...record,
      id: undefined,
      name: `${record.name} (copy)`,
    }).then((result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        router.push(`/purchase-links/${result.id}`);
        this.context.notifyCreated(result);
      }
    });
  };

  onDelete = () => {
    const { params, router, deleteRecord } = this.props;

    return deleteRecord(params.id).then(() => {
      router.replace('/purchase-links');
      this.context.notifyDeleted('Purchase link');
    });
  };

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    if (!this.props.record) {
      return <NotFoundPage />;
    }

    if (this.props.page) {
      return <this.props.page {...this.props} {...this.state} />;
    }

    return (
      <ViewPage
        {...this.props}
        {...this.state}
        onAddItem={this.onAddItem}
        onEditItems={this.onEditItems}
        onEditValues={this.onEditValues}
        onChangeCurrency={this.onChangeCurrency}
        onSubmitClone={this.onSubmitClone}
        getItemPrice={this.getItemPrice}
        onEdited={this.onEdited}
        onDelete={this.onDelete}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  getMapDispatchToProps(),
)(ViewPurchaseLink);
