import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import actions from 'actions';
import segment from 'services/segment';
import AccountPlanSelectionModal from 'components/pages/modals/account-plan-selection';
import { tokenizeCard } from 'services/api';
import moment from 'moment';

const mapStateToProps = (state) => ({
  account: state.account,
  client: state.client,
  flash: state.flash,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  flashSuccess: (message) => {
    dispatch(actions.flash.success(message));
  },

  fetchAccount: () => {
    return dispatch(actions.account.fetch());
  },

  fetchClient: () => {
    return dispatch(actions.client.fetch());
  },

  fetchInfo: () => {
    return dispatch(actions.account.fetchInfo());
  },

  fetchPlan: () => {
    return dispatch(actions.account.fetchPlan());
  },

  updateAccount: (data) => {
    return dispatch(actions.account.update(data));
  },

  updatePlan: (data) => {
    return dispatch(actions.account.updatePlan(data));
  },
});

class AccountPlanSelection extends React.PureComponent {
  static contextTypes = {
    closeModal: func.isRequired,
    notifyError: func.isRequired,
  };

  static propTypes = {
    account: object.isRequired,
    client: object.isRequired,
    fetchAccount: func.isRequired,
    fetchInfo: func.isRequired,
    fetchPlan: func.isRequired,
    flashSuccess: func.isRequired,
    router: object.isRequired,
    updateAccount: func.isRequired,
    updatePlan: func.isRequired,
    user: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPlan: '',
      step: 'plan',
    };

    this.onSelectPlan = this.onSelectPlan.bind(this);
    this.onUpdateBilling = this.onUpdateBilling.bind(this);
    this.onSubmitActivatePlan = this.onSubmitActivatePlan.bind(this);
  }

  async componentDidMount() {
    const { fetchInfo } = this.props;

    const leadInfo = await fetchInfo();
    this.setState({ leadInfo });
  }

  async onSubmitActivatePlan(values) {
    const success = await this.onSelectPlan(values);
    if (!success) {
      return;
    }

    const { flashSuccess, router } = this.props;
    const { closeModal } = this.context;
    await closeModal();

    await router.push(window.location.pathname);

    await flashSuccess(`Subscription plan has been successfully updated.`);
  }

  async onUpdateBilling(values) {
    const { updateAccount, fetchAccount } = this.props;
    const { notifyError } = this.context;

    const { BILLING_PUBLIC_KEY } = process.env;

    const card = values.billing.card || {};

    if (card.number) {
      try {
        const billing = { ...values.billing, card: undefined };
        const cardResult = await tokenizeCard(BILLING_PUBLIC_KEY, {
          card,
          billing,
        });
        if (cardResult.errors) {
          let knownError =
            cardResult.errors.gateway || cardResult.errors.number;
          if (knownError) {
            switch (knownError.code) {
              case 'CARD_DECLINED':
                knownError = {
                  message: 'Your credit card was declined, please try again',
                };
                break;
              case 'INCORRECT_CVC':
                knownError = {
                  message: 'Your card CVC code is incorrect, please try again',
                };
                break;
              case 'EXPIRED_CARD':
                knownError = {
                  message: 'Your credit card is expired, please try again',
                };
                break;
              default:
                break;
            }
          }
          throw new Error(
            `${
              knownError
                ? knownError.message
                : JSON.stringify(cardResult.errors)
            }`,
          );
        }
        values.billing.method = 'card';
        values.billing.card = cardResult;
        values.$subscription_payment = true;
      } catch (err) {
        notifyError(err.message);
        return false;
      }
    }

    try {
      const result = await updateAccount({
        billing: {
          ...values.billing,
        },
      });

      if (!result) {
        return false;
      } else if (result.errors) {
        if (result.errors['cards.0.gateway']) {
          notifyError(result.errors['cards.0.gateway'].message);
        } else {
          notifyError(result.errors);
        }
        return false;
      }
    } catch (err) {
      notifyError(err.message);
      return false;
    }

    return true;
  }

  async onSelectPlan(values) {
    const { client, user, fetchAccount, fetchClient, fetchPlan, updatePlan } =
      this.props;

    const { leadInfo } = this.state;
    const { notifyError } = this.context;

    const companyType = leadInfo?.signup_state?.company_type || '';

    if (values.billing) {
      const result = await this.onUpdateBilling({
        billing: values.billing,
      });

      if (!result) {
        return false;
      }
    }
    const planData = {
      plan: values.plan,
      interval: values.interval,
      interval_count: values.interval_count,
    };

    const planResult = await updatePlan(planData);

    if (!planResult) {
      return false;
    } else if (planResult.errors) {
      notifyError(planResult.errors);
      return false;
    }

    // Fetch client before account in order to get new env flags
    await fetchClient();

    const [newPlan] = await Promise.all([fetchPlan(), fetchAccount()]);

    await segment.track(
      'User subscribed plan',
      {
        id: user.id,
        username: user.username,
        email: user.username,
        client_id: user.client_id,
        client_name: client.name,
        first_name: user.firstName,
        last_name: user.lastName,
        plan_name: newPlan.name,
        stage_name: 'Closed Won',
        close_date: moment().add(2, 'months').format(),
        company_type: companyType,
      },
      {
        Salesforce: true,
      },
    );

    return true;
  }

  render() {
    return (
      <AccountPlanSelectionModal
        {...this.props}
        onSubmitActivatePlan={this.onSubmitActivatePlan}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountPlanSelection);
