import {
  get,
  capitalize,
  each,
  map,
  omit,
  reduce,
  find,
  cloneDeep,
} from 'lodash';
import flash from './flash';
import api from 'services/api';
import { singularize, pluralize, isValueEqual, isEmpty } from 'utils';

import dataActions from 'actions/data';
import userActions from 'actions/user';

import {
  populateItemTypeFields,
  cleanModelField,
  cleanModelFields,
  defaultContentModels,
  defaultContentView,
  normalizeContentModel,
  collectionName,
  getNameFieldExpand,
  findContentTypeIds,
  modelPageThemeUrl,
  findContentField,
  assignModelsToChildCollections,
} from 'utils/content';

import { PREDEFINED_LOOKUP_TYPES } from 'shared/utils';

import {
  COLLECTION_QUERY,
  COLLECTION_MODELS,
  CONTENT_MODELS,
  CONTENT_MODEL_VIEWS,
} from 'constants/content';

/** @deprecated */
const CONTENT_FETCH_FIELDS_DEPRECATED = 'content/fetchFieldsDeprecated';

const CONTENT_FETCH = 'content/fetch';
const CONTENT_FETCH_MODEL = 'content/fetchModel';
const CONTENT_CREATE_MODEL = 'content/createModel';
const CONTENT_DELETE_MODEL = 'content/deleteModel';
const CONTENT_FETCH_MODELS = 'content/fetchModels';
const CONTENT_FETCH_COLLECTIONS = 'content/fetchCollections';
const CONTENT_FETCH_VIEW = 'content/fetchView';
const CONTENT_LOADING = 'content/loading';

const CONTENT_FETCH_CONTENT_TYPES = 'content/fetchContentTypes';
const CONTENT_FETCH_MODEL_RECORD = 'content/fetchModelRecord';
const CONTENT_FETCH_MODEL_RECORD_LIST = 'content/fetchModelRecordList';
const CONTENT_DELETE_MODEL_RECORD = 'content/deleteModelRecord';

const DEPRECATED_CONTENT_USERS = ['nowvac', 'nowvac-dev'];

// Actions for new /:content endpoint
const actions = {
  // Deprecated methods return nothing
  // TODO: clean this up after nowvac is migrated
  fetchFieldsDeprecated(model = undefined) {
    return {
      type: CONTENT_FETCH_FIELDS_DEPRECATED,
      payload: Promise.resolve({ results: [], count: 0 }),
      meta: {},
    };
  },
};

// Actions for deprecated /content endpoint
const actionsDeprecated = {
  fetchFieldsDeprecated(model) {
    return {
      type: CONTENT_FETCH_FIELDS_DEPRECATED,
      payload: api.get('/data/content', {
        target_model: model,
        target_field: { $ne: null },
        limit: 100,
      }),
      meta: { model },
    };
  },
};

function actionDeprecatedOrNot(getState, name, ...props) {
  const { client } = getState();

  return client && DEPRECATED_CONTENT_USERS.includes(client.id)
    ? actionsDeprecated[name](...props)
    : actions[name] && actions[name](...props);
}

const exportActions = {
  fetchFieldsDeprecated(...props) {
    return (dispatch, getState) =>
      dispatch(
        actionDeprecatedOrNot(getState, 'fetchFieldsDeprecated', ...props),
      );
  },

  async fetch(id) {
    return {
      type: CONTENT_FETCH,
      payload: api
        .getLocalized('/data/:content/{id}', { id })
        .then(async (type) => {
          if (type) {
            await populateItemTypeFields(type.fields || []);
          }
          return type;
        }),
    };
  },

  fetchModels(query = {}, collection = undefined) {
    return (dispatch, getState) => {
      return dispatch({
        type: CONTENT_FETCH_MODELS,
        payload: api
          .getLocalized('/data/:content', {
            limit: 1000,
            ...query,
          })
          .then(async (result) => {
            const collections = await dispatch(
              this.fetchCollections(result.results),
            );
            assignCollectionLookupQueries(collections, result.results);
            return result.results;
          }),
        meta: {
          query,
          collection,
          appsEnabledById: getState().client.appsEnabledById,
        },
      });
    };
  },

  fetchCollections(contentModels = []) {
    return async (dispatch, getState) => {
      const { collections } = getState().content;

      let query;
      // Fetch all content collections on the first pass
      if (isEmpty(collections)) {
        query = COLLECTION_QUERY;
      } else if (!isEmpty(contentModels)) {
        query = {
          $or: contentModels.map(({ collection, app_id }) => ({
            name: app_id ? collection.split('/').pop() : collection,
            app_id: app_id || null,
          })),
        };
      }

      const payload = isEmpty(query)
        ? collections
        : await api
            .get('/data/:models', {
              ...query,
              limit: 1000,
            })
            .then((result) => {
              return result.results.reduce((acc, model) => {
                const collection = collectionName(model);

                acc[collection] = {
                  ...model,
                  ...getCollectionLabels(model),
                  collection,
                  nameFieldExpand: getNameFieldExpand(model),
                };

                reduce(
                  model.fields,
                  (acc, field, fieldName) => {
                    if (field.type !== 'collection') {
                      return acc;
                    }

                    const childCollection = collectionName({
                      namespace: model.namespace,
                      name: field.name,
                      app_id: field.app_id,
                    });

                    acc[childCollection] = {
                      ...field,
                      ...getChildCollectionLabels(model, field, fieldName),
                      fieldName,
                      child: true,
                      parent: collection,
                      collection: childCollection,
                      nameFieldExpand: getNameFieldExpand(field),
                    };

                    return acc;
                  },
                  acc,
                );

                return acc;
              }, {});
            });

      dispatch({
        type: CONTENT_FETCH_COLLECTIONS,
        payload,
      });

      return payload;
    };
  },

  modelPromises: new Map(),

  loadModels(collection) {
    return async (dispatch, getState) => {
      const { content } = getState();

      let model = content.modelsByCollection[collection];

      if (model !== undefined) {
        return model;
      }

      let promise = this.modelPromises.get(collection);

      if (promise !== undefined) {
        return promise;
      }

      promise = dispatch(this.fetchModels({ collection }, collection));

      this.modelPromises.set(collection, promise);

      const result = await promise;

      this.modelPromises.delete(collection);

      return result;
    };
  },

  getModelsByCollection(inCollection) {
    return async (_dispatch, getState) => {
      const { content } = getState();
      const [collection, childPart] = inCollection.split(':');
      const contentModels = content.modelsByCollection[collection];

      if (childPart) {
        const childContentModels = content.modelsByCollection[inCollection];

        // Pseudo models when parents contain child fields
        const parentContentModels = contentModels?.reduce((acc, model) => {
          const childField = findContentField(model.fields, childPart);

          if (childField?.fields) {
            acc.push({
              root: model.root || true,
              fields: childField.fields,
            });
          }

          return acc;
        }, []);

        return [...(childContentModels || []), ...(parentContentModels || [])];
      }

      return [...(contentModels || [])];
    };
  },

  loadModelsInitial() {
    return (dispatch, getState) => {
      const { models } = getState().content;
      if (!isEmpty(models)) {
        return Promise.resolve(models);
      }
      return dispatch(this.fetchModels());
    };
  },

  loadCollections() {
    return (dispatch, getState) => {
      const { collections } = getState().content;
      if (!isEmpty(collections)) {
        return Promise.resolve(collections);
      }
      return dispatch(this.fetchCollections());
    };
  },

  getViews(collectionName) {
    return (_dispatch, getState) => {
      return getState().content.viewsByCollection[collectionName];
    };
  },

  fetchView(collectionName, type, isNew) {
    return async (dispatch, getState) => {
      const { collections, modelsByCollection, viewsByCollection } =
        getState().content;
      const { contentViews: userViews } = getState().user;

      const currentCollection = collections[collectionName];
      if (!currentCollection) {
        return {};
      }

      const actualCollectionName = currentCollection.collection;
      const contentModels = modelsByCollection[actualCollectionName] || [];
      const collectionViews = viewsByCollection[actualCollectionName] || [];

      // TODO: remove this since viewsByCollection has defaults included now
      const defaultView = defaultContentView(
        currentCollection,
        contentModels,
        type,
        collections,
      );

      const views = [
        ...collectionViews.filter((view) => view?.type === type),
      ].filter(Boolean);

      const userViewId = get(userViews[actualCollectionName], type);

      // If user view is not found, use the first view in the list or a default view
      const currentView =
        currentCollection &&
        (find(views, {
          gid: userViewId,
        }) ||
          find(views, (view) => {
            if (type === 'record') {
              if (isNew) {
                return view.id === 'new';
              } else {
                return view.id !== 'new';
              }
            }
            return view.type === type;
          }) ||
          defaultView);

      if (currentView) {
        // Child collections already have 1 field, which is parent_id
        const hasUniqueFields =
          currentView.fields?.length > 0 &&
          (!currentView.childCollection || currentView.fields.length > 1);
        if (!hasUniqueFields) {
          currentView.fields = defaultView?.fields;
          currentView.content = false;
          currentView.hasContent = false;
        } else if (currentView.standard) {
          currentView.contentFields = defaultView.fields
            .filter((field) => !find(currentView.fields, { id: field.id }))
            .map((field) => ({
              ...field,
              admin_zone: 'content',
              contentModel: currentView.contentModel,
            }));
        }
        if (currentView.contentFields?.length) {
          await populateItemTypeFields(currentView.contentFields);
        }
      }

      return dispatch({
        type: CONTENT_FETCH_VIEW,
        payload: Promise.resolve({
          currentCollection,
          currentView,
          views,
        }),
      });
    };
  },

  setView(view) {
    return (dispatch) => {
      dispatch(userActions.setContentView(view));
      return view && dispatch(this.fetchView(view.collection, view.type));
    };
  },

  toggleStandardContent() {
    return (dispatch, getState) => {
      const { currentView } = getState().content;
      // Undefined is default true
      currentView.content =
        currentView.content === undefined ? false : !currentView.content;
      return dispatch({
        type: CONTENT_FETCH_VIEW,
        payload: Promise.resolve({
          currentView: { ...currentView },
        }),
      });
    };
  },

  proxyDataAction(collection, action) {
    return async (dispatch) => {
      return dispatch(action(collection));
    };
  },

  fetchRecord(collectionParam, id) {
    return this.proxyDataAction(collectionParam, (collection) =>
      dataActions.fetchRecord(collection, id),
    );
  },

  createRecord(collectionParam, data) {
    return this.proxyDataAction(collectionParam, (collection) =>
      dataActions.createRecord(collection, data),
    );
  },

  updateRecord(collectionParam, id, data) {
    return this.proxyDataAction(collectionParam, (collection) =>
      dataActions.updateRecord(collection, id, data),
    );
  },

  deleteRecord(collectionParam, id) {
    return this.proxyDataAction(collectionParam, (collection) =>
      dataActions.deleteRecord(collection, id),
    );
  },

  fetchCollectionModel(id) {
    // Support model IDs with child segments
    let [name, appPart] = String(id).split('.').reverse();
    name = decodeURIComponent(name);

    const appId = appPart?.includes?.('app_')
      ? appPart.split('app_').pop()
      : null;

    const [baseName, childName] = name.split(':');

    return async (dispatch, getState) => {
      return dispatch({
        type: CONTENT_FETCH_MODEL,
        payload: api
          .get('/data/:models/{name}', {
            name: baseName,
            app_id: appId,
            $app: true,
            include: {
              standard_model: {
                url: '/:models/{name}',
                data: {
                  client_id: null,
                },
                conditions: {
                  client_id: { $ne: null },
                },
              },
            },
          })
          .then(async (baseModel) => {
            if (!baseModel) {
              return null;
            }

            // If child collection, find the child model
            let model;
            if (childName) {
              model = baseModel.fields[childName];
              if (!model) {
                return null;
              }
            } else {
              model = baseModel;
            }

            // Fetch all content models
            const collection = collectionName(baseModel);

            const targetCollection = childName
              ? `${collection}:${childName}`
              : collection;

            const contentModels = getState().content.models.filter(
              (model) => model.collection === targetCollection,
            );

            // Fetch all content fields
            const fields = contentModels.reduce((acc, contentModel) => {
              const fields = (contentModel.fields || []).map((field) => ({
                ...field,
                content_id: contentModel.id,
                source_type: contentModel.source_type,
              }));

              acc.push(...fields);

              return acc;
            }, []);

            await populateItemTypeFields(fields);

            return {
              ...model,
              raw: model,
              collection: collectionName({
                namespace: baseModel.namespace,
                name: model.name,
              }),
              content_models: contentModels,
              item_types: contentModels.reduce((acc, contentModel) => {
                if (contentModel.item_types) {
                  acc.push(...contentModel.item_types);
                }

                return acc;
              }, []),
              ...(childName
                ? getChildCollectionLabels(baseModel, model, childName)
                : getCollectionLabels(baseModel)),
              fields,
            };
          }),
      });
    };
  },

  updateCollectionModel(id, { content_models, fields, item_types, ...data }) {
    const name = String(id).split('.').pop();
    const collection = name.replace('_', '/');

    return async (dispatch, getState) => {
      const { record, models } = getState().content;

      const modelData = {
        ...getCollectionLabels(data),
        description: data.description,
      };

      const modelUpdate = reduce(
        modelData,
        (acc, value, key) => {
          if (value && !isValueEqual(value, record[key])) {
            acc[key] = value;
          }

          return acc;
        },
        {},
      );

      let model = record;

      if (!isEmpty(modelUpdate)) {
        model = isEmpty(modelUpdate)
          ? record
          : await api.put('/data/:models/{id}', {
              id: record.id,
              ...modelUpdate,
            });

        if (model.errors) {
          dispatch(
            flash.error({
              message: 'Error updating collection',
              errors: model.errors,
            }),
          );

          return false;
        }

        dispatch({
          type: CONTENT_FETCH_COLLECTIONS,
          payload: {
            [collectionName(model)]: model,
          },
        });
      }

      const allFields = fields.reduce((acc, field) => {
        const modelId = field.content_id || `custom.admin.${collection}`;

        let list = acc[modelId];

        if (list === undefined) {
          list = [];
          acc[modelId] = list;
        }

        list.push(cleanModelField(field));

        return acc;
      }, {});

      const contentUpdates = reduce(
        allFields,
        (updates, fields, modelId) => {
          const exModel = models.find((m) => m.id === modelId);

          if (exModel) {
            switch (exModel.source_type) {
              case 'standard':
              case 'theme':
              case 'app':
                return updates;

              default:
                break;
            }

            /**
             * Standard content models are virtual, they do not actually exist,
             * they should not be created in the database
             *
             * @see defaultContentModels in reducer
             */
            if (exModel.standard) {
              return updates;
            }

            const exFields = cleanModelFields([...exModel.fields]);

            if (!isValueEqual(exFields, fields)) {
              updates[modelId] = {
                url: '/:content/{id}',
                data: {
                  id: modelId,
                  name: exModel.name || exModel.collection,
                  $set: { fields },
                },
              };
            }
          } else {
            updates.custom = {
              method: 'post',
              url: '/:content',
              data: {
                source_type: 'custom',
                source_id: 'admin',
                collection: data.app_id
                  ? `apps/${data.app_id}/${collection}`
                  : collection,
                fields,
              },
            };
          }

          return updates;
        },
        {},
      );

      for (const contentModel of content_models) {
        // Removed all fields
        if (
          isEmpty(allFields[contentModel.id]) &&
          !isEmpty(contentModel.fields) &&
          contentModel.source_type !== 'standard'
        ) {
          contentUpdates[contentModel.id] = {
            url: '/:content/{id}',
            data: {
              id: contentModel.id,
              $set: {
                fields: [],
              },
            },
          };
        }
      }

      if (!isEmpty(contentUpdates)) {
        let error;
        await api.put('/data/:batch', contentUpdates).then((results) => {
          each(results, (result) => {
            if (result.errors) {
              error = {
                message: 'Error updating content model',
                errors: result.errors,
              };
            } else {
              dispatch({
                type: CONTENT_FETCH_MODELS,
                payload: [result],
                meta: { appsEnabledById: getState().client.appsEnabledById },
              });
            }
          });
        });
        if (error) {
          dispatch(flash.error(error));
          return false;
        }
      }

      return dispatch(this.fetchCollectionModel(id));
    };
  },

  updateCollectionModelFields(id, { fields }) {
    return async (dispatch, getState) => {
      const { models } = getState().content;

      const allFields = fields.reduce((acc, field) => {
        const modelId = field.content_id;
        if (modelId) {
          let list = acc[modelId];

          if (list === undefined) {
            list = [];
            acc[modelId] = list;
          }

          list.push(cleanModelField(field));
        }
        return acc;
      }, {});

      const contentUpdates = reduce(
        allFields,
        (updates, fields, modelId) => {
          const exModel = models.find((m) => m.id === modelId);
          if (exModel) {
            if (
              exModel.source_type === 'theme' ||
              exModel.source_type === 'app'
            ) {
              return updates;
            }
            const exFields = cleanModelFields([...exModel.fields]);
            if (!isValueEqual(exFields, fields)) {
              updates[modelId] = {
                url: '/:content/{id}',
                data: {
                  id: modelId,
                  $set: {
                    fields,
                  },
                },
              };
              dispatch({
                type: CONTENT_FETCH_MODELS,
                payload: [{ ...exModel, fields }],
                meta: { appsEnabledById: getState().client.appsEnabledById },
              });
            }
          }
          return updates;
        },
        {},
      );

      if (!isEmpty(contentUpdates)) {
        let error;
        await api.put('/data/:batch', contentUpdates).then((results) => {
          each(results, (result) => {
            if (result.errors) {
              error = {
                message: 'Error updating content model',
                errors: result.errors,
              };
            } else {
              dispatch({
                type: CONTENT_FETCH_MODELS,
                payload: [result],
                meta: { appsEnabledById: getState().client.appsEnabledById },
              });
            }
          });
        });
        if (error) {
          dispatch(flash.error(error));
          return false;
        }
      }
    };
  },

  createCollectionModel(data) {
    return async (dispatch) => {
      const namespace =
        data.namespace ||
        (data.namespace_select === 'content' ? 'content' : null);
      const collection = collectionName({ namespace, name: data.collection });
      const collections = await dispatch(this.fetchCollections());
      if (collections[collection]) {
        dispatch(flash.error(`Collection '${collection}' already exists`));
        return;
      }
      return dispatch({
        type: CONTENT_CREATE_MODEL,
        payload: api.post('/data/:content', {
          label: data.label,
          name: data.collection,
          source_type: 'custom',
          source_id: 'admin',
          description: data.description,
          collection,
          fields: data.fields,
          root: data.root,
        }),
      });
    };
  },

  deleteCollectionModel(id) {
    const name = String(id).split('.').pop();
    const collection = name.replace('_', '/');

    return async (dispatch, getState) => {
      const models = await dispatch(this.fetchModels({ collection }));

      const deletes = models.reduce((acc, model) => {
        acc[model.id] = {
          method: 'delete',
          url: '/:content/{id}',
          data: {
            id: model.id,
          },
        };

        return acc;
      }, {});

      if (!isEmpty(deletes)) {
        await api.put('/data/:batch', deletes).then((result) => {
          each(result, (res) => {
            if (res.errors) {
              throw new Error(
                `Error deleting content model: ${JSON.stringify(res.errors)}`,
              );
            }
          });
        });
      }

      // If still existing...
      const collections = await dispatch(this.fetchCollections());
      const model = collections[collection];

      if (model && model.client_id) {
        await api.delete('/data/:models/{id}', {
          id: model.id,
        });
      }

      return dispatch({
        type: CONTENT_DELETE_MODEL,
        payload: collection,
      });
    };
  },

  updateDefaults(models, content) {
    return async (dispatch, getState) => {
      const updates = models.reduce((updates, model) => {
        if (isEmpty(model.defaults)) {
          return updates;
        }

        const defaults = reduce(
          content,
          (acc, val, key) => {
            if (model.defaults[key] !== undefined) {
              acc[key] = val;
            }
            return acc;
          },
          {},
        );

        if (!isValueEqual(defaults, model.defaults)) {
          updates[model.id] = {
            url: '/:content/{id}',
            data: {
              id: model.id,
              $set: {
                defaults,
              },
            },
          };
        }

        return updates;
      }, {});

      if (!isEmpty(updates)) {
        const result = await api.put('/data/:batch', updates);
        const payload = map(result, (res) => res).filter((res) => !res.errors);

        dispatch({
          type: CONTENT_FETCH_MODELS,
          payload,
          meta: { appsEnabledById: getState().client.appsEnabledById },
        });
      }
    };
  },

  loadFieldsDeprecated(model) {
    return (dispatch, getState) => {
      const { content } = getState();
      if (content.fieldsDeprecated[model]) {
        return Promise.resolve({ results: content.fieldsDeprecated[model] });
      }
      return dispatch(this.fetchFieldsDeprecated(model));
    };
  },

  loading(payload) {
    return { type: CONTENT_LOADING, payload };
  },

  /**
   * Pulls all content type definitions for a list of model fields.
   *
   * It looks at the fields, extracts the ids and get's full definitions from the
   * database via an API request.
   *
   * based on SettingsMenu.fetchContentTypesByModel but extracts the API logic
   * from the component.
   */
  fetchContentTypes(modelFields) {
    return async (dispatch, getState) => {
      const contentTypeIds = findContentTypeIds(modelFields);
      const contentTypes = await api.getLocalized('/data/:content', {
        id: { $in: contentTypeIds.all },
      });

      const result = {
        ...contentTypeIds,
        configs: reduce(
          contentTypes.results,
          (acc, config) => {
            acc[config.id] = config;
            return acc;
          },
          {},
        ),
      };

      return dispatch({
        type: CONTENT_FETCH_CONTENT_TYPES,
        payload: result,
      });
    };
  },

  /**
   * Get the database entry for an item with `id` belonging to `model` via an API
   * request.
   */
  fetchModelRecord(model, id, recordParams = {}) {
    const recordUrl = `${model}/${id}`;

    return async (dispatch, getState) => {
      const {
        content: { collections },
        themeConfig: { pages }, // content/model pages + editor.json config pages
      } = getState();

      const recordData = await api.getLocalized(`/data/${recordUrl}`, {
        $content: true,
        ...recordParams,
      });

      return dispatch({
        type: CONTENT_FETCH_MODEL_RECORD,
        payload: {
          data: recordData,
          model: model,
          modelLabel: collections[model].label,
          recordUrl: recordUrl, // data record, API URL:
          themeUrl: modelPageThemeUrl(model, pages, recordData), // theme (origin, horizon) only URL
        },
      });
    };
  },

  /**
   * Get a list of database entries belonging to `model` via an API request.
   */
  fetchModelRecordList(
    model,
    params = {
      fields: 'id, name',
      limit: 100,
    },
  ) {
    return async (dispatch, getState) => {
      const response = await api.get(`/data/${model}`, params);

      dispatch({
        type: CONTENT_FETCH_MODEL_RECORD_LIST,
        payload: {
          model: model,
          records: response?.results || [],
        },
      });
    };
  },

  /**
   * Save `model` by overriding the `values`.
   *
   * It makes a POST request for the `model` sending the `values` as payload.
   */
  saveModelRecord(model, values) {
    return async (dispatch, getState) => {
      const {
        content: { collections },
        themeConfig: { pages }, // content/model pages + editor.json config pages
      } = getState();
      const recordData = await api.post(`/data/${model}`, values);
      const recordUrl = `${model}/${recordData.id}`;

      return dispatch({
        type: CONTENT_FETCH_MODEL_RECORD,
        payload: {
          data: recordData,
          model: model,
          modelLabel: collections[model].label,
          recordUrl: recordUrl,
          themeUrl: modelPageThemeUrl(model, pages, recordData), // theme (origin, horizon) only URL
        },
      });
    };
  },

  /**
   * Delete a model record based on its id
   */
  deleteModelRecord(model, id) {
    return async (dispatch) => {
      await api.delete(`/data/${model}/${id}`);

      return dispatch({
        type: CONTENT_DELETE_MODEL_RECORD,
      });
    };
  },
};

export default exportActions;

export const initialState = {
  instance: null,
  record: null,
  recordsLoaded: {},
  models: [],
  modelsByCollection: {},
  modelsByApp: {},
  views: [],
  viewsByCollection: {},
  viewsByApp: {},
  currentCollection: null,
  currentView: null,
  hiddenModels: [],
  collectionModels: [],
  collections: {},
  collectionOptions: [],
  idsDeprecated: {},
  fieldsDeprecated: {},
  loading: false,
  contentTypes: {},
  data: [],
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET':
      return { ...initialState };

    case CONTENT_FETCH: {
      const payload = action.payload || {};

      if (payload.error || payload.errors) {
        return state;
      }

      return {
        ...state,
        instance: payload,
      };
    }

    case CONTENT_FETCH_MODEL:
      return {
        ...state,
        record: action.payload,
      };

    case CONTENT_CREATE_MODEL:
      return state;

    case CONTENT_DELETE_MODEL: {
      const collection = action.payload;

      return {
        ...state,
        record: null,
        models: state.models.filter((m) => m.collection !== collection),
        collections: omit({ ...state.collections }, collection),
        collectionModels: state.models.filter(
          (m) => m.collection !== collection,
        ),
        modelsByCollection: {
          ...state.modelsByCollection,
          [collection]:
            state.modelsByCollection[collection]?.filter(
              (m) => m.collection !== collection,
            ) || [],
        },
        viewsByCollection: {
          ...state.viewsByCollection,
          [collection]:
            state.viewsByCollection[collection]?.filter(
              (m) => m.collection !== collection,
            ) || [],
        },
      };
    }

    case CONTENT_FETCH_MODELS: {
      const payload = action.payload || [];

      if (payload.error || payload.errors) {
        return state;
      }

      const { collection: loadCollection, appsEnabledById } = action.meta || {};

      const collectionKeys = Object.keys(state.collections).reduce(
        (acc, key) => {
          acc[key] = [];
          return acc;
        },
        {},
      );

      // Normalize models from payload
      const contentModels = payload.reduce((acc, m) => {
        // Should be normalized only if there is collection model
        // since collection model is used to normalize views
        if (state.collections[m.collection]) {
          acc.push(
            normalizeContentModel(m, state.collections, appsEnabledById),
          );
        }

        return acc;
      }, []);

      const contentViews = contentModels.reduce((acc, contentModel) => {
        acc.push(...contentModel.views);
        return acc;
      }, []);

      // Save models by app for displaying in app settings
      // Clone to avoid mutating
      const modelsByApp = contentModels.reduce((acc, model) => {
        if (model.app_id) {
          let list = acc[model.app_id];

          if (list === undefined) {
            list = [];
            acc[model.app_id] = list;
          }

          list.push(cloneDeep(model));
        }

        return acc;
      }, {});

      const viewsByApp = contentViews.reduce((acc, view) => {
        if (view.app_id) {
          let list = acc[view.app_id];

          if (list === undefined) {
            list = [];
            acc[view.app_id] = list;
          }

          list.push(cloneDeep(view));
        }

        return acc;
      }, {});

      // Combine with default models for all collections
      const allContentModels = [
        ...contentModels,
        ...defaultContentModels(
          contentModels,
          state.collections,
          appsEnabledById,
        ),
      ];

      const allContentViews = allContentModels.reduce((acc, contentModel) => {
        acc.push(...contentModel.views);
        return acc;
      }, []);

      // Filter out ones that are not enabled by apps
      const availableModels = allContentModels.filter(
        (model) => !model.app_id || appsEnabledById[model.app_id],
      );

      const availableViews = allContentViews.filter(
        (view) => !view.app_id || appsEnabledById[view.app_id],
      );

      // Organize models and views by collection for easy access
      const modelsByCollection = availableModels.reduce((acc, contentModel) => {
        const key = contentModel.childCollection || contentModel.collection;

        let list = acc[key];

        if (list === undefined) {
          list = [];
          acc[key] = list;
        }

        list.push(contentModel);
        return acc;
      }, collectionKeys);

      const viewsByCollection = availableViews.reduce((acc, contentView) => {
        const key = contentView.childCollection || contentView.collection;

        let list = acc[key];

        if (list === undefined) {
          list = [];
          acc[key] = list;
        }

        list.push(contentView);
        return acc;
      }, collectionKeys);

      // Assign to globals for access in other functions
      Object.assign(CONTENT_MODELS, modelsByCollection);
      Object.assign(CONTENT_MODEL_VIEWS, viewsByCollection);

      // Assign model properties to child collection fields
      // This must occur after all models and views are normalized
      assignModelsToChildCollections(availableModels);

      return {
        ...state,
        models: [
          ...state.models.filter(
            (model) =>
              !availableModels.find(
                (contentModel) => model.id === contentModel.id,
              ),
          ),
          ...availableModels,
        ],
        hiddenModels: [
          ...state.hiddenModels.filter(
            (model) =>
              !availableModels.find(
                (contentModel) => model.id === contentModel.id,
              ),
          ),
          ...availableModels.filter((m) => !m.collection),
        ],
        collectionModels: [
          ...state.collectionModels.filter(
            (model) =>
              !availableModels.find(
                (contentModel) => model.id === contentModel.id,
              ),
          ),
          ...availableModels.filter((m) => Boolean(m.collection)),
        ],
        modelsByCollection: {
          ...(loadCollection ? { [loadCollection]: [] } : undefined),
          ...state.modelsByCollection,
          ...modelsByCollection,
        },
        modelsByApp: {
          ...state.modelsByApp,
          ...modelsByApp,
        },
        viewsByCollection: {
          ...(loadCollection ? { [loadCollection]: [] } : undefined),
          ...state.viewsByCollection,
          ...viewsByCollection,
        },
        viewsByApp: {
          ...state.viewsByApp,
          ...viewsByApp,
        },
      };
    }

    // Note: collections are data models in this context
    case CONTENT_FETCH_COLLECTIONS: {
      const payload = action.payload || {};

      if (payload.error || payload.errors) {
        return state;
      }

      const collections = {
        ...state.collections,
        ...payload,
      };

      Object.assign(COLLECTION_MODELS, collections);

      return {
        ...state,
        collections,
        collectionOptions: map(collections, (value, id) => ({
          value: id,
          label: value.plural || value.label,
          model: value,
        })),
      };
    }

    case CONTENT_FETCH_VIEW:
      return {
        ...state,
        ...action.payload,
      };

    case CONTENT_FETCH_FIELDS_DEPRECATED: {
      const payload = action.payload || {};

      if (payload.error || payload.errors) {
        return state;
      }

      if (action.meta.model) {
        return {
          ...state,
          fieldsDeprecated: {
            ...state.fieldsDeprecated,
            [action.meta.model]: payload.results,
          },
          idsDeprecated: payload.results.reduce(
            (acc, type) => {
              acc[type.id] = type;
              return acc;
            },
            { ...state.idsDeprecated },
          ),
        };
      }

      if (action.meta.id) {
        return {
          ...state,
          idsDeprecated: {
            ...state.idsDeprecated,
            [action.meta.id]: payload,
          },
        };
      }

      return state;
    }

    case CONTENT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case CONTENT_FETCH_CONTENT_TYPES:
      return {
        ...state,
        contentTypes: action.payload,
      };

    case CONTENT_FETCH_MODEL_RECORD:
      return {
        ...state,
        record: action.payload,
      };

    case CONTENT_FETCH_MODEL_RECORD_LIST: {
      const { model, records } = action.payload;

      return {
        ...state,
        recordsLoaded: {
          [model]: records,
        },
      };
    }

    case CONTENT_DELETE_MODEL_RECORD:
      return state;

    default:
      return state;
  }
}

function getCollectionLabels(model) {
  const plural = model.plural || pluralize(model.label);
  const singular = model.singular || singularize(model.label);
  return {
    label: model.label,
    plural,
    singular,
    pluralLower: plural.toLowerCase(),
    singularLower: singular.toLowerCase(),
  };
}

function getChildCollectionLabels(model, field, childName) {
  const plural = getChildCollectionPlural(childName, field);
  const singular = getChildCollectionSingular(childName, field);
  return {
    label: getChildCollectionLabel(model, field),
    plural,
    singular,
    pluralLower: plural.toLowerCase(),
    singularLower: singular.toLowerCase(),
  };
}

function getChildCollectionLabel(parent, child) {
  const parentLabel = singularize(
    String(parent.plural || parent.label).toLowerCase(),
  );
  const childLabel = pluralize(
    String(child.plural || child.label).toLowerCase(),
  );
  if (childLabel.indexOf(parentLabel) === 0) {
    return capitalize(childLabel);
  }
  return capitalize(`${parentLabel} ${childLabel}`);
}

function getChildCollectionPlural(fieldName, child) {
  if (child.plural) {
    return child.plural;
  }
  return capitalize(pluralize(fieldName));
}

function getChildCollectionSingular(fieldName, child) {
  if (child.singular) {
    return child.singular;
  }
  return capitalize(singularize(fieldName));
}

function assignCollectionLookupQueries(collections, results) {
  // First define child expands
  for (const model of results) {
    for (const field of model.fields || []) {
      if (field.collection_parent_id && field.collection_parent_field) {
        const parentField = model.fields.find(
          (pf) => pf.id === field.collection_parent_id,
        );
        if (parentField) {
          parentField.childExpands = parentField.childExpands || [];
          parentField.childExpands.push(`${field.collection_parent_field}:1`);
        }
      }
    }
  }
  // Then assign lookup queries
  for (const model of results) {
    for (const field of model.fields || []) {
      if (field.collection) {
        const lookupTypeId = `lookup_${field.collection}`;
        const lookup = PREDEFINED_LOOKUP_TYPES[lookupTypeId] || {};
        const collection = collections[field.collection];
        PREDEFINED_LOOKUP_TYPES[lookupTypeId] = {
          url: `/${field.collection}`,
          data: {
            ...(lookup.data ? lookup.data : {}),
            expand: [
              ...(field.childExpands || []),
              ...(lookup.data && lookup.data.expand ? lookup.data.expand : []),
              ...(collection ? collection.nameFieldExpand || [] : []),
            ],
          },
        };
      }
    }
  }
}
