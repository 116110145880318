import PaymentFlow from './PaymentFlow';

export default class PayPalPaymentFlow extends PaymentFlow {
  customAmountLockedMessage =
    'Custom amount is not availalble until you complete PayPal onboarding';

  allowCustomAmount() {
    const isPpcpProgressive = this.order.payments.results.some(
      (x) => x.paypal_intent === 'capture',
    );
    return !isPpcpProgressive;
  }
}
