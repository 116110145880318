import React from 'react';

import Modal from 'components/modal';
import { Form } from 'components/form';

import OrderItems from './items';

export default class OrderItemsEdit extends React.PureComponent {
  onChangeForm = (_values, edited) => {
    const { onEdited } = this.props;

    if (typeof onEdited === 'function') {
      onEdited(edited);
    }
  };

  render() {
    const {
      record,
      loading,
      editItems,
      onSubmitEditItems,
      onClickEditItems,
      onClickEditSingleItem,
      onClickRemoveEditItem,
      onClickAddItem,
      onCloseEditItems,
      onChangeEditItemInput,
      subscription = false,
    } = this.props;

    return (
      <Form
        onSubmit={onSubmitEditItems}
        onChange={this.onChangeForm}
        values={record ? record.items : undefined}
        autoFocus={false}
        autoFocusEmpty={false}
      >
        <Modal
          title={subscription ? 'Edit invoice items' : 'Edit items'}
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'secondary',
              className: 'button-cancel',
              onClick: onClickEditItems,
            },
          ]}
          cancel={false}
          loading={loading}
          onClose={onCloseEditItems}
          width={960}
        >
          <fieldset>
            <OrderItems
              subscription={subscription}
              record={record}
              editing={true}
              editItems={editItems}
              onClickAddItem={onClickAddItem}
              onClickRemoveEditItem={onClickRemoveEditItem}
              onChangeEditItemInput={onChangeEditItemInput}
              onClickEditSingleItem={onClickEditSingleItem}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
