import React from 'react';
import Collection from './Collection';
import InvoiceStatus from 'components/pages/invoice/status';
import { replaceLocationQuery } from 'actions/data';
import { formatDate } from 'utils';
import { accountName } from 'utils/account';
import { subscriptionTitle } from 'utils/subscription';
import api from 'services/api';

export const query = {
  expand: ['account', 'subscription.product', 'order'],
};

export const tabs = {
  default: {
    label: 'All invoices',
  },
  open: {
    label: 'Open',
    query: {
      paid: false,
      closed: false,
      void: { $ne: true },
    },
  },
  paid: {
    label: 'Paid',
    query: {
      paid: true,
    },
  },
  unpaid: {
    label: 'Unpaid',
    query: {
      paid: false,
      closed: true,
    },
  },
  void: {
    label: 'Void',
    query: {
      void: true,
    },
  },
};

export const filters = {
  open: {
    label: 'Open',
    query: {
      paid: false,
      closed: false,
      void: null,
    },
  },
  paid: {
    label: 'Paid',
    query: {
      paid: true,
    },
  },
  unpaid: {
    label: 'Unpaid',
    query: {
      paid: false,
      close: true,
    },
  },
  void: {
    label: 'Void',
    query: {
      void: true,
    },
  },
};

export const fields = {
  number: {
    label: 'Invoice',
    type: 'link',
    url: '/invoices/{id}',
    default: true,
  },
  date_created: {
    label: 'Date',
    func: (invoice) =>
      invoice.date_period_start ? (
        <span>
          {formatDate(invoice.date_period_start, 'short')} &mdash;{' '}
          {formatDate(invoice.date_period_end, 'short')}
        </span>
      ) : (
        formatDate(invoice.date_created, 'age')
      ),
  },
  account: {
    label: 'Customer',
    func: (invoice) => accountName(invoice.account),
  },
  source_model: {
    label: 'Source',
    func: (invoice) => {
      if (invoice.source_model === 'subscriptions') {
        return subscriptionTitle(invoice.subscription);
      } else if (invoice.source_model === 'orders') {
        return `Order #${invoice.order.number}`;
      }
      return 'Unknown';
    },
  },
  status: {
    label: 'Status',
    func: (invoice) => {
      return <InvoiceStatus invoice={invoice} />;
    },
  },
  grand_total: {
    label: 'Total',
    type: 'currency',
    rounded: true,
  },
};

export default class InvoicesContainer extends React.Component {
  static async onEnter(store, nextState, replace) {
    if (replaceLocationQuery(store, nextState, replace)) {
      return;
    }
  }

  async searchHandler(search, limit) {
    if (!search) {
      return {};
    }
    const productQuery = {
      search,
      limit: limit || 1000,
      fields: 'id',
      type: 'subscription',
    };
    const findProducts = api.get('/data/products', productQuery);
    const findAccounts = api.get('/data/accounts', {
      search,
      limit: limit || 1000,
      fields: 'id',
    });
    const { foundProducts, foundAccounts } = {
      foundProducts: await findProducts,
      foundAccounts: await findAccounts,
    };
    return foundProducts.count || foundAccounts.count
      ? {
          $or: [
            {
              'items.product_id': {
                $in: foundProducts.results.map((product) => product.id),
              },
            },
            {
              account_id: {
                $in: foundAccounts.results.map((account) => account.id),
              },
            },
          ],
        }
      : productQuery;
  }

  render() {
    return (
      <Collection
        title="Invoices"
        uri="/invoices"
        model="invoices"
        tabs={tabs}
        filters={filters}
        fields={fields}
        bulkActions={undefined}
        headerActions={undefined}
        {...this.props}
        query={{
          ...query,
          ...(this.props.query || {}),
        }}
        searchHandler={this.searchHandler}
      />
    );
  }
}
