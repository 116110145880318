import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import store from '../store/instance';

import { writeToClipboard } from 'utils/clipboard';
import {
  makePurchaseLinkUrl,
  getDomainFromSettings,
} from 'utils/purchase-link';

import actions from 'actions';

import Icon from 'components/icon';
import Status from 'components/status';
import Button from 'components/button';
import ViewLoading from 'components/view/loading';

import Collection from './Collection';

let DOMAIN = window.location.hostname;

export const query = {
  //
};

export const tabs = {
  default: {
    label: 'All links',
  },
  active: {
    label: 'Active',
    query: {
      active: true,
    },
  },
  inactive: {
    label: 'Inactive',
    query: {
      active: false,
    },
  },
};

export const filters = {
  grand_total: {
    label: 'Total',
    operators: ['gt', 'lt', 'eq'],
    type: 'currency',
  },
  date_created: {
    label: 'Date',
    operators: ['after', 'before'],
    type: 'date',
  },
  status: {
    label: 'Status',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'completed', label: 'Inactive' },
    ],
    func(query) {
      switch (query) {
        case 'active':
          return { active: true };

        case 'completed':
          return { active: false };

        default:
          return {};
      }
    },
  },
};

/**
 * @param {React.MouseEvent} event
 * @returns {void}
 */
function onCopyLink(event) {
  event.stopPropagation();

  writeToClipboard(makePurchaseLinkUrl(event.currentTarget.dataset.id, DOMAIN))
    .then(() => {
      return store.dispatch(
        actions.flash.success('Purchase link copied to clipboard'),
      );
    })
    .catch(() => {
      return store.dispatch(
        actions.flash.error(
          'Error adding link to clipboard. Please select the link text and copy it manually',
        ),
      );
    });
}

export const fields = {
  name: {
    label: 'Name',
    type: 'link',
    url: '/purchase-links/{id}',
    default: true,
  },
  purchase_link: {
    label: 'Link',
    sort: false,
    func(item) {
      return (
        <Button
          data-id={item.id}
          styleType="secondary"
          title={makePurchaseLinkUrl(item.id, DOMAIN)}
          onClick={onCopyLink}
          type="button"
          size="xs"
        >
          <Icon fa="copy" /> Copy link
        </Button>
      );
    },
  },
  active: {
    label: 'Status',
    func(item) {
      return item.active ? (
        <Status type="positive">Active</Status>
      ) : (
        <Status type="muted">Inactive</Status>
      );
    },
  },
  date_created: {
    url: '/purchase-links/{id}',
    label: 'Created',
    type: 'date',
  },
  items: {
    label: 'Items',
    func(item) {
      return item.items.length;
    },
  },
  grand_total: {
    label: 'Item total',
    type: 'currency',
    rounded: true,
  },
};

const modelParams = {
  modelName: 'purchase links',
  bulkParams: {
    sortKey: 'date_created',
  },
};

export const bulkActions = [
  {
    modal: 'BulkDelete',
    label: 'Delete',
    type: 'danger inverse',
    params: modelParams,
  },
];

const headerActions = [
  { label: 'New purchase link', link: '/purchase-links/new' },
];

class PurchaseLinks extends React.Component {
  state = { loaded: false };

  componentDidMount() {
    this.props.loadSettings().then(() => {
      DOMAIN = getDomainFromSettings(store.getState().settings.purchaselinks);
      this.setState({ loaded: true });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.bulk.running && !this.props.data.bulk.running) {
      const {
        data: { query, locationQuery },
        fetchCollection,
      } = this.props;

      fetchCollection('purchaselinks', query, locationQuery);
    }
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return (
      <div>
        <Collection
          ref="collection"
          {...this.props}
          title="Purchase links"
          uri="/purchase-links"
          model="purchaselinks"
          emptyTitle="Direct checkout with purchase links"
          emptyDescription="Use purchase links to send customers directly to your checkout, pre-filled with products you choose to sell."
          tabs={tabs}
          filters={filters}
          fields={fields}
          selectable={true}
          bulkActions={bulkActions}
          headerActions={headerActions}
          query={query}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ data, settings }) => ({ data, settings });

const mapDispatchToProps = (dispatch) => ({
  loadSettings() {
    return dispatch(actions.settings.fetch('purchaselinks'));
  },

  fetchCollection(model, query, locationQuery = {}) {
    return dispatch(actions.data.fetchCollection(model, query, locationQuery));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseLinks);
