export const getSortConditions = (sortObj) => {
  if (typeof sortObj !== 'object' || Object.keys(sortObj).length === 0) {
    return null;
  }

  const formattedSortObj = {};
  // Need to find key in sort[key]
  // Example input: sort[account.email], example output: account.email
  const regex = /^sort\[(.+?)\]$/;
  for (const key of Object.keys(sortObj)) {
    const match = key.match(regex);
    if (match) {
      formattedSortObj[match[1]] = sortObj[key];
    }
  }

  return formattedSortObj;
};

/**
 * Returns a new state object with the updated sort value based on the clicked sort field.
 * @param {String} sortState - The current sort state.
 * @param {Object} field - The clicked sort field.
 * @returns {Object} A new state object with the updated sort value.
 */
export function getSortState(sortState, field) {
  const fieldPath = field.path;
  const sortField = String(fieldPath);
  let direction = 'desc';

  if (sortState) {
    const [stateField, stateDirection] = sortState.split(' ');

    if (fieldPath.includes(stateField) && stateDirection === 'desc') {
      direction = 'asc';
    }
  }

  return { sort: `${sortField} ${direction}` };
}
