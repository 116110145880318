import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import Modal from 'components/modal';
import IconPayment from 'components/icon/payment';
import { Form, Field } from 'components/form';
import { formatCurrency } from 'utils';

export default class OrderFulfillmentGiftcard extends React.PureComponent {
  static propTypes = {
    record: PropTypes.object.isRequired,
    itemId: PropTypes.string,
    onClickFulfillmentGiftcard: PropTypes.func,
    onSubmitFulfillmentGiftcard: PropTypes.func,
  };

  render() {
    const { record, onClickFulfillmentGiftcard, onSubmitFulfillmentGiftcard } =
      this.props;

    const deliverableItems = filter(
      record.items,
      (item) => item.delivery === 'giftcard' && item.quantity_deliverable > 0,
    );

    return (
      <Form onSubmit={onSubmitFulfillmentGiftcard}>
        <Modal
          title="Send gift card"
          actions={[
            {
              label: `Create and send`,
              type: 'submit',
            },
            {
              label: 'Cancel',
              type: 'cancel',
              onClick: onClickFulfillmentGiftcard,
            },
          ]}
          width={570}
          cancel={false}
          onClose={onClickFulfillmentGiftcard}
        >
          <fieldset>
            <Field
              type="radio"
              name="order_item_id"
              label="Choose a gift card to fulfill"
              options={deliverableItems.map((item) => ({
                value: item.id,
                label: (
                  <span className="order-payment-radio-options">
                    <span className="order-payment-radio-label nowrap">
                      <IconPayment method="giftcard" />
                      {item.product
                        ? item.product.name
                        : 'Unknown gift card product'}
                    </span>
                    <span className="order-payment-radio-value">
                      {formatCurrency(item.price, record.currency)}
                    </span>
                  </span>
                ),
              }))}
              defaultValue={deliverableItems[0] && deliverableItems[0].value}
              required={true}
              stacked={true}
              className="order-payment-fieldset"
            />
            <Field
              type="text"
              name="send_email"
              label="Send to email address"
              rules="email"
              required={true}
              defaultValue={record.account.email}
            />
            <Field
              type="textarea"
              name="send_note"
              label="Note to recipient"
              placeholder="Optional"
              autoSize={true}
              rows={3}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
