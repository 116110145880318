import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import pt from 'prop-types';

import { parseNumber } from 'utils';

import { hasQuantityMaxInIndexKey, getQuantityMaxValue } from 'utils/product';

import { Field, FieldLocalized, Fieldtable } from 'components/form';

function getInitialValue(props) {
  return props.values || [];
}

export default class Prices extends React.PureComponent {
  static propTypes = {
    name: pt.string,
    values: pt.array,
    currency: pt.string,
    settings: pt.object,
    disabled: pt.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      prevValues: props.values,
      value: getInitialValue(props),
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.values !== state.prevValues) {
      return { prevValues: props.values, value: getInitialValue(props) };
    }

    return null;
  }

  renderTableHeading = () => {
    const { settings } = this.props;

    const hasGroups =
      get(settings.accounts, 'groups') && settings.accounts.groups.length > 0;

    return [
      <th key="price">Price rule</th>,
      hasGroups && <th key="account_group">Customer group</th>,
      <th key="quantity_min">Min. quantity</th>,
      <th key="quantity_max">Max. quantity</th>,
    ];
  };

  renderTableValue = ({ value, index, disabled, onChange }) => {
    const { settings, currency } = this.props;

    const hasGroups =
      get(settings.accounts, 'groups') && settings.accounts.groups.length > 0;

    const currentQuantityMaxValue = get(
      find(value, hasQuantityMaxInIndexKey),
      'quantity_max',
    );

    const quantityMax = getQuantityMaxValue(
      currentQuantityMaxValue,
      value.quantity_max,
    );

    return [
      <td key="price" style={{ width: hasGroups ? 175 : 'auto' }}>
        <FieldLocalized
          type="currency"
          name="price"
          defaultValue={value.price}
          currency={currency}
          localeValue={value.$currency}
          required={true}
          disabled={disabled}
          onChange={onChange}
          data-index={index}
          autoFocus={true}
        />
      </td>,
      hasGroups && (
        <td key="account_group" style={{ minWidth: hasGroups ? 140 : 'auto' }}>
          <Field
            type="select"
            name="account_group"
            options={settings.accounts.groups}
            defaultValue={value.account_group}
            clearIcon
            placeholder="All Customers"
            disabled={disabled}
            onChange={onChange}
            data-index={index}
          />
        </td>
      ),
      <td key="quantity_min" style={{ width: hasGroups ? 130 : 'auto' }}>
        <Field
          type="number"
          name="quantity_min"
          defaultValue={value.quantity_min}
          placeholder="1"
          disabled={disabled}
          onChange={onChange}
          data-index={index}
        />
      </td>,
      <td
        className="col"
        key="quantity_max"
        style={{ width: hasGroups ? 130 : 'auto' }}
      >
        <Field
          type="number"
          name="quantity_max"
          defaultValue={quantityMax}
          placeholder="No limit"
          disabled={disabled}
          onChange={onChange}
          data-index={index}
          rules={[
            {
              rule: () =>
                quantityMax === '' ||
                quantityMax === null ||
                quantityMax === undefined ||
                parseNumber(quantityMax) >= parseNumber(value.quantity_min),
              hint: () => 'Should be greater than or equal to minimum quantity',
            },
          ]}
        />
      </td>,
    ];
  };

  render() {
    const { disabled, name } = this.props;

    return (
      <div className="product-prices">
        <Fieldtable
          label="Price rule"
          name={name}
          defaultValue={this.state.value}
          renderHeading={this.renderTableHeading}
          renderValue={this.renderTableValue}
          disabled={disabled}
          sortable={true}
          inline={true}
        />
      </div>
    );
  }
}
