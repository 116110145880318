import { FETCH_PUBLISH_STATUS, UPDATE_PUBLISH_STATUS } from '../actions/types';

const initialState = {
  showEditor: false,
  publishStatus: {
    logs: [],
    renderedLogs: {},
    checkoutCurrent: true,
    checkoutPublishing: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'HIDE_EDITOR':
      return {
        ...state,
        showEditor: false,
      };
    case 'SHOW_EDITOR':
      return {
        ...state,
        showEditor: true,
      };
    case FETCH_PUBLISH_STATUS:
      const { pushed, current } = state.publishStatus;
      return {
        ...state,
        publishStatus: {
          ...state.publishStatus,
          ...action.payload,
          // Make sure pushed doesn't get overridden by fetch
          pushed:
            current === false && pushed === false
              ? false
              : action.payload.pushed,
          publishing: action.payload.publishing || false,
        },
      };
    case UPDATE_PUBLISH_STATUS:
      return {
        ...state,
        publishStatus: {
          ...state.publishStatus,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
