import React from 'react';
import { connect } from 'react-redux';
import Collection from 'containers/Collection';
import { formatNumber } from 'utils';
import { modelEndpoint, countModelFields } from 'utils/model';

import {
  contentCollectionLabel,
  contentCollectionSourceLabel,
} from 'utils/content';

import { truncatedText } from 'utils/collection';

import {
  STANDARD_QUERY,
  APPS_QUERY,
  CUSTOM_QUERY,
  COLLECTION_QUERY,
} from 'constants/content';

let APPS_BY_ID = {};

export const query = {
  sort: 'label asc',
  ...COLLECTION_QUERY,
  include: {
    item_count: {
      url: '{namespace|/}/{name}/:count',
    },
    item_app_count: {
      url: '/apps/{app_id}/{namespace|/}{name}/:count',
    },
  },
  limit: 1000,
};

export let tabs = {
  default: {
    label: 'All models',
  },
  standard: {
    label: 'Standard',
    query: STANDARD_QUERY,
  },
  apps: {
    label: 'Apps',
    query: APPS_QUERY,
  },
  custom: {
    label: 'Custom',
    query: CUSTOM_QUERY,
  },
};

export const filters = {
  date_created: {
    label: 'Created',
    operators: ['after', 'before'],
    type: 'date',
  },
  date_updated: {
    label: 'Updated',
    operators: ['after', 'before'],
    type: 'date',
  },
  kind: {
    label: 'Kind',
    options: [
      { value: 'standard', label: 'Standard' },
      { value: 'custom', label: 'custom' },
    ],
    func: (query) => {
      if (query === 'standard') {
        return STANDARD_QUERY;
      }
      if (query === 'custom') {
        return CUSTOM_QUERY;
      }
    },
  },
};

export const fields = {
  label: {
    label: 'Model',
    default: true,
    url: ({ api, namespace, name }) =>
      `/settings/models/${api}.${namespace ? `${namespace}_${name}` : name}`,
    func: (model) => (
      <>
        <div className="model-fields-first-cell">
          {contentCollectionLabel(model)}
        </div>
        <code className="muted">
          {modelEndpoint(model, APPS_BY_ID[model.app_id])}
        </code>
      </>
    ),
  },
  item_count: {
    label: 'Records',
    sort: false,
    func: ({ item_count, item_app_count }) => {
      return formatNumber(item_app_count || item_count || 0);
    },
  },
  fields: {
    label: 'Content fields',
    sort: false,
    func: countModelFields,
  },
  source: {
    label: 'Source',
    func: (model) => {
      const label = contentCollectionSourceLabel(
        model,
        APPS_BY_ID[model.app_id],
      );
      if (label === 'Standard') {
        return <span className="muted">{label}</span>;
      }
      return label;
    },
  },
  description: {
    label: 'Description',
    func: ({ description }) => truncatedText(description, 50),
  },
  date_updated: {
    label: 'Updated',
    type: 'date',
  },
};

export const headerActions = [
  {
    label: 'API reference',
    fa: 'book',
    type: 'sub',
    link: 'https://developers.swell.is/backend-api/introduction',
    target: 'blank',
    showAlways: true,
  },
  { label: 'New model', link: '/settings/models/new', showAlways: true },
];

const mapStateToProps = (state) => ({
  client: state.client,
});

const mapDispatchToProps = (dispatch) => ({
  //
});

class Models extends React.Component {
  constructor(props) {
    super(props);
    APPS_BY_ID = props.client.appsById;
  }

  render() {
    const {
      location: { query: locationQuery },
    } = this.props;

    const updatedQuery = {
      ...query,
      ...(locationQuery?.tab === 'apps' && {
        include: {
          item_count: {
            url: '/apps/{app_id|}/{namespace|/}{name}/:count',
          },
        },
      }),
    };

    return (
      <Collection
        {...this.props}
        detail={false}
        nested={false}
        title="Models"
        model=":models"
        uri="/settings/models"
        tabs={tabs}
        filters={filters}
        fields={fields}
        headerActions={headerActions}
        query={updatedQuery}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Models);
