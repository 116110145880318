import get from 'lodash/get';

export const STANDARD_QUERY = {
  // Ignore unused, reserved and not-yet-documented models
  name: {
    $nin: ['events', 'notifications', 'settings', 'contacts', 'credits'],
  },
  app_id: null,
  $or: [
    { client_id: null },
    { client_id: { $ne: null }, extends: { $ne: 'com.base' } },
  ],
};

export const APPS_QUERY = {
  client_id: { $ne: null },
  app_id: { $ne: null },
};

export const CUSTOM_QUERY = {
  client_id: { $ne: null },
  app_id: null,
  $or: [
    { extends: null },
    { extends: 'com.base' },
    { extends: { $regex: '^(?!com\\.)' } },
  ],
};

export const COLLECTION_QUERY = {
  abstract: { $ne: true },
  deprecated: { $ne: true },
  $or: [STANDARD_QUERY, APPS_QUERY, CUSTOM_QUERY],
};

// Populated by content actions
export const COLLECTION_MODELS = {};
export const CONTENT_MODELS = {};
export const CONTENT_MODEL_VIEWS = {};

export const VIEW_COLLECTIONS = {
  orders: { url: '/orders/{id}' },
  carts: {
    url: (cart) => (cart.draft ? '/orders/drafts/{id}' : '/carts/{id}'),
  },
  shipments: { url: '/orders/{order_id}', name: 'order' },
  returns: { url: '/orders/{order_id}', name: 'order' },
  payments: { url: '/orders/{order_id}', name: 'order' },
  accounts: { url: '/customers/{id}' },
  products: { url: '/products/{id}' },
  'products:variants': { url: '/products/{parent_id}' },
  attributes: { url: '/attributes/{id}' },
  categories: { url: '/categories/{id}' },
  giftcards: { url: '/giftcards/{id}' },
  subscriptions: { url: '/subscriptions/{id}' },
  invoices: { url: '/invoices/{id}' },
  coupons: { url: '/coupons/{id}' },
  promotions: { url: '/promotions/{id}' },
  settings: { url: '/settings/{id}' },
  ':content': {
    url: (model) =>
      model.collection &&
      `/settings/models/com.${model.collection.replace('/', '_')}`,
    name: 'content model',
  },
  ':models': { url: '/settings/models/{id}', name: 'model' },
  // TODO: support links for dynamic collections
  // events: { url: '/{model}/{data.id}', name: 'record' },
  // ':notes': { url: '/{model}/{record_id}', name: 'record' },
};

export const SOURCE_TYPES = {
  theme: { label: 'Storefront' },
  custom: { label: 'Custom' },
};

export const KIND_TYPES = {
  custom_collection: {
    label: 'Custom collection',
    help: 'Collection was created as a new model',
  },
  custom_fields: {
    label: 'Custom fields',
    help: 'Fields were added to an existing model',
  },
};

export const FIELD_TYPES = {
  short_text: {
    icon: 'short-text',
    label: 'Short text',
    desc: 'Field for a single line of text',
    dataType: 'string',
    ui: {
      default: {
        label: 'Plain',
        placeholder: 'Example text',
      },
      slug: {
        label: 'Slug',
        placeholder: 'example-slug',
      },
      email: {
        label: 'Email',
        placeholder: 'email@example.com',
      },
      phone: {
        label: 'Phone',
        placeholder: '(555) 555-5555',
      },
      url: {
        label: 'URL',
        placeholder: 'www.example.com',
      },
    },
  },
  long_text: {
    icon: 'long-text',
    label: 'Long text',
    desc: 'Field for multiple lines of text',
    dataType: 'string',
    ui: {
      default: {
        label: 'Plain',
        placeholder:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur libero mauris, vestibulum ac dolor vel, interdum elementum turpis. Morbi risus lorem, volutpat sed scelerisque non, viverra a tortor.',
      },
      basic_html: {
        label: 'Basic HTML',
      },
      rich_html: {
        label: 'Rich HTML',
      },
      // markdown
      // blocks
    },
  },
  number: {
    faIcon: 'hashtag',
    faType: 'regular',
    label: 'Number',
    desc: 'Field for numerical input',
    dataType: ({ digits }) => (!digits ? 'int' : 'float'),
    ui: {
      default: {
        label: 'Integer',
        placeholder: '0',
      },
      float: {
        label: 'Float',
        digits: 2,
      },
      currency: {
        label: 'Currency',
      },
      slider: {
        label: 'Slider',
        min: 0,
        max: 100,
      },
    },
  },
  select: {
    icon: 'select',
    label: 'Select',
    desc: 'Choose from a list of options',
    // default, radio, checkboxes
    dataType: ({ multi, default: defaultVal, options }) => {
      if (multi) {
        return 'array';
      }
      const firstValue = get(options, '0.value', get(options, '0', defaultVal));
      if (!isNaN(Number(firstValue))) {
        return ~~firstValue === +firstValue ? 'int' : 'float';
      }
      return 'string';
    },
    ui: {
      default: {
        label: 'Dropdown',
        placeholder: 'Choose one',
        options: ['Option 1', 'Option 2', 'Option 3'],
      },
      radio: {
        label: 'Radio buttons',
        options: ['Option 1', 'Option 2', 'Option 3'],
      },
      checkboxes: {
        label: 'Checkboxes',
        options: ['Option 1', 'Option 2', 'Option 3'],
        multi: true,
      },
    },
  },
  //
  boolean: {
    icon: 'toggle',
    label: 'Boolean',
    desc: 'Toggle a value on or off',
    // default, checkbox
    dataType: 'bool',
    ui: {
      default: {
        label: 'Checkbox',
      },
      toggle: {
        label: 'Toggle',
      },
    },
  },
  date: {
    faIcon: 'calendar',
    label: 'Date',
    desc: 'Field for date and time input',
    // default, time, datetime
    dataType: 'date',
    ui: {
      default: {
        label: 'Date',
      },
      time: {
        label: 'Time',
      },
      datetime: {
        label: 'Date/time',
      },
    },
  },
  asset: {
    faIcon: 'images',
    label: 'Media',
    desc: 'Choose assets from a media library',
    // default, image, video, document
    dataType: 'file',
    ui: {
      default: {
        label: 'Flexible',
      },
      image: {
        label: 'Image',
      },
      video: {
        label: 'Video',
      },
      document: {
        label: 'Document',
      },
    },
  },
  tags: {
    faIcon: 'tag',
    label: 'Tags',
    desc: 'Field to input a set of tags',
    dataType: 'array',
    ui: {
      default: {
        placeholder: 'Separate values with a comma',
      },
    },
  },
  //
  color: {
    faIcon: 'paint-brush',
    label: 'Color',
    desc: 'Field to pick a color value',
    dataType: 'string',
    ui: {
      default: {
        placeholder: '#ffffff',
      },
    },
  },
  icon: {
    faIcon: 'check-circle',
    label: 'Icon',
    desc: 'Choose from a list of icons',
    dataType: 'string',
    ui: {
      default: {
        placeholder: 'Select icon',
      },
    },
  },
  lookup: {
    faIcon: 'search',
    label: 'Lookup',
    desc: 'Choose an existing record',
    dataType: 'link',
    ui: {
      default: {
        collection: 'products',
        placeholder: 'Find something by name',
      },
    },
  },
  collection: {
    faIcon: 'th-list',
    label: 'Collection',
    desc: 'A collection of multiple fields',
    dataType: 'array',
  },
  // Wrappers
  field_group: {
    wrapper: true,
    label: 'Field group',
    desc: 'A group of fields',
    dataType: 'array',
  },
  field_row: {
    wrapper: true,
    inputLabel: false,
    label: 'Field row',
    desc: 'A row of inline fields',
    dataType: 'array',
  },
};

export const FIELD_ALIASES = {
  text: {
    type: 'short_text',
  },
  textarea: {
    type: 'long_text',
  },
  phone: {
    type: 'short_text',
    ui: 'phone',
  },
  email: {
    type: 'short_text',
    ui: 'email',
  },
  url: {
    type: 'short_text',
    ui: 'url',
  },
  slug: {
    type: 'short_text',
    ui: 'slug',
  },
  basic_html: {
    type: 'long_text',
    ui: 'basic_html',
  },
  rich_html: {
    type: 'long_text',
    ui: 'rich_html',
  },
  markdown: {
    type: 'long_text',
    ui: 'markdown',
  },
  blocks: {
    type: 'long_text',
    ui: 'blocks',
  },
  checkbox: {
    type: 'boolean',
    ui: 'checkbox',
  },
  toggle: {
    type: 'boolean',
    ui: 'toggle',
  },
  image: {
    type: 'asset',
    asset_types: ['image'],
  },
  document: {
    type: 'asset',
    asset_types: ['document'],
  },
  video: {
    type: 'asset',
    asset_types: ['video'],
  },
  radio: {
    type: 'select',
    ui: 'radio',
  },
  dropdown: {
    type: 'select',
    ui: 'dropdown',
  },
  checkboxes: {
    type: 'select',
    ui: 'checkboxes',
    multi: true,
  },
  integer: {
    type: 'number',
    digits: 0,
  },
  float: {
    type: 'number',
    digits: 2,
  },
  currency: {
    type: 'number',
    ui: 'currency',
  },
  percent: {
    type: 'number',
    unit: '%',
  },
  slider: {
    type: 'number',
    ui: 'slider',
    increment: 1,
  },
  time: {
    type: 'date',
    ui: 'time',
  },
  datetime: {
    type: 'date',
    ui: 'datetime',
  },
  child_collection: {
    type: 'collection',
    child: true,
  },
  product_lookup: {
    type: 'lookup',
    collection: 'products',
  },
  variant_lookup: {
    type: 'lookup',
    collection: 'products:variants',
  },
  category_lookup: {
    type: 'lookup',
    collection: 'categories',
  },
  customer_lookup: {
    type: 'lookup',
    collection: 'accounts',
  },
};

export const FIELD_PROPS = [
  'id',
  'type',
  'value_type',
  'label',
  'ui',
  'root',
  'fields',
  'item_types',
  'description',
  'conditions',
  'options',
  'required',
  'unique',
  'public',
  'default',
  '$locale',
  '$currency',
  'fallback',
  'placeholder',
  'min',
  'max',
  'digits',
  'enum',
  'multi',
  'model',
  'collection',
  'collection_parent_id',
  'collection_parent_field',
  'query',
  'key',
  'key_field',
  'name_pattern',
  'localized',
  'formula',
  'admin_span',
  'admin_zone',
  'admin_enabled',
  'root',
  'public',
];

export const VIEW_PROPS = [
  'id',
  'type',
  'fields',
  'filters',
  'tabs',
  'query',
  'actions',
  'record_actions',
  'description',
  'label',
  'sort',
];

export const ADMIN_ZONES_DEFAULT = [
  { value: '', label: 'Hidden' },
  { value: 'details', label: 'Details' },
  { value: 'content', label: 'Content' },
];

export const ADMIN_ZONES_CONTENT_ONLY = [
  { value: '', label: 'Hidden' },
  { value: 'content', label: 'Content' },
];

export const ADMIN_ZONES = {
  invoices: ADMIN_ZONES_DEFAULT,
  orders: ADMIN_ZONES_DEFAULT,
  carts: ADMIN_ZONES_DEFAULT,
  subscriptions: ADMIN_ZONES_DEFAULT,
  categories: ADMIN_ZONES_DEFAULT,

  attributes: ADMIN_ZONES_CONTENT_ONLY,
  giftcards: ADMIN_ZONES_CONTENT_ONLY,
  coupons: ADMIN_ZONES_CONTENT_ONLY,
  promotions: ADMIN_ZONES_CONTENT_ONLY,
  purchaselinks: ADMIN_ZONES_CONTENT_ONLY,

  accounts: [
    { value: '', label: 'Hidden' },
    { value: 'details', label: 'Details' },
    { value: 'contact', label: 'Contact' },
    { value: 'billing', label: 'Billing', root: 'billing' },
    { value: 'shipping', label: 'Shipping', root: 'shipping' },
  ],

  payments: [
    { value: '', label: 'Hidden' },
    { value: 'charge', label: 'Charge' },
    { value: 'refund', label: 'Refund', root: 'refunds' },
  ],

  shipments: [
    { value: '', label: 'Hidden' },
    { value: 'order', label: 'Order fulfillment' },
  ],
  returns: [
    { value: '', label: 'Hidden' },
    { value: 'order', label: 'Order return' },
  ],

  products: [
    { value: '', label: 'Hidden' },
    { value: 'details', label: 'Details' },
    { value: 'pricing', label: 'Pricing' },
    { value: 'options', label: 'Options' },
    { value: 'option-edit', label: 'Option edit', root: 'options' },
    {
      value: 'option-value-edit',
      label: 'Option value edit',
      root: 'options.values',
    },
    { value: 'variant-edit', label: 'Variant edit', root: 'variants' },
    { value: 'related', label: 'Related products' },
    { value: 'attributes', label: 'Attributes' },
    { value: 'inventory', label: 'Inventory' },
    { value: 'shipping', label: 'Shipping' },
    { value: 'content', label: 'Content' },
  ],
};

export const CONTENT_TYPES = {
  pages: {
    id: 'pages',
    label: 'Pages',
    model: 'content/pages',
    fields: [
      {
        id: 'name',
        label: 'Title',
        type: 'text',
      },
      {
        id: 'slug',
        label: 'Slug',
        type: 'slug',
        description:
          'The page will be located at a public URL using this value (e.g. /slug)',
        conditions: {
          slug: { $ne: 'home' },
        },
      },
      {
        id: 'published',
        label: 'Published',
        type: 'toggle',
        conditions: {
          slug: { $ne: 'home' },
        },
      },
      {
        id: 'content',
        label: 'Content',
        type: 'rich_html',
        conditions: {
          slug: { $ne: 'home' },
        },
      },
      {
        id: 'meta_title',
        label: 'Meta title',
        type: 'text',
        placeholder: 'Optional',
        description: 'Defines a meta title for search engines',
      },
      {
        id: 'meta_description',
        label: 'Meta description',
        type: 'long_text',
        placeholder: 'Optional',
        description:
          'Search engines typically display the first 140-170 characters of your page description',
      },
      {
        id: 'meta_keywords',
        label: 'Meta keywords',
        type: 'tags',
        placeholder: 'Optional',
        description:
          'Search engines typically display the first 140-170 characters of your page description',
      },
      {
        id: 'redirect',
        label: 'Redirect URL',
        type: 'url',
        description:
          'When entered, forces the page to redirect to a different location',
        conditions: {
          slug: { $ne: 'home' },
        },
      },
    ],
  },
};

export const STANDARD_CONTENT_VIEWS = {
  // These models have hard-coded views
  orders: true,
  carts: true,
  shipments: true,
  returns: true,
  invoices: true,
  payments: true,
  accounts: true,
  subscriptions: true,
  products: true,
  categories: true,
  attributes: true,
  giftcards: true,
  purchaselinks: true,
  coupons: true,
  promotions: true,

  // These models have configured views
  'content/pages': [
    {
      id: 'list',
      type: 'list',
      fields: [
        {
          id: 'name',
          type: 'short_text',
          default: true,
        },
        {
          id: 'slug',
          type: 'slug',
        },
        {
          id: 'published',
          type: 'toggle',
          label: 'Status',
          func: ({ published }) => (published ? 'Published' : 'Unpublished'),
        },
        {
          id: 'date_updated',
          type: 'date',
          label: 'Updated',
        },
      ],
      actions: [
        {
          id: 'new',
          link: '/collections/content_pages/new',
        },
      ],
      filters: [
        {
          id: 'published',
          query: { published: true },
          type: 'toggle',
          label: 'Published',
        },
      ],
      tabs: [
        {
          id: 'published',
          query: { published: true },
        },
      ],
    },
    {
      id: 'record',
      type: 'record',
      fields: [
        {
          id: 'name',
          type: 'short_text',
          required: true,
          localized: true,
        },
        {
          id: 'slug',
          type: 'slug',
          hint: 'Used to uniquely identify the product in a URL',
          placeholder: '{name}',
        },
        {
          id: 'published',
          type: 'toggle',
        },
        {
          id: 'content',
          type: 'rich_html',
          localized: true,
        },
        {
          id: 'date_published',
          type: 'date',
          hint: 'Optionally used to indicate when the page was published',
        },
        {
          id: 'meta_title',
          type: 'short_text',
          placeholder: '{name}',
          hint: 'If different than page name',
          localized: true,
        },
        {
          id: 'meta_description',
          type: 'long_text',
          localized: true,
        },
        {
          id: 'redirect',
          type: 'url',
          hint: 'Automatically redirect to a different URL when this page is accessed',
        },
      ],
    },
  ],
};
