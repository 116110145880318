import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { debounce, find, get, set, cloneDeep, filter, isEmpty } from 'lodash';
import {
  LOAD_SETTINGS_SUCCESS,
  CHANGE_SETTING_LOCAL,
  CHANGE_CHECKOUT_SETTING_LOCAL,
} from './types';
import { isValueEqual } from 'utils';
import { updatePublishStatus, updateModified } from './git';
import { updateEditorLoading } from './editor';
import settingActions from 'actions/settings';
import api from 'services/api';
import { populateLookupValues, depopulateLookupValues } from 'utils/content';

let LOADED_SETTINGS = {};

export const loadSettingsSuccess = (values) => ({
  type: LOAD_SETTINGS_SUCCESS,
  payload: values,
});

export const loadSettings = () => async (dispatch, getState) => {
  const {
    storefronts: { storefront },
    themeConfig,
  } = getState();

  dispatch(showLoading());

  const isTheme = Boolean(storefront.source_model);

  const settings = isTheme
    ? await getConfigFromSettings(storefront, dispatch, getState)
    : {};

  dispatch(hideLoading());

  if (isTheme) {
    if (populateDefaults(settings, themeConfig)) {
      changeSettingsRemoteDebounced(settings, dispatch, getState);
    }

    await populateSettingLookupValues(settings, themeConfig, storefront);
  }

  settings.checkout = await dispatch(loadCheckoutSettings());

  dispatch(loadSettingsSuccess(settings));

  LOADED_SETTINGS = cloneDeep(settings);
};

async function getConfigFromMenus(storefront) {
  try {
    const menus = await api.getLocalized(
      '/data/:storefronts/{id}/configs/menus',
      {
        id: storefront.id,
      },
    );
    return menus?.values?.menus || [];
  } catch (err) {
    console.error(
      `Unable to retrieve storefront configs/menus: ${err.message}`,
    );
    return [];
  }
}

async function getConfigFromSettings(storefront) {
  try {
    const settings = await api.getLocalized(
      '/data/:storefronts/{id}/configs/settings',
      {
        id: storefront.id,
      },
    );
    return settings?.values || {};
  } catch (err) {
    console.error(
      `Unable to retrieve storefront configs/settings: ${err.message}`,
    );
    return {};
  }
}

async function updateConfigInSettings(storefront, config) {
  await api.put('/data/:storefronts/{id}/configs/settings', {
    id: storefront.id,
    $set: {
      values: {
        ...config,
        date_updated: Date.now(),
      },
    },
  });
}

export const loadCheckoutSettings = () => async (dispatch, getState) => {
  const { themeConfig } = getState();
  const checkout = await dispatch(settingActions.load('checkout'));

  // No settings for custom checkout
  if (checkout?.custom_checkout) {
    return {};
  }

  const checkoutSettings = checkout?.theme_dev || {};
  populateDefaults(
    { checkout: checkoutSettings },
    {
      settings: [find(themeConfig.settings, { id: 'checkout' })],
    },
  );
  return cloneDeep(checkoutSettings);
};

export const changeSettingLocal = (id, value) => ({
  type: CHANGE_SETTING_LOCAL,
  payload: { id, value },
});

export const changeCheckoutSettingLocal = (id, value) => ({
  type: CHANGE_CHECKOUT_SETTING_LOCAL,
  payload: { id, value },
});

let delayedSettings = null;
let delayedCheckoutSettings = null;

export const changeSettingsRemote = (
  settings,
  dispatch,
  getState,
  options = {},
) => {
  const {
    storefronts: { storefront },
    themeConfig,
  } = getState();

  const { publishing = false, refresh = false } = options;

  if (delayedSettings !== null) {
    setTimeout(
      () => changeSettingsRemote(settings, dispatch, getState, options),
      250,
    );
    return;
  }

  dispatch(updateEditorLoading(true));

  const values = (delayedSettings = depopulateSettingLookupValues(
    settings,
    themeConfig,
  ));

  if (!publishing) {
    return updateConfigInSettings(storefront, values).then(async () => {
      delayedSettings = null;
      await new Promise((resolve) => {
        if (refresh) {
          refreshEditor(resolve);
        } else {
          resolve();
        }
      });
      dispatch(updateEditorLoading(false));
      if (!isValueEqual(LOADED_SETTINGS, values)) {
        dispatch(updateModified());
      }
    });
  }
};

const changeCheckoutSettings = async (
  settings,
  dispatch,
  getState,
  options = {},
) => {
  dispatch(updateEditorLoading(true));

  const { refresh = false } = options;

  if (delayedCheckoutSettings !== null) {
    setTimeout(
      () => changeCheckoutSettings(settings, dispatch, getState, options),
      250,
    );
    return;
  }

  delayedCheckoutSettings = settings;

  const checkoutSettings = await dispatch(settingActions.load('checkout'));
  if (isValueEqual(checkoutSettings.theme_dev, settings)) {
    return;
  }

  try {
    await dispatch(
      settingActions.update('checkout', { $set: { theme_dev: settings } }),
    );
    await new Promise((resolve) => {
      if (refresh) {
        refreshEditor(resolve);
      } else {
        resolve();
      }
    });
    dispatch(updateEditorLoading(false));
    const checkoutCurrent = isValueEqual(checkoutSettings.theme, settings);
    dispatch(
      updatePublishStatus({
        checkoutCurrent,
      }),
    );
  } catch (err) {
    console.error(err);
  }

  delayedCheckoutSettings = null;
};

const changeSettingsRemoteDebounced = debounce(changeSettingsRemote, 500);

const changeCheckoutSettingsDebounced = debounce(changeCheckoutSettings, 250);

export const changeSetting =
  (id, value, setting, options = {}) =>
  (dispatch, getState) => {
    const { themeSettings, themeConfig, settings: allSettings, storefronts: { storefront } } = getState();

    // Hosted checkout setting changes route differently
    if (setting.id === 'checkout' && !allSettings?.checkout?.custom_checkout) {
      dispatch(changeCheckoutSettingLocal(id, value));
      const nextSettings = {
        checkout: cloneDeep(themeSettings.present.checkout),
      };
      delete nextSettings.checkout[id];
      delete nextSettings.checkout.checkout;
      set(nextSettings, id, value);
      changeCheckoutSettingsDebounced(
        nextSettings.checkout,
        dispatch,
        getState,
        options,
      );
    } else {
      const nextSettings = cloneDeep(themeSettings.present);
      delete nextSettings[id];
      set(nextSettings, id, value);
      populateSettingLookupValues(nextSettings, themeConfig, storefront).then(() => {
        const localValue = get(nextSettings, id);
        dispatch(changeSettingLocal(id, localValue));
      });
      changeSettingsRemoteDebounced(nextSettings, dispatch, getState, options);
    }
  };

function populateDefaults(values, config) {
  const { settings = [] } = config;
  let isChanged = false;
  for (let setting of settings) {
    if (!setting) {
      continue;
    }
    for (let field of setting.fields) {
      if (
        (field.type === 'field_group' || field.type === 'field_row') &&
        field.fields
      ) {
        for (let groupField of field.fields) {
          const value = get(values, groupField.id);
          const isValueEmpty = value === undefined;
          if (isValueEmpty && groupField.default) {
            set(values, groupField.id, groupField.default);
            isChanged = true;
          }
        }
      } else {
        const value = get(values, field.id);
        const isValueEmpty = value === undefined;
        if (isValueEmpty && field.default) {
          set(values, field.id, field.default);
          isChanged = true;
        }
      }
    }
  }
  return isChanged;
}

export async function populateMenuValues(values, fields, storefront) {
  const menuFields = filter(fields, { type: 'menu' });
  if (isEmpty(menuFields)) return;

  const menus = await getConfigFromMenus(storefront);
  for (let menuField of menuFields) {
    const menu = get(values, menuField.id);
    if (typeof menu === 'string') {
      set(values, menuField.id, find(menus, { id: menu }));
    }
  }
}

export async function populateSettingLookupValues(values, config, storefront) {
  const { settings = [] } = config;
  const allFields = [];
  for (let setting of settings) {
    for (let field of setting.fields) {
      allFields.push(field);
    }
  }
  await populateMenuValues(values, allFields, storefront);
  return populateLookupValues(values, allFields);
}

export function depopulateSettingLookupValues(values, config) {
  const { settings = [] } = config;
  const allFields = [];
  for (let setting of settings) {
    for (let field of setting.fields) {
      allFields.push(field);
    }
  }
  return depopulateLookupValues(values, allFields, 'slug');
}

function refreshEditor(callback) {
  if (window.editorRefreshPage) {
    window.editorRefreshPage(callback);
  } else {
    callback();
  }
}
