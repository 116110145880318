import React from 'react';
import './error.scss';
import LogoTopLeft from 'components/logo';

export default class Error404 extends React.Component {
  render() {
    return (
      <div className="error-container">
        {this.props.router && <LogoTopLeft />}
        <div className="error-wrapper">
          <div className="error-404">
            <h1>404 page not found 🤔</h1>
          </div>
        </div>
      </div>
    );
  }
}
