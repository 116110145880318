export const DIRECTION = {
  UP: 'up',
  DOWN: 'down',
};

/**
 * helps identifying what to display as title when showing a section
 *
 * for content/pages: label or name
 * for content/quizzes: title
 */
export function sectionLabel(section) {
  return (
    (section && (section.label || section.name || section.title)) || 'Untitled'
  );
}

export function iconNameForSection(section) {
  let icon = 'sliders-h';

  if (section.icon) {
    return section.icon;
  }

  const name = sectionLabel(section);

  if (name.match(/style|color|design/i)) {
    icon = 'palette';
  } else if (name.match(/head/i)) {
    icon = 'arrow-to-top';
  } else if (name.match(/foot/i)) {
    icon = 'arrow-to-bottom';
  } else if (name.match(/home/i)) {
    icon = 'home';
  } else if (name.match(/product/i)) {
    icon = 'cube';
  } else if (name.match(/categor/i)) {
    icon = 'folder';
  } else if (name.match(/social/i)) {
    icon = 'thumbs-up';
  } else if (name.match(/cart|shopping/i)) {
    icon = 'shopping-cart';
  } else if (name.match(/checkout|order/i)) {
    icon = 'check-circle';
  } else if (name.match(/customer|account/i)) {
    icon = 'users';
  } else if (name.match(/page/i)) {
    icon = 'file';
  }

  return icon;
}

/**
 * Gets the page pathname for a newly created page based on editor config.
 *
 * It uses the `route_preview` (or `page_route_preview`) prop to return the preview path for
 * a page.
 * If the model page configuration and the editor configuration do not have
 * set `route_preview`, respectively `page_route_preview`, it will default to replacing
 * the `:slug` keyword in `page_route`.
 *
 * *`route_preview` and `page_route_preview` should point to pages within the theme (Horizon, Origin etc.) that serve as
 * a preview UI before the page is initially saved.*
 *
 * @param      {object} config  The editor config
 * @param      {object} pageContent         The page content
 *
 * @return     {string} the computed page pathname.
 */
export function newPagePathname(config, pageContent) {
  const modelPage = config.pages.find(
    (item) => item.model === pageContent.model,
  );

  const previewPageRoute =
    modelPage?.route_preview || config?.page_route_preview;

  return (
    previewPageRoute ||
    config.page_route?.replace(':slug', pageContent.new_record_id)
  );
}

/**
 * Moves an item at specific index within an array one positon up or down
 * and returns the new array.
 * The first element represents the top of the list, while the last represents the bottom.
 *
 * @example
 * // returns ['a', 'b', 'd', 'c']
 * moveListItem(['a', 'b', 'c', 'd'], 2, DIRECTION.DOWN)
 * @example
 * // returns ['a', 'c', 'b', 'd']
 * moveListItem(['a', 'b', 'c', 'd'], 2, DIRECTION.UP)
 *
 * @param      {array} list  The array
 * @param      {number | string} index  The index of the item to be moved
 * @param      {DIRECTION.UP | DIRECTION.DOWN} direction  The direction to be moved towards
 *
 * @return     {array} The computed array after the item was moved
 */

export function moveListItem(list, index, direction = DIRECTION.DOWN) {
  if (!Array.isArray(list)) {
    return list;
  }

  const directionIdxValue = directionIdx(direction);
  const itemIndex = Number(index);

  // We should not move up if it's the first item in the array
  // or move down if it's the last item in the array
  if (
    (itemIndex === 0 && directionIdxValue === -1) ||
    (itemIndex === list.length - 1 && directionIdxValue === 1)
  ) {
    return list;
  }

  let newList = [...list];

  const item = newList[itemIndex];

  newList.splice(index, 1);
  newList.splice(itemIndex + directionIdxValue, 0, item);

  return newList;
}

/**
 * Returns direction index value based on DIRECTION string value
 *
 * @param      {DIRECTION.UP | DIRECTION.DOWN} direction  The direction to be moved towards
 *
 * @return     {-1 | 1} The index value
 */
export function directionIdx(direction) {
  return direction === DIRECTION.DOWN ? 1 : -1;
}

export function getUrlFirstSegment(url) {
  const firstSegmentRegex = new RegExp(`^(https?://[^/]*)?(/[^/]+)`);
  const match = url.match(firstSegmentRegex);

  return match && match.pop();
}

export function localeNormalizedUrl(url, { locales, defaultLocale }) {
  const firstSegment = getUrlFirstSegment(url);

  if (
    !url ||
    !defaultLocale ||
    !locales ||
    !locales.find(({ code }) => firstSegment === `/${code}`)
  )
    return url;

  return url.replace(firstSegment, '') || '/';
}

export function localizedUrl(url, { editorLocale, defaultLocale }) {
  if (!url || !defaultLocale || !editorLocale || editorLocale === defaultLocale)
    return url;

  try {
    const normalizedUrl = new URL(
      localeNormalizedUrl(url, { locales: [editorLocale], defaultLocale }),
    );
    normalizedUrl.pathname = `/${editorLocale}` + normalizedUrl.pathname;

    return normalizedUrl.toString();
  } catch (error) {
    return `/${editorLocale}` + url;
  }
}
