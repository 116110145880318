import React from 'react';
import PropTypes from 'prop-types';
import { FadeInUp } from 'components/transitions';
import LogoTopLeft from 'components/logo/logo';
import StoreIcon from 'components/store/icon';
import UserPhoto from 'components/user/photo';
import Button from 'components/button';
import Icon from 'components/icon';

import './cli-switch-store.scss';

export default class CliSwitchStorePage extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  static propTypes = {
    onClickSwitchAccount: PropTypes.func.isRequired,
    onClickSelectStore: PropTypes.func.isRequired,
    docs: PropTypes.bool,
  };

  render() {
    const { user } = this.context;

    const { onClickSwitchAccount, onClickSelectStore, docs } = this.props;

    return (
      <div className="cli-switch-store-root">
        <LogoTopLeft />
        <FadeInUp>
          <div>
            <h2 className="cli-switch-store-heading">Choose a store</h2>
          </div>
          {user.authorizations && user.authorizations.length > 0 && (
            <div className="cli-switch-store-list">
              {user.authorizations.map((auth) => (
                <div
                  key={auth.client_id}
                  data-id={auth.client_id}
                  className="cli-switch-store-list-item"
                  onClick={() => {
                    onClickSelectStore && onClickSelectStore(auth.client_id);
                  }}
                >
                  <StoreIcon auth={auth} size={38} />
                  <div className="cli-switch-store-list-item-text">
                    <div>{auth.client_name}</div>
                    <div>{auth.client_id}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="cli-switch-store-footer">
            <div>
              <UserPhoto user={user} width={40} height={40} />
              <div className="cli-switch-store-footer-user">
                <div className="cli-switch-store-footer-user-name">
                  {user.name}
                </div>
                <div className="cli-switch-store-footer-user-email">
                  {user.email}
                </div>
              </div>
            </div>
            <Button
              size="sm"
              type="secondary"
              onClick={() => {
                window.location.pathname = '/admin/login';
              }}
            >
              Switch account
            </Button>
          </div>
        </FadeInUp>
        <div className="cli">
          <Icon
            svgType="terminal-square"
            className="cli-icon"
            width={24}
            height={24}
          />
          <div>{docs ? 'Developer docs' : 'Command-line interface'}</div>
        </div>
      </div>
    );
  }
}
