import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import {
  Console,
  mapStateToProps,
  mapDispatchToProps,
} from 'containers/Console';

import ConsoleAPIPage from 'components/console/api';

export class ConsoleModal extends Console {
  static contextTypes = {
    openModal: pt.func.isRequired,
    closeModal: pt.func.isRequired,
  };

  render() {
    return <ConsoleAPIPage {...this.props} {...this.state} {...this.methods} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsoleModal);
