import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import actions from 'actions';

export const ModalsContext = React.createContext({});

class ModalsProvider extends React.Component {
  static propTypes = {
    children: pt.node.isRequired,

    openModal: pt.func.isRequired,
    closeModal: pt.func.isRequired,
    refreshModal: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      openModal: (id, props) => {
        const { openModal } = this.props;
        // Prevent updating during a state transition
        setTimeout(() => {
          openModal(id, props);
        }, 1);
      },
      refreshModal: () => {
        const { refreshModal } = this.props;
        // Prevent updating during a state transition
        setTimeout(() => {
          refreshModal();
        }, 1);
      },
      closeModal: this.props.closeModal,
    };
  }

  render() {
    return (
      <ModalsContext.Provider value={this.state}>
        {this.props.children}
      </ModalsContext.Provider>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal(id, props) {
    dispatch(actions.modal.open(id, props));
  },

  refreshModal() {
    dispatch(actions.modal.refresh());
  },

  closeModal(id) {
    dispatch(actions.modal.close(id));
  },
});

export default connect(null, mapDispatchToProps)(ModalsProvider);
