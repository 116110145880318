import React from 'react';
import classNames from 'classnames';
import pt from 'prop-types';

import Icon from 'components/icon';

import Button from './button';
import LinkButton from './link';

import './button.scss';

export default class NextButton extends React.PureComponent {
  static propTypes = {
    disabled: pt.bool,
    className: pt.string,
    onClick: pt.func,
    to: pt.string,
  };

  onClick = (event) => {
    if (this.props.disabled) {
      event.preventDefault();
    } else if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render() {
    const { className, disabled, to, ...props } = this.props;

    let classNameExt = `button-next button-icon`;
    if (className) {
      classNameExt += ` ${className}`;
    }
    if (disabled) {
      classNameExt += ' disabled';
    }

    return to ? (
      <LinkButton
        to={to}
        {...props}
        onClick={this.onClick}
        className={classNameExt}
      >
        <Icon fa="chevron-right" faType="solid" />
      </LinkButton>
    ) : (
      <Button
        {...props}
        disabled={disabled}
        className={classNames('button-next button-icon', className, {
          disabled,
        })}
        styleType="secondary"
        onClick={this.onClick}
        type="button"
      >
        <Icon fa="chevron-right" faType="solid" />
      </Button>
    );
  }
}
