import React from 'react';
import { DayPickerRangeController } from 'react-dates';
import momentPropTypes from 'react-moment-proptypes';
import { head, pick, get } from 'lodash';
import moment from 'moment-timezone';
import pt from 'prop-types';

import { getDateRangeOptions } from 'constants/date-ranges';

import { isSameDay, isSameTime } from 'utils';

import { Form, Field } from 'components/form';
import Modal from 'components/modal';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react-dates-overrides.scss';
import './date-range-picker.scss';

const START_DATE = 'startDate';
const END_DATE = 'endDate';
const LABEL_FORMAT = 'MMM D, YYYY';

const COMPARE_OPTIONS = Object.freeze([
  {
    label: 'Previous period',
    value: 'previous period',
  },
  {
    label: 'Last year',
    value: 'last year',
  },
]);

export default class DateRangePicker extends React.PureComponent {
  static propTypes = {
    startDate: pt.oneOfType([momentPropTypes.momentObj, pt.string]),
    endDate: pt.oneOfType([momentPropTypes.momentObj, pt.string]),
    onChangeDates: pt.func.isRequired,
    defaultValue: pt.string,
    rangeType: pt.string,
    compareTo: pt.object,
  };

  static contextTypes = {
    client: pt.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    /** @type {Array<object>} */
    this.dateRangeOptions = getDateRangeOptions(
      context.client.timezone,
      props.rangeType,
    );

    const initialOption =
      props.defaultValue &&
      this.dateRangeOptions.find((item) => item.value === props.defaultValue);

    this.state = {
      opened: false,
      option: initialOption || null,
      isTime: props.rangeType === 'time',
      initialChanged: false,
      startDate: moment(
        props.startDate ||
          initialOption?.range.startDate ||
          this.dateRangeOptions[0].range.startDate,
      ),
      endDate: moment(
        props.endDate ||
          initialOption?.range.endDate ||
          this.dateRangeOptions[0].range.endDate,
      ),
      prevOption: null,
      customLabel: null,
      focusedInput: null,
    };

    if (!initialOption) {
      this.state.option = this.getOption(
        this.state.startDate,
        this.state.endDate,
      );
    }

    this.compareToChanged = false;
    this.timeInterval = 0;
  }

  componentDidMount() {
    if (this.state.isTime) {
      this.timeInterval = setInterval(() => {
        this.updateRangeTime();
      }, 60000);
    }
  }

  componentWillUnmount() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      if (this.props.startDate && this.props.endDate) {
        this.setState({
          option: this.getOption(
            moment(this.props.startDate),
            moment(this.props.endDate),
          ),
        });
      }
    }
  }

  updateRangeTime() {
    const { option } = this.state;
    if (this.state.option) {
      this.dateRangeOptions = getDateRangeOptions(
        this.context.client.timezone,
        'time',
      );

      const nextOption = this.dateRangeOptions.find(
        (item) => item.value === option.value,
      );

      if (nextOption?.range?.startDate !== option.range?.startDate) {
        this.setState(
          {
            option: nextOption,
            startDate: option.range.startDate,
            endDate: option.range.endDate,
          },
          () => {
            this.props.compareTo && this.setCompareTo();
            this.props.onChangeDates({
              option,
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              compareTo: this.compareTo,
            });
          },
        );
      }
    }
  }

  getCustomLabel(startDate, endDate) {
    const { client } = this.context;

    const startDateTZ = startDate && startDate.tz(client.timezone);
    const endDateTZ = endDate && endDate.tz(client.timezone);

    let customLabel;
    if (
      (startDateTZ && !endDateTZ) ||
      this.isSameDate(startDateTZ, endDateTZ)
    ) {
      customLabel = startDateTZ.format(LABEL_FORMAT);
    } else {
      customLabel = `${startDateTZ.format(LABEL_FORMAT)} — ${endDateTZ.format(
        LABEL_FORMAT,
      )}`;
    }

    return customLabel;
  }

  findOption(startDate, endDate) {
    return this.dateRangeOptions.find(
      ({ range }) =>
        range &&
        this.isSameDate(startDate, range.startDate) &&
        this.isSameDate(endDate || startDate, range.endDate),
    );
  }

  isSameDate(a, b) {
    return this.state.isTime ? isSameTime(a, b) : isSameDay(a, b);
  }

  getOption(startDate, endDate) {
    let option = this.findOption(startDate, endDate);

    if (!option) {
      const customLabel = this.getCustomLabel(startDate, endDate);

      this.dateRangeOptions = [
        {
          value: 'current',
          label: customLabel,
        },
        ...getDateRangeOptions(
          this.context.client.timezone,
          this.props.rangeType,
        ),
      ];

      option = this.dateRangeOptions[0];
    }

    return option;
  }

  setOption() {
    const { startDate, endDate } = this.state;

    this.setState({ option: this.getOption(startDate, endDate) }, () => {
      this.props.compareTo && this.setCompareTo();
    });
  }

  onChangeSelect = (_event, value) => {
    // Update range options before selecting
    this.dateRangeOptions = getDateRangeOptions(
      this.context.client.timezone,
      this.props.rangeType,
    );

    const { initialChanged } = this.state;
    const option = this.dateRangeOptions.find((item) => item.value === value);

    if (!option || value === 'current') {
      return;
    }

    if (value === 'custom') {
      this.setState((state) => {
        const newState = {
          opened: true,
          option,
          prevOption: state.option,
        };

        if (state.option?.value !== 'current') {
          Object.assign(newState, {
            focusedInput: 'endDate',
            startDate: moment().tz(this.context.client.timezone),
            endDate: null,
          });
        }

        return newState;
      });

      return false;
    }

    this.setState(
      {
        option,
        startDate: option.range.startDate,
        endDate: option.range.endDate,
        initialChanged: true,
      },
      () => {
        this.props.compareTo && this.setCompareTo();
        this.props.onChangeDates({
          option,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          compareTo: this.compareTo,
          selected: initialChanged,
        });
      },
    );
  };

  onChangeDates = ({ startDate, endDate }) => {
    if (!this.state.focusedInput) {
      endDate = null;
    }

    this.setState(
      {
        startDate,
        endDate,
      },
      () => this.setOption(),
    );
  };

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  getEndDate = ({ startDate, endDate } = this.state) => {
    return endDate || startDate;
  };

  onChangeStartDate = (event) => {
    const { timezone } = this.context.client;
    const { value } = event.target;
    const time = moment.tz(value, 'YYYY-MM-DD', timezone);

    if (value !== time.format('YYYY-MM-DD')) {
      return;
    }

    if (
      time.isAfter(this.state.endDate, 'day') ||
      this.isSameDate(time, this.state.endDate)
    ) {
      return this.setState(
        {
          startDate: time,
          endDate: time,
        },
        () => {
          this.setOption();
        },
      );
    }

    this.setState({ startDate: time }, () => {
      this.setOption();
    });
  };

  onChangeEndDate = (event) => {
    const { timezone } = this.context.client;
    const { value } = event.target;
    const time = moment.tz(value, 'YYYY-MM-DD', timezone);

    if (value !== time.format('YYYY-MM-DD')) {
      return;
    }

    if (
      this.state.startDate.isAfter(time, 'day') ||
      this.isSameDate(time, this.state.startDate)
    ) {
      return this.setState(
        {
          startDate: time,
          endDate: time,
        },
        () => {
          this.setOption();
        },
      );
    }

    this.setState({ endDate: time }, () => {
      this.setOption();
    });
  };

  onClickCancel = () => {
    this.compareToChanged = false;

    this.setState(
      (state) => ({
        opened: false,
        option: state.prevOption,
        prevOption: null,
      }),
      () => {
        if (this.state.option?.value === 'current') {
          this.setOption();
        }
      },
    );
  };

  onClickApply = () => {
    const { startDate } = this.state;
    const endDate = this.getEndDate();

    const customLabel = this.getCustomLabel(startDate, endDate);

    this.compareToChanged = false;
    this.dateRangeOptions = [
      {
        value: 'current',
        label: customLabel,
      },
      ...getDateRangeOptions(
        this.context.client.timezone,
        this.props.rangeType,
      ),
    ];

    const option = this.dateRangeOptions[0];
    this.setState({ opened: false, option });

    this.props.onChangeDates({
      option,
      startDate: this.state.startDate,
      endDate: endDate.clone().endOf('day'),
      compareTo: pick(this.compareTo, ['startDate', 'endDate']),
      selected: true,
    });
  };

  setCompareTo = (
    value = get(this, 'compareTo.value') || head(COMPARE_OPTIONS).value,
  ) => {
    const { startDate, option } = this.state;
    const { timezone } = this.context.client;
    const endDate = this.getEndDate();
    const length = endDate.diff(startDate, 'days') + 1;

    let newCompareTo;

    if (value === 'previous period') {
      if (option.compareTo) {
        newCompareTo = {
          ...option.compareTo,
          value,
        };
      } else {
        newCompareTo = {
          startDate: moment(startDate)
            .tz(timezone)
            .add(-length, 'days')
            .startOf('day'),
          endDate: moment(startDate).tz(timezone).add(-1, 'days').endOf('day'),
          value,
        };
      }
    } else if (value === 'last year') {
      newCompareTo = {
        startDate: moment(startDate)
          .tz(timezone)
          .add(-1, 'year')
          .startOf('day'),
        endDate: moment(endDate).tz(timezone).add(-1, 'year').endOf('day'),
        value,
      };
    }

    if (
      !this.compareTo ||
      (newCompareTo &&
        (!this.isSameDate(newCompareTo.startDate, this.compareTo.startDate) ||
          !this.isSameDate(newCompareTo.endDate, this.compareTo.endDate) ||
          newCompareTo.value !== this.compareTo.value))
    ) {
      this.compareTo = newCompareTo;

      this.forceUpdate();
    }
  };

  onChangeCompareTo = (event) => {
    const { value } = event.target;
    const changed = value !== this.compareTo.value;

    if (changed) {
      this.compareToChanged = true;
    }

    this.setCompareTo(value);
  };

  renderPanel() {
    const { focusedInput, startDate, endDate } = this.state;

    return (
      <Form onSubmit={this.onClickApply}>
        <Modal
          title="Date range"
          className="date-range-picker-modal"
          width={599}
          actions={[
            { label: 'Apply', type: 'default', onClick: this.onClickApply },
            { label: 'Cancel', type: 'cancel', onClick: this.onClickCancel },
          ]}
          onClose={this.onClickCancel}
          cancel={false}
        >
          <div className="date-range-picker-input-row">
            <Field
              type="text"
              label="Starting"
              className="date-range-picker-input"
              name={START_DATE}
              value={startDate.format('YYYY-MM-DD')}
              onChange={this.onChangeStartDate}
            />

            <Field
              className="date-range-picker-input"
              label="Ending"
              name={END_DATE}
              value={this.getEndDate().format('YYYY-MM-DD')}
              onChange={this.onChangeEndDate}
            />
          </div>

          <div className="date-range-picker-controller">
            <DayPickerRangeController
              enableOutsideDays
              startDate={startDate}
              endDate={endDate}
              numberOfMonths={2}
              daySize={34}
              focusedInput={focusedInput || START_DATE}
              onDatesChange={this.onChangeDates}
              onFocusChange={this.onFocusChange}
              hideKeyboardShortcutsPanel
            />
          </div>

          {this.compareTo && (
            <Field
              className="date-range-picker-select"
              type="select"
              name="compare-to"
              label="Compare to"
              defaultValue={this.compareTo.value}
              options={COMPARE_OPTIONS}
              onChange={this.onChangeCompareTo}
            />
          )}
        </Modal>
      </Form>
    );
  }

  render() {
    const {
      startDate,
      endDate,
      onChangeDates,
      defaultValue,
      rangeType,
      compareTo,
      ...props
    } = this.props;

    return (
      <div className="date-range-picker">
        <Field
          {...props}
          type="select"
          readonly={true}
          options={this.dateRangeOptions}
          value={this.state.option.value}
          onChange={this.onChangeSelect}
        />

        {this.state.opened && this.renderPanel()}
      </div>
    );
  }
}
