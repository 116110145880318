import React from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import pt from 'prop-types';

import Icon from 'components/icon';
import { Field } from 'components/form';
import DropdownButton from 'components/button/dropdown';
import DateRangePicker from 'components/date-range-picker';

export default class LogsFilters extends React.PureComponent {
  static propTypes = {
    filters: pt.object,
    allFilters: pt.object,
    location: pt.object,
    initialDateOption: pt.string,
    dateRangeRef: pt.object,
    searchRef: pt.object,

    onChangeDates: pt.func,
    onResetFilters: pt.func,
    onClickToggleFilter: pt.func,
    onKeyDownSearchEscape: pt.func,
  };

  renderFilters() {
    const { filters, allFilters, onClickToggleFilter } = this.props;

    return map(
      allFilters,
      (filter, id) =>
        filter.options?.length > 0 && (
          <DropdownButton
            key={id}
            anchor="left"
            arrow={true}
            keepOpen={true}
            items={filter.options.map((option, index) => {
              const selected = filters[id]?.includes(option.value);

              return (
                <button
                  key={index}
                  role="option"
                  data-id={id}
                  data-value={option.value}
                  aria-selected={selected}
                  className="console-logs-filter-option"
                  onClick={onClickToggleFilter}
                  type="button"
                >
                  {option.icon ? option.icon : null}
                  {option.label}
                  <span className={selected ? '' : 'invisible'}>
                    <Icon fa="check" />
                  </span>
                </button>
              );
            })}
          >
            <span className="console-logs-cols-button">
              {filter.label}
              {filters[id]?.length > 0 ? ': ' : ''}
              {filters[id]?.length > 0 &&
                filters[id].map((value, index) => {
                  const option = find(allFilters[id].options, {
                    value,
                  });

                  if (!option) {
                    return null;
                  }

                  return (
                    <span key={index} className="console-logs-cols-button-item">
                      {option.icon ? option.icon : null}
                      {option.label}
                      {index + 1 < filters[id].length ? ',' : ''}
                    </span>
                  );
                })}
            </span>
          </DropdownButton>
        ),
    );
  }

  render() {
    const {
      searchRef,
      dateRangeRef,
      location,
      initialDateOption,
      onChangeDates,
      onResetFilters,
      onKeyDownSearchEscape,
    } = this.props;

    return (
      <div className="console-logs-filters" id="console-logs-filters">
        <div className="console-logs-filter-search">
          <div className="row">
            <div className="span3">
              <Field
                name="search"
                placeholder="Search logs"
                defaultValue={location.query.search}
                value={undefined}
                onKeyDown={onKeyDownSearchEscape}
                autoComplete="off"
                ref={searchRef}
              />
            </div>

            <div className="span1">
              <DateRangePicker
                startDate={location.query.start}
                endDate={location.query.end}
                defaultValue={initialDateOption}
                onChangeDates={onChangeDates}
                rangeType="time"
                ref={dateRangeRef}
              />
            </div>
          </div>

          <div className="console-logs-filter-alt">
            {this.renderFilters()}

            {Object.keys(location.query).length > 0 && (
              <button
                className="button button-reset"
                onClick={onResetFilters}
                type="button"
              >
                Reset table
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
