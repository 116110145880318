import React, { Fragment } from 'react';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import Link from 'components/link';
import { Field, Tabs, TabView } from 'components/form';
import Loading from 'components/loading';
import PrevButton from 'components/button/prev';
import NextButton from 'components/button/next';
import ContentFields from 'components/content/fields';
import { FadeIn } from 'components/transitions';
import Tooltip from 'components/tooltip';
import SectionHeader from 'components/section-header';
import { formatDate, arrayToObject } from 'utils';

export default class ProductInventoryForm extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      variantIndex: props.record
        ? arrayToObject(props.record.variants.results)
        : {},
      stockHistory: [],
      historyTab: 'all',
      historyPage: 1,
      historyLoading: false,
      historyExists: false,
    };
  }

  componentWillMount() {
    this.loadHistory();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.record !== this.props.record) {
      this.setState({
        variantIndex: arrayToObject(nextProps.record.variants.results),
      });
    }
    if (nextProps.related.stock !== this.props.related.stock) {
      this.setState({ ...this.getStockHistory(nextProps) });
    }
  }

  async loadHistory(query = {}) {
    let filter = {};
    switch (this.state.historyTab) {
      case 'orders':
        filter = { order_id: { $ne: null } };
        break;
      case 'manual':
        filter = { order_id: null };
        break;
      case 'all':
      default:
        break;
    }
    this.setState({ historyLoading: true });
    await this.props.onLoadInventoryHistory({ ...filter, ...query });
    this.setState({ historyLoading: false });
  }

  getStockHistory(props) {
    const { record, related } = props;
    if (!record || !related.stock) {
      return [];
    }
    const hasVariants = record.variants.results.length > 0;
    const variantIndex = arrayToObject(record.variants.results);
    const stockHistory = related.stock.results.reduce((history, stock) => {
      if (hasVariants) {
        if (stock.variant_id) {
          stock.related = variantIndex[stock.variant_id] || record;
          history.push(stock);
        }
      } else {
        if (!stock.variant_id) {
          stock.related = record;
          history.push(stock);
        }
      }
      return history;
    }, []);
    return {
      stockHistory,
      historyExists: this.state.historyExists || stockHistory.length > 0,
    };
  }

  getStockHistoryDescription(stock) {
    const quantity = Math.abs(stock.quantity);
    const desc = quantity === 1 ? 'item' : 'items';
    switch (stock.reason) {
      case 'sold':
        return (
          <span>
            {quantity} {desc}&nbsp;
            {stock.order_id ? (
              <Link to={`/orders/${stock.order_id}`}>
                <b>sold</b>
              </Link>
            ) : (
              <b>sold</b>
            )}
          </span>
        );
      case 'canceled':
        return (
          <span>
            {quantity} {desc}&nbsp;<b>canceled</b>&nbsp;
            {stock.order_id && (
              <span>
                in <Link to={`/orders/${stock.order_id}`}>an order</Link>
              </span>
            )}
          </span>
        );
      case 'returned':
        return (
          <span>
            {quantity} {desc}&nbsp;<b>returned</b>&nbsp;
            {stock.order_id && (
              <span>
                from <Link to={`/orders/${stock.order_id}`}>an order</Link>
              </span>
            )}
          </span>
        );
      default:
        if (stock.quantity > 0) {
          return (
            <span>
              {quantity} {desc}&nbsp;<b>added</b>
            </span>
          );
        } else {
          return (
            <span>
              {quantity} {desc}&nbsp;<b>removed</b>
            </span>
          );
        }
    }
  }

  onClickAdjustInventory = (event) => {
    event.preventDefault();
    const variantId = event.currentTarget.dataset.variant;
    const variant = variantId && this.state.variantIndex[variantId];
    this.context.openModal('AdjustInventory', {
      params: {
        product: this.props.record,
        variant: variant || null,
        onAdjust: () => this.props.onAdjustInventory(),
      },
    });
  };

  onChangeHistoryTab = (value) => {
    this.setState({ historyTab: value, historyPage: 1 }, () =>
      this.loadHistory(),
    );
  };

  onClickHistoryPagePrev = (event) => {
    event.preventDefault();
    const historyPage = this.state.historyPage - 1;
    this.loadHistory({ page: historyPage });
    this.setState({ historyPage });
  };

  onClickHistoryPageNext = (event) => {
    event.preventDefault();
    const historyPage = this.state.historyPage + 1;
    this.loadHistory({ page: historyPage });
    this.setState({ historyPage });
  };

  getProductLabel(product) {
    if (product.sku) {
      return `${product.sku} (${product.name})`;
    }
    return product.name;
  }

  renderHistory(noneFound = null) {
    const {
      related: { stock: { pages } = {} },
    } = this.props;
    const { stockHistory, historyLoading, historyPage } = this.state;
    const { record } = this.props;
    const hasVariants = record.variants.results.length > 0;

    return (
      <FadeIn>
        {stockHistory.length > 0 ? (
          <div className="collection-table-container">
            <table
              className="collection-table"
              style={historyLoading ? { opacity: 0.5 } : undefined}
            >
              <thead>
                <tr>
                  <th>{hasVariants ? 'Variant' : 'Product'}</th>
                  <th>Adjustment</th>
                  <th>Date</th>
                  <th>Remaining</th>
                </tr>
              </thead>
              <tbody>
                {stockHistory.map((stock) => (
                  <tr key={stock.id}>
                    <td>{this.getProductLabel(stock.related)}</td>
                    <td>
                      {this.getStockHistoryDescription(stock)}{' '}
                      {stock.reason_message && (
                        <Tooltip
                          message={`${
                            stock.reason ? `${startCase(stock.reason)}. ` : ''
                          }${stock.reason_message}`}
                        >
                          <span className="help icon icon-info">&nbsp;</span>
                        </Tooltip>
                      )}
                    </td>
                    <td>{formatDate(stock.date_created, 'age')}</td>
                    <td>{stock.level || 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          !!noneFound && <p>{noneFound}</p>
        )}

        {stockHistory.length > 0 && pages && (
          <div className="collection-action-group">
            <span className="button-group">
              <PrevButton
                onClick={this.onClickHistoryPagePrev}
                type="secondary"
                size="sm"
                disabled={!pages[historyPage - 1]}
              />
              <NextButton
                onClick={this.onClickHistoryPageNext}
                type="secondary"
                size="sm"
                disabled={!pages[historyPage + 1]}
              />
            </span>
          </div>
        )}
      </FadeIn>
    );
  }

  render() {
    const { record, values, content, onChangeStockTracking } = this.props;
    const { historyTab, historyLoading, historyExists } = this.state;

    const hasVariants = record.variants.results.length > 0;

    return (
      <div>
        <fieldset className="full">
          <div className="view-body-section-group">
            <div className="view-body-section">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title="Inventory"
                  subtitle="Manage product inventory and stock levels"
                />
                <Field
                  type="toggle"
                  label="Track inventory on this product"
                  name="stock_tracking"
                  readonly={true}
                  defaultChecked={!!values.stock_tracking}
                  onChange={onChangeStockTracking}
                />
              </div>

              <FadeIn active={!!values.stock_tracking}>
                <div className="view-section-content">
                  <div className="collection-table-container">
                    <table className="collection-table">
                      <thead>
                        <tr>
                          <th>{hasVariants ? 'Variant' : 'Product'}</th>
                          <th>Quantity</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {hasVariants ? (
                          record.variants.results.map((variant) => (
                            <tr key={variant.id}>
                              <td>{this.getProductLabel(variant)}</td>
                              <td>{variant.stock_level || 0}</td>
                              <td className="action">
                                <a
                                  href=""
                                  onClick={this.onClickAdjustInventory}
                                  data-variant={variant.id}
                                >
                                  Adjust
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>{this.getProductLabel(record)}</td>
                            <td>{record.stock_level || 0}</td>
                            <td className="action">
                              <a href="" onClick={this.onClickAdjustInventory}>
                                Adjust
                              </a>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <FadeIn
                    active={!!values.stock_tracking}
                    transitionAppear={false}
                  >
                    <Field
                      type="toggle"
                      name="stock_purchasable"
                      label="Allow purchase when out of stock"
                      defaultChecked={!!values.stock_purchasable}
                    />
                  </FadeIn>
                </div>
              </FadeIn>
            </div>

            <FadeIn active={!!values.stock_tracking}>
              {historyExists ? (
                <div className="view-body-section">
                  <div className="view-body-subheader">
                    <SectionHeader
                      className="view-body-subtitle"
                      title="History"
                    />
                  </div>
                  <div className="view-section-content">
                    <Fragment>
                      <Tabs
                        name="tab"
                        items={[
                          { value: 'all', label: 'All history' },
                          { value: 'manual', label: 'Adjustments' },
                          { value: 'orders', label: 'Orders' },
                        ]}
                        onChange={this.onChangeHistoryTab}
                      />
                      <TabView value={historyTab} active="all" default>
                        {historyTab === 'all' && this.renderHistory()}
                      </TabView>
                      <TabView value={historyTab} active="orders">
                        {historyTab === 'orders' &&
                          this.renderHistory(
                            <span>No stock has been adjusted by an order</span>,
                          )}
                      </TabView>
                      <TabView value={historyTab} active="manual">
                        {historyTab === 'manual' &&
                          this.renderHistory(
                            <span>No manual adjustments have been made</span>,
                          )}
                      </TabView>
                    </Fragment>
                  </div>
                </div>
              ) : (
                historyLoading && (
                  <div>
                    <br />
                    <Loading />
                    <br />
                  </div>
                )
              )}
            </FadeIn>
          </div>
        </fieldset>
        <ContentFields
          {...this.props}
          zone="inventory"
          collection="products"
          models={content.models}
          record={record}
          values={values}
          currency={record.currency}
        />
      </div>
    );
  }
}
