import React from 'react';
import get from 'lodash/get';
import pt from 'prop-types';

import { Field, FieldLocalized } from 'components/form';
import { FadeIn } from 'components/transitions';

import TrialPurchaseOptionGroups from './trial-groups';

export default class TrialPurchaseOption extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    currency: pt.string,
    settings: pt.object,
    optionValues: pt.object,
    getFieldName: pt.func,
  };

  render() {
    const { record, settings, optionValues, getFieldName, currency } = this.props;

    const hasGroups =
      get(settings.accounts, 'groups') && settings.accounts.groups.length > 0;

    const disabled = !optionValues.active;

    return (
      <fieldset className="full">
        <div className="view-body-section">
          <header className="view-body-subheader">
            <div>
              <h3 className="view-body-subtitle">Try before you buy</h3>
              <p>
                Pricing and rules that allow customers to try products before
                purchase
              </p>
            </div>
            <div className="view-body-actions">
              <Field
                type="toggle"
                label="Enabled"
                name={getFieldName('trial.active')}
                defaultChecked={!disabled}
              />
            </div>
          </header>

          <FadeIn transitionAppear={false} active={!disabled}>
            <div className="view-section-content">
              <fieldset className="form-field">
                <div className="row">
                  <FieldLocalized
                    type="currency"
                    name={getFieldName('trial.price')}
                    label="Purchase price"
                    className="span1-3rd"
                    defaultValue={optionValues.price}
                    localeValue={optionValues.$currency}
                    localeFieldPrefix={getFieldName('trial')}
                    localeFieldName="price"
                    currency={currency}
                    disabled={disabled}
                    required={true}
                    maxValue={9999999}
                  />

                  <FieldLocalized
                    type="currency"
                    name={getFieldName('trial.auth_amount')}
                    label="Authorization amount"
                    className="span1-3rd"
                    defaultValue={optionValues.auth_amount}
                    localeValue={optionValues.$currency}
                    localeFieldPrefix={getFieldName('trial')}
                    localeFieldName="auth_amount"
                    disabled={disabled}
                    currency={currency}
                    maxValue={9999999}
                  />

                  <Field
                    type="number"
                    name={getFieldName('trial.trial_days')}
                    label="Trial days"
                    className="span1-3rd"
                    defaultValue={optionValues.trial_days}
                    placeholder="30"
                    disabled={disabled}
                    minValue={1}
                    maxValue={999}
                  />
                </div>
              </fieldset>

              {hasGroups && (
                <TrialPurchaseOptionGroups
                  name={getFieldName('trial.prices')}
                  values={optionValues.prices}
                  settings={settings}
                  disabled={disabled}
                />
              )}
            </div>
          </FadeIn>
        </div>
      </fieldset>
    );
  }
}
