import React, { Fragment } from 'react';
import { get, reduce, find, some, each, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import {
  isPrice,
  formatCurrency,
  formatCurrencyMaybeRound,
  formatNumber,
  currencyValue,
  CURRENCY_CODE,
} from 'utils';

import {
  subscriptionIntervalOptions,
  subscriptionPlanDesc,
} from 'utils/subscription';

import { Field } from 'components/form';
import Loading from 'components/loading';
import Help from 'components/tooltip/help';
import { FadeIn } from 'components/transitions';

export function getDeprecatedSubscriptionOptionPlan(product, values) {
  if (values.options) {
    let subOptionValue;

    each(product.options, (op) => {
      for (const valueOp of values.options) {
        if (valueOp.id === op.id && op.subscription === true) {
          for (const opValue of op.values || []) {
            if (valueOp.value === opValue.id) {
              subOptionValue = opValue;
              break;
            }
          }
          break;
        }
      }
    });

    if (subOptionValue) {
      return {
        billing_schedule: {
          interval: subOptionValue.subscription_interval,
          interval_count: subOptionValue.subscription_interval_count,
          trial_days: subOptionValue.subscription_trial_days,
        },
      };
    }
  }

  return null;
}

export default class PurchaseOptions extends React.PureComponent {
  static contextTypes = {
    setFormValue: PropTypes.func.isRequired,
  };

  hasInitialPricedItem() {
    return this.props.editAddItem || this.props.editSubscriptionPlan;
  }

  isDraftSubscription() {
    const { record } = this.props;

    return this.props.editSubscriptionPlan && (isEmpty(record) || record.draft);
  }

  getCurrentProduct() {
    return this.props.product;
  }

  purchaseOptionValue(field) {
    const { editAddItem, record, values } = this.props;
    const path = `purchase_option.${field}`;

    return get(values, path) !== undefined
      ? get(values, path)
      : editAddItem
      ? get(editAddItem, path)
      : get(record, path);
  }

  renderStandardPurchaseInputs() {
    const {
      editAddItem,
      record,
      values,
      pricedItem,
      priceLoading,
      priceOptionsRequired,
      editProductChanged,
    } = this.props;

    const product = this.getCurrentProduct();
    const { currency } = this.props || product;

    const actualPrice = isPrice(values.set_price)
      ? values.set_price
      : pricedItem && pricedItem.price;

    const actualQuantity =
      (values.quantity !== undefined
        ? values.quantity
        : editAddItem
        ? editAddItem.quantity
        : this.props.editSubscriptionPlan && record.quantity) || 1;

    const subscriptionPlans =
      get(product, 'purchase_options.subscription.plans') || [];

    const valuePlanId = get(values, 'purchase_option.plan_id');

    const planTrialDays = get(
      find(subscriptionPlans, { id: valuePlanId }),
      'billing_schedule.trial_days',
    );

    const legacySubscriptionOptionPlan = getDeprecatedSubscriptionOptionPlan(
      product,
      values,
    );

    const subscriptionTrialDays =
      get(values, 'purchase_option.type') === 'subscription' &&
      (!this.props.editSubscriptionPlan || this.isDraftSubscription()) &&
      (planTrialDays ||
        get(values.purchase_option, 'billing_schedule.trial_days') ||
        get(legacySubscriptionOptionPlan, 'billing_schedule.trial_days'));

    return (
      <table className="order-standard-purchase-inputs">
        <thead>
          <tr>
            <th>Qty</th>
            <th>Price</th>
            <th>&nbsp;</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td width="100">
              <Field
                type="number"
                placeholder="1"
                name="quantity"
                scale={0}
                minValue={1}
                maxValue={9999999}
                defaultValue={
                  editAddItem
                    ? editAddItem.quantity
                    : this.props.editSubscriptionPlan
                    ? record.quantity || null
                    : values.quantity
                }
                selectFocus={true}
              />
            </td>

            <td width="150">
              <Field
                type="currency"
                name="set_price"
                maxValue={9999999}
                placeholder={currencyValue(
                  pricedItem ? pricedItem.price : 0,
                  currency,
                )}
                defaultValue={
                  editProductChanged
                    ? null
                    : editAddItem?.price !== editAddItem?.orig_price
                    ? editAddItem.price
                    : this.props.editSubscriptionPlan
                    ? record.price
                    : values.set_price
                }
                currency={currency}
              />
            </td>

            <td
              className={`currency ${
                subscriptionTrialDays > 0 ? 'with-trial' : ''
              }`}
            >
              {priceLoading && !priceOptionsRequired ? (
                <Loading />
              ) : priceOptionsRequired ? (
                <span className="muted note">
                  Select options to calculate price
                </span>
              ) : (
                pricedItem && (
                  <FadeIn>
                    {actualQuantity > 1 ? (
                      <Fragment>
                        <span className="nowrap">
                          <b>Total</b>&emsp;
                          <span className="muted">
                            {formatNumber(actualQuantity)} &times;{' '}
                            {formatCurrencyMaybeRound(actualPrice, currency)} =
                          </span>
                        </span>
                        &nbsp;
                      </Fragment>
                    ) : (
                      <Fragment>
                        <b>Total</b>&emsp;
                      </Fragment>
                    )}

                    <span>
                      {subscriptionTrialDays > 0 ? (
                        <Fragment>
                          <strike>
                            {formatCurrency(
                              actualPrice * actualQuantity,
                              currency,
                            )}
                          </strike>
                          <div className="positive">
                            {subscriptionTrialDays}-day trial
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {formatCurrency(
                            actualPrice * actualQuantity,
                            currency,
                          )}
                        </Fragment>
                      )}
                    </span>
                  </FadeIn>
                )
              )}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  onSubscriptionPlanChange = (event, planId, isTrusted) => {
    if (isTrusted) {
      const currentPrice = this.props.values.set_price;

      if (typeof currentPrice === 'number') {
        const product = this.getCurrentProduct();

        const plan = product.purchase_options.subscription.plans.find(
          (plan) => plan.id === planId,
        );

        if (plan) {
          this.context.setFormValue('set_price', null);
        }
      }
    }
  };

  renderSubscriptionPurchaseOption() {
    const { currency, values, pricedItem } = this.props;

    const product = this.getCurrentProduct();

    const subscriptionPlans =
      get(product, 'purchase_options.subscription.plans') || [];

    const editPlanId = this.props.subscriptionPlanId;
    const valuePlanId = get(values, 'purchase_option.plan_id');

    const showCustomSubscriptionPlans =
      subscriptionPlans.length === 0 ||
      valuePlanId === 'custom' ||
      (!valuePlanId && editPlanId === 'custom');

    const hasOrderSchedule =
      pricedItem &&
      get(
        values,
        'purchase_option.has_order_schedule',
        Boolean(get(pricedItem, 'purchase_option.order_schedule', false)),
      );

    const orderSchedule =
      get(pricedItem, 'purchase_option.order_schedule') || {};

    return (
      <FadeIn transitionAppear={false}>
        {subscriptionPlans.length > 0 && (
          <div className="row">
            <Field
              type="select"
              label="Plan"
              name="purchase_option.plan_id"
              className="span4"
              options={[
                ...subscriptionPlans.map((plan) => ({
                  value: plan.id,
                  label: subscriptionPlanDesc(plan, {
                    currency: currency || CURRENCY_CODE,
                  }),
                })),
                { value: 'custom', label: 'Custom' },
              ]}
              required={true}
              defaultValue={editPlanId || subscriptionPlans[0].id}
              onChange={this.onSubscriptionPlanChange}
            />
          </div>
        )}

        {!showCustomSubscriptionPlans ? (
          <Field
            type="hidden"
            name="purchase_option.billing_schedule"
            value={null}
          />
        ) : (
          <FadeIn>
            <table className="order-subscription-purchase-inputs">
              <thead>
                <tr>
                  <th key="1" colSpan="2">
                    Interval
                  </th>

                  {(!this.props.editSubscriptionPlan ||
                    this.isDraftSubscription()) && <th key="5">Trial days</th>}

                  <th key="6">
                    Limit{' '}
                    <Help message="Optionally limit the number of times a subscription will invoice the customer" />
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td width="100">
                    <Field
                      type="number"
                      name="purchase_option.billing_schedule.interval_count"
                      placeholder="1"
                      minValue={1}
                      maxValue={999}
                      selectFocus={true}
                      defaultValue={
                        pricedItem &&
                        (get(
                          pricedItem,
                          'purchase_option.billing_schedule.interval_count',
                        ) ||
                          1)
                      }
                    />
                  </td>

                  <td>
                    <Field
                      type="select"
                      name="purchase_option.billing_schedule.interval"
                      options={subscriptionIntervalOptions(
                        get(
                          values,
                          'purchase_option.billing_schedule.interval_count',
                          pricedItem &&
                            get(
                              pricedItem,
                              'purchase_option.billing_schedule.interval_count',
                            ),
                        ),
                      )}
                      defaultValue={
                        pricedItem
                          ? get(
                              pricedItem,
                              'purchase_option.billing_schedule.interval',
                              'monthly',
                            )
                          : 'monthly'
                      }
                      required={true}
                    />
                  </td>

                  {(!this.props.editSubscriptionPlan ||
                    this.isDraftSubscription()) && (
                    <td width="100">
                      <Field
                        type="number"
                        name="purchase_option.billing_schedule.trial_days"
                        placeholder="0"
                        maxValue={999}
                        autoFocus={false}
                        selectFocus={true}
                        defaultValue={
                          pricedItem &&
                          get(
                            pricedItem,
                            'purchase_option.billing_schedule.trial_days',
                          )
                        }
                      />
                    </td>
                  )}

                  <td key="6" width="100">
                    <Field
                      type="number"
                      name="purchase_option.billing_schedule.limit"
                      placeholder="∞"
                      maxValue={999}
                      autoFocus={false}
                      selectFocus={true}
                      defaultValue={
                        pricedItem &&
                        get(
                          pricedItem,
                          'purchase_option.billing_schedule.limit',
                        )
                      }
                    />
                  </td>
                </tr>

                {product.type !== 'digital' && (
                  <Fragment>
                    <tr>
                      <td colSpan={3}>
                        <Field
                          className="items-add-purchase-option-toggle"
                          type="toggle"
                          label="Fulfill separately"
                          readonly={true}
                          name="purchase_option.has_order_schedule"
                          defaultChecked={hasOrderSchedule}
                        />

                        {!hasOrderSchedule &&
                          get(pricedItem, 'purchase_option.order_schedule') && (
                            <Field
                              type="hidden"
                              name="purchase_option.order_schedule"
                              value={null}
                            />
                          )}
                      </td>
                    </tr>

                    {hasOrderSchedule ? (
                      <tr>
                        <td colSpan={3}>
                          <table className="order-subscription-purchase-inputs">
                            <thead>
                              <tr>
                                <th key="1" colSpan="2">
                                  Order interval
                                </th>

                                <th key="6">
                                  Limit{' '}
                                  <Help message="Optionally limit the number of orders created by this subscription plan" />
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td width="100">
                                  <Field
                                    type="number"
                                    name="purchase_option.order_schedule.interval_count"
                                    placeholder="1"
                                    minValue={1}
                                    maxValue={999}
                                    selectFocus={true}
                                    defaultValue={
                                      orderSchedule.interval_count !== undefined
                                        ? orderSchedule.interval_count
                                        : get(
                                            values,
                                            'purchase_option.billing_schedule.interval_count',
                                          )
                                    }
                                  />
                                </td>

                                <td>
                                  <Field
                                    type="select"
                                    name="purchase_option.order_schedule.interval"
                                    options={subscriptionIntervalOptions(
                                      get(
                                        values,
                                        'purchase_option.order_schedule.interval_count',
                                        orderSchedule.interval_count,
                                      ),
                                    )}
                                    defaultValue={
                                      orderSchedule.interval !== undefined
                                        ? orderSchedule.interval
                                        : get(
                                            values,
                                            'purchase_option.billing_schedule.interval',
                                          )
                                    }
                                  />
                                </td>

                                <td width="100">
                                  <Field
                                    type="number"
                                    name="purchase_option.order_schedule.limit"
                                    defaultValue={
                                      orderSchedule.limit !== undefined
                                        ? orderSchedule.limit
                                        : get(
                                            values,
                                            'purchase_option.billing_schedule.limit',
                                          )
                                    }
                                    placeholder="∞"
                                    selectFocus={true}
                                    maxValue={999}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ) : null}
                  </Fragment>
                )}
              </tbody>
            </table>
          </FadeIn>
        )}

        {this.renderStandardPurchaseInputs()}
      </FadeIn>
    );
  }

  renderTrialPurchaseOption() {
    const {
      editAddItem,
      record,
      values,
      priceOptionsRequired,
      priceLoading,
      pricedItem,
    } = this.props;

    const product = this.getCurrentProduct();
    const { currency } = product || this.props;

    const actualPrice = isPrice(values.set_price)
      ? values.set_price
      : pricedItem && pricedItem.price;

    const actualQuantity =
      (values.quantity !== undefined
        ? values.quantity
        : editAddItem
        ? editAddItem.quantity
        : record && record.quantity) || 1;

    const authAmount = this.purchaseOptionValue('auth_amount');
    const trialDays = this.purchaseOptionValue('trial_days');
    const isItemShipped = !!(editAddItem && editAddItem.date_trial_end);

    return (
      <table className="order-standard-purchase-inputs">
        <thead>
          <tr>
            <th>Qty</th>
            <th>Price</th>
            <th>{isItemShipped ? 'Try period ends' : 'Auth amount'}</th>
            <th>{isItemShipped ? '' : 'Trial days'}</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td width="100">
              <Field
                type="number"
                placeholder="1"
                name="quantity"
                scale={0}
                minValue={1}
                maxValue={9999999}
                selectFocus={true}
                defaultValue={
                  (editAddItem
                    ? editAddItem.quantity
                    : record
                    ? record.quantity
                    : values.quantity) || 1
                }
              />
            </td>

            <td width="150">
              <Field
                type="currency"
                name="set_price"
                maxValue={9999999}
                currency={currency}
                placeholder={currencyValue(
                  get(pricedItem, 'price', 0),
                  currency,
                )}
                defaultValue={
                  editAddItem
                    ? editAddItem.price
                    : record
                    ? record.price
                    : values.set_price
                }
              />
            </td>

            {isItemShipped ? (
              <Fragment>
                <td width="100">
                  <Field
                    type="date"
                    name="date_trial_end"
                    defaultValue={editAddItem.date_trial_end}
                  />
                </td>

                <td width="150">
                  <Field
                    type="time"
                    name="date_trial_end_time"
                    defaultValue={editAddItem.date_trial_end}
                  />
                </td>
              </Fragment>
            ) : (
              <Fragment>
                <td width="150">
                  <Field
                    type="currency"
                    name="purchase_option.auth_amount"
                    maxValue={9999999}
                    currency={currency}
                    placeholder={currencyValue(
                      get(pricedItem, 'purchase_option.auth_amount', 0),
                      currency,
                    )}
                    defaultValue={authAmount}
                  />
                </td>

                <td width="100">
                  <Field
                    type="number"
                    name="purchase_option.trial_days"
                    maxValue={9999999}
                    minValue={1}
                    placeholder={get(
                      pricedItem,
                      'purchase_option.trial_days',
                      1,
                    )}
                    defaultValue={trialDays}
                  />
                </td>
              </Fragment>
            )}
          </tr>

          <tr>
            <td className="currency with-trial" colSpan="100">
              <div className="order-trial-total">
                {priceLoading && !priceOptionsRequired ? (
                  <Loading />
                ) : priceOptionsRequired ? (
                  <span className="muted note">
                    Select options to calculate price
                  </span>
                ) : (
                  pricedItem && (
                    <FadeIn>
                      {actualQuantity > 1 ? (
                        <Fragment>
                          <span className="nowrap">
                            <b>Total</b>&emsp;
                            <span className="muted">
                              {formatNumber(actualQuantity)} &times;{' '}
                              {formatCurrencyMaybeRound(actualPrice, currency)}{' '}
                              =
                            </span>
                          </span>
                          &nbsp;
                        </Fragment>
                      ) : (
                        <Fragment>
                          <b>Total</b>&emsp;
                        </Fragment>
                      )}

                      <span>
                        {formatCurrency(actualPrice * actualQuantity, currency)}
                      </span>
                    </FadeIn>
                  )
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  getPurchaseOptions(product) {
    const { subscription, editAddItem, isCart } = this.props;
    const { purchase_options, price, sale, sale_price, prices } = product || {};

    if (
      this.props.editSubscriptionPlan &&
      get(purchase_options, 'subscription')
    ) {
      const option = get(purchase_options, 'subscription', {});

      return [
        {
          ...option,
          plans: option.plans || [],
          type: 'subscription',
          name: option.name || 'Subscription',
        },
      ];
    }

    const options = subscription
      ? []
      : reduce(
          purchase_options,
          (acc, option, key) => {
            if (option.active) {
              switch (key) {
                case 'standard': {
                  acc.push({
                    ...option,
                    type: 'standard',
                    name: option.name || 'One-time purchase',
                  });

                  break;
                }

                case 'subscription': {
                  acc.push({
                    ...option,
                    plans: option.plans || [],
                    type: 'subscription',
                    name: option.name || 'Subscription',
                  });

                  break;
                }

                case 'trial': {
                  if (isCart || editAddItem) {
                    // trial product can only be added to the cart or edited in an already submitted order
                    acc.push({
                      ...option,
                      type: 'trial',
                      name: option.name || 'Try before you buy',
                    });
                  }

                  break;
                }

                default:
                  break;
              }
            }

            return acc;
          },
          [],
        );

    // Default standard
    if (options.length === 0) {
      options.push({
        type: 'standard',
        price,
        sale,
        sale_price,
        prices,
      });
    }

    return options;
  }

  render() {
    const { values } = this.props;

    const product = this.getCurrentProduct();

    const purchaseOptions = this.getPurchaseOptions(product);
    const valueType = get(values, 'purchase_option.type');
    const selectedType =
      valueType ||
      (purchaseOptions.length > 0 ? purchaseOptions[0].type : null);

    const hasDeprecatedSubscriptionOption = some(product.options, {
      subscription: true,
    });

    return purchaseOptions.length === 1 ? (
      <Fragment>
        <Field
          type="hidden"
          name="purchase_option.type"
          value={purchaseOptions[0].type}
        />
        {purchaseOptions[0].type === 'standard' ? (
          <div className="order-purchase-options standard-only">
            {this.props.editSubscriptionPlan && !hasDeprecatedSubscriptionOption
              ? this.renderSubscriptionPurchaseOption()
              : this.renderStandardPurchaseInputs()}
          </div>
        ) : purchaseOptions[0].type === 'subscription' ? (
          <div className="order-purchase-options standard-only">
            {this.renderSubscriptionPurchaseOption()}
          </div>
        ) : purchaseOptions[0].type === 'trial' ? (
          <div className="order-purchase-options standard-only">
            {this.renderTrialPurchaseOption()}
          </div>
        ) : null}
      </Fragment>
    ) : (
      <div className="order-purchase-options">
        <div className="box">
          <Field
            type="hidden"
            name="purchase_option.type"
            value={selectedType}
          />
          {purchaseOptions.map((option) => (
            <div key={option.type} className="box-section tight">
              {/*
                This radio field should be reworked like this:
                <Field
                    type="radio"
                    checked={!!selectedType}
                    onChange={this.onChangePurchaseOptionType}
                    options={[ purchase options ]}
                />
              */}
              <Field
                type="radio"
                label={option.name}
                value={option.type}
                checked={selectedType === option.type}
                help={option.description}
                onChange={(event, value) =>
                  this.props.onChangePurchaseOptionType(
                    option.type,
                    event,
                    value,
                  )
                }
              />

              {selectedType === option.type && (
                <FadeIn transitionAppear={Boolean(valueType)}>
                  <div className="order-purchase-option-inputs">
                    {option.type === 'standard'
                      ? this.renderStandardPurchaseInputs()
                      : option.type === 'subscription'
                      ? this.renderSubscriptionPurchaseOption()
                      : option.type === 'trial'
                      ? this.renderTrialPurchaseOption()
                      : null}
                  </div>
                </FadeIn>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
