import React from 'react';
import pt from 'prop-types';

import { formatCurrency } from 'utils';
import { productThumbUrl } from 'utils/product';

import Icon from 'components/icon';
import Link from 'components/link';

export const fieldWrapper = Object.freeze({
  image: {
    width: '45px',
    style: {
      whiteSpace: 'nowrap',
      paddingRight: 0,
    },
  },
  default: {},
  actions: { className: 'action nowrap' },
});

const buttonStyle = Object.freeze({
  verticalAlign: 'top',
});

const defaultWidths = Object.freeze([
  undefined,
  undefined,
  undefined,
  undefined,
]);

function CategoryProduct({
  itemId,
  product,
  sorting,
  widths = defaultWidths,
  movableProps,
  onRemove,
}) {
  return (
    <tr {...movableProps}>
      <td {...fieldWrapper.image}>
        <span className="collection-table-images">
          <span className="image">
            <img src={productThumbUrl(product)} alt={product.name} />
          </span>
        </span>
      </td>

      <td {...fieldWrapper.default} width={widths[1]}>
        <Link to={`/products/${product.id}`}>{product.name}</Link>
      </td>

      <td {...fieldWrapper.default} width={widths[2]}>
        <span>{formatCurrency(product.price, product.currency)}</span>
      </td>

      <td {...fieldWrapper.actions} width={widths[3]}>
        <button
          data-id={itemId}
          style={buttonStyle}
          className="as-link"
          onClick={onRemove}
          type="button"
        >
          Remove
        </button>

        {sorting && (
          <button className="drag" data-movable-handle="true" type="button">
            <Icon type="drag-vertical" />
          </button>
        )}
      </td>
    </tr>
  );
}

CategoryProduct.propTypes = {
  itemId: pt.string,
  product: pt.object.isRequired,
  sorting: pt.bool,
  widths: pt.array,
  movableProps: pt.object,

  onRemove: pt.func,
};

export default React.memo(CategoryProduct);
