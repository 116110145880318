import PropTypes from 'prop-types';
import React from 'react';
import { Draggable, Droppable } from 'react-drag-and-drop';
import Icon from 'components/icon';
import Label from './label';
import { calculateTagsInputStyle } from 'utils/form';
import { Form, Field } from 'components/form';
import Modal from 'components/modal';

export default class InputTags extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
  };

  static propTypes = {
    value: PropTypes.array,
    allowSpace: PropTypes.bool,
    editableTags: PropTypes.bool, // tag can be edited
    readonlyContent: PropTypes.bool,
    onSelectValue: PropTypes.func,
    onRemoveValue: PropTypes.func,
    renderTag: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      text: '',
      value: props.value || [],
      dragSourceId: null,
      dragTargetId: null,
      editTagIndex: null,
      editTagValue: {},
    };
    this.dragging = false;
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.updateTags = this.updateTags.bind(this);
    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onClickRemove = this.onClickRemove.bind(this);
    this.onClickSelect = this.onClickSelect.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== undefined && prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value }, () => this.forceUpdate());
    }
  }

  onKeyDown(event) {
    switch (event.keyCode) {
      case 13: // enter
      case 188: // comma
        event.preventDefault();
        if (event.target.value.length) {
          this.addTag(event.target.value.trim());
          event.target.value = '';
        }
        break;
      case 8: // backspace
        if (!event.target.value.length) {
          this.removeTag(this.state.value.length - 1);
          event.target.value = '';
        }
        break;
      case 32: // space
        if (this.props.allowSpace === false) {
          event.preventDefault();
          if (event.target.value.length) {
            this.addTag(event.target.value.trim());
            event.target.value = '';
          }
        } else {
          if (!event.target.value.length) {
            event.preventDefault();
          }
        }
        break;
      default:
        break;
    }
  }

  onBlur() {
    if (this.refs.input.value.length) {
      this.addTag(this.refs.input.value.trim());
      this.refs.input.value = '';
    }
  }

  onClickRemove(event) {
    event.preventDefault();
    event.stopPropagation();
    this.removeTag(event.currentTarget.dataset.index);
    this.refs.input.focus();
  }

  onClickSelect(event) {
    event.preventDefault();
    const { onSelectValue } = this.props;
    if (onSelectValue) {
      const { index } = event.currentTarget.dataset;
      const value = this.state.value[index];
      onSelectValue((value && value.name) || value, index);
    }
  }

  onDragStart(event) {
    const dragSourceId = Number(event.currentTarget.dataset.id);
    if (this.dragging) {
      return;
    }
    this.dragging = true;
    setTimeout(() => this.setState({ dragSourceId }), 1);
    event.dataTransfer.effectAllowed = 'move';
  }

  onDragEnd(event) {
    this.dragging = false;
    const { dragSourceId, dragTargetId } = this.state;
    this.setState({ dragSourceId: null, dragTargetId: null });
    if (
      dragSourceId === null ||
      dragTargetId === null ||
      dragSourceId === dragTargetId
    ) {
      return;
    }

    const next = [...this.state.value];
    const element = next.splice(dragSourceId, 1)[0];
    next.splice(dragTargetId, 0, element);
    this.updateTags(next);
  }

  onDragEnter(event) {
    const dragTargetId = Number(event.currentTarget.dataset.id);
    this.setState({ dragTargetId });
    event.stopPropagation();
  }

  onDragLeave(event) {
    //
  }

  updateTags(newTags) {
    const event = new Event('change', { bubbles: true });
    this.setState({ value: newTags }, () => this.forceUpdate());
    this.refs.input.dispatchEvent(event);
    this.props.onChange(event, newTags);
  }

  addTag(value) {
    const next = [...this.state.value];
    next.push(value);
    this.updateTags(next);
  }

  removeTag(index) {
    const { onRemoveValue } = this.props;
    const value = this.state.value[index];
    const name = (value && value.name) || value;
    if (onRemoveValue && onRemoveValue(name) === false) {
      this.context.openModal('ConfirmDelete', {
        title: name,
        onConfirm: () => {
          this.removeTagConfirmed(index);
        },
      });
    } else {
      this.removeTagConfirmed(index);
    }
  }

  removeTagConfirmed(index) {
    const next = [...this.state.value];
    next.splice(index, 1);
    this.updateTags(next);
  }

  getTagsValue() {
    const { value } = this.state;
    return (
      value instanceof Array
        ? value.map((val) => (val && val.name !== undefined ? val.name : val))
        : value.split
        ? value.split(/\s*,\s*/)
        : []
    ).filter((x) => String(x).trim());
  }

  onClickTag = (event) => {
    event.preventDefault();
    const { index } = event.currentTarget.dataset;
    const editIndex = Number(index);
    const { value } = this.state;
    if (!Array.isArray(value) || value.length <= editIndex) {
      return;
    }

    this.setState({
      editTagIndex: editIndex,
      editTagValue: {
        tag: value[editIndex],
      },
    }, () => {
      this.context.openModal(
        'tags_edit_value',
        this.renderValueForm,
      );
    })
  }

  onChangeValueForm = (values) => {
    this.setState({
      editTagValue: values,
    });
  };

  onCloseValueForm = () => {
    this.context.closeModal('tags_edit_value');
  };

  onClickCloseValueForm = (event) => {
    event.preventDefault();
    this.onCloseValueForm();
  }

  onSubmitValueForm = (values) => {
    this.onCloseValueForm();

    const { editTagIndex, value } = this.state;
    if (!Array.isArray(value) || value.length <= editTagIndex) {
      return;
    }

    const newValue = (values.tag || "").trim();
    if (newValue === "" || newValue === value[editTagIndex]) {
      return;
    }

    const next = [...this.state.value];
    next[editTagIndex] = newValue;
    this.updateTags(next);
  };

  // value editing form
  renderValueForm = () => {
    const value = this.state.editTagValue;

    return (
      <Form
        onSubmit={this.onSubmitValueForm}
        onChange={this.onChangeValueForm}
        autoFocus={true}
      >
        <Modal
          title="Edit value"
          width={600}
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'cancel',
              onClick: this.onCloseValueForm,
            },
          ]}
          cancel={false}
          onClose={this.onClickCloseValueForm}
          localized={true}
        >
          <fieldset>
            <Field
              type="text"
              name="tag"
              label="Value"
              defaultValue={value.tag}
              required={true}
              autoFocus={true}
              rules="tag,required"
            />
          </fieldset>
        </Modal>
      </Form>
    );
  };

  renderTag = (tag, index) => {
    const { renderTag, editableTags } = this.props;
    // tag has own render function
    if (renderTag) {
      return renderTag(tag);
    }

    // tag is not editable (default)
    if (!editableTags) {
      return tag;
    }

    // tag is editable
    return (
      <button
        className="editableTags"
        data-index={index}
        onClick={this.onClickTag}
      >
        {tag}
      </button>
    );
  }

  render() {
    const {
      label,
      help,
      readonlyContent,
      renderTag,
      onSelectValue,
      onRemoveValue,
      editableTags,
      ...props
    } = this.props;
    const { dragTargetId, dragSourceId } = this.state;
    const tagsValue = this.getTagsValue();

    return (
      <span className="form-tags">
        {label && <Label label={label} help={help} htmlFor={props.id} />}
        <span className="form-field-input">
          <input
            {...props}
            type="text"
            ref="input"
            value={undefined}
            defaultValue={undefined}
            onChange={undefined}
            onKeyDown={this.onKeyDown}
            onBlur={this.onBlur}
            className={`form-tags-input ${props.disabled ? 'disabled' : ''} ${
              props.className || ''
            }`}
            placeholder={tagsValue.length > 0 ? undefined : props.placeholder}
            style={calculateTagsInputStyle(this.refs.input, this.refs.taglist)}
          />
          <ul className="form-tags-list" ref="taglist">
            {tagsValue.map((tag, index) => (
              <Draggable
                wrapperComponent={{
                  type: 'li',
                  props: {
                    className: `
                      ${onSelectValue ? 'selectable' : ''}
                      ${dragSourceId === index ? 'draghidden' : ''}
                      ${
                        dragTargetId === index && dragSourceId !== dragTargetId
                          ? dragSourceId < dragTargetId
                            ? 'dragafter'
                            : 'dragbefore'
                          : ''
                      }
                      `,
                  },
                }}
                type="sortable"
                key={index}
                data-id={index}
                data-index={index}
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
                onClick={this.onClickSelect}
              >
                <Droppable
                  data-id={index}
                  types={['sortable']}
                  onDragEnter={this.onDragEnter}
                  onDragLeave={this.onDragLeave}
                >
                  <div className="tag">
                    {this.renderTag(tag, index)}
                    <a
                      className="remove"
                      href=""
                      onClick={this.onClickRemove}
                      data-index={index}
                    >
                      <Icon fa="times" />
                    </a>
                  </div>
                </Droppable>
              </Draggable>
            ))}
          </ul>
        </span>
      </span>
    );
  }
}
