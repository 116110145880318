/* eslint-disable */
import React from 'react';
import IconHelpLink from 'components/icon/helplink';
import { withRouter } from 'react-router';
import { TEST_ENV } from 'utils';
import './help-link.scss';
import helplinks from './help-links.json';

const { BASE_URI } = process.env;

class HelpLink extends React.PureComponent {
  render() {
    const {
      link,
      sectionName = '',
      isModal = false,
      isIntegration = false,
      overrideKey = null,
      ...props
    } = this.props;

    /** Rules for help link parsing
     *
     * All keys depend on their urls, we use the part after admin/ to create keys. Every "/" turns into "." and every "-" turns into "_".
     * The exception to that rule is the home page, the key for help links on home page should always starts with home.
     * https://mystore.swell.store/admin/reports/payments -> key:reports.payments
     * https://mystore.swell.store/admin/ -> key:home
     * https://mystore.swell.store/admin/reports/customers-first-vs-returning -> key:reports.customers_first_vs_returning
     *
     * We also use tabs and types in the keys when dealing with help links located at the bottom of pages and section help links, replacing "=" by "_".
     * https://mystore.swell.store/admin/orders?tab=unpaid -> key:orders.tab_unpaid
     * https://mystore.swell.store/admin/products/new?type=giftcard -> key:products.new.type_giftcard
     * The only keys we don’t use tabs and types in are for modals. So for example if you click on “Export orders” while in the “Awaiting for payment” tab,
     * the key for the modal will be orders.section_export_orders which is the same for when trying to export on the refunded orders tab.
     *
     * When we are dealing with items that can be created and edited like products, orders, coupons, customers, etc
     * we need different help links for dealing with creating or editing an item. So for help links located at the end of pages,
     * we add new and :id to keys to separate the cases. This means modals and section help links don't need that distinction.
     * https://mystore.swell.store/admin/products/5d48a74a896b24290f2c3c55 -> products.:id
     * Ps. For attributes specifically, the ids are the attribute names, which might be deceiving. The name should be replaced by :id
     * https://mystore.swell.store/admin/attributes/cost_in_eur -> attributes.:id
     *
     * For sections inside pages, all we need is the section title to create the key by adding "section_" in front of the title,
     * removing all capital letters and replacing any spaces by "_". Same goes for modals.
     *
     * We can replace the entire parsing with a custom key using overrideKey.
     */

    let key = null;
    let isSection = sectionName !== '';
    let pathname = this.props.location.pathname;

    if (TEST_ENV && pathname.startsWith(`${BASE_URI}/${TEST_ENV}`)) {
      pathname = pathname.replace(`${BASE_URI}/${TEST_ENV}`, BASE_URI);
    }

    if (!overrideKey) {
      // find key based on url (with tabs) and section names (in or outside modals)
      const idRegex = /\.(?:[0-9]+[a-z]|[a-z]+[0-9]|[0-9])[a-z0-9]*/; // regex to find the id in the string
      let basepath = pathname
        .replace(/\/admin\/?/, '') // remove 'admin'
        .replace(/\//g, '.') // replace '/' for '.'
        .replace(idRegex, '.:id') // replace ids with ':id'
        .replace(/\-/g, '_'); // replace '-' for '_'
      basepath = basepath === '' ? 'home' : basepath; // if basepath is empty, the help link belongs to home page, so we add the keyword for searching
      let keepId = true;

      if (basepath.includes('attributes')) {
        // attributes are user generated, so even if they have names for ids, we should replace them with :id
        basepath = basepath.includes('attributes.new')
          ? basepath
          : basepath.replace(/attributes\..*/, 'attributes.:id'); // replace any attribute name for :id
      }

      if (!isModal) {
        // Non modal help links should include information about current tab and page types

        let searchUrl = this.props.location.search;
        for (let searchItem of ['tab=', 'type=']) {
          let rgxp = new RegExp('.(' + searchItem + ')(.*?((?=&)|$))');
          let found = searchUrl.includes(searchItem)
            ? searchUrl.match(rgxp)[0]
            : null;
          basepath += found
            ? found.replace(/=/g, '_').replace(/\&|\?|\//g, '.')
            : ''; // change from '=' to '_', replace any '?', '&' or '/' with '.'
        }
      } else {
        // ":id" and "new" indicator should only exist in pages footers, not in sections or modals
        keepId = false; // in this case we have a modal
      }

      /**
       * For some cases, for example, clicking on view message on a activity from Order activity list on an order
       * we get a CollectionLocale object instead of the section name, so we have to get it from the object
       */
      let sectionbase = '';
      if (typeof sectionName === 'string') {
        sectionbase = sectionName;
      } else if (sectionName instanceof Object) {
        // get the section name from child component
        if (typeof sectionName.props?.children === 'string') {
          sectionbase = sectionName.props.children;
        } else {
          // not a valid section
          return null;
        }
      }

      let section = '';
      if (isSection) {
        section = '.section_' + sectionbase.toLowerCase().replace(/\s/g, '_'); // lowercase and replace any spaces in the title with "_"
        keepId = false; // in this case we have a section
      }

      // ":id" and "new" indicator should only exist in pages footers, not in sections or modals
      basepath = keepId
        ? basepath
        : basepath.replace('.:id', '').replace('.new', '');

      key = basepath + section;
    } else {
      key = overrideKey;
    }

    // try finding key in existing help links
    const helplink = helplinks.find((item) => item.tokenId === key);

    let url = null;
    if (helplink) {
      // get url: if there is an external url, should prioritize over resource
      url = !helplink.externalUrl
        ? helplink.resource.meta.domain + helplink.resource.meta.path
        : helplink.externalUrl;
    }

    // if it's located in a section, the help link should be on the side, gray questionmark (purple on hover) and text should be "Learn more"
    const className = isSection
      ? ''
      : isIntegration
      ? 'help-link integration'
      : 'help-link';
    // help link only has text on the side if is not in a section and not on integrations page
    const hasText = !isSection && !isIntegration;
    // hoverable help links change color when hovering, should only happen when help link has no text on the side
    const hoverable = !hasText;

    return (
      <div data={'key:' + key}>
        {helplink && helplink.visible && (
          <a href={url} className={className} target="_blank">
            <IconHelpLink hoverable={hoverable} />
            {hasText && (
              <div>
                {' '}
                {helplink.auxiliaryText} <b>{helplink.title} </b>{' '}
              </div>
            )}
          </a>
        )}
      </div>
    );
  }
}


export default withRouter(HelpLink);
