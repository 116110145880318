import React from 'react';
import classNames from 'classnames';
import pt from 'prop-types';

import { inflect, isObject } from 'utils';
import { categoriesHaveFoundChildren } from 'utils/category';

import Status from 'components/status';

import Field from './field';

export default class LookupCategory extends React.PureComponent {
  static propTypes = {
    query: pt.object,
    categories: pt.object.isRequired,

    onQuery: pt.func,
  };

  static contextTypes = {
    queryLookupCategories: pt.func.isRequired,
  };

  onQuery = (query) => {
    if (this.props.onQuery) {
      this.props.onQuery(query);
    } else {
      this.context.queryLookupCategories({
        ...(this.props.query || {}),
        search: query,
      });
    }
  };

  renderCategoryValue = (value) => {
    const { categories } = this.props;
    let category = value;

    if (!isObject(category)) {
      category = categories.index.get(value);
    }

    if (!category) {
      return value;
    }

    let { name, parent } = category;

    while (parent) {
      name = `${parent.name} / ${name}`;
      parent = parent.parent;
    }

    return name;
  };

  renderCategoryLookupItems = (props) => {
    const { list, lookup } = this.props.categories;
    const items = list
      .map((category) => this.renderCategoryLookupItem(category, lookup, props))
      .filter((item) => item !== null);
    return items;
  };

  renderCategoryLookupItem(category, lookup, props, depth = 0) {
    if (!categoriesHaveFoundChildren([category], lookup)) {
      return null;
    }

    const selected = category.id === props.selected?.id;

    return [
      <li
        key={category.id}
        role="option"
        data-id={category.id}
        aria-selected={selected}
        className={classNames('item', { selected })}
        onMouseOver={props.onMouseOver}
        onClick={props.onClick}
      >
        <span className="col" style={{ paddingLeft: 18 + depth * 20 }}>
          {category.name}
        </span>

        <span className="col muted">
          {inflect(category.product_count, 'products')}
        </span>

        <span className="col">
          {category.active ? (
            <Status type="positive">Active</Status>
          ) : (
            <Status>Inactive</Status>
          )}
        </span>
      </li>,
      ...(category.children?.length > 0
        ? category.children.map((child) =>
            this.renderCategoryLookupItem(child, lookup, props, depth + 1),
          )
        : []),
    ];
  }

  onValueLink(value) {
    return `/categories/${value.id}`;
  }

  render() {
    const { categories, ...props } = this.props;

    return (
      <Field
        ref="field"
        type="lookup"
        name="categories"
        placeholder="Find category by name"
        model="categories"
        onQuery={this.onQuery}
        renderValue={this.renderCategoryValue}
        renderLookupItems={this.renderCategoryLookupItems}
        multiple={false}
        {...props}
        lookup={categories}
        onValueLink={this.onValueLink}
      />
    );
  }
}
