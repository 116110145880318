import React from 'react';

import { accountName } from 'utils/account';
import { subscriptionTitle } from 'utils/subscription';

import api from 'services/api';

import NotFoundPage from 'components/pages/error/404';
import ViewLoading from 'components/view/loading';

import Orders from './Orders';

export default class SubscriptionOrders extends React.Component {
  state = { loaded: false, subscription: null };

  componentDidMount() {
    api
      .get('/data/subscriptions/{id}', {
        id: this.props.params.id,
        expand: 'account, product',
      })
      .then((subscription) => {
        this.setState({ loaded: true, subscription });
      });
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    const { subscription } = this.state;

    if (!subscription) {
      return <NotFoundPage />;
    }

    return (
      <Orders
        {...this.props}
        ref="invoices"
        sectionTitle={`${accountName(
          subscription.account,
        )} / ${subscriptionTitle(subscription)}`}
        title="Subscription orders"
        detail={true}
        nested={true}
        uri={`/subscriptions/${subscription.id}`}
        linkUri="/orders"
        query={
          subscription.order_id
            ? { id: subscription.order_id }
            : { subscription_id: subscription.id }
        }
        emptyAction={false}
      />
    );
  }
}
