import React, { Fragment } from 'react';
import { get, cloneDeep } from 'lodash';
import pt from 'prop-types';

import { currencyDecimals, currencyName } from 'utils/money';
import {
  countryOptions,
  stateOptions,
  countryStateLabel,
  localeName,
  weightUnitOptions,
} from 'utils/geo';

import { Form, Field } from 'components/form';
import View from 'components/view';
import SectionHeader from 'components/section-header';

import Currencies from './general-currencies';
import Locales from './general-locales';

import './settings.scss';

export default class GeneralSettings extends React.PureComponent {
  static propTypes = {
    client: pt.object,
    record: pt.object,
    values: pt.object,
    edited: pt.bool,
    suggestedAddresses: pt.array,

    onChangeForm: pt.func,
    onSubmitForm: pt.func,
    onSubmitClientUpdate: pt.func,
    onSubmitClientFormLoading: pt.func,
  };

  static contextTypes = {
    openModal: pt.func,
  };

  state = {
    loading: false,
  };

  formRef = React.createRef();

  onSubmitMultiCurrency = (values) => {
    this.onSubmitClientFormLoading({
      currencies: values,
    });
  };

  onSubmitMultiLocale = (values) => {
    this.onSubmitClientFormLoading({
      locales: values,
    });
  };

  onMakeDefaultCurrency = (code) => {
    // Set previous and new base currency as priced
    const prevCode = this.props.client.currency;
    const currencies = cloneDeep(this.props.client.currencies) || [];
    const prevCurrency = currencies.find((curr) => curr.code === prevCode);
    if (prevCurrency) {
      prevCurrency.priced = true;
    }
    const nextCurrency = currencies.find((curr) => curr.code === code);
    if (nextCurrency) {
      nextCurrency.priced = true;
    }
    this.onSubmitClientFormLoading({
      currency: code,
      currencies,
    });
  };

  onMakeDefaultLocale = (code) => {
    this.onSubmitClientFormLoading({
      locale: code,
    });
  };

  onClickEnableMultiCurrency = (event) => {
    event.preventDefault();
    const { record } = this.props;
    const code = record.client.currency;
    this.onSubmitClientFormLoading({
      currencies: [
        {
          code,
          name: currencyName(code),
          decimals: currencyDecimals(code),
          priced: true,
          fallback: false,
        },
      ],
    });
  };

  onClickEnableMultiLocale = (event) => {
    event.preventDefault();
    const { values } = this.props;
    const code = values.client.locale;
    if (code) {
      this.onSubmitClientFormLoading({
        locale: code,
        locales: [
          {
            code,
            name: localeName(code),
          },
        ],
      });
    }
  };

  onClickDisableMultiCurrency = (event) => {
    event.preventDefault();
    this.context.openModal('Confirm', {
      title: 'Disable multi-currency',
      message: <p>Are you sure you want to remove additional currencies?</p>,
      actionType: 'danger',
      onConfirm: () => {
        this.props.onSubmitClientFormLoading({
          currencies: [],
        });
      },
    });
  };

  onClickDisableMultiLocale = (event) => {
    event.preventDefault();
    this.context.openModal('Confirm', {
      title: 'Disable multi-Locale',
      message: <p>Are you sure you want to remove additional locales?</p>,
      actionType: 'danger',
      onConfirm: () => {
        this.props.onSubmitClientFormLoading({
          locales: [],
        });
      },
    });
  };

  hasMultiCurrencyOptions() {
    const { record } = this.props;
    return get(record.client.currencies, 'length') > 0;
  }

  hasMultiLocaleOptions() {
    const { record } = this.props;
    return get(record.client.locales, 'length') > 0;
  }

  onAddress = (option) => {
    if (!option) {
      return;
    }

    const { onChangeForm, record } = this.props;

    onChangeForm({
      client: {
        ...record.client,
        business: {
          address1: option.suggestedAddress.street_line,
          address2:
            option.suggestedAddress.entries < 2
              ? option.suggestedAddress.secondary
              : '',
          city: option.suggestedAddress.city,
          country: 'US',
          state: option.suggestedAddress.state,
          zip: option.suggestedAddress.zipcode,
        },
      },
    });
  };

  onSubmitClientFormLoading(values) {
    this.setState({ loading: true });

    return this.props
      .onSubmitClientUpdate(values, this.formRef.current)
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      record,
      values,
      edited,
      onSubmitForm,
      onChangeForm,
      suggestedAddresses,
    } = this.props;
    const { loading } = this.state;

    const clientBusiness = values.client.business || {};
    const clientBusinessCountry = values.client.business
      ? values.client.business.country
      : clientBusiness.country;

    const hasMultiCurrencyOptions = this.hasMultiCurrencyOptions();
    const hasMultiLocaleOptions = this.hasMultiLocaleOptions();

    const orderNumberStart = parseInt(values.orderFormatStart, 10) || 0;

    return (
      <div className="settings settings-general">
        <Form
          ref={this.formRef}
          onSubmit={onSubmitForm}
          onChange={onChangeForm}
          values={values}
        >
          <View
            detail={true}
            submitting={loading}
            uri="/settings"
            sectionTitle="Settings"
            headerTitle="General"
            headerActions={[
              {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
          >
            <fieldset className="full">
              <div className="view-body-section">
                <div className="view-body-subheader">
                  <SectionHeader
                    className="view-body-subtitle"
                    title="Store details"
                    subtitle="This information is presented to your customers in store and elsewhere"
                  />
                </div>
                <div className="view-section-content">
                  <Field
                    type="text"
                    name="client.name"
                    label="Store name"
                    defaultValue={record.client.name}
                    required={true}
                  />
                  <div className="row">
                    <Field
                      type="text"
                      name="client.support_email"
                      label="Customer support email"
                      defaultValue={record.client.support_email}
                      rules="required, email"
                      className="span2"
                    />
                    <Field
                      type="text"
                      name="client.support_phone"
                      label="Customer support phone"
                      defaultValue={record.client.support_phone}
                      placeholder="Optional"
                      className="span2"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="full">
              <div className="view-body-section">
                <div className="view-body-subheader">
                  <SectionHeader
                    className="view-body-subtitle"
                    title="Business details"
                    subtitle="This information is displayed on invoices and used to calculate shipping rates by default"
                  />
                </div>
                <div className="view-section-content">
                  <div className="row">
                    <Field
                      type="text"
                      name="client.business.name"
                      label="Legal business name"
                      defaultValue={clientBusiness.name || record.client.name}
                      required={true}
                      className="span2"
                    />
                    <Field
                      type="text"
                      name="client.business.phone"
                      label="Contact phone"
                      defaultValue={clientBusiness.phone}
                      className="span2"
                    />
                  </div>
                  <Field
                    type="text"
                    name="client.business.company"
                    label="Company"
                    defaultValue={clientBusiness.company}
                  />
                  <Field
                    name="client.business.address1"
                    label="Street address"
                    type="address"
                    onAddress={this.onAddress}
                    options={suggestedAddresses}
                    defaultValue={clientBusiness.address1}
                    values={values}
                    debounce={300}
                  />
                  <Field
                    type="text"
                    name="client.business.address2"
                    label="Apt, suite, etc. (Optional)"
                    defaultValue={clientBusiness.address2}
                  />
                  <div className="row">
                    <Field
                      type="text"
                      name="client.business.city"
                      label="City"
                      defaultValue={clientBusiness.city}
                      className="span2"
                    />
                    <Field
                      type="text"
                      name="client.business.zip"
                      label="Zip/postal code"
                      defaultValue={clientBusiness.zip}
                      className="span2"
                    />
                  </div>
                  <div className="row">
                    <Field
                      type="select"
                      name="client.business.country"
                      label="Country"
                      defaultValue={clientBusiness.country}
                      options={countryOptions}
                      className="span2"
                    />
                    <Field
                      type={
                        stateOptions[clientBusinessCountry] ? 'select' : 'text'
                      }
                      name="client.business.state"
                      label={countryStateLabel(clientBusinessCountry)}
                      defaultValue={clientBusiness.state}
                      options={stateOptions[clientBusinessCountry]}
                      className="span2"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="full">
              <div className="view-body-section">
                <div className="view-body-subheader">
                  <SectionHeader
                    className="view-body-subtitle"
                    title="Locale and timezone"
                    subtitle="Set the base locale that content will be created with, and time zone to format
                  dates in your dashboard. You can change locales later without affecting existing
                  content."
                  />
                </div>
                <div className="view-section-content">
                  <div className="row">
                    <Field
                      type="select"
                      name="client.locale"
                      options={record.geo.locales}
                      defaultValue={record.client.locale}
                      disabled={hasMultiLocaleOptions}
                      required={true}
                      className="span2"
                    />
                    <Field
                      type="select"
                      name="client.timezone"
                      options={record.geo.timezones}
                      defaultValue={record.client.timezone}
                      required={true}
                      className="span2"
                    />
                  </div>
                  {hasMultiLocaleOptions ? (
                    <Fragment>
                      <Locales
                        values={values}
                        record={record}
                        onSubmit={this.onSubmitMultiLocale}
                        onMakeDefault={this.onMakeDefaultLocale}
                      />
                    </Fragment>
                  ) : (
                    <div className="view-section-action-group">
                      <button
                        className="button button-sm button-secondary"
                        onClick={this.onClickEnableMultiLocale}
                        type="button"
                      >
                        Enable multi-language
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </fieldset>

            <fieldset className="full">
              <div className="view-body-section">
                <div className="view-body-subheader">
                  <SectionHeader
                    className="view-body-subtitle"
                    title="Currency and weights"
                    subtitle="Set the base currency and weight units that orders and products will be created
                  with. You can change currencies later without affecting existing orders or
                  products."
                  />
                </div>
                <div className="view-section-content">
                  <div className="row">
                    <Field
                      type="select"
                      name="client.currency"
                      options={record.geo.currencies}
                      defaultValue={record.client.currency}
                      disabled={hasMultiCurrencyOptions}
                      required={true}
                      className="span2"
                    />
                    <Field
                      type="select"
                      name="shipments.weight_unit"
                      options={weightUnitOptions}
                      defaultValue={record.shipments?.weight_unit || 'lb'}
                      required={true}
                      className="span2"
                    />
                  </div>
                  {hasMultiCurrencyOptions ? (
                    <Fragment>
                      <Currencies
                        values={values}
                        record={record}
                        onSubmit={this.onSubmitMultiCurrency}
                        onMakeDefault={this.onMakeDefaultCurrency}
                      />
                    </Fragment>
                  ) : (
                    <div className="view-section-action-group">
                      <button
                        className="button button-sm button-secondary"
                        onClick={this.onClickEnableMultiCurrency}
                        type="button"
                      >
                        Enable multi-currency
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </fieldset>
            <fieldset className="full">
              <div className="view-body-section">
                <div className="view-body-subheader">
                  <SectionHeader
                    className="view-body-subtitle"
                    title="Order number format"
                    subtitle="Set the format that order numbers will be created by. The starting number will be
                  used as the first in the sequence. Changing this format will affect the next order
                  placed."
                  />
                </div>
                <div className="view-section-content">
                  <div className="row">
                    <Field
                      type="text"
                      name="orderFormatPrefix"
                      label="Prefix"
                      defaultValue={record.orderFormatPrefix}
                      className="span2"
                      placeholder="Optional"
                    />
                    <Field
                      type="number"
                      name="orderFormatStart"
                      label="Starting number"
                      defaultValue={record.orderFormatStart}
                      required={true}
                      className="span2"
                      placeholder="100000"
                    />
                  </div>
                  {orderNumberStart >= 0 && (
                    <p className="note">
                      Your order numbers will appear as&nbsp;
                      {values.orderFormatPrefix}
                      {orderNumberStart + 1},&nbsp;
                      {values.orderFormatPrefix}
                      {orderNumberStart + 2},&nbsp;
                      {values.orderFormatPrefix}
                      {orderNumberStart + 3}
                      ...
                    </p>
                  )}
                </div>
              </div>
            </fieldset>
          </View>
        </Form>
      </div>
    );
  }
}
