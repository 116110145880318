import React from 'react';

import { countryOptions, stateOptions, countryStateLabel } from 'utils/geo';

import { Field } from 'components/form';

export default class OrderAddressForm extends React.PureComponent {
  onAddress = (option) => {
    const { onChangeForm, values, name } = this.props;

    if (option) {
      onChangeForm({
        [name]: {
          ...values,
          address1: option.suggestedAddress.street_line,
          address2:
            option.suggestedAddress.entries < 2
              ? option.suggestedAddress.secondary
              : '',
          city: option.suggestedAddress.city,
          country: 'US',
          state: option.suggestedAddress.state,
          zip: option.suggestedAddress.zipcode,
        },
      });
    }
  };

  render() {
    const {
      name,
      record = {},
      values = {},
      required,
      suggestedAddresses,
      disabled,
    } = this.props;

    const { country } = values;
    const isAddressRequired =
      required !== undefined
        ? required
        : values.country ||
          values.first_name ||
          values.last_name ||
          values.address2 ||
          values.city ||
          values.zip ||
          values.state ||
          values.phone;

    return (
      <div data-testid="address-form" className="order-address-form">
        <div className="row">
          <Field
            type="text"
            name={`${name}.first_name`}
            label="First name"
            defaultValue={values.first_name || record.first_name}
            className="span2"
            disabled={disabled}
          />
          <Field
            type="text"
            name={`${name}.last_name`}
            label="Last name"
            defaultValue={values.last_name || record.last_name}
            className="span2"
            disabled={disabled}
          />
        </div>
        <Field
          type="text"
          name={`${name}.company`}
          label="Company"
          defaultValue={values.company}
          disabled={disabled}
        />
        <Field
          type="address"
          name={`${name}.address1`}
          label="Street address"
          options={suggestedAddresses}
          defaultValue={values.address1}
          required={!!isAddressRequired}
          onAddress={this.onAddress}
          values={values}
          disabled={disabled}
          debounce={300}
        />
        <Field
          type="text"
          name={`${name}.address2`}
          label="Apt, suite, etc"
          defaultValue={values.address2}
          disabled={disabled}
        />
        <div className="row">
          <Field
            type="text"
            name={`${name}.city`}
            label="City"
            defaultValue={values.city}
            className="span2"
            disabled={disabled}
          />
          <Field
            type="text"
            name={`${name}.zip`}
            label="Zip/postal code"
            defaultValue={values.zip}
            className="span2"
            disabled={disabled}
          />
        </div>
        <div className="row">
          <Field
            type="select"
            name={`${name}.country`}
            label="Country"
            defaultValue={values.country}
            options={countryOptions}
            required={!!values.address1}
            className="span2"
            disabled={disabled}
          />
          <Field
            type={stateOptions[country] ? 'select' : 'text'}
            name={`${name}.state`}
            label={countryStateLabel(country)}
            defaultValue={values.state}
            options={stateOptions[country]}
            className="span2"
            disabled={disabled}
          />
        </div>
        <div className="row">
          <Field
            type="text"
            name={`${name}.phone`}
            label="Phone number"
            defaultValue={values.phone || record.phone}
            className="span4"
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}
