import React from 'react';
import pt from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import { isPrice, isValueEqual, CURRENCY_CODE } from 'utils';

import { Form, Field, LookupProduct } from 'components/form';
import Modal from 'components/modal';
import { FadeIn } from 'components/transitions';
import ProductOptionInputs from 'components/pages/product/option-inputs';

import PurchaseOptions, {
  getDeprecatedSubscriptionOptionPlan,
} from 'components/purchase-options';

export default class OrderItemsAdd extends React.PureComponent {
  static propTypes = {
    record: pt.shape({
      product_id: pt.string,
      variant_id: pt.string,
      product: pt.object,
      quantity: pt.number,
      price: pt.number,
      draft: pt.bool,
    }),

    editAddItem: pt.shape({
      id: pt.string,
      description: pt.string,
      product: pt.object,
      options: pt.array,
      purchase_option: pt.object,
      recurring: pt.bool,
    }),

    editAddIndex: pt.number,

    addItemProduct: pt.shape({
      id: pt.string,
    }),

    currency: pt.string,

    lookup: pt.object,
    loading: pt.bool,
    subscription: pt.bool,
    isCart: pt.bool,

    getItemPrice: pt.func,
    onEdited: pt.func,
    onSubmitAddItem: pt.func,
    onSubmitEditPlan: pt.func,
    onClickEditPlan: pt.func,
    onClickAddItem: pt.func,
    onCloseEditPlan: pt.func,
    onClickRemoveEditSingleItem: pt.func,
    onChangeAddProductLookup: pt.func,
  };

  constructor(props, context, assignProps) {
    super(props, context);

    this.productLookupQuery = {
      expand: ['bundle_items.product', 'bundle_items.variant', 'variants:1000'],
      fields: 'options, bundle, bundle_items, purchase_options',
    };

    if (assignProps) {
      Object.assign(this, assignProps);
    }

    if (typeof this.editSubscriptionPlan !== 'boolean') {
      this.editSubscriptionPlan = false;
    }

    this.state = {
      values: {
        set_price: (props.editAddItem || props.record || {}).price,
        purchase_option: props.editAddItem
          ? props.editAddItem.purchase_option
          : undefined,
      },
      priceLoading: false,
      priceOptionsRequired: false,
      editProductChanged: false,
    };

    this.state.pricedItem = this.hasInitialPricedItem()
      ? this.getInitialPricedItem()
      : null;

    this.getItemTimer = null;
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onChange = (values, edited) => {
    const hasInitialPricedItem = this.hasInitialPricedItem();

    this.setState(
      (state) => {
        const newValues = {
          ...state.values,
          ...values,
          purchase_option: {
            ...state.values.purchase_option,
            ...values.purchase_option,
            type:
              get(state.values.purchase_option, 'type') ||
              (this.editSubscriptionPlan ? 'subscription' : 'standard'),
          },
        };

        const editProductChanged =
          hasInitialPricedItem && this.isEditProductChanged(newValues);

        return {
          values: newValues,
          editProductChanged,
        };
      },
      () => {
        this.setPricedItem(values, hasInitialPricedItem);
      },
    );

    const { onEdited } = this.props;

    if (typeof onEdited === 'function') {
      onEdited(edited);
    }
  };

  hasInitialPricedItem() {
    return Boolean(this.props.editAddItem || this.editSubscriptionPlan);
  }

  getInitialPricedItem() {
    const { editAddItem, subscription, record } = this.props;

    const initialItem = cloneDeep(editAddItem || record);

    const item = {
      price: initialItem.price,
      quantity: initialItem.quantity,
      product_id: initialItem.product_id,
      variant_id: initialItem.variant_id,
      purchase_option: this.editSubscriptionPlan
        ? {
            type: 'subscription',
            plan_id: this.getEditSubscriptionPlanId(),
            billing_schedule: initialItem.billing_schedule,
            order_schedule: initialItem.order_schedule,
          }
        : editAddItem.purchase_option,
    };

    if (subscription) {
      item.recurring = initialItem.recurring;
    }

    return item;
  }

  setPricedItem(values, hasInitialPricedItem) {
    const { editProductChanged } = this.state;
    const { getItemPrice } = this.props;

    if (
      hasInitialPricedItem &&
      !editProductChanged &&
      isPrice(values.set_price)
    ) {
      return;
    }

    if (
      values.product &&
      getItemPrice &&
      (!this.state.pricedItem ||
        editProductChanged ||
        !isPrice(values.set_price))
    ) {
      clearTimeout(this.getItemTimer);

      this.setState({ priceLoading: true });

      this.getItemTimer = setTimeout(() => {
        getItemPrice({
          id: values.id,
          quantity: values.quantity,
          product_id: values.product.id,
          options: values.options,
          purchase_option: values.purchase_option,
        }).then((pricedItem) => {
          if (this.mounted) {
            this.setState({ priceLoading: false });

            if (!pricedItem?.errors) {
              this.setState({ pricedItem, priceOptionsRequired: false });
            } else if (pricedItem.errors['items.options']) {
              this.setState({ priceOptionsRequired: true });
            }
          }
        });
      }, 250);
    } else if (!values.product) {
      this.setState({ pricedItem: null, priceOptionsRequired: false });
    }
  }

  getCurrentProduct() {
    const { addItemProduct, editAddItem, record = {} } = this.props;
    const { editProductChanged } = this.state;

    return (
      addItemProduct ||
      (editAddItem && editAddItem.product) ||
      (this.editSubscriptionPlan && !editProductChanged && record.product)
    );
  }

  getEditSubscriptionPlanId() {
    const { editAddItem, record = {} } = this.props;

    if (!this.hasInitialPricedItem()) {
      return null;
    }

    const product = this.getCurrentProduct();
    const subscriptionPlans =
      get(product, 'purchase_options.subscription.plans') || [];
    const editRecord = editAddItem ? editAddItem.purchase_option : record;

    const editPlan = editRecord
      ? find(subscriptionPlans, (plan) => {
          const matched =
            plan.id === editRecord.plan_id &&
            get(plan, 'billing_schedule.interval') ===
              get(editRecord, 'billing_schedule.interval') &&
            get(plan, 'billing_schedule.interval_count') ===
              get(editRecord, 'billing_schedule.interval_count') &&
            (get(plan, 'billing_schedule.trial_days') || null) ===
              (get(editRecord, 'billing_schedule.trial_days') || null) &&
            (get(plan, 'billing_schedule.limit') || null) ===
              (get(editRecord, 'billing_schedule.limit') || null);

          return matched;
        })
      : null;

    return editPlan
      ? editPlan.id
      : !isEmpty(editRecord) &&
        (editRecord.type === 'subscription' ||
          !isEmpty(editRecord.billing_schedule)) &&
        subscriptionPlans.length > 0
      ? 'custom'
      : null;
  }

  isProductOnlyChanged() {
    const { addItemProduct, record = {}, editAddItem } = this.props;

    const initialProduct = editAddItem
      ? editAddItem.product
      : this.editSubscriptionPlan && record.product;

    if (!initialProduct) {
      return true;
    }

    return !addItemProduct || addItemProduct.id !== initialProduct.id;
  }

  isEditProductChanged(values) {
    const { addItemProduct, record = {}, editAddItem } = this.props;

    const initialProduct = editAddItem
      ? editAddItem.product
      : this.editSubscriptionPlan && record.product;

    if (!initialProduct) {
      return true;
    }

    const recordOptions = this.editSubscriptionPlan
      ? record.options
      : editAddItem && editAddItem.options;

    const editOptionsChanged =
      recordOptions &&
      values.options &&
      !isValueEqual(
        values.options,
        recordOptions.map((op) => ({
          id: op.id,
          value: op.value_id || op.value,
        })),
      );

    const editPurchaseOptionChanged =
      editAddItem && editAddItem.purchase_option
        ? get(editAddItem.purchase_option, 'type') !==
          get(values.purchase_option, 'type')
        : false;

    const editProductChanged =
      !addItemProduct ||
      addItemProduct.id !== initialProduct.id ||
      editOptionsChanged ||
      editPurchaseOptionChanged;

    if (editProductChanged) {
      return true;
    }

    if (
      !this.editSubscriptionPlan &&
      get(values.purchase_option, 'type') !== 'subscription'
    ) {
      return false;
    }

    const valuePlanId = get(values.purchase_option, 'plan_id');

    let valuePlan = find(
      get(
        addItemProduct || initialProduct,
        'purchase_options.subscription.plans',
      ),
      { id: valuePlanId },
    );

    // Deprecated subscription option
    if (!valuePlan) {
      valuePlan = getDeprecatedSubscriptionOptionPlan(
        this.getCurrentProduct(),
        values,
      );
    }

    const valuePurchaseOption = {
      type: get(values.purchase_option, 'type'),
      plan_id: get(valuePlan, 'id') || null,
      price: values.set_price,
      billing_schedule: {
        interval: get(
          valuePlan,
          'billing_schedule.interval',
          get(values.purchase_option, 'billing_schedule.interval'),
        ),
        interval_count:
          get(
            valuePlan,
            'billing_schedule.interval_count',
            get(values.purchase_option, 'billing_schedule.interval_count'),
          ) || 1,
        limit: get(
          valuePlan,
          'billing_schedule.limit',
          get(values.purchase_option, 'billing_schedule.limit'),
        ),
        ...((!this.editSubscriptionPlan || this.isDraftSubscription()) && {
          trial_days:
            get(
              valuePlan,
              'billing_schedule.trial_days',
              get(values.purchase_option, 'billing_schedule.trial_days'),
            ) || 0,
        }),
      },
      order_schedule: get(valuePlan, 'order_schedule') || null,
    };

    const editItem = editAddItem ? editAddItem : record;
    const editPurchaseOption = editAddItem
      ? editAddItem.purchase_option
      : record;

    const recordPurchaseOption = {
      type: editAddItem ? get(editPurchaseOption, 'type') : 'subscription',
      plan_id: this.getEditSubscriptionPlanId(),
      price: editItem.price,
      billing_schedule: {
        interval: get(editPurchaseOption, 'billing_schedule.interval'),
        interval_count:
          get(editPurchaseOption, 'billing_schedule.interval_count') || 1,
        limit: get(
          editPurchaseOption,
          'billing_schedule.limit',
          get(valuePurchaseOption, 'billing_interval.limit'),
        ),
        ...((!this.editSubscriptionPlan || this.isDraftSubscription()) && {
          trial_days: get(
            editPurchaseOption,
            'billing_schedule.trial_days',
            get(valuePurchaseOption, 'billing_interval.trial_days') || null,
          ),
        }),
      },
      order_schedule: get(editPurchaseOption, 'order_schedule')
        ? {
            interval: get(editPurchaseOption, 'order_schedule.interval'),
            interval_count:
              get(editPurchaseOption, 'order_schedule.interval_count') || 1,
            limit: get(editPurchaseOption, 'order_schedule.limit'),
          }
        : null,
    };

    const editPlanChanged =
      values.purchase_option &&
      !isValueEqual(valuePurchaseOption, recordPurchaseOption);

    return editProductChanged || editPlanChanged;
  }

  isSubscriptionProductProratable() {
    const { addItemProduct, record } = this.props;
    const { values, pricedItem } = this.state;

    if (!addItemProduct || !record.product) {
      return false;
    }

    if (addItemProduct.id !== record.product_id) {
      return true;
    }

    if (
      pricedItem &&
      (pricedItem.product_id !== record.product_id ||
        pricedItem.variant_id !== record.variant_id)
    ) {
      return true;
    }

    if (values.quantity !== undefined && values.quantity !== record.quantity) {
      return true;
    }

    if (
      (values.set_price || (pricedItem && pricedItem.price)) !== record.price
    ) {
      return true;
    }

    return false;
  }

  isDraftSubscription() {
    const { record } = this.props;

    return this.editSubscriptionPlan && (isEmpty(record) || record.draft);
  }

  onChangePurchaseOptionType = (type, _event, value) => {
    if (value && type) {
      this.setState((state) => ({
        values: {
          ...state.values,
          purchase_option: {
            ...state.values.purchase_option,
            type,
          },
        },
      }));
    }
  };

  onSubmit = (values) => {
    const {
      subscription,
      onSubmitAddItem,
      onSubmitEditPlan,
      onClickAddItem,
      onCloseEditPlan,
    } = this.props;

    const { editProductChanged } = this.state;

    let shouldSubmit = true;

    if (this.hasInitialPricedItem()) {
      const initialItem = this.getInitialPricedItem();

      const isValuesChanged =
        !isValueEqual(initialItem.price, values.set_price) ||
        !isValueEqual(initialItem.quantity, values.quantity) ||
        !isValueEqual(initialItem.options, values.options) ||
        (!isEmpty(subscription) &&
          !isValueEqual(initialItem.recurring, values.recurring));

      if (!editProductChanged && !isValuesChanged) {
        shouldSubmit = false;
      }
    }

    if (shouldSubmit) {
      return (onSubmitEditPlan || onSubmitAddItem)(values);
    }

    (onCloseEditPlan || onClickAddItem)();
  };

  render() {
    const {
      loading,
      lookup,
      currency,
      onClickAddItem,
      onClickEditPlan,
      onCloseEditPlan,
      onChangeAddProductLookup,
      onClickRemoveEditSingleItem,
      editAddItem,
      editAddIndex,
      subscription = false,
      record = {},
      isCart = false,
    } = this.props;

    const { values } = this.state;

    const product = this.getCurrentProduct();
    const isEditing =
      editAddItem || (this.editSubscriptionPlan && !this.isDraftSubscription());
    const isCustomItem = subscription && editAddItem && editAddItem.description;

    return (
      <Form
        onSubmit={this.onSubmit}
        onChange={this.onChange}
        autoFocus={!isEditing}
        autoFocusEmpty={!isEditing}
      >
        <Modal
          title={
            editAddItem
              ? 'Edit item'
              : this.editSubscriptionPlan
              ? record.product
                ? 'Edit plan'
                : 'Add plan'
              : 'Add item'
          }
          className="order-view-modal-add-item"
          actions={[
            {
              label: 'Save',
              type: 'submit',
            },
            {
              label: 'Cancel',
              type: 'secondary',
              className: 'button-cancel',
              onClick: onClickEditPlan || onClickAddItem,
            },
            editAddItem &&
              onClickRemoveEditSingleItem && {
                label: 'Remove item',
                type: 'secondary',
                className: 'left button-cancel',
                onClick: onClickRemoveEditSingleItem,
                'data-id': editAddItem.id,
                'data-index': editAddIndex,
                side: 'top',
              },
          ]}
          width={700}
          cancel={false}
          loading={loading}
          onClose={onCloseEditPlan || onClickAddItem}
        >
          <fieldset>
            {editAddItem && (
              <Field type="hidden" name="id" value={editAddItem.id} />
            )}

            {!isCustomItem ? (
              <LookupProduct
                name="product"
                query={this.productLookupQuery}
                queryFocus={true}
                lookup={lookup}
                required={true}
                onChange={onChangeAddProductLookup}
                defaultValue={product}
                currency={currency || CURRENCY_CODE}
                disabled={Boolean(editAddItem)}
              />
            ) : (
              <Field
                type="text"
                label="Description"
                name="description"
                required={true}
                autoFocus={true}
              />
            )}

            {(product || isCustomItem) && (
              <FadeIn>
                {product && (
                  <ProductOptionInputs
                    key={product.id}
                    product={product}
                    values={values}
                    defaultValue={
                      (editAddItem
                        ? { options: editAddItem.options }
                        : this.editSubscriptionPlan &&
                          !this.isProductOnlyChanged()
                        ? record.options || record.bundle_options
                          ? {
                              options: record.options,
                              bundle_options: record.bundle_options,
                            }
                          : undefined
                        : undefined) || undefined
                    }
                  />
                )}

                <PurchaseOptions
                  record={record}
                  product={product}
                  values={values}
                  isCart={isCart}
                  currency={currency || CURRENCY_CODE}
                  subscription={subscription}
                  subscriptionPlanId={this.getEditSubscriptionPlanId()}
                  editAddItem={editAddItem}
                  pricedItem={this.state.pricedItem}
                  priceLoading={this.state.priceLoading}
                  editProductChanged={this.state.editProductChanged}
                  priceOptionsRequired={this.state.priceOptionsRequired}
                  editSubscriptionPlan={this.editSubscriptionPlan}
                  onChangePurchaseOptionType={this.onChangePurchaseOptionType}
                />

                {subscription ? (
                  <div className="row order-view-add-item-toggle">
                    <Field
                      type="toggle"
                      label="Recurring"
                      name="recurring"
                      help="Recurring items will remain on a subscription after each invoice"
                      defaultChecked={editAddItem && editAddItem.recurring}
                    />
                  </div>
                ) : (
                  this.editSubscriptionPlan &&
                  record.id &&
                  record.draft !== true &&
                  this.isSubscriptionProductProratable() && (
                    <FadeIn>
                      <div className="row order-view-add-item-toggle">
                        <Field
                          type="toggle"
                          label="Prorated"
                          name="prorated"
                          defaultChecked={record.prorated}
                          help="Prorate the next invoice, accounting for unused time on the previous plan and remaining time on the new plan"
                          className="span4"
                        />
                      </div>
                    </FadeIn>
                  )
                )}
              </FadeIn>
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
