import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

const easeOut = 'cubic-bezier(0.22, 1, 0.36, 1)';

export default function ExpandPanel({
  children,
  initDisplayStyle = 'block',
  duration = 350,
  initWidth = 5,
  initHeight = 5,
  maxWidth = 200,
  maxHeight = 200,
  ...props
}) {
  const widthDuration = duration; //maxWidth > maxHeight ? duration : duration * (maxWidth / maxHeight);
  const heightDuration = duration; //maxHeight > maxWidth ? duration : duration * (maxHeight / maxWidth);
  const staticStyles = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };
  const transitionStyles = {
    defaultStyle: {
      display: 'none',
    },
    enterInitStyle: {
      ...staticStyles,
      display: initDisplayStyle,
      maxWidth: initWidth,
      maxHeight: initHeight,
    },
    enterStyle: {
      ...staticStyles,
      maxWidth: transit(maxWidth, widthDuration, easeOut),
      maxHeight: transit(maxHeight, heightDuration, easeOut),
    },
    leaveStyle: {
      ...staticStyles,
      maxWidth: transit(initWidth, widthDuration / 2, 'ease-in'),
      maxHeight: transit(initHeight, heightDuration / 2, 'ease-in'),
    },
    activeStyle: {
      //opacity: 1,
      //transform: 'translate(0, 0)',
    },
    active: true,
    transitionAppear: true,
  };

  return (
    <CSSTransition {...transitionStyles} {...props}>
      {children}
    </CSSTransition>
  );
}
