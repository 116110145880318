export default {
  identify(...args) {
    try {
      window.analytics && window.analytics.identify(...args);
    } catch (err) {
      console.error('identifySegment', err);
    }
  },

  alias(...args) {
    try {
      window.analytics && window.analytics.alias(...args);
    } catch (err) {
      console.error('aliasSegment', err);
    }
  },

  track(name, traits, integrations) {
    try {
      const options = {};
      if (integrations) {
        options.integrations = integrations;
      }
      window.analytics && window.analytics.track(name, traits, options);
    } catch (err) {
      console.error('Segment error', err);
    }
  },

  trackPage(name, traits) {
    try {
      const pageName = String(name).trim().toLowerCase();
      // Uses Intercom instead of segment to avoid extraneous events
      // Note: Intercom may not be loaded by this point for the first event triggered
      window.Intercom &&
        window.Intercom('trackEvent', `Viewed ${pageName}`, traits);
    } catch (err) {
      console.error('Segment error', err);
    }
  },

  initPage(key, page) {
    var analytics = (window.analytics = window.analytics || []);

    if (!analytics.initialize) {
      if (analytics.invoked) {
        // window.console && console.error && console.error('Segment snippet included twice.');
      } else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
        ];
        analytics.factory = function (t) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t, e) {
          var n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = !0;
          n.src =
            ('https:' === document.location.protocol ? 'https://' : 'http://') +
            'cdn.segment.com/analytics.js/v1/' +
            t +
            '/analytics.min.js';
          var o = document.getElementsByTagName('script')[0];
          o.parentNode.insertBefore(n, o);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = '4.1.0';
        analytics.load(key);
      }
    }

    analytics.page(page);
  },
};
