import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import LoginLayoutSinglePage from 'components/login/layout-single';
import SignupPage from 'components/signup/signup';
import actions from 'actions';
import segment from 'services/segment';

const mapStateToProps = (state) => ({
  client: state.client,
  flash: state.flash,
  session: state.session,
  lead: state.client.lead,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLead: (id) => {
    return dispatch(actions.client.fetchLead(id));
  },

  createLead: (data) => {
    dispatch(actions.flash.clear());
    return dispatch(actions.client.createLead(data));
  },

  validateClient: (data) => {
    return dispatch(actions.client.validate(data));
  },

  flashSuccess: (message) => {
    dispatch(actions.flash.success(message));
  },

  flashError: (message) => {
    dispatch(actions.flash.error(message));
  },
});

export class Signup extends React.PureComponent {
  static contextTypes = {
    setTitle: PropTypes.func.isRequired,
  };

  static onEnter() {
    window.scrollTo(0, 0);
  }

  static onChange() {
    window.scrollTo(0, 0);
  }

  state = {};
  mounted = false;
  validateTimer = null;
  validateLoadingTimer = null;

  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded: false,
      errors: {},
      valid: false,
      validating: false,
      waitlist: false,
      email: null,
      name: null,
      onSubmitForm: this.onSubmitForm.bind(this),
      onChangeStoreName: this.onChangeStoreName.bind(this),
      onChangeEmail: this.onChangeEmail.bind(this),
    };
  }

  async componentWillMount() {
    const {
      location: {
        query: { email = null, source, lead },
      },
      fetchLead,
    } = this.props;
    const title = 'Start your free trial 🚀';

    this.context.setTitle(title);
    this.mounted = true;

    if (lead) {
      fetchLead(lead);
    }

    this.setState({ loaded: true, email });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async onChangeEmail() {
    if (this.state.errors && this.state.errors.email) {
      // clear email error
      const { errors } = this.state;
      this.setState({
        errors: {
          ...errors,
          email: undefined,
        },
      });
    }
  }

  async onChangeStoreName(event, value) {
    const { flashError, validateClient } = this.props;
    const { errors } = this.state;

    clearTimeout(this.validateTimer);
    clearTimeout(this.validateLoadingTimer);

    this.setState({
      name: value,
    });

    this.setState({
      validating: false,
      valid: false,
      errors: {
        ...errors,
        store_name: undefined,
      },
    });

    if (!value) {
      return;
    }

    this.validateTimer = setTimeout(async () => {
      if (!this.mounted) {
        return;
      }

      this.validateLoadingTimer = setTimeout(() => {
        this.mounted && this.setState({ validating: true });
      }, 1000);

      const result = await validateClient({
        store_name: value,
      });

      clearTimeout(this.validateLoadingTimer);

      this.setState({ validating: false });

      if (!result) {
        flashError('Something went wrong!');
      }
      if (result.error) {
        flashError(result.error);
        return;
      }
      const { errors } = this.state;

      if (result.errors) {
        this.setState({
          errors: {
            ...errors,
            ...result.errors,
          },
        });
        return;
      }
      this.setState({
        errors: {
          ...errors,
          store_name: undefined,
        },
      });
    }, 500);
  }

  async onSubmitForm(values) {
    const { router, flashError, lead, createLead, location } = this.props;
    const { search } = location;

    const result = await createLead({
      ...values,
      name: this.state.name || values.name,
      lead_id: lead?.id,
      redirect: location.query.redirect || lead?.redirect,
    });

    if (!result) {
      return;
    }
    if (result.error) {
      flashError(result.error.message || result.error || result.errors);
      return false;
    }
    if (result.errors) {
      if (result.errors?.email?.message) {
        this.setState({ errors: result.errors });
      } else {
        flashError('An unknown error occurred');
      }
      return false;
    }

    const urlParams = new URLSearchParams(window.location.search);

    await segment.track('User lead created', {
      email: values.email,
      client_id: values.store_name,
      client_name: result.name,
      ref: urlParams.get('ref'),
      email_optin: values.marketing,
    });

    router.originalPush(`/signup/verify-email/${result.id}${search}`);
  }

  render() {
    const { loaded } = this.state;

    if (!loaded) {
      return null;
    }

    return (
      <LoginLayoutSinglePage {...this.props} {...this.state}>
        <SignupPage {...this.props} {...this.state} />
      </LoginLayoutSinglePage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
