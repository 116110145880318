import account, { reducer as accountReducer } from './account';
import activity, { reducer as activityReducer } from './activity';
import apps, { reducer as appsReducer } from './apps';
import attributes, { reducer as attributesReducer } from './attributes';
import categories, { reducer as categoriesReducer } from './categories';
import client, { reducer as clientReducer } from './client';
import content, { reducer as contentReducer } from './content';
import dashboard, { reducer as dashboardReducer } from './dashboard';
import data, { reducer as dataReducer } from './data';
import files, { reducer as filesReducer } from './files';
import flash, { reducer as flashReducer } from './flash';
import lookup, { reducer as lookupReducer } from './lookup';
import modal, { reducer as modalReducer } from './modal';
import notifications, {
  reducer as notificationsReducer,
} from './notifications';
import nav, { reducer as navReducer } from './nav';
import reports, { reducer as reportsReducer } from './reports';
import oauth, { reducer as oauthReducer } from './oauth';
import orders, { reducer as ordersReducer } from './orders';
import session, { reducer as sessionReducer } from './session';
import settings, { reducer as settingsReducer } from './settings';
import storefronts, { reducer as storefrontsReducer } from './storefronts';
import themes, { reducer as themesReducer } from './themes';
import user, { reducer as userReducer } from './user';
import webhooks, { reducer as webhooksReducer } from './webhooks';
import console, { reducer as consoleReducer } from './console';

// Editor reducers
import themeSettings from '../../../editor/src/reducers/themeSettings';
import themeConfig from '../../../editor/src/reducers/themeConfig';
import statusItems from 'editor/src/reducers/statusItems';
import git from 'editor/src/reducers/git';
import editor from 'editor/src/reducers/editor';

export const actions = {
  account,
  activity,
  apps,
  attributes,
  categories,
  client,
  content,
  dashboard,
  data,
  files,
  flash,
  lookup,
  modal,
  notifications,
  nav,
  oauth,
  orders,
  reports,
  session,
  settings,
  storefronts,
  themes,
  user,
  webhooks,
  console,
};

export const reducers = {
  account: accountReducer,
  apps: appsReducer,
  client: clientReducer,
  nav: navReducer,
  oauth: oauthReducer,
  session: sessionReducer,
  settings: settingsReducer,
  storefronts: storefrontsReducer,
  themes: themesReducer,
  user: userReducer,

  // State to reset when environment changes
  activity: withReset(activityReducer, activity),
  attributes: withReset(attributesReducer, attributes),
  categories: withReset(categoriesReducer, categories),
  content: withReset(contentReducer, content),
  dashboard: withReset(dashboardReducer, dashboard),
  data: withReset(dataReducer, data),
  files: withReset(filesReducer, files),
  flash: withReset(flashReducer, flash),
  lookup: withReset(lookupReducer, lookup),
  modal: withReset(modalReducer, modal),
  notifications: withReset(notificationsReducer, notifications),
  orders: withReset(ordersReducer, orders),
  reports: withReset(reportsReducer, reports),
  settings: withReset(settingsReducer, settings),
  themes: withReset(themesReducer, themes),
  webhooks: withReset(webhooksReducer, webhooks),
  console: withReset(consoleReducer, console),

  // Editor reducers
  themeSettings,
  themeConfig,
  statusItems,
  git,
  editor,
};

function withReset(reducer, { initialState }) {
  return (state, action, ...args) => {
    if (action.type === 'RESET' && initialState) {
      return initialState;
    }
    return reducer(state, action, ...args);
  };
}

export function resetInitialState() {
  return {
    type: 'RESET',
  };
}

export default actions;
