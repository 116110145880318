import api from 'services/api';

export const THEMES_FETCH = 'themes/fetch';
export const THEMES_CREATE = 'themes/create';
export const THEMES_UPDATE = 'themes/update';
export const THEMES_DELETE = 'themes/delete';
export const THEMES_LOADING = 'themes/loading';

export default {
  fetch() {
    return {
      type: THEMES_FETCH,
      payload: api.get('/themes'),
    };
  },

  create(data) {
    return {
      type: THEMES_CREATE,
      payload: api.post('/themes', data),
    };
  },

  update(id, data) {
    return {
      type: THEMES_UPDATE,
      payload: api.put(`/themes/${id}`, data),
    };
  },

  delete(id, confirm_delete_key = null) {
    return {
      type: THEMES_DELETE,
      payload: api.delete(`/themes/${id}`, { confirm_delete_key }),
    };
  },
};

export const initialState = {
  results: [],
  count: 0,
  pages: {},
  loading: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case THEMES_FETCH:
      return {
        ...state,
        ...action.payload,
      };

    case THEMES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}
