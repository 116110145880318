import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

export default function FadeOut({ duration = 250, children, ...props }) {
  const transitionStyles = {
    defaultStyle: {
      opacity: 1,
    },
    enterStyle: {
      opacity: transit(0, duration, 'ease-in'),
    },
    leaveStyle: {
      opacity: 1,
    },
    activeStyle: {
      display: 'none',
    },
    transitionAppear: true,
    active: true,
  };
  return (
    <CSSTransition {...transitionStyles} {...props}>
      {children}
    </CSSTransition>
  );
}
