import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'components/icon';
import './alert.scss';

Alert.propTypes = {
  type: PropTypes.string,
  appearance: PropTypes.oneOf(['opaque', 'normal']),
};

export default function Alert({
  type,
  appearance,
  children,
  className,
  ...props
}) {
  const isOpaque = appearance === 'opaque';

  return (
    <div
      {...props}
      className={`alert alert-${type || 'info'} ${appearance || ''} ${
        className || ''
      }`}
    >
      {type === 'warning' && (
        <Icon
          fa="exclamation-triangle"
          faType={isOpaque ? 'solid' : 'regular'}
          className="alert-message-icon"
        />
      )}
      {children}
    </div>
  );
}
