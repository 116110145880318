import React from 'react';
import pt from 'prop-types';

import classNames from 'classnames';

import { formatPaymentBrand, isExpired } from 'utils/order';

import IconPayment from 'components/icon/payment';
import Status from 'components/status/status';

function PaymentCard({ className, card, isDefault }) {
  const expired = isExpired(card.exp_month, card.exp_year);

  return (
    <div className={classNames('collection-cards-item', className)}>
      <IconPayment method="card" card={card} />
      <div className="collection-cards-container">
        <div className="collection-cards-ending">
          {`${formatPaymentBrand(card.brand)} ending in ${card.last4}`}
        </div>
        <div
          className={classNames('collection-cards-exp muted', {
            expired,
          })}
        >
          {expired
            ? `(Expired on ${card.exp_month}/${card.exp_year})`
            : `(Expires ${card.exp_month}/${card.exp_year})`}
        </div>
      </div>
      {isDefault && (
        <Status size="sm" type="muted" dot={false}>
          Default
        </Status>
      )}
      {card.test && (
        <Status size="sm" type="warning">
          Test
        </Status>
      )}
    </div>
  );
}

PaymentCard.propTypes = {
  card: pt.shape({
    exp_month: pt.number.isRequired,
    exp_year: pt.number.isRequired,
    brand: pt.string.isRequired,
    last4: pt.string.isRequired,
    test: pt.bool,
    billing: pt.object,
  }).isRequired,
  className: pt.string,
  isDefault: pt.bool,
};

export default React.memo(PaymentCard);
