import React, { Fragment } from 'react';
import { get, each } from 'lodash';
import ordinal from 'ordinal';
import { formatDate, arrayToObject } from 'utils';
import { accountName } from 'utils/account';
import { subscriptionReportPlanDescription } from 'utils/subscription';
import PaymentIcon from 'components/icon/payment';

export function getReportFields(timezone, settings) {
  const paymentMethods = arrayToObject(get(settings, 'payments.methods'));
  const timeFields = {
    label: 'Time',
    maxChecked: 1,
    minChecked: 1,
    fields: {
      day: {
        label: 'Day',
        originalPath: 'date_created',
        path: ['year', 'month', 'dayOfMonth'],
        func: ({ year, month, dayOfMonth } = {}) =>
          typeof year === 'number' &&
          typeof month === 'number' &&
          typeof dayOfMonth === 'number' &&
          formatDate([year, month - 1, dayOfMonth], 'monthDayShort', true),
        group: {
          dayOfMonth: { $dayOfMonth: { date: '$date_created', timezone } },
          month: { $month: { date: '$date_created', timezone } },
          year: { $year: { date: '$date_created', timezone } },
        },
      },
      month: {
        label: 'Month',
        originalPath: 'date_created',
        path: ['year', 'month'],
        func: ({ year, month } = {}) =>
          typeof year === 'number' &&
          typeof month === 'number' &&
          formatDate([year, month - 1], 'month', true),
        group: {
          month: { $month: { date: '$date_created', timezone } },
          year: { $year: { date: '$date_created', timezone } },
        },
      },
      year: {
        label: 'Year',
        originalPath: 'date_created',
        path: 'year',
        func: ({ year } = {}) =>
          typeof year === 'number' && formatDate([year, 1], 'year', true),
        group: {
          year: { $year: { date: '$date_created' } },
        },
      },
      week: {
        label: 'Week',
        originalPath: 'date_created',
        path: ['year', 'week'],
        func: (report) => ordinal(report.week),
        group: {
          week: { $isoWeek: { date: '$date_created', timezone } },
          year: { $year: { date: '$date_created', timezone } },
        },
      },
      hour: {
        label: 'Hour',
        originalPath: 'date_created',
        path: ['year', 'month', 'dayOfMonth', 'hour'],
        func: ({ year, month, dayOfMonth, hour } = {}) =>
          typeof year === 'number' &&
          typeof month === 'number' &&
          typeof dayOfMonth === 'number' &&
          typeof hour === 'number' &&
          formatDate([year, month - 1, dayOfMonth, hour], 'hour', true),
        group: {
          hour: { $hour: { date: '$date_created', timezone } },
          dayOfMonth: { $dayOfMonth: { date: '$date_created', timezone } },
          month: { $month: { date: '$date_created', timezone } },
          year: { $year: { date: '$date_created', timezone } },
        },
      },
    },
  };

  const fields = {
    sales: {
      time: timeFields,
      customer: {
        label: 'Customer',
        fields: {
          id: {
            label: 'Customer ID',
            path: 'account_id',
            type: 'link',
            url: '/customers/{account_id}',
            func: (report) => report.account_id,
            group: {
              account_id: '$account_id',
            },
          },
          email: {
            label: 'Customer email',
            path: 'account.email',
            type: 'link',
            url: '/customers/{account_id}',
            func: (report) => report.account.email,
            group: {
              account_id: '$account_id',
              account: { email: '$account.email' },
            },
          },
          name: {
            label: 'Customer name',
            path: 'account.name',
            type: 'link',
            url: '/customers/{account_id}',
            func: (report) => report.account && accountName(report.account),
            group: {
              account_id: '$account_id',
              account: { name: '$account.name' },
            },
          },
          group: {
            label: 'Customer group',
            path: 'account.group',
            func: (report) => report.account.group,
            group: {
              account: {
                account_id: '$account_id',
                group: '$account.group',
              },
            },
          },
          phone: {
            label: 'Customer phone',
            path: 'account.phone',
            func: (report) => report.account.phone,
            group: {
              account_id: '$account_id',
              account: { phone: '$account.phone' },
            },
          },
        },
      },
      order: {
        label: 'Order',
        fields: {
          count: {
            label: 'Orders',
            path: 'orders',
          },
          id: {
            label: 'Order ID',
            path: 'id',
            type: 'link',
            url: '/orders/{id}',
            group: {
              id: '$_id',
            },
          },
          number: {
            label: 'Order number',
            path: 'number',
            type: 'link',
            url: '/orders/{number}',
            group: {
              number: '$number',
            },
          },
          avg_price: {
            label: 'Average price',
            path: 'avg_price',
            type: 'currency',
            rounded: true,
          },
          avg_total: {
            label: 'Average order',
            path: 'avg_total',
            type: 'currency',
            rounded: true,
          },
          canceled_orders: {
            label: 'Canceled',
            path: 'canceled_orders',
            type: 'number',
          },
          fulfilled_orders: {
            label: 'Fulfilled',
            path: 'fulfilled_orders',
            type: 'number',
          },
          returned_orders: {
            label: 'Returned',
            path: 'returned_orders',
            type: 'number',
          },
          discount: {
            label: 'Item Discounts',
            path: 'discount_total',
            type: 'currency',
            rounded: true,
          },
          coupon_code: {
            label: 'Coupon code',
            path: 'coupon_code',
            group: {
              coupon_code: '$coupon_code',
            },
            // conditions: {
            //   $and: [{ coupon_id: { $ne: null } }],
            // },
          },
          item_taxes: {
            label: 'Item Taxes',
            path: 'item_tax',
            type: 'currency',
            rounded: true,
          },
          order_taxes: {
            label: 'Order Taxes',
            path: 'tax_total',
            type: 'currency',
            rounded: true,
          },
          shipping: {
            label: 'Shipping',
            path: 'shipment_total',
            type: 'currency',
            rounded: true,
          },
          gross_sales: {
            label: 'Gross sales',
            path: 'gross_total',
            type: 'currency',
            rounded: true,
          },
          net_sales: {
            label: 'Net sales',
            path: 'net_total',
            type: 'currency',
            rounded: true,
          },
          return_item_total: {
            label: 'Returned total',
            path: 'return_item_total',
            type: 'currency',
            rounded: true,
          },
          payment_total: {
            label: 'Payment total',
            path: 'payment_total',
            type: 'currency',
            rounded: true,
          },
          refund_total: {
            label: 'Refund total',
            path: 'refund_total',
            type: 'currency',
            rounded: true,
          },
          total_sales: {
            label: 'Total sales',
            path: 'grand_total',
            type: 'currency',
            rounded: true,
          },
          canceled_sales: {
            label: 'Canceled sales',
            path: 'canceled_total',
            type: 'currency',
            rounded: true,
          },
          fulfillment_status: {
            label: 'Fulfillment status',
            path: 'fulfillment_status',
            group: {
              fulfillment_status: '$fulfillment_status',
            },
          },
          payment_status: {
            label: 'Payment status',
            path: 'payment_status',
            group: {
              payment_status: '$payment_status',
            },
          },
          payment_method: {
            label: 'Payment method',
            group: {
              billing: { method: '$billing.method', card: '$billing.card' },
            },
            component: (report) => {
              const { billing = {} } = report;
              return (
                billing.method && (
                  <Fragment>
                    <PaymentIcon {...billing} />{' '}
                    {get(
                      paymentMethods[billing.method],
                      'name',
                      billing.method,
                    )}
                  </Fragment>
                )
              );
            },
            func: (report) => {
              const { billing: { method } = {} } = report;
              return get(paymentMethods[method], 'name', method);
            },
          },
          currency: {
            label: 'Currency',
            path: 'currency',
            group: {
              currency: '$currency',
            },
          },
        },
      },
      promotion: {
        label: 'Promotion',
        fields: {
          name: {
            label: 'Promotion name',
            path: 'promotion.name',
            type: 'link',
            url: '/promotions/{promotion.id}',
            group: {
              promotion: { id: '$promotion._id', name: '$promotion.name' },
            },
          },
        },
      },
      product: {
        label: 'Product',
        fields: {
          id: {
            label: 'Product ID',
            path: 'product.id',
            type: 'link',
            url: '/products/{product.id}',
            group: {
              product: { id: '$product._id' },
            },
          },
          sku: {
            label: 'Product SKU',
            type: 'link',
            url: '/products/{product.id}',
            group: {
              product: { id: '$product._id', sku: '$product.sku' },
              variant: { sku: '$variant.sku' },
            },
            func: (report) => report.variant.sku || report.product.sku,
          },
          price: {
            label: 'Product list price',
            path: 'product.price',
            type: 'currency',
            group: {
              product: { price: '$product.price' },
            },
          },
          name: {
            label: 'Product name',
            path: 'product.name',
            type: 'link',
            url: '/products/{product.id}',
            group: {
              product: { id: '$product._id', name: '$product.name' },
            },
          },
          type: {
            label: 'Product type',
            path: 'product.type',
            group: {
              product: { type: '$product.type' },
            },
          },
          quantity: {
            label: 'Quantity',
            path: 'item_quantity',
            type: 'number',
          },
          quantity_canceled: {
            label: 'Canceled quantity',
            path: 'item_quantity_canceled',
            type: 'number',
          },
          quantity_fulfilled: {
            label: 'Fulfilled quantity',
            path: 'item_quantity_delivered',
            type: 'number',
          },
          quantity_returned: {
            label: 'Returned quantity',
            path: 'item_quantity_returned',
            type: 'number',
          },
          item_quantity_gross: {
            label: 'Gross quantity',
            path: 'item_quantity_gross',
            type: 'number',
          },
          quantity_net: {
            label: 'Net quantity',
            path: 'item_quantity_net',
            type: 'number',
          },
        },
      },
      billing: {
        label: 'Billing',
        fields: {
          city: {
            label: 'Billing city',
            path: 'billing.city',
            group: { billing: { city: '$billing.city' } },
          },
          company: {
            label: 'Billing company',
            path: 'billing.company',
            group: { billing: { company: '$billing.company' } },
          },
          country: {
            label: 'Billing country',
            path: 'billing.country',
            group: { billing: { country: '$billing.country' } },
          },
          state: {
            label: 'Billing state',
            path: 'billing.state',
            group: { billing: { state: '$billing.state' } },
          },
          zip: {
            label: 'Billing zip',
            path: 'billing.zip',
            group: { billing: { zip: '$billing.zip' } },
          },
        },
      },
      shipping: {
        label: 'Shipping',
        fields: {
          city: {
            label: 'Shipping city',
            path: 'shipping.city',
            group: { shipping: { city: '$shipping.city' } },
          },
          country: {
            label: 'Shipping country',
            path: 'shipping.country',
            group: { shipping: { country: '$shipping.country' } },
          },
          state: {
            label: 'Shipping state',
            path: 'shipping.state',
            group: { shipping: { state: '$shipping.state' } },
          },
          zip: {
            label: 'Shipping zip',
            path: 'shipping.zip',
            group: { shipping: { zip: '$shipping.zip' } },
          },
        },
      },
      /*
      Intentionally hiding admin fields for now
      admin: {
        label: 'Admin',
        fields: {
          id: {
            label: 'Admin ID',
            path: 'admin.id',
            group: { admin: { id: '$admin.id' } },
          },
          name: {
            label: 'Admin name',
            path: 'admin.name',
            group: { admin: { id: '$admin.id' } },
          },
        },
      },
      */
    },
    subscriptions: {
      time: timeFields,
      subscription: {
        label: 'Subscription',
        fields: {
          plan: {
            label: 'Plan',
            path: ['billing_schedule', 'price'],
            type: 'link',
            url: '/subscriptions/{id}',
            func_requires_currency: true,
            func: (report, _format, currency) =>
              subscriptionReportPlanDescription(report, currency),
            group: {
              plan: {
                id: '$plan_id',
                interval: '$billing_schedule.interval',
                interval_count: '$billing_schedule.interval_count',
                price: '$price',
              },
            },
          },
          new_subscriptions: {
            label: 'New',
            path: 'new_subscriptions',
            type: 'number',
          },
          active: {
            label: 'Active',
            path: 'active',
            type: 'number',
          },
          paused: {
            label: 'Paused',
            path: 'paused',
            type: 'number',
          },
          canceled: {
            label: 'Canceled',
            path: 'canceled',
            type: 'number',
          },
          trials_expired: {
            label: 'Trials Expired',
            path: 'trials_expired',
            type: 'number',
          },
          trials_converted: {
            label: 'Trials Converted',
            path: 'trials_converted',
            type: 'number',
          },
          trial_conversion_rate: {
            label: 'Conversion Rate (%)',
            path: ['trials_expired', 'trials_converted'],
            func: (report) => {
              const { trials_expired, trials_converted } = report;
              let rate = (trials_converted / trials_expired) * 100;

              // handle 0/0
              if (Number.isNaN(rate)) {
                return '0%';
              }

              rate = rate.toFixed(0);

              return `${rate}%`;
            },
          },
          discounts: {
            label: 'Item Discounts',
            path: 'total_item_discounts',
            type: 'currency',
            rounded: true,
          },
          taxes: {
            label: 'Item Taxes',
            path: 'total_item_tax',
            type: 'currency',
            rounded: true,
          },
          total_taxes: {
            label: 'Total Taxes',
            path: 'total_tax',
            type: 'currency',
            rounded: true,
          },
          shipping: {
            label: 'Shipping',
            path: 'total_shipping',
            type: 'currency',
            rounded: true,
          },
          total_sales: {
            label: 'Total sales',
            path: 'total_sales',
            type: 'currency',
            rounded: true,
          },
          gross_sales: {
            label: 'Gross sales',
            path: 'gross_sales',
            type: 'currency',
            rounded: true,
          },
          return_item_total: {
            label: 'Returned total',
            path: 'return_item_total',
            type: 'currency',
            rounded: true,
          },
          net_sales: {
            label: 'Net sales',
            path: 'net_sales',
            type: 'currency',
            rounded: true,
          },
          currency: {
            label: 'Currency',
            path: 'currency',
            group: {
              currency: '$currency',
            },
          },
        },
      },
      product: {
        label: 'Product',
        fields: {
          name: {
            label: 'Product name',
            path: 'product.name',
            type: 'link',
            url: '/products/{product.id}',
            group: {
              product: { id: '$product._id', name: '$product.name' },
            },
          },
        },
      },
      customer: {
        label: 'Customer',
        fields: {
          id: {
            label: 'Customer ID',
            path: 'account.id',
            type: 'link',
            url: '/customers/{account.id}',
            group: {
              account: { id: '$account._id' },
            },
          },
          email: {
            label: 'Customer email',
            path: 'account.email',
            type: 'link',
            url: '/customers/{account.id}',
            group: {
              account: { id: '$account._id', email: '$account.email' },
            },
          },
          name: {
            label: 'Customer name',
            path: 'account.name',
            type: 'link',
            url: '/customers/{account.id}',
            func: (report) => report.account && accountName(report.account),
            group: {
              account: { id: '$account._id', name: '$account.name' },
            },
          },
          group: {
            label: 'Customer group',
            path: 'account.group',
            group: {
              account: { group: '$account.group' },
            },
          },
          phone: {
            label: 'Customer phone',
            path: 'account.phone',
            group: {
              account: { phone: '$account.phone' },
            },
          },
        },
      },
      billing: {
        label: 'Billing',
        fields: {
          billing_city: {
            label: 'Billing city',
            path: 'billing.city',
            group: {
              billing: { city: '$billing.city' },
            },
          },
          billing_company: {
            label: 'Billing company',
            path: 'billing.company',
            group: {
              billing: { company: '$billing.company' },
            },
          },
          billing_country: {
            label: 'Billing country',
            path: 'billing.country',
            group: {
              billing: { country: '$billing.country' },
            },
          },
          billing_state: {
            label: 'Billing state',
            path: 'billing.state',
            group: {
              billing: { state: '$billing.state' },
            },
          },
          billing_zip: {
            label: 'Billing zip',
            path: 'billing.zip',
            group: {
              billing: { zip: '$billing.zip' },
            },
          },
        },
      },
      shipping: {
        label: 'Shipping',
        fields: {
          shipping_city: {
            label: 'Shipping city',
            path: 'shipping.city',
            group: {
              shipping: { city: '$shipping.city' },
            },
          },
          shipping_country: {
            label: 'Shipping country',
            path: 'shipping.country',
            group: {
              shipping: { country: '$shipping.country' },
            },
          },
          shipping_state: {
            label: 'Shipping state',
            path: 'shipping.state',
            group: {
              shipping: { state: '$shipping.state' },
            },
          },
          shipping_zip: {
            label: 'Shipping zip',
            path: 'shipping.zip',
            group: {
              shipping: { zip: '$shipping.zip' },
            },
          },
        },
      },
    },
    customers: {
      time: timeFields,
      customer: {
        label: 'Customer',
        fields: {
          email: {
            label: 'Email',
            path: 'email',
            type: 'link',
            url: '/customers/{email}',
            func: (report) => report.email,
            group: {
              email: '$email',
            },
          },
          name: {
            label: 'Name',
            path: 'name',
            func: (report) => report.name,
            group: {
              name: '$name',
            },
          },
          group: {
            label: 'Group',
            path: 'group',
            group: {
              group: '$group',
            },
          },
          count: {
            label: 'Count',
            path: 'customers',
            type: 'number',
          },
        },
      },
      attributes: {
        label: 'Attributes',
        fields: {
          first_time: {
            label: 'First-time',
            path: 'first_time',
            type: 'number',
          },
          returning: {
            label: 'Returning',
            path: 'returning',
            type: 'number',
          },
        },
      },
      location: {
        label: 'Location',
        fields: {
          city: {
            label: 'City',
            path: 'shipping.city',
            group: {
              shipping: { city: '$shipping.city' },
            },
          },
          country: {
            label: 'Country',
            path: 'shipping.country',
            group: {
              shipping: { country: '$shipping.country' },
            },
          },
          state: {
            label: 'State',
            path: 'shipping.state',
            group: {
              shipping: { state: '$shipping.state' },
            },
          },
        },
      },
      order: {
        label: 'Order',
        fields: {
          count: {
            label: 'Order count',
            path: 'order_count',
            type: 'number',
          },
          lifetime: {
            label: 'Lifetime value',
            path: 'order_value',
            type: 'currency',
            rounded: true,
          },
        },
      },
      // TODO: fix these
      // first_order: {
      //   label: 'First order',
      //   maxChecked: 1,
      //   fields: {
      //     day: {
      //       label: 'First order day',
      //       path: 'date_first_order',
      //       func: (report) => formatDate(report.date_first_order, 'monthDayShort'),
      //     },
      //     month: {
      //       label: 'First order month',
      //       path: 'date_first_order',
      //       func: (report) => formatDate(report.date_first_order, 'month'),
      //     },
      //     year: {
      //       label: 'First order year',
      //       path: 'date_first_order',
      //       func: (report) => formatDate(report.date_first_order, 'year'),
      //     },
      //     week: {
      //       label: 'First order week',
      //       path: 'date_first_order',
      //       func: (report) => formatDate(report.date_first_order, 'week'),
      //     },
      //   },
      // },
      // last_order: {
      //   label: 'Last order',
      //   maxChecked: 1,
      //   fields: {
      //     day: {
      //       label: 'Last order day',
      //       path: 'date_last_order',
      //       func: (report) => formatDate(report.date_last_order, 'monthDayShort'),
      //     },
      //     month: {
      //       label: 'Last order month',
      //       path: 'date_last_order',
      //       func: (report) => formatDate(report.date_last_order, 'month'),
      //     },
      //     year: {
      //       label: 'Last order year',
      //       path: 'date_last_order',
      //       func: (report) => formatDate(report.date_last_order, 'year'),
      //     },
      //     week: {
      //       label: 'Last order week',
      //       path: 'date_last_order',
      //       func: (report) => formatDate(report.date_last_order, 'week'),
      //     },
      //   },
      // },
    },
    taxes: {
      shipping: {
        label: 'Shipping',
        fields: {
          country: {
            label: 'Country',
            path: 'shipping.country',
            group: {
              shipping: { country: '$shipping.country' },
            },
          },
          state: {
            label: 'State',
            path: 'shipping.state',
            group: {
              shipping: { state: '$shipping.state' },
            },
          },
        },
      },
      info: {
        label: 'Tax',
        fields: {
          name: {
            label: 'Name',
            path: 'taxes.name',
            group: {
              taxes: { name: '$taxes.name' },
            },
          },
          rate: {
            label: 'Rate',
            path: 'taxes.rate',
            func: (report) =>
              report.taxes.rate ? `${report.taxes.rate}%` : '—',
            type: 'number',
            group: {
              taxes: { rate: '$taxes.rate' },
            },
          },
          amount: {
            label: 'Amount',
            path: 'tax_total',
            type: 'currency',
            rounded: true,
          },
          currency: {
            label: 'Currency',
            path: 'currency',
            group: {
              currency: '$currency',
            },
          },
        },
      },
      time: timeFields,
    },
    payments: {
      payments: {
        label: 'Payments',
        fields: {
          method: {
            label: 'Payment method',
            path: 'method',
            func: (report) =>
              get(paymentMethods[report.method], 'name', report.method),
            group: {
              method: '$method',
            },
          },
          card_brand: {
            label: 'Credit card',
            path: 'card.brand',
            func: (report) => `${report.card.brand || ''}`,
            group: {
              card: { brand: '$card.brand' },
            },
          },
          count: {
            label: 'Transactions',
            path: 'payments',
            type: 'number',
          },
          gross_payments: {
            label: 'Gross payments',
            path: 'charge_total',
            type: 'currency',
            rounded: true,
          },
          refunds: {
            label: 'Refunds',
            path: 'refund_total',
            type: 'currency',
            rounded: true,
          },
          net_payments: {
            label: 'Net payments',
            path: 'net_total',
            type: 'currency',
            rounded: true,
          },
          currency: {
            label: 'Currency',
            path: 'currency',
            group: {
              currency: '$currency',
            },
          },
        },
      },
      time: timeFields,
    },
  };

  each(fields, (report) => {
    each(report, (group, groupId) => {
      each(group.fields, (field, fieldId) => {
        field.id = `${groupId}.${fieldId}`;
      });
    });
  });

  return fields;
}
