import React from 'react';
import { Form, Field, Fieldtable } from 'components/form';
import View from 'components/view';
import Tooltip from 'components/tooltip';
import SectionHeader from 'components/section-header';
import { FadeIn } from 'components/transitions';
import { snakeCase } from 'utils';
import './settings.scss';

export default class CustomerSettings extends React.PureComponent {
  onSubmitModal = (values) => {
    if (!values.id) {
      values.id = snakeCase(values.name);
    }
  };

  renderGroupHeading = () => {
    return [
      <th key="1">Group Name</th>,
      <th key="2">
        Group ID
        <Tooltip message="Sometimes used by apps to assign customer groups">
          <span className="help icon icon-info">&nbsp;</span>
        </Tooltip>
      </th>,
    ];
  };

  renderGroupValue = ({ value }) => {
    return [
      <td key="1">
        <div>{value.name}</div>
      </td>,
      <td key="2">
        <div className="muted">{value.id}</div>
      </td>,
    ];
  };

  renderGroupForm = (values, index, allValues, isNew) => {
    return (
      <div>
        <Field
          type="hidden"
          name="id"
          defaultValue={values.original_id || values.id}
        />
        <Field
          type="hidden"
          name="original_id"
          defaultValue={values.original_id || values.id}
          readonly={true}
        />
        <Field
          type="text"
          name="name"
          label="Group name"
          defaultValue={values.name}
          required={true}
        />
        <Field
          type="text"
          name="id"
          label="Group ID"
          defaultValue={values.id}
          placeholder={snakeCase(values.name)}
          required={true}
          locked={!isNew}
          help="Used by developers to identify the group in applications"
        />
      </div>
    );
  };

  render() {
    const { values, edited, onSubmitForm, onChangeForm } = this.props;

    return (
      <div className="settings settings-customers">
        <Form ref="form" onSubmit={onSubmitForm} onChange={onChangeForm}>
          <View
            detail={true}
            uri="/settings"
            sectionTitle="Settings"
            headerTitle="Customers"
            headerSubtitle="Manage settings that affect store customers"
            headerActions={[
              {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
          >
            <div className="view-body-section-group">
              <fieldset className="full">
                <div className="view-body-section">
                  <div className="view-body-subheader">
                    <SectionHeader
                      className="view-body-subtitle"
                      title="Busines customers"
                      subtitle="Enable setting a customer type as business or individual"
                    />
                    <div className="view-body-actions">
                      <Field
                        type="toggle"
                        name="features.business"
                        defaultChecked={values.features.business}
                      />
                    </div>
                  </div>

                  {values.features.business && (
                    <div className="view-section-content">
                      <div className="view-body-subsection">
                        <FadeIn active={values.features.business}>
                          <div className="view-body-subheader">
                            <div>
                              <h3 className="view-body-subtitle">VAT number</h3>
                              <p>
                                Enable collection of VAT number for business
                                customers
                              </p>
                            </div>
                            <div className="view-body-actions">
                              <Field
                                type="toggle"
                                name="features.vat"
                                defaultChecked={values.features.vat}
                              />
                            </div>
                          </div>
                        </FadeIn>
                      </div>
                    </div>
                  )}
                </div>
              </fieldset>
              <fieldset className="full">
                <div className="view-body-section">
                  <div className="view-body-subheader">
                    <SectionHeader
                      className="view-body-subtitle"
                      title="Groups"
                      subtitle="Manage groups of customers used to assign special pricing and promotions"
                    />
                  </div>
                  <div className="view-section-content">
                    <Fieldtable
                      label="Customer group"
                      name="groups"
                      defaultValue={values.groups}
                      onSubmitModal={this.onSubmitModal}
                      renderHeading={this.renderGroupHeading}
                      renderValue={this.renderGroupValue}
                      renderForm={this.renderGroupForm}
                      onSubmit={(values) =>
                        this.props.onSubmitForm({ groups: values })
                      }
                      sortable={true}
                      formWidth={400}
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          </View>
        </Form>
      </div>
    );
  }
}
