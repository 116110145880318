import React, { Fragment } from 'react';
import Icon from 'components/icon';
import Link from 'components/link';
import User from '../utils/user';
import Date from '../utils/date';

export default function InvoiceCreated({ event }) {
  const { subscription_id } = event.data;

  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/order-created" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          {subscription_id ? (
            <Fragment>
              Invoice{' '}
              <Link to={`/subscriptions/${subscription_id}`}>created</Link>
            </Fragment>
          ) : (
            <Fragment>Invoice created</Fragment>
          )}
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
      </div>
    </div>
  );
}
