import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import fingerprint from 'ssh-fingerprint';
import ViewLoading from 'components/view/loading';
import DeveloperToolsPage from 'components/storefront/developer-tools';
import actions from 'actions';
import { writeToClipboard } from 'utils/clipboard';
import store from '../store/instance';

const mapStateToProps = (state) => ({
  sshKeys: state.client.sshKeys,
  loadingAny: state.loading,
  loading: state.storefronts.loading,
});

const mapDispatchToProps = (dispatch) => ({
  createAPIKey: (sourceId, data) => {
    return dispatch(actions.storefronts.createAPIKey(sourceId, data));
  },

  updateAPIKey: (sourceId, keyId, data) => {
    return dispatch(actions.storefronts.updateAPIKey(sourceId, keyId, data));
  },

  deleteAPIKey: (sourceId, keyId) => {
    return dispatch(actions.storefronts.deleteAPIKey(sourceId, keyId));
  },

  fetchSSHKeys: () => {
    return dispatch(actions.client.fetchSSHKeys());
  },

  createSSHKey: (data) => {
    return dispatch(actions.client.createSSHKey(data));
  },

  deleteSSHKey: (id) => {
    return dispatch(actions.client.deleteSSHKey(id));
  },

  fetchStorefront: (id) => {
    return dispatch(actions.storefronts.fetch(id));
  },

  updateStorefront: (id, data) => {
    return dispatch(actions.storefronts.update(id, data));
  },
});

export class StorefrontDeveloperTools extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      values: {},
      copiedKey: false,
      onCopyKey: this.onCopyKey.bind(this),
      onSaveAPIKey: this.onSaveAPIKey.bind(this),
      onRemoveAPIKey: this.onRemoveAPIKey.bind(this),
      onCreateSSHKey: this.onCreateSSHKey.bind(this),
      onDeleteSSHKey: this.onDeleteSSHKey.bind(this),
    };
  }

  async componentWillMount() {
    const { fetchSSHKeys } = this.props;

    await fetchSSHKeys();

    this.setState({
      loaded: true,
    });
  }

  onCopyKey(event, key) {
    event.stopPropagation();

    writeToClipboard(key)
      .then(() => {
        this.setState({
          copiedKey: true,
        });

        setTimeout(() => {
          this.setState({
            copiedKey: false,
          });
        }, 5000);
        return store.dispatch(
          actions.flash.success('API key copied to clipboard'),
        );
      })
      .catch(() => {
        return store.dispatch(
          actions.flash.error(
            'Error copying API key to clipboard. Please select it and copy it manually',
          ),
        );
      });
    return true;
  }

  async onSaveAPIKey(keyId, values) {
    const { params, updateAPIKey, createAPIKey, fetchStorefront } = this.props;

    const result = await (keyId
      ? updateAPIKey(params.id, keyId, values)
      : createAPIKey(params.id, values));

    if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    await fetchStorefront(params.id);

    return true;
  }

  async onRemoveAPIKey(keyId) {
    const { params, deleteAPIKey, fetchStorefront } = this.props;

    const result = await deleteAPIKey(params.id, keyId);

    if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    await fetchStorefront(params.id);

    return true;
  }

  async onCreateSSHKey(values) {
    const { fetchSSHKeys, createSSHKey } = this.props;
    const fp = fingerprint(values.public_key);
    if (!fp) {
      this.context.notifyError('Public key is not valid');
      return false;
    }
    const result = await createSSHKey(values);
    if (result && result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }
    await fetchSSHKeys();
    return true;
  }

  async onDeleteSSHKey(id) {
    const { fetchSSHKeys, deleteSSHKey } = this.props;
    const result = await deleteSSHKey(id);
    if (result && result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }
    await fetchSSHKeys();
    return true;
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return <DeveloperToolsPage {...this.props} {...this.state} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StorefrontDeveloperTools);
