import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { capitalize, map, get, last, find } from 'lodash';
import {
  Field,
  Fieldtable,
  Fieldgroup,
  Label,
  Tabs,
  TabView,
} from 'components/form';
import Icon from 'components/icon';
import Help from 'components/tooltip/help';
import Link from 'components/link';
import AppIcon from 'components/apps/icon';
import ContentField from 'components/content/field';
import ContentFields from 'components/content/fields';
import ContentCollection from 'components/content/collection';
import { FadeIn } from 'components/transitions';
import {
  LOCALES,
  inflect,
  singularize,
  pluralize,
  wordify,
  snakeCase,
  isEmpty,
} from 'utils';

import {
  contentFieldAliasType,
  contentFieldProps,
  countContentFields,
  contentFieldLabel,
  contentFieldTypeLabel,
  contentFieldTypeProps,
} from 'utils/content';

import { truncatedText } from 'utils/collection';

import { FIELD_TYPES, ADMIN_ZONES, COLLECTION_MODELS } from 'constants/content';

import './model.scss';

function getOptionsWithAutoValues(options) {
  return options?.map((option) => {
    if (!option.value) {
      return { ...option, value: snakeCase(option.label) };
    }

    return option;
  });
}

export default class ContentModelFields extends React.PureComponent {
  static contextTypes = {
    user: PropTypes.object.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  static propTypes = {
    record: PropTypes.object,
    values: PropTypes.object.isRequired,
    lookup: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    content: PropTypes.object,
    client: PropTypes.object,
    currency: PropTypes.string,
    editable: PropTypes.bool,
    modal: PropTypes.bool,
    zone: PropTypes.string,
    editLoadingTitle: PropTypes.string,

    onOpenModal: PropTypes.func,
    onCloseModal: PropTypes.func,
  };

  state = {
    field: null,
    fieldPath: [],
    fieldPathName: null,
    disabled: undefined,
    editableProps: {},
    contentModel: null,
    contentField: null,
    contentFieldIndex: null,
    fieldIDLock: true,
  };

  editableProps = {};
  fieldtableProps = {};

  disabled = undefined;
  disabledProps = {
    addable: false,
    editable: false,
    removable: false,
    sortable: false,
  };

  constructor(props) {
    super(props);
    if (props.editable === false) {
      this.state = {
        ...this.state,
        disabled: true,
        editableProps: {
          ...this.disabledProps,
        },
        collectionOptions: this.getCollectionOptions(props),
      };
      this.disabled = true;
      this.editableProps = this.state.editableProps;
    }
    this.renderFieldsValue = this.renderFieldsValue.bind(this);
    this.renderFieldsForm = this.renderFieldsForm.bind(this);
    this.renderTypesValue = this.renderTypesValue.bind(this);
    this.renderTypesForm = this.renderTypesForm.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.values !== prevProps.values) {
      this.setState({
        collectionOptions: this.getCollectionOptions(this.props),
      });
    }
  }

  getFieldPathName(fieldPath) {
    return fieldPath.length
      ? fieldPath
          .map(({ path, itemtypes }, i) =>
            (fieldPath[i - 1] && fieldPath[i - 1].itemtypes) ||
            (i === 0 && this.props.values.item_types)
              ? `item_types.${path}`
              : `fields.${path}`,
          )
          .join('.')
      : '';
  }

  getField(fieldPathName) {
    return get(this.props.values, fieldPathName);
  }

  getFieldPath() {
    const { fieldPath } = this.state;
    return fieldPath;
  }

  onClickFieldPath = (event) => {
    event.preventDefault();
    const { path, type, group, itemtypes } = event.currentTarget.dataset;
    const fieldPath = [
      ...this.getFieldPath(),
      { path, type, group, itemtypes },
    ];
    const fieldPathName = this.getFieldPathName(fieldPath);
    const field = this.getField(fieldPathName);
    last(fieldPath).field = field || {};
    last(fieldPath).label = contentFieldLabel(field);
    this.setState({
      field,
      fieldPath,
      fieldPathName,
    });
  };

  onClickField = (event) => {
    event.preventDefault();
    const { index } = event.currentTarget.dataset;
    const fieldPath = this.getFieldPath().slice(0, +index + 1);
    const fieldPathName = this.getFieldPathName(fieldPath);
    const field = this.getField(fieldPathName);
    this.setState({
      field,
      fieldPath,
      fieldPathName,
    });
  };

  onClickFieldBack = (event) => {
    event.preventDefault();
    this.setState({
      field: null,
      fieldPath: [],
      fieldPathName: null,
    });
  };

  onAddRow = () => {
    this.disabled = this.state.disabled;
    this.editableProps = this.state.editableProps;

    if (this.props.onOpenModal) {
      this.props.onOpenModal();
    }
  };

  editableCheck = (value) => {
    if (value?.source_type === 'theme' || value?.source_type === 'app') {
      return false;
    }
    return true;
  };

  onEditFieldRow = (value) => {
    const { record = {} } = this.props;
    if (value.source_type === 'theme' || value.source_type === 'app') {
      this.disabled = true;
      this.editableProps = this.disabledProps;
    } else {
      this.disabled = this.state.disabled;
      this.editableProps = this.state.editableProps;
    }
    if (!value.label && value.id) {
      value.label = wordify(value.id);
    }
    if (value.tab) {
      delete value.tab;
    }
    if (this.props.onOpenModal) {
      this.props.onOpenModal();
      const contentModel = (record.content_models || []).find(
        (m) => m.id === value.content_id,
      );
      if (contentModel) {
        const contentFieldIndex = (contentModel.fields || []).findIndex(
          (field) => field.id === value.id,
        );
        const contentField = (contentModel.fields || [])[contentFieldIndex];
        this.setState({ contentModel, contentField, contentFieldIndex });
      }
    }
    // Lock root select initially
    this.setState({ fieldIDLock: true });
  };

  onChangeFieldIDLock = (locked) => {
    this.setState({ fieldIDLock: locked });
  };

  onEditTypeRow = (value) => {
    if (!value.name && value.id) {
      value.name = wordify(value.id);
    }
    if (this.props.onOpenModal) {
      this.props.onOpenModal();
    }
  };

  onCloseModal = () => {
    if (this.props.onCloseModal) {
      this.props.onCloseModal();
      this.setState({
        contentModel: null,
        contentField: null,
        contentFieldIndex: null,
      });
    }
  };

  onSubmitFieldsModal = (value) => {
    if (!value.id) {
      value.id = snakeCase(value.label);
    }
    if (!value.label) {
      value.label = wordify(value.id);
    }
    if (!value.fallback) {
      value.fallback = false;
    }
    if (value.options) {
      for (const op of value.options) {
        if (op && !this.isScalar(op) && !op.value) {
          op.value = snakeCase(op.label);
        }
      }
    }
  };

  onSubmitTypesModal = (value) => {
    if (!value.id) {
      value.id = snakeCase(value.name);
    }
    if (!value.name) {
      value.name = wordify(value.id);
    }
  };

  getCollectionOptions(props) {
    const { values = {} } = props;
    // Filter out child collections unless there is a parent field
    return props.content.collectionOptions.filter((option) => {
      if (!option.model.child) return true;
      return !!find(values.fields, { collection: option.model.parent });
    });
  }

  getParentCollectionOptions(lookupCollection) {
    const { values = {} } = this.props;
    let parents = [];
    const option = this.state.collectionOptions.find(
      (op) => op.value === lookupCollection,
    );
    if (option) {
      const parentFields = (values.fields || []).filter(
        (field) =>
          field.collection === option.model.parent &&
          field.value_type !== 'collection',
      );
      parents = parentFields.map((field) => ({
        value: field.id,
        label: `${field.label} records`,
      }));
    }
    return parents;
  }

  renderFieldLabel() {
    const { values } = this.props;
    const { fieldPath } = this.state;
    return (
      <label className="content-model-form-field-label">
        {fieldPath.length > 0 ? (
          <Fragment>
            <a href="" onClick={this.onClickFieldBack}>
              {values.item_types ? 'Item types' : 'Fields'}
            </a>
            <span className="muted">&nbsp;&rarr;&nbsp;</span>
          </Fragment>
        ) : values.item_types ? (
          'Item types'
        ) : (
          'Fields'
        )}
        {fieldPath.map((part, i) =>
          fieldPath[i + 1] ? (
            <Fragment key={i}>
              <a href="" onClick={this.onClickField} data-index={i}>
                {part.label}
              </a>
              <span className="muted">&nbsp;&rarr;&nbsp;</span>
            </Fragment>
          ) : (
            <Fragment key={i}>
              {part.field.item_types
                ? `${singularize(part.label)} types`
                : part.label}
            </Fragment>
          ),
        )}
      </label>
    );
  }

  renderFieldTypes() {
    const { values } = this.props;
    const { fieldPathName } = this.state;
    const typesPath = fieldPathName
      ? `${fieldPathName}.item_types`
      : 'item_types';
    const itemTypes = get(values, typesPath, []);
    const fieldsPath = fieldPathName ? `${fieldPathName}.fields` : 'fields';
    const fields = get(values, fieldsPath, []);
    return (
      <FadeIn transitionAppear={true} key={typesPath}>
        {itemTypes.length === 0 && (
          <div className="collection-table-container">
            <table className="collection-table headless">
              <tbody>
                <tr>
                  <td align="center">No item types added yet</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <Field type="hidden" name={fieldsPath} value={fields} />
        <Fieldtable
          label="Item type"
          name={typesPath}
          formWidth={950}
          defaultValue={itemTypes}
          renderHeading={this.renderTypesHeading}
          renderValue={this.renderTypesValue}
          renderForm={this.renderTypesForm}
          editTitle={this.renderTypesEditTitle}
          editSubtitle={this.renderTypesEditSubtitle}
          editLoadingTitle={this.props.editLoadingTitle}
          onSubmitModal={this.onSubmitTypesModal}
          onEditRow={this.onEditTypeRow}
          onAddRow={this.onAddRow}
          onCloseModal={this.onCloseModal}
          sortable={true}
          confirmEdited={true}
          unregisterField={false}
          {...this.fieldtableProps}
          {...this.editableProps}
        />
      </FadeIn>
    );
  }

  renderFormWidth = ({ type }, isNew) => {
    // Make fields with sub fields larger
    return isNew
      ? 770
      : type === 'collection' || type === 'field_row'
      ? 950
      : 680;
  };

  renderFieldsEditTitle = (field) => {
    const { label, name, id, new: isNew } = field;
    const { type } = contentFieldProps(field);
    const typeProps = contentFieldTypeProps(type);
    return (
      <Fragment>
        {typeProps.inputLabel === false
          ? typeProps.label
          : label || name || (isNew || !id ? 'New field' : wordify(id))}
      </Fragment>
    );
  };

  renderTypesEditTitle = ({ name, id, new: isNew }) => {
    if (name) {
      return name;
    }
    return isNew ? 'New item type' : wordify(id);
  };

  renderFieldsEditSubtitle = (field) => {
    const { type } = contentFieldProps(field);
    const typeProps = contentFieldTypeProps(type);
    return (
      <Fragment>
        {this.renderFieldTypeIcon(type)} &nbsp;
        {typeProps.inputLabel !== false && contentFieldTypeLabel(type)}
      </Fragment>
    );
  };

  renderTypesEditSubtitle = ({ name }) => {
    return name ? (
      <Fragment>{this.renderFieldTypeIcon('select')} &nbsp;Item type</Fragment>
    ) : null;
  };

  renderFieldsEditDevtools = () => {
    const { contentModel } = this.state;
    if (contentModel === null) {
      return null;
    }
    return {
      model: ':content',
      uri: contentModel.id,
    };
  };

  renderFieldsEditLocalized = (field) => {
    return field.localized && field.default_enabled;
  };

  onValidateSubmit = (values) => {
    // Check if any field is missing the Field ID
    if (values.some((item) => item.id === null)) {
      this.context.notifyError('Field ID is required');
      return false;
    }

    // we pass to onSubmit in this list of props
    if (this.fieldtableProps.onSubmit) {
      return this.fieldtableProps.onSubmit(values);
    }
  };

  renderFields() {
    const { values } = this.props;
    const { fieldPathName } = this.state;
    const fieldsPath = fieldPathName ? `${fieldPathName}.fields` : 'fields';
    const fields = get(values, fieldsPath, []).filter(
      (field) =>
        // Hide standard generated content fields
        !field?.content_id?.startsWith?.('standard'),
    );
    const fieldGroups = fields.filter((field) => field.type === 'field_group');
    const regularFields = fields.filter(
      (field) => field.type !== 'field_group',
    );

    return (
      <FadeIn transitionAppear={true} key={fieldsPath}>
        {fields.length === 0 && (
          <div className="collection-table-container">
            <table className="collection-table headless">
              <tbody>
                <tr>
                  <td align="center" className="muted">
                    No fields added yet
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {fieldGroups.length > 0 ? (
          <div className="content-model-fields-groups">
            <Tabs
              name="field_group_tab"
              items={fieldGroups.map((fieldGroup, i) => {
                const label = fieldGroup.label || fieldGroup.name;
                return { value: label, label };
              })}
            />
            {fieldGroups.map((fieldGroup, i) => {
              const label = fieldGroup.label || fieldGroup.name;
              return (
                <TabView
                  key={i}
                  value={values.field_group_tab}
                  active={label}
                  default
                >
                  <Fieldtable
                    label={label}
                    name={`${fieldsPath}.${i}.fields`}
                    formWidth={this.renderFormWidth}
                    defaultValue={fieldGroup.fields.map((field) => ({
                      ...field,
                      group: i,
                    }))}
                    renderHeading={this.renderFieldsHeading}
                    renderValue={this.renderFieldsValue}
                    renderForm={this.renderFieldsForm}
                    editTitle={this.renderFieldsEditTitle}
                    editSubtitle={this.renderFieldsEditSubtitle}
                    editLocalized={this.renderFieldsEditLocalized}
                    editDevtools={this.renderFieldsEditDevtools}
                    editLoadingTitle={this.props.editLoadingTitle}
                    onSubmitModal={this.onSubmitFieldsModal}
                    onEditRow={this.onEditFieldRow}
                    onAddRow={this.onAddRow}
                    onCloseModal={this.onCloseModal}
                    sortable={true}
                    confirmEdited={true}
                    saveAndContinue={true}
                    unregisterField={false}
                    saveBackLabel="Change field type"
                    editableCheck={this.editableCheck}
                    {...this.fieldtableProps}
                    {...this.state.editableProps}
                  />
                </TabView>
              );
            })}
          </div>
        ) : (
          <Fieldtable
            label="Field"
            name={fieldsPath}
            formWidth={this.renderFormWidth}
            defaultValue={regularFields}
            renderHeading={this.renderFieldsHeading}
            renderValue={this.renderFieldsValue}
            renderForm={this.renderFieldsForm}
            editTitle={this.renderFieldsEditTitle}
            editSubtitle={this.renderFieldsEditSubtitle}
            editLocalized={this.renderFieldsEditLocalized}
            editDevtools={this.renderFieldsEditDevtools}
            editLoadingTitle={this.props.editLoadingTitle}
            onSubmitModal={this.onSubmitFieldsModal}
            onEditRow={this.onEditFieldRow}
            onAddRow={this.onAddRow}
            onCloseModal={this.onCloseModal}
            sortable={true}
            confirmEdited={true}
            saveAndContinue={true}
            unregisterField={false}
            saveBackLabel="Change field type"
            saveable={!this.disabled}
            editableCheck={this.editableCheck}
            {...this.fieldtableProps}
            {...this.state.editableProps}
            onSubmit={this.onValidateSubmit}
          />
        )}
      </FadeIn>
    );
  }

  renderFieldsHeading() {
    return [
      <th key="1">Label</th>,
      <th key="2">ID</th>,
      <th key="3">Type</th>,
      <th key="4">Properties</th>,
      <th key="5">&nbsp;</th>,
    ];
  }

  renderTypesHeading() {
    return [
      <th key="1">Label</th>,
      <th key="2">ID</th>,
      <th key="4">Properties</th>,
      <th key="5">&nbsp;</th>,
    ];
  }

  renderFieldsValue({ value, index }) {
    return [
      <td key="1">
        <div className="content-model-table-cell">
          {truncatedText(value.label || wordify(value.id), 30)}
        </div>
      </td>,
      <td key="2">
        <div>
          {value.id ? (
            <div className="content-model-table-cell">
              <code className="muted">{truncatedText(value.id, 30)}</code>
            </div>
          ) : (
            <span className="muted">&mdash;</span>
          )}
        </div>
      </td>,
      <td key="3">
        <code>
          {contentFieldAliasType(value.type)}{' '}
          {value.format && `(${value.format})`}
        </code>
      </td>,
      ...this.renderFieldProperties(value, index),
    ];
  }

  renderTypesValue({ value, index }) {
    return [
      <td key="1">
        <div>{value.label || value.name || capitalize(value.id)}</div>
      </td>,
      <td key="2">
        <div>
          <code className="muted">{value.slug || value.id}</code>
        </div>
      </td>,
      ...this.renderFieldProperties(value, index),
    ];
  }

  renderFieldLookupProperty(field) {
    const {
      client: { appsById },
      content: { collections },
    } = this.props;

    const linkModel = field.collection || field.model;

    if (!linkModel) {
      return null;
    }

    let linkCollection = collections[linkModel];
    if (linkCollection?.parent) {
      linkCollection = collections[linkCollection.parent];
    }

    const renderLink = (children) =>
      linkCollection ? (
        <Link
          to={`/settings/models/${linkCollection.id}?tab=data`}
          className="collection-table-link"
        >
          {children}
        </Link>
      ) : (
        children
      );

    if (linkModel.startsWith('apps/')) {
      const linkParts = linkModel.split('/');
      const app = appsById[linkParts[1]];
      if (app) {
        return renderLink(
          <>
            To <AppIcon image={app.logo_icon} name={app.name} size={16} />
            {''}
            {linkParts[2]}
            {field.query && (
              <Help message={`Query: ${JSON.stringify(field.query)}`} />
            )}
          </>,
        );
      }
    }

    return renderLink(
      <>
        To {linkModel}{' '}
        {field.query && (
          <Help message={`Query: ${JSON.stringify(field.query)}`} />
        )}
      </>,
    );
  }

  renderFieldProperties(value, index) {
    const fieldProps = contentFieldProps(value);
    const tags = [
      value.required && 'Required',
      value.unique && 'Unique',
      fieldProps.ui &&
        fieldProps.ui !== 'default' &&
        `${wordify(fieldProps.ui)}`,
      !isEmpty(value.min) && `Min: ${value.min}`,
      !isEmpty(value.max) && `Max: ${value.max}`,
      !isEmpty(fieldProps.digits) && inflect(fieldProps.digits, 'digits'),
      value.localized && `Localized`,
      // TODO: enable when fields can be marked as explicitly public
      // value.public && 'Public',
      value.value_type === 'collection' && 'Collection',
      value.type === 'lookup' && this.renderFieldLookupProperty(value),
      !isEmpty(value.default) && (
        <span>
          {value.root_level && value.fallback !== false
            ? 'Fallback'
            : 'Default'}{' '}
          {typeof value.default === 'string' ? (
            <Help message={value.default} />
          ) : (
            value.default instanceof Array &&
            value.default[0] === 'string' && (
              <Help message={value.default.join(', ')} />
            )
          )}
        </span>
      ),
    ].filter((tag) => tag);
    const hasTags = tags.length > 0;
    const hasFields = !isEmpty(value.fields) || !isEmpty(value.item_types);
    if (!hasTags && !hasFields) {
      return [
        <td key="4">
          <span className="muted">&mdash;</span>
        </td>,
        <td key="5">&nbsp;</td>,
      ];
    }
    return [
      hasTags && (
        <td key="4">
          <ul className="view-body-tags">
            {tags.map((tag, i) => (
              <li key={i} className="nowrap">
                {tag}
              </li>
            ))}
          </ul>
        </td>
      ),
      hasFields && (
        <td key="5">
          <div className="nowrap">
            {this.renderFieldPropertiesLink(value, index)}
          </div>
        </td>
      ),
      (!hasTags || !hasFields) && <td key="5a">&nbsp;</td>,
    ].filter((x) => x);
  }

  renderFieldPropertiesLink(value, index) {
    return (
      <Fragment>
        {!isEmpty(value.item_types) ? (
          <a
            href=""
            onClick={this.onClickFieldPath}
            data-path={
              value.group !== undefined
                ? `${value.group}.fields.${index}`
                : index
            }
            data-type={value.type}
            data-itemtypes={true}
          >
            {this.renderFieldPropertiesText(value)} &rarr;
          </a>
        ) : (
          !isEmpty(value.fields) && (
            <a
              href=""
              onClick={this.onClickFieldPath}
              data-path={
                value.group !== undefined
                  ? `${value.group}.fields.${index}`
                  : index
              }
              data-wtf={JSON.stringify(value)}
              data-type={value.type}
              data-group={value.type === 'field_group' ? index : undefined}
            >
              {this.renderFieldPropertiesText(value)} &rarr;
            </a>
          )
        )}
      </Fragment>
    );
  }

  renderFieldPropertiesText(value) {
    return !isEmpty(value.item_types)
      ? inflect(value.item_types.length, 'item types')
      : inflect(countContentFields(value), 'fields');
  }

  fieldHasAdvancedOptions(type, values) {
    switch (type) {
      case 'field_group':
      case 'field_row':
        return false;
      default:
        return type === 'lookup' || !values.root_zone;
    }
  }

  fieldLocalizable(type) {
    switch (type) {
      case 'collection':
      case 'asset':
      case 'lookup':
      case 'icon':
      case 'boolean':
      case 'select':
      case 'field_group':
      case 'field_row':
        return false;
      default:
        return true;
    }
  }

  fieldHasMinmax(type) {
    switch (type) {
      case 'short_text':
      case 'long_text':
      case 'number':
        return true;
      default:
        return false;
    }
  }

  fieldEnumerable(type) {
    switch (type) {
      case 'short_text':
      case 'long_text':
        return true;
      default:
        return false;
    }
  }

  fieldHasPlaceholder(type, values) {
    switch (type) {
      case 'short_text':
      case 'long_text':
      case 'number':
        if (values.ui === 'slider') {
          return false;
        }
        return true;
      case 'select':
      case 'tags':
      case 'lookup':
        return true;
      default:
        return false;
    }
  }

  fieldHasHelptext(type) {
    switch (type) {
      case 'field_group':
      case 'field_row':
        return false;
      default:
        return true;
    }
  }

  fieldIsMulti(type, values) {
    switch (type) {
      case 'select':
        if (values.ui === 'checkboxes') {
          return true;
        }
        return false;
      default:
        return false;
    }
  }

  fieldHasWidth(type, values) {
    if (!values.admin_zone && !values.admin_span) {
      return false;
    }
    switch (type) {
      case 'asset':
      case 'boolean':
      case 'collection':
        return false;
      case 'select':
        if (values.ui === 'checkboxes' || values.ui === 'radio') {
          return false;
        }
        return true;
      case 'date':
        return values.ui !== 'datetime';
      default:
        return true;
    }
  }

  fieldHasValidation(type) {
    switch (type) {
      case 'boolean':
      case 'collection':
      case 'field_group':
      case 'field_row':
        return false;
      default:
        return true;
    }
  }

  previewFieldProps(type, values) {
    const uiProps = get(FIELD_TYPES[type], `ui.${values.ui || 'default'}`, {});
    if (type === 'number' && values.ui === 'float') {
      uiProps.placeholder = `0.${'0'.repeat(
        values.digits !== 0 ? values.digits : uiProps.digits || 2,
      )}`;
    }
    const uiFallbackValue = (key, handler = undefined) =>
      !isEmpty(values[key])
        ? handler
          ? handler(values[key])
          : values[key]
        : uiProps[key];
    return {
      ...uiProps,
      ...values,
      placeholder: uiFallbackValue('placeholder'),
      ...(type === 'number' && {
        min: uiFallbackValue('min'),
        max: uiFallbackValue('max'),
      }),
      ...(values.ui === 'slider' && {
        defaultValue: uiFallbackValue('min'),
      }),
      ...(type === 'select' && {
        options: uiFallbackValue('options', (options) =>
          map(values.options, ({ label, value }) => ({
            label,
            value: value || snakeCase(label),
          })),
        ),
      }),
      ...(values.type === 'color' && {
        type: 'text',
      }),
      ...(values.type === 'lookup' && {
        placeholder: values.model ? undefined : uiFallbackValue('placeholder'),
      }),
    };
  }

  renderFieldTypeIcon(type, active = false) {
    const fieldType = FIELD_TYPES[type] || {};
    if (fieldType.icon) {
      return (
        <Icon type={active ? `${fieldType.icon}-active` : fieldType.icon} />
      );
    }
    return (
      <Icon
        fa={fieldType.faIcon || 'cube'}
        faType={fieldType.faType || 'light'}
      />
    );
  }

  renderMinMaxFields(type, values, previewProps = {}) {
    return (
      <Fragment>
        <Field
          type="number"
          label={type === 'number' ? 'Min value' : 'Min length'}
          name="min"
          placeholder={!isEmpty(previewProps.min) ? previewProps.min : 'Any'}
          defaultValue={values.min}
          className="span1"
          disabled={this.disabled}
        />
        <Field
          type="number"
          label={type === 'number' ? 'Max value' : 'Max length'}
          name="max"
          placeholder={
            !isEmpty(previewProps.max)
              ? previewProps.max <= values.min
                ? +values.min + 100
                : previewProps.max
              : 'Any'
          }
          defaultValue={values.max < values.min ? '' : values.max}
          maxValue={999999999}
          className="span1"
          disabled={this.disabled}
        />
        <div className="span2" />
      </Fragment>
    );
  }

  renderFieldsForm(values, _index, _allValues, isNew) {
    const fieldType = contentFieldAliasType(values.type);
    const uiOptions = map(
      get(FIELD_TYPES[fieldType], 'ui'),
      (ui, key) =>
        ui.label && {
          value: key === 'default' ? '' : key,
          label: ui.label,
        },
    ).filter((ui) => ui);

    const fieldProps = contentFieldProps(values);
    const typeProps = contentFieldTypeProps(fieldType);
    const previewProps = this.previewFieldProps(fieldType, values);

    const lookupQueryEnabled =
      values.query_type !== undefined
        ? values.query_type === 'custom'
        : !isEmpty(values.query);

    const lookupCollection = values.collection || values.model;
    const lookupModel = COLLECTION_MODELS[lookupCollection];

    let childQueryOptions;
    if (lookupModel && lookupModel.parent) {
      childQueryOptions = this.getParentCollectionOptions(lookupCollection);
    }

    return (
      <Fragment>
        {isNew ? (
          <Fragment>
            <Field type="hidden" name="new" value={true} readonly={true} />
            <div className="row">
              <Field
                type="radio"
                name="type"
                label="Input type"
                defaultValue={fieldType}
                buttonsWithIcons={true}
                className="span4 content-model-fields-type"
                options={map(FIELD_TYPES, (type, value) => ({
                  value,
                  wrapper: type.wrapper,
                  label: (
                    <div className="form-radio-button-wrapper">
                      <div className="label">
                        {this.renderFieldTypeIcon(value, values.type === value)}
                        {type.label}
                      </div>
                      <span className="description muted">{type.desc}</span>
                    </div>
                  ),
                })).filter((type) => !type.wrapper)}
              />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Field
              type="hidden"
              name="new"
              value={values.new}
              readonly={true}
            />
            {fieldType === 'lookup' &&
            values.type !== 'lookup' &&
            values.model &&
            values.model !== fieldProps.model ? (
              <Field type="hidden" name="type" value="lookup" />
            ) : (
              <Field type="hidden" name="type" value={values.type} />
            )}
            <Tabs
              name="tab"
              items={[
                { value: 'settings', label: 'Settings' },
                { value: 'preview', label: 'User interface' },
                this.fieldHasValidation(fieldType) && {
                  value: 'validation',
                  label: 'Validation rules',
                },
              ]}
            />
            <TabView value={values.tab} active="settings" default>
              <div className="row">
                {typeProps.inputLabel !== false && (
                  <>
                    <Field
                      type="text"
                      name="label"
                      label="Label"
                      defaultValue={values.label}
                      placeholder={wordify(values.id)}
                      required={!values.id}
                      autoFocus={true}
                      className="span2"
                      disabled={this.disabled}
                    />
                    <Field
                      type="code"
                      name="id"
                      label="Field ID"
                      defaultValue={snakeCase(values.id)}
                      placeholder={snakeCase(values.label)}
                      required={!values.label}
                      locked={!values.new}
                      className="span2"
                      help="Used by developers to identify the field in applications"
                      disabled={this.disabled}
                      onChangeLock={this.onChangeFieldIDLock}
                    />
                  </>
                )}
                {/*<Field
                  type="select"
                  name="group"
                  label="Field group"
                  readonly={true}
                  defaultValue={values.group}
                  className="span1-3rd"
                  options={fieldGroups}
                  placeholder="Optional"
                  newable={true}
                />*/}
              </div>
              {fieldType === 'select' && (
                <fieldset className="no-border">
                  <label>Options</label>
                  <Fieldtable
                    label="Option"
                    name="options"
                    defaultValue={values.options}
                    renderHeading={this.renderSelectOptionHeading}
                    renderValue={this.renderSelectOptionValue}
                    inline={true}
                    uniqueFields={['label']}
                    sortable={true}
                    {...this.editableProps}
                  />
                </fieldset>
              )}
              {(fieldType === 'collection' || fieldType === 'field_row') && (
                <fieldset className="no-border">
                  <ContentModelFields
                    {...this.props}
                    values={values}
                    editable={!this.disabled}
                  />
                </fieldset>
              )}
              {fieldType === 'lookup' && (
                <Fragment>
                  <div className="row">
                    <Field
                      type="select"
                      label="Lookup collection"
                      name="collection"
                      placeholder="Choose one"
                      defaultValue={fieldProps.collection || fieldProps.model}
                      required={true}
                      className="span2"
                      options={this.state.collectionOptions}
                      disabled={this.disabled}
                    />
                    <div className="span2">
                      {(values.collection || lookupModel) && (
                        <FadeIn>
                          <Field
                            type="select"
                            label="Query"
                            name="query_type"
                            placeholder="All records"
                            defaultValue={
                              values.query_type ||
                              (lookupQueryEnabled
                                ? 'custom'
                                : childQueryOptions?.[0]?.value || undefined)
                            }
                            readonly={true}
                            options={[
                              ...(childQueryOptions || [
                                {
                                  value: '',
                                  label: `All ${pluralize(values.collection)}`,
                                },
                              ]),
                              { value: 'custom', label: 'Custom query' },
                            ]}
                            disabled={this.disabled}
                          />
                        </FadeIn>
                      )}
                    </div>
                  </div>
                  {values.collection && (
                    <Fragment>
                      <FadeIn active={lookupQueryEnabled}>
                        {lookupQueryEnabled ? (
                          <div className="row">
                            <Field
                              type="json-editor"
                              label="Lookup query"
                              name="query"
                              defaultValue={values.query}
                              className="span4"
                              disabled={this.disabled}
                            />
                          </div>
                        ) : (
                          <Fragment>
                            <Field
                              type="hidden"
                              name="collection_parent_id"
                              value={values.query_type}
                            />
                            <Field
                              type="hidden"
                              name="collection_parent_field"
                              value={lookupModel.fieldName}
                            />
                          </Fragment>
                        )}
                      </FadeIn>
                    </Fragment>
                  )}
                </Fragment>
              )}
              {LOCALES.length > 0 && this.fieldLocalizable(fieldType) && (
                <div className="row">
                  <Field
                    type="toggle"
                    name="localized"
                    label="Localized"
                    defaultChecked={values.localized}
                    help={`Allow content to be translated in configured languages ${
                      LOCALES.length > 0
                        ? `(${LOCALES.map((locale) => locale.name).join(', ')})`
                        : ''
                    }`}
                    className="span4"
                    disabled={this.disabled}
                  />
                </div>
              )}
              {this.renderDefaultField(fieldType, values)}
              <br />
              {values.root_level && this.renderAdminZoneSelect(values)}
              {(values.root_level || fieldType === 'lookup') &&
                this.fieldHasAdvancedOptions(fieldType, values) && (
                  <Fieldgroup label="Advanced options" className="normal">
                    {fieldType === 'lookup' && (
                      <Fragment>
                        <div className="row">
                          <Field
                            type="code"
                            name="key"
                            label="Lookup key ID"
                            defaultValue={
                              values.key ? snakeCase(values.key) : ''
                            }
                            placeholder={
                              values.id || values.label
                                ? `${snakeCase(values.id || values.label)}_id${
                                    values.value_type === 'collection'
                                      ? 's'
                                      : ''
                                  }`
                                : ''
                            }
                            locked={false && !values.new}
                            className="span2"
                            help="ID of the data field to store a reference to the lookup record"
                            disabled={this.disabled}
                          />
                          {lookupModel && lookupModel.secondary_field ? (
                            <Field
                              type="select"
                              name="key_field"
                              label="Lookup to field"
                              options={[
                                { value: 'id', label: 'Primary (id)' },
                                {
                                  value: lookupModel.secondary_field,
                                  label: `Secondary (${lookupModel.secondary_field})`,
                                },
                              ]}
                              defaultValue={values.key_field || 'id'}
                              locked={false && !values.new}
                              className="span2"
                              help="Determine which data field the lookup refers to (primary or secondary key)"
                              disabled={this.disabled}
                            />
                          ) : (
                            <Field type="hidden" name="key_field" value="id" />
                          )}
                        </div>
                        <div className="row">
                          <Field
                            type="code"
                            name="name_pattern"
                            label="Display name field"
                            defaultValue={values.name_pattern}
                            placeholder={
                              (lookupModel &&
                                (lookupModel.name_pattern ||
                                  lookupModel.name_field)) ||
                              '{name}'
                            }
                            className="span2"
                            help="Field name or pattern to display the name of a lookup record"
                            disabled={this.disabled}
                          />
                        </div>
                        <div className="row">
                          <Field
                            type="radio"
                            name="value_type"
                            label="Value type"
                            buttons={true}
                            options={[
                              {
                                value: '',
                                label: 'Single',
                              },
                              {
                                value: 'collection',
                                label: 'Collection',
                              },
                            ]}
                            defaultValue={values.value_type || ''}
                            help="Lookup value can be a single or collection of records"
                            className="span4"
                            readonly={true}
                            locked={false && !values.new}
                            disabled={this.disabled}
                          />
                        </div>
                      </Fragment>
                    )}
                    {values.root_level &&
                      !values.root_zone &&
                      this.renderNamespaceField(values)}
                  </Fieldgroup>
                )}
            </TabView>
            <TabView value={values.tab} active="validation">
              <div className="row">
                <Field
                  type="toggle"
                  name="required"
                  label="Required"
                  hint="This field requires a value"
                  defaultChecked={values.required}
                  className="span4"
                  disabled={this.disabled}
                />
              </div>
              <div className="row">
                <Field
                  type="toggle"
                  name="unique"
                  label="Unique"
                  hint="Field value must be unique within the collection"
                  defaultChecked={values.unique}
                  className="span4"
                  disabled={this.disabled}
                />
              </div>
              {this.fieldHasMinmax(fieldType) && (
                <Fragment>
                  <div className="row">
                    <Field
                      type="toggle"
                      name="minmax_enabled"
                      label={
                        fieldType === 'number'
                          ? 'Limit number range'
                          : 'Limit character count'
                      }
                      hint={
                        fieldType === 'number'
                          ? 'Specify minimum and maximum value range'
                          : 'Specify minimum and maximum number of characters'
                      }
                      defaultChecked={
                        values.minmax_enabled ||
                        (values.min || values.max || '') !== ''
                      }
                      readonly={true}
                      className="span4"
                      disabled={this.disabled}
                    />
                  </div>
                  {values.minmax_enabled && (
                    <div className="row indented">
                      {this.renderMinMaxFields(fieldType, values, previewProps)}
                    </div>
                  )}
                </Fragment>
              )}
              {false && this.fieldEnumerable(fieldType) && (
                <Fragment>
                  <div className="row">
                    <Field
                      type="toggle"
                      name="enum_enabled"
                      label="Only specific values"
                      hint="Field requires one or more of the specified values"
                      defaultChecked={values.enum_enabled}
                      readonly={true}
                      className="span4"
                      disabled={this.disabled}
                    />
                  </div>
                  {values.enum_enabled && (
                    <div className="row indented">
                      <Field
                        type="tags"
                        name="enum"
                        placeholder="Separate values with a comma"
                        defaultValue={values.enum}
                        className="span4"
                        disabled={this.disabled}
                      />
                    </div>
                  )}
                </Fragment>
              )}
            </TabView>
            <TabView value={values.tab} active="preview">
              {uiOptions.length > 0 && (
                <div className="row">
                  <Field
                    type="radio"
                    name="ui"
                    buttons={true}
                    defaultValue={fieldProps.ui}
                    className="span4"
                    options={uiOptions}
                    disabled={this.disabled}
                  />
                </div>
              )}
              <label>Preview</label>
              <div className="content-model-fields-preview">
                {fieldType === 'collection'
                  ? this.renderContentCollectionField(values)
                  : this.renderContentField(values)}
              </div>
              {this.fieldIsMulti(fieldType, values) ? (
                <Field type="hidden" name="multi" value={true} />
              ) : (
                fieldType === 'asset' && (
                  <div className="row">
                    <Field
                      type="toggle"
                      name="multi"
                      label="Allow uploading multiple assets"
                      defaultChecked={!!values.multi}
                      disabled={this.disabled}
                    />
                  </div>
                )
              )}
              {fieldType === 'number' && fieldProps.ui === 'float' && (
                <div className="row">
                  <Field
                    type="number"
                    name="digits"
                    label="Decimal places"
                    defaultValue={fieldProps.digits || 2}
                    placeholder="2"
                    maxValue={99}
                    className="span1"
                    disabled={this.disabled}
                  />
                  <div className="span3" />
                </div>
              )}
              {fieldType === 'number' && fieldProps.ui === 'slider' && (
                <div className="row">
                  {this.renderMinMaxFields(fieldType, values, previewProps)}
                </div>
              )}
              {this.fieldHasPlaceholder(fieldType, values) && (
                <div className="row">
                  <Field
                    type={fieldType === 'long_text' ? 'textarea' : 'text'}
                    name="placeholder"
                    label="Placeholder"
                    defaultValue={values.placeholder}
                    hint="Optional text displayed when the field has no value"
                    className="span4"
                    disabled={this.disabled}
                  />
                </div>
              )}
              {this.fieldHasHelptext(fieldType) && (
                <div className="row">
                  <Field
                    type="text"
                    name="description"
                    label="Description"
                    defaultValue={values.description}
                    hint="Optional hint to describe how this field is used"
                    className="span4"
                    disabled={this.disabled}
                  />
                </div>
              )}
              {this.fieldHasWidth(fieldType, values) && (
                <FadeIn>
                  <div className="row">
                    <Field
                      type="radio"
                      name="admin_span"
                      label="Field width"
                      buttons={true}
                      defaultValue={values.admin_span || 4}
                      options={[
                        { value: 1, label: '25%' },
                        { value: 2, label: '50%' },
                        { value: 3, label: '75%' },
                        { value: 4, label: '100%' },
                      ]}
                      className="span4"
                      hint="Relative width of the field in the admin dashboard"
                      disabled={this.disabled}
                    />
                  </div>
                </FadeIn>
              )}
            </TabView>
          </Fragment>
        )}
      </Fragment>
    );
  }

  getAdminFieldOptions(collection = undefined) {
    const { record = {} } = this.props;
    return ADMIN_ZONES[collection || record.collection];
  }

  renderAdminZoneSelect(values) {
    const options = this.getAdminFieldOptions();
    if (!options) {
      return null;
    }
    // If opening in modal, default to content area or the first value
    if (values.admin_zone === undefined && values.new) {
      const defaultZone =
        this.props.values.admin_zone ||
        this.props.zone ||
        (this.props.modal && 'content');
      if (defaultZone) {
        values.admin_zone = options.find((op) => op.value === defaultZone)
          ? defaultZone
          : options[1].value;
      }
    }

    const selectedRoot = get(
      options.find((op) => op.value === values.admin_zone),
      'root',
    );
    if (selectedRoot) {
      values.root_zone = true;
    }

    return (
      <Fragment>
        <div className="row">
          <Field
            type="radio"
            name="admin_zone"
            label="Admin field location"
            buttons={true}
            defaultValue={values.admin_zone}
            className="span4"
            options={options.map(({ value, label }) => ({
              value,
              label,
            }))}
            hint="Location to show this field for editing in the admin dashboard"
            disabled={this.disabled}
            {...(options.length > 4
              ? { type: 'select', buttons: undefined }
              : {})}
          />
        </div>
        <Field
          type="hidden"
          name="admin_enabled"
          value={
            values.admin_enabled !== undefined ? values.admin_enabled : true
          }
        />
        {selectedRoot && (
          <Fragment>
            <Field type="hidden" name="root" value={selectedRoot} />
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderNamespaceField(values) {
    const { contentModel } = this.state;

    let privateRoot = true;
    let publicRoot = '';
    let isContentRoot = false;
    // we should not show 'content' for this model, it has own content field
    const modelHasOwnContentField =
      this.props.record?.collection === 'content/pages';
    if (contentModel) {
      if (contentModel.root === true) {
        privateRoot = '';
        publicRoot = 'content';
        isContentRoot = true;
      } else if (typeof contentModel.root === 'string') {
        publicRoot = contentModel.root;
      } else if (!contentModel.root) {
        publicRoot = 'content';
      }
    } else {
      publicRoot = 'content';
    }

    const rootValue =
      values.root !== undefined
        ? values.root
        : isContentRoot
        ? privateRoot
        : publicRoot;
    const customRoot =
      typeof rootValue === 'string' && rootValue !== 'content' ? rootValue : '';
    const rootSelect =
      values.root_select !== undefined
        ? values.root_select
        : rootValue === true
        ? true
        : customRoot
        ? 'custom'
        : (values.admin_zone === 'content' || rootValue === 'content') &&
          !modelHasOwnContentField
        ? 'content'
        : true;

    const options = [
      {
        value: true,
        label: 'None',
      },
      {
        value: 'custom',
        label: 'Custom',
      },
    ];

    if (!modelHasOwnContentField) {
      options.push({
        value: 'content',
        label: 'Content',
      });
    }

    return (
      <Fragment>
        <div className="row">
          <Field
            type="radio"
            name="root_select"
            label="API namespace"
            buttons={true}
            options={options}
            defaultValue={rootSelect}
            help="API fields can be nested in a namespace object. Content fields are publicly accessible via Storefront API and Swell.js."
            className="span4"
            readonly={true}
            locked={false && !values.new}
            disabled={this.disabled}
          />
        </div>
        {rootSelect === 'custom' ? (
          <FadeIn>
            <div className="row">
              <Field
                type="code"
                name="root"
                placeholder="object_name"
                defaultValue={snakeCase(customRoot)}
                disabled={this.disabled}
                autoFocus={true}
                required={true}
                className="span4"
              />
            </div>
          </FadeIn>
        ) : (
          <Field type="hidden" name="root" value={rootSelect} />
        )}
        <Field
          type="hidden"
          name="public"
          value={rootValue === publicRoot ? true : ''}
        />
      </Fragment>
    );
  }

  renderPermissionField(values) {
    return (
      <div className="row">
        <Field
          type="radio"
          name="public"
          label="API permission"
          buttons={true}
          options={[
            { value: '', label: 'Private' },
            { value: true, label: 'Public' },
          ]}
          defaultValue={values.public ? true : ''}
          help="Public fields are accessible via Storefront API and Swell.js"
          className="span4"
          disabled={this.disabled}
        />
      </div>
    );
  }

  renderContentCollectionField = (values) => {
    const fields = map(values.fields, (field) => ({
      ...field,
      admin_zone: 'preview',
      admin_enabled: true,
      required: undefined,
    }));
    return (
      <div className="content-fields-box">
        <Label help={values.description}>{values.label || 'Example'}</Label>
        {fields.length > 0 ? (
          <div className="content-collection-preview">
            <ContentFields
              {...this.props}
              zone="preview"
              collection="preview"
              namespace="preview"
              models={[
                {
                  fields,
                  collection: 'preview',
                },
              ]}
              currency={this.props.currency}
              lookup={this.props.lookup}
              categories={this.props.categories}
              render={(children) => children}
            />
          </div>
        ) : (
          <table className="collection-table outer headless">
            <tbody>
              <tr>
                <td align="center" className="muted">
                  No fields added yet
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  };

  renderContentField = (values) => {
    return (
      <ContentFields
        {...this.props}
        zone="preview"
        collection="preview"
        namespace="preview"
        models={[
          {
            fields: [
              {
                ...values,
                options: getOptionsWithAutoValues(values.options),
                admin_zone: 'preview',
                admin_enabled: true,
                label: values.label || 'Label',
                required: undefined,
              },
            ],
            collection: 'preview',
          },
        ]}
        currency={this.props.currency}
        lookup={this.props.lookup}
        categories={this.props.categories}
      />
    );
  };

  renderDefaultField = (type, values) => {
    let component;
    const { record = {} } = this.props;

    const props = {
      type,
      ui: values.ui,
    };

    switch (type) {
      case 'boolean':
        return (
          <div className="row">
            <Field
              type="toggle"
              name="default"
              label="Default on"
              defaultChecked={values.default}
              help="This field will be enabled by default"
              className="span4"
              disabled={this.disabled}
            />
          </div>
        );

      case 'date': {
        component = (
          <Fragment>
            <div className="row">
              <Field
                type="radio"
                name="default_type"
                options={[
                  { value: 'date', label: 'Date' },
                  { value: 'now', label: 'Current time' },
                ]}
                defaultValue={values.default === 'now' ? 'now' : 'date'}
                readonly={true}
                className="span4"
                disabled={this.disabled}
              />
            </div>
            {values.default_type !== 'now' && (
              <div className="row">
                <ContentField
                  type={type}
                  key={values.ui || 'date'}
                  name="default"
                  className="span4"
                  {...props}
                  disabled={this.disabled}
                />
              </div>
            )}
          </Fragment>
        );
        break;
      }

      case 'number': {
        if (!values.ui || values.ui === 'int') {
          props.placeholder = '0';
        } else if (values.ui === 'float' || values.digits > 0) {
          props.placeholder = `0.${'0'.repeat(
            values.digits !== 0 ? values.digits : 2,
          )}`;
          props.digits = values.digits;
        } else {
          props.placeholder = undefined;
        }
        if (values.ui === 'currency') {
          props.localeValue = values.$currency;
        }
        break;
      }

      case 'select': {
        props.placeholder = 'Choose a default value';
        break;
      }

      case 'tags': {
        props.placeholder = 'Enter default values, separated with a comma';
        break;
      }

      case 'color': {
        props.type = 'text';
        props.placeholder = '#ffffff';
        break;
      }

      case 'collection': {
        if (isEmpty(values.fields)) {
          return null;
        }
        const fieldLabel = values.label || wordify(values.id) || 'value';
        this.collectionDefaultFieldLabel = fieldLabel;
        this.collectionDefaultFieldFields = values.fields;
        component = (
          <ContentCollection
            {...values}
            name="default"
            label={`Default ${singularize(fieldLabel).toLowerCase()}`}
            defaultValue={values.default}
            currency={this.props.currency}
            lookup={this.props.lookup}
            categories={this.props.categories}
            className="span4"
            {...this.editableProps}
          />
        );
        break;
      }

      case 'icon': {
        props.placeholder = 'Choose a default icon';
        break;
      }

      case 'short_text':
      case 'long_text':
        break;

      default:
        return null;
    }

    const defaultEnabled =
      values.default_enabled !== undefined
        ? values.default_enabled
        : !isEmpty(values.default);

    if (!defaultEnabled) {
      values.default = undefined;
      values.fallback = undefined;
    }

    const defaultFallback =
      values.fallback !== undefined
        ? values.fallback
        : record.id
        ? values.fallback !== false
        : false;

    return (
      <Fragment>
        <div className="row">
          <Field
            type="toggle"
            name="default_enabled"
            label="Default value"
            defaultChecked={defaultEnabled}
            help="Specify a default value for this field"
            className="span4"
            disabled={this.disabled}
          />
        </div>
        <FadeIn active={defaultEnabled}>
          {defaultEnabled && (
            <Fragment>
              {component || (
                <div className="row">
                  <ContentField
                    type={type}
                    name="default"
                    placeholder="Enter a default value"
                    className="span4"
                    autoFocus={true}
                    required={true}
                    lookup={this.props.lookup}
                    defaultValue={values.default}
                    localized={values.localized}
                    localeValue={values.$locale}
                    options={getOptionsWithAutoValues(values.options)}
                    {...props}
                    disabled={this.disabled}
                  />
                </div>
              )}

              {values.root_level && (
                <div className="row">
                  <Field
                    type="toggle"
                    name="fallback"
                    label="Enable fallback"
                    defaultChecked={defaultFallback}
                    hint="Use default value as fallback when content is not defined"
                    className="span4"
                    disabled={this.disabled}
                  />
                </div>
              )}
            </Fragment>
          )}
        </FadeIn>
      </Fragment>
    );
  };

  renderCollectionDefaultHeading = () => {
    return [<th key="1">Values</th>];
  };

  renderCollectionDefaultValue = ({ value, index }) => {
    const fieldLabel = this.collectionDefaultFieldLabel;
    return [
      <td key="1">
        <div>
          {value.name ||
            value.label ||
            value.heading ||
            value.text ||
            wordify(value.id) ||
            value[Object.keys(value)[0]] ||
            `${singularize(fieldLabel)} #${index + 1}`}
        </div>
      </td>,
    ];
  };

  renderCollectionDefaultForm = (values, index, allValues, isNew) => {
    const fieldFields = this.collectionDefaultFieldFields;
    return (
      <Fragment>
        {map(fieldFields, (field, i) => (
          <Fragment key={i}>
            {field.type === 'collection' ? (
              <ContentCollection
                {...field}
                name={field.id}
                label={field.label || wordify(field.id || field.type)}
                defaultValue={values[field.id]}
                currency={this.props.currency}
                lookup={this.props.lookup}
                categories={this.props.categories}
                className="span4"
              />
            ) : (
              <div className="row">
                <ContentField
                  {...field}
                  name={field.id}
                  label={field.label || wordify(field.id || field.type)}
                  defaultValue={values[field.id]}
                  localized={false}
                  currency={this.props.currency}
                  lookup={this.props.lookup}
                  categories={this.props.categories}
                  className="span4"
                />
              </div>
            )}
          </Fragment>
        ))}
      </Fragment>
    );
  };

  isScalar(value = undefined) {
    switch (typeof value) {
      case 'number':
      case 'string':
        return [<th key="2">Value</th>];
      default:
        return false;
    }
  }

  renderSelectOptionHeading = (value) => {
    if (value && this.isScalar(value[0])) {
      return [<th key="2">Value</th>];
    }
    return [<th key="1">Label</th>, <th key="2">Value</th>];
  };

  renderSelectOptionValue = ({ value, index }, allValues) => {
    if (allValues && this.isScalar(allValues[0])) {
      return [
        <td key="1">
          <Field
            type="text"
            name=""
            defaultValue={value}
            autoFocus={true}
            required={true}
            data-index={index}
            disabled={this.disabled}
          />
        </td>,
      ];
    }
    return [
      <td key="1">
        <Field
          type="text"
          name="label"
          aria-label={`Option label ${index + 1}`}
          defaultValue={value.label}
          autoFocus={true}
          required={true}
          data-index={index}
          disabled={this.disabled}
          error={value.error}
        />
      </td>,
      <td key="2">
        <Field
          type="text"
          name="value"
          aria-label={`Option value ${index + 1}`}
          defaultValue={value.value}
          placeholder={snakeCase(value.label)}
          data-index={index}
          disabled={this.disabled}
        />
      </td>,
    ];
  };

  renderTypesForm(values, index, allValues, isNew) {
    return (
      <Fragment>
        <div className="row">
          <Field
            type="text"
            name="name"
            label="Name"
            defaultValue={values.name}
            placeholder={capitalize(values.id)}
            required={!values.id}
            className="span2"
            disabled={this.disabled}
          />
          <Field
            type="text"
            name="id"
            label="Item type ID"
            defaultValue={snakeCase(values.id)}
            placeholder={snakeCase(values.name)}
            required={!values.name}
            locked={!values.new}
            className="span2"
            help="Used by developers to identify the item type in applications"
            disabled={this.disabled}
          />
        </div>
        <div className="row">
          <span className="span4">
            <ContentModelFields
              {...this.props}
              values={values}
              editable={!this.disabled}
            />
          </span>
        </div>
      </Fragment>
    );
  }

  render() {
    const { values } = this.props;
    const { field } = this.state;

    return (
      <Fragment>
        {this.renderFieldLabel()}
        {(field || values).item_types
          ? this.renderFieldTypes()
          : this.renderFields()}
      </Fragment>
    );
  }
}
