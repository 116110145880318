import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from './Confirm';
import { Field } from 'components/form';
import { nl2br } from 'utils';

export default class ConfirmDeleteModal extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    titleMessage: PropTypes.any,
    loadingTitle: PropTypes.string,
    confirmInput: PropTypes.object,
    action: PropTypes.string,
    actionType: PropTypes.string,
    message: PropTypes.string,

    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
  };

  state = {
    confirmError: null,
  };

  componentDidMount() {
    if (this.refs.confirmValue) {
      this.refs.confirmValue.focus();
    }
  }

  onConfirmInput = (event) => {
    const { confirmInput, onConfirm } = this.props;
    if (
      String(this.refs.confirmValue.value()).toLowerCase().trim() ===
      String(confirmInput.value).toLowerCase().trim()
    ) {
      return onConfirm();
    } else {
      this.setState({
        confirmError: `Please enter "${confirmInput.value}" to confirm deletion`,
      });
      return false;
    }
  };

  render() {
    const {
      title,
      titleMessage,
      loadingTitle,
      onConfirm,
      onCancel,
      message = null,
      confirmInput = null,
      action = 'Delete',
      actionType = 'danger',
      extraActions,
    } = this.props;

    return (
      <ConfirmModal
        title={titleMessage || `Delete ${title}`}
        message={
          <Fragment>
            {typeof message === 'string' ? (
              <p>{nl2br(message)}</p>
            ) : message ? (
              message
            ) : (
              <p>
                Are you sure you want to delete <b>{title}</b>?{' '}
                {!confirmInput && "This action can't be undone."}
              </p>
            )}
            {confirmInput && (
              <Fragment>
                <p>
                  {confirmInput.description} (
                  <span className="nowrap">{confirmInput.value}</span>)
                </p>
                <Field
                  ref="confirmValue"
                  placeholder={confirmInput.value}
                  readonly={true}
                  error={this.state.confirmError}
                />
              </Fragment>
            )}
          </Fragment>
        }
        action={action}
        actionType={actionType}
        onConfirm={confirmInput ? this.onConfirmInput : onConfirm}
        onCancel={onCancel}
        extraActions={extraActions}
        loadingTitle={loadingTitle}
      />
    );
  }
}
