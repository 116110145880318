import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';

export default class FileDropzone extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    acceptClassName: PropTypes.string,
    rejectClassName: PropTypes.string,
    disabledClassName: PropTypes.string,
    accept: PropTypes.string,
    onDrop: PropTypes.func.isRequired,
    children: PropTypes.node,
    multiple: PropTypes.bool,
  };

  render() {
    const {
      className,
      activeClassName,
      acceptClassName,
      rejectClassName,
      disabledClassName,
      accept = 'image/*',
      onDrop,
      children,
      multiple = true,
    } = this.props;

    return (
      <div className="form-dropzone">
        <Dropzone
          role="button"
          className={className}
          activeClassName={activeClassName}
          acceptClassName={acceptClassName}
          rejectClassName={rejectClassName}
          disabledClassName={disabledClassName}
          accept={accept}
          onDrop={onDrop}
          multiple={multiple}
        >
          {children}
        </Dropzone>
      </div>
    );
  }
}
