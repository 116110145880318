import React from 'react';
import get from 'lodash/get';

import { formatCurrencyRounded, formatCurrency, formatNumber } from 'utils';

import Link from 'components/link';
import DateTime from 'components/date-time';
import SectionHeader from 'components/section-header/section-header';

export default class CustomerDraftOrders extends React.PureComponent {
  render() {
    const {
      record: { id, draft_orders },
    } = this.props;

    const orderLength = get(draft_orders, 'results.length', 0);
    const orderCount = get(draft_orders, 'count', 0);

    return (
      <div className="customer-orders">
        <div className="view-body-section">
          <div className="view-body-subheader">
            <SectionHeader
              className="view-body-subtitle"
              title="Draft orders"
              count={formatNumber(orderCount)}
            />
          </div>
          <div className="view-section-content collection-full">
            {orderLength > 0 ? (
              <div className="collection-table-container">
                <table className="collection-table ">
                  <thead>
                    <tr>
                      <th>Number</th>
                      <th>Created</th>
                      <th>Status</th>
                      <th className="total">Discount</th>
                      <th className="total">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {draft_orders.results.map((draft) => (
                      <tr key={draft.id}>
                        <td>
                          <Link to={`/orders/drafts/${draft.number}`}>
                            {draft.number}
                          </Link>
                        </td>
                        <td>
                          <DateTime value={draft.date_created} format="age" />
                        </td>
                        <td>Open</td>
                        <td className="total">
                          {draft.discount_total > 0 ? (
                            <span>
                              {`(${formatCurrency(
                                draft.discount_total,
                                draft.currency,
                              )})`}
                            </span>
                          ) : (
                            <span className="muted">&mdash;</span>
                          )}
                        </td>
                        <td className="total">
                          {formatCurrencyRounded(
                            draft.grand_total,
                            draft.currency,
                          )}
                        </td>
                      </tr>
                    ))}
                    {orderCount > orderLength && (
                      <tr className="footnote">
                        <td colSpan={4}>
                          <Link to={`/orders/drafts?customer=${id}`}>
                            View all &rarr;
                          </Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="collection-none-found">No subscriptions yet</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
