/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import actions from 'actions';
import api from 'services/api';

import { Form, Field } from 'components/form';
import Link from 'components/link/link';
import { FadeInDownBounce } from 'components/transitions';
import Tooltip from 'components/tooltip';
import IconPayment from 'components/icon/payment';

import { formatCurrencyRounded } from 'utils';

const mapStateToProps = (state) => ({
  model: state.data.model,
  record: state.data.record,
});

const mapDispatchToProps = (dispatch) => ({
  addGiftcard(model, id, data) {
    return dispatch(
      actions.data.createRecord(`${model}/${id}/giftcards`, data),
    );
  },

  removeGiftcard(model, id, giftcardId) {
    return dispatch(
      actions.data.deleteRecord(`${model}/${id}/giftcards`, giftcardId),
    );
  },
});

class GiftcardOption extends React.PureComponent {
  static contextTypes = {
    notifyError: pt.func.isRequired,
  };

  static propTypes = {
    model: pt.string.isRequired,
    record: pt.object.isRequired,
    addGiftcard: pt.func.isRequired,
    removeGiftcard: pt.func.isRequired,
    fetchRecord: pt.func.isRequired,
    selected: pt.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      addingGiftcard: false,
    };

    this.formRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (state.addingGiftcard && props.selected === false) {
      return { addingGiftcard: false };
    }

    return null;
  }

  onClickAddGiftcard = (event) => {
    event.preventDefault();
    this.setState({ addingGiftcard: !this.state.addingGiftcard });
  };

  onClickRemoveGiftcard = (event) => {
    event.preventDefault();
    this.onRemoveGiftcard(event.target.dataset.id);
  };

  onClickSubmit = (event) => {
    event.preventDefault();
    this.formRef.submit();
  };

  onSubmit = async (values) => {
    const { notifyError } = this.context;
    const { model, record, fetchRecord, addGiftcard } = this.props;
    const code = String(values.giftcard_code)
      .replace(/[^0-9A-Z]*/gi, '')
      .toUpperCase();
    const giftcard = await api.get('/data/giftcards/:last', { code });

    if (!giftcard || !code) {
      return notifyError(`Gift card not found with code ${code}`);
    }

    if (giftcard.redeemed || giftcard.balance === 0) {
      return notifyError(`Gift card has already been fully redeemed`);
    }

    const hasGiftcard = record.giftcards?.find(
      (giftcard) => giftcard.code === code,
    );

    if (!hasGiftcard) {
      const addedGiftcard = await addGiftcard(model, record.id, { code });

      if (addedGiftcard.errors) {
        return notifyError(addedGiftcard.errors);
      }

      await fetchRecord(record.id);
    }

    this.setState({ addingGiftcard: false });
  };

  async onRemoveGiftcard(giftcardId) {
    const { model, record, fetchRecord, removeGiftcard } = this.props;

    await removeGiftcard(model, record.id, giftcardId);
    await fetchRecord(record.id);
  }

  renderGiftcardInput() {
    return (
      <FadeInDownBounce>
        <Form
          ref={(form) => (this.formRef = form)}
          className="giftcard-option-input"
          onSubmit={this.onSubmit}
        >
          <Field
            type="text"
            className="span4"
            name="giftcard_code"
            placeholder="**** **** **** ****"
            autoComplete="off"
            required={true}
          />
          <Link
            type="submit"
            className="button button-sm button-secondary"
            onClick={this.onClickSubmit}
          >
            Validate
          </Link>
          <Link className="link-button" onClick={this.onClickAddGiftcard}>
            Cancel
          </Link>
        </Form>
      </FadeInDownBounce>
    );
  }

  renderGiftcardTags() {
    const { giftcards } = this.props.record;

    return (
      <ul className="form-tags-list">
        {giftcards.map(({ id, last4, amount, giftcard }) => (
          <Tooltip
            key={id}
            message={`Original balance\n${formatCurrencyRounded(
              giftcard.balance,
              giftcard.currency,
            )}`}
          >
            <li>
              {last4} ({formatCurrencyRounded(amount, giftcard.currency)})
              <Link
                data-id={id}
                onClick={this.onClickRemoveGiftcard}
                type="button"
              >
                &times;
              </Link>
            </li>
          </Tooltip>
        ))}
      </ul>
    );
  }

  render() {
    const { record } = this.props;
    const { addingGiftcard } = this.state;
    const hasGiftcards = record.giftcards?.length > 0;

    return (
      <div className="payment-method-option giftcard-option">
        <div className="payment-method-option-container">
          <div className="payment-method-option-details">
            <IconPayment method="giftcard" />
            <div className="payment-method-option-name">Gift card</div>
          </div>
          {!addingGiftcard && (
            <Link className="link-button" onClick={this.onClickAddGiftcard}>
              Add gift card
            </Link>
          )}
        </div>
        {hasGiftcards && this.renderGiftcardTags()}
        {addingGiftcard && this.renderGiftcardInput()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftcardOption);
