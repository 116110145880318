import React from 'react';
import pt from 'prop-types';
import Popover from 'components/popover';

function Label({ label, labelRenderer, help, children, ...props }) {
  if (label === undefined && !children) {
    return null;
  }

  // use a label renderer if it exists
  const fieldLabel =
    typeof label === 'string' && labelRenderer ? labelRenderer(label) : label;

  return (
    <label {...props}>
      {fieldLabel || children}
      {help && <Popover message={help} location={location} openOnHover />}
    </label>
  );
}

Label.propTypes = {
  help: pt.oneOfType([pt.node, pt.object]),
  label: pt.node,
  labelRenderer: pt.func,
  children: pt.node,
};

export default React.memo(Label);
