import React, { Fragment } from 'react';
import classNames from 'classnames';
import pt from 'prop-types';

import { inflect } from 'utils';

import Icon from 'components/icon';
import Link from 'components/link';
import { Field } from 'components/form';
import { FadeInDownBounce } from 'components/transitions';

import ListStates from './list-states';

export default class ListCategory extends React.PureComponent {
  static propTypes = {
    item: pt.object,
    found: pt.object,
    foundSelf: pt.bool,
    foundChildren: pt.bool,
    foundParent: pt.bool,
    closedParent: pt.bool,
    version: pt.number,
    siblingId: pt.string,
    childId: pt.string,
    childrenCount: pt.number,
    parentProductsCount: pt.number,
    hasNonDraggingChildren: pt.bool,

    onClickEditCategory: pt.func,
    renderCategories: pt.func,
    onDragStart: pt.func,
    onDragEnd: pt.func,
    onDragEnter: pt.func,
    onDragLeave: pt.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      closed: props.found.query ? true : false,
      droppable: false,
      dragging: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.found.query !== prevProps.found.query) {
      this.setState({ closed: this.props.found.query ? true : false });
    }
  }

  onClickExpand = () => {
    this.setState((state) => ({ closed: !state.closed }));
  };

  /** @param {React.DragEvent} event */
  onDragStart = (event) => {
    event.stopPropagation();

    const { onDragStart } = this.props;

    if (onDragStart) {
      onDragStart(event);
    }

    this.setState({ dragging: true });
  };

  /** @param {React.DragEvent} event */
  onDragEnd = (event) => {
    event.stopPropagation();

    const { onDragEnd } = this.props;

    if (onDragEnd) {
      onDragEnd(event);
    }

    this.setState({ dragging: false });
  };

  /** @param {React.DragEvent} event */
  onDragEnter = (event) => {
    if (event.target === event.currentTarget) {
      this.setState({ droppable: true });
    }
  };

  /** @param {React.DragEvent} event */
  onDragLeave = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({ droppable: false });
    }
  };

  render() {
    const {
      item,
      found,
      foundSelf,
      foundChildren,
      foundParent,
      closedParent,
      siblingId,
      childId,
      childrenCount,
      parentProductsCount,
      hasNonDraggingChildren,
      onClickEditCategory,
      renderCategories,
      onDragEnter,
      onDragLeave,
    } = this.props;

    const { closed, dragging, droppable } = this.state;

    const { id, name, children = [], active } = item;

    return (
      <li
        data-categoryid={id}
        data-siblingid={siblingId}
        data-childid={childId}
        className="category-li"
        draggable
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
      >
        {dragging ? (
          <div className="category-item-placeholder">
            {/* This function is needed to prevent the active/inactive category value from being reset when it is moved within the list. */}
            <ListStates item={item} />
          </div>
        ) : (
          <div className="category-item-wrapper">
            <div
              id={`category-${id}`}
              className={classNames(
                'category-item',
                found.query
                  ? found.index.has(id)
                    ? 'found'
                    : 'not-found'
                  : '',
                children.length > 0 ? 'with-children' : '',
              )}
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
            >
              <span draggable={false} className="category-item-detail">
                <Link
                  to={`/categories/${id}`}
                  draggable={false}
                  data-id={id}
                  onClick={onClickEditCategory}
                >
                  <span className="category-item-name">{name}</span>
                </Link>

                <span className="category-item-products">
                  {`${inflect(parentProductsCount, 'products')} ${
                    childrenCount > 0
                      ? `(${childrenCount} in subcategories)`
                      : ''
                  }`}
                </span>
              </span>

              <span className="category-info">
                <span className="category-info-detail category-active">
                  <Field
                    type="toggle"
                    name={`${id}.active`}
                    label="Active"
                    labelOff="Inactive"
                    defaultChecked={active}
                  />
                </span>

                <span className="category-info-detail category-handle">
                  <Icon type="drag-vertical" />
                </span>
              </span>

              {!closedParent && droppable && (
                <Fragment>
                  <div
                    data-categoryid={id}
                    data-sibling={true}
                    className="Droppable sibling left"
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                  />

                  <div
                    data-categoryid={id}
                    data-sibling={true}
                    className="Droppable sibling right"
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                  />

                  <div
                    data-categoryid={id}
                    data-child={true}
                    className="Droppable child"
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                  />
                </Fragment>
              )}
            </div>

            {children.length > 0 && (
              <button
                data-categoryid={id}
                className={classNames(
                  'category-item-expand',
                  found.query
                    ? foundChildren
                      ? 'open'
                      : closed
                      ? 'closed'
                      : 'open'
                    : closed
                    ? 'closed'
                    : 'open',
                )}
                onClick={this.onClickExpand}
                type="button"
              >
                <Icon type="chevron" />
              </button>
            )}

            <div className={`category-dropzone child ${id}`}>
              <div className="outline" />
            </div>

            <FadeInDownBounce
              active={found.query ? foundChildren || !closed : !closed}
              transitionAppear={false}
              duration={250}
            >
              {renderCategories(
                children,
                found,
                foundSelf || foundParent,
                closed || closedParent,
              )}
            </FadeInDownBounce>

            {hasNonDraggingChildren && (
              <div className="category-children-placeholder" />
            )}

            <div className={`category-dropzone sibling ${id}`}>
              <div className="outline" />
            </div>
          </div>
        )}
      </li>
    );
  }
}
