import api from 'services/api';
import { activityQuery } from 'utils/activity';

export const ACTIVITY_FETCH = 'activity/fetch';
export const ACTIVITY_CLEAR = 'activity/clear';
export const ACTIVITY_LOADING = 'activity/loading';

export default {
  fetch(model, record) {
    return {
      type: ACTIVITY_FETCH,
      payload: activityQuery(model, record, api),
      meta: { id: record.id },
    };
  },

  addNote(note) {
    return (dispatch, getState) => {
      const { user, activity } = getState();
      const { results, count } = activity;
      const nextResults = [{ type: 'note', ...note, user }, ...results];
      dispatch({
        type: ACTIVITY_FETCH,
        payload: { results: nextResults, count: count + 1 },
      });
    };
  },

  loading(payload) {
    return { type: ACTIVITY_LOADING, payload };
  },

  clear() {
    return {
      type: ACTIVITY_CLEAR,
    };
  },
};

export const initialState = {
  fetched: false,
  loading: false,
  recordId: null,
  results: [],
  count: 0,
  page: 1,
};

export function reducer(state: Object = initialState, action: Object) {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case ACTIVITY_FETCH:
      if (!action.payload || action.payload.error) {
        return state;
      }
      if (action.meta && state.recordId && action.meta.id !== state.recordId) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
        fetched: true,
        recordId: action.meta ? action.meta.id : state.recordId,
      };
    case ACTIVITY_CLEAR:
      return initialState;
    case ACTIVITY_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
