import React, { Fragment } from 'react';
import pt from 'prop-types';
import { get, set, pick, find, findIndex } from 'lodash';

import { confirmRouteLeave, confirmPageLeave } from 'utils/container';
import { combineDateTime } from 'utils';
import { hasLocalizedContent } from 'utils/content';
import { getVariantByOptions } from 'utils/product';
import { getCleanItemOptions } from 'utils/order';

import {
  subscriptionTitle,
  subscriptionPriceDesc,
  subscriptionHasOrderItems,
} from 'utils/subscription';

import ActivityFeed from 'containers/ActivityFeed';

import { View } from 'components/view';
import { Form, Field, TabView } from 'components/form';
import Modal from 'components/modal';
import Side from 'components/view/side';
import DateTime from 'components/date-time';
import PrevButton from 'components/button/prev';
import NextButton from 'components/button/next';
import DropdownButton from 'components/button/dropdown';
import CustomerAvatar from 'components/customer/avatar';
import OrderItemsEdit from 'components/pages/order/items-edit';
import OrderItemsAdd from 'components/pages/order/items-add';
import OrderCustomer from 'components/pages/order/customer';
import OrderCustomerEdit from 'components/pages/order/customer-edit';
import OrderDiscountsEdit from 'components/pages/order/discounts-edit';
import OrderShippingEdit from 'components/pages/order/shipping-edit';
import OrderPaymentEdit from 'components/pages/order/payment-edit';
import OrderContent from 'components/pages/order/content';
import OrderNotes from 'components/pages/order/notes';
import OrderAddressEdit from 'components/pages/order/address-edit';
import CurrencyPicker from 'components/locale/currency';
import ManageAddresses from 'components/manage-addresses';
import WithViewPreferences from 'containers/WithViewPreferences';
import ContentRecordTab, {
  contentRecordSubmitAction,
  contentRecordSubmitChangeHandlers,
} from 'components/content/record-tab';

import SubscriptionItems from './items';
import SubscriptionStatus from './status';
import SubscriptionPayments from './payments';
import SubscriptionInvoiceNext from './invoice-next';
import SubscriptionOrderNext from './order-next';
import SubscriptionPlanEdit from './plan-edit';

import './subscription.scss';

function getOrderPeriodLabel(record) {
  const hasSeparateSchedule = Boolean(record.order_schedule?.interval);

  const schedule = hasSeparateSchedule
    ? record.order_schedule
    : record.billing_schedule;

  const order_period_end = hasSeparateSchedule
    ? record.date_order_period_end
    : record.date_period_end;

  if (
    order_period_end &&
    schedule?.limit > 0 &&
    schedule.limit <= schedule.limit_current
  ) {
    return 'Order period end';
  }

  return `${record.orders?.count > 0 ? 'Next' : 'First'} order`;
}

function getBillingPeriodLabel(record) {
  const { billing_schedule } = record;

  if (
    record.date_period_end &&
    billing_schedule?.limit > 0 &&
    billing_schedule.limit <= billing_schedule.limit_current
  ) {
    return 'Invoice period end';
  }

  return `${record.invoices?.count > 0 ? 'Next' : 'First'} invoice`;
}

export default class SubscriptionView extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    related: pt.object,
    location: pt.object,
    prev: pt.object,
    next: pt.object,
    discounts: pt.object,
    edited: pt.bool,
    loading: pt.bool,
    lookup: pt.object,
    content: pt.object,
    settings: pt.object,
    suggestedAddresses: pt.array,

    fetchRecord: pt.func,
    onShippingGetRates: pt.func,
    onChangeCurrency: pt.func,
    getItemPrice: pt.func,
    onPaymentEdit: pt.func,
    onPaymentRetry: pt.func,
    onAddItem: pt.func,
    onEdited: pt.func,
    onEditValues: pt.func,
    onEditItems: pt.func,
    onDelete: pt.func,
    onLoadAddresses: pt.func,
    onLoadBilling: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
    notifyError: pt.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      edited: false,
      showAddItem: false,
      showEditItems: false,
      showEditPlan: false,
      showEditCustomer: false,
      showDiscountsEdit: false,
      showPaymentEdit: false,
      showEditInvoiceDate: false,
      showEditOrderDate: false,
      showShippingEdit: false,
      addItemProduct: null,
      editAddItem: null,
      editAddIndex: null,
      editItems: [],
      modalLoading: false,
      showEditAddress: false,
      showManageAddresses: false,
    };
    this.onClickManageAddresses = this.onClickManageAddresses.bind(this);
  }

  componentDidMount() {
    confirmRouteLeave(this);
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  onClickDelete = (event) => {
    event.preventDefault();

    this.context.openModal('ConfirmDelete', {
      title: 'this subscription',
      confirmInput: {
        description: "Enter the customer's email address to confirm",
        value: this.props.record.account.email,
      },
      onConfirm: () => {
        this.props.onDelete();
      },
    });
  };

  onClickPause = (event) => {
    event.preventDefault();

    const { record: subscription } = this.props;

    const pauseOrResume = subscription.paused ? 'Resume' : 'Pause';

    this.context.openModal('PauseSubscription', {
      subscription,
      pauseOrResume,
      onPauseSubscription: this.onPauseSubscription,
    });
  };

  onClickCancel = (event) => {
    event.preventDefault();

    this.context.openModal('Confirm', {
      title: `Cancel subscription`,
      message: (
        <p>
          Are you sure you want to cancel this subscription? This action
          can&apos;t be undone.
        </p>
      ),
      action: 'Cancel subscription',
      actionType: 'danger',
      onConfirm: () => {
        this.props.onEditValues({
          canceled: true,
        });
      },
    });
  };

  onClickPrint = (event) => {
    event.preventDefault();

    window.print();
  };

  onClickAddItem = (event) => {
    event && event.preventDefault();
    if (this.state.showAddItem && this.props.edited) {
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.props.onEdited(this.state.editAddEdited);
          this.setState({ showAddItem: false });
        },
      });
    } else {
      this.setState({
        showAddItem: !this.state.showAddItem,
        addItemProduct: null,
        editAddItem: null,
        editAddIndex: null,
        editAddEdited: this.state.showEditItems ? this.props.edited : false,
      });
    }
  };

  onClickEditItems = (event) => {
    event.preventDefault();
    this.setState({
      showEditItems: !this.state.showEditItems,
      editItems: (this.props.record.items || []).map((item) => ({
        ...item,
      })),
    });
  };

  onClickEditSingleItem = (event) => {
    event.preventDefault();
    const { id, index } = event.currentTarget.dataset;
    const editItems = this.state.showEditItems
      ? this.state.editItems
      : this.props.record.items || [];
    this.setState({
      showAddItem: true,
      addItemProduct: null,
      editAddItem: id
        ? find(editItems, (item, i) => item.id === id)
        : editItems[index],
      editAddIndex: id ? null : index,
    });
  };

  onCloseEditItems = (event) => {
    if (this.props.edited) {
      event.preventDefault();
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.onClickEditItems(event);
        },
      });
    } else {
      this.onClickEditItems(event);
    }
  };

  onClickEditPlan = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      showEditPlan: !this.state.showEditPlan,
      addItemProduct: null,
      editAddItem: null,
      editAddIndex: null,
    });
  };

  onCloseEditPlan = (event) => {
    if (this.props.edited) {
      if (event) {
        event.preventDefault();
      }

      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.onClickEditPlan(event);
        },
      });
    } else {
      this.onClickEditPlan(event);
    }
  };

  onClickEditCustomer = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showEditCustomer: !state.showEditCustomer,
    }));
  };

  onClickDiscountsEdit = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showDiscountsEdit: !state.showDiscountsEdit,
    }));
  };

  onClickPaymentEdit = async (event) => {
    event.preventDefault();

    if (!this.state.showPaymentEdit) {
      await this.props.onLoadBilling();
    }

    this.setState((state) => ({
      showPaymentEdit: !state.showPaymentEdit,
    }));
  };

  onClickPaymentRetry = (event) => {
    event.preventDefault();

    if (!this.props.loading) {
      const { recordId, model, amount } = event.currentTarget.dataset;

      this.props.onPaymentRetry(recordId, model, amount);
    }
  };

  onChangeAddProductLookup = (_event, value) => {
    this.setState({
      addItemProduct: value || null,
    });
  };

  async onClickManageAddresses(event) {
    event?.preventDefault();

    if (!this.state.showManageAddresses) {
      await this.props.onLoadAddresses();
    }

    this.setState({
      showManageAddresses: !this.state.showManageAddresses,
    });
  }

  async updateStateModalLoading(handler, state = {}) {
    this.setState({ modalLoading: true });

    const result = await handler();

    if (result) {
      this.setState({
        ...state,
        modalLoading: false,
      });
    } else {
      this.setState({ modalLoading: false });
    }
  }

  onSubmitAddItem = async (values) => {
    const { addItemProduct, editAddItem, editAddIndex } = this.state;

    let addItem;
    if (values.description) {
      addItem = { ...values };
    } else {
      const itemProduct = editAddItem ? editAddItem.product : addItemProduct;

      addItem = {
        ...values,
        quantity: values.quantity || 1,
        product: itemProduct,
        product_id: itemProduct.id,
        options: getCleanItemOptions(itemProduct, values.options),
      };

      if (values.bundle_options) {
        addItem.bundle_items = itemProduct.bundle_items.map((item) => ({
          ...item,
          options: getCleanItemOptions(
            item.product,
            values.bundle_options[item.product_id],
            item.options,
          ),
        }));
      }
    }

    if (addItem.set_price === null) {
      this.setState({ modalLoading: true });

      const pricedItem = await this.props.getItemPrice(
        pick(addItem, [
          'id',
          'product_id',
          'options',
          'quantity',
          'purchase_option',
        ]),
      );

      if (pricedItem && !pricedItem.errors) {
        addItem.price = addItem.set_price = pricedItem.price;
      }
    } else {
      addItem.price = addItem.set_price;
    }

    if (this.state.showEditItems) {
      addItem.variant = getVariantByOptions(addItem.product, addItem.options);

      const editItems = [...this.state.editItems];

      if (addItem.id) {
        editItems[findIndex(editItems, { id: addItem.id })] = addItem;
      } else if (editAddIndex !== null) {
        editItems[editAddIndex] = addItem;
      } else {
        editItems.push(addItem);
      }

      this.props.onEdited(true);

      this.setState({
        showAddItem: false,
        addItemProduct: null,
        editItems,
        modalLoading: false,
      });
    } else {
      this.updateStateModalLoading(() => this.props.onAddItem(addItem), {
        showAddItem: false,
        addItemProduct: null,
      });
    }
  };

  onSubmitEditItems = () => {
    this.updateStateModalLoading(
      () => this.props.onEditItems(this.state.editItems),
      {
        showEditItems: false,
        editItems: [],
      },
    );
  };

  onSubmitEditPlan = async (values) => {
    const result = await this.props.onEditValues({
      billing_schedule: '',
      order_schedule: '',
      ...values,
      ...values.purchase_option,
      options: undefined,
      product: undefined,
      variant: undefined,
      set_price: undefined,
      purchase_option: undefined,
      price: values.set_price,
      product_id: values.product.id,
      $set: {
        options: values.options,
      },
    });

    if (result) {
      this.setState({
        showEditPlan: false,
        addItemProduct: null,
      });
    }
  };

  onSubmitEditCustomer = (values) => {
    const { record } = this.props;

    this.updateStateModalLoading(
      () =>
        this.props.onEditValues({
          account: values.account,
          shipping: {
            ...(record.shipping || {}),
            ...values.shipping,
          },
          billing: {
            ...(record.billing || {}),
            ...values.billing,
          },
        }),
      {
        showEditCustomer: false,
      },
    );
  };

  onSubmitDiscountsEdit = (values) => {
    this.updateStateModalLoading(
      () =>
        this.props.onEditValues({
          coupon_code: values.coupon_code,
        }),
      {
        showDiscountsEdit: false,
      },
    );
  };

  onSubmitPaymentEdit = (values) => {
    this.updateStateModalLoading(
      () =>
        this.props.onPaymentEdit({
          billing: values.billing,
        }),
      {
        showPaymentEdit: false,
      },
    );
  };

  onSubmitNotes = async (values) => {
    await this.props.onEditValues({
      notes: values.notes.trim(),
    });
  };

  onClickEditInvoiceDate = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showEditInvoiceDate: !state.showEditInvoiceDate,
    }));
  };

  onClickEditOrderDate = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showEditOrderDate: !state.showEditOrderDate,
    }));
  };

  onSubmitEditInvoiceDate = (values) => {
    const date_period_end = combineDateTime(
      values.date_period_end,
      values.date_period_end_time,
    );

    if (new Date(date_period_end) < new Date()) {
      this.context.notifyError('Next invoice date must be in the future');
      return;
    }

    this.updateStateModalLoading(
      () =>
        this.props.onEditValues({
          date_period_end,
        }),
      {
        showEditInvoiceDate: false,
      },
    );
  };

  onSubmitEditOrderDate = (values) => {
    const { cancel_at_end, date_period_end } = this.props.record;

    const date_order_cycle_start = combineDateTime(
      values.date_order_cycle_start,
      values.date_order_cycle_start_time,
    );

    if (new Date(date_order_cycle_start) < new Date()) {
      this.context.notifyError('Next order date must be in the future');
      return;
    }

    if (
      cancel_at_end &&
      new Date(date_order_cycle_start) > new Date(date_period_end)
    ) {
      this.context.notifyError(
        'Next order date must occur before the subscription is canceled',
      );
      return;
    }

    this.updateStateModalLoading(
      () =>
        this.props.onEditValues({
          date_order_cycle_start,
        }),
      {
        showEditOrderDate: false,
      },
    );
  };

  onClickRemoveEditItem = (event) => {
    event.preventDefault();

    const { index } = event.currentTarget.dataset;
    const editItems = [...this.state.editItems];

    if (editItems[index].id || editItems[index].removed) {
      editItems[index].removed = !editItems[index].removed;
    } else {
      editItems.splice(index, 1);
    }

    this.props.onEdited(true);

    this.setState({ editItems });
  };

  onClickRemoveEditSingleItem = (event) => {
    if (event) {
      event.preventDefault();
    }

    const { id, index } = event.currentTarget.dataset;

    if (this.state.showEditItems) {
      const editItems = [...this.state.editItems];

      if (id) {
        editItems[findIndex(editItems, { id })].removed = true;
      } else {
        editItems.splice(index, 1);
      }

      this.props.onEdited(true);

      this.setState({
        showAddItem: false,
        editItems,
      });
    } else {
      this.context.openModal('Confirm', {
        title: `Remove item`,
        message: (
          <p>
            Are you sure you want to remove this item? This action can&apos;t be
            undone.
          </p>
        ),
        action: 'Remove',
        actionType: 'danger',
        onConfirm: () => {
          const removedItem = { ...this.state.editAddItem, removed: true };

          this.updateStateModalLoading(
            () => this.props.onEditItems([removedItem]),
            {
              showAddItem: false,
            },
          );
        },
      });
    }
  };

  onChangeEditItemInput = (event, value) => {
    const editItems = [...this.state.editItems];

    set(editItems, event.target.name, value);

    const index = event.target.name.split('.')[0];

    const editItem = editItems[index];

    if (editItem.quantity <= 0 && editItem.quantity.trim() !== '') {
      editItem.quantity = '0';
      editItem.removed = true;
    } else {
      const now = Date.now();

      editItem.pricingTime = now;

      clearTimeout(this.editItemPriceTimer);

      this.editItemPriceTimer = setTimeout(() => {
        this.props
          .getItemPrice(
            pick(editItem, [
              'id',
              'product_id',
              'options',
              'quantity',
              'purchase_option',
            ]),
          )
          .then((pricedItem) => {
            if (this.state.showEditItems && pricedItem && !pricedItem.errors) {
              const postEditItems = [...this.state.editItems];
              if (get(postEditItems[index], 'pricingTime') === now) {
                postEditItems[index].calc_price = pricedItem.price;
                this.setState({ editItems: postEditItems });
              }
            }
          });
      }, 250);
    }

    this.setState({ editItems });
  };

  onClickShippingEdit = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showShippingEdit: !state.showShippingEdit,
    }));
  };

  onSubmitShippingEdit = (values) => {
    this.updateStateModalLoading(
      () =>
        this.props.onEditValues({
          shipping: values.shipping,
        }),
      {
        showShippingEdit: false,
      },
    );
  };

  onClickShippingGetRates = (event) => {
    event.preventDefault();

    this.props.onShippingGetRates();
  };

  onPauseSubscription = (paused, date_pause_end) => {
    return this.props.onEditValues({
      paused,
      date_pause_end,
    });
  };

  onClickEditAddress = (event) => {
    event.preventDefault();
    this.setState((state) => ({
      showEditAddress: !state.showEditAddress,
    }));
  };

  onSubmitEditAddress = (values) => {
    this.setState((state) => ({
      showEditAddress: !state.showEditAddress,
    }));
    if (values.account.id) {
      return this.props.onEditValues({
        account_id: values.account.id,
        shipping: values.account.shipping,
        billing: values.account.billing,
        account_info_saved: true,
      });
    } else {
      return this.props.onEditValues({
        account: values.account,
        shipping: values.shipping,
        billing: values.billing,
        account_info_saved: true,
      });
    }
  };

  render() {
    const {
      record,
      prev = {},
      next = {},
      lookup,
      content,
      settings,
      location,
      loading: loadingProp,
      suggestedAddresses,
      onChangeCurrency,
      fetchRecord,
    } = this.props;

    const { modalLoading } = this.state;

    const loading = loadingProp || modalLoading;

    const isEditable = !record.canceled;

    const isCancelable = !record.canceled;

    // TODO: make this harder?
    const isDeletable = true;

    const hasShipmentItems =
      record.product && subscriptionHasOrderItems(record, record.product);

    const shipping =
      record.shipping || (record.account && record.account.shipping);

    const billing =
      record.billing || (record.account && record.account.billing);

    const nextOrderDate = record.order_schedule?.interval
      ? new Date(record.date_order_period_start) > new Date()
        ? record.date_order_period_start
        : record.date_order_period_end
      : record.date_period_end;

    return (
      <div className="subscription-view order-view view-detail-full">
        <WithViewPreferences
          {...this.props}
          tabs={[]}
          model="subscriptions"
          id="edit"
          type="record"
        >
          <View
            detail={true}
            submitting={loadingProp}
            uri="/subscriptions"
            devtools={{
              model: 'subscriptions',
              record,
            }}
            withPreferences={true}
            sectionTitle="Subscriptions"
            headerTitle={subscriptionTitle(record)}
            headerImage={
              record.account && (
                <CustomerAvatar account={record.account} size={60} />
              )
            }
            headerSubtitle={
              <div className="subscription-view-subtitle">
                {subscriptionPriceDesc(record)}
                <CurrencyPicker
                  currency={record.currency}
                  onChange={onChangeCurrency}
                  editable={isEditable}
                />
              </div>
            }
            headerActions={[
              {
                component: (
                  <span key="1" className="button-group">
                    <PrevButton
                      to={`/subscriptions/${prev.id}`}
                      type="secondary"
                      disabled={!prev.id}
                    />
                    <NextButton
                      to={`/subscriptions/${next.id}`}
                      type="secondary"
                      disabled={!next.id}
                    />
                  </span>
                ),
              },
              {
                isActionMenu: true,
                component: (
                  <DropdownButton
                    key="2"
                    anchor="right"
                    anchorPosition={49}
                    type="secondary"
                    items={[
                      isEditable && (
                        <button key="0" onClick={this.onClickEditPlan}>
                          Edit plan
                        </button>
                      ),
                      isEditable &&
                        (record.items && record.items.length > 0 ? (
                          <button key="1" onClick={this.onClickEditItems}>
                            Edit invoice items
                          </button>
                        ) : (
                          <button key="1" onClick={this.onClickAddItem}>
                            Add invoice item
                          </button>
                        )),

                      !record.canceled && (
                        <button key="2" onClick={this.onClickPause}>
                          {`${record.paused ? 'Resume' : 'Pause'} subscription`}
                        </button>
                      ),
                      isCancelable && (
                        <button key="3" onClick={this.onClickCancel}>
                          Cancel subscription
                        </button>
                      ),
                      <button key="4" onClick={this.onClickPrint}>
                        Print subscription
                      </button>,
                    ]}
                  >
                    Actions
                  </DropdownButton>
                ),
              },
              this.state.contentRecordTab &&
                contentRecordSubmitAction(this.state.contentRecordTab),
            ]}
            extraActions={[
              isDeletable && {
                label: 'Delete subscription',
                onClick: this.onClickDelete,
                className: 'danger',
                delete: true,
              },
            ]}
            localized={hasLocalizedContent(
              this.props.content.models,
              'subscriptions',
            )}
          >
            <TabView value={location.query.tab} isDefault>
              <fieldset className="last-child">
                <div className="view-body-section-group">
                  <table className="order-kpi-table">
                    <tbody>
                      <tr>
                        <td>
                          <div className="order-kpi-label">Created</div>

                          <div className="order-kpi-value">
                            <DateTime
                              value={record.date_created}
                              format="shortExact"
                            />
                          </div>
                        </td>

                        <td>
                          <div className="order-kpi-label">Status</div>

                          <div className="order-kpi-value">
                            <SubscriptionStatus record={record} />
                          </div>
                        </td>

                        {record.canceled && record.cancel_at_end ? (
                          <td>
                            <div className="order-kpi-label">Period end</div>
                            <div className="order-kpi-value">
                              <button
                                className="as-link"
                                onClick={this.onClickEditInvoiceDate}
                                type="button"
                              >
                                <DateTime
                                  value={record.date_period_end}
                                  format="shortExact"
                                />
                              </button>
                            </div>
                          </td>
                        ) : (
                          <Fragment>
                            {record.ordering && (
                              <td>
                                <div className="order-kpi-label">
                                  {getOrderPeriodLabel(record)}
                                </div>

                                <div className="order-kpi-value">
                                  <SubscriptionOrderNext
                                    record={record}
                                    onClickPause={this.onClickPause}
                                    onClickEditOrderDate={
                                      this.onClickEditOrderDate
                                    }
                                    format="shortExact"
                                  />
                                </div>
                              </td>
                            )}

                            {(!record.ordering ||
                              (record.order_schedule &&
                                record.billing_schedule)) && (
                              <td>
                                <div className="order-kpi-label">
                                  {getBillingPeriodLabel(record)}
                                </div>

                                <div className="order-kpi-value">
                                  <SubscriptionInvoiceNext
                                    record={record}
                                    onClickPause={this.onClickPause}
                                    onClickEditInvoiceDate={
                                      this.onClickEditInvoiceDate
                                    }
                                    format="shortExact"
                                  />
                                </div>
                              </td>
                            )}
                          </Fragment>
                        )}
                      </tr>
                    </tbody>
                  </table>

                  <SubscriptionItems
                    record={record}
                    settings={settings}
                    discounts={this.props.discounts}
                    editable={isEditable}
                    loading={loading}
                    onClickEditItems={this.onClickEditItems}
                    onClickEditSingleItem={this.onClickEditSingleItem}
                    onClickEditPlan={this.onClickEditPlan}
                    onClickAddItem={this.onClickAddItem}
                    onClickDiscountsEdit={this.onClickDiscountsEdit}
                    onClickShippingEdit={this.onClickShippingEdit}
                    hasShipmentItems={hasShipmentItems}
                  />

                  <OrderContent
                    {...this.props}
                    zone="details"
                    collection="subscriptions"
                    record={record}
                    onSubmit={this.props.onEditValues}
                  />

                  <SubscriptionPayments
                    record={record}
                    loading={loading}
                    onClickPaymentRetry={this.onClickPaymentRetry}
                  />

                  {record.notes && (
                    <OrderNotes
                      deprecated
                      label="Subscription notes"
                      record={record}
                      onSubmit={this.onSubmitNotes}
                    />
                  )}

                  <OrderContent
                    {...this.props}
                    zone="content"
                    collection="subscriptions"
                    record={record}
                    onSubmit={this.props.onEditValues}
                  />

                  <ActivityFeed
                    model="subscriptions"
                    record={record}
                    label="Subscription activity"
                  />
                </div>
              </fieldset>
            </TabView>

            <ContentRecordTab
              {...this.props}
              collection="subscriptions"
              view="edit"
              form={true}
              {...contentRecordSubmitChangeHandlers(
                this,
                this.props.onEditValues,
              )}
            />

            <Side>
              <OrderCustomer
                isSubscription
                record={{
                  account: record.account,
                  billing: billing,
                  shipping: hasShipmentItems ? shipping : undefined,
                }}
                content={content}
                settings={settings}
                addable={false}
                onClickEditCustomer={this.onClickEditCustomer}
                onClickPaymentEdit={this.onClickPaymentEdit}
                onClickEditAddress={this.onClickEditAddress}
                onClickManageAddresses={this.onClickManageAddresses}
              />
            </Side>
          </View>
        </WithViewPreferences>

        {this.state.showEditPlan && (
          <SubscriptionPlanEdit
            record={record}
            lookup={lookup}
            loading={loading}
            onEdited={this.props.onEdited}
            addItemProduct={this.state.addItemProduct}
            onSubmitEditPlan={this.onSubmitEditPlan}
            onClickEditPlan={this.onClickEditPlan}
            onCloseEditPlan={this.onCloseEditPlan}
            onChangeAddProductLookup={this.onChangeAddProductLookup}
            getItemPrice={this.props.getItemPrice}
          />
        )}

        {this.state.showEditItems && (
          <OrderItemsEdit
            subscription={true}
            record={record}
            loading={loading}
            editItems={this.state.editItems}
            onEdited={this.props.onEdited}
            onSubmitEditItems={this.onSubmitEditItems}
            onClickEditItems={this.onClickEditItems}
            onClickEditSingleItem={this.onClickEditSingleItem}
            onClickRemoveEditItem={this.onClickRemoveEditItem}
            onClickAddItem={this.onClickAddItem}
            onCloseEditItems={this.onCloseEditItems}
            onChangeEditItemInput={this.onChangeEditItemInput}
            onClickShippingEdit={this.onClickShippingEdit}
            getItemPrice={this.props.getItemPrice}
          />
        )}

        {this.state.showAddItem && (
          <OrderItemsAdd
            subscription={true}
            lookup={lookup}
            loading={loading}
            addItemProduct={this.state.addItemProduct}
            onSubmitAddItem={this.onSubmitAddItem}
            onClickAddItem={this.onClickAddItem}
            onChangeAddProductLookup={this.onChangeAddProductLookup}
            onClickRemoveEditSingleItem={this.onClickRemoveEditSingleItem}
            getItemPrice={this.props.getItemPrice}
            editAddItem={this.state.editAddItem}
            editAddIndex={this.state.editAddIndex}
            onEdited={this.state.editAddItem && this.props.onEdited}
            currency={record.currency}
          />
        )}

        {this.state.showEditCustomer && (
          <OrderCustomerEdit
            record={record}
            loading={loading}
            settings={settings}
            billing={billing}
            shipping={shipping}
            showUseDefaultAddressCheckbox
            onSubmitEditCustomer={this.onSubmitEditCustomer}
            onClickEditCustomer={this.onClickEditCustomer}
            hideShipping={!hasShipmentItems}
            suggestedAddresses={suggestedAddresses}
          />
        )}

        {this.state.showDiscountsEdit && (
          <OrderDiscountsEdit
            record={record}
            loading={loading}
            lookup={lookup}
            discounts={this.props.discounts}
            promotions={false}
            onSubmitDiscountsEdit={this.onSubmitDiscountsEdit}
            onClickDiscountsEdit={this.onClickDiscountsEdit}
          />
        )}

        {this.state.showShippingEdit && (
          <OrderShippingEdit
            isSubscription
            record={{ ...record, shipping }}
            loading={loading}
            settings={settings}
            onSubmitShippingEdit={this.onSubmitShippingEdit}
            onClickShippingEdit={this.onClickShippingEdit}
            onClickShippingGetRates={this.onClickShippingGetRates}
          />
        )}

        {this.state.showPaymentEdit && (
          <OrderPaymentEdit
            loading={loading}
            fetchRecord={fetchRecord}
            onSubmitPaymentEdit={this.onSubmitPaymentEdit}
            onClickPaymentEdit={this.onClickPaymentEdit}
          />
        )}

        {this.state.showEditInvoiceDate && (
          <Form onSubmit={this.onSubmitEditInvoiceDate} autoFocus={true}>
            <Modal
              title={
                record.cancel_at_end
                  ? `Edit ${record.trial ? 'trial end' : 'cancel'} date`
                  : 'Edit next invoice date'
              }
              actions={[{ label: 'Save', type: 'submit' }]}
              width={550}
              onClose={this.onClickEditInvoiceDate}
            >
              <fieldset>
                {record.cancel_at_end ? (
                  <p>
                    The subscription will end at the specified date and time.
                  </p>
                ) : (
                  <p>
                    The next invoice will be issued at the specified date and
                    time. Changing this date won&apos;t affect the invoice
                    amount.
                  </p>
                )}

                <div className="row">
                  <Field
                    type="date"
                    name="date_period_end"
                    defaultValue={record.date_period_end}
                    required={true}
                    className="span2"
                  />

                  <Field
                    type="time"
                    name="date_period_end_time"
                    defaultValue={record.date_period_end}
                    required={true}
                    className="span2"
                  />
                </div>
              </fieldset>
            </Modal>
          </Form>
        )}

        {this.state.showEditOrderDate && (
          <Form onSubmit={this.onSubmitEditOrderDate} autoFocus={true}>
            <Modal
              title="Edit next order date"
              actions={[{ label: 'Save', type: 'submit' }]}
              width={550}
              onClose={this.onClickEditOrderDate}
            >
              <fieldset>
                <p>
                  The next order will be created at the specified date and time.
                  Changing this won&apos;t affect the next invoice date or
                  amount.
                </p>

                <div className="row">
                  <Field
                    type="date"
                    name="date_order_cycle_start"
                    defaultValue={nextOrderDate}
                    required={true}
                    className="span2"
                  />

                  <Field
                    type="time"
                    name="date_order_cycle_start_time"
                    defaultValue={nextOrderDate}
                    required={true}
                    className="span2"
                  />
                </div>
              </fieldset>
            </Modal>
          </Form>
        )}

        {this.state.showEditAddress && (
          <OrderAddressEdit
            record={record}
            loading={loading}
            shipping={record.shipping}
            onSubmitEditAddress={this.onSubmitEditAddress}
            onClickEditAddress={this.onClickEditAddress}
            onClickEditShipping={this.onClickShippingEdit}
            suggestedAddresses={suggestedAddresses}
          />
        )}

        {this.state.showManageAddresses && (
          <ManageAddresses
            fetchRecord={fetchRecord}
            loading={loading}
            onClickManageAddresses={this.onClickManageAddresses}
          />
        )}
      </div>
    );
  }
}
