import React from 'react';
import Image from 'components/loading/image';
import './panel.scss';

export default class SingleSignupPagePanel extends React.PureComponent {
  render() {
    return (
      <div className="panel">
        <div className="panel_gradient"></div>
        <div className="panel_image">
          <Image src={require('./illustrations/screen.png')} />
        </div>
        <div className="panel_content">
          <h3>Ecommerce without limits</h3>
          <p>
            Explore Swell's power and flexibility with a 14-day free trial
            <br />- no credit card required.
          </p>
        </div>
      </div>
    );
  }
}
