/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import pt from 'prop-types';
import { reduce } from 'lodash';

import Modal from 'components/modal';
import { Form, Field, Fieldgroup } from 'components/form';
import ContentFields from 'components/content/fields';
import { FadeIn } from 'components/transitions';
import PaymentMethodsEdit from 'components/payment/methods-edit';

import { parseCurrency, currencyValue } from 'utils';
import { hasLocalizedContent } from 'utils/content';
import {
  paymentMethodOptionsChargeable,
  paymentMethodDefault,
  isStripeCardGateway,
} from 'utils/payment';
import PayPalPaymentFlow from 'utils/payment-flows/PayPalPaymentFlow';
import PaymentFlow from 'utils/payment-flows/PaymentFlow';

function getDefaultPaymentAmount(record) {
  if (record.payment_balance < 0) {
    return -record.payment_balance;
  }

  if (record.payment_due > 0) {
    return record.payment_due;
  }

  return;
}
export default class OrderPaymentCharge extends React.PureComponent {
  static propTypes = {
    record: pt.object.isRequired,
    loading: pt.bool,
    settings: pt.object.isRequired,
    fetchRecord: pt.func.isRequired,
    onClickPaymentCharge: pt.func.isRequired,
    onSubmitPaymentCharge: pt.func.isRequired,
    content: pt.object.isRequired,
    sourceLabel: pt.string,
  };

  static contextTypes = {
    account: pt.object,
  };

  constructor(props) {
    super(props);

    const methodOptions = paymentMethodOptionsChargeable(props);
    const defaultMethod = paymentMethodDefault(props, methodOptions);

    this.state = {
      record: props.record,
      values: {
        amount: getDefaultPaymentAmount(props.record),
        method: defaultMethod,
      },
      methodOptions,
      defaultMethod,
      editing: false,
    };

    this.initPaymentFlows(methodOptions, props);

    this.paymentEditRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.record !== state.record) {
      const methodOptions = paymentMethodOptionsChargeable(props);
      const defaultMethod = paymentMethodDefault(props, methodOptions);

      return {
        record: props.record,
        values: {
          amount: getDefaultPaymentAmount(props.record),
          method: defaultMethod,
        },
        methodOptions,
        defaultMethod,
        editing: false,
      };
    }

    return null;
  }

  onClickEditCard = (editing) => {
    this.setState({ editing });
  };

  onSubmitPaymentCharge = (values) => {
    const { onSubmitPaymentCharge } = this.props;
    const editValues = this.paymentEditRef.current.wrappedInstance.value();

    if (!editValues) {
      return;
    }

    const { method, account_card_id } = editValues;
    const paymentData = {
      ...values,
      method,
    };

    if (method === 'card') {
      paymentData.account_card_id = account_card_id;
    }

    onSubmitPaymentCharge(paymentData);
  };

  calculateTrialAmount(values) {
    if (!values || !values.trial) {
      return {};
    }

    const {
      record: { payments = { results: [] } },
    } = this.props;

    const trialAmount = reduce(
      values.trial,
      (acc, value, id) => {
        if (value) {
          const payment = payments.results.find((payment) => payment.id === id);
          acc += payment ? payment.amount : 0;
        }
        return acc;
      },
      0,
    );

    return { amount: trialAmount };
  }

  initPaymentFlows(methodOptions, props) {
    this.paymentFlows = methodOptions.reduce((flows, methodOption) => {
      let flow;

      switch (methodOption.value) {
        case 'paypal':
          flow = new PayPalPaymentFlow(props.record);
          break;

        default:
          flow = new PaymentFlow(props.record);
          break;
      }

      flows[methodOption.value] = flow;

      return flows;
    }, {});
  }

  onChangeForm = (values) => {
    this.setState((state) => ({
      values: {
        ...state.values,
        ...values,
        ...this.calculateTrialAmount(values),
      },
    }));
  };

  renderUpcomingPayment = (payment) => {
    const {
      record: { currency },
    } = this.props;
    return (
      <Field
        key={payment.id}
        type="checkbox"
        name={`trial.${payment.id}`}
        label={
          <span>
            {payment.help}{' '}
            <span className="muted">
              ({currencyValue(payment.amount, currency, { isSymbol: true })})
            </span>
          </span>
        }
        defaultChecked={true}
      />
    );
  };

  renderActions() {
    const { record, settings, content } = this.props;
    const { values, defaultMethod } = this.state;
    const { currency, payments = { results: [] } } = record;
    const method = values.method || defaultMethod;
    const paymentFlow = this.paymentFlows[method];
    const isAmountLocked = !paymentFlow?.allowCustomAmount();
    const amountHelpText = isAmountLocked
      ? paymentFlow?.customAmountLockedMessage
      : '';

    const upcomingPayments = payments.results.filter((payment) =>
      Boolean(payment.upcoming),
    );
    // Temporarily disable trial payments
    const allowTrialPayments =
      false &&
      Boolean(
        upcomingPayments.length > 0 &&
          method === 'card' &&
          isStripeCardGateway(settings, record),
      );

    return (
      <fieldset>
        {allowTrialPayments ? (
          <Field
            type="toggle"
            name="show_trial"
            label="Charge upcoming payments"
            defaultChecked={true}
            readonly={true}
          />
        ) : (
          <Field
            type="hidden"
            name="show_trial"
            readonly={true}
            value={false}
          />
        )}

        {values.show_trial ? (
          <FadeIn>
            <Fieldgroup
              className="order-view-modal-payment-charge-trial-payments"
              label="Select items to charge"
              defaultActive={true}
              arrow={false}
              transition={false}
            >
              {upcomingPayments.length === 1
                ? this.renderUpcomingPayment(upcomingPayments[0])
                : upcomingPayments.map(this.renderUpcomingPayment)}
            </Fieldgroup>

            <Field type="hidden" name="amount" value={values.amount} />
          </FadeIn>
        ) : (
          <Field type="hidden" name="trial" value={null} />
        )}

        {!values.show_trial && (
          <div className="row">
            <Field
              type="currency"
              rounded={true}
              label="Amount"
              name="amount"
              required={true}
              defaultValue={values.amount}
              currency={currency}
              placeholder="0.00"
              className="span2"
              autoComplete="off"
              help={amountHelpText}
              disabled={isAmountLocked}
            />
          </div>
        )}

        <ContentFields
          {...this.props}
          zone="charge"
          models={content.models}
          collection="payments"
          record={values}
          values={undefined}
          currency={currency}
        />
      </fieldset>
    );
  }

  render() {
    const {
      record,
      loading,
      content,
      sourceLabel,
      fetchRecord,
      onClickPaymentCharge,
    } = this.props;
    const { values, methodOptions, editing } = this.state;
    const { currency } = record;
    const hasPaymentOptions = methodOptions.length > 0;

    return (
      <Form onSubmit={this.onSubmitPaymentCharge} onChange={this.onChangeForm}>
        <Modal
          title={`Charge ${sourceLabel || 'order'}`}
          className="order-view-modal-payment-charge"
          actions={[
            hasPaymentOptions
              ? {
                  label: `Charge ${
                    parseCurrency(values.amount) > 0
                      ? currencyValue(values.amount, currency, {
                          isSymbol: true,
                          precision: undefined,
                        })
                      : ''
                  }`,
                  type:
                    parseCurrency(values.amount) > 0 ? 'submit' : 'secondary',
                  disabled: editing,
                }
              : {
                  label: 'Close',
                  type: 'secondary',
                  onClick: onClickPaymentCharge,
                },
            { label: 'Cancel', type: 'cancel', onClick: onClickPaymentCharge },
          ]}
          width={700}
          cancel={false}
          loading={loading}
          onClose={onClickPaymentCharge}
          devtools={{ model: 'payments', zone: 'charge', console: false }}
          localized={hasLocalizedContent(content.models, 'payments', 'charge')}
        >
          {this.renderActions()}
          <PaymentMethodsEdit
            ref={this.paymentEditRef}
            fetchRecord={fetchRecord}
            requireAddress={false}
            onClickEditCard={this.onClickEditCard}
          />
        </Modal>
      </Form>
    );
  }
}
