import { get, find } from 'lodash';
import moment from 'moment';
import {
  billingTrialDaysLeft,
  billingTrialDaysLeftMessage,
  canGoLive,
  isSandbox,
  isOldSandbox,
  PLANS_BY_ID,
} from 'shared/billing';

import api from 'services/api';

import {
  executeAccountUpdateRecaptcha,
  executePlanCreateRecaptcha,
  executePlanUpdateRecaptcha,
  executePromoApplyRecaptcha,
} from 'utils/recaptcha';

import flash from './flash';

export const ACCOUNT_FETCH = 'account/fetch';
export const ACCOUNT_UPDATE = 'account/update';
export const ACCOUNT_FETCH_PLAN = 'account/fetchPlan';
export const ACCOUNT_CREATE_PLAN = 'account/createPlan';
export const ACCOUNT_UPDATE_PLAN = 'account/updatePlan';
export const ACCOUNT_CANCEL_PLAN = 'account/cancelPlan';
export const ACCOUNT_APPLY_PROMO = 'account/applyPromo';
export const ACCOUNT_FETCH_INVOICE = 'account/fetchInvoice';
export const ACCOUNT_FETCH_INFO = 'account/fetchInfo';

export default {
  fetch() {
    return {
      type: ACCOUNT_FETCH,
      payload: api.get('/account'),
    };
  },

  update(data) {
    return async (dispatch, getState) => {
      const { client } = getState();
      const recaptcha = await executeAccountUpdateRecaptcha(client);

      return dispatch({
        type: ACCOUNT_UPDATE,
        payload: api.put('/account', {
          ...data,
          recaptcha,
        }),
      });
    };
  },

  fetchPlan() {
    return (dispatch, getState) => {
      const { client, account } = getState();
      return dispatch({
        type: ACCOUNT_FETCH_PLAN,
        payload: api.get('/account/plan'),
        meta: { client, account },
      });
    };
  },

  createPlan(data) {
    return async (dispatch, getState) => {
      const { client } = getState();
      const recaptcha = await executePlanCreateRecaptcha(client);

      return dispatch({
        type: ACCOUNT_CREATE_PLAN,
        payload: api.post('/account/plan', {
          ...data,
          recaptcha,
        }),
      });
    };
  },

  updatePlan(data) {
    return async (dispatch, getState) => {
      const { client } = getState();
      const recaptcha = await executePlanUpdateRecaptcha(client);

      return dispatch({
        type: ACCOUNT_UPDATE_PLAN,
        payload: api.put('/account/plan', {
          ...data,
          recaptcha,
        }),
      });
    };
  },

  cancelPlan(data) {
    return {
      type: ACCOUNT_CANCEL_PLAN,
      payload: api.post('/account/cancel-plan', data),
    };
  },

  applyPromo(code) {
    return async (dispatch, getState) => {
      const { account, client } = getState();
      const recaptcha = await executePromoApplyRecaptcha(client);

      return dispatch({
        type: ACCOUNT_APPLY_PROMO,
        payload: api
          .put('/account/promo', { code, recaptcha })
          .then((result) => {
            if (!result && !account.promo) {
              dispatch(flash.error(`Promo code "${code}" is not valid 😞`));
            } else if (result) {
              dispatch(flash.success(`Promo code "${result.code}" applied`));
            }
            return result;
          }),
      });
    };
  },

  fetchInvoice(id) {
    return {
      type: ACCOUNT_FETCH_INVOICE,
      payload: api.get(`/account/invoices/${id}`),
    };
  },

  fetchInfo() {
    return {
      type: ACCOUNT_FETCH_INFO,
      payload: api.get('/account/info'),
    };
  },
};

export const initialState = {
  record: null,
  plan: null,
  planTrial: false,
  planCurrent: false,
  planSelected: false,
  planCanceled: false,
  paymentWarning: false,
  paymentRequired: false,
  promo: null,
  invoice: null,
  invoicesDue: [],
  isEnterprise: false,
  isSandbox: false,
  isOldSandbox: false,
  isExiled: false,
  isAppSumo: false,
  hasSupport: false,
  hasPricedCurrencies: false,
  features: {
    ...PLANS_BY_ID.trial?.features,
  },
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_FETCH:
      if (!action.payload || action.payload.errors) {
        return state;
      }
      return {
        ...state,
        record: action.payload,
        promo: action.payload.promo || initialState.promo,
      };

    case ACCOUNT_FETCH_PLAN:
      return {
        ...state,
        plan: planData(action.payload, action.meta),
        planTrial: planTrial(action.payload),
        planTrialDaysLeft: billingTrialDaysLeft(action.payload),
        planTrialDaysLeftMessage: billingTrialDaysLeftMessage(action.payload),
        planCurrent: planCurrent(action.payload),
        planSelected: planSelected(action.payload),
        planCanceled: planCanceled(action.payload),
        paymentWarning: paymentWarning(action.payload),
        paymentRequired: paymentRequired(action.payload),
        invoicesDue: invoicesDue(action.payload),
        isEnterprise: isEnterprise(action.payload),
        isSandbox: isSandbox(action.payload),
        isOldSandbox: isSandbox(action.payload),
        isExiled: isExiled(action.payload, action.meta.client),
        isAppSumo: isAppSumo(action.payload),
        hasSupport: hasSupport(action.payload),
        hasPricedCurrencies: hasPricedCurrencies(action.payload),
        hasTestEnvironment: hasTestEnvironment(action.payload),
        hasLiveEnvironment: hasLiveEnvironment(action.meta.client),
        canGoLive: canGoLive(action.payload),
        features: get(
          action,
          'payload.metadata.features',
          initialState.features,
        ),
      };

    case ACCOUNT_APPLY_PROMO:
      return {
        ...state,
        promo: action.payload,
      };

    case ACCOUNT_FETCH_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };

    case ACCOUNT_FETCH_INFO:
      return {
        ...state,
        info: action.payload,
      };

    default:
      return state;
  }
}

function planData(plan, meta) {
  if (plan && plan.metadata) {
    if (plan.metadata.fee_percent > 0) {
      plan.fee_percent = plan.metadata.fee_percent;
    }
    if (plan.metadata.revenue_max > 0) {
      plan.revenue_max = plan.metadata.revenue_max;
    }
    // Account pricing to override
    if (get(meta, 'account.record.pricing')) {
      const accountPricing = find(meta.account.record.pricing, {
        id: plan.metadata.id,
      });
      if (accountPricing) {
        if (
          accountPricing.fee_percent !== undefined &&
          accountPricing.fee_percent !== null
        ) {
          plan.fee_percent = accountPricing.fee_percent;
        }
        if (
          accountPricing.revenue_max !== undefined &&
          accountPricing.revenue_max !== null
        ) {
          plan.revenue_max = accountPricing.revenue_max;
        }
      }
    }
  }
  return plan;
}

function planTrial(plan) {
  if (plan && plan.trial) {
    return true;
  }
  return false;
}

function planCurrent(plan) {
  if (plan && plan.active) {
    return true;
  }
  return false;
}

function planSelected(plan) {
  if (plan && plan.metadata && plan.metadata.id !== 'trial') {
    return true;
  }
  return false;
}

function planCanceled(plan) {
  if (plan && plan.canceled && moment(plan.date_period_end) < moment()) {
    return true;
  }
  return false;
}

function paymentWarning(plan) {
  if (planCanceled(plan)) {
    return false;
  }
  if (
    plan &&
    !plan.trial &&
    !plan.unpaid &&
    !plan.paid &&
    !isEnterprise(plan)
  ) {
    return true;
  }
  return false;
}

function paymentRequired(plan) {
  if (planCanceled(plan)) {
    return false;
  }
  if (plan && !plan.trial && !plan.paid && !isEnterprise(plan)) {
    if (plan.unpaid) {
      return true;
    }
    const dueInvoices = invoicesDue(plan);
    if (dueInvoices.length > 0) {
      const daysDue = moment().diff(dueInvoices[0].date_period_end, 'days');
      if (daysDue > 7) {
        return true;
      }
    }
  }
  return false;
}

function invoicesDue(plan) {
  return get(plan, 'invoices.results', []).filter((i) => i.payment_due > 0);
}

function isEnterprise(plan) {
  if (get(plan, 'metadata.id') === 'enterprise') {
    return true;
  }
  return false;
}

function isCommunity(plan) {
  if (get(plan, 'metadata.id') === 'community') {
    return true;
  }
  return false;
}

function isExiled(plan, client) {
  return !plan || planCanceled(plan) || (!plan && client.trialExpired);
}

function isAppSumo(plan) {
  return !!get(plan, 'appsumo');
}

function hasSupport(plan) {
  return (
    get(plan, 'trial') ||
    get(plan, 'metadata.features.support') ||
    get(plan, 'metadata.price', 0) > 0
  );
}

function hasPricedCurrencies(plan) {
  return get(plan, 'metadata.features.priced_currencies') !== false;
}

function hasTestEnvironment(plan) {
  // Community and old sandbox plans do not get a test environment
  return !isCommunity(plan) && !isOldSandbox(plan);
}

function hasLiveEnvironment(client) {
  // Accounts that existed before environments feature may not have the live flag
  // Test flag is set for new accounts and unset when they go live
  // This handles both cases
  return client.live || !client.test;
}
