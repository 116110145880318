import PropTypes from 'prop-types';
import React from 'react';
import { values as _values, filter } from 'lodash';
import Modal from 'components/modal';
import { Form, Field, Fieldtable } from 'components/form';
import ButtonLink from 'components/button/link';
import { FadeIn } from 'components/transitions';
import DiscountRuleForm from './rule-form';
import DiscountRuleDescription from './rule-description';
import { discountRuleConditions } from 'utils/discount';
import { isNumber } from 'utils';

// Not pure
export default class DiscountRules extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    submit: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showingAddDiscountModal: false,
      anotherValues: {},
    };
    this.renderHeading = this.renderHeading.bind(this);
    this.renderValue = this.renderValue.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onClickAddAnotherDiscount = this.onClickAddAnotherDiscount.bind(this);
    this.onSubmitAnotherDiscount = this.onSubmitAnotherDiscount.bind(this);
    this.onChangeAnotherDiscount = this.onChangeAnotherDiscount.bind(this);
    this.renderAddAnotherDiscountModal =
      this.renderAddAnotherDiscountModal.bind(this);
    this.removeBuyGetDiscountItem = this.removeBuyGetDiscountItem.bind(this);
    this.getValues = this.getValues.bind(this);
    this.setValues = this.setValues.bind(this);
  }

  renderHeading() {
    return [<th key="1">Discount</th>, <th key="2">Conditions</th>];
  }

  renderValue({ value, index }) {
    const { client } = this.context;
    const { record = {}, settings, categories } = this.props;
    const currency = record.currency || client.currency;
    const conditions = discountRuleConditions(
      value,
      currency,
      settings,
      categories,
    );
    return [
      <td key="1">
        <DiscountRuleDescription rule={value} currency={currency} />
      </td>,
      <td key="2">
        {conditions.length > 0 ? (
          conditions.map((cond, index) => <div key={index}>{cond}</div>)
        ) : (
          <span className="muted">&mdash;</span>
        )}
      </td>,
    ];
  }

  renderForm(values, editIndex, b, isNew, onChangeModalForm) {
    const onRemoveItem = (event) => {
      event.preventDefault();
      const itemId = event.currentTarget.dataset.id;
      const field = event.currentTarget.dataset.name;
      const items = values[field] || [];
      onChangeModalForm({
        ...values,
        [field]: filter(items, (item) => item.id !== itemId),
      });
    };
    return (
      <DiscountRuleForm
        {...this.props}
        values={values}
        onRemoveBuyGetDiscountItem={onRemoveItem}
        modal={true}
      />
    );
  }

  onClickAddAnotherDiscount(event) {
    event.preventDefault();
    const { values } = this.props;

    const { submit, closeModal } = this.context;

    const discounts = _values(values.discounts || []);
    const canAddAnotherDiscount = this.canAddAnotherDiscount(discounts);

    if (!canAddAnotherDiscount) {
      // If adding another discount is not allowed, call form validation for required fields
      submit();
      return;
    }

    this.setState({
      anotherValues: {},
      showingAddDiscountModal: !this.state.showingAddDiscountModal,
    });
    if (this.state.showingAddDiscountModal) {
      closeModal();
    }
  }

  onSubmitAnotherDiscount(values) {
    this.props.onChangeForm({
      discounts: [this.props.values.discounts[0], values],
    });
    this.setState({ showingAddDiscountModal: false });
    this.context.closeModal();
    document.getElementById('main').scrollTop = 200;
  }

  onChangeAnotherDiscount(values) {
    const { anotherValues } = this.state;
    if (values.type !== anotherValues.type) {
      this.setState({ anotherValues: { ...values } });
    } else {
      this.setState({ anotherValues: { ...anotherValues, ...values } });
    }
  }

  getValues(index) {
    const { showingAddDiscountModal, anotherValues } = this.state;
    return showingAddDiscountModal
      ? anotherValues
      : this.props.values.discounts[index || 0];
  }

  setValues(update) {
    if (this.state.showingAddDiscountModal) {
      // update some other discount
      this.onChangeAnotherDiscount(update);
    } else {
      // update discounts[0]
      this.props.onChangeForm({
        discounts: [update],
      });
    }
  }

  removeBuyGetDiscountItem(event) {
    event.preventDefault();
    const itemId = event.currentTarget.dataset.id;
    const field = event.currentTarget.dataset.name;
    const discount = this.getValues();
    const items = discount[field] || [];
    this.setValues({
      ...discount,
      [field]: filter(items, (item) => item.id !== itemId),
    });
  }

  onChangeModalForm(prev, current, index) {
    if (index) {
      prev = prev[index];
    }

    if (prev?.discount_type === 'items' && current.discount_type === 'total') {
      delete current.quantity_max;
      delete current.get_items;
    } else if (
      prev?.condition_type === 'quantity_min' &&
      current.condition_type === 'total_min'
    ) {
      delete current.quantity_min;
    } else if (
      prev?.condition_type === 'total_min' &&
      current.condition_type === 'quantity_min'
    ) {
      delete current.total_min;
    }

    return current;
  }

  renderAddAnotherDiscountModal() {
    const { categories, lookup, settings } = this.props;

    const values = this.state.anotherValues || {};

    return (
      <Form
        onSubmit={this.onSubmitAnotherDiscount}
        onChange={this.onChangeAnotherDiscount}
      >
        <Modal
          title="Add discount"
          width={900}
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'cancel',
              onClick: this.onClickAddAnotherDiscount,
            },
          ]}
          cancel={false}
          onClose={this.onClickAddAnotherDiscount}
          localized={true}
        >
          <DiscountRuleForm
            categories={categories}
            lookup={lookup}
            settings={settings}
            values={values}
            onRemoveBuyGetDiscountItem={this.removeBuyGetDiscountItem}
            modal={true}
          />
        </Modal>
      </Form>
    );
  }

  canAddAnotherDiscount(discounts) {
    const discount = discounts[0];

    // if discount is not set, then no "add another discount" button is available
    if (!discount) {
      return false;
    }

    const isBuyXGetYDiscount =
      isNumber(discount.total_min) && isNumber(discount.value_percent);
    // if discount is set and conditions are set for "Buy X get Y" discount, then "add another discount" button is available
    if (isBuyXGetYDiscount) {
      return true;
    }

    // if discount is set and conditions are set for "Order total", "Shipping" or "Product or category" discount, then "add another discount" button is available
    if (isNumber(discount.value_fixed) || isNumber(discount.value_percent)) {
      return true;
    }

    return false;
  }

  render() {
    const {
      record = {},
      values = {},
      categories,
      lookup,
      settings,
    } = this.props;

    const defaultDiscount = { type: 'total', value_type: 'fixed' };

    const discounts = _values(values.discounts || [defaultDiscount]);

    return (
      <div className="discount-rules">
        {/*<div className="view-body-subheader">
          <h3 className="view-body-subtitle">
            {discounts.length === 1 ? 'Discount' : 'Discounts'}
          </h3>
        </div>*/}
        {discounts.length > 1 ? (
          <div>
            <FadeIn transitionAppear={false}>
              <Fieldtable
                label="Discount"
                name="discounts"
                defaultValue={discounts}
                renderHeading={this.renderHeading}
                renderValue={this.renderValue}
                renderForm={this.renderForm}
                onChangeModalForm={this.onChangeModalForm}
                formWidth={800}
                localized={true}
              />
            </FadeIn>
          </div>
        ) : (
          <div>
            <FadeIn transitionAppear={false}>
              <fieldset>
                <Field type="hidden" name="discounts" value={discounts} />
                <DiscountRuleForm
                  path="discounts[0]."
                  record={record}
                  values={discounts[0]}
                  categories={categories}
                  lookup={lookup}
                  settings={settings}
                  onRemoveBuyGetDiscountItem={this.removeBuyGetDiscountItem}
                />
                {this.state.showingAddDiscountModal &&
                  this.context.openModal(
                    `discount_rules_add`,
                    this.renderAddAnotherDiscountModal,
                  )}
                <div className="collection-action-group">
                  <div className="form-field">
                    <ButtonLink
                      type="secondary"
                      size="sm"
                      onClick={this.onClickAddAnotherDiscount}
                    >
                      Add another discount
                    </ButtonLink>
                  </div>
                </div>
              </fieldset>
            </FadeIn>
          </div>
        )}
      </div>
    );
  }
}
