import React from 'react';
import Table from 'components/collection/table';
import { wordify } from 'shared/utils';

export default class AppConfigs extends React.PureComponent {
  getInstalledPermissions() {
    const { installedApp } = this.props;

    const descriptors = installedApp.permissions?.map((perm) => {
      const [action, ...res] = perm.split('_');
      const resource = wordify(res.join('_'));
      switch (action) {
        case 'read':
          return {
            resource,
            permission: 'View',
          };
        case 'write':
          return {
            resource,
            permission: 'Manage',
          };
        case 'public':
          return {
            resource: 'Storefront content',
            permission: 'View',
          };
        default:
          return {
            resource,
            permission: null,
          };
      }
    });

    return descriptors?.length
      ? descriptors
      : [
          {
            resource: 'All resources',
            permission: 'Full access',
          },
        ];
  }

  render() {
    const descriptors = this.getInstalledPermissions();

    return (
      <Table
        title="Permissions"
        subtitle="What the app is allowed to do in your store"
        fields={[
          {
            label: 'Resource',
            path: 'resource',
          },
          {
            label: 'Permission',
            path: 'permission',
          },
        ]}
        rows={descriptors}
      />
    );
  }
}
