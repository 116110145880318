import { reduce } from 'lodash';
import { pluralize, wordify, singularize } from './index';

const NO_PLURALIZE = ['stock'];
const HIDDEN_ATTRS = ['name', 'label'];

export function modelEndpoint({ name, namespace }, app = undefined) {
  return `${app ? `/apps/${app.slug_id}` : ''}/${
    namespace ? `${namespace}/${name}` : name
  }`;
}

export const singularWithTitle = ({ label, title }) =>
  `${singularize(label)} ${title}`;

export function modelHumanizedName({ parent, label, name }) {
  const title = label
    ? NO_PLURALIZE.includes(label.toLowerCase())
      ? label
      : pluralize(label)
    : name;
  if (parent) {
    return wordify(singularWithTitle({ label: parent, title }));
  }
  return wordify(title);
}

export const modelPath = ({ name, namespace }) =>
  namespace ? `/${namespace}/${name}` : `/${name}`;

export function settingToModel(setting) {
  return {
    ...setting,
    id: setting.id.replace('com.', 'com.settings.'),
    label: singularWithTitle({ label: setting.label, title: 'Settings' }),
    namespace: 'settings',
  };
}

export function countModelFields(model) {
  if (!model?.fields) {
    return 0;
  }
  return reduce(
    model.fields,
    (count, field) => {
      if (!field) {
        return count;
      }
      return (
        count +
        (field.type === 'object' || field.value_type === 'object'
          ? countModelFields(field)
          : 1) +
        (field.object_types
          ? reduce(
              field.object_types,
              (count2, objectType) => count2 + countModelFields(objectType),
              0,
            )
          : 0)
      );
    },
    0,
  );
}

export function cleanModelField(field) {
  const def = {
    ...field,
    id: field.name,
  };
  return hideFieldAttrs(def);
}

function hideFieldAttrs(obj, key = undefined) {
  if (obj instanceof Array) {
    return obj.map((el) => {
      if (el && typeof el === 'object') {
        // Simplify complex "enum" objects
        if (key === 'enum' && el.value) {
          return el.value;
        }
        return hideFieldAttrs(el);
      }
      return el;
    });
  }

  const def = {
    ...obj,
  };

  HIDDEN_ATTRS.forEach((attr) => {
    delete def[attr];
  });

  return Object.entries(def).reduce(
    (acc, [attrKey, attrValue]) =>
      def[attrKey] && typeof def[attrKey] === 'object'
        ? { ...acc, [attrKey]: hideFieldAttrs(attrValue, attrKey) }
        : { ...acc, [attrKey]: attrValue },
    {},
  );
}
