import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import NewModalPage from 'components/pages/modals/new-attribute';

import {
  NewAttribute,
  mapStateToProps,
  mapDispatchToProps,
} from '../NewAttribute';

class NewAttributeModal extends NewAttribute {
  static contextTypes = {
    notifyCreated: pt.func.isRequired,
    notifyError: pt.func.isRequired,
    openModal: pt.func.isRequired,
    closeModal: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state.record = props.record;
    this.state.values = { ...(props.record || {}) };

    this.methods.onSubmitRecord = this.onSubmitRecord.bind(this);
  }

  onSubmitRecord(values) {
    return super.onSubmitRecord(values).then(() => {
      this.context.closeModal();
    });
  }

  render() {
    return <NewModalPage {...this.props} {...this.state} {...this.methods} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAttributeModal);
