import React from 'react';
import './section-header.scss';
import HelpLink from 'components/help-link';

export default class SectionHeader extends React.PureComponent {
  render() {
    const { className, title, titleIcon, subtitle, count, ...props } =
      this.props;

    return (
      <div>
        <div className="side-help-link">
          <h3 className={className}>
            {count > 0 ? (
              <div className="title-count">
                {title} <span className="title-badge">{count}</span>
              </div>
            ) : (
              <div>
                {titleIcon} {title}
              </div>
            )}
          </h3>
          {!subtitle && <HelpLink sectionName={title.toString()} {...props} />}
        </div>
        {subtitle && (
          <div className="side-help-link">
            <p>{subtitle}</p>
            <HelpLink sectionName={title.toString()} {...props} />
          </div>
        )}
      </div>
    );
  }
}
