/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { find, get, first } from 'lodash';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import Alert from 'components/alert';
import IconPayment from 'components/icon/payment';
import ContentFields from 'components/content/fields';
import { FadeIn } from 'components/transitions';
import SectionHeader from 'components/section-header';
import {
  formatCurrencyRounded,
  parseCurrency,
  currencyValue,
  arrayToObject,
} from 'utils';
import { hasLocalizedContent } from 'utils/content';
import { renderPaymentMethodDescription } from 'utils/order';
import { getRefundablePayments, isPaymentAuthorized } from 'utils/payment';

export default class OrderPaymentRefund extends React.PureComponent {
  static propTypes = {
    record: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    content: PropTypes.object,
    sourceLabel: PropTypes.string,
    paymentId: PropTypes.string,
    onClickPaymentRefund: PropTypes.func.isRequired,
    onSubmitPaymentRefund: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const refundablePayments = getRefundablePayments(
      props.record.payments.results,
    ).reverse();
    const paymentId = props.paymentId || get(first(refundablePayments), 'id');
    const payment = find(refundablePayments, { id: paymentId }) || {};
    this.state = {
      values: {
        ...props.record,
        parent_id: payment.id,
        amount: this.getRefundAmount(props, payment),
        currency: this.getRefundCurrency(props, payment),
      },
      refundablePayments,
      methodsById: arrayToObject(props.settings.payments.methods),
    };
    this.onChangeForm = this.onChangeForm.bind(this);
  }

  getRefundAmount(props, payment) {
    return props.paymentId
      ? payment.amount_refundable
      : props.record.payment_balance > 0
      ? payment.amount_refundable >= props.record.payment_balance
        ? props.record.payment_balance
        : payment.amount_refundable || ''
      : '';
  }

  getRefundCurrency(props, payment) {
    return payment.currency || props.record.currency;
  }

  getRefundMethodOptions(payment) {
    const { record } = this.props;
    const { methodsById } = this.state;
    if (!payment || !record.payments) {
      return null;
    }
    if (
      payment.method === 'account' ||
      !methodsById.account ||
      !methodsById.account.enabled
    ) {
      return null;
    }
    const paymentMethod = methodsById[payment.method] || {};

    return [
      {
        value: payment.method,
        label: (
          <div className="order-payment-radio-option">
            <span className="order-payment-radio-label">
              <IconPayment method={payment.method} card={payment.card} />
              {paymentMethod.name || payment.method}
            </span>
          </div>
        ),
      },
      {
        value: 'account',
        label: (
          <div className="order-payment-radio-option">
            <span className="order-payment-radio-label">
              <IconPayment method="account" />
              Account credit
            </span>
          </div>
        ),
      },
    ];
  }

  onChangeForm(values) {
    const {
      props: { record },
      state: { refundablePayments },
    } = this;

    let { amount } = values;
    let currency = values.currency || record.currency;
    if (values.parent_id !== this.state.values.parent_id) {
      const payment = find(refundablePayments, { id: values.parent_id }) || {};
      amount = this.getRefundAmount(this.props, payment);
      currency = this.getRefundCurrency(this.props, payment);
    }

    this.setState({
      values: {
        ...this.state.values,
        ...values,
        amount,
        currency,
      },
    });
  }

  renderPaymentOptionLabel(payment) {
    const { settings } = this.props;
    return renderPaymentMethodDescription(settings, payment);
  }

  renderPaymentOptionValue(payment) {
    return (
      <Fragment>
        {isPaymentAuthorized(payment) ? (
          <span className="muted nowrap">
            {formatCurrencyRounded(payment.amount, payment.currency)} authorized
          </span>
        ) : (
          formatCurrencyRounded(payment.amount, payment.currency)
        )}

        {payment.amount_refunded > 0 && (
          <span className="muted nowrap">
            &nbsp;&nbsp;(
            {formatCurrencyRounded(
              payment.amount_refunded,
              payment.currency,
            )}{' '}
            refunded)
          </span>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      loading,
      content,
      sourceLabel,
      onClickPaymentRefund,
      onSubmitPaymentRefund,
    } = this.props;

    const { values, refundablePayments } = this.state;

    const payment = find(refundablePayments, { id: values.parent_id });
    const authorizedPayment = isPaymentAuthorized(payment) ? payment : null;
    const refundMethodOptions = this.getRefundMethodOptions(payment);

    return (
      <Form onSubmit={onSubmitPaymentRefund} onChange={this.onChangeForm}>
        <Modal
          title={`Refund ${sourceLabel || 'order'}`}
          className="order-view-modal-payment-refund"
          actions={[
            payment
              ? {
                  label: `Refund ${
                    parseCurrency(values.amount) > 0
                      ? currencyValue(values.amount, values.currency, {
                          isSymbol: true,
                          precision: undefined,
                        })
                      : ''
                  }`,
                  type:
                    parseCurrency(values.amount) > 0 ? 'submit' : 'secondary',
                }
              : {
                  label: 'Close',
                  type: 'secondary',
                  onClick: onClickPaymentRefund,
                },
            { label: 'Cancel', type: 'cancel', onClick: onClickPaymentRefund },
          ]}
          width={600}
          cancel={false}
          loading={loading}
          onClose={onClickPaymentRefund}
          devtools={{ model: 'payments', zone: 'refund', console: false }}
          localized={hasLocalizedContent(content.models, 'payments', 'refund')}
        >
          <div className="view-body-section">
            <div className="view-body-subheader">
              <SectionHeader
                className="view-body-subtitle"
                title="Select a payment"
              />
              <SectionHeader className="view-body-subtitle" title="Amount" />
            </div>
            <div className="view-section-content order-payment-radio">
              <fieldset>
                {refundablePayments.length > 0 ? (
                  <Field
                    type="radio"
                    name="parent_id"
                    stacked={true}
                    defaultValue={values.parent_id}
                    options={refundablePayments.map((payment) => ({
                      value: payment.id,
                      label: (
                        <div className="order-payment-radio-option">
                          <span className="order-payment-radio-label">
                            {this.renderPaymentOptionLabel(payment)}
                          </span>
                          <span className="order-payment-radio-value">
                            {this.renderPaymentOptionValue(payment)}
                          </span>
                        </div>
                      ),
                    }))}
                  />
                ) : (
                  <Alert>There are no refundable payments</Alert>
                )}
              </fieldset>
            </div>
          </div>
          {refundMethodOptions ? (
            <FadeIn>
              <div className="view-body-section">
                <div className="view-body-subheader">
                  <SectionHeader
                    className="view-body-subtitle"
                    title="Refund method"
                  />
                </div>
                <div className="view-section-content order-payment-radio">
                  <fieldset>
                    <Field
                      type="radio"
                      name="method"
                      required={true}
                      options={refundMethodOptions}
                      defaultValue={payment.method}
                    />
                  </fieldset>
                </div>
              </div>
            </FadeIn>
          ) : (
            <Field type="hidden" name="method" value={payment.method} />
          )}
          <fieldset>
            <div className="row">
              <Field
                type="currency"
                rounded={true}
                label="Refund amount"
                name="amount"
                required={true}
                defaultValue={
                  authorizedPayment ? payment.amount : values.amount
                }
                disabled={authorizedPayment ? true : false}
                placeholder="0.00"
                currency={values.currency}
                className="span2"
              />
            </div>
            <div className="row">
              <Field
                type="textarea"
                name="reason"
                label="Reason"
                placeholder="Optional"
                autoSize={true}
                rows={2}
                help="Refund notes are not visible to the customer"
                className="span4"
              />
            </div>
            <ContentFields
              {...this.props}
              zone="refund"
              collection="payments"
              models={content.models}
              record={undefined}
              values={undefined}
              currency={values.currency}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
