import React from 'react';
import { get } from 'lodash';
import Icon from 'components/icon';
import Link from 'components/link';
import User from '../utils/user';
import Date from '../utils/date';

export default function Notification({ event, onClickShowNotification }) {
  if (!event.to) {
    return null;
  }

  const label = get(event, 'label');

  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/notification" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          Sent{' '}
          {label ? (
            <Link
              to={`/settings/notifications?template=${event.template}`}
              className="sneaky-link"
            >
              {label.toLowerCase()}
            </Link>
          ) : (
            'message'
          )}
          &nbsp;to <a href={`mailto:${event.to}`}>{event.to}</a>
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
          &nbsp;
          <button
            data-id={event.id}
            data-template={event.template}
            onClick={onClickShowNotification}
            className="activity-event-button"
          >
            View message
          </button>
        </div>
      </div>
    </div>
  );
}
