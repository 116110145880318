import React from 'react';
import Icon from 'components/icon';
import User from '../utils/user';
import Date from '../utils/date';

export default function SubscriptionPaused({ event }) {
  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/hold-on" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          Subscription paused
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
      </div>
    </div>
  );
}
