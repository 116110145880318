import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import Scrollbars from 'components/scrollbars';
import Icon from 'components/icon';
import Link from 'components/link';
import NavGroup from './group';
import StoreIcon from 'components/store/icon';
import UserPhoto from 'components/user/photo';
import EnvironmentBadge from 'components/nav/environment-badge';
import { DropdownMenu, DropdownMenuItem } from 'components/dropdown-menu';
import EasterEgg from './easter-egg';
import GoLiveNotice from './go-live-notice';
import InactiveNotice from './inactive-notice';
import NavStoreMenu from './store-menu';
import { navWithPreferences } from 'utils/preferences';
import './nav.scss';

export default class NavMain extends React.PureComponent {
  static contextTypes = {
    account: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    isOwner: PropTypes.bool,
    user: PropTypes.object.isRequired,
    onClickLogout: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  static propTypes = {
    updateSetup: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      navItems: navWithPreferences({
        user: props.user,
        client: props.client,
        content: props.content,
      }),
      storeMenuOpen: false,
      footerMenuOpen: false,
      stopMenuPropagation: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.content !== this.props.content ||
      prevProps.user?.preferences?.nav !== this.props.user?.preferences?.nav ||
      prevProps.client?.preferences?.nav !==
        this.props.client?.preferences?.nav ||
      prevProps.client?.apps !== this.props.client?.apps
    ) {
      this.setState({
        navItems: [
          ...cloneDeep(
            navWithPreferences({
              user: this.props.user,
              client: this.props.client,
              content: this.props.content,
            }),
          ),
        ],
      });
    }
  }

  onCloseGoLiveNotice = (event) => {
    event.preventDefault();
    this.props.updateSetup('golive', false);
  };

  onStoreMenuChange = (open) => {
    this.setState({
      storeMenuOpen: open,
    });
  };

  toggleStoreMenu = () => {
    this.setState((prevState) => ({ storeMenuOpen: !prevState.storeMenuOpen }));
  };

  onFooterMenuChange = (open) => {
    this.setState({
      footerMenuOpen: open,
    });

    // Prevents propagation when the menu sits on top
    // of the trigger and contains clickable items
    if (open) {
      setTimeout(() => {
        this.setState({
          stopMenuPropagation: false,
        });
      }, 150);
    } else {
      this.setState({
        stopMenuPropagation: true,
      });
    }
  };

  render() {
    const {
      nav,
      settings,
      content,
      location,
      onClickNavStore,
      onClickNavUser,
      showStoreMenu,
      testMode,
      onChangeTestEnv,
      enableLiveEnvironment,
      ...props
    } = this.props;

    const { navItems, footerMenuOpen, storeMenuOpen, stopMenuPropagation } =
      this.state;

    const navProps = {
      location,
      activeGroups: nav.activeGroups,
      ...props,
    };

    const { client, account, user, isOwner, onClickLogout } = this.context;
    const { hasTestEnvironment, hasLiveEnvironment, canGoLive, planTrial } =
      account;

    const store = null; // TBD: multi-store

    const isActive = !client.inactive;
    const isLive = !testMode;

    return (
      <div className="nav-container">
        <Scrollbars>
          <div className="nav-inner">
            <DropdownMenu
              rootRest={{
                modal: false,
                open: storeMenuOpen,
                onOpenChange: this.onStoreMenuChange,
              }}
              contentRest={{ side: 'bottom', align: 'right' }}
              triggerRest={{ 'aria-label': 'Nav store dropdown menu' }}
              triggerClassName="nav-store"
              triggerChildren={
                <>
                  <div className="nav-store-container">
                    <StoreIcon client={client} store={store} size={48} />
                    <div className="nav-store-name">
                      {store ? store.name : client.name}
                      {hasTestEnvironment && (
                        <EnvironmentBadge isLive={isLive} />
                      )}
                    </div>
                  </div>

                  {isActive && (
                    <div className="nav-store-dropdown-icon">
                      <Icon fa="chevron-down" />
                    </div>
                  )}
                </>
              }
            >
              <NavStoreMenu
                testMode={testMode}
                onChangeTestEnv={onChangeTestEnv}
                toggleStoreMenu={this.toggleStoreMenu}
                {...this.props}
              />
            </DropdownMenu>

            {!isActive && <InactiveNotice />}

            {isActive &&
              planTrial &&
              canGoLive &&
              !hasLiveEnvironment &&
              client.setup?.golive !== false && (
                <GoLiveNotice
                  onEnableLiveEnvironment={enableLiveEnvironment}
                  onCloseGoLiveNotice={this.onCloseGoLiveNotice}
                />
              )}

            <NavGroup {...navProps} items={navItems} />
            <NavGroup
              heading="Admin"
              {...navProps}
              items={[
                client.features.has('apps') && {
                  label: 'Apps',
                  icon: 'nav/apps',
                  link: '/apps',
                  permission: 'settings',
                },
                {
                  label: 'Storefronts',
                  icon: 'nav/storefront',
                  permission: 'storefront',
                  items: [
                    {
                      label: 'All storefronts',
                      link: '/storefronts',
                      permission: 'storefront',
                    },
                    { label: 'Checkout', link: '/settings/checkout' },
                  ],
                },
                {
                  label: 'Integrations',
                  icon: 'nav/integrations',
                  link: '/settings/integrations',
                  permission: 'settings',
                },
                {
                  label: 'Developer',
                  icon: 'nav/developer',
                  permission: 'settings',
                  items: [
                    { label: 'Console', link: '/console' },
                    { label: 'Models', link: '/settings/models' },
                    { label: 'Explorer', link: '/model-explorer' },
                    { label: 'Webhooks', link: '/settings/webhooks' },
                    { label: 'API keys', link: '/settings/api' },
                  ],
                },
                {
                  label: 'Settings',
                  icon: 'nav/settings',
                  permission: 'settings',
                  link: false,
                  items: [
                    { label: 'General', link: '/settings/general' },
                    { label: 'Account', link: '/settings/account' },
                    { label: 'Users', link: '/settings/users' },
                    { label: 'Products', link: '/settings/products' },
                    { label: 'Customers', link: '/settings/customers' },
                    { label: 'Orders', link: '/settings/orders' },
                    {
                      label: 'Subscriptions',
                      link: '/settings/subscriptions',
                    },
                    { label: 'Payments', link: '/settings/payments' },
                    { label: 'Shipping', link: '/settings/shipping' },
                    { label: 'Taxes', link: '/settings/taxes' },
                    { label: 'Gift cards', link: '/settings/giftcards' },
                    {
                      label: 'Notifications',
                      link: '/settings/notifications',
                    },
                  ],
                },
              ]}
            />

            <EasterEgg />
          </div>
        </Scrollbars>
        <div className="nav-footer-user">
          <DropdownMenu
            rootRest={{
              modal: false,
              open: footerMenuOpen,
              onOpenChange: this.onFooterMenuChange,
            }}
            contentRest={{ side: 'top', align: 'middle', sideOffset: 12 }}
            triggerRest={{ 'aria-label': 'Nav footer dropdown menu' }}
            triggerClassName="nav-footer-menu-button"
            triggerChildren={
              <>
                <UserPhoto user={user} width={30} height={30} />
                <div className="nav-user-trigger">
                  <div className="nav-user-name">{user.name}</div>
                  <div className="nav-user-email">{user.email}</div>
                </div>
                <Icon fa="ellipsis-h" />
              </>
            }
          >
            <DropdownMenuItem asChild>
              <Link to="/profile" disabled={stopMenuPropagation}>
                Edit profile
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <button
                type="button"
                onClick={onClickLogout}
                disabled={stopMenuPropagation}
              >
                Log out
              </button>
            </DropdownMenuItem>
          </DropdownMenu>
        </div>
      </div>
    );
  }
}
