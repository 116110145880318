import React, { Fragment } from 'react';
import Link from 'components/link';
import { Form, Field, Button } from 'components/form';
import { FadeInUp } from 'components/transitions';
import LogoTopLeft from 'components/logo/logo';
import Icon from 'components/icon/icon';
import Cookies from 'universal-cookie';
import './login.scss';

const cookies = new Cookies();

export default class LoginPage extends React.PureComponent {
  onClickLogin = (event) => {
    // Hack to workaround a weird issue where
    // submit does not work the 2nd time this form loads
    event.preventDefault();
    this.refs.form.submit();
  };

  onKeyDown = (event) => {
    switch (event.keyCode) {
      case 13: // enter
        event.preventDefault();
        this.refs.form.submit();
        break;
      default:
    }
  };

  onClickRecover = () => {
    const { onClickRecover } = this.props;
    const { form: { values = {} } = {} } = this.refs;
    onClickRecover(values);
  };

  getSignupUrl = () => {
    const { protocol, hostname, port } = window.location;
    let basename = hostname.split('.');
    if (basename.length > 2) {
      basename = basename.slice(1);
    }
    return `${protocol}//${basename.join('.')}${port ? `:${port}` : ''}/signup`;
  };

  render() {
    const { onSubmitLogin } = this.props;
    const cli = !!cookies.get('cliUrl');

    return (
      <Fragment>
        <div className="login-toplink">
          New to Swell? <a href={this.getSignupUrl()}>Create a store</a>
        </div>
        <div className="login-form">
          <div className="logo-container">
            <LogoTopLeft />
          </div>

          <FadeInUp>
            <div>
              <Form onSubmit={onSubmitLogin} autoFocus={true} ref="form">
                <fieldset>
                  <Field
                    label="Email"
                    type="text"
                    name="username"
                    required={true}
                    showError={false}
                    onKeyDown={this.onKeyDown}
                    large={true}
                  />
                  <Field
                    label="Password"
                    type="password"
                    name="password"
                    rules="required"
                    showError={false}
                    onKeyDown={this.onKeyDown}
                    large={true}
                  />
                </fieldset>
                <div className="login-actions">
                  <Button
                    type="submit"
                    styleType="default"
                    onClick={this.onClickLogin}
                    size="large"
                    className="button-default-purple"
                  >
                    Log in
                  </Button>
                  <div className="login-note">
                    Forgot your password?{' '}
                    <Link to="/recover" onClick={this.onClickRecover}>
                      Recover
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
          </FadeInUp>
        </div>
        {cli && (
          <div className="cli">
            <Icon
              svgType="terminal-square"
              className="cli-icon"
              width={24}
              height={24}
            />
            <div>Command-line interface</div>
          </div>
        )}
      </Fragment>
    );
  }
}
