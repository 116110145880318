import React from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import pt from 'prop-types';

import { isEmpty } from 'utils';

import Icon from 'components/icon';

import Label from './label';

const timeFormats = Object.freeze(['H:mm a', moment.HTML5_FMT.TIME]);

function parseDateValue(value, timezone) {
  if (value instanceof Date) {
    return moment(value).tz(timezone);
  }

  if (moment.isMoment(value)) {
    return value.tz(timezone);
  }

  const number = Date.parse(value);

  if (Number.isFinite(number)) {
    return moment(number).tz(timezone);
  }

  const date = moment
    .tz(value, moment.HTML5_FMT.TIME_SECONDS, 'UTC')
    .tz(timezone);

  return date;
}

function getInitialValue(props, timezone, nowValue) {
  const value = props.value || props.defaultValue || nowValue;

  if (value) {
    const date = parseDateValue(value, timezone);

    if (date.isValid()) {
      return date;
    }
  }

  return null;
}

function formatValue(mdate) {
  return mdate.format('LT');
}

function getInitialInputValue(date) {
  return date ? formatValue(date) : '';
}

/**
 * Copy date (year, month, day) from source to destination
 *
 * @param {moment.Moment} dest
 * @param {moment.Moment} src
 * @returns {moment.Moment}
 */
function inheritDate(dest, src) {
  return dest.set({
    year: src.year(),
    month: src.month(),
    date: src.date(),
  });
}

export default class InputTime extends React.PureComponent {
  static contextTypes = {
    client: pt.object.isRequired,
  };

  static propTypes = {
    value: pt.oneOfType([pt.string, pt.object]),
    defaultValue: pt.oneOfType([pt.string, pt.object]),
    defaultNow: pt.bool,
    label: pt.oneOfType([pt.string, pt.node]),
    labelRenderer: pt.func,
    rawLabel: pt.string,
    help: pt.oneOfType([pt.node, pt.object]),
    utc: pt.bool,
    readonlyContent: pt.bool,

    onChange: pt.func,
  };

  static defaultProps = {
    utc: false,
    defaultNow: false,
  };

  constructor(props, context) {
    super(props, context);

    const { locale } = context.client;
    const timezone = context.client.timezone || moment.tz.guess();

    moment.locale(locale);

    const nowValue = props.defaultNow ? Date.now() : null;
    const value = getInitialValue(props, timezone, nowValue);

    this.state = {
      value,
      prev: { value: props.value, defaultValue: props.defaultValue },
      inputValue: getInitialInputValue(value),
      timezoneName: moment.tz(timezone).format('z'),
      timezone,
      error: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.defaultValue !== state.prev.defaultValue &&
      props.value !== state.prev.value
    ) {
      const value = getInitialValue(props, state.timezone);

      return {
        value,
        inputValue: getInitialInputValue(value),
        prev: { value: props.value, defaultValue: props.defaultValue },
      };
    }

    return null;
  }

  /** @param {React.FocusEvent<HTMLInputElement>} event */
  onBlur = (event) => {
    const { value } = event.currentTarget;

    if (value) {
      let mdate = moment.tz(value, timeFormats, this.state.timezone);

      if (this.state.value) {
        mdate = inheritDate(mdate, this.state.value);
      }

      if (mdate.isValid()) {
        this.setState({
          value: mdate,
          inputValue: formatValue(mdate),
          error: false,
        });

        this.props.onChange(event, mdate.toDate());
      } else {
        this.setState({ error: true });
      }
    } else {
      this.setState({
        value: null,
        inputValue: '',
        error: false,
      });
    }
  };

  /** @param {React.ChangeEvent<HTMLInputElement>} event */
  onChange = (event) => {
    const { value } = event.currentTarget;

    let mdate = moment.tz(value, timeFormats, true, this.state.timezone);

    if (this.state.value) {
      mdate = inheritDate(mdate, this.state.value);
    }

    if (mdate.isValid()) {
      if (this.state.error) {
        this.setState({ error: false });
      }

      this.setState({ value: mdate });
      this.props.onChange(event, value ? mdate.toDate() : null);
    } else if (value !== '') {
      this.setState({ value: null });
      this.props.onChange(event, null);
    }

    this.setState({ inputValue: value });
  };

  focus() {
    this.refs.input.focus();
  }

  value() {
    const { value } = this.state;

    if (value) {
      return value.toISOString();
    }

    return null;
  }

  render() {
    const {
      label,
      rawLabel,
      labelRenderer,
      help,
      defaultNow,
      utc,
      readonlyContent,
      ...props
    } = this.props;
    const { inputValue, error, timezoneName } = this.state;
    const labelText = (typeof label === 'string' ? label : rawLabel) || '';
    const fieldLabel = timezoneName ? `${labelText} (${timezoneName})` : label;

    return (
      <div className="form-date">
        {!isEmpty(label) && (
          <Label
            label={fieldLabel}
            labelRenderer={labelRenderer}
            help={help}
            htmlFor={props.id}
          />
        )}

        <div className="form-field-input">
          <span
            className={classNames('form-date-icon', {
              'has-value': Boolean(inputValue && !props.disabled),
            })}
          >
            <Icon fa="clock" />
          </span>

          <input
            {...props}
            type="text"
            ref="input"
            value={inputValue}
            defaultValue={undefined}
            placeholder="12:00 AM"
            className={classNames(props.className, {
              disabled: Boolean(props.disabled),
              error: Boolean(error),
            })}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
        </div>
      </div>
    );
  }
}
