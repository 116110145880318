import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import { setTestEnv } from 'utils';

export const EnvironmentContext = React.createContext({ testEnv: '' });

class EnvironmentProvider extends React.PureComponent {
  static propTypes = {
    children: pt.node.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      testEnv: '',
      setTestEnv: (testEnv, updateState = true) => {
        setTestEnv(testEnv);

        if (updateState) {
          this.setState({ testEnv });
        }
      },
    };
  }

  render() {
    return (
      <EnvironmentContext.Provider value={this.state}>
        {this.props.children}
      </EnvironmentContext.Provider>
    );
  }
}

export default connect(null, null)(EnvironmentProvider);
