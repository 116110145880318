import React from 'react';
import Icon from './icon';

export default class Bolt extends React.Component {
  render() {
    const { active } = this.props;
    return (
      <Icon
        fa="bolt"
        faType="solid"
        className={`icon-bolt ${
          active !== undefined ? (active ? 'active' : 'inactive') : ''
        }`}
      />
    );
  }
}
