import React from 'react';
import { FadeIn } from 'components/transitions';

export default class Checkbox extends React.PureComponent {
  render() {
    const { checked, minus, className, animated = true, ...props } = this.props;

    return (
      <span
        {...props}
        className={`icon-checkbox ${
          checked ? 'checked' : minus ? 'minus' : ''
        } ${className ? className : ''}`}
      >
        {animated ? (
          <FadeIn
            component="div"
            className="icon-checkbox-mark"
            transitionAppear={false}
            active={checked}
          />
        ) : (
          <div className="icon-checkbox-mark" />
        )}
      </span>
    );
  }
}
