import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, set, find } from 'lodash';
import { textContrast } from 'text-contrast';

import {
  formatCurrency,
  formatNumber,
  formatDate,
  singularize,
  imageUrl,
  isEmpty,
} from 'utils';
import { productThumbUrl } from 'utils/product';
import { truncatedText } from 'utils/collection';
import {
  contentFieldProps,
  contentFieldLabel,
  contentFieldPath,
  findContentTitleFields,
  hasLocalizedFields,
  contentLookupRecordName,
  contentLookupRecordLink,
} from 'utils/content';

import Link from 'components/link';
import Icon from 'components/icon';
import ChildCollection from 'containers/ChildCollection';
import { Fieldtable, Label } from 'components/form';

import ContentFieldGroups from 'components/content/field-groups';

export default class ContentCollection extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func,
    refreshModal: PropTypes.func,
    closeModal: PropTypes.func,
  };

  static propTypes = {
    root: PropTypes.string,
    fields: PropTypes.array.isRequired,
    value: PropTypes.array,
    defaultValue: PropTypes.array,
    currency: PropTypes.string,
    lookup: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    onChangeCollectionType: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      fields: null,
      localized: false,
      modalValues: null,
      ...this.getFieldsByTypeWithModels(props),
    };
  }

  getFieldsByTypeWithModels(
    { fields, model, item_types },
    valueType = undefined,
  ) {
    let fieldsByType = [...fields].filter((x) => x);

    // Append fields from item type
    if (item_types && valueType !== undefined) {
      const itemType = find(
        item_types,
        (type) => type === valueType || type.id === valueType,
      );
      if (itemType && itemType.fields) {
        fieldsByType = [...fieldsByType, ...itemType.fields].filter((x) => x);
      }
    }

    return {
      fields: fieldsByType.map((field) => ({
        field,
        model,
        path: contentFieldPath(field, model, model?.root),
      })),
      localized: hasLocalizedFields(fieldsByType),
    };
  }

  fieldImageType(field) {
    const fieldProps = contentFieldProps(field);
    const collection = fieldProps.collection || fieldProps.model;
    switch (collection) {
      case 'products':
      case 'products:variants':
        return collection;
      default:
        return null;
    }
  }

  renderHeading = () => {
    if (this.props.heading === false) return null;
    const titleFields = findContentTitleFields(this.props.fields);
    return [
      titleFields.length > 0 ? (
        titleFields.map((field, i) => (
          <th
            key={field.id}
            colSpan={this.fieldImageType(field) && i === 0 ? 2 : 1}
          >
            {field.label || field.id}
          </th>
        ))
      ) : (
        <th key="title">Value</th>
      ),
    ];
  };

  renderImageValue(value, field) {
    const fieldValue = get(value, field.id);
    const collection = this.fieldImageType(field);
    const linkUrl = contentLookupRecordLink(collection, value);
    let imageSrc;
    let imageClass;
    switch (collection) {
      case 'products':
        imageSrc = productThumbUrl(fieldValue, undefined, 90);
        break;
      case 'products:variants':
        imageSrc = productThumbUrl(fieldValue.product_image, fieldValue, 90);
        imageClass = 'small';
        break;
      default:
        return [
          <td key="first-image" className="image">
            <span className="collection-table-images">
              <span className="image" />
            </span>
          </td>,
        ];
    }
    if (!fieldValue) {
      return [
        <td>
          <span className="muted">&mdash;</span>
        </td>,
      ];
    }
    return [
      <td key={`${field.id}-image`} className={`image ${imageClass || ''}`}>
        <span className={`collection-table-images ${imageClass || ''}`}>
          <span className="image">
            <Link to={linkUrl}>
              <img src={imageSrc} alt="" />
            </Link>
          </span>
        </span>
      </td>,
    ];
  }

  renderFieldValue(field, values = {}) {
    const { currency, model } = this.props;

    const fieldProps = contentFieldProps(field);
    const fieldPath = contentFieldPath(field, model, model?.root);

    const fieldValue = get(values, fieldPath);

    let retValue = null;

    if (isEmpty(fieldValue)) {
      retValue = <span className="muted">&mdash;</span>;
    } else {
      switch (fieldProps.type) {
        case 'short_text':
          retValue = truncatedText(fieldValue, 100);
          break;
        case 'long_text':
          retValue = truncatedText(fieldValue, 250);
          break;
        case 'lookup':
          const collection = fieldProps.collection || fieldProps.model;
          const recordName = contentLookupRecordName(
            collection,
            fieldValue,
            field,
          );
          const recordLink = contentLookupRecordLink(collection, fieldValue);
          retValue = (
            <>
              {recordLink ? (
                <Link to={recordLink}>{recordName}</Link>
              ) : recordName ? (
                Array.isArray(recordName) ? (
                  <ul className="view-body-tags">
                    {recordName.map((name, i) => (
                      <li key={i}>{name}</li>
                    ))}
                  </ul>
                ) : (
                  recordName
                )
              ) : (
                <span className="muted">&mdash;</span>
              )}
            </>
          );
          break;
        case 'number':
          switch (fieldProps.ui) {
            case 'currency':
              retValue = formatCurrency(fieldValue, currency);
              break;
            default:
              retValue = (
                <>
                  {formatNumber(fieldValue)}
                  {fieldProps.unit || ''}
                </>
              );
          }
          break;
        case 'select':
          if (Array.isArray(fieldValue)) {
            retValue = fieldValue
              .map((value) =>
                get(find(fieldProps.options, { value }), 'label', value),
              )
              .join(', ');
          } else {
            retValue = get(
              find(fieldProps.options, { value: fieldValue }),
              'label',
              fieldValue,
            );
          }
          break;
        case 'boolean':
          retValue = fieldValue ? 'Yes' : 'No';
          break;
        case 'icon':
          retValue = <span className="iconify" data-icon={fieldValue} />;
          break;
        case 'date':
          switch (fieldProps.ui) {
            case 'datetime':
              retValue = formatDate(fieldValue, 'shortExact');
              break;
            case 'time':
              retValue = formatDate(fieldValue, 'time');
              break;
            default:
              retValue = formatDate(fieldValue, 'age');
          }
          break;
        case 'asset':
          retValue = this.renderMultiValue(fieldValue, (value) => {
            const file = (value && value.file ? value.file : value) || {};
            const fileName = file.filename || file.id;
            if (field.ui !== 'image') {
              return (
                <div>
                  <Link to={file.url} target="blank">
                    <Icon fa="file" />
                    &nbsp;&nbsp;
                    {truncatedText(
                      `${fileName} ${
                        file.content_type && `(${file.content_type})`
                      }`,
                      25,
                    )}
                  </Link>
                </div>
              );
            }
            return (
              <span className="image">
                <Link to={file.url} target="blank">
                  <img
                    src={imageUrl(file, {
                      width: 90,
                      height: 90,
                      padded: true,
                    })}
                    alt={file.filename}
                  />
                </Link>
              </span>
            );
          });
          if (field.ui === 'image') {
            return (
              <td className="image">
                <span className="collection-table-images">{retValue}</span>
              </td>
            );
          }
          break;
        case 'tags':
          if (!Array.isArray(fieldValue)) {
            retValue = fieldValue;
          } else {
            retValue = (
              <ul className="view-body-tags">
                {fieldValue.map((tag, i) => (
                  <li key={i}>{tag}</li>
                ))}
              </ul>
            );
          }
          break;
        case 'color':
          const textColor =
            textContrast.isLightOrDark(fieldValue) === 'dark'
              ? 'white'
              : 'black';
          retValue = (
            <ul className="view-body-tags">
              <li
                className="color"
                style={{ backgroundColor: fieldValue, color: textColor }}
              >
                <span>{fieldValue}</span>
              </li>
            </ul>
          );
          break;
        default:
          if (isEmpty(fieldValue)) {
            retValue = <span className="muted">&mdash;</span>;
          } else {
            retValue = fieldValue;
          }
      }
    }

    return (
      <td key={field.id}>
        <div>{retValue}</div>
      </td>
    );
  }

  renderMultiValue(fieldValue, renderer) {
    if (Array.isArray(fieldValue)) {
      return fieldValue.map((value, i) => (
        <Fragment key={i}>{renderer(value)}</Fragment>
      ));
    }

    return renderer(fieldValue);
  }

  renderValue = ({ value, index }) => {
    const { fields } = this.props;
    const titleFields = findContentTitleFields(fields);
    return titleFields.length > 0
      ? [
          ...(this.fieldImageType(titleFields[0])
            ? this.renderImageValue(value, titleFields[0])
            : []),
          ...titleFields.map((field, i) => this.renderFieldValue(field, value)),
        ]
      : [<td key="value">#{index}</td>];
  };

  fieldValue(field, value) {
    const fieldValue = get(value, field.id);
    return fieldValue !== undefined ? fieldValue : field.default;
  }

  rootValue(field, value) {
    // Get initial default root value if field has localized defaults
    if (field.localized) {
      if (!value.$locale && field.id && field.$locale) {
        const parts = field.id.split('.');
        const fieldKey = parts.pop() || field.id;
        let pointer = value;
        if (parts.length > 0) {
          pointer = get(value, parts.join('.'));
        }
        pointer.$locale = pointer.$locale || {};
        for (const key of Object.keys(field.$locale)) {
          if (get(pointer.$locale[key], fieldKey) === undefined) {
            pointer.$locale[key] = pointer.$locale[key] || {};
            set(
              pointer.$locale[key],
              fieldKey,
              get(field.$locale[key], 'default'),
            );
          }
        }
      }
      if (!value.$currency && field.id && field.$currency) {
        const parts = field.id.split('.');
        const fieldKey = parts.pop() || field.id;
        let pointer = value;
        if (parts.length > 0) {
          pointer = get(value, parts.join('.'));
        }
        pointer.$currency = pointer.$currency || {};
        for (const key of Object.keys(field.$currency)) {
          if (get(pointer.$currency[key], fieldKey) === undefined) {
            pointer.$currency[key] = pointer.$currency[key] || {};
            set(
              pointer.$currency[key],
              fieldKey,
              get(field.$currency[key], 'default'),
            );
          }
        }
      }
    }
    return value;
  }

  renderForm = (thisValue, thisIndex, allValues) => {
    const { fields } = this.state;

    const record = allValues[thisIndex];

    return (
      <>
        {fields.length > 0 ? (
          <ContentFieldGroups
            {...this.props}
            root={this.props.model?.root}
            fields={fields}
            record={record}
            values={thisValue}
          />
        ) : (
          <table className="collection-table outer headless">
            <tbody>
              <tr>
                <td align="center" className="muted">
                  No fields added yet
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </>
    );
  };

  onChangeModalForm = (_allValues, thisValue, _index, prevValue) => {
    this.setState({ modalValues: thisValue });
    if (thisValue?.type !== undefined && prevValue?.type !== undefined) {
      this.setState({
        ...this.getFieldsByTypeWithModels(this.props, thisValue.type),
      });
    }
  };

  onCloseModalForm = () => {
    this.setState({ modalValues: null });
  };

  onAddRow = () => {
    this.setState({
      ...this.getFieldsByTypeWithModels(this.props),
    });
  };

  onEditRow = (thisValue) => {
    if (thisValue?.type) {
      this.setState({
        ...this.getFieldsByTypeWithModels(this.props, thisValue.type),
      });
    }
  };

  render() {
    const {
      name,
      label,
      description,
      rawLabel,
      record,
      value,
      defaultValue,
      lookup,
      disabled,
      readonly,
      child,
      view,
      collection,
      collectionModel,
      childCollection,
      childCollectionModel,
    } = this.props;

    const fieldLabel = contentFieldLabel({
      ...this.props,
      label: rawLabel || label,
    });

    return (
      <div className="content-collection">
        {child && collection && collectionModel ? (
          <ChildCollection
            {...this.props}
            childCollection={childCollection ? childCollection : collection}
            childCollectionModel={
              childCollection ? childCollectionModel : collectionModel
            }
            parentRecord={
              childCollectionModel?.parent === view?.contentModel?.collection
                ? record
                : undefined
            }
          />
        ) : child ? null : (
          <>
            <Label help={{ field: this.props, message: description }}>
              {label}
            </Label>
            <Fieldtable
              name={name}
              label={singularize(fieldLabel)}
              value={Array.isArray(value) ? value : undefined}
              defaultValue={
                Array.isArray(defaultValue) ? defaultValue : undefined
              }
              renderHeading={this.renderHeading}
              renderValue={this.renderValue}
              renderForm={this.renderForm}
              formWidth={950}
              localized={this.state.localized}
              lookup={lookup}
              disabled={disabled}
              readonly={readonly}
              sortable={!disabled}
              addable={!disabled}
              editable={!disabled}
              removable={!disabled}
              confirmEdited
              className="collection-table-no-text-overflow"
              onCloseModal={this.onCloseModalForm}
              onChangeModalForm={this.onChangeModalForm}
              onAddRow={this.onAddRow}
              onEditRow={this.onEditRow}
            />
          </>
        )}
      </div>
    );
  }
}
