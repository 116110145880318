import React from 'react';
import { find, get, map } from 'lodash';
import Link from 'components/link';
import DateTime from 'components/date-time';
import SectionHeader from 'components/section-header';
import ButtonLink from 'components/button/button-link';
import {
  formatNumber,
  formatCurrency,
  formatTableValue,
  template,
  imageUrl,
  classNames,
} from 'utils';
import { renderTemplate, truncatedText } from 'utils/collection';

export default function CollectionTable({
  title,
  titleCount = false,
  subtitle,
  fields,
  rows,
  action,
  onClickRow,
}) {
  const onClickRowInt = onClickRow || function onClick() {};

  return (
    <div className="view-body-section">
      <div className="view-body-subheader">
        <SectionHeader
          className="view-body-subtitle"
          title={title}
          subtitle={subtitle}
          count={rows?.length}
        />
        {action && <div>{action}</div>}
      </div>
      <div className="view-section-content collection-full">
        {rows?.length > 0 ? (
          <div className="collection-table-container">
            <table className="collection-table">
              <thead>
                <tr>
                  {map(
                    fields,
                    (field, key) => field && <th key={key}>{field.label}</th>,
                  )}
                </tr>
              </thead>
              <tbody>
                {rows.map((record, index) => (
                  <tr key={index}>
                    {map(
                      fields,
                      (field, key) =>
                        field && (
                          <CollectionRowField
                            key={key}
                            id={key}
                            record={record}
                            field={field}
                            onClick={onClickRowInt}
                            index={index}
                          />
                        ),
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="collection-none-found">
            No {title.toLowerCase()} yet
          </div>
        )}
      </div>
    </div>
  );
}

export class CollectionRowField extends React.PureComponent {
  state = { value: undefined };

  componentDidMount() {
    this.setValueState(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.field !== this.props.field ||
      nextProps.record !== this.props.record
    ) {
      this.setValueState(nextProps);
    }
  }

  setValueState(props) {
    const { field } = props;
    if (field.template) {
      this.getValueFromRecordTemplate(props).then((value) =>
        this.setState({ value }),
      );
    } else {
      const value = this.getValueFromRecord(props);
      this.setState({ value });
    }
  }

  getValueFromRecord({ id, field, record }) {
    let value;

    if (field.func) {
      value = field.func(record);
    } else if (field.path) {
      value = get(record, field.path);
    } else {
      value = record[id];
    }

    if (field.options) {
      const optionValue = find(field.options, { value });
      if (optionValue?.label) {
        value = optionValue.label;
      }
    }

    return value || null;
  }

  async getValueFromRecordTemplate({ field, record }) {
    if (field.template) {
      return await renderTemplate(field.template, record);
    }
  }

  render() {
    const { field, record, onClick, index } = this.props;
    const { value } = this.state;

    if (value === undefined) {
      return null;
    }

    const colProps = {
      'data-index': index,
      onClick,
      className: classNames(
        `${field.template ? 'string' : field.type}`,
        field.className,
        {
          right: field.align === 'right',
        },
      ),
      width: field.width,
    };

    switch (field.type || 'string') {
      case 'link':
        const url = fieldLinkUrl(field.url, record);
        return (
          <td {...colProps} data-url={url}>
            {formatTableValue(
              value && (
                <Link className="collection-table-link" to={url}>
                  {truncatedText(value, 96)}
                </Link>
              ),
            )}
          </td>
        );

      case 'date':
        colProps.className += ' nowrap';
        return (
          <td {...colProps}>
            {value ? (
              <DateTime value={value} format={field.format || 'age'} />
            ) : (
              <span className="muted">&mdash;</span>
            )}
          </td>
        );

      case 'currency':
        colProps.className += ' currency';
        return (
          <td {...colProps}>
            {field.func
              ? value
              : formatCurrency(
                  value,
                  record.currency,
                  field.rounded ? { precision: undefined } : undefined,
                )}
          </td>
        );

      case 'image': {
        const imageValue = value && (value[0] || value);

        const image = imageValue && (
          <span className="image">
            {field.func
              ? value
              : imageValue && (
                  <img
                    title={imageValue.caption || ''}
                    alt={imageValue.caption || ''}
                    src={imageUrl(imageValue, {
                      width: 90,
                      height: 90,
                      padded: true,
                    })}
                  />
                )}
          </span>
        );

        colProps.className += ' image';
        return (
          <td {...colProps}>
            <span className="collection-table-images">
              {image ? (
                field.url ? (
                  <Link to={fieldLinkUrl(field.url, record)}>{image}</Link>
                ) : (
                  image
                )
              ) : (
                <span className="image">&nbsp;</span>
              )}
            </span>
          </td>
        );
      }

      case 'order-images':
        return (
          <td {...colProps}>
            <span className="collection-table-images">
              {Array.isArray(value) &&
                value.map(
                  (item, index) =>
                    item.product &&
                    (index < 4 ? (
                      <span key={index} className="image">
                        <img
                          title={item.name || item.product.name}
                          alt={item.name || item.product.name}
                          src={imageUrl(item.product || item, {
                            width: 45,
                            height: 45,
                            padded: true,
                          })}
                        />
                      </span>
                    ) : index === 4 ? (
                      <span key={index} className="image-overflow">
                        ...
                      </span>
                    ) : null),
                )}
            </span>
          </td>
        );

      case 'string':
        return (
          <td {...colProps}>
            {value?.$$typeof
              ? value
              : formatTableValue(truncatedText(value, 96))}
          </td>
        );

      default:
        return <td {...colProps}>{formatTableValue()}</td>;
    }
  }
}

export function fieldLinkUrl(fieldUrl, record) {
  if (typeof fieldUrl === 'string') {
    return template(fieldUrl, record);
  }

  if (typeof fieldUrl === 'function') {
    return fieldUrl(record);
  }

  return fieldUrl;
}
