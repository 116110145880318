import React from 'react';
import { get } from 'lodash';
import Icon from 'components/icon';
import User from '../utils/user';
import Date from '../utils/date';
import Items from '../utils/items';
import { inflect, formatDate } from 'utils';

export default function TryPeriodActivated({ event, items, trialDays }) {
  const chargeDate = get(items, '[0].changes.date_trial_end');
  if (!chargeDate) {
    return null;
  }
  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/shipment-created" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          {inflect(trialDays, 'days')} try period activated
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
        <div className="activity-event-subtitle">
          Will be charged on {formatDate(chargeDate, 'longExact')}
        </div>
        <div className="activity-event-details">
          <Items items={items} />
        </div>
      </div>
    </div>
  );
}
