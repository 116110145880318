import React, { Fragment } from 'react';
import pt from 'prop-types';

import { isEmpty } from 'utils';

import { Field, FieldLocalized } from 'components/form';
import DiscountRules from 'components/discount/rules';
import ContentFields from 'components/content/fields';
import { FadeIn } from 'components/transitions';
import SectionHeader from 'components/section-header';

import './promotion.scss';

export default class PromotionForm extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    errors: pt.object,
    values: pt.object,
    settings: pt.object.isRequired,
    content: pt.object.isRequired,
    categories: pt.object,
    lookup: pt.object,

    onChangeForm: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
    closeModal: pt.func.isRequired,
  };

  render() {
    const {
      record = {},
      errors = {},
      values = {},
      settings,
      content,
      categories,
      lookup,
    } = this.props;

    const hasGroups = !isEmpty(settings.accounts?.groups);

    const checkLimitUses =
      values.check_limit_uses !== undefined
        ? !!values.check_limit_uses
        : record.limit_uses > 0;

    const checkLimitAccountUses =
      values.check_limit_account_uses !== undefined
        ? !!values.check_limit_account_uses
        : record.limit_account_uses > 0;

    const checkLimitAccountGroups =
      hasGroups && values.check_limit_account_groups !== undefined
        ? !!values.check_limit_account_groups
        : !isEmpty(record.limit_account_groups);

    return (
      <div className="promotion-form">
        <div className="view-body-section-group">
          <div className="view-body-section">
            <div className="view-section-content no-header">
              <fieldset className="full">
                <div className="row">
                  <FieldLocalized
                    type="text"
                    name="name"
                    label="Promotion name"
                    defaultValue={record.name}
                    localeValue={record.$locale}
                    error={errors.name}
                    required={true}
                    placeholder="Super sale"
                    className="span2"
                  />
                </div>

                <FieldLocalized
                  type="textarea"
                  label="Description"
                  name="description"
                  defaultValue={record.description}
                  localeValue={record.$locale}
                  error={errors.description}
                  placeholder="Optional"
                  rows={2}
                />

                {settings.discounts?.features?.groups &&
                  settings.discounts?.groups && (
                    <Field
                      type="select"
                      label="Discount group"
                      name="group"
                      defaultValue={record.group || ''}
                      error={errors.group}
                      options={settings.discounts.groups}
                      placeholder="Optional"
                      className="span2"
                    />
                  )}
              </fieldset>
            </div>
          </div>

          <div className="view-body-section">
            <div className="view-body-subheader">
              <SectionHeader className="view-body-subtitle" title="Discounts" />
            </div>
            <div className="view-section-content">
              <fieldset className="full">
                <DiscountRules
                  record={record}
                  values={values}
                  categories={categories}
                  lookup={lookup}
                  settings={settings}
                  onChangeForm={this.props.onChangeForm}
                />
              </fieldset>
            </div>
          </div>

          <div className="view-body-section">
            <div className="view-body-subheader">
              <SectionHeader
                className="view-body-subtitle"
                title="Usage limits"
              />
            </div>
            <div className="view-section-content">
              <fieldset className="full">
                <Field
                  type="checkbox"
                  name="check_limit_uses"
                  readonly={true}
                  label="Limit number of times this promo can be used"
                  defaultChecked={checkLimitUses}
                  className="snug"
                />

                <FadeIn active={checkLimitUses} transitionAppear={false}>
                  <div className="row indented">
                    <Field
                      type="number"
                      name="limit_uses"
                      defaultValue={record.limit_uses}
                      placeholder="0"
                      readonly={!checkLimitUses}
                      required={checkLimitUses}
                      selectFocus={true}
                      className="span1 snug2"
                    />
                  </div>
                </FadeIn>

                <Field
                  type="checkbox"
                  name="check_limit_account_uses"
                  readonly={true}
                  label={
                    <span>
                      Limit promo to one per customer{' '}
                      <span className="muted">(tracked by email)</span>
                    </span>
                  }
                  defaultChecked={checkLimitAccountUses}
                  className="snug"
                />

                <Field
                  type="hidden"
                  name="limit_account_uses"
                  readonly={!checkLimitAccountUses}
                  value={1}
                />

                {hasGroups && (
                  <Fragment>
                    <Field
                      type="checkbox"
                      name="check_limit_account_groups"
                      readonly={true}
                      label="Limit promo to specific customer groups"
                      defaultChecked={checkLimitAccountGroups}
                      className="snug"
                    />

                    <FadeIn
                      active={checkLimitAccountGroups}
                      transitionAppear={false}
                    >
                      <div className="row indented">
                        <Field
                          type="checkbox"
                          name="limit_account_groups"
                          options={settings.accounts.groups}
                          defaultValue={record.limit_account_groups}
                          readonly={!checkLimitAccountGroups}
                          className="snug2"
                        />
                      </div>
                    </FadeIn>
                  </Fragment>
                )}
              </fieldset>
            </div>
          </div>

          <div className="view-body-section">
            <div className="view-body-subheader">
              <SectionHeader
                className="view-body-subtitle"
                title="Active dates"
              />
              <div className="view-body-actions">
                {record.id && (
                  <div className="row">
                    <Field
                      type="toggle"
                      name="active"
                      label="Enabled"
                      defaultChecked={record.active}
                      error={errors.active}
                      className="span2"
                      help="Enabled promotion will be applied within active dates"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="view-section-content">
              <div>
                <div
                  className={record.id && !values.active ? 'form-muted' : ''}
                >
                  <fieldset className="full">
                    <div className="row">
                      <Field
                        type="date"
                        name="date_start"
                        label="Start date"
                        defaultValue={record.date_start}
                        defaultNow={true}
                        className="span2"
                      />

                      <Field
                        type="time"
                        name="date_start_time"
                        label="Start time"
                        defaultValue={record.date_start}
                        className="span2"
                      />
                    </div>
                    <div className="row">
                      <Field
                        type="checkbox"
                        name="set_date_end"
                        label="Set end date"
                        readonly={true}
                        defaultChecked={!!record.date_end}
                      />
                    </div>
                    {values.set_date_end ||
                    (values.set_date_end === undefined && !!record.date_end) ? (
                      <FadeIn transitionAppear={values.set_date_end}>
                        <div className="row">
                          <Field
                            type="date"
                            name="date_end"
                            label="End date"
                            defaultValue={record.date_end}
                            required={true}
                            className="span2"
                            autoFocus={true}
                          />

                          <Field
                            type="time"
                            name="date_end_time"
                            label="End time"
                            defaultValue={record.date_end}
                            className="span2"
                          />
                        </div>
                      </FadeIn>
                    ) : (
                      <Fragment>
                        <Field type="hidden" name="date_end" value="" />
                        <Field type="hidden" name="date_end_time" value="" />
                      </Fragment>
                    )}
                  </fieldset>
                </div>
              </div>
            </div>
          </div>

          <ContentFields
            {...this.props}
            zone="content"
            collection="promotions"
            models={content.models}
            record={record}
            values={values}
            currency={record.currency}
          />
        </div>
      </div>
    );
  }
}
