import React from 'react';
import pt from 'prop-types';

import { formatNumber } from 'utils';

export default class CollectionShowing extends React.PureComponent {
  static propTypes = {
    collection: pt.object.isRequired,
  };

  static contextTypes = {
    client: pt.object.isRequired,
  };

  render() {
    const {
      client: { locale },
    } = this.context;

    const { collection } = this.props;

    return (
      <div className="collection-showing">
        Showing
        {collection.pages && collection.pages[collection.page] ? (
          <span>
            &nbsp;
            {formatNumber(
              collection.pages[collection.page].start,
              locale,
            )} - {formatNumber(collection.pages[collection.page].end, locale)}{' '}
            of {formatNumber(collection.count, locale)}
          </span>
        ) : (
          <span>
            &nbsp;1 - {formatNumber(collection.count, locale)} of{' '}
            {formatNumber(collection.count, locale)}
          </span>
        )}
      </div>
    );
  }
}
