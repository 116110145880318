import React from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';
import Link from 'components/link';
import Icon from 'components/icon';
import ViewPreferencesMenu from 'components/view/preferences-menu';
import { objectToArray, locationWithQuery } from 'utils';
import classNames from 'classnames';
import './tabs.scss';

const numVisible = 100;

export default class Tabs extends React.PureComponent {
  static contextTypes = {
    clientTabs: PropTypes.array,
    swellTabs: PropTypes.array,
    cleanSaveTab: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.getTabState(props),
    };
  }

  componentDidUpdate(prevProps) {
    const { items, withPreferences } = this.props;
    if (
      prevProps.items !== items ||
      prevProps.withPreferences !== withPreferences
    ) {
      this.setState({
        ...this.getTabState(this.props),
      });
    }
  }

  getTabState(props) {
    const allItems = objectToArray(props.items);
    const visibleItems = allItems.filter((tab) => !tab.hidden);
    const items = visibleItems.slice(0, numVisible);
    const moreItems = [
      ...visibleItems.slice(numVisible),
      ...allItems.filter((tab) => tab.hidden),
    ];
    return {
      items,
      moreItems,
    };
  }

  onClick = (event, tab) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(tab);
      event.preventDefault();
    }
  };

  isActive = (tab, key) => {
    const { items, active, location } = this.props;
    if (tab.link) {
      if (location.pathname.endsWith(tab.link)) {
        return true;
      }
      if (key === 'default') {
        const anyActive = some(items, (tab) =>
          location.pathname.endsWith(tab.link),
        );
        if (!anyActive) {
          return true;
        }
      }
      return false;
    }
    return active === key || (!active && key === 'default');
  };

  onClickTabItem = (item) => {
    const { router, location } = this.props;
    if (router) {
      router.push(
        locationWithQuery(location, {
          tab: item.id === 'default' ? undefined : item.id,
        }),
      );
    }
  };

  renderPreferencesMenu() {
    const { items, withPreferences } = this.props;
    const { clientTabs, swellTabs, cleanSaveTab } = this.context;

    if (!withPreferences) {
      return null;
    }

    return (
      <div className="tabs-preferences">
        <ViewPreferencesMenu
          sideOffset={8}
          buttonChildren={<Icon fa="ellipsis-h" />}
          preferenceKey="tabs"
          items={items}
          clientItems={clientTabs}
          swellItems={swellTabs}
          cleanSaveItem={cleanSaveTab}
          onClickItem={this.onClickTabItem}
        />
      </div>
    );
  }

  renderTab(tab, key) {
    const { location } = this.props;
    const id = tab.id || key;

    return (
      <li key={id} className={this.isActive(tab, id) ? 'active' : ''}>
        <Link
          to={
            tab.link ||
            locationWithQuery(location, {
              tab: id === 'default' ? undefined : id,
            })
          }
          onClick={(event) =>
            this.onClick(event, id === 'default' ? undefined : id)
          }
        >
          {tab.label}
        </Link>
      </li>
    );
  }

  renderMoreTabs() {
    const { items: allItems } = this.props;
    const { moreItems: moreItemsAll, withPreferences } = this.state;
    const { cleanSaveTab } = this.context;

    // Shows a hidden tab at the top levle if active
    const moreItemActive = moreItemsAll.filter((tab) =>
      this.isActive(tab, tab.id),
    )[0];
    const moreItems = moreItemsAll.filter(
      (tab) => tab !== moreItemActive && !tab.hidden,
    );

    return (
      <>
        {moreItemActive && this.renderTab(moreItemActive)}
        {moreItems.length > 0 && (
          <li className="tabs-more">
            <ViewPreferencesMenu
              listOnly={true}
              withPreferences={withPreferences}
              sideOffset={6}
              preferenceKey="tabs"
              buttonChildren={<>{moreItems.length} more...</>}
              items={allItems}
              filter={(item) => moreItems.find((mi) => mi.id === item.id)}
              cleanSaveItem={cleanSaveTab}
              onClickItem={this.onClickTabItem}
            />
          </li>
        )}
      </>
    );
  }

  render() {
    const { className } = this.props;
    const { items } = this.state;

    return (
      <div className="tabs-container">
        <ul className={classNames('tabs', { className: className })}>
          {items.map((tab, key) => {
            if (!tab || tab.hidden) return null;
            return this.renderTab(tab, key);
          })}
          {this.renderMoreTabs()}
        </ul>
        {this.renderPreferencesMenu()}
      </div>
    );
  }
}
