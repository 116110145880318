import api from 'services/api';

export const DASHBOARD_FETCH = 'dashboard/fetch';
export const DASHBOARD_LOADING = 'dashboard/loading';

const SETUP_QUERIES = {
  orders: {
    url: '/orders/:count',
    required: true,
  },
  products: {
    url: '/products/:count',
    data: () => ({
      demo: { $ne: true },
    }),
  },
  payments: {
    url: '/settings/payments/methods/card',
    func: (card) => card && (card.gateway !== 'test' || card.enabled === false),
  },
  shipping: {
    url: '/settings/shipments/locations/0/address1',
  },
  taxes: {
    url: '/settings/taxes/date_updated',
  },
  promotions: {
    feature: true,
    url: '/promotions/:count',
  },
  giftcards: {
    feature: true,
    url: '/products/:count?type=giftcard',
  },
  cart_recovery: {
    feature: true,
    url: '/promotions/:count',
  },
};

const dashboard = {
  fetch(start, end) {
    return (dispatch, getState) => {
      const { client, account } = getState();

      return dispatch({
        type: DASHBOARD_FETCH,
        payload: api
          .get(
            '/data/:batch',
            Object.entries(SETUP_QUERIES).reduce((acc, [key, query]) => {
              if (
                query.required ||
                ((!query.feature || account.features[key]) &&
                  !client.setup[key])
              ) {
                acc[key] = {
                  url: query.url,
                  data: query.data ? query.data(client, account) : undefined,
                };
              }

              return acc;
            }, {}),
          )
          .then((results) => {
            // Storefront
            return api
              .get('/storefronts/:last', {
                client_id: client.id,
                primary: true,
              })
              .then((storefront) => {
                return {
                  ...results,
                  storefront: Boolean(storefront?.created),
                };
              });
          })
          .then((results) => {
            return {
              hasOrders: Boolean(results.orders),
              setup: {
                ...client.setup,
                ...Object.entries(results).reduce((acc, [key, result]) => {
                  const queryConfig = SETUP_QUERIES[key];

                  acc[key] = Boolean(
                    queryConfig && typeof queryConfig.func === 'function'
                      ? queryConfig.func(result)
                      : result,
                  );

                  return acc;
                }, {}),
                storefront: results.storefront,
              },
            };
          }),
        meta: { start, end },
      });
    };
  },
};

export const initialState = {
  start: null,
  end: null,
  setup: {},
  loading: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET':
      return initialState;

    case DASHBOARD_FETCH:
      return {
        ...state,
        ...action.meta,
        ...action.payload,
      };

    case DASHBOARD_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}

export default dashboard;
