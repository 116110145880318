import api from 'services/api';
import { arrayToMap } from 'utils';

const ATTRIBUTES_UPDATE = 'attributes/update';
const ATTRIBUTES_REMOVE = 'attributes/remove';
const ATTRIBUTES_FETCH = 'attributes/fetch';
const ATTRIBUTES_ADD = 'attributes/add';

const actions = {
  fetch() {
    return {
      type: ATTRIBUTES_FETCH,
      payload: api
        .getLocalized('/data/attributes', {
          limit: 1000,
          sort: 'name asc',
        })
        .then((result) => {
          return {
            ...result,
            index: arrayToMap(result.results),
            fetched: true,
          };
        }),
    };
  },

  load(refresh = false) {
    return (dispatch, getState) => {
      const { attributes } = getState();
      if (attributes.fetched && !refresh) {
        return Promise.resolve(attributes);
      }
      return dispatch(this.fetch());
    };
  },

  fetchByIds(ids) {
    return {
      type: ATTRIBUTES_FETCH,
      payload: api
        .getLocalized('/data/attributes', {
          id: { $in: ids },
          sort: 'name asc',
          limit: null,
        })
        .then((result) => {
          return {
            ...result,
            index: arrayToMap(result.results),
            fetched: false,
          };
        }),
    };
  },

  addToStore(attr) {
    return {
      type: ATTRIBUTES_ADD,
      payload: attr,
    };
  },

  updateInStore(attr) {
    return {
      type: ATTRIBUTES_UPDATE,
      payload: attr,
    };
  },

  removeFromStore(attrId) {
    return {
      type: ATTRIBUTES_REMOVE,
      payload: attrId,
    };
  },
};

export default actions;

export function fetchAttribute(id) {
  return api.getLocalized(`/data/attributes/${id}`);
}

export const initialState = {
  fetched: false,
  results: [],
  index: new Map(),
  count: 0,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET':
      return initialState;

    case ATTRIBUTES_FETCH: {
      if (action.payload.error) {
        return state;
      }

      return {
        ...state,
        ...action.payload,
      };
    }

    case ATTRIBUTES_ADD: {
      const attr = action.payload;
      const results = [...state.results, attr];
      const index = new Map(state.index);

      index.set(attr.id, attr);

      return {
        ...state,
        results,
        index,
        count: state.count + 1,
      };
    }

    case ATTRIBUTES_UPDATE: {
      const attr = action.payload;
      const results = [...state.results];
      const index = new Map(state.index);

      index.set(attr.id, attr);

      const pos = results.findIndex((item) => item.id === attr.id);

      if (pos !== -1) {
        results[pos] = attr;
      }

      return {
        ...state,
        results,
        index,
      };
    }

    case ATTRIBUTES_REMOVE: {
      const attrId = action.payload;
      const results = state.results.filter((attr) => attr.id !== attrId);
      const index = new Map(state.index);

      index.delete(attrId);

      return {
        ...state,
        results,
        index,
        count: state.count - 1,
      };
    }

    default:
      return state;
  }
}
