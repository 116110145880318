import React from 'react';
import { connect } from 'react-redux';
import ObjectID from 'bson-objectid';
import pt from 'prop-types';

import get from 'lodash/get';
import pick from 'lodash/pick';
import find from 'lodash/find';
import extend from 'lodash/extend';
import isEmpty from 'lodash/isEmpty';

import { scrollToAnchor } from 'utils/scroll';
import { arrayToObject, objectToArray } from 'utils';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';
import { localeOptions, currencyOptions, default as geo } from 'utils/geo';

import actions from 'actions';

import ViewLoading from 'components/view/loading';
import SettingsIndexPage from 'components/settings/index';
import NotFoundPage from 'components/pages/error/404';

import General from '/components/settings/general';
import Products from '/components/settings/products';
import Customers from '/components/settings/customers';
import Orders from '/components/settings/orders';
import Subscriptions from '/components/settings/subscriptions';
import Payments from '/components/settings/payments';
import Shipping from '/components/settings/shipping';
import Taxes from '/components/settings/taxes';
import Checkout from '/components/settings/checkout';
import Giftcards from '/components/settings/giftcards';
import Notifications from '/components/settings/notifications';
import Webhooks from '/components/settings/webhooks';
import API from '/components/settings/api';
import Integrations from '/components/settings/integrations';

const mapStateToProps = (state) => ({
  client: state.client,
  settings: state.settings,
  webhooks: state.webhooks,
  content: state.content,
  categories: state.categories,
  loading: state.loading,
  notifications: state.notifications,
  suggestedAddresses: state.lookup.suggestedAddresses,
  primaryStorefront: state.storefronts.primary,
});

const mapDispatchToProps = (dispatch) => ({
  fetchClient() {
    return dispatch(actions.client.fetch());
  },

  fetchSettings: (id) => {
    return dispatch(actions.settings.fetch(id));
  },

  fetchRecord: (model, id) => {
    return dispatch(actions.data.fetchRecord(model, id));
  },

  fetchWebhooks: () => {
    return dispatch(actions.webhooks.fetchAll());
  },

  updateWebhooks: (webhooks) => {
    return dispatch(actions.webhooks.updateAll(webhooks));
  },

  fetchWebhookAttempts: (webhookId) => {
    return dispatch(actions.webhooks.fetchAttempts(webhookId));
  },

  fetchOrderWebhookStats: () => {
    return dispatch(actions.webhooks.fetchOrderWebhookStats());
  },

  fetchOrderWebhookAttempts: () => {
    return dispatch(actions.webhooks.fetchOrderWebhookAttempts());
  },

  loadCategories: () => {
    return dispatch(actions.categories.load());
  },

  fetchNotification: (id) => {
    return dispatch(actions.notifications.load(id));
  },

  fetchPrintTemplate: (id) => {
    return dispatch(actions.notifications.loadPrintTemplate(id));
  },
  resetNotificationCache: () => {
    return dispatch(actions.notifications.resetCache());
  },
  createNotification: (values) => {
    return dispatch(actions.notifications.create(values));
  },

  createPrintTemplate: (values) => {
    return dispatch(actions.notifications.createPrintTemplate(values));
  },

  updateNotification: (id, values) => {
    return dispatch(actions.notifications.update(id, values));
  },

  updatePrintTemplate: (id, values) => {
    return dispatch(actions.notifications.updatePrintTemplate(id, values));
  },

  deleteNotification: (id) => {
    return dispatch(actions.notifications.delete(id));
  },

  deletePrintTemplate: (id) => {
    return dispatch(actions.notifications.deletePrintTemplate(id));
  },

  resetPrintTemplateCache: () => {
    return dispatch(actions.notifications.resetPrintTemplateCache());
  },

  fetchEnabledNotifications: () => {
    return dispatch(actions.notifications.fetchEnabled());
  },

  fetchAbandonedCartNotifications: () => {
    return dispatch(actions.notifications.fetchAbandonedCarts());
  },

  fetchOrderPrintTemplates: () => {
    return dispatch(actions.notifications.fetchOrderPrintTemplates());
  },

  fetchCustomNotifications: () => {
    return dispatch(actions.notifications.fetchCustom());
  },

  updateNotificationsEnabled: (enabled) => {
    return dispatch(actions.notifications.updateEnabled(enabled));
  },

  sendNotificationTest: (id, values) => {
    return dispatch(actions.data.createRecord('notifications', values));
  },

  updateSettings: (id, data) => {
    return dispatch(actions.settings.update(id, data));
  },

  updateRecord: (model, id, data) => {
    return dispatch(actions.data.updateRecord(model, id, data));
  },

  updateClient: (data) => {
    return dispatch(actions.client.update(data));
  },

  fetchClientKeys: () => {
    return dispatch(
      actions.data.fetchCollection(':clients/:self/keys', { limit: null }),
    );
  },

  updateClientKey: (id, values) => {
    return dispatch(
      actions.data.updateRecord(':clients/:self/keys', id, values),
    );
  },

  createClientKey: (values) => {
    return dispatch(actions.data.createRecord(':clients/:self/keys', values));
  },

  removeClientKey: (id) => {
    return dispatch(actions.data.deleteRecord(':clients/:self/keys', id));
  },

  fetchUsers: () => {
    return dispatch(actions.data.fetchCollection(':users', { limit: null }));
  },

  updateStorefront: (slug, data) => {
    return dispatch(actions.storefronts.update(slug, data));
  },
});

export class Settings extends React.PureComponent {
  static propTypes = {
    params: pt.object.isRequired,
    router: pt.object,
    section: pt.string,
    settings: pt.object,
    notifications: pt.object,
    primaryStorefront: pt.object,

    fetchUsers: pt.func,
    fetchRecord: pt.func,
    updateRecord: pt.func,
    fetchClient: pt.func,
    updateClient: pt.func,
    fetchSettings: pt.func,
    updateSettings: pt.func,
    loadCategories: pt.func,
    fetchClientKeys: pt.func,
    createClientKey: pt.func,
    updateClientKey: pt.func,
    removeClientKey: pt.func,
    fetchWebhooks: pt.func,
    updateWebhooks: pt.func,
    fetchOrderWebhookStats: pt.func,
    fetchNotification: pt.func,
    createNotification: pt.func,
    deleteNotification: pt.func,
    fetchEnabledNotifications: pt.func,
    fetchAbandonedCartNotifications: pt.func,
    updateNotificationsEnabled: pt.func,
    fetchCustomNotifications: pt.func,
    resetNotificationCache: pt.func,
    updateNotification: pt.func,
    createPrintTemplate: pt.func,
    updatePrintTemplate: pt.func,
    deletePrintTemplate: pt.func,
    sendNotificationTest: pt.func,
    resetPrintTemplateCache: pt.func,
    fetchOrderPrintTemplates: pt.func,
    updateStorefront: pt.func,
  };

  static contextTypes = {
    user: pt.object.isRequired,
    client: pt.object.isRequired,
    openModal: pt.func.isRequired,
    notifyError: pt.func.isRequired,
    notifySuccess: pt.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loaded: false,
      edited: false,
      record: null,
      values: {},
      section: props.params.section,
      submittingUpdate: false,
      onSubmitForm: this.onSubmitForm.bind(this),
      onSubmitClientUpdate: this.onSubmitClientUpdate.bind(this),
      onChangeForm: this.onChangeForm.bind(this),
      refetchSettings: this.refetchSettings.bind(this),
      onSaveClientKey: this.onSaveClientKey.bind(this),
      onRemoveClientKey: this.onRemoveClientKey.bind(this),
      onUpdateNotification: this.onUpdateNotification.bind(this),
      onSendNotificationTest: this.onSendNotificationTest.bind(this),
      onCreateAbandonedCartNotification:
        this.onCreateAbandonedCartNotification.bind(this),
      onCreateOrderPrintTemplate: this.onCreateOrderPrintTemplate.bind(this),
      onDeleteOrderPrintTemplate: this.onDeleteOrderPrintTemplate.bind(this),
      onDeleteAbandonedCartNotification:
        this.onDeleteAbandonedCartNotification.bind(this),
      onUpdatePrintTemplate: this.onUpdatePrintTemplate.bind(this),
      onClickEditNotification: this.onClickEditNotification.bind(this),
    };
  }

  componentDidMount() {
    confirmRouteLeave(this);

    const { params } = this.props;

    if (params.section) {
      this.fetchSettings(params.section)
        .then(this.mapSubscriptionRetryRules)
        .then((settings) => {
          if (settings) {
            this.setState({
              loaded: true,
              record: settings,
              values: { ...settings },
            });
          }
        })
        .then(scrollToAnchor);
    } else {
      this.setState({ loaded: true });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.params.section !== state.section) {
      return { loaded: false, section: props.params.section };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);

    const { params } = this.props;

    if (params.section && prevProps.params.section !== params.section) {
      this.fetchSettings(params.section).then((settings) => {
        if (settings) {
          this.setState({
            loaded: true,
            record: settings,
            values: { ...settings },
          });
        }
      });
    }
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  async onUpdateNotification(values) {
    const {
      updateNotification,
      notifications: { record },
    } = this.props;

    const result = await updateNotification(record.id, values);

    if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    return true;
  }

  async onUpdatePrintTemplate(values, form = null) {
    const {
      updatePrintTemplate,
      resetPrintTemplateCache,
      fetchOrderPrintTemplates,
      notifications: { record },
    } = this.props;

    this.setState({ submittingUpdate: true });

    const result = await updatePrintTemplate(record.id, values);

    if (!result) {
      this.setState({ submittingUpdate: false });
      return false;
    } else if (result.errors) {
      this.context.notifyError(result.errors);
      this.setState({ submittingUpdate: false });
      return false;
    }

    resetPrintTemplateCache();
    await fetchOrderPrintTemplates();

    if (form) {
      form.reset();
    }

    this.setState({ submittingUpdate: false });

    return true;
  }

  async onSendNotificationTest(values) {
    const {
      sendNotificationTest,
      notifications: { record },
    } = this.props;

    const { client, user } = this.context;
    const toEmail = values.to || user.email;
    const fromEmail = record.from || client.support_email;

    const template = `${record.app_id ? `app_${record.app_id}.` : ''}${
      record.model
    }.${record.name}`;

    const result = await sendNotificationTest(record.id, {
      template,
      data: record.sample,
      test: true,
      to: toEmail,
      from: fromEmail,
      locale: values.locale,
      $config: values.$config,
    });

    if (result && result.errors) {
      this.context.notifyError(result.errors);
      return false;
    } else if (result) {
      this.context.notifySuccess(`Test notification sent to ${toEmail}`);
      return true;
    }
  }

  async onCreateOrderPrintTemplate({
    templateName,
    content,
    fields,
    contact,
    query,
    sample,
    description,
  }) {
    const { createPrintTemplate, fetchOrderPrintTemplates } = this.props;
    const { record, values } = this.state;

    let notificationSettings;

    const result = await createPrintTemplate({
      model: 'orders',
      name: `print-${templateName.toLowerCase().split(' ').join('-')}.v2`,
      description,
      enabled: true,
      v2: true,
      label: templateName,
      fields,
      contact,
      sample,
      query,
      method: 'print',
      content: {
        html: {
          data: content,
        },
      },
    });

    await fetchOrderPrintTemplates();

    this.setState({
      record: {
        ...record,
        ...notificationSettings,
      },
      values: {
        ...values,
        ...notificationSettings,
      },
    });

    if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    return true;
  }

  async onCreateAbandonedCartNotification(number = null) {
    const {
      updateSettings,
      fetchNotification,
      createNotification,
      fetchAbandonedCartNotifications,
    } = this.props;
    const { record, values } = this.state;

    const abandonedCartSeries = get(record.abandoned_cart, 'series', []);

    let seriesNumber =
      number !== null ? ~~number : abandonedCartSeries.length + 1;

    let source;
    if (number === null && abandonedCartSeries.length > 0) {
      source = await fetchNotification(`carts.recovery-${seriesNumber - 1}`);
    }

    if (!source) {
      source = await fetchNotification('carts.recovery');
      if (!source || !source.record) {
        this.context.notifyError(
          'Unable to fetch source abandoned cart notification',
        );
        return false;
      }
    }

    let notificationSettings;
    if (number !== null) {
      notificationSettings = await updateSettings('notifications');
    } else {
      notificationSettings = await updateSettings('notifications', {
        abandoned_cart: {
          ...(record.abandoned_cart || {}),
          enabled: true,
          series: [
            ...abandonedCartSeries,
            {
              delay: 2,
              hour: 10,
            },
          ],
        },
      });
      if (notificationSettings.errors) {
        this.context.notifyError(notificationSettings.errors);
        return false;
      }
    }

    const result = await createNotification({
      model: 'carts',
      name: `recovery-${seriesNumber}.v2`,
      enabled: true,
      v2: true,
      label: `Cart Recovery Follow-up ${seriesNumber}`,
      ...pick(source.record, [
        'contact',
        'method',
        'query',
        'sample',
        'subject',
      ]),
      fields: source.record.fields,
      conditions: {
        $data: {
          $notify: `recovery-${seriesNumber}`,
        },
      },
      content: {
        html: {
          data: source.content,
        },
      },
    });

    await fetchAbandonedCartNotifications();

    this.setState({
      record: {
        ...record,
        ...notificationSettings,
      },
      values: {
        ...values,
        ...notificationSettings,
      },
    });

    if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    return true;
  }

  async onDeleteAbandonedCartNotification(id) {
    const { updateSettings, deleteNotification, notifications } = this.props;
    const { record, values } = this.state;

    const seriesNumber = id.replace('carts.recovery-', '');
    const updatedSeries = [...get(record.abandoned_cart, 'series', [])];
    updatedSeries.splice(seriesNumber - 1, 1);

    const result = await updateSettings('notifications', {
      $set: {
        abandoned_cart: {
          ...(record.abandoned_cart || {}),
          series: updatedSeries,
        },
      },
    });

    if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    const realId = get(notifications.abandonedCarts[id], 'id');
    if (realId) {
      await deleteNotification(notifications.abandonedCarts[id].id);
    }

    this.setState({
      record: {
        ...record,
        ...result,
      },
      values: {
        ...values,
        ...result,
      },
    });

    return true;
  }

  async onDeleteOrderPrintTemplate(id) {
    const { deletePrintTemplate, notifications } = this.props;
    const { record, values } = this.state;

    const orderPrintTemplate = notifications.orderPrintTemplates.results.find(
      (template) => template.id.includes(id),
    );

    if (orderPrintTemplate.id) {
      const result = await deletePrintTemplate(orderPrintTemplate.id);

      if (get(result, 'errors', undefined)) {
        this.context.notifyError(result.errors);
        return false;
      }
    }

    this.setState({
      record: {
        ...record,
      },
      values: {
        ...values,
      },
    });

    return true;
  }

  async fetchSettings(section) {
    const { fetchSettings, router } = this.props;

    switch (section) {
      case 'account':
        return this.fetchAccountSettings();

      case 'account-plans':
        router.replace('/settings/account/plans');
        return false;

      case 'general':
        return this.fetchGeneralSettings();

      case 'customers':
        return fetchSettings('accounts');

      case 'orders':
        return this.fetchOrderSettings();

      case 'payments':
        return this.fetchPaymentSettings();

      case 'shipping':
        return this.fetchShippingSettings();

      case 'taxes':
        return this.fetchTaxSettings();

      case 'users':
        return this.fetchUserSettings();

      case 'webhooks':
        return this.fetchWebhookSettings();

      case 'notifications': {
        return this.fetchNotificationSettings();
      }

      case 'integrations':
        return this.fetchIntegrationSettings();

      case 'api':
        return this.fetchAPISettings();

      default:
        return fetchSettings(section);
    }
  }

  async updateSettings(section, values) {
    const { updateSettings } = this.props;

    switch (section) {
      case 'account':
        return this.updateAccountSettings(values);

      case 'general':
        return this.updateGeneralSettings(values);

      case 'customers':
        return this.updateCustomerSettings(values);

      case 'orders':
        return this.updateOrderSettings(values);

      case 'payments':
        return this.updatePaymentSettings(values);

      case 'shipping':
        return this.updateShippingSettings(values);

      case 'taxes':
        return this.updateTaxSettings(values);

      case 'users':
        return this.updateUserSettings(values);

      case 'webhooks':
        return this.updateWebhookSettings(values);

      case 'notifications':
        return this.updateNotificationSettings(values);

      case 'integrations':
        return this.updateIntegrationSettings(values);

      case 'api':
        return this.updateAPISettings(values);

      case 'subscriptions':
        return this.updateSubscriptionSettings(values);

      default:
        return updateSettings(section, values);
    }
  }

  async fetchAccountSettings() {
    // noop
    return {};
  }

  async updateAccountSettings() {
    // noop
    return {};
  }

  async fetchGeneralSettings() {
    const { fetchRecord, fetchClient, fetchSettings } = this.props;

    const [client, general, shipments, orderFormat] = await Promise.all([
      fetchClient(),
      fetchSettings('general'),
      fetchSettings('shipments'),
      fetchRecord(':models', 'orders/fields/number/increment/pattern'),
    ]);

    return {
      geo: {
        ...geo,
        locales: localeOptions.map((locale) => ({
          value: locale.value,
          label: `${locale.label} (${locale.value})`,
          rawName: locale.label,
        })),
        currencies: currencyOptions.map((curr) => ({
          value: curr.value,
          label: `${curr.label} (${curr.value})`,
          rawName: curr.label,
        })),
      },
      client: {
        ...client,
        locale: client.locale ? client.locale.replace('_', '-') : client.locale,
      },
      general,
      shipments,
      orderFormat,
      ...this.parseOrderFormat(orderFormat),
    };
  }

  parseOrderFormat(orderFormat) {
    if (!orderFormat) {
      return {};
    }
    const parts = orderFormat.split('{');
    return {
      orderFormatPrefix: parts[0],
      orderFormatStart: parts[1].split('}')[0],
    };
  }

  async updateGeneralSettings(values) {
    const { updateClient, updateRecord, updateSettings } = this.props;

    const client = await updateClient(values.client);
    if (client.errors) {
      return client;
    } else {
      actions.client.setCurrency(client);
    }
    const general = await updateSettings('general', { ...values.general });
    if (general.errors) {
      return general;
    }
    const shipments = await updateSettings('shipments', {
      ...values.shipments,
    });
    if (shipments.errors) {
      return shipments;
    }

    let { orderFormat } = this.state.record;
    const valueOrderFormat = `${values.orderFormatPrefix}{${values.orderFormatStart}}`;
    if (valueOrderFormat !== orderFormat) {
      return updateRecord(':models', 'orders/fields/number/increment', {
        pattern: valueOrderFormat,
      });
    }

    return general;
  }

  async fetchPaymentSettings() {
    const { fetchSettings } = this.props;

    const [payments, orders, subscriptions, integrations] = await Promise.all([
      fetchSettings('payments'),
      fetchSettings('orders'),
      fetchSettings('subscriptions'),
      fetchSettings('integrations'),
    ]);

    const manualMethods = payments.methods.filter((m) => !!m.manual);
    const methods = arrayToObject(payments.methods.filter((m) => !m.manual));
    const gateways = arrayToObject(payments.gateways);

    return {
      ...payments,
      manualMethods,
      methods,
      gateways,
      orders,
      subscriptions,
      integrations,
    };
  }

  async updatePaymentSettings(values) {
    const { updateSettings } = this.props;
    const paypalMethod = values?.methods?.paypal;

    // Override PayPal settings based on PPE and PPCP settings
    if (!isEmpty(paypalMethod)) {
      paypalMethod.activated =
        paypalMethod.express_activated || paypalMethod.ppcp_activated;
      paypalMethod.enabled =
        paypalMethod.express_enabled || paypalMethod.ppcp_enabled;
      paypalMethod.ppcp =
        paypalMethod.ppcp_activated || paypalMethod.ppcp_enabled;
    }

    const gateways = objectToArray(
      values.gateways || this.state.values.gateways,
    );
    const methods = objectToArray(
      values.methods || this.state.values.methods,
    ).concat(
      values.manualMethods
        ? objectToArray(values.manualMethods)
        : this.state.values.manualMethods,
    );

    await updateSettings('orders', values.orders);
    await updateSettings('subscriptions', values.subscriptions);

    // TODO: update gateway settings instead
    // await updateStorefront(
    //   client.id,
    //   pick(values.storefront || {}, [
    //     'custom_authentication_enabled',
    //     'custom_authentication_url',
    //   ]),
    // );

    return updateSettings('payments', {
      ...values,
      orders: undefined,
      manualMethods: undefined,
      methods: undefined,
      gateways: undefined,
      subscriptions: undefined,
      integrations: undefined,
      storefront: undefined,
      $set: {
        methods,
        gateways,
      },
    });
  }

  async fetchShippingSettings() {
    const { fetchSettings } = this.props;

    await fetchSettings('accounts');

    const shipments = await fetchSettings('shipments');

    return {
      ...shipments,
    };
  }

  async updateShippingSettings(values) {
    const { updateSettings } = this.props;

    return updateSettings('shipments', {
      ...values,
      locations: undefined,
      services: undefined,
      zones: undefined,
      $set: {
        locations: values.locations,
        services: values.services,
        zones: values.zones,
      },
    });
  }

  async updateCustomerSettings(values) {
    const { updateSettings } = this.props;

    return updateSettings('accounts', {
      ...values,
      groups: undefined,
      $set: {
        groups: values.groups,
      },
    });
  }

  async fetchTaxSettings() {
    const { fetchSettings, loadCategories } = this.props;

    const categories = await loadCategories();

    await fetchSettings('shipments');

    const taxes = await fetchSettings('taxes');

    if (taxes.rules) {
      taxes.rules = taxes.rules.map((rule) => {
        if (rule.categories) {
          rule.categories = rule.categories
            .map((categoryId) => {
              return categories.index.get(categoryId);
            })
            .filter((x) => !!x);
        }
        return rule;
      });
    }

    return {
      ...taxes,
    };
  }

  async updateTaxSettings(values) {
    const { updateSettings } = this.props;

    if (values.rules) {
      values.rules = values.rules.map((rule) => {
        if (rule.categories) {
          rule.categories = rule.categories.map(
            (category) => category.id || category,
          );
        }
        return rule;
      });
    }

    return updateSettings('taxes', {
      ...values,
      rules: undefined,
      product_classes: undefined,
      account_classes: undefined,
      $set: {
        rules: values.rules,
        product_classes: values.product_classes,
        account_classes: values.account_classes,
      },
    });
  }

  async fetchNotificationSettings() {
    const {
      fetchSettings,
      fetchEnabledNotifications,
      fetchAbandonedCartNotifications,
      fetchCustomNotifications,
      resetNotificationCache,
    } = this.props;

    const notifications = await fetchSettings('notifications');
    const [enabled] = await Promise.all([
      fetchEnabledNotifications(),
      fetchAbandonedCartNotifications(),
      fetchCustomNotifications(),
      resetNotificationCache(),
    ]);

    return {
      ...notifications,
      enabled,
    };
  }

  async fetchOrderSettings() {
    const { fetchSettings, fetchOrderPrintTemplates, resetPrintTemplateCache } =
      this.props;

    const orders = await fetchSettings('orders');

    resetPrintTemplateCache();
    await fetchOrderPrintTemplates();

    return {
      ...orders,
    };
  }

  async updateNotificationSettings(values) {
    const { updateSettings, updateNotificationsEnabled } = this.props;

    await updateNotificationsEnabled(values.enabled);

    return updateSettings('notifications', {
      ...values,
      enabled: undefined,
    });
  }

  async updateOrderSettings(values) {
    const { updateSettings } = this.props;

    return updateSettings('orders', {
      ...values,
      printTemplates: undefined,
    });
  }

  async fetchWebhookSettings() {
    const { fetchWebhooks, fetchSettings, fetchOrderWebhookStats } = this.props;

    const webhooks = await fetchWebhooks();
    const orderSettings = await fetchSettings('orders');
    const orderWebhookStats = await fetchOrderWebhookStats();

    return {
      webhooks,
      orderWebhook: orderSettings.webhook || {},
      orderWebhookStats: orderWebhookStats,
    };
  }

  async updateWebhookSettings(values) {
    const { updateWebhooks, updateSettings } = this.props;

    let result;
    if (values.webhooks) {
      result = updateWebhooks(values.webhooks);
    }
    if (values.orderWebhook !== undefined && (!result || !result.errors)) {
      result = updateSettings('orders', {
        $set: {
          webhook: values.orderWebhook,
        },
      });
    }
    return result;
  }

  async fetchUserSettings() {
    const { fetchUsers } = this.props;

    const users = await fetchUsers();

    return {
      users: {
        ...users,
        results: users.results.reverse(),
      },
    };
  }

  async fetchIntegrationSettings() {
    const { fetchSettings } = this.props;

    const [integrations, payments] = await Promise.all([
      fetchSettings('integrations'),
      fetchSettings('payments'),
    ]);

    if (integrations.services) {
      for (const id in integrations.services) {
        const service = (integrations.services[id] =
          integrations.services[id] || {});
        if (service.type === 'payment') {
          const paymentSettings = find(payments.methods, { id }) || {};
          integrations.services[id] = {
            ...service,
            ...paymentSettings,
          };
        }
      }
    }

    return integrations;
  }

  async updateIntegrationSettings(values) {
    const { settings, updateSettings } = this.props;

    if (values.services) {
      let payments;
      for (const id in values.services) {
        const service = values.services[id];
        if (get(settings.integrations.services, id, {}).type === 'payment') {
          payments = settings.payments;
          const paymentSettings = find(settings.payments.methods, { id }) || {};
          extend(paymentSettings, pick(service, Object.keys(paymentSettings)));
          values.services[id] = {
            type: 'payment',
            enabled: service.enabled,
            activated: service.activated,
          };
        }
      }
      if (payments) {
        await updateSettings('payments', payments);
      }
    }
    return updateSettings('integrations', values);
  }

  async updateSubscriptionSettings(values) {
    const { updateSettings } = this.props;

    if (values.retry_rules) {
      values.$set = {
        retry_rules: values.retry_rules,
      };
      values.retry_rules = undefined;
    }

    return updateSettings('subscriptions', values);
  }

  async updateUserSettings(values) {
    // noop
    return {};
  }

  async fetchAPISettings() {
    const { fetchClientKeys } = this.props;

    const keys = await fetchClientKeys();

    return {
      keys,
    };
  }

  async onSaveClientKey(keyId, values) {
    const { params, updateClientKey, createClientKey } = this.props;

    const result = await (keyId
      ? updateClientKey(keyId, values)
      : createClientKey(values));

    if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    await this.fetchSettings(params.section).then((result) => {
      this.setState({
        edited: false,
        record: result,
        values: { ...result },
      });
    });

    return true;
  }

  async onRemoveClientKey(keyId) {
    const { params, removeClientKey } = this.props;

    const result = await removeClientKey(keyId);

    if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    await this.fetchSettings(params.section).then((result) => {
      this.setState({
        edited: false,
        record: result,
        values: { ...result },
      });
    });

    return true;
  }

  async updateAPISettings(values) {
    // noop
    return {};
  }

  onChangeForm(values, edited) {
    this.setState((state) => ({
      values: {
        ...state.values,
        ...values,
      },
      edited: state.submittingUpdate ? state.edited : edited,
    }));
  }

  async onSubmitForm(values, form = null) {
    const { params } = this.props;
    let result = await this.updateSettings(params.section, values);

    if (!result) {
      return false;
    } else if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    result = await this.fetchSettings(params.section);

    this.setState({
      record: result,
      values: { ...result },
    });

    if (form) {
      form.reset();
    }

    return result;
  }

  async onSubmitClientUpdate(values, form = null) {
    const { updateClient, primaryStorefront } = this.props;

    this.setState({ submittingUpdate: true });

    const result = await updateClient(values);

    if (!result) {
      this.setState({ submittingUpdate: false });
      return false;
    } else if (result.errors) {
      this.context.notifyError(result.errors);
      this.setState({ submittingUpdate: false });
      return false;
    }

    this.setState({
      record: {
        ...this.state.record,
        client: result,
      },
      values: {
        ...this.state.values,
        client: result,
      },
    });

    if (form) {
      form.reset();
    }

    this.setState({ submittingUpdate: false });

    try {
      if (primaryStorefront?.hosted) {
        await this.restartStorefrontPreview();
      }
    } catch (error) {
      // noop
    }

    return result;
  }

  // this queues a preview restart by updating a storefront,
  // triggering the restart handler
  restartStorefrontPreview = () => {
    const { updateStorefront, primaryStorefront } = this.props;

    return updateStorefront(primaryStorefront.id, { $restart: true });
  };

  onClickEditNotification(event) {
    event.preventDefault();
    event.target.blur();
    const { dataset } = event.currentTarget;
    this.context.openModal('EditNotification', {
      params: { id: dataset.id, default_id: dataset.default },
    });
  }

  async refetchSettings() {
    try {
      const settings = await this.fetchSettings(this.props.params.section);
      this.setState({
        record: settings,
        values: { ...settings },
      });
    } catch (err) {
      console.error(err);
    }
  }

  mapSubscriptionRetryRules = (settings) => {
    if (settings?.id !== 'subscriptions') {
      return settings;
    }

    const {
      features: { auto_payment_retry: autoPaymentRetry } = {},
      retry_rules: exRetryRules,
      retry_resolve: retryResolve,
    } = settings;
    const retryCount = settings.retry_count || 3;
    const retryDays = settings.retry_days || 3;

    if (!autoPaymentRetry || !isEmpty(exRetryRules)) {
      return settings;
    }

    const retryRules = [];
    for (let i = 1; i <= retryCount; i++) {
      const id = ObjectID().toHexString();
      retryRules.push({
        id,
        day: i * retryDays,
        retry_payment: true,
        notification_id: `subscriptions.payment-failed-${id}.v2`,
        ...(i === retryCount && { retry_resolve: retryResolve || 'nothing' }),
      });
    }

    return this.updateSubscriptionSettings({ retry_rules: retryRules });
  };

  renderPage() {
    switch (this.props.params.section) {
      case 'general':
        return <General {...this.props} {...this.state} />;
      case 'products':
        return <Products {...this.props} {...this.state} />;
      case 'customers':
        return <Customers {...this.props} {...this.state} />;
      case 'orders':
        return <Orders {...this.props} {...this.state} />;
      case 'subscriptions':
        return <Subscriptions {...this.props} {...this.state} />;
      case 'payments':
        return <Payments {...this.props} {...this.state} />;
      case 'shipping':
        return <Shipping {...this.props} {...this.state} />;
      case 'taxes':
        return <Taxes {...this.props} {...this.state} />;
      case 'checkout':
        return <Checkout {...this.props} {...this.state} />;
      case 'giftcards':
        return <Giftcards {...this.props} {...this.state} />;
      case 'notifications':
        return <Notifications {...this.props} {...this.state} />;
      case 'webhooks':
        return <Webhooks {...this.props} {...this.state} />;
      case 'api':
        return <API {...this.props} {...this.state} />;
      case 'integrations':
        return <Integrations {...this.props} {...this.state} />;
      default:
        return <SettingsIndexPage {...this.props} {...this.state} />;
    }
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    if (this.props.section && !this.props.settings[this.props.section]) {
      return <NotFoundPage />;
    }

    return this.renderPage();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
