import React from 'react';
import moment from 'moment';
import {
  Chart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Bars,
  Legend,
} from 'components/charts';

const dataForYearChart = [
  {
    id: 'first',
    description: 'First Interval',
    points: [
      [1483218000000, 0],
      [1485896400000, 0],
      [1488315600000, 0],
      [1490994000000, 0],
      [1493586000000, 0],
      [1496264400000, 7],
      [1498856400000, 806],
      [1501534800000, 1108],
      [1504213200000, 978],
      [1506805200000, 428],
      [1509483600000, 125],
      [1512075600000, 11],
    ],
  },
  {
    id: 'second',
    description: 'Second Interval',
    points: [
      [1451595600000, 0],
      [1454274000000, 0],
      [1456779600000, 0],
      [1459458000000, 0],
      [1462050000000, 0],
      [1464728400000, 0],
      [1467320400000, 0],
      [1469998800000, 0],
      [1472677200000, 0],
      [1475269200000, 0],
      [1477947600000, 0],
      [1480539600000, 0],
    ],
  },
];
const dataForDayChart = [
  {
    id: 'first',
    description: 'First Interval',
    points: [
      [1509483600000, 8],
      [1509570000000, 2],
      [1509656400000, 3],
      [1509742800000, 3],
      [1509829200000, 1],
      [1509915600000, 4],
      [1510002000000, 1],
      [1510088400000, 3],
      [1510174800000, 1],
      [1510261200000, 1],
      [1510347600000, 4],
      [1510434000000, 0],
      [1510520400000, 5],
      [1510606800000, 0],
      [1510693200000, 4],
      [1510779600000, 64],
      [1510866000000, 6],
      [1510952400000, 1],
      [1511038800000, 0],
      [1511125200000, 3],
      [1511211600000, 0],
      [1511298000000, 0],
      [1511384400000, 1],
      [1511470800000, 1],
      [1511557200000, 0],
      [1511643600000, 0],
      [1511730000000, 0],
      [1511816400000, 1],
      [1511902800000, 4],
      [1511989200000, 4],
      [1512075600000, 1],
      [1512162000000, 0],
      [1512248400000, 0],
      [1512334800000, 1],
      [1512421200000, 0],
      [1512507600000, 9],
      [1512594000000, 0],
      [1512680400000, 0],
      [1512766800000, 0],
      [1512853200000, 0],
      [1512939600000, 0],
      [1513026000000, 0],
      [1513112400000, 0],
      [1513198800000, 0],
      [1513285200000, 0],
      [1513371600000, 0],
      [1513458000000, 0],
      [1513544400000, 0],
      [1513630800000, 0],
      [1513717200000, 0],
      [1513803600000, 0],
      [1513890000000, 0],
      [1513976400000, 0],
      [1514062800000, 0],
      [1514149200000, 0],
      [1514235600000, 0],
      [1514322000000, 0],
      [1514408400000, 0],
      [1514494800000, 0],
      [1514581200000, 0],
      [1514667600000, 0],
    ],
  },
  {
    id: 'second',
    description: 'Second Interval',
    points: [
      [1504213200000, 61],
      [1504299600000, 2],
      [1504386000000, 0],
      [1504472400000, 62],
      [1504558800000, 40],
      [1504645200000, 53],
      [1504731600000, 21],
      [1504818000000, 62],
      [1504904400000, 41],
      [1504990800000, 42],
      [1505077200000, 83],
      [1505163600000, 61],
      [1505250000000, 40],
      [1505336400000, 29],
      [1505422800000, 19],
      [1505509200000, 6],
      [1505595600000, 0],
      [1505682000000, 28],
      [1505768400000, 40],
      [1505854800000, 10],
      [1505941200000, 33],
      [1506027600000, 28],
      [1506114000000, 11],
      [1506200400000, 2],
      [1506286800000, 37],
      [1506373200000, 59],
      [1506459600000, 41],
      [1506546000000, 34],
      [1506632400000, 26],
      [1506718800000, 7],
      [1506805200000, 7],
      [1506891600000, 23],
      [1506978000000, 16],
      [1507064400000, 17],
      [1507150800000, 15],
      [1507237200000, 30],
      [1507323600000, 13],
      [1507410000000, 8],
      [1507496400000, 54],
      [1507582800000, 14],
      [1507669200000, 26],
      [1507755600000, 21],
      [1507842000000, 25],
      [1507928400000, 23],
      [1508014800000, 4],
      [1508101200000, 25],
      [1508187600000, 8],
      [1508274000000, 18],
      [1508360400000, 7],
      [1508446800000, 10],
      [1508533200000, 9],
      [1508619600000, 2],
      [1508706000000, 20],
      [1508792400000, 4],
      [1508878800000, 11],
      [1508965200000, 2],
      [1509051600000, 3],
      [1509138000000, 5],
      [1509224400000, 0],
      [1509310800000, 5],
      [1509397200000, 0],
    ],
  },
];

export default class Charts extends React.Component {
  render() {
    return (
      <div>
        <Chart
          style={{ width: '100%', height: 400 }}
          margin={{ top: 20, left: 30, bottom: 20, right: 30 }}
          xValue="0"
          yValue="1"
          chartId={2}
          data={dataForYearChart}
          title="Sales by year"
          pointColors={{
            first: '#D4D4D4',
            second: '#785ce0',
          }}
        >
          <YAxis />
          <Legend />
          <XAxis />
          <Line x="0" y="1" dataId="first" fill="#D4D4D4" />
          <Line x="0" y="1" dataId="second" fill="#785ce0" />
          <Tooltip
            xFormat={(value) => moment.utc(value / 1000, 'X').format('MMM YY')}
            yFormat={(value) => `USD ${value}.00`}
          />
        </Chart>
        <Chart
          style={{ width: '100%', height: 400 }}
          margin={{ top: 20, left: 30, bottom: 20, right: 30 }}
          xValue="0"
          yValue="1"
          chartId={1}
          data={dataForDayChart}
          title="Sales by day"
          pointColors={{
            first: '#D4D4D4',
            second: '#785ce0',
          }}
        >
          <YAxis />
          <Legend />
          <XAxis />
          <Line x="0" y="1" dataId="first" fill="#D4D4D4" />
          <Line x="0" y="1" dataId="second" fill="#785ce0" />
          <Tooltip
            xFormat={(value) => moment.utc(value / 1000, 'X').format('DD MMM')}
            yFormat={(value) => `USD ${value}.00`}
          />
        </Chart>
        <Chart
          style={{ width: '100%', height: 400 }}
          margin={{ top: 20, left: 30, bottom: 20, right: 30 }}
          xValue="0"
          yValue="1"
          data={dataForYearChart}
          title="Bar chart example"
          ordinal
        >
          <YAxis />
          <XAxis />
          <Bars dataId="first" fill="#b692e7" x="0" y="1" />
          <Tooltip
            xFormat={(value) => moment.utc(value / 1000, 'X').format('DD MMM')}
            yFormat={(value) => `USD ${value}.00`}
            dataId="first"
          />
        </Chart>
      </div>
    );
  }
}
