import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { find } from 'lodash';

import Link from 'components/link';
import { Form, Field } from 'components/form';
import ButtonLink from 'components/button/link';
import View from 'components/view';
import Modal from 'components/modal';
import Help from 'components/tooltip/help';
import DateTime from 'components/date-time';
import AccountPlans from './account-plans';
import AccountBilling from './account-billing';
import AccountUsage from './account-usage';
import { FadeIn } from 'components/transitions';
import UserExitNote from 'components/user/exit-note';
import { formatDate, formatCurrency, formatNumber } from 'utils';
import { countryOptions, stateOptions, countryStateLabel } from 'utils/geo';
import './account.scss';

const { CLIENT_URL } = process.env;

export default class AccountSettings extends React.PureComponent {
  static contextTypes = {
    isOwner: PropTypes.bool,
    onClickSwitchAccount: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const queryEditCard =
      props.location.query['edit-card'] === undefined ? false : true;
    this.state = {
      queryEditCard,
      showEditPayment: queryEditCard,
      paymentValues: {},
    };
  }

  unsetLocationEditCard() {
    const { location, router } = this.props;
    router.replace(location.pathname);
  }

  onClickEditPayment = (event) => {
    event.preventDefault();
    this.setState({ showEditPayment: true });
  };

  onCloseEditPayment = () => {
    this.setState({ showEditPayment: false });
    this.unsetLocationEditCard();
  };

  onSubmitPaymentEdit = (values) => {
    this.props.onUpdateBilling(values).then((success) => {
      if (success) {
        this.setState({ showEditPayment: false });
        this.unsetLocationEditCard();
      }
    });
  };

  onChangePaymentEdit = (values) => {
    this.setState({ paymentValues: { ...values } });
  };

  renderEditPaymentModal() {
    const { loading, account } = this.props;
    const { paymentValues, queryEditCard } = this.state;
    const {
      record: { name, user_email, billing: accountBilling },
      isEnterprise,
    } = account;

    const billing = accountBilling || {};
    const billingValues = paymentValues.billing || {};
    const country = billingValues.country || billing.country;
    const isAccountBilling = billing.method === 'account' || isEnterprise;
    const showCard =
      !!paymentValues.updateCard || (!isAccountBilling && !billing.card);

    return (
      <Form
        onSubmit={this.onSubmitPaymentEdit}
        onChange={this.onChangePaymentEdit}
      >
        <Modal
          title="Edit billing information"
          width={570}
          actions={[{ label: 'Save', type: 'submit' }]}
          onClose={this.onCloseEditPayment}
          loading={loading}
        >
          <fieldset>
            <div className="row">
              {!isAccountBilling && billing.card && (
                <Field
                  type="toggle"
                  name="updateCard"
                  label="Update payment card"
                  readonly={true}
                  defaultChecked={queryEditCard}
                />
              )}
            </div>
            <FadeIn active={showCard} transitionAppear={false}>
              {showCard && (
                <div className="row">
                  <Field
                    type="card-number"
                    label="Credit card number"
                    name="billing.card.number"
                    rules={'required, credit_card_number'}
                    className="span2"
                  />
                  <Field
                    type="card-exp"
                    label="Expiration"
                    name="billing.card.exp"
                    rules={'required, credit_card_exp'}
                    className="span1"
                  />
                  <Field
                    type="text"
                    label="CVC"
                    name="billing.card.cvc"
                    placeholder="***"
                    rules={'required, credit_card_cvc'}
                    className="span1"
                  />
                </div>
              )}
            </FadeIn>
            {isAccountBilling && (
              <Field
                type="text"
                name="billing.company"
                label="Company name"
                defaultValue={billing.company}
                placeholder={name}
              />
            )}
            <div className="row">
              <Field
                type="text"
                name="billing.name"
                label={
                  isAccountBilling ? 'Billing contact name' : 'Billing name'
                }
                defaultValue={billing.name}
                required={true}
                className={isAccountBilling ? 'span2' : 'span4'}
              />
              {isAccountBilling && (
                <Field
                  type="text"
                  name="billing.email"
                  label="Billing contact email"
                  defaultValue={billing.email}
                  required={false}
                  rules="email"
                  placeholder={user_email}
                  className="span2"
                />
              )}
            </div>
            <Field
              type="text"
              name="billing.company"
              label="Company"
              defaultValue={billing.company}
            />
            <Field
              type="text"
              name="billing.address1"
              label="Street address"
              defaultValue={billing.address1}
            />
            <Field
              type="text"
              name="billing.address2"
              label="Apt, suite, etc"
              defaultValue={billing.address2}
            />
            <div className="row">
              <Field
                type="text"
                name="billing.city"
                label="City"
                defaultValue={billing.city}
                className="span3"
              />
              <Field
                type="text"
                name="billing.zip"
                label="Zip/postal code"
                defaultValue={billing.zip}
                className="span1"
              />
            </div>
            <div className="row">
              <Field
                type="select"
                name="billing.country"
                label="Country"
                defaultValue={billing.country}
                options={countryOptions}
                className="span2"
              />
              <Field
                type={stateOptions[country] ? 'select' : 'text'}
                name="billing.state"
                label={countryStateLabel(country)}
                defaultValue={
                  country === billing.country ? billing.state : undefined
                }
                options={stateOptions[country]}
                className="span2"
              />
            </div>
            <div className="row">
              <Field
                type="text"
                name="billing.phone"
                label="Phone number"
                defaultValue={billing.phone}
                className="span4"
              />
            </div>
            {((country && country !== 'US') || billing.vat_number) && (
              <div className="row">
                <Field
                  type="text"
                  name="billing.vat_number"
                  label="VAT number"
                  defaultValue={billing.vat_number}
                  placeholder="Optional"
                  className="span2"
                />
              </div>
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }

  renderPlanDescription() {
    const {
      account: { plan },
    } = this.props;

    return plan.name;
  }

  render() {
    const {
      user,
      client,
      userCount,
      account: {
        plan,
        planSelected,
        planCanceled,
        features,
        isEnterprise,
        isAppSumo,
        promo,
        record: { date_created: accountDateCreated },
      },
    } = this.props;

    const { isOwner } = this.context;
    const { parent_id, usage } = client;

    const parentAuth =
      parent_id && find(user.authorizations, { client_id: parent_id });

    const dateActivated = moment(
      (plan && plan.date_created) || accountDateCreated,
    ).toDate();
    const dateSalesAccurate = moment('2021-09-12').toDate();
    const date12MonthsAgo = moment().subtract(12, 'months').toDate();

    return (
      <div className="account-settings">
        {!planSelected ? (
          <AccountPlans {...this.props} />
        ) : (
          <View
            detail={true}
            uri="/settings"
            sectionTitle={planCanceled ? 'Back' : 'Settings'}
            headerTitle="Account"
            headerSubtitle="Manage your account plan and billing information"
          >
            <fieldset className="full last-child">
              {plan.canceled && (
                <div className="box warning">
                  <h4 className="box-title">
                    Your plan was canceled{' '}
                    {!!plan.date_canceled && (
                      <span>on {formatDate(plan.date_canceled, 'long')}</span>
                    )}
                  </h4>
                  <div className="box-subtitle">
                    Account access {planCanceled ? 'was' : 'will be'} revoked on{' '}
                    {formatDate(plan.date_period_end, 'longExact')}
                  </div>
                  <div className="box-action">
                    {isAppSumo ? (
                      <ButtonLink href="https://appsumo.com/products/swell/#appsumo-plans">
                        Reactivate
                      </ButtonLink>
                    ) : (
                      <ButtonLink to="/settings/account/plans">
                        Reactivate
                      </ButtonLink>
                    )}
                  </div>
                </div>
              )}
              <div className="box">
                <div className="box-section">
                  <div className="box-grid">
                    <div className="box-column">
                      <h4 className="box-title">
                        {this.renderPlanDescription()}
                        {false && promo && (
                          <span className="badge">
                            {promo.name}{' '}
                            {promo.discount ? `(${promo.discount}% off)` : null}
                          </span>
                        )}
                      </h4>
                      <div className="box-subtitle">
                        <span className="note">
                          {planCanceled ? 'Activated on' : 'Active since'}{' '}
                          {formatDate(dateActivated, 'long')}&ensp;
                        </span>
                      </div>
                    </div>
                    <div className="box-column">
                      {parent_id ? (
                        parentAuth ? (
                          <a
                            href=""
                            onClick={this.context.onClickSwitchAccount}
                            data-id={parent_id}
                            data-uri="/settings/account"
                          >
                            Switch to master account ({parent_id})
                          </a>
                        ) : (
                          <span className="muted">Managed by {parent_id}</span>
                        )
                      ) : (
                        isOwner &&
                        (isAppSumo ? (
                          <a
                            href={`https://appsumo.com/account/redemption/${plan.appsumo.invoice_item_uuid}#change-plan`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Change plan
                          </a>
                        ) : (
                          !isEnterprise &&
                          plan.interval && (
                            <ButtonLink to="/settings/account/plans">
                              Change plan
                            </ButtonLink>
                          )
                        ))
                      )}
                    </div>
                  </div>
                </div>
                {usage && (
                  <>
                    {plan.fee_percent > 0 && (
                      <div className="box-section tight">
                        <div className="box-title">
                          Sales this month:{' '}
                          <b>{formatCurrency(usage.current_month_sales)}</b>
                        </div>
                        <div className="box-subtitle">
                          The sum of paid minus canceled orders during the
                          current billing period. Your monthly invoice is based
                          on this amount.
                        </div>
                      </div>
                    )}
                    {plan.fee_percent > 0 && usage.last_month_sales > 0 && (
                      <div className="box-section tight">
                        <div className="box-title">
                          Previous month:{' '}
                          <b>{formatCurrency(usage.last_month_sales)}</b>
                        </div>
                        <div className="box-subtitle">
                          The sum of paid orders during the last billing period.
                          Your previous monthly invoice was based on this
                          amount.
                        </div>
                      </div>
                    )}
                    <div className="box-section tight">
                      <div className="box-title">
                        Last 12 months:{' '}
                        <b>{formatCurrency(usage.year_sales)}</b>
                      </div>
                      <div className="box-subtitle">
                        The sum of paid orders over the last 12 months.{' '}
                        {plan.revenue_max > 0 && (
                          <>
                            Plan limit:{' '}
                            <u>{formatCurrency(plan.revenue_max)}</u>.
                          </>
                        )}
                        {dateActivated < dateSalesAccurate &&
                          date12MonthsAgo < dateSalesAccurate && (
                            <>
                              <br />
                              <em>
                                Note: sales data may not be accurately displayed
                                for accounts created before September, 2021.
                              </em>
                            </>
                          )}
                      </div>
                    </div>
                    {userCount && (
                      <div className="box-section tight">
                        <div className="box-title">
                          Admin users: <b>{userCount}</b>
                        </div>
                        <div className="box-subtitle">
                          Number of admin users with access to your account.{' '}
                          {features.users > 0 && (
                            <>
                              Plan limit: <u>{features.users}</u>.
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {plan && plan.clients && plan.clients.results.length > 0 && (
                <table className="collection-table outer">
                  <thead>
                    <tr>
                      <th>Connected stores</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <table className="collection-table inner">
                          <thead>
                            <tr>
                              <th>Store</th>
                              <th>ID</th>
                              <th>Users</th>
                              <th>Created</th>
                            </tr>
                          </thead>
                          <tbody>
                            {plan.clients.results.map((client) => (
                              <tr key={client.id}>
                                <td>
                                  <Link
                                    to={`${CLIENT_URL.replace(
                                      'CLIENT_ID',
                                      client.id,
                                    )}/admin`}
                                    target="blank"
                                  >
                                    {client.name}
                                  </Link>
                                </td>
                                <td>{client.id}</td>
                                <td>
                                  {client.user_main_count +
                                    client.user_auth_count}
                                </td>
                                <td>
                                  <DateTime
                                    value={client.date_created}
                                    format="age"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </fieldset>
            {usage && <AccountUsage {...this.props} />}
            <AccountBilling
              {...this.props}
              onClickEditPayment={this.onClickEditPayment}
            />
            {this.state.showEditPayment && this.renderEditPaymentModal()}
            {planCanceled && <UserExitNote />}
          </View>
        )}
      </div>
    );
  }
}
