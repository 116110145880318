import React from 'react';
import OrderView from 'components/pages/order/view';

export default class CartView extends React.PureComponent {
  render() {
    const { record } = this.props;

    const isAbandoned = record.active === false && !record.order_id;

    return (
      <OrderView
        {...this.props}
        uri="/carts"
        title="Cart"
        sectionTitle={isAbandoned ? 'Abandoned carts' : 'Carts'}
      />
    );
  }
}
