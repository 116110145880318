import moment from 'moment-timezone';

const FORMATS = {
  year: 'YYYY',
  month: 'MMM YYYY',
  dayOfMonth: 'MMM DD, YYYY',
  hour: 'MMM DD, YYYY h:00a',
};

export function getChartPeriod(startDate, endDate) {
  if (moment(endDate).diff(startDate, 'day') <= 2) {
    return 'hour';
  } else if (moment(endDate).diff(startDate, 'month') <= 2) {
    return 'dayOfMonth';
  } else {
    return 'month';
  }
}

export function formatReportDate(period, timezone) {
  return (value) => {
    return moment
      .utc((value - 0) / 1000, 'X')
      .tz(timezone)
      .format(FORMATS[period]);
  };
}
