import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Field, FieldLocalized, Fieldlist } from 'components/form';
import { formatCurrency } from 'utils';

const DEFAULT_VALUES = [10, 25, 50, 100];

export default class GiftcardOptions extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      optionValues: this.getDefaultOptionValues(props, context),
    };
  }

  getDefaultOptionValues(props, context) {
    const { record = {} } = props;
    const { client } = context;

    if (record.options && record.options[0] && record.options[0].values) {
      return record.options[0].values;
    }

    const currency = record.currency || client.currency;
    return DEFAULT_VALUES.map((value) => ({
      name: formatCurrency(value, currency),
      price: value,
    }));
  }

  onChangeField = (event, value) => {
    const { record = {} } = this.props;
    const { client } = this.context;
    const currency = record.currency || client.currency;
    const optionValues = map(value, (val) => ({
      ...val,
      name: val.name || formatCurrency(val.price, currency),
      price: val.price,
    }));
    this.setState({ optionValues });
  };

  renderDenomination = ({ defaultValue, index, disabled, onChange }) => {
    const { client } = this.context;
    const { record = {} } = this.props;
    const currency = record.currency || client.currency;
    const defaultName = defaultValue.name || formatCurrency(defaultValue.price, currency);

    return [
      <span className="col" key="price" style={{ width: '50%' }}>
        <FieldLocalized
          label="Name"
          type="text"
          name="name"
          defaultValue={defaultName}
          localeValue={defaultValue.$locale}
          disabled={disabled}
          onChange={onChange}
          data-index={index}
          autoFocus={true}
        />
        <FieldLocalized
          label="Value"
          type="currency"
          name="price"
          defaultValue={defaultValue.price}
          currency={currency}
          localeValue={defaultValue.$currency}
          required={true}
          disabled={disabled}
          onChange={onChange}
          data-index={index}
        />
      </span>,
      <span className="col" key="description" style={{ width: '50%' }}>
        <FieldLocalized
          label="Description"
          type="textarea"
          name="description"
          defaultValue={defaultValue.description}
          localeValue={defaultValue.$locale}
          placeholder="Optional"
          autoSize={true}
          rows={1}
          maxRows={5}
          disabled={disabled}
          onChange={onChange}
          data-index={index}
        />
      </span>,
    ];
  };

  render() {
    const { disabled, name, record = {} } = this.props;
    const { optionValues } = this.state;
    const option = (record.options && record.options[0]) || {
      name: 'Value',
      required: true,
      variant: true,
    };

    return (
      <div className="product-giftcards-options">
        <Field type="hidden" name={`${name}[0].name`} value={option.name} />
        <Field
          type="hidden"
          name={`${name}[0].required`}
          value={option.required}
        />
        <Field
          type="hidden"
          name={`${name}[0].variant`}
          value={option.variant}
        />
        <Fieldlist
          label="denomination"
          name={`${name}[0].values`}
          defaultValue={optionValues}
          disabled={disabled}
          render={this.renderDenomination}
          onChange={this.onChangeField}
          localized={true}
        />
      </div>
    );
  }
}
