import React from 'react';
import Label from './label';
import JsonEditor from '../json-editor';

export default class InputEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.getInitialValue(props),
      valid: true,
      error: null,
    };
    this.changeTimeout = null;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: this.getInitialValue(nextProps) });
    }
    if (this.props.defaultValue !== nextProps.defaultValue) {
      this.setState({ value: this.getInitialValue(nextProps) });
    }
  }

  getInitialValue(props) {
    if (props.value) {
      return this.getJSON(props.value);
    }
    if (props.defaultValue) {
      return this.getJSON(props.defaultValue);
    }
    return {};
  }

  getJSON(value) {
    try {
      if (typeof value === 'string') {
        return JSON.parse(value);
      } else if (value && typeof value === 'object') {
        return value;
      } else {
        return {};
      }
    } catch (err) {}
  }

  onChange = (value) => {
    this.setState({ value });
    const event = new Event('change', { bubbles: true });
    this.props.onChange(event, value);
  };

  onValidate = (valid, error) => {
    this.setState({ valid, error });
  };

  value() {
    return this.state.value;
  }

  validate() {
    return this.state.valid;
  }

  render() {
    let { id, label, help, onKeyDown } = this.props;

    return (
      <span className="form-json-editor">
        <Label label={label} help={help} htmlFor={id} />
        <input
          type="text"
          ref="input"
          style={{ visibility: 'hidden', position: 'absolute' }}
        />
        <span className="form-field-input" onKeyDown={onKeyDown}>
          <JsonEditor
            id={id}
            onChange={this.onChange}
            onValidate={this.onValidate}
            value={this.state.value}
            autoFocus={true}
          />
        </span>
      </span>
    );
  }
}
