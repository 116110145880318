import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Collection from './Collection';
import BulkExport from 'components/bulk/export';
import GiftcardStatus from 'components/giftcard/status';
import GiftcardValue from 'components/giftcard/value';
import GiftcardDescription from 'components/giftcard/description';
import actions from 'actions';

export const query = {
  include: {
    product: {
      url: '/products/{product_id}',
      fields: 'name',
    },
  },
};

export const tabs = {
  default: {
    label: 'All gift cards',
  },
  new: {
    label: 'New',
    query: {
      $and: [{ redeemed: { $ne: true } }, { balance: { $gt: 0 } }],
    },
  },
  spent: {
    label: 'Spent',
    query: {
      amount_spent: { $gt: 0 },
    },
  },
  redeemed: {
    label: 'Redeemed',
    query: {
      redeemed: true,
    },
  },
  disabled: {
    label: 'Disabled',
    query: {
      $or: [
        { disabled: true },
        { date_expired: { $exists: true } },
        { date_expired: { $lte: Date.now() } },
      ],
    },
  },
};

export const filters = {
  date_created: {
    label: 'Created',
    operators: ['after', 'before'],
    type: 'date',
  },
  amount: {
    label: 'Value',
    operators: ['gt', 'lt', 'eq'],
    type: 'currency',
  },
  status: {
    label: 'Status',
    options: [
      { value: 'new', label: 'New' },
      { value: 'redeemed', label: 'Redeemed' },
      { value: 'disabled', label: 'Disabled' },
      { value: 'expired', label: 'Expired' },
    ],
    func: (query) => {
      if (query === 'new') {
        return {
          redeemed: { $ne: true },
        };
      }
      if (query === 'redeemed') {
        return {
          redeemed: true,
        };
      }
      if (query === 'disabled') {
        return {
          disabled: true,
        };
      }
      if (query === 'expired') {
        return {
          active: true,
          $and: [
            { date_expired: { $exists: true } },
            { date_expired: { $lte: Date.now() } },
          ],
        };
      }
    },
  },
  product: {
    label: 'Gift card product',
    type: 'LookupProduct',
    func: (product_id) => ({ product_id }),
    query: {
      type: 'giftcard',
    },
  },
};

export const fields = {
  code: {
    label: 'Code',
    type: 'link',
    url: '/giftcards/{id}',
    sort: false,
    default: true,
    func: (giftcard) => {
      return (
        <span className="monospaced">
          •••• •••• •••• {String(giftcard.code_formatted).slice(-4)}
        </span>
      );
    },
  },
  description: {
    label: 'Description',
    sort: false,
    func: (giftcard) => (
      <GiftcardDescription giftcard={giftcard} link={false} />
    ),
  },
  status: {
    label: 'Status',
    sort: false,
    func: (giftcard) => <GiftcardStatus giftcard={giftcard} />,
  },
  date_expired: {
    label: 'Expires',
    type: 'date',
  },
  amount: {
    label: 'Value',
    type: 'currency',
    func: (giftcard) => <GiftcardValue giftcard={giftcard} />,
  },
};

export const bulkActions = [
  {
    modal: 'BulkDelete',
    label: 'Delete',
    type: 'danger inverse',
    params: {
      query: {
        redeemed: { $ne: true },
      },
      modelName: 'giftcards',
    },
  },
];

const exportCSVFields = [
  { key: 'code', label: 'Code' },
  { key: 'code_formatted', label: 'Code formatted' },
  { key: 'amount', label: 'Value' },
  {
    key: 'redeemed',
    label: 'Redeemed',
    export: (giftcard) => (giftcard.redeemed ? 'true' : 'false'),
  },
  {
    key: 'disabled',
    label: 'Disabled',
    export: (giftcard) => (giftcard.disabled ? 'true' : 'false'),
  },
  { key: 'date_expired', label: 'Expires' },
  {
    key: 'account_name',
    label: 'Customer name',
    export: (giftcard) => (giftcard.account ? giftcard.account.name : ''),
  },
  {
    key: 'account_email',
    label: 'Customer email',
    export: (giftcard) =>
      giftcard.account ? giftcard.account.email : giftcard.send_email,
  },
  {
    key: 'account_id',
    label: 'Customer ID',
    export: (giftcard) => (giftcard.account ? giftcard.account.id : ''),
  },
  {
    key: 'order_number',
    label: 'Order number',
    export: (giftcard) => (giftcard.order ? giftcard.order.number : ''),
  },
  {
    key: 'order_id',
    label: 'Order ID',
    export: (giftcard) => (giftcard.order ? giftcard.order.id : ''),
  },
  {
    key: 'date_bulk_generated',
    label: 'Date generated',
  },
  {
    key: 'bulk_description',
    label: 'Bulk description',
  },
  { key: 'date_created', label: 'Date created' },
  { key: 'id', label: 'ID' },
];

const mapStateToProps = (state) => ({
  data: state.data,
  bulk: state.data.bulk,
});

const mapDispatchToProps = (dispatch) => ({
  bulkExport: (model: string, params: Object) => {
    return dispatch(actions.data.bulkExport(model, params));
  },

  bulkCancel: () => {
    return dispatch(actions.data.bulkCancel());
  },
});

export class Giftcards extends React.Component {
  static contextTypes = {
    account: PropTypes.object.isRequired,
  };

  constructor(props: Object) {
    super(props);
    this.state = {
      showingExport: false,
      onClickExport: this.onClickExport.bind(this),
      onClickExportCancel: this.onClickExportCancel.bind(this),
      onClickExportReset: this.onClickExportReset.bind(this),
      onSubmitExport: this.onSubmitExport.bind(this),
    };
  }

  onClickExport(event) {
    event.preventDefault();
    this.setState({ showingExport: !this.state.showingExport });
  }

  onClickExportCancel(event) {
    event.preventDefault();
    if (this.props.bulk.running) {
      this.props.bulkCancel();
    }
    this.setState({ showingExport: false });
  }

  onClickExportReset(event) {
    event.preventDefault();
    this.props.bulkCancel();
  }

  onSubmitExport(values) {
    this.props.bulkExport(`giftcards`, {
      ...values,
      filename: `giftcards`,
      csvFields: exportCSVFields,
      query: {
        expand: ['account', 'order'],
        ...(this.props.query || {}),
      },
    });
  }

  render() {
    return (
      <Fragment>
        <Collection
          title="Gift cards"
          uri="/giftcards"
          model="giftcards"
          tabs={tabs}
          filters={filters}
          fields={fields}
          bulkActions={bulkActions}
          emptyDescription="Offer physical and digital gift cards to increase sales and customer loyalty, or generate one-off gift codes for marketing campaigns."
          headerActions={[
            !this.props.nested && {
              label: 'Manage products',
              fa: 'gift-card',
              type: 'sub',
              link: '/giftcards/products',
              showAlways: true,
            },
            {
              label: 'Export',
              fa: 'arrow-to-bottom',
              type: 'sub',
              onClick: this.onClickExport.bind(this),
            },
            !this.props.nested && {
              label: 'New gift card',
              link: '/giftcards/new',
            },
          ]}
          {...this.props}
          query={{
            ...query,
            ...(this.props.query || {}),
          }}
          queryCurrency={true}
        />
        {this.state.showingExport && (
          <BulkExport
            label="Gift cards"
            {...this.props}
            {...this.state}
            selectable={true}
          />
        )}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Giftcards);
