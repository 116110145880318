import React from 'react';
import PropTypes from 'prop-types';
import { map, reduce, upperFirst, toLower, isEmpty, set } from 'lodash';
import ObjectID from 'bson-objectid';

import Icon from 'components/icon';
import Button from 'components/button';
import { Field, Fieldgroup } from 'components/form';
import { FadeIn } from 'components/transitions';

const MIN_DAY = 1;
const MAX_DAY = 999;

const RETRY_RESOLVE = {
  unpaid: 'Mark as unpaid',
  canceled: 'Cancel subscription',
  nothing: 'Do nothing',
};

export default class SubscriptionRetryRules extends React.PureComponent {
  static propTypes = {
    rules: PropTypes.array.isRequired,

    onChange: PropTypes.func.isRequired,
    deleteNotification: PropTypes.func.isRequired,
    onClickEditNotification: PropTypes.func.isRequired,
  };

  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    registerField: PropTypes.func,
    unregisterField: PropTypes.func,
  };

  static childContextTypes = {
    registerField: PropTypes.func,
    unregisterField: PropTypes.func,
  };

  getChildContext() {
    return {
      registerField: this.registerField,
      unregisterField: this.unregisterField,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      rules: props.rules,
    };

    this.newRules = new Set();
  }

  componentDidMount() {
    if (this.context.registerField) {
      this.context.registerField(this);
    }
  }

  componentWillUnmount() {
    if (this.context.unregisterField) {
      this.context.unregisterField(this);
    }
  }

  onClickAddRule = (event) => {
    event.preventDefault();

    const { onChange } = this.props;
    const rules = [...this.state.rules];
    const id = ObjectID().toHexString();

    const rule = {
      id,
      retry_payment: true,
      send_notification: false,
      notification_id: `subscriptions.payment-failed-${id}.v2`,
    };

    this.newRules.add(id);

    if (isEmpty(rules)) {
      rule.day = 3;
      rules.push(rule);
    } else if (rules.length === 1) {
      rule.day = rules[0].day + 1;
      rule.retry_resolve = 'nothing';
      rules.push(rule);
    } else {
      const index = rules.length - 2;
      rule.day = rules[index].day + 1;
      rules.splice(index + 1, 0, rule);
    }

    this.setState({ rules }, () => onChange({ retry_rules: rules }, true));
  };

  onClickDeleteRule = (event) => {
    event.preventDefault();
    const { deleteNotification, onChange } = this.props;
    const { index, day, noteid } = event.target.dataset;

    this.context.openModal('ConfirmDelete', {
      title: 'step',
      message: `Are you sure you want to delete the rule for Day ${day}? This action cannot be undone, and you will\nlose any changes you have made to the email template.`,
      onConfirm: () => {
        const rules = [...this.state.rules];
        rules.splice(index, 1);
        this.setState({ rules }, () => onChange({ retry_rules: rules }, true));
        deleteNotification(noteid);
      },
    });
  };

  onChangeField = (event, value) => {
    event.preventDefault();
    if (!event.target?.name) {
      return;
    }

    const fieldName = event.target.name.split("retry_rules.")[1];
    const name = fieldName.split('.')[1];
    switch (name) {
      case 'day':
        value = Number(value);
        break;
      case 'retry_payment':
      case 'send_notification':
        value = !!value;
        break;
      default:
        break;
    }

    const rules = [...this.state.rules];
    set(rules, fieldName, value);
    this.setState({ rules });
  };

  minDayValue(index, rules) {
    const prevRuleIndex = index - 1;
    const isFirstRule = index === 0;
    const prevRule = rules[prevRuleIndex];

    if (isFirstRule) {
      return MIN_DAY;
    } else {
      return prevRule && prevRule.day ? prevRule.day + 1 : MIN_DAY;
    }
  }

  maxDayValue(index, rules) {
    const rulesLength = rules.length;
    const lastRuleIndex = rulesLength - 1;
    const nextRuleIndex = index + 1;
    const isLastRule = index === lastRuleIndex;
    const nextRule = rules[nextRuleIndex];

    if (isLastRule) {
      return MAX_DAY;
    } else {
      return nextRule && nextRule.day ? nextRule.day - 1 : MAX_DAY;
    }
  }

  ruleDescription(rule) {
    const actions = [];
    if (rule.retry_payment) {
      actions.push('retry payment method');
    }
    if (rule.send_notification) {
      actions.push('send email notification');
    }
    if (rule.retry_resolve && rule.retry_resolve !== 'nothing') {
      const retryResolve = toLower(RETRY_RESOLVE[rule.retry_resolve]);
      actions.push(retryResolve);
    }

    const actionsLength = actions.length;

    return !actionsLength
      ? 'Do nothing'
      : reduce(
          actions,
          (acc, action, index) =>
            !index
              ? upperFirst(action)
              : `${acc}${
                  index + 1 === actionsLength ? ' and ' : ', '
                }${action}`,
          '',
        );
  }

  value() {
    return this.state.rules;
  }

  rule = (rule, index, rules) => {
    const { onClickEditNotification } = this.props;
    const rulesLength = rules.length;
    const lastRuleIndex = rulesLength - 1;
    const penultimateRuleIndex = rulesLength - 2;
    const lastRule = rules[lastRuleIndex];
    const penultimateRule = rules[penultimateRuleIndex];
    const shouldDisplayActions =
      rulesLength === 1 || index === penultimateRuleIndex;
    const ruleAddingAllowed =
      rulesLength === 1 || lastRule.day - penultimateRule.day > 1;
    const shouldDisplayDelete = index !== 0 && index !== lastRuleIndex;
    const shouldDisplayResolve = index === lastRuleIndex;
    const minDay = this.minDayValue(index, rules);
    const maxDay = this.maxDayValue(index, rules);

    return (
      <div
        key={rule.id}
        className={`retry-rule ${shouldDisplayActions ? 'with-actions' : ''}`}
      >
        <div className="retry-rule-title">
          <span className="bold">Day {rule.day || minDay}</span>

          <span>
            {index === 0
              ? ' (First payment failure)'
              : index === lastRuleIndex && ' (Final step)'}
          </span>

          <div className="muted">{this.ruleDescription(rule)}</div>
        </div>

        <Fieldgroup
          label="Edit"
          defaultActive={this.newRules.has(rule.id)}
          className="normal"
        >
          <div>
            <Field
              type="number"
              name={`retry_rules.${index}.day`}
              label="Days after first payment failure"
              minValue={minDay}
              maxValue={maxDay}
              defaultValue={rule.day}
              onChange={this.onChangeField}
            />

            <Field
              type="toggle"
              name={`retry_rules.${index}.retry_payment`}
              label="Retry payment method"
              defaultChecked={rule.retry_payment}
              onChange={this.onChangeField}
            />

            <Field
              type="toggle"
              name={`retry_rules.${index}.send_notification`}
              label="Send email notification"
              defaultChecked={rule.send_notification}
              onChange={this.onChangeField}
            />

            <FadeIn active={rule.send_notification}>
              <div className="retry-rule-notification-container">
                <Icon type="notification" width="20px" height="20px" />

                <div className="retry-rule-notification">
                  <div className="retry-rule-notification-title">
                    Your subscription payment failed
                  </div>

                  <Button
                    data-id={rule.notification_id}
                    data-default="subscriptions.payment-failed.v2"
                    onClick={onClickEditNotification}
                  >
                    Edit email
                  </Button>
                </div>
              </div>
            </FadeIn>

            {shouldDisplayResolve && (
              <Field
                type="radio"
                label="Action"
                name={`retry_rules.${index}.retry_resolve`}
                buttons={true}
                options={map(RETRY_RESOLVE, (label, value) => ({
                  label,
                  value,
                }))}
                defaultValue={rule.retry_resolve || 'nothing'}
                onChange={this.onChangeField}
              />
            )}

            {shouldDisplayDelete && (
              <Button
                data-index={index}
                data-day={rule.day}
                data-noteid={rule.notification_id}
                size="sm"
                styleType="danger"
                className="retry-rule-notification-delete"
                onClick={this.onClickDeleteRule}
              >
                Delete step
              </Button>
            )}
          </div>
        </Fieldgroup>

        {shouldDisplayActions && (
          <div className="retry-rule-actions">
            <Button
              onClick={this.onClickAddRule}
              size="sm"
              type="secondary"
              disabled={!ruleAddingAllowed}
            >
              Add step
            </Button>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className="retry-rules">{map(this.state.rules, this.rule)}</div>
    );
  }
}
