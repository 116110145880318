import PropTypes from 'prop-types';
import React from 'react';
import { get, set, assign, findIndex, uniqBy } from 'lodash';
import Label from './label';
import FileReader from './file-reader';
import FileDropzone from './file-dropzone';
import { CSSTransitionGroup } from 'react-css-transition';
import { Draggable, Droppable } from 'react-drag-and-drop';
import { FadeIn } from 'components/transitions';
import { pluralize } from 'utils';

export default class InputFile extends React.Component {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    uploadFiles: PropTypes.func.isRequired,
  };

  static propTypes = {
    multiple: PropTypes.bool,
    minimal: PropTypes.bool,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    valuePath: PropTypes.string,
    label: PropTypes.string,
    readonlyContent: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      dragSourceId: null,
      removedFileIds: [],
      sortOrder: null,
      uploading: false,
      uploadedFiles: [],
    };
    this.onUploadFiles = this.onUploadFiles.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
  }

  fileId(val) {
    const { valuePath } = this.props;
    return valuePath ? get(val, valuePath, {}).id : val && val.id;
  }

  setValue(uploadedFiles = []) {
    const { multiple, value, defaultValue, valuePath } = this.props;

    const newValue = [
      ...(multiple ? value || defaultValue : []),
      ...uploadedFiles,
    ]
      .filter((x) => !!x)
      .map((fileVal) => {
        const file = (valuePath ? get(fileVal, valuePath) : fileVal) || {};
        const nextVal = { ...fileVal };
        const nextFileVal = {
          id: file.id,
          date_uploaded: file.date_uploaded,
          length: file.length,
          md5: file.md5,
          filename: file.filename,
          content_type: file.content_type,
          metadata: file.metadata,
          url: file.url,
          uploaded_url: file.uploaded_url,
        };
        if (valuePath) {
          set(nextVal, valuePath, nextFileVal);
        } else {
          assign(nextVal, nextFileVal);
        }
        return nextVal;
      })
      .filter(
        (file) => this.state.removedFileIds.indexOf(this.fileId(file)) === -1,
      );

    if (!multiple) {
      return newValue[0];
    }

    const { sortOrder } = this.state;

    if (sortOrder) {
      newValue.sort((a, b) => {
        const indexA = sortOrder.indexOf(this.fileId(a));
        const indexB = sortOrder.indexOf(this.fileId(b));
        if (indexA > indexB) return 1;
        if (indexA === -1) return 1;
        return -1;
      });
      return newValue;
    }

    return uniqBy(newValue, (file) => this.fileId(file));
  }

  triggerChange(uploadedFiles) {
    const event = new Event('change', { bubbles: true });
    this.refs.input.dispatchEvent(event);
    this.props.onChange(event, this.setValue(uploadedFiles));
  }

  onUploadFiles(files) {
    const { valuePath, multiple } = this.props;
    this.setState({ uploading: true });
    this.context.uploadFiles(files).then((results) => {
      if (!results) {
        this.setState({ uploading: false });
        return;
      }
      const uploadedFiles = [
        ...results.reduce((files, result) => {
          if (result && result.id) {
            let file = {};
            const value = {
              id: result.id,
              date_uploaded: result.date_uploaded,
              length: result.length,
              md5: result.md5,
              filename: result.filename,
              content_type: result.content_type,
              metadata: result.metadata,
              url: result.url,
              uploaded_url: result.uploaded_url,
            };
            if (valuePath) {
              set(file, valuePath, value);
            } else {
              assign(file, value);
            }
            files.push(file);
          }
          return files;
        }, []),
      ];

      this.setState(
        {
          uploadedFiles: multiple
            ? [...this.state.uploadedFiles, ...uploadedFiles]
            : [...uploadedFiles],
          uploading: false,
        },
        () => this.triggerChange(uploadedFiles),
      );
    });
  }

  onClickRemove = (event) => {
    event.preventDefault();
    const fileId = event.currentTarget.dataset.fileid;
    this.context.openModal('Confirm', {
      title: `Remove file`,
      message: <p>Are you sure you want to remove this file?</p>,
      action: 'Remove',
      actionType: 'danger',
      onConfirm: () => {
        this.setState(
          {
            removedFileIds: [...this.state.removedFileIds, fileId],
          },
          () => this.triggerChange(),
        );
      },
    });
  };

  onDragStart(event) {
    event.stopPropagation();
    if (!this.props.multiple) {
      event.preventDefault();
      return;
    }
    const dragSourceId = event.currentTarget.dataset.fileid;
    this.setState({ dragSourceId });
    event.dataTransfer.effectAllowed = 'move';
  }

  onDragEnd(event) {
    event.stopPropagation();
    this.setState({ dragSourceId: null });
  }

  onDragEnter(event) {
    event.stopPropagation();
    const dragTargetId = event.currentTarget.dataset.fileid;
    if (this.state.dragSourceId === dragTargetId) return;
    this.setSortOrder(this.state.dragSourceId, dragTargetId);
  }

  onDragLeave(event) {
    event.stopPropagation();
  }

  setSortOrder(sourceId, targetId) {
    const sortOrder = this.props.value.map((file) => this.fileId(file));
    const sourceIndex = findIndex(
      this.props.value,
      (file) => this.fileId(file) === sourceId,
    );
    const targetIndex = findIndex(
      this.props.value,
      (file) => this.fileId(file) === targetId,
    );
    sortOrder.splice(targetIndex, 0, sortOrder.splice(sourceIndex, 1)[0]);
    this.setState({ sortOrder }, () => this.triggerChange());
  }

  render() {
    const {
      value,
      label,
      typeLabel = '',
      valuePath,
      help,
      multiple = false,
      minimal = false,
      wide = false,
      fullSize = true,
      maxWidth,
      maxHeight,
      minWidth,
      minHeight,
      accept,
      readonlyContent,
      ...props
    } = this.props;

    const { uploading } = this.state;

    let values;
    if (!multiple) {
      values = value ? [value] : [];
    } else {
      values = value || [];
    }

    const noSize =
      (maxWidth || maxHeight || minWidth || minHeight) !== undefined;

    const files =
      values instanceof Array
        ? values
            .filter((x) => !!(valuePath ? get(x, valuePath) : x))
            .map((file) => {
              const fileVal = valuePath ? get(file, valuePath) : file;
              return {
                id: fileVal.id,
                url: fileVal.url,
                filename: fileVal.filename,
                file: fileVal,
              };
            })
        : [];

    return (
      <span
        className={`form-file ${!multiple ? 'single' : ''} ${
          minimal ? 'minimal' : ''
        } ${!files.length ? 'empty' : ''}`}
      >
        <Label label={label} help={help} htmlFor={props.id} />
        <span
          className={`
            form-field-input
            ${wide ? 'wide' : ''}
            ${uploading ? 'uploading' : ''}
            ${this.state.dragSourceId ? 'dragging' : ''}
          `}
          style={{
            maxWidth,
            maxHeight,
            minWidth: minWidth || 0,
            minHeight: minHeight || (files.length ? 0 : undefined),
          }}
        >
          <div className="form-file-emptystate">
            <FadeIn active={!files.length} transitionAppear={false}>
              <FileDropzone
                className="form-file-dropzone"
                acceptClassName="form-file-dropzone-accepted"
                rejectClassName="form-file-dropzone-rejected"
                accept={accept || ''}
                onDrop={this.onUploadFiles}
                multiple={multiple}
              >
                <div className="form-file-upload">
                  Add{' '}
                  {(multiple ? pluralize(typeLabel || 'file') : typeLabel) ||
                    'file'}
                  <div className="muted">Drag & drop to upload</div>
                </div>
              </FileDropzone>
            </FadeIn>
          </div>
          {files.length > 0 && (
            <ul className="form-file-list">
              <CSSTransitionGroup>
                {files.map((file, index) => (
                  <FadeIn key={!multiple ? 0 : file.id} active={true}>
                    <li
                      className={`
                      ${this.state.dragSourceId === file.id ? 'dragging' : ''}
                      ${index === 0 ? 'default' : ''}
                      ${noSize ? 'nosize' : ''}
                    `}
                      data-index={index}
                      style={{
                        ...(fullSize
                          ? {
                              maxWidth,
                              maxHeight,
                              minWidth: minWidth
                                ? minWidth - 12
                                : 'calc(100% - 11px)',
                              minHeight: minHeight
                                ? minHeight - 12
                                : fullSize
                                ? 100
                                : undefined,
                            }
                          : {}),
                      }}
                    >
                      <Droppable
                        types={['file']}
                        data-fileid={file.id}
                        onDragEnter={this.onDragEnter}
                        onDragLeave={this.onDragLeave}
                      >
                        <Draggable
                          type="file"
                          data-fileid={file.id}
                          onDragStart={this.onDragStart}
                          onDragEnd={this.onDragEnd}
                          className={multiple ? 'draggable' : ''}
                        >
                          <div className="form-file-image">
                            <span>{file.filename || file.id}</span>
                          </div>
                          <span className="form-file-controls">
                            <button
                              onClick={this.onClickRemove}
                              data-fileid={file.id}
                            >
                              <i className="fa fa-trash" />
                            </button>
                            <a
                              href={file.url}
                              target="_blank"
                              data-id={file.id}
                              rel="noreferrer"
                            >
                              <i className="fa fa-file" />
                            </a>
                          </span>
                        </Draggable>
                      </Droppable>
                      <span className="form-file-mask" />
                    </li>
                  </FadeIn>
                ))}
              </CSSTransitionGroup>
            </ul>
          )}
          <FadeIn active={!!files.length}>
            <FileReader
              accept={accept || ''}
              onFiles={this.onUploadFiles}
              multiple={multiple}
            >
              <button className="button button-secondary button-sm form-file-addlink">
                {!multiple ? 'Replace file' : 'Add files'}
              </button>
            </FileReader>
          </FadeIn>
          <input
            {...props}
            ref="input"
            type="hidden"
            id={props.id}
            name={props.name}
            disabled={props.disabled}
            value=""
            defaultValue={undefined}
          />
        </span>
      </span>
    );
  }
}
