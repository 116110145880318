import React from 'react';
import classNames from 'classnames';
import pt from 'prop-types';

import { productThumbUrl } from 'utils/product';

import ProductPrice from 'components/pages/product/price';

import Field from './field';

function getQuery(query, currency) {
  let fields =
    'name, type, price, sale, sale_price, currency, date_created, images, options, slug, delivery';

  const expand = ['variants:1'];

  if (query) {
    if (typeof query.fields === 'string') {
      fields = `${fields}, ${query.fields}`;
    }

    if (typeof query.expand === 'string') {
      expand.push(query.expand);
    } else if (Array.isArray(query.expand)) {
      expand.push(...query.expand);
    }
  }

  return {
    ...(query || undefined),
    fields,
    expand,
    $currency: currency,
  };
}

export default class LookupProduct extends React.PureComponent {
  static propTypes = {
    query: pt.object,
    lookup: pt.object.isRequired,
    multiple: pt.bool,
    currency: pt.string,
    className: pt.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      query: getQuery(props.query, props.currency),
      prevQuery: props.query,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.query !== state.prevQuery) {
      return {
        query: getQuery(props.query, props.currency),
        prevQuery: props.query,
      };
    }

    return null;
  }

  renderProductValue(record) {
    return (
      <article>
        <div className="image">
          <img alt={record.name} src={productThumbUrl(record)} />
        </div>

        {record.name}
      </article>
    );
  }

  renderProductLookupItems({ lookup, ...props }) {
    return lookup.results.map((record) => (
      <li
        key={record.id}
        role="option"
        data-id={record.id}
        aria-selected={record.id === props.selected?.id}
        className={classNames('item', {
          selected: record.id === props.selected?.id,
        })}
        onMouseOver={props.onMouseOver}
        onClick={props.onClick}
      >
        <div className="col">
          <div className="image">
            <img alt={record.name} src={productThumbUrl(record)} />
          </div>

          {typeof record.price !== 'number' ? (
            <span className="name muted">{record.name}</span>
          ) : (
            <div className="name">{record.name}</div>
          )}
        </div>

        <div className="col muted">
          <ProductPrice product={record} />
        </div>

        <div className="col">{record.sku}</div>
      </li>
    ));
  }

  onValueLink(value) {
    return `/products/${value.id}`;
  }

  render() {
    const { className, ...props } = this.props;

    return (
      <div className={classNames('form-lookup-product', className)}>
        <Field
          ref="field"
          type="lookup"
          name={props.multiple ? 'products' : 'product'}
          placeholder={
            props.multiple ? 'Find products by name' : 'Find product by name'
          }
          model="products"
          renderValue={this.renderProductValue}
          renderLookupItems={this.renderProductLookupItems}
          queryFocus={true}
          {...props}
          query={this.state.query}
          onValueLink={this.onValueLink}
        />
      </div>
    );
  }
}
