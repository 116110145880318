import React from 'react';
import PropTypes from 'prop-types';

import { writeToClipboard } from 'utils/clipboard';
import {
  makePurchaseLinkUrl,
  getDomainFromSettings,
} from 'utils/purchase-link';

import { Field } from 'components/form';
import Icon from 'components/icon';
import Button from 'components/button';
import SectionHeader from 'components/section-header/section-header';

export default class LinkStatus extends React.PureComponent {
  static contextTypes = {
    notifySuccess: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  state = {};

  constructor(props, context) {
    super(props, context);

    this.state = {
      active: props.record.active !== undefined ? props.record.active : true,
    };
  }

  onClick = () => {
    const { record } = this.props;

    writeToClipboard(
      makePurchaseLinkUrl(
        record.id,
        getDomainFromSettings(this.props.settings.purchaselinks),
      ),
    )
      .then(() => {
        this.context.notifySuccess('Purchase link copied to clipboard');
      })
      .catch(() => {
        this.context.notifyError(
          'Error adding link to clipboard. Please select the link text and copy it manually.',
        );
      });
  };

  render() {
    const { record, disabled } = this.props;
    const { active = record?.active } = this.state;

    const isExists = Boolean(record.id);

    const domain = getDomainFromSettings(this.props.settings.purchaselinks);

    return (
      <div className="view-body-section">
        <div className="view-body-subheader">
          <SectionHeader className="view-body-subtitle" title="Purchase link" />
          <Field
            name="active"
            type="toggle"
            label="testing"
            defaultChecked={active}
            onChange={(_, active) => this.setState({ active })}
          ></Field>
        </div>
        <div className="view-section-content">
          <div className="link-status-content">
            <p>
              Use this link to send customers directly to your checkout,
              pre-filled with these items.
            </p>
            <div className={`link-status-input ${active ? 'active' : ''}`}>
              {makePurchaseLinkUrl(record.id || '...', domain)}
              <Button
                styleType="secondary"
                onClick={this.onClick}
                disabled={!isExists || disabled}
                type="button"
                size="xs"
              >
                <Icon fa="copy" />
                &ensp;Copy link
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
