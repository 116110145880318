import React from 'react';
import { find } from 'lodash';
import { normalizeUrl } from 'utils';

const { APP_DEPLOYMENT_PUBLIC_URL, CLIENT_URL } = process.env;

const THEME_LINKS = {
  origin: 'https://github.com/swellstores/origin-theme/',
  horizon: 'https://github.com/swellstores/horizon',
};

export function getStorefrontURL(storefront) {
  if (!storefront) return '';

  if (storefront.external?.custom && storefront.external?.custom_url) {
    return storefront.external.custom_url;
  }

  if (storefront.hosted) {
    if (storefront.domains?.results?.length) {
      const primaryDomain = find(storefront.domains.results, {
        primary: true,
        verified: true,
      });

      if (primaryDomain) {
        return normalizeUrl(primaryDomain.name, {
          defaultProtocol: 'https://',
        });
      }
    }

    return CLIENT_URL.replace('CLIENT_ID', storefront.slug);
  }

  return '';
}

export function storefrontPublicDeploymentURL(storefront) {
  const { preview_deployment_name } = storefront;
  return APP_DEPLOYMENT_PUBLIC_URL.replace(
    'DEPLOYMENT_ID',
    preview_deployment_name,
  );
}

export function storefrontCheckoutURL(clientId, dev = false) {
  return CLIENT_URL.replace('CLIENT_ID', `${clientId}${dev ? '--dev' : ''}`);
}

export function renderStorefrontThemeDesc(storefront) {
  return (
    <>
      <a
        href={THEME_LINKS[storefront.source_id]}
        target="_blank"
        rel="noreferrer"
      >
        {storefront.source?.name || storefront.source_id} theme
      </a>{' '}
      version {storefront.source_version}
    </>
  );
}
