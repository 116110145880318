import React, { Fragment } from 'react';
import { kebabCase } from 'lodash';
import Form from 'components/form';
import View from 'components/view';
import ModelForm from './form';

export default class ContentModelNew extends React.PureComponent {
  render() {
    const { onChangeForm, onSubmitRecord, ...props } = this.props;

    const { values } = props;

    values.namespace_default = kebabCase(values.namespace);
    values.collection_default = kebabCase(values.label);

    const namespace =
      values.namespace ||
      (values.namespace_select === 'content'
        ? 'content'
        : values.namespace_default);
    const collection = values.collection || values.collection_default;
    const endpoint = namespace
      ? `${namespace}${collection ? `/${collection}` : ''}`
      : collection;

    return (
      <Form onSubmit={onSubmitRecord} onChange={onChangeForm} autoFocus={true}>
        <div className="view-detail-full">
          <View
            detail={true}
            uri={`/settings/models`}
            sectionTitle="Models"
            headerTitle={values.label || 'New model'}
            headerActions={[{ label: 'Save model', submit: true }]}
            headerSubtitle={
              <Fragment>
                {endpoint ? (
                  <code>/{endpoint}</code>
                ) : (
                  !values.description && <code>&nbsp;</code>
                )}{' '}
                {values.description ? (
                  <span className="note">
                    {endpoint ? <span>&mdash;</span> : ''} {values.description}
                  </span>
                ) : (
                  ''
                )}
              </Fragment>
            }
            submitting={props.loading}
          >
            <ModelForm
              {...props}
              editable={true}
              tab={props.location.query.tab}
            />
          </View>
        </div>
      </Form>
    );
  }
}
