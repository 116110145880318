import React from 'react';
import { get } from 'lodash';

import Link from 'components/link';
import SectionHeader from 'components/section-header/section-header';
import {
  formatCurrencyRounded,
  formatCurrency,
  formatNumber,
  formatDate,
} from 'utils';

export default class CustomerAbandonedCarts extends React.PureComponent {
  render() {
    const {
      record: { id, abandoned_carts },
    } = this.props;

    const cartLength = get(abandoned_carts, 'results.length', 0);
    const cartCount = get(abandoned_carts, 'count', 0);

    return (
      <div className="customer-orders">
        <div className="view-body-section">
          <div className="view-body-subheader">
            <SectionHeader
              className="view-body-subtitle"
              title="Abandoned carts"
              count={formatNumber(cartCount)}
            />
          </div>
          <div className="view-section-content collection-full">
            {abandoned_carts && abandoned_carts.results.length > 0 ? (
              <div className="collection-table-container">
                <table className="collection-table">
                  <thead>
                    <tr>
                      <th>Number</th>
                      <th>Last active</th>
                      <th>Status</th>
                      <th className="total">Discount</th>
                      <th className="total">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {abandoned_carts.results.map((cart) => (
                      <tr key={cart.id}>
                        <td>
                          <Link to={`/carts/${cart.number}`}>
                            {cart.number}
                          </Link>
                        </td>
                        <td>
                          {formatDate(
                            cart.date_updated || cart.date_created,
                            'age',
                          )}
                        </td>
                        <td>Abandoned</td>
                        <td className="total">
                          {cart.discount_total > 0 ? (
                            <span>
                              (
                              {formatCurrency(
                                cart.discount_total,
                                cart.currency,
                              )}
                              )
                            </span>
                          ) : (
                            <span className="muted">&mdash;</span>
                          )}
                        </td>
                        <td className="total">
                          {formatCurrencyRounded(
                            cart.grand_total,
                            cart.currency,
                          )}
                        </td>
                      </tr>
                    ))}
                    {cartCount > cartLength && (
                      <tr className="footnote">
                        <td colSpan={5}>
                          <Link to={`/carts?customer=${id}`}>
                            View all &rarr;
                          </Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="collection-none-found">No subscriptions yet</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
