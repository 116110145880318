import React from 'react';
import classNames from 'classnames';
import pt from 'prop-types';

import get from 'lodash/get';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import debounce from 'lodash/debounce';

import Label from './label';

function getInitialValue(props) {
  return props.value || props.defaultValue || null;
}

export default class InputAddress extends React.PureComponent {
  static propTypes = {
    help: pt.oneOfType([pt.node, pt.object]),
    label: pt.string,
    values: pt.object,
    options: pt.array,
    newable: pt.bool,
    placeholder: pt.string,
    groupSelectable: pt.bool,
    groupSelectLabel: pt.string,
    disabled: pt.bool,
    disabledOptions: pt.array,
    renderIcon: pt.func,
    iconWidth: pt.number,
    debounce: pt.oneOfType([pt.bool, pt.number]),
    tabIndex: pt.number,

    onAddress: pt.func,
    onChange: pt.func,
  };

  static contextTypes = {
    fetchAddress: pt.func.isRequired,
    clearLookup: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showing: false,
      selected: null,
      value: getInitialValue(props),
      inputValue: '',
      filteredOptions: null,
      foundExact: true,
      disabledOptions: props.disabledOptions || [],
    };

    /** @type {React.RefObject<HTMLDivElement>} */
    this.rootRef = React.createRef();
    /** @type {React.RefObject<HTMLInputElement>} */
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutsideComponent);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutsideComponent);
  }

  /** @param {React.KeyboardEvent} event */
  onKeyDown = (event) => {
    this.setState((prevState) => {
      if (!prevState.showing) {
        return {
          showing: true,
        };
      }
    });

    switch (event.key) {
      case 'Enter': {
        if (this.state.showing) {
          event.preventDefault();
        }
        this.selectValue(event);
        break;
      }

      case 'Esc':
      case 'Escape': {
        this.setState({
          showing: false,
          value: this.state.value,
          inputValue: '',
          foundExact: false,
          filteredOptions: false,
        });
        this.setValue('');
        break;
      }

      case 'Up':
      case 'ArrowUp':
      case 'ArrowLeft': {
        event.preventDefault();
        if (!this.state.showing) {
          const ops = this.state.filteredOptions || this.state.options;
          this.setState({ showing: true, selected: get(ops, '[0].index', 0) });
        } else {
          this.selectPrev();
        }
        break;
      }

      case 'Down':
      case 'ArrowDown':
      case 'ArrowRight': {
        event.preventDefault();
        if (!this.state.showing) {
          const ops = this.state.filteredOptions || this.state.options;
          this.setState({ showing: true, selected: get(ops, '[0].index', 0) });
        } else {
          this.selectNext();
        }
        break;
      }

      // space
      case ' ':
      case 'Spacebar': {
        if (event.target.value.length <= 0) {
          event.preventDefault();
        }
        break;
      }

      case 'Tab': {
        this.setState({
          showing: false,
        });
        this.selectValue(event);
        break;
      }

      default:
        break;
    }
  };

  fetchAddress(params) {
    this.context.fetchAddress(params);
  }

  fetchAddressDebounced = debounce(
    this.fetchAddress.bind(this),
    typeof this.props.debounce === 'number'
      ? this.props.debounce
      : this.props.debounce === true
      ? 300
      : 0,
  );

  /** @param {React.ChangeEvent<HTMLInputElement>} event */
  onChange = (event) => {
    const { values, onChange, options } = this.props;
    const { clearLookup } = this.context;
    const { value } = event.target;

    if (event.target) {
      this.setState({
        inputValue: value,
        value: value,
      });

      onChange(event, value);

      if (value && value.length >= 3) {
        this.fetchAddressDebounced({ search: value, state: values.state });
      } else if (options.length) {
        clearLookup();
      }
    }
  };

  setValue(val, isArray = false, shouldRemove = false) {
    const { onChange } = this.props;
    const { value: stateValue, inputValue } = this.state;
    const event = new Event('change', { bubbles: true });
    const value = val;

    this.setState({ value });
    const result = onChange(event, value);

    if (result === false) {
      this.setState({
        value: stateValue,
        inputValue: inputValue,
      });
    }
  }

  selectValue(event) {
    const { showing, selected, filteredOptions, disabledOptions } = this.state;

    const { options, onAddress } = this.props;

    if (!showing) {
      return;
    }

    const ops = filteredOptions || options;
    const option = find(ops, { index: selected });

    if (option && disabledOptions.includes(option.value)) {
      return;
    }

    if (event.target) {
      const value = option ? option.value : selected ? selected.new : '';

      this.setState(
        {
          showing: false,
          selected: null,
          inputValue: value,
          value,
          filteredOptions: null,
          foundExact: !!option,
        },
        () => {
          onAddress(option);
        },
      );
    }
  }

  findNext(selected = null) {
    const { filteredOptions, inputValue } = this.state;
    const { options } = this.props;

    const ops = filteredOptions || options;

    if (selected !== null) {
      const index = findIndex(ops, { index: selected }) || 0;
      if (ops[index + 1]) {
        return ops[index + 1].index;
      } else if (this.props.newable && inputValue && selected !== inputValue) {
        return { new: inputValue };
      }
    }
    if (ops) {
      return 0;
    }

    return null;
  }

  findPrev(selected = null) {
    const { filteredOptions, inputValue } = this.state;
    const { options } = this.props;

    const ops = filteredOptions || options;

    if (selected !== null) {
      const index = findIndex(ops, { index: selected }) || 0;
      if (selected.new) {
        return ops[ops.length - 1] ? ops[ops.length - 1].index : 0;
      } else if (ops[index - 1]) {
        return ops[index - 1].index;
      } else if (this.props.newable && inputValue && selected !== inputValue) {
        return { new: inputValue };
      }
    }
    if (ops) {
      return this.findNext();
    }

    return null;
  }

  selectNext() {
    const { selected } = this.state;
    const next = this.findNext(selected);

    if (next !== null) {
      this.setState({ selected: next });
    }
  }

  selectPrev() {
    const { selected } = this.state;

    if (selected) {
      const prev = this.findPrev(selected);

      if (prev !== null) {
        this.setState({ selected: prev });
      }
    }
  }

  /** @param {React.MouseEvent<HTMLLIElement>} event */
  onClickSelectValue = (event) => {
    event.preventDefault();

    if (event.currentTarget.className.indexOf('muted') > 0) {
      return;
    }

    this.selectValue(event);

    // Scroll back to top of element
    const modals = document.getElementsByClassName('modal');

    const main =
      modals.length > 0
        ? modals[modals.length - 1]
        : document.getElementById('main');

    if (main) {
      const top = main.scrollTop;
      main.scrollTop = top;
    }
  };

  /** @param {React.MouseEvent<HTMLLIElement>} event */
  onMouseOverValue = (event) => {
    const { index } = event.currentTarget.dataset;

    this.setState({
      selected: Number(index),
      groupSelected: null,
    });
  };

  /** @param {MouseEvent} event */
  onClickOutsideComponent = (event) => {
    if (!this.rootRef.current.contains(event.target)) {
      if (this.state.showing) {
        this.setState((state) => ({
          showing: false,
          inputValue: state.value ? state.inputValue : '',
          selected: null,
          filteredOptions: null,
        }));
      }
    }
  };

  renderOption(option) {
    const { renderIcon } = this.props;
    const { selected, value, disabledOptions } = this.state;

    const isSelected = selected === option.index;
    const isCurrent = option.value === value;

    return (
      <li
        key={option.index}
        role="option"
        tabIndex={-1}
        aria-selected={isSelected}
        aria-current={isCurrent}
        data-index={option.index}
        className={classNames('item', {
          selected: isSelected,
          current: isCurrent,
          muted: disabledOptions.includes(option.value),
        })}
        onMouseOver={this.onMouseOverValue}
        onClick={this.onClickSelectValue}
      >
        <span className="col">
          {typeof renderIcon === 'function' && (
            <span className="form-select-icon">{renderIcon(option.value)}</span>
          )}

          {option.label || <span>&nbsp;</span>}
        </span>
      </li>
    );
  }

  renderOptions() {
    const { options } = this.props;

    if (options.length > 0) {
      return (
        <ul className="form-select-list">
          {options.map((option) => {
            return this.renderOption(option);
          })}
        </ul>
      );
    }

    return null;
  }

  render() {
    const {
      label,
      help,
      model,
      options,
      newable,
      disabledOptions,
      placeholder,
      tabIndex,
      groupSelectable,
      groupSelectLabel,
      renderIcon,
      iconWidth,
      ...props
    } = this.props;

    const { showing, value } = this.state;

    return (
      <div ref={this.rootRef} className="form-select">
        <Label label={label} help={help} htmlFor={props.id} />

        <div className="form-field-input">
          <input
            ref={this.inputRef}
            id={props.id}
            name={props.name}
            type="text"
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            disabled={props.disabled}
            value={value || undefined}
            autoComplete="none"
            placeholder={placeholder}
            className={classNames('form-input', props.className, {
              disabled: props.disabled,
              focus: showing,
            })}
            tabIndex={tabIndex}
          />

          <div className="form-select-list-container">
            {showing && this.renderOptions()}
          </div>
        </div>
      </div>
    );
  }
}
