import React, { Fragment } from 'react';
import Form from 'components/form';
import View from 'components/view';
import ModelForm from './form';
import { modelEndpoint } from 'utils/model';
import { contentCollectionLabel } from 'utils/content';

export default class ModelEdit extends React.PureComponent {
  state = {
    editDesc: false,
  };

  onClickEditDescription = (event) => {
    event.preventDefault();
    this.props.setEditedConfirm(true);
    this.setState({ editDesc: !this.state.editDesc });
  };

  async onSubmitForm(values) {
    const success = await this.props.onSubmitRecord(values);
    if (success) {
      this.props.setEditedConfirm(false);
      this.setState({ editDesc: false });
    }
  }

  render() {
    const {
      record,
      edited,
      editing,
      onSubmitRecord,
      onChangeForm,
      onClickDelete,
      ...props
    } = this.props;
    const { editDesc } = this.state;
    const { values, loading } = props;

    const editable = !record.id || !!record.content_id;
    const tab = props.location.query.tab;

    values.collection = record.name;
    const app = props.client.appsById[record.app_id];

    return (
      <Form
        ref="form"
        onChange={onChangeForm}
        onSubmit={this.onSubmitForm.bind(this)}
      >
        <div className="view-detail-full">
          <View
            detail={true}
            full={record.id}
            uri="/settings/models"
            devtools={{
              model: ':models',
              record,
            }}
            sectionTitle="Models"
            headerTitle={contentCollectionLabel(values)}
            headerSubtitle={
              <Fragment>
                <code>{modelEndpoint(record, app)}</code>
                {values.description && (
                  <span className="note">
                    <span>&mdash;</span> {values.description}
                  </span>
                )}
              </Fragment>
            }
            headerActions={[
              record.standard && {
                label: 'API reference',
                fa: 'book',
                type: 'sub',
                link: `https://developers.swell.is/backend-api/${record.name}`,
                target: 'blank',
              },
            ]}
            menuActions={[
              record.content_id && {
                label: 'Edit model',
                onClick: this.onClickEditDescription,
              },
            ]}
            extraActions={[
              {
                label: record.standard ? 'Reset model' : 'Delete model',
                onClick: onClickDelete,
                className: 'danger',
                hidden: !record.client_id,
                delete: true,
              },
            ]}
            submitting={loading}
          >
            <ModelForm
              {...props}
              record={record}
              editable={editable}
              tab={tab}
              editDesc={editDesc}
              onSubmitRecord={onSubmitRecord}
              onClickEditDescription={this.onClickEditDescription}
            />
          </View>
        </div>
      </Form>
    );
  }
}
