import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import Form from 'components/form';
import AttributeForm from 'components/pages/attribute/form';

export default class NewAttributeModalPage extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  onClickSubmit(event) {
    event.preventDefault();
    this.refs.form.submit();
  }

  onCloseModal(event) {
    if (this.props.edited) {
      event.preventDefault();
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.context.closeModal();
        },
      });
    }
  }

  render() {
    const { onChangeForm, onSubmitRecord } = this.props;

    return (
      <Modal
        title="New attribute"
        width={625}
        onClose={this.onCloseModal}
        actions={[{ label: 'Save attribute', onClick: this.onClickSubmit }]}
        localized={true}
        devtools={{
          model: 'attributes',
          zone: 'content',
        }}
      >
        <Form
          ref="form"
          onSubmit={onSubmitRecord}
          onChange={onChangeForm}
          autoFocus={true}
        >
          <AttributeForm {...this.props} modal={true} />
        </Form>
      </Modal>
    );
  }
}
