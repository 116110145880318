import React from 'react';
import PropTypes from 'prop-types';
import ObjectID from 'bson-objectid';
import { get, map, find, some, filter, isPlainObject } from 'lodash';

import { formatCurrency, isEmpty } from 'utils';
import { renderMultiCurrencyValuesTooltip } from 'utils/money';
import { VARIANT_TYPES } from 'utils/product';

import { Form, Field, FieldLocalized, Fieldtable } from 'components/form';
import Modal from 'components/modal';
import Help from 'components/tooltip/help';
import BoltIcon from 'components/icon/bolt';
import Icon from 'components/icon';
import ContentFields from 'components/content/fields';
import { FadeIn } from 'components/transitions';

import OptionsEdit from './options-edit';

const INPUT_TYPES = [
  {
    value: 'select',
    label: 'Select',
  },
  {
    value: 'toggle',
    label: 'Toggle',
  },
  {
    value: 'short_text',
    label: 'Short text',
  },
  {
    value: 'long_text',
    label: 'Long text',
  },
];

const REQUIRABLE_TYPES = new Set(['short_text', 'long_text']);

const PARENT_TYPES = new Set([
  'select',
  'toggle',
  'dropdown',
  'checkbox',
  'radio',
]);

export default class ProductOptionsCombined extends React.PureComponent {
  static propTypes = {
    record: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    setCountDraftOptions: PropTypes.func.isRequired,
    values: PropTypes.object,
    editingOptions: PropTypes.bool,
    settings: PropTypes.object,
    content: PropTypes.object,
    onSubmitCombinedOptions: PropTypes.func,
    onClickEditOptions: PropTypes.func,
    onClickSaveOptions: PropTypes.func,
    attributes: PropTypes.object,
    disabled: PropTypes.bool,
  };

  static contextTypes = {
    openModal: PropTypes.func,
    refreshModal: PropTypes.func,
    closeModal: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: this.getInitialValue(props),
      valueMeta: this.getInitialValueMeta(props),
      ...this.getFormVisibility(props),
      showEditValue: false,
      editOptionIndex: null,
      editValueIndex: null,
      editValue: null,
      editValueMeta: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.record.id && nextProps.values !== this.props.values) {
      this.setState({
        value: this.getInitialValue(nextProps),
        valueMeta: this.getInitialValueMeta(nextProps),
      });
    }

    if (
      nextProps.editingOptions !== this.props.editingOptions ||
      nextProps.values !== this.props.values
    ) {
      this.setState(this.getFormVisibility(nextProps, this.props));
    }

    if (
      isEmpty(this.state.value) &&
      nextProps.values.variable &&
      nextProps.values.variable !== this.props.values.variable
    ) {
      this.setState({
        value: [
          {
            id: ObjectID().toHexString(),
            name: '',
            values: [],
            variant: true,
            input_type: 'select',
          },
        ],
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.showEditValue && this.state.showEditValue) {
      this.context.openModal(
        'product_options_edit_value',
        this.renderValueForm,
      );
    } else if (prevState.showEditValue && !this.state.showEditValue) {
      this.context.closeModal('product_options_edit_value');
    } else if (this.state.showEditValue) {
      this.context.refreshModal();
    }
  }

  getFormVisibility(nextProps, prevProps) {
    const { combined_options: options } = nextProps.values;
    const isEmptyOptions = isEmpty(options);

    const isFirstNewOption =
      options instanceof Array &&
      options.length === 1 &&
      (options[0].name === '' ||
        (options[0].type &&
          options[0].type === 'select' &&
          isEmpty(options[0].values)));

    const showOptionsEdit =
      nextProps.editingOptions ||
      !nextProps.record.id ||
      isEmptyOptions ||
      isFirstNewOption;

    return {
      showOptionsEdit,
      showOptionsTable: !showOptionsEdit,
    };
  }

  getInitialValue(props) {
    return !isEmpty(props.values.combined_options)
      ? [...props.values.combined_options]
      : [];
  }

  getInitialValueMeta(props) {
    if (!isEmpty(props.values.combined_options)) {
      return this.getValueMeta(props.values.combined_options);
    }
    return {};
  }

  getValueMeta(value) {
    return value.reduce((acc, option, index) => {
      acc[index] = ((option && option.values) || []).reduce((acc, value) => {
        if (value) {
          acc[value.name] = value;
        }

        return acc;
      }, {});

      return acc;
    }, {});
  }

  renderParentOptionName({ parent_id, parent_value_ids }, allValues) {
    const parent = allValues && find(allValues, { id: parent_id });

    if (parent) {
      const parentValues =
        (parent_value_ids &&
          parent.values &&
          parent.values.filter(
            (val) =>
              parent_value_ids.includes && parent_value_ids.includes(val.id),
          )) ||
        [];

      if (
        parent_value_ids &&
        parent_value_ids.length > 0 &&
        parentValues.length <= 0
      ) {
        return <span className="muted">&mdash;</span>;
      }

      return (
        <>
          {parent.name}
          {parentValues.length > 0 && (
            <> ({parentValues.map((val) => val.name).join(', ')})</>
          )}
        </>
      );
    }

    return null;
  }

  renderOptionType({ input_type, variant }) {
    const type = INPUT_TYPES.find((type) => type.value === input_type);

    return (
      <>
        {type
          ? type.label
          : input_type || (variant ? 'Select' : <span>&mdash;</span>)}
      </>
    );
  }

  renderInputType(inputType, selectedType) {
    const { value, label } = inputType;

    switch (value) {
      case 'select':
        return (
          <div className="form-radio-button-wrapper">
            <div className="label">
              <Icon
                type={`${
                  selectedType === 'select' ? 'select-active' : 'select'
                }`}
              />
              {label}
            </div>
            <span className="description muted">
              Multiple preset option values
            </span>
          </div>
        );
      case 'toggle':
        return (
          <div className="form-radio-button-wrapper">
            <div className="label">
              <Icon
                type={`${
                  selectedType === 'toggle' ? 'toggle-active' : 'toggle'
                }`}
              />
              {label}
            </div>
            <span className="description muted">
              Binary option values (on/off)
            </span>
          </div>
        );
      case 'short_text':
        return (
          <div className="form-radio-button-wrapper">
            <div className="label">
              <Icon
                type={`${
                  selectedType === 'short_text'
                    ? 'short-text-active'
                    : 'short-text'
                }`}
              />
              {label}
            </div>
            <span className="description muted">
              Small text field for user input
            </span>
          </div>
        );
      case 'long_text':
        return (
          <div className="form-radio-button-wrapper">
            <div className="label">
              <Icon
                type={`${
                  selectedType === 'long_text'
                    ? 'long-text-active'
                    : 'long-text'
                }`}
              />
              {label}
            </div>
            <span className="description muted">
              Large text area for user input
            </span>
          </div>
        );
      default:
        return 'Unknown input type';
    }
  }

  renderTableHeading = (allValues) => {
    const hasParent = some(allValues, (value) => Boolean(value.parent_id));

    return [
      <th key="1">Option</th>,
      <th key="2">Type</th>,
      <th key="3">Values</th>,
      hasParent && <th key="4">Show if</th>,
      <th key="6">Status</th>,
    ];
  };

  renderTableValue = ({ value, index }, allValues) => {
    const { currency } = this.props;
    const weightUnit = this.props.settings.shipments.weight_unit;
    const hasParent = some(allValues, (value) => Boolean(value.parent_id));

    return [
      <td key="1">
        <span>
          {value.name}
          <span className="nowrap">
            &nbsp;
            {value.variant && <BoltIcon active={value.active} />}
            {!!value.description && <Help message={value.description} />}
          </span>
        </span>
      </td>,
      <td key="3" className="nowrap">
        <span>{this.renderOptionType(value)}</span>
      </td>,
      <td
        key="4"
        className={
          value.values && value.values.length > 0
            ? 'product-options-value-cell'
            : 'product-options-value-cell-empty'
        }
      >
        {Array.isArray(value.values) && value.values.length > 0 ? (
          value.values.map(
            (val, i) =>
              val && (
                <>
                  <span className="product-options-value" key={i}>
                    {val.name}
                    {(val.price > 0 || val.shipment_weight) > 0 && (
                      <>
                        {val.price > 0 && (
                          <>
                            <span className="product-options-value-separator" />+
                            {formatCurrency(val.price, currency)}
                            {renderMultiCurrencyValuesTooltip(
                              val.$currency,
                              'price',
                              currency,
                            )}
                          </>
                        )}
                        {val.shipment_weight > 0 && (
                          <>
                            <span className="product-options-value-separator" />+
                            {val.shipment_weight} {weightUnit}
                          </>
                        )}
                      </>
                    )}
                    {!!val.description && <Help message={val.description} />}
                  </span>
                  <wbr />
                </>
              ),
          )
        ) : REQUIRABLE_TYPES.has(value.input_type) ? (
          value.required ? (
            <span className="positive">Required</span>
          ) : (
            <span className="muted">Optional</span>
          )
        ) : (
          <span className="muted">&mdash;</span>
        )}
      </td>,
      hasParent && (
        <td key="2" className="nowrap">
          {value.parent_id ? (
            <span>{this.renderParentOptionName(value, allValues)}</span>
          ) : (
            <span className="muted">&mdash;</span>
          )}
        </td>
      ),
      <td className="compact" key="6">
        <Field
          type="toggle"
          name={`[${index}].active`}
          label="Active"
          defaultChecked={value.active}
          fieldtableInline={true}
        />
      </td>,
    ];
  };

  renderTableForm = (value, index, allValues) => {
    const { settings, /*attributes,*/ currency, content } = this.props;

    const optionType =
      value.input_type !== undefined ? value.input_type : 'select';

    const canBeVariant = VARIANT_TYPES.includes(optionType);
    const canBeRequired = REQUIRABLE_TYPES.has(optionType);

    const isVariant = canBeVariant
      ? value.variant !== undefined
        ? value.variant
        : true
      : false;

    const isRequired = canBeRequired
      ? value.required
      : optionType === 'select'
      ? true
      : value.required;

    const selectValues =
      value.select_values || (optionType === 'select' && value.values) || [];

    const toggleValues =
      value.toggle_values || (optionType === 'toggle' && value.values) || [];

    const toggleVal = toggleValues.length > 0 ? toggleValues[0] : {};

    if (optionType === 'toggle') {
      // The name of the toggle value and the toggle option must be the same
      // This is necessary so that the option name gets into the name of the product variant
      toggleVal.name = value.name;
      toggleVal.$locale = value.$locale;
    }

    const otherValues = filter(
      allValues,
      (parent) =>
        PARENT_TYPES.has(parent.input_type || 'short_text') &&
        parent.id !== value.id,
    );

    const parentOption =
      value.parent_id &&
      filter(allValues, (parent) => parent.id === value.parent_id)[0];

    const canBeHidden = otherValues.length > 0 && (!isVariant || !isRequired);

    if (value.input_type === 'short_text' || value.input_type === 'long_text') {
      value.values = [];
    }

    return (
      <div>
        <Field
          type="hidden"
          name="id"
          value={value.id || ObjectID().toHexString()}
        />

        <Field type="hidden" name="values" value={value.values} />

        <FieldLocalized
          type="text"
          label="Name"
          name="name"
          value={value.name}
          localeValue={value.$locale}
          placeholder="e.g. Color, Size"
          required={true}
        />
        {/*<Field
          type="select"
          label="Name"
          name="name"
          value={value.name}
          placeholder="e.g. Color, Size"
          options={attributes.results.filter((attr) => attr.variant)}
          disabledOptions={allValues.map((op) => op.name)}
          newable={true}
          required={true}
        />*/}

        <Field
          type="toggle"
          name="active"
          label="Active"
          defaultChecked={value.active !== undefined ? value.active : true}
        />

        <Field
          type="radio"
          label="Input type"
          name="input_type"
          buttonsWithIcons={true}
          value={optionType}
          defaultValue="true"
          options={map(INPUT_TYPES, (inputType) => ({
            value: inputType.value,
            label: this.renderInputType(inputType, optionType),
          }))}
          required={true}
        />

        {optionType === 'short_text' && (
          <FadeIn>
            <Field
              type="text"
              name="input_hint"
              label="Placeholder"
              value={value.input_hint}
              placeholder="Optional"
              help="Placeholder text to indicate how this option should be used"
            />
          </FadeIn>
        )}

        {optionType === 'long_text' && (
          <FadeIn>
            <Field
              type="textarea"
              name="input_hint"
              label="Placeholder"
              value={value.input_hint}
              placeholder="Optional"
              help="Placeholder text to indicate how this option should be used"
              rows={4}
            />
          </FadeIn>
        )}

        {optionType === 'select' ? (
          <FadeIn>
            <Field
              label="Option values"
              type="tags"
              name="select_values"
              value={selectValues}
              onChange={this.onChangeInput}
              onSelectValue={this.onSelectTagValue.bind(this, index)}
              onRemoveValue={this.onRemoveTagValue.bind(this, index)}
              renderTag={this.renderTagValue.bind(this, index)}
              placeholder="Separate values with a comma"
              required={true}
              rules="unique"
            />
          </FadeIn>
        ) : (
          <Field type="hidden" name="select_values" value={selectValues} />
        )}

        {optionType === 'toggle' ? (
          <FadeIn>
            <Field
              type="hidden"
              name="toggle_values[0].id"
              value={toggleVal.id || ObjectID().toHexString()}
            />

            <FieldLocalized
              type="hidden"
              name="toggle_values[0].name"
              localeFieldPrefix="toggle_values[0]"
              localeFieldName="name"
              value={toggleVal.name}
              localeValue={toggleVal.$locale}
            />

            <div className="row">
              <FieldLocalized
                type="currency"
                name="toggle_values[0].price"
                localeFieldPrefix="toggle_values[0]"
                localeFieldName="price"
                label="Add price"
                defaultValue={toggleVal.price}
                currency={currency}
                localeValue={toggleVal.$currency}
                help="Will be added to the original product price"
                className="span2"
              />

              <Field
                type="number"
                name="toggle_values[0].shipment_weight"
                label={`Add shipping weight (${settings.shipments.weight_unit})`}
                defaultValue={toggleVal.shipment_weight}
                help="Will be added to the original product weight for shipping calculation"
                placeholder="0"
                scale={2}
                selectFocus={true}
                className="span2"
              />
            </div>
          </FadeIn>
        ) : (
          <Field type="hidden" name="toggle_values" value={toggleValues} />
        )}

        {canBeVariant && (
          <FadeIn>
            <Field
              type="toggle"
              name="variant"
              label={
                <>
                  Automatically generate variants
                  <BoltIcon />
                </>
              }
              help="Option values will be included when generating product variants"
              defaultChecked={isVariant}
            />
          </FadeIn>
        )}

        {canBeHidden && (
          <FadeIn>
            <Field
              type="select"
              label="Show if selected"
              name="parent_id"
              defaultValue={value.parent_id}
              options={otherValues}
              placeholder="Optional"
              help="This option will be hidden unless the parent option has a value"
            />

            {parentOption &&
              parentOption.input_type !== 'toggle' &&
              parentOption.values &&
              parentOption.values.length > 0 && (
                <Field
                  type="checkbox"
                  name="parent_value_ids"
                  defaultValue={value.parent_value_ids}
                  options={parentOption.values}
                  required={true}
                  placeholder="Optional"
                  help="This option will be hidden unless a parent option value is selected"
                />
              )}
          </FadeIn>
        )}

        <div className="row">
          <FieldLocalized
            label="Description"
            type="textarea"
            name="description"
            defaultValue={value.description}
            localeValue={value.$locale}
            placeholder="Optional"
            autoSize={true}
            rows={1}
            maxRows={5}
            className="span4"
          />
        </div>

        {canBeRequired ? (
          <FadeIn>
            <Field
              type="toggle"
              name="required"
              label="Require a value"
              defaultChecked={value.required}
              help="Require a value for this option before adding to cart"
            />
          </FadeIn>
        ) : optionType === 'select' ? (
          <Field type="hidden" name="required" value={true} />
        ) : (
          <Field type="hidden" name="required" value="" />
        )}

        <ContentFields
          {...this.props}
          zone="option-edit"
          root="options"
          collection="products"
          models={content.models}
          record={value}
          values={value}
          currency={currency}
        />
      </div>
    );
  };

  renderTableDevtools = (value) => {
    const { record = {} } = this.props;
    return {
      model: 'products',
      zone: 'option-edit',
      uri: record.id && value.id && `${record.id}/options/${value.id}`,
    };
  };

  renderTagValue = (index, value) => {
    const { settings, currency } = this.props;

    let valueMeta;
    if (this.refs.optionsEdit) {
      valueMeta = this.refs.optionsEdit.state.valueMeta;
    } else {
      valueMeta = this.state.editValueMeta || this.state.valueMeta;
    }
    const meta = get(valueMeta, `[${index}][${value}]`, {});

    return (
      <>
        {value}
        {meta.price > 0 && (
          <>
            <span className="product-options-value-separator" />+
            {formatCurrency(meta.price, currency)}
            {renderMultiCurrencyValuesTooltip(
              meta.$currency,
              'price',
              currency,
            )}
          </>
        )}
        {meta.shipment_weight > 0 && (
          <>
            <span className="product-options-value-separator" />+
            {meta.shipment_weight} {settings.shipments.weight_unit}
          </>
        )}
        {!!meta.description && <Help message={meta.description} />}
      </>
    );
  };

  onSelectTagValue = (index, name, valueIndex) => {
    let editValue;
    if (this.refs.optionsEdit) {
      editValue = get(
        this.refs.optionsEdit.state.valueMeta,
        `[${index}][${name}]`,
      );
    }
    if (!editValue) {
      const valueMeta = this.state.editValueMeta || this.state.valueMeta;
      editValue = get(valueMeta, `[${index}][${name}]`, { name });
    }
    this.setState({
      showEditValue: true,
      editOptionIndex: index,
      editValueIndex: valueIndex,
      editValue,
    });
  };

  onRemoveTagValue = (index, name) => {
    const valueMeta = this.state.editValueMeta || this.state.valueMeta;
    const meta = get(valueMeta, `[${index}][${name}]`);
    if (meta && (meta.price || meta.shipment_weight || meta.description)) {
      return false;
    }
  };

  onSubmitValueForm = (values) => {
    const { editValueIndex } = this.state;

    // TODO: this is a total hack because we're out of time to fix it.
    // Later we should refactor and unbundle all this logic

    let value;
    let editValue;
    let editOptionIndex;
    if (this.refs.optionsTable) {
      value = [...this.state.value];
      editValue = this.refs.optionsTable.state.editValue;
      editOptionIndex = this.state.editOptionIndex;
    } else if (this.refs.optionsEdit.state.moreValue) {
      value = [...this.refs.optionsEdit.state.value];
      editValue = this.refs.optionsEdit.state.moreValue;
      editOptionIndex = this.refs.optionsEdit.state.moreIndex;
    } else if (this.refs.optionsEdit.state.value) {
      value = [...this.refs.optionsEdit.state.value];
      editOptionIndex = this.state.editOptionIndex;
      editValue = this.refs.optionsEdit.state.value[editOptionIndex];
    } else {
      value = [...this.state.value];
      editValue = this.state.value[editOptionIndex];
      editOptionIndex = this.state.editOptionIndex;
    }

    const exValues = [...(editValue.select_values || editValue.values || {})];

    value[editOptionIndex] = {
      ...editValue,
      values: exValues,
      select_values: exValues,
    };

    const selectValuesByIndex =
      value[editOptionIndex].select_values[editValueIndex];

    value[editOptionIndex].select_values[editValueIndex] = {
      ...(isPlainObject(selectValuesByIndex) ? selectValuesByIndex : {}),
      ...values,
    };

    const valuesByIndex = value[editOptionIndex].values[editValueIndex];

    value[editOptionIndex].values[editValueIndex] = {
      ...(isPlainObject(valuesByIndex) ? valuesByIndex : {}),
      ...values,
    };

    if (this.refs.optionsTable) {
      this.refs.optionsTable.onChangeModalForm({
        ...value[editOptionIndex],
      });
      this.setState({
        editValueMeta: this.getValueMeta(value),
      });
    } else if (this.refs.optionsEdit) {
      if (this.refs.optionsEdit.state.moreValue) {
        this.refs.optionsEdit.onChangeMoreForm({
          ...value[editOptionIndex],
        });
      } else {
        this.refs.optionsEdit.setState({
          value,
        });
      }
      const { valueMeta } = this.refs.optionsEdit.state;
      const editValueMeta = {
        ...valueMeta,
        [editOptionIndex]: {
          ...(valueMeta[editOptionIndex] || {}),
          [values.name]: { ...values },
        },
      };

      this.refs.optionsEdit.setState({
        valueMeta: editValueMeta,
      });

      this.setState({ editValueMeta });
    }

    this.setState({ showEditValue: false });
  };

  onChangeValueForm = (values) => {
    this.setState((state) => ({
      editValue: {
        ...state.editValue,
        ...values,
      },
    }));
  };

  onCloseValueForm = () => {
    this.setState({ showEditValue: false });
  };

  onClickCloseValueForm = (event) => {
    event.preventDefault();
    this.onCloseValueForm();
  };

  renderValueForm = () => {
    const { /*attributes,*/ currency, content, record = {} } = this.props;
    const { editOptionIndex } = this.state;

    const value = this.state.editValue;

    return (
      <Form
        onSubmit={this.onSubmitValueForm}
        onChange={this.onChangeValueForm}
        autoFocus={true}
      >
        <Modal
          title="Edit option value"
          width={600}
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'cancel',
              onClick: this.onClickCloseValueForm,
            },
          ]}
          cancel={false}
          onClose={this.onCloseValueForm}
          localized={true}
          devtools={{
            model: 'products',
            zone: 'option-value-edit',
            uri:
              record.id &&
              value.id &&
              `${record.id}/options/${editOptionIndex}/values/${value.id}`,
          }}
        >
          <fieldset>
            <FieldLocalized
              type="text"
              name="name"
              label="Name"
              defaultValue={value.name}
              localeValue={value.$locale}
              required={true}
              autoFocus={true}
            />
            {/*<Field
              type="select"
              label="Name"
              name="name"
              defaultValue={value.name}
              placeholder="e.g. Color, Size"
              options={attributes.results.filter((attr) => attr.variant)}
              newable={true}
              required={true}
              autoFocus={true}
            />*/}
            <div className="row">
              <FieldLocalized
                type="currency"
                name="price"
                label="Add price"
                defaultValue={value.price}
                currency={currency}
                localeValue={value.$currency}
                help="Will be added to the original product price"
                className="span2"
              />
              <Field
                type="number"
                name="shipment_weight"
                label="Add shipping weight"
                defaultValue={value.shipment_weight}
                help="Will be added to the original product weight for shipping calculation"
                placeholder="0"
                scale={2}
                selectFocus={true}
                className="span2"
              />
            </div>
            <div className="row">
              <FieldLocalized
                label="Description"
                type="textarea"
                name="description"
                defaultValue={value.description}
                localeValue={value.$locale}
                placeholder="Optional"
                autoSize={true}
                rows={1}
                maxRows={5}
                className="span4"
              />
            </div>
            <ContentFields
              {...this.props}
              zone="option-value-edit"
              root="options.values"
              collection="products"
              models={content.models}
              record={value}
              values={value}
              currency={currency}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  };

  renderValueHeading = () => {
    return [
      <th key="1">Value</th>,
      <th key="2">Price</th>,
      <th key="3">Weight</th>,
    ];
  };

  renderValueValue = ({ value, index, disabled, onChange }) => {
    return [
      <td key="1" width="50%">
        <Field
          type="text"
          name="name"
          defaultValue={value.name}
          required={true}
          autoFocus={true}
          disabled={disabled}
          onChange={onChange}
          data-index={index}
        />
      </td>,
      <td key="2" width="25%">
        <FieldLocalized
          type="currency"
          name="price"
          defaultValue={value.price}
          currency={this.props.currency}
          localeValue={value.$currency}
          disabled={disabled}
          onChange={onChange}
          data-index={index}
        />
      </td>,
      <td key="3" width="25%">
        <Field
          type="number"
          name="shipment_weight"
          defaultValue={value.shipment_weight}
          placeholder="0"
          disabled={disabled}
          onChange={onChange}
          data-index={index}
        />
      </td>,
    ];
  };

  onEditTableRow = () => {
    this.setState({ editValueMeta: null });
  };

  onSubmitTable = (values) => {
    const { onSubmitCombinedOptions } = this.props;

    if (this.state.disabled) {
      return [];
    }

    return onSubmitCombinedOptions(
      map(values, (option) => {
        const values =
          (option.input_type === 'select'
            ? option.select_values
            : option.input_type === 'toggle'
            ? option.toggle_values
            : null) || option.values;

        return {
          ...option,
          variant: VARIANT_TYPES.includes(option.input_type)
            ? option.variant
            : false,
          select_values: undefined,
          toggle_values: undefined,
          values:
            Array.isArray(values) &&
            values.map((optionValue) => {
              const object =
                typeof optionValue === 'string'
                  ? { name: optionValue }
                  : optionValue;

              if (!object.id) {
                object.id = ObjectID().toHexString();
              }

              return {
                ...object,
              };
            }),
        };
      }),
    );
  };

  onClickEditOptions = (event) => {
    if (this.props.editingOptions) {
      this.setState({ editValueMeta: null });
    }
    this.props.onClickEditOptions(event);
  };

  render() {
    const {
      currency,
      settings,
      attributes,
      disabled,
      editingOptions,
      onClickSaveOptions,
      values: { variable } = {},
      setCountDraftOptions,
    } = this.props;

    const { value, showOptionsEdit, showOptionsTable } = this.state;

    return (
      <div className="product-options">
        <FadeIn active={!disabled} transitionAppear={false}>
          <FadeIn active={showOptionsEdit} transitionAppear={false}>
            {showOptionsEdit && (
              <>
                <OptionsEdit
                  name="combined_options"
                  ref="optionsEdit"
                  settings={settings}
                  attributes={attributes}
                  currency={currency}
                  defaultValue={value}
                  editingOptions={editingOptions}
                  variable={variable}
                  onClickSaveOptions={onClickSaveOptions}
                  onClickEditOptions={this.onClickEditOptions}
                  onSelectTagValue={this.onSelectTagValue}
                  onRemoveTagValue={this.onRemoveTagValue}
                  onSubmitValueForm={this.onSubmitValueForm}
                  onChangeValueForm={this.onChangeValueForm}
                  renderMoreForm={this.renderTableForm}
                  renderTagValue={this.renderTagValue}
                  renderValueForm={this.renderValueForm}
                  renderDevtools={this.renderTableDevtools}
                  setCountDraftOptions={setCountDraftOptions}
                />
              </>
            )}
          </FadeIn>
          <FadeIn active={showOptionsTable} transitionAppear={false}>
            {showOptionsTable && (
              <Fieldtable
                label="Option"
                name="combined_options"
                ref="optionsTable"
                defaultValue={value}
                renderHeading={this.renderTableHeading}
                renderValue={this.renderTableValue}
                renderForm={this.renderTableForm}
                disabled={disabled || showOptionsEdit}
                formWidth={750}
                sortable={true}
                submitPreventValue
                onSubmit={this.onSubmitTable}
                onEditRow={this.onEditTableRow}
                editDevtools={this.renderTableDevtools}
                localized={true}
              />
            )}
          </FadeIn>
        </FadeIn>
      </div>
    );
  }
}
