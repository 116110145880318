import moment from 'moment';
import api from 'services/api';
import * as CSV from 'csv-string';
import fileDownload from 'js-file-download';
import flash from './flash';
import { formatDate, isBase64, b64toBlob } from 'utils';

const DEFAULT_LIMIT = 50;
const EXPORT_PAGE_LIMIT = 100;

export const REPORTS_SET_DATE_RANGE = 'reports/setDateRange';
export const REPORTS_FETCH_CONVERSION = 'reports/fetchConversion';
export const REPORTS_FETCH_GRAPH = 'reports/fetchGraph';
export const REPORTS_FETCH_COLLECTION = 'reports/fetchCollection';
export const REPORTS_FETCH_RECORDS = 'reports/fetchRecords';
export const REPORTS_LIST_CUSTOM = 'reports/listCustom';
export const REPORTS_FETCH_CUSTOM = 'reports/fetchCustom';
export const REPORTS_UPDATE_CUSTOM = 'reports/updateCustom';
export const REPORTS_DELETE_CUSTOM = 'reports/deleteCustom';
export const REPORTS_DOWNLOAD_CUSTOM = 'reports/downloadCustom';
export const REPORTS_EXPORT_START = 'reports/exportStart';
export const REPORTS_EXPORT_PROGRESS = 'reports/exportProgress';
export const REPORTS_EXPORT_CANCEL = 'reports/exportCancel';
export const REPORTS_EXPORT_COMPLETE = 'reports/exportComplete';
export const REPORTS_CREATE_CUSTOM = 'reports/createCustom';

export default {
  setDateRange(start, end) {
    return {
      type: REPORTS_SET_DATE_RANGE,
      payload: { start, end },
    };
  },

  fetchGraph(model, params = {}) {
    const { startDate, endDate, period, chartType } = params;

    const data = {
      period,
      start: formatDate(startDate, 'isoString'),
      end: formatDate(endDate, 'isoString'),
    };

    return {
      type: REPORTS_FETCH_GRAPH,
      payload: api.get(
        `/data/reports/graph/${model}${chartType ? `-${chartType}` : ''}`,
        data,
      ),
      meta: { model, data },
    };
  },

  fetchCollection(type, query = {}) {
    query.limit = query.limit || DEFAULT_LIMIT;
    return {
      type: REPORTS_FETCH_COLLECTION,
      payload: api.post(`/data/reports/${type}`, query),
    };
  },

  fetchRecords(model, query = {}) {
    query.limit = query.limit || DEFAULT_LIMIT;
    return {
      type: REPORTS_FETCH_RECORDS,
      payload: api.get(`/data/${model}`, query),
    };
  },

  listCustom() {
    return {
      type: REPORTS_LIST_CUSTOM,
      payload: api.get('/data/reports/custom'),
    };
  },

  fetchCustom(id, query) {
    return {
      type: REPORTS_FETCH_CUSTOM,
      payload: api.get(`/data/reports/custom/${id}`, id, query),
    };
  },

  createCustom(data) {
    return {
      type: REPORTS_CREATE_CUSTOM,
      payload: api.post('/data/reports/custom', data),
    };
  },

  updateCustom(id, data) {
    return {
      type: REPORTS_UPDATE_CUSTOM,
      payload: api.put(`/data/reports/custom/${id}`, id, data),
    };
  },

  deleteCustom(id) {
    return {
      type: REPORTS_DELETE_CUSTOM,
      payload: api.delete(`/data/reports/custom/${id}`),
    };
  },

  downloadCustom(id) {
    return async (dispatch) => {
      try {
        const report = await api.get(`/data/reports/custom/${id}`);
        dispatch(
          flash.success(`Downloading ${report.label || report.name}...`, 3000),
        );
        let fileData = await api.get(`/data/reports/custom/${id}/data`);
        if (!report) {
          dispatch(flash.error('Report not found'));
          return;
        }
        if (!report.custom_file || !fileData) {
          dispatch(flash.error('Report data not found'));
          return;
        }
        if (isBase64(fileData)) {
          fileData = b64toBlob(fileData, report.custom_file.content_type);
        }
        fileDownload(fileData, report.custom_file.filename || 'report.csv');
      } catch (err) {
        dispatch(flash.error(err instanceof Error ? err.message : err));
      }
    };
  },

  fetchConversion() {
    return {
      type: REPORTS_FETCH_CONVERSION,
      payload: api.post('/data/reports/conversion', {}),
    };
  },

  fetchAccountUsageGraph() {
    return (dispatch, getState) => {
      const { account } = getState();

      return new Promise(async (resolve) => {
        const [current, previous] = await Promise.all(
          [
            {
              startDate: account.plan.date_period_start,
              endDate: account.plan.date_period_end,
            },
            {
              startDate: moment(account.plan.date_period_start).subtract(
                1,
                account.plan.interval === 'yearly' ? 'year' : 'month',
              ),
              endDate: account.plan.date_period_start,
            },
          ].map(({ startDate, endDate }) =>
            dispatch(
              this.fetchGraph('account', {
                chartType: 'usage',
                period: 'dayOfMonth',
                startDate,
                endDate,
              }),
            ),
          ),
        );

        resolve({ current, previous });
      });
    };
  },

  export(params: Object) {
    return (dispatch, getState) => {
      if (getState().reports.export.running) {
        return;
      }

      dispatch(this.exportStart(params));

      runExport({
        getState,
        params,
        onProgress: (percent) => {
          dispatch(this.exportProgress(percent));
        },
        onCancel: () => {
          dispatch(this.exportCancel());
        },
        onComplete: (data, filename, mimetype) => {
          dispatch(this.exportComplete(data, filename, mimetype));
        },
        onError: (message) => {
          dispatch(this.exportCancel());
          dispatch(flash.error(message));
        },
      });
    };
  },

  exportStart(params: Object) {
    return {
      type: REPORTS_EXPORT_START,
      payload: params,
    };
  },

  exportProgress(percent: Number) {
    return {
      type: REPORTS_EXPORT_PROGRESS,
      payload: percent,
    };
  },

  exportCancel() {
    return {
      type: REPORTS_EXPORT_CANCEL,
      payload: null,
    };
  },

  exportComplete(data: String, filename: String, mimetype: String) {
    return {
      type: REPORTS_EXPORT_COMPLETE,
      payload: { data, filename, mimetype },
    };
  },
};

export const initialState = {
  date_start: null,
  date_end: null,
  overview: {
    // TODO
  },
  current: {
    // TODO
  },
  custom: {},
  export: {
    running: false,
    complete: false,
    params: {},
    percent: 0,
    data: null,
    filename: null,
  },
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case REPORTS_SET_DATE_RANGE:
      return {
        ...state,
        date_start: action.payload.start,
        date_end: action.payload.end,
      };

    case REPORTS_LIST_CUSTOM:
      return {
        ...state,
        custom: action.payload,
      };

    case REPORTS_FETCH_CUSTOM:
      return {
        ...state,
        custom: action.payload,
      };

    case REPORTS_EXPORT_START:
      return {
        ...state,
        export: {
          ...initialState.export,
          running: true,
          params: action.payload,
        },
      };

    case REPORTS_EXPORT_PROGRESS:
      return {
        ...state,
        export: {
          ...state.export,
          percent: action.payload,
        },
      };

    case REPORTS_EXPORT_CANCEL:
      return {
        ...state,
        export: {
          ...initialState.export,
        },
      };

    case REPORTS_EXPORT_COMPLETE:
      return {
        ...state,
        export: {
          ...state.export,
          running: false,
          complete: true,
          percent: 100,
          data: action.payload.data,
          filename: action.payload.filename,
          mimetype: action.payload.mimetype,
        },
      };

    default:
      return state;
  }
}

const exportCSVFields = [
  // TODO
];

function runExport(args, data = []) {
  const { getState, params, onCancel, onError, page = 1 } = args;

  const query = getExportQuery(params, getState);

  if (!query) {
    onError('There is nothing to export');
    return;
  }

  return api
    .get('/data/:group', { ...query, page })
    .then((result) => {
      // May be no results
      if (!result.count && !data.length) {
        onError('There is nothing to export');
        return;
      }
      // May be canceled while running
      if (getState().reports.export.running !== true) {
        onCancel();
        return;
      }
      // Update progress state
      handleExportProgress(args, result);
      // Update data from result
      handleExportData(args, result, data);
      // Complete when pages run out
      if (result.pages && result.pages[page + 1] === undefined) {
        handleExportComplete(args, data);
        return;
      }
      // Next page
      return runExport({ ...args, page: page + 1 }, data);
    })
    .catch((err) => {
      onError(err.message);
      console.error(err);
    });
}

function getExportQuery(params, getState) {
  const query = {
    sort: 'date_created desc',
    limit: EXPORT_PAGE_LIMIT,
    // Expand for CSV only
    expand:
      params.format !== 'csv'
        ? []
        : [
            'account',
            'promotions',
            'items.product',
            'items.variant',
            'items.bundle_items.product',
            'items.bundle_items.variant',
          ],
  };

  const { data } = getState();

  switch (params.type) {
    case 'page':
      query.id = { $in: data.collection.results.map((result) => result.id) };
      break;
    case 'search':
      query.search = data.query.search;
      query.where = data.query.where;
      break;
    case 'date':
      query.$and = [
        { date_created: { $gte: params.date_start } },
        { date_created: { $lte: params.date_end } },
      ];
      break;
    case 'selected':
      // Includes search
      query.search = data.query.search;
      query.where = data.query.where;
      if (data.selection.all) {
        const exceptIds = Object.keys(data.selection.except);
        if (exceptIds.length) {
          query.id = { $nin: exceptIds };
        }
      } else {
        const recordIds = Object.keys(data.selection.records);
        if (recordIds.length) {
          query.id = { $in: recordIds };
        } else {
          return;
        }
      }
      break;
    case 'all':
    default:
      break;
  }
  return query;
}

function handleExportProgress({ page = 1, onProgress }, result) {
  let percent = Math.ceil(((page * EXPORT_PAGE_LIMIT) / result.count) * 100);
  if (percent > 100) {
    percent = 100;
  }
  onProgress(percent);
}

function handleExportData({ params }, result, data) {
  result.results.forEach((result) => {
    switch (params.format) {
      case 'json':
        data.push(result);
        break;
      case 'csv':
      default:
        // CSV header first
        if (data.length === 0) {
          data.push(exportCSVFields.map((field) => field.label));
        }
        Array.prototype.push.apply(data, generateCSVRows(result));
        break;
    }
  });
}

function handleExportComplete({ getState, params, onComplete }, data = []) {
  let finalData;
  let filename;
  let mimetype;
  let extype;

  const { client } = getState();
  const now = Date.now();

  switch (params.type) {
    case 'page':
      extype = `current-page-${formatDate(now, 'isoDate')}`;
      break;
    case 'search':
      extype = `current-search-${formatDate(now, 'isoDate')}`;
      break;
    case 'date':
      extype = `by-date-${formatDate(
        params.date_start,
        'isoDate',
      )}-to-${formatDate(params.date_end, 'isoDate')}`;
      break;
    case 'selected':
      extype = `selected-${formatDate(now, 'isoDate')}`;
      break;
    default:
    case 'all':
      extype = `all-${formatDate(now, 'isoDate')}`;
      break;
  }

  switch (params.format) {
    case 'json':
      filename = `${client.id}-report-${extype}.json`;
      mimetype = 'application/json';
      finalData = `${JSON.stringify(data)}`;
      break;
    case 'csv':
    default:
      filename = `${client.id}-report-${extype}.csv`;
      mimetype = 'text/csv';
      finalData = CSV.stringify(data);
      break;
  }

  onComplete(finalData, filename, mimetype);
}

function generateCSVRows(result) {
  // Generate values for each CSV field
  const values = exportCSVFields.reduce((cols, field) => {
    let value;
    try {
      if (typeof field.value === 'function') {
        value = field.value(result);
      } else {
        value = field.value;
      }
    } catch (err) {
      // Oops
    }
    cols.push(value);
    return cols;
  }, []);

  // Generate row matrix from values
  const rows = [[]];
  values.forEach((value, topIndex) => {
    if (value instanceof Array) {
      value.forEach((val, valIndex) => {
        if (rows[valIndex] === undefined) {
          rows[valIndex] = Array(values.length).fill('');
        }
        rows[valIndex][topIndex] = val || '';
      });
    } else {
      rows[0].push(value || '');
    }
  });

  return rows;
}
