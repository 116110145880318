import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';

import { arrayToObject, isPrice, snakeCase, currencyValue } from 'utils';
import { renderMultiCurrencyValuesTooltip } from 'utils/money';
import {
  countryName,
  countryOptions,
  stateOptions,
  countryStateLabel,
} from 'utils/geo';

import {
  Field,
  FieldLocalized,
  FieldAmount,
  Fieldtable,
  Fieldgroup,
} from 'components/form';

const AVATAX_SHIPPING_TAX_CODES_URL =
  'https://taxcode.avatax.avalara.com/search?category=Freight&subcategory=Shipping%20and%20handling&tab=decision_tree';

function hasTaxCodes(props) {
  const { settings } = props;

  if (!get(settings.integrations, 'services.avatax.activated')) {
    return false;
  }

  return true;
}

export default class ShippingServices extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
  };

  static propTypes = {
    services: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    zones: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      services: filter(props.services, (service) => !service.carrier),
    };

    this.hasTaxCodes = hasTaxCodes(props);
    this.zonesById = arrayToObject(props.zones);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.services !== this.props.services) {
      this.setState({
        services: filter(this.props.services, (service) => !service.carrier),
      });
    }
  }

  onSubmitModal = (values) => {
    if (!values.id) {
      values.id = snakeCase(values.name);
    }
    if (!values.price) {
      values.price = 0;
    }
  };

  renderServiceHeading = () => {
    return [
      <th key="1">Service</th>,
      <th key="3">Price &amp; Rules</th>,
      this.hasTaxCodes && <th key="tax">Tax code</th>,
      <th key="4">Status</th>,
    ];
  };

  renderServiceValue = ({ value }) => {
    const { currency } = this.context.client;
    const weightUnit = this.props.settings.shipments.weight_unit;
    return [
      <td key="1">
        <div>
          {value.name}
          <div className="muted">
            {value.zones && value.zones.length > 0 ? (
              value.zones.map(
                (zoneId) =>
                  this.zonesById[zoneId] && (
                    <div key={zoneId}>{this.zonesById[zoneId].name}</div>
                  ),
              )
            ) : (
              <span>All zones</span>
            )}
          </div>
        </div>
      </td>,
      <td key="3">
        <div>
          {value.price > 0 ? (
            <div>
              {currencyValue(value.price, currency, { isSymbol: true })}
              {renderMultiCurrencyValuesTooltip(
                value.$currency,
                'price',
                currency,
              )}{' '}
              per {value.price_type}
            </div>
          ) : (
            <div>Free</div>
          )}
          {value.package_weight > 0 && (
            <div>
              {value.package_weight} {weightUnit} maximum per package
            </div>
          )}
          {value.rules &&
            value.rules.length > 0 &&
            value.rules.map((rule, i) => (
              <div key={i}>{this.renderServiceRuleDescription(rule)}</div>
            ))}
        </div>
      </td>,
      this.hasTaxCodes && (
        <td key="tax">
          <div>{value.tax_code || <span className="muted">&mdash;</span>}</div>
        </td>
      ),
      <td key="4">
        <div>
          {value.enabled ? 'Enabled' : <span className="muted">Disabled</span>}
        </div>
      </td>,
    ];
  };

  renderServiceRuleEffect(rule, hasConditions = false) {
    const { currency } = this.context.client;
    const weightUnit = this.props.settings.shipments.weight_unit;

    if (isPrice(rule.price)) {
      return (
        <div>
          Set price to {currencyValue(rule.price, currency, { isSymbol: true })}
          {renderMultiCurrencyValuesTooltip(
            rule.$currency,
            'price',
            currency,
          )}{' '}
          {hasConditions && 'when'}
        </div>
      );
    } else if (rule.fee_type) {
      return (
        <div>
          Add{' '}
          {rule.fee_type === 'percent'
            ? `${rule.fee_percent}%`
            : currencyValue(rule.fee_amount, currency, { isSymbol: true })}
          {rule.fee_type !== 'percent' &&
            renderMultiCurrencyValuesTooltip(
              rule.$currency,
              'fee_amount',
              currency,
            )}{' '}
          {hasConditions && 'when'}
        </div>
      );
    } else if (rule.package_weight) {
      return (
        <div>
          Set max. package weight to {rule.package_weight} {weightUnit}{' '}
          {hasConditions && 'when'}
        </div>
      );
    } else if (rule.exclude) {
      return <div>Exclude {hasConditions && 'when'}</div>;
    }
  }

  renderServiceRuleConditions(rule) {
    const { settings } = this.props;
    const { currency } = this.context.client;
    const weightUnit = settings.shipments.weight_unit;

    const conditions = [];

    // Conditions
    if (rule.total_min) {
      conditions.push(
        <div>
          Order total greater than{' '}
          {currencyValue(rule.total_min, currency, { isSymbol: true })}
          {renderMultiCurrencyValuesTooltip(
            rule.$currency,
            'total_min',
            currency,
          )}
        </div>,
      );
    }
    if (rule.total_max) {
      conditions.push(
        <div>
          Order total less than{' '}
          {currencyValue(rule.total_max, currency, { isSymbol: true })}
          {renderMultiCurrencyValuesTooltip(
            rule.$currency,
            'total_max',
            currency,
          )}
        </div>,
      );
    }
    if (rule.weight_min) {
      conditions.push(
        <div>
          Weight total greater than {rule.weight_min} {weightUnit}
        </div>,
      );
    }
    if (rule.weight_max) {
      conditions.push(
        <div>
          Weight total less than {rule.weight_max} {weightUnit}
        </div>,
      );
    }
    if (rule.state) {
      conditions.push(<div>Shipping state is {rule.state}</div>);
    }
    if (rule.zip) {
      conditions.push(<div>Shipping zip/postal code is {rule.zip}</div>);
    }
    if (rule.country) {
      conditions.push(
        <div>Shipping country is {countryName(rule.country)}</div>,
      );
    }
    if (rule.account_group) {
      const accountGroup = find(settings.accounts.groups, {
        id: rule.account_group,
      });
      conditions.push(
        <div>
          Customer group is{' '}
          {accountGroup ? accountGroup.name : rule.account_group}
        </div>,
      );
    }

    return conditions;
  }

  renderServiceRuleDescription(rule) {
    const conditions = this.renderServiceRuleConditions(rule);
    const effect = this.renderServiceRuleEffect(rule, conditions.length > 0);

    if (!effect) {
      return null;
    }

    return (
      <div>
        {effect}
        {conditions.length > 0 && (
          <ul>
            {conditions.map((cond, index) => (
              <li key={index}>{cond}</li>
            ))}
          </ul>
        )}
      </div>
    );
  }

  onServiceRuleFormSubmitModal = (values) => {
    // reset fields that won't be used with current values.type
    if (values.type === 'price') {
      values.price = values.price || 0;
      values.fee_amount = null;
      values.fee_percent = null;
      values.fee_type = null;
    } else {
      values.price = null;
    }
  };

  renderServiceForm = (values, index) => {
    const { currency } = this.context.client;
    const weightUnit = this.props.settings.shipments.weight_unit;

    const record = this.state.services[index];

    return (
      <div>
        <Field name="id" type="hidden" defaultValue={values.id} />

        <div className="row">
          <FieldLocalized
            type="text"
            name="name"
            label="Service name"
            defaultValue={values.name || ''}
            localeValue={values.$locale}
            required={true}
            className={this.hasTaxCodes ? 'span3' : 'span4'}
          />

          {this.hasTaxCodes && (
            <Field
              type="string"
              label="Tax code"
              name="tax_code"
              defaultValue={values.tax_code}
              className="span1 shipping-services-taxcode-field"
              placeholder="N/A"
              help="Enter the Avalara Avatax code for this shipping service."
              hint={
                <Fragment>
                  Find shipping tax codes{' '}
                  <a
                    href={AVATAX_SHIPPING_TAX_CODES_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    here
                  </a>
                </Fragment>
              }
            />
          )}
        </div>

        <Field
          type="toggle"
          name="enabled"
          label="Enabled"
          defaultChecked={values.enabled !== undefined ? values.enabled : true}
        />

        <FieldLocalized
          type="textarea"
          name="description"
          label="Service description"
          defaultValue={values.description || ''}
          localeValue={values.$locale}
          rows="2"
          placeholder="Optional"
        />

        <div className="row">
          <FieldLocalized
            type="currency"
            name="price"
            label="Price"
            defaultValue={values.price}
            localeValue={values.$currency}
            currency={currency}
            className="span2"
          />

          <Field
            type="number"
            name="package_weight"
            label={`Max. package weight (${weightUnit})`}
            defaultValue={values.package_weight}
            placeholder="Optional"
            className="span2"
            help="This service will be available when total shipping weight is below this amount"
          />
        </div>

        <Field
          type="radio"
          name="price_type"
          buttons={true}
          options={[
            { value: 'order', label: 'Per order' },
            { value: 'package', label: 'Per package' },
          ]}
          defaultValue={
            values.price_type !== undefined ? values.price_type : 'order'
          }
          required={true}
        />

        {this.props.zones && (
          <Fragment>
            <br />
            <Field
              type="checkbox"
              name="zones"
              label="Shipping zones"
              options={map(this.props.zones, (zone) => zone)}
              defaultValue={values.zones}
              help="This service will be available in all shipping zones by default"
              stacked={true}
            />
          </Fragment>
        )}

        <br />

        <div className="view-body-subheader">
          <h3 className="view-body-subtitle">Rules</h3>

          <p>Optional rules to apply when this shipping service is available</p>
        </div>

        <Fieldtable
          label={`${values.name ? values.name : ''} Rule`}
          name="rules"
          defaultValue={values.rules}
          renderHeading={this.renderRuleHeading}
          renderValue={this.renderRuleValue}
          renderForm={this.renderRuleForm}
          onSubmitModal={this.onServiceRuleFormSubmitModal}
          localized={true}
          formWidth={600}
        />

        <Fieldgroup label="Advanced options" defaultActive={record?.pickup}>
          <Field
            type="checkbox"
            name="pickup"
            label="Customer will pick up items"
            help="When enabled, customer will not be required to enter a shipping address"
            defaultChecked={record?.pickup}
          />
        </Fieldgroup>
      </div>
    );
  };

  renderRuleHeading = () => {
    return [<th key="1">Effect</th>, <th key="2">Conditions</th>];
  };

  renderRuleValue = ({ value }) => {
    return [
      <td key="1">{this.renderServiceRuleEffect(value)}</td>,
      <td key="2">
        {this.renderServiceRuleConditions(value).map((cond, index) => (
          <div key={index}>{cond}</div>
        ))}
      </td>,
    ];
  };

  renderRuleForm = (values) => {
    const { settings } = this.props;
    const { currency } = this.context.client;
    const weightUnit = settings.shipments.weight_unit;

    const valueType =
      values.type || (values.fee_type !== undefined ? 'fee' : 'price');

    return (
      <div>
        <Field
          type="radio"
          name="type"
          buttons={true}
          defaultValue={valueType}
          readonly={true}
          options={[
            { value: 'price', label: 'Override price' },
            { value: 'fee', label: 'Add a fee' },
          ]}
        />

        <div className="row">
          {valueType === 'price' && (
            <FieldLocalized
              type="currency"
              name="price"
              label="Set price"
              defaultValue={values.price}
              localeValue={values.$currency}
              currency={currency}
              className="span2"
              required={true}
            />
          )}

          {valueType === 'fee' && (
            <FieldAmount
              label="Fee amount"
              typeName="fee_type"
              fixedName="fee_amount"
              fixedValue="fixed"
              percentName="fee_percent"
              percentValue="percent"
              defaultValueType={values.fee_type}
              defaultValueFixed={values.fee_amount}
              defaultValuePercent={values.fee_percent}
              localized={true}
              localeValue={values.$currency}
              currency={currency}
              className="span2"
              required={true}
            />
          )}

          <Field
            type="number"
            name="package_quantity"
            label="Set max. package quantity"
            defaultValue={values.package_quantity}
            placeholder="Optional"
            className="span2"
            help="We'll count the number of packages based on this product quantity per box"
          />
        </div>

        <br />

        <div className="view-body-subheader">
          <h3 className="view-body-subtitle">Conditions</h3>

          <p>Optional criteria that must be met in order to apply this rule</p>
        </div>

        <div className="row">
          <Field
            type="select"
            name="country"
            label="Country"
            defaultValue={values.country || ''}
            options={[{ value: null, label: ' ' }, ...countryOptions]}
            className="span2"
          />

          <Field
            type={stateOptions[values.country] ? 'select' : 'text'}
            name="state"
            label={countryStateLabel(values.country)}
            defaultValue={values.state}
            options={[
              { value: null, label: ' ' },
              ...(stateOptions[values.country] || []),
            ]}
            className="span2"
          />
        </div>

        <div className="row">
          <Field
            type="text"
            name="zip"
            label="Postal/zip code"
            defaultValue={values.zip}
            className="span2"
          />

          <Field
            type="select"
            name="account_group"
            label="Customer group"
            defaultValue={values.account_group || ''}
            options={settings.accounts.groups}
            clearIcon
            className="span2"
          />
        </div>

        <div className="row">
          <FieldLocalized
            type="currency"
            name="total_min"
            label="Order total greater than"
            defaultValue={values.total_min}
            localeValue={values.$currency}
            currency={currency}
            className="span2"
          />

          <FieldLocalized
            type="currency"
            name="total_max"
            label="Order total less than"
            defaultValue={values.total_max}
            localeValue={values.$currency}
            currency={currency}
            className="span2"
          />
        </div>

        <div className="row">
          <Field
            type="number"
            name="weight_min"
            label={`Weight greater than (${weightUnit})`}
            defaultValue={values.weight_min}
            className="span2"
          />

          <Field
            type="number"
            name="weight_max"
            label={`Weight less than (${weightUnit})`}
            defaultValue={values.weight_max}
            className="span2"
          />
        </div>
      </div>
    );
  };

  render() {
    const { onSubmit } = this.props;

    return (
      <Fieldtable
        label="Shipping service"
        name="services"
        defaultValue={this.state.services}
        onSubmitModal={this.onSubmitModal}
        renderHeading={this.renderServiceHeading}
        renderValue={this.renderServiceValue}
        renderForm={this.renderServiceForm}
        onSubmit={onSubmit}
        formWidth={650}
        sortable={true}
        localized={true}
      />
    );
  }
}
