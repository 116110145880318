import React, { Fragment } from 'react';
import { get, find } from 'lodash';

import Link from 'components/link';
import Tooltip from 'components/tooltip';
import Icon from 'components/icon';
import Help from 'components/tooltip/help';
import ButtonLink from 'components/button/link';
import IconPayment from 'components/icon/payment';
import SubscriptionStatus from 'components/pages/subscription/status';
import { formatCurrencyRounded, formatDate, inflect } from 'utils';
import { getShippableItems, calculateBundleItemValues } from 'utils/order';
import { subscriptionTitle, subscriptionPriceDesc } from 'utils/subscription';
import { renderContentTooltip } from 'utils/content';
import SectionHeader from 'components/section-header';

export default class OrderFulfillment extends React.PureComponent {
  getNumItemsPendingFulfillment(delivery, items) {
    return items.reduce(
      (acc, item) =>
        acc +
        (item.delivery === delivery ||
        get(item, 'purchase_option.type') === delivery
          ? item.quantity_deliverable
          : 0),
      0,
    );
  }

  getNumItemsQuantity(items, field = 'quantity') {
    return items.reduce((acc, item) => acc + item[field] || 0, 0);
  }

  getItemsByType(type, items, itemsDelivered) {
    return items.reduce(
      (acc, item) => [
        ...acc,
        ...(item.delivery === type || get(item, 'purchase_option.type') === type
          ? [
              {
                ...item,
                [`${type}_delivered`]: find(itemsDelivered.results, {
                  order_item_id: item.id,
                }),
              },
            ]
          : []),
        ...(item.bundle_items
          ? this.getItemsByType(type, item.bundle_items, itemsDelivered).map(
              (bundleItem) => ({
                ...bundleItem,
                bundleItem: true,
              }),
            )
          : []),
      ],
      [],
    );
  }

  renderReturnCreditTotal(retrn) {
    const {
      record: { items, currency, item_tax_included },
    } = this.props;
    let priceTotal = 0;
    let total =
      (retrn.shipment_total || 0) +
      (retrn.shipment_tax || 0) +
      (retrn.extra_credit || 0) -
      (retrn.restock_fee || 0) +
      (retrn.items || []).reduce((acc, item) => {
        const orderItem = find(items, { id: item.order_item_id });
        let itemPrice = acc;
        if (item.bundle_item_id) {
          const bundleItemValues =
            calculateBundleItemValues(orderItem)[item.bundle_item_id];
          itemPrice +=
            (bundleItemValues.price -
              bundleItemValues.discount +
              (item_tax_included ? 0 : bundleItemValues.tax)) *
            item.quantity;
        } else {
          itemPrice +=
            get(orderItem, 'return_price_total', 0) -
            get(orderItem, 'return_discount_total', 0) +
            (item_tax_included ? 0 : get(orderItem, 'return_tax_total', 0));
        }
        priceTotal += itemPrice;
        return itemPrice;
      }, 0);

    return (
      <Fragment>
        {formatCurrencyRounded(total, currency)}

        {(retrn.credit_total > 0 || retrn.restock_fee > 0) && (
          <Fragment>
            &nbsp;
            <Help
              message={`${formatCurrencyRounded(priceTotal, currency)}${
                retrn.shipment_total > 0
                  ? ` + ${formatCurrencyRounded(
                      retrn.shipment_total + (retrn.shipment_tax || 0),
                      currency,
                    )} (shipping)`
                  : ''
              }${
                retrn.extra_credit > 0
                  ? ` + ${formatCurrencyRounded(
                      retrn.extra_credit,
                      currency,
                    )} (extra)`
                  : ''
              }${
                retrn.restock_fee > 0
                  ? ` - ${formatCurrencyRounded(
                      retrn.restock_fee,
                      currency,
                    )} (fee)`
                  : ''
              }`}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      record: { shipments, returns, items, delivery_marked },
      content,
      giftcardsDelivered = {},
      subscriptionsDelivered = {},
      onClickFulfillmentAdd,
      onClickFulfillmentEdit,
      onClickFulfillmentGiftcard,
      hasReturnableItems,
      onClickReturnAdd,
      onClickReturnEdit,
    } = this.props;

    const shippableItems = getShippableItems(items);

    const numItemsPendingShipment = this.getNumItemsPendingFulfillment(
      'shipment',
      shippableItems,
    );

    const giftcards = giftcardsDelivered.results || [];
    const giftcardItems = this.getItemsByType(
      'giftcard',
      items,
      giftcardsDelivered,
    );
    const numItemsPendingGiftcard = this.getNumItemsPendingFulfillment(
      'giftcard',
      giftcardItems,
    );
    const numItemsPendingGiftcardSendable = this.getNumItemsPendingFulfillment(
      'giftcard',
      giftcardItems.filter((item) => !item.bundleItem),
    );

    const subscriptions = subscriptionsDelivered.results || [];
    const subscriptionItems = this.getItemsByType(
      'subscription',
      items,
      subscriptionsDelivered,
    );
    const numItemsPendingSubscription = this.getNumItemsPendingFulfillment(
      'subscription',
      subscriptionItems,
    );

    const numItemsPendingFulfillment =
      numItemsPendingShipment +
      numItemsPendingGiftcard +
      numItemsPendingSubscription;

    const hasAddShipment =
      !delivery_marked &&
      shippableItems.some((item) => item.quantity_deliverable > 0);

    const hasPendingGiftcardSendable = numItemsPendingGiftcardSendable > 0;

    const hasActions =
      hasAddShipment || hasPendingGiftcardSendable || hasReturnableItems;

    return (
      <div className="view-body-section">
        <div className="view-body-subheader">
          <SectionHeader className="view-body-subtitle" title="Fulfillment" />
        </div>
        <div className="view-section-content collection-full">
          {subscriptions.length > 0 && (
            <div className="collection-table-container">
              <table className="collection-table">
                <thead>
                  <tr>
                    <th>Subscription</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th width="150">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptions
                    .map((sub) => {
                      return (
                        <tr key={sub.id}>
                          <td>
                            <Link to={`/subscriptions/${sub.id}`}>
                              {subscriptionTitle(sub)}
                            </Link>
                          </td>
                          <td>{subscriptionPriceDesc(sub)}</td>
                          <td>
                            <SubscriptionStatus record={sub} />
                          </td>
                          <td>{formatDate(sub.date_created, 'age')}</td>
                        </tr>
                      );
                    })
                    .reverse()}
                </tbody>
              </table>
            </div>
          )}
          {giftcards.length > 0 && (
            <div className="collection-table-container">
              <table className="collection-table">
                <thead>
                  <tr>
                    <th>Gift card</th>
                    <th>Value</th>
                    <th>Sent to</th>
                    <th width="150">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {giftcards
                    .map((gc) => {
                      return (
                        <tr key={gc.id}>
                          <td>
                            <IconPayment method="giftcard" />
                            <Link
                              to={`/giftcards/${gc.id}`}
                              className="monospaced"
                            >
                              {gc.code_formatted}
                            </Link>
                          </td>
                          <td>
                            {formatCurrencyRounded(gc.amount, gc.currency)}
                          </td>
                          <td>
                            {gc.send_email || (
                              <span className="muted">&mdash;</span>
                            )}
                          </td>
                          <td>{formatDate(gc.date_created, 'age')}</td>
                        </tr>
                      );
                    })
                    .reverse()}
                </tbody>
              </table>
            </div>
          )}
          {shipments.results.length > 0 && (
            <div className="collection-table-container">
              <table className="collection-table">
                <thead>
                  <tr>
                    <th width="95">Shipment</th>
                    <th width="75"># Items</th>
                    <th>Tracking</th>
                    <th width="75">Date</th>
                    <th width="50">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {shipments.results
                    .map((shipment) => {
                      return (
                        <tr key={shipment.id}>
                          <td>
                            {shipment.number}
                            {shipment.notes && (
                              <Tooltip
                                message={shipment.notes}
                                className="muted"
                              >
                                &nbsp;&nbsp;&nbsp;
                                <Icon fa="comment-alt-lines" />
                              </Tooltip>
                            )}
                            {renderContentTooltip(
                              shipment,
                              content.models,
                              'shipments',
                              'order',
                            )}
                          </td>
                          <td>
                            {inflect(
                              this.getNumItemsQuantity(shipment.items),
                              'item',
                            )}
                          </td>
                          <td>
                            {shipment.tracking_code ? (
                              <span>
                                {shipment.carrier_name} {shipment.tracking_code}
                              </span>
                            ) : (
                              <span className="muted">
                                {shipment.carrier_name
                                  ? shipment.carrier_name
                                  : 'None'}
                              </span>
                            )}
                            {shipment.service_name && (
                              <span className="muted">
                                &nbsp;&nbsp;(
                                {shipment.carrier_name
                                  ? shipment.service_name
                                      .replace(shipment.carrier_name, '')
                                      .trim()
                                  : shipment.service_name}
                                )
                              </span>
                            )}
                          </td>
                          <td>{formatDate(shipment.date_created, 'age')}</td>
                          <td className="action">
                            <Link
                              to={`/console?get/shipments/${shipment.id}`}
                              onClick={onClickFulfillmentEdit}
                              data-id={shipment.id}
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                    .reverse()}
                </tbody>
              </table>
            </div>
          )}
          {returns.results.length > 0 && (
            <div className="collection-table-container">
              <table className="collection-table">
                <thead>
                  <tr>
                    <th width="95">Return</th>
                    <th width="75"># Items</th>
                    <th># Restocked</th>
                    <th>Credit value</th>
                    <th width="75">Date</th>
                    <th width="50">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {returns.results
                    .map((retrn) => {
                      return (
                        <tr key={retrn.id}>
                          <td>
                            {retrn.number}
                            {retrn.notes && (
                              <Tooltip message={retrn.notes} className="muted">
                                &nbsp;&nbsp;&nbsp;
                                <Icon fa="comment-alt-lines" />
                              </Tooltip>
                            )}
                            {renderContentTooltip(
                              retrn,
                              content.models,
                              'returns',
                              'order',
                            )}
                          </td>
                          {(() => {
                            const numItems = this.getNumItemsQuantity(
                              retrn.items,
                            );
                            const numItemsRestocked = this.getNumItemsQuantity(
                              retrn.items,
                              'quantity_restocked',
                            );
                            return (
                              <Fragment>
                                <td>{inflect(numItems, 'item')}</td>
                                <td>
                                  {numItems === numItemsRestocked
                                    ? 'All items'
                                    : inflect(numItemsRestocked, 'item')}
                                </td>
                              </Fragment>
                            );
                          })()}
                          <td>{this.renderReturnCreditTotal(retrn)}</td>
                          <td>{formatDate(retrn.date_created, 'age')}</td>
                          <td className="action">
                            <Link
                              to={`/console?get/returns/${retrn.id}`}
                              onClick={onClickReturnEdit}
                              data-id={retrn.id}
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                    .reverse()}
                </tbody>
              </table>
            </div>
          )}
          {numItemsPendingFulfillment > 0 ? (
            <div className="view-section-content">
              {delivery_marked ? (
                <span>This order was manually marked as fulfilled</span>
              ) : (
                <Fragment>
                  {numItemsPendingShipment > 0 && (
                    <div>
                      {inflect(numItemsPendingShipment, 'item')} pending
                      shipment
                    </div>
                  )}
                  {numItemsPendingGiftcard > 0 && (
                    <div>
                      {inflect(numItemsPendingGiftcard, 'gift card')} pending
                      fulfillment
                    </div>
                  )}
                  {numItemsPendingSubscription > 0 && (
                    <div>
                      {inflect(numItemsPendingSubscription, 'subscription')}{' '}
                      pending fulfillment
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          ) : (
            shipments.results.length === 0 &&
            giftcards.length === 0 &&
            subscriptions.length === 0 && (
              <div className="view-section-content">
                <p>There are no items to fulfill</p>
              </div>
            )
          )}

          {hasActions && (
            <div className="view-section-footer">
              <div className="view-section-action-group">
                {hasReturnableItems && (
                  <ButtonLink
                    type="secondary"
                    size="sm"
                    onClick={onClickReturnAdd}
                  >
                    Return items
                  </ButtonLink>
                )}
                {hasAddShipment && (
                  <ButtonLink
                    type="secondary"
                    size="sm"
                    onClick={onClickFulfillmentAdd}
                  >
                    Add shipment
                  </ButtonLink>
                )}
                {hasPendingGiftcardSendable && (
                  <ButtonLink
                    type="default"
                    size="sm"
                    onClick={onClickFulfillmentGiftcard}
                  >
                    Send gift card
                  </ButtonLink>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
