import PropTypes from 'prop-types';
import React from 'react';
import { find } from 'lodash';
import OrderReturnAdd from './return-add';

export default class OrderReturnEdit extends React.PureComponent {
  static propTypes = {
    record: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    returnId: PropTypes.string.isRequired,
    onClickReturnEdit: PropTypes.func.isRequired,
    onSubmitReturnEdit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onSubmitForm(values) {
    this.props.onSubmitReturnEdit(values);
  }

  getReturnForEdit() {
    const { returnId, record } = this.props;
    const returnRecord = find(record.returns.results, { id: returnId }) || {};

    return returnRecord;
  }

  render() {
    const {
      record,
      settings,
      loading,
      onClickReturnEdit,
      onClickReturnDelete,
    } = this.props;

    const returnRecord = this.getReturnForEdit();

    return (
      <OrderReturnAdd
        {...this.props}
        record={record}
        settings={settings}
        loading={loading}
        returnRecord={returnRecord}
        onClickReturnAdd={onClickReturnEdit}
        onClickReturnDelete={onClickReturnDelete}
        onSubmitReturnAdd={this.onSubmitForm}
      />
    );
  }
}
