import React, { Fragment } from 'react';
import { map } from 'lodash';
import { Field, FieldLocalized, Fieldtable } from 'components/form';
import IconPayment from 'components/icon/payment';
import { arrayToObject, snakeCase } from 'utils';
import { truncatedText } from 'utils/collection';

export default class PaymentsManual extends React.Component {
  defaultMethods = {
    bank_deposit: 'Bank deposit',
    cash_on_delivery: 'Cash on delivery (COD)',
    money_order: 'Money order',
  };

  constructor(props) {
    super(props);
    this.renderManualHeading = this.renderManualHeading.bind(this);
    this.renderManualValue = this.renderManualValue.bind(this);
    this.renderManualForm = this.renderManualForm.bind(this);
    this.onSubmitModal = this.onSubmitModal.bind(this);
  }

  onSubmitModal(values) {
    if (!values.id) {
      if (values.suggested && values.suggested !== 'custom') {
        values.id = values.suggested;
      } else {
        values.id = snakeCase(values.name);
      }
    }
    if (!values.name && values.suggested && values.suggested !== 'custom') {
      values.name = this.defaultMethods[values.suggested];
    }
  }

  renderManualHeading = () => {
    return [
      <th key="1">Name</th>,
      <th key="2">ID</th>,
      <th key="3">Description</th>,
      <th key="4">Instructions</th>,
    ];
  };

  renderManualValue({ value }) {
    return [
      <td key="1" width="20%">
        <IconPayment method={value.id} settings={{ methods: [value] }} />
        <span className="nowrap">{value.name}</span>
      </td>,
      <td key="2">
        <span className="monospaced muted">{value.id}</span>
      </td>,
      <td key="3">
        <span className="muted">
          {value.description ? (
            truncatedText(value.description, 50)
          ) : (
            <span>&mdash;</span>
          )}
        </span>
      </td>,
      <td key="4">
        <span className="muted">
          {value.instructions ? (
            truncatedText(value.instructions, 50)
          ) : (
            <span>&mdash;</span>
          )}
        </span>
      </td>,
    ];
  }

  renderManualForm(values, index, allValues, isNew) {
    const methodsById = arrayToObject(this.props.values.manualMethods);
    const methodOptions = map(this.defaultMethods, (method, id) =>
      !methodsById[id] ? { value: id, label: method } : null,
    ).filter((method) => !!method);

    return (
      <Fragment>
        <Field type="hidden" name="id" value={values.id} />
        <Field type="hidden" name="enabled" value={true} />
        <Field type="hidden" name="manual" value={true} />
        {isNew && methodOptions.length > 0 && (
          <Field
            type="select"
            name="suggested"
            placeholder="Choose a method"
            options={[...methodOptions, { value: 'custom', label: 'Custom' }]}
            required={true}
            readonly={true}
          />
        )}
        {!isNew || values.suggested || methodOptions.length === 0 ? (
          <div className="row">
            <FieldLocalized
              type="text"
              name="name"
              label="Payment method name"
              defaultValue={values.suggested === 'custom' ? '' : values.name}
              localeValue={values.$locale}
              placeholder={
                values.suggested && this.defaultMethods[values.suggested]
              }
              className="span2"
              required={
                values.suggested && values.suggested !== 'custom' ? false : true
              }
              autoFocus={true}
            />
            <Field
              type="text"
              label="ID"
              name="id"
              defaultValue={snakeCase(values.id)}
              placeholder={
                values.suggested && values.suggested !== 'custom'
                  ? values.suggested
                  : snakeCase(values.name)
              }
              required={!values.name}
              locked={!isNew}
              className="span2"
              help="Used by apps to identify this payment method"
            />
          </div>
        ) : (
          <span>
            <Field
              type="hidden"
              name="name"
              value={this.defaultMethods[values.suggested]}
            />
          </span>
        )}
        <div className="row">
          <FieldLocalized
            type="textarea"
            name="description"
            label="Short description"
            defaultValue={values.description}
            localeValue={values.$locale}
            rows={3}
            help="Displayed on the payment page of checkout"
            className="span4"
          />
        </div>
        <div className="row">
          <FieldLocalized
            type="textarea"
            name="instructions"
            label="Payment instructions"
            defaultValue={values.instructions}
            localeValue={values.$locale}
            rows={6}
            help="Displayed on the payment page of checkout and email confirmation post-purchase"
            className="span4"
          />
        </div>
        <div className="row">
          <Field
            type="select"
            name="icon"
            label="Icon"
            placeholder="Optional"
            options={[
              { value: 'bank', label: 'Bank' },
              { value: 'card', label: 'Card' },
              { value: 'cash', label: 'Cash' },
              { value: 'check', label: 'Check' },
              { value: 'gift', label: 'Gift' },
            ]}
            renderIcon={(id) =>
              id && <i className={`icon icon-payment manual-${id}`} />
            }
            iconWidth={63}
            defaultValue={values.icon}
            className="span2"
            help="Used in the Swell dashboard to represent this payment method"
          />
        </div>
      </Fragment>
    );
  }

  render() {
    const { values } = this.props;

    return (
      <Fieldtable
        label="Manual payment method"
        name="manualMethods"
        formWidth={650}
        onSubmit={this.props.onSubmit}
        onSubmitModal={this.onSubmitModal}
        defaultValue={values.manualMethods}
        renderHeading={this.renderManualHeading}
        renderValue={this.renderManualValue}
        renderForm={this.renderManualForm}
        sortable={true}
        localized={true}
      />
    );
  }
}
