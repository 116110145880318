import csv from './formats/csv';
import json from './formats/json';

const Importer = {
  csv,
  json,
}

const dataImporter = (format = 'csv') => Importer[format]; // CSV as a default importer

export default dataImporter;
