export const STOREFRONT_HOSTING_PROVIDERS = [
  { id: 'vercel', name: 'Vercel', description: '' },
  { id: 'netlify', name: 'Netlify', description: '' },
  { id: 'heroku', name: 'Heroku', description: '' },
  { id: 'cloudflare', name: 'Cloudflare', description: '' },
  { id: 'other', name: 'Other', description: '' },
];

export const STOREFRONT_LANGUAGES_FRAMEWORKS = [
  { id: 'nodejs', name: 'Node.js', description: '' },
  {
    id: 'ruby',
    name: 'Ruby',
    description: '',
  },
  { id: 'react', name: 'React', description: '' },
  { id: 'vuejs', name: 'Vue.js', description: '' },
  { id: 'angular', name: 'Angular', description: '' },
  { id: 'nextjs', name: 'Next.js', description: '' },
  { id: 'nuxtjs', name: 'NuxtJS', description: '' },
  { id: 'tailwindcss', name: 'Tailwind CSS', description: '' },
  { id: 'svelte', name: 'Svelte', description: '' },
  { id: 'graphql', name: 'GraphQL', description: '' },
  { id: 'java', name: 'Java', description: '' },
  { id: 'php', name: 'PHP', description: '' },
  { id: 'other', name: 'Other', description: '' },
];