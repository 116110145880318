/**
 * Returns account name
 *
 * @param {object} account
 * @param {string} fallback
 * @returns {string}
 */
export function accountName(account, fallback = undefined) {
  if (account) {
    if (account.name) {
      return account.name;
    }
    if (account.billing && account.billing.name) {
      return account.billing.name;
    }
    if (account.shipping && account.shipping.name) {
      return account.shipping.name;
    }
    if (account.email) {
      return account.email;
    }
  }
  return fallback || '(Unknown account)';
}

/**
 * Prepares address string
 *
 * @param {object} suggestion
 * @returns {string}
 */
export function buildAddress(suggestion) {
  return `${suggestion.street_line} ${suggestion.secondary} ${suggestion.city} ${suggestion.state} ${suggestion.zipcode}`;
}

/**
 * Returns account default card id
 *
 * @param {object} account
 * @returns {string}
 */
export function getDefaultCardId(account) {
  return account?.billing?.account_card_id ?? '';
}

/**
 * Returns account default address id
 *
 * @param {object} account
 * @returns {string}
 */
export function getDefaultAddressId(account) {
  return account?.shipping?.account_address_id ?? '';
}

/**
 * Prepares query to fetch account addresses
 *
 * @param {string} accountId
 * @param {object | boolean} active
 * @returns {object}
 */
export function getAccountAddressesQuery(accountId, active = true) {
  return {
    url: '/accounts:addresses',
    data: {
      parent_id: accountId,
      active,
      limit: 1000,
    },
  };
}

/**
 * Prepares query to fetch account cards
 *
 * @param {string} accountId
 * @param {object | boolean} active
 * @returns {object}
 */
export function getAccountCardsQuery(accountId, active = true) {
  return {
    url: '/accounts:cards',
    data: {
      parent_id: accountId,
      active,
      limit: 1000,
    },
  };
}
