import React from 'react';
import classNames from 'classnames';
import pt from 'prop-types';

import Popover from 'components/popover';

import './tooltip.scss';

function getBalloonSize(length) {
  switch (length) {
    case 'small':
    case 'medium':
      return 175;

    case 'large':
    case 'xlarge':
    default:
      return 250;
  }
}

export default class Tooltip extends React.PureComponent {
  static propTypes = {
    dir: pt.string,
    length: pt.string,
    delayed: pt.bool,
    message: pt.oneOfType([pt.string, pt.object]),
    className: pt.string,
    maxTextLineWidth: pt.number,
    maxTextLineSeparator: pt.string,
    children: pt.node.isRequired,
  };

  state = {
    balloonDir: null,
    balloonLength: null,
  };

  /** @type {React.RefObject<HTMLElement>} */
  balloonRef = React.createRef();

  componentDidMount() {
    this.ensureModalContained();
  }

  ensureModalContained() {
    const { current: ref } = this.balloonRef;

    if (!ref) {
      return;
    }

    const { message, length } = this.props;

    if (!message) {
      return;
    }

    let balloonLength = length
      ? length
      : message.length > 50
      ? 'large'
      : undefined;

    const balloonSize = getBalloonSize(balloonLength);

    // located in editor components
    const modalBody = ref.closest('.SettingsMenu-body');

    if (modalBody !== null) {
      const {
        width: modalWidth,
        left: xPosParent,
        top: yPosParent,
      } = modalBody.getBoundingClientRect();

      const { width, left: xPos, top: yPos } = ref.getBoundingClientRect();

      const offsetTop = yPos - yPosParent;
      const offsetLeft = xPos - xPosParent;
      const offsetRight = modalWidth - (offsetLeft + width);

      let balloonDir = 'up';
      let balloonOrientation = 'up';

      if (offsetTop < 50) {
        balloonOrientation = 'down';
        balloonDir = 'down';
      }

      if (offsetLeft - balloonSize / 2 < 0) {
        balloonDir = `${balloonOrientation}-left`;

        if (offsetLeft + balloonSize > modalWidth) {
          balloonLength = 'medium';
        }
      } else if (offsetLeft + balloonSize / 2 > modalWidth) {
        balloonDir = `${balloonOrientation}-right`;

        if (offsetRight - balloonSize < 0) {
          balloonLength = 'medium';
        }
      }

      this.setState({ balloonDir: balloonDir });
    }

    this.setState({ balloonLength: balloonLength });
  }

  render() {
    const {
      message = '',
      delayed = false,
      dir,
      length,
      className,
      maxTextLineWidth,
      maxTextLineSeparator,
      children,
      ...props
    } = this.props;

    if (!message) {
      return children;
    }

    const { balloonDir, balloonLength } = this.state;

    return (
      <span className="tooltip-help" role="tooltip">
        <Popover
          align="middle"
          message={message}
          maxTextLineWidth={maxTextLineWidth}
          maxTextLineSeparator={maxTextLineSeparator}
          triggerChildren={children}
          triggerOnClick={() => {}}
          openOnHover
        />
      </span>
    );

    return message.length > 0 ? (
      <span
        {...props}
        className={classNames('tooltip', className, {
          'tooltip-delayed': delayed,
        })}
        data-balloon={message}
        data-balloon-pos={balloonDir || dir || 'up'}
        data-balloon-length={balloonLength}
        ref={this.balloonRef}
      >
        {children}
      </span>
    ) : (
      children
    );
  }
}
