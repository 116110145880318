import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import { map } from 'lodash';
import { FadeLoading } from 'components/transitions';
import { getReports } from 'constants/reports';
import HelpLink from 'components/help-link';
import { formatDate } from 'utils';
import './reports.scss';
import SectionHeader from 'components/section-header';

export default class Reports extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props);
    this.state = {
      reports: getReports(context.client.timezone),
    };
  }

  onClickDownloadCustom = (event) => {
    event.preventDefault();
    const { id } = event.currentTarget.dataset;
    this.setState({ downloading: true });
    this.props
      .downloadCustom(id)
      .then(() => this.setState({ downloading: false }));
  };

  render() {
    const { account } = this.context;
    const { custom } = this.props;
    let { reports, downloading } = this.state;

    if (!account.features.advanced_reporting) {
      reports = { sales: reports.sales };
    }

    if (custom && custom.results && custom.results.length > 0) {
      reports.custom = {
        name: 'Custom',
        description: 'View and download custom generated reports',
        types: custom.results.map((report) => ({
          name: report.label,
          type: 'custom',
          download_url: `/api/data/reports/custom/${report.id}/download`,
          description: report.description,
          date_generated:
            report.date_custom_generated ||
            (report.custom_file && report.custom_file.date_uploaded),
          record: report,
        })),
      };
    }

    return (
      <div className="reports view-container">
        <div className="view-body detail">
          {map(reports, (group, key) => (
            <div key={key} hidden={group.hidden}>
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title={`${group.name} reports`}
                  subtitle={group.description}
                />
              </div>
              <table className="collection-table headless outer">
                <tbody>
                  {group.types.map(
                    (report) =>
                      (!report.condition || report.condition(account)) && (
                        <tr key={report.url || report.name}>
                          <td>
                            {report.download_url ? (
                              <Fragment>
                                <h4 className="reports-custom-title">
                                  {report.name}
                                </h4>
                                {report.description && (
                                  <p className="reports-custom-desc">
                                    {report.description}
                                  </p>
                                )}
                                {report.record.custom_file ? (
                                  <div className="note">
                                    <a
                                      href=""
                                      onClick={this.onClickDownloadCustom}
                                      data-id={report.record.id}
                                      className="highlight"
                                    >
                                      Download{' '}
                                      {report.record.custom_file.filename || ''}
                                    </a>{' '}
                                    &bull; Generated{' '}
                                    {formatDate(report.date_generated, 'short')}
                                  </div>
                                ) : (
                                  <div className="note muted">
                                    No data generated yet
                                  </div>
                                )}
                              </Fragment>
                            ) : report.url ? (
                              <Link to={`/reports/${report.url}`}>
                                {report.name}
                              </Link>
                            ) : (
                              <b>{report.name}</b>
                            )}
                          </td>
                          {/*<td width="50%">0 {report.metric.label}</td>*/}
                        </tr>
                      ),
                  )}
                </tbody>
              </table>
            </div>
          ))}
          {!account.features.advanced_reporting && (
            <div className="box">
              <div className="box-title">Advanced reporting</div>
              <div className="box-subtitle">
                Need more data? Upgrade your account to access advanced reports
                and more.
              </div>
              <div className="box-action">
                <Link to="/settings/account/plans">Details</Link>
              </div>
            </div>
          )}
        </div>
        <FadeLoading active={downloading} className="view-loading-mask" />
        <div className="reports-help-link">
          <HelpLink />
        </div>
      </div>
    );
  }
}
