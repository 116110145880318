import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  get,
  pick,
  map,
  reduce,
  find,
  slice,
  each,
  isEmpty,
  keys,
  every,
  difference,
  merge,
  assign,
  cloneDeep,
} from 'lodash';
import moment from 'moment';
import Loading from 'components/loading';
import ConsoleLink from 'components/console/link';
import { FadeIn } from 'components/transitions';
import {
  OrderCreated,
  OrderSubmitted,
  OrderCanceled,
  OrderHoldOn,
  OrderHoldOff,
  BillingUpdated,
  PaymentSucceeded,
  PaymentFailed,
  PaymentRefundSucceeded,
  ItemsAdded,
  ItemsCanceled,
  ItemsChanged,
  CouponAdded,
  CouponRemoved,
  PromoAdded,
  PromoRemoved,
  ShipmentCreated,
  ShipmentUpdated,
  ShippingUpdated,
  ReturnCreated,
  Notification,
  NoteAdded,
  SubscriptionCreated,
  SubscriptionCanceled,
  SubscriptionPaused,
  SubscriptionResumed,
  SubscriptionPlanChanged,
  TryPeriodActivated,
  TryPeriodChanged,
  TryPeriodDeactivated,
  InvoiceCreated,
} from './events';
import './activity.scss';

export default class Activity extends PureComponent {
  static contextTypes = {
    user: PropTypes.object.isRequired,
  };

  getItemUpdate(event, prevEvents) {
    const { record } = this.props;
    const isItemAdded = (prevItems) => prevItems.length === 0;
    const isItemCanceled = (prevItems, item) =>
      !!item.quantity_canceled &&
      !!find(
        prevItems,
        (prevItem) =>
          prevItem.quantity_canceled !== undefined &&
          prevItem.quantity_canceled < item.quantity_canceled,
      );
    const isTryPeriodActivated = (prevItems, item) => item.date_trial_end;
    const isTryPeriodDeactivated = (prevItems, item) =>
      item.date_trial_end === null;
    const isTryPeriodChanged = (prevItems, item) => {
      if (!item.date_trial_end) {
        return false;
      }
      for (let i = 0; i < prevItems.length; i++) {
        const prevItem = prevItems[i];
        if (prevItem.date_trial_end === null) {
          return false;
        } else if (
          prevItem.date_trial_end &&
          prevItem.date_trial_end !== item.date_trial_end
        ) {
          return true;
        }
      }
    };
    const getItemChanges = (prevItems, item) => {
      const changes = {};
      const quantityChange =
        item.quantity !== undefined &&
        find(
          prevItems,
          (prevItem) =>
            prevItem.quantity !== undefined &&
            prevItem.quantity !== item.quantity,
        );
      const priceChange =
        item.price !== undefined &&
        find(
          prevItems,
          (prevItem) =>
            prevItem.price !== undefined && prevItem.price !== item.price,
        );
      const currencyChange =
        priceChange &&
        get(event, 'data.currency') !== undefined &&
        find(
          prevEvents,
          (prevEvent) =>
            get(prevEvent, 'data.currency') !== undefined &&
            get(prevEvent, 'data.currency') !== get(event, 'data.currency'),
        );
      if (quantityChange) {
        changes.quantity = { old: quantityChange.quantity, new: item.quantity };
      }
      if (currencyChange) {
        changes.currency = {
          old: currencyChange.data.currency,
          new: event.data.currency,
        };
      }
      if (priceChange) {
        changes.price = {
          old: priceChange.price,
          new: item.price,
        };
      }
      return changes;
    };
    const pushRecordItem = (arr, eventItem, changes) => {
      const recordItem = find(record.items, { id: eventItem.id });
      if (recordItem) {
        arr.push({ ...recordItem, changes });
      }
    };

    return reduce(
      event.data.items,
      (acc, item) => {
        if (!item) {
          return acc;
        }

        const prevItems = reduce(
          prevEvents,
          (acc, prevEvent) => {
            const prevEventItems = get(prevEvent, 'data.items');

            each(prevEventItems, (prevEventItem) => {
              if (prevEventItem && prevEventItem.id === item.id) {
                acc.push(prevEventItem);
              }
            });

            return acc;
          },
          [],
        );

        if (isItemAdded(prevItems)) {
          pushRecordItem(acc.added, item);
        } else if (isItemCanceled(prevItems, item)) {
          pushRecordItem(acc.canceled, item);
        } else if (isTryPeriodChanged(prevItems, item)) {
          pushRecordItem(acc.try.changed, item, {
            date_trial_end: item.date_trial_end,
          });
        } else if (isTryPeriodActivated(prevItems, item)) {
          const trialDays = Math.ceil(
            moment(item.date_trial_end).diff(event.date_created, 'days', true),
          );
          acc.try.activated[trialDays] = acc.try.activated[trialDays] || [];
          pushRecordItem(acc.try.activated[trialDays], item, {
            date_trial_end: item.date_trial_end,
          });
        } else if (isTryPeriodDeactivated(prevItems, item)) {
          pushRecordItem(acc.try.deactivated, item);
        } else {
          const itemChanges = getItemChanges(prevItems, item);
          !isEmpty(itemChanges) &&
            pushRecordItem(acc.changed, item, itemChanges);
        }

        return acc;
      },
      {
        added: [],
        canceled: [],
        changed: [],
        try: { activated: {}, changed: [], deactivated: [] },
      },
    );
  }

  getSubscriptionPlanUpdate(event, prevEvents) {
    const { record } = this.props;

    const planProps = [
      'product_id',
      'variant_id',
      'quantity',
      'price',
      'interval',
      'interval_count',
      'billing_schedule',
      'order_schedule',
    ];
    const isPlanChanged = (data) =>
      planProps.reduce(
        (acc, field) => (data[field] !== undefined ? true : acc),
        false,
      );

    if (!isPlanChanged(event.data)) {
      return null;
    }

    let eventItem = {
      ...event,
      ...pick(record, planProps),
      ...pick(event.data, planProps),
    };
    if (!event.product) {
      const prevFrom = find(prevEvents, (prevEvent) => prevEvent.product);
      eventItem = {
        ...eventItem,
        ...(prevFrom
          ? {
              product: prevFrom.product,
              variant: prevFrom.variant,
            }
          : {
              product: record.product,
              variant: record.variant,
            }),
      };
    }

    const findPropChange = (prop) => {
      const eventDataValues = {};
      const props = !(prop instanceof Array) ? [prop] : prop;
      let hasAny = false;
      for (const p of props) {
        const val = get(event.data, p);
        if (val !== undefined) {
          eventDataValues[p] = val;
          hasAny = true;
        }
      }
      if (!hasAny) {
        return false;
      }
      return find(prevEvents, (prevEvent) => {
        for (const ppp of props) {
          if (
            eventDataValues[ppp] !== undefined &&
            get(prevEvent, `data.${ppp}`) !== undefined &&
            get(prevEvent, `data.${ppp}`) !== eventDataValues[ppp]
          ) {
            return true;
          }
        }
      });
    };

    const changes = {};
    const productChange = findPropChange('product_id');
    const variantChange = findPropChange('variant_id');
    const quantityChange = findPropChange('quantity');
    const priceChange = findPropChange('price');
    const scheduleChange = findPropChange([
      'interval',
      'interval_count',
      'limit',
    ]);
    const currencyChange = findPropChange('currency');

    if (productChange) {
      changes.product = { old: productChange.product, new: eventItem.product };
    }
    if (variantChange) {
      changes.variant = { old: variantChange.variant, new: eventItem.variant };
    }
    if (quantityChange) {
      changes.quantity = {
        old: quantityChange.data.quantity,
        new: eventItem.quantity,
      };
    }
    if (currencyChange) {
      changes.currency = {
        old: currencyChange.data.currency,
        new: event.data.currency,
      };
    }
    if (priceChange) {
      changes.price = {
        old: get(priceChange, 'data.price', eventItem.price),
        new: get(event, 'data.price', eventItem.price),
      };
    }
    if (scheduleChange) {
      changes.schedule = {
        old_interval: get(scheduleChange, 'data.interval', eventItem.interval),
        new_interval: get(event, 'data.interval', eventItem.interval),
        old_interval_count: get(
          scheduleChange,
          'data.interval_count',
          eventItem.interval_count,
        ),
        new_interval_count: get(
          event,
          'data.interval_count',
          eventItem.interval_count,
        ),
        old_limit: get(
          scheduleChange,
          'data.billing_schedule.limit',
          get(eventItem, 'billing_schedule.limit'),
        ),
        new_limit: get(
          event,
          'data.billing_schedule.limit',
          get(eventItem, 'billing_schedule.limit'),
        ),
      };
    }

    return !isEmpty(changes) ? { ...eventItem, changes } : null;
  }

  // Discounts
  getOrderValuesUpdate(event, prevEvents) {
    const newValues = pick(event.data, [
      'sub_total',
      'shipment_total',
      'tax_included_total',
      'grand_total',
    ]);

    if (isEmpty(newValues)) {
      return null;
    }

    const updates = reduce(
      newValues,
      (acc, value, key) => {
        acc[key] = { new: value };
        return acc;
      },
      {},
    );
    const fields = keys(newValues);

    each(prevEvents, (prevEvent) => {
      const oldValues = pick(prevEvent.data, fields);
      each(fields, (field) => {
        if (
          updates[field].old === undefined &&
          oldValues[field] !== undefined
        ) {
          updates[field].old = oldValues[field];
        }
      });

      if (every(updates, (value) => value.old !== undefined)) {
        return false;
      }
    });

    return updates;
  }

  // Coupon
  isCouponAdded(event) {
    return get(event, 'data.coupon_id') && get(event, 'data.coupon_code');
  }

  isCouponRemoved(event) {
    return (
      get(event, 'data.coupon_id') === null &&
      get(event, 'data.coupon_code') === null
    );
  }

  getCouponAddedEventData(event, prevEvents) {
    return {
      name: event.data.coupon_code,
      ...this.getOrderValuesUpdate(event, prevEvents),
    };
  }

  getCouponRemovedEventData(event, prevEvents) {
    const prevCouponEvent = find(
      prevEvents,
      (prevEvent) =>
        get(prevEvent, 'data.coupon_id') && get(prevEvent, 'data.coupon_code'),
    );
    return !prevCouponEvent
      ? null
      : {
          name: prevCouponEvent.data.coupon_code,
          ...this.getOrderValuesUpdate(event, prevEvents),
        };
  }

  // Promo
  isPromoAdded(event, prevPromoEvent) {
    return (
      !prevPromoEvent ||
      !isEmpty(
        difference(event.data.promotion_ids, prevPromoEvent.data.promotion_ids),
      )
    );
  }

  isPromoRemoved(event, prevPromoEvent) {
    return (
      prevPromoEvent &&
      !isEmpty(
        difference(prevPromoEvent.data.promotion_ids, event.data.promotion_ids),
      )
    );
  }

  getPromoAddedEventData(event, prevPromoEvent, prevEvents) {
    const addedPromoIds = difference(
      event.data.promotion_ids,
      get(prevPromoEvent, 'data.promotion_ids'),
    );
    const promoNames = reduce(
      addedPromoIds,
      (acc, addedPromoId) => {
        const promotions = get(event, 'promotions');
        const promotion = find(promotions, { id: addedPromoId });
        if (promotion) {
          acc.push(promotion.name);
        }
        return acc;
      },
      [],
    );
    return isEmpty(promoNames)
      ? null
      : {
          name: promoNames.join(', '),
          ...this.getOrderValuesUpdate(event, prevEvents),
        };
  }

  getPromoRemovedEventData(event, prevPromoEvent, prevEvents) {
    const removedPromoIds = difference(
      prevPromoEvent.data.promotion_ids,
      event.data.promotion_ids,
    );
    const promoNames = reduce(
      removedPromoIds,
      (acc, removedPromoId) => {
        const promotions = get(prevPromoEvent, 'promotions');
        const promotion = find(promotions, { id: removedPromoId });
        if (promotion) {
          acc.push(promotion.name);
        }
        return acc;
      },
      [],
    );
    return isEmpty(promoNames)
      ? null
      : {
          name: promoNames.join(', '),
          ...this.getOrderValuesUpdate(event, prevEvents),
        };
  }

  getEventCurrency(event, prevEvents) {
    const eventCurrency = get(event, 'data.currency');
    if (eventCurrency) {
      return eventCurrency;
    }
    const prevCurrencyChange = find(
      prevEvents,
      (prevEvent) => get(prevEvent, 'data.currency') !== undefined,
    );
    if (prevCurrencyChange) {
      return prevCurrencyChange.data.currency;
    }
    return this.props.record.currency;
  }

  mergeSimilarEventProps(last, next) {
    // Set last 'old' changes to next if present
    if (next.changes) {
      each(last.changes, (change, key) => {
        if (next.changes[key]) {
          each(next.changes[key], (value, valueKey) => {
            if (valueKey.indexOf('old') === 0) {
              change[valueKey] = value;
            }
          });
        } else {
          assign(last.changes, next.changes);
        }
      });
    } else {
      merge(next, last);
      assign(last, next);
    }
  }

  aggregateSimilarEvents(event, index, prevEvents, props, typeName, render) {
    if (event.checkingAggregate) {
      return { event, props, typeName };
    }

    const eventTime = new Date(event.date_created).getTime();
    for (const prevEvent of prevEvents) {
      prevEvent.checkingAggregate = true;
      const prevTime = new Date(prevEvent.date_created).getTime();
      // Same events within the last 5 minutes
      if (eventTime - prevTime < 60000 * 5) {
        try {
          const prevUpdates = this.renderEvent(
            prevEvent,
            index + 1,
            prevEvents.slice(index + 1),
          );
          if (prevUpdates instanceof Array) {
            for (const update of prevUpdates) {
              if (update.typeName === typeName) {
                prevEvent.aggregated = true;
                event.aggregations = event.aggregations || [];
                event.aggregations.push(prevEvent);
                // merge props
                const propKeys = Object.keys(update.props);
                for (const key of propKeys) {
                  if (props[key] instanceof Array) {
                    if (update.props[key] instanceof Array) {
                      for (const pushProp of update.props[key]) {
                        const ex = find(props[key], {
                          id: pushProp && pushProp.id,
                        });
                        if (ex) {
                          this.mergeSimilarEventProps(ex, pushProp);
                        } else {
                          props[key].push(pushProp);
                        }
                      }
                    }
                  } else {
                    this.mergeSimilarEventProps(props[key], update.props[key]);
                  }
                }
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
      prevEvent.checkingAggregate = false;
    }

    return render();
  }

  renderEvent(event, index, events) {
    if (event.aggregated) {
      return null;
    }
    try {
      const { model } = this.props;
      const prevEvents = slice(events, index + 1).filter(
        (pev) => pev.model === model,
      );

      switch (event.type) {
        case 'subscription.created':
          return <SubscriptionCreated {...this.props} event={event} />;
        case 'subscription.canceled':
          return <SubscriptionCanceled {...this.props} event={event} />;
        case 'subscription.paused':
          return <SubscriptionPaused {...this.props} event={event} />;
        case 'subscription.resumed':
          return <SubscriptionResumed {...this.props} event={event} />;
        case 'invoice.created':
          return model ===
            'subscriptions' ? null /* <SubscriptionInvoiceCreated {...this.props} event={event} /> */ : (
            <InvoiceCreated {...this.props} event={event} />
          );
        case 'order.created':
          return <OrderCreated {...this.props} event={event} />;
        case 'order.submitted':
          return <OrderSubmitted {...this.props} event={event} />;
        case 'order.canceled':
          return <OrderCanceled {...this.props} event={event} />;
        case 'payment.succeeded':
          return <PaymentSucceeded {...this.props} event={event} />;
        case 'payment.failed':
          return <PaymentFailed {...this.props} event={event} />;
        case 'payment.refund.succeeded':
          return <PaymentRefundSucceeded {...this.props} event={event} />;
        case 'shipment.created':
          return <ShipmentCreated {...this.props} event={event} />;
        case 'shipment.updated':
          return this.aggregateSimilarEvents(
            event,
            index,
            prevEvents,
            {},
            'ShipmentUpdated',
            () => <ShipmentUpdated {...this.props} event={event} />,
          );
        case 'return.created':
          return <ReturnCreated {...this.props} event={event} />;
        case 'notification':
          return <Notification {...this.props} event={event} />;
        case 'note':
          return (
            <NoteAdded {...this.props} event={event} user={this.context.user} />
          );
        case 'order.updated':
        case 'subscription.updated':
          const updateEvents = [];
          const key = (type) => `${event.id}-${type}`;

          // subscription plan update
          if (model === 'subscriptions') {
            const planItemUpdate = this.getSubscriptionPlanUpdate(
              event,
              prevEvents,
            );
            if (!isEmpty(planItemUpdate)) {
              updateEvents.push(
                this.aggregateSimilarEvents(
                  event,
                  index,
                  prevEvents,
                  { items: planItemUpdate },
                  'SubscriptionPlanChanged',
                  () => (
                    <SubscriptionPlanChanged
                      key={key('items-changed')}
                      {...this.props}
                      event={event}
                      items={[planItemUpdate]}
                      currency={this.getEventCurrency(event, prevEvents)}
                    />
                  ),
                ),
              );
            }
          }
          // items update
          if (event.data.items) {
            const itemUpdates = this.getItemUpdate(event, prevEvents);
            !isEmpty(itemUpdates.added) &&
              updateEvents.push(
                this.aggregateSimilarEvents(
                  event,
                  index,
                  prevEvents,
                  { items: itemUpdates.added },
                  'ItemsAdded',
                  () => (
                    <ItemsAdded
                      key={key('items-added')}
                      {...this.props}
                      event={event}
                      items={itemUpdates.added}
                    />
                  ),
                ),
              );
            !isEmpty(itemUpdates.canceled) &&
              updateEvents.push(
                this.aggregateSimilarEvents(
                  event,
                  index,
                  prevEvents,
                  { items: itemUpdates.canceled },
                  'ItemsCanceled',
                  () => (
                    <ItemsCanceled
                      key={key('items-canceled')}
                      {...this.props}
                      event={event}
                      items={itemUpdates.canceled}
                    />
                  ),
                ),
              );
            !isEmpty(itemUpdates.changed) &&
              updateEvents.push(
                this.aggregateSimilarEvents(
                  event,
                  index,
                  prevEvents,
                  { items: itemUpdates.changed },
                  'ItemsChanged',
                  () => (
                    <ItemsChanged
                      key={key('items-changed')}
                      {...this.props}
                      event={event}
                      items={itemUpdates.changed}
                    />
                  ),
                ),
              );
            !isEmpty(itemUpdates.try.activated) &&
              map(itemUpdates.try.activated, (items, trialDays) =>
                updateEvents.push(
                  this.aggregateSimilarEvents(
                    event,
                    index,
                    prevEvents,
                    { items },
                    'TryPeriodActivated',
                    () => (
                      <TryPeriodActivated
                        key={key('items-try-period-activated')}
                        {...this.props}
                        event={event}
                        items={items}
                        trialDays={trialDays}
                      />
                    ),
                  ),
                ),
              );
            !isEmpty(itemUpdates.try.changed) &&
              updateEvents.push(
                <TryPeriodChanged
                  key={key('items-try-period-changed')}
                  {...this.props}
                  event={event}
                  items={itemUpdates.try.changed}
                />,
              );
            !isEmpty(itemUpdates.try.deactivated) &&
              updateEvents.push(
                this.aggregateSimilarEvents(
                  event,
                  index,
                  prevEvents,
                  { items: itemUpdates.try.deactivated },
                  'TryPeriodDeactivated',
                  () => (
                    <TryPeriodDeactivated
                      key={key('items-try-period-deactivated')}
                      {...this.props}
                      event={event}
                      items={itemUpdates.try.deactivated}
                    />
                  ),
                ),
              );
          }

          // coupon update
          if (this.isCouponAdded(event)) {
            const coupon = this.getCouponAddedEventData(event, prevEvents);
            updateEvents.push(
              this.aggregateSimilarEvents(
                event,
                index,
                prevEvents,
                { coupon },
                'CouponAdded',
                () => (
                  <CouponAdded
                    key={key('coupon-added')}
                    {...this.props}
                    event={event}
                    coupon={coupon}
                  />
                ),
              ),
            );
          } else if (this.isCouponRemoved(event)) {
            const coupon = this.getCouponRemovedEventData(event, prevEvents);
            coupon &&
              updateEvents.push(
                this.aggregateSimilarEvents(
                  event,
                  index,
                  prevEvents,
                  { coupon },
                  'CouponRemoved',
                  () => (
                    <CouponRemoved
                      key={key('coupon-removed')}
                      {...this.props}
                      event={event}
                      coupon={coupon}
                    />
                  ),
                ),
              );
          }

          // promotion update
          if (event.data.promotion_ids) {
            const prevPromoEvent = find(prevEvents, (prevEvent) =>
              get(prevEvent, 'data.promotion_ids'),
            );
            if (this.isPromoAdded(event, prevPromoEvent)) {
              const promo = this.getPromoAddedEventData(
                event,
                prevPromoEvent,
                prevEvents,
              );
              promo &&
                updateEvents.push(
                  this.aggregateSimilarEvents(
                    event,
                    index,
                    prevEvents,
                    { promo },
                    'PromoAdded',
                    () => (
                      <PromoAdded
                        key={key('promo-added')}
                        {...this.props}
                        event={event}
                        promo={promo}
                      />
                    ),
                  ),
                );
            } else if (this.isPromoRemoved(event, prevPromoEvent)) {
              const promo = this.getPromoRemovedEventData(
                event,
                prevPromoEvent,
                prevEvents,
              );
              promo &&
                updateEvents.push(
                  this.aggregateSimilarEvents(
                    event,
                    index,
                    prevEvents,
                    { promo },
                    'PromoRemoved',
                    () => (
                      <PromoRemoved
                        key={key('promo-removed')}
                        {...this.props}
                        event={event}
                        promo={promo}
                      />
                    ),
                  ),
                );
            }
          }

          // billing update
          if (event.data.billing) {
            const billing = event.data.billing;
            updateEvents.push(
              this.aggregateSimilarEvents(
                event,
                index,
                prevEvents,
                { billing },
                'BillingUpdated',
                () => (
                  <BillingUpdated
                    key={key('billing-updated')}
                    {...this.props}
                    event={event}
                    billing={billing}
                  />
                ),
              ),
            );
          }

          // shipping update
          if (event.data.shipping) {
            const shipping = event.data.shipping;
            updateEvents.push(
              this.aggregateSimilarEvents(
                event,
                index,
                prevEvents,
                { shipping },
                'ShippingUpdated',
                () => (
                  <ShippingUpdated
                    key={key('shipping-updated')}
                    {...this.props}
                    event={event}
                    shipping={shipping}
                    currency={this.getEventCurrency(event, prevEvents)}
                  />
                ),
              ),
            );
          }

          // order hold on
          if (event.data.hold) {
            updateEvents.push(
              this.aggregateSimilarEvents(
                event,
                index,
                prevEvents,
                {},
                'OrderHoldOn',
                () => (
                  <OrderHoldOn
                    key={key('on-hold')}
                    {...this.props}
                    event={event}
                  />
                ),
              ),
            );
          }

          // order hold off
          if (event.data.hold === false) {
            updateEvents.push(
              this.aggregateSimilarEvents(
                event,
                index,
                prevEvents,
                {},
                'OrderHoldOff',
                () => (
                  <OrderHoldOff
                    key={key('off-hold')}
                    {...this.props}
                    event={event}
                  />
                ),
              ),
            );
          }

          return isEmpty(updateEvents) ? null : updateEvents;
        default:
          return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  render() {
    const {
      label,
      record,
      activity: { fetched, loading, results: events } = {},
      notes,
    } = this.props;
    const { user } = this.context;

    // Clone events to isolate internal mutation
    const clonedEvents = cloneDeep(events);

    return (
      <div className="activity">
        <span className="activity-label bold">
          {label || 'Recent activity'}
        </span>{' '}
        {user.devtools && record.id && (
          <ConsoleLink model="events" uri={`?data.id=${record.id}`} />
        )}
        {notes}
        {fetched ? (
          <FadeIn>
            <div
              className={`activity-events ${loading ? 'activity-loading' : ''}`}
            >
              {map(clonedEvents, (ev, i) => (
                <Fragment key={ev.id}>
                  {this.renderEvent(ev, i, clonedEvents)}
                </Fragment>
              ))}
            </div>
          </FadeIn>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}
