import React from 'react';

import View from 'components/view';
import { Form, Field } from 'components/form';
import { FadeIn } from 'components/transitions';

import './settings.scss';

const expireIntervalOptions = [
  { value: 'days', label: 'Days' },
  { value: 'months', label: 'Months' },
  { value: 'years', label: 'Years' },
];

export default class GiftcardSettings extends React.PureComponent {
  render() {
    const { values, edited, onSubmitForm, onChangeForm } = this.props;

    const featureAutoExpire = Boolean(
      values.features && values.features.auto_expire,
    );

    return (
      <div className="settings settings-giftcards">
        <Form ref="form" onSubmit={onSubmitForm} onChange={onChangeForm}>
          <View
            detail={true}
            uri="/settings"
            sectionTitle="Settings"
            headerTitle="Gift cards"
            headerSubtitle="Manage settings that affect store gift cards"
            headerActions={[
              {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
          >
            <fieldset className="full">
              <div className="row">
                <Field
                  type="text"
                  name="code_pattern"
                  label="Gift card code pattern"
                  className="span2"
                  required={true}
                  defaultValue={values.code_pattern}
                  hint="Used to generate unique codes when a gift card is fulfilled"
                />
              </div>

              <br />

              <div className="box">
                <div className="box-section">
                  <h3 className="box-title">Auto-expiration</h3>
                  <div className="box-subtitle">
                    Set gift cards to automatically expire after a certain
                    amount of time
                  </div>

                  <div className="box-action">
                    <Field
                      type="toggle"
                      name="features.auto_expire"
                      defaultChecked={featureAutoExpire}
                    />
                  </div>

                  <FadeIn active={featureAutoExpire}>
                    <div className="row" style={{ width: '50%' }}>
                      <Field
                        type="number"
                        name="expire_count"
                        label="Expire after"
                        className="span1"
                        required={featureAutoExpire}
                        defaultValue={values.expire_count}
                      />
                      <Field
                        type="select"
                        name="expire_interval"
                        label={<span>&nbsp;</span>}
                        className="span2"
                        defaultValue={values.expire_interval}
                        options={expireIntervalOptions}
                      />

                      <span className="span2" />
                    </div>
                  </FadeIn>
                </div>
              </div>
            </fieldset>
          </View>
        </Form>
      </div>
    );
  }
}
