import React from 'react';
import { connect } from 'react-redux';

import actions from 'actions';

import ViewLoading from 'components/view/loading';
import ReportsPage from 'components/reports';

export const mapStateToProps = (state) => ({
  custom: state.reports.custom,
});

export const mapDispatchToProps = (dispatch) => ({
  listCustom: () => {
    return dispatch(actions.reports.listCustom());
  },

  downloadCustom: (id) => {
    return dispatch(actions.reports.downloadCustom(id));
  },
});

export class Reports extends React.PureComponent {
  state = {
    loaded: false,
    downloading: false,
  };

  componentDidMount() {
    const { listCustom } = this.props;

    listCustom().then(() => {
      this.setState({ loaded: true });
    });
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return <ReportsPage {...this.props} {...this.state} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reports);
