import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, size, find } from 'lodash';
import Link from 'components/link';
import {
  Field,
  FieldLocalized,
  Fieldtable,
  LookupCategories,
} from 'components/form';
import { snakeCase } from 'utils';
import { countryOptions, stateOptions, countryStateLabel } from 'utils/geo';

export default class TaxRules extends React.PureComponent {
  static propTypes = {
    values: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.renderRuleValue = this.renderRuleValue.bind(this);
    this.renderRuleForm = this.renderRuleForm.bind(this);
    this.renderRuleTable = this.renderRuleTable.bind(this);
    this.renderRateValue = this.renderRateValue.bind(this);
    this.renderRateForm = this.renderRateForm.bind(this);
    this.onSubmitModal = this.onSubmitModal.bind(this);
  }

  onSubmitModal(values) {
    if (!values.id) {
      values.id = snakeCase(values.name);
    }
  }

  renderRuleHeading() {
    return [
      <th key="1">Name</th>,
      <th key="2">Rates</th>,
      <th key="3">Conditions</th>,
    ];
  }

  renderRuleValue({ value }) {
    return [
      <td key="1">
        {value.name}
        {value.priority !== undefined &&
          value.priority !== null &&
          value.priority !== '' && (
            <div className="muted">Priority {value.priority}</div>
          )}
      </td>,
      <td key="2">
        {this.renderRuleRates(value)}
        {value.rates && value.rates.length > 3 && (
          <div className="muted">{value.rates.length - 3} more...</div>
        )}
      </td>,
      <td key="3">{this.renderRuleConditions(value)}</td>,
    ];
  }

  renderRuleConditions(rule) {
    const { settings, categories } = this.props;
    let conditions = [];
    if (rule.origin) {
      const loc = find(settings.shipments.locations, { id: rule.origin });
      if (loc) {
        conditions.push(`Origin: ${loc.name}`);
      }
    }
    if (rule.categories) {
      conditions = conditions.concat(
        rule.categories.map((category) => {
          const currentCategory = categories.index.get(category.id || category);

          return (
            <span>
              Products in{' '}
              <Link to={`/categories/${currentCategory.id}`}>
                {currentCategory.name}
              </Link>
            </span>
          );
        }),
      );
    }
    if (rule.shipping) {
      conditions.push('Applies to shipping');
    }
    if (!conditions.length) {
      return null;
    }
    return conditions.map((cond, index) => (
      <div key={index}>&bull; {cond}</div>
    ));
  }

  renderRuleRates(rule) {
    if (!rule.rates || !rule.rates.length) {
      return <span className="muted">&mdash;</span>;
    }

    return rule.rates.slice(0, 3).map((r, index) => (
      <div key={index}>
        {r.country}
        {!!r.state && `/${r.state}`}
        {!!r.zip && `/${r.zip}`} = {r.rate}%
      </div>
    ));
  }

  renderRuleForm(values) {
    const { settings, categories } = this.props;
    const selectedCategories = (values.categories || []).map((category) =>
      categories.index.get(category.id || category),
    );

    return (
      <Fragment>
        <Field type="hidden" name="id" defaultValue={values.id} />
        <div className="row">
          <Field
            type="text"
            name="name"
            label="Tax rule name"
            defaultValue={values.name}
            required={true}
            className="span3"
          />
          <Field
            type="number"
            name="priority"
            label="Priority"
            help="Taxes are added with those of the same priority, or compounded with those of lower priority"
            defaultValue={values.priority}
            scale={0}
            placeholder="1"
            className="span1"
          />
        </div>
        {settings.shipments.features.multi_location ? (
          <Field
            type="select"
            name="origin"
            label="Apply to product location"
            options={settings.shipments.locations}
            defaultValue={values.origin}
            placeholder="All Locations"
          />
        ) : (
          <Field type="hidden" name="origin" defaultValue={null} />
        )}
        <LookupCategories
          name="categories"
          label="Product categories (optional)"
          defaultValue={selectedCategories}
          categories={categories}
        />
        <Field
          type="checkbox"
          name="shipping"
          label="Apply tax to shipping"
          defaultChecked={!!values.shipping}
        />
        <div className="row">
          {this.props.values.product_classes &&
            this.props.values.product_classes.length > 0 && (
              <Field
                type="checkbox"
                name="product_classes"
                label="Product tax classes"
                options={this.props.values.product_classes}
                defaultValue={values.product_classes}
                stacked={true}
                className="span2"
              />
            )}
          {this.props.values.account_classes &&
            this.props.values.account_classes.length > 0 && (
              <Field
                type="checkbox"
                name="account_classes"
                label="Customer tax classes"
                options={this.props.values.account_classes}
                defaultValue={values.account_classes}
                stacked={true}
                className="span2"
              />
            )}
        </div>
        <br />
        <div className="view-body-subheader">
          <h3 className="view-body-subtitle">Tax rates</h3>
        </div>
        <Fieldtable
          label={`${values.name ? values.name : ''} rate`}
          name="rates"
          defaultValue={values.rates}
          renderHeading={this.renderRateHeading}
          renderValue={this.renderRateValue}
          renderForm={this.renderRateForm}
          formWidth={550}
          localized={true}
        />
      </Fragment>
    );
  }

  renderRuleTable(values, component) {
    if (size(values) === 0) {
      return null;
    }
    return (
      <div className="box">
        {map(values, (rule, index) => (
          <div key={index} className="box-section">
            <div className="box-title">{rule.name}</div>
            <div>
              &bull; Priority {rule.priority || '1'}
              {this.renderRuleConditions(rule)}
            </div>
            <br />
            <div className="box-action">
              <a href="" onClick={component.onClickEditRow} data-index={index}>
                Edit
              </a>
              &nbsp;&nbsp;&nbsp;
              <a
                href=""
                onClick={component.onClickRemoveRow}
                data-index={index}
              >
                Remove
              </a>
            </div>
            {rule.rates.length > 0 ? (
              <div className="collection-table-container">
                <table className="collection-table">
                  <thead>
                    <tr>
                      <th key="1">Rates</th>
                      <th key="2">Country</th>
                      <th key="3">%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rule.rates.map((rate, index) => (
                      <tr key={index}>
                        {this.renderRateValue({ value: rate })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="negative">No rates specified</div>
            )}
          </div>
        ))}
      </div>
    );
  }

  renderRateHeading() {
    return [
      <th key="1">Name</th>,
      <th key="2">Country</th>,
      <th key="3">Rate</th>,
    ];
  }

  renderRateValue({ value }) {
    return [
      <td key="1">{value.name}</td>,
      <td key="2">
        {value.country}
        {!!value.state && `/${value.state}`}
        {!!value.zip && `/${value.zip}`}
      </td>,
      <td key="3">{value.rate}%</td>,
    ];
  }

  renderRateForm(values) {
    return (
      <div>
        <FieldLocalized
          type="text"
          name="name"
          label="Rate name"
          defaultValue={values.name}
          localeValue={values.$locale}
          placeholder="Sales tax"
          required={true}
        />
        <div className="row">
          <Field
            type="select"
            name="country"
            label="Country"
            defaultValue={values.country}
            options={countryOptions}
            required={true}
            className="span2"
          />
          <Field
            type="number"
            name="rate"
            label="Rate %"
            defaultValue={values.rate}
            placeholder="0"
            maxScale={4}
            required={true}
            className="span2"
          />
        </div>
        <div className="row">
          <Field
            type={stateOptions[values.country] ? 'select' : 'text'}
            name="state"
            label={countryStateLabel(values.country)}
            defaultValue={values.state}
            options={stateOptions[values.country]}
            placeholder="Optional"
            className="span2"
          />
          <Field
            type="text"
            name="zip"
            label="Zip/postal code"
            defaultValue={values.zip}
            placeholder="Optional"
            className="span2"
          />
        </div>
      </div>
    );
  }

  render() {
    const { values, categories, onSubmit } = this.props;

    return (
      <Fieldtable
        label="Tax Rule"
        name="rules"
        defaultValue={values.rules}
        onSubmitModal={this.onSubmitModal}
        renderHeading={this.renderRuleHeading}
        renderValue={this.renderRuleValue}
        renderForm={this.renderRuleForm}
        renderTable={this.renderRuleTable}
        onSubmit={onSubmit}
        categories={categories}
        formWidth={650}
      />
    );
  }
}
