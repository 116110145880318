import React from 'react';
import { get, set, pick, find, findIndex } from 'lodash';
import pt from 'prop-types';

import { hasLocalizedContent } from 'utils/content';
import { getVariantByOptions } from 'utils/product';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';
import { getCheckoutUrl, getCleanItemOptions } from 'utils/order';

import { View } from 'components/view';
import { TabView } from 'components/form';
import Link from 'components/link';
import Icon from 'components/icon';
import Side from 'components/view/side';
import PrevButton from 'components/button/prev';
import NextButton from 'components/button/next';
import CurrencyPicker from 'components/locale/currency';
import DropdownButton from 'components/button/dropdown';
import ManageAddresses from 'components/manage-addresses';
import WithViewPreferences from 'containers/WithViewPreferences';
import ContentRecordTab, {
  contentRecordSubmitAction,
  contentRecordSubmitChangeHandlers,
} from 'components/content/record-tab';

import OrderPaymentEdit from './payment-edit';
import OrderItems from './items';
import OrderItemsEdit from './items-edit';
import OrderItemsAdd from './items-add';
import OrderCustomer from './customer';
import OrderCustomerEdit from './customer-edit';
import OrderShippingEdit from './shipping-edit';
import OrderDiscountsEdit from './discounts-edit';
import OrderAddressEdit from './address-edit';
import OrderContent from './content';

import './order.scss';

export default class OrderDraft extends React.PureComponent {
  static propTypes = {
    prev: pt.object,
    next: pt.object,
    record: pt.object,
    edited: pt.bool,
    lookup: pt.object,
    related: pt.object,
    settings: pt.object,
    showPaymentEdit: pt.bool,
    checkoutCustomUrl: pt.string,
    discounts: pt.object,
    location: pt.object,
    content: pt.object,
    loading: pt.bool,
    suggestedAddresses: pt.array,
    isCart: pt.bool,

    onEdited: pt.func,
    onDelete: pt.func,
    onAddItem: pt.func,
    onEditItems: pt.func,
    onStripeInit: pt.func,
    onEditValues: pt.func,
    onPaymentEdit: pt.func,
    onSubmitOrder: pt.func,
    onLoadAddresses: pt.func,
    onLoadBilling: pt.func,
    onChangeCurrency: pt.func,
    onShippingGetRates: pt.func,
    onDiscountsApplyPromos: pt.func,
    getItemPrice: pt.func,
    fetchRecord: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
    testEnv: pt.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showAddItem: false,
      showEditItems: false,
      showEditCustomer: false,
      showShippingEdit: false,
      showEditAddress: false,
      showPaymentEdit: false,
      showDiscountsEdit: false,
      showManageAddresses: false,
      addItemProduct: null,
      editAddItem: null,
      editAddIndex: null,
      editItems: [],
      modalLoading: false,
    };

    this.editItemPriceTimer = 0;
  }

  componentDidMount() {
    confirmRouteLeave(this);
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);

    if (this.props.showPaymentEdit !== prevProps.showPaymentEdit) {
      this.setState({ showPaymentEdit: this.props.showPaymentEdit });
    }
  }

  componentWillUnmount() {
    confirmPageLeave(this);

    if (this.editItemPriceTimer) {
      clearTimeout(this.editItemPriceTimer);
    }
  }

  onClickDelete = (event) => {
    event.preventDefault();

    const { record } = this.props;

    this.context.openModal('ConfirmDelete', {
      title: `draft ${record.number}`,
      onConfirm: () => {
        this.props.onDelete();
      },
    });
  };

  onClickAddItem = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.state.showAddItem && this.props.edited) {
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.props.onEdited(this.state.editAddEdited);
          this.setState({ showAddItem: false });
        },
      });
    } else {
      this.setState((state, props) => ({
        showAddItem: !state.showAddItem,
        addItemProduct: null,
        editAddItem: null,
        editAddIndex: null,
        editAddEdited: state.showEditItems ? props.edited : false,
      }));
    }
  };

  onClickEditItems = (event) => {
    event.preventDefault();

    this.setState((state, props) => ({
      showEditItems: !state.showEditItems,
      editItems: (props.record.items || []).map((item) => ({
        ...item,
      })),
    }));
  };

  onClickEditSingleItem = (event) => {
    event.preventDefault();
    const { id, index } = event.currentTarget.dataset;

    this.setState((state, props) => {
      const editItems = state.showEditItems
        ? state.editItems
        : props.record.items || [];

      return {
        showAddItem: true,
        addItemProduct: null,
        editAddItem: id
          ? find(editItems, (item, i) => item.id === id)
          : editItems[index],
        editAddIndex: id ? null : index,
      };
    });
  };

  onCloseEditItems = (event) => {
    if (this.props.edited) {
      event.preventDefault();
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.onClickEditItems(event);
        },
      });
    } else {
      this.onClickEditItems(event);
    }
  };

  onClickEditCustomer = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showEditCustomer: !state.showEditCustomer,
    }));
  };

  onClickRemoveCustomer = (event) => {
    event.preventDefault();
    return this.props.onEditValues({
      account_id: null,
      billing: null,
      shipping: null,
    });
  };

  onClickShippingEdit = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showShippingEdit: !state.showShippingEdit,
    }));
  };

  onClickEditAddress = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showEditAddress: !state.showEditAddress,
    }));
  };

  onClickShippingGetRates = (event) => {
    event.preventDefault();
    this.props.onShippingGetRates();
  };

  onClickPaymentEdit = async (event) => {
    event.preventDefault();

    if (!this.state.showPaymentEdit) {
      await this.props.onLoadBilling();
    }

    this.setState((state) => ({
      showPaymentEdit: !state.showPaymentEdit,
    }));
  };

  onClickDiscountsEdit = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showDiscountsEdit: !state.showDiscountsEdit,
    }));
  };

  onClickDiscountsApplyPromos = (event) => {
    event.preventDefault();
    this.props.onDiscountsApplyPromos();
  };

  onChangeAddProductLookup = (_event, value) => {
    this.setState({ addItemProduct: value || null });
  };

  async updateStateModalLoading(handler, state = {}) {
    this.setState({ modalLoading: true });
    const result = await handler();
    if (result) {
      this.setState({
        ...state,
        modalLoading: false,
      });
    } else {
      this.setState({ modalLoading: false });
    }
  }

  onSubmitAddItem = async (values) => {
    const { addItemProduct, editAddItem, editAddIndex } = this.state;
    const itemProduct = editAddItem ? editAddItem.product : addItemProduct;

    const addItem = {
      ...values,
      quantity: values.quantity || 1,
      product: itemProduct,
      product_id: itemProduct.id,
      bundle_items: itemProduct.bundle_items,
      options: getCleanItemOptions(itemProduct, values.options),
    };

    if (values.bundle_options) {
      addItem.bundle_items = itemProduct.bundle_items.map((item) => ({
        ...item,
        options: getCleanItemOptions(
          item.product,
          values.bundle_options[item.product_id],
        ),
      }));
    }

    if (addItem.set_price === null) {
      this.setState({ modalLoading: true });
      const pricedItem = await this.props.getItemPrice(
        pick(addItem, [
          'id',
          'product_id',
          'options',
          'quantity',
          'purchase_option',
        ]),
      );
      if (pricedItem && !pricedItem.errors) {
        addItem.price = pricedItem.price;
        addItem.calc_price = pricedItem.price;
        if (!this.state.showEditItems) {
          addItem.set_price = pricedItem.price;
        }
      }
    } else {
      addItem.price = addItem.set_price;
    }

    if (this.state.showEditItems) {
      if (
        addItem.purchase_option &&
        !addItem.purchase_option.type &&
        addItem.purchase_option.plan_id
      ) {
        const plan = addItem.product.purchase_options.subscription.plans.find(
          (plan) => plan.id === addItem.purchase_option.plan_id,
        );

        if (plan) {
          addItem.purchase_option = {
            ...addItem.purchase_option,
            type: 'subscription',
            billing_schedule: plan.billing_schedule,
          };
        }
      }

      addItem.variant = getVariantByOptions(addItem.product, addItem.options);

      const editItems = [...this.state.editItems];
      if (addItem.id) {
        const editIndex = findIndex(editItems, { id: addItem.id });
        editItems[editIndex] = { ...editItems[editIndex], ...addItem };
      } else if (editAddIndex !== null) {
        editItems[editAddIndex] = addItem;
      } else {
        editItems.push(addItem);
      }

      this.props.onEdited(true);
      this.setState({
        showAddItem: false,
        addItemProduct: null,
        editItems,
        modalLoading: false,
      });
    } else {
      this.updateStateModalLoading(() => this.props.onAddItem(addItem), {
        showAddItem: false,
        addItemProduct: null,
      });
    }
  };

  onSubmitEditItems = () => {
    this.updateStateModalLoading(
      () => this.props.onEditItems(this.state.editItems),
      {
        showEditItems: false,
        editItems: [],
      },
    );
  };

  onSubmitEditCustomer = (values) => {
    this.setState({ showEditCustomer: false });

    if (values.account.id) {
      return this.props.onEditValues({
        account_id: values.account.id,
        shipping: values.account.shipping,
        billing: values.account.billing,
        account_info_saved: true,
      });
    } else {
      return this.props.onEditValues({
        account: values.account,
        shipping: values.shipping,
        billing: values.billing,
        account_info_saved: true,
      });
    }
  };

  async onSubmitAddAddress() {
    const {
      shipping: existingShipping,
      account: { shipping: accountShipping },
    } = this.props.record;

    if (existingShipping?.address1) {
      return; // Do nothing if shipping address is already present
    }

    const shippingAddress = accountShipping?.address1 && accountShipping;
    if (accountShipping?.address1) {
      await this.props.onEditValues({
        shipping: shippingAddress,
        account_info_saved: true,
      });
    }
  }

  onSubmitEditAddress = (values) => {
    this.setState({ showEditAddress: false });

    if (values.account.id) {
      return this.props.onEditValues({
        account_id: values.account.id,
        shipping: values.account.shipping,
        billing: values.account.billing,
        account_info_saved: true,
      });
    } else {
      return this.props.onEditValues({
        account: values.account,
        shipping: values.shipping,
        billing: values.billing,
        account_info_saved: true,
      });
    }
  };

  onSubmitShippingEdit = (values) => {
    this.setState({ showShippingEdit: false });

    return this.props.onEditValues({
      shipping: values.shipping,
    });
  };

  onSubmitPaymentEdit = (values) => {
    return this.updateStateModalLoading(
      () =>
        this.props.onPaymentEdit({
          billing: values.billing,
          giftcard_code: values.giftcard_code,
          account_info_saved: true,
        }),
      {
        showPaymentEdit: false,
      },
    );
  };

  onSubmitDiscountsEdit = (values) => {
    this.updateStateModalLoading(
      () =>
        this.props.onEditValues({
          coupon_code: values.coupon_code,
          promotion_ids: {
            $set: values.promotion_ids,
          },
        }),
      {
        showDiscountsEdit: false,
      },
    );
  };

  onClickRemoveEditItem = (event) => {
    event.preventDefault();
    const { index } = event.currentTarget.dataset;
    const editItems = [...this.state.editItems];
    if (editItems[index].id) {
      editItems[index].removed = !editItems[index].removed;
    } else {
      editItems.splice(index, 1);
    }
    this.props.onEdited(true);
    this.setState({ editItems });
  };

  onClickRemoveEditSingleItem = (event) => {
    event && event.preventDefault();
    const { id, index } = event.currentTarget.dataset;
    if (this.state.showEditItems) {
      const editItems = [...this.state.editItems];
      if (id) {
        editItems[findIndex(editItems, { id })].removed = true;
      } else {
        editItems.splice(index, 1);
      }
      this.props.onEdited(true);
      this.setState({
        showAddItem: false,
        editItems,
      });
    } else {
      this.context.openModal('Confirm', {
        title: 'Remove item',
        message: (
          <p>
            Are you sure you want to remove this item? This action can&apos;t be
            undone.
          </p>
        ),
        action: 'Remove',
        actionType: 'danger',
        onConfirm: () => {
          const removedItem = { ...this.state.editAddItem, removed: true };
          this.updateStateModalLoading(
            () => this.props.onEditItems([removedItem]),
            {
              showAddItem: false,
            },
          );
        },
      });
    }
  };

  onChangeEditItemInput = (event, value) => {
    const editItems = [...this.state.editItems];
    const [index, fieldName] = event.target.name.split('.');
    const editItem = editItems[index];

    set(editItems, event.target.name, value);

    if (editItem.quantity <= 0 && editItem.quantity.trim() !== '') {
      editItem.quantity = '0';
      editItem.removed = true;
      editItem.calc_price = undefined;
    } else if (fieldName === 'quantity') {
      const now = Date.now();
      editItem.pricingTime = now;

      if (this.editItemPriceTimer) {
        clearTimeout(this.editItemPriceTimer);
      }

      this.editItemPriceTimer = setTimeout(() => {
        this.editItemPriceTimer = 0;

        this.props
          .getItemPrice(
            pick(editItem, [
              'id',
              'product_id',
              'options',
              'quantity',
              'purchase_option',
            ]),
          )
          .then((pricedItem) => {
            if (this.state.showEditItems && pricedItem && !pricedItem.errors) {
              const postEditItems = [...this.state.editItems];
              if (get(postEditItems[index], 'pricingTime') === now) {
                postEditItems[index].calc_price = pricedItem.price;
                this.setState({ editItems: postEditItems });
              }
            }
          });
      }, 250);
    }

    this.setState({ editItems });
  };

  onClickSubmitOrder = async (event) => {
    event.preventDefault();
    await this.onSubmitAddAddress();
    this.props.onSubmitOrder();
  };

  getCheckoutLink() {
    const {
      record: { checkout_id },
      checkoutCustomUrl = '',
    } = this.props;

    return getCheckoutUrl(checkout_id, checkoutCustomUrl, this.context.testEnv);
  }

  onClickManageAddresses = async (event) => {
    event?.preventDefault();

    if (!this.state.showManageAddresses) {
      await this.props.onLoadAddresses();
    }

    this.setState((state) => ({
      showManageAddresses: !state.showManageAddresses,
    }));
  };

  render() {
    const {
      record,
      related,
      prev = {},
      next = {},
      lookup,
      settings,
      content,
      location,
      loading: loadingProp,
      suggestedAddresses,
      fetchRecord,
      isCart = true,
    } = this.props;

    const { modalLoading } = this.state;

    const loading = loadingProp || modalLoading;

    const isConverted = !!record.order_id;
    const isComplete = !!(
      isConverted === false &&
      record.id &&
      record.items &&
      record.items.length > 0 &&
      record.billing &&
      record.account
    );

    return (
      <div className="order-view order-draft view-detail-full">
        <WithViewPreferences
          {...this.props}
          tabs={[]}
          model="orders"
          id="edit"
          type="record"
        >
          <View
            detail={true}
            submitting={loadingProp}
            uri="/orders/drafts"
            devtools={{
              model: 'carts',
              editModel: 'orders',
              record,
            }}
            withPreferences={true}
            sectionTitle="Drafts"
            headerTitle={record.number ? `Draft ${record.number}` : 'New order'}
            headerActions={[
              next &&
                prev && {
                  component: (
                    <span key="1" className="button-group">
                      <PrevButton
                        to={`/orders/drafts/${prev.id}`}
                        type="secondary"
                        disabled={!prev.id}
                      />
                      <NextButton
                        to={`/orders/drafts/${next.id}`}
                        type="secondary"
                        disabled={!next.id}
                      />
                    </span>
                  ),
                },
              record.number && {
                isActionMenu: true,
                component: (
                  <DropdownButton
                    key="2"
                    anchor="right"
                    alignOffset={13}
                    type="secondary"
                    items={[
                      isConverted && (
                        <Link key="2" to={`/orders/${record.order_id}`}>
                          View submitted order
                        </Link>
                      ),
                      <a key="1" href={this.getCheckoutLink()} target="blank">
                        View checkout <Icon fa="external-link" />
                      </a>,
                    ].filter(Boolean)}
                  >
                    Actions
                  </DropdownButton>
                ),
              },
              isComplete && {
                component: (
                  <button
                    key="2"
                    className="button button-md button-default"
                    onClick={this.onClickSubmitOrder}
                  >
                    Submit order
                  </button>
                ),
              },
              this.state.contentRecordTab &&
                contentRecordSubmitAction(this.state.contentRecordTab),
            ]}
            headerSubtitle={
              <CurrencyPicker
                pretext="Prices in"
                currency={record.currency}
                onChange={this.props.onChangeCurrency}
                editable={!isConverted}
              />
            }
            extraActions={[
              record.number && {
                label: 'Delete draft',
                onClick: this.onClickDelete,
                className: 'danger',
                delete: true,
              },
            ]}
            localized={hasLocalizedContent(this.props.content.models, 'orders')}
          >
            <TabView value={location.query.tab} isDefault>
              <fieldset className="last-child">
                <OrderItems
                  record={record}
                  settings={settings}
                  editable={!isConverted}
                  onClickEditItems={this.onClickEditItems}
                  onClickEditSingleItem={
                    isConverted ? undefined : this.onClickEditSingleItem
                  }
                  onClickAddItem={this.onClickAddItem}
                  onClickShippingEdit={this.onClickShippingEdit}
                  onClickDiscountsEdit={this.onClickDiscountsEdit}
                />

                <OrderContent
                  {...this.props}
                  zone="details"
                  collection="orders"
                  record={record}
                  onSubmit={this.props.onEditValues}
                />

                <OrderContent
                  {...this.props}
                  zone="content"
                  collection="orders"
                  record={record}
                  onSubmit={this.props.onEditValues}
                />
              </fieldset>
            </TabView>

            <ContentRecordTab
              {...this.props}
              collection="orders"
              view="edit"
              form={true}
              {...contentRecordSubmitChangeHandlers(
                this,
                this.props.onEditValues,
              )}
            />

            <Side>
              <OrderCustomer
                record={record}
                content={content}
                settings={settings}
                removable={!isConverted}
                editable={!isConverted}
                onClickEditCustomer={this.onClickEditCustomer}
                onClickRemoveCustomer={this.onClickRemoveCustomer}
                onClickPaymentEdit={this.onClickPaymentEdit}
                onClickEditAddress={this.onClickEditAddress}
                onClickManageAddresses={this.onClickManageAddresses}
              />
            </Side>
          </View>
        </WithViewPreferences>

        {this.state.showEditItems && (
          <OrderItemsEdit
            record={record}
            loading={loading}
            editItems={this.state.editItems}
            onEdited={this.props.onEdited}
            onSubmitEditItems={this.onSubmitEditItems}
            onClickEditItems={this.onClickEditItems}
            onClickEditSingleItem={this.onClickEditSingleItem}
            onClickRemoveEditItem={this.onClickRemoveEditItem}
            onClickAddItem={this.onClickAddItem}
            onCloseEditItems={this.onCloseEditItems}
            onChangeEditItemInput={this.onChangeEditItemInput}
          />
        )}

        {this.state.showAddItem && (
          <OrderItemsAdd
            lookup={lookup}
            isCart={isCart}
            addItemProduct={this.state.addItemProduct}
            onSubmitAddItem={this.onSubmitAddItem}
            onClickAddItem={this.onClickAddItem}
            onChangeAddProductLookup={this.onChangeAddProductLookup}
            onClickRemoveEditSingleItem={this.onClickRemoveEditSingleItem}
            getItemPrice={this.props.getItemPrice}
            editAddItem={this.state.editAddItem}
            editAddIndex={this.state.editAddIndex}
            onEdited={this.state.editAddItem && this.props.onEdited}
            currency={record.currency}
          />
        )}

        {this.state.showEditCustomer && (
          <OrderCustomerEdit
            record={record}
            settings={settings}
            loading={loading}
            lookup={lookup}
            billing={record.billing}
            onSubmitEditCustomer={this.onSubmitEditCustomer}
            onClickEditCustomer={this.onClickEditCustomer}
          />
        )}

        {this.state.showEditAddress && (
          <OrderAddressEdit
            record={record}
            loading={loading}
            shipping={
              record.shipping?.address1
                ? record.shipping
                : record.account.shipping
            }
            onSubmitEditAddress={this.onSubmitEditAddress}
            onClickEditAddress={this.onClickEditAddress}
            onClickEditShipping={this.onClickShippingEdit}
            suggestedAddresses={suggestedAddresses}
          />
        )}

        {this.state.showPaymentEdit && (
          <OrderPaymentEdit
            loading={loading}
            fetchRecord={fetchRecord}
            onSubmitPaymentEdit={this.onSubmitPaymentEdit}
            onClickPaymentEdit={this.onClickPaymentEdit}
          />
        )}

        {this.state.showShippingEdit && (
          <OrderShippingEdit
            record={record}
            settings={settings}
            loading={loading}
            onSubmitShippingEdit={this.onSubmitShippingEdit}
            onClickShippingEdit={this.onClickShippingEdit}
            onClickShippingGetRates={this.onClickShippingGetRates}
            suggestedAddresses={suggestedAddresses}
          />
        )}

        {this.state.showDiscountsEdit && (
          <OrderDiscountsEdit
            record={record}
            loading={loading}
            lookup={lookup}
            promotions={true}
            discounts={this.props.discounts}
            onSubmitDiscountsEdit={this.onSubmitDiscountsEdit}
            onClickDiscountsEdit={this.onClickDiscountsEdit}
          />
        )}

        {this.state.showManageAddresses && (
          <ManageAddresses
            fetchRecord={fetchRecord}
            loading={loading}
            onClickManageAddresses={this.onClickManageAddresses}
          />
        )}
      </div>
    );
  }
}
