import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import BulkProgress from './progress';
import { FadeIn } from 'components/transitions';
import { singularize, inflect } from 'utils';
import { BULK_RUNNING_WARNING } from 'utils/messages';
import './bulk.scss';

export default class BulkEdit extends React.PureComponent {
  static propTypes = {
    initialFields: PropTypes.array,
    label: PropTypes.string.isRequired,
    bulk: PropTypes.object.isRequired,
    selection: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showingAddField: true,
      fields: props.initialFields || [],
      options: this.getInitialOptions(props),
    };
    this.onClose = this.onClose.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onChangeAddField = this.onChangeAddField.bind(this);
    this.onClickRemoveField = this.onClickRemoveField.bind(this);
    this.onClickAddField = this.onClickAddField.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.props.options) {
      this.setState({ options: this.getInitialOptions(nextProps) });
    }
  }

  getInitialOptions(props) {
    return props.options.map((option) => ({
      label: option.label,
      value: option,
    }));
  }

  onClose(event) {
    if (this.props.bulk.running) {
      event.preventDefault();
      this.context.openModal('Confirm', {
        title: 'Cancel update',
        message: BULK_RUNNING_WARNING,
        action: 'Confirm',
        actionType: 'danger',
        onConfirm: () => {
          this.props.onClose(event);
        },
      });
      return;
    }
    this.props.onClose(event);
  }

  onClickCancel(event) {
    event.preventDefault();
    if (this.props.bulk.running) {
      this.context.openModal('Confirm', {
        title: 'Cancel update',
        message: BULK_RUNNING_WARNING,
        action: 'Confirm',
        actionType: 'danger',
        onConfirm: () => {
          this.props.onClose(event);
        },
      });
      return;
    }
    this.props.onClose(event);
  }

  onChangeAddField(event, field) {
    if (!field) {
      return;
    }
    if (field.type === 'currency') {
      field.currency = this.context.client.currency;
    }
    this.setState(
      {
        fields: [...this.state.fields, field],
        showingAddField: false,
      },
      () => {
        this.refs[`field_${field.name}`].focus();
      },
    );
  }

  onClickRemoveField(event) {
    event.preventDefault();
    const name = event.currentTarget.dataset.name;
    const fields = this.state.fields.filter((field) => field.name !== name);
    this.setState({ fields });
  }

  onClickAddField(event) {
    event.preventDefault();
    this.setState({ showingAddField: true }, () => {
      this.refs.addField.focus();
    });
  }

  render() {
    const {
      label,
      selection,
      bulk: { running, percent, complete },
      onSubmit,
      loaded,
    } = this.props;

    const { fields, options } = this.state;

    return (
      <Form onSubmit={onSubmit}>
        <Modal
          title={`Edit ${inflect(selection.count, label.toLowerCase())}`}
          width={600}
          className="bulk-edit-modal"
          actions={[
            { label: 'Save', type: 'submit' },
            { label: 'Cancel', type: 'cancel', onClick: this.onClickCancel },
          ]}
          cancel={false}
          onClose={this.onClose}
          loading={loaded === false}
        >
          <fieldset>
            {running || complete ? (
              <BulkProgress percent={percent} />
            ) : (
              <div>
                {fields.length > 0 && (
                  <div className="collection-table-container">
                    <table className="collection-table">
                      <thead>
                        <th>Field</th>
                        <th>{}</th>
                        <th>{}</th>
                      </thead>
                      <tbody>
                        {fields.map((field) => (
                          <tr key={field.name}>
                            <td>
                              <FadeIn active={true} transitionAppear={true}>
                                <div className="collection-field-padded">
                                  {field.label || field.name}
                                </div>
                              </FadeIn>
                            </td>
                            <td>
                              <FadeIn active={true} transitionAppear={true}>
                                <Field
                                  ref={`field_${field.name}`}
                                  {...field}
                                  label={undefined}
                                />
                              </FadeIn>
                            </td>
                            <td>
                              <FadeIn active={true} transitionAppear={true}>
                                <a
                                  href=""
                                  onClick={this.onClickRemoveField}
                                  data-name={field.name}
                                >
                                  Remove
                                </a>
                              </FadeIn>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {this.state.showingAddField ? (
                  <FadeIn>
                    <Field
                      ref="addField"
                      key={JSON.stringify(fields)}
                      type="select"
                      options={options}
                      disabledOptions={fields}
                      placeholder={`Choose ${singularize(
                        label,
                      ).toLowerCase()} properties to edit`}
                      onChange={this.onChangeAddField}
                    />
                  </FadeIn>
                ) : (
                  <a
                    href=""
                    onClick={this.onClickAddField}
                    className="button button-secondary button-sm form-link"
                  >
                    Add field
                  </a>
                )}
              </div>
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
