import React, { Fragment } from 'react';
import { reduce, find, omit } from 'lodash';

import { formatCurrency, combineDateTime, isEmpty } from 'utils';
import { renderMultiCurrencyValuesTooltip } from 'utils/money';

// Get the single coupon code if applicable
export function couponCodeSingle(coupon, values = {}) {
  if (coupon.multi_codes) {
    return null;
  }

  const codeRecord = coupon.codes && coupon.codes.results[0];

  const codeValue =
    values.code !== undefined ? values.code : codeRecord ? codeRecord.code : '';

  return String(codeValue).toUpperCase();
}

// Get a friendly description of a discount rule conditions
export function discountRuleConditions(rule, currency, settings, categories) {
  const conditions = [];

  if (!rule) {
    return conditions;
  }

  if (rule.total_min > 0) {
    conditions.push(
      <Fragment>
        Minimum purchase of {formatCurrency(rule.total_min, currency)}
        {renderMultiCurrencyValuesTooltip(
          rule.$currency,
          'total_min',
          currency,
        )}
      </Fragment>,
    );
  }

  if (rule.price_min > 0) {
    conditions.push(
      <Fragment>
        Minimum product price of {formatCurrency(rule.price_min, currency)}
        {renderMultiCurrencyValuesTooltip(
          rule.$currency,
          'price_min',
          currency,
        )}
      </Fragment>,
    );
  }

  if (rule.shipment_service) {
    const service = find(settings.shipments.services, {
      id: rule.shipment_service,
    });
    conditions.push(
      `Applies to ${service ? service.name : rule.shipment_service}`,
    );
  }

  if (rule.quantity_min > 0) {
    conditions.push(`Minimum quantity of ${rule.quantity_min}`);
  }

  if (rule.quantity_max > 0) {
    conditions.push(`Maximum quantity of ${rule.quantity_max}`);
  }

  if (rule.quantity_add > 0) {
    conditions.push(`Add ${rule.quantity_add} to cart when applied`);
  }

  if (rule.exclude_categories && rule.exclude_categories.length > 0) {
    const excludeCategoryNames = rule.exclude_categories
      .map((exCat) => categories.index.get(exCat.id)?.name)
      .filter((name) => !!name)
      .join(', ');

    conditions.push(`Excluding ${excludeCategoryNames}`);
  }

  if (rule.discount_max > 0) {
    conditions.push(
      <Fragment>
        Maximum discount of {formatCurrency(rule.discount_max, currency)}
        {renderMultiCurrencyValuesTooltip(
          rule.$currency,
          'discount_max',
          currency,
        )}
      </Fragment>,
    );
  }

  return conditions;
}

export function discountStateFromRecord(record, categories) {
  if (!record) {
    return {};
  }

  return {
    values: {
      ...record,
      discounts: (record.discounts || []).map((discount) => ({
        ...discount,
        category: categories.index.get(discount.category_id),
        exclude_categories: reduce(
          discount.exclude_category_ids,
          (result, id) => {
            const category = categories.index.get(id);

            if (category) {
              result.push(category);
            }

            return result;
          },
          [],
        ),
        buy_items: (discount.buy_items || []).map((item) =>
          convertDiscountItemToValue(item, categories),
        ),
        get_items: (discount.get_items || []).map((item) =>
          convertDiscountItemToValue(item, categories),
        ),
        check_exclude_categories: Boolean(discount.exclude_category_ids),
        check_shipment_service: Boolean(discount.shipment_service),
        check_total_min: discount.total_min > 0,
        check_quantity_min: discount.quantity_min > 0,
        check_quantity_max: discount.quantity_max > 0,
        check_quantity_add: discount.quantity_add > 0,
        check_price_min: discount.price_min > 0,
        condition_type: discount.total_min > 0 ? 'total_min' : 'quantity_min',
        discount_type: discount.get_total === true ? 'total' : 'items',
        check_discount_max: discount.discount_max > 0,
      })),
    },
  };
}

// Get updates for containers
export function discountUpdatesFromValues(values, record) {
  const updates = { ...values, $set: {} };

  if (values.discounts) {
    updates.discounts = values.discounts.map((valueDisc) => {
      const discount = { ...valueDisc, type_input: undefined };
      const isBuyGetDiscount = discount.type === 'buy_get';

      // Remove check_ fields
      for (const key of Object.keys(discount)) {
        if (key.indexOf('check_') === 0) {
          // since BuyGetRuleForm doesn't use check boxes, while still using fields like quantity_min
          // their values shouldn't be wiped for that type of rule
          if (!discount[key] && !isBuyGetDiscount) {
            delete discount[key.replace('check_', '')];
          }

          delete discount[key];
        }
      }

      if (isBuyGetDiscount) {
        return buyGetDiscountUpdatesFromValues(discount);
      }

      if (discount.product) {
        discount.product_id = discount.product.id;
        delete discount.product;
      } else {
        delete discount.product_id;
      }

      if (discount.category) {
        discount.category_id = discount.category.id;
        delete discount.category;
      } else {
        delete discount.category_id;
      }

      if (discount.exclude_categories) {
        discount.exclude_category_ids = discount.exclude_categories.map(
          (category) => category.id,
        );
        delete discount.exclude_categories;
      } else {
        delete discount.exclude_category_ids;
      }

      return discount;
    });
  }

  if (record.id) {
    if (updates.discounts) {
      updates.$set.discounts = updates.discounts.map((discount, index) => {
        // reset currency fields for coupon or promotion if the user tries to uncheck them
        resetCurrencyField('total_min', discount, values.discounts, index);
        resetCurrencyField('discount_max', discount, values.discounts, index);

        if (discount.type === 'product') {
          return {
            ...discount,
            product_id: discount.product_id,
          };
        }

        return omit(discount, ['category', 'product_id']);
      });

      delete updates.discounts;
    }
  }

  if (values.code) {
    if (!values.multi_codes) {
      const codeRecord = record.codes ? record.codes.results[0] : undefined;

      updates.codes = [
        {
          id: codeRecord && codeRecord.id,
          code: values.code,
        },
      ];

      // Override name if applicable
      if (!values.name) {
        updates.name = String(values.code).toUpperCase();
      }
    }
  }

  if (values.date_valid) {
    updates.date_valid = combineDateTime(
      values.date_valid,
      values.date_valid_time,
    );

    delete updates.date_valid_time;
  } else {
    updates.date_valid = values.date_valid !== undefined ? null : undefined;
  }

  if (values.date_expired) {
    updates.date_expired = combineDateTime(
      values.date_expired,
      values.date_expired_time,
    );

    delete updates.date_expired_time;
  } else {
    updates.date_expired = values.date_expired !== undefined ? null : undefined;
  }

  if (values.date_start) {
    updates.date_start = combineDateTime(
      values.date_start,
      values.date_start_time,
    );

    delete updates.date_start_time;
  } else {
    updates.date_start = values.date_start !== undefined ? null : undefined;
  }

  if (values.date_end) {
    updates.date_end = combineDateTime(values.date_end, values.date_end_time);

    delete updates.date_end_time;
  } else {
    updates.date_end = values.date_end !== undefined ? null : undefined;
  }

  if (!values.limit_uses) {
    updates.limit_uses = null;
  }

  if (!values.limit_subscription_uses) {
    updates.limit_subscription_uses = null;
  }

  if (!values.limit_account_uses) {
    updates.limit_account_uses = null;
  }

  if (isEmpty(values.limit_account_groups)) {
    updates.limit_account_groups = null;
  }

  return updates;
}

function resetCurrencyField(fieldName, discount, discountList, index) {
  if (
    !discount[fieldName] &&
    Array.isArray(discountList) &&
    discountList.length > index &&
    discountList[index][fieldName]
  ) {
    discount[fieldName] = null;

    if (discount.$currency) {
      for (const currency of Object.values(discount.$currency)) {
        if (currency && fieldName in currency) {
          currency[fieldName] = null;
        }
      }
    }
  }
}

function buyGetDiscountUpdatesFromValues(discount) {
  if (Array.isArray(discount.buy_items)) {
    discount.buy_items = discount.buy_items.map(convertValueToDiscountItem);
    delete discount.buy_items_index;
  }

  if (Array.isArray(discount.get_items)) {
    discount.get_items = discount.get_items.map(convertValueToDiscountItem);
    delete discount.get_items_index;
  }

  if (discount.condition_type === 'total_min') {
    discount.quantity_min = null;
  } else if (discount.condition_type === 'quantity_min') {
    discount.total_min = null;
    discount.quantity_min = discount.quantity_min || 1;
  }

  delete discount.condition_type;

  if (discount.discount_type === 'items') {
    discount.get_total = false;
  } else if (discount.discount_type === 'total') {
    discount.get_total = true;
  }

  delete discount.discount_type;
  discount.value_type = 'percent'; // always

  return discount;
}

function convertDiscountItemToValue(item, categories) {
  if (item.category_id) {
    const category = categories.index.get(item.category_id) || {};

    return {
      ...category,
      _model: 'categories',
    };
  }

  return {
    ...item.product,
    _model: 'products',
  };
}

function convertValueToDiscountItem(value) {
  if (value._model === 'products') {
    return {
      product_id: value.id,
    };
  }

  return {
    category_id: value.id,
  };
}
