import PropTypes from 'prop-types';
import React from 'react';
import './loading.scss';

const svgStyle = {
  animation: 'rotate 0.8s linear infinite',
  height: '100%',
  transformOrigin: 'center center',
  width: '100%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
};

const circleStyle = {
  strokeDasharray: '50,200',
  strokeDashoffset: '0',
  //animation: 'dash 1.3s linear infinite',
  strokeLinecap: 'round',
  //stroke: '#000',
};

const animation = `@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
}`;

export default class Loading extends React.PureComponent {
  static propTypes = {
    when: PropTypes.bool,
    style: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    margin: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string,
  };

  render() {
    let { when = true, children, className } = this.props;

    if (when) {
      let { width, height, margin, style = {} } = this.props;

      style.width = width;
      style.height = height;
      style.margin = margin;

      return (
        <div className={`loading ${className || ''}`} style={style}>
          <style>{animation}</style>
          <svg style={svgStyle} viewBox="25 25 50 50">
            <circle
              style={circleStyle}
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth="3"
              strokeMiterlimit="10"
            />
          </svg>
          {children}
        </div>
      );
    }
    return <div>{children || null}</div>;
  }
}
