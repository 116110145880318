import React from 'react';

import Form from 'components/form';
import View from 'components/view';

import PromotionForm from './form';

import './promotion.scss';

export default class PromotionNew extends React.PureComponent {
  render() {
    const { edited, onSubmitRecord, onChangeForm, ...props } = this.props;

    return (
      <Form onSubmit={onSubmitRecord} autoFocus={true} onChange={onChangeForm}>
        <View
          detail={true}
          uri={`/promotions`}
          sectionTitle="Promotions"
          headerTitle="New promotion"
          headerActions={[
            { label: 'Save promotion', submit: true, type: 'default' },
          ]}
          localized={true}
        >
          <PromotionForm {...props} onChangeForm={onChangeForm} />
        </View>
      </Form>
    );
  }
}
