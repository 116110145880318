import URL from 'url';
import api from 'services/api';
import generatePassword from 'generate-password';
import Cookies from 'universal-cookie';
import { pull, find, sortBy } from 'lodash';

import { COOKIE_MAX_AGE } from 'services/cookie';
import { normalizeUrl } from 'utils';
import {
  populateMenuLookupValues,
  depopulateMenuLookupValues,
} from 'utils/content';

import { USER_FETCH } from './user';

const cookies = new Cookies();

export const STOREFRONTS_FETCH = 'storefronts/fetch';
export const STOREFRONTS_FETCH_PRIMARY = 'storefronts/fetchPrimary';
export const STOREFRONTS_FETCH_ALL = 'storefronts/fetchAll';
export const STOREFRONTS_FETCH_QUEUE = 'storefronts/fetchQueue';
export const STOREFRONTS_FETCH_CONFIG = 'storefronts/fetchConfig';
export const STOREFRONTS_FETCH_THEMES = 'storefronts/fetchThemes';
export const STOREFRONTS_FETCH_THEME_DETAILS = 'storefronts/fetchThemeDetails';
export const STOREFRONTS_LOAD = 'storefronts/load';
export const STORE_LOAD = 'store/load';
export const STOREFRONTS_CREATE = 'storefronts/create';
export const STOREFRONTS_UPDATE = 'storefronts/update';
export const STOREFRONTS_UPDATE_CONFIG = 'storefronts/updateConfig';
export const STOREFRONTS_FETCH_PUBLISH_STATUS =
  'storefronts/fetchPublishStatus';
export const STOREFRONTS_UPDATE_VERSION = 'storefronts/updateVersion';
export const STOREFRONTS_CANCEL_UPDATE = 'storefronts/cancelUpdate';
export const STOREFRONTS_DELETE = 'storefronts/delete';
export const STOREFRONTS_CREATE_API_KEY = 'storefronts/createAPIKey';
export const STOREFRONTS_UPDATE_API_KEY = 'storefronts/updateAPIKey';
export const STOREFRONTS_DELETE_API_KEY = 'storefronts/deleteAPIKey';
export const STOREFRONTS_CREATE_DOMAIN = 'storefronts/createDomain';
export const STOREFRONTS_UPDATE_DOMAIN = 'storefronts/updateDomain';
export const STOREFRONTS_VERIFY_DOMAIN = 'storefronts/verifyDomain';
export const STOREFRONTS_ENABLE_SSL_DOMAIN = 'storefronts/enableSSLDomain';
export const STOREFRONTS_DISABLE_SSL_DOMAIN = 'storefronts/disableSSLDomain';
export const STOREFRONTS_DELETE_DOMAIN = 'storefronts/deleteDomain';
export const STOREFRONTS_FETCH_THUMBNAILS = 'storefronts/fetchThumbnails';
export const STOREFRONTS_FETCH_INFO = 'storefronts/fetchInfo';
export const STOREFRONTS_POST_PASSWORD = 'storefronts/postPassword';
export const STOREFRONTS_GET_TEST_CHECKOUT = 'storefronts/getTestCheckout';
export const STOREFRONTS_LOADING = 'storefronts/loading';

const actions = {
  fetchPrimary() {
    return {
      type: STOREFRONTS_FETCH_PRIMARY,
      payload: api.get(`/storefront`),
    };
  },

  loadPrimary() {
    return (dispatch, getState) => {
      const { storefronts } = getState();
      if (storefronts.fetchedPrimary) {
        return Promise.resolve(storefronts.primary);
      }
      return dispatch(this.fetchPrimary());
    };
  },

  fetch(id) {
    return {
      type: STOREFRONTS_FETCH,
      payload: api.get(`/storefronts/${id}`),
    };
  },

  fetchAll() {
    return {
      type: STOREFRONTS_FETCH_ALL,
      payload: api.get(`/storefronts`),
    };
  },

  fetchQueue(id) {
    return {
      type: STOREFRONTS_FETCH_QUEUE,
      payload: api.get(`/deployments/${id}/queue`),
    };
  },

  fetchThemes() {
    return {
      type: STOREFRONTS_FETCH_THEMES,
      payload: api.get(`/themes`),
    };
  },

  fetchThemeDetails(id) {
    return {
      type: STOREFRONTS_FETCH_THEME_DETAILS,
      payload: api.get(`/themes/${id}`, { expand: ['latest_version'] }),
    };
  },

  // load doesn't mutate state
  load(id, { query }) {
    return {
      type: STOREFRONTS_LOAD,
      payload: api.get(`/storefronts/${id}`, {
        ...query,
        $cache: Date.now(),
      }),
    };
  },

  loadStore() {
    return {
      type: STORE_LOAD,
      payload: api.get('/store'),
    };
  },

  create(data) {
    return {
      type: STOREFRONTS_CREATE,
      payload: api.post('/storefronts', data),
    };
  },

  update(id, data) {
    return function (dispatch, getState) {
      if (data.external?.custom_url) {
        data.external.custom_url = normalizeUrl(data.external.custom_url, {
          stripWWW: false,
        });
      }

      return dispatch({
        type: STOREFRONTS_UPDATE,
        payload: api.put(`/storefronts/${id}`, data).then((result) => {
          if (result?.errors) {
            return result;
          }

          if (getState().storefronts.storefront?.id === id) {
            return api.get(`/storefronts/${id}`);
          }

          return result;
        }),
      });
    };
  },

  fetchPublishStatus: (storefront) => (dispatch) => {
    return dispatch({
      type: STOREFRONTS_FETCH_PUBLISH_STATUS,
      payload: api.get(`/storefronts/${storefront.id}/status`),
      showLoading: false,
    });
  },

  updateVersion:
    ({ tag }) =>
    (dispatch, getState) => {
      const {
        storefronts: { storefront },
      } = getState();
      return dispatch({
        type: STOREFRONTS_UPDATE_VERSION,
        payload: api.put(`/storefronts/${storefront.id}`, {
          $update: tag,
        }),
        showLoading: false,
      });
    },

  cancelUpdate: () => (dispatch, getState) => {
    const {
      storefronts: { storefront },
    } = getState();

    return dispatch({
      type: STOREFRONTS_CANCEL_UPDATE,
      payload: api.put(`/storefronts/${storefront.id}`, {
        $update: false,
      }),
      showLoading: false,
    });
  },

  delete(id) {
    return {
      type: STOREFRONTS_DELETE,
      payload: api.delete(`/storefronts/${id}`),
    };
  },

  createAPIKey(id, data) {
    return {
      type: STOREFRONTS_CREATE_API_KEY,
      payload: api.post(`/storefronts/${id}/keys`, data),
    };
  },

  updateAPIKey(id, keyId, data) {
    return {
      type: STOREFRONTS_UPDATE_API_KEY,
      payload: api.put(`/storefronts/${id}/keys/${keyId}`, data),
    };
  },

  deleteAPIKey(id, keyId) {
    return {
      type: STOREFRONTS_DELETE_API_KEY,
      payload: api.delete(`/storefronts/${id}/keys/${keyId}`),
    };
  },

  createDomain(id, data) {
    return {
      type: STOREFRONTS_CREATE_DOMAIN,
      payload: api.post(`/storefronts/${id}/domains`, data),
    };
  },

  updateDomain(id, domainId, data) {
    return {
      type: STOREFRONTS_UPDATE_DOMAIN,
      payload: api.put(`/storefronts/${id}/domains/${domainId}`, data),
    };
  },

  verifyDomain(id, domainId) {
    return {
      type: STOREFRONTS_VERIFY_DOMAIN,
      payload: api.put(`/storefronts/${id}/domains/${domainId}/verified`),
    };
  },

  enableSSLDomain(id, domainId) {
    return {
      type: STOREFRONTS_ENABLE_SSL_DOMAIN,
      payload: api.put(`/storefronts/${id}/domains/${domainId}`, {
        ssl: true,
      }),
    };
  },

  disableSSLDomain(id, domainId) {
    return {
      type: STOREFRONTS_DISABLE_SSL_DOMAIN,
      payload: api.put(`/storefronts/${id}/domains/${domainId}`, {
        ssl: false,
      }),
    };
  },

  deleteDomain(id, domainId) {
    return {
      type: STOREFRONTS_DELETE_DOMAIN,
      payload: api.delete(`/storefronts/${id}/domains/${domainId}`),
    };
  },

  fetchThumbnails: (storefront) => (dispatch) => {
    return dispatch({
      type: STOREFRONTS_FETCH_THUMBNAILS,
      payload: api.put(`/storefronts/${storefront.id}`, {
        $thumbnails: true,
      }),
      showLoading: false,
    });
  },

  fetchInfo() {
    return {
      type: STOREFRONTS_FETCH_INFO,
      payload: api.getStoreInfo(),
    };
  },

  postPassword(password) {
    return {
      type: STOREFRONTS_POST_PASSWORD,
      payload: api.postStorePassword(password),
    };
  },

  getTestCheckout(reset) {
    return {
      type: STOREFRONTS_GET_TEST_CHECKOUT,
      payload: api.get('/test-checkout', { reset }),
    };
  },

  fetchConfig(id, type) {
    return {
      type: STOREFRONTS_FETCH_CONFIG,
      payload: api.getLocalized(`/storefronts/${id}/configs/${type}`),
      meta: { id, type },
    };
  },

  updateConfig(id, type, values) {
    return {
      type: STOREFRONTS_UPDATE_CONFIG,
      payload: api.put(`/storefronts/${id}/configs/${type}`, values),
      meta: { id, type },
    };
  },

  fetchMenus(id) {
    return async (dispatch) => {
      const config = await dispatch(this.fetchConfig(id, 'menus'));
      await populateMenuLookupValues(config?.values?.menus);
      return config?.values?.menus;
    };
  },

  updateMenu(id, values) {
    return async (dispatch, getState) => {
      const {
        storefronts: { configs },
      } = getState();

      const exMenus = (
        configs.menus ?? (await dispatch(this.fetchConfig(id, 'menus')))
      )?.values?.menus;

      let menus = sortBy(
        [
          ...pull(
            [...exMenus],
            find(exMenus, (menu) => menu.id === values.id),
          ),
          {
            ...values,
          },
        ],
        (menu) => menu.name,
      );

      menus = depopulateMenuLookupValues(menus);

      return dispatch(
        this.updateConfig(id, 'menus', {
          $set: {
            values: { menus },
          },
        }),
      );
    };
  },

  deleteMenu(id, menuId) {
    return async (dispatch, getState) => {
      const {
        storefronts: { configs },
      } = getState();

      const exMenus = (
        configs.menus ?? (await dispatch(this.fetchConfig(id, 'menus')))
      )?.values?.menus;

      let menus = pull(
        [...(exMenus || [])],
        find(exMenus, (menu) => menu.id === menuId),
      );

      menus = depopulateMenuLookupValues(menus);

      return dispatch(
        this.updateConfig(id, 'menus', {
          $set: {
            values: { menus },
          },
        }),
      );
    };
  },
};

export const initialState = {
  primary: null, // global main primary storefront
  storefront: null, // changes on page context
  all: {},
  configs: {},
  info: null,
  testCheckout: null,
  loading: false,
  themes: {},
  thumbnails: null,
  publishStatus: {},
  creatingQueue: {},
  fetchedPrimary: false,
};

function setStorefrontConfig(state, action) {
  // Make sure config is being fetched for the current storefront
  if (state.storefront?.id && action.meta.id !== state.storefront?.id) {
    return state;
  }

  return {
    ...state,
    configs: { ...state.configs, [action.meta.type]: action.payload },
  };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET':
      return initialState;

    case STOREFRONTS_FETCH_PRIMARY:
      return {
        ...state,
        primary: action.payload,
        fetchedPrimary: true,
      };

    case STOREFRONTS_FETCH: {
      // Clear configs if a new storefront is being loaded
      const configs =
        !state.storefront?.id || state.storefront?.id === action.payload?.id
          ? state.configs
          : {};

      return {
        ...state,
        storefront: action.payload,
        configs,
        ...(action.payload?.primary ? { primary: action.payload } : {}),
      };
    }

    case STOREFRONTS_FETCH_ALL:
      return {
        ...state,
        all: action.payload,
      };

    case STOREFRONTS_FETCH_INFO:
      return {
        ...state,
        info: action.payload,
      };

    case STOREFRONTS_UPDATE: {
      if (action.payload?.errors) {
        return state;
      }

      return {
        ...state,
        ...(state.storefront?.id === action.payload.id
          ? { storefront: action.payload }
          : undefined),
        ...(action.payload.primary ? { primary: action.payload } : undefined),
      };
    }

    case STOREFRONTS_UPDATE_CONFIG: {
      if (action.payload?.errors) {
        return state;
      }

      return setStorefrontConfig(state, action);
    }

    case STOREFRONTS_FETCH_CONFIG:
      return setStorefrontConfig(state, action);

    case STOREFRONTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case STOREFRONTS_GET_TEST_CHECKOUT:
      return {
        ...state,
        testCheckout: action.payload,
      };

    case USER_FETCH:
      if (action.payload && action.payload.test_checkout_id) {
        return {
          ...state,
          testCheckout: {
            id: action.payload.test_checkout_id,
          },
        };
      }
      return state;

    case STOREFRONTS_FETCH_PUBLISH_STATUS:
    case STOREFRONTS_UPDATE_VERSION:
      return {
        ...state,
        publishStatus: action.payload,
      };

    case STOREFRONTS_FETCH_THEMES:
      return {
        ...state,
        themes: action.payload,
      };

    case STOREFRONTS_FETCH_QUEUE:
      return {
        ...state,
        creatingQueue: action.payload,
      };

    default:
      return state;
  }
}

export function generatePasswordAndCookie(deploymentName) {
  const password = generatePassword.generate({
    length: 8,
    numbers: false,
    symbols: false,
    excludeSimilarCharacters: true,
  });
  const domain = URL.parse(
    process.env.CLIENT_URL.replace('CLIENT_ID', deploymentName),
  ).host.replace(/:.*/, '');
  cookies.set('_swell_password', password, {
    path: '/',
    maxAge: COOKIE_MAX_AGE,
    domain,
    // client cookies cannot be httponly
    httpOnly: false,
    sameSite: 'lax',
    secure: process.env.NODE_ENV !== 'development',
  });
  return password;
}

export default actions;
