import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import api from 'services/api';

import actions from 'actions';

import ViewLoading from 'components/view/loading';
import NewPurchaseLink from 'components/pages/purchase-links/new';

import { getMapDispatchToProps } from './ViewPurchaseLink';

export const mapStateToProps = (state) => ({
  record: state.data.record,
  related: state.data.related,
  prev: state.data.record && (state.data.record.prev || undefined),
  next: state.data.record && (state.data.record.next || undefined),
  loading: state.data.loading,
  errors: state.data.recordErrors,
  lookup: state.lookup,
  categories: state.categories,
  content: state.content,
  settings: state.settings,
  discounts: state.orders.discounts,
});

export const mapDispatchToProps = getMapDispatchToProps((dispatch) => ({
  fetchRecord(id) {
    return dispatch(
      actions.data.fetchRecord('purchaselinks', id, {
        expand: [
          'items.product',
          'items.variant',
          'items.bundle_items.product',
          'items.bundle_items.variant',
          'coupon',
        ],
      }),
    );
  },

  createRecord(data) {
    return dispatch(actions.data.createRecord('purchaselinks', data));
  },

  updateRecord(id, data) {
    return dispatch(actions.data.updateRecord('purchaselinks', id, data));
  },

  updateFetchRecord(id, data) {
    return dispatch(actions.data.updateFetchRecord('purchaselinks', id, data));
  },

  getItemPrice(item) {
    return api.get(`/data/purchaselinks/new/items`, { $price: item });
  },

  addItem(id, item) {
    return dispatch(actions.data.createRecord(`purchaselinks/${id}/items`, item));
  },

  editItems(id, items) {
    return Promise.all(
      items
        .filter((item) => Boolean(item.removed))
        .map((item) => {
          return dispatch(actions.data.deleteRecord(`purchaselinks/${id}/items`, item.id));
        }),
    ).then(() => {
      return dispatch(
        actions.data.updateRecord(
          'purchaselinks',
          `${id}/items`,
          items.filter((item) => !item.removed),
        ),
      );
    });
  },

  deleteRecord(id) {
    return dispatch(actions.data.deleteRecord('purchaselinks', id));
  },

  clearRecord() {
    return dispatch(actions.data.clearRecord());
  },

  clearErrors() {
    return dispatch(actions.data.clearErrors());
  },

  fetchDiscounts() {
    return dispatch(actions.orders.fetchDiscounts());
  },

  loadContentModels() {
    dispatch(actions.content.loadModels('purchaselinks'));
  },
}));

export class NewPurchaseLinks extends React.PureComponent {
  static contextTypes = {
    notifySuccess: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      edited: false,
      submitting: false,
    };
  }

  componentDidMount() {
    const { clearRecord, fetchDiscounts, loadContentModels } = this.props;

    clearRecord();

    Promise.all([fetchDiscounts(), loadContentModels()]).then(() => {
      this.setState({ loaded: true });
    });
  }

  onEdited = (edited) => {
    this.setState({ edited });
  };

  onSubmitRecord = async (values) => {
    const { router, createRecord } = this.props;

    this.setState({ submitting: true });

    const record = await createRecord(values);

    this.setState({ submitting: false });

    if (record.errors) {
      this.context.notifyError(record.errors);
    } else {
      router.replace(`/purchase-links/${record.id}`);
    }
  };

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    if (this.props.page) {
      return <this.props.page {...this.props} {...this.state} />;
    }

    return (
      <NewPurchaseLink
        {...this.props}
        {...this.state}
        onSubmitRecord={this.onSubmitRecord}
        onEdited={this.onEdited}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPurchaseLinks);
