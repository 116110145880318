import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import actions from 'actions';

import { contentUpdatesDeprecated } from 'utils/content';

import {
  sortProducts,
  addProduct,
  moveProduct,
  removeProduct,
} from 'utils/category';

import NewPage from 'components/pages/category/new';

export const mapStateToProps = (state) => ({
  data: state.data,
  categories: state.categories,
  content: state.content,
  errors: state.data.recordErrors,
  loading: state.data.loading,
  lookup: state.lookup,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchCategories() {
    return dispatch(actions.categories.fetch());
  },

  loadContentModels() {
    return dispatch(actions.content.loadModels('categories'));
  },

  loadContentFieldsDeprecated() {
    return dispatch(actions.content.loadFieldsDeprecated('categories'));
  },

  createRecord(data) {
    return dispatch(actions.data.createRecord('categories', data));
  },
});

export class NewCategory extends React.Component {
  static propTypes = {
    router: pt.object,
    content: pt.object,
    createItemInline: pt.bool,

    createRecord: pt.func,
    onItemCreated: pt.func,
    fetchCategories: pt.func,
    loadContentModels: pt.func,
    loadContentFieldsDeprecated: pt.func,
  };

  static contextTypes = {
    notifyCreated: pt.func.isRequired,
    notifyError: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      edited: false,
      values: {},
      products: { results: [], count: 0 },
      productIndex: new Set(),
      sorting: null,
    };

    this.methods = {
      onChangeForm: this.onChangeForm,
      onSubmitRecord: this.onSubmitRecord,
      onSelectProduct: this.onSelectProduct,
      onChangeSorting: this.onChangeSorting,
      onClickRemoveProduct: this.onClickRemoveProduct,
      onMoveProductManually: this.onMoveProductManually,
    };
  }

  componentDidMount() {
    const { loadContentModels, loadContentFieldsDeprecated } = this.props;

    Promise.all([loadContentModels(), loadContentFieldsDeprecated()]);
  }

  /**
   * @param {React.ChangeEvent} event
   * @param {string} sorting
   */
  onChangeSorting = (event, sorting) => {
    this.setState((state) => ({
      sorting,
      products: {
        ...state.products,
        results: sortProducts(state.products.results, sorting),
      },
    }));
  };

  onSelectProduct = (product) => {
    this.setState((state) => {
      const isAdding = !state.productIndex.has(product.id);

      isAdding
        ? state.productIndex.add(product.id)
        : state.productIndex.delete(product.id);

      return {
        products: isAdding
          ? addProduct(state.products, product, state.sorting)
          : removeProduct(state.products, product.id),
        productIndex: state.productIndex,
      };
    });
  };

  /** @param {React.MouseEvent} event */
  onClickRemoveProduct = (event) => {
    event.preventDefault();

    const { id } = event.currentTarget.dataset;

    this.setState((state) => {
      state.productIndex.delete(id);

      return {
        products: removeProduct(state.products, id),
        productIndex: state.productIndex,
      };
    });
  };

  /**
   * @param {string} itemId
   * @param {number} sourceIndex
   * @param {number} targetIndex
   * @param {number} targetSort
   */
  onMoveProductManually = (itemId, sourceIndex, targetIndex, targetSort) => {
    this.setState((state) => ({
      products: moveProduct(state.products, sourceIndex, targetIndex),
    }));
  };

  /**
   * @param {object} values
   * @param {boolean} edited
   */
  onChangeForm = (values, edited) => {
    this.setState((state) => ({
      values: {
        ...state.values,
        ...values,
      },
      edited,
    }));
  };

  onSubmitRecord = async (values) => {
    const { router, content, createRecord } = this.props;

    const result = await createRecord({
      ...values,
      parent: undefined,
      parent_id: values.parent && values.parent.id,
      slug: values.slug.trim() !== '' ? values.slug : undefined,
      products: this.state.products.results.map((product) => ({
        product_id: product.id,
        sort: product.sort,
      })),
      content: contentUpdatesDeprecated(
        content.fieldsDeprecated.categories,
        values.content,
      ),
    });

    if (!result || result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    // create category inline: notify parent and do not redirect
    if (this.props.createItemInline) {
      if (this.props.onItemCreated) {
        this.props.onItemCreated(result);
      }

      this.context.notifyCreated(result);

      return true;
    }

    router.push(`/categories/${result.id}`);
    this.context.notifyCreated(result);

    return true;
  };

  render() {
    return <NewPage {...this.props} {...this.state} {...this.methods} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCategory);
