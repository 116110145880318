import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import auth from 'services/auth';

import actions from 'actions';

import { Form, Field } from 'components/form';
import Modal from 'components/modal';
import Loading from 'components/loading';

const modalActions = [{ label: 'Create', type: 'submit' }];

const mapStateToProps = (state) => ({
  client: state.client,
  flash: state.flash,
  session: state.session,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  createClient: (data) => {
    dispatch(actions.flash.clear());
    return dispatch(actions.client.create(data));
  },

  validateClient: (data) => {
    return dispatch(actions.client.validate(data));
  },

  fetchClient: () => {
    return dispatch(actions.client.fetch());
  },

  flashSuccess: (message) => {
    dispatch(actions.flash.success(message));
  },

  flashError: (message) => {
    dispatch(actions.flash.error(message));
  },
});

export class CreateStoreModal extends React.PureComponent {
  static contextTypes = {
    notifySuccess: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      valid: false,
      validating: false,
      submitting: false,
    };

    this.mounted = false;

    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onChangeStoreName = this.onChangeStoreName.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async onChangeStoreName(event, value) {
    const { flashError, validateClient } = this.props;

    clearTimeout(this.validateTimer);
    clearTimeout(this.validateLoadingTimer);

    if (!value) {
      this.setState({ validating: false, valid: false, errors: {} });
      return;
    }

    this.validateTimer = setTimeout(async () => {
      if (!this.mounted) {
        return;
      }

      this.validateLoadingTimer = setTimeout(() => {
        this.mounted && this.setState({ validating: true });
      }, 1000);

      const result = await validateClient({
        store_name: value,
      });

      clearTimeout(this.validateLoadingTimer);

      this.setState({ validating: false });

      if (!result) {
        flashError('Something went wrong!');
      }
      if (result.error) {
        flashError(result.error);
        return;
      }
      if (result.errors) {
        this.setState({ valid: false, errors: result.errors });
        return;
      }
      this.setState({ valid: true, errors: {} });
    }, 250);
  }

  async onSubmitForm(values) {
    const { flashError, createClient } = this.props;

    if (!this.state.valid) {
      return false;
    }

    this.setState({ submitting: true });

    const result = await createClient(values);

    if (!result || result.error || result.errors) {
      this.setState({ submitting: false });
    }

    if (!result) {
      return false;
    }
    if (result.error) {
      flashError(result.error);
      return false;
    }
    if (result.errors) {
      this.setState({ errors: result.errors });
      return false;
    }

    auth.redirectClientSessionTo('/');
  }

  render() {
    const { errors, valid, validating } = this.state;

    return (
      <Form onSubmit={this.onSubmitForm} autoFocus={true}>
        <Modal
          title="Create a new store"
          actions={modalActions}
          width={520}
          loading={this.props.loading}
          loadingTitle="Creating store"
          className="create-store-modal"
        >
          <fieldset>
            <p>
              New stores are linked to your account and can be transferred to
              other users at any time.
            </p>

            <div className="signup-name">
              <input
                type="text"
                name="store_name"
                className="hidden"
                defaultValue=""
              />

              <Field
                type="text"
                name="store_name"
                label="Store name"
                required={true}
                maxLength={32}
                warning={
                  errors.store_name &&
                  errors.store_name.message ===
                    'Store name is already taken' ? (
                    <Fragment>Store name is already taken</Fragment>
                  ) : undefined
                }
                showError={errors.store_name ? true : false}
                autoComplete="off"
                onChange={this.onChangeStoreName}
                large={true}
                valid={valid}
                validateIcon={true}
                validateBlur={true}
              />

              {validating && <Loading when={validating} />}
            </div>
          </fieldset>
        </Modal>
      </Form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateStoreModal);
