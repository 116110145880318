import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfilePage from 'components/profile';
import actions from 'actions';
import { getImageFromFile } from 'utils';
import { MAX_UPLOAD_SIZE_MB } from 'constants/files';

const mapStateToProps = (state) => ({
  user: state.user,
  loading: state.user.loading,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => {
    return dispatch(actions.user.update(data));
  },

  uploadPhoto: (image) => {
    return dispatch(actions.user.uploadPhoto(image));
  },

  notifyFileSizeUploadError: (record) => {
    dispatch(
      actions.flash.error(`File upload cannot exceed ${MAX_UPLOAD_SIZE_MB} MB`),
    );
  },

  userLogout: () => {
    dispatch(actions.user.performLogout());
  },
});

export class Profile extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
  };

  state = {
    values: {},
    edited: false,
  };

  componentWillMount() {
    const { user } = this.props;
    this.setState({ values: { ...user } });
  }

  onUploadPhoto = (files) => {
    const { uploadPhoto, notifyFileSizeUploadError } = this.props;

    const file = files[0];

    if (file.size / 1048576 >= MAX_UPLOAD_SIZE_MB) {
      notifyFileSizeUploadError();
      return;
    }

    return getImageFromFile(file).then((image) => {
      return uploadPhoto(image).then((result) => {
        if (result.errors) {
          this.context.notifyError(result.errors);
        } else {
          return this.onSubmitProfile({ photo: result });
        }
      });
    });
  };

  onChangeForm = (values, edited) => {
    this.setState({ values: { ...this.state.values, ...values }, edited });
  };

  async onSubmitProfile(values) {
    const { updateUser } = this.props;

    try {
      const result = await updateUser(values);
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        this.context.notifySuccess('Your profile has been updated');
        this.setState({
          values: { ...this.state.values, ...result },
          edited: false,
        });
      }
    } catch (err) {
      this.context.notifyError(err);
    }
  }

  onClickLogout = (event) => {
    event.preventDefault();
    const { user, userLogout, router } = this.props;
    this.context.openModal('Confirm', {
      title: `Log out ${user.name}`,
      message: <p>Are you sure you want to log out?</p>,
      action: 'Logout',
      actionType: 'danger',
      onConfirm: () => {
        userLogout();
        router.push('/');
      },
    });
  };

  render() {
    return (
      <ProfilePage
        {...this.props}
        {...this.state}
        onUploadPhoto={this.onUploadPhoto}
        onChangeForm={this.onChangeForm}
        onSubmitProfile={this.onSubmitProfile.bind(this)}
        onClickLogout={this.onClickLogout}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
