import React from 'react';
import PropTypes from 'prop-types';
import Field from 'components/form/field';
import Icon from 'components/icon';
import { localeOptions } from 'utils/geo';

export default class LocaleFieldSelect extends React.Component {
  static contextTypes = {
    client: PropTypes.object,
  };

  renderIcon(code) {
    return code ? <Icon locale={code} /> : null;
  }

  render() {
    const {
      client: { locales: clientLocales },
    } = this.context;
    const { options, defaultValue } = this.props;
    return (
      <Field
        type="select"
        name="locale"
        label="Locale"
        {...this.props}
        defaultValue={defaultValue}
        options={
          options || [
            ...(clientLocales.length > 0
              ? clientLocales.map((locale) => ({
                  value: locale.code,
                  label: locale.name,
                }))
              : localeOptions),
          ]
        }
        renderIcon={this.renderIcon}
        iconWidth={47}
      />
    );
  }
}
