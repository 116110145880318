import React from 'react';
import pt from 'prop-types';

import { formatDate } from 'utils';

function DateTime({ value, format, isCurrentTimezone }) {
  return (
    <time dateTime={value} title={value}>
      {formatDate(value, format, isCurrentTimezone)}
    </time>
  );
}

DateTime.propTypes = {
  value: pt.string.isRequired,
  format: pt.string,
  isCurrentTimezone: pt.bool,
};

export default React.memo(DateTime);
