import React from 'react';
import Icon from 'components/icon';
import IconPayment from 'components/icon/payment';
import { paymentMethod } from 'utils/order';
import User from '../utils/user';
import Date from '../utils/date';

export default function BillingUpdated({ event, billing, settings }) {
  const method = paymentMethod(settings, billing);

  if (!method) {
    return null;
  } else if (
    method.id === 'card' &&
    (!billing.card || !billing.card.brand || !billing.card.last4)
  ) {
    return null;
  }

  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/payment" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          Payment method changed
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
        <span className="activity-event-subtitle">
          <span className="activity-event-payment">
            <IconPayment method={method.id} card={billing.card} />
            &ensp;
            {method.id === 'card' ? billing.card.last4 : method.name}
          </span>
        </span>
      </div>
    </div>
  );
}
