import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import {
  Field,
  FieldLocalized,
  Fieldtable,
  FieldAmount,
} from 'components/form';
import ContentFields from 'components/content/fields';
import { FadeIn } from 'components/transitions';
import SectionHeader from 'components/section-header';
import { formatCurrency, isPrice } from 'utils';
import { renderMultiCurrencyValuesTooltip } from 'utils/money';
import {
  countryName,
  countryOptions,
  stateOptions,
  countryStateLabel,
} from 'utils/geo';

export default class ProductShippingForm extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.renderRulesHeading = this.renderRulesHeading.bind(this);
    this.renderRulesValue = this.renderRulesValue.bind(this);
    this.renderRulesForm = this.renderRulesForm.bind(this);
    this.onSubmitModal = this.onSubmitModal.bind(this);
  }

  onSubmitModal(values) {
    // reset fields that won't be used with current values.type
    if (values.type === 'price') {
      values.price = values.price || 0;
      values.fee_amount = null;
      values.fee_percent = null;
      values.fee_type = null;
    } else {
      values.price = null;
    }
  }

  renderRulesHeading() {
    return [
      <th key="1">Service</th>,
      <th key="2" className="nowrap">
        Price / Fee
      </th>,
      <th key="3">Conditions</th>,
    ];
  }

  renderRulesValue({ value, index }) {
    const { settings } = this.props;
    const { client } = this.context.client;
    const currency = this.props.record.currency || client.currency;
    const service = find(settings.shipments.services, { id: value.service });
    const serviceLabel = service
      ? service.name || value.service
      : value.service;
    const weightUnit = settings.shipments.weight_unit;

    return [
      <td key="1">{serviceLabel}</td>,
      <td key="2">
        {isPrice(value.price) && (
          <div>
            Set price to{' '}
            <b>
              {formatCurrency(value.price, currency)}
              {renderMultiCurrencyValuesTooltip(
                value.$currency,
                'price',
                currency,
              )}
            </b>
          </div>
        )}
        {!!value.fee_type && (
          <div>
            Add{' '}
            {value.fee_type === 'fixed' ? (
              <b>
                {formatCurrency(value.fee_amount, currency)}
                {renderMultiCurrencyValuesTooltip(
                  value.$currency,
                  'fee_amount',
                  currency,
                )}
              </b>
            ) : (
              <b>{value.fee_percent}%</b>
            )}
          </div>
        )}
      </td>,
      <td key="3">
        {value.country && (
          <div>
            Country is <b>{countryName(value.country)}</b>
          </div>
        )}
        {value.state && (
          <div>
            State is <b>{value.state}</b>
          </div>
        )}
        {value.zip && (
          <div>
            Postal/zip code is <b>{value.zip}</b>
          </div>
        )}
        {value.account_group && (
          <div>
            Customer group is <b>{value.account_group}</b>
          </div>
        )}
        {value.total_min && (
          <div>
            Order total greater than{' '}
            <b>
              {formatCurrency(value.total_min, currency)}
              {renderMultiCurrencyValuesTooltip(
                value.$currency,
                'total_min',
                currency,
              )}
            </b>
          </div>
        )}
        {!!value.total_max && (
          <div>
            Order total less than{' '}
            <b>
              {formatCurrency(value.total_max, currency)}
              {renderMultiCurrencyValuesTooltip(
                value.$currency,
                'total_max',
                currency,
              )}
            </b>
          </div>
        )}
        {!!value.weight_min && (
          <div>
            Shipping weight greater than{' '}
            <b>
              {value.weight_min} {weightUnit}
            </b>
          </div>
        )}
        {!!value.weight_max && (
          <div>
            Shipping weight less than{' '}
            <b>
              {value.weight_max} {weightUnit}
            </b>
          </div>
        )}
        {!value.country &&
          !value.state &&
          !value.zip &&
          !value.account_group &&
          !value.total_min &&
          !value.total_max &&
          !value.weight_min &&
          !value.weight_max && <span className="muted">None</span>}
      </td>,
    ];
  }

  renderRulesForm(values) {
    const { settings } = this.props;
    const { client } = this.context.client;
    const currency = this.props.record.currency || client.currency;
    const valueType =
      values.type || (values.fee_type !== undefined ? 'fee' : 'price');
    const shipmentServices = settings.shipments.services.filter(
      (service) => service.enabled,
    );
    const defaultService =
      values.service || (shipmentServices[0] && shipmentServices[0].id);
    const weightUnit = settings.shipments.weight_unit;

    return (
      <Fragment>
        <Field
          type="radio"
          name="type"
          buttons={true}
          defaultValue={valueType}
          readonly={true}
          options={[
            { value: 'price', label: 'Override price' },
            { value: 'fee', label: 'Add fee' },
          ]}
        />
        <div className="row">
          {valueType === 'price' && (
            <FieldLocalized
              type="currency"
              name="price"
              label="Set price"
              defaultValue={values.price}
              localeValue={values.$currency}
              currency={currency}
              className="span2"
              required={true}
            />
          )}
          {valueType === 'fee' && (
            <FieldAmount
              label="Fee amount"
              typeName="fee_type"
              fixedName="fee_amount"
              fixedValue="fixed"
              percentName="fee_percent"
              percentValue="percent"
              defaultValueType={values.fee_type}
              defaultValueFixed={values.fee_amount}
              defaultValuePercent={values.fee_percent}
              localized={true}
              localeValue={values.$currency}
              currency={currency}
              className="span2"
              required={true}
            />
          )}
          <Field
            type="number"
            name="package_quantity"
            label="Set max. package quantity"
            defaultValue={values.package_quantity}
            placeholder="Optional"
            className="span2"
          />
        </div>
        <div className="row">
          <Field
            type="select"
            name="service"
            label="Shipping service"
            defaultValue={defaultService}
            options={shipmentServices}
            required={true}
            className="span4"
          />
        </div>
        <br />
        <div className="view-body-subheader">
          <h3 className="view-body-subtitle">Conditions</h3>
          <p>Optional criteria that must be met in order to apply this rule</p>
        </div>
        <div className="row">
          <Field
            type="select"
            name="country"
            label="Country"
            defaultValue={values.country || ''}
            options={countryOptions}
            className="span2"
          />
          <Field
            type={stateOptions[values.country] ? 'select' : 'text'}
            name="state"
            label={countryStateLabel(values.country)}
            defaultValue={values.state}
            options={stateOptions[values.country]}
            className="span2"
          />
        </div>
        <div className="row">
          <Field
            type="text"
            name="zip"
            label="Postal/zip code"
            defaultValue={values.zip}
            className="span2"
          />
          <Field
            type="select"
            name="account_group"
            label="Customer group"
            defaultValue={values.account_group || ''}
            options={settings.accounts.groups}
            clearIcon
            className="span2"
          />
        </div>
        <div className="row">
          <FieldLocalized
            type="currency"
            name="total_min"
            label="Order total greater than"
            defaultValue={values.total_min}
            localeValue={values.$currency}
            currency={currency}
            className="span2"
          />
          <FieldLocalized
            type="currency"
            name="total_max"
            label="Order total less than"
            defaultValue={values.total_max}
            localeValue={values.$currency}
            currency={currency}
            className="span2"
          />
        </div>
        <div className="row">
          <Field
            type="number"
            name="weight_min"
            label={`Weight greater than (${weightUnit})`}
            defaultValue={values.weight_min}
            className="span2"
          />
          <Field
            type="number"
            name="weight_max"
            label={`Weight less than (${weightUnit})`}
            defaultValue={values.weight_max}
            className="span2"
          />
        </div>
      </Fragment>
    );
  }

  render() {
    const { record = {}, values = {}, content, settings } = this.props;

    const dims = record.shipment_dimensions || {};

    return (
      <fieldset className="full">
        <div className="view-body-subheader">
          <SectionHeader
            className="view-body-subtitle"
            title="Shipping"
            subtitle="Configure to calculate the most accurate shipping rates"
          />
        </div>
        <div className="row">
          <Field
            type="number"
            name="shipment_weight"
            label={`Weight (${settings.shipments.weight_unit})`}
            placeholder="1"
            defaultValue={record.shipment_weight}
            hint="Per quantity weight used when calculating shipping rates"
            className="span1-3rd"
          />
          <Field
            type="number"
            name="shipment_package_quantity"
            label="Max. package quantity"
            defaultValue={record.shipment_package_quantity}
            placeholder="Unlimited"
            hint="Determines how many items will fit in a single package"
            className="span1-3rd"
          />
          <div className="span1-3rd" />
        </div>
        <Field
          type="toggle"
          label="Dimensions"
          name="dimensions"
          defaultChecked={!!values.dimensions}
        />
        <FadeIn active={values.dimensions}>
          <div className="row">
            <div className="span2">
              <div className="row">
                <Field
                  type="number"
                  name="shipment_dimensions.length"
                  label="Length"
                  placeholder="L"
                  defaultValue={dims.length}
                  required={!!values.dimensions}
                  className="span1"
                />
                <Field
                  type="number"
                  name="shipment_dimensions.width"
                  label="Width"
                  placeholder="W"
                  defaultValue={dims.width}
                  required={!!values.dimensions}
                  className="span1"
                />
                <Field
                  type="number"
                  name="shipment_dimensions.height"
                  label="Height"
                  placeholder="H"
                  defaultValue={dims.height}
                  required={!!values.dimensions}
                  className="span1"
                />
                <Field
                  type="select"
                  name="shipment_dimensions.unit"
                  label={<span>&nbsp;</span>}
                  options={[
                    {
                      value: 'in',
                      label: 'in',
                    },
                    {
                      value: 'cm',
                      label: 'cm',
                    },
                  ]}
                  defaultValue={dims.unit || 'in'}
                  required={!!values.dimensions}
                  className="span1"
                />
              </div>
            </div>
            <div className="span2" />
          </div>
        </FadeIn>
        <br />
        <div className="view-body-subheader">
          <SectionHeader
            className="view-body-subtitle"
            title="Rules"
            subtitle="Override standard shipping price and package quantity, or add a special fee"
          />
        </div>
        <Fieldtable
          label="Shipping rule"
          name="shipment_prices"
          onSubmitModal={this.onSubmitModal}
          defaultValue={record.shipment_prices}
          renderHeading={this.renderRulesHeading}
          renderValue={this.renderRulesValue}
          renderForm={this.renderRulesForm}
          localized={true}
          formWidth={600}
        />
        <br />
        <ContentFields
          {...this.props}
          zone="shipping"
          collection="products"
          models={content.models}
          record={record}
          values={values}
          currency={record.currency}
        />
      </fieldset>
    );
  }
}
