import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import Icon from 'components/icon';
import { FadeIn } from 'components/transitions';
import DropdownButton from 'components/button/dropdown';
import {
  registerLocaleSelector,
  unregisterLocaleSelector,
  refreshAllLocaleSelectors,
} from 'utils/container';
import { currencySymbol } from 'utils';
import './locale.scss';

export default class LocaleSelector extends React.PureComponent {
  static contextTypes = {
    toggleLocale: PropTypes.func,
    toggleCurrency: PropTypes.func,
    account: PropTypes.object,
    client: PropTypes.object,
    user: PropTypes.object,
  };

  state = {
    localeCodes: [],
    currencyCodes: [],
    localeFieldCount: 0,
    currencyFieldCount: 0,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      localeCodes: context.user.localeCodes,
      currencyCodes: context.user.currencyCodes,
      localeFieldCount: 0,
      currencyFieldCount: 0,
    };
  }

  componentDidMount() {
    registerLocaleSelector(this);
  }

  componentWillUnmount() {
    unregisterLocaleSelector(this);
  }

  toggleLocale(code) {
    const localeCodes = this.context.toggleLocale(code);
    this.setState({ localeCodes });
    refreshAllLocaleSelectors(this, { localeCodes }, 'locale');
  }

  toggleCurrency(code) {
    const currencyCodes = this.context.toggleCurrency(code);
    this.setState({ currencyCodes });
    refreshAllLocaleSelectors(this, { currencyCodes }, 'currency');
  }

  render() {
    let {
      client: {
        locales,
        locale: clientLocale,
        pricedCurrencies,
        currency: clientCurrency,
      },
      account: { hasPricedCurrencies },
    } = this.context;
    const { localeCodes, currencyCodes, localeFieldCount, currencyFieldCount } =
      this.state;

    if (!hasPricedCurrencies) {
      pricedCurrencies = [];
    }

    if (
      (locales.length <= 1 || !localeFieldCount) &&
      (pricedCurrencies.length <= 1 || !currencyFieldCount)
    ) {
      return null;
    }

    const isLocaleActive = (locale) =>
      clientLocale === locale.code || localeCodes.indexOf(locale.code) >= 0;
    const isCurrencyActive = (currency) =>
      clientCurrency === currency.code ||
      currencyCodes.indexOf(currency.code) >= 0;

    const onClickLocale = (event, locale) => {
      event.preventDefault();
      this.toggleLocale(locale.code);
    };
    const onClickCurrency = (event, currency) => {
      event.preventDefault();
      this.toggleCurrency(currency.code);
    };

    const enabledCodes = [
      ...(localeFieldCount > 0
        ? localeCodes.filter((code) => find(locales, { code }))
        : []),
      ...(currencyFieldCount > 0
        ? currencyCodes.filter((code) => find(pricedCurrencies, { code }))
        : []),
    ];

    const baseCodeCount =
      (localeFieldCount > 0 && locales.length > 1 ? 1 : 0) +
      (currencyFieldCount > 0 && pricedCurrencies.length > 1 ? 1 : 0);

    return (
      <DropdownButton
        anchor="right"
        anchorPosition={42}
        type="sub"
        columns={[
          localeFieldCount > 0 &&
            locales.length > 0 &&
            locales.map((locale) => (
              <div
                onClick={(event) => onClickLocale(event, locale)}
                className={`locale-select ${
                  !isLocaleActive(locale) ? 'muted' : ''
                }`}
              >
                <div className="locale-check">
                  <FadeIn
                    className="check"
                    transitionAppear={false}
                    active={isLocaleActive(locale)}
                  >
                    <Icon fa="check" />
                  </FadeIn>
                </div>
                <Icon locale={locale.code} /> {locale.name}
              </div>
            )),
          currencyFieldCount > 0 &&
            pricedCurrencies.length > 0 &&
            pricedCurrencies.map((currency) => (
              <div
                onClick={(event) => onClickCurrency(event, currency)}
                className={`locale-select currency ${
                  !isCurrencyActive(currency) ? 'muted' : ''
                }`}
              >
                <div className="locale-check">
                  <FadeIn
                    className="check"
                    transitionAppear={false}
                    active={isCurrencyActive(currency)}
                  >
                    <Icon fa="check" />
                  </FadeIn>
                </div>
                {currency.code}{' '}
                <span className="locale-currency-symbol">
                  {currencySymbol(currency.code)}
                </span>
              </div>
            )),
        ]}
      >
        <>
          {localeFieldCount > 0 && locales.length > 0 && (
            <Fragment>
              <Icon locale={clientLocale} />{' '}
            </Fragment>
          )}
          {currencyFieldCount > 0 && pricedCurrencies.length > 0 && (
            <Fragment>
              <span className="locale-currency-symbol">{clientCurrency}</span>{' '}
            </Fragment>
          )}
          {enabledCodes.length > baseCodeCount && `${enabledCodes.length}`}
        </>
      </DropdownButton>
    );
  }
}
