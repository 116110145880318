export const MODAL_OPEN = 'modal/open';
export const MODAL_UPDATE = 'modal/update';
export const MODAL_REFRESH = 'modal/refresh';
export const MODAL_CLOSE = 'modal/close';

const actions = {
  open(id, props) {
    return (dispatch, getState) => {
      const { stack } = getState().modal;
      const current = stack[stack.length - 1];
      if (current && current.id === id) {
        return dispatch({
          type: MODAL_UPDATE,
          payload: { id, props },
        });
      }
      return dispatch({
        type: MODAL_OPEN,
        payload: { id, props },
      });
    };
  },

  refresh() {
    return {
      type: MODAL_REFRESH,
    };
  },

  close(id) {
    return {
      type: MODAL_CLOSE,
      payload: id,
    };
  },
};

export default actions;

export const initialState = {
  stack: [], // { id, props }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET':
      return initialState;

    case MODAL_OPEN:
      return {
        ...state,
        stack: [...state.stack, action.payload],
      };

    case MODAL_UPDATE: {
      const { id } = action.payload;

      const index = state.stack.findIndex((modal) => modal.id === id);

      if (index >= 0) {
        const stack = [...state.stack];
        stack.splice(index, 1, action.payload);

        return {
          ...state,
          stack,
        };
      }

      return state;
    }

    case MODAL_REFRESH:
      return { ...state };

    case MODAL_CLOSE: {
      if (action.payload) {
        return {
          ...state,
          stack: state.stack.filter((modal) => modal.id !== action.payload),
        };
      }

      return {
        ...state,
        stack: state.stack.slice(0, -1),
      };
    }

    default:
      return state;
  }
}
