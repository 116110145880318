/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { isLiveMode } from 'utils/payment';

export default class PayPalCommerceOnboardingModal extends React.PureComponent {
  static propTypes = {
    paypalMethod: PropTypes.object,
    onClickEdit: PropTypes.func,
    onClose: PropTypes.func,
  };

  render() {
    const { paypalMethod, onClickEdit, onClose } = this.props;
    const { mode } = paypalMethod;
    const merchantId = paypalMethod[`${mode}_merchant_id`];
    const isLive = isLiveMode(mode);

    return (
      <Modal
        title="PayPal activated"
        actions={[
          {
            label: 'Close',
            type: 'submit',
            onClick: onClose,
          },
          {
            component: (
              <a
                href=""
                className="button button-sm button-secondary float-begin"
                onClick={onClickEdit}
              >
                Edit settings
              </a>
            ),
          },
        ]}
        loading={!merchantId}
        width={500}
        cancel={false}
        onClose={onClose}
      >
        <fieldset>
          <p>Your PayPal business account has been connected.</p>
          <p>
            {isLive ? (
              <span className="positive">Live mode</span>
            ) : (
              <span className="muted">Sandbox mode</span>
            )}
            <br />
            Merchant ID: <span className="monospaced">{merchantId}</span>
          </p>
        </fieldset>
      </Modal>
    );
  }
}
