import React from 'react';
import Slider from 'rc-slider';
import Label from './label';
import 'rc-slider/assets/index.css';

export default class InputSlider extends React.Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      value: this.getInitialValue(props),
      realMin: this.getRealMinValue(props),
    };
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState({ value: this.getInitialValue(nextProps) });
    }
    if (nextProps.value !== this.props.value) {
      this.setState({ value: this.getInitialValue(nextProps) });
    }
    if (
      nextProps.min !== this.props.min ||
      nextProps.marks !== this.props.marks
    ) {
      this.setState({ realMin: this.getRealMinValue(nextProps) });
    }
  }

  getInitialValue(props) {
    const value = this.valueAdjusted(props.value || props.defaultValue, props);
    return Number(value);
  }

  getRealMinValue(props) {
    if (props.marks) {
      return +Object.keys(props.marks)[0];
    }
    return props.min;
  }

  value() {
    return this.state.value;
  }

  valueAdjusted(value, props = this.props) {
    const { min, max } = this.props;
    if (max !== undefined && value > max) {
      return max;
    } else if (min !== undefined && value < min) {
      return min;
    } else if (value === undefined) {
      return min;
    }
    return value;
  }

  onChange(val) {
    const value = this.valueAdjusted(val);
    this.setState({ value });
    const event = new Event('change', { bubbles: true });
    this.props.onChange(event, value);
  }

  render() {
    const {
      id,
      label,
      help,
      max,
      step,
      marks,
      disabled,
      defaultValue,
      className,
    } = this.props;
    const { value, realMin } = this.state;

    return (
      <span className="form-slider">
        {label && <Label label={label} help={help} htmlFor={id} />}
        <span
          className={`form-field-input ${
            marks &&
            (value === realMin
              ? 'form-slider-min-value'
              : value === max
              ? 'form-slider-max-value'
              : '')
          }`}
        >
          <Slider
            id={id}
            min={realMin}
            max={max}
            step={step}
            marks={marks}
            disabled={disabled}
            {...(value < realMin ? { value: realMin } : {})}
            defaultValue={defaultValue}
            onAfterChange={this.onChange}
            className={`${disabled ? 'disabled' : ''} ${className || ''}`}
          />
        </span>
      </span>
    );
  }
}
