import React, { Fragment } from 'react';
import { get, isEqual, pick, find, startCase, sumBy } from 'lodash';

import { arrayToObject, isEmpty } from 'utils';
import { getVariantByOptions, isTrialItem } from 'utils/product';
import { truncatedText } from 'utils/collection';
import { calculateBundleItemValues } from 'shared/utils';

import IconPayment from 'components/icon/payment';

const { API_PORT } = process.env;

export { calculateBundleItemValues };

export const ADDRESS_PROPS = Object.freeze([
  'name',
  'first_name',
  'last_name',
  'address1',
  'address2',
  'city',
  'state',
  'zip',
  'country',
  'phone',
  'company',
]);

export const EMPTY_ADDRESS = Object.freeze(
  ADDRESS_PROPS.reduce((acc, field) => {
    acc[field] = null;

    return acc;
  }, {}),
);

// Get payment due amount
export function paymentDue(order) {
  if (!order.canceled && order.payment_balance < 0) {
    return -order.payment_balance;
  }
}

// Get refund due amount
export function refundDue(order) {
  if (order.canceled) {
    return order.payment_total - order.refund_total;
  }
  if (order.payment_balance > 0) {
    return order.payment_balance;
  }
}

/**
 * Get payment status description
 *
 * @param {object} order
 */
export function paymentStatus(order) {
  if (refundDue(order)) {
    return 'Refund due';
  }

  if (
    order.payment_total > 0 &&
    order.payment_total > order.refund_total &&
    order.payment_total < order.grand_total &&
    paymentDue(order)
  ) {
    return 'Partially paid';
  }

  if (order.refund_total > 0) {
    if (order.refund_total < order.payment_total) {
      return 'Partially refunded';
    }

    if (order.refund_total === order.payment_total) {
      return 'Refunded';
    }
  }

  if (order.payment_total > 0 && order.payment_balance === 0) {
    return 'Paid';
  }

  if (order.grand_total > 0) {
    return 'Unpaid';
  }

  // If zero grand total and no payment was made
  return 'Free';
}

/**
 * Get fulfillment status description
 *
 * @param {object} order
 */
export function fulfillmentStatus(order) {
  if (order.canceled) {
    return 'Canceled';
  }

  if (order.hold || order.status === 'hold') {
    return 'On hold';
  }

  if (order.delivered) {
    if (order.item_quantity_returned > 0 || order.return_total > 0) {
      if (order.item_quantity_returned === order.item_quantity) {
        return 'Returned';
      }

      return 'Partially returned';
    }

    return 'Fulfilled';
  }

  if (order.item_quantity_delivered > 0) {
    return 'Partially fulfilled';
  }

  if (order.item_quantity_deliverable > 0) {
    return 'Unfulfilled';
  }

  return null;
}
/**
 * Checks if shipping.use_account dictates to use account address
 * @param {object} shipping - shipping object
 * @returns {boolean} - Returns true if account address should be used or false othwerwise
 */
export function shouldUseAccountShippingAddress(shipping) {
  if (!shipping) {
    return false;
  }

  // for legacy reasons undefined is equal to true
  if (shipping.use_account === true || shipping.use_account === undefined) {
    return true;
  }

  return false;
}

/**
 * Checks if billing.use_account dictates to use account address
 * @param {object} billing - billing object
 * @returns {boolean} - Returns true if account address should be used or false othwerwise
 */
export function shouldUseAccountBillingAddress(billing) {
  return billing?.use_account === true;
}

/**
 * Determine if objects contained the same address
 *
 * @param {object} left
 * @param {object} right
 * @returns {boolean}
 */
export function isSameAddress(left, right) {
  return isEqual(pick(left, ADDRESS_PROPS), pick(right, ADDRESS_PROPS));
}

/**
 * Get address fields from record
 *
 * @param {object} record
 * @returns {object}
 */
export function getAddressFromRecord(record = {}) {
  return pick(record, ADDRESS_PROPS);
}

/**
 * Get billing from record
 *
 * @param {object} record
 * @returns {object}
 */
export function getRecordBilling(record = {}) {
  const accountBilling = record.account?.billing ?? {};
  const recordBilling = record.billing ?? {};

  if (isEmpty(recordBilling)) {
    return accountBilling;
  }

  if (shouldUseAccountBillingAddress(recordBilling)) {
    return {
      ...recordBilling,
      ...accountBilling,
    };
  }

  return recordBilling;
}

// Get payment gateway name
export function paymentMethod(settings, billing = {}) {
  if (billing && !billing.method) {
    if (billing.card) {
      billing.method = 'card';
    } else if (billing.paypal) {
      billing.method = 'paypal';
    } else if (billing.amazon) {
      billing.method = 'amazon';
    } else if (billing.affirm) {
      billing.method = 'affirm';
    } else if (billing.resolve) {
      billing.method = 'resolve';
    } else if (billing.klarna) {
      billing.method = 'klarna';
    } else if (billing.ideal) {
      billing.method = 'ideal';
    } else if (billing.bancontact) {
      billing.method = 'bancontact';
    } else if (billing.google) {
      billing.method = 'google';
    } else if (billing.apple) {
      billing.method = 'apple';
    } else if (billing.twint) {
      billing.method = 'twint';
    } else if (billing.paysafecard) {
      billing.method = 'paysafecard';
    }
  }

  if (billing && billing.method) {
    const method = {
      ...(find(settings.payments.methods, { id: billing.method }) || {}),
    };

    if (billing.method === 'paypal') {
      method.name = 'PayPal';
    }

    if (method && billing.method === 'card') {
      const billingGateway = billing.card && billing.card.gateway;
      const gatewayId = billingGateway || method.gateway;
      const gateway = find(get(settings, 'payments.gateways'), {
        id: gatewayId,
      });
      if (gateway && gateway.name) {
        method.gateway = gateway;
      }
    }
    return method;
  }

  return undefined;
}

// Get shipping service name
export function shippingService(settings, shipping = {}) {
  if (shipping && shipping.service) {
    const service = {
      ...(find(get(settings, 'shipments.services'), { id: shipping.service }) ||
        {}),
    };
    if (service && !service.name) {
      service.name = service.id;
    }
    return service;
  }
  return undefined;
}

// Get items with delivery type shipment
export function getShipmentItems(items = []) {
  return (items || []).reduce((result, item) => {
    if (item.bundle_items) {
      const bundleItemValues = calculateBundleItemValues(item);

      for (const bundleItem of item.bundle_items) {
        // skip items w/o shipment and canceled
        if (bundleItem.delivery === 'shipment' && item.quantity_total > 0) {
          result.push({
            ...bundleItem,
            id: item.id,
            bundle_item_id: bundleItem.id,
            quantity: item.quantity_total * bundleItem.quantity,
            price: bundleItemValues[bundleItem.id].price,
            discount_each: bundleItemValues[bundleItem.id].discount,
            tax_each: bundleItemValues[bundleItem.id].tax,
          });
        }
      }
    } else if (item.delivery === 'shipment') {
      result.push({ ...item });
    }

    return result;
  }, []);
}

export function getShippableItems(items = [], exItems) {
  const shippableItems = getShipmentItems(items);

  // Set quantities from shipment record if applicable
  if (exItems) {
    // Set quantities for shipped items
    for (const exItem of exItems) {
      const exShippableItem = shippableItems.find((item) => {
        if (item.id !== exItem.order_item_id) {
          return false;
        }
        if (item.bundle_item_id !== exItem.bundle_item_id) {
          return false;
        }
        return true;
      });

      if (exShippableItem) {
        exShippableItem.quantity_deliverable = exItem.quantity;
      } else {
        shippableItems.push({
          ...exItem,
          id: exItem.order_item_id,
          quantity_deliverable: exItem.quantity,
          order_item_id: undefined,
        });
      }
    }

    // Set all others to zero
    for (const shippableItem of shippableItems) {
      const exItem = exItems.find((item) => {
        if (item.order_item_id !== shippableItem.id) {
          return false;
        }
        if (item.bundle_item_id !== shippableItem.bundle_item_id) {
          return false;
        }
        return true;
      });

      if (!exItem) {
        shippableItem.quantity_deliverable = 0;
      }
    }
  }

  return shippableItems;
}

export function getReturnableItems(items = [], exItems) {
  const returnableItems = getShipmentItems(items);

  // Set quantities from return record if applicable
  if (exItems) {
    // Set quantities for returned items
    for (const exItem of exItems) {
      const exReturnableItem = find(returnableItems, (item) => {
        if (item.id !== exItem.order_item_id) {
          return false;
        }
        if (item.bundle_item_id !== exItem.bundle_item_id) {
          return false;
        }
        return true;
      });
      if (exReturnableItem) {
        exReturnableItem.quantity_returnable = exItem.quantity;
      } else {
        returnableItems.push({
          ...exItem,
          id: exItem.order_item_id,
          quantity_returnable: exItem.quantity,
          order_item_id: undefined,
        });
      }
    }
  }

  return returnableItems.filter((item) => item.quantity_returnable > 0);
}

/**
Returns an array of returned items with their corresponding quantity and restocked quantity.

* @param {Array} itemValues - An array of items with their corresponding quantity and restocked quantity.
* @param {Boolean} restock - A boolean value indicating whether or not restocking is required.
* @param {Array} returnableItems - An array of returnable items with their corresponding product, variant, and bundle item IDs.
* @returns {Array} An array of returned items with their corresponding quantity and restocked quantity.
*/
export function getReturnedItems(itemValues, restock, returnableItems) {
  const finalItems = itemValues
    .map((item, index) => {
      const { id, product_id, variant_id, bundle_item_id } =
        returnableItems[index];

      let quantityRestocked = 0;

      if (restock) {
        quantityRestocked =
          typeof item.quantity_restocked === 'number'
            ? item.quantity_restocked
            : item.quantity || 0;
      }

      return {
        product_id,
        variant_id,
        bundle_item_id,
        order_item_id: id,
        quantity: item.quantity,
        quantity_restocked: quantityRestocked,

        // TODO: remove this when we have separated receiving from authorization
        quantity_received: item.quantity,
      };
    })
    .filter((i) => i.quantity > 0);
  return finalItems;
}

// Determine if items are equivalent
export function isItemEqual(item1, item2) {
  for (let key of ['product_id', 'variant_id', 'options']) {
    if (item1[key] !== item2[key]) {
      return false;
    }
  }
  return true;
}

export function formatPaymentBrand(brand) {
  return startCase(brand);
}

// Render payment/card description with icon
export function renderPaymentSourceDetails(
  billing = {},
  specifiedMethod = null,
  short = false,
) {
  const method =
    specifiedMethod ||
    (billing.method ? billing.method : billing.card && 'card');

  switch (method) {
    case 'card': {
      const card = billing.card || {};
      const { brand, last4, exp_month, exp_year } = card;

      if (brand) {
        return (
          <Fragment>
            <IconPayment method="card" card={card} />
            &ensp;
            {short ? (
              <Fragment>{`${formatPaymentBrand(brand)} ${last4}`}</Fragment>
            ) : (
              <Fragment>
                {`${formatPaymentBrand(brand)} ending in ${last4}`}{' '}
                <span className="muted">
                  {`(expires ${exp_month}/${exp_year})`}
                </span>
              </Fragment>
            )}
          </Fragment>
        );
      }

      return 'No card on file';
    }

    case 'account':
      return 'Pay by invoice';

    default:
      return <span>&mdash;</span>;
  }
}

// Render payment/card description with icon
export function renderPaymentMethodDescription(
  settings,
  payment = {},
  maxlength = 0,
) {
  const { method, card, giftcard } = payment;
  switch (method) {
    case 'card':
      if (card) {
        const { brand, last4 } = card;
        if (brand) {
          return (
            <Fragment>
              <IconPayment method={method} card={card} />
              {truncatedText(
                `${formatPaymentBrand(brand)} ${last4}`,
                maxlength,
              )}
            </Fragment>
          );
        }
        return (
          <Fragment>
            <IconPayment method={method} card={card} />
            {truncatedText(`Credit card ${last4}`, maxlength)}
          </Fragment>
        );
      }
      return (
        <Fragment>
          <IconPayment method={method} />
          Credit card
        </Fragment>
      );
    case 'amazon':
    case 'paypal':
    case 'affirm':
    case 'resolve':
    case 'klarna':
    case 'bancontact':
    case 'google':
    case 'apple':
    case 'twint':
    case 'ideal':
    case 'paysafecard':
    case 'cash':
    case 'cash_on_delivery':
    case 'account': {
      const altMethod = paymentMethod(settings, payment);

      return (
        <Fragment>
          <IconPayment method={method} />
          {truncatedText(altMethod.name || method, maxlength)}
        </Fragment>
      );
    }

    case 'giftcard':
      return (
        <Fragment>
          <IconPayment method={method} />
          {truncatedText(
            giftcard ? `Gift card ${giftcard.code.substr(-4, 4)}` : 'Gift card',
            maxlength,
          )}
        </Fragment>
      );

    default: {
      const defaultMethod = paymentMethod(settings, payment);

      if (defaultMethod) {
        return (
          <Fragment>
            <IconPayment method={method} />
            {truncatedText(defaultMethod.name || method, maxlength)}
          </Fragment>
        );
      }

      return <Fragment>&mdash;</Fragment>;
    }
  }
}

export function orderPaymentMethodValid(billing = {}) {
  if (billing && billing.method) {
    switch (billing.method) {
      case 'card':
        return Boolean(billing.card || billing.account_card_id);

      default:
        return true;
    }
  }

  return false;
}

// Render shipment address description
export function renderShipmentAddressDescription(shipment = {}) {
  const {
    destination: { address1, address2, city, state, zip, country },
  } = shipment;
  return `
    ${address1}
    ${address2 ? ` ${address2}` : ''}${city ? `, ${city}` : ''}${
    state ? `, ${state}` : ''
  }${zip ? ` ${zip}` : ''}${country ? ` (${country})` : ''}
  `.trim();
}

// Render shipping weight description
export function renderShipmentWeightDescription(settings, weight) {
  const unit = settings.shipments.weight_unit;
  return `${weight} ${unit}`;
}

// Get an array of clean product options, removing the junk I guess
export function getCleanItemOptions(product, options, presetOptions) {
  if (!options) {
    return presetOptions;
  }

  const productOptions = arrayToObject(product.options);

  const existsOptions = new Set();
  const cleanOptions = [];

  if (Array.isArray(presetOptions)) {
    cleanOptions.push(...presetOptions);

    for (const { id } of presetOptions) {
      existsOptions.add(id);
    }
  }

  for (const option of options) {
    const productOption = option && productOptions[option.id];

    if (productOption && !existsOptions.has(productOption.id)) {
      const productOptionValues = arrayToObject(productOption.values);

      const productOptionValue =
        productOptionValues[option.value] ||
        find(productOptionValues, (optionValue) =>
          [optionValue.id, optionValue.name].includes(option.value),
        );

      cleanOptions.push({
        id: productOption.id,
        name: productOption.name,
        value: productOptionValue ? productOptionValue.name : option.value,
        value_id: productOptionValue ? productOptionValue.id : null,
        variant: productOption.variant,
      });
    }
  }

  return cleanOptions;
}

// remove excess fields to prevent unwanted overwrites on updates
export function getCleanBundleItems(bundleItems) {
  if (isEmpty(bundleItems)) {
    return undefined;
  }
  return bundleItems.map((item) => ({
    id: item.id,
    product: undefined,
    product_id: item.product_id,
    variant: undefined,
    variant_id: item.variant_id,
    options: item.options,
  }));
}

export function getItemPrice(item) {
  return item.price + (item.tax_each || 0) - (item.discount_each || 0);
}

export function getItemPriceTotal(item) {
  const quantity =
    (item.quantity || 0) -
    (item.quantity_returned || 0) -
    (item.quantity_canceled || 0);
  return quantity * getItemPrice(item);
}

export function getItemsPriceTotal(items = []) {
  return sumBy(items, getItemPriceTotal) || 0;
}

/**
 * @param {object} values
 * @param {object} options
 * @param {object} callbacks
 * @returns {Promise<any>}
 */
export async function prepareOrderItemFromModalData(
  values,
  options,
  callbacks,
) {
  const {
    addItemProduct,
    editAddItem,
    editAddIndex,
    showEditItems,
    editItems,
    getItemPrice,
  } = options;

  const itemProduct = editAddItem ? editAddItem.product : addItemProduct;

  const addItem = {
    ...values,
    quantity: values.quantity || 1,
    product: itemProduct,
    product_id: itemProduct.id,
    bundle_items: itemProduct.bundle_items,
    options: getCleanItemOptions(itemProduct, values.options),
  };

  if (values.bundle_options) {
    addItem.bundle_items = itemProduct.bundle_items.map((item) => ({
      ...item,
      options: getCleanItemOptions(
        item.product,
        values.bundle_options[item.product_id],
      ),
    }));
  }

  if (addItem.set_price === null) {
    callbacks.onLoading();

    const pricedItem = await getItemPrice(
      pick(addItem, [
        'id',
        'product_id',
        'options',
        'quantity',
        'purchase_option',
      ]),
    );

    if (pricedItem && !pricedItem.errors) {
      addItem.price = pricedItem.price;

      if (!showEditItems) {
        addItem.set_price = pricedItem.price;
      }
    }
  } else {
    addItem.price = addItem.set_price;
  }

  if (showEditItems) {
    if (
      addItem.purchase_option &&
      !addItem.purchase_option.type &&
      addItem.purchase_option.plan_id
    ) {
      const plan = addItem.product.purchase_options.subscription.plans.find(
        (plan) => plan.id === addItem.purchase_option.plan_id,
      );

      if (plan) {
        addItem.purchase_option = {
          ...addItem.purchase_option,
          type: 'subscription',
          billing_schedule: plan.billing_schedule,
        };
      }
    }

    addItem.variant = getVariantByOptions(addItem.product, addItem.options);

    const editItemsList = [...editItems];

    if (addItem.id) {
      const editIndex = editItemsList.findIndex(
        (item) => item.id === addItem.id,
      );
      editItemsList[editIndex] = { ...editItemsList[editIndex], ...addItem };
    } else if (editAddIndex !== null) {
      editItemsList[editAddIndex] = addItem;
    } else {
      editItemsList.push(addItem);
    }

    return callbacks.onEditItems(editItemsList);
  }

  return callbacks.onAddItem(addItem);
}

export function makeOrderItem(item) {
  return {
    product_id: item.product_id,
    variant_id: item.variant_id,
    description: item.description,
    recurring: item.recurring,
    options: item.options,
    quantity: item.quantity,
    price: item.set_price,
    purchase_option: item.purchase_option,
    bundle_items: getCleanBundleItems(item.bundle_items),
    date_trial_end: item.date_trial_end,
  };
}

export function addTrialUpcomingPayments(record) {
  const { items = [], billing = {}, payment_balance } = record || {};
  const payments = (record.payments && record.payments.results) || [];
  const { method } = billing;
  const methodData = billing[method];

  if (!payments.length || !methodData) {
    return;
  }

  // Calculate the available balance to pay off upcoming payments.
  // given:
  //   * balance = payment_total - (grand_total - amount of unpaid trial items);
  //   * payment_balance = payment_total - grand_total, then
  // then:
  //   * balance = payment_balance + amount of unpaid trial items
  const unpaidTrialItems = items.reduce((acc, item, index) => {
    if (!item.paid && isTrialItem(item)) {
      acc.push({ ...item, index });
    }
    return acc;
  }, []);
  let balance =
    payment_balance +
    unpaidTrialItems.reduce((acc, item) => acc + getItemPriceTotal(item), 0);

  // get trial items for upcoming payments
  //
  // upcoming payments should be created for unpaid trial items that contain date_trial_end field
  // also need to sort the items to apply the available balance in the correct order
  const upcomingItems = unpaidTrialItems
    .filter((item) => Boolean(item.date_trial_end))
    .sort((a, b) => a.date_trial_end - b.date_trial_end);

  // create upcoming payments
  upcomingItems.forEach((item) => {
    if (item.paid === false && !item.payment_error) {
      // if the item is unpaid and there is no payment error,
      // it means that async payment is pending confirmation.
      return;
    }

    const itemPriceTotal = getItemPriceTotal(item);

    let amount;
    if (balance <= 0) {
      // No available balance. Full amount is must be paid.
      amount = itemPriceTotal;
    } else if (itemPriceTotal > balance) {
      // Balance partially covers the item price. Partial amount must be paid.
      amount = itemPriceTotal - balance;
      balance = 0;
    } else {
      // Balance fully covers the remaining amount. No payment required.
      balance -= itemPriceTotal;
      return;
    }

    // must be inserted at the beginning of the array
    // to display upcoming payments in the correct order
    record.payments.results.unshift({
      id: `upcoming-${item.id}`,
      item_id: item.id,
      item_index: item.index,
      upcoming: true,
      date_created: item.date_trial_end,
      amount,
      method,
      [method]: methodData,
      help: `${item.quantity} × ${item.product_name}`,
      error: item.payment_error,
    });
  });
}

// returns an object with arrays of items grouped by type
export function getItemGroups(items) {
  if (!items || !items.length) {
    return {};
  }

  return items.reduce((acc, item, index) => {
    item.index = index;
    if (isTrialItem(item)) {
      acc.trial = acc.trial || [];
      acc.trial.push(item);
    } else {
      acc.standard = acc.standard || [];
      acc.standard.push(item);
    }
    return acc;
  }, {});
}

export function getCheckoutUrl(checkoutId, customUrl, testEnv = '') {
  if (!customUrl) {
    const testSegment = testEnv
      ? testEnv === 'test'
        ? 'test/'
        : `test-${testEnv}/`
      : '';
    return `${window.location.origin.replace(
      /:[0-9]+$/,
      `:${API_PORT}`,
    )}/checkout/${testSegment}${checkoutId}`;
  }

  return customUrl.replace('{checkout_id}', checkoutId);
}

export function makeAddressString(address = {}) {
  return [
    address.address1,
    address.address2,
    address.city,
    address.state,
    address.zip,
    address.country,
  ]
    .filter(Boolean)
    .join(', ');
}

export function isExpired(month, year) {
  // Create a new date for the last day of the given expiry month and year
  const expiryDate = new Date(Date.UTC(year, month, 0));

  // Get the current date
  const currentDate = new Date();

  // Set the current date to UTC and to the end of the month
  currentDate.setUTCDate(0);
  currentDate.setUTCHours(23, 59, 59, 999);

  // Compare the two dates
  return currentDate > expiryDate;
}
