import React from 'react';
import ReactDOM from 'react-dom';

export default class Legend extends React.PureComponent {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.el = document.getElementById(
      'chart-legend-viewport-' + this.props.chartId,
    );
  }

  renderLegend() {
    const { data, pointColors } = this.props;

    return (
      <div className="chart-legend-container">
        {data.map(({ id, description }) => (
          <span className="chart-legend-item" key={id}>
            <div
              className="chart-legend-color-box"
              style={{ backgroundColor: pointColors[id] }}
            />
            {description || id}
          </span>
        ))}
      </div>
    );
  }

  render() {
    return ReactDOM.createPortal(this.renderLegend(), this.el);
  }
}
