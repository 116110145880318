import React, { PureComponent } from 'react';
import Button from 'components/button';
import DropdownButton from 'components/button/dropdown';
import { FadeIn } from 'components/transitions';
import Field from 'components/form/field';
import Icon from 'components/icon';
import User from '../utils/user';
import Date from '../utils/date';

export default class NoteAdded extends PureComponent {
  state = {
    edit: false,
    value: null,
  };

  onChangeNote = (event, value) => {
    this.setState({ value });
  };

  onClickEditNote = (e) => {
    e.preventDefault();
    this.setState({ edit: true });
  };

  onClickCancelEdit = () => {
    this.setState({ edit: false, value: null });
  };

  onClickSaveNote = () => {
    const { event, onSaveNote } = this.props;
    const { value } = this.state;
    onSaveNote && onSaveNote(event.id, value);
    this.setState({ edit: false });
  };

  onClickDeleteNote = (e) => {
    e.preventDefault();
    const { event, onDeleteNote } = this.props;
    onDeleteNote && onDeleteNote(event.id);
  };

  render() {
    const { value, edit } = this.state;
    const { event, user } = this.props;

    const isSuperAdmin = user.client_id === 'admin';
    const isSelf = event.user && event.user.id === user.id;

    return (
      <div key={event.id} className="activity-event">
        <a name="notes" />
        <div className="activity-event-icon">
          <Icon type="events/note-added" />
        </div>
        <div className="activity-event-info">
          <div className="activity-event-title">
            <span className="bold">Note</span>
            <User user={event.user} />
            &nbsp;
            <Date date={event.date_created} />
            {(isSuperAdmin || isSelf) && (
              <DropdownButton
                className="activity-event-note-actions"
                arrow={false}
                anchor="right"
                anchorPosition={40}
                items={[
                  !edit && (
                    <a key="1" href="" onClick={this.onClickEditNote}>
                      Edit note
                    </a>
                  ),
                  <a key="2" href="" onClick={this.onClickDeleteNote}>
                    Delete note
                  </a>,
                ]}
              >
                ...
              </DropdownButton>
            )}
          </div>
          <div className="activity-event-details activity-event-details-note">
            {edit ? (
              <FadeIn>
                <Field
                  type="textarea"
                  name="note"
                  defaultValue={event.body}
                  onChange={this.onChangeNote}
                  autoFocus={true}
                  autoSize={true}
                  rows={1}
                />
                <div className="activity-event-details-note-actions">
                  <Button size="xs" onClick={this.onClickSaveNote}>
                    Save
                  </Button>
                  <Button
                    type="cancel"
                    size="xs"
                    onClick={this.onClickCancelEdit}
                  >
                    Cancel
                  </Button>
                </div>
              </FadeIn>
            ) : (
              <div className="body">{value || event.body}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
