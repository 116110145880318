import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import './secret.scss';

export default class Secret extends React.PureComponent {
  static contextTypes = {
    text: PropTypes.string,
    numVisible: PropTypes.number,
  };

  state = {
    revealed: false,
  };

  onClickReveal = (event) => {
    event.preventDefault();
    this.setState({ revealed: !this.state.revealed });
  };

  render() {
    const { text = '', numVisible = 5 } = this.props;
    const { revealed } = this.state;

    const value = String(text);
    if (!value.length) {
      return null;
    }

    let repeatLength = value.length - numVisible - 1;
    if (repeatLength > numVisible * 2) {
      repeatLength = numVisible * 2;
    }

    return (
      <span className="secret">
        <code>
          {revealed ? (
            value
          ) : (
            <Fragment>
              {value.slice(0, numVisible)}
              &nbsp;
            </Fragment>
          )}
          {repeatLength > 0 && (
            <a href="" onClick={this.onClickReveal}>
              {revealed ? (
                <Fragment>
                  &nbsp;
                  <span className="muted">
                    <Icon fa="eye-slash" />
                  </span>
                </Fragment>
              ) : (
                <Fragment>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: '&bull;'.repeat(repeatLength + 1),
                    }}
                  />
                  &nbsp;&nbsp;
                </Fragment>
              )}
            </a>
          )}
        </code>
      </span>
    );
  }
}
