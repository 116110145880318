import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduce } from 'lodash';

import { currencyValue } from 'utils';
import { defaultVariantPrice } from 'utils/product';

import { Form, Field, FieldLocalized } from 'components/form';
import ContentFields from 'components/content/fields';
import Modal from 'components/modal';

import ProductPrices from './prices';
import PurchaseOptions from './purchase-options';
import ProductAttributesForm from './form-attributes';

import './product.scss';

export default class ProductVariantEditModal extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  onCloseModal = (event) => {
    if (this.props.variantEdited) {
      event.preventDefault();
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.props.onClickEditVariant(event);
        },
      });
    } else {
      this.props.onClickEditVariant(event);
    }
  };

  multiCurrencyPlaceholder($currency, field) {
    return reduce(
      $currency,
      (acc, values, code) => ({
        ...acc,
        [code]: currencyValue(values[field], code),
      }),
      {},
    );
  }

  renderStandardPurchaseOption() {
    const { record = {}, values = {}, settings, variantValues } = this.props;
    const { client } = this.context;
    const currency = record.currency || client.currency;

    const defaultPrice = defaultVariantPrice(
      record,
      variantValues,
      values.price,
    );

    return (
      <PurchaseOptions
        type="standard"
        name="purchase_options"
        record={variantValues}
        values={variantValues}
        currency={currency}
        settings={settings}
        variantProps={{
          record,
          values: {
            ...values,
            price: defaultPrice,
          },
          pricePlaceholder: currencyValue(defaultPrice, currency),
          priceLocalePlaceholder: this.multiCurrencyPlaceholder(
            record.$currency,
            'price',
          ),
          salePricePlaceholder: currencyValue(record.sale_price, currency),
          salePriceLocalePlaceholder: this.multiCurrencyPlaceholder(
            record.$currency,
            'sale_price',
          ),
        }}
      />
    );
  }

  render() {
    const {
      record = {},
      values = {},
      settings,
      loading,
      features,
      content,
      variantValues,
      attributeSetAttributes,
      onSubmitEditVariant,
      onChangeVariantForm,
    } = this.props;

    const { client } = this.context;

    const currency = record.currency || client.currency;

    return (
      <Form
        onSubmit={onSubmitEditVariant}
        onChange={onChangeVariantForm}
        autoFocus={true}
      >
        <Modal
          title="Edit variant"
          className="product-modal-variant-edit"
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'secondary',
              className: 'button-cancel',
              onClick: this.onCloseModal,
            },
          ]}
          cancel={false}
          onClose={this.onCloseModal}
          width={900}
          loading={loading}
          localized={true}
          devtools={{
            model: 'products',
            uri: `${record.id}/variants/${variantValues.id}`,
            zone: 'variant-edit',
          }}
        >
          <fieldset>
            <div className="row">
              <FieldLocalized
                type="text"
                label="Name"
                name="name"
                defaultValue={variantValues.name}
                localeValue={variantValues.$locale}
                required={true}
                className={features.inventory ? 'span3' : 'span4'}
              />

              {features.inventory && (
                <Field
                  type="text"
                  label="SKU"
                  name="sku"
                  defaultValue={variantValues.sku}
                  placeholder={record.sku || 'Optional'}
                  className="span1"
                />
              )}
            </div>

            <div className="row">
              <Field
                type="toggle"
                name="active"
                label="Active"
                defaultChecked={variantValues.active}
                className="span2"
                help="An active variant is visible in store listings"
              />
            </div>

            {settings.products.features.cost_tracking && (
              <div className="row">
                <Field
                  type="currency"
                  label="Cost"
                  name="cost"
                  defaultValue={variantValues.cost}
                  placeholder={currencyValue(record.cost, currency)}
                  currency={currency}
                  className="span1"
                />
              </div>
            )}
          </fieldset>
          <div className="view-body-section-group">
            {values.deprecated_subscription_option ? (
              <Fragment>
                <fieldset>
                  <div className="view-body-subheader">
                    <h3 className="view-body-subtitle">Pricing</h3>

                    <div className="view-body-actions">
                      <Field
                        type="toggle"
                        label="On sale"
                        name="sale"
                        defaultChecked={variantValues.sale}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <FieldLocalized
                      type="currency"
                      label="List price"
                      name="price"
                      defaultValue={variantValues.price}
                      placeholder={currencyValue(
                        defaultVariantPrice(record, variantValues),
                        currency,
                      )}
                      currency={currency}
                      localeValue={variantValues.$currency}
                      localePlaceholder={this.multiCurrencyPlaceholder(
                        record.$currency,
                        'price',
                      )}
                      selectFocus={true}
                      className="span1-3rd"
                    />

                    <FieldLocalized
                      type="currency"
                      label="Sale price"
                      name="sale_price"
                      defaultValue={variantValues.sale_price}
                      placeholder={currencyValue(record.sale_price, currency)}
                      currency={currency}
                      localeValue={variantValues.$currency}
                      localePlaceholder={this.multiCurrencyPlaceholder(
                        record.$currency,
                        'sale_price',
                      )}
                      selectFocus={true}
                      className="span1-3rd"
                    />

                    <div className="span1-3rd" />
                  </div>
                </fieldset>

                <ProductPrices
                  name="prices"
                  values={variantValues.prices}
                  currency={currency}
                  settings={settings}
                />
              </Fragment>
            ) : (
              this.renderStandardPurchaseOption()
            )}

            {attributeSetAttributes.length > 0 && (
              <ProductAttributesForm
                {...this.props}
                record={variantValues}
                isVariant={true}
              />
            )}
          </div>

          <ContentFields
            {...this.props}
            zone="variant-edit"
            root="variants"
            collection="products"
            models={content.models}
            record={variantValues}
            values={variantValues}
            currency={currency}
          />
        </Modal>
      </Form>
    );
  }
}
