import React from 'react';
import { get, reduce } from 'lodash';
import pt from 'prop-types';

import { formatCurrency } from 'utils';

import { productThumbUrl, productFeaturesByType } from 'utils/product';

import Icon from 'components/icon';
import Help from 'components/tooltip/help';
import { Field } from 'components/form';

import ProductPrice from './price';

import './product.scss';

export default class ProductVariants extends React.PureComponent {
  static propTypes = {
    currency: pt.string,

    record: pt.shape({
      type: pt.string,
      cost: pt.number,

      stock_tracking: pt.bool,

      options: pt.arrayOf(
        pt.shape({
          values: pt.arrayOf(
            pt.shape({
              id: pt.string.isRequired,
              name: pt.string.isRequired,
            }),
          ),
        }),
      ),

      variants: pt.shape({
        count: pt.number.isRequired,

        results: pt.arrayOf(
          pt.shape({
            id: pt.string.isRequired,
            sku: pt.string,
            cost: pt.number,
            stock_level: pt.number,
            active: pt.bool.isRequired,

            option_value_ids: pt.arrayOf(pt.string),
          }),
        ).isRequired,
      }).isRequired,
    }).isRequired,

    settings: pt.shape({
      products: pt.shape({
        features: pt.shape({
          cost_tracking: pt.bool,
        }).isRequired,
      }).isRequired,
    }).isRequired,

    onClickVariantImages: pt.func.isRequired,
    onClickEditVariant: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      options: props.record.options,
      features: productFeaturesByType(props.record.type),
      variantNames: ProductVariants.getVariantNames(props),
      variantPage: props.record.variants.page,
      variantLimit: props.record.variants.results.length,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.record.options !== state.options ||
      props.record.variants.page !== state.variantPage ||
      props.record.variants.results.length !== state.variantLimit
    ) {
      return {
        variantPage: props.record.variants.page,
        options: props.record.options,
        variantNames: ProductVariants.getVariantNames(props),
      };
    }

    return null;
  }

  /**
   * @param {object} props
   * @returns {Map<string, string>}
   */
  static getVariantNames({ record }) {
    if (!record.variants || record.variants.results.length <= 0) {
      return new Map();
    }

    const optionValues = reduce(
      record.options,
      (map, option) => {
        return reduce(
          option.values,
          (acc, value) => {
            return acc.set(value.id, value.name);
          },
          map,
        );
      },
      new Map(),
    );

    return record.variants.results.reduce((acc, variant) => {
      const names = [];

      for (const [valId, name] of optionValues) {
        if (
          Array.isArray(variant.option_value_ids) &&
          variant.option_value_ids.includes(valId)
        ) {
          names.push(name);
        }
      }

      return acc.set(variant.id, names.join(', '));
    }, new Map());
  }

  renderImage(variant) {
    if (get(variant, 'images.0.file')) {
      return (
        <span className="image">
          <img
            src={productThumbUrl(variant, null, 84)}
            alt={variant.name}
            width="42"
            height="42"
          />
        </span>
      );
    }

    return <Icon type="image" />;
  }

  render() {
    const {
      record,
      currency,
      settings,
      onClickEditVariant,
      onClickVariantImages,
    } = this.props;
    const { features, variantNames } = this.state;

    if (!record.variants || !record.variants.results.length) {
      return null;
    }

    return (
      <div className="product-variants">
        <table className="collection-table outer">
          <thead>
            <tr>
              <th colSpan="2">Variant</th>

              <th className="right">Price</th>

              {settings.products.features.cost_tracking && (
                <th className="right" width="25">
                  Cost
                </th>
              )}

              <th width="1">&nbsp;</th>

              {features.inventory && <th width="125">SKU</th>}

              {features.inventory && record.stock_tracking && (
                <th width="64">Stock</th>
              )}

              <th width="58">&nbsp;</th>
            </tr>
          </thead>

          <tbody>
            {record.variants.results.map((variant, index) => (
              <tr key={variant.id}>
                <td width="50" className="tight-right">
                  <button
                    data-id={variant.id}
                    className="button-input product-variant-image-button"
                    onClick={onClickVariantImages}
                    type="button"
                  >
                    {this.renderImage(variant)}
                  </button>
                </td>

                <td>
                  {variant.name}

                  {variantNames.get(variant.id) !== variant.name && (
                    <Help message={variantNames.get(variant.id)} />
                  )}
                </td>

                <td className="right">
                  <ProductPrice
                    product={record}
                    variant={variant}
                    currency={currency}
                  />
                </td>

                {settings.products.features.cost_tracking && (
                  <td className="right nowrap">
                    {variant.cost !== null && variant.cost !== undefined ? (
                      <span>{formatCurrency(variant.cost, currency)}</span>
                    ) : !record.cost ? (
                      <span className="muted">&mdash;</span>
                    ) : (
                      <span className="muted">
                        {formatCurrency(record.cost, currency)}
                      </span>
                    )}
                  </td>
                )}

                <td className="right">
                  <Field
                    type="toggle"
                    label="Active"
                    name={`variants.${variant.id}.active`}
                    defaultChecked={variant.active}
                  />
                </td>

                {features.inventory && (
                  <td width="150" className="tight-right">
                    <Field
                      name={`variants.${variant.id}.sku`}
                      defaultValue={variant.sku}
                      placeholder={record.sku || 'Optional'}
                      tabIndex={1 + index}
                    />
                  </td>
                )}

                {features.inventory && record.stock_tracking && (
                  <td className="tight-right">
                    <Field
                      type="text"
                      name={`variants.${variant.id}.stock_level`}
                      defaultValue={
                        variant.stock_level > 0 ? variant.stock_level : ''
                      }
                      placeholder="0"
                      selectFocus={true}
                      tabIndex={1 + index + record.variants.results.length}
                    />
                  </td>
                )}

                <td>
                  <button
                    data-id={variant.id}
                    className="button-input product-variants"
                    onClick={onClickEditVariant}
                    type="button"
                  >
                    More
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
