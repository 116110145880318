import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import ButtonLink from 'components/button/link';
import { formatCurrency, formatDate } from 'utils';

export default class PaymentWarningModal extends React.PureComponent {
  static contextTypes = {
    account: PropTypes.object.isRequired,
  };

  render() {
    const { account } = this.context;
    return (
      <Modal
        title={account.paymentRequired ? 'Payment required' : 'Account payment'}
        actions={[
          {
            component: (
              <ButtonLink
                type={account.paymentRequired ? 'danger' : 'default'}
                size="sm"
                to="/settings/account?edit-card"
              >
                Update payment card
              </ButtonLink>
            ),
          },
        ]}
        width={520}
        cancel={!account.paymentRequired}
        cancelText="I'll do it later"
        closable={false}
        tapout={false}
      >
        {account.paymentRequired ? (
          <p>
            There was a problem processing your latest payment. Please update
            your credit card information.
          </p>
        ) : (
          <p>
            There was a problem processing your latest payment. Please update
            your credit card information as early as possible to avoid any
            interruption in service.
          </p>
        )}
        {account.invoicesDue.length > 0 && (
          <Fragment>
            <p>
              {account.invoicesDue.length} invoice
              {account.invoicesDue.length > 1 ? `s are` : ` is`} past due
            </p>
            <ul>
              {account.invoicesDue.map((invoice) => (
                <li key={invoice.id}>
                  Invoice #{invoice.number} for{' '}
                  {formatCurrency(invoice.payment_due, 'USD')} due{' '}
                  {formatDate(invoice.date_created, 'age')}
                </li>
              ))}
            </ul>
          </Fragment>
        )}
      </Modal>
    );
  }
}
