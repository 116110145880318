import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import NotFoundPage from 'components/pages/error/404';
import Icon from 'components/icon';
import { formatDate, formatCurrency, formatCurrencyRounded } from 'utils';
import { productName } from 'utils/product';
import logo from 'components/logo/swell-purple.svg';

export default class AccountInvoice extends React.PureComponent {
  static contextTypes = {
    setTitle: PropTypes.func,
  };

  componentWillMount() {
    const { invoice } = this.props;
    this.context.setTitle(`Invoice ${invoice.number}`);
  }

  onClickPrint = (event) => {
    event.preventDefault();
    window.print();
  };

  render() {
    const {
      invoice,
      account: { record },
    } = this.props;

    if (!invoice) {
      return <NotFoundPage />;
    }

    const isAccountBilling =
      record.billing && record.billing.method === 'account';

    return (
      <div className="account-invoices">
        <div className="account-invoices-page">
          <header>
            <div className="clearfix account-invoices-company">
              <div className="left">
                <img
                  src={logo}
                  width="75"
                  className="account-invoices-logo"
                  alt="Swell"
                />
                <p>
                  44 Montgomery Street
                  <br />
                  San Francisco, California 94104-7775
                </p>
              </div>
              <div className="right">
                <h1>Invoice</h1>
              </div>
            </div>
            <div className="clearfix account-invoices-summary">
              <div className="left">
                <h3>Billed to</h3>
                <p>
                  {record.billing && record.billing.address1 ? (
                    <Fragment>
                      {record.billing.company ? (
                        <Fragment>
                          {record.billing.company}
                          <br />
                        </Fragment>
                      ) : (
                        <Fragment>
                          {record.name}
                          <br />
                        </Fragment>
                      )}
                      {record.billing.name && (
                        <Fragment>
                          {record.billing.name}
                          <br />
                        </Fragment>
                      )}
                      {record.billing.address1 && (
                        <Fragment>
                          {record.billing.address1}
                          <br />
                        </Fragment>
                      )}
                      {record.billing.address2 && (
                        <Fragment>
                          {record.billing.address2}
                          <br />
                        </Fragment>
                      )}
                      {record.billing.city ? (
                        <Fragment>
                          {record.billing.city} {record.billing.state}{' '}
                          {record.billing.zip}{' '}
                          {record.billing.country && (
                            <Fragment>({record.billing.country})</Fragment>
                          )}
                          <br />
                        </Fragment>
                      ) : (
                        record.billing.country && (
                          <Fragment>
                            {record.billing.country}
                            <br />
                          </Fragment>
                        )
                      )}
                      {record.billing.phone && (
                        <Fragment>
                          {record.billing.phone}
                          <br />
                        </Fragment>
                      )}
                      {record.billing.vat_number && (
                        <Fragment>
                          VAT: {record.billing.vat_number}
                          <br />
                        </Fragment>
                      )}
                      {record.billing.email ? (
                        <Fragment>
                          {record.billing.email}
                          <br />
                        </Fragment>
                      ) : (
                        <Fragment>
                          {record.user_email || record.email}
                          <br />
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {record.name}
                      <br />
                      {(record.billing && record.billing.email) ||
                        record.user_email ||
                        record.email}
                      <br />
                    </Fragment>
                  )}
                </p>
                <div>
                  <b>Account: {invoice.account.name}</b>
                </div>
              </div>
              <div className="right">
                <h3>Summary</h3>
                <p>
                  Number: {invoice.number}
                  <br />
                  Date: {formatDate(invoice.date_created, 'monthDayShortComma')}
                  <br />
                  Billing period:{' '}
                  {formatDate(
                    invoice.date_period_start,
                    'monthDayShortComma',
                  )}{' '}
                  - {formatDate(invoice.date_period_end, 'monthDayShortComma')}
                  <br />
                  Payment terms: Due upon receipt
                  <br />
                  <b>
                    Invoice total:{' '}
                    {formatCurrencyRounded(
                      invoice.grand_total,
                      invoice.currency,
                    )}
                  </b>
                </p>
              </div>
            </div>
          </header>
          <main>
            {invoice.items && (
              <table className="collection-table account-invoices-table">
                <thead>
                  <tr>
                    <th width="50%">Description</th>
                    <th>Qty</th>
                    <th className="currency">Price</th>
                    <th className="currency">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.items.map((item) => (
                    <tr>
                      <td>
                        {item.product && item.product.scope === 'billing-plan'
                          ? `Swell `
                          : ''}
                        {item.description ||
                          productName(item.product, item.variant)}
                      </td>
                      <td>{item.quantity}</td>
                      <td className="currency">
                        {formatCurrency(item.price, invoice.currency)}
                      </td>
                      <td className="currency">
                        {formatCurrency(item.price_total, invoice.currency)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/*<tfoot>
                  <tr>
                    <td colSpan="4">
                      Invoice total: $99.99
                    </td>
                  </tr>
                </tfoot>*/}
              </table>
            )}
            {isAccountBilling && (
              <p>
                <em>See attached wire details for payment instructions.</em>
              </p>
            )}
          </main>
        </div>
        <div className="account-invoices-print">
          <a href="" onClick={this.onClickPrint}>
            <Icon fa="print" /> Print this invoice
          </a>
        </div>
      </div>
    );
  }
}
