import React, { Fragment } from 'react';
import { get } from 'lodash';
import { Field } from 'components/form';
import Icon from 'components/icon';
import ContentModelFields from 'components/content/model/fields';
import AppIndicator from 'components/view/app-indicator';
import { wordify, singularize, isEmpty } from 'utils';
import {
  ADMIN_ZONES,
  contentFieldLabel,
  contentFieldPath,
  contentFieldTypeLabel,
  contentFieldTypeProps,
  contentFieldAliasType,
  contentFieldDataType,
  contentFieldSourceLabel,
} from 'utils/content';
import { truncatedText } from 'utils/collection';
import './model.scss';

export default class ModelFields extends ContentModelFields {
  fieldtableProps = {
    sortSubmit: true,
    onSubmit: (fields) => this.props.onSubmit(fields),
    editedFieldId: this.props.params?.editedFieldId,
  };

  getFieldModel(field = undefined) {
    return get(this.props.record, 'content_models', []).find(
      (model) =>
        (field && field.content_id === model.id) ||
        (model.source_type === 'custom' && model.source_id === 'admin'),
    );
  }

  renderFieldsForm(values, ...args) {
    const contentModel = this.getFieldModel(values);
    values.root_level = true;
    values.source_type = get(contentModel, 'source_type');
    return (
      <Fragment>
        <Field
          type="hidden"
          name="content_id"
          value={values.content_id || get(contentModel, 'id')}
        />
        {super.renderFieldsForm(values, ...args)}
      </Fragment>
    );
  }

  renderFieldsHeading = () => {
    const { record = {} } = this.props;
    return [
      <th key="field">Field</th>,
      <th key="type">Type</th>,
      <th key="props">Properties</th>,
      <th key="actions">&nbsp;</th>,
      ADMIN_ZONES[record.collection] && <th key="admin">Location</th>,
      <th key="source">Source</th>,
    ];
  };

  renderFieldsValue = ({ value, index }) => {
    const { record = {} } = this.props;
    const fieldType = contentFieldAliasType(value.type);
    const dataType = contentFieldDataType(fieldType, value);
    const fieldLabel = contentFieldLabel(value);
    const typeProps = contentFieldTypeProps(fieldType);

    const fieldModel = this.getFieldModel(value);
    const fieldPath = contentFieldPath(value, fieldModel);

    value.root_level = true;
    return [
      <td key="field">
        <div className="nowrap">
          {fieldLabel && !typeProps.wrapper ? (
            <>
              <div className="model-fields-first-cell">
                {truncatedText(fieldLabel, 64)}
              </div>
              <code className="muted">
                {value.id && truncatedText(fieldPath, 64)}
              </code>
            </>
          ) : (
            <span className="muted">{contentFieldTypeLabel(fieldType)}</span>
          )}
        </div>
      </td>,
      <td key="type">
        <div className="nowrap">
          {fieldLabel && (
            <>
              {contentFieldTypeLabel(fieldType)}
              <br />
            </>
          )}
          <code className="muted">
            {fieldType} {dataType ? `(${dataType})` : ''}
          </code>
        </div>
      </td>,
      ...this.renderFieldProperties(value, index),
      ADMIN_ZONES[record.collection] && (
        <td key={`admin ${value.id}`}>
          <div className="nowrap">{this.renderAdminLocation(value, index)}</div>
        </td>
      ),
      <td key="source">
        <div className="nowrap">
          {this.renderFieldSource(value, fieldModel)}
        </div>
      </td>,
    ];
  };

  renderFieldSource(field, fieldModel) {
    const {
      client: { apps, appsBySlug, appsById },
    } = this.props;

    let source = 'Custom';

    if (fieldModel?.app_id) {
      const app = appsBySlug[fieldModel.app_id] || appsById[fieldModel.app_id];
      if (app) {
        source = <AppIndicator app={app} icon={false} />;
      }
    } else if (field.content_id) {
      return contentFieldSourceLabel(field, apps);
    }

    return source;
  }

  renderAdminLocation(value, index) {
    const { record = {} } = this.props;

    let zoneLabel;
    switch (value.admin_zone) {
      default:
        if (value.admin_zone) {
          zoneLabel = wordify(value.admin_zone);
        } else {
          zoneLabel = null;
        }
        break;
    }

    const model =
      record.id &&
      record.content_models.find((model) => model.id === value.content_id);
    const defaultEnabled =
      record.id && value.admin_enabled !== undefined
        ? !!value.admin_enabled
        : value.admin_enabled !== false;

    const zoneLocationLabel =
      zoneLabel &&
      this.renderAdminLocationPath([
        record.singular || record.label,
        zoneLabel,
      ]);

    return model?.source_type === 'app' ? (
      zoneLocationLabel
    ) : model?.source_type === 'theme' ? (
      this.renderAdminLocationPath(['Storefront', 'Editor'])
    ) : record.id && zoneLabel ? (
      <Field
        type="toggle"
        name="admin_enabled"
        label={zoneLocationLabel}
        defaultChecked={defaultEnabled}
        className="model-fields-toggle-enabled"
        onChange={(event, val) => {
          const enabled = !!val;
          if (enabled === defaultEnabled) return;
          const { record = {}, onSubmit } = this.props;
          const field = record.fields.find((f) => f.id === value.id);
          if (field) {
            value.admin_enabled = field.admin_enabled = enabled;
            return onSubmit(record.fields, true);
          }
        }}
        data-index={index}
      />
    ) : (
      <span className="muted">&mdash;</span>
    );
  }

  renderAdminLocationPath(parts) {
    return (
      <Fragment>
        {parts.map((part, i) => (
          <Fragment key={i}>
            {part}
            {i + 1 < parts.length && (
              <Fragment>
                <Icon fa="chevron-right" faType="light" className="muted" />
              </Fragment>
            )}
          </Fragment>
        ))}
      </Fragment>
    );
  }

  renderAdminZoneFilter() {
    const { record = {}, zone: defaultZone } = this.props;
    const zones = record.id && ADMIN_ZONES[record.collection];
    if (!zones || isEmpty(record.fields)) {
      return null;
    }
    return (
      <div className="model-fields-zone-filter collection-pages">
        <div className="collection-limit">
          <Field
            type="select"
            name="admin_zone"
            options={[
              { value: '', label: 'All fields' },
              ...zones.slice(1).map(({ value, label }) => ({
                value,
                label: `${singularize(label)} fields`,
              })),
            ]}
            defaultValue={defaultZone || ''}
            placeholder="All fields"
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderFields()}
        {this.renderAdminZoneFilter()}
      </Fragment>
    );
  }
}
