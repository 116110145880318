import React from 'react';
import { connect } from 'react-redux';
import Collection from './Collection';
import BulkExport from 'components/bulk/export';
import SubscriptionStatus from 'components/pages/subscription/status';
import SubscriptionInvoiceNext from 'components/pages/subscription/invoice-next';
import Tooltip from 'components/tooltip';
import { currencyValue, formatDate } from 'utils';
import {
  subscriptionTitle,
  subscriptionPriceDesc,
  subscriptionStatus,
} from 'utils/subscription';
import { replaceLocationQuery } from 'actions/data';
import { accountName } from 'utils/account';
import api from 'services/api';
import actions from 'actions';

export const query = {
  expand: ['account', 'product', 'variant'],
};

export const tabs = {
  default: {
    label: 'All subscriptions',
    query: { draft: { $ne: true } },
  },
  active: {
    label: 'Active',
    query: {
      active: true,
      status: 'active',
    },
  },
  trial: {
    label: 'Trial',
    query: {
      status: 'trial',
    },
  },
  paused: {
    label: 'Paused',
    query: {
      paused: true,
    },
  },
  canceled: {
    label: 'Canceled',
    query: {
      status: 'canceled',
    },
  },
  pastdue: {
    label: 'Past due',
    query: {
      status: 'pastdue',
    },
  },
  draft: {
    label: 'Drafts',
    query: { draft: true },
  },
};

export const filters = {
  product: {
    label: 'Product',
    type: 'LookupProduct',
    func: (product_id) => ({ product_id }),
  },
  customer: {
    label: 'Customer',
    type: 'LookupCustomer',
    func: (account_id) => ({ account_id }),
  },
  recurring_total: {
    label: 'Recurring total',
    operators: ['gt', 'lt', 'eq'],
    type: 'currency',
  },
  status: {
    label: 'Status',
    options: [
      { value: 'canceled', label: 'Canceled' },
      { value: 'paused', label: 'Paused' },
      { value: 'active', label: 'Active' },
      { value: 'trial', label: 'Trial' },
      { value: 'pastdue', label: 'Past due' },
      { value: 'draft', label: 'Draft' },
    ],
    func: (query) => {
      if (query === 'canceled') {
        return { canceled: true };
      }
      if (query === 'active') {
        return { active: true };
      }
      if (query === 'trial') {
        return { trial: true };
      }
      if (query === 'pastdue') {
        return { $or: [{ unpaid: true }, { active: true, paid: false }] };
      }
      if (query === 'paused') {
        return { paused: true };
      }
      if (query === 'draft') {
        return { draft: true };
      }
    },
  },
  date_created: {
    label: 'Date created',
    operators: ['after', 'before'],
    type: 'date',
  },
  date_canceled: {
    label: 'Date canceled',
    operators: ['after', 'before'],
    type: 'date',
  },
};

export const fields = {
  name: {
    label: 'Plan',
    sort: false,
    url: '/subscriptions/{id}',
    default: true,
    columns: [
      {
        label: '',
        type: 'image',
        path: 'product.images',
      },
      {
        id: 'plan',
        label: 'Plan',
        path: 'name',
        type: 'link',
        url: '/subscriptions/{id}',
        func: (sub) => (sub.product ? subscriptionTitle(sub) : ''),
      },
    ],
  },
  customer: {
    label: 'Customer',
    sort: false,
    func: (sub) => (sub.account ? accountName(sub.account) : ''),
  },
  date_created: {
    label: 'Created',
    type: 'date',
  },
  status: {
    label: 'Status',
    sort: false,
    func: (sub) => (
      <div style={{ display: 'flex' }}>
        <SubscriptionStatus record={sub} />
        {sub.paused && sub.active && (
          <Tooltip
            message={`Active period ends on ${formatDate(
              sub.date_period_end,
              'long',
            )}`}
          >
            <span className="help icon icon-info">&nbsp;</span>
          </Tooltip>
        )}
      </div>
    ),
  },
  date_period_end: {
    label: 'Next invoice',
    func: (sub) => <SubscriptionInvoiceNext record={sub} />,
  },
  price: {
    label: 'Price',
    func: (sub) => subscriptionPriceDesc(sub),
  },
};

export const draftFields = {
  number: {
    label: 'Draft',
    type: 'link',
    url: '/subscriptions/drafts/{id}',
  },
  ...fields,
  date_period_end: {
    ...fields.date_period_end,
    show: false,
  },
};

// export const bulkActions = [
//   {
//     modal: 'BulkEditProducts',
//     label: 'Edit Products',
//   },
// ];

const exportCSVFields = [
  { key: 'name', label: 'Customer', export: (sub) => sub.account.name },
  {
    key: 'first_name',
    label: 'First name',
    export: (sub) => sub.account.first_name,
  },
  {
    key: 'last_name',
    label: 'Last name',
    export: (sub) => sub.account.last_name,
  },
  { key: 'email', label: 'Email', export: (sub) => sub.account.email },

  { key: 'product', label: 'Product', export: (sub) => subscriptionTitle(sub) },
  {
    key: 'price',
    label: 'Price',
    export: (sub) => currencyValue(sub.price, sub.currency),
  },
  { key: 'quantity', label: 'Quantity' },
  {
    key: 'interval',
    label: 'Billing interval',
  },
  {
    key: 'interval_count',
    label: 'Billing interval count',
  },
  {
    key: 'price_description',
    label: 'Price description',
    export: (sub) => subscriptionPriceDesc(sub),
  },
  {
    key: 'discount_total',
    label: 'Discount',
    export: (sub) => currencyValue(sub.discount_total, sub.currency),
  },
  {
    key: 'grand_total',
    label: 'Total',
    export: (sub) =>
      currencyValue(sub.grand_total, sub.currency, { precision: undefined }),
  },
  {
    key: 'lifetime_value',
    label: 'Lifetime value',
    export: (sub) => currencyValue(sub.lifetime_value.total, sub.currency),
  },
  { key: 'status', label: 'Status', export: (sub) => subscriptionStatus(sub) },
  { key: 'trial_days', label: 'Trial days' },
  { key: 'canceled', label: 'Canceled' },
  { key: 'date_canceled', label: 'Date ended' },
  { key: 'date_created', label: 'Date created' },
  { key: 'id', label: 'ID' },
];

const mapStateToProps = (state) => ({
  data: state.data,
  bulk: state.data.bulk,
  loading: state.data.loading,
});

const mapDispatchToProps = (dispatch) => ({
  bulkExport: (model: string, params: Object) => {
    return dispatch(actions.data.bulkExport(model, params));
  },

  bulkCancel: () => {
    return dispatch(actions.data.bulkCancel());
  },

  clearQuery: () => {
    return dispatch(actions.data.clearQuery());
  },
});

export class Subscriptions extends React.Component {
  static async onEnter(store, nextState, replace) {
    if (replaceLocationQuery(store, nextState, replace)) {
      return;
    }
  }

  async searchHandler(search, limit) {
    if (!search) {
      return {};
    }
    const productQuery = {
      search,
      limit: limit || 1000,
      fields: 'id',
      type: 'subscription',
    };
    const findProducts = api.get('/data/products', productQuery);
    const findAccounts = api.get('/data/accounts', {
      search,
      limit: limit || 1000,
      fields: 'id',
    });
    const { foundProducts, foundAccounts } = {
      foundProducts: await findProducts,
      foundAccounts: await findAccounts,
    };
    return foundProducts.count || foundAccounts.count
      ? {
          $or: [
            {
              product_id: {
                $in: foundProducts.results.map((product) => product.id),
              },
            },
            {
              account_id: {
                $in: foundAccounts.results.map((account) => account.id),
              },
            },
          ],
        }
      : productQuery;
  }

  state = {};

  constructor(props: Object) {
    super(props);
    this.state = {
      fields: props.location.query.tab === 'draft' ? draftFields : fields,
      loadingTab: false,
      showingExport: false,
      onClickExport: this.onClickExport.bind(this),
      onClickExportCancel: this.onClickExportCancel.bind(this),
      onClickExportReset: this.onClickExportReset.bind(this),
      onSubmitExport: this.onSubmitExport.bind(this),
    };
  }

  componentWillUnmount() {
    const { clearQuery } = this.props;

    clearQuery();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.query.tab !== this.props.location.query.tab) {
      this.setState({ loadingTab: true });
    } else if (
      this.state.loadingTab &&
      this.props.loading &&
      !nextProps.loading
    ) {
      this.setState({
        loadingTab: false,
        fields: nextProps.location.query.tab === 'draft' ? draftFields : fields,
      });
    }
  }

  onClickExport(event: Object) {
    event.preventDefault();
    this.setState({ showingExport: !this.state.showingExport });
  }

  onClickExportCancel(event: Object) {
    event.preventDefault();
    if (this.props.bulk.running) {
      this.props.bulkCancel();
    }
    this.setState({ showingExport: false });
  }

  onClickExportReset(event: Object) {
    event.preventDefault();
    this.props.bulkCancel();
  }

  onSubmitExport(values: Object) {
    this.props.bulkExport(`subscriptions`, {
      ...values,
      filename: `subscriptions`,
      csvFields: exportCSVFields,
      query: {
        include: {
          product: {
            url: '/products/{product_id}',
            data: {
              fields: 'name',
            },
          },
          variant: {
            url: '/products:variants/{variant_id}',
            data: {
              fields: 'name',
            },
          },
          account: {
            url: '/accounts/{account_id}',
            data: {
              fields: 'name, first_name, last_name, email',
            },
          },
          lifetime_value: {
            url: '/payments/:group',
            data: {
              total: { $sum: 'amount_refundable' },
              where: {
                success: true,
              },
            },
            params: {
              where: {
                subscription_id: 'id',
              },
            },
          },
        },
      },
    });
  }

  headerActions = [
    {
      label: 'Export',
      fa: 'arrow-to-bottom',
      type: 'sub',
      onClick: this.onClickExport.bind(this),
    },
    {
      label: 'New subscription',
      showAlways: true,
      link: '/subscriptions/drafts/new',
    },
  ];

  render() {
    return (
      <div className="subscription-list">
        <Collection
          {...this.props}
          title="Subscriptions"
          uri="/subscriptions"
          model="subscriptions"
          emptyTitle="Create and manage subscriptions"
          emptyDescription="You'll use this section to manage subscriptions purchased by customers, or create subscriptions manually on their behalf."
          emptyActionLink="/subscriptions/drafts/new"
          tabs={tabs}
          filters={filters}
          fields={this.state.fields}
          bulkActions={undefined}
          headerActions={this.headerActions}
          query={query}
          searchHandler={this.searchHandler}
        />
        {this.state.showingExport && (
          <BulkExport label="Subscriptions" {...this.props} {...this.state} />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
