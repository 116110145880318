// @flow
import { get, min, max, isEqual, find } from 'lodash';
import { arrayToObject } from 'utils';
import moment from 'moment';
import api from 'services/api';

export const WEBHOOKS_FETCH_ALL = 'webhooks/fetchAll';
export const WEBHOOKS_FETCH_ATTEMPTS = 'webhooks/fetchAttempts';
export const WEBHOOKS_FETCH_ORDER_STATS = 'webhooks/fetchOrderStats';
export const WEBHOOKS_FETCH_ORDER_ATTEMPTS = 'webhooks/fetchOrderAttempts';
export const WEBHOOKS_UPDATE_ALL = 'webhooks/updateAll';
export const WEBHOOKS_LOADING = 'webhooks/loading';

export default {
  fetchAll() {
    return {
      type: WEBHOOKS_FETCH_ALL,
      payload: api
        .get('/data/:webhooks', {
          limit: null,
          sort: 'date_created asc',
          app_id: null,
        })
        .then((result) => {
          return result.results;
        }),
    };
  },

  fetchAttempts(webhookId: string) {
    return {
      type: WEBHOOKS_FETCH_ATTEMPTS,
      payload: api.get('/data/events:webhooks', {
        webhook_id: webhookId,
        status: { $ne: 200 },
        limit: 10,
      }),
    };
  },

  fetchOrderWebhookStats() {
    return {
      type: WEBHOOKS_FETCH_ORDER_STATS,
      payload: api
        .get(`/data/:batch`, {
          orders: {
            url: '/orders/:group',
            data: {
              where: {
                date_webhook_first_failed: {
                  $gt: moment().add(-30, 'days').toDate(),
                },
              },
              attempts_failed: {
                $sum: 'webhook_attempts_failed',
              },
              date_first_failed: {
                $min: 'date_webhook_first_failed',
              },
              date_last_succeeded: {
                $max: 'date_webhook_last_succeeded',
              },
            },
          },
          carts: {
            url: '/carts/:group',
            data: {
              where: {
                date_webhook_first_failed: {
                  $gt: moment().add(-30, 'days').toDate(),
                },
              },
              attempts_failed: {
                $sum: 'webhook_attempts_failed',
              },
              date_first_failed: {
                $min: 'date_webhook_first_failed',
              },
              date_last_succeeded: {
                $max: 'date_webhook_last_succeeded',
              },
            },
          },
        })
        .then((result) => {
          return {
            attempts_failed:
              get(result, 'orders.attempts_failed', 0) +
              get(result, 'carts.attempts_failed', 0),
            date_first_failed: min([
              get(result, 'orders.date_first_failed'),
              get(result, 'carts.date_first_failed'),
            ]),
            date_last_succeeded: max([
              get(result, 'orders.date_last_succeeded'),
              get(result, 'carts.date_last_succeeded'),
            ]),
          };
        }),
    };
  },

  fetchOrderWebhookAttempts() {
    return {
      type: WEBHOOKS_FETCH_ORDER_ATTEMPTS,
      payload: api.get(`/data/:batch`, {
        orders: {
          url: '/orders',
          data: {
            date_webhook_first_failed: {
              $gt: moment().add(-30, 'days').toDate(),
            },
            fields: [
              'number',
              'date_created',
              'webhook_status',
              'webhook_response',
              'webhook_attempts_failed',
              'date_webhook_first_failed',
            ],
          },
        },
        carts: {
          url: '/carts',
          data: {
            date_webhook_first_failed: {
              $gt: moment().add(-30, 'days').toDate(),
            },
            fields: [
              'number',
              'date_created',
              'webhook_status',
              'webhook_response',
              'webhook_attempts_failed',
              'date_webhook_first_failed',
            ],
          },
        },
      }),
    };
  },

  updateAll(webhooks: Array) {
    return (dispatch: Function, getState: Function) => {
      const { list } = getState().webhooks;

      const listById = arrayToObject(list);
      const webhooksById = arrayToObject(webhooks);
      const deleteWebhooks = list.filter((record) => !webhooksById[record.id]);
      const createWebhooks = webhooks.filter((record) => !listById[record.id]);
      const updateWebhooks = webhooks.filter(
        (record) =>
          listById[record.id] && isWebhookUpdated(record, listById[record.id]),
      );

      const batchRequests = createWebhooks
        .map((webhook) => ({
          method: 'post',
          url: '/:webhooks',
          data: webhook,
        }))
        .concat(
          updateWebhooks.map((webhook) => ({
            method: 'put',
            url: '/:webhooks/{id}',
            data: {
              ...webhook,
              events: undefined,
              $set: { events: webhook.events },
            },
          })),
        )
        .concat(
          deleteWebhooks.map((webhook) => ({
            method: 'delete',
            url: '/:webhooks/{id}',
            data: { id: webhook.id },
          })),
        );
      if (batchRequests.length === 0) {
        return true;
      }
      return dispatch({
        type: WEBHOOKS_UPDATE_ALL,
        payload: api.put('/data/:batch', batchRequests).then((result) => {
          const errors = find(result, 'errors');
          return errors ? errors : result;
        }),
      });
    };
  },
};

export const initialState = {
  list: [],
  attempts: {},
  orderStats: {},
  orderAttempts: {},
  loading: false,
};

export function reducer(state: Object = initialState, action: Object) {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case WEBHOOKS_FETCH_ALL:
      if (action.payload.error) {
        return state;
      }
      return {
        ...state,
        list: action.payload,
      };

    case WEBHOOKS_FETCH_ATTEMPTS:
      if (action.payload.error) {
        return state;
      }
      return {
        ...state,
        attempts: action.payload,
      };

    case WEBHOOKS_FETCH_ORDER_STATS:
      if (action.payload.error) {
        return state;
      }
      return {
        ...state,
        orderStats: action.payload,
      };

    case WEBHOOKS_FETCH_ORDER_ATTEMPTS:
      if (action.payload.error) {
        return state;
      }
      return {
        ...state,
        orderAttempts: action.payload,
      };

    case WEBHOOKS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}

function isWebhookUpdated(a, b) {
  if (a.id !== b.id) {
    return false;
  }
  return !isEqual(
    {
      url: a.url,
      alias: a.alias,
      description: a.description,
      events: a.events,
      enabled: a.enabled,
    },
    {
      url: b.url,
      alias: b.alias,
      description: b.description,
      events: b.events,
      enabled: b.enabled,
    },
  );
}
