import { get, map, reduce, find } from 'lodash';
import { arrayToObject } from './index';
import geoData from './geo-data';

export default geoData;

export const countries = arrayToObject(geoData.countries);
export const currencies = arrayToObject(geoData.currencies);
export const locales = arrayToObject(geoData.locales);
export const states = arrayToObject(geoData.states);
export const timezones = arrayToObject(geoData.timezones);

export const countryOptions = map(geoData.countries, (country) => ({
  value: country.id,
  label: country.name,
}));

export const stateOptions = reduce(
  geoData.states,
  (result, state) => {
    (result[state.country] || (result[state.country] = [])).push({
      value: state.id,
      label: state.name,
    });
    return result;
  },
  {},
);

export const countriesWithStates = map(
  stateOptions,
  (states, country) => country,
);

export const localeOptions = map(geoData.locales, (locale) => ({
  value: locale.id,
  label: locale.name,
}));

export const currencyOptions = map(geoData.currencies, (currency) => ({
  value: currency.id,
  label: currency.name,
}));

// Get country name by id
export function countryName(id) {
  return countries[id] ? countries[id].name : id;
}

// Get country state label by id
export function countryStateLabel(id) {
  return (countries[id] && countries[id].state_label) || 'State/province';
}

// Get locale identifier from browser
export function getLocale() {
  const id = window.navigator.language;
  const locale = find(geoData.locales, { id });
  return locale ? String(id || '').replace('_', '-') : 'en-US';
}

// Get locale name from geo data
export function localeName(code) {
  return get(find(geoData.locales, { id: code }), 'name', code);
}

export const weightUnitOptions = [
  {
    value: 'g',
    label: 'Grams (g)',
  },
  {
    value: 'kg',
    label: 'Kilograms (kg)',
  },
  {
    value: 'oz',
    label: 'Ounces (oz)',
  },
  {
    value: 'lb',
    label: 'Pounds (lb)',
  },
];

// Get default weight unit by country
export function weightUnitByCountry(country) {
  return country === 'US' ? 'lb' : 'kg';
}
