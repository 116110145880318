import React from 'react';
import { find, cloneDeep } from 'lodash';

import Link from 'components/link';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import Icon from 'components/icon';
import ContentFields from 'components/content/fields';
import { formatDate, formatCurrencyRounded } from 'utils';
import { renderPaymentMethodDescription } from 'utils/order';
import { hasLocalizedContent, renderContentTooltip } from 'utils/content';

import PaymentStatus from './payment-status';

export default class OrderPaymentView extends React.PureComponent {
  state = {
    payment: null,
    refund: null,
    values: null,
    edited: false,
  };

  constructor(props) {
    super(props);
    const { record, paymentId, refundId } = props;
    const payment = find(record.payments.results, { id: paymentId });
    const refund = payment
      ? find(payment.refunds.results, { id: refundId })
      : null;
    this.state = {
      ...this.state,
      payment,
      refund,
      values: cloneDeep(refund ? refund : payment),
    };
  }

  onChangeForm = (values, edited) => {
    this.setState({ values: { ...this.state.values, ...values }, edited });
  };

  render() {
    const {
      settings,
      loading,
      content,
      onSubmitPaymentView,
      onClickPaymentView,
      onClickRefundView,
    } = this.props;

    const { payment, refund, edited } = this.state;

    return (
      <Form
        onSubmit={onSubmitPaymentView}
        onChange={this.onChangeForm}
        values={refund || payment}
        autoFocus={true}
      >
        <Modal
          title={
            refund ? `Refund ${refund.number}` : `Payment ${payment.number}`
          }
          className="order-view-modal-payment-charge"
          actions={[
            {
              label: 'Save changes',
              type: 'submit',
              styleType: edited ? 'default' : 'secondary',
            },
            {
              label: 'Cancel',
              type: 'cancel',
              onClick: onClickPaymentView,
            },
          ]}
          width={750}
          cancel={false}
          loading={loading}
          onClose={onClickPaymentView}
          devtools={{
            model: 'payments',
            uri: refund ? `${payment.id}/refunds/${refund.id}` : payment.id,
            zone: refund ? 'refund' : 'charge',
          }}
          localized={hasLocalizedContent(
            content.models,
            'payments',
            refund ? 'refund' : 'charge',
          )}
        >
          <fieldset>
            <table className="collection-table outer order-payment">
              <tbody>
                <tr>
                  <td>
                    <table className="collection-table inner">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th className="currency">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {refund ? (
                            <td className="inner-link">
                              <Link
                                to={`/console?get/payments/${payment.id}`}
                                onClick={onClickPaymentView}
                                data-id={payment.id}
                              >
                                {renderPaymentMethodDescription(
                                  settings,
                                  payment,
                                )}
                              </Link>
                              {renderContentTooltip(
                                payment,
                                content.models,
                                'payments',
                                'charge',
                              )}
                            </td>
                          ) : (
                            <td>
                              {renderPaymentMethodDescription(
                                settings,
                                payment,
                              )}
                            </td>
                          )}
                          <td>{formatDate(payment.date_created, 'age')}</td>

                          <td width="125">
                            <PaymentStatus payment={payment} />
                          </td>

                          <td className="currency" width="125">
                            {formatCurrencyRounded(
                              payment.amount,
                              payment.currency,
                            )}
                          </td>
                        </tr>
                        {(refund ? [refund] : payment.refunds.results)
                          .map((ref) => (
                            <tr key={ref.id}>
                              {refund ? (
                                <td>
                                  <span className="order-payment-refund-icon">
                                    <Icon fa="level-up" />
                                  </span>
                                  {renderPaymentMethodDescription(
                                    settings,
                                    ref,
                                  )}
                                </td>
                              ) : (
                                <td className="inner-link">
                                  <Link
                                    to={`/console?get/payments/${payment.id}%2Frefunds%2F${ref.id}`}
                                    onClick={onClickRefundView}
                                    data-paymentid={payment.id}
                                    data-id={ref.id}
                                  >
                                    <span className="order-payment-refund-icon">
                                      <Icon fa="level-up" />
                                    </span>
                                    {renderPaymentMethodDescription(
                                      settings,
                                      ref,
                                    )}
                                  </Link>
                                  {renderContentTooltip(
                                    ref,
                                    content.models,
                                    'payments',
                                    'refund',
                                  )}
                                </td>
                              )}
                              <td>{formatDate(ref.date_created, 'age')}</td>

                              <td width="125">
                                <PaymentStatus payment={payment} refund={ref} />
                              </td>

                              <td className="currency muted" width="125">
                                (
                                {formatCurrencyRounded(
                                  ref.amount,
                                  ref.currency,
                                )}
                                )
                              </td>
                            </tr>
                          ))
                          .reverse()}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            {refund && (
              <div className="row">
                <Field
                  type="textarea"
                  name="reason"
                  label="Reason"
                  defaultValue={refund.reason}
                  placeholder="Optional"
                  autoSize={true}
                  rows={2}
                  help="Refund notes are not visible to the customer"
                  className="span4"
                />
              </div>
            )}
            <ContentFields
              {...this.props}
              zone={refund ? 'refund' : 'charge'}
              collection="payments"
              models={content.models}
              record={refund || payment}
              values={refund || payment}
              currency={payment.currency}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
