import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, find, includes, chunk, filter } from 'lodash';
import { Field } from 'components/form';
import { FadeIn } from 'components/transitions';
import { formatCurrency, arrayToObject, isEmpty } from 'utils';
import { isBundleItemVariable } from 'utils/product';
import { subscriptionOptionValueNameDeprecated } from 'utils/subscription';

export default class ProductOptionInputs extends React.PureComponent {
  static propTypes = {
    product: PropTypes.object.isRequired,
    defaultValue: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.object),
      bundle_options: PropTypes.object,
    }),
    name: PropTypes.string,
    columns: PropTypes.number,
    custom: PropTypes.bool,
    price: PropTypes.bool,
    values: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      bundleOptions: [],
    };
  }

  componentDidMount() {
    const { product } = this.props;
    const { custom = true } = this.props;
    const options = this.getOrderedOptions(
      filter(
        product.options,
        (option) => (custom || option.variant) && this.isOptionValid(option),
      ),
    );

    if (!product.bundle || isEmpty(product.bundle_items)) {
      this.setState({ options });
      return;
    }

    const bundleOptions = [];
    for (const bundleItem of product.bundle_items) {
      if (!isBundleItemVariable(bundleItem)) {
        continue;
      }
      const bundleProductOptions = this.getOrderedOptions(
        filter(
          bundleItem.product.options,
          (option) => (custom || option.variant) && this.isOptionValid(option),
        ),
      );
      bundleOptions.push({
        product: bundleItem.product,
        options: bundleProductOptions,
      });
    }
    this.setState({ options, bundleOptions });
  }

  getOrderedOptions(options = []) {
    return options.sort((a, b) => {
      if (!a.parent_id && !b.parent_id) {
        return 0;
      } else if (!a.parent_id || b.parent_id === a.id) {
        return -1;
      } else if (!b.parent_id || a.parent_id === b.id) {
        return 1;
      }
      return 0;
    });
  }

  isOptionValid(option) {
    if (
      option.variant ||
      option.input_type === 'dropdown' ||
      option.input_type === 'radio'
    ) {
      if (option.values && option.values.length > 0) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }

  getOptionValues(option) {
    const { product, price = true } = this.props;
    return option.values
      ? option.values.map((opValue) => ({
          value: opValue.id,
          label: `${
            option.subscription
              ? subscriptionOptionValueNameDeprecated(opValue, {
                  currency: product.currency,
                })
              : opValue.name
          }${
            price &&
            opValue.price > 0 &&
            ((product.delivery !== 'giftcard' && !option.subscription) ||
              option !== this.state.options[0])
              ? `  +${formatCurrency(opValue.price, product.currency)}`
              : ''
          }`,
        }))
      : [];
  }

  getOptionInputProps(option, defaultValues) {
    const { product } = this.props;
    const defaultOptionValue = defaultValues?.[option.id];
    const defaultValue =
      defaultOptionValue?.value_id || defaultOptionValue?.value;

    const defaults = {
      help: option.description,
      defaultValue,
    };
    switch (option.input_type || 'select') {
      case 'select':
        return {
          ...defaults,
          type: 'select',
          placeholder: `Choose ${option.name.toLowerCase()}`,
          options: this.getOptionValues(option),
        };
      case 'toggle':
        // eslint-disable-next-line no-case-declarations
        const { price: valuePrice } = option.values?.[0] || {};

        return {
          ...defaults,
          label: `${option.name}${
            valuePrice > 0
              ? ` +${formatCurrency(valuePrice, product.currency)}`
              : ''
          }`,
          type: 'toggle',
          className: 'span4',
          defaultValue: undefined,
          defaultChecked: Boolean(defaultValue),
          value: get(option.values, '[0].id', true),
        };
      case 'short_text':
      case 'long_text':
      case 'text': // Deprecated
      case 'textarea': // Deprecated
        return {
          ...defaults,
          type:
            option.input_type === 'long_text'
              ? 'textarea'
              : option.input_type === 'short_text'
              ? 'text'
              : option.input_type,
          placeholder:
            option.input_hint || (option.required ? 'Required' : 'Optional'),
          rows:
            option.input_type === 'textarea' ||
            option.input_type === 'long_text'
              ? 5
              : undefined,
          className: 'span4',
        };
      // Following are deprecated
      case 'dropdown':
        return {
          ...defaults,
          type: 'select',
          placeholder: `Choose ${option.name.toLowerCase()}`,
          options: this.getOptionValues(option),
        };
      case 'checkbox':
        return {
          ...defaults,
          type: 'checkbox',
        };
      case 'radio':
        return {
          ...defaults,
          type: 'radio',
          options: this.getOptionValues(option),
          stacked: true,
        };
      case 'image':
        return {
          ...defaults,
          type: 'images',
          single: option.input_multi ? false : true,
          minimal: true,
          wide: false,
          maxWidth: 380,
          minWidth: 0,
          minHeight: 0,
        };
      default:
        return defaults;
    }
  }

  filterOptionsByParentCheck = (option) => {
    if (!option.parent_id) {
      return true;
    }

    const { values, product } = this.props;
    const parentOption = find(values?.options || [], { id: option.parent_id });
    const productOption = find(product?.options || [], {
      id: option.parent_id,
    });

    if (parentOption && productOption) {
      const parentOptionValue = parentOption.value;
      const optionParentValueIds = option.parent_value_ids;

      return isEmpty(optionParentValueIds)
        ? parentOptionValue
        : find(
            productOption.values,
            (value) =>
              includes(optionParentValueIds, value.id) &&
              value.id === parentOptionValue,
          );
    } else if (!productOption) {
      return true;
    }

    return false;
  };

  renderInputs = ({
    options = [],
    product,
    defaultValue,
    name = 'options',
    columns = 2,
    price = true,
  }) => {
    const activeOptions = options.filter(
      (option) =>
        option.active !== false && this.filterOptionsByParentCheck(option),
    );

    const optionGroups = chunk(activeOptions, columns);

    if (optionGroups.length <= 0) {
      return <Field type="hidden" name={name} value={null} />;
    }

    const defaultValues = arrayToObject(
      defaultValue ||
        activeOptions
          .filter((op) => op.values && op.values.length === 1)
          .map((op) => ({
            id: op.id,
            value: op.values[0].name,
          })),
    );

    return optionGroups.map(
      (opGroup, index) =>
        opGroup.length > 0 &&
        opGroup
          .map((option, i) => {
            const inputProps = this.getOptionInputProps(option, defaultValues);
            return (
              <div key={option.id} className={inputProps.className || 'span2'}>
                <Field
                  key={`${option.id}_id`}
                  type="hidden"
                  name={`${name}[${index * columns + i}].id`}
                  value={option.id}
                />

                <FadeIn transitionAppear={Boolean(option.parent_id)}>
                  <Field
                    key={option.id}
                    name={`${name}[${index * columns + i}].value`}
                    data-id={option.id}
                    label={`${option.name}${
                      price &&
                      option.price > 0 &&
                      ((product.delivery !== 'giftcard' &&
                        !option.subscription) ||
                        option !== options[0])
                        ? `  +${formatCurrency(option.price, product.currency)}`
                        : ''
                    }`}
                    required={
                      option.required !== undefined
                        ? option.required
                        : !!option.variant
                    }
                    {...inputProps}
                    className={undefined}
                  />
                </FadeIn>
              </div>
            );
          })
          .reduce(
            (p, c, i) =>
              !(i % 2) || c.props.className === 'span4'
                ? p.concat([[c]])
                : (p[p.length - 1].push(c), p),
            [],
          )
          .map((fields, i) => (
            <div className="row" key={i}>
              {fields.length === 2 ? fields : [...fields, <span key="span" />]}
            </div>
          )),
    );
  };

  render() {
    const {
      product,
      defaultValue,
      name = 'options',
      columns = 2,
      price = true,
    } = this.props;
    const { options, bundleOptions } = this.state;

    const rootProductOptions = this.renderInputs({
      options: options,
      product: product,
      defaultValue: get(defaultValue, 'options'),
      name,
      columns,
      price,
    });

    if (isEmpty(bundleOptions)) {
      return rootProductOptions;
    }

    const bundleOptionsInputs = [];

    for (const bundleItem of bundleOptions) {
      const name = `bundle_options.${bundleItem.product.id}`;

      const inputs = this.renderInputs({
        ...bundleItem,
        defaultValue: get(defaultValue, name),
        price: false,
        name,
        columns,
      });

      if (inputs && inputs.length > 0) {
        bundleOptionsInputs.push(
          <div key={bundleItem.product.id}>
            <h4 className="product-options-bundle-item-name">
              {bundleItem.product.name}
            </h4>
            {inputs}
          </div>,
        );
      }
    }

    return (
      <Fragment>
        {rootProductOptions}

        {bundleOptionsInputs.length > 0 && (
          <Fragment>
            <div className="product-options-bundle-heading">Bundle options</div>

            {bundleOptionsInputs}
          </Fragment>
        )}
      </Fragment>
    );
  }
}
