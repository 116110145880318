// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BulkEdit from 'components/bulk/edit';
import actions from 'actions';
import { inflect } from 'utils';
import { bulkEditUpdates } from 'utils/bulk';

const mapStateToProps = (state) => ({
  bulk: state.data.bulk,
  model: state.data.model,
  query: state.data.query,
  selection: state.data.selection,
  locationQuery: state.data.locationQuery,
});

const mapDispatchToProps = (dispatch) => ({
  bulkUpdateSelected: (handler: Function) => {
    return dispatch(actions.data.bulkUpdateSelected('products', handler));
  },

  bulkCancel: () => {
    return dispatch(actions.data.bulkCancel());
  },
});

export class BulkEditModal extends React.PureComponent {
  static contextTypes = {
    notifySuccess: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  state = {};

  constructor(props: Object) {
    super(props);
    const params = props.params || {};
    this.state = {
      initialFields: params.initialFields || props.initialFields || [],
      label: params.label || props.label || 'Records',
      options: params.options || props.options || [],
      onClose: this.onClose.bind(this),
      onSubmit: this.onSubmit.bind(this),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.props.options) {
      this.setState({ options: nextProps.options });
    }
  }

  onClose(event) {
    this.context.closeModal();
    this.props.bulkCancel();
  }

  onSubmit(values) {
    const { bulkUpdateSelected, defaultStoreCurrency } = this.props;

    bulkUpdateSelected((record) =>
      bulkEditUpdates(record, values, defaultStoreCurrency),
    ).then((num) => {
      this.context.notifySuccess(
        `Updated ${inflect(num, this.state.label).toLowerCase()}`,
      );
      this.context.closeModal();
      this.props.bulkCancel();
    });
  }

  render() {
    return <BulkEdit {...this.props} {...this.state} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkEditModal);
