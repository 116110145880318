import React from 'react';
import { find, each, map, assign, xor } from 'lodash';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import pt from 'prop-types';

import { validateSVGFile, getFileFromFile, getImageFromFile } from 'utils';

import segment from 'services/segment';

import actions, { resetInitialState } from 'actions';

import MainLayout from 'components/layout/main';
import { MAX_UPLOAD_SIZE_MB } from 'constants/files';
import { getStorefrontURL } from 'utils/storefront';
import { getTemplateEngine, setTemplateEngine } from 'utils/collection';

import ModalsProvider, { ModalsContext } from '../context/modals';
import EnvironmentProvider, {
  EnvironmentContext,
} from '../context/environment';

const { BASE_URI } = process.env;

const PLAN_WARNING_TIMEOUT = 60 * 60 * 6 * 1000; // 6 hours

const cookies = new Cookies();

/**
 * Get a link to the plans page (if the user isn't already there)
 *
 * export only for testing
 *
 * @param {object} client
 * @param {string} pathname
 * @returns {string}
 */
export function getLinkToPlansPage(client, pathname) {
  const baseUrlEnv = client.test ? `${BASE_URI}/test` : BASE_URI;

  const allowedPaths = [`${baseUrlEnv}/plans`, `${baseUrlEnv}/account`];

  if (
    !allowedPaths.includes(pathname) &&
    !pathname.startsWith(`${baseUrlEnv}/settings/account/invoices/`)
  ) {
    return `${baseUrlEnv}/plans`;
  }

  return '';
}

const mapStateToProps = (state) => ({
  account: state.account,
  client: state.client,
  user: state.user,
  content: state.content,
  flash: state.flash,
  isModalOpened: state.modal.stack.length > 0,
  nav: state.nav,
  primaryStorefront: state.storefronts.primary,
  storefrontURL: getStorefrontURL(state.storefronts.primary),
  isHostedStorefront: state.storefronts.primary?.hosted,
  isLiveStorefront: state.storefronts.primary?.env === 'production',
  session: state.session,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInitialSettings: async () => {
    await Promise.all([
      dispatch(actions.storefronts.fetchPrimary()),
      dispatch(actions.settings.fetch('general')),
      dispatch(actions.settings.fetch('integrations')),
      dispatch(actions.content.loadModelsInitial()),
    ]);
  },

  resetInitialState: () => {
    dispatch(resetInitialState());
    return Promise.all([
      dispatch(actions.storefronts.fetchPrimary()),
      dispatch(actions.settings.fetch('general')),
      dispatch(actions.settings.fetch('integrations')),
      dispatch(actions.content.loadModelsInitial()),
    ]);
  },

  fetchClient() {
    return dispatch(actions.client.fetch({ expand: ['leads', 'owner'] }));
  },

  fetchPlan() {
    dispatch(actions.account.fetchPlan());
  },

  enableLiveEnvironment: () => {
    dispatch(actions.client.enableLiveEnvironment());
  },

  toggleNavGroup: (id) => {
    dispatch(actions.nav.toggleGroup(id));
  },

  updatePreferences: async (
    userValues,
    clientValues,
    clientDefaults = false,
  ) => {
    await (clientDefaults
      ? Promise.all([
          dispatch(actions.client.updatePreferences(clientValues)),
          dispatch(actions.user.updatePreferences(userValues)),
        ])
      : dispatch(actions.user.updatePreferences(userValues)));
  },

  openModal: (id, props) => {
    dispatch(actions.modal.open(id, props));
  },

  refreshModal: () => {
    dispatch(actions.modal.refresh());
  },

  closeModal: (id) => {
    dispatch(actions.modal.close(id));
  },

  uploadFile: (file) => {
    return dispatch(actions.files.uploadFile(file));
  },

  uploadImage: (image) => {
    return dispatch(actions.files.uploadImage(image));
  },

  fetchAddress: (address) => {
    return dispatch(actions.lookup.fetchAddress(address));
  },

  queryLookup: (model, query) => {
    return dispatch(actions.lookup.query(model, query));
  },

  multiModelQueryLookup: (models, queries) => {
    return dispatch(actions.lookup.multiModelQuery(models, queries));
  },

  queryLookupLoading: (value) => {
    return dispatch(actions.lookup.loading(value));
  },

  queryLookupResults: (results, count) => {
    return dispatch(actions.lookup.results(results, count));
  },

  queryLookupCategories: (query) => {
    return dispatch(actions.categories.lookup(query));
  },

  clearLookup: () => {
    return dispatch(actions.lookup.clear());
  },

  updateSetup(id, value) {
    return dispatch(actions.client.updateSetup(id, value));
  },

  notifyCreated: (record) => {
    dispatch(
      actions.flash.success(
        `${
          record?.name
            ? `${record.name} saved`
            : typeof record === 'string'
            ? `${record} saved`
            : 'Saved'
        }`,
      ),
    );
  },

  notifySuccess: (message = 'Success!') => {
    dispatch(actions.flash.success(message));
  },

  notifyError: (message = 'An unknown error occurred') => {
    dispatch(actions.flash.error(message));
  },

  notifyWarning: (message = 'Warning!') => {
    dispatch(actions.flash.warning(message));
  },

  notifyDeleted: (type) => {
    dispatch(actions.flash.success(`${type || 'Record'} deleted`));
  },

  notifyFileSizeUploadError: (record) => {
    dispatch(
      actions.flash.error(`File upload cannot exceed ${MAX_UPLOAD_SIZE_MB} MB`),
    );
  },

  notifySVGFileValidationUploadError: (fileName) => {
    dispatch(actions.flash.error(`Invalid SVG file`));
  },

  setLocaleCodes: (codes) => {
    dispatch(actions.user.setLocaleCodes(codes));
  },

  setCurrencyCodes: (codes) => {
    dispatch(actions.user.setCurrencyCodes(codes));
  },

  setContentView: (view) => {
    dispatch(actions.content.setView(view));
  },

  toggleStandardContent: () => {
    dispatch(actions.content.toggleStandardContent());
  },

  superAccess: (mode) => {
    dispatch(actions.session.superAccess(mode)).then(() => {
      window.location.href = BASE_URI;
    });
  },
});

export class Main extends React.PureComponent {
  static propTypes = {
    children: pt.node,
    location: pt.object,
    router: pt.object,
    routes: pt.array,

    // mapStateToProps
    user: pt.object,
    client: pt.object,

    // mapDispatchToProps
    fetchInitialSettings: pt.func,
    fetchClient: pt.func,
    queryLookup: pt.func,
    multiModelQueryLookup: pt.func,
    fetchAddress: pt.func,
    queryLookupLoading: pt.func,
    queryLookupCategories: pt.func,
    queryLookupResults: pt.func,
    clearLookup: pt.func,
    toggleNavGroup: pt.func,
    uploadFile: pt.func,
    uploadImage: pt.func,
    notifyCreated: pt.func,
    notifySuccess: pt.func,
    notifyError: pt.func,
    notifyWarning: pt.func,
    notifyDeleted: pt.func,
    notifyFileSizeUploadError: pt.func,
    notifySVGFileValidationUploadError: pt.func,
    setLocaleCodes: pt.func,
    setCurrencyCodes: pt.func,
    superAccess: pt.func,

    // Modal context
    openModal: pt.func,
    refreshModal: pt.func,
    closeModal: pt.func,

    // Environment context
    testEnv: pt.string,
    setTestEnv: pt.func,
  };

  static contextTypes = {
    router: pt.object.isRequired,
  };

  static onChange(_store, params) {
    if (params.location.action !== 'REPLACE') {
      const anchor = document.getElementById('scroll-anchor');

      if (anchor) {
        anchor.scrollIntoView();
      } else {
        (document.getElementById('main') || window).scrollTop = 0;
      }
    }
  }

  static childContextTypes = {
    uploadFiles: pt.func,
    uploadImages: pt.func,
    queryLookup: pt.func,
    multiModelQueryLookup: pt.func,
    fetchAddress: pt.func,
    queryLookupLoading: pt.func,
    queryLookupCategories: pt.func,
    queryLookupResults: pt.func,
    clearLookup: pt.func,
    openModal: pt.func,
    refreshModal: pt.func,
    closeModal: pt.func,
    notifyCreated: pt.func,
    notifySuccess: pt.func,
    notifyError: pt.func,
    notifyWarning: pt.func,
    notifyDeleted: pt.func,
    toggleLocale: pt.func,
    toggleCurrency: pt.func,
    toggleTestEnv: pt.func,
    setContentView: pt.func,
    toggleStandardContent: pt.func,
    renderTemplate: pt.func,
    updateNavPreferences: pt.func,
    updateViewPreferences: pt.func,
    resetNavPreferences: pt.func,
    resetViewPreferences: pt.func,
    openIntercom: pt.func,
    superAccess: pt.func,
    testEnv: pt.string,
    getModalSavingActive: pt.func,
    setModalSavingActive: pt.func,
  };

  templateEngine = null;

  constructor(props, context) {
    super(props, context);

    this.paymentWarningTimer = null;

    this.state = {
      loaded: false,
      reloading: false,
      testEnv: undefined,
      showStoreMenu: false,
      showUserMenu: false,
      paymentWarningLocation: null,
      modalSavingActive: false,
      onCloseMenus: this.onCloseMenus.bind(this),
      onClickNavGroup: this.onClickNavGroup.bind(this),
      onClickNavStore: this.onClickNavStore.bind(this),
      onClickNavUser: this.onClickNavUser.bind(this),
      onClickCreateStore: this.onClickCreateStore.bind(this),
    };
  }

  getChildContext() {
    return {
      uploadFiles: this.uploadFiles.bind(this),
      uploadImages: this.uploadImages.bind(this),
      queryLookup: this.props.queryLookup,
      multiModelQueryLookup: this.props.multiModelQueryLookup,
      fetchAddress: this.props.fetchAddress,
      queryLookupLoading: this.props.queryLookupLoading,
      queryLookupResults: this.props.queryLookupResults,
      queryLookupCategories: this.props.queryLookupCategories,
      clearLookup: this.props.clearLookup,
      openModal: this.props.openModal,
      refreshModal: this.props.refreshModal,
      closeModal: this.props.closeModal,
      notifyCreated: this.props.notifyCreated,
      notifySuccess: this.props.notifySuccess,
      notifyError: this.props.notifyError,
      notifyWarning: this.props.notifyWarning,
      notifyDeleted: this.props.notifyDeleted,
      toggleLocale: this.toggleLocale.bind(this),
      toggleTestEnv: this.toggleTestEnv.bind(this),
      toggleCurrency: this.toggleCurrency.bind(this),
      setContentView: this.props.setContentView,
      toggleStandardContent: this.props.toggleStandardContent,
      renderTemplate: this.renderTemplate.bind(this),
      updateNavPreferences: this.updateNavPreferences.bind(this),
      updateViewPreferences: this.updateViewPreferences.bind(this),
      resetNavPreferences: this.resetNavPreferences.bind(this),
      resetViewPreferences: this.resetViewPreferences.bind(this),
      openIntercom: this.openIntercom.bind(this),
      superAccess: this.props.superAccess,
      testEnv: this.state.testEnv,
      getModalSavingActive: this.getModalSavingActive.bind(this),
      setModalSavingActive: this.setModalSavingActive.bind(this),
    };
  }

  componentDidMount() {
    this.mounted = true;

    this.requireTestUri(this.props);

    const { fetchInitialSettings } = this.props;

    if (!this.requireUser(this.props)) {
      return;
    }

    if (!this.requirePlan(this.props)) {
      setTimeout(() => {
        if (this.mounted !== false) this.setState({ loaded: true });
      }, 500);

      return;
    }

    fetchInitialSettings().then(() => {
      this.setTemplateEngine();
      if (this.mounted !== false) this.setState({ loaded: true });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.paymentWarningTimer);
  }

  componentDidUpdate(prevProps) {
    this.requireTestUri(this.props);
    this.requireUser(this.props);

    if (this.requirePlan(this.props)) {
      this.requireIntro(this.props);
    }

    if (this.needsPaymentWarning(this.props)) {
      this.props.openModal('PaymentWarning');
    }

    if (
      prevProps.client !== this.props.client ||
      prevProps.primaryStorefront !== this.props.primaryStorefront
    ) {
      this.setTemplateEngine();
    }
  }

  needsPaymentWarning(props) {
    const { account, client, location, fetchPlan } = props;
    if (location.pathname.indexOf('/settings/account') >= 0) {
      return false;
    }
    // Catch issue where invoice is missing 1/14/2020
    if (
      (account.paymentWarning || account.paymentRequired) &&
      account.invoicesDue.length === 0
    ) {
      if (!this.caughtMissingInvoice) {
        this.caughtMissingInvoice = true;
        console.warn('Invoice missing from subscription', {
          client_id: client.id,
          client_name: client.name,
          invoice_missing: true,
        });
        segment.track(
          'Invoice missing from subscription',
          {
            client_id: client.id,
            client_name: client.name,
          },
          {
            Salesforce: true,
          },
        );
      }
      return false;
    }
    if (account.paymentRequired) {
      if (this.state.paymentWarningLocation !== location.pathname) {
        this.setState({ paymentWarningLocation: location.pathname });
        return true;
      }
    } else if (account.paymentWarning) {
      if (!this.state.paymentWarningLocation) {
        this.setState({ paymentWarningLocation: location.pathname });
        this.paymentWarningTimer = setTimeout(() => {
          this.setState({ paymentWarningLocation: null }, () => fetchPlan());
        }, PLAN_WARNING_TIMEOUT);
        return true;
      }
    }
    return false;
  }

  requirePlan(props) {
    const { client, account, session } = props;
    const { router } = this.context;

    const shouldRequirePlan = Boolean(
      client.id &&
        account.plan &&
        (account.planCanceled || (!account.plan && client.trialExpired)) &&
        (!session.is_super_admin || !session.super_access),
    );

    if (shouldRequirePlan) {
      const redirectLink = getLinkToPlansPage(client, router.location.pathname);

      if (redirectLink) {
        router.replace(redirectLink);
      }

      return false;
    }

    return true;
  }

  // Determines if the environment has changed
  requireTestUri(props) {
    const { client, location, setTestEnv, resetInitialState } = props;
    const { router } = this.context;
    const { loaded, testEnv } = this.state;
    const isPathTestEnv = location.pathname.startsWith(`${BASE_URI}/test`);

    if (client?.test && !isPathTestEnv) {
      router.replace(location.pathname.replace(BASE_URI, `${BASE_URI}/test`));
      return;
    }

    const nextTestEnv = isPathTestEnv ? router.params?.env || 'test' : null;
    if (testEnv !== nextTestEnv) {
      setTestEnv(nextTestEnv);
      this.setState(
        {
          testEnv: nextTestEnv,
          reloading: testEnv !== undefined,
        },
        () => {
          // Reset initial state only if testEnv was set before
          if (testEnv === undefined) {
            this.setTemplateEngine();
          } else if (loaded) {
            resetInitialState().then(() => {
              this.setTemplateEngine();
              this.setState({ reloading: false });
            });
          }
        },
      );
    }
  }

  requireUser(props) {
    const { session } = props;
    const { router } = this.context;
    const { pathname, search, query = {} } = router.location;
    let { cli: cliUrl, docs: docsUrl } = query;

    // if cliUrl is set, we need to send the user to the cliUrl after login
    // or if logged in already.
    //
    // set the cliUrl in a cookie so that we can access it after login easily.
    // a cookie can be accessed anytime and doesn't need query params to be
    // passed around. It also guards against multiple redirects.
    if (cliUrl) {
      let decodedUrl = cliUrl;
      try {
        decodedUrl = atob(cliUrl);
      } catch (err) {
        // may not be encoded
      }
      cookies.set('cliUrl', decodedUrl, { path: '/' });
    }

    if (docsUrl) {
      cookies.set('docsUrl', docsUrl, { path: '/' });
    }

    if (!session.user_id) {
      // eslint-disable-next-line no-useless-escape
      const toRegexp = new RegExp(BASE_URI ? `^\\${BASE_URI}` : '^/');
      const path = (pathname + search).replace(toRegexp, '');

      if (!cliUrl && path.length > 0) {
        router.replace(`${BASE_URI}/login?to=${encodeURIComponent(path)}`);
      } else if (!docsUrl && path.length > 0) {
        router.replace(`${BASE_URI}/login?to=${encodeURIComponent(path)}`);
      } else {
        router.replace(`${BASE_URI}/login`);
      }
      return false;
    }

    // run the appropriate action when cliUrl is set.
    //
    // we need to read from the cookie because the query params are removed
    // after login redirect.
    cliUrl ||= cookies.get('cliUrl');
    const cliStoreId = cookies.get('cliStoreId');
    if (cliUrl && !cliStoreId) {
      router.replace(`${BASE_URI}/cli-success`);
    }

    docsUrl ||= cookies.get('docsUrl');
    const docsStoreId = cookies.get('docsStoreId');
    if (docsUrl && !docsStoreId) {
      router.replace(`${BASE_URI}/docs-login`);
    }

    return true;
  }

  requireIntro(props) {
    const { router } = this.context;
    const shouldRequireIntro = false;
    if (shouldRequireIntro) {
      if (router.location.pathname !== `${BASE_URI}/intro`) {
        router.replace(`${BASE_URI}/intro`);
      }
      return false;
    }
    return true;
  }

  toggleLocale(code) {
    const { localeCodes } = this.props.user;
    const { locale: clientLocale } = this.props.client;
    const nextCodes = xor(localeCodes, [code]);
    if (clientLocale && nextCodes[0] !== clientLocale) {
      nextCodes.unshift(clientLocale);
    }
    this.props.setLocaleCodes(nextCodes);
    return nextCodes;
  }

  toggleCurrency = (code) => {
    const { currencyCodes } = this.props.user;
    const { currency: clientCurrency } = this.props.client;
    const nextCodes = xor(currencyCodes, [code]);
    if (clientCurrency && nextCodes[0] !== clientCurrency) {
      nextCodes.unshift(clientCurrency);
    }
    this.props.setCurrencyCodes(nextCodes);
    return nextCodes;
  };

  /**
   * Toggling between live and test environment.
   * Updates url if environment type is changing.
   * Sets the env state variable to the clients test or live environment object
   */
  toggleTestEnv = async (test, env = undefined) => {
    const { location, client, testEnv, fetchClient, setTestEnv } = this.props;
    const { router } = this.context;

    let nextPath;

    if (testEnv && !test) {
      if (client.test) {
        this.props.openModal('AccountPlanSelection');
        return false;
      }
      nextPath = location.pathname.replace(
        new RegExp(`${BASE_URI}/test[^/]*`),
        BASE_URI,
      );
    } else if (!testEnv && test) {
      nextPath = location.pathname.replace(
        BASE_URI,
        `${BASE_URI}/test${env ? `-${env}` : ''}`,
      );
    } else if (testEnv && test && testEnv !== env) {
      nextPath = location.pathname.replace(
        new RegExp(`${BASE_URI}/test[^/]*`),
        `${BASE_URI}/test${env ? `-${env}` : ''}`,
      );
    }

    const nextTestEnv = test ? env || 'test' : null;
    setTestEnv(nextTestEnv, false);

    const nextClient = await fetchClient();

    if (nextClient) {
      router.push(nextPath);

      setTestEnv(nextTestEnv, true);
    } else {
      setTestEnv(testEnv);
      return false;
    }

    return true;
  };

  updateNavPreferences(values, clientDefaults = false) {
    return this.props.updatePreferences(
      {
        $set: {
          nav: values,
        },
      },
      {
        $set: {
          nav: values,
        },
      },
      clientDefaults,
    );
  }

  updateViews(views, view, values) {
    const updateView = find(views, { id: view.gid });

    if (updateView) {
      assign(updateView, values);
    } else {
      views.push({
        id: view.gid,
        type: view.type,
        collection: view.childCollection || view.collection,
        ...values,
      });
    }
  }

  updateViewPreferences(view, values, clientDefaults = false) {
    // if not owner, reset to store detaults or update
    // if owner, reset to swell detaults or update
    // clientDefaults only used by owner to change store defaults
    const { user, client } = this.props;

    const userViews = user?.preferences?.views || [];
    this.updateViews(userViews, view, values);
    const clientViews = client?.preferences?.views || [];
    if (clientDefaults) {
      this.updateViews(clientViews, view, values);
    }

    return this.props.updatePreferences(
      {
        $set: {
          views: userViews,
        },
      },
      {
        $set: {
          views: clientViews,
        },
      },
      clientDefaults,
    );
  }

  resetNavPreferences(clientDefaults = false) {
    if (clientDefaults) {
      this.props.openModal('ConfirmDelete', {
        titleMessage: `Reset navigation`,
        action: 'Confirm',
        message: (
          <p>Are you sure you want to reset navigation to Swell defaults?</p>
        ),
        onConfirm: () => {
          this.updateNavPreferences(null, clientDefaults);
        },
      });
    } else {
      this.updateNavPreferences(null);
    }
  }

  // Values determine what to reset i.e. { tabs: null }
  resetViewPreferences(view, values, clientDefaults = false) {
    if (clientDefaults) {
      this.props.openModal('ConfirmDelete', {
        titleMessage: `Reset view`,
        action: 'Confirm',
        message: (
          <p>
            Are you sure you want to reset these preferences to Swell defaults?
          </p>
        ),
        onConfirm: () => {
          this.updateViewPreferences(view, values, clientDefaults);
        },
      });
    } else {
      this.updateViewPreferences(view, values);
    }
  }

  setTemplateEngine() {
    const { client, primaryStorefront } = this.props;
    this.templateEngine = getTemplateEngine(client, primaryStorefront);
    setTemplateEngine(this.templateEngine);
  }

  renderTemplate(template, data) {
    this.templateEngine.render(template, data);
  }

  openIntercom = () => {
    if (window.Intercom) {
      window.Intercom('show');
    } else {
      alert(
        "Sorry, we're unable to load the chat window. Please make sure ad-blockers are disabled to use this feature.",
      );
    }
  };

  getModalSavingActive() {
    return this.state.modalSavingActive;
  }

  setModalSavingActive(isActive) {
    this.setState({ modalSavingActive: isActive });
  }

  onCloseMenus(event) {
    event?.preventDefault();
    if (this.mounted !== false) {
      this.setState({ showStoreMenu: false, showUserMenu: false });
    }
  }

  onClickNavStore = (event) => {
    event?.preventDefault();
    if (this.mounted !== false) {
      this.setState((state) => ({ showStoreMenu: !state.showStoreMenu }));
    }
  };

  onClickNavUser(event) {
    event?.preventDefault();
    if (this.mounted !== false) {
      this.setState({ showUserMenu: !this.state.showUserMenu });
    }
  }

  onClickNavGroup(event) {
    event?.preventDefault();
    event.stopPropagation();
    this.props.toggleNavGroup(event.currentTarget.dataset.id);
  }

  onClickCreateStore(event) {
    event?.preventDefault();
    this.setState({ showStoreMenu: false, showUserMenu: false });
    this.props.openModal('CreateStore');
  }

  uploadFiles = async (files) => {
    const {
      uploadFile,
      notifyError,
      notifyFileSizeUploadError,
      notifySVGFileValidationUploadError,
    } = this.props;

    let maxFileSize = 0;
    each(files, (file) => {
      if (file.size > maxFileSize) {
        maxFileSize = file.size;
      }
    });

    if (maxFileSize / 1048576 >= MAX_UPLOAD_SIZE_MB) {
      notifyFileSizeUploadError();
      return;
    }

    return Promise.all(
      map(files, async (file) => {
        if (file.type === 'image/svg+xml') {
          const svgContent = await file.text();
          if (!validateSVGFile(svgContent)) {
            notifySVGFileValidationUploadError(file.name);
            return;
          }
        }

        return getFileFromFile(file).then((file) => {
          return uploadFile(file).then((result) => {
            if (result) {
              if (result.errors) {
                notifyError();
              } else {
                return result;
              }
            }
          });
        });
      }),
    );
  };

  uploadImages = async (files) => {
    const {
      uploadImage,
      notifyError,
      notifyFileSizeUploadError,
      notifySVGFileValidationUploadError,
    } = this.props;

    let maxFileSize = 0;
    for (const file of files) {
      if (file.size > maxFileSize) {
        maxFileSize = file.size;
      }
    }

    if (maxFileSize / 1048576 >= MAX_UPLOAD_SIZE_MB) {
      notifyFileSizeUploadError();
      return;
    }

    return Promise.all(
      map(files, async (file) => {
        if (file.type === 'image/svg+xml') {
          const svgContent = await file.text();
          if (!validateSVGFile(svgContent)) {
            notifySVGFileValidationUploadError(file.name);
            return;
          }
        }

        return getImageFromFile(file).then((image) => {
          return uploadImage(image).then((result) => {
            if (result) {
              if (result.errors) {
                notifyError();
              } else {
                return result;
              }
            }
          });
        });
      }),
    );
  };

  render() {
    const { loaded, reloading, testEnv } = this.state;

    if (!loaded) {
      return null;
    }

    // Render current route only
    if (this.props.routes !== this.props.router.routes) {
      return null;
    }

    return (
      <MainLayout {...this.props} {...this.state}>
        {reloading ? null : testEnv ? (
          <div className="main-test-wrapper" key={testEnv}>
            {this.props.children}
          </div>
        ) : (
          this.props.children
        )}
      </MainLayout>
    );
  }
}

function MainWrapper(props) {
  return (
    <ModalsContext.Consumer>
      {(modals) => (
        <EnvironmentContext.Consumer>
          {(environment) => <Main {...props} {...modals} {...environment} />}
        </EnvironmentContext.Consumer>
      )}
    </ModalsContext.Consumer>
  );
}

class ContextWrapper extends React.Component {
  static onChange(store, params) {
    if (params.location.action !== 'REPLACE') {
      const anchor = document.getElementById('scroll-anchor');

      if (anchor) {
        anchor.scrollIntoView();
      } else {
        (document.getElementById('main') || window).scrollTop = 0;
      }
    }
  }

  render() {
    return (
      <ModalsProvider>
        <EnvironmentProvider>
          <MainWrapper {...this.props} />
        </EnvironmentProvider>
      </ModalsProvider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextWrapper);
