import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { locationWithQuery } from 'utils';

import actions from 'actions';

import ListPage from 'components/pages/category/list';
import CollectionLoading from 'components/collection/loading';
import { getSortedCategoryUpdates } from 'actions/categories';

const mapStateToProps = (state) => ({
  categories: state.categories,
  loading: state.categories.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCategories: async () => {
    return dispatch(actions.categories.fetch());
  },

  searchCategories: async (search) => {
    if (search) {
      return dispatch(actions.categories.search(search));
    }
    return dispatch(actions.categories.clearSearch());
  },

  clearSearch: async () => {
    return dispatch(actions.categories.clearSearch());
  },

  updateCategories: async (sortedResults, origResults) => {
    const updates = getSortedCategoryUpdates(sortedResults, origResults);
    return dispatch(actions.categories.updateBatch(updates));
  },
});

export class Categories extends React.Component {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      onSubmitSearch: this.onSubmitSearch.bind(this),
      onSubmitCategories: this.onSubmitCategories.bind(this),
      onClickNewCategory: this.onClickNewCategory.bind(this),
      onClickEditCategory: this.onClickEditCategory.bind(this),
    };
  }

  componentDidMount() {
    const {
      location,
      fetchCategories,
      searchCategories,
      clearSearch,
    } = this.props;

    fetchCategories()
      .then((result) => {
        if (location.query.search) {
          return searchCategories(location.query.search);
        }

        return clearSearch();
      })
      .then(() => {
        if (location.query.id) {
          this.highlightCategory(location.query.id);
        }
        this.setState({ loaded: true });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.query.id !== prevProps.location.query.id) {
      this.highlightCategory(this.props.location.query.id);
    }
  }

  highlightCategory(id) {
    if (!id) {
      return;
    }

    const layoutContainer = document.getElementsByClassName(
      'layout-container',
    )[0];
    const categoryContainer = document.getElementById(`category-${id}`);

    if (categoryContainer) {
      layoutContainer.scrollTop = categoryContainer.offsetTop;
    }
  }

  async onSubmitCategories(sortedResults) {
    const { categories, updateCategories, fetchCategories } = this.props;
    await updateCategories(sortedResults, categories.results);
    // TODO: this shouldn't be necessary, seems to be a bug here
    await fetchCategories();
  }

  onSubmitSearch({ search }) {
    const { location, searchCategories } = this.props;

    if (location.query.search && location.query.search !== search) {
      const next = locationWithQuery(this.props.location, { search: '' });

      this.props.router.replace(next);
    }

    searchCategories(search);
  }

  onClickNewCategory(event) {
    event.preventDefault();
    this.context.openModal('NewCategory');
  }

  onClickEditCategory(event) {
    event.preventDefault();
    event.target.blur();
    this.context.openModal('EditCategory', {
      params: { id: event.currentTarget.dataset.id },
    });
  }

  render() {
    if (!this.state.loaded) {
      return <CollectionLoading />;
    }

    return (
      <div>
        <ListPage {...this.props} {...this.state} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Categories);
