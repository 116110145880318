import React, { Fragment } from 'react';
import pt from 'prop-types';

import { Field } from 'components/form';

/**
 * Renders a hierarchical list of categories states (active/inactive) when activated by drag and drop
 *
 * @param {object} props - component props
 * @param {object} props.item - category object
 * @returns {JSX.Element} the rendered hierarchical list of categories
 */
function ListStates({ item }) {
  const { id, children = [], active } = item;

  return (
    <Fragment key={id}>
      <Field type="hidden" name={`${id}.active`} value={active} />

      {children.map((item) => (
        <MemoListStates key={item.id} item={item} />
      ))}
    </Fragment>
  );
}

ListStates.propTypes = {
  item: pt.object.isRequired,
};

const MemoListStates = React.memo(ListStates);

export default MemoListStates;
