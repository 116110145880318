// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iTdCT {\n    display: block;\n    font-weight: 500;\n    color: #000;\n    margin-bottom: 10px;\n}\n\n.QZw0R {\n    margin: 0 0 0 10px;\n    cursor: pointer;\n}\n\n.PHIDb {\n    margin: 0 10px 0 0;\n}\n\n.o9ykV {\n    margin-left: 10px;\n    cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./editor/src/components/form/Label/Label.sss"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".root {\n    display: block;\n    font-weight: 500;\n    color: #000;\n    margin-bottom: 10px;\n}\n\n.leftMargin {\n    margin: 0 0 0 10px;\n    cursor: pointer;\n}\n\n.rightMargin {\n    margin: 0 10px 0 0;\n}\n\n.icon {\n    margin-left: 10px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "iTdCT",
	"leftMargin": "QZw0R",
	"rightMargin": "PHIDb",
	"icon": "o9ykV"
};
export default ___CSS_LOADER_EXPORT___;
