import React from 'react';
import { connect } from 'react-redux';

import actions from 'actions';

import LoginLayout from 'components/login/layout';
import PasswordPage from 'components/storefront/password';

const mapStateToProps = (state) => ({
  loading: state.storefronts.loading,
  flash: state.flash,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInfo: () => {
    return dispatch(actions.storefronts.fetchInfo());
  },

  postPassword: (password) => {
    return dispatch(actions.storefronts.postPassword(password));
  },

  flashError: (message) => {
    dispatch(actions.flash.error(message));
  },
});

export class StorefrontPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      info: null,
    };
  }

  componentDidMount() {
    this.props.fetchInfo().then((info) => {
      this.setState({ info, loaded: true });
    });
  }

  onSubmitPassword = async ({ password }) => {
    const { flashError, postPassword } = this.props;

    const result = await postPassword(password);

    if (!result || !result.success) {
      flashError('The password provided is incorrect, please try again');
      return;
    }

    window.location.href = '/';
  };

  render() {
    if (!this.state.loaded) {
      return null;
    }

    return (
      <LoginLayout {...this.props} {...this.state}>
        <PasswordPage
          {...this.props}
          {...this.state}
          onSubmitPassword={this.onSubmitPassword}
        />
      </LoginLayout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorefrontPassword);
