import React from 'react';
import JSONPretty from 'react-json-pretty';
import classNames from 'classnames';
import { get, pick, map, reduce, isObject, kebabCase } from 'lodash';
import pt from 'prop-types';

import { isEmpty, singularize } from 'utils';
import { contentFieldSourceLabel } from 'utils/content';
import { cleanModelField } from 'utils/model';

import Tabs from 'components/tabs';
import { Field, TabView, Button } from 'components/form';
import Help from 'components/tooltip/help';
import Link from 'components/link';
import { FadeIn } from 'components/transitions';
import AppIcon from 'components/apps/icon';
import AppIndicator from 'components/view/app-indicator';

import ModelFields from './fields';

import './model.scss';

export default class ModelForm extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    values: pt.object,
    client: pt.object,
    content: pt.object,
    editable: pt.bool,
    editDesc: pt.bool,
    location: pt.object,
    modal: pt.bool,
    tab: pt.string,

    onClickEditDescription: pt.func,
    setEditedConfirm: pt.func,
    onSubmitRecord: pt.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      json: this.getInitialJSON(props),
      values: {
        ...props.values,
        fields: this.getFilteredFields(props),
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.record !== this.props.record) {
      this.setState({ json: this.getInitialJSON(this.props) });
    }

    if (prevProps.values !== this.props.values) {
      this.setState({
        values: {
          ...this.props.values,
          fields: this.getFilteredFields(this.props),
        },
      });
    }
  }

  getInitialJSON({ record: { raw: model } = {} }) {
    if (!model) {
      return {};
    }
    return {
      ...pick(model, ['name', 'namespace']),
      fields: {
        ...reduce(
          model.fields,
          (acc, field, key) => {
            if (!field) {
              return acc;
            }
            return { ...acc, [key]: cleanModelField(field) };
          },
          {},
        ),
      },
      ...pick(model, [
        'id',
        'events',
        'primary_field',
        'secondary_field',
        'date_created',
        'date_updated',
      ]),
    };
  }

  getFilteredFields(props) {
    const { record = {}, values = {} } = props;
    let nextFields = record.id
      ? record.fields
      : (values.fields || []).filter((field) => field.root_level);
    const filterZone = this.getFilterZone(props);
    if (filterZone && !isEmpty(nextFields)) {
      return filterZone
        ? nextFields.filter((field) => field.admin_zone === filterZone)
        : nextFields;
    }
    return nextFields;
  }

  getFilterZone({ zone, values: { admin_zone } } = this.props) {
    return admin_zone !== undefined ? admin_zone : zone;
  }

  onSubmitFields = async (updatedFields, fieldsOnly = false) => {
    const { record = {}, onSubmitRecord } = this.props;
    if (record.id) {
      const fields = this.getUpdatedFields(updatedFields, record.fields || []);
      this.setState({ submitting: !fieldsOnly });
      const success = await onSubmitRecord({ fields }, fieldsOnly);
      this.setState({ submitting: false });
      return success;
    }
  };

  getUpdatedFields(updatedFields, recordFields) {
    const filterZone = this.getFilterZone();
    if (filterZone) {
      let beforeFields = [];
      const firstIndex = recordFields.findIndex(
        (f) => f.admin_zone === filterZone,
      );
      if (firstIndex > 0) {
        beforeFields = recordFields.slice(0, firstIndex);
      }
      const afterFields = recordFields.filter(
        (f) =>
          f.admin_zone !== filterZone &&
          !beforeFields.find((bf) => f.id === bf.id),
      );
      return [...beforeFields, ...updatedFields, ...afterFields];
    }
    return updatedFields;
  }

  onOpenModal = () => {
    if (this.props.setEditedConfirm) {
      this.props.setEditedConfirm(true);
    }
  };

  onCloseModal = () => {
    if (this.props.setEditedConfirm) {
      this.props.setEditedConfirm(false);
    }
  };

  onClickTab = (tab) => {
    this.setState({ tab });
  };

  renderContentFields() {
    const { record = {} } = this.props;
    const { submitting, values } = this.state;

    return (
      <div className="row">
        <div className="span4">
          {!record.id && <label>Content fields</label>}
          <ModelFields
            {...this.props}
            values={values}
            editable={true}
            onSubmit={this.onSubmitFields}
            onOpenModal={this.onOpenModal}
            onCloseModal={this.onCloseModal}
            editLoadingTitle={
              submitting
                ? `Updating ${singularize(record.name)} model`
                : undefined
            }
          />
        </div>
      </div>
    );
  }

  mapDataFields(fields, standardFields = undefined, path = undefined) {
    const all = map(fields, (field, key) => ({
      ...field,
      id: key,
      path,
      standard: get(standardFields, key) || key === '$app',
      app_id:
        path === '$app'
          ? key
          : path && path.startsWith('$app')
          ? path.split('.')[1]
          : undefined,
    }));

    let mappedFields = [];
    let mappedTopFields = [];
    for (const field of all) {
      const thisField = {
        ...field,
        path,
        fullPath: path ? `${path}.${field.id}` : field.id,
      };

      if (!path && this.isTopField(thisField)) {
        thisField.top = true;
        mappedTopFields.push(thisField);
        continue;
      }

      mappedFields.push(thisField);

      if (field.fields) {
        const subStandardFields = get(field.standard, 'fields');
        mappedFields = [
          ...mappedFields,
          ...this.mapDataFields(
            field.fields,
            subStandardFields,
            path ? `${path}.${field.id}` : field.id,
          ),
        ];
      }
    }
    mappedTopFields.sort(this.sortTopDataFields);
    mappedFields.sort(this.sortDataFields);
    return [...mappedTopFields, ...mappedFields];
  }

  isTopField(field) {
    if (field.id === 'id' || field.unique || field.required) {
      return true;
    }
    return false;
  }

  sortDataFields(a, b) {
    if (a.path === b.path) {
      if (b.id === 'id') {
        return 1;
      }
      if (b.unique) {
        return 1;
      }
      if (a.id === 'id') {
        return -1;
      }
      if (a.unique) {
        return -1;
      }
    }
    return a.fullPath > b.fullPath ? 1 : -1;
  }

  sortTopDataFields(a, b) {
    if (b.id === 'id') {
      return 1;
    }
    if (b.unique) {
      return 1;
    }
    if (a.id === 'id') {
      return -1;
    }
    if (a.unique) {
      return -1;
    }
    return a.fullPath > b.fullPath ? 1 : -1;
  }

  renderDataFields() {
    const {
      record: { raw: model },
    } = this.props;

    const fields = this.mapDataFields(
      model.fields,
      model.standard_model?.fields,
    );
    return (
      <>
        {fields.length === 0 ? (
          <div className="collection-table-container">
            <table className="collection-table headless">
              <tbody>
                <tr>
                  <td align="center" className="muted">
                    No data fields added yet
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="collection-table-container">
            <table className="collection-table outer">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Type</th>
                  <th>Properties</th>
                  {!model.app_id && <th>Source</th>}
                </tr>
              </thead>
              <tbody>
                {fields.map((field, i) => (
                  <tr
                    key={i}
                    className={
                      field.top && !fields[i + 1]?.top ? 'separated' : ''
                    }
                  >
                    <td>
                      <code
                        className={classNames('model-fields-first-cell', {
                          'field-deprecated': field.deprecated,
                        })}
                      >
                        {field.path && (
                          <span className="muted">{field.path}.</span>
                        )}
                        {field.id}
                      </code>
                      {field.description && (
                        <div className="muted">{field.description}</div>
                      )}
                    </td>
                    <td>
                      <code className="muted nowrap">
                        {field.type}
                        {field.value_type ? `[${field.value_type}]` : ''}
                      </code>
                    </td>
                    <td>{this.renderFieldProperties(field)}</td>
                    {!model.app_id && <td>{this.renderFieldSource(field)}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }

  renderFieldLinkProperty(linkModel) {
    const {
      client: { appsById },
      content: { collections },
    } = this.props;

    let linkCollection = collections[linkModel];
    if (linkCollection?.parent) {
      linkCollection = collections[linkCollection.parent];
    }

    const renderLink = (children) =>
      linkCollection ? (
        <Link
          to={`/settings/models/${linkCollection.id}?tab=data`}
          className="collection-table-link"
        >
          {children}
        </Link>
      ) : (
        children
      );

    if (linkModel.startsWith('apps/')) {
      const linkParts = linkModel.split('/');
      const app = appsById[linkParts[1]];
      if (app) {
        return renderLink(
          <>
            to: <AppIcon image={app.logo_icon} name={app.name} size={16} /> /
            {linkParts[2]}
          </>,
        );
      }
    }

    return renderLink(`to: /${linkModel}`);
  }

  renderFieldProperties(field) {
    const hasDefault = !isEmpty(field.default);
    const hasFormula = !isEmpty(field.formula);

    const tags = [
      field.deprecated && 'deprecated',
      !isEmpty(field.enum) &&
        `enum: ${map(field.enum, (val, i) => get(val, 'value', val)).join(
          ', ',
        )}`,
      // field.auto && 'auto',
      field.required && 'required',
      field.unique && 'unique',
      // field.immutable && 'immutable',
      field.readonly && 'readonly',
      ...(field.type === 'link' && [
        field.model
          ? this.renderFieldLinkProperty(field.model)
          : field.url && `to: ${field.url}`,
        field.key && `key: ${field.key}`,
        field.params && (
          <>
            link params <Help message={JSON.stringify(field.params)} />
          </>
        ),
        field.data && (
          <>
            link data <Help message={JSON.stringify(field.data)} />
          </>
        ),
      ]),
      !isEmpty(field.min) && `min: ${field.min}`,
      !isEmpty(field.max) && `max: ${field.max}`,
      hasFormula && (
        <>
          formula <Help message={field.formula} />
        </>
      ),
      hasDefault &&
        (isObject(field.default) ? (
          <>
            default <Help message={JSON.stringify(field.default.$formula)} />
          </>
        ) : (
          `default: ${field.default}`
        )),
    ].filter((tag) => tag);
    return tags.length > 0 ? (
      <ul className="view-body-tags">
        {tags.map((tag, i) => (
          <li key={i} className={classNames({ warning: tag === 'deprecated' })}>
            <code>{tag}</code>
          </li>
        ))}
      </ul>
    ) : (
      <span className="muted">&mdash;</span>
    );
  }

  renderFieldSource(field) {
    const {
      client: { apps, appsBySlug, appsById },
    } = this.props;

    let source = 'Custom';

    console.log(field);

    if (field.content_id) {
      source = contentFieldSourceLabel(field, apps);
    } else if (field.app_id) {
      const app = appsBySlug[field.app_id] || appsById[field.app_id];
      if (app) {
        source = <AppIndicator app={app} icon={false} />;
      }
    } else if (field.standard) {
      source = <span className="muted">Standard</span>;
    }

    return source;
  }

  renderJSON() {
    const { json } = this.state;
    return <JSONPretty className="console-response console-box" data={json} />;
  }

  getNamespace() {
    const { values = {} } = this.props;

    if (values.namespace_select) {
      return values.namespace_select;
    }

    if (values.namespace === 'content') {
      return 'content';
    }

    if (values.namespace) {
      return 'namespace';
    }

    return '';
  }

  getContentNamespace() {
    const { values = {} } = this.props;

    if (values.namespace_select === 'content') {
      return 'content';
    }

    return '';
  }

  render() {
    const {
      values = {},
      record = {},
      editable,
      editDesc,
      location,
      modal,
      client,
    } = this.props;

    const { tab = this.props.tab } = this.state;

    const namespace = this.getNamespace();
    const contentNamespace = this.getContentNamespace();
    const app = client.appsById[record.app_id];

    return (
      <div className="model-form">
        {editable && (
          <FadeIn active={!record.id || editDesc}>
            <fieldset className="full">
              {!record.id && (
                <div className="row">
                  <Field
                    type="radio"
                    label="Namespace"
                    name="namespace_select"
                    buttons={true}
                    defaultValue={namespace}
                    options={[
                      {
                        value: '',
                        label: 'None',
                      },
                      {
                        value: 'namespace',
                        label: 'Custom',
                      },
                      {
                        value: 'content',
                        label: (
                          <>
                            Content
                            <Help message="Content models are automatically accessible from the Storefront API and Swell.js" />
                          </>
                        ),
                      },
                    ]}
                    className="span4 field-with-tooltip"
                  />
                </div>
              )}
              <div className="row">
                <Field
                  type="text"
                  label="Model name"
                  name="label"
                  defaultValue={values.label}
                  required={true}
                  className="span1-3rd"
                />
                {namespace && (
                  <div className="span1-3rd model-form-endpoint-field">
                    <FadeIn>
                      <Field
                        type="code"
                        label="API namespace"
                        name="namespace"
                        help={!record.id && 'Prefix of your API endpoint'}
                        placeholder={contentNamespace}
                        defaultValue={
                          contentNamespace ? '' : kebabCase(values.namespace)
                        }
                        disabled={!!(record.id || contentNamespace)}
                        autoFocus={true}
                        required={true}
                      />
                      <span className="model-form-endpoint-slash">/</span>
                    </FadeIn>
                  </div>
                )}
                <div className="span1-3rd model-form-endpoint-field">
                  <Field
                    type="code"
                    label="API endpoint"
                    name="collection"
                    help={
                      !record.id &&
                      'A new API model will be created with this name'
                    }
                    placeholder={values.collection_default}
                    defaultValue={kebabCase(values.collection)}
                    disabled={!!record.id}
                  />
                  <span className="model-form-endpoint-slash">/</span>
                </div>
                {!namespace && <div className="span1-3rd" />}
              </div>
              <div className="row">
                <Field
                  type="text"
                  label="Description"
                  name="description"
                  defaultValue={values.description}
                  placeholder="Optional"
                  className="span4"
                  // type="textarea"
                  // rows={1}
                  // maxRows={4}
                  // autoSize={true}
                />
              </div>
              {editDesc && (
                <>
                  <div>
                    <Button type="submit" styleType="secondary" size="sm">
                      Save changes
                    </Button>
                    <Button
                      type="cancel"
                      onClick={this.props.onClickEditDescription}
                      size="sm"
                    >
                      Cancel
                    </Button>
                  </div>
                  <br />
                </>
              )}
            </fieldset>
          </FadeIn>
        )}
        {record.id ? (
          <>
            <div className="view-header-tabs">
              <Tabs
                uri="/settings/models"
                location={location}
                items={{
                  default: {
                    label: 'Content fields',
                  },
                  data: {
                    label: 'Data fields',
                  },
                  json: {
                    label: 'JSON',
                  },
                }}
                onClick={modal && this.onClickTab}
                active={tab}
              />
            </div>
            <TabView value={tab} default>
              {this.renderContentFields()}
            </TabView>
            <TabView value={tab} active="data">
              {tab === 'data' && this.renderDataFields()}
            </TabView>
            <TabView value={tab} active="json">
              {tab === 'json' && this.renderJSON()}
            </TabView>
          </>
        ) : (
          this.renderContentFields()
        )}
        {app && <AppIndicator app={app} />}
      </div>
    );
  }
}
