import { get, isEqual, pull, last } from 'lodash';
import { locationWithQuery } from './index';
import { UNSAVED_CHANGES_WARNING } from 'utils/messages';

const { BASE_URI } = process.env;

let LOCALE_SELECTORS = [];
let LOCALE_FIELDS = [];
const LOCALE_FIELD_INITIAL = { localeFieldCount: 0, currencyFieldCount: 0 };

export function registerLocaleSelector(component) {
  LOCALE_SELECTORS.push({ component, state: { ...LOCALE_FIELD_INITIAL } });
}

export function unregisterLocaleSelector(component) {
  LOCALE_SELECTORS = LOCALE_SELECTORS.filter(
    (sel) => sel.component !== component,
  );
}

export function setLocaleSelectorState(component, key, diff = 0) {
  const field = findFieldByComponent(component);
  if (field && field.selector) {
    field.selector.state = {
      ...field.selector.state,
      [key]: field.selector.state[key] + diff,
    };
    field.selector.component.setState(field.selector.state);
  }
}

export function refreshAllLocaleSelectors(self, state, type = undefined) {
  for (const sel of LOCALE_SELECTORS) {
    if (sel.component !== self) {
      sel.component.setState(state);
    }
  }
}

function findFieldByComponent(component) {
  const field = LOCALE_FIELDS.find((field) => field.component === component);
  return field;
}

export function registerLocalizedField(component) {
  const field = findFieldByComponent(component);
  if (!field) {
    LOCALE_FIELDS.push({ component, selector: last(LOCALE_SELECTORS) });
  }
}

export function unregisterLocalizedField(component) {
  const field = findFieldByComponent(component);
  if (field) {
    pull(LOCALE_FIELDS, field.component);
  }
}

export function isEdited(self, values, name) {
  if (!self._editStartTime) {
    self._editStartTime = Date.now();
  } else if (Date.now() - self._editStartTime > 2000) {
    const { record } = self.props;
    if (!isEqual(get(record, name), get(values, name))) {
      return true;
    }
  }
  return false;
}

export function confirmRouteLeave(self) {
  const { route, router } = self.props;

  if (typeof self.context.openModal !== 'function') {
    throw new Error(
      `Component ${self.constructor.name} missing context type openModal() (needed for confirmRouteLeave)`,
    );
  }

  // Source: https://stackoverflow.com/questions/35275344/how-to-use-a-custom-component-with-react-router-route-transitions/35793421#comment60252624_35793421
  let withinHook = false;
  let finalResult = undefined;
  let finalResultSet = false;
  router.setRouteLeaveHook(route || last(self.props.routes), (nextLocation) => {
    if (!self.state.edited || self.state.editedConfirm === false) {
      return;
    }
    withinHook = true;
    if (!finalResultSet) {
      const onConfirm = () => {
        finalResult = true;
        finalResultSet = true;
        if (!withinHook && nextLocation) {
          // Re-schedule the navigation
          self.setState({ edited: false });
          // Reset location path otherwise it gets double-encoded
          const nextPath = `${nextLocation.pathname}${nextLocation.search}`;
          router.push(nextPath);
        }
      };
      self.context.openModal('ConfirmRouteLeave', { onConfirm });
    }
    let result = finalResultSet ? finalResult : false;
    withinHook = false;
    finalResult = undefined;
    finalResultSet = false;
    return result;
  });
}

export function confirmTabLeave(self, nextProps, onLeave) {
  const { router, location } = self.props;

  if (
    self.state.edited &&
    !self._confirmTabChanging &&
    location.query !== nextProps.location.query &&
    self.state.editedConfirm !== false
  ) {
    self._confirmTabChanging = true;
    router.replace(locationWithQuery(location, location.query));
    self.context.openModal('ConfirmRouteLeave', {
      onConfirm: () => {
        self.setState({ edited: false }, () => {
          self._confirmTabChanging = false;
          router.push(
            locationWithQuery(nextProps.location, nextProps.location.query),
          );
          onLeave && onLeave();
        });
      },
      onCancel: () => {
        self._confirmTabChanging = false;
      },
    });
  }

  if (self._confirmTabChanging) {
    return true;
  }
}

export function confirmPageLeave(self, prevState) {
  if (
    !prevState ||
    (prevState.edited && !self.state.edited) ||
    self.state.editedConfirm === false
  ) {
    window.onbeforeunload = null;
  } else if (
    !prevState.edited &&
    self.state.edited &&
    self.state.editedConfirm !== false
  ) {
    window.onbeforeunload = function (event) {
      const message = UNSAVED_CHANGES_WARNING;
      event.returnValue = message;
      return message;
    };
  }
}

const basePathCache = {};

/**
 * Parses router url and returns the following
 * baseUri:
 * isTest: true if url is a test env route. ie (/admin/env-)
 * envName: name of the environment if route is a test env route
 */
export function getBaseAndTestEnvPath() {
  const { pathname } = window.location;
  if (!pathname) {
    return {
      baseUri: BASE_URI,
      envName: '',
      isTest: false,
    };
  }

  if (basePathCache[pathname]) {
    return basePathCache[pathname];
  }

  let baseUri,
    isTest = false,
    envName = '';

  const baseParts = pathname.replace(BASE_URI, '').split('/');
  const maybeEnvRoute = baseParts[1];

  if (new RegExp('test[-/]?').test(maybeEnvRoute)) {
    // Reset base path to include test segment
    baseUri = `${BASE_URI}/${maybeEnvRoute}`;
    envName = maybeEnvRoute.replace('test-', '');
    isTest = true;
  } else {
    baseUri = BASE_URI;
  }

  return (basePathCache[pathname] = {
    baseUri,
    isTest,
    envName,
  });
}
