import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EditModalPage from 'components/pages/modals/edit-category';

import {
  EditCategory,
  mapStateToProps,
  mapDispatchToProps,
} from '../EditCategory';

export class EditCategoryModal extends EditCategory {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifyDeleted: PropTypes.func.isRequired,
  };

  closeOnDelete = true;

  onSubmitRecord(values) {
    return super.onSubmitRecord(values).then((success) => {
      if (success) {
        // this.context.closeModal();
        return this.props.fetchCategories();
      }
    });
  }

  render() {
    return (
      <EditModalPage
        {...this.props}
        {...this.state}
        {...this.methods}
        // Closing the modal window is triggered when the address changes
        onChangePage={undefined}
        onChangeLimit={undefined}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCategoryModal);
