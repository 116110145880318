import React from 'react';
import find from 'lodash/find';
import pt from 'prop-types';
import { Form, Field, Fieldgroup } from 'components/form';
import Link from 'components/link';
import Modal from 'components/modal';
import Secret from 'components/secret';
import ButtonLink from 'components/button/link';
import KebabButton from 'components/button/kebab';
import SectionHeader from 'components/section-header';
import DateTime from 'components/date-time';
import AppIcon from 'components/apps/icon';
import AppIndicator from 'components/view/app-indicator';
import { inflect, nl2br } from 'utils';
import './settings.scss';

export default class APIKeys extends React.PureComponent {
  static propTypes = {
    keys: pt.array,
    source: pt.string,
    loading: pt.bool,
    showPublic: pt.bool,
    showSecret: pt.bool,

    onSaveKey: pt.func,
    onRemoveKey: pt.func,
  };

  static contextTypes = {
    client: pt.object.isRequired,
    openModal: pt.func.isRequired,
    notifySuccess: pt.func.isRequired,
    notifyWarning: pt.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showNewKeyModal: false,
      showEditDescriptionModal: false,
      keyType: null,
      keyId: null,
    };
  }

  onClickNewKey = (event) => {
    event.preventDefault();
    const { type } = event.currentTarget.dataset;
    this.setState({
      showNewKeyModal: true,
      keyType: type,
    });
  };

  onCloseNewKey = () => {
    this.setState({ showNewKeyModal: false });
  };

  onClickEditDescription = (event) => {
    event.preventDefault();
    const keyId = event.currentTarget.dataset.id;
    this.setState({
      showEditDescriptionModal: true,
      keyId,
    });
  };

  onCloseEditDescription = () => {
    this.setState({ showEditDescriptionModal: false });
  };

  onSubmitKeyForm = async (values) => {
    const { keyType } = this.state;
    const success = await this.props.onSaveKey(null, {
      ...values,
      ...(keyType === 'secret' ? { secret: true } : { public: true }),
      ...(values.app_id
        ? { scope: 'app', app_id: values.app_id }
        : { scope: 'user', app_id: undefined }),
    });
    if (success) {
      this.setState({ showNewKeyModal: false });
      this.context.notifySuccess(
        `${keyType === 'secret' ? 'Secret' : 'Public'} key created`,
      );
    }
  };

  onSubmitEditDescription = async (values) => {
    const { keyId } = this.state;
    const success = await this.props.onSaveKey(keyId, values);
    if (success) {
      this.setState({ showEditDescriptionModal: false });
      this.context.notifySuccess('Key description updated');
    }
  };

  onClickRemoveKey = (event) => {
    event.preventDefault();
    const { id } = event.currentTarget.dataset;
    this.context.openModal('ConfirmDelete', {
      title: 'this key',
      onConfirm: async () => {
        const success = await this.props.onRemoveKey(id);
        if (success) {
          this.setState({ showNewKeyModal: false });
          this.context.notifyWarning('Key deleted');
        }
      },
    });
  };

  onClickRevokeKey = (event) => {
    event.preventDefault();
    const { id } = event.currentTarget.dataset;
    this.context.openModal('Confirm', {
      title: 'Revoke key',
      actionType: 'danger',
      message: <p>Are you sure you want to revoke this key?</p>,
      onConfirm: async () => {
        await this.props.onSaveKey(id, {
          revoked: true,
          date_revoked: new Date(),
        });
        this.context.notifyWarning('Key revoked');
      },
    });
  };

  onClickRestoreKey = (event) => {
    event.preventDefault();
    const { id } = event.currentTarget.dataset;
    this.context.openModal('Confirm', {
      title: 'Restore key',
      actionType: 'default',
      message: <p>Are you sure you want to restore this key?</p>,
      onConfirm: async () => {
        await this.props.onSaveKey(id, { revoked: false });
        this.context.notifySuccess('Key restored');
      },
    });
  };

  renderNewKeyModal() {
    const { source, loading } = this.props;
    const { client } = this.context;
    const { keyType } = this.state;

    const appOptions = client.apps.results?.map((installedApp) => ({
      label: installedApp.app.name,
      value: installedApp.app_id,
    }));

    return (
      <Form onSubmit={this.onSubmitKeyForm}>
        <Modal
          title={`New ${keyType} key`}
          width={600}
          actions={[{ label: 'Create key', type: 'submit' }]}
          className="modal-confirm"
          loading={loading}
          onClose={this.onCloseNewKey}
        >
          {!source && (
            <Field
              type="select"
              label="Scope"
              name="app_id"
              options={[{ label: 'User', value: '' }, ...(appOptions || [])]}
              renderIcon={(appId) =>
                client.appsById[appId] && (
                  <AppIcon
                    image={client.appsById[appId]?.logo_icon}
                    name={client.appsById[appId]?.name}
                    size={20}
                  />
                )
              }
              iconWidth={43}
              help={`User keys can access all ${
                keyType === 'public' ? 'public' : 'backend'
              } resources. App keys are restricted by permissions granted to the app.`}
            />
          )}
          <Field
            type="textarea"
            label="Key description"
            name="description"
            autoSize={true}
            rows={3}
            placeholder="Optional"
          />
        </Modal>
      </Form>
    );
  }

  renderEditDescriptionModal() {
    const { keys, loading } = this.props;
    const { keyId } = this.state;

    const key = find(keys, { id: keyId });

    if (!key) {
      return null;
    }

    return (
      <Form
        onSubmit={this.onSubmitEditDescription}
        autoFocus={true}
        autoFocusEmpty={true}
      >
        <Modal
          title={`${key.description ? 'Edit' : 'Add'} key description`}
          width={600}
          actions={[{ label: 'Save', type: 'submit' }]}
          className="modal-confirm"
          loading={loading}
          onClose={this.onCloseEditDescription}
        >
          <Field
            type="textarea"
            name="description"
            autoSize={true}
            rows={3}
            defaultValue={key.description}
            placeholder="Describe this key"
          />
        </Modal>
      </Form>
    );
  }

  renderKeysTable(args) {
    const { source } = this.props;
    const { client } = this.context;

    console.log(client);

    return (
      <>
        <div className="view-body-subheader">
          <SectionHeader
            className="view-body-subtitle"
            title={args.title}
            subtitle={args.subtitle}
          />
          <div>
            {args.active && (
              <ButtonLink
                size="sm"
                type="secondary"
                onClick={this.onClickNewKey}
                data-type={args.type}
              >
                Add {args.type} key
              </ButtonLink>
            )}
          </div>
        </div>

        <div className="view-section-content collection-full">
          {args.keys.length > 0 ? (
            <div className="collection-table-container">
              <table className="collection-table">
                <thead>
                  <tr>
                    <th width="37%">Key</th>
                    {!source && <th width="11%">Scope</th>}
                    <th>Description</th>
                    <th width="5%">Created</th>
                    {!args.active && <th width="5%">Revoked</th>}
                    <th width="5%">&nbsp;</th>
                  </tr>
                </thead>

                <tbody>
                  {args.keys.map((key) => {
                    const description = (key.description || '').trim();
                    return (
                      <tr key={key.id}>
                        <td className={`nowrap ${!args.active ? 'muted' : ''}`}>
                          {key.secret ? (
                            <Secret text={key.secret} numVisible={20} />
                          ) : (
                            <code>{key.public}</code>
                          )}
                        </td>

                        {!source && (
                          <td className="nowrap muted">
                            {key.app_id && client.appsById[key.app_id] ? (
                              <AppIndicator app={client.appsById[key.app_id]} />
                            ) : key.source_id && key.source_model === 'apps' ? (
                              <>{key.source?.name || 'App'}</>
                            ) : key.source_id &&
                              key.source_model === 'storefronts' ? (
                              <Link
                                to={`/storefronts/${key.source_id}?tab=devtools`}
                              >
                                {key.source?.title || 'Storefront'}
                              </Link>
                            ) : key.scope === 'app' ? (
                              'App'
                            ) : (
                              'User'
                            )}
                          </td>
                        )}

                        <td>
                          {nl2br(description) || (
                            <span className="muted">&mdash;</span>
                          )}
                        </td>

                        <td className="nowrap">
                          <DateTime value={key.date_created} format="short" />
                        </td>

                        {!args.active && (
                          <td className="nowrap">
                            {key.date_revoked && (
                              <DateTime
                                value={key.date_revoked}
                                format="short"
                              />
                            )}
                          </td>
                        )}

                        <td className="action">
                          <KebabButton
                            items={[
                              <button
                                key="description"
                                data-id={key.id}
                                onClick={this.onClickEditDescription}
                                type="button"
                              >
                                {`${
                                  key.description ? 'Edit' : 'Add'
                                } description`}
                              </button>,
                              ...(key.revoked
                                ? [
                                    <button
                                      key="restore"
                                      data-id={key.id}
                                      onClick={this.onClickRestoreKey}
                                    >
                                      Restore key
                                    </button>,
                                    <button
                                      key="delete"
                                      data-id={key.id}
                                      className="danger"
                                      onClick={this.onClickRemoveKey}
                                    >
                                      Delete key
                                    </button>,
                                  ]
                                : [
                                    <button
                                      key="revoke"
                                      data-id={key.id}
                                      onClick={this.onClickRevokeKey}
                                    >
                                      Revoke key
                                    </button>,
                                  ]),
                            ]}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="collection-none-found">
              {source ? `This ${source} has` : 'You have'} no{' '}
              {args.active && 'active'} {args.type} keys
            </div>
          )}
        </div>
      </>
    );
  }

  render() {
    const { source, keys, showPublic, showSecret } = this.props;
    const { showNewKeyModal, showEditDescriptionModal } = this.state;

    const secretKeys = keys.filter((key) => !key.revoked && key.secret);
    const publicKeys = keys.filter((key) => !key.revoked && key.public);
    const revokedKeys = keys.filter((key) => key.revoked);

    const showingPublic = showPublic || !source || publicKeys.length > 0;
    const showingSecret = showSecret || !source || secretKeys.length > 0;

    return (
      <div className="settings-api-key-form">
        <div className="view-body-section-group">
          {showingPublic && (
            <div className="view-body-section">
              {this.renderKeysTable({
                active: true,
                type: 'public',
                title: 'Public keys',
                keys: publicKeys,
                subtitle: <span>Used to access frontend API resources</span>,
              })}
            </div>
          )}

          {showingSecret && (
            <div className="view-body-section">
              {this.renderKeysTable({
                active: true,
                type: 'secret',
                title: 'Secret keys',
                keys: secretKeys,
                subtitle: <span>Used to access backend API resources</span>,
              })}
            </div>
          )}

          {revokedKeys.length > 0 && (
            <Fieldgroup
              label={`${inflect(revokedKeys.length, 'revoked keys')}`}
              className="normal"
            >
              <div className="view-body-section">
                {this.renderKeysTable({
                  active: false,
                  title: 'Revoked keys',
                  keys: revokedKeys,
                })}
              </div>
            </Fieldgroup>
          )}
        </div>

        {showNewKeyModal && this.renderNewKeyModal()}

        {showEditDescriptionModal && this.renderEditDescriptionModal()}
      </div>
    );
  }
}
