// @flow
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import SendNotificationModalPage from 'components/pages/modals/send-notification';
import actions from 'actions';

const mapStateToProps = (state) => ({
  subject: state.notifications.record.subject,
  fromEmails: state.notifications.fromEmails,
  loading: state.notifications.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotification: async (id: string) => {
    return dispatch(actions.notifications.fetch(id));
  },

  sendNotification: async (url: string, id: string, args: Object) => {
    return dispatch(actions.notifications.send(url, id, args));
  },
});

export class SendNotificationModal extends React.PureComponent {
  static contextTypes = {
    notifyError: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  static propTypes = {
    title: PropTypes.string.isRequired,
    template: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    recordUrl: PropTypes.string.isRequired,
    recordData: PropTypes.object,
    onSend: PropTypes.func,
  };

  state = {};

  constructor(props: Object) {
    super(props);
    this.state = {
      loaded: false,
      sending: false,
      sent: false,
      onSubmitForm: this.onSubmitForm.bind(this),
    };
  }

  componentWillMount() {
    const { template, fetchNotification } = this.props;

    fetchNotification(template).then(() => {
      this.setState({ loaded: true });
    });
  }

  onSubmitForm(values: Object) {
    const { template, recordUrl, sendNotification, onSend } = this.props;

    this.setState({ sending: true });

    return sendNotification(recordUrl, template, values).then(() => {
      this.setState({ sending: false, sent: true });
      if (onSend) {
        onSend();
      }
      this.context.closeModal();
    });
  }

  render() {
    return <SendNotificationModalPage {...this.props} {...this.state} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendNotificationModal);
