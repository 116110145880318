import React from 'react';
import FileReader from '../../form/file-reader';
import Modal from '../../modal/modal';
import Button from '../../button/button';
import Image from 'components/loading/image';
import Loading from '../../loading/loading';
import Icon from '../../icon/icon';
import { imageUrl } from 'utils';
import './insert-image.scss';

export default function InsertImageModalPage({
  files,
  images,
  page,
  selectedId,
  onClickImage,
  onClickInsert,
  onClickNextPage,
  onClickPrevPage,
  onUploadImage,
}) {
  return (
    <Modal title="Insert image" className="modal-insert-image">
      {files.loading ? (
        <Loading />
      ) : images.length ? (
        <div>
          <div
            className={`insert-image-list ${files.loading ? 'loading' : ''}`}
          >
            {page > 1 && (
              <a
                href=""
                className="insert-image-nav prev"
                onClick={onClickPrevPage}
              >
                <Icon fa="chevron-left" />
              </a>
            )}
            <ul>
              {images.slice((page - 1) * 5, page * 5).map((image) => (
                <li
                  key={image.id}
                  className={selectedId === image.id ? 'selected' : ''}
                >
                  <Image
                    src={imageUrl(image, {
                      width: 100,
                      height: 100,
                      padded: true,
                    })}
                    alt={image.filename}
                    data-id={image.id}
                    onClick={onClickImage}
                  />
                </li>
              ))}
            </ul>
            {files.collection.totalPages > page && (
              <button
                type="button"
                className="insert-image-nav next"
                onClick={onClickNextPage}
              >
                <Icon fa="chevron-right" />
              </button>
            )}
          </div>
          <div>
            <FileReader accept="image/*" onFiles={onUploadImage}>
              <Button type="secondary" size="sm">
                Upload Image
              </Button>
              <Button
                type={selectedId ? 'default' : 'secondary disabled'}
                size="sm"
                data-prevent-click={true}
                onClick={selectedId ? onClickInsert : null}
              >
                Insert image
              </Button>
            </FileReader>
          </div>
        </div>
      ) : (
        <div className="insert-image-emptystate">
          <FileReader accept="image/*" onFiles={onUploadImage}>
            <Button>Upload image</Button>
          </FileReader>
        </div>
      )}
    </Modal>
  );
}
