import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Link from 'components/link';
import Modal from 'components/modal';
import { Form, Field, Label } from 'components/form';
import Tooltip from 'components/tooltip';
import Checkbox from 'components/icon/checkbox';
import Alert from 'components/alert';
import { formatDate } from 'utils';

export default class OrderDiscountsEdit extends React.PureComponent {
  static contextTypes = {
    account: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      values: props.record,
      selectedAllPromotions: this.getInitialSelectedAllPromotions(props),
      selectedPromotionIds: this.getInitialSelectedPromotionIds(props),
    };
    this.onChangeForm = this.onChangeForm.bind(this);
    this.onClickSelectAllPromotions =
      this.onClickSelectAllPromotions.bind(this);
    this.onClickSelectPromotion = this.onClickSelectPromotion.bind(this);
  }

  getInitialSelectedAllPromotions({ record, discounts, promotions }) {
    if (promotions === false) {
      return false;
    }
    if (!record.promotion_ids || !discounts || !discounts.promotions) {
      return false;
    }
    const promoIds = discounts.promotions.results.map((promo) => promo.id);
    return record.promotion_ids.length === promoIds.length;
  }

  getInitialSelectedPromotionIds({ record, discounts, promotions }) {
    if (promotions === false) {
      return [];
    }
    if (!record.promotion_ids || !discounts || !discounts.promotions) {
      return [];
    }
    return record.promotion_ids;
  }

  onChangeForm(values) {
    this.setState({ values: { ...this.state.values, ...values } });
  }

  onClickSelectAllPromotions(event, value) {
    event.preventDefault();
    const { selectedAllPromotions } = this.state;
    if (selectedAllPromotions) {
      this.setState({
        selectedAllPromotions: false,
        selectedPromotionIds: [],
      });
    } else {
      this.setState({
        selectedAllPromotions: true,
        selectedPromotionIds: this.props.discounts.promotions.results.map(
          (promo) => promo.id,
        ),
      });
    }
  }

  onClickSelectPromotion(event, value) {
    const id = event.currentTarget.dataset.id;
    const { selectedPromotionIds } = this.state;
    const selectedIndex = selectedPromotionIds.indexOf(id);
    if (selectedIndex === -1) {
      selectedPromotionIds.push(id);
    } else {
      delete selectedPromotionIds[selectedIndex];
    }
    this.setState({
      selectedPromotionIds: [...selectedPromotionIds],
      selectedAllPromotions:
        selectedPromotionIds.length ===
        this.props.discounts.promotions.results.length,
    });
  }

  onSubmitDiscountForm = (values) => {
    const { onSubmitDiscountsEdit } = this.props;
    const { selectedPromotionIds } = this.state;
    onSubmitDiscountsEdit({
      ...values,
      promotion_ids: selectedPromotionIds,
    });
  };

  render() {
    const {
      record: { discounts = [], coupon_code },
      loading,
      onClickDiscountsEdit,
    } = this.props;

    const discountData = this.props.discounts;

    const hasCoupons = discountData.coupon_count > 0;
    const hasPromos =
      discountData.promotions && discountData.promotions.count > 0;

    return (
      <Form
        onSubmit={this.onSubmitDiscountForm}
        onChange={this.onChangeForm}
        autoFocus={true}
      >
        <Modal
          title={
            discounts && discounts.length > 0
              ? 'Edit discounts'
              : 'Apply discounts'
          }
          className="order-view-modal-edit-discounts"
          actions={[
            hasCoupons || hasPromos
              ? {
                  label: 'Save',
                  type: 'submit',
                }
              : {
                  label: 'Close',
                  type: 'secondary',
                  onClick: onClickDiscountsEdit,
                },
            { label: 'Cancel', type: 'cancel', onClick: onClickDiscountsEdit },
          ]}
          width={500}
          cancel={false}
          loading={loading}
          onClose={onClickDiscountsEdit}
        >
          <fieldset>
            {!hasCoupons ? (
              <Alert type="info">
                No coupons found.&nbsp;
                <Link to="/coupons/new">Create a coupon &rarr;</Link>
              </Alert>
            ) : (
              <Field
                type="text"
                name="coupon_code"
                label="Coupon code"
                defaultValue={coupon_code}
                placeholder={coupon_code}
              />
            )}

            {this.props.promotions &&
              (!hasPromos ? (
                <Alert type="info">
                  No promotions found.&nbsp;
                  <Link to="/promotions/new">Create a promo &rarr;</Link>
                </Alert>
              ) : (
                <Fragment>
                  <div className="row">
                    <div className="span4">
                      <Field
                        type="hidden"
                        className="hidden"
                        name="promotion_ids"
                        value={this.state.selectedPromotionIds}
                      />

                      <Label>Promotions</Label>

                      <div className="collection-table-container">
                        <table className="collection-table headless">
                          <tbody>
                            {discountData.promotions.results.map((promo) => (
                              <tr key={promo.id}>
                                <td className="select" width="25">
                                  <Checkbox
                                    onClick={this.onClickSelectPromotion}
                                    data-id={promo.id}
                                    checked={this.state.selectedPromotionIds.includes(
                                      promo.id,
                                    )}
                                  />
                                </td>

                                <td
                                  onClick={this.onClickSelectPromotion}
                                  data-id={promo.id}
                                  className="pointer"
                                  style={{ paddingLeft: 0 }}
                                >
                                  {promo.name}
                                </td>

                                <td width="25">
                                  {!!promo.date_end && (
                                    <Tooltip
                                      className="negative"
                                      message={`Promotion ends ${formatDate(
                                        promo.date_end,
                                        'shortExact',
                                      )}`}
                                    >
                                      <span className="help icon icon-info">
                                        &nbsp;
                                      </span>
                                    </Tooltip>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="span4">
                      {discountData.promotions.count > 1 && (
                        <div className="collection-pages">
                          <a href="" onClick={this.onClickSelectAllPromotions}>
                            {this.state.selectedAllPromotions ? (
                              <span className="muted">
                                Clear all promotions
                              </span>
                            ) : (
                              'Apply all promotions'
                            )}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </Fragment>
              ))}
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
