import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ObjectID from 'bson-objectid';
import { get, set, map, each, find, reduce } from 'lodash';

import { isEmpty } from 'utils';
import { VARIANT_TYPES } from 'utils/product';

import { Form, Field } from 'components/form';
import { FadeIn } from 'components/transitions';
import Button from 'components/button/button';
import Modal from 'components/modal';

import './product.scss';

export default class ProductOptionsEdit extends React.Component {
  static propTypes = {
    setCountDraftOptions: PropTypes.func.isRequired,
  };

  static childContextTypes = {
    formValues: PropTypes.object,
    registerField: PropTypes.func,
    unregisterField: PropTypes.func,
    onChangeField: PropTypes.func,
  };

  getChildContext() {
    return {
      formValues: {},
      registerField: this.registerField,
      unregisterField: this.unregisterField,
      onChangeField: null,
    };
  }

  static contextTypes = {
    registerField: PropTypes.func,
    unregisterField: PropTypes.func,
    onChangeField: PropTypes.func,
    openModal: PropTypes.func,
    refreshModal: PropTypes.func,
    closeModal: PropTypes.func,
  };

  fieldCanFocus = false;

  constructor(props) {
    super(props);
    this.state = {
      value: this.getInitialValue(props),
      valueMeta: this.getInitialValueMeta(props),
      disabled: props.disabled,
      showMoreForm: false,
      moreIndex: null,
      moreValue: null,
    };
    this.fields = {};
    this.registerField = this.registerField.bind(this);
    this.unregisterField = this.unregisterField.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onClickAddOption = this.onClickAddOption.bind(this);
    this.onClickRemoveOption = this.onClickRemoveOption.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.disabled !== this.props.disabled) {
      this.setState({ disabled: nextProps.disabled });
    }
    if (
      nextProps.defaultValue !== this.props.defaultValue ||
      nextProps.variable !== this.props.variable
    ) {
      this.setState(
        {
          value: this.getInitialValue(nextProps),
        },
        () => {
          this.onChangeField();
        },
      );
    }
  }

  componentDidMount() {
    if (this.context.registerField) {
      this.context.registerField(this);
    }

    setTimeout(() => (this.fieldCanFocus = true), 500);
  }

  componentWillUnmount() {
    if (this.context.unregisterField) {
      this.context.unregisterField(this);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.showEditValue && this.state.showEditValue) {
      this.context.openModal(
        `product_options_edit_value`,
        this.renderValueForm,
      );
    } else if (!prevState.showMoreForm && this.state.showMoreForm) {
      this.context.openModal(`product_options_more_form`, this.renderMoreForm);
    } else if (prevState.showEditValue && !this.state.showEditValue) {
      this.context.closeModal(`product_options_edit_value`);
    } else if (prevState.showMoreForm && !this.state.showMoreForm) {
      this.context.closeModal(`product_options_more_form`);
    } else if (this.state.showEditValue || this.state.showMoreForm) {
      this.context.refreshModal();
    }
  }

  registerField(component) {
    this.fields[component.props.name] = component;
    if (this.fieldCanFocus && component.props.autoFocus && component.focus) {
      setTimeout(() => {
        component.focus();
      }, 200);
    }
  }

  unregisterField(component) {
    delete this.fields[component.props.name];
  }

  getInitialValue(props) {
    const { variable, defaultValue = [] } = props;
    const { value = [] } = this.state || {};

    if (variable) {
      if (isEmpty(value)) {
        return map(defaultValue, (option) => ({
          ...option,
          values: (option.values || []).map((value) => value.name),
        }));
      }

      return value;
    }

    return reduce(
      value,
      (acc, option) => {
        if (option.name || !isEmpty(option.values)) {
          acc.push(option);
        }
        return acc;
      },
      [],
    );
  }

  getValueMeta(value) {
    return value.reduce((acc, option, index) => {
      acc[index] = (option?.values || []).reduce((acc, value) => {
        acc[value.name] = value;
        return acc;
      }, {});

      return acc;
    }, {});
  }

  getInitialValueMeta(props) {
    if (!isEmpty(props.defaultValue)) {
      return this.getValueMeta(props.defaultValue);
    }
    return {};
  }

  onChangeField() {
    const { setCountDraftOptions } = this.props;
    const { value } = this.state;
    if (this.context.onChangeField) {
      //this.context.onChangeField(this);
    }

    setCountDraftOptions(value.length);
  }

  onClickAddOption(event) {
    event.preventDefault();
    const { setCountDraftOptions } = this.props;

    this.setState((state) => {
      const value = [...state.value];

      value.push({
        id: ObjectID().toHexString(),
        name: '',
        values: [],
        variant: true,
        input_type: 'select',
      });

      setCountDraftOptions(value.length);

      return { ...state, value };
    });
  }

  onClickRemoveOption(event) {
    event.preventDefault();

    const { index } = event.target.dataset;

    this.setState(
      (state) => {
        const value = [...state.value];
        value.splice(index, 1);
        return { ...state, value };
      },
      () => this.onChangeField(),
    );
  }

  onChangeInput(event, val) {
    if (!event.target) {
      return;
    }

    const { name, value: targetValue } = event.target;

    this.setState(
      (state) => {
        const value = [...state.value];
        set(value, name, val || targetValue);
        return { ...state, value };
      },
      () => this.onChangeField(),
    );
  }

  validate() {
    let isValid = true;
    each(this.fields, (component, name) => {
      isValid = component.validate() && isValid;
    });
    return isValid;
  }

  disabled(isDisabled) {
    this.setState({ disabled: isDisabled });
  }

  value() {
    const { disabled, value, valueMeta } = this.state;

    if (disabled) {
      return [];
    }

    return map(value, (option, index) => {
      const values =
        (option.input_type === 'select'
          ? option.select_values
          : option.input_type === 'toggle'
          ? option.toggle_values
          : null) || option.values;

      return {
        ...option,
        id: option.id || ObjectID().toHexString(),
        name: option.name,
        select_values: undefined,
        toggle_values: undefined,
        values: values?.map((optionValue) => {
          let object;
          let name;

          if (typeof optionValue === 'string') {
            name = optionValue;
            if (option.id) {
              const origOption = find(this.props.defaultValue, {
                id: option.id,
              });
              if (origOption) {
                object = find(origOption.values, { name: optionValue });
              }
            }
          } else {
            object = optionValue;
            name = object.name;
          }
          object = object || {};

          return {
            id: ObjectID().toHexString(),
            ...get(valueMeta, `[${index}][${name}]`, {}),
            name,
          };
        }),
      };
    });
  }

  onClickMore = (event) => {
    event.preventDefault();

    const { index } = event.currentTarget.dataset;

    this.setState((state) => ({
      showMoreForm: true,
      moreIndex: index,
      moreValue: state.value[index],
    }));
  };

  onSubmitMoreForm = (values) => {
    this.setState((state) => {
      const { valueMeta, moreIndex } = state;
      const value = [...state.value];

      if (values.input_type === 'select') {
        values.values = values.select_values;
      } else if (values.input_type === 'toggle') {
        values.values = values.toggle_values;
      }

      value[moreIndex] = {
        ...values,
        variant: VARIANT_TYPES.includes(values.input_type)
          ? values.variant
          : false,
      };

      return {
        showMoreForm: false,
        value,
        valueMeta: {
          ...valueMeta,
          [moreIndex]: {
            ...(valueMeta[moreIndex] || undefined),
            [values.name]:
              values.input_type === 'toggle' ? values.values[0] : values,
          },
        },
      };
    });
  };

  onChangeMoreForm = (values) => {
    this.setState((state) => ({
      moreValue: {
        ...state.moreValue,
        ...values,
      },
    }));
  };

  onCloseMoreForm = () => {
    this.setState({ showMoreForm: false });
  };

  onClickCloseValueForm = (event) => {
    event.preventDefault();
    this.onCloseMoreForm();
  };

  renderMoreForm = () => {
    const { value, moreValue, moreIndex } = this.state;
    return (
      <Form
        onSubmit={this.onSubmitMoreForm}
        onChange={this.onChangeMoreForm}
        autoFocus={true}
      >
        <Modal
          title="Edit option"
          width={750}
          actions={[
            { label: 'Save', type: 'submit' },
            { label: 'Cancel', type: 'cancel', onClick: this.onCloseMoreForm },
          ]}
          cancel={false}
          onClose={this.onCloseMoreForm}
          localized={true}
          devtools={
            this.props.renderDevtools && this.props.renderDevtools(moreValue)
          }
        >
          <fieldset>
            {this.props.renderMoreForm(moreValue, moreIndex, value)}
          </fieldset>
        </Modal>
      </Form>
    );
  };

  render() {
    const {
      /*attributes,*/ editingOptions,
      onClickEditOptions,
      onClickSaveOptions,
    } = this.props;

    const { value, disabled } = this.state;

    return (
      <Fragment>
        <div className="product-options">
          {map(value, (option, index) => (
            <FadeIn key={index}>
              <span className="product-options-name">
                <Field
                  label="Name"
                  name={`${index}.name`}
                  value={option.name || ''}
                  onChange={this.onChangeInput}
                  placeholder="e.g. Color, Size"
                  rules={['required']}
                  disabled={disabled}
                  autoFocus={true}
                />
                {/*<Field
                  type="select"
                  label="Name"
                  name={`${index}.name`}
                  value={option.name || ''}
                  onChange={this.onChangeInput}
                  placeholder="e.g. Color, Size"
                  options={attributes.results.filter((attr) => attr.variant)}
                  disabled={disabled}
                  newable={true}
                  required={true}
                />*/}
              </span>

              <span className="product-options-values">
                {!option.input_type || option.input_type === 'select' ? (
                  <Field
                    type="tags"
                    label="Values"
                    name={`${index}.values`}
                    value={option.values || []}
                    onChange={this.onChangeInput}
                    placeholder="Separate values with a comma"
                    rules="required, unique"
                    disabled={disabled}
                    onSelectValue={this.props.onSelectTagValue.bind(
                      this,
                      index,
                    )}
                    onRemoveValue={this.props.onRemoveTagValue.bind(
                      this,
                      index,
                    )}
                    renderTag={this.props.renderTagValue.bind(this, index)}
                  />
                ) : (
                  <Fragment>
                    <label>Values</label>
                    <span className="product-options-values-placeholder" />
                  </Fragment>
                )}
              </span>

              <button
                data-index={index}
                className="button-input product-options-more"
                onClick={this.onClickMore}
                type="button"
              >
                More
              </button>

              <button
                data-index={index}
                className="button-input product-options-remove"
                onClick={this.onClickRemoveOption}
                type="button"
              >
                &times;
              </button>
            </FadeIn>
          ))}
        </div>

        <div className="form-field product-options-edit-actions">
          {editingOptions && (
            <Button type="default" size="sm" onClick={onClickSaveOptions}>
              Save options
            </Button>
          )}

          <button
            className="button button-secondary button-sm form-fieldlist-add form-link"
            onClick={this.onClickAddOption}
            type="button"
          >
            Add option
          </button>

          {editingOptions && (
            <Button
              size="sm"
              type="secondary"
              className="button-cancel"
              onClick={onClickEditOptions}
            >
              Cancel
            </Button>
          )}
        </div>
      </Fragment>
    );
  }
}
