'use strict';

const currency = require('currency.js');
const qs = require('qs');
const {
  get,
  set,
  unset,
  isEmpty,
  reduce,
  find,
  cloneDeep,
  capitalize,
  words,
  isNumber,
} = require('lodash');

function slugify(value) {
  if (value) {
    const slug = String(value)
      .replace(/[^a-zA-Z0-9\-_\s:.]/g, '')
      .replace(/[_\s:.-]+/g, '-')
      .replace(/^[-]+|[-]+$/g, '')
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .toLowerCase();
    return slug;
  }
  return null;
}

function wordify(value) {
  return capitalize(words(value).join(' '));
}

/**
 * Flex slug allows both snake and kebab case
 *
 * @param {string} value
 */
function slugifyFlex(value) {
  if (value) {
    const slug = String(value)
      .replace(/[^a-zA-Z0-9\-_\s:.]/g, '')
      .replace(/[\s:.]+/g, '-')
      .replace(/[_]+/g, '_')
      .replace(/[-]+/g, '-')
      .replace(/^[_-]+|[_-]+$/g, '')
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .toLowerCase();
    return slug;
  }
  return null;
}

function snakeCase(value) {
  if (value) {
    return String(value)
      .replace(/[^a-zA-Z0-9\-_\s]/g, '')
      .replace(/[_\s-]+/g, '_')
      .replace(/([a-z])([A-Z])/g, '$1_$2')
      .toLowerCase();
  }
  return null;
}

function isCircular(origObj, key = null, seen = new WeakSet(), obj = null) {
  obj = obj || origObj;

  if (!(obj instanceof Object)) {
    throw new TypeError(
      'isCircular: first parameter must be an object (or inherit from it)',
    );
  }

  seen.add(obj);

  for (const [objKey, val] of Object.entries(obj)) {
    if (!key || key === objKey) {
      if (val instanceof Object) {
        if (seen.has(val) || isCircular(origObj, key, seen, val)) {
          return true;
        }
      }
    }
  }

  seen.delete(obj);

  return false;
}

/**
 * qs ignores empty array/object and prevents us from sending `?array[]=`.
 * This is a workaround to map an empty array to `[null]` so it gets treated
 * as an empty string.
 *
 * @see https://github.com/ljharb/qs/issues/362
 *
 * @param {object} params
 * @returns {object}
 */
function fixParamsForQueryString(params) {
  if (Array.isArray(params)) {
    for (let i = 0; i < params.length; ++i) {
      const value = params[i];

      if (typeof value === 'object' && value !== null) {
        params[i] = fixParamsForQueryString(
          Array.isArray(value) ? [...value] : { ...value },
        );
      }
    }
  }

  for (const [key, value] of Object.entries(params)) {
    switch (typeof value) {
      case 'object': {
        if (Array.isArray(value)) {
          if (value.length <= 0) {
            params[key] = [null];
          } else {
            params[key] = fixParamsForQueryString([...value]);
          }
        } else if (
          value !== null &&
          Object.getPrototypeOf(value) === Object.prototype
        ) {
          params[key] = fixParamsForQueryString({ ...value });
        }

        break;
      }

      default:
        break;
    }
  }

  return params;
}

/**
 * Serializes a query string object into a string.
 */
function stringifyQuery(params) {
  params = fixParamsForQueryString({ ...params });

  const str = qs.stringify(params, {
    // Use strict null value handling. This matches the platform API parsing.
    // https://gitlab.com/schema/schema-api-server/-/blob/6eb33fee1905f31a77b1ce5d7c85a539d3c5eaa0/server/util.js#L993
    // a=null => a
    // a=''   => a=
    strictNullHandling: true,
    // Encode only the values, for better readability of the query string
    encodeValuesOnly: true,
  });

  return str ? `?${str}` : '';
}

const PREDEFINED_LOOKUP_TYPES = {
  product: {
    url: '/products',
  },
  product_lookup: {
    url: '/products',
  },
  lookup_products: {
    url: '/products',
    data: {
      include: {
        variants: {
          url: '/products/{id}/variants',
        },
      },
    },
  },
  'lookup_products:variants': {
    url: '/products:variants',
    data: {
      include: {
        product_image: {
          url: '/products/{parent_id}/images/0',
        },
      },
    },
  },
  variant_lookup: {
    url: '/products:variants',
  },
  category: {
    url: '/categories',
  },
  category_lookup: {
    url: '/categories',
  },
  customer_lookup: {
    url: '/accounts',
  },
  content: {
    url: '/content/...',
  },
  // Custom type defs are added on the fly
  lookup: {
    url: '/...',
  },
  menu: {
    url: '/...',
  },
};

function forEachMenuItem(items, callback, path = undefined) {
  if (items) {
    items.forEach((item, index) => {
      if (item) {
        const thisPath =
          path !== undefined ? `${path}.items.${index}` : `items.${index}`;
        callback(item, thisPath);
        forEachMenuItem(item.items, callback, thisPath);
      }
    });
  }
}

function contentLookupKey(field) {
  // Note: lookup key needs to be nested in field ID, if defined
  if (field.key) {
    return field.key;
  }
  let defaultKey;
  switch (field.type) {
    case 'product_lookup':
      defaultKey = 'product_id';
      break;
    case 'variant_lookup':
      defaultKey = 'variant_id';
      break;
    case 'category_lookup':
      defaultKey = 'category_id';
      break;
    case 'customer_lookup':
      defaultKey = 'account_id';
      break;
    default:
      if (field.id) {
        if (field.type === 'menu') {
          defaultKey = field.id;
        } else if (field.value_type === 'collection') {
          defaultKey = `${field.id}_ids`;
        } else {
          defaultKey = `${field.id}_id`;
        }
      }
      break;
  }
  return defaultKey;
}

let populateTimer = 0;
let populateResolvers = [];
let populateLookupQueries = [];

function getPopulateLookupQueries() {
  return populateLookupQueries;
}

/**
 * @param {Record<string, any>} values
 * @param {object[]} fields
 * @param {((queries) => Promise<any>) | undefined} getter
 * @param {Record<string, any>} [handlers={}]
 */
async function populateLookupValues(values, fields, getter, handlers = {}) {
  if (populateTimer) {
    clearTimeout(populateTimer);
  }

  const promise = new Promise((resolve) => {
    populateResolvers.push(resolve);
  });

  for (const field of fields) {
    let lookupType, lookupTypeId;
    // Use dynamic type + collection (reset at the end)
    const collection = field.collection || field.model;

    if (collection) {
      // this is so we support old (no prefix) and new (`content/` prefix)
      // content collection names
      const composedLookupTypeId = collection.startsWith('content/')
        ? collection
        : `${field.type}_${collection}`;

      if (field.type === 'content') {
        PREDEFINED_LOOKUP_TYPES[composedLookupTypeId] = PREDEFINED_LOOKUP_TYPES[
          composedLookupTypeId
        ] || {
          // same idea here
          url: collection.startsWith('content/')
            ? collection
            : `/content/${collection}`,
        };
      } else if (field.type === 'lookup') {
        PREDEFINED_LOOKUP_TYPES[composedLookupTypeId] = PREDEFINED_LOOKUP_TYPES[
          composedLookupTypeId
        ] || {
          url: `/${collection}`,
        };
      }

      if (PREDEFINED_LOOKUP_TYPES[composedLookupTypeId]) {
        lookupType = PREDEFINED_LOOKUP_TYPES[composedLookupTypeId];
        lookupTypeId = composedLookupTypeId;
      }
    }

    lookupTypeId = lookupTypeId || field.type;
    lookupType = lookupType || PREDEFINED_LOOKUP_TYPES[field.type];

    // Menu lookup is handled separately in populateMenuValues()
    if (lookupTypeId === 'menu' || !lookupType) {
      continue;
    }

    const handlerType = handlers[field.type] || {};

    let value = handlerType.value
      ? handlerType.value(field)
      : get(values, field.id);

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      continue;
    }

    if (!value) {
      value = get(values, contentLookupKey(field));
    }

    if (value) {
      const lookupField = { ...field, lookupTypeId };
      let merged = false;

      const lq = populateLookupQueries.find((lq) => lq.getter === getter);

      if (lq) {
        lq.ids[lookupTypeId] = lq.ids[lookupTypeId] || [];

        if (Array.isArray(value)) {
          lq.ids[lookupTypeId].push(...value);
        } else {
          lq.ids[lookupTypeId].push(value);
        }

        lq.values.push(values);
        lq.lookupFields.push(lookupField);

        merged = true;
      }

      if (!merged) {
        populateLookupQueries.push({
          getter,
          handlers,
          ids: { [lookupTypeId]: Array.isArray(value) ? [...value] : [value] },
          values: [values],
          lookupFields: [lookupField],
        });
      }
    }
  }

  populateTimer = setTimeout(async () => {
    populateTimer = 0;
    const thisResolvers = populateResolvers;
    const thisLookupQueries = populateLookupQueries;

    populateResolvers = [];
    populateLookupQueries = [];

    if (!isEmpty(thisLookupQueries)) {
      for (const lookupQuery of thisLookupQueries) {
        const {
          getter,
          handlers,
          ids: lookupIds,
          values: thisValues,
          lookupFields: thisLookupFields,
        } = lookupQuery;

        const queries = reduce(
          lookupIds,
          (acc, ids, type) => {
            const lookupType = PREDEFINED_LOOKUP_TYPES[type];
            const handlerType = handlers[type] || {};

            acc[type] = {
              method: 'get',
              url: handlerType.url || lookupType.url,
              data: handlerType.data
                ? handlerType.data(ids)
                : typeof lookupType.data === 'function'
                ? lookupType.data(ids)
                : {
                    $or: [{ id: { $in: ids } }, { slug: { $in: ids } }],
                    limit: null,
                    ...(lookupType.data || undefined),
                  },
            };

            return acc;
          },
          {},
        );

        const relations = !isEmpty(queries) ? await getter(queries) : {};

        for (let i = 0; i < thisLookupFields.length; ++i) {
          const field = thisLookupFields[i];
          const values = thisValues[i];

          const lookupType = PREDEFINED_LOOKUP_TYPES[field.lookupTypeId];
          const handlerType = handlers[field.type] || {};

          if (lookupType) {
            let value = handlerType.value
              ? handlerType.value(field)
              : get(values, field.id);

            if (
              typeof value === 'object' &&
              value !== null &&
              !Array.isArray(value)
            ) {
              continue;
            }

            if (!value) {
              value = get(values, contentLookupKey(field));
            }

            const result = relations?.[field.lookupTypeId];
            const results = result?.results || result || [];

            if (Array.isArray(value)) {
              set(
                values,
                field.id,
                handlerType.result
                  ? handlerType.result(field, results, value)
                  : lookupType.result
                  ? lookupType.result(field, results, value)
                  : value.reduce((acc, val) => {
                      const item =
                        find(results, (item) => item.id === val) ||
                        find(results, (item) => item.slug === val);

                      if (item) {
                        const obj = {};
                        set(obj, field.id, item);
                        acc.push(obj);
                      }

                      return acc;
                    }, []),
              );
            } else {
              set(
                values,
                field.id,
                handlerType.result
                  ? handlerType.result(field, results, value)
                  : lookupType.result
                  ? lookupType.result(field, results, value)
                  : find(results, (item) => item.id === value) ||
                    find(results, (item) => item.slug === value),
              );
            }
          }
        }
      }
    }

    for (const resolve of thisResolvers) {
      resolve();
    }
  }, 250);

  return promise;
}

/**
 * @param {Record<string, any>} values
 * @param {object[]} fields
 * @param {string} [defaultKeyField='id']
 * @returns {Record<string, any>}
 */
function depopulateLookupValues(values, fields, defaultKeyField = 'id') {
  let updated = values;

  for (const field of fields) {
    if (PREDEFINED_LOOKUP_TYPES[field.type]) {
      const lookupValue = get(updated, field.id);

      const lookupKey = contentLookupKey(field) || field.id;

      if (updated === values) {
        updated = cloneDeep(values);
      }

      const keyField =
        field.key_field !== undefined ? field.key_field : defaultKeyField;

      if (Array.isArray(lookupValue)) {
        set(
          updated,
          lookupKey,
          lookupValue.map((v) =>
            get(get(v, field.id), keyField, lookupValue.id),
          ),
        );
      } else if (lookupValue === null || lookupValue === '') {
        set(updated, lookupKey, null);
      } else if (typeof lookupValue === 'number' || lookupValue) {
        set(
          updated,
          lookupKey,
          get(lookupValue, keyField, lookupValue.id || lookupValue),
        );
      }

      if (lookupKey !== field.id) {
        set(updated, field.id, undefined);
      }
    }
  }

  return updated;
}

/**
 * Used for accounts with deprecated content configs (peppersq etc)
 *
 * @deprecated
 *
 * @param {Record<string, any>} values
 * @param {object[]} fields
 * @returns {Record<string, any>}
 */
function depopulateLookupValuesDeprecated(values, fields) {
  const updated = cloneDeep(values);
  for (let field of fields) {
    if (PREDEFINED_LOOKUP_TYPES[field.type]) {
      const lookupValue = get(updated, field.id);
      if (lookupValue) {
        // Note: could be a bug here with setting field IDs which can be nested, vs content field IDs which are not
        const lookupKey = contentLookupKey(field) || field.id;
        set(updated, lookupKey, lookupValue.id);
        if (lookupKey !== field.id) {
          unset(updated, field.id);
        }
      }
    }
  }
  return updated;
}

/* General utils */

/**
 * @param {number} value
 * @returns {boolean}
 */
function isPrice(value) {
  return isNumber(value) || !isEmpty(value);
}

/* Product utils */

/**
 * @param {object?} product
 * @param {object?} variant
 * @returns {number}
 */
function productPrice(product, variant) {
  if (variant) {
    const value = parseFloat(variant.price);

    if (Number.isFinite(value)) {
      return value;
    }

    if (product) {
      return defaultVariantPrice(product, variant);
    }
  }

  if (product) {
    return parseFloat(product.price);
  }

  return Number.NaN;
}

/**
 * @param {object?} product
 * @param {object?} variant
 * @param {number?} productPrice
 * @returns {number | null}
 */
function defaultVariantPrice(product, variant, productPrice) {
  let price =
    productPrice !== undefined ? productPrice : product && product.price;

  if (product && variant && Array.isArray(variant.option_value_ids)) {
    let subPrice = 0;
    let optionPrice = 0;

    const subOption = product && find(product.options, { subscription: true });

    for (const option of product.options || []) {
      for (const optionValue of option.values || []) {
        const valuePrice = get(optionValue, `$currency[${product.code}].price`);

        const value = {
          ...optionValue,
          price: isPrice(valuePrice) ? valuePrice : optionValue.price,
        };

        if (
          variant.option_value_ids.includes(value.id) &&
          typeof value.price === 'number'
        ) {
          if (subOption === option) {
            subPrice = value.price || 0;
          } else {
            optionPrice += value.price || 0;
          }
        }
      }
    }

    if (subOption) {
      price = (subPrice || price || 0) + optionPrice;
    } else if (optionPrice !== 0) {
      price = (price || 0) + optionPrice;
    }
  }

  return typeof price === 'number' ? price : null;
}

/* Order utils */

/**
 * @typedef {object} BundleItemValue
 * @property {number} BundleItemValue.tax
 * @property {number} BundleItemValue.price
 * @property {number} BundleItemValue.discount
 */

/**
 * Calculate properties needed to return products from bundle
 *
 * @param {object} item
 * @returns {Record<string, BundleItemValue | undefined>}
 */
function calculateBundleItemValues(item) {
  const { bundle_items: bundleItems } = item;

  // Get total price of all products in bundle
  const totalPrice = reduce(
    bundleItems,
    (acc, bundleItem) => {
      const price =
        bundleItem.price ||
        productPrice(bundleItem.product, bundleItem.variant) ||
        0;

      return acc.add(currency(price).multiply(bundleItem.quantity));
    },
    currency(0),
  );

  return reduce(
    bundleItems,
    (acc, bundleItem) => {
      // Get price ratio of product to entire bundle (product.price / totalPrice)
      const ratio =
        bundleItem.amount_ratio ||
        (bundleItem.product
          ? currency(bundleItem.product.price).divide(totalPrice).value
          : 0);

      // Calculate required properties based on ratio
      acc[bundleItem.id] = {
        price: bundleItem.price || currency(item.price).multiply(ratio).value,
        discount:
          bundleItem.discount_each ||
          (item.discount_each
            ? currency(item.discount_each).multiply(ratio).value
            : 0),
        tax:
          bundleItem.tax_each ||
          (item.tax_each ? currency(item.tax_each).multiply(ratio).value : 0),
      };

      return acc;
    },
    {},
  );
}

module.exports = {
  slugify,
  slugifyFlex,
  snakeCase,
  wordify,
  isCircular,
  stringifyQuery,
  populateLookupValues,
  depopulateLookupValues,
  depopulateLookupValuesDeprecated,
  getPopulateLookupQueries,
  forEachMenuItem,
  contentLookupKey,
  PREDEFINED_LOOKUP_TYPES,
  isPrice,
  productPrice,
  defaultVariantPrice,
  calculateBundleItemValues,
};
