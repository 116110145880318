import React from 'react';
import Form from '../../form/form';
import View from '../../view/view';
import CategoryForm from './form';
import './category.scss';

export default function NewCategoryPage({ onSubmitRecord, ...props }) {
  return (
    <Form onSubmit={onSubmitRecord} autoFocus={true}>
      <View
        detail={true}
        uri={`/categories`}
        sectionTitle="Categories"
        headerTitle="New category"
        headerActions={[{ label: 'Save category', submit: true }]}
        localized={true}
        devtools={{
          model: 'categories',
          console: false,
        }}
      >
        <CategoryForm {...props} />
      </View>
    </Form>
  );
}
