import api from 'services/api';
import { reduce, map, get, toLower, toNumber, includes } from 'lodash';
import { loadScript, isEmpty } from 'utils';
import { getGateway } from 'utils/settings';

const addressFieldsMap = {
  city: 'city',
  country: 'country',
  line1: 'address1',
  line2: 'address2',
  postal_code: 'zip',
  state: 'state',
};

const billingFieldsMap = {
  name: 'name',
  phone: 'phone',
};

const ZERO_DECIMAL_CURRENCIES = [
  'BIF', // Burundian Franc
  'DJF', // Djiboutian Franc,
  'JPY', // Japanese Yen
  'KRW', // South Korean Won
  'PYG', // Paraguayan Guaraní
  'VND', // Vietnamese Đồng
  'XAF', // Central African Cfa Franc
  'XPF', // Cfp Franc
  'CLP', // Chilean Peso
  'GNF', // Guinean Franc
  'KMF', // Comorian Franc
  'MGA', // Malagasy Ariary
  'RWF', // Rwandan Franc
  'VUV', // Vanuatu Vatu
  'XOF', // West African Cfa Franc
];

let stripe = null;

export async function loadStripe(settings) {
  const stripeSettings = getGateway(settings, 'stripe') || {};
  const key =
    stripeSettings.mode === 'live'
      ? stripeSettings.live_publishable_key
      : stripeSettings.test_publishable_key;
  if (key) {
    if (!window.Stripe || !window.Stripe.StripeV3) {
      await loadScript('stripe-js', 'https://js.stripe.com/v3/');
    }
    if (window.Stripe && window.Stripe.StripeV3) {
      if (!stripe) {
        stripe = window.Stripe.StripeV3(key);
      }
      return stripe;
    } else if (window.Stripe && !window.Stripe.StripeV3) {
      console.error('Warning: StripeV3 was not loaded');
    }
  }
}

function stripeAmountByCurrency(currency, amount) {
  return currency in ZERO_DECIMAL_CURRENCIES
    ? amount
    : Math.round(amount * 100);
}

function getBillingDetails(billing) {
  const fillValues = (fieldsMap) =>
    reduce(
      fieldsMap,
      (acc, value, key) => {
        const billingValue = billing[value];
        if (billingValue) {
          acc[key] = billingValue;
        }
        return acc;
      },
      {},
    );

  const billingDetails = fillValues(billingFieldsMap);
  if (!isEmpty(billingDetails)) {
    const address = fillValues(addressFieldsMap);
    return {
      ...billingDetails,
      ...(!isEmpty(address) ? { address } : {}),
    };
  }
}

function getKlarnaItems(record) {
  const currency = toLower(get(record, 'currency', 'eur'));
  const items = map(record.items, (item) => ({
    type: 'sku',
    description: get(item, 'product.name', 'Product'),
    quantity: item.quantity,
    currency,
    amount: Math.round(toNumber(item.price_total - item.discount_total) * 100),
  }));

  const tax = get(record, 'tax_included_total');
  if (tax) {
    items.push({
      type: 'tax',
      description: 'Taxes',
      currency,
      amount: toNumber(tax) * 100,
    });
  }

  const shipping = get(record, 'shipping', {});
  const shippingTotal = get(record, 'shipment_total', {});
  if (shipping.price) {
    items.push({
      type: 'shipping',
      description: shipping.service_name,
      currency,
      amount: toNumber(shippingTotal) * 100,
    });
  }

  return items;
}

function setKlarnaBillingShipping(source, data) {
  const shippingNameFieldsMap = {
    shipping_first_name: 'first_name',
    shipping_last_name: 'last_name',
  };
  const shippingFieldsMap = {
    phone: 'phone',
  };
  const billingNameFieldsMap = {
    first_name: 'first_name',
    last_name: 'last_name',
  };
  const billingFieldsMap = {
    email: 'email',
  };

  const fillValues = (fieldsMap, data) =>
    reduce(
      fieldsMap,
      (acc, srcKey, destKey) => {
        const value = data[srcKey];
        if (value) {
          acc[destKey] = value;
        }
        return acc;
      },
      {},
    );

  source.klarna = {
    ...source.klarna,
    ...fillValues(shippingNameFieldsMap, data.shipping),
  };
  const shipping = fillValues(shippingFieldsMap, data.shipping);
  const shippingAddress = fillValues(addressFieldsMap, data.shipping);
  if (shipping || shippingAddress) {
    source.source_order.shipping = {
      ...(shipping ? shipping : {}),
      ...(shippingAddress ? { address: shippingAddress } : {}),
    };
  }

  source.klarna = {
    ...source.klarna,
    ...fillValues(billingNameFieldsMap, data.billing),
  };
  const billing = fillValues(billingFieldsMap, data.account);
  const billingAddress = fillValues(addressFieldsMap, data.billing);
  if (billing || billingAddress) {
    source.owner = {
      ...(billing ? billing : {}),
      ...(billingAddress ? { address: billingAddress } : {}),
    };
  }
}

export async function createStripeCardPaymentMethod(
  stripe,
  cardElement,
  billing = {},
) {
  const billingDetails = getBillingDetails(billing);
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
    ...(billingDetails ? { billing_details: billingDetails } : {}),
  });
  return error
    ? { error }
    : {
        gateway: 'stripe',
        token: paymentMethod.id,
        last4: paymentMethod.card.last4,
        exp_month: paymentMethod.card.exp_month,
        exp_year: paymentMethod.card.exp_year,
        brand: paymentMethod.card.brand,
        address_check: paymentMethod.card.checks.address_line1_check,
        cvc_check: paymentMethod.card.checks.cvc_check,
        zip_check: paymentMethod.card.checks.address_zip_check,
      };
}

export async function createIDealPaymentMethod(stripe, element, billing = {}) {
  const billingDetails = getBillingDetails(billing);
  return await stripe.createPaymentMethod({
    type: 'ideal',
    ideal: element,
    ...(billingDetails ? { billing_details: billingDetails } : {}),
  });
}

export const createIDealPaymentIntent = async (key, record, amount) =>
  api.createStripeIntent(key, {
    payment_method: get(record, 'billing.ideal.token'),
    amount: amount
      ? toNumber(amount) * 100
      : get(record, 'grand_total', 0) * 100,
    currency: toLower(get(record, 'currency', 'eur')),
    payment_method_types: 'ideal',
    confirmation_method: 'manual',
    confirm: true,
    return_url: window.location.href,
  });

export const isSingleUsePaymentMethodError = (error) => {
  const message = get(error, 'intent.message');
  return (
    message &&
    (includes(message, ' attach it to a Customer first') ||
      includes(message, 'attaching them to a Customer object first'))
  );
};

export async function createKlarnaSource(stripe, record, client) {
  const sourceObject = {
    type: 'klarna',
    flow: 'redirect',
    amount: get(record, 'grand_total', 0) * 100,
    currency: toLower(get(record, 'currency', 'eur')),
    klarna: {
      product: 'payment',
      purchase_country: get(client, 'business.country', 'DE'),
    },
    source_order: {
      items: getKlarnaItems(record),
    },
    redirect: {
      return_url: window.location.href,
    },
  };
  setKlarnaBillingShipping(sourceObject, record);

  return await stripe.createSource(sourceObject);
}

export async function createPaymentIntent(record, key, customAmount) {
  const customer = get(record, 'account.stripe_customer');
  const currency = get(record, 'currency', 'USD');
  const amount = customAmount || record.capture_total + record.auth_total;

  return api.createStripeIntent(key, {
    payment_method: get(record, 'billing.card.token'),
    amount: stripeAmountByCurrency(currency, amount),
    currency: toLower(currency),
    capture_method: 'manual',
    setup_future_usage: 'off_session',
    ...(customer ? { customer } : {}),
  });
}
