// Calculate input style based on input and taglist elements
export function calculateTagsInputStyle(input, taglist) {
  const inputStyle = {
    paddingLeft: 15,
    paddingTop: 10,
    paddingLeft: 12,
    paddingTop: 8,
  };

  if (taglist && taglist.children.length) {
    const lastChild = taglist.children[taglist.children.length - 1];
    inputStyle.paddingLeft = lastChild.offsetLeft + lastChild.offsetWidth + 20;
    inputStyle.paddingTop = lastChild.offsetTop + 1;
    if (input.offsetWidth - inputStyle.paddingLeft < 50) {
      inputStyle.paddingLeft = 15;
      inputStyle.paddingTop = taglist.offsetHeight + 10;
    }
  }

  return inputStyle;
}

export function rgbToHex(r = 0, g = 0, b = 0) {
  let hex = '#';
  const rr = Number(r).toString(16);
  if (String(rr).length > 1) {
    hex += rr;
  } else {
    hex += '0' + rr;
  }
  const gg = Number(g).toString(16);
  if (String(gg).length > 1) {
    hex += gg;
  } else {
    hex += '0' + gg;
  }
  const bb = Number(b).toString(16);
  if (String(bb).length > 1) {
    hex += bb;
  } else {
    hex += '0' + bb;
  }
  if (hex.length < 7) {
    return '#000000';
  }
  return hex;
}
