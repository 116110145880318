import React from 'react';
import Tooltip from 'components/tooltip';
import './button.scss';

export default function Button({
  size = 'md',
  type = 'default',
  styleType,
  className,
  disabled,
  tooltip,
  ...props
}) {
  const buttonStyleType = styleType || (type === 'submit' ? 'default' : type);
  const buttonType =
    type === 'submit' || type === 'primary' ? 'submit' : 'button';

  let classNameExt = `button button-${size} button-${buttonStyleType}`;
  if (className) {
    classNameExt += ` ${className}`;
  }

  return (
    <button
      type={buttonType}
      className={`${classNameExt} ${
        !styleType && disabled ? 'button-disabled' : ''
      }`}
      disabled={disabled}
      {...props}
    >
      {tooltip ? (
        <Tooltip message={tooltip}>{props.children}</Tooltip>
      ) : (
        props.children
      )}
    </button>
  );
}
