import React from 'react';
import PropTypes from 'prop-types';
import { get, find, map } from 'lodash';
import ContentField from './field';
import ContentFieldtableDeprecated from './fieldtable-deprecated';
import { FadeIn } from 'components/transitions';
import { Field } from 'components/form';

export default class ContentFieldsDeprecated extends React.PureComponent {
  static propTypes = {
    base: PropTypes.string.isRequired,
    types: PropTypes.array.isRequired,
    record: PropTypes.object,
    values: PropTypes.object,
    currency: PropTypes.string,
  };

  getRecordValue(type, path = undefined) {
    const { base, record = {} } = this.props;
    const recordValue = get(record, `${base}.${type.target_field}`, {});
    return path ? get(recordValue, path) : recordValue || {};
  }

  getEnabledValue(type) {
    const { base, values = {} } = this.props;
    const enableField = find(type.fields, { id: 'enabled' });
    if (!enableField || enableField.type !== 'toggle') {
      return true;
    }
    const valuesValue = get(values, `${base}.${type.target_field}`, {});
    const enabledValue = get(valuesValue, 'enabled');
    return enabledValue;
  }

  renderField(type, field) {
    const { base, currency, lookup, values = {}, record = {} } = this.props;

    const enabled = this.getEnabledValue(type);

    if (field.type === 'fieldset') {
      return (
        <fieldset key={field.id} className="full">
          {field.label && (
            <div className="view-body-subheader">
              <h3 className="view-body-subtitle">{field.label}</h3>
              {field.description && <p>{field.description}</p>}
            </div>
          )}
          {field.fields &&
            map(field.fields, (f) => (
              <ContentField
                key={f.id}
                {...f}
                name={`${base}.${type.target_field}.${field.id}.${f.id}`}
                currency={currency}
                lookup={lookup}
                values={values}
                defaultValue={this.getRecordValue(type, `${field.id}.${f.id}`)}
                disabled={!enabled}
              />
            ))}
        </fieldset>
      );
    }
    if (field.type === 'fieldtable') {
      return (
        <ContentFieldtableDeprecated
          key={field.id}
          {...field}
          name={`${base}.${type.target_field}.${field.id}`}
          currency={currency}
          lookup={lookup}
          values={values}
          record={record}
          defaultValue={this.getRecordValue(type, field.id)}
          disabled={!enabled}
        />
      );
    }
    return (
      <ContentField
        key={field.id}
        {...field}
        name={`${base}.${type.target_field}.${field.id}`}
        currency={currency}
        lookup={lookup}
        values={values}
        defaultValue={this.getRecordValue(type, field.id)}
        disabled={!enabled}
      />
    );
  }

  renderVisibleFields(type) {
    if (type.fields) {
      const visibleFields = type.fields.filter(
        (field) => field.type !== 'objectid' && field.id !== 'enabled',
      );
      if (visibleFields.length > 0) {
        return visibleFields.map((field) => this.renderField(type, field));
      }
    }
    return (
      <div className="collection-none-found">
        This content section has no input fields defined
      </div>
    );
  }

  renderEnableField(type) {
    const { base } = this.props;
    const enableField = find(type.fields, { id: 'enabled' });
    if (!enableField || enableField.type !== 'toggle') {
      return null;
    }
    return (
      <div className="view-body-actions">
        <Field
          type="toggle"
          name={`${base}.${type.target_field}.enabled`}
          defaultChecked={this.getRecordValue(type, 'enabled')}
          className="right"
        />
      </div>
    );
  }

  render() {
    const { types = [] } = this.props;

    return types.map((type) => (
      <fieldset
        key={type.id}
        className={`full ${this.getEnabledValue(type) ? 'enabled' : ''}`}
      >
        <div className="view-body-subheader">
          <h3 className="view-body-subtitle">{type.name || type.id}</h3>
          {type.description && <p>{type.description}</p>}
          {this.renderEnableField(type)}
        </div>
        <FadeIn transitionAppear={false} active={this.getEnabledValue(type)}>
          {this.renderVisibleFields(type)}
        </FadeIn>
      </fieldset>
    ));
  }
}
