import { findIndex, filter } from 'lodash';

export function putElementInFirstPlace(arr, condition) {
  const idx = findIndex(arr, condition);
  if (idx === -1) {
    return arr;
  }
  const rest = filter(arr, (elem, i) => i !== idx);
  return [arr[idx], ...rest];
}
