import React, { Fragment } from 'react';
import Link from 'components/link';

export default function GiftcardDescription({ giftcard, link = true }) {
  return (
    <Fragment>
      {giftcard.product_id ? (
        <Fragment>
          Generated for{' '}
          {link !== false ? (
            <Link to={`/products/${giftcard.product_id}`}>
              {giftcard.product ? giftcard.product.name : 'product'}
            </Link>
          ) : giftcard.product ? (
            giftcard.product.name
          ) : (
            'product'
          )}
        </Fragment>
      ) : (
        giftcard.bulk_description || <span className="muted">&mdash;</span>
      )}
    </Fragment>
  );
}
