export const webhookEventOptions = [
  {
    label: 'account',
    options: [
      'account.created',
      'account.updated',
      'account.deleted',
      'account.card.created',
      'account.card.updated',
      'account.card.deleted',
      'account.address.created',
      'account.address.updated',
      'account.address.deleted',
      'account.credit.created',
      'account.credit.updated',
      'account.credit.deleted',
    ],
  },
  {
    label: 'cart',
    options: [
      'cart.created',
      'cart.deleted',
      'cart.converted',
      'cart.abandoned',
    ],
  },
  {
    label: 'category',
    options: [
      'category.created',
      'category.updated',
      'category.deleted',
      'category.product.added',
      'category.product.removed',
    ],
  },
  {
    label: 'contact',
    options: ['contact.created', 'contact.updated', 'contact.deleted'],
  },
  {
    label: 'coupon',
    options: [
      'coupon.created',
      'coupon.updated',
      'coupon.deleted',
      'coupon.code.created',
      'coupon.code.deleted',
      'coupon.generation.completed',
    ],
  },
  // Deprecated
  // {
  //   label: 'credit',
  //   options: [
  //     'credit.created',
  //     'credit.updated',
  //     'credit.deleted',
  //     'credit.refund_succeeded',
  //     'credit.refund_failed',
  //   ],
  // },
  {
    label: 'invoice',
    options: [
      'invoice.created',
      'invoice.updated',
      'invoice.deleted',
      'invoice.refund_succeeded',
      'invoice.refund_failed',
    ],
  },
  {
    label: 'order',
    options: [
      'order.created',
      'order.updated',
      'order.deleted',
      'order.submitted',
      'order.canceled',
      'order.paid',
      'order.payment_failed',
      'order.refunded',
      'order.delivered',
      'order.trial_started',
      'order.trial_will_end',
      'order.trial_ended',
    ],
  },
  {
    label: 'page',
    options: [
      'page.created',
      'page.updated',
      'page.deleted',
      'page.article.created',
      'page.article.updated',
      'page.article.deleted',
    ],
  },
  {
    label: 'payment',
    options: [
      'payment.succeeded',
      'payment.failed',
      'payment.voided',
      'payment.refund.succeeded',
      'payment.refund.failed',
      'payment.refund.voided',
    ],
  },
  {
    label: 'product',
    options: [
      'product.created',
      'product.updated',
      'product.deleted',
      'product.stock_adjusted',
      'product.variant.created',
      'product.variant.updated',
      'product.variant.deleted',
      'product.review.created',
      'product.review.updated',
      'product.review.deleted',
    ],
  },
  {
    label: 'promotion',
    options: ['promotion.created', 'promotion.updated', 'promotion.deleted'],
  },
  {
    label: 'setting',
    options: ['setting.updated'],
  },
  {
    label: 'shipment',
    options: [
      'shipment.created',
      'shipment.updated',
      'shipment.deleted',
      'shipment.canceled',
    ],
  },
  {
    label: 'subscription',
    options: [
      'subscription.created',
      'subscription.updated',
      'subscription.deleted',
      'subscription.activated',
      'subscription.trial_will_end',
      'subscription.trial_ended',
      'subscription.invoiced',
      'subscription.payment_failed',
      // added as commented until this_is_causing_problems resolved for the event
      // 'subscription.payment_expiring',
      'subscription.paid',
      'subscription.canceled',
      'subscription.paused',
      'subscription.resumed',
      'subscription.completed',
    ],
  },
];

export const orderWebhookEventOptions = ['shipping', 'taxes'];
