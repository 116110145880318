import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, size, find } from 'lodash';
import { Form, Field, Fieldtable, FieldEvents } from 'components/form';
import View from 'components/view';
import Modal from 'components/modal';
import Link from 'components/link';
import Icon from 'components/icon';
import SectionHeader from 'components/section-header';
import { inflect, formatDate } from 'utils';
import { truncatedText } from 'utils/collection';
import { orderWebhookEventOptions } from 'constants/webhooks';
import './settings.scss';

export default class WebhookSettings extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    refreshModal: PropTypes.func,
    closeModal: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showAttemptsId: null,
      showOrderWebhookAttempts: null,
    };

    this.onClickShowAttempts = this.onClickShowAttempts.bind(this);
    this.onClickShowOrderWebhookAttempts =
      this.onClickShowOrderWebhookAttempts.bind(this);
    this.onCloseShowAttempts = this.onCloseShowAttempts.bind(this);
    this.onClickCloseShowAttempts = this.onClickCloseShowAttempts.bind(this);
    this.renderWebhookForm = this.renderWebhookForm.bind(this);
    this.renderWebhookTable = this.renderWebhookTable.bind(this);
    this.renderWebhookAttempts = this.renderWebhookAttempts.bind(this);
    this.renderOrderWebhookForm = this.renderOrderWebhookForm.bind(this);
    this.renderOrderWebhookTable = this.renderOrderWebhookTable.bind(this);
  }

  onClickShowAttempts(event) {
    event.preventDefault();
    const showAttemptsId = event.currentTarget.dataset.id;
    this.props.fetchWebhookAttempts(showAttemptsId);
    this.setState({ showAttemptsId });
  }

  onClickShowOrderWebhookAttempts(event) {
    event.preventDefault();
    this.props.fetchOrderWebhookAttempts();
    this.setState({ showOrderWebhookAttempts: true });
  }

  onCloseShowAttempts() {
    this.setState({ showAttemptsId: null, showOrderWebhookAttempts: false });
  }

  onClickCloseShowAttempts(event) {
    event.preventDefault();
    this.onCloseShowAttempts();
  }

  renderWebhookAttempts() {
    const {
      webhooks: { attempts, loading },
    } = this.props;

    if (!attempts || !attempts.results) {
      return null;
    }

    return (
      <Modal
        title="Recent failed attempts"
        width={850}
        loading={loading}
        actions={[
          {
            label: 'Close',
            type: 'cancel',
            onClick: this.onClickCloseShowAttempts,
          },
        ]}
        cancel={false}
        onClose={this.onCloseShowAttempts}
      >
        <div className="box settings-webhooks-attempts">
          {attempts.count > 0 ? (
            attempts.results.map((attempt) => (
              <div className="box-section" key={attempt.id}>
                <p className="note">
                  <span className="muted">
                    {formatDate(attempt.date_sent, 'shortExact')}
                  </span>
                </p>
                <div className="settings-webhooks-attempts-response">
                  {attempt.response || (
                    <span className="muted">No response</span>
                  )}
                </div>
              </div>
            ))
          ) : (
            <span>None</span>
          )}
        </div>
        {attempts.count > attempts.results.length && (
          <div className="note">
            Showing last {attempts.results.length} of {attempts.count} attempts
          </div>
        )}
      </Modal>
    );
  }

  renderOrderWebhookAttempts() {
    const {
      webhooks: { orderAttempts, loading },
    } = this.props;

    return (
      <Modal
        title="Recent failed order webhooks"
        width={650}
        loading={loading}
        actions={[
          {
            label: 'Close',
            type: 'cancel',
            onClick: this.onClickCloseShowAttempts,
          },
        ]}
        cancel={false}
        onClose={this.onCloseShowAttempts}
      >
        {orderAttempts.orders && orderAttempts.orders.count > 0 && (
          <Fragment>
            <div className="box settings-webhooks-attempts">
              <div className="box-section">
                <h3 className="box-title">Orders</h3>
              </div>
              {orderAttempts.orders.results.map((attempt) => (
                <div className="box-section" key={attempt.id}>
                  <p className="note">
                    <Link to={`/orders/${attempt.id}`}>
                      Order #{attempt.number}
                    </Link>{' '}
                    -{' '}
                    {formatDate(
                      attempt.date_webhook_first_failed,
                      'shortExact',
                    )}
                  </p>
                  <div className="settings-webhooks-attempts-response">
                    {attempt.webhook_response || (
                      <span className="muted">No response</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {orderAttempts.orders.count >
              orderAttempts.orders.results.length && (
              <div className="note">
                Showing last {orderAttempts.orders.results.length} of{' '}
                {orderAttempts.orders.count} order webhook attempts
              </div>
            )}
          </Fragment>
        )}
        {orderAttempts.carts && orderAttempts.carts.count > 0 && (
          <Fragment>
            <div className="box settings-webhooks-attempts">
              <div className="box-section">
                <h3 className="box-title">Shopping carts</h3>
              </div>
              {orderAttempts.carts.results.map((attempt) => (
                <div className="box-section" key={attempt.id}>
                  <p className="note">
                    Cart #{attempt.number} -{' '}
                    {formatDate(
                      attempt.date_webhook_first_failed,
                      'shortExact',
                    )}
                  </p>
                  <div className="settings-webhooks-attempts-response">
                    {attempt.webhook_response || (
                      <span className="muted">No response</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {orderAttempts.carts.count > orderAttempts.carts.results.length && (
              <div className="note">
                Showing last {orderAttempts.carts.results.length} of{' '}
                {orderAttempts.carts.count} cart webhook attempts
              </div>
            )}
          </Fragment>
        )}
      </Modal>
    );
  }

  renderWebhookForm(values) {
    return (
      <div>
        <Field type="hidden" name="id" defaultValue={values.id} />

        <Field
          name="url"
          type="text"
          rules="url"
          label="Webhook URL"
          defaultValue={values.url}
          placeholder="https://www.mydomain.com/example"
          required={true}
        />
        <Field
          type="toggle"
          name="enabled"
          label="Enabled"
          defaultChecked={values.enabled !== undefined ? values.enabled : true}
        />
        <Field
          type="text"
          name="alias"
          label="Alias"
          defaultValue={values.alias}
          placeholder="Optional"
          hint="May be used to identify the webhook by 3rd party application code"
        />
        <Field
          type="textarea"
          name="description"
          label="Description"
          defaultValue={values.description}
          rows={3}
          placeholder="Optional"
        />
        <FieldEvents name="events" defaultValue={values.events} />
      </div>
    );
  }

  renderWebhookTable(values, component) {
    if (size(values) === 0) {
      return null;
    }
    return (
      <div className="box">
        {map(values, (webhook, index) => {
          const record =
            (this.props.record &&
              find(this.props.record.webhooks, { id: webhook.id })) ||
            {};
          return (
            <div key={index} className="box-section">
              <code style={{ maxWidth: 450, wordBreak: 'break-all' }}>
                {truncatedText(webhook.url, 80)}
              </code>
              <div className="box-subtitle" style={{ maxWidth: 550 }}>
                {!!webhook.alias && (
                  <div className="note">Alias: {webhook.alias}</div>
                )}
                {!!webhook.description && (
                  <div className="note">{webhook.description}</div>
                )}
                {(webhook.alias || webhook.description) && <br />}
              </div>
              {record.attempts_failed > 0 && (
                <p>
                  <br />
                  <Icon fa="exclamation-circle" className="negative" />
                  &nbsp;&nbsp;
                  {inflect(
                    record.attempts_failed,
                    'failed attempts',
                  )} since {formatDate(record.date_first_failed, 'short')}.
                  &ensp;
                  <a
                    href=""
                    onClick={this.onClickShowAttempts}
                    data-id={webhook.id}
                    className="note"
                  >
                    View details
                  </a>
                </p>
              )}
              <div className="settings-webhooks-events">
                {webhook.events.length > 0 ? (
                  <Fragment>
                    <b>Events</b>
                    <br />
                    <ul className="view-body-tags" style={{ width: '50%' }}>
                      {map(webhook.events, (event, evidx) => (
                        <li key={`events-${evidx}`} className="tag">
                          <code>{event}</code>
                        </li>
                      ))}
                    </ul>
                  </Fragment>
                ) : (
                  <span>No events selected</span>
                )}
              </div>
              <br />
              <a
                href=""
                onClick={component.onClickEditRow}
                data-index={index}
                className="view-link"
              >
                Edit
              </a>
              &nbsp;&nbsp;&nbsp;
              <a
                href=""
                onClick={component.onClickRemoveRow}
                data-index={index}
                className="view-link"
              >
                Remove
              </a>
              <div className="box-action">
                <Field
                  type="toggle"
                  name={`webhooks[${index}].enabled`}
                  defaultChecked={webhook.enabled}
                  onChange={(event, value) => {
                    component.setState(
                      {
                        value: {
                          ...values,
                          [index]: { ...webhook, enabled: value },
                        },
                      },
                      () => component.onChangeField(),
                    );
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderOrderWebhookForm(values) {
    return (
      <div>
        <Field
          name="url"
          type="text"
          rules="url"
          label="Webhook URL"
          defaultValue={values.url}
          placeholder="https://www.mydomain.com/example"
          required={true}
        />

        <Field
          type="toggle"
          name="enabled"
          label="Enabled"
          defaultChecked={values.enabled !== undefined ? values.enabled : true}
        />

        <Field
          type="textarea"
          name="description"
          label="Description"
          defaultValue={values.description}
          rows={3}
          placeholder="Optional"
        />

        <Field
          type="checkbox"
          name="events"
          label="Events"
          defaultValue={values.events}
          options={orderWebhookEventOptions}
          stacked={true}
          required={true}
          help="Webhook will be called in real-time when one of these calculations is triggered"
        />

        <Field
          type="number"
          name="timeout"
          label="Request timeout (ms)"
          defaultValue={values.timeout}
          placeholder="10000"
          hint="Default: 10000, Min: 1000, Max: 60000"
          help="Webhook requests will fail if not fulfilled within this time"
        />

        <Field
          type="select"
          name="retry_attempts"
          label="Retries"
          defaultValue={values.retry_attempts}
          placeholder="Do not retry if webhook fails"
          options={[
            { value: null, label: 'Do not retry if webhook fails' },
            { value: 1, label: 'Retry 1 time' },
            { value: 2, label: 'Retry 2 times' },
            { value: 3, label: 'Retry 3 times' },
          ]}
          help="Webhook requests will fail after making up to 3 consecutive attempts, all within the specified timeout period"
        />

        <Field
          type="toggle"
          name="reject_failed"
          label="Reject order updates when webhook fails"
          defaultChecked={values.reject_failed}
          help="When a webhook fails, the update that triggered it will return an error (e.g. shipping or tax calculation)"
        />
      </div>
    );
  }

  renderOrderWebhookTable(values, component) {
    const {
      values: { orderWebhookStats },
    } = this.props;
    if (!values || !values.url) {
      return null;
    }
    return (
      <div className="box">
        <div className="box-section">
          <code style={{ maxWidth: 450 }}>{truncatedText(values.url, 80)}</code>
          {(!!values.description || orderWebhookStats.date_last_succeeded) && (
            <div className="box-subtitle" style={{ maxWidth: 450 }}>
              {!!values.description && (
                <div className="note">{values.description}</div>
              )}
              {orderWebhookStats.date_last_succeeded && (
                <div className="positive">
                  Last succeeded on{' '}
                  {formatDate(orderWebhookStats.date_last_succeeded, 'short')}
                </div>
              )}
            </div>
          )}
          {orderWebhookStats.attempts_failed > 0 && (
            <p>
              <Icon fa="exclamation-circle" className="negative" />
              &nbsp;&nbsp;
              {inflect(
                orderWebhookStats.attempts_failed,
                'failed attempts',
              )}{' '}
              since {formatDate(orderWebhookStats.date_first_failed, 'short')}.{' '}
              <a
                href=""
                onClick={this.onClickShowOrderWebhookAttempts}
                className="note"
              >
                Details
              </a>
            </p>
          )}
          <div className="settings-webhooks-events">
            <b>Timeout:</b> {values.timeout || 10000}ms
            <br />
            <b>Retries:</b>{' '}
            {values.retry_attempts > 0
              ? inflect(values.retry_attempts, 'times')
              : 'None'}
            <br />
            {values.reject_failed && (
              <span>
                <b>When failed:</b> Reject order updates
              </span>
            )}
            <br />
            {values.events && values.events.length > 0 ? (
              <Fragment>
                <b>Events</b>
                <br />
                <ul className="view-body-tags" style={{ width: '50%' }}>
                  {map(values.events, (event, evidx) => (
                    <li key={`events-${evidx}`} className="tag">
                      <code>{event}</code>
                    </li>
                  ))}
                </ul>
              </Fragment>
            ) : (
              <span>No events selected</span>
            )}
          </div>
          <br />
          <a href="" onClick={component.onClickEditRow} className="view-link">
            Edit
          </a>
          &nbsp;&nbsp;&nbsp;
          <a href="" onClick={component.onClickRemoveRow} className="view-link">
            Remove
          </a>
          <div className="box-action">
            <Field
              type="toggle"
              name={`orderWebhook.enabled`}
              defaultChecked={values.enabled}
              onChange={(event, value) => {
                component.setState(
                  {
                    value: {
                      ...values,
                      enabled: value,
                    },
                  },
                  () => component.onChangeField(),
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { values, edited, onSubmitForm, onChangeForm } = this.props;

    return (
      <div className="settings settings-webhooks">
        <Form ref="form" onSubmit={onSubmitForm} onChange={onChangeForm}>
          <View
            detail={true}
            headerTitle="Webhooks"
            headerSubtitle="Notify external apps about events in your store"
            headerActions={[
              {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
          >
            <fieldset className="full">
              <Fieldtable
                label="Webhook"
                name="webhooks"
                defaultValue={values.webhooks}
                renderForm={this.renderWebhookForm}
                renderTable={this.renderWebhookTable}
                onSubmitModalForm={(values) =>
                  onSubmitForm({ webhooks: values })
                }
                formWidth={650}
              />
            </fieldset>
            <fieldset className="full">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title="Order webhook"
                  subtitle="Send a real-time webhook to calculate custom shipping and order values"
                />
              </div>
              <Fieldtable
                label="Order webhook"
                name="orderWebhook"
                single={true}
                defaultValue={
                  values.orderWebhook.url ? values.orderWebhook : null
                }
                renderForm={this.renderOrderWebhookForm}
                renderTable={this.renderOrderWebhookTable}
                onSubmitModalForm={(values) =>
                  onSubmitForm({ orderWebhook: values })
                }
                formWidth={650}
              />
            </fieldset>
          </View>
        </Form>
        {this.state.showAttemptsId && this.renderWebhookAttempts()}
        {this.state.showOrderWebhookAttempts &&
          this.renderOrderWebhookAttempts()}
      </div>
    );
  }
}
