import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import Link from 'components/link';
import Icon from 'components/icon';
import Field from './field';
import Status from 'components/status';
import Checkbox from 'components/icon/checkbox';
import { inflect } from 'utils';
import { categoriesHaveFoundChildren } from 'utils/category';

export default class LookupCategories extends React.PureComponent {
  static propTypes = {
    categories: PropTypes.object.isRequired,
    onQuery: PropTypes.func,
  };

  static contextTypes = {
    queryLookupCategories: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.onQuery = this.onQuery.bind(this);
    this.renderCategoryValue = this.renderCategoryValue.bind(this);
    this.renderCategoryLookupItems = this.renderCategoryLookupItems.bind(this);
    this.renderNewCategoryLookupItem =
      this.renderNewCategoryLookupItem.bind(this);
  }

  onQuery(query) {
    if (this.props.onQuery) {
      this.props.onQuery(query);
    } else {
      this.context.queryLookupCategories(query);
    }
  }

  renderCategoryValue(value, state) {
    if (value && state) {
      if (value.children) {
        for (let child of value.children) {
          if (state.valueIndex.get(child.id)) {
            return null;
          }
        }
      }

      let name = (
        <Link to={`/categories/${value.id}`}>
          <b>{value.name}</b>
        </Link>
      );
      let { parent } = value;

      while (parent) {
        name = (
          <span>
            <Link to={`/categories/${parent.id}`}>
              {state.value.indexOf(parent) >= 0 ? (
                <b>{parent.name}</b>
              ) : (
                parent.name
              )}
            </Link>
            <Icon fa="chevron-right" faType="regular" />
            {name}
          </span>
        );
        parent = parent.parent;
      }

      return name;
    }

    return <Fragment />;
  }

  renderCategoryLookupItems(props) {
    const { list, lookup } = this.props.categories;
    const items = list
      .map((category) => this.renderCategoryLookupItem(category, lookup, props))
      .filter((item) => item !== null);
    return items;
  }

  renderCategoryLookupItem(category, lookup, props, depth = 0) {
    if (!categoriesHaveFoundChildren([category], lookup)) {
      return null;
    }

    const selected = category.id === props.selected?.id;

    return [
      <li
        key={category.id}
        role="option"
        data-id={category.id}
        aria-selected={selected}
        className={`item ${selected ? 'selected' : null}`}
        onMouseOver={props.onMouseOver}
        onClick={props.onClick}
      >
        <span className="col" style={{ paddingLeft: 42 + depth * 20 }}>
          <Checkbox
            checked={props.valueIndex.has(category.id)}
            className={`
              checkbox
              ${
                props.valueIndex.get(category.id) &&
                !find(props.value, { id: category.id })
                  ? 'muted'
                  : ''
              }
            `}
            style={{ left: 14 + depth * 20 }}
          />
          {category.name}
        </span>
        <span className="col muted">
          {inflect(category.product_count, 'products')}
        </span>
        <span className="col">
          {category.active ? (
            <Status type="positive">Active</Status>
          ) : (
            <Status>Inactive</Status>
          )}
        </span>
      </li>,
      ...(category.children && category.children.length > 0
        ? category.children.map((child) =>
            this.renderCategoryLookupItem(child, lookup, props, depth + 1),
          )
        : []),
    ];
  }

  // render item to be added inline with same format as usual items
  renderNewCategoryLookupItem({
    value,
    newItemId,
    onClick,
    onMouseOver,
    selected,
  }) {
    return (
      <li
        key={newItemId}
        role="option"
        data-id={newItemId}
        aria-selected={newItemId === selected}
        className={`item ${
          newItemId === selected ? 'selected' : 'new-inline-item'
        }`}
        onMouseOver={onMouseOver}
        onClick={onClick}
      >
        <span className="col padded_item_col">
          <Icon
            fa="plus-circle"
            faType="solid"
            className="new-inline-item-icon"
          />
          {value}
        </span>
        <span className="col" />
        <span className="col" />
      </li>
    );
  }

  render() {
    const { categories, ...props } = this.props;

    return (
      <Field
        ref="field"
        type="lookup"
        name="categories"
        placeholder="Find categories by name"
        model="categories"
        onQuery={this.onQuery}
        renderValue={this.renderCategoryValue}
        renderLookupItems={this.renderCategoryLookupItems}
        renderNewLookupItem={this.renderNewCategoryLookupItem}
        multiple={true}
        {...props}
        lookup={categories}
      />
    );
  }
}
