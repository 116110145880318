export const NAV_ICONS = [
  'nav/home',
  'nav/orders',
  'nav/subscriptions',
  'nav/customers',
  'nav/products',
  'nav/discounts',
  'nav/apps',
  'nav/reporting',
];

export const NAV_ICON_PREFIX = 'nav/';

export const NAV_DEFAULTS = [
  {
    id: 'home',
    label: 'Home',
    icon: 'nav/home',
    link: '/',
    permission: 'dashboard',
  },
  {
    id: 'orders',
    label: 'Orders',
    link: '/orders',
    icon: 'nav/orders',
    items: [
      {
        id: 'all_orders',
        label: 'All orders',
        link: '/orders',
        permission: 'orders',
      },
      {
        id: 'draft_orders',
        label: 'Draft orders',
        link: '/orders/drafts',
        permission: 'orders',
      },
      {
        id: 'carts',
        label: 'Carts',
        link: '/carts',
        permission: 'carts',
      },
    ],
  },
  {
    id: 'subscriptions',
    label: 'Subscriptions',
    link: '/subscriptions',
    icon: 'nav/subscriptions',
    permission: 'subscriptions',
  },
  {
    id: 'customers',
    label: 'Customers',
    link: '/customers',
    icon: 'nav/customers',
    permission: 'customers',
  },
  {
    id: 'products',
    label: 'Products',
    link: '/products',
    icon: 'nav/products',
    items: [
      {
        id: 'all_products',
        label: 'All products',
        link: '/products',
        permission: 'products',
      },
      {
        id: 'categories',
        label: 'Categories',
        link: '/categories',
        permission: 'categories',
      },
      {
        id: 'attributes',
        label: 'Attributes',
        link: '/attributes',
        permission: 'attributes',
      },
      {
        id: 'giftcards',
        label: 'Gift cards',
        link: '/giftcards',
        permission: 'giftcards',
      },
      {
        id: 'purchaselinks',
        label: 'Purchase links',
        link: '/purchase-links',
        permission: 'products',
      },
    ],
  },
  {
    id: 'discounts',
    label: 'Discounts',
    icon: 'nav/discounts',
    items: [
      {
        id: 'coupons',
        label: 'Coupons',
        link: '/coupons',
        permission: 'coupons',
      },
      {
        id: 'promotions',
        label: 'Promotions',
        link: '/promotions',
        permission: 'promotions',
      },
    ],
  },
  {
    id: 'content',
    label: 'Content',
    icon: 'nav/apps',
    items: [
      {
        id: 'pages',
        label: 'Pages',
        link: '/collections/content_pages',
        permission: 'content',
      },
    ],
    permission: 'content',
  },
  {
    id: 'reporting',
    label: 'Reporting',
    link: '/reports',
    icon: 'nav/reporting',
    permission: 'reports',
    items: [
      //{ label: 'All reports', link: '/reports', permission: 'reports' },
    ],
  },
];
