import { set } from 'lodash';
import undoable, { includeAction } from 'redux-undo';
import {
  LOAD_SETTINGS_SUCCESS,
  CHANGE_SETTING_LOCAL,
  CHANGE_CHECKOUT_SETTING_LOCAL,
} from '../actions/types';

const initialState = {};

export default undoable(
  (state = initialState, action) => {
    let newState;
    switch (action.type) {
      case LOAD_SETTINGS_SUCCESS:
        return action.payload;
      case CHANGE_SETTING_LOCAL:
        newState = {
          ...state,
        };
        set(newState, action.payload.id, action.payload.value);
        return newState;
      case CHANGE_CHECKOUT_SETTING_LOCAL:
        newState = {
          ...state,
          checkout: {
            ...state.checkout,
          },
        };
        set(newState, action.payload.id, action.payload.value);
        return newState;
      default:
        return state;
    }
  },
  {
    filter: includeAction(CHANGE_SETTING_LOCAL),
  },
);
