import React from 'react';
import Link from 'components/link';
import { formatCurrency } from 'utils';
import { renderMultiCurrencyValuesTooltip } from 'utils/money';

export default class DiscountRuleDescription extends React.PureComponent {
  render() {
    const { rule, currency } = this.props;

    if (!rule) {
      return null;
    }
    switch (rule.type) {
      case 'total':
        if (rule.value_type === 'fixed') {
          return (
            <span>
              {formatCurrency(rule.value_fixed || rule.value, currency)}
              {renderMultiCurrencyValuesTooltip(
                rule.$currency,
                'value_fixed',
                currency,
              )}{' '}
              off entire order
            </span>
          );
        } else {
          return <span>{rule.value_percent}% off order total</span>;
        }
      case 'shipment':
        if (rule.value_type === 'fixed') {
          return (
            <span>
              {formatCurrency(rule.value_fixed || rule.value, currency)}
              {renderMultiCurrencyValuesTooltip(
                rule.$currency,
                'value_fixed',
                currency,
              )}{' '}
              off shipping
            </span>
          );
        } else {
          if (rule.value_percent >= 100) {
            return <span>Free shipping</span>;
          } else {
            return <span>{rule.value_percent}% off shipping</span>;
          }
        }
      case 'product':
        const productName = rule.product ? (
          <Link to={`/products/${rule.product.id}`}>{rule.product.name}</Link>
        ) : (
          <span>unknown product</span>
        );
        if (rule.value_type === 'fixed') {
          return (
            <span>
              {formatCurrency(rule.value_fixed || rule.value, currency)}
              {renderMultiCurrencyValuesTooltip(
                rule.$currency,
                'value_fixed',
                currency,
              )}{' '}
              off {productName}
            </span>
          );
        } else {
          return (
            <span>
              {rule.value_percent}% off {productName}
            </span>
          );
        }
      case 'category':
        const categoryName = rule.category ? (
          <Link to={`/categories/${rule.category.id}`}>
            {rule.category.name}
          </Link>
        ) : (
          <span>unknown category</span>
        );
        if (rule.value_type === 'fixed') {
          return (
            <span>
              {formatCurrency(rule.value_fixed || rule.value, currency)}
              {renderMultiCurrencyValuesTooltip(
                rule.$currency,
                'value_fixed',
                currency,
              )}{' '}
              off {categoryName}
            </span>
          );
        } else {
          return (
            <span>
              {rule.value_percent}% off {categoryName}
            </span>
          );
        }
      case 'buy_get':
        const isTotal = rule.get_total || rule.discount_type === 'total';
        return (
          <span>
            {rule.value_percent}% off{' '}
            {isTotal ? 'entire cart' : 'selected items'}
          </span>
        );
      default:
        return <span>Unknown rule '{rule.type}'</span>;
    }
  }
}
