/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import actions from 'actions';

import { Form, Field } from 'components/form';
import Link from 'components/link/link';
import { FadeInDownBounce } from 'components/transitions';
import IconPayment from 'components/icon/payment';

import { formatCurrencyRounded } from 'utils';

const mapStateToProps = (state) => ({
  record: state.data.record,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccount(id, data) {
    return dispatch(actions.data.updateRecord('accounts', id, data));
  },
});

class AccountOption extends React.PureComponent {
  static contextTypes = {
    notifySuccess: pt.func.isRequired,
    notifyError: pt.func.isRequired,
  };

  static propTypes = {
    record: pt.object.isRequired,
    updateAccount: pt.func.isRequired,
    fetchRecord: pt.func.isRequired,
    selected: pt.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      addingCredit: false,
    };

    this.formRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (state.addingCredit && props.selected === false) {
      return { addingCredit: false };
    }

    return null;
  }

  onClickAddCredit = (event) => {
    event.preventDefault();
    this.setState({ addingCredit: !this.state.addingCredit });
  };

  onClickSubmit = (event) => {
    event.preventDefault();
    this.formRef.submit();
  };

  onSubmit = async (values) => {
    const { notifySuccess, notifyError } = this.context;
    const { record, fetchRecord, updateAccount } = this.props;
    const { currency } = values;

    const result = await updateAccount(record.account_id, {
      credits: [values],
    });

    if (!result || result.error || result.errors) {
      return notifyError(result.error || result.errors || 'Unknown error');
    }

    notifySuccess(
      `Added ${formatCurrencyRounded(
        values.amount,
        currency,
      )} to customer account balance`,
    );

    await fetchRecord(record.id);

    this.setState({ addingCredit: false });
  };

  renderAccountInput() {
    const { record } = this.props;

    return (
      <FadeInDownBounce>
        <Form
          ref={(form) => (this.formRef = form)}
          className="account-option-input"
          onSubmit={this.onSubmit}
        >
          <Field type="hidden" name="currency" value={record.currency} />
          <div className="row">
            <Field
              type="currency"
              className="span1"
              name="amount"
              currency={record.currency}
              required={true}
            />
            <Field
              type="text"
              className="span3"
              name="reason"
              placeholder="Reason (optional)"
            />
          </div>
          <div className="account-option-input-actions row">
            <Link className="link-button" onClick={this.onClickAddCredit}>
              Cancel
            </Link>
            <Link
              type="submit"
              className="button button-sm button-secondary"
              onClick={this.onClickSubmit}
            >
              Add credit
            </Link>
          </div>
        </Form>
      </FadeInDownBounce>
    );
  }

  render() {
    const { record } = this.props;
    const { account, currency } = record;
    const { addingCredit } = this.state;

    return (
      <div className="payment-method-option account-option">
        <div className="payment-method-option-container">
          <div className="payment-method-option-details">
            <IconPayment method="account" />
            <div className="payment-method-option-name">
              Account credit
              <span className="payment-method-option-description">
                {formatCurrencyRounded(account.balance, currency)} available
              </span>
            </div>
          </div>
          {!addingCredit && (
            <Link className="link-button" onClick={this.onClickAddCredit}>
              Add account credit
            </Link>
          )}
        </div>
        {addingCredit && this.renderAccountInput()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountOption);
