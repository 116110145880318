import React from 'react';

// this is part of engineering on-boarding, having newbie
// engineers add to the codebase in their first days 💪

// Disclaimer:
// The views and opinions expressed in these quotes does not necessarily reflect
// the view and opinions of Swell.
const QUOTES = [
  'With great power comes great responsibility.',
  "If I don't have some cake soon, I might die.",
  'Do or do not. There is no try.',
  'Even a broken clock is right twice a day.',
  'Whereof one cannot speak, thereof one must be silent.',
  "That which isn’t good for the hive, isn't good for the bee.",
  'Time is an illusion. Lunchtime doubly so.',
  'Live life with no regrets.',
  'I do not fear this new challenge. Rather like a true warrior, I will rise to meet it.'
];

const getRandomQuote = () => {
  return QUOTES[Math.floor(Math.random() * QUOTES.length)];
};

export default class EasterEgg extends React.Component {
  constructor(props) {
    super(props);
    this.state = { quote: getRandomQuote() };
  }

  componentDidMount() {
    // change quote every 20s
    this.timerID = setInterval(() => this.nextQuote(), 20000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  nextQuote() {
    this.setState({ quote: getRandomQuote() });
  }

  getRandomQuote() {
    return QUOTES[Math.floor(Math.random() * QUOTES.length)];
  }

  render() {
    return (
      <div className="nav-easter-egg">
        {process.env.NODE_ENV === 'development' && (
          <div className="quote">“{this.state.quote}”</div>
        )}
      </div>
    );
  }
}
