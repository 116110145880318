import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import LoginLayoutSinglePage from 'components/login/layout-single';
import { firstError } from 'utils';
import auth from 'services/auth';
import actions from 'actions';
import segment from 'services/segment';
import moment from 'moment';
import VerifySignupPage from 'components/signup/verify';

const mapStateToProps = (state) => ({
  flash: state.flash,
  lead: state.client.lead,
  session: state.session,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLead: (id) => {
    return dispatch(actions.client.fetchLead(id));
  },

  resendLead: (id) => {
    return dispatch(actions.client.resendLead(id));
  },

  verifyLead: (id, data) => {
    return dispatch(actions.client.verifyLead(id, data));
  },

  createClient: (data) => {
    dispatch(actions.flash.clear());
    return dispatch(actions.client.create(data));
  },

  putSendgridContact: (query) => {
    return dispatch(actions.user.putSendgridContact(query));
  },

  flashSuccess: (message) => {
    dispatch(actions.flash.success(message));
  },

  flashError: (message) => {
    dispatch(actions.flash.error(message));
  },
});

export class SignupVerify extends React.PureComponent {
  static contextTypes = {
    setTitle: PropTypes.func.isRequired,
  };

  static onEnter() {
    window.scrollTo(0, 0);
  }

  static onChange() {
    window.scrollTo(0, 0);
  }

  state = {};
  validateTimeout = null;

  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded: false,
      verifiedByLink: false,
      onResendEmail: this.onResendEmail.bind(this),
      onSubmitCode: this.onSubmitCode.bind(this),
      onVerified: this.onVerified.bind(this),
    };
  }

  async componentWillMount() {
    const { params, router, lead, fetchLead, location } = this.props;

    this.context.setTitle('Verify your email 📥');

    if (params.id && !lead) {
      const verifyLead = await fetchLead(params.id);
      if (!verifyLead) {
        router.originalReplace('/login');
        return;
      } else if (!verifyLead.business) {
        // router.originalReplace(`/intro/${verifyLead.id}`);
        // return;
      }
    }

    if (params.key || location.query.key) {
      await this.onVerified();
    }

    this.setState({ loaded: true });
  }

  async onResendEmail() {
    const { lead, resendLead, flashSuccess } = this.props;

    await resendLead(lead.id);

    flashSuccess(`Sent email verification to ${lead.email}`);
  }

  async onVerified() {
    const { params, flashError, verifyLead, location } = this.props;

    this.setState({ loading: true });

    const lead = await verifyLead(params.id, {
      code: params.key || location.query.key,
    });

    if (!lead) {
      return;
    }
    if (lead.error || lead.errors) {
      flashError(
        lead.error ||
          firstError(
            lead.errors,
            'An unknown error occurred, please try again',
          ),
      );
      this.setState({ loading: undefined });
      return false;
    }

    this.setState({ verifiedByLink: true });

    await this.onSignupComplete(lead);
  }

  async onSubmitCode(values) {
    const { params, flashError, verifyLead } = this.props;

    this.setState({ loading: true });

    const result = await verifyLead(params.id, {
      code: values.code,
    });

    // if we have a server validation error
    if (!result) {
      this.setState({ loading: undefined });
      return;
    }
    if (result.error) {
      flashError(result.error);
      this.setState({ loading: undefined });
      return false;
    }
    if (result.errors) {
      flashError('That code appears to be incorrect, please try again');
      this.setState({ loading: undefined });
      return false;
    }

    await this.onSignupComplete(result);
  }

  async onSignupComplete(lead) {
    const { flashError, createClient, putSendgridContact, location } =
      this.props;
    const { redirect } = location.query;

    const result = await createClient({
      lead_id: lead.id,
      store_id: lead.client_id,
      store_name: lead.client_name,
      email: lead.email,
      name: lead.name,
      password: lead.password,
    });

    if (!result) {
      return;
    }
    if (result.error || result.errors) {
      flashError(
        result.error ||
          firstError(
            result.errors,
            'An unknown error occurred, please try again',
          ),
      );
      this.setState({ loading: undefined });
      return false;
    }

    await segment.track(
      'User signed up',
      {
        email: lead.email,
        client_id: result.id,
        client_name: result.name,
        stage_name: 'Qualified',
        close_date: moment().add(2, 'months').format(),
        plan_name: 'Free Trial',
      },
      {
        Salesforce: true,
      },
    );

    await putSendgridContact({
      email: lead.email,
      name: result.name,
      signed_up: true,
    });

    if (redirect) {
      window.location.replace(redirect);
    } else {
      auth.redirectClientSessionTo(lead.redirect || '/');
    }
  }

  render() {
    if (!this.state.loaded || this.state.verifiedByLink) {
      return null;
    }

    return (
      <LoginLayoutSinglePage {...this.props} {...this.state}>
        <VerifySignupPage {...this.props} {...this.state} />
      </LoginLayoutSinglePage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupVerify);
