import {
  get,
  map,
  reduce,
  isEmpty,
  includes,
  uniq,
  each,
  find,
  last,
  filter,
  sortBy,
  toArray,
  remove,
} from 'lodash';
import { getItemGroups } from './order';

function getEventsQuery(model, record) {
  const shipments = get(record, 'shipments.results');
  const returns = get(record, 'returns.results');
  const invoices = get(record, 'invoices.results');
  const payments = get(record, 'payments.results');
  const refunds = reduce(
    payments,
    (acc, payment) => {
      const refunds = get(payment, 'refunds.results');
      return !isEmpty(refunds) ? acc.concat(refunds) : acc;
    },
    [],
  );
  const query = {
    limit: 1000,
    $or: [{ model, 'data.id': record.id }],
  };

  if (!isEmpty(shipments)) {
    query.$or.push({
      model: 'shipments',
      type: { $in: ['shipment.created', 'shipment.updated'] },
      'data.id': { $in: map(shipments, 'id') },
    });
  }

  if (!isEmpty(returns)) {
    query.$or.push({
      model: 'returns',
      type: { $in: ['return.created'] },
      'data.id': { $in: map(returns, 'id') },
    });
  }

  if (!isEmpty(invoices)) {
    query.$or.push({
      model: 'invoices',
      type: { $in: ['invoice.created'] },
      'data.id': { $in: map(invoices, 'id') },
    });
  }

  if (!isEmpty(payments)) {
    query.$or.push({
      model: 'payments',
      type: { $in: ['payment.succeeded', 'payment.failed'] },
      'data.id': { $in: map(payments, 'id') },
    });
  }

  if (!isEmpty(refunds)) {
    query.$or.push({
      model: 'payments:refunds',
      type: { $in: ['payment.refund.succeeded'] },
      'data.id': { $in: map(refunds, 'id') },
    });
  }

  return query;
}

async function getEvents(model, record, api) {
  const query = getEventsQuery(model, record);
  const events = await api.get('/data/events', query);
  if (events && events.results) {
    // Always get first event if it's not `created` due to page limit
    const firstEventType = (last(events.results) || {}).type;
    if (firstEventType && firstEventType.indexOf('.created') === -1) {
      const firstEvent = await api.get('/data/events/:first', {
        'data.id': record.id,
      });
      events.results.push(firstEvent);
    }
  }
  return events;
}

function productsVariantsQuery(events) {
  const productIds = [];
  const variantIds = [];
  for (const event of events) {
    if (
      includes(['subscription.created', 'subscription.updated'], event.type)
    ) {
      if (event.data.product_id !== undefined) {
        productIds.push(event.data.product_id);
      }
      if (event.data.variant_id !== undefined) {
        variantIds.push(event.data.variant_id);
      }
    }
  }
  return {
    ...(productIds.length > 0 && {
      products: {
        url: '/products',
        method: 'get',
        data: {
          id: { $in: productIds },
        },
      },
    }),
    ...(variantIds.length > 0 && {
      variants: {
        url: '/products:variants',
        method: 'get',
        data: {
          id: { $in: variantIds },
        },
      },
    }),
  };
}

function paymentsQuery(events) {
  const paymentIds = reduce(
    events,
    (acc, event) => {
      if (
        includes(['payment.succeeded', 'payment.failed'], event.type) &&
        !includes(acc, event.data.id)
      ) {
        acc.push(event.data.id);
      }
      return acc;
    },
    [],
  );
  return {
    ...(paymentIds.length > 0 && {
      payments: {
        url: '/payments',
        method: 'get',
        data: {
          id: { $in: paymentIds },
        },
      },
    }),
  };
}

function refundsQuery(events) {
  const refundIds = reduce(
    events,
    (acc, event) => {
      if (
        event.type === 'payment.refund.succeeded' &&
        !includes(acc, event.data.id)
      ) {
        acc.push(event.data.id);
      }
      return acc;
    },
    [],
  );
  return {
    ...(refundIds.length > 0 && {
      refunds: {
        url: '/payments:refunds',
        method: 'get',
        data: {
          id: { $in: refundIds },
        },
      },
    }),
  };
}

function promotionsQuery(events) {
  const promotionIds = reduce(
    events,
    (acc, event) => {
      const promotionIds = get(event, 'data.promotion_ids');
      if (promotionIds) {
        acc = uniq(acc.concat(promotionIds));
      }
      return acc;
    },
    [],
  );
  return {
    ...(promotionIds.length > 0 && {
      promotions: {
        url: '/promotions',
        method: 'get',
        data: {
          id: { $in: promotionIds },
          fields: ['name'],
        },
      },
    }),
  };
}

function usersQuery(events) {
  const userIds = reduce(
    events,
    (acc, event) => {
      if (event.user_id) {
        acc.push(event.user_id);
        acc = uniq(acc);
      }
      return acc;
    },
    [],
  );
  return {
    ...(userIds.length > 0 && {
      users: {
        url: '/:users',
        method: 'get',
        data: {
          id: { $in: userIds },
          fields: ['name', 'username', 'email'],
          $include_deleted_users: true,
        },
      },
    }),
  };
}

function notificationsQuery(record) {
  const recordIds = [record.id];
  const payments = get(record, 'payments.results');

  if (!isEmpty(payments)) {
    each(payments, (payment) => {
      if (payment.async && payment.async_data) {
        recordIds.push(payment.id);
      }
    });
  }

  return {
    url: '/notifications',
    method: 'get',
    data: {
      limit: 0,
      record_id: { $in: recordIds },
      error: null,
      include: {
        label: {
          url: '/:notifications/{template}/label',
        },
      },
    },
  };
}

function notesQuery(record) {
  return {
    url: '/:notes',
    method: 'get',
    data: {
      limit: 0,
      record_id: record.id,
    },
  };
}

async function getRecords(record, eventResults, api) {
  const prefetchRecords = await api.post('/data/:batch', [
    notificationsQuery(record),
    notesQuery(record),
  ]);
  const [notifications, notes] = toArray(prefetchRecords);
  const events = [
    ...eventResults,
    ...notifications.results,
    ...get(notes, 'results', []),
  ];
  const records = await api.post('/data/:batch', {
    ...productsVariantsQuery(events),
    ...paymentsQuery(events),
    ...refundsQuery(events),
    ...promotionsQuery(events),
    ...usersQuery(events),
  });
  return { ...records, notifications, notes };
}

function getPayment(payments, event) {
  return find(payments && payments.results, { id: event.data.id });
}

function getRefund(refunds, event) {
  return find(refunds && refunds.results, { id: event.data.id });
}

function getPromotions(promotions, event) {
  return filter(promotions && promotions.results, (promotion) =>
    includes(event.data.promotion_ids, promotion.id),
  );
}

function getUser(users, event) {
  return find(users && users.results, { id: event.user_id });
}

function removeOrderCreatedWhenSubmitted(events) {
  const created = find(events.results, { type: 'order.created' });
  const submitted = find(events.results, { type: 'order.submitted' });

  const createdDate = Date.parse(get(created, 'data.date_created'));
  const submittedDate = Date.parse(get(submitted, 'date_created'));

  if (submitted && submittedDate - createdDate < 3000) {
    remove(events.results, created);
    submitted.data = created.data;
    submitted.date_created = created.data.date_created;
  }
}

async function populateEvents(model, record, events, api) {
  const {
    products,
    variants,
    payments,
    refunds,
    promotions,
    users,
    notifications,
    notes,
  } = await getRecords(record, events.results, api);

  each(events.results, (event) => {
    if (event.data) {
      // TODO: same for order items removed
      if (
        includes(['subscription.created', 'subscription.updated'], event.type)
      ) {
        if (event.data.product_id) {
          event.product = find(products && products.results, {
            id: event.data.product_id,
          });
        }
        if (event.data.variant_id) {
          event.variant = find(variants && variants.results, {
            id: event.data.variant_id,
          });
        }
      }

      if (includes(['payment.succeeded', 'payment.failed'], event.type)) {
        event.payment = getPayment(payments, event);
      } else if (event.type === 'payment.refund.succeeded') {
        event.refund = getRefund(refunds, event);
      }

      if (!isEmpty(event.data.promotion_ids)) {
        event.promotions = getPromotions(promotions, event);
      }
    }
    if (event.user_id) {
      event.user = getUser(users, event);
    }

    // Prefer record created date vs event
    if (event.data.date_created) {
      event.date_created = event.data.date_created;
    }
  });

  if (model === 'orders') {
    removeOrderCreatedWhenSubmitted(events);
  }

  events.results = sortBy(
    [
      ...events.results,
      ...map(notifications.results, (event) => {
        event.type = 'notification';
        if (event.user_id) {
          event.user = getUser(users, event);
        }
        // Add 3 seconds to notifications so they appear after related events
        event.date_created = Date.parse(event.date_created) + 3000;
        return event;
      }),
      ...map(notes.results, (event) => {
        event.type = 'note';
        if (event.user_id) {
          event.user = getUser(users, event);
        }
        return event;
      }),
    ],
    (event) =>
      typeof event.date_created === 'string'
        ? Date.parse(event.date_created)
        : event.date_created,
  ).reverse();

  return events;
}

export async function activityQuery(model, record, api) {
  const events = await getEvents(model, record, api);
  return events.error ? events : populateEvents(model, record, events, api);
}

// TODO: move this logic into order feature, doesn't belong here
// returns the total data of items displayed in notifications
export function getTrialTotal(record, model) {
  if (!record || !record.trial || model !== 'orders') {
    return {};
  }

  const itemGroups = getItemGroups(record.items);

  if (itemGroups.trial && itemGroups.trial.length) {
    const itemsByTrialDays = itemGroups.trial.reduce((acc, item) => {
      const trialDays = get(item, 'purchase_option.trial_days');
      if (!trialDays) {
        return acc;
      }
      if (acc[trialDays]) {
        acc[trialDays].push(item);
      } else {
        acc[trialDays] = [item];
      }
      return acc;
    }, {});

    itemGroups.trial = [];
    for (const [trialDays, items] of Object.entries(itemsByTrialDays)) {
      itemGroups.trial.push({
        trial_days: trialDays,
        items,
      });
    }
  }

  return { trial_total: itemGroups };
}
