import React from 'react';
import pt from 'prop-types';

import { countryName } from 'utils/geo';
import { contentFieldPreviews } from 'utils/content';
import { truncatedText } from 'utils/collection';

function OrderAddress({ address, record, content, collection, zone }) {
  const contentValues = content
    ? contentFieldPreviews(
        record || address,
        content.models,
        collection,
        zone,
        zone,
      )
    : [];

  if (!contentValues.length && (!address || !address.address1)) {
    return <span className="muted">None</span>;
  }

  return (
    <div className="order-address">
      {address.name && <div>{address.name}</div>}
      {address.company && <div>{address.company}</div>}
      {address.address1 && <div>{address.address1}</div>}
      {address.address2 && <div>{address.address2}</div>}
      <div>
        {address.city && `${address.city}, `}
        {address.state && `${address.state} `}
      </div>
      {address.zip && <div>{address.zip}</div>}
      {address.country && <div>{countryName(address.country)}</div>}
      {address.phone && <div>{address.phone}</div>}
      {contentValues.map((value, i) => (
        <div className="order-address-field" key={i}>
          {truncatedText(value, 70)}
        </div>
      ))}
    </div>
  );
}

OrderAddress.propTypes = {
  address: pt.object,
  record: pt.object,
  content: pt.object.isRequired,
  collection: pt.string,
  zone: pt.string,
};

export default React.memo(OrderAddress);
