import map from 'lodash/map';
import get from 'lodash/get';

import api from 'services/api';

export const NOTIFICATIONS_FETCH = 'notifications/fetch';
export const NOTIFICATIONS_CREATE = 'notifications/create';
export const NOTIFICATIONS_UPDATE = 'notifications/update';
export const NOTIFICATIONS_DELETE = 'notifications/delete';
export const NOTIFICATIONS_UPDATE_ENABLED = 'notifications/updateEnabled';
export const NOTIFICATIONS_FETCH_ENABLED = 'notifications/fetchEnabled';
export const NOTIFICATIONS_FETCH_ABANDONED_CARTS =
  'notifications/fetchAbandonedCarts';
export const NOTIFICATIONS_FETCH_CUSTOM = 'notifications/fetchCustom';
export const NOTIFICATIONS_FETCH_EMAILS = 'notifications/fetchEmails';
export const NOTIFICATIONS_RESET_CACHE = 'notifications/resetCache';
export const NOTIFICATIONS_SEND = 'notifications/send';
export const NOTIFICATIONS_LOADING = 'notifications/loading';

export const PRINT_TEMPLATE_FETCH = 'template/fetch';
export const PRINT_TEMPLATES_CREATE = 'template/create';
export const PRINT_TEMPLATES_UPDATE = 'template/update';
export const PRINT_TEMPLATES_DELETE = 'template/delete';
export const PRINT_TEMPLATES_FETCH = 'template/fetchPrintTemplates';
export const PRINT_TEMPLATES_RESET_CACHE = 'template/resetCache';

const actions = {
  fetch(id, defaultNoteId) {
    return (dispatch, getState) => {
      const { client, notifications } = getState();
      let defaultId = defaultNoteId || id;
      let defaultClientId = null;

      if (notifications.abandonedCarts[id]) {
        let num = id.replace('carts.recovery-', '');
        num = ~~num === ~~num ? ~~num : 0;
        defaultId = num > 1 ? `carts.recovery-${num - 1}` : 'carts.recovery';

        defaultClientId = num > 0 ? client.id : null;
      }

      const isV2 = client.v2 || client.v2_notifications;
      return dispatch({
        type: NOTIFICATIONS_FETCH,
        meta: { id },
        payload: this.getFetchPayload(id, defaultId, defaultClientId, isV2),
      });
    };
  },

  load(id, defaultId) {
    return (dispatch, getState) => {
      const { cache } = getState().notifications;
      if (cache[id]) {
        dispatch({
          type: NOTIFICATIONS_FETCH,
          payload: cache[id],
        });
        return cache[id];
      }
      return dispatch(this.fetch(id, defaultId));
    };
  },

  resetCache() {
    return {
      type: NOTIFICATIONS_RESET_CACHE,
    };
  },

  resetPrintTemplateCache() {
    return {
      type: PRINT_TEMPLATES_RESET_CACHE,
    };
  },

  create(values) {
    return {
      type: NOTIFICATIONS_CREATE,
      payload: api.post('/data/:notifications', values),
    };
  },

  update(id, values) {
    return {
      type: NOTIFICATIONS_UPDATE,
      payload: api.put('/data/:notifications/{id}', {
        id,
        ...values,
        content: {
          html: { data: values.content },
        },
      }),
      meta: { id },
    };
  },

  delete(id) {
    return {
      type: NOTIFICATIONS_DELETE,
      payload: api.delete('/data/:notifications/{id}', {
        id,
      }),
    };
  },

  getFetchPayload(id, defaultId, defaultClientId, isV2) {
    return api
      .getLocalized('/data/:batch', {
        record: {
          url: '/:notifications/{id}',
          data: {
            id: isV2 ? id : `${id}.v2`,
          },
        },
        recordHTMLContent: {
          url: '/:notifications/{id}/content/html/data',
          data: {
            id: isV2 ? id : `${id}.v2`,
          },
        },
        defaultRecord: {
          url: '/:notifications/{id}',
          data: {
            id: isV2 ? `com.${defaultId}` : `com.${defaultId}.v2`,
            client_id: defaultClientId,
          },
        },
        defaultHTMLContent: {
          url: '/:notifications/{id}/content/html/data',
          data: {
            id: isV2 ? `com.${defaultId}` : `com.${defaultId}.v2`,
            client_id: defaultClientId,
          },
        },
      })
      .then((result) => {
        const record = result.record || result.defaultRecord;
        return {
          name: record && getNoteId(record),
          record: result.record || result.defaultRecord,
          content: result.recordHTMLContent,
          defaultRecord: result.defaultRecord || result.record,
          defaultContent: result.defaultHTMLContent,
        };
      });
  },

  fetchPrintTemplates(id) {
    return (dispatch, getState) => {
      const { client } = getState();
      const defaultId = 'orders.print';
      const defaultClientId = null;
      const isV2 = client.v2 || client.v2_notifications;
      return dispatch({
        type: PRINT_TEMPLATE_FETCH,
        payload: this.getFetchPayload(id, defaultId, defaultClientId, isV2),
        meta: { id },
      });
    };
  },

  loadPrintTemplate(id) {
    return (dispatch, getState) => {
      const { cache } = getState().notifications;
      if (cache.printTemplates && cache.printTemplates[id]) {
        dispatch({
          type: PRINT_TEMPLATE_FETCH,
          payload: cache.printTemplates[id],
        });
        return cache.printTemplates[id];
      }
      return dispatch(this.fetchPrintTemplates(id));
    };
  },

  createPrintTemplate(values) {
    return {
      type: PRINT_TEMPLATES_CREATE,
      payload: api.post('/data/:notifications', values),
    };
  },

  updatePrintTemplate(id, values) {
    return {
      type: PRINT_TEMPLATES_UPDATE,
      meta: { id },
      payload: api.put('/data/:notifications/{id}', {
        id,
        ...values,
        content: values.content && {
          html: { data: values.content },
        },
      }),
    };
  },

  deletePrintTemplate(id) {
    return {
      type: PRINT_TEMPLATES_DELETE,
      meta: { id },
      payload: api.delete('/data/:notifications/{id}', {
        id,
      }),
    };
  },

  fetchOrderPrintTemplates() {
    return (dispatch, getState) => {
      const { cache } = getState().notifications;

      if (cache.printTemplates) {
        return dispatch({
          type: PRINT_TEMPLATES_FETCH,
          payload: cache.printTemplates,
        });
      }

      return dispatch({
        type: PRINT_TEMPLATES_FETCH,
        payload: api.getLocalized('/data/:notifications', {
          fields:
            'id, model, name, subject, label, description, default, app_id',
          limit: null,
          v2: true,
          model: 'orders',
          method: 'print',
          sort: 'label asc',
        }),
      });
    };
  },

  fetchEnabled() {
    return {
      type: NOTIFICATIONS_FETCH_ENABLED,
      payload: api
        .getLocalized('/data/:notifications', {
          fields: 'id, model, name, enabled, app_id',
          limit: null,
          v2: true,
        })
        .then((result) => {
          return result.results.reduce(
            (acc, note) => ({
              ...acc,
              [getNoteId(note)]:
                note.enabled !== undefined ? note.enabled : true,
            }),
            {},
          );
        }),
    };
  },

  fetchAbandonedCarts() {
    return {
      type: NOTIFICATIONS_FETCH_ABANDONED_CARTS,
      payload: api
        .getLocalized('/data/:notifications', {
          fields: 'id, model, name, subject, app_id',
          limit: null,
          v2: true,
          model: 'carts',
          name: { $regex: '^recovery' },
          sort: 'name asc',
        })
        .then((result) => {
          return result.results.reduce(
            (acc, note) => ({
              ...acc,
              [getNoteId(note)]: note,
            }),
            {},
          );
        }),
    };
  },

  fetchCustom() {
    return {
      type: NOTIFICATIONS_FETCH_CUSTOM,
      payload: api
        .getLocalized('/data/:notifications', {
          fields: 'id, model, name, description, label, app_id',
          limit: null,
          v2: true,
          client_id: { $exists: true },
          extends: { $exists: false },
          // Ignore cart recovery templates
          name: { $regex: '^(?!recovery)' },
          method: { $ne: 'print' },
        })
        .then((result) => {
          return result.results.reduce(
            (acc, note) => ({
              ...acc,
              [getNoteId(note)]: note,
            }),
            {},
          );
        }),
    };
  },

  updateEnabled(enabled) {
    return (dispatch, getState) => {
      const { client } = getState();
      const isV2 = client.v2 || client.v2_notifications;
      return dispatch({
        type: NOTIFICATIONS_UPDATE_ENABLED,
        payload: api
          .put(
            '/data/:batch',
            map(enabled, (isEnabled, id) => ({
              url: '/:notifications/{id}',
              data: {
                id: isV2 ? id : `${id}.v2`,
                enabled: isEnabled,
              },
            })),
          )
          .then(() => {
            return enabled;
          }),
      });
    };
  },

  fetchEmails() {
    return {
      type: NOTIFICATIONS_FETCH_EMAILS,
      payload: api
        .get('/data/:users', {
          fields: 'email',
          limit: null,
        })
        .then((result) => {
          return {
            fromEmails: result.users.results.map((user) => user.email),
          };
        }),
    };
  },

  send(url, id, args) {
    return {
      type: NOTIFICATIONS_SEND,
      payload: api.put(`/data/${url}`, {
        $notify: {
          id: `${id}.v2`,
          to: args.to_email,
          from: args.from_email,
          subject: args.subject,
          data: args.data,
        },
      }),
    };
  },
};

export default actions;

export const initialState = {
  record: {},
  content: '',
  defaultRecord: {},
  defaultContent: '',
  fromEmails: [],
  enabled: {},
  custom: {},
  abandonedCarts: {},
  orderPrintTemplates: {},
  loading: false,
  cache: {},
};

export function reducer(state = initialState, action) {
  let id, note;

  switch (action.type) {
    case 'RESET':
      return initialState;
    case PRINT_TEMPLATE_FETCH:
    case NOTIFICATIONS_FETCH:
      if (action.payload.error) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
        cache: {
          ...state.cache,
          ...(action.meta && action.meta.id
            ? { [action.meta.id]: action.payload }
            : {}),
        },
      };

    case PRINT_TEMPLATES_RESET_CACHE:
    case NOTIFICATIONS_RESET_CACHE:
      return {
        ...state,
        cache: {},
      };

    case NOTIFICATIONS_FETCH_ENABLED:
      if (action.payload.error) {
        return state;
      }
      return {
        ...state,
        enabled: action.payload,
      };

    case NOTIFICATIONS_FETCH_ABANDONED_CARTS:
      if (action.payload.error) {
        return state;
      }
      return {
        ...state,
        abandonedCarts: action.payload,
      };

    case NOTIFICATIONS_FETCH_CUSTOM:
      if (action.payload.error) {
        return state;
      }
      return {
        ...state,
        custom: action.payload,
      };

    case NOTIFICATIONS_FETCH_EMAILS:
      if (action.payload.error) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
      };

    case NOTIFICATIONS_CREATE:
      if (action.payload.error) {
        return state;
      }
      return {
        ...state,
        enabled: {
          ...state.enabled,
          [action.payload.id]: true,
        },
        ...(action.payload.model === 'carts' && {
          abandonedCarts: {
            ...state.abandonedCarts,
            [action.payload.id]: action.payload,
          },
        }),
      };

    case NOTIFICATIONS_UPDATE:
      if (action.payload.error) {
        return state;
      }
      note = action.payload;
      if (note.errors) {
        // Note: there's an error here when updating a non-existing notification
        // but it doesn't seem to cause a problem
        return state;
      }
      id = getNoteId(note);
      const cache = {
        ...state.cache,
        [id]: undefined,
      };
      if (state.abandonedCarts[id]) {
        return {
          ...state,
          cache,
          abandonedCarts: {
            ...state.abandonedCarts,
            [id]: action.payload,
          },
        };
      } else if (state.custom[id]) {
        return {
          ...state,
          cache,
          custom: {
            ...state.custom,
            [id]: action.payload,
          },
        };
      } else {
        return {
          ...state,
          cache,
        };
      }

    case NOTIFICATIONS_DELETE:
      if (action.payload && action.payload.error) {
        return state;
      }

      note = action.payload || {};
      id = getNoteId(note);

      if (state.abandonedCarts[id]) {
        return {
          ...state,
          abandonedCarts: {
            ...state.abandonedCarts,
            [id]: undefined,
          },
        };
      } else {
        return state;
      }

    case NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case PRINT_TEMPLATES_FETCH:
      if (action.payload.error) {
        return state;
      }

      return {
        ...state,
        cache: {
          ...state.cache,
          printTemplates: action.payload,
        },
        orderPrintTemplates: action.payload,
      };

    case PRINT_TEMPLATES_CREATE:
      if (action.payload.error) {
        return state;
      }
      return {
        ...state,
        cache: {
          ...state.cache,
          printTemplates: undefined,
        },
      };

    case PRINT_TEMPLATES_UPDATE:
      if (action.payload.error) {
        return state;
      }
      note = action.payload;

      if (note.errors) {
        return state;
      }

      return {
        ...state,
        cache: {
          ...state.cache,
          [action.meta.id]: undefined,
          printTemplates: undefined,
        },
      };

    case PRINT_TEMPLATES_DELETE:
      if (action.payload && action.payload.error) {
        return state;
      }

      note = action.payload || {};

      return {
        ...state,
        cache: {
          ...state.cache,
          [action.meta.id]: undefined,
          printTemplates: undefined,
        },
        orderPrintTemplates: {
          ...state.orderPrintTemplates,
          results: get(state.orderPrintTemplates, 'results', []).filter(
            (template) => {
              return !get(note, 'id', '').includes(template.id);
            },
          ),
        },
      };

    default:
      return state;
  }
}

function getNoteId(note) {
  return `${note.app_id ? `app_${note.app_id}.` : ''}${(note.model || '').replace(
    /\//g,
    '_',
  )}.${(note.name || '').replace(/\.v2$/, '')}`;
}
