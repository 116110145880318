import React from 'react';

import ConsoleAPI from 'containers/ConsoleAPI';
import ConsoleLogs from 'containers/ConsoleLogs';
import NotFoundPage from 'components/pages/error/404';

import View from 'components/view';

export default class Console extends React.Component {
  state = {};
  scrollInterval = null;

  constructor(props) {
    super(props);

    this.state = {
      editing: props.query && props.query.method ? false : true,
    };

    this.methods = {
      onClickScrollBackTop: this.onClickScrollBackTop.bind(this),
    };
  }

  componentDidMount() {
    this.watchScrollTop();
  }

  componentWillUnmount() {
    this.unwatchScrollTop();
  }

  watchScrollTop() {
    this.scrollInterval = setInterval(() => {
      try {
        const main = document.getElementById('main');
        const top = main && main.scrollTop;
        if (top >= 300 && !this.state.showBackTop) {
          this.setState({ showBackTop: true });
        } else if (top < 300 && this.state.showBackTop) {
          this.setState({ showBackTop: false });
        }
      } catch (err) {}
    }, 500);
  }

  unwatchScrollTop() {
    clearInterval(this.scrollInterval);
  }

  onClickScrollBackTop = (event) => {
    event.preventDefault();
    const main = document.getElementById('main');
    if (main) main.scrollTop = 0;
    this.setState({ showBackTop: false });
  };

  render() {
    const { route, ...props } = this.props;

    const showApi = !route.section || route.section === 'api';
    const showLogs = route.section === 'logs';

    if (!showApi && !showLogs) {
      return <NotFoundPage />;
    }

    return (
      <div className="console">
        <View
          detail={true}
          full={true}
          headerTitle={props.title || 'Console'}
          headerSubtitle={
            props.subtitle || "Run API requests on your store's backend"
          }
          headerActions={
            props.actions || [
              {
                label: 'API reference',
                fa: 'book',
                type: 'sub',
                link: 'https://developers.swell.is/backend-api/introduction',
                target: 'blank',
              },
            ]
          }
          tabs={{
            default: {
              label: 'API',
              link: '/console',
            },
            logs: {
              label: 'Logs',
              link: '/console/logs',
            },
          }}
          helpLink={false}
        >
          {showApi ? (
            <ConsoleAPI {...this.props} {...this.methods} />
          ) : (
            showLogs && <ConsoleLogs {...this.props} {...this.methods} />
          )}
        </View>
      </div>
    );
  }
}
