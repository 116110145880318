import React from 'react';
import PropTypes from 'prop-types';
import { map, reduce, isEqualWith, cloneDeep } from 'lodash';
import classNames from 'classnames';

import Icon from 'components/icon';
import { FadeIn } from 'components/transitions';

import Label from './label';

function customCompare(a, b) {
  return typeof a === 'function' && typeof b === 'function'
    ? `${a}` === `${b}`
    : undefined;
}

function getInitialOptions(props) {
  return reduce(
    props.options,
    (memo, option, key) => {
      if (!option) return memo;
      const isObject = typeof option === 'object';
      memo[key] = {
        ...cloneDeep(option),
        value: isObject ? option.value || option.id : option,
        label: String(
          isObject
            ? option.label !== undefined
              ? option.label
              : option.name !== undefined
              ? option.name
              : option.value || option.id
            : option,
        ).trim(),
      };
      return memo;
    },
    {},
  );
}

export default class InputMultiselect extends React.PureComponent {
  static propTypes = {
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    help: PropTypes.string,
    tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.string,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);

    const options = getInitialOptions(this.props);

    this.state = {
      showing: false,
      selected: null,
      options,
      prev: { options: this.props.options },
    };

    this.rootRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutsideComponent);

    this.onChange(this.state.options);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutsideComponent);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.options &&
      !isEqualWith(props.options, state.prev.options, customCompare)
    ) {
      const options = getInitialOptions(props);

      return {
        options,
        prev: { options: props.options },
      };
    }

    return null;
  }

  onChange(options) {
    const event = new Event('change', { bubbles: true });

    this.props.onChange(event, {
      flat: this.getCheckedOptions(options),
      nested: this.getCheckedOptions(options, false),
    });
  }

  setOptions(options) {
    this.setState({ options: { ...options } });
    this.onChange(options);
  }

  onMouseDown = (event) => {
    event.stopPropagation();
    this.setState({ showing: !this.state.showing });
  };

  onClickChevron = (event) => {
    event.stopPropagation();
    this.setState({ showing: !this.state.showing });
    if (!this.state.showing) {
      this.inputRef.current.focus();
    }
  };

  getCheckedOptions(options, flat = true) {
    if (flat) {
      return reduce(
        options,
        (memo, option) => {
          if (option.fields) {
            memo.push(...this.getCheckedOptions(option.fields, true));
          } else if (option.checked) {
            memo.push({ ...option, checked: undefined });
            // memo.push({ ...option });
          }

          return memo;
        },
        [],
      );
    }

    const nested = cloneDeep(options);

    for (const group of Object.values(nested)) {
      const fields = {};

      for (const [fieldKey, field] of Object.entries(group.fields)) {
        if (field.checked) {
          fields[fieldKey] = { ...field, checked: undefined };
        }
      }

      group.fields = fields;
    }

    return nested;
  }

  findOptionByPath(options, path) {
    return reduce(
      path,
      (memo, field, index) => {
        const option = memo[field] || memo[+field];

        return index === path.length - 1 ? option : option.fields;
      },
      options,
    );
  }

  selectValue = (event) => {
    const { showing, selected, options } = this.state;

    if (!showing) {
      return;
    }

    event.preventDefault();

    const path = String(selected).split('\\\\');
    const option = this.findOptionByPath(options, path);

    if (path.length > 1) {
      const parentOption = this.findOptionByPath(
        options,
        path.slice(0, path.length - 1),
      ); // number of checked options in current group

      const checkedCount = this.getCheckedOptions(parentOption.fields).length;

      if (
        option.checked &&
        parentOption.minChecked &&
        checkedCount === parentOption.minChecked
      ) {
        return;
      }

      if (
        !option.checked &&
        parentOption.maxChecked &&
        checkedCount === parentOption.maxChecked
      ) {
        if (checkedCount === 1) {
          for (const option of Object.values(parentOption.fields)) {
            if (option.checked) {
              option.checked = false;
              break;
            }
          }
        } else {
          return;
        }
      }
    }

    option.checked = !option.checked;

    this.setOptions(options);
  };

  onMouseOverValue = (event) => {
    const { index } = event.currentTarget.dataset;
    const indexNumber = Number(index);

    this.setState({
      selected: Number.isNaN(indexNumber) ? index : indexNumber,
    });
  };

  onClickOutsideComponent = (event) => {
    if (!this.rootRef.current.contains(event.target)) {
      if (this.state.showing) {
        this.setState({ showing: false, selected: null });
      }
    }
  };

  renderOption = (option, index) => {
    if (option.fields) {
      return (
        <li key={index} className="form-select-option-group">
          <span className="form-select-option-group-label">{option.label}</span>

          <ul className="form-select-option-group-list">
            {map(option.fields, (childOption, childOptionIndex) =>
              this.renderOption(childOption, `${index}\\\\${childOptionIndex}`),
            )}
          </ul>
        </li>
      );
    }

    const { selected: selectedIndex } = this.state;
    const selected = selectedIndex === index;

    return (
      <li
        key={index}
        role="option"
        data-index={index}
        aria-selected={selected}
        className={classNames('item', { selected })}
        onMouseOver={this.onMouseOverValue}
        onClick={this.selectValue}
      >
        <span className="form-select-option">
          <span className="form-multiselect-check-wrapper">
            <FadeIn
              className="check"
              transitionAppear={false}
              active={!!option.checked}
            >
              <Icon fa="check" />
            </FadeIn>
          </span>

          <span className="col form-small-padding-left">{option.label}</span>
        </span>
      </li>
    );
  };

  renderOptions() {
    const { options } = this.state;
    const items = map(options, this.renderOption);

    if (items.length > 0) {
      return <ul className="form-select-list">{items}</ul>;
    }

    return null;
  }

  render() {
    const { label, help, tabIndex, disabled, className, id, placeholder } =
      this.props;

    const { showing } = this.state;

    return (
      <div className="form-select multi" ref={this.rootRef}>
        <Label label={label} help={help} htmlFor={id} />

        <div className="form-field-input">
          <input
            type="text"
            ref={this.inputRef}
            readOnly
            tabIndex={tabIndex}
            value={placeholder}
            disabled={disabled}
            autoComplete="off"
            className={classNames('form-select-input', className, {
              disabled,
              focus: showing,
            })}
            onMouseDown={this.onMouseDown}
          />

          <button onClick={this.onClickChevron} type="button">
            <Icon type="chevron" className={classNames({ disabled })} />
          </button>

          <div className="form-select-list-container">
            {!disabled && showing && this.renderOptions()}
          </div>
        </div>
      </div>
    );
  }
}
