import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { find, reduce } from 'lodash';
import classNames from 'classnames';

import Icon from 'components/icon';

import './CollectionItem.scss';

export default class CollectionItem extends React.PureComponent {
  static propTypes = {
    values: PropTypes.object,
    field: PropTypes.object,
    contextMenuButton: PropTypes.object,
    className: PropTypes.string,
    // Label representing the name of the collection item
    label: PropTypes.string,
    // Label representing the type of the item, ex: type of page section
    typeLabel: PropTypes.string,
  };

  // This is needed for when we want to indicate which field
  // to be used for rendering the value opposed to the default rendering
  renderBodyWithItemLabel(field, values) {
    const itemLabelField = find(
      field.fields,
      (item) => item.id === field.item_label,
    );

    if (itemLabelField.type === 'generic_lookup') {
      const collectionItemLabel = reduce(
        values[field.item_label],
        (acc, cur) => acc.concat(`${cur.name}; `),
        '',
      ).trim();

      return (
        <Fragment>
          {field.icon && (
            <div className="CollectionItem-title-icon" key={values.icon}>
              <Icon fa={field.icon} faType="solid" />
            </div>
          )}
          <div className="CollectionItem-title">{collectionItemLabel}</div>
        </Fragment>
      );
    }
  }

  renderBody(values, label, typeLabel) {
    return (
      <Fragment>
        {values.icon && (
          <Fragment>
            {[
              <div className="CollectionItem-title-icon" key={values.icon}>
                <span className="iconify" data-icon={values.icon} />
              </div>,
            ]}
          </Fragment>
        )}
        {typeLabel ? (
          <Fragment>
            <div className="CollectionItem-title-label">{label}</div>
            <div className="CollectionItem-title-type">{typeLabel}</div>
          </Fragment>
        ) : (
          <div className="CollectionItem-title">{label}</div>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      values,
      field,
      contextMenuButton,
      label,
      typeLabel,
      className,
      ...restProps
    } = this.props;

    return (
      <button
        className={classNames('CollectionItem', className)}
        {...restProps}
        type="button"
      >
        {field.item_label
          ? this.renderBodyWithItemLabel(field, values)
          : this.renderBody(values, label, typeLabel)}
        {contextMenuButton}
      </button>
    );
  }
}
