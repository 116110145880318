import React from 'react';
import pt from 'prop-types';
import Modal from 'components/modal';
import PaymentCardsEditRadio from 'components/payment/cards-edit-radio';

export default class CustomerBillingCardEdit extends React.PureComponent {
  static propTypes = {
    fetchRecord: pt.func,
    loading: pt.bool,
    onClickBillingCardEdit: pt.func.isRequired,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };
  }

  onClickEditCard = () => {
    this.setState({ editing: !this.state.editing });
  };

  onClose = (event) => {
    if (this.state.editing) {
      event.preventDefault();

      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.props.onClickBillingCardEdit(event);
        },
      });
    } else {
      this.props.onClickBillingCardEdit(event);
    }
  };

  render() {
    const { loading, fetchRecord } = this.props;

    return (
      <Modal
        title="Manage payment methods"
        className="customer-view-modal-edit-billing"
        actions={[
          {
            label: 'Done',
            type: 'secondary',
            onClick: this.onClose,
          },
        ]}
        width={700}
        cancel={false}
        loading={loading}
        onClose={this.onClose}
      >
        <PaymentCardsEditRadio
          fetchRecord={fetchRecord}
          onClickAddCard={this.onClickEditCard}
          onClickEditCard={this.onClickEditCard}
          onClickCancel={this.onClickEditCard}
          deletable={true}
          selectable={false}
        />
      </Modal>
    );
  }
}
