import React from 'react';
import PropTypes from 'prop-types';
import { reduce } from 'lodash';
import { connect } from 'react-redux';
import AuthorizePage from 'components/oauth/authorize';
import segment from 'services/segment';
import actions from 'actions';

const mapStateToProps = (state) => ({
  flash: state.flash,
  oauth: state.oauth,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchClientInfo: (query) => {
    return dispatch(actions.oauth.fetchClientInfo(query));
  },

  getAuthorization: (query) => {
    return dispatch(actions.oauth.getAuthorization(query));
  },

  createAuthorization: (data) => {
    return dispatch(actions.oauth.createAuthorization(data));
  },

  flashSuccess: (message) => {
    dispatch(actions.flash.success(message));
  },

  flashError: (message) => {
    dispatch(actions.flash.error(message));
  },
});

export class OAuth extends React.PureComponent {
  static contextTypes = {
    setTitle: PropTypes.func.isRequired,
  };

  static onEnter() {
    window.scrollTo(0, 0);
  }

  static onChange() {
    window.scrollTo(0, 0);
  }

  state = {};

  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded: false,
      onSubmitForm: this.onSubmitForm.bind(this),
    };
  }

  async componentWillMount() {
    const {
      fetchClientInfo,
      getAuthorization,
      location: { query },
    } = this.props;
    this.context.setTitle('Authorize application');

    await fetchClientInfo(query);

    const auth = await getAuthorization(query);

    if (auth) {
      window.location.href = `${query.redirect_uri}${
        query.redirect_uri.indexOf('?') > 0 ? '&' : '?'
      }${this.getRedirectParams(auth)}`;

      return;
    }

    this.setState({ loaded: true });
  }

  getRedirectParams(auth) {
    return reduce(
      {
        code: auth.code,
        state: auth.state,
        store_id: auth.client_id,
        hmac: auth.code_hmac,
      },
      (acc, value, key) => [...acc, `${key}=${encodeURIComponent(value)}`],
      [],
    ).join('&');
  }

  async onSubmitForm(values) {
    const {
      oauth: { user, app },
      flashError,
      createAuthorization,
      location: { query },
    } = this.props;

    const { store_id } = values;

    const auth = await createAuthorization({
      store_id: query.store_id || store_id,
      client_id: query.client_id,
      scope: query.scope,
      state: query.state,
      redirect_uri: query.redirect_uri,
      code_challenge: query.code_challenge,
      code_challenge_method: query.code_challenge_method,
    });

    if (!auth) {
      return;
    }
    if (auth.error || auth.errors) {
      flashError(auth);
      return false;
    }

    await segment.track('App authorized', {
      email: user.email,
      username: user.username,
      client_id: user.client_id,
      client_name: user.client_name,
      app_id: app.id,
      app_name: app.name,
      app_published: app.published ? 'published' : 'unpublished',
    });

    window.location.href = `${query.redirect_uri}${
      query.redirect_uri.indexOf('?') > 0 ? '&' : '?'
    }${this.getRedirectParams(auth)}`;
  }

  render() {
    const { loaded } = this.state;

    if (!loaded) {
      return null;
    }

    return <AuthorizePage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OAuth);
