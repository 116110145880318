import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@radix-ui/react-dropdown-menu';

export default class DropdownMenuLabel extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
  };

  render() {
    const { children, className } = this.props;
    return (
      <Label
        className={className ? className : 'dropdown-menu-label'}
        {...this.props}
      >
        {children}
      </Label>
    );
  }
}
