import React, { Fragment } from 'react';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import DOMPurify from 'isomorphic-dompurify';
import { connect } from 'react-redux';
import UrlPattern from 'url-pattern';
import classNames from 'classnames';
import pt from 'prop-types';
import trimStart from 'lodash/trimStart';
import trimEnd from 'lodash/trimEnd';
import find from 'lodash/find';

import api from 'services/api';
import actions from 'actions';

import {
  loadSettings,
  resetFiles,
  publishCheckout,
  fetchPublishStatus,
  updateEditorSection,
  sendEditorMessage,
  handleEditorMessage,
  updatePublishStatus,
} from '../../actions';

import { formatDate } from 'utils';
import { renderLogs } from 'utils/string';

import {
  getStorefrontURL,
  storefrontCheckoutURL,
  storefrontPublicDeploymentURL,
} from 'utils/storefront';

import Link from 'components/link';
import Icon from 'components/icon';
import Field from 'components/form/field';
import Button from 'components/button';
import Loading from 'components/loading';
import LoadingBar from 'components/loading/bar';
import ButtonLink from 'components/button/link';
import ViewLoading from 'components/view/loading';
import LocaleSelector from 'components/locale/selector';
import { FadeIn } from 'components/transitions';
import Scrollbars from 'components/scrollbars';

import SettingsMenu from '../SettingsMenu';
import DeviceToolbar from '../DeviceToolbar';

import './EditorLayout.scss';
import '../../libs/croppie/croppie.css';

let USING_MESSAGE_API;
let EDITOR_URL_FULL = '';

window.editorRefreshPage = (callback = null) => {
  if (USING_MESSAGE_API !== false) {
    if (callback) {
      callback();
    }
    return;
  }

  /** @type {HTMLIFrameElement | null} */
  const iframe = document.getElementById('editor-viewport');

  if (iframe) {
    // forces a reload while avoiding cors and content policy issues
    iframe.src = iframe.src;
  }

  function onLoad(event) {
    callback(event);
    iframe.removeEventListener('load', onLoad);
  }

  if (callback) {
    if (iframe) {
      iframe.addEventListener('load', onLoad);
    } else {
      callback();
    }
  }
};

@connect(
  (state) => ({
    client: state.client,
    account: state.account,
    loading: state.loading,
    storefront: state.storefronts.storefront,
    hasPreview: !!state.storefronts.storefront?.preview_deployment_name,
    testCheckout: state.storefronts.testCheckout,
    checkoutSettings: state.settings.checkout,
    showEditor: state.editor.showEditor,
    editorSection: state.editor.section,
    editorLoading: state.editor.loading,
    themeConfig: state.themeConfig,
    publishStatus: state.git.publishStatus,
    flash: state.flash,
  }),
  (dispatch) => ({
    loadSettings: () => dispatch(loadSettings()),
    resetFiles: () => dispatch(resetFiles()),
    publishCheckout: () => dispatch(publishCheckout()),
    fetchPublishStatus: (storefrontId) =>
      dispatch(fetchPublishStatus(storefrontId)),
    updatePublishStatus: (data) => dispatch(updatePublishStatus(data)),
    fetchStorefront: (id) => dispatch(actions.storefronts.fetch(id)),
    publishStorefront: (id, $publish = true) =>
      dispatch(actions.storefronts.update(id, { $publish })),
    getTestCheckout: (reset) =>
      dispatch(actions.storefronts.getTestCheckout(reset)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
    updateSection: (section, scrollTop) =>
      dispatch(updateEditorSection(section, scrollTop)),
    notifyError: (message) => {
      dispatch(actions.flash.error(message));
    },
  }),
)
class EditorLayout extends React.Component {
  static propTypes = {
    router: pt.object,
    params: pt.object,
    location: pt.object,
    loading: pt.bool,
    hasPreview: pt.bool,
    account: pt.object,
    client: pt.object,
    storefront: pt.object,
    testCheckout: pt.object,
    renderedLogs: pt.object,
    publishStatus: pt.object,
    editorSection: pt.object,
    themeConfig: pt.object,
    checkoutSettings: pt.object,
    showReloadNotice: pt.bool,

    showLoading: pt.func,
    hideLoading: pt.func,
    loadSettings: pt.func,
    updateSection: pt.func,
    fetchStorefront: pt.func,
    getTestCheckout: pt.func,
    publishCheckout: pt.func,
    publishStorefront: pt.func,
    fetchPublishStatus: pt.func,
    updatePublishStatus: pt.func,
    notifyError: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
    testEnv: pt.string,
    user: pt.object,
  };

  state = {
    loaded: false,
    viewport: 'desktop',
    show: true,
    editorLoaded: false,
    editorUrl: null,
    editorNavIndex: 0,
    editorNavStack: [],
    editorRoute: null,
    editorLocale: null,
    containerUrl: null,
    showPublishingModal: false,
    showLocaleSelector: false,
    appLogs: null,
    appLogSource: null,
    appLogLines: 100,
    showAppLogs: false,
    loadingAppLogs: false,
    showPublishLogs: false,
    publishProgress: 0,
    justPublished: false,
    showContentPublishedWarning: false,
    usingMessageApi: USING_MESSAGE_API,
    inCheckout: false,
  };

  editorFrame = null;
  editorBound = false;
  editorConnected = false;
  navigatedBack = false;
  navigatedForward = false;
  loadingTimer = null;

  async componentDidMount() {
    const {
      router,
      params,
      loadSettings,
      fetchStorefront,
      fetchPublishStatus,
      notifyError,
    } = this.props;

    this.appLogsRef = React.createRef();
    this.publishLogsRef = React.createRef();

    const storefront = await fetchStorefront(params.id);

    // Storefront has to exist
    if (!storefront) {
      router.push('/storefronts');
      return;
    }

    await loadSettings();

    if (storefront.hosted) {
      if (!storefront.preview_deployment_name) {
        // setTimeout is used so the flash message doesn't get flushed before appearing.
        setTimeout(() => {
          notifyError(
            'A preview was not found for this storefront. Please contact our support team.',
          );
        }, 50);

        router.push('/storefronts');
        return;
      }

      fetchPublishStatus(storefront.id);
    }

    const section = this.setSectionFromQuery();
    const baseUrl = this.getBaseUrl();

    this.setState({
      storefront,
      loaded: true,
      containerUrl: section?.checkout ? undefined : baseUrl, // prevents flip/flop to checkout url
      usingMessageApi: (USING_MESSAGE_API = undefined),
      ...this.getFromState(this.props, storefront),
    });

    this.showLoading();

    this.editorConnected = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.publishStatus &&
      this.props.publishStatus !== nextProps.publishStatus
    ) {
      if (this.props.publishStatus.renderedLogs !== nextProps.renderedLogs) {
        this.setPublishProgress(nextProps.publishStatus);
      }
      // Just finished publishing
      if (
        this.props.publishStatus.publishing &&
        !nextProps.publishStatus.publishing
      ) {
        if (!nextProps.publishStatus.error) {
          this.setState({ justPublished: true });
        }
        // Show modal automatically
        this.showPublishingModal();
      }
      if (this.publishLogsRef.current) {
        const el = this.publishLogsRef.current;
        setTimeout(() => {
          el.scrollTop = el.scrollHeight;
        }, 500);
      }
    }
    if (
      this.props.editorSection !== nextProps.editorSection ||
      this.props.themeConfig !== nextProps.themeConfig
    ) {
      this.setStateFromSection(nextProps, this.props);
    }
  }

  componentDidUpdate() {
    this.editorFrame = document.getElementById('editor-viewport');
    if (this.editorFrame && !this.editorBound) {
      this.editorBound = true;
      this.editorFrame.addEventListener('load', this.onChangeEditorPage);
      handleEditorMessage('route.changed', this.handleRouteChange);
      handleEditorMessage('locale.changed', this.handleLocaleChange);
    }
  }

  componentWillUnmount() {
    if (this.editorBound) {
      this.editorFrame.removeEventListener('load', this.onChangeEditorPage);
      handleEditorMessage('route.changed', this.handleRouteChange, false);
    }
  }

  showLoading() {
    this.props.showLoading();

    // Auto hide loader after a few seconds
    this.loadingTimer = setTimeout(() => {
      if (this.props.loading) {
        this.hideLoading();
      }
    }, 5000);
  }

  hideLoading() {
    this.props.hideLoading();
    clearTimeout(this.loadingTimer);
  }

  setSectionFromQuery() {
    const { location, updateSection, editorSection } = this.props;

    const settings = location.query.section || location.query.setting;
    const pages = location.query.page;

    let section;

    if (settings === 'checkout') {
      section = { checkout: true };
    } else if (settings) {
      section = { settings };
    } else if (pages) {
      section = { pages };
    }

    if (section) {
      updateSection(section, 0);
      return section;
    } else if (editorSection?.checkout) {
      // Unset checkout section if not set in URL
      updateSection({ settings: null }, 0);
    }
  }

  getFromState(props, storefront) {
    const { location } = props;

    let fromUri;
    let fromLabel;

    // Special case
    if (location.query.section === 'checkout') {
      fromUri = '/settings/checkout';
      fromLabel = 'checkout settings';
    } else {
      fromUri = `/storefronts/${storefront.id}`;
      fromLabel = storefront.name;
    }

    return { fromUri, fromLabel };
  }

  getEditorUrl = (url = this.editorFrame.src) => {
    const baseUrl = this.getBaseUrl();
    return url.replace(baseUrl, '');
  };

  getEditorUrlFull = () => {
    const { containerUrl, editorUrl } = this.state;

    if (this.state.inCheckout && EDITOR_URL_FULL) {
      return EDITOR_URL_FULL;
    }

    if (editorUrl?.startsWith('http')) {
      EDITOR_URL_FULL = editorUrl;
    } else {
      EDITOR_URL_FULL = trimEnd(containerUrl, '/') + (editorUrl || '');
    }

    return EDITOR_URL_FULL;
  };

  onChangeEditorPage = () => {
    if (!this.state.editorLoaded) {
      this.setSectionFromQuery();
      const editorRoute = { location: this.getEditorUrl() };
      if (editorRoute?.location) {
        this.setState((state) => ({
          editorNavIndex: state.editorNavIndex + 1,
          editorNavStack: [...state.editorNavStack, editorRoute],
          editorRoute,
        }));
      }
    }

    // Detect when the frame navigates away from checkout in cases where the storefront is not a theme
    // This only happens after the editor is fully loaded
    if (this.state.inCheckout && !this.editorFrame.src.includes('/checkout/')) {
      this.setState({ inCheckout: false }, () => {
        this.setState({ editorUrl: this.getEditorUrl() });
      });
    } else {
      this.setState({ editorLoaded: true, editorUrl: this.getEditorUrl() });
      this.props.hideLoading();
    }
  };

  onClickNavBack = (event) => {
    event.preventDefault();
    const { editorNavStack, editorNavIndex } = this.state;

    let inc = 1;
    let lastLocation = (editorNavStack[editorNavIndex - inc] || {}).location;
    if (lastLocation) {
      inc++;
      lastLocation = (editorNavStack[editorNavIndex - inc] || {}).location;
    }

    if (lastLocation !== undefined && !lastLocation) {
      const nextRoute = editorNavStack[editorNavIndex - (inc + 1)];
      this.setFrameUrl(nextRoute.location);
      this.setState({
        editorNavIndex: editorNavIndex - inc,
        editorNavStack: [...editorNavStack.slice(editorNavIndex - (inc + 1))],
        editorRoute: nextRoute,
      });
    } else {
      this.setFrameUrl(lastLocation);
      this.setState({
        editorNavIndex: editorNavIndex - 1,
      });
    }

    this.navigatedBack = true;
    this.showLoading();
  };

  onClickNavForward = (event) => {
    event.preventDefault();
    const { editorNavStack, editorNavIndex } = this.state;

    const nextLocation = (editorNavStack[editorNavIndex] || {}).location;

    if (nextLocation !== undefined && !nextLocation) {
      const nextRoute = editorNavStack[editorNavIndex + 1];
      this.setFrameUrl(nextRoute.location);
      this.setState({
        editorNavIndex: editorNavIndex + 2,
        editorRoute: nextRoute,
      });
    } else {
      this.setFrameUrl(nextLocation);
      this.setState({
        editorNavIndex: editorNavIndex + 1,
      });
    }

    this.navigatedForward = true;
    this.showLoading();
  };

  onClickNavRefresh = (event) => {
    event.preventDefault();
    this.showLoading();
    this.editorFrame.src = this.getEditorUrlFull();
  };

  selectContent = (path) => {
    if (this.editorFrame) {
      sendEditorMessage('content.selected', { path });
    }
  };

  showContentPublishedWarning = (value) => {
    this.setState({ showContentPublishedWarning: value });
  };

  showLocaleSelector = (value) => {
    if (value !== this.state.showLocaleSelector) {
      this.setState({ showLocaleSelector: value });
    }
  };

  handleRouteChange = ({ details } = {}) => {
    if (!details) return;

    const { location, app } = details;

    if (!location) return;

    console.log('editor route', location);

    let { editorNavIndex, editorNavStack } = this.state;

    if (location && !this.state.editorLoaded) {
      this.setSectionFromQuery();
      this.setStateFromSection(this.props);
    }

    if (app === 'checkout') {
      this.setState({
        inCheckout: true,
        editorUrl: this.getEditorUrl(location),
      });
    } else {
      this.setState({
        inCheckout: false,
        editorUrl: this.getEditorUrl(location),
      });
    }

    this.setState({ editorLoaded: true });

    if (this.navigatedBack) {
      this.navigatedBack = false;
      this.hideLoading();
    } else if (this.navigatedForward) {
      this.navigatedForward = false;
      this.hideLoading();
    } else {
      const prevRoute = editorNavStack[editorNavStack.length - 1];

      if (!prevRoute || prevRoute.location !== location) {
        editorNavStack = editorNavStack.slice(0, editorNavIndex);
        this.setState({
          editorNavIndex: editorNavIndex + 1,
          editorNavStack: [...editorNavStack, details],
          editorRoute: details,
          usingMessageApi: (USING_MESSAGE_API = details.events !== false),
        });
      }
    }
  };

  handleLocaleChange = ({ details } = {}) => {
    if (!details) {
      return;
    }
    const { locale } = details;

    if (locale) {
      console.log('editor locale', locale);
      this.setState({ editorLocale: locale });
    }
  };

  onSelectViewport = (viewport) => this.setState({ viewport });

  getBaseUrl() {
    const { location, storefront, client } = this.props;

    if (this.state.inCheckout) {
      return storefrontCheckoutURL(client.id, true);
    }

    if (location.query.url) {
      return location.query.url;
    }

    if (storefront.external?.custom && storefront.external?.custom_url) {
      return storefront.external.custom_url;
    }

    return storefrontPublicDeploymentURL(storefront);
  }

  async setStateFromSection(props) {
    const { themeConfig, editorSection, getTestCheckout } = props;

    let relUrl = '';

    if (editorSection.settings && themeConfig.settings) {
      const setting = find(themeConfig.settings, {
        id: editorSection.settings,
      });
      if (setting && setting.url) {
        const pattern = new UrlPattern(setting.url);
        if (setting.id === 'checkout') {
          const { id } = await getTestCheckout();
          relUrl = pattern.stringify({ id: id || 'error' });
        } else {
          relUrl = pattern.stringify({ id: 'editor' });
        }
      }
    } else if (editorSection.checkout && themeConfig.checkout) {
      if (themeConfig.checkout.url) {
        const pattern = new UrlPattern(themeConfig.checkout.url);
        let { id } = await getTestCheckout();

        id = id || 'error';

        // Make sure that the environment ID is passed for swell checkout
        if (!this.props.checkoutSettings.custom_checkout) {
          const { testEnv } = this.context;
          id = testEnv ? `${testEnv}/${id}` : id;
        }

        relUrl = pattern.stringify({ id });
      }
    }

    if (relUrl) {
      // Reset checkout flag
      if (editorSection.checkout && !this.state.inCheckout) {
        // Await in order for getBaseUrl to get the flag
        await new Promise((resolve) =>
          this.setState({ inCheckout: true }, resolve),
        );
      }

      const baseUrl = this.getBaseUrl();
      EDITOR_URL_FULL = `${baseUrl}/${trimStart(relUrl, '/')}`;
      this.setFrameUrl(EDITOR_URL_FULL);
    }
  }

  setFrameUrl = (url = '/') => {
    const iframe = document.getElementById('editor-viewport');
    if (iframe) {
      const full = url.startsWith('http');
      const frameUrl = full
        ? url
        : `${this.getBaseUrl()}/${trimStart(url, '/')}`;

      if (iframe.src !== frameUrl) {
        iframe.src = frameUrl;
      }
    }
  };

  onClickResetTestCheckout = () => {
    this.props.getTestCheckout(true).then(() => {
      window.editorRefreshPage(() => this.setStateFromSection(this.props));
    });
  };

  onClickReloadNotice = (event) => {
    event.preventDefault();
    window.location.reload();
  };

  onClickShowPublishing = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showPublishingModal: !state.showPublishingModal,
    }));
  };

  onClickAppLogs = (event) => {
    event.preventDefault();

    const { storefront } = this.props;
    const { appLogLines } = this.state;

    if (this.state.showAppLogs) {
      this.onClickCloseAppLogs(event);
      return;
    }

    const appLogSource = this.state.appLogSource || 'production';

    this.setState({
      showAppLogs: true,
      loadingAppLogs: true,
      appLogs: null,
      appLogSource,
      showPublishingModal: false,
    });

    api
      .get(`/storefronts/${storefront.id}/logs`, {
        env: appLogSource,
        $logs: appLogLines,
      })
      .then((raw) => {
        this.setState(
          { loadingAppLogs: false, appLogs: renderLogs(raw, true) },
          () => {
            if (this.appLogsRef.current) {
              const el = this.appLogsRef.current;
              el.scrollTop = el.scrollHeight;
            }
          },
        );
      })
      .catch(() => {
        this.setState({ loadingAppLogs: false });
      });
  };

  onClickAppLogLines = (event) => {
    event.preventDefault();
    const { lines = this.state.appLogLines } = event.currentTarget.dataset;
    this.setState({ appLogLines: +lines });
    this.refreshAppLogs(lines);
  };

  refreshAppLogs = (lines = this.state.appLogLines) => {
    const { storefront } = this.props;

    this.setState({ loadingAppLogs: true });
    const appLogSource = this.state.appLogSource || 'production';

    api
      .get(`/storefronts/${storefront.id}/logs`, {
        env: appLogSource,
        $logs: lines,
      })
      .then(({ $logs: raw }) => {
        this.setState(
          { loadingAppLogs: false, appLogs: renderLogs(raw, true) },
          () => {
            if (this.appLogsRef.current) {
              const el = this.appLogsRef.current;
              el.scrollTop = el.scrollHeight;
            }
          },
        );
      })
      .catch(() => {
        this.setState({ loadingAppLogs: false });
      });
  };

  onClickCloseAppLogs = (event) => {
    event.preventDefault();
    this.setState({ showAppLogs: false });
  };

  onClickPreview = (event) => {
    event.preventDefault();

    const wnd = window.open(this.getEditorUrlFull(), '_blank');

    if (wnd !== null) {
      wnd.focus();
    }
  };

  onClickLive = (event) => {
    event.preventDefault();
    const { storefront } = this.props;

    let storefrontURL = EDITOR_URL_FULL;
    if (this.state.inCheckout) {
      storefrontURL = EDITOR_URL_FULL.replace('--dev.', '.');
    } else {
      storefrontURL = getStorefrontURL(storefront);
    }

    const wnd = window.open(storefrontURL, '_blank');

    if (wnd !== null) {
      wnd.focus();
    }
  };

  showPublishingModal() {
    this.firstShowingLogs = true;
    this.setState({ showPublishingModal: true, showAppLogs: false });
  }

  onClickPublishTop = (event) => {
    event.preventDefault();
    this.showPublishingModal();
  };

  onClickPublish = (event) => {
    event.preventDefault();

    const {
      account,
      storefront,
      publishStatus,
      publishCheckout,
      publishStorefront,
      fetchStorefront,
      updatePublishStatus,
      fetchPublishStatus,
    } = this.props;

    const { inCheckout, showPublishingModal } = this.state;

    if (
      inCheckout &&
      !publishStatus.checkoutCurrent &&
      !publishStatus.checkoutPublishing
    ) {
      publishCheckout();
    } else if (account.planSelected && !account.isSandbox) {
      if (showPublishingModal && !publishStatus.publishing) {
        publishStorefront(storefront.id).then(() => {
          fetchStorefront(storefront.id);

          if (storefront.hosted) {
            updatePublishStatus({
              publishing: true,
              canceled: false,
              error: null,
              logs: null,
              renderedLogs: {},
            });
            fetchPublishStatus(storefront.id);
          }
        });
      }
    }

    if (!showPublishingModal) {
      this.showPublishingModal();
    }
  };

  onClickCancelPublish = (event) => {
    event.preventDefault();

    const { storefront, publishStorefront } = this.props;

    publishStorefront(storefront.id, false);
  };

  onClickClosePublishing = (event) => {
    event.preventDefault();
    this.setState({ showPublishingModal: false, justPublished: false });
  };

  onChangeNotifyPublishing = (event, value) => {
    api.put('/user', {
      notify_publish: !!value,
    });
  };

  onClickShowPublishLogs = (event) => {
    event.preventDefault();

    this.setState((state) => ({ showPublishLogs: !state.showPublishLogs }));
  };

  setPublishProgress(nextPublishStatus) {
    const {
      renderedLogs: { lastStage },
      publishing,
    } = nextPublishStatus;
    const {
      renderedLogs: { lastStage: prevLastStage = {} },
    } = this.props.publishStatus;
    if (!publishing || !lastStage) {
      this.setState({ publishProgress: 0 });
      return;
    }
    if (prevLastStage.start === lastStage.start) {
      return;
    }
    const publishProgress =
      lastStage.start > this.state.publishProgress
        ? lastStage.start
        : this.state.publishProgress;
    this.setState({ publishProgress });
    let interval = (lastStage.end - lastStage.start) / (lastStage.time / 1000);
    clearInterval(this.publishProgressInterval);
    this.publishProgressInterval = setInterval(() => {
      const publishProgress = this.state.publishProgress + interval;
      if (publishProgress > lastStage.end) {
        return;
      }
      this.setState({ publishProgress });
    }, 1000);
    setTimeout(
      () => clearInterval(this.publishProgressInterval),
      lastStage.time,
    );
  }

  renderAppLogsModal() {
    const { appLogs, appLogLines, appLogSource, loadingAppLogs } = this.state;

    const hasLogs = appLogs && appLogs.length > 0;

    const logs = hasLogs && (
      <div
        ref={this.appLogsRef}
        className={classNames('EditorLayout-publishing-container', 'logs', {
          visible: hasLogs,
          'logs-loading': loadingAppLogs,
        })}
      >
        {appLogs.map((log, i) => (
          <Fragment key={i}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(log, {
                  WHOLE_DOCUMENT: true,
                }),
              }}
            />
          </Fragment>
        ))}
      </div>
    );

    const logLines = hasLogs &&
      appLogLines >= 100 &&
      appLogs.length >= 100 && [100, 200, 500, 1000];
    const lastLineLength = logLines && logLines[logLines.length - 1];

    return (
      <FadeIn
        className={classNames('EditorLayout-publishing-window', {
          'with-logs': appLogs,
        })}
      >
        <div className="EditorLayout-publishing-wrapper">
          <div className="EditorLayout-publishing-container">
            <h3 className="EditorLayout-publishing-title">
              Application logs {loadingAppLogs && <Loading />}
            </h3>
            {!loadingAppLogs && !hasLogs && (
              <p>
                No logs found for storefront <b>{appLogSource}</b>. If your app
                is in the process of loading or publishing changes, try again in
                a minute.
              </p>
            )}
          </div>
          {logs}
        </div>
        <div className="EditorLayout-publishing-actions">
          <Button size="sm" onClick={this.onClickCloseAppLogs} type="default">
            Close
          </Button>
          <Button size="sm" onClick={this.onClickAppLogLines} type="secondary">
            Refresh
          </Button>
          {hasLogs && (
            <Fragment>
              <Field
                type="radio"
                className="left"
                onChange={(_event, value) => {
                  if (value && value !== appLogSource) {
                    this.setState({ appLogSource: value }, () =>
                      this.refreshAppLogs(),
                    );
                  }
                }}
                options={[
                  {
                    value: 'preview',
                    label: 'Preview',
                  },
                  {
                    value: 'production',
                    label: 'Production',
                  },
                ]}
              />
              {logLines && (
                <div className="note left">
                  Log lines:{' '}
                  {logLines.map((num) => (
                    <Fragment key={num}>
                      {num === appLogLines ? (
                        <b>{num}</b>
                      ) : (
                        <button
                          data-lines={num}
                          className="as-link"
                          onClick={this.onClickAppLogLines}
                          type="button"
                        >
                          {num}
                        </button>
                      )}
                      {num < lastLineLength && <span>&ensp;</span>}
                    </Fragment>
                  ))}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </FadeIn>
    );
  }

  renderPublishingModal() {
    const { storefront, account } = this.props;
    const { inCheckout } = this.state;

    if (inCheckout) {
      return this.renderCheckoutPublishingModal();
    }

    if (storefront.hosted) {
      if (account.planTrial && !account.planSelected) {
        return this.renderPublishingAccountRequiredModal();
      }
      if (account.isSandbox) {
        return this.renderPublishingSandboxUpgradeModal();
      }

      return this.renderHostedPublish();
    }

    // noop
    return <Fragment />;
  }

  renderHostedPublish() {
    const { publishStatus } = this.props;
    const { renderedLogs } = publishStatus;
    const { showPublishLogs, publishProgress, justPublished } = this.state;

    const hasLogs = renderedLogs.lines && renderedLogs.lines.length > 0;
    const showingLogs =
      hasLogs &&
      showPublishLogs &&
      (publishStatus.current ||
        publishStatus.publishing ||
        publishStatus.error);

    const logs = (
      <div
        ref={this.publishLogsRef}
        className={classNames('EditorLayout-publishing-container', 'logs', {
          visible: showingLogs,
        })}
      >
        {renderedLogs.lines &&
          renderedLogs.lines.map((log, i) => (
            <Fragment key={i}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(log, {
                    WHOLE_DOCUMENT: true,
                  }),
                }}
              />
            </Fragment>
          ))}
      </div>
    );

    return (
      <FadeIn
        className={classNames('EditorLayout-publishing-window', {
          'with-logs': showingLogs,
        })}
      >
        <div className="EditorLayout-publishing-wrapper">
          {publishStatus.publishing || publishStatus.error ? (
            <Fragment>
              {publishStatus.publishing ? (
                <div className="EditorLayout-publishing-container">
                  <h3 className="EditorLayout-publishing-title">
                    {publishStatus.canceled ? 'Canceling' : 'Publishing'}{' '}
                    <Loading />
                  </h3>
                  {publishStatus.canceled ? (
                    <p>
                      The deployment is being canceled. This can take a few
                      seconds.
                    </p>
                  ) : (
                    <Fragment>
                      <p>
                        To provide your customers with faster loading times,
                        some theme files need to be re-deployed. This can take a
                        few minutes.
                      </p>
                      <div className="EditorLayout-publishing-progress">
                        <div className="EditorLayout-publishing-progress-bar">
                          <div className="progress-bg" />
                          <div
                            className="progress-fg"
                            style={{ width: `${Math.round(publishProgress)}%` }}
                          />
                          <div className="progress-text">
                            {Math.round(publishProgress)}%
                          </div>
                        </div>
                        <Fragment>
                          <code className="EditorLayout-publishing-progress-line">
                            {renderedLogs.lastLine ||
                              'Initializing, please wait...'}
                          </code>
                          {hasLogs && (
                            <button
                              className="EditorLayout-publishing-progress-action"
                              onClick={this.onClickShowPublishLogs}
                              type="button"
                            >
                              {showPublishLogs ? 'Hide' : 'Show'} logs
                            </button>
                          )}
                        </Fragment>
                      </div>
                    </Fragment>
                  )}
                </div>
              ) : (
                publishStatus.error && (
                  <div className="EditorLayout-publishing-container">
                    <h3 className="EditorLayout-publishing-title negative">
                      Error
                    </h3>
                    <p>
                      An error occurred while publishing.{' '}
                      {publishStatus.date_published && (
                        <Fragment>
                          Changes were last successfully published{' '}
                          {formatDate(publishStatus.date_published, 'ago')}.{' '}
                        </Fragment>
                      )}
                      {hasLogs && (
                        <button
                          className="as-link"
                          onClick={this.onClickShowPublishLogs}
                          type="button"
                        >
                          {showPublishLogs ? 'Hide' : 'Show'} logs
                        </button>
                      )}
                    </p>
                  </div>
                )
              )}
              {logs}
            </Fragment>
          ) : (
            <Fragment>
              <div className="EditorLayout-publishing-container">
                {publishStatus.date_published ? (
                  <Fragment>
                    {justPublished ? (
                      <h3 className="EditorLayout-publishing-title">
                        All changes are live
                      </h3>
                    ) : (
                      <h3 className="EditorLayout-publishing-title">Publish</h3>
                    )}
                    <p>
                      Your changes were last published{' '}
                      {formatDate(publishStatus.date_published, 'ago')}.{' '}
                      {hasLogs && (
                        <button
                          className="as-link"
                          onClick={this.onClickShowPublishLogs}
                          type="button"
                        >
                          {showPublishLogs ? 'Hide' : 'Show'} logs
                        </button>
                      )}
                    </p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <h3 className="EditorLayout-publishing-title">
                      Ready to go live?
                    </h3>
                    <p>
                      Click below to publish your storefront for the first time.
                    </p>
                  </Fragment>
                )}
              </div>
              {logs}
            </Fragment>
          )}
        </div>
        <div className="EditorLayout-publishing-actions">
          {publishStatus.publishing || publishStatus.error ? (
            <Fragment>
              <Button
                size="sm"
                onClick={this.onClickClosePublishing}
                type="default"
              >
                Okay
              </Button>
              {publishStatus.publishing ? (
                <Fragment>
                  {!publishStatus.canceled && (
                    <Button
                      size="sm"
                      onClick={this.onClickCancelPublish}
                      type="sub"
                    >
                      Cancel deployment
                    </Button>
                  )}
                </Fragment>
              ) : (
                publishStatus.error && (
                  <Button size="sm" onClick={this.onClickPublish} type="sub">
                    Try again
                  </Button>
                )
              )}
            </Fragment>
          ) : justPublished ? (
            <Fragment>
              <Button
                size="sm"
                onClick={this.onClickClosePublishing}
                type="default"
              >
                Okay
              </Button>
              <button
                className="as-link left"
                onClick={this.onClickLive}
                type="button"
              >
                View live storefront <Icon fa="external-link" faType="light" />
              </button>
              <Button size="sm" onClick={this.onClickPublish} type="sub">
                Publish again
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button size="sm" onClick={this.onClickPublish} type="default">
                Publish changes
              </Button>
              <button
                className="as-link left"
                onClick={this.onClickLive}
                type="button"
              >
                View live storefront <Icon fa="external-link" faType="light" />
              </button>
              <Button
                size="sm"
                onClick={this.onClickClosePublishing}
                type="sub"
              >
                Okay
              </Button>
            </Fragment>
          )}
        </div>
      </FadeIn>
    );
  }

  renderCheckoutPublishingModal() {
    const { publishStatus, checkoutSettings } = this.props;
    return (
      <FadeIn className="EditorLayout-publishing-window">
        <div className="EditorLayout-publishing-wrapper">
          {publishStatus.checkoutCurrent ? (
            <Fragment>
              <div className="EditorLayout-publishing-container">
                <h3 className="EditorLayout-publishing-title">
                  Checkout published
                </h3>
                {checkoutSettings.date_theme_published ? (
                  <p>
                    Your changes were published{' '}
                    {formatDate(checkoutSettings.date_theme_published, 'ago')}.{' '}
                  </p>
                ) : (
                  <p>You haven't made any changes yet.</p>
                )}
              </div>
            </Fragment>
          ) : publishStatus.checkoutPublishing ? (
            <div className="EditorLayout-publishing-container">
              <h3 className="EditorLayout-publishing-title">
                Publishing checkout <Loading />
              </h3>
              <p>This will just take a moment.</p>
            </div>
          ) : (
            <div className="EditorLayout-publishing-container">
              <h3 className="EditorLayout-publishing-title">
                Unpublished changes
              </h3>
              {checkoutSettings.date_theme_published ? (
                <p>
                  Changes have been made since your last publish{' '}
                  {formatDate(checkoutSettings.date_theme_published, 'ago')}.
                </p>
              ) : (
                <p>Click below to make your checkout changes live.</p>
              )}
            </div>
          )}
        </div>
        <div className="EditorLayout-publishing-actions">
          <Button
            size="sm"
            onClick={this.onClickClosePublishing}
            type={publishStatus.checkoutCurrent ? 'default' : 'secondary'}
          >
            Okay
          </Button>
          {!publishStatus.checkoutCurrent ? (
            <Button
              size="sm"
              onClick={this.onClickPublish}
              type={publishStatus.checkoutCurrent ? 'secondary' : 'default'}
            >
              Publish changes
            </Button>
          ) : (
            <button
              className="as-link left"
              onClick={this.onClickLive}
              type="button"
            >
              View live checkout <Icon fa="external-link" faType="light" />
            </button>
          )}
        </div>
      </FadeIn>
    );
  }

  renderPublishingAccountRequiredModal() {
    const { account } = this.props;
    return (
      <FadeIn className="EditorLayout-publishing-window">
        <div className="EditorLayout-publishing-wrapper">
          <div className="EditorLayout-publishing-container">
            <h3 className="EditorLayout-publishing-title">Upgrade required</h3>
            <p>
              Ready to go live? You have {account.planTrialDaysLeftMessage} in
              your free trial.{' '}
              <Link to="/settings/account/plans">Upgrade now</Link> and launch
              your storefront right away.
            </p>
          </div>
        </div>
        <div className="EditorLayout-publishing-actions">
          <ButtonLink to="/settings/account/plans" size="sm" type="default">
            View plans
          </ButtonLink>
          <Button size="sm" onClick={this.onClickClosePublishing} type="sub">
            Not now
          </Button>
        </div>
      </FadeIn>
    );
  }

  renderPublishingSandboxUpgradeModal() {
    return (
      <FadeIn className="EditorLayout-publishing-window">
        <div className="EditorLayout-publishing-wrapper">
          <div className="EditorLayout-publishing-container">
            <h3 className="EditorLayout-publishing-title">Upgrade required</h3>
            <p>
              Your account is currently on the Sandbox plan.{' '}
              <Link to="/settings/account/plans">Upgrade to a paid plan</Link>{' '}
              in order to publish a live storefront.
            </p>
          </div>
        </div>
        <div className="EditorLayout-publishing-actions">
          <ButtonLink to="/settings/account/plans" size="sm" type="default">
            View plans
          </ButtonLink>
          <Button size="sm" onClick={this.onClickClosePublishing} type="sub">
            Nevermind
          </Button>
        </div>
      </FadeIn>
    );
  }

  renderEditorUrlDisplay() {
    return this.getEditorUrlFull()
      .replace(/^http(s)?:\/\//, '')
      .replace(/\/$/, '')
      .replace('--dev', '');
  }

  render() {
    const {
      storefront,
      hasPreview,
      publishStatus,
      testCheckout,
      checkoutSettings,
    } = this.props;

    const {
      loaded,
      containerUrl,
      viewport,
      showAppLogs,
      showPublishingModal,
      showContentPublishedWarning,
      showLocaleSelector,
      editorUrl,
      editorRoute,
      editorLocale,
      editorLoaded,
      editorNavIndex,
      editorNavStack,
      usingMessageApi,
      inCheckout,
      fromUri,
      fromLabel,
    } = this.state;

    if (!loaded) {
      return <ViewLoading />;
    }

    return (
      <div className="EditorLayout">
        <div className="EditorLayout-side">
          <div className="EditorLayout-side-header">
            <Link
              to={fromUri || '/storefronts'}
              className="EditorLayout-side-header-back"
            >
              Back to {fromLabel || 'dashboard'}
            </Link>
            <div
              style={{ visibility: showLocaleSelector ? 'visible' : 'hidden' }}
            >
              <LocaleSelector />
            </div>
          </div>
          <div className="EditorLayout-side-menu">
            <SettingsMenu
              key="settings"
              {...this.props}
              editorUrl={editorUrl}
              editorRoute={editorRoute}
              editorLoaded={editorLoaded}
              editorLocale={editorLocale}
              usingMessageApi={usingMessageApi}
              containerUrl={containerUrl}
              setFrameUrl={this.setFrameUrl}
              selectContent={this.selectContent}
              showContentPublishedWarning={this.showContentPublishedWarning}
              showLocaleSelector={this.showLocaleSelector}
            />
          </div>
        </div>
        <div className="EditorLayout-content">
          <div className="EditorLayout-header">
            {hasPreview && storefront.hosted ? (
              <div className="EditorLayout-header-status">
                {editorLoaded &&
                  (publishStatus.current === undefined ? (
                    <FadeIn>
                      <span className="muted">
                        <Loading className="EditorLayout-header-status-loading" />
                        Checking publish status...
                      </span>
                    </FadeIn>
                  ) : inCheckout ? (
                    publishStatus.checkoutCurrent === false ? (
                      <FadeIn>
                        {!publishStatus.publishing ? (
                          <Fragment>
                            <span className="EditorLayout-header-status-indicator current" />
                            {checkoutSettings.date_theme_published ? (
                              <span className="muted">
                                Last published{' '}
                                {formatDate(
                                  checkoutSettings.date_theme_published,
                                  'ago',
                                )}
                              </span>
                            ) : (
                              <span className="muted">Not yet published</span>
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            <span className="EditorLayout-header-status-indicator publishing" />
                            <span className="muted">Publishing checkout</span>
                          </Fragment>
                        )}
                      </FadeIn>
                    ) : (
                      <FadeIn>
                        <span className="EditorLayout-header-status-indicator current" />
                        <span className="muted">Live</span>
                      </FadeIn>
                    )
                  ) : (
                    <FadeIn>
                      {!publishStatus.publishing ? (
                        <Fragment>
                          <span className="EditorLayout-header-status-indicator current" />
                          {publishStatus.date_published ? (
                            <span className="muted">
                              Last published{' '}
                              {formatDate(publishStatus.date_published, 'ago')}
                            </span>
                          ) : (
                            <span className="muted">Not yet published</span>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <span className="EditorLayout-header-status-indicator publishing" />
                          <span className="muted">Publishing</span>
                        </Fragment>
                      )}
                    </FadeIn>
                  ))}
              </div>
            ) : (
              <div className="EditorLayout-header-status">
                {inCheckout &&
                  (publishStatus.checkoutCurrent === false ? (
                    <FadeIn>
                      {!publishStatus.publishing ? (
                        <Fragment>
                          <span className="EditorLayout-header-status-indicator current" />
                          {publishStatus.date_checkout_published ? (
                            <span className="muted">
                              Last published{' '}
                              {formatDate(
                                publishStatus.date_checkout_published,
                                'ago',
                              )}
                            </span>
                          ) : (
                            <span className="muted">Not yet published</span>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <span className="EditorLayout-header-status-indicator publishing" />
                          <span className="muted">Publishing checkout</span>
                        </Fragment>
                      )}
                    </FadeIn>
                  ) : (
                    <FadeIn>
                      <span className="EditorLayout-header-status-indicator current" />
                      <span className="muted">Live</span>
                    </FadeIn>
                  ))}
              </div>
            )}

            <DeviceToolbar onSelect={this.onSelectViewport} />

            <div className="EditorLayout-header-actions">
              {storefront.hosted && !inCheckout && (
                <Button
                  size="sm"
                  onClick={this.onClickAppLogs}
                  type="secondary"
                  className="icon-only"
                >
                  <Icon fa="scroll" faType="regular" />
                </Button>
              )}

              <Button size="sm" onClick={this.onClickPreview} type="secondary">
                Preview
              </Button>

              {hasPreview || inCheckout ? (
                <Button
                  size="sm"
                  onClick={this.onClickPublishTop}
                  type="default"
                  className="EditorLayout-header-publish"
                >
                  {publishStatus.publishing ? (
                    <span>
                      <Loading />
                      &nbsp;
                    </span>
                  ) : inCheckout ? (
                    'Publish checkout'
                  ) : (
                    'Publish'
                  )}
                </Button>
              ) : (
                <Fragment />
              )}
            </div>
          </div>
          <div className="EditorLayout-view">
            <div id="modal-viewport" />
            <div id="tooltip-viewport" />
            <Scrollbars className="EditorLayout-view-container">
              <div className="EditorLayout-view-body">
                <div
                  className={classNames('EditorLayout-view-frame', viewport)}
                >
                  <div className="EditorLayout-view-chrome">
                    <div className="EditorLayout-view-chrome-bar">
                      <div className="chrome-addr">
                        <div className="chrome-addr-bg">
                          <LoadingBar />
                          {this.renderEditorUrlDisplay()}
                          <button
                            className="chrome-addr-refresh"
                            onClick={this.onClickNavRefresh}
                            type="button"
                          >
                            <Icon fa="redo" faType="solid" />
                          </button>
                        </div>
                      </div>
                      <div className="chrome-btn" />
                      <div className="chrome-btn" />
                      <div className="chrome-btn" />
                      {!inCheckout && (
                        <Fragment>
                          <button
                            className={classNames('chrome-nav', {
                              active: editorNavIndex > 1,
                            })}
                            onClick={this.onClickNavBack}
                            disabled={editorNavIndex <= 1}
                            type="button"
                          >
                            <Icon fa="arrow-left" faType="solid" />
                          </button>

                          <button
                            className={classNames('chrome-nav', {
                              active: editorNavIndex < editorNavStack.length,
                            })}
                            onClick={this.onClickNavForward}
                            disabled={editorNavIndex >= editorNavStack.length}
                            type="button"
                          >
                            <Icon fa="arrow-right" faType="solid" />
                          </button>
                        </Fragment>
                      )}
                    </div>
                    {!editorLoaded && (
                      <div className="EditorLayout-view-chrome-loading">
                        <Loading />
                      </div>
                    )}
                    <iframe
                      id="editor-viewport"
                      src={containerUrl}
                      title="Editor"
                    />
                    {testCheckout && testCheckout.order_id && (
                      <div className="EditorLayout-view-notice">
                        Your test checkout is complete. Reset it to start over.
                        <Button
                          size="sm"
                          onClick={this.onClickResetTestCheckout}
                          type="default"
                        >
                          Reset checkout
                        </Button>
                      </div>
                    )}
                    {showContentPublishedWarning && (
                      <div className="EditorLayout-content-published-warning">
                        This page is not published
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
        {showPublishingModal && this.renderPublishingModal()}
        {showAppLogs && this.renderAppLogsModal()}
      </div>
    );
  }
}

export default EditorLayout;
