import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { arrayToObject, isEmpty } from 'utils';
import { replaceLocationQuery } from 'actions/data';
import actions from 'actions';

import BulkExport from 'components/bulk/export';
import BulkImport from 'components/bulk/import';
import ViewLoading from 'components/view/loading';

import Collection from './Collection';
import NotFoundPage from 'components/pages/error/404';

let EXPORT_CSV_FIELDS = [];

const mapStateToProps = (state) => ({
  data: state.data,
  bulk: state.data.bulk,
  content: state.content,
  currentView: state.content.currentView,
});

function collectionParam(collection) {
  return collection.replace(/_/g, '/');
}

const mapDispatchToProps = (dispatch) => ({
  fetchListView: (collection) => {
    return dispatch(
      actions.content.fetchView(collectionParam(collection), 'list'),
    );
  },

  bulkExport: (collection, params) => {
    return dispatch(
      actions.data.bulkExport(collectionParam(collection), params),
    );
  },

  bulkImport: (collection, params) => {
    return dispatch(
      actions.data.bulkImport(collectionParam(collection), params),
    );
  },

  bulkCancel: () => {
    return dispatch(actions.data.bulkCancel());
  },

  addImportedCategory: (category) => {
    return dispatch(actions.categories.add(category));
  },
});

export class CollectionList extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    testEnv: PropTypes.string,
  };

  static async onEnter(store, nextState, replace) {
    if (replaceLocationQuery(store, nextState, replace)) {
      return;
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      model: null,
      view: null,
      collectionProps: null,
      showExport: false,
      showImport: false,
      onClickExport: this.onClickExport,
      onClickExportCancel: this.onClickExportCancel,
      onClickExportReset: this.onClickExportReset,
      onSubmitExport: this.onSubmitExport,
      onClickImport: this.onClickImport,
      onSampleImportCount: this.onSampleImportCount,
      onSampleImportIndex: this.onSampleImportIndex,
      onSampleImportData: this.onSampleImportData,
    };

    this.IMPORT_VARIANT_OPTION_VALUES = new Set();
  }

  componentDidMount() {
    const { params, fetchListView } = this.props;

    Promise.all([fetchListView(params.collection)]).then(
      ([{ currentCollection, currentView }]) => {
        this.setCollectionProps(currentCollection, currentView);
        this.setState({ loaded: true });
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    const { fetchListView } = this.props;
    const {
      params: { collection },
    } = nextProps;

    if (
      this.state.loaded &&
      !isEmpty(nextProps.content.collections) &&
      (collection !== this.props.params.collection ||
        !nextProps.currentView ||
        nextProps.currentView?.gid !== this.props.currentView?.gid)
    ) {
      this.setState({ loaded: false });
      fetchListView(collection).then(({ currentCollection, currentView }) => {
        this.setCollectionProps(currentCollection, currentView);
        this.setState({ loaded: true });
      });
    }
  }

  setCollectionProps(model, view) {
    const { params } = this.props;

    if (!model || !view) {
      this.setState({ collectionProps: null });
      return;
    }

    // First field link url for the record
    view.fields[0].url = `/collections/${params.collection}/{id}`;

    this.setState({
      model,
      view,
      collectionProps: {
        view,
        title: model.plural,
        uri: `/collections/${params.collection}`,
        model: model.collection,
        tabs: view.tabs,
        filters: view.filters,
        fields: arrayToObject(view.fields),
        headerActions: view.actions,
        query: view.query,
        queryCurrency: true,
      },
    });
  }

  onClickExport = (event) => {
    event.preventDefault();
    this.setState({ showExport: !this.state.showExport });
  };

  onClickExportCancel = (event) => {
    event.preventDefault();
    if (this.props.bulk.running) {
      this.props.bulkCancel();
    }
    this.setState({ showExport: false });
  };

  onClickExportReset = (event) => {
    event.preventDefault();
    this.props.bulkCancel();
  };

  onClickImport = (event) => {
    event.preventDefault();
    if (this.state.showImport) {
      this.props.bulkCancel();
    }
    this.setState({ showImport: !this.state.showImport });
  };

  onSubmitExport = (values) => {
    const { params } = this.props;
    this.props.bulkExport(params.collection, {
      ...values,
      filename: `products`,
      csvFields: EXPORT_CSV_FIELDS,
      onCountRowsExported: (data) => {
        // Count rows with a product name only
        return data.filter((row) => row[0]).length;
      },
      query: {
        expand: ['bundle_items.product', 'bundle_items.variant'],
        include: {
          variants: {
            url: '/products:variants',
            params: {
              parent_id: 'id',
            },
            data: {
              limit: 1000,
              archived: { $ne: true },
            },
          },
          categories: {
            url: '/categories:products',
            params: {
              product_id: 'id',
            },
            data: {
              limit: 1000,
              fields: 'parent_id, sort',
            },
          },
        },
      },
    });
  };

  render() {
    const { loaded, collectionProps, exportCSVFields } = this.state;

    if (!loaded) {
      return <ViewLoading />;
    }

    if (!collectionProps) {
      return <NotFoundPage />;
    }

    return (
      <>
        <Collection
          {...this.props}
          {...collectionProps}
          views={this.props.content.views}
        />
        {this.state.showExport && (
          <BulkExport
            label={collectionProps.plural}
            {...this.props}
            {...this.state}
            selectable={true}
          />
        )}
        {this.state.showImport && (
          <BulkImport
            label={collectionProps.plural}
            csvFields={exportCSVFields}
            {...this.props}
            {...this.state}
            selectable={true}
            isOverwriteDefault
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionList);
