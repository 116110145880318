export const MIGRATION_ACTIONS_BY_TYPE = {
  default: {
    icon: {
      fa: 'sign-out-alt',
    },
    key: 'migration-default',
    title: 'Migrate your store',
    subtitle: 'Considerations and examples to help migrate your store',
    buttons: [
      {
        key: 'button-docs',
        action: 'See migration docs',
        link: 'https://developers.swell.is/guides/migrate-to-swell',
      },
    ],
  },
  shopify: {
    icon: {
      type: 'migration/shopify',
      width: '20px',
      height: '19.5px',
    },
    key: 'migration-shopify',
    title: 'Migrate from Shopify',
    subtitle: 'Quickly migrate products and current customers',
    buttons: [
      {
        key: 'button-learn-more',
        action: 'Learn more',
        link: 'https://developers.swell.is/guides/migrate-from-shopify',
      },
    ],
  },
  none: null,
};

export const STOREFRONT_ACTIONS_BY_TYPE = {
  origin: {
    icon: {
      type: 'storefront-customize',
      width: '18px',
      height: '18px',
    },
    key: 'storefront-origin',
    title: 'Your storefront',
    subtitle: 'Start customizing your new store.',
    buttons: [
      {
        key: 'button-customize',
        action: 'Customize storefront',
        link: '/storefronts',
        isInternalLink: true,
      },
    ],
  },
  builder: {
    icon: {
      type: 'storefront/builder',
      width: '16px',
      height: '18.78px',
    },
    key: 'storefront-builder',
    title: 'Your storefront',
    subtitle: 'Learn how to set up Builder with Swell.',
    buttons: [
      {
        key: 'button-builder',
        action: 'Go to Builder',
        link: 'https://builder.io/signup',
      },
      {
        key: 'button-docs',
        action: 'See docs',
        link: 'https://developers.swell.is/storefronts/storefront-apps/builder-io',
      },
    ],
  },
  vercel: {
    icon: {
      type: 'storefront/next',
      width: '19px',
      height: '20px',
    },
    key: 'storefront-next',
    title: 'Your storefront',
    subtitle: 'Learn how to set up Next.js with Swell.',
    buttons: [
      {
        key: 'button-builder',
        action: 'Go to Vercel',
        link: 'https://vercel.com/signup',
      },
      {
        key: 'button-docs',
        action: 'See docs',
        link: 'https://developers.swell.is/storefronts/storefront-apps/next-js-commerce',
      },
    ],
  },
  custom: {
    icon: {
      fa: 'code-fork',
    },
    key: 'storefront-custom',
    title: 'Your custom storefront',
    subtitle: 'Set up the right environment for you.',
    buttons: [
      {
        key: 'button-docs',
        action: 'See docs',
        link: 'https://developers.swell.is/guides/core-concepts/intro-to-storefronts#custom-storefronts',
      },
    ],
  },
};

export const FRAMEWORK_ACTIONS_BY_TYPE = {
  default: {
    icon: {
      fa: 'code',
    },
    key: 'framework-default',
    title: 'Your framework',
    subtitle: 'Learn the best way to work with your preferred framework.',
    buttons: [
      {
        key: 'button-docs',
        action: 'See framework docs',
        link: 'https://developers.swell.is/storefronts/frameworks',
      },
    ],
  },
  react: {
    icon: {
      svgType: 'frameworks/react',
      width: '20px',
      height: '18px',
    },
    key: 'framework-react',
    title: 'Developing with React',
    subtitle: 'Dive into storefront options that use React',
    buttons: [
      {
        key: 'button-learn-more',
        action: 'Learn more',
        link: 'https://developers.swell.is/storefronts/frameworks#react',
      },
    ],
  },
  vue: {
    icon: {
      svgType: 'frameworks/vue',
      width: '20.5px',
      height: '17px',
    },
    key: 'framework-vue',
    title: 'Developing with Vue',
    subtitle: 'Dive into storefront options that use Vue',
    buttons: [
      {
        key: 'button-learn-more',
        action: 'Learn more',
        link: 'https://developers.swell.is/storefronts/frameworks#vue',
      },
    ],
  },
  gatsby: {
    icon: {
      svgType: 'frameworks/gatsby',
    },
    key: 'framework-gatsby',
    title: 'Developing with Gatsby',
    subtitle: 'Dive into storefront options that use Gatbsy',
    buttons: [
      {
        key: 'button-learn-more',
        action: 'Learn more',
        link: 'https://developers.swell.is/storefronts/frameworks#gatsby',
      },
    ],
  },
};

export const HOSTING_ACTIONS_BY_TYPE = {
  default: {
    icon: {
      fa: 'server',
    },
    key: 'hosting-default',
    title: 'Your hosting',
    subtitle: 'Explore hosting options for your storefront.',
    buttons: [
      {
        key: 'button-see-hosting-docs',
        action: 'See hosting docs',
        link: 'https://developers.swell.is/storefronts/Storefront-hosting/hosting-swell-storefronts',
      },
    ],
  },
  netlify: {
    icon: {
      svgType: 'hosting/netlify',
      width: '20px',
      height: '20px',
    },
    key: 'hosting-netlify',
    title: 'Hosting on Netlify',
    subtitle: 'Step by step guide to hosting on Netlify.',
    buttons: [
      {
        key: 'button-learn-more',
        action: 'Learn more',
        link: 'https://developers.swell.is/storefronts/Storefront-hosting/swell-themes-on-netlify',
      },
    ],
  },
  vercel: {
    icon: {
      svgType: 'hosting/vercel',
    },
    key: 'hosting-vercel',
    title: 'Hosting on Vercel',
    subtitle: 'Step by step guide to hosting on Vercel.',
    buttons: [
      {
        key: 'button-learn-more',
        action: 'Learn more',
        link: 'https://developers.swell.is/storefronts/Storefront-hosting/storefronts-on-vercel',
      },
    ],
  },
  cloudflare: {
    icon: {
      svgType: 'hosting/cloudflare',
      width: '19px',
    },
    key: 'hosting-cloudflare',
    title: 'Hosting on Cloudflare',
    subtitle: 'Step by step guide to hosting on Cloudflare.',
    buttons: [
      {
        key: 'button-learn-more',
        action: 'Learn more',
        link: 'https://developers.swell.is/storefronts/Storefront-hosting/storefronts-on-cloudflare-pages',
      },
    ],
  },
  heroku: {
    icon: {
      svgType: 'hosting/heroku',
      width: '15px',
      height: '19px',
    },
    key: 'hosting-heroku',
    title: 'Hosting on Heroku',
    subtitle: 'Step by step guide to hosting on Heroku.',
    buttons: [
      {
        key: 'button-learn-more',
        action: 'Learn more',
        link: 'https://developers.swell.is/storefronts/Storefront-hosting/storefronts-on-heroku',
      },
    ],
  },
};
