import React from 'react';
import PropTypes from 'prop-types';
import Field from 'components/form/field';
import { currencySymbol } from 'utils';

export default class CurrencyFieldSelect extends React.Component {
  static contextTypes = {
    client: PropTypes.object,
  };

  static propTypes = {
    record: PropTypes.object,
  };

  getSelectCurrencies() {
    const { record } = this.props;
    const {
      client: { pricedCurrencies },
    } = this.context;
    const selectCurrencies = [...pricedCurrencies.map((curr) => curr.code)];
    if (record.currency && selectCurrencies.indexOf(record.currency) === -1) {
      selectCurrencies.unshift(record.currency);
    }
    const valueCodes = Object.keys(record.$currency || {});
    for (const code of valueCodes) {
      if (selectCurrencies.indexOf(code) === -1) {
        selectCurrencies.push(code);
      }
    }
    return selectCurrencies.map((value) => ({
      value,
      label: `${value} ${currencySymbol(value)}`,
    }));
  }
  render() {
    const { record, ...props } = this.props;
    const { client } = this.context;
    return (
      <Field
        type="select"
        name="currency"
        label="Currency"
        {...props}
        placeholder={`${client.currency} ${currencySymbol(client.currency)}`}
        options={this.getSelectCurrencies()}
      />
    );
  }
}
