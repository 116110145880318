import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import pt from 'prop-types';

import { chargeGiftcard } from 'utils/payment';
import {
  loadStripe,
  createIDealPaymentMethod,
  createIDealPaymentIntent,
  isSingleUsePaymentMethodError,
  createKlarnaSource,
} from 'utils/stripe';

import { getAccountAddressesQuery, getAccountCardsQuery } from 'utils/account';

import { tokenizeCard } from 'services/api';

import actions from 'actions';

import ViewPage from 'components/pages/invoice/view';
import ViewLoading from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';

export const mapStateToProps = (state) => ({
  record: state.data.record,
  related: state.data.related,
  prev: state.data.record && (state.data.record.prev || undefined),
  next: state.data.record && (state.data.record.next || undefined),
  loading: state.data.loading,
  errors: state.data.recordErrors,
  lookup: state.lookup,
  categories: state.categories,
  content: state.content,
  settings: state.settings,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchRecord(id) {
    return dispatch(
      actions.data.fetchRecord('invoices', id, {
        expand: [
          'account',
          'items.product',
          'items.variant',
          'items.bundle_items.product',
          'items.bundle_items.variant',
          'coupon',
          'source',
          'source.product',
          'giftcards.giftcard',
        ],
        include: {
          prev: {
            url: '/invoices/:first',
            params: {
              id: { $gt: 'id' },
            },
          },
          next: {
            url: '/invoices/:last',
            params: {
              id: { $lt: 'id' },
            },
          },
          payments: {
            url: '/payments',
            params: {
              invoice_id: 'id',
            },
            data: {
              limit: 100,
              expand: 'giftcard',
              include: {
                refunds: {
                  url: '/payments:refunds',
                  params: {
                    parent_id: 'id',
                  },
                },
              },
            },
          },
        },
      }),
    ).then(async (result) => {
      if (!result) return result;
      await dispatch(actions.data.fetchIncludedContent(['payments'], result));
      dispatch(
        actions.data.fetchRelated(id, {
          notifications: {
            url: '/notifications',
            data: {
              record_id: result.id,
              template: {
                $in: ['orders.invoice', 'subscriptions.invoice'],
              },
              error: null,
            },
          },
          // events: {
          //   url: '/events',
          //   data: {
          //     'data.id': result.id,
          //   },
          // },
          account_cards: getAccountCardsQuery(result.account_id),
          account_addresses: getAccountAddressesQuery(result.account_id, {
            $ne: false,
          }),
        }),
      );
      return result;
    });
  },

  updateRecord(id, data) {
    return dispatch(actions.data.updateRecord('invoices', id, data));
  },

  updateFetchRecord(id, data) {
    return dispatch(actions.data.updateFetchRecord('invoices', id, data));
  },

  deleteRecord(id) {
    return dispatch(actions.data.deleteRecord('invoices', id));
  },

  createPayment(id, data) {
    return dispatch(actions.data.createRecord(`invoices/${id}/payments`, data));
  },

  updatePayment(id, paymentId, data) {
    return dispatch(
      actions.data.updateRecord(`invoices/${id}/payments`, paymentId, data),
    );
  },

  refundPayment(id, data) {
    return dispatch(actions.data.createRecord(`invoices/${id}/refunds`, data));
  },

  updateRefund(id, refundId, data) {
    return dispatch(
      actions.data.updateRecord(`invoices/${id}/refunds`, refundId, data),
    );
  },

  resendEmail(id, template) {
    return dispatch(
      actions.data.updateRecord('invoices', id, { $notify: template }),
    );
  },

  loadSettings() {
    return Promise.all([
      dispatch(actions.settings.fetch('payments')),
      dispatch(actions.settings.fetch('accounts')),
    ]);
  },

  fetchAccountCards: (relatedId, accountId) => {
    return dispatch(
      actions.data.fetchRelated(relatedId, {
        account_cards: {
          url: '/accounts:cards',
          data: {
            parent_id: accountId,
            active: true,
          },
        },
        account_addresses: {
          url: '/accounts:addresses',
          data: {
            parent_id: accountId,
            active: true,
          },
        },
      }),
    );
  },
});

export class ViewInvoice extends React.PureComponent {
  static propTypes = {
    page: pt.element,
    params: pt.object.isRequired,
    router: pt.object,
    record: pt.object,
    related: pt.object,
    location: pt.object,
    settings: pt.object,
    prev: pt.object,
    next: pt.object,

    fetchRecord: pt.func,
    updateRecord: pt.func,
    updateFetchRecord: pt.func,
    deleteRecord: pt.func,
    createPayment: pt.func,
    updatePayment: pt.func,
    refundPayment: pt.func,
    updateRefund: pt.func,
    loadSettings: pt.func,
    resendEmail: pt.func,
    fetchAccountCards: pt.func,
  };

  static contextTypes = {
    client: pt.object.isRequired,
    notifySuccess: pt.func.isRequired,
    notifyError: pt.func.isRequired,
    notifyDeleted: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      showingPaymentEdit: false,
      onEditValues: this.onEditValues.bind(this),
      onPaymentEdit: this.onPaymentEdit.bind(this),
      onPaymentCharge: this.onPaymentCharge.bind(this),
      onPaymentRefund: this.onPaymentRefund.bind(this),
      onPaymentUpdate: this.onPaymentUpdate.bind(this),
      onRefundUpdate: this.onRefundUpdate.bind(this),
      onResendEmail: this.onResendEmail.bind(this),
      onDelete: this.onDelete.bind(this),
      onLoadBilling: this.onLoadBilling.bind(this),
      onStripeInit: this.onStripeInit.bind(this),
    };
  }

  componentDidMount() {
    const { params, fetchRecord, loadSettings } = this.props;

    Promise.all([fetchRecord(params.id), loadSettings()]).then(() => {
      this.setState({ loaded: true });
    });
  }

  componentDidUpdate(prevProps) {
    const { params, router, location, fetchRecord } = this.props;

    if (prevProps.params.id !== params.id) {
      this.setState({ loaded: false });

      fetchRecord(params.id).then(() => {
        this.setState({ loaded: true });
      });
    } else if (!prevProps.record && this.props.record) {
      const { record } = this.props;
      const method = get(record, 'billing.method');
      const query = location.query || {};

      switch (method) {
        case 'ideal':
          this.handleIDealRedirectAction(query, record);
          break;

        case 'klarna':
          this.handleKlarnaRedirectAction(query, record);
          break;

        default:
          break;
      }

      router.replace(location.pathname);
    }
  }

  async handleIDealRedirectAction(query, record) {
    if (!query.payment_intent) {
      return;
    }

    const { params, updateRecord, createPayment, fetchRecord } = this.props;

    if (query.redirect_status === 'succeeded') {
      this.setState({ loading: true });
      const amount = get(record, 'billing.intent.amount');
      const ideal = get(record, 'account.billing.ideal');
      const result = await updateRecord(params.id, {
        billing: {
          ideal: ideal,
          intent: { id: query.payment_intent },
        },
      });
      if (result.errors) {
        return this.context.notifyError(result.errors);
      }
      const payment = await createPayment(params.id, {
        captured: true,
        method: 'ideal',
        ideal: ideal,
        amount,
      });
      this.setState({ loading: undefined });
      await fetchRecord(params.id);
      return payment.errors && this.context.notifyError(payment.errors);
    }

    return this.context.notifyError(
      'We are unable to authenticate your payment method. Please choose a different payment method and try again.',
    );
  }

  async handleKlarnaRedirectAction(query, record) {
    if (!query.source) {
      return;
    }

    const { params, createPayment, fetchRecord } = this.props;

    if (query.redirect_status === 'succeeded') {
      this.setState({ loading: true });
      const klarna = get(record, 'billing.klarna');
      const payment = await createPayment(params.id, {
        captured: true,
        method: 'klarna',
        amount: klarna.amount,
        klarna,
      });
      this.setState({ loading: undefined });
      await fetchRecord(params.id);
      return payment.errors && this.context.notifyError(payment.errors);
    } else if (query.redirect_status === 'canceled') {
      return this.context.notifyError('Your payment was canceled.');
    }
    return this.context.notifyError(
      'We are unable to authenticate your payment method. Please choose a different payment method and try again.',
    );
  }

  onEditValues(values, draftId) {
    const { params, updateFetchRecord, fetchRecord } = this.props;

    const recordId = draftId || params.id;

    return updateFetchRecord(recordId, values).then((result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        return fetchRecord(recordId);
      }
    });
  }

  async onPaymentEdit(values) {
    const { params, record, updateRecord, fetchRecord } = this.props;

    const billing = values.billing || {};
    const card = billing.card || {};

    this.setState({ loading: true });

    if (card.number) {
      const cardResult = await tokenizeCard(this.context.client.public_key, {
        card,
        billing: { ...values.billing, card: undefined },
        account_id: record.account_id,
      });
      if (cardResult.errors) {
        const errorMessage = get(
          cardResult,
          'errors.gateway.message',
          JSON.stringify(cardResult.errors),
        );
        this.context.notifyError(`Error: ${errorMessage}`);
        this.setState({ loading: undefined });
        return false;
      }
      values.billing.card = {
        token: cardResult.token,
      };
    } else if (billing.method === 'ideal') {
      const { stripe, stripeElement } = this.state;
      const { error, paymentMethod } = await createIDealPaymentMethod(
        stripe,
        stripeElement,
        billing,
      );
      if (error) {
        this.context.notifyError(`Error: ${error.message}`);
        this.setState({ loading: undefined });
        return false;
      }
      if (!paymentMethod.ideal.bank || !paymentMethod.ideal.bic) {
        this.setState({ loading: undefined });
        return false;
      }
      values.billing.ideal = { token: paymentMethod.id };
    }

    if (
      billing.method &&
      billing.method !== 'card' &&
      get(record, 'billing.card')
    ) {
      billing.card = null;
      billing.account_card_id = null;
    } else if (billing.account_card_id) {
      const accountCardId = billing.account_card_id;

      if (
        accountCardId &&
        accountCardId !== get(record, 'billing.account_card_id')
      ) {
        billing.account_card_id = accountCardId;
      }
    }

    const result = await updateRecord(params.id, values);
    if (result.errors) {
      this.context.notifyError(result.errors);
      this.setState({ loading: undefined });
      return false;
    }

    await fetchRecord(params.id);
    this.setState({ loading: undefined });
    return true;
  }

  async getStripe() {
    const { settings } = this.props;
    const { stripe } = this.state;
    return stripe || (await loadStripe(settings));
  }

  async createPayment(id, values) {
    const { createPayment } = this.props;

    if (await this.handleAltPaymentRedirect(id, values)) {
      return;
    }

    return await createPayment(id, values);
  }

  async handleAltPaymentRedirect(id, values) {
    // Note: this method doesn't get called because ideal/klarna are alt methods
    // that require customer authentication - leaving the code here in case we
    // want to enable dashboard testing of alt payment methods
    const { record, updateRecord } = this.props;
    const { notifyError } = this.context;

    if (values.method === 'ideal') {
      const intent = await createIDealPaymentIntent(
        this.context.client.public_key,
        record,
        values.amount,
      );
      if (intent.errors) {
        if (isSingleUsePaymentMethodError(intent.errors)) {
          notifyError('iDeal bank is not selected');
          this.setState({ showPaymentEdit: true });
          return true;
        }
        notifyError(intent.errors);
        return true;
      }
      const stripe = await this.getStripe();
      if (!stripe) {
        notifyError('Stripe was not loaded');
        return true;
      }
      const result = await updateRecord(id, {
        billing: { intent: { id: intent.id, amount: values.amount } },
      });
      if (result.errors) {
        notifyError(result.errors);
        return true;
      }

      await stripe.handleCardAction(intent.client_secret);
      return true;
    } else if (values.method === 'klarna') {
      const stripe = await this.getStripe();
      if (!stripe) {
        notifyError('Stripe was not loaded');
        return true;
      }
      const chargeRecord = {
        ...record,
        shipping: {
          ...(record.shipping || {}),
          price: 0,
        },
        items: [
          {
            price_total: values.amount,
            quantity: 1,
            product: { name: 'Klarna charge' },
          },
        ],
        grand_total: values.amount,
        tax_total: 0,
      };
      const { error, source } = await createKlarnaSource(
        stripe,
        chargeRecord,
        this.context,
      );
      if (error) {
        notifyError(error.message);
        return true;
      }
      const result = await updateRecord(id, {
        billing: { klarna: { source: source.id, amount: values.amount } },
      });
      if (result.errors) {
        notifyError(result.errors);
        return true;
      }

      window.location.replace(source.redirect.url);
      return true;
    }
  }

  async onPaymentCharge(values) {
    const { params, createPayment, updatePayment, fetchRecord, record } =
      this.props;
    const { notifyError } = this.context;
    const { authorized_payment } = record;

    this.setState({ loading: true });

    const shouldCaptureAuthorized =
      authorized_payment &&
      authorized_payment.method === values.method &&
      !authorized_payment.captured &&
      !authorized_payment.error;

    let result;
    if (shouldCaptureAuthorized) {
      result = await updatePayment(params.id, authorized_payment.id, {
        captured: true,
        amount: values.amount,
      });
    } else if (values.method === 'giftcard') {
      try {
        await chargeGiftcard(record, values.amount, createPayment);
      } catch (error) {
        result = { error };
      }
    } else {
      result = await this.createPayment(params.id, values);
    }

    if (result) {
      if (result.errors) {
        if (result.errors.amount) {
          notifyError(result.errors.amount.message);
        } else {
          notifyError(result.errors);
        }
        this.setState({ loading: undefined });
        return false;
      }
      if (result.error) {
        notifyError(result.error.message);
        await fetchRecord(params.id);
        this.setState({ loading: undefined });
        return false;
      }
    }

    await fetchRecord(params.id);
    this.setState({ loading: undefined });
    return true;
  }

  onPaymentRefund(values) {
    const { params, refundPayment, fetchRecord } = this.props;

    this.setState({ loading: true });

    return refundPayment(params.id, values).then(async (result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
        this.setState({ loading: undefined });
        return false;
      }
      await fetchRecord(params.id);
      this.setState({ loading: undefined });
      return true;
    });
  }

  async onPaymentUpdate(paymentId, values) {
    const { params, updatePayment, fetchRecord } = this.props;
    const { notifyError } = this.context;

    this.setState({ loading: true });

    const result = await updatePayment(params.id, paymentId, {
      ...values,
    });

    if (result) {
      if (result.errors) {
        notifyError(result.errors);
        this.setState({ loading: undefined });
        return false;
      }
      if (result.error) {
        notifyError(result.error.message);
        await fetchRecord(params.id);
        this.setState({ loading: undefined });
        return false;
      }
    }

    await fetchRecord(params.id);
    this.setState({ loading: undefined });
    return true;
  }

  async onRefundUpdate(refundId, values) {
    const { params, updateRefund, fetchRecord } = this.props;
    const { notifyError } = this.context;

    this.setState({ loading: true });

    const result = await updateRefund(params.id, refundId, {
      ...values,
    });

    if (result) {
      if (result.errors) {
        notifyError(result.errors);
        this.setState({ loading: undefined });
        return false;
      }
      if (result.error) {
        notifyError(result.error.message);
        await fetchRecord(params.id);
        this.setState({ loading: undefined });
        return false;
      }
    }

    await fetchRecord(params.id);
    this.setState({ loading: undefined });
    return true;
  }

  async onResendEmail(recordId, template, title) {
    const { resendEmail, record } = this.props;
    const { notifySuccess, notifyError } = this.context;

    try {
      const result = await resendEmail(recordId, template);
      if (!result || result.errors) {
        throw new Error(result ? result.errors : 'Unable to send email');
      }
      notifySuccess(`${title} sent to ${record.account.email}`);
    } catch (err) {
      notifyError(err);
    }
  }

  onDelete() {
    const { params, router, deleteRecord } = this.props;

    return deleteRecord(params.id).then(() => {
      this.context.notifyDeleted('Invoice');
      router.replace('/invoices');
    });
  }

  async onLoadBilling() {
    const {
      record: { id, account_id },
      fetchAccountCards,
    } = this.props;

    await fetchAccountCards(id, account_id);
  }

  onStripeInit(stripe, stripeElement) {
    this.setState({ stripe, stripeElement });
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    if (!this.props.record) {
      return <NotFoundPage />;
    }

    return this.props.page ? (
      <this.props.page {...this.props} {...this.state} />
    ) : (
      <ViewPage {...this.props} {...this.state} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoice);
