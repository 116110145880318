import React from 'react';
import Icon from 'components/icon/icon';
import './mobile-alert.scss';

export default class MobileAlert extends React.PureComponent {
  render() {
    return (
      <div className="mobile-alert-root">
        <Icon svgType="monitor" width={72} height={72} />
        <div className="mobile-alert-title">
          Swell is optimized for a desktop experience.
        </div>
        <div className="mobile-alert-subtitle">
          Open Swell on a larger screen to start using your dashboard.
        </div>
      </div>
    );
  }
}
