const FONT_TYPE_FALLBACKS = {
  sans: '-apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif',
  serif:
    'Iowan Old Style, Apple Garamond, Baskerville, Times New Roman, Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  mono: 'Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace',
};

export const FONT_OPTIONS = [
  {
    label: 'Sans-serif',
    fallback: FONT_TYPE_FALLBACKS.sans,
    options: [
      {
        provider: 'system',
        name: 'Avant Garde',
        type: 'sans',
        weights: [300, 400, 600, 700],
      },
      {
        provider: 'system',
        name: 'Gill Sans',
        type: 'sans',
        weights: [300, 400, 600, 700],
      },
      {
        provider: 'system',
        name: 'Helvetica Neue',
        type: 'sans',
        weights: [300, 400, 600, 700],
      },
      {
        provider: 'system',
        name: 'Arial',
        type: 'sans',
        weights: [300, 400, 600, 700],
      },
      {
        provider: 'system',
        name: 'Candara',
        type: 'sans',
        weights: [300, 400, 600, 700],
      },
      {
        provider: 'system',
        name: 'Geneva',
        type: 'sans',
        weights: [300, 400, 600, 700],
      },
    ],
  },
  {
    label: 'Sans-serif, Google Fonts',
    fallback: FONT_TYPE_FALLBACKS.sans,
    options: [
      {
        provider: 'Google',
        name: 'Alegreya Sans',
        type: 'sans',
        weights: [300, 400, 500, 700, 800, 900],
      },
      {
        provider: 'Google',
        name: 'Archivo',
        type: 'sans',
        weights: [400, 500, 600, 700],
      },
      {
        provider: 'Google',
        name: 'Archivo Black',
        type: 'sans',
        weights: [400],
      },
      {
        provider: 'Google',
        name: 'B612',
        type: 'sans',
        weights: [400, 700],
      },
      {
        provider: 'Google',
        name: 'Cairo',
        type: 'sans',
        weights: [300, 400, 700],
      },
      {
        provider: 'Google',
        name: 'Fira Sans',
        type: 'sans',
        weights: [300, 400, 500, 600, 700, 800, 900],
      },
      {
        provider: 'Google',
        name: 'Fira Sans Extra Condensed',
        type: 'sans',
        weights: [300, 400, 500, 600, 700, 800, 900],
      },
      {
        provider: 'Google',
        name: 'Inter',
        type: 'sans',
        weights: [300, 400, 500, 600, 700, 800, 900],
      },
      {
        provider: 'Google',
        name: 'Lato',
        type: 'sans',
        weights: [300, 400, 600, 700, 900],
      },
      {
        provider: 'Google',
        name: 'Libre Franklin',
        type: 'sans',
        weights: [300, 400, 500, 600, 700, 800, 900],
      },
      {
        provider: 'Google',
        name: 'Montserrat',
        type: 'sans',
        weights: [300, 400, 500, 600, 700, 800, 900],
      },
      {
        provider: 'Google',
        name: 'Montserrat Alternates',
        type: 'sans',
        weights: [300, 400, 500, 600, 700, 800, 900],
      },
      {
        provider: 'Google',
        name: 'Mulish',
        type: 'sans',
        weights: [300, 400, 500, 600, 700, 800, 900],
      },
      {
        provider: 'Google',
        name: 'Poppins',
        type: 'sans',
        weights: [300, 400, 500, 600, 700, 800, 900],
      },
      {
        provider: 'Google',
        name: 'PT Sans',
        type: 'sans',
        weights: [400, 700],
      },
      {
        provider: 'Google',
        name: 'Rubik',
        type: 'sans',
        weights: [300, 400, 500, 700, 900],
      },
      {
        provider: 'Google',
        name: 'Roboto',
        type: 'sans',
        weights: [300, 400, 500, 700, 900],
      },
      {
        provider: 'Google',
        name: 'Source Sans Pro',
        type: 'sans',
        weights: [300, 400, 600, 700, 900],
      },
      {
        provider: 'Google',
        name: 'Titillium Web',
        type: 'sans',
        weights: [300, 400, 600, 700, 900],
      },
      {
        provider: 'Google',
        name: 'Ubuntu',
        type: 'sans',
        weights: [300, 400, 500, 700],
      },
      {
        provider: 'Google',
        name: 'Work Sans',
        type: 'sans',
        weights: [300, 400, 500, 600, 700, 800, 900],
      },
    ],
  },
  {
    label: 'Serif',
    fallback: FONT_TYPE_FALLBACKS.serif,
    options: [
      {
        provider: 'system',
        name: 'Big Caslon',
        type: 'serif',
        weights: [300, 400, 600, 700],
      },
      {
        provider: 'system',
        name: 'Calisto MT',
        type: 'serif',
        weights: [300, 400, 600, 700],
      },
      {
        provider: 'system',
        name: 'Baskerville',
        type: 'serif',
        weights: [300, 400, 600, 700],
      },
      {
        provider: 'system',
        name: 'Garamond',
        type: 'serif',
        weights: [300, 400, 600, 700],
      },
      {
        provider: 'system',
        name: 'Times New Roman',
        type: 'serif',
        weights: [300, 400, 600, 700],
      },
    ],
  },
  {
    label: 'Serif, Google Fonts',
    fallback: FONT_TYPE_FALLBACKS.serif,
    options: [
      {
        provider: 'Google',
        name: 'Alegreya',
        type: 'serif',
        weights: [400, 500, 700, 800, 900],
      },
      {
        provider: 'Google',
        name: 'Arvo',
        type: 'serif',
        weights: [400, 700],
      },
      {
        provider: 'Google',
        name: 'Cormorant',
        type: 'serif',
        weights: [300, 400, 500, 600, 700],
      },
      {
        provider: 'Google',
        name: 'Crimson Text',
        type: 'serif',
        weights: [400, 600, 700],
      },
      {
        provider: 'Google',
        name: 'DM Serif Display',
        type: 'serif',
        weights: [400],
      },
      {
        provider: 'Google',
        name: 'Frank Ruhl Libre',
        type: 'serif',
        weights: [300, 400, 500, 600, 700],
      },
      {
        provider: 'Google',
        name: 'Lora',
        type: 'serif',
        weights: [400, 500, 600, 700],
      },
      {
        provider: 'Google',
        name: 'Playfair Display',
        type: 'serif',
        weights: [400, 500, 600, 700, 800, 900],
      },
      {
        provider: 'Google',
        name: 'PT Serif',
        type: 'serif',
        weights: [400, 700],
      },
      {
        provider: 'Google',
        name: 'Source Serif Pro',
        type: 'sans',
        weights: [300, 400, 600, 700, 900],
      },
      {
        provider: 'Google',
        name: 'Spectral',
        type: 'serif',
        weights: [300, 400, 500, 600, 700, 800],
      },
    ],
  },
  {
    label: 'Monospace, Google Fonts',
    fallback:
      'Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace',
    options: [
      {
        provider: 'Google',
        name: 'B612 Mono',
        type: 'sans',
        weights: [400, 700],
      },
      {
        provider: 'Google',
        name: 'Fira Mono',
        type: 'mono',
        weights: [400, 500, 700],
      },
      {
        provider: 'Google',
        name: 'Space Mono',
        type: 'mono',
        weights: [400, 700],
      },
      {
        provider: 'Google',
        name: 'Ubuntu Mono',
        type: 'sans',
        weights: [400, 700],
      },
    ],
  },
];

export const FONT_WEIGHT_OPTIONS = [
  {
    value: 300,
    label: 'Light',
  },
  {
    value: 400,
    label: 'Normal',
  },
  {
    value: 500,
    label: 'Medium',
  },
  {
    value: 600,
    label: 'Semi Bold',
  },
  {
    value: 700,
    label: 'Bold',
  },
  {
    value: 800,
    label: 'Extra Bold',
  },
  {
    value: 900,
    label: 'Black',
  },
];

export const FONT_SIZE_OPTIONS = [
  '13px',
  '14px',
  '15px',
  '16px',
  '17px',
  '18px',
  '19px',
  '20px',
  '22px',
  '24px',
  '26px',
  '28px',
  '30px',
  '32px',
  '34px',
  '36px',
];

export const FONT_SIZE_BODY_OPTIONS = [
  '13px',
  '14px',
  '15px',
  '16px',
  '17px',
  '18px',
  '19px',
  '20px',
];

export const FONT_SIZE_HEADING_OPTIONS = [
  '20px',
  '22px',
  '24px',
  '26px',
  '28px',
  '30px',
  '32px',
  '34px',
  '36px',
];

export const FONT_SIZE_OPTIONS_NEW = [
  '14px',
  '15px',
  '16px',
  '17px',
  '18px',
  '19px',
  '20px',
];

export function findFontByName(name, weight = undefined) {
  for (let group of FONT_OPTIONS) {
    for (let option of group.options) {
      if (option.name === name) {
        return {
          ...option,
          fallback: group.fallback,
          weight: findNearestFontWeight(option, weight),
        };
      }
    }
  }
}

export function findNearestFontWeight(font, weight) {
  if (!font) return;
  // Find the correct option weight or default in the middle
  let fontWeight = weight;
  if (font.weights.indexOf(weight) === -1) {
    if (weight) {
      let diff = 1000;
      for (let opw of font.weights) {
        const opDiff = Math.abs(weight - opw);
        if (opDiff < diff) {
          fontWeight = opw;
          diff = opDiff;
        }
      }
    } else {
      const defaultIndex = Math.round((font.weights.length - 1) / 2);
      fontWeight = font.weights[defaultIndex];
    }
  }
  return fontWeight;
}
