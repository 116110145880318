import PropTypes from 'prop-types';
import React from 'react';
import Label from './label';
import { filter, findIndex, sortedIndexBy } from 'lodash';
import Checkbox from 'components/icon/checkbox';
import { FadeInPop } from 'components/transitions';
import Icon from '../icon/icon';
import $ from 'jquery';

const QUERY_LIMIT = 15;

export default class InputFinder extends React.PureComponent {
  static contextTypes = {
    queryLookup: PropTypes.func.isRequired,
    multiModelQueryLookup: PropTypes.func.isRequired,
  };

  static propTypes = {
    onSelectValue: PropTypes.func,
    onChange: PropTypes.func,
    models: PropTypes.arrayOf(PropTypes.string).isRequired,
    lookup: PropTypes.object.isRequired,
    queries: PropTypes.object,
    renderLookupItems: PropTypes.func,
    closeOnItemSelected: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      showing: false,
      selected: props.lookup.results ? props.lookup.results[0] : null,
      value: [], // these that are selected
      valueIndex: {}, // ids of selected items for easy add/delete
      inputValue: '',
    };
    this.inputTimer = null;
    this.justSelected = false;
    this.onFocus = this.onFocus.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onMouseOverValue = this.onMouseOverValue.bind(this);
    this.onClickSelectValue = this.onClickSelectValue.bind(this);
    this.onClickComponent = this.onClickComponent.bind(this);
    this.onClickOutsideComponent = this.onClickOutsideComponent.bind(this);
  }

  componentDidMount() {
    this.setState({
      valueIndex: this.getInitialValueIndex(this.props),
    });

    document.addEventListener('click', this.onClickOutsideComponent);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutsideComponent);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lookup !== this.props.lookup) {
      this.setState({ selected: nextProps.lookup.results[0] });
    }
    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState(
        {
          value: this.getInitialValue(nextProps),
          valueIndex: this.getInitialValueIndex(nextProps),
        },
        () => this.forceUpdate(),
      );
    }
  }

  getInitialValue(props) {
    if (props.defaultValue instanceof Array) {
      return props.defaultValue;
    }
    return [];
  }

  getInitialValueIndex(props) {
    if (props.defaultValue instanceof Array) {
      const idx = props.defaultValue.reduce((index, value) => {
        if (!value) return index;
        index[value.id] = true;
        return index;
      }, {});
      return idx;
    }
    return {};
  }

  onKeyDown(event) {
    switch (event.keyCode) {
      case 13: // enter
        this.selectValue();
        break;
      case 27: // escape
        this.refs.input.value = '';
        this.setState({
          showing: false,
        });
        break;
      case 38: // up
        event.preventDefault();
        this.selectPrev();
        break;
      case 40: // down
        event.preventDefault();
        this.selectNext();
        break;
      default:
        break;
    }
  }

  onKeyUp(event) {
    if (event.keyCode === 27) {
      // escape
      return;
    }
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      const inputValue = this.refs.input.value;
      const isChanged = inputValue !== this.state.inputValue;
      this.setState({ inputValue });
      if (isChanged && inputValue) {
        this.doQuery(inputValue);
      }
    }, 100);
  }

  doQuery(search) {
    const { models, queries = {} } = this.props;
    const limit = Math.floor(QUERY_LIMIT / models.length) || 1;
    for (const model of models) {
      queries[model] = { ...queries[model], limit, search: search };
    }

    if (this.context.multiModelQueryLookup) {
      this.context.multiModelQueryLookup(models, queries);
    }
  }

  onFocus() {
    this.setState({
      showing: true,
    });
    if (this.justSelected) {
      this.justSelected = false;
    } else {
      this.doQuery();
    }
  }

  selectValue() {
    const { showing, selected, value: prevValue, valueIndex } = this.state;
    const { onSelectValue, onChange } = this.props;
    if (showing && selected) {
      const event = new Event('change', { bubbles: true });
      let newState;
      if (!valueIndex[selected.id]) {
        // new item is being added
        const items = [...prevValue];
        items.splice(sortedIndexBy(items, selected, 'name'), 0, selected);
        newState = {
          value: items,
          valueIndex: {
            ...valueIndex,
            [selected.id]: true,
          },
        };
      } else {
        // item is being removed
        newState = {
          value: filter(prevValue, (v) => v.id !== selected.id.toString()),
          valueIndex: {
            ...valueIndex,
            [selected.id]: undefined,
          },
        };
      }
      this.setState(newState, () => {
        onSelectValue && onSelectValue(selected);
        onChange && onChange(event, this.state.value);
        this.refs.input.dispatchEvent(event);
      });
    }
  }

  selectPrev() {
    const { results } = this.props.lookup;
    const index = results.indexOf(this.state.selected);
    if (index > 0) {
      this.setState({
        selected: results[index - 1],
      });
    }
  }

  selectNext() {
    const { results } = this.props.lookup;
    const index = results.indexOf(this.state.selected);
    if (index < results.length - 1) {
      this.setState({
        selected: results[index + 1],
      });
    }
  }

  onClickSelectValue(event) {
    event.preventDefault();
    this.selectValue();
    const modals = document.getElementsByClassName('modal');
    const main = modals.length
      ? modals[modals.length - 1]
      : document.getElementById('main');
    const top = main.scrollTop;
    this.justSelected = true;
    this.refs.input.focus();
    main.scrollTop = top;
  }

  onMouseOverValue(event) {
    const { results } = this.props.lookup;
    const id = event.currentTarget.dataset.id;
    if (!id) return;
    const index = findIndex(results, { id });
    if (index >= 0) {
      this.setState({
        selected: results[index],
      });
    }
  }

  onClickComponent(event) {
    event.stopPropagation();
  }

  onClickOutsideComponent(event) {
    if (!$(event.target).closest(this.refs.self).length) {
      if (this.state.showing) {
        this.refs.input.value = '';
        this.setState({ showing: false, inputValue: '' });
      }
    }
  }

  renderLookupItems() {
    const { lookup } = this.props;
    const { selected, value, valueIndex } = this.state;
    const items = this.props.renderLookupItems
      ? this.props.renderLookupItems({
          lookup,
          selected,
          value,
          valueIndex,
          onClick: this.onClickSelectValue,
          onMouseOver: this.onMouseOverValue,
        })
      : this.props.lookup.results.length > 0 &&
        lookup.results.map((result) => (
          <li
            key={result.id}
            data-id={result.id}
            onClick={this.onClickSelectValue}
            onMouseOver={this.onMouseOverValue}
            className={`item ${result === selected ? 'selected' : null}`}
          >
            <span className="col" style={{ paddingLeft: 42 }}>
              <Checkbox
                checked={result === selected}
                className="checkbox"
                style={{ left: 14 }}
              />
              {result.name}
            </span>
          </li>
        ));
    if (!items.length) {
      return (
        <li className="item muted">
          <span className="col">None found</span>
        </li>
      );
    }
    return items;
  }

  render() {
    const {
      label,
      help,
      model,
      lookup,
      query,
      onSelectValue,
      renderLookupItems,
      ...props
    } = this.props;

    const { showing, inputValue } = this.state;

    return (
      <span className="form-lookup" onClick={this.onClickComponent} ref="self">
        <Label label={label} help={help} htmlFor={props.id} />
        <span className="form-field-input">
          <input
            {...props}
            type="text"
            ref="input"
            name={`${props.name}_search`}
            value={undefined}
            defaultValue={inputValue}
            onKeyDown={this.onKeyDown}
            onKeyUp={this.onKeyUp}
            onFocus={this.onFocus}
            onChange={(e) => e.stopPropagation()}
            autoComplete="off"
            className={`form-lookup-input ${props.disabled ? 'disabled' : ''} ${
              props.className || ''
            }`}
          />
          <Icon fa="search" />
          <FadeInPop
            active={showing}
            origin="top left"
            component="ul"
            className="form-lookup-list"
          >
            {this.renderLookupItems()}
          </FadeInPop>
        </span>
      </span>
    );
  }
}
