import { testUrlSegment } from './';

const { PAYPAL_EXPRESS_SANDBOX_MERCHANT_ID, PAYPAL_EXPRESS_LIVE_MERCHANT_ID } =
  process.env;

export const PAYPAL_DEFAULT_STYLE = {
  layout: 'horizontal',
  color: 'gold',
  shape: 'rect',
  height: 45,
  label: 'paypal',
  tagline: false,
};

export const paypalLayoutOptions = [
  { value: 'horizontal', label: 'Horizontal' },
  { value: 'vertical', label: 'Vertical' },
];

export const paypalColorOptions = [
  { value: 'gold', label: 'Gold' },
  { value: 'blue', label: 'Blue' },
  { value: 'silver', label: 'Silver' },
  { value: 'white', label: 'White' },
  { value: 'black', label: 'Black' },
];

export const paypalShapeOptions = [
  { value: 'rect', label: 'Rect' },
  { value: 'pill', label: 'Pill' },
];

export const paypalLabelOptions = [
  { value: 'paypal', label: 'Paypal' },
  { value: 'checkout', label: 'Checkout' },
  { value: 'buynow', label: 'Buy now' },
  { value: 'pay', label: 'Pay' },
];

function getConnectParams(clientId, command, isLive, testEnv) {
  const baseUrl = window.location.origin;
  const mode = !testEnv && isLive ? 'live' : 'test';
  const returnUrl = `${baseUrl}/admin/${testUrlSegment(
    testEnv,
  )}settings/payments/${command}?mode=${mode}`;

  return {
    merchantId: `${clientId}-${Date.now()}`,
    returnToPartnerUrl: returnUrl,
  };
}

export function gatPayPalExpressConnectParams(clientId, isLive, testEnv) {
  return {
    ...getConnectParams(clientId, 'paypal_express_activated', isLive, testEnv),
    partnerId: isLive
      ? PAYPAL_EXPRESS_LIVE_MERCHANT_ID
      : PAYPAL_EXPRESS_SANDBOX_MERCHANT_ID,
    productIntentID: 'addipmt',
    countryCode: 'US',
    integrationType: 'T',
    permissionNeeded: [
      'EXPRESS_CHECKOUT',
      'REFUND',
      'AUTH_CAPTURE',
      'REFERENCE_TRANSACTION',
      'TRANSACTION_DETAILS',
    ].join(','),
    displayMode: 'regular',
    showPermissions: 'true',
  };
}

export function getPayPalCommerceConnectParams(clientId, isLive, testEnv) {
  return getConnectParams(
    clientId,
    'paypal_commerce_activated',
    isLive,
    testEnv,
  );
}
