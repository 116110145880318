import api from 'services/api';
import * as CSV from 'csv-string';
import flash from './flash';
import { currencyValue, formatDate } from 'utils';
import { paymentStatus, fulfillmentStatus } from 'utils/order';

const EXPORT_PAGE_LIMIT = 30;

export const ORDERS_EXPORT_START = 'orders/exportStart';
export const ORDERS_EXPORT_PROGRESS = 'orders/exportProgress';
export const ORDERS_EXPORT_CANCEL = 'orders/exportCancel';
export const ORDERS_EXPORT_COMPLETE = 'orders/exportComplete';
export const ORDERS_FETCH_DISCOUNTS = 'orders/fetchDiscounts';

export default {
  fetchDiscounts() {
    return {
      type: ORDERS_FETCH_DISCOUNTS,
      payload: api.get('/data/:batch', {
        coupon_count: {
          url: '/coupons/:count',
        },
        promotions: {
          url: '/promotions',
          data: {
            active: true,
            limit: 100,
            // TODO: move this expired check into discounts-edit view
            // $or: [{ date_end: null }, { date_end: { $gt: Date.now() } }],
          },
        },
      }),
    };
  },

  export(params: Object) {
    return (dispatch, getState) => {
      if (getState().orders.export.running) {
        return;
      }

      dispatch(this.exportStart(params));

      runExport({
        getState,
        params,
        onProgress: (percent) => {
          dispatch(this.exportProgress(percent));
        },
        onCancel: () => {
          dispatch(this.exportCancel());
        },
        onComplete: (data, filename, mimetype) => {
          dispatch(this.exportComplete(data, filename, mimetype));
        },
        onError: (message) => {
          dispatch(this.exportCancel());
          dispatch(flash.error(message));
        },
      });
    };
  },

  exportStart(params: Object) {
    return {
      type: ORDERS_EXPORT_START,
      payload: params,
    };
  },

  exportProgress(percent: Number) {
    return {
      type: ORDERS_EXPORT_PROGRESS,
      payload: percent,
    };
  },

  exportCancel() {
    return {
      type: ORDERS_EXPORT_CANCEL,
      payload: null,
    };
  },

  exportComplete(data: String, filename: String, mimetype: String) {
    return {
      type: ORDERS_EXPORT_COMPLETE,
      payload: { data, filename, mimetype },
    };
  },
};

export const initialState = {
  discounts: {
    coupon_count: 0,
    promotions: {},
  },
  export: {
    running: false,
    complete: false,
    params: {},
    percent: 0,
    data: null,
    filename: null,
  },
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case ORDERS_EXPORT_START:
      return {
        ...state,
        export: {
          ...initialState.export,
          running: true,
          params: action.payload,
        },
      };

    case ORDERS_EXPORT_PROGRESS:
      return {
        ...state,
        export: {
          ...state.export,
          percent: action.payload,
        },
      };

    case ORDERS_EXPORT_CANCEL:
      return {
        ...state,
        export: {
          ...initialState.export,
        },
      };

    case ORDERS_EXPORT_COMPLETE:
      return {
        ...state,
        export: {
          ...state.export,
          running: false,
          complete: true,
          percent: 100,
          data: action.payload.data,
          filename: action.payload.filename,
          mimetype: action.payload.mimetype,
        },
      };

    case ORDERS_FETCH_DISCOUNTS:
      return {
        ...state,
        discounts: action.payload,
      };

    default:
      return state;
  }
}

const exportCSVFields = [
  { label: 'Order', value: (order) => order.number },
  { label: 'Email', value: (order) => order.account.email },
  { label: 'Phone', value: (order) => order.account.phone },
  {
    label: 'Payment status',
    value: (order) => String(paymentStatus(order) || '').toLowerCase(),
  },
  {
    label: 'Fulfillment status',
    value: (order) => String(fulfillmentStatus(order) || '').toLowerCase(),
  },
  { label: 'Currency', value: (order) => order.currency },
  {
    label: 'Subtotal',
    value: (order) => currencyValue(order.sub_total, order.currency),
  },
  {
    label: 'Shipping',
    value: (order) => currencyValue(order.shipment_total, order.currency),
  },
  {
    label: 'Taxes',
    value: (order) => currencyValue(order.tax_total, order.currency),
  },
  { label: 'Coupon code', value: (order) => order.coupon_code },
  {
    label: 'Promotions',
    value: (order) =>
      order.promotions.results.map((promo) => promo.name).join('\n'),
  },
  {
    label: 'Discount total',
    value: (order) => currencyValue(order.discount_total, order.currency),
  },
  {
    label: 'Shipping method',
    value: (order) => order.shipping.service_name || order.shipping.service,
  },
  { label: 'Date created', value: (order) => order.date_created },
  {
    label: 'Item quantity',
    value: (order) =>
      order.items &&
      order.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map(
                (bundleItem) => bundleItem.quantity * item.quantity,
              )
            : [item.quantity],
        );
      }, []),
  },
  {
    label: 'Item name',
    value: (order) =>
      order.items &&
      order.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map(
                (bundleItem) =>
                  `(${item.product.name}) ${bundleItem.product.name}${
                    bundleItem.variant ? ` - ${bundleItem.variant.name}` : ''
                  }`,
              )
            : [
                `${item.product.name}${
                  item.variant ? ` - ${item.variant.name}` : ''
                }`,
              ],
        );
      }, []),
  },
  {
    label: 'Item price',
    value: (order) =>
      order.items &&
      order.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map((bundleItem, index) =>
                index === 0 ? item.price : null,
              )
            : [currencyValue(item.price, order.currency)],
        );
      }, []),
  },
  {
    label: 'Item tax total',
    value: (order) =>
      order.items &&
      order.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map((bundleItem, index) =>
                index === 0 ? item.tax_total : null,
              )
            : [currencyValue(item.tax_total, order.currency)],
        );
      }, []),
  },
  {
    label: 'Item discount total',
    value: (order) =>
      order.items &&
      order.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map((bundleItem, index) =>
                index === 0 ? item.discount_total : null,
              )
            : [currencyValue(item.discount_total, order.currency)],
        );
      }, []),
  },
  {
    label: 'Item SKU',
    value: (order) =>
      order.items &&
      order.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map(
                (bundleItem) =>
                  (bundleItem.variant && bundleItem.variant.sku) ||
                  bundleItem.product.sku ||
                  item.product.sku,
              )
            : [(item.variant && item.variant.sku) || item.product.sku],
        );
      }, []),
  },
  {
    label: 'Item fulfillment method',
    value: (order) =>
      order.items &&
      order.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map((bundleItem) => bundleItem.delivery)
            : [item.delivery],
        );
      }, []),
  },
  {
    label: 'Item quantity fulfilled',
    value: (order) =>
      order.items &&
      order.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map(
                (bundleItem) => bundleItem.quantity_delivered || '0',
              )
            : [item.quantity_delivered || '0'],
        );
      }, []),
  },
  {
    label: 'Item quantity canceled',
    value: (order) =>
      order.items &&
      order.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map(
                (bundleItem) => bundleItem.quantity_canceled || '0',
              )
            : [item.quantity_canceled || '0'],
        );
      }, []),
  },
  {
    label: 'Item quantity returned',
    value: (order) =>
      order.items &&
      order.items.reduce((rows, item) => {
        return rows.concat(
          item.bundle_items
            ? item.bundle_items.map(
                (bundleItem) => bundleItem.quantity_returned || '0',
              )
            : [item.quantity_returned || '0'],
        );
      }, []),
  },
  { label: 'Billing name', value: (order) => order.billing.name },
  { label: 'Billing address line 1', value: (order) => order.billing.address1 },
  { label: 'Billing address line 2', value: (order) => order.billing.address2 },
  { label: 'Billing city', value: (order) => order.billing.city },
  { label: 'Billing state', value: (order) => order.billing.state },
  { label: 'Billing zip', value: (order) => order.billing.zip },
  { label: 'Billing country', value: (order) => order.billing.country },
  { label: 'Billing phone', value: (order) => order.billing.phone },
  { label: 'Shipping name', value: (order) => order.shipping.name },
  {
    label: 'Shipping address line 1',
    value: (order) => order.shipping.address1,
  },
  {
    label: 'Shipping address line 2',
    value: (order) => order.shipping.address2,
  },
  { label: 'Shipping city', value: (order) => order.shipping.city },
  { label: 'Shipping state', value: (order) => order.shipping.state },
  { label: 'Shipping zip', value: (order) => order.shipping.zip },
  { label: 'Shipping country', value: (order) => order.shipping.country },
  { label: 'Shipping phone', value: (order) => order.shipping.phone },
  { label: 'Comments', value: (order) => order.comments },
  { label: 'Notes', value: (order) => order.notes },
  { label: 'Canceled', value: (order) => (order.canceled ? 'yes' : '') },
  { label: 'Payment method', value: (order) => order.billing.method },
  {
    label: 'Payment total',
    value: (order) =>
      currencyValue(order.payment_total, order.currency, {
        precision: undefined,
      }),
  },
  {
    label: 'Refund total',
    value: (order) =>
      currencyValue(order.refund_total, order.currency, {
        precision: undefined,
      }),
  },
  {
    label: 'Payment balance',
    value: (order) => currencyValue(order.payment_balance, order.currency),
  },
  { label: 'ID', value: (order) => order.id },
];

function runExport(args, data = []) {
  const { getState, params, onCancel, onError, page = 1, retries = 0 } = args;

  const query = getExportQuery(params, getState);

  if (!query) {
    onError('There is nothing to export');
    return;
  }

  return api
    .get('/data/orders', { ...query, page })
    .then((result) => {
      // May be no results
      if (!result.count && !data.length) {
        onError('There is nothing to export');
        return;
      }
      // May be canceled while running
      if (getState().orders.export.running !== true) {
        onCancel();
        return;
      }
      // Update progress state
      handleExportProgress(args, result);
      // Update data from result
      handleExportData(args, result, data);
      // Complete when pages run out
      if (
        result.pages
          ? result.pages[page + 1] === undefined
          : !result.results.length
      ) {
        handleExportComplete(args, data);
        return;
      }
      // Next page
      return runExport({ ...args, page: page + 1 }, data);
    })
    .catch((err) => {
      console.error(err);
      if (retries > 10) {
        onError(err.message);
        return;
      }
      return runExport({ ...args, retries: retries + 1 }, data);
    });
}

function getExportQuery(params, getState) {
  const query = {
    sort: 'date_created desc',
    limit: EXPORT_PAGE_LIMIT,
    // Expand for CSV only
    expand:
      params.format !== 'csv'
        ? []
        : [
            'account',
            'promotions',
            'items.product',
            'items.variant',
            'items.bundle_items.product',
            'items.bundle_items.variant',
          ],
  };

  const { data } = getState();

  switch (params.type) {
    case 'page':
      query.id = { $in: data.collection.results.map((result) => result.id) };
      break;
    case 'search':
      query.search = data.query.search;
      query.where = data.query.where;
      break;
    case 'date':
      query.$and = [
        { date_created: { $gte: params.date_start } },
        { date_created: { $lte: params.date_end } },
      ];
      break;
    case 'selected':
      // Includes search
      query.search = data.query.search;
      query.where = data.query.where;
      if (data.selection.all) {
        const exceptIds = Object.keys(data.selection.except);
        if (exceptIds.length) {
          query.id = { $nin: exceptIds };
        }
      } else {
        const recordIds = Object.keys(data.selection.records);
        if (recordIds.length) {
          query.id = { $in: recordIds };
        } else {
          return;
        }
      }
      break;
    case 'all':
    default:
      break;
  }
  return query;
}

function handleExportProgress({ page = 1, onProgress }, result) {
  let percent = Math.ceil(((page * EXPORT_PAGE_LIMIT) / result.count) * 100);
  if (percent > 100) {
    percent = 100;
  }
  onProgress(percent);
}

function handleExportData({ params }, result, data) {
  result.results.forEach((result) => {
    switch (params.format) {
      case 'json':
        data.push(result);
        break;
      case 'csv':
      default:
        // CSV header first
        if (data.length === 0) {
          data.push(exportCSVFields.map((field) => field.label));
        }
        Array.prototype.push.apply(data, generateCSVRows(result));
        break;
    }
  });
}

function handleExportComplete({ getState, params, onComplete }, data = []) {
  let finalData;
  let filename;
  let mimetype;
  let extype;

  const { client } = getState();
  const now = Date.now();

  switch (params.type) {
    case 'page':
      extype = `current-page-${formatDate(now, 'isoDate')}`;
      break;
    case 'search':
      extype = `current-search-${formatDate(now, 'isoDate')}`;
      break;
    case 'date':
      extype = `by-date-${formatDate(
        params.date_start,
        'isoDate',
      )}-to-${formatDate(params.date_end, 'isoDate')}`;
      break;
    case 'selected':
      extype = `selected-${formatDate(now, 'isoDate')}`;
      break;
    default:
    case 'all':
      extype = `all-${formatDate(now, 'isoDate')}`;
      break;
  }

  switch (params.format) {
    case 'json':
      filename = `${client.id}-orders-${extype}.json`;
      mimetype = 'application/json';
      finalData = `${JSON.stringify(data)}`;
      break;
    case 'csv':
    default:
      filename = `${client.id}-orders-${extype}.csv`;
      mimetype = 'text/csv';
      finalData = CSV.stringify(data);
      break;
  }

  onComplete(finalData, filename, mimetype);
}

function generateCSVRows(result) {
  // Generate values for each CSV field
  const values = exportCSVFields.reduce((cols, field) => {
    let value;
    try {
      if (typeof field.value === 'function') {
        value = field.value(result);
      } else {
        value = field.value;
      }
    } catch (err) {
      // Oops
    }
    cols.push(value);
    return cols;
  }, []);

  // Generate row matrix from values
  const rows = [[]];
  values.forEach((value, topIndex) => {
    if (value instanceof Array) {
      value.forEach((val, valIndex) => {
        if (rows[valIndex] === undefined) {
          rows[valIndex] = Array(values.length).fill('');
        }
        rows[valIndex][topIndex] = val || '';
      });
    } else {
      rows[0].push(value || '');
    }
  });

  return rows;
}
