import Cookies from 'universal-cookie';

export const COOKIE_MAX_AGE = 7776000; // 90 days

/**
 * Wrapper for universal-cookies. Applies swell settings to cookies.
 */
const CookieHelpers = {
  cookies: new Cookies(),

  get(name) {
    return this.cookies.get(cookieName(name));
  },

  set(name, value, options) {
    this.cookies.set(cookieName(name), value, cookieOptions(options));
  },

  remove(name, options) {
    this.cookies.remove(cookieName(name), cookieOptions(options));
  },
};

function cookieName(name) {
  return `_swell_${name}`;
}

function cookieOptions(options = {}) {
  const { NODE_ENV } = process.env;
  return {
    ...options,
    domain: resolveDomain(),
    path: '/',
    maxAge: COOKIE_MAX_AGE,
    // client cookies cannot be httponly
    httpOnly: false,
    sameSite: 'lax',
    secure: NODE_ENV !== 'development',
  };
}

function resolveDomain() {
  if (!window.location) {
    return null;
  }

  if (window.location.hostname === 'localhost') {
    return null;
  }

  return `.${window.location.hostname.split('.').slice(-2).join('.')}`;
}

export default CookieHelpers;
