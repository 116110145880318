import React from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import pt from 'prop-types';

import actions from 'actions';
import { serializeRequest, unserializeRequest } from 'actions/console';

import { isEmpty } from 'utils';

import ConsoleAPIPage from 'components/console/api';
import ViewLoading from 'components/view/loading';

export const mapStateToProps = (state) => ({
  client: state.client,
  endpoints: state.console.endpoints,
  response: state.console.response,
  headers: state.console.headers,
  history: state.console.history,
  loading: state.loading,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchHistory() {
    return dispatch(actions.console.fetchHistory());
  },

  fetchEndpoints() {
    return dispatch(actions.console.fetchEndpoints());
  },

  runQuery(options) {
    return dispatch(actions.console.runQuery(options));
  },

  clearResponse() {
    return dispatch(actions.console.clearResponse());
  },
});

export class ConsoleAPI extends React.Component {
  static propTypes = {
    router: pt.object,
    history: pt.array,
    location: pt.object,

    runQuery: pt.func,
    fetchHistory: pt.func,
    clearResponse: pt.func,
    fetchEndpoints: pt.func,
  };

  static contextTypes = {
    notifyError: pt.func.isRequired,
    openModal: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      values: {},
      loaded: false,
      edited: false,
      query: null,
      queryRan: false,
      queryTime: null,
      querySubmitted: false,
      requestId: null,
    };

    this.methods = {
      onChangeForm: this.onChangeForm,
      onSubmitQuery: this.onSubmitQuery,
    };
  }

  componentDidMount() {
    const { location, fetchEndpoints, fetchHistory } = this.props;

    Promise.all([fetchHistory(), fetchEndpoints()]).then(() => {
      if (location.search) {
        const requestId = location.search.slice(1);
        this.doRequest(requestId);
      }

      this.setState({ loaded: true });
    });
  }

  componentDidUpdate(prevProps) {
    const { location, history, clearResponse } = this.props;

    if (
      this.state.loaded &&
      location.search &&
      location.search !== prevProps.location.search
    ) {
      if (this.state.querySubmitted) {
        this.setState({
          querySubmitted: false,
        });

        return;
      }

      const requestId = location.search.slice(1);
      const request = find(history, ['id', requestId]);

      if (request) {
        if (request.method === 'get') {
          this.onSubmitQuery(request, true);
        } else {
          this.setState({
            requestId,
            values: request,
            query: request,
            queryTime: null,
          });
        }
      } else if (requestId !== this.state.requestId) {
        Promise.resolve(requestId).then(async (requestId) => {
          await clearResponse();

          this.doRequest(requestId);
        });
      }
    }
  }

  doRequest(requestId) {
    const { router, endpoints, client } = this.props;

    try {
      const request = unserializeRequest(requestId, endpoints, client);

      this.setState({
        requestId,
        values: request,
        query: request,
      });

      if (request.method === 'get') {
        this.onSubmitQuery(request, true);
      }
    } catch (err) {
      console.error(err);
      router.replace('/console');
    }
  }

  /**
   * @param {object} values
   * @param {boolean} edited
   */
  onChangeForm = (values, edited) => {
    this.setState((state) => ({
      values: {
        ...state.values,
        ...values,
      },
      edited,
    }));
  };

  normalizeEndpoint(endpoint) {
    const { client } = this.props;

    // Convert endpoints using canonical app IDs to slug IDs
    if (endpoint.startsWith('apps/')) {
      const [_, appId, ...model] = endpoint.split('/');
      if (client.appsById[appId]) {
        return `apps/${client.appsById[appId].slug_id}/${model.join('/')}`;
      }
    }

    return endpoint;
  }

  async trySubmitQuery(values, mounting = false) {
    const { router, location, runQuery } = this.props;

    const parsedSubmitValues = this.parseSubmitValues(values);

    const { method, endpoint: parsedEndpoint, uri, body } = parsedSubmitValues;
    const endpoint = this.normalizeEndpoint(parsedEndpoint);
    const normalValues = { method, endpoint, uri, body };

    try {
      this.setState({ loading: true });

      const start = Date.now();

      await runQuery({
        method,
        endpoint,
        uri,
        body: { ...body, $console: true },
      });

      this.setState({ loading: undefined });

      const queryTime = Date.now() - start;
      const requestId = serializeRequest({ method, endpoint, uri, body });

      if (location.search.slice(1) !== requestId) {
        this.setState({
          querySubmitted: true,
        });

        router[mounting ? 'replace' : 'push'](`/console?${requestId}`);
      }

      this.setState({
        requestId,
        values: normalValues,
        query: normalValues,
        queryRan: true,
        queryTime,
      });
    } catch (err) {
      this.context.notifyError(err.message);
    }
  }

  onSubmitQuery = (values, mounting = false) => {
    const parsedSubmitValues = this.parseSubmitValues(values);

    const { method } = parsedSubmitValues;

    if (!method) {
      return;
    } else if (method === 'delete') {
      this.context.openModal('ConfirmDelete', {
        title: 'this resource',
        onConfirm: () => {
          this.trySubmitQuery(values, mounting);
        },
      });
    } else {
      this.trySubmitQuery(values, mounting);
    }
  };

  parseSubmitValues(values) {
    const { body } = values;

    return {
      ...values,
      body: !isEmpty(body)
        ? typeof body === 'string'
          ? JSON.parse(body)
          : body
        : undefined,
    };
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return <ConsoleAPIPage {...this.props} {...this.state} {...this.methods} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsoleAPI);
