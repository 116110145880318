import React from 'react';
import get from 'lodash/get';
import pt from 'prop-types';
import ObjectID from 'bson-objectid';

import {
  SUBSCRIPTION_INTERVAL_OPTIONS,
  getDefaultSubscriptionPlan,
  subscriptionIntervalOptions,
} from 'utils/subscription';

import SectionHeader from 'components/section-header/section-header';
import { Field, FieldLocalized, Fieldtable } from 'components/form';
import { FadeIn } from 'components/transitions';
import Help from 'components/tooltip/help';
import Alert from 'components/alert';
import Icon from 'components/icon';

import DeprecatedSubscriptionOption from './subscription-deprecated';

function DeprecatedSubscriptionOptionWrapper({ record, values, settings }) {
  return (
    <fieldset className="full">
      <div className="view-body-subheader">
        <SectionHeader
          className="view-body-subtitle"
          title="Subscription pricing"
        />

        <Alert type="warning" className="tight">
          This product uses a legacy subscription setting.{' '}
          <a
            href="https://developers.swell.is/guides/core-concepts/purchase-options"
            target="_blank"
            rel="noreferrer"
          >
            Learn how to upgrade <Icon fa="external-link" />
          </a>
        </Alert>
      </div>

      <DeprecatedSubscriptionOption
        name="deprecated_subscription_option"
        record={record}
        values={values}
        settings={settings}
      />
    </fieldset>
  );
}

DeprecatedSubscriptionOptionWrapper.propTypes = {
  record: pt.object,
  values: pt.object,
  settings: pt.object,
};

const tdPlanStyle = { width: '100%' };

export default class SubscriptionPurchaseOption extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    values: pt.object,
    currency: pt.string,
    settings: pt.object,
    optionValues: pt.object,
    getFieldName: pt.func,
  };

  static contextTypes = {
    account: pt.object.isRequired,
    client: pt.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.defaultSubscriptionPlans = [getDefaultSubscriptionPlan(context)];
  }

  onAddRow = () => {
    const defaultSubscriptionPlan = getDefaultSubscriptionPlan(this.context);

    return { ...defaultSubscriptionPlan };
  };

  renderSubscriptionPlanTableValue = ({ value, index, disabled }) => {
    const {
      currency,
      values: { purchase_options },
    } = this.props;

    const {
      id,
      active,
      price,
      description,
      billing_schedule: {
        id: billingId,
        interval,
        interval_count,
        trial_days,
        limit,
      },
    } = value;

    const option = SUBSCRIPTION_INTERVAL_OPTIONS.find(
      (option) => option.value === interval,
    );

    const required = Boolean(
      get(purchase_options, 'subscription.active', false),
    );

    const hasOrderSchedule = Boolean(value.has_order_schedule);
    const orderSchedule = value.order_schedule || { ...value.billing_schedule };

    return (
      <td key={index} style={tdPlanStyle}>
        <table className="product-subscription-option-table">
          <tbody>
            <tr>
              <td>
                <div className="product-subscription-grid product-subscription-billing-schedule">
                  <label>
                    {hasOrderSchedule ? 'Billing interval' : 'Interval'}
                  </label>

                  <label>Price</label>

                  <label>Trial days</label>

                  <label>Description</label>

                  <label>
                    Limit{' '}
                    <Help message="Optionally limit the number of times this subscription will invoice the customer" />
                  </label>

                  <div className="product-subscription-interval">
                    <div>
                      <span>Every</span>

                      {/* NOTE: ids not used yet */}
                      <Field
                        type="hidden"
                        name="active"
                        value={active !== undefined ? active : true}
                        disabled={disabled}
                        data-index={index}
                      />

                      <Field
                        type="hidden"
                        name="id"
                        value={id || ObjectID().toHexString()}
                        disabled={disabled}
                        data-index={index}
                      />

                      {billingId && (
                        <Field
                          type="hidden"
                          name="billing_schedule.id"
                          value={billingId}
                          disabled={disabled}
                          data-index={index}
                        />
                      )}

                      <Field
                        type="hidden"
                        name="name"
                        defaultValue={option ? option.label : value.name}
                        disabled={disabled}
                        data-index={index}
                      />

                      <Field
                        type="number"
                        className="product-subscription-interval-count"
                        name="billing_schedule.interval_count"
                        defaultValue={interval_count}
                        placeholder="1"
                        minValue={1}
                        maxValue={999}
                        selectFocus={true}
                        disabled={disabled}
                        data-index={index}
                      />

                      <Field
                        type="select"
                        name="billing_schedule.interval"
                        options={subscriptionIntervalOptions(interval_count)}
                        defaultValue={interval}
                        required={required}
                        disabled={disabled}
                        data-index={index}
                      />
                    </div>
                  </div>

                  <FieldLocalized
                    type="currency"
                    name="price"
                    defaultValue={price}
                    localeValue={value.$currency}
                    currency={currency}
                    required={required}
                    disabled={disabled}
                    autoFocus={true}
                    maxValue={9999999}
                    data-index={index}
                  />

                  <Field
                    type="number"
                    name="billing_schedule.trial_days"
                    defaultValue={trial_days}
                    placeholder="0"
                    selectFocus={true}
                    maxValue={999}
                    disabled={disabled}
                    data-index={index}
                  />

                  <FieldLocalized
                    type="textarea"
                    name="description"
                    defaultValue={description}
                    localeValue={value.$locale}
                    placeholder="Optional"
                    disabled={disabled}
                    autoSize={true}
                    rows={1}
                    maxRows={5}
                    data-index={index}
                  />

                  <Field
                    type="number"
                    name="billing_schedule.limit"
                    defaultValue={limit}
                    placeholder="∞"
                    selectFocus={true}
                    maxValue={999}
                    disabled={disabled}
                    data-index={index}
                  />
                </div>
              </td>
            </tr>

            {this.props.values.type !== 'digital' && (
              <React.Fragment>
                <tr>
                  <td>
                    <Field
                      type="toggle"
                      label="Fulfill separately"
                      name="has_order_schedule"
                      defaultChecked={hasOrderSchedule}
                      readonly={true}
                      disabled={disabled}
                      data-index={index}
                    />
                  </td>
                </tr>

                {hasOrderSchedule ? (
                  <tr>
                    <td>
                      <div className="product-subscription-grid product-subscription-order-schedule">
                        <label>Order interval</label>

                        <label>
                          Limit{' '}
                          <Help message="Optionally limit the number of orders created by this subscription plan" />
                        </label>

                        <div className="product-subscription-interval">
                          <div>
                            <span>Every</span>

                            <Field
                              type="number"
                              className="product-subscription-interval-count"
                              name="order_schedule.interval_count"
                              defaultValue={orderSchedule.interval_count}
                              disabled={disabled}
                              data-index={index}
                            />

                            <Field
                              type="select"
                              name="order_schedule.interval"
                              options={subscriptionIntervalOptions(
                                orderSchedule.interval_count,
                              )}
                              defaultValue={orderSchedule.interval}
                              disabled={disabled}
                              data-index={index}
                            />
                          </div>
                        </div>

                        <Field
                          type="number"
                          name="order_schedule.limit"
                          defaultValue={orderSchedule.limit}
                          placeholder="∞"
                          selectFocus={true}
                          disabled={disabled}
                          maxValue={999}
                          data-index={index}
                        />
                      </div>
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            )}
          </tbody>
        </table>
      </td>
    );
  };

  render() {
    const {
      record,
      values,
      settings,
      optionValues,
      getFieldName,
      values: { deprecated_subscription_option, delivery },
    } = this.props;

    if (deprecated_subscription_option && delivery === 'subscription') {
      return (
        <DeprecatedSubscriptionOptionWrapper
          record={record}
          values={values}
          settings={settings}
        />
      );
    }

    const disabled = !optionValues.active;

    return (
      <fieldset className="full">
        <div className="view-body-section">
          <header className="view-body-subheader">
            <SectionHeader
              className="view-body-subtitle"
              title="Subscription pricing"
              subtitle="Manage subscription pricing with billing intervals"
            />

            <div className="view-body-actions">
              <Field
                type="toggle"
                label="Enabled"
                name={getFieldName('subscription.active')}
                defaultChecked={!disabled}
              />
            </div>
          </header>

          <FadeIn transitionAppear={false} active={!disabled}>
            <div className="view-section-content no-padding">
              <div className="product-subscription-option">
                <Fieldtable
                  label="Plan"
                  name={getFieldName('subscription.plans')}
                  defaultValue={
                    optionValues.plans || this.defaultSubscriptionPlans
                  }
                  renderValue={this.renderSubscriptionPlanTableValue}
                  onAddRow={this.onAddRow}
                  removable={
                    optionValues.plans && optionValues.plans.length > 1
                  }
                  chromeless={true}
                  disabled={disabled}
                  sortable={true}
                  inline={true}
                />
              </div>
              {deprecated_subscription_option && (
                <Field
                  type="hidden"
                  name="deprecated_subscription_option"
                  value={deprecated_subscription_option}
                />
              )}
            </div>
          </FadeIn>
        </div>
      </fieldset>
    );
  }
}
