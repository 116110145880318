/**
 * Pricing plans
 * -------------
 * These settings are used to automatically update the underlying
 * subscription products after each deploy. Refer to:
 *
 *   /server/api/account.js:ensureSubscriptionProductsExist
 */

const TRIAL_DAYS = 14;
const ANNUAL_DISCOUNT = 10;

const INCREMENTAL_PRICING = {
  data: {
    price: 5,
    increment: 1, // Per 1GB
  },
  api_calls: {
    price: 5,
    increment: 100000, // Per 100k
  },
  function_calls: {
    price: 5,
    increment: 100000, // Per 100k
  },
};

const STARTER_FEATURE_FLAGS = {
  users: 2,
  support: true,
  priced_currencies: false, // Legacy support when this is undefined
  data: 1, // 1GB storage
  api_calls: 100000, // 100k monthly
  function_calls: 100000, // 100k monthly
};

const BASIC_FEATURE_FLAGS = {
  ...STARTER_FEATURE_FLAGS,
  users: 5,
  data: 3, // 3GB storage
  api_calls: 500000, // 500k monthly
  function_calls: 500000, // 500k monthly
};

const STANDARD_FEATURE_FLAGS = {
  ...BASIC_FEATURE_FLAGS,
  users: 15,
  priced_currencies: true,
  advanced_reporting: true,
  advanced_integrations: true,
  data: 10, // 10GB storage
  api_calls: 2000000, // 2M monthly
  function_calls: 2000000, // 2M monthly
};

const UNLIMITED_FEATURE_FLAGS = {
  ...STANDARD_FEATURE_FLAGS,
  users: 0, // Unlimited
  advanced_user_permissions: true,
  data: 0, // Unlimited
  api_calls: 0, // Unlimited
  function_calls: 5000000, // 5M monthly
};

const ENTERPRISE_FEATURE_FLAGS = {
  ...UNLIMITED_FEATURE_FLAGS,
};

const SANDBOX_FEATURE_FLAGS = {
  ...UNLIMITED_FEATURE_FLAGS,
  data: 1, // 1GB storage
  api_calls: 100000, // 100k monthly
  function_calls: 100000, // 100k monthly
};

// Just FYI, these are no longer used
// eslint-disable-next-line
const DEPRECATED_FEATURE_FLAGS = {
  webhooks: 1,
  coupons: true,
  giftcards: true,
  cart_recovery: true,
  basic_reporting: true,
  promotions: true,
  advanced_cart_recovery: true,
  calculated_shipping: true,
  order_webhook: true,
};

const TRIAL_PLAN = {
  id: 'trial',
  name: 'Trial',
  price: 0,
  features: {
    ...STARTER_FEATURE_FLAGS,
    // give trial access to Standard features to try out
    priced_currencies: true,
    advanced_reporting: true,
    advanced_integrations: true,
  },
};

const STARTER_PLAN = {
  id: 'starter',
  name: 'Starter',
  description: 'Get started with up to 2 admin users',
  price: 19,
  revenue_max: 150000, // $150k
  fee_percent_above_max: 2,
  features: {
    ...STARTER_FEATURE_FLAGS,
  },
  items: [
    {
      value: 2,
      label: 'Admin users',
    },
    {
      icon: 'dollar-sign',
      label: 'Up to $150K in sales',
      tooltip: 'up to $150K USD in sales, then 2% of sales beyond that',
    },
  ],
};

const BASIC_PLAN = {
  id: 'basic2',
  name: 'Basic',
  description: 'Everything you need to launch and grow',
  price: 89,
  revenue_max: 500000, // $500k
  fee_percent_above_max: 1.5,
  features: {
    ...BASIC_FEATURE_FLAGS,
  },
  highlight: true,
  items: [
    {
      value: 5,
      label: 'Admin users',
    },
    {
      icon: 'dollar-sign',
      label: 'Up to $500K in sales',
      tooltip:
        'Process up to $500K USD in sales, and then pay 1.5% for sales in excess during a given billing cycle',
    },
    {
      icon: 'check',
      label: 'Currencies and languages',
    },
  ],
};

const STANDARD_PLAN = {
  id: 'standard2',
  name: 'Standard',
  description: 'Time-saving features for larger teams',
  price: 299,
  revenue_max: 2000000, // $2M
  fee_percent_above_max: 1,
  features: {
    ...STANDARD_FEATURE_FLAGS,
  },
  items: [
    {
      value: 10,
      label: 'Admin users',
    },
    {
      icon: 'dollar-sign',
      label: 'Up to $2M in sales',
      tooltip:
        'Process up to $2M USD in sales, and then pay 1% for sales in excess during a given billing cycle',
    },
    {
      icon: 'check',
      label: 'Advanced reports',
    },
    {
      icon: 'check',
      label: 'International price lists',
    },
  ],
};

const UNLIMITED_PLAN = {
  id: 'unlimited',
  name: 'Unlimited',
  description: 'Enterprise scalability and greater control',
  price: 2000,
  revenue_max: 6500000, // $6.5M
  fee_percent_above_max: 0.4,
  features: {
    ...UNLIMITED_FEATURE_FLAGS,
  },
  items: [
    {
      icon: 'infinity',
      label: 'Unlimited admin users',
    },
    {
      icon: 'dollar-sign',
      label: 'Up to $6.5M in sales',
      tooltip:
        'Process up to $6.5M USD in sales, and pay just 0.40% for sales in excess during a given billing cycle',
    },
    {
      icon: 'check',
      label: 'Role-based permissions',
      beta: true,
    },
    {
      icon: 'check',
      label: 'Developer support',
      tooltip:
        'Includes a dedicated account manager and technical solution support from Swell engineers',
    },
    {
      icon: 'check',
      label: '100% uptime SLA',
    },
  ],
};

// Note: not changing plan until the next revamp
const ENTERPRISE_PLAN = {
  id: 'enterprise',
  name: 'Enterprise',
  description:
    'Priority technical support and negotiated pricing for growing merchants',
  price: 2500,
  price_interval: 'yearly',
  fee_percent: null,
  enterprise: true,
  revenue_tiers: [
    { max: 5000000, price: 2500 },
    { max: 10000000, price: 4750 },
    { max: 15000000, price: 6750 },
    { max: 20000000, price: 8500 },
    { max: 25000000, price: 10000 },
    { max: 35000000, price: 13125 },
    { max: 45000000, price: 15750 },
    { max: 60000000, price: 18000 },
    { max: 75000000, price: 18750 },
  ],
  features: {
    ...ENTERPRISE_FEATURE_FLAGS,
  },
  items: [
    {
      icon: 'infinity',
      label: 'Unlimited admin users',
    },
    {
      icon: 'check',
      label: 'Role-based permissions',
      beta: true,
    },
    {
      icon: 'check',
      label: 'Multiple dev environments',
      beta: true,
    },
    {
      icon: 'check',
      label: 'Advanced user permissions',
    },
    {
      icon: 'check',
      label: 'Enterprise integrations',
      tooltip: 'Salesforce, ERP platforms, and more',
    },
    {
      icon: 'check',
      label: '100% uptime SLA',
      tooltip: 'Enterprise SLA includes up to 10x reimbursement',
    },
    {
      icon: 'check',
      label: 'Engineering support',
      tooltip:
        'Includes a dedicated account manager and technical solution support from Swell engineers',
    },
  ],
};

const PARTNER_SANDBOX_PLAN = {
  id: 'partner-sandbox',
  name: 'Partner sandbox',
  description: 'Sandbox for development partners',
  price: 0,
  features: {
    ...SANDBOX_FEATURE_FLAGS,
  },
  items: [
    {
      icon: 'infinity',
      label: 'Unlimited admin users',
    },
    {
      icon: 'check',
      label: 'Role-based permissions',
      beta: true,
    },
    {
      icon: 'check',
      label: 'Test environment only',
    },
  ],
};

const APPSUMO_PRICING = {
  price: 0,
  fee_percent: null,
  description: 'Lifetime access to Swell Advanced plan via AppSumo',
};

const APPSUMO_FEATURE_FLAGS = {
  ...STANDARD_FEATURE_FLAGS,
  support: true,
};

const APPSUMO_PLANS = [
  {
    ...APPSUMO_PRICING,
    id: 'appsumo-tier-1',
    name: 'Standard (AppSumo Tier 1)',
    revenue_max: 75000,
    fee_percent_above_max: 1,
    features: {
      ...APPSUMO_FEATURE_FLAGS,
      users: 3,
    },
  },
  {
    ...APPSUMO_PRICING,
    id: 'appsumo-tier-2',
    name: 'Standard (AppSumo Tier 2)',
    revenue_max: 250000,
    fee_percent_above_max: 1,
    features: {
      ...APPSUMO_FEATURE_FLAGS,
      users: 6,
    },
  },
  {
    ...APPSUMO_PRICING,
    id: 'appsumo-tier-3',
    name: 'Standard (AppSumo Tier 3)',
    revenue_max: 500000,
    fee_percent_above_max: 1,
    features: {
      ...APPSUMO_FEATURE_FLAGS,
      users: 10,
      advanced_api: true,
    },
  },
  {
    ...APPSUMO_FEATURE_FLAGS,
    id: 'appsumo-tier-4',
    name: 'Standard (AppSumo Tier 4)',
    revenue_max: 1000000,
    fee_percent_above_max: 1,
    features: {
      ...APPSUMO_FEATURE_FLAGS,
      users: 20,
      advanced_api: true,
    },
  },
];

const DEPRECATED_PLANS = [
  {
    id: 'community',
    name: 'Community',
    description: 'Everything you need to launch and grow a new online store',
    price: 0,
    fee_percent: 2,
    items: [
      {
        value: 3,
        label: 'Admin users',
      },
      {
        icon: 'check',
        label: 'All standard features',
      },
      {
        icon: 'check',
        label: 'Fair use API limits',
      },
      {
        icon: 'check',
        label: 'Community support',
        tooltip: 'Get help and help others in the Swell Community forum',
      },
    ],
    features: {
      data: 100, // 100GB storage
      users: 3,
      priced_currencies: false, // Legacy support when this is undefined
    },
  },
  {
    id: 'basic',
    name: 'Basic',
    active: true,
    description: 'Everything you need to launch a new online store',
    price: 59,
    revenue_max: 75000,
    items: [
      {
        value: 3,
        label: 'Admin users',
      },
      {
        icon: 'check',
        label: 'Basic reporting',
      },
    ],
    features: {
      data: 100,
      users: 3,
      webhooks: 1,
      coupons: true,
      giftcards: true,
      cart_recovery: true,
      basic_reporting: true,
      // --
      promotions: false,
      advanced_reporting: false,
      advanced_cart_recovery: false,
      calculated_shipping: false,
      order_webhook: false,
    },
  },
  {
    id: 'standard',
    name: 'Standard',
    active: true,
    description: 'Ideal for sellers leveling up in operations and marketing',
    price: 299,
    revenue_max: 250000,
    items: [
      {
        value: 10,
        label: 'Admin users',
      },
      {
        icon: 'check',
        label: 'All basic features',
      },
      {
        icon: 'check',
        label: 'Promotions & discount rules',
      },
    ],
    features: {
      data: 100,
      users: 10,
      webhooks: 3,
      coupons: true,
      giftcards: true,
      cart_recovery: true,
      basic_reporting: true,
      promotions: true,
      bundles: true,
      // --
      advanced_reporting: true,
      advanced_cart_recovery: true,
      calculated_shipping: true,
      order_webhook: false,
    },
  },
  {
    id: 'advanced',
    name: 'Advanced',
    active: true,
    description: 'Generous limits and time-saving features for growing brands',
    price: 549,
    revenue_max: 2000000,
    revenue_tiers: [
      { price: 549, revenue_max: 500000 },
      { price: 749, revenue_max: 750000 },
      { price: 949, revenue_max: 1000000 },
      { price: 1149, revenue_max: 1250000 },
      { price: 1349, revenue_max: 1500000 },
      { price: 1549, revenue_max: 1750000 },
      { price: 1749, revenue_max: 2000000 },
    ],
    items: [
      {
        value: 25,
        label: 'Admin users',
      },
      {
        icon: 'check',
        label: 'All standard features',
      },
      {
        icon: 'check',
        label: 'Cross-sells & up-sells',
      },
      {
        icon: 'check',
        label: 'Bulk coupon generation',
      },
      {
        icon: 'check',
        label: 'Advanced reporting',
      },
      {
        icon: 'check',
        label: 'Advanced user permissions',
      },
    ],
    features: {
      data: 100,
      users: 25,
      webhooks: 10,
      coupons: true,
      giftcards: true,
      cart_recovery: true,
      basic_reporting: true,
      promotions: true,
      bundles: true,
      cross_up_sells: true,
      bulk_coupons: true,
      advanced_reporting: true,
      // ++
      advanced_cart_recovery: true,
      calculated_shipping: true,
      order_webhook: true,
      advanced_user_permissions: true,
      // --
    },
  },
  // Original sandbox deprecated in favor of sandbox2
  {
    ...TRIAL_PLAN,
    id: 'sandbox',
    name: 'Sandbox',
    description: 'Sandbox for developers and partners',
    features: {
      ...ENTERPRISE_FEATURE_FLAGS,
      support: true,
    },
  },
  // Sandbox plus deprecated because we no longer offer a special sandbox to enterprise opps
  {
    ...TRIAL_PLAN,
    id: 'sandbox-plus',
    name: 'Sandbox Plus',
    description: 'Sandbox for enterprise developers and partners',
    features: {
      ...ENTERPRISE_FEATURE_FLAGS,
      support: true,
    },
  },
  // Sandbox2 deprecated in favor of partner-sandbox because they existed before Test environments
  {
    ...TRIAL_PLAN,
    id: 'sandbox2',
    name: 'Sandbox',
    description: 'Sandbox for development partners',
    price: 0,
    features: {
      ...SANDBOX_FEATURE_FLAGS,
    },
  },
].map((plan) => {
  plan.deprecated = true;
  return plan;
});

const MAIN_PLANS = [BASIC_PLAN, STANDARD_PLAN, UNLIMITED_PLAN];
const ALT_PLANS = [STARTER_PLAN, ENTERPRISE_PLAN];
const HIDDEN_PLANS = [TRIAL_PLAN, PARTNER_SANDBOX_PLAN];

const PLANS = [
  ...MAIN_PLANS,
  ...ALT_PLANS,
  ...HIDDEN_PLANS,
  ...APPSUMO_PLANS,
  ...DEPRECATED_PLANS,
].map((plan) => {
  plan.active = plan.active !== false ? true : false;
  return plan;
});

const PLANS_BY_ID = PLANS.reduce(
  (acc, plan) => ({ ...acc, [plan.id]: plan }),
  {},
);

module.exports = {
  PLANS,
  PLANS_BY_ID,
  MAIN_PLANS,
  ALT_PLANS,
  HIDDEN_PLANS,
  TRIAL_DAYS,
  ANNUAL_DISCOUNT,
  DEPRECATED_PLANS,
  INCREMENTAL_PRICING,
  // All active plans
  STARTER_PLAN,
  BASIC_PLAN,
  STANDARD_PLAN,
  UNLIMITED_PLAN,
  ENTERPRISE_PLAN,
  PARTNER_SANDBOX_PLAN,
};
