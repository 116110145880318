import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { imageUrl } from 'utils';

import Status from 'components/status';
import ProductPrice from 'components/pages/product/price';

import Collection from './Collection';

export const query = {
  $or: [{ type: 'giftcard' }, { delivery: 'giftcard' }],
  include: {
    variants: {
      url: '/products:variants',
      params: {
        parent_id: 'id',
      },
      data: {
        fields: 'price',
      },
    },
  },
};

export const tabs = {
  default: {
    label: 'All gift cards',
  },
  active: {
    label: 'Active',
    query: {
      active: true,
    },
  },
  inactive: {
    label: 'Inactive',
    query: {
      active: false,
    },
  },
};

export const fields = {
  name: {
    label: 'Product',
    sort: ['name'],
    url: '/products/{id}',
    default: true,
    columns: [
      {
        type: 'image',
        func(product) {
          const image = get(
            product,
            'images[0]',
            get(product, 'variant_with_image.images[0]'),
          );
          return (
            image && (
              <img
                title={image.caption || ''}
                alt={image.caption || ''}
                src={imageUrl(image, {
                  width: 90,
                  height: 90,
                  padded: true,
                })}
              />
            )
          );
        },
      },
      {
        label: 'Product',
        id: 'name',
        path: 'name',
        type: 'link',
        url: '/products/{id}',
      },
    ],
  },
  status: {
    label: 'Status',
    func: (product) => {
      return (
        <Status type={product.active ? 'positive' : 'muted'}>
          {product.active ? 'Active' : 'Inactive'}
        </Status>
      );
    },
  },
  stock_level: {
    label: 'Stock',
    func: (product) => {
      return product.stock_tracking ? (
        <span className={product.stock_level > 0 ? '' : 'muted'}>
          {product.stock_level || 0}
        </span>
      ) : (
        <span className="muted">&mdash;</span>
      );
    },
  },
  price: {
    label: 'Value',
    type: 'currency',
    func: (product) => <ProductPrice product={product} />,
  },
};

export const bulkActions = [
  {
    modal: 'BulkEditProducts',
    label: 'Edit Products',
  },
  {
    modal: 'BulkCategoriesAdd',
    label: 'Add to categories',
    type: 'secondary',
  },
  {
    modal: 'BulkCategoriesRemove',
    label: 'Remove from categories',
    type: 'secondary',
  },
  {
    modal: 'BulkDelete',
    label: 'Delete',
    type: 'danger inverse',
    condition: (selection) =>
      !selection.all || Object.keys(selection.except).length > 0,
  },
];

const headerActions = [
  { label: 'New product', link: '/products/new?type=giftcard' },
];

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export class GiftcardProducts extends React.Component {
  render() {
    return (
      <div>
        <Collection
          {...this.props}
          detail={true}
          nested={true}
          sectionTitle="Gift cards"
          title="Gift card products"
          uri="/giftcards"
          linkUri="/products"
          model="products"
          emptyTitle="Add gift card products"
          emptyDescription="Get started by adding the first gift card product to your store"
          emptyAction="Create gift card"
          tabs={tabs}
          filters={undefined}
          fields={fields}
          bulkActions={bulkActions}
          headerActions={headerActions}
          query={query}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftcardProducts);
