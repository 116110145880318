import React from 'react';
import { connect } from 'react-redux';

import { formatDate } from 'utils';

import actions from 'actions';
import { replaceLocationQuery } from 'actions/data';

import Status from 'components/status';
import DiscountRuleDescriptions from 'components/discount/rule-descriptions';
import ViewLoading from 'components/view/loading';

import Collection from './Collection';

let SETTINGS;
let CATEGORIES;

const query = {
  expand: ['discounts.product', 'discounts.category'],
};

const tabs = {
  default: {
    label: 'All promotions',
  },
  active: {
    label: 'Active',
    query: {
      active: true,
      $or: [
        { date_end: null },
        { date_end: { $exists: false } },
        { date_end: { $gt: Date.now() } },
      ],
    },
  },
  expired: {
    label: 'Ended',
    query: {
      $and: [
        { date_end: { $exists: true } },
        { date_end: { $lte: Date.now() } },
      ],
    },
  },
  inactive: {
    label: 'Inactive',
    query: {
      active: false,
    },
  },
};

const filters = {
  use_count: {
    label: 'Uses',
    operators: ['gt', 'lt', 'eq'],
    type: 'number',
  },
  date_created: {
    label: 'Created',
    operators: ['after', 'before'],
    type: 'date',
  },
  status: {
    label: 'Status',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
      { value: 'ended', label: 'Ended' },
    ],
    func(query) {
      switch (query) {
        case 'active':
          return {
            active: true,
            $or: [
              { date_end: { $exists: false } },
              { date_end: null },
              { date_end: { $gt: Date.now() } },
            ],
          };

        case 'inactive':
          return {
            active: false,
          };

        case 'ended':
          return {
            active: true,
            $and: [
              { date_end: { $exists: true } },
              { date_end: { $lte: Date.now() } },
            ],
          };

        default:
          break;
      }
    },
  },
};

const fields = {
  name: {
    label: 'Promotion',
    type: 'link',
    url: '/promotions/{id}',
    default: true,
  },
  discounts: {
    label: 'Discount',
    sort: false,
    func: (promo) => (
      <DiscountRuleDescriptions
        record={promo}
        settings={SETTINGS}
        categories={CATEGORIES}
      />
    ),
  },
  use_count: {
    label: 'Used',
    func: (promo) => {
      return promo.use_count || 0;
    },
  },
  starts: {
    label: 'Starts',
    sort: false,
    func: (promo) => {
      if (promo.date_start) {
        return (
          <span className="nowrap">
            {formatDate(promo.date_start, 'short')}
          </span>
        );
      }
      return <span className="muted">&mdash;</span>;
    },
  },
  expires: {
    label: 'Ends',
    sort: false,
    func: (promo) => {
      if (promo.date_end) {
        return (
          <span className="nowrap">{formatDate(promo.date_end, 'short')}</span>
        );
      }
      return <span className="muted">&mdash;</span>;
    },
  },
  status: {
    label: 'Status',
    sort: false,
    func: (promo) => {
      const isValid =
        promo.date_start && new Date(promo.date_start) <= Date.now();
      const isExpired =
        promo.date_end && new Date(promo.date_end) <= Date.now();
      return (
        <Status
          type={
            isValid === false
              ? 'muted'
              : isExpired
              ? 'negative'
              : promo.active
              ? 'positive'
              : 'muted'
          }
        >
          {isValid === false
            ? 'Pending'
            : isExpired
            ? 'Ended'
            : promo.active
            ? 'Active'
            : 'Inactive'}
        </Status>
      );
    },
  },
};

const headerActions = [{ label: 'New promotion', link: '/promotions/new' }];

class Promotions extends React.Component {
  static async onEnter(store, nextState, replace) {
    if (replaceLocationQuery(store, nextState, replace)) {
      return;
    }
  }

  state = { loaded: false };

  componentDidMount() {
    const { loadCategories, loadSettings } = this.props;

    Promise.all([loadCategories(), loadSettings()]).then(() => {
      this.setState({ loaded: true });
    });
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    CATEGORIES = this.props.categories;
    SETTINGS = this.props.settings;

    return (
      <Collection
        {...this.props}
        title="Promotions"
        uri="/promotions"
        model="promotions"
        emptyDescription="Promotions automatically apply discounts at checkout based on products and other properties of a shopping cart."
        tabs={tabs}
        filters={filters}
        fields={fields}
        headerActions={headerActions}
        query={query}
        queryCurrency={true}
      />
    );
  }
}

function mapStateToProps({ categories, settings }) {
  return {
    categories,
    settings,
  };
}

const mapDispatchToProps = (dispatch) => ({
  loadCategories() {
    return dispatch(actions.categories.load());
  },

  loadSettings() {
    return dispatch(actions.settings.fetch('shipments'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
