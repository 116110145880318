/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import View from 'components/view';
import { Form } from 'components/form';
import './storefront.scss';
import { Field } from 'components/form';
import StorefrontDocs from './docs';

export default class StorefrontNew extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  state = {
    values: {
      name: this.context.client.name,
    },
  };

  onChangeForm = (values, _edited) => {
    this.setState({
      values: { ...values },
    });
  };

  onSubmitForm = async ({ name, custom_url }) => {
    const storefrontParams = {
      name,
      external: {
        custom: true,
        custom_url,
      },
    };

    await this.props.onCreateStorefront(storefrontParams);
  };

  render() {
    const { client } = this.context;
    const urlPlaceholder = `https://www.${client.id || 'example'}.com`;

    return (
      <div className="view-detail">
        <Form
          onChange={this.onChangeForm}
          onSubmit={this.onSubmitForm}
          autoFocus={true}
        >
          <View
            uri="/storefronts"
            sectionTitle="Storefront"
            headerTitle="New storefront"
            detail={true}
            headerActions={[
              {
                label: 'Create storefront',
                type: 'default',
                submit: true,
              },
            ]}
            localized={true}
          >
            <fieldset>
              <div className="view-body-subsection">
                <div className="row">
                  <Field
                    type="text"
                    name="name"
                    label="Storefront name"
                    placeholder="My storefront"
                    required={true}
                    className="span4"
                    help="Name used to identify this storefront. Not visible to customers."
                  />
                </div>
                <div className="row">
                  <Field
                    type="text"
                    name="custom_url"
                    label="Storefront URL"
                    placeholder={urlPlaceholder}
                    rules="secure_url"
                    className="span4"
                    help="URL to your storefront home page"
                    hint="You can enter this after your storefront is up and running"
                  />
                </div>
              </div>
              <StorefrontDocs />
            </fieldset>
          </View>
        </Form>
      </div>
    );
  }
}
