import React from 'react';
import pt from 'prop-types';

import { formatDate } from 'utils';

import Status from 'components/status';
import Tooltip from 'components/tooltip';

function PaymentStatus({ model, record, loading, onClickPaymentRetry }) {
  if (record.void) {
    return <Status type="warning">Void</Status>;
  }

  if (record.paid) {
    return <Status type="positive">Paid</Status>;
  }

  if (record.payment_error) {
    return (
      <span>
        <Tooltip
          message={
            record.date_payment_retry
              ? `${record.payment_error}. Auto-retry on ${formatDate(
                  record.date_payment_retry,
                  'short',
                )}`
              : record.payment_error
          }
        >
          <Status type="negative">
            Error
            <span className="help icon icon-info">&nbsp;</span>
          </Status>
        </Tooltip>
        &nbsp;
        <button
          data-model={model}
          data-record-id={record.id}
          data-amount={record.grand_total}
          style={loading ? { opacity: 0.5 } : {}}
          className="as-link"
          onClick={onClickPaymentRetry}
          type="button"
        >
          retry
        </button>
      </span>
    );
  }

  if (record.unpaid) {
    return <Status>Unpaid</Status>;
  }

  if (
    record.payments?.results?.some(
      (payment) => payment.async && payment.async_data,
    )
  ) {
    return (
      <Tooltip message="Awaiting customer confirmation">
        <Status>
          Pending
          <span className="help icon icon-info">&nbsp;</span>
        </Status>
      </Tooltip>
    );
  }

  return <Status>Pending</Status>;
}

PaymentStatus.propTypes = {
  model: pt.string.isRequired,
  record: pt.object.isRequired,
  loading: pt.bool,
  onClickPaymentRetry: pt.func,
};

export default React.memo(PaymentStatus);
