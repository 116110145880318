import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';
import {
  map,
  find,
  isArray,
  reduce,
  get,
  merge,
  cloneDeep,
  isEqual,
  includes,
} from 'lodash';
import moment from 'moment-timezone';

import { getReportFields } from 'constants/report-fields';
import { getReports } from 'constants/reports';

import { formatDate, locationWithQuery, isEmpty, parseQuery } from 'utils';
import { putElementInFirstPlace } from 'utils/array';

import actions from 'actions';

import NotFoundPage from 'components/pages/error/404';
import ReportsCollection from 'containers/ReportsCollection.connect';
import ViewLoading from 'components/view/loading';
import { getSortConditions } from 'utils/report';

const mapStateToProps = (state) => ({
  custom: state.reports.custom,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustom(params) {
    return dispatch(actions.reports.fetchCustom(params));
  },

  loadSettings() {
    return dispatch(actions.settings.fetch('payments'));
  },
});

export class ViewReport extends React.Component {
  static propTypes = {
    params: pt.object,
    router: pt.object,
    location: pt.object,
    settings: pt.object,

    fetchCustom: pt.func,
    loadSettings: pt.func,
  };

  static contextTypes = {
    client: pt.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loaded: false,
      searchQuery: null,
    };

    const { client } = context;

    this.startDate = null;
    this.endDate = null;

    this.defaultStartDate = moment()
      .tz(client.timezone)
      .add(-30, 'days')
      .startOf('day');

    this.defaultEndDate = moment()
      .tz(client.timezone)
      .add(-1, 'days')
      .endOf('day');

    this.fields = null;
    this.fieldsToSelect = null;
    this.report = null;
    this.reportType = null;
    this.type = null;
    this.title = null;
    this.charts = null;
    this.summaryMetrics = null;
  }

  componentDidMount() {
    this.props
      .loadSettings()
      .then(() => this.setReport())
      .then(() => {
        this.setState({ loaded: true });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      !isEqual(prevProps.location.query, this.props.location.query)
    ) {
      this.setReport();
    }
  }

  async setReport() {
    const { client } = this.context;
    const { params, settings, location } = this.props;
    const query = {};
    this.usingSearchFilter = false;

    // TODO: refactor this
    let type = params.type.split('-')[0];

    const reports = getReports(client.timezone);
    const reportFields = getReportFields(client.timezone, settings);

    this.isCustom = false; //!reportFields[type];

    let report;
    let fields;

    if (this.isCustom) {
      const id = type;
      this.custom = id;
      const custom = await this.props.fetchCustom({ id });
      type = custom.type.split(/_/)[0];
      const parentReport = find(
        reports[type].types,
        (report) => custom.type === report.type,
      );
      report = custom;
      report.charts = parentReport.charts;
      report.dateRange = {};
      report.columns = !isEmpty(custom.columns)
        ? custom.columns
        : parentReport.columns;
      if (custom.min) {
        report.dateRange.startDate = moment(custom.min);
      }
      if (custom.max) {
        report.dateRange.endDate = moment(custom.max);
      }
    } else {
      const group = find(reports, (group) =>
        find(group.types, { url: params.type }),
      );
      report = find(group.types, { url: params.type });
    }

    if (!report) {
      return;
    }

    this.reportType = report.type;

    fields = cloneDeep(reportFields[type]);

    if (!report.includeTimeFields) {
      delete fields.time;
    }

    if (report.removeFields) {
      for (const [key, value] of Object.entries(report.removeFields)) {
        const fieldGroup = fields[key];
        if (fieldGroup?.fields) {
          if (Array.isArray(value)) {
            value.forEach((fieldToDelete) => {
              delete fieldGroup.fields[fieldToDelete];
            });
          }
        }
      }
    }

    this.fieldsToSelect = fields;
    this.fields = [];

    const queryFields = location.query.fields
      ? location.query.fields.split(/\s*,\s*/)
      : null;

    if (queryFields) {
      const fieldsByLabel = reduce(
        fields,
        (acc, group) => {
          reduce(
            group.fields,
            (acc, f) => {
              acc[f.label] = f;
              return acc;
            },
            acc,
          );

          return acc;
        },
        {},
      );

      this.fields = queryFields
        .map((label) => {
          const field = fieldsByLabel[label];

          if (!field) {
            return null;
          }

          field.checked = true;
          query.group = merge(query.group, field.group);

          if (report.main === field.id) {
            field.main = true;
            field.mainPath = field.id;
          }

          return field;
        })
        .filter(Boolean);
    } else {
      if (isArray(report.columns)) {
        map(report.columns, (column) => {
          const field = get(fields, column);

          if (queryFields) {
            field.checked = includes(queryFields, field.label);
          } else {
            field.checked = true;
          }

          if (field.checked) {
            this.fields.push(field);
            query.group = merge(query.group, field.group);

            if (report.main === column) {
              field.main = true;
            }
          }
        });
      } else {
        map(report.columns, (columns, group) => {
          columns.forEach((column) => {
            const field = fields[group].fields[column];

            if (queryFields) {
              field.checked = includes(queryFields, field.label);
            } else {
              field.checked = true;
            }

            if (field.checked) {
              this.fields.push(field);
              query.group = merge(query.group, field.group);

              if (report.main === `${group}.${column}`) {
                field.main = true;
                field.mainPath = `${group}.${column}`;
              }
            }
          });
        });
      }

      this.fields = putElementInFirstPlace(
        this.fields,
        (option) => option.main === true,
      );
    }

    this.type = type;
    this.title = report.name;
    this.charts = report.charts;
    this.summaryMetrics = report.totalSummaryMetrics || [];
    this.search = report.search;

    this.report = report;

    const { startDate = this.defaultStartDate, endDate = this.defaultEndDate } =
      report.dateRange || {};

    this.startDate = startDate;
    this.endDate = endDate;

    if (location.query.startDate) {
      this.startDate = moment
        .tz(location.query.startDate, client.timezone)
        .startOf('day')
        .utc();
    }

    if (location.query.endDate) {
      this.endDate = moment
        .tz(location.query.endDate, client.timezone)
        .endOf('day')
        .utc();
    }

    query.conditions = this.getConditions(
      this.fields,
      this.startDate,
      this.endDate,
    );

    query.sort =
      location.query.sort || getSortConditions(location.query) || report.sort;

    query.page = location.query.page;

    if (this.search) {
      if (location.query.search) {
        const { searchField } = this.search;
        this.usingSearchFilter = true;

        query.search = {
          [searchField]: {
            $regex: location.query.search,
            $options: 'i',
          },
        };
      }

      if (this.search.filters) {
        const parsedQuery = parseQuery(location.search);
        const filter = {};

        for (const [key, value] of Object.entries(this.search.filters)) {
          if (parsedQuery[key]) {
            filter[value.searchField] = value.filterValue
              ? value.filterValue(parsedQuery[key])
              : parsedQuery[key];
          }
        }

        if (!isEmpty(filter)) {
          this.usingSearchFilter = true;
          query.filter = filter;
        }
      }
    }

    // set reportId onto query for csv/json bulkExport
    query.reportId = report.id;

    this.setState({ query, searchQuery: location.query.search });
  }

  getConditions(fields, startDate, endDate) {
    return merge(
      this.mergeObjectsArray(fields.map((field) => field.conditions)),
      {
        $and: [
          {
            date_created: {
              $gte: { $date: formatDate(startDate, 'isoString') },
              $lte: { $date: formatDate(endDate, 'isoString') },
            },
          },
        ],
      },
    );
  }

  mergeObjectsArray = (objects) => {
    return reduce(objects, (memo, object) => merge(memo, object), {});
  };

  onChangeQuery = (params) => {
    const locationQuery = {};
    const groups = map(params.fields, (field) => field.group);
    const group = params.fields && this.mergeObjectsArray(groups);
    const conditions = this.getConditions(
      params.fields || this.fields,
      this.startDate,
      this.endDate,
    );
    const query = { conditions };

    if (params.startDate && params.startDate !== this.defaultStartDate) {
      locationQuery.startDate = formatDate(params.startDate, 'isoDate');
    }

    if (params.endDate && params.endDate !== this.defaultEndDate) {
      locationQuery.endDate = formatDate(params.endDate, 'isoDate');
    }

    if (params.fields) {
      const beforeFields = this.fields.map((field) => field.path);
      const afterFields = params.fields.map((field) => field.path);
      if (!isEqual(beforeFields, afterFields)) {
        this.fields = putElementInFirstPlace(
          params.fields,
          (option) => option.mainPath === this.report.main,
        );
        locationQuery.fields = params.fields
          .map((field) => field.label)
          .join(',');
      }
    }

    if (this.search) {
      if (params.search) {
        const { searchField } = this.search;

        query.search = {
          [searchField]: {
            $regex: params.search,
            $options: 'i',
          },
        };
        locationQuery.search = params.search;
      }

      if (params.filter && this.search.filters) {
        Object.keys(this.search.filters).forEach((key) => {
          locationQuery[key] = params.filter[key]
            ? params.filter[key]
            : undefined;
        });
      }
    }

    // clear search
    if (params.search === '') {
      query.search = undefined;
      locationQuery.search = undefined;
    }

    if (params.page) {
      query.page = params.page;
      locationQuery.page = params.page;
    }

    if (params.sort || this.state.query.sort) {
      query.sort = params.sort || this.state.query.sort;
      locationQuery.sort = query.sort;
    }

    if (!isEmpty(group) || this.state.query.group) {
      query.group = !isEmpty(group) ? group : this.state.query.group;
    }

    const next = locationWithQuery(this.props.location, locationQuery, true);
    this.props.router.replace(next);
  };

  onChangeDates = (startDate, endDate) => {
    this.onChangeQuery({ startDate, endDate });
  };

  onResetReport = () => {
    const { location } = this.props;
    this.startDate = this.defaultStartDate;
    this.endDate = this.defaultEndDate;
    this.props.router.push(location.pathname);
  };

  onReportUpdate = (params) => {
    if (params.name) {
      this.title = params.name;
    }

    this.forceUpdate();
  };

  render() {
    const { searchQuery } = this.state;
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    if (!this.report) {
      return <NotFoundPage />;
    }

    return (
      <ReportsCollection
        {...this.props}
        title={this.title}
        detail={true}
        uri="/reports"
        sectionTitle="Reports"
        model={this.type}
        charts={this.charts}
        search={this.search}
        searchQuery={searchQuery}
        usingSearchFilter={this.usingSearchFilter}
        summaryMetrics={this.summaryMetrics}
        custom={this.custom}
        fields={this.fields}
        fieldsToSelect={this.fieldsToSelect}
        query={this.state.query}
        dateRange={this.dateRange}
        startDate={this.startDate}
        endDate={this.endDate}
        onChangeQuery={this.onChangeQuery}
        onChangeDates={this.onChangeDates}
        onResetReport={this.onResetReport}
        onReportUpdate={this.onReportUpdate}
        reportType={this.reportType}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewReport);
