import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import Scrollbars from 'components/scrollbars';
import { MoveInLeft } from 'components/transitions';

const VIEW_DISTANCE = 368;
const VIEW_DURATION = 250;

function SettingsTab(props) {
  return (
    <MoveInLeft
      active={props.active}
      distance={VIEW_DISTANCE}
      duration={VIEW_DURATION}
      className="SettingsMenu-view"
      transitionAppear={props.transitionAppear}
    >
      <div className="SettingsMenu-header">
        <div className="SettingsMenu-title">
          {props.crumbs ? (
            <>
              <div className="SettingsMenu-title-label">{props.title}</div>
              <div className="SettingsMenu-title-crumbs">{props.crumbs}</div>
            </>
          ) : (
            props.title
          )}
        </div>
        <div className="SettingsMenu-back">
          <a
            href=""
            onClick={props.onClickBack}
            data-testid="rtl-settings-tab-back"
          >
            <Icon fa="arrow-left" faType="light" />
          </a>
        </div>
      </div>
      <div className="SettingsMenu-body">
        <Scrollbars>
          <div className="SettingsMenu-body-container">
            <div className="SettingsMenu-body-shadow-cover" />
            <div className="SettingsMenu-sections">{props.children}</div>
          </div>
        </Scrollbars>
      </div>
    </MoveInLeft>
  );
}

SettingsTab.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  crumbs: PropTypes.string,
  onClickBack: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  transitionAppear: PropTypes.bool.isRequired,
};

export default SettingsTab;
