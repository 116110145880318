import React from 'react';
import Icon from './icon.js';
import Tooltip from 'components/tooltip';
import { paymentMethodName, paymentMethodManualIcon } from 'utils/payment';
import { formatPaymentBrand } from 'utils/order';
import './icon.scss';

const CARD_BRANDS = {
  amex: 'amex',
  'American Express': 'amex',
  AMEX: 'amex',
  cirrus: 'cirrus',
  Cirrus: 'cirrus',
  CIRRUS: 'cirrus',
  dinersclub: 'dinersclub',
  'Diners Club': 'dinersclub',
  DINERS: 'dinersclub',
  discover: 'discover',
  Discover: 'discover',
  DISCOVER: 'discover',
  jcb: 'jcb',
  JCB: 'jcb',
  maestro: 'maestro',
  Maestro: 'maestro',
  MAESTRO: 'maestro',
  mastercard: 'mastercard',
  MasterCard: 'mastercard',
  MASTERCARD: 'mastercard',
  visa: 'visa',
  Visa: 'visa',
  VISA: 'visa',
};

const ALT_METHODS = {
  amazon: 'amazon-pay',
  paypal: 'paypal',
};

export default class IconPayment extends React.PureComponent {
  getMethodName() {
    const { method, card, settings } = this.props;
    if (method === 'card' && card && CARD_BRANDS[card.brand]) {
      return `${formatPaymentBrand(card.brand)} ${paymentMethodName(
        method,
        settings,
      ).toLowerCase()}`;
    }
    return paymentMethodName(method, settings);
  }

  renderIcon() {
    const { method, card = {}, settings = {} } = this.props;

    if (method === 'card') {
      return card && CARD_BRANDS[card.brand] ? (
        <Icon
          className="icon-payment-fa"
          fa={`cc-${CARD_BRANDS[card.brand]}`}
          faType="brand"
        />
      ) : (
        <i className="icon icon-payment manual-card" />
      );
    }
    // if (CARD_BRANDS[card.brand]) {
    //   return <i className={`icon icon-payment ${CARD_BRANDS[card.brand]}`} />;
    // }

    if (ALT_METHODS[method]) {
      return (
        <Icon
          className="icon-payment-fa"
          fa={`cc-${ALT_METHODS[method]}`}
          faType="brand"
        />
      );
    }

    if (method === 'swell') {
      return <i className="icon icon-payment swell" />;
    }

    if (method === 'affirm') {
      return <i className="icon icon-payment affirm" />;
    }

    if (method === 'resolve') {
      return <i className="icon icon-payment resolve" />;
    }

    if (method === 'ideal') {
      return <i className="icon icon-payment ideal" />;
    }

    if (method === 'klarna') {
      return <i className="icon icon-payment klarna" />;
    }

    if (method === 'bancontact') {
      return <i className="icon icon-payment bancontact" />;
    }

    if (method === 'google') {
      return <i className="icon icon-payment google" />;
    }

    if (method === 'apple') {
      return (
        <Icon className="icon-payment-fa" fa="cc-apple-pay" faType="brand" />
      );
    }

    if (method === 'twint') {
      return <i className="icon icon-payment twint" />;
    }

    if (method === 'paysafecard') {
      return <i className="icon icon-payment paysafecard" />;
    }

    // Custom manual payment icon
    if (settings && settings.methods) {
      const icon = paymentMethodManualIcon(method, settings);
      if (icon) {
        return <i className={`icon icon-payment manual-${icon}`} />;
      }
    }

    if (method === 'giftcard') {
      return <i className="icon icon-payment manual-gift" />;
    }

    if (method === 'account') {
      return <i className="icon icon-payment manual-check" />;
    }

    if (method === 'bank' || method === 'bank_deposit' || method === 'wire') {
      return <i className="icon icon-payment manual-bank" />;
    }

    // Default to cash
    if (method) {
      return <i className="icon icon-payment manual-cash" />;
    }

    return <i className="icon icon-payment icon-payment-none" />;
  }

  render() {
    const { tooltip = false } = this.props;
    if (tooltip) {
      return (
        <Tooltip message={this.getMethodName()} dir="left" delayed>
          {this.renderIcon()}
        </Tooltip>
      );
    }
    return this.renderIcon();
  }
}
