import PropTypes from 'prop-types';
import React from 'react';
import Field from './field';

export default class Group extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    required: PropTypes.bool,
    className: PropTypes.string,
  };

  render() {
    let { type = 'text', className, ...attrs } = this.props;

    return (
      <div className={`form-group ${className || ''}`}>
        {this.renderByType(type, attrs)}
      </div>
    );
  }

  renderByType(type, attrs) {
    let { label, ...inputAttrs } = attrs;

    switch (type) {
      case 'checkbox':
        // Has own label
        return <Field type="checkbox" {...inputAttrs} />;
      case 'radio':
        // Has own label
        return <Field type="radio" {...inputAttrs} />;
      case 'textarea':
        return (
          <div>
            <label htmlFor={inputAttrs.id}>{label}</label>
            <Field type="textarea" {...inputAttrs} />
          </div>
        );
      case 'select':
        return (
          <div>
            <label htmlFor={inputAttrs.id}>{label}</label>
            <Field type="select" {...inputAttrs} />
          </div>
        );
      case 'select-country':
        return (
          <div>
            <label htmlFor={inputAttrs.id}>{label}</label>
            <Field type="select-country" {...inputAttrs} />
          </div>
        );
      case 'select-state':
        return (
          <div>
            <label htmlFor={inputAttrs.id}>{label}</label>
            <Field type="select-state" {...inputAttrs} />
          </div>
        );
      case 'credit-card':
        return (
          <div>
            <label htmlFor={inputAttrs.id}>{label}</label>
            <Field type="credit-card" {...inputAttrs} />
          </div>
        );
      default:
        return (
          <div>
            <label htmlFor={inputAttrs.id}>{label}</label>
            <Field type={type} {...inputAttrs} />
          </div>
        );
    }
  }
}
