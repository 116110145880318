import csv from './formats/csv';
import json from './formats/json';

const Exporter = {
  csv,
  json,
};

const dataExporter = (format = 'csv') => Exporter[format]; // CSV as a default exporter

export default dataExporter;
