import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { map, get, some } from 'lodash';

import actions from 'actions';
import { putElementInFirstPlace } from 'utils/array';
import { getSortState } from 'utils/report';

import { Chart, Line, XAxis, YAxis, Tooltip, Legend } from 'components/charts';
import Pagination from 'components/collection/pagination';
import { Form, Field } from 'components/form';
import { FadeLoading } from 'components/transitions';
import Scrollbars from 'components/scrollbars';
import CollectionSort from 'components/collection/sort';
import CollectionEmpty from 'components/collection/empty';
import SummaryMetricBox from 'components/reports/collection/summary-metric-box';
import CollectionFilter from 'components/collection/filter';

import CollectionRow from './row';

import './collection.scss';

const mapStateToProps = (state) => ({
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  loadCategories() {
    return dispatch(actions.categories.load());
  },
});

function getInitialSort(props) {
  const { query } = props;
  let sort;
  if (query.sort) {
    const keys = Object.keys(query.sort);
    sort = `${keys[0]} ${query.sort[keys[0]] > 0 ? 'asc' : 'desc'}`;
  }
  return sort;
}

class Collection extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj,
    onChangeDates: PropTypes.func,
    onChangeFields: PropTypes.func,
    summaryMetrics: PropTypes.array,
    search: PropTypes.object,
    searchQuery: PropTypes.string,
    onSubmitSearch: PropTypes.func,
    usingSearchFilter: PropTypes.bool,
    charts: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.array,
        type: PropTypes.string,
        xFormat: PropTypes.func,
        yFormat: PropTypes.func,
        isCurrency: PropTypes.bool,
        title: PropTypes.string,
      }),
    ),
  };

  static defaultProps = {
    query: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      fields: props.fields,
      sort: getInitialSort(props),
      filters: props.search?.filters,
      prev: { query: props.query },
    };
  }

  componentDidMount() {
    const { loadCategories } = this.props;

    loadCategories().then(() => {
      const filters = this.hydrateFilters(this.props);
      this.setState({ filters });
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.query !== state.prev.query) {
      return {
        fields: props.fields,
        sort: getInitialSort(props),
        prev: { query: props.query },
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    const isCollectionChanged =
      location?.pathname !== prevProps.location?.pathname;

    const isQueryChanged = location?.query !== prevProps.location?.query;

    if (isCollectionChanged || isQueryChanged) {
      const filters = this.hydrateFilters(this.props);
      this.setState({ filters });
    }
  }

  hydrateFilters(props) {
    const { location, categories, search } = props;

    if (search?.filters) {
      const filters = { ...search.filters };
      for (const [key, query] of Object.entries(location.query)) {
        const filter = filters[key];
        if (filter?.type === 'LookupCategory' && categories) {
          const category = categories.index.get(query);
          filter.valueLabel = category ? category.name : query;
        }
      }

      return filters;
    } else {
      return null;
    }
  }

  onClickSort = (field) => {
    const { sort } = this.state;

    const newState = getSortState(sort, field);
    this.setState(newState);
    this.props.onClickSort(newState.sort, field.originalPath);
  };

  onChangeDates = ({ startDate, endDate, compareTo }) => {
    this.props.onChangeDates({ startDate, endDate, compareTo });
  };

  onSaveAs = ({ name }) => {
    const { startDate, endDate } = this.props;

    this.props.onSaveAs({
      name,
      min: startDate.format('YYYY-MM-DD'),
      max: endDate.format('YYYY-MM-DD'),
      checkedFields: this.checkedFields,
    });
  };

  onChangeFields = (event, { flat: flattenOptions, nested: nestedOptions }) => {
    // TODO: better way to order fields as in original report
    if (!flattenOptions) {
      return;
    }

    const orderedFields = [];
    let remainingFields = flattenOptions;

    this.props.fields.forEach((field) => {
      let found = false;

      remainingFields = remainingFields.filter((item) => {
        if (!found && item.label === field.label) {
          orderedFields.push(item);
          found = true;
          return false;
        } else {
          return true;
        }
      });
    });

    orderedFields.splice(0, 0, ...remainingFields);

    const fields = putElementInFirstPlace(
      orderedFields,
      (option) => option.main === true,
    );

    this.props.onChangeFields(fields);

    this.checkedFields = nestedOptions;

    this.setState({ fields });
  };

  renderMetricBox(summaryMetric, index) {
    const { label, fieldId } = summaryMetric;
    const { collection } = this.props;
    const { fields } = this.state;
    const { currency } = this.context.client;

    const field = fields.find((f) => f.id === fieldId);
    if (!field) {
      return null;
    }

    const values = collection.results.map((result) => get(result, field.path));

    return (
      <SummaryMetricBox
        key={index}
        label={label}
        field={field}
        values={values}
        currency={currency}
      />
    );
  }

  render() {
    const {
      collection = {},
      charts,
      fieldsToSelect,
      loading,
      onClickPage,
      summaryMetrics,
      search,
      searchQuery,
      onSubmitSearch,
      onChangeSearch,
      onChangeActiveFilters,
      usingSearchFilter,
    } = this.props;

    const { currency } = this.context.client;

    const { sort, fields, filters } = this.state;
    let num = 0;
    let sortField;
    let sortDir;

    if (sort) {
      [sortField, sortDir] = sort.split(' ');
    }

    return (
      <div className="collection-container">
        {charts?.map((chart, index) => {
          const showChart =
            chart &&
            some(chart.data, (line) => get(line, 'points.length') >= 3);

          if (!showChart) {
            return null;
          }

          return (
            <Fragment key={index}>
              {chart.title && (
                <div className="report-chart-title">{chart.title}</div>
              )}

              <div
                className="reports-overview-chart headless"
                key={`chart_${index}`}
              >
                <Chart
                  style={{ width: '100%', height: 200 }}
                  margin={{ top: 20, left: 50, bottom: 20, right: 30 }}
                  xValue="0"
                  yValue="1"
                  chartId={2}
                  data={chart.data}
                  ordinal={/* bar chart needs true here */ false}
                  pointColors={chart.pointColors}
                >
                  <YAxis isCurrency={chart.isCurrency} />

                  <Legend />

                  <XAxis />

                  {/*Bar chart stuff:{map(chart.data, (data, ind) => <Bar key={ind} x="0" y="1" dataId={ind} />)}
              {map(chart.data, (data, ind) => <Tooltip xFormat={chart.xFormat} yFormat={chart.yFormat} dataId={ind} />)}*/}

                  {map(chart.data, (data, ind) => (
                    <Line key={ind} x="0" y="1" dataId={ind} />
                  ))}

                  <Tooltip xFormat={chart.xFormat} yFormat={chart.yFormat} />
                </Chart>
              </div>
            </Fragment>
          );
        })}

        {summaryMetrics && collection.count > 0 && (
          <div className="summary-metric-container">
            {summaryMetrics.map((summaryMetric, index) =>
              this.renderMetricBox(summaryMetric, index),
            )}
          </div>
        )}

        {fieldsToSelect && (usingSearchFilter || collection.count > 0) && (
          <div className="collection-select-panel">
            <Field
              className="edit-columns-select"
              type="multiselect"
              name="fields"
              placeholder="Edit columns"
              options={fieldsToSelect}
              onChange={this.onChangeFields}
            />

            {search && (
              <Form onSubmit={onSubmitSearch} onChange={onChangeSearch}>
                <Field
                  name="search"
                  className="collection-search-field"
                  placeholder={search.placeHolder || ''}
                  defaultValue={undefined}
                  value={searchQuery}
                  autoComplete="off"
                  ref="search"
                />

                {search.filters && (
                  <CollectionFilter
                    {...this.props}
                    filters={filters}
                    onChangeActiveFilters={onChangeActiveFilters}
                  />
                )}
              </Form>
            )}
          </div>
        )}

        <FadeLoading active={loading} className="view-loading-mask" />

        {collection.count > 0 ? (
          <div className="collection-table-container">
            <Scrollbars autoHeight>
              <table className="collection-table">
                <thead>
                  <tr>
                    {map(fields, (field, key) => (
                      <th key={key} className={field.type}>
                        {this.props.onClickSort && field.path ? (
                          <CollectionSort
                            field={key}
                            label={field.label}
                            dir={sortDir}
                            sorted={sort && field.path.includes(sortField)}
                            onClick={this.onClickSort.bind(this, field)}
                          />
                        ) : (
                          field.label
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {collection.summary && (
                    <tr className="collection-summary">
                      {map(fields, (field, key) => (
                        <CollectionRow
                          key={key}
                          id={key}
                          num={num++}
                          record={collection.summary}
                          field={field}
                          currency={currency}
                          summary
                        />
                      ))}
                    </tr>
                  )}

                  {collection.results.map((record, index) => (
                    <tr
                      key={index}
                      className={`${
                        collection.summary
                          ? 'collection-summary-other-rows'
                          : ''
                      }`}
                    >
                      {map(fields, (field, key) => (
                        <CollectionRow
                          key={key}
                          id={key}
                          record={record}
                          field={field}
                          currency={currency}
                        />
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Scrollbars>
          </div>
        ) : (
          <CollectionEmpty
            className="with-query"
            emptyTitle={false}
            emptyDescription={
              this.props.emptyDescription ||
              `There is no ${this.props.model} data to display`
            }
            emptyAction={false}
          />
        )}

        {onClickPage && (
          <Pagination
            collection={collection}
            title="results"
            onClickPage={onClickPage}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
