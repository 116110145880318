import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import { FadeIn } from 'components/transitions';
import DropdownButton from 'components/button/dropdown';
import { currencySymbol } from 'utils';

export default class CurrencyPicker extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object,
  };

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    currency: PropTypes.string,
    pretext: PropTypes.string,
    editable: PropTypes.bool,
  };

  onClickCurrency(event) {
    event.preventDefault();
    const { code } = event.currentTarget.dataset;
    this.props.onChange(code);
  }

  renderCurrent(isEditable) {
    const { currency: currencyCode } = this.props;
    const {
      client: { currency: baseCode },
    } = this.context;

    const currentCode = currencyCode || baseCode;
    const symbol = currencySymbol(currentCode);

    return (
      <Fragment>
        {currentCode} {symbol && symbol !== '?' ? symbol : ''}{' '}
        {isEditable && <Icon fa="sort-down" faType="solid" />}
      </Fragment>
    );
  }

  render() {
    const {
      pretext = '',
      editable = true,
      onChange,
      currency: currencyCode,
    } = this.props;
    const {
      client: { pricedCurrencies, currency: baseCode },
    } = this.context;

    const currentCode = currencyCode || baseCode;
    const isEditable = editable !== false && onChange;

    if (pricedCurrencies.length <= 1 && baseCode === currentCode) {
      return null;
    }

    const currencyList = [...pricedCurrencies];
    if (
      baseCode !== currentCode &&
      !currencyList.find((curr) => curr.code === currentCode)
    ) {
      currencyList.unshift({ code: currentCode });
    }

    return (
      <span className="locale-currency-picker locale-selector">
        {pretext && <span className="locale-currency-pretext">{pretext} </span>}
        {isEditable ? (
          <DropdownButton
            anchor="left"
            anchorPosition={49}
            type="sub"
            clickOff={true}
            contentWidth={96}
            items={currencyList.map((currency) => (
              <div
                onClick={this.onClickCurrency.bind(this)}
                data-code={currency.code}
                className={`locale-select currency ${
                  currency.code !== currentCode ? 'muted' : ''
                }`}
              >
                <div className="locale-check">
                  <FadeIn
                    className="check"
                    transitionAppear={false}
                    active={currency.code === currentCode}
                  >
                    <Icon fa="check" />
                  </FadeIn>
                </div>
                <span className="locale-currency-symbol">{currency.code}</span>{' '}
                {currencySymbol(currency.code)}
              </div>
            ))}
          >
            {this.renderCurrent(isEditable)}
          </DropdownButton>
        ) : (
          this.renderCurrent(isEditable)
        )}
      </span>
    );
  }
}
