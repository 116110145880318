import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { get, set, uniq } from 'lodash';
import moment from 'moment';

import { accountName } from 'utils/account';
import { countryOptions } from 'utils/geo';
import { renderMultiCurrencyValuesTooltip } from 'utils/money';
import { currencyValue, formatCurrency, inflect } from 'utils';

import api from 'services/api';

import actions from 'actions';

import BulkExport from 'components/bulk/export';
import BulkImport from 'components/bulk/import';
import CustomerAvatar from 'components/customer/avatar';
import CollectionLocale from 'components/collection/locale';
import ViewLoading from 'components/view/loading';

import Collection from './Collection';

const query = {
  //
};

const tabs = {
  default: {
    label: 'All customers',
  },
  new: {
    label: 'New',
    query: {
      order_count: { $lt: 2 },
    },
  },
  returning: {
    label: 'Returning',
    query: {
      order_count: { $gt: 1 },
    },
  },
  abandoned: {
    label: 'Abandoned carts',
    query: {
      cart_abandoned_count: { $gt: 0 },
    },
  },
  optin: {
    label: 'Email opt-in',
    query: {
      email_optin: true,
    },
  },
};

const filters = {
  date_created: {
    label: 'Date added',
    operators: ['after', 'before'],
    type: 'date',
  },
  order_count: {
    label: 'Orders placed',
    operators: ['gt', 'lt', 'eq'],
    type: 'number',
  },
  order_value: {
    label: 'Total spent',
    operators: ['gt', 'lt', 'eq'],
    type: 'currency',
  },
  balance: {
    label: 'Account credit',
    operators: ['gt', 'lt', 'eq'],
    type: 'currency',
  },
  // date_first_order: {
  //   label: 'First order',
  //   operators: ['after', 'before'],
  //   type: 'date',
  // },
  date_last_order: {
    label: 'Last order',
    operators: ['after', 'before'],
    type: 'date',
  },
  // date_first_cart_abandoned: {
  //   label: 'First abandoned cart',
  //   operators: ['after', 'before'],
  //   type: 'date',
  // },
  date_last_cart_abandoned: {
    label: 'Last abandoned cart',
    operators: ['after', 'before'],
    type: 'date',
  },
  email_optin: {
    label: 'Email opt-in',
    //desc: 'Email opt-in:',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
    func: (query) => {
      if (query === 'yes') {
        return { email_optin: true };
      } else {
        return { email_optin: { $ne: true } };
      }
    },
  },
  country: {
    label: 'Country',
    desc: false,
    options: countryOptions,
    func: (query) => {
      return {
        $or: [
          {
            'shipping.country': query,
          },
          {
            'billing.country': query,
          },
        ],
      };
    },
  },
};

const fields = {
  name: {
    label: 'Customer',
    sort: ['name'],
    url: '/customers/{id}',
    default: true,
    columns: [
      {
        type: 'image',
        url: '/customers/{id}',
        func: (account) => {
          return <CustomerAvatar account={account} size={45} />;
        },
      },
      {
        label: 'Customer',
        id: 'name',
        path: 'name',
        type: 'link',
        url: '/customers/{id}',
      },
    ],
  },
  date_created: {
    label: 'Since',
    type: 'date',
  },
  email: {
    label: 'Email',
  },
  email_optin: {
    label: 'Opt-in',
    func: (account) => (account.email_optin ? 'Yes' : 'No'),
  },
  order_count: {
    label: 'Orders',
  },
  order_value: {
    label: 'Total spent',
    type: 'currency',
    func: (account) => {
      const multiCurrencyTooltip = renderMultiCurrencyValuesTooltip(
        account.$currency,
        'order_value',
        account.currency,
      );
      return account.order_value > 0 || multiCurrencyTooltip ? (
        <Fragment>
          {formatCurrency(account.order_value, account.currency)}
          {multiCurrencyTooltip}
        </Fragment>
      ) : (
        <span className="muted">&mdash;</span>
      );
    },
  },
  balance: {
    label: 'Credit',
    type: 'currency',
    func: (account) => {
      const multiCurrencyTooltip = renderMultiCurrencyValuesTooltip(
        account.$currency,
        'balance',
        account.currency,
      );
      return account.balance > 0 || multiCurrencyTooltip ? (
        <Fragment>
          {formatCurrency(account.balance, account.currency)}
          {multiCurrencyTooltip}
        </Fragment>
      ) : (
        <span className="muted">&mdash;</span>
      );
    },
  },
};

const exportCSVFields = [
  { key: 'name', label: 'Customer' },
  { key: 'first_name', label: 'First name' },
  { key: 'last_name', label: 'Last name' },
  { key: 'email', label: 'Email' },
  { key: 'email_optin', label: 'Opt-in' },
  { key: 'phone', label: 'Phone' },
  { key: 'type', label: 'Type' },
  { key: 'group', label: 'Group' },
  { key: 'order_count', label: 'Orders' },
  {
    key: 'order_value',
    label: 'Lifetime value',
    export: (customer) =>
      currencyValue(customer.order_value, customer.currency),
  },
  { key: 'order_date', label: 'Last order date' },
  { key: 'cart_abandoned_count', label: 'Abandoned carts' },
  {
    key: 'balance',
    label: 'Credit balance',
    export: (customer) => currencyValue(customer.balance, customer.currency),
  },
  { key: 'notes', label: 'Notes' },
  {
    key: 'billing.name',
    label: 'Billing name',
    export: (account) => get(account, 'billing.name'),
  },
  {
    key: 'billing.address1',
    label: 'Billing address line 1',
    export: (account) => get(account, 'billing.address1'),
  },
  {
    key: 'billing.address2',
    label: 'Billing address line 2',
    export: (account) => get(account, 'billing.address2'),
  },
  {
    key: 'billing.city',
    label: 'Billing city',
    export: (account) => get(account, 'billing.city'),
  },
  {
    key: 'billing.state',
    label: 'Billing state',
    export: (account) => get(account, 'billing.state'),
  },
  {
    key: 'billing.zip',
    label: 'Billing zip',
    export: (account) => get(account, 'billing.zip'),
  },
  {
    key: 'billing.country',
    label: 'Billing country',
    export: (account) => get(account, 'billing.country'),
  },
  {
    key: 'billing.phone',
    label: 'Billing phone',
    export: (account) => get(account, 'billing.phone'),
  },
  {
    key: 'shipping.name',
    label: 'Shipping name',
    export: (account) => get(account, 'shipping.name'),
  },
  {
    key: 'shipping.address1',
    label: 'Shipping address line 1',
    export: (account) => get(account, 'shipping.address1'),
  },
  {
    key: 'shipping.address2',
    label: 'Shipping address line 2',
    export: (account) => get(account, 'shipping.address2'),
  },
  {
    key: 'shipping.city',
    label: 'Shipping city',
    export: (account) => get(account, 'shipping.city'),
  },
  {
    key: 'shipping.state',
    label: 'Shipping state',
    export: (account) => get(account, 'shipping.state'),
  },
  {
    key: 'shipping.zip',
    label: 'Shipping zip',
    export: (account) => get(account, 'shipping.zip'),
  },
  {
    key: 'shipping.country',
    label: 'Shipping country',
    export: (account) => get(account, 'shipping.country'),
  },
  {
    key: 'shipping.phone',
    label: 'Shipping phone',
    export: (account) => get(account, 'shipping.phone'),
  },
  { key: 'date_created', label: 'Date created' },
  { key: 'id', label: 'ID' },
];

/**
 * Registers tabs, fields, filters for customer groups.
 *
 * @param {Array<Object>} groups list of customer groups from settings
 */
function registerCustomerGroups(groups) {
  // Reset all existing group tabs.
  for (const [id, tab] of Object.entries(tabs)) {
    if (tab.isGroup) {
      delete tabs[id];
    }
  }

  // Reset group fields and filters.
  delete fields.group;
  delete filters.group;

  if (Array.isArray(groups) && groups.length > 0) {
    // (Re-)register group tabs.
    for (const group of groups) {
      if (group.id !== 'customers') {
        tabs[group.id] = {
          label: group.name,
          query: {
            group: group.id,
          },
          isGroup: true,
        };
      }
    }

    // Register group field and filter option.
    fields.group = {
      label: 'Group',
      func: (account) =>
        account.group === 'customers'
          ? 'Customers'
          : tabs[account.group]?.label,
    };

    filters.group = {
      label: 'Group',
      options: groups.map((group) => ({
        value: group.id,
        label: group.name,
      })),
    };
  }
}

let EXPORT_CSV_FIELDS = [];

function importCSVFields(data, withFiles = true) {
  return EXPORT_CSV_FIELDS.reduce((acc, field) => {
    const value = get(data, field.key);

    if (field.import && value !== undefined) {
      set(acc, field.key, field.import(value));
    }

    return acc;
  }, {});
}

const mapStateToProps = (state) => ({
  data: state.data,
  bulk: state.data.bulk,
});

const mapDispatchToProps = (dispatch) => ({
  bulkExport: (model, params) => {
    return dispatch(actions.data.bulkExport(model, params));
  },

  bulkImport: (model, params) => {
    return dispatch(actions.data.bulkImport(model, params));
  },

  bulkCancel: () => {
    return dispatch(actions.data.bulkCancel());
  },

  fetchSettings(id) {
    return dispatch(actions.settings.fetch(id));
  },
});

export class Customers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      showingExport: false,
      onClickExport: this.onClickExport.bind(this),
      onClickExportCancel: this.onClickExportCancel.bind(this),
      onClickExportReset: this.onClickExportReset.bind(this),
      onSubmitExport: this.onSubmitExport.bind(this),
      onClickImport: this.onClickImport,
      onSampleImportCount: this.onSampleImportCount,
      onSampleImportIndex: this.onSampleImportIndex,
      onSampleImportData: this.onSampleImportData,
      onSubmitImport: this.onSubmitImport,
    };
  }

  componentDidMount() {
    // Account groups
    this.props
      .fetchSettings('accounts')
      .then((accountSettings) => {
        if (!accountSettings) {
          return;
        }

        const features = accountSettings.features || {};

        if (features.business) {
          filters.type = {
            label: 'Type',
            options: [
              { value: 'individual', label: 'Individual' },
              { value: 'business', label: 'Business' },
            ],
          };
        } else {
          delete filters.type;
        }

        EXPORT_CSV_FIELDS = exportCSVFields;

        registerCustomerGroups(accountSettings.groups);
      })
      .then(() => {
        this.setState({ loaded: true });
      });
  }

  onClickExport(event) {
    event.preventDefault();
    this.setState({ showingExport: !this.state.showingExport });
  }

  onClickExportCancel(event) {
    event.preventDefault();
    if (this.props.bulk.running) {
      this.props.bulkCancel();
    }
    this.setState({ showingExport: false });
  }

  onClickExportReset(event) {
    event.preventDefault();
    this.props.bulkCancel();
  }

  onSubmitExport(values) {
    this.props.bulkExport(`accounts`, {
      ...values,
      filename: `customers`,
      csvFields: exportCSVFields,
    });
  }

  onSampleImportCount = (data, format) => {
    const customers = [];
    const dupes = [];
    let customerCount = 0;

    for (const row of data) {
      customerCount++;
      if (format === 'csv') {
        const id = row.id;
        if (id && customers.indexOf(id) !== -1) {
          dupes.push(id);
        } else {
          customers.push(id);
        }
      }
    }

    return {
      error:
        dupes.length === 0 ? null : (
          <Fragment>
            <p>
              Your import file has <b>{dupes.length}</b> duplicate customer ID
              {inflect(dupes.length, 'values').replace(dupes.length, '')}.
            </p>
            <ul>
              {uniq(dupes).map((id) => (
                <li key={id}>{id}</li>
              ))}
            </ul>
          </Fragment>
        ),
      total: customerCount,
      description: (
        <span>
          You will import <b>{inflect(customerCount, 'customers')}</b>
        </span>
      ),
    };
  };

  /**
   * @param {Array} data
   * @param {number} index
   * @param {boolean} next
   * @returns {number?}
   */
  onSampleImportIndex = (data, index, next) => {
    if (!data[index]) {
      return null;
    }

    let customerIndex = index;

    while (!data[customerIndex]) {
      customerIndex += next ? 1 : -1;
    }

    if (!data[customerIndex]) {
      return null;
    }

    return customerIndex;
  };

  /**
   * @param {Array} data
   * @param {number} index
   * @returns {JSX.Element}
   */
  onSampleImportData = (data, index) => {
    if (!data[index]) {
      return null;
    }

    const customer = {
      ...data[index],
      ...importCSVFields(data[index]),
    };
    const standardFields = EXPORT_CSV_FIELDS.map((field) => {
      return {
        ...field,
        value: (data) => {
          let value;

          if (field.export) {
            value = field.export(customer);

            if (value instanceof Array) {
              value = value[0];
            }
          } else if (field.value) {
            value = field.value;
          }

          return value || data[field.key];
        },
      };
    });

    return (
      <div>
        <table>
          <tbody>
            {standardFields.map((field, i) => (
              <tr key={i}>
                <td className="muted nowrap" align="right">
                  {field.label}
                </td>
                <td>
                  {field.value(customer) || (
                    <span className="muted">&mdash;</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  async importCustomersGroup(url, data, record) {
    const groups = await api.get('/data/settings/accounts/groups');
    const isGroupExist = groups.find((group) => group.id === data.group);

    if (!isGroupExist && data.group) {
      //Create user groups if they not exist
      await api.put('/data/settings/accounts', {
        $set: {
          groups: [
            ...groups,
            {
              id: data.group,
              original_id: data.group,
              edit_id: false,
              name: data.group.split('_').join(' '),
            },
          ],
        },
      });
    }
  }

  onSubmitImport = (values) => {
    const { bulkImport } = this.props;

    bulkImport('accounts', {
      ...values,
      onMessage: (data) => data.name || 'anonymous',
      csvFields: EXPORT_CSV_FIELDS,
      csvTransform: async (data, prev) => {
        let result = {
          orig: data,
          data: {
            ...data,
            date_created: data.date_created || moment().toISOString(),
            // // May be a bug where record is getting set into data
            record: undefined,
            $set: {
              ...importCSVFields(data, false),
            },
          },
        };
        return result;
      },
      idTransform: async (data, prev) => {
        if (data.id) {
          return data.id;
        }
        return null;
      },
      // Before import
      updateTransform: async (record, data, origData = {}) => {
        if (record) {
          for (const key in data) {
            if (data[key] === '' && record[key] === undefined) {
              delete data[key];
            }
          }
          for (const key in data.$set) {
            if (data.$set[key] === '' && record[key] === undefined) {
              delete data.$set[key];
            }
          }
        }
        return data;
      },
      // After import
      recordTransform: async (url, data, record, next) => {
        const [fileErrors] = [
          await this.importCustomersGroup(url, data, record),
        ];
        return [...(fileErrors || [])];
      },
    });
  };

  onClickImport = (event) => {
    event.preventDefault();
    const { bulkCancel } = this.props;

    this.setState((prevState) => {
      if (prevState.showImport) {
        bulkCancel();
      }
      return {
        showImport: !prevState.showImport,
      };
    });
  };

  headerActions = [
    {
      label: 'Export',
      fa: 'arrow-to-bottom',
      type: 'sub',
      onClick: this.onClickExport.bind(this),
    },
    {
      label: 'Import',
      showAlways: true,
      fa: 'arrow-from-bottom',
      type: 'sub',
      onClick: this.onClickImport,
    },
    { label: 'New customer', link: '/customers/new' },
  ];

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return (
      <div className="customer-list">
        <Collection
          {...this.props}
          title="Customers"
          uri="/customers"
          model="accounts"
          emptyTitle="Create and manage customers"
          emptyDescription="Customer accounts are added to this section when an order is placed. You may also create new accounts manually."
          tabs={tabs}
          filters={filters}
          fields={fields}
          headerActions={this.headerActions}
          query={query}
          queryCurrency={true}
        />
        {this.state.showingExport && (
          <BulkExport label="Customers" {...this.props} {...this.state} />
        )}
        {this.state.showImport && (
          <BulkImport
            label="Customers"
            csvFields={EXPORT_CSV_FIELDS}
            {...this.props}
            {...this.state}
            sampleFileCSV="/admin/public/customer-import-sample.csv"
            sampleFileJSON=""
            selectable={true}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
