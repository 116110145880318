export function scrollToAnchor() {
  const hash = window.location.hash.slice(1);

  if (hash) {
    setTimeout(() => {
      const elem = document.getElementById(hash)

      elem?.scrollIntoView({ behavior: 'smooth' });
    }, 10);
  }
}
