import React from 'react';
import pt from 'prop-types';
import { get, set, pick, find, findIndex } from 'lodash';

import {
  subscriptionPriceDesc,
  subscriptionHasOrderItems,
} from 'utils/subscription';

import { confirmRouteLeave, confirmPageLeave } from 'utils/container';
import { hasLocalizedContent } from 'utils/content';
import { getVariantByOptions } from 'utils/product';
import { getCleanItemOptions } from 'utils/order';

import { View } from 'components/view';
import Side from 'components/view/side';
import OrderItemsAdd from 'components/pages/order/items-add';
import OrderItemsEdit from 'components/pages/order/items-edit';
import OrderCustomer from 'components/pages/order/customer';
import OrderCustomerEdit from 'components/pages/order/customer-edit';
import OrderDiscountsEdit from 'components/pages/order/discounts-edit';
import OrderShippingEdit from 'components/pages/order/shipping-edit';
import OrderPaymentEdit from 'components/pages/order/payment-edit';
import OrderAddressEdit from 'components/pages/order/address-edit';
import OrderContent from 'components/pages/order/content';
import CurrencyPicker from 'components/locale/currency';
import ManageAddresses from 'components/manage-addresses';

import SubscriptionItems from './items';
import SubscriptionPayments from './payments';
import SubscriptionPlanEdit from './plan-edit';
import SubscriptionItemsEdit from './items-edit';

import './subscription.scss';

export default class SubscriptionDraft extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    related: pt.object,
    loading: pt.bool,
    lookup: pt.object,
    edited: pt.bool,
    content: pt.object,
    settings: pt.object,
    discounts: pt.object,
    suggestedAddresses: pt.array,

    fetchRecord: pt.func,
    onActivateSubscription: pt.func,
    onShippingGetRates: pt.func,
    onChangeCurrency: pt.func,
    onPaymentEdit: pt.func,
    getItemPrice: pt.func,
    onEditItems: pt.func,
    onAddItem: pt.func,
    onEdited: pt.func,
    onEditValues: pt.func,
    onDelete: pt.func,
    onLoadAddresses: pt.func,
    onLoadBilling: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
    notifyError: pt.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      edited: false,
      showAddItem: false,
      showEditItems: false,
      showingEditPlan: false,
      showingEditCustomer: false,
      showingDiscountsEdit: false,
      showingPaymentEdit: false,
      showingShippingEdit: false,
      showManageAddresses: false,
      addItemProduct: null,
      editAddItem: null,
      editAddIndex: null,
      editItems: [],
      modalLoading: false,
      showEditAddress: false,
    };
    this.onClickManageAddresses = this.onClickManageAddresses.bind(this);
  }

  componentDidMount() {
    confirmRouteLeave(this);
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  onClickDelete = (event) => {
    event.preventDefault();

    const { record } = this.props;

    this.context.openModal('ConfirmDelete', {
      title: `draft ${record.number}`,
      onConfirm: () => {
        this.props.onDelete();
      },
    });
  };

  onClickAddItem = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.state.showAddItem && this.props.edited) {
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.props.onEdited(this.state.editAddEdited);
          this.setState({ showAddItem: false });
        },
      });
    } else {
      this.setState((state, props) => ({
        showAddItem: !state.showAddItem,
        addItemProduct: null,
        editAddItem: null,
        editAddIndex: null,
        editAddEdited: state.showEditItems ? props.edited : false,
      }));
    }
  };

  onClickEditItems = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showEditItems: !state.showEditItems,
      editItems: (this.props.record.items || []).map((item) => ({
        ...item,
      })),
    }));
  };

  onClickEditSingleItem = (event) => {
    event.preventDefault();

    const { id, index } = event.currentTarget.dataset;

    this.setState((state, props) => {
      const editItems = state.showEditItems
        ? state.editItems
        : props.record.items || [];

      return {
        showAddItem: true,
        addItemProduct: null,
        editAddItem: id
          ? find(editItems, (item) => item.id === id)
          : editItems[index],
        editAddIndex: id ? null : index,
      };
    });
  };

  onCloseEditItems = (event) => {
    if (this.props.edited) {
      event.preventDefault();

      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.onClickEditItems(event);
        },
      });
    } else {
      this.onClickEditItems(event);
    }
  };

  onClickEditPlan = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState((state) => ({
      showingEditPlan: !state.showingEditPlan,
      addItemProduct: null,
      editAddItem: null,
      editAddIndex: null,
    }));
  };

  onCloseEditPlan = (event) => {
    if (this.props.edited) {
      if (event) {
        event.preventDefault();
      }

      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.onClickEditPlan(event);
        },
      });
    } else {
      this.onClickEditPlan(event);
    }
  };

  onClickEditCustomer = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showingEditCustomer: !state.showingEditCustomer,
    }));
  };

  onClickRemoveCustomer = (event) => {
    event.preventDefault();

    return this.props.onEditValues({
      account_id: null,
      billing: null,
      shipping: null,
    });
  };

  onClickDiscountsEdit = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showingDiscountsEdit: !state.showingDiscountsEdit,
    }));
  };

  onClickPaymentEdit = async (event) => {
    event.preventDefault();

    if (!this.state.showingPaymentEdit) {
      await this.props.onLoadBilling();
    }

    this.setState((state) => ({
      showingPaymentEdit: !state.showingPaymentEdit,
    }));
  };

  onChangeAddProductLookup = (event, value) => {
    this.setState({
      addItemProduct: value || null,
    });
  };

  async updateStateModalLoading(handler, state = {}) {
    this.setState({ modalLoading: true });
    const result = await handler();
    if (result) {
      this.setState({
        ...state,
        modalLoading: false,
      });
    } else {
      this.setState({ modalLoading: false });
    }
  }

  onSubmitAddItem = async (values) => {
    const { addItemProduct, editAddItem, editAddIndex } = this.state;
    const itemProduct = editAddItem ? editAddItem.product : addItemProduct;

    const addItem = {
      ...values,
      quantity: values.quantity || 1,
      product: itemProduct,
      product_id: itemProduct.id,
      bundle_items: itemProduct.bundle_items,
      options: getCleanItemOptions(itemProduct, values.options),
    };

    if (values.bundle_options) {
      addItem.bundle_items = itemProduct.bundle_items.map((item) => ({
        ...item,
        options: getCleanItemOptions(
          item.product,
          values.bundle_options[item.product_id],
          item.options,
        ),
      }));
    }

    if (addItem.set_price === null) {
      this.setState({ modalLoading: true });

      const pricedItem = await this.props.getItemPrice(
        pick(addItem, [
          'id',
          'product_id',
          'options',
          'quantity',
          'purchase_option',
        ]),
      );

      if (pricedItem && !pricedItem.errors) {
        addItem.price = pricedItem.price;
        if (!this.state.showEditItems) {
          addItem.set_price = pricedItem.price;
        }
      }
    } else {
      addItem.price = addItem.set_price;
    }

    if (this.state.showEditItems) {
      addItem.variant = getVariantByOptions(addItem.product, addItem.options);

      const editItems = [...this.state.editItems];

      if (addItem.id) {
        const editIndex = findIndex(editItems, { id: addItem.id });
        editItems[editIndex] = { ...editItems[editIndex], ...addItem };
      } else if (editAddIndex !== null) {
        editItems[editAddIndex] = addItem;
      } else {
        editItems.push(addItem);
      }

      this.props.onEdited(true);

      this.setState({
        showAddItem: false,
        addItemProduct: null,
        editItems,
        modalLoading: false,
      });
    } else {
      this.updateStateModalLoading(() => this.props.onAddItem(addItem), {
        showAddItem: false,
        addItemProduct: null,
      });
    }
  };

  onSubmitEditItems = async (values) => {
    const result = await this.props.onEditItems(this.state.editItems);

    if (result) {
      this.setState({
        showEditItems: false,
        editItems: [],
      });
    }
  };

  onSubmitEditPlan = async (values) => {
    const result = await this.props.onEditValues({
      billing_schedule: '',
      order_schedule: '',
      ...values,
      ...values.purchase_option,
      options: undefined,
      product: undefined,
      variant: undefined,
      set_price: undefined,
      purchase_option: undefined,
      price: values.set_price,
      product_id: values.product.id,
      $set: {
        options: values.options,
      },
    });

    if (result) {
      this.setState({
        showingEditPlan: false,
        addItemProduct: null,
      });
    }
  };

  onSubmitEditCustomer = async (values) => {
    if (values.account.id) {
      await this.props.onEditValues({
        account_id: values.account.id,
        shipping: values.account?.shipping ? values.account.shipping : null, // Reset shipping
        billing: values.account?.billing ? values.account.billing : null, // Reset billing
      });
    } else {
      await this.props.onEditValues({
        account: values.account,
        shipping: values.shipping,
        billing: values.billing,
      });
    }

    this.setState({
      showingEditCustomer: false,
    });
  };

  onSubmitDiscountsEdit = async (values) => {
    const result = await this.props.onEditValues({
      coupon_code: values.coupon_code,
    });

    if (result) {
      this.setState({
        showingDiscountsEdit: false,
      });
    }
  };

  onSubmitPaymentEdit = async (values) => {
    const result = await this.props.onPaymentEdit({
      billing: values.billing,
    });

    if (result) {
      this.setState({
        showingPaymentEdit: false,
      });
    }
  };

  onClickRemoveEditItem = (event) => {
    event.preventDefault();

    const { index } = event.currentTarget.dataset;
    const editItems = [...this.state.editItems];

    if (editItems[index].id || editItems[index].removed) {
      editItems[index].removed = !editItems[index].removed;
    } else {
      editItems.splice(index, 1);
    }

    this.props.onEdited(true);

    this.setState({ editItems });
  };

  onClickRemoveEditSingleItem = (event) => {
    if (event) {
      event.preventDefault();
    }

    const { id, index } = event.currentTarget.dataset;

    if (this.state.showEditItems) {
      const editItems = [...this.state.editItems];

      if (id) {
        editItems[findIndex(editItems, { id })].removed = true;
      } else {
        editItems.splice(index, 1);
      }

      this.props.onEdited(true);

      this.setState({
        showAddItem: false,
        editItems,
      });
    } else {
      this.context.openModal('Confirm', {
        title: 'Remove item',
        message: (
          <p>
            Are you sure you want to remove this item? This action can&apos;t be
            undone.
          </p>
        ),
        action: 'Remove',
        actionType: 'danger',
        onConfirm: () => {
          const removedItem = { ...this.state.editAddItem, removed: true };

          this.updateStateModalLoading(
            () => this.props.onEditItems([removedItem]),
            {
              showAddItem: false,
            },
          );
        },
      });
    }
  };

  onChangeEditItemInput = (event, value) => {
    const editItems = [...this.state.editItems];
    const [index, fieldName] = event.target.name.split('.');
    const editItem = editItems[index];

    set(editItems, event.target.name, value);

    if (editItem.quantity <= 0 && editItem.quantity.trim() !== '') {
      editItem.quantity = '0';
      editItem.removed = true;
      editItem.calc_price = undefined;
    } else if (fieldName === 'quantity') {
      const now = Date.now();

      editItem.pricingTime = now;

      clearTimeout(this.editItemPriceTimer);

      this.editItemPriceTimer = setTimeout(() => {
        this.props
          .getItemPrice(
            pick(editItem, [
              'id',
              'product_id',
              'options',
              'quantity',
              'purchase_option',
            ]),
          )
          .then((pricedItem) => {
            if (this.state.showEditItems && pricedItem && !pricedItem.errors) {
              const postEditItems = [...this.state.editItems];
              if (get(postEditItems[index], 'pricingTime') === now) {
                postEditItems[index].calc_price = pricedItem.price;
                this.setState({ editItems: postEditItems });
              }
            }
          });
      }, 250);
    }

    this.setState({ editItems });
  };

  onClickShippingEdit = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showShippingEdit: !state.showShippingEdit,
    }));
  };

  onSubmitShippingEdit = async (values) => {
    const result = await this.props.onEditValues({
      shipping: values.shipping,
    });

    if (result) {
      this.setState({
        showShippingEdit: false,
      });
    }
  };

  onClickShippingGetRates = (event) => {
    event.preventDefault();

    this.props.onShippingGetRates();
  };

  onClickActivateSubscription = (event) => {
    event.preventDefault();

    this.props.onActivateSubscription();
  };

  onClickEditAddress = (event) => {
    event.preventDefault();
    this.setState((state) => ({
      showEditAddress: !state.showEditAddress,
    }));
  };

  onSubmitEditAddress = (values) => {
    this.setState((state) => ({
      showEditAddress: !state.showEditAddress,
    }));
    if (values.account.id) {
      return this.props.onEditValues({
        account_id: values.account.id,
        shipping: values.account.shipping,
        billing: values.account.billing,
        account_info_saved: true,
      });
    } else {
      return this.props.onEditValues({
        account: values.account,
        shipping: values.shipping,
        billing: values.billing,
        account_info_saved: true,
      });
    }
  };

  async onClickManageAddresses(event) {
    event?.preventDefault();

    if (!this.state.showManageAddresses) {
      await this.props.onLoadAddresses();
    }

    this.setState({
      showManageAddresses: !this.state.showManageAddresses,
    });
  }

  render() {
    const {
      record,
      related,
      lookup,
      content,
      settings,
      loading,
      suggestedAddresses,
      onChangeCurrency,
      fetchRecord,
    } = this.props;

    const isDeletable = Boolean(record.id);

    const hasShipmentItems =
      record.product && subscriptionHasOrderItems(record, record.product);

    const isConverted = record && record.draft === false;

    const isComplete =
      record && !isConverted && !!record.account_id && !!record.product_id;

    return (
      <div className="subscription-view order-view view-detail-full">
        <View
          detail={true}
          uri="/subscriptions"
          devtools={{
            model: 'subscriptions',
            record,
          }}
          sectionTitle="Subscriptions"
          headerTitle={
            record.number ? `Draft ${record.number}` : 'New subscription'
          }
          headerSubtitle={
            <div className="subscription-view-subtitle">
              {subscriptionPriceDesc(record)}

              <CurrencyPicker
                pretext="Prices in"
                currency={record.currency}
                onChange={onChangeCurrency}
                editable={!isConverted}
              />
            </div>
          }
          headerActions={[
            isConverted && {
              label: `Subscription ${get(record, 'number', record.id)}`,
              fa: 'clipboard-check',
              type: 'sub',
              link: `/subscriptions/${record.id}`,
            },
            isComplete && {
              label: 'Activate subscription',
              type: 'default',
              onClick: this.onClickActivateSubscription,
            },
          ]}
          extraActions={[
            isDeletable && {
              label: 'Delete draft',
              onClick: this.onClickDelete,
              className: 'danger',
              delete: true,
            },
          ]}
          localized={hasLocalizedContent(
            this.props.content.models,
            'subscriptions',
          )}
        >
          <fieldset className="last-child">
            <SubscriptionItems
              record={record}
              discounts={this.props.discounts}
              editable={!isConverted}
              loading={loading}
              onClickEditPlan={this.onClickEditPlan}
              onClickEditItems={this.onClickEditItems}
              onClickEditSingleItem={
                isConverted ? undefined : this.onClickEditSingleItem
              }
              onClickAddItem={this.onClickAddItem}
              onClickDiscountsEdit={this.onClickDiscountsEdit}
              onClickShippingEdit={this.onClickShippingEdit}
              hasShipmentItems={hasShipmentItems}
            />

            <SubscriptionPayments
              record={record}
              settings={settings}
              loading={loading}
              editable={!isConverted}
            />

            <OrderContent
              {...this.props}
              zone="content"
              collection="subscriptions"
              record={record}
              onSubmit={this.props.onEditValues}
            />
          </fieldset>

          <Side>
            <OrderCustomer
              isSubscription
              record={record}
              content={content}
              settings={settings}
              editable={!isConverted && !!record.account_id}
              removable={!isConverted && !!record.account_id}
              onClickEditCustomer={this.onClickEditCustomer}
              onClickRemoveCustomer={this.onClickRemoveCustomer}
              onClickPaymentEdit={this.onClickPaymentEdit}
              onClickEditAddress={this.onClickEditAddress}
              onClickManageAddresses={this.onClickManageAddresses}
            />
          </Side>
        </View>

        {this.state.showingEditPlan && (
          <SubscriptionPlanEdit
            record={record}
            lookup={lookup}
            loading={loading}
            onEdited={this.props.onEdited}
            addItemProduct={this.state.addItemProduct}
            onSubmitEditPlan={this.onSubmitEditPlan}
            onClickEditPlan={this.onClickEditPlan}
            onCloseEditPlan={this.onCloseEditPlan}
            onChangeAddProductLookup={this.onChangeAddProductLookup}
            getItemPrice={this.props.getItemPrice}
          />
        )}

        {this.state.showEditItems && (
          <SubscriptionItemsEdit
            record={record}
            loading={loading}
            editItems={this.state.editItems}
            onEdited={this.props.onEdited}
            onSubmitEditItems={this.onSubmitEditItems}
            onClickEditItems={this.onClickEditItems}
            onClickRemoveEditItem={this.onClickRemoveEditItem}
            onClickAddItem={this.onClickAddItem}
            onCloseEditItems={this.onCloseEditItems}
            onChangeEditItemInput={this.onChangeEditItemInput}
            onClickShippingEdit={this.onClickShippingEdit}
          />
        )}

        {this.state.showEditItems && (
          <OrderItemsEdit
            subscription={true}
            record={record}
            loading={loading}
            editItems={this.state.editItems}
            onEdited={this.props.onEdited}
            onSubmitEditItems={this.onSubmitEditItems}
            onClickEditItems={this.onClickEditItems}
            onClickEditSingleItem={this.onClickEditSingleItem}
            onClickRemoveEditItem={this.onClickRemoveEditItem}
            onClickAddItem={this.onClickAddItem}
            onCloseEditItems={this.onCloseEditItems}
            onChangeEditItemInput={this.onChangeEditItemInput}
            getItemPrice={this.props.getItemPrice}
          />
        )}

        {this.state.showAddItem && (
          <OrderItemsAdd
            subscription={true}
            lookup={lookup}
            loading={loading}
            addItemProduct={this.state.addItemProduct}
            onSubmitAddItem={this.onSubmitAddItem}
            onClickAddItem={this.onClickAddItem}
            onChangeAddProductLookup={this.onChangeAddProductLookup}
            onClickRemoveEditSingleItem={this.onClickRemoveEditSingleItem}
            getItemPrice={this.props.getItemPrice}
            editAddItem={this.state.editAddItem}
            editAddIndex={this.state.editAddIndex}
            onEdited={this.state.editAddItem && this.props.onEdited}
            currency={record.currency}
          />
        )}

        {this.state.showingEditCustomer && (
          <OrderCustomerEdit
            record={record}
            loading={loading}
            lookup={lookup}
            settings={settings}
            billing={record.billing}
            shipping={record.shipping}
            showUseDefaultAddressCheckbox
            onSubmitEditCustomer={this.onSubmitEditCustomer}
            onClickEditCustomer={this.onClickEditCustomer}
            hideShipping={!hasShipmentItems}
            suggestedAddresses={suggestedAddresses}
          />
        )}

        {this.state.showingDiscountsEdit && (
          <OrderDiscountsEdit
            record={record}
            loading={loading}
            lookup={lookup}
            discounts={this.props.discounts}
            promotions={false}
            onSubmitDiscountsEdit={this.onSubmitDiscountsEdit}
            onClickDiscountsEdit={this.onClickDiscountsEdit}
          />
        )}

        {this.state.showShippingEdit && (
          <OrderShippingEdit
            isSubscription
            record={record}
            loading={loading}
            settings={settings}
            onSubmitShippingEdit={this.onSubmitShippingEdit}
            onClickShippingEdit={this.onClickShippingEdit}
            onClickShippingGetRates={this.onClickShippingGetRates}
          />
        )}

        {this.state.showingPaymentEdit && (
          <OrderPaymentEdit
            loading={loading}
            fetchRecord={fetchRecord}
            onSubmitPaymentEdit={this.onSubmitPaymentEdit}
            onClickPaymentEdit={this.onClickPaymentEdit}
          />
        )}

        {this.state.showEditAddress && (
          <OrderAddressEdit
            record={record}
            loading={loading}
            shipping={record.shipping}
            onSubmitEditAddress={this.onSubmitEditAddress}
            onClickEditAddress={this.onClickEditAddress}
            onClickEditShipping={this.onClickShippingEdit}
            suggestedAddresses={suggestedAddresses}
          />
        )}

        {this.state.showManageAddresses && (
          <ManageAddresses
            fetchRecord={fetchRecord}
            loading={loading}
            onClickManageAddresses={this.onClickManageAddresses}
          />
        )}
      </div>
    );
  }
}
