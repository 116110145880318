import React from 'react';
import Button from 'components/button';
import ButtonLink from 'components/button/link';
import { singularize } from 'utils';
import './collection.scss';

const VOWELS = ['a', 'e', 'i', 'o', 'u'];

export default class CollectionEmpty extends React.PureComponent {
  getDefaultActionLabel(title) {
    const object = singularize(title).toLowerCase();
    const article = VOWELS.includes(object[0].toLowerCase()) ? 'an' : 'a';
    return `Create ${article} ${object}`;
  }

  render() {
    const {
      uri,
      title = 'things',
      emptyTitle,
      emptyAction,
      emptyActionLink,
      emptyActionTarget,
      emptyActionSize = 'md',
      emptyDescription,
      emptySecondAction,
      emptySecondActionLink,
      emptySecondActionTarget,
      renderEmpty,
      onClickAction,
      className,
    } = this.props;

    if (renderEmpty) {
      return renderEmpty(this.props);
    }

    const actionLabel = emptyAction
      ? emptyAction
      : this.getDefaultActionLabel(title);

    return (
      <div className={`collection-empty ${className || ''}`}>
        <div className="collection-empty-container">
          <div className="collection-empty-inner">
            {emptyTitle !== false && (
              <h2>
                {emptyTitle ||
                  (emptyDescription
                    ? `Create your first ${singularize(title).toLowerCase()}`
                    : `No ${title.toLowerCase()} yet`)}
              </h2>
            )}
            {emptyDescription !== false && (
              <p>
                {emptyDescription ||
                  `Get started by adding your first ${singularize(
                    title,
                  ).toLowerCase()}`}
              </p>
            )}
            {emptyAction !== false && (
              <div className="collection-empty-action">
                {onClickAction ? (
                  <Button onClick={onClickAction} size={emptyActionSize}>
                    {actionLabel}
                  </Button>
                ) : (
                  <ButtonLink
                    to={emptyActionLink ? emptyActionLink : `${uri}/new`}
                    target={emptyActionTarget}
                    size={emptyActionSize}
                  >
                    {actionLabel}
                  </ButtonLink>
                )}
                {emptySecondAction && (
                  <ButtonLink
                    to={emptySecondActionLink}
                    target={emptySecondActionTarget}
                    type="secondary"
                    size={emptyActionSize}
                  >
                    {emptySecondAction}
                  </ButtonLink>
                )}
              </div>
            )}
          </div>
          <div className="collection-empty-image"></div>
        </div>
      </div>
    );
  }
}
