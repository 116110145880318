// @flow
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { discountUpdatesFromValues } from 'utils/discount';

import actions from 'actions';

import NewPage from 'components/promotion/new';
import ViewLoading from 'components/view/loading';

export const mapStateToProps = (state) => ({
  data: state.data,
  loading: state.data.loading,
  errors: state.data.recordErrors,
  categories: state.categories,
  content: state.content,
  lookup: state.lookup,
  settings: state.settings,
});

export const mapDispatchToProps = (dispatch: Function) => ({
  searchCategories: async (search: string) => {
    return dispatch(actions.categories.search(search, 10));
  },

  createRecord: async (data: Object) => {
    return dispatch(actions.data.createRecord('promotions', data));
  },

  loadSettings() {
    return Promise.all([
      dispatch(actions.settings.fetch('discounts')),
      dispatch(actions.settings.fetch('shipments')),
      dispatch(actions.settings.fetch('accounts')),
    ]);
  },
});

export class NewPromotion extends React.Component {
  static contextTypes = {
    notifyCreated: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      values: {},
      edited: false,
      onChangeForm: this.onChangeForm.bind(this),
      onSubmitRecord: this.onSubmitRecord.bind(this),
      onQueryCategories: this.onQueryCategories.bind(this),
    };
  }

  componentDidMount() {
    this.props.loadSettings().then(() => {
      this.setState({ loaded: true });
    });
  }

  onQueryCategories(value: string) {
    this.props.searchCategories(value);
  }

  onChangeForm(values: Object, edited: boolean) {
    this.setState((state) => ({
      values: { ...state.values, ...values },
      edited,
    }));
  }

  onSubmitRecord(values: Object) {
    const { router, createRecord } = this.props;

    const { client } = this.context;

    return createRecord({
      ...values,
      active: true,
      ...discountUpdatesFromValues(values, {}, client),
    }).then((result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        router.replace(`/promotions`);
        this.context.notifyCreated(result);
      }
    });
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return <NewPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPromotion);
