import React from 'react';
import { connect } from 'react-redux';
import { get, set, find, filter, uniq, isEqual } from 'lodash';
import ObjectID from 'bson-objectid';
import PropTypes from 'prop-types';

import {
  inflect,
  imageUrl,
  slugify,
  isEmpty,
  getClientLocaleAndCurrencyParams,
} from 'utils';
import { dataExporter, dataImporter } from 'utils/data';

import api from 'services/api';

import { replaceLocationQuery } from 'actions/data';
import actions from 'actions';

import Status from 'components/status';
import BulkExport from 'components/bulk/export';
import BulkImport from 'components/bulk/import';
import ProductPrice from 'components/pages/product/price';
import ViewLoading from 'components/view/loading';

import Collection from './Collection';

let ATTRIBUTES = {};
let CATEGORIES = {};
let EXPORT_CSV_FIELDS = [];
const RENDER_RAW_DATA = new Set(['bundle_items', 'options']);

const query = {
  expand: [],
  include: {
    variant_with_image: {
      url: '/products:variants/:first',
      params: {
        parent_id: 'id',
      },
      data: {
        images: { $exists: true },
        fields: 'images',
      },
    },
  },
};

export const tabs = {
  default: {
    label: 'All products',
  },
  active: {
    label: 'Active',
    query: {
      active: true,
    },
  },
  inactive: {
    label: 'Inactive',
    query: {
      active: false,
    },
  },
  bundles: {
    label: 'Bundles',
    query: {
      bundle: true,
    },
  },
  out_of_stock: {
    label: 'Out of stock',
    query: {
      stock_tracking: true,
      $or: [{ stock_level: { $lte: 0 } }, { stock_level: { $exists: false } }],
    },
  },
};

export const filters = {
  type: {
    label: 'Product type',
    options: [
      { value: 'physical', label: 'Physical' },
      { value: 'digital', label: 'Digital' },
      { value: 'bundle', label: 'Bundle' },
      { value: 'giftcard', label: 'Gift card' },
      { value: null, label: 'None' },
    ],
    func(query) {
      return { type: query };
    },
  },
  purchase_option: {
    label: 'Purchase option',
    options: [
      { value: 'standard', label: 'Standard' },
      { value: 'subscription', label: 'Subscription' },
      { value: 'trial', label: 'Trial' },
    ],
    func(query) {
      const poQuery = { [`purchase_options.${query}`]: { $exists: true } };

      switch (query) {
        case 'standard':
          return {
            $or: [
              poQuery,
              {
                purchase_options: { $exists: false },
                delivery: 'shipment',
              },
            ],
          };

        case 'subscription':
          return { $or: [poQuery, { delivery: 'subscription' }] };

        default:
          break;
      }

      return poQuery;
    },
  },
  price: {
    label: 'Price',
    operators: ['gt', 'lt', 'eq'],
    type: 'currency',
  },
  stock: {
    label: 'Stock availability',
    options: [
      { value: 'in', label: 'In stock' },
      { value: 'out', label: 'Out of stock' },
      { value: 'low', label: 'Low stock' },
    ],
    func(query) {
      switch (query) {
        case 'in':
          return { stock_level: { $gt: 0 } };

        case 'out':
          return {
            $or: [
              { stock_level: { $lte: 0 } },
              { stock_level: { $exists: false } },
            ],
            stock_tracking: true,
          };
        case 'low':
          return {
            $or: [
              { stock_level: { $lte: 3 } },
              { stock_level: { $exists: false } },
            ],
            stock_tracking: true,
          };

        default:
          break;
      }
    },
  },
  category: {
    label: 'Category',
    desc: 'Category',
    type: 'LookupCategory',
    query: true,
    func(query) {
      if (typeof query === 'string') {
        return { categories: query };
      }
    },
  },
  tags: {
    label: 'Tags',
    desc: 'Tagged with',
    type: 'tags',
  },
};

export const fields = {
  name: {
    label: 'Product',
    sort: ['name'],
    url: '/products/{id}',
    default: true,
    columns: [
      {
        type: 'image',
        func(product) {
          const image = get(
            product,
            'images[0]',
            get(product, 'variant_with_image.images[0]'),
          );
          return (
            image && (
              <img
                title={image.caption || ''}
                alt={image.caption || ''}
                src={imageUrl(image, {
                  width: 90,
                  height: 90,
                  padded: true,
                })}
              />
            )
          );
        },
      },
      {
        label: 'Product',
        id: 'name',
        path: 'name',
        type: 'link',
        url: '/products/{id}',
      },
      {
        id: 'type',
        func(product) {
          switch (product.delivery) {
            case 'giftcard':
              return 'Gift card';

            default: {
              if (product.bundle) {
                return 'Bundle';
              }

              return ' ';
            }
          }
        },
      },
    ],
  },
  status: {
    label: 'Status',
    sort: false,
    func: (product) => {
      return (
        <Status type={product.active ? 'positive' : 'muted'}>
          {product.active ? 'Active' : 'Inactive'}
        </Status>
      );
    },
  },
  date_updated: {
    label: 'Updated',
    type: 'date',
  },
  stock_level: {
    label: 'Stock',
    sort: false,
    func: (product) => {
      return product.stock_tracking ? (
        <span className={product.stock_level > 0 ? '' : 'muted'}>
          {product.stock_level || 0}
        </span>
      ) : (
        <span className="muted">&mdash;</span>
      );
    },
  },
  price: {
    label: 'Price',
    type: 'currency',
    func: (product) => <ProductPrice product={product} />,
  },
};

const bulkActions = [
  {
    modal: 'BulkEditProducts',
    label: 'Edit Products',
  },
  {
    modal: 'BulkCategoriesAdd',
    label: 'Add to categories',
    type: 'secondary',
  },
  {
    modal: 'BulkCategoriesRemove',
    label: 'Remove from categories',
    type: 'secondary',
  },
  {
    modal: 'BulkDelete',
    label: 'Delete',
    type: 'danger inverse',
    // condition: (selection) => !selection.all || Object.keys(selection.except).length > 0,
  },
];

const exportCSVFields = () => [
  { key: 'name', label: 'Name' },
  {
    key: 'variant_name',
    label: 'Variant Name',
    export: (product) =>
      product.variants.results.reduce(
        (rows, variant) => rows.concat(variant.name),
        [''],
      ),
  },
  {
    key: 'sku',
    label: 'SKU',
    export: (product, format) => dataExporter(format).exportSku(product),
  },
  { key: 'slug', label: 'Slug', import: (slug) => slugify(slug) },
  {
    key: 'active',
    label: 'Active',
    export: (product, format) => dataExporter(format).exportActive(product),
  },
  {
    key: 'stock_level',
    label: 'Stock Level',
    export: (product, format) => dataExporter(format).exportStockLevel(product),
  },
  {
    key: 'variant_options',
    label: 'Variant Options',
    export: (product, format) =>
      dataExporter(format).exportVariantOptions(product),
  },
  {
    key: 'options',
    label: 'Options',
    export: (product, format) =>
      dataExporter(format).exportProductOptions(product),
    import: (data, format) => dataImporter(format).importProductOptions(data),
  },
  {
    key: 'custom_options',
    label: 'Custom Options',
    // Should be deprecated
    // We only use this for CSV, and it only works with select options (but we have different types: "select", "toggle", "long_text", "short_text").
    // export: (product) => product.custom_options || exportCustomOptions(product),
    import: (data) => data,
  },
  { key: 'type', label: 'Type' },
  { key: 'delivery', label: 'Delivery' },
  {
    key: 'categories',
    label: 'Categories',
    export: (product, format) =>
      dataExporter(format).exportCategories(
        product.categories.results,
        CATEGORIES,
      ),
    import: (data, format) => dataImporter(format).importCategories(data),
  },
  { key: 'tax_code', label: 'Tax code' },
  {
    key: 'tags',
    label: 'Tags',
    export: (product, format) => dataExporter(format).exportTags(product.tags),
    import: (data, format) => dataImporter(format).importTags(data),
  },
  { key: 'description', label: 'Description' },
  { key: 'meta_title', label: 'Page Title' },
  { key: 'meta_description', label: 'Meta Description' },
  {
    key: 'images',
    label: 'Images',
    export: (product, format) => dataExporter(format).exportImages(product),
    import: (data, format) => dataImporter(format).importImages(data),
  },
  {
    key: 'price',
    label: 'List Price',
    export: (product, format) => dataExporter(format).exportPrice(product),
  },
  {
    key: 'sale',
    label: 'On Sale',
    export: (product, format) => dataExporter(format).exportSale(product),
  },
  {
    key: 'sale_price',
    label: 'Sale Price',
    export: (product, format) => dataExporter(format).exportSalePrice(product),
  },
  {
    key: 'prices',
    label: 'Price Rules',
    export: (product, format) => dataExporter(format).exportPrices(product),
    import: (data) => importJSONArray(data),
  },
  {
    key: 'cost',
    label: 'Cost',
    export: (product, format) => dataExporter(format).exportCost(product),
  },
  { key: 'bundle', label: 'Bundle' },
  {
    key: 'bundle_items',
    label: 'Bundle Items',
    export: (product, format) =>
      dataExporter(format).exportBundleItems(product.bundle_items),
    import: (data, format) => dataImporter(format).importBundleItems(data),
  },
  { key: 'shipment_location', label: 'Shipping Location' },
  {
    key: 'shipment_weight',
    label: 'Shipping Weight',
    export: (product, format) =>
      dataExporter(format).exportShippingWeight(product),
  },
  { key: 'shipment_package_quantity', label: 'Shipping Package Quantity' },
  {
    key: 'shipment_prices',
    label: 'Shipping Price Rules',
    export: (product, format) =>
      dataExporter(format).exportShippingPrices(product.shipment_prices),
    import: (data) => importJSONArray(data),
  },
  { key: 'subscription_interval', label: 'Subscription Interval' },
  { key: 'subscription_interval_count', label: 'Subscription Interval Count' },
  { key: 'subscription_trial_days', label: 'Subscription Trial Days' },
  ...get(ATTRIBUTES, 'results', []).map((attr) => ({
    key: `attributes.${attr.id}`,
    label: attr.name,
    export: (product, format) =>
      dataExporter(format).exportAttributes(product, attr),
    import: (data, format) => dataImporter(format).importAttribute(attr, data),
  })),
  {
    key: 'id',
    label: 'ID',
    export: (product, format) => dataExporter(format).exportId(product),
  },
  {
    key: 'purchase_options',
    label: 'Purchase Options',
    export: (product, format) =>
      dataExporter(format).exportPurchaseOptions(product),
    import: (data, format) => dataImporter(format).importPurchaseOptions(data),
  },
  {
    key: '$locale',
    label: 'Locale',
    export: (product, format) =>
      dataExporter(format).exportLocaleOptions(product),
    import: (data, format) => dataImporter(format).importLocaleOptions(data),
  },
];

// function importJSON(data) {
//   try {
//     return JSON.parse(data);
//   } catch (err) {
//     return undefined;
//   }
// }

function importJSONArray(data) {
  try {
    if (!data.trim().startsWith('[')) {
      return data.split(/\n|\r\n/).map((str) => JSON.parse(str));
    }

    return JSON.parse(data);
  } catch (err) {
    return undefined;
  }
}

function categoryNameId(name) {
  return name.replace(/[^A-Za-z0-9]+/g, '').toLowerCase();
}

function isActiveOptionSelected(record) {
  return record?.active?.toLowerCase() === 'true';
}

function categoryFromPath(path, format) {
  // Example: Category > Sub-category: {sort} > Sub-sub-category etc
  const parts = dataImporter(format).importCategoriesPartsFromPath(path);

  let foundCategory;
  let categoryList = CATEGORIES.list;
  while (parts.length > 0) {
    const part = parts.shift();
    const nameId = categoryNameId(
      dataImporter(format).importCategoriesPartName(part),
    );
    let foundChildren = false;
    for (const category of categoryList) {
      const exNameId = categoryNameId(category.name);
      if (nameId === exNameId) {
        if (parts.length > 0) {
          if (category.children) {
            foundChildren = true;
            foundCategory = category;
            categoryList = category.children;
            break;
          } else {
            // Didn't make it to the end
            return [category.id, parts];
          }
        } else {
          // Found the target
          return [category.id, null];
        }
      }
    }
    if (!foundChildren && foundCategory) {
      // Not found in existing children
      return [foundCategory.id, [part, ...parts]];
    }
    if (!foundCategory) {
      return [null, [part, ...parts]];
    }
  }
  return [null, null];
}

function categoryIdFromPath(path, format) {
  const [categoryId, parts] = categoryFromPath(path, format);
  if (parts && parts.length) {
    return null;
  }
  return categoryId;
}

async function findVariantIdForImport(product, variant, format) {
  if (!product || !variant || !variant.variant_options) {
    return;
  }

  const variantOptions = (product.options || []).filter((op) => !!op.variant);

  const optionsByName = variantOptions.reduce((acc, option) => {
    acc[option.name.trim().toLowerCase()] = {
      ...option,
      values: option.values.reduce((acc, value) => {
        acc[value.name.trim().toLowerCase()] = value;

        return acc;
      }, {}),
    };

    return acc;
  }, {});

  const optionValueIds = dataImporter(format).importVariantsOptionValueIds(
    variant.variant_options,
    optionsByName,
  );

  if (optionValueIds.length <= 0) {
    return;
  }

  const existingVariant = await api.get(
    '/data/products/{parent_id}/variants/:first',
    {
      parent_id: product.id,
      option_value_ids: { $all: optionValueIds },
    },
  );

  return existingVariant && existingVariant.id;
}

function importCSVFields(data, withFiles = true, format) {
  const fileKeys = [
    'images',
    ...ATTRIBUTES.results
      .filter((attr) => attr.type === 'image' || attr.type === 'file')
      .map((attr) => `attributes.${attr.id}`),
  ];

  const fieldsList = withFiles
    ? EXPORT_CSV_FIELDS
    : EXPORT_CSV_FIELDS.filter((field) => !fileKeys.includes(field.key));

  const values = fieldsList.reduce((acc, field) => {
    const value = get(data, field.key);

    if (field.import && value !== undefined) {
      set(acc, field.key, field.import(value, format));
    }

    return acc;
  }, {});

  return values;
}

const mapStateToProps = (state) => ({
  data: state.data,
  bulk: state.data.bulk,
});

const mapDispatchToProps = (dispatch) => ({
  bulkExport: (model, params) => {
    return dispatch(actions.data.bulkExport(model, params));
  },

  bulkImport: (model, params) => {
    return dispatch(actions.data.bulkImport(model, params));
  },

  bulkCancel: () => {
    return dispatch(actions.data.bulkCancel());
  },

  fetchCollection: (model, query, locationQuery = {}) => {
    return dispatch(actions.data.fetchCollection(model, query, locationQuery));
  },

  addImportedCategory: (category) => {
    return dispatch(actions.categories.add(category));
  },

  loadAttributes() {
    return dispatch(actions.attributes.load());
  },

  loadCategories() {
    return dispatch(actions.categories.load());
  },

  fetchSettings(id) {
    return dispatch(actions.settings.fetch(id));
  },
});

export class Products extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    loadAttributes: PropTypes.func,
    loadCategories: PropTypes.func,
    fetchSettings: PropTypes.func,
    fetchCollection: PropTypes.func,
    data: PropTypes.object,
    bulk: PropTypes.object,
    bulkCancel: PropTypes.func,
    bulkExport: PropTypes.func,
    bulkImport: PropTypes.func,
    addImportedCategory: PropTypes.func,
  };

  static async onEnter(store, nextState, replace) {
    if (replaceLocationQuery(store, nextState, replace)) {
      return;
    }
  }

  async searchHandler(search, limit) {
    if (!search) {
      return {};
    }

    const productQuery = {
      search,
      limit: 1000,
      fields: 'id',
    };

    const [foundProducts, foundVariants] = await Promise.all([
      api.get('/data/products', productQuery),
      api.get('/data/products:variants', {
        search,
        limit: 1000,
        fields: 'parent_id',
      }),
    ]);

    const uniqueIds = uniq(
      foundProducts.results
        .map((product) => product.id)
        .concat(foundVariants.results.map((variant) => variant.parent_id)),
    );

    return uniqueIds.length > 0
      ? {
          id: { $in: uniqueIds },
        }
      : productQuery;
  }

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      showExport: false,
      showImport: false,
      onClickExport: this.onClickExport,
      onClickExportCancel: this.onClickExportCancel,
      onClickExportReset: this.onClickExportReset,
      onSubmitExport: this.onSubmitExport,
      onClickImport: this.onClickImport,
      onSampleImportCount: this.onSampleImportCount,
      onSampleImportIndex: this.onSampleImportIndex,
      onSampleImportData: this.onSampleImportData,
      onSubmitImport: this.onSubmitImport,
    };

    this.IMPORT_VARIANT_OPTION_VALUES = new Set();
  }

  componentDidMount() {
    const { loadAttributes, loadCategories, fetchSettings } = this.props;

    Promise.all([loadAttributes(), loadCategories(), fetchSettings('products')])
      .then(([attributes, categories, productSettings]) => {
        ATTRIBUTES = attributes;
        CATEGORIES = categories;

        EXPORT_CSV_FIELDS = exportCSVFields();

        if (!productSettings) {
          return;
        }

        const productTypes = filter(
          productSettings.types,
          (type) => !type.archived || type.id === productSettings.default_type,
        );

        filters.type.options = productTypes.map((type) => ({
          value: type.id,
          label: type.name,
        }));
      })
      .then(() => {
        this.setState({ loaded: true });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bulk.running && !this.props.bulk.running) {
      this.fetchCollection();
    }
  }

  onClickExport = (event) => {
    event.preventDefault();
    this.setState({ showExport: !this.state.showExport });
  };

  onClickExportCancel = (event) => {
    event.preventDefault();
    if (this.props.bulk.running) {
      this.props.bulkCancel();
    }
    this.setState({ showExport: false });
  };

  onClickExportReset = (event) => {
    event.preventDefault();
    this.props.bulkCancel();
  };

  onClickImport = (event) => {
    event.preventDefault();
    if (this.state.showImport) {
      this.props.bulkCancel();
    }
    this.setState({ showImport: !this.state.showImport });
  };

  fetchCollection() {
    const {
      data: { query, locationQuery },
      fetchCollection,
    } = this.props;

    return fetchCollection('products', query, locationQuery);
  }

  onSubmitExport = (values) => {
    const { client } = this.context;
    this.props.bulkExport('products', {
      ...values,
      filename: `products`,
      csvFields: EXPORT_CSV_FIELDS,
      onCountRowsExported: (data) => {
        // Count rows with a product name only
        return data.filter((row) => row[0]).length;
      },
      query: {
        expand: ['bundle_items.product', 'bundle_items.variant'],
        include: {
          variants: {
            url: '/products:variants',
            params: {
              parent_id: 'id',
            },
            data: {
              limit: 1000,
              archived: { $ne: true },
              ...getClientLocaleAndCurrencyParams(
                client,
                values?.allLocales,
                values?.allCurrencies,
              ),
            },
          },
          categories: {
            url: '/categories:products',
            params: {
              product_id: 'id',
            },
            data: {
              limit: 1000,
              fields: 'parent_id, sort',
            },
          },
        },
      },
    });
  };

  /**
   * @param {Array} data
   * @param {number} index
   * @param {boolean} next
   * @returns {number?}
   */
  onSampleImportIndex = (data, index, next) => {
    if (!data[index]) {
      return null;
    }

    let productIndex = index;

    while (data[productIndex] && data[productIndex].variant_name) {
      productIndex += next ? 1 : -1;
    }

    if (!data[productIndex]) {
      return null;
    }

    return productIndex;
  };

  /**
   * @param {Array} data
   * @param {number} index
   * @returns {JSX.Element}
   */
  onSampleImportData = (data, index) => {
    if (!data[index]) {
      return null;
    }

    const variants = [];
    const purchaseOptions =
      (data[index]?.purchase_options &&
        JSON.parse(data[index]?.purchase_options)) ||
      null;

    let variantIndex = index + 1;
    while (data[variantIndex] && data[variantIndex].variant_name) {
      variants.push({
        ...data[variantIndex],
        ...importCSVFields(data[variantIndex]),
        active: isActiveOptionSelected(data[variantIndex]),
      });
      variantIndex++;
    }
    const product = {
      ...data[index],
      ...importCSVFields(data[index]),
      active: isActiveOptionSelected(data[index]),
      variants: { results: [] },
    };
    if (!product.slug) {
      product.slug = slugify(product.slug) || slugify(product.name);
    }
    const imageFields = [
      {
        label: 'Images',
        key: 'images',
        array: true,
        value: product.images,
      },
    ].concat(
      ATTRIBUTES.results
        .filter((attr) => attr.type === 'image')
        .map((attr) => ({
          label: attr.name,
          key: `attributes.${attr.id}`,
          array: !!attr.multi,
          value: get(product, `attributes.${attr.id}`, attr.multi ? [] : null),
        })),
    );
    const fileFields = ATTRIBUTES.results
      .filter((attr) => attr.type === 'file')
      .map((attr) => ({
        label: attr.name,
        key: `attributes.${attr.id}`,
        array: !!attr.multi,
        value: get(product, `attributes.${attr.id}`, attr.multi ? [] : null),
      }));

    const standardFields = EXPORT_CSV_FIELDS.filter(
      (field) =>
        !find(imageFields, (f) => f.key === field.key) &&
        !find(fileFields, (f) => f.key === field.key),
    )
      .filter(
        (field) =>
          field.key !== 'variant_name' &&
          field.key !== 'variant_options' &&
          field.key !== 'categories' &&
          field.key !== 'purchase_options' &&
          (product.delivery === 'shipment' ||
            !product.delivery ||
            field.key.indexOf('shipment') === -1) &&
          (product.delivery === 'subscription' ||
            field.key.indexOf('subscription') === -1) &&
          (product.bundle === 'true' || field.key.indexOf('bundle') === -1),
      )
      .map((field) => {
        return {
          ...field,
          value: (data) => {
            let value;

            if (field.export) {
              value = field.export(product);

              if (RENDER_RAW_DATA.has(field.key)) {
                if (product.bundle_items) {
                  value = JSON.stringify(product.bundle_items, null, 4);
                } else if (product.options) {
                  value = JSON.stringify(product.options, null, 4);
                }
              }

              if (value instanceof Array) {
                value = value[0];
              }
            } else if (field.value) {
              value = field.value;
            }

            if (product.options && field.key === 'options') {
              value = JSON.stringify(product.options, null, 4);
            }

            return value || data[field.key];
          },
        };
      });

    return (
      <div>
        <table>
          <tbody>
            {standardFields.map((field, i) => (
              <tr key={i}>
                <td className="muted nowrap" align="right">
                  {field.label}
                </td>
                <td>
                  {RENDER_RAW_DATA.has(field?.key) ? (
                    <pre>{field.value(product)}</pre>
                  ) : (
                    field.value(product) || (
                      <span className="muted">&mdash;</span>
                    )
                  )}
                </td>
              </tr>
            ))}
            <tr key="categories">
              <td className="muted nowrap" align="right">
                Categories
              </td>
              <td>
                {product.categories && product.categories.length > 0 ? (
                  product.categories.map((categoryPath, i) => (
                    <div key={i}>{categoryPath}</div>
                  ))
                ) : (
                  <span className="muted">&mdash;</span>
                )}
              </td>
            </tr>
            {imageFields.map((field) => (
              <tr key={field.key}>
                <td className="muted" align="right">
                  {field.label}
                </td>
                <td>
                  {field.value && field.array ? (
                    field.value.length ? (
                      field.value.map((image, i) => (
                        <img
                          key={i}
                          src={image.file.url}
                          height={100}
                          alt={image.file.url}
                        />
                      ))
                    ) : (
                      <span className="muted">&mdash;</span>
                    )
                  ) : field.value ? (
                    <img
                      src={field.value.file.url}
                      height={100}
                      alt={field.value.file.url}
                    />
                  ) : (
                    <span className="muted">&mdash;</span>
                  )}
                </td>
              </tr>
            ))}
            {fileFields.map((field) => (
              <tr key={field.key}>
                <td className="muted" align="right">
                  {field.label}
                </td>
                <td className="files">
                  {field.value && field.array ? (
                    field.value.length ? (
                      field.value.map((file, i) => (
                        <a
                          key={file.file.url}
                          href={file.file.url}
                          target="blank"
                          className="button button-secondary button-xs"
                        >
                          {file.file.url}
                        </a>
                      ))
                    ) : (
                      <span className="muted">&mdash;</span>
                    )
                  ) : field.value ? (
                    <a
                      href={field.value.file.url}
                      target="blank"
                      className="button button-secondary button-xs"
                    >
                      {field.value.file.url}
                    </a>
                  ) : (
                    <span className="muted">&mdash;</span>
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td className="muted" align="right">
                Purchase options
              </td>
              <td>
                {purchaseOptions ? (
                  <pre>{JSON.stringify(purchaseOptions, null, 4)}</pre>
                ) : (
                  <span className="muted">&mdash;</span>
                )}
              </td>
            </tr>
            <tr>
              <td className="muted" align="right">
                Variants
              </td>
              <td>
                {variants.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <td colSpan="2">Name</td>
                        <td>Status</td>
                        <td>Stock</td>
                      </tr>
                    </thead>
                    <tbody>
                      {variants.map((variant, i) => (
                        <tr key={i}>
                          <td className="nowrap">
                            {String(variant.variant_name).trim() ||
                              variant.variant_options}
                          </td>
                          <td width={50}>
                            {variant.images &&
                              variant.images.map((image, i) => (
                                <img
                                  key={i}
                                  src={image.file.url}
                                  height={50}
                                  alt={image.file.url}
                                />
                              ))}
                          </td>
                          <td>
                            {variant.active ? (
                              <Status type="positive">Active</Status>
                            ) : (
                              <Status type="muted">Inactive</Status>
                            )}
                          </td>
                          <td>
                            {variant.stock_level || (
                              <span className="muted">&mdash;</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <span className="muted">&mdash;</span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  /**
   * @param {Array} data
   * @param {string} format
   * @returns
   */
  onSampleImportCount = (data, format) => {
    const products = [];
    const dupes = [];
    const badOptionValues = [];
    let productCount = 0;
    let variantCount = 0;

    if (format === 'csv') {
      for (const row of data) {
        if (row.variant_name) {
          variantCount++;
        } else {
          productCount++;
          const id = row.id || slugify(row.slug) || row.sku;
          if (id && products.indexOf(id) !== -1) {
            dupes.push(id);
          } else {
            products.push(id);
          }
        }
        if (row.variant_name && row.variant_options) {
          for (const optionValue of row.variant_options.split(/\n|\r\n/)) {
            if (String(optionValue || '').trim()) {
              const [opName, valName] = optionValue.split(':');
              if (!opName || !valName) {
                badOptionValues.push({
                  name: row.variant_name,
                  value: optionValue,
                });
              }
            }
          }
        }
      }
    } else if (format === 'json') {
      for (const row of data) {
        productCount++;

        if (get(row, 'variants.count', 0) > 0) {
          // eslint-disable-next-line no-unused-vars
          for (const variant of row.variants.results) {
            variantCount++;
          }
        }
      }
    }

    return {
      error:
        (dupes.length === 0 ? null : (
          <>
            <p>
              Your import file has <b>{dupes.length}</b> duplicate product ID or
              slug {inflect(dupes.length, 'values').replace(dupes.length, '')}.
            </p>
            <ul>
              {uniq(dupes).map((id) => (
                <li key={id}>{id}</li>
              ))}
            </ul>
          </>
        )) || badOptionValues.length === 0 ? null : (
          <>
            <p>
              Your import file has <b>{badOptionValues.length}</b> invalid
              variant{' '}
              {inflect(badOptionValues.length, 'options').replace(
                badOptionValues.length,
                '',
              )}
              . Variant options should be in the format{' '}
              <code className="negative">Color: Red</code>. Multiple option
              values per variant should be separated by line breaks.
            </p>
            <ul>
              {uniq(badOptionValues).map((opVal, index) => (
                <li key={index}>
                  Variant: {opVal.name}, Variant Option: <b>{opVal.value}</b>
                </li>
              ))}
              row
            </ul>
          </>
        ),
      total: productCount,
      description: (
        <span>
          You will import <b>{inflect(productCount, 'products')}</b>
          {variantCount > 0 ? (
            <>
              {' '}
              with a total of <b>{inflect(variantCount, 'variants')}</b>.
            </>
          ) : (
            '.'
          )}
        </span>
      ),
    };
  };

  async importTransformFiles(url, data, record, allFiles, format) {
    const fileFields = [
      {
        key: 'images',
        array: true,
      },
    ].concat(
      ATTRIBUTES.results
        .filter((attr) => attr.type === 'image' || attr.type === 'file')
        .map((attr) => ({
          key: `attributes.${attr.id}`,
          array: !!attr.multi,
        })),
    );
    const recordErrors = [];
    const recordUpdates = {};
    for (const field of fileFields) {
      const value = get(data, field.key);
      const recordValue = get(record, field.key);
      if (value) {
        const fileUrls = dataImporter(format).importTransformFilesUrls(value);
        const exFiles = recordValue
          ? field.array
            ? recordValue
            : [recordValue]
          : [];
        if (fileUrls.length > 0) {
          let updatedCount = 0;
          const updateFiles = [];
          await Promise.all(
            fileUrls.map(async (url, index) => {
              let fileResult;
              const exFile = find(
                exFiles,
                (file) =>
                  file.file.url === url || file.file.uploaded_url === url,
              );
              // Reference files already uploaded somewhere else
              // TODO: make this work eventually, but now system would delete files shared by different records
              if (false && allFiles[url]) {
                fileResult = allFiles[url];
              } else {
                fileResult = await api.post('/util/import-file', {
                  url,
                  file: exFile ? exFile.file : null,
                });
                if (fileResult) {
                  allFiles[url] = fileResult;
                }
              }
              if (fileResult.errors) {
                recordErrors.push({
                  data,
                  errors: fileResult.errors,
                });
              } else {
                updateFiles.push({ file: fileResult });
                const name = fileResult.filename || fileResult.id;
                if (!exFile || exFile.file.id !== fileResult.id) {
                  console.log(`Uploaded file ${name} (${field.key})`);
                  updatedCount++;
                } else if (exFile) {
                  console.log(`Skipped uploading file ${name} (${field.key})`);
                }
              }
            }),
          );
          if (updatedCount > 0 || exFiles.length !== updateFiles.length) {
            recordUpdates[field.key] = field.array
              ? updateFiles
              : updateFiles[0];
          }
        } else {
          // Clear files
          if (exFiles.length > 0) {
            recordUpdates[field.key] = field.array ? [] : null;
          }
        }
      } else if (value !== undefined) {
        const hasValue = field.array
          ? recordValue && recordValue.length > 0
          : recordValue;
        if (hasValue) {
          // Clear files
          recordUpdates[field.key] = field.array ? [] : null;
        }
      }
    }
    // Finally update if needed
    if (!isEmpty(recordUpdates)) {
      const updateResult = await api.put(url, {
        id: record.id,
        $set: recordUpdates,
        $events: false,
      });
      if (updateResult.errors) {
        recordErrors.push({
          data,
          errors: updateResult.errors,
        });
      }
    }
    return recordErrors;
  }

  async importTransformPurchaseOptions(url, data, record) {
    const { client } = this.context;

    if (
      typeof data.purchase_options === 'string' &&
      data?.purchase_options?.trim().length > 0
    ) {
      data.purchase_options = JSON.parse(data.purchase_options);
    }

    // we build standard purchase options from the fields price/sale/sale_price
    // but in case when 'standard' wasn't in the original product, we shouldn't create it
    const standardPurchaseOptions =
      data.purchase_options && !data.purchase_options.standard
        ? undefined
        : {
            active: true,
            price: record.price,
            sale: record.sale,
            sale_price: record.sale_price,
            prices: record.prices || [],
            $currency: data.purchase_options?.standard?.$currency,
          };

    const productResult = await api.put(url, {
      id: record.id,
      $set: {
        purchase_options: {
          standard: standardPurchaseOptions,
          ...(data.purchase_options?.subscription && {
            subscription: data.purchase_options.subscription,
          }),
          ...(data.purchase_options?.trial &&
            client.features.has('trial_purchase') && {
              trial: data.purchase_options.trial,
            }),
        },
      },
    });

    if (productResult.errors) {
      return [
        {
          data,
          errors: productResult.errors,
        },
      ];
    }
  }

  async importTransformCategories(url, data, record, format) {
    if (data.variant_name) {
      return;
    }
    if (data.categories === undefined) {
      return;
    }
    const value = data.categories || '';
    const recordCategories = await api.get('/data/categories:products', {
      product_id: record.id,
      limit: 1000,
      fields: 'parent_id, sort',
    });
    const newCategories = dataImporter(format).importNewCategories(value);
    const createCategories = newCategories
      .map((path) => {
        const result = categoryFromPath(path, format);
        if (result[1]) {
          return result;
        }
        return null;
      })
      .filter((result) => result);
    const newFoundCategories = newCategories
      .map((val) => categoryIdFromPath(val, format))
      .filter((id) => id);
    const exCategories = recordCategories.results.map((cat) => cat.parent_id);
    const addCategories = newFoundCategories.filter(
      (id) => exCategories.indexOf(id) === -1,
    );
    const removeCategories = exCategories.filter(
      (id) => newFoundCategories.indexOf(id) === -1,
    );
    if (removeCategories.length > 0) {
      await Promise.all(
        removeCategories.map(async (categoryId) => {
          const categoryProduct = find(recordCategories.results, {
            parent_id: categoryId,
          });
          if (categoryProduct) {
            const removeResult = await api.delete(
              '/data/categories:products/{id}',
              {
                id: categoryProduct.id,
                $events: false,
              },
            );
            if (removeResult && removeResult.errors) {
              return [{ data, errors: removeResult.errors }];
            }
          }
        }),
      );
    }
    if (createCategories.length > 0) {
      const alreadyCreated = {};
      for (const create of createCategories) {
        const [categoryId, pathParts] = create;
        let parentCategoryId = categoryId;
        while (pathParts.length > 0) {
          const part = pathParts.shift();
          const alreadyCreatedId = `${parentCategoryId} > ${part}`;
          let createResult;
          if (alreadyCreated[alreadyCreatedId]) {
            createResult = alreadyCreated[alreadyCreatedId];
          } else {
            const categoryName =
              dataImporter(format).importCategoriesPartName(part);
            createResult = await api.post('/data/categories', {
              parent_id: parentCategoryId,
              name: categoryName,
              $events: false,
            });
            if (createResult.errors) {
              // Handle existing slug error
              const existingSlug = get(createResult.errors, 'slug.fields.slug');
              if (existingSlug) {
                createResult = await api.get('/data/categories/{slug}', {
                  slug: existingSlug,
                });
                if (createResult) {
                  createResult = await api.put('/data/categories/{id}', {
                    id: createResult.id,
                    parent_id: parentCategoryId,
                    $events: false,
                  });
                }
              }
              if (createResult.errors) {
                return [{ data, errors: createResult.errors }];
              }
            }
            CATEGORIES = this.props.addImportedCategory(createResult);
            alreadyCreated[alreadyCreatedId] = createResult;
          }
          if (pathParts.length > 0) {
            parentCategoryId = createResult.id;
          } else {
            addCategories.push(createResult.id);
          }
        }
      }
    }
    if (addCategories.length > 0) {
      await Promise.all(
        addCategories.map(async (categoryId) => {
          const addResult = await api.post('/data/categories:products', {
            parent_id: categoryId,
            product_id: record.id,
          });
          if (addResult.errors) {
            return [{ data, errors: addResult.errors }];
          }
        }),
      );
    }
  }

  async importTransformVariantOptions(url, data, record, next, format) {
    if (!data.variant_name || !data.variant_options) {
      return;
    }

    const product = await api.get('/data/products/{id}', {
      id: data.parent_id,
      fields: 'options',
    });

    if (!product) {
      return;
    }

    if (!product.options) {
      product.options = [];
    }

    const customOptions = product.options.filter((op) => !op.variant);
    const variantOptions = product.options.filter((op) => !!op.variant);

    const optionsByName = variantOptions.reduce((acc, option) => {
      acc[option.name.trim().toLowerCase()] = {
        ...option,
        values: option.values.reduce((acc, value) => {
          acc[value.name.trim().toLowerCase()] = value;

          return acc;
        }, {}),
      };

      return acc;
    }, {});

    // TODO: handle escaped commas

    // Analogue of React.createRef — used for side effect
    const optionsUpdated = { current: false };

    const importOptionValueIds = dataImporter(
      format,
    ).importTransformVariantValueIds(
      data,
      optionsByName,
      optionsUpdated,
      variantOptions,
    );

    // Update product options
    if (optionsUpdated.current) {
      const productResult = await api.put('/data/products/{id}', {
        id: product.id,
        $set: {
          options: [...variantOptions, ...customOptions],
        },
        $generate_variants: false,
        $events: false,
      });

      if (productResult.errors) {
        return [
          {
            data,
            errors: productResult.errors,
          },
        ];
      }
    }

    // Aggregate option value ids
    for (const id of importOptionValueIds) {
      this.IMPORT_VARIANT_OPTION_VALUES.add(id);
    }

    // Update variant
    if (!isEqual(importOptionValueIds, record.option_value_ids)) {
      const variantResult = await api.put('/data/products:variants/{id}', {
        id: record.id,
        $set: { option_value_ids: importOptionValueIds },
        $events: false,
      });

      if (variantResult.errors) {
        return [
          {
            data,
            errors: variantResult.errors,
          },
        ];
      }
    }

    // If this is the last variant row, remove unused variant options
    if (!next || !next.variant_name) {
      const finalVariantOptions = variantOptions.reduce((list, option) => {
        if (option.values) {
          option.values = option.values.filter((value) =>
            this.IMPORT_VARIANT_OPTION_VALUES.has(value.id),
          );

          if (option.values.length > 0) {
            list.push(option);
          }
        }

        return list;
      }, []);

      await api.put('/data/products/{id}', {
        id: product.id,
        $set: {
          options: [...finalVariantOptions, ...customOptions],
        },
        $generate_variants: true,
        $events: false,
      });
    }
  }

  async importTransformStock(url, data, record, next) {
    if (data.stock_level === undefined) {
      return;
    }
    const stockLevel = parseInt(data.stock_level, 10);

    if (!isNaN(stockLevel) && stockLevel !== record.stock_level) {
      let stockResult;
      if (data.variant_name) {
        stockResult = await api.post('/data/products:stock', {
          parent_id: record.parent_id,
          variant_id: record.id,
          quantity:
            record.stock_level !== undefined
              ? stockLevel - record.stock_level
              : stockLevel,
          $events: false,
        });
      } else {
        if (next && next.variant_name) {
          // Parent product
          return;
        }
        const hasVariants = !!(await api.get(
          '/data/products/{id}/variants/:count',
          {
            id: record.id,
          },
        ));
        if (hasVariants) {
          return;
        }
        stockResult = await api.post('/data/products:stock', {
          parent_id: record.id,
          quantity:
            record.stock_level !== undefined
              ? stockLevel - record.stock_level
              : stockLevel,
          $events: false,
        });
      }
      if (stockResult && stockResult.errors) {
        return [
          {
            data,
            errors: stockResult.errors,
          },
        ];
      }
    }
  }

  onSubmitImport = (values) => {
    const parentProducts = {};
    const allFiles = {};

    this.IMPORT_VARIANT_OPTION_VALUES.clear();

    this.props.bulkImport('products', {
      ...values,
      onMessage: (data) =>
        `Importing ${
          get(parentProducts, data.parent_id, data).name || 'unnamed product'
        }...`,
      csvFields: EXPORT_CSV_FIELDS,
      csvTransform: async (data, prev) => {
        let result = {
          orig: data,
          data: {
            ...data,
            id: data.id,
            bundle_item_ids: undefined,
            variant_name: undefined,
            variant_options: undefined,
            custom_options: undefined,
            stock_level: undefined,
            attributes: undefined,
            categories: undefined,
            images: undefined,
            tags: undefined,
            // May be a bug where record is getting set into data
            record: undefined,
            $generate_variants: false,
            $set: {
              ...importCSVFields(data, false, values.format),
              categories: undefined,
            },
          },
        };
        if (data.variant_name) {
          data.parent_id = prev.parent_id || prev.id;
          if (!data.parent_id) {
            // May have been a problem importing parent
            return;
          }
          result = {
            model: 'products:variants',
            orig: data,
            data: {
              ...result.data,
              name: data.variant_name,
              parent_id: data.parent_id,
              bundle: undefined,
              bundle_items: undefined,
              delivery: undefined,
              description: undefined,
              meta_description: undefined,
              meta_title: undefined,
              prices: undefined,
              shipment_location: undefined,
              shipment_package_quantity: undefined,
              shipment_prices: undefined,
              slug: undefined,
              tags: undefined,
              // May be a bug where record is getting set into data
              record: undefined,
              $set: {
                ...result.data.$set,
                active: isActiveOptionSelected(data),
                slug: undefined,
                tags: undefined,
              },
            },
          };
          // Reset variant option cache on first
          if (!prev.parent_id) {
            this.IMPORT_VARIANT_OPTION_VALUES.clear();
          }
        }
        return result;
      },
      idTransform: async (data, prev) => {
        if (data.id) {
          return data.id;
        }
        if (data.variant_name) {
          if (get(prev, 'record.slug') && !parentProducts[data.parent_id]) {
            parentProducts[data.parent_id] = prev.record;
          }
          return await findVariantIdForImport(
            parentProducts[data.parent_id],
            data,
            values.format,
          );
        }
        if (data.slug) {
          const withSlug = await api.get('/data/products/:first', {
            slug: slugify(data.slug),
            fields: 'id',
          });
          if (withSlug) {
            return withSlug.id;
          }
        }
        if (data.sku) {
          const withSku = await api.get('/data/products/:first', {
            sku: data.sku,
            fields: 'id',
          });
          if (withSku) {
            return withSku.id;
          }
          const withNameSkuSlug = await api.get('/data/products/:first', {
            slug: `${slugify(data.name)}-${slugify(data.sku)}`,
            fields: 'id',
          });
          if (withNameSkuSlug) {
            return withNameSkuSlug.id;
          }
        }
        return null;
      },
      // Before import
      updateTransform: async (record, data, origData = {}) => {
        if (record) {
          for (const key in data) {
            if (data[key] === '' && record[key] === undefined) {
              delete data[key];
            }
          }
          for (const key in data.$set) {
            if (data.$set[key] === '' && record[key] === undefined) {
              delete data.$set[key];
            }
          }
        }

        if (data.$set.attributes) {
          const attrs = {
            ...(record ? record.attributes || {} : {}),
          };
          for (const key in data.$set.attributes) {
            if (data.$set.attributes[key] === '' && attrs[key] === undefined) {
              // Remove attributes that are empty and not defined by the record already
              delete data.$set.attributes[key];
            } else {
              attrs[key] = data.$set.attributes[key];
            }
          }
          data.$set.attributes = attrs;
        }

        if (data.$set.bundle_items) {
          const bundleItemIds = data.$set.bundle_items.map(
            (item) => item.product_id,
          );

          const existingProducts = await Promise.all(
            bundleItemIds.map((productId) =>
              api.get('/data/products/{id}', { id: productId, fields: 'id' }),
            ),
          );

          const bundleItems = data.$set.bundle_items.filter(
            (item, index) => existingProducts[index],
          );

          data.$set.bundle_items = bundleItems;
        }

        if (!record) {
          // Set a name/sku slug if it might collide with an existing slug
          if (!data.parent_id && !data.slug && data.sku) {
            data.slug = `${slugify(data.name)}-${slugify(data.sku)}`;
          }
          // Set a default for slug
          if (!data.parent_id && !data.slug) {
            data.slug = slugify(data.name);
          }
        }
        // Set stock tracking if defined
        if (!data.parent_id && origData.stock_level) {
          data.stock_tracking = true;
        }
        if (
          values.format === 'json' &&
          data.variants &&
          data.variants.results
        ) {
          data.variants = data.variants.results;
          delete data.stock_status;
        }
        // Custom options before variant options
        if (origData.custom_options) {
          const customOptions = origData.custom_options
            .split(/\n|\r\n/)
            .filter((op) => op.trim().length > 0)
            .map((op) => {
              const [name, vals] = op.split('(');
              const values =
                vals &&
                vals
                  .trim()
                  .replace(/\)$/, '')
                  .split(',')
                  .map((val) => ({
                    id: ObjectID().toHexString(),
                    name: val.trim(),
                  }));
              return {
                id: ObjectID().toHexString(),
                name: name.trim(),
                input_type: values ? 'select' : 'toggle',
                values,
              };
            });
          if (record && record.options) {
            data.$set.options = record.options;
            for (const option of customOptions) {
              const ex = find(
                data.$set.options,
                (exOp) => slugify(exOp.name) === slugify(option.name),
              );
              if (ex) {
                ex.values = option.values;
              } else {
                data.$set.options.push(option);
              }
            }
          } else {
            data.$set.options = customOptions;
          }
        }

        return data;
      },
      // After import
      recordTransform: async (url, data, record, next) => {
        const [
          fileErrors,
          categoryErrors,
          purchaseOptionsErrors,
          stockErrors,
          variantOptionErrors,
        ] = await Promise.all([
          this.importTransformFiles(url, data, record, allFiles, values.format),
          this.importTransformCategories(url, data, record, values.format),
          this.importTransformPurchaseOptions(url, data, record),
          this.importTransformStock(url, data, record, next),
          this.importTransformVariantOptions(
            url,
            data,
            record,
            next,
            values.format,
          ),
        ]);
        return [
          ...(fileErrors || []),
          ...(categoryErrors || []),
          ...(purchaseOptionsErrors || []),
          ...(stockErrors || []),
          ...(variantOptionErrors || []),
        ];
      },
    });
  };

  headerActions = [
    {
      label: 'Export',
      fa: 'arrow-to-bottom',
      type: 'sub',
      onClick: this.onClickExport,
    },
    {
      label: 'Import',
      showAlways: true,
      fa: 'arrow-from-bottom',
      type: 'sub',
      onClick: this.onClickImport,
    },
    { label: 'New product', link: '/products/new' },
  ];

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return (
      <>
        <Collection
          {...this.props}
          title="Products"
          uri="/products"
          model="products"
          emptyDescription="Products are what you sell. Add a product to start building your inventory and make your storefront operational."
          bulkActions={bulkActions}
          headerActions={this.headerActions}
          tabs={tabs}
          filters={filters}
          fields={fields}
          query={query}
          queryCurrency={true}
          searchHandler={this.searchHandler}
        />
        {this.state.showExport && (
          <BulkExport
            label="Products"
            {...this.props}
            {...this.state}
            selectable={true}
            showCurrency
            showLocale
          />
        )}
        {this.state.showImport && (
          <BulkImport
            label="Products"
            csvFields={EXPORT_CSV_FIELDS}
            {...this.props}
            {...this.state}
            sampleFileCSV="/admin/public/product-import-sample.csv"
            sampleFileJSON=""
            selectable={true}
            isOverwriteDefault
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);
