import api from 'services/api';
import { find } from 'lodash';

export const APPS_FETCH = 'apps/fetch';
export const APPS_FETCH_ALL = 'apps/fetchAll';
export const APPS_FETCH_CONFIGS = 'apps/fetchConfigs';
export const APPS_FETCH_REVIEWS = 'apps/fetchReviews';
export const APPS_CREATE = 'apps/create';
export const APPS_UPDATE = 'apps/update';
export const APPS_DELETE = 'apps/delete';
export const APPS_LOADING = 'apps/loading';

export default {
  fetch(id) {
    return {
      type: APPS_FETCH,
      payload: api.get(`/apps/${id}`),
    };
  },

  fetchAll() {
    return {
      type: APPS_FETCH_ALL,
      payload: api.get('/apps'),
    };
  },

  fetchConfigs(id, query) {
    return {
      type: APPS_FETCH_CONFIGS,
      payload: api.get(`/apps/${id}/configs`, query),
    };
  },

  fetchReviews(id) {
    return {
      type: APPS_FETCH_REVIEWS,
      payload: api.get(`/apps/${id}/reviews`),
    };
  },

  create(data) {
    return {
      type: APPS_CREATE,
      payload: api.post('/apps', data),
    };
  },

  update(id, data) {
    return {
      type: APPS_UPDATE,
      payload: api.put(`/apps/${id}`, data),
    };
  },

  delete(id, confirm_delete_key = null) {
    return {
      type: APPS_DELETE,
      payload: api.delete(`/apps/${id}`, { confirm_delete_key }),
    };
  },
};

export const initialState = {
  fetched: false,
  results: [],
  count: 0,
  pages: {},
  reviews: {
    results: [],
    count: 0,
    pages: {},
  },
  record: null,
  loading: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case APPS_FETCH:
      return {
        ...state,
        record: applyRecordProps(action.payload),
      };

    case APPS_FETCH_ALL:
      return {
        ...state,
        ...action.payload,
        results: action.payload?.results.map((app) => applyRecordProps(app)),
        fetched: true,
      };

    case APPS_FETCH_REVIEWS:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          ...action.payload,
        },
      };

    case APPS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}

function applyRecordProps(app) {
  return {
    ...app,
    updateAvailable: updateAvailable(app),
  };
}

function updateAvailable(app) {
  return app.installed?.version && app.version > app.installed.version;
}