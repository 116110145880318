import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  get,
  find,
  filter,
  each,
  assign,
  cloneDeep,
  capitalize,
  isEmpty,
} from 'lodash';
import Link from 'components/link';
import {
  Form,
  Field,
  FieldLocalized,
  LookupCategory,
  LookupContent,
  LookupProduct,
} from 'components/form';
import Icon from 'components/icon';
import Modal from 'components/modal';
import Alert from 'components/alert';
import { FadeIn } from 'components/transitions';
import { Draggable, Droppable } from 'react-drag-and-drop';
import FlipMove from 'react-flip-move';
import { singularize, snakeCase } from 'utils';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';
import { removeModelNamespace } from 'utils/content';

const ITEM_OPTIONS = [
  { value: 'product_all', label: 'All products' }, // special
  { value: 'category_all', label: 'All categories' }, // special
  { value: 'product', label: 'Product', requiresValue: true },
  { value: 'category', label: 'Category', requiresValue: true },
  { value: 'content', label: 'Content', requiresValue: true },
  { value: 'home', label: 'Home' },
  { value: 'search', label: 'Search' },
  { value: 'url', label: 'URL', requiresValue: true },
  { value: 'heading', label: 'Heading' },
  // TBD:
  // { value: 'promotion', label: 'Promotion' },
  // { value: 'callout', label: 'Call-out' },
];

const ITEM_OPTIONS_DEPRECATED = [
  { value: 'product_all', label: 'All products' }, // special
  { value: 'category_all', label: 'All categories' }, // special
  { value: 'product', label: 'Product' },
  { value: 'category', label: 'Category' },
  { value: 'search', label: 'Search' },
  { value: 'home', label: 'Home' },
  { value: 'link', label: 'Link' },
];

const DEPRECATED_OPTION_MAP = {
  url: 'link',
};

// Deprecated
// TODO: remove this when we have proper API versioning
const HACKY_EXCLUDED_CLIENTS = [
  'peppersq',
  'peppersq-dev',
  'nowvac',
  'nowvac-dev',
];

const isExcludedClient = (client) =>
  HACKY_EXCLUDED_CLIENTS.indexOf(client.id) !== -1;

const isTypeValue = (type, expected) =>
  type && (type === expected || type === DEPRECATED_OPTION_MAP[expected]);

const typeLabel = (type) =>
  find(ITEM_OPTIONS, { value: type })?.label ||
  find(ITEM_OPTIONS_DEPRECATED, { value: type })?.label ||
  undefined;

export default class Menu extends React.Component {
  static propTypes = {
    record: PropTypes.object,
    menuConfigs: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    route: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    lookup: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
    inEditor: PropTypes.bool,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const values = this.prepareMenu(props.record);
    const origValues = this.prepareMenu(props.record);
    this.state = {
      dragSourceId: null,
      dragTargetId: null,
      dragTargetNode: null,
      values,
      origValues,
      index: this.buildIndex(values),
      itemsSerialized: this.serializeItems(values),
      edited: false,
      closed: {},
      showingItem: false,
      itemValues: {},
      itemEdited: false,
      itemId: null,
      itemParent: null,
      itemTypes: null,
      menuConfig: find(props.menuConfigs, {
        id: values.theme_menu_id,
      }),
    };
    this.dragging = false;
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentWillMount() {
    confirmRouteLeave(this);
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.record !== nextProps.record) {
      const values = this.prepareMenu(nextProps.record);
      const origValues = this.prepareMenu(nextProps.record);
      this.setState({
        values,
        origValues,
        index: this.buildIndex(values),
        itemsSerialized: this.serializeItems(values),
        edited: false,
      });
      if (this.props.onEdited) {
        this.props.onEdited(false);
      }
    }
    if (this.props.modalProps) {
      if (this.props.modalProps.loading && !nextProps.modalProps.loading) {
        this.setOnRef(nextProps);
      }
    }
    if (this.props.edited !== nextProps.edited) {
      this.setState({
        edited: nextProps.edited,
      });
    }
  }

  componentDidMount() {
    this.setOnRef(this.props);
  }

  setOnRef(props) {
    setTimeout(() => {
      if (props.onRef && this.refs.form) {
        props.onRef(this.refs.form);
      }
    }, 1000);
  }

  buildIndex(menu, index = {}) {
    each(menu && menu.items, (item, i) => {
      item.id = `${menu.id || ''}.${i}`;
      item.parent = menu;
      index[item.id] = item;
      if (item.items) {
        this.buildIndex(item, index);
      }
    });
    return index;
  }

  onClickItem = (event) => {
    event.preventDefault();
    const { id: itemId, itemtypes } = event.currentTarget.dataset;
    const itemTypes = itemtypes ? itemtypes.split(',') : null;
    this.setState({
      showingItem: true,
      itemEdited: false,
      itemId,
      itemTypes,
      itemValues: { ...this.state.index[itemId] },
    });
  };

  onClickAddItem = (event) => {
    event.preventDefault();
    const { index } = this.state;
    const { parentid: parentId, itemtypes } = event.currentTarget.dataset;
    const itemTypes = itemtypes ? itemtypes.split(',') : null;
    this.setState({
      showingItem: true,
      itemEdited: false,
      itemId: null,
      itemTypes,
      itemValues: {},
      itemParent: parentId ? index[parentId] : null,
    });
  };

  onClickAddColumn = (event) => {
    event.preventDefault();
    const parentId = event.currentTarget.dataset.parentid;
    const { index, values } = this.state;

    const parent = index[parentId] || values;
    parent.items = parent.items || [];
    parent.items.push({ type: 'column', items: [] });
    this.setState({
      index: this.buildIndex(values),
      edited: true,
    });
    if (this.props.onEdited) {
      this.props.onEdited(true);
    }
  };

  onDragStart = (event) => {
    console.log('onDragStart', event.currentTarget);
    const dragSourceId = event.currentTarget.dataset.id;
    if (this.dragging) return;
    this.dragging = true;
    setTimeout(() => this.setState({ dragSourceId }), 1);
    this.setDraggingClass(event.currentTarget, true);
    event.dataTransfer.effectAllowed = 'move';
  };

  onDragEnd = (event) => {
    console.log('onDragEnd', event.currentTarget);
    this.dragging = false;
    this.moveItems();
    this.setState({
      dragSourceId: null,
      dragTargetId: null,
      dragTargetNode: null,
    });
    this.setDropzoneClass(this.state.dragTargetNode, false);
    this.setDraggingClass(event.currentTarget, false);
    this.resetDropzoneClasses();
  };

  onDragEnter = (event) => {
    console.log('onDragEnter', event.currentTarget);
    const dragTargetId = event.currentTarget.dataset.id;
    if (this.state.dragSourceId === dragTargetId) return;
    this.setState({ dragTargetId, dragTargetNode: event.currentTarget });
    this.setDropzoneClass(event.currentTarget, true);
    event.stopPropagation();
  };

  onDragLeave = (event) => {
    console.log('onDragLeave', event.currentTarget);
    this.setDropzoneClass(event.currentTarget, false);
  };

  setDropzoneClass(targetNode, isOver) {
    const parentNode = this.getParentNode(targetNode);
    if (parentNode) {
      const { id } = parentNode.dataset;
      const columnClass =
        parentNode.className.indexOf('column') >= 0 ? 'column' : '';
      parentNode.counter = parentNode.counter || 0;
      parentNode.counter += isOver ? 1 : -1;
      if (parentNode.counter <= 0) {
        parentNode.className = `${columnClass}`;
        parentNode.counter = 0;
      }
      if (targetNode.dataset.sibling) {
        const dropzone = parentNode.getElementsByClassName(
          'category-dropzone sibling ' + id,
        )[0];
        if (
          !isOver ||
          parentNode.dataset.siblingid !== this.state.dragSourceId
        ) {
          dropzone.className = `${columnClass} category-dropzone sibling ${id} ${
            isOver ? 'over' : ''
          }`;
        }
        if (parentNode.counter > 0 && isOver) {
          parentNode.classList = 'sibling over';
        }
      }
      if (targetNode.dataset.child) {
        const dropzone = parentNode.getElementsByClassName(
          'category-dropzone child ' + id,
        )[0];
        if (!isOver || parentNode.dataset.childid !== this.state.dragSourceId) {
          dropzone.className = `${columnClass} category-dropzone child ${id} ${
            isOver ? 'over' : ''
          }`;
        }
        if (parentNode.counter > 0 && isOver) {
          parentNode.className = `${columnClass} child over`;
        }
      }
    }
  }

  resetDropzoneClasses() {
    const dropzones = document.body.getElementsByClassName(
      'category-dropzone over',
    );
    if (dropzones.length) {
      each(dropzones, (dropzone) => dropzone.classList.remove('over'));
    }
  }

  setDraggingClass(targetNode, isDragging) {
    const parentNode = this.getParentNode(targetNode);
    if (parentNode) {
      parentNode.className = isDragging ? 'dragging' : '';
    }
  }

  getParentNode(targetNode) {
    let parentNode = targetNode;
    while (parentNode && parentNode.tagName !== 'LI') {
      parentNode = parentNode.parentNode;
    }
    return parentNode;
  }

  moveItems(targetNode) {
    const { dragSourceId, dragTargetId, dragTargetNode } = this.state;
    if (dragTargetNode) {
      this.moveItemSourceTarget(dragSourceId, dragTargetId, {
        child: dragTargetNode.dataset.child,
      });
    }
  }

  onClickMoveUp = (event) => {
    event.preventDefault();
    const { id, upid } = event.currentTarget.dataset;
    this.moveItemSourceTarget(id, upid, { up: true });
  };

  onClickMoveDown = (event) => {
    event.preventDefault();
    const { id, downid } = event.currentTarget.dataset;
    this.moveItemSourceTarget(id, downid);
  };

  moveItemSourceTarget(sourceId, targetId, { child = false, up = false } = {}) {
    const { index } = this.state;

    const source = index[sourceId];
    const target = index[targetId];

    if (!source || !target) {
      return;
    }

    // Remove source from original parent location
    if (source.parent) {
      source.parent.items = source.parent.items || [];
      const origIndex = source.parent.items.indexOf(source);
      if (origIndex >= 0) {
        source.parent.items.splice(origIndex, 1);
      }
    }

    if (child) {
      // Target is new parent
      source.parent = target;
      target.items = target.items || [];
      target.items.unshift(source);
    } else {
      // Target is sibling
      source.parent = target.parent;
      if (target.parent) {
        target.parent.items = target.parent.items || [];
        const targetIndex = target.parent.items.indexOf(target);
        if (targetIndex >= 0) {
          target.parent.items.splice(targetIndex + (up ? 0 : 1), 0, source);
        }
      }
    }

    const itemsSerialized = this.serializeItems(this.state.values);

    const edited =
      itemsSerialized !== this.serializeItems(this.state.origValues);
    this.setState({
      itemsSerialized,
      edited,
    });
    if (this.props.onEdited) {
      this.props.onEdited(edited);
    }
  }

  serializeItems(menu) {
    return (
      ((menu && menu.items) || []).reduce(
        (acc, item) =>
          `${acc}${item.name}>${item.items ? this.serializeItems(item) : ''}:`,
        '',
      ) || null
    );
  }

  onClickExpand = (event) => {
    event.preventDefault();
    const { id } = event.currentTarget.dataset;
    const item = this.state.index[id];
    const isClosed =
      this.state.closed[id] ||
      (this.state.closed[id] === undefined &&
        (!item.items || !item.items.length));
    this.setState({
      closed: { ...this.state.closed, [id]: !isClosed },
    });
  };

  onCloseModal = (event) => {
    if (this.state.edited) {
      event.preventDefault();
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.props.onClose();
        },
      });
    } else {
      this.props.onClose();
    }
  };

  onChangeForm = (values, edited) => {
    const isEdited =
      edited ||
      this.state.itemsSerialized !== this.serializeItems(this.state.origValues);
    this.setState({
      values: {
        ...this.state.values,
        ...values,
      },
      menuConfig: find(this.props.menuConfigs, {
        id: values.theme_menu_id,
      }),
      edited: isEdited,
    });
    if (this.props.onEdited) {
      this.props.onEdited(isEdited);
    }
  };

  prepareMenu(record) {
    const { menus } = this.props;

    const menu = cloneDeep(record || {});

    menu.items = this.prepareMenuItems(menu.items);
    if (!menu.theme_id && menus?.length > 0) {
      menu.theme_menu_id = get(find(menus, { id: menu.id }), 'id', null);
    }
    return menu;
  }

  prepareMenuItems = (items) => {
    return (
      items &&
      items.map((item) => ({
        ...item,
        type: !item.value
          ? item.type === 'category'
            ? 'category_all'
            : item.type === 'product'
            ? 'product_all'
            : item.type
          : item.type,
        items: this.prepareMenuItems(item.items),
      }))
    );
  };

  cleanMenuItems = (items) => {
    return (
      items &&
      items.map((item) => ({
        name: item.name,
        options: item.options,
        type:
          item.type === 'category_all'
            ? 'category'
            : item.type === 'product_all'
            ? 'product'
            : item.type,
        model: item.type === 'content' ? item.model : undefined,
        value:
          item.type === 'category_all' || item.type === 'product_all'
            ? undefined
            : item.value,
        items: this.cleanMenuItems(item.items),
        $locale: item.$locale,
      }))
    );
  };

  async onSubmitForm(values) {
    const { storefront } = this.props;
    const items = this.cleanMenuItems(this.state.values.items);
    if (values.theme_menu_id) {
      values.theme_id =
        storefront.source_model === 'themes' ? storefront.source_id : null;
    } else {
      values.theme_id = null;
    }
    const success = await this.props.onSave({
      ...values,
      items,
    });
    if (success) {
      this.setState({ edited: false });
      if (this.props.onEdited) {
        this.props.onEdited(false);
      }
    }
  }

  onCloseItem = (event, canceled) => {
    if (this.state.itemEdited && !canceled) {
      event.preventDefault();
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.setState({ showingItem: false });
        },
      });
    } else {
      this.setState({ showingItem: false });
    }
  };

  onChangeItemForm = (values, edited) => {
    const { index, itemValues, itemId } = this.state;

    const item = index[itemId];

    let { value } = values;
    let { options } = values;

    if (values.type !== itemValues.type) {
      if (item && values.type === item.type) {
        value = item.value;
        options = item.options;
      } else {
        value = null;
        options = {};
      }
    } else if (values.model !== itemValues.model) {
      value = null;
      options = {};
    }

    this.setState({
      itemValues: {
        ...itemValues,
        ...values,
        value,
        options,
      },
      itemEdited: edited,
    });
  };

  onSubmitItemForm = (itemValues) => {
    const { index, itemId, itemParent, values } = this.state;

    if (!itemValues.name) {
      itemValues.name =
        itemValues.value?.name || typeLabel(itemValues.type) || 'Item';
    }

    if (itemId) {
      assign(index[itemId], itemValues);
      const edited = this.state.edited || this.state.itemEdited;
      this.setState({
        showingItem: false,
        edited,
      });
      if (this.props.onEdited) {
        this.props.onEdited(edited);
      }
    } else {
      const parent = itemParent || values;
      parent.items = parent.items || [];
      parent.items.push(itemValues);
      this.setState({
        showingItem: false,
        index: this.buildIndex(values),
        edited: true,
      });
      if (this.props.onEdited) {
        this.props.onEdited(true);
      }
    }
  };

  onClickDeleteItem = (event) => {
    event.preventDefault();
    this.context.openModal('ConfirmDelete', {
      title: 'this menu item',
      onConfirm: async () => {
        const { index, itemId } = this.state;
        const item = index[itemId];
        if (item.parent) {
          const itemIndex = item.parent.items.indexOf(item);
          if (itemIndex >= 0) {
            item.parent.items.splice(itemIndex, 1);
          }
        }
        index[itemId] = undefined;
        this.setState({
          showingItem: false,
          edited: true,
        });
        if (this.props.onEdited) {
          this.props.onEdited(true);
        }
      },
    });
  };

  onClickDeleteColumn = (event) => {
    event.preventDefault();
    this.setState({ itemId: event.currentTarget.dataset.id });
    this.onClickDeleteItem(event);
  };

  filterTypesWithConfig(itemTypes, allOptions) {
    if (!itemTypes) {
      return allOptions;
    }

    if (
      itemTypes.indexOf('category') >= 0 &&
      itemTypes.indexOf('category_all') === -1
    ) {
      itemTypes.push('category_all');
    }
    if (
      itemTypes.indexOf('product') >= 0 &&
      itemTypes.indexOf('product_all') === -1
    ) {
      itemTypes.push('product_all');
    }

    return allOptions.filter((op) => itemTypes.indexOf(op.value) >= 0);
  }

  renderParentName(parent) {
    return parent && parent.name ? String(parent.name).toLowerCase() : 'menu';
  }

  renderItemModal() {
    const { categories, lookup, content } = this.props;
    const { itemId, index, itemValues, itemParent, itemTypes } = this.state;
    const { client } = this.context;

    const item = index[itemId] || {};
    const options = itemValues.options || {};

    const typeOptions = this.filterTypesWithConfig(
      itemTypes,
      isExcludedClient(client) ? ITEM_OPTIONS_DEPRECATED : ITEM_OPTIONS,
    );

    return (
      <Form
        onSubmit={this.onSubmitItemForm}
        onChange={this.onChangeItemForm}
        autoFocus={!itemId}
      >
        <Modal
          title={item.name || `New ${this.renderParentName(itemParent)} item`}
          width={600}
          onClose={this.onCloseItem}
          actions={[
            { label: 'Save', type: 'submit' },
            itemId && {
              label: 'Delete',
              onClick: this.onClickDeleteItem,
              type: 'secondary',
              className: 'left button-cancel',
            },
          ]}
          localized={true}
        >
          <fieldset>
            <div className="row">
              <Field
                type="select"
                name="type"
                label="Type"
                defaultValue={item.type}
                required={true}
                options={typeOptions}
                placeholder="Choose"
                className="span2"
              />
              {itemValues.type === 'content' && (
                <Field
                  type="select"
                  name="model"
                  label="Content model"
                  defaultValue={item.model}
                  required={true}
                  options={content.collectionOptions.filter(({ value }) =>
                    value.startsWith('content/'),
                  )}
                  placeholder="Choose"
                  className="span2"
                />
              )}
            </div>
            {itemValues.type && (
              <p className="storefront-menu-help-tip note">
                <span className="help icon icon-info">&nbsp;</span>
                {isTypeValue(itemValues.type, 'product_all') ? (
                  <Fragment>Link to a page displaying all products.</Fragment>
                ) : isTypeValue(itemValues.type, 'category_all') ? (
                  <Fragment>Link to a page displaying all categories.</Fragment>
                ) : isTypeValue(itemValues.type, 'category') ? (
                  <Fragment>Link to a category of products.</Fragment>
                ) : isTypeValue(itemValues.type, 'product') ? (
                  <Fragment>
                    Link directly to a product or a page showing all products.
                  </Fragment>
                ) : isTypeValue(itemValues.type, 'content') ? (
                  <Fragment>
                    Link directly to a{' '}
                    {singularize(
                      removeModelNamespace(itemValues.model) || 'pages',
                    )}{' '}
                    content.
                  </Fragment>
                ) : isTypeValue(itemValues.type, 'home') ? (
                  <Fragment>Link directly to the home page.</Fragment>
                ) : isTypeValue(itemValues.type, 'search') ? (
                  <Fragment>
                    Link directly to the search page with an optional
                    pre-defined query showing product results.
                  </Fragment>
                ) : isTypeValue(itemValues.type, 'url') ? (
                  <Fragment>Link directly to a URL.</Fragment>
                ) : isTypeValue(itemValues.type, 'promotion') ? (
                  <Fragment>Display a promotion directly in the menu.</Fragment>
                ) : isTypeValue(itemValues.type, 'callout') ? (
                  <Fragment>Call out a specific product or feature.</Fragment>
                ) : (
                  isTypeValue(itemValues.type, 'heading') && (
                    <Fragment>
                      Display a heading above a set of menu items.
                    </Fragment>
                  )
                )}
              </p>
            )}
            {isTypeValue(itemValues.type, 'product') && (
              <LookupProduct
                name="value"
                label="Product"
                defaultValue={itemValues.value}
                lookup={lookup}
                required={true}
              />
            )}
            {isTypeValue(itemValues.type, 'category') && (
              <LookupCategory
                name="value"
                label="Category"
                defaultValue={itemValues.value}
                categories={categories}
                required={true}
              />
            )}
            {isTypeValue(itemValues.type, 'search') && (
              <Fragment>
                <Field
                  type="toggle"
                  name="options.query"
                  label="Enter a pre-defined search query"
                  defaultChecked={!!options.query}
                />
                <FadeIn active={!!options.query}>
                  <Field
                    type="text"
                    name="value"
                    label="Search query"
                    defaultValue={itemValues.value}
                    placeholder="Example: red shoes"
                    required={!!options.query}
                  />
                </FadeIn>
              </Fragment>
            )}
            {isTypeValue(itemValues.type, 'content') && itemValues.model && (
              <FadeIn>
                <LookupContent
                  name="value"
                  label={capitalize(
                    singularize(removeModelNamespace(itemValues.model)),
                  )}
                  model={itemValues.model}
                  defaultValue={itemValues.value}
                  lookup={lookup}
                  required={true}
                />
              </FadeIn>
            )}
            {isTypeValue(itemValues.type, 'url') && (
              <Fragment>
                <Field
                  type="text"
                  name="value"
                  label="Link URL"
                  defaultValue={itemValues.value}
                  placeholder="http://"
                  required={true}
                />
                <Field
                  type="checkbox"
                  name="options.target"
                  value="blank"
                  label="Open link in a new tab/window"
                  defaultChecked={options.target}
                />
              </Fragment>
            )}
            {itemValues.type && (
              <div className="row">
                <FieldLocalized
                  type="text"
                  name="name"
                  label="Label"
                  defaultValue={itemValues.name}
                  localeValue={itemValues.$locale}
                  placeholder={
                    itemValues.value?.name || typeLabel(itemValues.type)
                  }
                  className="span4"
                />
              </div>
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }

  renderColumnMaybe(config, items, parent, childRenderer) {
    const { values } = this.state;
    const columns = filter(items, (item) => item.type === 'column');
    const hasColumns = columns.length > 0;
    const hasNonColumns =
      filter(items, (item) => item.type !== 'column').length > 0;
    const configColumns = config && config.columns;
    if (configColumns || hasColumns) {
      return (
        <FlipMove typeName="ul" className="storefront-menu-columns">
          {false && hasNonColumns && (
            <li key="warning1" className="outside-warning">
              <Alert type="info">
                <Icon fa="warning" /> This menu has items outside of columns
                that may not be displayed
              </Alert>
            </li>
          )}
          {!!values.theme_menu_id && columns.length > 0 && !configColumns && (
            <li key="warning2" className="inside-warning">
              <Alert type="info">
                <Icon fa="warning" /> Columns may not be displayed correctly in
                this theme area
              </Alert>
            </li>
          )}
          {items.map(({ id, items: children = [] }, index) => {
            const colNum = index + 1;
            return (
              <li
                key={id}
                data-id={id}
                data-siblingid={items[index + 1] && items[index + 1].id}
                data-childid={children[0] && children[0].id}
                className={`column ${
                  config && !configColumns ? 'inside-warning' : ''
                }`}
              >
                <div className="category-item-wrapper">
                  <span className="category-info">
                    <span className="category-info-detail muted">
                      Column {colNum}
                    </span>
                    <span className="category-info-detail category-column">
                      {index > 0 && (
                        <a
                          href=""
                          onClick={this.onClickMoveUp}
                          data-id={id}
                          data-upid={items[index - 1].id}
                        >
                          <Icon fa="chevron-up" />
                        </a>
                      )}
                      {index + 1 < items.length && (
                        <a
                          href=""
                          onClick={this.onClickMoveDown}
                          data-id={id}
                          data-downid={items[index + 1].id}
                        >
                          <Icon fa="chevron-down" />
                        </a>
                      )}
                      <a
                        href=""
                        onClick={this.onClickDeleteColumn}
                        data-id={id}
                      >
                        <Icon fa="trash" />
                      </a>
                    </span>
                  </span>
                  <Droppable
                    types={['item']}
                    data-id={id}
                    data-child={true}
                    className="child"
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                  />
                  <div className={`category-dropzone child ${id}`}>
                    <div className="outline" />
                  </div>
                  {childRenderer(children, id, colNum)}
                </div>
              </li>
            );
          })}
          {(hasColumns || configColumns) && (
            <li>
              <div className="category-item category-item-add">
                {parent ? (
                  <a
                    className="category-item-detail"
                    href=""
                    onClick={this.onClickAddColumn}
                    data-parentid={parent.id}
                  >
                    <Icon fa="plus" />
                    &nbsp; <span>Add column</span>
                  </a>
                ) : (
                  <a
                    className="category-item-detail"
                    href=""
                    onClick={this.onClickAddColumn}
                  >
                    <Icon fa="plus" />
                    &nbsp; <span>Add column</span>
                  </a>
                )}
              </div>
            </li>
          )}
        </FlipMove>
      );
    }
    return childRenderer(items);
  }

  renderItemLink(item) {
    const { type, value, options, model } = item;
    const itemOption = find(ITEM_OPTIONS, { value: type });
    const linkValue = value && value.name ? value.name : value;

    if (itemOption && isEmpty(linkValue) && itemOption.requiresValue) {
      return null;
    }

    const linkDescription = isTypeValue(type, 'category_all') ? (
      <Fragment>All categories</Fragment>
    ) : isTypeValue(type, 'product_all') ? (
      <Fragment>All products</Fragment>
    ) : isTypeValue(type, 'category') ? (
      <Fragment>
        <Link to={`/categories/${value.id || value}`}>{linkValue}</Link>{' '}
        (category)
      </Fragment>
    ) : isTypeValue(type, 'product') ? (
      <Fragment>
        <Link to={`/products/${value.id || value}`}>{linkValue}</Link> (product)
      </Fragment>
    ) : isTypeValue(type, 'content') ? (
      <Fragment>
        {linkValue} ({singularize(removeModelNamespace(model)) || 'content'})
      </Fragment>
    ) : isTypeValue(type, 'home') ? (
      <Fragment>Home page</Fragment>
    ) : isTypeValue(type, 'search') ? (
      <Fragment>
        Search {options && options.query ? `for "${options.query}"` : 'page'}
      </Fragment>
    ) : isTypeValue(type, 'url') ? (
      <Fragment>
        Link to {value} {options && options.target && `(new tab)`}
      </Fragment>
    ) : (
      itemOption && <Fragment>{itemOption.label}</Fragment>
    );

    return linkDescription ? (
      <span className="category-item-products">
        <span className="arrow">&rarr;</span> {linkDescription}
      </span>
    ) : null;
  }

  renderChildren(items, level, parent = null, config = null) {
    if (!items || !items.length) {
      return null;
    }

    const { menuConfig } = this.state;
    const { client } = this.context;

    const isColumnContainer =
      config && config.child_items && config.child_items.columns;

    const canHaveChildren =
      !isExcludedClient(client) &&
      (menuConfig ? config && config.child_items : level < 3);

    return (
      <Fragment>
        {items.map((item, index) => {
          const { id, name, items: children = [] } = item;
          let hasNonDraggingChildren;
          if (children.length > 0) {
            if (children.length === 1) {
              if (children[0].id !== this.state.dragSourceId) {
                hasNonDraggingChildren = true;
              }
            } else {
              hasNonDraggingChildren = true;
            }
          }
          const isClosed = this.state.closed[id]; // || (this.state.closed[id] === undefined && !children.length);
          return (
            <li
              key={`${index}_${id}`}
              data-id={id}
              data-siblingid={items[index + 1] && items[index + 1].id}
              data-childid={children[0] && children[0].id}
            >
              <Draggable
                type="item"
                data-id={id}
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
                className="draggable"
              >
                {this.state.dragSourceId === id ? (
                  <div className="category-item-placeholder" />
                ) : (
                  <div className="category-item-wrapper">
                    <div
                      id={`item-${id}`}
                      className={`category-item ${
                        children.length > 0 ? 'with-children' : ''
                      }`}
                    >
                      <span draggable={false} className="category-item-detail">
                        <Link
                          draggable={false}
                          to={`/categories/${id}`}
                          onClick={this.onClickItem}
                          data-id={id}
                          data-itemtypes={config && config.item_types}
                        >
                          <span className="category-item-name">
                            {name || '-'}
                          </span>
                        </Link>
                        {this.renderItemLink(item)}
                      </span>
                      <span className="category-info">
                        <span className="category-info-detail category-handle">
                          <Icon type="drag-vertical" />
                        </span>
                      </span>
                    </div>
                    {children.length > 0 && (
                      <a
                        href=""
                        onClick={this.onClickExpand}
                        className={`category-item-expand ${
                          isClosed ? 'closed' : 'open'
                        } ${
                          children.length > 0
                            ? 'category-item-expand-has-children'
                            : 'category-item-expand-empty'
                        }`}
                        data-id={id}
                        style={
                          canHaveChildren ? undefined : { visibility: 'hidden' }
                        }
                      >
                        <Icon type="chevron" />
                      </a>
                    )}
                    <Droppable
                      types={['item']}
                      data-id={id}
                      data-sibling={true}
                      className={`sibling left ${
                        !canHaveChildren ||
                        (isColumnContainer && !children.length)
                          ? 'no-children'
                          : ''
                      }`}
                      onDragEnter={this.onDragEnter}
                      onDragLeave={this.onDragLeave}
                    />
                    <Droppable
                      types={['item']}
                      data-id={id}
                      data-sibling={true}
                      className="sibling right"
                      onDragEnter={this.onDragEnter}
                      onDragLeave={this.onDragLeave}
                    />
                    {(!config ||
                      !config.child_items ||
                      !config.child_items.columns) && (
                      <Fragment>
                        {canHaveChildren && (
                          <Droppable
                            types={['item']}
                            data-id={id}
                            data-child={true}
                            className="child"
                            onDragEnter={this.onDragEnter}
                            onDragLeave={this.onDragLeave}
                          />
                        )}
                      </Fragment>
                    )}
                    <div className={`category-dropzone child ${id}`}>
                      <div className="outline" />
                    </div>
                    <FadeIn active={!isClosed} transitionAppear={false}>
                      {this.renderColumnMaybe(
                        config && config.child_items,
                        children,
                        item,
                        (children, parentId, colNum) => {
                          return (
                            <ul>
                              {this.renderChildren(
                                children,
                                level + 1,
                                {
                                  id,
                                  name,
                                  parent,
                                },
                                config && config.child_items,
                              )}
                              {canHaveChildren && (
                                <li>
                                  <div className="category-item category-item-add">
                                    <a
                                      className="category-item-detail"
                                      href=""
                                      onClick={this.onClickAddItem}
                                      data-parentid={parentId || id}
                                    >
                                      <Icon fa="plus" />
                                      &nbsp;{' '}
                                      <span>
                                        Add{' '}
                                        {this.renderParentName({
                                          name,
                                        })}{' '}
                                        item{' '}
                                        {colNum > 0
                                          ? `to column ${colNum}`
                                          : ''}
                                      </span>
                                    </a>
                                  </div>
                                </li>
                              )}
                            </ul>
                          );
                        },
                      )}
                    </FadeIn>
                    {hasNonDraggingChildren && (
                      <div className="category-children-placeholder" />
                    )}
                    <div className={`category-dropzone sibling ${id}`}>
                      <div className="outline" />
                    </div>
                  </div>
                )}
              </Draggable>
            </li>
          );
        })}
      </Fragment>
    );
  }

  renderForm() {
    const { record = {}, inEditor, defaultMenuId, menuConfigs } = this.props;

    const { values, origValues, dragSourceId, menuConfig } = this.state;

    const menuItems = values.items || [];

    return (
      <Form
        onSubmit={this.onSubmitForm}
        onChange={this.onChangeForm}
        autoFocus={!record.id}
        ref="form"
      >
        <fieldset className="no-border">
          <div className="row">
            <Field
              type="text"
              name="name"
              label="Menu name"
              defaultValue={record.name}
              required={true}
              hint="For admin display only, not used by themes"
              className="span2"
            />
            <Field
              type="text"
              name="id"
              label="Alias"
              defaultValue={record.id}
              placeholder={snakeCase(values.name)}
              locked={!!record.id}
              hint="Used by themes to identify the menu"
              className="span2"
            />
          </div>
          {!inEditor && menuConfigs?.length > 0 && (
            <div className="row">
              <Field
                type="radio"
                name="theme_menu_id"
                label="Theme area"
                buttons={true}
                defaultValue={defaultMenuId || origValues.theme_menu_id}
                options={[
                  {
                    value: '',
                    label: 'Any',
                  },
                  ...menuConfigs.map((menu) => ({
                    value: menu.id,
                    label: menu.label || menu.name,
                  })),
                ]}
                placeholder="Optional"
                hint="Your theme defines menu layouts for different areas"
                className="span4"
              />
            </div>
          )}
        </fieldset>
        <div className="category-list menu">
          <div
            className={`category-list-categories ${!menuItems ? 'empty' : ''}`}
          >
            {this.renderColumnMaybe(
              menuConfig,
              menuItems,
              null,
              (children, parentId, colNum) => {
                return (
                  <ul className={`${dragSourceId ? 'dragging' : ''}`}>
                    {this.renderChildren(children, 1, null, menuConfig)}
                    <li>
                      <div className="category-item category-item-add">
                        <a
                          className="category-item-detail"
                          href=""
                          onClick={this.onClickAddItem}
                          data-itemtypes={menuConfig && menuConfig.item_types}
                          data-parentid={parentId}
                        >
                          <Icon fa="plus" />
                          &nbsp; <span>Add item</span>
                        </a>
                      </div>
                    </li>
                  </ul>
                );
              },
            )}
          </div>
        </div>
      </Form>
    );
  }

  render() {
    const { modalProps } = this.props;
    const { showingItem } = this.state;

    return (
      <Fragment>
        {modalProps ? (
          <Modal {...modalProps}>{this.renderForm()}</Modal>
        ) : (
          this.renderForm()
        )}
        {showingItem && this.renderItemModal()}
      </Fragment>
    );
  }
}
