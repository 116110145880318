import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { without } from 'lodash';
import Field from './field';
import { inflect } from 'utils';
import { webhookEventOptions } from 'constants/webhooks';

export default class FieldEvents extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.getInitialValue(props),
    };
  }

  componentWillReceiveProps(nextProps) {
    const isValueChanged =
      nextProps.value !== this.props.value ||
      nextProps.defaultValue !== this.props.defaultValue;
    if (isValueChanged) {
      this.setState({ value: this.getInitialValue(nextProps) });
    }
  }

  getInitialValue(props) {
    if (props.value !== undefined) {
      return (props.value instanceof Array ? props.value : []).sort();
    }
    return (
      props.defaultValue instanceof Array ? props.defaultValue : []
    ).sort();
  }

  setValue(value) {
    this.setState({ value: (value || []).sort() });
  }

  onChangeEvents = (event, value) => {
    this.setValue(value);
  };

  onClickRemove = (event) => {
    event.preventDefault();
    const { val } = event.currentTarget.dataset;
    this.setValue(without(this.state.value, val));
  };

  onClickRemoveAll = (event) => {
    event.preventDefault();
    this.setValue([]);
  };

  onClickReceiveAll = (event) => {
    event.preventDefault();
    this.setValue(
      webhookEventOptions.reduce(
        (acc, group) => [...acc, ...group.options],
        [],
      ),
    );
  };

  render() {
    const { value, className, ...props } = this.props;

    const { value: stateValue } = this.state;

    return (
      <span className={`form-events ${className ? className : ''}`}>
        <Field
          type="select"
          label="Events"
          placeholder="Select events"
          multiSelectable={true}
          groupSelectable={true}
          groupSelectLabel="events"
          {...props}
          ref="input"
          defaultValue={stateValue}
          options={webhookEventOptions}
          onChange={this.onChangeEvents}
        />
        <div className="form-events-list">
          {stateValue && stateValue.length > 0 ? (
            stateValue.map((val) => (
              <div className="form-events-list-value" key={val}>
                {val}
                <a
                  href=""
                  className="remove"
                  onClick={this.onClickRemove}
                  data-val={val}
                >
                  &times;
                </a>
              </div>
            ))
          ) : (
            <div className="form-events-list-empty">
              <p>
                <b>No events selected</b>
                <br />
                Select events from the dropdown above,
                <br /> or{' '}
                <a href="" onClick={this.onClickReceiveAll}>
                  receive all events
                </a>
                .
              </p>
            </div>
          )}
        </div>
        <div className="form-events-list-foot">
          {stateValue && stateValue.length > 0 ? (
            <Fragment>
              {inflect(stateValue.length, 'events')} selected
              <a href="" className="remove-all" onClick={this.onClickRemoveAll}>
                Clear all
              </a>
            </Fragment>
          ) : (
            <Fragment>&nbsp;</Fragment>
          )}
        </div>
      </span>
    );
  }
}
