// @flow
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import BulkEdit from './BulkEdit';
import actions from 'actions';

const STANDARD_OPTIONS = [
  {
    label: 'Tags',
    name: 'tags',
    type: 'tags',
  },
  {
    label: 'Active',
    name: 'active',
    type: 'toggle',
    defaultChecked: true,
    help: 'An active product is visible in store listings',
  },
  {
    label: 'List price',
    name: 'price',
    type: 'currency',
  },
  {
    label: 'On sale',
    name: 'sale',
    type: 'toggle',
    defaultChecked: true,
  },
  {
    label: 'Sale price',
    name: 'sale_price',
    type: 'currency',
  },
  {
    label: 'Track inventory',
    name: 'stock_tracking',
    type: 'toggle',
    defaultChecked: true,
  },
  {
    label: 'Tax code',
    name: 'tax_code',
    type: 'text',
  },
  {
    label: 'Allow purchase when out of stock',
    name: 'stock_purchasable',
    type: 'toggle',
    defaultChecked: true,
  },
];

const mapStateToProps = (state) => ({
  attributes: state.attributes,
  client: state.client,
});

const mapDispatchToProps = (dispatch) => ({
  loadAttributes: async () => {
    return dispatch(actions.attributes.load());
  },
});

export class BulkEditProductsModal extends React.PureComponent {
  static contextTypes = {
    notifyError: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  static propTypes = {
    client: PropTypes.shape({
      currency: PropTypes.string.isRequired,
    }).isRequired,
    loadAttributes: PropTypes.func.isRequired,
  };

  state = {};

  constructor(props: Object) {
    super(props);
    this.state = {
      loaded: false,
      optionsWithAttributes: null,
    };
  }

  componentWillMount() {
    const { loadAttributes } = this.props;

    loadAttributes().then((attrs) => {
      const attributeOptions = attrs.results
        .map((attr) => {
          const props = this.getAttributeInputProps(attr);
          if (props) {
            return {
              ...props,
              label: attr.name,
              name: `attributes.${attr.id}`,
              required: !!attr.required,
            };
          }
          return null;
        })
        .filter((x) => !!x);
      this.setState({
        loaded: true,
        optionsWithAttributes: [...STANDARD_OPTIONS, ...attributeOptions],
      });
    });
  }

  getAttributeInputProps(attribute, value) {
    if (!attribute) {
      return;
    }
    switch (attribute.type) {
      case 'text':
      case 'textarea':
        return {
          type: attribute.multi ? 'textarea' : 'text',
          rows: attribute.multi ? 5 : undefined,
        };
      case 'dropdown':
        return {
          type: 'select',
          options: attribute.values || [],
        };
      case 'multiselect':
      case 'checkbox':
        return {
          type: 'checkbox',
          options: attribute.values || [],
          stacked: true,
        };
      case 'radio':
        return {
          type: 'radio',
          options: attribute.values || [],
          stacked: true,
        };
      case 'image':
        if (attribute.multi) {
          return;
        }
        return {
          type: 'images',
          single: attribute.multi ? false : true,
          wide: false,
          maxWidth: 478,
          minWidth: 0,
          minHeight: 0,
        };
      default:
        return {};
    }
  }

  render() {
    const {
      client: { currency },
    } = this.props;

    return (
      <BulkEdit
        label="Products"
        loaded={this.state.loaded}
        defaultStoreCurrency={currency}
        options={this.state.optionsWithAttributes}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkEditProductsModal);
