import React from 'react';
import PropTypes from 'prop-types';
import { get, map } from 'lodash';
import ObjectID from 'bson-objectid';

import { SUBSCRIPTION_INTERVAL_OPTIONS } from 'utils/subscription';

import { Field, FieldLocalized, Fieldtable } from 'components/form';

import '../product.scss';

function getDefaultOptionValue(props) {
  const { values, name } = props;

  const option = values[name] || {};
  const vals = get(option, 'values', []);

  if (vals.length > 0) {
    return { ...option };
  }

  const defaultOption = SUBSCRIPTION_INTERVAL_OPTIONS[0];

  return {
    name: 'Plan',
    ...option,
    variant: true,
    subscription: true,
    values: [
      {
        name: defaultOption.label,
        subscription_interval: defaultOption.value,
        price: '',
        description: '',
        subscription_trial_days: '',
        subscription_interval_count: '',
        separate_fulfillment: false,
        fulfillment_interval: defaultOption.value,
        fulfillment_interval_count: '',
      },
    ],
  };
}

export default class DeprecatedSubscriptionOption extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      prevValues: null,
      value: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.values !== state.prevValues) {
      return {
        prevValues: props.values,
        value: getDefaultOptionValue(props),
      };
    }

    return null;
  }

  getDisabledOptions(selfInterval) {
    const list = map(
      get(this.props.values.subscription_option, 'values'),
      (val) => val.subscription_interval,
    );

    if (selfInterval) {
      return list.filter((val) => val !== selfInterval);
    }

    return list;
  }

  onToggleSeparateFulfillment = (_ev, value, component) => {
    const idx = component.props['data-index'];
    if (!value) {
      const updated = {
        ...this.state.value,
        values: this.state.value.values.map((val, i) => {
          if (i === idx) {
            return {
              ...val,
              fulfillment_interval: '',
              separate_fulfillment: false,
              fulfillment_interval_count: '',
            };
          }
          return val;
        }),
      };
      this.setState({ value: updated });
    }
  };

  renderTableValue = ({ value, index }) => {
    const { record = {} } = this.props;
    const interval = value.subscription_interval;

    const option = SUBSCRIPTION_INTERVAL_OPTIONS.find(
      (value) => value === interval,
    );

    return [
      <td key="1" width="125">
        <Field
          type="select"
          name="subscription_interval"
          options={SUBSCRIPTION_INTERVAL_OPTIONS}
          disabledOptions={this.getDisabledOptions(value.subscription_interval)}
          defaultValue={value.subscription_interval}
          required={true}
          data-index={index}
        />

        <Field
          type="hidden"
          name="name"
          value={option ? option.label : value.name}
          data-index={index}
        />
      </td>,

      <td key="2" width="125">
        <FieldLocalized
          type="currency"
          name="price"
          defaultValue={value.price}
          localeValue={value.$currency}
          currency={record.currency}
          required={true}
          autoFocus={true}
          data-index={index}
        />
      </td>,

      <td key="3" width="65">
        <Field
          type="number"
          name="subscription_trial_days"
          defaultValue={value.subscription_trial_days}
          placeholder="0"
          data-index={index}
        />
      </td>,

      <td key="4">
        <FieldLocalized
          type="textarea"
          name="description"
          defaultValue={value.description}
          localeValue={value.$locale}
          placeholder="Optional"
          autoSize={true}
          rows={1}
          maxRows={5}
          data-index={index}
        />
      </td>,
    ];
  };

  onAddRow = () => {
    const disabledOps = this.getDisabledOptions();

    for (const op of SUBSCRIPTION_INTERVAL_OPTIONS) {
      if (!disabledOps.includes(op.value)) {
        return {
          name: op.label,
          subscription_interval: op.value,
          fulfillment_interval: op.value,
          separate_fulfillment: false,
        };
      }
    }
  };

  render() {
    const { disabled, name } = this.props;

    const option = this.state.value;

    if (!option) {
      return null;
    }

    return (
      <React.Fragment>
        <Field
          type="hidden"
          name={`${name}.id`}
          value={option.id || ObjectID().toHexString()}
        />

        <Field
          type="hidden"
          name={`${name}.name`}
          value={option.name || 'Plan'}
        />

        <Field type="hidden" name={`${name}.variant`} value={true} />

        <Field type="hidden" name={`${name}.subscription`} value={true} />

        <Fieldtable
          label="Plan option"
          name={`${name}.values`}
          key={this.props.isBundle} // Hack to rerender table when this changes
          chromeless={true}
          defaultValue={option.values}
          renderValue={this.renderTableValue}
          disabled={disabled}
          sortable={true}
          addable={option.values.length < SUBSCRIPTION_INTERVAL_OPTIONS.length}
          removable={option.values.length > 1}
          inline={true}
          onAddRow={this.onAddRow}
        />
      </React.Fragment>
    );
  }
}
