import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import { formatDate } from 'utils';
import { productThumbUrl, productName } from 'utils/product';
import {
  getShippableItems,
  renderShipmentWeightDescription,
} from 'utils/order';
import { hasLocalizedContent } from 'utils/content';

import Link from 'components/link';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import ContentFields from 'components/content/fields';
import { FadeIn } from 'components/transitions';

import OrderItemDetail from './item-detail';

export default class OrderFulfillmentAdd extends React.PureComponent {
  static propTypes = {
    record: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    content: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    shipment: PropTypes.object,
    onClickFulfillmentAdd: PropTypes.func.isRequired,
    onClickFulfillmentDelete: PropTypes.func,
    onSubmitFulfillmentAdd: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { record, shipment = {} } = props;
    const shippableItems = getShippableItems(
      record.items,
      shipment.items,
    ).filter((item) => item.quantity_deliverable > 0);

    this.state = {
      values: {
        ...shipment,
        items: this.getInitialItemValues(shippableItems),
      },
      shippableItems,
      serviceOptions: this.getServiceOptions(props),
      carrierOptions: this.getCarrierOptions(props),
    };
  }

  getInitialItemValues(shippableItems) {
    return shippableItems.map((item) => ({
      quantity: item.quantity_deliverable,
    }));
  }

  getServiceOptions(props) {
    const {
      record,
      settings: {
        shipments: { services },
      },
    } = props;

    return [
      ...services
        .filter(
          (service) =>
            service.enabled ||
            find(record.shipments.results, { service: service.id }),
        )
        .map((service) => ({
          value: service.id,
          label: service.name,
          carrier: service.carrier,
        })),
    ];
  }

  getCarrierOptions(props) {
    return [
      ...props.settings.shipments.carriers.map((carrier) => ({
        value: carrier.id,
        label: carrier.name,
      })),
    ];
  }

  onChangeForm = (values) => {
    this.setState({
      values: {
        ...this.state.values,
        ...values,
        items: [...values.items],
      },
    });
  };

  onSubmitForm = (values) => {
    if (!this.hasSelectedItems(values.items)) {
      return;
    }

    const service = find(this.props.settings.shipments.services, {
      id: values.service,
    });

    const carrier = find(this.props.settings.shipments.carriers, {
      id: (service && service.carrier) || values.carrier,
    });

    this.props.onSubmitFulfillmentAdd({
      ...values,
      items: this.mapItemsFromRecord(values.items),
      service: service ? service.id : null,
      service_name: values.service_name || (service && service.name),
      carrier: carrier ? carrier.id : null,
      carrier_name: values.carrier_name || (carrier && carrier.name),
      $notify: values.$notify ? true : false,
    });
  };

  mapItemsFromRecord(itemValues) {
    const { shippableItems } = this.state;
    const finalItems = itemValues
      .map((item, index) => {
        const { id, product_id, variant_id, bundle_item_id } =
          shippableItems[index];
        return {
          product_id,
          variant_id,
          bundle_item_id,
          order_item_id: id,
          quantity: item.quantity,
        };
      })
      .filter((i) => i.quantity > 0);
    return finalItems;
  }

  hasSelectedItems(items = []) {
    return items.some((item) => item.quantity > 0);
  }

  render() {
    const {
      record,
      shipment = {},
      loading,
      settings,
      content,
      onClickFulfillmentAdd,
      onClickFulfillmentDelete,
    } = this.props;

    const { values, serviceOptions, carrierOptions, shippableItems } =
      this.state;

    const hasItems = this.hasSelectedItems(values.items);

    const serviceId =
      values.service !== undefined
        ? values.service
        : shipment.id
        ? shipment.service
        : record.shipping.service;

    const service =
      serviceId &&
      find(this.props.settings.shipments.services, {
        id: serviceId,
      });

    const serviceCarrier =
      service &&
      find(this.props.settings.shipments.carriers, {
        id: service.carrier,
      });

    return (
      <Form
        onSubmit={this.onSubmitForm}
        onChange={this.onChangeForm}
        values={values}
      >
        <Modal
          title={
            shipment.id ? `Edit shipment ${shipment.number}` : 'Add shipment'
          }
          actions={[
            {
              label: 'Save',
              type: hasItems ? 'submit' : 'secondary',
              disabled: hasItems ? false : true,
            },
            shipment.id
              ? {
                  label: 'Delete',
                  type: 'secondary',
                  className: 'left button-cancel',
                  onClick: onClickFulfillmentDelete,
                }
              : null,
            { label: 'Cancel', type: 'cancel', onClick: onClickFulfillmentAdd },
          ]}
          width={700}
          cancel={false}
          loading={loading}
          onClose={onClickFulfillmentAdd}
          devtools={{
            model: 'shipments',
            uri: shipment.id,
            console: !!shipment.id,
            zone: 'order',
          }}
          localized={hasLocalizedContent(content.models, 'shipments', 'order')}
        >
          <fieldset>
            {!!shipment.date_created && (
              <p>Created {formatDate(shipment.date_created, 'shortExact')}</p>
            )}

            <div className="collection-table-container">
              <table className="collection-table">
                <thead>
                  <tr>
                    <th colSpan="2">Shippable Items</th>
                    <th>Weight</th>
                    <th>Quantity</th>
                  </tr>
                </thead>

                <tbody>
                  {shippableItems.map((item, index) => {
                    const { quantity } = values.items[index];

                    const zero = !(quantity > 0);

                    return (
                      <tr
                        key={item.id || index}
                        style={zero ? { opacity: 0.5 } : undefined}
                      >
                        <td className="image">
                          <span className="collection-table-images">
                            <span className="image">
                              <Link to={`/products/${item.product_id}`}>
                                <img
                                  src={productThumbUrl(
                                    item.product,
                                    item.variant,
                                  )}
                                  alt={productName(item.product, item.variant)}
                                />
                              </Link>
                            </span>
                          </span>
                        </td>

                        <td>
                          <OrderItemDetail
                            item={item}
                            style={
                              zero
                                ? { textDecoration: 'line-through' }
                                : undefined
                            }
                          />
                        </td>

                        <td>
                          <div className="collection-field-padded">
                            {renderShipmentWeightDescription(
                              settings,
                              item.shipment_weight || 1,
                            )}
                          </div>
                        </td>

                        <td className="nowrap">
                          <Field
                            type="number"
                            name={`items.${index}.quantity`}
                            defaultValue={!zero ? quantity : ''}
                            maxValue={item.quantity_deliverable}
                            placeholder="0"
                            className="order-line-items-qty-field"
                            selectFocus={true}
                            tabIndex={index + 1}
                          />

                          <div className="order-line-items-qty-desc">
                            of {item.quantity_deliverable}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <Field
              type="select"
              label="Shipping service"
              name="service"
              placeholder="Optional"
              defaultValue={serviceId || ''}
              options={serviceOptions}
              tabIndex={shippableItems.length + 2}
            />

            <div className="row">
              <Field
                type="text"
                label={
                  serviceCarrier
                    ? `${serviceCarrier.name} tracking number`
                    : 'Tracking number'
                }
                name="tracking_code"
                placeholder="Optional"
                defaultValue={shipment.tracking_code || ''}
                className={!service || !serviceCarrier ? 'span2' : 'span4'}
                autoComplete="off"
                tabIndex={shippableItems.length + 3}
              />

              {(!service || !serviceCarrier) && (
                <FadeIn transitionAppear={false} className="span2">
                  <Field
                    type="select"
                    label="Shipping carrier"
                    name="carrier"
                    placeholder="Optional"
                    defaultValue={shipment.carrier || ''}
                    options={carrierOptions}
                    tabIndex={shippableItems.length + 4}
                  />
                </FadeIn>
              )}
            </div>

            <Field
              type="checkbox"
              label="Send email confirmation to customer"
              name="$notify"
              defaultChecked={
                shipment.$notify !== undefined ? shipment.$notify : true
              }
              tabIndex={shippableItems.length + 5}
            />

            <Field
              type="textarea"
              name="notes"
              label="Notes"
              defaultValue={shipment.notes}
              placeholder="Optional"
              autoSize={true}
              rows={2}
              help="Fulfillment notes are not visible to the customer"
            />
          </fieldset>

          <ContentFields
            {...this.props}
            zone="order"
            collection="shipments"
            models={content.models}
            record={shipment}
            values={values}
            currency={record.currency}
          />
        </Modal>
      </Form>
    );
  }
}
