import React from 'react';

import { BLANK_IMAGE_URL } from 'utils';

import { fieldWrapper } from './product';

function CategoryProductPlaceholder() {
  return (
    <tr>
      <td {...fieldWrapper.image}>
        <span className="collection-table-images">
          <span className="image">
            <img src={BLANK_IMAGE_URL} alt="placeholder" />
          </span>
        </span>
      </td>

      <td>
        <div className="category-product-name-placeholder" />
      </td>

      <td>
        <div className="category-product-price-placeholder" />
      </td>

      <td {...fieldWrapper.actions} />
    </tr>
  );
}

export default React.memo(CategoryProductPlaceholder);
