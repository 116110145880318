import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import Link from 'components/link';
import LinkButton from 'components/button/link';
import { classNames } from 'utils';

export default class ModelLink extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func,
  };

  static propTypes = {
    model: PropTypes.string.isRequired,
    zone: PropTypes.string,
    tooltip: PropTypes.bool,
    className: PropTypes.string,
  };

  onClick = (event) => {
    event.preventDefault();

    const { zone, model } = this.props;

    this.context.openModal('EditModel', {
      zone,
      params: { id: `com.${encodeURIComponent(model)}` },
    });
  };

  render() {
    const {
      className,
      model,
      tooltip,
      buttonType = undefined,
      icon = true,
    } = this.props;

    const Wrapper = buttonType === null ? Link : LinkButton;

    return (
      <Wrapper
        to={`/settings/models/com.${encodeURIComponent(model)}`}
        className={classNames('model-link', className)}
        onClick={this.onClick}
        type={buttonType !== undefined ? buttonType : 'sub button-sm'}
      >
        {tooltip ? (
          <Tooltip message="Edit model fields">
            <Icon fa="cube" />
          </Tooltip>
        ) : (
          <Fragment>
            {icon !== false && (
              <Fragment>
                <Icon fa="cube" />
                &ensp;
              </Fragment>
            )}
            Edit fields
          </Fragment>
        )}
      </Wrapper>
    );
  }
}
