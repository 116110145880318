import React from 'react';
import { Link as RouterLink } from 'react-router';
import { getBaseAndTestEnvPath } from 'utils/container';

export default class Link extends React.PureComponent {
  getDisabledProps() {
    const { disabled, className } = this.props;

    return disabled
      ? {
          onClick: (event) => {
            event.preventDefault();
            event.stopPropagation();
          },
          className: className ? `${className} disabled` : 'disabled',
        }
      : {};
  }

  render() {
    const { to, children, ...props } = this.props;
    const { baseUri } = getBaseAndTestEnvPath();

    if (!to) {
      return (
        <a
          href=""
          {...props}
          onClick={
            props.onClick ||
            ((event) => {
              event.preventDefault();
            })
          }
          className={props.className}
        >
          {children}
        </a>
      );
    }

    const toString = String(to);
    const toWithBase =
      toString.indexOf('http') === 0 || toString.indexOf('mailto') === 0
        ? toString
        : baseUri && toString.indexOf(baseUri) !== 0
        ? `${baseUri}${toString}`.replace(/\/$/, '')
        : toString;

    return (
      <RouterLink {...props} to={toWithBase} {...this.getDisabledProps()}>
        {children}
      </RouterLink>
    );
  }
}
