import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Tabs } from 'components/form';
import Loading from 'components/loading';
import { FadeInUp } from 'components/transitions';
import LocaleSelector from 'components/locale/selector';
import HelpLink from 'components/help-link';
import { classNames } from 'utils';
import ModalActions from './actions';
import ButtonLink from 'components/button/button-link';

import 'components/view/view.scss';
import './modal.scss';

let MODAL_COUNT = 0;

export default class Modal extends React.PureComponent {
  static propTypes = {
    devtools: PropTypes.object,
  };

  static contextTypes = {
    closeModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: !props.loading,
      mounting: Boolean(props.loading),
      wasLoading: Boolean(props.loading),
    };

    this.unmounted = false;
  }

  componentDidMount() {
    MODAL_COUNT += 1;
  }

  componentDidUpdate(prevProps) {
    if (!this.state.loaded && prevProps.loading && !this.props.loading) {
      setTimeout(() => {
        if (!this.unmounted) {
          this.setState({ loaded: true });
        }
      }, 50);
    }
  }

  componentWillUnmount() {
    MODAL_COUNT -= 1;

    this.unmounted = true;
  }

  onMountBody = () => {
    setTimeout(() => {
      if (!this.unmounted) {
        this.setState({ mounting: false });
      }
    }, 250);
  };

  onClickClose = (event, canceled = undefined) => {
    if (this.props.onClose) {
      this.props.onClose(event, canceled);
      if (event.defaultPrevented) {
        return;
      }
    }
    event.preventDefault();
    this.context.closeModal();
  };

  onClickCancel = (event) => {
    this.onClickClose(event, true);
  };

  onClick = (event) => {
    const { closable = true, children, tapout: tapoutProp } = this.props;
    let tapout =
      tapoutProp !== null && tapoutProp !== undefined ? tapoutProp : true;

    if (tapout) {
      React.Children.forEach(children, (child) => {
        if (child?.type === 'fieldset') {
          tapout = false;
        }
      });
    }

    if (get(event, 'target.id') === 'modal' && closable && tapout) {
      this.onClickClose(event);
    }
  };

  renderLoading() {
    const { loadingTitle } = this.props;

    return (
      <Fragment>
        {loadingTitle && (
          <FadeInUp className="modal-loading-title" duration={450}>
            <h4>{loadingTitle}</h4>
          </FadeInUp>
        )}

        <Loading className="modal-loading" />
      </Fragment>
    );
  }

  render() {
    const {
      title,
      subtitle,
      actions,
      cancel = true,
      closable = true,
      loading = false,
      chromeless = false,
      headless = false,
      width,
      minWidth,
      maxWidth,
      className,
      children,
      cancelText = 'Cancel',
      loadingTitle,
      localized,
      externalLink,
      modalIndex = 0,
      devtools,
      tabs,
      helpLink = true,
      overrideHelpLinkKey = null,
      cancelSize,
      icon = null,
    } = this.props;

    const { loaded, mounting, wasLoading } = this.state;

    const isLoading = (!loaded || loadingTitle) && loading;
    const hasTabs = tabs && tabs.length > 0;

    return (
      <div
        id="modal"
        className={classNames('modal', className)}
        onClick={this.onClick}
      >
        {(isLoading || mounting) && (
          <div className="modal-container-loading">{this.renderLoading()}</div>
        )}

        {loaded && !isLoading && (
          <FadeInUp
            duration={350}
            transitionDelay={wasLoading || MODAL_COUNT >= 3 ? 0 : 200}
            distance="40px"
            className="modal-container"
            onMount={this.onMountBody}
          >
            <div
              className={classNames('modal-window', {
                'modal-window-loading': loading,
                chromeless: chromeless,
                'with-tabs': hasTabs,
                headless,
              })}
              style={{
                width,
                minWidth,
                maxWidth,
                marginTop: modalIndex > 0 ? modalIndex * 30 : undefined,
              }}
            >
              <div className={`modal-header ${hasTabs ? 'has-tabs' : ''}`}>
                {title && (
                  <h4 className="modal-header-title">
                    {title}

                    {helpLink && (
                      <HelpLink
                        sectionName={title}
                        isModal={true}
                        overrideKey={overrideHelpLinkKey}
                      />
                    )}
                    {icon && icon}
                  </h4>
                )}

                {subtitle && (
                  <div className="modal-header-subtitle">{subtitle}</div>
                )}

                <div className="view-header-actions modal-header-actions">
                  {localized && <LocaleSelector />}
                  {externalLink && (
                    <ButtonLink
                      type="secondary"
                      size="sm"
                      to={externalLink.link}
                    >
                      {externalLink.label}
                    </ButtonLink>
                  )}
                </div>

                {closable && (
                  <button
                    className="modal-header-close"
                    onClick={this.onClickClose}
                    type="button"
                  />
                )}
                {hasTabs && <Tabs name="tab" items={tabs} />}
              </div>

              <div className="modal-body">{children}</div>

              {(actions === undefined || actions) && (
                <ModalActions
                  actions={actions}
                  devtools={devtools}
                  loading={loading}
                  cancel={cancel}
                  cancelText={cancelText}
                  cancelSize={cancelSize}
                  onClickCancel={this.onClickCancel}
                />
              )}
            </div>
          </FadeInUp>
        )}
      </div>
    );
  }
}
