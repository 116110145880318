import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { Form, LookupCategories } from 'components/form';
import BulkProgress from './progress';
import { BULK_RUNNING_WARNING } from 'utils/messages';
import './bulk.scss';

export default class BulkCategories extends React.PureComponent {
  static propTypes = {
    bulk: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
  }

  onClose(event) {
    if (this.props.bulk.running && !confirm(BULK_RUNNING_WARNING)) {
      event.preventDefault();
      return;
    }
    this.props.onClose(event);
  }

  onClickCancel(event) {
    event.preventDefault();
    if (this.props.bulk.running && !confirm(BULK_RUNNING_WARNING)) {
      return;
    }
    this.props.onClose(event);
  }

  render() {
    const {
      title,
      hint,
      bulk: { running, percent, complete },
      onSubmit,
      loaded,
      categories,
    } = this.props;

    return (
      <Form onSubmit={onSubmit}>
        <Modal
          title={title}
          width={700}
          className="bulk-categories-edit-modal"
          actions={[
            { label: 'Save', type: 'submit' },
            { label: 'Cancel', type: 'cancel', onClick: this.onClickCancel },
          ]}
          cancel={false}
          onClose={this.onClose}
          loading={loaded === false}
        >
          <fieldset>
            {running || complete ? (
              <BulkProgress percent={percent} />
            ) : (
              <Fragment>
                <LookupCategories
                  name="categories"
                  label={hint}
                  categories={categories}
                />
              </Fragment>
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
