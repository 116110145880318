import React from 'react';
import classNames from 'classnames';
import each from 'lodash/each';
import pt from 'prop-types';

import { inflect } from 'utils';

import {
  buildCategoryIndex,
  buildCategoryList,
  cloneCategoryResults,
  assignProductCounts,
} from 'actions/categories';

import { Form, Field } from 'components/form';
import ButtonLink from 'components/button/button-link';
import CollectionEmpty from 'components/collection/empty';
import { FadeIn, FadeOut, FadeLoading } from 'components/transitions';
import HelpLink from 'components/help-link';

import ListCategories from './list-categories';

import './category.scss';

/**
 * @param {object[]} list
 * @returns {string}
 */
function serializeList(list) {
  return list.reduce(
    (acc, curr) =>
      `${acc}${curr.id}|${curr.active ? 'active' : 'inactive'}>${
        curr.children ? serializeList(curr.children) : ''
      }:`,
    '',
  );
}

export default class CategoryList extends React.Component {
  static propTypes = {
    loading: pt.bool,
    location: pt.object,
    categories: pt.object,

    onSubmitSearch: pt.func,
    onSubmitCategories: pt.func,
    onClickNewCategory: pt.func,
    onClickEditCategory: pt.func,
  };

  static contextTypes = {
    client: pt.object.isRequired,
  };

  constructor(props) {
    super(props);

    const results = cloneCategoryResults(props.categories.results);
    const index = buildCategoryIndex(results);
    const list = buildCategoryList(index);

    assignProductCounts(index, props.categories.productCounts);

    this.state = {
      results,
      index,
      list,
      listSerialized: serializeList(list),
      versions: new Map(),
      edited: false,
    };

    this.searchRef = React.createRef();
    this.searchFormRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.categories.results !== prevProps.categories.results) {
      const results = cloneCategoryResults(this.props.categories.results);
      const index = buildCategoryIndex(results);
      const list = buildCategoryList(index);

      assignProductCounts(index, this.props.categories.productCounts);

      this.setState({
        results,
        index,
        list,
        versions: new Map(),
        edited: false,
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.searchTimer);
  }

  onChangeActiveForm = (values) => {
    const { index } = this.state;

    each(values, (category, id) => {
      const item = index.get(id);

      if (item) {
        item.active = Boolean(category.active);
      }
    });

    this.setState((state) => {
      const newList = buildCategoryList(state.index);

      return {
        edited: state.listSerialized !== serializeList(newList),
      };
    });
  };

  onClickSave = async (event) => {
    event.preventDefault();
    await this.props.onSubmitCategories(this.state.results);
    this.setState({ edited: false });
  };

  onSubmitSearch = (values) => {
    this.props.onSubmitSearch(values);
  };

  onKeyDownSearch = (event) => {
    switch (event.key) {
      case 'Esc':
      case 'Escape': {
        this.searchRef.current.setState({ value: '' });
        this.props.onSubmitSearch({ search: '' });
        break;
      }

      default: {
        clearTimeout(this.searchTimer);

        this.searchTimer = setTimeout(() => {
          this.searchFormRef.current.submit();
        }, 300);

        break;
      }
    }
  };

  increaseParentsVersion(parentId) {
    if (!parentId) {
      return;
    }

    const { index, versions } = this.state;

    for (
      let parent = index.get(parentId);
      parent;
      parent = index.get(parent.parent_id)
    ) {
      const version = versions.get(parent.id) || 0;

      versions.set(parent.id, version + 1);
    }
  }

  /**
   * Update category version to redraw components after moving
   *
   * @param {object} source
   * @param {object} target
   */
  updateCategoriesVersion(source, target) {
    this.increaseParentsVersion(source.parent_id);
    this.increaseParentsVersion(target.parent_id);
  }

  moveCategories = (dragSourceId, dragTargetId, dragTargetNode) => {
    const { results, index, list } = this.state;

    const source = index.get(dragSourceId);
    const target = index.get(dragTargetId);

    if (!source || !target) {
      return;
    }

    this.updateCategoriesVersion(source, target);

    let sortOrder = 0;

    if (dragTargetNode.dataset.child) {
      // Target is new parent
      source.parent_id = target.id;
      // Start sort from the top
      source.sort = sortOrder;
      // Re-sort children after top
      target.children = target.children || [];
      target.children.forEach((child) => {
        if (child.id !== source.id) {
          sortOrder = child.sort = sortOrder + 1;
        }
      });
    } else if (dragTargetNode.dataset.sibling) {
      // Target is sibling
      source.parent_id = target.parent_id;
      // Start sort from target
      sortOrder = source.sort = target.sort + 1;
      // Re-sort siblings after target
      const childList = target.parent ? target.parent.children : list;
      let foundSibling = false;
      childList.forEach((child) => {
        if (child.id === target.id) {
          foundSibling = true;
        } else if (foundSibling) {
          if (child.id !== source.id) {
            sortOrder = child.sort = sortOrder + 1;
          }
        }
      });
    }

    // Remove links from index/results
    for (const category of index.values()) {
      delete category.children;
      delete category.parent;
    }

    // Re-build index/list
    const newIndex = buildCategoryIndex(results);
    const newList = buildCategoryList(newIndex);
    assignProductCounts(newIndex, this.props.categories.productCounts);

    this.setState((state) => ({
      index: newIndex,
      list: newList,
      edited: state.listSerialized !== serializeList(newList),
    }));
  };

  render() {
    const { loading, categories, location, onClickNewCategory } = this.props;

    const { list, edited } = this.state;

    const { found } = categories;

    return (
      <div className="category-list">
        <div className="view-container">
          <FadeLoading
            active={loading}
            duration={50}
            className="view-loading-mask"
          />

          <div className="view-header detail">
            <div className="view-header-title">
              <h2 className="view-header-name">Categories</h2>
            </div>

            <div className="view-header-actions">
              <div className="view-header-actions-buttons">
                {edited && (
                  <FadeIn component="span" active={edited}>
                    <ButtonLink onClick={this.onClickSave}>Save</ButtonLink>
                  </FadeIn>
                )}

                {categories.totalCount > 0 && (
                  <ButtonLink
                    to="/categories/new"
                    onClick={onClickNewCategory}
                    type={edited ? 'secondary' : 'default'}
                  >
                    New category
                  </ButtonLink>
                )}
              </div>
            </div>
          </div>

          <div className="view-body detail">
            {categories.totalCount > 0 ? (
              <>
                <Form ref={this.searchFormRef} onSubmit={this.onSubmitSearch}>
                  <Field
                    ref={this.searchRef}
                    name="search"
                    autoComplete="off"
                    placeholder="Search categories"
                    className="collection-search-field"
                    value={undefined}
                    defaultValue={
                      location.query.search || categories.found.query
                    }
                    onKeyDown={this.onKeyDownSearch}
                  />
                </Form>

                {found.query && (
                  <FadeIn active={true} duration={250}>
                    <div className="category-search-note">
                      Found {inflect(found.count, 'categories')} matching "
                      {found.query}"
                    </div>
                  </FadeIn>
                )}

                {categories.count > 0 && (
                  <Form onChange={this.onChangeActiveForm}>
                    <div
                      className={classNames('category-list-categories', {
                        empty: categories.count === 0,
                      })}
                    >
                      <ListCategories
                        index={this.state.index}
                        found={categories.found}
                        categories={list}
                        versions={this.state.versions}
                        moveCategories={this.moveCategories}
                        onClickEditCategory={this.props.onClickEditCategory}
                      />
                    </div>
                  </Form>
                )}

                {categories.count > 0 && (
                  <div className="category-search-note">
                    {`Showing ${
                      found.query ? ` ${found.count} of` : ''
                    } ${inflect(categories.count, 'categories')}`}
                  </div>
                )}
              </>
            ) : (
              <CollectionEmpty
                {...this.props}
                uri="/categories"
                title="Categories"
                emptyDescription="Use categories to organize your products into groups for customers to discover, and to apply discounts and promomtions selectively."
              />
            )}
          </div>

          <FadeOut duration={100} className="view-loading-mask" />

          <div className="category-help-link">
            <HelpLink />
          </div>
        </div>
      </div>
    );
  }
}
