import React from 'react';
import logo from './swell-purple.svg';
import './logo-inline.scss';

export default class LogoInline extends React.PureComponent {
  render() {
    return (
      <a href={`https://www.swell.is`} className="swell-logo-inline">
        <img className="swell-logo" src={logo} alt="Swell" />
      </a>
    );
  }
}
