import React from 'react';
import PropTypes from 'prop-types';
import View from 'components/view';
import Side from 'components/view/side';
import Status from 'components/status';
import DateTime from 'components/date-time';
import { TabView, Fieldgroup } from 'components/form';
import AppIcon from './icon';
import AppLocations from './locations';
import AppPermissions from './permissions';
import AppConfigs from './configs';
import AppSettings from './settings';

export default class AppView extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func,
    notifySuccess: PropTypes.func,
    notifyWarning: PropTypes.func,
  };

  onClickUpdate = (event) => {
    event.preventDefault();
    const { app, installedApp, updateApp } = this.props;

    this.context.openModal('Confirm', {
      title: `Update ${app.name}`,
      message: (
        <p>
          Update the app from version {installedApp.version} to {app.version}?
        </p>
      ),
      action: 'Update app',
      onConfirm: () => {
        updateApp(app.id, { version: app.version }).then(() =>
          this.context.notifySuccess(
            `${app.name} version updated to ${app.version}`,
          ),
        );
      },
    });
  };

  onClickEnable = (event) => {
    event.preventDefault();
    const { app, updateApp } = this.props;

    this.context.openModal('Confirm', {
      title: `Enable ${app.name}`,
      message: <p>This will restore all app fields and events.</p>,
      action: 'Enable app',
      onConfirm: () => {
        updateApp(app.id, { active: true });
      },
    });
  };

  onClickDisable = (event) => {
    event.preventDefault();
    const { app, updateApp } = this.props;

    this.context.openModal('Confirm', {
      title: `Disable ${app.name}`,
      message: (
        <p>
          This will disable all app fields and events. You can re-enable the app
          at a later time.
        </p>
      ),
      action: 'Disable app',
      actionType: 'danger',
      onConfirm: () => {
        updateApp(app.id, { active: false });
      },
    });
  };

  onClickUninstall = (event) => {
    event.preventDefault();
    const { app, uninstallApp, router } = this.props;

    const appId = app.public_id || app.private_id.replace(/^\_/, '');
    this.context.openModal('ConfirmDelete', {
      titleMessage: `Uninstall ${app.name}`,
      message: (
        <p>
          This will permanently remove the app and all of its data from your
          store. Proceed with caution.
        </p>
      ),
      confirmInput: {
        description: `Enter the app ID to confirm`,
        value: appId,
      },
      action: 'Uninstall app',
      actionType: 'danger',
      onConfirm: () => {
        uninstallApp(app.id).then(() => {
          router.replace('/apps');
          this.context.notifyWarning(`${app.name} has been uninstalled`);
        });
      },
    });
  };

  getSettingContentModels() {
    const { appConfigs, app } = this.props;

    const settingConfigs = appConfigs.results.filter(
      (config) => config.type === 'setting',
    );

    return settingConfigs.map((config) => ({
      ...config.values,
      collection: 'settings',
      root: config.values.name,
      appSettingsField: true,
      app,
    }));
  }

  render() {
    const { app, installedApp, location, edited } = this.props;

    const tab = location.query.tab || 'default';
    const settingContentModels = this.getSettingContentModels();
    const developer = app.partner || app.client;
    const supportWebsite = developer.support_website || app.support_website;
    const supportEmail = developer.support_email || app.support_email;
    const supportPhone = developer.support_phone || app.support_phone;

    return (
      <div className="apps view-detail-full">
        <View
          detail={true}
          uri="/apps"
          sectionTitle="Apps"
          headerTitle={<>{app.name}</>}
          headerStatus={
            <>
              {installedApp.active === false ? (
                <Status type="muted">Disabled</Status>
              ) : (
                installedApp.version &&
                app.version > installedApp.version && (
                  <Status type="primary" dot={false}>
                    Update available
                  </Status>
                )
              )}
              {app.client_id === installedApp.parent_id &&
                installedApp.environment_id === 'test' && (
                  <Status type="warning">Development</Status>
                )}
            </>
          }
          headerSubtitle={app.description}
          headerImage={
            <AppIcon image={app.logo_icon} name={app.name} size={60} />
          }
          headerActions={[
            tab === 'settings' && {
              label: 'Save settings',
              type: edited ? 'default' : 'secondary',
              onClick: () => this.refs.appSettings.refs.form.submit(),
            },
          ]}
          menuActions={[
            app.updateAvailable && {
              label: 'Update available',
              onClick: this.onClickUpdate,
            },
            installedApp.active === false
              ? {
                  label: 'Enable app',
                  onClick: this.onClickEnable,
                }
              : {
                  label: 'Disable app',
                  onClick: this.onClickDisable,
                },
          ]}
          extraActions={[
            {
              label: 'Uninstall app',
              onClick: this.onClickUninstall,
              className: 'danger',
            },
          ]}
          tabs={{
            default: { label: 'Overview' },
            ...(settingContentModels.length > 0 && {
              settings: { label: 'Settings' },
            }),
          }}
        >
          <TabView value={tab} active="default">
            <fieldset className="last-child">
              <div className="view-body-section-group">
                <AppLocations {...this.props} />
                <AppPermissions {...this.props} />
                <Fieldgroup label="App configurations" className="normal">
                  <AppConfigs {...this.props} />
                </Fieldgroup>
              </div>
            </fieldset>
          </TabView>

          <TabView value={tab} active="settings">
            <AppSettings
              {...this.props}
              ref="appSettings"
              settingContentModels={settingContentModels}
            />
          </TabView>

          {tab === 'default' && (
            <Side>
              <div className="view-side-container">
                <section className="view-side-section">
                  <div className="view-side-section-header">
                    <h4>Developer</h4>
                  </div>
                  <div className="view-side-body">
                    <div>{developer.name}</div>
                  </div>
                </section>
                <section className="view-side-section">
                  <div className="view-side-section-header">
                    <h4>Current version</h4>
                  </div>
                  <div className="view-side-body">
                    {installedApp.version || app.version}
                    &nbsp;&nbsp;
                    {app.updateAvailable && (
                      <Status type="primary" dot={false}>
                        Update available
                      </Status>
                    )}
                  </div>
                </section>
                {supportWebsite && (
                  <section className="view-side-section">
                    <div className="view-side-section-header">
                      <h4>Website</h4>
                    </div>
                    <div className="view-side-body">{supportWebsite}</div>
                  </section>
                )}
                {supportEmail && (
                  <section className="view-side-section">
                    <div className="view-side-section-header">
                      <h4>Support email</h4>
                    </div>
                    <div className="view-side-body">{supportEmail}</div>
                  </section>
                )}
                {supportPhone && (
                  <section className="view-side-section">
                    <div className="view-side-section-header">
                      <h4>Support phone</h4>
                    </div>
                    <div className="view-side-body">{supportPhone}</div>
                  </section>
                )}
                {app.published_id && (
                  <section className="view-side-section">
                    <div className="view-side-section-header">
                      <h4>App ID</h4>
                    </div>
                    <div className="view-side-body">
                      <code>{app.published_id}</code>
                    </div>
                  </section>
                )}
                {(!app.public_id ||
                  installedApp.app_slug_id !== app.public_id) && (
                  <section className="view-side-section">
                    <div className="view-side-section-header">
                      <h4>Private ID</h4>
                    </div>
                    <div className="view-side-body">
                      <code>{installedApp.app_slug_id}</code>
                    </div>
                  </section>
                )}
                <section className="view-side-section">
                  <div className="view-side-section-header">
                    <h4>Installed</h4>
                  </div>
                  <div className="view-side-body">
                    <DateTime
                      value={
                        installedApp.date_installed || installedApp.date_created
                      }
                      format="age"
                    />
                  </div>
                </section>
              </div>
            </Side>
          )}
        </View>
      </div>
    );
  }
}
