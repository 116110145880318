import React from 'react';
import PropTypes from 'prop-types';
import { get, last, reduce, capitalize } from 'lodash';

import Link from 'components/link';
import { Fieldgroup } from 'components/form';
import Status from 'components/status';
import Tooltip from 'components/tooltip';
import DateTime from 'components/date-time';
import Alert from 'components/alert';
import SectionHeader from '../section-header/section-header';
import { formatCurrencyRounded, formatDate, formatCurrency } from 'utils';
import { renderPaymentSourceDetails } from 'utils/order';
import { countryName } from 'utils/geo';
import './account.scss';

export default class AccountBilling extends React.PureComponent {
  static contextTypes = {
    session: PropTypes.object,
    isOwner: PropTypes.bool,
  };

  static propTypes = {
    user: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    onClickEditPayment: PropTypes.func.isRequired,
  };

  invoiceHasAccountCredit(invoice) {
    if (invoice.items && invoice.items.length > 0) {
      return String(last(invoice.items).description).toLowerCase() ===
        'account credit'
        ? true
        : false;
    }
    return false;
  }

  invoiceAccountCreditAmount(invoice) {
    return last(invoice.items).price;
  }

  renderInvoiceList(invoices) {
    return invoices.map((invoice) => (
      <tr key={invoice.id}>
        <td width="25%">
          <Link to={`/settings/account/invoices/${invoice.id}`} target="blank">
            {invoice.number}
          </Link>
        </td>
        <td width="25%">
          {formatCurrencyRounded(invoice.grand_total, invoice.currency)}
          {this.invoiceHasAccountCredit(invoice) && (
            <span className="muted">
              &nbsp; (
              {formatCurrency(
                this.invoiceAccountCreditAmount(invoice),
                invoice.currency,
              )}{' '}
              credited)
            </span>
          )}
        </td>
        <td width="25%">
          <DateTime value={invoice.date_created} format="age" />
        </td>
        <td width="25%">
          {invoice.paid ? (
            <Status type="positive">Paid</Status>
          ) : invoice.payment_error ? (
            <Tooltip message={invoice.payment_error}>
              <Status type="negative">
                Error
                <span className="help icon icon-info">&nbsp;</span>
              </Status>
            </Tooltip>
          ) : (
            <Status>{capitalize(invoice.status)}</Status>
          )}
        </td>
      </tr>
    ));
  }

  render() {
    const {
      user,
      client,
      account: {
        plan,
        isEnterprise,
        record: { email, user_email, billing: accountBilling },
      },
      onClickEditPayment,
    } = this.props;

    const { isOwner, session } = this.context;
    const { parent_id } = client;

    const billing = accountBilling || {};
    const isAccountBilling = billing.method === 'account' || isEnterprise;
    const hasCard = billing.method === 'card' && get(billing, 'card.brand');

    const canViewInvoices =
      (isEnterprise && user.view_invoices) || isOwner || session.is_super_admin;

    const clientInvoices = reduce(
      ((plan && plan.clients) || {}).results,
      (acc, childClient) => [
        ...acc,
        ...(
          (childClient.account && childClient.account.invoices) ||
          []
        ).results.map((invoice) => ({
          ...invoice,
          account_client_id: childClient.account.client_id,
          account_name: childClient.account.name,
        })),
      ],
      [],
    ).sort((a, b) => (a.date_created > b.date_created ? -1 : 1));

    return (
      <>
        {canViewInvoices && !parent_id && (
          <fieldset className="full last-child">
            <div className="view-body-subheader">
              <SectionHeader
                className="view-body-subtitle"
                title="Billing information"
              />
            </div>
            {isEnterprise && (
              <Alert>
                Contact your{' '}
                <a href="mailto:success@swell.store">enterprise support team</a>{' '}
                for more account details and billing assistance.
              </Alert>
            )}
            {!isEnterprise && plan.last_payment && plan.last_payment.error && (
              <Alert type="warning">
                Your last payment of{' '}
                {formatCurrency(
                  plan.last_payment.amount,
                  plan.last_payment.currency,
                )}{' '}
                failed on{' '}
                {formatDate(plan.last_payment.date_created, 'shortExact')}.{' '}
                <a href="" className="box-action" onClick={onClickEditPayment}>
                  Update payment details
                </a>
              </Alert>
            )}
            <div className="box account-billing">
              <div className="box-section">
                <div className="box-grid">
                  <div className="box-column">
                    {isAccountBilling ? (
                      <>
                        {billing.company && <div>{billing.company}</div>}
                        {billing.name && <div>{billing.name}</div>}
                        <div>{billing.email || user_email || email}</div>
                      </>
                    ) : (
                      <div>{renderPaymentSourceDetails(billing, 'card')}</div>
                    )}
                  </div>
                  <div className="box-column">
                    <a href="" onClick={onClickEditPayment}>
                      {isAccountBilling || hasCard ? 'Edit' : 'Add'}
                    </a>
                  </div>
                </div>
              </div>
              {billing &&
                ((!isAccountBilling && billing.name) || billing.address1) && (
                  <div className="box-section tight">
                    {!isAccountBilling && (
                      <>
                        {billing.name && (
                          <div className="box-title">{billing.name}</div>
                        )}
                      </>
                    )}
                    {billing.address1 && (
                      <div className="box-title">{billing.address1}</div>
                    )}
                    {billing.address2 && (
                      <div className="box-title">{billing.address2}</div>
                    )}
                    <div className="box-title">
                      {billing.city && `${billing.city}, `}
                      {billing.state && `${billing.state} `}
                    </div>
                    {billing.zip && (
                      <div className="box-title">{billing.zip}</div>
                    )}
                    {billing.country && (
                      <div className="box-title">
                        {countryName(billing.country)}
                      </div>
                    )}
                    {billing.vat_number && (
                      <div className="box-title">VAT: {billing.vat_number}</div>
                    )}
                  </div>
                )}
            </div>
          </fieldset>
        )}
        <br />
        {canViewInvoices &&
          plan.invoices &&
          (plan.invoices.count > 0 || clientInvoices.length > 0) && (
            <fieldset className="full">
              <table className="collection-table outer">
                <thead>
                  <tr>
                    <th>Invoices</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {plan.invoices.count > 0 && (
                        <>
                          <table className="collection-table inner last">
                            <thead>
                              <tr>
                                <th width="25%">Invoice</th>
                                <th width="25%">Amount</th>
                                <th width="25%">Date</th>
                                <th width="25%">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.renderInvoiceList(
                                plan.invoices.results.slice(0, 3),
                              )}
                            </tbody>
                          </table>
                          {plan.invoices.results.length > 3 && (
                            <Fieldgroup
                              label="View all invoices"
                              className="normal small last account-all-invoices"
                            >
                              <table className="collection-table inner">
                                <tbody>
                                  {this.renderInvoiceList(
                                    plan.invoices.results.slice(3),
                                  )}
                                </tbody>
                              </table>
                            </Fieldgroup>
                          )}
                        </>
                      )}
                      {clientInvoices.length > 0 && (
                        <>
                          <div className="collection-table-subheading">
                            <h3 className="collection-table-subtitle">
                              Invoices from connected accounts
                            </h3>
                          </div>
                          <table className="collection-table inner">
                            <thead>
                              <tr>
                                <th>Invoice</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Store</th>
                              </tr>
                            </thead>
                            <tbody>
                              {clientInvoices.map((invoice) => (
                                <tr key={invoice.id}>
                                  <td>
                                    <Link
                                      to={`/settings/account/invoices/${invoice.id}`}
                                      target="blank"
                                    >
                                      {invoice.number}
                                    </Link>
                                  </td>
                                  <td>
                                    {formatCurrencyRounded(
                                      invoice.grand_total,
                                      invoice.currency,
                                    )}
                                    {this.invoiceHasAccountCredit(invoice) && (
                                      <span className="muted">
                                        &nbsp; (
                                        {formatCurrency(
                                          this.invoiceAccountCreditAmount(
                                            invoice,
                                          ),
                                          invoice.currency,
                                        )}{' '}
                                        credited)
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <DateTime
                                      value={invoice.date_created}
                                      format="age"
                                    />
                                  </td>
                                  <td>
                                    {invoice.paid ? (
                                      <Status type="positive">Paid</Status>
                                    ) : invoice.payment_error ? (
                                      <Tooltip message={invoice.payment_error}>
                                        <Status type="negative">
                                          Error
                                          <span className="help icon icon-info">
                                            &nbsp;
                                          </span>
                                        </Status>
                                      </Tooltip>
                                    ) : (
                                      <Status>
                                        {capitalize(invoice.status)}
                                      </Status>
                                    )}
                                  </td>
                                  <td>
                                    <Link
                                      to={`${CLIENT_URL.replace(
                                        'CLIENT_ID',
                                        invoice.account_client_id,
                                      )}/admin`}
                                      target="blank"
                                    >
                                      {invoice.account_name}
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          )}
      </>
    );
  }
}
