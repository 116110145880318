import React from 'react';
import PropTypes from 'prop-types';

import View from 'components/view';
import { Form, Field } from 'components/form';
import SectionHeader from 'components/section-header';
import { FadeIn } from 'components/transitions';

import SubscriptionRetryRules from './subscription-retry-rules';

import './settings.scss';

/**
 * @template T
 * @typedef {Object} SelectOption
 * @property {T} value - The value for the option
 * @property {string} label - The text that is displayed to the user for the option
 *
 */

/**
 * Get the number of days for select options
 * @param {number} number
 * @returns {SelectOption[]} The select options
 */
function getDays(number) {
  return Array(number)
    .fill('')
    .map((_, idx) => {
      const value = idx + 1;
      return {
        value,
        label: value === 1 ? '1 day' : `${value} days`,
      };
    });
}

export default class SubscriptionSettings extends React.PureComponent {
  static propTypes = {
    values: PropTypes.object.isRequired,
    edited: PropTypes.bool.isRequired,

    onSubmitForm: PropTypes.func.isRequired,
    onChangeForm: PropTypes.func.isRequired,
    onClickEditNotification: PropTypes.func.isRequired,
    updateNotificationsEnabled: PropTypes.func.isRequired,
    deleteNotification: PropTypes.func.isRequired,
  };

  onSubmitForm = async (values) => {
    const { updateNotificationsEnabled } = this.props;

    const result = await this.props.onSubmitForm(values);

    // If retries are enabled, ensure retry notifications are also enabled.
    if (result !== false && result?.features?.auto_payment_retry) {
      const enabled = values.retry_rules?.reduce((acc, rule) => {
        if (rule?.send_notification) {
          acc[rule.notification_id] = true;
        }
        return acc;
      }, {});

      if (enabled && Object.keys(enabled).length > 0) {
        await updateNotificationsEnabled(enabled);
      }
    }
  };

  render() {
    const {
      values,
      edited,
      onChangeForm,
      onClickEditNotification,
      deleteNotification,
    } = this.props;

    return (
      <div className="settings settings-subscriptions">
        <Form ref="form" onSubmit={this.onSubmitForm} onChange={onChangeForm}>
          <View
            detail={true}
            uri="/settings"
            sectionTitle="Settings"
            headerTitle="Subscriptions"
            headerSubtitle="Manage settings that affect subscriptions"
            headerActions={[
              {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
          >
            <div className="view-body-section-group">
              <fieldset className="full last-child">
                <div className="view-body-section-group">
                  <div className="view-body-section">
                    <div className="view-body-subheader">
                      <SectionHeader
                        className="view-body-subtitle"
                        title="Charge automatically"
                        subtitle=" Subscription invoices will be charged automatically when
                      due"
                      />
                      <div className="view-body-actions">
                        <Field
                          type="toggle"
                          name="features.auto_invoice_payment"
                          defaultChecked={values.features.auto_invoice_payment}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="view-body-section">
                    <div className="view-body-subheader">
                      <SectionHeader
                        className="view-body-subtitle"
                        title="Dunning and retry rules"
                        subtitle="Configure automatic retries to occur when credit card
                      payments fail"
                      />
                      <div className="view-body-actions">
                        <Field
                          type="toggle"
                          name="features.auto_payment_retry"
                          defaultChecked={
                            values.features.auto_payment_retry === undefined
                              ? values.retry_count > 0
                              : values.features.auto_payment_retry
                          }
                        />
                      </div>
                    </div>

                    <FadeIn
                      active={Boolean(values.features.auto_payment_retry)}
                    >
                      <div className="view-section-content">
                        <SubscriptionRetryRules
                          name="retry_rules"
                          rules={values.retry_rules}
                          onChange={onChangeForm}
                          onClickEditNotification={onClickEditNotification}
                          deleteNotification={deleteNotification}
                        />
                      </div>
                    </FadeIn>
                  </div>
                </div>
              </fieldset>

              <fieldset className="full">
                <div className="view-body-section">
                  <div className="view-body-subheader">
                    <SectionHeader
                      className="view-body-subtitle"
                      title="Pausing"
                      subtitle="Rules affecting how customers pause and resume subscriptions"
                    />
                  </div>

                  <div className="view-section-content">
                    <div className="view-body-subsection">
                      <div className="view-body-subheader">
                        <div>
                          <h3 className="view-body-subtitle">
                            Pause during trial
                          </h3>
                          <p>
                            Allow subscriptions to be paused during trial period
                          </p>
                        </div>
                        <div className="view-body-actions">
                          <Field
                            type="toggle"
                            name="features.pause_during_trial"
                            defaultChecked={values.features.pause_during_trial}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="view-body-subsection">
                      <div className="view-body-subheader">
                        <div>
                          <h3 className="view-body-subtitle">
                            Pause when past due
                          </h3>
                          <p>
                            Allow subscriptions to be paused if payment is past
                            due
                          </p>
                        </div>
                        <div className="view-body-actions">
                          <Field
                            type="toggle"
                            name="features.pause_past_due"
                            defaultChecked={values.features.pause_past_due}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="view-body-subsection">
                      <div className="view-body-subheader">
                        <div>
                          <h3 className="view-body-subtitle">
                            Skip next interval
                          </h3>
                          <p>
                            Allow customers to skip the next interval of their
                            subscription
                          </p>
                        </div>
                        <div className="view-body-actions">
                          <Field
                            type="toggle"
                            name="features.pause_skip_next"
                            defaultChecked={values.features.pause_skip_next}
                          />
                        </div>
                      </div>

                      {values.features.pause_skip_next && (
                        <div className="subsection-content contained">
                          <FadeIn
                            transitionAppear={true}
                            active={values.features.pause_skip_next || 1}
                          >
                            <div className="row">
                              <Field
                                type="select"
                                label="Number of days before next shipment by which a customer must skip"
                                name="pause_next_skip_threshold"
                                defaultValue={values.pause_next_skip_threshold}
                                options={getDays(21)}
                              />
                            </div>
                          </FadeIn>
                        </div>
                      )}
                    </div>

                    <div className="view-body-subsection">
                      <div className="view-body-subheader">
                        <div>
                          <h3 className="view-body-subtitle">
                            Pause indefinitely
                          </h3>
                          <p>
                            Allow customers to pause their subscription
                            indefinitely
                          </p>
                        </div>
                        <div className="view-body-actions">
                          <Field
                            type="toggle"
                            name="features.pause_indefinitely"
                            defaultChecked={values.features.pause_indefinitely}
                          />
                        </div>
                      </div>

                      {values.features.pause_indefinitely && (
                        <FadeIn
                          transitionAppear={true}
                          active={values.features.pause_indefinitely}
                        >
                          <div className="subsection-content contained">
                            <div className="row">
                              <Field
                                type="select"
                                label="When subscription is resumed, when is the next shipment"
                                name="pause_next_shipping"
                                defaultValue={
                                  values.pause_next_shipping ||
                                  'next_new_standard'
                                }
                                options={[
                                  {
                                    value: 'next_revert',
                                    label:
                                      'Next available shipping date, then revert to standard schedule',
                                  },
                                  {
                                    value: 'next_new_standard',
                                    label:
                                      'Next available shipping date, which becomes new standard ship date',
                                  },
                                  {
                                    value: 'next_available',
                                    label:
                                      'Next standard shipment date available',
                                  },
                                ]}
                              />
                            </div>
                            <div className="row">
                              <Field
                                type="select"
                                label="When subscription is resumed, when is the next billing date"
                                name="pause_next_billing"
                                defaultValue={
                                  values.pause_next_billing ||
                                  'subscription_resumed'
                                }
                                disabledOptions={['product_shipped']}
                                options={[
                                  {
                                    value: 'subscription_resumed',
                                    label: 'When subscription is resumed',
                                  },
                                  {
                                    value: 'product_shipped',
                                    label:
                                      'Next time product is shipped (coming soon)',
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </FadeIn>
                      )}
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </View>
        </Form>
      </div>
    );
  }
}
