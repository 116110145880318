import React, { Fragment } from 'react';
import pt from 'prop-types';

import { snakeCase } from 'utils';
import {
  countryName,
  countryOptions,
  stateOptions,
  countryStateLabel,
} from 'utils/geo';

import { Field, Fieldtable } from 'components/form';

export default class ShippingLocations extends React.PureComponent {
  static propTypes = {
    values: pt.array.isRequired,
    suggestedAddresses: pt.array,

    onChangeForm: pt.func,
    onSubmit: pt.func,
  };

  onSubmitModal = (values) => {
    if (!values.id) {
      values.id = snakeCase(values.name);
    }
  };

  onAddress = (option) => {
    const { onChangeForm } = this.props;

    if (option) {
      onChangeForm({
        address1: option.suggestedAddress.street_line,
        address2:
          option.suggestedAddress.entries < 2
            ? option.suggestedAddress.secondary
            : '',
        city: option.suggestedAddress.city,
        country: 'US',
        state: option.suggestedAddress.state,
        zip: option.suggestedAddress.zipcode,
      });
    }
  };

  renderLocationHeading() {
    return [<th key="1">Name</th>, <th key="2">Address</th>];
  }

  renderLocationValue({ value }) {
    return [
      <td key="1">{value.name || 'Default'}</td>,
      <td key="2">
        {value.address1 && <div>{value.address1}</div>}
        {value.address2 && <div>{value.address2}</div>}
        <div>
          {value.city && `${value.city}, `}
          {value.state && `${value.state} `}
        </div>
        {value.zip && <div>{value.zip}</div>}
        {value.country && <div>{countryName(value.country)}</div>}
      </td>,
    ];
  }

  renderLocationForm = (values) => {
    const { suggestedAddresses, values: storeLocationValues } = this.props;

    return (
      <Fragment>
        <Field type="hidden" name="id" defaultValue={values.id} />
        <Field
          type="text"
          name="name"
          label="Location name"
          defaultValue={values.name || ''}
          required={true}
          hint="Used to identify the location in your admin dashboard"
        />
        <Field
          type="address"
          onAddress={this.onAddress}
          options={suggestedAddresses}
          name="address1"
          label="Street address"
          defaultValue={storeLocationValues.address1 || values.address1}
          required
          values={values}
          debounce={300}
        />
        <Field
          type="text"
          name="address2"
          label="Apt, suite, etc"
          defaultValue={values.address2}
        />
        <div className="row">
          <Field
            type="text"
            name="city"
            label="City"
            defaultValue={storeLocationValues.city || values.city}
            required={true}
            className="span2"
          />
          <Field
            type="text"
            name="zip"
            label="Zip/postal code"
            defaultValue={storeLocationValues.zip || values.zip}
            className="span2"
          />
        </div>
        <div className="row">
          <Field
            type="select"
            name="country"
            label="Country"
            defaultValue={storeLocationValues.country || values.country}
            options={countryOptions}
            required={true}
            className="span2"
          />
          <Field
            type={stateOptions[values.country] ? 'select' : 'text'}
            name="state"
            label={countryStateLabel(values.country)}
            defaultValue={storeLocationValues.state || values.state}
            options={stateOptions[values.country]}
            className="span2"
          />
        </div>
      </Fragment>
    );
  };

  render() {
    const { values, onSubmit } = this.props;

    return (
      <Fieldtable
        label="Store location"
        name="locations"
        defaultValue={values.locations}
        onSubmitModal={this.onSubmitModal}
        renderHeading={this.renderLocationHeading}
        renderValue={this.renderLocationValue}
        renderForm={this.renderLocationForm}
        onSubmit={onSubmit}
        formWidth={550}
        sortable={true}
        transitionAppear={false}
      />
    );
  }
}
