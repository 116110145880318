import React from 'react';
import Modal from 'components/modal';
import ContentFields from 'components/content/fields';
import { Form } from 'components/form';
import OrderAddressForm from 'components/pages/order/address-form';
import { isEmpty } from 'utils';
import { hasLocalizedContent } from 'utils/content';

export default class CustomerBillingEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: props.record,
    };
    this.onChangeForm = this.onChangeForm.bind(this);
  }

  onChangeForm(values) {
    this.setState({
      values: {
        billing: {
          ...this.state.values.billing,
          ...values.billing,
        },
      },
    });
  }

  render() {
    const {
      record,
      content,
      onClickBillingEdit,
      onSubmitBillingEdit,
      suggestedAddresses,
    } = this.props;
    const { values } = this.state;

    return (
      <Form
        onSubmit={onSubmitBillingEdit}
        onChange={this.onChangeForm}
        autoFocus={true}
      >
        <Modal
          title="Edit billing address"
          className="customer-view-modal-edit-billing"
          actions={[
            { label: 'Save', type: 'submit' },
            { label: 'Cancel', type: 'cancel', onClick: onClickBillingEdit },
          ]}
          width={600}
          cancel={false}
          onClose={onClickBillingEdit}
          localized={hasLocalizedContent(content.models, 'accounts')}
          devtools={{
            model: 'accounts',
            uri: `${record.id}/shipping`,
            zone: 'billing',
            console: !isEmpty(values.billing),
          }}
        >
          <fieldset>
            <OrderAddressForm
              suggestedAddresses={suggestedAddresses}
              name="billing"
              record={values.billing}
              values={values.billing}
              onChangeForm={this.onChangeForm}
            />
            <ContentFields
              {...this.props}
              zone="billing"
              collection="accounts"
              models={content.models}
              record={record}
              values={values}
              currency={record.currency}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
