import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { bool, func, object, string } from 'prop-types';
import actions from 'actions';
import { formatDate } from 'utils';
import { currencyValueRound } from 'utils/money';
import { billingPriceByInterval } from 'shared/billing';
import { PLANS } from 'shared/plans';
import Field from 'components/form/field';
import { Form } from 'components/form';

const mapStateToProps = (state) => ({
  account: state.account,
  client: state.client,
});

const mapDispatchToProps = (dispatch) => ({
  flashSuccess: (message) => {
    dispatch(actions.flash.success(message));
  },
});

class PaymentDetails extends React.PureComponent {
  static propTypes = {
    account: object,
    client: object,
    currentPlan: string,
    isMonthly: bool,
    onSubmitActivatePlan: func,
  };

  render() {
    const { account, client, currentPlan, isMonthly, onSubmitActivatePlan } =
      this.props;

    const plan = find(PLANS, { id: currentPlan });

    const trialChargeInfo = {
      community: 'Your card will not be charged until receiving live orders',
      standard2:
        currentPlan &&
        `After your free trial ends (${formatDate(
          client.date_trial_end,
          'short',
        )}) you'll be
        charged $${currencyValueRound(
          billingPriceByInterval(
            plan,
            isMonthly ? 'monthly' : 'yearly',
            1,
            account.record,
          ),
          'USD',
        )}`,
      enterprise: null,
    };
    return (
      <Form onSubmit={onSubmitActivatePlan}>
        <div className="payment-container">
          <div className="billing-price-container">
            <span className="billing-price">
              $
              {currencyValueRound(
                billingPriceByInterval(
                  plan,
                  isMonthly ? 'monthly' : 'yearly',
                  1,
                  account.record,
                ),
                'USD',
              )}
            </span>
            <span className="billing-interval">
              billed {isMonthly ? 'monthly' : 'annually'}
            </span>
          </div>
          <div className="payment-method-container">
            <span className="billing-container">
              <Field
                type="card-number"
                label="CREDIT CARD NUMBER"
                name="billing.card.number"
                rules={'required, credit_card_number'}
                labelClass="billing-label"
                required
              />
            </span>
            <span className="cc-container">
              <Field
                type="card-exp"
                label="EXPIRATION"
                name="billing.card.exp"
                rules={'required, credit_card_exp'}
                labelClass="billing-label"
                required
              />
            </span>
            <span className="cc-container">
              <Field
                type="text"
                label="CVC"
                name="billing.card.cvc"
                placeholder="***"
                rules={'required, credit_card_cvc'}
                labelClass="billing-label"
                required
              />
            </span>
          </div>
          <div className="trial-container">
            <span className="trial-end">{trialChargeInfo[currentPlan]}</span>
            <button className="button button-default button-sm" type="submit">
              Activate
            </button>
          </div>
          <Field type="hidden" name="plan" value={currentPlan} label="plan" />
          <Field
            type="hidden"
            name="interval"
            value={isMonthly ? 'monthly' : 'yearly'}
            label="interval"
          />
          <Field
            type="hidden"
            name="interval_count"
            value={1}
            label="interval_count"
          />
        </div>
      </Form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
