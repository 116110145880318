import { stringifyQuery } from '../utils';

require('@iconify/iconify');

const ICONIFY_URL = 'https://api.iconify.design'; //search?query=box&limit=120&&callback=IconifySearch.search1591046959018
const TIMEOUT = 10000;

export default {
  search(query, options = {}) {
    return request('get', `/search`, { query, limit: 120, ...options });
  },
};

function request(method, url, query, headers = {}, params = {}) {
  const requestId = iconifyRequestId();
  const callback = `iconify_response_${requestId}`;

  const data = {
    ...query,
    callback,
  };

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `${ICONIFY_URL}${url}${stringifyQuery(data)}`;

    const errorTimeout = setTimeout(() => {
      window[callback]({
        error: `Request timed out after ${TIMEOUT / 1000} seconds`,
      });
    }, TIMEOUT);

    window[callback] = (result) => {
      clearTimeout(errorTimeout);
      if (result && result.error) {
        const err = new Error(result.error);
        reject(err);
      } else if (!result || result.$status >= 300) {
        const err = new Error(
          'A connection error occurred while making the request',
        );
        reject(err);
      } else {
        resolve(result);
      }
      delete window[callback];
      script.parentNode.removeChild(script);
    };

    document.getElementsByTagName('head')[0].appendChild(script);
  });
}

function iconifyRequestId() {
  window.__iconify_request_id = window.__iconify_request_id || 0;
  window.__iconify_request_id++;
  return window.__iconify_request_id;
}
