import React from 'react';
import pt from 'prop-types';

import IconPayment from 'components/icon/payment';

export default class MethodOption extends React.PureComponent {
  static propTypes = {
    id: pt.string.isRequired,
    name: pt.string.isRequired,
    settings: pt.object.isRequired,
    description: pt.string,
    authorized: pt.bool,
  };

  render() {
    const { id, name, description, settings, authorized } = this.props;

    return (
      <div className="payment-method-option method-option">
        <div className="payment-method-option-container">
          <div className="payment-method-option-details">
            <IconPayment method={id} settings={settings} />
            <div className="payment-method-option-name">
              {name}
              {authorized && ' (authorized)'}
              {description && (
                <span className="payment-method-option-description">
                  {description}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
