import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Icon from 'components/icon';
import './DeviceToolbar.scss';

export default class DeviceToolbar extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onSelect: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    onSelect: noop,
  };

  state = {
    active: 'desktop',
  };

  onSelectDevice = (event) => {
    event.preventDefault();
    const { type } = event.currentTarget.dataset;
    this.props.onSelect(type);
    this.setState({ active: type });
  };

  render() {
    const { className } = this.props;
    const { active } = this.state;

    return (
      <div className={`DeviceToolbar ${className}`}>
        <div
          className={`DeviceToolbar-device ${
            active === 'mobile' ? 'active' : ''
          }`}
          onClick={this.onSelectDevice}
          data-type="mobile"
        >
          <Icon fa="mobile" faType="regular" />
        </div>
        <div
          className={`DeviceToolbar-device ${
            active === 'tablet' ? 'active' : ''
          }`}
          onClick={this.onSelectDevice}
          data-type="tablet"
        >
          <Icon fa="tablet" faType="regular" />
        </div>
        <div
          className={`DeviceToolbar-device ${
            active === 'desktop' ? 'active' : ''
          }`}
          onClick={this.onSelectDevice}
          data-type="desktop"
        >
          <Icon fa="desktop" faType="regular" />
        </div>
      </div>
    );
  }
}
