import React, { Fragment } from 'react';
import { isObject, map, omit } from 'lodash';
import PropTypes from 'prop-types';

import { wordify, singularize } from 'utils';

import Icon from 'components/icon';
import Fieldgroup from 'components/form/fieldgroup';
import { MoveDownBounce } from 'components/transitions';

import './flash.scss';

export default class Flash extends React.Component {
  static contextTypes = {
    clearFlash: PropTypes.func.isRequired,
    cancelAutoFlash: PropTypes.func.isRequired,
  };

  renderError(error) {
    if (error instanceof Error) {
      return error.message;
    }

    if (isObject(error)) {
      const message = error.message;
      const errors = error.errors || error;

      return (
        <Fragment>
          {message || ''}
          {errors && (
            <ul className={error.message ? 'with-message' : undefined}>
              {map(errors, (fieldError, key) => (
                <li key={key}>{this.renderFieldError(key, fieldError)}</li>
              ))}
            </ul>
          )}
        </Fragment>
      );
    }

    return error;
  }

  renderFieldError(path, error) {
    switch (error && error.code) {
      case 'REQUIRED':
        return this.renderFieldErrorDetail(
          `${this.wordifyPath(path)} required`,
          path,
          error,
        );
      case 'UNIQUE':
        return this.renderFieldErrorDetail(
          `${this.wordifyPath(path)} already exists`,
          path,
          error,
        );
      case 'INVALID':
        return this.renderFieldErrorDetail(error.message, path, error);
      default:
        return (
          error &&
          this.renderFieldErrorDetail(
            `Field error: ${error.message}`,
            path,
            error,
          )
        );
    }
  }

  wordifyPath(path) {
    return wordify(path)
      .split(' ')
      .map((word) => singularize(word).replace(/^id$/i, 'ID'))
      .join(' ');
  }

  renderFieldErrorDetail(message, path, error) {
    const params = { field: path, ...omit(error, ['code', 'message']) };
    return (
      <Fragment>
        <Fieldgroup label={message} className="flash-error-details">
          <code>
            <pre>{JSON.stringify(params, null, 2)}</pre>
          </code>
        </Fieldgroup>
      </Fragment>
    );
  }

  render() {
    const { error, warning, success, visible } = this.props;

    const clearFlash = this.context.clearFlash;
    const cancelAutoFlash = this.context.cancelAutoFlash;

    return (
      <div
        className={`flash-container ${success ? 'success' : ''} ${
          warning ? 'warning' : ''
        }`}
      >
        {error && (
          <MoveDownBounce active={visible} distance={215}>
            <div
              className="flash-message flash-error"
              onClick={cancelAutoFlash}
            >
              <Icon fa="exclamation-circle" className="flash-message-icon" />

              {this.renderError(error)}

              <button
                className="flash-clear"
                onClick={clearFlash}
                type="button"
              >
                <Icon fa="times" faType="light" />
              </button>
            </div>
          </MoveDownBounce>
        )}

        {warning && (
          <MoveDownBounce active={visible} distance={215}>
            <div
              className="flash-message flash-warning"
              onClick={cancelAutoFlash}
            >
              <Icon fa="exclamation-triangle" className="flash-message-icon" />

              {warning}

              <button
                className="flash-clear"
                onClick={clearFlash}
                type="button"
              >
                <Icon fa="times" faType="light" />
              </button>
            </div>
          </MoveDownBounce>
        )}

        {success && (
          <MoveDownBounce active={visible} distance={215}>
            <div
              className="flash-message flash-success"
              onClick={cancelAutoFlash}
            >
              <Icon fa="check" className="flash-message-icon" />

              {success}

              <button
                className="flash-clear"
                onClick={clearFlash}
                type="button"
              >
                <Icon fa="times" faType="light" />
              </button>
            </div>
          </MoveDownBounce>
        )}
      </div>
    );
  }
}
