import React from 'react';
import Icon from 'components/icon';
import User from '../utils/user';
import Date from '../utils/date';
import Items from '../utils/items';

export default function TryPeriodDeactivated({ event, items }) {
  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/shipment-created" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          Try period deactivated
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
        <div className="activity-event-details">
          <Items items={items} />
        </div>
      </div>
    </div>
  );
}
