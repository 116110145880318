import React from 'react';
import { FadeIn } from 'components/transitions';

export default class TabView extends React.PureComponent {
  state = {
    isActive: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isActive: this.getActiveState(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ isActive: this.getActiveState(this.props) });
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;
    if (onChange) {
      onChange(false);
    }
  }

  getActiveState = (props) => {
    const { value, active, default: isDefault = false, onChange } = props;

    const isActive = value === active || (!value && isDefault) ? true : false;

    if (onChange) {
      onChange(isActive);
    }

    return isActive;
  };

  render() {
    const { className = '', children, transitionAppear } = this.props;

    const { isActive } = this.state;

    return (
      <FadeIn
        active={isActive}
        className={`form-tabs-view ${className} ${!isActive ? 'hidden' : ''}`}
        transitionAppear={transitionAppear}
      >
        {children}
      </FadeIn>
    );
  }
}
