import React, { Fragment } from 'react';
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';
import moment from 'moment-timezone';
import classNames from 'classnames';
import pt from 'prop-types';

import Icon from 'components/icon';

import Label from './label';
import Time from './input-time';

import './input-date-override.scss';

function isOutsideRange() {
  return false;
}

function getInitialValue(props, timezone, nowValue) {
  const value = props.value || props.defaultValue || nowValue;

  if (value) {
    let date = moment(value);

    if (timezone) {
      date = date.tz(timezone);
    }

    if (date.isValid()) {
      return date;
    }
  }

  return null;
}

/**
 * Copy time (hours, minutes, seconds, milliseconds) from source to destination
 *
 * @param {moment.Moment} dest
 * @param {moment.Moment} src
 * @returns {moment.Moment}
 */
function inheritTime(dest, src) {
  return dest.set({
    hours: src.hours(),
    minutes: src.minutes(),
    seconds: src.seconds(),
    milliseconds: src.milliseconds(),
  });
}

export default class InputDate extends React.PureComponent {
  static contextTypes = {
    client: pt.object.isRequired,
  };

  static propTypes = {
    time: pt.bool,
    type: pt.string,
    value: pt.oneOfType([pt.string, pt.instanceOf(Date)]),
    defaultValue: pt.oneOfType([pt.string, pt.instanceOf(Date)]),
    disabled: pt.bool,
    defaultNow: pt.bool,
    name: pt.string,
    label: pt.oneOfType([pt.string, pt.node]),
    labelRenderer: pt.func,
    rawLabel: pt.string,
    help: pt.oneOfType([pt.node, pt.object]),
    currency: pt.string,
    selectFocus: pt.bool,

    onChange: pt.func,
  };

  constructor(props, context) {
    super(props, context);

    const { locale } = context.client;
    const timezone = context.client.timezone || moment.tz.guess();

    moment.locale(locale);

    const nowValue = props.defaultNow ? Date.now() : null;
    const value = getInitialValue(props, timezone, nowValue);

    this.state = {
      value,
      time: value,
      prev: { value: props.value, defaultValue: props.defaultValue },
      localeFormat: moment.localeData().longDateFormat('L'),
      timezone,
      timezoneName:
        (props.time || props.type === 'time') &&
        moment.tz(timezone).format('z'),
      error: false,
      focused: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.defaultValue !== state.prev.defaultValue &&
      props.value !== state.prev.value
    ) {
      const value = getInitialValue(props, state.timezone);

      return {
        value,
        time: value,
        prev: { value: props.value, defaultValue: props.defaultValue },
      };
    }

    return null;
  }

  getActualValue() {
    return this.inputRef().value;
  }

  /** @returns {HTMLInputElement} */
  inputRef() {
    return this.refs['input'].querySelector('input');
  }

  /**
   * @param {import('moment-timezone').Moment | null} mdate in the local time zone
   */
  onChange = (mdate) => {
    const event = new Event('change', { bubbles: true });

    if (mdate?.isValid()) {
      // Convert from local to context timezone
      mdate = moment.tz(
        mdate.format('YYYY-MM-DD[T]HH:mm:ss'),
        this.state.timezone,
      );

      const { error, time } = this.state;

      if (error) {
        this.setState({ error: false });
      }

      if (time) {
        // Apply hours and minutes
        mdate = inheritTime(mdate, time);
      }

      this.setState({ value: mdate, time: mdate });
      return this.props.onChange(event, mdate.toDate());
    }

    this.setState({ value: null });
    this.props.onChange(event, null);
  };

  /**
   * @param {React.ChangeEvent} event
   * @param {Date | null} time
   */
  onChangeTime = (event, time) => {
    const { value } = this.state;

    if (!value) {
      return;
    }

    let date = value;

    if (time) {
      date = inheritTime(date, moment(time).tz(this.state.timezone));

      this.setState({ value: date, time: date });
      return this.props.onChange(event, date.toDate());
    }

    date = date.set({ hours: 0, minutes: 0 });

    this.setState({ value: date, time: date });
    this.props.onChange(event, date.toDate());
  };

  onFocusChange = ({ focused }) => {
    this.setState({ focused });
  };

  focus() {
    this.inputRef().focus();
  }

  value() {
    if (this.state.value) {
      const mdate = moment(this.state.value);

      if (!this.props.time) {
        return mdate
          .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
          .toDate();
      }

      return mdate.toDate();
    }

    const actualValue = this.getActualValue();

    if (actualValue) {
      return actualValue;
    }

    return null;
  }

  renderIcon() {
    const { disabled } = this.props;
    const { value } = this.state;

    return (
      <span
        className={classNames('form-date-icon', {
          'has-value': value && !disabled,
        })}
      >
        <Icon fa="calendar" />
      </span>
    );
  }

  render() {
    const { name, label, rawLabel, labelRenderer, help, time, defaultNow } = this.props;

    const { value, error, timezoneName, localeFormat, focused } = this.state;
    const labelText = (typeof label === 'string' ? label : rawLabel) || '';
    const fieldLabel = timezoneName ? `${labelText} (${timezoneName})` : label;

    return (
      <Fragment>
        <div className="form-date">
          {fieldLabel && (
            <Label
              label={fieldLabel}
              labelRenderer={labelRenderer}
              help={help}
              htmlFor={name}
            />
          )}

          <div
            ref="input"
            className={classNames('form-field-input', {
              focus: Boolean(focused),
              error: Boolean(error),
            })}
          >
            <SingleDatePicker
              id={name}
              date={value}
              focused={focused}
              placeholder={localeFormat}
              numberOfMonths={1}
              enableOutsideDays
              hideKeyboardShortcutsPanel
              customInputIcon={this.renderIcon()}
              onDateChange={this.onChange}
              onFocusChange={this.onFocusChange}
              isOutsideRange={isOutsideRange}
            />
          </div>
        </div>

        {time && (
          <div className="form-date">
            {fieldLabel && <Label label="&nbsp;" htmlFor={name} />}
            <Time
              value={value}
              defaultNow={defaultNow}
              disabled={!value}
              onChange={this.onChangeTime}
            />
          </div>
        )}
      </Fragment>
    );
  }
}
