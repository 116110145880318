import qs from 'qs';
import juri from 'juri-cutlery';
import reduce from 'lodash/reduce';
import trim from 'lodash/trim';

import api, { getLocalizedParams } from 'services/api';

import { isEmpty } from 'utils';

import flash from './flash';

const JURI = juri();

const HISTORY_LIMIT = 100;

export const CONSOLE_FETCH_ENDPOINTS = 'console/fetchEndpoints';
export const CONSOLE_ADD_REQUEST = 'console/addRequest';
export const CONSOLE_FETCH_HISTORY = 'console/fetchRequests';
export const CONSOLE_RUN_QUERY = 'console/runQuery';
export const CONSOLE_CLEAR_RESPONSE = 'console/clearResponse';

const actions = {
  async fetchHistory() {
    const history = await api.connectConsoleHistory();

    return {
      type: CONSOLE_FETCH_HISTORY,
      payload: history
        .chain()
        .simplesort('meta.created', { desc: true })
        .limit(HISTORY_LIMIT)
        .data(),
    };
  },

  fetchEndpoints() {
    return {
      type: CONSOLE_FETCH_ENDPOINTS,
      payload: api.get('/data/:options'),
    };
  },

  clearResponse() {
    return {
      type: CONSOLE_CLEAR_RESPONSE,
      payload: null,
    };
  },

  async runQuery({ method, endpoint, uri = '', body = {} }) {
    const history = await api.connectConsoleHistory();

    return async (dispatch) => {
      const uriSegments = uri.split('?');

      const path =
        uriSegments[0] && !uriSegments[0].startsWith('/')
          ? `/${uriSegments[0]}`
          : uriSegments[0];

      const query = {
        ...(uriSegments[1] ? qs.parse(uriSegments[1]) : undefined),
        ...body,
      };

      // Always remove $console flag
      if (body?.$console) {
        delete body.$console;
      }

      const requestId = serializeRequest({ method, endpoint, uri, body });

      history.chain().find({ id: requestId }).remove();
      history.insert({
        id: requestId,
        method,
        endpoint,
        uri,
        body,
      });

      const collection = history
        .chain()
        .simplesort('meta.created', { desc: true })
        .limit(HISTORY_LIMIT)
        .data();

      dispatch({ type: CONSOLE_ADD_REQUEST, payload: collection });

      const apiMethod = method.toLowerCase();
      const apiEndpoint = endpoint.replace(/^\/+/, '');

      if (apiMethod === 'get') {
        Object.assign(query, getLocalizedParams(query));
      }

      let result;
      try {
        result = await api.post(
          `/data/$${apiMethod}/${apiEndpoint}${path}`,
          query,
          { 'X-Swell-Console': true },
        );
      } catch (error) {
        dispatch(flash.error(error));
        return { data: undefined };
      }

      const { data } = result;

      // Show errors except for 404 for find queries.
      if (data?.error && !data.id) {
        dispatch(flash.error(data.error));
        return { data };
      }

      return dispatch({
        type: CONSOLE_RUN_QUERY,
        payload: result,
      });
    };
  },
};

export default actions;

export const initialState = {
  endpoints: null,
  response: null,
  history: null,
  headers: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case CONSOLE_FETCH_ENDPOINTS: {
      const models = reduce(
        action.payload,
        (acc, model) => {
          acc.push({
            url: model.url,
            name: model.name,
            namespace: model.namespace,
          });

          if (model.children) {
            reduce(
              model.children,
              (acc, child) => {
                acc.push({
                  url: `${model.url}:${child.name}`,
                  name: `${model.url}:${child.name}`,
                  namespace: model.namespace,
                });

                return acc;
              },
              acc,
            );
          }

          return acc;
        },
        [],
      ).sort((a, b) => {
        if (a.name.startsWith(':')) {
          if (b.name.startsWith(':')) {
            return a.name > b.name ? 1 : -1;
          } else {
            return 1;
          }
        } else if (b.name.startsWith(':')) {
          return -1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      });

      return {
        ...state,
        endpoints: models,
      };
    }

    case CONSOLE_FETCH_HISTORY:
      return {
        ...state,
        history: action.payload,
      };

    case CONSOLE_ADD_REQUEST: {
      return {
        ...state,
        history: action.payload,
      };
    }

    case CONSOLE_RUN_QUERY:
      return {
        ...state,
        response: action.payload.data,
        headers: action.payload.headers,
      };

    case CONSOLE_CLEAR_RESPONSE:
      return {
        ...state,
        response: action.payload,
      };

    default:
      return state;
  }
}

export function serializeRequest({ method, endpoint, uri, body }) {
  return trim(
    [
      method,
      encodeURIComponent(endpoint),
      !isEmpty(uri) ? `-${JURI.encode(uri.replace(/^\//, ''))}` : '',
      !isEmpty(body) ? `-${JURI.encode(body)}` : '',
    ].join('/'),
    '/',
  );
}

export function unserializeRequest(id, endpoints, client) {
  const [method, ...endpointParts] = String(id).split('/');

  let finalUri = '';
  let finalBody = '';
  let finalEndpoint = decodeURIComponent(endpointParts.join('/'));

  // Convert endpoints using canonical app IDs to slug IDs
  if (finalEndpoint.includes('apps/')) {
    const [, appId, ...model] = finalEndpoint.split('/');

    if (client.appsById[appId]) {
      finalEndpoint = `apps/${client.appsById[appId].slug_id}/${model.join(
        '/',
      )}`;

      endpointParts[1] = client.appsById[appId].slug_id;
    }
  }

  // Find the endpoint part by part
  for (const ep of endpoints) {
    let checkEndpoint = '';

    for (let i = 0; i < endpointParts.length; ++i) {
      checkEndpoint = `${checkEndpoint}/${decodeURIComponent(
        endpointParts[i],
      )}`;

      if (ep.url === checkEndpoint) {
        finalEndpoint = ep.url.slice(1);
        finalUri = endpointParts[i + 1];
        finalBody = endpointParts[i + 2];
        break;
      }
    }
  }

  const uriParsed = finalUri
    ? finalUri.startsWith('-')
      ? JURI.decode(finalUri.substring(1))
      : decodeURIComponent(finalUri)
    : '';

  const bodyParsed = finalBody
    ? finalBody.startsWith('-')
      ? JURI.decode(finalBody.substring(1))
      : JSON.parse(decodeURIComponent(finalBody))
    : '';

  return {
    method,
    endpoint: finalEndpoint,
    uri: uriParsed,
    body: bodyParsed,
  };
}
