import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import { inflect } from 'utils';
import { BULK_RUNNING_WARNING } from 'utils/messages';

import actions from 'actions';

import Modal from 'components/modal';
import BulkProgress from 'components/bulk/progress';

import ConfirmModal from './Confirm';

const mapStateToProps = (state) => ({
  bulk: state.data.bulk,
  selection: state.data.selection,
  model: state.data.model,
  query: state.data.query,
  locationQuery: state.data.locationQuery,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  bulkCancel() {
    return dispatch(actions.data.bulkCancel());
  },

  bulkDeleteSelected(model) {
    return dispatch(
      actions.data.bulkDeleteSelected(
        model,
        ownProps.query,
        ownProps.bulkParams,
      ),
    );
  },
});

export class BulkDeleteModal extends React.PureComponent {
  static propTypes = {
    bulk: pt.object,
    selection: pt.object,
    query: pt.object,
    model: pt.string,
    modelName: pt.string,

    bulkCancel: pt.func,
    bulkDeleteSelected: pt.func,
    onComplete: pt.func,
  };

  static contextTypes = {
    notifySuccess: pt.func.isRequired,
    openModal: pt.func.isRequired,
    closeModal: pt.func.isRequired,
  };

  state = {
    confirmedOnce: false,
    count: 0,
  };

  onClose = (event) => {
    this.context.closeModal();
    this.props.bulkCancel();
  };

  onCloseProgress = (event) => {
    if (this.props.bulk.running) {
      event.preventDefault();
      this.context.openModal('Confirm', {
        title: 'Cancel delete',
        message: BULK_RUNNING_WARNING,
        action: 'Confirm',
        actionType: 'danger',
        onConfirm: () => {
          this.context.closeModal();
          this.props.bulkCancel();
        },
      });
      return;
    }
    this.context.closeModal();
    this.props.bulkCancel();
  };

  onConfirm = () => {
    const { selection } = this.props;
    setTimeout(
      () => this.setState({ confirmedOnce: true, count: selection.count }),
      100,
    );
    return false;
  };

  onConfirmAgain = () => {
    const { bulkDeleteSelected, model, modelName } = this.props;
    bulkDeleteSelected(model).then((num) => {
      this.context.notifySuccess(`Deleted ${inflect(num, modelName || model)}`);
      this.context.closeModal();
      this.props.bulkCancel();

      if (typeof this.props.onComplete === 'function') {
        this.props.onComplete();
      }
    });
    return false;
  };

  render() {
    const {
      model,
      modelName,
      selection,
      bulk: { running, percent, complete },
    } = this.props;

    const deleteName = modelName || model;

    return (
      <div>
        {running || complete ? (
          <Modal
            title={`Deleting ${inflect(this.state.count, deleteName)}`}
            width={400}
            cancel={false}
            onClose={this.onCloseProgress}
          >
            <fieldset>
              <BulkProgress percent={percent} />
            </fieldset>
          </Modal>
        ) : (
          <Fragment>
            <ConfirmModal
              title={`Delete ${deleteName}`}
              message={
                <p>
                  Are you sure you want to{' '}
                  <b> delete {inflect(selection.count, deleteName)}</b>? This
                  action can&apos;t be undone.
                  {this.props.query && (
                    <React.Fragment>
                      <br />
                      <br />
                      Note: this action may not affect all selected {deleteName}
                    </React.Fragment>
                  )}
                </p>
              }
              action={`Delete selected ${deleteName}`}
              actionType="danger"
              onConfirm={this.onConfirm}
            />

            {this.state.confirmedOnce && (
              <ConfirmModal
                title="Just to make sure..."
                message={
                  <p>
                    Are you really sure you want to{' '}
                    <b> delete {inflect(selection.count, deleteName)}</b>? This
                    action is permanent.
                  </p>
                }
                action="Yes, really!"
                actionType="danger"
                onConfirm={this.onConfirmAgain}
              />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkDeleteModal);
