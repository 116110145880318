import React from 'react';

import Link from 'components/link';
import { Form, Field, Button } from 'components/form';
import { FadeInUp } from 'components/transitions';

const passwordRules = ['password', 'required'];

export default class Password extends React.PureComponent {
  render() {
    const { info, onSubmitPassword } = this.props;

    return (
      <div className="login-form">
        <FadeInUp>
          <div>
            <div className="login-heading">
              <h2>Enter {info.name}</h2>

              {Boolean(info.password_message) && <p>{info.password_message}</p>}
            </div>

            <Form onSubmit={onSubmitPassword} autoFocus={true} ref="form">
              <fieldset>
                <Field
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="********"
                  rules={passwordRules}
                  showError={false}
                  large={true}
                />
              </fieldset>

              <div className="login-actions">
                <Button type="submit" styleType="primary" size="large">
                  Enter store
                </Button>

                <div className="login-note">
                  Store owner? <Link to="/admin">Log in here</Link>
                </div>
              </div>
            </Form>
          </div>
        </FadeInUp>
      </div>
    );
  }
}
