import React from 'react';
import { find } from 'lodash';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import { STOREFRONT_LANGUAGES_FRAMEWORKS } from 'constants/storefront';

function frameworkName(id) {
  return find(STOREFRONT_LANGUAGES_FRAMEWORKS, { id })?.name;
}

export default function FrameworkIcons({ frameworks = [] }) {
  return (
    <div className="storefront-framework-icons">
      {frameworks?.map((framework) => (
        <Tooltip key={framework} message={frameworkName(framework)}>
          <Icon
            svgType={`frameworks/${framework}`}
            fallback="info"
            width={30}
            height={30}
          />
        </Tooltip>
      ))}
    </div>
  );
}
