import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import actions from 'actions';
import { fetchAttribute } from 'actions/attributes';

import { confirmRouteLeave, confirmPageLeave } from 'utils/container';

import EditPage from 'components/pages/attribute/edit';
import LoadingView from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';

export const mapStateToProps = (state) => ({
  collection: state.data.children?.attribute_products?.collection,
  selection: state.data.selection,
  categories: state.categories,
  content: state.content,
  lookup: state.lookup,
  loading: state.attributes.loading,
  errors: state.data.recordErrors,
});

export const mapDispatchToProps = (dispatch) => ({
  loadContentModels() {
    return dispatch(actions.content.loadModels('attributes'));
  },

  updateRecord(id, data) {
    return dispatch(actions.data.updateRecord('attributes', id, data));
  },

  deleteRecord(id) {
    return dispatch(actions.data.deleteRecord('attributes', id));
  },

  removeAttributeFromStore(id) {
    return dispatch(actions.attributes.removeFromStore(id));
  },
});

export class EditAttribute extends React.PureComponent {
  static propTypes = {
    id: pt.string,
    params: pt.object,
    router: pt.object,

    updateRecord: pt.func,
    deleteRecord: pt.func,
    loadContentModels: pt.func,
    removeAttributeFromStore: pt.func,
  };

  static contextTypes = {
    notifyError: pt.func.isRequired,
    notifyDeleted: pt.func.isRequired,
    openModal: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      id: '',
      loaded: false,
      edited: false,
      record: {},
      values: {},
    };
  }

  componentDidMount() {
    confirmRouteLeave(this);

    const { params, loadContentModels } = this.props;

    Promise.all([fetchAttribute(params.id), loadContentModels()]).then(
      ([attr]) => {
        this.setState({
          loaded: true,
          record: attr,
          values: { ...attr },
        });
      },
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id !== state.id) {
      return { id: props.id, loaded: false };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);

    if (this.state.id !== prevState.id) {
      const { id } = this.state;

      fetchAttribute(id).then((attr) => {
        this.setState({
          loaded: true,
          record: attr,
          values: { ...attr },
        });
      });
    }
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  onChangeForm = (values, edited) => {
    this.setState((state) => ({
      values: { ...state.values, ...values },
      edited: edited,
    }));
  };

  /** @param {React.MouseEvent} event */
  onClickDelete = (event) => {
    event.preventDefault();

    this.context.openModal('ConfirmDelete', {
      title: this.state.record.name,
      onConfirm: () => {
        const { params, router, deleteRecord } = this.props;

        deleteRecord(params.id).then((result) => {
          if (result && !result.errors) {
            this.setState({ edited: false }, () => {
              router.replace('/attributes');
              this.context.notifyDeleted('Attribute');
            });
          }
        });
      },
    });
  };

  onSubmitRecord = async (values) => {
    const { params, updateRecord } = this.props;

    const data = {
      ...values,
      $set: {
        values: values.values,
      },
    };

    delete data.values;

    data.$unset = [];

    if (Array.isArray(data.default)) {
      data.$set.default = data.default;
      delete data.default;
    } else if (!data.default) {
      data.$unset.push('default');
      delete data.default;
    }
    if (!data.localized && data.$locale) {
      for (const locale of Object.keys(data.$locale || {})) {
        delete data.$locale[locale].default;
      }
    }

    if (data.$locale) {
      data.$set.__locale = data.$locale;
      delete data.$locale;
    }

    const result = await updateRecord(params.id, data);

    if (result) {
      if (result.errors) {
        this.context.notifyError(result.errors);
        return;
      }

      const attr = await fetchAttribute(params.id);

      this.setState({
        edited: false,
        record: attr,
        values: { ...attr },
      });

      if (params.onUpdate) {
        params.onUpdate(attr);
      }
    }
  };

  render() {
    if (!this.state.loaded) {
      return <LoadingView />;
    }

    if (!this.state.record) {
      return <NotFoundPage />;
    }

    return (
      <EditPage
        {...this.props}
        {...this.state}
        onSubmitRecord={this.onSubmitRecord}
        onClickDelete={this.onClickDelete}
        onChangeForm={this.onChangeForm}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAttribute);
