import React from 'react';
import PropTypes from 'prop-types';

import View from 'components/view';
import Alert from 'components/alert';
import APIKeys from './api-keys';

import './settings.scss';

export default class APISettings extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  render() {
    const {
      record: { keys },
      loading,
      onSaveClientKey,
      onRemoveClientKey,
    } = this.props;
    const { client } = this.context;

    return (
      <div className="settings settings-api">
        <View
          detail={true}
          uri="/settings"
          headerTitle="API access"
          headerSubtitle="Manage API keys used by storefronts and custom applications"
        >
          <Alert>
            <code>
              Store ID: <strong>{client.id}</strong>
            </code>
          </Alert>
          <APIKeys
            loading={loading}
            keys={keys.results}
            onSaveKey={onSaveClientKey}
            onRemoveKey={onRemoveClientKey}
          />
        </View>
      </div>
    );
  }
}
