import React from 'react';
import Link from 'components/link';

export default class Back extends React.Component {
  render() {
    const { children, ...props } = this.props;
    return (
      <Link {...props} className={`button-back ${props.className || ''}`}>
        <span className="button-back-arrow">&larr;</span>
        {children}
      </Link>
    );
  }
}
