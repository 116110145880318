import React from 'react';
import Form from 'components/form';
import Modal from 'components/modal';

export default class ManageAttributesModalPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  onClickSubmit(event) {
    event.preventDefault();
    this.refs.form.submit();
  }

  render() {
    const {
      //type,
      typeName,
      //lookup,
      //attributes,
      loading,
      onSubmitForm,
    } = this.props;

    return (
      <Modal
        title={`${typeName} attributes`}
        className="modal-manage-attributes"
        actions={[{ label: 'Save category', onClick: this.onClickSubmit }]}
        loading={loading}
      >
        <Form ref="form" onSubmit={onSubmitForm}>
          hello
        </Form>
      </Modal>
    );
  }
}
