// @flow
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import BulkCategories from 'components/bulk/categories';
import actions from 'actions';
import { inflect } from 'utils';
import { bulkCategoriesAdd } from 'utils/bulk';

const mapStateToProps = (state) => ({
  bulk: state.data.bulk,
  selection: state.data.selection,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  loadCategories: async () => {
    return dispatch(actions.categories.load());
  },

  searchCategories: async (search: string) => {
    return dispatch(actions.categories.search(search, 10));
  },

  bulkCancel: () => {
    return dispatch(actions.data.bulkCancel());
  },

  bulkUpdateSelected: (handler: Function) => {
    return dispatch(actions.data.bulkUpdateSelected('products', handler));
  },

  fetchCategories: async () => {
    return dispatch(actions.categories.fetch());
  },
});

export class BulkCategoriesAddModal extends React.PureComponent {
  static contextTypes = {
    notifySuccess: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  state = {};

  constructor(props: Object, context: Object) {
    super(props, context);
    this.state = {
      loaded: false,
      onClose: this.onClose.bind(this),
      onSubmit: this.onSubmit.bind(this),
    };
  }

  componentWillMount() {
    const { loadCategories } = this.props;

    loadCategories().then(() => {
      this.setState({ loaded: true });
    });
  }

  onClose(event) {
    this.context.closeModal();
    this.props.bulkCancel();
  }

  onSubmit({ categories }) {
    const { bulkUpdateSelected, fetchCategories } = this.props;

    bulkUpdateSelected((record) => bulkCategoriesAdd(record, categories)).then(
      (num) => {
        fetchCategories().then(() => {
          this.context.notifySuccess(`Updated ${inflect(num, 'products')}`);
          this.context.closeModal();
          this.props.bulkCancel();
        });
      },
    );
  }

  render() {
    return (
      <BulkCategories
        title={`Add to categories`}
        hint={`${inflect(
          this.props.selection.count,
          'products',
        )} will be added to select categories`}
        {...this.props}
        {...this.state}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkCategoriesAddModal);
