import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import pt from 'prop-types';

import {
  isStripeCardGateway,
  isStripeCardPaymentMethod,
  isStripeIDealGateway,
  isStripeIDealPaymentMethod,
  isStripeKlarnaGateway,
} from 'utils/payment';

import {
  loadStripe,
  isSingleUsePaymentMethodError,
  createPaymentIntent,
  createIDealPaymentIntent,
  createKlarnaSource,
} from 'utils/stripe';

import {
  setContentValuesByPath,
  depopulateContentLookupValues,
} from 'utils/content';

import { getAccountAddressesQuery, getAccountCardsQuery } from 'utils/account';

import actions from 'actions';

import DraftPage from 'components/pages/order/draft';
import ViewLoading from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';

import { ViewOrder, getMapDispatchToProps } from './ViewOrder';

export const mapStateToProps = (state) => ({
  record: state.data.record,
  related: state.data.related,
  prev: state.data.record && (state.data.record.prev || undefined),
  next: state.data.record && (state.data.record.next || undefined),
  loading: state.data.loading,
  errors: state.data.recordErrors,
  lookup: state.lookup,
  categories: state.categories,
  content: state.content,
  settings: state.settings,
  discounts: state.orders.discounts,
  suggestedAddresses: state.lookup.suggestedAddresses,
  checkoutCustomUrl: state.settings.checkout?.custom_checkout
    ? state.settings.checkout?.custom_checkout_url
    : '',
});

export const mapDispatchToProps = getMapDispatchToProps(
  'carts',
  (dispatch) => ({
    fetchRecord(id) {
      return dispatch(
        actions.data.fetchRecord('carts', id, {
          expand: [
            'account',
            'items.product',
            'items.variant',
            'items.bundle_items.product',
            'items.bundle_items.variant',
            'coupon',
            'promotions',
            'giftcards.giftcard',
          ],
          include: {
            prev: {
              url: `/carts/:first`,
              params: {
                id: { $gt: 'id' },
              },
              data: {
                draft: true,
              },
            },
            next: {
              url: `/carts/:last`,
              params: {
                id: { $lt: 'id' },
              },
              data: {
                draft: true,
              },
            },
            order: {
              url: '/orders/{id}',
              params: {
                id: 'order_id',
              },
              data: {
                fields: 'number',
              },
            },
          },
        }),
      ).then((result) => {
        if (!result) return result;
        if (result.account_id) {
          dispatch(
            actions.data.fetchRelated(id, {
              account_cards: getAccountCardsQuery(result.account_id),
              account_addresses: getAccountAddressesQuery(result.account_id),
            }),
          );
        }
        return result;
      });
    },

    clearRecord() {
      return dispatch(actions.data.clearRecord());
    },

    createDraft(data) {
      return dispatch(
        actions.data.createRecord('carts', {
          ...data,
          draft: true,
        }),
      );
    },

    submitOrder(id, data) {
      return dispatch(
        actions.data.createRecord('orders', {
          cart_id: id,
          ...data,
          draft: false,
        }),
      );
    },

    updateCart(id, data) {
      return dispatch(actions.data.updateRecord('carts', id, data));
    },

    fetchDiscounts() {
      return dispatch(actions.orders.fetchDiscounts());
    },

    loadContentModels() {
      return Promise.all([
        dispatch(actions.content.loadModels('orders')),
        dispatch(actions.content.loadModels('accounts')),
      ]);
    },

    loadSettings() {
      return Promise.all([
        dispatch(actions.settings.load('payments')),
        dispatch(actions.settings.load('shipments')),
        dispatch(actions.settings.load('accounts')),
        dispatch(actions.settings.load('checkout')),
      ]);
    },
  }),
);

export class DraftOrder extends React.Component {
  static propTypes = {
    params: pt.object.isRequired,
    router: pt.object,
    location: pt.object,

    // mapStateToProps
    record: pt.object,
    content: pt.object,
    settings: pt.object,

    // mapDispatchToProps
    updateCart: pt.func,
    createDraft: pt.func,
    submitOrder: pt.func,
    clearRecord: pt.func,
    fetchRecord: pt.func,
    updateRecord: pt.func,
    deleteRecord: pt.func,
    loadSettings: pt.func,
    fetchDiscounts: pt.func,
    loadContentModels: pt.func,
    fetchAccountAddresses: pt.func,
    fetchAccountCards: pt.func,
  };

  static contextTypes = {
    client: pt.object.isRequired,
    notifySuccess: pt.func.isRequired,
    notifyError: pt.func.isRequired,
    notifyDeleted: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      edited: false,
      submitting: false,
      stripe: null,
      stripeElement: null,
      getItemPrice: ViewOrder.prototype.getItemPrice.bind(this),
      onAddItem: this.onAddItem.bind(this),
      onEditItems: this.onEditItems.bind(this),
      onEditValues: this.onEditValues.bind(this),
      onShippingGetRates: this.onShippingGetRates.bind(this),
      onPaymentEdit: this.onPaymentEdit.bind(this),
      onPaymentAddGiftcard: this.onPaymentAddGiftcard.bind(this),
      onPaymentRemoveGiftcard: this.onPaymentRemoveGiftcard.bind(this),
      onChangeCurrency: this.onChangeCurrency.bind(this),
      onSubmitOrder: this.onSubmitOrder.bind(this),
      onEdited: this.onEdited.bind(this),
      onDelete: this.onDelete.bind(this),
      onStripeInit: this.onStripeInit.bind(this),
      onLoadAddresses: this.onLoadAddresses.bind(this),
      onLoadBilling: this.onLoadBilling.bind(this),
    };
  }

  componentDidMount() {
    const {
      params,
      clearRecord,
      fetchRecord,
      fetchDiscounts,
      loadContentModels,
      loadSettings,
    } = this.props;

    Promise.all([fetchDiscounts(), loadContentModels(), loadSettings()])
      .then(() => {
        return params.id ? fetchRecord(params.id) : clearRecord();
      })
      .then(() => {
        this.setState({ loaded: true });
      });
  }

  componentDidUpdate(prevProps) {
    const { params, router, location, clearRecord, fetchRecord } = this.props;

    if (params.id && prevProps.params.id !== params.id) {
      this.setState({ loaded: false });

      fetchRecord(params.id).then(() => {
        this.setState({ loaded: true });
      });
    } else if (!params.id && prevProps.params.id) {
      clearRecord();
      this.setState({ loaded: true });
    } else if (!prevProps.record && this.props.record) {
      const { record } = this.props;
      const method = get(record, 'billing.method');
      const query = get(this.props, 'location.query', {});

      switch (method) {
        case 'ideal':
          router.replace(location.pathname);
          this.handleIDealRedirectAction(query);
          break;

        case 'klarna':
          router.replace(location.pathname);
          this.handleKlarnaRedirectAction(query);
          break;

        default:
          break;
      }
    }
  }

  async handleIDealRedirectAction(query) {
    if (!query.payment_intent) {
      return;
    }

    const { params, updateRecord, submitOrder, router } = this.props;

    if (query.redirect_status === 'succeeded') {
      this.setState({ submitting: true });
      const result = await updateRecord(params.id, {
        billing: {
          intent: { id: query.payment_intent },
        },
      });
      if (result.errors) {
        return this.context.notifyError(result.errors);
      }
      const order = await submitOrder(params.id, this.getOrderContent());
      this.setState({ submitting: false });

      if (order.errors) {
        this.context.notifyError(order.errors);
      } else {
        this.context.notifySuccess(
          `Draft has been converted to Order ${order.id}`,
        );
        router.replace(`/orders/${order.id}`);
      }
      return;
    }

    return this.context.notifyError(
      'We are unable to authenticate your payment method. Please choose a different payment method and try again.',
    );
  }

  async handleKlarnaRedirectAction(query) {
    if (!query.source) {
      return;
    }

    const { params, updateRecord, submitOrder, router } = this.props;

    if (query.redirect_status === 'succeeded') {
      this.setState({ submitting: true });
      const result = await updateRecord(params.id, {
        billing: {
          klarna: { source: query.source },
        },
      });
      if (result.errors) {
        return this.context.notifyError(result.errors);
      }
      const order = await submitOrder(params.id, this.getOrderContent());
      this.setState({ submitting: false });

      if (order.errors) {
        this.context.notifyError(order.errors);
      } else {
        this.context.notifySuccess(
          `Draft has been converted to Order ${order.id}`,
        );
        router.replace(`/orders/${order.id}`);
      }
      return;
    } else if (query.redirect_status === 'canceled') {
      return this.context.notifyError('Your payment was canceled.');
    }

    return this.context.notifyError(
      'We are unable to authenticate your payment method. Please choose a different payment method and try again.',
    );
  }

  onEdited(edited) {
    this.setState({ edited });
  }

  async ensureDraft() {
    const { record, router, createDraft } = this.props;

    if (!record) {
      const draft = await createDraft();
      if (draft.id) {
        router.replace(`/orders/drafts/${draft.id}`);
      }
      return draft;
    }

    return record;
  }

  onAddItem(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewOrder.prototype.onAddItem.apply(this, [...args, draft.id]);
    });
  }

  onEditItems(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewOrder.prototype.onEditItems.apply(this, [...args, draft.id]);
    });
  }

  onEditValues(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewOrder.prototype.onEditValues.apply(this, [...args, draft.id]);
    });
  }

  onShippingGetRates(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewOrder.prototype.onShippingGetRates.apply(this, [
        ...args,
        draft.id,
      ]);
    });
  }

  onPaymentEdit(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewOrder.prototype.onPaymentEdit.apply(this, [...args, draft.id]);
    });
  }

  addGiftcard(...args) {
    return ViewOrder.prototype.addGiftcard.apply(this, [...args]);
  }

  onPaymentAddGiftcard(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewOrder.prototype.onPaymentAddGiftcard.apply(this, [
        ...args,
        draft.id,
      ]);
    });
  }

  onPaymentRemoveGiftcard(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewOrder.prototype.onPaymentRemoveGiftcard.apply(this, [
        ...args,
        draft.id,
      ]);
    });
  }

  onChangeCurrency(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewOrder.prototype.onChangeCurrency.apply(this, [
        ...args,
        draft.id,
      ]);
    });
  }

  onOrderSubmitError(error) {
    this.context.notifyError(error);
    this.setState({ submitting: false });
  }

  async getStripe() {
    const { settings } = this.props;
    const { stripe } = this.state;
    return stripe || (await loadStripe(settings));
  }

  async onSubmitOrder() {
    const { router, record, submitOrder } = this.props;

    this.setState({ submitting: true });
    if (await this.handleAltPaymentRedirect()) {
      return;
    }
    const order = await submitOrder(record.id, this.getOrderContent());
    this.setState({ submitting: false });

    if (order.errors) {
      this.context.notifyError(order.errors);
    } else {
      this.context.notifySuccess(
        `Draft has been converted to Order ${order.id}`,
      );
      router.replace(`/orders/${order.id}`);
    }
  }

  getOrderContent() {
    const { record, content } = this.props;
    const contentModels = content.models.filter(
      (model) => model.collection === 'orders' && !model.standard,
    );
    if (contentModels.length > 0) {
      const values = depopulateContentLookupValues(record, contentModels);
      const setValues = {};
      const unsetValues = [];
      for (const model of contentModels || []) {
        for (const field of model.fields || []) {
          setContentValuesByPath({
            setValues,
            unsetValues,
            values: cloneDeep(values || {}),
            field,
            model,
          });
        }
      }
      return setValues;
    }
    return {};
  }

  async handleAltPaymentRedirect() {
    // Note: this method doesn't get called because ideal/klarna are alt methods
    // that require customer authentication - leaving the code here in case we
    // want to enable dashboard testing of alt payment methods
    const { record, settings, updateCart } = this.props;
    const { client } = this.context;

    if (isStripeCardGateway(settings, record)) {
      if (!record.trial) {
        // should create a Stripe payment intent only if there are trial items.
        // Stripe payment intent in this case is required to authorize funds when purchasing trial products.
        // Do not use this logic in other cases.
        return false;
      }
      if (!isStripeCardPaymentMethod(record)) {
        this.onOrderSubmitError('Card is not selected');
        this.setState({ showPaymentEdit: true });
        return true;
      }
      const stripe = await this.getStripe();
      if (!stripe) {
        this.onOrderSubmitError('Stripe was not loaded');
        return true;
      }
      const intent = await createPaymentIntent(record, client.public_key);
      if (intent.errors) {
        this.onOrderSubmitError(intent.errors);
        return true;
      }
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        intent.client_secret,
      );
      if (error) {
        this.onOrderSubmitError(error.message);
        return true;
      }
      await updateCart(record.id, {
        billing: {
          intent: {
            stripe: {
              id: paymentIntent.id,
              auth_amount: record.auth_total,
            },
          },
        },
      });
    } else if (isStripeIDealGateway(settings, record)) {
      if (!isStripeIDealPaymentMethod(record)) {
        this.onOrderSubmitError('iDeal bank is not selected');
        this.setState({ showPaymentEdit: true });
        return true;
      }

      const stripe = await this.getStripe();
      if (!stripe) {
        this.onOrderSubmitError('Stripe was not loaded');
        return true;
      }

      const intent = await createIDealPaymentIntent(client.public_key, record);
      if (intent.errors) {
        if (isSingleUsePaymentMethodError(intent.errors)) {
          this.onOrderSubmitError('iDeal bank is not selected');
          this.setState({ showPaymentEdit: true });
          return true;
        }
        this.onOrderSubmitError(intent.errors);
        return true;
      }

      await stripe.handleCardAction(intent.client_secret);
      return true;
    } else if (isStripeKlarnaGateway(settings, record)) {
      const stripe = await this.getStripe();
      if (!stripe) {
        this.onOrderSubmitError('Stripe was not loaded');
        return true;
      }
      const { error, source } = await createKlarnaSource(
        stripe,
        record,
        client,
      );
      if (error) {
        this.onOrderSubmitError(error.message);
      } else {
        window.location.replace(source.redirect.url);
      }
      return true;
    }
  }

  onDelete() {
    const { params, router, deleteRecord } = this.props;

    return deleteRecord(params.id).then(() => {
      this.context.notifyDeleted('Draft order');
      router.replace('/orders/drafts');
      (document.getElementById('main') || window).scrollTop = 0;
    });
  }

  onStripeInit(stripe, stripeElement) {
    this.setState({ stripe, stripeElement });
  }

  async onLoadAddresses() {
    const {
      record: { id, account_id },
      fetchAccountAddresses,
    } = this.props;

    await fetchAccountAddresses(id, account_id);
  }

  async onLoadBilling() {
    const {
      record: { id, account_id },
      fetchAccountCards,
    } = this.props;

    await fetchAccountCards(id, account_id);
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    if (this.props.params.id && !this.props.record) {
      return <NotFoundPage />;
    }

    return (
      <DraftPage
        {...this.props}
        {...this.state}
        record={this.props.record || {}}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DraftOrder);
