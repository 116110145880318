import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import actions from 'actions';

import DraftPage from 'components/pages/subscription/draft';
import ViewLoading from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';

import { ViewSubscription, getMapDispatchToProps } from './ViewSubscription';

import { getAccountAddressesQuery, getAccountCardsQuery } from 'utils/account';

export const mapStateToProps = (state) => ({
  record: state.data.record,
  related: state.data.related,
  prev: state.data.record && (state.data.record.prev || undefined),
  next: state.data.record && (state.data.record.next || undefined),
  loading: state.data.loading,
  errors: state.data.recordErrors,
  lookup: state.lookup,
  categories: state.categories,
  content: state.content,
  settings: state.settings,
  discounts: state.orders.discounts,
  suggestedAddresses: state.lookup.suggestedAddresses,
});

export const mapDispatchToProps = getMapDispatchToProps((dispatch) => ({
  fetchRecord: (id) => {
    return dispatch(
      actions.data.fetchRecord('subscriptions', id, {
        expand: [
          'account',
          'product',
          'product.bundle_items.product',
          'product.bundle_items.variant',
          'variant',
          'items.product',
          'items.variant',
          'items.bundle_items.product',
          'items.bundle_items.variant',
          'coupon',
        ],
      }),
    ).then((result) => {
      if (!result) return result;

      if (result.account_id) {
        dispatch(
          actions.data.fetchRelated(id, {
            account_cards: getAccountCardsQuery(result.account_id),
            account_addresses: getAccountAddressesQuery(result.account_id),
          }),
        );
      }

      return result;
    });
  },

  updateRecord: (id, data) => {
    return dispatch(actions.data.updateRecord('subscriptions', id, data));
  },

  updateFetchRecord: (id, data) => {
    return dispatch(actions.data.updateFetchRecord('subscriptions', id, data));
  },

  addItem: (id, item) => {
    return dispatch(
      actions.data.createRecord(`subscriptions/${id}/items`, item),
    );
  },

  editItems: (id, items) => {
    return Promise.all(
      items
        .filter((item) => !!item.removed)
        .map((item) => {
          return dispatch(
            actions.data.deleteRecord(`subscriptions/${id}/items`, item.id),
          );
        }),
    ).then(() => {
      return dispatch(
        actions.data.updateRecord(
          'subscriptions',
          `${id}/items`,
          items.filter((item) => !item.removed),
        ),
      );
    });
  },

  deleteRecord: (id) => {
    return dispatch(actions.data.deleteRecord('subscriptions', id));
  },

  retryPayment: (data) => {
    return dispatch(actions.data.createRecord(`payments`, data));
  },

  clearRecord: () => {
    return dispatch(actions.data.clearRecord());
  },

  createDraft: (data) => {
    return dispatch(
      actions.data.createRecord('subscriptions', {
        ...data,
        draft: true,
      }),
    );
  },

  fetchDiscounts: () => {
    return dispatch(actions.orders.fetchDiscounts());
  },

  loadContentModels() {
    return Promise.all([
      dispatch(actions.content.loadModels('subscriptions')),
      dispatch(actions.content.loadModels('accounts')),
    ]);
  },

  loadSettings() {
    return Promise.all([
      dispatch(actions.settings.fetch('payments')),
      dispatch(actions.settings.fetch('accounts')),
      dispatch(actions.settings.fetch('shipments')),
    ]);
  },
}));

export class DraftSubscription extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifyDeleted: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      edited: false,
      getItemPrice: ViewSubscription.prototype.getItemPrice.bind(this),
      onAddItem: this.onAddItem.bind(this),
      onEditItems: this.onEditItems.bind(this),
      onEditValues: this.onEditValues.bind(this),
      onPaymentEdit: this.onPaymentEdit.bind(this),
      onChangeCurrency: this.onChangeCurrency.bind(this),
      onEdited: this.onEdited.bind(this),
      onDelete: this.onDelete.bind(this),
      onShippingGetRates: this.onShippingGetRates.bind(this),
      onActivateSubscription: this.onActivateSubscription.bind(this),
      onLoadAddresses: this.onLoadAddresses.bind(this),
      onLoadBilling: this.onLoadBilling.bind(this),
    };
  }

  componentDidMount() {
    const {
      params,
      clearRecord,
      fetchRecord,
      fetchDiscounts,
      loadContentModels,
      loadSettings,
    } = this.props;

    Promise.all([fetchDiscounts(), loadContentModels(), loadSettings()])
      .then(() => (params.id ? fetchRecord(params.id) : clearRecord()))
      .then(() => {
        this.setState({ loaded: true });
      });
  }

  async componentWillReceiveProps(nextProps) {
    const { params, clearRecord, fetchRecord } = this.props;

    if (nextProps.params.id && params.id !== nextProps.params.id) {
      this.setState({ loaded: false });
      await fetchRecord(nextProps.params.id);
      this.setState({ loaded: true });
    } else if (!nextProps.params.id && params.id) {
      clearRecord();
      this.setState({ loaded: true });
    }
  }

  onEdited(edited) {
    this.setState({ edited });
  }

  async ensureDraft() {
    const { record, router, createDraft } = this.props;

    if (!record) {
      const draft = await createDraft();
      router.replace(`/subscriptions/drafts/${draft.id}`);
      return draft;
    }

    return record;
  }

  onAddItem(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewSubscription.prototype.onAddItem.apply(this, [
        ...args,
        draft.id,
      ]);
    });
  }

  onEditItems(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewSubscription.prototype.onEditItems.apply(this, [
        ...args,
        draft.id,
      ]);
    });
  }

  onEditValues(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewSubscription.prototype.onEditValues.apply(this, [
        ...args,
        draft.id,
      ]);
    });
  }

  onPaymentEdit(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewSubscription.prototype.onPaymentEdit.apply(this, [
        ...args,
        draft.id,
      ]);
    });
  }

  onChangeCurrency(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewSubscription.prototype.onChangeCurrency.apply(this, [
        ...args,
        draft.id,
      ]);
    });
  }

  onShippingGetRates(...args) {
    return this.ensureDraft().then((draft) => {
      return ViewSubscription.prototype.onShippingGetRates.apply(this, [
        ...args,
        draft.id,
      ]);
    });
  }

  onDelete() {
    const { params, router, deleteRecord } = this.props;

    return deleteRecord(params.id).then(() => {
      this.context.notifyDeleted('Draft subscription');
      router.replace('/subscriptions?tab=draft');
      (document.getElementById('main') || window).scrollTop = 0;
    });
  }

  async onActivateSubscription() {
    const { params, updateFetchRecord, router } = this.props;

    this.setState({ loading: true });

    const result = await updateFetchRecord(params.id, {
      draft: false,
      $notify: {
        id: 'new',
      },
    });

    if (result.errors) {
      this.setState({ loading: undefined }, () => {
        this.context.notifyError(result.errors);
      });
    } else {
      this.setState({ loading: undefined }, () => {
        this.context.notifySuccess(`Subscription has been activated`);
        router.replace(`/subscriptions/${result.id}`);
      });
    }
  }

  async onLoadAddresses() {
    const {
      record: { id, account_id },
      fetchAccountAddresses,
    } = this.props;

    await fetchAccountAddresses(id, account_id);
  }

  async onLoadBilling() {
    const {
      record: { id, account_id },
      fetchAccountCards,
    } = this.props;

    await fetchAccountCards(id, account_id);
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    const { params, record } = this.props;

    if (params.id && (!record || (record && record.draft !== true))) {
      return <NotFoundPage />;
    }

    return this.props.page ? (
      <this.props.page {...this.props} {...this.state} />
    ) : (
      <DraftPage {...this.props} {...this.state} record={record || {}} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DraftSubscription);
