import React from 'react';
import pt from 'prop-types';
import classNames from 'classnames';

import { truncatedText } from 'utils/collection';
import { getDefaultCardId } from 'utils/account';
import { paymentMethod, formatPaymentBrand, isExpired, shouldUseAccountBillingAddress } from 'utils/order';

import Status from 'components/status';
import IconPayment from 'components/icon/payment';

function PaymentMethod({ account, billing, settings }) {
  const method = paymentMethod(settings, billing);

  if (!method?.id) {
    return (
      <div className="order-payment-method">No payment method selected</div>
    );
  } else if (!billing?.card || method.id !== "card") {
    return (
      <div className="order-payment-method">
        <span className="order-payment-method-details">{method.name}</span>
        <IconPayment method={method.id} />
      </div>
    );
  }

  const { card } = billing;
  const isDefault =
    shouldUseAccountBillingAddress(billing) ||
    getDefaultCardId(account) === billing.account_card_id;
  const expired = isExpired(card.exp_month, card.exp_year);
  const cardBrand = formatPaymentBrand(card.brand);
  const cardBrandMaxLength = isDefault ? 12 : 20;
  const truncatedCardBrand = truncatedText(cardBrand, cardBrandMaxLength);

  return (
    <div className="order-payment-method">
      <div className="order-payment-method-details">
        <span className="nowrap">
          {truncatedCardBrand} {card.last4}
        </span>
        <span
          className={classNames('muted', {
            expired,
          })}
        >
          {expired
            ? `(Expired on ${card.exp_month}/${card.exp_year})`
            : `(Expires ${card.exp_month}/${card.exp_year})`}
        </span>
      </div>
      <IconPayment method="card" card={card} />
      {isDefault && (
        <Status type="muted" size="sm" dot={false}>
          Default
        </Status>
      )}
    </div>
  );
}

PaymentMethod.propTypes = {
  account: pt.object,

  billing: pt.shape({
    card: pt.object,
    method: pt.string,
    use_account: pt.bool,
    account_card_id: pt.string,
  }).isRequired,

  settings: pt.shape({
    methods: pt.array,
  }).isRequired,
};

export default React.memo(PaymentMethod);
