import React from 'react';
import PropTypes from 'prop-types';
import { map, reverse } from 'lodash';
import Icon from 'components/icon';
import Chart from 'components/charts/chart';
import { Line, XAxis, YAxis, Tooltip, Legend } from 'components/charts';
import { toFixed } from 'utils';
import { formatReportDate } from 'utils/chart';
import { formatCurrencyAbbreviated, formatCurrencyRound } from 'utils/money';
import './overview.scss';

const chartConfig = {
  data: [
    {
      id: 0,
      description: 'Current period',
    },
    {
      id: 1,
      description: 'Previous period',
    },
  ],
};

export default class SalesOverview extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  renderContent() {
    const { currency, timezone } = this.context.client;
    const { points, period } = this.props;

    const [current, previous] = points;
    const diff = toFixed(
      previous.summary.sub_total &&
        (current.summary.sub_total / previous.summary.sub_total - 1) * 100,
      0,
    );

    const chart = {
      ...chartConfig,
      data: chartConfig.data.map((line, i) => ({
        ...line,
        points: points[i].results.map((point) => [point.x, point.sub_total]),
      })),
    };

    return (
      <div>
        <div className="reports-overview-values">
          <div className="reports-overview-value">
            {formatCurrencyRound(current.summary.sub_total, currency)}
          </div>
          <div className="reports-overview-diff">
            {diff > 0 && (
              <span className="positive">
                <Icon fa="arrow-alt-up" faType="solid" /> {diff}%
              </span>
            )}
            {diff < 0 && (
              <span className="muted">
                <Icon fa="arrow-alt-down" faType="solid" /> {-diff}%
              </span>
            )}
          </div>
        </div>
        <Chart
          style={{ width: '100%', height: 200 }}
          margin={{ top: 20, left: 50, bottom: 20, right: 30 }}
          xValue="0"
          yValue="1"
          chartId={2}
          data={chart.data}
        >
          <Legend />
          <XAxis />
          <YAxis />
          {reverse(
            map(chart.data, (data, i) => (
              <Line key={i} x="0" y="1" dataId={i} />
            )),
          )}
          <Tooltip
            xFormat={formatReportDate(period, timezone)}
            yFormat={(value) => formatCurrencyAbbreviated(value, currency)}
          />
        </Chart>
      </div>
    );
  }

  render() {
    return <div className="reports-overview-chart">{this.renderContent()}</div>;
  }
}
