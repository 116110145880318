import React, { PureComponent } from 'react';
import * as d3 from 'd3';
import './chart.scss';
import moment from 'moment-timezone';

const MILLISECONDS = 1000;
const SECONDS = 60;
const MINUTES = 60;
const HOURS = 24;

//MONTH = 30,
const DAY_INTERVAL = MILLISECONDS * SECONDS * MINUTES * HOURS;

class XAxis extends PureComponent {
  componentDidMount() {
    this.renderD3();
  }

  componentDidUpdate() {
    this.renderD3();
  }

  tickInterval() {
    const { xValue, width, margin, offsetWidth, timezone } = this.props;
    const chartWidth = width - margin.left - margin.right - offsetWidth;
    const count = chartWidth / 70;
    const ticks = this.props.data[0].points.length;
    const circleModule = Math.max(Math.floor(ticks / count), 1);
    const defaultScale = this.props.data[0].points;
    const interval = defaultScale[1][xValue] - defaultScale[0][xValue];

    if (interval < DAY_INTERVAL) {
      return {
        tickValues: this.props.data[0].points
          .filter((el, idx) => idx % circleModule === 0)
          .map((x) => x[xValue]),
        format: (item) =>
          moment
            .utc(item / 1000, 'X')
            ?.tz(timezone)
            ?.format('ha'),
        rotate: 0,
      };
    }

    if (interval >= DAY_INTERVAL && interval < DAY_INTERVAL * 4) {
      return {
        tickValues: this.props.data[0].points
          .filter((el, idx) => idx % circleModule === 0)
          .map((x) => x[xValue]),
        format: (item, i) =>
          ticks < 20 || i % 2 === 0
            ? moment
                .utc(item / 1000, 'X')
                ?.tz(timezone)
                ?.format('DD MMM')
                ?.toUpperCase()
            : null,
        rotate: 0,
      };
    }

    if (interval > DAY_INTERVAL * 4) {
      return {
        tickValues: this.props.data[0].points
          .filter((el, idx) => idx % circleModule === 0)
          .map((x) => x[xValue]),
        format: (item) =>
          moment
            .utc(item / 1000, 'X')
            ?.tz(timezone)
            ?.format('MMM YY')
            ?.toUpperCase(),
        rotate: 0,
      };
    }
  }

  renderD3() {
    const { xScales, data } = this.props;
    const { format, rotate, tickValues } = this.tickInterval();

    const axis = d3
      .axisBottom()
      .scale(xScales[data[0].id])
      .tickSizeInner(0)
      .tickSizeOuter(0)
      .tickValues(tickValues)
      .tickFormat(format);

    d3.select(this.refs.axisAnchor)
      .attr('class', 'x axis')
      .call(axis)
      .selectAll('text')
      .attr('transform', `rotate(${rotate})`)
      .attr('dy', '2.5em');
  }

  render() {
    const { margin, height, offsetWidth, offsetHeight } = this.props;
    const translate = `translate(${margin.left + offsetWidth}, ${
      height - margin.bottom - offsetHeight
    })`;

    return <g ref="axisAnchor" transform={translate} />;
  }
}

export default XAxis;
