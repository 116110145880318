import { isEmpty } from 'lodash';
import ansiToHTML from 'ansi-to-html';
import stripAnsi from 'strip-ansi';
import { UPDATE_PUBLISH_STATUS, FETCH_PUBLISH_STATUS } from './types';
import settingActions from 'actions/settings';
import { isValueEqual } from 'utils';
import { hideEditor, showEditor } from './editor';
import api from 'services/api';

const AUTO_COMMIT_DELAY = 360000;
const PUBLISH_STATUS_CHECK_INTERVAL = 2500;

const PUBLISH_STAGES = [
  {
    text: '-----> Preparing environment',
    start: 0,
    end: 10,
    time: 10000,
  },
  {
    text: '-----> Installing dependencies',
    start: 10,
    end: 50,
    time: 120000,
  },
  {
    text: '-----> Building app',
    start: 50,
    end: 80,
    time: 120000,
  },
  {
    text: '-----> Updating theme content',
    start: 80,
    end: 85,
    time: 1500,
  },
  {
    text: '-----> Updating theme editor',
    start: 80,
    end: 85,
    time: 1500,
  },
  {
    text: '-----> Updating theme settings',
    start: 80,
    end: 85,
    time: 1500,
  },
  {
    text: "-----> Updating '",
    start: 85,
    end: 98,
    time: 15000,
  },
  {
    text: "-----> Launching '",
    start: 85,
    end: 98,
    time: 15000,
  },
];

export const commitFiles = () => (dispatch, getState) => {
  const {
    storefronts: { storefront },
  } = getState();

  return api
    .put(`/storefronts/${storefront.id}/deployment`, {
      $commit: true,
    })
    .catch((err) => {
      console.error(err);
    });
};

let commitTimeout;

export const autoCommit = () => (dispatch) => {
  clearTimeout(commitTimeout);
  commitTimeout = setTimeout(() => {
    dispatch(commitFiles());
  }, AUTO_COMMIT_DELAY);
};

export const publishCheckout = () => async (dispatch, getState) => {
  const {
    git: { publishStatus },
    themeSettings: { present: settings },
  } = getState();
  try {
    if (
      settings.checkout &&
      !publishStatus.publishing &&
      !publishStatus.checkoutCurrent
    ) {
      dispatch(
        updatePublishStatus({
          publishing: true,
          checkoutPublishing: true,
          canceled: false,
          error: null,
          logs: null,
          renderedLogs: {},
        }),
      );
      const checkoutSettings = await dispatch(settingActions.load('checkout'));
      const result = await dispatch(
        settingActions.update('checkout', {
          $set: { theme: checkoutSettings.theme_dev },
          date_theme_published: new Date(),
        }),
      );
      dispatch(
        updatePublishStatus({
          checkoutCurrent: result.errors ? false : true,
          publishing: false,
          checkoutPublishing: false,
        }),
      );
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateModified = () => async (dispatch, getState) => {
  const {
    storefronts: { storefront },
  } = getState();
  try {
    dispatch(
      updatePublishStatus({
        current: false,
        filesCurrent: false,
        pushed: false,
      }),
    );
    await api.put(`/storefronts/${storefront.id}/deployment`, {
      $modified: true,
    });
  } catch (err) {
    console.error(err);
  }
};

export const fetchPublishStatus = (storefrontId) => async (dispatch) => {
  try {
    const [publishStatus, checkout] = await Promise.all([
      api.get(`/storefronts/${storefrontId}/status`),
      dispatch(settingActions.load('checkout')),
    ]);

    if (!publishStatus) {
      return dispatch({
        type: FETCH_PUBLISH_STATUS,
        payload: {
          publishing: false,
          canceled: false,
          error: new Error('Unable to get the status of the published changes'),
        },
      });
    }

    publishStatus.filesCurrent = publishStatus.current;

    if (isValueEqual(checkout.theme, checkout.theme_dev)) {
      publishStatus.checkoutCurrent = true;
    } else {
      publishStatus.checkoutCurrent = false;
    }

    publishStatus.date_checkout_published = checkout.date_theme_published;
    publishStatus.renderedLogs = getRenderedLogs(publishStatus.logs);

    dispatch({
      type: FETCH_PUBLISH_STATUS,
      payload: publishStatus,
    });

    if (publishStatus.publishing) {
      setTimeout(() => {
        dispatch(fetchPublishStatus(storefrontId));
      }, PUBLISH_STATUS_CHECK_INTERVAL);
    }

    return publishStatus;
  } catch (err) {
    console.error(err);
  }
};

export const updatePublishStatus = (update) => ({
  type: UPDATE_PUBLISH_STATUS,
  payload: update,
});

export const resetFiles = () => (dispatch, getState) => {
  const {
    storefronts: { storefront },
  } = getState();
  dispatch(hideEditor());
  api
    .put(`/storefronts/${storefront.id}/deployment`, {
      $reset: true,
    })
    .then((res) => {
      setTimeout(() => {
        dispatch(showEditor());
        // window.editorRefreshPage(() => dispatch(updateEditorLoading(false)));
      }, 500);
    })
    .catch((error) => console.error(error));
};

function getRenderedLogs(logs) {
  const convertAnsi = new ansiToHTML();
  const lines = [];
  let lastStage;
  let lastLine;
  if (logs) {
    for (let i = 0; i < logs.length; i++) {
      const str = logs[i];
      let rstr = str.replace(/^ {7}/, '');
      if (rstr.indexOf('-----> ') === 0) {
        for (let stage of PUBLISH_STAGES) {
          if (rstr.indexOf(stage.text) === 0) {
            lastStage = { line: stripAnsi(rstr), ...stage };
            lastLine = rstr.replace(/^-----> /, '');
          }
        }
        if (logs[i + 2] && logs[i + 2].indexOf('       ✅') === 0) {
          rstr = `${rstr} ✅`;
          logs.splice(i, 2);
        }
        rstr = `<b class="heading">${rstr.replace(/^-----> /, '')}</b>`;
      } else if (!isEmpty(str.trim())) {
        // lastLine = `> ${stripAnsi(str)}`;
      }
      lines.push(convertAnsi.toHtml(rstr) + '<br />');
    }
  }

  return { lines, lastStage, lastLine };
}
