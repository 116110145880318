import React from 'react';
import get from 'lodash/get';

import { formatNumber } from 'utils';
import { subscriptionTitle, subscriptionPriceDesc } from 'utils/subscription';

import Link from 'components/link';
import DateTime from 'components/date-time';
import SubscriptionStatus from 'components/pages/subscription/status';
import SubscriptionInvoiceNext from 'components/pages/subscription/invoice-next';
import SectionHeader from 'components/section-header/section-header';

export default class CustomerSubscriptions extends React.PureComponent {
  render() {
    const {
      record: { id, subscriptions },
    } = this.props;

    const subLength = get(subscriptions, 'results.length', 0);
    const subCount = get(subscriptions, 'count', 0);

    return (
      <div className="customer-subscriptions">
        <div className="view-body-section">
          <div className="view-body-subheader">
            <SectionHeader
              className="view-body-subtitle"
              title="Subscriptions"
              count={formatNumber(subCount)}
            />
          </div>
          <div className="view-section-content collection-full">
            {subscriptions && subscriptions.results.length > 0 ? (
              <div className="collection-table-container">
                <table className="collection-table">
                  <thead>
                    <tr>
                      <th>Plan</th>
                      <th>Created</th>
                      <th>Status</th>
                      <th>Next Invoice</th>
                      <th>Price</th>
                    </tr>
                  </thead>

                  <tbody>
                    {subscriptions.results.map((sub) => (
                      <tr key={sub.id}>
                        <td className="customer-subscription-title">
                          <Link to={`/subscriptions/${sub.id}`}>
                            {subscriptionTitle(sub)}
                          </Link>
                        </td>

                        <td>
                          <DateTime value={sub.date_created} format="short" />
                        </td>

                        <td>
                          <SubscriptionStatus record={sub} />
                        </td>

                        <td>
                          <SubscriptionInvoiceNext record={sub} />
                        </td>

                        <td>{subscriptionPriceDesc(sub)}</td>
                      </tr>
                    ))}
                    {subCount > subLength && (
                      <tr className="footnote">
                        <td colSpan={5}>
                          <Link to={`/subscriptions?customer=${id}`}>
                            View all &rarr;
                          </Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="collection-none-found">No subscriptions yet</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
