import React from 'react';

import PropTypes from 'prop-types';
import Form from 'components/form';
import View from 'components/view';
import { TabView } from 'components/form';
import WithViewPreferences from 'containers/WithViewPreferences';
import ContentRecordTab from 'components/content/record-tab';

import CategoryForm from './form';

import './category.scss';

function CategoryEdit({
  record,
  edited,
  onSubmitRecord,
  onChangeForm,
  onClickDelete,
  ...props
}) {
  return (
    <Form onSubmit={onSubmitRecord} onChange={onChangeForm}>
      <WithViewPreferences
        {...props}
        tabs={[]}
        model="categories"
        id="edit"
        type="record"
      >
        <View
          detail={true}
          uri="/categories"
          sectionTitle="Categories"
          devtools={{
            model: 'categories',
            record,
          }}
          headerTitle={record.name}
          headerActions={[
            {
              label: 'Save category',
              submit: true,
              type: edited ? 'default' : 'secondary',
            },
          ]}
          extraActions={[
            {
              label: 'Delete category',
              onClick: onClickDelete,
              className: 'danger',
              delete: true,
            },
          ]}
          localized={true}
        >
          <TabView value={location?.query?.tab} isDefault>
            <CategoryForm record={record} {...props} />
          </TabView>
          <ContentRecordTab {...props} collection="categories" view="edit" />
        </View>
      </WithViewPreferences>
    </Form>
  );
}

CategoryEdit.propTypes = {
  record: PropTypes.object.isRequired,
  edited: PropTypes.bool.isRequired,
  onSubmitRecord: PropTypes.func.isRequired,
  onChangeForm: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default CategoryEdit;
