import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, map } from 'lodash';
import { Form } from 'components/form';
import View from 'components/view';
import { canUserManagePermissions } from 'utils';
import AccessDeniedPage from 'components/pages/error/403';
import './settings.scss';

export default class UserRoles extends PureComponent {
  static contextTypes = {
    user: PropTypes.object.isRequired,
    isOwner: PropTypes.bool.isRequired,
    isAdvancedUserPermissions: PropTypes.bool.isRequired,
  };

  onClickRole = (event) => {
    const roleId = get(event, 'currentTarget.dataset.id');
    if (roleId) {
      this.props.router.push(`/settings/users/roles/${roleId}`);
    }
  };

  render() {
    const { roles } = this.props;
    const canManageRole = canUserManagePermissions(this.context);

    if (!canManageRole) {
      return <AccessDeniedPage withLogo={false} />;
    }

    return (
      <div className="settings settings-user-roles">
        <Form ref="form">
          <View
            detail={true}
            uri="/settings/users"
            sectionTitle="Users"
            headerTitle="Manage roles"
            headerSubtitle="Create and manage user permissions"
            headerActions={[
              { label: 'New role', link: '/settings/users/roles/new' },
            ]}
          >
            <div className="collection-table-container">
              <table className="collection-table collection-table-hoverable outer">
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>Users</th>
                  </tr>
                </thead>
                <tbody>
                  {map(roles.results, (role) => (
                    <tr
                      key={role.id}
                      data-id={role.id}
                      onClick={this.onClickRole}
                    >
                      <td className="bold">{role.name}</td>
                      <td>{role.users.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </View>
        </Form>
      </div>
    );
  }
}
