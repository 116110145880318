import React from 'react';
import PropTypes from 'prop-types';
import './user.scss';

export default class ExitNote extends React.PureComponent {
  static contextTypes = {
    user: PropTypes.object.isRequired,
    onClickLogout: PropTypes.func.isRequired,
    onClickSwitch: PropTypes.func.isRequired,
  };

  render() {
    const { hideSwitch, redirect } = this.props;
    const { user, onClickLogout, onClickSwitch } = this.context;

    return (
      <p className="note user-exit-note">
        Logged in as {user.email}.{' '}
        <u>
          <a href="" className="muted" onClick={onClickLogout}>
            Log out
          </a>
        </u>
        {!hideSwitch && user.authorizations.length > 0 && (
          <span>
            {' or '}
            <u>
              <a
                href=""
                className="muted"
                onClick={onClickSwitch}
                data-redirect={redirect}
              >
                switch accounts
              </a>
            </u>
          </span>
        )}
      </p>
    );
  }
}
