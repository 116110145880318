import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find } from 'lodash';
import ViewLoading from 'components/view/loading';
import Loading from 'components/loading';
import StorefrontsPage from 'components/storefront';
import actions from 'actions';

export const mapStateToProps = (state) => ({
  storefronts: state.storefronts.all,
  creatingQueue: state.storefronts.creatingQueue,
  loading: state.storefronts.loading,
});

export const mapDispatchToProps = (dispatch) => ({
  loadStore: () => {
    return dispatch(actions.storefronts.loadStore());
  },

  fetchAllStorefronts: () => {
    return dispatch(actions.storefronts.fetchAll());
  },

  fetchPublishStatus: (name) => {
    return dispatch(actions.storefronts.fetchPublishStatus(name));
  },

  fetchQueue: (name) => {
    return dispatch(actions.storefronts.fetchQueue(name));
  },

  updateStorefront: (name, data) => {
    return dispatch(actions.storefronts.update(name, data));
  },

  deleteStorefront: (name, data) => {
    return dispatch(actions.storefronts.delete(name, data));
  },
});

export class Storefront extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifyDeleted: PropTypes.func.isRequired,
  };

  mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      migrationStatus: false,
      cloneId: null,
      load: this.load.bind(this),
      onClickDelete: this.onClickDelete,
      onClickSetPrimary: this.onClickSetPrimary,
    };
  }

  componentDidMount() {
    this.mounted = true;

    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async load() {
    const { loadStore, fetchAllStorefronts } = this.props;
    const store = await loadStore();

    if (store?._migrate) {
      if (store._migrate_error) {
        this.setState({ migrationStatus: 'error' });
      } else if (store._migrating) {
        this.setState({ migrationStatus: 'migrating' });

        setTimeout(() => {
          this.load();
        }, 4000);
      }
    } else {
      // Reset migration status
      if (this.state.migrationStatus) {
        this.setState({ migrationStatus: false });
      }

      await fetchAllStorefronts();
      this.setState({ loaded: true });
    }
  }

  onClickDelete = (event) => {
    event?.preventDefault();
    const { record, storefronts, deleteStorefront, fetchAllStorefronts } =
      this.props;

    // Note this is used in both the list and view containers
    const id = event?.currentTarget?.dataset.id || params.id;
    if (!id) {
      return;
    }

    const storefront = record || find(storefronts.results, { id });
    if (!storefront) {
      return;
    }

    this.context.openModal('ConfirmDelete', {
      title: 'storefront',
      action: 'Confirm',
      confirmInput: {
        description: `Enter the storefront name to confirm`,
        value: storefront.name,
      },
      message: `Are you sure you want to delete this storefront permanently?`,
      onConfirm: () => {
        deleteStorefront(storefront.id).then(() => {
          this.context.notifyDeleted(`Storefront deleted`);
          fetchAllStorefronts();
        });
      },
    });
  };

  onClickSetPrimary = (event) => {
    event?.preventDefault();

    const { params, fetchAllStorefronts, updateStorefront } = this.props;

    // Note this is used in both the list and view containers
    const id = event?.currentTarget?.dataset.id || params.id;
    if (!id) {
      return;
    }

    this.context.openModal('Confirm', {
      title: 'Make primary',
      action: 'Set as primary',
      message: (
        <p>
          Are you sure you want to set this storefront as primary?
          <br />
          <br />
          Note: Customers will be referred to your primary storefront by
          default.
        </p>
      ),
      onConfirm: () => {
        updateStorefront(id, { primary: true }).then(() => {
          this.context.notifySuccess(`Storefront set as primary`);
          return fetchAllStorefronts();
        });
      },
    });
  };

  render() {
    const { migrationStatus, loaded } = this.state;

    if (migrationStatus) {
      if (migrationStatus === 'error') {
        return (
          <div className="view-container">
            <div className="storefront-view-thumbnail-overlay">
              We're sorry, something went wrong.
              <br />
              <span className="muted">
                Our system encountered an error while migrating your storefront
                configs. Please contact us for assistance.
                <br />
                <br />
              </span>
            </div>
          </div>
        );
      }

      return (
        <div className="view-container">
          <div className="storefront-view-thumbnail-overlay">
            <Loading />
            <br />
            <br />
            <span className="muted">
              <br />
              We are migrating your storefront configs. Please hold...
            </span>
          </div>
        </div>
      );
    }

    if (!loaded) {
      return <ViewLoading />;
    }

    return (
      <Fragment>
        <StorefrontsPage {...this.props} {...this.state} />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Storefront);
