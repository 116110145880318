import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { find, filter, size } from 'lodash';
import { Field, FieldLocalized, Fieldtable } from 'components/form';
import Tooltip from 'components/tooltip';
import { FadeIn } from 'components/transitions';
import { formatCurrency, currencyValue, inflect, isEmpty } from 'utils';
import { currencyDecimals, currencyName } from 'utils/money';

export default class GeneralCurrencies extends React.Component {
  static contextTypes = {
    account: PropTypes.object,
    openModal: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.renderCurrencyValue = this.renderCurrencyValue.bind(this);
    this.renderCurrencyForm = this.renderCurrencyForm.bind(this);
    this.renderModalActions = this.renderModalActions.bind(this);
    this.onSubmitModal = this.onSubmitModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmitModal(values) {
    if (isEmpty(values.decimals)) {
      values.decimals = currencyDecimals(values.code);
    }
    if (!values.name) {
      values.name = currencyName(values.code);
    }
    if (!values.round) {
      values.round = null;
      values.round_interval = null;
    }
  }

  onSubmit(values) {
    const { record } = this.props;
    const submitValue = [...values];
    const baseCode = record.client.currency;
    if (!find(record.client.currencies, { code: baseCode })) {
      submitValue.unshift({
        code: baseCode,
        name: currencyName(baseCode),
        decimals: currencyDecimals(baseCode),
        priced: true,
        fallback: false,
      });
      this.fieldtable.setState({ value: submitValue });
    }
    return this.props.onSubmit(submitValue);
  }

  onClickRemoveValue = (code, event) => {
    event.preventDefault();
    const { record } = this.props;
    const isDefault = code === record.client.currency;
    this.context.openModal('Confirm', {
      title: isDefault ? 'Disable multi-currency' : `Remove ${code}`,
      message: (
        <p>
          Are you sure you want to{' '}
          {isDefault
            ? 'disable multi-currency settings'
            : 'remove this currency'}
          ?
        </p>
      ),
      actionType: 'danger',
      onConfirm: () => {
        this.props.onSubmit(
          filter(record.client.currencies, (c) => c.code !== code),
        );
        this.fieldtable.onCloseModalForm();
      },
    });
  };

  onClickMakeDefault = (code, event) => {
    event.preventDefault();
    this.context.openModal('Confirm', {
      title: `Change base currency`,
      message: (
        <p>
          Are you sure you want to set <b>{code}</b> as your base currency?
        </p>
      ),
      actionType: 'danger',
      onConfirm: () => {
        this.props.onMakeDefault(code);
        this.fieldtable.onCloseModalForm();
      },
    });
  };

  renderModalActions(values, index, actions) {
    const { record } = this.props;
    if (
      !values ||
      !values.code ||
      !find(record.client.currencies, { code: values.code })
    ) {
      return actions;
    }
    const isDefault = values.code === record.client.currency;
    return [
      actions[0],
      ...(isDefault &&
        size(record.client.currencies) === 1 && [
          {
            label: 'Disable multi-currency',
            type: 'secondary',
            className: 'left button-cancel',
            onClick: this.onClickRemoveValue.bind(this, values.code),
          },
        ]),
      ...(!isDefault && [
        {
          label: 'Remove currency',
          type: 'secondary',
          className: 'left button-cancel',
          onClick: this.onClickRemoveValue.bind(this, values.code),
        },
        {
          label: 'Set as default',
          type: 'secondary',
          onClick: this.onClickMakeDefault.bind(this, values.code),
        },
      ]),
      actions[1],
    ];
  }

  renderRoundDescription(value) {
    switch (value.round) {
      case 'up':
      case 'down':
      case 'nearest':
        return (
          <Fragment>
            &bull; Round {value.round === 'nearest' ? '' : value.round} to the
            nearest{' '}
            {value.round_interval === 'fraction'
              ? `${formatCurrency(+value.round_fraction || 0, value.code)}`
              : 'whole value'}
          </Fragment>
        );
      default:
        break;
    }
    return <span className="muted">&mdash;</span>;
  }

  renderCurrencyHeading() {
    return [
      <th key="1">Currency</th>,
      <th key="2">Decimals</th>,
      <th key="3">Value required</th>,
      <th key="4">Fallback</th>,
      <th key="5">Kind</th>,
    ];
  }

  renderCurrencyValue({ value }) {
    const {
      account: { hasPricedCurrencies },
    } = this.context;
    const {
      record: { client },
    } = this.props;

    const isDefault = value.code === client.currency;
    const displayClass = !isDefault && !value.active ? 'muted' : '';
    return [
      <td key="1" className={displayClass}>
        <span>
          {value.name} ({value.code})
        </span>
      </td>,
      <td key="2" className={displayClass}>
        <span>{inflect(value.decimals || 0, 'digits')}</span>
      </td>,
      <td key="3" className={displayClass}>
        <span>{isDefault || value.required ? 'Yes' : 'No'}</span>
      </td>,
      <td key="4" className={displayClass}>
        <span>
          {!isDefault &&
          (!hasPricedCurrencies || !value.priced || value.fallback) ? (
            value.round ? (
              <Fragment>
                Conversion from {client.currency}
                <br />
                {this.renderRoundDescription(value)}
              </Fragment>
            ) : (
              <Fragment>Conversion from {client.currency}</Fragment>
            )
          ) : (
            <span className="muted">&mdash;</span>
          )}
        </span>
      </td>,
      <td key="5" className={displayClass}>
        <span>
          {isDefault ? (
            <Tooltip message="Orders are denominated and settled in your base currency">
              Base
            </Tooltip>
          ) : value.priced ? (
            <Tooltip message="Products can be priced in this currency">
              Priced
            </Tooltip>
          ) : (
            <Tooltip message="Products can be displayed in this currency using a live conversion rate">
              Display
            </Tooltip>
          )}
        </span>
      </td>,
    ];
  }

  renderCurrencyForm(values, index, allValues) {
    const {
      account: { hasPricedCurrencies },
    } = this.context;

    const { record } = this.props;
    const disabledOptions = [
      this.props.values.client.currency,
      ...allValues.filter((c, i) => i !== +index).map((c) => c.code),
    ];
    const isDefault = values.code === this.props.values.client.currency;
    const isPriced =
      hasPricedCurrencies && values.priced !== undefined
        ? !!values.priced
        : values.code
        ? false
        : true;
    const isRounding = !!values.round;
    const nameValue = currencyName(values.code);
    const decimals = !isEmpty(values.decimals)
      ? values.decimals
      : currencyDecimals(values.code);

    return (
      <Fragment>
        {isDefault && (
          <p className="note muted">Note: this is your base currency</p>
        )}
        <div className="row">
          <Field
            type="select"
            name="code"
            label="Currency"
            options={record.geo.currencies}
            defaultValue={values.code}
            disabledOptions={disabledOptions}
            disabled={isDefault}
            required={true}
            className="span3"
          />
          <Field
            type="number"
            name="decimals"
            label="Decimal digits"
            defaultValue={values.decimals}
            placeholder={decimals}
            selectFocus={true}
            className="span1"
            maxValue={10}
          />
        </div>
        <div className="row">
          <FieldLocalized
            type="text"
            name="name"
            label="Label"
            defaultValue={values.name === nameValue ? '' : values.name}
            localeValue={values.$locale}
            placeholder={nameValue}
            className="span3"
          />
          <div className="span1" />
        </div>
        {isDefault ? (
          <Fragment>
            {values.active !== undefined && (
              <Field type="hidden" name="active" value={values.active} />
            )}
            {values.priced !== undefined && (
              <Field type="hidden" name="priced" value={values.priced} />
            )}
            {values.required !== undefined && (
              <Field type="hidden" name="required" value={values.required} />
            )}
            {values.fallback !== undefined && (
              <Field type="hidden" name="fallback" value={values.fallback} />
            )}
          </Fragment>
        ) : (
          <Fragment>
            <br />
            <div className="row">
              <Field
                type="toggle"
                name="active"
                label="Enabled"
                defaultChecked={
                  values.active !== undefined ? values.active : true
                }
                className="span4"
              />
            </div>
            <div className="row">
              <Field
                type="toggle"
                name="priced"
                label="Priced"
                defaultChecked={isPriced}
                help={
                  hasPricedCurrencies
                    ? 'Products can be priced in this currency'
                    : 'Upgrade your plan to enable setting prices in this currency'
                }
                className="span4"
                disabled={!hasPricedCurrencies}
              />
            </div>
            <div className="row">
              <FadeIn
                active={isPriced}
                transitionAppear={false}
                className="span4"
              >
                <Field
                  type="toggle"
                  name="required"
                  label="Require values for this currency"
                  defaultChecked={
                    values.required !== undefined ? values.required : false
                  }
                  help="Priced currency fields will require a value to be saved"
                />
              </FadeIn>
            </div>
            <div className="row">
              <FadeIn
                active={isPriced}
                transitionAppear={false}
                className="span4"
              >
                <Field
                  type="toggle"
                  name="fallback"
                  label="Use conversion rate as fallback"
                  defaultChecked={!!values.fallback}
                  help="When a price is not defined, use live conversion rate from your base currency"
                />
              </FadeIn>
            </div>
            {(!isPriced || values.fallback) && (
              <Fragment>
                <div className="row">
                  <Field
                    type="radio"
                    name="round"
                    label="Rounding"
                    buttons={true}
                    defaultValue={values.round}
                    options={[
                      {
                        value: null,
                        label: 'None',
                      },
                      {
                        value: 'up',
                        label: 'Up',
                      },
                      {
                        value: 'down',
                        label: 'Down',
                      },
                      // {
                      //   value: 'nearest',
                      //   label: 'Nearest',
                      // },
                    ]}
                    className="span4"
                  />
                </div>
                <FadeIn
                  active={isRounding}
                  transitionAppear={false}
                  className="row"
                >
                  <Field
                    type="radio"
                    name="round_interval"
                    label="To the nearest"
                    defaultValue={values.round_interval}
                    stacked={true}
                    className="span4"
                    options={[
                      {
                        value: 'whole',
                        label: 'Whole',
                      },
                      {
                        value: 'fraction',
                        label: (
                          <Fragment>
                            Fraction{' '}
                            {values.round_interval === 'fraction' && (
                              <FadeIn
                                className="form-radio-nested"
                                style={{ width: 100 }}
                              >
                                <Field
                                  type="currency"
                                  name="round_fraction"
                                  currency={values.code}
                                  scale={+decimals}
                                  defaultValue={currencyValue(
                                    values.round_fraction,
                                    values.code,
                                    {
                                      precision: values.decimals,
                                    },
                                  )}
                                  placeholder={currencyValue(0, values.code, {
                                    precision: values.decimals,
                                  })}
                                  autoFocus={true}
                                  selectFocus={true}
                                  required={true}
                                  minValue={0}
                                  maxValue={1}
                                />
                              </FadeIn>
                            )}
                          </Fragment>
                        ),
                      },
                    ]}
                  />
                </FadeIn>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }

  render() {
    const { record } = this.props;

    return (
      <Fieldtable
        label="Currency"
        name="client.currencies"
        formWidth={600}
        mountAddRow={this.props.mountAddRow}
        onSubmit={this.onSubmit}
        onSubmitModal={this.onSubmitModal}
        defaultValue={record.client.currencies}
        renderHeading={this.renderCurrencyHeading}
        renderValue={this.renderCurrencyValue}
        renderForm={this.renderCurrencyForm}
        sortable={true}
        removable={false}
        getRef={(fieldtable) => (this.fieldtable = fieldtable)}
        renderModalActions={this.renderModalActions}
        localized={true}
      />
    );
  }
}
