import React from 'react';
import { bool, func } from 'prop-types';
import { ANNUAL_DISCOUNT } from '../../shared/billing';

const PlanToggle = ({ isMonthly, onClickIntervalToggle }) => (
  <div className="interval-toggle-container">
    <button
      className={`interval-toggle left-toggle ${
        isMonthly ? 'active-toggle' : 'inactive-toggle'
      }`}
      onClick={() => onClickIntervalToggle('monthly')}
    >
      Monthly
    </button>
    <button
      className={`interval-toggle right-toggle ${
        isMonthly ? 'inactive-toggle' : 'active-toggle'
      }`}
      onClick={() => onClickIntervalToggle('annually')}
    >
      Yearly (Save {ANNUAL_DISCOUNT}%)
    </button>
  </div>
);

PlanToggle.propTypes = {
  isMonthly: bool.isRequired,
  onClickIntervalToggle: func.isRequired,
};

export default PlanToggle;
