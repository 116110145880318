import React from 'react';
import Label from './label';
import IconPayment from 'components/icon/payment';
import ccutils from 'creditcardutils';

export default class InputCardNumber extends React.PureComponent {
  state = {
    formattedValue: '',
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        formattedValue: ccutils.formatCardNumber(nextProps.value),
      });
    }
  }

  value() {
    return this.state.formattedValue;
  }

  render() {
    const { label, help, onChange, labelClass, ...props } = this.props;

    const { formattedValue } = this.state;
    const cardBrand = ccutils.parseCardType(formattedValue);

    return (
      <span className="form-card-number">
        <Label
          className={labelClass}
          label={label}
          help={help}
          htmlFor={props.id}
        />
        <span className="form-field-input">
          <input
            {...props}
            ref="input"
            placeholder={props.placeholder || '#### #### #### ####'}
            value={formattedValue}
            defaultValue={undefined}
            onChange={onChange}
            autoComplete="off"
            className={`${props.disabled ? 'disabled' : ''} ${
              props.className || ''
            }`}
          />
          <IconPayment method="card" card={{ brand: cardBrand }} />
        </span>
      </span>
    );
  }
}
