import React from 'react';
import pt from 'prop-types';

import Modal from 'components/modal';
import Form from 'components/form';
import Link from 'components/link';
import Icon from 'components/icon';

import CategoryForm from 'components/pages/category/form';

export default class EditCategoryModalPage extends React.Component {
  static propTypes = {
    record: pt.object,
    loaded: pt.bool,
    edited: pt.bool,

    onChangeForm: pt.func,
    onSubmitRecord: pt.func,
    onClickDelete: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
    closeModal: pt.func.isRequired,
  };

  formRef = React.createRef();

  onClickSubmit = (event) => {
    event.preventDefault();
    this.formRef.current.submit();
  };

  onClickClose = (event) => {
    if (this.props.edited) {
      event.preventDefault();

      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.context.closeModal();
        },
      });
    } else {
      this.context.closeModal();
    }
  };

  onCloseModal = (event) => {
    if (this.props.edited) {
      event.preventDefault();

      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.context.closeModal();
        },
      });
    }
  };

  render() {
    const {
      loaded,
      record,
      edited,
      onSubmitRecord,
      onChangeForm,
      onClickDelete,
    } = this.props;

    return (
      <Modal
        title={record && <span>{record.name}</span>}
        width={800}
        onClose={this.onCloseModal}
        cancel={edited ? true : false}
        actions={[
          edited
            ? {
                label: 'Save category',
                onClick: this.onClickSubmit,
                type: 'default',
              }
            : { label: 'Close', onClick: this.onClickClose, type: 'secondary' },
          {
            label: 'Delete category',
            onClick: onClickDelete,
            type: 'cancel-danger left',
          },
        ]}
        loading={!loaded}
        localized={true}
        externalLink={{
          link: record && `/categories/${record.id}`,
          label: 'Open in page',
        }}
        devtools={{ model: 'categories', uri: record && record.id }}
        overrideHelpLinkKey="categories.:id"
      >
        {record && (
          <Form
            ref={this.formRef}
            onSubmit={onSubmitRecord}
            onChange={onChangeForm}
          >
            <CategoryForm {...this.props} modal={true} />
          </Form>
        )}
      </Modal>
    );
  }
}
