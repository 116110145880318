import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, find, filter, size } from 'lodash';
import { Field, FieldLocalized, Fieldtable } from 'components/form';
import LocaleFieldSelect from 'components/locale/field-select';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import { localeName } from 'utils/geo';

export default class GeneralLocales extends React.Component {
  static contextTypes = {
    openModal: PropTypes.func,
    user: PropTypes.object,
    toggleLocale: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.renderLocaleValue = this.renderLocaleValue.bind(this);
    this.renderLocaleForm = this.renderLocaleForm.bind(this);
    this.renderModalActions = this.renderModalActions.bind(this);
    this.onSubmitModal = this.onSubmitModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmitModal(values) {
    const { record } = this.props;
    const { user, toggleLocale } = this.context;

    if (!values.name) {
      values.name = localeName(values.code);
    }
    if (values.fallback === record.client.locale) {
      values.fallback = null;
    }
    const isNew = !find(record.client.locales, { code: values.code });
    if (isNew && user.localeCodes.indexOf(values.code) === -1) {
      toggleLocale(values.code);
    }
  }

  onSubmit(values) {
    const { record } = this.props;
    const submitValue = [...values];
    const baseCode = record.client.locale;
    if (!find(record.client.locales, { code: baseCode })) {
      submitValue.unshift({
        code: baseCode,
        name: localeName(baseCode),
      });
      this.fieldtable.setState({ value: submitValue });
    }
    submitValue.forEach((el, index) => {
      if (el.default) {
        delete el.default;
        this.props.onMakeDefault(el.code);
      }
    });
    return this.props.onSubmit(submitValue);
  }

  onClickRemoveValue = (code, event) => {
    event.preventDefault();
    const { record } = this.props;
    const isDefault = code === record.client.locale;
    this.context.openModal('Confirm', {
      title: isDefault ? 'Disable multi-language' : `Remove ${code}`,
      message: (
        <p>
          Are you sure you want to{' '}
          {isDefault ? 'disable multi-language settings' : 'remove this locale'}
          ?
        </p>
      ),
      actionType: 'danger',
      onConfirm: () => {
        this.props.onSubmit(
          filter(record.client.locales, (c) => c.code !== code),
        );
        this.fieldtable.onCloseModalForm();
      },
    });
  };

  onClickMakeDefault = (code, event) => {
    event.preventDefault();
    this.context.openModal('Confirm', {
      title: `Change base locale`,
      message: (
        <p>
          Are you sure you want to set <b>{code}</b> as your base locale?
        </p>
      ),
      actionType: 'danger',
      onConfirm: () => {
        this.props.onMakeDefault(code);
        this.fieldtable.onCloseModalForm();
      },
    });
  };

  renderModalActions(values, index, actions) {
    const { record } = this.props;
    if (
      !values ||
      !values.code ||
      !find(record.client.locales, { code: values.code })
    ) {
      return actions;
    }
    const isDefault = values.code === record.client.locale;
    return [
      actions[0],
      ...(isDefault &&
        size(record.client.locales) === 1 && [
          {
            label: 'Disable multi-language',
            type: 'secondary',
            className: 'left button-cancel',
            onClick: this.onClickRemoveValue.bind(this, values.code),
          },
        ]),
      ...(!isDefault && [
        {
          label: 'Remove locale',
          type: 'secondary',
          className: 'left button-cancel',
          onClick: this.onClickRemoveValue.bind(this, values.code),
        },
        {
          label: 'Set as default',
          type: 'secondary',
          onClick: this.onClickMakeDefault.bind(this, values.code),
        },
      ]),
      actions[1],
    ];
  }

  renderLocaleHeading() {
    return [
      <th key="1">Locale</th>,
      <th key="2">Content required</th>,
      <th key="3">Fallback</th>,
      <th key="4">Kind</th>,
    ];
  }

  renderLocaleValue({ value }) {
    const {
      record: { client },
    } = this.props;
    const isDefault = value.code === client.locale;
    const displayClass = !isDefault && !value.active ? 'muted' : '';
    return [
      <td key="1" className={displayClass}>
        <span>
          <Icon locale={value.code} /> &nbsp; {value.name} ({value.code})
        </span>
      </td>,
      <td key="2" className={displayClass}>
        <span>{isDefault || value.required ? 'Yes' : 'No'}</span>
      </td>,
      <td key="3" className={displayClass}>
        <span>
          {isDefault || value.fallback === 'none' ? (
            <span className="muted">&mdash;</span>
          ) : (
            value.fallback || client.locale
          )}
        </span>
      </td>,
      <td key="4" className={displayClass}>
        <span>
          {isDefault ? (
            <Tooltip message="Text is defined in this locale by default">
              Default
            </Tooltip>
          ) : (
            <Tooltip message="Text can be defined in this locale as an alternative">
              Alternate
            </Tooltip>
          )}
        </span>
      </td>,
    ];
  }

  renderLocaleForm(values, index, allValues) {
    const { record } = this.props;
    const defaultCode = this.props.values.client.locale;
    const disabledOptions = [
      ...allValues.filter((c, i) => i !== +index).map((c) => c.code),
    ];
    const isDefault = values.code === this.props.values.client.locale;
    const nameValue = localeName(values.code);
    const defaultFallbackName = get(
      find(allValues, { code: defaultCode }),
      'name',
      localeName(defaultCode),
    );

    return (
      <Fragment>
        {isDefault && (
          <Fragment>
            <p className="note muted">Note: this is your default locale</p>
          </Fragment>
        )}
        <div className="row">
          <LocaleFieldSelect
            name="code"
            label="Locale"
            options={record.geo.locales}
            defaultValue={values.code}
            disabledOptions={disabledOptions}
            required={true}
            className="span4"
          />
        </div>
        {values.code && (
          <div className="row">
            <FieldLocalized
              type="text"
              name="name"
              label="Label"
              defaultValue={values.name === nameValue ? '' : values.name}
              localeValue={values.$locale}
              localeTemp={values.code}
              placeholder={nameValue}
              className="span4"
            />
          </div>
        )}
        {isDefault ? (
          <Fragment>
            <Field type="hidden" name="default" value={true} />
            {values.active !== undefined && (
              <Field type="hidden" name="active" value={values.active} />
            )}
            {values.required !== undefined && (
              <Field type="hidden" name="required" value={values.required} />
            )}
            {values.fallback !== undefined && (
              <Field type="hidden" name="fallback" value={values.fallback} />
            )}
          </Fragment>
        ) : (
          <Fragment>
            <br />
            <div className="row">
              <Field
                type="toggle"
                name="active"
                label="Enabled"
                defaultChecked={
                  values.active !== undefined ? values.active : true
                }
                help="Locale content will be visible in your storefront"
                className="span4"
              />
            </div>
            <div className="row">
              <Field
                type="toggle"
                name="required"
                label="Require content for this locale"
                defaultChecked={
                  values.required !== undefined ? values.required : false
                }
                help="Localizable fields will require content to be saved"
                className="span4"
              />
            </div>
            <br />
            <div className="row">
              <Field
                type="select"
                name="fallback"
                label="Fallback locale"
                options={[
                  { value: 'none', label: 'None' },
                  ...record.geo.locales,
                ]}
                defaultValue={values.fallback}
                disabledOptions={[values.code]}
                placeholder={defaultFallbackName}
                help="If no content is provided for the above locale, your storefront will fallback to this one"
                className="span4"
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }

  render() {
    const { record } = this.props;

    return (
      <Fieldtable
        label="Locale"
        name="client.locales"
        formWidth={600}
        mountAddRow={this.props.mountAddRow}
        onSubmit={this.onSubmit}
        onSubmitModal={this.onSubmitModal}
        defaultValue={record.client.locales}
        renderHeading={this.renderLocaleHeading}
        renderValue={this.renderLocaleValue}
        renderForm={this.renderLocaleForm}
        sortable={true}
        removable={false}
        getRef={(fieldtable) => (this.fieldtable = fieldtable)}
        renderModalActions={this.renderModalActions}
        localized={true}
      />
    );
  }
}
