import React, { Fragment } from 'react';
import pt from 'prop-types';
import get from 'lodash/get';

import {
  formatCurrencyRounded,
  formatCurrency,
  parseCurrency,
  currencyValue,
  isPrice,
} from 'utils';

import { shippingService } from 'utils/order';
import { productThumbUrl, productName } from 'utils/product';

import Link from 'components/link';
import { Field } from 'components/form';
import Icon from 'components/icon';
import ButtonLink from 'components/button/link';
import { FadeIn } from 'components/transitions';
import OrderItemDetail from 'components/pages/order/item-detail';
import Help from 'components/tooltip/help';
import Tooltip from 'components/tooltip';
import {
  getNotIncludedTaxesTooltip,
  getIncludedTaxesFootnote,
} from 'components/pages/order/taxes-tooltip';

export default class SubscriptionItems extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    settings: pt.object,
    editing: pt.bool,
    editable: pt.bool,
    editItems: pt.array,
    hasShipmentItems: pt.bool,

    onClickEditItems: pt.func,
    onClickEditSingleItem: pt.func,
    onClickEditPlan: pt.func,
    onClickAddItem: pt.func,
    onClickRemoveEditItem: pt.func,
    onClickDiscountsEdit: pt.func,
    onChangeEditItemInput: pt.func,
    onClickShippingEdit: pt.func,
  };

  static contextTypes = {
    client: pt.object.isRequired,
    account: pt.object.isRequired,
  };

  render() {
    const {
      record,
      settings,
      editing,
      editable,
      editItems,
      hasShipmentItems,
      onClickEditItems,
      onClickEditSingleItem,
      onClickEditPlan,
      onClickAddItem,
      onClickRemoveEditItem,
      onClickDiscountsEdit,
      onChangeEditItemInput,
      onClickShippingEdit,
    } = this.props;

    const { client } = this.context;

    const currency = record.currency || client.currency;
    const couponCode = record.coupon_code || undefined;
    const invoiceItems = editing ? editItems : record.items || [];

    const subscriptionTrialDays =
      get(record, 'billing_schedule.trial_days') ||
      get(
        (record.options || []).find((option) => option.subscription) || {},
        'subscription_trial_days',
      );

    if (
      (record.product && record.product_id) ||
      typeof record.price === 'number'
    ) {
      return (
        <table className="collection-table white-space-wrap outer">
          <thead>
            <tr>
              <th colSpan="2">{editing ? 'Invoice items' : 'Plan'}</th>
              <th>Qty</th>
              <th className={editing ? undefined : 'currency'}>Price</th>
              <th className="currency">Total</th>
              {editing && <th>&nbsp;</th>}
            </tr>
          </thead>

          <tbody>
            {!editing && (
              <tr>
                <td className="image">
                  <div className="collection-table-images">
                    <div className="image">
                      <Link to={`/products/${record.product_id}`}>
                        <img
                          src={productThumbUrl(record.product, record.variant)}
                          alt={productName(record.product, record.variant)}
                        />
                      </Link>
                    </div>
                  </div>
                </td>

                <td>
                  <OrderItemDetail
                    item={{
                      ...record,
                      bundle_items: record.product?.bundle_items,
                    }}
                    onClickEditSingleItem={onClickEditSingleItem}
                    onClickEditPlan={onClickEditPlan}
                  />
                </td>

                <td className="nowrap">
                  <div className="collection-field-padded">
                    {record.quantity}
                  </div>
                </td>

                <td className="currency">
                  <div className="collection-field-padded">
                    {formatCurrency(record.price, currency)}
                  </div>
                </td>

                <td className="currency">
                  <div className="collection-field-padded">
                    {record.draft && subscriptionTrialDays > 0 ? (
                      <Fragment>
                        <strike>
                          {formatCurrency(record.price_total, currency)}
                        </strike>

                        <div className="positive">
                          {subscriptionTrialDays}-day trial
                        </div>
                      </Fragment>
                    ) : (
                      formatCurrency(record.price_total, currency)
                    )}
                  </div>
                </td>
              </tr>
            )}

            {invoiceItems.length > 0 && (
              <Fragment>
                {!editing && (
                  <tr>
                    <th colSpan="5">Invoice items</th>
                  </tr>
                )}

                {invoiceItems.map((item, index) => {
                  const hasPrice =
                    (isPrice(item.price) && item.price !== item.orig_price) ||
                    isPrice(item.set_price);

                  const actualPrice = isPrice(item.set_price)
                    ? item.set_price
                    : isPrice(item.calc_price)
                    ? item.calc_price
                    : item.price;

                  const formattedItemPrice = currencyValue(
                    actualPrice,
                    currency,
                  );

                  const floatItemPrice = parseCurrency(
                    formattedItemPrice,
                    currency,
                  );

                  return (
                    <tr
                      key={index}
                      style={item.removed ? { opacity: 0.5 } : undefined}
                    >
                      <td className="image top">
                        <FadeIn active={true} transitionAppear={!item.id}>
                          <Field
                            type="hidden"
                            name={`items[${index}].id`}
                            value={item.id}
                          />

                          <div className="collection-table-images">
                            <div className="image">
                              {item.product_id && (
                                <Link to={`/products/${item.product_id}`}>
                                  <img
                                    src={productThumbUrl(
                                      item.product,
                                      item.variant,
                                    )}
                                    alt={productName(
                                      item.product,
                                      item.variant,
                                    )}
                                  />
                                </Link>
                              )}
                            </div>
                          </div>
                        </FadeIn>
                      </td>

                      <td>
                        <FadeIn active={true} transitionAppear={!item.id}>
                          <OrderItemDetail
                            item={item}
                            index={index}
                            style={
                              item.removed
                                ? { textDecoration: 'line-through' }
                                : undefined
                            }
                            onClickEditSingleItem={onClickEditSingleItem}
                          />
                        </FadeIn>
                      </td>

                      <td
                        className="nowrap"
                        style={
                          editing
                            ? { width: 60, textAlign: 'center' }
                            : undefined
                        }
                      >
                        {item.proration ? (
                          <span className="muted">&mdash;</span>
                        ) : editing ? (
                          <FadeIn active={true} transitionAppear={!item.id}>
                            <Field
                              type="text"
                              placeholder="1"
                              name={`${index}.quantity`}
                              defaultValue={item.quantity}
                              scale={0}
                              minValue={1}
                              maxValue={9999999}
                              onChange={onChangeEditItemInput}
                              disabled={item.removed}
                              selectFocus={true}
                              tabIndex={index}
                            />
                          </FadeIn>
                        ) : (
                          <div className="collection-field-padded">
                            {item.quantity}
                          </div>
                        )}
                      </td>

                      <td
                        className={editing ? undefined : 'currency'}
                        style={
                          editing
                            ? { width: 110, textAlign: 'center' }
                            : undefined
                        }
                      >
                        {item.proration ? (
                          <span className="muted">&mdash;</span>
                        ) : editing ? (
                          <FadeIn active={true} transitionAppear={!item.id}>
                            <Field
                              type="currency"
                              name={`${index}.set_price`}
                              placeholder={
                                isPrice(item.calc_price)
                                  ? currencyValue(item.calc_price)
                                  : currencyValue(item.price)
                              }
                              defaultValue={hasPrice ? actualPrice : undefined}
                              currency={currency}
                              onChange={onChangeEditItemInput}
                              disabled={item.removed}
                              selectFocus={true}
                              tabIndex={index}
                            />
                          </FadeIn>
                        ) : (
                          <div className="collection-field-padded">
                            {formatCurrency(item.price, currency)}
                          </div>
                        )}
                      </td>

                      <td
                        className="currency"
                        style={editing ? { width: 75 } : undefined}
                      >
                        <div
                          className="collection-field-padded"
                          style={
                            item.removed
                              ? { textDecoration: 'line-through' }
                              : undefined
                          }
                        >
                          {editing ? (
                            <FadeIn active={true} transitionAppear={!item.id}>
                              {currencyValue(
                                floatItemPrice * (item.quantity || 1),
                                currency,
                                { isSymbol: true },
                              )}
                            </FadeIn>
                          ) : (
                            formatCurrency(item.price_total, currency)
                          )}
                        </div>
                      </td>

                      {editing && (
                        <td className="action">
                          <FadeIn active={true} transitionAppear={!item.id}>
                            <div className="collection-field-padded">
                              <button
                                data-index={index}
                                className="as-link"
                                onClick={onClickRemoveEditItem}
                                type="button"
                              >
                                {item.removed ? 'Undo' : 'Remove'}
                              </button>
                            </div>
                          </FadeIn>
                        </td>
                      )}
                    </tr>
                  );
                })}

                {(editing || editable) && (
                  <tr className="order-row-add empty">
                    <td>
                      <button
                        className="collection-table-images"
                        onClick={onClickAddItem}
                        type="button"
                      >
                        <div className="image">&nbsp;</div>
                      </button>
                    </td>

                    <td colSpan="4">
                      <button
                        className="as-link"
                        onClick={onClickAddItem}
                        type="button"
                      >
                        <div className="collection-field-padded">
                          <Icon fa="plus" />
                          &nbsp; Add item
                        </div>
                      </button>
                    </td>

                    {editing && <td />}
                  </tr>
                )}
              </Fragment>
            )}

            {!editing && (
              <tr>
                <td colSpan="5">
                  <div className="order-totals">
                    <span className="order-totals-label">Subtotal</span>

                    <span className="order-totals-value">
                      {formatCurrency(record.sub_total, currency)}
                    </span>

                    {hasShipmentItems && (
                      <div>
                        <div className="order-totals-label">
                          <button
                            className="as-link sneaky-link"
                            onClick={onClickShippingEdit}
                            type="button"
                          >
                            Shipping{' '}
                            {settings && record.shipping?.service && (
                              <Fragment>
                                (
                                {get(
                                  shippingService(settings, record.shipping),
                                  'name',
                                  record.shipping.service,
                                )}
                                )
                              </Fragment>
                            )}
                          </button>
                        </div>

                        <div className="order-totals-value">
                          <button
                            className="as-link sneaky-link"
                            onClick={onClickShippingEdit}
                            type="button"
                          >
                            {record.shipment_discount > 0 && (
                              <Tooltip
                                message={`Discounted by ${formatCurrency(
                                  record.shipment_discount,
                                  currency,
                                )} from ${formatCurrency(
                                  record.shipment_total +
                                    record.shipment_discount,
                                  currency,
                                )}`}
                              >
                                <span className="help icon icon-info icon-info-shipping">
                                  &nbsp;
                                </span>
                                &nbsp;&nbsp;
                              </Tooltip>
                            )}

                            {formatCurrency(record.shipment_total, currency)}
                          </button>
                        </div>
                      </div>
                    )}

                    {record.discount_total > 0 && (
                      <div>
                        <div className="order-totals-label">
                          Discounts
                          <ul>
                            {couponCode && (
                              <li>
                                <div>
                                  Coupon:&nbsp;
                                  {record.coupon_id ? (
                                    <Link to={`/coupons/${record.coupon_id}`}>
                                      {couponCode}
                                    </Link>
                                  ) : (
                                    <span>{couponCode}</span>
                                  )}
                                  {record.coupon &&
                                  record.coupon.limit_subscription_uses ? (
                                    <Help
                                      message={`Applied to ${
                                        record.coupon_use_count
                                          ? record.coupon_use_count
                                          : 0
                                      } of ${
                                        record.coupon.limit_subscription_uses
                                      } subscription cycles`}
                                    />
                                  ) : null}
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>

                        <div className="order-totals-value">
                          {record.discount_total > 0 ? (
                            <span>
                              {`(${formatCurrency(
                                record.discount_total,
                                currency,
                              )})`}
                            </span>
                          ) : (
                            <span>&mdash;</span>
                          )}
                        </div>
                      </div>
                    )}

                    <span className="order-totals-label">Taxes</span>

                    <span className="order-totals-value">
                      {record.tax_included_total > 0 ? (
                        <span>
                          {getNotIncludedTaxesTooltip(
                            record,
                            true,
                            hasShipmentItems,
                            currency,
                          )}
                          {formatCurrency(record.tax_included_total, currency)}
                        </span>
                      ) : (
                        <span>&mdash;</span>
                      )}
                    </span>

                    <span className="order-totals-label grand">Total</span>

                    <span className="order-totals-value grand">
                      {formatCurrencyRounded(record.grand_total, currency)}
                    </span>

                    {getIncludedTaxesFootnote(
                      record,
                      true,
                      hasShipmentItems,
                      currency,
                    )}
                  </div>
                </td>
              </tr>
            )}

            {!editing && editable && (
              <tr className="tfoot">
                <td colSpan="100" className="action">
                  <ButtonLink
                    type="secondary"
                    size="sm"
                    onClick={onClickDiscountsEdit}
                  >
                    {record.discounts ? 'Edit coupon' : 'Apply coupon'}
                  </ButtonLink>

                  {hasShipmentItems && (
                    <ButtonLink
                      type="secondary"
                      size="sm"
                      onClick={onClickShippingEdit}
                    >
                      Edit shipping
                    </ButtonLink>
                  )}

                  {invoiceItems.length > 0 ? (
                    <ButtonLink
                      type="secondary"
                      size="sm"
                      onClick={onClickEditItems}
                    >
                      Edit invoice items
                    </ButtonLink>
                  ) : (
                    <ButtonLink
                      type="secondary"
                      size="sm"
                      onClick={onClickAddItem}
                    >
                      Add invoice item
                    </ButtonLink>
                  )}

                  {record.draft && (
                    <ButtonLink
                      type="secondary"
                      size="sm"
                      onClick={onClickEditPlan}
                    >
                      Edit plan
                    </ButtonLink>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      );
    }

    return (
      <table className="collection-table outer empty">
        <thead>
          <tr>
            <th>Plan</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              {editable ? (
                <button
                  className="as-link order-empty-items"
                  onClick={onClickEditPlan}
                  type="button"
                >
                  Add plan
                </button>
              ) : (
                <span className="order-empty-items" />
              )}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
