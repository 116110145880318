import React from 'react';
import Help from 'components/tooltip/help';
import {
  get,
  set,
  unset,
  pick,
  filter,
  reduce,
  isObject,
  cloneDeep,
  omit,
  uniq,
  find,
  merge,
} from 'lodash';
import {
  LOCALE_CODE,
  CURRENCY_CODE,
  evalConditions,
  isValueEqual,
  wordify,
} from '../index';

import {
  populateLookupValues as sharedPopulateLookupValues,
  depopulateLookupValues as sharedDepopulateLookupValues,
  getPopulateLookupQueries,
  depopulateLookupValuesDeprecated,
  forEachMenuItem,
  contentLookupKey,
} from 'shared/utils';

import { isEmpty, inflect, isNumber } from 'utils';
import { expandString } from 'utils/string';
import api from 'services/api';
import AppIndicator from 'components/view/app-indicator';

import { isFieldGroup, getParentModelField } from './collection';

import {
  COLLECTION_MODELS,
  VIEW_COLLECTIONS,
  SOURCE_TYPES,
  FIELD_TYPES,
  FIELD_ALIASES,
  FIELD_PROPS,
  VIEW_PROPS,
} from 'constants/content';

export { getPopulateLookupQueries, contentLookupKey };

const IMAGE_MAX_WIDTH = 316;
const IMAGE_MAX_HEIGHT = 400;
const IMAGE_MIN_HEIGHT = 200;
const MINIMAL_MAX_SIZE = 200;
const TINY_MAX_SIZE = 80;

export * from './collection';

export * from 'constants/content';

export function contentFieldAliasType(alias) {
  if (FIELD_TYPES[alias]) {
    return alias;
  }
  if (FIELD_ALIASES[alias]) {
    return FIELD_ALIASES[alias].type;
  }
  return null;
}

export function contentFieldAliasLookup(alias) {
  return FIELD_ALIASES[alias] || {};
}

export function contentFieldProps(field) {
  return { ...field, ...(FIELD_ALIASES[field.type] || {}) };
}

export function contentFieldTypeLabel(type) {
  return FIELD_TYPES[type] ? FIELD_TYPES[type].label : wordify(type);
}

export function contentFieldTypeProps(type) {
  return {
    ...(FIELD_TYPES[type] || {}),
    ...(FIELD_ALIASES[type] || {}),
  };
}

export function contentFieldDataType(type, field) {
  const fieldType = FIELD_TYPES[type];
  if (fieldType?.dataType) {
    if (typeof fieldType.dataType === 'function') {
      return fieldType.dataType(field);
    }
    return fieldType.dataType;
  }
}

export function findContentTitleFields(fields) {
  const titleFields = [];
  for (const field of fields || []) {
    const type = contentFieldAliasType(field.type);
    switch (type) {
      case 'short_text':
      case 'long_text':
      case 'select':
      case 'number':
      case 'date':
      case 'icon':
      case 'lookup':
      case 'boolean':
      case 'asset':
      case 'tags':
      case 'color':
        titleFields.push(field);
        if (titleFields.length === 3) {
          return titleFields;
        }
        break;
      default:
        break;
    }
  }
  return titleFields;
}

function findContentTooltipFields(fields) {
  const tipFields = [];
  for (const field of fields || []) {
    const type = contentFieldAliasType(field.type);
    switch (type) {
      case 'short_text':
      case 'long_text':
      case 'select':
      case 'number':
      case 'date':
      case 'lookup':
      case 'boolean':
      case 'collection':
        tipFields.push(field);
        break;
      default:
        break;
    }
  }
  return tipFields;
}

/**
 * @param {object[]} contentModels
 * @param {string} collection
 * @param {string} [zone]
 * @returns {object[]}
 */
function contentFieldsInZone(contentModels, collection, zone) {
  const fields = [];
  for (const model of contentModels || []) {
    if (model.collection !== collection) {
      continue;
    }
    for (const field of model.fields || []) {
      if (zone && field.admin_zone !== zone) {
        continue;
      }
      if (field.admin_zone && field.admin_enabled !== false) {
        fields.push(field);
      }
    }
  }
  return fields;
}

/**
 * @param {object} values
 * @param {object[]} contentModels
 * @param {string} collection
 * @param {string} [zone]
 * @param {string} [root]
 * @returns {string[]}
 */
export function contentFieldPreviews(
  values,
  contentModels,
  collection,
  zone,
  root,
) {
  const fields = contentFieldsInZone(contentModels, collection, zone);
  const tipFields = findContentTooltipFields(fields);

  const parts = [];

  for (const field of tipFields) {
    const value = contentFieldValue(field, values, field.model, root);

    if (!isEmpty(value)) {
      let stringValue = String(value);

      if (Array.isArray(value)) {
        stringValue = inflect(value.length, String(field.label).toLowerCase());
        parts.push(stringValue);
      } else {
        if (field.type === 'lookup') {
          stringValue = contentLookupRecordName(
            field.collection || field.model,
            value,
          );
        }

        parts.push(`${contentFieldLabel(field)}: ${stringValue}`);
      }

      if (parts.length > 3) {
        break;
      }
    }
  }

  return parts;
}

/**
 * @param {object} values
 * @param {object[]} contentModels
 * @param {string} collection
 * @param {string} [zone]
 * @param {string} [root]
 * @returns {JSX.Element | null}
 */
export function renderContentTooltip(
  values,
  contentModels,
  collection,
  zone,
  root,
) {
  const parts = contentFieldPreviews(
    values,
    contentModels,
    collection,
    zone,
    root,
  );

  if (parts.length > 0) {
    const message = parts
      .map((str) => (str.length > 30 ? `${str.substring(0, 30)}...` : str))
      .join(', ');
    return <Help message={message} />;
  }

  return null;
}

function contentModelKind(contentModel, collections = {}) {
  const model = collections[contentModel.collection];
  return model && model.content_id === contentModel.id
    ? 'custom_collection'
    : contentModel.collection
    ? 'custom_fields'
    : 'abstract';
}

/**
 * @param {object} model
 * @param {object} [app]
 * @returns {string}
 */
export function contentCollectionSourceLabel(model, app) {
  if (model.app_id) {
    if (app) {
      return <AppIndicator app={app} />;
    }
    return `App ${model.app_id}`;
  }
  if (model.content_id) {
    const type = model.content_id.split('.').shift();
    return get(SOURCE_TYPES[type], 'label', 'Custom');
  } else if (
    model.client_id &&
    (!model.extends ||
      model.extends === 'com.base' ||
      !model.extends.startsWith('com.'))
  ) {
    return 'Custom';
  }
  return 'Standard';
}

/**
 * @param {object} field
 * @param {object} [installedApps]
 * @returns {string | JSX.Element}
 */
export function contentFieldSourceLabel(field, installedApps = {}) {
  if (field.content_id) {
    const type = field.content_id.split('.').shift();
    if (type === 'app') {
      const appId = field.content_id.split('.')[1];
      if (appId) {
        const app = find(installedApps?.results, { app_id: appId });
        if (app?.app) {
          return <AppIndicator app={app.app} icon={false} />;
        }
        return `App ${appId}`;
      }
    }
    return get(SOURCE_TYPES[type], 'label', 'Custom');
  }
  return 'Standard';
}

/**
 * @param {object} contentModel
 * @returns {number}
 */
export function countContentFields(contentModel) {
  if (contentModel.item_types) {
    return contentModel.item_types.reduce(
      (count, it) => count + countContentFields(it),
      0,
    );
  }

  if (Array.isArray(contentModel.fields)) {
    // Content type fields
    return contentModel.fields.reduce((count, field) => {
      if (!field) {
        return count;
      }

      switch (field.type) {
        case 'collection':
        case 'field_group':
        case 'field_row':
          return count + 1 + countContentFields(field);

        default:
          return count + 1;
      }
    }, 0);
  }

  return 0;
}

export function cleanModelField(field) {
  const obj = pick(field, FIELD_PROPS);

  // deprecated use of `model` as a string only
  if (typeof field.model === 'string') {
    obj.model = 'model';
  }

  if (field.fields) {
    obj.fields = cleanModelFields(field.fields);
  }

  if (field.item_types) {
    obj.item_types = reduce(
      field.item_types,
      (acc, it) => {
        const item = { ...it };

        if (it.fields) {
          item.fields = cleanModelFields(it.fields);
        }

        acc.push(item);

        return acc;
      },
      [],
    );
  }

  for (const [key, value] of Object.entries(obj)) {
    if (value === null || value === '' || value === undefined) {
      delete obj[key];
    }
  }

  return obj;
}

export function cleanModelFields(fields) {
  return (fields || []).map((field) => cleanModelField(field));
}

function cleanModelViews(views) {
  const cleanViews = (views || []).map((view) => ({
    ...pick(view, VIEW_PROPS),
  }));

  return cleanViews;
}

/**
 * @deprecated
 * @param {Array<object>} contentModels
 * @param {object} values
 * @returns {Array<object>}
 */
export function contentTypesConditionalDeprecated(contentModels, values) {
  return filter(
    contentModels,
    (type) => !type.conditions || evalConditions(type.conditions, values),
  );
}

/**
 * @param {boolean | string} [root]
 * @param {object} [contentModel]
 * @returns {boolean | string}
 */
function contentRoot(root, contentModel) {
  return root !== undefined
    ? root
    : // Child collections use a root path by default
    contentModel?.childCollection
    ? true
    : contentModel?.root;
}

/**
 * @param {boolean | string} [root]
 */
function contentRootPath(root) {
  if (root === true || root === undefined) {
    return '';
  } else if (typeof root === 'string') {
    return root;
  }

  // TODO: probably shouldn't fall back to content root
  // Need more tests before fixing though
  return 'content';
}

export function contentModelRoot(contentModel, collectionModel) {
  const modelRoot = contentRoot(undefined, contentModel);

  // App-defined fields on standard models have a root of $app.id
  if (contentModel.app_id && collectionModel && !collectionModel.app_id) {
    const appPath = `$app.${contentModel.app?.slug_id || contentModel.app_id}`;
    const rootPath = contentRootPath(modelRoot);
    if (appPath && rootPath) {
      return `${appPath}.${rootPath}`;
    }
    return appPath;
  }

  return modelRoot;
}

/**
 * Used at the root of any field
 *
 * @param {object} field
 * @param {object} [contentModel]
 * @returns {string}
 */
export function contentFieldRootPath(field, contentModel) {
  const fieldRoot = contentRoot(field.root, contentModel);
  const rootPath = contentRootPath(fieldRoot);

  if (rootPath.startsWith('$')) {
    // Root may directly target a standard or app's field
    if (rootPath.startsWith('$app')) {
      return rootPath;
    }
    return rootPath.slice(1);
  }

  return rootPath;
}

/**
 * @param {object} field
 * @param {object} [contentModel]
 * @param {string} [root]
 * @returns {string}
 */
export function contentFieldPath(field, contentModel, root) {
  if (field.id?.startsWith('$')) {
    // Field ID may directly target a standard or app's field
    if (field.id.startsWith('$app')) {
      return field.id;
    }
    return field.id.slice(1);
  }

  let fieldId = field.id?.replace?.(/\$/g, '') || '';
  const rootPath = contentFieldRootPath(field, contentModel);

  if (rootPath) {
    const path = root && root === rootPath ? '' : rootPath;
    const fieldPath = path ? `${path}.${fieldId}` : fieldId;
    return fieldPath;
  }

  const typeProps = contentFieldTypeProps(field.type);
  return !typeProps.wrapper ? fieldId : '';
}

/**
 * @param {object} field
 * @param {object} values
 * @param {object} [contentModel]
 * @param {string} [root]
 */
export function contentFieldValue(field, values, contentModel, root) {
  return get(values, contentFieldPath(field, contentModel, root));
}

export function contentFieldLabel(field) {
  return field.label || field.name || wordify(field.id);
}

/**
 * @param {object} params
 * @param {object} params.field
 * @param {(parentId: string) => object} [params.getParent]
 * @param {(parent: object) => string} [params.getParentPath]
 * @param {object[]} [params.allFields]
 * @param {object} params.valueProps mutates
 * @returns {boolean | undefined}
 */
export function contentFieldVisible({
  field,
  getParent,
  getParentPath,
  allFields,
  valueProps,
}) {
  let visible;
  let parentId;

  // TODO: implement visibility based on conditions
  if (field.collection_parent_id && field.collection_parent_field) {
    const parent = getParent
      ? getParent(field.collection_parent_id)
      : allFields.find((f) => f.id === field.collection_parent_id);

    if (parent) {
      const parentFieldPath = getParentPath ? getParentPath(parent) : parent.id;
      const parentValue = get(valueProps.rootValue, parentFieldPath);

      visible =
        get(parentValue, `${field.collection_parent_field}.results`, [])
          .length > 0;

      parentId = get(parentValue, 'id');

      if (!parentValue && !valueProps.usingDefault) {
        valueProps.defaultValue = '';
      } else if (field.parentId && field.parentId !== parentId) {
        valueProps.defaultValue = '';
      }

      valueProps.parentId = field.parentId = parentId;
    } else {
      visible = false;
    }
  }

  return visible;
}

/**
 * @param {object} model
 * @returns {string}
 */
export function contentCollectionLabel(model) {
  const contentLabel = model.content_id && model.label;
  return contentLabel || model.plural || model.label || wordify(model.name);
}

/**
 * @param {object[]} models
 * @param {string} collection
 * @param {string} [zone]
 * @returns {boolean}
 */
export function hasLocalizedContent(models, collection, zone) {
  for (const model of models || []) {
    if (model.collection === collection) {
      for (const field of model.fields || []) {
        if (
          (!zone || field.admin_zone === zone) &&
          field.admin_zone &&
          field.localized
        ) {
          return true;
        }
      }
    }
  }

  return false;
}

/**
 * @param {object[]} models
 * @param {(field: object) => boolean} [fieldFilter]
 * @returns {object[]}
 */
export function contentFieldsAllByModel(models, fieldFilter) {
  return models.reduce((acc, model) => {
    const fields = model.fields || [];

    const filtered = fieldFilter
      ? fields.filter((field) => fieldFilter(field))
      : fields;

    const items = filtered.map((field) => ({
      field,
      model: field.contentModel || model,
      path: contentFieldRootPath(field, field.contentModel || model),
    }));

    acc.push(...items);

    return acc;
  }, []);
}

/**
 * @param {object} view
 * @param {(field: object) => boolean} [fieldFilter]
 * @returns {Array<object> | undefined}
 */
export function contentFieldsAllByView(view, fieldFilter) {
  if (!view?.fields) {
    return undefined;
  }

  const filtered = fieldFilter
    ? view.fields.filter((field) => fieldFilter(field))
    : view.fields;

  return filtered.map((field) => ({
    field,
    model: field.contentModel || view.contentModel,
    path: contentFieldRootPath(field, field.contentModel || view.contentModel),
  }));
}

export function contentFieldsAllGrouped(allFields) {
  const fieldsWithoutGroup = contentFieldsWithoutGroup(allFields);
  const groupFields = contentFieldsByGroup(allFields);
  const groups = Object.keys(groupFields);
  const rowFields = contentFieldsInRows(allFields);
  return {
    groups,
    groupFields,
    fieldsWithoutGroup,
    allFields: [
      ...fieldsWithoutGroup,
      ...rowFields,
      ...reduce(
        groupFields,
        (acc, fields) => {
          acc.push(...fields);
          return acc;
        },
        [],
      ),
    ],
  };
}

function contentFieldsByGroup(allFields) {
  return allFields
    .filter(({ field }) => field.type === 'field_group')
    .reduce((acc, { model, field: { label, fields } }) => {
      let list = acc[label];

      if (list === undefined) {
        list = [];
        acc[label] = list;
      }

      const arr = (fields || []).map((field) => ({
        field,
        model,
        path: contentFieldPath(field, model),
      }));

      if (arr.length > 0) {
        list.push(...arr);
      }

      return acc;
    }, {});
}

function contentFieldsWithoutGroup(allFields) {
  return allFields.filter(({ field }) => field.type !== 'field_group');
}

function contentFieldsInRows(allFields) {
  return allFields
    .filter(({ field }) => field.type === 'field_row')
    .reduce((acc, { model, field, path }) => {
      const list = (field.fields || []).map((field) => ({
        field,
        model,
        path,
      }));

      if (list.length > 0) {
        acc.push(...list);
      }

      return acc;
    }, []);
}

export function hasLocalizedFields(fields) {
  for (const field of fields || []) {
    if (field.localized) {
      return true;
    }

    if (field.fields?.length > 0) {
      if (hasLocalizedFields(field.fields)) {
        return true;
      }
    }
  }

  return false;
}

const populateLookupGetter = (q) => api.post('/data/:batch', q);

export function populateLookupValues(values, fields, handlers = {}) {
  return sharedPopulateLookupValues(
    values,
    fields,
    populateLookupGetter,
    handlers,
  );
}

export function depopulateLookupValues(values, fields, defaultKeyField) {
  return sharedDepopulateLookupValues(values, fields, defaultKeyField);
}

export function contentValuesWithLocationData(location) {
  if (isObject(location?.query?.record)) {
    return { ...location.query.record };
  }
  return {};
}

/**
 * Iterate through all the fields and call a handler for each field value
 *
 * @param {object} field
 * @param {object} values
 * @param {object} contentModel
 * @param {string} [nestedPath='']
 * @param {(values: object, path: string) => void} handler
 * @returns {void}
 */
function eachContentFieldValueByPath(
  field,
  values,
  contentModel,
  nestedPath = '',
  handler,
) {
  const path = nestedPath
    ? field.fields
      ? field.id
      : ''
    : contentFieldPath(field, contentModel);

  const actualPath =
    path && nestedPath ? `${nestedPath}.${path}` : path || nestedPath;

  if (path) {
    const parts = path.split('.');
    let actualValues = values;

    while (parts.length > 0) {
      const part = parts.shift();
      if (parts.length > 0 || field.fields) {
        actualValues = get(actualValues, part);
      }

      if (actualValues && Array.isArray(actualValues.results)) {
        actualValues = actualValues.results;
      }

      if (Array.isArray(actualValues)) {
        if (parts.length > 0) {
          const nextField = { ...field, root: parts.join('.') };
          for (let i = 0; i < actualValues.length; i++) {
            const thisPath = actualPath ? `${actualPath}.${i}` : String(i);
            eachContentFieldValueByPath(
              nextField,
              actualValues[i],
              contentModel,
              thisPath,
              handler,
            );
          }
        } else {
          for (let i = 0; i < actualValues.length; i++) {
            const thisPath = actualPath ? `${actualPath}.${i}` : String(i);
            handler(actualValues[i], thisPath);
          }
        }
        return;
      }
    }
    if (!isEmpty(actualValues)) {
      handler(actualValues, actualPath);
    }
  } else {
    if (!isEmpty(values)) {
      handler(values, nestedPath);
    }
  }
}

/**
 * @param {object} values
 * @param {object[]} contentModels
 * @param {boolean} [nested=false]
 * @param {string} [basePath='']
 * @returns {Promise<void[]>}
 */
export function populateContentLookupValues(
  values,
  contentModels,
  nested = false,
  basePath = '',
) {
  const promises = [];
  const fieldsByPath = new Map();

  for (const model of contentModels || []) {
    const fields = [...(model.fields || [])];

    if (model.childCollection) {
      fields.push(getParentModelField(model));
    }

    for (const field of fields) {
      eachContentFieldValueByPath(
        field,
        values,
        model,
        nested ? basePath : '',
        (vals, path) => {
          const thisPath = path;

          if (field.fields) {
            promises.push(
              populateContentLookupValues(
                vals,
                [{ ...model, fields: field.fields }],
                nested || !isFieldGroup(field),
                path,
              ),
            );
          } else {
            let obj = fieldsByPath.get(thisPath);

            if (obj === undefined) {
              obj = { vals, fields: [] };
              fieldsByPath.set(thisPath, obj);
            }

            obj.fields.push(field);
          }
        },
      );
    }
  }

  for (const { vals, fields } of fieldsByPath.values()) {
    promises.push(populateLookupValues(vals, fields, true));
  }

  return Promise.all(promises);
}

/**
 * @param {object} values
 * @param {object[]} contentModels
 * @param {boolean} [nested=false]
 * @param {string} [basePath='']
 * @returns {object}
 */
export function depopulateContentLookupValues(
  values,
  contentModels,
  nested = false,
  basePath = '',
) {
  const contentValues = nested ? values : cloneDeep(values);

  for (const model of contentModels || []) {
    const fields = [...(model.fields || [])];

    if (model.childCollection) {
      fields.push(getParentModelField(model));
    }

    for (const field of fields) {
      eachContentFieldValueByPath(
        field,
        contentValues,
        model,
        nested ? basePath : '',
        (vals, path) => {
          if (field.fields) {
            const updated = depopulateContentLookupValues(
              vals,
              [{ ...model, fields: field.fields }],
              nested || !isFieldGroup(field),
              path,
            );

            if (updated !== vals) {
              Object.assign(vals, updated);
            }
          } else {
            const updated = depopulateLookupValues(vals, [field]);

            if (updated !== vals) {
              Object.assign(vals, updated);
            }
          }
        },
      );
    }
  }

  return contentValues;
}

export function assignContentValuesForUpdate(
  values = {},
  record = {},
  contentModels = [],
) {
  // First depopulate lookups
  const recordValues = depopulateContentLookupValues(record, contentModels);
  const contentValues = depopulateContentLookupValues(values, contentModels);

  if (contentValues.$set) {
    contentValues.$set = depopulateContentLookupValues(
      contentValues.$set,
      contentModels,
    );
  }

  const setValues = contentValues.$set || {};

  const unsetValues = Array.isArray(contentValues.$unset)
    ? contentValues.$unset
    : contentValues.$unset
    ? [contentValues.$unset]
    : [];

  // Assign all the root values to $set object
  for (const model of contentModels || []) {
    for (const field of model.fields || []) {
      setContentValuesByPath({
        setValues,
        unsetValues,
        values: cloneDeep(contentValues || {}),
        record: recordValues,
        field,
        model,
        forUpdate: true,
      });
    }
  }

  const result = {
    ...omit(contentValues, Object.keys(setValues)),
    ...(!isEmpty(setValues) && { $set: setValues }),
    ...(!isEmpty(unsetValues) && { $unset: unsetValues }),
  };

  return result;
}

/**
 * @param {object} params
 * @param {object} params.setValues
 * @param {object[]} params.unsetValues
 * @param {object} params.values
 * @param {object} params.record
 * @param {object} params.field
 * @param {object} [params.model]
 * @param {string} [params.path]
 * @param {string} [params.root]
 * @param {boolean} [params.forUpdate=false]
 */
export function setContentValuesByPath({
  setValues,
  unsetValues,
  values,
  record,
  field,
  model,
  path,
  root,
  forUpdate = false,
}) {
  let fieldPath = path;
  if (!fieldPath) {
    if (forUpdate && field.type === 'lookup') {
      fieldPath = contentFieldPath(
        { ...field, id: contentLookupKey(field) },
        model,
        root,
      );
    } else {
      fieldPath = contentFieldPath(field, model, root);
    }
  }

  const pathParts = fieldPath.split('.');
  const setRoot = pathParts[0];

  let val = values;
  let rec = record;
  let setVal = setValues;

  if (values.$set && values.$set[setRoot]) {
    val = values.$set;
  }

  let thisVal = val;
  let thisRec = rec;
  let firstPart = !path;

  const recFromVal = (val, rec) =>
    val
      .map((v) => rec.results.find((r) => r.id === (v && v.id)))
      .filter(Boolean);

  while (pathParts.length > 0) {
    const key = pathParts.shift();

    val = val && val[key];
    rec = rec && rec[key];

    // Exit at first part when val undefined
    // Otherwise continue because we may need to
    if (firstPart && val === undefined) {
      break;
    }

    // Set content in nested record collections as an array first
    if (rec && rec.results && rec.page) {
      if (Array.isArray(val) && Array.isArray(rec.results)) {
        // TODO: make sure this mapping works in all cases
        rec = recFromVal(val, rec);
      } else {
        // Avoid setting in this case
        break;
      }
    }

    if (pathParts.length === 0) {
      if (val === undefined) {
        break;
      }

      setVal[key] = val;

      // Set localized values if not already set
      if (thisVal && !setVal.$locale && thisVal.$locale) {
        setVal.$locale = {
          ...(thisRec && thisRec.$locale),
          ...thisVal.$locale,
        };
        for (const code of Object.keys(thisVal.$locale)) {
          setVal.$locale[code] = {
            ...get(thisRec, `$locale.${code}`, {}),
            ...get(thisVal, `$locale.${code}`, {}),
          };
        }
      }
      if (thisVal && !setVal.$currency && thisVal.$currency) {
        setVal.$currency = {
          ...(thisRec && thisRec.$currency),
          ...thisVal.$currency,
        };
        for (const code of Object.keys(thisVal.$currency)) {
          setVal.$currency[code] = {
            ...get(thisRec, `$currency.${code}`, {}),
            ...get(thisVal, `$currency.${code}`, {}),
          };
        }
      }
      // If fallback, unset values that equal default
      let isFallback;
      if (field.fallback !== false && field.default !== undefined) {
        if (isValueEqual(val, field.default)) {
          isFallback = true;
          // Remove and $unset
          unset(setVal, key);
          unset(thisVal, key);
          if (!path && setRoot === fieldPath) {
            unsetValues.push(fieldPath);
          }
        }
        if (setVal.$locale) {
          for (const code of Object.keys(setVal.$locale)) {
            if (get(field.$locale, `${code}.default`) !== undefined) {
              const localeValue = setVal.$locale[code][key];
              if (isValueEqual(localeValue, field.$locale[code].default)) {
                // Remove and $unset
                unset(setVal.$locale[code], key);
                if (thisVal && thisVal.$locale) {
                  unset(thisVal.$locale[code], key);
                }
                if (setRoot === fieldPath) {
                  unsetValues.push(fieldPath.replace(key, `$locale.${key}`));
                }
              }
            } else {
              if (isFallback && code === LOCALE_CODE) {
                unset(setVal.$locale[code], key);
                if (thisVal && thisVal.$locale) {
                  unset(thisVal.$locale[code], key);
                }
                if (setRoot === fieldPath) {
                  unsetValues.push(fieldPath.replace(key, `$locale.${key}`));
                }
              }
            }
          }
        }
        if (setVal.$currency) {
          for (const code of Object.keys(setVal.$currency)) {
            if (get(field.$currency, `${code}.default`) !== undefined) {
              const localeValue = setVal.$currency[code][key];
              if (isValueEqual(localeValue, field.$currency[code].default)) {
                // Remove and $unset
                unset(setVal.$currency[code], key);
                if (thisVal && thisVal.$currency) {
                  unset(thisVal.$currency[code], key);
                }
                if (setRoot === fieldPath) {
                  unsetValues.push(fieldPath.replace(key, `$currency.${key}`));
                }
              }
            } else {
              if (isFallback && code === CURRENCY_CODE) {
                unset(setVal.$currency[code], key);
                if (thisVal && thisVal.$currency) {
                  unset(thisVal.$currency[code], key);
                }
                if (setRoot === fieldPath) {
                  unsetValues.push(fieldPath.replace(key, `$currency.${key}`));
                }
              }
            }
          }
        }
      }
    } else if (val instanceof Array) {
      const nextPath = pathParts.join('.');
      for (let i = 0; i < val.length; i++) {
        const arrVal = val[i];
        if (arrVal && isObject(arrVal)) {
          const recVal =
            rec &&
            arrVal.id &&
            (Array.isArray(rec)
              ? rec.find((r) => r.id === arrVal.id) || rec[i]
              : rec);

          if (arrVal.$set) {
            arrVal.$unset = [];
          }
          setVal[key] = setVal[key] || [];
          setVal[key][i] = setVal[key][i] || {
            ...(recVal || {}),
            ...arrVal,
          };
          setContentValuesByPath({
            setValues: arrVal.$set || setVal[key][i],
            unsetValues: arrVal.$unset || [],
            values: arrVal,
            record: recVal,
            field,
            model,
            path: arrVal.$unset ? undefined : nextPath,
            root: field.root,
            forUpdate,
          });
          if (isEmpty(setVal[key][i].$unset)) {
            delete setVal[key][i].$unset;
          }
        }
      }
      break;
    } else {
      setVal[key] = setVal[key] || {
        ...merge(rec, val),
        $locale: undefined,
        $currency: undefined,
      };
      const val$locale = get(val, '$locale');
      const rec$locale = get(rec, '$locale');
      if ((val$locale || rec$locale) && !setVal[key].$locale) {
        setVal[key].$locale = {
          ...rec$locale,
          ...val$locale,
        };
        for (const code of Object.keys(val$locale || rec$locale)) {
          setVal[key].$locale[code] = {
            ...get(rec$locale, code, {}),
            ...get(val$locale, code, {}),
          };
        }
      }
      const val$currency = get(val, '$currency');
      const rec$currency = get(rec, '$currency');
      if ((val$currency || rec$currency) && !setVal[key].$currency) {
        setVal[key].$currency = {
          ...rec$currency,
          ...val$currency,
        };
        for (const code of Object.keys(val$currency || rec$currency)) {
          setVal[key].$currency[code] = {
            ...get(rec$currency, code, {}),
            ...get(val$currency, code, {}),
          };
        }
      }

      setVal = setVal[key];
      thisVal = thisVal && thisVal[key];
      thisRec = thisRec && thisRec[key];
      firstPart = false;
    }
  }

  // If values also have the same root key, overwrite it at the end
  if (!path && setRoot && values[setRoot] && setValues[setRoot]) {
    values[setRoot] = setValues[setRoot];
  }
}

/**
 * @param {object[]} [fields=[]]
 * @param {Record<string, object>} [itemTypes={}]
 * @returns {Promise<void>}
 */
export async function populateItemTypeFields(fields = [], itemTypes = {}) {
  for (const field of fields) {
    if (field.item_types && field.item_types.length > 0) {
      let allItemTypes = itemTypes;
      const itemTypeIds = field.item_types.filter(
        (it) => typeof it === 'string' && !itemTypes[it],
      );
      if (itemTypeIds.length > 0) {
        const its = await api.get('/data/:content', {
          where: {
            $or: [{ id: { $in: itemTypeIds } }, { slug: { $in: itemTypeIds } }],
          },
          fields: 'slug, fields, name',
        });
        allItemTypes = {
          ...its.results.reduce((acc, it) => {
            acc[it.slug || it.id] = it;
            return acc;
          }, {}),
          ...itemTypes,
        };
        for (let i = 0; i < field.item_types.length; i++) {
          const it = field.item_types[i];
          if (typeof it === 'string') {
            field.item_types[i] = {
              fields: [],
              ...(allItemTypes[it] || undefined),
              id: it,
            };
          } else if (!it || !it.id) {
            field.item_types.splice(i, 1);
            i--;
          }
          await populateItemTypeFields(
            field.item_types[i].fields,
            allItemTypes,
          );
        }
      }
      if (get(field.fields, '[0].id') !== 'type') {
        field.fields = [
          {
            id: 'type',
            label: 'Type',
            type: 'select',
            placeholder: 'Choose one',
            options: field.item_types.map((it) => ({
              value: it.id,
              label: it.name || it.id,
            })),
          },
          ...(field.fields || []),
          // TODO: make sure this isn't useful and remove it
          // ...field.item_types.reduce(
          //   (acc, it) => [
          //     ...acc,
          //     ...it.fields.map((field) => ({ ...(field || {}), item_type: it.id })),
          //   ],
          //   [],
          // ),
        ];
      }
    }
  }
}

/**
 * @param {object[]} menus
 * @returns {Promise<any>}
 */
export function populateMenuLookupValues(menus) {
  const allItems = [];

  function handleMenuItem(item, id) {
    allItems.push({
      ...item,
      id: `${id}.value`,
      // Hack: populate from value_id due to a prior bug that depopulated to this key instead of value
      key: item.value_id ? `${id}.value_id` : `${id}.value`,
    });
  }

  const list = menus || [];

  for (let i = 0; i < list.length; ++i) {
    forEachMenuItem(list[i].items, handleMenuItem, i);
  }

  return populateLookupValues(menus, allItems);
}

export function depopulateMenuLookupValues(menus) {
  const allItems = [];

  function handleMenuItem(item, id) {
    allItems.push({
      ...item,
      id: `${id}.value`,
      key: `${id}.value`,
    });
  }

  const list = menus || [];

  for (let i = 0; i < list.length; ++i) {
    forEachMenuItem(list[i].items, handleMenuItem, i);
  }

  return depopulateLookupValues(menus, allItems, 'slug');
}

/**
 * @deprecated
 */
function depopulateMenuLookupValuesDeprecated(menus) {
  const allItems = [];
  function handleMenuItem(item, id) {
    allItems.push({
      id: `${id}.value`,
      ...item,
    });
  }
  const menusArr = menus || [];
  for (let i = 0; i < menusArr.length; i++) {
    forEachMenuItem(menusArr[i].items, handleMenuItem, i);
  }
  return depopulateLookupValuesDeprecated(menus, allItems);
}

export function contentExpandsDeprecated(contentFieldsDeprecated = []) {
  return reduce(
    contentFieldsDeprecated,
    (acc, type) => {
      for (const field of type.fields) {
        const path = type.target_field
          ? `content.${type.target_field}`
          : 'content';

        if (field.fields) {
          for (const child of field.fields) {
            const childPath = `${path}.${field.id}`;

            if (child.type.includes('lookup')) {
              acc.push(`${childPath}.${contentExpandKey(child)}`);
            }
          }
        } else if (field.type.includes('lookup')) {
          acc.push(`${path}.${contentExpandKey(field)}`);
        }
      }

      return acc;
    },
    [],
  );
}

export function contentUpdatesDeprecated(
  contentFieldsDeprecated,
  contentValue,
) {
  if (isEmpty(contentFieldsDeprecated)) {
    return contentValue;
  }

  return reduce(
    contentFieldsDeprecated,
    (acc, type) => {
      for (const field of type.fields) {
        const path = type.target_field ? type.target_field : '';

        if (field.fields) {
          for (const child of field.fields) {
            const childPath = `${path}.${field.id}`;
            contentUpdateLookupValue(acc, child, childPath);
          }
        } else {
          contentUpdateLookupValue(acc, field, path);
        }
      }

      return acc;
    },
    cloneDeep(contentValue || {}),
  );
}

function contentExpandKey(field) {
  if (field.type === 'product_lookup') {
    return `${field.id}.variants:1`;
  }
  return field.id;
}

function contentUpdateLookupValue(acc, field, path) {
  if (field.type.includes('lookup')) {
    const parentValue = get(acc, path);

    if (Array.isArray(parentValue)) {
      for (let i = 0; i < parentValue.length; i++) {
        contentUpdateLookupValue(acc, field, `${path}[${i}]`);
      }
    } else {
      const lookupValue = get(acc, `${path}.${field.id}`);

      if (lookupValue) {
        const key = contentLookupKey(field);
        set(acc, `${path}.${key}`, lookupValue.id);
        set(acc, `${path}.${field.id}`, undefined);
      }
    }
  }
}

/**
 * Get the name of a content record, from the name field or pattern
 *
 * Fallback to various known patterns
 *
 * @param {string} collection
 * @param {object} value
 * @param {string} [pattern]
 * @returns {string}
 */
export function contentRecordName(collection, value, pattern) {
  let namePattern = pattern;
  if (!pattern) {
    const model = COLLECTION_MODELS[collection];
    if (model) {
      namePattern =
        model.name_pattern ||
        (model.name_field && model.name_field !== 'id'
          ? model.name_field
          : undefined);
    }
  }

  let name;
  if (namePattern) {
    name = expandString(namePattern, value);
    if (!name || namePattern === name) {
      name = get(value, namePattern);
    }
  }

  if (!isEmpty(name)) {
    return name;
  }

  const errr =
    value?.name ||
    value?.label ||
    value?.title ||
    value?.number ||
    value?.id ||
    'Unnamed';
  return errr;
}

/**
 * Get expand query from a model name pattern if applicable
 *
 * @param {object} model
 * @param {string} model.name_pattern
 * @returns {string[]}
 */
export function getNameFieldExpand(model) {
  const expand = [];
  if (model && typeof model.name_pattern === 'string') {
    const nameArgs = model.name_pattern.match(/\{.+?\}/g);
    if (nameArgs) {
      for (const arg of nameArgs) {
        // Trim curly braces {} from pattern
        const field = arg.substring(1, arg.length - 1);
        // Only match up to last object in field path
        if (field.includes('.')) {
          expand.push(field.split('.').slice(0, -1).join('.'));
        }
      }
    }
  }
  return expand;
}

/**
 * Get the record name for lookup of a collection value or values if it's an array
 *
 * @param {string} collection
 * @param {object | object[]} values
 * @param {object} [field]
 * @param {string} [pattern]
 * @returns {string}
 */
export function contentLookupRecordName(collection, values, field, pattern) {
  if (Array.isArray(values)) {
    return values.map((value) =>
      contentRecordName(
        collection,
        field && field.value_type === 'collection'
          ? get(value, field.id)
          : value,
        pattern,
      ),
    );
  }

  return contentRecordName(collection, values, pattern);
}

/**
 * @param {string} collection
 * @param {object} values
 * @returns {string | undefined}
 */
export function contentLookupRecordLink(collection, values) {
  const viewModel = VIEW_COLLECTIONS[collection];

  if (!viewModel) {
    return;
  }

  let url =
    typeof viewModel.url === 'function' ? viewModel.url(values) : viewModel.url;

  url = expandString(url, values);

  return url;
}

export function removeModelNamespace(modelName) {
  if (!modelName) return modelName;
  return modelName.split('/').pop();
}

/**
 * Merge data into a query with substitution
 *
 * Example query: `{ active: true, subscription_id: "{id}" }`
 *
 * Above would replace the `"{id}"` with the `'id'` value of the parent record
 *
 * @param {object} query
 * @param {object} data
 * @returns {object}
 */
export function mergeQueryParams(query, data) {
  if (!query) {
    return query;
  }

  const query2 = cloneDeep(query);

  switch (typeof query2) {
    case 'string':
      return expandString(query2, data || {}, { typecast: true });

    case 'object': {
      if (Array.isArray(query2)) {
        for (let i = 0; i < query2.length; ++i) {
          query2[i] = mergeQueryParams(query2[i], data);
        }
      } else {
        for (let key of Object.keys(query2)) {
          if (key.startsWith('{')) {
            const expandKey = expandString(key, data || {});

            if (expandKey === null) {
              continue;
            }

            query2[expandKey] = query2[key];
            delete query2[key];
            key = expandKey;
          }

          query2[key] = mergeQueryParams(query2[key], data);
        }
      }

      break;
    }

    default:
      break;
  }

  return query2;
}

/**
 * Merge data into a query for links
 *
 * Example params: `{ account_id: "id" }`
 *
 * Above would replace the `"id"` with the `'id'` value of the parent record
 *
 * @param {object} params
 * @param {object} data
 * @returns {object}
 */
export function mergeLinkParams(params, data) {
  if (!params) {
    return params;
  }

  const query = cloneDeep(params);

  switch (typeof query) {
    case 'string':
      return get(data, query);

    case 'object': {
      if (Array.isArray(query)) {
        for (let i = 0; i < query.length; ++i) {
          query[i] = mergeLinkParams(query[i], data);
        }
      } else {
        for (const key of Object.keys(query)) {
          query[key] = mergeLinkParams(query[key], data);
        }
      }

      break;
    }

    default:
      break;
  }

  return query;
}

/**
 * Extracts content_ids from model fields
 *
 * Example:
 *
 * in:
 *
 * ```js
 * questions: {
 *   type: 'array',
 *   content_id: 'theme.horizon.quiz',
 *   value_type: 'object',
 *   object_types: {
 *     quiz_question: {
 *       content_id: 'theme.horizon.quiz_question',
 *     },
 *     quiz_transition: {
 *       content_id: 'theme.horizon.quiz_transition',
 *     },
 *   },
 * }
 * ```
 *
 * out:
 *
 * ```js
 * {
 *   all: [
 *     'theme.horizon.quiz',
 *     'theme.horizon.quiz_question',
 *     'theme.horizon.quiz_transition',
 *   ],
 *   paths: {
 *     questions: 'theme.horizon.quiz',
 *     questions__types: {
 *       quiz_question: 'theme.horizon.quiz_question',
 *       quiz_transition: 'theme.horizon.quiz_transition',
 *     },
 *   },
 * }
 * ```
 *
 * @param {object} fields - The model fields to extract content_ids of.
 * @param {object} options
 * @param {Array<string>} options.include - List of allowed content id sources
 */
export function findContentTypeIds(fields, options) {
  if (!isObject(fields) || Array.isArray(fields)) {
    throw new TypeError('Expecting object "fields"');
  }

  const contentIds = { all: [], paths: {} };
  const path = '';
  const parentContentId = '';

  const { include } = options || { include: null };

  return _findContentTypeIdsBuilder(
    fields,
    contentIds,
    path,
    parentContentId,
    include,
  );
}

/**
 * Helper function for findContentTypeIds to ease recursivity and separate mechanisms
 * required for building contentIds from "real" arguments required when actually used,
 * i.e. model.fields.
 */
function _findContentTypeIdsBuilder(
  fields,
  contentIds,
  path,
  parentContentId,
  sourceContentIds,
) {
  // internal helper function that chooses the value of the content_id
  function _addToContentIds({ field, fieldPath, objectTypePath }) {
    const contentTypeId = field.content_id || parentContentId;

    if (contentTypeId) {
      const isContentIdAllowed = sourceContentIds
        ? sourceContentIds.some((sourceContentId) =>
            field.content_id?.startsWith(sourceContentId),
          )
        : true;

      if (isContentIdAllowed) {
        contentIds.all.push(contentTypeId);

        // fields that have object_types need an extra layer
        if (objectTypePath) {
          contentIds.paths[objectTypePath] ||= {};
          contentIds.paths[objectTypePath][fieldPath] = contentTypeId;
        } else {
          contentIds.paths[fieldPath] = contentTypeId;
        }
      }
    }
  }

  for (const [key, field] of Object.entries(fields)) {
    const fieldPath = path ? `${path}.${key}` : key;

    _addToContentIds({ field, fieldPath });

    // add content ids associated to object types
    const objectTypes = field.object_types;
    for (const objectTypeKey in objectTypes) {
      _addToContentIds({
        field: objectTypes[objectTypeKey],
        fieldPath: objectTypeKey,
        objectTypePath: `${fieldPath}__types`,
      });
    }

    // fields that have a field attribute need to be re-processed
    //
    // this is actually a bit odd: when reprocessing, the content_id is not taken into
    // consideration, but a soft of artifical one is used.
    // when migrating "pages" to `ContentModelTab` or an improved abstraction in
    // `SettingsMenu` we'll need to make this consistent.
    if (field.fields) {
      _findContentTypeIdsBuilder(
        field.fields,
        contentIds,
        fieldPath,
        field.content_id,
        sourceContentIds,
      );
    }
  }

  contentIds.all = uniq(contentIds.all);

  return contentIds;
}

/**
 * Replaces the attribute of a record in an route template.
 *
 * The function attempts to only replace an attribute in the `routeTemplate` if that
 * attribute is defined on the `record`. It tries to avoid situations when
 * `id_secondary` in the template would be replaced by the attribute `id`.
 *
 * Allowed chars for an attribute name: _, a-z (lowercase)
 *
 * Examples:
 *
 * ```js
 * buildThemeUrl('/route/:id', {id: 123}) => '/route/123'
 * buildThemeUrl('/route/:id/nested', {id: 123}) => '/route/123/nested'
 * buildThemeUrl('/route/:id_secondary', {id: 123}) => '/route/:id_secondary'
 * buildThemeUrl('/route/:ID', {id: 123}) => '/route/:ID'
 * buildThemeUrl('/route/:missing', {id: 123}) => '/route/:missing'
 * ```
 *
 * @param      {string} routeTemplate  The route template
 * @param      {object} record         The record
 *
 * @return     {string} the computed theme url.
 */
export function buildThemeUrl(routeTemplate, record) {
  const recordAttr = routeTemplate.match(/:(?<attr>[a-z_]+)\/?/)?.groups?.attr;
  const recordVal = record[recordAttr];

  if (recordAttr && recordVal) {
    return routeTemplate.replace(`:${recordAttr}`, record[recordAttr]);
  }

  return routeTemplate;
}

/**
 * Gets the themeUrl of a model page using {@link buildThemeUrl} function.
 *
 *
 * @param      {string} model  The model identifier
 * @param      {Array.<Object>} pages  The array of pages from editor.json config file
 * @param      {object} record         The record
 *
 * @return     {string} the computed theme url.
 */
export function modelPageThemeUrl(model, pages, record) {
  // themes define links to be displayed in the editor in a file called
  // editor.json within an attribute called "pages" (naming is "overloaded").
  //
  // we try to find the "page" (i.e. editor/theme config page, actually a link)
  // for the fetched record using the content/model defined for the "page" / link
  // vs. the one that the record belongs to in the database.
  const modelPage = pages?.find((page) => page.model === model);

  // furthermore, config pages / links define routes to be shown by the theme using
  // a "route" attribute.
  //
  // we use this attribute and the helper function to compute a URL that only the
  // theme knows how to render, not related to our backend API.
  return record && modelPage?.route && buildThemeUrl(modelPage.route, record);
}

/**
 * Checks if the asset type is an image
 *
 * @param {Array<string>} assetTypes
 * @returns {boolean}
 */
export function isImageAssetType(assetTypes = []) {
  return assetTypes.length === 1 && assetTypes[0].startsWith('image');
}

/**
 * Returns image props based on field configuration
 *
 * @param {object} field
 * @returns {object}
 */
export function getImageProps(field = {}) {
  let maxWidth = IMAGE_MAX_WIDTH;
  let maxHeight = IMAGE_MAX_HEIGHT;
  let minHeight = IMAGE_MIN_HEIGHT;
  let minimal = false;
  let tiny = false;

  if (isImageAssetType(field.asset_types)) {
    if (isNumber(field.max_width)) {
      maxWidth = field.max_width;
    }

    if (isNumber(field.max_height)) {
      maxHeight = field.max_height;
    }
  }

  if (minHeight > maxHeight) {
    minHeight = maxHeight;
  }

  if (maxWidth < TINY_MAX_SIZE || maxHeight < TINY_MAX_SIZE) {
    tiny = true;
  } else if (maxWidth < MINIMAL_MAX_SIZE || maxHeight < MINIMAL_MAX_SIZE) {
    minimal = true;
  }

  return {
    maxWidth,
    maxHeight,
    minHeight,
    placeholder: field.placeholder,
    minimal,
    tiny,
  };
}
