import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

export default function FadeLoading({ duration = 100, children, ...props }) {
  const transitionStyles = {
    defaultStyle: {
      opacity: 0,
      left: -999999,
    },
    enterStyle: {
      opacity: transit(0.5, duration, 'linear'),
    },
    leaveStyle: {
      opacity: transit(0, duration, 'linear'),
    },
    activeStyle: {
      opacity: 0.5,
    },
    active: true,
    transitionAppear: true,
  };
  return (
    <CSSTransition {...transitionStyles} {...props}>
      {children}
    </CSSTransition>
  );
}
