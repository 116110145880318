import React from 'react';
import PropTypes from 'prop-types';

import { productFeaturesByType } from 'utils/product';
import { getStorefrontURL } from 'utils/storefront';

import View from 'components/view';
import Modal from 'components/modal';
import Loading from 'components/loading';
import { Form, Field } from 'components/form';
import DropdownButton from 'components/button/dropdown';
import { FadeIn } from 'components/transitions';
import WithViewPreferences from 'containers/WithViewPreferences';

import ProductVariantEditModal from './variant-edit';
import ProductVariantImagesModal from './variant-images';
import PurchaseLinkModal from './purchase-link-modal';
import ProductForm from './form';

import './product.scss';

export default class ProductEdit extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    refreshModal: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.onTriggerTabChange(() => {
      this.refs.form.reset();
    });
  }

  componentDidUpdate(prevProps) {
    const cloneLoadingChanged =
      this.props.cloneLoading !== prevProps.cloneLoading;
    const cloneProgressMessageChanged =
      this.props.cloneProgressMessage !== prevProps.cloneProgressMessage;
    const cloneProgressStepChanged =
      this.props.cloneProgressStep !== prevProps.cloneProgressStep;

    if (
      cloneLoadingChanged ||
      cloneProgressMessageChanged ||
      cloneProgressStepChanged
    ) {
      this.context.refreshModal();
    }
  }

  getStorefrontLink = () => {
    const { slug } = this.props.values;
    const { primary } = this.props.storefronts;

    return primary ? `${getStorefrontURL(primary)}/products/${slug}/` : '';
  };

  onClickViewInStorefront = (event) => {
    event.preventDefault();

    const link = this.getStorefrontLink();

    window.open(link, '_blank');
  };

  onClickClone = (event) => {
    event.preventDefault();

    this.context.openModal('CloneProduct', this.renderCloneForm);
  };

  renderCloneForm = () => {
    const {
      record,
      //settings,
      loading,
      onSubmitClone,
      cloneLoading,
      cloneProgressMessage,
      cloneProgressStep,
      cloneProgressStepTotal,
    } = this.props;

    const title = cloneLoading ? undefined : `Clone '${record.name}'`;
    const actions = cloneLoading
      ? undefined
      : [{ label: 'Clone', type: 'submit' }];

    return (
      <Form onSubmit={onSubmitClone} autoFocus={true} loading={loading}>
        <Modal
          title={title}
          actions={actions}
          cancel={!cloneLoading}
          closable={!cloneLoading}
          width={700}
        >
          <>
            {cloneLoading && (
              <FadeIn>
                <div className="product-clone-container">
                  <Loading
                    message={cloneProgressMessage}
                    height="26px"
                    width="26px"
                    margin="8px"
                  />

                  <span className="label">{cloneProgressMessage}</span>

                  {cloneProgressStep && cloneProgressStepTotal && (
                    <span className="sub-label">
                      Step {cloneProgressStep} of {cloneProgressStepTotal}
                    </span>
                  )}
                </div>
              </FadeIn>
            )}

            <fieldset
              className={cloneLoading ? 'product-clone-form-hidden' : undefined}
            >
              <Field
                type="text"
                name="name"
                label="New product name"
                defaultValue={`${record.name} (copy)`}
                required={true}
              />

              <div className="row">
                <Field
                  type="text"
                  label="SKU"
                  name="sku"
                  placeholder={record.sku || 'Optional'}
                  className="span2"
                />

                {/* <Field
                  type="select"
                  label="Product type"
                  name="type"
                  defaultValue={record.type || 'standard'}
                  options={settings.products.types.filter(
                    type => ['giftcard', 'subscription', 'download', 'booking'].indexOf(type.id) === -1
                  )}
                  required={true}
                  className="span2"
                /> */}
              </div>

              <Field
                type="toggle"
                name="active"
                label="Active"
                help="An active product is visible to customers"
                defaultChecked={
                  record.active !== undefined ? record.active : true
                }
              />
            </fieldset>
          </>
        </Modal>
      </Form>
    );
  };

  onClickSaveOptions = (event) => {
    event.preventDefault();

    const { combined_options } = this.refs.form.fields;

    if (!combined_options.validate()) {
      return;
    }

    const options = combined_options.value();

    this.props.onSaveOptions(options);
  };

  onAddAttribute = (attr) => {
    this.props.onAddAttribute(attr);

    setTimeout(() => {
      const field = this.refs.form.fields[`attributes[${attr.id}]`];

      if (field && field.refs.input && field.refs.input.type === 'text') {
        field.refs.input.focus();
      }
    }, 200);
  };

  render() {
    const {
      record,
      errors,
      settings,
      onSubmitRecord,
      onChangeForm,
      edited,
      editingVariant,
      editingVariantImages,
      attributeSorted,
      onSubmitPurchaseLink,
      creatingPurchaseLink,
      onClickDelete,
      storefronts,
      ...props
    } = this.props;

    const productImage = null; //record.images && record.images[0] && record.images[0].file && imageUrl(record, { width: 120, height: 120, padded: true });
    const features = productFeaturesByType(record.type);
    const hasStorefront = !!storefronts.primary;

    const tabs = {
      default: {
        label: 'Details',
      },
      attributes: {
        label: 'Attributes',
      },
      ...(features.inventory &&
        settings.products.features.stock_tracking && {
          inventory: {
            label: 'Inventory',
          },
        }),
      ...(record.delivery === 'shipment' && {
        shipping: {
          label: 'Shipping',
        },
      }),
    };

    return (
      <div>
        <Form
          ref="form"
          onSubmit={onSubmitRecord}
          onChange={onChangeForm}
          values={props.values}
        >
          <WithViewPreferences
            {...this.props}
            tabs={tabs}
            model="products"
            id="edit"
            type="record"
          >
            <View
              detail={true}
              uri="/products"
              sectionTitle="Products"
              headerTitle={props.values.name || record.name}
              headerImage={productImage}
              devtools={{
                model: 'products',
                record,
                zone: props.location.query.tab,
              }}
              localized={true}
              withPreferences={true}
              tabs={{
                default: {
                  label: 'Details',
                },
                attributes: {
                  label: 'Attributes',
                },
                ...(features.inventory &&
                  settings.products.features.stock_tracking && {
                    inventory: {
                      label: 'Inventory',
                    },
                  }),
                ...(record.delivery === 'shipment' && {
                  shipping: {
                    label: 'Shipping',
                  },
                }),
              }}
              headerActions={[
                {
                  isActionMenu: true,
                  component: (
                    <DropdownButton
                      anchor="right"
                      alignOffset={14}
                      type="secondary"
                      items={[
                        <button onClick={this.onClickClone} type="button">
                          Clone product
                        </button>,
                        <button
                          onClick={this.props.onClickCreatePurchaseLink}
                          type="button"
                        >
                          Create purchase link
                        </button>,
                        hasStorefront && (
                          <a
                            key="1"
                            href={this.getStorefrontLink()}
                            onClick={this.onClickViewInStorefront}
                          >
                            View in storefront
                          </a>
                        ),
                      ]}
                    >
                      Actions
                    </DropdownButton>
                  ),
                },
                {
                  label: 'Save product',
                  type: edited ? 'default' : 'secondary disabled',
                  submit: true,
                },
              ]}
              extraActions={[
                {
                  label: 'Delete product',
                  onClick: onClickDelete,
                  className: 'danger',
                  delete: true,
                },
              ]}
            >
              <ProductForm
                {...props}
                record={record}
                errors={errors}
                settings={settings}
                onClickSaveOptions={this.onClickSaveOptions}
                onAddAttribute={this.onAddAttribute}
              />
            </View>
          </WithViewPreferences>
        </Form>

        {editingVariant && (
          <ProductVariantEditModal
            record={record}
            errors={errors}
            settings={settings}
            features={features}
            {...props}
          />
        )}

        {editingVariantImages && <ProductVariantImagesModal {...props} />}

        {creatingPurchaseLink && (
          <PurchaseLinkModal
            record={record}
            onSubmit={onSubmitPurchaseLink}
            onClose={this.props.onClickCreatePurchaseLink}
          />
        )}
      </div>
    );
  }
}
