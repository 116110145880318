import React from 'react';
import { connect } from 'react-redux';
import LoadingView from 'components/view/loading';
import Unsubscribed from 'components/pages/unsubscribed/unsubscribed';
import actions from 'actions';

const mapStateToProps = (state) => ({
  flash: state.flash,
});

const mapDispatchToProps = (dispatch) => ({
  unsubscribe: (query) => {
    return dispatch(actions.client.unsubscribeFromEmails(query));
  },
});

export class UnsubscribeFromEmails extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded: false,
    };
  }

  async componentWillMount() {
    const { location, unsubscribe } = this.props;

    await unsubscribe(location.query);
    this.setState({ loaded: true });
  }

  render() {
    const { flash } = this.props;
    const { loaded } = this.state;
    if (!loaded) {
      return <LoadingView />;
    }
    return <Unsubscribed flash={flash} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnsubscribeFromEmails);
