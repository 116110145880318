import React from 'react';
import pt from 'prop-types';

import './status.scss';

export default function Status({
  children,
  blank = false,
  type = 'positive',
  radius = 'rounded',
  dot = true,
  size = 'md',
}) {
  return children ? (
    <span className={`status ${type} ${radius} ${size}`}>
      {dot && <span className="status-dot" />}
      {children}
    </span>
  ) : blank ? null : (
    <span className="muted">&mdash;</span>
  );
}

Status.propTypes = {
  type: pt.string,
  dot: pt.bool,
  blank: pt.bool,
  radius: pt.oneOf(['rounded', 'rounded-4xl', 'rounded-full']),
  children: pt.node,
  size: pt.oneOf(['sm', 'md']),
};
