/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import pt from 'prop-types';

import { hasLocalizedContent } from 'utils/content';
import { getDefaultAddressId } from 'utils/account';
import { EMPTY_ADDRESS, isSameAddress, makeAddressString, shouldUseAccountShippingAddress } from 'utils/order';
import { isEmpty } from 'utils';

import actions from 'actions';

import Modal from 'components/modal';
import Button from 'components/button';
import Link from 'components/link/link';
import Status from 'components/status/status';
import ContentFields from 'components/content/fields';
import OrderAddressForm from 'components/pages/order/address-form';
import { Form, Field } from 'components/form';

import './manage-addresses.scss';

const EMPTY_ARRAY = Object.freeze([]);

const mapStateToProps = (state) => ({
  model: state.data.model,
  record: state.data.record,
  suggestedAddresses: state.lookup.suggestedAddresses,
  addresses: state.data.related?.account_addresses?.results || EMPTY_ARRAY,
});

const mapDispatchToProps = (dispatch) => ({
  updateRecord(model, id, data) {
    return dispatch(actions.data.updateRecord(model, id, data));
  },

  updateAccount(id, data) {
    return dispatch(actions.data.updateRecord('accounts', id, data));
  },

  createAddress(id, data) {
    return dispatch(
      actions.data.createRecord(`accounts/${id}/addresses`, data),
    );
  },

  updateAddress(id, addressId, data) {
    return dispatch(
      actions.data.updateRecord(`accounts/${id}/addresses`, addressId, data),
    );
  },

  deleteAddress(id, addressId) {
    return dispatch(
      actions.data.deleteRecord(`accounts/${id}/addresses`, addressId),
    );
  },
});

class ManageAddresses extends React.PureComponent {
  static propTypes = {
    model: pt.string,
    record: pt.object,
    content: pt.object,
    addresses: pt.array,
    suggestedAddresses: pt.array,

    fetchRecord: pt.func,
    onClickManageAddresses: pt.func,
    selectable: pt.bool,
    deletable: pt.bool,

    updateRecord: pt.func,
    updateAccount: pt.func,
    createAddress: pt.func,
    updateAddress: pt.func,
    deleteAddress: pt.func,
  };

  static contextTypes = {
    openModal: pt.func,
    notifyError: pt.func,
    notifySuccess: pt.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      adding: !this.hasAddresses(props),
      editing: false,
      selectedAddress: this.getDefaultSelectedAddressId(props),
      values: {},
    };
  }

  getDefaultAddressId(props = this.props) {
    const { record } = props;

    return getDefaultAddressId(record.account || record);
  }

  getDefaultSelectedAddressId(props = this.props) {
    const { addresses } = props;
    const useAccount = props.record.type === 'subscription' && shouldUseAccountShippingAddress(props.record.shipping);
    const record = useAccount ? props.record.account : props.record;
    const shippingAddressId = getDefaultAddressId(record);

    if (shippingAddressId) {
      return shippingAddressId;
    }

    const address = addresses.find((address) =>
      isSameAddress(address, record?.shipping),
    );

    return (
      address?.id || this.getDefaultAddressId(props) || addresses[0]?.id || null
    );
  }

  getAddress(addressId) {
    const { addresses } = this.props;

    return addresses.find((address) => address.id === addressId);
  }

  hasAddresses(props = this.props) {
    return props.addresses.length > 0;
  }

  onSelect = (event) => {
    event.preventDefault();

    const { value } = event.currentTarget.dataset;

    this.setState({ selectedAddress: value });
  };

  onClickAdd = (event) => {
    event.preventDefault();

    this.setState({ adding: true, values: {} });
  };

  onClickEdit = (event) => {
    event.preventDefault();

    const { value } = event.currentTarget.dataset;
    const address = this.getAddress(value);

    this.setState({ editing: true, values: { ...address } });
  };

  onClickRemove = (event) => {
    event.preventDefault();

    const { openModal } = this.context;
    const { addresses, onClickManageAddresses } = this.props;
    const { value } = event.currentTarget.dataset;
    const address = this.getAddress(value);

    if (!address) {
      return;
    }

    openModal('ConfirmDelete', {
      title: 'this address',
      action: 'Confirm',
      actionType: 'danger',
      width: 700,
      message: `Are you sure you want to remove the following address: ${makeAddressString(
        address,
      )}`,
      onConfirm: async () => {
        try {
          await this.onRemoveAddress(value);

          const selectedAddress = this.getDefaultAddressId();

          if (addresses.length === 1) {
            onClickManageAddresses(event);
          } else {
            this.setState({
              selectedAddress,
              editing: false,
            });
          }

          return true;
        } catch {
          return false;
        }
      },
    });
  };

  onClickClose = (event) => {
    event.preventDefault();

    const { onClickManageAddresses } = this.props;

    if (!this.hasAddresses()) {
      return onClickManageAddresses(event);
    }

    this.setState({ adding: false, editing: false, values: {} });
  };

  onChangeForm = (values) => {
    this.setState({
      values: {
        ...this.state.values,
        ...values.shipping,
      },
    });
  };

  onAddAddress = async (values) => {
    const { notifySuccess, notifyError } = this.context;
    const {
      model,
      record,
      selectable = true,
      fetchRecord,
      createAddress,
      updateRecord,
      updateAccount,
      onClickManageAddresses,
    } = this.props;
    const { shipping, is_default: isDefault } = values;
    const accountId = record.account_id || record.id;
    const hasAddresses = this.hasAddresses();

    const result = await createAddress(accountId, shipping);

    if (result.errors) {
      return notifyError(result.errors);
    }

    if (isDefault || !hasAddresses) {
      await updateAccount(accountId, {
        shipping: {
          account_address_id: result.id,
        },
      });
    }

    // Select an address when adding the first address
    if (!hasAddresses && selectable) {
      await updateRecord(model, record.id, {
        shipping: {
          account_address_id: result.id,
        },
      });
    }

    await fetchRecord(record.id);

    notifySuccess('Shipping address created');

    if (!hasAddresses) {
      return onClickManageAddresses();
    }

    this.setState({ selectedAddress: result.id, adding: false });
  };

  onEditAddress = async (values) => {
    const { notifySuccess, notifyError } = this.context;
    const { record, fetchRecord, updateAddress, updateAccount } = this.props;
    const { selectedAddress } = this.state;
    const { shipping, is_default: isDefault } = values;
    const accountId = record.account_id || record.id;

    const result = await updateAddress(accountId, selectedAddress, shipping);

    if (result.errors) {
      return notifyError(result.errors);
    }

    if (isDefault) {
      await updateAccount(accountId, {
        shipping: {
          account_address_id: result.id,
        },
      });
    }

    await fetchRecord(record.id);

    notifySuccess('Shipping address updated');

    this.setState({ selectedAddress: result.id, editing: false });
  };

  onRemoveAddress = async (addressId) => {
    const { notifySuccess } = this.context;
    const { record, fetchRecord, updateAccount, deleteAddress } = this.props;
    const result = await deleteAddress(record.id, addressId);

    if (!result) {
      return;
    }

    if (result.id === record.shipping?.account_address_id) {
      await updateAccount(record.id, {
        shipping: {
          ...EMPTY_ADDRESS,
          account_address_id: null,
        },
      });
    }

    await fetchRecord(record.id);

    notifySuccess('Address successfully removed');
  };

  onSave = async (event) => {
    const { notifyError } = this.context;
    const { model, record, fetchRecord, updateRecord, onClickManageAddresses } =
      this.props;
    const { selectedAddress } = this.state;

    const result = await updateRecord(model, record.id, {
      shipping: {
        account_address_id: selectedAddress,
      },
    });

    if (result.errors) {
      return notifyError(result.errors);
    }

    await fetchRecord(record.id);

    onClickManageAddresses(event);
  };

  onMakeDefault = async (event) => {
    const { notifyError } = this.context;
    const { record, fetchRecord, updateAccount, onClickManageAddresses } =
      this.props;
    const { selectedAddress } = this.state;

    const result = await updateAccount(record.id, {
      shipping: {
        account_address_id: selectedAddress,
      },
    });

    if (result.errors) {
      return notifyError(result.errors);
    }

    await fetchRecord(record.id);

    onClickManageAddresses(event);
  };

  renderAddressForm() {
    const { record, content, suggestedAddresses } = this.props;
    const { editing, values } = this.state;
    const accountId = record.account_id || record.id;
    const defaultAddressId = this.getDefaultAddressId();
    const isDefault = values.id === defaultAddressId;

    return (
      <Form
        onSubmit={editing ? this.onEditAddress : this.onAddAddress}
        onChange={this.onChangeForm}
      >
        <Modal
          title={editing ? 'Edit address' : 'New address'}
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'secondary',
              onClick: this.onClickClose,
            },
          ]}
          width={700}
          cancel={false}
          onClose={this.onClickClose}
          localized={
            content
              ? hasLocalizedContent(content.models, 'accounts')
              : undefined
          }
          devtools={{
            model: 'accounts',
            uri: `${accountId}/addresses/${values.id}`,
            zone: 'shipping',
            console: editing,
          }}
        >
          <fieldset>
            <OrderAddressForm
              suggestedAddresses={suggestedAddresses}
              name="shipping"
              record={record.shipping}
              values={values}
              onChangeForm={this.onChangeForm}
            />

            {isDefault ? (
              <Field type="hidden" name="is_default" value={true} />
            ) : (
              <div className="row">
                <Field
                  type="checkbox"
                  label="Set as default address"
                  name="is_default"
                  className="span4"
                  defaultChecked={true}
                  nonValue={false}
                  disabled={!this.hasAddresses()}
                />
              </div>
            )}

            {content && (
              <ContentFields
                {...this.props}
                zone="shipping"
                collection="accounts"
                models={content.models}
                record={record}
                values={values}
                currency={record.currency}
              />
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }

  renderAddressOption = (address) => {
    const { selectable = true, deletable } = this.props;
    const { selectedAddress } = this.state;
    const defaultAddress = this.getDefaultAddressId();
    const isChecked = address.id === selectedAddress;
    const isDefault = address.id === defaultAddress;

    return (
      <div
        key={address.id}
        className={classNames('manage-addresses-option', {
          selectable,
        })}
      >
        <button
          role="radio"
          aria-checked={isChecked}
          data-value={address.id}
          onClick={this.onSelect}
          className={classNames('form-field-input', {
            checked: isChecked,
          })}
        >
          {selectable && (
            <span
              className={classNames('form-radio-button', {
                checked: isChecked,
              })}
            />
          )}
          <div className="manage-addresses-option-details">
            <span className="manage-addresses-option-name">
              {address.first_name} {address.last_name}
            </span>
            <span className="manage-addresses-option-address">
              {makeAddressString(address)}
            </span>
          </div>
          {isDefault && (
            <Status size="md" type="muted" dot={false}>
              Default
            </Status>
          )}
          {deletable && (
            <Link
              data-value={address.id}
              className="link-button danger"
              onClick={this.onClickRemove}
            >
              Remove
            </Link>
          )}
          <Link
            data-value={address.id}
            className="link-button"
            onClick={this.onClickEdit}
          >
            Edit
          </Link>
        </button>
      </div>
    );
  };

  getModalActions() {
    const { selectable = true, onClickManageAddresses } = this.props;
    const { selectedAddress } = this.state;
    const isSelected = Boolean(selectedAddress);

    if (!selectable) {
      return [
        {
          label: 'Done',
          type: 'secondary',
          onClick: onClickManageAddresses,
        },
      ];
    }

    return [
      isSelected && {
        label: 'Save',
        onClick: this.onSave,
      },
      {
        label: 'Cancel',
        type: 'cancel',
        onClick: onClickManageAddresses,
      },
    ];
  }

  render() {
    const { record, model, addresses, onClickManageAddresses, content } =
      this.props;
    const { adding, editing } = this.state;
    const showAddressForm = adding || editing;

    return (
      <Fragment>
        <Modal
          title="Shipping addresses"
          actions={this.getModalActions()}
          width={700}
          cancel={false}
          onClose={onClickManageAddresses}
          localized={
            content
              ? hasLocalizedContent(content.models, 'accounts')
              : undefined
          }
          devtools={{
            model,
            uri: `${record.id}/shipping`,
            zone: 'shipping',
            console: !isEmpty(record.shipping),
          }}
        >
          <div className="manage-addresses">
            <div className="manage-addresses-options">
              {addresses.map(this.renderAddressOption)}
            </div>
            <div className="manage-addresses-action">
              <Button size="sm" type="secondary" onClick={this.onClickAdd}>
                Add address
              </Button>
            </div>
          </div>
        </Modal>
        {showAddressForm && this.renderAddressForm()}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageAddresses);
