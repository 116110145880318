import React from 'react';
import PropTypes from 'prop-types';
import { map, find } from 'lodash';
import { hasPermission } from 'utils';

import NavItem from './item';

import './nav.scss';

const { BASE_URI } = process.env;

export default class NavGroup extends React.PureComponent {
  static contextTypes = {
    isAdvancedUserPermissions: PropTypes.bool,
    user: PropTypes.object.isRequired,
  };

  getActiveItem(items = []) {
    let activeItem = null;

    try {
      for (const item of items) {
        let isActive = false;
        let childItems = item.items ? item.items?.filter((x) => !!x) : [];

        const pathname = `${location.pathname
          .replace(BASE_URI, '')
          .replace(/^\/test[^/]*/, '')
          .replace(/\/$/, '')}`;
        const uri = `${pathname}${location.search}`;

        if (item.exact) {
          if (item.link === uri) {
            isActive = true;
          }
        } else {
          if (item.link === '/' && (pathname === '/' || pathname === '')) {
            isActive = true;
          } else if (childItems.length) {
            isActive = !!find(
              childItems,
              (item) => uri.indexOf(item.link) === 0,
            );
            if (
              !isActive &&
              item.link !== '/' &&
              uri.indexOf(item.link) === 0
            ) {
              isActive = true;
            }
          } else if (item.link !== '/' && uri.indexOf(item.link) === 0) {
            isActive = true;
          }
        }
        if (isActive) {
          activeItem = item;
        }
      }
    } catch (err) {
      console.log(err);
    }

    return activeItem;
  }

  render() {
    const { heading, items, location, ...props } = this.props;
    const { isAdvancedUserPermissions = false, user } = this.context;

    const navItems = items;
    const activeParentItem = this.getActiveItem(navItems);

    let activeChildItem = null;
    for (const item of navItems) {
      if (item.items) {
        const activeItem = this.getActiveItem(item.items);
        if (activeItem) {
          activeChildItem = activeItem;
        }
      }
    }

    const navItemChildren = map(navItems, (item, index) => {
      const itemProps = {
        ...props,
        index,
        key: `${index}.${item.link}`,
        path: index,
        isActive: activeParentItem === item,
        activeChildItem: activeChildItem,
        otherItems: navItems.filter((i) => i !== item),
        ...item,
      };
      return (
        hasPermission(user, item.permission, item.link, {
          isAdvancedUserPermissions,
        }) && <NavItem {...itemProps} />
      );
    }).filter(Boolean);

    if (!navItemChildren.length) {
      return null;
    }

    return (
      <div className="nav-group">
        <h4 className="nav-group-heading">{heading}</h4>
        <ul className="nav-group-list">{navItemChildren}</ul>
      </div>
    );
  }
}
