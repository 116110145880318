import React, { Fragment } from 'react';
import { find, cloneDeep, isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { classNames, locationWithQuery, objectToArray } from 'utils';
import { getVisibleConfigsByLocation } from 'utils/collection';

import Link from 'components/link';
import Icon from 'components/icon';
import Tabs from 'components/tabs';
import Tooltip from 'components/tooltip';
import BackButton from 'components/button/back';
import LinkButton from 'components/button/link';
import DropdownButton from 'components/button/dropdown';
import ConsoleLink from 'components/console/link';
import ModelLink from 'components/model/link';
import { FadeLoading, FadeOut } from 'components/transitions';
import LocaleSelector from 'components/locale/selector';
import ViewSelector from 'components/content/view-selector';
import HelpLink from 'components/help-link';
import AppIndicator from './app-indicator';
import AppIcon from 'components/apps/icon';

import './view.scss';
import './print.scss';

export default class View extends React.PureComponent {
  static contextTypes = {
    submit: PropTypes.func,
    location: PropTypes.object,
    tabs: PropTypes.array,
    tags: PropTypes.array,
    detail: PropTypes.bool,
    uri: PropTypes.string,
    getLastUrl: PropTypes.func,
    setLastUrl: PropTypes.func,
    toggleLocale: PropTypes.func,
    router: PropTypes.object,
    client: PropTypes.object,
    user: PropTypes.object,
  };

  static propTypes = {
    sectionTitle: PropTypes.string,
    headerTitle: PropTypes.any,
    headerActions: PropTypes.array,
    headerComponent: PropTypes.object,
    menuActions: PropTypes.array,
    extraActions: PropTypes.array,
    footerActions: PropTypes.array,
    form: PropTypes.object,
    devtools: PropTypes.object,
    withPreferences: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      tabs: getVisibleConfigsByLocation(props.tabs, props.location),
      defaultTabs: getVisibleConfigsByLocation(
        props.defaultTabs,
        props.location,
      ),
      ...this.getActions(props, context),
    };
  }

  componentDidUpdate(prevProps) {
    const { headerActions, footerActions, menuActions, extraActions } =
      this.props;
    if (
      prevProps.headerActions !== headerActions ||
      prevProps.footerActions !== footerActions ||
      prevProps.menuActions !== menuActions ||
      prevProps.extraActions !== extraActions
    ) {
      this.setState({
        ...this.getActions(this.props, this.context),
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let obj = null;

    const changedLocation = props.location !== state.location;

    if (changedLocation) {
      obj = Object.assign(obj ?? {}, {
        location: props.location,
      });
    }

    if (changedLocation || props.fields !== state.prevFields) {
      obj = Object.assign(obj ?? {}, {
        prevFields: props.fields,
        tabs: getVisibleConfigsByLocation(props.tabs, props.location),
        defaultTabs: getVisibleConfigsByLocation(
          props.defaultTabs,
          props.location,
        ),
      });
    }

    return obj;
  }

  componentWillUnmount() {
    if (this.wentBack) {
      this.context.setLastUrl('');
    } else if (this.context.location.pathname.split('/').length > 2) {
      this.context.setLastUrl(this.context.location.pathname);
    }
  }

  onClickSubmit = (event) => {
    event.preventDefault();
    if (this.props.form && this.props.form.submit) {
      this.props.form.submit();
    } else if (this.context.submit) {
      this.context.submit();
    }
  };

  renderTags() {
    const {
      tags,
      editableTags = [],
      onClickRemoveTag,
      onSubmitAddTag,
    } = this.props;

    const tagsFiltered =
      tags &&
      tags
        .filter((x) => !!x)
        .map((tag) => <li key={tag}>{tag}</li>)
        .concat(
          editableTags.map((tag) => (
            <li className="editable" key={tag}>
              {tag}
              <a
                className="remove"
                href=""
                onClick={onClickRemoveTag}
                data-tag={tag}
              >
                &times;
              </a>
            </li>
          )),
        );

    if (!tagsFiltered || !tagsFiltered.length) {
      return;
    }

    return (
      <ul className="view-body-tags">
        {tagsFiltered}
        {onSubmitAddTag && (
          <li className="add">
            <a href="" onClick={this.onClickAddTag}>
              +
            </a>
          </li>
        )}
      </ul>
    );
  }

  getBackUrl() {
    const backUrl = this.context.getLastUrl();
    if (
      !this.props.nested &&
      backUrl &&
      backUrl.indexOf(this.context.location.pathname) === -1
    ) {
      return backUrl;
    }
    return;
  }

  onClickBack = (event) => {
    if (this.getBackUrl()) {
      event.preventDefault();
      this.wentBack = true;
      this.context.router.goBack();
    }
  };

  renderDevtoolsConsoleLink(props, context) {
    const { devtools } = props;
    if (!devtools || !context.user.devtools || devtools.viewConsole === false) {
      return null;
    }
    return (
      <ConsoleLink
        model={devtools.model.replace(/_/g, '/')}
        uri={devtools.uri || (devtools.record && devtools.record.id)}
        buttonType={null}
        icon={false}
        label="View in console"
      />
    );
  }

  renderDevtoolsModelLink(props, context) {
    const { devtools } = props;
    if (
      !devtools ||
      !context.user.devtools ||
      devtools.editModel === false ||
      devtools.model[0] === ':'
    ) {
      return null;
    }
    return (
      <ModelLink
        model={devtools.editModel || devtools.model}
        zone={devtools.zone}
        buttonType={null}
        icon={false}
      />
    );
  }

  renderLocaleSelector() {
    if (!this.props.localized) {
      return null;
    }
    return {
      component: <LocaleSelector />,
    };
  }

  renderViewSelector() {
    const { views } = this.props;
    // Disabled for now
    if (true || !views?.length) {
      return null;
    }
    return {
      component: <ViewSelector views={views} />,
    };
  }

  renderStatus() {
    const { headerStatus } = this.props;
    if (!headerStatus) {
      return null;
    }
    return {
      component: <div className="view-header-status">{headerStatus}</div>,
    };
  }

  getActionComponent = (action, index, array, linkProps) => {
    if (!action) {
      return null;
    }

    return (
      <Fragment key={index}>
        {action.component || (
          <Link
            to={action.link}
            onClick={action.disabled ? undefined : action.onClick}
            type={action.type}
            disabled={action.disabled}
            className={action.className}
            {...linkProps}
          >
            <Tooltip message={action.disabled && action.help}>
              {action.icon && <Icon type={action.icon} />}
              {action.label}
            </Tooltip>
          </Link>
        )}
      </Fragment>
    );
  };

  getActions(props, context) {
    const {
      headerActions: origHeaderActions = [],
      footerActions: origFooterActions = [],
      menuActions: origMenuActions = [],
      extraActions: origExtraActions = [],
    } = props;

    let [headerActions, footerActions, menuActions, extraActions] = [
      origHeaderActions,
      origFooterActions,
      origMenuActions,
      origExtraActions,
    ].map((actions) => actions.filter((act) => act && !act.hidden));

    const modelLink = this.renderDevtoolsModelLink(props, context);
    const consoleLink = this.renderDevtoolsConsoleLink(props, context);
    const devtoolItems = [
      ...(consoleLink ? [consoleLink] : []),
      ...(modelLink ? [modelLink] : []),
    ];
    const extraItems = [
      ...menuActions.map(this.getActionComponent),
      ...(menuActions.length > 0 &&
      (devtoolItems.length > 0 || extraActions.length > 0)
        ? [<hr />]
        : []),
      ...devtoolItems,
      ...(devtoolItems.length > 0 && extraActions.length > 0 ? [<hr />] : []),
      ...extraActions.map(this.getActionComponent),
    ];

    // Append devtools and extra actions to header action menu append extra menu
    const actionMenu = find(headerActions, { isActionMenu: true });
    const actionItems = actionMenu?.component?.props?.items;

    if (actionItems) {
      if (actionItems.length > 0) {
        actionItems.push(<hr />);
      }
      for (const item of extraItems) {
        actionItems.push(item);
      }
    } else if (extraItems.length > 0) {
      const subActions = headerActions.filter(
        (act) => (act.type || act.component?.props?.type) === 'sub',
      );
      headerActions = [
        ...subActions,
        {
          component: (
            <DropdownButton
              anchor="right"
              alignOffset={14}
              type="secondary"
              items={extraItems}
            >
              Actions
            </DropdownButton>
          ),
        },
        ...headerActions.filter((act) => subActions.indexOf(act) === -1),
      ];
    }

    return { headerActions, footerActions };
  }

  getEnhancedTabs(tabs) {
    const {
      client: { appsById },
    } = this.context;
    // Always convert objects to arrays
    let enhancedTabs = cloneDeep(tabs);
    if (!isEmpty(tabs) && !Array.isArray(tabs)) {
      enhancedTabs = [...map(tabs, (val, id) => ({ id, ...val }))];
    }

    // Add app logos and click actions to tabs
    for (const tab of enhancedTabs) {
      // TODO: Add click handler for tab actions
      // tab.onClick = this.onClickTab();

      // Add icon when a tab name matches another one
      if (enhancedTabs.filter((t) => t.label === tab.label).length > 1) {
        const app = appsById[tab.app_id];
        if (app) {
          tab.label = (
            <>
              <AppIcon image={app.logo_icon} name={app.name} size={16} />
              {tab.label}
            </>
          );
        }
      }
    }

    return enhancedTabs;
  }

  onClickTab = (tab) => {
    const { location, router } = this.props;
    if (location.query.tab !== tab) {
      router.push(
        locationWithQuery(location, {
          tab: tab === 'default' ? undefined : tab,
        }),
      );
    }

    // TODO: show options when clicked while already on tab
  };

  renderTabs() {
    const { uri, tabs: tabsIn, withPreferences } = this.props;
    const { location, router } = this.context;

    const tabs = tabsIn?.length > 0 ? tabsIn : objectToArray(tabsIn);

    if (!tabs?.length || !location) return null;

    return (
      <div className="view-header-tabs">
        <Tabs
          uri={uri}
          location={location}
          router={router}
          items={this.getEnhancedTabs(tabs)}
          active={location.query.tab}
          withPreferences={withPreferences}
        />
      </div>
    );
  }

  render() {
    const {
      uri,
      sectionTitle,
      headerTitle,
      headerSubtitle,
      headerImage,
      headerComponent,
      className,
      collectionEmpty,
      submitting,
      app,
      childId,
      showApp = true,
      detail = false,
      smaller = false,
      full = false,
      helpLink = true,
      overrideHelpLinkKey = null,
    } = this.props;

    const { headerActions, footerActions } = this.state;
    const isChild = Boolean(childId);

    const backUrl = this.getBackUrl();

    return (
      <div className={classNames('view-container', className)}>
        <div className={classNames('view-header', { detail, smaller, full })}>
          <div className="view-header-title">
            {sectionTitle && (
              <span className="view-header-crumb">
                {detail ? (
                  <BackButton to={backUrl || uri} onClick={this.onClickBack}>
                    {backUrl ? 'Back' : sectionTitle}
                  </BackButton>
                ) : (
                  <Link to={backUrl || uri} onClick={this.onClickBack}>
                    {sectionTitle}
                  </Link>
                )}
              </span>
            )}

            {headerComponent
              ? headerComponent
              : headerTitle &&
                (headerImage ? (
                  <div className="with-image">
                    <div className="view-header-image">{headerImage}</div>
                    <h3
                      className={classNames('view-header-name', {
                        'with-crumb': Boolean(sectionTitle),
                      })}
                    >
                      {headerTitle}
                    </h3>

                    {headerSubtitle && (
                      <div className="view-header-note">{headerSubtitle}</div>
                    )}
                  </div>
                ) : (
                  <h3
                    className={classNames('view-header-name', {
                      'with-crumb': Boolean(sectionTitle),
                    })}
                  >
                    {headerTitle}
                  </h3>
                ))}
          </div>

          {headerSubtitle && !headerImage && (
            <div className="view-header-subtitle">{headerSubtitle}</div>
          )}

          {this.renderTags()}

          <div
            className={classNames('view-header-actions', {
              'with-title': Boolean(sectionTitle),
              full,
            })}
          >
            <div className="view-header-actions-buttons">
              {[
                !isChild && this.renderStatus(),
                !isChild && this.renderViewSelector(),
                !isChild && this.renderLocaleSelector(),
                ...headerActions,
              ].map(
                (action, index) =>
                  action &&
                  (!collectionEmpty || action.showAlways) &&
                  (action.component ? (
                    <Fragment key={action.key || index}>
                      {action.component}
                    </Fragment>
                  ) : (
                    <LinkButton
                      key={index}
                      to={action.link}
                      target={action.target}
                      onClick={
                        action.submit ? this.onClickSubmit : action.onClick
                      }
                      type={action.type}
                      disabled={action.disabled || action.type === 'disabled'}
                      size={isChild ? 'sm' : undefined}
                    >
                      {(action.fa || action.icon) && (
                        <Icon fa={action.fa} type={action.icon} />
                      )}
                      {action.label}
                    </LinkButton>
                  )),
              )}
            </div>
          </div>

          {this.renderTabs()}
        </div>

        <div
          className={classNames('view-body', {
            detail,
            smaller,
            full,
          })}
        >
          {this.props.children}

          {app && showApp !== false && <AppIndicator app={app} />}
        </div>

        <FadeOut duration={100} className="view-loading-mask" />

        <FadeLoading active={submitting} className="view-loading-mask" />

        {footerActions.length > 0 && (
          <div className={classNames('view-footer', { detail, smaller })}>
            <div className="view-footer-buttons">
              {footerActions.map((action, index, array) =>
                this.getActionComponent(action, index, array, { size: 'sm' }),
              )}
            </div>
          </div>
        )}
        {helpLink && (
          <div className="view-help-link">
            <HelpLink overrideKey={overrideHelpLinkKey} />
          </div>
        )}
      </div>
    );
  }
}
