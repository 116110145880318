import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { View } from 'components/view';
import Side from 'components/view/side';
import Link from 'components/link';
import Icon from 'components/icon';
import Modal from 'components/modal';
import { Form, Field, TabView } from 'components/form';
import DropdownButton from 'components/button/dropdown';
import GiftcardStatus from 'components/giftcard/status';
import GiftcardValue from 'components/giftcard/value';
import GiftcardDescription from 'components/giftcard/description';
import OrderNotes from 'components/pages/order/notes';
import OrderContent from 'components/pages/order/content';
import OrderCustomer from 'components/pages/order/customer';
import OrderCustomerEdit from 'components/pages/order/customer-edit';
import WithViewPreferences from 'containers/WithViewPreferences';
import ContentRecordTab from 'components/content/record-tab';
import { formatDate } from 'utils';
import { hasLocalizedContent } from 'utils/content';
import './giftcard.scss';

export default class GiftcardView extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      revealingCode: false,
      showingSend: false,
      showingEditExpired: false,
      showingEditCustomer: false,
    };
    this.onSubmitNotes = this.onSubmitNotes.bind(this);
  }

  onClickDelete = (event) => {
    event.preventDefault();
    this.context.openModal('ConfirmDelete', {
      title: this.props.record.name,
      onConfirm: () => {
        this.props.onDelete();
      },
    });
  };

  onClickEditCustomer = (event) => {
    event.preventDefault();
    this.setState({
      showingEditCustomer: !this.state.showingEditCustomer,
    });
  };

  onSubmitEditCustomer = (values) => {
    this.setState({
      showingEditCustomer: false,
    });
    if (values.account.id) {
      return this.props.onEditValues({
        account_id: values.account.id,
      });
    } else {
      return this.props.onEditValues({
        account: values.account,
      });
    }
  };

  onClickRevealCode = (event) => {
    event.preventDefault();
    this.setState({
      revealingCode: !this.state.revealingCode,
    });
  };

  onClickEditExpired = (event) => {
    event.preventDefault();
    return this.setState({
      showingEditExpired: !this.state.showingEditExpired,
    });
  };

  onSubmitEditExpired = (values) => {
    this.setState({
      showingEditExpired: false,
    });
    return this.props.onEditValues({
      date_expired: values.date_expired,
    });
  };

  onClickSend = (event) => {
    event.preventDefault();
    return this.setState({
      showingSend: !this.state.showingSend,
    });
  };

  onSubmitSend = (values) => {
    this.setState({
      showingSend: false,
    });
    return this.props.onEditValues({
      send_email: values.send_email,
      send_note: values.send_note,
      $notify: values.$notify,
    });
  };

  onClickDisable = (event) => {
    event.preventDefault();
    return this.props.onEditValues({
      disabled: !this.props.record.disabled,
    });
  };

  async onSubmitNotes(values) {
    await this.props.onEditValues({
      notes: values.notes.trim(),
    });
  }

  render() {
    const { record, errors, lookup, content, settings, location } = this.props;
    const {
      revealingCode,
      showingSend,
      showingEditExpired,
      showingEditCustomer,
    } = this.state;

    const isDeletable = !record.redeemed && !record.order;
    const isExpirable =
      !record.redeemed && record.amount > (record.amount_spent || 0);

    return (
      <div className="giftcard-view view-detail-full">
        <WithViewPreferences
          {...this.props}
          tabs={[]}
          model="giftcards"
          id="edit"
          type="record"
        >
          <View
            detail={true}
            uri="/giftcards"
            devtools={{
              model: 'giftcards',
              record,
            }}
            withPreferences={true}
            sectionTitle="Gift cards"
            headerTitle={
              <span className="monospaced">
                {revealingCode ? (
                  record.code_formatted
                ) : (
                  <span>
                    •••• •••• ••••{' '}
                    {String(record.code_formatted).substr(-4, 4).trim()}
                  </span>
                )}
                <a
                  href=""
                  onClick={this.onClickRevealCode}
                  className="giftcard-view-reveal"
                >
                  <Icon fa={revealingCode ? 'eye-slash' : 'eye'} />
                </a>
              </span>
            }
            headerSubtitle={`${
              record.order_id ? 'Purchased' : 'Created'
            } ${formatDate(record.date_created, 'longDate')}`}
            headerActions={[
              !record.redeemed && {
                isActionMenu: true,
                component: (
                  <DropdownButton
                    key="2"
                    anchor="right"
                    anchorPosition={49}
                    type="secondary"
                    items={[
                      <button key="0" onClick={this.onClickDisable}>
                        {record.disabled ? 'Enable' : 'Disable'} gift card
                      </button>,
                    ]}
                  >
                    Actions
                  </DropdownButton>
                ),
              },
              !record.redeemed && {
                label: record.send_email ? 'Resend' : 'Send',
                type: 'secondary',
                onClick: this.onClickSend,
              },
            ]}
            localized={hasLocalizedContent(
              this.props.content.models,
              'giftcards',
            )}
            extraActions={[
              {
                label: 'Delete gift card',
                onClick: this.onClickDelete,
                disabled: !isDeletable,
                help: 'Gift card cannot be deleted after redemption',
                className: 'danger',
                delete: true,
              },
            ]}
          >
            <TabView value={location.query.tab} isDefault>
              <fieldset className="last-child">
                <table className="order-kpi-table">
                  <tbody>
                    <tr>
                      <td width="25%">
                        <span className="order-kpi-label">Gift card value</span>
                        <span className="order-kpi-value">
                          <GiftcardValue giftcard={record} />
                        </span>
                      </td>
                      <td width="25%">
                        <span className="order-kpi-label">Order</span>
                        <span className="order-kpi-value">
                          <div>
                            {record.order ? (
                              <Link to={`/orders/${record.order_id}`}>
                                Order {record.order.number}
                              </Link>
                            ) : (
                              <span className="muted">&mdash;</span>
                            )}
                          </div>
                        </span>
                      </td>
                      <td width="25%">
                        <span className="order-kpi-label">Expires</span>
                        <span className="order-kpi-value">
                          <div>
                            {isExpirable ? (
                              <a href="" onClick={this.onClickEditExpired}>
                                {record.date_expired
                                  ? formatDate(record.date_expired, 'shortDate')
                                  : 'Never'}
                              </a>
                            ) : record.date_expired ? (
                              formatDate(record.date_expired, 'shortDate')
                            ) : (
                              <span className="muted">&mdash;</span>
                            )}
                          </div>
                        </span>
                      </td>
                      <td width="25%">
                        <span className="order-kpi-label">Status</span>
                        <span className="order-kpi-value">
                          <div>
                            <GiftcardStatus giftcard={record} />
                          </div>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {!!record.send_email && (
                  <table className="order-kpi-table">
                    <tbody>
                      <tr>
                        <td width="37.5%">
                          <span className="order-kpi-label">Sent to</span>
                          <span className="order-kpi-value">
                            <div>{record.send_email}</div>
                          </span>
                        </td>
                        <td>
                          <span className="order-kpi-label">
                            Note to recipient
                          </span>
                          <span className="order-kpi-value">
                            <div>
                              {record.send_note || (
                                <span className="muted">&mdash;</span>
                              )}
                            </div>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {!!record.date_bulk_generated && (
                  <table className="order-kpi-table">
                    <tbody>
                      <tr>
                        <td width="37.5%">
                          <span className="order-kpi-label">Generated</span>
                          <span className="order-kpi-value">
                            <div>
                              {formatDate(record.date_bulk_generated, 'age')}
                            </div>
                          </span>
                        </td>
                        <td>
                          <span className="order-kpi-label">Description</span>
                          <span className="order-kpi-value">
                            <GiftcardDescription giftcard={record} />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                <OrderNotes
                  record={record}
                  onSubmit={this.onSubmitNotes}
                  label="Notes"
                />
                <OrderContent
                  {...this.props}
                  zone="content"
                  collection="giftcards"
                  record={record}
                  onSubmit={this.props.onEditValues}
                />
              </fieldset>
            </TabView>
            <ContentRecordTab
              {...this.props}
              collection="giftcards"
              view="edit"
            />
            <Side>
              <OrderCustomer
                record={
                  record.account
                    ? {
                        account: record.account,
                        shipping: record.account.shipping,
                        billing: record.account.billing,
                      }
                    : {}
                }
                content={content}
                settings={settings}
                removable={!record.redeemed}
                emptyLabel="Apply to customer account"
                onClickEditCustomer={
                  record.redeemed ? undefined : this.onClickEditCustomer
                }
              />
            </Side>
          </View>
        </WithViewPreferences>
        {showingEditCustomer && (
          <OrderCustomerEdit
            record={record}
            lookup={lookup}
            settings={settings}
            label="Apply to customer account"
            billing={get(record.account, 'billing', {})}
            shipping={get(record.account, 'shipping', {})}
            onSubmitEditCustomer={this.onSubmitEditCustomer}
            onClickEditCustomer={this.onClickEditCustomer}
            showLookupCurrency={true}
          />
        )}
        {showingSend && (
          <Form onSubmit={this.onSubmitSend} autoFocus={true}>
            <Modal
              title="Send via email"
              actions={[{ label: 'Send', type: 'submit' }]}
              width={500}
              onClose={this.onClickSend}
            >
              <fieldset>
                <Field
                  type="text"
                  name="send_email"
                  label="Send to email address"
                  rules="email"
                  required={true}
                  error={errors.send_email}
                  defaultValue={record.send_email}
                />
                <Field
                  type="textarea"
                  name="send_note"
                  label="Note to recipient"
                  placeholder="Optional"
                  autoSize={true}
                  defaultValue={record.send_note}
                  rows={3}
                />
                {!!record.send_email && (
                  <div className="row">
                    <Field
                      type="checkbox"
                      name="$notify"
                      value="fulfillment"
                      label="Re-send fulfillment email to recipient"
                      defaultChecked={true}
                    />
                  </div>
                )}
              </fieldset>
            </Modal>
          </Form>
        )}
        {showingEditExpired && (
          <Form onSubmit={this.onSubmitEditExpired} autoFocus={true}>
            <Modal
              title="Edit expiration date"
              actions={[{ label: 'Save', type: 'submit' }]}
              width={450}
              onClose={this.onClickEditExpired}
            >
              <fieldset>
                <Field
                  type="date"
                  name="date_expired"
                  error={errors.date_expired}
                  defaultValue={record.date_expired}
                  hint="Note: some countries prohibit the use of expiration dates"
                />
              </fieldset>
            </Modal>
          </Form>
        )}
      </div>
    );
  }
}
