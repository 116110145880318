import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class TooltipViewport extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      document.getElementById('tooltip-viewport'),
    );
  }
}

export default TooltipViewport;
