import React from 'react';
import PropTypes from 'prop-types';
import Frame from 'components/frame';
import { set, find, merge, isEmpty } from 'lodash';
import DOMPurify from 'isomorphic-dompurify';

import { isValueEqual, localeFallbackValue } from 'utils';
import './template-preview.scss';

const CLIENT_URL = process.env.CLIENT_URL;

export default class TemplatePreview extends React.PureComponent {
  previewContent = null;
  previewTimeout = false;

  static propTypes = {
    values: PropTypes.object,
    client: PropTypes.object.isRequired,
    record: PropTypes.object,
    defaults: PropTypes.object.isRequired,
    onReadyStateChange: PropTypes.func,
    getTemplateEngine: PropTypes.func.isRequired,
  };

  static defaultProps = {
    values: {},
    onReadyStateChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      hiddenProps: null,
      previewContentHtml: null,
      previewContentError: null,
    };
  }

  componentDidMount() {
    this.setPreview();
  }

  componentWillReceiveProps(nextProps) {
    const wasHidden = !this.props.visible && nextProps.visible;
    const prevProps =
      wasHidden && this.state.hiddenProps ? this.state.hiddenProps : this.props;
    const { values, visible } = nextProps;
    const { values: prevValues } = prevProps;
    const prevContent = prevValues.content;
    const nextContent = values.content;
    if (
      visible &&
      (!isValueEqual(prevContent, nextContent) ||
        !isValueEqual(prevValues.fields, values.fields) ||
        !isValueEqual(prevValues.preview_locale, values.preview_locale) ||
        values.preview_locale !== prevValues.preview_locale)
    ) {
      this.setPreview(nextProps, wasHidden);
    } else if (!visible && this.props.visible) {
      this.setState({ hiddenProps: { ...nextProps } });
    }
  }

  setPreview(props = this.props, skipTimer = false) {
    const { values, defaults, locale } = props;
    const fields = values.fields || defaults.record.fields;

    // Debounce preview
    clearTimeout(this.previewTimeout);

    this.previewTimeout = setTimeout(
      () => {
        this.previewTimeout = null;
        this.props.onReadyStateChange(true);
        this.setPreviewContent(
          props,
          { fields },
          locale || values.preview_locale,
        ).then(() => {
          this.props.onReadyStateChange(false);
        });
      },
      !skipTimer && this.state.previewContentHtml ? 500 : 0,
    );
  }

  async getSampleData(setConfig = {}, locale = undefined) {
    const { client, record, values, getTemplateEngine } = this.props;
    const { record: templateRecord, defaultRecord } = this.props.defaults;
    const config = { ...templateRecord, ...values, ...setConfig };

    const subjectData = !isEmpty(record)
      ? record
      : merge(defaultRecord.sample || {}, templateRecord.sample || {});
    const data = {
      ...subjectData,
      ...(config.globals || {}),
      currency: subjectData.currency || client.currency,
      store: {
        ...client,
        admin_url: CLIENT_URL.replace('CLIENT_ID', client.id),
        logo: config.store_logo || client.logo,
        logo_width: config.store_logo_width,
        color: config.store_color || '#614ed0',
        footer: config.store_footer,
        url: CLIENT_URL.replace('CLIENT_ID', client.id).replace(/admin$/, ''),
      },
      content: {},
    };

    // Render fields with data
    if (config.fields && config.fields.length > 0) {
      const content = {};
      const localeConfigs = client.locales;
      const localeConfig =
        locale && (find(localeConfigs, { code: locale }) || {});
      await Promise.all(
        config.fields.map(async (field) => {
          const localeValue =
            locale &&
            localeFallbackValue({
              context: field,
              name: 'value',
              locale,
              localeConfigs,
            });
          const localeDefault =
            locale &&
            localeFallbackValue({
              context: field,
              name: 'default',
              locale,
              localeConfigs,
            });
          const value =
            localeValue && localeValue.length > 0
              ? localeValue
              : localeDefault && localeDefault.length > 0
              ? localeDefault
              : locale && localeConfig.fallback === 'none'
              ? null
              : field.value && field.value.length > 0
              ? field.value
              : field.default;
          set(content, field.id, await getTemplateEngine().render(value, data));
        }),
      ).catch((err) => console.error(err.message));
      data.content = content;
    }

    return data;
  }

  async setPreviewContent(props, setConfig = {}, locale = undefined) {
    const { values, defaults, getTemplateEngine } = props;
    const { content: defaultContent } = defaults;

    const sampleData = await this.getSampleData(setConfig, locale);

    let previewContentHtml = null;
    let previewContentError = null;
    const content = values.content || defaultContent;

    await getTemplateEngine()
      .render(content, sampleData)
      .then((result) => (previewContentHtml = result))
      .catch((err) => (previewContentError = err.toString()));

    this.setState({
      previewContentHtml,
      previewContentError,
    });
  }

  renderPreviewContent() {
    const { previewContentHtml, previewContentError } = this.state;

    return (
      <div>
        {previewContentError ? (
          <div className="__frameError">{previewContentError}</div>
        ) : (
          previewContentHtml && (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(previewContentHtml, {
                  WHOLE_DOCUMENT: true,
                }),
              }}
            />
          )
        )}
      </div>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <Frame
        id="template-preview-iframe"
        scrolling="no"
        className={`preview-frame content ${className}`}
      >
        {this.renderPreviewContent()}
      </Frame>
    );
  }
}
