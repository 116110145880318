import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { Form, Field } from 'components/form';
import View from 'components/view';
import Modal from 'components/modal';
import Help from 'components/tooltip/help';
import Alert from 'components/alert';
import ButtonLink from 'components/button/link';
import AccountPlans from 'components/account-plans';
import IntervalToggle from 'components/account-plans/interval-toggle';
import { FadeIn } from 'components/transitions';
import UserExitNote from 'components/user/exit-note';
import HelpLink from '../help-link/help-link';
import { formatDate } from 'utils';
import { formatCurrencyRound, currencyValueRound } from 'utils/money';
import { subscriptionIntervalDesc } from 'utils/subscription';
import './account.scss';

import {
  PLANS,
  MAIN_PLANS,
  DEPRECATED_PLANS,
  TRIAL_DAYS,
  billingDiscountByInterval,
  billingPriceByInterval,
  billingPriceByMonth,
} from '../../shared/billing';

const { BASE_URI } = process.env;

export default class AccountPlanSettings extends React.PureComponent {
  static contextTypes = {
    account: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    superAccess: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showingSelectPlanId: null,
      selectValues: {},
      selectEdited: false,
      cancelValues: {},
      showingPromo: false,
      isMonthly: props.account?.plan?.interval === 'monthly' ? true : false,
    };
  }

  onClickCancelPlan = (event) => {
    event.preventDefault();
    this.setState({
      showingCancelModal: true,
      cancelPassword: null,
      cancelReason: null,
      cancelReasonText: null,
    });
  };

  onChangeCancelPlan = (values) => {
    this.setState({
      cancelValues: {
        ...this.state.cancelValues,
        ...values,
      },
    });
  };

  onClickCancelCancel = (event) => {
    event.preventDefault();
    this.setState({ showingCancelModal: false });
  };

  onCloseCancelPlan = () => {
    this.setState({ showingCancelModal: false });
  };

  onSubmitCancelPlan = (values) => {
    this.props.onCancelPlan(values);
  };

  onClickShowPromo = (event) => {
    event.preventDefault();
    this.setState({ showingPromo: !this.state.showingPromo });
    setTimeout(() => {
      this.refs.promoCode && this.refs.promoCode.focus();
    }, 500);
  };

  onClickApplyPromo = (event) => {
    event.preventDefault();
    this.onApplyPromo();
  };

  onKeyDownPromoEnter = (event) => {
    if (event.keyCode === 13) {
      event.stopPropagation();
      event.preventDefault();
      this.onApplyPromo();
    }
  };

  onApplyPromo = () => {
    const { account } = this.props;
    const { promo_code } = this.state.selectValues;
    if (promo_code || account.promo) {
      this.props.applyPromo(promo_code).then((result) => {
        if (result || account.promo) {
          this.setState({ showingPromo: false });
        }
      });
    } else {
      this.setState({ showingPromo: false });
    }
  };

  renderCancelModal() {
    const { loading } = this.props;
    const { cancelValues } = this.state;
    const cancelReason = cancelValues.cancel_reason;
    return (
      <Form
        onSubmit={this.onSubmitCancelPlan}
        onChange={this.onChangeCancelPlan}
        autoFocus={true}
      >
        <Modal
          title="Cancel your account"
          className="account-plans-cancel-modal"
          actions={[
            !cancelValues.password
              ? null
              : {
                  label: 'Cancel account',
                  type: 'submit',
                  styleType: 'danger',
                },
            {
              label: 'Nevermind',
              type: 'cancel',
              onClick: this.onClickCancelCancel,
            },
          ]}
          width={500}
          onClose={this.onCloseCancelPlan}
          loading={loading}
          cancel={false}
        >
          <fieldset>
            <Field
              type="select"
              name="cancel_reason"
              label="Why do you want to cancel your account?"
              options={[
                { value: 'pause', label: 'Need a break, be back later' },
                { value: 'competitor', label: 'Using another platform' },
                { value: 'few_features', label: 'Not enough features' },
                { value: 'few_orders', label: 'Not enough orders' },
                { value: 'too_hard_customize', label: 'Too hard to customize' },
                { value: 'too_hard_use', label: 'Too hard to use' },
                { value: 'too_expensive', label: 'Too expensive' },
                { value: 'other', label: 'Other' },
              ]}
              placeholder="Choose a reason"
              required={true}
            />
            {!!cancelReason && (
              <Field
                type="textarea"
                name="cancel_reason_text"
                rows={3}
                label={
                  cancelReason === 'competitor'
                    ? 'Which platform did you choose?'
                    : cancelReason === 'few_features'
                    ? 'Which features were you looking for?'
                    : cancelReason === 'too_hard_customize'
                    ? 'What customizations did you have trouble with?'
                    : cancelReason === 'too_hard_use'
                    ? 'What features did you have trouble using?'
                    : cancelReason === 'too_expensive'
                    ? 'What price were you looking to pay?'
                    : 'Can you tell us more?'
                }
              />
            )}
            <input type="text" name="username" className="hidden-field" />
            <Field
              type="password"
              label="Enter your password to confirm"
              name="password"
              rules="required, password"
              placeholder="********"
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }

  onClickSelectPlan = (event) => {
    event.preventDefault();
    const showingSelectPlanId = event.currentTarget.dataset.id;
    this.setState({
      showingSelectPlanId,
      selectValues: {},
      selectEdited: false,
    });
  };

  onClickIntervalToggle = (interval) =>
    interval === 'monthly'
      ? this.setState({ isMonthly: true })
      : this.setState({ isMonthly: false });

  onCloseSelectPlan = (event) => {
    event.preventDefault();
    this.setState({ showingSelectPlanId: false });
  };

  onChangeSelectPlan = (values, edited) => {
    this.setState({
      selectValues: { ...this.state.selectValues, ...values },
      selectEdited: edited,
    });
  };

  onSubmitSelectPlan = (values) => {
    const { showingPromo } = this.state;
    if (showingPromo) {
      return;
    } else {
      this.props.onSelectPlan(values);
    }
  };

  renderBillingDescription(plan, intervalId = 'monthly') {
    const { account } = this.props;
    const { selectValues } = this.state;
    const selectedValue =
      selectValues.intervalType ||
      (account.planSelected &&
        !account.plan.canceled &&
        this.getDefaultPlanInterval(plan));
    let saveDiscount;
    switch (intervalId) {
      case 'percent':
        return (
          <div className="account-plans-interval-info">
            <span
              className={`title ${
                selectedValue === intervalId ? 'selected' : ''
              }`}
            >
              Monthly
            </span>
            <span className="price">
              $0/mo{this.renderFeePercentHelp(plan)}
            </span>
          </div>
        );
      case 'monthly':
        return (
          <div className="account-plans-interval-info">
            <span
              className={`title ${
                selectedValue === intervalId ? 'selected' : ''
              }`}
            >
              monthly
            </span>
            <span className="price">
              {formatCurrencyRound(
                billingPriceByInterval(plan, 'monthly', 1, account.record),
                'USD',
              )}
              /mo
              {/*<span className="muted">
                (
                {formatCurrencyRound(billingPriceByYear(plan, 'monthly', 1, account.record), 'USD')}
                /year)
              </span>*/}
            </span>
          </div>
        );
      case 'yearly1':
        saveDiscount = billingDiscountByInterval(
          plan,
          'yearly',
          1,
          account.record,
        );
        return (
          <div className="account-plans-interval-info">
            <span
              className={`title ${
                selectedValue === intervalId ? 'selected' : ''
              }`}
            >
              annually
            </span>
            <span className="price">
              {formatCurrencyRound(
                billingPriceByInterval(plan, 'yearly', 1, account.record),
                'USD',
              )}
              /yr
              <span className="muted">
                (
                {formatCurrencyRound(
                  billingPriceByMonth(plan, 'yearly', 1, account.record),
                  'USD',
                )}
                /month)
              </span>
            </span>
            {saveDiscount > 0 && (
              <span className="saving-badge">Save {saveDiscount}%</span>
            )}
          </div>
        );
      case 'yearly2':
        saveDiscount = billingDiscountByInterval(
          plan,
          'yearly',
          2,
          account.record,
        );
        return (
          <div className="account-plans-interval-info">
            <span
              className={`title ${
                selectedValue === intervalId ? 'selected' : ''
              }`}
            >
              once every 2 years
            </span>
            <span className="price">
              {formatCurrencyRound(
                billingPriceByInterval(plan, 'yearly', 2, account.record),
                'USD',
              )}
              /yr
              <span className="muted">
                (
                {formatCurrencyRound(
                  billingPriceByMonth(plan, 'yearly', 2, account.record),
                  'USD',
                )}
                /mo)
              </span>
            </span>
            {saveDiscount > 0 && (
              <span className="saving-badge">Save {saveDiscount}%</span>
            )}
          </div>
        );
      default:
        return 'Unknown interval';
    }
  }

  getCurrentPlanDescription() {
    const {
      account: { plan },
    } = this.props;
    let intDesc = plan.interval;
    if (plan.interval_count > 1) {
      intDesc = `${plan.interval_count} years`;
    }
    return `${plan.name}, ${intDesc}`;
  }

  getDefaultPlanInterval(plan) {
    const { account } = this.props;
    return this.state.isMonthly ||
      (!account.plan.metadata.id === plan.id &&
        account.plan.interval === 'yearly')
      ? 'monthly'
      : `yearly${account.plan.interval_count}`;
  }

  renderSelectPlan() {
    const { account, client, loading } = this.props;
    const { selectValues, showingPromo } = this.state;
    const { intervalType = 'monthly', interval_count } = selectValues;

    const plan = find(PLANS, { id: this.state.showingSelectPlanId });

    const isActive = account.planSelected && !account.plan.canceled;
    const isUpgrade = Boolean(account.plan?.metadata.price < plan.price);
    const isDowngrade = Boolean(account.plan?.metadata.price > plan.price);
    const canUpdateBilling = account.record?.billing?.method !== 'invoice';
    const showBilling = Boolean(
      selectValues.updateBilling || !account.record || !account.record.billing,
    );

    return (
      <Form
        onSubmit={this.onSubmitSelectPlan}
        onChange={this.onChangeSelectPlan}
        autoFocus={true}
      >
        <Modal
          title={
            isActive
              ? isUpgrade
                ? `Upgrade to ${plan.name}`
                : isDowngrade
                ? `Downgrade to ${plan.name}`
                : `Change current plan (${this.getCurrentPlanDescription(
                    plan.name,
                  )})`
              : `Subscribe to ${plan.name}`
          }
          width={720}
          className="account-plans-select"
          actions={[
            !showingPromo && {
              label: isActive
                ? isUpgrade
                  ? `Upgrade plan`
                  : isDowngrade
                  ? `Downgrade plan`
                  : 'Save plan'
                : 'Activate',
              type: 'submit',
              disabled: loading,
              'data-id': `submit-${plan.id}`,
            },
          ]}
          onClose={this.onCloseSelectPlan}
          loading={loading}
        >
          <fieldset>
            {
              <div className="row">
                <Field
                  type="radio"
                  name="intervalType"
                  label={plan.price > 0 ? 'Billing interval' : undefined}
                  options={
                    plan.price > 0
                      ? [
                          {
                            value: 'monthly',
                            label: this.renderBillingDescription(
                              plan,
                              'monthly',
                            ),
                          },
                          {
                            value: 'yearly1',
                            label: this.renderBillingDescription(
                              plan,
                              'yearly1',
                            ),
                          },
                        ]
                      : [
                          {
                            value: 'monthly',
                            label: this.renderBillingDescription(
                              plan,
                              'percent',
                            ),
                          },
                        ]
                  }
                  defaultValue={
                    this.state.isMonthly
                      ? 'monthly'
                      : this.getDefaultPlanInterval(plan)
                  }
                  readonly={true}
                  stacked={true}
                  className={`account-plans-interval ${
                    plan.price === 0 ? 'full' : ''
                  }`}
                />
                <Field type="hidden" name="plan" value={plan.id} />
                <Field
                  type="hidden"
                  name="interval"
                  value={intervalType === 'monthly' ? 'monthly' : 'yearly'}
                />
                <Field
                  type="hidden"
                  name="interval_count"
                  value={
                    intervalType === 'monthly'
                      ? 1
                      : intervalType === 'yearly1'
                      ? 1
                      : intervalType === 'yearly2'
                      ? 2
                      : 1
                  }
                />
              </div>
            }
            {account.record && account.record.billing && canUpdateBilling && (
              <div>
                <Field
                  type="toggle"
                  name="updateBilling"
                  label="Update payment card"
                  readonly={true}
                />
              </div>
            )}
            <FadeIn active={showBilling} transitionAppear={false}>
              {showBilling && (
                <div className="account-plans-card-fields">
                  <div className="row">
                    <Field
                      type="card-number"
                      label="Credit card number"
                      name="billing.card.number"
                      rules={'required, credit_card_number'}
                      className="span2"
                    />
                    <Field
                      type="card-exp"
                      label="Expiration"
                      name="billing.card.exp"
                      rules={'required, credit_card_exp'}
                      className="span1"
                    />
                    <Field
                      type="text"
                      label="CVC"
                      name="billing.card.cvc"
                      placeholder="***"
                      rules={'required, credit_card_cvc'}
                      className="span1"
                    />
                  </div>
                  {/*}<div className="row">
                    <Field
                      type="card-number"
                      label="Billing Address"
                      name="billing.address1"
                      required={true}
                      className="span3"
                      />
                    <Field
                      type="card-number"
                      label="Zip/Postal Code"
                      name="billing.zip"
                      required={true}
                      className="span1"
                      />
                  </div>*/}
                </div>
              )}
              {plan.price > 0 && account.planTrial ? (
                <Alert className="account-plans-charge-warning">
                  After your <b>free trial</b> ends (
                  {formatDate(client.date_trial_end, 'short')}) you'll be
                  charged{' '}
                  {formatCurrencyRound(
                    billingPriceByInterval(
                      plan,
                      intervalType,
                      interval_count,
                      account.record,
                    ),
                    'USD',
                  )}
                </Alert>
              ) : (
                plan.fee_percent > 0 && (
                  <Alert className="account-plans-charge-warning">
                    Your card will not be charged until receiving live orders
                  </Alert>
                )
              )}
            </FadeIn>
          </fieldset>
          {plan.price > 0 && (
            <>
              <FadeIn
                active={showingPromo}
                className="account-plans-promo-wrapper"
              >
                <div className="account-plans-promo-fields">
                  <Field
                    type="text"
                    name="promo_code"
                    ref="promoCode"
                    placeholder="Enter a promo code"
                    defaultValue={account.promo && account.promo.code}
                    onKeyDown={this.onKeyDownPromoEnter}
                  />
                </div>
                <div className="account-plans-promo-actions">
                  <ButtonLink
                    onClick={this.onClickShowPromo}
                    size="sm"
                    type="secondary"
                  >
                    Cancel
                  </ButtonLink>
                  <ButtonLink onClick={this.onClickApplyPromo} size="sm">
                    Apply
                  </ButtonLink>
                </div>
              </FadeIn>
              {!showingPromo && (
                <a
                  href=""
                  className="account-plans-promo-link"
                  onClick={this.onClickShowPromo}
                >
                  Promo code{' '}
                  {account.promo && (
                    <span>
                      ({account.promo.code} &mdash;{' '}
                      {account.promo.discount > 0
                        ? `${account.promo.discount}% off`
                        : 'Special pricing'}
                      )
                    </span>
                  )}
                </a>
              )}
            </>
          )}
        </Modal>
      </Form>
    );
  }

  renderPlansWithView() {
    return (
      <View
        detail={true}
        uri="/settings/account"
        sectionTitle="Account"
        headerTitle="Change plan"
        headerSubtitle={<HelpLink sectionName="Change plan" />}
        className="account-plans-view"
        helpLink={false}
      >
        {this.renderPlans()}
      </View>
    );
  }

  renderFeePercentHelp(plan) {
    return (
      <Help
        message={`Plus ${plan.fee_percent}% billed monthly for paid orders received in the previous month`}
      />
    );
  }

  renderPlans() {
    const { location } = this.props;
    const { isMonthly } = this.state;
    const { client, account, session } = this.context;

    const currentPlanMetadata =
      (account.plan && !account.plan.canceled && account.plan.metadata) || {};
    const enterprisePlan = find(PLANS, { id: 'enterprise' });

    const isIntro = location.pathname.indexOf('/intro') >= 0;
    const isExiled = !isIntro && account.isExiled;

    const isCurrentPlanDeprecated =
      currentPlanMetadata.id &&
      DEPRECATED_PLANS.find((plan) => plan.id === currentPlanMetadata.id) &&
      !MAIN_PLANS.find((plan) => plan.id === currentPlanMetadata.id);

    return (
      <div className="account-plans-settings">
        {client.trialExpired && !account.plan && (
          <>
            <div className="account-plans-spacer" />
            <div className="account-plans-trial-expired">
              Your trial has expired, please select a plan
            </div>
          </>
        )}
        {account.planCanceled && (
          <>
            <div className="account-plans-spacer" />
            <div className="account-plans-trial-expired">
              Your plan was canceled, please select a new plan
            </div>
          </>
        )}
        {!account.planSelected && (
          <div
            className={`account-plans-welcome ${
              client.trialExpired ? 'expired' : ''
            }`}
          >
            <h2 className="account-plans-welcome-title">Pick a plan</h2>
            <p className="account-plans-welcome-subtitle">
              {account.plan ? (
                account.planTrialDaysLeft > 0 ? (
                  <>
                    Choose a plan today and continue your {TRIAL_DAYS}-day free
                    trial.
                    <Help
                      message={`After choosing a plan, your trial will continue with ${account.planTrialDaysLeftMessage}`}
                    />
                  </>
                ) : (
                  <>
                    Your {TRIAL_DAYS}-day free trial has run out. Choose a plan
                    to continue.
                  </>
                )
              ) : (
                <>No risk {TRIAL_DAYS}-day free trial. Cancel any time.</>
              )}
            </p>
          </div>
        )}
        <div className="account-plans-interval">
          <IntervalToggle
            isMonthly={isMonthly}
            onClickIntervalToggle={this.onClickIntervalToggle}
          />
        </div>
        {isCurrentPlanDeprecated && (
          <div className="account-plans-container">
            <div className="account-plans-section highlight">
              <div className="account-plans-section-heading">
                <h4 className="account-plans-section-title">
                  {currentPlanMetadata.name} at $
                  {currencyValueRound(account.plan.price, 'USD')}/
                  {subscriptionIntervalDesc(account.plan).toLowerCase()}
                </h4>
                <div className="account-plans-section-items">
                  This is a legacy plan.
                </div>
              </div>
            </div>
          </div>
        )}
        {currentPlanMetadata.id === enterprisePlan.id ? (
          <div className="account-plans-container">
            <div className="account-plans-section highlight">
              <div className="account-plans-section-heading">
                <h4 className="account-plans-section-title">
                  {enterprisePlan.name}
                </h4>
                <div className="account-plans-section-items">
                  Contact your account representative for details or to request
                  changes to your plan.
                </div>
              </div>
            </div>
          </div>
        ) : (
          <AccountPlans
            {...this.props}
            isMonthly={isMonthly}
            onClickSelectPlan={this.onClickSelectPlan}
          />
        )}
        {account.plan &&
          !account.plan.canceled &&
          currentPlanMetadata.id !== enterprisePlan.id && (
            <div className="account-plans-settings-cancel note">
              <a href="" className="muted" onClick={this.onClickCancelPlan}>
                Cancel this account
              </a>
            </div>
          )}
        {isExiled && account.planSelected && (
          <div className="note">
            <center>
              <a href={`${BASE_URI}/account`} className="muted">
                View account settings
              </a>
            </center>
            <br />
          </div>
        )}
        {isExiled && session.is_super_admin && (
          <div className="note">
            <center>
              <a
                href=""
                onClick={(event) => {
                  event.preventDefault();
                  this.context.superAccess(true);
                }}
              >
                [ Access as super admin ]
              </a>
            </center>
            <br />
          </div>
        )}
        {isExiled && <UserExitNote />}
        {this.state.showingSelectPlanId && this.renderSelectPlan()}
        {this.state.showingCancelModal && this.renderCancelModal()}
      </div>
    );
  }

  render() {
    const { account } = this.props;

    if (account.planSelected && !account.planCanceled) {
      return this.renderPlansWithView();
    }

    return this.renderPlans();
  }
}
