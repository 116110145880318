import React from 'react';
import pt from 'prop-types';

import Modal from 'components/modal';
import PaymentMethodsEdit from 'components/payment/methods-edit';

export default class OrderPaymentEdit extends React.PureComponent {
  static propTypes = {
    loading: pt.bool.isRequired,
    fetchRecord: pt.func.isRequired,
    onClickPaymentEdit: pt.func.isRequired,
    onSubmitPaymentEdit: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };

    this.paymentEditRef = React.createRef();
  }

  onClickEditCard = (editing) => {
    this.setState({ editing });
  };

  onSubmit = () => {
    const { onSubmitPaymentEdit } = this.props;
    const values = this.paymentEditRef.current.wrappedInstance.value();

    if (!values) {
      return;
    }

    const { method, account_card_id, address } = values;

    onSubmitPaymentEdit({
      billing: {
        method,
        ...(method === 'card' ? { account_card_id } : address),
      },
    });
  };

  render() {
    const { loading, fetchRecord, onClickPaymentEdit } = this.props;
    const { editing } = this.state;

    return (
      <Modal
        title="Change payment method"
        className="order-view-modal-payment-edit"
        actions={[
          {
            label: 'Save',
            type: 'submit',
            onClick: this.onSubmit,
            disabled: editing,
          },
          {
            label: 'Cancel',
            type: 'cancel',
            onClick: onClickPaymentEdit,
          },
        ]}
        loading={loading}
        width={700}
        cancel={false}
        onClose={onClickPaymentEdit}
      >
        <PaymentMethodsEdit
          ref={this.paymentEditRef}
          fetchRecord={fetchRecord}
          onClickEditCard={this.onClickEditCard}
        />
      </Modal>
    );
  }
}
