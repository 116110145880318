import React from 'react';
import pt from 'prop-types';

import { formatDate } from 'utils';

import Tooltip from 'components/tooltip';

function ActivityDate({ date }) {
  const value = new Date(date);

  return (
    <Tooltip
      dir="up"
      message={formatDate(value, 'longExact')}
      delayed={true}
      className="activity-event-date"
    >
      <time dateTime={value.toISOString()}>
        {formatDate(value, 'recentAgo')}&nbsp;
      </time>
    </Tooltip>
  );
}

ActivityDate.propTypes = {
  date: pt.oneOfType([pt.string, pt.number, pt.instanceOf(Date)]).isRequired,
};

export default React.memo(ActivityDate);
