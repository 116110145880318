import React from 'react';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import { FadeInUp } from 'components/transitions';
import LogoTopLeft from 'components/logo/logo';
import StoreIcon from 'components/store/icon';
import Icon from 'components/icon';

import './cli-auth-success.scss';

export default class CliAuthSuccessPage extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  render() {
    const { client, user } = this.context;

    return (
      <>
        <Confetti
          recycle={false}
          colors={[
            '#fcfaff',
            '#f9f5ff',
            '#f4ebff',
            '#ecebfc',
            '#d6bbfb',
            '#a790f4',
            '#785ce0',
            '#6646a4',
            '#6941c6',
            '#53389e',
            '#42307d',
          ]}
        />
        <div className="cli-auth-success-root">
          <LogoTopLeft />
          <FadeInUp>
            <div className="cli-auth-success-content">
              <div className="cli-auth-success-store-icon">
                <Icon
                  svgType="check-success"
                  className="cli-check-success"
                  width={24}
                  height={24}
                />
                <StoreIcon auth={user} size={56} />
              </div>
              <div>
                <h2>Logged in {client?.id ? `to ${client.id}` : ''}</h2>
                <p>You can close this window and return to the terminal.</p>
              </div>
            </div>
          </FadeInUp>
          <div className="cli">
            <Icon
              svgType="terminal-square"
              className="cli-icon"
              width={24}
              height={24}
            />
            <div>Command-line interface</div>
          </div>
        </div>
      </>
    );
  }
}
