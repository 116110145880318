/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Fragment } from 'react';
import pt from 'prop-types';
import { find } from 'lodash';
import { View } from 'components/view';

import { TabView } from 'components/form';
import PrevButton from 'components/button/prev';
import NextButton from 'components/button/next';
import Side from 'components/view/side';
import Icon from 'components/icon';
import CustomerAvatar from './avatar';
import CustomerOrders from './orders';
import CustomerSubscriptions from './subscriptions';
import CustomerDraftOrders from './draft-orders';
import CustomerAbandonedCarts from './abandoned-carts';
import CustomerContactEdit from './contact-edit';
import CustomerShippingEdit from './shipping-edit';
import CustomerBillingEdit from './billing-edit';
import CustomerBillingCardEdit from './billing-card-edit';
import CustomerPasswordReset from './password-reset';
import CustomerCredits from './credits';
import OrderAddress from 'components/pages/order/address';
import OrderNotes from 'components/pages/order/notes';
import OrderContent from 'components/pages/order/content';
import PaymentMethod from 'components/pages/order/payment-method';
import ManageAddresses from 'components/manage-addresses';
import WithViewPreferences from 'containers/WithViewPreferences';
import ContentRecordTab, {
  contentRecordSubmitAction,
  contentRecordSubmitChangeHandlers,
} from 'components/content/record-tab';
import { formatDate, formatCurrency } from 'utils';
import {
  multiCurrencyValueFromTotals,
  renderMultiCurrencyValuesTooltip,
} from 'utils/money';
import { accountName, getDefaultCardId } from 'utils/account';
import { localeName } from 'utils/geo';
import { hasLocalizedContent } from 'utils/content';
import './customer.scss';

export default class CustomerView extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    related: pt.object,
    prev: pt.object,
    next: pt.object,
    settings: pt.object,
    location: pt.object,
    loading: pt.bool,
    loadingRelated: pt.bool,
    suggestedAddresses: pt.array,
    content: pt.object,
    lookup: pt.object,
    categories: pt.object,
    fetchRecord: pt.func,
    onDelete: pt.func,
    onEditValues: pt.func,
    onLoadBilling: pt.func,
    onLoadCredits: pt.func,
    onLoadAddresses: pt.func,
    onUpdateBilling: pt.func,
    onAdjustCredit: pt.func,
  };

  static contextTypes = {
    client: pt.object.isRequired,
    openModal: pt.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showContactEdit: false,
      showShippingEdit: false,
      showBillingEdit: false,
      showBillingCardEdit: false,
      showPasswordReset: false,
      showCredits: false,
      showManageAddresses: false,
      creditPage: 1,
      addressesLength: 0,
      cardsLength: 0,
    };
    this.onClickContactEdit = this.onClickContactEdit.bind(this);
    this.onSubmitContactEdit = this.onSubmitContactEdit.bind(this);
    this.onClickShippingEdit = this.onClickShippingEdit.bind(this);
    this.onSubmitShippingEdit = this.onSubmitShippingEdit.bind(this);
    this.onClickBillingEdit = this.onClickBillingEdit.bind(this);
    this.onClickBillingCardEdit = this.onClickBillingCardEdit.bind(this);
    this.onSubmitBillingEdit = this.onSubmitBillingEdit.bind(this);
    this.onClickPasswordReset = this.onClickPasswordReset.bind(this);
    this.onSubmitPasswordReset = this.onSubmitPasswordReset.bind(this);
    this.onClickCredits = this.onClickCredits.bind(this);
    this.onSubmitCreditAdjustment = this.onSubmitCreditAdjustment.bind(this);
    this.onClickCreditPagePrev = this.onClickCreditPagePrev.bind(this);
    this.onClickCreditPageNext = this.onClickCreditPageNext.bind(this);
    this.onSubmitNotes = this.onSubmitNotes.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickManageAddresses = this.onClickManageAddresses.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const addressesLength = props.related?.account_addresses?.count || 0;
    const cardsLength = props.related?.account_cards?.count || 0;

    if (
      addressesLength !== state.addressesLength ||
      cardsLength !== state.cardsLength
    ) {
      return { addressesLength, cardsLength };
    }

    return null;
  }

  onClickDelete(event) {
    event.preventDefault();
    this.context.openModal('ConfirmDelete', {
      title: 'this customer',
      confirmInput: {
        description: `Enter the customer's email address to confirm`,
        value: this.props.record.email,
      },
      onConfirm: () => {
        this.props.onDelete();
      },
    });
  }

  onClickContactEdit(event) {
    event.preventDefault();
    this.setState({
      showContactEdit: !this.state.showContactEdit,
    });
  }

  onClickShippingEdit(event) {
    event.preventDefault();
    this.setState({
      showShippingEdit: !this.state.showShippingEdit,
    });
  }

  async onClickBillingEdit(event) {
    event.preventDefault();

    if (!this.state.showBillingEdit) {
      await this.props.onLoadBilling();
    }

    this.setState({
      showBillingEdit: !this.state.showBillingEdit,
    });
  }

  async onClickBillingCardEdit(event) {
    event.preventDefault();

    if (!this.state.showBillingCardEdit) {
      await this.props.onLoadBilling();
    }

    this.setState({
      showBillingCardEdit: !this.state.showBillingCardEdit,
    });
  }

  onClickPasswordReset(event) {
    event.preventDefault();
    this.setState({
      showPasswordReset: !this.state.showPasswordReset,
    });
  }

  onClickCredits(event) {
    event.preventDefault();
    if (!this.state.showCredits) {
      this.props.onLoadCredits();
    }
    this.setState({
      showCredits: !this.state.showCredits,
      creditPage: 1,
    });
  }

  onClickCreditPagePrev(event) {
    event.preventDefault();
    const creditPage = this.state.creditPage - 1;
    this.props.onLoadCredits({ page: creditPage });
    this.setState({ creditPage });
  }

  onClickCreditPageNext(event) {
    event.preventDefault();
    const creditPage = this.state.creditPage + 1;
    this.props.onLoadCredits({ page: creditPage });
    this.setState({ creditPage });
  }

  async onClickManageAddresses(event) {
    event?.preventDefault();

    if (!this.state.showManageAddresses) {
      await this.props.onLoadAddresses();
    }

    this.setState({
      showManageAddresses: !this.state.showManageAddresses,
    });
  }

  async onSubmitContactEdit(values) {
    const result = await this.props.onEditValues({
      ...values,
      type: values.convert ? 'business' : values.type,
      name: values.convert
        ? `${values.first_name} ${values.last_name}`
        : values.name,
    });

    if (result) {
      this.setState({
        showContactEdit: false,
      });
    }
  }

  async onSubmitShippingEdit(values) {
    const result = await this.props.onEditValues({
      shipping: values.shipping,
    });
    if (result) {
      this.setState({
        showShippingEdit: false,
      });
    }
  }

  async onSubmitBillingEdit(values) {
    const result = await this.props.onUpdateBilling(values);
    if (result) {
      this.setState({
        showBillingEdit: false,
        showBillingCardEdit: false,
      });
    }
  }

  async onSubmitPasswordReset(values) {
    const result = await this.props.onEditValues({
      password: values.password,
    });
    if (result) {
      this.setState({
        showPasswordReset: false,
      });
    }
  }

  async onSubmitCreditAdjustment(values) {
    const result = await this.props.onAdjustCredit({
      amount: values.adjust === 'add' ? values.amount : -values.amount,
      reason: values.reason,
      reason_message: values.reason_message,
      currency: values.currency || this.props.record.currency,
    });
    if (result) {
      this.setState({
        showCredits: false,
      });
    }
  }

  async onSubmitNotes(values) {
    await this.props.onEditValues({
      notes: values.notes.trim(),
    });
  }

  render() {
    const {
      record,
      prev = {},
      next = {},
      settings,
      related,
      location,
      loading,
      loadingRelated,
      suggestedAddresses,
      content,
      lookup,
      categories,
      onEditValues,
      fetchRecord,
    } = this.props;
    const { addressesLength, cardsLength } = this.state;

    const { billing, currency } = record;

    // Calculate order totals across all currencies
    const allTotals = [...record.order_totals, ...record.invoice_totals];
    const spendTotal = multiCurrencyValueFromTotals(
      allTotals,
      'total',
      currency,
    );

    const spendAvg = multiCurrencyValueFromTotals(allTotals, 'avg', currency);

    const customerGroup =
      record.group &&
      settings.accounts.groups.length > 0 &&
      find(settings.accounts.groups, { id: record.group });

    const contentProps = {
      content,
      lookup,
      categories,
    };

    return (
      <div className="customer-view view-detail-full">
        <WithViewPreferences
          {...this.props}
          tabs={[]}
          model="accounts"
          id="edit"
          type="record"
        >
          <View
            detail={true}
            submitting={loading}
            uri="/customers"
            devtools={{
              model: 'accounts',
              record,
            }}
            withPreferences={true}
            sectionTitle="Customers"
            headerTitle={accountName(record)}
            headerSubtitle={
              <time
                dateTime={record.date_created}
                title={record.date_created}
              >{`Since ${formatDate(record.date_created, 'longDate')}`}</time>
            }
            headerActions={[
              {
                type: 'sub',
                component: (
                  <span key="1" className="button-group">
                    <PrevButton
                      to={`/customers/${prev.id}`}
                      type="secondary"
                      disabled={!prev.id}
                    />
                    <NextButton
                      to={`/customers/${next.id}`}
                      type="secondary"
                      disabled={!next.id}
                    />
                  </span>
                ),
              },
              this.state.contentRecordTab &&
                contentRecordSubmitAction(this.state.contentRecordTab),
            ]}
            localized={hasLocalizedContent(
              this.props.content.models,
              'accounts',
            )}
            extraActions={[
              {
                label: 'Delete customer',
                onClick: this.onClickDelete,
                className: 'danger',
                delete: true,
              },
            ]}
          >
            <TabView value={location.query.tab} isDefault>
              <fieldset className="last-child">
                <div className="view-body-section-group">
                  <table className="order-kpi-table">
                    <tbody>
                      <tr>
                        <td>
                          <span className="order-kpi-label">
                            Credit balance
                          </span>
                          <span className="order-kpi-value">
                            <div>
                              <a href="" onClick={this.onClickCredits}>
                                {formatCurrency(record.balance, currency)}
                                {renderMultiCurrencyValuesTooltip(
                                  record.$currency,
                                  'balance',
                                  currency,
                                )}
                              </a>
                            </div>
                          </span>
                        </td>
                        <td>
                          <span className="order-kpi-label">Total spent</span>
                          <span className="order-kpi-value">
                            <div>
                              {formatCurrency(spendTotal, currency)}
                              {renderMultiCurrencyValuesTooltip(
                                allTotals,
                                'total',
                                currency,
                              )}
                            </div>
                          </span>
                        </td>
                        <td>
                          <span className="order-kpi-label">Average spend</span>
                          <span className="order-kpi-value">
                            <div>
                              {formatCurrency(spendAvg, currency)}
                              {renderMultiCurrencyValuesTooltip(
                                allTotals,
                                'avg',
                                currency,
                              )}
                            </div>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <OrderContent
                    {...this.props}
                    zone="details"
                    collection="accounts"
                    record={record}
                    onSubmit={this.props.onEditValues}
                  />
                  {record.subscriptions.count > 0 && (
                    <CustomerSubscriptions record={record} />
                  )}
                  {settings.general.features.orders && (
                    <Fragment>
                      <CustomerOrders record={record} />
                      {record.draft_orders && record.draft_orders.count > 0 && (
                        <CustomerDraftOrders record={record} />
                      )}
                      {record.abandoned_carts &&
                        record.abandoned_carts.count > 0 && (
                          <CustomerAbandonedCarts record={record} />
                        )}
                    </Fragment>
                  )}
                  <OrderNotes
                    record={record}
                    onSubmit={this.onSubmitNotes}
                    label="Customer notes"
                  />
                  <OrderContent
                    {...this.props}
                    zone="content"
                    collection="accounts"
                    record={record}
                    onSubmit={this.props.onEditValues}
                  />
                </div>
              </fieldset>
            </TabView>
            <ContentRecordTab
              {...this.props}
              collection="accounts"
              view="edit"
              form={true}
              {...contentRecordSubmitChangeHandlers(
                this,
                this.props.onEditValues,
              )}
            />
            <Side>
              <div className="view-side-container">
                <section className="view-side-section">
                  <div className="view-side-section-header">
                    <h4>
                      {record.type === 'business' ? 'Business' : 'Contact'}
                    </h4>
                    <button
                      type="button"
                      className="as-link"
                      onClick={this.onClickContactEdit}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="view-side-customer-avatar">
                    <CustomerAvatar account={record} size={50} />
                  </div>
                  <h4 className="view-side-customer-name">{record.name}</h4>
                  <div>{record.email}</div>
                  {record.phone && <div>{record.phone}</div>}
                  {customerGroup && <div>Group: {customerGroup.name}</div>}
                  {record.vat_number && <div>VAT: {record.vat_number}</div>}
                  {record.locale && (
                    <div className="customer-view-locale muted">
                      <Icon locale={record.locale} />{' '}
                      {this.context.client.localeNames[record.locale] ||
                        localeName(record.locale)}
                    </div>
                  )}
                </section>
                <section className="view-side-section">
                  <div className="view-side-section-header">
                    <h4>
                      Addresses
                      <span className="collection-table-count">
                        {addressesLength}
                      </span>
                    </h4>
                    <button
                      type="button"
                      className="as-link"
                      onClick={this.onClickManageAddresses}
                    >
                      {addressesLength > 0 ? 'Manage' : 'Add'}
                    </button>
                  </div>
                  <div className="view-side-body">
                    <OrderAddress
                      address={record.shipping}
                      content={content}
                      collection="accounts"
                      zone="shipping"
                    />
                  </div>
                </section>
                <section className="view-side-section">
                  <div className="view-side-section-header">
                    <h4>
                      Credit cards
                      <span className="collection-table-count">
                        {cardsLength}
                      </span>
                    </h4>
                    <button
                      type="button"
                      className="as-link"
                      onClick={this.onClickBillingCardEdit}
                    >
                      {cardsLength > 0 ? 'Manage' : 'Add'}
                    </button>
                  </div>
                  <div className="view-side-body">
                    <PaymentMethod
                      account={record}
                      billing={billing}
                      settings={settings}
                    />
                    <OrderAddress
                      address={billing}
                      record={billing}
                      content={content}
                      collection="accounts"
                      zone="billing"
                    />
                  </div>
                </section>
                <section className="view-side-section">
                  <div className="view-side-section-header">
                    <h4>Password</h4>
                    <button
                      type="button"
                      className="as-link"
                      onClick={this.onClickPasswordReset}
                    >
                      {record.password || record.$isPasswordSet
                        ? 'Change'
                        : 'Set'}
                    </button>
                  </div>
                  <div className="view-side-body">
                    <span className="muted">
                      {record.password || record.$isPasswordSet
                        ? '*********'
                        : 'None'}
                    </span>
                  </div>
                </section>
                {record.email_optin && (
                  <section className="view-side-section items-start">
                    <div className="view-side-body">
                      <div className="muted">
                        <span className="positive">
                          <Icon fa="check" />
                        </span>{' '}
                        Accepts email marketing
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </Side>
          </View>
        </WithViewPreferences>
        {this.state.showContactEdit && (
          <CustomerContactEdit
            record={record}
            settings={settings}
            loading={loading}
            {...contentProps}
            onClickContactEdit={this.onClickContactEdit}
            onSubmitContactEdit={this.onSubmitContactEdit}
            onEditValues={onEditValues}
          />
        )}
        {this.state.showShippingEdit && (
          <CustomerShippingEdit
            record={record}
            loading={loading}
            {...contentProps}
            onClickShippingEdit={this.onClickShippingEdit}
            suggestedAddresses={suggestedAddresses}
            onSubmitShippingEdit={this.onSubmitShippingEdit}
          />
        )}
        {this.state.showBillingEdit && (
          <CustomerBillingEdit
            record={record}
            related={related}
            suggestedAddresses={suggestedAddresses}
            loading={loading || loadingRelated}
            {...contentProps}
            onClickBillingEdit={this.onClickBillingEdit}
            onSubmitBillingEdit={this.onSubmitBillingEdit}
          />
        )}
        {this.state.showBillingCardEdit && (
          <CustomerBillingCardEdit
            fetchRecord={fetchRecord}
            loading={loading || loadingRelated}
            defaultCardId={getDefaultCardId(record)}
            onClickBillingCardEdit={this.onClickBillingCardEdit}
          />
        )}
        {this.state.showPasswordReset && (
          <CustomerPasswordReset
            record={record}
            loading={loading}
            onClickPasswordReset={this.onClickPasswordReset}
            onSubmitPasswordReset={this.onSubmitPasswordReset}
          />
        )}
        {this.state.showCredits && (
          <CustomerCredits
            record={record}
            related={related}
            loading={loading || loadingRelated}
            creditPage={this.state.creditPage}
            onClickCredits={this.onClickCredits}
            onSubmitCreditAdjustment={this.onSubmitCreditAdjustment}
            onClickCreditPagePrev={this.onClickCreditPagePrev}
            onClickCreditPageNext={this.onClickCreditPageNext}
          />
        )}
        {this.state.showManageAddresses && (
          <ManageAddresses
            fetchRecord={fetchRecord}
            loading={loading || loadingRelated}
            onClickManageAddresses={this.onClickManageAddresses}
            selectable={false}
            deletable={true}
            {...contentProps}
          />
        )}
      </div>
    );
  }
}
