import React from 'react';
import pt from 'prop-types';
import Link from 'components/link';
import { Form, Field, Button } from 'components/form';
import { FadeInUp, FadeIn } from 'components/transitions';
import LogoTopLeft from 'components/logo/logo';
import './login.scss';

const FADE_IN_DURATION = 500;

export default class JoinPage extends React.PureComponent {
  static propTypes = {
    invited: pt.shape({
      client_name: pt.string.isRequired,
      email: pt.string,
      name: pt.string,
    }),
    onSubmitJoin: pt.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tab: 'new', //this.props.invited.requires_login ? 'existing' : 'new',
    };
  }

  onChangeTab = (event, tab) => {
    this.setState({ tab });
  };

  renderSubmitButton() {
    const { invited } = this.props;

    return (
      <Button
        type="submit"
        styleType="default"
        size="large"
        className="button-default-purple"
      >
        Join <span className="capitalized">{invited.client_name}</span>
      </Button>
    );
  }

  render() {
    const { invited, onSubmitJoin } = this.props;
    const { tab } = this.state;

    return (
      <div className="login-form login-form-join">
        <FadeInUp>
          <div>
            <div className="logo-container">
              <LogoTopLeft />
            </div>
            <div className="login-subheading">
              Create an account or log in to join{' '}
              <span className="bold capitalized">{invited.client_name}</span>{' '}
              store.
            </div>
            <Form onSubmit={onSubmitJoin} autoFocus={true} ref="form">
              <Field
                type="radio"
                name="tab"
                className="login-join-tabs"
                options={[
                  { value: 'new', label: "I'm new here" },
                  { value: 'existing', label: 'I have an account' },
                ]}
                onChange={this.onChangeTab}
                defaultValue={tab}
                buttons
                readonly
              />
              {tab === 'new' && (
                <FadeIn duration={FADE_IN_DURATION}>
                  <fieldset>
                    <div className="row">
                      <Field
                        label="First name"
                        type="text"
                        name="first_name"
                        defaultValue={invited.name}
                        required={true}
                        autoCompleteOff={true}
                        large={true}
                        validateIcon={true}
                        validateBlur={true}
                        className="span2"
                      />
                      <Field
                        label="Last name"
                        type="text"
                        name="last_name"
                        defaultValue={invited.name}
                        required={true}
                        autoCompleteOff={true}
                        large={true}
                        validateIcon={true}
                        validateBlur={true}
                        className="span2"
                      />
                    </div>
                    <Field
                      label="Email"
                      type="text"
                      name="email"
                      defaultValue={invited.email}
                      rules="required, email"
                      autoCompleteOff={true}
                      large={true}
                      validateIcon={true}
                      validateBlur={true}
                    />
                    <input
                      type="password"
                      name="password"
                      className="hidden"
                      defaultValue=""
                    />
                    <Field
                      label="Create a password"
                      type="password"
                      name="password"
                      rules="required, password_admin"
                      autoComplete="new-password"
                      large={true}
                      validateIcon={true}
                      validateBlur={true}
                    />
                  </fieldset>
                  <div className="login-actions">
                    {this.renderSubmitButton()}
                  </div>
                </FadeIn>
              )}
              {tab === 'existing' && (
                <FadeIn duration={FADE_IN_DURATION}>
                  <fieldset>
                    <Field
                      label="Email"
                      type="text"
                      name="email"
                      defaultValue={invited.email}
                      rules="required, email"
                      large={true}
                      validateIcon={true}
                      validateBlur={true}
                    />
                    <Field
                      label="Password"
                      type="password"
                      name="password"
                      rules="required, password"
                      large={true}
                      validateIcon={true}
                      validateBlur={true}
                    />
                  </fieldset>
                  <div className="login-actions">
                    {this.renderSubmitButton()}
                    <div className="login-note">
                      Forgot your password?{' '}
                      <Link to="/recover" target="blank">
                        Recover
                      </Link>
                    </div>
                  </div>
                </FadeIn>
              )}
            </Form>
          </div>
        </FadeInUp>
      </div>
    );
  }
}
