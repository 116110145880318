import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import classNames from 'classnames';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from 'components/dropdown-menu';

export default class DropdownButton extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array,
    columns: PropTypes.array,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false,
    };
  }

  renderItem = (item, index) => {
    if (item?.type === 'li') {
      return item;
    }

    if (item?.type === 'hr' || item?.props?.children?.type === 'hr') {
      return <DropdownMenuSeparator key={index} />;
    }

    const renderedItem = typeof item === 'function' ? item(this) : item;

    return (
      renderedItem && (
        <DropdownMenuItem
          key={index}
          asChild
          onClick={() => !this.props.keepOpen && this.setState({ open: false })}
        >
          <div>{renderedItem}</div>
        </DropdownMenuItem>
      )
    );
  };

  renderTrigger() {
    const {
      button = true,
      children,
      props,
      className,
      arrow = true,
    } = this.props;

    if (button) {
      return (
        <>
          {children}
          {!!arrow && <Icon fa="chevron-down" />}
        </>
      );
    }

    return (
      <span className={classNames('button-dropdown', className)}>
        <span className="button-dropdown-button">
          <span {...props}>{children}</span>
        </span>
      </span>
    );
  }

  onDropdownMenuChange = (open) => {
    this.setState({ open: open });
  };

  render() {
    const {
      items = [],
      columns = [],
      anchor,
      button = true,
      modal = false,
      side = 'bottom',
      sideOffset = 16,
      alignOffset = 0,
      contentWidth = '100%',
    } = this.props;
    const { open } = this.state;

    return (
      <DropdownMenu
        rootRest={{
          modal: modal,
          open: open,
          onOpenChange: this.onDropdownMenuChange,
        }}
        contentRest={{
          side: side,
          sideOffset: sideOffset,
          align: anchor === 'left' ? 'start' : 'end',
          alignOffset: alignOffset,
          avoidCollisions: false,
          style: { minWidth: contentWidth },
        }}
        contentClassName={
          columns.length > 0
            ? 'dropdown-menu-content dropdown-menu-content-grid'
            : null
        }
        triggerChildren={this.renderTrigger()}
        triggerClassName={
          button ? 'button button-sm button-secondary' : 'button-dropdown'
        }
      >
        {columns.length > 0 ? (
          <>
            {columns.map(
              (col, index) =>
                col && (
                  <div key={index} className="dropdown-menu-item-grid-col">
                    {col.map(
                      (item, index) => item && this.renderItem(item, index),
                    )}
                  </div>
                ),
            )}
          </>
        ) : (
          <>
            {items.map((item, index) => item && this.renderItem(item, index))}
          </>
        )}
      </DropdownMenu>
    );
  }
}
