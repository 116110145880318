import React from 'react';
import { get, map, partition, orderBy, toUpper } from 'lodash';

import { formatDate, isEmpty } from 'utils';

import { Field } from 'components/form';
import { FadeIn } from 'components/transitions';
import Icon from 'components/icon';
import Link from 'components/link';
import Status from 'components/status';
import DropdownButton from 'components/button/dropdown';

function getMethodStatus(method) {
  switch (method) {
    case 'post':
      return 'positive';
    case 'get':
      return 'blue';
    case 'put':
      return 'warning';
    case 'delete':
      return 'negative';
    default:
      return '';
  }
}

export default class ConsoleForm extends React.Component {
  urlToEndpoint(url) {
    return url.replace(/^\//, '');
  }

  onClickHistoryItem = () => {
    const uriRef = get(this.refs.uri, 'refs.input');
    if (uriRef) {
      uriRef.focus();
    }
  };

  renderMethodStatus(method) {
    const type = getMethodStatus(method);

    return (
      <Status dot={false} type={type} size="sm">
        {toUpper(method)}
      </Status>
    );
  }

  renderHistoryItem(item) {
    const text = `${toUpper(item.method)} /${item.endpoint}${
      item.uri ? `/${item.uri}` : ''
    } ${this.renderHistoryBody(item.body)}`;
    const displayText = text.length > 100 ? `${text.substr(0, 100)}...` : text;

    return (
      <div>
        {this.renderMethodStatus(item.method)}
        {displayText.replace(toUpper(item.method), '')}
      </div>
    );
  }

  renderHistoryBody(body) {
    if (isEmpty(body)) {
      return '';
    }
    return JSON.stringify(body);
  }

  render() {
    const { endpoints, values, history } = this.props;

    let [metaModels, comModels] = partition(
      endpoints.map((endpoint) => ({
        value: this.urlToEndpoint(endpoint.url),
        label: endpoint.url,
        group: endpoint.name.startsWith(':')
          ? 'Meta models'
          : 'Commerce models',
      })),
      (op) => op.value[0] === ':',
    );

    // Filter out child meta models -- only include the base meta model.
    metaModels = metaModels.filter(
      ({ value }) => value.split(':').length === 2,
    );

    const options = [
      ...orderBy(comModels, 'value'),
      ...orderBy(metaModels, 'value'),
    ];

    return (
      <div className="console-form">
        <div className="console-form-methods">
          <Field
            type="radio"
            name="method"
            defaultValue={values.method || 'get'}
            buttons={true}
            options={[
              { value: 'get', label: <code>GET</code> },
              { value: 'put', label: <code>PUT</code> },
              { value: 'post', label: <code>POST</code> },
              { value: 'delete', label: <code>DELETE</code> },
            ]}
          />
        </div>
        <div className="row">
          <Field
            type="select"
            label="Collection"
            name="endpoint"
            options={options}
            defaultValue={values.endpoint}
            placeholder="Choose "
            className="span1 console-form-endpoint"
            required={true}
            selectFocus={true}
            onClickSelectValue={() => this.refs.uri.focus()}
            data-1p-ignore
          />
          <Field
            type="text"
            name="uri"
            label="URI"
            placeholder="/"
            className="span3 console-form-uri"
            defaultValue={values.uri}
            ref="uri"
            data-1p-ignore
          />
          <DropdownButton
            type="secondary"
            anchor="right"
            alignOffset={-2}
            anchorPosition={50}
            className="console-form-history-button-dropdown"
            arrow={false}
            items={
              history.length > 0
                ? map(orderBy(history, 'meta.created', 'desc'), (item) => (
                    <Link
                      to={`/console?${item.id}`}
                      title={this.renderHistoryItem(item).props.children[1]}
                      onClick={this.onClickHistoryItem}
                      key={item.meta.created}
                      className="console-history-item"
                    >
                      <span>{this.renderHistoryItem(item)}</span>
                      <div className="console-history-date">
                        {formatDate(new Date(item.meta.created), 'age')}
                      </div>
                    </Link>
                  ))
                : [<span className="nowrap">No request history</span>]
            }
          >
            <Icon fa="history" />
          </DropdownButton>
        </div>
        {values.method !== 'delete' && (
          <FadeIn>
            <Field
              type="json-editor"
              name="body"
              label="Body"
              defaultValue={values.body}
            />
          </FadeIn>
        )}
      </div>
    );
  }
}
