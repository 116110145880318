import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import Icon from 'components/icon';
import DropdownButton from 'components/button/dropdown';

import cx from 'classnames';

export default class ContentViewSelector extends React.PureComponent {
  static contextTypes = {
    setContentView: PropTypes.func,
    toggleStandardContent: PropTypes.func,
    user: PropTypes.object,
  };

  static propTypes = {
    views: PropTypes.array,
  };

  state = {
    currentView: null,
    withContentFields: true,
  };

  constructor(props, context) {
    super(props, context);
    const currentView = this.getCurrentView();
    this.state = {
      currentView,
    };
  }

  componentDidUpdate(prevProps) {
    const currentView = this.getCurrentView();
    if (currentView !== this.state.currentView) {
      this.setState({ currentView });
    }
  }

  onClickView = (event) => {
    event.preventDefault();
    const nextView = find(this.props.views, {
      gid: event.currentTarget.dataset.id,
    });
    if (nextView) {
      this.setState({ currentView: nextView });
      this.context.setContentView(nextView);
    }
    // this.refs.dropdown.setState({ open: false });
  };

  onClickToggleStandardContent = (event) => {
    event.preventDefault();
    this.context.toggleStandardContent();
  };

  getCurrentView() {
    const { views } = this.props;
    const {
      user: { contentViews },
    } = this.context;

    if (!views[0]) {
      return null;
    }

    const { collection, type } = views[0];
    const currentGid =
      contentViews[collection] && contentViews[collection][type];
    const currentView =
      (currentGid && find(views, { gid: currentGid })) || views[0];

    return currentView;
  }

  isViewCurrent(view) {
    const { currentView } = this.state;
    return Boolean(currentView && currentView.gid === view.gid);
  }

  render() {
    const { views } = this.props;
    const { currentView } = this.state;

    const hasViews = views?.length > 1;
    const hasContentFields =
      currentView?.standard && views[0].contentFields?.length > 0;
    const hasBoth = hasViews && hasContentFields;

    if (!hasViews && !hasContentFields) {
      return null;
    }

    return (
      <div className="content-view-selector">
        <DropdownButton
          anchor="right"
          anchorPosition={42}
          type="sub"
          ref="dropdown"
          columns={[
            [
              ...(hasViews
                ? views.map((view) => (
                    <div
                      onClick={this.onClickView.bind(this)}
                      data-id={view.gid}
                      className="locale-select"
                    >
                      {view.label}
                      <div
                        className={cx('content-view-selector-check', {
                          active: this.isViewCurrent(view),
                        })}
                      >
                        <Icon fa="check" />
                      </div>
                    </div>
                  ))
                : []),
              ...(hasBoth ? [<hr />] : []),
              ...(hasContentFields && [
                <div
                  onClick={this.onClickToggleStandardContent.bind(this)}
                  className="locale-select"
                >
                  Show content fields
                  <div
                    className={cx('content-view-selector-check', {
                      active: currentView.content !== false,
                    })}
                  >
                    <Icon fa="check" />
                  </div>
                </div>,
              ]),
            ],
          ]}
        >
          <span className="content-view-selector-label">
            <Icon type="nav/apps" />{' '}
            {/*views.length > 1 ? views.length : null*/}
          </span>
        </DropdownButton>
      </div>
    );
  }
}
