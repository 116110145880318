import React, { Fragment } from 'react';
import { find, get, cloneDeep, set } from 'lodash';

import IconPayment from 'components/icon/payment';
import AccountOption from 'components/payment/account-option';
import GiftcardOption from 'components/payment/giftcard-option';
import MethodOption from 'components/payment/method-option';

import { formatCurrencyRounded, wordify, inflect } from './index';
import { renderPaymentSourceDetails } from './order';
import { getCardMethod } from './settings';

// This is a list of payment methods, the details of which are not specified in the billing.
const METHODS_WITHOUT_DETAILS = ['twint', 'paysafecard'];

export const ALT_METHODS = {
  amazon: 'Amazon Pay',
  paypal: 'PayPal',
  affirm: 'Affirm',
  resolve: 'Resolve',
  klarna: 'Klarna',
  ideal: 'iDEAL',
  bancontact: 'Bancontact',
  google: 'Google Pay',
  apple: 'Apple Pay',
  twint: 'TWINT',
  paysafecard: 'Paysafecard',
};

export function paymentMethodName(method, settings) {
  if (settings && settings.methods) {
    const methodSetting = find(settings.methods, { id: method });
    if (methodSetting) {
      return methodSetting.name;
    }
  }
  return wordify(method);
}

export function paymentMethodManualIcon(method, settings) {
  if (settings && settings.methods) {
    const methodSetting = find(settings.methods, { id: method, manual: true });
    if (methodSetting) {
      return methodSetting.icon;
    }
  }
  return undefined;
}

export function paymentMethodOptions(props, selectedMethod) {
  const { model, record, settings, fetchRecord } = props;
  const { account, authorized_payment: authorizedPayment } = record;
  const methods = settings.payments.methods || [];
  const billing = record.billing || {};
  const options = [];

  if (billing.card) {
    options.push({
      value: 'card',
      label: renderPaymentSourceDetails(billing, 'card', true),
    });
  } else {
    options.push({
      value: 'card',
      label: (
        <Fragment>
          <IconPayment method="card" />
          Credit card
        </Fragment>
      ),
    });
  }

  const giftcardMethod = methods.find(
    ({ id, enabled }) => id === 'giftcard' && enabled === true,
  );

  // Disable gift cards for subscriptions
  if (giftcardMethod && model !== 'subscriptions') {
    options.push({
      value: 'giftcard',
      label: (
        <GiftcardOption
          fetchRecord={fetchRecord}
          selected={selectedMethod === 'giftcard'}
        />
      ),
    });
  }

  const accountMethod = methods.find(
    ({ id, enabled }) => id === 'account' && enabled === true,
  );

  if (account && (accountMethod || billing.method === 'account')) {
    options.push({
      value: 'account',
      label: (
        <AccountOption
          fetchRecord={fetchRecord}
          selected={selectedMethod === 'account'}
        />
      ),
    });
  }

  options.push(
    ...methods
      .filter((method) => method.manual && method.enabled)
      .map((method) => ({
        value: method.id,
        label: (
          <MethodOption
            id={method.id}
            name={method.name}
            description={method.description}
            settings={settings.payments}
          />
        ),
      })),
  );

  // Enable charging authorized alt methods
  if (canChargeAuthorizedAltPayment(authorizedPayment, billing)) {
    const { method } = authorizedPayment;
    const name = ALT_METHODS[method];

    options.push({
      value: method,
      label: (
        <MethodOption
          id={method}
          name={name}
          settings={settings.payments}
          authorized={true}
        />
      ),
    });
  }

  return options;
}

/**
 * Checks if the payment is captured.
 *
 * @param {object} payment
 * @returns {boolean}
 */
export function isPaymentCaptured(payment) {
  if (!payment) {
    return false;
  }

  return payment.captured !== false;
}

/**
 * Checks if the payment is authorized.
 *
 * @param {object} payment
 * @returns {boolean}
 */
export function isPaymentAuthorized(payment) {
  if (!payment) {
    return false;
  }

  return Boolean(payment.authorized) && !isPaymentCaptured(payment);
}

/**
 * Checks if the payment is async.
 *
 * @param {object} payment
 * @returns {boolean}
 */
export function isPaymentAsync(payment) {
  if (!payment) {
    return false;
  }

  return Boolean(payment.async);
}

/**
 * Checks if the payment method is an alternative.
 *
 * @param {object} payment
 * @returns {boolean}
 */
export function isAltPaymentMethod(payment) {
  if (!payment?.method) {
    return false;
  }

  return Boolean(ALT_METHODS[payment.method]);
}

/**
 * Checks whether an authorized payment can be captured.
 *
 * @param {object} payment
 * @param {object} billing
 * @returns {boolean}
 */
export function canChargeAuthorizedPayment(payment, billing) {
  if (!payment?.method) {
    return false;
  }

  const { method } = payment;
  const hasBilling =
    billing[method] || METHODS_WITHOUT_DETAILS.includes(method);

  if (!hasBilling) {
    return false;
  }

  return isPaymentAuthorized(payment) && !isPaymentAsync(payment);
}

/**
 * Checks whether an authorized payment with alternative payment method can be captured.
 *
 * @param {object} payment
 * @param {object} billing
 * @returns {boolean}
 */
export function canChargeAuthorizedAltPayment(payment, billing) {
  return (
    isAltPaymentMethod(payment) && canChargeAuthorizedPayment(payment, billing)
  );
}

export function paymentMethodOptionsChargeable(props) {
  return paymentMethodOptions(props).filter((option) =>
    option.value === 'card'
      ? Boolean(
          props.record.billing.card || props.record.billing.account_card_id,
        )
      : true,
  );
}

export function paymentGiftcardOptions(props) {
  const { giftcards, currency } = props.record;
  if (!giftcards || !giftcards.length) {
    return null;
  }
  return [
    ...giftcards.map((gc) => ({
      value: gc.id,
      label: (
        <Fragment>
          <IconPayment method="giftcard" />
          {gc.last4}{' '}
          {!!gc.giftcard && (
            <span className="muted">
              &nbsp;({formatCurrencyRounded(gc.giftcard.balance, currency)})
            </span>
          )}
        </Fragment>
      ),
    })),
    {
      value: '',
      label: 'Enter code',
    },
  ];
}

export function paymentMethodDefault(props, methodOptions) {
  const {
    record: { billing },
  } = props;

  if (billing) {
    if (billing.method && find(methodOptions, { value: billing.method })) {
      return billing.method;
    }

    if (billing.card) {
      return 'card';
    }

    if (billing.amazon) {
      return 'amazon';
    }

    if (billing.paypal) {
      return 'paypal';
    }
  }

  return get(methodOptions, '[0].value');
}

/**
 * Prefixes the original key name with live_ or test_ depending on which mode.
 */
export function modePrefix(isLive, name) {
  const prefix = isLive ? 'live_' : 'test_';
  return `${prefix}${name}`;
}

export function isStripeConfigured(settings) {
  const method = getCardMethod(settings);
  return method && method.gateway === 'stripe' && method.enabled;
}
function isStripePaymentMethod(token) {
  return String(token).startsWith('pm_');
}

export const isStripeCardGateway = (settings, record) =>
  isStripeConfigured(settings) &&
  get(record, 'billing.method') === 'card' &&
  get(record, 'billing.card.gateway') === 'stripe';
export const isStripeCardPaymentMethod = (record) => {
  const token = get(record, 'billing.card.token');
  return isStripePaymentMethod(token);
};

export const isStripeIDealGateway = (settings, record) =>
  isStripeConfigured(settings) && get(record, 'billing.method') === 'ideal';
export const isStripeIDealPaymentMethod = (record) =>
  isStripePaymentMethod(get(record, 'billing.ideal.token'));

export const isStripeKlarnaGateway = (settings, record) =>
  isStripeConfigured(settings) && get(record, 'billing.method') === 'klarna';

export const getRefundablePayments = (payments) =>
  (payments ?? [])
    .filter((payment) => payment.amount_refundable > 0)
    // disable refund for paypal progressive payments for now
    .filter((payment) => payment.paypal_intent !== 'capture');

export const mergePaymentSettings = ({ settings, key, value }) => {
  const newSettings = cloneDeep(settings);

  set(newSettings, `methods.${key}`, {
    ...newSettings.methods[key],
    ...value,
  });

  return newSettings;
};

export function isLiveMode(mode) {
  return mode !== 'test';
}

/**
 * Creates gift card payments.
 *
 * @param {object} record
 * @param {number} amount
 * @param {function} createPayment
 * @returns {Promise<void>}
 * @throws {Error}
 */
export async function chargeGiftcard(record, amount, createPayment) {
  const { id, giftcards, currency } = record;
  const hasGiftcards = giftcards?.length > 0;

  if (!hasGiftcards) {
    throw new Error('No gift cards applied');
  }

  const giftcardsBalance = giftcards.reduce(
    (acc, giftcard) => acc + giftcard.giftcard.balance,
    0,
  );

  if (giftcardsBalance < amount) {
    throw new Error(
      `Gift ${inflect(giftcards.length, 'cards', {
        showCount: false,
      })} balance is short (${formatCurrencyRounded(
        giftcardsBalance,
        currency,
      )})`,
    );
  }

  for await (const giftcard of giftcards) {
    const giftcardBalance = giftcard.giftcard.balance;

    if (giftcardBalance <= 0) {
      continue;
    }

    const paymentAmount = amount > giftcardBalance ? giftcardBalance : amount;

    const result = await createPayment(id, {
      method: 'giftcard',
      giftcard_id: giftcard.id,
      amount: paymentAmount,
    });

    if (!result || result.error || result.errors) {
      throw new Error(
        result?.error || result?.errors || 'Something went wrong',
      );
    }

    amount -= paymentAmount;

    if (amount <= 0) {
      break;
    }
  }
}
