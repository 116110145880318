import React from 'react';
import strip from 'strip';
import ansiToHTML from 'ansi-to-html';
import normUrl from 'normalize-url';

export function stripTags(str) {
  return strip(str);
}

// Expand a string template with object values
// This was copied from the API code
// Options:
//   unset: remove matched keys from from values
//   typecast: use original type if whole string matches a single value
export function expandString(
  strTemp,
  values,
  { unset = false, typecast = false } = {},
) {
  if (!strTemp || !strTemp.replace) {
    return null;
  }
  let shouldAbort = false;
  let singleValue = null;
  let singleField = null;

  try {
    // Replace occurrences of {field} with values[field]
    const strExpanded = strTemp.replace(/\{.+?\}/g, (field) => {
      singleField = field;
      // Stripe curly braces
      field = field.substring(1, field.length - 1);
      let value;
      let splitParts;
      let defaultValue;
      // {field|default} values
      if (field.indexOf('|') !== -1) {
        splitParts = field.split('|');
        field = splitParts[0];
        defaultValue = splitParts[1];
      }
      // {field.nested} values
      if (field.indexOf('.') !== -1) {
        splitParts = field.split('.');
        field = splitParts.shift().trim();
        let nestedValues = values[field];
        while (splitParts.length) {
          const nestedField = splitParts.shift().trim();
          if (splitParts.length) {
            nestedValues = nestedValues ? nestedValues[nestedField] : {};
          } else {
            value = nestedValues ? nestedValues[nestedField] : null;
          }
        }
      } else {
        value = values[field];
      }
      // If field value not defined
      if (value === '' || value === null || value === undefined) {
        if (defaultValue !== undefined) {
          return (singleValue = String(defaultValue));
        }
        // Or abort expand
        shouldAbort = true;
        return;
      }
      // Unset value if it was expanded into string
      if (unset) {
        delete values[field];
      }
      singleValue = value;
      return value;
    });

    if (typecast && singleField === strTemp) {
      return singleValue;
    }

    return shouldAbort ? null : strExpanded;
  } catch (err) {
    console.error(err);
  }

  return strTemp;
}

export function renderLogs(str, timestamps = false) {
  const convertAnsi = new ansiToHTML();
  return String(str || '')
    .split('\n')
    .map((rawLine) => {
      let line = rawLine;
      if (timestamps) {
        const ts = line.match(/^([0-9][^Z]+Z)/);
        if (ts && ts[1]) {
          const date = ts[1].replace('T', ' ').replace(/\.[0-9]+Z$/, '');
          line = line.replace(
            /^[0-9][^Z]+Z/,
            `<span class="date">${date}&emsp;</span>`,
          );
        }
      }
      return convertAnsi.toHtml(line) + '<br />';
    });
}

export function normalizeUrl(url, options = {}) {
  let result = normUrl(url, {
    defaultProtocol: 'https:',
    forceHttps: true,
    ...options,
  });
  // Correct for when `forceHttps` and `defaultProtocol` aren't working (??)
  if (options?.forceHttps !== false) {
    result = result.replace('http:', 'https:');
  }
  return result;
}

export function displayUrl(url) {
  return String(url)
    .trim()
    .replace(/^http:\/\//, '')
    .replace(/^https:\/\//, '')
    .replace(/^www\./, '');
}

/**
 * Splits long message into multiple lines
 * tries to separate using maxTextLineSeparator if possible to create user friendly lines.
 * returns array with parts
 *
 * @param {string} message string to split
 * @param {number} maxTextLineWidth maximum text line width
 * @param {string} maxTextLineSeparator try to separate on this places if possible
 * @param {number} maxDiff the interval to find separator from the proposed position
 * @return {string[]} array with parts
 */
export function separateStringOnParts(
  message,
  maxTextLineWidth,
  maxTextLineSeparator,
  maxDiff = 20,
) {
  const parts = [];
  let ind = 0;
  while (ind < message.length) {
    const nextPos = ind + maxTextLineWidth;
    let part = '';
    if (nextPos >= message.length) {
      // last part
      part = message.substring(ind, message.length);
      ind = message.length;
    } else {
      // find separator in range [0...maxDiff)
      let pos = nextPos;
      while (pos < message.length && pos < nextPos + maxDiff) {
        if (
          message.substring(pos, pos + maxTextLineSeparator.length) ===
          maxTextLineSeparator
        ) {
          // include separator to the previous part
          part = message.substring(ind, pos + maxTextLineSeparator.length);
          ind = pos + maxTextLineSeparator.length;
          break;
        }

        pos += 1;
      }

      // no separators near position - split on exact place
      if (part === '') {
        part = message.substring(ind, nextPos);
        ind = nextPos;
      }
    }

    part = part.trim();
    parts.push(part);
  }

  return parts;
}

/**
 * Splits long message into multiple lines
 * tries to separate using maxTextLineSeparator if possible to create user friendly lines.
 * returns prepared JSX.Element
 *
 * @param {string} message string to split
 * @param {number} maxTextLineWidth maximum text line width
 * @param {string} maxTextLineSeparator try to separate on this places if possible
 * @param {number} maxDiff the interval to find separator from the proposed position
 * @return {JSX.Element} prepared element with string parts and <br /> between them
 */
export function separateLongMessage(
  message,
  maxTextLineWidth,
  maxTextLineSeparator,
  maxDiff = 20,
) {
  const parts = separateStringOnParts(
    message,
    maxTextLineWidth,
    maxTextLineSeparator,
    maxDiff,
  );
  const result = parts.reduce((acc, value) => {
    if (!value) {
      return acc;
    }

    // separate parts with <br />
    if (acc.length > 0) {
      acc.push(<br />);
    }

    acc.push(value);
    return acc;
  }, []);

  return result;
}
