import React from 'react';
import { Img } from 'react-image';
import classNames from 'classnames';

import Loading from './loading';

export default function Image(props) {
  return (
    <Img
      loader={
        <div className={classNames(props.className, 'loading-preload')}>
          <Loading />
        </div>
      }
      alt=""
      {...props}
    />
  );
}
