import React from 'react';
import Form from 'components/form';
import View from 'components/view';
import ProductForm from './form';
import { productFeaturesByType } from 'utils/product';
import './product.scss';

export default class ProductNew extends React.PureComponent {
  render() {
    const { errors, onSubmitRecord, onChangeForm, ...props } = this.props;

    const { name } = productFeaturesByType(
      this.props.location.query.type || 'standard',
    );

    return (
      <Form onSubmit={onSubmitRecord} autoFocus={true} onChange={onChangeForm}>
        <View
          detail={true}
          uri={`/products`}
          sectionTitle="Products"
          headerTitle={props.values.name || `New ${name}`}
          headerActions={[{ label: 'Save product', submit: true }]}
          localized={true}
          devtools={
            props.record && {
              model: 'products',
              console: false,
            }
          }
        >
          <ProductForm {...props} errors={errors} />
        </View>
      </Form>
    );
  }
}
