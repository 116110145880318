import React from 'react';
import PropTypes from 'prop-types';
import * as CSV from 'csv-string';
import filesize from 'filesize';
import set from 'lodash/set';

import { formatDate, inflect, getDataFromFile, slugify } from 'utils';

import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import PrevButton from 'components/button/prev';
import NextButton from 'components/button/next';
import FileReader from 'components/form/file-reader';
import BulkProgress from 'components/bulk/progress';
import { FadeIn } from 'components/transitions';

const formatOptions = [
  { value: 'csv', label: 'CSV' },
  { value: 'json', label: 'JSON' },
];

export default class BulkImport extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  static propTypes = {
    bulk: PropTypes.object.isRequired,
    csvFields: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    isOverwriteDefault: PropTypes.bool,
    onClickImport: PropTypes.func.isRequired,
    onSampleImportCount: PropTypes.func.isRequired,
    onSampleImportData: PropTypes.func.isRequired,
    onSampleImportIndex: PropTypes.func.isRequired,
    onSubmitImport: PropTypes.func.isRequired,
    sampleFileCSV: PropTypes.string.isRequired,
    sampleFileJSON: PropTypes.string.isRequired,
    selectable: PropTypes.bool,
  };

  state = {
    values: { format: 'csv' },
    errors: null,
    data: null,
    file: null,
    loading: false,
    uploaded: false,
    sample: {},
    sampleNum: 1,
    sampleIndex: 0,
    sampleNextIndex: null,
    complete: false,
    completedTime: null,
    showingErrorData: {},
  };

  static getDerivedStateFromProps(props, state) {
    if (props.bulk.complete === state.complete) {
      return null;
    }

    // Update completed status and time
    const newState = { complete: props.bulk.complete };
    if (props.bulk.complete && !state.completedTime) {
      newState.completedTime = Date.now();
    }
    return newState;
  }

  onChangeForm = (values) => {
    this.setState((state) => ({
      values: {
        ...state.values,
        ...values,
      },
    }));
  };

  onSubmitForm = () => {
    const { data, values } = this.state;

    this.props.onSubmitImport({ ...values, data });
  };

  onClose = (event) => {
    const {
      bulk: { running },
    } = this.props;
    if (running) {
      event.preventDefault();
      this.context.openModal('Confirm', {
        title: 'Cancel import',
        message: 'Are you sure you want to cancel this import?',
        action: 'Confirm',
        actionType: 'danger',
        onConfirm: () => {
          this.props.onClickImport(event);
          this.context.closeModal();
        },
      });
    } else {
      this.props.onClickImport(event);
    }
  };

  onClickCancel = (event) => {
    const {
      bulk: { running },
    } = this.props;
    if (running) {
      event.preventDefault();
      this.context.openModal('Confirm', {
        title: 'Cancel import',
        message: 'Are you sure you want to cancel this import?',
        action: 'Confirm',
        actionType: 'danger',
        onConfirm: () => {
          this.onClickBack(event);
          this.context.closeModal();
        },
      });
    } else {
      this.onClickBack(event);
    }
  };

  /** @param {Array<string>} headerRow */
  getFieldsInOrder(headerRow) {
    const { csvFields } = this.props;

    const headerSlugCounts = new Map();

    const headerSlugs = headerRow.map((label) => slugify(label));

    headerSlugs.forEach((slug, index) => {
      const count = headerSlugCounts.get(slug) || 0;

      if (count > 0) {
        headerSlugs[index] = `${slug}--${count}`;
      }

      headerSlugCounts.set(slug, count + 1);
    });

    const fieldsBySlug = csvFields.reduce((acc, field) => {
      const slug = slugify(field.label);

      if (acc[slug] === undefined) {
        acc[slug] = field;
      } else {
        let num = 0;
        let nextSlug = slug;

        while (acc[nextSlug]) {
          num += 1;
          nextSlug = `${slug}--${num}`;
        }

        acc[nextSlug] = field;
      }

      return acc;
    }, {});

    return headerSlugs.map((slug) => fieldsBySlug[slug]);
  }

  onUploadFile = (files) => {
    const { values } = this.state;
    const file = files[0];
    const errors = [];
    let data = [];

    this.setState({
      loading: true,
      file: `${file.name} (${filesize(file.size)})`,
    });

    getDataFromFile(file)
      .then(function onfulfilled(result) {
        return Buffer.from(result.data.split(',')[1], 'base64').toString(
          'utf8',
        );
      })
      .then((rawData) => {
        this.setState({ loading: false });

        switch (values.format) {
          case 'csv': {
            try {
              const csvData = CSV.parse(rawData);

              const headerRow = csvData.shift();
              const fieldsInOrder = this.getFieldsInOrder(headerRow);

              data = csvData.map((row) => {
                return fieldsInOrder.reduce((results, field, index) => {
                  if (field) {
                    set(results, field.key, row[index]);
                  }

                  return results;
                }, {});
              });
            } catch (err) {
              errors.push(`Unable to parse CSV data: ${err.message}`);
            }

            break;
          }

          case 'json': {
            try {
              //need flat to make inline array if it json file
              data = rawData
                .replace(/^\s+|\s+$/g, '')
                .split(/\r\n|\n/)
                .map((line) => JSON.parse(line))
                .flat();
            } catch (err) {
              errors.push(`Unable to parse JSON data: ${err.message}`);
            }

            break;
          }

          default:
            break;
        }

        if (errors.length > 0) {
          this.setState({
            data: null,
            errors,
          });

          return;
        }

        this.setState({
          data,
          errors: null,
        });

        this.updateSample();
      });
  };

  onClickUpload = (event) => {
    event.preventDefault();
    if (this.state.file) {
      this.setState({ uploaded: true });
    }
  };

  onClickBack = (event) => {
    event.preventDefault();
    this.setState({ uploaded: false });
    this.props.bulkCancel();
  };

  onClickSampleNext = (event) => {
    event.preventDefault();
    this.setState(
      {
        sampleNum: this.state.sampleNum + 1,
        sampleIndex: this.state.sampleNextIndex,
      },
      () => this.updateSample(),
    );
  };

  onClickSamplePrev = (event) => {
    event.preventDefault();
    const { data, sampleNum, sampleIndex } = this.state;
    const sampleIndexPrev = this.props.onSampleImportIndex(
      data,
      sampleIndex - 1,
    );
    this.setState(
      {
        sampleNum: sampleNum - 1,
        sampleIndex: sampleIndexPrev,
      },
      () => this.updateSample(),
    );
  };

  updateSample() {
    const { onSampleImportData, onSampleImportCount } = this.props;

    const { data, sampleIndex, values } = this.state;

    const sample = {
      count: onSampleImportCount(data, values.format),
      data:
        values.format === 'csv' ? (
          onSampleImportData(data, sampleIndex)
        ) : (
          <pre>{JSON.stringify(data[this.state.sampleIndex], null, 4)}</pre>
        ),
    };

    const sampleNextIndex = this.props.onSampleImportIndex(
      data,
      sampleIndex + 1,
      true,
    );

    this.setState({
      sample,
      sampleNextIndex,
    });
  }

  onClickShowErrorData = (event) => {
    event.preventDefault();
    const index = event.currentTarget.dataset.index;
    this.setState({
      showingErrorData: {
        ...this.state.showingErrorData,
        [index]: !this.state.showingErrorData[index],
      },
    });
  };

  render() {
    const {
      label,
      bulk: { running, complete, percent, message, result },
      sampleFileCSV,
      sampleFileJSON,
      isOverwriteDefault,
    } = this.props;

    const {
      values,
      file,
      errors,
      sample,
      sampleNum,
      sampleIndex,
      sampleNextIndex,
      loading,
      uploaded,
    } = this.state;

    const labelLower = label.toLowerCase();

    const firstStep = !uploaded;

    return (
      <Form
        onSubmit={this.onSubmitForm}
        onChange={this.onChangeForm}
        autoFocus={true}
      >
        <Modal
          title={`Import ${labelLower}`}
          actions={[
            firstStep && {
              label: 'Upload',
              onClick: this.onClickUpload,
              type: file ? 'default' : 'secondary',
            },
            uploaded &&
              (sample.count?.error
                ? {
                    label: 'Back',
                    onClick: this.onClickBack,
                    type: 'default',
                  }
                : !running && {
                    label: 'Back',
                    onClick: this.onClickBack,
                    type: 'secondary',
                    className: 'left button-cancel',
                  }),
            uploaded &&
              !sample.count?.error &&
              !running &&
              !complete && { label: 'Import', type: 'submit' },
            !complete && {
              label: 'Cancel',
              type: 'cancel',
              onClick: running ? this.onClickCancel : this.onClose,
            },
            uploaded &&
              complete && {
                label: 'Close',
                type: 'default',
                onClick: this.onClose,
              },
            firstStep &&
              values.format === 'csv' &&
              sampleFileCSV && {
                component: (
                  <a href={sampleFileCSV} className="note left muted">
                    Download sample CSV file
                  </a>
                ),
              },
            firstStep &&
              values.format === 'json' &&
              sampleFileJSON && {
                component: (
                  <a href={sampleFileJSON} className="note left muted">
                    Download sample JSON file
                  </a>
                ),
              },
          ]}
          width={uploaded && !running && !complete ? 800 : 600}
          cancel={false}
          loading={loading}
          onClose={this.onClose}
        >
          <fieldset>
            <Field
              className={
                uploaded && !errors && !sample.count.error ? 'hidden' : ''
              }
              type="radio"
              name="format"
              options={formatOptions}
              defaultValue={values.format}
              required={true}
            />
            {uploaded && running ? (
              <BulkProgress percent={percent} message={message} />
            ) : uploaded && complete ? (
              <FadeIn
                key="complete"
                active={true}
                transitionAppear={true}
                className="bulk-complete import"
              >
                <div>
                  <strong>
                    Finished importing at{' '}
                    {formatDate(this.state.completedTime, 'time')}
                  </strong>
                  <ul>
                    <li>
                      {inflect(result.counts.succeeded, 'records')} imported
                      successfully
                    </li>
                    <li>
                      {values.overwrite ? (
                        <span>
                          {inflect(result.counts.updated, `existing records`)}{' '}
                          updated
                        </span>
                      ) : (
                        <span>
                          {inflect(result.counts.ignored, `existing records`)}{' '}
                          ignored
                        </span>
                      )}
                    </li>
                    <li>{inflect(result.errors.length, 'error')} occurred</li>
                  </ul>
                  {result.errors.length > 0 && (
                    <div className="bulk-errors-container">
                      <strong className="negative">
                        The following errors occurred
                      </strong>
                      <ul>
                        {result.errors.map((error, i) => (
                          <li key={i}>
                            {error.data.name}
                            <ul>
                              {Object.keys(error.errors).map((key) => (
                                <li key={key}>
                                  {key}: {error.errors[key].message}
                                </li>
                              ))}
                              <li>
                                {this.state.showingErrorData[i] ? (
                                  <div>
                                    <a
                                      href=""
                                      onClick={this.onClickShowErrorData}
                                      data-index={i}
                                      className="muted"
                                    >
                                      Hide data
                                    </a>
                                    <pre>
                                      {JSON.stringify(error.data, null, 2)}
                                    </pre>
                                  </div>
                                ) : (
                                  <a
                                    href=""
                                    onClick={this.onClickShowErrorData}
                                    data-index={i}
                                  >
                                    Show data
                                  </a>
                                )}
                              </li>
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </FadeIn>
            ) : uploaded ? (
              errors ? (
                <div className="bulk-complete import">
                  <p className="negative">
                    There was a problem importing this file:
                  </p>
                  <ul>
                    {errors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              ) : sample.count.error ? (
                <div className="bulk-complete import">{sample.count.error}</div>
              ) : (
                <div>
                  <p>{sample.count.description}</p>
                  <div className="bulk-sample-title row">
                    Sample {labelLower} ({sampleNum} of {sample.count.total})
                    <div className="bulk-sample-nav">
                      <span className="button-group">
                        <PrevButton
                          onClick={this.onClickSamplePrev}
                          type="secondary"
                          size="sm"
                          disabled={sampleIndex <= 0}
                        />
                        <NextButton
                          onClick={this.onClickSampleNext}
                          type="secondary"
                          size="sm"
                          disabled={!sampleNextIndex}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="bulk-sample-data">{sample.data}</div>
                </div>
              )
            ) : (
              <div>
                <FileReader
                  accept="*/*"
                  onFiles={this.onUploadFile}
                  multiple={false}
                >
                  <button
                    className="button button-sm button-default"
                    type="button"
                  >
                    {file ? file : <span>Choose file</span>}
                  </button>
                </FileReader>
                <br />
                {file && (
                  <FadeIn>
                    <Field
                      type="checkbox"
                      name="overwrite"
                      label={`Overwrite existing ${labelLower}`}
                      defaultChecked={isOverwriteDefault}
                    />
                  </FadeIn>
                )}
              </div>
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
