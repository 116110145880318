import React, { Fragment } from 'react';
import Items from './items';

export default function Shipment({ event, items }) {
  const data = event.data;
  const service = data.carrier_name || data.service_name;
  const tracking = data.tracking_code;
  const notes = data.notes;

  function renderService(carrier, service) {
    if (carrier && service) {
      return `${carrier}: ${service}`;
    } else {
      return carrier || service;
    }
  }

  return (
    <Fragment>
      {(service || tracking) && (
        <div className="activity-event-subtitle">
          {service && (
            <Fragment>
              {renderService(data.carrier_name, data.service_name)}&emsp;
            </Fragment>
          )}
          {tracking && `Tracking: ${tracking}`}
        </div>
      )}
      {items && (
        <div className="activity-event-details">
          <Items items={items} showQuantity />
        </div>
      )}
      {notes && (
        <div className="activity-event-subtitle">
          <div className="activity-event-details-note">
            <div className="note body">{notes}</div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
