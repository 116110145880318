import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import actions from 'actions';

import { snakeCase } from 'utils';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';

import NewPage from 'components/pages/attribute/new';

export const mapStateToProps = (state) => ({
  attributes: state.attributes,
  categories: state.categories,
  content: state.content,
  lookup: state.lookup,
  loading: state.attributes.loading,
});

export const mapDispatchToProps = (dispatch) => ({
  loadContentModels() {
    return dispatch(actions.content.loadModels('attributes'));
  },

  createRecord(data) {
    return dispatch(actions.data.createRecord('attributes', data));
  },
});

export class NewAttribute extends React.PureComponent {
  static propTypes = {
    params: pt.object,
    router: pt.object,

    createRecord: pt.func,
    loadContentModels: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
    notifyCreated: pt.func.isRequired,
    notifyError: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      values: {},
      edited: false,
      product: props.params.product,
    };

    this.methods = {
      onChangeForm: this.onChangeForm,
      onSubmitRecord: this.onSubmitRecord,
    };
  }

  componentDidMount() {
    confirmRouteLeave(this);

    const { loadContentModels } = this.props;

    loadContentModels();
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  onChangeForm = (values, edited) => {
    this.setState((state) => ({
      values: { ...state.values, ...values },
      edited,
    }));
  };

  onSubmitRecord = async (values) => {
    const { params, router, createRecord } = this.props;

    if (!values.id) {
      values.id = snakeCase(values.name);
    }

    const result = await createRecord({ ...values });

    if (result.errors) {
      this.context.notifyError(result.errors);
      return;
    }

    this.setState({ edited: false });

    if (params.onCreate) {
      params.onCreate(result);
    } else {
      router.replace('/attributes');
    }

    this.context.notifyCreated(result);
  };

  render() {
    return <NewPage {...this.props} {...this.state} {...this.methods} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAttribute);
