import React from 'react';
import PropTypes from 'prop-types';

import { hasLocalizedContent } from 'utils/content';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';
import { prepareOrderItemFromModalData, makeOrderItem } from 'utils/order';

import { View } from 'components/view';
import { Form, Field } from 'components/form';
import ContentFields from 'components/content/fields';

import OrderItems from '../order/items';
import OrderItemsAdd from '../order/items-add';

import LinkStatus from './link-status';

import '../order/order.scss';

export default class PurchaseLink extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
  };

  state = {
    values: {},
    showAddItem: false,
    addItemProduct: null,
    modalLoading: false,
  };

  componentDidMount() {
    confirmRouteLeave(this);
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  onChangeForm = (values, edited) => {
    this.setState({ values: { ...this.state.values, ...values } });
    this.props.onEdited(edited);
  };

  onSubmitForm = () => {
    this.props.onSubmitRecord(this.state.values);
  };

  onClickAddItem = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState((state) => ({
      showAddItem: !state.showAddItem,
    }));
  };

  onChangeAddProductLookup = (event, value) => {
    this.setState({
      addItemProduct: value || null,
    });
  };

  onSubmitAddItem = async (values) => {
    const { addItemProduct } = this.state;
    const { getItemPrice } = this.props;

    const options = {
      getItemPrice,
      addItemProduct,
    };

    await prepareOrderItemFromModalData(values, options, {
      onLoading: () => {
        this.setState({ modalLoading: true });
      },
      onAddItem: (item) => {
        this.setState({ modalLoading: true });

        const { name, ...values } = this.state.values;

        const data = makeOrderItem(item);

        const record = {
          ...values,
          name: name || `Purchase link ${Date.now().toString(16)}`,
          items: [data],
        };

        return this.props.onSubmitRecord(record).then((result) => {
          if (!result) {
            this.setState({ modalLoading: false });
            return;
          }

          this.setState({
            showAddItem: false,
            addItemProduct: null,
            modalLoading: false,
          });
        });
      },
    });
  };

  render() {
    const {
      loading: loadingProps,
      errors,
      settings,
      content,
      lookup,
      submitting,
      clearErrors,
    } = this.props;
    const { values, modalLoading } = this.state;

    const loading = loadingProps || modalLoading;

    return (
      <div className="order-view view-detail-full">
        <Form
          loading={loading}
          onChange={this.onChangeForm}
          onSubmit={this.onSubmitForm}
          onBeforeValidation={clearErrors}
        >
          <View
            detail={true}
            submitting={submitting}
            uri="/purchase-links"
            sectionTitle="Back"
            headerTitle="New purchase link"
            headerActions={[
              {
                label: 'Save link',
                type: 'default',
                submit: true,
              },
            ]}
            localized={hasLocalizedContent(
              this.props.content.models,
              'purchaselinks',
            )}
          >
            <fieldset>
              <div className="row">
                <Field
                  required
                  type="text"
                  name="name"
                  label="Name"
                  placeholder="Specify a unique name for this purchase link"
                  className="span4"
                  defaultValue={this.state.name}
                  error={errors.name}
                />
              </div>

              <OrderItems
                record={values}
                editable={true}
                settings={settings}
                onClickAddItem={this.onClickAddItem}
              />

              <LinkStatus record={values} settings={settings} disabled={true} />

              <ContentFields
                {...this.props}
                zone="content"
                collection="purchaselinks"
                models={content.models}
                record={values}
                values={values}
                currency={values.currency}
              />
            </fieldset>
          </View>
        </Form>

        {this.state.showAddItem && (
          <OrderItemsAdd
            isCart={true}
            lookup={lookup}
            loading={loading}
            addItemProduct={this.state.addItemProduct}
            onSubmitAddItem={this.onSubmitAddItem}
            onClickAddItem={this.onClickAddItem}
            onChangeAddProductLookup={this.onChangeAddProductLookup}
            getItemPrice={this.props.getItemPrice}
          />
        )}
      </div>
    );
  }
}
