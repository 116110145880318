import React from 'react';
import pt from 'prop-types';

import DateTime from 'components/date-time';

function SubscriptionOrderNext({
  record,
  format = 'short',
  onClickEditOrderDate,
  onClickPause,
}) {
  const {
    paused,
    complete,
    canceled,
    draft,
    date_order_period_start,
    date_order_period_end,
    date_period_end,
    date_pause_end,
  } = record;

  const hasSeparateSchedule = Boolean(record.order_schedule?.interval);
  const hasSeparateLimit = Boolean(record.order_schedule?.limit);

  const schedule = hasSeparateSchedule
    ? record.order_schedule
    : record.billing_schedule;

  const nextDate = hasSeparateSchedule
    ? new Date(date_order_period_start) > new Date()
      ? date_order_period_start
      : date_order_period_end
    : date_period_end;

  if (
    (complete && (!nextDate || !hasSeparateLimit)) ||
    canceled ||
    draft ||
    (paused && !date_pause_end) ||
    (!nextDate &&
      schedule?.limit > 0 &&
      schedule.limit <= schedule.limit_current)
  ) {
    return <span className="muted">&mdash;</span>;
  }

  if (paused && date_pause_end) {
    const datePauseEnd = <DateTime value={date_pause_end} format={format} />;

    if (onClickPause) {
      return (
        <button className="as-link" onClick={onClickPause} type="button">
          {datePauseEnd}
        </button>
      );
    }

    return datePauseEnd;
  }

  const datePeriodNext = nextDate ? (
    <DateTime value={nextDate} format={format} />
  ) : (
    '—'
  );

  if (onClickEditOrderDate) {
    return (
      <button className="as-link" onClick={onClickEditOrderDate} type="button">
        {datePeriodNext}
      </button>
    );
  }

  return datePeriodNext;
}

SubscriptionOrderNext.propTypes = {
  record: pt.object.isRequired,
  format: pt.string,

  onClickEditOrderDate: pt.func,
  onClickPause: pt.func,
};

export default React.memo(SubscriptionOrderNext);
