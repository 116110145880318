export default class PaymentFlow {
  constructor(order) {
    this.order = order;
  }

  customAmounItLockedMessage = 'Custom amount is not availalble';

  allowCustomAmount() {
    return true;
  }
}
