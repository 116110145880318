import React from 'react';

export default class InactiveNotice extends React.Component {
  render() {
    return (
      <div className="nav-inactive-notice">
        This store has been deactivated. Contact{' '}
        <a href="mailto:support@swell.is">support</a> for details.
      </div>
    );
  }
}
