// @flow
import React from 'react';
import ConfirmModal from './Confirm';
import { UNSAVED_CHANGES_WARNING } from 'utils/messages';

export default class ConfirmRouteLeaveModal extends React.PureComponent {
  render() {
    const { onConfirm, onCancel, ...props } = this.props;

    return (
      <ConfirmModal
        title="Unsaved changes"
        message={<p>{UNSAVED_CHANGES_WARNING}</p>}
        action="Confirm"
        actionType="danger"
        cancelText="Keep editing"
        helpLink={false}
        {...props}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    );
  }
}
