import React from 'react';
import pt from 'prop-types';
import { connect } from 'react-redux';
import { get, extend, reduce, pick, find } from 'lodash';

import api from 'services/api';

import actions from 'actions';

import {
  locationWithQuery,
  formatDate,
  isEmpty,
  CURRENCY_CODES,
  objectToArray,
  updateQueryObject,
} from 'utils';
import { filterQueryToWhere } from 'utils/collection';
import { accountName } from 'utils/account';

import View from 'components/view/view';
import CollectionPage from 'components/collection/collection';
import CollectionLoading from 'components/collection/loading';
import { withViewPreferences } from './WithViewPreferences';

const mapStateToProps = (state, { childId, selectable, bulkActions = [] }) => {
  const data = childId
    ? state.data.children[childId] || state.data.initialState()
    : state.data;
  return {
    client: state.client,
    user: state.user,
    collection: data.collection,
    collectionCount: data.collectionCount,
    selection: data.selection,
    selectable: selectable !== undefined ? selectable : bulkActions.length > 0,
    content: state.content,
    categories: state.categories,
    lookup: state.lookup,
    loading: data.loading,
    loadingCollection: state.data.loadingCollection,
  };
};

const mapDispatchToProps = (
  dispatch,
  { model, childId, query = {}, queryCurrency, afterFetch },
) => ({
  fetchCollectionCount: () => {
    return dispatch(
      childId
        ? actions.data.childFetchCollectionCount(
            childId,
            model,
            undefined,
            query,
          )
        : actions.data.fetchCollectionCount(model, undefined, query),
    );
  },

  fetchCollection: async (locationQuery, tabsIn, filtersIn) => {
    const where = { $and: [...get(query, 'where.$and', [])] };
    const tabs = objectToArray(tabsIn);
    const filters = objectToArray(filtersIn);

    if (tabs) {
      const locationTab = find(tabs, { id: locationQuery.tab });
      const defaultTab = find(tabs, { id: 'default' });
      if (locationTab) {
        if (locationTab.query) {
          where.$and.push(locationTab.query);
        }
      } else if (defaultTab) {
        if (defaultTab.query) {
          where.$and.push(defaultTab.query);
        }
      }
    }

    let extraQuery = {};
    if (filters) {
      const { filterWhere, filterQuery } = filterQueryToWhere(
        filters,
        locationQuery,
      );
      if (filterWhere.length > 0) {
        where.$and = where.$and.concat(filterWhere);
        extraQuery.$and = (extraQuery.$and || []).concat(filterWhere);
      }
      if (filterQuery.length > 0) {
        for (let exq of filterQuery) {
          extend(extraQuery, exq);
        }
      }
    }

    if (isEmpty(where.$and)) {
      delete where.$and;
    }

    if (queryCurrency) {
      if (CURRENCY_CODES.length) {
        query.$currency = CURRENCY_CODES;
      } else {
        delete query.$currency;
      }
    }

    const dataQuery = {
      ...query,
      ...extraQuery,
      page: locationQuery.page,
      search: locationQuery.search || query.search,
      limit: locationQuery.limit || query.limit,
      sort: locationQuery.sort || query.sort,
      where: {
        ...(locationQuery.where || {}),
        ...(query.where || {}),
        ...where,
      },
    };

    const fetchArgs = [model, dataQuery, locationQuery, extraQuery];
    const result = await dispatch(
      childId
        ? actions.data.childFetchCollection(childId, ...fetchArgs)
        : actions.data.fetchCollection(...fetchArgs),
    );

    if (afterFetch) {
      await afterFetch(result);
    }

    return result;
  },

  selectAll: () =>
    dispatch(
      childId ? actions.data.childSelectAll(childId) : actions.data.selectAll(),
    ),
  selectOne: (id, isRange) =>
    dispatch(
      childId
        ? actions.data.childSelectOne(childId, id, isRange)
        : actions.data.selectOne(id, isRange),
    ),
  selectClear: () =>
    dispatch(
      childId
        ? actions.data.childSelectClear(childId)
        : actions.data.selectClear(),
    ),
  setLoading: (is) =>
    dispatch(
      childId
        ? actions.data.childLoading(childId, is)
        : actions.data.loading(is),
    ),

  loadCategories: () => {
    return dispatch(actions.categories.load());
  },
});

class Collection extends React.Component {
  static propTypes = {
    model: pt.string.isRequired,
    child: pt.bool,
    page: pt.func,
    query: pt.object,
    locationQuery: pt.object,
    router: pt.object,
    title: pt.string,
    headerTitle: pt.string,
    component: pt.object,
    emptyTitle: pt.any,
    emptyDescription: pt.any,
    emptyComponent: pt.func,
    collection: pt.object,
    collectionCount: pt.number,
    loadingCollection: pt.bool,
    bulkActions: pt.array,
    tabs: pt.oneOfType([pt.object, pt.array]),
    filters: pt.oneOfType([pt.object, pt.array]),

    selectAll: pt.func.isRequired,
    selectOne: pt.func.isRequired,
    selectClear: pt.func.isRequired,
    setLoading: pt.func.isRequired,
    loadCategories: pt.func.isRequired,
    fetchCollection: pt.func.isRequired,
    fetchCollectionCount: pt.func.isRequired,
  };

  static contextTypes = {
    client: pt.object.isRequired,
    user: pt.object.isRequired,
    openModal: pt.func.isRequired,
    setLastUrl: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      search: null,
      filters: objectToArray(props.filters),
      filterKeys: null,
      filterOp: null,
      filterValue: null,
      filterValues: null,
      filterListVisible: false,
      collectionEmpty: !props.collectionCount,
      onSubmitSearch: this.onSubmitSearch.bind(this),
      onChangeSearch: this.onChangeSearch.bind(this),
      onClickShowFilterList: this.onClickShowFilterList.bind(this),
      onClickApplyFilter: this.onClickApplyFilter.bind(this),
      onClickRemoveFilter: this.onClickRemoveFilter.bind(this),
      onClickSelectAll: this.onClickSelectAll.bind(this),
      onClickSelectClear: this.onClickSelectClear.bind(this),
      onClickSelectRow: this.onClickSelectRow.bind(this),
      onClickSort: this.onClickSort.bind(this),
      onClickPage: this.onClickPage.bind(this),
      onChangeLimit: this.onChangeLimit.bind(this),
      onClickBulkAction: this.onClickBulkAction.bind(this),
    };
  }

  componentDidMount() {
    const { loadCategories, fetchCollectionCount } = this.props;

    // Load categories and storefront first to support location filtering
    Promise.all([loadCategories()])
      .then(() => {
        return Promise.all([fetchCollectionCount(), this.fetchCollection()]);
      })
      .then(() => {
        this.context.setLastUrl(null);
      });
  }

  async componentDidUpdate(prevProps) {
    const { location, locationQuery, fetchCollectionCount } = this.props;

    const isCollectionChanged =
      location && location.pathname !== prevProps.location.pathname;

    const query = locationQuery || location.query;
    const prevQuery = prevProps.locationQuery || prevProps.location.query;
    const isQueryChanged = query !== prevQuery;

    if (isCollectionChanged) {
      this.setState({ loaded: false, filterListVisible: false });
      await Promise.all([fetchCollectionCount(), this.fetchCollection()]);
    } else if (isQueryChanged) {
      const filters = await this.getFiltersHydrated();
      this.setState({ filterListVisible: false, filters });
      await this.fetchCollection();
    }

    // Mark as loaded when collection is done initially loading
    if (
      !this.state.loaded &&
      prevProps.loadingCollection &&
      !this.props.loadingCollection &&
      this.props.collection
    ) {
      const filters = await this.getFiltersHydrated();
      this.setState({ loaded: true, filters });
    }

    if (this.props.collectionCount !== prevProps.collectionCount) {
      this.setState({
        collectionEmpty: !this.props.collectionCount,
      });
    }

    if (this.props.filters !== prevProps.filters) {
      const filters = await this.getFiltersHydrated();
      this.setState({ filters });
    }
  }

  async fetchCollection() {
    const { fetchCollection } = this.props;
    const locationQuery = await this.locationQueryWithSearchHandler();

    return await fetchCollection(
      locationQuery,
      this.props.tabs,
      this.state.filters,
    );
  }

  async locationQueryWithSearchHandler() {
    const { location, locationQuery, searchHandler } = this.props;

    const query = locationQuery || location.query;

    if (searchHandler && query.search) {
      const searchQuery = await searchHandler(query.search, query.limit || 50);

      return {
        ...query,
        where: searchQuery,
        search: undefined,
      };
    } else {
      return query;
    }
  }

  async getFiltersHydrated() {
    // Execute in a microtask so that the location object has time to be modified in App.js
    return await Promise.resolve(this.props).then(() => this.hydrateFilters());
  }

  async hydrateFilters() {
    const {
      location,
      locationQuery,
      categories,
      filters: filtersProp,
    } = this.props;
    const query = locationQuery || location.query;

    if (!filtersProp) {
      return;
    }

    const filters = objectToArray(filtersProp);

    for (const [key, value] of Object.entries(query)) {
      const filter = find(filters, { id: key });

      if (filter) {
        switch (filter.type) {
          case 'LookupCategory': {
            const category = categories.index.get(value);

            filter.valueLabel = `'${category ? category.name : value}'`;

            break;
          }

          case 'LookupCustomer': {
            const account = await this.fetchCustomer(value);

            filter.valueLabel = `'${account ? accountName(account) : value}'`;

            break;
          }

          case 'LookupProduct': {
            const product = await this.fetchProduct(value);

            filter.valueLabel = `'${product ? product.name : value}'`;

            break;
          }

          case 'date': {
            filter.valueLabels = {
              after: formatDate(value.after, 'short'),
              before: formatDate(value.before, 'short'),
            };

            break;
          }

          default:
            break;
        }
      }
    }

    return filters;
  }

  async fetchCustomer(id) {
    const { setLoading } = this.props;
    setLoading(true);
    const account = await api.get(`/data/accounts/{id}`, { id });
    setLoading(false);
    return account;
  }

  async fetchProduct(id) {
    const { setLoading } = this.props;
    setLoading(true);
    const product = await api.get(`/data/products/{id}`, { id });
    setLoading(false);
    return product;
  }

  updateLocationQuery(update, replace = false) {
    const { router, onQuery, location, locationQuery = {} } = this.props;

    if (onQuery) {
      const nextQuery = updateQueryObject(locationQuery, update);
      onQuery(nextQuery);
    } else {
      const nextUri = locationWithQuery(location, update);
      replace ? router.replace(nextUri) : router.push(nextUri);
    }
  }

  onSubmitSearch({ search }) {
    this.updateLocationQuery({ search, page: 1 });
    if (
      this.state.filterListVisible &&
      (this.state.filterValue || this.state.filterValues)
    ) {
      this.applyFilter();
    }
  }

  onClickShowFilterList(event) {
    event.preventDefault();

    this.setState((state) => ({
      filterListVisible: !state.filterListVisible,
    }));
  }

  onChangeSearch({ filterKeys, filterOp, filterValue, filterValues }) {
    this.setState({
      filterKeys: { ...filterKeys },
      filterOp,
      filterValue,
      filterValues,
    });
  }

  onClickSort(event) {
    event.preventDefault();
    const { field, dir, sorted } = event.currentTarget.dataset;

    this.updateLocationQuery(
      {
        sort: `${field} ${sorted > 0 && dir === 'desc' ? 'asc' : 'desc'}`,
      },
      true,
    );
  }

  applyFilter() {
    const { location, locationQuery } = this.props;
    const { filterKeys, filterValue, filterValues } = this.state;

    const nextProps = reduce(
      filterKeys,
      (acc, active, key) => {
        if (active) {
          if (filterValues && !isEmpty(filterValues[key])) {
            acc[key] = filterValues[key];
          } else if (filterValue && filterValue[key] !== undefined) {
            acc[key] =
              filterValue[key] && filterValue[key].id
                ? filterValue[key].id
                : filterValue[key];
          }
        }

        return acc;
      },
      {},
    );

    const query = pick(locationQuery || location.query, [
      'search',
      'page',
      'limit',
      'tab',
    ]);

    if (query.page === '1') {
      delete query.page;
    }

    if (query.limit === '50') {
      delete query.limit;
    }

    this.updateLocationQuery({ ...nextProps, ...query });
  }

  onClickApplyFilter(event) {
    event.preventDefault();
    this.applyFilter();
  }

  onClickRemoveFilter(event) {
    event.preventDefault();
    const filterKey = event.currentTarget.dataset.key;
    this.updateLocationQuery({ [filterKey]: undefined });
  }

  onClickSelectAll(event) {
    event.preventDefault();
    this.props.selectAll();
  }

  onClickSelectRow(event) {
    event.preventDefault();
    this.props.selectOne(event.currentTarget.dataset.id, event.shiftKey);
    if (window.getSelection) {
      // All browsers, except IE <=8
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      // IE <=8
      document.selection.empty();
    }
  }

  onClickSelectClear(event) {
    event.preventDefault();
    this.props.selectClear();
  }

  onClickPage(event) {
    event.preventDefault();
    this.updateLocationQuery({ page: event.currentTarget.dataset.page });
  }

  onChangeLimit(_event, value) {
    if (!this.state.firstChanged) {
      this.setState({ firstChanged: true });
    } else {
      try {
        window.localStorage.setItem(`${this.props.model}_per_page`, value);
      } catch (err) {
        console.error(err);
      }

      this.updateLocationQuery({
        limit: value,
        page: 1,
      });
    }
  }

  onClickBulkAction(event) {
    event.preventDefault();
    const { bulkActions } = this.props;
    const index = event.currentTarget.dataset.action;
    const action = bulkActions[index];

    if (action.modal) {
      const params = {
        ...action.params,
        onComplete: async () => {
          const { fetchCollectionCount, fetchCollection } = this.props;

          await Promise.all([
            fetchCollectionCount(),
            this.locationWithSearchHandler(this.props).then(fetchCollection),
          ]);

          return action.params?.onComplete();
        },
      };

      this.context.openModal(action.modal, params);
    } else if (action.onClick) {
      action.onClick(event);
    }
  }

  render() {
    const { component, title, headerTitle, tabs, view } = this.props;
    const { loaded, collectionEmpty } = this.state;
    const { client } = this.context;

    if (!loaded) {
      return <CollectionLoading />;
    }

    if (component) {
      return <component {...this.props} {...this.state} />;
    }

    return (
      <div className="collection">
        <View
          {...this.props}
          {...this.state}
          headerTitle={headerTitle !== undefined ? headerTitle : title}
          tabs={collectionEmpty ? null : tabs}
          app={view?.app}
          withPreferences={true}
        >
          <CollectionPage {...this.props} {...this.state} apps={client.apps} />
        </View>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withViewPreferences({ id: 'list', type: 'list' }, Collection));
