import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { connect } from 'react-redux';
import NewPage from 'components/model/new';
import actions from 'actions';
import { cleanModelFields } from 'utils/content';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';

export const mapStateToProps = (state) => ({
  client: state.client,
  content: state.content,
  loading: state.content.loading,
  lookup: state.lookup,
  categories: state.categories,
});

export const mapDispatchToProps = (dispatch) => ({
  createRecord: (data) => {
    return dispatch(actions.content.createCollectionModel(data));
  },

  loadCategories: () => {
    return dispatch(actions.categories.load());
  },

  loadContentCollections: () => {
    return dispatch(actions.content.loadCollections());
  },

  fetchModels: () => {
    return dispatch(actions.content.fetchModels());
  },
});

export class NewModel extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    notifyCreated: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  static propTypes = {
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    loadCategories: PropTypes.func.isRequired,
    loadContentCollections: PropTypes.func.isRequired,
    createRecord: PropTypes.func.isRequired,
    fetchModels: PropTypes.func.isRequired,
  };

  state = {
    values: {},
    edited: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      values: {},
      edited: false,
      product: props.params.product,
      onChangeForm: this.onChangeForm.bind(this),
      onSubmitRecord: this.onSubmitRecord.bind(this),
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { loadCategories, loadContentCollections } = this.props;

    confirmRouteLeave(this);

    loadCategories();
    loadContentCollections();
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  onChangeForm(values, edited) {
    this.setState({
      values: { ...values, json: undefined },
      edited,
    });
  }

  async onSubmitRecord(values) {
    const { router, createRecord, fetchModels } = this.props;

    if (!values.collection) {
      values.collection = kebabCase(values.label);
    }

    const result = await createRecord({
      ...values,
      fields: cleanModelFields(values.fields),
    });

    if (!result) {
      return;
    } else if (result.errors) {
      this.context.notifyError(result.errors);
      return;
    }

    // Reset content models after creating
    await fetchModels();

    this.setState({ edited: false });
    router.replace(
      `/settings/models/com.${result.collection.replace('/', '_')}`,
    );
    this.context.notifyCreated(result);
  }

  render() {
    return <NewPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewModel);
