import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import View from 'components/view';
import SSHKeys from './ssh-keys';
import SSHKeyForm from './ssh-key-form';
import ThemeSupportAlert from './theme-support-alert';
import { Field, Form } from 'components/form';
import Modal from 'components/modal/modal';
import { getStorefrontURL } from 'utils/storefront';
import APIKeys from 'components/settings/api-keys';
import Alert from 'components/alert/alert';

export default class DeveloperTools extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  state = {
    showAddSSHKeyModal: false,
    showNewKeyModal: false,
  };

  onClickNewKey = (event) => {
    event.preventDefault();
    this.setState({
      showNewKeyModal: true,
    });
  };

  onCloseNewKey = () => {
    this.setState({ showNewKeyModal: false });
  };

  onSubmitKeyForm = async (values) => {
    const { record: storefront, onSaveAPIKey } = this.props;

    const success = await onSaveAPIKey(null, {
      ...values,
      public: true,
      scope: 'app',
      source_model: 'storefronts',
      source_id: storefront.id,
    });

    if (success && !success.error) {
      this.setState({ showNewKeyModal: false });
      this.context.notifySuccess(`Public key created`);
    }
  };

  renderNewKeyModal = () => {
    const { loading } = this.props;

    return (
      <Form onSubmit={this.onSubmitKeyForm} autoFocus={true}>
        <Modal
          title="New storefront public key"
          width={600}
          actions={[{ label: 'Create key', type: 'submit' }]}
          className="modal-confirm"
          loading={loading}
          onClose={this.onCloseNewKey}
        >
          <Field
            type="textarea"
            label="Key description"
            name="description"
            autoSize={true}
            rows={3}
            placeholder="Optional"
          />
        </Modal>
      </Form>
    );
  };

  onClickAddSSHKey = (event) => {
    event.preventDefault();
    this.setState({ showAddSSHKeyModal: true });
  };

  onCloseSSHKeyForm = () => {
    this.setState({ showAddSSHKeyModal: false });
  };

  onSubmitSSHKeyForm = (values) => {
    this.props.onCreateSSHKey(values).then((success) => {
      if (success) {
        this.setState({ showAddSSHKeyModal: false });
      }
    });
  };

  render() {
    const {
      viewProps = {},
      record,
      loading,
      onSaveAPIKey,
      onRemoveAPIKey,
    } = this.props;

    const { showAddSSHKeyModal, showNewKeyModal } = this.state;

    const storefrontURL = getStorefrontURL(record);

    return (
      <Fragment>
        <View
          {...viewProps}
          headerActions={[
            ...viewProps.headerActions,
            {
              label: 'View storefront',
              type: 'default',
              link: storefrontURL,
              target: 'blank',
              disabled: !storefrontURL,
            },
          ]}
          form={this.refs.form}
        >
          {record.hosted && <ThemeSupportAlert />}

          <Alert>
            <code>
              Storefront ID: <strong>{record.id}</strong>
            </code>
          </Alert>

          <fieldset className="full">
            <APIKeys
              loading={loading}
              keys={record.keys?.results}
              onSaveKey={onSaveAPIKey}
              onRemoveKey={onRemoveAPIKey}
              source="storefront"
              showPublic={true}
              showSecret={true}
            />
          </fieldset>
          {record.hosted ? (
            <fieldset className="full">
              <SSHKeys {...this.props} />
            </fieldset>
          ) : (
            <Fragment />
          )}
        </View>
        {showAddSSHKeyModal && (
          <SSHKeyForm
            {...this.props}
            loading={this.props.loadingAny}
            onSubmitForm={this.onSubmitSSHKeyForm}
            onCloseModal={this.onCloseSSHKeyForm}
          />
        )}
        {showNewKeyModal && this.renderNewKeyModal()}
      </Fragment>
    );
  }
}
