import React from 'react';
import { connect } from 'react-redux';

import {
  ViewOrder,
  mapStateToProps as orderMapStateToProps,
  getMapDispatchToProps,
} from 'containers/ViewOrder';

import ViewPage from 'components/pages/cart/view';

const mapStateToProps = orderMapStateToProps;

const mapDispatchToProps = getMapDispatchToProps('carts');

export class ViewCart extends React.PureComponent {
  render() {
    return <ViewOrder {...this.props} page={ViewPage} isCart={true} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCart);
