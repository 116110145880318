// @flow
import React from 'react';

export class ModelExplorer extends React.PureComponent {
  render() {
    return (
      <iframe
        src="/admin/model-explorer/client/"
        width="100%"
        style={{ height: 'calc(100vh - 7px)', paddingBottom: 0 }}
        frameBorder="0"
      />
    );
  }
}

export default ModelExplorer;
