import React, { Fragment } from 'react';
import { get, find } from 'lodash';

import { formatCurrency } from 'utils';
import {
  productPrice,
  productSalePrice,
  subOptionValueIntervalDesc,
} from 'utils/product';

export default function SubscriptionPrice({
  product,
  variant,
  currency,
  quantity = 1,
  price = undefined,
  purchaseOption = undefined,
}) {
  let productVariant = variant;
  let interval = product.subscription_interval || 'monthly';
  let intervalCount = 1;

  // TODO: billing_schedule vs order_schedule
  if (purchaseOption) {
    interval = get(purchaseOption, 'billing_schedule.interval', interval);
    intervalCount = get(
      purchaseOption,
      'billing_schedule.interval_count',
      intervalCount,
    );
  } else {
    const productPurchaseOption =
      product && get(product, 'purchase_options.subscription');
    if (productPurchaseOption) {
      interval = get(
        productPurchaseOption,
        'plans[0].billing_schedule.interval',
        interval,
      );
      intervalCount = get(
        productPurchaseOption,
        'plans[0].billing_schedule.interval_count',
        intervalCount,
      );
    }
  }

  // Deprecated subscription option
  const option = product && find(product.options, { subscription: true });
  if (option) {
    let optionValue;
    if (variant) {
      optionValue =
        option.values &&
        find(
          option.values,
          (value) =>
            variant.option_value_ids &&
            variant.option_value_ids.indexOf(value.id) >= 0,
        );
    }
    if (!optionValue) {
      optionValue = option.values && option.values[0];
    }
    if (optionValue) {
      interval = optionValue.subscription_interval || interval;
      intervalCount = optionValue.subscription_interval_count || intervalCount;
      if (!variant) {
        productVariant =
          product.variants &&
          find(
            product.variants.results,
            (variant) =>
              variant.option_value_ids &&
              variant.option_value_ids.indexOf(optionValue.id) >= 0,
          );
      }
    }
  }
  if (productVariant) {
    if (productVariant.subscription_interval) {
      interval = productVariant.subscription_interval;
    }
    if (productVariant.subscription_interval_count) {
      intervalCount = productVariant.subscription_interval_count;
    }
  }

  const regPrice =
    price !== undefined ? price : productPrice(product, productVariant);
  const salePrice = productSalePrice(product, productVariant);
  const sale = product.sale || (variant && variant.sale);
  const isSale = !Number.isNaN(salePrice) && sale;
  const actualCurrency = currency || product.currency;

  const intervalDesc =
    interval && intervalCount > 1
      ? subOptionValueIntervalDesc({
          subscription_interval: interval,
          subscription_interval_count: intervalCount,
        })
      : interval;
  const isSubscription = get(purchaseOption, 'type') === 'subscription';

  return (
    <span className="nowrap">
      {isSale && !isSubscription ? (
        <Fragment>
          <strike>{formatCurrency(regPrice * quantity, actualCurrency)}</strike>
          &nbsp;
          <span className="positive">
            {formatCurrency(salePrice * quantity, actualCurrency)}/
            {intervalDesc}
          </span>
        </Fragment>
      ) : (
        <Fragment>
          {formatCurrency(regPrice * quantity, actualCurrency)}/{intervalDesc}
        </Fragment>
      )}
    </span>
  );
}
