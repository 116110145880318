import React from 'react';
import LogoTopLeft from 'components/logo';
import Flash from 'components/flash';
import './unsubscribed.scss';

export default function UnsubscribedPage({ flash }) {
  return (
    <div className="container">
      <LogoTopLeft />
      <Flash {...flash} />
      <div className="wrapper">
        <div className="text">
          <h1>You've been unsubscribed from these emails</h1>
        </div>
      </div>
    </div>
  );
}
