import React, { Fragment } from 'react';
import { CSSTransitionGroup } from 'react-css-transition';
import { Draggable, Droppable } from 'react-drag-and-drop';
import { find, uniqBy, last, map, get } from 'lodash';
import pt from 'prop-types';

import { imageUrl, classNames } from 'utils';

import Modal from 'components/modal';
import Image from 'components/loading/image';
import { FadeIn } from 'components/transitions';
import Icon from 'components/icon';

import Label from './label';
import FileReader from './file-reader';
import FileDropzone from './file-dropzone';
import ImageCropper from './image-cropper';

const { PUBLIC_URL } = process.env;

const droppableTypes = Object.freeze(['image']);

export default class InputImages extends React.PureComponent {
  static propTypes = {
    id: pt.string,
    name: pt.string,
    help: pt.oneOfType([pt.string, pt.object]),
    label: pt.oneOfType([pt.string, pt.object]),
    typeLabel: pt.string,
    placeholder: pt.string,
    tiny: pt.bool,
    wide: pt.bool,
    single: pt.bool,
    minimal: pt.bool,
    fullSize: pt.bool,
    disabled: pt.bool,
    value: pt.any,
    defaultValue: pt.any,
    maxWidth: pt.number,
    maxHeight: pt.number,
    minWidth: pt.number,
    minHeight: pt.number,
    imageWidth: pt.number,
    imageHeight: pt.number,
    readonlyContent: pt.bool,

    onChange: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
    uploadImages: pt.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      dragSourceId: null,
      removedFileIds: new Set(),
      sortOrder: [],
      uploading: false,
      uploadedImages: [],
      enlargedImage: null,
      croppedImage: null,
    };
  }

  setValue(uploadedImages = []) {
    const { single, value, defaultValue } = this.props;
    const { sortOrder, removedFileIds } = this.state;

    const newValue = [
      ...(single ? [value || defaultValue] : value || defaultValue || []),
      ...uploadedImages,
    ]
      .filter((x) => Boolean(x))
      .map((image) => {
        const file = image.file || image || {};
        return {
          ...image,
          file: {
            id: file.id,
            date_uploaded: file.date_uploaded,
            length: file.length,
            md5: file.md5,
            filename: file.filename,
            content_type: file.content_type,
            metadata: file.metadata,
            url: file.url,
            uploaded_url: file.uploaded_url,
            width: file.width,
            height: file.height,
          },
        };
      })
      .filter((image) => !removedFileIds.has(image.file.id));

    if (single) {
      return last(newValue);
    }

    if (sortOrder.length > 0) {
      const orderMap = new Map(sortOrder.map((id, i) => [id, i]));

      newValue.sort((a, b) => {
        const indexA = orderMap.get(a.file.id);
        const indexB = orderMap.get(b.file.id);

        if (indexA > indexB) return 1;
        if (indexA === undefined) return 1;
        return -1;
      });

      return newValue;
    }

    return uniqBy(newValue, (image) => image.file.id);
  }

  triggerChange(uploadedImages) {
    const event = new Event('change', { bubbles: true });
    this.refs.input.dispatchEvent(event);
    this.props.onChange(event, this.setValue(uploadedImages));
  }

  /** @param {FileList} files */
  onUploadImages = (files) => {
    this.setState({ uploading: true });

    this.context.uploadImages(files).then((results) => {
      if (!results) {
        return;
      }

      const uploadedImages = results.reduce((images, result) => {
        if (result && result.id) {
          images.push({ file: result });
        }
        return images;
      }, []);

      this.setState(
        (state) => {
          state.uploadedImages.push(...uploadedImages);

          return {
            uploadedImages: state.uploadedImages,
            uploading: false,
          };
        },
        () => this.triggerChange(this.state.uploadedImages),
      );
    });
  };

  onClickRemove = (event) => {
    event.preventDefault();

    const fileId = event.currentTarget.dataset.fileid;

    this.context.openModal('Confirm', {
      title: 'Remove image',
      message: <p>Are you sure you want to remove this image?</p>,
      action: 'Remove',
      actionType: 'danger',
      onConfirm: () => {
        this.setState(
          ({ removedFileIds }) => {
            removedFileIds.add(fileId);

            return { removedFileIds };
          },
          () => this.triggerChange(),
        );
      },
    });
  };

  onClickEnlarge = (event) => {
    event.preventDefault();
    const { id } = event.currentTarget.dataset;
    const { value, single = false } = this.props;
    const enlargedImage = single
      ? value
      : find(value, (img) => img.file.id === id);
    if (enlargedImage && enlargedImage.file && enlargedImage.file.url) {
      this.setState({ enlargedImage });
    }
  };

  onClickCrop = (event) => {
    event.preventDefault();
    const { id } = event.currentTarget.dataset;
    const { value, single = false } = this.props;
    const croppedImage = single
      ? value
      : find(value, (img) => img.file.id === id);
    if (croppedImage && croppedImage.file && croppedImage.file.url) {
      this.setState({ croppedImage });
    }
  };

  onCloseEnlarged = () => {
    this.setState({ enlargedImage: null });
  };

  onCloseCropped = () => {
    this.setState({ croppedImage: null });
  };

  onSubmitCropper = (file, params) => {
    this.setState({ uploading: true });

    this.context.uploadImages([file]).then((results) => {
      const image = { ...params, file: results[0] };

      if (!get(image, 'file.id')) {
        return this.setState({ uploading: false }, this.onCloseCropped);
      }

      this.setState(
        (state, props) => {
          const { croppedImage, uploadedImages, removedFileIds } = state;
          const croppedFileId = get(croppedImage, 'file.id');

          uploadedImages.push(image);
          removedFileIds.add(croppedFileId);

          return {
            uploadedImages,
            removedFileIds,
            ...(Array.isArray(props.value)
              ? {
                  sortOrder: map(props.value, (value) =>
                    value.file.id === croppedFileId
                      ? image.file.id
                      : value.file.id,
                  ),
                }
              : undefined),
            uploading: false,
          };
        },
        () => {
          this.triggerChange(this.state.uploadedImages);
          this.onCloseCropped();
        },
      );
    });
  };

  onDragStart = (event) => {
    event.stopPropagation();
    if (this.props.single) {
      event.preventDefault();
      return;
    }
    const dragSourceId = event.currentTarget.dataset.fileid;
    this.setState({ dragSourceId });
    event.dataTransfer.effectAllowed = 'move';
  };

  onDragEnd = (event) => {
    event.stopPropagation();
    this.setState({ dragSourceId: null });
  };

  onDragEnter = (event) => {
    event.stopPropagation();
    const dragTargetId = event.currentTarget.dataset.fileid;
    if (this.state.dragSourceId === dragTargetId) return;
    this.setSortOrder(this.state.dragSourceId, dragTargetId);
  };

  onDragLeave = (event) => {
    event.stopPropagation();
  };

  setSortOrder(sourceId, targetId) {
    const sortOrder = this.props.value.map((image) => image.file.id);

    const sourceIndex = this.props.value.findIndex(
      (image) => image.file.id === sourceId,
    );

    const targetIndex = this.props.value.findIndex(
      (image) => image.file.id === targetId,
    );

    sortOrder.splice(targetIndex, 0, sortOrder.splice(sourceIndex, 1)[0]);

    this.setState({ sortOrder }, () => this.triggerChange());
  }

  getPlaceholder() {
    const { placeholder, single, tiny } = this.props;

    if (placeholder) {
      return this.getCustomPlaceholder(placeholder);
    } else if (tiny) {
      return <Icon type="image" />;
    }

    return (
      <Fragment>
        {`Add image${!single ? 's' : ''}`}
        <div className="muted">Drag & drop to upload</div>
      </Fragment>
    );
  }

  getCustomPlaceholder(placeholder) {
    switch (placeholder) {
      case 'favicon':
        return this.getFaviconPlaceholder();
      default:
        return <Icon type="image" />;
    }
  }

  getFaviconPlaceholder() {
    return (
      <img
        className="form-images-favicon-placeholder"
        src={`${PUBLIC_URL}/favicon.png`}
        alt="favicon"
      />
    );
  }

  render() {
    const {
      id,
      value,
      label,
      typeLabel,
      help,
      single = false,
      minimal = false,
      tiny = false,
      wide = false,
      fullSize = true,
      maxWidth,
      maxHeight,
      minWidth,
      minHeight,
      imageWidth,
      imageHeight,
      readonlyContent,
      ...props
    } = this.props;

    const { uploading, enlargedImage, croppedImage } = this.state;

    let values;
    if (single) {
      values = value ? [value] : [];
    } else {
      if (Array.isArray(value)) {
        values = value;
      } else {
        // for multiloading is enabled in the New Field preview
        values = value ? [value] : [];
      }
    }

    const noSize =
      (maxWidth || maxHeight || minWidth || minHeight) !== undefined;

    const images = values
      .filter((x) => Boolean(x && x.file))
      .map((image) => ({
        id: image.file.id,
        width: maxWidth || image.file.width > 100 ? image.file.width : 100,
        height:
          image.file.height > 100
            ? maxWidth
              ? (maxWidth / image.file.width) * image.file.height
              : image.file.height
            : 100,
        url: imageUrl(image, {
          width: imageWidth ? imageWidth * 2 : undefined,
          height: imageHeight ? imageHeight * 2 : undefined,
          padded: true,
        }),
        file: image.file,
      }));

    return (
      <div
        className={classNames('form-images', {
          single,
          minimal,
          tiny,
          empty: images.length <= 0,
          [this.props.name]: this.props.name,
        })}
      >
        <Label label={label} help={help} htmlFor={id} />

        <div
          className={classNames('form-field-input', {
            wide: wide,
            uploading: uploading,
            dragging: Boolean(this.state.dragSourceId),
          })}
          style={{
            maxWidth,
            maxHeight,
            minWidth: minWidth || 0,
            minHeight: minHeight || (images.length > 0 ? 0 : undefined),
          }}
        >
          <div className="form-images-emptystate">
            <FadeIn active={!images.length} transitionAppear={false}>
              <FileDropzone
                className="form-images-dropzone"
                acceptClassName="form-images-dropzone-accepted"
                rejectClassName="form-images-dropzone-rejected"
                accept="image/*"
                onDrop={this.onUploadImages}
                multiple={!single}
              >
                <div className="form-images-upload">
                  {this.getPlaceholder()}
                </div>
              </FileDropzone>
            </FadeIn>
          </div>

          <ul className="form-images-list">
            <CSSTransitionGroup>
              {images.map((image, index) => (
                <FadeIn key={image.file.id} active={true}>
                  <li
                    className={classNames({
                      dragging: this.state.dragSourceId === image.file.id,
                      default: index === 0,
                      nosize: noSize,
                    })}
                    data-index={index}
                    style={{
                      ...(fullSize
                        ? {
                            maxWidth,
                            maxHeight,
                            minWidth: minWidth
                              ? minWidth - 12
                              : 'calc(100% - 11px)',
                            minHeight: minHeight
                              ? minHeight - 12
                              : fullSize
                              ? image.height
                              : undefined,
                          }
                        : undefined),
                    }}
                  >
                    <Droppable
                      types={droppableTypes}
                      data-fileid={image.file.id}
                      onDragEnter={this.onDragEnter}
                      onDragLeave={this.onDragLeave}
                    >
                      <Draggable
                        type="image"
                        data-fileid={image.file.id}
                        onDragStart={this.onDragStart}
                        onDragEnd={this.onDragEnd}
                        className={!single ? 'draggable' : ''}
                      >
                        <div className="form-images-image">
                          <Image src={image.url} />
                        </div>

                        <div className="form-images-controls">
                          <button
                            data-fileid={image.file.id}
                            aria-label="Remove image"
                            onClick={this.onClickRemove}
                            type="button"
                          >
                            <i className="fa fa-trash" />
                          </button>

                          {!tiny && (
                            <Fragment>
                              <button
                                data-id={image.id}
                                aria-label="Enlarge image"
                                onClick={this.onClickEnlarge}
                                type="button"
                              >
                                <i className="fa fa-search" />
                              </button>

                              <button
                                data-id={image.id}
                                aria-label="Crop image"
                                onClick={this.onClickCrop}
                                type="button"
                              >
                                <i className="far fa-crop-alt" />
                              </button>
                            </Fragment>
                          )}
                        </div>
                      </Draggable>
                    </Droppable>

                    <div className="form-images-mask" />
                  </li>
                </FadeIn>
              ))}
            </CSSTransitionGroup>
          </ul>
          <FadeIn active={images.length > 0}>
            <FileReader
              id={id}
              accept="image/*"
              onFiles={this.onUploadImages}
              multiple={!single}
            >
              <button
                className="button button-sm button-secondary form-images-addlink view-link"
                type="button"
              >
                {single ? 'Replace image' : 'Add images'}
              </button>
            </FileReader>
          </FadeIn>

          <input
            {...props}
            ref="input"
            type="hidden"
            defaultValue={undefined}
            value=""
          />
        </div>

        {enlargedImage && (
          <Modal
            onClose={this.onCloseEnlarged}
            chromeless={true}
            cancel={false}
          >
            <div className="form-images-enlarged">
              <Image src={enlargedImage.file.url} />
            </div>
          </Modal>
        )}

        {croppedImage && (
          <ImageCropper
            image={croppedImage}
            onClose={this.onCloseCropped}
            onSubmit={this.onSubmitCropper}
          />
        )}
      </div>
    );
  }
}
