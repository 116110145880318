import React from 'react';
import Label from './label';
import { loadStripe } from 'utils/stripe';
import variables from 'styles/variables.scss';

const INPUT_STYLE = {
  style: {
    base: {
      color: variables.colorInput,
      fontWeight: variables.fontWeightInput,
      fontFamily: variables.fontForm,
      fontSize: variables.fontSizeInput,
    },
  },
};

export default class InputIDeal extends React.PureComponent {
  state = {
    stripe: null,
    mounted: false,
  };

  async componentDidMount() {
    this.setState({
      stripe: await loadStripe(this.props.settings),
    });
  }

  componentDidUpdate() {
    const { stripe, mounted } = this.state;
    const { onInit, onChange } = this.props;

    if (!mounted && stripe) {
      const elements = stripe.elements();
      const ideal = elements.create('idealBank', {
        ...INPUT_STYLE,
        classes: {
          base: 'input',
          focus: 'focus',
        },
      });
      ideal.mount('#stripe-ideal');
      onInit && onInit(stripe, ideal);
      onChange && ideal.on('change', (event) => onChange(event, {}));
      this.setState({ mounted: true });
    }
  }

  render() {
    const { id } = this.props;

    return (
      <div className="form-field form-field-ideal">
        <span className="form-ideal">
          <Label label="iDeal bank" htmlFor={id} />
          <span className="form-field-input">
            <div id="stripe-ideal" />
          </span>
        </span>
      </div>
    );
  }
}
