import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import pt from 'prop-types';

import { SOURCE_TYPES } from 'constants/content';

import {
  contentFieldAliasType,
  contentFieldAliasLookup,
  isImageAssetType,
} from 'utils/content';

import { expandString } from 'utils/string';
import { singularize, slugify, wordify, isEmpty } from 'utils';

import {
  Field,
  FieldLocalized,
  LookupProduct,
  LookupVariant,
  LookupCategory,
  LookupCustomer,
  LookupIcon,
} from 'components/form';

function mapAssetTypeToAcceptType(assetType) {
  switch (assetType) {
    case 'image':
      return 'image/*';
    case 'document':
      return 'application/*';
    case 'video':
      return 'video/*';
    default:
      return '';
  }
}

function getFileAcceptByAssetTypes(assetTypesArray) {
  if (!Array.isArray(assetTypesArray) || assetTypesArray.length <= 0) {
    return '';
  }

  const accept = assetTypesArray
    .map((assetType) => mapAssetTypeToAcceptType(assetType))
    .filter(Boolean)
    .join(',');

  return accept;
}

// Note: must be Component vs Pure, because of formtable issue with lookup
export default class ContentField extends React.Component {
  static propTypes = {
    id: pt.string,
    ui: pt.string,
    type: pt.string,
    name: pt.string,
    values: pt.object,
    rootValue: pt.object,
    description: pt.node,
    source_type: pt.string,
    content_id: pt.string,
    currency: pt.string,
    showHelp: pt.bool,
    hint: pt.node,
    view: pt.object,
    app: pt.object,
  };

  getLocaleProps(props = this.props) {
    const localeProps = {
      localized: props.localized || false,
      localeValue: props.localeValue,
      localeTemp: props.localeTemp,
      localeFieldName: props.localeFieldName,
      localeFieldPrefix: props.localeFieldPrefix,
    };

    if (
      props.localeFieldName === undefined &&
      props.localeFieldPrefix === undefined &&
      props.name &&
      props.name.includes('.')
    ) {
      const nameParts = props.name.split('.');
      localeProps.localeFieldName = nameParts.pop();
      localeProps.localeFieldPrefix = nameParts.join('.');
      if (props.type === 'currency' || props.ui === 'currency') {
        localeProps.localeValue = get(
          props.rootValue,
          `${localeProps.localeFieldPrefix}.$currency`,
        );
      } else {
        localeProps.localeValue = get(
          props.rootValue,
          `${localeProps.localeFieldPrefix}.$locale`,
        );
      }
    }

    if (!localeProps.localeValue && props.rootValue) {
      if (props.type === 'currency' || props.ui === 'currency') {
        localeProps.localeValue = props.rootValue.$currency;
      } else {
        localeProps.localeValue = props.rootValue.$locale;
      }
    }

    return localeProps;
  }

  getHelpTip() {
    const {
      hint,
      description,
      app,
      view,
      showHelp = true,
      source_type,
      content_id,
    } = this.props;

    let isCustomField = source_type === 'custom';

    if (content_id) {
      const type = content_id.split('.').shift();

      isCustomField = get(SOURCE_TYPES[type], 'label', 'Custom');
    }

    // note: it is necessary to pass an empty message Help for custom fields to show the "Edit Field" tooltip
    if (!hint && !description && !isCustomField) {
      // No tip to show.
      return null;
    }

    if (!showHelp) {
      return null;
    }

    const message = description || hint; // note: a hint in our component is below the input, but in content field it's a tooltip
    // Pass app in help object to render tooltip with source indicator
    return {
      message,
      field: this.props,
      ...(view && { view }),
      ...(app && { app }),
    };
  }

  expandPropStrings(fieldProps, uiType, values) {
    // Expand template strings in certain props
    if (values && String(fieldProps.placeholder).includes('{')) {
      const preValue = fieldProps.placeholder;
      fieldProps.placeholder = expandString(preValue, values);

      // Apply ui specific modifications if the value changed
      if (preValue !== fieldProps.placeholder) {
        switch (uiType) {
          case 'slug':
            fieldProps.placeholder = slugify(fieldProps.placeholder);
            break;
          default:
            break;
        }
      }
    }
  }

  render() {
    const {
      id,
      ui,
      name,
      type: inType,
      values,
      rootValue,
      ...props
    } = this.props;

    const label = props.label !== null ? props.label || wordify(id) : undefined;

    const type = contentFieldAliasType(inType);
    const uiType = ui || inType || type;

    const fieldProps = {
      name,
      label,
      defaultValue: props.defaultValue,
      help: this.getHelpTip(),
      onChange: props.onChange,
      className: props.className || 'span4',
      disabled: props.disabled || props.readonly,
      autoFocus: props.autoFocus,
      debounce: props.debounce,
    };

    if (props.rawLabel) {
      fieldProps.rawLabel = props.rawLabel;
    }

    if (props.labelRenderer) {
      fieldProps.labelRenderer = props.labelRenderer;
    }

    if (props.placeholder !== undefined) {
      fieldProps.placeholder = props.placeholder;
    }

    if (props.required) {
      fieldProps.required = true;
    }

    if (props.readonly !== undefined) {
      fieldProps.readonlyContent = props.readonly;
    }

    // TODO: make this work with template engine instead
    this.expandPropStrings(fieldProps, uiType, rootValue || props.recordValues);

    const localeProps = this.getLocaleProps();

    switch (type) {
      case 'short_text': {
        switch (uiType) {
          case 'phone': {
            fieldProps.rules = 'phone';
            break;
          }

          case 'email': {
            fieldProps.rules = 'email';
            break;
          }

          case 'slug': {
            fieldProps.defaultValue = slugify(fieldProps.defaultValue);
            break;
          }

          case 'url': {
            if (!fieldProps.placeholder) {
              fieldProps.placeholder = 'http://www.example.com';
            }

            break;
          }

          default:
            break;
        }

        if (props.min) {
          fieldProps.minLength = props.min;
        }

        if (props.max) {
          fieldProps.maxLength = props.max;
        }

        return <FieldLocalized type="text" {...fieldProps} {...localeProps} />;
      }

      case 'long_text':
      case 'basic_html':
      case 'rich_html':
      case 'markdown':
      case 'blocks': {
        fieldProps.type = 'textarea';

        switch (uiType) {
          case 'basic_html': {
            fieldProps.type = 'editor';
            fieldProps.toolbar = 'basic';
            break;
          }

          case 'rich_html': {
            fieldProps.type = 'editor';
            fieldProps.toolbar = 'rich';
            break;
          }

          case 'markdown': {
            // TODO
            break;
          }

          case 'blocks': {
            // TODO
            break;
          }

          default:
            break;
        }

        if (props.min) {
          fieldProps.minLength = props.min;
        }

        if (props.max) {
          fieldProps.maxLength = props.max;
        }

        return (
          <FieldLocalized
            rows={props.rows || 3}
            maxRows={15}
            autoSize={true}
            {...fieldProps}
            {...localeProps}
          />
        );
      }

      case 'asset': {
        const assetTypes = props.asset_types || [];
        let key;
        let accept;
        let typeLabel;

        if (props.defaultValue) {
          fieldProps.value = props.defaultValue;
        }

        // Use image specific component vs other
        if (uiType === 'image' || isImageAssetType(assetTypes)) {
          accept = 'image/*';
          fieldProps.type = 'image';
          key = 'image';

          if (props.imageProps) {
            Object.assign(fieldProps, props.imageProps);
          } else {
            fieldProps.fullSize = false;
          }

          if (props.multi) {
            fieldProps.single = false;
          } else {
            fieldProps.single = true;
          }
        } else {
          if (props.multi) {
            fieldProps.multiple = true;
          } else {
            fieldProps.multiple = false;
          }

          switch (uiType) {
            case 'video': {
              accept = getFileAcceptByAssetTypes(assetTypes) || 'video/*';
              typeLabel = 'video';
              key = 'video';
              break;
            }

            case 'document': {
              accept = getFileAcceptByAssetTypes(assetTypes) || 'application/*';
              typeLabel = 'document';
              key = 'document';
              break;
            }

            case 'asset': {
              key = 'asset';
              break;
            }

            default:
              break;
          }
        }

        if (props.multi) {
          key = `${key}_multi`;
        }

        // TODO: test + remove this
        // if (fieldProps.value && !fieldProps.value.file) {
        //   fieldProps.value = { file: fieldProps.value };
        // }

        return (
          <Field
            type="file"
            {...fieldProps}
            accept={accept}
            typeLabel={typeLabel}
            key={key}
          />
        );
      }

      case 'icon':
        return <LookupIcon {...fieldProps} lookup={props.lookup} />;

      case 'lookup': {
        const aliasProps = contentFieldAliasLookup(inType);

        const model =
          props.collection ||
          props.model ||
          aliasProps.collection ||
          aliasProps.model;

        const lookupProps = {
          ...aliasProps,
          ...fieldProps,
          model,
          contentQuery: props.query,
          lookup: props.lookup,
          parentId: props.parentId,
          record: rootValue || props.recordValues,
          namePattern: props.name_pattern,
        };

        if (!lookupProps.placeholder) {
          delete lookupProps.placeholder;
        }

        // Parent lookup with child collection fields
        if (props.childExpands) {
          lookupProps.query = {
            ...(lookupProps.query || undefined),
            expand: [
              ...props.childExpands,
              ...(lookupProps.query.expand
                ? lookupProps.query.expand instanceof Array
                  ? lookupProps.query.expand
                  : [lookupProps.query.expand]
                : []),
            ],
          };
        }

        // Child lookup with parent field ID
        if (
          !lookupProps.parentId &&
          props.collection_parent_id &&
          props.collection_parent_field &&
          !isEmpty(rootValue)
        ) {
          const parentPath = `${props.collection_parent_id}.id`;

          lookupProps.parentId =
            get(rootValue, parentPath) || get(props.recordValues, parentPath);
        }

        switch (model || lookupProps.model) {
          case 'products':
            return <LookupProduct {...lookupProps} />;

          case 'products:variants':
            return <LookupVariant {...lookupProps} />;

          case 'categories':
            return (
              <LookupCategory {...lookupProps} categories={props.categories} />
            );

          case 'accounts':
            return <LookupCustomer {...lookupProps} />;

          default: {
            if (!lookupProps.placeholder) {
              lookupProps.placeholder = `Find ${singularize(
                model || lookupProps.model || props.label || '',
              )
                .split('/')
                .pop()
                .toLowerCase()} by name`;
            }

            return <Field type="lookup" {...lookupProps} />;
          }
        }
      }

      case 'boolean': {
        if (uiType === 'toggle') {
          fieldProps.type = 'toggle';
        }

        return (
          <FieldLocalized
            type="checkbox"
            defaultChecked={props.defaultValue}
            {...fieldProps}
            {...localeProps}
            defaultValue={undefined}
            nonValue={false}
          />
        );
      }

      case 'select': {
        switch (uiType) {
          case 'radio': {
            fieldProps.type = 'radio';
            fieldProps.stacked = true;
            break;
          }

          case 'checkboxes': {
            fieldProps.type = 'checkbox';
            fieldProps.stacked = true;
            break;
          }

          default: {
            if (props.multi) {
              fieldProps.type = 'checkbox';
            }

            break;
          }
        }

        if (!fieldProps.placeholder && props.default) {
          fieldProps.placeholder = get(
            find(props.options, { value: props.default }),
            'label',
            props.default,
          );
        }

        return (
          <FieldLocalized
            type="select"
            options={props.options}
            {...fieldProps}
            {...localeProps}
          />
        );
      }

      case 'number': {
        switch (uiType) {
          case 'currency': {
            fieldProps.type = 'currency';
            fieldProps.currency = props.currency || this.context.currency;
            fieldProps.scale = props.digits || 0;
            break;
          }

          case 'slider': {
            fieldProps.type = 'slider';
            const min = +props.min || 0;
            const max = props.max <= min ? min + 100 : props.max || min + 100;
            const diff = max - min;

            const marks = props.marks || [
              min,
              min + Math.round(diff / 5),
              min + Math.round(diff / 5) * 2,
              min + Math.round(diff / 5) * 3,
              min + Math.round(diff / 5) * 4,
              max,
            ];

            fieldProps.min = min;
            fieldProps.max = max;

            fieldProps.marks = reduce(
              marks,
              (acc, mark) => {
                acc[mark] = mark;
                return acc;
              },
              {},
            );

            if (fieldProps.defaultValue < min) {
              fieldProps.defaultValue = min;
            }

            break;
          }

          default: {
            fieldProps.type = 'number';
            fieldProps.scale = props.digits || 0;
            break;
          }
        }

        // TODO: unit
        return <FieldLocalized {...fieldProps} {...localeProps} />;
      }

      case 'date': {
        switch (uiType) {
          case 'time': {
            fieldProps.type = 'time';
            break;
          }

          case 'datetime': {
            fieldProps.type = 'datetime';
            fieldProps.time = true;
            break;
          }

          default: {
            fieldProps.time = false;
            break;
          }
        }

        if (props.default === 'now') {
          props.defaultValue = Date.now();
        }

        // TODO: min/max
        return <FieldLocalized type="date" {...fieldProps} {...localeProps} />;
      }

      case 'tags':
        return <FieldLocalized type="tags" {...fieldProps} {...localeProps} />;

      // Note: these might be rendered differently by parent components
      case 'color':
        return <FieldLocalized type="color" {...fieldProps} {...localeProps} />;

      case 'collection':
      case 'field_group':
      case 'field_row':
      default: {
        if (!id) {
          return null;
        }

        return (
          <div className="collection-none-found" style={{ width: '100%' }}>
            Invalid field type &apos;{type}&apos; ({label})
          </div>
        );
      }
    }
  }
}
