import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { find, get, map } from 'lodash';
import { Form, Field } from 'components/form';
import View from 'components/view';
import FileReader from 'components/form/file-reader';
import ImageCropper from 'components/form/image-cropper';
import UserPhoto from 'components/user/photo';
import UserPermissions from 'components/user/permissions';
import Button from 'components/button/button';
import { FadeIn } from 'components/transitions';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';
import { canUserManagePermissions } from 'utils';
import './settings.scss';
import SectionHeader from 'components/section-header';

export default class User extends PureComponent {
  static contextTypes = {
    user: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    uploadImages: PropTypes.func.isRequired,
    isOwner: PropTypes.bool.isRequired,
    isAdvancedUserPermissions: PropTypes.bool.isRequired,
  };

  static propTypes = {
    users: PropTypes.array,
    roles: PropTypes.array,
    onRemoveUser: PropTypes.func,
    onInviteUser: PropTypes.func,
    onUploadPhoto: PropTypes.func,
    onSubmitUser: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const user = this.getUser(props);
    this.state = {
      edited: false,
      uploadedPhoto: null,
      user,
      values: { ...user },
    };
  }

  componentWillMount() {
    confirmRouteLeave(this);
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.users !== this.props.users) {
      const user = this.getUser(nextProps);
      this.setState({ user, values: { ...user } });
    }
  }

  getUser(props = this.props) {
    const {
      users,
      params: { id },
    } = props;
    return find(users.results, { id });
  }

  onUploadPhoto = (files) => {
    return this.context
      .uploadImages(files)
      .then(
        (results) =>
          results && this.setState({ uploadedPhoto: { file: results[0] } }),
      );
  };

  onCloseCropper = () => {
    this.setState({ uploadedPhoto: null });
  };

  onSubmitCropper = (file) => {
    if (file) {
      this.props.onUploadPhoto(file).then(this.onCloseCropper);
    }
  };

  onDelete = (event) => {
    event.preventDefault();
    const { onRemoveUser } = this.props;
    const { user } = this.state;
    this.context.openModal('ConfirmDelete', {
      title: `${user.name || user.email} user`,
      onConfirm: () => onRemoveUser(user.id),
    });
  };

  onClickSendInvite = (event) => {
    event.preventDefault();
    const { onInviteUser } = this.props;
    const {
      user: { id, email, role_id, notify },
    } = this.state;

    onInviteUser(id, { email, role_id, notify });
  };

  onChange = (values, edited) => {
    this.setState({ values: { ...values }, edited });
  };

  render() {
    const { roles, onSubmitUser } = this.props;
    const {
      client,
      user: userSelf,
      isOwner: isStoreOwnerSession,
      isAdvancedUserPermissions,
    } = this.context;
    const { uploadedPhoto, user, values, edited } = this.state;

    const hasJoined = !!(user && user.date_last_login);
    const isSelf = userSelf.id === user.id;
    const isStoreOwner = client.owner_id === user.id;
    const canMakeOwner = !isStoreOwner && isStoreOwnerSession && hasJoined;

    // Note: AdvancedUserPermissions is not required to delete the user.
    // hence validateUserPermission flag is passed as false
    const canManagePermissions = canUserManagePermissions(this.context, false);
    const canDelete = !isSelf && !isStoreOwner && canManagePermissions;

    const canSave = isSelf || !isStoreOwner;
    const hasRoles = !!get(roles, 'results.length');
    const canChangeRole =
      isAdvancedUserPermissions &&
      hasRoles &&
      !isSelf &&
      !isStoreOwner &&
      canManagePermissions;
    const canChangePermissions =
      !isAdvancedUserPermissions && !isStoreOwner && canManagePermissions;

    return (
      <div className="settings settings-user">
        <Form ref="form" onSubmit={onSubmitUser} onChange={this.onChange}>
          <View
            detail={true}
            uri="/settings/users"
            sectionTitle="Users"
            headerActions={[
              !hasJoined && {
                label: 'Re-send invite',
                type: 'secondary',
                onClick: this.onClickSendInvite,
              },
              canSave && {
                label: 'Save user',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
            extraActions={[
              {
                label: 'Delete this user',
                onClick: this.onDelete,
                hidden: !canDelete,
                className: 'danger',
                delete: true,
              },
            ]}
          >
            <div className="user-view">
              <div className="user-container">
                <div className="view-body-section-group">
                  <div className="view-body-section">
                    <div className="view-section-content no-header">
                      <div className="user-details">
                        <div className="user-photo-container">
                          <FileReader
                            accept="image/*"
                            onFiles={this.onUploadPhoto}
                            multiple={false}
                            disabled={!isSelf}
                          >
                            <UserPhoto user={user} width={72} height={72} />
                            {isSelf ? (
                              <Button type="secondary" size="sm">
                                Upload photo
                              </Button>
                            ) : (
                              ''
                            )}
                          </FileReader>
                        </div>
                        <h3 className="view-header-name">
                          {user.name || user.email}{' '}
                        </h3>
                        <div className="view-header-note">
                          {user.name && user.email}
                        </div>
                        <div className="status positive">
                          <UserPermissions user={user} />
                        </div>
                      </div>
                      <div className="user-form-fields">
                        <fieldset>
                          {(isSelf || user.name) && (
                            <Field
                              type="text"
                              name="name"
                              label="Name"
                              required={true}
                              disabled={!isSelf}
                              defaultValue={user.name}
                            />
                          )}

                          <Field
                            type="text"
                            name="email"
                            label="Email address"
                            required={true}
                            disabled={!isSelf}
                            defaultValue={user.email}
                          />
                          {canChangeRole && (
                            <Field
                              type="select"
                              label="Role"
                              name="role_id"
                              placeholder="Choose a role"
                              options={map(roles.results, (role) => ({
                                value: role.id,
                                label: role.name,
                              }))}
                              defaultValue={get(user, 'role.id')}
                            />
                          )}

                          {isSelf && (
                            <Fragment>
                              <Field
                                type="checkbox"
                                name="notify"
                                label="Notify when an order is placed"
                                defaultChecked={userSelf.notify}
                              />

                              <Field
                                type="checkbox"
                                name="devtools"
                                label="Enable developer tools"
                                help="Turn on a few useful shortcuts for developers"
                                defaultChecked={userSelf.devtools}
                              />
                            </Fragment>
                          )}
                          {canMakeOwner && (
                            <Field
                              type="checkbox"
                              name="owner"
                              label="Make account owner"
                              defaultChecked={false}
                              help="Account owner is responsible for billing and user management"
                            />
                          )}
                        </fieldset>
                      </div>
                    </div>
                  </div>
                  {canChangePermissions && (
                    <div className="view-body-section">
                      <div className="view-body-subheader">
                        <SectionHeader
                          className="view-body-subtitle"
                          title="Permissions"
                        />
                      </div>

                      <div className="view-section-content">
                        <Fragment>
                          <Field
                            type="checkbox"
                            name="all_permissions"
                            label="Full permissions"
                            disabled={isSelf}
                            defaultChecked={
                              !values.permissions || values.all_permissions
                            }
                            help="Users may have all or limited permissions to view and edit store data"
                          />
                          {(values.all_permissions === undefined
                            ? !!values.permissions
                            : !values.all_permissions) && (
                            <div>
                              <FadeIn>
                                <div className="row indented">
                                  <Field
                                    type="checkbox"
                                    name="permissions"
                                    disabled={isSelf}
                                    options={[
                                      {
                                        value: 'dashboard',
                                        label: 'Dashboard',
                                      },
                                      { value: 'orders', label: 'Orders' },
                                      {
                                        value: 'carts',
                                        label: 'Abandoned carts',
                                      },
                                      {
                                        value: 'customers',
                                        label: 'Customers',
                                      },
                                      { value: 'products', label: 'Products' },
                                      {
                                        value: 'subscriptions',
                                        label: 'Subscriptions',
                                      },
                                      {
                                        value: 'categories',
                                        label: 'Categories',
                                      },
                                      {
                                        value: 'attributes',
                                        label: 'Attributes',
                                      },
                                      {
                                        value: 'giftcards',
                                        label: 'Gift cards',
                                      },
                                      { value: 'coupons', label: 'Coupons' },
                                      {
                                        value: 'promotions',
                                        label: 'Promotions',
                                      },
                                      { value: 'content', label: 'Content' },
                                      { value: 'reports', label: 'Reports' },
                                      {
                                        value: 'storefront',
                                        label: 'Storefront',
                                      },
                                      { value: 'apps', label: 'Apps' },
                                      { value: 'settings', label: 'Settings' },
                                    ]}
                                    value={values.permissions || []}
                                    stacked={true}
                                  />
                                </div>
                              </FadeIn>
                            </div>
                          )}
                        </Fragment>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </View>
        </Form>
        {uploadedPhoto && (
          <ImageCropper
            image={uploadedPhoto}
            onClose={this.onCloseCropper}
            onSubmit={this.onSubmitCropper}
          />
        )}
      </div>
    );
  }
}
