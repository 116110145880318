import React from 'react';
import { Form, Field } from 'components/form';
import View from 'components/view';
import { FadeIn } from 'components/transitions';
import SectionHeader from 'components/section-header';
import TaxRules from './taxes-rules';
import './settings.scss';

export default class TaxSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
    this.onClickShowAdvancedOptions =
      this.onClickShowAdvancedOptions.bind(this);
  }

  onClickShowAdvancedOptions(event) {
    event.preventDefault();
    this.setState({ showOptions: !this.state.showOptions });
  }

  render() {
    const { values, edited, settings, categories, onSubmitForm, onChangeForm } =
      this.props;

    return (
      <div className="settings settings-taxes">
        <Form ref="form" onSubmit={onSubmitForm} onChange={onChangeForm}>
          <View
            detail={true}
            uri="/settings"
            sectionTitle="Settings"
            headerTitle="Taxes"
            headerActions={[
              {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
          >
            <fieldset className="full">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title="Rules"
                  subtitle="Manage tax rules and rates for calculating taxes at checkout"
                />
              </div>
              <TaxRules
                values={values}
                settings={settings}
                categories={categories}
                onSubmit={(values) =>
                  this.props.onSubmitForm({ rules: values })
                }
              />
            </fieldset>
            <fieldset className="full">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title="Calculation options"
                  subtitle="Manage options affecting tax calculation"
                />
              </div>
              <div className="box">
                <div className="box-section">
                  <div className="box-grid">
                    <div className="box-column">
                      <div className="box-title">
                        Product prices include tax
                      </div>
                      <div className="box-subtitle">
                        Enable if your product prices are set with tax included
                      </div>
                    </div>
                    <div className="box-column">
                      <Field
                        type="toggle"
                        name="product_price_includes_tax"
                        defaultChecked={
                          settings.taxes.product_price_includes_tax
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="box-section">
                  <div className="box-grid">
                    <div className="box-column">
                      <div className="box-title">
                        Shipping prices include tax
                      </div>
                      <div className="box-subtitle">
                        Enable if your shipping prices are set with tax included
                      </div>
                    </div>
                    <div className="box-column">
                      <Field
                        type="toggle"
                        name="shipping_price_includes_tax"
                        defaultChecked={
                          settings.taxes.shipping_price_includes_tax
                        }
                      />
                    </div>
                  </div>
                </div>
                <FadeIn active={this.state.showOptions}>
                  <div className="box-section">
                    <div className="box-grid">
                      <div className="box-column">
                        <div className="box-title">
                          Apply taxes after discounts
                        </div>
                        <div className="box-subtitle">
                          Enable if taxes should be applied after discounts are
                          subtracted
                        </div>
                      </div>
                      <div className="box-column">
                        <Field
                          type="toggle"
                          name="apply_tax_including_discount"
                          defaultChecked={
                            settings.taxes.apply_tax_including_discount
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-section">
                    <div className="box-grid">
                      <div className="box-column">
                        <div className="box-title">
                          Apply discounts after taxes
                        </div>
                        <div className="box-subtitle">
                          Enable if discounts should be calculated after taxes
                          are added
                        </div>
                      </div>
                      <div className="box-column">
                        <Field
                          type="toggle"
                          name="apply_discount_including_tax"
                          defaultChecked={
                            settings.taxes.apply_discount_including_tax
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-section">
                    <div className="box-grid">
                      <div className="box-column">
                        <div className="box-title">Taxable location</div>
                        <div className="box-subtitle">
                          Determines which location is used for tax calculation
                        </div>
                      </div>
                      <div className="box-column">
                        <Field
                          type="radio"
                          name="location_source"
                          options={[
                            { value: 'shipping', label: 'Shipping address' },
                            { value: 'billing', label: 'Billing address' },
                          ]}
                          defaultValue={settings.taxes.location_source}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-section">
                    <div className="box-grid">
                      <div className="box-column">
                        <div className="box-title">Calculation source</div>
                        <div className="box-subtitle">
                          Determines which value is used for tax calculation
                        </div>
                      </div>
                      <div className="box-column">
                        <Field
                          type="radio"
                          name="calculation_source"
                          options={[
                            { value: 'total', label: 'Order total' },
                            { value: 'row', label: 'Item total' },
                            { value: 'unit', label: 'Product price' },
                          ]}
                          defaultValue={settings.taxes.calculation_source}
                        />
                      </div>
                    </div>
                  </div>
                </FadeIn>
                <div className="box-section note">
                  <div className="note">
                    <a href="" onClick={this.onClickShowAdvancedOptions}>
                      {this.state.showOptions ? 'Hide' : 'Show'} advanced
                      options
                    </a>
                  </div>
                </div>
              </div>
            </fieldset>
          </View>
        </Form>
      </div>
    );
  }
}
