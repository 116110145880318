import PropTypes from 'prop-types';
import React from 'react';
import { find, noop } from 'lodash';
import View from 'components/view';
import ThemeSupportAlert from './theme-support-alert';
import CollectionEmpty from 'components/collection/empty';
import Menu from './menu';
import { locationWithQuery } from 'utils';
import { forEachMenuItem } from '../../shared/utils';

export default class Navigation extends React.PureComponent {
  static propTypes = {
    onSaveMenu: PropTypes.func,
    onDeleteMenu: PropTypes.func,
    onEdited: PropTypes.func,
  };

  static defaultProps = {
    onSaveMenu: noop,
    onDeleteMenu: noop,
    onEdited: noop,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showingMenu: false,
      menuId: null,
    };
  }

  componentWillMount() {
    this.showMenuFromQuery(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.edited) {
      this.showMenuFromQuery(nextProps);
    }
  }

  onEdited = (edited) => {
    this.props.onEdited(edited);
  };

  showMenuFromQuery(props) {
    const { location, menus } = props;

    if (!location.query.create && location.query.menu && find(menus, { id: location.query.menu })) {
      this.setState({
        showingMenu: true,
        menuId: location.query.menu,
      });
    } else if (!location.query.create && !location.query.menu && this.state.showingMenu) {
      this.setState({ showingMenu: false, menuId: this.state.menuId || null });
    }
  }

  onClickShowMenu = (event) => {
    const { router, location } = this.props;
    event.preventDefault();
    const menuId = event.currentTarget.dataset.id;
    this.setState({ showingMenu: true, menuId });
    router.push(locationWithQuery(location, { menu: menuId, create: null }, true));
  };

  onClickNewMenu = (event) => {
    const { router, location } = this.props;
    event.preventDefault();
    this.setState({ showingMenu: true, menuId: null });
    router.push(locationWithQuery(location, { menu: null, create: true }, true));
  };

  onCloseMenu = () => {
    const { router, location } = this.props;
    this.setState({ showingMenu: false });
    router.push(locationWithQuery(location, { menu: null, create: null }, true));
  };

  onSaveMenu = (values) => {
    return this.props.onSaveMenu(values).then((result) => {
      if (!result || !find(result, { id: values.id })) {
        return false;
      }
      const { router, location } = this.props;
      this.setState({ menuId: values.id });
      router.push(locationWithQuery(location, { menu: values.id, create: null }, true));
      return result;
    });
  };

  onDeleteMenu = (values) => {
    return this.props.onDeleteMenu(values);
  };

  countMenuItems(menu) {
    if (!menu.items) {
      return 0;
    }
    return menu.items.reduce((acc, item) => {
      let inc = 0;
      forEachMenuItem(item.items, () => (inc += 1));
      return acc + inc;
    }, menu.items.length);
  }

  renderMenuPage() {
    const { record, menus } = this.props;
    const { menuId } = this.state;
    const menu = find(menus, { id: menuId });
    return (
      <Menu
        {...this.props}
        record={menu}
        storefront={record}
        onEdited={this.onEdited}
        onClose={this.onCloseMenu}
        onSave={this.onSaveMenu}
        onDelete={this.onDeleteMenu}
      />
    );
  }

  render() {
    const { record, menus, viewProps = {} } = this.props;

    const { showingMenu } = this.state;

    return (
      <div className="settings">
        {showingMenu ? (
          this.renderMenuPage()
        ) : (
          <View
            key="navigation"
            {...viewProps}
            detail={true}
            headerActions={[
              ...viewProps.headerActions,
              { label: 'New menu', onClick: this.onClickNewMenu },
            ]}
          >
            {record.hosted && <ThemeSupportAlert />}
            {menus?.length > 0 ? (
              <table className="collection-table outer">
                <thead>
                  <tr>
                    <th>Menu</th>
                    <th>Alias</th>
                    <th>Items</th>
                  </tr>
                </thead>
                <tbody>
                  {menus.map((menu) => (
                    <tr key={menu.id}>
                      <td>
                        <a
                          href=""
                          onClick={this.onClickShowMenu}
                          data-id={menu.id}
                        >
                          {menu.name}
                        </a>
                      </td>
                      <td>
                        <code>{menu.id}</code>
                      </td>
                      <td>{this.countMenuItems(menu)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="box">
                <CollectionEmpty
                  title="Menus"
                  emptyTitle="Navigation menus"
                  emptyDescription="Use menus to build and manage store navigation elements"
                  onClickAction={this.onClickNewMenu}
                />
              </div>
            )}
          </View>
        )}
      </div>
    );
  }
}
