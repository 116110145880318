import React, { Fragment } from 'react';
import TemplateEngine from 'swell-template';
import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import pick from 'lodash/pick';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import pt from 'prop-types';

import api from 'services/api';

import {
  inflect,
  localeFallbackValue,
  formatDate,
  combineDateTime,
} from 'utils';
import {
  getCheckoutUrl,
  getCleanItemOptions,
  getShippableItems,
} from 'utils/order';
import { getVariantByOptions, isTrialItem } from 'utils/product';
import { hasLocalizedContent } from 'utils/content';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';

import ActivityFeed from 'containers/ActivityFeed';

import { View } from 'components/view';
import Side from 'components/view/side';
import Icon from 'components/icon';
import Link from 'components/link';
import LocaleIcon from 'components/locale/icon';
import PrevButton from 'components/button/prev';
import NextButton from 'components/button/next';
import DropdownButton from 'components/button/dropdown';
import { Form, Field, TabView } from 'components/form';
import LocaleFieldSelect from 'components/locale/field-select';
import CurrencyPicker from 'components/locale/currency';
import Modal from 'components/modal';
import DateTime from 'components/date-time';
import CartStatus from 'components/pages/cart/status';
import ManageAddresses from 'components/manage-addresses';
import WithViewPreferences from 'containers/WithViewPreferences';
import ContentRecordTab, {
  contentRecordSubmitAction,
  contentRecordSubmitChangeHandlers,
} from 'components/content/record-tab';
import TemplatePreview from 'components/template-preview';

import OrderItems from './items';
import OrderItemsAdd from './items-add';
import OrderItemsEdit from './items-edit';
import OrderItemsCancel from './items-cancel';
import OrderCustomer from './customer';
import OrderCustomerEdit from './customer-edit';
import OrderAddressEdit from './address-edit';
import OrderShippingEdit from './shipping-edit';
import OrderDiscountsEdit from './discounts-edit';
import OrderPayments from './payments';
import OrderPaymentEdit from './payment-edit';
import OrderPaymentCharge from './payment-charge';
import OrderPaymentRefund from './payment-refund';
import OrderPaymentView from './payment-view';
import OrderFulfillment from './fulfillment';
import OrderFulfillmentAdd from './fulfillment-add';
import OrderFulfillmentEdit from './fulfillment-edit';
import OrderFulfillmentGiftcard from './fulfillment-giftcard';
import OrderReturnAdd from './return-add';
import OrderReturnEdit from './return-edit';
import OrderContent from './content';
import { PaymentStatus, FulfillmentStatus } from './status';
import OrderNotes from './notes';

import './order.scss';
import './print.scss';

export default class OrderView extends React.PureComponent {
  static propTypes = {
    uri: pt.string,
    prev: pt.object,
    next: pt.object,
    title: pt.string,
    record: pt.object,
    edited: pt.bool,
    lookup: pt.object,
    content: pt.object,
    related: pt.object,
    loading: pt.bool,
    settings: pt.object,
    location: pt.object,
    discounts: pt.object,
    categories: pt.object,
    sectionTitle: pt.string,
    notifications: pt.object,
    showPaymentEdit: pt.bool,
    checkoutCustomUrl: pt.string,
    suggestedAddresses: pt.array,
    isCart: pt.bool,

    onEdited: pt.func,
    onDelete: pt.func,
    onAddItem: pt.func,
    onEditItems: pt.func,
    onEditValues: pt.func,
    updateRecord: pt.func,
    onResendEmail: pt.func,
    onPaymentEdit: pt.func,
    onSendInvoice: pt.func,
    onRefundUpdate: pt.func,
    onReturnDelete: pt.func,
    onReturnEdit: pt.func,
    onReturnAdd: pt.func,
    onGiftcardSend: pt.func,
    onPaymentCharge: pt.func,
    onPaymentUpdate: pt.func,
    onPaymentRefund: pt.func,
    onLoadAddresses: pt.func,
    onLoadBilling: pt.func,
    onChangeCurrency: pt.func,
    onShippingGetRates: pt.func,
    onFulfillmentDelete: pt.func,
    onFulfillmentEdit: pt.func,
    onFulfillmentAdd: pt.func,
    fetchOrderPrintTemplates: pt.func,
    fetchPrintTemplate: pt.func,
    fetchNotification: pt.func,
    getItemPrice: pt.func,
    fetchRecord: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
    client: pt.object.isRequired,
    closeModal: pt.func.isRequired,
    notifyError: pt.func.isRequired,
    notifySuccess: pt.func.isRequired,
    testEnv: pt.string,
  };

  templateEngine = null;

  constructor(props, context) {
    super(props, context);

    this.state = {
      showAddItem: false,
      showEditItems: false,
      showEditSingleItem: false,
      showAddItems: false,
      showCancelItems: false,
      showEditCustomer: false,
      showEditAddress: false,
      showShippingEdit: false,
      showDiscountsEdit: false,
      showPaymentEdit: false,
      showPaymentCharge: false,
      showPaymentRefund: false,
      showPaymentView: false,
      showFulfillmentAdd: false,
      showFulfillmentEdit: false,
      showFulfillmentGiftcard: false,
      showManageAddresses: false,
      pendingFulfillmentAdd: false,
      showReturnAdd: false,
      showReturnEdit: false,
      addItemProduct: null,
      editAddItem: null,
      editAddIndex: null,
      paymentId: null,
      refundId: null,
      shipmentId: null,
      editItems: [],
      notificationValues: {},
      notification: null,
      notificationLoading: false,
      modalLoading: false,
      showNotification: false,
      isDefaultTemplate: true,
      ...this.getInitialState(props),
    };

    this.editItemPriceTimer = 0;
  }

  componentDidMount() {
    confirmRouteLeave(this);
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);

    if (this.props.record !== prevProps.record) {
      this.setState(this.getInitialState(this.props));
    } else if (this.props.showPaymentEdit !== prevProps.showPaymentEdit) {
      this.setState({ showPaymentEdit: this.props.showPaymentEdit });
    }
  }

  componentWillUnmount() {
    confirmPageLeave(this);

    if (this.editItemPriceTimer) {
      clearTimeout(this.editItemPriceTimer);
    }
  }

  onClickManageAddresses = async (event) => {
    event?.preventDefault();

    if (!this.state.showManageAddresses) {
      await this.props.onLoadAddresses();
    }

    this.setState((state) => ({
      showManageAddresses: !state.showManageAddresses,
    }));
  };

  getCancelableItems({ record }) {
    // Note: map is needed due to change in field definitions in aug 2019
    return map(record.items, (item) => ({
      ...item,
      quantity_canceled: item.quantity_canceled || 0,
      quantity_cancelable:
        item.quantity - item.quantity_delivered - (item.quantity_canceled || 0),
    })).filter((item) => item.quantity_cancelable > 0);
  }

  getInitialState(props) {
    const cancelableItems = this.getCancelableItems(props);
    return {
      isEditable: this.isEditable(props),
      isCancelable: this.isCancelable(props),
      isLocalized: hasLocalizedContent(props.content.models, 'orders'),
      hasEditableItems: this.hasEditableItems(props),
      hasCancelableItems: this.hasCancelableItems(props, cancelableItems),
      cancelableItems,
      hasDeliverableItems: getShippableItems(props.record.items).some(
        (item) => item.quantity_deliverable > 0,
      ),
      hasReturnableItems: (props.record.items || []).some(
        (item) => item.quantity_returnable > 0,
      ),
      isPaid: props.record.paid,
    };
  }

  isEditable({ editable, record }) {
    if (editable) {
      return true;
    }
    if (!record.canceled && !record.complete && !record.order_id) {
      return true;
    }
    return false;
  }

  isCancelable({ isCart, record }) {
    if (isCart || record.canceled) {
      return false;
    }
    if (record.item_quantity_delivered > 0) {
      return false;
    }
    return true;
  }

  hasEditableItems({ isCart, record }) {
    if (isCart && record.order_id) {
      return false;
    }
    if (record.canceled || record.paid) {
      return false;
    }
    return true;
  }

  hasCancelableItems({ isCart, record }, cancelableItems = []) {
    if (isCart || record.canceled) {
      return false;
    }
    if (!cancelableItems.length) {
      return false;
    }
    return true;
  }

  onClickDelete = (event) => {
    event.preventDefault();

    const { record, title = 'Order' } = this.props;

    this.context.openModal('ConfirmDelete', {
      title: `this ${title}`,
      confirmInput: {
        description: 'Enter the order number to confirm and delete',
        value: record.number,
      },
      onConfirm: () => {
        this.props.onDelete();
      },
    });
  };

  onClickCancel = (event) => {
    event.preventDefault();

    const { record } = this.props;

    this.context.openModal('Confirm', {
      title: 'Cancel order',
      message: (
        <p>
          Are you sure you want to cancel <b>Order {record.number}</b>? This
          action can&apos;t be undone.
        </p>
      ),
      action: 'Cancel order',
      actionType: 'danger',
      onConfirm: () => {
        this.props.onEditValues({
          canceled: true,
        });
      },
    });
  };

  onClickPrintPreview = () => {
    this.setState({ showPrintPreview: false });

    const iframe = document.frames
      ? document.frames['template-preview-iframe']
      : document.querySelector('#template-preview-iframe');
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();
  };

  onClickResendEmail = (event) => {
    event.preventDefault();
    const { record, onResendEmail } = this.props;
    const { template, title, lastsent } = event.currentTarget.dataset;
    const lowerTitle = title.toLowerCase();
    if (!record.account) {
      this.context.openModal('Confirm', {
        title: `Re-send ${lowerTitle}`,
        message: 'Unable to send email notification. Customer not found.',
        actionHidden: true,
      });
      return;
    }
    this.context.openModal('Confirm', {
      title: `Re-send ${lowerTitle}`,
      message: (
        <p>
          Are you sure you want to re-send <b>{lowerTitle}</b> to{' '}
          {record.account.email}?
          <br />
          <br />
          <span className="muted">
            This email was last sent on {formatDate(lastsent, 'shortExact')}
          </span>
        </p>
      ),
      action: 'Send email',
      onConfirm: () => {
        onResendEmail(record.id, template, title);
      },
    });
  };

  onChangeOrderPrintTemplate = async (event, value) => {
    const { fetchPrintTemplate, record } = this.props;

    if (value) {
      const result = await fetchPrintTemplate(value);

      this.setState({
        printTemplateValues: {
          ...result,
          content: result.content || result.defaultContent,
          fields:
            result.record.fields ||
            (result.content === result.defaultContent
              ? result.defaultRecord.fields
              : null),
          preview_locale: record.display_locale,
        },
        isDefaultTemplate: result.record.default,
      });
    }
  };

  onClickShowOrderPrintModal = async (event) => {
    event.preventDefault();

    const { fetchPrintTemplate, fetchOrderPrintTemplates, record } = this.props;

    const { payload } = await fetchOrderPrintTemplates();
    const defaultTemplate = payload.results.find(
      (template) => template.default,
    );
    const result = await fetchPrintTemplate(defaultTemplate.id);

    this.setState({
      printTemplateDefaults: {
        ...result,
        content: result.content || result.defaultContent,
        fields:
          result.record.fields ||
          (result.content === result.defaultContent
            ? result.defaultRecord.fields
            : null),
      },
      printTemplateValues: {
        preview_locale: record.display_locale,
      },
      showPrintPreview: true,
    });
  };

  handleClosePrintModal = (event) => {
    event.preventDefault();

    return this.setState({ showPrintPreview: false });
  };

  onClickAddItem = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.state.showAddItem && this.props.edited) {
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.props.onEdited(this.state.editAddEdited);
          this.setState({ showAddItem: false });
        },
      });
    } else {
      this.setState((state, props) => ({
        showAddItem: !state.showAddItem,
        addItemProduct: null,
        editAddItem: null,
        editAddIndex: null,
        editAddEdited: state.showEditItems ? props.edited : false,
      }));
    }
  };

  onClickEditItems = (event) => {
    event.preventDefault();

    this.setState((state, props) => ({
      showEditItems: !state.showEditItems,
      editItems: (props.record.items || []).map((item) => ({
        ...item,
      })),
    }));
  };

  onClickEditSingleItem = (event) => {
    event.preventDefault();

    const { id, index } = event.currentTarget.dataset;

    this.setState((state, props) => {
      const editItems = state.showEditItems
        ? state.editItems
        : props.record.items || [];

      return {
        showAddItem: true,
        addItemProduct: null,
        editAddItem: id
          ? find(editItems, (item, i) => item.id === id)
          : editItems[index],
        editAddIndex: id ? null : index,
      };
    });
  };

  onCloseEditItems = (event) => {
    if (this.props.edited) {
      event.preventDefault();
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.onClickEditItems(event);
        },
      });
    } else {
      this.onClickEditItems(event);
    }
  };

  onClickCancelItems = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState((state) => ({
      showCancelItems: !state.showCancelItems,
    }));
  };

  onCloseCancelItems = (event) => {
    if (this.props.edited) {
      event.preventDefault();
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.onClickCancelItems(event);
        },
      });
    } else {
      this.onClickCancelItems(event);
    }
  };

  onClickEditCustomer = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showEditCustomer: !state.showEditCustomer,
    }));
  };

  onClickEditAddress = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showEditAddress: !state.showEditAddress,
    }));
  };

  onClickShippingEdit = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showShippingEdit: !state.showShippingEdit,
    }));
  };

  onClickShippingGetRates = (event) => {
    event.preventDefault();
    this.props.onShippingGetRates();
  };

  onClickDiscountsEdit = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showDiscountsEdit: !state.showDiscountsEdit,
    }));
  };

  onClickPaymentEdit = async (event) => {
    event.preventDefault();

    if (!this.state.showPaymentEdit) {
      await this.props.onLoadBilling();
    }

    this.setState((state) => ({
      showPaymentEdit: !state.showPaymentEdit,
    }));
  };

  onClickPaymentCharge = async (event) => {
    event.preventDefault();

    if (!this.state.showPaymentCharge) {
      await this.props.onLoadBilling();
    }

    this.setState((state) => ({
      paymentId: null,
      showPaymentCharge: !state.showPaymentCharge,
    }));
  };

  onClickPaymentRefund = (event) => {
    event.preventDefault();

    const paymentId = event.currentTarget.dataset.id;

    this.setState((state) => ({
      paymentId,
      showPaymentRefund: !state.showPaymentRefund,
    }));
  };

  onClickPaymentView = (event) => {
    event.preventDefault();

    const { id } = event.currentTarget.dataset;

    this.setState(
      (state) => ({
        paymentId: id,
        refundId: null,
        showPaymentView: !state.showPaymentView,
      }),
      () => {
        if (id && !this.state.showPaymentView) {
          this.setState({ showPaymentView: true });
        }
      },
    );
  };

  onClickRefundView = (event) => {
    event.preventDefault();

    const { paymentid, id } = event.currentTarget.dataset;

    this.setState(
      (state) => ({
        paymentId: paymentid,
        refundId: id,
        showPaymentView: !state.showPaymentView,
      }),
      () => {
        if (id && !this.state.showPaymentView) {
          this.setState({ showPaymentView: true });
        }
      },
    );
  };

  onClickFulfillmentAdd = (event) => {
    event.preventDefault();

    const { record } = this.props;

    if (!this.state.showFulfillmentAdd) {
      const hasAddress =
        record.shipping && record.shipping.address1 && record.shipping.country;
      const isPickup = record.shipping && record.shipping.pickup;
      if (!hasAddress && !isPickup) {
        this.context.openModal('Confirm', {
          title: 'Shipping address required',
          message: <p>Enter a shipping address before fulfilling this order</p>,
          action: 'Edit shipping',
          onConfirm: () => {
            this.setState({
              showEditAddress: true,
              pendingFulfillmentAdd: true,
            });
          },
        });
        return;
      }
    }

    this.setState((state) => ({
      showFulfillmentAdd: !state.showFulfillmentAdd,
    }));
  };

  onClickFulfillmentEdit = (event) => {
    event.preventDefault();

    const shipmentId = event.currentTarget.dataset.id;

    this.setState((state) => ({
      shipmentId,
      showFulfillmentEdit: !state.showFulfillmentEdit,
    }));
  };

  onClickFulfillmentDelete = (event) => {
    event.preventDefault();

    this.context.openModal('ConfirmDelete', {
      title: 'Shipment',
      onConfirm: () => {
        this.props.onFulfillmentDelete(this.state.shipmentId);
        this.setState({
          showFulfillmentEdit: false,
        });
      },
    });
  };

  onClickFulfillmentGiftcard = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showFulfillmentGiftcard: !state.showFulfillmentGiftcard,
    }));
  };

  onClickReturnAdd = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showReturnAdd: !state.showReturnAdd,
    }));
  };

  onClickReturnEdit = (event) => {
    event.preventDefault();

    const returnId = event.currentTarget.dataset.id;

    this.setState((state) => ({
      returnId,
      showReturnEdit: !state.showReturnEdit,
    }));
  };

  onClickReturnDelete = (event) => {
    event.preventDefault();

    this.context.openModal('ConfirmDelete', {
      title: 'Return',
      onConfirm: () => {
        this.props.onReturnDelete(this.state.returnId);

        this.setState({ showReturnEdit: false });
      },
    });
  };

  onChangeAddProductLookup = (_event, value) => {
    this.setState({ addItemProduct: value || null });
  };

  onClickRemoveTag = (event) => {
    event.preventDefault();
  };

  async updateStateModalLoading(handler, state = {}) {
    this.setState({ modalLoading: true });
    const result = await handler();
    if (result) {
      this.setState({
        ...state,
        modalLoading: false,
      });
    } else {
      this.setState({ modalLoading: false });
    }
  }

  onSubmitAddTag = (tag) => {};

  onSubmitAddItem = async (values) => {
    const { addItemProduct, editAddItem, editAddIndex } = this.state;
    const itemProduct = editAddItem ? editAddItem.product : addItemProduct;

    const addItem = {
      ...values,
      quantity: values.quantity || 1,
      product: itemProduct,
      product_id: itemProduct.id,
      variant_id: null,
      bundle_items: itemProduct.bundle_items,
      options: getCleanItemOptions(itemProduct, values.options),
    };

    if (values.bundle_options) {
      addItem.bundle_items = itemProduct.bundle_items.map((item) => ({
        ...item,
        options: getCleanItemOptions(
          item.product,
          values.bundle_options[item.product_id],
          item.options,
        ),
      }));
    }

    if (addItem.set_price === null) {
      this.setState({ modalLoading: true });
      const pricedItem = await this.props.getItemPrice(
        pick(addItem, [
          'id',
          'product_id',
          'options',
          'quantity',
          'purchase_option',
        ]),
      );
      if (pricedItem && !pricedItem.errors) {
        addItem.price = addItem.set_price = pricedItem.price;
      }
    } else {
      addItem.price = addItem.set_price;
    }

    if (
      isTrialItem(addItem) &&
      addItem.date_trial_end &&
      addItem.date_trial_end_time
    ) {
      addItem.date_trial_end = combineDateTime(
        values.date_trial_end,
        values.date_trial_end_time,
      );

      addItem.date_trial_end_time = undefined;
      if (new Date(addItem.date_trial_end) < new Date()) {
        this.context.notifyError('Try period end date must be in the future');
        return;
      }
    }

    if (this.state.showEditItems) {
      addItem.variant = getVariantByOptions(addItem.product, addItem.options);

      const editItems = [...this.state.editItems];
      if (addItem.id) {
        editItems[findIndex(editItems, { id: addItem.id })] = addItem;
      } else if (editAddIndex !== null) {
        editItems[editAddIndex] = addItem;
      } else {
        editItems.push(addItem);
      }

      this.props.onEdited(true);
      this.setState({
        showAddItem: false,
        addItemProduct: null,
        editItems,
        modalLoading: false,
      });
    } else {
      this.updateStateModalLoading(() => this.props.onAddItem(addItem), {
        showAddItem: false,
        addItemProduct: null,
      });
    }
  };

  onSubmitEditItems = () => {
    this.updateStateModalLoading(
      () => this.props.onEditItems(this.state.editItems),
      {
        showEditItems: false,
        editItems: [],
      },
    );
  };

  onSubmitCancelItems = (values) => {
    this.updateStateModalLoading(() => this.props.onEditValues(values), {
      showCancelItems: false,
    });
  };

  onSubmitEditCustomer = (values) => {
    this.updateStateModalLoading(
      () =>
        this.props.onEditValues({
          account: values.account,
          shipping: {
            ...values.shipping,
            default: !!values.auto_update_account_address,
          },
          billing: {
            ...values.billing,
            default: !!values.auto_update_account_address,
          },
          account_info_saved: !!values.auto_update_account_address,
          auto_update_account_address: !!values.auto_update_account_address,
        }),
      {
        showEditCustomer: false,
        ...(this.state.pendingFulfillmentAdd && {
          showFulfillmentAdd: true,
          pendingFulfillmentAdd: false,
        }),
      },
    );
  };

  onSubmitEditAddress = (values) => {
    this.updateStateModalLoading(
      () =>
        this.props.onEditValues({
          account: values.account,
          shipping: {
            ...values.shipping,
            default: !!values.auto_update_account_address,
          },
        }),
      {
        showEditAddress: false,
        ...(this.state.pendingFulfillmentAdd && {
          showFulfillmentAdd: true,
          pendingFulfillmentAdd: false,
        }),
      },
    );
  };

  onSubmitShippingEdit = (values) => {
    this.updateStateModalLoading(
      () =>
        this.props.onEditValues({
          shipping: values.shipping,
          account_info_saved: true,
        }),
      {
        showShippingEdit: false,
      },
    );
  };

  onSubmitDiscountsEdit = (values) => {
    this.updateStateModalLoading(
      () =>
        this.props.onEditValues({
          coupon_code: values.coupon_code,
          promotion_ids: {
            $set: values.promotion_ids,
          },
        }),
      {
        showDiscountsEdit: false,
      },
    );
  };

  onSubmitPaymentEdit = (values) => {
    this.updateStateModalLoading(
      () =>
        this.props.onPaymentEdit({
          billing: values.billing,
          giftcard_code: values.giftcard_code,
          account_info_saved: true,
        }),
      {
        showPaymentEdit: false,
      },
    );
  };

  onSubmitPaymentCharge = (values) => {
    this.updateStateModalLoading(() => this.props.onPaymentCharge(values), {
      showPaymentCharge: false,
    });
  };

  onSubmitPaymentRefund = (values) => {
    this.updateStateModalLoading(() => this.props.onPaymentRefund(values), {
      showPaymentRefund: false,
    });
  };

  onSubmitPaymentView = (values) => {
    const { paymentId, refundId } = this.state;

    this.updateStateModalLoading(
      () =>
        refundId
          ? this.props.onRefundUpdate(refundId, values)
          : this.props.onPaymentUpdate(paymentId, values),
      {
        showPaymentView: false,
      },
    );
  };

  onSubmitFulfillmentAdd = (values) => {
    this.updateStateModalLoading(() => this.props.onFulfillmentAdd(values), {
      showFulfillmentAdd: false,
    });
  };

  onSubmitFulfillmentEdit = (values) => {
    this.updateStateModalLoading(
      () => this.props.onFulfillmentEdit(this.state.shipmentId, values),
      {
        showFulfillmentEdit: false,
      },
    );
  };

  onSubmitFulfillmentGiftcard = (values) => {
    this.updateStateModalLoading(() => this.props.onGiftcardSend(values), {
      showFulfillmentGiftcard: false,
    });
  };

  onSubmitReturnAdd = (values) => {
    this.updateStateModalLoading(() => this.props.onReturnAdd(values), {
      showReturnAdd: false,
    });
  };

  onSubmitReturnEdit = (values) => {
    this.updateStateModalLoading(
      () => this.props.onReturnEdit(this.state.returnId, values),
      {
        showReturnEdit: false,
      },
    );
  };

  onSubmitNote = async (values) => {
    const { record, updateRecord } = this.props;

    await updateRecord(record.id, { notes: values.notes.trim() });
  };

  onClickRemoveEditItem = (event) => {
    event.preventDefault();
    const { index } = event.currentTarget.dataset;
    const editItems = [...this.state.editItems];
    if (editItems[index].id || editItems[index].removed) {
      editItems[index].removed = !editItems[index].removed;
    } else {
      editItems.splice(index, 1);
    }
    this.props.onEdited(true);
    this.setState({ editItems });
  };

  onClickRemoveEditSingleItem = (event) => {
    if (event) {
      event.preventDefault();
    }

    const { id, index } = event.currentTarget.dataset;
    if (this.state.showEditItems) {
      const editItems = [...this.state.editItems];
      if (id) {
        editItems[findIndex(editItems, { id })].removed = true;
      } else {
        editItems.splice(index, 1);
      }
      this.props.onEdited(true);
      this.setState({
        showAddItem: false,
        editItems,
      });
    } else {
      this.context.openModal('Confirm', {
        title: 'Remove item',
        message: (
          <p>
            Are you sure you want to remove this item? This action can&apos;t be
            undone.
          </p>
        ),
        action: 'Remove',
        actionType: 'danger',
        onConfirm: () => {
          const removedItem = { ...this.state.editAddItem, removed: true };
          this.updateStateModalLoading(
            () => this.props.onEditItems([removedItem]),
            {
              showAddItem: false,
            },
          );
        },
      });
    }
  };

  onChangeEditItemInput = (event, value) => {
    const editItems = [...this.state.editItems];
    set(editItems, event.target.name, value);
    const index = event.target.name.split('.')[0];

    const editItem = editItems[index];

    if (editItem.quantity <= 0 && editItem.quantity.trim() !== '') {
      editItem.quantity = '0';
      editItem.removed = true;
      editItem.calc_price = undefined;
    } else {
      const now = Date.now();
      editItem.pricingTime = now;

      if (this.editItemPriceTimer) {
        clearTimeout(this.editItemPriceTimer);
      }

      this.editItemPriceTimer = setTimeout(() => {
        this.editItemPriceTimer = 0;

        this.props
          .getItemPrice(
            pick(editItem, [
              'id',
              'product_id',
              'options',
              'quantity',
              'purchase_option',
            ]),
          )
          .then((pricedItem) => {
            if (this.state.showEditItems && pricedItem && !pricedItem.errors) {
              const postEditItems = [...this.state.editItems];
              if (get(postEditItems[index], 'pricingTime') === now) {
                postEditItems[index].calc_price = pricedItem.price;
                this.setState({ editItems: postEditItems });
              }
            }
          });
      }, 250);
    }

    this.setState({ editItems });
  };

  onClickSendInvoice = (event) => {
    event.preventDefault();

    const { record, fetchNotification } = this.props;
    const { title } = event.currentTarget.dataset;
    const lowerTitle = title.toLowerCase();

    if (!record.account) {
      this.context.openModal('Confirm', {
        title: `Send ${lowerTitle}`,
        message: 'Unable to send invoice notification. Customer not found.',
        actionHidden: true,
      });
      return;
    }

    fetchNotification('carts.invoice').then((result) => {
      this.setState({
        invoiceNotificationDefaults: {
          ...result,
          content: result.content || result.defaultContent,
          fields:
            result.record.fields ||
            (result.content === result.defaultContent
              ? result.defaultRecord.fields
              : null),
        },
        invoiceNotificationValues: {
          preview_locale: record.display_locale,
        },
        showSendInvoice: true,
      });
    });
  };

  onSendInvoiceModalSubmit = () => {
    const { record, onSendInvoice } = this.props;
    const { invoiceNotificationValues } = this.state;

    return onSendInvoice(record.id, {
      subject: invoiceNotificationValues.subject,
      to: invoiceNotificationValues.to,
      locale: invoiceNotificationValues.preview_locale,
    }).then(() => this.setState({ showSendInvoice: false }));
  };

  onChangeNotificationForm = (invoiceNotificationValues) => {
    this.setState((state) => {
      const { preview_locale } = state.invoiceNotificationValues;

      if (preview_locale !== invoiceNotificationValues.preview_locale) {
        invoiceNotificationValues.subject = null; // set locale-default subject
      }

      return {
        invoiceNotificationValues: { ...invoiceNotificationValues },
      };
    });
  };

  renderSendInvoiceModal = () => {
    const {
      invoiceNotificationDefaults: defaults,
      invoiceNotificationValues: values,
      modalLoading,
    } = this.state;
    const { record } = this.props;
    const { client } = this.context;
    const hasLocales = client.locales.length > 0;
    let subjectValue =
      values.subject || record.subject || defaults.defaultRecord.sub;

    if (values.preview_locale) {
      const context =
        values.$locale || values.subject ? values : defaults.record;
      subjectValue = localeFallbackValue({
        context,
        name: 'subject',
        locale: values.preview_locale,
        localeConfigs: client.locales,
      });
    }

    return (
      <Form
        ref={(form) => (this.noteForm = form)}
        onChange={this.onChangeNotificationForm}
      >
        <Modal
          title="Send invoice"
          width={960}
          actions={[
            {
              label: 'Send',
              type: 'submit',
              onClick: this.onSendInvoiceModalSubmit,
            },
          ]}
          loading={modalLoading}
          onClose={() => this.setState({ showSendInvoice: false })}
          localized={false}
        >
          <p>
            Your customer will receive the following email with a checkout link
            to complete the order.
          </p>

          <div className="row">
            <Field
              type="text"
              label="Email address"
              className={hasLocales ? 'span2' : 'span4'}
              name="to"
              defaultValue={record.account.email}
            />

            <LocaleFieldSelect
              name="preview_locale"
              label="Notification locale"
              className={hasLocales ? 'span2' : 'span4'}
              defaultValue={values.preview_locale || client.locale}
            />
          </div>

          <div>
            <div>
              <Field
                type="text"
                name="subject"
                label="Email subject"
                defaultValue={subjectValue}
              />
            </div>

            <div className="form-field">
              <TemplatePreview
                className="invoice-action-preview"
                client={client}
                record={record}
                values={values}
                defaults={defaults}
                getTemplateEngine={this.getTemplateEngine}
              />
            </div>
          </div>
        </Modal>
      </Form>
    );
  };

  onClickHold = (event) => {
    event.preventDefault();

    this.props.onEditValues({
      hold: !this.props.record.hold,
    });
  };

  lastSentNotification(template) {
    const { related } = this.props;
    if (!related.notifications) {
      return false;
    }
    for (const result of related.notifications.results) {
      if (result.template === template) {
        return result.date_created;
      }
    }
    return false;
  }

  getCheckoutLink() {
    const {
      record: { checkout_id },
      checkoutCustomUrl = '',
    } = this.props;

    return getCheckoutUrl(checkout_id, checkoutCustomUrl, this.context.testEnv);
  }

  getTemplateEngine = () => {
    const { record = {} } = this.props;
    const { client } = this.context;

    const $locale = get(record, 'account.locale', record.display_locale);

    if (this.templateEngine) {
      return this.templateEngine;
    }
    return (this.templateEngine = new TemplateEngine({
      get: (url, data) => {
        return api.get(`/data/${url}`, { $locale, ...(data || {}) });
      },
      details: {
        ...client,
        currency: get(this.props.record || client, 'currency'),
      },
    }));
  };

  renderOrderPrintModal = () => {
    const {
      props: { notifications, loading, record, isCart, settings },
      state: {
        printTemplateDefaults: defaults,
        printTemplateValues: values,
        isDefaultTemplate,
      },
      context: { client },
    } = this;
    const templates = get(notifications, 'orderPrintTemplates.results', []).map(
      (template) => {
        return {
          value: template.id,
          label: template.label,
          name: template.name,
          default: template.default,
        };
      },
    );

    const { store_logo, store_width } = settings.notifications;

    return (
      <Modal
        title={`Print ${isCart ? 'cart' : 'order'}`}
        loading={loading}
        width={960}
        cancel={false}
        actions={[
          {
            label: 'Print',
            type: 'default',
            onClick: () => this.onClickPrintPreview(),
          },
          {
            label: 'Cancel',
            type: 'cancel',
            onClick: this.handleClosePrintModal,
          },
        ]}
        onClose={this.handleClosePrintModal}
      >
        {get(templates, 'length', 0) > 1 && (
          <Field
            type="select"
            label="Template"
            name="template"
            onChange={this.onChangeOrderPrintTemplate}
            value={
              get(
                templates.find((template) => template.default),
                'label',
              ) || ''
            }
            options={templates}
          />
        )}

        <TemplatePreview
          className="settings-notifications-preview-frame subject"
          client={client}
          record={record}
          values={{
            store_logo,
            store_width,
            // This check is necessary to prevent other templates from overwriting the default template during the first load.
            ...(!isDefaultTemplate && values),
          }}
          defaults={defaults}
          getTemplateEngine={this.getTemplateEngine}
          visible
        />
      </Modal>
    );
  };

  render() {
    const {
      record,
      related,
      prev = {},
      next = {},
      lookup,
      settings,
      content,
      loading: loadingProp,
      location,
      uri = '/orders',
      title = 'Order',
      sectionTitle = 'Orders',
      suggestedAddresses,
      fetchRecord,
      isCart = false,
    } = this.props;

    const {
      isEditable,
      isCancelable,
      isLocalized,
      hasEditableItems,
      hasCancelableItems,
      cancelableItems,
      hasDeliverableItems,
      hasReturnableItems,
      isPaid,
      showSendInvoice,
      showPrintPreview,
      showNotification,
      modalLoading,
    } = this.state;

    const loading = loadingProp || modalLoading;

    const contentProps = {
      content,
      lookup: this.props.lookup,
      categories: this.props.categories,
      currency: record.currency,
    };

    const lastSentOrderNotice = this.lastSentNotification('orders.receipt');

    return (
      <div className="order-view view-detail-full">
        <WithViewPreferences
          {...this.props}
          tabs={[]}
          model={isCart ? 'carts' : 'orders'}
          id="edit"
          type="record"
        >
          <View
            detail={true}
            submitting={loadingProp}
            uri={uri}
            devtools={{
              model: uri.replace('/', ''),
              record,
            }}
            withPreferences={true}
            sectionTitle={sectionTitle}
            headerTitle={
              <Fragment>
                {title} {record.number}{' '}
                {record.display_locale &&
                  record.display_locale !==
                    get(record.account, 'locale', record.display_locale) && (
                    <LocaleIcon locale={record.display_locale} />
                  )}
              </Fragment>
            }
            headerSubtitle={
              <CurrencyPicker
                pretext="Prices in"
                currency={record.currency}
                onChange={this.props.onChangeCurrency}
                editable={!isPaid}
              />
            }
            headerActions={[
              {
                component: (
                  <span key="1" className="button-group">
                    <PrevButton
                      to={`${uri}/${prev.id}`}
                      type="secondary"
                      disabled={!prev.id}
                    />
                    <NextButton
                      to={`${uri}/${next.id}`}
                      type="secondary"
                      disabled={!next.id}
                    />
                  </span>
                ),
              },
              {
                isActionMenu: true,
                component: (
                  <DropdownButton
                    key="2"
                    anchor="right"
                    type="secondary"
                    anchorPosition={49}
                    items={[
                      hasEditableItems && (
                        <button key="0" onClick={this.onClickEditItems}>
                          Edit items
                        </button>
                      ),
                      !hasEditableItems && hasCancelableItems && (
                        <button key="1" onClick={this.onClickCancelItems}>
                          Cancel items
                        </button>
                      ),
                      !isCart && !record.canceled && hasDeliverableItems && (
                        <button key="2" onClick={this.onClickFulfillmentAdd}>
                          Fulfill items
                        </button>
                      ),
                      !isCart && hasReturnableItems && (
                        <button key="3" onClick={this.onClickReturnAdd}>
                          Return items
                        </button>
                      ),
                      !isCart && !record.canceled && (
                        <button key="4" onClick={this.onClickHold}>
                          {`${record.hold ? 'Remove' : 'Put on'} hold`}
                        </button>
                      ),
                      !isCart && !record.canceled && lastSentOrderNotice && (
                        <button
                          key="5"
                          onClick={this.onClickResendEmail}
                          data-template="orders.receipt"
                          data-title="Order receipt"
                          data-lastsent={lastSentOrderNotice}
                        >
                          Resend order receipt
                        </button>
                      ),
                      isCancelable && (
                        <button key="6" onClick={this.onClickCancel}>
                          Cancel order
                        </button>
                      ),
                      <button key="7" onClick={this.onClickShowOrderPrintModal}>
                        Print {title.toLowerCase()}
                      </button>,
                      !isCart &&
                        !record.canceled &&
                        !isPaid &&
                        record.payment_balance < 0 && (
                          <button
                            key="9"
                            onClick={this.onClickSendInvoice}
                            data-template="carts.invoice"
                            data-title="invoice"
                          >
                            Send invoice
                          </button>
                        ),
                      record.subscription_id && (
                        <Link key="10" to={`/subscriptions/${record.subscription_id}`}>
                          View subscription
                        </Link>
                      ),
                      isCart && record.order_id && (
                        <Link key="11" to={`/orders/${record.order_id}`}>
                          View order
                        </Link>
                      ),
                      isCart && record.checkout_id && (
                        <a key="8" href={this.getCheckoutLink()} target="blank">
                          View checkout <Icon fa="external-link" />
                        </a>
                      ),
                    ]}
                  >
                    Actions
                  </DropdownButton>
                ),
              },
              this.state.contentRecordTab &&
                contentRecordSubmitAction(this.state.contentRecordTab),
            ]}
            extraActions={[
              {
                label: `Delete ${isCart ? 'cart' : 'order'}`,
                onClick: this.onClickDelete,
                className: 'danger',
                delete: true,
              },
            ]}
            tags={[
              related.note_count > 0 && (
                <span>
                  <a href="#notes">
                    <Icon fa="comment-alt-lines" />
                    &ensp;
                    {inflect(related.note_count, 'notes')}
                  </a>
                </span>
              ),
            ]}
            localized={isLocalized}
          >
            <TabView value={location.query.tab} isDefault>
              <fieldset className="last-child">
                <div className="view-body-section-group">
                  <table className="order-kpi-table">
                    <tbody>
                      <tr>
                        <td width="30%">
                          <span className="order-kpi-label">Created</span>
                          <span className="order-kpi-value">
                            <DateTime
                              value={record.date_created}
                              format="shortExact"
                            />
                          </span>
                        </td>

                        <td width="30%">
                          <span className="order-kpi-label">Updated</span>
                          <span className="order-kpi-value">
                            <div>
                              {record.date_updated ? (
                                <DateTime
                                  value={record.date_updated}
                                  format="shortExact"
                                />
                              ) : (
                                <span className="muted">&mdash;</span>
                              )}
                            </div>
                          </span>
                        </td>

                        <td width="40%">
                          <span className="order-kpi-label">Status</span>
                          <span className="order-kpi-value">
                            {isCart ? (
                              <Fragment>
                                <CartStatus cart={record} />
                              </Fragment>
                            ) : (
                              <Fragment>
                                <PaymentStatus order={record} blank />
                                <FulfillmentStatus order={record} blank />
                              </Fragment>
                            )}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <OrderItems
                    record={record}
                    settings={settings}
                    discounts={this.props.discounts}
                    editable={isEditable}
                    hasEditableItems={hasEditableItems}
                    hasCancelableItems={hasCancelableItems}
                    loading={loading}
                    onClickEditItems={this.onClickEditItems}
                    onClickEditSingleItem={
                      hasEditableItems ? this.onClickEditSingleItem : undefined
                    }
                    onClickAddItem={this.onClickAddItem}
                    onClickCancelItems={this.onClickCancelItems}
                    onCloseCancelItems={this.onCloseCancelItems}
                    onClickShippingEdit={this.onClickShippingEdit}
                    onClickDiscountsEdit={this.onClickDiscountsEdit}
                  />

                  <OrderContent
                    {...contentProps}
                    zone="details"
                    collection={isCart ? 'carts' : 'orders'}
                    record={record}
                    onSubmit={this.props.onEditValues}
                  />

                  {/* Don't show payment info if we are viewing a cart,
                   * or if the order came from a subscription
                   */}
                  {!isCart &&
                    (!record.subscription_id || !record.paid_by_invoice) && (
                      <OrderPayments
                        record={record}
                        settings={settings}
                        loading={loading}
                        {...contentProps}
                        onClickPaymentCharge={this.onClickPaymentCharge}
                        onClickPaymentRefund={this.onClickPaymentRefund}
                        onClickPaymentView={this.onClickPaymentView}
                        onClickRefundView={this.onClickRefundView}
                        onClickEditSingleItem={
                          hasEditableItems
                            ? this.onClickEditSingleItem
                            : undefined
                        }
                      />
                    )}

                  {!isCart &&
                    (record.shipment_delivery ||
                      record.giftcard_delivery ||
                      record.subscription_delivery) && (
                      <OrderFulfillment
                        record={record}
                        loading={loading}
                        {...contentProps}
                        giftcardsDelivered={related.giftcards_delivered}
                        subscriptionsDelivered={related.subscriptions_delivered}
                        hasReturnableItems={hasReturnableItems}
                        onClickFulfillmentAdd={this.onClickFulfillmentAdd}
                        onClickFulfillmentEdit={this.onClickFulfillmentEdit}
                        onClickFulfillmentGiftcard={
                          this.onClickFulfillmentGiftcard
                        }
                        onClickReturnAdd={this.onClickReturnAdd}
                        onClickReturnEdit={this.onClickReturnEdit}
                      />
                    )}

                  <OrderContent
                    {...contentProps}
                    zone="content"
                    collection={isCart ? 'carts' : 'orders'}
                    record={record}
                    onSubmit={this.props.onEditValues}
                  />
                  {!isCart && (
                    <Fragment>
                      {record.notes && (
                        <OrderNotes
                          deprecated
                          label="Order notes"
                          record={record}
                          onSubmit={this.onSubmitNote}
                        />
                      )}

                      <ActivityFeed
                        model="orders"
                        record={record}
                        label="Order activity"
                      />
                    </Fragment>
                  )}
                </div>
              </fieldset>
            </TabView>

            <ContentRecordTab
              {...this.props}
              collection={isCart ? 'carts' : 'orders'}
              view="edit"
              form={true}
              {...contentRecordSubmitChangeHandlers(
                this,
                this.props.onEditValues,
              )}
            />

            <Side>
              <OrderCustomer
                record={record}
                content={content}
                settings={settings}
                addable={false}
                onClickEditCustomer={this.onClickEditCustomer}
                onClickEditAddress={this.onClickEditAddress}
                onClickPaymentEdit={this.onClickPaymentEdit}
                onClickManageAddresses={this.onClickManageAddresses}
              />
            </Side>
          </View>
        </WithViewPreferences>

        {this.state.showEditItems && (
          <OrderItemsEdit
            record={record}
            loading={loading}
            editItems={this.state.editItems}
            single={this.state.showEditSingleItem}
            onEdited={this.props.onEdited}
            onSubmitEditItems={this.onSubmitEditItems}
            onClickEditItems={this.onClickEditItems}
            onClickEditSingleItem={
              hasEditableItems ? this.onClickEditSingleItem : undefined
            }
            onClickRemoveEditItem={this.onClickRemoveEditItem}
            onClickAddItem={this.onClickAddItem}
            onCloseEditItems={this.onCloseEditItems}
            onChangeEditItemInput={this.onChangeEditItemInput}
          />
        )}

        {this.state.showCancelItems && (
          <OrderItemsCancel
            record={record}
            loading={loading}
            onEdited={this.props.onEdited}
            cancelableItems={cancelableItems}
            onSubmitCancelItems={this.onSubmitCancelItems}
            onClickCancelItems={this.onClickCancelItems}
            onCloseCancelItems={this.onCloseCancelItems}
          />
        )}

        {this.state.showAddItem && (
          <OrderItemsAdd
            lookup={lookup}
            loading={loading}
            isCart={isCart}
            addItemProduct={this.state.addItemProduct}
            onSubmitAddItem={this.onSubmitAddItem}
            onClickAddItem={this.onClickAddItem}
            onChangeAddProductLookup={this.onChangeAddProductLookup}
            onClickRemoveEditSingleItem={this.onClickRemoveEditSingleItem}
            getItemPrice={this.props.getItemPrice}
            editAddItem={this.state.editAddItem}
            editAddIndex={this.state.editAddIndex}
            onEdited={this.state.editAddItem && this.props.onEdited}
            currency={record.currency}
          />
        )}
        {this.state.showEditCustomer && (
          <OrderCustomerEdit
            record={record}
            settings={settings}
            loading={loading}
            billing={record.billing}
            suggestedAddresses={suggestedAddresses}
            shipping={record.shipping}
            onSubmitEditCustomer={this.onSubmitEditCustomer}
            onClickEditCustomer={this.onClickEditCustomer}
            showAutoUpdateAddress
          />
        )}

        {this.state.showEditAddress && (
          <OrderAddressEdit
            record={record}
            loading={loading}
            shipping={record.shipping}
            onSubmitEditAddress={this.onSubmitEditAddress}
            onClickEditAddress={this.onClickEditAddress}
            onClickEditShipping={this.onClickShippingEdit}
            suggestedAddresses={suggestedAddresses}
          />
        )}

        {this.state.showPaymentEdit && (
          <OrderPaymentEdit
            loading={loading}
            fetchRecord={fetchRecord}
            onSubmitPaymentEdit={this.onSubmitPaymentEdit}
            onClickPaymentEdit={this.onClickPaymentEdit}
          />
        )}

        {this.state.showShippingEdit && (
          <OrderShippingEdit
            record={record}
            settings={settings}
            loading={loading}
            onSubmitShippingEdit={this.onSubmitShippingEdit}
            onClickShippingEdit={this.onClickShippingEdit}
            onClickShippingGetRates={this.onClickShippingGetRates}
          />
        )}

        {this.state.showDiscountsEdit && (
          <OrderDiscountsEdit
            record={record}
            loading={loading}
            lookup={lookup}
            promotions={true}
            discounts={this.props.discounts}
            onSubmitDiscountsEdit={this.onSubmitDiscountsEdit}
            onClickDiscountsEdit={this.onClickDiscountsEdit}
          />
        )}

        {this.state.showPaymentCharge && (
          <OrderPaymentCharge
            record={record}
            loading={loading}
            settings={settings}
            fetchRecord={fetchRecord}
            onSubmitPaymentCharge={this.onSubmitPaymentCharge}
            onClickPaymentCharge={this.onClickPaymentCharge}
            {...contentProps}
          />
        )}

        {this.state.showPaymentRefund && (
          <OrderPaymentRefund
            record={record}
            loading={loading}
            settings={settings}
            {...contentProps}
            paymentId={this.state.paymentId}
            onSubmitPaymentRefund={this.onSubmitPaymentRefund}
            onClickPaymentRefund={this.onClickPaymentRefund}
          />
        )}

        {this.state.showPaymentView && (
          <OrderPaymentView
            record={record}
            loading={loading}
            settings={settings}
            {...contentProps}
            paymentId={this.state.paymentId}
            refundId={this.state.refundId}
            onSubmitPaymentView={this.onSubmitPaymentView}
            onClickPaymentView={this.onClickPaymentView}
            onClickRefundView={this.onClickRefundView}
          />
        )}

        {this.state.showFulfillmentAdd && (
          <OrderFulfillmentAdd
            record={record}
            loading={loading}
            settings={settings}
            {...contentProps}
            onSubmitFulfillmentAdd={this.onSubmitFulfillmentAdd}
            onClickFulfillmentAdd={this.onClickFulfillmentAdd}
          />
        )}

        {this.state.showFulfillmentEdit && (
          <OrderFulfillmentEdit
            record={record}
            loading={loading}
            settings={settings}
            {...contentProps}
            shipmentId={this.state.shipmentId}
            onSubmitFulfillmentEdit={this.onSubmitFulfillmentEdit}
            onClickFulfillmentEdit={this.onClickFulfillmentEdit}
            onClickFulfillmentDelete={this.onClickFulfillmentDelete}
          />
        )}

        {this.state.showFulfillmentGiftcard && (
          <OrderFulfillmentGiftcard
            record={record}
            loading={loading}
            onClickFulfillmentGiftcard={this.onClickFulfillmentGiftcard}
            onSubmitFulfillmentGiftcard={this.onSubmitFulfillmentGiftcard}
          />
        )}

        {this.state.showReturnAdd && (
          <OrderReturnAdd
            record={record}
            loading={loading}
            settings={settings}
            {...contentProps}
            onSubmitReturnAdd={this.onSubmitReturnAdd}
            onClickReturnAdd={this.onClickReturnAdd}
          />
        )}

        {this.state.showReturnEdit && (
          <OrderReturnEdit
            record={record}
            loading={loading}
            settings={settings}
            {...contentProps}
            returnId={this.state.returnId}
            onSubmitReturnEdit={this.onSubmitReturnEdit}
            onClickReturnEdit={this.onClickReturnEdit}
            onClickReturnDelete={this.onClickReturnDelete}
          />
        )}

        {this.state.showManageAddresses && (
          <ManageAddresses
            fetchRecord={fetchRecord}
            loading={loading}
            onClickManageAddresses={this.onClickManageAddresses}
          />
        )}

        {showPrintPreview && this.renderOrderPrintModal()}

        {showSendInvoice && this.renderSendInvoiceModal()}

        {showNotification && this.showNotification()}
      </div>
    );
  }
}
