import React from 'react';
import pt from 'prop-types';

import { snakeCase } from 'utils';
import { countryName, countryOptions } from 'utils/geo';

import { Field, Fieldtable, Fieldlist } from 'components/form';
import { FadeIn } from 'components/transitions';

export default class ShippingZones extends React.PureComponent {
  static propTypes = {
    zones: pt.array,

    onSubmit: pt.func,
  };

  onSubmitModal = (values) => {
    if (!values.id) {
      values.id = snakeCase(values.name);
    }
  };

  renderZoneHeading = () => {
    return [<th key="1">Zone</th>, <th key="2">Regions</th>];
  };

  renderZoneValue = ({ value }) => {
    return [
      <td key="1" className="nowrap">
        {value.name}
      </td>,
      <td key="3">
        {value.remaining ? (
          'All remaining areas'
        ) : value.rules && value.rules.length > 0 ? (
          value.rules.map((rule, index) => (
            <div key={index}>
              <span>
                <u>{countryName(rule.country)}</u>{' '}
                {this.renderZoneRuleFilter(rule)}
              </span>
            </div>
          ))
        ) : (
          <span className="muted">&mdash;</span>
        )}
      </td>,
    ];
  };

  renderZoneRuleFilter(rule, only = false) {
    const hasFilters =
      rule.filter_type && (rule.filter_states || rule.filter_zipcodes);

    if (hasFilters) {
      let prefix = rule.filter_type === 'include' ? 'including' : 'excluding';
      if (only) {
        prefix = prefix.charAt(0).toUpperCase() + prefix.slice(1);
      }

      const filterStates =
        (rule.filter_states && rule.filter_states.split('\n').join(', ')) || '';
      const filterZipcodes =
        (rule.filter_zipcodes && rule.filter_zipcodes.split('\n').join(', ')) ||
        '';

      return (
        <span className="wrap-words">
          {prefix}&nbsp;
          {filterStates}{' '}
          {filterStates.length && filterZipcodes.length ? 'and' : ''}{' '}
          {filterZipcodes}
        </span>
      );
    }

    return null;
  }

  renderZoneForm = (values) => {
    return (
      <div>
        <Field type="hidden" name="id" defaultValue={values.id} />
        <Field
          type="text"
          name="name"
          label="Zone name"
          defaultValue={
            values.name || (values.remaining ? 'Rest of world' : '')
          }
          required={true}
        />
        <Field
          type="toggle"
          name="remaining"
          label="Include all remaining areas"
          defaultChecked={values.remaining}
        />
        <FadeIn active={!values.remaining}>
          <br />
          <div className="modal-body-subheader">
            <h3 className="modal-body-subtitle">Zone regions</h3>
            <p>Specify regions that make up this shipping zone</p>
          </div>
          <Fieldlist
            label="Region"
            name="rules"
            value={values.rules}
            render={this.renderRuleValues}
            fieldLabels={true}
          />
          {/*<Fieldtable
            label={`${values.name ? values.name : ''} Rule`}
            name="rules"
            defaultValue={values.rules}
            renderHeading={this.renderRuleHeading}
            renderValue={this.renderRuleValue}
            renderForm={this.renderRuleForm}
            formWidth={550}
            />*/}
        </FadeIn>
      </div>
    );
  };

  renderRuleHeading() {
    return [<th key="1">Country</th>, <th key="2">Filter</th>];
  }

  renderRuleValue = ({ value }) => {
    return [
      <td key="1">{countryName(value.country)}</td>,
      <td key="2">
        {this.renderZoneRuleFilter(value, true) || (
          <span className="muted">None</span>
        )}
      </td>,
    ];
  };

  renderRuleForm = (values) => {
    return (
      <div>
        <div className="row">
          <Field
            type="select"
            name="country"
            label="Country"
            defaultValue={values.country || undefined}
            options={[...countryOptions]}
            placeholder="Choose one"
            className="span2"
            required={true}
          />
          <Field
            type="select"
            name="filter_type"
            label="Filter"
            defaultValue={values.filter_type || ''}
            options={[
              { value: null, label: '' },
              { value: 'include', label: 'Including' },
              { value: 'exclude', label: 'Excluding' },
            ]}
            placeholder="Optional"
            className="span2"
          />
        </div>
        {values.filter_type === 'include' && (
          <div>
            <FadeIn>
              <p>
                Narrow by <u>including</u> specific states or zip/postal codes
              </p>
              <div className="row">
                <Field
                  type="textarea"
                  name="filter_states"
                  label="Include states"
                  defaultValue={values.filter_states || ''}
                  rows="8"
                  placeholder="Separate states by new line"
                  className="span2"
                />
                <Field
                  type="textarea"
                  name="filter_zipcodes"
                  label="Include zip/postal codes"
                  defaultValue={values.filter_zipcodes || ''}
                  rows="8"
                  placeholder="Separate codes by new line"
                  className="span2"
                />
              </div>
            </FadeIn>
          </div>
        )}
        {values.filter_type === 'exclude' && (
          <div>
            <FadeIn>
              <p>
                Narrow by <u>excluding</u> specific states or zip/postal codes
              </p>
              <div className="row">
                <Field
                  type="textarea"
                  name="filter_states"
                  label="Exclude states"
                  defaultValue={values.filter_states || ''}
                  rows="8"
                  placeholder="Separate states by new line"
                  className="span2"
                />
                <Field
                  type="textarea"
                  name="filter_zipcodes"
                  label="Exclude zip/postal codes"
                  defaultValue={values.filter_zipcodes || ''}
                  rows="8"
                  placeholder="Separate codes by new line"
                  className="span2"
                />
              </div>
            </FadeIn>
          </div>
        )}
      </div>
    );
  };

  renderRuleValues = ({ value, index, disabled, onChange }) => {
    const hasFilter = !!value.filter_type;
    return [
      <span className="col" key="country">
        <Field
          type="select"
          name="country"
          label="Country"
          defaultValue={value.country}
          options={[...countryOptions]}
          placeholder="Choose one"
          required={true}
          disabled={disabled}
          onChange={onChange}
          data-index={index}
          autoFocus={true}
        />
        {hasFilter && (
          <Field
            type="textarea"
            name="filter_states"
            label={`${
              value.filter_type === 'include' ? 'Include' : 'Exclude'
            } states`}
            defaultValue={value.filter_states || ''}
            rows={2}
            maxRows={10}
            autoSize={true}
            placeholder="Separate states by new line"
            disabled={disabled}
            onChange={onChange}
            data-index={index}
          />
        )}
      </span>,
      <span className="col" key="type">
        <Field
          type="select"
          name="filter_type"
          label="Filter"
          defaultValue={value.filter_type || ''}
          options={[
            { value: null, label: 'None' },
            { value: 'include', label: 'Including' },
            { value: 'exclude', label: 'Excluding' },
          ]}
          placeholder="Optional"
          disabled={disabled}
          onChange={onChange}
          data-index={index}
        />
        {hasFilter && (
          <Field
            type="textarea"
            name="filter_zipcodes"
            label={`${
              value.filter_type === 'include' ? 'Include' : 'Exclude'
            } zip/postal codes`}
            defaultValue={value.filter_zipcodes || ''}
            rows={2}
            maxRows={10}
            autoSize={true}
            placeholder="Separate codes by new line"
            disabled={disabled}
            onChange={onChange}
            data-index={index}
          />
        )}
      </span>,
    ];
  };

  render() {
    const { zones, onSubmit } = this.props;

    const sorted = Array.from(zones || []).sort((a, b) =>
      a.remaining ? 1 : a.name < b.name ? 0 : -1,
    );

    return (
      <Fieldtable
        label="Shipping zone"
        name="zones"
        defaultValue={sorted}
        onSubmitModal={this.onSubmitModal}
        renderHeading={this.renderZoneHeading}
        renderValue={this.renderZoneValue}
        renderForm={this.renderZoneForm}
        onSubmit={onSubmit}
        formWidth={750}
        sortable={true}
      />
    );
  }
}
