import React from 'react';
import { map } from 'lodash';
import ButtonLink from 'components/button/link';
import { Form, Field, FieldLocalized } from 'components/form';
import View from 'components/view';
import { FadeIn } from 'components/transitions';
import SectionHeader from 'components/section-header';
import Link from 'components/link';
import Alert from 'components/alert';
import './settings.scss';

const DEFAULT_CART_EXPIRATION_MINUTES = 14 * 24 * 60; // 2 weeks

const CHECKOUT_TYPES = {
  swell: {
    label: 'Swell checkout',
    desc: 'I want to use Swell’s hosted checkout',
  },
  custom: {
    label: 'Custom checkout',
    desc: 'I use a custom or third-party checkout application',
  },
};

export default class CheckoutSettings extends React.PureComponent {
  render() {
    const { values, edited, onSubmitForm, onChangeForm, primaryStorefront } =
      this.props;

    return (
      <div className="settings settings-checkout">
        <Form ref="form" onSubmit={onSubmitForm} onChange={onChangeForm}>
          <View
            detail={true}
            uri="/settings"
            sectionTitle="Settings"
            headerTitle="Checkout"
            headerSubtitle="Manage hosted and custom checkout settings"
            headerActions={[
              {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
            localized={true}
          >
            <fieldset className="full">
              <div className="row">
                <Field
                  name="custom_checkout"
                  type="radio"
                  buttonsWithCheck
                  className="span4"
                  defaultValue={values.custom_checkout}
                  options={map(CHECKOUT_TYPES, (type, value) => ({
                    value: value === 'custom',
                    label: (
                      <div className="form-radio-button-wrapper">
                        <div className="label">{type.label}</div>
                        <span className="description muted">{type.desc}</span>
                      </div>
                    ),
                  }))}
                />
              </div>

              {values.custom_checkout && (
                <div className="row custom-checkout-url-container">
                  <div className="view-body-subheader">
                    <h3 className="view-body-subtitle">Checkout URL</h3>
                    <p>
                      {`Enter a custom checkout page URL. Optionally use "{checkout_id}" placeholder`}
                    </p>
                  </div>
                  <Field
                    type="url"
                    name="custom_checkout_url"
                    placeholder="https://mystore.com/checkout/{checkout_id}"
                    defaultValue={values.custom_checkout_url}
                    disabled={!values.custom_checkout}
                    rules="secure_url"
                    className="custom-checkout-url"
                  />
                </div>
              )}
              {!values.custom_checkout &&
                (primaryStorefront ? (
                  <FadeIn transitionAppear={false}>
                    <fieldset className="full">
                      <div className="view-body-subheader">
                        <h3 className="view-body-subtitle">Style</h3>
                        <p>
                          Change your checkout style with a custom logo, fonts,
                          colors, and more
                        </p>
                        <br />
                        <ButtonLink
                          to={`/storefronts/${primaryStorefront.id}/editor?section=checkout`}
                          type="secondary"
                        >
                          Customize checkout
                        </ButtonLink>
                      </div>
                    </fieldset>
                  </FadeIn>
                ) : (
                  <Alert type="warning">
                    Note: A storefront is required to customize your checkout
                    theme.{' '}
                    <Link to="/storefronts">Create storefront &rarr;</Link>
                  </Alert>
                ))}
            </fieldset>
            {!values.custom_checkout && (
              <>
                <fieldset className="full">
                  <div className="view-body-subheader">
                    <h3 className="view-body-subtitle">Customer login</h3>
                    <p>
                      Choose whether to require account login or allow guest
                      checkout
                    </p>
                  </div>
                  <Field
                    type="radio"
                    name="accounts"
                    defaultValue={
                      values.accounts ? values.accounts : 'optional'
                    }
                    options={[
                      { value: 'optional', label: 'Optional' },
                      { value: 'required', label: 'Required' },
                      { value: 'disabled', label: 'Disabled' },
                    ]}
                  />
                </fieldset>
                <fieldset className="full">
                  <div className="view-body-subheader">
                    <SectionHeader
                      className="view-body-subtitle"
                      title="Email opt-in"
                      subtitle="Get permission to send promotional emails to customers"
                    />
                  </div>
                  <Field
                    type="radio"
                    name="email_optin"
                    defaultValue={
                      values.email_optin ? values.email_optin : 'agree'
                    }
                    options={[
                      { value: 'agree', label: 'Opt-in by default' },
                      { value: 'disagree', label: 'Opt-out by default' },
                      { value: 'disabled', label: 'Disable and hide' },
                    ]}
                  />
                </fieldset>
                <fieldset className="full">
                  <div className="view-body-subheader">
                    <h3 className="view-body-subtitle">Form fields</h3>
                    <p>
                      Specify how particular fields are displayed during
                      checkout
                    </p>
                  </div>
                  <div className="box">
                    <div className="box-section box-grid center">
                      <div className="box-column">
                        <div className="box-title">Full name</div>
                      </div>

                      <div className="box-action box-column">
                        <Field
                          type="radio"
                          name="fields.name"
                          defaultValue={
                            values.fields ? values.fields.name : 'last'
                          }
                          options={[
                            { value: 'last', label: 'Require last name only' },
                            { value: 'full', label: 'First and last name' },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="box-section box-grid center">
                      <div className="box-column">
                        <div className="box-title">Company name</div>
                      </div>

                      <div className="box-action box-column">
                        <Field
                          type="radio"
                          name="fields.company"
                          defaultValue={
                            values.fields ? values.fields.company : 'optional'
                          }
                          options={[
                            { value: 'optional', label: 'Optional' },
                            { value: 'required', label: 'Required' },
                            { value: 'hidden', label: 'Hidden' },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="box-section box-grid center">
                      <div className="box-column">
                        <div className="box-title">Phone number</div>
                      </div>

                      <div className="box-action box-column">
                        <Field
                          type="radio"
                          name="fields.phone"
                          defaultValue={
                            values.fields ? values.fields.phone : 'optional'
                          }
                          options={[
                            { value: 'optional', label: 'Optional' },
                            { value: 'required', label: 'Required' },
                            { value: 'hidden', label: 'Hidden' },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="box-section box-grid center">
                      <div className="box-column">
                        <div className="box-title">
                          Address line 2 (Apt, suite, etc)
                        </div>
                      </div>

                      <div className="box-action box-column">
                        <Field
                          type="radio"
                          name="fields.address2"
                          defaultValue={
                            values.fields ? values.fields.address2 : 'optional'
                          }
                          options={[
                            { value: 'optional', label: 'Optional' },
                            { value: 'required', label: 'Required' },
                            { value: 'hidden', label: 'Hidden' },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="box-section box-grid center">
                      <div className="box-column">
                        <div className="box-title">Customer comments</div>
                      </div>

                      <div className="box-action box-column ">
                        <Field
                          type="radio"
                          name="fields.comments"
                          defaultValue={
                            values.fields ? values.fields.comments : 'hidden'
                          }
                          options={[
                            { value: 'optional', label: 'Optional' },
                            { value: 'hidden', label: 'Hidden' },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="full">
                  <div className="view-body-subheader">
                    <SectionHeader
                      className="view-body-subtitle"
                      title="Checkout scripts"
                      subtitle="Add custom JavaScript code to the order confirmation page"
                    />
                  </div>
                  <Field
                    type="textarea"
                    name="head_scripts"
                    defaultValue={values.head_scripts}
                    placeholder={
                      '// Add scripts that should be inserted in the <head> tag \n<script src="https://example.com/script.js"></script>'
                    }
                    rows={4}
                  />
                  <Field
                    type="textarea"
                    name="scripts"
                    defaultValue={values.scripts}
                    placeholder={
                      '// Add scripts that should be inserted in the end of the <body> tag \n<script src="https://example.com/script.js"></script>'
                    }
                    rows={4}
                  />
                </fieldset>
                <fieldset className="full">
                  <div className="box box-grid">
                    <div className="box-column">
                      <h3 className="box-title">Custom return link</h3>
                      <div className="box-subtitle">
                        Specify a custom URL to use when navigating back to your
                        store from checkout
                      </div>
                    </div>

                    <div className="box-action box-column">
                      <Field
                        type="toggle"
                        name="custom_return_enabled"
                        defaultChecked={values.custom_return_enabled}
                      />
                    </div>
                    {!!values.custom_return_enabled && (
                      <FadeIn transitionAppear={false}>
                        <Field
                          type="text"
                          name="custom_return_url"
                          label="Return URL"
                          defaultValue={values.custom_return_url}
                          disabled={!values.custom_return_enabled}
                          required={!!values.custom_return_enabled}
                          help="Note: This defaults to your store home page"
                          placeholder="https://www.example.com/cart/"
                          autoFocus={true}
                          rules="url"
                        />
                      </FadeIn>
                    )}
                  </div>
                  <div className="box box-grid">
                    <div className="box-column">
                      <h3 className="box-title">Custom order receipt page</h3>
                      <div className="box-subtitle">
                        Make Swell checkout redirect to your own custom order
                        receipt page
                      </div>
                    </div>

                    <div className="box-action box-column">
                      <Field
                        type="toggle"
                        name="custom_receipt_enabled"
                        defaultChecked={values.custom_receipt_enabled}
                      />
                    </div>
                    {!!values.custom_receipt_enabled && (
                      <FadeIn transitionAppear={false}>
                        <Field
                          type="text"
                          name="custom_receipt_url"
                          label="Order receipt page URL"
                          defaultValue={values.custom_receipt_url}
                          disabled={!values.custom_receipt_enabled}
                          required={!!values.custom_receipt_enabled}
                          help="Enter the URL of your order receipt page with {checkout_id} as a placeholder"
                          placeholder="https://www.example.com/order/{checkout_id}"
                          autoFocus={true}
                          rules="url, custom_receipt_url"
                        />
                      </FadeIn>
                    )}
                  </div>
                </fieldset>
                <fieldset className="full">
                  <div className="view-body-subheader">
                    <SectionHeader
                      className="view-body-subtitle"
                      title="Store policies"
                    />
                  </div>
                  <FieldLocalized
                    type="textarea"
                    name="refund_policy"
                    label="Refund policy"
                    defaultValue={values.refund_policy}
                    localeValue={values.$locale}
                    rows={5}
                    maxRows={12}
                    tooltipDir="left"
                    autoSize={true}
                  />
                  <FieldLocalized
                    type="textarea"
                    name="privacy_policy"
                    label="Privacy policy"
                    defaultValue={values.privacy_policy}
                    localeValue={values.$locale}
                    rows={5}
                    maxRows={12}
                    tooltipDir="left"
                    autoSize={true}
                  />
                  <FieldLocalized
                    type="textarea"
                    name="terms_policy"
                    label="Terms of service"
                    defaultValue={values.terms_policy}
                    localeValue={values.$locale}
                    rows={5}
                    maxRows={12}
                    tooltipDir="left"
                    autoSize={true}
                  />
                </fieldset>
              </>
            )}
            <fieldset className="full">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title="Cart expiration"
                  subtitle="Set the amount of time before a customer's cart expires"
                />
              </div>
              <div className="row">
                <Field
                  label="Minutes before expiration"
                  type="number"
                  name="cart_checkout_expiration"
                  defaultValue={
                    values.cart_checkout_expiration ??
                    DEFAULT_CART_EXPIRATION_MINUTES
                  }
                  className="span1"
                />
              </div>
            </fieldset>
          </View>
        </Form>
      </div>
    );
  }
}
