import PropTypes from 'prop-types';
import React from 'react';
import md5 from 'md5';
import './customer.scss';

export default class CustomerAvatar extends React.PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    size: PropTypes.number.isRequired,
  };

  render() {
    const { account, size = 50 } = this.props;
    const hash = md5((account && account.email) || 'none');
    return (
      <img
        className="customer-avatar"
        src={`https://www.gravatar.com/avatar/${hash}?s=${
          size * 2
        }&d=identicon`}
        alt=""
      />
    );
  }
}
