import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ViewLoading from 'components/view/loading';
import StorefrontNewPage from 'components/storefront/new';
import actions from 'actions';

export const mapStateToProps = (state) => ({
  storefronts: state.storefronts.all,
  themes: state.storefronts.themes,
  loading: state.storefronts.loading,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchAllStorefronts: () => {
    return dispatch(actions.storefronts.fetchAll());
  },

  fetchStorefrontThemes: () => {
    return dispatch(actions.storefronts.fetchThemes());
  },

  fetchThemeDetails: (id) => {
    return dispatch(actions.storefronts.fetchThemeDetails(id));
  },

  createStorefront: (data) => {
    return dispatch(actions.storefronts.create(data));
  },
});

export class NewStorefront extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      onCreateStorefront: this.onCreateStorefront.bind(this),
    };
  }

  async componentWillMount() {
    const { fetchAllStorefronts, fetchStorefrontThemes } = this.props;

    await Promise.all([fetchAllStorefronts(), fetchStorefrontThemes()]);

    this.setState({ loaded: true });
  }

  async onCreateStorefront(values) {
    const { router, createStorefront } = this.props;

    const result = await createStorefront(values);

    if (!result || result.errors) {
      this.context.notifyError(result.errors);
      return;
    }

    this.context.notifySuccess(`Storefront created`);

    router.replace(`/storefronts/${result.slug}`);
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return <StorefrontNewPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewStorefront);
