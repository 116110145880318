import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import StandardPurchaseOption from './purchase-options/standard';
import SubscriptionPurchaseOption from './purchase-options/subscription';
import TrialPurchaseOption from './purchase-options/trial';

const emptyObject = {};

export default class ProductPurchaseOptions extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    variantProps: PropTypes.object,
  };

  getFieldName = (path) => {
    const { name } = this.props;

    return `${name}.${path}`;
  };

  render() {
    const {
      type,
      values: { purchase_options },
    } = this.props;

    const optionValues = get(purchase_options, type) || emptyObject;

    switch (type) {
      case 'standard': {
        return (
          <StandardPurchaseOption
            record={this.props.record}
            values={this.props.values}
            currency={this.props.currency}
            settings={this.props.settings}
            optionValues={optionValues}
            variantProps={this.props.variantProps}
            getFieldName={this.getFieldName}
          />
        );
      }

      case 'subscription': {
        return (
          <SubscriptionPurchaseOption
            record={this.props.record}
            values={this.props.values}
            currency={this.props.currency}
            settings={this.props.settings}
            optionValues={optionValues}
            getFieldName={this.getFieldName}
          />
        );
      }

      case 'trial': {
        return (
          <TrialPurchaseOption
            record={this.props.record}
            values={this.props.values}
            currency={this.props.currency}
            settings={this.props.settings}
            optionValues={optionValues}
            getFieldName={this.getFieldName}
          />
        );
      }

      default:
        return null;
    }
  }
}
