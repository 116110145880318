import React from 'react';

import { formatCurrencyRounded } from 'utils';
import Icon from 'components/icon';
import User from '../utils/user';
import Payment from '../utils/payment';
import Date from '../utils/date';

export default function PaymentRefundSucceeded({ event, settings }) {
  const { refund = {} } = event;

  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/payment" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          {formatCurrencyRounded(refund.amount || 0, refund.currency || 'USD')}{' '}
          refunded
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
        <div className="activity-event-subtitle">
          <Payment data={refund} settings={settings} />
          &nbsp;
          {refund.reason && <span className="muted">({refund.reason})</span>}
        </div>
      </div>
    </div>
  );
}
