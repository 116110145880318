import React from 'react';
import reduce from 'lodash/reduce';
import pt from 'prop-types';

import { stripTags } from 'utils/string';
import { productThumbUrl } from 'utils/product';
import { contentTypesConditionalDeprecated } from 'utils/content';
import { formatCurrency, slugify, classNames } from 'utils';

import Icon from 'components/icon';
import ContentFields from 'components/content/fields';
import ContentFieldsDeprecated from 'components/content/fields-deprecated';
import { Field, FieldLocalized, LookupCategory } from 'components/form';
import { FadeIn } from 'components/transitions';
import SectionHeader from 'components/section-header';

import CategoryProducts, { MANUAL_SORTING_LIMIT } from './products';

import './category.scss';

const optionsSorting = Object.freeze([
  { label: 'Sort by best selling', value: 'popularity' },
  { label: 'Sort by price, ascending', value: 'price_asc' },
  { label: 'Sort by price, descending', value: 'price_desc' },
  { label: 'Sort by date created, ascending', value: 'date_asc' },
  { label: 'Sort by date created, descending', value: 'date_desc' },
]);

const optionsSortingWithManual = Object.freeze([
  { label: 'Sort manually', value: '' },
  ...optionsSorting,
]);

const productFinder = Object.freeze({
  models: ['products'],
  queries: {
    products: {
      fields: 'name, price, currency, date_created, images, category_index.id',
    },
  },
});

export default class CategoryForm extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    errors: pt.object,
    values: pt.object,
    lookup: pt.object,
    content: pt.object,
    sorting: pt.string,
    loading: pt.bool,
    products: pt.object.isRequired,
    productIndex: pt.object,
    categories: pt.object,
    modal: pt.bool,

    onChangePage: pt.func,
    onChangeLimit: pt.func,
    onChangeSorting: pt.func,
    onSelectProduct: pt.func,
    onClickRemoveProduct: pt.func,
    onMoveProductManually: pt.func,
  };

  static contextTypes = {
    client: pt.object.isRequired,
    settings: pt.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      charCounts: {},
    };
  }

  onChangeSEOField = (event, value) => {
    if (event.target) {
      const { name } = event.target;

      this.setState((state) => ({
        charCounts: {
          ...state.charCounts,
          [name]: value.length,
        },
      }));
    }
  };

  getCategoryFromList(id, list = []) {
    for (const currentCategory of list) {
      const category =
        currentCategory.id === id
          ? currentCategory
          : this.getCategoryFromList(id, currentCategory.children);

      if (category) {
        return category;
      }
    }
  }

  getChildCategoryIds(category) {
    return reduce(
      category.children,
      (acc, child) => {
        acc.push(child.id);

        if (child.children) {
          acc.push(...this.getChildCategoryIds(child));
        }

        return acc;
      },
      [],
    );
  }

  getForbiddenParentCategoryIds() {
    const { record = {}, categories: { list } = {} } = this.props;
    const category = this.getCategoryFromList(record.id, list);

    return category ? [category.id, ...this.getChildCategoryIds(category)] : [];
  }

  renderProductLookupItems = (props) => {
    const { id: categoryId } = this.props.record || {};

    return props.lookup.results.map((record) => {
      const selected = record.id === props.selected?.id;

      const checked = categoryId
        ? record.category_index?.id?.includes(categoryId)
        : this.props.productIndex.has(record.id);

      return (
        <li
          key={record.id}
          data-id={record.id}
          role="option"
          aria-checked={checked}
          aria-selected={selected}
          className={classNames('item', {
            selected,
          })}
          onMouseOver={props.onMouseOver}
          onFocus={props.onMouseOver}
          onClick={props.onClick}
        >
          <span className="col" style={{ paddingLeft: 42 }}>
            <FadeIn className="check" transitionAppear={false} active={checked}>
              <Icon fa="check" />
            </FadeIn>

            <span className="image">
              <img alt={record.name} src={productThumbUrl(record)} />
            </span>

            <span className="name">{record.name}</span>
          </span>

          <span className="col muted">
            {formatCurrency(record.price, record.currency)}
          </span>

          <span className="col">{record.sku}</span>
        </li>
      );
    });
  };

  render() {
    const {
      modal = false,
      record = {},
      errors = {},
      products = [],
      categories = {},
      sorting = null,
      values,
      lookup,
      content,
      loading,
      onChangePage,
      onChangeLimit,
      onChangeSorting,
      onSelectProduct,
      onClickRemoveProduct,
      onMoveProductManually,
    } = this.props;

    const { charCounts } = this.state;

    const contentFieldsDeprecated = contentTypesConditionalDeprecated(
      content.fieldsDeprecated.categories,
      values,
    );

    return (
      <div className="category-form">
        <fieldset className="full">
          <FieldLocalized
            type="text"
            label="Category name"
            name="name"
            localeFieldName="name"
            defaultValue={record.name}
            localeValue={record.$locale}
            placeholder={record.name}
            error={errors.name}
            required={true}
          />

          <Field
            type="toggle"
            name="active"
            label="Active"
            help="An active category is visible to customers"
            defaultChecked={record.active !== undefined ? record.active : true}
            error={errors.active}
          />

          <LookupCategory
            label="Parent category"
            name="parent"
            defaultValue={record.parent}
            placeholder="Find category by name"
            categories={categories}
            query={{
              where: {
                id: { $nin: this.getForbiddenParentCategoryIds() },
              },
            }}
          />

          <ContentFields
            {...this.props}
            zone="details"
            collection="categories"
            models={content.models}
            record={record}
            values={values}
            currency={this.context.client.currency}
          />
        </fieldset>

        <fieldset id={modal ? undefined : 'scroll-anchor'} className="full">
          <div className="view-body-subheader">
            <SectionHeader className="view-body-subtitle" title="Products" />
          </div>

          <div className="row">
            <Field
              type="finder"
              placeholder="Add products"
              className="span2"
              models={productFinder.models}
              queries={productFinder.queries}
              renderLookupItems={this.renderProductLookupItems}
              onSelectValue={onSelectProduct}
              lookup={lookup}
              readonly={true}
            />

            <Field
              type="select"
              name="sorting"
              className="span2"
              placeholder="Sort by …"
              defaultValue={record.sorting}
              onChange={onChangeSorting}
              options={
                products.count > MANUAL_SORTING_LIMIT
                  ? optionsSorting
                  : optionsSortingWithManual
              }
            />
          </div>

          <CategoryProducts
            modal={modal}
            sorting={sorting}
            loading={loading}
            products={products}
            categoryId={record.id}
            onChangePage={onChangePage}
            onChangeLimit={onChangeLimit}
            onMoveProductManually={onMoveProductManually}
            onClickRemoveProduct={onClickRemoveProduct}
          />
        </fieldset>

        <fieldset className="full">
          <div className="view-body-subheader">
            <SectionHeader className="view-body-subtitle" title="Content" />
          </div>

          <Field
            type="images"
            label="Image"
            name="images"
            fullSize={false}
            defaultValue={record.images}
          />

          <FieldLocalized
            type="editor"
            name="description"
            localeFieldName="description"
            label="Description"
            defaultValue={record.description}
            localeValue={record.$locale}
          />

          <FieldLocalized
            type="text"
            name="meta_title"
            localeFieldName="meta_title"
            label={
              <span>
                Page title{' '}
                <span className="muted">
                  (
                  {charCounts.meta_title ||
                    (record.meta_title || '').length ||
                    0}{' '}
                  of 70 chars)
                </span>
              </span>
            }
            help="Search engines typically display the first 50-70 characters of your page title"
            defaultValue={record.meta_title}
            localeValue={record.$locale}
            placeholder={values.name}
            onChange={this.onChangeSEOField}
          />

          <FieldLocalized
            type="textarea"
            name="meta_description"
            localeFieldName="meta_description"
            label={
              <span>
                Meta description{' '}
                <span className="muted">
                  (
                  {charCounts.meta_description ||
                    stripTags(record.meta_description).length ||
                    0}{' '}
                  of 170 chars)
                </span>
              </span>
            }
            help="Search engines typically display the first 140-170 characters of your page description"
            defaultValue={record.meta_description}
            localeValue={record.$locale}
            placeholder={stripTags(values.description) || 'Optional'}
            autoSize={true}
            rows={3}
            maxRows={20}
            onChange={this.onChangeSEOField}
          />

          <Field
            type="text"
            name="slug"
            label={<span>URL name</span>}
            help={`Also known as a "slug", used to uniquely identify the category in a URL`}
            defaultValue={record.slug}
            placeholder={record.slug || slugify(values.name)}
            maxLength={70}
          />

          <br />

          <ContentFields
            {...this.props}
            zone="content"
            collection="categories"
            models={content.models}
            record={record}
            values={values}
            currency={this.context.client.currency}
          />
        </fieldset>

        {contentFieldsDeprecated.length > 0 && (
          <ContentFieldsDeprecated
            base="content"
            types={contentFieldsDeprecated}
            record={record}
            values={values}
            currency={undefined}
            lookup={lookup}
          />
        )}
      </div>
    );
  }
}
