import React, { Fragment } from 'react';
import { Field } from 'components/form';
import Loading from 'components/loading';
import './fields.scss';
import { slugify } from 'utils';

export default class SingleSignupPageFields extends React.PureComponent {
  render() {
    const {
      email,
      errors,
      valid,
      validating,
      onChangeStoreName,
      onChangeEmail,
      name,
    } = this.props;

    return (
      <fieldset className="fields">
        <div>
          <input type="text" name="email" className="hidden" value="" />
          <Field
            type="text"
            name="email"
            label="Email"
            defaultValue={email}
            required={true}
            rules="email"
            error={
              errors.email && errors.email.message ? (
                <Fragment>{errors.email.message}</Fragment>
              ) : undefined
            }
            large={true}
            validateIcon={true}
            validateBlur={true}
            onChange={onChangeEmail}
            autoCompleteOff={true}
            autoFocus={true}
          />
        </div>
        <div>
          <input type="text" name="password" className="hidden" value="" />
          <Field
            type="password"
            name="password"
            label="Password"
            rules="password_admin, required"
            error={
              errors.email && errors.email.message ? true : undefined // the error message is shown with the email field for security reasons
            }
            large={true}
            validateIcon={true}
            validateBlur={true}
            autoComplete="new-password"
          />
        </div>
        <div className="signup-name">
          <input type="text" name="store_name" className="hidden" value="" />
          <Field
            type="text"
            name="store_name"
            label="Store name"
            required={true}
            maxLength={32}
            warning={
              errors.store_name &&
              (errors.store_name.message === 'Store name is already taken' ? (
                <Fragment>
                  Store name is already taken. Are you the owner?{' '}
                  <a href="/login?to=/admin#create-store">Log in!</a>
                </Fragment>
              ) : (
                <Fragment>{errors.store_name.message}</Fragment>
              ))
            }
            showError={errors.store_name ? true : false}
            autoComplete="off"
            onChange={onChangeStoreName}
            large={true}
            valid={valid}
            validateIcon={true}
            validateBlur={true}
            hintOnFocus={
              <p>
                https://<b>{name ? slugify(name) : 'your-store-name'}</b>
                .swell.store will be your store domain.
                <br /> This can't be changed later.
              </p>
            }
          />
          {validating && <Loading when={validating} />}
        </div>
        <div className="fields_checkbox">
          <input type="checkbox" name="marketing" className="hidden" value="" />
          <Field
            type="checkbox"
            name="marketing"
            label={
              <p className="checkbox-label">
                I'd like to receive the latest news and updates about Swell.
              </p>
            }
          />
        </div>
      </fieldset>
    );
  }
}
