import React from 'react';
import classNames from 'classnames';
import pt from 'prop-types';

import { isEmpty } from 'utils';

import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import Help from 'components/tooltip/help';
import { Field, FieldLocalized } from 'components/form';
import ButtonLink from 'components/button/button-link';
import ContentFields from 'components/content/fields';
import SortableCollection from 'components/collection/sortable';
import { FadeIn } from 'components/transitions';
import SectionHeader from 'components/section-header';

const ARR = Object.freeze([]);

const LOCALIZED_ATTRS = Object.freeze([
  'text',
  'textarea',
  'short_text',
  'long_text',
  'tags',
  'currency',
]);

const lookupQuery = Object.freeze({ variant: { $ne: true } });

function onDragStartField(event) {
  event.preventDefault();
  event.stopPropagation();
}

export default class ProductAttributesForm extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    values: pt.object,
    errors: pt.object,
    lookup: pt.object,
    content: pt.object.isRequired,
    settings: pt.object.isRequired,
    attributes: pt.object,
    isVariant: pt.bool,
    attributeSetAttributes: pt.array.isRequired,
    attributeValues: pt.object,

    onAddAttribute: pt.func,
    onSortAttributes: pt.func,
    onUpdateAttribute: pt.func,
    onRemoveAttribute: pt.func,
    onChangeAttributeSet: pt.func,
    onChangeAttributeValue: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
    setFormValue: pt.func.isRequired,
    onChangeField: pt.func.isRequired,
    client: pt.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      addingAttribute: false,
    };

    /** @type {React.RefObject<HTMLFieldSetElement>} */
    this.movableContainerRef = React.createRef();
    /** @type {React.RefObject<Field>} */
    this.addAttributeRef = React.createRef();
    /** @type {Map<string, Field>} */
    this.attributesRefMap = new Map();

    this.lookupFormParams = {
      onCreate: (attr) => {
        const { record } = this.props;

        if (
          !record ||
          !record.attributes ||
          record.attributes[attr.id] === undefined
        ) {
          this.context.setFormValue(`attributes[${attr.id}]`, null);
        }

        this.props.onAddAttribute(attr);
      },
    };
  }

  /** @param {Field | null} field */
  getAttributeRef = (field) => {
    if (field === null) {
      return;
    }

    const attrId = field.props?.['data-id'];

    if (attrId) {
      this.attributesRefMap.set(attrId, field);
    }
  };

  onClickAddAttribute = (event) => {
    event.preventDefault();

    this.setState({ addingAttribute: true }, () => {
      this.addAttributeRef.current.getInputRef().focus();
    });
  };

  onClickAddAttributeCancel = (event) => {
    event.preventDefault();

    this.setState({ addingAttribute: false });
  };

  onChangeAddAttribute = (event, attr) => {
    event.preventDefault();

    if (!attr) {
      return;
    }

    if (this.props.attributeSetAttributes.includes(attr.id)) {
      const field = this.attributesRefMap.get(attr.id);

      if (field) {
        field.getInputRef()?.focus();
      }

      this.setState({ addingAttribute: false });
      return;
    }

    const { record } = this.props;

    if (
      !record ||
      !record.attributes ||
      record.attributes[attr.id] === undefined
    ) {
      this.context.setFormValue(`attributes[${attr.id}]`, null);
    }

    if (
      attr.type === 'radio' &&
      Array.isArray(attr.values) &&
      attr.values.length > 0
    ) {
      attr.default = attr.values[0];
    }

    this.props.onAddAttribute(attr);

    this.setState({ addingAttribute: false });
  };

  onClickEditAttribute = (event) => {
    this.context.openModal('EditAttribute', {
      params: {
        id: event.currentTarget.dataset.id,
        onUpdate: (attr) => {
          this.props.onUpdateAttribute(attr);
        },
      },
    });
  };

  onClickRemoveAttribute = (event) => {
    const { record } = this.props;
    const attrId = event.currentTarget.dataset.id;

    if (
      !record ||
      !record.attributes ||
      record.attributes[attrId] === undefined
    ) {
      this.context.setFormValue(`attributes[${attrId}]`, undefined);
    }

    const component = this.attributesRefMap.get(attrId);

    if (component) {
      this.attributesRefMap.delete(attrId);
      this.context.onChangeField(component);
    }

    this.props.onRemoveAttribute(attrId);
  };

  renderAttributeInputValue(attr, render) {
    if (!attr) {
      return null;
    }

    if (!attr.values || attr.values.length === 0) {
      switch (attr.type) {
        case 'multiselect':
        case 'checkbox':
        case 'radio':
          return (
            <div className="collection-field-padded">
              <span className="muted">
                No {attr.name} options found{' '}
                <Help
                  message={`Edit this attribute and specify one or more ${attr.type} options`}
                />
              </span>
            </div>
          );

        default:
          break;
      }
    }

    return render();
  }

  getAttributeInputProps(attribute, value) {
    const { client } = this.context;
    const { record } = this.props;

    if (!attribute) {
      return {};
    }

    switch (attribute.type) {
      case 'text':
      case 'textarea':
      case 'number':
        return {
          type: attribute.type,
          rows: attribute.type === 'textarea' ? 5 : undefined,
        };

      case 'select':
      case 'dropdown':
        return {
          type: 'select',
          options: attribute.values || ARR,
        };

      case 'multiselect':
      case 'checkbox':
        return {
          type: 'checkbox',
          options: attribute.values || ARR,
          stacked: true,
        };

      case 'radio':
        return {
          type: 'radio',
          options: attribute.values || ARR,
          stacked: true,
        };

      case 'currency': {
        const currency = record ? record.currency : client.currency;
        return {
          type: 'currency',
          currency,
        };
      }

      case 'image':
        return {
          type: 'images',
          single: attribute.multi ? false : true,
          value: attribute.multi
            ? Array.isArray(value)
              ? value
              : [value]
            : Array.isArray(value)
            ? value[0]
            : value,
          minimal: true,
          wide: false,
          maxWidth: 200,
          //minWidth: 0,
          //minHeight: 0,
        };

      case 'file':
        return {
          type: 'file',
          valuePath: 'file',
          multiple: attribute.multi,
          value: attribute.multi
            ? Array.isArray(value)
              ? value
              : [value]
            : Array.isArray(value)
            ? value[0]
            : value,
          minimal: true,
          wide: false,
          maxWidth: 200,
        };

      default:
        return {};
    }
  }

  renderAttributeLookupItems = ({ lookup, ...props }) => {
    const { attributeSetAttributes } = this.props;

    return lookup.results.map((record) => {
      const name = record.name || record.id;
      const selected = record.id === props.selected?.id;
      const disabled = attributeSetAttributes.includes(record.id);

      return (
        <li
          key={record.id}
          role="option"
          data-id={record.id}
          aria-selected={selected}
          aria-disabled={disabled}
          aria-label={name}
          className={classNames('item', { selected, disabled })}
          onMouseOver={props.onMouseOver}
          onClick={props.onClick}
        >
          <span className="col">{name}</span>
        </li>
      );
    });
  };

  render() {
    const {
      record = {},
      values,
      errors = {},
      lookup,
      content,
      settings,
      attributes,
      isVariant,
      onChangeAttributeSet,
      //attributeSet,
      //attributeSetName,
      attributeSetAttributes,
      attributeValues,
      onSortAttributes,
      onChangeAttributeValue,
    } = this.props;

    const { addingAttribute } = this.state;

    const attributeSets = settings.products.attribute_sets || ARR;

    return (
      <fieldset ref={this.movableContainerRef} className="full">
        {isVariant ? (
          <div className="view-body-subheader">
            <h3 className="view-body-subtitle">Attributes</h3>
          </div>
        ) : (
          <div className="view-body-subheader">
            <SectionHeader
              className="view-body-subtitle"
              title="Attributes"
              subtitle="Manage product attributes for content and filtering"
            />
          </div>
        )}

        <Field name="attributes" type="hidden" value={attributeValues} />

        {attributeSets.length > 0 && (
          <div>
            <Field
              type="select"
              label="Attribute Set"
              name="attribute_set"
              defaultValue={record.attribute_set}
              error={errors.attribute_set}
              options={attributeSets}
              newable={true}
              onChange={onChangeAttributeSet}
              className="span2"
            />
          </div>
        )}

        {attributeSetAttributes.length > 0 && (
          <FadeIn active={true}>
            <SortableCollection
              className="collection-table aligned"
              onSort={onSortAttributes}
              values={attributeSetAttributes}
              columns={[
                { content: 'Attribute', props: { width: '20%' } },
                { content: 'Value', props: { width: '60%' } },
                { content: '', props: { width: '10%' } },
                { content: '', props: { width: '5%' } },
                { content: '', props: { width: '5%' } },
              ]}
              movableContainer={this.movableContainerRef.current}
              enabled={!isVariant}
              rows={attributeSetAttributes.map((attrId) => {
                const attr = attributes.index.get(attrId) || { name: attrId };
                return [
                  {
                    content: (
                      <label
                        htmlFor={`attribute-${attrId}`}
                        className="collection-field-padded"
                      >
                        {attr.name}
                      </label>
                    ),
                  },
                  {
                    content: this.renderAttributeInputValue(attr, () =>
                      attr.variant ? (
                        <div className="collection-field-padded">
                          {isEmpty(attributeValues[attrId]) ? (
                            <span className="muted">&mdash;</span>
                          ) : (
                            <div className="view-body-tags">
                              {Array.isArray(attributeValues[attrId]) ? (
                                attributeValues[attrId].map((val, i) => (
                                  <li key={i}>{val}</li>
                                ))
                              ) : (
                                <li>{attributeValues[attrId]}</li>
                              )}
                            </div>
                          )}
                        </div>
                      ) : LOCALIZED_ATTRS.includes(attr.type) &&
                        attr.localized ? (
                        <FieldLocalized
                          data-id={attrId}
                          name={attrId}
                          id={`attribute-${attrId}`}
                          ref={this.getAttributeRef}
                          value={attributeValues[attrId]}
                          defaultValue={attr.default}
                          error={errors[`attributes.${attrId}`]}
                          onChange={onChangeAttributeValue}
                          readonly={true}
                          required={attr.required}
                          {...this.getAttributeInputProps(
                            attributes.index.get(attrId),
                            attributeValues[attrId],
                          )}
                          draggable="true"
                          onDragStart={onDragStartField}
                          localeValue={
                            attr.type === 'currency'
                              ? attributeValues.$currency
                              : attributeValues.$locale
                          }
                        />
                      ) : (
                        <Field
                          data-id={attrId}
                          name={attrId}
                          id={`attribute-${attrId}`}
                          ref={this.getAttributeRef}
                          value={attributeValues[attrId]}
                          defaultValue={attr.default}
                          error={errors[`attributes.${attrId}`]}
                          onChange={onChangeAttributeValue}
                          readonly={true}
                          required={attr.required}
                          {...this.getAttributeInputProps(
                            attributes.index.get(attrId),
                            attributeValues[attrId],
                          )}
                          draggable="true"
                          onDragStart={onDragStartField}
                        />
                      ),
                    ),
                  },
                  {
                    content: (
                      <span className="collection-field-padded nowrap">
                        {attr.visible ? (
                          <Tooltip message="Visible to customers">
                            <Icon fa="eye" />
                          </Tooltip>
                        ) : (
                          <Tooltip message="Not visible to customers">
                            <Icon fa="eye-slash" className="muted" />
                          </Tooltip>
                        )}
                        &nbsp;&nbsp;&nbsp;
                        {attr.filterable && (
                          <Tooltip message="Can be used in product filters">
                            <Icon fa="filter" />
                          </Tooltip>
                        )}
                      </span>
                    ),
                  },
                  <td key="a" className="action compact">
                    <div className="collection-field-padded">
                      <button
                        data-id={attrId}
                        className="as-link"
                        onClick={this.onClickEditAttribute}
                        type="button"
                      >
                        Edit
                      </button>

                      {!isVariant && (
                        <button
                          data-id={attrId}
                          className="as-link"
                          onClick={this.onClickRemoveAttribute}
                          type="button"
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </td>,
                  <td key="h" className="action handle compact movable">
                    <span
                      className="collection-field-padded"
                      data-movable-handle
                    >
                      <span className="collection-table-handle">
                        <Icon type="drag-vertical" />
                      </span>
                    </span>
                  </td>,
                ];
              })}
            />
          </FadeIn>
        )}

        {addingAttribute ? (
          <FadeIn key="find">
            <div className="row">
              <Field
                type="lookup"
                ref={this.addAttributeRef}
                newable={true}
                lookup={lookup}
                query={lookupQuery}
                model="attributes"
                className="span2"
                placeholder="Find or create attribute"
                newValueFormName="NewAttribute"
                newValueFormParams={this.lookupFormParams}
                renderLookupItems={this.renderAttributeLookupItems}
                onChange={this.onChangeAddAttribute}
              />
            </div>

            <div className="form-field">
              <ButtonLink
                size="sm"
                type="secondary"
                onClick={this.onClickAddAttributeCancel}
              >
                Cancel
              </ButtonLink>
            </div>
          </FadeIn>
        ) : (
          !isVariant && (
            <FadeIn key="add">
              <button
                className="button button-secondary button-sm form-link"
                onClick={this.onClickAddAttribute}
                type="button"
              >
                Add attribute
              </button>
            </FadeIn>
          )
        )}

        <br />

        <ContentFields
          {...this.props}
          zone="attributes"
          collection="products"
          models={content.models}
          record={record}
          values={values}
          currency={record.currency}
        />
      </fieldset>
    );
  }
}
