import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

const easeOutQuart = 'cubic-bezier(0.165, 0.84, 0.44, 1)';

export default function FadeInLeft({
  duration = 350,
  distance = '50px',
  children,
  ...props
}) {
  const transitionStyles = {
    defaultStyle: {
      opacity: 0,
      transform: `translate(${distance}, 0)`,
    },
    enterStyle: {
      opacity: transit(1.0, duration / 2, easeOutQuart),
      transform: transit('translate(0, 0)', duration, easeOutQuart),
    },
    leaveStyle: {
      opacity: transit(0, duration / 2, easeOutQuart),
      transform: transit(`translate(${distance}, 0)`, duration, easeOutQuart),
    },
    activeStyle: {},
    transitionAppear: true,
    active: true,
  };

  return (
    <CSSTransition {...transitionStyles} {...props}>
      {children}
    </CSSTransition>
  );
}
