import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, set, cloneDeep } from 'lodash';

import { isValueEqual, isEmpty, evalConditionsWithAppFields } from 'utils';

import {
  contentFieldValue,
  contentFieldPath,
  contentFieldRootPath,
  contentFieldVisible,
  contentFieldsAllGrouped,
  setContentValuesByPath,
} from 'utils/content';
import { renderTemplate } from 'utils/collection';

import { Tabs, TabView } from 'components/form';
import KebabButton from 'components/button/kebab';
import { FadeIn } from 'components/transitions';

import ContentField from './field';
import ContentCollection from './collection';

import './content.scss';

export default class ContentFieldGroups extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    root: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    namespace: PropTypes.string,
    record: PropTypes.object,
    currency: PropTypes.string,
    lookup: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    fields: PropTypes.array.isRequired,
    values: PropTypes.object,
    fallback: PropTypes.bool,

    onUpdate: PropTypes.func,
  };

  state = {
    values: undefined,
    groups: [],
    groupFields: {},
    fieldsWithoutGroup: [],
    hasDefaults: {},
    usingDefault: {},
    anyFieldsVisible: true,
    visibleReady: false,
  };

  componentDidMount() {
    this.getInitialState(this.props.fields).then((initialState) => {
      this.setState(
        {
          ...initialState,
          ...this.getFieldConditions(initialState),
        },
        () => {
          this.setInitialVisibleState();
        },
      );
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.fields !== this.props.fields ||
      prevProps.values !== this.props.values ||
      prevProps.record !== this.props.record
    ) {
      this.getInitialState(prevProps.fields).then((initialState) => {
        this.setState(
          {
            ...initialState,
            ...this.getFieldConditions(initialState),
          },
          () => {
            this.setInitialVisibleState();
          },
        );
      });
    }

    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(this);
    }
  }

  async getInitialState(prevFields) {
    const {
      fields: inAllFields,
      values: valuesProp,
      record = {},
      root,
    } = this.props;

    const { groups, groupFields, fieldsWithoutGroup, allFields } =
      contentFieldsAllGrouped(inAllFields);

    if (allFields.length <= 0) {
      return {
        allFields,
        groups,
        groupFields,
        fieldsWithoutGroup,
        anyFieldsVisible: false,
        visibleReady: true,
      };
    }

    const values =
      this.state.values && isValueEqual(prevFields, this.props.fields)
        ? valuesProp
        : this.getInitialValues(allFields, valuesProp, record);

    const currentDefaults = await this.getCurrentDefaults(allFields, record);

    const recordValues = cloneDeep(
      isEmpty(values)
        ? root
          ? get(currentDefaults, root) || {}
          : currentDefaults
        : values,
    );

    const { hasDefaults, usingDefault } = this.getFieldDefaults(
      allFields,
      recordValues,
      currentDefaults,
    );

    return {
      allFields,
      values,
      groups,
      groupFields,
      fieldsWithoutGroup,
      hasDefaults,
      usingDefault,
      currentDefaults,
      recordValues,
    };
  }

  setInitialVisibleState() {
    if (!this.props.onUpdate) return;
    const anyFieldsVisible =
      this.state.groups.length > 0 ||
      this.getAnyFieldsVisible(this.state.fieldsWithoutGroup);

    if (anyFieldsVisible !== this.state.anyFieldsVisible) {
      this.setState({
        anyFieldsVisible,
        visibleReady: true,
      });
    } else {
      this.setState({ visibleReady: true });
    }
  }

  getFieldPath(field, model) {
    const { root } = this.props;
    return contentFieldPath(field, model, root);
  }

  async getCurrentDefaults(allFields, record) {
    // TODO: defaults with multi locales
    // const currentDefaults = {};
    // for (const { field, model } of allFields) {
    //   const fieldPath = this.getFieldPath(field, model);
    //   const pathParts = fieldPath.split('.');
    //   const lastPart = pathParts.pop();
    //   const basePath = pathParts.length ? pathParts.join('.') : '';
    //   set(currentDefaults, fieldPath, get(model.defaults, fieldPath));
    //   const localeValue = get(model.defaults, basePath ? `${basePath}.$locale` : '$locale');
    //   if (localeValue) {
    //     for (const locale of Object.keys(localeValue)) {
    //       if (LOCALE_CODE === locale) continue;
    //       const thisPrefix = basePath ? `${basePath}.` : '';
    //       const thisLocalePath = `${thisPrefix}${locale}.$locale.${lastPart}`;
    //       set(currentDefaults, thisLocalePath, get(model.defaults, thisLocalePath));
    //     }
    //   }
    // }

    const currentDefaults = {};
    for (const { field, model, path } of allFields) {
      let appPath = '';
      if (
        model?.app_id &&
        model.collectionModel &&
        !model.collectionModel.app_id
      ) {
        appPath = `$app.${model.app?.slug_id || model.app_id}`;
      }

      if (model?.defaults?.[field.id] !== undefined) {
        const defaultValue =
          typeof model.defaults[field.id] === 'string'
            ? await renderTemplate(model.defaults[field.id], {
                ...((appPath ? get(record, appPath) : record) || undefined),
                record,
              })
            : model.defaults[field.id];
        set(
          currentDefaults,
          `${path ? `${path}.` : ''}${field.id}`,
          defaultValue,
        );
      } else if (field.default !== undefined) {
        const defaultValue =
          typeof field.default === 'string'
            ? await renderTemplate(field.default, {
                ...((appPath ? get(record, appPath) : record) || undefined),
                record,
              })
            : field.default;
        set(currentDefaults, field.id, defaultValue);
      }
    }

    return currentDefaults;
  }

  getInitialValues(allFields, values, record) {
    if (this.props.fallback === false) {
      return cloneDeep(values);
    }

    const setValues = {};
    const unsetValues = [];

    for (const { field, model } of allFields) {
      setContentValuesByPath({
        setValues,
        unsetValues,
        // Clone values in case they have references to record properties
        values: cloneDeep(values || {}),
        record: record || {},
        field,
        model,
        root: this.props.root,
      });
    }

    return setValues;
  }

  getFieldDefaults(allFields, values, currentDefaults) {
    const hasDefaults = {};
    const usingDefault = {};
    for (const { field, model, path } of allFields) {
      const fieldPath = this.getFieldPath(field, model);
      const currentDefault = path
        ? get(currentDefaults, path)
        : currentDefaults;
      const thisDefault = get(currentDefault, field.id);

      if (!isEmpty(thisDefault) || typeof thisDefault === 'boolean') {
        hasDefaults[fieldPath] = true;
        const thisValue = get(values, fieldPath);
        if (thisValue === undefined || isValueEqual(thisValue, thisDefault)) {
          usingDefault[fieldPath] = true;
          // TODO: properly detect localized defaults
          // let localeUsingDefault = true;
          // const localeValue = get(values, basePath ? `${basePath}.$locale` : '$locale');
          // if (localeValue) {
          //   for (const locale of Object.keys(localeValue)) {
          //     if (LOCALE_CODE === locale) continue;
          //     const thisLocalePath = `${locale}.${lastPart}`;
          //     const thisLocaleValue = get(localeValue, thisLocalePath);
          //     const thisLocaleDefault = get(
          //       currentDefault,
          //       basePath ? `${basePath}.$locale` : `$locale`,
          //     );
          //     if (
          //       thisLocaleDefault !== undefined &&
          //       thisLocaleValue !== undefined &&
          //       !isValueEqual(thisLocaleValue, get(thisLocaleDefault, thisLocalePath))
          //     ) {
          //       localeUsingDefault = false;
          //     } else {
          //       if (
          //         thisLocaleDefault === undefined &&
          //         (!isEmpty(thisLocaleValue) || typeof thisLocaleValue === 'boolean')
          //       ) {
          //         localeUsingDefault = false;
          //       }
          //     }
          //   }
          // }
        }
      }
    }
    return { hasDefaults, usingDefault };
  }

  getAnyFieldsVisible(fields) {
    return fields.some((fieldProps) => {
      const visible = this.isFieldVisible(
        fieldProps,
        this.valueProps(
          fieldProps.field,
          fieldProps.model,
          this.getFieldPath(fieldProps.field, fieldProps.model),
        ),
      );
      return visible === undefined ? true : visible;
    });
  }

  isArrayField(field) {
    if (field.type === 'collection') {
      return true;
    }
    if (field.multi) {
      return true;
    }
    return false;
  }

  // TODO: this method became orphaned somehow
  // Might have happened when we moved logic from content/fields to field-groups
  // Should look into this because it might be more appropriate logic
  setDefaultsForEachField(model, defaults, fields, nestedPath = '') {
    const { root } = this.props;
    let thisDefaults = defaults || {};

    for (let field of fields || []) {
      const fieldRoot = contentFieldRootPath(field, model);
      const path = nestedPath
        ? nestedPath
        : root && root === fieldRoot
        ? ''
        : fieldRoot;
      const pathPrefix = path ? `${path ? `${path}.` : ''}` : path;
      const thisPath = `${pathPrefix}${field.id}`;

      if (
        field.default !== undefined &&
        field.fallback !== false &&
        this.props.fallback !== false
      ) {
        const value = get(path ? thisDefaults[path] : thisDefaults, field.id);

        if (value === undefined) {
          if (path) {
            thisDefaults[path] = thisDefaults[path] || {};
            set(thisDefaults[path], field.id, field.default);
          } else {
            set(thisDefaults, field.id, field.default);
          }
        }
      } else if (this.isArrayField(field)) {
        if (Array.isArray(thisDefaults)) {
          for (let i = 0; i < thisDefaults.length; i++) {
            thisDefaults = this.setDefaultsForEachField(
              model,
              thisDefaults,
              field.fields,
              `${thisPath ? `${thisPath}.` : ''}${i}`,
            );
          }
        }

        continue;
      } else if (Array.isArray(thisDefaults)) {
        thisDefaults = {};
      } else if (field.fields) {
        thisDefaults = this.setDefaultsForEachField(
          model,
          thisDefaults,
          field.fields,
          thisPath,
        );
      }
    }

    return thisDefaults;
  }

  onClickResetDefault = (event) => {
    event.preventDefault();
    const { path, fieldid, fieldpath } = event.currentTarget.dataset;
    const recordValues = { ...this.state.recordValues };
    set(
      recordValues,
      fieldpath,
      get(this.state.currentDefaults, `${path ? `${path}.` : ''}${fieldid}`),
    );
    this.setState({
      recordValues,
    });
  };

  renderField(fieldProps) {
    const { field } = fieldProps;

    if (field.type === 'field_row') {
      return (
        <div className="row">
          {field.fields?.map((rowField) => (
            <div
              key={rowField.id}
              className={this.rowSpanInRow(rowField, field.fields)}
            >
              {this.renderFieldVisible({
                ...fieldProps,
                field: rowField,
                inRow: true,
              })}
            </div>
          ))}
        </div>
      );
    }

    return this.renderFieldVisible(fieldProps);
  }

  renderFieldVisible(fieldProps) {
    const { model, field } = fieldProps;
    const fieldPath = this.getFieldPath(field, model);
    const valueProps = this.valueProps(field, model, fieldPath);

    const visible = this.isFieldVisible(fieldProps, valueProps);

    if (visible === undefined) {
      return (
        <div className="content-fields-row">
          {this.renderFieldContent(fieldProps, fieldPath, valueProps)}
        </div>
      );
    }

    if (visible) {
      return (
        <FadeIn
          transitionAppear={
            !valueProps.defaultValue || !!valueProps.usingDefault
          }
          className="content-fields-row"
        >
          {this.renderFieldContent(fieldProps, fieldPath, valueProps)}
        </FadeIn>
      );
    }

    return null;
  }

  getFieldConditions(state) {
    const { allFields, recordValues } = state;
    const fieldConditions = {};

    for (let fieldProps of allFields) {
      const { field } = fieldProps;
      if (field.id) {
        fieldConditions[field.id] = this.evalFieldConditions(
          fieldProps,
          recordValues,
        );
      }
    }

    return { fieldConditions };
  }

  evalFieldConditions({ field, model, path }, recordValues) {
    const { settings, values } = this.props;
    const { client } = this.context;

    if (field.conditions) {
      const $settings = settings?.[model.app_id];
      const matched = evalConditionsWithAppFields(
        client,
        path,
        field.conditions,
        {
          ...values,
          ...recordValues,
          $settings,
        },
      );
      return matched;
    }

    return true;
  }

  isFieldVisible(fieldProps, valueProps) {
    const { field } = fieldProps;
    const { fieldConditions } = this.state;
    const { recordValues } = valueProps;

    if (field.type === 'field_row') {
      return field.fields?.some?.((rowField) => {
        const visible = this.isFieldVisible(
          {
            ...fieldProps,
            field: rowField,
            inRow: true,
          },
          this.valueProps(
            rowField,
            field.model,
            this.getFieldPath(rowField, field.model),
          ),
        );
        return visible === undefined ? true : visible;
      });
    }

    const isActive =
      fieldConditions[field.id] !== undefined
        ? fieldConditions[field.id]
        : this.evalFieldConditions(fieldProps, recordValues);

    // Child lookup fields may be hidden until their parent value is defined
    const isVisible = contentFieldVisible({
      field,
      getParent: (parentId) =>
        this.state.allFields.find((f) => f.field.id === parentId),
      getParentPath: (field) => this.getFieldPath(field.field, field.model),
      valueProps,
    });

    return this.props.zone === 'preview' || (isActive && isVisible);
  }

  renderFieldContent({ model, field, path, inRow }, fieldPath, valueProps) {
    const {
      record,
      currency,
      lookup,
      categories,
      disabled,
      readonly,
      namespace,
      root,
      view,
      showHelp,
    } = this.props;

    const { hasDefaults, usingDefault } = this.state;

    const fieldName = namespace ? `${namespace}.${fieldPath}` : fieldPath;

    const hasFallback =
      hasDefaults[fieldPath] && field.fallback && this.props.fallback !== false;

    const isDefault = field.default && hasFallback && usingDefault[fieldPath];

    const renderLabelParams = {
      field,
      path,
      fieldPath,
      isDefault,
      hasFallback,
    };

    // allow child components to use the same render function
    const self = this;
    const renderLabelFunction = (text) => {
      return self.renderLabel(text, renderLabelParams);
    };

    const label = this.renderLabel(field.label, renderLabelParams);

    const fieldProps = {
      readonly,
      ...field,
      label,
      labelRenderer: renderLabelFunction,
      rawLabel: field.label,
      name: fieldName,
      currency,
      lookup,
      categories,
      ...valueProps,
      disabled,
      className: `${inRow ? undefined : this.rowSpan(field)} ${
        isDefault ? 'content-fields-using-default' : ''
      }`,
      app: model?.app,
      content_id: model?.collectionModel?.fields[field.id]?.content_id || null,
      source_type: model?.source_type,
      fieldCollection: model?.collection,
      appSettingsField: model?.appSettingsField,
      view,
      showHelp,
    };

    if (field.type === 'collection') {
      return (
        <ContentCollection
          {...fieldProps}
          record={record}
          model={model}
          path={path}
          root={root}
          className={isDefault ? 'content-fields-using-default' : undefined}
        />
      );
    }

    if (field.type === 'lookup' && field.value_type === 'collection') {
      return (
        <ContentCollection
          {...fieldProps}
          record={record}
          model={model}
          path={path}
          root={root}
          fields={[
            {
              ...field,
              value_type: undefined,
              collection_parent_id: undefined,
              parentId: valueProps.parentId,
            },
          ]}
          heading={false}
          className={isDefault ? 'content-fields-using-default' : undefined}
        />
      );
    }

    if (inRow) {
      return this.renderContentField({ ...fieldProps, admin_span: undefined });
    }

    return <div className="row">{this.renderContentField(fieldProps)}</div>;
  }

  renderContentField(props) {
    return <ContentField {...props} />;
  }

  renderLabel(labelText, params) {
    const { field, path, fieldPath, isDefault, hasFallback } = params;
    if (this.props.zone === 'preview') {
      return labelText;
    }
    if (field.childCollection) {
      return null;
    }

    return (
      <>
        {labelText}{' '}
        {hasFallback && (
          <span className="content-fields-label-fallback">
            {isDefault ? (
              <span className="default-label">Default</span>
            ) : (
              <div className="default-kebab">
                <KebabButton
                  anchorPosition={44}
                  items={[
                    <button
                      key="reset"
                      data-path={path}
                      data-fieldid={field.id}
                      data-fieldpath={fieldPath}
                      onClick={this.onClickResetDefault}
                      type="button"
                    >
                      Reset to default
                    </button>,
                  ]}
                />
              </div>
            )}
          </span>
        )}
      </>
    );
  }

  valueProps(field, model, fieldPath) {
    const { root } = this.props;
    const { usingDefault, recordValues, currentDefaults } = this.state;
    const props = {
      rootValue: recordValues,
      defaultValue: contentFieldValue(field, recordValues, model, root),
      recordValues,
    };

    // Return default root value if applicable
    if (!isEmpty(model?.defaults) && usingDefault[fieldPath]) {
      const defaultValues = root ? get(currentDefaults, root) : currentDefaults;
      props.rootValue = defaultValues;
      props.defaultValue = contentFieldValue(field, defaultValues, model, root);
      props.usingDefault = true;
    }

    return props;
  }

  rowSpan(field) {
    if (field.admin_span) {
      return `span${field.admin_span}`;
    }
    return 'span4';
  }

  rowSpanInRow(field, rowFields) {
    const defaultSpan = 5 - rowFields.length;
    if (field.admin_span) {
      return `span${field.admin_span}`;
    }
    return `span${defaultSpan <= 1 ? 1 : defaultSpan}`;
  }

  renderGroups(groups, groupFields) {
    const { values = {} } = this.props;
    const tabName = `content_groups_${groups.join('_')}`;
    const tabValue = values && values[tabName];

    return (
      <>
        <Tabs
          name={tabName}
          items={groups.map((group) => ({
            value: group,
            label: group,
          }))}
        />

        {groups.map((group, i) => (
          <TabView
            key={group}
            active={group}
            value={tabValue}
            default={i === 0}
          >
            {groupFields[group].map(({ model, field, path }, index) => (
              <Fragment key={`${field.id} ${field.type} ${index}`}>
                {this.renderField(
                  {
                    model,
                    field,
                    path,
                  },
                  index + 1 === groupFields[group].length,
                )}
              </Fragment>
            ))}
          </TabView>
        ))}
      </>
    );
  }

  render() {
    const { fieldsWithoutGroup, groups, groupFields } = this.state;

    if (!fieldsWithoutGroup.length && !groups.length) {
      return null;
    }

    return (
      <div className="content-field-groups">
        {fieldsWithoutGroup.map((field, index) => (
          <Fragment key={`${field.id} ${field.type} ${index}`}>
            {this.renderField(field, index + 1 === fieldsWithoutGroup.length)}
          </Fragment>
        ))}

        {groups.length > 0 && this.renderGroups(groups, groupFields)}
      </div>
    );
  }
}
