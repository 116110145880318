import React from 'react';
import { connect } from 'react-redux';

import api from 'services/api';

import actions from 'actions';

import BulkExport from 'components/bulk/export';
import NotFoundPage from 'components/pages/error/404';
import ViewLoading from 'components/view/loading';

import Collection from './Collection';

const query = {
  expand: ['codes:1'],
};

const tabs = {
  default: {
    label: 'All codes',
  },
  active: {
    label: 'Used',
    query: {
      use_count: { $gt: 0 },
    },
  },
  inactive: {
    label: 'Unused',
    query: {
      use_count: 0,
    },
  },
};

const filters = {
  use_count: {
    label: 'Used',
    operators: ['gt', 'lt', 'eq'],
    type: 'number',
  },
  date_created: {
    label: 'Created',
    operators: ['after', 'before'],
    type: 'date',
  },
};

const fields = {
  code: {
    label: 'Code',
    default: true,
  },
  use_count: {
    label: 'Used',
    func: (code) => {
      return code.use_count || 0;
    },
  },
  date_created: {
    label: 'Created',
    type: 'date',
  },
};

const exportCSVFields = [
  { key: 'code', label: 'Code' },
  { key: 'use_count', label: 'Used', export: (code) => code.use_count || 0 },
  { key: 'date_created', label: 'Date created' },
  { key: 'id', label: 'ID' },
];

const mapStateToProps = (state) => ({
  data: state.data,
  bulk: state.data.bulk,
});

const mapDispatchToProps = (dispatch) => ({
  bulkExport: (model, params) => {
    return dispatch(actions.data.bulkExport(model, params));
  },

  bulkCancel: () => {
    return dispatch(actions.data.bulkCancel());
  },
});

export class CouponCodes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coupon: null,
      loaded: false,
      showingExport: false,
      onClickExport: this.onClickExport.bind(this),
      onClickExportCancel: this.onClickExportCancel.bind(this),
      onClickExportReset: this.onClickExportReset.bind(this),
      onSubmitExport: this.onSubmitExport.bind(this),
    };

    this.headerActions = [
      {
        label: 'Export',
        fa: 'arrow-to-bottom',
        type: 'sub',
        onClick: this.onClickExport.bind(this),
      },
    ];
  }

  componentDidMount() {
    api
      .get('/data/coupons/{id}', {
        id: this.props.params.id,
      })
      .then((coupon) => {
        this.setState({ loaded: true, coupon });
      });
  }

  onClickExport(event) {
    event.preventDefault();
    this.setState({ showingExport: !this.state.showingExport });
  }

  onClickExportCancel(event) {
    event.preventDefault();
    if (this.props.bulk.running) {
      this.props.bulkCancel();
    }
    this.setState({ showingExport: false });
  }

  onClickExportReset(event) {
    event.preventDefault();
    this.props.bulkCancel();
  }

  onSubmitExport(values) {
    const { coupon } = this.state;

    this.props.bulkExport(`coupons:codes`, {
      ...values,
      filename: `coupon-${coupon.id}-codes`,
      csvFields: exportCSVFields,
      query: {
        parent_id: coupon.id,
      },
    });
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    const { coupon } = this.state;

    if (!coupon) {
      return <NotFoundPage />;
    }

    return (
      <div>
        <Collection
          {...this.props}
          sectionTitle={coupon.name}
          title="Coupon codes"
          detail={true}
          nested={true}
          uri={`/coupons/${coupon.id}`}
          model={`coupons/${coupon.id}/codes`}
          recordUri="/coupons/{parent_id}"
          emptyAction={false}
          tabs={tabs}
          filters={filters}
          fields={fields}
          headerActions={this.headerActions}
          query={query}
        />

        {this.state.showingExport && (
          <BulkExport label="Coupon codes" {...this.props} {...this.state} />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponCodes);
