import React from 'react';
import { classNames } from 'utils';

import Loading from 'components/loading';

import './view.scss';

export default function ViewLoading({ className, children }) {
  return (
    <div className={classNames('view-loading', className)}>
      <Loading>
        <div className="view-loading-content" aria-label="Loading…">
          {children}
        </div>
      </Loading>
    </div>
  );
}
