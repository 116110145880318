import React from 'react';
import { connect } from 'react-redux';
import qs from 'qs';

import actions from 'actions';
import auth from 'services/auth';
import segment from 'services/segment';

import LoginLayout from 'components/login/layout';
import LoginPage from 'components/login/login';
import RecoverPage from 'components/login/recover';
import JoinPage from 'components/login/join';
import SwitchPage from 'components/login/switch';
import CliSwitchStorePage from 'components/login/cli-switch-store';
import CliAuthSuccessPage from 'components/login/cli-auth-success';
import LoadingView from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';
import Cookies from 'universal-cookie';

const { BASE_URI } = process.env;

const cookies = new Cookies();

const mapStateToProps = (state) => ({
  client: state.client,
  user: state.user,
  invited: state.user.invited,
  flash: state.flash,
});

const mapDispatchToProps = (dispatch) => ({
  performLogin: (username, password, options) => {
    dispatch(actions.flash.clear());
    return dispatch(actions.user.performLogin(username, password, options));
  },

  performLoginForum: (username, password, token) => {
    dispatch(actions.flash.clear());
    return dispatch(actions.user.loginForum(username, password, token));
  },

  recover: (data) => {
    dispatch(actions.flash.clear());
    return dispatch(actions.user.recover(data));
  },

  fetchUser: () => {
    return dispatch(actions.user.fetch());
  },

  fetchInvitedUser: (inviteKey) => {
    return dispatch(actions.user.fetchInvited(inviteKey));
  },

  updateInvitedUser: (inviteKey, values) => {
    return dispatch(actions.user.updateInvited(inviteKey, values));
  },

  flashSuccess: (message) => {
    dispatch(actions.flash.success(message));
  },

  flashError: (message) => {
    dispatch(actions.flash.error(message));
  },

  sendCliAuth(cliBaseUrl, storeId) {
    dispatch(actions.session.sendCliAuth(cliBaseUrl, storeId)).then(() => {
      cookies.remove('cliUrl', { path: '/' });
      window.location.href = '/admin/cli-success';
    });
  },
});

export class Login extends React.PureComponent {
  static onEnter() {
    window.scrollTo(0, 0);
  }

  static onChange() {
    window.scrollTo(0, 0);
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded: false,
      notfound: false,
      defaultValues: {},
      onSubmitLogin: this.onSubmitLogin.bind(this),
      onSubmitRecover: this.onSubmitRecover.bind(this),
      onSubmitJoin: this.onSubmitJoin.bind(this),
      onClickRecover: this.onClickRecover.bind(this),
    };
  }

  async componentWillMount() {
    const { params, client, user, fetchInvitedUser, router } = this.props;
    if (params.inviteKey) {
      const user = await fetchInvitedUser(params.inviteKey);
      if (!user) {
        router.replace('/login');
        this.setState({ notfound: true });
      }
    }

    // Auto choose store if on a store subdomain
    const cliUrl = cookies.get('cliUrl');
    const subdomain = window.location.host.split('.')[0];
    if (cliUrl && user.email && subdomain !== 'login') {
      this.onChooseCliStore(client.id);
    }

    this.setState({ loaded: true });
  }

  verifyLoginResponse(user) {
    const { flashError } = this.props;

    if (user === null) {
      flashError('Your email or password is incorrect, please try again.');
      return false;
    } else if (user.error) {
      flashError(user.error.message || 'An unknown error occurred');
      return false;
    }

    return true;
  }

  onSubmitLogin({ username, password }) {
    const { router, performLogin, performLoginForum, location } = this.props;
    const { jwt: token } = location.query;
    const isForumLogin = !!token;

    return isForumLogin
      ? performLoginForum(username, password, token).then((user) => {
          if (!this.verifyLoginResponse(user)) {
            return false;
          }

          if (!user.redirect_url) {
            return false;
          }

          return window.location.replace(user.redirect_url);
        })
      : performLogin(username, password).then(async (user) => {
          if (!this.verifyLoginResponse(user)) {
            return false;
          }

          segment.track('User logged in', {
            id: user.id,
            name: user.name,
            email: user.email,
            username: user.username,
            client_id: user.client_id,
            client_name: user.client_name,
          });

          const redirectTo =
            location.query.to && location.query.to.replace(/^\/admin/, '');

          let forceRedirect = false;

          if (redirectTo) {
            const { store_id } = qs.parse(decodeURIComponent(redirectTo)) || {};
            forceRedirect = Boolean(store_id);
          }

          const cliUrl = cookies.get('cliUrl');
          if (cliUrl) {
            router.replace(`${BASE_URI}/cli-switch`);
          } else {
            auth.redirectClientSessionTo(redirectTo, router, {
              forceRedirect,
            });
          }
        });
  }

  async onSubmitRecover({ email, password }) {
    const { router, params, flashError, flashSuccess, recover } = this.props;

    const { resetKey } = params;

    const result = await recover({
      email,
      password,
      resetKey,
    });

    if (result.success) {
      if (resetKey) {
        router.replace('/login');
        flashSuccess('Your password has been reset, login to continue');
        segment.track('User completed password reset', {
          email: result.email,
        });
      } else {
        flashSuccess('An email has been sent to recover your password');
        segment.track('User requested password reset', {
          email: result.email,
        });
      }
    } else if (result.error) {
      flashError(result.error.message || result.error);
    } else if (result.errors) {
      flashError(result.errors);
    } else {
      router.replace('/recover');
      flashError('Your reset key has expired. Please enter your email again.');
      segment.track('User failed password reset', {
        reset_key: resetKey,
      });
    }
  }

  async onSubmitJoin(values) {
    const { params, router, flashError, performLogin, updateInvitedUser } =
      this.props;

    let result;
    try {
      result = await updateInvitedUser(params.inviteKey, values);
    } catch (err) {
      flashError('Your email or password is incorrect, please try again.');
      return false;
    }

    if (!result) {
      return false;
    } else if (result.errors) {
      if (result.errors.username) {
        flashError('Looks like you already have an account, please log in');
      } else {
        flashError(result.errors);
      }
      return false;
    }

    const user = await performLogin(values.email, values.password, {
      recaptcha: false,
    });

    if (user) {
      // TODO: why would this not be set? we had an error here
      segment.track('User logged in', {
        id: user.id,
        name: user.name,
        email: user.email,
        username: user.username,
        client_id: user.client_id,
        client_name: user.client_name,
      });
    }
    auth.redirectClientSessionTo('/', router);
  }

  onClickRecover(values) {
    const { username } = values;
    this.setState({
      defaultValues: {
        ...this.state.defaultValues,
        username,
      },
    });
  }

  onChooseCliStore(storeId) {
    const { sendCliAuth } = this.props;
    const cliUrl = cookies.get('cliUrl');
    sendCliAuth(cliUrl, storeId);
  }

  onChooseDocsStore(storeId) {
    const { user } = this.props;
    const docsUrl = cookies.get('docsUrl');
    cookies.remove('docsUrl', { path: '/' });
    window.location.href = encodeURI(
      `${docsUrl}?session=${auth.getSession()}&email=${
        user.email
      }&store=${storeId}&stores=${user.authorizations
        .map((auth) => auth.client_id)
        .join(',')}`,
    );
  }

  renderPage() {
    switch (this.props.route.page) {
      case 'recover':
        return <RecoverPage {...this.props} {...this.state} />;
      case 'join':
        return <JoinPage {...this.props} {...this.state} />;
      case 'switch':
        return <SwitchPage {...this.props} {...this.state} />;
      case 'cli-switch':
        return (
          <CliSwitchStorePage
            {...this.props}
            {...this.state}
            onClickSelectStore={(storeId) => {
              this.onChooseCliStore(storeId);
            }}
          />
        );
      case 'docs-login':
        return (
          <CliSwitchStorePage
            {...this.props}
            {...this.state}
            docs={true}
            onClickSelectStore={(storeId) => {
              this.onChooseDocsStore(storeId);
            }}
          />
        );
      case 'cli-success':
        return <CliAuthSuccessPage {...this.props} {...this.state} />;
      default:
        return <LoginPage {...this.props} {...this.state} />;
    }
  }

  render() {
    if (!this.state.loaded) {
      return <LoadingView />;
    }

    if (this.state.notfound) {
      return <NotFoundPage />;
    }

    return (
      <LoginLayout {...this.props} {...this.state}>
        {this.renderPage()}
      </LoginLayout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
