import React from 'react';
import ContentFields from 'components/content/fields';
import FadeIn from 'components/transitions/fade-in';

export default class ContentRecordForm extends React.PureComponent {
  render() {
    const { record, values, content, model, view } = this.props;

    const showContentFields =
      view.content !== false && view.contentFields?.length > 0;

    return (
      <div className="content-record">
        <fieldset className="full content-record-view-fields">
          <ContentFields
            {...this.props}
            view={view}
            zone={undefined}
            collection={model.collection}
            models={content.models}
            record={record}
            values={values}
            currency={record?.currency}
            warnEmpty={!showContentFields}
          />
        </fieldset>
        {showContentFields && (
          <fieldset className="full">
            <FadeIn>
              <ContentFields
                {...this.props}
                zone="content"
                collection={model.collection}
                models={content.models}
                view={{
                  ...view.defaultView,
                  fields: view.contentFields,
                }}
                record={record}
                values={values}
                currency={record?.currency}
              />
            </FadeIn>
          </fieldset>
        )}
      </div>
    );
  }
}
