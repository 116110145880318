import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, map, each, find, pick } from 'lodash';
import TemplateEngine from 'swell-template';
import $ from 'jquery';

import { timezoneName } from 'utils/date';
import onTemplateContentKeyDown from 'utils/template';
import { isValueEqual, localeFallbackValue } from 'utils';

import api from 'services/api';

import View from 'components/view';
import Link from 'components/link';
import Alert from 'components/alert';
import { Form, Field, FieldLocalized, TabView, Label } from 'components/form';
import Modal from 'components/modal';
import ButtonLink from 'components/button/link';
import Help from 'components/tooltip/help';
import { FadeIn } from 'components/transitions';
import LocaleFieldSelect from 'components/locale/field-select';
import TemplatePreview from 'components/template-preview';
import AppIndicator from 'components/view/app-indicator';
import AppIcon from 'components/apps/icon';

import SectionHeader from '../section-header/section-header';

import NotificationFields from './notifications-fields';

import './settings.scss';

export default class NotificationSettings extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,

    edited: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    client: PropTypes.object.isRequired,
    record: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    notifications: PropTypes.object.isRequired,

    onSubmitForm: PropTypes.func.isRequired,
    onChangeForm: PropTypes.func.isRequired,
    fetchNotification: PropTypes.func.isRequired,
    onUpdateNotification: PropTypes.func.isRequired,
    onSendNotificationTest: PropTypes.func.isRequired,
    onCreateAbandonedCartNotification: PropTypes.func.isRequired,
    onDeleteAbandonedCartNotification: PropTypes.func.isRequired,
  };

  static contextTypes = {
    user: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
  };

  templateEngine = null;
  previewContent = null;
  previewContentData = null;
  previewSubject = null;
  previewSubjectData = null;

  constructor(props) {
    super(props);
    this.state = {
      showNotificationId: null,
      showNotificationLabel: null,
      noteValues: {},
      noteEdited: false,
      noteDefault: null,
      noteLoading: false,
    };

    this.onTemplateContentKeyDown = onTemplateContentKeyDown.bind(this);
    this.onSubmitNotificationFieldsForm =
      this.onSubmitNotificationFieldsForm.bind(this);
  }

  componentDidMount() {
    this.openNotificationTemplate(this.props.location.query.template);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.query.template !== prevProps.location.query.template) {
      this.openNotificationTemplate(location.query.template);
    }
  }

  openNotificationTemplate(template) {
    if (!template) {
      return;
    }

    const notesBySection = this.getNotificationsBySection();

    const allNotes = notesBySection.reduce((acc, section) => {
      acc.push(...section.notifications);

      return acc;
    }, []);

    const note = find(allNotes, { id: template });

    if (!note) {
      this.context.notifyError(`Notification '${template}' not found`);
      return;
    }

    const showNotificationId = note.id;
    const showNotificationLabel = note.showLabel || note.label;

    this.setState(
      { showNotificationId, showNotificationLabel, noteLoading: true },
      () => {
        setTimeout(() => this.loadNotification(showNotificationId), 10);
      },
    );
  }

  setLocationTemplate(template) {
    const { location } = this.props;
    if (template) {
      if (!location.query.template || location.query.template !== template) {
        this.routerReplace(`/settings/notifications?template=${template}`);
      }
    }
  }

  async loadNotification(id) {
    const result = await this.props.fetchNotification(id);
    if (result) {
      this.setLocationTemplate(result.id);
      this.setState({
        noteDefault: result,
        noteValues: {
          tab: 'preview',
          ...result.record,
          content: result.content || result.defaultContent,
          fields:
            result.record.fields ||
            (result.content === result.defaultContent
              ? result.defaultRecord.fields
              : null),
          store_logo: get(this.props.values, 'store_logo'),
          store_logo_width: get(this.props.values, 'store_logo_width'),
        },
        noteEdited: false,
        noteLoading: false,
      });
    } else {
      this.context.notifyError(`Unable to load notification template: ${id}`);
    }
  }

  onClickShowNotification = (event) => {
    event.preventDefault();
    const showNotificationId = event.currentTarget.dataset.id;
    const showNotificationLabel = event.currentTarget.dataset.label;
    this.setState(
      { showNotificationId, showNotificationLabel, noteLoading: true },
      () => {
        setTimeout(() => this.loadNotification(showNotificationId), 10);
      },
    );
  };

  onClickRevertNotification = (event) => {
    event.preventDefault();
    const { notifications, fetchNotification, onUpdateNotification } =
      this.props;
    const { showNotificationId, noteDefault } = this.state;
    const { defaultRecord, defaultContent } = notifications;
    this.context.openModal('Confirm', {
      title: `Revert notification template`,
      message: (
        <div>
          <p>
            Are you sure you want to revert to the original notification
            template?
          </p>
          <p>
            Warning: this will undo all changes you've made to this template.
          </p>
        </div>
      ),
      action: 'Revert',
      actionType: 'danger',
      onConfirm: async () => {
        this.noteForm.setValue('subject', defaultRecord.subject);
        this.noteForm.setValue('content', defaultContent);
        if (
          noteDefault.content !== defaultContent ||
          noteDefault.record.subject !== defaultRecord.subject ||
          !isValueEqual(noteDefault.record.$locale, defaultRecord.$locale) ||
          !isFieldValuesEqual(noteDefault.record.fields, defaultRecord.fields)
        ) {
          await onUpdateNotification({
            v2: defaultRecord.v2,
            content: defaultContent,
            subject: defaultRecord.subject,
            contact: defaultRecord.contact,
            $set: {
              query: defaultRecord.query,
              conditions: defaultRecord.conditions,
              sample: defaultRecord.sample,
              fields: defaultRecord.fields,
            },
          });
          const result = await fetchNotification(showNotificationId);
          if (result) {
            this.setState({
              noteDefault: result,
            });
          }
        }
        this.setState({
          noteValues: {
            ...this.state.noteValues,
            v2: defaultRecord.v2,
            content: defaultContent,
            subject: defaultRecord.subject,
            contact: defaultRecord.contact,
            fields: defaultRecord.fields,
            $locale: defaultRecord.$locale,
            $set: {
              query: defaultRecord.query,
              conditions: defaultRecord.conditions,
              sample: defaultRecord.sample,
            },
          },
        });
      },
    });
  };

  afterClose = () => {
    const { location } = this.props;

    if (location.query.template) {
      this.routerReplace(location.pathname);
    }
  };

  routerReplace = (path) => {
    const { router } = this.props;

    // Maintain scroll position
    const mainEl = document.getElementById('main') || window;
    const origScrollTop = mainEl.scrollTop;

    router.replace(path);

    mainEl.scrollTop = origScrollTop;
  };

  onCloseShowNotification = (event) => {
    if (this.state.noteEdited) {
      event.preventDefault();

      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.setState({ showNotificationId: null }, this.afterClose);
          // TODO: do we still need closeModal?
          this.context.closeModal();
        },
      });
    } else {
      this.setState({ showNotificationId: null }, this.afterClose);
    }
  };

  onClickCloseShowNotification = (event) => {
    event.preventDefault();
    this.onCloseShowNotification();
  };

  onChangeNotificationForm = (values, noteEdited) => {
    const { noteValues } = this.state;
    this.setState({
      noteValues: {
        ...noteValues,
        ...values,
      },
      noteEdited,
    });
  };

  onClickSendNotification = (event) => {
    const { noteValues } = this.state;
    event.preventDefault();
    this.props.onSendNotificationTest({
      to: noteValues.send_to,
      locale: noteValues.preview_locale,
      $config: {
        _locale: noteValues.$locale,
        subject: noteValues.subject,
        content: {
          html: {
            data: noteValues.content,
          },
        },
        fields: noteValues.fields,
      },
    });
  };

  onSubmitNotificationForm = (values) => {
    this.props
      .onUpdateNotification({
        ...values,
        send_to: undefined,
        preview_locale: undefined,
        editing_fields: undefined,
        // Deprecated, remove this later
        replyto: this.state.noteValues.replyto ? null : undefined,
        v2: this.state.noteValues.v2,
        $set: {
          ...this.state.noteValues.$set,
        },
      })
      .then((success) => {
        if (success) {
          this.setState({ showNotificationId: null }, this.afterClose);
        }
      });
  };

  async onSubmitNotificationFieldsForm(values) {
    const result = await this.props
      .onUpdateNotification({
        $set: {
          fields: values,
        },
      })
      .then((success) => {
        if (success) {
          this.setState({
            noteValues: {
              ...this.state.noteValues,
              fields: values,
            },
          });
        }
        return success;
      });

    return result;
  }

  getTemplateEngine = () => {
    const { client } = this.context;

    if (this.templateEngine) {
      return this.templateEngine;
    }
    return (this.templateEngine = new TemplateEngine({
      get: (url, data) => {
        return api.getLocalized(`/data/${url}`, data);
      },
      details: {
        ...client,
      },
    }));
  };

  setPreviewReadyState = (noteLoading) => this.setState({ noteLoading });

  renderShowNotification = () => {
    const { client, notifications, loading } = this.props;
    const { record, defaultRecord, defaultContent } = notifications;
    const { noteValues, noteEdited, noteLoading } = this.state;

    if (!record) {
      this.context.notifyError(`Notification not found`);
      return;
    }

    const isV2 = client.v2 || client.v2_notifications;
    const isCustom = record.client_id && !record.extends;

    const isCustomized =
      defaultRecord &&
      !defaultRecord.client_id &&
      (noteValues.content !== defaultContent ||
        noteValues.subject !== defaultRecord.subject ||
        noteValues.contact !== defaultRecord.contact ||
        !isValueEqual(noteValues.query, defaultRecord.query) ||
        !isValueEqual(noteValues.conditions, defaultRecord.conditions) ||
        !isFieldValuesEqual(noteValues.fields, defaultRecord.fields));

    const hasLocales = client.locales.length > 0;
    let subjectValue =
      noteValues.subject || record.subject || defaultRecord.sub || '';

    if (noteValues.preview_locale) {
      const context =
        noteValues.$locale || noteValues.subject ? noteValues : record;
      subjectValue = localeFallbackValue({
        context,
        name: 'subject',
        locale: noteValues.preview_locale,
        localeConfigs: client.locales,
      });
    }

    // Hack to prevent autosize template from janking scroll position
    if (this.templateModalScrollTop) {
      const $modal = $('.modal');
      if ($modal[0]) {
        $modal[0].scrollTop = this.templateModalScrollTop;
        this.templateModalScrollTop = null;
      }
    }

    const app = client.appsById[record.app_id];

    return (
      <Form
        ref={(form) => (this.noteForm = form)}
        onSubmit={this.onSubmitNotificationForm}
        onChange={this.onChangeNotificationForm}
      >
        <Modal
          title={this.state.showNotificationLabel}
          ref="showModal"
          width={960}
          actions={[
            {
              label: 'Save',
              type: 'submit',
              styleType: noteEdited ? 'default' : 'secondary',
            },
            isCustomized && {
              label: 'Revert to default',
              type: 'secondary',
              className: 'left button-cancel',
              onClick: this.onClickRevertNotification,
            },
            noteValues.tab === 'preview' &&
              app && {
                component: (
                  <>
                    <div className="left">
                      <AppIndicator app={app} />
                    </div>
                  </>
                ),
              },
            noteValues.tab === 'fields' && {
              component: (
                <Field
                  type="toggle"
                  name="editing_fields"
                  className="left outside"
                  label="Edit fields"
                  defaultChecked={!!noteValues.editing_fields}
                />
              ),
            },
          ]}
          loading={noteLoading || loading}
          onClose={this.onCloseShowNotification}
          localized={true}
          devtools={{ model: ':notifications', uri: record.id }}
          tabs={[
            { value: 'preview', label: 'Preview' },
            { value: 'fields', label: 'Fields' },
            { value: 'template', label: 'Template' },
            { value: 'settings', label: 'Settings' },
            { value: 'test', label: 'Test email' },
          ]}
        >
          {record && record.id ? (
            <fieldset>
              {!isV2 && (
                <Alert type="info">
                  <b>Note:</b> Your account is currently setup to send v1
                  notifications by default.
                  <br />
                  Please contact us when you're ready to make the switch.
                </Alert>
              )}
              <TabView value={noteValues.tab} active="preview" default>
                <div className="row">
                  <div
                    className={`form-field ${hasLocales ? 'span2' : 'span4'}`}
                  >
                    <Label>Subject</Label>
                    <TemplatePreview
                      client={client}
                      className="preview-subject"
                      defaults={notifications}
                      values={{ ...noteValues, content: subjectValue }}
                      onReadyStateChange={this.setPreviewReadyState}
                      getTemplateEngine={this.getTemplateEngine}
                      visible={!noteValues.tab || noteValues.tab === 'preview'}
                    />
                  </div>
                  {hasLocales && (
                    <LocaleFieldSelect
                      name="preview_locale"
                      label="Preview locale"
                      className="span2"
                      defaultValue={noteValues.preview_locale || client.locale}
                    />
                  )}
                </div>
                <div>
                  <div className="form-field">
                    <Label>Email body</Label>
                    <TemplatePreview
                      className="preview-content"
                      client={client}
                      defaults={notifications}
                      values={noteValues}
                      onReadyStateChange={this.setPreviewReadyState}
                      getTemplateEngine={this.getTemplateEngine}
                      visible={!noteValues.tab || noteValues.tab === 'preview'}
                    />
                  </div>
                </div>
              </TabView>
              <TabView value={noteValues.tab} active="fields">
                <NotificationFields
                  values={noteValues}
                  record={record}
                  onSubmit={this.onSubmitNotificationFieldsForm}
                  editing={noteValues.editing_fields}
                />
              </TabView>
              <TabView value={noteValues.tab} active="template">
                <FieldLocalized
                  type="text"
                  name="subject"
                  label="Email subject"
                  defaultValue={noteValues.subject}
                  localeValue={noteValues.$locale}
                />
                {noteValues.tab === 'template' ? (
                  <Field
                    type="textarea"
                    name="content"
                    label="Email body"
                    defaultValue={noteValues.content}
                    autoSize={true}
                    onKeyDown={this.onTemplateContentKeyDown}
                    className="code"
                  />
                ) : (
                  <Field
                    type="hidden"
                    name="content"
                    value={noteValues.content}
                  />
                )}
              </TabView>
              <TabView value={noteValues.tab} active="settings">
                {isCustom && (
                  <Fragment>
                    <div className="row">
                      <Field
                        type="text"
                        name="label"
                        label="Label"
                        help="For internal use, not visible to customers"
                        defaultValue={record.label}
                        placeholder={record.label || record.name}
                        required={!!record.label}
                        className="span2"
                      />
                      <Field
                        type="text"
                        name="name"
                        label="Internal ID"
                        help="For developer use, not visible to customers"
                        defaultValue={record.name.replace('v2', '')}
                        placeholder={record.name}
                        readonly={true}
                        disabled={true}
                        className="span2"
                      />
                    </div>
                    <Field
                      type="textarea"
                      name="description"
                      label="Description"
                      help="For internal use, not visible to customers"
                      defaultValue={record.description}
                      placeholder={record.description}
                      required={!!record.description}
                    />
                  </Fragment>
                )}
                <Field
                  type="text"
                  name="from"
                  label={
                    <span>
                      From email <span className="muted">(optional)</span>
                    </span>
                  }
                  defaultValue={noteValues.from || noteValues.replyto}
                  placeholder={this.context.client.support_email}
                  rules="email"
                />
                <Field
                  type="text"
                  name="bcc"
                  label={
                    <span>
                      BCC emails <span className="muted">(optional)</span>
                    </span>
                  }
                  defaultValue={noteValues.bcc}
                  placeholder="Comma separated list of emails"
                />
              </TabView>
              <TabView value={noteValues.tab} active="test">
                <div className="row">
                  <Field
                    type="text"
                    name="send_to"
                    label="Send to email"
                    defaultValue={this.context.user.email}
                    placeholder={this.context.user.email}
                    selectFocus={true}
                    className={hasLocales ? 'span2' : 'span4'}
                  />
                  {hasLocales && (
                    <LocaleFieldSelect
                      name="preview_locale"
                      label="Test locale"
                      className="span2"
                      defaultValue={noteValues.preview_locale || client.locale}
                    />
                  )}
                </div>
                <ButtonLink size="sm" onClick={this.onClickSendNotification}>
                  Send
                </ButtonLink>
              </TabView>
            </fieldset>
          ) : (
            <span>Notification {this.state.showNotificationId} not found</span>
          )}
        </Modal>
      </Form>
    );
  };

  onClickShowAbandonedCartNotification = (event) => {
    event.preventDefault();
    const { id, number } = event.currentTarget.dataset;
    const { notifications } = this.props;
    if (!notifications.abandonedCarts[id]) {
      this.onClickAddAbandonedCart(event, number);
    } else {
      this.onClickShowNotification(event);
    }
  };

  onClickAddAbandonedCart = (event, number) => {
    event.preventDefault();
    const showNotificationId = event.currentTarget.dataset.id;
    const showNotificationLabel = event.currentTarget.dataset.label;
    this.setState({
      showNotificationId,
      showNotificationLabel,
      noteLoading: true,
    });
    this.props.onCreateAbandonedCartNotification(number).then((success) => {
      if (success) {
        this.loadNotification(showNotificationId);
      }
    });
  };

  onClickRemoveAbandonedCart = (event) => {
    event.preventDefault();
    const { id } = event.currentTarget.dataset;
    this.context.openModal('ConfirmDelete', {
      title: 'abandoned cart email',
      onConfirm: () => {
        this.props.onDeleteAbandonedCartNotification(id);
      },
    });
  };

  getCustomNotificationsBySection() {
    const { notifications, content } = this.props;
    const {
      client: { appsById, appsEnabledById },
    } = this.context;

    const sections = {};
    each(notifications.custom, (note, id) => {
      const app = note.app_id && appsById[note.app_id];
      const installedApp = note.app_id && appsEnabledById[note.app_id];
      if (note.app_id && !installedApp) {
        return;
      }

      const modelCollection = content.collections[note.model];
      const sectionId = note.admin
        ? 'admin'
        : modelCollection
        ? note.model
        : 'custom';
      const sectionLabel = note.admin
        ? 'Admin'
        : modelCollection
        ? modelCollection.label
        : 'Custom';

      sections[sectionId] = sections[sectionId] || {
        id: sectionId,
        label: sectionLabel,
        isModel: !!modelCollection,
        notifications: [],
      };

      sections[sectionId].notifications.push({
        id,
        showId: `${note.model}.${note.name}`,
        label: !app ? (
          note.label
        ) : (
          <>
            {note.label}
            <AppIcon image={app.logo_icon} name={app.name} size={20} />
          </>
        ),
        showLabel: note.label,
        description: note.description || note.id,
      });
    });

    return sections;
  }

  getNotificationsBySection() {
    const { values, record, notifications } = this.props;
    const abandonedCartValues = values.abandoned_cart || {};
    const abandonedCartRecord = record.abandoned_cart || {};
    const abandonedCartSeries = [
      { id: 'carts.recovery', ...abandonedCartRecord },
      ...(abandonedCartRecord.series || []).map((instance, i) => ({
        ...(instance || {}),
        id: `carts.recovery-${i + 1}`,
      })),
    ];

    const customNotifications = this.getCustomNotificationsBySection();

    const sections = [
      {
        id: 'orders',
        label: 'Orders',
        notifications: [
          {
            id: 'orders.receipt',
            label: 'Order confirmation',
            description: 'Sent automatically when an order is submitted',
          },
          {
            id: 'orders.canceled',
            label: 'Order canceled',
            description: 'Sent automatically when an order is canceled',
          },
          {
            id: 'orders.refund',
            label: 'Order refunded',
            description: 'Sent automatically when a refund is processed',
          },
          {
            id: 'carts.invoice',
            label: 'Draft order invoice',
            description:
              "Sent manually by an admin using the 'Send invoice' action",
            enabled: true,
          },
          {
            id: 'giftcards.fulfillment',
            label: 'Gift card',
            description: 'Sent automatically when a gift card is ordered',
            enabled: true,
          },
          {
            id: 'carts.recovery',
            label: 'Abandoned cart',
            component:
              // checks for existing cart.recovery template before rendering this section
              Object.keys(notifications.abandonedCarts).length > 0 ? (
                <div key="abandoned" className="box-section">
                  <div className="box-grid">
                    <div className="box-column">
                      <div className="box-title">Abandoned cart</div>
                      <div className="box-subtitle">
                        Sent automatically when a cart is abandoned
                      </div>
                    </div>
                    <div className="box-column">
                      <Field
                        type="toggle"
                        name="abandoned_cart.enabled"
                        defaultChecked={!!abandonedCartValues.enabled}
                      />
                    </div>
                  </div>
                  <FadeIn
                    active={!!abandonedCartValues.enabled}
                    transitionAppear={false}
                    className="settings-box-table"
                  >
                    <div className="collection-table-container">
                      <table className="collection-table headless">
                        <tbody>
                          {abandonedCartSeries.map((instance, i) => (
                            <tr key={i}>
                              <td>
                                {abandonedCartSeries.length > 1 && (
                                  <span>#{i + 1}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                )}
                                <a
                                  href=""
                                  onClick={
                                    this.onClickShowAbandonedCartNotification
                                  }
                                  data-id={instance.id}
                                  data-number={i}
                                  data-label={
                                    abandonedCartSeries.length === 1
                                      ? 'Abandoned cart recovery'
                                      : `Abandoned cart recovery #${i + 1}`
                                  }
                                >
                                  {notifications.abandonedCarts[instance.id]
                                    ? notifications.abandonedCarts[instance.id]
                                        .subject
                                    : get(
                                        notifications.abandonedCarts[
                                          'carts.recovery'
                                        ],
                                        'subject',
                                        'You left something in your cart',
                                      )}
                                </a>
                              </td>
                              {i === 0 ? (
                                <td className="right">
                                  <div className="settings-box-table-inline-field">
                                    <Field
                                      type="select"
                                      name="abandoned_cart.delay"
                                      defaultValue={
                                        instance.delay !== undefined
                                          ? instance.delay
                                          : 3
                                      }
                                      options={[
                                        { value: 1, label: 'After 1 hour' },
                                        { value: 3, label: 'After 3 hours' },
                                        { value: 6, label: 'After 6 hours' },
                                        { value: 10, label: 'After 10 hours' },
                                        { value: 24, label: 'After 24 hours' },
                                      ]}
                                    />
                                  </div>
                                </td>
                              ) : (
                                <Fragment>
                                  <td className="right">
                                    <div className="settings-box-table-inline-field">
                                      <Field
                                        type="select"
                                        name={`abandoned_cart.series[${
                                          i - 1
                                        }].delay`}
                                        defaultValue={
                                          instance.delay !== undefined
                                            ? instance.delay
                                            : 2
                                        }
                                        options={[
                                          { value: 1, label: '1 day later' },
                                          { value: 2, label: '2 days later' },
                                          { value: 3, label: '3 days later' },
                                          { value: 4, label: '4 days later' },
                                          { value: 5, label: '5 days later' },
                                          { value: 6, label: '6 days later' },
                                          { value: 7, label: '7 days later' },
                                          { value: 8, label: '8 days later' },
                                          { value: 9, label: '9 days later' },
                                          { value: 10, label: '10 days later' },
                                        ]}
                                      />
                                    </div>
                                    <div className="settings-box-table-inline-field">
                                      {this.renderAbandonedCartTimeInput(
                                        i,
                                        instance.hour,
                                      )}
                                    </div>
                                  </td>
                                </Fragment>
                              )}
                              {abandonedCartSeries.length > 1 && (
                                <Fragment>
                                  <td className="action">
                                    <a
                                      href=""
                                      onClick={this.onClickRemoveAbandonedCart}
                                      data-id={instance.id}
                                    >
                                      Remove
                                    </a>
                                  </td>
                                </Fragment>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="form-field">
                      <ButtonLink
                        size="sm"
                        type="secondary"
                        onClick={this.onClickAddAbandonedCart}
                        data-id={`carts.recovery-${abandonedCartSeries.length}`}
                        data-label={`Abandoned cart recovery #${
                          abandonedCartSeries.length + 1
                        }`}
                      >
                        Add follow-up email
                      </ButtonLink>
                    </div>
                  </FadeIn>
                </div>
              ) : (
                <Fragment />
              ),
          },
          ...abandonedCartSeries.map((_, index) => ({
            id: `carts.recovery-${index}`,
            hide: true,
            label: `Abandoned cart #${index + 1}`,
          })),
        ],
      },
      {
        id: 'shipments',
        label: 'Shipping',
        notifications: [
          {
            id: 'orders.shipped',
            label: 'Shipping confirmation',
            description: 'Sent automatically when an order is fulfilled',
            enabled: true,
          },
          {
            id: 'orders.shipped-update',
            label: 'Shipping update',
            description:
              'Sent automatically when a fulfillment tracking number is updated',
            enabled: true,
          },
        ],
      },
      {
        id: 'accounts',
        label: 'Customers',
        notifications: [
          {
            id: 'accounts.welcome',
            label: 'Customer welcome',
            description:
              'Sent automatically when a customer first creates an account',
          },
          // {
          //   id: 'accounts.invite',
          //   label: 'Customer invite',
          //   description: `Sent manually by an admin using the 'Send invite' action`,
          //   enabled: true,
          // },
          {
            id: 'accounts.password-reset',
            label: 'Password reset',
            description:
              'Sent automatically when a customer requests to recover their password',
            enabled: true,
          },
        ],
      },
      {
        id: 'subscriptions',
        label: 'Subscriptions',
        notifications: [
          {
            id: 'subscriptions.new',
            label: 'New subscription',
            description: 'Sent automatically when a subscription is created',
          },
          {
            id: 'subscriptions.invoice',
            label: 'Subscription invoice',
            description:
              'Sent automatically when a subscription invoice is created',
          },
          {
            id: 'subscriptions.canceled',
            label: 'Subscription canceled',
            description: 'Sent automatically when a subscription is canceled',
          },
          {
            id: 'subscriptions.paused',
            label: 'Subscription paused',
            description: 'Sent automatically when a subscription is paused',
          },
          {
            id: 'subscriptions.resumed',
            label: 'Subscription resumed',
            description: 'Sent automatically when a subscription is resumed',
          },
          {
            id: 'subscriptions.payment-expiring',
            label: 'Subscription payment expiring',
            description:
              'Sent automatically when payment method for a subscription is about to expire',
          },
          {
            id: 'subscriptions.payment-failed',
            label: 'Subscription payment failed',
            description:
              'Sent automatically when a subscription payment is failed',
          },
          {
            component: (
              <div key="subscriptions-retry-rules" className="box-section">
                <div className="box-title">
                  <Link to="/settings/subscriptions">
                    Dunning and retry rules
                  </Link>
                </div>
                <div className="box-subtitle">
                  Configure automatic retries to occur when credit card payments
                  fail
                </div>
              </div>
            ),
          },
        ],
      },
      {
        id: 'payments',
        label: 'Payments',
        notifications: [
          {
            id: 'payments.confirm',
            label: 'Payment confirmation',
            description:
              'Sent automatically when a payment requires customer action',
          },
        ],
      },
      {
        id: 'admin',
        label: 'Admin',
        notifications: [
          {
            id: 'orders.receipt-admin',
            label: 'Order received',
            description:
              'Sent automatically to admin users when an order is placed',
          },
        ],
      },
      ...(customNotifications.custom?.notifications?.length > 0
        ? [
            {
              id: 'custom',
              label: 'Custom notifications',
              help: 'Custom notifications may not belong to a known collection',
              notifications: [],
            },
          ]
        : []),
    ];

    // Apply custom/app notifications to sections
    each(customNotifications, (section) => {
      const existingSection = sections.find((s) => s.id === section.id);
      if (existingSection) {
        for (const note of [...section.notifications]) {
          const matchingIndex = existingSection.notifications.findIndex(
            (n) => n.id === note.showId,
          );
          if (matchingIndex >= 0) {
            existingSection.notifications.splice(matchingIndex + 1, 0, note);
          } else {
            existingSection.notifications.push(note);
          }
        }
      } else {
        sections.push(section);
      }
    });

    return sections;
  }

  renderAbandonedCartTimeInput(num, value) {
    const { timezone } = this.context.client;
    const tz = timezoneName(timezone);
    const options = [];
    let hour = 1;
    while (hour <= 24) {
      options.push({
        value: hour,
        label: `${
          hour < 12 ? `${hour}:00 AM` : `${hour > 12 ? hour - 12 : hour}:00 PM`
        } (${tz})`,
      });
      hour++;
    }
    return (
      <Field
        type="select"
        name={`abandoned_cart.series[${num - 1}].hour`}
        defaultValue={value !== undefined ? value : 10}
        options={options}
      />
    );
  }

  render() {
    const { edited, values, onSubmitForm, onChangeForm } = this.props;

    const notificationsBySection = this.getNotificationsBySection();

    return (
      <div className="settings settings-notifications">
        <Form onSubmit={onSubmitForm} onChange={onChangeForm}>
          <View
            detail={true}
            uri="/settings/notifications"
            sectionTitle="Settings"
            headerTitle="Notifications"
            headerSubtitle="Customize notification emails with your own branding and content"
            headerActions={[
              {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
          >
            {notificationsBySection.map(
              (section) =>
                section.notifications.length > 0 && (
                  <fieldset
                    id={section.id}
                    key={section.id}
                    className="full last-child"
                  >
                    <header className="view-body-subheader">
                      <SectionHeader
                        className="view-body-subtitle"
                        title={section.label}
                        titleIcon={
                          section.help && <Help message={section.help} />
                        }
                      />
                    </header>

                    <div className="box">
                      {section.notifications.map((note) =>
                        note.component ? (
                          note.component
                        ) : note.hide ? (
                          <Fragment key={note.id} />
                        ) : (
                          <div key={note.id} className="box-section">
                            <div className="box-grid">
                              <div className="box-column">
                                <div className="box-title">
                                  <Link
                                    onClick={(event) => {
                                      event.preventDefault();
                                      this.routerReplace(
                                        `/settings/notifications?template=${note.id}`,
                                      );
                                    }}
                                    data-id={note.id}
                                    data-label={note.showLabel || note.label}
                                  >
                                    {note.label}
                                  </Link>
                                </div>
                                <div className="box-subtitle">
                                  {note.description}
                                </div>
                              </div>
                              <div className="box-column">
                                {note.enabled === undefined && (
                                  <Field
                                    type="toggle"
                                    name={`enabled['${note.id}']`}
                                    defaultChecked={values.enabled[note.id]}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </fieldset>
                ),
            )}
            <fieldset className="full">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title="Branding"
                />
              </div>
              <Field
                type="images"
                name="store_logo"
                label="Logo image"
                defaultValue={values.store_logo}
                single={true}
                minHeight={200}
              />
              <div className="row">
                <Field
                  type="number"
                  name="store_logo_width"
                  label={
                    <span>
                      Logo width <span className="muted">(pixels)</span>
                    </span>
                  }
                  defaultValue={values.store_logo_width || 200}
                  className="span2"
                />
                <Field
                  type="text"
                  name="store_color"
                  label="Primary color"
                  defaultValue={values.store_color}
                  placeholder="#614ed0"
                  className="span2"
                />
              </div>
            </fieldset>
          </View>
        </Form>
        {this.state.showNotificationId && this.renderShowNotification()}
      </div>
    );
  }
}

export function isFieldValuesEqual(fields1, fields2) {
  const compareFields = (field) => ({
    ...pick(field, ['id', 'label', 'type']),
    default: field.value !== undefined ? field.value : field.default,
  });
  const a = map(fields1, compareFields);
  const b = map(fields2, compareFields);
  return isValueEqual(a, b);
}
