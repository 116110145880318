import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';

export default class UserPermissions extends React.PureComponent {
  static contextTypes = {
    isAdvancedUserPermissions: PropTypes.bool,
    client: PropTypes.object.isRequired,
  };

  render() {
    const { user } = this.props;
    const { isAdvancedUserPermissions = false, client } = this.context;

    return (
      <Fragment>
        {user.client_id === 'admin' ? (
          <span>{capitalize(user.role)}</span>
        ) : client.owner_id === user.id ? (
          <span>Store owner</span>
        ) : isAdvancedUserPermissions && user.role && user.role.name ? (
          <span>{user.role.name}</span>
        ) : user.all_permissions || !user.permissions ? (
          <span>Full permissions</span>
        ) : user.permissions ? (
          <span>Custom permissions</span>
        ) : user.role ? (
          <span>{capitalize(user.role)}</span>
        ) : (
          'None'
        )}
      </Fragment>
    );
  }
}
