import React from 'react';
import { find } from 'lodash';
import Form from 'components/form';
import View from 'components/view';
import ContentRecordForm from 'components/content/record-form';
import { contentRecordName } from 'utils/content';
import { renderTemplate } from 'utils/collection';

export default class CollectionRecordPage extends React.PureComponent {
  state = {
    title: null,
    subtitle: null,
  };

  componentDidUpdate() {
    const { view, values } = this.props;

    // Render view template strings
    Promise.all([
      view?.title && renderTemplate(view.title, values),
      view?.subtitle && renderTemplate(view.subtitle, values),
    ]).then(([title, subtitle]) => this.setState({ title, subtitle }));
  }

  render() {
    const { onSubmitRecord, onChangeForm, ...props } = this.props;
    const { collection } = props.params;
    const { title, subtitle } = this.state;

    // Only show 'new' view if it exists
    // Show all except 'new' view otherwise
    const newView = props.new && find(props.content.views, { id: 'new' });
    const editViews =
      !props.new && props.content.views?.filter((view) => view.id !== 'new');
    const recordViews = newView ? [newView] : editViews;

    return (
      <Form
        onSubmit={onSubmitRecord}
        autoFocus={true}
        onChange={onChangeForm}
        values={props.values}
      >
        <View
          detail={true}
          uri={`/collections/${collection}`}
          sectionTitle={props.model.plural}
          headerTitle={
            title ||
            (props.new
              ? `New ${props.model.singular.toLowerCase()}`
              : contentRecordName(props.model.collection, props.values))
          }
          headerSubtitle={subtitle}
          headerActions={props.actions}
          extraActions={props.extraActions}
          localized={true}
          devtools={{
            record: props.record,
            model: collection,
            viewConsole: !!props.record,
          }}
          views={recordViews}
          app={props.view?.app}
        >
          <ContentRecordForm {...props} />
        </View>
      </Form>
    );
  }
}
