import { map, findIndex } from 'lodash';
import {
  LOAD_THEME_CONFIG_SUCCESS,
  SET_THEME_HOME_PAGE,
} from '../actions/types';

const initialState = {};

export default function themeConfig(state = initialState, action) {
  switch (action.type) {
    case LOAD_THEME_CONFIG_SUCCESS:
      return normalizeConfig(action.payload);
    case SET_THEME_HOME_PAGE:
      const homePageContent = `/content/pages/${action.payload}`;
      const homePageIndex = findIndex(state.pages, {
        content: homePageContent,
      });
      if (homePageIndex >= 0) {
        const pages = [...state.pages];
        const origIndex = findIndex(state.pages, { home: true });
        pages[homePageIndex] = { ...state.pages[homePageIndex], home: true };
        if (origIndex >= 0) {
          pages[origIndex] = { ...state.pages[origIndex], home: undefined };
        }
        return {
          ...state,
          pages,
        };
      }
      return state;
    default:
      return state;
  }
}

export function normalizeConfig(config) {
  config.settings = map(config.settings || [], (setting, index) => {
    if (setting.id !== 'checkout') {
      setting.id = setting.id || `${setting.label || setting.name}_${index}`;
    }
    setting.fields = map(setting.fields || [], (field) => {
      field.id = field.id || field.label || field.name;
      return field;
    });
    return setting;
  });
  config.pages = map(config.pages || [], (page, index) => {
    page.id = page.id || `${page.label || page.name}_${index}`;
    return page;
  });
  return config;
}
