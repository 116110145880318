import React from 'react';
import Alert from 'components/alert/alert';

export default function ThemeSupportAlert(props) {
  return (
    <Alert type="warning" {...props}>
      Hosted storefronts will no longer be officially supported while we work to
      implement new hosting integrations. &nbsp;
      <a href="https://developers.swell.is/storefronts/storefront-migration-guide">
        Learn how to migrate
      </a>
    </Alert>
  );
}
