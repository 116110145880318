import React, { Fragment } from 'react';
import { map, get, reduce } from 'lodash';
import Link from 'components/link';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import { formatCurrency } from 'utils';
import { productThumbUrl, productName } from 'utils/product';
import { subscriptionIntervalDesc } from 'utils/subscription';

export default function Items({
  items,
  showQuantity = false,
  currency: eventCurrency,
}) {
  function renderCustomOptions(options) {
    const customOptions = options.filter((option) => !option.variant);
    return (
      customOptions.length > 0 && (
        <div className="order-view-item-options">
          <h4>Options</h4>
          <ul className="text">
            {customOptions.map((option, index) => (
              <li key={index}>
                <b>{option.name}</b> {option.value}
              </li>
            ))}
          </ul>
        </div>
      )
    );
  }

  function getItemImage(item) {
    const imageUrl = productThumbUrl(item.product, item.variant, 84, {
      padded: '',
      blank: item.product ? undefined : null,
    });
    if (!imageUrl) {
      return null;
    }
    return (
      <span className="image">
        {item.product_id ? (
          <Link to={`/products/${item.product_id}`}>
            <img src={imageUrl} alt={productName(item.product, item.variant)} />
          </Link>
        ) : (
          <img src={imageUrl} alt={productName(item.product, item.variant)} />
        )}
      </span>
    );
  }

  function getItemDetails(item) {
    const details = [];
    const sku =
      (item.variant && item.variant.sku) || (item.product && item.product.sku);
    if (item.variant || item.sku) {
      details.push(
        <div className="order-view-item-details">
          {item.changes && item.changes.variant && (
            <span className="muted">
              {get(item.changes.variant.old, 'name', 'Unknown')} &rarr;{' '}
            </span>
          )}
          {item.variant && (
            <label className="item-variant-name">{item.variant.name}</label>
          )}
          {sku && <label className="item-sku">({sku})</label>}
        </div>,
      );
    }
    if (item.options) {
      details.push(renderCustomOptions(item.options));
    }
    if (item.bundle_items && item.bundle_items.length > 0) {
      details.push(
        <div className="order-view-item-bundle">
          <ul>
            {item.bundle_items.map((bundleItem) => (
              <li key={bundleItem.id}>
                {getItemImage(bundleItem)}
                <Link to={`/products/${bundleItem.product_id}`}>
                  {productName(bundleItem.product, bundleItem.variant)}
                </Link>
                <span className="muted">
                  &nbsp;&times; {bundleItem.quantity}
                </span>
                {bundleItem.options && renderCustomOptions(bundleItem.options)}
              </li>
            ))}
          </ul>
        </div>,
      );
    }
    return details;
  }

  function renderCurrencyChange(itemChange) {
    return (
      <span className="nowrap">
        <span className="muted">
          Currency:&nbsp;
          <strike>{itemChange.old}</strike> &rarr;
        </span>{' '}
        {itemChange.new}&emsp;
      </span>
    );
  }

  function renderPriceChange(itemChange, currencyChange, currency) {
    return (
      <span className="nowrap">
        <span className="muted">
          Price:&nbsp;
          <strike>
            {formatCurrency(
              itemChange.old,
              currencyChange
                ? currencyChange.old
                : itemChange.currency || currency,
            )}
          </strike>{' '}
          &rarr;
        </span>{' '}
        {formatCurrency(
          itemChange.new,
          currencyChange ? currencyChange.new : itemChange.currency || currency,
        )}
        &emsp;
      </span>
    );
  }

  function renderQuantityChange(itemChange) {
    return (
      <span className="nowrap">
        <span className="muted">
          Quantity:&nbsp;
          {itemChange.old} &rarr;
        </span>{' '}
        {itemChange.new}&emsp;
      </span>
    );
  }

  function renderScheduleChange(itemChange) {
    return (
      <span className="nowrap">
        <span className="muted">
          Schedule:&nbsp;
          <strike>
            {subscriptionIntervalDesc(
              {
                interval: itemChange.old_interval,
                interval_count: itemChange.old_interval_count,
                limit: itemChange.old_limit,
              },
              { every: true },
            )}
          </strike>{' '}
          &rarr;
        </span>{' '}
        {subscriptionIntervalDesc(
          {
            interval: itemChange.new_interval,
            interval_count: itemChange.new_interval_count,
            limit: itemChange.new_limit,
          },
          { every: true },
        )}
        &emsp;
      </span>
    );
  }

  function renderItemChanges(item) {
    if (!item.changes) {
      return null;
    }
    const currency = eventCurrency || get(item, 'product.currency', 'USD');
    const changes = reduce(
      item.changes,
      (acc, itemChange, key) => {
        if (key === 'currency') {
          acc.push(renderCurrencyChange(itemChange));
        } else if (key === 'price') {
          acc.push(
            renderPriceChange(itemChange, item.changes.currency, currency),
          );
        } else if (key === 'quantity') {
          acc.push(renderQuantityChange(itemChange));
        } else if (key === 'schedule') {
          acc.push(renderScheduleChange(itemChange));
        }
        return acc;
      },
      [],
    );
    return (
      changes.length > 0 && (
        <div className="activity-event-details">
          {changes.map((change, i) => (
            <Fragment key={i}>{change}</Fragment>
          ))}
        </div>
      )
    );
  }

  return map(items, (item, index) => {
    const changes = renderItemChanges(item);
    return (
      <div key={item.id || index} className="box">
        {getItemImage(item)}
        <div className="activity-event-item">
          <span>
            {item.changes && item.changes.product && (
              <span className="muted">
                {get(item.changes.product.old, 'name', 'Unknown')} &rarr;{' '}
              </span>
            )}
            <Link to={`/products/${item.product_id}`}>
              {get(item, 'product.name', item.description || 'Unknown product')}
            </Link>
            &nbsp;
            {showQuantity && (
              <span className="muted">&times; {item.quantity}</span>
            )}
            {item.recurring && (
              <span className="activity-item-recurring-icon">
                <Tooltip message="This is a recurring invoice item">
                  <Icon fa="sync-alt" faType="regular" />
                </Tooltip>
              </span>
            )}
            <div className="muted">
              {getItemDetails(item).map((detail, i) => (
                <Fragment key={i}>{detail}</Fragment>
              ))}
            </div>
          </span>
          {changes}
        </div>
      </div>
    );
  });
}
