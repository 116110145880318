import React from 'react';
import Icon from 'components/icon';
import { formatCurrencyMaybeRound } from 'utils';
import { shippingService } from 'utils/order';
import User from '../utils/user';
import Date from '../utils/date';

export default function ShippingUpdated({
  event,
  shipping,
  settings,
  currency,
}) {
  const service = shippingService(settings, shipping);

  if (!service) {
    return null;
  }

  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/shipment-updated" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          Shipping method changed
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
        <span className="activity-event-subtitle">
          <span className="muted">
            {service.name} (
            {shipping.price > 0
              ? formatCurrencyMaybeRound(shipping.price, currency)
              : 'FREE'}
            )
          </span>
        </span>
      </div>
    </div>
  );
}
