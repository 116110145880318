// @flow
import React from 'react';
import { connect } from 'react-redux';
import Status from 'components/status';
import Collection from './Collection';
import { accountName } from 'utils/account';

export const query = {
  draft: true,
  expand: ['account'],
};

export const tabs = {
  default: {
    label: 'All drafts',
  },
  open: {
    label: 'Open',
    query: {
      order_id: null,
    },
  },
  completed: {
    label: 'Completed',
    query: {
      order_id: { $ne: null },
    },
  },
};

export const filters = {
  grand_total: {
    label: 'Total',
    operators: ['gt', 'lt', 'eq'],
    type: 'currency',
  },
  date_created: {
    label: 'Date',
    operators: ['after', 'before'],
    type: 'date',
  },
  status: {
    label: 'Status',
    options: [
      { value: 'open', label: 'Open' },
      { value: 'completed', label: 'Completed' },
    ],
    func: (query) => {
      if (query === 'open') {
        return { draft: true, order_id: null };
      }
      if (query === 'completed') {
        return { draft: true, order_id: { $ne: null } };
      }
    },
  },
  customer: {
    label: 'Customer',
    type: 'LookupCustomer',
    func: (account_id) => {
      return { account_id };
    },
  },
};

export const fields = {
  number: {
    label: 'Draft',
    type: 'link',
    url: '/orders/drafts/{id}',
    default: true,
  },
  date_created: {
    label: 'Date',
    type: 'date',
  },
  account: {
    label: 'Customer',
    func: (cart: Object) => cart.account && accountName(cart.account),
  },
  status: {
    label: 'Status',
    func: (cart: Object) => {
      if (cart.order_id) {
        return <Status type="positive">Converted</Status>;
      }
      return <Status type="muted">Open</Status>;
    },
  },
  grand_total: {
    label: 'Total',
    type: 'currency',
    rounded: true,
  },
};

export const bulkActions = [
  {
    modal: 'BulkDelete',
    label: 'Delete',
    type: 'danger inverse',
  },
];

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export class DraftOrders extends React.Component {
  state = {};

  constructor(props: Object) {
    super(props);
    this.state = {};
  }

  headerActions = [{ label: 'New order', link: '/orders/drafts/new' }];

  render() {
    return (
      <div>
        <Collection
          ref="collection"
          {...this.props}
          title="Draft orders"
          uri="/orders/drafts"
          model="carts"
          emptyDescription="Create orders for customers manually and send email invoices, or send a direct link to checkout to receive payment."
          tabs={tabs}
          filters={filters}
          fields={fields}
          selectable={true}
          bulkActions={bulkActions}
          headerActions={this.headerActions}
          query={query}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DraftOrders);
