import React from 'react';
import PropTypes from 'prop-types';
import { map, reverse } from 'lodash';
import Icon from 'components/icon';
import Chart from 'components/charts/chart';
import Help from 'components/tooltip/help';
import { Line, Tooltip } from 'components/charts';
import { toFixed, formatNumberAbbreviated, inflect } from 'utils';
import { formatReportDate } from 'utils/chart';
import './overview.scss';

const chartConfig = {
  data: [
    {
      id: 0,
      description: 'Current period',
    },
    {
      id: 1,
      description: 'Previous period',
    },
  ],
};

export default class AccountUsageOverview extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  renderContent() {
    const { timezone } = this.context.client;
    const { type, typeName, hint, points, period = 'dayOfMonth' } = this.props;

    const [current, previous] = points;
    const diff = toFixed(
      previous.summary[type] &&
        (current.summary[type] / previous.summary[type] - 1) * 100,
      0,
    );

    const chart = {
      ...chartConfig,
      data: chartConfig.data.map((line, i) => ({
        ...line,
        points: points[i].results.map((point) => [point.x, point[type]]),
      })),
    };

    return (
      <>
        <div className="reports-overview-values">
          <div className="reports-overview-value">
            {formatNumberAbbreviated(current.summary[type])}{' '}
            {typeName &&
              inflect(current.summary[type], typeName, {
                showCount: false,
              })}
            {hint && <Help message={hint} />}
          </div>
          <div className="reports-overview-diff">
            {diff > 0 && (
              <span className="positive">
                <Icon fa="arrow-alt-up" faType="solid" /> {diff}%
              </span>
            )}
            {diff < 0 && (
              <span className="muted">
                <Icon fa="arrow-alt-down" faType="solid" /> {-diff}%
              </span>
            )}
          </div>
        </div>
        <Chart
          style={{ width: '100%', height: 75 }}
          margin={{ top: 10, left: 0, bottom: 0, right: 0 }}
          xValue="0"
          yValue="1"
          ticks={0}
          data={chart.data}
        >
          {reverse(
            map(chart.data, (_data, i) => (
              <Line key={i} x="0" y="1" dataId={i} useDashesBeyondNow={true} />
            )),
          )}
          <Tooltip
            xFormat={formatReportDate(period, timezone)}
            yFormat={(value) => formatNumberAbbreviated(value)}
          />
        </Chart>
      </>
    );
  }

  render() {
    return <div className="reports-overview-chart">{this.renderContent()}</div>;
  }
}
