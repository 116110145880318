import Avatar from 'boring-avatars';
import React from 'react';
import { imageUrl, classNames } from 'utils';
import './apps.scss';

export default function AppIcon({ image, name, size = 32, disabled = false }) {
  return (
    <div
      className={classNames('apps-icon', { disabled })}
      style={{ width: size, height: size }}
    >
      {image?.url ? (
        <img
          src={imageUrl(image, { width: size * 2, height: size * 2 })}
          alt={name || 'App'}
          style={{ width: size, height: size }}
        />
      ) : (
        <Avatar
          size={size}
          square={true}
          name={name || 'App'}
          variant="pixel"
        />
      )}
    </div>
  );
}
