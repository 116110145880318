import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Icon from 'components/icon';
import { MoveUp } from 'components/transitions';

import actions from 'actions';

import MenuSectionTitle from './MenuSectionTitle';
import SettingsTab from './SettingsTab';
import { sectionLabel } from 'editor/src/utils/settingsMenu';

const mapStateToProps = (state, ownProps) => ({
  model: state.content?.collections[ownProps.modelName] || {},
  contentItems: state.content?.recordsLoaded?.[ownProps.modelName] || [],
  contentTypes: state.content?.contentTypes,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    fetchContentTypes,
    fetchModelRecord,
    fetchModelRecordList,
    saveModelRecord,
  } = actions.content;

  // dry, will call it multiple times
  const modelName = ownProps.modelName;
  const fetchModelRecordListAction = fetchModelRecordList(ownProps.modelName);

  return {
    fetchContentTypes: (modelFields) =>
      dispatch(fetchContentTypes(modelFields)),
    fetchModelRecord: (contentItemId) =>
      dispatch(fetchModelRecord(modelName, contentItemId)),
    fetchModelRecordList: () => dispatch(fetchModelRecordListAction),
    // saves a new record for the values, then refetches the list of records
    // to make sure the state is up to date
    saveModelRecord: (values) =>
      dispatch(saveModelRecord(modelName, values)).then(() =>
        dispatch(fetchModelRecordListAction),
      ),
  };
};

/**
 * Show a list of items belonging to a content model
 *
 * The component encapsulates the actions required to:
 * - pull all items that belong to the content model
 * - fetch a certain item with complete data
 * - save a new item
 *
 * At the same time, the component displays buttons to trigger the addition or
 * edit of items.
 */
class ContentModelTab extends React.Component {
  componentDidMount() {
    const { model, fetchContentTypes, fetchModelRecordList } = this.props;

    // fetch model items needed for display
    fetchModelRecordList();

    // fetch data for the content types used by the model, they are required for
    // display in `SettingsMenu`
    //
    // these definitions are setup while configuring the theme through json files
    if (model) {
      fetchContentTypes(model.fields);
    }
  }

  componentDidUpdate(prevProps) {
    const { model, fetchContentTypes } = this.props;
    const { model: prevModel } = prevProps;

    // ensure that we have the latest types in case of model changes
    if (model && model !== prevModel && model.fields !== prevModel?.fields) {
      fetchContentTypes(model.fields);
    }
  }

  /**
   * called when an item from the displayed list is clicked
   * it fetches the full item data from the API, intention being to update the
   * Redux store for other components to pick-up
   */
  onClickItem = (event) => {
    event.preventDefault();
    const { fetchModelRecord } = this.props;
    const { contentItemId } = event.currentTarget.dataset;
    fetchModelRecord(contentItemId);
  };

  onClickNew = (event) => {
    event.preventDefault();
    const { contentTypes, model, onClickNew } = this.props;
    onClickNew(model, contentTypes);
  };

  onClickSave = (event) => {
    event.preventDefault();
    const { onClickSave, saveModelRecord } = this.props;
    onClickSave(saveModelRecord);
  };

  render() {
    const {
      active,
      contentItems,
      faIcon,
      model,
      onClickBack,
      showSave,
      transitionAppear,
      contextMenuButton,
      modelName,
    } = this.props;

    return (
      <Fragment>
        <SettingsTab
          active={active}
          transitionAppear={transitionAppear}
          onClickBack={onClickBack}
          title={model.label}
          crumbs="Template"
        >
          {contentItems.map((contentItem, index) => (
            <MenuSectionTitle
              key={contentItem.id}
              className={index === 0 ? 'first' : ''}
              faIcon={faIcon}
              onClick={this.onClickItem}
              data-content-item-id={contentItem.id}
              title={sectionLabel(contentItem)}
              contextMenuButton={contextMenuButton({
                contentitemid: contentItem.id,
                model: modelName,
                label: sectionLabel(contentItem),
              })}
            />
          ))}
          <section key="add" className="last">
            <a
              href=""
              onClick={this.onClickNew}
              className="button button-secondary button-md button-inline"
            >
              <Icon fa="plus" faType="solid" /> Add {model.label}
            </a>
          </section>
        </SettingsTab>
        {showSave && (
          <MoveUp
            active={true}
            distance={80}
            className="SettingsMenu-collection-action"
          >
            <Button size="sm" onClick={this.onClickSave}>
              Save {model.label}
            </Button>
          </MoveUp>
        )}
      </Fragment>
    );
  }
}

ContentModelTab.propTypes = {
  active: PropTypes.bool.isRequired,
  transitionAppear: PropTypes.bool.isRequired,
  faIcon: PropTypes.string.isRequired,
  onClickNew: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  showSave: PropTypes.bool.isRequired,
  // redux:
  contentItems: PropTypes.array.isRequired,
  contentTypes: PropTypes.object,
  model: PropTypes.shape({
    label: PropTypes.string.isRequired,
    fields: PropTypes.object,
  }).isRequired,
  contextMenuButton: PropTypes.func.isRequired,
  modelName: PropTypes.string.isRequired,
};

// connect the component to Redux
export default connect(mapStateToProps, mapDispatchToProps)(ContentModelTab);
