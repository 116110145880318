import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'components/form';
import Modal from 'components/modal';

export default class SSHKeyForm extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  static propTypes = {
    onSubmitForm: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  state = {
    values: {},
  };

  onChangeForm = (values) => {
    const { public_key, name } = this.state.values;
    const shouldCheckKeyName =
      !name &&
      !values.name &&
      values.public_key &&
      !public_key &&
      public_key !== values.public_key;
    if (shouldCheckKeyName) {
      const parts = values.public_key.split(' ');
      if (parts[0] === 'ssh-rsa' && parts[1] && parts[2]) {
        values.name = parts.slice(2).join(' ').trim();
      }
    }
    this.setState({
      values: {
        ...this.state.values,
        ...values,
      },
    });
  };

  render() {
    const { loading } = this.props;
    const { values } = this.state;

    return (
      <Form onSubmit={this.props.onSubmitForm} onChange={this.onChangeForm}>
        <Modal
          title="Add SSH key"
          width={500}
          actions={[{ label: 'Add', type: 'submit' }]}
          className="modal-confirm"
          loading={loading}
          loadingTitle="Adding SSH key"
          onClose={this.props.onCloseModal}
        >
          <fieldset>
            <p>
              For more information see{' '}
              <a
                href="https://help.github.com/articles/generating-a-new-ssh-key-and-adding-it-to-the-ssh-agent/"
                target="blank"
              >
                generating a new SSH key
              </a>
              .
            </p>
            <Field
              type="textarea"
              name="public_key"
              label="Public key"
              required={true}
              autoSize={true}
              rows={3}
              placeholder="ssh-rsa..."
            />
            <Field
              type="text"
              name="name"
              label="Key name"
              required={true}
              placeholder="user@host"
              defaultValue={values.name}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
