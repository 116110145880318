import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import Form from 'components/form';
import Link from 'components/link';
import Icon from 'components/icon';
import LinkButton from 'components/button/link';
import ModelForm from 'components/model/form';
import { contentCollectionLabel } from 'utils/content';

export default class EditModelModalPage extends React.Component {
  static contextTypes = {
    closeModal: PropTypes.func.isRequired,
  };

  onClickSubmit = (event) => {
    event.preventDefault();
    this.refs.form.submit();
  };

  onClickClose = (event) => {
    this.context.closeModal();
  };

  modelName = (record) => {
    return `/settings/models/${record.api}.${
      record.namespace ? `${record.namespace}_${record.name}` : record.name
    }`;
  };

  modelHelpLinkName = (record) => {
    return this.modelName(record).replace(/\//g, '.').substring(1);
  };

  render() {
    const { loaded, record, params, onSubmitRecord, onChangeForm } = this.props;

    const found = Boolean(record?.id);

    return (
      <Modal
        title={
          found ? (
            <Fragment>
              {contentCollectionLabel(record)}
              <LinkButton
                to={`https://developers.swell.is/backend-api/${record.name}`}
                target="blank"
                type="sub"
                className="model-edit-modal-link"
              >
                <Icon fa="book" /> API reference
              </LinkButton>
            </Fragment>
          ) : (
            'Edit fields'
          )
        }
        icon={
          found && (
            <span className="muted">
              <Link to={this.modelName(record)}>
                <Icon svgType="link-external" />
              </Link>
            </span>
          )
        }
        width={1200}
        cancel={false}
        actions={[
          { label: 'Close', onClick: this.onClickClose, type: 'secondary' },
        ]}
        loading={!loaded}
        overrideHelpLinkKey={found && this.modelHelpLinkName(record)}
      >
        {found ? (
          <Form ref="form" onSubmit={onSubmitRecord} onChange={onChangeForm}>
            <ModelForm
              {...this.props}
              modal={true}
              editable={false}
              onSubmitRecord={onSubmitRecord}
            />
          </Form>
        ) : (
          <>Model not found: {params.id}</>
        )}
      </Modal>
    );
  }
}
