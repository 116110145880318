/* eslint-disable react/no-string-refs */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import postscribe from 'postscribe';

import cloneDeep from 'lodash/cloneDeep';
import upperFirst from 'lodash/upperFirst';
import lowerFirst from 'lodash/lowerFirst';
import get from 'lodash/get';
import map from 'lodash/map';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';

import api from 'services/api';

import { Form, Field, Fieldgroup, Label } from 'components/form';
import View from 'components/view';
import Modal from 'components/modal';
import Status from 'components/status';
import Secret from 'components/secret';
import Icon from 'components/icon';
import Link from 'components/link';
import IconPayment from 'components/icon/payment';
import PaymentMethodLogo from 'components/settings/payment-method-logo';
import PaymentsManual from './payments-manual';
import Help from 'components/tooltip/help';
import { FadeIn } from 'components/transitions';
import SectionHeader from 'components/section-header';
import FileReader from 'components/form/file-reader';
import PayPalExpress, {
  PayPalExpressEditModal,
} from 'components/settings/paypal/express';
import PayPalCommerce, {
  PayPalCommerceEditModal,
  PayPalCommerceOnboardingModal,
} from 'components/settings/paypal/commerce';
import { getDataFromFile, normalizeUrl, testUrlSegment } from 'utils';
import { modePrefix, mergePaymentSettings, isLiveMode } from 'utils/payment';
import {
  PAYPAL_DEFAULT_STYLE,
  gatPayPalExpressConnectParams,
  getPayPalCommerceConnectParams,
} from 'utils/paypal';
import { DELETE_WARNING } from 'utils/messages';
import { truncatedText } from 'utils/collection';

import './settings.scss';

const {
  STRIPE_CONNECT_REDIRECT_URL,
  STRIPE_CONNECT_TEST_CLIENT_ID,
  STRIPE_CONNECT_LIVE_CLIENT_ID,
} = process.env;

const CARD_GATEWAY_OPTIONS = [
  { value: 'authorizenet', label: 'Authorize.Net' },
  { value: 'braintree', label: 'Braintree' },
  { value: 'stripe', label: 'Stripe' },
  // WorldPay is disabled as gateway depends on new API
  // { value: 'worldpay', label: 'WorldPay' },
  { value: 'saferpay', label: 'Saferpay' },
  { value: 'quickpay', label: 'QuickPay' },
  { value: 'test', label: 'Swell test gateway' },
];

const KLARNA_REGIONS = {
  eu: 'Europe',
  na: 'North America',
  oc: 'Oceania',
};

const KLARNA_PAY_OPTIONS = {
  now: 'Pay now',
  later: 'Pay later',
  over_time: 'Pay over time',
};

function isStripeConnected(gateway = {}, useMode = false) {
  if (useMode) {
    const mode = gateway.mode !== 'test' ? 'live' : 'test';
    return (
      gateway[`${mode}_account_id`] &&
      gateway[`${mode}_publishable_key`] &&
      gateway[`${mode}_secret_key`] &&
      gateway[`${mode}_refresh_token`]
    );
  }
  return (
    (gateway.test_account_id || gateway.live_account_id) &&
    (gateway.test_publishable_key || gateway.live_publishable_key) &&
    (gateway.test_secret_key || gateway.live_secret_key) &&
    (gateway.test_refresh_token || gateway.live_refresh_token)
  );
}

function isBraintreeAppleEnabled(apple = {}) {
  return apple.enabled && apple.gateway === 'braintree';
}

function isBraintreeGoogleEnabled(google = {}) {
  return google.enabled && google.gateway === 'braintree';
}

function isBraintreePayPalEnabled(paypal = {}) {
  return paypal.enabled && paypal.gateway === 'braintree';
}

function isStripeKlarnaEnabled(klarna = {}) {
  return klarna.enabled && klarna.gateway === 'stripe';
}

function isStripeAppleEnabled(apple = {}) {
  return apple.enabled && apple.gateway === 'stripe';
}

function isStripeGoogleEnabled(google = {}) {
  return google.enabled && google.gateway === 'stripe';
}

function isStripeSourcesEnabled(methods = {}) {
  return (
    isStripeKlarnaEnabled(methods.klarna) ||
    get(methods.ideal, 'enabled') ||
    get(methods.bancontact, 'enabled') ||
    isStripeAppleEnabled(methods.apple) ||
    isStripeGoogleEnabled(methods.google)
  );
}

function isBraintreeSourcesEnabled(methods = {}) {
  return (
    isBraintreeAppleEnabled(methods.apple) ||
    isBraintreeGoogleEnabled(methods.google) ||
    isBraintreePayPalEnabled(methods.paypal)
  );
}

function isSaferpaySourcesEnabled(methods = {}) {
  return get(methods.twint, 'enabled');
}

export default class PaymentSettings extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    testEnv: PropTypes.string,
  };

  static propTypes = {
    params: PropTypes.shape({
      command: PropTypes.string,
    }),
    router: PropTypes.shape({
      replace: PropTypes.func,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({
        mode: PropTypes.string,
        merchantId: PropTypes.string,
        merchantIdInPayPal: PropTypes.string,
        permissionsGranted: PropTypes.string,
      }),
    }),
    routeParams: PropTypes.shape({
      command: PropTypes.string,
    }),
    client: PropTypes.shape({
      id: PropTypes.string,
      public_key: PropTypes.string,
      super_features: PropTypes.shape({
        has: PropTypes.func,
      }),
    }),
    record: PropTypes.object,
    values: PropTypes.shape({
      store: PropTypes.object,
      methods: PropTypes.shape({
        account: PropTypes.object,
        affirm: PropTypes.object,
        amazon: PropTypes.object,
        apple: PropTypes.object,
        bancontact: PropTypes.object,
        card: PropTypes.object,
        giftcard: PropTypes.object,
        google: PropTypes.object,
        ideal: PropTypes.object,
        klarna: PropTypes.object,
        paypal: PropTypes.object,
        paysafecard: PropTypes.object,
        resolve: PropTypes.object,
        twint: PropTypes.object,
      }),
      gateways: PropTypes.shape({
        stripe: PropTypes.object,
      }),
    }),
    edited: PropTypes.bool,
    loading: PropTypes.bool,
    onChangeForm: PropTypes.func,
    onSubmitForm: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      editValues: null,
      showEditCardModal: false,
      showEditPaypalExpressModal: false,
      showEditPaypalCommerceModal: false,
      showOnboardingPaypalCommerceModal: false,
      showPaysafecardModal: false,
      showKlarnaModal: false,
      showEditAmazonModal: false,
      showEditAffirmModal: false,
      showEditResolveModal: false,
      showActivateAmazonModal: false,
      showActivateStripeModal: false,
      gatewayActivationMode: props.location.query.mode,
      gatewayActivationError: false,
      amazonPrivateKey: null,
      confirmIsNeeded: false,
      confirmModalIsShown: false,
    };

    this.onChangeEditValues = this.onChangeEditValues.bind(this);
    // Card
    this.onClickEditCard = this.onClickEditCard.bind(this);
    this.onSubmitEditCard = this.onSubmitEditCard.bind(this);
    // PayPal Express
    this.onSubmitEditPaypal = this.onSubmitEditPaypal.bind(this);
    this.onClickEditPaypalExpress = this.onClickEditPaypalExpress.bind(this);
    // PayPal Commerce
    this.onClickEditPaypalCommerce = this.onClickEditPaypalCommerce.bind(this);
    this.onClickEditPaypalCommerceOnboarding =
      this.onClickEditPaypalCommerceOnboarding.bind(this);
    this.onClickClosePayPalCommerceOnboarding =
      this.onClickClosePayPalCommerceOnboarding.bind(this);
    this.onClickActivatePaypalCommerce =
      this.onClickActivatePaypalCommerce.bind(this);
    this.onClickResetPaypalCommerce =
      this.onClickResetPaypalCommerce.bind(this);
    // Amazon Pay
    this.onClickEditAmazon = this.onClickEditAmazon.bind(this);
    this.onClickPostActivateAmazon = this.onClickPostActivateAmazon.bind(this);
    this.onSubmitEditAmazon = this.onSubmitEditAmazon.bind(this);
    this.onClickActivateAmazon = this.onClickActivateAmazon.bind(this);
    // Stipe
    this.onClickActivateStripe = this.onClickActivateStripe.bind(this);
    // Affirm
    this.onClickEditAffirm = this.onClickEditAffirm.bind(this);
    this.onSubmitEditAffirm = this.onSubmitEditAffirm.bind(this);
    // Resolve
    this.onClickEditResolve = this.onClickEditResolve.bind(this);
    this.onSubmitEditResolve = this.onSubmitEditResolve.bind(this);
  }

  componentDidMount() {
    this.getCommandState();
  }

  getCommandState() {
    const { routeParams } = this.props;
    const command = routeParams?.command;

    switch (command) {
      case 'stripe_activated':
        return this.getStripeCommandState();
      case 'paypal_commerce_activated':
        return this.getPayPalCommerceCommandState();
      default:
        return;
    }
  }

  getStripeCommandState() {
    const { location, values } = this.props;
    const { code, mode } = location?.query || {};

    if (code) {
      api
        .connectStripe(this.props.client.public_key, code, mode)
        .then((response) => {
          if (response.error) {
            this.setState({
              showActivateStripeModal: true,
              gatewayActivationError: true,
            });
            return this.context.notifyError(response.error.message);
          }
          const {
            stripe_user_id,
            access_token,
            stripe_publishable_key,
            refresh_token,
            livemode,
          } = response;
          const stripeMode = livemode ? 'live' : 'test';
          setTimeout(() => {
            this.props.onSubmitForm(
              {
                ...values,
                gateways: {
                  ...values.gateways,
                  stripe: {
                    ...values.gateways.stripe,
                    connected: true,
                    use_connect: true,
                    mode: stripeMode,
                    [`${stripeMode}_account_id`]: stripe_user_id,
                    [`${stripeMode}_publishable_key`]: stripe_publishable_key,
                    [`${stripeMode}_secret_key`]: access_token,
                    [`${stripeMode}_refresh_token`]: refresh_token,
                  },
                },
                methods: {
                  ...values.methods,
                  card: {
                    ...values.methods.card,
                    gateway: 'stripe',
                  },
                },
              },
              this.refs.form,
            );
          }, 1000);
        });
    }

    this.setState({
      showActivateStripeModal: true,
    });
  }

  getPayPalCommerceCommandState() {
    const { location } = this.props;
    const { merchantId, merchantIdInPayPal, permissionsGranted, mode } =
      location?.query || {};
    const isLive = isLiveMode(mode);

    if (!merchantIdInPayPal || permissionsGranted !== 'true') {
      return;
    }

    setTimeout(() => {
      this.enablePayPalCommerce({
        mode: isLive ? 'live' : 'test',
        integrated_merchant_id: merchantId,
        [`${mode}_merchant_id`]: merchantIdInPayPal,
      });
    }, 1000);

    this.setState({
      showOnboardingPaypalCommerceModal: true,
    });
  }

  enablePayPalCommerce(values = {}) {
    this.props.onSubmitForm(
      {
        ...this.props.values,
        methods: {
          ...this.props.values.methods,
          paypal: {
            ...this.props.values.methods.paypal,
            name: 'PayPal Commerce Platform',
            activated: true,
            enabled: true,
            ppcp_activated: true,
            ppcp_enabled: true,
            ppcp: true,
            mode: 'test',
            style: PAYPAL_DEFAULT_STYLE,
            // Make sure PayPal Express is deactivated
            express_activated: false,
            express_enabled: false,
            // Reset PayPal Express fields
            merchant_id: null,
            client_id: null,
            client_secret: null,
            last_client_id: null,
            last_client_secret: null,
            gateway: null,
            ...values,
          },
        },
      },
      this.refs.form,
    );
  }

  removeCommandFromLocation() {
    const { params, router, location } = this.props;
    if (params.command) {
      router.replace(location.pathname.replace(`/${params.command}`, ''));
    }
  }

  onClickEditCard(event) {
    event.preventDefault();
    this.removeCommandFromLocation();
    this.setState({
      editValues: cloneDeep(this.props.values),
      showEditCardModal: !this.state.showEditCardModal,
      showActivateStripeModal: false,
      edited: false,
    });
  }

  onClickEditPaysafecard = (event) => {
    event.preventDefault();
    this.removeCommandFromLocation();
    this.setState({
      editValues: cloneDeep(this.props.values),
      showPaysafecardModal: !this.state.showPaysafecardModal,
      edited: false,
    });
  };

  onClickEditKlarna = (event) => {
    event.preventDefault();
    this.removeCommandFromLocation();
    this.setState({
      editValues: cloneDeep(this.props.values),
      showKlarnaModal: !this.state.showKlarnaModal,
      edited: false,
    });
  };

  onClickEditPaypalExpress(event) {
    event.preventDefault();
    this.removeCommandFromLocation();
    const { showEditPaypalExpressModal } = this.state;

    this.setState({
      editValues: cloneDeep(this.props.values),
      showEditPaypalExpressModal: !showEditPaypalExpressModal,
      edited: false,
    });
  }

  onClickEditPaypalCommerce(event) {
    event.preventDefault();
    this.removeCommandFromLocation();
    const { showEditPaypalCommerceModal } = this.state;

    this.setState({
      editValues: cloneDeep(this.props.values),
      showEditPaypalCommerceModal: !showEditPaypalCommerceModal,
      edited: false,
    });
  }

  onClickEditPaypalCommerceOnboarding(event) {
    event.preventDefault();

    this.setState({
      showOnboardingPaypalCommerceModal: false,
      showEditPaypalCommerceModal: true,
    });
  }

  onClickClosePayPalCommerceOnboarding(event) {
    event.preventDefault();
    this.removeCommandFromLocation();

    this.setState({
      showOnboardingPaypalCommerceModal: false,
    });
  }

  onEnablePayPalCommerce = (event, enabled) => {
    event.preventDefault();
    const { values } = this.props;
    const paypalMethod = values?.methods?.paypal || {};

    // Show confirmation modal when enabling PayPal Commerce if PayPal Express is activated
    if (enabled && paypalMethod.express_activated) {
      this.renderPayPalCommerceConfirmationModal().then((confirmed) => {
        if (confirmed) {
          return this.enablePayPalCommerce();
        }
      });

      // Return 'false' explicitly to not enable PayPal Commerce without confirmation
      return false;
    }
  };

  async onClickActivatePaypalCommerce(event) {
    event.preventDefault();
    const { values } = this.props;
    const { testEnv } = this.context;
    const paypalMethod = values?.methods?.paypal || {};

    // Show confirmation modal when activating PayPal Commerce if PayPal Express is activated
    if (paypalMethod.express_activated) {
      const confirmed = await this.renderPayPalCommerceConfirmationModal();

      if (!confirmed) {
        return;
      }
    }

    const { client } = this.props;
    const { editValues } = this.state;
    const paypalValues = editValues?.methods?.paypal || {};
    const isLive = !testEnv && isLiveMode(paypalValues.mode);
    const connectParams = getPayPalCommerceConnectParams(
      client.id,
      isLive,
      testEnv,
    );
    const response = await api.connectPaypal(
      client.public_key,
      connectParams,
      isLive ? 'live' : 'sandbox',
    );

    if (response.error) {
      return this.context.notifyError(response.error.message);
    }

    window.location.href = response.href;
  }

  onClickResetPaypalCommerce(event) {
    event.preventDefault();

    this.context.openModal('Confirm', {
      title: 'Reset PayPal Commerce credentials',
      message: DELETE_WARNING,
      action: 'Confirm',
      actionType: 'danger',
      onConfirm: () => {
        const { values, onSubmitForm } = this.props;
        const paypalMethod = values?.methods?.paypal || {};
        const settings = mergePaymentSettings({
          settings: values,
          key: 'paypal',
          value: {
            ...paypalMethod,
            [`${paypalMethod.mode}_merchant_id`]: null,
            integrated_merchant_id: null,
            ppcp_activated: false,
          },
        });

        onSubmitForm(settings);
      },
    });
  }

  onClickActivatePaysafecard = (event) => {
    event.preventDefault();
    this.removeCommandFromLocation();
    this.setState({
      showPaysafecardModal: !this.state.showPaysafecardModal,
    });
  };

  onClickActivateKlarna = (event) => {
    event.preventDefault();
    this.removeCommandFromLocation();
    this.setState({
      showKlarnaModal: !this.state.showKlarnaModal,
    });
  };

  onClickEditAmazon(event) {
    event.preventDefault();
    this.removeCommandFromLocation();
    this.setState({
      editValues: cloneDeep(this.props.values),
      showEditAmazonModal: !this.state.showEditAmazonModal,
      edited: false,
    });
  }

  onClickActivateAmazon(event) {
    event.preventDefault();
    this.removeCommandFromLocation();
    this.setState({
      showEditAmazonModal: !this.state.showEditAmazonModal,
    });
  }

  onClickEditAffirm(event) {
    event.preventDefault();
    this.removeCommandFromLocation();
    this.setState({
      editValues: cloneDeep(this.props.values),
      showEditAffirmModal: !this.state.showEditAffirmModal,
      edited: false,
    });
  }

  onClickEditResolve(event) {
    event.preventDefault();
    this.removeCommandFromLocation();
    this.setState({
      editValues: cloneDeep(this.props.values),
      showEditResolveModal: !this.state.showEditResolveModal,
      edited: false,
    });
  }

  onClickActivateStripe(event) {
    event.preventDefault();
    const { testEnv } = this.context;
    const { mode } = event.currentTarget.dataset;
    this.removeCommandFromLocation();
    this.setState({
      showActivateStripeModal: !this.state.showActivateStripeModal,
      gatewayActivationMode: testEnv ? 'test' : mode || null,
      gatewayActivationError: false,
    });
  }

  onSubmitEditCard(values) {
    this.props
      .onSubmitForm(
        {
          ...this.props.values,
          ...values,
        },
        this.refs.form,
      )
      .then((success) => {
        if (success) {
          this.setState({ showEditCardModal: false });
        }
      });
  }

  onSubmitEditPaypal(values) {
    return this.props
      .onSubmitForm(
        {
          ...this.props.values,
          ...values,
          methods: {
            ...this.props.values.methods,
            ...values.methods,
            paypal: {
              ...this.props.values.methods.paypal,
              ...values.methods.paypal,
            },
          },
        },
        this.refs.form,
      )
      .then(() => {
        this.setState({
          showEditPaypalExpressModal: false,
          showEditPaypalCommerceModal: false,
        });
      });
  }

  onSubmitEditPaysafecard = (values) => {
    this.props
      .onSubmitForm(
        {
          ...this.props.values,
          ...values,
          methods: {
            ...this.props.values.methods,
            ...values.methods,
            paysafecard: {
              ...this.props.values.methods.paysafecard,
              ...values.methods.paysafecard,
            },
          },
        },
        this.refs.form,
      )
      .then(() => {
        this.setState({ showPaysafecardModal: false });
      });
  };

  onSubmitEditKlarna = (values) => {
    this.props
      .onSubmitForm(
        {
          ...this.props.values,
          ...values,
          methods: {
            ...this.props.values.methods,
            ...values.methods,
            klarna: {
              ...this.props.values.methods.klarna,
              ...values.methods.klarna,
              pay_options: reduce(
                values.methods.klarna.pay_options,
                (acc, enabled, id) => {
                  if (enabled) {
                    acc.push(id);
                  }
                  return acc;
                },
                [],
              ),
            },
          },
        },
        this.refs.form,
      )
      .then(() => {
        this.setState({ showKlarnaModal: false });
      });
  };

  onSubmitEditAmazon(values) {
    const { amazon } = values.methods;
    const isLive = isLiveMode(amazon.mode);
    if (!amazon[modePrefix(isLive, 'private_key')]) {
      return this.context.notifyError(
        `${isLive ? 'Live' : 'Test'} private key is required`,
      );
    }

    this.props
      .onSubmitForm(
        {
          ...this.props.values,
          ...values,
          methods: {
            ...this.props.values.methods,
            ...values.methods,
            amazon: {
              ...this.props.values.methods.amazon,
              ...values.methods.amazon,
            },
          },
        },
        this.refs.form,
      )
      .then(() => {
        this.setState({ showEditAmazonModal: false });
      });
  }

  onSubmitEditAffirm(values) {
    this.props
      .onSubmitForm(
        {
          ...this.props.values,
          ...values,
        },
        this.refs.form,
      )
      .then(() => {
        this.setState({ showEditAffirmModal: false });
      });
  }

  onSubmitEditResolve(values) {
    this.props
      .onSubmitForm(
        {
          ...this.props.values,
          ...values,
        },
        this.refs.form,
      )
      .then(() => {
        this.setState({ showEditResolveModal: false });
      });
  }

  onClickConnectStripe = (event) => {
    event.preventDefault();
    window.location.href = this.getStripeConnectUrl();
  };

  onClickRefreshStripe = (event) => {
    event.preventDefault();
    api
      .refreshStripeConnection(
        this.props.client.public_key,
        this.state.editValues.mode,
      )
      .then(async (response) => {
        if (response.error) {
          return this.context.notifyError(response.error.message);
        }
        const { access_token, stripe_publishable_key, livemode } = response;
        const prefix = livemode ? 'live' : 'test';
        const result = await this.props.onSubmitForm(
          {
            ...this.props.values,
            gateways: {
              ...this.props.values.gateways,
              stripe: {
                ...this.props.values.gateways.stripe,
                [`${prefix}_publishable_key`]: stripe_publishable_key,
                [`${prefix}_secret_key`]: access_token,
              },
            },
          },
          this.refs.form,
        );

        this.context.notifySuccess('Your access token has been updated');

        this.setState({
          editValues: result,
        });
      });
  };

  onSubmitManualPaymentMethod = (values) => {
    return this.props
      .onSubmitForm({
        ...this.props.values,
        manualMethods: values,
      })
      .then((result) => {
        if (!result || result.errors) {
          return false;
        }
      });
  };

  onChangeEditValues(values, edited) {
    this.setState({
      editValues: { ...values },
      edited,
    });
  }

  async onConfirmDisconnectGatewayValidate(gateway) {
    const { id } = gateway;
    switch (id) {
      case 'stripe':
        const { error } = await api.disconnectStripe(
          this.props.client.public_key,
          this.state.editValues.mode,
        );
        if (error && !error.message.includes('account does not exist')) {
          this.context.notifyError(error.message);
          return { success: false };
        }
        const prefix = gateway.mode === 'live' ? 'live' : 'test';
        return {
          success: true,
          values: {
            connected: false,
            [`${prefix}_account_id`]: null,
            [`${prefix}_publishable_key`]: null,
            [`${prefix}_secret_key`]: null,
            [`${prefix}_refresh_token`]: null,
          },
        };
      default:
        return { success: true };
    }
  }

  onClickDisconnectGateway = (id) => {
    const gateway = get(this.props.record.gateways, id, {});
    this.context.openModal('Confirm', {
      title: `Disconnect ${gateway.name || 'this gateway'}`,
      message: (
        <p>
          Are you sure you want to disconnect {gateway.name || 'this gateway'}{' '}
          and clear settings?
        </p>
      ),
      action: 'Disconnect',
      actionType: 'danger',
      onConfirm: () => {
        this.onConfirmDisconnectGatewayValidate(gateway).then(
          async ({ success, values = {} }) => {
            if (success) {
              const result = await this.props.onSubmitForm({
                ...this.props.values,
                gateways: {
                  ...this.props.values.gateways,
                  [id]: {
                    ...gateway,
                    ...values,
                  },
                },
              });

              this.setState({
                editValues: result,
              });
            }
          },
        );
      },
    });
  };

  // TODO: for when we re-design this page with system-wide payment mode
  // getMethodsByModeExclusive(mode = 'test') {
  //   const { values } = this.props;

  //   const liveMethods = map(values.methods, (method) => {
  //     const gateway = find(values.gateways, { id: method.gateway });
  //     return {
  //       ...method,
  //       mode: gateway ? gateway.mode : method.mode,
  //     };
  //   }).filter((method) => method.enabled && method.mode === mode);

  //   return liveMethods;
  // }

  // renderSwitchPaymentMode = () => {
  //   const { record } = this.props;

  //   const testOnlyMethods = this.getMethodsByModeExclusive('test');
  //   const liveOnlyMethods = this.getMethodsByModeExclusive('live');
  //   const isEnabling = record.mode === 'test';

  //   return (
  //     <Modal
  //       title={
  //         isEnabling ? 'Enable live payments' : 'Disable live payments'
  //       }
  //       actions={[
  //         {
  //           label: 'Confirm',
  //           type: 'submit',
  //           onClick: this.onClickConfirmPaymentMode,
  //         },
  //       ]}
  //       width={600}
  //       onClose={this.onClickSwitchPaymentMode}
  //     >
  //       {isEnabling ? (
  //         testConfigs.length > 0 ? (
  //           <div>
  //             You have {inflect(testConfigs.length, 'payment methods')} still in <b>test mode</b>.
  //             <ul>
  //               {testMethods.map((method, i) => (<li key={i}>{method.name}</li>))}
  //             </ul>
  //           </div>
  //         ) : (
  //           <p>This will enabled your payment methods and gateways to make live transactions.</p>
  //         )
  //       ) : (
  //         <p>This will </p>
  //       )}
  //     </Modal>
  //   );
  // };

  getPaypalActivateUrl(sandbox = false) {
    const { client, testEnv } = this.context;

    if (this.paypalScriptTimeout) {
      clearTimeout(this.paypalScriptTimeout);
    }
    this.paypalScriptTimeout = setTimeout(() => {
      this.paypalScriptTimeout = null;
      if (document.getElementById('#paypal_script')) {
        postscribe(
          '#paypal_script',
          `
          <script>(function(d, s, id){
          var js, ref = d.getElementsByTagName(s)[0];
          if (!d.getElementById(id)){
          js = d.createElement(s); js.id = id; js.async = true;
          js.src =
          "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";
          ref.parentNode.insertBefore(js, ref);
          }
          }(document, "script", "paypal-js"));
          </script>
        `,
        );
      }
    }, 1000);

    const isLive = !testEnv && !sandbox;
    const params = gatPayPalExpressConnectParams(client.id, isLive, testEnv);
    const baseUrl = isLive
      ? 'https://www.paypal.com/webapps/merchantboarding/webflow/externalpartnerflow'
      : 'https://www.sandbox.paypal.com/webapps/merchantboarding/webflow/externalpartnerflow';
    const activateUrl = `${baseUrl}?${map(
      params,
      (val, key) => `${key}=${encodeURIComponent(val)}`,
    ).join('&')}`;

    return activateUrl;
  }

  onClickPostActivateAmazon(event) {
    event.preventDefault();
    const { CLIENT_URL } = process.env;
    const { client, session } = this.context;
    const { country = 'US' } = get(client, 'business', {});
    const domains = get(this.props, 'record.store.domains.results') || [];
    const isEU = ['US', 'CA'].indexOf(country) === -1;

    const baseUrl = isEU
      ? 'https://payments-eu.amazon.com/register'
      : 'https://payments.amazon.com/register';

    const params = {
      isSandbox: isEU,
      marketplaceId: 'A3QE3KW96NA5LO',
      ld: 'APUSSWELL',
      source: 'SPPL',
      merchantUniqueId: client.id,
      locale: client.locale,
      merchantCountry: country,
      merchantStoreDescription: client.name,
      returnURL: `${window.location.origin}/admin/api/activate-amazon-pay/${session.id}`,
      merchantLoginDomains: [
        CLIENT_URL.replace('CLIENT_ID', client.id),
        CLIENT_URL.replace('CLIENT_ID', `${client.id}_dev`),
        ...domains
          .filter((domain) => domain.verified)
          .map((domain) => domain.name),
      ].map((url) => normalizeUrl(url)),
    };

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', baseUrl);

    const appendFormField = (name, value) => {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', name);
      hiddenField.setAttribute('value', value);
      form.appendChild(hiddenField);
    };

    for (let key in params) {
      // eslint-disable-next-line no-prototype-builtins
      if (params.hasOwnProperty(key)) {
        if (params[key] instanceof Array) {
          for (let value of params[key]) {
            appendFormField(`${key}[]`, value);
          }
        } else {
          appendFormField(key, params[key]);
        }
      }
    }

    document.body.appendChild(form);
    form.submit();
  }

  getStripeConnectUrl = () => {
    const { testEnv } = this.context;
    const { editValues, gatewayActivationMode } = this.state;
    const { values } = this.props;
    const stripe = editValues
      ? editValues.gateways.stripe
      : values.gateways.stripe;
    const mode = gatewayActivationMode || stripe.mode;
    return `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write&client_id=${
      isLiveMode(mode)
        ? STRIPE_CONNECT_LIVE_CLIENT_ID
        : STRIPE_CONNECT_TEST_CLIENT_ID
    }&redirect_uri=${STRIPE_CONNECT_REDIRECT_URL}/admin/api/activate-stripe-connect&state=${`${
      window.location.origin
    }/admin/${testUrlSegment(testEnv)}settings/payments/stripe_activated?mode=${
      isLiveMode(mode) ? 'live' : 'test'
    }`}`;
  };

  getEditActions(id) {
    const disconnectAction = {
      label: 'Disconnect',
      type: 'secondary',
      className: 'left button-cancel',
      onClick: this.onClickDisconnectGateway.bind(this, id),
    };
    switch (id) {
      case 'stripe':
        const stripeGateway = get(this.state.editValues, 'gateways.stripe', {});
        const { use_connect } = stripeGateway;
        const useConnect =
          use_connect ||
          !(stripeGateway.test_secret_key || stripeGateway.live_secret_key);
        const stripeConnected = isStripeConnected(
          stripeGateway,
          stripeGateway.mode,
        );
        return [
          useConnect && !stripeConnected
            ? {
                label: 'Connect Stripe',
                type: 'submit',
                onClick: this.onClickActivateStripe,
              }
            : { label: 'Save', type: 'submit' },
          // stripeConnected && {
          //   label: 'Refresh',
          //   type: 'secondary',
          //   onClick: this.onClickRefreshStripe,
          // },
          { label: 'Cancel', type: 'cancel', onClick: this.onClickEditCard },
          stripeConnected && disconnectAction,
        ];
      case 'paysafecard':
        return [
          { label: 'Save', type: 'submit' },
          {
            label: 'Cancel',
            type: 'cancel',
            onClick: this.onClickEditPaysafecard,
          },
        ];
      case 'klarna':
        return [
          { label: 'Save', type: 'submit' },
          { label: 'Cancel', type: 'cancel', onClick: this.onClickEditKlarna },
        ];
      default:
        return [
          { label: 'Save', type: 'submit' },
          { label: 'Cancel', type: 'cancel', onClick: this.onClickEditCard },
        ];
    }
  }

  renderEditCardModal() {
    const { testEnv } = this.context;

    const { methods: existingMethods, gateways: existingGateways } =
      this.props.values;
    const { methods, gateways } = this.state.editValues;
    const { gateway } = methods.card || {};
    const gatewaySettings = gateways[gateway] || {};
    const useStripeConnect = get(existingGateways, 'stripe.use_connect');
    const klarnaMethod = existingMethods?.klarna;

    return (
      <Form onSubmit={this.onSubmitEditCard} onChange={this.onChangeEditValues}>
        <Modal
          title="Credit card payments"
          actions={this.getEditActions(gateway)}
          width={500}
          cancel={false}
          loading={this.props.loading}
          onClose={this.onClickEditCard}
        >
          <fieldset>
            <Field type="hidden" name="methods" defaultValue={methods} />
            <Field
              type="hidden"
              name="methods.card.mode"
              value={gatewaySettings.mode || methods.mode}
            />
            <Field type="hidden" name="gateways" defaultValue={gateways} />
            <Field
              type="select"
              name="methods.card.gateway"
              label="Payment gateway"
              placeholder="Choose a payment provider"
              options={CARD_GATEWAY_OPTIONS}
              defaultValue={gateway}
              required={true}
            />
            {gateway === 'test' && (
              <p>Use for simple testing of payments and checkout.</p>
            )}
            {gateway === 'authorizenet' &&
              this.renderEditCardFields('authorizenet', {
                login_id: 'Login ID',
                trans_key: 'Transaction key',
              })}
            {gateway === 'braintree' && (
              <Fragment>
                {this.renderEditCardFields('braintree', {
                  merchant_id: 'Merchant ID',
                  merchant_account_id: 'Merchant account ID?',
                  public_key: 'Public key',
                  private_key: 'Private key',
                })}
                <Fieldgroup
                  label="Advanced options"
                  className="settings-payments-advanced-options"
                  defaultActive={isBraintreeSourcesEnabled(existingMethods)}
                >
                  {methods.apple.enabled && (
                    <Field
                      type="hidden"
                      name="methods.apple.gateway"
                      value="braintree"
                    />
                  )}
                  <Field
                    type="checkbox"
                    label={
                      <Fragment>
                        <IconPayment method="apple" /> Enable Apple Pay
                      </Fragment>
                    }
                    name="methods.apple.enabled"
                    defaultChecked={isBraintreeAppleEnabled(
                      existingMethods.apple,
                    )}
                  />
                  {methods.google.enabled && (
                    <Field
                      type="hidden"
                      name="methods.google.gateway"
                      value="braintree"
                    />
                  )}
                  <Field
                    type="checkbox"
                    label={
                      <Fragment>
                        <IconPayment method="google" /> Enable Google Pay
                      </Fragment>
                    }
                    name="methods.google.enabled"
                    defaultChecked={isBraintreeGoogleEnabled(
                      existingMethods.google,
                    )}
                  />
                  {isBraintreeGoogleEnabled(methods.google) && (
                    <FadeIn>
                      <Field
                        type="text"
                        label="Google merchant ID"
                        hint={
                          isLiveMode(gatewaySettings.mode) && (
                            <>
                              To use Google Pay in Live mode, you must{' '}
                              <a
                                href="https://developers.google.com/pay/api/web/guides/test-and-deploy/request-prod-access"
                                target="_blank"
                                rel="noreferrer"
                              >
                                request production access
                              </a>{' '}
                              in your Google account.
                            </>
                          )
                        }
                        name="methods.google.merchant_id"
                        defaultValue={get(
                          existingMethods,
                          'google.merchant_id',
                        )}
                        required={true}
                      />
                    </FadeIn>
                  )}
                </Fieldgroup>
              </Fragment>
            )}
            {gateway === 'stripe' && (
              <Fragment>
                {useStripeConnect === false ||
                (!isStripeConnected(gatewaySettings) &&
                  !!(
                    gatewaySettings.test_secret_key ||
                    gatewaySettings.live_secret_key
                  )) ? (
                  <Fragment>
                    {!gatewaySettings.use_connect ? (
                      this.renderEditCardFields('stripe', {
                        secret_key: 'Secret key',
                        publishable_key: 'Publishable key',
                      })
                    ) : !testEnv ? (
                      <Field
                        type="toggle"
                        name="gateways.stripe.mode"
                        label="Live mode"
                        value="live"
                        nonValue="test"
                        defaultChecked={isLiveMode(gatewaySettings.mode)}
                      />
                    ) : (
                      <Field
                        type="hidden"
                        name="gateways.stripe.mode"
                        value="test"
                      />
                    )}
                    <Field
                      type="toggle"
                      name="gateways.stripe.use_connect"
                      label="Use Stripe Connect"
                      defaultChecked={useStripeConnect}
                      help="Connect to Stripe using OAuth instead of API keys"
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <Field
                      type="hidden"
                      name="gateways.stripe.use_connect"
                      value={true}
                    />
                    {!testEnv ? (
                      <Field
                        type="toggle"
                        name="gateways.stripe.mode"
                        label="Live mode"
                        value="live"
                        nonValue="test"
                        defaultChecked={isLiveMode(gatewaySettings.mode)}
                      />
                    ) : (
                      <Field
                        type="hidden"
                        name="gateways.stripe.mode"
                        value="test"
                      />
                    )}
                  </Fragment>
                )}
                <Fieldgroup
                  label="Advanced options"
                  className="settings-payments-advanced-options"
                  defaultActive={isStripeSourcesEnabled(existingMethods)}
                >
                  <Field
                    type="checkbox"
                    label={
                      <Fragment>
                        <IconPayment method="ideal" /> Enable iDEAL
                      </Fragment>
                    }
                    name="methods.ideal.enabled"
                    defaultChecked={!!get(existingMethods, 'ideal.enabled')}
                  />
                  <Field
                    type="checkbox"
                    label={
                      <Fragment>
                        <IconPayment method="bancontact" /> Enable Bancontact
                      </Fragment>
                    }
                    name="methods.bancontact.enabled"
                    defaultChecked={
                      !!get(existingMethods, 'bancontact.enabled')
                    }
                  />
                  {methods.apple.enabled && (
                    <Field
                      type="hidden"
                      name="methods.apple.gateway"
                      value="stripe"
                    />
                  )}
                  <Field
                    type="checkbox"
                    label={
                      <Fragment>
                        <IconPayment method="apple" /> Enable Apple Pay
                      </Fragment>
                    }
                    name="methods.apple.enabled"
                    defaultChecked={isStripeAppleEnabled(existingMethods.apple)}
                  />
                  {methods.google.enabled && (
                    <Field
                      type="hidden"
                      name="methods.google.gateway"
                      value="stripe"
                    />
                  )}
                  <Field
                    type="checkbox"
                    label={
                      <Fragment>
                        <IconPayment method="google" /> Enable Google Pay
                      </Fragment>
                    }
                    name="methods.google.enabled"
                    defaultChecked={isStripeGoogleEnabled(
                      existingMethods.google,
                    )}
                  />
                  <Field
                    type="checkbox"
                    label={
                      <Fragment>
                        Custom authentication
                        <Help message="Use your own custom authentication instead of an authentication hosted by Swell" />
                      </Fragment>
                    }
                    name="gateways.stripe.custom_authentication_enabled"
                    defaultChecked={
                      !!get(
                        this.props.record,
                        'gateways.stripe.custom_authentication_enabled',
                      )
                    }
                  />
                  {!!get(
                    this.props.values,
                    'gateways.stripe.custom_authentication_enabled',
                  ) && (
                    <FadeIn>
                      <Field
                        type="url"
                        label="Custom authentication URL"
                        name="gateways.stripe.custom_authentication_url"
                        defaultValue={get(
                          this.props.values,
                          'gateways.stripe.custom_authentication_url',
                        )}
                        placeholder="https://mystore.com/authenticate/{payment_id}"
                        hint={`Enter the URL to access to your custom authentication page. Optionally use "{payment_id}" placeholder`}
                        rules="secure_url"
                      />
                    </FadeIn>
                  )}
                </Fieldgroup>
                {isStripeKlarnaEnabled(klarnaMethod) && (
                  <Field
                    type="hidden"
                    name="methods.klarna.mode"
                    value={gatewaySettings.mode}
                  />
                )}
              </Fragment>
            )}
            {gateway === 'worldpay' &&
              this.renderEditCardFields('worldpay', {
                service_key: 'Service key',
                client_key: 'Client key',
              })}
            {gateway === 'saferpay' && (
              <Fragment>
                {/*}<p>
                  Your <b>Customer ID</b>, <b>Terminal ID</b>, <b>Username</b> and{' '}
                  <b>Password</b> are require to capture charges on the Saferpay Payment Page.
                </p>
                {isLiveMode(gatewaySettings.mode) ? (
                  <p>
                    To capture charges in live mode you need to contact{' '}
                    <a
                      target="blank"
                      href="https://www.six-payment-services.com/en/home/contacts.html"
                    >
                      Saferpay Sales
                    </a>{' '}
                    in order to sign a live contract. After activating a live account, you will
                    receive the respective logins, password, Customer ID and Terminal IDs, you need
                    to go live.
                  </p>
                ) : (
                  <p>
                    Saferpay offers an extensive Sandbox, that allows you to simulate transactions,
                    flows and other things. You can get your own test-account{' '}
                    <a target="blank" href="https://test.saferpay.com/BO/Welcome">
                      over here
                    </a>
                    . Everything you need will be sent to you via E-Mail, including things like your
                    test Customer ID, Terminal IDs, API user and password etc.
                  </p>
                )}*/}
                {this.renderEditCardFields('saferpay', {
                  customer_id: 'Customer ID',
                  terminal_id: 'Terminal ID',
                  username: 'Username',
                  password: 'Password',
                })}
                <Fieldgroup
                  label="Advanced options"
                  className="settings-payments-advanced-options"
                  defaultActive={isSaferpaySourcesEnabled(existingMethods)}
                >
                  <Field
                    type="checkbox"
                    label={
                      <Fragment>
                        <IconPayment method="twint" /> Enable TWINT
                      </Fragment>
                    }
                    name="methods.twint.enabled"
                    defaultChecked={!!get(existingMethods, 'twint.enabled')}
                  />
                </Fieldgroup>
              </Fragment>
            )}
            {gateway === 'quickpay' &&
              this.renderEditCardFields('quickpay', {
                api_key: 'User API key',
              })}
          </fieldset>
        </Modal>
      </Form>
    );
  }

  renderEditCardFields(id, fields) {
    const { testEnv } = this.context;

    const values = this.state.editValues;
    const gateway = get(values.gateways, id, {});

    return (
      <>
        {!testEnv ? (
          <>
            <Field
              type="toggle"
              name={`gateways.${id}.mode`}
              label="Live mode"
              value="live"
              nonValue="test"
              defaultChecked={isLiveMode(gateway.mode)}
            />
            {map(fields, (label, key) => (
              <div
                key={key}
                className={`${isLiveMode(gateway.mode) ? '' : 'hidden'}`}
              >
                <Field
                  type="text"
                  label={`Live ${lowerFirst(label.replace('?', ''))}`}
                  name={`gateways.${id}.live_${key}`}
                  defaultValue={gateway[`live_${key}`] || ''}
                  required={label.indexOf('?') >= 0 ? false : true}
                  placeholder={label.indexOf('?') >= 0 ? 'Optional' : undefined}
                  disabled={isLiveMode(gateway.mode) ? undefined : true}
                />
              </div>
            ))}
          </>
        ) : (
          <Field type="hidden" name={`gateways.${id}.mode`} value="test" />
        )}
        {map(fields, (label, key) => (
          <div
            key={key}
            className={`${isLiveMode(gateway.mode) ? 'hidden' : ''}`}
          >
            <Field
              type="text"
              label={`Test ${lowerFirst(label.replace('?', ''))}`}
              name={`gateways.${id}.test_${key}`}
              defaultValue={gateway[`test_${key}`] || ''}
              required={label.indexOf('?') >= 0 ? false : true}
              placeholder={label.indexOf('?') >= 0 ? 'Optional' : undefined}
              disabled={isLiveMode(gateway.mode) ? true : undefined}
            />
          </div>
        ))}
      </>
    );
  }

  renderEditMethodFields(id, fields) {
    const { testEnv } = this.context;

    const method = get(this.state, `editValues.methods[${id}]`) || {};
    const isLive = !testEnv && isLiveMode(method.mode);

    return (
      <>
        {!testEnv ? (
          <>
            <Field
              type="toggle"
              name={`methods.${id}.mode`}
              label="Live mode"
              value="live"
              nonValue="test"
              defaultChecked={isLive}
            />
            {map(fields, (label, key) => (
              <div key={key} className={`${isLive ? '' : 'hidden'}`}>
                <Field
                  type="text"
                  label={`Live ${lowerFirst(label)}`}
                  name={`methods.${id}.live_${key}`}
                  defaultValue={method[`live_${key}`] || ''}
                  required={true}
                  disabled={isLive ? undefined : true}
                />
              </div>
            ))}
          </>
        ) : (
          <Field type="hidden" name={`methods.${id}.mode`} value="test" />
        )}
        {map(fields, (label, key) => (
          <div key={key} className={`${isLive ? 'hidden' : ''}`}>
            <Field
              type="text"
              label={`Test ${lowerFirst(label)}`}
              name={`methods.${id}.test_${key}`}
              defaultValue={method[`test_${key}`] || ''}
              required={true}
              disabled={isLive ? true : undefined}
            />
          </div>
        ))}
      </>
    );
  }

  renderActivateAmazonModal() {
    const { testEnv } = this.context;

    const amazonMethod = this.props.values.methods.amazon;
    const { activated = false } = amazonMethod;

    return (
      <Modal
        title={activated ? 'Amazon Pay activated' : 'Activate Amazon Pay'}
        actions={[
          !activated && {
            component: (
              <a
                href=""
                onClick={this.onClickPostActivateAmazon}
                className="button button-default button-sm"
              >
                Activate
              </a>
            ),
          },
          {
            label: activated ? 'Ok' : 'Cancel',
            type: activated ? 'default' : 'cancel',
            onClick: this.onClickActivateAmazon,
          },
        ]}
        width={500}
        cancel={false}
        onClose={this.onClickActivateAmazon}
      >
        <fieldset>
          {activated ? (
            <Fragment>
              <p>Your Amazon Pay seller account has been connected.</p>
              <p>
                {!testEnv && isLiveMode(amazonMethod.mode) ? (
                  <span className="positive">Live mode</span>
                ) : (
                  <span className="muted">Sandbox mode</span>
                )}
                <br />
                Merchant ID:{' '}
                <span className="monospaced">{amazonMethod.merchant_id}</span>
              </p>
              <a href="" onClick={this.onClickEditAmazon} className="as-link">
                Edit settings
              </a>
            </Fragment>
          ) : (
            <Fragment>
              <p>
                By activating Amazon Pay, you&apos;ll sign into or create an
                Amazon seller account and grant us permission to access your
                profile.
              </p>
              <ol>
                <li>
                  Click <b>Activate</b> to begin the process.
                </li>
                <li>
                  Follow the steps to create or sign into your Amazon seller
                  account.
                </li>
                <li>
                  You&apos;ll be redirected back to this page with Amazon Pay
                  activated.
                </li>
              </ol>
            </Fragment>
          )}
        </fieldset>
      </Modal>
    );
  }

  renderActivateStripeModal() {
    const { testEnv } = this.context;

    const { editValues, gatewayActivationMode, gatewayActivationError } =
      this.state;
    const stripeGateway =
      get(editValues, 'gateways.stripe') ||
      this.props.values.gateways.stripe ||
      {};
    const stripeMode = gatewayActivationMode || stripeGateway.mode;
    const stripeConnected =
      !gatewayActivationError &&
      isStripeConnected({ ...stripeGateway, mode: stripeMode }, true);

    return (
      <Modal
        title={
          stripeConnected
            ? `Stripe connected`
            : gatewayActivationError
            ? 'Unable to connect Stripe'
            : 'Connect Stripe'
        }
        actions={[
          !stripeConnected && {
            component: (
              <a
                href=""
                onClick={this.onClickConnectStripe}
                className="button button-default button-sm"
              >
                {gatewayActivationError ? 'Retry' : 'Connect'}
              </a>
            ),
          },
          {
            label: stripeConnected ? 'Ok' : 'Cancel',
            type: stripeConnected ? 'default' : 'cancel',
            onClick: this.onClickActivateStripe,
          },
        ]}
        width={500}
        cancel={false}
        loading={
          this.props.routeParams.command === 'stripe_activated' &&
          !stripeConnected &&
          !gatewayActivationError
        }
        onClose={this.onClickActivateStripe}
      >
        <fieldset>
          {stripeConnected ? (
            <Fragment>
              <p>
                Your Stripe account has been connected{' '}
                {stripeGateway.mode === 'test'
                  ? `(${stripeGateway.mode} mode)`
                  : ''}
                .
              </p>
              <a href="" onClick={this.onClickEditCard} className="as-link">
                Edit settings
              </a>
            </Fragment>
          ) : gatewayActivationError ? (
            <p>
              There was an error connecting to Stripe. Click below to retry or
              cancel to try later.
            </p>
          ) : (
            <Fragment>
              <p>
                Sign into or create a Stripe account and grant Swell permission
                to access your profile.
              </p>
              <ol>
                <li>
                  Click <b>Connect</b> to begin the process.
                </li>
                <li>Follow the steps to create or sign into Stripe.</li>
                <li>You&apos;ll be redirected back to this page.</li>
              </ol>
              {!testEnv && (
                <Field
                  type="toggle"
                  label="Live mode"
                  value="live"
                  nonValue="test"
                  defaultChecked={isLiveMode(
                    get(editValues, 'gateways.stripe.mode'),
                  )}
                  onChange={(_event, value) => {
                    this.setState({ gatewayActivationMode: value });
                  }}
                />
              )}
            </Fragment>
          )}
        </fieldset>
      </Modal>
    );
  }

  /**
   * Checks if the PayPal Commerce feature is enabled.
   *
   * @returns {boolean}
   */
  isPayPalCommerceFeatureEnabled() {
    return this.props.client?.super_features?.has('ppcp');
  }

  /**
   * Checks if PayPal Express should be displayed.
   *
   * Note: PayPal Express should be displayed when:
   *         * The PayPal Commerce feature is disabled;
   *         * PayPal Express is activated,
   *           but PayPal Commerce is not activated.
   *
   * @returns {boolean}
   */
  shouldRenderPayPalExpress() {
    const { values } = this.props;
    const { express_activated, ppcp } = get(values.methods, 'paypal', {});

    return (
      !this.isPayPalCommerceFeatureEnabled() || (!ppcp && express_activated)
    );
  }

  /**
   * Checks if PayPal Commerce should be displayed.
   *
   * Note: PayPal Commerce should be displayed when the PayPal Commerce feature is enabled.
   *
   * @returns {boolean}
   */
  shouldRenderPayPalCommerce() {
    return this.isPayPalCommerceFeatureEnabled();
  }

  renderPayPalExpressEditModal() {
    const { editValues } = this.state;
    const { testEnv } = this.context;
    const { loading, values } = this.props;
    const paypalMethod = values?.methods?.paypal || {};

    return (
      <PayPalExpressEditModal
        loading={loading}
        paypalMethod={paypalMethod}
        editValues={editValues}
        onChange={this.onChangeEditValues}
        onSubmit={this.onSubmitEditPaypal}
        onClose={this.onClickEditPaypalExpress}
        testEnv={testEnv}
      />
    );
  }

  renderPayPalCommerceEditModal() {
    const { editValues } = this.state;
    const { testEnv } = this.context;
    const { loading, values } = this.props;
    const cardMethod = values?.methods?.card || {};
    const paypalMethod = values?.methods?.paypal || {};

    return (
      <PayPalCommerceEditModal
        loading={loading}
        cardMethod={cardMethod}
        paypalMethod={paypalMethod}
        editValues={editValues}
        onChange={this.onChangeEditValues}
        onSubmit={this.onSubmitEditPaypal}
        onClose={this.onClickEditPaypalCommerce}
        onClickActivate={this.onClickActivatePaypalCommerce}
        onClickReset={this.onClickResetPaypalCommerce}
        testEnv={testEnv}
      />
    );
  }

  renderPayPalCommerceOnboadringModal() {
    const { values } = this.props;
    const paypalMethod = values?.methods?.paypal || {};

    return (
      <PayPalCommerceOnboardingModal
        paypalMethod={paypalMethod}
        onClickEdit={this.onClickEditPaypalCommerceOnboarding}
        onClose={this.onClickClosePayPalCommerceOnboarding}
      />
    );
  }

  renderPayPalCommerceConfirmationModal() {
    return new Promise((resolve) =>
      this.context.openModal('Confirm', {
        title: 'Enable PayPal Commerce Platform',
        message: (
          <p>
            Once PayPal Commerce Platform has been enabled, you will no longer
            have access to the legacy PayPal Express payment gateway.
          </p>
        ),
        width: 700,
        action: 'Continue',
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
      }),
    );
  }

  renderPaysafecardModal() {
    const { testEnv } = this.context;

    const {
      loading,
      values: { methods },
    } = this.props;
    const paysafecard = methods.paysafecard || {};
    const values = get(this.state, 'editValues.methods.paysafecard', {});
    let isLive = !testEnv && isLiveMode(values.mode);

    const mode = isLive ? 'live' : 'test';
    const keyName = `${mode}_api_key`;
    const keyLabel = `${upperFirst(mode)} API key`;

    return (
      <Form
        onSubmit={this.onSubmitEditPaysafecard}
        onChange={this.onChangeEditValues}
      >
        <Modal
          title="Paysafecard method"
          actions={this.getEditActions(paysafecard.id)}
          width={500}
          cancel={false}
          loading={loading}
          onClose={this.onClickEditPaysafecard}
        >
          <fieldset>
            <Field
              type="hidden"
              name="methods.paysafecard.activated"
              value={true}
            />
            <Field
              type="hidden"
              name="methods.paysafecard.enabled"
              value={paysafecard.activated ? paysafecard.enabled : true}
            />
            {!testEnv ? (
              <Field
                type="radio"
                buttons={true}
                name="methods.paysafecard.mode"
                options={[
                  { value: 'live', label: 'Live' },
                  { value: 'test', label: 'Sandbox' },
                ]}
                defaultValue={isLiveMode(paysafecard.mode) ? 'live' : 'test'}
              />
            ) : (
              <Field
                type="hidden"
                name="methods.paysafecard.mode"
                value="test"
              />
            )}
            <Field
              key={mode}
              type="text"
              required={true}
              label={keyLabel}
              name={`methods.paysafecard.${keyName}`}
              defaultValue={paysafecard[keyName]}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }

  renderKlarnaModal() {
    const { testEnv } = this.context;
    const {
      loading,
      values: { methods },
    } = this.props;
    const values = get(this.state, 'editValues.methods.klarna', {});

    const klarna = methods.klarna || {};
    const card = methods.card || {};
    const canUseStripeGateway = card.gateway === 'stripe';
    const useStripeGateway =
      canUseStripeGateway && isStripeKlarnaEnabled(values);

    const isLive =
      !testEnv &&
      (useStripeGateway ? isLiveMode(card.mode) : isLiveMode(values.mode));
    const mode = isLive ? 'live' : 'test';
    const usernameName = `${mode}_username`;
    const passwordName = `${mode}_password`;
    const usernameLabel = `${isLive ? 'Live' : 'Sandbox'} username`;
    const passwordLabel = `${isLive ? 'Live' : 'Sandbox'} password`;

    return (
      <Form
        onSubmit={this.onSubmitEditKlarna}
        onChange={this.onChangeEditValues}
      >
        <Modal
          title="Klarna settings"
          actions={this.getEditActions(klarna.id)}
          width={500}
          cancel={false}
          loading={loading}
          onClose={this.onClickEditKlarna}
        >
          <fieldset>
            <Field type="hidden" name="methods.klarna.activated" value={true} />
            <Field
              type="hidden"
              name="methods.klarna.enabled"
              value={klarna.activated ? klarna.enabled : true}
            />
            {canUseStripeGateway && (
              <Field
                type="toggle"
                label="Use Stripe to process Klarna payments"
                name="methods.klarna.gateway"
                value="stripe"
                nonValue="klarna"
                defaultChecked={useStripeGateway}
              />
            )}
            {useStripeGateway ? (
              <FadeIn>
                <Field
                  type="hidden"
                  name="methods.klarna.gateway"
                  value="stripe"
                />
                <Field type="hidden" name="methods.klarna.mode" value={mode} />
                <p>
                  Your Stripe account will be used to process Klarna payments as
                  long as it&apos;s enabled. Contact{' '}
                  <a href="mailto:support@swell.is">support</a> for details.
                </p>
              </FadeIn>
            ) : (
              <FadeIn>
                <Field
                  type="hidden"
                  name="methods.klarna.gateway"
                  value="klarna"
                />
                {!testEnv ? (
                  <Field
                    type="radio"
                    buttons={true}
                    name="methods.klarna.mode"
                    options={[
                      { value: 'live', label: 'Live' },
                      { value: 'test', label: 'Sandbox' },
                    ]}
                    defaultValue={isLiveMode(klarna.mode) ? 'live' : 'test'}
                  />
                ) : (
                  <Field
                    type="hidden"
                    name="methods.klarna.mode"
                    value="test"
                  />
                )}
                <Field
                  type="select"
                  label="Region"
                  name="methods.klarna.region"
                  placeholder="Choose region"
                  required={true}
                  options={map(KLARNA_REGIONS, (label, value) => ({
                    value,
                    label,
                  }))}
                  defaultValue={klarna.region}
                />
                <Field
                  key={usernameName}
                  type="text"
                  required={true}
                  label={usernameLabel}
                  name={`methods.klarna.${usernameName}`}
                  defaultValue={klarna[usernameName]}
                  placeholder={usernameLabel}
                />
                <Field
                  key={passwordName}
                  type="text"
                  required={true}
                  label={passwordLabel}
                  name={`methods.klarna.${passwordName}`}
                  defaultValue={klarna[passwordName]}
                  placeholder={passwordLabel}
                />
                <Fieldgroup
                  label="Payment options"
                  className="settings-payments-advanced-options"
                  defaultActive={!isEmpty(klarna.pay_options)}
                >
                  {map(KLARNA_PAY_OPTIONS, (label, id) => (
                    <Field
                      key={id}
                      type="checkbox"
                      label={label}
                      name={`methods.klarna.pay_options.${id}`}
                      defaultChecked={includes(klarna.pay_options, id)}
                    />
                  ))}
                </Fieldgroup>
              </FadeIn>
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }

  uploadAmazonPrivateKey = async (files) => {
    const file = files[0];
    if (!file) {
      return;
    }

    try {
      const result = await getDataFromFile(file);
      const rawData = Buffer.from(result.data.split(',')[1], 'base64').toString(
        'utf-8',
      );
      this.setState({ amazonPrivateKey: rawData });
      this.context.notifySuccess(`${file.name} uploaded successfully`);
    } catch (error) {
      this.context.notifyError(error.message);
    }
  };

  renderEditAmazonModal() {
    const { testEnv } = this.context;

    const amazonMethod = get(this.props, 'values.methods.amazon', {});
    const { activated = false } = amazonMethod;
    const values = get(this.state, 'editValues.methods.amazon', {});
    const isLive = !testEnv && isLiveMode(values.mode);

    // Resolve Amazon Pay private key in order of precedence from state:
    // 1) from the uploaded file (this.state.amazonPrivateKey)
    // 2) from the text field by mode (amazonMethod[...])
    const privateKey =
      this.state.amazonPrivateKey ||
      amazonMethod[modePrefix(isLive, 'private_key')];

    return (
      <Form
        onSubmit={this.onSubmitEditAmazon}
        onChange={this.onChangeEditValues}
      >
        <Modal
          title={activated ? 'Edit Amazon Pay settings' : 'Activate Amazon Pay'}
          actions={[
            { label: activated ? 'Save' : 'Activate', type: 'submit' },
            {
              label: 'Cancel',
              type: 'cancel',
              onClick: this.onClickEditAmazon,
            },
          ]}
          width={500}
          cancel={false}
          onClose={this.onClickEditAmazon}
        >
          <fieldset>
            <Field type="hidden" name="methods.amazon.activated" value={true} />
            <Field type="hidden" name="methods.amazon.v2" value={true} />
            <Field
              type="hidden"
              name="methods.amazon.enabled"
              value={activated ? amazonMethod.enabled : true}
            />
            <Fragment>
              <p>
                Navigate to{' '}
                <a
                  target="_blank"
                  href="https://sellercentral.amazon.com/gp/pyop/seller/integrationcentral/"
                  rel="noreferrer"
                >
                  Amazon Pay Integration Central
                </a>{' '}
                to generate API credentials.
              </p>
              {!testEnv ? (
                <Field
                  type="toggle"
                  name="methods.amazon.mode"
                  label="Live mode"
                  value="live"
                  nonValue="test"
                  disabled={!!testEnv}
                  defaultChecked={isLive}
                />
              ) : (
                <Field type="hidden" name="methods.amazon.mode" value="test" />
              )}
              <Field
                type="hidden"
                key={modePrefix(isLive, 'private_key')}
                name={`methods.amazon.${modePrefix(isLive, 'private_key')}`}
                value={privateKey}
              />
              <Label>Private key {!isLive ? ' (sandbox mode)' : ''}</Label>
              <FileReader
                className="form-field"
                accept=".pem"
                onFiles={this.uploadAmazonPrivateKey}
                multiple={false}
              >
                <a href="" className="button button-sm button-default">
                  <span>{`${
                    privateKey ? 'Replace' : 'Choose'
                  } private key file`}</span>
                </a>
              </FileReader>
              {map(
                {
                  merchant_id: 'Merchant ID',
                  client_id: 'Store ID',
                  public_key_id: 'Public Key ID',
                },
                (label, key) => (
                  <div key={modePrefix(isLive, key)}>
                    <Field
                      type="text"
                      label={`${label}${!isLive ? ' (sandbox mode)' : ''}`}
                      name={`methods.amazon.${modePrefix(isLive, key)}`}
                      required={true}
                      defaultValue={
                        amazonMethod[`${modePrefix(isLive, key)}`] || ''
                      }
                    />
                  </div>
                ),
              )}
            </Fragment>
          </fieldset>
        </Modal>
      </Form>
    );
  }

  renderEditAffirmModal() {
    const { testEnv } = this.context;

    const affirmMethod = this.props.values.methods.affirm;
    const values = this.state.editValues;
    const isLive = !testEnv && isLiveMode(values.methods.affirm.mode);

    return (
      <Form
        onSubmit={this.onSubmitEditAffirm}
        onChange={this.onChangeEditValues}
      >
        <Modal
          title="Edit Affirm settings"
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'cancel',
              onClick: this.onClickEditAffirm,
            },
          ]}
          width={500}
          cancel={false}
          onClose={this.onClickEditAffirm}
        >
          <fieldset>
            <Field type="hidden" name="methods" defaultValue={values.methods} />
            <Field type="hidden" name="methods.affirm.activated" value={true} />
            <Field
              type="hidden"
              name="methods.affirm.enabled"
              value={affirmMethod.activated ? affirmMethod.enabled : true}
            />
            {!testEnv ? (
              <div className="row">
                <Field
                  type="radio"
                  name="methods.affirm.mode"
                  buttons={true}
                  options={[
                    {
                      value: 'live',
                      label: 'Live',
                    },
                    {
                      value: 'test',
                      label: 'Sandbox',
                    },
                  ]}
                  defaultValue={affirmMethod.mode || 'live'}
                  required={true}
                />
              </div>
            ) : (
              <Field type="hidden" name="methods.affirm.mode" value="test" />
            )}
            {['live', 'test'].map(
              (mode) =>
                (mode === 'live' ? isLive : !isLive) && (
                  <Fragment key={mode}>
                    <div className="row">
                      <Field
                        type="text"
                        label={`${
                          mode === 'live' ? 'Live' : 'Test'
                        } public key`}
                        name={`methods.affirm.${mode}_public_key`}
                        defaultValue={affirmMethod[`${mode}_public_key`]}
                        required={true}
                        help="Find your public key in the Affirm dashboard under API Settings"
                        className="span4"
                      />
                    </div>
                    <div className="row">
                      <Field
                        type="text"
                        label={`${
                          mode === 'live' ? 'Live' : 'Test'
                        } private key`}
                        name={`methods.affirm.${mode}_private_key`}
                        defaultValue={affirmMethod[`${mode}_private_key`]}
                        required={true}
                        help="Find your private key in the Affirm dashboard under API Settings"
                        className="span4"
                      />
                    </div>
                  </Fragment>
                ),
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }

  renderEditResolveModal() {
    const { testEnv } = this.context;

    const resolveMethod = this.props.values.methods.resolve;
    const values = this.state.editValues;
    const isLive = !testEnv && isLiveMode(values.methods.resolve.mode);

    return (
      <Form
        onSubmit={this.onSubmitEditResolve}
        onChange={this.onChangeEditValues}
      >
        <Modal
          title="Edit Resolve settings"
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label: 'Cancel',
              type: 'cancel',
              onClick: this.onClickEditResolve,
            },
          ]}
          width={500}
          cancel={false}
          onClose={this.onClickEditResolve}
        >
          <fieldset>
            <Field type="hidden" name="methods" defaultValue={values.methods} />
            <Field
              type="hidden"
              name="methods.resolve.activated"
              value={true}
            />
            <Field
              type="hidden"
              name="methods.resolve.enabled"
              value={resolveMethod.activated ? resolveMethod.enabled : true}
            />
            {!testEnv ? (
              <div className="row">
                <Field
                  type="radio"
                  name="methods.resolve.mode"
                  buttons={true}
                  options={[
                    {
                      value: 'live',
                      label: 'Live',
                    },
                    {
                      value: 'test',
                      label: 'Sandbox',
                    },
                  ]}
                  defaultValue={resolveMethod.mode || 'live'}
                  required={true}
                />
              </div>
            ) : (
              <Field type="hidden" name="methods.resolve.mode" value="test" />
            )}
            <Field
              type="text"
              label="Merchant ID"
              name={`methods.resolve.merchant_id`}
              defaultValue={resolveMethod.merchant_id}
              required={true}
              help="Find your merchant ID in the Resolve dashboard under integration Settings"
            />
            {['live', 'test'].map(
              (mode) =>
                (mode === 'live' ? isLive : !isLive) && (
                  <Fragment key={mode}>
                    <div className="row">
                      <Field
                        type="text"
                        label={`${
                          mode === 'live' ? 'Live' : 'Test'
                        } secret key`}
                        name={`methods.resolve.${mode}_secret_key`}
                        defaultValue={resolveMethod[`${mode}_secret_key`]}
                        required={true}
                        help="Find your secret key in the Resolve dashboard under integration Settings"
                        className="span4"
                      />
                    </div>
                  </Fragment>
                ),
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }

  render() {
    const { testEnv } = this.context;
    const { values, edited, onSubmitForm, onChangeForm } = this.props;

    const cardMethod = get(values.methods, 'card', {});
    const cardGateway = get(values.gateways, cardMethod.gateway, {});
    const paypalMethod = get(values.methods, 'paypal', {});
    const paysafecardMethod = get(values.methods, 'paysafecard', {});
    const amazonMethod = get(values.methods, 'amazon', {});
    const affirmMethod = get(values.methods, 'affirm', {});
    const resolveMethod = get(values.methods, 'resolve', {});
    const klarnaMethod = get(values.methods, 'klarna', {});
    // const affirmIntegration = get(values.integrations, 'services.affirm', {});
    // const resolveIntegration = get(values.integrations, 'services.resolve', {});
    const useStripeConnect = get(values.gateways, 'stripe.use_connect');

    const isStripeKlarnaActivated =
      cardMethod.gateway === 'stripe' && klarnaMethod.gateway === 'stripe';
    const isKlarnaActivated = klarnaMethod.activated || isStripeKlarnaActivated;
    const isKlarnaLiveMode = isStripeKlarnaActivated
      ? isLiveMode(cardGateway.mode)
      : isLiveMode(klarnaMethod.mode);

    const cardSecretKey = isLiveMode(cardGateway.mode)
      ? cardGateway.live_secret_key
      : cardGateway.test_secret_key;

    const isStripeActive =
      (useStripeConnect !== false && isStripeConnected(cardGateway)) ||
      cardSecretKey;

    return (
      <div className="settings settings-payments">
        <Form ref="form" onSubmit={onSubmitForm} onChange={onChangeForm}>
          <View
            detail={true}
            uri="/settings"
            sectionTitle="Settings"
            headerTitle="Payments"
            headerActions={[
              {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
          >
            <fieldset className="full last-child">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title="Credit cards"
                  subtitle="Accept credit card payments by configuring a payment gateway"
                />
              </div>
              <Field
                type="hidden"
                name="methods"
                defaultValue={values.methods}
              />
              <Field
                type="hidden"
                name="gateways"
                defaultValue={values.gateways}
              />
              <div
                className={`settings-payments-card ${values.methods.card?.gateway} box box-grid`}
              >
                <div className="box-column">
                  {values.methods.card?.gateway === 'test' && (
                    <div className="settings-3rdparty-desc">
                      <h4 className="box-title">{cardGateway.name}</h4>
                      <div className="box-subtitle">
                        Use card number 4242 4242 4242 4242 for testing{' '}
                        <Help message="Use test card number with any security code and future expiration date" />
                      </div>
                    </div>
                  )}
                  {values.methods.card?.gateway === 'authorizenet' && (
                    <div className="settings-3rdparty-desc authorizenet">
                      <div className="settings-payments-card-logo box-logo" />
                      <div>
                        Login ID:{' '}
                        {isLiveMode(cardGateway.mode)
                          ? cardGateway.live_login_id
                          : cardGateway.test_login_id}
                      </div>
                      <div>
                        Transaction key:{' '}
                        {isLiveMode(cardGateway.mode)
                          ? cardGateway.live_trans_key
                          : cardGateway.test_trans_key}
                      </div>
                    </div>
                  )}
                  {values.methods.card?.gateway === 'braintree' && (
                    <div className="settings-3rdparty-desc braintree">
                      <div className="settings-payments-card-logo box-logo" />
                      <div>
                        Merchant ID:{' '}
                        <span className="monospaced">
                          {isLiveMode(cardGateway.mode)
                            ? cardGateway.live_merchant_id
                            : cardGateway.test_merchant_id}
                        </span>
                      </div>
                      {(isLiveMode(cardGateway.mode)
                        ? cardGateway.live_merchant_account_id
                        : cardGateway.test_merchant_account_id) && (
                        <div>
                          Merchant account ID:{' '}
                          <span className="monospaced">
                            {isLiveMode(cardGateway.mode)
                              ? cardGateway.live_merchant_account_id
                              : cardGateway.test_merchant_account_id}
                          </span>
                        </div>
                      )}
                      <div>
                        Public key:{' '}
                        <span className="monospaced">
                          {isLiveMode(cardGateway.mode)
                            ? cardGateway.live_public_key
                            : cardGateway.test_public_key}
                        </span>
                      </div>
                      <div>
                        Private key:{' '}
                        <Secret
                          text={
                            isLiveMode(cardGateway.mode)
                              ? cardGateway.live_private_key
                              : cardGateway.test_private_key
                          }
                        />
                      </div>
                      {isBraintreeSourcesEnabled(values.methods) && (
                        <div>
                          Other sources:{' '}
                          {isBraintreeAppleEnabled(values.methods.apple) && (
                            <Fragment>
                              <IconPayment method="apple" />
                              Apple Pay &nbsp;
                            </Fragment>
                          )}
                          {isBraintreeGoogleEnabled(values.methods.google) && (
                            <Fragment>
                              <IconPayment method="google" />
                              Google Pay &nbsp;
                            </Fragment>
                          )}
                          {isBraintreePayPalEnabled(values.methods.paypal) && (
                            <Fragment>
                              <IconPayment method="paypal" />
                              PayPal
                            </Fragment>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {values.methods.card?.gateway === 'stripe' && (
                    <div className="settings-3rdparty-desc stripe">
                      <div className="settings-payments-card-logo box-logo" />
                      {useStripeConnect !== false &&
                      isStripeConnected(cardGateway) ? (
                        <div>Status: Connected</div>
                      ) : cardSecretKey ? (
                        <Fragment>
                          <div className="nowrap">
                            Secret key: {truncatedText(cardSecretKey, 60)}
                          </div>
                          {(cardGateway.live_publishable_key ||
                            cardGateway.test_publishable_key) && (
                            <div className="nowrap">
                              Publishable key:{' '}
                              <span className="monospaced">
                                {isLiveMode(cardGateway.mode)
                                  ? truncatedText(
                                      cardGateway.live_publishable_key,
                                      60,
                                    )
                                  : truncatedText(
                                      cardGateway.test_publishable_key,
                                      60,
                                    )}
                              </span>
                            </div>
                          )}
                        </Fragment>
                      ) : (
                        <div className="negative">Status: Not connected</div>
                      )}
                      {isStripeSourcesEnabled(values.methods) && (
                        <div>
                          Other sources:{' '}
                          {isStripeKlarnaEnabled(klarnaMethod) && (
                            <Fragment>
                              <IconPayment method="klarna" />
                              Klarna &nbsp;
                            </Fragment>
                          )}
                          {get(values.methods.ideal, 'enabled') && (
                            <Fragment>
                              <IconPayment method="ideal" />
                              iDEAL &nbsp;
                            </Fragment>
                          )}
                          {get(values.methods.bancontact, 'enabled') && (
                            <Fragment>
                              <IconPayment method="bancontact" />
                              Bancontact &nbsp;
                            </Fragment>
                          )}
                          {isStripeAppleEnabled(values.methods.apple) && (
                            <Fragment>
                              <IconPayment method="apple" />
                              Apple Pay &nbsp;
                            </Fragment>
                          )}
                          {isStripeGoogleEnabled(values.methods.google) && (
                            <Fragment>
                              <IconPayment method="google" />
                              Google Pay
                            </Fragment>
                          )}
                        </div>
                      )}
                      {get(values, 'store.custom_authentication_enabled') && (
                        <div>
                          Custom authentication&ensp;
                          <span className="positive">
                            <Icon fa="check" />
                          </span>
                          <Help
                            message={cardGateway.custom_authentication_url}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {values.methods.card?.gateway === 'worldpay' && (
                    <div className="settings-3rdparty-desc worldpay">
                      <div className="settings-payments-card-logo box-logo" />
                      <div>
                        Service key:{' '}
                        <span className="monospaced">
                          {isLiveMode(cardGateway.mode)
                            ? cardGateway.live_service_key
                            : cardGateway.test_service_key}
                        </span>
                      </div>
                      <div>
                        Client key:{' '}
                        <Secret
                          text={
                            isLiveMode(cardGateway.mode)
                              ? cardGateway.live_client_key
                              : cardGateway.test_client_key
                          }
                        />
                      </div>
                    </div>
                  )}
                  {values.methods.card?.gateway === 'saferpay' && (
                    <div className="settings-3rdparty-desc saferpay">
                      <div className="settings-payments-card-logo box-logo" />
                      <div>
                        Customer ID:{' '}
                        <span className="monospaced">
                          {isLiveMode(cardGateway.mode)
                            ? cardGateway.live_customer_id
                            : cardGateway.test_customer_id}
                        </span>
                      </div>
                      <div>
                        Terminal ID:{' '}
                        <span className="monospaced">
                          {isLiveMode(cardGateway.mode)
                            ? cardGateway.live_terminal_id
                            : cardGateway.test_terminal_id}
                        </span>
                      </div>
                      <div>
                        Username:{' '}
                        <span className="monospaced">
                          {isLiveMode(cardGateway.mode)
                            ? cardGateway.live_username
                            : cardGateway.test_username}
                        </span>
                      </div>
                      <div>
                        Password:{' '}
                        <Secret
                          text={
                            isLiveMode(cardGateway.mode)
                              ? cardGateway.live_password
                              : cardGateway.test_password
                          }
                        />
                      </div>
                      {isSaferpaySourcesEnabled(values.methods) && (
                        <div>
                          Other sources:{' '}
                          {get(values.methods.twint, 'enabled') && (
                            <Fragment>
                              <IconPayment method="twint" />
                              TWINT &nbsp;
                            </Fragment>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {values.methods.card?.gateway === 'quickpay' && (
                    <div className="settings-3rdparty-desc quickpay">
                      <div className="settings-payments-card-logo box-logo" />
                      <div>
                        User API key:{' '}
                        <Secret
                          text={
                            isLiveMode(cardGateway.mode)
                              ? cardGateway.live_api_key
                              : cardGateway.test_api_key
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="stripe-test-actions">
                    {values.methods.card?.gateway === 'test' ? (
                      <Fragment>
                        <a
                          href=""
                          onClick={this.onClickActivateStripe}
                          data-mode="test"
                          className="button button-default button-sm"
                        >
                          Connect Stripe
                        </a>
                        <a href="" onClick={this.onClickEditCard}>
                          Use a different gateway
                        </a>
                      </Fragment>
                    ) : (
                      <a
                        href=""
                        onClick={this.onClickEditCard}
                        className="as-link"
                      >
                        Edit settings
                      </a>
                    )}
                  </div>
                </div>
                <div className="box-action box-column">
                  <div className="box-row">
                    {values.methods.card?.gateway === 'stripe' ? (
                      isStripeActive ? (
                        (testEnv || !isLiveMode(cardGateway.mode)) && (
                          <Status type="warning">Test mode</Status>
                        )
                      ) : (
                        <Status>Not configured</Status>
                      )
                    ) : (
                      (testEnv || !isLiveMode(cardGateway.mode)) && (
                        <Status type="warning">Test mode</Status>
                      )
                    )}
                    <Field
                      type="toggle"
                      name="methods.card.enabled"
                      defaultChecked={!!values.methods.card?.enabled}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="full last-child">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title="Alternative methods"
                  subtitle="Provide customers with more ways to pay"
                />
              </div>
              <div className="settings-integrations-list">
                {this.shouldRenderPayPalCommerce() && (
                  <PayPalCommerce
                    paypalMethod={paypalMethod}
                    onEnable={this.onEnablePayPalCommerce}
                    onClickEdit={this.onClickEditPaypalCommerce}
                  />
                )}
                {this.shouldRenderPayPalExpress() && (
                  <PayPalExpress
                    paypalMethod={paypalMethod}
                    onClickEdit={this.onClickEditPaypalExpress}
                  />
                )}
                <div
                  data-testid="rtl-amazonBox"
                  className="settings-integrations-box box"
                >
                  <div className="box-grid">
                    <div className="box-column">
                      <div className="box-title payment-method">
                        <PaymentMethodLogo id="amazon" />
                        Amazon Pay
                      </div>
                    </div>
                    <div className="box-column">
                      {amazonMethod.activated && (
                        <Field
                          type="toggle"
                          name="methods.amazon.enabled"
                          defaultChecked={Boolean(amazonMethod.enabled)}
                        />
                      )}
                    </div>
                  </div>

                  <div className="box-subtitle">
                    Enable customers to pay with Amazon Pay button at checkout
                  </div>

                  <div className="box-actions">
                    {amazonMethod.activated ? (
                      <a
                        href=""
                        onClick={this.onClickEditAmazon}
                        className="button button-sm button-secondary"
                      >
                        Settings
                      </a>
                    ) : (
                      <a
                        href=""
                        onClick={this.onClickActivateAmazon}
                        className="button button-sm button-default"
                      >
                        Enable
                      </a>
                    )}
                    {amazonMethod.activated &&
                      amazonMethod.enabled &&
                      (testEnv || !isLiveMode(amazonMethod.mode)) && (
                        <Status type="warning">Test mode</Status>
                      )}
                  </div>
                </div>
                <div
                  data-testid="rtl-klarnaBox"
                  className="settings-integrations-box box"
                >
                  <div className="box-grid">
                    <div className="box-column">
                      <div className="box-title payment-method">
                        <PaymentMethodLogo id="klarna" />
                        Klarna
                      </div>
                    </div>
                    <div className="box-column">
                      {isKlarnaActivated && (
                        <Field
                          type="toggle"
                          name="methods.klarna.enabled"
                          defaultChecked={!!klarnaMethod.enabled}
                        />
                      )}
                    </div>
                  </div>

                  <div className="box-subtitle">
                    Enable customers to pay with Klarna at checkout
                  </div>
                  <div className="box-actions">
                    {isKlarnaActivated ? (
                      <a
                        href=""
                        onClick={this.onClickEditKlarna}
                        className="button button-sm button-secondary"
                      >
                        Settings
                      </a>
                    ) : (
                      <a
                        href=""
                        onClick={this.onClickActivateKlarna}
                        className="button button-sm button-default"
                      >
                        Enable
                      </a>
                    )}
                    {isKlarnaActivated &&
                      klarnaMethod.enabled &&
                      (testEnv || !isKlarnaLiveMode) && (
                        <Status type="warning">Test mode</Status>
                      )}
                  </div>
                </div>
                <div className="settings-integrations-box box">
                  <div className="box-grid">
                    <div className="box-column">
                      <div className="box-title payment-method">
                        <PaymentMethodLogo id="affirm" />
                        Affirm
                        <Help message="Note: Affirm is currently available for integration with custom checkouts only" />
                      </div>
                    </div>
                    <div className="box-column">
                      {affirmMethod.activated && (
                        <Field
                          type="toggle"
                          name="methods.affirm.enabled"
                          defaultChecked={!!affirmMethod.enabled}
                        />
                      )}
                    </div>
                  </div>

                  <div className="box-subtitle">
                    Enable customers to pay with Affirm at checkout
                  </div>
                  <div className="box-actions">
                    {affirmMethod.activated ? (
                      <Fragment>
                        <a
                          href=""
                          onClick={this.onClickEditAffirm}
                          className="button button-sm button-secondary"
                        >
                          Settings
                        </a>
                      </Fragment>
                    ) : (
                      <a
                        href=""
                        onClick={this.onClickEditAffirm}
                        className="button button-sm button-default"
                      >
                        Enable
                      </a>
                    )}
                  </div>
                </div>
                <div className="settings-integrations-box box">
                  <div className="box-grid">
                    <div className="box-column">
                      <div className="box-title payment-method">
                        <PaymentMethodLogo id="resolve" />
                        Resolve
                        <Help message="Note: Resolve is currently available for integration with custom checkouts only" />
                      </div>
                    </div>
                    <div className="box-column">
                      {resolveMethod.activated && (
                        <Field
                          type="toggle"
                          name="methods.resolve.enabled"
                          defaultChecked={!!resolveMethod.enabled}
                        />
                      )}
                    </div>
                  </div>
                  <div className="box-subtitle">
                    Enable customers to pay with Resolve for B2B
                  </div>
                  <div className="box-actions">
                    {resolveMethod.activated ? (
                      <a
                        href=""
                        onClick={this.onClickEditResolve}
                        className="button button-sm button-secondary"
                      >
                        Settings
                      </a>
                    ) : (
                      <a
                        href=""
                        onClick={this.onClickEditResolve}
                        className="button button-sm button-default"
                      >
                        Enable
                      </a>
                    )}
                    {resolveMethod.activated &&
                      resolveMethod.enabled &&
                      (testEnv || !isLiveMode(resolveMethod.mode)) && (
                        <Status type="warning">Test mode</Status>
                      )}
                  </div>
                </div>
                <div className="settings-integrations-box box">
                  <div className="box-grid">
                    <div className="box-column">
                      <div className="box-title payment-method">
                        <PaymentMethodLogo id="paysafecard" />
                        Paysafecard
                      </div>
                    </div>
                    <div className="box-column">
                      {paysafecardMethod.activated && (
                        <Field
                          type="toggle"
                          name="methods.paysafecard.enabled"
                          defaultChecked={!!paysafecardMethod.enabled}
                        />
                      )}
                    </div>
                  </div>
                  <div className="box-subtitle">
                    Enable customers to pay with Paysafecard at checkout
                  </div>
                  <div className="box-actions">
                    {paysafecardMethod.activated ? (
                      <a
                        href=""
                        onClick={this.onClickEditPaysafecard}
                        className="button button-sm button-secondary"
                      >
                        Settings
                      </a>
                    ) : (
                      <a
                        href=""
                        onClick={this.onClickActivatePaysafecard}
                        className="button button-sm button-default"
                      >
                        Enable
                      </a>
                    )}
                    {paysafecardMethod.activated &&
                      paysafecardMethod.enabled &&
                      (testEnv || !isLiveMode(paysafecardMethod.mode)) && (
                        <Status type="warning">Test mode</Status>
                      )}
                  </div>
                </div>
              </div>
              <br />
              <div className="box box-grid">
                <div className="box-column">
                  <h4 className="box-title">Gift cards</h4>
                  <p>Enable customers to pay with gift cards at checkout</p>
                </div>
                <div className="box-action box-column">
                  <Field
                    type="toggle"
                    name="methods.giftcard.enabled"
                    defaultChecked={!!values.methods.giftcard?.enabled}
                  />
                </div>
              </div>
              <div className="box box-grid">
                <div className="box-column">
                  <h4 className="box-title">Account credits</h4>
                  <p>
                    Enable customers to pay with account credits at checkout
                  </p>
                </div>
                <div className="box-action box-column">
                  <Field
                    type="toggle"
                    name="methods.account.enabled"
                    defaultChecked={!!values.methods.account?.enabled}
                  />
                </div>
              </div>
              <br />
            </fieldset>
            <fieldset className="full last-child">
              <div className="view-body-subheader">
                <h3 className="view-body-subtitle">Manual payments</h3>
                <p>
                  Enable customers to pay offline, providing instructions for
                  bank deposit, cash on delivery (COD), money order, or custom
                  payments.
                </p>
              </div>
              <PaymentsManual
                values={values}
                onSubmit={this.onSubmitManualPaymentMethod}
              />
            </fieldset>
          </View>
        </Form>
        {this.state.showEditCardModal && this.renderEditCardModal()}
        {this.state.showEditPaypalExpressModal &&
          this.renderPayPalExpressEditModal()}
        {this.state.showEditPaypalCommerceModal &&
          this.renderPayPalCommerceEditModal()}
        {this.state.showOnboardingPaypalCommerceModal &&
          this.renderPayPalCommerceOnboadringModal()}
        {this.state.showEditAmazonModal && this.renderEditAmazonModal()}
        {this.state.showEditAffirmModal && this.renderEditAffirmModal()}
        {this.state.showEditResolveModal && this.renderEditResolveModal()}
        {this.state.showActivateAmazonModal && this.renderActivateAmazonModal()}
        {this.state.showActivateStripeModal && this.renderActivateStripeModal()}
        {this.state.showPaysafecardModal && this.renderPaysafecardModal()}
        {this.state.showKlarnaModal && this.renderKlarnaModal()}
      </div>
    );
  }
}
