import React, { Fragment } from 'react';
import Icon from 'components/icon';
import Link from 'components/link';
import User from '../utils/user';
import Date from '../utils/date';

export default function OrderCreated({ event }) {
  const { draft, cart_id } = event.data;

  const fromDraft = draft === false && cart_id;
  const noCart = !cart_id;

  if (!draft && !fromDraft && !noCart) {
    return null;
  }

  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/order-created" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          {fromDraft ? (
            <Fragment>
              <Link to={`/orders/drafts/${cart_id}`}>Draft order</Link> created
            </Fragment>
          ) : draft ? (
            <Fragment>Draft order created</Fragment>
          ) : (
            <Fragment>Order created</Fragment>
          )}
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
      </div>
    </div>
  );
}
