import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Field from './field';
import ProductPrice from 'components/pages/product/price';
import { productThumbUrl } from 'utils/product';

export default class LookupVariant extends React.PureComponent {
  static propTypes = {
    lookup: PropTypes.object.isRequired,
    multiple: PropTypes.bool,
    parentId: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      query: this.getQuery(props),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.query !== this.props.query ||
      nextProps.parentId !== this.props.parentId
    ) {
      this.setState({ query: this.getQuery(nextProps) });
    }
  }

  getQuery(props) {
    return {
      ...(props.query || {}),
      ...(props.parentId ? { parent_id: props.parentId } : {}),
      fields: 'name, price, currency, date_created, images, parent_id',
      include: {
        ...get(props.query, 'include', {}),
        product_image: {
          url: '/products/{parent_id}/images/0',
        },
      },
    };
  }

  renderProductValue(record) {
    return (
      <span>
        <span className="image">
          <span>
            <img alt="" src={productThumbUrl(record.product_image, record)} />
          </span>
        </span>
        {record.name}
      </span>
    );
  }

  renderProductLookupItems({ lookup, ...props }) {
    return lookup.results.map((record) => (
      <li
        key={record.id}
        role="option"
        data-id={record.id}
        aria-selected={record.id === props.selected?.id}
        className={`item ${
          record.id === props.selected?.id ? 'selected' : null
        }`}
        onMouseOver={props.onMouseOver}
        onClick={props.onClick}
      >
        <span className="col">
          <span className="image">
            <span>
              <img alt="" src={productThumbUrl(record.product_image, record)} />
            </span>
          </span>
          {record.name}
        </span>
        <span className="col muted">
          <ProductPrice product={record} variant={record} />
        </span>
        <span className="col ">{record.sku}</span>
      </li>
    ));
  }

  render() {
    return (
      <span className={`form-lookup-product ${this.props.className || ''}`}>
        <Field
          ref="field"
          type="lookup"
          name={this.props.multiple ? 'variants' : 'variant'}
          placeholder={
            this.props.multiple
              ? 'Find variants by name'
              : 'Find variant by name'
          }
          model="products:variants"
          renderValue={this.renderProductValue}
          renderLookupItems={this.renderProductLookupItems}
          lookup={this.props.lookup}
          multiple={this.props.multiple}
          queryFocus={true}
          {...this.props}
          query={this.state.query}
          className={undefined}
          parentId={undefined}
        />
      </span>
    );
  }
}
