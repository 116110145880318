import React, { Fragment } from 'react';

export const INTEGRATIONS = [
  {
    id: 'avatax',
    name: 'Avalara AvaTax',
    description:
      'Simplify taxes with real-time tax calculations and compliance',
    renderConnected: (values) => (
      <Fragment>
        {values.environment === 'production' ? (
          <span>
            with company code <b>{values.production_company_code}</b>
          </span>
        ) : (
          <span>
            in <u className="muted">sandbox mode</u> with company code{' '}
            <b>{values.sandbox_company_code}</b>
          </span>
        )}
      </Fragment>
    ),
  },
  {
    id: 'mailchimp',
    name: 'Mailchimp',
    description: 'Synchronize customer emails with your Mailchimp account',
    renderConnected: (values) => (
      <Fragment>
        {values.list_name ? (
          <span>
            with <b>{values.list_name}</b>
          </span>
        ) : null}
      </Fragment>
    ),
  },
  {
    id: 'taxjar',
    name: 'TaxJar',
    description:
      'Simplify taxes with real-time tax calculations and compliance',
    renderConnected: (values) => (
      <Fragment>
        {values.environment === 'production' ? (
          <span>
            in <u className="muted">production mode</u>
          </span>
        ) : (
          <span>
            in <u className="muted">sandbox mode</u>
          </span>
        )}
      </Fragment>
    ),
  },
  {
    id: 'slack',
    name: 'Slack',
    description: 'Send store events to your Slack workspace',
    renderConnected: (values) => (
      <Fragment>
        with site ID <b>{values.site_id}</b>
      </Fragment>
    ),
  },
  {
    id: 'zapier',
    name: 'Zapier',
    description: 'Automate workflows in your store with Zapier',
    externallink:
      'https://zapier.com/developer/public-invite/137804/821a1896f6e22def2b45b5476f3b7bb3/',
  },
  {
    id: 'segment',
    name: 'Segment',
    description: 'Send store events to Segment automatically',
    renderConnected: (values) => (
      <Fragment>
        <span>
          with Write Key <b>{values.write_key}</b>
        </span>
      </Fragment>
    ),
  },
  {
    id: 'omnisend',
    name: 'Omnisend',
    description: 'Synchronize order and customer data with Omnisend',
    sync: true,
    renderConnected: (values) => (
      <Fragment>
        with store URL <b>{values.store_url}</b>
      </Fragment>
    ),
  },
  {
    id: 'klaviyo',
    name: 'Klaviyo',
    description: 'Synchronize order and customer data with Klaviyo',
    renderConnected: (values) => (
      <Fragment>
        with site ID <b>{values.site_id}</b>
      </Fragment>
    ),
  },
  {
    id: 'algolia',
    name: 'Algolia',
    sync: true,
    description:
      'Improve search functionalities by indexing product data with Algolia',
  },
  // {
  //   id: 'quickbooks',
  //   name: 'QuickBooks',
  //   comingsoon: true,
  //   description: 'Synchronize payments and customer details with QuickBooks',
  // },
  {
    id: 'sendgrid',
    name: 'SendGrid',
    description: 'Optimize email notification delivery with SendGrid',
  },
  {
    id: 'gladly',
    name: 'Gladly',
    description: 'Synchronize customers data with Gladly',
    externallink: 'https://www.swell.is/help/integrations/gladly',
  },
  {
    id: 'contentful',
    name: 'Contentful',
    description: 'Connect store data with Contentful for integrated content',
    externallink: 'https://www.contentful.com/marketplace/app/swell/',
  },
  {
    id: 'shipstation',
    name: 'ShipStation',
    sync: true,
    description: 'Manage and ship your orders with ShipStation',
  },
  {
    id: 'yotpo',
    name: 'Yotpo',
    sync: true,
    description: `Grow your brand by linking your data with Yotpo's marketing tools`,
  },
  {
    id: 'smartystreets',
    name: 'Smarty',
    description: 'US address autocomplete for checkout and customer forms',
  },
];

export function integrationName(id) {
  const integration = INTEGRATIONS.find((i) => id === i.id);
  return integration?.name;
}

export function integrationActivated(id, service = {}) {
  switch (id) {
    case 'mailchimp':
      return Boolean(service.activated && service.access_token);
    case 'slack':
      return Boolean(service.activated && service.installation);
    default:
      return Boolean(service.activated);
  }
}
