import React from 'react';
import PropTypes from 'prop-types';

export class PriceDisplay extends React.PureComponent {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const { order } = this.props;
    const { location } = this.context.router;
    const isSubscription = order.subscription_id || order.paid_by_invoice;
    const hasPhysicalProducts = order.items.some(
      (item) => item.delivery === 'shipment',
    );

    return isSubscription &&
      !hasPhysicalProducts &&
      location.pathname.includes('orders') ? (
      <span>&mdash;</span>
    ) : (
      this.props.children
    );
  }
}
