import React, { Fragment } from 'react';
import find from 'lodash/find';
import pt from 'prop-types';

import { formatCurrencyRounded } from 'utils';
import { multiCurrencyValueFromTotals } from 'utils/money';

import Link from 'components/link';
import DateTime from 'components/date-time';
import SectionHeader from 'components/section-header';
import ButtonLink from 'components/button/button-link';

import PaymentTotal from './payment-total';
import PaymentStatus from './payment-status';

import './subscription.scss';

function SubscriptionPayments({
  record,
  loading,
  editable = true,
  onClickPaymentRetry,
}) {
  const {
    invoices = {},
    orders = {},
    currency,
    tax_included,
    order_totals,
    invoice_totals,
  } = record;

  const totals = [...(order_totals || []), ...(invoice_totals || [])];

  let totalPaid = multiCurrencyValueFromTotals(
    totals,
    'payment_total',
    currency,
  );

  if (record.order) {
    const orderItem = find(record.order.items, { id: record.order_item_id });

    if (orderItem) {
      totalPaid +=
        (orderItem.price_total || 0) +
        (tax_included ? 0 : orderItem.tax_total || 0);
    }
  }

  return (
    <Fragment>
      <div className="view-body-section-group">
        {editable && invoices.results && invoices.results.length > 0 && (
          <div className="view-body-section">
            <div className="view-body-subheader">
              <SectionHeader
                className="view-body-subtitle"
                title="Invoices"
                count={invoices.count}
              />
              <div>
                {invoices.count > invoices.results.length && (
                  <ButtonLink
                    type="secondary"
                    size="sm"
                    to={`/subscriptions/${record.id}/invoices`}
                  >
                    View all invoices
                  </ButtonLink>
                )}
              </div>
            </div>
            <div className="view-section-content collection-full">
              <div className="collection-table-container">
                <table className="collection-table">
                  <thead>
                    <tr>
                      <th>Number</th>
                      <th>Period</th>
                      <th>Status</th>
                      <th className="currency">Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {invoices.results.map((invoice) => (
                      <tr key={invoice.id}>
                        <td>
                          <Link to={`/invoices/${invoice.id}`}>
                            {invoice.number}
                          </Link>
                        </td>

                        <td>
                          <DateTime
                            value={invoice.date_period_start}
                            format="short"
                          />
                          {' — '}
                          <DateTime
                            value={invoice.date_period_end}
                            format="short"
                          />
                        </td>

                        <td>
                          <PaymentStatus
                            model="invoices"
                            record={invoice}
                            loading={loading}
                            onClickPaymentRetry={onClickPaymentRetry}
                          />
                        </td>

                        <td className="currency">
                          {formatCurrencyRounded(
                            invoice.grand_total,
                            invoice.currency,
                          )}
                        </td>
                      </tr>
                    ))}
                    {totalPaid >= 0 && (
                      <tr>
                        <td colSpan="3">Total paid</td>
                        <td className="currency">
                          <PaymentTotal
                            totalPaid={totalPaid}
                            currency={currency}
                            totals={totals}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {editable && orders.results && orders.results.length > 0 && (
          <div className="view-body-section">
            <div className="view-body-subheader">
              <SectionHeader
                className="view-body-subtitle"
                title="Orders"
                count={orders.count}
              />
              <div>
                {orders.count > orders.results.length && (
                  <ButtonLink
                    type="secondary"
                    size="sm"
                    to={`/subscriptions/${record.id}/orders`}
                  >
                    View all orders
                  </ButtonLink>
                )}
              </div>
            </div>
            <div className="view-section-content collection-full">
              <div className="collection-table-container">
                <table className="collection-table">
                  <thead>
                    <tr>
                      <th>Number</th>
                      <th>Period</th>
                      <th>Status</th>
                      <th className="currency">Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orders.results.map((order) => (
                      <tr key={order.id}>
                        <td>
                          <Link to={`/orders/${order.id}`}>{order.number}</Link>
                        </td>

                        <td>
                          <DateTime
                            value={order.date_period_start}
                            format="short"
                          />
                          {' — '}
                          <DateTime
                            value={order.date_period_end}
                            format="short"
                          />
                        </td>

                        <td>
                          <PaymentStatus
                            model="orders"
                            record={order}
                            loading={loading}
                            onClickPaymentRetry={onClickPaymentRetry}
                          />
                        </td>

                        <td className="currency">
                          {formatCurrencyRounded(
                            order.grand_total,
                            order.currency,
                          )}
                        </td>
                      </tr>
                    ))}
                    {totalPaid >= 0 && invoices.results?.length <= 0 && (
                      <tr>
                        <td colSpan="3">Total paid</td>
                        <td className="currency">
                          <PaymentTotal
                            totalPaid={totalPaid}
                            currency={currency}
                            totals={totals}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

SubscriptionPayments.propTypes = {
  record: pt.object,
  loading: pt.bool,
  editable: pt.bool,
  onClickPaymentRetry: pt.func,
};

export default React.memo(SubscriptionPayments);
