import React from 'react';
import { find } from 'lodash';
import { connect } from 'react-redux';
import ViewLoading from 'components/view/loading';
import ViewPage from 'components/apps/view';
import NotFoundPage from 'components/pages/error/404';
import actions from 'actions';

const mapStateToProps = (state) => ({
  app: state.apps.record,
  installedApps: state.client.apps,
  settingConfigs: state.settings.configs,
  lookup: state.lookup,
  categories: state.categories,
  content: state.content,
});

const mapDispatchToProps = (dispatch) => ({
  fetchApp: (id) => {
    return dispatch(actions.apps.fetch(id));
  },

  fetchAppConfigs: (id, query) => {
    return dispatch(actions.apps.fetchConfigs(id, query));
  },

  loadSettings: (id) => {
    return dispatch(actions.settings.load(id));
  },

  updateSettings: (id, data) => {
    return dispatch(actions.settings.update(id, data));
  },

  updateApp: async (id, data) => {
    const result = await dispatch(actions.client.updateApp(id, data));
    // Reset content models after updating
    await dispatch(actions.content.fetchModels());
    return result;
  },

  uninstallApp: (id) => {
    return dispatch(actions.client.uninstallApp(id));
  },
});

export class ViewApp extends React.PureComponent {
  state = {};
  mounted = false;

  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded: false,
      values: null,
      edited: false,
      app: null,
      appConfigs: null,
      installedApp: null,
      settings: null,
    };
  }

  componentWillMount() {
    this.mounted = true;
    this.fetchAppData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentWillReceiveProps(nextProps) {
    const { params, installedApps } = this.props;

    if (params.id !== nextProps.params.id) {
      this.setState({ loaded: false });
      this.fetchAppData(nextProps);
    } else if (installedApps !== nextProps.installedApps) {
      this.fetchAppData(nextProps);
    }
  }

  async fetchAppData(props = this.props) {
    const { params, installedApps, fetchApp, fetchAppConfigs, loadSettings } =
      props;
    try {
      const installedApp = find(installedApps?.results, {
        app_id: params.id,
      });
      if (!installedApp) {
        this.setState({
          loaded: true,
        });
        return;
      }

      const [app, appConfigs, settings] = await Promise.all([
        fetchApp(params.id),
        fetchAppConfigs(params.id, { version: installedApp.version }),
        loadSettings(params.id),
      ]);

      if (this.mounted) {
        this.setState({
          loaded: true,
          app,
          appConfigs,
          installedApp,
          settings,
          values: { ...settings },
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  onChangeSettings = (values, edited) => {
    this.setState({
      values: { ...this.state.values, ...values },
      edited,
    });
  };

  onSubmitSettings = async (values) => {
    const { params, updateSettings } = this.props;
    await updateSettings(params.id, values);
  };

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    if (!this.state.app || !this.state.installedApp) {
      return <NotFoundPage />;
    }

    return (
      <ViewPage
        {...this.props}
        {...this.state}
        onChangeSettings={this.onChangeSettings}
        onSubmitSettings={this.onSubmitSettings}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewApp);
