const actions = {
  exportActive: (data) => data.active,
  exportAttributes: (data) => data.attributes,
  exportBundleItems: (data) => data,
  exportCategories: (data) => data,
  exportCost: (data) => data.cost,
  exportId: (data) => data.id,
  exportImages: (data) => data.images.flatMap(({ file }) => file.url),
  exportPrice: (data) => data.price,
  exportPrices: (data) => data.prices,
  exportSale: (data) => data.sale,
  exportSalePrice: (data) => data.sale_price,
  exportShippingPrices: (data) => data,
  exportShippingWeight: (data) => data.shipment_weight,
  exportSku: (data) => data.sku,
  exportStockLevel: (data) => data.stock_level,
  exportTags: (data) => data,
  exportVariantOptions: (data) => data,
  exportProductOptions: (data) => data.options,
  exportPurchaseOptions: (data) => data.purchase_options,
  exportLocaleOptions: (data) => data.$locale,
};

export default actions;
