import React from 'react';
import Icon from 'components/icon';
import Link from 'components/link';
import User from '../utils/user';
import Date from '../utils/date';

export default function OrderSubmitted({ event, record }) {
  const { draft, cart_id } = record;

  const fromCart = draft !== false && cart_id;

  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/order-submitted" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          Order{' '}
          {fromCart ? (
            <Link to={`/carts/${cart_id}`}>placed</Link>
          ) : cart_id ? (
            'placed'
          ) : (
            'submitted'
          )}
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
      </div>
    </div>
  );
}
