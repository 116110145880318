import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import moment from 'moment';

import api from 'services/api';

import { setTestEnv, parseQuery, hasPermission } from 'utils';
import { getBaseAndTestEnvPath } from 'utils/container';
import { getPurchaseLinkIdFromPath } from 'utils/purchase-link';

import segment from 'services/segment';

import actions from 'actions';
import { getNameFields } from 'actions/user';

import AccessDeniedPage from 'components/pages/error/403';
import NotFoundPage from 'components/pages/error/404';

const { BASE_URI } = process.env;
const { SEGMENT_CLIENT_KEY } = process.env;

const IDLE_TIME = 3 * 60 * 60 * 1000; // 3 hours

const mapStateToProps = (state) => {
  return {
    account: state.account,
    client: state.client,
    content: state.content,
    flash: state.flash,
    settings: state.settings,
    session: state.session,
    user: state.user,
    loading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchClient: async () => {
    return dispatch(actions.client.fetch({ expand: ['leads', 'owner'] }));
  },

  fetchEnvironments: async () => {
    return dispatch(actions.client.fetchEnvironments());
  },

  fetchSession() {
    return dispatch(actions.session.fetch());
  },

  fetchUser: async () => {
    return dispatch(actions.user.fetch());
  },

  fetchAccount: async () => {
    return dispatch(actions.account.fetch());
  },

  fetchAccountPlan: async () => {
    return dispatch(actions.account.fetchPlan());
  },

  flashError: (message) => {
    dispatch(actions.flash.error(message));
  },

  clearFlash: (event) => {
    if (event) event.preventDefault();
    dispatch(actions.flash.clear());
  },

  cancelAutoFlash: (event) => {
    if (event) event.preventDefault();
    actions.flash.cancelAuto();
  },

  userLogout: () => {
    dispatch(actions.user.performLogout());
  },

  switchAccount: (clientId, uri) => {
    return dispatch(actions.user.switchAccount(clientId, uri));
  },

  createStore: () => {
    return dispatch(actions.stores.createInitial());
  },
});

function insertCss(...styles) {
  const removeCss = styles.map((x) => x._insertCss());

  return function cleanup() {
    removeCss.forEach((f) => f());
  };
}

export class App extends React.Component {
  static childContextTypes = {
    account: PropTypes.object,
    client: PropTypes.object,
    flash: PropTypes.object,
    settings: PropTypes.object,
    session: PropTypes.object,
    user: PropTypes.object,
    isOwner: PropTypes.bool,
    isAdvancedUserPermissions: PropTypes.bool,
    location: PropTypes.object,
    clearFlash: PropTypes.func,
    cancelAutoFlash: PropTypes.func,
    setTitle: PropTypes.func,
    getLastUrl: PropTypes.func,
    setLastUrl: PropTypes.func,
    onClickLogout: PropTypes.func,
    onClickSwitch: PropTypes.func,
    onClickSwitchAccount: PropTypes.func,
    insertCss: PropTypes.func,
  };

  static async onChange(store, nextState) {
    const { flash, modal } = store.getState();
    if (flash.visible) {
      store.dispatch(actions.flash.clear());
    }
    if (modal.stack.length > 0) {
      store.dispatch(actions.modal.close());
    }

    App.lastUrl = null;
  }

  state = {
    loaded: false,
    validEnvironment: true,
  };

  bodyLoadingEl = null;

  segmentIdentity = null;

  lastActiveDate = null;

  async componentDidMount() {
    const {
      fetchUser,
      fetchClient,
      fetchEnvironments,
      fetchSession,
      fetchAccount,
      fetchAccountPlan,
      location,
    } = this.props;

    this.enhanceRouter(this.props);
    this.enhanceLocationQuery(this.props);

    this.bodyLoadingEl = document.getElementById('loading');

    // Note: This is an odd place for such logic
    // Note: this is only used in local dev environment
    // It goes through gateway.js in production (facepalm)
    const { id: purchaseLinkId, testEnv } = getPurchaseLinkIdFromPath(
      location.pathname,
    );
    if (purchaseLinkId) {
      const result = await api.getPurchaseLinkInfo(purchaseLinkId, testEnv);
      if (result?.checkout_url) {
        window.location.replace(result.checkout_url);
        return;
      }

      this.setState({ loaded: true });
      this.removeLoadingAnim();

      return; // Results in a 404
    }

    const session = await fetchSession();
    const isLoggedIn = Boolean(session?.user_id);

    const isLocationValid =
      !BASE_URI ||
      location.pathname.startsWith(BASE_URI) ||
      location.pathname.startsWith('/switch');

    this.presetTestEnv();

    await Promise.all(
      isLoggedIn && isLocationValid
        ? [
            fetchClient(),
            fetchUser(),
            fetchAccount().then(() => fetchAccountPlan()),
            fetchEnvironments(),
          ]
        : [fetchClient()],
    );

    this.validateEnvironment();

    this.initSegment(this.props);

    this.setState({ loaded: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.enhanceLocationQuery(nextProps);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.client !== this.props.client) {
      this.setTitle();
    }

    const { loading, location, account, client, user } = prevProps;

    if (this.bodyLoadingEl && loading && !this.props.loading) {
      this.removeLoadingAnim();
    }

    if (location !== this.props.location) {
      this.initSegment(this.props);
      this.trackIdle();
    }

    const isUserChanged =
      account.plan !== this.props.account.plan ||
      client !== this.props.client ||
      user !== this.props.user;

    const isLoggedIn = this.props.session.user_id;

    if (isLoggedIn) {
      if (isUserChanged) {
        this.identifyTrackers(this.props);
      }

      if (
        prevProps.client?.hasEnvironments !== this.props.client?.hasEnvironments
      ) {
        this.validateEnvironment();
      }
    }
  }

  removeLoadingAnim() {
    this.bodyLoadingEl.outerHTML = '';
    this.bodyLoadingEl = null;
  }

  initSegment(props) {
    const { pathname, search } = props.location;
    if (SEGMENT_CLIENT_KEY) {
      const page = pathname + search;
      segment.initPage(SEGMENT_CLIENT_KEY, page);
    }
  }

  identifyTrackers(props) {
    const { account, client, user, session } = props;
    if (client.id && user.email) {
      const plan = account.plan || {};
      const nameFields = getNameFields(user);
      const trackersIdentity = {
        id: user.id,
        company: {
          id: client.id,
          name: client.name,
          plan: plan.name,
          ...(client.business || {}),
        },
        avatar: user.photo && user.photo.url,
        created_at: user.date_created,
        email: user.email,
        username: user.username,
        first_name: nameFields.firstName,
        last_name: nameFields.lastName,
        title: user.title,
        phone: client.business && client.business.phone,
        super_admin: session.is_super_admin,
      };
      if (!isEqual(trackersIdentity, this.trackersIdentity)) {
        this.trackersIdentity = trackersIdentity;
        clearTimeout(this.trackersTimeout);
        this.trackersTimeout = setTimeout(() => {
          segment.identify(user.id, trackersIdentity);
        }, 3000);
      }
    }
  }

  presetTestEnv() {
    const { envName } = getBaseAndTestEnvPath();
    setTestEnv(envName);
  }

  // Validates the environment within the url.
  // A client must have a test environment when navigating to /admin/test-:name
  // A client must have a live environment when navigating to /admin (live route)
  // Clients with no environments cannot navigate to /admin/test-:name
  validateEnvironment() {
    const { account, client, route, router, session } = this.props;
    const { isTest, envName } = getBaseAndTestEnvPath();

    if (isTest && !account.hasTestEnvironment) {
      const isLoggedIn = Boolean(session?.user_id);
      this.setState({ validEnvironment: !isLoggedIn });
    } else if (
      !isTest &&
      !account.hasLiveEnvironment &&
      route.testEnv !== false // Ignore when route indicates no test env
    ) {
      // Redirect to test environment route for simplicity
      router.replace(location.pathname.replace(BASE_URI, `${BASE_URI}/test`));
      this.setState({ validEnvironment: true });
    } else if (isTest && envName && envName !== 'test') {
      // Validate client has this specific test environment
      const { environments } = client;
      const clientHasSelectedEnv = environments.some(
        (env) => env.slug === envName,
      );
      this.setState({ validEnvironment: clientHasSelectedEnv });
    } else {
      this.setState({ validEnvironment: true });
    }
  }

  trackIdle() {
    const { client, user } = this.props;

    if (!client.id) {
      return;
    }

    if (!this.lastActiveDate) {
      this.lastActiveDate = new Date();
      return;
    }

    if (Date.now() - this.lastActiveDate > IDLE_TIME) {
      const duration = moment.duration(Date.now() - this.lastActiveDate);
      segment.track('User was idle', {
        username: user.username,
        client_id: client.id,
        client_name: client.name,
        idle_hours: duration.asHours(),
        idle_time: duration.humanize(),
      });
      this.lastActiveDate = new Date();
    }
  }

  getChildContext() {
    return {
      account: this.props.account,
      client: this.props.client,
      flash: this.props.flash,
      settings: this.props.settings,
      session: this.props.session,
      user: this.props.user,
      isOwner: this.isOwner(),
      isAdvancedUserPermissions: this.isAdvancedUserPermissions(),
      location: this.props.location,
      clearFlash: this.props.clearFlash,
      cancelAutoFlash: this.props.cancelAutoFlash,
      setTitle: this.setTitle,
      getLastUrl: this.getLastUrl,
      setLastUrl: this.setLastUrl,
      onClickLogout: this.onClickLogout,
      onClickSwitch: this.onClickSwitch,
      onClickSwitchAccount: this.onClickSwitchAccount,
      insertCss: insertCss,
    };
  }

  checkDidChangeEnvironment(router, uri, lastUri) {
    // Make the router global so it can be accessed by data actions
    global.router = router;

    const wasTest = lastUri.includes(`${BASE_URI}/test`);
    const isTest = uri.includes(`${BASE_URI}/test`);

    if ((wasTest && !isTest) || (isTest && !wasTest)) {
      return true;
    }

    return false;
  }

  enhanceRouter(props) {
    if (!props.router.enhancedWithBaseUri) {
      props.router.originalPush = props.router.push;

      let lastUri = props.router.location.pathname;

      props.router.push = (uri, ...args) => {
        const { baseUri } = getBaseAndTestEnvPath();
        const nextUri = typeof uri === 'string' ? uri : uri.pathname;

        // This check is used to re-route depending on state when environment changes
        props.router.didChangeEnvironment = this.checkDidChangeEnvironment(
          props.router,
          nextUri,
          lastUri,
        );

        lastUri = nextUri;

        if (!BASE_URI || nextUri.indexOf(BASE_URI) === 0) {
          return props.router.originalPush(uri, ...args);
        }

        return props.router.originalPush(
          `${baseUri}${nextUri}`.replace(/\/$/, ''),
        );
      };

      props.router.originalReplace = props.router.replace;
      props.router.replace = (uri, ...args) => {
        const { baseUri } = getBaseAndTestEnvPath();
        const nextUri = typeof uri === 'string' ? uri : uri.pathname;

        if (!BASE_URI || nextUri.indexOf(BASE_URI) === 0) {
          return props.router.originalReplace(uri, ...args);
        }

        return props.router.originalReplace(
          `${baseUri}${nextUri}`.replace(/\/$/, ''),
        );
      };

      props.router.enhancedWithBaseUri = true;
    }
  }

  enhanceLocationQuery(props) {
    if (props.location.queryParsed !== props.location.query) {
      props.location.query = parseQuery(props.location.search);
      props.location.queryParsed = props.location.query;
    }
  }

  setTitle = (section = '') => {
    document.title = `${section ? `${section} / ` : ''}${
      this.props.client.name || 'Swell'
    }`;
  };

  getLastUrl = () => {
    return App.lastUrl;
  };

  setLastUrl = (url) => {
    if (url) {
      App.lastUrl = url;
    }
  };

  isOwner() {
    const { user, client, session } = this.props;
    if (session.is_owner || session.is_super_admin) {
      return true;
    }
    if (client.owner_id === user.id) {
      return true;
    }
    return false;
  }

  isAdvancedUserPermissions() {
    return !!this.props.account.features.advanced_user_permissions;
  }

  onClickLogout = (event) => {
    event.preventDefault();
    this.props.userLogout();
    this.props.router.originalPush('/login');
  };

  onClickSwitch = (event) => {
    event.preventDefault();
    const { redirect } = event.currentTarget.dataset;
    this.props.router.originalPush(
      `/switch${redirect ? `?redirect=${redirect}` : ''}`,
    );
  };

  onClickSwitchAccount = (event) => {
    event.preventDefault();
    const {
      client,
      router,
      flashError,
      switchAccount,
      location: { query },
    } = this.props;
    const { id, uri } = event.currentTarget.dataset;
    if (id === client.id) {
      router.push(uri || query.redirect || '/');
      return;
    }
    if (id) {
      try {
        const result = switchAccount(id, uri || query.redirect);
        if (!result) {
          flashError('Access denied');
        }
      } catch (err) {
        flashError(err.message);
      }
    }
  };

  render() {
    if (!this.state.loaded) {
      return null;
    }

    const { client, routes, children, user, session } = this.props;

    if (!client.exists && client.id && client.id !== 'app') {
      if (window.location.pathname.indexOf('/verify') !== 0) {
        return <NotFoundPage />;
      }
    } else if (
      !client.id &&
      window.location.hostname === 'swell.store' &&
      window.location.pathname === '/'
    ) {
      window.location.href = `https://www.${window.location.hostname}`;
      return null;
    }

    if (session?.user_id && !this.state.validEnvironment) {
      return <NotFoundPage />;
    }

    const targetRoute = routes[routes.length - 1];
    if (
      !hasPermission(user, targetRoute.permission, window.location.pathname, {
        isAdvancedUserPermissions: this.isAdvancedUserPermissions(),
      })
    ) {
      return <AccessDeniedPage />;
    }

    return children;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
