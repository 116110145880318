import React, { Fragment } from 'react';
import pt from 'prop-types';

import { formatCurrencyRounded } from 'utils';
import { renderContentTooltip } from 'utils/content';
import { getRefundablePayments } from 'utils/payment';
import {
  paymentDue,
  refundDue,
  renderPaymentMethodDescription,
} from 'utils/order';

import Tooltip from 'components/tooltip';
import Help from 'components/tooltip/help';
import Link from 'components/link';
import Button from 'components/button';
import Icon from 'components/icon';
import DateTime from 'components/date-time';

import PaymentStatus from './payment-status';
import SectionHeader from 'components/section-header';

function OrderPayments({
  record,
  content,
  settings,
  sourceLabel = 'order',

  onClickPaymentCharge,
  onClickPaymentRefund,
  onClickEditSingleItem,
}) {
  const { payments: recordPayments = {}, canceled, currency } = record;

  const duePayment = paymentDue(record);
  const dueRefund = refundDue(record);

  const totalPaid = (record.payment_total || 0) - (record.refund_total || 0);

  const hasPayments = recordPayments.results?.length > 0;

  const showPaymentTotals = duePayment > 0 || dueRefund > 0 || hasPayments;

  const refundablePayments = getRefundablePayments(recordPayments.results);

  const hasEditableUpcomingPayments =
    onClickEditSingleItem &&
    hasPayments &&
    recordPayments.results.some((payment) => payment.upcoming);

  const isChargeAvailable = onClickPaymentCharge && !canceled;
  const isRefundAvailable =
    onClickPaymentRefund && refundablePayments.length > 0;

  const isPaymentActionsAvailable = isRefundAvailable || isChargeAvailable;

  return (
    <div className="order-payment">
      <div className="view-body-section-group">
        <div className="view-body-section">
          <div className="view-body-subheader">
            <SectionHeader
              className="view-body-subtitle"
              title="Transactions"
            />
          </div>
          <div className="view-section-content collection-full ">
            {hasPayments ? (
              <div className="collection-table-container">
                <table className="collection-table">
                  <thead>
                    <tr>
                      <th>Method</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th className="currency">Amount</th>
                      {hasEditableUpcomingPayments && <th>&nbsp;</th>}
                    </tr>
                  </thead>

                  <tbody>
                    {recordPayments.results
                      .map((payment) => [
                        <tr key={payment.id}>
                          <td className="inner-link">
                            {payment.upcoming ? (
                              <Fragment>
                                {renderPaymentMethodDescription(
                                  settings,
                                  payment,
                                )}

                                {payment.help && (
                                  <Help message={payment.help} />
                                )}
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Link
                                  to={`/console?get/payments/${payment.id}`}
                                >
                                  {renderPaymentMethodDescription(
                                    settings,
                                    payment,
                                  )}
                                </Link>

                                {renderContentTooltip(
                                  payment,
                                  content.models,
                                  'payments',
                                  'charge',
                                )}
                              </Fragment>
                            )}
                          </td>

                          <td>
                            <DateTime
                              value={payment.date_created}
                              format="age"
                            />
                          </td>

                          <td width="125">
                            <PaymentStatus payment={payment} />

                            {payment.authorized_amount &&
                              payment.authorized_amount !== payment.amount && (
                                <Help
                                  message={`Authorized amount ${formatCurrencyRounded(
                                    payment.authorized_amount,
                                    payment.currency,
                                  )}`}
                                />
                              )}
                          </td>

                          <td className="currency" width="125">
                            {formatCurrencyRounded(
                              payment.amount,
                              payment.currency,
                            )}
                          </td>

                          {hasEditableUpcomingPayments && (
                            <td className="action">
                              {!!payment.upcoming && (
                                <button
                                  data-id={payment.item_id}
                                  data-index={payment.item_index}
                                  className="as-link"
                                  onClick={onClickEditSingleItem}
                                  type="button"
                                >
                                  Edit
                                </button>
                              )}
                            </td>
                          )}
                        </tr>,
                        ...(payment.refunds && payment.refunds.count > 0
                          ? payment.refunds.results.map((refund) => (
                              <tr key={refund.id}>
                                <td className="inner-link">
                                  <Link
                                    to={`/console?get/payments/${payment.id}%2Frefunds%2F${refund.id}`}
                                  >
                                    <span className="order-payment-refund-icon">
                                      <Icon fa="level-up" />
                                    </span>

                                    {renderPaymentMethodDescription(
                                      settings,
                                      refund,
                                    )}
                                  </Link>

                                  {refund.reason && (
                                    <Tooltip
                                      message={refund.reason}
                                      className="muted"
                                    >
                                      &nbsp;&nbsp;
                                      <Icon fa="comment-alt-lines" />
                                    </Tooltip>
                                  )}

                                  {renderContentTooltip(
                                    refund,
                                    content.models,
                                    'payments',
                                    'refund',
                                  )}
                                </td>

                                <td>
                                  <DateTime
                                    value={refund.date_created}
                                    format="age"
                                  />
                                </td>

                                <td width="125">
                                  <PaymentStatus
                                    payment={payment}
                                    refund={refund}
                                  />
                                </td>

                                <td className="currency muted" width="125">
                                  (
                                  {formatCurrencyRounded(
                                    refund.amount,
                                    refund.currency,
                                  )}
                                  )
                                </td>
                              </tr>
                            ))
                          : []
                        ).reverse(),
                      ])
                      .reverse()}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="view-section-content">
                <div className="order-payment-empty">
                  <span>
                    No transactions have taken place for this order yet
                  </span>
                  <span className="muted">
                    You can collect payment by charging the order below.
                  </span>
                </div>
              </div>
            )}

            {showPaymentTotals && (
              <div className="view-section-content">
                <div className="order-payment-totals">
                  <div className="order-payment-line-item">
                    <p>Total paid</p>
                    <p>{formatCurrencyRounded(totalPaid, currency)}</p>
                  </div>
                  {!record.paid && duePayment > 0 && (
                    <div className="order-payment-line-item">
                      <p>Payment due</p>
                      <p>({formatCurrencyRounded(duePayment, currency)})</p>
                    </div>
                  )}
                  {dueRefund > 0 && (
                    <div className="order-payment-line-item">
                      <p>Refund due</p>
                      <p>({formatCurrencyRounded(dueRefund, currency)})</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {isPaymentActionsAvailable && (
            <div className="view-section-footer">
              <div className="view-section-action-group">
                {isRefundAvailable && (
                  <Button
                    size="sm"
                    type={dueRefund ? 'default' : 'secondary'}
                    onClick={onClickPaymentRefund}
                  >
                    Refund {sourceLabel}
                  </Button>
                )}
                {isChargeAvailable && (
                  <Button
                    size="sm"
                    type={duePayment ? 'default' : 'secondary'}
                    onClick={onClickPaymentCharge}
                  >
                    Charge {sourceLabel}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

OrderPayments.propTypes = {
  record: pt.object,
  content: pt.object,
  settings: pt.object,
  sourceLabel: pt.string,

  onClickPaymentCharge: pt.func,
  onClickPaymentRefund: pt.func,
  onClickEditSingleItem: pt.func,
};

export default React.memo(OrderPayments);
