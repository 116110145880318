import React, { Fragment } from 'react';
import classNames from 'classnames';
import pt from 'prop-types';

import { arrayToObject, isEmpty } from 'utils';
import {
  productName,
  productThumbUrl,
  isProductVariable,
  isBundleItemVariable,
} from 'utils/product';

import Link from 'components/link';
import { Field, Fieldtable, LookupProduct } from 'components/form';
import ProductOptionInputs from 'components/pages/product/option-inputs';

const variableOptions = Object.freeze([
  { value: 'choose', label: 'Customer will choose options' },
  { value: 'specific', label: 'Select options' },
]);

export default class ProductBundle extends React.PureComponent {
  static propTypes = {
    name: pt.string.isRequired,
    label: pt.string,
    record: pt.object.isRequired,
    lookup: pt.object.isRequired,
    defaultValue: pt.array,
  };

  renderBundleHeading = () => {
    return [
      <th key="1" colSpan="2">
        Product
      </th>,
      <th key="2">Options</th>,
      <th key="3">Qty</th>,
    ];
  };

  getProductOption(value, id) {
    const optionsById = arrayToObject(value.options);
    let productOption = optionsById[id];

    if (!value.product_id && !isBundleItemVariable(value)) {
      const productOptions = arrayToObject(value.product.options);
      const optionValues = productOptions[productOption.id].values;
      const optionValue = arrayToObject(optionValues)[productOption.value];

      if (optionValue) {
        productOption = {
          id: optionValue.id,
          value: optionValue.name,
        };
      }
    }

    return productOption;
  }

  renderOptionDescription(value) {
    if (isEmpty(value.options)) {
      return [];
    }

    return value.options
      .map((option) => {
        const productOption = this.getProductOption(value, option.id);
        return (
          productOption && {
            name: productOption.name,
            value: productOption.value,
          }
        );
      })
      .filter(Boolean)
      .map((option, i) => {
        return (
          <span key={i} className="product-options-value">
            {option.value}
          </span>
        );
      });
  }

  renderBundleValue = ({ value, index }) => {
    const customerWillChoose = isBundleItemVariable(value);
    const optionDescription = this.renderOptionDescription(value);

    return [
      <td key="0" className="image">
        <span className="collection-table-images">
          <span className="image">
            <Link to={`/products/${value.product_id}`}>
              <img
                src={productThumbUrl(value.product, value.variant)}
                alt={productName(
                  value.product,
                  value.variant,
                  value.product_name,
                )}
              />
            </Link>
          </span>
        </span>
      </td>,
      <td key="1">
        <Link to={`/products/${value.product_id}`}>
          {productName(value.product, value.variant, value.product_name)}
        </Link>
      </td>,
      <td
        key="2"
        className={classNames('nowrap', {
          'product-options-value-cell': optionDescription.length > 0,
        })}
      >
        {customerWillChoose ? (
          <div>Customer will choose</div>
        ) : optionDescription.length > 0 ? (
          optionDescription
        ) : (
          <div className="muted">&mdash;</div>
        )}
      </td>,
      <td key="3">
        <span>{value.quantity || '1'}</span>
      </td>,
    ];
  };

  renderBundleForm = (value) => {
    const { lookup, record } = this.props;

    const isVariable = isProductVariable(value.product);

    const valueVariable =
      value.variable ||
      (value.options ? 'specific' : isVariable ? 'choose' : 'specific');

    return (
      <fieldset>
        <LookupProduct
          name="product"
          label="Product"
          defaultValue={value.product}
          query={{
            id: { $ne: record.id },
            delivery: { $nin: ['subscription'] },
          }}
          lookup={lookup}
          required={true}
        />

        <Field type="hidden" name="variant" value={value.variant} />

        {isVariable && (
          <Fragment>
            <Field
              type="radio"
              name="variable"
              defaultValue={valueVariable}
              options={variableOptions}
              readonly={true}
            />

            {valueVariable === 'specific' && (
              <ProductOptionInputs
                product={value.product}
                values={value}
                defaultValue={{ options: value.options }}
                custom={false}
                price={false}
              />
            )}
          </Fragment>
        )}

        <div className="row">
          <Field
            type="number"
            name="quantity"
            label="Quantity"
            defaultValue={value.quantity || ''}
            maxValue={1000000}
            placeholder="1"
            className="span1"
          />
        </div>
      </fieldset>
    );
  };

  render() {
    const { name, defaultValue, lookup, label } = this.props;

    return (
      <Fieldtable
        label={label}
        name={name}
        defaultValue={defaultValue}
        renderHeading={this.renderBundleHeading}
        renderValue={this.renderBundleValue}
        renderForm={this.renderBundleForm}
        formWidth={500}
        lookup={lookup}
        sortable={true}
      />
    );
  }
}
