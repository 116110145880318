import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import Help from 'components/tooltip/help';
import IconHelpLink from 'components/icon/helplink';
import Icon from 'components/icon';
import { currencyValueRound } from 'utils/money';
import './account-plans.scss';

import {
  PLANS,
  MAIN_PLANS,
  ALT_PLANS,
  billingPriceByInterval,
} from '../../shared/billing';

export default class AccountPlans extends React.PureComponent {
  static contextTypes = {
    account: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    superAccess: PropTypes.func.isRequired,
  };

  static propTypes = {
    isMonthly: PropTypes.bool,
    onClickSelectPlan: PropTypes.func.isRequired,
  };

  renderFeePercentHelp(plan) {
    return (
      <Help
        message={`Plus ${plan.fee_percent}% billed monthly for paid orders received in the previous month`}
      />
    );
  }

  render() {
    const { onClickSelectPlan, isMonthly = true } = this.props;
    const { account, session } = this.context;

    const currentPlanMetadata =
      (account.plan && !account.plan.canceled && account.plan.metadata) || {};

    const enterprisePlan = find(PLANS, { id: 'enterprise' });

    // Highlight when plan is not active
    const isActive = account.planSelected && !account.plan?.canceled;
    const isSameInterval =
      account.plan?.interval === 'yearly' && !isMonthly
        ? true
        : account.plan?.interval === 'monthly' && isMonthly
        ? true
        : false;
    const highlightPlan = isActive && currentPlanMetadata.id;
    const shouldHighlight = Boolean(
      !isActive || (isSameInterval && highlightPlan),
    );

    return (
      <>
        <div className="account-plans-container">
          {MAIN_PLANS.map((plan) => (
            <div
              key={plan.id}
              className={`account-plans-section ${
                shouldHighlight &&
                (highlightPlan ? plan.id === highlightPlan : plan.highlight)
                  ? 'highlight'
                  : ''
              }`}
            >
              <div className="account-plans-section-heading">
                <h4 className="account-plans-section-title">{plan.name}</h4>
                <p className="account-plans-section-subtitle">
                  {plan.description}
                </p>
                {shouldHighlight &&
                  (highlightPlan
                    ? plan.id === highlightPlan
                    : plan.highlight) && (
                    <span className="badge highlight-badge">
                      {highlightPlan ? 'Your plan' : 'Most popular'}
                    </span>
                  )}
                <div className="account-plans-section-price">
                  {plan.id === enterprisePlan.id ? (
                    <>
                      <span className="amount custom">
                        <a href="https://www.swell.is/for-enterprise">
                          Learn more &rarr;
                        </a>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="symbol">$</span>
                      <span className="amount">
                        {currencyValueRound(
                          billingPriceByInterval(
                            plan,
                            isMonthly ? 'monthly' : 'yearly',
                            1,
                            account.record,
                          ) / (isMonthly ? 1 : 12),
                          'USD',
                        )}
                        <span className="period">/mo</span>
                      </span>
                      <span className="period">
                        &nbsp;
                        {plan.fee_percent > 0 &&
                          this.renderFeePercentHelp(plan)}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="account-plans-section-button">
                {plan.id === enterprisePlan.id ? (
                  <>
                    {session.is_super_admin ? (
                      <a
                        href=""
                        className="button button-sm button-default"
                        onClick={onClickSelectPlan}
                        data-id={plan.id}
                      >
                        Select plan (admin)
                      </a>
                    ) : (
                      <a
                        href="https://www.swell.is/for-enterprise#contact-form"
                        target="blank"
                        className="button button-sm button-secondary"
                        data-id={plan.id}
                      >
                        Contact us
                      </a>
                    )}
                  </>
                ) : currentPlanMetadata.id === plan.id && shouldHighlight ? (
                  <a
                    href=""
                    className="button button-sm button-secondary"
                    onClick={onClickSelectPlan}
                    data-id={plan.id}
                  >
                    Current plan
                  </a>
                ) : (
                  <a
                    href=""
                    className="button button-sm button-default"
                    onClick={onClickSelectPlan}
                    data-id={plan.id}
                  >
                    Select plan
                  </a>
                )}
              </div>
              <div className="account-plans-section-items">
                {(
                  (currentPlanMetadata.id === plan.id &&
                  (!plan.items || !plan.items.length)
                    ? currentPlanMetadata.items
                    : plan.items) || []
                ).map((item) => (
                  <div key={item.label} className="nowrap">
                    {item.value ? (
                      <b>{item.value}</b>
                    ) : (
                      item.icon && <Icon fa={item.icon} className="positive" />
                    )}{' '}
                    {item.label}
                    {item.tooltip && <Help message={item.tooltip} />}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="account-plans-container alt">
          {ALT_PLANS.map((plan) => (
            <div className="account-plans-alt box" key={plan.id}>
              <div className="box-title">
                {plan.enterprise ? (
                  plan.name
                ) : (
                  <>
                    <div>
                      {plan.name}&nbsp;
                      <span className="symbol">$</span>
                      <span className="amount">
                        {currencyValueRound(
                          billingPriceByInterval(
                            plan,
                            isMonthly ? 'monthly' : 'yearly',
                            1,
                            account.record,
                          ) / (isMonthly ? 1 : 12),
                          'USD',
                        )}
                        <span className="period">/mo</span>
                      </span>
                      <span className="period">
                        &nbsp;
                        {plan.fee_percent > 0 &&
                          this.renderFeePercentHelp(plan)}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="box-subtitle">
                {plan.description}{' '}
                {!plan.enterprise && (
                  <Help
                    message={
                      plan.items?.length > 0 &&
                      `
                          Includes 
                          ${plan.items
                            .map(
                              (item) =>
                                `${
                                  item.value
                                    ? `${
                                        item.value
                                      } ${item.label.toLowerCase()}`
                                    : item.tooltip
                                }`,
                            )
                            .join(' and ')}
                        `
                    }
                  />
                )}
              </div>
              <div className="box-action">
                {plan.enterprise ? (
                  <a
                    href="https://www.swell.is/contact"
                    target="blank"
                    className="button button-sm button-secondary"
                    data-id={plan.id}
                  >
                    Talk to sales
                  </a>
                ) : currentPlanMetadata.id === plan.id ? (
                  <a
                    href=""
                    className="button button-sm button-secondary"
                    onClick={onClickSelectPlan}
                    data-id={plan.id}
                  >
                    Current plan
                  </a>
                ) : (
                  <a
                    href=""
                    className="button button-sm button-default"
                    onClick={onClickSelectPlan}
                    data-id={plan.id}
                  >
                    Select plan
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="account-plans-container alt">
          <div className="view-help-link">
            <a
              href="https://www.swell.is/pricing"
              target="blank"
              className="help-link"
            >
              <IconHelpLink />
              <div>
                Learn more about <b>plan features</b>
              </div>
            </a>
          </div>
        </div>
      </>
    );
  }
}
