import React from 'react';
import pt from 'prop-types';
import Delayed from 'react-delayed';
import { find, cloneDeep } from 'lodash';

import { isEmpty, classNames, objectToArray } from 'utils';

import { Form, Field } from 'components/form';
import ButtonLink from 'components/button/link';
import Checkbox from 'components/icon/checkbox';
import { FadeLoading, MoveUp } from 'components/transitions';
import Pagination from 'components/collection/pagination';
import CollectionSort from 'components/collection/sort';
import CollectionEmpty from 'components/collection/empty';
import CollectionFilter from 'components/collection/filter';
import ViewPreferencesMenu from 'components/view/preferences-menu';
import { CollectionRowField, fieldLinkUrl } from './table';
import Icon from 'components/icon';

import './collection.scss';

export default class Collection extends React.PureComponent {
  static contextTypes = {
    client: pt.object.isRequired,
    withPreferences: pt.bool,
    fields: pt.array,
    clientFields: pt.array,
    swellFields: pt.array,
    cleanSaveField: pt.func,
  };

  static propTypes = {
    collection: pt.object,
    location: pt.object.isRequired,
    router: pt.object.isRequired,
    bulkActions: pt.array,
    fields: pt.oneOfType([pt.array, pt.object]),
    filters: pt.oneOfType([pt.array, pt.object]),
    onSubmitSearch: pt.func,
  };

  constructor(props) {
    super(props);

    const enhancedFields = this.getEnhancedFields(props.fields);

    this.state = {
      activeFilters: [],
      enhancedFields,
    };
  }

  componentDidUpdate(prevProps) {
    const changedLocation = prevProps.location !== this.props.location;

    if (changedLocation || this.props.fields !== prevProps.fields) {
      const enhancedFields = this.getEnhancedFields(this.props.fields);

      this.setState({
        enhancedFields,
      });
    }
  }

  onSubmitSearch = (values) => {
    this.props.onSubmitSearch(values);
    this.refs.search.getInputRef().select();
  };

  onKeyDownSearchEscape = (event) => {
    if (event.keyCode === 27) {
      this.props.onSubmitSearch({ search: '' });
      this.refs.search.setState({ value: '' });
    }
  };

  onChangeActiveFilters = (activeFilters) => {
    this.setState({ activeFilters });
  };

  onClickRow = async (event) => {
    const { collection, router, uri, linkUri, recordUri, onClickRow } =
      this.props;
    const { index, url } = event.currentTarget.dataset;
    const record = collection.results[index];

    const isLinkTarget = event.target.tagName === 'A';

    let linkUrl;
    if (url && isLinkTarget) {
      linkUrl = url;
    } else if (recordUri) {
      linkUrl = fieldLinkUrl(recordUri, record);
    } else if (linkUri || uri) {
      linkUrl = `${linkUri || uri}/${record.id}`;
    }

    if (!isLinkTarget) {
      if (onClickRow) {
        const result = await onClickRow(record, url);
        if (result === false) {
          return;
        }
      }

      if (linkUrl) {
        (document.getElementById('main') || window).scrollTop = 0;
        router.push(linkUrl);
      }
    }
  };

  getEnhancedFields(fields) {
    const enhancedFields = cloneDeep(objectToArray(fields));

    // Add app logos and stuff
    /* for (const field of enhancedFields) {
      const app = appsById[field.app_id];
      if (app) {
        field.label = (
          <>
            {field.label}
            <AppIcon
              image={app.logo_icon}
              name={app.name}
              size={16}
            />
          </>
        );
      }
    } */

    return enhancedFields;
  }

  onClickTab = (tab) => {
    const { location, locationQuery: lq, router } = this.props;
    const locationQuery = lq || location.query;

    // TODO: props.onQuery

    if (locationQuery.tab !== tab) {
      router.push(
        locationWithQuery(location, {
          tab: tab === 'default' ? undefined : tab,
        }),
      );
    }

    // TODO: show options when clicked while already on tab
  };

  renderTabDescription() {
    const { tabs, location, locationQuery: lq } = this.props;
    const locationQuery = lq || location.query;

    if (!locationQuery.tab) return null;
    const tab = find(tabs, { id: locationQuery.tab });

    return (
      tab && (
        <span>
          &nbsp;
          {tab.label.toLowerCase()}
        </span>
      )
    );
  }

  render() {
    const {
      title,
      collection,
      loading,
      location,
      locationQuery: lq,
      filters,
      selectable,
      selection = {},
      searchable = true,
      headless = false,
      bulkActions,
      onChangeSearch,
      onClickSelectAll,
      onClickSelectClear,
      onClickSelectRow,
      onClickSort,
      onClickPage,
      onChangeLimit,
      onClickBulkAction,
      collectionEmpty,
    } = this.props;

    const { activeFilters, enhancedFields } = this.state;

    const {
      withPreferences,
      fields: userFields,
      clientFields,
      swellFields,
      cleanSaveField,
    } = this.context;

    const locationQuery = lq || location.query;

    const titleLower = title?.toLowerCase();
    const visibleFields = enhancedFields.filter((field) => !field.hidden);
    const sort =
      typeof locationQuery.sort === 'string' ? locationQuery.sort : '';
    const [sortField, sortDir] = sort.split(/[\s|+]/);

    return (
      <div className="collection-container">
        {searchable && !collectionEmpty && (
          <div className="collection-search">
            <Form onSubmit={this.onSubmitSearch} onChange={onChangeSearch}>
              <Field
                name="search"
                className="collection-search-field"
                placeholder={`Search ${titleLower}`}
                defaultValue={locationQuery.search}
                value={undefined}
                onKeyDown={this.onKeyDownSearchEscape}
                autoComplete="off"
                ref="search"
              />

              {!isEmpty(filters) && (
                <CollectionFilter
                  {...this.props}
                  filters={filters}
                  onChangeActiveFilters={this.onChangeActiveFilters}
                />
              )}
            </Form>
          </div>
        )}

        {selectable && !collectionEmpty && (
          <ul className="collection-selection">
            {selection.count > 0 && <li>{selection.count} selected</li>}

            {selection.count > 0 && (
              <li>
                <button onClick={onClickSelectClear} type="button">
                  Clear selection
                </button>
              </li>
            )}
          </ul>
        )}

        <FadeLoading active={loading} className="view-loading-mask" />

        {collection.count > 0 ? (
          <div className="collection-table-container">
            <table
              className={classNames('collection-table outer', {
                'collection-table-hoverable': collection.count > 0,
                headless,
              })}
            >
              {!headless && (
                <thead>
                  <tr>
                    {selectable && (
                      <th key="table-select" className="select">
                        <Checkbox
                          onClick={
                            selection.count > 0
                              ? onClickSelectClear
                              : onClickSelectAll
                          }
                          checked={
                            selection.all &&
                            Object.keys(selection.except).length === 0
                          }
                          minus={
                            (!selection.all ||
                              Object.keys(selection.except).length > 0) &&
                            selection.count > 0
                          }
                        />
                      </th>
                    )}

                    {visibleFields.reduce((list, field) => {
                      if (field.columns) {
                        list.push(
                          ...field.columns.map((col, index) => (
                            <th key={col.id || index} className={col.type}>
                              {field.sort !== false && col.label ? (
                                <CollectionSort
                                  field={field.id}
                                  label={col.label}
                                  dir={sortDir}
                                  sorted={field.id === sortField}
                                  onClick={onClickSort}
                                />
                              ) : (
                                col.label
                              )}
                            </th>
                          )),
                        );
                      } else {
                        list.push(
                          <th
                            key={field.id}
                            className={field.template ? 'string' : field.type}
                          >
                            {field.sort !== false ? (
                              <CollectionSort
                                field={field.id}
                                label={field.label}
                                dir={sortDir}
                                sorted={field.id === sortField}
                                onClick={onClickSort}
                              />
                            ) : (
                              field.label
                            )}
                          </th>,
                        );
                      }

                      return list;
                    }, [])}

                    {withPreferences && (
                      <th key="table-action" className="action">
                        <ViewPreferencesMenu
                          sideOffset={6}
                          buttonChildren={<Icon svgType="settings-01" />}
                          preferenceKey="fields"
                          items={userFields}
                          clientItems={clientFields}
                          swellItems={swellFields}
                          cleanSaveItem={cleanSaveField}
                        />
                      </th>
                    )}
                  </tr>
                </thead>
              )}
              <tbody>
                {collection.results.length > 0 ? (
                  collection.results.map((record, index) => (
                    <tr
                      key={record.id || index}
                      {...(isRecordSelected(selection, record.id)
                        ? { className: 'selected' }
                        : {})}
                    >
                      {selectable && (
                        <td key="table-select" className="select">
                          <Checkbox
                            animated={false}
                            onClick={onClickSelectRow}
                            data-id={record.id}
                            checked={isRecordSelected(selection, record.id)}
                          />
                        </td>
                      )}

                      {visibleFields.reduce((list, field) => {
                        if (field.columns) {
                          list.push(
                            ...field.columns.map((col, idx) => (
                              <CollectionRowField
                                key={col.id || idx}
                                id={col.id}
                                record={record}
                                field={col}
                                onClick={this.onClickRow}
                                index={index}
                              />
                            )),
                          );
                        } else {
                          list.push(
                            <CollectionRowField
                              key={field.id}
                              id={field.id}
                              record={record}
                              field={field}
                              onClick={this.onClickRow}
                              index={index}
                            />,
                          );
                        }

                        return list;
                      }, [])}

                      {withPreferences && (
                        <td key="table-action" className="action" />
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={visibleFields.length + (selectable ? 1 : 0)}
                      align="center"
                    >
                      <br />
                      <span className="muted">
                        There are no {titleLower} on this page.
                      </span>
                      &nbsp;
                      <button data-page="1" onClick={onClickPage} type="button">
                        Return to page 1
                      </button>
                      <br />
                      <br />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : collectionEmpty ? (
          <CollectionEmpty {...this.props} />
        ) : (
          <CollectionEmpty
            {...this.props}
            className="with-query"
            emptyTitle={false}
            emptyDescription={
              <span>
                No
                {this.renderTabDescription()}
                &nbsp;{titleLower} found
                {locationQuery.search && (
                  <span>&nbsp;matching "{locationQuery.search}"</span>
                )}
                {activeFilters.length > 0 && (
                  <span>&nbsp;matching filters</span>
                )}
              </span>
            }
            emptyAction={false}
          />
        )}

        {onClickPage && (
          <Pagination
            collection={collection}
            title={titleLower}
            onClickPage={onClickPage}
            onChangeLimit={onChangeLimit}
          />
        )}

        <Delayed mounted={true} mountAfter={selection.count > 0 ? 500 : 0}>
          {bulkActions && bulkActions.length > 0 && selection.count > 0 && (
            <MoveUp
              className={`collection-bulk-actions ${
                selection.count ? 'visible' : ''
              }`}
              active={bulkActions && selection.count > 0}
              transitionAppear={true}
            >
              <span className="collection-bulk-actions-shadow" />
              <div className="collection-bulk-actions-inner">
                {bulkActions.map(
                  (action, index) =>
                    (action.condition ? action.condition(selection) : true) && (
                      <ButtonLink
                        key={index}
                        size="sm"
                        type={action.type}
                        onClick={onClickBulkAction}
                        data-action={index}
                      >
                        {action.label}
                      </ButtonLink>
                    ),
                )}
              </div>
            </MoveUp>
          )}
        </Delayed>
      </div>
    );
  }
}

function isRecordSelected(selection, id) {
  if (selection && selection.records) {
    if (selection.all) {
      if (selection.except[id]) {
        return false;
      }

      return true;
    }

    if (selection.records[id]) {
      return true;
    }
  }

  return false;
}
