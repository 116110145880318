import React from 'react';
import pt from 'prop-types';

import Status from 'components/status';
import Tooltip from 'components/tooltip';

function PaymentStatus({ payment, refund }) {
  const record = refund || payment;

  if (record.upcoming) {
    if (!record.error) {
      return <Status>Upcoming</Status>;
    }

    return (
      <Tooltip message={record.error}>
        <Status type="negative">
          Error
          <span className="help icon icon-info">&nbsp;</span>
        </Status>
      </Tooltip>
    );
  }

  if (record.void) {
    return <Status type="warning">Void</Status>;
  }

  if (record.success && refund) {
    return <Status type="warning">Refunded</Status>;
  }

  if (record.success && record.captured !== false) {
    return <Status type="positive">Paid</Status>;
  }

  if (record.error) {
    return (
      <Tooltip message={record.error.message}>
        <Status type="negative">
          Error
          <span className="help icon icon-info-error">&nbsp;</span>
        </Status>
      </Tooltip>
    );
  }

  if (refund) {
    return (
      <Tooltip message="This refund is waiting to be processed">
        <Status>
          Pending
          <span className="help icon icon-info">&nbsp;</span>
        </Status>
      </Tooltip>
    );
  }

  if (payment.async) {
    if (payment.paypal_intent === 'capture') {
      return (
        <Tooltip message="Payment will be captured after completing onboarding">
          <Status>
            Pending
            <span className="help icon icon-info">&nbsp;</span>
          </Status>
        </Tooltip>
      );
    }

    if (payment.async_data) {
      return (
        <Tooltip message="Awaiting customer confirmation">
          <Status>
            Pending
            <span className="help icon icon-info">&nbsp;</span>
          </Status>
        </Tooltip>
      );
    }
  } else {
    if (record.authorized_amount && record.captured === false) {
      return <Status type="positive">Authorized</Status>;
    }

    if (record.authorized || (record.success && record.captured === false)) {
      return <Status>Authorized</Status>;
    }
  }

  return <Status>Pending</Status>;
}

PaymentStatus.propTypes = {
  payment: pt.object,
  refund: pt.object,
};

export default React.memo(PaymentStatus);
