import React, { Fragment } from 'react';
import { find } from 'lodash';
import { Form, Field, Button } from 'components/form';
import ButtonLink from 'components/button/link';
import { FadeInUp } from 'components/transitions';
import Flash from 'components/flash';
import Icon from 'components/icon';
import LoadingBar from 'components/loading/bar';
import LogoTopMiddle from 'components/logo/logo-top-middle';
import UserPhoto from 'components/user/photo';
import StoreIcon from 'components/store/icon';
import './oauth.scss';

const OAUTH_PARAMS = {
  client_id: 'Your application ID provided by Swell',
  redirect_uri: 'The location to redirect a user after authorization',
  scope: 'A string representing permissions being requested',
  state: 'A randomly selected value unique for each request',
};

export default class AuthorizePage extends React.PureComponent {
  onClickCancel = () => {
    const { router, oauth } = this.props;
    if (window.opener && window.opener !== window) {
      // may be a popup
      window.close();
    } else if (oauth.referrer) {
      window.location.href = oauth.referrer;
    } else {
      router.push('/');
    }
  };

  validateParams() {
    const {
      location: { query },
    } = this.props;
    const invalidParams = Object.keys(OAUTH_PARAMS).filter(
      (key) => !query[key],
    );
    return invalidParams.length ? invalidParams : null;
  }

  validateCodeChallengeMethod() {
    const {
      location: { query },
    } = this.props;
    if (
      query.code_challenge_method &&
      ['plain', 'S256'].indexOf(query.code_challenge_method) === -1
    ) {
      return true;
    }
    return false;
  }

  renderInvalidParams(invalidParams) {
    return (
      <div className="oauth-errors">
        <div className="oauth-scopes">
          <div className="oauth-scopes-title">
            Your authorization request is missing the following:
          </div>
          <ul className="oauth-scopes-list">
            {invalidParams.map((param) => (
              <li>
                <Icon fa="times" faType="light" />
                <code>{param}</code>
                <br />
                <span className="note muted">{OAUTH_PARAMS[param]}</span>
              </li>
            ))}
          </ul>
        </div>
        <ButtonLink
          type="secondary"
          size="sm"
          href="https://developers.swell.is"
        >
          View documentation <Icon fa="external-link" />
        </ButtonLink>
      </div>
    );
  }

  renderInvalidCodeChallenge() {
    return (
      <div className="oauth-errors">
        <div className="oauth-scopes">
          <div className="oauth-scopes-title">
            Your authorization code challenge is invalid:
          </div>
          <ul className="oauth-scopes-list">
            <li>
              <Icon fa="times" faType="light" />
              <code>code_challenge_method</code>
              <br />
              <span className="note muted">
                Method must be either `plain` or `S256`
              </span>
            </li>
          </ul>
        </div>
        <ButtonLink
          type="secondary"
          size="sm"
          href="https://developers.swell.is"
        >
          View documentation <Icon fa="external-link" />
        </ButtonLink>
      </div>
    );
  }

  renderScopePermission(perm) {
    const [action, ...res] = perm.split('_');
    const resource = res.join('_');
    switch (action) {
      case 'read':
        return `View all ${resource}`;
      case 'write':
        return `View and manage ${resource}`;
      case 'public':
        return 'Access to display storefront content';
      default:
        return `Permission: ${perm}`;
    }
  }

  render() {
    const {
      oauth: { app, user, scope, error },
      location: { pathname, search, query },
      flash,
      onSubmitForm,
    } = this.props;

    const invalidParams = this.validateParams();
    const invalidCodeChallenge = this.validateCodeChallengeMethod();
    const loginRedirect = encodeURIComponent(
      `${pathname}${encodeURIComponent(search)}`,
    );
    const loginUrl = `/login?to=${loginRedirect}`;

    return (
      <div className={`oauth-container ${!user ? 'center' : ''}`}>
        <LoadingBar />
        <Flash {...flash} />
        <div className="oauth-wrapper">
          <div className="oauth-wrapper-left">
            <div className="oauth-logo">
              <LogoTopMiddle />
            </div>
            <div className="oauth-form">
              <FadeInUp>
                {invalidParams ? (
                  this.renderInvalidParams(invalidParams)
                ) : invalidCodeChallenge ? (
                  this.renderInvalidCodeChallenge()
                ) : (
                  <Fragment>
                    {!app ? (
                      error ? (
                        <div className="oauth-errors">
                          <div className="oauth-header">
                            <div className="oauth-header-title">
                              Authorization error:
                            </div>
                            <div>{error.message}</div>
                          </div>
                        </div>
                      ) : (
                        <div className="oauth-errors">
                          <div className="oauth-header">
                            <div className="oauth-header-title">
                              The app you're looking for does not exist (
                              {query.client_id})
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <Fragment>
                        <div className="oauth-header">
                          {app.logo_icon && (
                            <img
                              src={`${app.logo_icon.url}?width=160&height=160`}
                              alt={app.name}
                              className="oauth-header-icon"
                            />
                          )}
                          <div className="oauth-header-title">
                            {app.partner_url ? (
                              <a href={app.partner_url} target="blank">
                                {app.name}
                              </a>
                            ) : (
                              app.name
                            )}{' '}
                            would like access to your Swell store
                          </div>
                        </div>
                        {!user ? (
                          <div className="oauth-actions center">
                            <ButtonLink to={loginUrl} size="md" type="default">
                              Log in to continue
                            </ButtonLink>
                          </div>
                        ) : (
                          <Form onSubmit={onSubmitForm}>
                            <fieldset>
                              {!query.store_id &&
                                user.authorizations.length > 1 && (
                                  <div className="row">
                                    <Field
                                      type="select"
                                      label="Select a store"
                                      name="store_id"
                                      options={user.authorizations.map(
                                        (userAuth) => ({
                                          value: userAuth.client_id,
                                          label: userAuth.client_name,
                                        }),
                                      )}
                                      defaultValue={
                                        user.authorizations[0].client_id
                                      }
                                      required={true}
                                      renderIcon={(client_id) => (
                                        <StoreIcon
                                          auth={
                                            find(user.authorizations, {
                                              client_id,
                                            })}
                                          size={24}
                                          text={false}
                                        />
                                      )}
                                      iconWidth={47}
                                      className="span4 oauth-store-select"
                                    />
                                  </div>
                                )}
                              <div className="oauth-scopes">
                                <div className="oauth-scopes-title">
                                  {app.name} will receive the following
                                </div>
                                <ul className="oauth-scopes-list">
                                  <li>
                                    <Icon fa="check" faType="light" /> Basic
                                    store details
                                  </li>
                                  {scope.permissions.map((perm) => (
                                    <li key={perm}>
                                      <Icon fa="check" faType="light" />
                                      {this.renderScopePermission(perm)}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className="oauth-actions">
                                <Button
                                  type="secondary"
                                  onClick={this.onClickCancel}
                                  size="md"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type="submit"
                                  styleType="primary"
                                  size="md"
                                >
                                  Allow
                                </Button>
                              </div>
                            </fieldset>
                            <div className="oauth-footer">
                              <div className="oauth-user">
                                <div className="oauth-user-name">
                                  {user.name}
                                </div>
                                <div className="oauth-user-email muted">
                                  {user.username}
                                </div>
                                <div className="oauth-user-login">
                                  <a href={loginUrl}>Use another account</a>
                                </div>
                                <UserPhoto user={user} />
                              </div>
                            </div>
                          </Form>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </FadeInUp>
            </div>
          </div>
          <div className="oauth-wrapper-right" />
        </div>
      </div>
    );
  }
}
