import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';

export default class Bar extends React.PureComponent {
  static propTypes = {
    x: PropTypes.string.isRequired,
    y: PropTypes.string.isRequired,
    dataId: PropTypes.number.isRequired,
    fill: PropTypes.string,
  };

  render() {
    const {
      data,
      x,
      y,
      fill,
      xScales,
      yScale,
      margin,
      dataId,
      height,
      offsetWidth,
      offsetHeight,
      pointColors,
    } = this.props;

    const translate = `translate(${margin.left + offsetWidth}, ${margin.top})`;
    const calculateHeight = (point) =>
      height -
      yScale(point[y] || 0.2) -
      margin.top -
      margin.bottom -
      offsetHeight;
    const fillColor = fill || pointColors[dataId];

    const bars = find(data, { id: dataId }).points.map((point, index) => (
      <rect
        key={index}
        fill={fillColor}
        x={xScales[dataId](point[x])}
        y={yScale(point[y] || 0.2)}
        width={xScales[dataId].bandwidth ? xScales[dataId].bandwidth() : 0}
        className="chart-bar-item"
        height={calculateHeight(point) > 0 ? calculateHeight(point) : 0}
      />
    ));

    return <g transform={translate}>{bars}</g>;
  }
}
