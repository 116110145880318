import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { FadeIn } from 'components/transitions';
import Label from '../Label';
import { rgbToHex } from 'utils/form';
import $ from 'jquery';
import './ColorPicker.scss';

const { textContrast } = require('text-contrast');

class ColorPicker extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
  };

  state = {
    showingPicker: false,
  };

  componentDidUpdate(_prevProps, prevState) {
    if (!prevState.showingPicker && this.state.showingPicker) {
      document.addEventListener('mousedown', this.onClickWhenPickerVisible);
    } else if (prevState.showingPicker && !this.state.showingPicker) {
      document.removeEventListener('mousedown', this.onClickWhenPickerVisible);
    }
  }

  onClickWhenPickerVisible = (event) => {
    if (!$(event.target).closest(this.refs.colorPicker).length) {
      this.setState({ showingPicker: false });
    }
  };

  onClickShowPicker = (event) => {
    event.preventDefault();
    this.setState({ showingPicker: !this.state.showingPicker });
  };

  onChangeComplete = (value) => {
    const event = new Event('change', { bubbles: true });
    const { r, g, b, a } = value.rgb;
    const nextValue = a < 1 ? `rgba(${r},${g},${b},${a})` : rgbToHex(r, g, b);
    this.props.onChange && this.props.onChange(event, nextValue);
  };

  onClickClose = (event) => {
    event.preventDefault();
    this.setState({ showingPicker: false });
  };

  render() {
    const { value, label, className = '' } = this.props;
    const { showingPicker } = this.state;

    return (
      <div className={`ColorPicker ${className}`}>
        <div onClick={this.onClickShowPicker}>
          <Label>{label}</Label>
          <div
            className="ColorPicker-preview"
            style={{ backgroundColor: value || 'rgba(255, 255, 255, 0.2)' }}
          >
            {value && (
              <span className={textContrast.isLightOrDark(value)}>{value}</span>
            )}
          </div>
        </div>
        {showingPicker && (
          <FadeIn className="ColorPicker-picker-container">
            <a
              href=""
              onClick={this.onClickClose}
              className="ColorPicker-picker-close"
            >
              &times;
            </a>
            <div ref="colorPicker">
              <SketchPicker
                className="ColorPicker-picker-color"
                color={value}
                onChangeComplete={this.onChangeComplete}
              />
            </div>
          </FadeIn>
        )}
      </div>
    );
  }
}

export default ColorPicker;
