import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import { Field } from 'components/form';
import Icon from 'components/icon';
import StoreIcon from 'components/store/icon';

import {
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuLabel,
} from 'components/dropdown-menu';

export default class NavStoreMenu extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onClickSwitch: PropTypes.func.isRequired,
    onClickSwitchAccount: PropTypes.func.isRequired,
  };

  static propTypes = {
    showStoreMenu: PropTypes.bool.isRequired,
    onClickNavStore: PropTypes.func.isRequired,
    onClickCreateStore: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { showStoreMenu } = this.props;

    const isShowingMenu = nextProps.showStoreMenu && !showStoreMenu;
    const isHidingMenu = !nextProps.showStoreMenu && showStoreMenu;

    if (isShowingMenu) {
      document.addEventListener('click', this.onClickWhenShowingMenu);
    } else if (isHidingMenu) {
      document.removeEventListener('click', this.onClickWhenShowingMenu);
    }
  }

  onClickWhenShowingMenu = (event) => {
    if (!$(event.target).closest(this.refs.userActionList).length > 0) {
      this.props.onClickNavStore(event);
    }
  };

  onClickEnv = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onChangeTestEnv(!this.props.testMode);
  };

  renderStore(auth, index) {
    if (!auth) {
      return null;
    }

    const { onClickNavStore } = this.props;
    const { client, onClickSwitchAccount } = this.context;

    return (
      <DropdownMenuItem key={index} asChild>
        <button
          key={auth.client_id}
          data-id={auth.client_id}
          className="box-section login-switch-section"
          onClick={
            auth.client_id === client.id
              ? onClickNavStore
              : onClickSwitchAccount
          }
          type="button"
        >
          <span className="login-switch-icon">
            <StoreIcon auth={auth} size={24} />
          </span>
          <span className="login-switch-body">{auth.client_name}</span>
          {auth.client_id === client.id && (
            <span className="login-switch-check">
              <Icon fa="check" />
            </span>
          )}
        </button>
      </DropdownMenuItem>
    );
  }

  render() {
    const { onClickCreateStore, toggleStoreMenu, testMode } = this.props;
    const { client, account, user } = this.context;
    const { canGoLive, hasTestEnvironment } = account;

    return (
      <div ref="userActionList">
        {canGoLive && hasTestEnvironment && (
          <>
            <DropdownMenuItem onClick={this.onClickEnv}>
              <div className="nav-store-toggle">
                <div className={`nav-store-env ${testMode ? 'muted' : ''}`}>
                  Live
                </div>
                <div className="nav-store-menu-inner">
                  <Field
                    type="toggle"
                    label="Test"
                    labelOff="Test"
                    className="smaller"
                    checked={!!testMode}
                    inactive={true}
                  />
                </div>
              </div>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
          </>
        )}
        {Array.isArray(user.authorizations) &&
          user.authorizations.length > 1 && (
            <>
              <DropdownMenuLabel>Stores</DropdownMenuLabel>
              {this.renderStore(
                user.authorizations.find(
                  (auth) => auth.client_id === client.id,
                ),
              )}
              {user.authorizations
                .filter((auth) => auth.client_id !== client.id)
                .map((auth, index) => this.renderStore(auth, index))}
              <DropdownMenuSeparator />
            </>
          )}
        <DropdownMenuItem
          onClick={() => {
            onClickCreateStore();
            toggleStoreMenu();
          }}
        >
          Create a new store
        </DropdownMenuItem>
      </div>
    );
  }
}
