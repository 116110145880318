import React from 'react';
import { startCase } from 'lodash';
import Tooltip from 'components/tooltip';
import { formatDate } from 'utils';

export default function SubscriptionStatus({ record }) {
  const {
    status,
    active,
    canceled,
    cancel_at_end,
    date_canceled,
    paused,
    draft,
  } = record;
  return (
    <span
      className={`status ${
        canceled ||
        status === 'pastdue' ||
        status === 'void' ||
        status === 'canceled'
          ? 'negative'
          : status === 'trial' || status === ''
          ? 'positive'
          : paused && !canceled
          ? ''
          : status === 'active' || status === 'complete'
          ? 'positive'
          : 'muted'
      }`}
    >
      <span className="status-dot" />
      {!active && canceled && date_canceled ? (
        <Tooltip
          message={`Canceled ${formatDate(date_canceled, 'shortExact')}`}
          delayed
        >
          Canceled
        </Tooltip>
      ) : status === 'pastdue' ? (
        'Past due'
      ) : canceled && cancel_at_end ? (
        'Canceled'
      ) : paused && !canceled ? (
        'Paused'
      ) : draft ? (
        'Draft'
      ) : (
        startCase(status)
      )}
    </span>
  );
}
