import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import unset from 'lodash/fp/unset';
import assoc from 'lodash/fp/assoc';
import get from 'lodash/get';
import set from 'lodash/set';

import { isEmpty, classNames } from 'utils';

import { FadeLoading } from 'components/transitions';

import './form.scss';

const { NODE_ENV } = process.env;

const READY_TIMEOUT = NODE_ENV !== 'test' ? 500 : 0;
const SCROLL_OFFSET = 200;

/**
 * Convert field value to its type
 *
 * @param {React.Component} component field component
 * @param {any} value field value
 * @returns {any}
 */
export function ensureValueType(component, value) {
  if (value !== undefined) {
    switch (component.props['data-type'] || component.props.type) {
      case 'checkbox': {
        if (component.props.options) {
          if (Array.isArray(value)) {
            return value;
          }

          if (!isEmpty(value)) {
            return [value];
          }

          return [];
        }

        // If value is passed, then it should be returned instead of boolean
        return typeof component.props.value === 'undefined' &&
          typeof component.props.defaultValue === 'undefined'
          ? Boolean(value)
          : value;
      }

      case 'toggle':
        // If value is passed, then it should be returned instead of boolean
        return typeof component.props.value === 'undefined' &&
          typeof component.props.defaultValue === 'undefined'
          ? Boolean(value)
          : value;

      case 'number':
      case 'currency': {
        if (typeof value === 'string' && value.trim().length > 0) {
          value = Number(value);
        }

        return Number.isFinite(value) ? value : null;
      }

      case 'time':
      case 'date':
      case 'datetime': {
        if (typeof value === 'string' || typeof value === 'number') {
          return new Date(value);
        }

        break;
      }

      default: {
        if (value === null) {
          return '';
        }

        break;
      }
    }
  }

  return value;
}

function isInputFieldValid(field) {
  return Boolean(field.inputRef?.current ?? field.refs?.input);
}

function findFirstError(fields) {
  const list =
    fields instanceof Map ? Array.from(fields.values()) : Object.values(fields);

  for (const field of list) {
    const isEmptyFields =
      field.fields instanceof Map
        ? field.fields.size <= 0
        : isEmpty(field.fields);

    const invalidField = isEmptyFields
      ? field.state.error && isInputFieldValid(field) && field
      : findFirstError(field.fields);

    if (invalidField) {
      return invalidField;
    }
  }

  return null;
}

export function getComponentValue(component) {
  return typeof component.value === 'function'
    ? component.value()
    : component.state.value;
}

/**
 * Immutable version of set
 */
function immutableSet(object, path, value) {
  return assoc(path, value, object);
}

/**
 * Immutable version of unset
 */
function immutableUnset(object, path) {
  return unset(path, object);
}

export default class Form extends React.Component {
  static propTypes = {
    values: PropTypes.object,
    loading: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    autoFocus: PropTypes.bool,
    autoFocusEmpty: PropTypes.bool,

    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onBeforeValidation: PropTypes.func,
  };

  static childContextTypes = {
    submit: PropTypes.func,
    formValues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    setFormValue: PropTypes.func,
    subscribeChange: PropTypes.func,
    unsubscribeChange: PropTypes.func,
    registerField: PropTypes.func,
    unregisterField: PropTypes.func,
    registerButton: PropTypes.func,
    unregisterButton: PropTypes.func,
    onChangeField: PropTypes.func,
  };

  getChildContext() {
    return {
      submit: this.submit,
      formValues: this.values,
      setFormValue: this.setValue,
      subscribeChange: this.subscribeChange,
      unsubscribeChange: this.unsubscribeChange,
      registerField: this.registerField,
      unregisterField: this.unregisterField,
      registerButton: this.registerButton,
      unregisterButton: this.unregisterButton,
      onChangeField: this.onChangeField,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      initLoading: Boolean(props.loading),
      loading: Boolean(props.loading),
    };

    this.edited = new Set();
    this.fields = {};
    this.fieldsExtra = new Map();
    this.listeners = new Map();
    this.mounted = false;
    this.ready = false;
    this.isEdited = false;

    if (props.values) {
      this.values = cloneDeep(props.values);
      this.origValues = cloneDeep(props.values);
    } else {
      this.values = {};
      this.origValues = {};
    }

    this.readyTimer = 0;
    this.submitTimer = 0;
    this.autoFocusTimer = 0;
    this.focusFirstFieldTimer = 0;
    this.focusFirstErrorTimer = 0;
  }

  static getDerivedStateFromProps(props, state) {
    const loading = Boolean(props.loading);

    if (loading !== state.initLoading) {
      return { loading, initLoading: loading };
    }

    return null;
  }

  componentDidMount() {
    this.mounted = true;

    this.handleFormReady();

    if (this.props.autoFocus) {
      this.focusFirstField();
    }
  }

  componentWillUnmount() {
    this.mounted = false;

    if (this.readyTimer) {
      clearTimeout(this.readyTimer);
    }

    if (this.submitTimer) {
      clearTimeout(this.submitTimer);
    }

    if (this.autoFocusTimer) {
      clearTimeout(this.autoFocusTimer);
    }

    if (this.focusFirstFieldTimer) {
      clearTimeout(this.focusFirstFieldTimer);
    }

    if (this.focusFirstErrorTimer) {
      clearTimeout(this.focusFirstErrorTimer);
    }
  }

  handleFormReady() {
    const { onChange } = this.props;

    this.ready = false;

    this.readyTimer = setTimeout(() => {
      this.readyTimer = 0;

      if (this.mounted) {
        this.ready = true;

        if (onChange) {
          onChange(this.values, false);
        }
      }
    }, READY_TIMEOUT);
  }

  subscribeChange = (id, onChange) => {
    this.listeners.set(id, onChange);
  };

  unsubscribeChange = (id) => {
    this.listeners.delete(id);
  };

  registerField = (component, onChange) => {
    const { name } = component.props;

    // Track extra fields to affect edited check when removing
    if (this.fields[name]) {
      let list = this.fieldsExtra.get(name);

      if (list === undefined) {
        list = [];

        this.fieldsExtra.set(name, list);
      }

      list.push(component);
    } else {
      this.fields[name] = component;

      if (typeof onChange === 'function') {
        this.listeners.set(name, onChange);
      }

      // Trigger changeField listener when field is registered dynamically after the initial form registration.
      if (this.ready) {
        this.onChangeField(component);
      }

      // Add value to origValues for edit detection
      this.setValue(
        name,
        ensureValueType(component, getComponentValue(component)),
        undefined,
        true,
      );
    }

    if (this.ready && component.props.autoFocus && component.focus) {
      this.autoFocusTimer = setTimeout(() => {
        this.autoFocusTimer = 0;

        if (getComponentValue(component)) {
          component.setState({ autoFocusMounted: true }, () => {
            component.focus();
          });
        } else {
          component.focus();
        }
      }, 200);
    }
  };

  unregisterField = (component) => {
    const { name } = component.props;
    /** @type {Array<object> | undefined} */
    const extra = this.fieldsExtra.get(name);

    if (this.fields[name] === component) {
      if (extra) {
        this.fields[name] = extra.shift();
      } else {
        // Remove value from origValues for edit detection
        this.setValue(name, undefined, undefined, true);

        delete this.fields[name];
        this.listeners.delete(name);
        this.onRemoveField(component);
      }
    } else if (extra) {
      const index = extra.indexOf(component);

      if (index !== -1) {
        extra.splice(index, 1);

        const field = extra.length > index ? extra[index] : extra[index - 1];

        this.onChangeField(field || this.fields[name]);
      }
    }

    // Clear extra fields if none left
    if (extra && extra.length === 0) {
      this.fieldsExtra.delete(name);
    }
  };

  registerButton = (component) => {
    this.button = component;
  };

  unregisterButton = (component) => {
    delete this.button;
  };

  onChangeForm() {
    // Handled by onChangeField
  }

  onChangeField = (component) => {
    if (!component) {
      return;
    }

    const { name } = component.props;

    const value = ensureValueType(component, get(this.values, name));
    const newValue = ensureValueType(component, getComponentValue(component));

    // Short circuit
    if (!name || isEqual(value, newValue)) {
      return;
    }

    const isDefined = Boolean(
      this.props.values || get(this.origValues, name) !== undefined,
    );
    const isChanged = isDefined && !isEqual(value, newValue);

    this.setValue(name, newValue, value);

    if (isChanged) {
      if (!this.state.loading) {
        this.setEdited(component);
      }
    } else if (isDefined) {
      return;
    }

    const onChange = this.listeners.get(name);

    if (onChange) {
      onChange(this.values);
    }

    if (this.ready && (isChanged || !isDefined)) {
      if (!this.state.loading) {
        const { onChange } = this.props;

        if (onChange) {
          onChange(this.values, this.isEdited);
        }
      }
    }
  };

  onRemoveField(component) {
    const { name } = component.props;

    if (!name || !this.mounted) {
      return;
    }

    const origValue = get(this.origValues, name);

    if (origValue !== undefined) {
      this.values = immutableSet(this.values, name, origValue);
    } else {
      this.values = immutableUnset(this.values, name);
      this.setEdited(component, true);

      // Non-blocking update as cascading changes can occur
      Promise.resolve(name).then((name) => {
        const onChange = this.listeners.get(name);

        if (onChange) {
          onChange(this.values);
        }

        if (this.ready) {
          const { onChange } = this.props;

          if (onChange) {
            onChange(this.values, this.isEdited);
          }
        }
      });
    }
  }

  setValue = (name, newValue, beforeValue = undefined, setOrig = false) => {
    this.values = immutableSet(this.values, name, cloneDeep(newValue));

    if (!this.ready || this.props.values || setOrig) {
      const origValue = get(this.origValues, name);

      if (origValue === undefined || setOrig) {
        const value = beforeValue !== undefined ? beforeValue : newValue;
        set(this.origValues, name, cloneDeep(value));
      }
    }
  };

  setEdited(component, removed = false) {
    const { name } = component.props;

    if (component.readonly || component.props.readonly) {
      return this.isEdited;
    }

    const origValue = ensureValueType(component, get(this.origValues, name));

    if (removed) {
      origValue !== undefined
        ? this.edited.add(name)
        : this.edited.delete(name);
    } else {
      const newValue = ensureValueType(component, getComponentValue(component));

      isEqual(origValue, newValue)
        ? this.edited.delete(name)
        : this.edited.add(name);
    }

    this.isEdited = this.edited.size > 0;
  }

  submit = () => {
    return this.refs.form.dispatchEvent(
      new Event('submit', { cancelable: true }),
    );
  };

  onSubmit = async (event) => {
    event.preventDefault();
    // Prevent parent form submit
    event.stopPropagation();

    if (this.state.loading) {
      return;
    }

    const { onSubmit, onBeforeValidation } = this.props;

    if (typeof onBeforeValidation === 'function') {
      await onBeforeValidation(this);
    }

    if (!this.validate()) {
      this.focusFirstError();
      return;
    }

    const values = {};

    for (const [name, component] of Object.entries(this.fields)) {
      if (!component.readonly && !component.props.readonly) {
        set(values, name, getComponentValue(component));
      }
    }

    // Callback may return a promise to indicate loading
    if (onSubmit) {
      const result = onSubmit(values);

      if (result && typeof result.then === 'function') {
        this.setLoading(true);

        this.submitTimer = setTimeout(() => {
          this.submitTimer = 0;

          result.then((success) => {
            if (!this.mounted) {
              return;
            }

            success === false ? this.focusFirstField() : this.reset();

            this.setLoading(false);
          });
        }, 250);
      }
    } else {
      this.reset();
    }
  };

  reset() {
    this.ready = false;
    this.edited.clear();
    this.isEdited = false;
    this.values = cloneDeep(this.props.values || {});
    this.origValues = cloneDeep(this.props.values || {});

    for (const component of Object.values(this.fields)) {
      this.onChangeField(component);
    }

    this.handleFormReady();
  }

  setLoading(isLoading) {
    this.setState({ loading: isLoading });

    // each(this.fields, component => component.disabled(isLoading));

    if (this.button) {
      this.button.loading(isLoading);
    }
  }

  getInputRef(component) {
    let input = null;

    if (!isEmpty(component.fields)) {
      const fields =
        component.fields instanceof Map
          ? Array.from(component.fields.values())
          : Object.values(component.fields);

      for (const field of fields) {
        input = this.getInputRef(field);

        if (input && input.type === 'hidden') {
          input = null;
        }

        if (input) {
          break;
        }
      }
    } else {
      input = component.inputRef?.current ?? component.refs.input;
    }

    while (input) {
      if (typeof input.getWrappedInstance === 'function') {
        input = input.getWrappedInstance();
      } else if (input.inputRef?.current) {
        input = input.inputRef.current;
      } else if (input.refs?.input) {
        input = input.refs.input;
      } else {
        break;
      }
    }

    if (typeof input?.focus === 'function') {
      return input;
    }

    return null;
  }

  validate() {
    let isValid = true;

    for (const component of Object.values(this.fields)) {
      if (typeof component.validate !== 'function') continue;
      // need to reset the readonly property,
      // since during the previous validation, the component could be invalid and invisible
      component.readonly = component.props.readonly;

      const isComponentValid = component.validate();

      if (!isComponentValid) {
        const inputRef = this.getInputRef(component);

        const isInputVisible = inputRef && inputRef.offsetParent;

        if (isInputVisible) {
          isValid &= isComponentValid;
        } else {
          component.readonly = true;
        }
      }
    }

    return isValid;
  }

  focusFirstField() {
    this.focusFirstFieldTimer = setTimeout(() => {
      this.focusFirstFieldTimer = 0;

      const firstField = Object.values(this.fields).find((field) => {
        return (
          isInputFieldValid(field) &&
          field.props.type !== 'hidden' &&
          field.props.type !== 'radio' &&
          field.props.type !== 'toggle' &&
          !field.props.disabled &&
          (this.props.autoFocusEmpty ||
            ((field.state.value === null || field.state.value === '') &&
              field.props.autoFocus !== false))
        );
      });

      if (firstField) {
        const inputRef = this.getInputRef(firstField);

        if (inputRef) {
          inputRef.focus();
        }
      }
    }, READY_TIMEOUT);
  }

  focusFirstError() {
    this.focusFirstErrorTimer = setTimeout(() => {
      this.focusFirstErrorTimer = 0;

      const firstError = findFirstError(this.fields);

      if (firstError) {
        const inputRef = this.getInputRef(firstError);

        if (inputRef) {
          this.scrollToAnchor(inputRef);
          inputRef.focus();
        }
      }
    }, READY_TIMEOUT);
  }

  scrollToAnchor(field = null) {
    const scrollParent =
      $(field).closest('.modal').get(0) ||
      document.getElementById('main') ||
      $('html, body').get(0);

    const scrollTop =
      $(field).offset().top -
      $(scrollParent).offset().top +
      scrollParent.scrollTop -
      SCROLL_OFFSET;

    $(scrollParent).animate({ scrollTop }, 750);
  }

  render() {
    const {
      values,
      loading,
      children,
      className,
      autoFocusEmpty,
      onBeforeValidation,
      ...attrs
    } = this.props;

    return (
      <form
        autoComplete="off"
        {...attrs}
        onSubmit={this.onSubmit}
        onChange={this.onChangeForm}
        ref="form"
        className={classNames('form', className)}
        data-testid="rtl-form"
      >
        <FadeLoading
          active={this.state.loading}
          className="view-loading-mask"
        />

        <a ref="anchor" />

        {typeof children === 'function' ? children(this.values) : children}

        <button type="submit" className="button-submit-hidden" />
      </form>
    );
  }
}
