export const FLASH_ERROR = 'flash/error';
export const FLASH_WARNING = 'flash/warning';
export const FLASH_SUCCESS = 'flash/success';
export const FLASH_CLEAR = 'flash/clear';

let flashTimer = null;

export default {
  error(message, timeout = undefined) {
    return setMessage(FLASH_ERROR, message, timeout);
  },

  warning(message, timeout = undefined) {
    return setMessage(FLASH_WARNING, message, timeout);
  },

  success(message, timeout = undefined) {
    return setMessage(FLASH_SUCCESS, message, timeout);
  },

  clear() {
    return {
      type: FLASH_CLEAR,
    };
  },

  cancelAuto() {
    clearTimeout(flashTimer);
  },
};

export const initialState = {
  error: null,
  warning: null,
  success: null,
  visible: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case FLASH_ERROR:
      return {
        error: action.payload,
        visible: true,
      };

    case FLASH_WARNING:
      return {
        warning: action.payload,
        visible: true,
      };

    case FLASH_SUCCESS:
      return {
        success: action.payload,
        visible: true,
      };

    case FLASH_CLEAR:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

function setMessage(type, message, timeout = 7000) {
  return (dispatch) => {
    dispatch({
      type,
      payload: message,
    });
    clearTimeout(flashTimer);
    flashTimer = setTimeout(() => dispatch({ type: FLASH_CLEAR }), timeout);
  };
}
