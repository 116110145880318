// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BulkCategories from 'components/bulk/categories';
import actions from 'actions';
import { inflect } from 'utils';
import { bulkCategoriesRemove } from 'utils/bulk';

const mapStateToProps = (state) => ({
  bulk: state.data.bulk,
  selection: state.data.selection,
  model: state.data.model,
  query: state.data.query,
  locationQuery: state.data.locationQuery,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  loadCategories: async () => {
    return dispatch(actions.categories.load());
  },

  searchCategories: async (search: string) => {
    return dispatch(actions.categories.search(search, 10));
  },

  bulkCancel: () => {
    return dispatch(actions.data.bulkCancel());
  },

  bulkUpdateSelected: (handler: Function) => {
    return dispatch(actions.data.bulkUpdateSelected('products', handler));
  },
});

export class BulkCategoriesAddModal extends React.PureComponent {
  static contextTypes = {
    notifySuccess: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  state = {};

  constructor(props: Object, context: Object) {
    super(props, context);
    this.state = {
      loaded: false,
      onClose: this.onClose.bind(this),
      onSubmit: this.onSubmit.bind(this),
    };
  }

  componentWillMount() {
    const { loadCategories } = this.props;

    loadCategories().then(() => {
      this.setState({ loaded: true });
    });
  }

  onClose(event) {
    this.context.closeModal();
    this.props.bulkCancel();
  }

  onSubmit({ categories }) {
    const { bulkUpdateSelected } = this.props;

    bulkUpdateSelected((record) =>
      bulkCategoriesRemove(record, categories),
    ).then((num) => {
      this.context.notifySuccess(`Updated ${inflect(num, 'products')}`);
      this.context.closeModal();
      this.props.bulkCancel();
    });
  }

  render() {
    return (
      <BulkCategories
        title={`Remove from categories`}
        hint={`${inflect(
          this.props.selection.count,
          'products',
        )} will be removed from select categories`}
        {...this.props}
        {...this.state}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkCategoriesAddModal);
