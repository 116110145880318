import React, { Fragment } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import pt from 'prop-types';

import { inflect } from 'utils';
import { couponCodeSingle } from 'utils/discount';

import Link from 'components/link';
import { Field, FieldLocalized } from 'components/form';
import Modal from 'components/modal';
import DiscountRules from 'components/discount/rules';
import BulkProgress from 'components/bulk/progress';
import ContentFields from 'components/content/fields';
import { FadeIn } from 'components/transitions';
import SectionHeader from 'components/section-header';

import './coupon.scss';

// Not pure
export default class CouponForm extends React.Component {
  static propTypes = {
    bulk: pt.object,
    record: pt.object,
    values: pt.object,
    errors: pt.object,
    lookup: pt.object,
    settings: pt.object.isRequired,
    content: pt.object.isRequired,
    categories: pt.object,
    generatingCodes: pt.bool,
    generatingCount: pt.number,

    onChangeForm: pt.func,
    onSubmitGenerateMore: pt.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showingGenerateMore: false,
      generateMoreError: null,
    };
  }

  onClickGenerateMore = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showingGenerateMore: !state.showingGenerateMore,
      generateMoreError: null,
    }));
  };

  onCloseGenerateMore = () => {
    this.setState({ showingGenerateMore: false });
  };

  onClickGenerateMoreSubmit = (event) => {
    event.preventDefault();
    const { generate } = this.props.values;
    if (generate && generate.count > 0) {
      this.setState({ showingGenerateMore: false });
      this.props.onSubmitGenerateMore();
    } else {
      this.setState({
        generateMoreError: 'You should generate at least 1 coupon code',
      });
    }
  };

  renderGenerateMore() {
    return (
      <Modal
        title="Generate more codes"
        width={400}
        actions={[
          {
            label: 'Generate',
            type: 'default',
            onClick: this.onClickGenerateMoreSubmit,
          },
          {
            label: 'Cancel',
            type: 'cancel',
            onClick: this.onClickGenerateMore,
          },
        ]}
        onClose={this.onCloseGenerateMore}
        cancel={false}
      >
        <fieldset>
          <Field
            type="number"
            name="generate.count"
            label="How many more coupon codes to generate?"
            required={true}
            readonly={true}
            error={this.state.generateMoreError}
            placeholder="Up to 10,000"
          />
        </fieldset>
      </Modal>
    );
  }

  renderGenerationProgress() {
    const {
      bulk: { percent },
      generatingCount,
    } = this.props;

    return (
      <Modal
        title={`Generating ${inflect(generatingCount, 'codes')}`}
        width={300}
        cancel={false}
        tapout={false}
        closable={false}
      >
        <fieldset>
          <BulkProgress percent={percent} />
        </fieldset>
      </Modal>
    );
  }

  render() {
    const {
      record = {},
      errors = {},
      values = {},
      settings,
      content,
      categories,
      lookup,
    } = this.props;

    const codeValue = couponCodeSingle(record, values);

    const hasGroups = !isEmpty(settings.accounts?.groups);

    const checkLimitUses =
      values.check_limit_uses !== undefined
        ? !!values.check_limit_uses
        : record.limit_uses > 0;

    const checkLimitCodeUses =
      values.check_limit_code_uses !== undefined
        ? !!values.check_limit_code_uses
        : record.limit_code_uses > 0;

    const checkLimitSubscriptionUses =
      values.check_limit_subscription_uses !== undefined
        ? !!values.check_limit_subscription_uses
        : record.limit_subscription_uses > 0;

    const checkLimitAccountUses =
      values.check_limit_account_uses !== undefined
        ? !!values.check_limit_account_uses
        : record.limit_account_uses > 0;

    const checkLimitAccountGroups =
      hasGroups && values.check_limit_account_groups !== undefined
        ? !!values.check_limit_account_groups
        : !isEmpty(record.limit_account_groups);

    return (
      <div className="coupon-form">
        <div className="view-body-section-group">
          <div className="view-body-section">
            <div className="view-section-content no-header">
              <fieldset className="full">
                {!record.id && (
                  <Field
                    type="radio"
                    name="multi_codes"
                    buttons={true}
                    options={[
                      { value: '', label: 'Single code' },
                      { value: 'true', label: 'Multiple codes' },
                    ]}
                    defaultValue={''}
                  />
                )}

                <div className="row">
                  {values.multi_codes ? (
                    <FieldLocalized
                      type="text"
                      name="name"
                      label="Coupon name"
                      defaultValue={record.name}
                      localeValue={record.$locale}
                      error={errors.name}
                      required={true}
                      className="span2"
                      help="May be visible to customers in their shopping cart"
                    />
                  ) : (
                    <Field
                      type="text"
                      name="code"
                      label="Coupon code"
                      defaultValue={codeValue}
                      error={errors.code}
                      required={true}
                      rules="coupon_code"
                      placeholder="SAVEBIG"
                      className="span2"
                    />
                  )}

                  {settings.discounts?.features?.groups &&
                    settings.discounts?.groups && (
                      <Field
                        type="select"
                        label="Discount group"
                        name="group"
                        defaultValue={record.group || ''}
                        error={errors.group}
                        options={settings.discounts.groups}
                        placeholder="Optional"
                        className="span2"
                        help="Only the highest value discount from the same group will apply"
                      />
                    )}
                </div>

                <FieldLocalized
                  type="textarea"
                  label="Description"
                  name="description"
                  defaultValue={record.description}
                  localeValue={record.$locale}
                  error={errors.description}
                  placeholder="Optional"
                  rows={2}
                />
              </fieldset>
            </div>
          </div>
          <div className="view-body-section">
            <div className="view-body-subheader">
              <SectionHeader className="view-body-subtitle" title="Rules" />
            </div>
            <div className="view-section-content">
              <DiscountRules
                record={record}
                values={values}
                categories={categories}
                lookup={lookup}
                settings={settings}
                onChangeForm={this.props.onChangeForm}
              />
            </div>
          </div>

          {values.multi_codes && (
            <fieldset className="full">
              {record.id ? (
                <div>
                  <div className="view-body-subheader">
                    <h4 className="view-body-subtitle">Coupon codes</h4>

                    <div className="view-body-actions">
                      <Link to={`/coupons/${record.id}/codes`}>
                        View all ({record.codes.count})
                      </Link>

                      <a href="" onClick={this.onClickGenerateMore}>
                        Generate more
                      </a>
                    </div>
                  </div>

                  {record.codes.count > 0 ? (
                    <div>
                      <table className="collection-table outer">
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Used</th>
                          </tr>
                        </thead>

                        <tbody>
                          {record.codes.results.map((code) => (
                            <tr key={code.id}>
                              <td>{code.code}</td>
                              <td>{code.use_count || 0}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="collection-none-found">
                      Coupon doesn&apos;t have any codes yet
                    </div>
                  )}
                </div>
              ) : (
                <div className="row">
                  <Field
                    type="number"
                    name="generate.count"
                    label="How many coupon codes to generate?"
                    required={true}
                    placeholder="Up to 10,000"
                    className="span2"
                  />
                </div>
              )}
            </fieldset>
          )}

          <div className="view-body-section">
            <div className="view-body-subheader">
              <SectionHeader
                className="view-body-subtitle"
                title="Usage limits"
              />
            </div>
            <div className="view-section-content">
              <fieldset className="full">
                {(!values.multi_codes || record.limit_uses > 0) && (
                  <Fragment>
                    <div className="row">
                      <Field
                        type="checkbox"
                        name="check_limit_uses"
                        readonly={true}
                        label={`Limit number of times this coupon can be used${
                          values.multi_codes ? ' across all codes' : ''
                        }`}
                        defaultChecked={checkLimitUses}
                        className="snug"
                      />
                    </div>

                    {checkLimitUses && (
                      <FadeIn
                        transitionAppear={values.check_limit_uses !== undefined}
                      >
                        <div className="row indented">
                          <Field
                            type="number"
                            name="limit_uses"
                            defaultValue={record.limit_uses}
                            placeholder="0"
                            required={true}
                            selectFocus={true}
                            autoFocus={true}
                            className="span1 snug2"
                          />
                        </div>
                      </FadeIn>
                    )}
                  </Fragment>
                )}

                {(values.multi_codes || record.limit_code_uses > 0) && (
                  <Fragment>
                    <div className="row">
                      <Field
                        type="checkbox"
                        name="check_limit_code_uses"
                        readonly={true}
                        label="Limit coupon to one use per unique code"
                        defaultChecked={checkLimitCodeUses}
                      />
                    </div>
                    {checkLimitCodeUses && (
                      <Field type="hidden" name="limit_code_uses" value={1} />
                    )}
                  </Fragment>
                )}

                <div className="row">
                  <Field
                    type="checkbox"
                    name="check_limit_account_uses"
                    readonly={true}
                    label={
                      <span>
                        Limit coupon to one use per customer{' '}
                        <span className="muted">(tracked by email)</span>
                      </span>
                    }
                    defaultChecked={checkLimitAccountUses}
                    className="snug"
                  />
                </div>

                {checkLimitAccountUses && (
                  <Field type="hidden" name="limit_account_uses" value={1} />
                )}

                {hasGroups && (
                  <Fragment>
                    <div className="row">
                      <Field
                        type="checkbox"
                        name="check_limit_account_groups"
                        readonly={true}
                        label="Limit coupon to specific customer groups"
                        defaultChecked={checkLimitAccountGroups}
                        className="snug"
                      />
                    </div>

                    <FadeIn
                      active={checkLimitAccountGroups}
                      transitionAppear={false}
                    >
                      <div className="row indented">
                        <Field
                          type="checkbox"
                          name="limit_account_groups"
                          options={settings.accounts.groups}
                          defaultValue={record.limit_account_groups}
                          readonly={!checkLimitAccountGroups}
                          className="span4 snug2"
                        />
                      </div>
                    </FadeIn>
                  </Fragment>
                )}

                <Fragment>
                  <div className="row">
                    <Field
                      type="checkbox"
                      name="check_limit_subscription_uses"
                      readonly={true}
                      label="Limit number of times this coupon will apply to a subscription cycle"
                      defaultChecked={checkLimitSubscriptionUses}
                      className="snug"
                      help="The coupon will not apply to future orders or invoices from an individual subscription after the specified number of billing cycles."
                    />
                  </div>

                  {checkLimitSubscriptionUses && (
                    <FadeIn
                      transitionAppear={
                        values.check_limit_subscription_uses !== undefined
                      }
                    >
                      <div className="row indented">
                        <Field
                          type="number"
                          name="limit_subscription_uses"
                          defaultValue={record.limit_subscription_uses}
                          placeholder="0"
                          required={true}
                          selectFocus={true}
                          autoFocus={true}
                          className="span1 snug2"
                        />
                      </div>
                    </FadeIn>
                  )}
                </Fragment>
              </fieldset>
            </div>
          </div>

          <div className="view-body-section">
            <div className="view-body-subheader">
              <SectionHeader
                className="view-body-subtitle"
                title="Active dates"
              />
              <div className="view-body-actions">
                {record.id && (
                  <div className="row">
                    <Field
                      type="toggle"
                      name="active"
                      label="Enabled"
                      defaultChecked={record.active}
                      error={errors.active}
                      className="span2"
                      help="Enabled coupon can be used within active dates"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="view-section-content">
              <div>
                <div
                  className={classNames({
                    'form-muted': record.id && !values.active,
                  })}
                >
                  <fieldset className="full">
                    <div className="row">
                      <Field
                        type="date"
                        name="date_valid"
                        label="Valid from"
                        defaultValue={record.date_valid}
                        defaultNow={true}
                        className="span2"
                      />

                      <Field
                        type="time"
                        name="date_valid_time"
                        label="Valid from time"
                        defaultValue={record.date_valid}
                        className="span2"
                      />
                    </div>

                    <div className="row">
                      <Field
                        type="checkbox"
                        name="set_date_expired"
                        label="Set expiration date"
                        readonly={true}
                        defaultChecked={!!record.date_expired}
                      />
                    </div>

                    {values.set_date_expired ||
                    (values.set_date_expired === undefined &&
                      !!record.date_expired) ? (
                      <FadeIn transitionAppear={!!values.set_date_expired}>
                        <div className="row">
                          <Field
                            type="date"
                            name="date_expired"
                            label="Expiration date"
                            defaultValue={record.date_expired}
                            required={true}
                            className="span2"
                            autoFocus={true}
                          />
                          <Field
                            type="time"
                            name="date_expired_time"
                            label="Expiration time"
                            defaultValue={record.date_expired}
                            className="span2"
                          />
                        </div>
                      </FadeIn>
                    ) : (
                      <Fragment>
                        <Field type="hidden" name="date_expired" value="" />
                        <Field
                          type="hidden"
                          name="date_expired_time"
                          value=""
                        />
                      </Fragment>
                    )}
                  </fieldset>
                </div>
              </div>
            </div>
          </div>

          <ContentFields
            {...this.props}
            zone="content"
            collection="coupons"
            models={content.models}
            record={record}
            values={values}
            currency={record.currency}
          />
        </div>

        {this.state.showingGenerateMore && this.renderGenerateMore()}

        {this.props.generatingCodes && this.renderGenerationProgress()}
      </div>
    );
  }
}
