import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import actions from 'actions';

import NewPage from 'components/giftcard/new';
import ViewLoading from 'components/view/loading';

export const mapStateToProps = (state) => ({
  data: state.data,
  loading: state.data.loading,
  errors: state.data.recordErrors,
  settings: state.settings,
});

export const mapDispatchToProps = (dispatch) => ({
  createRecord: async (data) => {
    return dispatch(actions.data.createRecord('giftcards', data));
  },

  loadSettings() {
    return dispatch(actions.settings.fetch('giftcards'));
  },
});

export class NewGiftcard extends React.Component {
  static contextTypes = {
    notifyCreated: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      values: {},
      loaded: false,
      edited: false,
      onChangeForm: this.onChangeForm.bind(this),
      onSubmitForm: this.onSubmitForm.bind(this),
    };
  }

  componentDidMount() {
    this.props.loadSettings().then(() => {
      this.setState({ loaded: true });
    });
  }

  onChangeForm(values) {
    this.setState({
      values: { ...this.state.values, ...values },
      edited: true,
    });
  }

  async onSubmitForm(values) {
    const { router, createRecord } = this.props;

    const result = await createRecord(values);

    if (result.errors) {
      this.context.notifyError(result.errors);
      return;
    }

    this.context.notifyCreated(result);
    router.replace(`/giftcards/${result.id}`);
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return <NewPage {...this.props} {...this.state} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewGiftcard);
