import React from 'react';
import each from 'lodash/each';
import pt from 'prop-types';

import ListStates from './list-states';
import ListCategory from './list-item';

export default class ListCategories extends React.PureComponent {
  static propTypes = {
    index: pt.object,
    found: pt.object,
    foundParent: pt.bool,
    categories: pt.array,
    versions: pt.object,

    moveCategories: pt.func,
    onClickEditCategory: pt.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
    };

    this.dragSourceId = '';
    this.dragTargetNode = null;
  }

  /**
   * @param {HTMLElement} targetNode
   * @param {boolean} isOver
   */
  setDropzoneClass(targetNode, isOver) {
    const parentNode = this.getParentNode(targetNode);

    if (parentNode) {
      const id = parentNode.dataset.categoryid;
      parentNode.counter = parentNode.counter || 0;
      parentNode.counter += isOver ? 1 : -1;

      if (parentNode.counter <= 0) {
        parentNode.classList.remove('sibling', 'child', 'over');
        parentNode.counter = 0;
      }

      if (targetNode.dataset.sibling) {
        const dropzone = parentNode.getElementsByClassName(
          `category-dropzone sibling ${id}`,
        )[0];

        if (!isOver || parentNode.dataset.siblingid !== this.dragSourceId) {
          isOver
            ? dropzone.classList.add('over')
            : dropzone.classList.remove('over');
        }

        if (parentNode.counter > 0 && isOver) {
          parentNode.classList.add('sibling', 'over');
        }
      }

      if (targetNode.dataset.child) {
        const dropzone = parentNode.getElementsByClassName(
          `category-dropzone child ${id}`,
        )[0];

        if (!isOver || parentNode.dataset.childid !== this.dragSourceId) {
          isOver
            ? dropzone.classList.add('over')
            : dropzone.classList.remove('over');
        }

        if (parentNode.counter > 0 && isOver) {
          parentNode.classList.add('child', 'over');
        }
      }
    }
  }

  resetDropzoneClasses() {
    const dropzones = document.body.getElementsByClassName(
      'category-dropzone over',
    );

    if (dropzones.length > 0) {
      each(dropzones, (dropzone) => dropzone.classList.remove('over'));
    }
  }

  setDraggingClass(targetNode, isDragging) {
    const parentNode = this.getParentNode(targetNode);

    if (parentNode) {
      isDragging
        ? parentNode.classList.add('dragging')
        : parentNode.classList.remove('dragging');
    }
  }

  /** @param {HTMLElement | null} targetNode */
  getParentNode(targetNode) {
    let parentNode = targetNode;
    while (parentNode !== null && parentNode.tagName !== 'LI') {
      parentNode = parentNode.parentNode;
    }
    return parentNode;
  }

  categoriesHaveFoundChildren(categories, found) {
    for (const category of categories) {
      if (found.index.has(category.id)) {
        return true;
      }

      if (this.categoriesHaveFoundChildren(category.children || [], found)) {
        return true;
      }
    }

    return false;
  }

  /** @param {React.DragEvent} event */
  onDragStart = (event) => {
    this.dragSourceId = event.currentTarget.dataset.categoryid;

    this.setDraggingClass(event.currentTarget, true);

    event.dataTransfer.effectAllowed = 'move';

    this.setState({ dragging: true });
  };

  /** @param {React.DragEvent} event */
  onDragEnd = (event) => {
    this.setState({ dragging: false });

    if (this.dragTargetNode) {
      const { dragSourceId } = this;
      const dragTargetId = this.dragTargetNode.dataset.categoryid;

      this.props.moveCategories(
        dragSourceId,
        dragTargetId,
        this.dragTargetNode,
      );

      this.setDropzoneClass(this.dragTargetNode, false);
    }

    this.setDraggingClass(event.currentTarget, false);
    this.resetDropzoneClasses();

    this.dragTargetNode = null;
    this.dragSourceId = '';
  };

  /** @param {React.DragEvent} event */
  onDragEnter = (event) => {
    const { currentTarget } = event;
    const dragTargetId = currentTarget.dataset.categoryid;

    if (this.dragSourceId === dragTargetId) {
      return;
    }

    this.dragTargetNode = currentTarget;
    this.setDropzoneClass(currentTarget, true);
  };

  /** @param {React.DragEvent} event */
  onDragLeave = (event) => {
    const { currentTarget, relatedTarget } = event;

    if (
      relatedTarget !== null &&
      currentTarget.dataset.categoryid !== relatedTarget.dataset?.categoryid
    ) {
      this.dragTargetNode = null;
    }

    this.setDropzoneClass(currentTarget, false);
  };

  renderCategories = (
    categories,
    found,
    foundParent = false,
    closedParent = false,
  ) => {
    const { index: indexMap, versions, onClickEditCategory } = this.props;

    return (
      <ul>
        {categories.map((item, index, categories) => {
          const { id, children = [] } = item;

          let foundSelf = false;
          let foundChildren = false;

          if (found.query) {
            foundSelf = this.categoriesHaveFoundChildren([{ id }], found);

            foundChildren = this.categoriesHaveFoundChildren(
              [{ children }],
              found,
            );

            if (!foundParent && !foundSelf && !foundChildren) {
              return <ListStates key={id ?? index} item={item} />;
            }
          }

          const childrenCount = children.reduce(
            (acc, category) => acc + category.product_count,
            0,
          );

          const indexCategory = indexMap.get(id);

          const parentProductsCount =
            indexCategory && indexCategory.product_count - childrenCount > 0
              ? indexCategory.product_count - childrenCount
              : 0;

          let hasNonDraggingChildren = false;

          if (children.length > 0) {
            if (children.length === 1) {
              if (children[0].id !== this.dragSourceId) {
                hasNonDraggingChildren = true;
              }
            } else {
              hasNonDraggingChildren = true;
            }
          }

          const siblingId =
            categories.length > index && categories[index + 1]
              ? categories[index + 1].id
              : undefined;

          const childId = children.length > 0 ? children[0].id : undefined;

          return (
            <ListCategory
              key={id ?? index}
              item={item}
              found={found}
              foundSelf={foundSelf}
              foundChildren={foundChildren}
              foundParent={foundParent}
              closedParent={closedParent}
              // the `version` is only needed to redraw the component after the move is complete
              version={versions.get(id)}
              siblingId={siblingId}
              childId={childId}
              childrenCount={childrenCount}
              parentProductsCount={parentProductsCount}
              hasNonDraggingChildren={hasNonDraggingChildren}
              onClickEditCategory={onClickEditCategory}
              renderCategories={this.renderCategories}
              onDragStart={this.onDragStart}
              onDragEnd={this.onDragEnd}
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
            />
          );
        })}
      </ul>
    );
  };

  render() {
    const { categories, found, foundParent = false } = this.props;

    return (
      <div className={this.state.dragging ? 'dragging' : ''}>
        {this.renderCategories(categories, found, foundParent)}
      </div>
    );
  }
}
