import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import DashboardStats from './stats';
import DashboardNext from './next';
import './dashboard.scss';
import {
  MIGRATION_ACTIONS_BY_TYPE,
  STOREFRONT_ACTIONS_BY_TYPE,
  FRAMEWORK_ACTIONS_BY_TYPE,
  HOSTING_ACTIONS_BY_TYPE,
} from './configs';

export default class Dashboard extends React.PureComponent {
  static contextTypes = {
    account: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
  };

  state = {
    actions: [],
    nextSteps: [],
  };

  componentWillMount() {
    this.setState({ ...this.mapActionState(this.props, this.context) });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.dashboard !== nextProps.dashboard) {
      this.setState({ ...this.mapActionState(nextProps, nextContext) });
    }
  }

  mapActionState(props, context) {
    const actions = this.getInitialActions(props, context);
    const nextSteps = actions.filter(
      (action) => !props.dashboard.setup[action.key],
    );
    return { actions, nextSteps };
  }

  getInitialActions(props, context) {
    const { dashboard, client } = props;
    const signupState = get(
      client,
      'leads.results[0].signup_state',
      client.signup || {},
    );
    const { storefront, migration, framework, hosting } = signupState;

    return [
      (!migration || migration === 'none') && {
        icon: {
          fa: 'cube',
        },
        key: 'products',
        title: 'Create your first product',
        buttons: [
          {
            key: 'button-create-product',
            action: 'Create product',
            link: '/products/new',
            isInternalLink: true,
          },
        ],
      },
      !storefront && {
        icon: {
          fa: 'desktop',
        },
        key: 'storefront',
        title: 'Configure a storefront',
        subtitle: 'Build your headless frontend with a modern tech stack',
        buttons: [
          {
            key: 'button-activate-store',
            action: 'Configure storefront',
            link: '/storefronts/new',
            isInternalLink: true,
          },
        ],
      },
      {
        icon: {
          fa: 'credit-card',
        },
        key: 'payments',
        title: 'Set up a payment gateway',
        subtitle: `Accept payments via credit card, PayPal, Amazon Pay, and more`,
        buttons: [
          {
            key: 'button-connect-gateway',
            action: 'Connect gateway',
            link: '/settings/payments',
            isInternalLink: true,
          },
        ],
      },
      migration && migration !== 'none'
        ? MIGRATION_ACTIONS_BY_TYPE.default
        : null,
      MIGRATION_ACTIONS_BY_TYPE[migration] || null,
      STOREFRONT_ACTIONS_BY_TYPE[storefront] || null,
      ...(storefront === 'custom'
        ? [
            FRAMEWORK_ACTIONS_BY_TYPE.default,
            FRAMEWORK_ACTIONS_BY_TYPE[framework] || null,
          ]
        : []),
      ...(storefront === 'custom'
        ? [
            HOSTING_ACTIONS_BY_TYPE.default,
            HOSTING_ACTIONS_BY_TYPE[hosting] || null,
          ]
        : []),
    ]
      .filter((x) => !!x)
      .sort((a, b) => (dashboard.setup[a.key] ? -1 : 1));
  }

  render() {
    const { dashboard } = this.props;
    const { nextSteps } = this.state;

    return (
      <div className="dashboard">
        <DashboardNext {...this.props} {...this.state} />
        {(dashboard.hasOrders || !nextSteps.length) && (
          <DashboardStats {...this.props} {...this.state} />
        )}
      </div>
    );
  }
}
