import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

const easeOutQuart = 'cubic-bezier(0.165, 0.84, 0.44, 1)';

export default function MoveInLeft({
  children,
  initDisplayStyle = 'block',
  duration = 500,
  distance = 250,
  ...props
}) {
  const transitionStyles = {
    defaultStyle: {
      transform: `translate(${distance}px, 0)`,
      display: 'none',
    },
    enterInitStyle: {
      display: initDisplayStyle,
      transform: `translate(${distance}px, 0)`,
    },
    enterStyle: {
      transform: transit('translate(0, 0)', duration, easeOutQuart),
    },
    leaveStyle: {
      transform: transit(`translate(${distance}px, 0)`, duration, easeOutQuart),
    },
    activeStyle: {
      //transform: 'translate(0, 0)',
    },
    active: true,
    transitionAppear: true,
  };
  return (
    <CSSTransition {...transitionStyles} {...props}>
      {children}
    </CSSTransition>
  );
}
