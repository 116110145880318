import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import currencies from 'currency-formatter/currencies.json';

import {
  currencySymbol,
  currencyValue,
  parseCurrency,
  formatCurrency,
} from 'utils';

import Tooltip from 'components/tooltip';

import Label from './label';

const CHAR_WIDTH = 9;
const SYMBOL_PADDING_LEFT = 15; // px
const SYMBOL_PADDING_RIGHT = 9;

function getSymbolWidth(symbolString, currencyCode) {
  // TODO: detect actual char width
  switch (currencyCode) {
    case 'SEK':
      return symbolString.length * CHAR_WIDTH * 0.7;

    case 'AED':
      return symbolString.length * CHAR_WIDTH * 0.3;

    default:
      return symbolString.length * CHAR_WIDTH;
  }
}

function getInitialCurrency(currency) {
  const currencyFormats = currencies[currency] || {};

  return {
    currency,
    thousandsSeparator: currencyFormats.thousandsSeparator || ',',
    decimalSeparator: currencyFormats.decimalSeparator || '.',
  };
}

function getInitialValue(props, currency) {
  let value = props.value !== undefined ? props.value : props.defaultValue;

  value =
    value === '' || value === null || value === undefined
      ? ''
      : currencyValue(
          value,
          currency,
          props.rounded ? { precision: undefined } : undefined,
        );

  return value;
}

function getCurrencyPrecision(value, decimalSeparator) {
  return value.length - value.lastIndexOf(decimalSeparator) - 1;
}

function getValueFromInput(props, state, value, inCurrency) {
  const { minValue, maxValue } = props;
  const { currency, decimalSeparator, thousandsSeparator } = state;

  const testValue = Number(
    value
      .trim()
      .replace(
        new RegExp(`[,. \\${decimalSeparator}\\${thousandsSeparator}]`, 'g'),
        '',
      ),
  );

  if (Number.isNaN(testValue)) {
    return;
  }

  const currencyCode = inCurrency || currency;

  const formattedValue = currencyValue(
    value,
    currencyCode,
    props.rounded ? { precision: undefined } : undefined,
  );

  const floatedValue = parseCurrency(formattedValue, currencyCode);

  if (maxValue !== undefined && floatedValue > maxValue) {
    value = String(maxValue);
  } else if (minValue !== undefined && floatedValue < minValue) {
    value = String(minValue);
  }

  // Get position of start of decimals
  const pos = value.lastIndexOf(decimalSeparator);

  if (pos !== -1) {
    // Get precision for currency
    const precision = getCurrencyPrecision(formattedValue, decimalSeparator);

    // Check that the number of entered decimal places exceeds precision
    if (value.length - pos - 1 > precision) {
      // Remove extra decimals
      value = value.slice(0, pos + precision + 1);
    }
  }

  if (inCurrency) {
    return currencyValue(value, currencyCode);
  }

  return value;
}

export default class InputCurrency extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.string,
    rounded: PropTypes.bool,
    selectFocus: PropTypes.bool,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    currencySymbolTip: PropTypes.bool,
    currencySymbolPadding: PropTypes.number,
    readonlyContent: PropTypes.bool,

    onChange: PropTypes.func.isRequired,
  };

  static contextTypes = {
    client: PropTypes.object,
  };

  static defaultProps = {
    minValue: 0,
  };

  constructor(props, context) {
    super(props, context);

    const { currency, thousandsSeparator, decimalSeparator } =
      getInitialCurrency(props.currency || context.client?.currency);

    this.state = {
      focused: false,
      value: getInitialValue(props, currency),
      defaultValue: props.defaultValue,
      prevCurrency: props.currency,
      currency,
      thousandsSeparator,
      decimalSeparator,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let obj = null;
    let currency;

    if (props.currency !== state.prevCurrency) {
      currency = getInitialCurrency(props.currency || state.currency);

      const nextValue = getValueFromInput(
        props,
        state,
        state.value,
        currency.currency,
      );

      obj = Object.assign(obj ?? {}, {
        value: nextValue !== undefined ? nextValue : state.value,
        prevCurrency: props.currency,
        ...currency,
      });
    }

    if (
      !state.focused &&
      props.defaultValue !== state.defaultValue &&
      props.defaultValue !== state.value
    ) {
      currency =
        currency || getInitialCurrency(props.currency || state.currency);

      obj = Object.assign(obj ?? {}, {
        value: getInitialValue(props, currency.currency),
        defaultValue: props.defaultValue,
        ...currency,
      });
    }

    return obj;
  }

  onFocus = (_event) => {
    if (this.props.selectFocus !== false) {
      this.refs.input.select();
    }

    this.setState({ focused: true });
  };

  onBlur = (_event) => {
    this.setState({ focused: false });

    if (String(this.state.value).trim().length > 0) {
      this.setState((state, props) => ({
        value: currencyValue(
          state.value,
          state.currency,
          props.rounded ? { precision: undefined } : undefined,
        ),
      }));
    }
  };

  onChange = (event) => {
    const {
      props: { onChange },
    } = this;

    const value = getValueFromInput(
      this.props,
      this.state,
      String(event.target.value),
    );

    if (value === undefined) {
      return;
    }

    this.setState({ value });

    const numValue =
      value !== '' ? parseCurrency(value, this.state.currency) : '';

    onChange(event, numValue);
  };

  value() {
    if (this.state.value) {
      return parseCurrency(this.state.value, this.state.currency);
    }

    return null;
  }

  focus() {
    this.refs.input.focus();
  }

  render() {
    const {
      label,
      help,
      rounded,
      selectFocus,
      minValue,
      maxValue,
      currency,
      currencySymbolTip,
      currencySymbolPadding = 0,
      readonlyContent,
      ...props
    } = this.props;

    const { client } = this.context;

    const currencyCode = this.state.currency;
    const currencySymbolString = currencySymbol(currencyCode);

    const indent =
      currencySymbolPadding +
      SYMBOL_PADDING_LEFT +
      getSymbolWidth(currencySymbolString, currencyCode) +
      SYMBOL_PADDING_RIGHT;

    return (
      <span className="form-currency">
        <Label label={label} help={help} htmlFor={props.id} />
        {readonlyContent ? (
          <div className="form-field-readonly">
            {formatCurrency(this.state.value, currencyCode)}
          </div>
        ) : (
          <span className="form-field-input">
            <span
              className={classNames('form-currency-symbol', {
                'has-value': Boolean(this.state.value && !props.disabled),
              })}
            >
              {currencySymbolTip && client.currencyNames[currencyCode] ? (
                <Tooltip
                  message={client.currencyNames[currencyCode]}
                  dir="left"
                  delayed={true}
                >
                  {currencySymbolString}
                </Tooltip>
              ) : (
                currencySymbolString
              )}
            </span>

            <input
              {...props}
              ref="input"
              value={this.state.value}
              defaultValue={undefined}
              placeholder={
                props.placeholder !== undefined
                  ? props.placeholder
                  : currencyValue(
                      0,
                      currencyCode,
                      rounded ? { precision: undefined } : undefined,
                    )
              }
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              className={classNames(props.className, {
                disabled: Boolean(props.disabled),
              })}
              style={{ paddingLeft: indent }}
            />
          </span>
        )}
      </span>
    );
  }
}
