import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Fieldtable, Field } from 'components/form';
import View from 'components/view';
import ButtonLink from 'components/button/link';
import { FadeIn } from 'components/transitions';
import SectionHeader from 'components/section-header';
import OrderPrintModal from './orders/orders-print-modal';
import './settings.scss';
export default class OrderSettings extends Component {
  static contextTypes = {
    user: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOrderPrintTemplateId: null,
      showOrderPrintLabel: null,
      isUpdateOrderPrintTemplate: false,
    };
  }

  onClickEditOrderPrintTemplate = (event) => {
    event.preventDefault();
    const showOrderPrintTemplateId = event.currentTarget.dataset.id;
    const showOrderPrintLabel = event.currentTarget.dataset.label;

    this.setState({
      showOrderPrintTemplateId,
      showOrderPrintLabel,
      isUpdateOrderPrintTemplate: true,
    });
  };

  onCloseOrderPrintModal = (noteEdited = false) => {
    const {
      context: { openModal },
    } = this;

    if (noteEdited) {
      openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.setState({ showOrderPrintTemplateId: null });
        },
      });
    } else {
      this.setState({
        showOrderPrintTemplateId: null,
        isUpdateOrderPrintTemplate: false,
      });
    }
  };

  onCloseCreateTemplateModal = () => {
    this.setState({
      showOrderPrintTemplateId: null,
    });
  };

  renderOrderPrintModal = () => {
    const {
      state: {
        showOrderPrintTemplateId,
        isUpdateOrderPrintTemplate,
        showOrderPrintLabel,
      },
      props: {
        fetchPrintTemplate,
        onCreateOrderPrintTemplate,
        onUpdatePrintTemplate,
        notifications: {
          record,
          defaultRecord,
          defaultContent,
          orderPrintTemplates,
        },
        loading,
      },
    } = this;

    return (
      <OrderPrintModal
        noteForm={(form) => (this.noteForm = form)}
        showOrderPrintLabel={showOrderPrintLabel}
        showOrderPrintTemplateId={showOrderPrintTemplateId}
        onClose={this.onCloseOrderPrintModal}
        fetchPrintTemplate={fetchPrintTemplate}
        getTemplateEngine={this.getTemplateEngine}
        onCreatePrintTemplate={onCreateOrderPrintTemplate}
        onUpdatePrintTemplate={(values) =>
          onUpdatePrintTemplate(values, this.refs.form)
        }
        onCloseCreateTemplateModal={this.onCloseCreateTemplateModal}
        loading={loading}
        record={record}
        defaultRecord={defaultRecord}
        defaultContent={defaultContent}
        templates={orderPrintTemplates.results}
        isUpdate={isUpdateOrderPrintTemplate}
      />
    );
  };

  onClickAddOrderPrintTemplate = (event) => {
    event.preventDefault();
    const showOrderPrintTemplateId = event.currentTarget.dataset.id;
    const showOrderPrintLabel = event.currentTarget.dataset.label;
    this.setState({
      showOrderPrintTemplateId,
      showOrderPrintLabel,
      isUpdateOrderPrintTemplate: false,
    });
  };

  onClickRemoveOrderPrintTemplate = (event) => {
    event.preventDefault();
    const { id, label } = event.currentTarget.dataset;

    this.context.openModal('ConfirmDelete', {
      title: label,
      onConfirm: () => {
        this.props.onDeleteOrderPrintTemplate(id);
      },
    });
  };

  renderPrintTemplateHeading = () => {
    return [
      <th key="1">label</th>,
      <th key="2">Description</th>,
      <th key="4">Actions</th>,
    ];
  };

  renderPrintTemplateValue = ({ value }) => {
    const { notifications } = this.props;
    const orderPrintTemplates = [
      ...(notifications.orderPrintTemplates.results || []),
    ];

    return [
      <td key="1">
        {value.label}&emsp;
        {value.default && <span className="status">Default</span>}
      </td>,
      <td key="2">
        <span className="muted">
          {value.description || <span className="muted">&mdash;</span>}
        </span>
      </td>,
      <td className="action template-action" key="4">
        <a
          href=""
          onClick={this.onClickEditOrderPrintTemplate}
          data-id={value.id}
          data-label={value.label}
        >
          Edit
        </a>
        {/* can't remove default template, autogenerated template and when one template in the list */}
        {orderPrintTemplates.length > 1 &&
          !value.default &&
          value.name !== 'print.v2' && (
            <a
              href=""
              onClick={this.onClickRemoveOrderPrintTemplate}
              data-id={value.id}
              data-label={value.label}
            >
              Remove
            </a>
          )}
      </td>,
    ];
  };

  render() {
    const {
      props: { edited, onSubmitForm, onChangeForm, notifications, values },
      state: { showOrderPrintTemplateId },
    } = this;

    const orderPrintTemplates = [
      ...(notifications.orderPrintTemplates.results || []),
    ];

    return (
      <div className="settings settings-orders">
        <Form
          ref="form"
          onSubmit={onSubmitForm}
          onChange={onChangeForm}
          values={values}
        >
          <View
            detail
            uri="/settings/orders"
            sectionTitle="Settings"
            headerTitle="Orders"
            headerSubtitle="Manage settings that affect store orders"
            headerActions={[
              {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
          >
            <div className="view-body-section-group">
              <fieldset className="full last-child">
                <div className="view-body-section-group">
                  <div className="view-body-section">
                    <div className="view-body-subheader">
                      <SectionHeader
                        className="view-body-subtitle"
                        title="Charge automatically"
                        subtitle="Orders will be charged automatically when submitted"
                      />
                      <div className="view-body-actions">
                        <Field
                          type="toggle"
                          name="features.auto_order_payment"
                          defaultChecked={values.features.auto_order_payment}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="view-body-section">
                    <div className="view-body-subheader">
                      <SectionHeader
                        className="view-body-subtitle"
                        title="Require payment authorization"
                        subtitle="Orders will be rejected if payment is not authorized by
                      your payment gateway"
                      />
                      <div className="view-body-actions">
                        <Field
                          type="toggle"
                          name="features.require_authorized_payment"
                          defaultChecked={
                            values.features.require_authorized_payment
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className="full last-child">
                <div className="view-body-section">
                  <div className="view-body-subheader">
                    <SectionHeader
                      className="view-body-subtitle"
                      title="Print templates"
                      subtitle="Layouts used to manually print from the dashboard order page"
                    />
                  </div>
                  <div className="view-section-content">
                    <div className="box-section">
                      <FadeIn
                        active={orderPrintTemplates.length > 0}
                        transitionAppear={false}
                      >
                        <Fieldtable
                          label="Template"
                          name="printTemplates"
                          defaultValue={orderPrintTemplates}
                          renderHeading={this.renderPrintTemplateHeading}
                          renderValue={this.renderPrintTemplateValue}
                          inline
                          removable={false}
                          addable={false}
                          readonly={true}
                        />
                      </FadeIn>
                      <div className="form-field">
                        <div className="collection-action-group">
                          <ButtonLink
                            size="sm"
                            type="secondary"
                            onClick={this.onClickAddOrderPrintTemplate}
                            data-id={`orders.print-${orderPrintTemplates.length}`}
                            data-label={`Print template #${orderPrintTemplates.length}`}
                          >
                            Add template
                          </ButtonLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </View>
        </Form>
        {showOrderPrintTemplateId && this.renderOrderPrintModal()}
      </div>
    );
  }
}
