import React from 'react';
import { get, set, pick, find } from 'lodash';
import PropTypes from 'prop-types';

import { prepareOrderItemFromModalData } from 'utils/order';
import { hasLocalizedContent } from 'utils/content';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';

import { View } from 'components/view';
import { Form, Field, TabView } from 'components/form';
import DropdownButton from 'components/button/dropdown';
import CurrencyPicker from 'components/locale/currency';
import ContentFields from 'components/content/fields';
import WithViewPreferences from 'containers/WithViewPreferences';
import ContentRecordTab from 'components/content/record-tab';

import OrderItems from '../order/items';
import OrderContent from '../order/content';
import OrderItemsAdd from '../order/items-add';
import OrderItemsEdit from '../order/items-edit';
import OrderDiscountsEdit from '../order/discounts-edit';

import LinkStatus from './link-status';

import '../order/order.scss';
import './purchase-links.scss';

export default class PurchaseLink extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  state = {
    errors: {},
    showAddItem: false,
    showEditItems: false,
    showDiscountsEdit: false,
    addItemProduct: null,
    editAddItem: null,
    editAddIndex: null,
    editItems: [],
    modalLoading: false,
  };

  componentDidMount() {
    confirmRouteLeave(this);
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  onChangeForm = (_, edited) => {
    this.props.onEdited(edited);
  };

  onSubmitForm = (values) => {
    return this.props
      .updateRecord(this.props.id, { ...values, items: undefined })
      .then((result) => {
        if (result.errors) {
          this.context.notifyError(result.errors);
        } else {
          this.props.onEdited(false);

          return this.props.fetchRecord(this.props.id);
        }
      });
  };

  onClickClone = (event) => {
    event.preventDefault();

    this.context.openModal('Confirm', {
      title: 'Clone purchase link',
      message: 'Are you sure you want to clone this purchase link?',
      action: 'Clone',
      onConfirm: () => {
        this.props.onSubmitClone();
      },
    });
  };

  onClickDelete = (event) => {
    event.preventDefault();

    const { record } = this.props;

    this.context.openModal('ConfirmDelete', {
      title: `Purchase link "${record.name}"`,
      onConfirm: () => {
        this.props.onDelete();
      },
    });
  };

  onClickAddItem = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.state.showAddItem && this.props.edited) {
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.setState({ showAddItem: false });
        },
      });
    } else {
      this.setState((state) => ({
        showAddItem: !state.showAddItem,
        addItemProduct: null,
        editAddItem: null,
        editAddIndex: null,
      }));
    }
  };

  onClickEditItems = (event) => {
    event.preventDefault();

    this.setState((state, props) => ({
      showEditItems: !state.showEditItems,
      editItems: (props.record.items || []).map((item) => ({
        ...item,
      })),
    }));
  };

  onClickEditSingleItem = (event) => {
    event.preventDefault();

    const { id, index } = event.currentTarget.dataset;

    this.setState((state, props) => {
      const editItems = state.showEditItems
        ? state.editItems
        : props.record.items || [];

      return {
        showAddItem: true,
        addItemProduct: null,
        editAddItem: id
          ? find(editItems, (item) => item.id === id)
          : editItems[index],
        editAddIndex: id ? null : index,
      };
    });
  };

  onCloseEditItems = (event) => {
    if (this.props.edited) {
      event.preventDefault();

      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.onClickEditItems(event);
        },
      });
    } else {
      this.onClickEditItems(event);
    }
  };

  onClickDiscountsEdit = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      showDiscountsEdit: !state.showDiscountsEdit,
    }));
  };

  onClickDiscountsApplyPromos = (event) => {
    event.preventDefault();

    this.props.onDiscountsApplyPromos();
  };

  onChangeAddProductLookup = (event, value) => {
    this.setState({
      addItemProduct: value || null,
    });
  };

  onSubmitAddItem = async (values) => {
    const {
      addItemProduct,
      editAddItem,
      editAddIndex,
      showEditItems,
      editItems,
    } = this.state;
    const { getItemPrice } = this.props;

    const options = {
      getItemPrice,
      addItemProduct,
      editAddItem,
      editAddIndex,
      showEditItems,
      editItems,
    };

    await prepareOrderItemFromModalData(values, options, {
      onLoading: () => {
        this.setState({ modalLoading: true });
      },
      onEditItems: (items) => {
        return this.setState({
          showAddItem: false,
          addItemProduct: null,
          editItems: items,
          modalLoading: false,
        });
      },
      onAddItem: (item) => {
        this.setState({ modalLoading: true });

        return this.props.onAddItem(item).then((result) => {
          if (!result) {
            this.setState({ modalLoading: false });
            return;
          }

          this.setState({
            showAddItem: false,
            addItemProduct: null,
            modalLoading: false,
          });
        });
      },
    });
  };

  onSubmitEditItems = () => {
    this.setState({ modalLoading: true });

    return this.props.onEditItems(this.state.editItems).then((result) => {
      if (!result) {
        this.setState({ modalLoading: false });
        return;
      }

      this.setState({
        showEditItems: false,
        editItems: [],
        modalLoading: false,
      });
    });
  };

  onSubmitDiscountsEdit = (values) => {
    this.setState({ modalLoading: true });

    return this.props
      .onEditValues({
        coupon_code: values.coupon_code,
        promotion_ids: {
          $set: values.promotion_ids,
        },
      })
      .then((result) => {
        if (!result) {
          this.setState({ modalLoading: false });
          return;
        }

        this.setState({
          showDiscountsEdit: false,
          modalLoading: false,
        });
      });
  };

  onClickRemoveEditItem = (event) => {
    event.preventDefault();

    const index = event.currentTarget.dataset.index;
    const editItems = [...this.state.editItems];

    if (editItems[index].id) {
      editItems[index].removed = !editItems[index].removed;
    } else {
      editItems.splice(index, 1);
    }

    this.setState({ editItems });
  };

  onClickRemoveEditSingleItem = (event) => {
    if (event) {
      event.preventDefault();
    }

    const { id, index } = event.currentTarget.dataset;

    if (this.state.showEditItems) {
      const editItems = [...this.state.editItems];

      if (id) {
        editItems[editItems.findIndex((item) => item.id === id)].removed = true;
      } else {
        editItems.splice(index, 1);
      }

      this.setState({
        showAddItem: false,
        editItems,
      });
    } else {
      this.context.openModal('Confirm', {
        title: 'Remove item',
        message: (
          <p>
            Are you sure you want to remove this item? This action can't be
            undone.
          </p>
        ),
        action: 'Remove',
        actionType: 'danger',
        onConfirm: () => {
          this.setState({ modalLoading: true });

          const removedItem = { ...this.state.editAddItem, removed: true };

          return this.props.onEditItems([removedItem]).then((result) => {
            if (!result) {
              this.setState({ modalLoading: false });
              return;
            }

            this.setState({
              showAddItem: false,
              modalLoading: false,
            });
          });
        },
      });
    }
  };

  onChangeEditItemInput = (event, value) => {
    const editItems = [...this.state.editItems];
    const [index, fieldName] = event.target.name.split('.');
    const editItem = editItems[index];

    set(editItems, event.target.name, value);

    if (editItem.quantity <= 0 && editItem.quantity.trim() !== '') {
      editItem.quantity = '0';
      editItem.removed = true;
      editItem.calc_price = undefined;
    } else if (fieldName === 'quantity') {
      const now = Date.now();

      editItem.pricingTime = now;

      clearTimeout(this.editItemPriceTimer);

      this.editItemPriceTimer = setTimeout(() => {
        this.props
          .getItemPrice(
            pick(editItem, [
              'id',
              'product_id',
              'options',
              'quantity',
              'purchase_option',
            ]),
          )
          .then((pricedItem) => {
            if (this.state.showEditItems && pricedItem && !pricedItem.errors) {
              const postEditItems = [...this.state.editItems];
              if (get(postEditItems[index], 'pricingTime') === now) {
                postEditItems[index].calc_price = pricedItem.price;
                this.setState({ editItems: postEditItems });
              }
            }
          });
      }, 250);
    }

    this.setState({ editItems });
  };

  render() {
    const {
      record,
      edited,
      lookup,
      errors,
      settings,
      content,
      location,
      loading: loadingProp,
      submitting,
      clearErrors,
    } = this.props;

    const { modalLoading } = this.state;

    const loading = loadingProp || modalLoading;

    return (
      <div className="order-view view-detail-full">
        <Form
          loading={loading}
          onChange={this.onChangeForm}
          onSubmit={this.onSubmitForm}
          onBeforeValidation={clearErrors}
        >
          <WithViewPreferences
            {...this.props}
            tabs={[]}
            model="purchaselinks"
            id="edit"
            type="record"
          >
            <View
              detail={true}
              submitting={submitting}
              uri="/purchase-links"
              devtools={{
                model: 'purchaselinks',
                editModel: 'purchaselinks',
                record,
              }}
              withPreferences={true}
              sectionTitle="Back"
              headerTitle={record.name}
              headerSubtitle={
                <CurrencyPicker
                  pretext="Prices in"
                  currency={record.currency}
                  onChange={this.props.onChangeCurrency}
                  editable
                />
              }
              headerActions={[
                {
                  isActionMenu: true,
                  component: (
                    <DropdownButton
                      key="2"
                      type="secondary"
                      anchor="right"
                      anchorPosition={54}
                      items={[
                        <button onClick={this.onClickClone} type="button">
                          Clone purchase link
                        </button>,
                      ]}
                    >
                      Actions
                    </DropdownButton>
                  ),
                },
                {
                  label: 'Save link',
                  type: edited ? 'default' : 'secondary disabled',
                  submit: true,
                },
              ]}
              extraActions={[
                {
                  label: 'Delete purchase link',
                  onClick: this.onClickDelete,
                  className: 'danger',
                  delete: true,
                },
              ]}
              localized={hasLocalizedContent(
                this.props.content.models,
                'purchaselinks',
              )}
            >
              <TabView value={location.query.tab} isDefault>
                <fieldset>
                  <div className="row">
                    <Field
                      required
                      name="name"
                      type="text"
                      label="Name"
                      placeholder="Unique name of this purchase link"
                      className="span4"
                      defaultValue={record.name}
                      error={errors.name}
                    />
                  </div>

                  <OrderItems
                    record={record}
                    editable={true}
                    settings={settings}
                    onClickEditItems={this.onClickEditItems}
                    onClickEditSingleItem={this.onClickEditSingleItem}
                    onClickAddItem={this.onClickAddItem}
                    onClickDiscountsEdit={this.onClickDiscountsEdit}
                  />

                  <OrderContent
                    {...this.props}
                    zone="details"
                    collection="purchaselinks"
                    record={record}
                    onSubmit={this.props.onEditValues}
                  />

                  <LinkStatus
                    record={record}
                    settings={settings}
                    disabled={!record.active}
                  />

                  <ContentFields
                    {...this.props}
                    zone="content"
                    collection="purchaselinks"
                    models={content.models}
                    record={record}
                    values={record}
                    currency={record.currency}
                  />
                </fieldset>
              </TabView>
              <ContentRecordTab
                {...this.props}
                collection="purchaselinks"
                view="edit"
              />
            </View>
          </WithViewPreferences>
        </Form>

        {this.state.showEditItems && (
          <OrderItemsEdit
            record={record}
            loading={loading}
            editItems={this.state.editItems}
            onSubmitEditItems={this.onSubmitEditItems}
            onClickEditItems={this.onClickEditItems}
            onClickEditSingleItem={this.onClickEditSingleItem}
            onClickRemoveEditItem={this.onClickRemoveEditItem}
            onClickAddItem={this.onClickAddItem}
            onCloseEditItems={this.onCloseEditItems}
            onChangeEditItemInput={this.onChangeEditItemInput}
          />
        )}

        {this.state.showAddItem && (
          <OrderItemsAdd
            isCart={true}
            lookup={lookup}
            loading={loading}
            addItemProduct={this.state.addItemProduct}
            onSubmitAddItem={this.onSubmitAddItem}
            onClickAddItem={this.onClickAddItem}
            onChangeAddProductLookup={this.onChangeAddProductLookup}
            onClickRemoveEditSingleItem={this.onClickRemoveEditSingleItem}
            getItemPrice={this.props.getItemPrice}
            editAddItem={this.state.editAddItem}
            editAddIndex={this.state.editAddIndex}
            currency={record.currency}
          />
        )}

        {this.state.showDiscountsEdit && (
          <OrderDiscountsEdit
            record={record}
            lookup={lookup}
            loading={loading}
            promotions={true}
            discounts={this.props.discounts}
            onSubmitDiscountsEdit={this.onSubmitDiscountsEdit}
            onClickDiscountsEdit={this.onClickDiscountsEdit}
          />
        )}
      </div>
    );
  }
}
