import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import ObjectID from 'bson-objectid';
import Link from 'components/link';
import { Field, Fieldtable, LookupProduct, FieldAmount } from 'components/form';
import { FadeIn } from 'components/transitions';
import { formatCurrency } from 'utils';
import { productThumbUrl } from 'utils/product';

export default class ProductRelatedProducts extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    record: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      upValue: this.getInitialUpsellValue(props),
      crossValue: this.getInitialCrosssellValue(props),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.record !== this.props.record) {
      this.setState({
        upValue: this.getInitialUpsellValue(nextProps),
        crossValue: this.getInitialCrosssellValue(nextProps),
      });
    }
  }

  getInitialUpsellValue(props) {
    if (props.record.up_sells) {
      return [...props.record.up_sells];
    }
    return props.record.up_sells;
  }

  getInitialCrosssellValue(props) {
    if (props.record.cross_sells) {
      return [...props.record.cross_sells];
    }
    return props.record.cross_sells;
  }

  getFormattedPrice(value, currency) {
    if (value.type === 'giftcard') {
      const values = get(value, 'options[0].values');
      const lowValue = minBy(values, 'price');
      const highValue = maxBy(values, 'price');

      if (lowValue && highValue && lowValue !== highValue) {
        return `${formatCurrency(lowValue.price, currency)} — ${formatCurrency(
          highValue.price,
          currency,
        )}`;
      } else if (lowValue || highValue) {
        return formatCurrency(
          lowValue ? lowValue.price : highValue.price,
          currency,
        );
      }
    } else {
      return formatCurrency(value.price, currency);
    }
  }

  renderUpsellHeading = () => {
    return [
      <th key="1" colSpan="2">
        Product
      </th>,
      <th key="2">Price</th>,
    ];
  };

  renderUpsellValue = ({ value, index }) => {
    const currency = this.props.record.currency || this.context.client.currency;
    const productValue = value.product || { name: 'Unknown product' };

    return [
      <td key="0" className="image">
        <span className="collection-table-images">
          <span className="image">
            <Link to={`/products/${productValue.id}`}>
              <img
                src={productThumbUrl(productValue)}
                alt={productValue.name}
              />
            </Link>
          </span>
        </span>
      </td>,
      <td key="1" width="35%">
        <Link to={`/products/${productValue.id}`}>{productValue.name}</Link>
      </td>,
      <td key="2">
        <div>
          {productValue.sale && productValue.sale_price ? (
            <span>
              <strike>{formatCurrency(productValue.price, currency)}</strike>{' '}
              <span className="positive">
                {formatCurrency(productValue.sale_price, currency)}
              </span>
            </span>
          ) : (
            this.getFormattedPrice(productValue, currency)
          )}
        </div>
      </td>,
    ];
  };

  renderUpsellForm = (value, index) => {
    const { lookup } = this.props;
    return (
      <Fragment>
        <Field
          type="hidden"
          name="id"
          value={value.id || ObjectID().toHexString()}
        />
        <LookupProduct
          name="product"
          label="Product"
          query={{ id: { $ne: this.props.record.id } }}
          defaultValue={value.product}
          lookup={lookup}
          required={true}
        />
      </Fragment>
    );
  };

  renderCrosssellHeading = () => {
    return [
      <th key="1" colSpan="2">
        Product
      </th>,
      <th key="2">Price</th>,
      <th key="3">Discount</th>,
    ];
  };

  renderCrosssellValue = ({ value, index }) => {
    const currency = this.props.record.currency || this.context.client.currency;
    const productValue = value.product || { name: 'Unknown product' };

    return [
      <td key="0" className="image">
        <span className="collection-table-images">
          <span className="image">
            <Link to={`/products/${productValue.id}`}>
              <img
                src={productThumbUrl(productValue)}
                alt={productValue.name}
              />
            </Link>
          </span>
        </span>
      </td>,
      <td key="1" width="35%">
        <Link to={`/products/${productValue.id}`}>{productValue.name}</Link>
      </td>,
      <td key="2">
        <div>
          {productValue.sale && productValue.sale_price ? (
            <span>
              <strike>{formatCurrency(productValue.price, currency)}</strike>{' '}
              <span className="positive">
                {formatCurrency(productValue.sale_price, currency)}
              </span>
            </span>
          ) : (
            this.getFormattedPrice(productValue, currency)
          )}
        </div>
      </td>,
      <td key="3">
        <div>
          {value.discount_type === 'fixed' ? (
            value.discount_amount > 0 ? (
              <span>{formatCurrency(value.discount_amount, currency)} off</span>
            ) : (
              <span className="muted">&mdash;</span>
            )
          ) : value.discount_percent > 0 ? (
            <span>{value.discount_percent}% off</span>
          ) : (
            <span className="muted">&mdash;</span>
          )}
        </div>
      </td>,
    ];
  };

  renderCrosssellForm = (value, index) => {
    const { lookup } = this.props;
    const currency = this.props.record.currency || this.context.client.currency;
    return (
      <div>
        <Field
          type="hidden"
          name="id"
          value={value.id || ObjectID().toHexString()}
        />
        <LookupProduct
          name="product"
          label="Product"
          query={{ id: { $ne: this.props.record.id } }}
          defaultValue={value.product}
          lookup={lookup}
          required={true}
        />
        <div className="row">
          <FieldAmount
            label="Discount"
            typeName="discount_type"
            fixedName="discount_amount"
            fixedValue="fixed"
            percentName="discount_percent"
            percentValue="percent"
            localeValue={value.$currency}
            localized={true}
            defaultValueType={value.discount_type}
            defaultValueFixed={value.discount_amount}
            defaultValuePercent={value.discount_percent}
            currency={currency}
            className="span2"
            help="Discount will be applied to this product when added to a shopping cart from a cross-sell offer"
          />
        </div>
      </div>
    );
  };

  render() {
    const { record, values = {}, lookup, disabled } = this.props;

    const { upValue, crossValue } = this.state;

    const hasUpsells = !!(record.up_sells && record.up_sells.length > 0);
    const hasCrosssells = !!(
      record.cross_sells && record.cross_sells.length > 0
    );

    return (
      <div className="view-section-content">
        <div className="product-related">
          <div className="view-body-subsection">
            <div className="view-body-subheader">
              <div>
                <h3 className="view-body-subtitle">Up-sells</h3>
                <p>
                  Manage products offered as an upgrade or alternative option
                </p>
              </div>
              <div className="view-body-actions"></div>

              <Field
                type="toggle"
                name="enable_up_sells"
                defaultChecked={hasUpsells}
                className="right"
              />
            </div>

            {(values.enable_up_sells !== undefined
              ? !!values.enable_up_sells
              : hasUpsells) && (
              <FadeIn>
                <Fieldtable
                  label="Up-sell product"
                  name="up_sells"
                  defaultValue={upValue}
                  renderHeading={this.renderUpsellHeading}
                  renderValue={this.renderUpsellValue}
                  renderForm={this.renderUpsellForm}
                  disabled={disabled}
                  formWidth={600}
                  sortable={true}
                  lookup={lookup}
                  mountAddRow={!hasUpsells}
                />
              </FadeIn>
            )}
          </div>
          <div className="view-body-subsection">
            <div className="view-body-subheader">
              <div>
                <h3 className="view-body-subtitle">Cross-sells</h3>
                <p>
                  Manage products offered as an addon with an optional discount
                  at checkout
                </p>
              </div>

              <div className="view-body-actions">
                <Field
                  type="toggle"
                  name="enable_cross_sells"
                  defaultChecked={hasCrosssells}
                  className="right"
                />
              </div>
            </div>
            {(values.enable_cross_sells !== undefined
              ? !!values.enable_cross_sells
              : hasCrosssells) && (
              <FadeIn>
                <Fieldtable
                  label="Cross-sell product"
                  name="cross_sells"
                  defaultValue={crossValue}
                  renderHeading={this.renderCrosssellHeading}
                  renderValue={this.renderCrosssellValue}
                  renderForm={this.renderCrosssellForm}
                  disabled={disabled}
                  formWidth={600}
                  sortable={true}
                  lookup={lookup}
                  mountAddRow={!hasCrosssells}
                />
              </FadeIn>
            )}
          </div>
        </div>
      </div>
    );
  }
}
