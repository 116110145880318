import { find, each, map, reduce, snakeCase, cloneDeep, sortBy } from 'lodash';
import { evalConditionsWithAppFields, isEmpty, objectToArray } from 'utils';
import { collectionLinkTarget } from './content';
import { NAV_DEFAULTS } from '../constants/nav';

export function navWithPreferences({ user, client, content }) {
  let navItems = cloneDeep(NAV_DEFAULTS);

  const userNav = cloneDeep(user?.preferences?.nav || client?.preferences?.nav);

  if (userNav?.length > 0) {
    const defaultNav = cloneDeep(NAV_DEFAULTS);

    // Add new items from defaults
    forEachNavItem(defaultNav, (navItem, index, parent, parentIndex) => {
      const userItem = findNavItem(navItem, userNav);
      if (!userItem) {
        if (parent) {
          const userItemParent = findNavItem(parent, userNav);
          if (userItemParent) {
            userItemParent.items = userItemParent.items || [];
            userItemParent.items.splice(index, 0, navItem);
          } else {
            // Append new item parent in the default location
            userNav.splice(parentIndex, 0, parent);
          }
        } else {
          userNav.splice(index, 0, navItem);
        }
      }
    });

    // Remove old default and disabled app items from user nav
    forEachNavItem(userNav, (navItem, index, parent) => {
      const defaultItem = findNavItem(navItem, defaultNav);
      // Custom items are ignored
      if (!defaultItem && !navItem?.custom) {
        if (parent) {
          parent.items.splice(index, 1);
        } else {
          userNav.splice(index, 1);
        }
      }
      if (navItem.app_id && !client.appsEnabledById[navItem.app_id]) {
        if (parent) {
          parent.items.splice(index, 1);
        } else {
          userNav.splice(index, 1);
        }
      }
    });

    navItems = userNav;
  }

  appendNavContentViews(navItems, content);

  return navItems;
}

export function forEachNavItem(
  navItems,
  callback,
  parent = undefined,
  parentIndex = undefined,
) {
  each(navItems, (item, index) => {
    const cont = callback(item, index, parent, parentIndex);
    if (cont === false) {
      return;
    }
    if (item?.items?.length) {
      forEachNavItem(item.items, callback, item, index);
    }
  });
}

export function findNavItem(item, navItems) {
  let foundItem;
  forEachNavItem(navItems, (navItem) => {
    if (navItem?.id === item?.id) {
      foundItem = navItem;
      return false;
    }
  });
  return foundItem;
}

export function findNavItemParent(item, navItems) {
  let foundIndex;
  let foundParent;
  forEachNavItem(navItems, (navItem, index, parent) => {
    if (navItem?.id === item?.id) {
      foundIndex = index;
      foundParent = parent;
      return false;
    }
  });
  return { foundIndex, foundParent };
}

export function appendNavContentViews(navItems, content) {
  if (Object.keys(content.viewsByCollection).length === 0) {
    return;
  }

  const contentViews = [];

  // Add app/custom content views
  for (const collection in content.viewsByCollection) {
    const views = content.viewsByCollection[collection];
    contentViews.push(
      ...views.filter((view) => view.type === 'list' && view.nav),
    );
  }

  const sortedContentViews = sortBy(contentViews, (view) =>
    view.date_created ? view.date_created : -1,
  );

  // Append to nav items if not existing
  for (const view of sortedContentViews) {
    const collection = view.childCollection || view.collection;

    const viewItem = {
      id: view.default ? view.collection : view.gid,
      label: view.nav?.label || view.label,
      link: collectionLinkTarget({ collection }),
      ...(view.nav?.permission ? { permission: view.nav.permission } : {}),
      ...(view.nav?.icon ? { icon: `${NAV_ICON_PREFIX}${view.nav.icon}` } : {}),
      ...(view.app_id ? { app_id: view.app_id } : {}),
    };

    const found = findNavItem(viewItem, navItems);

    if (view.active !== false) {
      if (!found) {
        if (view.nav?.parent) {
          const parent = find(navItems, { id: view.nav.parent });
          if (parent?.items) {
            parent.items.push(viewItem);
          }
        } else {
          navItems.push(viewItem);
        }
      }
    } else {
      // Remove inactive items
      if (found) {
        const { foundIndex, foundParent } = findNavItemParent(found, navItems);
        if (foundParent) {
          foundParent.items.splice(foundIndex, 1);
        } else if (foundIndex >= 0) {
          navItems.items.splice(foundIndex, 1);
        }
      }
    }
  }
}

/**
 * Get a view config with content views and preferences applied
 * (tabs, fields, actions, filters, etc)
 */
export function viewConfigsWithPreferences(
  configType,
  {
    currentView,
    user,
    client,
    content,
    defaults,
    overrides,
    conditionalValues,
  },
) {
  const userPrefs = user?.preferences;
  const clientPrefs = client?.preferences;
  const configsWithPreferences = [];

  // Start with default values (object format)
  const baseConfigs = [];
  const defaultConfigs = objectToArray(defaults);

  if (!currentView) {
    return defaultConfigs;
  }

  const collection = currentView.childCollection || currentView.collection;

  // Add content view values
  if (content) {
    // Standard views have other content view values applied
    if (currentView.standard) {
      const contentViews = content.viewsByCollection[collection]?.filter(
        (view) =>
          view.active !== false &&
          view.standard !== true &&
          view.type === currentView.type &&
          view.collection === collection,
      );
      for (const view of contentViews) {
        for (const viewConfig of view[configType] || []) {
          // Standard views use their own default things
          if (viewConfig.id === 'default' || viewConfig.default) {
            // Unless the standard view has no default, use this one
            if (currentView[configType]?.length === 0) {
              baseConfigs.push(viewConfig);
            }
            continue;
          }

          // Set config IDs on tab fields
          let fields = viewConfig.fields;
          if (configType === 'tabs' && fields) {
            fields = fields.map((field) => ({
              ...field,
              id: `${view.app_id || 'admin'}_${field.id}`,
            }));
          }

          const configId = `${view.app_id || 'admin'}_${viewConfig.id}`;
          baseConfigs.push({
            ...viewConfig,
            id: configId,
            ...(fields ? { fields } : undefined),
            ...(view.app_id ? { app_id: view.app_id } : {}),
          });
        }
      }
    } else {
      // Custom views use their own configs
      baseConfigs.push(...(currentView[configType] || []));
    }
  }

  // Add default configs to base configs
  const addConfigs = [];
  for (const config of defaultConfigs) {
    if (!find(baseConfigs, { id: config.id })) {
      addConfigs.push(config);
    }
  }
  // Make sure default configs are added in the right order
  baseConfigs.unshift(...addConfigs);

  // Apply config preferences
  let prefView = overrides || find(userPrefs?.views, { id: currentView.gid });
  if (!prefView?.[configType]) {
    prefView = find(clientPrefs?.views, { id: currentView.gid });
  }

  for (const prefConfig of prefView?.[configType] || []) {
    if (!prefConfig) {
      continue;
    }
    if (prefConfig.app_id && !client.appsEnabledById[prefConfig.app_id]) {
      continue;
    }

    const configId = prefConfig.id || `admin_${snakeCase(prefConfig.label)}`;
    const base =
      find(baseConfigs, { id: configId }) ||
      find(baseConfigs, { id: prefConfig.source_id });

    if (!base && !prefConfig.custom) {
      continue;
    }

    const configWithPref = {
      ...(base || undefined),
      ...prefConfig,
      ...(prefConfig.hidden || (!overrides && base.hidden)
        ? { hidden: true }
        : undefined),
      id: configId,
    };

    // Set hidden if not overriding fields, otherwise remove it from base def
    if (prefConfig.hidden || (!overrides && base.hidden)) {
      configWithPref.hidden = true;
    } else {
      delete configWithPref.hidden;
    }

    configsWithPreferences.push(configWithPref);
  }

  // Add all base configs not in preferences
  for (const config of baseConfigs) {
    if (!find(configsWithPreferences, { id: config.id })) {
      configsWithPreferences.push(config);
    }
  }

  // Add hidden flags to conditional fields
  return configsWithPreferences.map((config) => {
    if (config.id === 'default' || config.default) {
      // Ensure default configs are not hidden
      delete config.hidden;
      config.default = true;
    }
    if (
      !matchPreferenceConditions(currentView, config, client, conditionalValues)
    ) {
      config.hidden = true;
      config.hiddenByConditions = true;
    }
    return config;
  });
}

function matchPreferenceConditions(
  currentView,
  config,
  client,
  conditionalValues,
) {
  if (!isEmpty(config.conditions)) {
    const app = client?.appsById[currentView.app_id];
    const rootPath = conditionalValues && app ? `$app.${app.slug_id}` : '';

    // Remove field conditions if the view is a list type
    const viewConditions =
      currentView.type === 'record'
        ? config.conditions
        : reduce(
            config.conditions,
            (acc, value, key) => {
              if (key === '$settings' || key.startsWith('$settings.')) {
                return {
                  ...acc,
                  [key]: value,
                };
              }
              return acc;
            },
            {},
          );

    if (Object.keys(viewConditions).length === 0) {
      return true;
    }

    const result = evalConditionsWithAppFields(
      client,
      rootPath,
      viewConditions,
      conditionalValues,
    );

    return result;
  }
  return true;
}
