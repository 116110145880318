import React from 'react';
import Status from 'components/status';
import { paymentStatus, fulfillmentStatus } from 'utils/order';

export const PaymentStatus = ({ order, blank = false }) => {
  const status = paymentStatus(order);
  return (
    <Status
      type={
        order.canceled || order.payment_balance > 0
          ? 'negative'
          : order.paid
          ? 'positive'
          : status === 'Unpaid'
          ? 'muted'
          : 'warning'
      }
      blank={blank}
    >
      {status}
    </Status>
  );
};

export const FulfillmentStatus = ({ order, blank = false }) => {
  const status = fulfillmentStatus(order);
  return (
    <Status
      type={
        order.canceled
          ? 'negative'
          : order.hold
          ? 'muted'
          : order.delivered
          ? 'positive'
          : 'warning'
      }
      blank={blank}
    >
      {status}
    </Status>
  );
};
