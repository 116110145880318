import React, { Fragment } from 'react';
import { get, find, map, reduce } from 'lodash';
import Link from 'components/link';
import ButtonLink from 'components/button/link';
import Modal from 'components/modal';
import BulkProgress from 'components/bulk/progress';
import { Field } from 'components/form';
import { formatCurrency, inflect, isEmpty } from 'utils';
import { renderMultiCurrencyValuesTooltip } from 'utils/money';

export default class GiftcardShipment extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showGenerateMore: false,
      generateMoreError: null,
      generatingCount: 0,
    };
  }

  onClickGenerateMore = (event) => {
    event.preventDefault();
    this.setState({
      showGenerateMore: !this.state.showGenerateMore,
      generateMoreError: null,
    });
  };

  onCloseGenerateMore = () => {
    this.setState({ showGenerateMore: false });
  };

  getActualGeneratingCount() {
    const { giftcard_generate } = this.props.values;
    return get(giftcard_generate, 'counts', []).reduce(
      (acc, count) =>
        acc +
        Number(count && count.count > 0 && count.amount > 0 ? count.count : 0),
      0,
    );
  }

  onClickGenerateMoreSubmit = (event) => {
    event.preventDefault();
    const actualCount = this.getActualGeneratingCount();
    if (actualCount > 0) {
      this.setState({ showGenerateMore: false, generatingCount: actualCount });
      this.props.onSubmitGenerateMoreGiftcards();
    } else {
      this.setState({
        generateMoreError: 'You should generate at least 1 gift card',
      });
    }
  };

  renderGenerateMore() {
    const { values } = this.props;
    const optionValues = get(values, 'options[0].values', []);
    return (
      <Modal
        title="Generate gift card codes"
        width={550}
        actions={[
          {
            label: 'Generate',
            type: 'default',
            onClick: this.onClickGenerateMoreSubmit,
          },
          {
            label: 'Cancel',
            type: 'cancel',
            onClick: this.onClickGenerateMore,
          },
        ]}
        onClose={this.onCloseGenerateMore}
        cancel={false}
      >
        <fieldset>
          <p>You can generate up to 1,000 gift card codes at a time</p>
          {optionValues.map((value, index) => (
            <Fragment key={value.id || index}>
              <Field
                type="hidden"
                name={`giftcard_generate.counts[${index}].amount`}
                value={value.price}
              />
              <Field
                type="hidden"
                name={`giftcard_generate.counts[${index}].$currency`}
                value={reduce(
                  value.$currency,
                  (acc, cv, code) => ({
                    ...acc,
                    [code]: {
                      amount: cv.price,
                    },
                  }),
                  {},
                )}
              />
              <div className="span1" style={{ width: 150 }}>
                <Field
                  type="number"
                  label={
                    <Fragment>
                      {value.name}
                      {renderMultiCurrencyValuesTooltip(
                        value.$currency,
                        'price',
                        this.props.record.currency,
                      )}
                    </Fragment>
                  }
                  name={`giftcard_generate.counts[${index}].count`}
                  readonly={true}
                  placeholder="0"
                  maxLength="4"
                  autoFocus={index === 0}
                />
              </div>
              <div />
            </Fragment>
          ))}
        </fieldset>
      </Modal>
    );
  }

  renderGenerationProgress() {
    const { generatingCount } = this.state;
    return (
      <Modal
        title={`Generating ${
          generatingCount
            ? inflect(generatingCount, 'gift card codes')
            : 'gift card codes'
        }`}
        width={450}
        cancel={false}
        tapout={false}
        closable={false}
      >
        <fieldset>
          <BulkProgress percent={undefined} />
        </fieldset>
      </Modal>
    );
  }

  render() {
    const { record = {}, related = {}, values } = this.props;

    const optionValues = get(values, 'options[0].values', []);
    const giftcardsByAmount = get(related, 'giftcards_by_amount', {});

    return (
      <div className="product-giftcards-shipment">
        {giftcardsByAmount.count > 0 ? (
          <Fragment>
            <div className="view-body-subsection">
              <div className="view-body-subheader">
                <h4 className="view-body-subtitle">Gift card codes</h4>
                <div className="view-body-actions">
                  <Link to={`/giftcards/products/${record.id}/giftcards`}>
                    View all codes (
                    {giftcardsByAmount.results.reduce(
                      (acc, result) => acc + result.count,
                      0,
                    )}
                    )
                  </Link>
                  <a href="" onClick={this.onClickGenerateMore}>
                    Generate more
                  </a>
                </div>
              </div>
              <div className="subsection-content">
                <div className="collection-table-container">
                  <table className="collection-table">
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(optionValues, (value) => (
                        <tr key={value.price}>
                          <td width="100">
                            {formatCurrency(value.price, record.currency)}
                            {renderMultiCurrencyValuesTooltip(
                              value.$currency,
                              'price',
                              record.currency,
                            )}
                          </td>
                          <td>
                            {get(
                              find(giftcardsByAmount.results, {
                                amount: value.price,
                              }),
                              'count',
                              0,
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          !isEmpty(optionValues) > 0 && (
            <div className="row indented">
              <div className="view-body-subsection span4">
                <div className="view-body-subheader">
                  <p>
                    Optionally generate a number of gift card codes per
                    denomination
                  </p>
                </div>
                {!record.id ? (
                  <div className="row">
                    {map(optionValues, (value, index) => (
                      <Fragment key={value.id || index}>
                        <Field
                          type="hidden"
                          name={`giftcard_generate.counts[${index}].amount`}
                          value={value.price}
                        />
                        <Field
                          type="hidden"
                          name={`giftcard_generate.counts[${index}].$currency`}
                          value={reduce(
                            value.$currency,
                            (acc, cv, code) => ({
                              ...acc,
                              [code]: {
                                amount: cv.price,
                              },
                            }),
                            {},
                          )}
                        />
                        <span className="span1" style={{ width: 150 }}>
                          <Field
                            type="number"
                            label={
                              <Fragment>
                                {value.name}
                                {renderMultiCurrencyValuesTooltip(
                                  value.$currency,
                                  'price',
                                  this.props.record.currency,
                                )}
                              </Fragment>
                            }
                            name={`giftcard_generate.counts[${index}].count`}
                            placeholder="0"
                            maxLength="4"
                          />
                        </span>
                        <span />
                      </Fragment>
                    ))}
                  </div>
                ) : (
                  <div>
                    <ButtonLink
                      size="sm"
                      type="secondary"
                      onClick={this.onClickGenerateMore}
                    >
                      Generate gift card codes
                    </ButtonLink>
                    <br />
                    <br />
                  </div>
                )}
              </div>
            </div>
          )
        )}
        {this.state.showGenerateMore && this.renderGenerateMore()}
        {this.props.giftcardGenerating && this.renderGenerationProgress()}
      </div>
    );
  }
}
