import PropTypes from 'prop-types';
import React from 'react';
import { find } from 'lodash';
import OrderFulfillmentAdd from './fulfillment-add';

export default class OrderFulfillmentEdit extends React.PureComponent {
  static propTypes = {
    record: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    shipmentId: PropTypes.string.isRequired,
    onClickFulfillmentEdit: PropTypes.func.isRequired,
    onSubmitFulfillmentEdit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onSubmitForm(values) {
    this.props.onSubmitFulfillmentEdit(values);
  }

  getShipmentForEdit() {
    const { shipmentId, record } = this.props;
    const shipment = find(record.shipments.results, { id: shipmentId }) || {};

    shipment.$notify = false;

    return shipment;
  }

  render() {
    const {
      record,
      settings,
      loading,
      onClickFulfillmentEdit,
      onClickFulfillmentDelete,
    } = this.props;

    const shipment = this.getShipmentForEdit();

    return (
      <OrderFulfillmentAdd
        {...this.props}
        record={record}
        settings={settings}
        shipment={shipment}
        loading={loading}
        onClickFulfillmentAdd={onClickFulfillmentEdit}
        onClickFulfillmentDelete={onClickFulfillmentDelete}
        onSubmitFulfillmentAdd={this.onSubmitForm}
      />
    );
  }
}
