import React from 'react';
import Icon from './icon';

export default class IconHelpLink extends React.Component {
  render() {
    const { hoverable } = this.props;
    return (
      <Icon
        fa="help-link"
        className={`icon-help-link ${
          hoverable !== undefined ? (hoverable ? 'hoverable' : '') : ''
        }`}
      />
    );
  }
}
