import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, cloneDeep, omit, pick } from 'lodash';
import TemplateEngine from 'swell-template';
import Alert from 'components/alert';
import { Form, Field, Label, TabView } from 'components/form';
import Modal from 'components/modal';
import LocaleFieldSelect from 'components/locale/field-select';
import TemplatePreview from 'components/template-preview';
import onTemplateContentKeyDown from 'utils/template';
import NotificationFields from '../notifications-fields';
import { isFieldValuesEqual } from '../notifications';
import { isValueEqual } from 'utils';
import api from 'services/api';
import $ from 'jquery';
import '../settings.scss';

export default class OrderPrintModal extends Component {
  static contextTypes = {
    user: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
  };
  constructor(props, context) {
    super(props, context);

    this.state = {
      duplicateFrom: null,
      noteValues: {},
      noteEdited: false,
      noteDefault: null,
      noteLoading: true,
      previewContentHtml: null,
    };

    this.onTemplateContentKeyDown = onTemplateContentKeyDown.bind(this);
    this.onChangeOrderPrintTemplate =
      this.onChangeOrderPrintTemplate.bind(this);
  }

  async componentDidMount() {
    const { fetchPrintTemplate, showOrderPrintTemplateId } = this.props;
    const result = await fetchPrintTemplate(showOrderPrintTemplateId);
    this.setState({
      noteLoading: false,
      noteDefault: result,
      noteValues: {
        tab: 'preview',
        ...result.record,
        content: result.content || result.defaultContent,
        fields:
          get(result, 'record.fields', false) ||
          (result.content === result.defaultContent
            ? get(result, 'defaultRecord.fields', '')
            : null),
      },
    });
  }

  componentWillReceiveProps() {
    if (this.templateModalScrollTop) {
      const $modal = $('.modal');

      if ($modal[0]) {
        $modal[0].scrollTop = this.templateModalScrollTop;
        this.templateModalScrollTop = null;
      }
    }
  }

  async onChangeOrderPrintTemplate(event) {
    const { value } = event.target;
    const { fetchPrintTemplate } = this.props;

    if (value) {
      this.setState({
        duplicateFrom: value,
        noteLoading: true,
      });

      const result = await fetchPrintTemplate(value);

      this.setState({
        noteLoading: false,
        noteDefault: result,
        noteValues: {
          tab: 'preview',
          ...result.record,
          content: result.content || result.defaultContent,
          fields:
            get(result, 'record.fields', false) ||
            (result.content === result.defaultContent
              ? get(result, 'defaultRecord.fields', '')
              : null),
        },
      });
    }
  }

  onCreatePrintTemplate = (values) => {
    const { onCreatePrintTemplate, onCloseCreateTemplateModal } = this.props;

    onCreatePrintTemplate(values).then((success) => {
      if (success) {
        onCloseCreateTemplateModal();
      }
    });
  };

  onUpdateOrderPrintTemplate = (values, record) => {
    const { onUpdatePrintTemplate, onCloseCreateTemplateModal } = this.props;

    onUpdatePrintTemplate({
      ...record,
      $set: {
        ...values,
      },
    }).then((success) => {
      if (success) {
        onCloseCreateTemplateModal();
      }
    });
  };

  onClickMakeDefault = (label, event) => {
    event.preventDefault();
    const { noteValues } = this.state;
    const { templates, fetchPrintTemplate } = this.props;
    const { client } = this.context;

    const defaultTemplate = templates.find((template) => template.default);

    this.context.openModal('Confirm', {
      title: 'Set as default',
      message: (
        <p>
          Are you sure you want to set <b>{label}</b> as your base print
          template?
        </p>
      ),
      actionType: 'danger',
      onConfirm: async () => {
        const defaultTemplateContent = await fetchPrintTemplate(
          defaultTemplate.id,
        );

        await this.onUpdateOrderPrintTemplate(
          { default: false },
          {
            ...pick(noteValues, ['api', 'model', 'client_id', 'method']),
            ...pick(defaultTemplateContent.record, [
              'fields',
              'query',
              'sample',
              'label',
              'description',
            ]),
            content: defaultTemplateContent.content,
            id: defaultTemplate.id.includes(`${client.id}.`) // this is needed for the default template(which was created automatically, on first time)
              ? defaultTemplate.id
              : `${client.id}.${defaultTemplate.id}`,
          },
        );

        await this.onUpdateOrderPrintTemplate(
          { default: true },
          {
            ...noteValues,
          },
        );
      },
    });
  };

  onChangeOrderPrintForm = (values, noteEdited) => {
    const { noteValues } = this.state;
    if (
      values.tab === 'preview' &&
      ((noteValues.tab !== 'preview' &&
        (!isFieldValuesEqual(this.previewContentFields, values.fields) ||
          !isValueEqual(this.previewContentLocale, values.preview_locale))) ||
        values.preview_locale !== noteValues.preview_locale)
    ) {
      this.previewContentLocale = values.preview_locale;
      this.previewContentFields = cloneDeep(values.fields);
    }
    this.setState({
      noteValues: {
        ...noteValues,
        ...values,
      },
      noteEdited,
    });
  };

  onClickRevertOrderPrintTemplate = (event) => {
    event.preventDefault();
    const {
      fetchPrintTemplate,
      onUpdatePrintTemplate,
      defaultRecord,
      defaultContent,
    } = this.props;
    const { showOrderPrintTemplateId, noteDefault, noteValues } = this.state;

    this.context.openModal('Confirm', {
      title: `Revert print template`,
      message: (
        <div>
          <p>Are you sure you want to revert to the original print template?</p>
          <p>
            Warning: this will undo all changes you've made to this template
          </p>
        </div>
      ),
      action: 'Revert',
      actionType: 'danger',
      onConfirm: async () => {
        if (
          noteDefault.content !== defaultContent ||
          !isValueEqual(noteDefault.record.$locale, defaultRecord.$locale) ||
          !isFieldValuesEqual(noteDefault.record.fields, defaultRecord.fields)
        ) {
          await onUpdatePrintTemplate({
            v2: defaultRecord.v2,
            content: defaultContent,
            contact: defaultRecord.contact,
            description: defaultRecord.description,
            $set: {
              query: defaultRecord.query,
              conditions: defaultRecord.conditions,
              sample: defaultRecord.sample,
              fields: defaultRecord.fields,
            },
          });
          const result = await fetchPrintTemplate(showOrderPrintTemplateId);
          if (result) {
            this.setState({
              noteDefault: result,
            });
          }
        }
        this.setState({
          noteValues: {
            ...omit(noteValues, ['default']),
            v2: defaultRecord.v2,
            content: defaultContent,
            description: defaultRecord.description,
            fields: defaultRecord.fields,
            $locale: defaultRecord.$locale,
            $set: {
              query: defaultRecord.query,
              conditions: defaultRecord.conditions,
              sample: defaultRecord.sample,
            },
          },
        });
      },
    });
  };

  getTemplateEngine = () => {
    const { client } = this.context;

    if (this.templateEngine) {
      return this.templateEngine;
    }
    return (this.templateEngine = new TemplateEngine({
      get: (url, data) => {
        return api.getLocalized(`/data/${url}`, data);
      },
      details: {
        ...client,
      },
    }));
  };

  render() {
    const {
      noteForm,
      showOrderPrintLabel,
      onClose,
      loading,
      record,
      onSubmitOrderPrintFieldsForm,
      showOrderPrintTemplateId,
      isUpdate,
      defaultRecord,
      templates,
      defaultContent,
    } = this.props;
    const { noteEdited } = this.state;
    const { client } = this.context;

    const { duplicateFrom, noteValues, noteLoading } = this.state;
    const defaultSelectValue =
      templates.find((template) => template.default) || {};
    const commonValues = {
      fields: noteValues.fields,
      description: noteValues.description,
    };
    const isV2 = client.v2 || client.v2_notifications;
    const preparedTemplates = templates.map((template) => {
      return {
        value: template.id,
        label: template.label,
        name: template.name,
        default: template.default,
      };
    });
    const isCustomized =
      defaultRecord &&
      !defaultRecord.client_id &&
      (noteValues.content !== defaultContent ||
        !isValueEqual(noteValues.query, defaultRecord.query) ||
        !isFieldValuesEqual(noteValues.fields, defaultRecord.fields));
    const hasLocales = client.locales.length > 0;

    return (
      <Form
        ref={noteForm}
        onSubmit={() => {
          isUpdate
            ? this.onUpdateOrderPrintTemplate(
                {
                  ...commonValues,
                  label: noteValues.label,
                  content: {
                    html: {
                      data: noteValues.content || noteValues.defaultContent,
                    },
                  },
                },
                {
                  ...noteValues,
                  id: noteValues.id.includes(`${client.id}.`) // this is needed for the default template(which was created automatically, on first time)
                    ? noteValues.id
                    : `${client.id}.${noteValues.id}`,
                },
              )
            : this.onCreatePrintTemplate({
                ...record,
                ...commonValues,
                content: noteValues.content || noteValues.defaultContent,
                templateName: noteValues.label,
                duplicateFrom: duplicateFrom || defaultSelectValue.name,
                default: false,
              });

          onClose();
        }}
        onChange={this.onChangeOrderPrintForm}
        autoFocus={!isUpdate}
        autoFocusEmpty
      >
        <Modal
          title={showOrderPrintLabel}
          width={960}
          actions={[
            {
              label: 'Save',
              type: 'submit',
              styleType: noteEdited ? 'default' : 'secondary',
            },
            ...(isUpdate &&
              (!record.default
                ? [
                    {
                      label: 'Set as default',
                      type: 'secondary',
                      className: 'left button-cancel',
                      onClick: this.onClickMakeDefault.bind(
                        this,
                        noteValues.label,
                      ),
                    },
                  ]
                : isCustomized && [
                    {
                      label: 'Revert to default',
                      type: 'secondary',
                      className: 'left button-cancel',
                      onClick: this.onClickRevertOrderPrintTemplate,
                    },
                  ])),
            noteValues.tab === 'fields' && {
              component: (
                <Field
                  type="toggle"
                  name="editing_fields"
                  className="left"
                  label="Edit fields"
                  defaultChecked={false}
                />
              ),
            },
          ]}
          tabs={[
            { value: 'preview', label: 'Preview' },
            noteValues.fields && { value: 'fields', label: 'Fields' },
            { value: 'template', label: 'Template' },
          ]}
          loading={loading || noteLoading}
          onClose={() => onClose(noteEdited)}
          localized={true}
          devtools={{ model: ':notifications', uri: record.id }}
        >
          {record && record.id ? (
            <fieldset>
              {!isV2 && (
                <Alert type="info">
                  <b>Note:</b> Your account is currently setup to send v1
                  notifications by default.
                  <br />
                  Please contact us when you're ready to make the switch.
                </Alert>
              )}
              <TabView value={noteValues.tab} active="preview" default>
                {!isUpdate && (
                  <div className="row">
                    <Field
                      type="select"
                      label="Copy from existing"
                      name="template"
                      onChange={this.onChangeOrderPrintTemplate}
                      value={defaultSelectValue.label}
                      options={preparedTemplates}
                      className="span2"
                    />
                    <div />
                  </div>
                )}
                <div className="row">
                  <Field
                    name="label"
                    label="Template name"
                    required
                    className="span2"
                    defaultValue={isUpdate ? noteValues.label : null}
                  />
                  {hasLocales && (
                    <LocaleFieldSelect
                      name="preview_locale"
                      label="Preview locale"
                      className="span2"
                      defaultValue={noteValues.preview_locale || client.locale}
                    />
                  )}
                </div>
                <div className="row">
                  <Field
                    type="textarea"
                    name="description"
                    label="Description"
                    className="span4"
                    placeholder="Optional"
                    defaultValue={noteValues.description}
                  />
                </div>
                <div>
                  <div className="form-field">
                    <Label>Template body</Label>
                    <TemplatePreview
                      className="settings-notifications-preview-frame subject"
                      client={client}
                      record={record}
                      values={noteValues}
                      defaults={noteValues}
                      getTemplateEngine={this.getTemplateEngine}
                      visible={!noteValues.tab || noteValues.tab === 'preview'}
                    />
                  </div>
                </div>
              </TabView>
              {noteValues.fields && (
                <TabView value={noteValues.tab} active="fields">
                  <NotificationFields
                    values={noteValues}
                    record={record}
                    onSubmit={onSubmitOrderPrintFieldsForm}
                    editing={noteValues.editing_fields}
                  />
                </TabView>
              )}
              <TabView value={noteValues.tab} active="template">
                {noteValues.tab === 'template' ? (
                  <Field
                    type="textarea"
                    name="content"
                    label="Template body"
                    defaultValue={noteValues.content}
                    autoSize={true}
                    className="code"
                    onKeyDown={this.onTemplateContentKeyDown}
                  />
                ) : (
                  <Field
                    type="hidden"
                    name="content"
                    value={noteValues.content}
                  />
                )}
              </TabView>
            </fieldset>
          ) : (
            <span>Print template {showOrderPrintTemplateId} not found</span>
          )}
        </Modal>
      </Form>
    );
  }
}
