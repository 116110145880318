import React from 'react';
import ReactCustomScrollbars from 'react-custom-scrollbars';
import './scrollbars.scss';

export default class Scrollbars extends React.Component {
  renderTrackVertical(props) {
    return (
      <div {...props} className="scrollbars-track scrollbars-track-vertical" />
    );
  }

  renderThumbVertical(props) {
    return (
      <div {...props} className="scrollbars-thumb scrollbars-thumb-vertical" />
    );
  }

  renderTrackHorizontal(props) {
    return (
      <div
        {...props}
        className="scrollbars-track scrollbars-track-horizontal"
      />
    );
  }

  renderThumbHorizontal(props) {
    return (
      <div
        {...props}
        className="scrollbars-thumb scrollbars-thumb-horizontal"
      />
    );
  }

  render() {
    const { className = '', ...props } = this.props;
    return (
      <ReactCustomScrollbars
        autoHide
        autoHeight={!!this.props.autoHeight}
        autoHeightMax={1000000}
        className={`Scrollbars ${className}`}
        hideTracksWhenNotNeeded
        renderTrackVertical={this.renderTrackVertical}
        renderThumbVertical={this.renderThumbVertical}
        renderTrackHorizontal={this.renderTrackHorizontal}
        renderThumbHorizontal={this.renderThumbHorizontal}
        {...props}
      >
        {this.props.children}
      </ReactCustomScrollbars>
    );
  }
}
