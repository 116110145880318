import React from 'react';
import PropTypes from 'prop-types';
import { Separator } from '@radix-ui/react-dropdown-menu';

export default class DropdownMenuSeparator extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className } = this.props;
    return (
      <Separator
        className={className ? className : 'dropdown-menu-separator'}
      />
    );
  }
}
