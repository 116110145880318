import React from 'react';
import PropTypes from 'prop-types';
import { Item } from '@radix-ui/react-dropdown-menu';

export default class DropdownMenuItem extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
  };

  render() {
    const { children, className } = this.props;
    return (
      <Item
        className={className ? className : 'dropdown-menu-item'}
        {...this.props}
      >
        {children}
      </Item>
    );
  }
}
