import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

function Modals({ modal, ...props }) {
  return modal.stack.map((activeModal, index) => {
    if (typeof activeModal.props === 'function') {
      const renderModal = activeModal.props;

      return (
        <Fragment key={index}>
          {renderModal({ modalIndex: index, ...props })}
        </Fragment>
      );
    }

    const ModalComponent =
      require(`../../containers/modals/${activeModal.id}.js`).default;

    return (
      <ModalComponent
        key={index}
        modalIndex={index}
        {...props}
        {...activeModal.props}
      />
    );
  });
}

const mapStateToProps = (state) => ({
  modal: state.modal,
});

export default connect(mapStateToProps)(withRouter(Modals));
