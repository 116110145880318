import React, { Fragment } from 'react';
import pt from 'prop-types';
import {
  get,
  set,
  unset,
  find,
  findLast,
  findLastIndex,
  filter,
  findIndex,
  map,
  each,
  reduce,
  cloneDeep,
  capitalize,
  words,
  omit,
  random,
  isEmpty,
} from 'lodash';
import objectid from 'objectid';
import { connect } from 'react-redux';
import UrlPattern from 'url-pattern';
import FlipMove from 'react-flip-move';
import DOMPurify from 'isomorphic-dompurify';
import cx from 'classnames';
import $ from 'jquery';

import api from 'services/api';

import Link from 'components/link';
import Scrollbars from 'components/scrollbars';
import Icon from 'components/icon';
import Loading from 'components/loading';
import Modal from 'components/modal';
import MenuForm from 'components/storefront/menu-form';
import {
  Form,
  Field,
  FieldLocalized,
  Tabs,
  TabView,
  LookupGeneric,
} from 'components/form';
import {
  ExpandPanel,
  FadeIn,
  MoveInLeft,
  MoveUp,
} from 'components/transitions';
import ButtonLink from 'components/button/link';
import ContentField from 'components/content/field';
import ColorPicker from '../form/ColorPicker/ColorPicker';
import CollectionItem from '../CollectionItem';
import { CONTENT_TYPES } from 'constants/content';
import {
  FONT_OPTIONS,
  FONT_WEIGHT_OPTIONS,
  FONT_SIZE_OPTIONS_NEW,
  findFontByName,
} from './fonts';
import './SettingsMenu.scss';
import actions from 'actions';
import {
  loadSettings,
  changeSetting,
  loadThemeConfig,
  updateThemeConfigPage,
  updateEditorSection,
  sendEditorMessage,
  setThemeHomePage,
} from '../../actions';
import {
  singularize,
  isValueEqual,
  slugify,
  evalConditions,
  localeFallbackValue,
  doesLocaleFallback,
} from 'utils';
import { productThumbUrl } from 'utils/product';
import { expandString } from 'utils/string';
import {
  populateLookupValues,
  depopulateLookupValues,
  getPopulateLookupQueries,
  findContentTypeIds,
  getImageProps,
} from 'utils/content';

import {
  sectionLabel,
  iconNameForSection,
  newPagePathname,
  moveListItem,
  localeNormalizedUrl,
  localizedUrl,
  directionIdx,
  DIRECTION,
  // eslint-disable-next-line import/no-relative-parent-imports
} from '../../utils/settingsMenu';

import MenuSectionTitle from './MenuSectionTitle';
import ContentModelTab from './ContentModelTab';

const VIEW_DISTANCE = 368;
const VIEW_DURATION = 250;
const NOOP = () => {};

const FONT_OPTIONS_FORMATTED = FONT_OPTIONS.map((group) => ({
  ...group,
  options: group.options.map((option) => ({
    value: option.value || option.name,
    label: option.label || option.name,
  })),
}));

@connect(
  (state) => ({
    storefront: state.storefronts.storefront,
    values: state.themeSettings.present || {},
    config: state.themeConfig,
    lookup: state.lookup,
    categories: state.categories,
    section: state.editor.section,
    scrollTop: state.editor.scrollTop,
    content: state.content,
    checkoutSettings: state.settings.checkout,
  }),
  (dispatch) => ({
    loadSettings: () => dispatch(loadSettings()),
    changeSetting: (id, value, setting, options) =>
      dispatch(changeSetting(id, value, setting, options)),
    loadThemeConfig: () => dispatch(loadThemeConfig()),
    updateThemeConfigPage: (page, prev) =>
      dispatch(updateThemeConfigPage(page, prev)),
    loadCategories: () => dispatch(actions.categories.load()),
    updateSection: (section, scrollTop) =>
      dispatch(updateEditorSection(section, scrollTop)),
    fetchStorefrontMenus: (id) => dispatch(actions.storefronts.fetchMenus(id)),
    updateStorefrontMenu: (id, data) =>
      dispatch(actions.storefronts.updateMenu(id, data)),
    setThemeHomePage: (value) => dispatch(setThemeHomePage(value)),
    loadContentCollections: () => dispatch(actions.content.loadCollections()),
    // duplication: ideally, this action would be encapsulated into a
    // different component, something like ContentModelTab
    fetchContentModelRecordList: (modelName) =>
      dispatch(actions.content.fetchModelRecordList(modelName)),
    // end duplication note
    deleteContentModelRecord: (modelName, id) =>
      dispatch(actions.content.deleteModelRecord(modelName, id)),
  }),
)
export default class SettingsMenu extends React.PureComponent {
  static propTypes = {
    // ownProps
    client: pt.object,
    location: pt.object,
    editorUrl: pt.string,
    editorRoute: pt.object,
    editorLoaded: pt.bool,
    editorLocale: pt.string,
    containerUrl: pt.string,
    showSection: pt.bool,
    usingMessageApi: pt.bool,

    setFrameUrl: pt.func,
    selectContent: pt.func,

    // mapStateToProps
    values: pt.object,
    config: pt.object,
    lookup: pt.object,
    content: pt.object,
    section: pt.object,
    scrollTop: pt.number,
    categories: pt.object,
    storefront: pt.object,
    checkoutSettings: pt.object,

    // mapDispatchToProps
    loadSettings: pt.func,
    loadCategories: pt.func,
    loadThemeConfig: pt.func,
    loadContentCollections: pt.func,
    fetchContentModelRecordList: pt.func,
    fetchStorefrontMenus: pt.func,
    updateStorefrontMenu: pt.func,
    showContentPublishedWarning: pt.func,
    showLocaleSelector: pt.func,
    updateThemeConfigPage: pt.func,
    updateSection: pt.func,
    setThemeHomePage: pt.func,
    deleteContentModelRecord: pt.func,
  };

  static contextTypes = {
    notifyError: pt.func,
    notifyDeleted: pt.func,
    openModal: pt.func,
  };

  state = {
    loaded: false,
    loadedByTime: false,
    tab: null,
    tabLoading: null,
    section: {},
    showSection: false,
    showCollectionItem: false,
    showAddPage: false,
    sectionTransitionAppear: false,
    sectionLoading: false,
    sectionSaving: false,
    fieldConditions: {},
    lookupField: null,
    lookupHeading: null,
    menus: null,
    editMenuId: null,
    loadedMenus: false,
    page: null,
    recordUrl: null,
    recordParams: null,
    pageContent: null,
    contentTypeAliases: null,
    toggleDefaults: {},
    showDefaultToggle: false,
    collectionStack: [],
    collectionLoading: false,
    viewTab: null,
    contextMenu: null,
    duplicatePage: null,
    duplicateValues: {},
  };

  scrollRef = null;
  scrollTimeout = null;
  fieldChangeHandlers = {};
  settingFieldChangeHandlers = {};
  pageFieldChangeHandlers = {};
  pageFieldsById = {};
  sendMessageTimers = {};
  updatePageTimers = {};
  collectionReady = false;
  navigatedTo = null;
  collectionStackIndex = undefined;
  hasLocalizedFields = false;

  async componentDidMount() {
    const {
      loadCategories,
      loadSettings,
      loadThemeConfig,
      loadContentCollections,
      editorUrl,
    } = this.props;
    let { section } = this.props;
    const initialShowSection = !!(
      section.pages ||
      section.settings ||
      section.checkout
    );

    // Note: order is important for conditions checking
    loadCategories();
    this.setLoadedByTimed();

    loadContentCollections();

    await loadThemeConfig(); // Need to load theme config before settings
    await Promise.all([loadSettings(), this.loadContentTypeAliases()]);

    this.bindSettingChangeHandlers(this.props);
    this.setFieldConditions(this.props);

    this.setState(
      {
        section,
        sectionTransitionAppear: !initialShowSection,
        initialShowSection,
        loaded: true,
      },
      () => {
        if (this.props.editorLoaded) {
          this.setEditorLoadedInitial(initialShowSection, section, editorUrl);
        }
      },
    );

    document.addEventListener('click', this.onClickOutsideComponentMenu);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { config, values, editorUrl, editorLoaded } = this.props;

    if (config !== nextProps.config) {
      if (!this.state.tab && nextProps.config) {
        const hasPages =
          nextProps.config.pages && nextProps.config.pages.length > 0;
        this.setState({
          tab: hasPages
            ? 'pages'
            : nextProps.config.lang
            ? 'lang'
            : nextProps.config.checkout
            ? 'checkout'
            : 'settings',
        });
      }

      this.bindSettingChangeHandlers(nextProps);
      this.setFieldConditions(nextProps);
    }

    if (values !== nextProps.values) {
      this.setFieldConditions(nextProps);
    }

    if (nextProps.editorUrl && values.storefront?.locale) {
      const {
        storefront: { locale, locales },
      } = values;

      const currentEditorUrl = localeNormalizedUrl(editorUrl || '/', {
        locales,
        defaultLocale: locale,
      });

      const newEditorUrl = localeNormalizedUrl(nextProps.editorUrl, {
        locales,
        defaultLocale: locale,
      });

      if (
        newEditorUrl &&
        currentEditorUrl !== newEditorUrl &&
        (currentEditorUrl || newEditorUrl !== '/')
      ) {
        if (this.navigatedTo && this.navigatedTo !== newEditorUrl) {
          if (!this.isShowingNewSection()) {
            this.setSectionFromUrl(newEditorUrl);
          }
        }

        if (!this.navigateSetPublishedWarning) {
          this.props.showContentPublishedWarning(false);
        }

        this.navigatedTo = null;
        this.navigateSetPublishedWarning = false;
      }
    }

    if (nextProps.editorLoaded && !editorLoaded) {
      this.setEditorLoadedInitial(
        this.state.initialShowSection,
        this.state.section,
        nextProps.editorUrl,
      );
    }
  }

  async componentDidUpdate(prevProps) {
    const { content } = this.props;
    const record = content && content.record;
    const { content: prevContent } = prevProps;

    // prepares state for showing an item belonging to a content model, for example
    // loaded via  onClickContentModelItem
    if (record && record !== prevContent.record) {
      const pageContent = {
        model: record.model,
        modelLabel: record.modelLabel,
        types: content.contentTypes,
        record: record.data,
        record_id: record.data.id,
        isContentNamespace: true,
      };

      // when the themeUrl exists (not for new items), display it on the right hand
      // side of the editor view.
      if (record.themeUrl) {
        this.navigateTo(record.themeUrl);
      }

      const { fields, values } = this.getPageFieldsFlat(pageContent);
      await populateLookupValues(values, fields);

      pageContent.record = values;
      pageContent.values = cloneDeep(values);

      this.setState({
        // based on onClickSavePage, if we have a record (id), then no need to
        // showAddPage
        showAddPage: false,
        //
        showSection: true,
        pageContent: pageContent,
        page: record.data,
        recordUrl: record.recordUrl,
        sectionLoading: false,

        // required to show the edit item fields:
        tab: 'pages',
        viewTab: null,
      });
    }
  }

  isShowingNewSection() {
    const { showAddPage, collectionStack } = this.state;

    if (showAddPage) {
      return true;
    }

    const lastUnsavedItem = findLast(
      collectionStack,
      (item) => item.showing && !item.record,
    );

    if (lastUnsavedItem) {
      return true;
    }

    return false;
  }

  setLoadedByTimed() {
    this.setState({ loadedByTime: true });
    // Not sure this was a good idea, makes left menu load after frame
    // setTimeout(() => {
    //   if (!this.props.editorLoaded && this.state.loaded) {
    //     this.setState({ loadedByTime: true });
    //   }
    // }, 3000);
  }

  setEditorLoadedInitial(shouldShow, section, editorUrl) {
    if (shouldShow && section.pages !== 'new') {
      this.showSection(section, editorUrl, true);
    }
  }

  componentWillUnmount() {
    if (this.scrollRef) {
      this.scrollRef.removeEventListener('scroll', this.onSectionScroll);
    }

    document.removeEventListener('click', this.onClickOutsideComponentMenu);
  }

  async loadContentTypeAliases() {
    this.setState({
      contentTypeAliases: await api.get(`/data/:content/:aliases`),
    });
  }

  resetSectionScrollTop() {
    const { scrollTop, showSection, location } = this.props;

    this.scrollRef = $('.SettingsMenu-view .Scrollbars > div').get(0);
    this.scrollRef.addEventListener('scroll', this.onSectionScroll);

    if (
      location.query.section ||
      location.query.pages ||
      location.query.settings
    ) {
      return;
    }

    if (showSection && scrollTop > 0) {
      setTimeout(() => (this.scrollRef.scrollTop = scrollTop), 100);
    }
  }

  resetCollectionScrollTop(index) {
    const scrollRef = $(
      `.SettingsMenu-view-collection-${index} .Scrollbars > div`,
    ).get(0);
    if (scrollRef) {
      scrollRef.scrollTop = 0;
    }
  }

  setSectionFromUrl(editorUrl) {
    const {
      config: { settings = [], lang = [] },
    } = this.props;
    const { section } = this.state;
    const editorUrlRel = editorUrl.replace(/^http(s)?:\/\/[^/]+/, '');

    for (let setting of settings) {
      if (setting.url && editorUrlRel !== '/') {
        const pattern = new UrlPattern(setting.url);

        if (pattern.match(editorUrlRel)) {
          this.showSection({ settings: setting.id });
          return;
        }
      }
    }

    for (let setting of lang) {
      if (setting.url && editorUrlRel !== '/') {
        const pattern = new UrlPattern(setting.url);

        if (pattern.match(editorUrlRel)) {
          this.showSection({ lang: setting.id });
          return;
        }
      }
    }

    if (section.pages || !section.settings) {
      const page = this.getPageFromUrl(editorUrlRel);

      if (page) {
        this.setState({ pageContent: null }, () => {
          this.showSection({ pages: page.id }, editorUrlRel);
        });
      }
    }
  }

  getRouteFromUrl(editorUrl, pageRoute) {
    const routes = pageRoute instanceof Array ? pageRoute : [pageRoute];

    for (let route of routes) {
      const testRoute = new UrlPattern(route);

      if (testRoute.match(editorUrl)) {
        return testRoute;
      }
    }
  }

  getPageFromUrl(editorUrl) {
    const {
      config: { pages = [] },
    } = this.props;

    for (let page of pages) {
      if (editorUrl === '/' && page.home) {
        return page;
      }

      if (page.route) {
        const route = this.getRouteFromUrl(editorUrl, page.route);

        if (route) {
          return page;
        }
      }
    }

    return null;
  }

  showSection(section, editorUrl = undefined, resetScrollTop = false) {
    if (
      !this.state.pageContent ||
      (!section.pages && editorUrl !== this.props.editorUrl) ||
      section.pages !== this.state.section.pages
    ) {
      this.resetPageContent(section.pages);
      this.fetchPageContent(section.pages, editorUrl || this.props.editorUrl);
    }

    this.setState({
      section,
      showSection: true,
      tab: section.pages
        ? 'pages'
        : section.lang
        ? 'lang'
        : section.checkout
        ? 'checkout'
        : 'settings',
      viewTab: null,
    });

    if (resetScrollTop) {
      this.resetSectionScrollTop();
    } else {
      this.scrollRef = $('.SettingsMenu-view .Scrollbars > div').get(0);
      this.scrollRef.scrollTop = 0;
    }

    if (section.lang) {
      this.focusContent(section.lang);
    } else if (section.checkout) {
      this.focusContent(section.checkout);
    } else if (section.settings) {
      this.focusContent(section.settings);
    }

    this.props.updateSection(section, 0);
    this.collectionReady = false;

    setTimeout(() => (this.collectionReady = true), 500);
  }

  hideSection() {
    this.setState({ showSection: false, showAddPage: false });
    this.props.updateSection({ [this.state.tab]: null }, 0);
    this.props.showLocaleSelector(false);
  }

  onSectionScroll = () => {
    clearTimeout(this.scrollTimeout);

    this.scrollTimeout = setTimeout(() => {
      const { updateSection } = this.props;
      const { section } = this.state;

      updateSection(section, this.scrollRef.scrollTop);
    }, 1000);
  };

  bindSettingChangeHandlers = ({ config, changeSetting }) => {
    const settings = config.settings || [];
    const langSettings = config.lang || [];
    const checkoutSettings = config.checkout || [];

    const allSettings = [...settings, ...langSettings, ...checkoutSettings];

    // Binding change handlers
    this.settingFieldChangeHandlers = reduce(
      allSettings,
      (acc, setting) => {
        return reduce(
          this.flattenFields(setting.fields),
          (fields, field) => {
            fields[field.id] = (field, id, value, locale) => {
              const exValue = get(this.props.values, id);

              if (isValueEqual(exValue, value)) {
                return;
              }

              let shouldRefresh = true;
              const { client, editorLocale } = this.props;
              const values = {};
              set(values, id, value);
              let sendValues = depopulateLookupValues(values, [field], 'slug');

              if (locale) {
                const parts = id.split('.$locale.');

                if (parts.length > 1 && editorLocale === locale) {
                  const context = get(this.props.values, parts[0]);
                  set(context, `$locale.${parts[1]}`, value);
                  const localeValue = localeFallbackValue({
                    context,
                    name: parts[1].split('.')[1],
                    locale,
                    localeConfigs: client.locales,
                  });
                  set(sendValues, id, localeValue);
                } else if (!editorLocale || editorLocale !== locale) {
                  shouldRefresh = false;
                }
              } else if (editorLocale && editorLocale !== client.locale) {
                const parts = id.split('.');
                const name = parts.pop();
                const context = get(this.props.values, parts.join('.'));
                const doesFallback = doesLocaleFallback({
                  context,
                  name,
                  locale: editorLocale,
                  localeConfigs: client.locales,
                });

                if (!doesFallback) {
                  shouldRefresh = false;
                }
              }

              const { editorRoute } = this.props;
              const routeEvents = editorRoute && editorRoute.events;

              changeSetting(id, value, setting, {
                refresh: shouldRefresh && routeEvents === false,
              });

              // Send editor message if enabled
              if (shouldRefresh && routeEvents !== false) {
                this.sendEditorMessageDebounced(
                  'settings.updated',
                  {
                    path: id,
                    value: get(sendValues, id),
                  },
                  250,
                );
              }
            };

            return fields;
          },
          acc,
        );
      },
      {},
    );
  };

  sendEditorMessageDebounced(eventType, details, timeout = 150) {
    if (this.sendMessageTimers[eventType]) {
      clearTimeout(this.sendMessageTimers[eventType]);
    }

    this.sendMessageTimers[eventType] = setTimeout(
      () => sendEditorMessage(eventType, details),
      timeout,
    );
  }

  isCollectionItemSaved(index) {
    const { collectionStack } = this.state;

    if (index === undefined) {
      return false;
    }

    for (let i = index; i >= 0; i--) {
      if (!collectionStack[i] || !collectionStack[i].record) {
        return false;
      }
    }

    return true;
  }

  bindPageChangeHandler = (field) => {
    if (!field.id) return;

    this.pageFieldChangeHandlers[field.id] = async (
      field,
      id,
      value,
      fieldPath,
      valuePath,
      instaUpdate = false,
      locale = undefined,
    ) => {
      if (field.id === 'type' && !value) {
        return true;
      }

      const { pageContent, recordUrl, collectionStack, showAddPage } =
        this.state;
      const collectionItem = collectionStack[field.collectionStackIndex];
      const isCollectionItemSaved = this.isCollectionItemSaved(
        field.collectionStackIndex,
      );
      let values = pageContent.values || cloneDeep(pageContent.record) || {};
      let exValue;
      let newValues;
      let isValueChanged = instaUpdate;
      //
      // this only `true` when a content level is changing itself
      // e.g. moving up or down
      // otherwise `shouldUpdatePageContent` defaults to `false`
      //
      let shouldUpdatePageContent = id === fieldPath;
      let shouldUpdateCollectionState = false;
      // Change state and get values for editor message

      if (collectionItem) {
        if (!collectionItem.showing) {
          return;
        }

        if (!this.collectionReady) {
          return;
        }

        const { values: itemValuesConst, valueId, index } = collectionItem;
        const thisPath = id.replace(`${valueId}.${index}.`, '');
        let itemValues = itemValuesConst;

        exValue = get(itemValues, thisPath);
        set(itemValues, thisPath, value);

        if (!isValueChanged && !isValueEqual(exValue, value)) {
          isValueChanged = true;
          shouldUpdateCollectionState = true;
          // Reset collection values when type changes
          if (field.id === 'type') {
            itemValues = collectionItem.values = {
              id: itemValues.id,
              type: value,
            };
            if (isCollectionItemSaved) {
              set(values, valuePath, itemValues);
            }
          }
        }
        // Depopulate lookup vals for unsaved content message
        if (isValueChanged && !isCollectionItemSaved) {
          let allValues = cloneDeep(values);
          set(allValues, valuePath, itemValues);
          allValues = this.depopulateAllLookupValues(allValues);
          newValues =
            valuePath !== undefined ? get(allValues, valuePath) : itemValues;
        }
      }

      // Indicate updating page content
      if (!collectionItem || isCollectionItemSaved) {
        exValue = get(values, id);
        set(values, id, value);
        if (!isValueEqual(exValue, value)) {
          isValueChanged = true;
          shouldUpdatePageContent = true;
        }
        if (isValueChanged) {
          const allValues = this.depopulateAllLookupValues(cloneDeep(values));
          newValues =
            valuePath !== undefined ? get(allValues, valuePath) : allValues;
        }
      }

      let success = true;
      if (isValueChanged) {
        if (pageContent.model) {
          const messageId = `${recordUrl}_message`;
          if (this.updatePageTimers[messageId]) {
            clearTimeout(this.updatePageTimers[messageId]);
          }
          this.updatePageTimers[messageId] = setTimeout(() => {
            const { client, editorLocale } = this.props;
            if (locale) {
              if (newValues && newValues.$locale && editorLocale === locale) {
                const localeValue = localeFallbackValue({
                  context: newValues,
                  name: field.id,
                  locale,
                  localeConfigs: client.locales,
                });
                newValues = cloneDeep(newValues);
                set(newValues, field.id, localeValue);
              } else if (editorLocale && editorLocale !== locale) {
                return;
              }
            } else if (editorLocale && editorLocale !== client.locale) {
              const doesFallback = doesLocaleFallback({
                context: newValues,
                name: field.id,
                locale: editorLocale,
                localeConfigs: client.locales,
              });
              if (!doesFallback) {
                return;
              }
            }
            sendEditorMessage('content.updated', {
              model: pageContent.model,
              id: pageContent.record_id || pageContent.new_record_id,
              path: valuePath,
              value: newValues,
            });
            // Focus content when type is first selected
            if (
              collectionItem &&
              !isCollectionItemSaved &&
              !collectionItem.focused
            ) {
              collectionItem.focused = true;
              this.focusContent(field, valuePath);
            }
            // Navigate to new page when first created
            if (
              showAddPage &&
              pageContent.new_record_id &&
              !pageContent.navigated
            ) {
              pageContent.navigated = true;
              this.navigateSetPublishedWarning = true;

              this.navigateTo(newPagePathname(this.props.config, pageContent));
            }
          }, 150);
        }

        if (shouldUpdatePageContent) {
          if (instaUpdate) {
            success = await this.updatePageContent(values, instaUpdate);

            this.setState({
              pageContent: { ...pageContent, values },
            });
          } else {
            if (this.updatePageTimers[recordUrl]) {
              clearTimeout(this.updatePageTimers[recordUrl]);
            }

            this.updatePageTimers[recordUrl] = setTimeout(() => {
              this.updatePageContent(values);

              this.setState({
                pageContent: { ...pageContent, values },
              });
            }, 500);
          }
        }
      }

      if (shouldUpdateCollectionState) {
        this.setState({
          collectionStack: [...collectionStack],
        });
      }

      return success;
    };
  };

  getFieldChangeHandler = (field, valueId, onChange) => {
    if (this.fieldChangeHandlers[valueId]) {
      return this.fieldChangeHandlers[valueId];
    }

    const parts = String(field.id).split('.');
    const lastId = parts.pop();
    const path = parts.join('.');

    return (this.fieldChangeHandlers[valueId] = (event, value, component) => {
      if (field.onChange) {
        field.onChange(event, value);
      }

      let locale;
      if (component) {
        if (component.props) {
          locale = component.props['data-locale'];
        } else if (component.dataset) {
          locale = component.dataset.locale;
        }
      }

      if (locale) {
        const localeId = `${path ? `${path}.` : ''}$locale.${locale}.${lastId}`;
        const localeValueId = valueId.replace(
          new RegExp(`${field.id}$`),
          localeId,
        );
        onChange(field, localeValueId, value, locale);
      } else {
        onChange(field, valueId, value);
      }
    });
  };

  setFieldConditions(props) {
    const {
      values,
      config: { settings = [] },
    } = props;
    const fieldConditions = {};

    for (let setting of settings) {
      for (let field of setting.fields) {
        fieldConditions[field.id] = this.evalFieldConditions(field, values);
      }
    }

    this.setState({ fieldConditions });
  }

  evalFieldConditions(field, values) {
    if (field.condition) {
      field.conditions = {
        [field.condition]: { $nin: [null, '', false] },
      };
    }

    if (field.conditions) {
      return evalConditions(field.conditions, values);
    }
  }

  onClickTab = (event) => {
    event.preventDefault();
    const { tab } = event.currentTarget.dataset;

    // This is awkward but it works
    if (!tab && this.state.section.checkout) {
      const { setFrameUrl, containerUrl } = this.props;
      setFrameUrl(containerUrl);
      this.hideSection();
    }

    this.setState({ tab });
  };

  /**
   * set state so that we show the root of SettingsMenu, the default render
   */
  onClickBackToRoot = (event) => {
    event.preventDefault();
    this.setState({ tab: 'pages' });
    this.hideSection();
  };

  onClickSection = (event) => {
    event.preventDefault();

    const { section, tab } = event.currentTarget.dataset;
    const nextTab = tab !== undefined ? tab : this.state.tab;

    new Promise((resolve) => {
      if (nextTab === 'pages') {
        this.setState({ pageContent: null }, () => resolve());
      } else {
        resolve();
      }
    }).then(() => {
      // Boolean section is used to bypass nested setting structure
      this.showSection({ [nextTab]: section === 'true' ? true : section });
      this.setState({ tab: nextTab, viewTab: null });
    });
  };

  onClickHideSection = (nextTab) => (event) => {
    event.preventDefault();

    const { config } = this.props;
    const { pageContent, showAddPage } = this.state;

    if (showAddPage) {
      new Promise((resolve) => {
        const hasValues = reduce(
          pageContent.record,
          (acc, val) => acc || !isEmpty(val),
          false,
        );

        if (hasValues) {
          this.context.openModal('ConfirmRouteLeave', {
            title: 'Unsaved edits',
            message: 'Do you want to keep editing or discard your changes?',
            action: 'Keep editing',
            cancelText: 'Discard changes',
            onConfirm: () => {
              resolve(false);
            },
            onCancel: () => {
              resolve(true);
            },
          });
          return;
        } else {
          resolve(true);
        }
      }).then((shouldHide) => {
        if (shouldHide) {
          this.hideSection();

          if (pageContent.new_record_id) {
            const pageRoute = config.page_route.replace(
              ':slug',
              pageContent.new_record_id,
            );

            if (pageRoute === this.props.editorUrl) {
              this.props.showContentPublishedWarning(false);
              this.navigateTo('/');
            }
          }
        }
      });
    } else {
      this.hideSection();
    }

    // ensure we are showing the desired tab: in some situations, as a work-around,
    // we might want to force something else other than "pages". E.g. showing
    // quizzes or tab === content/quizzes
    this.setState({ tab: nextTab });
  };

  async updatePageContent(valuesIn, instaUpdate = false) {
    let values = valuesIn;
    const { pageContent, recordUrl, recordParams, showAddPage } = this.state;

    if (!instaUpdate && isValueEqual(values, pageContent.record)) {
      return;
    }

    if (showAddPage) {
      this.setState({
        pageContent: {
          ...pageContent,
          record: {
            ...pageContent.record,
            ...values,
          },
          values,
        },
      });
    } else {
      values = this.depopulateAllLookupValues(cloneDeep(values));

      if (pageContent.hasDefaults) {
        await this.updateContentTypeDefaults(values);
      } else {
        if (!recordUrl) {
          return;
        }

        const setValues = { ...values };
        delete setValues.date_updated;

        if (!setValues.slug) {
          delete setValues.slug;
        }

        const recordBefore = pageContent.record;

        const record = await api.put(`/data/${recordUrl}`, {
          $content: true,
          $set: setValues,
          ...recordParams,
        });

        if (record.errors) {
          this.context.notifyError(record.errors);
          return false;
        } else {
          const { fields } = this.getPageFieldsFlat(pageContent);

          await populateLookupValues(record, fields);

          this.setState({
            pageContent: {
              ...pageContent,
              record,
              values: {
                ...pageContent.values,
                date_updated: record.date_updated,
                slug: values.slug || record.slug,
              },
            },
          });
        }

        const { model } = pageContent;

        if (model === 'content_pages') {
          Promise.resolve()
            .then(() => this.props.updateThemeConfigPage(record, recordBefore))
            .then((page) => {
              const recordUrl = expandString(
                page.content.replace(/^\//, ''),
                record,
              );
              this.setState({ page, recordUrl, section: { pages: page.id } });
            });
        } else if (model === 'content/quizzes') {
          // hard-coded update:
          //
          // ideally, updatePageContent would be part of ContentModelTab with
          // the other actions that deal with content model items.
          //
          // however, this is not currently possible because updatePageContent
          // is deeply linked within different behaviors of SettingsMenu.
          //
          // this is a workaround to ensure that the list of records displayed
          // shows the latest updates.
          const { fetchContentModelRecordList } = this.props;
          await fetchContentModelRecordList(model);
        }
      }
    }

    return true;
  }

  depopulateAllLookupValues(valuesIn, fields = undefined, path = '') {
    const valueFields =
      fields || this.getPageFieldsFlat(this.state.pageContent).fields;
    const values = depopulateLookupValues(valuesIn, valueFields, 'slug') || {};

    for (let field of valueFields) {
      const value = get(values, field.id);
      const fieldPath = path ? `${path}.${field.id}` : field.id;

      if (value instanceof Array) {
        for (let i = 0; i < value.length; i++) {
          if (!value[i]) continue;

          const fieldFields = this.flattenFields([
            ...(field.fields || []),
            ...(field.item_types
              ? this.getCollectionItemTypeFields(
                  field.item_types,
                  value[i].type,
                  fieldPath,
                )
              : []),
          ]);

          if (fieldFields.length) {
            value[i] = this.depopulateAllLookupValues(
              value[i],
              fieldFields,
              fieldPath,
            );
          }
        }
      } else if (value) {
        const fieldFields = this.flattenFields([
          ...(field.fields || []),
          ...(field.item_types
            ? this.getCollectionItemTypeFields(
                field.item_types,
                value.type,
                fieldPath,
              )
            : []),
        ]);

        if (fieldFields.length) {
          set(
            values,
            field.id,
            this.depopulateAllLookupValues(value, fieldFields, fieldPath),
          );
        }
      }
    }

    return values;
  }

  async updateContentTypeDefaults(values) {
    const { pageContent } = this.state;

    for (let typeId in pageContent.types.configs) {
      const type = pageContent.types.configs[typeId];
      const typeDefaults = pageContent.isContentNamespace
        ? type.defaults
        : type.defaults.content;
      const typeBasePath = pageContent.isContentNamespace ? '' : 'content.';
      const typeValues = Object.fromEntries(
        Object.entries(
          pageContent.isContentNamespace ? values : values.content,
        ).filter(
          ([fieldId, _value]) =>
            pageContent.types.paths[typeBasePath + fieldId] === typeId,
        ),
      );

      const defaults = reduce(
        typeValues,
        (acc, val, key) => {
          acc[key] = val;
          return acc;
        },
        { ...typeDefaults },
      );

      if (!isValueEqual(defaults, typeDefaults)) {
        const result = await api.put('/data/:content/{id}', {
          id: typeId,
          $set: {
            defaults: pageContent.isContentNamespace
              ? defaults
              : { content: defaults },
          },
        });

        if (result.errors) {
          this.context.notifyError(result.errors);
          return;
        }

        this.setState({
          pageContent: {
            ...pageContent,
            record: {
              ...pageContent.record,
              ...result,
            },
            values,
          },
        });
      }
    }
  }

  async fetchPageContent(pageId, editorUrl) {
    const {
      config: { pages },
    } = this.props;
    const page = find(pages, { id: pageId });

    if (!page || !page.content) {
      return;
    }

    this.setState({ sectionLoading: true });

    try {
      const route = this.getRouteFromUrl(editorUrl, page.route);
      let recordParams = (route && route.match(editorUrl)) || {};
      let recordUrl = expandString(
        page.content.replace(/^\//, ''),
        recordParams,
      );
      const modelUrl = recordUrl || page.content.replace(/^\//, '');
      let modelName = modelUrl.split('/')[0];
      const isContentNamespace = modelName === 'content';

      if (modelName === 'content') {
        modelName = `${modelName}_${modelUrl.split('/')[1]}`;
      }

      const model = await api.get(`/data/:models/{modelName}`, { modelName });

      if (!model) {
        // Params are probably undefined here
        this.setState({ sectionLoading: false });
        return;
      }

      let pageUrl;
      if (!route) {
        if (!recordUrl) {
          const possible = await this.getPagePossibleRecord(page);
          if (possible) {
            recordUrl = expandString(
              page.content.replace(/^\//, ''),
              possible.record,
            );
            pageUrl = possible.pageUrl;
          }
        } else if (page.page) {
          pageUrl = page.route;
        }
      }

      const record =
        recordUrl &&
        (await api.getLocalized(`/data/${recordUrl}`, {
          $content: true,
          ...recordParams,
        }));
      const idField = (model && model.secondary_field) || 'id';
      const pageContent = {
        model: modelName,
        types: await this.fetchContentTypesByModel(model),
        record,
        record_id: record && (record[idField] || record.slug || record.id),
        isContentNamespace,
      };
      const { fields, values } = this.getPageFieldsFlat(pageContent);

      await populateLookupValues(values, fields);

      pageContent.record = values;
      pageContent.values = cloneDeep(values);
      pageContent.defaults = this.getContentTypeDefaults(
        model,
        pageContent.types.configs,
      );
      pageContent.hasDefaults = !isEmpty(pageContent.defaults);

      this.setState({ page, pageContent, recordUrl, recordParams }, () => {
        this.setPageDefaultsEnabled();
      });

      if (page.home) {
        this.navigateTo('/');
      } else if (pageUrl) {
        this.navigateTo(pageUrl);
      }

      if (pageContent.model === 'content_pages') {
        this.props.showContentPublishedWarning(
          record ? !record.published : false,
        );

        if (page.home || pageUrl) {
          this.navigateSetPublishedWarning = true;
        }
      }
    } catch (err) {
      console.log(err);
    }

    this.setState({ sectionLoading: false });
  }

  async getPagePossibleRecord(page) {
    const variableIndex = page.content.indexOf('{');

    if (variableIndex >= 0) {
      const possibleModelUrl = page.content
        .substring(0, variableIndex - 1)
        .replace(/^\//, '');
      const result = await api.getLocalized(`/data/${possibleModelUrl}`, {
        limit: 5,
        $or: [{ active: true }, { published: true }],
      });

      if (result && result.results instanceof Array && result.results.length) {
        const record = result.results[random(0, result.results.length - 1)];
        const routes = page.route instanceof Array ? page.route : [page.route];

        for (let route of routes) {
          const pattern = new UrlPattern(route);

          try {
            const pageUrl = pattern.stringify(record);
            if (pageUrl) {
              return { pageUrl, record };
            }
          } catch (err) {
            console.error(err);
          }
        }
      }
    }
  }

  async fetchContentTypesByModel(model) {
    // Get storefront reference, as we'll be using its source_id
    const {
      storefront: { source_id: sourceId },
    } = this.props;

    this.applyStandardContentType(model);

    // Content ID source list to be included in this model's content types
    // Base model and custom content fields are added by default
    const includeContentSource = [model.id, 'custom.'];

    if (sourceId) {
      // Add theme specific content IDs
      includeContentSource.push(`theme.${sourceId}`);
    }

    const contentIds = findContentTypeIds(model.fields, {
      include: includeContentSource,
    });

    const contentTypes = await api.getLocalized('/data/:content', {
      id: { $in: contentIds.all },
    });

    const result = {
      ...contentIds,
      configs: reduce(
        contentTypes.results,
        (acc, config) => {
          acc[config.id] = config;
          return acc;
        },
        { ...CONTENT_TYPES[model.name] },
      ),
    };

    return result;
  }

  applyStandardContentType(model) {
    if (model.namespace === 'content' && CONTENT_TYPES[model.name]) {
      each(model.fields, (field) => {
        if (!field.content_id) {
          field.content_id = CONTENT_TYPES[model.name].id;
        }
      });
    }
  }

  getContentTypeDefaults(model, contentTypes) {
    const defaults = reduce(
      contentTypes,
      (acc, type) => {
        if ((type.collection || type.model) && type.defaults) {
          Object.assign(acc, type.defaults);
        }

        return acc;
      },
      {},
    );

    return defaults;
  }

  resetPageContent(pageId) {
    this.setState((state) => ({
      pageContent: null,
      showDefaultToggle: false,
      toggleDefaults: {
        ...state.toggleDefaults,
        [pageId]: undefined,
      },
    }));
  }

  renderPages() {
    const {
      props: {
        config: { pages },
      },
      state: { loaded },
    } = this;

    if (!loaded) {
      return <Loading />;
    }

    const contentPages = pages.filter((page) => page.page);
    const otherPages = pages.filter((page) => !page.page);

    return (
      <Fragment>
        {otherPages.length > 0 && (
          <Fragment>
            {otherPages.map((page, index) => (
              <MenuSectionTitle
                key={page.id}
                className="first last individual"
                onClick={this.onClickSection}
                faIcon={iconNameForSection(page)}
                data-tab={page.model ? page.model : 'pages'}
                data-section={page.id}
                title={sectionLabel(page)}
              />
            ))}
          </Fragment>
        )}
        {contentPages.length > 0 && (
          <Fragment>
            <div className="SettingsMenu-section-heading">Pages</div>
            {contentPages.map((page, index) => (
              <button
                key={page.id}
                data-tab="pages"
                data-section={page.id}
                className={cx({
                  first: index === 0,
                  inactive: !page.published,
                })}
                onClick={this.onClickSection}
                type="button"
              >
                <div className="SettingsMenu-section-icon">
                  {this.renderSectionIcon(page)}
                </div>
                <div className="SettingsMenu-section-title">
                  {sectionLabel(page)}
                </div>
                {this.renderContextMenuButton('page', page.id, 'ellipsis-v')}
                {page.home && (
                  <span className="SettingsMenu-home-badge">
                    <span className="label">{sectionLabel(page)}</span>
                    <span className="badge">Home</span>
                  </span>
                )}
              </button>
            ))}
          </Fragment>
        )}
        <section
          key="add"
          className={`${!contentPages.length ? 'first' : ''} last`}
        >
          <button
            className="button button-secondary button-md button-inline"
            onClick={this.onClickAddPage}
            type="button"
          >
            <Icon fa="plus" faType="solid" /> Add page
          </button>
        </section>
      </Fragment>
    );
  }

  /**
   * render pages that have an attribute "model" defined in the theme editor.json
   * configuration
   *
   * for example:
   *
   *     "pages": [
   *        {
   *          "label": "Quiz",
   *          "route": "/content/quizzes/:slug/",
   *          "model": "content/quizzes",
   *          "icon": "question-square"
   *        },
   *        {
   *          "label": "Product detail pages",
   *          "route": "/products/:slug/",
   *          "content": "/products/{slug}"
   *        }
   *     ],
   *
   * source: https://gitlab.com/schema/storefronts/horizon/-/blob/5c8f658efa512c35353fd9607dfb7faec454fcb1/config/editor.json#L436
   */
  renderModelPages() {
    const {
      config: { pages = [] },
    } = this.props;
    const { tab, showAddPage, sectionTransitionAppear, pageContent } =
      this.state;

    const modelPages = pages.filter((page) => page.model);

    return (
      <Fragment>
        {modelPages.map((page) => (
          <ContentModelTab
            key={`${page.model}-index`}
            active={tab === page.model}
            transitionAppear={sectionTransitionAppear}
            modelName={page.model}
            onClickBack={this.onClickBackToRoot}
            onClickNew={this.onContentModelItemAdd}
            onClickSave={this.onContentModelItemSave}
            faIcon={page.icon}
            showSave={Boolean(
              showAddPage && pageContent && pageContent.modelLabel,
            )}
            contextMenuButton={(data) =>
              this.renderContextMenuButton(
                'content_model',
                'content_model',
                'ellipsis-v',
                data,
              )
            }
          />
        ))}
      </Fragment>
    );
  }

  renderContextMenuButton(type, id, buttonIcon, data = undefined) {
    const dataset = reduce(
      data,
      (acc, val, key) => {
        acc[`data-${key}`] = val;
        return acc;
      },
      {},
    );

    return (
      <button
        {...dataset}
        data-id={id}
        data-type={type}
        className="SettingsMenu-context-menu-button"
        onClick={this.onClickShowContextMenu}
        type="button"
      >
        <Icon fa={buttonIcon} faType="light" />
      </button>
    );
  }

  renderContextMenu() {
    const { contextMenu, pageContent, collectionStack } = this.state;

    if (!contextMenu || !contextMenu.id) {
      return null;
    }

    const { id, type, index, x, y, dataset } = contextMenu;
    let items = [];

    switch (type) {
      case 'content_model': {
        items = [
          {
            label: 'Close',
            icon: 'times-circle',
            onClick: this.onClickCloseMenu,
          },
          {
            label: 'Delete',
            icon: 'edit',
            onClick: this.onClickDeleteContentModel,
          },
        ];

        break;
      }

      case 'page': {
        const page = find(this.props.config.pages, { id });
        const isContentPage =
          get(page, 'content', '').indexOf('/content/pages/') === 0;

        items = [
          {
            label: 'Close',
            icon: 'times-circle',
            onClick: this.onClickCloseMenu,
          },
          !page.home &&
            isContentPage && {
              label: 'Set as home page',
              icon: 'home',
              onClick: this.onClickSetHomePage,
            },
          // !isContentPage && {
          //   label: 'Edit defaults',
          //   icon: 'edit',
          //   onClick: this.onClickEditPageDefaults,
          // },
          isContentPage && {
            label: 'Duplicate',
            icon: 'copy',
            onClick: this.onClickDuplicatePage,
          },
          isContentPage && {
            label: 'Delete',
            icon: 'edit',
            onClick: this.onClickDeletePage,
          },
        ];

        break;
      }

      case 'collection': {
        let canMoveUp = false;
        let canMoveDown = false;
        let currentActiveIndex = -1;

        const lastCollectionItem = findLast(collectionStack, { showing: true });
        const collIndex = findIndex(collectionStack, lastCollectionItem);
        const prevCollectionItem = collectionStack[collIndex - 1];
        const collectionItem = !dataset.top
          ? lastCollectionItem
          : prevCollectionItem;
        const activeCollectionItem = find(collectionStack, { showing: true });
        const activeItem = activeCollectionItem && activeCollectionItem.values;
        const values = get(pageContent.values, id);

        if (values && activeItem) {
          currentActiveIndex = values.findIndex(
            (value) => value.id === activeItem.id,
          );
        }

        currentActiveIndex =
          currentActiveIndex !== -1 ? currentActiveIndex : index;

        if (currentActiveIndex > 0) {
          canMoveUp = true;
        }

        if (collectionItem) {
          const valuePath = `${collectionItem.valueId}.${collectionItem.index}`;
          const collectionValueId = `${id.replace(`${valuePath}.`, '')}`;

          if (
            get(
              collectionItem.values,
              `${collectionValueId}.${Number(currentActiveIndex) + 1}`,
            )
          ) {
            canMoveDown = true;
          }
        } else {
          if (
            get(pageContent.values, `${id}.${Number(currentActiveIndex) + 1}`)
          ) {
            canMoveDown = true;
          }
        }

        items = [
          {
            label: 'Close',
            icon: 'times-circle',
            onClick: this.onClickCloseMenu,
          },
          canMoveUp && {
            label: 'Move up',
            icon: 'caret-up',
            onClick: canMoveUp
              ? this.onClickMoveUpCollectionItem
              : this.onClickDisabled,
            disabled: !canMoveUp,
          },
          canMoveDown && {
            label: 'Move down',
            icon: 'caret-down',
            onClick: canMoveDown
              ? this.onClickMoveDownCollectionItem
              : this.onClickDisabled,
            disabled: !canMoveDown,
          },
          //{ label: 'Duplicate', icon: 'copy', onClick: this.onClickDuplicateCollectionItem },
          {
            label: 'Delete',
            icon: 'trash',
            onClick: this.onClickDeleteCollectionItem,
          },
        ];

        break;
      }

      default:
        break;
    }

    const visibleItems = items.filter((x) => Boolean(x));

    return (
      <Fragment>
        {visibleItems.length > 1 && (
          <ExpandPanel
            active={true}
            className="SettingsMenu-context-menu"
            style={{ left: x - 2, top: y }}
            maxWidth={185}
            maxHeight={visibleItems.length * 35}
          >
            <div ref="menu">
              {visibleItems.map(({ label, icon, onClick, disabled }) => (
                <button
                  key={label}
                  {...reduce(
                    dataset,
                    (acc, val, key) => {
                      acc[`data-${key}`] = val;
                      return acc;
                    },
                    {},
                  )}
                  data-id={id}
                  data-index={index}
                  disabled={disabled}
                  className={cx({ disabled })}
                  onClick={(event) => {
                    this.closeContextMenu();
                    onClick(event);
                  }}
                  type="button"
                >
                  <Icon fa={icon} faType="solid" /> {label}
                </button>
              ))}
            </div>
          </ExpandPanel>
        )}
      </Fragment>
    );
  }

  onClickShowContextMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { id, type, index, ...dataset } = event.currentTarget.dataset;
    const { left: x, top: y } = $(event.currentTarget).offset();

    this.setState({ contextMenu: { id, type, index, x, y: y - 35, dataset } });
    this.canClickOutsideMenu = false;
  };

  onClickOutsideComponentMenu = (event) => {
    setTimeout(() => (this.canClickOutsideMenu = true), 250);

    if (this.canClickOutsideMenu) {
      if (!$(event.target).closest(this.refs.menu).length) {
        if (this.state.contextMenu) {
          this.closeContextMenu();
        }
      }
    }
  };

  onClickDisabled = (event) => {
    event.preventDefault();
  };

  onClickCloseMenu = (event) => {
    event.preventDefault();
    this.closeContextMenu();
  };

  onChangeViewTab = (value = null) => {
    this.setState({ viewTab: value });
  };

  onClickSetHomePage = (event) => {
    event.preventDefault();
    const { config } = this.props;
    const { id } = event.currentTarget.dataset;
    const page = find(config.pages, { id });

    if (!page) {
      return;
    }

    const slug = page.content.replace('/content/pages/', '');
    this.props.setThemeHomePage(slug);
  };

  onClickEditPageDefaults = (event) => {
    event.preventDefault();
    this.closeContextMenu();
  };

  onClickDuplicatePage = (event) => {
    event.preventDefault();

    const { config } = this.props;
    const { id } = event.currentTarget.dataset;
    const page = find(config.pages, { id });

    if (!page) {
      return;
    }

    this.setState({ duplicatePage: page, duplicateValues: {} });
  };

  onCloseDuplicatePage = () => {
    this.setState({ duplicatePage: null });
  };

  onChangeDuplicateForm = (values) => {
    this.setState({ duplicateValues: { ...values } });
  };

  onSubmitDuplicatePageForm = (values) => {
    const { duplicatePage } = this.state;

    api.getLocalized(`/data${duplicatePage.content}`).then((origPage) => {
      api
        .post('/data/content/pages', {
          ...(origPage || undefined),
          ...values,
          slug: values.slug || slugify(values.name),
          id: undefined,
          date_created: undefined,
          date_updated: undefined,
        })
        .then((result) => {
          if (result && !result.errors) {
            this.props.loadThemeConfig().then(() => {
              this.showSection({ pages: result.slug });
              this.setState({
                tab: 'pages',
                viewTab: null,
                duplicatePage: null,
              });
            });
          }
        });
    });
  };

  onClickAddPage = (event) => {
    event.preventDefault();

    this.setState({ sectionLoading: true });

    const modelName = 'content_pages';

    api.get(`/data/:models/${modelName}`).then((model) => {
      if (!model) {
        this.setState({ sectionLoading: false });
        return;
      }

      this.fetchContentTypesByModel(model).then((types) => {
        const id = objectid().toString();
        const pageContent = {
          model: modelName,
          types,
          record: {},
          values: {},
          record_id: null,
          new_record_id: id,
          isContentNamespace: true,
          defaults: {},
          hasDefaults: false,
        };
        this.setState(
          {
            showAddPage: true,
          },
          () => {
            this.showSection({ pages: 'new' });
            this.setState({
              pageContent,
              tab: 'pages',
              viewTab: null,
              sectionLoading: false,
            });
          },
        );
      });
    });
  };

  onClickSavePage = (event) => {
    event.preventDefault();

    const { pageContent } = this.state;

    if (!this.validateSectionForm()) {
      return;
    }

    if (!pageContent.values.slug) {
      delete pageContent.values.slug;
    }

    const values = this.depopulateAllLookupValues(
      cloneDeep(pageContent.values),
    );

    api.post('/data/content/pages', values).then((result) => {
      if (result && result.errors) {
        if (result.errors.slug) {
          this.context.notifyError(
            'A page with this title or slug already exists',
          );
        } else {
          this.context.notifyError(result.errors);
        }
        return;
      }

      const recordId = result.slug || result.id;

      this.setState({ recordUrl: `content/pages/${recordId}` }, () => {
        this.props.loadThemeConfig().then(() => {
          this.setState({
            showAddPage: false,
            section: {
              pages: recordId,
            },
            pageContent: {
              ...pageContent,
              record: result,
              record_id: recordId,
              isContentNamespace: true,
            },
          });
          this.navigateTo(
            this.props.config.page_route.replace(':slug', recordId),
          );
        });
      });
    });
  };

  onClickDeletePage = (event) => {
    event.preventDefault();

    const { config, loadThemeConfig } = this.props;
    const { id } = event.currentTarget.dataset;
    const page = find(config.pages, { id });

    if (!page) {
      return;
    }

    this.context.openModal('ConfirmDelete', {
      title: 'this page',
      confirmInput: {
        description: `Enter the page's title confirm`,
        value: page.label,
      },
      onConfirm: () => {
        this.setState({ tabLoading: true });
        api
          .delete(`/data${page.content}`)
          .then(() => {
            return loadThemeConfig();
          })
          .finally(() => {
            this.setState({ tabLoading: false });
            this.hideSection();
            if (page.route === this.props.editorUrl) {
              this.navigateTo('/');
            }
          });
      },
    });
  };

  onClickDeleteContentModel = (event) => {
    event.preventDefault();
    const { model, contentitemid, label } = event.currentTarget.dataset;
    const { fetchContentModelRecordList, deleteContentModelRecord } =
      this.props;

    this.context.openModal('ConfirmDelete', {
      title: 'this item',
      confirmInput: {
        description: `Enter item's title to confirm`,
        value: label,
      },
      onConfirm: () => {
        this.setState({ tabLoading: true });
        deleteContentModelRecord(model, contentitemid)
          .then(() => {
            return fetchContentModelRecordList(model);
          })
          .finally(() => {
            this.setState({ tabLoading: false });
          });
      },
    });
  };

  onContentModelItemAdd = (model, contentTypes) => {
    const id = objectid().toString();
    const pageContent = {
      model: model.modelName || model.collection,
      modelLabel: model.label,
      types: contentTypes,
      record: {},
      values: {},
      record_id: null,
      new_record_id: id,
      isContentNamespace: true,
      defaults: {},
      hasDefaults: false,
    };

    this.setState(
      {
        showAddPage: true,
      },
      () => {
        this.showSection({ pages: 'new' });
        this.setState({
          pageContent,
          // tab 'pages' is required to display the collection fields when adding
          // and item
          tab: 'pages',
          viewTab: null,
          sectionLoading: false,
        });
      },
    );
  };

  onContentModelItemSave = (saveModelRecordCallback) => {
    // based on this.onClickSavePage, but the API request logic is extracted and
    // triggered via the callback
    const { pageContent } = this.state;

    if (!this.validateSectionForm()) {
      return;
    }

    if (!pageContent.values.slug) {
      delete pageContent.values.slug;
    }

    const values = this.depopulateAllLookupValues(
      cloneDeep(pageContent.values),
    );

    // after preparing the data, trigger the callback action to save it
    saveModelRecordCallback(values);
  };

  onClickMoveUpCollectionItem = (event) => {
    this.moveCollectionValue(event, DIRECTION.UP);
  };

  onClickMoveDownCollectionItem = (event) => {
    this.moveCollectionValue(event, DIRECTION.DOWN);
  };

  moveCollectionValue(event, direction) {
    event.preventDefault();

    const { pageContent, collectionStack } = this.state;
    const { id, index, fieldid: fieldId, top } = event.currentTarget.dataset;
    const lastCollectionItem = findLast(collectionStack, { showing: true });
    const collIndex = findIndex(collectionStack, lastCollectionItem);
    const prevCollectionItem = collectionStack[collIndex - 1];
    const field = this.pageFieldsById[fieldId];
    const changeHandler = this.pageFieldChangeHandlers[field.id];
    const fieldPath = fieldId;

    let valuePath = id;
    let value;
    // Moving from top collection item or collection field in prev item
    let collectionItem = !top ? lastCollectionItem : prevCollectionItem;

    if (collectionItem) {
      valuePath = `${collectionItem.valueId}.${collectionItem.index}`;
      const collectionValueId = `${id.replace(`${valuePath}.`, '')}`;
      const list = get(collectionItem.values, collectionValueId);
      value = moveListItem(list, index, direction);

      if (collectionItem.record) {
        this.setPrevCollectionItemValues(
          collectionStack,
          collectionItem,
          collectionItem.values,
        );
      }
    } else {
      valuePath = id.replace(`.${fieldPath}`, '');
      const list = get(pageContent.values, id);
      value = moveListItem(list, index, direction);

      if (lastCollectionItem?.record) {
        this.setPrevCollectionItemValues(
          collectionStack,
          lastCollectionItem,
          lastCollectionItem.values,
        );
      }
    }

    // If the action is triggered from top (from within section header)
    // we should also change the index to reflect the currently selected collection item
    if (top) {
      lastCollectionItem.index =
        Number(lastCollectionItem.index) + directionIdx(direction);
    }

    changeHandler(field, id, value, fieldPath, valuePath, true).then(
      (success) => {
        //
      },
    );
  }

  onClickDuplicateCollectionItem = (event) => {
    event.preventDefault();
  };

  onClickDeleteCollectionItem = (event) => {
    // TODO: make this logic common between move up/down and delete
    event.preventDefault();

    const { pageContent } = this.state;
    const {
      id,
      index,
      fieldid: fieldId,
      label: origLabel,
      top,
    } = event.currentTarget.dataset;
    const label = (singularize(origLabel) || 'item').toLowerCase();

    this.context.openModal('ConfirmDelete', {
      title: `this ${label}`,
      onConfirm: () => {
        const field = this.pageFieldsById[fieldId];
        const lastCollectionItem = findLast(this.state.collectionStack, {
          showing: true,
        });
        const collIndex = findIndex(
          this.state.collectionStack,
          lastCollectionItem,
        );
        const prevCollectionItem = this.state.collectionStack[collIndex - 1];

        let changeHandler;
        let fieldPath;
        let valuePath;
        let value;
        if (lastCollectionItem) {
          changeHandler = this.pageFieldChangeHandlers[field.id];
          fieldPath = fieldId;
          valuePath = `${lastCollectionItem.valueId}.${lastCollectionItem.index}`;
          let collectionValueId = `${id.replace(
            `${lastCollectionItem.valueId}.${lastCollectionItem.index}.`,
            '',
          )}`;
          value = get(lastCollectionItem.values, collectionValueId);

          if (!value) {
            if (prevCollectionItem) {
              valuePath = `${prevCollectionItem.valueId}.${prevCollectionItem.index}`;
              collectionValueId = `${id.replace(
                `${prevCollectionItem.valueId}.${prevCollectionItem.index}.`,
                '',
              )}`;
              value = get(prevCollectionItem.values, collectionValueId);
            }
          }
        }

        if (!value) {
          changeHandler = this.pageFieldChangeHandlers[field.id];
          fieldPath = fieldId;
          valuePath = id.replace(`.${fieldPath}`, '');
          value = get(pageContent.values, id);
        }

        if (value) {
          value = [...value];
          value.splice(index, 1);
        } else {
          // Should not happen
          return;
        }

        if (top) {
          // Adjust showing to affect changeHandler
          lastCollectionItem.showing = false;
          lastCollectionItem.deleted = true;
        }

        changeHandler(field, id, value, fieldPath, valuePath, true).then(
          (success) => {
            //
          },
        );
      },
    });
  };

  closeContextMenu() {
    this.setState({ contextMenu: null });
  }

  onChangeToggleDefaults = (event, value) => {
    this.setState(({ toggleDefaults, section }) => ({
      toggleDefaults: {
        ...toggleDefaults,
        [section.pages]: value,
      },
    }));
  };

  setPageDefaultsEnabled() {
    // Disabled temporarily
    return;
  }

  renderPageDefaultsToggle() {
    const { section, toggleDefaults } = this.state;
    return (
      <div className="SettingsMenu-body-defaults-toggle">
        <Field
          type="toggle"
          label="Edit globals"
          defaultChecked={toggleDefaults[section.pages]}
          onChange={this.onChangeToggleDefaults}
        />
      </div>
    );
  }

  renderSettingSections(settingsKey = 'settings') {
    const {
      props: { config },
      state: { loaded },
    } = this;

    if (!loaded) {
      return <Loading />;
    }

    const settings = config[settingsKey];

    if (!settings) return;

    if (loaded && !settings.length) {
      return (
        <Fragment>
          <div className="SettingsMenu-sections-none">
            There are no settings defined. Check that your theme configuration
            is located in /config/editor.json and formatted correctly.
            <br />
            <br />
            For more information, see{' '}
            <a href="https://support.swell.store">help docs</a>.
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {settings.map((section, index) => (
          <button
            key={section.id}
            data-tab={settingsKey}
            data-section={section.id}
            className={cx({
              first: index === 0,
              last: index === settings.length - 1,
            })}
            onClick={this.onClickSection}
            type="button"
          >
            <div className="SettingsMenu-section-icon">
              {this.renderSectionIcon(section)}
            </div>
            <div className="SettingsMenu-section-title">
              {sectionLabel(section)}
            </div>
          </button>
        ))}
      </Fragment>
    );
  }

  renderSectionHeader({ label, crumbs, back, backProps = {}, menu }) {
    return (
      <Fragment>
        <div className="SettingsMenu-header">
          <div className="SettingsMenu-title">
            {crumbs ? (
              <Fragment>
                <div className="SettingsMenu-title-label">{label}</div>
                <div className="SettingsMenu-title-crumbs">{crumbs}</div>
              </Fragment>
            ) : (
              label
            )}
          </div>
          {back && (
            <div className="SettingsMenu-back">
              <button
                className="as-link"
                onClick={back}
                {...backProps}
                type="button"
              >
                <Icon fa="arrow-left" faType="light" />
              </button>
            </div>
          )}
          {menu && <div className="SettingsMenu-header-context">{menu}</div>}
        </div>
        <div className="SettingsMenu-body-shadow" />
      </Fragment>
    );
  }

  renderSectionIcon(section) {
    const icon = iconNameForSection(section);

    return <Icon fa={icon} faType="solid" />;
  }

  renderCollectionFields(...args) {
    return this.renderPageFields(...args);
  }

  getCollectionItemTypeFields(
    itemTypes,
    valueType = undefined,
    path = undefined,
    fieldParts = undefined,
  ) {
    const { pageContent } = this.state;
    let fields = [];

    if (valueType === undefined) {
      fields = reduce(
        itemTypes,
        (acc, type) => {
          const itemType = find(itemTypes, (type) =>
            typeof type === 'string'
              ? get(pageContent.types.configs, type)
              : type,
          );

          if (itemType?.fields) {
            acc.push(...itemType.fields);
          }

          return acc;
        },
        [],
      );
    } else {
      const itemType = find(
        itemTypes,
        (type) => type === valueType || type.id === valueType,
      );

      if (itemType && itemType.fields) {
        fields = [...itemType.fields];
      } else if (path) {
        // TODO: rework types.paths so this contains tested ones
        const typeIds = pageContent.types.paths[`${path}__types`];
        //
        const typeId = typeIds && typeIds[valueType];
        const typeContentType = typeId && pageContent.types.configs[typeId];

        if (typeContentType) {
          const subFields =
            fieldParts && fieldParts.length
              ? reduce(
                  typeContentType.fields,
                  (acc, field) => {
                    acc.push(
                      ...(field.type === 'field_group' ||
                      field.type === 'field_row'
                        ? field.fields
                        : [field]),
                    );

                    return acc;
                  },
                  [],
                )
              : typeContentType.fields;
          fields = [...subFields];
        }
      }
    }

    return fields;
  }

  getPageFields(
    pageContent,
    fieldPath = undefined,
    valuePath = undefined,
    collectionStackIndex = undefined,
  ) {
    const { config } = this.props;
    const { section, collectionStack } = this.state;

    const page = find(config.pages, { id: section.pages });

    let values = pageContent.values || pageContent.record || {};
    const collectionItem = collectionStack[collectionStackIndex] || {};

    let fields;

    if (fieldPath) {
      const fieldParts = fieldPath.split('.');
      const basePath = !pageContent.isContentNamespace
        ? `content.${fieldParts[0]}`
        : fieldParts[0];
      const contentId = pageContent.types.paths[basePath];
      // TODO: this only considers one content type but it's possible there could be nested ones...
      const contentType = pageContent.types.configs[contentId];

      if (contentType) {
        let path;
        let parent = contentType;
        const valueParts = valuePath.split('.');
        fields = parent.fields;

        while (parent && fieldParts.length) {
          const fieldPart = fieldParts.shift();
          let valuePart = valueParts.shift();

          if (valueParts[0] !== fieldParts[0]) {
            valuePart = `${valuePart}.${valueParts.shift()}`;
          }

          const field = find(fields, { id: fieldPart });
          fields = field ? field.fields : [];
          parent = field;
          path = path ? `${path}.${fieldPart}` : fieldPart;
          values = get(values, valuePart);

          if (
            values &&
            values.type &&
            field &&
            field.item_types &&
            field.item_types.length > 0
          ) {
            fields = [
              ...(fields || []),
              ...this.getCollectionItemTypeFields(
                field.item_types,
                values.type,
                path,
                fieldParts,
              ),
            ];
          }
        }

        fields = fields ? [...fields] : [];

        const typeIds = pageContent.types.paths[`${fieldPath}__types`];

        if (
          typeIds &&
          parent &&
          parent.item_types &&
          parent.item_types.length > 0 &&
          !collectionItem.record
        ) {
          fields.unshift({
            id: 'type',
            label: 'Type',
            type: 'select',
            placeholder: 'Choose a type',
            onChange: () => this.resetCollectionScrollTop(collectionStackIndex),
            options: parent.item_types
              .map((type) => {
                if (typeof type === 'string') {
                  const contentId = typeIds[type];
                  const contentType = pageContent.types.configs[contentId];

                  if (contentType) {
                    return {
                      value: type,
                      label: contentType.name || contentType.id,
                    };
                  }
                } else if (type && type.id) {
                  return {
                    value: type.id,
                    label: type.label || type.id,
                  };
                }

                return null;
              })
              .filter((obj) => Boolean(obj)),
          });
        }
      }
    } else {
      const modelContentTypes = filter(
        pageContent.types.configs,
        (config) => config.model,
      );

      fields = modelContentTypes.reduce((acc, contentType) => {
        if (Array.isArray(contentType.fields)) {
          acc.push(...contentType.fields);
        }

        return acc;
      }, []);

      if (pageContent.model === 'content_pages') {
        fields = [
          (!page || !page.home) && {
            id: 'published',
            type: 'toggle',
            label: 'Live',
            onChange: (event, value) =>
              this.props.showContentPublishedWarning(!value),
          },
          {
            type: 'field_group',
            label: 'Content',
            fields: [
              {
                id: 'name',
                type: 'short_text',
                label: 'Title',
                required: true,
                localized: true,
              },
              ...fields,
            ],
          },
          {
            type: 'field_group',
            label: 'SEO',
            fields: [
              {
                id: 'slug',
                type: 'short_text',
                ui: 'slug',
                label: 'Slug',
                placeholder: slugify(
                  pageContent.values && pageContent.values.name,
                ),
                disabled: page && page.home ? true : undefined,
                help:
                  page && page.home
                    ? "Your home page slug can't be edited here"
                    : undefined,
              },
              {
                id: 'meta_title',
                type: 'short_text',
                label: 'Meta title',
                placeholder: pageContent.values && pageContent.values.name,
                localized: true,
              },
              {
                id: 'meta_description',
                type: 'long_text',
                label: 'Meta description',
                localized: true,
              },
            ],
          },
        ];
      }
    }

    // Append collection stack index for change handler
    if (fields && collectionStackIndex !== undefined) {
      for (const field of fields) {
        field.collectionStackIndex = collectionStackIndex;

        if (field.fields) {
          for (const subField of field.fields) {
            subField.collectionStackIndex = collectionStackIndex;
          }
        }
      }
    }

    return { fields, values };
  }

  flattenFields(fields) {
    return reduce(
      fields,
      (acc, field) => {
        acc.push(
          ...(field.type === 'field_group' || field.type === 'field_row'
            ? field.fields
            : [field]),
        );
        return acc;
      },
      [],
    );
  }

  getPageFieldsFlat(pageContent, ...args) {
    const { fields, values } = this.getPageFields(pageContent, ...args);
    const flatFields = this.flattenFields(fields);
    return { fields: flatFields, values };
  }

  renderPageFields(
    fieldPath = undefined,
    valuePathIn = undefined,
    collectionStackIndex = undefined,
  ) {
    const { page, pageContent } = this.state;

    if (!pageContent) {
      return (
        <div className="note">
          No content fields defined in editor.json.
          <br />
          <br />
          For more information, see{' '}
          <a href="https://support.swell.store">help docs</a>.
        </div>
      );
    }

    const valuePath =
      !pageContent.isContentNamespace && valuePathIn === undefined
        ? `content`
        : valuePathIn;

    const { fields, values } = this.getPageFields(
      pageContent,
      fieldPath,
      valuePath,
      collectionStackIndex,
    );

    if (!fields) {
      return null;
    }

    if (this.collectionStackIndex === collectionStackIndex) {
      this.hasLocalizedFields = false;
    }

    const result = (
      <Fragment>
        {this.renderFields(fields, {
          values,
          page,
          collectionStackIndex,
          onFieldId: (field) => {
            const fieldId = fieldPath ? `${fieldPath}.${field.id}` : field.id;
            this.pageFieldsById[fieldId] = field;
            return fieldId;
          },
          onValueId: (field) => {
            return valuePath ? `${valuePath}.${field.id}` : field.id;
          },
          onLabel: (field) =>
            field.label || capitalize(words(field.name || field.id)),
          onValue: (field, id) => {
            if (collectionStackIndex !== undefined) {
              const { collectionStack } = this.state;
              const {
                values: itemValues,
                valueId,
                index,
              } = collectionStack[collectionStackIndex];
              const thisPath = id.replace(`${valueId}.${index}.`, '');
              return [get(itemValues, thisPath), itemValues];
            }
            return [get(values, id), values];
          },
          onChange: (field, id, value, locale) => {
            return get(this.pageFieldChangeHandlers, field.id, NOOP)(
              field,
              id,
              value,
              fieldPath,
              valuePath,
              false,
              locale,
            );
          },
        })}
      </Fragment>
    );

    if (this.collectionStackIndex === collectionStackIndex) {
      this.props.showLocaleSelector(this.hasLocalizedFields);
    }

    return result;
  }

  renderSettingFields(settingsKey = 'settings') {
    const { config, values } = this.props;
    const { section, showSection } = this.state;

    const setting =
      typeof section[settingsKey] === 'string'
        ? find(config[settingsKey], { id: section[settingsKey] })
        : config[settingsKey];
    const fields = get(setting, 'fields');

    if (!fields) {
      return null;
    }

    this.hasLocalizedFields = false;

    const result = this.renderFields(fields, {
      setting,
      onFieldId: (field) => field.id,
      onValueId: (field) => field.id,
      onLabel: (field) => field.label || capitalize(field.name || field.id),
      onValue: (field) => {
        const parts = String(field.id).split('.');
        const lastId = parts.pop();
        const scope = get(values, parts.join('.'));
        return [get(scope, lastId), scope];
      },
      onChange: (field, id, value, locale) =>
        get(this.settingFieldChangeHandlers, field.id, NOOP)(
          field,
          id,
          value,
          locale,
        ),
    });

    this.props.showLocaleSelector(showSection && this.hasLocalizedFields);

    return result;
  }

  renderFields(fields, params) {
    const { fieldConditions } = this.state;
    const { values } = params;

    const tabs = filter(fields, (field) => field.type === 'field_group').map(
      (field) => ({
        value: params.onLabel(field),
        label: params.onLabel(field),
      }),
    );

    if (tabs.length > 0) {
      params.tabs = tabs;
    }

    return map(fields, (field, index) => {
      if (!field || !field.type) {
        return;
      }
      if (field.conditions) {
        const { pageContent } = this.state;

        let fieldVals = values;
        if (pageContent && !pageContent.isContentNamespace) {
          fieldVals = values.content;
        }

        const active =
          fieldConditions[field.id] !== undefined
            ? fieldConditions[field.id]
            : this.evalFieldConditions(field, fieldVals);

        return (
          <FadeIn
            active={active}
            transitionAppear={false}
            key={index}
            className="SettingsMenu-field"
          >
            <div>{this.renderField(field, params, index, active)}</div>
          </FadeIn>
        );
      }
      return (
        <Fragment key={index}>
          <div className="SettingsMenu-field">
            {this.renderField(field, params, index)}
          </div>
        </Fragment>
      );
    });
  }

  renderField(rawField, params, index, active = true) {
    const { contentTypeAliases, viewTab } = this.state;
    const { onFieldId, onValueId, onLabel, onValue, onChange, tabs, setting } =
      params;
    const { lookup } = this.props;

    let field = rawField;
    if (contentTypeAliases && contentTypeAliases[rawField.type]) {
      field = { ...rawField, ...contentTypeAliases[rawField.type] };
    }

    if (!setting) {
      this.bindPageChangeHandler(field);
    }

    const fieldId = onFieldId(field);
    const valueId = onValueId(field);
    const label = onLabel(field);
    const [value, scope] = onValue(field, valueId);
    const fieldName = String(field.id).split('.').pop();
    const onChangeHandler = this.getFieldChangeHandler(
      field,
      valueId,
      onChange,
    );

    if (field.localized && active) {
      if (field.collectionStackIndex === undefined) {
        this.hasLocalizedFields = true;
      } else if (field.collectionStackIndex === this.collectionStackIndex) {
        this.hasLocalizedFields = true;
      }
    }

    switch (field.type) {
      case 'heading':
        return (
          <div
            className={`SettingsMenu-field-heading ${
              index === 0 ? 'first' : index
            }`}
          >
            {label}
          </div>
        );

      case 'font_family': {
        const fontName =
          value && typeof value === 'object' ? value.name : undefined;

        const fontValue = findFontByName(
          fontName,
          value && typeof value === 'object' ? value.weight : undefined,
        );

        const weightOptions = fontValue
          ? FONT_WEIGHT_OPTIONS.filter(
              (weight) => fontValue.weights.indexOf(weight.value) !== -1,
            )
          : FONT_WEIGHT_OPTIONS;

        return (
          <div className="SettingsMenu-field-font">
            <Field
              type="select"
              label={label}
              placeholder={field.placeholder}
              help={field.description}
              hint={field.hint}
              required={field.required}
              options={FONT_OPTIONS_FORMATTED}
              defaultValue={fontValue && fontValue.name}
              onChange={(event, value) => {
                const newFontValue = omit(
                  findFontByName(value, fontValue && fontValue.weight),
                  ['weights', 'type'],
                );
                onChangeHandler(event, newFontValue);
              }}
            />
            <Field
              type="select"
              label={<span>&nbsp;</span>}
              required={field.required}
              options={weightOptions}
              defaultValue={fontValue && fontValue.weight}
              onChange={(event, value) => {
                onChangeHandler(
                  event,
                  fontValue && {
                    provider: fontValue.provider,
                    name: fontValue.name,
                    weight: value,
                    fallback: fontValue.fallback,
                  },
                );
              }}
            />
          </div>
        );
      }

      case 'font_size':
        return (
          <Field
            name={fieldId}
            type="select"
            label={label}
            placeholder={field.placeholder}
            help={field.description}
            hint={field.hint}
            required={field.required}
            options={FONT_SIZE_OPTIONS_NEW}
            defaultValue={value}
            onChange={onChangeHandler}
          />
        );

      case 'color':
        return (
          <ColorPicker
            name={fieldId}
            label={label}
            placeholder={field.placeholder}
            help={field.description}
            hint={field.hint}
            required={field.required}
            value={value}
            onChange={onChangeHandler}
          />
        );

      case 'menu':
        return this.renderLookupField({
          field,
          model: 'menus',
          value,
          valueId,
        });

      case 'lookup':
        return this.renderLookupField({
          field,
          model: field.model,
          image:
            field.model === 'products'
              ? productThumbUrl(value || {})
              : undefined,
          value,
          valueId,
        });

      case 'icon':
        return this.renderLookupField({
          field,
          model: 'icons',
          value,
          valueId,
        });

      case 'html':
        return (
          <p
            className="note"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(field.content, {
                WHOLE_DOCUMENT: true,
              }),
            }}
          />
        );

      case 'collection': {
        const items = filter(value, (val) => !isEmpty(val));
        const typeLabels = this.getTypeLabels(items, fieldId, field);

        return (
          <Fragment>
            <div className="SettingsMenu-section-heading">{label}</div>
            <FlipMove className="SettingsMenu-collection">
              {map(items, (values, index) => (
                <CollectionItem
                  key={values.id || values.heading || index}
                  className={index === 0 ? 'first' : ''}
                  values={values}
                  data-path={valueId}
                  data-field={fieldId}
                  data-index={index}
                  field={field}
                  onClick={this.onClickCollectionItem}
                  typeLabel={typeLabels[index]}
                  label={this.renderCollectionItemLabel(values, field, index)}
                  contextMenuButton={this.renderContextMenuButton(
                    'collection',
                    valueId,
                    'ellipsis-v',
                    {
                      index,
                      label,
                      fieldid: fieldId,
                    },
                  )}
                />
              ))}
              <section
                key="add"
                className={`${!items.length ? 'first' : ''} last`}
              >
                <button
                  data-path={valueId}
                  data-field={fieldId}
                  data-index={value ? value.length : 0}
                  className="button button-secondary button-md button-inline"
                  onClick={this.onClickAddCollectionItem}
                  type="button"
                >
                  <Icon fa="plus" faType="solid" /> Add{' '}
                  {this.renderCollectionLabelSingular(field, label)}
                </button>
              </section>
            </FlipMove>
          </Fragment>
        );
      }

      case 'field_group':
        return (
          <Fragment>
            {tabs.length > 0 && label === tabs[0].label && (
              <Tabs
                defaultValue={tabs[0].label}
                items={tabs}
                onChange={this.onChangeViewTab}
                value={viewTab}
              />
            )}
            <TabView
              default={tabs[0].label}
              value={viewTab || tabs[0].label}
              active={label}
            >
              {this.renderFields(field.fields, { ...params, tabs: null })}
            </TabView>
          </Fragment>
        );

      case 'field_row':
        return this.renderFields(field.fields, { ...params, tabs: null });

      case 'generic_lookup':
        return (
          <LookupGeneric
            label={label}
            field={field}
            onChange={(newValue) => onChangeHandler({}, newValue)}
            lookup={lookup}
            value={value}
          />
        );

      default:
        return (
          <ContentField
            name={field.id}
            {...field}
            label={label}
            defaultValue={value !== undefined ? value : field.default}
            localeValue={scope && scope.$locale}
            localeFieldName={fieldName}
            localized={field.localized}
            onChange={onChangeHandler}
            debounce
            imageProps={getImageProps(field)}
          />
        );
    }
  }

  getTypeLabels(items, fieldId, field) {
    const { pageContent } = this.state;

    if (!items || !field.item_types) {
      return {};
    }

    const typeIds = pageContent.types.paths[`${fieldId}__types`] || {};
    const typeLabels = {};

    each(items, (item, index) => {
      if (item.type) {
        const typeId = typeIds[item.type];
        const typeContentType = typeId && pageContent.types.configs[typeId];

        if (typeContentType) {
          typeLabels[index] = typeContentType.name;
        }
      }
    });

    return typeLabels;
  }

  renderCollectionLabelSingular(field, label) {
    return singularize(
      (
        label ||
        field.label ||
        field.name ||
        field.id.split('.').pop()
      ).toLowerCase(),
    );
  }

  focusContent(fieldId, valueId = '') {
    this.props.selectContent(valueId || fieldId);
  }

  navigateTo(url) {
    const { editorLocale, editorUrl, usingMessageApi, setFrameUrl, values } =
      this.props;

    const locale = values.storefront?.locale;

    if (url !== editorUrl) {
      this.navigatedTo = url;

      const localizedNewUrl = localizedUrl(url, {
        editorLocale,
        defaultLocale: locale,
      });

      if (usingMessageApi) {
        sendEditorMessage('browser', {
          action: 'navigate',
          value: localizedNewUrl,
        });
      } else {
        setFrameUrl(localizedNewUrl);
      }
    }
  }

  onClickAddCollectionItem = (event) => {
    event.preventDefault();

    const { path, field, index } = event.currentTarget.dataset;
    const { pageContent } = this.state;
    const collectionItem = {
      key: Date.now(),
      showing: true,
      values: { id: objectid().toString() },
      record: null,
      valueId: path,
      fieldId: field,
      index,
      field: this.pageFieldsById[field],
    };
    const collectionStack = [
      ...this.state.collectionStack.filter((item) => item.showing),
      collectionItem,
    ];

    this.setState({ collectionStack });
    this.resetCollectionScrollTop(collectionStack.length - 1);

    sendEditorMessage('content.updated', {
      model: pageContent.model,
      id: pageContent.record_id || pageContent.new_record_id,
      path: `${path}.${index}`,
      value: collectionItem.values,
    });

    this.focusContent(field, `${path}.${index}`);
    setTimeout(() => (this.collectionReady = true), 500);
  };

  onClickCollectionItem = (event) => {
    event.preventDefault();

    const { path, field, index } = event.currentTarget.dataset;
    const { pageContent } = this.state;
    const lastCollectionItem = findLast(this.state.collectionStack, {
      showing: true,
    });
    const values = cloneDeep(
      lastCollectionItem
        ? get(
            lastCollectionItem.values,
            `${path.replace(
              `${lastCollectionItem.valueId}.${lastCollectionItem.index}.`,
              '',
            )}.${index}`,
          )
        : get(pageContent.values, `${path}.${index}`),
    );
    const nextCollectionItem = {
      key: Date.now(),
      showing: true,
      values,
      record: values || {},
      valueId: path,
      fieldId: field,
      index,
      field: this.pageFieldsById[field],
    };
    const collectionStack = [
      ...this.state.collectionStack.filter((item) => item.showing),
      nextCollectionItem,
    ];
    const collectionStackIndex = (this.collectionStackIndex =
      collectionStack.length - 1);

    const { fields } = this.getPageFieldsFlat(
      pageContent,
      field,
      `${path}.${index}`,
      collectionStackIndex,
    );

    const promise = populateLookupValues(values, fields);
    const lookupQueries = getPopulateLookupQueries();

    this.setState({
      collectionStack,
      viewTab: null,
      collectionLoading: lookupQueries.length > 0,
    });

    promise.then(() => {
      if (lookupQueries.length > 0) {
        nextCollectionItem.values = values;
        nextCollectionItem.record = values || {};
        this.setState({ collectionLoading: false, collectionStack });
      }
      this.resetCollectionScrollTop(collectionStackIndex);
      this.focusContent(field, `${path}.${index}`);
      setTimeout(() => (this.collectionReady = true), 500);
    });
  };

  onClickHideCollectionItem = (event) => {
    event.preventDefault();

    const { pageContent } = this.state;
    const { index } = event.currentTarget.dataset;
    const collectionStack = [...this.state.collectionStack];
    const collectionItem = collectionStack[index];

    // This is needed so we can detect navigation backwards in the app (e.g. the Horizon theme) rendered in the iframe
    sendEditorMessage('collection_item.hide', {
      path: `${collectionItem.valueId}.${collectionItem.index}`,
      model: pageContent.model,
    });

    new Promise((resolve) => {
      if (
        !isValueEqual(
          collectionItem.values,
          collectionItem.record || { id: collectionItem.values.id },
        )
      ) {
        this.context.openModal('ConfirmRouteLeave', {
          title: 'Unsaved edits',
          message: 'Do you want to keep editing or discard your changes?',
          action: 'Keep editing',
          cancelText: 'Discard changes',
          onConfirm: () => {
            resolve(undefined);
          },
          onCancel: () => {
            this.collectionStackIndex = index > 0 ? index - 1 : undefined;
            resolve(true);
          },
        });
      } else {
        this.collectionStackIndex = index > 0 ? index - 1 : undefined;
        resolve(false);
      }
    }).then((shouldRevert) => {
      if (shouldRevert !== undefined) {
        collectionItem.showing = false;

        this.setState({ collectionStack, viewTab: null });

        this.collectionReady = false;

        setTimeout(() => (this.collectionReady = true), 500);
      }
      if (shouldRevert === true) {
        if (!collectionItem.record) {
          const prevCollectionItem = collectionStack[index - 1];
          let prevValue;

          if (prevCollectionItem) {
            prevValue = get(prevCollectionItem.values, collectionItem.field.id);

            if (
              prevValue &&
              typeof prevValue === 'object' &&
              !(prevValue instanceof Array)
            ) {
              prevValue = this.depopulateAllLookupValues(
                prevValue,
                [collectionItem.field],
                prevCollectionItem.fieldId,
              );
            }
          } else {
            const values = this.depopulateAllLookupValues(
              cloneDeep(pageContent.values),
            );
            prevValue = get(values, collectionItem.valueId);
          }

          sendEditorMessage('content.updated', {
            model: pageContent.model,
            id: pageContent.record_id || pageContent.new_record_id,
            path: collectionItem.valueId,
            value: prevValue,
          });

          this.focusContent(
            `${collectionItem.valueId}.${collectionItem.index - 1}`,
          );
        }
        collectionItem.deleted = true;
      } else if (shouldRevert === false) {
        setTimeout(() => {
          collectionItem.deleted = true;
        }, 1000);

        this.setState({ viewTab: null });
        this.setPrevCollectionItemValues(
          collectionStack,
          collectionItem,
          collectionItem.values,
        );
      }
    });
  };

  onClickSaveCollectionItem = (event) => {
    event.preventDefault();

    const { pageContent } = this.state;
    const collectionStack = [...this.state.collectionStack];
    const lastIndex = findLastIndex(collectionStack, { showing: true });
    const lastCollectionItem = findLast(collectionStack, { showing: true });
    const prevCollectionItem = collectionStack[lastIndex - 1];
    const isCollectionItemSaved = this.isCollectionItemSaved(lastIndex - 1);

    if (lastCollectionItem) {
      if (!this.validateCollectionForm(lastCollectionItem.key)) {
        return;
      }

      if (lastIndex >= 0 && (!prevCollectionItem || isCollectionItemSaved)) {
        const values = pageContent.values || {};
        const valueId = `${lastCollectionItem.valueId}.${lastCollectionItem.index}`;

        set(values, valueId, lastCollectionItem.values);

        this.setState({ sectionSaving: true });

        this.updatePageContent(values, true).then((success) => {
          this.setState({ sectionSaving: false, viewTab: null });

          lastCollectionItem.showing = false;

          this.setPrevCollectionItemValues(
            collectionStack,
            lastCollectionItem,
            lastCollectionItem.values,
          );
        });
      } else if (prevCollectionItem) {
        this.setState({ viewTab: null });

        lastCollectionItem.showing = false;

        this.setPrevCollectionItemValues(
          collectionStack,
          lastCollectionItem,
          lastCollectionItem.values,
        );
      }
    }
  };

  onClickRemoveCollectionItem = (event) => {
    event.preventDefault();

    const { pageContent } = this.state;
    const collectionStack = [...this.state.collectionStack];
    const lastIndex = findLastIndex(collectionStack, { showing: true });
    const lastCollectionItem = findLast(collectionStack, { showing: true });
    const prevCollectionItem = collectionStack[lastIndex - 1];
    const isCollectionItemSaved = this.isCollectionItemSaved(lastIndex - 1);

    if (lastCollectionItem) {
      const { values, field, index } = lastCollectionItem;
      const label = this.renderCollectionItemLabel(
        values,
        field,
        index,
      ).toLowerCase();

      this.context.openModal('Confirm', {
        title: `Remove ${label}`,
        message: 'Are you sure you want to remove this item?',
        action: 'Confirm',
        actionType: 'danger',
        onConfirm: () => {
          lastCollectionItem.showing = false;

          if (
            lastIndex >= 0 &&
            (!prevCollectionItem || isCollectionItemSaved)
          ) {
            const values = pageContent.values || {};
            const valueId = `${lastCollectionItem.valueId}.${lastCollectionItem.index}`;

            unset(values, valueId);

            this.setState({ sectionSaving: true });
            this.updatePageContent(values, true).then(() => {
              this.setState({ sectionSaving: false });

              lastCollectionItem.showing = false;

              this.setPrevCollectionItemValues(
                collectionStack,
                prevCollectionItem,
                lastCollectionItem,
              );
            });
          } else if (prevCollectionItem) {
            this.setPrevCollectionItemValues(
              collectionStack,
              prevCollectionItem,
              lastCollectionItem,
            );
          }
        },
      });
    }
  };

  setPrevCollectionItemValues(
    collectionStack,
    lastCollectionItem,
    values = undefined,
  ) {
    let lastItem = lastCollectionItem;
    let lastIndex = findIndex(collectionStack, lastItem);
    let prevItem = collectionStack[lastIndex - 1];

    while (prevItem) {
      const prevValueId = `${prevItem.valueId}.${prevItem.index}`;
      const thisValueId = `${lastItem.valueId}.${lastItem.index}`.replace(
        `${prevValueId}.`,
        '',
      );

      if (values !== undefined) {
        set(prevItem.values, thisValueId, values);
      } else {
        unset(prevItem.values, thisValueId);
      }

      lastIndex--;
      lastItem = prevItem;
      prevItem = collectionStack[lastIndex - 1];

      if (prevItem && !prevItem.record) {
        break;
      }
    }

    this.setState({
      collectionStack: [...collectionStack],
    });
  }

  validateSectionForm() {
    const form = this.refs.sectionForm;

    if (form && form.validate) {
      if (!form.validate()) {
        form.focusFirstError();
        return false;
      }
    }

    return true;
  }

  validateCollectionForm(key) {
    const form = this.refs[`collectionForm_${key}`];

    if (form && form.validate) {
      if (!form.validate()) {
        form.focusFirstError();
        return false;
      }
    }

    return true;
  }

  onClickMenuModal = (event) => {
    event.preventDefault();

    const {
      config,
      fetchStorefrontMenus,
      values: settingValues,
      storefront: { id: storefrontId },
    } = this.props;
    const { section } = this.state;
    const { id, field } = event.currentTarget.dataset;
    const lookupSection = find(config.settings, { id: section.settings });
    const lookupField = find(lookupSection.fields, { id: field });

    this.setState({
      editMenuId: id,
      lookupSection,
      lookupField,
      loadedMenus: false,
    });

    fetchStorefrontMenus(storefrontId).then((menus) => {
      set(settingValues, lookupField.id, find(menus, { id }));
      this.setState({ menus, loadedMenus: true });
    });
  };

  onCloseMenuModal = () => {
    this.setState({ editMenuId: null, lookupField: null });
  };

  onSaveMenuModal = (values) => {
    const {
      fetchStorefrontMenus,
      updateStorefrontMenu,
      values: settingValues,
      storefront: { id: storefrontId },
    } = this.props;
    const { lookupField } = this.state;

    updateStorefrontMenu(values.id, values).then(() => {
      fetchStorefrontMenus(storefrontId).then((menus) => {
        set(settingValues, lookupField.id, find(menus, { id: values.id }));
        this.setState({ menus });
        this.onCloseMenuModal();
        sendEditorMessage('settings.updated', {
          model: 'menus',
          value: menus,
        });
      });
    });
  };

  renderLookupField({ field, model, image, value, valueId }) {
    const label = singularize(model);

    return (
      <div className="SettingsMenu-field-lookup-container">
        <div className="SettingsMenu-field-lookup">
          {!!field.label && <label>{field.label}</label>}
          {value ? (
            <div className="SettingsMenu-field-lookup-value">
              {!!image && (
                <span className="image">
                  <img alt="" src={image} />
                </span>
              )}
              {field.type === 'menu' ? (
                <Link
                  onClick={this.onClickMenuModal}
                  data-id={value.id}
                  data-field={field.id}
                >
                  {value.name || value}
                </Link>
              ) : model === 'icons' ? (
                <span className="icon" key={value}>
                  <span
                    className="iconify"
                    data-icon={value}
                    data-width={50}
                    data-height={50}
                  />
                </span>
              ) : model ? (
                <a href={`/admin/${model}/${value.id}`} target="blank">
                  {value.label || value.name}
                </a>
              ) : (
                <b>{value.label || value.name || value}</b>
              )}
            </div>
          ) : field.type === 'menu' ? (
            <ButtonLink
              size="sm"
              type="default"
              onClick={this.onClickMenuModal}
              data-field={field.id}
              data-value-id={valueId}
            >
              Select {label}
            </ButtonLink>
          ) : (
            <ButtonLink
              size="sm"
              type="default"
              onClick={this.onClickLookupSelect}
              data-id={field.id}
              data-value-id={valueId}
            >
              Select {label}
            </ButtonLink>
          )}
        </div>
        {!!value && (
          <div className="SettingsMenu-field-lookup-actions">
            {field.type === 'menu' ? (
              <div className="row">
                <ButtonLink
                  size="sm"
                  type="default"
                  onClick={this.onClickMenuModal}
                  data-id={value.id}
                  data-field={field.id}
                  data-value-id={valueId}
                >
                  Edit {label}
                </ButtonLink>
                <ButtonLink
                  size="sm"
                  type="secondary"
                  onClick={this.onClickLookupSelect}
                  data-id={field.id}
                  data-value-id={valueId}
                >
                  Switch
                </ButtonLink>
              </div>
            ) : (
              <ButtonLink
                size="sm"
                type="default"
                onClick={this.onClickLookupSelect}
                data-id={field.id}
                data-value-id={valueId}
              >
                Switch
              </ButtonLink>
            )}
          </div>
        )}
      </div>
    );
  }

  onClickLookupSelect = (event) => {
    event.preventDefault();

    const {
      config,
      storefront: { id: storefrontId },
    } = this.props;
    const { section, tab, pageContent, collectionStack } = this.state;
    const { id, valueId } = event.currentTarget.dataset;
    let lookupHeading = null;
    let lookupField = null;
    let lookupSection = null;
    let lookupValue = undefined;

    if (pageContent) {
      const lastCollectionItem = findLast(collectionStack, { showing: true });
      lookupSection = lastCollectionItem.field;

      if (lookupSection.item_types) {
        const valueType = get(lastCollectionItem.values, 'type');

        const itemTypeFields = reduce(
          this.getCollectionItemTypeFields(
            lookupSection.item_types,
            valueType,
            lookupSection.id,
          ),
          (acc, field) => {
            acc.push(
              ...(field.type === 'field_group' || field.type === 'field_row'
                ? field.fields
                : [field]),
            );

            return acc;
          },
          [],
        );

        lookupField = find(itemTypeFields, { id });
      } else {
        lookupField = find(lookupSection.fields, { id });
      }

      if (lastCollectionItem) {
        lookupValue = get(lastCollectionItem.values, id);
      } else {
        const values = cloneDeep(pageContent.values) || {};
        lookupValue = get(values, valueId);
      }
    } else {
      lookupSection = find(config.settings, { id: section[tab] });
      lookupField = find(lookupSection.fields, { id });
      const index = findIndex(lookupSection.fields, lookupField);

      for (let i = index; i > 0; i--) {
        const f = lookupSection.fields[i];
        if (f.type === 'heading') {
          lookupHeading = f.name;
          break;
        }
      }
    }

    this.setState({
      lookupField,
      lookupHeading,
      lookupSection,
      lookupValue,
      lookupValueId: valueId,
      loadedMenus: false,
    });

    if (lookupField.type === 'menu') {
      this.props.fetchStorefrontMenus(storefrontId).then((menus) => {
        this.setState({ menus, loadedMenus: true });
      });
    }
  };

  onSubmitLookupForm = (values) => {
    const { lookupField, lookupValueId, pageContent, collectionStack } =
      this.state;
    const { id } = lookupField;
    const value = get(values, id);

    if (pageContent) {
      const lastCollectionItem = findLast(collectionStack, { showing: true });
      if (lastCollectionItem) {
        const changeHandler = get(
          this.pageFieldChangeHandlers,
          lookupField.id,
          NOOP,
        );
        if (changeHandler) {
          changeHandler(
            lookupField,
            lookupValueId,
            value,
            lastCollectionItem.fieldId,
            `${lastCollectionItem.valueId}.${lastCollectionItem.index}`,
          );
        }
      }
    } else {
      this.settingFieldChangeHandlers[id](lookupField, id, value);
    }

    this.setState({ lookupField: null });
  };

  onCloseLookupModal = () => {
    this.setState({ lookupField: null, editMenuId: null });
  };

  renderLookupModal() {
    const { values, lookup, categories } = this.props;
    const {
      lookupField,
      lookupValue,
      lookupHeading,
      menus,
      editMenuId,
      contentTypeAliases,
    } = this.state;

    let field = lookupField;
    if (contentTypeAliases && contentTypeAliases[lookupField.type]) {
      field = { ...lookupField, ...contentTypeAliases[lookupField.type] };
    }

    const title = [lookupHeading, field.label].filter((x) => !!x).join(' / ');

    if (editMenuId) {
      return this.renderMenuModal(title);
    }

    return (
      <Form onSubmit={this.onSubmitLookupForm} autoFocus={true}>
        <Modal
          title={title}
          width={500}
          onClose={this.onCloseLookupModal}
          actions={[{ label: 'Save', type: 'submit' }]}
        >
          <fieldset>
            <div className="row">
              {field.type === 'menu' ? (
                <Field
                  type="select"
                  name={field.id}
                  options={menus || []}
                  defaultValue={get(values, field.id, {}).id}
                  placeholder="Choose a storefront menu"
                  className="span4"
                />
              ) : (
                <ContentField
                  name={field.id}
                  className="span4"
                  defaultValue={lookupValue}
                  {...field}
                  label={null}
                  lookup={lookup}
                  categories={categories}
                />
              )}
            </div>
          </fieldset>
        </Modal>
      </Form>
    );
  }

  renderDuplicatePageModal() {
    const { duplicatePage, duplicateValues } = this.state;

    const label = sectionLabel(duplicatePage);
    const suffix = label.match(/page$/i) ? '' : 'page';

    return (
      <Form
        onSubmit={this.onSubmitDuplicatePageForm}
        onChange={this.onChangeDuplicateForm}
        autoFocus={true}
      >
        <Modal
          title={`Duplicate ${label} ${suffix}`}
          width={500}
          onClose={this.onCloseDuplicatePage}
          actions={[{ label: 'Save', type: 'submit' }]}
        >
          <fieldset>
            <div className="row">
              <FieldLocalized
                type="text"
                name="name"
                label="New page name"
                required={true}
                className="span4"
              />
            </div>
            <div className="row">
              <Field
                type="text"
                label="Slug"
                name="slug"
                placeholder={slugify(duplicateValues.name)}
                className="span4"
              />
            </div>
            <div className="row">
              <Field
                type="toggle"
                name="published"
                label="Published"
                defaultChecked={false}
              />
            </div>
          </fieldset>
        </Modal>
      </Form>
    );
  }

  renderMenuModal(title) {
    const { values, config } = this.props;
    const { lookupField, loadedMenus } = this.state;
    const record = get(values, lookupField.id);

    return (
      <MenuForm
        {...this.props}
        modalProps={{
          title,
          width: 900,
          onClose: this.onCloseLookupModal,
          actions: [
            {
              label: 'Save',
              type: 'default',
              onClick: () => this.menuFormRef.submit(),
            },
          ],
          loading: !loadedMenus,
        }}
        record={record}
        menuConfigs={config.menus}
        defaultMenuId={lookupField.default}
        loading={!loadedMenus}
        onClose={this.onCloseMenuModal}
        onSave={this.onSaveMenuModal}
        onRef={(ref) => (this.menuFormRef = ref)}
      />
    );
  }

  renderCollectionStack() {
    const {
      collectionStack,
      sectionTransitionAppear,
      sectionSaving,
      collectionLoading,
    } = this.state;
    let lastItem = findLast(collectionStack, { showing: true });

    if (!lastItem) {
      lastItem = collectionStack[collectionStack.length - 1];
    }

    const showSave =
      lastItem &&
      lastItem.showing &&
      !lastItem.record &&
      (!lastItem.field.item_types || lastItem.values.type);

    return (
      <Fragment>
        {collectionStack.map(
          (
            { key, showing, values, fieldId, valueId, field, index, record },
            i,
          ) => {
            const label = this.renderCollectionLabelSingular(field);
            return (
              <MoveInLeft
                key={key}
                active={!!showing}
                distance={VIEW_DISTANCE}
                duration={VIEW_DURATION}
                className={`SettingsMenu-view SettingsMenu-view-collection-${i}`}
                transitionAppear={sectionTransitionAppear}
              >
                {this.renderSectionHeader({
                  label:
                    this.renderCollectionItemLabel(values, field, index) ||
                    `Add ${label}`,
                  crumbs:
                    // this.renderContentTypeLabel(fieldId, values) ||
                    // (lastItem ? this.renderContentTypeLabel(lastItem.fieldId, lastItem.values) : undefined),
                    `${
                      i > 0
                        ? `${collectionStack
                            .slice(0, i)
                            .map((i) =>
                              this.renderContentTypeLabel(i.fieldId, i.values),
                            )
                            .filter((i) => i)
                            .join(' / ')} / `
                        : ''
                    }${this.renderContentTypeLabel(fieldId, values)}`,
                  // `${sectionLabel(page)} ${
                  //   i > 0
                  //     ? ` / ${collectionStack
                  //         .slice(0, i)
                  //         .map((i) => this.renderCollectionItemLabel(i.values, i.field, i.index))
                  //         .join(' / ')}`
                  //     : ''
                  // }`,
                  back: this.onClickHideCollectionItem,
                  backProps: { 'data-index': i },
                  menu:
                    record &&
                    this.renderContextMenuButton(
                      'collection',
                      valueId,
                      'ellipsis-h',
                      {
                        index,
                        label,
                        fieldid: fieldId,
                        top: true,
                      },
                    ),
                })}
                <div className="SettingsMenu-body">
                  <Scrollbars>
                    <Form
                      loading={sectionSaving}
                      className={`SettingsMenu-body-container`}
                      ref={`collectionForm_${key}`}
                      autoFocus={!!showSave}
                      autoFocusEmpty={true}
                    >
                      <div className="SettingsMenu-body-shadow-cover" />
                      {collectionLoading ? (
                        <Loading />
                      ) : (
                        this.renderCollectionFields(
                          fieldId,
                          `${valueId}.${index}`,
                          i,
                        )
                      )}
                    </Form>
                  </Scrollbars>
                </div>
              </MoveInLeft>
            );
          },
        )}
        {showSave && (
          <MoveUp
            active={showSave}
            distance={80}
            className="SettingsMenu-collection-action"
          >
            <button
              data-index={lastItem && lastItem.index}
              className="button button-primary button-sm"
              onClick={this.onClickSaveCollectionItem}
              type="button"
            >
              Save{' '}
              {lastItem && this.renderCollectionLabelSingular(lastItem.field)}
            </button>
          </MoveUp>
        )}
      </Fragment>
    );
  }

  renderContentTypeLabel(fieldId, values) {
    const type = get(values, 'type');
    if (type) {
      const { pageContent } = this.state;
      const configs = get(pageContent, 'types.configs', {});
      for (let key in configs) {
        const slug = get(configs[key], 'slug');
        if (slug === type) {
          return get(configs[key], 'name');
        }
      }
    }
    const part = fieldId.split('.').pop();
    if (part) {
      return capitalize(singularize(words(part).join(' ')));
    }
  }

  renderCollectionItemLabel(values, field, index) {
    return (
      (values &&
        (values.label ||
          values.name ||
          values.heading ||
          values.description ||
          values.text)) ||
      (index !== undefined
        ? `${capitalize(this.renderCollectionLabelSingular(field))} ${
            ~~index + 1
          }`
        : values.id)
    );
  }

  render() {
    const { config, editorLoaded, storefront, containerUrl, checkoutSettings } =
      this.props;
    const {
      tab,
      tabLoading,
      section,
      sectionLoading,
      showSection,
      showCollectionItem,
      showAddPage,
      sectionSaving,
      sectionTransitionAppear,
      lookupField,
      pageContent,
      showDefaultToggle,
      toggleDefaults,
      duplicatePage,
      loaded,
      loadedByTime,
    } = this.state;

    let configSection =
      tab &&
      (typeof section[tab] === 'string'
        ? find(config[tab], { id: section[tab] })
        : config[tab]);
    const collectionField = showCollectionItem && find(pageContent.types);

    // helps fork rendering with specifics to generic content models, e.g. quizzes
    const modelLabel = pageContent && pageContent.modelLabel;

    let showingCollectionItem;
    if (showAddPage && pageContent) {
      configSection = pageContent.values;
      showingCollectionItem = findLast(this.state.collectionStack, {
        showing: true,
      });
    }

    // prepare data for section header display
    if (!showAddPage && modelLabel && pageContent) {
      configSection = pageContent.values;
    }

    if (!editorLoaded && !loadedByTime) {
      return null;
    }

    const isLoading = !editorLoaded || !loaded;

    const hasLangSettings = !!config.lang;
    const globalSettingsClasses = cx([
      'first',
      {
        last: !hasLangSettings,
      },
    ]);

    const inThemeEditor = Boolean(storefront.editor && containerUrl);

    return (
      <Fragment>
        <div
          className={`SettingsMenu-container ${
            tabLoading ? 'SettingsMenu-loading' : ''
          }`}
        >
          <div className="SettingsMenu-root">
            <div className="SettingsMenu-body">
              <Scrollbars>
                <div
                  className={`SettingsMenu-body-container ${
                    !loaded ? 'loading' : ''
                  }`}
                >
                  {!loaded ? (
                    <Loading />
                  ) : (
                    <Fragment>
                      {inThemeEditor && (
                        <Fragment>
                          <div className="SettingsMenu-sections">
                            <button
                              key="globals"
                              data-tab="settings"
                              className={globalSettingsClasses}
                              onClick={this.onClickTab}
                              type="button"
                            >
                              <div className="SettingsMenu-section-icon">
                                <Icon fa="palette" faType="solid" />
                              </div>
                              <div className="SettingsMenu-section-title">
                                Design &amp; global settings
                              </div>
                            </button>
                            {hasLangSettings && (
                              <button
                                key="lang"
                                data-tab="lang"
                                className={
                                  checkoutSettings?.custom_checkout
                                    ? 'last'
                                    : undefined
                                }
                                onClick={this.onClickTab}
                                type="button"
                              >
                                <div className="SettingsMenu-section-icon">
                                  <Icon fa="language" faType="solid" />
                                </div>
                                <div className="SettingsMenu-section-title">
                                  Language
                                </div>
                              </button>
                            )}
                            {!checkoutSettings?.custom_checkout && (
                              <button
                                key="checkout"
                                data-tab="checkout"
                                data-section={true}
                                className="last"
                                onClick={this.onClickSection}
                                type="button"
                              >
                                <div className="SettingsMenu-section-icon">
                                  <Icon fa="shopping-cart" faType="solid" />
                                </div>
                                <div className="SettingsMenu-section-title">
                                  Checkout
                                </div>
                              </button>
                            )}
                          </div>
                          <div className="SettingsMenu-sections">
                            {this.renderPages()}
                          </div>
                        </Fragment>
                      )}

                      {isLoading && (
                        <div className="SettingsMenu-body-loading" />
                      )}
                    </Fragment>
                  )}
                </div>
              </Scrollbars>
            </div>
          </div>
          <MoveInLeft
            active={tab === 'settings'}
            distance={VIEW_DISTANCE}
            duration={VIEW_DURATION}
            className="SettingsMenu-view"
            transitionAppear={sectionTransitionAppear}
          >
            <div className="SettingsMenu-header">
              <div className="SettingsMenu-title">
                Design &amp; global settings
              </div>
              <div className="SettingsMenu-back">
                <button
                  data-tab=""
                  className="as-link"
                  onClick={this.onClickTab}
                  type="button"
                >
                  <Icon fa="arrow-left" faType="light" />
                </button>
              </div>
            </div>
            <div className="SettingsMenu-body">
              <Scrollbars>
                <div className="SettingsMenu-body-container">
                  <div className="SettingsMenu-body-shadow-cover" />
                  <div className="SettingsMenu-sections">
                    {tab === 'settings' && this.renderSettingSections()}
                  </div>
                </div>
              </Scrollbars>
            </div>
          </MoveInLeft>
          {hasLangSettings && (
            <MoveInLeft
              active={tab === 'lang'}
              distance={VIEW_DISTANCE}
              duration={VIEW_DURATION}
              className="SettingsMenu-view"
              transitionAppear={sectionTransitionAppear}
            >
              <div className="SettingsMenu-header">
                <div className="SettingsMenu-title">Language</div>
                <div className="SettingsMenu-back">
                  <button
                    data-tab=""
                    className="as-link"
                    onClick={this.onClickTab}
                    type="button"
                  >
                    <Icon fa="arrow-left" faType="light" />
                  </button>
                </div>
              </div>
              <div className="SettingsMenu-body">
                <Scrollbars>
                  <div className="SettingsMenu-body-container">
                    <div className="SettingsMenu-body-shadow-cover" />
                    <div className="SettingsMenu-sections">
                      {tab === 'lang' && this.renderSettingSections('lang')}
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </MoveInLeft>
          )}
          {config.checkout && (
            <MoveInLeft
              active={tab === 'checkout'}
              distance={VIEW_DISTANCE}
              duration={VIEW_DURATION}
              className="SettingsMenu-view"
              transitionAppear={sectionTransitionAppear}
            >
              {inThemeEditor && (
                <div className="SettingsMenu-header">
                  <div className="SettingsMenu-title">Checkout</div>
                  <div className="SettingsMenu-back">
                    <button
                      data-tab=""
                      className="as-link"
                      onClick={this.onClickTab}
                      type="button"
                    >
                      <Icon fa="arrow-left" faType="light" />
                    </button>
                  </div>
                </div>
              )}
              <div
                className={`SettingsMenu-body ${
                  !inThemeEditor ? 'without-header' : ''
                }`}
              >
                <Scrollbars>
                  <div className="SettingsMenu-body-container">
                    <div className="SettingsMenu-body-shadow-cover" />
                    <div className="SettingsMenu-sections">
                      {tab === 'checkout' &&
                        this.renderSettingFields('checkout')}
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </MoveInLeft>
          )}
          <MoveInLeft
            // checkout tab is are rendered separately
            active={!!showSection && tab !== 'checkout'}
            distance={VIEW_DISTANCE}
            duration={VIEW_DURATION}
            className="SettingsMenu-view"
            transitionAppear={sectionTransitionAppear}
          >
            {this.renderSectionHeader({
              label: sectionLabel(configSection),
              crumbs:
                tab === 'pages'
                  ? !collectionField && configSection && configSection.home
                    ? 'Home page'
                    : modelLabel || 'Page'
                  : tab === 'settings'
                  ? 'Settings'
                  : tab === 'lang'
                  ? 'Language'
                  : null,
              back: this.onClickHideSection(
                modelLabel ? pageContent.model : tab,
              ),
              menu:
                tab === 'pages' &&
                pageContent &&
                pageContent.record_id &&
                pageContent.model === 'content_pages' &&
                this.renderContextMenuButton(
                  'page',
                  section.pages,
                  'ellipsis-h',
                ),
            })}
            <div className="SettingsMenu-body">
              {showDefaultToggle && this.renderPageDefaultsToggle()}
              <Scrollbars>
                <Form
                  key={section[tab]}
                  loading={sectionSaving}
                  className={`SettingsMenu-body-container ${
                    sectionLoading ? 'loading' : ''
                  }
                  ${showDefaultToggle ? 'with-defaults' : ''}
                  ${
                    showDefaultToggle && toggleDefaults[section.pages]
                      ? 'edit-defaults'
                      : ''
                  }`}
                  ref="sectionForm"
                  autoFocus={!!showAddPage}
                >
                  <div className="SettingsMenu-body-shadow-cover" />
                  {sectionLoading ? (
                    <Loading />
                  ) : (
                    <Fragment>
                      {tab === 'pages' && this.renderPageFields()}
                      {tab === 'settings' && this.renderSettingFields()}
                      {tab === 'lang' && this.renderSettingFields('lang')}
                    </Fragment>
                  )}
                </Form>
              </Scrollbars>
            </div>
          </MoveInLeft>
          {showAddPage && !showingCollectionItem && (
            <MoveUp
              active={showAddPage && !showingCollectionItem}
              distance={80}
              className="SettingsMenu-collection-action"
            >
              <button
                className="button button-primary button-sm"
                onClick={this.onClickSavePage}
                type="button"
              >
                Save page
              </button>
            </MoveUp>
          )}
          {this.renderModelPages()}
          {this.renderCollectionStack()}
          {lookupField && this.renderLookupModal()}
          {duplicatePage && this.renderDuplicatePageModal()}
        </div>
        {this.renderContextMenu()}
      </Fragment>
    );
  }
}
