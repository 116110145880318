import React from 'react';
import Flash from 'components/flash';
import LoadingBar from 'components/loading/bar';

export default class LoginLayoutSinglePage extends React.PureComponent {
  render() {
    const { flash, children } = this.props;

    return (
      <div className="login-container">
        <LoadingBar />
        <Flash {...flash} />
        <div className="login-wrapper">{children}</div>
      </div>
    );
  }
}
