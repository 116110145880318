import React from 'react';
import classNames from 'classnames';
import { find } from 'lodash';
import JSONPretty from 'react-json-pretty';
import Link from 'components/link';
import Icon from 'components/icon';
import AppIcon from 'components/apps/icon';
import { formatDate, locationWithQuery } from 'utils';

// Populated dynamically
let APP_RESULTS;

const SLOW_QUERY_THRESHOLD_MS = 10000;

export const LOG_LEVEL_FILTERS = Object.freeze({
  // search handled separately
  search: () => ({}),
  dates: (values) => ({
    $and: [
      { date: { $gte: values.dates.startDate } },
      { date: { $lte: values.dates.endDate } },
    ],
  }),
  environments: (values) => ({ environment_id: { $in: values.environments } }),
  apps: (values) => ({ app_id: { $in: values.apps } }),
  types: (values) => ({ type: { $in: values.types } }),
  performance: (values) => {
    const filters = [];

    for (const performance of values.performance) {
      switch (performance) {
        case 'limited': {
          filters.push({ 'message.limited': true });
          break;
        }
        case 'slow': {
          filters.push(
            { time: { $gt: SLOW_QUERY_THRESHOLD_MS } },
            { 'message.method': 'get' },
          );
          break;
        }
        default: {
          // nothing
        }
      }
    }

    return { $and: filters };
  },
});

export const LOG_COLUMN_DEFAULTS = Object.freeze([
  'date',
  'message',
  'message.data',
  'status',
  'time',
]);

export const LOG_COLUMNS = Object.freeze({
  date: {
    label: 'Date',
    render: (val) => <time dateTime={val}>{formatDate(val, 'log')}</time>,
    toggle: false,
  },
  message: {
    label: 'Request',
    render: (_val, values, _location, parentLine) => (
      <span
        className={`console-logs-status-${
          values.message?.status > 299 ? 'error' : 'positive'
        }`}
      >
        {LOG_MESSAGE_TYPES[values.message?.type].render(
          values.message || {},
          parentLine,
        )}
      </span>
    ),
  },
  'message.data': {
    label: 'Data',
    render: (_val, values) => {
      let data;
      try {
        data = JSON.parse(values.message.data);
      } catch (e) {
        data = values.message.data;
      }

      const type = typeof data;
      if (type === 'undefined') {
        return <span className="muted">&mdash;</span>;
      } else if (data === null) {
        return <span className="muted">null</span>;
      } else if (Array.isArray(data)) {
        return `array`;
      } else if (type === 'object') {
        return `object`;
      }
      return type;
    },
  },
  status: {
    label: 'Status',
    render: (_val, values) =>
      values.message?.status ? (
        <span
          className={`console-logs-status-${
            values.message.status > 299 ? 'error' : 'success'
          }`}
        >
          <span className="status-dot" />
          {values.message.status}
        </span>
      ) : (
        <span className="muted">&mdash;</span>
      ),
  },
  time: {
    label: 'Exec time',
    render: (val, values) => {
      if (!val) {
        return <span className="muted">&mdash;</span>;
      }

      const { message, time } = values || {};
      const limited = Boolean(message?.limited);
      const slow = message?.method === 'get' && time > SLOW_QUERY_THRESHOLD_MS;

      return (
        <span className={classNames({ limited, slow })}>
          {limited} {val}ms
        </span>
      );
    },
  },
  type: { label: 'Type', render: (_val, values) => values.message?.type },
  app_id: {
    label: 'App',
    render: (val) => {
      const installedApp = find(APP_RESULTS, { app_id: val });
      return installedApp ? (
        <span className="console-logs-app">
          <AppIcon
            image={installedApp.app.logo_icon}
            name={installedApp.app.name}
            size={20}
          />{' '}
          {installedApp.app.name}
        </span>
      ) : (
        val
      );
    },
  },
});

export const LOG_MESSAGE_TYPES = Object.freeze({
  api: {
    render: (msg, parentLine) => (
      <>
        {parentLine && (
          <span className="muted">
            <Icon fa="arrow-right" faType="light" />
            &nbsp;
          </span>
        )}
        {msg.method.toUpperCase()} {msg.url}
      </>
    ),
  },
  webhook: {
    render: (msg, parentLine) => (
      <>
        {parentLine && (
          <span className="muted">
            <Icon fa="arrow-right" faType="light" />
            &nbsp;
          </span>
        )}
        <span className="console-logs-webhook-icon">
          <Icon fa="webhook" faType="solid" />
          &nbsp;
        </span>
        POST {msg.url} <span className="muted">&nbsp;{msg.event.type}</span>
      </>
    ),
  },
  function: {
    // Can be invocation or log lines
    render: (msg, parentLine) => {
      return (
        <div>
          {parentLine && (
            <span className="muted">
              <Icon fa="arrow-right" faType="light" />
              &nbsp;
            </span>
          )}
          <span className="console-logs-function-icon">
            <Icon fa="lambda" />
            &nbsp;
          </span>
          {msg.method ? msg.method.toUpperCase() : ''} /{msg.name}
          {msg.event?.type && (
            <span className="muted">
              &nbsp;
              {msg.event.hook ? `${msg.event.hook}:` : ''}
              {msg.event.type}
            </span>
          )}
          {msg.logs?.map((log, index) => (
            <div key={index} className="console-logs-function-logs">
              {log.line?.map((line, index) => (
                <div key={index}>
                  {renderDataAsJsonOrString(line, log.level)}
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    },
  },
});

export const LOG_DETAIL_TYPES = Object.freeze({
  message: {
    ...LOG_COLUMNS.message,
    label: false,
    render: (val, ...args) => {
      if (val.type === 'function') {
        return (
          <span className="console-detail-message-request">
            <span className="console-logs-function-icon">
              <Icon fa="lambda" />
              &nbsp;
            </span>
            {val.method ? val.method.toUpperCase() : ''} /{val.name}{' '}
            {val.event?.type && (
              <span className="event muted">
                {val.event.hook ? `${val.event.hook}:` : ''}
                {val.event.type}
              </span>
            )}
          </span>
        );
      } else if (val.type === 'webhook') {
        return (
          <span className="console-detail-message-request">
            <span className="console-logs-webhook-icon">
              <Icon fa="webhook" faType="solid" />
              &nbsp;
            </span>
            POST {val.url} <span className="event muted">{val.event.type}</span>
          </span>
        );
      }
      return LOG_COLUMNS.message.render(val, ...args);
    },
  },
  date: LOG_COLUMNS.date,
  req_id: {
    label: 'Request ID',
    render: (val, _, location) =>
      val && (
        <Link to={locationWithQuery(location, { search: val })}>{val}</Link>
      ),
  },
  ip: {
    label: 'IP Address',
    renderEmpty: false,
    render: (val, values) => {
      return !values.message?.event ? (
        val ? (
          val
        ) : (
          <span className="muted">&mdash;</span>
        )
      ) : null;
    },
  },
  type: LOG_COLUMNS.type,
  status: {
    ...LOG_COLUMNS.status,
    renderEmpty: false,
    label: 'Status code',
  },
  error: {
    label: 'Error',
    renderEmpty: false,
    render: (_val, values) => values.message?.error,
  },
  environment_id: {
    ...LOG_COLUMNS.environment_id,
    label: 'Environment',
    render: (val) => (
      <span className={`console-logs-env console-env-${val ? 'test' : 'live'}`}>
        {val ? val.toUpperCase() : 'LIVE'}
      </span>
    ),
  },
  time: {
    ...LOG_COLUMNS.time,
    renderEmpty: false,
    label: 'Execution time',
  },
  app_id: {
    label: 'App',
    renderEmpty: false,
    render: (val, _, location) => {
      if (!val) return;
      const installedApp = find(APP_RESULTS, { app_id: val });
      return installedApp ? (
        <span className="console-logs-app">
          <Link
            to={`/apps/${installedApp.app_id}`}
            className="console-logs-link-external"
          >
            <Icon fa="arrow-up-right" faType="light" />
          </Link>
          <AppIcon
            image={installedApp.app.logo_icon}
            name={installedApp.app.name}
            size={20}
          />{' '}
          <Link
            to={locationWithQuery(location, { app_id: [installedApp.app_id] })}
          >
            <span className="name">{installedApp.app.name}</span>
          </Link>
        </span>
      ) : (
        val
      );
    },
  },
  data: {
    label: 'Request data',
    renderUnder: true,
    renderEmpty: false,
    render: (_val, values) => {
      if (!values.message) return undefined;

      const { data } = values.message;
      if (data !== undefined && data !== null) {
        return renderDataAsJsonOrString(data);
      } else {
        return <span className="muted">&mdash;</span>;
      }
    },
  },
  response: {
    label: 'Response data',
    renderUnder: true,
    renderEmpty: false,
    render: (_val, values) => {
      if (
        values.message?.response === undefined ||
        values.message?.response === null
      )
        return undefined;

      const { response } = values.message;
      if (response !== undefined && response !== null) {
        return renderDataAsJsonOrString(response);
      } else {
        return <span className="muted">&mdash;</span>;
      }
    },
  },
  logs: {
    label: 'Logs',
    renderUnder: true,
    renderEmpty: false,
    render: (_val, values) => {
      if (!values.message?.logs) return undefined;

      const { logs } = values.message;
      if (logs && logs.length > 0) {
        return (
          <div>
            {logs.map((log, index) => (
              <div key={index} className="console-logs-function-logs">
                {log.line?.map((line, index) => (
                  <div key={index}>
                    {renderDataAsJsonOrString(line, log.level)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        );
      } else {
        return undefined;
      }
    },
  },
});

export const LOG_MESSAGE_FILTERS = Object.freeze({
  type: {
    label: 'Type',
    options: [
      {
        value: 'api',
        label: 'API',
      },
      {
        value: 'webhook',
        label: 'Webhooks',
      },
      {
        value: 'function',
        label: 'Functions',
      },
    ],
  },
  status: {
    label: 'Status',
    options: [
      {
        value: '200',
        label: '200',
      },
      {
        value: '300',
        label: '300',
      },
      {
        label: '400',
        value: '400',
      },
      {
        value: '500',
        label: '500',
      },
    ],
    formatValue: (value) => Number(value),
  },
  performance: {
    label: 'Performance',
    options: [
      {
        value: 'slow',
        label: 'Slow Queries',
      },
      {
        value: 'limited',
        label: 'Rate-limited',
      },
    ],
  },
  // May or may not want to support this in the future
  /* environments: {
    label: 'Env',
    options: [
      {
        value: 'test',
        label: 'Test',
      },
      {
        value: 'live',
        label: 'Live',
      },
    ],
  }, */
  // Populated dynamically
  app_id: {
    path: 'app_id',
    label: 'App',
  },
});

function renderDataAsJsonOrString(data, level = undefined) {
  try {
    const json = JSON.parse(data);
    return (
      <JSONPretty
        className={`console-response ${
          level ? `console-response-${level}` : ''
        }`}
        data={json}
        onJSONPrettyError={(e) =>
          data && typeof data === 'object' && console.log(e)
        }
      />
    );
  } catch (e) {
    return data;
  }
}

export function logMessageFilters(appResults = []) {
  APP_RESULTS = appResults;

  return {
    ...LOG_MESSAGE_FILTERS,
    ...(appResults?.length > 0
      ? {
          app_id: {
            label: 'App',
            path: 'app_id',
            options: appResults.map((installedApp) => ({
              value: installedApp.app.id,
              label: installedApp.app.name,
              icon: (
                <AppIcon
                  image={installedApp.app.logo_icon}
                  name={installedApp.app.name}
                  size={14}
                />
              ),
            })),
          },
        }
      : undefined),
  };
}
