import React from 'react';

import Tooltip from './tooltip';

function Helptip(props) {
  return (
    <Tooltip {...props}>
      <span className="help icon icon-info">&nbsp;</span>
    </Tooltip>
  );
}

export default React.memo(Helptip);
