// @flow
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { discountUpdatesFromValues } from 'utils/discount';

import actions from 'actions';

import NewPage from 'components/coupon/new';
import ViewLoading from 'components/view/loading';

export const mapStateToProps = (state: Object) => ({
  data: state.data,
  loading: state.data.loading,
  errors: state.data.recordErrors,
  categories: state.categories,
  content: state.content,
  lookup: state.lookup,
  settings: state.settings,
  bulk: state.data.bulk,
});

export const mapDispatchToProps = (dispatch: Function) => ({
  searchCategories: (search: string) => {
    return dispatch(actions.categories.search(search, 10));
  },

  createRecord: (data: Object) => {
    return dispatch(actions.data.createRecord('coupons', data));
  },

  bulkGenerateCouponCodes: (id: string, count: Number) => {
    return dispatch(actions.data.bulkGenerateCouponCodes(id, count));
  },

  bulkCancel: () => {
    return dispatch(actions.data.bulkCancel());
  },

  loadSettings() {
    return Promise.all([
      dispatch(actions.settings.fetch('discounts')),
      dispatch(actions.settings.fetch('shipments')),
      dispatch(actions.settings.fetch('accounts')),
    ]);
  },
});

export class NewCoupon extends React.Component {
  static contextTypes = {
    notifyCreated: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      values: {},
      edited: false,
      generatingCodes: false,
      generatingCount: 0,
      onChangeForm: this.onChangeForm.bind(this),
      onSubmitRecord: this.onSubmitRecord.bind(this),
      onQueryCategories: this.onQueryCategories.bind(this),
    };
  }

  componentDidMount() {
    const { loadSettings } = this.props;

    loadSettings().then(() => {
      this.setState({ loaded: true });
    });
  }

  onQueryCategories(value: string) {
    this.props.searchCategories(value);
  }

  onChangeForm(values: Object, edited: boolean) {
    this.setState({
      values: {
        ...this.state.values,
        ...values,
      },
      edited,
    });
  }

  async onSubmitRecord(values: Object) {
    const { router, createRecord, bulkGenerateCouponCodes, bulkCancel } =
      this.props;

    const { client } = this.context;

    const result = await createRecord({
      ...values,
      name: values.name || values.code,
      active: true,
      ...discountUpdatesFromValues(values, {}, client),
      generate: undefined,
    });

    if (result.errors) {
      this.context.notifyError(result.errors);
    } else {
      if (values.generate) {
        this.setState({
          generatingCodes: true,
          generatingCount: values.generate.count,
        });
        await bulkGenerateCouponCodes(result.id, values.generate.count);
        this.setState({ generatingCodes: false });
        bulkCancel();
      }
      router.replace(`/coupons`);
      this.context.notifyCreated(result);
    }
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    return <NewPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCoupon);
