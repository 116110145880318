import React from 'react';
import PropTypes from 'prop-types';
import { findKey, map } from 'lodash';
import { Field, FieldLocalized, LookupCategories } from 'components/form';
import BoltIcon from 'components/icon/bolt';
import { FadeIn } from 'components/transitions';
import ProductBundle from './bundle';
import PurchaseOptions from './purchase-options';
import ProductVariants from './variants';
import ProductOptionsCombined from './options-combined';
import GiftcardOptions from './giftcard-options';
import GiftcardFulfillment from './giftcard-fulfillment';
import RelatedProducts from './related-products';
import AttributesForm from './form-attributes';
import InventoryForm from './form-inventory';
import ShippingForm from './form-shipping';
import ContentFields from 'components/content/fields';
import ProductPurchaseLinks from './purchase-links';
import Help from 'components/tooltip/help';
import ContentFieldsDeprecated from 'components/content/fields-deprecated';
import ContentRecordTab, {
  getContentTabs,
} from 'components/content/record-tab';
import Pagination from 'components/collection/pagination';
import Icon from 'components/icon';
import SectionHeader from 'components/section-header';
import { slugify } from 'utils';
import { stripTags } from 'utils/string';
import {
  productFeaturesByType,
  defaultTaxCodeAndHintText,
} from 'utils/product';
import { contentTypesConditionalDeprecated } from 'utils/content';
import './product.scss';

const PRODUCT_TYPES = {
  physical: {
    faIcon: 'cube',
    label: 'Physical',
    desc: 'Item has weight and needs shipping or customer pickup',
  },
  digital: {
    faIcon: 'credit-card',
    label: 'Digital',
    desc: 'Item is delivered digitally or provided as a service',
  },
  bundle: {
    faIcon: 'layer-group',
    label: 'Bundle',
    desc: 'Item is a group of several physical or digital products',
  },
  giftcard: {
    faIcon: 'gift',
    label: 'Gift card',
    desc: 'Generates a unique code for store credit when purchased',
  },
};

const ARR = [];

export default class ProductForm extends React.PureComponent {
  static contextTypes = {
    account: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
  };

  state = {};

  constructor(props, context) {
    super(props, context);
    this.state = {
      charCounts: {},
      hasDraftOptions: false,
      features: this.getFeaturesByType(this.getProductType(props)),
    };
  }

  getProductType({ record = {}, values = {}, location }) {
    return record.type || location.query.type || values.type || 'physical';
  }

  hasSetOptions({ record = {} }) {
    return record?.options?.length;
  }

  componentWillReceiveProps(nextProps) {
    const prevType = this.getProductType(this.props);
    const newType = this.getProductType(nextProps);

    if (prevType !== newType) {
      this.setState({ features: this.getFeaturesByType(newType) });
    }

    // Check to see whether we have completely removed the attributes
    if (this.hasSetOptions(this.props) && !this.hasSetOptions(nextProps)) {
      this.setState({ hasDraftOptions: false });
    }
  }

  getFeaturesByType(type) {
    return productFeaturesByType(type);
  }

  onChangeSEOField = (event, value) => {
    if (event.target) {
      const { name } = event.target;

      this.setState((state) => ({
        charCounts: {
          ...state.charCounts,
          [name]: value.length,
        },
      }));
    }
  };

  // Count of not saved options
  setCountDraftOptions = (draftOptions) => {
    this.setState({ hasDraftOptions: Boolean(draftOptions) });
  };

  renderDetailsTab() {
    const {
      record,
      values,
      related,
      settings,
      categories,
      attributes,
      lookup,
      content,
      editingOptions,
      onClickEditOptions,
      onClickSaveOptions,
      onClickEditVariant,
      onClickVariantImages,
      onSubmitCombinedOptions,
      onSubmitGenerateMoreGiftcards,
      onClickPage,
      onClickCreatePurchaseLink,
      onChangeLimit,
      collection,
      giftcardGenerating,
      location,
    } = this.props;

    const { charCounts, features, hasDraftOptions } = this.state;
    const { client } = this.context;
    const currency = values.currency || record.currency || client.currency;
    const isBundle = !!(
      record.bundle ||
      values.bundle ||
      values.type === 'bundle' ||
      features.bundle
    );
    const isGiftcard =
      values.type === 'giftcard' ||
      this.props.location.query.type === 'giftcard';

    const hasRecordOptions = record.options && record.options.length > 0;

    const hasOptions =
      (values.combined_options && values.combined_options.length > 0) ||
      hasRecordOptions;

    const hasVariants = record.variants && record.variants.results.length > 0;

    const hasRelatedProducts =
      !!(record.up_sells && record.up_sells.length > 0) ||
      !!(record.cross_sells && record.cross_sells.length > 0);

    const hasRelatedProductValues =
      !!(
        values.up_sells &&
        (values.up_sells.length > 0 || values.up_sells[0])
      ) ||
      !!(
        values.cross_sells &&
        (values.cross_sells.length > 0 || values.cross_sells[0])
      );

    const isOptionsEnabled = Boolean(values.variable) || hasRecordOptions;

    const contentFieldsDeprecated = contentTypesConditionalDeprecated(
      content.fieldsDeprecated.products,
      values,
    );

    const hasPresetType = !!location.query.type;

    return (
      <div className="view-body-section-group">
        <fieldset className="full">
          <div className="view-body-section">
            <div className="view-section-content no-header">
              <div className="row">
                <FieldLocalized
                  type="text"
                  label="Product name"
                  name="name"
                  defaultValue={record.name}
                  localeValue={record.$locale}
                  required={true}
                  className={features.inventory ? 'span3' : 'span4'}
                />
                {features.inventory && (
                  <Field
                    type="text"
                    label="SKU"
                    name="sku"
                    defaultValue={record.sku}
                    placeholder="Optional"
                    className="span1"
                  />
                )}
              </div>
              {!record.id && !hasPresetType && (
                <div className="row">
                  <Field
                    name="type"
                    type="radio"
                    label="Type"
                    buttonsWithIcons
                    required
                    className="span4"
                    defaultValue={location.query.type || 'physical'}
                    options={map(PRODUCT_TYPES, (type, value) => ({
                      value,
                      label: (
                        <div className="form-radio-button-wrapper">
                          <div className="label">
                            <Icon fa={type.faIcon} faType="solid" />
                            {type.label}
                          </div>
                          <span className="description muted">{type.desc}</span>
                        </div>
                      ),
                    }))}
                  />
                </div>
              )}
              <div className="row">
                <Field
                  type="toggle"
                  name="active"
                  label="Active"
                  help="An active product is visible to customers"
                  defaultChecked={
                    record.active !== undefined ? record.active : true
                  }
                />
              </div>
              <div className="row">
                <LookupCategories
                  label="Categories"
                  name="categoryValues"
                  defaultValue={values.categoryValues || ARR}
                  categories={categories}
                  className="span4"
                  newable
                  newValueText="New category"
                  newValueFormName="NewCategory"
                />
              </div>
              {this.renderTaxCodeField()}
              {settings.products.features.cost_tracking && (
                <div className="row">
                  <Field
                    type="currency"
                    label="Cost"
                    name="cost"
                    defaultValue={record.cost}
                    currency={currency}
                    className="span1"
                    help="Internal cost of a product used for reporting, not visible to customers"
                  />
                </div>
              )}
              <ContentFields
                {...this.props}
                zone="details"
                collection="products"
                models={content.models}
                record={record}
                values={values}
                currency={currency}
              />
            </div>
          </div>
        </fieldset>
        <fieldset className="full">
          <div className="view-body-section">
            <div className="view-body-subheader">
              <SectionHeader className="view-body-subtitle" title="Media" />
            </div>
            <div className="view-section-content asset-uploader">
              <Field
                type="images"
                name="images"
                fullSize={false}
                imageWidth={243}
                imageHeight={243}
                defaultValue={record.images}
              />
            </div>
          </div>
        </fieldset>
        {isBundle && (
          <fieldset className="full">
            <div className="view-body-section">
              <FadeIn>
                <div className="view-body-subheader">
                  <SectionHeader
                    className="view-body-subtitle"
                    title="Bundle items"
                    subtitle="Assign other products to be sold as a part of a bundle"
                  />
                </div>
                <div className="view-section-content">
                  <ProductBundle
                    label="Bundle item"
                    name="bundle_items"
                    record={record}
                    lookup={lookup}
                    defaultValue={values.bundle_items}
                  />
                </div>
              </FadeIn>
            </div>
          </fieldset>
        )}
        {!isGiftcard && (
          <>
            <PurchaseOptions
              type="standard"
              name="purchase_options"
              record={record}
              values={values}
              currency={currency}
              settings={settings}
              isBundle={isBundle}
            />
            <PurchaseOptions
              type="subscription"
              name="purchase_options"
              record={record}
              values={values}
              currency={currency}
              settings={settings}
            />
            {client.features.has('trial_purchase') && (
              <PurchaseOptions
                type="trial"
                name="purchase_options"
                record={record}
                values={values}
                currency={currency}
                settings={settings}
              />
            )}
          </>
        )}
        <ContentFields
          {...this.props}
          zone="pricing"
          collection="products"
          models={content.models}
          record={record}
          values={values}
          render={(component) => (
            <fieldset className="full">{component}</fieldset>
          )}
        />
        {features.options && (
          <>
            <fieldset className="full">
              <div className="view-body-section">
                <div className="view-body-subheader">
                  <SectionHeader
                    className="view-body-subtitle"
                    title="Options"
                    subtitle="Manage variations including size &amp; color, add-ons or personalization"
                  />
                  {!hasRecordOptions ? (
                    <div className="view-body-actions">
                      <Field
                        type="toggle"
                        name="variable"
                        defaultChecked={hasRecordOptions || hasDraftOptions}
                        className="right"
                      />
                    </div>
                  ) : (
                    !editingOptions && (
                      <div className="view-body-actions">
                        <button
                          className="button button-sm button-secondary"
                          onClick={onClickEditOptions}
                          type="button"
                        >
                          Edit all options
                        </button>
                      </div>
                    )
                  )}
                </div>
                <FadeIn
                  transitionAppear={false}
                  active={(hasOptions && isOptionsEnabled) || hasDraftOptions}
                >
                  <div className="view-section-content">
                    <ProductOptionsCombined
                      {...this.props}
                      record={record}
                      values={values}
                      currency={currency}
                      settings={settings}
                      attributes={attributes}
                      disabled={!isOptionsEnabled && !hasOptions}
                      editingOptions={editingOptions}
                      onClickEditOptions={onClickEditOptions}
                      onClickSaveOptions={onClickSaveOptions}
                      setCountDraftOptions={this.setCountDraftOptions}
                      onSubmitCombinedOptions={onSubmitCombinedOptions}
                    />
                    <ContentFields
                      {...this.props}
                      zone="options"
                      collection="products"
                      models={content.models}
                      record={record}
                      values={values}
                      currency={currency}
                    />
                  </div>
                </FadeIn>
              </div>
            </fieldset>
          </>
        )}
        {features.variants && hasVariants && (
          <fieldset className="full">
            <div className="view-body-section">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title="Variants"
                  titleIcon={<BoltIcon />}
                  subtitle="Unique combinations of selectable options (automatically generated)"
                />
              </div>
              <div className="view-section-content">
                <ProductVariants
                  record={record}
                  currency={currency}
                  settings={settings}
                  onClickEditVariant={onClickEditVariant}
                  onClickVariantImages={onClickVariantImages}
                />
                <Pagination
                  collection={collection}
                  title="variants"
                  onChangeLimit={onChangeLimit}
                  onClickPage={onClickPage}
                  help={
                    record.variants.count > record.variants.results.length && (
                      <span className="note">
                        <Help message="There's currently a limit to the number of variants you can edit at once. Contact us for details." />
                      </span>
                    )
                  }
                />
              </div>
            </div>
          </fieldset>
        )}
        {features.giftcard && (
          <>
            <fieldset className="full">
              <div className="view-body-section">
                <div className="view-body-subheader">
                  <SectionHeader
                    className="view-body-subtitle"
                    title="Gift card denominations"
                    subtitle="Manage gift card denominations offered for sale in your store"
                  />
                </div>
                <div className="view-section-content">
                  <GiftcardOptions
                    name="variant_options"
                    record={record}
                    settings={settings}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="full">
              <div className="view-body-section">
                <div className="view-body-subheader">
                  <SectionHeader
                    className="view-body-subtitle"
                    title="Gift card fulfillment"
                    subtitle="Manage the way gift cards are fulfilled when an order is placed"
                  />
                </div>
                <div className="view-section-content">
                  <Field
                    type="radio"
                    name="delivery"
                    options={[
                      {
                        value: 'giftcard',
                        label: 'Send gift cards automatically by email',
                      },
                      {
                        value: 'shipment',
                        label: 'Manually fulfill physical gift cards',
                      },
                    ]}
                    defaultValue={record.delivery}
                    stacked={true}
                  />
                  {values.delivery === 'shipment' && (
                    <FadeIn>
                      <GiftcardFulfillment
                        record={record}
                        values={values}
                        related={related}
                        onSubmitGenerateMoreGiftcards={
                          onSubmitGenerateMoreGiftcards
                        }
                        giftcardGenerating={giftcardGenerating}
                      />
                    </FadeIn>
                  )}
                </div>
              </div>
            </fieldset>
          </>
        )}
        <fieldset className="full">
          <div className="view-body-section">
            <div className="view-body-subheader">
              <SectionHeader
                className="view-body-subtitle"
                title="Related products"
                subtitle="Manage products offered as recommended up-sells and cross-sells"
              />
              <div className="view-body-actions">
                <Field
                  type="toggle"
                  name="related"
                  defaultChecked={hasRelatedProducts || !!values.related}
                  className="right"
                  readonly={true}
                  disabled={hasRelatedProductValues}
                />
              </div>
            </div>
            {(values.related !== undefined
              ? !!values.related
              : hasRelatedProducts) && (
              <FadeIn>
                <RelatedProducts
                  name="options"
                  record={record}
                  values={values}
                  lookup={lookup}
                  disabled={!values.related}
                />
                <ContentFields
                  {...this.props}
                  zone="related"
                  collection="products"
                  models={content.models}
                  record={record}
                  values={values}
                  currency={currency}
                />
              </FadeIn>
            )}
          </div>
        </fieldset>

        <fieldset className="full">
          <div className="view-body-section">
            <div className="view-body-subheader">
              <SectionHeader
                className="view-body-subtitle"
                title="Content"
                subtitle="Information to help customers find and understand your product"
              />
            </div>
            <div className="view-section-content">
              <FieldLocalized
                type="editor"
                name="description"
                label="Description"
                defaultValue={record.description}
                localeValue={record.$locale}
              />
              <FieldLocalized
                type="tags"
                label="Tags"
                name="tags"
                defaultValue={record.tags || ARR}
                localeValue={record.$locale}
                placeholder="Separate tags with a comma"
                help="Tags are commonly used to improve product search and discovery"
                rules="unique"
                editableTags
              />
              <FieldLocalized
                type="text"
                name="meta_title"
                label={
                  <span>
                    Page title{' '}
                    <span className="muted">
                      (
                      {charCounts.meta_title ||
                        (record.meta_title || '').length ||
                        0}{' '}
                      of 70 chars)
                    </span>
                  </span>
                }
                help="Search engines typically display the first 50-70 characters of your page title"
                defaultValue={record.meta_title}
                localeValue={record.$locale}
                placeholder={values.name}
                onChange={this.onChangeSEOField}
              />
              <FieldLocalized
                type="textarea"
                name="meta_description"
                label={
                  <span>
                    Meta description{' '}
                    <span className="muted">
                      (
                      {charCounts.meta_description ||
                        stripTags(record.meta_description).length ||
                        0}{' '}
                      of 170 chars)
                    </span>
                  </span>
                }
                help="Search engines typically display the first 140-170 characters of your page description"
                defaultValue={record.meta_description}
                localeValue={record.$locale}
                placeholder={stripTags(values.description) || 'Optional'}
                autoSize={true}
                rows={3}
                maxRows={20}
                onChange={this.onChangeSEOField}
              />
              <div className="row">
                <Field
                  type="text"
                  name="slug"
                  label="Slug"
                  help="Used to uniquely identify the product in a URL"
                  defaultValue={values.slug}
                  placeholder={slugify(values.name)}
                  hint={
                    <>
                      https://{client.id}.swell.store/products/
                      <b>{values.slug || slugify(values.name) || '...'}</b>/
                    </>
                  }
                  maxLength={70}
                  className="span4"
                />
              </div>
              <ContentFields
                {...this.props}
                zone="content"
                collection="products"
                models={content.models}
                record={record}
                values={values}
                currency={currency}
              />
            </div>
          </div>
        </fieldset>
        <fieldset className="full">
          <ProductPurchaseLinks
            items={record?.purchaseLinks?.results || []}
            currency={currency}
            settings={settings}
            onClickCreatePurchaseLink={onClickCreatePurchaseLink}
          />
        </fieldset>
        {contentFieldsDeprecated.length > 0 && (
          <ContentFieldsDeprecated
            base="content"
            types={contentFieldsDeprecated}
            record={record}
            values={values}
            currency={currency}
            lookup={lookup}
          />
        )}
        {!record.id && (
          <fieldset className="full">
            <div className="product-attributes-inline-form">
              <AttributesForm {...this.props} />
            </div>
          </fieldset>
        )}
      </div>
    );
  }

  renderTaxCodeField() {
    const { record = {}, values = {}, settings } = this.props;

    const activeTaxIntegrationId = findKey(
      settings.integrations.services,
      (s) => s && s.type === 'tax' && s.activated === true,
    );

    if (!activeTaxIntegrationId) {
      return null;
    }
    // TODO: does this make sense?
    // Not sure if subscriptions get taxed yet
    if (values.type === 'subscription') {
      return null;
    }

    const {
      text: defaultText,
      code: defaultCode,
      serviceName,
      referenceUrl,
    } = defaultTaxCodeAndHintText(activeTaxIntegrationId, values);
    const help =
      defaultCode && defaultCode.length > 0
        ? `Enter the ${serviceName} code for this product. Defaults to the tax code for ${defaultText} (${defaultCode}).`
        : `Enter the ${serviceName} code for this product. Defaults to ${defaultText} (no tax code).`;

    return (
      <div className="row">
        <Field
          type="string"
          label="Tax code"
          name="tax_code"
          defaultValue={record.tax_code}
          className="span1"
          help={help}
          placeholder={defaultCode}
          hint={
            referenceUrl && (
              <>
                Find product tax codes{' '}
                <a href={referenceUrl} target="_blank" rel="noreferrer">
                  here
                </a>
              </>
            )
          }
        />
      </div>
    );
  }

  render() {
    const { tab, tabs = { details: true }, record = {}, content } = this.props;

    let displayTab;
    let contentTab;
    switch (tab) {
      case 'attributes':
        displayTab = tab;
        break;
      case 'inventory':
        displayTab = tab;
        break;
      case 'shipping':
        displayTab = tab;
        break;
      default:
        const contentTabs = getContentTabs(content, 'products', 'edit');
        contentTab = contentTabs.find((t) => t.id === tab);
        if (contentTab) {
          displayTab = tab;
        } else {
          displayTab = 'details';
        }
    }

    // Note: we use `tabs` to avoid rendering all tabs unless they were visited at least once
    const renderTab = (renderer, tab) =>
      tabs[tab] && (
        <div
          style={{
            display: displayTab === tab ? 'block' : 'none',
          }}
        >
          {renderer()}
        </div>
      );

    return (
      <>
        {renderTab(() => this.renderDetailsTab(), 'details')}
        {contentTab &&
          renderTab(
            () => <ContentRecordTab {...this.props} contentTab={contentTab} />,
            tab,
          )}
        {record.id && (
          <>
            {renderTab(
              () => (
                <AttributesForm {...this.props} />
              ),
              'attributes',
            )}
            {renderTab(
              () => (
                <InventoryForm {...this.props} />
              ),
              'inventory',
            )}
            {renderTab(
              () => (
                <ShippingForm {...this.props} />
              ),
              'shipping',
            )}
          </>
        )}
      </>
    );
  }
}
