import React from 'react';
import { Form, Field } from 'components/form';
import View from 'components/view';
import './settings.scss';
import SectionHeader from 'components/section-header';

export default class ProductSettings extends React.PureComponent {
  render() {
    const { values, edited, onSubmitForm, onChangeForm } = this.props;

    return (
      <div className="settings settings-products">
        <Form ref="form" onSubmit={onSubmitForm} onChange={onChangeForm}>
          <View
            detail={true}
            uri="/settings"
            sectionTitle="Settings"
            headerTitle="Products"
            headerSubtitle="Manage settings that affect store products"
            headerActions={[
              {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary disabled',
                submit: true,
              },
            ]}
          >
            <fieldset className="full">
              <div className="view-body-section-group">
                <div className="view-body-section">
                  <div className="view-body-subheader">
                    <SectionHeader
                      className="view-body-subtitle"
                      title="Inventory tracking"
                      subtitle="Keep track of stock levels and history"
                    />
                    <div className="view-body-actions">
                      <Field
                        type="toggle"
                        name="features.stock_tracking"
                        defaultChecked={values.features.stock_tracking}
                      />
                    </div>
                  </div>
                </div>
                <div className="view-body-section">
                  <div className="view-body-subheader">
                    <SectionHeader
                      className="view-body-subtitle"
                      title="Product cost"
                      subtitle="Enable product and variant cost tracking"
                    />
                    <div className="view-body-actions">
                      <Field
                        type="toggle"
                        name="features.cost_tracking"
                        defaultChecked={values.features.cost_tracking}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </View>
        </Form>
      </div>
    );
  }
}
