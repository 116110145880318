import { loadScript } from 'utils';

const { GOOGLE_RECAPTCHA_SITE_KEY } = process.env;

const GOOGLE_RECAPTCHA_URL = 'https://www.google.com/recaptcha/api.js';

const executeReCaptcha = async (client, action) => {
  if (!isRecaptchaEnabled(client, action)) {
    return null;
  }

  const score = client.super_features.get('recaptcha').thresholds[action];

  await loadScript(
    'recaptcha',
    `${GOOGLE_RECAPTCHA_URL}?render=${GOOGLE_RECAPTCHA_SITE_KEY}`,
  );

  const { grecaptcha } = window;
  if (!grecaptcha) {
    // recaptcha was not loaded
    return null;
  }

  try {
    await new Promise((resolve) => grecaptcha.ready(resolve));
    const token = await grecaptcha.execute(GOOGLE_RECAPTCHA_SITE_KEY, {
      action,
    });
    return {
      token,
      action,
      score,
    };
  } catch (err) {
    console.error('recaptcha error', err);
  }
  return null;
};

// client
const executeLeadCreateRecaptcha = (client) =>
  executeReCaptcha(client, 'lead_create');
const executeSignupRecaptcha = (client) => executeReCaptcha(client, 'signup');
const executeClientVerifyRecaptcha = (client) =>
  executeReCaptcha(client, 'client_verify');
const executeClientUpdateRecaptcha = (client) =>
  executeReCaptcha(client, 'client_update');

// user
const executeLoginRecaptcha = (client) => executeReCaptcha(client, 'login');

// account
const executeAccountUpdateRecaptcha = (client) =>
  executeReCaptcha(client, 'account_update');
const executePlanCreateRecaptcha = (client) =>
  executeReCaptcha(client, 'plan_create');
const executePlanUpdateRecaptcha = (client) =>
  executeReCaptcha(client, 'plan_update');
const executePromoApplyRecaptcha = (client) =>
  executeReCaptcha(client, 'promo_apply');

/**
 * Determines whether recaptcha is enabled for a specific client & action.
 */
function isRecaptchaEnabled(client, action) {
  const recaptchaFeature = client?.super_features?.get('recaptcha');
  if (!recaptchaFeature) {
    // recaptcha feature is not globally enabled
    return false;
  }

  const score = recaptchaFeature?.thresholds?.[action];
  if (typeof score !== 'number' || score < 0 || score > 1) {
    // recaptcha is not globally configured. wrong score value
    return false;
  }

  const hasClientBypass = Boolean(client?.features?.has('recaptcha.bypass'));
  if (hasClientBypass) {
    // client has specific recaptcha bypass
    return false;
  }

  return true;
}

export {
  isRecaptchaEnabled,
  executeLeadCreateRecaptcha,
  executeLoginRecaptcha,
  executeSignupRecaptcha,
  executeClientVerifyRecaptcha,
  executeClientUpdateRecaptcha,
  executeAccountUpdateRecaptcha,
  executePlanCreateRecaptcha,
  executePlanUpdateRecaptcha,
  executePromoApplyRecaptcha,
};
