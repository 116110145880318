import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  discountStateFromRecord,
  discountUpdatesFromValues,
} from 'utils/discount';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';

import actions from 'actions';

import EditPage from 'components/promotion/edit';
import LoadingView from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';

export const mapStateToProps = (state) => ({
  data: state.data,
  loading: state.data.loading,
  record: state.data.record,
  errors: state.data.recordErrors,
  categories: state.categories,
  content: state.content,
  lookup: state.lookup,
  settings: state.settings,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchRecord: async (id) => {
    return dispatch(
      actions.data.fetchRecord('promotions', id, {
        expand: [
          'codes',
          'discounts.product',
          'discounts.buy_items.product',
          'discounts.get_items.product',
        ],
      }),
    );
  },

  loadCategories: async () => {
    return dispatch(actions.categories.load());
  },

  searchCategories: async (search) => {
    return dispatch(actions.categories.search(search, 10));
  },

  updateRecord: async (id, data) => {
    return dispatch(actions.data.updateRecord('promotions', id, data));
  },

  deleteRecord: async (id) => {
    return dispatch(actions.data.deleteRecord('promotions', id));
  },

  loadSettings() {
    return Promise.all([
      dispatch(actions.settings.fetch('discounts')),
      dispatch(actions.settings.fetch('shipments')),
      dispatch(actions.settings.fetch('accounts')),
    ]);
  },
});

export class EditPromotion extends React.PureComponent {
  static contextTypes = {
    notifyError: PropTypes.func.isRequired,
    notifyDeleted: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loaded: false,
      values: {},
      edited: false,
      onSubmitRecord: this.onSubmitRecord.bind(this),
      onChangeForm: this.onChangeForm.bind(this),
      onClickDelete: this.onClickDelete.bind(this),
      onQueryCategories: this.onQueryCategories.bind(this),
    };

    confirmRouteLeave(this);
  }

  componentDidMount() {
    const { params, fetchRecord, loadCategories, loadSettings } = this.props;

    loadSettings()
      .then(() => Promise.all([fetchRecord(params.id), loadCategories()]))
      .then(([record, categories]) => {
        this.setState({
          loaded: true,
          ...discountStateFromRecord(record, categories),
        });
      });
  }

  componentWillReceiveProps(nextProps) {
    const { params, categories, fetchRecord } = this.props;

    if (nextProps.params.id !== params.id) {
      this.setState({ loaded: false });
      fetchRecord(nextProps.params.id).then((record) => {
        this.setState({
          loaded: true,
          ...discountStateFromRecord(record, categories),
        });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  onChangeForm(values, edited) {
    this.setState({
      values: {
        ...this.state.values,
        ...values,
      },
      edited,
    });
  }

  onClickDelete(event) {
    event.preventDefault();

    this.context.openModal('ConfirmDelete', {
      title: this.props.record.name,
      onConfirm: () => {
        const { params, router, deleteRecord } = this.props;

        deleteRecord(params.id).then((result) => {
          if (result && !result.errors) {
            this.setState({ edited: false }, () => {
              router.replace(`/promotions`);
              this.context.notifyDeleted('Promotion');
            });
          }
        });
      },
    });
  }

  onSubmitRecord(values) {
    const { params, record, categories, fetchRecord, updateRecord } =
      this.props;

    this.setState({ edited: false });

    return updateRecord(params.id, discountUpdatesFromValues(values, record))
      .then((result) => {
        if (result) {
          if (result.errors) {
            this.context.notifyError(result.errors);
          } else {
            return fetchRecord(params.id).then((record) => {
              this.setState({
                edited: false,
                ...discountStateFromRecord(record, categories),
              });
            });
          }
        }
      });
  }

  onQueryCategories(value) {
    this.props.searchCategories(value);
  }

  render() {
    if (!this.state.loaded) {
      return <LoadingView />;
    }

    if (!this.props.record) {
      return <NotFoundPage />;
    }

    return <EditPage {...this.props} {...this.state} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditPromotion);
