import React from 'react';
import classNames from 'classnames';
import pt from 'prop-types';
import {
  get,
  find,
  findIndex,
  filter,
  groupBy,
  reduce,
  uniq,
  without,
} from 'lodash';

import { inflect, escapeRegExp, formatTableValue } from 'utils';

import Icon from 'components/icon';
import Checkbox from 'components/icon/checkbox';

import Label from './label';

function cleanValue(value) {
  return value ? String(value).trim().toLowerCase() : null;
}

function getInitialInputValue(props, state) {
  if (props.multiSelectable) {
    return '';
  }

  const rawValue = props.value ?? props.defaultValue ?? null;
  const inValue = props.newable ? get(rawValue, 'new', rawValue) : rawValue;

  const index = findIndex(
    state.options,
    (op) => cleanValue(op.value) === cleanValue(inValue),
  );

  if (index >= 0) {
    return state.options[index].label || inValue || '';
  }

  return inValue || '';
}

function makeOptionItem(option) {
  const isObject = typeof option === 'object';

  return {
    value: isObject ? option.value ?? option.id : option,
    label: String(
      isObject
        ? option.label !== undefined
          ? option.label
          : option.name !== undefined
          ? option.name
          : option.value ?? option.id
        : option,
    ).trim(),
  };
}

function getInitialOptions(props) {
  const ops = reduce(
    props.options,
    (acc, option) => {
      if (!option) return acc;

      if (option.options) {
        return option.options.reduce((acc, op) => {
          if (!op) return acc;

          const item = makeOptionItem(op);

          item.group = option.label;

          acc.push(item);

          return acc;
        }, acc);
      }

      acc.push(makeOptionItem(option));

      return acc;
    },
    [],
  );

  ops.forEach((option, index) => {
    option.index = index;
  });

  return ops;
}

function getInitialValue(props) {
  let value = props.value ?? props.defaultValue ?? null;

  if (props.multiSelectable && !Array.isArray(value)) {
    value = value ? [value] : [];
  }

  return value;
}

export default class InputSelect extends React.PureComponent {
  static propTypes = {
    id: pt.string,
    name: pt.string,
    help: pt.oneOfType([pt.node, pt.object]),
    model: pt.string,
    label: pt.node,
    rawLabel: pt.string,
    labelRenderer: pt.func,
    placeholder: pt.string,
    value: pt.oneOfType([pt.string, pt.array]),
    defaultValue: pt.oneOfType([pt.string, pt.array]),
    tabIndex: pt.number,
    options: pt.array.isRequired,
    newable: pt.bool,
    multiSelectable: pt.bool,
    groupSelectable: pt.bool,
    groupSelectLabel: pt.string,
    disabled: pt.bool,
    disabledOptions: pt.array,
    renderIcon: pt.func,
    iconWidth: pt.number,
    readonly: pt.bool,
    readonlyContent: pt.bool,
    selectFocus: pt.bool,
    clearIcon: pt.bool,

    onClickSelectValue: pt.func,
    onChange: pt.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showing: false,
      selected: null,
      options: getInitialOptions(props),
      value: getInitialValue(props),
      inputValue: '',
      filteredOptions: null,
      foundExact: true,
      disabledOptions: props.disabledOptions || [],
    };

    this.state.inputValue = getInitialInputValue(this.props, this.state);

    this.rootRef = React.createRef();
    this.hiddenInputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutsideComponent);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutsideComponent);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.setState(
        {
          options: getInitialOptions(this.props),
        },
        () => {
          this.setState({
            inputValue: getInitialInputValue(this.props, this.state),
          });
        },
      );
    }

    if (
      prevProps.value !== this.props.value ||
      prevProps.defaultValue !== this.props.defaultValue
    ) {
      this.setState({
        value: getInitialValue(this.props),
        inputValue: getInitialInputValue(this.props, this.state),
      });
    }
  }

  onMouseDown = (event) => {
    event.stopPropagation();

    this.setState((state) => ({
      showing: !state.showing,
    }));
  };

  onFocus = () => {
    if (this.props.selectFocus) {
      this.refs.input.select();
    }
  };

  onClickChevron = (event) => {
    event.stopPropagation();

    if (!this.state.showing) {
      this.refs.input.focus();
    }

    this.setState((state) => ({
      showing: !state.showing,
    }));
  };

  onClickClearValue = (event) => {
    event.stopPropagation();
    this.setState({ inputValue: '' });
    this.setValue(event, '');
  };

  /** @param {React.KeyboardEvent} event */
  onKeyDown = (event) => {
    switch (event.key) {
      case 'Enter': {
        if (this.state.showing) {
          event.preventDefault();
        }

        this.selectValue(event);

        break;
      }

      case 'Escape': {
        event.preventDefault();

        if (this.state.showing) {
          this.setState({ showing: false });
        } else {
          this.setState((state, props) => ({
            value: props.multiSelectable ? state.value : null,
            inputValue: '',
            foundExact: false,
            filteredOptions: null,
          }));
        }

        break;
      }

      case 'ArrowUp':
      case 'ArrowLeft': {
        event.preventDefault();

        if (this.state.showing) {
          this.selectPrev();
        } else {
          this.setState({ showing: true });
        }

        break;
      }

      case 'ArrowDown':
      case 'ArrowRight': {
        event.preventDefault();

        if (this.state.showing) {
          this.selectNext();
        } else {
          this.setState({ showing: true });
        }

        break;
      }

      // Space
      case ' ': {
        if (event.target.value.length <= 0) {
          event.preventDefault();
        }

        break;
      }

      case 'Tab': {
        this.setState({ showing: false });
        this.selectValue(event);
        break;
      }

      default:
        break;
    }
  };

  getFilteredOptions(inputValue) {
    const { options } = this.state;

    let foundExact = false;
    let filteredOptions = options;
    let selected = null;

    if (inputValue.length) {
      const regex = new RegExp(escapeRegExp(`${inputValue}`), 'i');
      filteredOptions = options.reduce((found, option, index) => {
        if (regex.test(option.label) || regex.test(option.value)) {
          found.push(option);
          if (option.label.length === inputValue.trim().length) {
            foundExact = true;
            selected = found.length - 1;
          }
        }
        return found;
      }, []);
    }

    if (selected === null) {
      if (filteredOptions.length) {
        selected = filteredOptions[0].index;
      } else {
        selected = { new: inputValue };
      }
    }

    return {
      foundExact,
      filteredOptions,
      selected,
    };
  }

  onChange = () => {
    this.setState((state, props) => ({
      value: props.multiSelectable ? state.value : null,
      inputValue: this.refs.input.value,
      showing: true,
      ...this.getFilteredOptions(this.refs.input.value),
    }));
  };

  setValue(event, val, isArray = false, shouldRemove = false) {
    const { multiSelectable } = this.props;
    const { value: stateValue, inputValue } = this.state;

    const synthEvent = new Event('change', { bubbles: true });

    let value = val;

    if (multiSelectable) {
      if (val) {
        if (isArray) {
          if (shouldRemove) {
            value = without(stateValue, ...value);
          } else {
            value = uniq([...stateValue, ...value]);
          }
        } else {
          if (stateValue.indexOf(value) >= 0) {
            value = without(stateValue, value);
          } else {
            value = [...stateValue, value];
          }
        }
      } else {
        value = stateValue;
      }
    }

    this.setState({ value });

    this.hiddenInputRef.current.value = value || '';
    this.hiddenInputRef.current.realValue = value;
    this.hiddenInputRef.current.dispatchEvent(synthEvent);

    const result = this.props.onChange(
      synthEvent,
      value,
      event ? event.isTrusted : false,
    );

    if (result === false) {
      this.setState({
        value: stateValue,
        inputValue: inputValue,
      });

      this.hiddenInputRef.current.value = stateValue || '';
      this.hiddenInputRef.current.realValue = stateValue;
    }
  }

  selectValue(event) {
    const { showing, selected, options, filteredOptions, disabledOptions } =
      this.state;

    const { multiSelectable, newable } = this.props;

    if (!showing) {
      return;
    }

    const ops = filteredOptions || options;
    const option = find(ops, { index: selected });
    if (option && disabledOptions.includes(option.value)) {
      return;
    }

    if (!multiSelectable) {
      this.setState({
        showing: false,
        selected: null,
        inputValue: option ? option.label : selected ? selected.new : '',
        filteredOptions: null,
        foundExact: !!option,
      });
    }

    if (option) {
      this.setValue(event, option.value);
    } else if (newable) {
      this.setValue(event, selected);
    } else {
      this.setState({ inputValue: '' });
      this.setValue(event, '');
    }
  }

  findNext(selected = null) {
    const { options, filteredOptions, value, inputValue } = this.state;
    const ops = filteredOptions || options;

    if (selected !== null) {
      const index = findIndex(ops, { index: selected }) ?? -1;

      if (index + 1 < ops.length) {
        return ops[index + 1].index;
      }

      if (this.props.newable && inputValue && selected !== inputValue) {
        return { new: inputValue };
      }

      return ops[0].index;
    }

    if (ops) {
      const option = find(ops, { value });

      return option ? this.findNext(option.index) ?? 0 : 0;
    }

    return null;
  }

  findPrev(selected = null) {
    const { options, filteredOptions, value, inputValue } = this.state;
    const ops = filteredOptions || options;

    if (selected !== null) {
      const index = findIndex(ops, { index: selected }) ?? 0;

      if (selected.new) {
        return ops[ops.length - 1] ? ops[ops.length - 1].index : 0;
      }

      if (index > 0) {
        return ops[index - 1].index;
      }

      if (this.props.newable && inputValue && selected !== inputValue) {
        return { new: inputValue };
      }

      return ops[ops.length - 1].index;
    }

    if (ops) {
      const option = find(ops, { value });

      return option ? this.findPrev(option.index) ?? 0 : 0;
    }

    return null;
  }

  selectNext() {
    const { selected } = this.state;
    const next = this.findNext(selected);

    if (next !== null) {
      this.setState({ selected: next });
    }
  }

  selectPrev() {
    const { selected } = this.state;
    const prev = this.findPrev(selected);

    if (prev !== null) {
      this.setState({ selected: prev });
    }
  }

  /** @param {React.MouseEvent} event */
  onClickSelectValue = (event) => {
    event.preventDefault();

    if (event.currentTarget.className.includes('muted')) {
      return;
    }

    this.selectValue(event);

    // Scroll back to top of element
    const modals = document.getElementsByClassName('modal');

    const main =
      modals.length > 0
        ? modals[modals.length - 1]
        : document.getElementById('main');

    if (main) {
      const top = main.scrollTop;
      main.scrollTop = top;
    }

    const { multiSelectable, onClickSelectValue } = this.props;

    if (multiSelectable) {
      this.refs.input.focus();
    } else if (onClickSelectValue) {
      onClickSelectValue(event);
    }
  };

  /** @param {React.MouseEvent} event */
  onClickSelectGroup = (event) => {
    event.preventDefault();

    if (event.currentTarget.className.includes('muted')) {
      return;
    }

    const { group } = event.currentTarget.dataset;
    const { value, options, filteredOptions, disabledOptions } = this.state;
    const ops = filteredOptions || options;
    const groupOptions = filter(
      ops,
      (op) => op.group === group && disabledOptions.indexOf(op.value) === -1,
    );
    if (!groupOptions.length) {
      return;
    }
    const groupValues = groupOptions.map((op) => op.value);
    const shouldRemove = groupValues.reduce(
      (acc, val) => acc && value.indexOf(val) >= 0,
      true,
    );
    this.setValue(
      event,
      groupOptions.map((op) => op.value),
      true,
      shouldRemove,
    );
    this.refs.input.focus();
  };

  /** @param {React.MouseEvent} event */
  onMouseOverValue = (event) => {
    const { new: isNew, index, group } = event.currentTarget.dataset;
    if (isNew) {
      this.setState({
        selected: { new: this.state.inputValue },
      });
    } else if (group) {
      this.setState({
        groupSelected: group,
        selected: null,
      });
    } else {
      this.setState({
        selected: Number(index),
        groupSelected: null,
      });
    }
  };

  /** @param {React.MouseEvent} event */
  onClickOutsideComponent = (event) => {
    if (event.target && !this.rootRef.current.contains(event.target)) {
      if (this.state.showing) {
        this.setState((state) => ({
          showing: false,
          inputValue: state.value ? state.inputValue : '',
          selected: null,
          filteredOptions: null,
        }));
      }
    }
  };

  renderOption(option) {
    const { multiSelectable, renderIcon } = this.props;
    const { selected, value, disabledOptions } = this.state;

    const icon = renderIcon && renderIcon(option.value);

    const isSelected = selected === option.index;
    const isCurrent = option.value === value;

    return (
      <li
        key={option.index}
        role="option"
        aria-disabled={disabledOptions.includes(option.value)}
        aria-selected={isSelected}
        aria-current={isCurrent}
        data-index={option.index}
        className="item"
        onMouseOver={this.onMouseOverValue}
        onClick={this.onClickSelectValue}
      >
        <span className="col">
          {multiSelectable && (
            <Checkbox
              checked={value?.includes(option.value)}
              className="checkbox"
              style={{ left: 14 }}
              animated={false}
            />
          )}

          {icon && <span className="form-select-icon">{icon}</span>}

          {option.label || <span>&nbsp;</span>}
        </span>
      </li>
    );
  }

  renderOptions() {
    const {
      multiSelectable = false,
      groupSelectable,
      groupSelectLabel,
    } = this.props;

    const {
      options,
      selected,
      groupSelected,
      inputValue,
      filteredOptions,
      foundExact,
    } = this.state;

    const ops = filteredOptions || options;

    const groups = groupBy(ops, 'group');

    const items = reduce(
      groups,
      (acc, groupOp, groupName) => {
        if (groupName !== 'undefined') {
          acc.push(
            <li key={groupName} className="item-group">
              <span
                data-group={groupName}
                {...(groupSelectable && {
                  className: classNames('item-group-selectable', {
                    selected: groupSelected === groupName,
                  }),
                  onMouseOver: this.onMouseOverValue,
                  onClick: this.onClickSelectGroup,
                })}
              >
                {groupName}

                {groupSelected === groupName && groupSelectLabel && (
                  <span className="item-group-select-label">
                    {inflect(groupOp.length, groupSelectLabel)}
                  </span>
                )}
              </span>

              <ul>{groupOp.map((op) => this.renderOption(op))}</ul>
            </li>,
          );

          return acc;
        }

        return groupOp.map((op) => this.renderOption(op));
      },
      [],
    );

    if (this.props.newable && inputValue.length && !foundExact) {
      const isSelected = Boolean(selected?.new);

      items.push(
        <li
          key="new"
          role="option"
          aria-selected={isSelected}
          data-new={true}
          className="item"
          onMouseOver={this.onMouseOverValue}
          onClick={this.onClickSelectValue}
        >
          <span className="col">{`Create "${inputValue}"`}</span>
        </li>,
      );
    }

    if (items.length > 0) {
      return (
        <ul
          className={classNames('form-select-list', {
            'form-select-multi': multiSelectable,
          })}
        >
          {items}
        </ul>
      );
    }

    return null;
  }

  render() {
    const {
      label,
      rawLabel,
      labelRenderer,
      help,
      model,
      options,
      newable,
      disabledOptions,
      placeholder,
      tabIndex,
      multiSelectable,
      groupSelectable,
      groupSelectLabel,
      renderIcon,
      iconWidth,
      name,
      id,
      onClickSelectValue,
      selectFocus,
      readonlyContent,
      readonly,
      clearIcon,
      ...props
    } = this.props;

    const { showing, inputValue } = this.state;

    const iconValue = renderIcon && renderIcon(this.state.value);
    const listboxId = `${id || name}-listbox`;

    return (
      <div ref={this.rootRef} className="form-select">
        <Label label={label} help={help} htmlFor={id} />

        {readonlyContent ? (
          <div className="form-field-readonly">
            {formatTableValue(inputValue)}
          </div>
        ) : (
          <div
            className={`form-field-input${
              clearIcon ? ' form-field-with-clear' : ''
            }`}
          >
            <input
              id={id}
              ref="input"
              type="text"
              role="combobox"
              aria-controls={listboxId}
              aria-expanded={showing}
              value={inputValue}
              defaultValue={undefined}
              onChange={this.onChange}
              onKeyDown={this.onKeyDown}
              onMouseDown={this.onMouseDown}
              onFocus={this.onFocus}
              disabled={props.disabled}
              name={name}
              autoComplete="off"
              placeholder={placeholder}
              className={classNames('form-select-input', props.className, {
                disabled: props.disabled,
                focus: showing,
              })}
              tabIndex={tabIndex}
              style={{
                paddingLeft: iconValue ? iconWidth || undefined : undefined,
              }}
              data-testid="rtl-inputSelect-input"
              data-1p-ignore="true"
            />

            <input
              type="text"
              name={name}
              disabled={props.disabled}
              style={{ display: 'none' }}
            />

            <input
              {...props}
              type="hidden"
              ref={this.hiddenInputRef}
              name={name}
              disabled={props.disabled}
              value={inputValue}
              defaultValue={undefined}
              readOnly={readonly}
            />

            {iconValue && <span className="form-select-icon">{iconValue}</span>}

            {clearIcon && inputValue && (
              <button
                tabIndex={-1}
                className="form-select-clear"
                onClick={this.onClickClearValue}
                type="button"
              >
                <Icon fa="xmark" faType="solid" className="icon-clear" />
              </button>
            )}

            <button
              tabIndex={-1}
              className="form-select-chevron"
              onClick={this.onClickChevron}
              type="button"
            >
              <Icon type="chevron" />
            </button>

            <div
              id={listboxId}
              role="listbox"
              className="form-select-list-container"
            >
              {!props.disabled && showing && this.renderOptions()}
            </div>
          </div>
        )}
      </div>
    );
  }
}
