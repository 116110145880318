import React from 'react';
import Icon from 'components/icon';
import CollectionEmpty from 'components/collection/empty';

export default function BuildAppsCallout() {
  return (
    <div className="apps-callout-build">
      <CollectionEmpty
        className="apps-callout-build"
        emptyTitle="Make commerce for everyone"
        emptyDescription="Apps are a new way to create amazing experiences for customers, with built-in content models, notifications, serverless functions, and more. All managed by Swell."
        emptyAction={
          <>
            <Icon fa="book" />
            &nbsp;&nbsp;Read the docs
          </>
        }
        emptyActionLink="https://www.notion.so/swellcorp/Swell-Apps-4dc8e6e696e14778973a8e5a1bc3575c?pvs=4"
        emptyActionTarget="blank"
        emptySecondAction={
          <>
            <Icon fa="discord fa-brands" />
            &nbsp;&nbsp;Join our Discord community
          </>
        }
        emptySecondActionLink="https://discord.com"
        emptySecondActionTarget="blank"
      />
    </div>
  );
}
