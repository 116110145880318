import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import { FadeIn } from 'components/transitions';

export default class Fieldgroup extends React.Component {
  static propTypes = {
    label: PropTypes.any,
    defaultActive: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      active: !!props.defaultActive,
    };
  }

  componentWillReceiveProps(nextProps) {
    const isValueChanged = nextProps.defaultActive !== this.props.defaultActive;
    if (isValueChanged) {
      this.setState({ active: !!nextProps.defaultActive });
    }
  }

  onClickToggle = (event) => {
    event.preventDefault();
    this.setState({ active: !this.state.active });
  };

  render() {
    const {
      children,
      arrow = true,
      transition = true,
      label,
      className,
      defaultActive,
      ...attrs
    } = this.props;
    const { active } = this.state;
    const isActive = transition ? active : defaultActive;

    return (
      <div
        {...attrs}
        className={`form-fieldgroup ${className || ''} ${
          active ? 'active' : ''
        }`}
      >
        <label>
          <a
            onClick={this.onClickToggle}
            href=""
            className={`${!arrow ? 'view-link' : ''}`}
          >
            {arrow && (
              <span className="chevron">
                <Icon fa="chevron-down" />
              </span>
            )}
            {label}
          </a>
        </label>
        <FadeIn
          transitionAppear={true}
          active={isActive}
          className="form-fieldgroup-children"
        >
          {children}
        </FadeIn>
      </div>
    );
  }
}
