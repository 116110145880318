import React from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { capitalize } from 'lodash';

import { hasPermission, isValueEqual, classNames } from 'utils';

import Link from 'components/link';
import Icon from 'components/icon';
import AppIcon from 'components/apps/icon';
import { FadeIn } from 'components/transitions';

import './nav.scss';

const AUTO_TOGGLED = {};

export default class NavItem extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object,
    isAdvancedUserPermissions: PropTypes.bool,
    user: PropTypes.object.isRequired,
    router: PropTypes.object,
    setTitle: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      childItems: this.getChildItems(props),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!isValueEqual(this.props.items, nextProps.items)) {
      this.setState({ childItems: this.getChildItems(nextProps) });
    }
  }

  getChildItems({ items }) {
    const { isAdvancedUserPermissions = false, user } = this.context;
    let childItems = items ? items.filter((x) => !!x) : [];
    if (childItems.length > 0) {
      childItems = childItems.filter(
        (item) =>
          !item.permission ||
          hasPermission(user, item.permission, item.link, {
            isAdvancedUserPermissions,
          }),
      );
    }
    return childItems;
  }

  onClickNavLink = (event) => {
    event.preventDefault();
    const { toggleNavGroup, target, label } = this.props;
    if (toggleNavGroup) {
      if (event.target.dataset.open === undefined) {
        event.preventDefault();
        return;
      }
      if (event.target.dataset.open === 'false') {
        toggleNavGroup(label);
      }
    }
    let { link } = this.props;
    if (link === undefined) {
      link = this.state.childItems[0].link;
    }
    if (link) {
      if (target) {
        window.open(link);
      } else {
        this.context.router.push(link);
        this.resetScrollTop();
      }
    }
  };

  resetScrollTop() {
    const main = document.getElementById('main');
    if (main) {
      main.scrollTop = 0;
    }
  }

  renderSortableList(navItemChildren, label) {
    return (
      <Droppable droppableId={label} type="item">
        {(dropProvided) => (
          <ul ref={dropProvided.innerRef} className="nav-group-list">
            {navItemChildren.map((item, index) => (
              <Draggable key={item.key} draggableId={item.label} index={index}>
                {(dragProvided) => (
                  <li
                    ref={dragProvided.innerRef}
                    className="open"
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                  >
                    <NavItem {...item} />
                  </li>
                )}
              </Draggable>
            ))}

            {dropProvided.placeholder}
          </ul>
        )}
      </Droppable>
    );
  }

  renderLabel() {
    const { label } = this.props;
    return label;
  }

  onOpenKebab = (kebabOpen) => {
    this.setState({ kebabOpen });
  };

  renderItem(childItems) {
    const {
      label,
      icon,
      faIcon,
      app_id,
      isActive,
      onClickNavGroup,
      activeGroups,
      otherItems,
    } = this.props;

    const { client } = this.context;

    const isActiveGroup = activeGroups && activeGroups[label];
    const app = client.appsById?.[app_id];
    const showAppIcon =
      app && otherItems.filter((i) => i.label === label).length > 0;

    return (
      <>
        {icon ? (
          <span className="icon-id">
            <Icon type={`${isActive ? `${icon}-active` : icon}`} />
          </span>
        ) : (
          faIcon && (
            <span className="icon-id">
              <Icon fa={faIcon} />
            </span>
          )
        )}
        {this.renderLabel()}
        {app && (
          <span className={classNames('icon-app', { show: showAppIcon })}>
            <AppIcon image={app.logo_icon} name={app.name} size={20} />
          </span>
        )}
        {childItems.length > 0 && (
          <span onClick={onClickNavGroup} className="chevron" data-id={label}>
            <Icon
              fa="chevron-down icon"
              className={Boolean(isActiveGroup) ? 'open' : ''}
            />
          </span>
        )}
      </>
    );
  }

  renderInner() {
    const {
      label,
      link,
      location,
      activeChildItem,
      onClickNavGroup,
      activeGroups,
      path,
    } = this.props;
    const { childItems } = this.state;

    const isActiveGroup = activeGroups && activeGroups[label];

    const navItemChildren = childItems.map((item, index) => {
      const itemProps = {
        index,
        key: `${index}.${item.link}`,
        path: `${path}.items.${index}`,
        isActive: activeChildItem?.link === item?.link,
        location,
        activeChildItem: activeChildItem,
        otherItems: childItems.filter((i) => i !== item),
        ...item,
      };
      return <NavItem {...itemProps} />;
    });

    return (
      <>
        {link ? (
          <Link
            to={link}
            onClick={this.onClickNavLink}
            data-open={!!isActiveGroup}
          >
            {this.renderItem(childItems)}
          </Link>
        ) : (
          <a
            href=""
            onClick={link === false ? onClickNavGroup : this.onClickNavLink}
            data-id={label}
            data-open={!!isActiveGroup}
          >
            {this.renderItem(childItems)}
          </a>
        )}
        {childItems.length > 0 && isActiveGroup && (
          <FadeIn
            component="ul"
            className="nav-group-list"
            transitionAppear={true}
          >
            {navItemChildren}
          </FadeIn>
        )}
      </>
    );
  }

  render() {
    const {
      label,
      link,
      items,
      isActive,
      toggleNavGroup,
      activeGroups,
      permission,
    } = this.props;
    const { isAdvancedUserPermissions = false, user } = this.context;
    const { childItems } = this.state;

    if (!label) {
      return null;
    }

    if (items && items.length > 0 && childItems.length === 0) {
      return null;
    }

    if (!hasPermission(user, permission, link, { isAdvancedUserPermissions })) {
      return null;
    }

    const isActiveGroup = activeGroups && activeGroups[label];

    if (isActive) {
      if (!AUTO_TOGGLED[label] && !isActiveGroup && toggleNavGroup) {
        AUTO_TOGGLED[label] = true;
        setTimeout(() => toggleNavGroup(label), 1);
      }
      this.context.setTitle(
        label.indexOf('All ') === 0
          ? capitalize(label.replace('All ', ''))
          : label,
      );
    }

    return (
      <li
        className={`${isActive ? 'active' : ''} ${isActiveGroup ? 'open' : ''}`}
      >
        {this.renderInner()}
      </li>
    );
  }
}
