import { omit } from 'lodash';
import {
  STATUS_ITEM_CREATE,
  STATUS_ITEM_UPDATE,
  STATUS_ITEM_DELETE,
} from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case STATUS_ITEM_CREATE:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    case STATUS_ITEM_UPDATE:
      if (!state[action.payload.id]) {
        return state;
      }

      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload,
        },
      };

    case STATUS_ITEM_DELETE:
      return omit(state, action.payload.id);
    default:
      return state;
  }
}
