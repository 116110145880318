import React from 'react';
import { get, find, map } from 'lodash';
import geoData from './geo-data';
import Help from 'components/tooltip/help';
import NumAbbrev from 'number-abbreviate';
import currencyFormatter from 'currency-formatter';
import currencies from 'currency-formatter/currencies.json';
import { parseCurrency, formatCurrency, CURRENCY_CODE } from 'utils';

const numAbbrev = new NumAbbrev();

export function formatCurrencyRound(value, code = CURRENCY_CODE) {
  return currencyFormatter.format(value, {
    code,
    precision: 0,
  });
}

export function currencyValueRound(value, code = CURRENCY_CODE) {
  const floatVal =
    typeof value === 'string' ? parseCurrency(value, code) : value;
  return !currencies[code] || isNaN(floatVal)
    ? value
    : currencyFormatter.format(floatVal, {
        code,
        symbol: '',
        precision: 0,
      });
}

export function formatCurrencyAbbreviated(value = 0, code = CURRENCY_CODE) {
  const formattedNum = currencyFormatter.format(value, {
    code,
    precision: 0,
  });

  const formattedValue = currencyFormatter.format(value, {
    code,
    precision: 0,
    format: {
      pos: '%v',
      neg: '%v',
      zero: '%v',
    },
  });

  const abbreviatedValue = numAbbrev.abbreviate(value, 2);

  return value >= 1000
    ? formattedNum.replace(formattedValue, abbreviatedValue)
    : formattedNum;
}

export function currencyName(code) {
  return get(find(geoData.currencies, { id: code }), 'name');
}

export function currencyDecimals(code) {
  return get(currencies, `${code}.decimalDigits`, 0);
}

export function multiCurrencyValueFromTotals(values, field, currency) {
  return values.reduce(
    (acc, value) =>
      value.currency === currency ? acc + get(value, field, 0) : acc,
    0,
  );
}

export function renderMultiCurrencyValuesTooltip(
  values,
  field,
  currency,
  dir = undefined,
) {
  const inValues = Array.isArray(values)
    ? values
    : map(values, (vals, currency) => ({
        [field]: vals[field],
        currency,
      }));

  /** @type {Map<string, number>} */
  const tipValues = inValues
    .filter((val) => val.currency !== currency)
    .reduce((map, value) => {
      return map.set(
        value.currency,
        (map.get(value.currency) || 0) + value[field] || 0,
      );
    }, new Map());

  if (tipValues.size > 0) {
    return (
      <Help
        message={Array.from(tipValues.entries())
          .map(([currency, value]) => formatCurrency(value, currency))
          .join(', ')}
        dir={dir}
      />
    );
  }

  return null;
}
