import React from 'react';
import PropTypes from 'prop-types';
import { Root, Trigger, Portal, Content } from '@radix-ui/react-dropdown-menu';

import './dropdown-menu.scss';

/**
 * Displays a menu to the user—such as a set of actions or functions—triggered by a button
 */
export default class DropdownMenu extends React.PureComponent {
  static propTypes = {
    rootRest: PropTypes.object,
    triggerChildren: PropTypes.any.isRequired,
    triggerClassName: PropTypes.string,
    triggerRest: PropTypes.object,
    contentClassName: PropTypes.string,
    contentRest: PropTypes.object,
    children: PropTypes.any.isRequired,
  };

  render() {
    const {
      rootRest,
      triggerChildren,
      triggerClassName,
      triggerRest,
      contentClassName,
      contentRest,
      children,
    } = this.props;

    return (
      <Root {...rootRest}>
        <Trigger asChild>
          <button
            type="button"
            className={
              triggerClassName ? triggerClassName : 'dropdown-menu-trigger'
            }
            {...triggerRest}
          >
            {triggerChildren}
          </button>
        </Trigger>
        <Portal>
          <Content
            className={
              contentClassName ? contentClassName : 'dropdown-menu-content'
            }
            {...contentRest}
          >
            {children}
          </Content>
        </Portal>
      </Root>
    );
  }
}
