import React from 'react';
import classNames from 'classnames';

export default class ViewSide extends React.PureComponent {
  render() {
    const { className, children, ...props } = this.props;

    return (
      <aside {...props} className={classNames('view-side', className)}>
        {children}
      </aside>
    );
  }
}
