import React from 'react';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import './password-reset.scss';

export default class CustomerPasswordReset extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      passwordShown: false,
    };
  }

  togglePassword = (e) => {
    const { passwordShown } = this.state;
    this.setState({ passwordShown: !passwordShown });
    e.preventDefault();
  };

  render() {
    const { onClickPasswordReset, onSubmitPasswordReset } = this.props;
    const { passwordShown } = this.state;

    return (
      <Form onSubmit={onSubmitPasswordReset} autoFocus={true}>
        <Modal
          title="Change password"
          className="customer-view-modal-password-reset"
          actions={[
            { label: 'Save', type: 'submit' },
            { label: 'Cancel', type: 'cancel', onClick: onClickPasswordReset },
          ]}
          width={500}
          cancel={false}
          onClose={onClickPasswordReset}
        >
          <fieldset>
            <Field
              type={passwordShown ? 'text' : 'password'}
              label="New password"
              name="password"
              placeholder="Enter new password"
              rules="required, password"
              autoComplete="new-password"
            />
            <button
              className="button-show-password"
              onClick={this.togglePassword}
            >
              {passwordShown ? 'Hide password' : 'Show password'}
            </button>
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
