import axios from 'axios';
import { get as getProperty } from 'lodash';
import loki from 'lokijs';
import lokiIndexedAdapter from 'lokijs/src/loki-indexed-adapter';

import auth from './auth';
import { stringifyQuery, LOCALE_CODES, CURRENCY_CODES } from 'client/src/utils';

/** @typedef {import('lokijs').Collection} Collection */
/** @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig */

const {
  NODE_ENV,
  API_PORT,
  BASE_URI = '',
  CLIENT_URL,
  LOKI_DB_NAME,
  SWELL_VERSION,
} = process.env;

let { API_URL } = process.env;

const isTest = NODE_ENV === 'test';

/** @type {Collection} */
let consoleHistory;

const API_BASE = `${BASE_URI}/api`;

API_URL = API_URL || window.location.origin || 'http://localhost:4001';

if (API_PORT) {
  API_URL = API_URL.replace(/^(.+):[0-9]+$/, `$1:${API_PORT}`);
}

const SWELL_API_URL = CLIENT_URL.replace('CLIENT_ID.', '');

export const BASE_API_URL = API_URL;

export function getLocalizedParams(data) {
  return {
    $locale: data.$locale || (LOCALE_CODES.length ? LOCALE_CODES : undefined),
    $currency:
      data.$currency || (CURRENCY_CODES.length ? CURRENCY_CODES : undefined),
  };
}

const actions = {
  /**
   * @template T
   * @param {string} uri
   * @param {Record<string, unknown>} [query]
   * @param {Record<string, string>} [headers]
   * @param {AxiosRequestConfig} [options]
   * @returns {Promise<T>}
   */
  get(uri, query, headers, options) {
    return requestPromise({
      method: 'GET',
      uri: `${API_URL}${API_BASE}${uri}`,
      qs: query,
      headers,
      ...options,
    });
  },

  /**
   * @template T
   * @param {string} uri
   * @param {Record<string, unknown>} [query]
   * @param {Record<string, string>} [headers]
   * @param {AxiosRequestConfig} [options]
   * @returns {Promise<T>}
   */
  getLocalized(uri, query, headers, options) {
    const data = query || {};

    return requestPromise({
      method: 'GET',
      uri: `${API_URL}${API_BASE}${uri}`,
      qs: {
        ...data,
        ...getLocalizedParams(data),
      },
      headers,
      ...options,
    });
  },

  // Get localized store settings: country, home_page, etc.
  storefrontGetLocalized(client, uri) {
    return requestPromise({
      method: 'GET',
      uri: `${API_URL}/api${uri}`,
      qs: {
        $locale: LOCALE_CODES.length ? LOCALE_CODES : undefined,
        $currency: CURRENCY_CODES.length ? CURRENCY_CODES : undefined,
      },
      headers: {
        Authorization: `Basic ${Buffer.from(client.public_key).toString(
          'base64',
        )}`,
      },
    });
  },

  async getAll(uri, query) {
    let nextPage = 1;

    const result = {
      results: [],
      count: 0,
    };

    do {
      const pageResult = await requestPromise({
        method: 'GET',
        uri: `${API_URL}${API_BASE}${uri}`,
        qs: {
          ...(query || undefined),
          $locale: LOCALE_CODES.length ? LOCALE_CODES : undefined,
          $currency: CURRENCY_CODES.length ? CURRENCY_CODES : undefined,
          limit: 1000,
          page: nextPage,
        },
      });
      if (pageResult && pageResult.results) {
        result.results = result.results.concat(pageResult.results);
        result.count = pageResult.count;
        nextPage++;
      } else {
        break;
      }
    } while (result.count > result.results.length && result.results.length > 0);
    return result;
  },

  /**
   * @template T
   * @param {string} uri
   * @param {Record<string, unknown>} data
   * @param {Record<string, string>} [headers]
   * @param {AxiosRequestConfig} [options]
   * @returns {Promise<T>}
   */
  put(uri, data, headers, options) {
    return requestPromise({
      method: 'PUT',
      uri: `${API_URL}${API_BASE}${uri}`,
      data,
      headers,
      ...options,
    });
  },

  /**
   * @template T
   * @param {string} uri
   * @param {Record<string, unknown>} data
   * @param {Record<string, string>} [headers]
   * @param {AxiosRequestConfig} [options]
   * @returns {Promise<T>}
   */
  post(uri, data, headers, options) {
    return requestPromise({
      method: 'POST',
      uri: `${API_URL}${API_BASE}${uri}`,
      data,
      headers,
      ...options,
    });
  },

  /**
   * @template T
   * @param {string} uri
   * @param {Record<string, unknown>} data
   * @param {Record<string, string>} [headers]
   * @param {AxiosRequestConfig} [options]
   * @returns {Promise<T>}
   */
  delete(uri, data, headers, options) {
    return requestPromise({
      method: 'DELETE',
      uri: `${API_URL}${API_BASE}${uri}`,
      data,
      headers,
      ...options,
    });
  },

  async getStoreInfo() {
    try {
      const url = `${API_URL}/info`;
      const response = await axios(url, {
        method: 'GET',
        headers: { Accept: 'application/json' },
      }).then((response) => {
        const { responseURL } = response.request;
        if (
          responseURL !== url &&
          (response.headers['content-type'] || '').includes('text/html')
        ) {
          window.location.href = responseURL;
          return null;
        }

        return response.data;
      });

      return response;
    } catch (err) {
      return null;
    }
  },

  async postStorePassword(password) {
    try {
      const response = await axios(`${API_URL}/password`, {
        method: 'POST',
        data: JSON.stringify({ password }),
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      }).then((response) => response.data);
      return response;
    } catch (err) {
      return null;
    }
  },

  getPurchaseLinkInfo(id, testEnv) {
    return requestPromise({
      method: 'GET',
      uri: `${API_URL}/buy/${id}`,
      headers: {
        // Necessary because this call is made before global TEST_ENV is set
        'Swell-Env': testEnv || undefined,
      },
    });
  },

  fetchCountryByIP() {
    return requestPromise({
      method: 'GET',
      uri: `${API_URL}${API_BASE}/util/ip-info`,
      timeout: 3000,
    })
      .then((result) => {
        if (result && result.country_code) {
          return result.country_code;
        }
        console.warn('Unable to lookup IP info');
        return null;
      })
      .catch((err) => {
        console.error(`IP info lookup error: ${err.message}`);
        return null;
      });
  },

  connectMailchimp(code) {
    return requestPromise({
      method: 'POST',
      uri: `${API_URL}/integration/api/mailchimp/connect`,
      data: { code },
      timeout: 7500,
    }).catch((err) => {
      console.error(`Mailchimp connection error: ${err.message}`);
      return null;
    });
  },

  slackUninstall() {
    return requestPromise({
      method: 'POST',
      uri: `${API_URL}/integration/api/slack/uninstall`,
    });
  },

  getSlackConnectUrl(baseUrl) {
    return requestPromise({
      method: 'POST',
      uri: `${SWELL_API_URL}/integration/api/slack/geturl`,
      data: { baseUrl },
      withCredentials: true,
    });
  },

  connectStripe(publicKey, code, mode) {
    initSchema(publicKey);
    return vaultRequest('post', '/connections', {
      gateway: 'stripe',
      credentials: { code },
      mode,
    });
  },

  refreshStripeConnection(publicKey, mode) {
    initSchema(publicKey);
    return vaultRequest('put', '/connections', {
      gateway: 'stripe',
      mode,
    });
  },

  disconnectStripe(publicKey, mode) {
    initSchema(publicKey);
    return vaultRequest('delete', '/connections', {
      gateway: 'stripe',
      mode,
    });
  },

  createStripeIntent(publicKey, intent) {
    initSchema(publicKey);
    return vaultRequest('post', '/intent', {
      gateway: 'stripe',
      intent,
    });
  },

  connectPaypal(publicKey, credentials, mode) {
    initSchema(publicKey);
    return vaultRequest('post', '/connections', {
      gateway: 'paypal',
      credentials,
      mode,
    });
  },

  getPaypalMerchantOnboardingStatus(publicKey, merchantId) {
    initSchema(publicKey);
    return vaultRequest('put', '/connections', {
      gateway: 'paypal',
      merchantId,
    });
  },

  /**
   * @param {string?} dbName
   * @returns {Promise<Collection>}
   */
  connectConsoleHistory(dbName = LOKI_DB_NAME) {
    if (consoleHistory) {
      return Promise.resolve(consoleHistory);
    }

    return new Promise((resolve) => {
      let adapter = isTest
        ? new loki.LokiMemoryAdapter()
        : new lokiIndexedAdapter();

      const db = new loki(`${dbName}.db`, {
        autoload: true,
        autoloadCallback: function () {
          let history = db.getCollection('history');

          if (!history) {
            history = db.addCollection('history');
          }

          consoleHistory = history;
          resolve(history);
        },
        autosave: true,
        autosaveInterval: 4000,
        adapter: adapter,
      });
    });
  },

  fetchMailchimpLists() {
    return requestPromise({
      method: 'GET',
      uri: `${API_URL}/integration/api/mailchimp/lists`,
      timeout: 7500,
    }).catch((err) => {
      console.error(`Can't get mailchimp lists: ${err.message}`);
      return null;
    });
  },

  deleteWebhooks(integration, options) {
    return requestPromise({
      method: 'POST',
      uri: `${API_URL}/integration/api/${integration}/deactivate`,
      timeout: 7500,
    }).catch((err) => {
      console.error(`Can't delete webhook: ${err.message}`);
      return null;
    });
  },

  validateIntegrationCredentials(integration, data) {
    return requestPromise({
      method: 'POST',
      uri: `${API_URL}/integration/api/${integration}/validate-login`,
      data,
      timeout: 7500,
    }).catch((err) => {
      console.error(`${integration} validation error: ${err.message}`);
      return null;
    });
  },

  requestHubSpotToken(form) {
    return requestPromise({
      method: 'POST',
      uri: `${API_URL}/integration/api/hubspot/getToken`,
      data: form,
    }).catch((err) => {
      console.log({ err });
      console.error(`HubSpot validation error: ${err.message}`);
      return null;
    });
  },

  validateTaxjarApiKey(data) {
    return requestPromise({
      method: 'POST',
      uri: `${API_URL}/integration/api/taxjar/validate-login`,
      data,
      timeout: 7500,
    }).catch((err) => {
      console.error(`Taxjar validation error: ${err.message}`);
      return null;
    });
  },

  validateGladlyApiKey(data) {
    return requestPromise({
      method: 'POST',
      uri: `${API_URL}/integration/api/gladly/validate-login`,
      data,
      timeout: 7500,
    }).catch((err) => {
      console.error(`Gladly validation error: ${err.message}`);
      return null;
    });
  },

  requestSmartyStreetsData(data) {
    return requestPromise({
      method: 'GET',
      uri: `${API_URL}/integration/api/smartystreets/lookup`,
      qs: data,
    }).catch((err) => {
      throw new Error(`SmartyStreets error: ${err.message}`);
    });
  },
};

export default actions;

function requestPromise({ uri, data, qs, ...params }) {
  const { TEST_ENV } = require('../utils');

  const method = params.method?.toUpperCase() ?? 'GET';

  const headers = {
    ...(params.headers || undefined),
    Accept: 'application/json',
    'Swell-Version': SWELL_VERSION,
    ...auth.buildSessionHeaders(),
  };

  if (TEST_ENV) {
    headers['Swell-Env'] = TEST_ENV;
  }

  /** @type {AxiosRequestConfig} */
  const options = {
    ...params,
    headers,
    responseType: 'text',
    validateStatus: (status) => status < 400,
  };

  if (data) {
    options.data = JSON.stringify(data);

    if (method !== 'GET') {
      headers['Content-Type'] = 'application/json';
    }
  }

  const response = axios(`${uri}${stringifyQuery(qs)}`, options)
    .then((resp) => parseBody(resp.data))
    .catch((error) => {
      if (!error.response) {
        throw new Error(error.message);
      }

      const body = parseBody(error.response.data);

      throw new Error(
        getProperty(
          body,
          'error.message',
          getProperty(body, 'error', error.response.data),
        ),
      );
    });

  return response;
}

function parseBody(body) {
  try {
    return JSON.parse(body);
  } catch (err) {
    return body;
  }
}

function initSchema(publicKey) {
  if (!publicKey) {
    throw new Error('Missing `publicKey` in api.tokenizeCard()');
  }
  if (!global.Schema) {
    throw new Error('Unable to load things. Are you offline?');
  }

  global.Schema.setPublicKey(publicKey);

  if (process.env.NODE_ENV === 'development') {
    global.Schema.vaultUrl =
      process.env.VAULT_URL_DEV || global.Schema.vaultUrl;
  }
}

function vaultRequest(method, url, data) {
  return new Promise((resolve) => {
    global.Schema.vault()[method](url, data, (result, headers) => {
      let response = result || {};
      if (headers.$error) {
        response.error = { message: headers.$error };
      } else if (response.errors) {
        const param = Object.keys(result.errors)[0];
        response.error = result.errors[param];
        response.error.param = param;
        headers.$status = 402;
      } else if (result.toObject) {
        response = result.toObject();
      }
      return resolve({ ...response, status: headers.$status });
    });
  });
}

// Tokenize a credit card using vault
export function tokenizeCard(publicKey, params) {
  initSchema(publicKey);
  return new Promise((resolve) => {
    const exp = global.Schema.cardExpiry(params.card.exp);
    global.Schema.createToken(
      {
        number: params.card.number,
        cvc: params.card.cvc,
        exp_month: exp.month,
        exp_year: exp.year,
        billing: params.billing,
        account_id: params.account_id,
      },
      (status, response) => {
        if (response.errors) {
          resolve({ errors: response.errors });
          return;
        }
        if (status >= 300) {
          resolve({ errors: { gateway: response.error || 'Unknown error' } });
          return;
        }
        // Success
        resolve(response);
      },
    );
  });
}
