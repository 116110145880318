import React from 'react';
import { get, find } from 'lodash';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import { formatCurrency, currencyValue, arrayToObject } from 'utils';
import { shouldUseAccountShippingAddress } from 'utils/order';

export default class OrderShippingEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    const serviceOptions = this.getServiceOptions(props);
    this.state = {
      values: props.record,
      serviceOptions,
      ratedServiceOptions: this.getRatedServiceOptions(props, serviceOptions),
    };
    this.onChangeForm = this.onChangeForm.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.record !== this.props.record) {
      this.setState({
        ratedServiceOptions: this.getRatedServiceOptions(
          nextProps,
          this.state.serviceOptions,
        ),
      });
    }
  }

  onChangeForm(values) {
    this.setState({ values: { ...this.state.values, ...values } });
  }

  getServiceOptions(props) {
    const services = props.settings.shipments.services || [];
    const carriers = arrayToObject(props.settings.shipments.carriers || []);
    return services
      .filter((service) => {
        const carrier = (service.carrier && carriers[service.carrier]) || {};
        return service.enabled && (!service.carrier || carrier.enabled);
      })
      .map((service) => ({
        value: service.id,
        label: service.name,
      }));
  }

  getRatedServiceOptions(props, serviceOptions = []) {
    const rating = props.record.shipment_rating || {};
    const ratedServices = rating.services || [];
    const services = get(props, 'settings.shipments.services', []);
    const ratedServiceOptions = ratedServices.map((service) => ({
      value: service.id,
      label: `${service.name} - ${formatCurrency(
        service.price,
        props.record.currency,
      )}`,
    }));
    return serviceOptions.map((option) => {
      const ratedOption = find(ratedServiceOptions, { value: option.value });
      if (ratedOption) {
        option.label = ratedOption.label;
      } else {
        const service = find(services, { id: option.value });
        option.label = service ? service.name : option.label;
      }
      return option;
    });
  }

  getSelectedServicePrice(shippingType) {
    const {
      record: { shipment_rating = {} },
    } = this.props;
    const { values } = this.state;

    let servicePrice;
    if (
      shippingType === 'rated' &&
      shipment_rating &&
      shipment_rating.services
    ) {
      const service = find(shipment_rating.services, {
        id: values.shipping && values.shipping.service,
      });
      if (service) {
        servicePrice = service.price;
      }
    }

    return servicePrice;
  }

  getDefaultShippingType() {
    const {
      record: { shipping = {} },
    } = this.props;
    const { values, serviceOptions, ratedServiceOptions } = this.state;

    if (!values.type && shipping.service) {
      const service = find(serviceOptions, { value: shipping.service });
      if (!service) {
        const ratedService = find(ratedServiceOptions, {
          value: shipping.service,
        });
        if (!ratedService) {
          return 'custom';
        }
      }
    }

    return 'rated';
  }

  render() {
    const {
      record: { currency, shipping = {}, shipment_rating, account },
      loading,
      onSubmitShippingEdit,
      onClickShippingEdit,
      onClickShippingGetRates,
      isSubscription,
    } = this.props;

    const { values } = this.state;

    const shippingType = values.type || this.getDefaultShippingType();
    const shipmentRating = shipment_rating || {};
    const servicePrice = this.getSelectedServicePrice(shippingType);
    const isSubscriptionWithUseAccountAddress = isSubscription && shouldUseAccountShippingAddress(shipping);
    const hasCountry = isSubscriptionWithUseAccountAddress ? Boolean(account?.shipping?.country) : Boolean(shipping.country);
    const canGetRates = shippingType === 'rated' && hasCountry;

    return (
      <Form
        onSubmit={onSubmitShippingEdit}
        onChange={this.onChangeForm}
        autoFocus={true}
      >
        <Modal
          title="Edit shipping"
          className="order-view-modal-edit-shipping"
          actions={[
            { label: 'Save', type: 'submit' },
            {
              label:
                shipment_rating && canGetRates ? 'Refresh rates' : 'Get rates',
              type: shipmentRating.services ? 'secondary' : 'default',
              ...(canGetRates
                ? {
                    onClick: onClickShippingGetRates,
                  }
                : {
                    disabled: true,
                    tooltip:
                      'Shipping information must be present to get shipping rates',
                    type: 'secondary',
                  }),
            },
            { label: 'Cancel', type: 'cancel', onClick: onClickShippingEdit },
          ]}
          width={500}
          cancel={false}
          loading={loading}
          onClose={onClickShippingEdit}
        >
          <fieldset>
            <Field
              type="radio"
              name="type"
              buttons={true}
              options={[
                { value: 'rated', label: 'Rated service' },
                { value: 'custom', label: 'Custom' },
              ]}
              defaultValue={shippingType}
              readonly={true}
            />
            {shippingType === 'rated' &&
              (shipmentRating.services ? (
                <Field
                  type="select"
                  name="shipping.service"
                  label="Shipping service"
                  options={this.state.ratedServiceOptions}
                  defaultValue={shipping.service}
                  placeholder="Choose one"
                  required={true}
                />
              ) : (
                <Field
                  type="select"
                  name="shipping.service"
                  label="Shipping service"
                  options={this.state.serviceOptions}
                  defaultValue={shipping.service}
                  placeholder="Choose one"
                  required={true}
                />
              ))}
            {shippingType === 'custom' && (
              <Field
                type="text"
                name="shipping.service"
                label="Custom service name"
                defaultValue={shipping.service_name}
                required={true}
              />
            )}
            <div className="row">
              <Field
                type="currency"
                name="shipping.price"
                label="Price"
                placeholder={currencyValue(
                  servicePrice || shipping.price || '0',
                  currency,
                )}
                defaultValue={servicePrice || shipping.price}
                currency={currency}
                className="span2"
              />
            </div>
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
