import React from 'react';
import Icon from 'components/icon';
import { get, reduce, find, isEmpty } from 'lodash';
import User from '../utils/user';
import Date from '../utils/date';
import Return from '../utils/return';

export default function ReturnCreated({ event, record }) {
  const returnItems = get(event, 'data.items');
  const items = reduce(
    returnItems,
    (acc, returnItem) => {
      if (returnItem) {
        let item = find(record.items, { id: returnItem.order_item_id });

        if (item && returnItem.bundle_item_id) {
          item = find(item.bundle_items, { id: returnItem.bundle_item_id });
        }
        if (item) {
          acc.push({ ...item, quantity: returnItem.quantity });
        }
      }
      return acc;
    },
    [],
  );

  if (isEmpty(items)) {
    return null;
  }

  return (
    <div key={event.id} className="activity-event">
      <div className="activity-event-icon">
        <Icon type="events/return-created" />
      </div>
      <div className="activity-event-info">
        <div className="activity-event-title">
          {items.length > 1 ? 'Items' : 'Item'} returned
          <User user={event.user} />
          &nbsp;
          <Date date={event.date_created} />
        </div>
        <Return event={event} items={items} />
      </div>
    </div>
  );
}
