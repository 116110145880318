import React from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import get from 'lodash/get';

import { b64toBlob } from 'utils';

import { Field, Button } from 'components/form';
import Modal from 'components/modal';
import Tooltip from 'components/tooltip';

import 'cropperjs/dist/cropper.css';

const CROPPER_WIDTH = 600;
const CROPPER_HEIGHT = 600;
const ZOOM_RATIO = 0.1;
const ROTATE_DEGREE = 90;

const ASPECT_RATIO = Object.freeze({
  free: '',
  square: 1,
  sixteen_nine: 16 / 9,
  four_three: 4 / 3,
});

export default class ImageCropper extends React.Component {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
  };

  state = {
    width: null,
    height: null,
    naturalWidth: null,
    naturalHeight: null,
    ready: false,
    edited: false,
    caption: '',
  };

  cropper = null;
  mounted = false;

  componentDidMount() {
    this.mounted = true;

    setTimeout(() => {
      if (this.mounted) {
        this.setState({ ready: true });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  isCropperReady() {
    return get(this.cropper, 'cropper.ready');
  }

  cropperAction(action, ...args) {
    if (this.isCropperReady()) {
      return this.cropper[action](...args);
    }
  }

  initImageValues() {
    const { naturalWidth, naturalHeight } =
      this.cropperAction('getImageData') || {};
    this.setState({
      width: naturalWidth,
      height: naturalHeight,
      naturalWidth,
      naturalHeight,
    });
  }

  onCropperReady = () => {
    this.initImageValues();
  };

  setEdited(edited) {
    if (this.state.ready) {
      this.setState({ edited });
    }
  }

  onCropperChange = () => {
    this.setEdited(true);
  };

  onChangeCaption = (_event, value) => {
    this.setState({ caption: value });
    this.setEdited(true);
  };

  onChangeAspectRatio = (event, value) => {
    event.preventDefault();
    this.cropperAction('setAspectRatio', value);
    this.setEdited(true);
  };

  onClickZoomIn = (event) => {
    event.preventDefault();
    this.cropperAction('zoom', ZOOM_RATIO);
    this.setEdited(true);
  };

  onClickZoomOut = (event) => {
    event.preventDefault();
    this.cropperAction('zoom', -ZOOM_RATIO);
    this.setEdited(true);
  };

  onClickRotateRight = (event) => {
    event.preventDefault();
    this.cropperAction('rotate', ROTATE_DEGREE);
    this.setEdited(true);
  };

  onClickRotateLeft = (event) => {
    event.preventDefault();
    this.cropperAction('rotate', -ROTATE_DEGREE);
    this.setEdited(true);
  };

  onClickFlipHorizontal = (event) => {
    event.preventDefault();
    const { scaleX = 1, scaleY = 1 } = this.cropperAction('getImageData');
    this.cropperAction('scale', -scaleX, scaleY);
    this.setEdited(true);
  };

  onClickFlipVertical = (event) => {
    event.preventDefault();
    const { scaleX = 1, scaleY = 1 } = this.cropperAction('getImageData');
    this.cropperAction('scale', scaleX, -scaleY);
    this.setEdited(true);
  };

  onChangeWidth = (event, value) => {
    event.preventDefault();
    const imageData = this.cropperAction('getImageData');
    if (imageData) {
      const { naturalWidth } = this.state;
      const { scaleX = 1 } = imageData;
      const newScaleX = value / naturalWidth;
      this.cropperAction('scaleX', scaleX >= 0 ? newScaleX : -newScaleX);
      this.setState({ width: Number(value) });
      this.setEdited(true);
    }
  };

  onChangeHeight = (event, value) => {
    event.preventDefault();
    const imageData = this.cropperAction('getImageData');
    if (imageData) {
      const { naturalHeight } = this.state;
      const { scaleY = 1 } = imageData;
      const newScaleY = value / naturalHeight;
      this.cropperAction('scaleY', scaleY >= 0 ? newScaleY : -newScaleY);
      this.setState({ height: Number(value) });
      this.setEdited(true);
    }
  };

  onClickReset = (event) => {
    event.preventDefault();
    this.context.openModal('Confirm', {
      title: `Reset image`,
      message: <p>Reset this image back to its original state?</p>,
      action: 'Reset',
      actionType: 'danger',
      onConfirm: () => {
        this.cropperAction('reset');
        this.initImageValues();
        this.setEdited(false);
      },
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { image, onSubmit, onClose } = this.props;
    if (!this.state.edited) {
      onClose();
      return;
    }
    const filename = get(image, 'file.filename');
    const contentType = get(image, 'file.content_type', 'image/jpeg');
    const canvas = this.cropperAction('getCroppedCanvas');
    const b64 = canvas.toDataURL(contentType).split(',')[1];
    const blob = b64toBlob(b64, contentType);
    const file = new File([blob], filename, { type: contentType });
    onSubmit(file, { caption: this.state.caption });
  };

  onClose = () => {
    const { onClose } = this.props;
    if (this.state.edited) {
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          onClose();
        },
      });
    } else {
      onClose();
    }
  };

  render() {
    const { image } = this.props;
    const { width, height, naturalWidth, naturalHeight, edited } = this.state;

    return (
      <Modal
        className="image-cropper-modal"
        onClose={this.onClose}
        chromeless={true}
        cancel={false}
        minWidth={1000}
      >
        <div className="image-cropper">
          <Cropper
            ref={(cropper) => {
              this.cropper = cropper;
            }}
            src={image.file.url}
            style={{
              height: `${CROPPER_HEIGHT}px`,
              width: `${CROPPER_WIDTH}px`,
            }}
            autoCropArea={1}
            dragMode="move"
            ready={this.onCropperReady}
            crop={this.onCropperChange}
            zoom={this.onCropperChange}
          />

          <div className="image-cropper-controls">
            <div className="control-container">
              <Field
                type="text"
                label="Caption"
                placeholder="A bag of coffee resting on a table"
                hint="A caption can be used as alt text"
                scale={0}
                defaultValue={image.caption || ''}
                onChange={this.onChangeCaption}
              />

              <Field
                type="radio"
                label="Aspect ratio"
                buttons={true}
                options={[
                  { value: ASPECT_RATIO.free, label: 'Free' },
                  { value: ASPECT_RATIO.sixteen_nine, label: '16:9' },
                  { value: ASPECT_RATIO.four_three, label: '4:3' },
                  { value: ASPECT_RATIO.square, label: '1:1' },
                ]}
                defaultValue={ASPECT_RATIO.free}
                onChange={this.onChangeAspectRatio}
              />

              <div className="control-group">
                <label>Zoom</label>

                <span className="button-group">
                  <Button type="secondary" onClick={this.onClickZoomIn}>
                    <Tooltip message="Zoom in" delayed>
                      <i className="fas fa-plus-circle" />
                    </Tooltip>
                  </Button>

                  <Button type="secondary" onClick={this.onClickZoomOut}>
                    <Tooltip message="Zoom out" delayed>
                      <i className="fas fa-minus-circle" />
                    </Tooltip>
                  </Button>
                </span>
              </div>

              <div className="control-group">
                <label>Rotate</label>

                <span className="button-group">
                  <Button type="secondary" onClick={this.onClickRotateLeft}>
                    <Tooltip message="Rotate left" delayed>
                      <i className="fas fa-undo" />
                    </Tooltip>
                  </Button>

                  <Button type="secondary" onClick={this.onClickRotateRight}>
                    <Tooltip message="Rotate right" delayed>
                      <i className="fas fa-redo" />
                    </Tooltip>
                  </Button>
                </span>
              </div>

              <div className="control-group">
                <label>Flip</label>

                <span className="button-group">
                  <Button type="secondary" onClick={this.onClickFlipHorizontal}>
                    <Tooltip message="Flip horizontal" delayed>
                      <i className="fas fa-arrows-alt-h" />
                    </Tooltip>
                  </Button>

                  <Button type="secondary" onClick={this.onClickFlipVertical}>
                    <Tooltip message="Flip vertical" delayed>
                      <i className="fas fa-arrows-alt-v" />
                    </Tooltip>
                  </Button>
                </span>
              </div>

              <div className="control-group">
                <label>Size</label>

                <div className="row">
                  <Field
                    type="number"
                    label="W"
                    scale={0}
                    value={width}
                    defaultValue={naturalWidth}
                    onChange={this.onChangeWidth}
                  />

                  <Field
                    type="number"
                    label="H"
                    scale={0}
                    value={height}
                    defaultValue={naturalHeight}
                    onChange={this.onChangeHeight}
                  />
                </div>
              </div>
            </div>

            <div className="image-cropper-footer">
              <div className="row">
                <Button
                  type="secondary"
                  onClick={this.onClickReset}
                  disabled={!edited}
                  className="button button-secondary button-md"
                >
                  Reset
                </Button>

                <Button
                  className="button button-default button-md"
                  onClick={this.onSubmit}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
