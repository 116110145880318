import React, { Fragment } from 'react';
import classNames from 'classnames';
import pt from 'prop-types';

import { nl2br, formatCurrency, LOCALE_CODES } from 'utils';
import { isSameAddress, shouldUseAccountBillingAddress, shouldUseAccountShippingAddress } from 'utils/order';
import { accountName } from 'utils/account';

import Status from 'components/status/status';
import Link from 'components/link';
import CustomerAvatar from 'components/customer/avatar';
import LocaleIcon from 'components/locale/icon';

import PaymentMethod from './payment-method';
import OrderAddress from './address';

export default class OrderCustomer extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    isSubscription: pt.bool,
    content: pt.object,
    settings: pt.object,
    addable: pt.bool,
    editable: pt.bool,
    removable: pt.bool,
    emptyLabel: pt.string,

    onClickEditCustomer: pt.func,
    onClickRemoveCustomer: pt.func,
    onClickPaymentEdit: pt.func,
    onClickManageAddresses: pt.func,
  };

  render() {
    const {
      record,
      isSubscription,
      content,
      settings,
      removable = false,
      addable = true,
      editable = true,
      emptyLabel,
      onClickEditCustomer,
      onClickRemoveCustomer,
      onClickPaymentEdit,
      onClickManageAddresses,
    } = this.props;

    const { account, shipping, billing, currency } = record;
    const shippingAddress = isSubscription && shouldUseAccountShippingAddress(shipping)
      ? account?.shipping
      : shipping;
    const hasShippingAddress = Boolean(shippingAddress?.address1);
    const billingData = shouldUseAccountBillingAddress(billing) ? account?.billing : billing;
    const canEditShipping = editable && Boolean(onClickManageAddresses);
    const canEditBilling = editable && Boolean(onClickPaymentEdit);

    return (
      <div
        className={classNames('order-customer', 'view-side-container', {
          empty: !account,
        })}
      >
        {account ? (
          <section
            className={classNames('view-side-section', {
              removable: Boolean(removable),
            })}
          >
            <div className="view-side-section-header">
              <h3>Customer</h3>
              <div className="view-side-section-actions">
                {editable && onClickEditCustomer && (
                  <button
                    className="as-link"
                    onClick={onClickEditCustomer}
                    type="button"
                  >
                    Edit
                  </button>
                )}
                {removable && onClickRemoveCustomer && (
                  <button
                    className="as-link"
                    onClick={onClickRemoveCustomer}
                    type="button"
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
            <div className="view-side-customer-avatar">
              <Link to={`/customers/${account.id}`}>
                <CustomerAvatar account={account} size={50} />
              </Link>
            </div>
            <h4 className="view-side-customer-name">
              <Link to={`/customers/${account.id}`}>
                {accountName(account)}
              </Link>
              {account.locale && LOCALE_CODES.length > 0 && (
                <span className="order-customer-locale">
                  <LocaleIcon locale={account.locale} />
                </span>
              )}
            </h4>
            <div className="view-side-customer-details">{account.email}</div>
            <div className="view-side-customer-details muted">
              {`${formatCurrency(account.order_value, currency)} spent \u2022 ${
                account.order_count
              } orders`}
            </div>
            {(account.order_count >= 5 && (
              <Status dot={false} radius="rounded-4xl" type="positive">
                Frequent buyer
              </Status>
            )) ||
              account.order_count === 0 ||
              (account.order_count === 1 && (
                <Status dot={false} radius="rounded-4xl" type="positive">
                  First order
                </Status>
              ))}
          </section>
        ) : addable !== false ? (
          <button
            className="as-link order-empty-customer"
            onClick={onClickEditCustomer}
            type="button"
          >
            {emptyLabel || 'Add customer'}
          </button>
        ) : (
          <div className="order-empty-customer" />
        )}

        {account && (
          <Fragment>
            {shipping && (
              <section className="view-side-section">
                <div className="view-side-section-header">
                  <h4>Shipping</h4>
                  {canEditShipping && (
                    <button
                      className="as-link"
                      onClick={onClickManageAddresses}
                      type="button"
                    >
                      {hasShippingAddress ? 'Edit' : 'Add'}
                    </button>
                  )}
                </div>
                <div className="view-side-body">
                  <OrderAddress
                    address={shippingAddress}
                    record={account.shipping}
                    content={content}
                    collection="accounts"
                    zone="shipping"
                  />
                </div>
              </section>
            )}
            <section className="view-side-section">
              <div className="view-side-section-header">
                <h4>Billing</h4>
                {canEditBilling && (
                  <button
                    className="as-link"
                    onClick={onClickPaymentEdit}
                    type="button"
                  >
                    Edit
                  </button>
                )}
              </div>
              <div className="view-side-body">
                <PaymentMethod
                  account={account}
                  billing={billingData}
                  settings={settings}
                />
                {hasShippingAddress &&
                isSameAddress(billingData, shippingAddress) ? (
                  <Fragment>Same as shipping</Fragment>
                ) : (
                  <OrderAddress
                    address={billingData}
                    record={account.billing}
                    content={content}
                    collection="accounts"
                    zone="billing"
                  />
                )}
              </div>
            </section>
            <section className="view-side-section">
              <div className="view-side-section-header">
                <h4>Account credit</h4>
              </div>
              <div className="view-side-body">
                {formatCurrency(account.balance, currency)}
              </div>
            </section>
            {Boolean(account.notes) && (
              <section className="view-side-section">
                <div className="view-side-section-header">
                  <h4>Customer notes</h4>
                </div>
                <div className="view-side-body">{nl2br(account.notes)}</div>
              </section>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}
