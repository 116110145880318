// @flow
import { isArray, flatten } from 'lodash';
import api from '../services/api';
import { buildAddress } from 'utils/account';

export const LOOKUP_CLEAR = 'lookup/clear';
export const LOOKUP_QUERY = 'lookup/query';
export const LOOKUP_MULTIMODEL_QUERY = 'lookup/multimodel';
export const LOOKUP_LOADING = 'lookup/loading';
export const LOOKUP_ADDRESS = 'lookup/address';

export default {
  clear() {
    return { type: LOOKUP_CLEAR };
  },

  loading(value = true) {
    return { type: LOOKUP_LOADING, payload: value };
  },

  query(model: string, query: Object) {
    return {
      type: LOOKUP_QUERY,
      payload: api.get(`/data/${model}`, query),
      meta: { model, query },
    };
  },

  multiModelQuery(models: Array, queries: Object) {
    return {
      type: LOOKUP_MULTIMODEL_QUERY,
      payload: Promise.all(
        models.map((model) =>
          api
            .get(`/data/${model}`, queries[model])
            .then((results) => ({ ...results, model: model })),
        ),
      ),
      meta: { models, queries },
    };
  },

  results(results, count = undefined) {
    return {
      type: LOOKUP_QUERY,
      payload: { results, count: count || results.length },
      meta: { model: null, query: null },
    };
  },

  fetchAddress(data) {
    return async (dispatch) => {
      return dispatch({
        type: LOOKUP_ADDRESS,
        payload: api.requestSmartyStreetsData({
          prefer_states: data.state,
          search: data.search,
        }),
      });
    };
  },
};

export const initialState = {
  model: null,
  query: null,
  results: [],
  suggestedAddresses: [],
  count: 0,
  loading: false,
};

export function reducer(state: Object = initialState, action: Object) {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case LOOKUP_CLEAR:
      return { ...initialState };

    case LOOKUP_QUERY:
      if (action.payload.error) {
        return state;
      }
      return {
        ...state,
        ...action.meta,
        results: action.payload.results,
        count: action.payload.count,
      };

    case LOOKUP_MULTIMODEL_QUERY:
      if (action.payload.error) {
        return state;
      }
      const results = flatten(
        action.payload.map((result) => {
          if (isArray(result.results)) {
            // add "_model" field (stores item origin model name for later use)
            return result.results.map((item) => ({
              ...item,
              _model: result.model,
            }));
          }
          return [];
        }),
      );
      return {
        ...state,
        ...action.meta,
        results,
        count: results.length,
      };

    case LOOKUP_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case LOOKUP_ADDRESS: {
      const { suggestions } = action.payload;

      return {
        ...state,
        suggestedAddresses: suggestions
          ? suggestions.map((address, index) => {
              return {
                value: address.street_line,
                label: buildAddress(address),
                key: `${address.street_line}-${index}`,
                suggestedAddress: address,
                index,
              };
            })
          : [],
      };
    }

    default:
      return state;
  }
}
