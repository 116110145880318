import { connect } from 'react-redux';

import actions from 'actions';

import ReportsCollection from './ReportsCollection';

const mapStateToProps = (state) => ({
  collection: state.data.collection,
  selection: state.data.selection,
  locationQuery: state.data.locationQuery,
  bulk: state.data.bulk,
  loading: state.data.loading,
});

const mapDispatchToProps = (dispatch, { model, query, tabs }) => ({
  fetchCollection(location, reportId, props = {}) {
    let where = { $and: [] };

    if (tabs && tabs[location.query.tab]) {
      where.$and.push(tabs[location.query.tab].query);
    }

    const dataQuery = {
      ...(props.query || query),
      page: location.query.page,
      reportId: reportId,
    };

    return dispatch(actions.reports.fetchCollection(model, dataQuery));
  },

  fetchGraph(chartType, startDate, endDate, period) {
    return dispatch(
      actions.reports.fetchGraph(model, {
        startDate,
        endDate,
        period,
        chartType,
      }),
    );
  },

  createCustom: (params) => dispatch(actions.reports.createCustom(params)),
  updateCustom: (params) => dispatch(actions.reports.updateCustom(params)),
  deleteCustom: (params) => dispatch(actions.reports.deleteCustom(params)),

  bulkExport(params) {
    return dispatch(actions.data.bulkExport(`reports/${model}`, params));
  },

  bulkCancel() {
    return dispatch(actions.data.bulkCancel());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsCollection);
