import React from 'react';
import { Root, Trigger, Portal, Content, Arrow } from '@radix-ui/react-popover';
import PropTypes from 'prop-types';

import { contentFieldSourceLabel } from 'utils/content';

import Button from 'components/button';
import AppIcon from 'components/apps/icon';
import { separateLongMessage } from '../../utils/string';

import './popover.scss';

function TriggerIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1547_6725)">
        <path
          d="M6.05967 6.00016C6.21641 5.55461 6.52578 5.1789 6.93298 4.93958C7.34018 4.70027 7.81894 4.61279 8.28446 4.69264C8.74998 4.77249 9.17222 5.01451 9.47639 5.37585C9.78057 5.73718 9.94705 6.19451 9.94634 6.66683C9.94634 8.00016 7.94634 8.66683 7.94634 8.66683M7.99967 11.3335H8.00634M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const DEFAULT_DELAY_DURATION = 150;
const DEFAULT_TRIGGER_CLASSNAME = 'popover-trigger';
const DEFAULT_CONTENT_CLASSNAME = 'popover-content';
const DEFAULT_ARROW_CLASSNAME = 'popover-arrow';

export default class Popover extends React.PureComponent {
  static propTypes = {
    rootRest: PropTypes.object,
    triggerChildren: PropTypes.any,
    triggerClassName: PropTypes.string,
    triggerRest: PropTypes.object,
    triggerOnClick: PropTypes.func,
    contentClassName: PropTypes.string,
    contentRest: PropTypes.object,
    side: PropTypes.string,
    sideOffset: PropTypes.number,
    align: PropTypes.string,
    arrowPadding: PropTypes.number,
    arrowRest: PropTypes.object,
    // TODO:  it would be better if popoever didn’t have any field-specific logic (didn’t take in a complex message object)
    message: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
    children: PropTypes.any,
    openOnHover: PropTypes.bool,
    delayDuration: PropTypes.number,
    location: PropTypes.object,
    maxTextLineWidth: PropTypes.number, // ability to reduce the maximum text width and display text in multiple lines
    maxTextLineSeparator: PropTypes.string, // separator to split text into multiple lines
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false,
      contentFocused: false,
    };

    this.sourceByPath = new Map();
    this.toggleTimer = 0;
  }

  componentWillUnmount() {
    this.clearToggleTimer();
  }

  clearToggleTimer() {
    if (this.toggleTimer) {
      clearTimeout(this.toggleTimer);
    }
  }

  onMouseEnter = () => {
    const { openOnHover } = this.props;

    if (!openOnHover) {
      return null;
    }

    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  onMouseLeave = () => {
    const { openOnHover, delayDuration = DEFAULT_DELAY_DURATION } = this.props;
    const { contentFocused } = this.state;

    if (!openOnHover || contentFocused) {
      return null;
    }

    this.clearToggleTimer();

    this.toggleTimer = setTimeout(() => {
      this.toggleTimer = 0;

      if (!this.state.contentFocused) {
        this.setState(() => ({
          open: false,
          contentFocused: false,
        }));
      }
    }, delayDuration / 2);
  };

  onMouseClickContent = (event) => {
    event.stopPropagation();
  };

  onMouseEnterContent = () => {
    this.setState((prevState) => ({
      contentFocused: !prevState.contentFocused,
    }));
  };

  onMouseLeaveContent = () => {
    const { openOnHover, delayDuration = DEFAULT_DELAY_DURATION } = this.props;

    if (!openOnHover) {
      return null;
    }

    this.clearToggleTimer();

    this.toggleTimer = setTimeout(() => {
      this.toggleTimer = 0;

      this.setState((prevState) => ({
        open: !prevState.open,
        contentFocused: !prevState.contentFocused,
      }));
    }, delayDuration);
  };

  onClick = (event) => {
    event.stopPropagation();
    const { openOnHover, delayDuration = DEFAULT_DELAY_DURATION } = this.props;

    if (openOnHover) {
      event.preventDefault();
      return null;
    }

    this.clearToggleTimer();

    this.toggleTimer = setTimeout(() => {
      this.toggleTimer = 0;

      this.setState((prevState) => ({
        open: !prevState.open,
      }));
    }, delayDuration);
  };

  renderFieldSource(field) {
    let source = field.source_type || 'custom';

    if (field.content_id) {
      const path = field.path ? `${field.path}.${field.id}` : field.id;

      source = this.sourceByPath.get(path);

      if (source === undefined) {
        source = contentFieldSourceLabel(field, this.context.client.apps);
        this.sourceByPath.set(path, source);
      }
    }

    return typeof source === 'string' ? source.toLowerCase() : source;
  }

  onActionClick(source, field) {
    const { router } = this.context;
    const { location } = this.props;

    // ignore Edit Fields from preview
    if (field.admin_zone === 'preview') {
      return;
    }

    const appId = field.app?.id;
    if (appId) {
      const isTestEnv = location?.pathname.split('/')[2] === 'test';

      return router.replace(`/admin/${isTestEnv ? 'test/' : ''}apps/${appId}`);
    }

    // open corresponding collection and activate field editing
    const collection = `com.${encodeURIComponent(
      field.fieldCollection.replace(/\//g, '_'),
    )}`;

    this.context.openModal('EditModel', {
      params: { id: collection, editedFieldId: field.id },
    });

    this.setState({
      open: false,
    });
  }

  renderSourceAction(source, field) {
    const actionLabel =
      source === 'standard' || source === 'storefront'
        ? 'View model'
        : source === 'custom'
        ? 'Edit field'
        : 'Manage';

    if (field.appSettingsField) {
      return;
    }

    return (
      <div>
        <Button
          type="secondary"
          size="sm"
          onClick={() => this.onActionClick(source, field)}
        >
          {actionLabel}
        </Button>
      </div>
    );
  }

  renderContent() {
    const {
      message,
      message: { field },
      children,
      maxTextLineWidth,
      maxTextLineSeparator,
    } = this.props;

    if (message?.length > 0) {
      if (maxTextLineWidth > 0 && message.length >= maxTextLineWidth) {
        return separateLongMessage(
          message,
          maxTextLineWidth,
          maxTextLineSeparator,
        );
      }

      return message;
    }

    if (!field?.id) {
      // allow to render React elements as tooltip
      if (message?.type) {
        return message;
      }

      return null;
    }

    if (field) {
      return this.renderFieldContent(field);
    }

    return children;
  }

  renderFieldContent = (field) => {
    const {
      user: { devtools },
    } = this.context;

    const source = this.renderFieldSource(field);

    return (
      <>
        {devtools && this.renderContentTop(field)}
        {this.renderContentMiddle()}
        {this.renderContentBottom(field, source, devtools)}
      </>
    );
  };

  renderContentTop = (field) => {
    const { name, type } = field;

    const source = this.renderFieldSource(field);
    const idDisplay = name?.length > 30 ? <span>{name}</span> : name;
    const normalizeSource =
      typeof source === 'object' ? 'custom' : source.toLowerCase();

    if (idDisplay === null || idDisplay === undefined) {
      return null;
    }

    return (
      <div className={`popover-content-top ${normalizeSource}`}>
        <div>
          <code>{idDisplay}</code>
          <div>{type.replace(/_/g, ' ')}</div>
        </div>
      </div>
    );
  };

  renderContentMiddle = () => {
    const { message } = this.props;
    return (
      message.message && (
        <span className="popover-content-middle">{message.message}</span>
      )
    );
  };

  renderContentBottom = (field, source, devtools) => {
    let appSource = null;

    if (field.app) {
      const { name, logo_icon } = field.app;
      appSource = (
        <div className="app-source">
          <div className="icon-app">
            <AppIcon image={logo_icon} name={name} size={20} />
          </div>
          <span className="muted">{name}</span>
        </div>
      );
    }

    if (appSource || devtools) {
      return (
        <div className="popover-content-bottom">
          {/* Source: https://gitlab.com/schema/swell-admin/-/blob/4c80b272d3f45ea81c1415ddd00b264a1839cac5/client/src/components/model/form.js#L373 */}
          <div className="content-source">{appSource || source}</div>
          {this.renderSourceAction(source, field)}
        </div>
      );
    }
  };

  // Check to ensure that tooltips are only shown if the message exists or devtools are enabled
  shouldRenderContent = () => {
    const { message } = this.props;

    const {
      user: { devtools },
    } = this.context;

    if (devtools) {
      return true;
    }

    /*
      message?.message?.length
      This check is necessary because sometimes we return a message as an object,
      this object is created in the getHelpTip() function
      and this function may return this object:

      {
        message: string | null;
        field: object;
        view: object;
        app: object;
      };
    */

    return typeof message === 'string' || message?.message?.length;
  };

  render() {
    const {
      rootRest,
      triggerChildren,
      triggerClassName,
      triggerRest,
      triggerOnClick,
      contentClassName,
      contentRest,
      side = 'top',
      sideOffset = 4,
      align = 'start',
      arrowPadding = 24,
      arrowRest,
    } = this.props;

    const { open } = this.state;

    if (!this.shouldRenderContent()) {
      return null;
    }

    return (
      <Root open={open} {...rootRest}>
        <Trigger asChild>
          <button
            type="button"
            className={
              triggerClassName ? triggerClassName : DEFAULT_TRIGGER_CLASSNAME
            }
            onClick={triggerOnClick ? triggerOnClick : this.onClick}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            {...triggerRest}
          >
            {triggerChildren ? triggerChildren : <TriggerIcon />}
          </button>
        </Trigger>
        <Portal>
          <Content
            side={side}
            sideOffset={sideOffset}
            align={align}
            arrowPadding={arrowPadding}
            className={
              contentClassName ? contentClassName : DEFAULT_CONTENT_CLASSNAME
            }
            avoidCollisions={false}
            ref={this.contentRef}
            onClick={this.onMouseClickContent}
            onMouseEnter={this.onMouseEnterContent}
            onMouseLeave={this.onMouseLeaveContent}
            {...contentRest}
          >
            {this.renderContent()}
            <Arrow className={DEFAULT_ARROW_CLASSNAME} {...arrowRest} />
          </Content>
        </Portal>
      </Root>
    );
  }
}
