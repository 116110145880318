import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

const easeOutBack = 'cubic-bezier(0.175, 0.885, 0.32, 1.275)';
const easeInBack = 'cubic-bezier(0.6, -0.28, 0.735, 0.045)';

export default ({
  children,
  initDisplayStyle = 'block',
  duration = 250,
  ...props
}) => {
  const transitionStyles = {
    defaultStyle: {
      opacity: 0,
      transform: 'translate(0, 20px)',
      display: 'none',
    },
    enterInitStyle: {
      opacity: 0,
      display: initDisplayStyle,
      transform: 'translate(0, 20px)',
    },
    enterStyle: {
      opacity: transit(1, duration, 'linear'),
      transform: transit('translate(0, 0)', duration, easeOutBack),
    },
    leaveStyle: {
      opacity: transit(0, duration, 'linear'),
      transform: transit('translate(0, 20px)', duration, easeInBack),
    },
    activeStyle: {
      //opacity: 1,
      //transform: 'translate(0, 0)',
    },
    active: true,
    transitionAppear: true,
  };
  return (
    <CSSTransition {...transitionStyles} {...props}>
      {children}
    </CSSTransition>
  );
};
