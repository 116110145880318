import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

// const easeOutBack = 'cubic-bezier(0.175, 0.885, 0.32, 1.275)';
// const easeInBack = 'cubic-bezier(0.6, -0.28, 0.735, 0.045)';
const easeOutQuart = 'cubic-bezier(0.165, 0.84, 0.44, 1)';

export default function MoveDownBounce({ duration = 300, distance = 65, children, ...props }) {
  const transitionStyles = {
    defaultStyle: {
      display: 'none',
      transform: 'translate(0, 0)',
    },
    enterInitStyle: {
      display: 'block',
      transform: 'translate(0, 0)',
    },
    enterStyle: {
      transform: transit(`translate(0, ${distance}px)`, duration, easeOutQuart),
    },
    leaveStyle: {
      transform: transit('translate(0, 0)', duration, easeOutQuart),
    },
    activeStyle: {
      transform: `translate(0, ${distance}px)`,
    },
    transitionAppear: true,
  };
  return (
    <CSSTransition {...transitionStyles} {...props}>
      {children}
    </CSSTransition>
  );
}
