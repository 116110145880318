import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import Modal from 'components/modal';
import { Form, Field } from 'components/form';
import OrderItemDetail from './item-detail';
import { formatCurrency, inflect } from 'utils';
import { productThumbUrl, productName } from 'utils/product';

export default class OrderItemsCancel extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
  };

  state = {};

  constructor(props) {
    super(props);
    const { cancelableItems } = props;
    this.state = {
      edited: false,
      values: {
        items: [...cancelableItems],
      },
    };
  }

  onConfirmCancel(values) {
    const { cancelableItems, onCloseCancelItems } = this.props;
    const canceledItems = values.items.filter(
      (item) => item.cancel_quantity > 0,
    );
    if (!canceledItems.length) {
      onCloseCancelItems();
      return;
    }
    this.context.openModal('Confirm', {
      message: (
        <Fragment>
          <p>
            Are you sure you want to cancel{' '}
            {inflect(canceledItems.length, 'item')}?
          </p>
          <ul>
            {values.items.map((item, index) => {
              const cancelItem = cancelableItems[index];
              return (
                item.cancel_quantity > 0 && (
                  <li key={index}>
                    {productName(cancelItem.product, cancelItem.variant)}{' '}
                    &times; {item.cancel_quantity}
                  </li>
                )
              );
            })}
          </ul>
          {values.$events.notes && (
            <p>
              Reason:{' '}
              {values.$events.notes || (
                <span className="muted">None provided</span>
              )}
            </p>
          )}
        </Fragment>
      ),
      onConfirm: () => {
        this.props.onSubmitCancelItems({
          ...values,
          $events: values.$events.notes ? values.$events : undefined,
          items: this.props.cancelableItems
            .map((item, index) => ({
              id: item.id,
              quantity_canceled: values.items[index].cancel_quantity
                ? ~~values.items[index].cancel_quantity + item.quantity_canceled
                : undefined,
            }))
            .filter((item) => item.quantity_canceled !== undefined),
        });
      },
      cancelText: 'Back',
      actionType: 'danger',
    });
  }

  onChangeForm = (values, edited) => {
    this.setState({
      edited,
      values: {
        ...this.state.values,
        ...values,
        items: [...values.items],
      },
    });
    this.props.onEdited(edited);
  };

  onSubmitForm = (values) => {
    this.onConfirmCancel({
      ...values,
    });
  };

  onClickCancelAllItems = (event) => {
    event.preventDefault();
    const { values } = this.state;
    this.onConfirmCancel({
      ...values,
      items: this.props.cancelableItems.map((item, index) => ({
        id: item.id,
        cancel_quantity: item.quantity_cancelable,
      })),
    });
  };

  render() {
    const {
      record,
      loading,
      onClickCancelItems,
      onCloseCancelItems,
      cancelableItems,
    } = this.props;
    const { values } = this.state;

    const totalSelected = values.items.reduce(
      (acc, item) => acc + Number(item.cancel_quantity),
      0,
    );

    return (
      <Form
        onSubmit={this.onSubmitForm}
        onChange={this.onChangeForm}
        autoFocus={true}
      >
        <Modal
          title="Cancel items"
          actions={[
            {
              label: 'Save',
              type: 'submit',
              className: totalSelected ? 'default' : 'secondary',
            },
            {
              label: 'Cancel all items',
              type: 'secondary',
              className: 'button-cancel',
              onClick: this.onClickCancelAllItems,
            },
            {
              label: 'Close',
              type: 'secondary',
              className: 'button-cancel',
              onClick: onClickCancelItems,
            },
          ]}
          cancel={false}
          loading={loading}
          onClose={onCloseCancelItems}
          minWidth={700}
        >
          <fieldset>
            <div className="collection-table-container">
              <table className="collection-table">
                <thead>
                  <tr>
                    <th colSpan="2">Items</th>
                    <th>Quantity</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {cancelableItems.map((item, index) => {
                    const quantityCanceled =
                      values.items[index].cancel_quantity;
                    const all = ~~quantityCanceled === item.quantity_cancelable;
                    return (
                      <tr key={index}>
                        <td className="image top">
                          <span className="collection-table-images">
                            <span className="image">
                              <Link to={`/products/${item.product_id}`}>
                                <img
                                  src={productThumbUrl(
                                    item.product,
                                    item.variant,
                                  )}
                                  alt={productName(item.product, item.variant)}
                                />
                              </Link>
                            </span>
                          </span>
                        </td>
                        <td>
                          <OrderItemDetail
                            item={item}
                            style={
                              all
                                ? { textDecoration: 'line-through' }
                                : undefined
                            }
                          />
                        </td>
                        <td className="nowrap">
                          <Field
                            type="number"
                            name={`items.${index}.cancel_quantity`}
                            maxValue={item.quantity_cancelable}
                            placeholder="0"
                            className="order-line-items-qty-field"
                            selectFocus={true}
                            tabIndex={index + 1}
                          />
                          <div className="order-line-items-qty-desc">
                            of {item.quantity_cancelable}
                          </div>
                        </td>
                        <td>
                          <div className="collection-field-padded">
                            {formatCurrency(item.price, record.currency)}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Field
              type="textarea"
              name="$events.notes"
              label="Reason"
              placeholder="Optional"
              autoSize={true}
              rows={2}
              help="Cancellation notes are not visible to the customer"
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
