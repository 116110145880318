import React from 'react';
import PropTypes from 'prop-types';

import { contentFieldsAllByModel, contentFieldsAllByView } from 'utils/content';

import ContentFieldGroups from './field-groups';

import './content.scss';

function getInitialModels(models, collection) {
  return models.filter((model) => model.collection === collection);
}

function inTheZone(field, zone) {
  if (field.admin_zone === zone && field.admin_enabled !== false) {
    return true;
  }

  return false;
}

function isInView(field, view) {
  // Child collections can't be in a 'new' view
  const isChildCollection = field.type === 'collection' && field.child;
  return !isChildCollection || view.id !== 'new';
}

function getInitialState(props) {
  const { zone, view } = props;

  let allFields;
  if (view) {
    allFields = contentFieldsAllByView(view, (field) => isInView(field, view));
  } else {
    const models = getInitialModels(props.models, props.collection);
    allFields = contentFieldsAllByModel(models, (field) =>
      inTheZone(field, zone),
    );

    // Used to split toggle from other fields in app settings for example
    if (props.showFirstToggle !== undefined) {
      const firstToggle =
        allFields[0].field.type === 'toggle' ? allFields[0] : null;
      if (props.showFirstToggle) {
        if (firstToggle) {
          allFields = [firstToggle];
        } else {
          allFields = [];
        }
      } else {
        if (firstToggle) {
          allFields = allFields.slice(1);
        }
      }
    }
  }

  return {
    allFields,
  };
}

export default class ContentFields extends React.PureComponent {
  static propTypes = {
    models: PropTypes.array.isRequired,
    collection: PropTypes.string.isRequired,
    view: PropTypes.object,
    zone: PropTypes.string,
    root: PropTypes.string,
    namespace: PropTypes.string,
    record: PropTypes.object,
    values: PropTypes.object,
    currency: PropTypes.string,
    lookup: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    warnEmpty: PropTypes.bool,
    fallback: PropTypes.bool,

    onUpdate: PropTypes.func,
    render: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      zone: null,
      values: undefined,
      groups: [],
      groupFields: {},
      fieldsWithoutGroup: [],
      hasDefaults: {},
      usingDefault: {},
      prev: {},
      anyFieldsVisible: true,
      visibleReady: false,
      allFields: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.models !== state.prev.models ||
      props.values !== state.prev.values ||
      props.record !== state.prev.record ||
      props.view !== state.prev.view
    ) {
      const initialState = getInitialState(props);

      return {
        ...initialState,
        prev: {
          models: props.models,
          values: props.values,
          record: props.record,
          view: props.view,
        },
        visibleReady: initialState.allFields.length === 0,
      };
    }

    return null;
  }

  componentDidUpdate() {
    if (this.props.onUpdate) {
      this.props.onUpdate(this);
    }
  }

  onFieldGroupsUpdate = (component) => {
    const { anyFieldsVisible, visibleReady } = component.state;
    if (anyFieldsVisible !== this.state.anyFieldsVisible) {
      this.setState({ anyFieldsVisible });
    }
    if (visibleReady !== this.state.visibleReady) {
      this.setState({ visibleReady });
    }
  };

  render() {
    const { render, warnEmpty, onUpdate } = this.props;
    const { allFields } = this.state;

    if (allFields.length <= 0) {
      if (warnEmpty) {
        return (
          <div className="collection-none-found">This view has no fields</div>
        );
      } else {
        return null;
      }
    }

    const children = (
      <div className="content-fields">
        <ContentFieldGroups
          {...this.props}
          fields={allFields}
          onUpdate={onUpdate && this.onFieldGroupsUpdate}
        />
      </div>
    );

    if (typeof render === 'function') {
      return render(children);
    }

    return <div className="content-fields content-fields-box">{children}</div>;
  }
}
