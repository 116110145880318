import React from 'react';
import pt from 'prop-types';
import classNames from 'classnames';
import SectionHeader from 'components/section-header';
import OrderBillingEdit from 'components/pages/order/billing-edit';

import './payment.scss';

export default class PaymentMethodsRadio extends React.PureComponent {
  static propTypes = {
    className: pt.string,
    title: pt.string,
    options: pt.array,
    actions: pt.node,
    empty: pt.node,
    value: pt.string,
    disabled: pt.bool,
    showBilling: pt.bool,
    selectable: pt.bool,
    onSelect: pt.func,
    onClickAddBilling: pt.func,
    onSubmitBilling: pt.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.value !== props.value) {
      return { value: props.value };
    }

    return null;
  }

  onSelect = (event) => {
    event.preventDefault();

    const { onSelect } = this.props;
    const { value } = event.currentTarget.dataset;

    this.setState({ value });

    if (value && onSelect) {
      onSelect(value);
    }
  };

  renderOption = (option) => {
    const { selectable = true, disabled } = this.props;
    const { value } = this.state;
    const isChecked = option.value === value;

    return (
      <div
        key={option.value}
        className={classNames('payment-method-option-wrapper', { selectable })}
      >
        <button
          role="radio"
          aria-checked={isChecked}
          data-value={option.value}
          disabled={disabled || option.disabled}
          onClick={this.onSelect}
          className={classNames('form-field-input', {
            checked: isChecked,
          })}
        >
          {selectable && (
            <span
              className={classNames('form-radio-button', {
                checked: isChecked,
              })}
            />
          )}
          {option.label}
        </button>
      </div>
    );
  };

  render() {
    const {
      className,
      title,
      options,
      actions,
      empty,
      disabled,
      showBilling = false,
      onClickAddBilling,
      onSubmitBilling,
    } = this.props;
    const hasOptions = options?.length > 0;

    return (
      <div className={classNames('payment-methods-radio', className)}>
        <div className="view-body-section">
          {title && (
            <div className="view-body-subheader">
              <SectionHeader className="view-body-subtitle" title={title} />
            </div>
          )}
          <div
            className={classNames(
              'view-section-content payment-methods-radio-options',
              { disabled },
            )}
          >
            {hasOptions ? (
              <div className="payment-methods-options">
                {options.map(this.renderOption)}
              </div>
            ) : (
              <div className="payment-methods-radio-empty">{empty}</div>
            )}
            {actions && (
              <div className="payment-methods-actions">{actions}</div>
            )}
          </div>
        </div>
        {showBilling && (
          <OrderBillingEdit
            onSubmitEditBilling={onSubmitBilling}
            onClickEditBilling={onClickAddBilling}
          />
        )}
      </div>
    );
  }
}
