import {
  HIDE_EDITOR,
  SHOW_EDITOR,
  UPDATE_EDITOR_LOADING,
  UPDATE_EDITOR_SECTION,
} from '../actions/types';

const initialState = {
  showEditor: false,
  loading: false,
  section: { pages: null, settings: null },
  scrollTop: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case HIDE_EDITOR:
      return {
        ...state,
        showEditor: false,
      };
    case SHOW_EDITOR:
      return {
        ...state,
        showEditor: true,
      };
    case UPDATE_EDITOR_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case UPDATE_EDITOR_SECTION:
      return {
        ...state,
        section: action.payload.section || {},
        scrollTop: action.payload.scrollTop,
      };
    default:
      return state;
  }
}
