import React from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import Icon from 'components/icon';
import ButtonLink from 'components/button/link';
import { FadeIn } from 'components/transitions';

export default class DashboardNext extends React.PureComponent {
  static contextTypes = {
    user: PropTypes.object.isRequired,
  };

  onClickHideSetup = (event) => {
    event.preventDefault();
    const {
      dashboard: { setup },
    } = this.props;
    const { key } = event.currentTarget.dataset;
    this.props.updateSetup(key, !setup[key]);
  };

  renderAction({ icon, key, title, subtitle, buttons }, index) {
    const {
      dashboard: { setup },
    } = this.props;
    return (
      <div
        key={key}
        className={`dashboard-next-action setup ${
          setup[key] ? 'disabled' : ''
        } ${index === 0 && !setup[key] ? 'highlight' : ''}`}
      >
        <div className="dashboard-next-action-icon">
          <Icon
            {...(icon ? icon : {})}
            {...(setup[key] ? { fa: 'check' } : {})}
          />
        </div>
        <div className="dashboard-next-action-desc">
          <h3 className="dashboard-next-action-heading">{title}</h3>
          {!setup[key] && (
            <div className="dashboard-next-action-subheading">{subtitle}</div>
          )}
        </div>
        <a
          href=""
          onClick={this.onClickHideSetup}
          data-key={key}
          className="dashboard-next-action-hide"
        >
          &times;
        </a>
        {buttons && buttons.length && (
          <div className="button-container">
            {buttons.map(({ key, action, link, isInternalLink }) => (
              <ButtonLink
                {...(isInternalLink
                  ? { to: link }
                  : { href: link, target: 'blank' })}
                size="sm"
                type={index === 0 && !setup[key] ? 'default' : 'secondary'}
                key={key}
              >
                {action}
              </ButtonLink>
            ))}
          </div>
        )}
      </div>
    );
  }

  renderActions() {
    const {
      actions,
      dashboard: { setup },
    } = this.props;
    return (
      <FlipMove>
        {[
          ...actions
            .filter((a) => !setup[a.key])
            .map(this.renderAction.bind(this)),
          <div className="dashboard-next-action setup" key="help">
            <div className="dashboard-next-action-icon">
              <Icon fa="comment" />
            </div>
            <div className="dashboard-next-action-desc">
              <h3 className="dashboard-next-action-heading">Get a demo</h3>
              <div className="dashboard-next-action-subheading">
                Talk to our team and learn more about what Swell can do
              </div>
            </div>
            <ButtonLink
              size="sm"
              type="default"
              href="https://www.swell.is/for-enterprise#contact-form"
              target="blank"
            >
              Request demo
            </ButtonLink>
          </div>,
          <div className="dashboard-next-action setup" key="docs">
            <div className="dashboard-next-action-icon">
              <Icon fa="book" />
            </div>
            <div className="dashboard-next-action-desc">
              <h3 className="dashboard-next-action-heading">Read the docs</h3>
              <div className="dashboard-next-action-subheading">
                Learn more about Swell's features
              </div>
            </div>
            <div className="button-container">
              <ButtonLink
                size="sm"
                type="secondary"
                href="https://swell.is/help"
                target="blank"
              >
                User guide
              </ButtonLink>
              <ButtonLink
                size="sm"
                type="secondary"
                href="https://developers.swell.is/"
                target="blank"
              >
                Developer docs
              </ButtonLink>
            </div>
          </div>,
          <div className="dashboard-next-action setup" key="api">
            <div className="dashboard-next-action-icon">
              <Icon fa="code" />
            </div>
            <div className="dashboard-next-action-desc">
              <h3 className="dashboard-next-action-heading">
                Make an API call
              </h3>
              <div className="dashboard-next-action-subheading">
                Explore using the console, API library, or HTTP client
              </div>
            </div>
            <ButtonLink size="sm" type="secondary" to="/console?get/products">
              API console
            </ButtonLink>
          </div>,
          ...actions
            .filter((a) => setup[a.key])
            .map(this.renderAction.bind(this)),
        ]}
      </FlipMove>
    );
  }

  onClickIntercom = (event) => {
    event.preventDefault();
    window.Intercom && window.Intercom('show');
  };

  renderSteps() {
    return <FadeIn transitionDelay={100}>{this.renderActions()}</FadeIn>;
  }

  render() {
    const {
      dashboard: { hasOrders },
      nextSteps,
    } = this.props;

    const numNextSteps = nextSteps.length;
    if (!numNextSteps || hasOrders) {
      return null;
    }

    return (
      <div className={`dashboard-next ${!hasOrders ? 'without-orders' : ''}`}>
        <FadeIn>
          <div className="dashboard-welcome">
            <h2 className="dashboard-welcome-title">Quick start guide</h2>
            {this.renderSteps()}
            <div className="dashboard-welcome-image" />
          </div>
        </FadeIn>
      </div>
    );
  }
}
