import React from 'react';
import pt from 'prop-types';

import Modal from 'components/modal';
import { Form } from 'components/form';
import OrderItems from 'components/pages/order/items';

export default class SubscriptionItemsEdit extends React.PureComponent {
  static propTypes = {
    record: pt.object,
  };

  onChangeForm = (values, edited) => {
    this.props.onEdited(edited);
  };

  render() {
    const {
      record,
      editItems,
      onSubmitEditItems,
      onClickEditItems,
      onClickRemoveEditItem,
      onClickAddItem,
      onCloseEditItems,
      onChangeEditItemInput,
    } = this.props;

    return (
      <Form
        onSubmit={onSubmitEditItems}
        onChange={this.onChangeForm}
        autoFocus={false}
        autoFocusEmpty={false}
      >
        <Modal
          title="Edit invoice items"
          actions={[
            { label: 'Save', type: 'submit' },
            //{ label: 'Add item', type: 'secondary', onClick: onClickAddItem },
            {
              label: 'Cancel',
              type: 'secondary',
              className: 'button-cancel',
              onClick: onClickEditItems,
            },
          ]}
          cancel={false}
          onClose={onCloseEditItems}
          minWidth={800}
        >
          <fieldset>
            <OrderItems
              record={record}
              editing={true}
              editItems={editItems}
              onClickAddItem={onClickAddItem}
              onClickRemoveEditItem={onClickRemoveEditItem}
              onChangeEditItemInput={onChangeEditItemInput}
            />
          </fieldset>
        </Modal>
      </Form>
    );
  }
}
