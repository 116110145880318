import React from 'react';
import Status from 'components/status';

export default function GiftcardStatus({ giftcard }) {
  const isExpired = !!(
    giftcard.date_expired && new Date(giftcard.date_expired) < Date.now()
  );
  return (
    <Status
      type={
        giftcard.redeemed || giftcard.amount_spent > 0
          ? 'positive'
          : giftcard.disabled || isExpired
          ? 'negative'
          : ''
      }
    >
      {giftcard.redeemed
        ? 'Redeemed'
        : giftcard.disabled
        ? 'Disabled'
        : isExpired
        ? 'Expired'
        : giftcard.amount > 0 && giftcard.amount_spent === giftcard.amount
        ? 'Spent'
        : giftcard.amount_spent > 0
        ? 'Partially spent'
        : 'New'}
    </Status>
  );
}
