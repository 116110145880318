import React from 'react';
import pt from 'prop-types';

import DateTime from 'components/date-time';

function SubscriptionInvoiceNext({
  record,
  format = 'short',
  onClickEditInvoiceDate,
  onClickPause,
}) {
  const {
    paused,
    complete,
    canceled,
    draft,
    status,
    billing_schedule,
    date_pause_end,
    date_period_end,
  } = record;

  if (
    (complete && !date_period_end) ||
    canceled ||
    draft ||
    (status === 'draft' && !date_period_end) ||
    (paused && !date_pause_end) ||
    (!date_period_end &&
      billing_schedule?.limit > 0 &&
      billing_schedule.limit <= billing_schedule.limit_current)
  ) {
    return <span className="muted">&mdash;</span>;
  }

  if (paused && date_pause_end) {
    const datePauseEnd = <DateTime value={date_pause_end} format={format} />;

    if (onClickPause) {
      return (
        <button className="as-link" onClick={onClickPause} type="button">
          {datePauseEnd}
        </button>
      );
    }

    return datePauseEnd;
  }

  const datePeriodEnd = date_period_end ? (
    <DateTime value={date_period_end} format={format} />
  ) : (
    '—'
  );

  if (onClickEditInvoiceDate) {
    return (
      <button
        className="as-link"
        onClick={onClickEditInvoiceDate}
        type="button"
      >
        {datePeriodEnd}
      </button>
    );
  }

  return datePeriodEnd;
}

SubscriptionInvoiceNext.propTypes = {
  record: pt.object.isRequired,
  format: pt.string,

  onClickEditInvoiceDate: pt.func,
  onClickPause: pt.func,
};

export default React.memo(SubscriptionInvoiceNext);
