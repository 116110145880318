import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, cloneDeep, map, find } from 'lodash';
import FlipMove from 'react-flip-move';
import { Form, Field, FieldEvents } from 'components/form';
import Link from 'components/link';
import View from 'components/view';
import Modal from 'components/modal';
import Loading from 'components/loading';
import Help from 'components/tooltip/help';
import HelpLink from 'components/help-link';
import actions from 'actions';
import Tooltip from 'components/tooltip';
import Icon from 'components/icon';
import { normalizeUrl, testUrlSegment } from 'utils';
import {
  INTEGRATIONS,
  integrationName,
  integrationActivated,
} from 'utils/integration';
import api from 'services/api';

const {
  MAILCHIMP_CLIENT_ID,
  HUBSPOT_CLIENT_ID,
  HUBSPOT_PERMISSIONS_SCOPES,
  HUBSPOT_REDIRECT_URI,
} = process.env;

export const mapDispatchToProps = (dispatch) => ({
  startIntegrationSync: (id) => {
    return dispatch(actions.data.startIntegrationSync(id));
  },

  watchIntegrationSync: (id) => {
    return dispatch(actions.data.watchIntegrationSync(id));
  },

  unwatchIntegrationSync: (id) => {
    return dispatch(actions.data.watchIntegrationSync(id, false));
  },
});

const mapStateToProps = (state) => ({
  integrationSync: state.data.integrationSync,
});

class IntegrationSettings extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    testEnv: PropTypes.string,
  };

  state = {};

  constructor(props) {
    super(props);
    this.state = {
      editId: null,
      editValues: null,
      editLoading: false,
      showEditModal: false,
      mailchimpLists: [],
      ...this.getCommandState(props),
    };
  }

  componentDidMount() {
    const {
      values: { services },
    } = this.props;

    for (const [id, service] of Object.entries(services)) {
      if (service.sync_status === 'processing') {
        this.props.watchIntegrationSync(id);
      }
    }
  }

  componentWillUnmount() {
    const { integrationSync } = this.props;

    for (const [id, integration] of Object.entries(integrationSync)) {
      if (integration.watch) {
        this.props.unwatchIntegrationSync(id);
      }
    }
  }

  getCommandState(props) {
    if (props.routeParams.command === 'mailchimp_activated') {
      return this.getMailchimpCommandState(props);
    } else if (props.routeParams.command === 'slack_activated') {
      return this.getSlackCommandState(props);
    } else if (props.routeParams.command === 'hubspot_activated') {
      return this.getHubSpotCommandState(props);
    }
    return {};
  }
  async getHubSpotCommandState(props) {
    const { code } = props.location.query;

    if (code) {
      const formData = {
        code: code.split('"').join(''),
        redirect_uri: HUBSPOT_REDIRECT_URI,
      };

      const response = await api.requestHubSpotToken(formData);

      if (response.success) {
        const result = await this.props.onSubmitForm(
          {
            ...props.values,
            services: {
              ...props.values.services,
              hubspot: {
                ...props.values.services.hubspot,
                activated: true,
                enabled: true,
                ...response.data,
              },
            },
          },
          this.refs.form,
        );
        this.setState({
          editLoading: false,
          editValues: result || props.values.services.hubspot,
        });
        this.props.startIntegrationSync('hubspot');
      }

      return {
        editId: 'hubspot',
        editValues: props.values.services.hubspot,
        editLoading: true,
        showEditModal: true,
      };
    }
  }
  getMailchimpCommandState(props) {
    const { code } = props.location.query;

    if (code) {
      api.connectMailchimp(code).then(async (response) => {
        let result;

        if (response) {
          result = await this.props.onSubmitForm(
            {
              ...props.values,
              services: {
                ...props.values.services,
                mailchimp: {
                  ...props.values.services.mailchimp,
                  ...response,
                  activated: true,
                  enabled: true,
                },
              },
            },
            this.refs.form,
          );
        }

        await this.fetchMailchimpLists();

        this.setState({
          editLoading: false,
          editValues: result || props.values.services.mailchimp,
        });
      });

      return {
        editId: 'mailchimp',
        editValues: props.values.services.mailchimp,
        editLoading: true,
        showEditModal: true,
      };
    }

    return {
      editId: 'mailchimp',
      editValues: props.values.services.mailchimp,
      editLoading: false,
      showEditModal: true,
    };
  }

  getSlackCommandState(props) {
    return {
      editId: 'slack',
      editValues: {
        services: { slack: props.values.services.slack },
      },
      editLoading: false,
      showEditModal: true,
    };
  }

  removeCommandFromLocation() {
    const { params, router, location } = this.props;
    if (params.command) {
      router.replace(location.pathname.replace(`/${params.command}`, ''));
    }
  }

  async fetchMailchimpLists() {
    const accessToken = get(
      this.props.values,
      'services.mailchimp.access_token',
    );
    if (accessToken) {
      try {
        const mailchimpLists = await api.fetchMailchimpLists();
        this.setState({ mailchimpLists });
      } catch (err) {
        this.context.notifyError(
          'There was a problem retrieving Mailchimp contact lists',
        );
      }
    }
  }

  onClickEdit = (event) => {
    event.preventDefault();
    const { id } = event.currentTarget.dataset;

    this.setState({
      editId: id,
      editValues: cloneDeep(this.props.values),
      showEditModal: true,
      edited: false,
      editLoading: true,
    });

    // Re fetch service settings that might have changed while syncing
    const service = find(INTEGRATIONS, { id }) || {};
    if (service.sync) {
      this.props.refetchSettings();
    }

    this.onClickEditLoading(id);
  };

  async onClickEditLoading(editId) {
    if (editId === 'mailchimp') {
      await this.fetchMailchimpLists();
    }

    this.setState({ editLoading: false });
  }

  onClickRefreshMailchimpLists = (event) => {
    event.preventDefault();
    this.setState({ editLoading: true });
    this.fetchMailchimpLists().then(() => {
      this.setState({ editLoading: false });
    });
  };

  onCloseEdit = (event) => {
    event.preventDefault();
    this.removeCommandFromLocation();
    this.setState({
      showEditModal: false,
      edited: false,
      editLoading: false,
    });
  };

  async onSubmitEditValidate(values) {
    const { editId } = this.state;
    const { notifyError } = this.context;

    const service = find(INTEGRATIONS, { id: editId }) || {};
    const serviceValues = get(values, `services.${editId}`) || {};

    if (!serviceValues.enabled) {
      return true;
    }

    switch (editId) {
      case 'avatax': {
        const environment = serviceValues.environment;
        const companyCode = serviceValues[`${environment}_company_code`];

        const response = await api.validateIntegrationCredentials('avatax', {
          environment,
          company_code: companyCode,
          username: serviceValues[`${environment}_account_id`],
          password: serviceValues[`${environment}_license_key`],
        });
        if (!response) {
          notifyError(
            `Unable to validate AvaTax credentials. Try again in a moment.`,
          );
          return false;
        }
        if (response.companyNotFound) {
          notifyError(
            `The company code '${companyCode}' was not found or is not active`,
          );
          return false;
        }
        if (!response.success) {
          notifyError(
            'Your AvaTax account ID or license key appears to be invalid',
          );
          return false;
        }
        break;
      }

      case 'shipstation': {
        this.setState({
          editLoading: true,
        });
        const apiKey = serviceValues.api_key.trim();
        const apiSecret = serviceValues.api_secret.trim();
        const response = await api.validateIntegrationCredentials(
          'shipstation',
          {
            username: apiKey,
            password: apiSecret,
          },
        );
        this.setState({
          editLoading: false,
        });
        if (!response) {
          notifyError(
            `Unable to validate ShipStation credentials. Try again in a moment.`,
          );
          return false;
        }
        const res = response ? response.success : false;
        if (!res) {
          notifyError('Your ShipStation API keys appears to be invalid');
          return false;
        }
        return {
          options: response.options,
          api_key: apiKey,
          api_secret: apiSecret,
        };
      }
      case 'yotpo': {
        const storeId = serviceValues.store_id.trim();
        const secretKey = serviceValues.secret_key.trim();
        const apiKey = serviceValues.api_key.trim();
        const guid = serviceValues.guid.trim();
        let obj = {};

        if (guid && apiKey) {
          obj.guid = guid;
          obj.apiKey = apiKey;
        }

        const response = await api.validateIntegrationCredentials('yotpo', {
          storeId: storeId,
          secretKey: secretKey,
          ...obj,
        });
        if (!response) {
          notifyError(
            `Unable to validate Yotpo credentials. Try again in a moment.`,
          );
          return false;
        }
        const res = response ? response.success : false;
        if (!res) {
          notifyError('Your Yotpo API keys appears to be invalid');
          return false;
        }
        const { access_token } = response.access_token;

        values.services.yotpo = {
          ...values.services.yotpo,
          access_token: access_token,
        };

        break;
      }

      case 'taxjar': {
        const environment = serviceValues.environment;
        const apiKey = serviceValues[`${environment}_api_key`];
        const response = await api.validateTaxjarApiKey({
          environment,
          api_key: apiKey,
        });
        if (!response) {
          notifyError(
            `Unable to validate TaxJar credentials. Try again in a moment.`,
          );
          return false;
        }
        if (!response.success) {
          notifyError('Your TaxJar API token appears to be invalid');
          return false;
        }
        break;
      }
      case 'gladly': {
        const { api_key, api_url, agent_email } = serviceValues;
        const response = await api.validateGladlyApiKey({
          api_key,
          api_url,
          agent_email,
        });
        if (!response.success) {
          this.context.notifyError(
            'Your Gladly API token appears to be invalid',
          );
          return false;
        }
        serviceValues.id = response.id;
        break;
      }
      case 'omnisend': {
        const response = await api.validateIntegrationCredentials('omnisend', {
          api_key: serviceValues.api_key,
        });
        if (!response) {
          notifyError(
            `Unable to validate Omnisend credentials. Try again in a moment.`,
          );
          return false;
        }
        values.services.omnisend = {
          ...values.services.omnisend,
          store_url: normalizeUrl(values.services.omnisend.store_url),
        };
        if (!response.success) {
          notifyError('Your Omnisend API key  appears to be invalid');
          return false;
        }
        break;
      }
      case 'algolia': {
        const apiApplicationId = serviceValues.api_application_id.trim();
        const apiAdminKey = serviceValues.api_admin_key.trim();
        const response = await api.validateIntegrationCredentials('algolia', {
          apiApplicationId: apiApplicationId,
          apiAdminKey: apiAdminKey,
        });
        if (!response) {
          notifyError(
            `Unable to validate Algolia credentials. Try again in a moment.`,
          );
          return false;
        }
        const res = response ? response.success : false;
        if (!res) {
          notifyError('Your Algolia API keys appears to be invalid');
          return false;
        }
        break;
      }
      case 'smartystreets': {
        const auth_id = serviceValues.auth_id.trim();
        const auth_token = serviceValues.auth_token.trim();
        const response = await api.validateIntegrationCredentials(
          'smartystreets',
          {
            auth_id,
            auth_token,
          },
        );
        if (!response) {
          notifyError(
            `Unable to validate SmartyStreets credentials. Try again in a moment.`,
          );
          return false;
        }
        const res = response ? response.success : false;
        if (!res) {
          notifyError('Your SmartyStreets API key appears to be invalid');
          return false;
        }
        break;
      }
      default:
        break;
    }

    // Reset sync status before save
    if (
      service.sync &&
      serviceValues.enabled &&
      serviceValues.sync_status === 'failed'
    ) {
      serviceValues.sync_status = null;
    }

    return true;
  }

  submitShipStationValues({ values, option, result }) {
    this.submitForm({
      $set: {
        ...values,
        services: {
          ...this.props.record.services,
          shipstation: {
            api_key: result.api_key,
            sync_error: '',
            api_secret: result.api_secret,
            selling_channels: {
              options: result.options,
              picked: option.id,
            },
            activated: true,
            enabled: true,
          },
        },
      },
    }).then((result) => {
      this.onSubmitStartSync(result);
    });
  }

  openShipStationModal({ values, result }) {
    this.context.openModal('Confirm', {
      title: `Activate ShipStation?`,
      message: (
        <div id="Channel">
          <Field
            type="select"
            label="Selling channel"
            name="shipstation.selling_channels.picked"
            required={true}
            hint="Choose the selling channel to sync the orders"
            options={map(result.options, (list) => ({
              value: list.id,
              label: list.name,
            }))}
          />
        </div>
      ),
      actionType: 'submit',
      onConfirm: () => {
        let label = document.getElementsByName(
          'shipstation.selling_channels.picked',
        )[0].value;
        if (!label)
          return this.context.notifyError(
            'Select a selling channel to continue',
          );
        const option = result.options.find((element) => element.name === label);
        values.services.shipstation.api_key =
          values.services.shipstation.api_key.trim();
        values.services.shipstation.api_secret =
          values.services.shipstation.api_secret.trim();
        result.api_key = result.api_key.trim();
        result.api_secret = result.api_secret.trim();
        this.submitShipStationValues({ result, option, values });
      },
    });
  }

  async onSubmitStartSync(values) {
    const { editId } = this.state;
    const service = find(INTEGRATIONS, { id: editId }) || {};
    const serviceValues = get(values, `services.${editId}`) || {};

    // Start sync after save if enabled
    if (service.sync && serviceValues.enabled && !serviceValues.sync_status) {
      this.props.startIntegrationSync(editId);
    }
  }

  onSubmitEdit = () => {
    const values = {
      ...this.props.values,
      ...this.state.editValues,
    };

    this.onSubmitEditValidate(values).then((success) => {
      if (success) {
        // TODO: move this logic into onSubmitEditValidate
        // deactivate the other tax service
        const { taxjar, avatax, shipstation, algolia, yotpo } = values.services;
        const { services: currentServices } = this.props.values;
        let serviceToDeactivate, serviceToActivate;

        if (taxjar && taxjar.enabled) {
          values.services.avatax = {
            enabled: false,
            activated: false,
          };
          serviceToActivate = 'TaxJar';

          if (get(currentServices, 'avatax.activated')) {
            serviceToDeactivate = integrationName('avatax');
          }
        } else if (avatax && avatax.enabled) {
          values.services.taxjar = {
            enabled: false,
            activated: false,
          };
          serviceToActivate = 'Avalara Avatax';

          if (get(currentServices, 'taxjar.activated')) {
            serviceToDeactivate = integrationName('taxjar');
          }
        }

        if (shipstation) {
          const { ...result } = success;
          if (get(shipstation, `selling_channels.picked`) === undefined) {
            this.openShipStationModal({ result, values });
            return;
          } else {
            this.submitShipStationValues({
              result: {
                ...result,
                options: success.options,
              },
              option: { id: shipstation.selling_channels.picked },
              values,
            });
          }
        }

        if (serviceToDeactivate) {
          this.context.openModal('Confirm', {
            title: `Activate ${serviceToActivate}?`,
            message: (
              <p>
                Enabling {serviceToActivate} will cause your{' '}
                {serviceToDeactivate} integration to be deactivated. Are you
                sure?
              </p>
            ),
            actionType: 'danger',
            onConfirm: () => {
              this.submitForm(values).then((result) => {
                if (result) {
                  this.onSubmitStartSync(result);
                }
              });
            },
          });
        } else {
          if (shipstation) {
            values.services.shipstation.api_key =
              values.services.shipstation.api_key.trim();
            values.services.shipstation.api_secret =
              values.services.shipstation.api_secret.trim();
          }

          if (yotpo) {
            values.services.yotpo.store_id =
              values.services.yotpo.store_id.trim();
            values.services.yotpo.secret_key =
              values.services.yotpo.secret_key.trim();
            values.services.yotpo.access_token =
              values.services.yotpo.access_token.trim();
            values.services.yotpo.sync_status = '';

            if (
              values.services.yotpo.guid !== '' &&
              values.services.yotpo.api_key !== ''
            ) {
              values.services.yotpo.guid = values.services.yotpo.guid.trim();
              values.services.yotpo.api_key =
                values.services.yotpo.api_key.trim();
            } else {
              values.services.yotpo.guid = '';
              values.services.yotpo.api_key = '';
            }
          }
          if (algolia) {
            values.services.algolia.api_application_id =
              values.services.algolia.api_application_id.trim();
            values.services.algolia.api_admin_key =
              values.services.algolia.api_admin_key.trim();
          }

          this.submitForm(values).then((result) => {
            if (result) {
              this.onSubmitStartSync(result);
            }
          });
        }
      }
    });
  };

  onChangeEditValues = (values, edited) => {
    this.setState({
      editValues: { ...this.state.editValues, ...values },
      edited,
    });
  };

  submitForm(values) {
    return this.props.onSubmitForm(values, this.refs.form).then((result) => {
      this.setState({ showEditModal: false });
      return result;
    });
  }

  onClickChat = (event) => {
    event.preventDefault();
    window.Intercom('show');
  };

  getMailchimpConnectUrl = () => {
    const { testEnv } = this.context;
    return `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${MAILCHIMP_CLIENT_ID}&redirect_uri=${
      window.location.origin
    }/admin/${testUrlSegment(
      testEnv,
    )}settings/integrations/mailchimp_activated`;
  };

  slackRedirect = async () => {
    const { url } = await api.getSlackConnectUrl(window.location.origin);
    window.location.href = url;
  };

  getHubSpotConnectUrl = () => {
    const HUBSPOT_SCOPES =
      HUBSPOT_PERMISSIONS_SCOPES.split(/ |, ?|%20/).join(' ');
    return `https://app.hubspot.com/oauth/authorize?client_id=${encodeURIComponent(
      HUBSPOT_CLIENT_ID,
    )}&scope=${encodeURIComponent(
      HUBSPOT_SCOPES,
    )}&redirect_uri=${HUBSPOT_REDIRECT_URI}&state=${
      window.location.origin
    }_hubspot`;
  };
  onClickDeactivate = (id) => {
    const service = get(this.props.record.services, id, {});
    this.context.openModal('Confirm', {
      title: `Deactivate`,
      message: (
        <p>
          Are you sure you want to deactivate and clear settings for{' '}
          {service.name || 'this integration'}?
        </p>
      ),
      action: 'Deactivate',
      actionType: 'danger',
      onConfirm: async () => {
        if (id === 'shipstation') {
          await api.deleteWebhooks('shipstation', {});
        } else if (id === 'slack') {
          await api.slackUninstall();
        }
        this.submitForm({
          $set: {
            services: {
              ...this.props.record.services,
              [id]: {
                type: service.type,
                activated: false,
                enabled: false,
              },
            },
          },
        });
      },
    });
  };

  getEditActions(id) {
    const { values } = this.props;
    const { editId } = this.state;
    const service = get(values.services, id, {});

    const activated = integrationActivated(id, service);
    const deactivateAction = activated && {
      label: 'Deactivate',
      type: 'secondary',
      className: 'left button-cancel',
      onClick: this.onClickDeactivate.bind(this, id),
    };
    const syncStatus = service.sync_status;

    const retryAction = activated &&
      syncStatus === 'failed' && {
        label: 'Retry',
        type: 'secondary',
        className: 'left button-cancel',
        onClick: () =>
          this.submitForm({
            $set: {
              services: {
                ...this.props.record.services,
                [editId]: {
                  ...service,
                  sync_error: '',
                  sync_status: '',
                },
              },
            },
          }).then((result) => {
            if (result) {
              this.props.startIntegrationSync(editId);
            }
          }),
      };

    const syncStatusComponent = syncStatus && {
      component: (
        <div className="settings-integrations-sync-status button button-sm button-cancel">
          {syncStatus === 'processing' ? (
            <Fragment>
              Syncing
              <Loading className="loading-icon" />
            </Fragment>
          ) : syncStatus === 'failed' && activated ? (
            <span className="muted">
              <Tooltip message={service.sync_error}>Sync failed</Tooltip>
            </span>
          ) : (
            syncStatus === 'complete' && (
              <span className="muted">Synchronized</span>
            )
          )}
        </div>
      ),
    };

    switch (id) {
      case 'mailchimp':
        const { mailchimpLists } = this.state;
        const hasLists = mailchimpLists && mailchimpLists.length > 0;
        return [
          hasLists && { label: 'Save', type: 'submit' },
          !activated && {
            component: (
              <a
                href={this.getMailchimpConnectUrl()}
                className="button button-sm button-default"
              >
                Connect your Mailchimp account
              </a>
            ),
          },
          {
            label: activated ? 'Close' : 'Cancel',
            type: 'cancel',
            onClick: this.onCloseEdit,
          },
          activated && {
            component: (
              <a
                href={this.getMailchimpConnectUrl()}
                className="button button-sm button-cancel"
              >
                Switch accounts
              </a>
            ),
          },
          deactivateAction,
        ];
      case 'hubspot':
        return [
          !activated && {
            component: (
              <a
                href={this.getHubSpotConnectUrl()}
                className="button button-sm button-default"
              >
                Connect your HubSpot account
              </a>
            ),
          },
          {
            label: activated ? 'Close' : 'Cancel',
            type: 'cancel',
            onClick: this.onCloseEdit,
          },
          retryAction,
          syncStatusComponent,
          deactivateAction,
        ];
      case 'segment':
        return [
          { label: activated ? 'Save' : 'Enable', type: 'submit' },
          {
            label: activated ? 'Close' : 'Cancel',
            type: 'cancel',
            onClick: this.onCloseEdit,
          },
          deactivateAction,
        ];
      case 'slack':
        return [
          activated && { label: 'Save', type: 'submit' },
          !activated && {
            component: (
              <a
                onClick={this.slackRedirect}
                className="button button-sm button-default"
              >
                Connect to Slack
              </a>
            ),
          },
          activated && {
            component: (
              <a
                onClick={this.slackRedirect}
                className="button button-sm button-cancel"
              >
                Switch accounts
              </a>
            ),
          },
          deactivateAction,
        ];
      case 'yotpo':
        return [
          { label: activated ? 'Save' : 'Enable', type: 'submit' },
          {
            label: activated ? 'Close' : 'Cancel',
            type: 'cancel',
            onClick: this.onCloseEdit,
          },
          syncStatusComponent,
          deactivateAction,
          retryAction,
        ];
      default:
        return [
          { label: activated ? 'Save' : 'Enable', type: 'submit' },
          {
            label: activated ? 'Close' : 'Cancel',
            type: 'cancel',
            onClick: this.onCloseEdit,
          },
          deactivateAction,
          retryAction,
          syncStatusComponent,
        ];
    }
  }

  renderEditModal() {
    const { loading } = this.props;
    const { editId, editValues, editLoading } = this.state;

    const service = find(INTEGRATIONS, { id: editId }) || {};
    const values = get(editValues.services, editId, {});
    const record = get(this.props.record.services, editId, {});

    const renderFields = this[`renderEditModalFields_${editId}`];
    return (
      <Form onSubmit={this.onSubmitEdit} onChange={this.onChangeEditValues}>
        <Modal
          title={service.name}
          actions={this.getEditActions(editId)}
          width={550}
          cancel={false}
          loading={loading || editLoading}
          onClose={this.onCloseEdit}
          helpLink={false}
        >
          <fieldset>
            {renderFields ? (
              <Fragment>
                {renderFields.call(this, values, record)}
                {this.renderEnableToggle(editId, record)}
              </Fragment>
            ) : (
              <p>Integration fields not defined</p>
            )}
          </fieldset>
        </Modal>
      </Form>
    );
  }

  renderEnableToggle(id, record) {
    return (
      <div className="settings-integrations-enabler">
        {integrationActivated(id, record) && (
          <Fragment>
            <Field
              type="toggle"
              id={`${id}_enabled_modal`}
              name={`services.${id}.enabled`}
              defaultChecked={!!record.enabled}
            />
          </Fragment>
        )}
      </div>
    );
  }

  renderEditModalFields_klaviyo(values, record) {
    return (
      <Fragment>
        <p>
          Use Klaviyo to build automated and a revenue-driving email campaigns.
        </p>
        {!integrationActivated('klaviyo', record) && (
          <Fragment>
            <Field type="hidden" name="services.klaviyo.enabled" value="true" />
            <Field
              type="hidden"
              name="services.klaviyo.activated"
              value="true"
            />
            <p>
              <b>To get started</b>,{' '}
              <a href="https://www.klaviyo.com/" target="blank">
                get a Klaviyo account
              </a>
              , then return to this page to enter your credentials.
            </p>
            <p>
              Find your public and private keys in the Klaviyo dashboard under{' '}
              <a
                href="https://www.klaviyo.com/account#api-keys-tab"
                target="blank"
              >
                API Keys
              </a>
              .
            </p>
          </Fragment>
        )}
        <Fragment>
          <Field
            type="text"
            label="Public API key (Site ID)"
            name="services.klaviyo.site_id"
            defaultValue={record.site_id}
            required={true}
          />
          <Field
            type="text"
            label="Private API key"
            name="services.klaviyo.private_api_key"
            defaultValue={record.private_api_key}
            required={true}
          />
        </Fragment>
      </Fragment>
    );
  }

  renderEditModalFields_omnisend(values, record) {
    return (
      <Fragment>
        <p>
          Take a shortcut to growth with Omnisend’s easy-to-use platform for
          email marketing, SMS and automation.
        </p>
        {!integrationActivated('omnisend', record) && (
          <Fragment>
            <Field
              type="hidden"
              name="services.omnisend.enabled"
              value="true"
            />
            <Field
              type="hidden"
              name="services.omnisend.activated"
              value="true"
            />
            <p>
              <b>To get started</b>,{' '}
              <a href="https://www.omnisend.com/" target="blank">
                create an Omnisend account
              </a>
              , then return to this page to enter your credentials.
            </p>
            <p>
              Find your API key in the Omnisend settings{' '}
              <a
                href="https://app.omnisend.com/#/my-account/integrations/api-keys"
                target="blank"
              >
                API Keys
              </a>
              .
            </p>
          </Fragment>
        )}
        {record.auto_disabled ? (
          <Fragment>
            <Field
              type="hidden"
              name="services.omnisend.auto_disabled"
              value="false"
            />
            <Field
              type="hidden"
              name="services.omnisend.sync_status"
              value=""
            />
            <Field type="hidden" name="services.omnisend.sync_error" value="" />
            <p>
              <span className="negative note">
                This integration was automatically disabled because your API key
                was rejected by Omnisend. Make sure your credentials are correct
                and re-enable it.
              </span>
            </p>
          </Fragment>
        ) : (
          record.sync_error && (
            <Fragment>
              <Field
                type="hidden"
                name="services.omnisend.sync_status"
                value=""
              />
              <Field
                type="hidden"
                name="services.omnisend.sync_error"
                value=""
              />
              <p>
                <span className="note">
                  There was an error synchronizing with Omnisend.
                </span>
              </p>
              <div className="note alert alert-info negative">
                {record.sync_error}
              </div>
            </Fragment>
          )
        )}
        <Fragment>
          <Field
            type="text"
            label="API key"
            name="services.omnisend.api_key"
            defaultValue={record.api_key}
            required
          />
          <Field
            type="text"
            label="Store URL"
            name="services.omnisend.store_url"
            defaultValue={record.store_url}
            rules="required, url"
          />
          {record && record.api_key && !record.sync_error && (
            <Field
              type="hidden"
              name="services.omnisend.sync_status"
              value={
                record.api_key !== values.api_key ? '' : record.sync_status
              }
            />
          )}
        </Fragment>
      </Fragment>
    );
  }

  renderEditModalFields_avatax(values, record) {
    const { testEnv } = this.context;

    const isProduction =
      !testEnv && (!values.environment || values.environment === 'production');

    const isActivated = integrationActivated('avatax', record);

    return (
      <Fragment>
        <p>
          Use Avatax to calculate taxes in real-time at checkout and create
          documentation for automated sales tax compliance.
        </p>
        {!isActivated && (
          <Fragment>
            <Field type="hidden" name="services.avatax.enabled" value="true" />
            <Field
              type="hidden"
              name="services.avatax.activated"
              value="true"
            />
            <p>
              <b>To get started</b>,{' '}
              <a
                href="https://www.avalara.com/us/en/get-started.html"
                target="blank"
              >
                reach out to Avalara
              </a>{' '}
              and create your account, then return to this page to enter your
              credentials.
            </p>
          </Fragment>
        )}
        {isActivated && record.auto_disabled && (
          <Fragment>
            <Field
              type="hidden"
              name="services.avatax.auto_disabled"
              value="false"
            />
            <p>
              <span className="negative note">
                This integration was automatically disabled because your account
                ID or license key was rejected by Avalara. Make sure your
                credentials are correct and re-enable it.
              </span>
            </p>
          </Fragment>
        )}
        {!testEnv && (
          <Field
            type="radio"
            name="services.avatax.environment"
            options={[
              {
                value: 'production',
                label: 'Production',
              },
              {
                value: 'sandbox',
                label: 'Sandbox',
              },
            ]}
            buttons={true}
            defaultValue={record.environment || 'production'}
            required={true}
          />
        )}
        {isProduction ? (
          <>
            {values.environment === 'sandbox' && !isActivated && (
              <p>
                Note: You may need to specifically request a sandbox from your
                Avalara account manager.
              </p>
            )}
            <Field
              type="text"
              label="Company code"
              name="services.avatax.production_company_code"
              defaultValue={record.production_company_code}
              required={true}
              help="Find your company code in the Avalara dashboard under Settings"
            />
            <Field
              type="text"
              label="Account ID"
              name="services.avatax.production_account_id"
              defaultValue={
                record.production_account_id || record.production_username
              }
              autoComplete="avatax-account-id-production"
              rules="required"
              help="Your account ID is found in the Avalara dashboard"
            />
            <Field
              type="text"
              label="License key"
              name="services.avatax.production_license_key"
              defaultValue={
                record.production_license_key || record.production_password
              }
              autoComplete="avatax-license-key-production"
              rules="required"
              placeholder="****************"
              help="Your license key is found in the Avalara dashboard"
            />
          </>
        ) : (
          <Fragment>
            <Field
              type="hidden"
              name="services.avatax.environment"
              value="sandbox"
            />
            {!isActivated && (
              <p>
                Note: You may need to specifically request a sandbox from your
                Avalara account manager.
              </p>
            )}
            <Field
              type="text"
              label="Sandbox company code"
              name="services.avatax.sandbox_company_code"
              defaultValue={record.sandbox_company_code}
              required={true}
              help="Find your company code in the Avalara dashboard under Settings"
            />
            <Field
              type="text"
              label="Sandbox account ID"
              name="services.avatax.sandbox_account_id"
              defaultValue={
                record.sandbox_account_id || record.sandbox_username
              }
              autoComplete="avatax-account-id-sandbox"
              rules="required"
              help="Your account ID is found in the Avalara dashboard"
            />
            <Field
              type="text"
              label="Sandbox license key"
              name="services.avatax.sandbox_license_key"
              defaultValue={
                record.sandbox_license_key || record.sandbox_password
              }
              autoComplete="avatax-account-license-key-sandbox"
              rules="required"
              placeholder="****************"
              help="Your license key is found in the Avalara dashboard"
            />
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderEditModalFields_taxjar(values, record) {
    const { testEnv } = this.context;

    const isProduction =
      !testEnv && (!values.environment || values.environment === 'production');

    const isActivated = integrationActivated('taxjar', record);

    return (
      <Fragment>
        <p>
          Use TaxJar to calculate taxes in real-time at checkout and create
          documentation for automated sales tax compliance.
        </p>
        {!isActivated && (
          <Fragment>
            <Field type="hidden" name="services.taxjar.enabled" value="true" />
            <Field
              type="hidden"
              name="services.taxjar.activated"
              value="true"
            />
            <p>
              <b>To get started</b>,{' '}
              <a href="https://app.taxjar.com/sign_up" target="blank">
                create a free TaxJar account
              </a>
              , then return to this page to enter your API token.
            </p>
          </Fragment>
        )}
        {isActivated && record.auto_disabled && (
          <Fragment>
            <Field
              type="hidden"
              name="services.taxjar.auto_disabled"
              value="false"
            />
            <p>
              <span className="negative note">
                This integration was automatically disabled because your API
                token was rejected by TaxJar. Make sure your credentials are
                correct and TaxJar account is active.
              </span>
            </p>
          </Fragment>
        )}
        {!testEnv && (
          <Field
            type="radio"
            name="services.taxjar.environment"
            options={[
              {
                value: 'production',
                label: 'Production',
              },
              {
                value: 'sandbox',
                label: 'Sandbox',
              },
            ]}
            buttons={true}
            defaultValue={record.environment || 'production'}
            required={true}
          />
        )}
        {isProduction ? (
          <Field
            type="text"
            label="Live API token"
            name="services.taxjar.production_api_key"
            defaultValue={record.production_api_key}
            required={true}
            help="Find your API token in the TaxJar account settings under TaxJar API"
          />
        ) : (
          <Fragment>
            <Field
              type="hidden"
              name="services.taxjar.environment"
              value="sandbox"
            />
            <Field
              type="text"
              label="Sandbox API token"
              name="services.taxjar.sandbox_api_key"
              defaultValue={record.sandbox_api_key}
              required={true}
              help="Find your Sandbox API token in the TaxJar account settings under TaxJar API"
            />
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderEditModalFields_mailchimp(values, record) {
    const { mailchimpLists } = this.state;

    return (
      <Fragment>
        {!integrationActivated('mailchimp', record) ? (
          <p>
            Connect your Mailchimp account to automatically synchronize your
            customer emails when they opt-in and out of marketing
            communications.
          </p>
        ) : (
          <Fragment>
            <p>
              Your Mailchimp account is connected{' '}
              {record.list_name ? (
                <span>
                  with <b>{record.list_name}</b>
                </span>
              ) : null}
            </p>
            {mailchimpLists && mailchimpLists.length > 0 ? (
              <Fragment>
                {values.list_id && (
                  <Field
                    type="hidden"
                    name="services.mailchimp.list_name"
                    value={get(
                      find(
                        mailchimpLists,
                        (list) => list.id === values.list_id,
                      ),
                      'name',
                    )}
                  />
                )}
                <Field
                  type="select"
                  label="Audience"
                  name="services.mailchimp.list_id"
                  defaultValue={get(
                    record,
                    'mailchimp.list_id',
                    get(mailchimpLists, '[0].id', ''),
                  )}
                  required={true}
                  hint="Choose the audience to sync email subscribers to"
                  options={map(mailchimpLists, (list) => ({
                    value: list.id,
                    label: list.name,
                  }))}
                />
              </Fragment>
            ) : (
              <div className="alert alert-info">
                We're unable to retrieve your Mailchimp{' '}
                <a
                  href="https://mailchimp.com/help/getting-started-audience"
                  target="blank"
                >
                  Audiences
                </a>
                . To use this integration, check your internet connection and
                make sure you have at least one Audience for us to sync
                subscribers with.
                <br />
                <br />
                <a href="" onClick={this.onClickRefreshMailchimpLists}>
                  Refresh audience list
                </a>
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderEditModalFields_slack(values, record) {
    return (
      <Fragment>
        {!integrationActivated('slack', record) ? (
          <p>
            Connect your Slack workspace and get notified when selected store
            events happen.
          </p>
        ) : (
          <Fragment>
            <p>
              Your store is sending events to <b>{record.channel}</b> channel in
              the <b>{record.workspace}</b> workspace.
            </p>
            <FieldEvents
              name="services.slack.events.$set"
              defaultValue={get(values.events, '$set', values.events)}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderEditModalFields_segment(values, record) {
    return (
      <Fragment>
        <p>
          Use Segment send events to other integrated platforms such as Google
          Analytics, Intercom, HubSpot and{' '}
          <a href="https://segment.com/product/connections/" target="blank">
            more
          </a>
          .
        </p>
        {!integrationActivated('segment', record) && (
          <Fragment>
            <Field type="hidden" name="services.segment.enabled" value="true" />
            <Field
              type="hidden"
              name="services.segment.activated"
              value="true"
            />
          </Fragment>
        )}
        <Field
          type="text"
          label="Segment write key"
          name="services.segment.write_key"
          defaultValue={values.write_key}
          required={true}
          help="Find and enter the write key from your Segment account"
        />
        <FieldEvents
          name="services.segment.events.$set"
          defaultValue={get(values.events, '$set', values.events)}
        />
      </Fragment>
    );
  }

  renderEditModalFields_shipstation(values, record) {
    const api_key = record['api_key'] ? record['api_key'] : record['apiKey'];
    const api_secret = record['api_secret']
      ? record['api_secret']
      : record['apiSecret'];
    return (
      <Fragment>
        <p>
          Use ShipStation to fulfill orders and synchronize shipments with
          Swell.
        </p>
        {!integrationActivated('shipstation', record) && (
          <Fragment>
            <p>
              <b>To get started,</b>
              <a
                href="https://ship12.shipstation.com/settings/api"
                target="blank"
              >
                {' '}
                navigate to your ShipStation API settings,
              </a>{' '}
              retrieve your API keys and enter them below.
            </p>
            <Field
              type="hidden"
              name="services.shipstation.enabled"
              value="true"
            />
            <Field
              type="hidden"
              name="services.shipstation.activated"
              value="true"
            />
            <Field
              type="hidden"
              name="services.shipstation.sync_status"
              value=""
            />
            <Field
              type="hidden"
              name="services.shipstation.sync_error"
              value=""
            />
          </Fragment>
        )}{' '}
        <Field
          label="API key"
          name="services.shipstation.api_key"
          defaultValue={record ? api_key : ''}
        />
        <Field
          label="API secret"
          name="services.shipstation.api_secret"
          defaultValue={record ? api_secret : ''}
        />
        {record.selling_channels && (
          <Field
            type="select"
            label="Selling channel"
            name="services.shipstation.selling_channels.picked"
            defaultValue={get(record, 'selling_channels.picked')}
            hint="Choose the selling channel to sync the orders"
            options={map(record.selling_channels.options, (list) => ({
              value: list.id,
              label: list.name,
            }))}
          />
        )}
      </Fragment>
    );
  }
  renderEditModalFields_hubspot(values, record) {
    return (
      <Fragment>
        {!integrationActivated('hubspot', record) ? (
          <Fragment>
            <p>
              Connect your HubSpot account to automatically synchronize
              customers, orders, and product details.
            </p>

            <Field type="hidden" name="services.hubspot.sync_status" value="" />
            <Field type="hidden" name="services.hubspot.sync_error" value="" />
            <Field type="hidden" name="services.hubspot.enabled" value="true" />
            <Field
              type="hidden"
              name="services.hubspot.activated"
              value="true"
            />
          </Fragment>
        ) : (
          <Fragment>
            <p>Your HubSpot account is connected</p>
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderEditModalFields_algolia(values, record) {
    return (
      <Fragment>
        <p>
          Optimize your store's search and discovery by syncing your Swell data
          with Algolia search tools.{' '}
          <a
            href="https://www.swell.is/help/integrations/algolia"
            target="blank"
          >
            Read more
          </a>
        </p>
        {!integrationActivated('algolia', record) && (
          <Fragment>
            <p>
              <b>To get started</b>,{' '}
              <a
                href="https://www.algolia.com/apps/RXJUL5E7Q8/api-keys/all"
                target="blank"
              >
                navigate to your Algolia API keys
              </a>
              , retrieve your <b>Admin API key</b> and <b>Application ID</b>,
              and enter them below:
            </p>
            <Field type="hidden" name="services.algolia.enabled" value="true" />
            <Field
              type="hidden"
              name="services.algolia.activated"
              value="true"
            />
            <Field type="hidden" name="services.algolia.sync_status" value="" />
            <Field type="hidden" name="services.algolia.sync_error" value="" />
          </Fragment>
        )}{' '}
        <Field
          label="Application ID"
          name="services.algolia.api_application_id"
          defaultValue={record ? record['api_application_id'] : ''}
        />
        <Field
          label="Admin API key"
          name="services.algolia.api_admin_key"
          defaultValue={record ? record['api_admin_key'] : ''}
        />
      </Fragment>
    );
  }

  renderEditModalFields_yotpo(values, record) {
    return (
      <Fragment>
        <p>
          Power customer reviews, ratings, loyalty programs, and referral
          incentives within your Swell storefront through Yotpo Reviews and
          Yotpo Loyalty.
        </p>
        {!integrationActivated('yotpo', record) && (
          <Fragment>
            <Field type="hidden" name="services.yotpo.enabled" value="true" />
            <Field type="hidden" name="services.yotpo.activated" value="true" />
            <Field type="hidden" name="services.yotpo.sync_status" value="" />
            <Field type="hidden" name="services.yotpo.sync_error" value="" />
          </Fragment>
        )}{' '}
        <p>
          <b>Yotpo Reviews</b>
        </p>
        <p>
          Utilize Yotpo Reviews to track and promote customer reviews and
          product ratings. To get started,{' '}
          <a
            href="https://settings.yotpo.com/#/general_settings"
            target="blank"
          >
            navigate to your Yotpo Reviews settings
          </a>
          , retrieve your credentials and enter them below.
        </p>
        <Field
          label="Store ID (app key)"
          name="services.yotpo.store_id"
          defaultValue={record ? record.store_id : ''}
          help="Your account app key is found in the Yotpo Reviews & Ratings dashboard"
        />
        <Field
          label="Secret key"
          name="services.yotpo.secret_key"
          defaultValue={record ? record.secret_key : ''}
          help="Your account secret key is found in the Yotpo Reviews & Ratings dashboard"
        />
        <p />
        <p style={{ paddingTop: '15px' }}>
          <b>Yotpo Loyalty (Optional)</b>
        </p>
        <p>
          This feature is available for <b>premium</b> Yotpo accounts. Use it to
          implement customized loyalty and referral programs for your customers.
          To get started,{' '}
          <a href="https://loyalty.yotpo.com/general-settings" target="blank">
            navigate to your Yotpo Loyalty settings
          </a>
          , retrieve your credentials, and enter them below:
        </p>
        <Field
          label="GUID"
          name="services.yotpo.guid"
          defaultValue={record ? record.guid : ''}
          help="Your account GUID is found in the Yotpo Loyalty dashboard"
        />
        <Field
          label="API key"
          name="services.yotpo.api_key"
          defaultValue={record ? record.api_key : ''}
          help="Your account API key is found in the Yotpo Loyalty dashboard"
        />
      </Fragment>
    );
  }

  renderEditModalFields_sendgrid(values, record) {
    return (
      <Fragment>
        <p>Use SendGrid to deliver your Swell email notifications.</p>
        {!integrationActivated('sendgrid', record) && (
          <Fragment>
            <Field
              type="hidden"
              name="services.sendgrid.enabled"
              value="true"
            />
            <Field
              type="hidden"
              name="services.sendgrid.activated"
              value="true"
            />
            <p>
              <b>To get started</b>,{' '}
              <a href="https://signup.sendgrid.com/" target="blank">
                sign up for SendGrid
              </a>{' '}
              and return to this page to enter your API credentials.
            </p>
          </Fragment>
        )}
        <Field
          type="text"
          label="API key"
          name={`services.sendgrid.api_key`}
          defaultValue={record.api_key}
          required={true}
        />
      </Fragment>
    );
  }

  renderEditModalFields_gladly(values, record) {
    return (
      <Fragment>
        <p>Use Gladly to synchronize your Swell data.</p>
        {!integrationActivated('gladly', record) && (
          <Fragment>
            <Field type="hidden" name="services.gladly.enabled" value="true" />
            <Field
              type="hidden"
              name="services.gladly.activated"
              value="true"
            />
            <p>
              <b>To get started</b>,{' '}
              <a
                href="https://developer.gladly.com/rest/#section/Getting-Started/Creating-API-Tokens"
                target="blank"
              >
                create an API token
              </a>{' '}
              and return to this page to enter your API credentials.
            </p>
          </Fragment>
        )}
        <Field
          type="text"
          label="Gladly URL"
          name={`services.gladly.api_url`}
          defaultValue={record.api_url}
          required={true}
        />
        <Field
          type="text"
          label="Agent email"
          name={`services.gladly.agent_email`}
          defaultValue={record.agent_email}
          required={true}
        />
        <Field
          type="text"
          label="API key"
          name={`services.gladly.api_key`}
          defaultValue={record.api_key}
          required={true}
        />
      </Fragment>
    );
  }

  renderEditModalFields_smartystreets(values, record) {
    return (
      <Fragment>
        <p>
          Use SmartyStreets for US address autocomplete for checkout and
          customer forms.
        </p>
        {!integrationActivated('smartystreets', record) && (
          <Fragment>
            <Field
              type="hidden"
              name="services.smartystreets.enabled"
              value="true"
            />
            <Field
              type="hidden"
              name="services.smartystreets.activated"
              value="true"
            />
            <p>
              <b>To get started</b>,{' '}
              <a href="https://www.smartystreets.com/" target="blank">
                sign up for SmartyStreets
              </a>{' '}
              and return to this page to enter your API credentials.
            </p>
          </Fragment>
        )}
        <Field
          type="text"
          label="Auth ID"
          name={`services.smartystreets.auth_id`}
          defaultValue={record.auth_id}
          required={true}
        />
        <Field
          type="text"
          label="Auth Token"
          name={`services.smartystreets.auth_token`}
          defaultValue={record.auth_token}
          required={true}
        />
      </Fragment>
    );
  }

  renderIcon(id) {
    let icon;
    try {
      icon = require(`../icon/integration/${id}.svg`);
    } catch (err) {
      icon = require('../logo/swell-purple-logomark.svg');
    }

    return (
      <img src={icon} alt={id} className={`settings-integrations-icon ${id}`} />
    );
  }

  render() {
    const { onSubmitForm, onChangeForm, integrationSync } = this.props;
    const { account } = this.context;

    let anyActivated = false;
    const values = {};
    const activated = {};
    for (const service of INTEGRATIONS) {
      values[service.id] = get(this.props.values.services, service.id, {});
      activated[service.id] = integrationActivated(
        service.id,
        values[service.id],
      );
      anyActivated = anyActivated || activated[service.id];
    }

    return (
      <div className="settings settings-integrations">
        <Form ref="form" onSubmit={onSubmitForm} onChange={onChangeForm}>
          <View
            detail={true}
            smaller={false}
            uri="/settings"
            sectionTitle="Settings"
            headerTitle="Integrations"
            headerSubtitle="Connect your store to 3rd party services and apps"
            headerActions={
              undefined /*[
              anyActivated && {
                label: 'Save changes',
                type: edited ? 'default' : 'secondary',
                submit: true,
              },
            ]*/
            }
          >
            <fieldset className="full last-child">
              <Field
                type="hidden"
                name="services"
                defaultValue={this.props.values.services}
              />
              <FlipMove className="settings-integrations-list">
                {INTEGRATIONS.slice()
                  .sort(
                    ({ id: a, comingsoon: ca }, { id: b, comingsoon: cb }) =>
                      (!ca && cb) ||
                      (values[a].enabled && !values[b].enabled) ||
                      (values[a].activated && !values[b].activated)
                        ? -1
                        : 1,
                  )
                  .map(
                    ({
                      id,
                      name,
                      renderConnected,
                      renderVisible,
                      description,
                      comingsoon,
                      externallink,
                    }) =>
                      (!renderVisible || renderVisible(values[id])) && (
                        <div key={id} className="settings-integrations-box box">
                          <div className="box-title">
                            {this.renderIcon(id)}
                            {name}
                          </div>

                          <div className="box-subtitle">{description}</div>

                          <div className="settings-integrations-actions settings-integrations-with-help-link">
                            {activated[id] ? (
                              values[id].auto_disabled ? (
                                <a
                                  href=""
                                  onClick={this.onClickEdit}
                                  data-id={id}
                                  className="button button-sm button-danger"
                                >
                                  Auto-disabled
                                  <Help
                                    message={`Auto-disabled due to a failing connection with ${name}. Edit your ${name} settings to continue.`}
                                    dir="down"
                                  />
                                </a>
                              ) : (
                                <Fragment>
                                  <a
                                    href=""
                                    onClick={this.onClickEdit}
                                    data-id={id}
                                    className="button button-sm button-secondary no-margin-button"
                                  >
                                    Settings
                                  </a>
                                  <div className="settings-integrations-sync-status">
                                    {get(integrationSync[id], 'status') ===
                                    'processing' ? (
                                      <Fragment>
                                        Syncing
                                        <Loading className="loading-icon" />
                                      </Fragment>
                                    ) : (
                                      get(integrationSync[id], 'status') ===
                                        'failed' && (
                                        <span className="negative">
                                          Sync failed
                                        </span>
                                      )
                                    )}
                                  </div>
                                  {values[id].enabled ? (
                                    <div className="settings-integrations-status-badge badge enabled">
                                      Enabled
                                    </div>
                                  ) : (
                                    <div className="settings-integrations-status-badge badge disabled">
                                      Disabled
                                    </div>
                                  )}
                                </Fragment>
                              )
                            ) : externallink ? (
                              <a
                                href={externallink}
                                target="blank"
                                className="button button-sm button-secondary"
                              >
                                Learn more
                                <Icon fa="external-link" />
                              </a>
                            ) : comingsoon ? (
                              <div className="settings-integrations-comingsoon muted alert alert-info">
                                Coming soon
                              </div>
                            ) : (
                              <a
                                href=""
                                onClick={this.onClickEdit}
                                data-id={id}
                                className="button button-sm button-default"
                              >
                                Enable
                              </a>
                            )}
                            <HelpLink
                              isIntegration={true}
                              overrideKey={
                                'settings.integrations.section_' +
                                name.toLowerCase().replace(/\s/g, '_')
                              }
                            />
                          </div>
                        </div>
                      ),
                  )}
              </FlipMove>
              {/*<div className="box">
                <div className="box-title">Suggest an integration</div>
                <div className="box-subtitle">
                  <a href="" onClick={this.onClickChat}>
                    Chat with us
                  </a>{' '}
                  about your preferred integrations
                </div>
              </div>*/}
            </fieldset>
          </View>
        </Form>
        {this.state.showEditModal && this.renderEditModal()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationSettings);
