import api from 'services/api';
import auth from 'services/auth';
import segment from 'services/segment';

import { getCookie, setCookie } from 'utils/cookie';
import { executeLoginRecaptcha } from 'utils/recaptcha';

import account from './account';
import client from './client';
import session from './session';

export const USER_FETCH = 'user/fetch';
export const USER_LOGIN = 'user/login';
export const USER_LOGIN_FORUM = 'user/loginForum';
export const USER_LOGOUT = 'user/logout';
export const USER_RECOVER = 'user/recover';
export const USER_UPDATE = 'user/update';
export const USER_UPLOAD_PHOTO = 'user/uploadPhoto';
export const USER_FETCH_INVITED = 'user/fetchInvited';
export const USER_UPDATE_INVITED = 'user/updateInvited';
export const USER_UPDATE_LOCALES = 'user/updateLocales';
export const USER_UPDATE_VIEWS = 'user/updateViews';
export const USER_UPDATE_PREFERENCES = 'user/updatePreferences';
export const USER_UPDATE_CURRENCIES = 'user/updateCurrencies';
export const USER_LOADING = 'user/loading';
export const USER_FETCH_TYPEFORM = 'user/fetchTypeform';
export const USER_PUT_SENDGRID_CONTACT = 'user/putSendgridContact';

export default {
  fetch() {
    return {
      type: USER_FETCH,
      payload: api.get('/user'),
    };
  },

  login(username, password, options = { recaptcha: true }) {
    return async (dispatch, getState) => {
      const { client } = getState();
      const recaptcha = options.recaptcha
        ? await executeLoginRecaptcha(client)
        : null;

      return dispatch({
        type: USER_LOGIN,
        payload: api
          .post('/user/login', {
            username,
            password,
            recaptcha,
          })
          .then((result) => {
            if (result && result.session_id) {
              auth.setLoggedInSessionId(result.session_id, result.client_id);
            }
            return result;
          })
          .catch((message) => ({ error: { message } })),
      });
    };
  },

  loginForum(username, password, token) {
    return async (dispatch) => {
      const recaptcha = await executeLoginRecaptcha();

      return dispatch({
        type: USER_LOGIN_FORUM,
        payload: api
          .post('/user/login-forum', {
            username,
            password,
            recaptcha,
            token,
          })
          .catch((message) => ({ error: { message } })),
      });
    };
  },

  logout() {
    return {
      type: USER_LOGOUT,
      payload: api.post('/user/logout'),
    };
  },

  recover({ email, password, resetKey }) {
    return {
      type: USER_RECOVER,
      payload: api
        .post('/user/recover', {
          email,
          password,
          reset_key: resetKey,
        })
        .catch((err) => {
          return { error: { message: err.message } };
        }),
    };
  },

  update(data) {
    return {
      type: USER_UPDATE,
      payload: api.put('/user', data),
    };
  },

  updatePreferences(data) {
    return {
      type: USER_UPDATE_PREFERENCES,
      payload: api.put('/user/preferences', data),
    };
  },

  uploadPhoto(image) {
    return {
      type: USER_UPLOAD_PHOTO,
      payload: api.post(`/data/:files`, {
        data: {
          $binary: image.data.replace(/data:.*?base64,/, ''),
          $type: '00',
        },
        content_type: image.type,
        filename: image.filename,
        width: image.width,
        height: image.height,
      }),
      meta: { image },
    };
  },

  performLogin(username, password, options) {
    return (dispatch) => {
      return dispatch(this.login(username, password, options)).then(
        (result) => {
          if (result) {
            return result.error
              ? result
              : Promise.all([
                  dispatch(client.fetch()),
                  dispatch(session.fetch()),
                  dispatch(account.fetchPlan()),
                  dispatch(client.fetchEnvironments()),
                ])
                  .then(() => dispatch(this.fetch()))
                  .then(() => result);
          }
          return null;
        },
      );
    };
  },

  performLogout() {
    return (dispatch) => {
      return dispatch(this.logout()).then(() => {
        if (window?.Intercom) {
          // shut down intercom chat after logout
          window.Intercom('shutdown'); 
        }
        return dispatch(session.fetch());
      });
    };
  },

  fetchInvited(inviteKey) {
    return {
      type: USER_FETCH_INVITED,
      payload: api.get(`/user/invited/${inviteKey}`),
    };
  },

  updateInvited(inviteKey, data) {
    return {
      type: USER_UPDATE_INVITED,
      payload: api.post(`/user/invited/${inviteKey}`, data),
    };
  },

  switchAccount(id, uri = undefined) {
    return (_dispatch, getState) => {
      const beforeClient = getState().client;
      return api
        .post('/user/switch', {
          client_id: id,
        })
        .then((result) => {
          if (result && result.session_id) {
            segment.track('User switched stores', {
              id: result.id,
              username: result.username,
              client_id: result.client_id,
              client_name: result.client_name,
              from_client_name: beforeClient.name,
            });
            auth.setLoggedInSessionId(result.session_id, result.client_id);
            auth.redirectClientSessionTo(uri || '/');
          }
          return result;
        });
    };
  },

  setLocaleCodes(nextCodes) {
    setCookie('_locale_codes', nextCodes);
    return {
      type: USER_UPDATE_LOCALES,
      payload: nextCodes,
    };
  },

  setCurrencyCodes(nextCodes) {
    setCookie('_currency_codes', nextCodes);
    return {
      type: USER_UPDATE_CURRENCIES,
      payload: nextCodes,
    };
  },

  setContentView(view) {
    const contentViews = getCookie('_content_views') || {};
    contentViews[view.collection] = contentViews[view.collection] || {};
    contentViews[view.collection][view.type] = view.gid;
    setCookie('_content_views', contentViews);
    return {
      type: USER_UPDATE_VIEWS,
      payload: contentViews,
    };
  },

  fetchTypeform(query) {
    return {
      type: USER_FETCH_TYPEFORM,
      payload: api.get(`/user/typeform`, query),
    };
  },

  putSendgridContact(query) {
    return {
      type: USER_PUT_SENDGRID_CONTACT,
      payload: api.put(`/user/sendgrid`, query),
    };
  },
};

export const initialState = {
  email: null,
  name: null,
  nameInitials: null,
  firstName: null,
  lastName: null,
  invited: null,
  authorizations: [],
  localeCodes: [],
  currencyCodes: [],
  preferences: {},
  contentViews: {},
  loading: false,
  typeform: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case USER_FETCH:
    case USER_UPDATE:
    case USER_UPLOAD_PHOTO:
      if (action.payload.errors) {
        return state;
      }
      return calculateUser({
        ...state,
        ...action.payload,
      });

    case USER_LOGIN:
      if (action.payload === null) {
        return state;
      }
      if (action.payload.error) {
        return state;
      }
      return calculateUser({
        ...state,
        ...action.payload,
      });

    case USER_LOGOUT:
      return {};

    case USER_FETCH_INVITED:
      return {
        ...state,
        invited: action.payload,
      };

    case USER_UPDATE_LOCALES:
      return {
        ...state,
        localeCodes: action.payload,
      };

    case USER_UPDATE_CURRENCIES:
      return {
        ...state,
        currencyCodes: action.payload,
      };

    case USER_UPDATE_PREFERENCES:
      return {
        ...state,
        preferences: action.payload,
      };

    case USER_UPDATE_VIEWS:
      return {
        ...state,
        contentViews: action.payload,
      };

    case USER_FETCH_TYPEFORM:
      return {
        ...state,
        typeform: action.payload,
      };

    default:
      return state;
  }
}

export function getLocaleCodes() {
  try {
    const localeCodes = getCookie('_locale_codes');
    return localeCodes || [];
  } catch (err) {
    return [];
  }
}

export function getCurrencyCodes() {
  try {
    const currencyCodes = getCookie('_currency_codes');
    return currencyCodes || [];
  } catch (err) {
    return [];
  }
}

export function getContentViews() {
  try {
    const contentViews = getCookie('_content_views');
    return contentViews || {};
  } catch (err) {
    return {};
  }
}

function calculateUser(user) {
  return {
    ...user,
    ...getNameFields(user),
    localeCodes: getLocaleCodes(),
    currencyCodes: getCurrencyCodes(),
    contentViews: getContentViews(),
  };
}

export function getNameFields(user) {
  const userName = String(
    user.name || user.email?.replace(/\+/g, ' ').split('@').shift(),
  );
  const parts = userName.split(' ');
  return {
    name: userName,
    nameInitials: `${parts[0][0]}${parts[1] ? parts[1][0] : ''}`.toUpperCase(),
    firstName: parts.shift(),
    lastName: parts.join(' '),
  };
}
