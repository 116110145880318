import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

export default function FadeInPop({
  children,
  scale = 0.98,
  origin = undefined,
  duration = 100,
  initDisplayStyle = 'block',
  ...props
}) {
  const transformStart = `scale(${scale})`;
  const transitionStyles = {
    defaultStyle: {
      opacity: 0,
      transform: transformStart,
      transformOrigin: origin,
      display: 'none',
    },
    enterInitStyle: {
      opacity: 0,
      transform: transformStart,
      display: initDisplayStyle,
      transformOrigin: origin,
    },
    enterStyle: {
      opacity: transit(1, duration, 'ease-out'),
      transform: transit('scale(1)', duration, 'ease-out'),
      transformOrigin: origin,
    },
    leaveStyle: {
      opacity: transit(0, duration, 'ease-in'),
      transform: transit(transformStart, duration, 'ease-in'),
      transformOrigin: origin,
    },
    activeStyle: {},
    active: true,
    transitionAppear: true,
  };
  return (
    <CSSTransition {...transitionStyles} {...props}>
      {children}
    </CSSTransition>
  );
}
