// @flow
import api from '../services/api';

export const FILES_FETCH_COLLECTION = 'files/fetchCollection';
export const FILES_LOAD_MORE = 'files/loadMore';
export const FILES_UPLOAD = 'files/upload';
export const FILES_UPLOAD_IMAGE = 'files/uploadImage';
export const FILES_LOADING = 'files/loading';

const actions = {
  fetchCollection(query: Object) {
    return {
      type: FILES_FETCH_COLLECTION,
      payload: api.get('/data/:files', query),
      meta: { query },
    };
  },

  loadMore(query: Object) {
    return {
      type: FILES_LOAD_MORE,
      payload: api.get('/data/:files', query),
      meta: { query },
    };
  },

  uploadFile(file: Object) {
    return {
      type: FILES_UPLOAD,
      payload: api.post('/data/:files', {
        data: {
          $binary: file.data.replace(/data:.*?base64,/, ''),
          $type: '00',
        },
        content_type: file.type,
        filename: file.filename,
      }),
      meta: { file },
    };
  },

  uploadImage(image: Object) {
    return {
      type: FILES_UPLOAD_IMAGE,
      payload: api.post('/data/:files', {
        data: {
          $binary: image.data.replace(/data:.*?base64,/, ''),
          $type: '00',
        },
        content_type: image.type,
        filename: image.filename,
        width: image.width,
        height: image.height,
      }),
      meta: { image },
    };
  },
};

export default actions;

export const initialState = {
  collection: null,
  errors: {},
  loading: false,
};

export function reducer(state: Object = initialState, action: Object) {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case FILES_FETCH_COLLECTION:
      return {
        ...state,
        ...action.meta,
        collection: {
          ...action.payload,
          totalPages: Math.ceil(action.payload.count / action.meta.query.limit),
        },
      };

    case FILES_LOAD_MORE:
      return {
        ...state,
        ...action.meta,
        collection: {
          ...state.collection,
          results: [...state.collection.results, ...action.payload.results],
          page: action.payload.page,
          pages: {
            ...(state.collection.pages || {}),
            ...(action.payload.pages || {}),
          },
          totalPages: Math.ceil(action.payload.count / action.meta.query.limit),
        },
      };

    case FILES_UPLOAD:
      if (action.payload.errors) {
        return {
          ...state,
          errors: action.payload.errors,
        };
      }
      return state;

    case FILES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}
