import React from 'react';
import currencies from 'currency-formatter/currencies.json';
import some from 'lodash/some';
import pt from 'prop-types';

import { nl2br, imageUrl } from 'utils';

import { filters as productFilters } from 'containers/Products';
import View from 'components/view';
import { Form, TabView } from 'components/form';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';
import Collection from 'containers/Collection';
import CollectionEmpty from 'components/collection/empty';
import { fields as productFields } from 'containers/Products';
import WithViewPreferences from 'containers/WithViewPreferences';
import ContentRecordTab from 'components/content/record-tab';

import AttributeForm from './form';

export default class AttributeEdit extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    location: pt.object,
    collection: pt.object,
    errors: pt.object,
    edited: pt.bool,

    onChangeForm: pt.func,
    onSubmitRecord: pt.func,
    onClickDelete: pt.func,
  };

  /** @type {React.RefObject<Form>} */
  formRef = React.createRef();

  formatCurrency(value, currency) {
    const currencyFormats = currencies[currency] || {};

    const code = currencyFormats.code || 'USD';
    const symbol = currencyFormats.symbol || '$';
    const thousandsSeparator = currencyFormats.thousandsSeparator || ',';
    const decimalSeparator = currencyFormats.decimalSeparator || '.';

    const currencyValue = value
      .toString()
      .replace('.', decimalSeparator)
      .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

    return `${symbol} ${currencyValue} ${code}`;
  }

  renderProductValue(value, currency) {
    const { record } = this.props;

    switch (record.type) {
      case 'textarea':
        return nl2br(value);

      case 'image': {
        const images = Array.isArray(value) ? value : [value];

        return (
          value && (
            <span className="collection-table-images">
              {images.map(
                (image) =>
                  image.file && (
                    <span className="image" key={image.file.id}>
                      <img
                        title={image.caption || ''}
                        alt={image.caption || ''}
                        src={imageUrl(image, {
                          width: 45,
                          height: 45,
                          padded: true,
                        })}
                      />
                    </span>
                  ),
              )}
            </span>
          )
        );
      }

      case 'file': {
        const files = value instanceof Array ? value : [value];

        return (
          value && (
            <span className="collection-table-files">
              {files.map(
                (file) =>
                  file && (
                    <Tooltip key={file.id} message={file.filename || file.id}>
                      <span className="image">
                        <Icon fa="file" faType="light" faSize="lg" />
                      </span>
                    </Tooltip>
                  ),
              )}
            </span>
          )
        );
      }

      case 'currency': {
        if (Array.isArray(value)) {
          return (
            <ul className="view-body-tags">
              {value.map((val) => (
                <li key={val}>{this.formatCurrency(val, currency)}</li>
              ))}
            </ul>
          );
        }

        return this.formatCurrency(value, currency);
      }

      case 'text':
      case 'dropdown':
      case 'select':
      case 'checkbox':
      case 'radio':
      default: {
        if (Array.isArray(value)) {
          return (
            <ul className="view-body-tags">
              {value.map((val) => (
                <li key={val}>{val}</li>
              ))}
            </ul>
          );
        }

        return value;
      }
    }
  }

  onClickSave = (event) => {
    event.preventDefault();
    this.formRef.current.submit();
  };

  // define if collection was filtered
  hasCollectionFilters = () => {
    const { query } = this.props.location;
    if (!query) {
      return false;
    }

    if (query.search) {
      return true;
    }

    if (!productFilters) {
      return false;
    }

    return some(query, (value, key) => productFilters[key]);
  };

  render() {
    const {
      record,
      errors,
      edited,
      location,
      onSubmitRecord,
      onChangeForm,
      onClickDelete,
      collection,
      ...props
    } = this.props;

    const isDeleteAllowed =
      Boolean(record.id) && (!collection || !collection.results.length);

    // do not show search box for initially empty collection
    const isSearchable =
      collection?.results?.length > 0 || this.hasCollectionFilters();

    return (
      <WithViewPreferences
        {...this.props}
        tabs={[]}
        model="attributes"
        id="edit"
        type="record"
      >
        <View
          detail={true}
          uri="/attributes"
          devtools={{
            model: 'attributes',
            record,
          }}
          withPreferences={true}
          sectionTitle="Attributes"
          headerTitle={record.name}
          headerActions={[
            {
              label: 'Save attribute',
              type: edited ? 'default' : 'secondary disabled',
              onClick: this.onClickSave,
            },
          ]}
          extraActions={[
            {
              label: 'Delete attribute',
              onClick: onClickDelete,
              disabled: !isDeleteAllowed,
              help: 'Variant options cannot be deleted while there are products using them',
              className: 'danger',
              delete: true,
            },
          ]}
          localized={true}
          tags={[record.variant && 'Variant option']}
        >
          <Form
            ref={this.formRef}
            onSubmit={onSubmitRecord}
            onChange={onChangeForm}
            values={props.values}
          >
            <TabView value={location.query.tab} isDefault>
              <AttributeForm {...props} record={record} />
              {record.id && (
                <div className="collection-child">
                  <Collection
                    {...this.props}
                    readonly={true}
                    childId="attribute_products"
                    headerTitle={`Products with ${record.name}`}
                    title="Products"
                    model="products"
                    filters={productFilters}
                    searchable={isSearchable}
                    fields={{
                      name: productFields.name,
                      status: productFields.status,
                      value: {
                        label: `${record.name} ${
                          record.variant || record.multi ? 'values' : 'value'
                        }`,
                        func: (product) =>
                          product.attributes &&
                          this.renderProductValue(
                            product.attributes[record.id],
                            product.currency,
                          ),
                      },
                    }}
                    query={{
                      $and: [
                        { [`attributes.${record.id}`]: { $exists: true } },
                        { [`attributes.${record.id}`]: { $nin: [null, ''] } },
                      ],
                    }}
                    helpLink={false}
                    renderEmpty={({ collectionEmpty }) => {
                      return (
                        <CollectionEmpty
                          className="with-query"
                          emptyTitle={false}
                          emptyDescription={
                            <span>
                              No products{' '}
                              {collectionEmpty
                                ? 'with this attribute'
                                : 'found'}
                            </span>
                          }
                          emptyAction={false}
                        />
                      );
                    }}
                  />
                </div>
              )}
            </TabView>
            <ContentRecordTab
              {...this.props}
              collection="attributes"
              view="edit"
            />
          </Form>
        </View>
      </WithViewPreferences>
    );
  }
}
