import React from 'react';
import PropTypes from 'prop-types';

import { nl2br } from 'utils';

import Modal from 'components/modal';

export default class ConfirmModal extends React.PureComponent {
  static contextTypes = {
    closeModal: PropTypes.func.isRequired,
  };

  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.any,
    action: PropTypes.string,
    actionType: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    cancel: PropTypes.bool,
    cancelText: PropTypes.string,
    loadingTitle: PropTypes.string,
    width: PropTypes.number,
    actionHidden: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (event) => {
    switch (event.keyCode) {
      case 13: // enter
        this.onClickConfirm(event);
        break;
      case 27: // escape
        this.onClickCancel(event);
        break;
      default:
    }
  };

  onClickConfirm = (event) => {
    event.preventDefault();
    if (this.props.onConfirm) {
      this.setState({ loading: true });
      Promise.resolve(this.props.onConfirm()).then((result) => {
        if (result !== false) {
          this.context.closeModal();
        } else {
          this.setState({ loading: false });
        }
      });
    } else {
      this.context.closeModal();
    }
  };

  onClickCancel = (event) => {
    event.preventDefault();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.context.closeModal();
  };

  render() {
    const {
      title = 'Confirmation',
      message = "Are you sure? This action can't be undone",
      action = 'Confirm',
      actionType = 'default',
      actionHidden = false,
      cancelText = 'Cancel',
      extraActions,
      cancel = true,
      width,
      loadingTitle,
      ...modalProps
    } = this.props;

    return (
      <Modal
        title={title}
        className="modal-confirm"
        actions={[
          actionHidden
            ? null
            : { label: action, type: actionType, onClick: this.onClickConfirm },
          ...(extraActions || []),
          !!cancel && {
            label: cancelText,
            type: 'cancel',
            onClick: this.onClickCancel,
          },
        ]}
        width={width}
        onClose={this.onClickCancel}
        cancel={false}
        closable={true}
        tapout={true}
        loadingTitle={loadingTitle}
        {...modalProps}
        loading={this.state.loading}
      >
        {typeof message === 'string' ? <p>{nl2br(message)}</p> : message}
      </Modal>
    );
  }
}
