import ObjectID from 'bson-objectid';

const csvImporter = {
  importAttribute: (attr, data) => {
    switch (attr.type) {
      // Array types
      case 'multiselect':
      case 'checkbox': {
        if (data.length > 0) {
          return data.split(/\n|\r\n/);
        }

        break;
      }

      case 'image':
      case 'file': {
        if (data.length > 0) {
          if (attr.multi) {
            return data
              .split(/\n|\r\n/)
              .filter((url) => url)
              .map((url) => ({ file: { url } }));
          }

          return { file: { url: data } };
        }

        break;
      }

      default:
        break;
    }

    return data;
  },

  importCategories: (data) => data.split(/\n|\r\n/).filter((url) => url),

  importCategoriesPartsFromPath: (data) => data.split('>'),

  importCategoriesPartName: (data) => data.split(':')[0].trim(),

  importImages: (data) =>
    data
      .split(/\n|\r\n/)
      .filter((url) => url)
      .map((url) => ({ file: { url } })),

  importNewCategories: (data) => data.split(/\n|\r\n/).filter((path) => path),

  importTags: (data) => data.split(/\s*,\s*/),

  importTransformFilesUrls: (data) =>
    data.split(/\n|\r\n/).filter((url) => url.trim()),

  importTransformVariantValueIds: (
    data,
    optionsByName,
    optionsUpdated,
    variantOptions,
  ) =>
    data.variant_options.split(/\n|\r\n/).reduce((list, optionValueData) => {
      if (!String(optionValueData || '').trim()) {
        return list;
      }

      const [opName, valName, valDesc] = optionValueData.split(':');

      const opNameKey = String(opName || '')
        .trim()
        .toLowerCase();

      const valNameKey = String(valName || '')
        .trim()
        .toLowerCase();

      if (!opNameKey || !valNameKey) {
        return list;
      }

      const option = optionsByName[opNameKey];

      if (option) {
        // Existing option
        const value = option.values[valNameKey];

        if (value) {
          // Check if option value description changed
          if (
            (valDesc && !value.description) ||
            (!valDesc && value.description) ||
            (valDesc && value.description && valDesc !== value.description)
          ) {
            const exOption = variantOptions.find(({ id }) => id === option.id);

            if (exOption) {
              const exValue = exOption.values.find(({ id }) => id === value.id);

              if (exValue) {
                optionsUpdated.current = true;

                exValue.description = valDesc ? valDesc.trim() : null;
              }
            }
          }

          list.push(value.id);

          return list;
        }

        // New option value
        optionsUpdated.current = true;

        const optionValueId = ObjectID().toHexString();

        const newOptionValue = {
          id: optionValueId,
          name: valName.trim(),
          description: valDesc ? valDesc.trim() : null,
        };

        option.values[valNameKey] = newOptionValue;

        const exOption = variantOptions.find(({ id }) => id === option.id);

        if (!exOption.values) {
          exOption.values = [];
        }

        exOption.values.push(newOptionValue);

        list.push(optionValueId);

        return list;
      }

      // New option
      optionsUpdated.current = true;

      const optionValueId = ObjectID().toHexString();

      const newOption = {
        id: ObjectID().toHexString(),
        name: opName.trim(),
        variant: true,
        input_type: 'select',
        values: [
          {
            id: optionValueId,
            name: valName.trim(),
            description: valDesc ? valDesc.trim() : null,
          },
        ],
      };

      optionsByName[opNameKey] = {
        ...newOption,
        values: {
          [valNameKey]: newOption.values[0],
        },
      };

      variantOptions.push(newOption);

      list.push(optionValueId);

      return list;
    }, []),

  importVariantsOptionValueIds: (data, optionsByName) =>
    String(data)
      .split(/\n|\r\n/)
      .reduce((list, optionValueData) => {
        const [opName, valName] = optionValueData.split(':');

        const opNameKey = String(opName).trim().toLowerCase();

        const valNameKey = String(valName).trim().toLowerCase();

        if (!opNameKey || !valNameKey) {
          return list;
        }

        if (optionsByName[opNameKey]) {
          // Existing option
          if (optionsByName[opNameKey].values[valNameKey]) {
            // Existing option value
            list.push(optionsByName[opNameKey].values[valNameKey].id);
          }
        }

        return list;
      }, []),

  importBundleItems: (data) => importJSONArray(data),
  importProductOptions: (data) => importJSONArray(data),
  importPurchaseOptions: (data) => importJSONArray(data, undefined),
  importLocaleOptions: (data) => importJSON(data),
};

function importJSON(data) {
  try {
    if (!data) {
      return undefined;
    }

    return JSON.parse(data);
  } catch (err) {
    return undefined;
  }
}

function importJSONArray(data, errorValue = []) {
  try {
    if (!data.trim().startsWith('[')) {
      return data.split(/\n|\r\n/).map((str) => JSON.parse(str));
    }

    return JSON.parse(data);
  } catch (err) {
    return errorValue;
  }
}

export default csvImporter;
