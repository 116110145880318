import React from 'react';
import Icon from 'components/icon';
import DiscountRuleDescription from './rule-description';
import { discountRuleConditions } from 'utils/discount';
import './discount.scss';

export default function DiscountRuleDescriptions({
  record,
  settings,
  categories,
}) {
  const rules = (record.discounts || []).map((rule, index) => {
    const conditions = discountRuleConditions(
      rule,
      record.currency,
      settings,
      categories,
    );
    return (
      <div key={index}>
        <DiscountRuleDescription rule={rule} currency={record.currency} />
        {conditions.length > 0 && (
          <ul className="discount-rule-descriptions-conditions">
            {conditions.map((cond, i) => (
              <li key={i}>
                <Icon fa="check" className="positive" /> {cond}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  });
  if (rules.length > 0) {
    return <div className="discount-rule-descriptions">{rules}</div>;
  }
  return <span className="muted">&mdash;</span>;
}
