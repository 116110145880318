import React from 'react';
import find from 'lodash/find';
import pt from 'prop-types';

import { wordify } from 'shared/utils';
import { contentFieldsAllByModel, collectionLinkTarget } from 'utils/content';

import Table from 'components/collection/table';
import Link from 'components/link';
import Icon from 'components/icon';

export default class AppLocations extends React.PureComponent {
  static propTypes = {
    app: pt.object.isRequired,
    content: pt.object.isRequired,
    appConfigs: pt.object.isRequired,
    installedApp: pt.object.isRequired,
  };

  getContentLocations() {
    const { app, appConfigs, content } = this.props;

    const potentialLocations = [];
    const contentViews = content.viewsByApp[app.id] || [];
    const contentModels = content.modelsByApp[app.id] || [];

    // Content views
    potentialLocations.push(
      ...contentViews.reduce((acc, view) => {
        const linkCollection = view.childCollection || view.collection;
        const linkCollectionModel =
          view.childCollectionModel || view.collectionModel;
        acc.push({
          collection: linkCollection,
          name: linkCollectionModel.singular,
          area: view.type === 'list' ? 'list' : 'details',
          link: collectionLinkTarget({ collection: linkCollection }),
        });
        return acc;
      }, []),
    );

    // Content fields in admin zones
    potentialLocations.push(
      ...contentFieldsAllByModel(
        contentModels,
        (field) => field.admin_zone,
      ).map(({ model, field }) => ({
        collection: model.collection,
        name: model.singular,
        area: field.admin_zone,
        link: `/${model.collection}`,
      })),
    );

    // Notifications
    potentialLocations.push(
      ...appConfigs.results
        .filter((config) => config.type === 'notification')
        .map((config) => ({
          collection: config.values?.label || config.name,
          name: config.values?.label || config.name,
          area: 'notification',
          link: `/settings/notifications?template=app_${app.id}.${config.values.model}.${config.values.name}`,
        })),
    );

    const locations = potentialLocations.reduce((acc, loc) => {
      let location = find(acc, { collection: loc.collection });
      if (!location) {
        location = {
          collection: loc.collection,
          name: loc.name,
          areas: [],
          link: loc.link,
        };
        acc.push(location);
      }
      if (!location.areas.includes(loc.area)) {
        location.areas.push(loc.area);
      }
      return acc;
    }, []);

    return locations;
  }

  renderContentLocation = (location) => {
    return (
      <b>
        {wordify(location.name)}{' '}
        {location.areas.map((area) => area.toLowerCase()).join(', ')}
      </b>
    );
  };

  renderContentLocationLink = (location) => {
    return (
      <b>
        <Link to={location.link}>View location</Link>{' '}
        <Icon fa="external-link" />
      </b>
    );
  };

  render() {
    const { installedApp } = this.props;
    const contentLocations = this.getContentLocations();

    return (
      <Table
        title="Locations"
        titleCount={true}
        subtitle="Where you'll find app elements in the Swell dashboard"
        fields={[
          { label: 'Location', func: this.renderContentLocation },
          installedApp.active !== false && {
            label: '',
            align: 'right',
            func: this.renderContentLocationLink,
          },
        ]}
        rows={contentLocations}
      />
    );
  }
}
