import React from 'react';
import get from 'lodash/get';
import pt from 'prop-types';

import { Field, FieldLocalized } from 'components/form';
import { FadeIn } from 'components/transitions';
import SectionHeader from 'components/section-header/section-header';

import ProductPrices from '../prices';

/**
 * Check if price is higher than sale price
 * @param {object} optionValues
 * @param {object} defaultValues
 * @return {boolean} - true if the rule is satisfied
 */
export function checkSalePriceRule(optionValues, defaultValues) {
  const price = Number(
    optionValues.price ?? defaultValues?.price ?? Number.POSITIVE_INFINITY,
  );

  const salePrice = Number(
    optionValues.sale_price ??
      defaultValues?.sale_price ??
      Number.NEGATIVE_INFINITY,
  );

  return price > salePrice;
}

/**
 * Check if price is higher than sale price for the specified currency
 * @param {string | undefined} currency - currency code or undefined to check the main object
 * @param {object} optionValues
 * @param {object} defaultValues
 * @return {boolean} - true if the rule is satisfied
 */
export function checkSalePriceRuleForCurrency(
  currency,
  optionValues,
  defaultValues,
) {
  // check the main object if no currency provided
  if (!currency) {
    return checkSalePriceRule(optionValues, defaultValues);
  }

  // check prices for the spcified currency
  const optionValue = optionValues?.$currency?.[currency] || {};
  const defaultValue = defaultValues?.$currency?.[currency] || {};

  return checkSalePriceRule(optionValue, defaultValue);
}

export default class StandardPurchaseOption extends React.PureComponent {
  static propTypes = {
    record: pt.object.isRequired,
    currency: pt.string,
    settings: pt.object.isRequired,
    optionValues: pt.object.isRequired,
    variantProps: pt.object,
    getFieldName: pt.func.isRequired,
  };

  render() {
    const {
      record,
      currency,
      settings,
      optionValues,
      variantProps,
      getFieldName,
    } = this.props;

    if (
      variantProps &&
      !get(variantProps, 'values.purchase_options.standard.active')
    ) {
      return null;
    }

    // Handle default active since purchase options introduced Oct 2021
    const defaultActive = Boolean(
      optionValues.active !== undefined
        ? optionValues.active
        : record.delivery !== 'subscription' &&
            record.price !== undefined &&
            get(record, 'purchase_options') === undefined,
    );

    const disabled = !(variantProps || defaultActive);

    return (
      <fieldset className="full">
        <div className="view-body-section">
          <header className="view-body-subheader">
            <SectionHeader
              className="view-body-subtitle"
              title="Standard pricing"
              subtitle={
                !variantProps &&
                'Manage standard pricing with optional price rules'
              }
            />

            {!variantProps && (
              <React.Fragment>
                <div className="view-body-actions">
                  <Field
                    type="toggle"
                    label="Enabled"
                    name={getFieldName('standard.active')}
                    defaultChecked={defaultActive}
                  />
                </div>
              </React.Fragment>
            )}
          </header>

          <FadeIn transitionAppear={false} active={!disabled}>
            <div className="view-section-content">
              <fieldset className="form-field">
                <div className="row">
                  <FieldLocalized
                    type="currency"
                    label="List price"
                    name={getFieldName('standard.price')}
                    defaultValue={
                      optionValues.price !== undefined
                        ? optionValues.price
                        : record.price
                    }
                    localeValue={optionValues.$currency}
                    localeFieldPrefix={getFieldName('standard')}
                    localeFieldName="price"
                    currency={currency}
                    disabled={disabled}
                    placeholder={variantProps && variantProps.pricePlaceholder}
                    localePlaceholder={
                      variantProps && variantProps.priceLocalePlaceholder
                    }
                    className="span1-3rd"
                    help="The default price used when a product is not on sale"
                  />

                  <FieldLocalized
                    type="currency"
                    label={
                      <React.Fragment>
                        Sale price
                        <Field
                          type="toggle"
                          label=" "
                          name={getFieldName('standard.sale')}
                          defaultChecked={
                            optionValues.sale !== undefined
                              ? optionValues.sale
                              : record.sale
                          }
                          help="Enable to override list price and display savings"
                          tabIndex={-1}
                        />
                      </React.Fragment>
                    }
                    name={getFieldName('standard.sale_price')}
                    defaultValue={
                      optionValues.sale_price !== undefined
                        ? optionValues.sale_price
                        : record.sale_price
                    }
                    localeValue={optionValues.$currency}
                    localeFieldPrefix={getFieldName('standard')}
                    localeFieldName="sale_price"
                    currency={currency}
                    disabled={disabled}
                    placeholder={
                      variantProps && variantProps.salePricePlaceholder
                    }
                    localePlaceholder={
                      variantProps && variantProps.salePriceLocalePlaceholder
                    }
                    className="span1-3rd"
                    // currency should be provided for rules
                    hasLocalizedRules
                    rules={[
                      {
                        rule: (currency) => {
                          return checkSalePriceRuleForCurrency(
                            currency,
                            optionValues,
                            variantProps?.values,
                          );
                        },
                        hint: () => 'Should be lower than price',
                      },
                    ]}
                  />

                  <div className="span1-3rd" />
                </div>
              </fieldset>

              <ProductPrices
                name={getFieldName('standard.prices')}
                values={
                  optionValues.prices !== undefined
                    ? optionValues.prices
                    : record.prices
                }
                currency={currency}
                settings={settings}
                disabled={disabled}
              />
            </div>
          </FadeIn>
        </div>
      </fieldset>
    );
  }
}
