import React, { Fragment } from 'react';
import { find } from 'lodash';
import IconPayment from 'components/icon/payment';
import { paymentMethod } from 'utils/order';

export default function Payment({ data, settings, className }) {
  function getMethodName() {
    if (data.card) {
      return data.card.last4;
    }

    const method = paymentMethod(settings, data);

    return (method && method.name) || 'Unknown payment method';
  }

  function getGatewayName() {
    const gateway = find(settings.payments.gateways, { id: data.gateway });
    return gateway.name;
  }

  return (
    <span className="activity-event-payment">
      <IconPayment method={data.method} card={data.card} />
      &ensp;
      <span className={className}>{getMethodName()}</span>
      {data.gateway && (
        <Fragment>
          &nbsp;via <span className={className}>{getGatewayName()}</span>
        </Fragment>
      )}
    </span>
  );
}
