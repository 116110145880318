import React from 'react';
import Link from 'components/link';
import View from 'components/view';
import './settings.scss';

const SECTIONS = [
  {
    to: '/settings/account',
    title: 'Account',
    subtitle: 'Manage your account plan and billing information',
  },
  {
    to: '/settings/general',
    title: 'General',
    subtitle: 'Manage store details such as name and business address',
  },
  {
    to: '/settings/users',
    title: 'Users',
    subtitle: 'Manage users with access to this dashboard',
  },
  {
    to: '/settings/products',
    title: 'Products',
    subtitle: 'Manage settings that affect store products',
  },
  {
    to: '/settings/customers',
    title: 'Customers',
    subtitle: 'Manage settings that affect your customers',
  },
  {
    to: '/settings/orders',
    title: 'Orders',
    subtitle: 'Manage settings that affect orders',
  },
  {
    to: '/settings/payments',
    title: 'Payments',
    subtitle: 'Manage payment gateways and related settings',
  },
  {
    to: '/settings/subscriptions',
    title: 'Subscriptions',
    subtitle: 'Manage settings that affect subscriptions',
  },
  {
    to: '/settings/shipping',
    title: 'Shipping',
    subtitle: 'Manage shipping services, carriers and price rules',
  },
  {
    to: '/settings/taxes',
    title: 'Taxes',
    subtitle: 'Manage tax zones, classes and other rules',
  },
  {
    to: '/settings/checkout',
    title: 'Checkout',
    subtitle: 'Manage the customer checkout experience',
  },
  {
    to: '/settings/giftcards',
    title: 'Gift cards',
    subtitle: 'Manage gift card expiration and formatting',
  },
  {
    to: '/settings/notifications',
    title: 'Notifications',
    subtitle: 'Manage email notifications sent to admins and customers',
  },
];

export default class SettingsIndex extends React.PureComponent {
  render() {
    return (
      <div className="settings settings-index">
        <View
          detail={true}
          smaller={true}
          uri="/settings"
          headerTitle="Settings"
        >
          <div className="settings-index-container">
            {SECTIONS.map((section) => (
              <Link
                key={section.to}
                to={section.to}
                className="settings-index-section"
              >
                <h4 className="settings-index-heading">{section.title}</h4>
                <p className="settings-index-subheading">{section.subtitle}</p>
              </Link>
            ))}
          </div>
        </View>
      </div>
    );
  }
}
