// Adapted from https://github.com/10xjs/locale-emoji
const langToCountry = {
  aa: 'ET',
  ab: 'GE',
  abr: 'GH',
  ace: 'ID',
  ach: 'UG',
  ada: 'GH',
  ady: 'RU',
  ae: 'IR',
  aeb: 'TN',
  af: 'ZA',
  agq: 'CM',
  aho: 'IN',
  ak: 'GH',
  akk: 'IQ',
  aln: 'XK',
  alt: 'RU',
  am: 'ET',
  amo: 'NG',
  aoz: 'ID',
  apd: 'TG',
  ar: 'EG',
  arc: 'IR',
  'arc-Nbat': 'JO',
  'arc-Palm': 'SY',
  arn: 'CL',
  aro: 'BO',
  arq: 'DZ',
  ary: 'MA',
  arz: 'EG',
  as: 'IN',
  asa: 'TZ',
  ase: 'US',
  ast: 'ES',
  atj: 'CA',
  av: 'RU',
  awa: 'IN',
  ay: 'BO',
  az: 'AZ',
  'az-Arab': 'IR',
  ba: 'RU',
  bal: 'PK',
  ban: 'ID',
  bap: 'NP',
  bar: 'AT',
  bas: 'CM',
  bax: 'CM',
  bbc: 'ID',
  bbj: 'CM',
  bci: 'CI',
  be: 'BY',
  bej: 'SD',
  bem: 'ZM',
  bew: 'ID',
  bez: 'TZ',
  bfd: 'CM',
  bfq: 'IN',
  bft: 'PK',
  bfy: 'IN',
  bg: 'BG',
  bgc: 'IN',
  bgn: 'PK',
  bgx: 'TR',
  bhb: 'IN',
  bhi: 'IN',
  bhk: 'PH',
  bho: 'IN',
  bi: 'VU',
  bik: 'PH',
  bin: 'NG',
  bjj: 'IN',
  bjn: 'ID',
  bjt: 'SN',
  bkm: 'CM',
  bku: 'PH',
  blt: 'VN',
  bm: 'ML',
  bmq: 'ML',
  bn: 'BD',
  bo: 'CN',
  bpy: 'IN',
  bqi: 'IR',
  bqv: 'CI',
  br: 'FR',
  bra: 'IN',
  brh: 'PK',
  brx: 'IN',
  bs: 'BA',
  bsq: 'LR',
  bss: 'CM',
  bto: 'PH',
  btv: 'PK',
  bua: 'RU',
  buc: 'YT',
  bug: 'ID',
  bum: 'CM',
  bvb: 'GQ',
  byn: 'ER',
  byv: 'CM',
  bze: 'ML',
  ca: 'ES',
  cch: 'NG',
  ccp: 'BD',
  ce: 'RU',
  ceb: 'PH',
  cgg: 'UG',
  ch: 'GU',
  chk: 'FM',
  chm: 'RU',
  cho: 'US',
  chp: 'CA',
  chr: 'US',
  cja: 'KH',
  cjm: 'VN',
  ckb: 'IQ',
  co: 'FR',
  cop: 'EG',
  cps: 'PH',
  cr: 'CA',
  crh: 'UA',
  crj: 'CA',
  crk: 'CA',
  crl: 'CA',
  crm: 'CA',
  crs: 'SC',
  cs: 'CZ',
  csb: 'PL',
  csw: 'CA',
  ctd: 'MM',
  cu: 'RU',
  'cu-Glag': 'BG',
  cv: 'RU',
  cy: 'GB',
  da: 'DK',
  dak: 'US',
  dar: 'RU',
  dav: 'KE',
  dcc: 'IN',
  de: 'DE',
  den: 'CA',
  dgr: 'CA',
  dje: 'NE',
  dnj: 'CI',
  doi: 'IN',
  dsb: 'DE',
  dtm: 'ML',
  dtp: 'MY',
  dty: 'NP',
  dua: 'CM',
  dv: 'MV',
  dyo: 'SN',
  dyu: 'BF',
  dz: 'BT',
  ebu: 'KE',
  ee: 'GH',
  efi: 'NG',
  egl: 'IT',
  egy: 'EG',
  eky: 'MM',
  el: 'GR',
  en: 'US',
  'en-Shaw': 'GB',
  es: 'ES',
  esu: 'US',
  et: 'EE',
  ett: 'IT',
  eu: 'ES',
  ewo: 'CM',
  ext: 'ES',
  fa: 'IR',
  fan: 'GQ',
  ff: 'SN',
  'ff-Adlm': 'GN',
  ffm: 'ML',
  fi: 'FI',
  fia: 'SD',
  fil: 'PH',
  fit: 'SE',
  fj: 'FJ',
  fo: 'FO',
  fon: 'BJ',
  fr: 'FR',
  frc: 'US',
  frp: 'FR',
  frr: 'DE',
  frs: 'DE',
  fub: 'CM',
  fud: 'WF',
  fuf: 'GN',
  fuq: 'NE',
  fur: 'IT',
  fuv: 'NG',
  fvr: 'SD',
  fy: 'NL',
  ga: 'IE',
  gaa: 'GH',
  gag: 'MD',
  gan: 'CN',
  gay: 'ID',
  gbm: 'IN',
  gbz: 'IR',
  gcr: 'GF',
  gd: 'GB',
  gez: 'ET',
  ggn: 'NP',
  gil: 'KI',
  gjk: 'PK',
  gju: 'PK',
  gl: 'ES',
  glk: 'IR',
  gn: 'PY',
  gom: 'IN',
  gon: 'IN',
  gor: 'ID',
  gos: 'NL',
  got: 'UA',
  grc: 'CY',
  'grc-Linb': 'GR',
  grt: 'IN',
  gsw: 'CH',
  gu: 'IN',
  gub: 'BR',
  guc: 'CO',
  gur: 'GH',
  guz: 'KE',
  gv: 'IM',
  gvr: 'NP',
  gwi: 'CA',
  ha: 'NG',
  hak: 'CN',
  haw: 'US',
  haz: 'AF',
  he: 'IL',
  hi: 'IN',
  hif: 'FJ',
  hil: 'PH',
  hlu: 'TR',
  hmd: 'CN',
  hnd: 'PK',
  hne: 'IN',
  hnj: 'LA',
  hnn: 'PH',
  hno: 'PK',
  ho: 'PG',
  hoc: 'IN',
  hoj: 'IN',
  hr: 'HR',
  hsb: 'DE',
  hsn: 'CN',
  ht: 'HT',
  hu: 'HU',
  hy: 'AM',
  hz: 'NA',
  ia: 'FR',
  iba: 'MY',
  ibb: 'NG',
  id: 'ID',
  ife: 'TG',
  ig: 'NG',
  ii: 'CN',
  ik: 'US',
  ikt: 'CA',
  ilo: 'PH',
  in: 'ID',
  inh: 'RU',
  is: 'IS',
  it: 'IT',
  iu: 'CA',
  iw: 'IL',
  izh: 'RU',
  ja: 'JP',
  jam: 'JM',
  jgo: 'CM',
  ji: 'UA',
  jmc: 'TZ',
  jml: 'NP',
  jut: 'DK',
  jv: 'ID',
  jw: 'ID',
  ka: 'GE',
  kaa: 'UZ',
  kab: 'DZ',
  kac: 'MM',
  kaj: 'NG',
  kam: 'KE',
  kao: 'ML',
  kbd: 'RU',
  kby: 'NE',
  kcg: 'NG',
  kck: 'ZW',
  kde: 'TZ',
  kdh: 'TG',
  kdt: 'TH',
  kea: 'CV',
  ken: 'CM',
  kfo: 'CI',
  kfr: 'IN',
  kfy: 'IN',
  kg: 'CD',
  kge: 'ID',
  kgp: 'BR',
  kha: 'IN',
  khb: 'CN',
  khn: 'IN',
  khq: 'ML',
  kht: 'IN',
  khw: 'PK',
  ki: 'KE',
  kiu: 'TR',
  kj: 'NA',
  kjg: 'LA',
  kk: 'KZ',
  'kk-Arab': 'CN',
  kkj: 'CM',
  kl: 'GL',
  kln: 'KE',
  km: 'KH',
  kmb: 'AO',
  kn: 'IN',
  knf: 'SN',
  ko: 'KR',
  koi: 'RU',
  kok: 'IN',
  kos: 'FM',
  kpe: 'LR',
  krc: 'RU',
  kri: 'SL',
  krj: 'PH',
  krl: 'RU',
  kru: 'IN',
  ks: 'IN',
  ksb: 'TZ',
  ksf: 'CM',
  ksh: 'DE',
  ku: 'TR',
  'ku-Arab': 'IQ',
  kum: 'RU',
  kv: 'RU',
  kvr: 'ID',
  kvx: 'PK',
  kw: 'GB',
  kxm: 'TH',
  kxp: 'PK',
  ky: 'KG',
  'ky-Arab': 'CN',
  'ky-Latn': 'TR',
  la: 'VA',
  lab: 'GR',
  lad: 'IL',
  lag: 'TZ',
  lah: 'PK',
  laj: 'UG',
  lb: 'LU',
  lbe: 'RU',
  lbw: 'ID',
  lcp: 'CN',
  lep: 'IN',
  lez: 'RU',
  lg: 'UG',
  li: 'NL',
  lif: 'NP',
  'lif-Limb': 'IN',
  lij: 'IT',
  lis: 'CN',
  ljp: 'ID',
  lki: 'IR',
  lkt: 'US',
  lmn: 'IN',
  lmo: 'IT',
  ln: 'CD',
  lo: 'LA',
  lol: 'CD',
  loz: 'ZM',
  lrc: 'IR',
  lt: 'LT',
  ltg: 'LV',
  lu: 'CD',
  lua: 'CD',
  luo: 'KE',
  luy: 'KE',
  luz: 'IR',
  lv: 'LV',
  lwl: 'TH',
  lzh: 'CN',
  lzz: 'TR',
  mad: 'ID',
  maf: 'CM',
  mag: 'IN',
  mai: 'IN',
  mak: 'ID',
  man: 'GM',
  'man-Nkoo': 'GN',
  mas: 'KE',
  maz: 'MX',
  mdf: 'RU',
  mdh: 'PH',
  mdr: 'ID',
  men: 'SL',
  mer: 'KE',
  mfa: 'TH',
  mfe: 'MU',
  mg: 'MG',
  mgh: 'MZ',
  mgo: 'CM',
  mgp: 'NP',
  mgy: 'TZ',
  mh: 'MH',
  mi: 'NZ',
  min: 'ID',
  mis: 'IQ',
  mk: 'MK',
  ml: 'IN',
  mls: 'SD',
  mn: 'MN',
  'mn-Mong': 'CN',
  mni: 'IN',
  mnw: 'MM',
  moe: 'CA',
  moh: 'CA',
  mos: 'BF',
  mr: 'IN',
  mrd: 'NP',
  mrj: 'RU',
  mro: 'BD',
  ms: 'MY',
  mt: 'MT',
  mtr: 'IN',
  mua: 'CM',
  mus: 'US',
  mvy: 'PK',
  mwk: 'ML',
  mwr: 'IN',
  mwv: 'ID',
  mxc: 'ZW',
  my: 'MM',
  myv: 'RU',
  myx: 'UG',
  myz: 'IR',
  mzn: 'IR',
  na: 'NR',
  nan: 'CN',
  nap: 'IT',
  naq: 'NA',
  nb: 'NO',
  nch: 'MX',
  nd: 'ZW',
  ndc: 'MZ',
  nds: 'DE',
  ne: 'NP',
  new: 'NP',
  ng: 'NA',
  ngl: 'MZ',
  nhe: 'MX',
  nhw: 'MX',
  nij: 'ID',
  niu: 'NU',
  njo: 'IN',
  nl: 'NL',
  nmg: 'CM',
  nn: 'NO',
  nnh: 'CM',
  no: 'NO',
  nod: 'TH',
  noe: 'IN',
  non: 'SE',
  nqo: 'GN',
  nr: 'ZA',
  nsk: 'CA',
  nso: 'ZA',
  nus: 'SS',
  nv: 'US',
  nxq: 'CN',
  ny: 'MW',
  nym: 'TZ',
  nyn: 'UG',
  nzi: 'GH',
  oc: 'FR',
  om: 'ET',
  or: 'IN',
  os: 'GE',
  osa: 'US',
  otk: 'MN',
  pa: 'IN',
  'pa-Arab': 'PK',
  pag: 'PH',
  pal: 'IR',
  'pal-Phlp': 'CN',
  pam: 'PH',
  pap: 'AW',
  pau: 'PW',
  pcd: 'FR',
  pcm: 'NG',
  pdc: 'US',
  pdt: 'CA',
  peo: 'IR',
  pfl: 'DE',
  phn: 'LB',
  pka: 'IN',
  pko: 'KE',
  pl: 'PL',
  pms: 'IT',
  pnt: 'GR',
  pon: 'FM',
  pra: 'PK',
  prd: 'IR',
  ps: 'AF',
  pt: 'BR',
  puu: 'GA',
  qu: 'PE',
  quc: 'GT',
  qug: 'EC',
  raj: 'IN',
  rcf: 'RE',
  rej: 'ID',
  rgn: 'IT',
  ria: 'IN',
  rif: 'MA',
  rjs: 'NP',
  rkt: 'BD',
  rm: 'CH',
  rmf: 'FI',
  rmo: 'CH',
  rmt: 'IR',
  rmu: 'SE',
  rn: 'BI',
  rng: 'MZ',
  ro: 'RO',
  rob: 'ID',
  rof: 'TZ',
  rtm: 'FJ',
  ru: 'RU',
  rue: 'UA',
  rug: 'SB',
  rw: 'RW',
  rwk: 'TZ',
  ryu: 'JP',
  sa: 'IN',
  saf: 'GH',
  sah: 'RU',
  saq: 'KE',
  sas: 'ID',
  sat: 'IN',
  sav: 'SN',
  saz: 'IN',
  sbp: 'TZ',
  sc: 'IT',
  sck: 'IN',
  scn: 'IT',
  sco: 'GB',
  scs: 'CA',
  sd: 'PK',
  'sd-Deva': 'IN',
  'sd-Khoj': 'IN',
  'sd-Sind': 'IN',
  sdc: 'IT',
  sdh: 'IR',
  se: 'NO',
  sef: 'CI',
  seh: 'MZ',
  sei: 'MX',
  ses: 'ML',
  sg: 'CF',
  sga: 'IE',
  sgs: 'LT',
  shi: 'MA',
  shn: 'MM',
  si: 'LK',
  sid: 'ET',
  sk: 'SK',
  skr: 'PK',
  sl: 'SI',
  sli: 'PL',
  sly: 'ID',
  sm: 'WS',
  sma: 'SE',
  smj: 'SE',
  smn: 'FI',
  smp: 'IL',
  sms: 'FI',
  sn: 'ZW',
  snk: 'ML',
  so: 'SO',
  sou: 'TH',
  sq: 'AL',
  sr: 'RS',
  srb: 'IN',
  srn: 'SR',
  srr: 'SN',
  srx: 'IN',
  ss: 'ZA',
  ssy: 'ER',
  st: 'ZA',
  stq: 'DE',
  su: 'ID',
  suk: 'TZ',
  sus: 'GN',
  sv: 'SE',
  sw: 'TZ',
  swb: 'YT',
  swc: 'CD',
  swg: 'DE',
  swv: 'IN',
  sxn: 'ID',
  syl: 'BD',
  syr: 'IQ',
  szl: 'PL',
  ta: 'IN',
  taj: 'NP',
  tbw: 'PH',
  tcy: 'IN',
  tdd: 'CN',
  tdg: 'NP',
  tdh: 'NP',
  te: 'IN',
  tem: 'SL',
  teo: 'UG',
  tet: 'TL',
  tg: 'TJ',
  'tg-Arab': 'PK',
  th: 'TH',
  thl: 'NP',
  thq: 'NP',
  thr: 'NP',
  ti: 'ET',
  tig: 'ER',
  tiv: 'NG',
  tk: 'TM',
  tkl: 'TK',
  tkr: 'AZ',
  tkt: 'NP',
  tl: 'PH',
  tly: 'AZ',
  tmh: 'NE',
  tn: 'ZA',
  to: 'TO',
  tog: 'MW',
  tpi: 'PG',
  tr: 'TR',
  tru: 'TR',
  trv: 'TW',
  ts: 'ZA',
  tsd: 'GR',
  tsf: 'NP',
  tsg: 'PH',
  tsj: 'BT',
  tt: 'RU',
  ttj: 'UG',
  tts: 'TH',
  ttt: 'AZ',
  tum: 'MW',
  tvl: 'TV',
  twq: 'NE',
  txg: 'CN',
  ty: 'PF',
  tyv: 'RU',
  tzm: 'MA',
  udm: 'RU',
  ug: 'CN',
  'ug-Cyrl': 'KZ',
  uga: 'SY',
  uk: 'UA',
  uli: 'FM',
  umb: 'AO',
  und: 'US',
  unr: 'IN',
  'unr-Deva': 'NP',
  unx: 'IN',
  ur: 'PK',
  uz: 'UZ',
  'uz-Arab': 'AF',
  vai: 'LR',
  ve: 'ZA',
  vec: 'IT',
  vep: 'RU',
  vi: 'VN',
  vic: 'SX',
  vls: 'BE',
  vmf: 'DE',
  vmw: 'MZ',
  vot: 'RU',
  vro: 'EE',
  vun: 'TZ',
  wa: 'BE',
  wae: 'CH',
  wal: 'ET',
  war: 'PH',
  wbp: 'AU',
  wbq: 'IN',
  wbr: 'IN',
  wls: 'WF',
  wni: 'KM',
  wo: 'SN',
  wtm: 'IN',
  wuu: 'CN',
  xav: 'BR',
  xcr: 'TR',
  xh: 'ZA',
  xlc: 'TR',
  xld: 'TR',
  xmf: 'GE',
  xmn: 'CN',
  xmr: 'SD',
  xna: 'SA',
  xnr: 'IN',
  xog: 'UG',
  xpr: 'IR',
  xsa: 'YE',
  xsr: 'NP',
  yao: 'MZ',
  yap: 'FM',
  yav: 'CM',
  ybb: 'CM',
  yo: 'NG',
  yrl: 'BR',
  yua: 'MX',
  yue: 'HK',
  'yue-Hans': 'CN',
  za: 'CN',
  zag: 'SD',
  zdj: 'KM',
  zea: 'NL',
  zgh: 'MA',
  zh: 'CN',
  'zh-Bopo': 'TW',
  'zh-Hanb': 'TW',
  'zh-Hant': 'TW',
  zlm: 'TG',
  zmi: 'MY',
  zu: 'ZA',
  zza: 'TR',
};

export function localeCountry(locale) {
  const split = locale.toUpperCase().split(/-|_/);
  const lang = split.shift();

  let country = split.pop();
  if (!/^[A-Z]{2}$/.test(country)) {
    country = langToCountry[lang.toLowerCase()];
  }

  return country;
}
