import Alert from 'components/alert/alert';
import Icon from 'components/icon/icon';
import SectionHeader from 'components/section-header/section-header';
import React, { Fragment } from 'react';
import FrameworkIcons from './framework-icons';
import HostingIcons from './hosting-icons';

const StorefrontDocs = () => {
  return (
    <Fragment>
      <fieldset>
        <Alert>
          <Icon fa="info-circle" faType="solid" />
          &nbsp; Build a headless storefront with your preferred tech stack by
          following the guides below
        </Alert>
        <div className="view-body-subheader">
          <SectionHeader
            className="view-body-subtitle"
            title="Technologies"
            subtitle={
              <span>
                We recommend choosing a modern framework to build your
                storefront. Read our documentation for code examples and check
                out Swell’s original storefront apps{' '}
                <a
                  href="https://github.com/swellstores/origin-theme/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Origin
                </a>{' '}
                and{' '}
                <a
                  href="https://github.com/swellstores/horizon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Horizon
                </a>{' '}
                for more inspiration.
              </span>
            }
          />
        </div>

        <div className="storefront-docs-section">
          <FrameworkIcons
            frameworks={[
              'vuejs',
              'react',
              'nuxtjs',
              'nextjs',
              'svelte',
              'nodejs',
              'php',
            ]}
          />

          <div>
            <a
              href="https://developers.swell.is/frontend-api/"
              target="_blank"
              className="view-link"
              rel="noreferrer"
            >
              View frontend docs
            </a>
            &nbsp;&nbsp;&nbsp;
            <a
              href="https://developers.swell.is/backend-api/"
              target="_blank"
              className="view-link"
              rel="noreferrer"
            >
              View backend docs
            </a>
          </div>
        </div>
      </fieldset>
      <fieldset className="last-child">
        <div className="view-body-subheader">
          <SectionHeader
            className="view-body-subtitle"
            title="Hosting"
            subtitle="We recommend hosting your storefront with one of the leading JAMstack providers. Get an API key from the Developer menu and connect directly to Swell. Once your storefront is up and running, return to configure its URL in the settings."
          />
        </div>

        <div className="storefront-docs-section">
          <HostingIcons
            hostingProviders={['vercel', 'netlify', 'cloudflare', 'heroku']}
          />

          <div>
            <a
              href="https://developers.swell.is/storefronts/storefront-hosting/"
              target="_blank"
              className="view-link"
              rel="noreferrer"
            >
              View hosting docs
            </a>
          </div>
        </div>
      </fieldset>
    </Fragment>
  );
};

export default StorefrontDocs;
