import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import each from 'lodash/each';
import cloneDeep from 'lodash/cloneDeep';
import intersection from 'lodash/intersection';

import api from 'services/api';
import actions from 'actions';

import { slugify, isValueEqual, isEmpty } from 'utils';
import { getCleanItemOptions } from 'utils/order';
import { hasQuantityMaxInIndexKey, getQuantityMaxValue } from 'utils/product';
import {
  cleanPurchaseOptions,
  isBundleItemVariable,
  mapCategoryValuesFromIndex,
  transformCombinedOptions,
  getCleanOptions,
} from 'utils/product';
import {
  contentExpandsDeprecated,
  contentUpdatesDeprecated,
} from 'utils/content';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';
import {
  SUBSCRIPTION_INTERVAL_OPTIONS,
  getDefaultSubscriptionPlan,
} from 'utils/subscription';

import LoadingView from 'components/view/loading';
import NotFoundPage from 'components/pages/error/404';
import EditPage from 'components/pages/product/edit';

const DEFAULT_LIMIT = 25;

export const mapStateToProps = (state) => ({
  data: state.data,
  loading: state.data.loading,
  record: state.data.record,
  related: state.data.related,
  errors: state.data.recordErrors,
  categories: state.categories,
  content: state.content,
  lookup: state.lookup,
  attributes: state.attributes,
  settings: state.settings,
  bulk: state.data.bulk,
  storefronts: state.storefronts,
});

export const mapDispatchToProps = (dispatch) => ({
  async fetchRecord(id, variantPage = 1, limit = DEFAULT_LIMIT) {
    const contentDeprecated = await dispatch(
      actions.content.loadFieldsDeprecated('products'),
    );

    return dispatch(
      actions.data.fetchRecord('products', id, {
        expand: [
          'bundle_items.product',
          'bundle_items.variant',
          'up_sells.product',
          'cross_sells.product',
          ...(contentDeprecated
            ? contentExpandsDeprecated(contentDeprecated.results)
            : []),
        ],
        include: {
          variants: {
            url: '/products:variants',
            params: {
              parent_id: 'id',
            },
            data: {
              sort: 'name asc',
              page: variantPage,
              limit,
              archived: { $ne: true },
            },
          },
          categories: {
            url: '/categories',
            params: {
              id: { $in: 'category_index.id' },
            },
            data: {
              fields: 'name',
              limit: null,
            },
          },
          categoryProducts: {
            url: '/categories:products?product_id={id}',
            data: {
              limit: null,
            },
          },
          purchaseLinks: {
            url: '/purchaselinks',
            params: { 'items.product_id': 'id' },
          },
        },
      }),
    ).then(async (result) => {
      if (!result) {
        return result;
      }

      await dispatch(actions.data.fetchIncludedContent(['variants'], result));

      if (result.type === 'giftcard' && result.delivery !== 'giftcard') {
        dispatch(
          actions.data.fetchRelated(id, {
            giftcards_by_amount: {
              url: '/giftcards',
              data: {
                where: {
                  product_id: result.id,
                },
                group: {
                  amount: 1,
                  count: { $sum: 1 },
                },
                $currency: null,
              },
            },
          }),
        );
      }

      return result;
    });
  },

  fetchVariants(id, page, limit) {
    return api
      .getLocalized('/data/products:variants', {
        sort: 'name asc',
        page,
        limit,
        archived: { $ne: true },
        parent_id: id,
      })
      .then((results) => {
        dispatch(actions.data.patchRecord({ variants: results }));
        return results;
      })
      .catch((err) => console.error(err));
  },

  fetchProductStock(productId, query) {
    return dispatch(
      actions.data.fetchRelated(productId, {
        stock: {
          url: '/products:stock',
          data: {
            sort: 'date_created desc',
            parent_id: productId,
            ...(query || {}),
          },
        },
      }),
    );
  },

  loadCategories() {
    return dispatch(actions.categories.load());
  },

  fetchCategories() {
    return dispatch(actions.categories.fetch());
  },

  searchCategories(search) {
    return dispatch(actions.categories.search(search, 10));
  },

  loadAttributesByIds(ids) {
    return dispatch(actions.attributes.fetchByIds(ids));
  },

  addAttributeToStore(attr) {
    return dispatch(actions.attributes.addToStore(attr));
  },

  updateAttributeInStore(attr) {
    return dispatch(actions.attributes.updateInStore(attr));
  },

  createRecord(data) {
    return dispatch(actions.data.createRecord('products', data));
  },

  updateRecord(id, data) {
    return dispatch(actions.data.updateRecord('products', id, data));
  },

  deleteRecord(id) {
    return dispatch(actions.data.deleteRecord('products', id));
  },

  async deleteCategoryProducts(ids) {
    if (!Array.isArray(ids) || ids.length <= 0) {
      return;
    }

    await Promise.all(
      ids.map((id) =>
        dispatch(actions.data.deleteRecord('categories:products', id)),
      ),
    );
  },

  notifySavedOptions() {
    return dispatch(actions.flash.success(`Options saved successfully`));
  },

  bulkGenerateGiftCards(id, counts, description) {
    return dispatch(
      actions.data.bulkGenerateGiftCards(id, counts, description),
    );
  },

  bulkCancel() {
    return dispatch(actions.data.bulkCancel());
  },

  createPurchaseLink(name, item) {
    return dispatch(
      actions.data.createRecord('purchaselinks', {
        name,
        items: [item],
        active: true,
      }),
    );
  },

  loadSettings() {
    return Promise.all([
      dispatch(actions.settings.fetch('products')),
      dispatch(actions.settings.fetch('accounts')),
      dispatch(actions.settings.fetch('shipments')),
      dispatch(actions.settings.fetch('integrations')),
    ]);
  },
});

// Export for testing only
export function prepareVariantStockForUpdate(variants, record) {
  return map(variants, (variant, key) => {
    const origVariant = find(record.variants.results, { id: key });

    if (origVariant) {
      if (variant.stock_level || typeof variant.stock_level === 'number') {
        const stockLevel = Number.parseInt(variant.stock_level, 10) || 0;
        const origStockLevel = origVariant.stock_level || 0;

        if (stockLevel !== origStockLevel) {
          // Stock adjustment
          return {
            quantity:
              typeof origVariant.stock_level === 'number'
                ? stockLevel - origVariant.stock_level
                : stockLevel,
            parent_id: record.id,
            variant_id: origVariant.id,
          };
        }
      }
    }
  }).filter(Boolean);
}

function getTabState(props, state) {
  return {
    tab: props.location.query.tab || 'details',
    tabs: {
      ...state.tabs,
      [props.location.query.tab || 'details']: true,
    },
  };
}

export class EditProduct extends React.PureComponent {
  static propTypes = {
    data: pt.object,
    record: pt.object,
    params: pt.object,
    router: pt.object,
    location: pt.object,
    settings: pt.object,
    content: pt.object,
    categories: pt.object,
    attributes: pt.object,

    fetchRecord: pt.func,
    fetchVariants: pt.func,
    fetchProductStock: pt.func,
    loadSettings: pt.func,
    loadCategories: pt.func,
    fetchCategories: pt.func,
    loadAttributesByIds: pt.func,
    addAttributeToStore: pt.func,
    searchCategories: pt.func,
    notifySavedOptions: pt.func,
    createRecord: pt.func,
    createPurchaseLink: pt.func,
    updateRecord: pt.func,
    deleteCategoryProducts: pt.func,
    deleteRecord: pt.func,
    bulkGenerateGiftCards: pt.func,
    bulkCancel: pt.func,
  };

  static contextTypes = {
    account: pt.object.isRequired,
    client: pt.object.isRequired,
    notifyError: pt.func.isRequired,
    notifySuccess: pt.func.isRequired,
    notifyCreated: pt.func.isRequired,
    notifyDeleted: pt.func.isRequired,
    uploadImages: pt.func.isRequired,
    openModal: pt.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loaded: false,
      values: {},
      valuesEdited: false,
      variantValues: {},
      variantEdited: false,
      edited: false,
      tab: null,
      tabs: {},
      editingOptions: null,
      editingVariant: null,
      editingVariantImages: null,
      creatingPurchaseLink: false,
      attributeSet: null,
      attributeSetName: null,
      attributeSetAttributes: [],
      attributeValues: {},
      attributeSorted: false,
      cloneLoading: false,
      cloneProgressMessage: null,
      cloneProgressStep: null,
      cloneProgressStepTotal: null,
      collection: {},
      variants: null,
      giftcardGenerating: false,
      onSubmitRecord: this.onSubmitRecord.bind(this),
      onSubmitClone: this.onSubmitClone.bind(this),
      onTriggerTabChange: this.onTriggerTabChange.bind(this),
      onQueryCategories: this.onQueryCategories.bind(this),
      onClickEditOptions: this.onClickEditOptions.bind(this),
      onClickEditVariant: this.onClickEditVariant.bind(this),
      onSaveOptions: this.onSaveOptions.bind(this),
      onSubmitEditVariant: this.onSubmitEditVariant.bind(this),
      onSubmitVariantOptions: this.onSubmitVariantOptions.bind(this),
      onSubmitCustomOptions: this.onSubmitCustomOptions.bind(this),
      onSubmitCombinedOptions: this.onSubmitCombinedOptions.bind(this),
      onClickVariantImages: this.onClickVariantImages.bind(this),
      onSubmitVariantImages: this.onSubmitVariantImages.bind(this),
      onClickCreatePurchaseLink: this.onClickCreatePurchaseLink.bind(this),
      onSubmitPurchaseLink: this.onSubmitPurchaseLink.bind(this),
      onClickDelete: this.onClickDelete.bind(this),
      onChangeForm: this.onChangeForm.bind(this),
      onChangeVariantForm: this.onChangeVariantForm.bind(this),
      onAddAttribute: this.onAddAttribute.bind(this),
      onUpdateAttribute: this.onUpdateAttribute,
      onRemoveAttribute: this.onRemoveAttribute.bind(this),
      onChangeAttributeValue: this.onChangeAttributeValue.bind(this),
      onSortAttributes: this.onSortAttributes.bind(this),
      onChangeStockTracking: this.onChangeStockTracking.bind(this),
      onAdjustInventory: this.onAdjustInventory.bind(this),
      onLoadInventoryHistory: this.onLoadInventoryHistory.bind(this),
      onSubmitGenerateMoreGiftcards:
        this.onSubmitGenerateMoreGiftcards.bind(this),
      onClickPage: this.onClickPage.bind(this),
      onChangeLimit: this.onChangeLimit.bind(this),
    };

    this.tabChangeListeners = [];
  }

  componentDidMount() {
    const {
      params,
      fetchRecord,
      loadSettings,
      loadCategories,
      loadAttributesByIds,
    } = this.props;

    confirmRouteLeave(this);

    loadSettings()
      .then(() =>
        Promise.all([
          fetchRecord(params.id).then(async (record) => {
            const attrs = Object.keys(record?.attributes ?? {});
            if (attrs.length > 0) {
              await loadAttributesByIds(attrs);
            }
            return record;
          }),
          loadCategories(),
        ]),
      )
      .then(([record, categories]) => {
        this.timeMounted = Date.now();

        if (!record) {
          this.setState({ loaded: true });
          return;
        }

        const { variants } = record;
        const collection =
          variants && variants.count > 0
            ? {
                limit: DEFAULT_LIMIT,
                page: 1,
                pages: variants.pages || {},
                count: variants.count,
                totalPages: Object.keys(variants.pages || {}).length || 1,
              }
            : {};

        this.setState((state, props) => ({
          loaded: true,
          collection,
          variants: variants ? variants.results : [],
          ...getTabState(props, state),
          ...this.mapStateFromRecord(record, categories),
        }));
      });
  }

  onTriggerTabChange(callback) {
    //this.tabChangeListeners.push(callback);
  }

  onChangeTab = () => {
    // this.setState({
    //   attributeSorted: false,
    //   valuesEdited: false,
    //   edited: false,
    //   ...this.mapStateFromRecord(this.props.record),
    // }, () => {
    //   for (let callback of this.tabChangeListeners) {
    //     callback();
    //   }
    //   this.tabChangeListeners = [];
    // });
  };

  generatePages(newPage, newLimit) {
    const limit = newLimit || this.state.collection.limit;
    const page = newPage || this.state.collection.page;
    const { record } = this.props;

    this.props
      .fetchVariants(this.props.params.id, page, limit)
      .then((result) => {
        this.setState((state) => {
          const combined_options = [...getCleanOptions(record?.options)];

          return {
            ...state,
            values: {
              ...state.values,
              combined_options,
            },
            variants: result,
            collection: {
              ...state.collection,
              pages: result.pages || {},
              page: newPage,
              limit,
              totalPages: Object.keys(result.pages || {}).length || 1,
              count: result.count,
            },
          };
        });
      });
  }

  onChangeLimit(event) {
    event.preventDefault();
    this.generatePages(1, event.target.realValue);
  }

  onClickPage(event) {
    event.preventDefault();
    const { limit } = this.state.collection;
    this.generatePages(Number(event.currentTarget.dataset.page), limit);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.query.tab !== state.tab) {
      return getTabState(props, state);
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);

    const { record, fetchRecord } = this.props;

    if (this.props.params.id !== prevProps.params.id) {
      this.setState((state, props) => ({
        loaded: false,
        values: {},
        ...getTabState(props, state),
      }));

      fetchRecord(this.props.params.id).then((record) => {
        this.setState({
          loaded: true,
          values: {},
          variantValues: {},
          edited: false,
          valuesEdited: false,
          variantEdited: false,
          attributeSorted: false,
          ...this.mapStateFromRecord(record),
        });
      });
    } else {
      if (record && this.props.attributes !== prevProps.attributes) {
        this.setState((state, props) => ({
          attributeSetAttributes: this.getAttributesFromSet(
            record.attribute_set,
            state.attributeValues,
            props.attributes.index,
          ),
        }));
      }
    }
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  isEdited() {
    return this.state.valuesEdited || this.state.attributeSorted;
  }

  refreshRecord() {
    const { params, fetchRecord } = this.props;
    const { page, limit } = this.state.collection;

    return fetchRecord(params.id, page, limit);
  }

  async refetchAttributes(record, prevRecord) {
    const { loadAttributesByIds } = this.props;

    const beforeAttrs = Object.keys(prevRecord.attributes ?? {});
    const recordAttrs = Object.keys(record.attributes ?? {});

    if (!isValueEqual(recordAttrs, beforeAttrs) && recordAttrs.length > 0) {
      await loadAttributesByIds(recordAttrs);
    }
  }

  async onChangeStockTracking(event, value) {
    const { params, updateRecord } = this.props;

    const currentValue = !!this.state.values.stock_tracking;
    const nextValue = !!value;
    if (nextValue === currentValue) {
      return;
    }

    this.setState((state) => ({
      values: {
        ...state.values,
        stock_tracking: nextValue,
      },
    }));

    const result = await updateRecord(params.id, {
      stock_tracking: nextValue,
    });

    if (result.errors) {
      this.context.notifyError(result.errors);
      return;
    }

    const record = await this.refreshRecord();

    this.setState({
      edited: this.isEdited(),
      ...this.mapStateFromRecord(record),
    });
  }

  onAdjustInventory() {
    this.refreshRecord().then((record) => {
      this.setState({
        ...this.mapStateFromRecord(record),
      });
    });
  }

  async onLoadInventoryHistory(query) {
    const { params, fetchProductStock } = this.props;

    await fetchProductStock(params.id, query);
  }

  refactorPurchaseOptions(data, defaultSubscriptionPlan, isPriceRule = false) {
    data.forEach((item) => {
      if (item.billing_schedule && item.billing_schedule.limit === undefined) {
        item.billing_schedule.limit = null;
      }

      if (defaultSubscriptionPlan.$currency) {
        if (!item.$currency) {
          item.$currency = {};
        }

        for (let c in defaultSubscriptionPlan.$currency) {
          if (!item.$currency[c]) {
            item.$currency[c] = defaultSubscriptionPlan.$currency[c];
          }
        }
      }

      if (defaultSubscriptionPlan.$locale) {
        if (!item.$locale) {
          item.$locale = {};
        }

        for (let c in defaultSubscriptionPlan.$locale) {
          if (!item.$locale[c]) {
            item.$locale[c] = defaultSubscriptionPlan.$locale[c];
          }
        }
      }

      if (!isPriceRule) {
        item.has_order_schedule = !!item.order_schedule;

        if (!item.has_order_schedule) {
          item.order_schedule = defaultSubscriptionPlan.order_schedule;
        }
      }

      if (!isPriceRule && item.billing_schedule.interval) {
        const defaultName = find(SUBSCRIPTION_INTERVAL_OPTIONS, {
          value: item.billing_schedule.interval,
        });
        item.name = (defaultName && defaultName.label) || item.name;
      }

      if (isPriceRule && !item.account_group) {
        item.account_group = '';
      }
    });
  }

  mapStateFromRecord(record, categories) {
    if (!record) {
      return {};
    }

    // add default $currency/$locale values in purchase_options.subscription.plans
    // if multi-currency/multi-language are enabled and there's no
    // $currency/$locale in purchase_options.subscription.plans
    if (
      record.purchase_options &&
      record.purchase_options.subscription &&
      Array.isArray(record.purchase_options.subscription.plans)
    ) {
      const defaultSubscriptionPlan = getDefaultSubscriptionPlan(this.context);

      this.refactorPurchaseOptions(
        record.purchase_options.subscription.plans,
        defaultSubscriptionPlan,
      );
    }

    // add default $currency/$locale values in purchase_options.standard.prices
    // if multi-currency/multi-language are enabled and there's no
    // $currency/$locale in purchase_options.standard.prices
    if (
      record.purchase_options &&
      record.purchase_options.standard &&
      Array.isArray(record.purchase_options.standard.prices)
    ) {
      const defaultSubscriptionPlan = getDefaultSubscriptionPlan(
        this.context,
        false,
      );

      this.refactorPurchaseOptions(
        record.purchase_options.standard.prices,
        defaultSubscriptionPlan,
        true,
      );
    }

    // update name of legacy subscription
    if (
      (!record.purchase_options || !record.purchase_options.subscription) &&
      Array.isArray(record.options)
    ) {
      for (const { subscription, values } of record.options) {
        if (subscription && Array.isArray(values)) {
          for (const item of values) {
            const defaultName = SUBSCRIPTION_INTERVAL_OPTIONS.find(
              (option) => option.value === item.name,
            );

            item.name = (defaultName && defaultName.label) || item.name;
          }
        }
      }
    }

    return {
      values: {
        ...this.state.values,
        ...cloneDeep(record),
        ...this.ensureOptions(record),
        ...this.ensureBundleItemOptions(record),
        customizable:
          filter(record.options, (option) => !option.variant).length > 0,
        categoryValues: mapCategoryValuesFromIndex(
          record.categories,
          categories ? categories.index : this.props.categories.index,
        ),
      },
      attributeSetAttributes: this.getAttributesFromSet(
        record.attribute_set,
        record.attributes,
        this.props.attributes.index,
      ),
      attributeValues: this.mapDeprecatedAttributes(record.attributes),
    };
  }

  ensureOptions({ options, purchase_options, delivery }) {
    const purchaseOptions = purchase_options || {};

    // Detect and map deprecated subscription option to new purchase option
    let deprecated_subscription_option = find(options, { subscription: true });
    if (isEmpty(get(deprecated_subscription_option, 'values'))) {
      // Only if it has values (plans)
      deprecated_subscription_option = undefined;
    } else if (!purchaseOptions.subscription && delivery !== 'subscription') {
      // Only if purchase option doesn't already exist
      const defaultSubscriptionPlan = getDefaultSubscriptionPlan(this.context);

      purchaseOptions.subscription = {
        active: true,
        plans: map(deprecated_subscription_option.values, (value) => {
          const refactoredValue = {
            name: value.name,
            description: value.description,
            price: value.price,
            billing_schedule: {
              interval: value.subscription_interval,
              interval_count: value.subscription_interval_count,
              trial_days: value.subscription_trial_days,
              limit: null,
            },
            has_order_schedule: false,
            order_schedule: {
              interval: SUBSCRIPTION_INTERVAL_OPTIONS[0].value,
              interval_count: 1,
              limit: '',
            },
          };

          if (defaultSubscriptionPlan.$currency) {
            refactoredValue.$currency = value.$currency || {};

            for (let c in defaultSubscriptionPlan.$currency) {
              if (!refactoredValue.$currency[c]) {
                refactoredValue.$currency[c] =
                  defaultSubscriptionPlan.$currency[c];
              }
            }
          }

          if (defaultSubscriptionPlan.$locale) {
            refactoredValue.$locale = value.$locale || {};

            for (let c in defaultSubscriptionPlan.$locale) {
              if (!refactoredValue.$locale[c]) {
                refactoredValue.$locale[c] = defaultSubscriptionPlan.$locale[c];
              }
            }
          }

          return refactoredValue;
        }),
      };
    }

    const cleanOptions = getCleanOptions(options);
    const custom_options = filter(cleanOptions, (op) => !op.variant);
    const variant_options = filter(cleanOptions, (op) => op.variant);
    const combined_options = [...cleanOptions];
    return {
      options: cleanOptions,
      custom_options,
      variant_options,
      combined_options,
      deprecated_subscription_option,
      purchase_options: purchaseOptions,
    };
  }

  ensureBundleItemOptions({ bundle_items }) {
    if (!bundle_items) {
      return {};
    }
    for (let bundleItem of bundle_items) {
      if (bundleItem.variant && bundleItem.product && !bundleItem.options) {
        bundleItem.options = bundleItem.product.options
          .filter((option) => option.variant)
          .map((option) => ({
            id: option.id,
            value: get(
              find(
                option.values,
                (val) =>
                  bundleItem.variant.option_value_ids.indexOf(val.id) !== -1,
              ),
              'id',
            ),
          }));
      }
    }
    return { bundle_items };
  }

  mapDeprecatedAttributes(attrs) {
    if (!attrs || typeof attrs !== 'object') {
      return {};
    }
    for (const key of Object.keys(attrs)) {
      if (
        attrs[key] &&
        typeof attrs[key] === 'object' &&
        'value' in attrs[key]
      ) {
        attrs[key] = attrs[key].value;
      }
    }
    return attrs;
  }

  setTypeFields({ type, bundle_items }) {
    // TODO: refactor once we implement custom product types
    switch (type) {
      case 'bundle': {
        const hasStandardItem =
          Array.isArray(bundle_items) &&
          bundle_items.some(
            ({ product }) =>
              product &&
              (product.type === 'standard' || product.delivery === 'shipment'),
          );

        return {
          bundle: true,
          delivery: hasStandardItem ? 'shipment' : null,
          virtual: true,
        };
      }

      default:
        return {};
    }
  }

  onChangeForm(values, edited) {
    const { record } = this.props;
    const combined_options = [...getCleanOptions(record?.options)];

    this.setState((state) => ({
      values: {
        ...state.values,
        ...values,
        combined_options,
      },
      valuesEdited: edited,
      edited: !state.editingVariant && (edited || state.attributeSorted),
    }));
  }

  onChangeVariantForm(values, edited) {
    this.setState((state) => ({
      variantValues: {
        ...state.variantValues,
        ...values,
      },
      variantEdited: edited,
    }));
  }

  onClickDelete(event) {
    event.preventDefault();
    this.context.openModal('ConfirmDelete', {
      title: this.props.record.name,
      onConfirm: () => {
        const { params, router, deleteRecord } = this.props;
        deleteRecord(params.id).then((result) => {
          if (result && !result.errors) {
            this.setState({ edited: false }, () => {
              router.replace('/products');
              this.context.notifyDeleted('Product');
            });
          }
        });
      },
    });
  }

  onChangeAttributeSet(event) {
    const { value } = event.currentTarget;

    this.setState((state, { record, settings, attributes }) => ({
      attributeSet: value,
      attributeSetName:
        value && find(settings.products.attribute_sets, { id: value }).name,
      attributeSetAttributes: this.getAttributesFromSet(
        record.attribute_set,
        record.attributes,
        attributes.index,
      ),
    }));
  }

  onAddAttribute(attr) {
    if (!this.props.attributes.index.has(attr.id)) {
      this.props.addAttributeToStore(attr);
    }

    this.setState((state) => ({
      attributeSetAttributes: [...state.attributeSetAttributes, attr.id],
      attributeValues: {
        ...state.attributeValues,
        [attr.id]: attr.default || null,
      },
    }));
  }

  onUpdateAttribute = (attr) => {
    this.props.updateAttributeInStore(attr);
  };

  onRemoveAttribute(attrId) {
    const attributeValues = { ...this.state.attributeValues };

    delete attributeValues[attrId];

    this.setState((state) => ({
      attributeSetAttributes: state.attributeSetAttributes.filter(
        (attr) => attr !== attrId,
      ),
      attributeValues,
    }));
  }

  onSortAttributes(sourceId, targetId) {
    const sorted = [...this.state.attributeSetAttributes];
    const sourceIndex = sorted.indexOf(sourceId);
    const targetIndex = sorted.indexOf(targetId);

    sorted.splice(targetIndex, 0, sorted.splice(sourceIndex, 1)[0]);

    const attributeValues = sorted.reduce((attrs, attrId) => {
      if (this.state.attributeValues[attrId] !== undefined) {
        attrs[attrId] = this.state.attributeValues[attrId];
      }

      return attrs;
    }, {});

    const origSorted = this.getAttributesFromSet(
      this.props.record.attribute_set,
      this.props.record.attributes,
      this.props.attributes.index,
    );

    const attributeSorted = !isEqual(sorted, origSorted);

    this.setState((state) => ({
      attributeSetAttributes: sorted,
      attributeSorted,
      attributeValues,
      edited: state.valuesEdited || attributeSorted,
    }));
  }

  /**
   * @param {string} attributeSetId
   * @param {object} recordAttributes
   * @param {Map<string, object>} attributeIndex
   * @returns {Array<object>}
   */
  getAttributesFromSet(attributeSetId, recordAttributes = {}, attributeIndex) {
    const { settings } = this.props;

    const attributeSet = find(settings.products.attribute_sets, {
      id: attributeSetId,
    });

    if (attributeSet) {
      return (attributeSet.attributes || []).filter((key) =>
        attributeIndex.has(key),
      );
    }

    if (recordAttributes) {
      return Object.keys(recordAttributes).filter((key) =>
        attributeIndex.has(key),
      );
    }

    return [];
  }

  onChangeAttributeValue(event, value) {
    const attrId = event.target?.name;

    if (!attrId) {
      return;
    }

    this.setState((state) => {
      const attributeValues = { ...state.attributeValues };

      set(attributeValues, attrId, value);

      return { attributeValues };
    });
  }

  async onSubmitRecord(values) {
    const {
      data,
      params,
      categories,
      updateRecord,
      deleteCategoryProducts,
      fetchCategories,
    } = this.props;

    this.setState({ editingOptions: false });

    await this.populateBundleItemVariants(values);

    const beforeRecord = { ...data.record };

    const result = await updateRecord(params.id, {
      ...values,
      ...this.getUpdates(data, values),
    });

    if (result) {
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        await deleteCategoryProducts(
          this.getCategoryProductDeletes(values.categoryValues),
        );

        const record = await this.refreshRecord();

        await this.refetchAttributes(record, beforeRecord);

        if (
          // if new categories were created
          record.categories.results.some(
            (category) => !categories.index.has(category.id),
          )
        ) {
          await fetchCategories();
        }

        this.setState({
          edited: false,
          valuesEdited: false,
          attributeSorted: false,
          ...this.mapStateFromRecord(record),
        });
      }
    }
  }

  async populateBundleItemVariants(values) {
    if (isEmpty(values.bundle_items)) {
      return;
    }
    for (let bundleItem of values.bundle_items) {
      if (!bundleItem.product) {
        continue;
      }
      if (isBundleItemVariable(bundleItem)) {
        bundleItem.variant_id = null;
        bundleItem.variant = undefined;
      } else if (bundleItem.options) {
        const exOptionIds =
          bundleItem.variant && bundleItem.variant.option_value_ids.sort();
        const newOptionIds = bundleItem.options
          .map((option) => option.value)
          .sort();
        if (!isEqual(exOptionIds, newOptionIds)) {
          bundleItem.variant = await api.get('/data/products:variants/:first', {
            parent_id: bundleItem.product.id,
            archived: { $ne: true },
            option_value_ids: {
              $all: bundleItem.options.map((option) => option.value),
            },
          });
        }
      }
    }
  }

  getCloneProgressStepTotal() {
    const { record } = this.props;
    let cloneProgressStepTotal = 2;

    if (record.images && record.images.length) {
      cloneProgressStepTotal += 1;
    }

    if (record.variants.results && record.variants.results.length) {
      cloneProgressStepTotal += 1;
    }

    return cloneProgressStepTotal;
  }

  onSubmitClone(values) {
    const { router, record, createRecord } = this.props;

    const cloneProgressStepTotal = this.getCloneProgressStepTotal();

    this.setState({
      cloneLoading: true,
      cloneProgressMessage: 'Cloning product',
      cloneProgressStep: 1,
      cloneProgressStepTotal,
    });

    return createRecord({
      ...values,
      slug: undefined,
      type: record.type,
      attributes: record.attributes,
      delivery: record.delivery,
      price: record.price,
      prices: record.prices,
      sale: record.sale,
      sale_price: record.sale_price,
      cost: record.cost,
      dimensions: record.dimensions,
      purchase_options: record.purchase_options,
      shipment_dimensions: record.shipment_dimensions,
      shipment_package_quantity: record.shipment_package_quantity,
      shipment_prices: record.shipment_prices,
      shipment_weight: record.shipment_weight,
      bundle: record.bundle,
      bundle_items: record.bundle_items,
      variable: record.variable,
      options: record.options,
      stock_tracking: record.stock_tracking,
      stock_purchasable: record.stock_purchasable,
      description: record.description,
      customizable: record.customizable,
      up_sells: record.up_sells,
      cross_sells: record.cross_sells,
      tax_code: record.tax_code,
      content: record.content,
      $locale: record.$locale,
      $currency: record.$currency,
    }).then((result) => {
      if (result.errors) {
        setTimeout(() => {
          this.setState({ cloneLoading: false }, () => {
            this.context.notifyError(result.errors);
          });
        }, 300);
      } else {
        return this.updateClonedProduct(result).then(() => {
          setTimeout(() => {
            this.setState(
              { cloneLoading: false, cloneProgressMessage: null },
              () => {
                router.push(`/products/${result.id}`);
                this.context.notifyCreated(result);
              },
            );
          }, 300);
        });
      }
    });
  }

  async updateClonedProduct(result) {
    const { record, updateRecord } = this.props;

    if (record.images && record.images.length) {
      this.setState((state) => ({
        cloneProgressMessage: 'Cloning product images',
        cloneProgressStep: state.cloneProgressStep + 1,
      }));
    }

    const [clonedProduct, clonedProductImages] = await Promise.all([
      api.get(`/data/products/${result.id}`, { expand: 'variants' }),
      this.cloneImages(record.images || []),
    ]);

    const categoryProducts = record.categoryProducts.results.map((catProd) => ({
      parent_id: catProd.parent_id,
      product_id: result.id,
    }));

    const recordVariants = record.variants.results;

    let updatedVariants = clonedProduct.variants.results.reduce(
      (acc, variant) => {
        const recordVariant = recordVariants.find(
          (recordVariant) =>
            intersection(
              variant.option_value_ids,
              recordVariant.option_value_ids,
            ).length === variant.option_value_ids.length,
        );

        if (recordVariant) {
          acc.push({
            ...variant,
            images: recordVariant.images,
            cost: recordVariant.cost,
            price: recordVariant.price,
            prices: recordVariant.prices,
            purchase_options: recordVariant.purchase_options,
            sale: recordVariant.sale,
            sale_price: recordVariant.sale_price,
            stock_level: recordVariant.stock_level,
            sku: recordVariant.sku,
          });
        }

        return acc;
      },
      [],
    );

    if (recordVariants && recordVariants.length) {
      this.setState((state) => ({
        cloneProgressMessage: 'Cloning product variants',
        cloneProgressStep: state.cloneProgressStep + 1,
      }));
    }

    updatedVariants = await Promise.all(
      updatedVariants.map(async (variant) => {
        const clonnedVariantImages = variant.images
          ? await this.cloneImages(variant.images || [])
          : undefined;

        return {
          ...variant,
          images: clonnedVariantImages,
        };
      }),
    );

    const updatedPurchaseOptions = record.purchase_options
      ? Object.entries(record.purchase_options).reduce((acc, [key, value]) => {
          if (key === 'subscription') {
            acc[key] = {
              ...value,
              plans: value.plans.map(({ id, ...restPlan }) => restPlan),
            };
          } else {
            acc[key] = value;
          }

          return acc;
        }, {})
      : undefined;

    this.setState((state) => ({
      cloneProgressMessage: 'Finishing up',
      cloneProgressStep: state.cloneProgressStep + 1,
    }));

    return updateRecord(result.id, {
      purchase_options: updatedPurchaseOptions,
      images: clonedProductImages,
      categories: categoryProducts,
      variants: updatedVariants,
    });
  }

  cloneImages(images) {
    return Promise.all(
      images.map((image) =>
        api
          .post('/util/import-file', {
            url: image.file.url,
            file: null,
          })
          .then((result) =>
            result && !result.errors ? { file: result } : null,
          ),
      ),
    ).filter((img) => !!img);
  }

  getUpdates(data, values) {
    const updates = {
      $set: {},
      ...this.setTypeFields({
        type: data.record.type,
        bundle_items: values.bundle_items,
      }),
    };

    if (values.variants) {
      updates.variants = this.prepareVariantsForUpdate(
        values.variants,
        data.record,
      );
      updates.stock = prepareVariantStockForUpdate(
        values.variants,
        data.record,
      );
    }
    if (values.bundle_items) {
      updates.bundle_items = this.getBundleItemUpdates(values.bundle_items);
    }
    if (values.categoryValues) {
      updates.categoryValues = undefined;
      updates.categories = this.getCategoryProductUpdates(
        values.categoryValues,
      );
    }
    if (values.images) {
      updates.images = undefined;
      updates.$set.images = values.images;
    }
    if (values.attributes) {
      updates.attributes = undefined;
      updates.$set.attributes = this.state.attributeValues;
      // TODO: fix this
      if (updates.$set.attributes.undefined !== undefined) {
        delete updates.$set.attributes.undefined;
      }
    }
    if (values.shipment_prices) {
      updates.shipment_prices = undefined;
      updates.$set.shipment_prices = values.shipment_prices;
    }
    if (!values.slug && values.slug !== undefined) {
      updates.slug = slugify(values.name);
    }
    if (!values.price && values.price !== undefined) {
      updates.price = 0;
    }
    if (values.deprecated_subscription_option) {
      if (data.record.delivery === 'subscription') {
        updates.deprecated_subscription_option = undefined;
        updates.$set.options = [values.deprecated_subscription_option];
      } else {
        updates.$set.options = updates.$set.options || [];
        updates.deprecated_subscription_option =
          values.deprecated_subscription_option;
      }
    }
    if (values.options) {
      updates.options = undefined;
      updates.$set.options = updates.$set.options || [];
      updates.$set.options = updates.$set.options.concat(values.options);
    }
    if (values.variant_options) {
      updates.variant_options = undefined;
      updates.$set.options = updates.$set.options || [];
      updates.$set.options = updates.$set.options.concat(
        values.variant_options,
      );
    }
    if (values.custom_options) {
      updates.custom_options = undefined;
      updates.$set.options = updates.$set.options || [];
      updates.$set.options = updates.$set.options.concat(values.custom_options);
    }
    if (values.combined_options) {
      updates.combined_options = undefined;
      updates.$set.options = updates.$set.options || [];
      updates.$set.options = updates.$set.options.concat(
        transformCombinedOptions(values.combined_options),
      );
    }
    if (values.purchase_options) {
      updates.purchase_options = undefined;
      updates.$set.purchase_options = cleanPurchaseOptions(
        values.purchase_options,
      );
      if (get(updates, '$set.purchase_options.standard.prices')) {
        this.modifyPrices(updates);
      }
      // Don't update inactive purchase options if they weren't active before
      each(values.purchase_options, (purchaseOption, type) => {
        if (
          !purchaseOption.active &&
          !get(data.record, `purchase_options[${type}].active`)
        ) {
          values.purchase_options[type] = get(
            data.record,
            `purchase_options[${type}]`,
          );
        }
      });
      this.unsetBasePricingValues(values, updates);
    }
    if (values.enable_up_sells !== undefined) {
      values.up_sells = values.enable_up_sells ? values.up_sells : null;
      updates.enable_up_sells = undefined;
    }
    if (values.enable_cross_sells !== undefined) {
      values.cross_sells = values.enable_cross_sells
        ? values.cross_sells
        : null;
      updates.enable_cross_sells = undefined;
    }
    if (values.up_sells !== undefined) {
      updates.up_sells = this.getUpsellUpdates(values.up_sells);
    }
    if (values.cross_sells !== undefined) {
      updates.cross_sells = this.getCrosssellUpdates(values.cross_sells);
    }
    if (values.dimensions !== undefined) {
      if (!values.dimensions) {
        updates.shipment_dimensions = null;
      }
    }
    if (values.content) {
      updates.content = undefined;
      updates.$set.content = contentUpdatesDeprecated(
        this.props.content.fieldsDeprecated.products,
        values.content,
      );
    }
    if (values.tags) {
      updates.tags = undefined;
      updates.$set.tags = values.tags;
    }
    // TODO: fix this
    if (values.undefined !== undefined) {
      updates.undefined = undefined;
    }
    return updates;
  }

  getVariantUpdates(data, values) {
    const updates = { $set: values.$set || {} };
    if (values.attributes) {
      updates.attributes = undefined;
      updates.$set.attributes = {};
      const keys = Object.keys(values.attributes);
      for (const key of keys) {
        const value = values.attributes[key];
        if (!isValueEqual(data.record.attributes[key], value)) {
          updates.$set.attributes[key] = value;
        }
      }
    }
    if (values.purchase_options) {
      updates.purchase_options = undefined;
      updates.$set.purchase_options = values.purchase_options;
      if (get(updates, '$set.purchase_options.standard.prices')) {
        this.modifyPrices(updates);
      }
      this.unsetBasePricingValues(values, updates);
    }
    return updates;
  }

  unsetBasePricingValues(values, updates) {
    // With purchase options, unset base price etc fields (automatically set by API method)
    updates.price = undefined;
    updates.sale = undefined;
    updates.sale_price = undefined;
    updates.prices = undefined;
    for (const field of ['price', 'sale', 'sale_price', 'prices']) {
      updates[field] = undefined;
      if (values.$currency) {
        for (const currency of Object.keys(values.$currency)) {
          updates.$currency = updates.$currency || { ...values.$currency };
          updates.$currency[currency][field] = undefined;
        }
      }
    }
  }

  getBundleItemUpdates(bundleItems) {
    if (!bundleItems) {
      return undefined;
    }
    return {
      $set: bundleItems
        .map((item) => ({
          id: item.id,
          product_id: item.product && item.product.id,
          variant_id: item.variant && item.variant.id,
          options: (item.variable !== 'choose' && item.options) || [],
          quantity: item.quantity || 1,
          variable: item.variable,
        }))
        .filter((item) => !!item.product_id),
    };
  }

  getCategoryProductUpdates(categories) {
    if (!categories) {
      return undefined;
    }
    const updates = [];
    categories.forEach((category) => {
      const categoryProduct = find(this.props.record.categoryProducts.results, {
        parent_id: category.id,
      });
      if (!categoryProduct) {
        updates.push({
          parent_id: category.id,
          product_id: this.props.record.id,
          sort: 0,
        });
      }
    });
    return updates;
  }

  getCategoryProductDeletes(categories) {
    if (!categories) {
      return;
    }
    const deletes = [];
    this.props.record.categoryProducts.results.forEach((categoryProduct) => {
      const category = find(categories, {
        id: categoryProduct.parent_id,
      });
      if (!category) {
        deletes.push(categoryProduct.id);
      }
    });
    return deletes;
  }

  getUpsellUpdates(upsells) {
    if (!upsells || !upsells.length) {
      return { $set: null };
    }
    return {
      $set: upsells.map((item) => ({
        id: item.id,
        product_id: (item.product && item.product.id) || item.product_id,
      })),
    };
  }

  getCrosssellUpdates(crosssells) {
    if (!crosssells || !crosssells.length) {
      return { $set: null };
    }
    return {
      $set: crosssells.map((item) => ({
        id: item.id,
        product_id: (item.product && item.product.id) || item.product_id,
        discount_type: item.discount_type,
        discount_amount: item.discount_amount,
        discount_percent: item.discount_percent,
        $currency: item.$currency,
      })),
    };
  }

  // remove index-like keys with { quantity_max: NUMBER } values and set correct quantity_max values for request payload
  modifyPrices(updates) {
    for (let priceRule of updates.$set.purchase_options.standard.prices) {
      let currentQuantityMaxValue;
      for (let key in priceRule) {
        if (hasQuantityMaxInIndexKey(priceRule[key])) {
          currentQuantityMaxValue = priceRule[key]['quantity_max'];
          delete priceRule[key];
        }
      }

      priceRule.quantity_max = getQuantityMaxValue(
        currentQuantityMaxValue,
        priceRule.quantity_max,
      );
    }
  }

  onQueryCategories(value) {
    this.props.searchCategories(value);
  }

  onClickEditOptions(event) {
    event.preventDefault();

    this.setState((state) => ({ editingOptions: !state.editingOptions }));
  }

  onClickEditVariant(event) {
    event.preventDefault();

    const id = event.currentTarget?.dataset?.id;

    this.setState((state, { record, attributes }) => {
      const variantRecord = id ? find(record.variants.results, { id }) : null;

      const variantAttributes = variantRecord && {
        ...state.attributeValues,
        ...(variantRecord.attributes || {}),
      };

      return {
        editingVariant: !state.editingVariant,
        variantRecord: variantRecord,
        variantValues: { ...variantRecord },
        variantEdited: state.editingVariant ? false : state.variantEdited,
        attributeValues: variantRecord
          ? variantAttributes
          : this.mapDeprecatedAttributes(record.attributes),
        attributeSetAttributes: this.getAttributesFromSet(
          variantRecord ? variantRecord.attribute_set : record.attribute_set,
          variantRecord ? variantAttributes : record.attributes,
          attributes.index,
        ),
      };
    });
  }

  onClickVariantImages(event) {
    event.preventDefault();

    const { id } = event.currentTarget.dataset;

    this.setState((state, { record }) => {
      const variantRecord = id ? find(record.variants.results, { id }) : null;

      return {
        editingVariantImages: !state.editingVariantImages,
        variantRecord: variantRecord,
      };
    });
  }

  onClickCreatePurchaseLink() {
    this.setState((state) => ({
      creatingPurchaseLink: !state.creatingPurchaseLink,
    }));
  }

  async onSubmitPurchaseLink(values) {
    const { record: itemProduct, createPurchaseLink } = this.props;

    const addItem = {
      ...values,
      price: values.set_price,
      quantity: values.quantity || 1,
      product_id: itemProduct.id,
      bundle_items: itemProduct.bundle_items,
      options: getCleanItemOptions(itemProduct, values.options),
    };

    // Remove the `name` as it belongs to the purchase link
    delete addItem.name;
    // Remove `set_price` since its value is stored in `price`
    delete addItem.set_price;

    if (values.bundle_options) {
      addItem.bundle_items = itemProduct.bundle_items.map((item) => ({
        ...item,
        options: getCleanItemOptions(
          item.product,
          values.bundle_options[item.product_id],
        ),
      }));
    }

    const response = await createPurchaseLink(values.name, addItem);

    if (response.errors) {
      return this.context.notifyError(response.errors);
    }

    this.context.notifySuccess('Purchase link has been created');

    this.setState({ creatingPurchaseLink: false });

    return this.refreshRecord();
  }

  onSaveOptions(combinedOptions) {
    const { data, params, updateRecord, notifySavedOptions } = this.props;

    const {
      values: { deprecated_subscription_option },
    } = this.state;

    return updateRecord(params.id, {
      ...this.getUpdates(data, {
        combined_options: transformCombinedOptions(combinedOptions),
        deprecated_subscription_option,
      }),
    }).then((result) => {
      if (result) {
        if (result.errors) {
          this.context.notifyError(result.errors);
        } else {
          return this.refreshRecord().then((record) => {
            this.setState(
              {
                ...this.mapStateFromRecord(record),
              },
              () => {
                notifySavedOptions();

                this.setState({
                  editingOptions: false,
                });
              },
            );
          });
        }
      }
    });
  }

  prepareVariantsForUpdate(variants, record) {
    return map(variants, (variant, key) => {
      const origVariant = find(record.variants.results, { id: key });
      if (origVariant) {
        let update = {};
        // Disable because we removed price from variant field grid
        // const price = parseFloat(variant.price);
        // if (
        //   variant.price !== undefined &&
        //   !isNaN(price) &&
        //   price !== parseFloat(origVariant.price)
        // ) {
        //   update.price = variant.price;
        // } else if (
        //   origVariant.price !== undefined &&
        //   origVariant.price !== null &&
        //   (variant.price === undefined || variant.price === '')
        // ) {
        //   update.price = null;
        // }
        if (
          variant.active !== undefined &&
          !!variant.active !== origVariant.active
        ) {
          update.active = !!variant.active;
        }
        if (variant.sku !== undefined) {
          const updateSku = variant.sku ? variant.sku : null;
          if (updateSku !== origVariant.sku) {
            update.sku = updateSku;
          }
        }
        if (Object.keys(update).length) {
          update.id = origVariant.id;
          return update;
        }
      }
    }).filter(Boolean);
  }

  onSubmitEditVariant(values) {
    const { data, params, updateRecord } = this.props;

    const { variantRecord } = this.state;

    return updateRecord(params.id, {
      variants: [
        {
          id: variantRecord.id,
          ...values,
          ...this.getVariantUpdates(data, values),
        },
      ],
    }).then((result) => {
      if (result) {
        if (result.errors) {
          this.context.notifyError(result.errors);
        } else {
          return this.refreshRecord().then((record) => {
            this.setState({
              editingVariant: false,
              editingVariantImages: false,
              variantRecord: null,
              variantEdited: false,
              ...this.mapStateFromRecord(record),
            });
          });
        }
      }
    });
  }

  onSubmitVariantOptions(variantOptions) {
    const { record } = this.props;

    const subscriptionOptions = (record.options || []).filter(
      (op) => op.subscription,
    );
    const customOptions = (record.options || []).filter((op) => !op.variant);

    return this.updateOptions([
      ...subscriptionOptions,
      ...variantOptions,
      ...customOptions,
    ]);
  }

  onSubmitCustomOptions(customOptions) {
    const { record } = this.props;

    const subscriptionOptions = (record.options || []).filter(
      (op) => op.subscription,
    );
    const variantOptions = (record.options || []).filter(
      (op) => op.variant && !op.subscription,
    );

    return this.updateOptions([
      ...subscriptionOptions,
      ...variantOptions,
      ...customOptions,
    ]);
  }

  onSubmitCombinedOptions(combinedOptions) {
    const { record } = this.props;

    const subscriptionOptions = (record.options || []).filter((op) =>
      Boolean(op.subscription),
    );

    return this.updateOptions([...subscriptionOptions, ...combinedOptions]);
  }

  async updateOptions(options) {
    const { params, record, updateRecord } = this.props;

    const { notifyError } = this.context;

    const beforeRecord = { ...record };

    const result = await updateRecord(params.id, {
      $set: { options },
    });

    if (result) {
      if (result.errors) {
        notifyError(result.errors);
      } else {
        const record = await this.refreshRecord();

        await this.refetchAttributes(record, beforeRecord);

        this.setState({
          ...this.mapStateFromRecord(record),
        });
        this.generatePages(1, this.state.collection.limit);
      }
    }
  }

  onSubmitVariantImages(values) {
    return this.onSubmitEditVariant({ $set: { images: values.images } });
  }

  async onSubmitGenerateMoreGiftcards() {
    const { params, updateRecord, bulkGenerateGiftCards, bulkCancel } =
      this.props;

    const { giftcard_generate } = this.state.values;
    const giftcardCounts = giftcard_generate.counts.map((count) => ({
      ...count,
    }));

    if (giftcard_generate.counts) {
      for (let count of giftcard_generate.counts) {
        if (count.count > 1000) {
          this.context.notifyError(
            'You can only generate up to 1,000 codes at once per denomination',
          );
          return false;
        }
      }
    }

    this.setState({ giftcardGenerating: true });
    await updateRecord(params.id, {
      delivery: 'shipment',
    });
    await bulkGenerateGiftCards(params.id, giftcardCounts);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.setState({ giftcardGenerating: false });
    bulkCancel();

    const record = await this.refreshRecord();

    this.setState({
      ...this.mapStateFromRecord(record),
    });
  }

  render() {
    if (!this.state.loaded) {
      return <LoadingView />;
    }

    if (!this.props.record) {
      return <NotFoundPage />;
    }

    return <EditPage {...this.props} {...this.state} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
