import React from 'react';
import { sortBy } from 'lodash';
import { Form } from 'components/form';
import ContentFields from 'components/content/fields';
import SectionHeader from 'components/section-header';

export default class AppSettings extends React.PureComponent {
  constructor(props) {
    super(props);

    const allContentIds = props.settingContentModels.map((model) => model.name);

    this.state = {
      allContentIds,
      contentVisible: {},
    };

    this.visibleReady = {};
  }

  onFieldsUpdate = (contentId, component) => {
    const { contentVisible } = this.state;
    const { anyFieldsVisible, visibleReady } = component.state;

    if (contentVisible[contentId] !== anyFieldsVisible) {
      this.setState({
        contentVisible: {
          ...contentVisible,
          [contentId]: anyFieldsVisible,
        },
      });
    }

    // Only renders once all fields are ready
    // Using state for this seems to cause an infinite loop
    if (this.visibleReady[contentId] !== visibleReady) {
      this.visibleReady[contentId] = visibleReady;
    }
  };

  getAllContentIdsReady() {
    const { allContentIds } = this.state;
    return allContentIds.every((contentId) => this.visibleReady[contentId]);
  }

  render() {
    const {
      settings,
      values,
      onSubmitSettings,
      onChangeSettings,
      settingContentModels,
    } = this.props;

    const { contentVisible } = this.state;

    const allReady = this.getAllContentIdsReady();

    return (
      <Form
        ref="form"
        onSubmit={onSubmitSettings}
        onChange={onChangeSettings}
        values={values}
      >
        <div
          className={`view-body-section-group ${
            allReady ? 'visible' : 'invisible'
          }`}
        >
          {sortBy(settingContentModels, 'label').map((contentModel, index) => (
            <div key={index} className="view-body-section">
              <div className="view-body-subheader">
                <SectionHeader
                  className="view-body-subtitle"
                  title={contentModel.label}
                  subtitle={contentModel.description}
                />
                <div className="view-body-actions">
                  <div className="content-record-view-fields">
                    <ContentFields
                      {...this.props}
                      collection="settings"
                      models={[contentModel]}
                      record={settings}
                      values={values}
                      fallback={false}
                      showFirstToggle={true}
                      showHelp={false}
                    />
                  </div>
                </div>
              </div>
              <ContentFields
                {...this.props}
                collection="settings"
                models={[contentModel]}
                record={settings}
                values={values}
                fallback={false}
                showFirstToggle={false}
                render={(children) => (
                  <div
                    className={`view-section-content ${
                      contentVisible[contentModel.name] === undefined ||
                      !contentVisible[contentModel.name]
                        ? 'hidden'
                        : ''
                    }`}
                  >
                    <div className="content-record-view-fields">{children}</div>
                  </div>
                )}
                onUpdate={this.onFieldsUpdate.bind(this, contentModel.name)}
              />
            </div>
          ))}
        </div>
      </Form>
    );
  }
}
