import React from 'react';
import PropTypes from 'prop-types';
import Field from './field';
import { singularize } from 'utils';
import { removeModelNamespace } from 'utils/content';

export default class LookupContent extends React.PureComponent {
  static propTypes = {
    lookup: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      query: this.getQuery(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.query !== this.props.query) {
      this.setState({ query: this.getQuery(this.props) });
    }
  }

  getQuery(props) {
    return {
      ...(props.query || {}),
      fields: 'name, published, date_published',
    };
  }

  isUnpublished(record) {
    return !!(
      record.published === false &&
      (!record.date_published || record.date_published < Date.now())
    );
  }

  renderContentValue = (record) => {
    return (
      <span>
        {record.name}
        {this.isUnpublished(record) ? (
          <span className="muted"> (Unpublished)</span>
        ) : (
          <span>&nbsp;</span>
        )}
      </span>
    );
  };

  renderContentLookupItems = ({ lookup, ...props }) => {
    return lookup.results.map((record) => (
      <li
        key={record.id}
        role="option"
        data-id={record.id}
        aria-selected={record.id === props.selected?.id}
        className={`item ${
          record.id === props.selected?.id ? 'selected' : null
        }`}
        onMouseOver={props.onMouseOver}
        onClick={props.onClick}
      >
        <span className="col">{record.name}</span>
        {this.isUnpublished(record) ? (
          <span className="col muted"> (Unpublished)</span>
        ) : (
          <span>&nbsp;</span>
        )}
      </li>
    ));
  };

  render() {
    return (
      <span className={`form-lookup-content ${this.props.className || ''}`}>
        <Field
          ref="field"
          type="lookup"
          name={singularize(this.props.model)}
          placeholder={`Find ${singularize(
            removeModelNamespace(this.props.model),
          )} by name`}
          renderValue={this.renderContentValue}
          renderLookupItems={this.renderContentLookupItems}
          query={this.state.query}
          lookup={this.props.lookup}
          {...this.props}
          model={this.props.model}
          className={undefined}
        />
      </span>
    );
  }
}
