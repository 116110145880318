import React from 'react';
import pt from 'prop-types';

import Modal from 'components/modal';
import Form from 'components/form';
import AttributeForm from 'components/pages/attribute/form';

export default class EditAttributeModalPage extends React.PureComponent {
  static propTypes = {
    loaded: pt.bool,
    edited: pt.bool,
    record: pt.object,

    onSubmitRecord: pt.func,
    onChangeForm: pt.func,
  };

  static contextTypes = {
    openModal: pt.func.isRequired,
    closeModal: pt.func.isRequired,
  };

  /** @type {React.RefObject<Form>} */
  formRef = React.createRef();

  onClickSubmit = (event) => {
    event.preventDefault();
    this.formRef.current.submit();
  };

  onCloseModal = (event) => {
    if (this.props.edited) {
      event.preventDefault();
      this.context.openModal('ConfirmRouteLeave', {
        onConfirm: () => {
          this.context.closeModal();
        },
      });
    }
  };

  render() {
    const { loaded, record, onSubmitRecord, onChangeForm } = this.props;

    return (
      <Modal
        title="Edit attribute"
        width={625}
        onClose={this.onCloseModal}
        actions={[{ label: 'Save attribute', onClick: this.onClickSubmit }]}
        loading={!loaded}
        localized={true}
        devtools={{
          model: 'attributes',
          record,
          zone: 'content',
        }}
      >
        {record && (
          <Form
            ref={this.formRef}
            onSubmit={onSubmitRecord}
            onChange={onChangeForm}
          >
            <AttributeForm {...this.props} modal={true} />
          </Form>
        )}
      </Modal>
    );
  }
}
