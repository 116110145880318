import React from 'react';
import Label from './label';

export default class InputCardExp extends React.PureComponent {
  state = {
    formattedValue: '',
  };

  componentWillReceiveProps(nextProps) {
    const nextLen = nextProps.value.length;
    const nextValue = nextProps.value;
    const prevLen = this.state.formattedValue.length;
    const rawValue = nextValue.replace(/[^0-9]/g, '').substr(0, 4);
    let formattedValue;
    if (nextLen > prevLen) {
      if (rawValue.length >= 2) {
        formattedValue = rawValue.replace(/(.{2})(.*)/, '$1 / $2');
      } else {
        const lastChar = nextValue[nextValue.length - 1];
        if (lastChar === '/') {
          formattedValue = `0${rawValue} / `;
        } else {
          formattedValue = rawValue;
        }
      }
    } else {
      if (rawValue.length <= 2) {
        formattedValue = rawValue;
      } else {
        formattedValue = rawValue.replace(/(.{2})(.*)/, '$1 / $2');
      }
    }
    this.setState({ formattedValue });
  }

  render() {
    const { label, help, value, onChange, labelClass, ...props } = this.props;

    return (
      <span className="form-card-exp">
        <Label
          label={label}
          help={help}
          htmlFor={props.id}
          className={labelClass}
        />
        <span className="form-field-input">
          <input
            {...props}
            ref="input"
            placeholder={props.placeholder || 'MM / YY'}
            value={this.state.formattedValue}
            defaultValue={undefined}
            onChange={onChange}
            autoComplete="off"
            className={`${props.disabled ? 'disabled' : ''} ${
              props.className || ''
            }`}
          />
        </span>
      </span>
    );
  }
}
