import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import pt from 'prop-types';

import { snakeCase } from 'utils';

import { Field, FieldLocalized } from 'components/form';
import ContentFields from 'components/content/fields';

const TYPE_OPTIONS = Object.freeze([
  {
    value: 'text',
    label: 'Text',
  },
  {
    value: 'textarea',
    label: 'Textarea',
  },
  {
    value: 'select',
    label: 'Select',
  },
  {
    value: 'checkbox',
    label: 'Checkbox',
  },
  {
    value: 'radio',
    label: 'Radio',
  },
  {
    value: 'image',
    label: 'Image',
  },
  {
    value: 'file',
    label: 'File',
  },
  {
    value: 'number',
    label: 'Number',
  },
  {
    value: 'currency',
    label: 'Currency',
  },
]);

const NON_FILTERABLE_TYPES = Object.freeze(['image', 'file']);

const NON_LOCALIZABLE_TYPES = Object.freeze([
  'image',
  'file',
  'checkbox',
  'radio',
  'select',
  'number',
]);

export default class AttributeForm extends React.PureComponent {
  static propTypes = {
    record: pt.object,
    values: pt.object,
    content: pt.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      record: null,
      type: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.record !== state.record) {
      return {
        type: props.record ? props.record.type : 'text',
        record: props.record,
      };
    }

    return null;
  }

  onChangeType = (event, value) => {
    if (value) {
      this.setState({ type: value });
    }
  };

  render() {
    const { record = {}, values = {}, content } = this.props;
    const { type } = this.state;

    return (
      <div className="attribute-form">
        <fieldset className="last-child">
          <div className="row">
            <FieldLocalized
              type="text"
              label="Attribute name"
              name="name"
              defaultValue={record.name}
              localeValue={record.$locale}
              required={true}
              className="span2"
              help="Visible to admins and customers in your store"
            />

            <Field
              type="text"
              label="Attribute ID"
              name="id"
              defaultValue={record.id}
              placeholder={snakeCase(values.name)}
              disabled={Boolean(record.id)}
              className="span2"
              help="Used by developers to identify the attribute"
              title={record.id}
            />
          </div>

          {record.variant ? (
            <Fragment>
              <div className="row">
                <div>
                  <label>Option values</label>
                  {Array.isArray(record.values) && record.values.length > 0 ? (
                    <ul className="view-body-tags">
                      {record.values.map((val, i) => (
                        <li key={i}>{val}</li>
                      ))}
                    </ul>
                  ) : (
                    <div className="collection-none-found">
                      No option values
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="row">
                <Field
                  type="select"
                  label="Type"
                  name="type"
                  defaultValue={record.type || 'text'}
                  options={TYPE_OPTIONS}
                  required={true}
                  className="span2"
                  onChange={this.onChangeType}
                />

                <div className="span2" />
              </div>

              {type === 'text' && (
                <FieldLocalized
                  type="text"
                  name="default"
                  label="Default text"
                  placeholder="Optional"
                  defaultValue={
                    isEmpty(record.default) ? undefined : record.default
                  }
                  localeValue={record.$locale}
                  localized={Boolean(values.localized)}
                />
              )}

              {type === 'textarea' && (
                <FieldLocalized
                  rows={4}
                  type="textarea"
                  name="default"
                  label="Default text"
                  placeholder="Optional"
                  defaultValue={
                    isEmpty(record.default) ? undefined : record.default
                  }
                  localeValue={record.$locale}
                  localized={Boolean(values.localized)}
                />
              )}

              {(type === 'dropdown' || type === 'select') && (
                <Fragment>
                  <Field
                    type="tags"
                    editableTags
                    name="values"
                    label="Select options"
                    placeholder="Enter select values separated by a comma"
                    defaultValue={record.values}
                  />

                  <Field
                    type="select"
                    name="default"
                    label="Default value"
                    defaultValue={
                      isEmpty(record.default) ? undefined : record.default
                    }
                    options={values.values || []}
                    placeholder="Optional"
                  />
                </Fragment>
              )}

              {type === 'checkbox' && (
                <Fragment>
                  <Field
                    type="tags"
                    editableTags
                    name="values"
                    label="Checkbox options"
                    placeholder="Enter checkbox values separated by a comma"
                    defaultValue={record.values}
                  />

                  <Field
                    type="tags"
                    editableTags
                    name="default"
                    label="Default checked"
                    placeholder="Enter default checked values separated by a comma (optional)"
                    defaultValue={
                      Array.isArray(record.default) ? record.default : undefined
                    }
                  />
                </Fragment>
              )}

              {type === 'radio' && (
                <Fragment>
                  <Field
                    type="tags"
                    editableTags
                    name="values"
                    label="Radio options"
                    placeholder="Enter radio values separated by a comma"
                    defaultValue={record.values}
                  />

                  <Field
                    type="select"
                    name="default"
                    label="Default value"
                    defaultValue={
                      isEmpty(record.default) ? undefined : record.default
                    }
                    options={values.values}
                    placeholder="Optional"
                  />
                </Fragment>
              )}

              {type === 'image' && (
                <Field
                  type="toggle"
                  name="multi"
                  label="Enable uploading multiple images"
                  defaultChecked={record.multi}
                />
              )}

              {type === 'file' && (
                <Field
                  type="toggle"
                  name="multi"
                  label="Enable uploading multiple files"
                  defaultChecked={record.multi}
                />
              )}
            </Fragment>
          )}

          <div className="row">
            <Field
              type="toggle"
              name="visible"
              label="Visible"
              defaultChecked={
                record.visible !== undefined ? record.visible : true
              }
              help="A visible attribute is displayed in product listings"
              className="span4"
            />
          </div>

          {!NON_FILTERABLE_TYPES.includes(type) && (
            <div className="row">
              <Field
                type="toggle"
                name="filterable"
                label="Filterable"
                defaultChecked={
                  record.filterable !== undefined ? record.filterable : false
                }
                help="To be used in storefront category page filters"
                className="span4"
              />
            </div>
          )}

          {!record.variant && (
            <div className="row">
              <Field
                type="toggle"
                name="required"
                label="Required"
                defaultChecked={record.required}
                help="A value will be required to save"
                className="span4"
              />
            </div>
          )}

          {!record.variant && !NON_LOCALIZABLE_TYPES.includes(type) && (
            <div className="row">
              <Field
                type="toggle"
                name="localized"
                label="Localized"
                defaultChecked={record.localized}
                help="A value will be localizable"
                className="span4"
              />
            </div>
          )}
        </fieldset>

        <ContentFields
          {...this.props}
          zone="content"
          collection="attributes"
          models={content.models}
          record={record}
          values={values}
          currency={record.currency}
          render={(children) => (
            <fieldset className="full">
              <div className="content-fields content-fields-box">
                {children}
              </div>
            </fieldset>
          )}
        />
      </div>
    );
  }
}
