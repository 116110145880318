import $ from 'jquery';

function onTemplateContentKeyDown(event) {
  // Keep track of modal scroll position
  const $modal = $('.modal');
  if ($modal[0]) {
    this.templateModalScrollTop = $modal[0].scrollTop;
  }
  // Handle specific keys
  const el = event.currentTarget;
  const caretPos = el.selectionStart;
  const caretEnd = el.selectionEnd;
  switch (event.keyCode) {
    case 9: // tab
      // Insert 2 spaces instead of blurring field
      event.preventDefault();
      if (el) {
        const val = $(el).val();
        el.focus();
        $(el)
          .val(val.substring(0, caretPos) + '  ' + val.substring(caretEnd))
          .prop('selectionStart', caretPos + 2)
          .prop('selectionEnd', caretPos + 2);
      }
      break;
    case 13: // enter
      // Start the next line with whitespace
      if (el) {
        const val = $(el).val();
        let newlinePos = caretPos - 1;
        while (newlinePos > 0) {
          if (val[newlinePos] === '\n') {
            break;
          }
          newlinePos--;
        }
        if (newlinePos > 0) {
          const space = val.substring(newlinePos).match(/[ ]+/);
          if (space) {
            event.preventDefault();
            const extra = '\n' + space[0];
            $(el)
              .val(val.substring(0, caretPos) + extra + val.substring(caretEnd))
              .prop('selectionStart', caretPos + extra.length)
              .prop('selectionEnd', caretPos + extra.length);
          }
        }
      }
      break;
    default:
      break;
  }
}

export default onTemplateContentKeyDown;
