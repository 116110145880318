import React from 'react';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';
import ObjectID from 'bson-objectid';
import Link from 'components/link';
import ContentField from './field';
import { Field, Fieldtable } from 'components/form';
import { formatCurrency, singularize } from 'utils';
import { productThumbUrl } from 'utils/product';

export default class ContentFieldtableDeprecated extends React.PureComponent {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    fields: PropTypes.array.isRequired,
    record: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: this.getInitialValue(props),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.record !== this.props.record) {
      this.setState({
        value: this.getInitialValue(nextProps),
      });
    }
  }

  getInitialValue(props) {
    const value = get(props.record, props.name);
    if (value) {
      return [...value];
    }
    return value;
  }

  hasImage(field) {
    switch (field.type) {
      case 'product_lookup':
        return true;
      default:
        return false;
    }
  }

  renderHeading = () => {
    return [
      ...this.props.fields.map((field) => (
        <th key={field.id} colSpan={this.hasImage(field) ? 2 : 1}>
          {field.label || field.id}
        </th>
      )),
    ];
  };

  renderImageValue(value, fields) {
    const firstValue = value[fields[0].id] || {};
    let linkUrl;
    let imageSrc;
    let imageAlt;
    switch (fields[0].type) {
      case 'product_lookup':
        linkUrl = `/products/${firstValue.id}`;
        imageSrc = productThumbUrl(firstValue);
        imageAlt = firstValue.name;
        const variantField = find(fields, { type: 'variant_lookup' });
        if (variantField) {
          const variantValue = value[variantField.id] || {};
          imageSrc = productThumbUrl(firstValue, variantValue);
          imageAlt = `${firstValue.name} ${variantValue.name}`;
        }
        break;
      default:
        return [
          <td key="first-image" className="image">
            <span className="collection-table-images">
              <span className="image" />
            </span>
          </td>,
        ];
    }
    return [
      <td key="first-image" className="image">
        <span className="collection-table-images">
          <span className="image">
            <Link to={linkUrl}>
              <img src={imageSrc} alt={imageAlt} />
            </Link>
          </span>
        </span>
      </td>,
    ];
  }

  renderFieldValue(field, value = {}) {
    const { record = {} } = this.props;
    const fieldValue = value[field.id];
    switch (field.type) {
      case 'lookup':
      case 'product_lookup':
      case 'category_lookup':
      case 'categories_lookup':
      case 'customer_lookup':
        let model = field.model;
        let key = field.key;
        switch (field.type) {
          case 'product_lookup':
            model = model || 'products';
            key = key || 'product_id';
            break;
          case 'category_lookup':
            model = model || 'categories';
            key = key || 'category_id';
            break;
          case 'customer_lookup':
            model = model || 'accounts';
            key = key || 'account_id';
            break;
          default:
            break;
        }
        const keyValue = fieldValue ? fieldValue.id : value[key];
        return (
          <td key={field.id}>
            <Link to={`/${model}/${keyValue}`}>
              {fieldValue ? fieldValue.name : keyValue}
            </Link>
          </td>
        );
      case 'currency':
        return (
          <td key={field.id}>{formatCurrency(fieldValue, record.currency)}</td>
        );
      default:
        return (
          <td key={field.id}>
            {fieldValue !== null &&
            fieldValue !== undefined &&
            fieldValue !== '' ? (
              <span>
                {fieldValue && fieldValue.name
                  ? fieldValue.name
                  : String(fieldValue)}
              </span>
            ) : (
              <span className="muted">&mdash;</span>
            )}
          </td>
        );
    }
  }

  renderValue = ({ value, index }) => {
    const { fields } = this.props;
    return [
      ...(this.hasImage(fields[0]) ? this.renderImageValue(value, fields) : []),
      ...fields.map((field) => this.renderFieldValue(field, value)),
    ];
  };

  renderForm = (value, index) => {
    const { lookup, fields, record = {} } = this.props;
    return (
      <div>
        <Field
          type="hidden"
          name="id"
          value={value.id || ObjectID().toHexString()}
        />
        {fields.map((field) => (
          <div key={field.id} className="row">
            <ContentField
              {...field}
              name={field.id}
              currency={record.currency}
              lookup={lookup}
              values={value}
              defaultValue={get(value, field.id)}
            />
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { name, label, lookup, sortable, disabled } = this.props;
    const { value } = this.state;

    return (
      <Fieldtable
        label={singularize(label)}
        name={name}
        defaultValue={value}
        renderHeading={this.renderHeading}
        renderValue={this.renderValue}
        renderForm={this.renderForm}
        disabled={disabled}
        formWidth={600}
        sortable={sortable !== undefined ? sortable : true}
        lookup={lookup}
      />
    );
  }
}
