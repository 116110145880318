import React, { Fragment } from 'react';
import { classNames } from 'utils';
import pt from 'prop-types';

import Icon from 'components/icon';
import Link from 'components/link';
import LinkButton from 'components/button/link';

export default class ConsoleLink extends React.PureComponent {
  static propTypes = {
    size: pt.string,
    label: pt.string,
    className: pt.string,
    model: pt.string.isRequired,
    uri: pt.string,
  };

  render() {
    const {
      size,
      label,
      children,
      className,
      model,
      uri,
      buttonType = undefined,
      icon = true,
    } = this.props;

    const path = `/console?get/${encodeURIComponent(model)}${
      uri ? `/${encodeURIComponent(uri)}` : ''
    }`;

    const Wrapper = buttonType === null ? Link : LinkButton;

    return (
      <Wrapper
        to={path}
        size={size}
        title="Open console"
        className={classNames('console-link', className)}
        type={buttonType !== undefined ? buttonType : 'sub'}
      >
        {icon !== false && (
          <Fragment>
            <Icon fa="code" />
            &ensp;
          </Fragment>
        )}
        {label || children}
      </Wrapper>
    );
  }
}
