import validator from 'validator';
import ccutils from 'creditcardutils';
import moment from 'moment';
import { map, uniq } from 'lodash';
import { normalizeUrl } from 'utils';

const rules = {
  required: {
    rule: (value) => {
      if (typeof value === 'string') {
        return value.trim().length > 0;
      }

      if (Array.isArray(value)) {
        return value.length > 0;
      }

      if (value === 0) {
        return true;
      }

      return Boolean(value);
    },
    hint: () => {
      return 'Required';
    },
  },

  email: {
    rule: (value) => {
      return value ? validator.isEmail(value) : true;
    },
    hint: () => {
      return 'Must be a valid email';
    },
  },

  phone: {
    rule: (value) => {
      return value ? String(value).replace(/[^0-9]/g, '').length >= 10 : true;
    },
    hint: () => {
      return 'Must be a valid phone number';
    },
  },

  date: {
    rule: (value) => {
      return value ? moment(value).isValid() : true;
    },
    hint: () => {
      return 'Must be a valid date';
    },
  },

  time: {
    rule: (value) => {
      return (
        !value ||
        moment(value).isValid() ||
        moment(`2001-01-01T${value}Z`).isValid()
      );
    },
    hint: () => {
      return 'Must be a valid time';
    },
  },

  credit_card_number: {
    rule: (value) => {
      return value ? ccutils.validateCardNumber(value) : true;
    },
    hint: () => {
      return 'Must be a valid credit card number';
    },
  },

  credit_card_exp: {
    rule: (value) => {
      if (value) {
        const exp = ccutils.parseCardExpiry(value);
        return ccutils.validateCardExpiry(exp.month, exp.year);
      }
      return true;
    },
    hint: () => {
      return 'Invalid';
    },
  },

  credit_card_cvc: {
    rule: (value) => {
      return value ? ccutils.validateCardCVC(value) : true;
    },
    hint: () => {
      return 'Invalid';
    },
  },

  field_increment_format: {
    rule: (value) => {
      if (value) {
        const parts = value.match(/\{[0-9]+\}/g);
        return parts !== null && parts.length === 1;
      }
      return true;
    },
    hint: () => {
      return 'Must contain exactly one number template (ex. XYZ{12345})';
    },
  },

  domain_name: {
    rule: (value) => {
      return value ? validator.isFQDN(value) : true;
    },
    hint: () => {
      return 'Must be a valid domain name (i.e. example.com)';
    },
  },

  url: {
    rule: (value) => {
      return value ? validator.isURL(value) : true;
    },
    hint: () => {
      return 'Must be a valid URL (i.e. https://www.example.com)';
    },
  },

  secure_url: {
    rule(value) {
      if (!value) {
        return true;
      }
      const url = normalizeUrl(value);
      return validator.isURL(url || '', {
        protocols: ['https'],
        require_protocol: true,
      });
    },
    hint() {
      return 'Must be a valid secure URL (i.e. https://www.example.com)';
    },
  },

  custom_receipt_url: {
    rule: (value) => {
      return value ? value.indexOf('{checkout_id}') >= 0 : true;
    },
    hint: () => {
      return 'Must have {checkout_id} in the URL (i.e. https://www.example.com/order/{checkout_id})';
    },
  },

  coupon_code: {
    rule: (value) => {
      const match = value.match(/[A-Z0-9]+/);
      return match && match[0] === value;
    },
    hint: () => {
      return 'Must contain only letters and numbers with no spaces';
    },
  },

  unique: {
    rule: (value) => {
      const stringValues = map(value, (val) =>
        val && val.name ? val.name : val,
      );
      return uniq(stringValues).length === value.length;
    },
    hint: () => {
      return 'Values must be unique';
    },
  },

  password: {
    warn: true,
    rule: (value) => {
      return !value || value.length >= 8;
    },
    hint: () => {
      return 'Must have at least 8 characters';
    },
  },

  password_admin: {
    warn: true,
    rule: (value) => {
      return (
        // Same validation in the schema-api-server server/utils/password.js
        Boolean(value) &&
        value.length >= 10 && // Must have at least 10 characters
        /\d/.test(value) && // Must have at least 1 number
        /[A-Z]/.test(value) // Must have at least 1 uppercase letter
      );
    },
    hint: () => {
      return 'Password must be at least 10 characters long with at least 1 number and 1 uppercase letter';
    },
  },

  tag: {
    rule: (value) => {
      return !value || !value.includes(",");
    },
    hint: () => {
      return 'Tag must not contain comma';
    },
  },
};

export default { rules };
