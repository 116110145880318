import React, { PureComponent } from 'react';
import Chart from './chart';
import './chart.scss';

class ChartWithSummary extends PureComponent {
  render() {
    const { title, summary, ...props } = this.props;

    return (
      <div className="chart-summary-root">
        <div className="chart-summary-title">{title}</div>
        <div className="chart-summary-text">{summary}</div>
        <Chart {...props} />
      </div>
    );
  }
}

export default ChartWithSummary;
