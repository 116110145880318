import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import actions from 'actions';

import { isValueEqual } from 'utils';
import onTemplateContentKeyDown from 'utils/template';

import EditModalPage from 'components/pages/modals/edit-notification';

const mapStateToProps = (state) => ({
  loading: state.notifications.loading || state.data.loading,
  notifications: state.notifications,
  client: state.client,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotification: (id, defaultId) => {
    return dispatch(actions.notifications.load(id, defaultId));
  },

  createNotification: (values) => {
    return dispatch(actions.notifications.create(values));
  },

  resetNotificationCache: () => {
    return dispatch(actions.notifications.resetCache());
  },

  updateNotification: (id, values) => {
    return dispatch(actions.notifications.update(id, values));
  },

  sendNotificationTest: (id, values) => {
    return dispatch(actions.data.createRecord('notifications', values));
  },
});

export class EditNotificationModal extends React.PureComponent {
  static propTypes = {
    params: PropTypes.object.isRequired,
    notifications: PropTypes.object.isRequired,

    fetchNotification: PropTypes.func.isRequired,
    createNotification: PropTypes.func.isRequired,
    updateNotification: PropTypes.func.isRequired,
    sendNotificationTest: PropTypes.func.isRequired,
    resetNotificationCache: PropTypes.func.isRequired,
  };

  static contextTypes = {
    user: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    notifySuccess: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      noteValues: {},
      noteEdited: false,
      noteDefault: null,
      noteLoading: true,
      onChange: this.onChange.bind(this),
      onSubmit: this.onSubmit.bind(this),
      onClickRevertNotification: this.onClickRevertNotification.bind(this),
      onClickSendNotification: this.onClickSendNotification.bind(this),
      onSubmitNotificationFieldsForm:
        this.onSubmitNotificationFieldsForm.bind(this),
      onTemplateContentKeyDown: onTemplateContentKeyDown.bind(this),
      setPreviewReadyState: this.setPreviewReadyState.bind(this),
      setFormRef: this.setFormRef.bind(this),
    };
  }

  componentDidMount() {
    const { params } = this.props;
    this.loadNotification(params.id);
  }

  componentWillReceiveProps(nextProps) {
    const { params } = this.props;

    if (params.id !== nextProps.params.id) {
      this.setState({ noteLoading: true });
      this.loadNotification(nextProps.params.id);
    }
  }

  onChange(values, noteEdited) {
    this.setState(({ noteValues }) => ({
      noteValues: {
        ...noteValues,
        ...values,
      },
      noteEdited,
    }));
  }

  async onSubmit(values) {
    const {
      notifications: { record },
    } = this.props;

    const success = await this.onUpdateNotification({
      ...values,
      send_to: undefined,
      preview_locale: undefined,
      editing_fields: undefined,
      // Deprecated, remove this later
      replyto: this.state.noteValues.replyto ? null : undefined,
      v2: this.state.noteValues.v2,
      conditions: {
        $data: { $notify: record.name },
      },
      $set: {
        ...this.state.noteValues.$set,
      },
    });

    if (success) {
      this.context.closeModal();
    }
  }

  onClickRevertNotification(event) {
    event.preventDefault();

    const { notifications, fetchNotification, params } = this.props;
    const { noteDefault } = this.state;
    const { record, defaultRecord, defaultContent } = notifications;

    this.context.openModal('Confirm', {
      title: 'Revert notification template',
      message: (
        <div>
          <p>
            Are you sure you want to revert to the original notification
            template?
          </p>
          <p>Warning: this will undo changes you've made to this template</p>
        </div>
      ),
      action: 'Revert',
      actionType: 'danger',
      onConfirm: async () => {
        this.noteForm.setValue('subject', defaultRecord.subject);
        this.noteForm.setValue('content', defaultContent);

        const conditions = {
          ...defaultRecord.conditions,
          $data: {
            ...defaultRecord.conditions?.$data,
            $notify: record.name,
          },
        };

        if (
          noteDefault.content !== defaultContent ||
          noteDefault.record.subject !== defaultRecord.subject ||
          !isValueEqual(noteDefault.record.$locale, defaultRecord.$locale) ||
          !isValueEqual(noteDefault.record.fields, defaultRecord.fields)
        ) {
          await this.onUpdateNotification({
            v2: defaultRecord.v2,
            content: defaultContent,
            subject: defaultRecord.subject,
            contact: defaultRecord.contact,
            $set: {
              conditions,
              query: defaultRecord.query,
              sample: defaultRecord.sample,
              fields: defaultRecord.fields,
            },
          });

          const result = await fetchNotification(params.id, params.default_id);

          if (result) {
            this.setState({
              noteDefault: result,
            });
          }
        }

        this.setState(({ noteValues }) => ({
          noteValues: {
            ...noteValues,
            v2: defaultRecord.v2,
            content: defaultContent,
            subject: defaultRecord.subject,
            contact: defaultRecord.contact,
            fields: defaultRecord.fields,
            $locale: defaultRecord.$locale,
            $set: {
              conditions,
              query: defaultRecord.query,
              sample: defaultRecord.sample,
            },
          },
        }));
      },
    });
  }

  onSubmitNotificationFieldsForm(values) {
    this.onUpdateNotification({
      $set: {
        fields: values,
      },
    }).then((success) => {
      if (success) {
        this.setState({
          noteValues: {
            ...this.state.noteValues,
            fields: values,
          },
        });
      }
    });
  }

  onClickSendNotification(event) {
    const { noteValues } = this.state;
    event.preventDefault();
    this.onSendNotificationTest({
      to: noteValues.send_to,
      locale: noteValues.preview_locale,
      $config: {
        _locale: noteValues.$locale,
        subject: noteValues.subject,
        content: {
          html: {
            data: noteValues.content,
          },
        },
        fields: noteValues.fields,
      },
    });
  }

  async onUpdateNotification(values) {
    const {
      updateNotification,
      notifications: { record },
    } = this.props;

    const result = await updateNotification(record.id, values);

    if (result.errors) {
      this.context.notifyError(result.errors);
      return false;
    }

    return true;
  }

  async onSendNotificationTest(values) {
    const {
      sendNotificationTest,
      notifications: { record },
    } = this.props;

    const { client, user } = this.context;
    const toEmail = values.to || user.email;
    const fromEmail = record.from || client.support_email;

    const result = await sendNotificationTest(record.id, {
      template: `${record.model}.${record.name}`,
      data: record.sample,
      test: true,
      to: toEmail,
      from: fromEmail,
      locale: values.locale,
      $config: values.$config,
    });

    if (result) {
      if (result.errors) {
        this.context.notifyError(result.errors);
        return false;
      }

      this.context.notifySuccess(`Test notification sent to ${toEmail}`);
      return true;
    }
  }

  async loadNotification(id) {
    const { resetNotificationCache } = this.props;

    await resetNotificationCache();

    const result = await this.ensureNotification(id);

    this.setState({
      noteDefault: result,
      ...(result && {
        noteValues: {
          tab: 'preview',
          ...result.record,
          content: result.content || result.defaultContent,
          fields:
            result.record.fields ||
            (result.content === result.defaultContent
              ? result.defaultRecord.fields
              : null),
        },
      }),
      noteEdited: false,
      noteLoading: false,
    });
  }

  async ensureNotification(id) {
    const {
      fetchNotification,
      createNotification,
      params: { default_id },
    } = this.props;

    const [model, name] = id.split(/\.(.+)/, 2);

    let result = await fetchNotification(id, default_id);

    if (
      get(result, 'record.model') === model &&
      get(result, 'record.name') === name
    ) {
      return result;
    }

    if (!default_id) {
      return;
    }

    result = await fetchNotification(default_id);

    if (!result || !result.record) {
      return;
    }

    const { conditions } = result.record;

    result = await createNotification({
      ...result.record,
      model,
      name,
      conditions: {
        ...conditions,
        $data: {
          ...conditions?.$data,
          $notify: name,
        },
      },
      content: {
        html: {
          data: result.defaultContent,
        },
      },
    });

    if (result.errors) {
      this.context.notifyError(result.errors);
      return;
    }

    return fetchNotification(result.id, default_id);
  }

  setPreviewReadyState = (noteLoading) => this.setState({ noteLoading });

  setFormRef = (form) => (this.noteForm = form);

  render() {
    return <EditModalPage {...this.props} {...this.state} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditNotificationModal);
