import React from 'react';
import PropTypes from 'prop-types';
import Help from 'components/tooltip/help';
import FullPageModal from 'components/full-page-modal';
import AccountPlans from 'components/account-plans';
import IntervalToggle from 'components/account-plans/interval-toggle';
import PaymentDetails from 'components/account-plans/payment-details';
import { FadeIn } from 'components/transitions';
import './account-plan-selection.scss';

export default class AccountPlanSelectionModal extends React.PureComponent {
  static contextTypes = {
    account: PropTypes.object.isRequired,
  };

  static propTypes = {
    onSubmitActivatePlan: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPlan: null,
      isMonthly: true,
      step: 'plan',
    };
  }

  onClickSelectPlan = (event) => {
    event.preventDefault();
    const planId = event.currentTarget.dataset.id;
    this.setState({ step: 'paymentDetails', currentPlan: planId });
  };

  onClickIntervalToggle = (interval) =>
    interval === 'monthly'
      ? this.setState({ isMonthly: true })
      : this.setState({ isMonthly: false });

  onClickBackButton = () => this.setState({ step: 'plan' });

  renderBackButton = () => (
    <button
      className="full-page-modal-back-button"
      onClick={this.onClickBackButton}
      type="button"
    >
      Back
    </button>
  );

  render() {
    const { onSubmitActivatePlan } = this.props;
    const { isMonthly, step, currentPlan } = this.state;
    const { account } = this.context;

    const planStep = step === 'plan';

    return (
      <FullPageModal
        className="modal-account-plan-selection"
        backButton={planStep ? undefined : this.renderBackButton()}
        transition={false}
      >
        <div className="plan-selection-header-container">
          <div className="plan-selection-title">
            {planStep ? 'Pick a plan' : 'Payment details'}
          </div>
          <div className="plan-selection-subtitle">
            {planStep ? (
              <>
                Choose a plan to enable your live store environment
                <Help
                  message={`After choosing a plan, your trial will continue with ${account.planTrialDaysLeftMessage}`}
                />
              </>
            ) : (
              'Enter payment for your new subscription'
            )}
          </div>
        </div>
        {planStep && (
          <IntervalToggle
            isMonthly={isMonthly}
            onClickIntervalToggle={this.onClickIntervalToggle}
          />
        )}
        {planStep ? (
          <FadeIn key="plans">
            <AccountPlans
              isMonthly={isMonthly}
              onClickSelectPlan={this.onClickSelectPlan}
            />
          </FadeIn>
        ) : (
          <FadeIn key="payment">
            <PaymentDetails
              currentPlan={currentPlan}
              isMonthly={isMonthly}
              onSubmitActivatePlan={onSubmitActivatePlan}
            />
          </FadeIn>
        )}
      </FullPageModal>
    );
  }
}
