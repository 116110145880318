import React from 'react';
import Icon from 'components/icon';
import DropdownButton from './dropdown';
import classNames from 'classnames';
import './button.scss';

export default class KebabButton extends React.PureComponent {
  render() {
    const { className } = this.props;
    return (
      <DropdownButton
        side="top"
        sideOffset={8}
        alignOffset={10}
        type="inline"
        anchor="middle"
        {...this.props}
        className={classNames('button-dropdown-kebab', className)}
        arrow={false}
      >
        <Icon fa="ellipsis-h" />
      </DropdownButton>
    );
  }
}
