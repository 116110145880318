import React from 'react';
import { connect } from 'react-redux';
import ViewLoading from 'components/view/loading';
import View from 'components/view';
import CollectionPage from 'components/collection/collection';
import AppIcon from 'components/apps/icon';
import Status from 'components/status';
import DateTime from 'components/date-time';
import { stripTags } from 'utils/string';
import actions from 'actions';
import BuildAppsCallout from 'components/apps/callout-build-apps';
import 'components/apps/apps.scss';

const fields = {
  icon: {
    type: 'image',
    func: ({ app, active }) => {
      return (
        <AppIcon
          image={app.logo_icon}
          name={app.name}
          size={45}
          disabled={active === false}
        />
      );
    },
  },
  name: {
    func: ({ app }) => (
      <>
        <div>
          <b>{app.name}</b>
        </div>
        <div
          className="muted"
          dangerouslySetInnerHTML={{
            __html: stripTags(app.description),
          }}
        />
      </>
    ),
  },
  status: {
    width: '10%',
    className: 'nowrap',
    align: 'right',
    func: ({ parent_id, environment_id, app, active, version }) => {
      return (
        <>
          {active === false ? (
            <Status type="muted">Disabled</Status>
          ) : (
            version &&
            app.version > version && (
              <Status type="primary" dot={false}>
                Update available
              </Status>
            )
          )}
          {app.client_id === parent_id && environment_id === 'test' && (
            <Status type="warning">Development</Status>
          )}
        </>
      );
    },
  },
  installed: {
    width: '10%',
    className: 'nowrap',
    align: 'right',
    func: ({ date_installed, date_created }) => (
      <>
        Installed{' '}
        <DateTime value={date_installed || date_created} format="age" />
      </>
    ),
  },
};

const mapStateToProps = (state) => ({
  //
});

const mapDispatchToProps = (dispatch) => ({
  fetchInstalledApps: () => dispatch(actions.client.fetchInstalledApps()),
});

export class Apps extends React.PureComponent {
  state = {
    loaded: false,
    installedApps: null,
  };

  componentWillMount() {
    const { fetchInstalledApps } = this.props;
    fetchInstalledApps().then((installedApps) => {
      this.setState({ loaded: true, installedApps });
    });
  }

  render() {
    const { installedApps } = this.state;

    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    const { enabledApps, disabledApps } = installedApps.results.reduce(
      (acc, installedApp) => {
        const { app, active } = installedApp;

        if (!app) {
          return acc;
        } else if (active !== false) {
          acc.enabledApps.push(installedApp);
        } else {
          acc.disabledApps.push(installedApp);
        }

        return acc;
      },
      { enabledApps: [], disabledApps: [] },
    );

    return (
      <div className="apps-list">
        <View
          detail={true}
          headerTitle="Apps"
          headerSubtitle="Manage your Swell applications"
          uri="/apps"
        >
          {enabledApps.length > 0 && (
            <CollectionPage
              {...this.props}
              recordUri="/apps/{app_id}"
              searchable={false}
              headless={true}
              collection={{ count: enabledApps.length, results: enabledApps }}
              fields={fields}
            />
          )}
          {disabledApps.length > 0 && (
            <CollectionPage
              {...this.props}
              recordUri="/apps/{app_id}"
              searchable={false}
              headless={true}
              collection={{
                count: disabledApps.length,
                results: disabledApps,
              }}
              fields={fields}
            />
          )}
          <BuildAppsCallout />
        </View>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Apps);
