import React from 'react';
import { find } from 'lodash';
import * as d3 from 'd3';

export default class Line extends React.PureComponent {
  getValue(data, key) {
    if (typeof key === 'string') {
      return data[key];
    }
    if (typeof key === 'function') {
      return key(data);
    }
  }

  generateStrokeDasharray(line) {
    const { useDashesBeyondNow, data, dataId, x, offsetWidth } = this.props;

    if (useDashesBeyondNow && this.refs.linePath) {
      const pathLength = this.refs.linePath.getTotalLength();

      // Find the data for the current line
      const lineData = find(data, { id: dataId }).points;

      // Generate stroke-dasharray
      // Assumes X is always a timestamp (in our charts, they are)
      // Note this calculation seems slightly off when rendered, but it's close
      const now = Date.now();
      const thisLength = (pathLength + offsetWidth) / lineData.length;

      return lineData
        .map((d, i) => {
          // If x value is beyond now, generate a dash and a gap
          const value = this.getValue(d, x);
          if (value > now) {
            // Generate dashes for the length of this value
            return Array(Math.ceil(thisLength / 3))
              .fill('3,3')
              .join(', ');
          }
          // Otherwise, generate a solid line
          return `${thisLength},0`;
        })
        .join(', ');
    } else {
      return undefined;
    }
  }

  render() {
    const {
      data,
      x,
      y,
      fill,
      xScales,
      yScale,
      margin,
      dataId,
      height,
      offsetWidth,
      offsetHeight,
      pointColors,
    } = this.props;

    const withArea =
      this.props.withArea !== false ? (dataId < 1 ? true : false) : false;

    const translate = `translate(${margin.left + offsetWidth}, ${margin.top})`;

    const line = d3
      .line()
      .x((d) => xScales[dataId](this.getValue(d, x)))
      .y((d) => yScale(this.getValue(d, y)))(find(data, { id: dataId }).points);

    const area =
      withArea &&
      d3
        .area()
        .x((d) => xScales[dataId](this.getValue(d, x)))
        .y0(height - margin.bottom - margin.top - offsetHeight)
        .y1((d) => yScale(this.getValue(d, y)))(
        find(data, { id: dataId }).points,
      );

    // Special color for main area
    const lineColor = fill || pointColors[dataId];
    const areaColor =
      lineColor === '#785ce0'
        ? 'url(#chartLineAreaGradient)'
        : fill || lineColor;
    const areaOpacity = lineColor === '#785ce0' ? 0.8 : 0.3;

    const strokeDasharray = this.generateStrokeDasharray(line);

    return [
      area && (
        <path
          key="1"
          d={area}
          transform={translate}
          fill={areaColor}
          fillOpacity={areaOpacity}
        />
      ),
      <path
        key="2"
        d={line}
        transform={translate}
        stroke={lineColor}
        fill="none"
        strokeWidth={2}
        strokeDasharray={strokeDasharray}
        ref="linePath"
      />,
    ];
  }
}
