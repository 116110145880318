import React, { Fragment } from 'react';
import map from 'lodash/map';
import pt from 'prop-types';

import { LOG_DETAIL_TYPES } from 'constants/logs';

import { renderLineColumn } from './logs-line';

function LogsDetails({
  line,
  parent,
  location,
  detailPanelRef,
  onClickLineDetails,
}) {
  return (
    <div ref={detailPanelRef} className="console-logs-detail-panel">
      <div className="console-box-table">
        <table className="collection-table">
          <thead>
            <tr>
              <th>
                Log details{' '}
                <button
                  className="console-logs-detail-close"
                  onClick={onClickLineDetails}
                  type="button"
                >
                  &times;
                </button>
              </th>
            </tr>
          </thead>

          <tbody>
            {map(LOG_DETAIL_TYPES, (detail, id) => {
              const lineColumn = renderLineColumn(
                line,
                detail,
                id,
                parent,
                location,
              );

              if (lineColumn === null) {
                return null;
              }

              return (
                <tr key={id}>
                  <td
                    className={`console-logs-detail-row console-detail-${id}`}
                  >
                    <div>
                      {detail.renderUnder ? (
                        <Fragment>
                          <div className="console-logs-detail-label">
                            {detail.label}
                          </div>

                          <div className="console-logs-detail-value under">
                            {lineColumn}
                          </div>
                        </Fragment>
                      ) : detail.label ? (
                        <Fragment>
                          <div className="console-logs-detail-label">
                            {detail.label}
                          </div>

                          <span className="console-logs-detail-value">
                            {lineColumn}
                          </span>
                        </Fragment>
                      ) : (
                        <div className="console-logs-detail-label">
                          {lineColumn}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

LogsDetails.propTypes = {
  line: pt.object.isRequired,
  parent: pt.object,
  location: pt.object,
  detailPanelRef: pt.object,

  onClickLineDetails: pt.func,
};

export default React.memo(LogsDetails);
