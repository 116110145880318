/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import actions from 'actions';

import { Field } from 'components/form';
import PaymentMethodsRadio from 'components/payment/methods-radio';

import { makeAddressString, isSameAddress } from 'utils/order';
import { getDefaultAddressId } from 'utils/account';
import { paymentMethodOptions } from 'utils/payment';

import './payment.scss';

const NEW_ADDRESS_ID = 'new';
const NONE_ADDRESS_ID = 'none';
const EMPTY_ARRAY = Object.freeze([]);

const mapStateToProps = (state) => ({
  model: state.data.model,
  record: state.data.record,
  settings: state.settings,
  addresses: state.data.related?.account_addresses?.results || EMPTY_ARRAY,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccount(id, data) {
    return dispatch(actions.data.updateRecord('accounts', id, data, false));
  },

  createAccountAddress(id, data) {
    return dispatch(
      actions.data.createRecord(`accounts/${id}/addresses`, data),
    );
  },
});

class PaymentMethodsEditRadio extends React.PureComponent {
  static contextTypes = {
    notifySuccess: pt.func.isRequired,
    notifyError: pt.func.isRequired,
  };

  static propTypes = {
    model: pt.string.isRequired,
    record: pt.object.isRequired,
    settings: pt.object.isRequired,
    addresses: pt.array.isRequired,
    fetchRecord: pt.func.isRequired,
    updateAccount: pt.func.isRequired,
    createAccountAddress: pt.func.isRequired,
    value: pt.string,
    onSelect: pt.func,
    onSelectAddress: pt.func,
    requireAddress: pt.bool,
    disabled: pt.bool,
  };

  constructor(props) {
    super(props);

    const selectedMethod = this.getDefaultSelectedMethod(props);
    const selectedAddress = this.getDefaultSelectedAddress(props);

    if (selectedMethod) {
      props.onSelect?.(selectedMethod);
    }

    if (selectedAddress) {
      props.onSelectAddress?.(selectedAddress);
    }

    this.state = {
      selectedMethod,
      selectedAddress,
      addingBilling: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.selectedMethod !== props.value) {
      return { selectedMethod: props.value };
    }

    return null;
  }

  getDefaultSelectedMethod(props = this.props) {
    const { record } = props;
    const method = record.billing?.method;

    if (method && method !== 'card') {
      return method;
    }

    return null;
  }

  getDefaultSelectedAddress(props = this.props) {
    const { record, addresses } = props;

    if (!record.billing?.address1)
      return NONE_ADDRESS_ID;

    const defaultAddressId = getDefaultAddressId(record.account);
    let defaultAddress;

    for (const address of addresses) {
      if (isSameAddress(record.billing, address)) {
        return address.id;
      }

      if (defaultAddressId === address.id) {
        defaultAddress = address;
      }
    }

    return defaultAddress?.id || addresses[0]?.id || null;
  }

  onSelect = (value) => {
    const { onSelect } = this.props;

    this.setState({ selectedMethod: value });

    onSelect?.(value);
  };

  onSelectAddress(addressId) {
    const { onSelectAddress } = this.props;

    this.setState({ selectedAddress: addressId });

    onSelectAddress?.(addressId);
  }

  onClickAddBilling = () => {
    this.setState({ addingBilling: !this.state.addingBilling });
  };

  onChangeBilling = (event) => {
    event.preventDefault();

    const { addresses } = this.props;
    const { index } = event.currentTarget.dataset;
    const isNewBillingIndex = index === '0';
    const isNoneBillingIndex = index === '1';

    if (isNewBillingIndex) {
      this.onClickAddBilling();
      this.onSelectAddress(null);
    } else if (isNoneBillingIndex) {
      this.onSelectAddress(NONE_ADDRESS_ID);
    } else {
      this.onSelectAddress(addresses[index - 2]?.id);
    }
  };

  onAddAddress = async (values) => {
    const { notifySuccess, notifyError } = this.context;
    const { record, fetchRecord, createAccountAddress, updateAccount } =
      this.props;
    const accountId = record.account_id || record.id;
    const { is_default: isDefaultAddress, billing } = values;

    const result = await createAccountAddress(accountId, billing);

    if (result.errors) {
      return notifyError(result.errors);
    }

    if (isDefaultAddress) {
      await updateAccount(accountId, {
        shipping: { account_address_id: result.id },
        billing,
      });
    }

    await fetchRecord(record.id);

    notifySuccess('Billing address added');

    this.setState({ addingBilling: false });

    this.onSelectAddress(result.id);
  };

  getOptions() {
    const { selectedMethod } = this.state;
    const methodOptions = paymentMethodOptions(this.props, selectedMethod);

    return methodOptions.filter((option) => option.value !== 'card');
  }

  renderActions() {
    const { addresses, requireAddress, disabled } = this.props;
    const { selectedAddress } = this.state;

    if (!requireAddress) {
      return null;
    }

    return (
      <div className="payment-method-action row">
        <Field
          type="select"
          label="Billing address"
          className="span4"
          placeholder="Select billing address"
          options={[
            { value: NEW_ADDRESS_ID, label: 'New address' },
            { value: NONE_ADDRESS_ID, label: 'None' },
            ...addresses.map((address) => ({
              value: address.id,
              label: makeAddressString(address),
            })),
          ]}
          onClickSelectValue={this.onChangeBilling}
          defaultValue={selectedAddress}
          disabled={disabled}
          readonly={true}
          autoComplete="off"
        />
      </div>
    );
  }

  render() {
    const { disabled } = this.props;
    const { selectedMethod, addingBilling } = this.state;
    const methodOptions = this.getOptions();
    const hasOptions = methodOptions.length > 0;

    if (!hasOptions) {
      return null;
    }

    return (
      <PaymentMethodsRadio
        title="Other payment options"
        options={methodOptions}
        actions={this.renderActions()}
        onSelect={this.onSelect}
        value={selectedMethod}
        disabled={disabled}
        showBilling={addingBilling}
        onClickAddBilling={this.onClickAddBilling}
        onSubmitBilling={this.onAddAddress}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentMethodsEditRadio);
