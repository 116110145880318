import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import pt from 'prop-types';

import { formatCurrency } from 'utils';
import { confirmRouteLeave } from 'utils/container';
import {
  isStripeCardGateway,
  isStripeCardPaymentMethod,
  isStripeIDealGateway,
  isStripeKlarnaGateway,
  chargeGiftcard,
} from 'utils/payment';
import {
  loadStripe,
  createStripeCardPaymentMethod,
  createIDealPaymentMethod,
  createIDealPaymentIntent,
  isSingleUsePaymentMethodError,
  createKlarnaSource,
  createPaymentIntent,
} from 'utils/stripe';
import {
  getCleanBundleItems,
  addTrialUpcomingPayments,
  getAddressFromRecord,
  isSameAddress,
} from 'utils/order';
import { getAccountAddressesQuery, getAccountCardsQuery } from 'utils/account';
import { LOCALE_CODE } from 'utils';

import actions from 'actions';

import api, { tokenizeCard } from 'services/api';

import ViewLoading from 'components/view/loading';
import ViewPage from 'components/pages/order/view';
import NotFoundPage from 'components/pages/error/404';

export const mapStateToProps = (state) => ({
  record: state.data.record,
  related: state.data.related,
  prev: state.data.record && (state.data.record.prev || undefined),
  next: state.data.record && (state.data.record.next || undefined),
  loading: state.data.loading,
  errors: state.data.recordErrors,
  lookup: state.lookup,
  categories: state.categories,
  content: state.content,
  settings: state.settings,
  discounts: state.orders.discounts,
  suggestedAddresses: state.lookup.suggestedAddresses,
  notifications: state.notifications,
  checkoutCustomUrl: state.settings.checkout?.custom_checkout
    ? state.settings.checkout?.custom_checkout_url
    : '',
});

export const getMapDispatchToProps =
  (model = 'orders', mapMore = null) =>
  (dispatch) => ({
    fetchRecord(id) {
      const seriesQuery = dispatch(
        actions.data.getCollectionSeriesQuery(model),
      );

      const query = {
        expand: [
          'items.product',
          'items.variant',
          'items.bundle_items.product',
          'items.bundle_items.variant',
          'authorized_payment',
          'coupon',
          'promotions:100',
          'giftcards.giftcard',
        ],
        $locale: LOCALE_CODE,
        include: {
          account: {
            url: '/accounts/{account_id}',
            params: {
              $currency: 'currency',
            },
          },
          prev: {
            url: `/${model}/:first`,
            data: seriesQuery,
            params: {
              id: { $gt: 'id' },
            },
          },
          next: {
            url: `/${model}/:last`,
            data: seriesQuery,
            params: {
              id: { $lt: 'id' },
            },
          },
        },
      };

      switch (model) {
        case 'carts': {
          Object.assign(query.include, {
            order: {
              url: '/orders/{id}',
              params: {
                id: 'order_id',
              },
              data: {
                fields: 'number',
              },
            },
          });

          break;
        }

        case 'orders': {
          Object.assign(query.include, {
            first_order: {
              url: '/orders/:first',
              params: {
                account_id: 'account_id',
              },
              data: {
                fields: 'id',
              },
            },
            order_count: {
              url: '/orders/:count',
              params: {
                account_id: 'account_id',
              },
            },
            payments: {
              url: '/payments',
              params: {
                order_id: 'id',
              },
              data: {
                limit: 100,
                expand: 'giftcard',
                include: {
                  refunds: {
                    url: '/payments:refunds',
                    params: {
                      parent_id: 'id',
                    },
                  },
                },
              },
            },
            shipments: {
              url: '/shipments',
              params: {
                order_id: 'id',
              },
              data: {
                limit: 100,
                expand: ['items.product', 'items.variant'],
              },
            },
            returns: {
              url: '/returns',
              params: {
                order_id: 'id',
              },
              data: {
                limit: 100,
                expand: ['items.product', 'items.variant'],
              },
            },
          });

          break;
        }

        default:
          break;
      }

      return dispatch(actions.data.fetchRecord(model, id, query)).then(
        async (result) => {
          if (!result) return result;

          await dispatch(
            actions.data.fetchIncludedContent(
              ['payments', 'shipments', 'returns'],
              result,
            ),
          );

          const relatedQuery = {
            note_count: {
              url: '/:notes/:count',
              data: {
                record_id: result.id,
              },
            },
          };

          if (result.account_id) {
            relatedQuery.account_cards = getAccountCardsQuery(
              result.account_id,
              { $ne: false },
            );

            relatedQuery.account_addresses = getAccountAddressesQuery(
              result.account_id,
              { $ne: false },
            );
          }

          if (model === 'orders') {
            Object.assign(relatedQuery, {
              notifications: {
                url: '/notifications',
                data: {
                  record_id: result.id,
                  template: {
                    $in: ['orders.receipt', 'orders.shipped'],
                  },
                  error: null,
                },
              },
              giftcards_delivered: {
                url: '/giftcards',
                data: {
                  order_id: result.id,
                },
              },
              subscriptions_delivered: {
                url: '/subscriptions',
                data: {
                  order_id: result.id,
                  expand: 'product, variant',
                },
              },
            });
          }

          dispatch(actions.data.fetchRelated(id, relatedQuery));

          addTrialUpcomingPayments(result);

          return result;
        },
      );
    },

    fetchAccountAddresses(relatedId, accountId) {
      return dispatch(
        actions.data.fetchRelated(relatedId, {
          account_addresses: getAccountAddressesQuery(accountId),
        }),
      );
    },

    fetchAccountCards: (relatedId, accountId) => {
      return dispatch(
        actions.data.fetchRelated(relatedId, {
          account_cards: getAccountCardsQuery(accountId),
          account_addresses: getAccountAddressesQuery(accountId),
        }),
      );
    },

    fetchDiscounts() {
      return dispatch(actions.orders.fetchDiscounts());
    },

    updateRecord(id, data) {
      return dispatch(actions.data.updateRecord(model, id, data));
    },

    addGiftcard(id, data) {
      return dispatch(
        actions.data.createRecord(`${model}/${id}/giftcards`, data),
      );
    },

    removeGiftcard(id, giftcardId) {
      return dispatch(
        actions.data.deleteRecord(`${model}/${id}/giftcards`, giftcardId),
      );
    },

    updateFetchRecord(id, data) {
      return dispatch(actions.data.updateFetchRecord(model, id, data));
    },

    deleteRecord(id) {
      return dispatch(actions.data.deleteRecord(model, id));
    },

    fetchOrderPrintTemplates() {
      return dispatch(actions.notifications.fetchOrderPrintTemplates());
    },

    fetchPrintTemplate(id) {
      return dispatch(actions.notifications.loadPrintTemplate(id));
    },

    getItemPrice(id, item) {
      return api.get(`/data/${model}/${id || 'new'}/items`, { $price: item });
    },

    addItem(id, item) {
      return dispatch(actions.data.createRecord(`${model}/${id}/items`, item));
    },

    /**
     * @param {string} id
     * @param {Array<object>} items
     */
    async editItems(id, items) {
      const removedItems = items.filter((item) => item.removed);
      const updatedItems = items
        .filter((item) => !item.removed)
        .map((item) => ({ ...item, removed: undefined }));
      if (removedItems.length > 0) {
        await Promise.all(
          removedItems.map((item) => {
            return dispatch(
              actions.data.deleteRecord(`${model}/${id}/items`, item.id),
            );
          }),
        );
      }
      if (updatedItems.length > 0) {
        return await dispatch(
          actions.data.updateRecord(model, `${id}/items`, updatedItems),
        );
      }
    },

    createPayment(id, data) {
      return dispatch(
        actions.data.createRecord(`${model}/${id}/payments`, data),
      );
    },

    updatePayment(id, paymentId, data) {
      return dispatch(
        actions.data.updateRecord(`${model}/${id}/payments`, paymentId, data),
      );
    },

    refundPayment(id, data) {
      return dispatch(
        actions.data.createRecord(`${model}/${id}/refunds`, data),
      );
    },

    updateRefund(id, refundId, data) {
      return dispatch(
        actions.data.updateRecord(`${model}/${id}/refunds`, refundId, data),
      );
    },

    createShipment(id, data) {
      return dispatch(
        actions.data.createRecord(`${model}/${id}/shipments`, data),
      );
    },

    updateShipment(id, data) {
      return dispatch(actions.data.updateRecord('shipments', id, data));
    },

    deleteShipment(id) {
      return dispatch(actions.data.deleteRecord('shipments', id));
    },

    createReturn(id, data) {
      return dispatch(
        actions.data.createRecord(`${model}/${id}/returns`, data),
      );
    },

    updateReturn(id, data) {
      return dispatch(actions.data.updateRecord('returns', id, data));
    },

    deleteReturn(id) {
      return dispatch(actions.data.deleteRecord('returns', id));
    },

    createGiftcard(data) {
      return dispatch(actions.data.createRecord(`giftcards`, data));
    },

    /**
     * @param {string} id
     * @param {string} template
     */
    resendEmail(id, template) {
      return dispatch(
        actions.data.updateRecord(model, id, { $notify: template }),
      );
    },

    fetchNotification(id) {
      return dispatch(actions.notifications.fetch(id));
    },

    loadContentModels() {
      return Promise.all([
        dispatch(actions.content.loadModels('orders')),
        dispatch(actions.content.loadModels('accounts')),
      ]);
    },

    loadSettings() {
      return Promise.all([
        dispatch(actions.settings.load('payments')),
        dispatch(actions.settings.load('shipments')),
        dispatch(actions.settings.load('accounts')),
        dispatch(actions.settings.load('checkout')),
        dispatch(actions.settings.load('notifications')),
      ]);
    },

    ...(mapMore ? mapMore(dispatch) : {}),
  });

export class ViewOrder extends React.PureComponent {
  static propTypes = {
    params: pt.object.isRequired,
    router: pt.object,
    location: pt.object,
    isCart: pt.bool,
    page: pt.element,

    // mapStateToProps
    record: pt.object,
    settings: pt.object,

    // mapDispatchToProps
    addItem: pt.func,
    editItems: pt.func,
    fetchRecord: pt.func,
    updateRecord: pt.func,
    deleteRecord: pt.func,
    updateRefund: pt.func,
    createReturn: pt.func,
    updateReturn: pt.func,
    deleteReturn: pt.func,
    refundPayment: pt.func,
    createPayment: pt.func,
    updatePayment: pt.func,
    createShipment: pt.func,
    updateShipment: pt.func,
    deleteShipment: pt.func,
    fetchDiscounts: pt.func,
    fetchAccountAddresses: pt.func,
    fetchAccountCards: pt.func,
    fetchOrderPrintTemplates: pt.func,
    updateFetchRecord: pt.func,
    loadContentModels: pt.func,
    createGiftcard: pt.func,
    removeGiftcard: pt.func,
    addGiftcard: pt.func,
    loadSettings: pt.func,
    getItemPrice: pt.func,
    resendEmail: pt.func,
  };

  static contextTypes = {
    client: pt.object.isRequired,
    notifySuccess: pt.func.isRequired,
    notifyError: pt.func.isRequired,
    notifyDeleted: pt.func.isRequired,
    openModal: pt.func.isRequired,
    user: pt.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      loaded: false,
      edited: false,
      submitting: false,
      showPaymentEdit: false,
      stripe: null,
      stripeElement: null,
      getItemPrice: this.getItemPrice.bind(this),
      onAddItem: this.onAddItem.bind(this),
      onEditItems: this.onEditItems.bind(this),
      onEditValues: this.onEditValues.bind(this),
      onShippingGetRates: this.onShippingGetRates.bind(this),
      onPaymentEdit: this.onPaymentEdit.bind(this),
      onPaymentCharge: this.onPaymentCharge.bind(this),
      onPaymentRefund: this.onPaymentRefund.bind(this),
      onPaymentUpdate: this.onPaymentUpdate.bind(this),
      onRefundUpdate: this.onRefundUpdate.bind(this),
      onPaymentAddGiftcard: this.onPaymentAddGiftcard.bind(this),
      onPaymentRemoveGiftcard: this.onPaymentRemoveGiftcard.bind(this),
      onChangeCurrency: this.onChangeCurrency.bind(this),
      onFulfillmentAdd: this.onFulfillmentAdd.bind(this),
      onFulfillmentEdit: this.onFulfillmentEdit.bind(this),
      onFulfillmentDelete: this.onFulfillmentDelete.bind(this),
      onReturnAdd: this.onReturnAdd.bind(this),
      onReturnEdit: this.onReturnEdit.bind(this),
      onReturnDelete: this.onReturnDelete.bind(this),
      onGiftcardSend: this.onGiftcardSend.bind(this),
      onResendEmail: this.onResendEmail.bind(this),
      onEdited: this.onEdited.bind(this),
      onDelete: this.onDelete.bind(this),
      onStripeInit: this.onStripeInit.bind(this),
      onSendInvoice: this.onSendInvoice.bind(this),
      onLoadAddresses: this.onLoadAddresses.bind(this),
      onLoadBilling: this.onLoadBilling.bind(this),
    };
  }

  componentDidMount() {
    confirmRouteLeave(this);

    const {
      params,
      fetchRecord,
      fetchDiscounts,
      fetchOrderPrintTemplates,
      loadContentModels,
      loadSettings,
    } = this.props;

    Promise.all([
      fetchOrderPrintTemplates(),
      fetchDiscounts(),
      loadContentModels(),
      fetchRecord(params.id),
      loadSettings(),
    ]).then(() => {
      this.setState({ loaded: true });
    });
  }

  componentDidUpdate(prevProps) {
    const { params, router, location, fetchRecord } = this.props;

    if (prevProps.params.id !== params.id) {
      this.setState({ loaded: false });

      fetchRecord(params.id).then(() => {
        this.setState({ loaded: true });
      });
    }

    if (!prevProps.record && this.props.record) {
      const { record } = this.props;
      const method = get(record, 'billing.method');
      const query = get(this.props, 'location.query', {});

      switch (method) {
        case 'ideal':
          router.replace(location.pathname);
          this.handleIDealRedirectAction(query, record);
          break;

        case 'klarna':
          router.replace(location.pathname);
          this.handleKlarnaRedirectAction(query, record);
          break;

        default:
          break;
      }
    }
  }

  async onLoadAddresses() {
    const {
      record: { id, account_id },
      fetchAccountAddresses,
    } = this.props;

    await fetchAccountAddresses(id, account_id);
  }

  async onLoadBilling() {
    const {
      record: { id, account_id },
      fetchAccountCards,
    } = this.props;

    await fetchAccountCards(id, account_id);
  }

  async handleIDealRedirectAction(query, record) {
    if (!query.payment_intent) {
      return;
    }

    const { params, updateRecord, createPayment, fetchRecord } = this.props;

    if (query.redirect_status === 'succeeded') {
      this.setState({ loading: true });
      const amount = get(record, 'billing.intent.amount');
      const result = await updateRecord(params.id, {
        billing: {
          intent: { id: query.payment_intent },
        },
      });
      if (result.errors) {
        return this.context.notifyError(result.errors);
      }
      const payment = await createPayment(params.id, {
        captured: true,
        method: 'ideal',
        amount,
      });
      this.setState({ loading: undefined });
      await fetchRecord(params.id);
      return payment.errors && this.context.notifyError(payment.errors);
    }

    return this.context.notifyError(
      'We are unable to authenticate your payment method. Please choose a different payment method and try again.',
    );
  }

  async handleKlarnaRedirectAction(query, record) {
    if (!query.source) {
      return;
    }

    const { params, createPayment, fetchRecord } = this.props;

    if (query.redirect_status === 'succeeded') {
      this.setState({ loading: true });
      const klarna = get(record, 'billing.klarna');
      const payment = await createPayment(params.id, {
        captured: true,
        method: 'klarna',
        amount: klarna.amount,
      });
      this.setState({ loading: undefined });
      await fetchRecord(params.id);
      return payment.errors && this.context.notifyError(payment.errors);
    } else if (query.redirect_status === 'canceled') {
      return this.context.notifyError('Your payment was canceled.');
    }
    return this.context.notifyError(
      'We are unable to authenticate your payment method. Please choose a different payment method and try again.',
    );
  }

  onEdited(edited) {
    this.setState({ edited });
  }

  getItemPrice(item) {
    const { params, getItemPrice } = this.props;
    return getItemPrice(params.id, item);
  }

  onAddItem(values, draftId) {
    const { params, addItem, editItems, fetchRecord } = this.props;

    const recordId = draftId || params.id;

    const data = {
      product_id: values.product_id,
      variant_id: values.variant_id,
      options: values.options,
      quantity: values.quantity,
      price: values.set_price,
      purchase_option: values.purchase_option,
      bundle_items: getCleanBundleItems(values.bundle_items),
      date_trial_end: values.date_trial_end,
    };

    let promise;
    if (values.id) {
      promise = editItems(recordId, [{ id: values.id, ...data }]);
    } else {
      promise = addItem(recordId, data);
    }

    return promise.then((result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
        return false;
      } else {
        return fetchRecord(recordId);
      }
    });
  }

  onEditItems(items, draftId) {
    const { params, editItems, fetchRecord } = this.props;

    const recordId = draftId || params.id;

    this.setState({ submitting: true });
    return editItems(
      recordId,
      items.map((item) => ({
        id: item.id,
        product: undefined,
        product_id: item.product ? item.product.id : item.product_id,
        variant: undefined,
        variant_id: item.variant ? item.variant.id : item.variant_id,
        price: item.set_price,
        quantity: item.quantity,
        options: item.options,
        removed: item.removed,
        bundle_items: getCleanBundleItems(item.bundle_items),
        purchase_option: item.purchase_option,
      })),
    ).then((result) => {
      this.setState({ edited: false, submitting: false });
      return result && result.errors
        ? this.context.notifyError(result.errors)
        : fetchRecord(recordId);
    });
  }

  onEditValues(values, draftId) {
    const { params, updateFetchRecord, fetchRecord } = this.props;

    const recordId = draftId || params.id;

    this.setState({ submitting: true });
    return updateFetchRecord(recordId, values).then((result) => {
      this.setState({ submitting: false });
      if (result.errors) {
        this.context.notifyError(result.errors);
      } else {
        this.setState({ edited: false });
        return fetchRecord(recordId);
      }
    });
  }

  onShippingGetRates(draftId) {
    const { params, updateFetchRecord } = this.props;

    const recordId = draftId || params.id;

    return updateFetchRecord(recordId, {
      shipment_rating: null,
    });
  }

  async onPaymentEdit(values, draftId) {
    const { params, record, updateRecord, fetchRecord } = this.props;
    const { stripe, stripeElement } = this.state;

    const recordId = draftId || params.id;

    const billing = values.billing || {};
    const card = billing.card || {};

    this.setState({ loading: true });

    if (card.number) {
      delete billing.card;

      const cardResult = await tokenizeCard(this.context.client.public_key, {
        card,
        billing,
        account_id: record && record.account_id,
      });

      if (cardResult.errors) {
        const errorMessage = get(
          cardResult,
          'errors.gateway.message',
          JSON.stringify(cardResult.errors),
        );

        this.context.notifyError(`Error: ${errorMessage}`);
        this.setState({ loading: undefined });

        return false;
      }

      billing.card = {
        token: cardResult.token,
      };
    } else if (values.giftcard_code) {
      const giftcard = await this.addGiftcard(values);

      if (!giftcard) {
        this.setState({ loading: undefined });
        return false;
      }

      values.giftcard_code = undefined;
    }

    if (
      billing.method &&
      billing.method !== 'card' &&
      get(record, 'billing.card')
    ) {
      billing.card = null;
      billing.account_card_id = null;
    } else if (billing.account_card_id) {
      const accountCardId = billing.account_card_id;
      const isAddressChanged = !isSameAddress(billing, record.billing);

      if (
        accountCardId !== record.billing.account_card_id ||
        isAddressChanged
      ) {
        await updateRecord(recordId, {
          billing: {
            account_card_id: accountCardId,
            ...(isAddressChanged ? getAddressFromRecord(billing) : undefined),
          },
        });

        delete billing.account_card_id;
      }
    } else if (billing.method === 'card' && stripeElement && stripe) {
      const stripeCard = await createStripeCardPaymentMethod(
        stripe,
        stripeElement,
        billing,
      );

      if (stripeCard.error) {
        this.context.notifyError(`Error: ${stripeCard.error.message}`);
        this.setState({ loading: undefined });
        return false;
      }

      billing.card = stripeCard;
    } else if (billing.method === 'ideal') {
      const { error, paymentMethod } = await createIDealPaymentMethod(
        stripe,
        stripeElement,
        billing,
      );

      if (error) {
        this.context.notifyError(`Error: ${error.message}`);
        this.setState({ loading: undefined });
        return false;
      }

      if (!paymentMethod.ideal.bank || !paymentMethod.ideal.bic) {
        this.setState({ loading: undefined });
        return false;
      }

      billing.ideal = { token: paymentMethod.id };
    }

    const result = await updateRecord(recordId, values);
    if (result.errors) {
      this.context.notifyError(result.errors);
      this.setState({ loading: undefined });
      return false;
    }

    await fetchRecord(recordId);
    this.setState({ loading: undefined });
    return true;
  }

  async getStripe() {
    const { settings } = this.props;
    const { stripe } = this.state;
    return stripe || (await loadStripe(settings));
  }

  async createPayment(id, values) {
    const { createPayment } = this.props;
    const { notifyError } = this.context;
    const paymentAction = await this.handlePaymentAction(id, values);
    const paymentData = {
      ...values,
      trial: undefined,
    };

    if (paymentAction) {
      if (paymentAction.error) {
        return notifyError(paymentAction.error);
      } else if (paymentAction.redirect) {
        return;
      } else if (paymentAction.values) {
        // assign payment action data
        for (const [key, value] of Object.entries(paymentAction.values)) {
          paymentData[key] = value;
        }
      }
    }

    return createPayment(id, paymentData);
  }

  handlePaymentAction(id, values) {
    // handle additional actions required to make a payment,
    // such as confirm payment or redirect to a third party page.
    //
    // returns:
    // * undefined - no additional action required
    // * { redirect: true } - redirect to a third party page
    // * { error: 'Error message' } - payment processing error
    // * { values: {...some values} } - additional payment values

    const { record, settings } = this.props;

    if (values.method === 'card' && isStripeCardGateway(settings, record)) {
      return this.handleStripeCardAction(id, values);
    } else if (
      values.method === 'ideal' &&
      isStripeIDealGateway(settings, record)
    ) {
      return this.handleStripeIDealAction(id, values);
    } else if (
      values.method === 'klarna' &&
      isStripeKlarnaGateway(settings, record)
    ) {
      return this.handleStripeKlarnaAction(id, values);
    }
  }

  async handleStripeCardAction(id, values) {
    const { record } = this.props;
    const { client } = this.context;

    if (!isStripeCardPaymentMethod(record)) {
      // only cards with pm_ token require additional actions
      return;
    }

    const stripe = await this.getStripe();
    if (!stripe) {
      return { error: 'Stripe was not loaded' };
    }

    const intent = await createPaymentIntent(
      record,
      client.public_key,
      values.amount,
    );
    if (intent.errors) {
      return { error: intent.errors };
    }

    const { paymentIntent, error: intentError } =
      await stripe.confirmCardPayment(intent.client_secret);
    if (intentError) {
      return { error: intentError.message };
    }

    const paymentValues = {
      intent: { stripe: { id: paymentIntent.id } },
    };

    if (values.trial) {
      // mark trial items as paid
      const itemsUpdate = reduce(
        values.trial,
        (acc, value, id) => {
          if (value) {
            acc.push({ id: id.replace('upcoming-', ''), paid: true });
          }
          return acc;
        },
        [],
      );

      if (!itemsUpdate.length) {
        return { error: 'Trial items are not selected' };
      }

      paymentValues.$order_update = {
        items: itemsUpdate,
      };
    }

    return { values: paymentValues };
  }

  async handleStripeIDealAction(id, values) {
    const { record, updateRecord } = this.props;
    const { client } = this.context;

    const intent = await createIDealPaymentIntent(
      client.public_key,
      record,
      values.amount,
    );
    if (intent.errors) {
      if (isSingleUsePaymentMethodError(intent.errors)) {
        this.setState({ showPaymentEdit: true });
        return { error: 'iDeal bank is not selected' };
      }
      return { error: intent.errors };
    }

    const stripe = await this.getStripe();
    if (!stripe) {
      return { error: 'Stripe was not loaded' };
    }

    const result = await updateRecord(id, {
      billing: { intent: { id: intent.id, amount: values.amount } },
    });
    if (result.errors) {
      return { error: result.errors };
    }

    await stripe.handleCardAction(intent.client_secret);
    return { redirect: true };
  }

  async handleStripeKlarnaAction(id, values) {
    const { record, updateRecord } = this.props;
    const { client } = this.context;

    const stripe = await this.getStripe();
    if (!stripe) {
      return { error: 'Stripe was not loaded' };
    }

    const chargeRecord = {
      ...record,
      shipping: {
        ...(record.shipping || {}),
        price: 0,
      },
      items: [
        {
          price_total: values.amount,
          quantity: 1,
          product: { name: 'Klarna charge' },
        },
      ],
      grand_total: values.amount,
      tax_total: 0,
    };

    const { error, source } = await createKlarnaSource(
      stripe,
      chargeRecord,
      client,
    );
    if (error) {
      return { error: error.message };
    }

    const result = await updateRecord(id, {
      billing: { klarna: { source: source.id, amount: values.amount } },
    });
    if (result.errors) {
      return { error: result.errors };
    }

    window.location.replace(source.redirect.url);
    return { redirect: true };
  }

  async onPaymentCharge(values) {
    const { params, createPayment, updatePayment, fetchRecord, record } =
      this.props;
    const { notifyError } = this.context;
    const { authorized_payment } = record;

    this.setState({ loading: true });

    const shouldCaptureAuthorized =
      !values.trial &&
      authorized_payment &&
      authorized_payment.method === values.method &&
      authorized_payment.amount === values.amount &&
      !authorized_payment.captured &&
      !authorized_payment.error;

    let result;
    if (shouldCaptureAuthorized) {
      result = await updatePayment(params.id, authorized_payment.id, {
        captured: true,
        amount: values.amount,
      });
    } else if (values.method === 'giftcard') {
      try {
        await chargeGiftcard(record, values.amount, createPayment);
      } catch (error) {
        result = { error };
      }
    } else {
      result = await this.createPayment(params.id, values);
    }

    if (result) {
      if (result.errors) {
        if (result.errors.amount) {
          notifyError(result.errors.amount.message);
        } else {
          notifyError(result.errors);
        }
        this.setState({ loading: undefined });
        return false;
      }
      if (result.error) {
        notifyError(result.error.message);
        await fetchRecord(params.id);
        this.setState({ loading: undefined });
        return false;
      }
    }

    await fetchRecord(params.id);
    this.setState({ loading: undefined });
    return true;
  }

  async onPaymentUpdate(paymentId, values) {
    const { params, updatePayment, fetchRecord } = this.props;
    const { notifyError } = this.context;

    this.setState({ loading: true });

    const result = await updatePayment(params.id, paymentId, {
      ...values,
    });

    if (result) {
      if (result.errors) {
        notifyError(result.errors);
        this.setState({ loading: undefined });
        return false;
      }
      if (result.error) {
        notifyError(result.error.message);
        await fetchRecord(params.id);
        this.setState({ loading: undefined });
        return false;
      }
    }

    await fetchRecord(params.id);
    this.setState({ loading: undefined });
    return true;
  }

  async onRefundUpdate(refundId, values) {
    const { params, updateRefund, fetchRecord } = this.props;
    const { notifyError } = this.context;

    this.setState({ loading: true });

    const result = await updateRefund(params.id, refundId, {
      ...values,
    });

    if (result) {
      if (result.errors) {
        notifyError(result.errors);
        this.setState({ loading: undefined });
        return false;
      }
      if (result.error) {
        notifyError(result.error.message);
        await fetchRecord(params.id);
        this.setState({ loading: undefined });
        return false;
      }
    }

    await fetchRecord(params.id);
    this.setState({ loading: undefined });
    return true;
  }

  async addGiftcard(values) {
    const { params, record, addGiftcard } = this.props;
    const { notifyError } = this.context;

    const code = String(values.giftcard_code)
      .replace(/[^0-9A-Z]*/gi, '')
      .toUpperCase();
    const giftcard = await api.get('/data/giftcards/:last', { code });
    if (!giftcard || !code) {
      notifyError(`Gift card not found with code ${code}`);
      return null;
    }
    if (giftcard.balance < values.amount) {
      notifyError(
        `Gift card balance is short (${formatCurrency(
          giftcard.balance,
          record.currency,
        )})`,
      );
      return null;
    }
    if (giftcard.redeemed || giftcard.balance === 0) {
      notifyError(`Gift card has already been fully redeemed`);
      return null;
    }
    const ex = find(record.giftcards, { code });
    if (!ex) {
      const added = await addGiftcard(params.id, { code });
      if (added.errors) {
        notifyError(added.errors);
        return null;
      }
    }
    return giftcard;
  }

  async onPaymentAddGiftcard(values) {
    const { params, fetchRecord } = this.props;
    this.setState({ loading: true });
    const giftcard = await this.addGiftcard(values);
    if (!giftcard) {
      this.setState({ loading: undefined });
      return false;
    }
    await fetchRecord(params.id);
    this.setState({ loading: undefined });
    return true;
  }

  async onPaymentRemoveGiftcard(giftcardId) {
    const { params, fetchRecord, removeGiftcard } = this.props;
    this.setState({ loading: true });
    await removeGiftcard(params.id, giftcardId);
    await fetchRecord(params.id);
    this.setState({ loading: undefined });
    return true;
  }

  async onChangeCurrency(currencyCode, draftId) {
    const { params, fetchRecord, updateRecord } = this.props;
    const { notifyError } = this.context;

    this.setState({ loading: true });

    const result = await updateRecord(draftId || params.id, {
      currency: currencyCode,
    });

    if (result.errors) {
      notifyError(result.errors);
      this.setState({ loading: undefined });
      return false;
    }

    await fetchRecord(draftId || params.id);
    this.setState({ loading: undefined });
    return true;
  }

  onPaymentRefund(values) {
    const { params, refundPayment, fetchRecord } = this.props;
    const { notifyError } = this.context;

    this.setState({ loading: true });

    return refundPayment(params.id, values).then(async (result) => {
      this.setState({ loading: undefined });
      if (result.errors) {
        notifyError(result.errors);
        return false;
      }
      if (result.error) {
        notifyError(result.error.message);
        return false;
      }
      await fetchRecord(params.id);
      return true;
    });
  }

  onFulfillmentAdd(values) {
    const { params, record, createShipment, fetchRecord } = this.props;

    const shipping = record.shipping || {};
    // TODO: replace with accountName util
    const accountName =
      (record.account && record.account.name) ||
      (record.billing && record.billing.name);
    return createShipment(params.id, {
      ...values,
      destination: {
        name: shipping.name || accountName,
        address1: shipping.address1,
        address2: shipping.address2,
        city: shipping.city,
        state: shipping.state,
        zip: shipping.zip,
        country: shipping.country,
        phone: shipping.phone,
      },
      $notify: !values.$notify ? false : 'orders.shipped',
    }).then(async (result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
        return false;
      }
      if (values.$notify && record.account) {
        this.context.notifySuccess(
          `Shipping confirmation sent to ${record.account.email}`,
        );
      }
      await fetchRecord(params.id);
      return true;
    });
  }

  onFulfillmentEdit(shipmentId, values) {
    const { params, record, updateShipment, fetchRecord } = this.props;

    return updateShipment(shipmentId, {
      ...values,
      items: undefined,
      $set: {
        items: values.items,
      },
      $notify: !values.$notify ? false : 'orders.shipped-update',
    }).then(async (result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
        return false;
      }
      if (values.$notify && record.account) {
        this.context.notifySuccess(
          `Shipping update confirmation sent to ${record.account.email}`,
        );
      }
      await fetchRecord(params.id);
      return true;
    });
  }

  onFulfillmentDelete(shipmentId) {
    const { params, deleteShipment, fetchRecord } = this.props;

    return deleteShipment(shipmentId).then(() => {
      return fetchRecord(params.id);
    });
  }

  onReturnAdd(values) {
    const { params, createReturn, fetchRecord } = this.props;

    return createReturn(params.id, {
      ...values,
      // origin: {
      //   // TODO
      // },
    }).then(async (result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
        return false;
      }
      await fetchRecord(params.id);
      return true;
    });
  }

  onReturnEdit(returnId, values) {
    const { params, updateReturn, fetchRecord } = this.props;

    return updateReturn(returnId, {
      ...values,
      items: undefined,
      $set: {
        items: values.items,
      },
    }).then(async (result) => {
      if (result.errors) {
        this.context.notifyError(result.errors);
        return false;
      }
      await fetchRecord(params.id);
      return true;
    });
  }

  onReturnDelete(returnId) {
    const { params, deleteReturn, fetchRecord } = this.props;

    return deleteReturn(returnId).then(() => {
      return fetchRecord(params.id);
    });
  }

  onGiftcardSend(values) {
    const { params, createGiftcard, updateRecord, fetchRecord } = this.props;

    return api
      .get('/data/orders/{id}', {
        id: params.id,
      })
      .then(async (order) => {
        const item = find(order.items, { id: values.order_item_id });
        if (!item) {
          this.context.notifyError(
            'Unable to send giftcard: order item not found',
          );
          return false;
        }
        const result = await createGiftcard({
          ...values,
          order_id: params.id,
          order_item_id: item.id,
          amount: item.price,
          $notify: 'fulfillment',
        });
        if (result.errors) {
          this.context.notifyError(result.errors);
          return false;
        }
        await updateRecord(params.id, {
          items: [
            {
              id: item.id,
              quantity_delivered: item.quantity_delivered + 1,
            },
          ],
        });
        this.context.notifySuccess(
          `Gift card created and sent to ${values.send_email}`,
        );
        await fetchRecord(params.id);
        return true;
      });
  }

  async onResendEmail(recordId, template, title) {
    const { resendEmail, record } = this.props;
    const { notifySuccess, notifyError } = this.context;

    try {
      const result = await resendEmail(recordId, template);
      if (!result || result.errors) {
        throw new Error(result ? result.errors : 'Unable to send email');
      }
      notifySuccess(`${title} sent to ${record.account.email}`);
    } catch (err) {
      notifyError(err);
    }
  }

  onDelete() {
    const { router, deleteRecord, isCart, record } = this.props;

    const { id } = record;

    return deleteRecord(id).then((result) => {
      if (result) {
        this.context.notifyDeleted(isCart ? 'Cart' : 'Order');
        router.replace(isCart ? '/carts' : '/orders');
      }
    });
  }

  onStripeInit(stripe, stripeElement) {
    this.setState({ stripe, stripeElement });
  }

  async onSendInvoice(recordId, params = {}) {
    const { updateRecord, record } = this.props;
    const { notifySuccess, notifyError } = this.context;
    try {
      const result = await updateRecord(recordId, {
        $notify: {
          ...params,
          id: 'invoice',
        },
        locale: params.locale,
      });
      if (!result || result.errors) {
        throw new Error(result ? result.errors : 'Unable to send invoice');
      }
      notifySuccess(`Invoice sent to ${params.to || record.account.email}`);
    } catch (err) {
      notifyError(err);
    }
  }

  render() {
    if (!this.state.loaded) {
      return <ViewLoading />;
    }

    if (!this.props.record) {
      return <NotFoundPage />;
    }

    return this.props.page ? (
      <this.props.page {...this.props} {...this.state} />
    ) : (
      <ViewPage {...this.props} {...this.state} />
    );
  }
}

export default connect(mapStateToProps, getMapDispatchToProps())(ViewOrder);
