import React from 'react';
import PropTypes from 'prop-types';

import { formatDate } from 'utils';
import { hasLocalizedContent } from 'utils/content';
import { confirmRouteLeave, confirmPageLeave } from 'utils/container';
import { subscriptionTitle } from 'utils/subscription';

import Link from 'components/link';
import { View } from 'components/view';
import Side from 'components/view/side';
import PrevButton from 'components/button/prev';
import NextButton from 'components/button/next';
import DropdownButton from 'components/button/dropdown';
import ActivityFeed from 'containers/ActivityFeed';
import OrderItems from 'components/pages/order/items';
import OrderCustomer from 'components/pages/order/customer';
import OrderCustomerEdit from 'components/pages/order/customer-edit';
import OrderPayments from 'components/pages/order/payments';
import OrderPaymentEdit from 'components/pages/order/payment-edit';
import OrderPaymentCharge from 'components/pages/order/payment-charge';
import OrderPaymentRefund from 'components/pages/order/payment-refund';
import OrderPaymentView from 'components/pages/order/payment-view';
import OrderContent from 'components/pages/order/content';
import OrderNotes from 'components/pages/order/notes';

import InvoiceStatus from './status';

import './invoice.scss';

export default class InvoiceView extends React.PureComponent {
  static contextTypes = {
    openModal: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showEditCustomer: false,
      showPaymentEdit: false,
      showPaymentCharge: false,
      showPaymentRefund: false,
      paymentId: null,
      refundId: null,
    };
    this.onClickEditCustomer = this.onClickEditCustomer.bind(this);
    this.onSubmitEditCustomer = this.onSubmitEditCustomer.bind(this);
    this.onClickPrint = this.onClickPrint.bind(this);
    this.onClickResendEmail = this.onClickResendEmail.bind(this);
    this.onClickPaymentEdit = this.onClickPaymentEdit.bind(this);
    this.onClickPaymentCharge = this.onClickPaymentCharge.bind(this);
    this.onClickPaymentRefund = this.onClickPaymentRefund.bind(this);
    this.onClickPaymentView = this.onClickPaymentView.bind(this);
    this.onClickRefundView = this.onClickRefundView.bind(this);
    this.onSubmitPaymentEdit = this.onSubmitPaymentEdit.bind(this);
    this.onSubmitPaymentCharge = this.onSubmitPaymentCharge.bind(this);
    this.onSubmitPaymentRefund = this.onSubmitPaymentRefund.bind(this);
    this.onSubmitPaymentView = this.onSubmitPaymentView.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickVoid = this.onClickVoid.bind(this);
  }

  componentDidMount() {
    confirmRouteLeave(this);
  }

  componentDidUpdate(prevProps, prevState) {
    confirmPageLeave(this, prevState);
  }

  componentWillUnmount() {
    confirmPageLeave(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.showPaymentEdit !== nextProps.showPaymentEdit) {
      this.setState({ showPaymentEdit: nextProps.showPaymentEdit });
    }
  }

  onClickDelete(event) {
    event.preventDefault();
    const { record } = this.props;
    this.context.openModal('ConfirmDelete', {
      title: `this invoice`,
      confirmInput: {
        description: `Enter the invoice number to confirm`,
        value: record.number,
      },
      onConfirm: () => {
        this.props.onDelete();
      },
    });
  }

  onClickVoid(event) {
    event.preventDefault();
    const { record } = this.props;
    this.context.openModal('Confirm', {
      title: `Void invoice`,
      message: (
        <p>
          Are you sure you want to void <b>Invoice {record.number}</b>? This
          action can't be undone.
        </p>
      ),
      action: 'Void invoice',
      actionType: 'danger',
      onConfirm: () => {
        this.props.onEditValues({
          void: true,
        });
      },
    });
  }

  onClickPrint(event) {
    event.preventDefault();
    window.print();
  }

  onClickResendEmail(event) {
    event.preventDefault();
    const { record, onResendEmail } = this.props;
    const { template, title, lastsent } = event.currentTarget.dataset;
    const lowerTitle = title.toLowerCase();
    this.context.openModal('Confirm', {
      title: `Re-send ${lowerTitle}`,
      message: (
        <p>
          Are you sure you want to re-send <b>{lowerTitle}</b> to{' '}
          {record.account.email}?
          <br />
          <br />
          <span className="muted">
            This email was last sent on {formatDate(lastsent, 'shortExact')}
          </span>
        </p>
      ),
      action: 'Send email',
      onConfirm: () => {
        onResendEmail(record.id, template, title);
      },
    });
  }

  onClickEditCustomer(event) {
    event.preventDefault();
    this.setState({
      showEditCustomer: !this.state.showEditCustomer,
    });
  }

  async onClickPaymentEdit(event) {
    event.preventDefault();

    if (!this.state.showPaymentEdit) {
      await this.props.onLoadBilling();
    }

    this.setState({
      showPaymentEdit: !this.state.showPaymentEdit,
    });
  }

  async onClickPaymentCharge(event) {
    event.preventDefault();

    if (!this.state.showPaymentCharge) {
      await this.props.onLoadBilling();
    }

    this.setState({
      showPaymentCharge: !this.state.showPaymentCharge,
    });
  }

  onClickPaymentRefund(event) {
    event.preventDefault();
    const paymentId = event.currentTarget.dataset.id;
    this.setState({
      paymentId,
      showPaymentRefund: !this.state.showPaymentRefund,
    });
  }

  onClickPaymentView(event) {
    event.preventDefault();
    const { id } = event.currentTarget.dataset;
    this.setState(
      {
        paymentId: id,
        refundId: null,
        showPaymentView: !this.state.showPaymentView,
      },
      () => {
        if (id && !this.state.showPaymentView) {
          this.setState({ showPaymentView: true });
        }
      },
    );
  }

  onClickRefundView(event) {
    event.preventDefault();
    const { paymentid, id } = event.currentTarget.dataset;
    this.setState(
      {
        paymentId: paymentid,
        refundId: id,
        showPaymentView: !this.state.showPaymentView,
      },
      () => {
        if (id && !this.state.showPaymentView) {
          this.setState({ showPaymentView: true });
        }
      },
    );
  }

  onSubmitEditCustomer(values) {
    this.setState({
      showEditCustomer: false,
    });
    return this.props.onEditValues({
      account: {
        ...values.account,
        billing: values.billing,
      },
      billing: values.billing,
    });
  }

  async onSubmitPaymentEdit(values) {
    const result = await this.props.onPaymentEdit({
      billing: values.billing,
    });
    if (result) {
      this.setState({
        showPaymentEdit: false,
      });
    }
  }

  async onSubmitPaymentCharge(values) {
    const result = await this.props.onPaymentCharge(values);
    if (result) {
      this.setState({
        showPaymentCharge: false,
      });
    }
  }

  async onSubmitPaymentRefund(values) {
    const result = await this.props.onPaymentRefund(values);
    if (result) {
      this.setState({
        showPaymentRefund: false,
      });
    }
  }

  async onSubmitPaymentView(values) {
    const { paymentId, refundId } = this.state;
    const result = refundId
      ? await this.props.onRefundUpdate(refundId, values)
      : await this.props.onPaymentUpdate(paymentId, values);
    if (result) {
      this.setState({
        showPaymentView: false,
      });
    }
  }

  onSubmitNotes = async (values) => {
    await this.props.onEditValues({
      notes: values.notes.trim(),
    });
  };

  getHasSentNotification(template) {
    const { related } = this.props;
    if (!related.notifications) {
      return false;
    }
    for (let result of related.notifications.results) {
      if (result.template === template) {
        return result.date_created;
      }
    }
    return false;
  }

  render() {
    const {
      record,
      prev = {},
      next = {},
      settings,
      content,
      loading,
      fetchRecord,
    } = this.props;

    const contentProps = {
      content: this.props.content,
      lookup: this.props.lookup,
      categories: this.props.categories,
      currency: record.currency,
    };

    const isVoidable = !!(!record.void && !record.closed && !record.paid);

    const notificationTemplate =
      record.source_model === 'orders'
        ? 'orders.invoice'
        : 'subscriptions.invoice';
    const notificationTitle =
      record.source_model === 'orders'
        ? 'Order invoice'
        : 'Subscriptions invoice';
    const lastSentOrderNotice =
      this.getHasSentNotification(notificationTemplate);

    const account = record.account || {};

    return (
      <div className="invoice-view order-view view-detail-full">
        <View
          detail={true}
          uri="/invoices"
          devtools={{
            model: 'invoices',
            record,
          }}
          sectionTitle="Invoices"
          headerTitle={`Invoice ${record.number}`}
          headerActions={[
            {
              component: (
                <span key="1" className="button-group">
                  <PrevButton
                    to={`/invoices/${prev.id}`}
                    type="secondary"
                    disabled={!prev.id}
                  />
                  <NextButton
                    to={`/invoices/${next.id}`}
                    type="secondary"
                    disabled={!next.id}
                  />
                </span>
              ),
            },
            {
              isActionMenu: true,
              component: (
                <DropdownButton
                  key="2"
                  anchor="right"
                  anchorPosition={49}
                  type="secondary"
                  items={[
                    lastSentOrderNotice && (
                      <a
                        key="1"
                        href=""
                        onClick={this.onClickResendEmail}
                        data-template={notificationTemplate}
                        data-title={notificationTitle}
                        data-lastsent={lastSentOrderNotice}
                      >
                        Resend {notificationTitle.toLowerCase()}
                      </a>
                    ),
                    isVoidable && (
                      <a key="3" href="" onClick={this.onClickVoid}>
                        Void invoice
                      </a>
                    ),
                    <a key="4" href="" onClick={this.onClickPrint}>
                      Print invoice
                    </a>,
                  ]}
                >
                  Actions
                </DropdownButton>
              ),
            },
          ]}
          extraActions={[
            {
              label: 'Delete invoice',
              onClick: this.onClickDelete,
              className: 'danger',
              delete: true,
            },
          ]}
          localized={hasLocalizedContent(this.props.content.models, 'invoices')}
        >
          <fieldset className="last-child">
            <table className="order-kpi-table">
              <tbody>
                <tr>
                  {record.date_period_start ? (
                    <td>
                      <span className="order-kpi-label">Period</span>
                      <span className="order-kpi-value">
                        <div>
                          {formatDate(record.date_period_start, 'short')}{' '}
                          &mdash; {formatDate(record.date_period_end, 'short')}
                        </div>
                      </span>
                    </td>
                  ) : (
                    <td>
                      <span className="order-kpi-label">Date</span>
                      <span className="order-kpi-value">
                        <div>
                          {formatDate(record.date_created, 'shortExact')}
                        </div>
                      </span>
                    </td>
                  )}
                  <td>
                    <span className="order-kpi-label">Source</span>
                    <span className="order-kpi-value">
                      <div>
                        {record.source_model === 'orders' ? (
                          <Link to={`/orders/${record.order_id}`}>
                            Order #{record.source.number}
                          </Link>
                        ) : (
                          <Link to={`/subscriptions/${record.subscription_id}`}>
                            {subscriptionTitle(record.source)}
                          </Link>
                        )}
                      </div>
                    </span>
                  </td>
                  <td>
                    <span className="order-kpi-label">Status</span>
                    <span className="order-kpi-value">
                      <div>
                        <InvoiceStatus invoice={record} />
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <OrderItems record={record} editable={false} loading={loading} />

            <OrderContent
              {...contentProps}
              zone="details"
              collection="invoices"
              record={record}
              onSubmit={this.props.onEditValues}
            />

            <OrderPayments
              sourceLabel="invoice"
              record={{
                ...record,
                billing: record.billing || account.billing,
                canceled: record.void,
              }}
              settings={settings}
              loading={loading}
              {...contentProps}
              onClickPaymentCharge={this.onClickPaymentCharge}
              onClickPaymentRefund={this.onClickPaymentRefund}
              onClickPaymentView={this.onClickPaymentView}
              onClickRefundView={this.onClickRefundView}
            />

            {record.notes && (
              <OrderNotes
                deprecated
                label="Invoice notes"
                record={record}
                onSubmit={this.onSubmitNotes}
              />
            )}

            <OrderContent
              {...contentProps}
              zone="content"
              collection="invoices"
              record={record}
              onSubmit={this.props.onEditValues}
            />

            <ActivityFeed
              model="invoices"
              record={record}
              label="Invoice activity"
            />
          </fieldset>
          <Side>
            <OrderCustomer
              record={{
                ...(account.id
                  ? {
                      account,
                      billing: record.billing || account.billing,
                    }
                  : {}),
              }}
              content={content}
              settings={settings}
              addable={false}
              onClickEditCustomer={this.onClickEditCustomer}
              onClickPaymentEdit={this.onClickPaymentEdit}
            />
          </Side>
        </View>
        {this.state.showEditCustomer && (
          <OrderCustomerEdit
            record={record}
            loading={loading}
            settings={settings}
            {...contentProps}
            billing={record.billing}
            onSubmitEditCustomer={this.onSubmitEditCustomer}
            onClickEditCustomer={this.onClickEditCustomer}
            hideShipping={true}
          />
        )}
        {this.state.showPaymentCharge && (
          <OrderPaymentCharge
            sourceLabel="invoice"
            record={{
              ...record,
              billing: record.billing || account.billing,
            }}
            loading={loading}
            settings={settings}
            fetchRecord={fetchRecord}
            onSubmitPaymentCharge={this.onSubmitPaymentCharge}
            onClickPaymentCharge={this.onClickPaymentCharge}
            {...contentProps}
          />
        )}
        {this.state.showPaymentRefund && (
          <OrderPaymentRefund
            sourceLabel="invoice"
            record={{
              ...record,
              billing: record.billing || account.billing,
            }}
            loading={loading}
            settings={settings}
            {...contentProps}
            paymentId={this.state.paymentId}
            onSubmitPaymentRefund={this.onSubmitPaymentRefund}
            onClickPaymentRefund={this.onClickPaymentRefund}
          />
        )}
        {this.state.showPaymentView && (
          <OrderPaymentView
            record={record}
            loading={loading}
            settings={settings}
            {...contentProps}
            paymentId={this.state.paymentId}
            refundId={this.state.refundId}
            onSubmitPaymentView={this.onSubmitPaymentView}
            onClickPaymentView={this.onClickPaymentView}
            onClickRefundView={this.onClickRefundView}
          />
        )}
        {this.state.showPaymentEdit && (
          <OrderPaymentEdit
            loading={loading}
            fetchRecord={fetchRecord}
            onSubmitPaymentEdit={this.onSubmitPaymentEdit}
            onClickPaymentEdit={this.onClickPaymentEdit}
          />
        )}
      </div>
    );
  }
}
